import { camelizeKeys } from 'humps';
import { socketSend } from '@namespace/socket';
import { setLocalStorageValue } from '@namespace/helpers';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
// eslint-disable-next-line @alf/loop-deps
import { addWallet, getWallets } from '@namespace/payments';
import normalizeUserData from './utils/normalizeUserData';
import normalizeAccountStatuses from './utils/normalizeAccountStatuses';
import { types } from './reducers/types';
import { SET_ACTION_RAW_PAYLOAD } from './constants';
import {
  changeActiveWallet,
  changeUser,
  checkIsVideoAvailable,
  createBankAccount,
  deleteBankAccount,
  getBankAccounts,
  loginUser,
  logoutUser,
  setUserNotifications,
  updateAddressFields,
  updateBankAccount,
  updatePhone,
  updateUser,
  uploadDocuments,
  getInboxCounter,
  acceptNewPolicy
} from './api';

export const actions = {
  [types.SET_COUNTERS]: (dispatch) => (data) => {
    const payload = {};

    if (data[SET_ACTION_RAW_PAYLOAD]) {
      Object.assign(payload, data);
    } else {
      payload.messagesCount = data;
    }

    dispatch({
      type: types.SET_COUNTERS,
      payload
    });
  },
  [types.UPDATE_ACCOUNT_STATUSES]: (dispatch) => (payload) => {
    dispatch({
      type: types.UPDATE_ACCOUNT_STATUSES,
      payload
    });
  },
  [types.FETCH_MESSAGES_COUNTERS]: (dispatch) => async (userId) => {
    const unreadMessages = (await getInboxCounter(userId)) || 0;

    dispatch({
      type: types.SET_COUNTERS,
      payload: {
        messagesCount: unreadMessages
      }
    });
  },
  [types.UPDATE_PHONE]: (dispatch) => async ({ userId, phone }) => {
    await updatePhone(phone, userId);

    dispatch({
      type: types.SET_PHONE,
      payload: phone
    });
  },
  [types.UPDATE_USER]: (dispatch) => async ({
    firstName,
    middleName,
    lastName,
    sex,
    countryId,
    birthday,
    secretQuestion,
    secretQuestionAnswer
  }) => {
    const result = await updateUser({
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      title: sex,
      country_id: countryId,
      date_of_birth: birthday,
      question: secretQuestion,
      answer: secretQuestionAnswer
    });

    if (!result.error_code) {
      dispatch({
        type: types.SET_USER,
        payload: {
          firstName,
          middleName,
          lastName,
          sex,
          countryId,
          birthday,
          question: secretQuestion
        }
      });
    }
  },
  [types.UPDATE_NOTIFICATIONS]: (dispatch) => async (notifications) => {
    await setUserNotifications(notifications);

    dispatch({
      type: types.SET_NOTIFICATIONS,
      payload: notifications
    });
  },
  [types.UPDATE_ACTIVE_WALLET]: (dispatch) => async (wallet) => {
    await changeActiveWallet(wallet);
    dispatch({
      type: types.SET_ACTIVE_WALLET,
      payload: wallet.walletHash
    });
  },
  [types.ADD_WALLET]: (dispatch) => async ({
    paymentInstrumentId,
    paymentSystems,
    wallet,
    isRefresh
  }) => {
    const { walletHash, userId, errorCode } = await addWallet({
      ...wallet,
      paymentInstrumentId
    });

    if (walletHash) {
      await changeActiveWallet({
        userId,
        walletHash
      });

      if (isRefresh) {
        const wallets = await getWallets();

        dispatch({
          type: types.SET_WALLETS,
          payload: {
            wallets,
            paymentSystems
          }
        });
      }
      return { walletHash, paymentInstrumentId };
    }
    return { errorCode };
  },
  [types.CHECK_IS_VIDEO_AVAILABLE]: () => async (userId) => {
    const isVideoAvailable = await checkIsVideoAvailable(userId);

    return isVideoAvailable === 'yes';
  },
  [types.FETCH_LOGIN]: () => ({
    username,
    password,
    captcha,
    affiliateParams,
    reCaptchaData,
    reCaptchaToken
  }) =>
    loginUser({
      username,
      password,
      captcha,
      affiliateParams,
      reCaptchaData,
      reCaptchaToken
    }),
  [types.FETCH_USER_DATA]: (dispatch) => async () => {
    try {
      const data = await socketSend({ cmd: 'get_user' });
      dispatch({ type: types.SET_USER, payload: normalizeUserData(data.data) });
    } catch (e) {
      dispatch({ type: types.SET_USER_INFO_LOADED, payload: true });
      throw e; // important! needed for bug with different sessions in socket
    }
  },
  [types.UPDATE_POLICY]: (dispatch) => async (userId) => {
    const res = await acceptNewPolicy(userId);
    if (res.status === 'ok') {
      dispatch({
        type: types.SET_NEW_POLICY
      });
    }
  },
  [types.FETCH_USER_DATA_FOR_WIDGET]: (dispatch) => async () => {
    const data = await socketSend({
      cmd: 'do_proto_v1', // todo 'accounting/login'
      data: { query: { user: {} } }
    });
    if (data?.data?.user?.id) {
      dispatch({ type: types.SET_USER, payload: camelizeKeys(data.data.user) });
    }
  },
  [types.FETCH_LOGOUT]: (dispatch) => async (id) => {
    dispatch({ type: types.LOGGING_OUT });

    await logoutUser(id);
  },
  [types.GET_BANK_ACCOUNTS]: (dispatch) => async () => {
    const accounts = await getBankAccounts();
    dispatch({
      type: types.SET_BANK_ACCOUNTS,
      payload: { accounts }
    });
  },
  [types.CREATE_BANK_ACCOUNT]: () => async (data) => createBankAccount(data),
  [types.DELETE_BANK_ACCOUNT]: () => async (data) => deleteBankAccount(data),
  [types.UPDATE_BANK_ACCOUNT]: () => async (data) => updateBankAccount(data),
  [types.SHOW_VERIFICATION_MESSAGE]: (dispatch) => async (status) => {
    dispatch({
      type: types.SET_VERIFICATION_MESSAGE,
      payload: {
        verificationMessageType: status
      }
    });
  },
  [types.UPLOAD_DOCUMENTS]: () => async (files) => {
    return uploadDocuments(files);
  },
  [types.UPDATE_ADDRESS_FIELDS]: (dispatch) => async (data) => {
    await updateAddressFields(data);
    dispatch({
      type: types.SET_ADDRESS_FIELDS,
      payload: data
    });
  },
  [types.CHANGE_USER]: () => (data) => changeUser(data),
  [types.SHOW_QUICK_DEPOSIT]: (dispatch) => async (status) => {
    dispatch({
      type: types.SET_QUICK_DEPOSIT,
      payload: status
    });
  },
  [types.CHANGE_LINE_VIEW]: (dispatch) => async (localName, value) => {
    setLocalStorageValue(localName, value);
    logAction(LOG_ACTIONS.SWITCH_SETTINGS, {
      settings: localName,
      value
    });
    dispatch({
      type: types.SET_LINE_VIEW,
      payload: value
    });
  },
  [types.FETCH_BONUS_WALLET]: (dispatch) => async ({ walletHash }) => {
    const data = await socketSend({
      cmd: 'accounting/account/get_prewager_bonus_wallet',
      data: {
        wallet_hash: walletHash
      }
    });
    if (data.ok) {
      const { data: walletData } = data;
      const { amount } = walletData;
      dispatch({
        type: types.SET_BONUS_WALLET,
        payload: {
          deposit: amount
        }
      });
    }
  },
  [types.PHONE_VERIFY]: () => ({ phoneNumber = '' }) =>
    socketSend({
      cmd: 'accounting/user_phone_verify',
      ...(phoneNumber && {
        data: {
          phone: phoneNumber
        }
      })
    }).then(camelizeKeys),
  [types.PHONE_CONFIRM]: (dispatch) => async ({ sessionId, confirmCode }) => {
    const response = await socketSend({
      cmd: 'accounting/user_phone_confirm',
      data: {
        confirm_code: Number(confirmCode),
        session_id: sessionId
      }
    });
    if (!response.error) {
      dispatch({
        type: types.SET_IS_PHONE_VERIFIED,
        payload: true
      });
    }
    return response;
  },
  [types.SET_ACCOUNT_STATUSES]: (dispatch) => (payload) => {
    dispatch({
      type: types.SET_ACCOUNT_STATUSES,
      payload: normalizeAccountStatuses(payload)
    });
  },
  [types.ENABLE_FAST_BET]: (dispatch) => async (value) => {
    const { data, ok } = await socketSend({
      cmd: 'accounting/set_quickbet',
      cid: 'accounting/set_quickbet',
      data: { value }
    });

    if (ok) {
      const { quickbet } = data;

      dispatch({
        type: types.SET_FAST_BET,
        payload: quickbet === 'true' // it should be here because backend response not so good
      });
    }
  }
};
