import { useContext } from 'react';
import { UserContext } from '@namespace/user';
import { DOCUMENT_STATUSES } from '../constants';

export const useStatuses = () => {
  const [user] = useContext(UserContext);
  const { accountStatuses = {} } = user;
  const {
    isPassportInVerification,
    isAdditionalInformationRequired,
    isPassportVerifiedByRisk
  } = accountStatuses;
  const isNotVerified =
    !isPassportInVerification &&
    !isAdditionalInformationRequired &&
    !isPassportVerifiedByRisk;

  const documentStatusFormatStatus = (() => {
    if (isNotVerified) return DOCUMENT_STATUSES.NOT_VERIFIED;
    if (isPassportVerifiedByRisk) return DOCUMENT_STATUSES.VERIFIED;
    if (isPassportInVerification) return DOCUMENT_STATUSES.PENDING;
    if (isAdditionalInformationRequired)
      return DOCUMENT_STATUSES.ADDITIONAL_INFORMATION;

    throw new Error('unhandled type');
  })();

  return {
    ...accountStatuses,
    isNotVerified,
    documentStatusFormatStatus
  };
};
