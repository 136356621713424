import { isEmpty, isNil } from 'lodash';
import { useI18n } from '@namespace/i18n';
import { useOtherSettings } from '@namespace/cms';
import { AText, Box } from '@alf/uikit';
import { useGetNumberOfDecimalPlaces } from '@namespace/common';

import { getCurrencySign, formatNumToAmount } from '../../../../../../utils';
import { useGetInHouseJackpotDateFormatter } from '../../../../../../hooks/useGetInHouseJackpotDateFormatter';
import { useGetInHouseJackpotWinnerInfo } from '../../../../../../jackpotsContext/selectors';
import { useGetInHouseJpGameLaunchHandler } from '../../useGetInHouseJpGameLaunchHandler';

import styles from './index.module.css';
import { JackpotGameInfoBlock } from './JackpotGameInfoBlock';

export const JackpotDetailsInfoColumn = ({ jackpotInfo, className = '' }) => {
  const { t } = useI18n();
  const numberOfDecimalPlaces = useGetNumberOfDecimalPlaces();
  const { isShowInHouseJackpotGamesNameAsLink = false } = useOtherSettings();
  const { maxWinner, lastWinner } = jackpotInfo;

  const {
    gameName: maxWinnerGameName,
    providerName: maxWinnerProviderName,
    gameId: maxWinnerGameId,
    providerId: maxWinnerProviderId
  } = useGetInHouseJackpotWinnerInfo(maxWinner?.gameId);
  const {
    gameName: lastWinnerGameName,
    providerName: lastWinnerProviderName,
    gameId: lastWinnerGameId,
    providerId: lastWinnerProviderId
  } = useGetInHouseJackpotWinnerInfo(lastWinner?.gameId);

  const onLaunchGame = useGetInHouseJpGameLaunchHandler();
  const formatDate = useGetInHouseJackpotDateFormatter();

  return (
    <Box className={`${styles.wrapper} ${className}`} direction="column">
      {!isNil(jackpotInfo.total) && (
        <Box justify="between" className={styles.infoRow}>
          <AText size="m" breed="B" color="accent_color_3">
            {t('games.jackpot.totalWinners')}
          </AText>
          <AText size="m" breed="R" color="txt_color_4">
            {jackpotInfo.total}
          </AText>
        </Box>
      )}
      {jackpotInfo.activationAmount && (
        <>
          <Box className={styles.hr} />
          <Box justify="between" className={styles.infoRow}>
            <AText size="m" breed="B" color="accent_color_3">
              {t('games.jackpot.activationAmount')}
            </AText>
            <Box align="baseline">
              <AText
                size="m"
                breed="R"
                color="txt_color_4"
                className={styles.amount}
              >
                {formatNumToAmount({
                  num: jackpotInfo.convertedActivationAmount,
                  numberOfDecimalPlaces
                })}
              </AText>
              <AText
                size="s"
                breed="R"
                color="txt_color_4"
                className={styles.currencySign}
              >
                {getCurrencySign(jackpotInfo.convertedCurrency)}
              </AText>
            </Box>
          </Box>
        </>
      )}
      {!isEmpty(jackpotInfo.maxWinner) && (
        <>
          <Box className={styles.hr} />
          <Box className={styles.infoRow} direction="column">
            <AText size="m" breed="B" color="accent_color_3">
              {t('games.jackpot.maxWinner')}
            </AText>
            <Box justify="between">
              <AText size="m" breed="RU" color="txt_color_4">
                {t('games.jackpot.id')}
                <span>: </span>
                <span>{jackpotInfo.maxWinner.userId}</span>
              </AText>
              <Box align="baseline">
                <AText
                  size="m"
                  breed="R"
                  color="txt_color_4"
                  className={styles.amount}
                >
                  {formatNumToAmount({
                    num: jackpotInfo.maxWinner.convertedAmount,
                    numberOfDecimalPlaces
                  })}
                </AText>
                <AText
                  size="s"
                  breed="R"
                  color="txt_color_4"
                  className={styles.currencySign}
                >
                  {getCurrencySign(jackpotInfo.convertedCurrency)}
                </AText>
              </Box>
            </Box>
            <AText size="m" breed="R" color="txt_color_4">
              {formatDate(jackpotInfo.maxWinner.reportedAt)}
            </AText>
            <JackpotGameInfoBlock
              onLaunchGame={() => {
                onLaunchGame({
                  gameId: maxWinnerGameId,
                  providerId: maxWinnerProviderId,
                  launchGameId: maxWinner?.gameId
                });
              }}
              winnerProviderName={maxWinnerProviderName}
              winnerGameName={maxWinnerGameName}
              isShowInHouseJackpotGamesNameAsLink={
                isShowInHouseJackpotGamesNameAsLink
              }
            />
          </Box>
        </>
      )}
      {!isEmpty(jackpotInfo.lastWinner) && (
        <>
          <Box className={styles.hr} />
          <Box className={styles.infoRow} direction="column">
            <AText size="m" breed="B" color="accent_color_3">
              {t('games.jackpot.lastWinner')}
            </AText>
            <Box justify="between">
              <AText size="m" breed="RU" color="txt_color_4">
                {t('games.jackpot.id')}
                <span>: </span>
                <span>{jackpotInfo.lastWinner.userId}</span>
              </AText>
              <Box align="baseline">
                <AText
                  size="m"
                  breed="R"
                  color="txt_color_4"
                  className={styles.amount}
                >
                  {formatNumToAmount({
                    num: jackpotInfo.lastWinner.convertedAmount,
                    numberOfDecimalPlaces
                  })}
                </AText>
                <AText
                  size="s"
                  breed="R"
                  color="txt_color_4"
                  className={styles.currencySign}
                >
                  {getCurrencySign(jackpotInfo.convertedCurrency)}
                </AText>
              </Box>
            </Box>
            <AText size="m" breed="R" color="txt_color_4">
              {formatDate(jackpotInfo.lastWinner.reportedAt)}
            </AText>
            <JackpotGameInfoBlock
              onLaunchGame={() => {
                onLaunchGame({
                  gameId: lastWinnerGameId,
                  providerId: lastWinnerProviderId,
                  launchGameId: lastWinner?.gameId
                });
              }}
              winnerProviderName={lastWinnerProviderName}
              winnerGameName={lastWinnerGameName}
              isShowInHouseJackpotGamesNameAsLink={
                isShowInHouseJackpotGamesNameAsLink
              }
            />
          </Box>
        </>
      )}
      {isEmpty(jackpotInfo.maxWinner) && isEmpty(jackpotInfo.lastWinner) && (
        <>
          <Box className={styles.hr} />
          <Box className={styles.infoRow} direction="column">
            <AText size="l" align="center" breed="R" color="txt_color_4">
              {t('games.jackpot.noWinnerYet')}
            </AText>
          </Box>
        </>
      )}
    </Box>
  );
};
