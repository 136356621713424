import { useContext, useMemo } from 'react';
import { pickBy, isEmpty } from 'lodash';
import {
  SECTION_TYPES,
  DEFAULT_JACKPOT_CODE,
  LIVE_RTP_GAMES_SECTION_AMOUNT,
  LOBBY_LIVE_RTP_GAMES_AMOUNT
} from '../../constants';
import { GamesContext } from '../context';

export const useGamesState = () => {
  const [gamesState] = useContext(GamesContext);
  return gamesState;
};

export const useGamesFeed = (feedName) => {
  const [gamesState] = useContext(GamesContext);

  return gamesState.feeds[feedName];
};

export const useProvidersData = () => {
  const [gamesState] = useContext(GamesContext);

  return gamesState.providers.data;
};

export const useGetGame = (gameCode) => {
  const [gamesState] = useContext(GamesContext);

  return gamesState.games.data[gameCode];
};

const selectCategories = ({ ids, data }) => ids.map((id) => data[id]);

export const useIsFeedLoaded = (feedName) => {
  const [gamesState] = useContext(GamesContext);

  return gamesState.feeds[feedName]?.isLoaded;
};

// TODO: replace with useIsFeedLoaded ?
export const useGamesIsLoaded = (feedName, platform) => {
  const [gamesState] = useContext(GamesContext);

  return (
    gamesState.feeds[feedName]?.isLoaded &&
    gamesState.feeds[feedName]?.platform === platform
  );
};

export const useCategories = (feedName) => {
  const [gamesState] = useContext(GamesContext);
  const { categories } = gamesState.feeds[feedName] || {};

  return useMemo(
    () => (isEmpty(categories) ? [] : selectCategories(categories)),
    [categories]
  );
};

export const useCategory = (categoryId, feedName) => {
  const [gamesState] = useContext(GamesContext);
  const { feeds } = gamesState;

  return feeds[feedName]?.categories?.data?.[categoryId] || {};
};

export const getGames = (gamesIds = [], games = {}) =>
  gamesIds.map((id) => games[id]).filter(Boolean);

export const useGetPreparedCategories = (categories) => {
  const [gamesState] = useContext(GamesContext);
  const { games } = gamesState;

  return useMemo(
    () =>
      categories
        .map(
          ({
            id,
            title,
            games: categoryGames,
            instantGames: wideCategoryGames,
            logo,
            logoActive
          }) => {
            return {
              type: SECTION_TYPES.CATEGORY_GENERAL,
              id,
              title,
              logo,
              logoActive,
              categoryGames: getGames(categoryGames, games.data),
              wideCategoryGames: getGames(wideCategoryGames, games.data)
            };
          }
        )
        .filter(({ categoryGames }) => categoryGames.length > 0),
    [categories, games.data]
  );
};

export const useJackpot = (
  providerServiceId = 'general',
  code = DEFAULT_JACKPOT_CODE
) => {
  const [{ jackpots }] = useContext(GamesContext);

  return jackpots[providerServiceId]?.[code];
};

export const useProvider = (providerId) => {
  const [gamesState] = useContext(GamesContext);
  const { providers } = gamesState;

  // TODO: why useMemo ?
  return useMemo(() => providers.data[providerId] || {}, [
    providerId,
    providers
  ]);
};

export const useProviderByServiceId = (serviceId) => {
  const [gamesState] = useContext(GamesContext);
  const {
    providers: { data, serviceIdToProviderId }
  } = gamesState;
  const providerId = serviceIdToProviderId[serviceId];

  return useMemo(() => data[providerId] || {}, [providerId, data]);
};

export const useUnsortedGamesByProvider = (providerId) => {
  const [gamesState] = useContext(GamesContext);
  const { games } = gamesState;

  return useMemo(() => {
    const providerGames = pickBy(
      games.data,
      (game) => game.providerId === providerId
    );

    return providerGames;
  }, [providerId, games]);
};

export const useGetCategoryGames = (categoryId, feedName) => {
  const [gamesState] = useContext(GamesContext);
  const { games, feeds } = gamesState;

  const categoryGames = feeds[feedName]?.categories?.data?.[categoryId];

  return useMemo(() => {
    if (isEmpty(categoryGames)) return [];

    return getGames(categoryGames.games, games.data);
  }, [games.data, categoryGames]);
};

export const useGetIsRecentlyPlayedLoaded = (entityName) => {
  const [gamesState] = useContext(GamesContext);

  return gamesState.recentlyPlayed[entityName]?.isLoaded;
};

export const useGetIsRecommendedGamesLoaded = (entityName) => {
  const [gamesState] = useContext(GamesContext);

  return gamesState.recommendedGames[entityName]?.isLoaded;
};

export const useGetIsWagerSlotGamesLoaded = () => {
  const [gamesState] = useContext(GamesContext);

  return gamesState.dynamicCategories?.wagerSlotGames?.isLoaded;
};

export const useGetIsLiveRtpGamesLoaded = () => {
  const [gamesState] = useContext(GamesContext);

  return gamesState.dynamicCategories?.liveRtpGames?.isLoaded;
};

const mapAndFilterGameLaunchIds = (
  launchIds,
  launchIdToGameId,
  feedGamesIds
) => {
  return launchIds
    .map((gameLaunchId) => launchIdToGameId[gameLaunchId])
    .filter((gameId) => gameId && feedGamesIds.includes(gameId));
};

export const useGetRecommendedGamesIds = (entityName, feedName) => {
  const [gamesState] = useContext(GamesContext);
  const { games, feeds, recommendedGames } = gamesState;
  const gamesFeed = feeds?.[feedName];
  const { launchIdToGameId } = games;

  return useMemo(() => {
    if (!gamesFeed?.isLoaded) return [];

    const recommendedGamesLaunchIds = recommendedGames?.[entityName]?.ids || [];

    return mapAndFilterGameLaunchIds(
      recommendedGamesLaunchIds,
      launchIdToGameId,
      gamesFeed.gamesIds
    );
  }, [entityName, gamesFeed, launchIdToGameId, recommendedGames]);
};

export const useGetRecentlyPlayedGamesIds = (entityName, feedName) => {
  const [gamesState] = useContext(GamesContext);
  const { games, feeds, recentlyPlayed } = gamesState;
  const gamesFeed = feeds?.[feedName];
  const { launchIdToGameId } = games;

  return useMemo(() => {
    if (!gamesFeed?.isLoaded) return [];

    const recentlyPlayedGamesLaunchIds =
      recentlyPlayed?.[entityName]?.ids || [];

    return mapAndFilterGameLaunchIds(
      recentlyPlayedGamesLaunchIds,
      launchIdToGameId,
      gamesFeed.gamesIds
    );
  }, [
    entityName,
    gamesFeed?.gamesIds,
    gamesFeed?.isLoaded,
    launchIdToGameId,
    recentlyPlayed
  ]);
};

export const useGetWagerSlotsGamesIds = (feedName) => {
  const [gamesState] = useContext(GamesContext);
  const { games, feeds, dynamicCategories = {} } = gamesState;
  const gamesFeed = feeds?.[feedName];
  const { wagerSlotGames } = dynamicCategories;
  const { launchIdToGameId } = games;

  return useMemo(() => {
    if (!gamesFeed?.isLoaded || isEmpty(wagerSlotGames)) return [];

    const wagerSlotsGamesLaunchIds = wagerSlotGames?.ids || [];

    return mapAndFilterGameLaunchIds(
      wagerSlotsGamesLaunchIds,
      launchIdToGameId,
      gamesFeed.gamesIds
    );
  }, [gamesFeed, launchIdToGameId, wagerSlotGames]);
};

export const useGetLiveRtpGamesSectionsIds = ({ feedName }) => {
  const [gamesState] = useContext(GamesContext);
  const { games, feeds, dynamicCategories = {} } = gamesState;
  const gamesFeed = feeds?.[feedName];
  const { liveRtpGames } = dynamicCategories;

  return useMemo(() => {
    if (!gamesFeed?.isLoaded || isEmpty(liveRtpGames)) {
      return [];
    }

    return [
      {
        sectionId: 'hot',
        gamesIds: mapAndFilterGameLaunchIds(
          liveRtpGames.hot,
          games.launchIdToGameId,
          gamesFeed.gamesIds
        ).slice(0, LIVE_RTP_GAMES_SECTION_AMOUNT)
      },
      {
        sectionId: 'cold',
        gamesIds: mapAndFilterGameLaunchIds(
          liveRtpGames.cold,
          games.launchIdToGameId,
          gamesFeed.gamesIds
        ).slice(0, LIVE_RTP_GAMES_SECTION_AMOUNT)
      }
    ];
  }, [
    games.launchIdToGameId,
    gamesFeed?.gamesIds,
    gamesFeed?.isLoaded,
    liveRtpGames
  ]);
};
export const useGetLiveRtpMixedGamesIds = ({ feedName }) => {
  const [gamesState] = useContext(GamesContext);
  const { games, feeds, dynamicCategories = {} } = gamesState;
  const gamesFeed = feeds?.[feedName];
  const { liveRtpGames } = dynamicCategories;

  return useMemo(() => {
    if (!gamesFeed?.isLoaded || isEmpty(liveRtpGames)) {
      return [];
    }

    return mapAndFilterGameLaunchIds(
      liveRtpGames.mixed,
      games.launchIdToGameId,
      gamesFeed.gamesIds
    ).slice(0, LOBBY_LIVE_RTP_GAMES_AMOUNT);
  }, [
    games.launchIdToGameId,
    gamesFeed?.gamesIds,
    gamesFeed?.isLoaded,
    liveRtpGames
  ]);
};

export const useGetLiveRtpCategory = () => {
  const [gamesState] = useContext(GamesContext);
  const { dynamicCategories = {} } = gamesState;

  return dynamicCategories.liveRtpGames;
};

export const useGetIsEmptyLiveRtpCategory = (feedName) => {
  const mixedLiveRtpGamesIds = useGetLiveRtpMixedGamesIds({ feedName });
  const liveRtpGamesSectionsIds = useGetLiveRtpGamesSectionsIds({ feedName });
  const isLiveRtpGamesLoaded = useGetIsLiveRtpGamesLoaded();

  if (!isLiveRtpGamesLoaded) return false;

  // the whole category considered empty if any section is empty
  return [
    { gamesIds: mixedLiveRtpGamesIds },
    ...liveRtpGamesSectionsIds
  ].some((section) => isEmpty(section.gamesIds));
};
