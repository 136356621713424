import Cookies from 'js-cookie';

/**
 * Set cookie
 * @param {string} name
 * @param value
 * @param {{
 *   path: string|undefined,
 *   expires: number|undefined
 * }} [settings]
 */
export const setCookie = (name, value, settings) => {
  try {
    Cookies.set(name, value, settings);
  } catch (e) {
    console.warn(e, 'cookies error');
  }
};

export const getCookie = (name, defaultValue) => {
  try {
    return Cookies.get(name) || defaultValue;
  } catch (e) {
    console.warn(e, 'cookies error');
    return null;
  }
};

export const removeCookie = (name, attributes) => {
  try {
    return Cookies.remove(name, attributes);
  } catch (e) {
    console.warn(e, 'cookies error');
    return null;
  }
};
