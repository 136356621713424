import { useCallback, useContext, useState } from 'react';
import { UserContext } from '../../context';

const useUpdatePhoneNumber = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [user, userActions] = useContext(UserContext);
  const { UPDATE_PHONE } = userActions;
  const { id: userId } = user;

  const updatePhone = useCallback(
    (phone) => {
      setIsFetching(true);
      return UPDATE_PHONE({ phone, userId }).finally(() =>
        setIsFetching(false)
      );
    },
    [userId, UPDATE_PHONE, setIsFetching]
  );

  return { isFetching, updatePhone };
};

export default useUpdatePhoneNumber;
