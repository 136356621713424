import { useContext } from 'react';
import clsx from 'clsx';
import { Notification } from '@alf/uikit';
import { useIsNavMenuVisible } from '@namespace/common';
import { DeviceContext } from '@namespace/device';

import useBetSlipVisible from '../../hooks/useBetSlipVisible';

import styles from './index.module.css';

export { MobileMessage } from './MobileMessage/connector';

const VerificationStatusNotification = ({
  mobileMessage,
  message,
  ...props
}) => {
  const { isDesktop } = useContext(DeviceContext);
  const isBetSlipVisible = useBetSlipVisible();
  const navMenuVisible = useIsNavMenuVisible();

  return (
    <Notification
      {...props}
      message={!isDesktop && mobileMessage ? mobileMessage : message}
      isMobileView={!isDesktop}
      className={clsx([
        styles.general,
        !isDesktop && styles.mobileNotification,
        isBetSlipVisible && styles.withBetslip,
        navMenuVisible && styles.navMenuEnabled
      ])}
    />
  );
};

export default VerificationStatusNotification;
