import { useCallback } from 'react';
import { Box, Button, Input } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { Controller } from 'react-hook-form';
import Control from '../../common/Control';
import styles from './index.module.css';

export const VerificationField = ({
  name,
  label = '',
  hint = '',
  classNames = {},
  inputType = 'text',
  dataRole = '',
  placeholder = '',
  buttonText = '',
  onVerifyClick,
  isDisplayVerificationButton,
  disabled
}) => {
  const { t } = useI18n();

  const handleBlur = useCallback(
    (fn) => (e) => {
      const validValue = e.target.value.trim();

      e.target.value = validValue;
      fn?.(e);
    },
    []
  );

  return (
    <Control
      classNames={classNames}
      hint={hint}
      id={name}
      label={t(label)}
      dataRole={dataRole}
    >
      <Box align="center">
        <Controller
          name={name}
          render={({ field: { ref, onBlur, ...field } }) => (
            <Input
              disabled={disabled}
              id={name}
              data-role={dataRole && `${dataRole}-input`}
              type={inputType}
              placeholder={t(placeholder)}
              onBlur={handleBlur(onBlur)}
              {...field}
            />
          )}
        />
        {isDisplayVerificationButton && (
          <Button
            onClick={onVerifyClick}
            intent="primary"
            actionType="color1"
            size="l"
            className={styles.button}
            data-role={`${dataRole}-btn`}
          >
            {t(buttonText)}
          </Button>
        )}
      </Box>
    </Control>
  );
};
