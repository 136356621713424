import { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Icon } from '@namespace/icons2';
import { COLOR_SCHEME } from '@namespace/themes';
import Box from '../../../../../Box';
import AText from '../../../../../AText';
import Input from '../../../../../Input';
import styles from './index.module.css';

const isEmptyValue = (value) => value == null || value === '';

export const SelectTrigger = ({
  value,
  titleOption,
  className = '',
  disabled,
  placeholder,
  isOpen,
  setIsOpen,
  intent = 'default',
  size = 'xs',
  additionSelectStyles = {},
  arrowSize,
  intentType,
  selectType,
  noPlaceholderColor = false,
  hasSearch = false,
  isInlineSearch = true,
  searchValue,
  onSearchValueChange,
  inputRef,
  showDropdownIconWhenOnlyOneOption,
  alwaysShowDropdownIcon,
  options,
  iconDropDownName = 'icons/general/nav/dropdown_down'
}) => {
  useEffect(() => {
    if (searchValue && !isOpen) {
      setIsOpen?.(true);
    }
  }, [isOpen, searchValue, setIsOpen]);

  const isDefaultTitleColor = noPlaceholderColor || !isEmptyValue(value);
  let color = isDefaultTitleColor ? 'input_txt' : 'input_txt_placeholder';
  if (intentType === COLOR_SCHEME.SECONDARY) {
    color = isDefaultTitleColor ? 'input_02_txt' : 'input_02_txt_placeholder';
  }

  const {
    triggerContentClass,
    triggerContentTitleClass,
    arrowColor
  } = additionSelectStyles;

  return (
    <Box
      align="center"
      className={clsx([
        styles.root,
        styles[intentType],
        styles[size],
        styles[intent],
        styles[selectType],
        disabled && styles.disabled,
        isOpen && hasSearch && styles.focusInput,
        className,
        triggerContentClass
      ])}
    >
      {titleOption?.iconElement}
      {hasSearch && isInlineSearch ? (
        <Input
          type="text"
          className={styles.inputCountry}
          intent="ghost"
          inputRef={inputRef}
          onChange={onSearchValueChange}
          value={searchValue}
          placeholder={titleOption?.title || placeholder}
          maxLength={50}
          min={0}
          size={size}
          disabled={disabled}
        />
      ) : (
        <AText
          className={triggerContentTitleClass}
          breed="R"
          size={size === 'm' ? 'l' : 'm'}
          overflow="ellipsis"
          color={titleOption.ntf ? 'input_error_txt' : color}
        >
          {(titleOption.ntf
            ? `${titleOption.title} ${titleOption.ntf}`
            : titleOption.title) || placeholder}
        </AText>
      )}

      {((showDropdownIconWhenOnlyOneOption && options?.length === 1) ||
        options?.length > 1 ||
        alwaysShowDropdownIcon) && (
        <Icon
          name={iconDropDownName}
          className={clsx([
            styles.icon,
            styles[intentType],
            isOpen && styles.openedIcon,
            arrowColor // TODO: make more general props name
          ])}
          size={arrowSize || size}
        />
      )}
    </Box>
  );
};

SelectTrigger.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  placeholder: PropTypes.string,
  intent: PropTypes.oneOf(['error', 'success', 'default']),
  intentType: PropTypes.oneOf([COLOR_SCHEME.PRIMARY, COLOR_SCHEME.SECONDARY]),
  size: PropTypes.oneOf(['xs', 's', 'm']),
  showDropdownIconWhenOnlyOneOption: PropTypes.bool,
  options: PropTypes.array
};
