import { RESULT_TYPE_IDS } from '../../../../../../../constants';

/**
 * @memberOf module:SetScore
 * Build eventResultTotal string for soccer
 * @param {Object.<string, number>} scores
 * @param {ResultTypeData} byResultType
 */
export const buildSoccerTotal = (scores, byResultType) => {
  const firstHalfIdx = byResultType.findIndex(
    ({ resultTypeId }) => resultTypeId === RESULT_TYPE_IDS.HALF_1
  );
  const secondHalfIdx = byResultType.findIndex(
    ({ resultTypeId }) => resultTypeId === RESULT_TYPE_IDS.HALF_2
  );
  const extraTimeIdx = byResultType.findIndex(
    ({ resultTypeId }) => resultTypeId === RESULT_TYPE_IDS.EXTRATIME_1
  );
  const secondExtraTimeIdx = byResultType.findIndex(
    ({ resultTypeId }) => resultTypeId === RESULT_TYPE_IDS.EXTRATIME_2
  );
  const penaltiesIdx = byResultType.findIndex(
    ({ resultTypeId }) => resultTypeId === RESULT_TYPE_IDS.PENALTIES
  );

  const resultsIdxs = [
    firstHalfIdx,
    secondHalfIdx,
    extraTimeIdx,
    secondExtraTimeIdx,
    penaltiesIdx
  ];

  const detailedResult = [
    `${scores.firstParticipantTotalPointsInFT}:${scores.secondParticipantTotalPointsInFT}`,
    `${scores.firstParticipantTotalPointsInST}:${scores.secondParticipantTotalPointsInST}`,
    `${scores.firstParticipantTotalPointsInET}:${scores.secondParticipantTotalPointsInET}`,
    `${scores.firstParticipantTotalPointsInET2}:${scores.secondParticipantTotalPointsInET2}`
  ].filter((_, idx) => resultsIdxs[idx] >= 0);
  let eventResultTotal = `${scores.firstParticipantTotalPoints}:${scores.secondParticipantTotalPoints}`;
  eventResultTotal += ` (${detailedResult.join(',')}) `;

  if (penaltiesIdx !== -1) {
    eventResultTotal += `${scores.firstParticipantTotalPenaltyPoints}:${scores.secondParticipantTotalPenaltyPoints}`;
  }

  return eventResultTotal;
};
