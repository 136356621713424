import { AText, Box, Button } from '@alf/uikit';
import { useGeneralRoutes } from '@namespace/cms';
import { DeviceContext } from '@namespace/device';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { Image } from '@namespace/icons2';
import { HTMLSnippet } from '@namespace/snippets';
import { useContext } from 'react';
import styles from './index.module.css';

const UnsuccessfulDepositCode = ({ code, isNeedSupport, snippetIdHelp }) => {
  const { t } = useI18n();
  const { isDesktop } = useContext(DeviceContext);
  const { deposit } = useGeneralRoutes();
  const defaultError = t('accountingMessages.unsuccessDepositDefaultError');
  return (
    <Box
      direction="column"
      align="center"
      padding="ms m"
      className={styles.wrapper}
    >
      <Box
        padding={isDesktop ? 'l' : 'ms m'}
        align="center"
        direction="column"
        gap="ms"
        className={styles.contentWrapper}
      >
        <Image width={isDesktop ? 88 : 116} name="general/error" />
        <AText color="po_txt_color_1" size="2xl" breed="B">
          {t('web.unsuccess.deposit.title')}
        </AText>
        <AText color="po_txt_color_1" size="m" breed="B">
          {t(`accountingMessages.${code}`, null, defaultError)}
        </AText>
        <LocalizedLink to={deposit}>
          <Button size="l" intent="primary" actionType="color1">
            {t('web.unsuccess.deposit.button')}
          </Button>
        </LocalizedLink>
      </Box>
      {isNeedSupport && snippetIdHelp && (
        <HTMLSnippet snippetId={snippetIdHelp} />
      )}
    </Box>
  );
};

export default UnsuccessfulDepositCode;
