import { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Icon } from '@namespace/icons2';
import Box from '../../../Box';
import AText from '../../../AText';
import styles from './index.module.css';

const Item = ({ item, NavLink, menu, isTop }) => {
  const {
    link,
    title,
    isActive = false,
    isExpanded = false,
    items,
    onClick = () => {}
  } = item;
  const [isMenuOpen, setMenuOpen] = useState(isExpanded);
  const [isActiveItem, setIsActiveItem] = useState(false);
  const isExpandable = useMemo(() => Boolean(items?.length), [items]);

  useEffect(() => {
    // Hack for the item to be active after hydration
    setIsActiveItem(isActive);
  }, [isActive]);

  const className = clsx([
    styles.item,
    !isExpandable && styles.notExpandable,
    isActiveItem && styles.active,
    isTop && styles.top,
    isMenuOpen && styles.expanded
  ]);

  const toggleExpand = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setMenuOpen((prevState) => !prevState);
    },
    [setMenuOpen]
  );

  const itemProps = link ? { component: NavLink, to: link } : { onClick };

  return (
    <Box direction="column" component="li">
      <Box className={className} align="center" padding="s" {...itemProps}>
        {isExpandable && (
          <Icon
            name={`icons/general/nav/${isMenuOpen ? 'arrow-up' : 'arrow-down'}`}
            size="xs"
            className={styles.icon}
            onClick={toggleExpand}
          />
        )}
        <AText
          size={isTop ? 'l' : 'm'}
          breed={isActiveItem || isTop ? 'B' : 'R'}
          className={styles.title}
        >
          {title}
        </AText>
      </Box>
      {isMenuOpen && isExpandable && <Box padding="0 m">{menu}</Box>}
    </Box>
  );
};

export default Item;
