import { asyncAttempt } from '@namespace/helpers';
import { getAccountingBonusesCount } from '../../../bonusesCount';

const makeResponseMap = (data) => (i) => data[i];

const accountingBonusesCountResponse = async ({ types, userId }) => {
  if (!types.length) return [];

  const [error, response] = await asyncAttempt(getAccountingBonusesCount, {
    userId,
    type: types
  });

  if (error) {
    console.error(`An error occurred for bonus types ${types}:`, error);
    return [];
  }

  const result = types.map(makeResponseMap(response));

  return result;
};

export default accountingBonusesCountResponse;
