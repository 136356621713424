import { postAsUriParameters } from '@namespace/helpers';

export const getTotalPages = (filter = {}, platform, route) => {
  const { language, stateList } = filter;
  return postAsUriParameters(
    route,
    {
      state_list: stateList,
      lang: language,
      platform
    },
    true
  )
    .then(({ error, response }) =>
      error === 'no' ? response?.response?.bonusCount || 0 : 0
    )
    .catch(console.error);
};
