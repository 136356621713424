const formatItems = (
  children = [],
  location,
  t,
  iKey = 'i',
  isExpandedByDefault
) =>
  children.map(({ title, link, children: subChildren, ...rest }, i) => {
    const key = `${iKey}-${i}`;
    const items = formatItems(
      subChildren,
      location,
      t,
      key,
      isExpandedByDefault
    );
    const { pathname, hash = '' } = location;
    const isActive =
      link === `${pathname}${hash}` ||
      link === `${pathname.replace(/\/$/, '')}${hash}`;
    const isExpanded = isExpandedByDefault
      ? true
      : items.some(
          ({ isActive: isItemActive, isExpanded: isItemExpanded }) =>
            isItemActive || isItemExpanded
        );
    return {
      iKey: key,
      title: t(title),
      items,
      isActive,
      isExpanded,
      link,
      ...rest
    };
  });

export default formatItems;
