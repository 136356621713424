import { Box, AText } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { useI18n } from '@namespace/i18n';

import styles from './index.module.css';

const Header = ({ toggleChat }) => {
  const { t } = useI18n('support.chat.');
  return (
    <Box
      className={styles.header}
      direction="row"
      justify="between"
      padding="m"
    >
      <Box direction="column">
        <AText breed="B" size="2xl" color="txt_color_4">
          {t('header1')}
        </AText>
        <AText breed="R" size="m" color="txt_color_4">
          {t('header2')}
        </AText>
      </Box>
      <Box>
        <Icon
          name="icons/general/action/close"
          color="txt_color_4"
          onClick={toggleChat}
        />
      </Box>
    </Box>
  );
};

export default Header;
