import { useContext } from 'react';
import { OnboardingContext } from '../../store/context';

// todo apply defaults and overrides automatically? Needs robust memoization to prevent performance hit.
export const useCurrentStep = () => {
  const [state] = useContext(OnboardingContext);
  const { stepIndex, steps } = state;

  return steps[stepIndex] ?? {};
};
