import { useContext, useMemo } from 'react';
import { OutcomeButton } from '@alf/uikit';
import { useBetSlip } from '@namespace/betslip';
import { DeviceContext } from '@namespace/device';
import { useOutcomeCoefChange } from '../../hooks/useOutcomeCoefChange';
import useOutcomeCalculation from '../../hooks/useOutcomeCalculation';
import { useOutcome } from '../../store/selectors/outcome';

const minVisibleCoef = 1.01;

const OutcomeButtonContainer = ({
  outcomeId,
  outcomeTypeId,
  market,
  eventId,
  isShowName,
  isShowShortName,
  lineView
}) => {
  const outcome = useOutcome(outcomeId);

  const { outcomeVisible, outcomeName, outcomeShortName } = outcome;
  const name = useMemo(() => {
    if (isShowName) return outcomeName;
    if (isShowShortName) return outcomeShortName;
    return null;
  }, [isShowName, isShowShortName, outcomeShortName, outcomeName]);
  const { marketSuspend } = market;
  const { placeBet, isSelected, coef } = useBetSlip(outcome, market);
  const outcomeChange = useOutcomeCoefChange(coef);
  const isShowOutcome =
    !marketSuspend && outcomeVisible && Number(coef) >= minVisibleCoef;
  const { isDesktopDevice } = useContext(DeviceContext);
  const outcomeCalculation = useOutcomeCalculation();
  const coefficient = outcomeCalculation(coef);

  const handleBetClick = () => {
    // Disabled after discussion with Ivan Postol
    // !isSelected &&
    //   logAction(LOG_ACTIONS.SELECTED_OUTCOME, {
    //     category: `${event.serviceId === 0 ? 'SPORT' : 'LIVE'} || ${
    //       event.sportName
    //     } || ${event.categoryName} || ${event.tournamentName}`,
    //     ...(detailsPage && { betPlace: 'Event Details Page' }),
    //     tournamentId: event.tournamentId,
    //     matchId: event.eventId,
    //     outcome: coef
    //   });
    placeBet();
  };

  return (
    <OutcomeButton
      coefficient={coefficient}
      name={name}
      isShowOutcome={isShowOutcome}
      outcomeChange={outcomeChange}
      onClick={handleBetClick}
      isSelected={isSelected}
      isDesktopDevice={isDesktopDevice}
      lineView={lineView}
      outcomeTypeId={outcomeTypeId}
      eventId={eventId}
    />
  );
};

export default OutcomeButtonContainer;
