import { useCallback, useContext } from 'react';
import { transformLangToISO, useI18n, useLanguage } from '@namespace/i18n';
import { SiteSettingsContext, useConfig } from '@namespace/cms';
import { DeviceContext } from '@namespace/device';
import { scrollTo } from '@namespace/helpers';
import clsx from 'clsx';
import { Select, SELECT_TYPE } from '@alf/uikit';
import { Flag } from '@namespace/icons2';
import styles from './index.module.css';

const LanguageSelect = ({
  classNames = {},
  isFooter,
  isHeader,
  isCustomFooter,
  id,
  title,
  arrowSize = 's'
}) => {
  const {
    containerClass,
    flagIconClass,
    additionalMobileSelectStyleClass
  } = classNames;
  const { t, language } = useI18n();
  const [settings] = useContext(SiteSettingsContext);
  const { siteConfig = {}, otherSettings = {} } = settings;
  const { languages = [] } = siteConfig;
  const { languageConfig = {} } = otherSettings;
  const {
    showDropdownIconWhenOnlyOneOption = true,
    disableDropdownWhenOnlyOneOption = false
  } = languageConfig;
  const { config = {} } = useConfig(id);
  const { enableRuFlag = false, showFlagIcons = true } = config;

  const [setNewLanguage] = useLanguage();
  const { isDesktop } = useContext(DeviceContext);
  const handleClick = useCallback(
    async (newLanguage) => {
      await setNewLanguage(newLanguage.value);
      scrollTo();
    },
    [setNewLanguage]
  );

  const langArray = languages.map((name) => ({
    iconElement: (
      <Flag
        size="s"
        iso={transformLangToISO(name, enableRuFlag)}
        className={`${styles.flagIcon} ${flagIconClass}`}
      />
    ),
    title: t(`${title || 'languages.short'}.${name}`),
    value: name
  }));

  const languageClassNames = {
    triggerClass: `${styles.trigger} languageDropdownTrigger`,
    ...classNames
  };

  return (
    <Select
      value={language}
      options={langArray}
      onChange={handleClick}
      className={clsx([
        containerClass,
        styles.container,
        isCustomFooter && styles.customFooter
      ])}
      size="xs"
      arrowSize={arrowSize}
      additionalMobileSelectStyle={clsx([
        additionalMobileSelectStyleClass,
        styles.mobileSelect
      ])}
      additionSelectContentStyle={clsx([
        styles.contentStyle,
        isHeader && styles.header
      ])}
      additionSelectTitleStyle={clsx([
        styles.contentTitle,
        isHeader && styles.header
      ])}
      additionalPopoverStyle={clsx([isFooter && styles.popover])}
      additionSelectStyles={languageClassNames}
      additionSelectOptionStyle={{
        active: `${styles.selectOptionActive} ${isHeader && styles.header}`,
        item: `${styles.selectOption} ${isHeader && styles.header}`
      }}
      isDesktop={isDesktop}
      hasIcon={showFlagIcons}
      hasCheck={false}
      selectType={SELECT_TYPE.ADDITIONAL}
      showDropdownIconWhenOnlyOneOption={showDropdownIconWhenOnlyOneOption}
      disableDropdownWhenOnlyOneOption={disableDropdownWhenOnlyOneOption}
      isDisabledBottomElement={true}
    />
  );
};

export default LanguageSelect;
