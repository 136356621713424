import { RedirectComponent, ThemesSwitcher } from '@namespace/common';

import { AssistantPage } from '@namespace/assistant';

import { MobileAppBanner, NativeAppsLink } from '@namespace/native-app';

import {
  BackHeader,
  CyberSportBackHeader,
  CyberSportBreadCrumbsMicrodata,
  CyberSportCategoriesMenu,
  CyberSportFavoriteLiveEvents,
  CyberSportFavoritePreMatchEvents,
  CyberSportLiveCategoryEvents,
  CyberSportMenu,
  CyberSportPopularBetsEvents,
  CyberSportPopularBetsEventsCards,
  CyberSportPreMatchCategoryEvents,
  CyberSportPreMatchTournamentEvents,
  CyberSportPreMatchTournamentEventsExtended,
  CyberSportsMicrodata,
  CyberSportSlider,
  GoToTournamentButton,
  LiveAllEvents,
  LiveBreadCrumbsMicrodata,
  LiveCalendar,
  LiveEvents,
  LiveEventsButton,
  LiveFavoriteEvents,
  LiveFavoritesButton,
  LiveOneEvent,
  LiveSportsMenu,
  LiveSportsMenuSlider,
  PopularSports,
  PreMatchBreadCrumbsMicrodata,
  PreMatchCategoriesMenu,
  PreMatchCategoriesTournamentsMenu,
  PreMatchCategoryEvents,
  PreMatchCategoryEventsExtended,
  PreMatchEvents,
  PreMatchFavoriteEvents,
  PreMatchFavoritesButton,
  PreMatchFilteredEventsByConfig,
  PreMatchFilteredEventsByParams,
  PreMatchFilterList,
  PreMatchOneEvent,
  PreMatchPopularBetsEvents,
  PreMatchPopularBetsEventsCards,
  PreMatchSportsMenu,
  PreMatchSportsMenuSlider,
  PreMatchTournamentEvents,
  PreMatchTournamentEventsExtended,
  SearchBackHeader,
  SportsBookSearch,
  SportsMenuByConfig,
  SportsMicrodata,
  SportsRedirect,
  Teasers,
  TeasersVersion2,
  TopEvents,
  TopEventsMenu,
  TopEventsMenu2,
  TopEventsMicrodata,
  TopLiveEvents,
  SportsLobbyHeader,
  ServicesListWidget,
  CustomFilterEvents
} from '@namespace/sports';
import {
  VirtualSportEvent,
  VirtualSportLauncher,
  VirtualSportLobbyMenu,
  VirtualSportsLobby,
  VirtualSportsSubMenu,
  VsGamesListWidget
} from '@namespace/virtual-sports';
import { SportsResults, VirtualSportsResults } from '@namespace/results';
import {
  AddBetsFromExternal,
  AddMultiBetsFromExternal,
  AllBets,
  BetDetails,
  BetDetails2,
  BetsHistory,
  CasinoDetails,
  CasinoHistory,
  ExpressDayLink,
  KenoDetails,
  KenoHistory,
  MultipleOfTheDay,
  MyBets,
  SystemBetCalculator
} from '@namespace/bets';
import { BetSlip, BetSlipGlobal, BetSlipLocal } from '@namespace/betslip';
import {
  ChangePassword,
  ChangePhoneNumber,
  EmailVerificationError,
  EmailVerificationSuccess,
  Info,
  LogOnTimer,
  MailInbox,
  MailMessageDetails,
  MailNewMessage,
  MailOutbox,
  PageBack,
  PageSubmenu,
  PageTitle,
  PersonalOfficeMenu,
  ProfileBankAccountDetails,
  ProfileBankAccounts,
  ProfileDocuments,
  ProfileLoginHistory,
  ProfileReadonlyDocuments,
  ProfileRequisites,
  UserNotifications,
  VerificationDocuments,
  VerificationSuccessUpload
} from '@namespace/account';
import {
  ClubPlayerData,
  ClubPurchaseHistory,
  ClubScoreHistory,
  Shop,
  ShopOrder,
  ShopOrderSuccess
} from '@namespace/loyalty';
import {
  FreeSpinBonusDetails,
  FreeSpinBonusesHistoryList,
  FreeSpinBonusesList,
  FundsBonusDetails,
  FundsBonusesHistoryList,
  FundsBonusesList,
  JackpotBonusesList,
  PromoCode,
  RiskFreeBonusDetails,
  RiskFreeBonusesHistoryList,
  RiskFreeBonusesList
} from '@namespace/bonuses';
import {
  Limit,
  RealityCheck,
  ResponsibleGambling,
  SelfExclusion
} from '@namespace/responsible-gambling';
import {
  Promotion,
  Promotions,
  RecentlyCompletedPromotions
} from '@namespace/promotions';
import {
  CasinoBreadCrumbsMicrodata,
  CasinoLaunchRedirect,
  GameContainer,
  GameLauncher,
  GamePlay,
  Games,
  GamesListWidget,
  InstantGame,
  JackpotInHouseInBanner,
  ProviderGames,
  CasinoGamesSearch
} from '@namespace/games';
import { HtmlContent, HTMLSegmentedSnippet } from '@namespace/snippets';
import {
  EmailUnsubscribe,
  FastLogin,
  LoginButton,
  LoginStepper,
  PasswordRecovery,
  Register,
  RegisterButton,
  RegisterSuccess
} from '@namespace/auth';
import {
  CreateWallet,
  Deposit,
  DepositResult,
  DepositTransactions,
  QuickDeposit,
  SuccessfulDeposit,
  UnsuccessfulDeposit,
  Wallets,
  Withdraw,
  WithdrawalTransactions,
  WithdrawBonusConfirmation
} from '@namespace/payments';
import {
  Deposit as DepositSw,
  Withdraw as WithdrawSw,
  DepositButton,
  QuickDepositButton
} from '@namespace/payments-sw';
import {
  PredictionsBlock,
  PredictionsFooter,
  PredictionsInfoBlock,
  ResultsTournamentBracket,
  TournamentBracket
} from '@namespace/predictions';
import { COMPONENTS_TYPES } from '@namespace/cms';

import { CookieManagement } from '@namespace/cookie-management';
import Banner from '../Banner';
import ContentPageMenu from '../ContentPageMenu';
import FooterMenu from '../FooterMenu';
import Footer from '../Footer';
import HeaderWrapper from '../Header';
import Logo from '../../common/Logo';
import Scroller from '../Scroller';
import SubMenu from '../SubMenu';
import Clock from '../../common/Clock';
import FooterClock from '../../common/FooterClock';
import BurgerMenu from '../BurgerMenu';
import BottomNavigationMenu from '../BottomNavigationMenu';
import FullScreenPopup from '../FullScreenPopup';
import LobbyMenu from '../LobbyMenu';
import Settings from '../Settings';

import NavigationOldVersion from '../Navigation/Item/NavigationOldVersion';
import User from '../User';
import { TabsMenu } from '../TabsMenu';

import NavigationGoToOld from '../Navigation/Item/NavigationGoToOld';
import Language from '../../common/Language';
import Navigation from '../Navigation';
import ColorSchemeSwitcher from '../ColorSchemeSwitcher';
import BackToTop from '../../common/BackToTop';

export const pageComponentsMap = {
  [COMPONENTS_TYPES.PRE_MATCH_SPORTS_MENU]: PreMatchSportsMenu,
  [COMPONENTS_TYPES.PRE_MATCH_FILTER_MENU]: PreMatchFilterList,
  [COMPONENTS_TYPES.PRE_MATCH_SPORTS_CATEGORIES_MENU]: PreMatchCategoriesMenu,
  [COMPONENTS_TYPES.PRE_MATCH_CATEGORIES_TOURNAMENTS_MENU]: PreMatchCategoriesTournamentsMenu,
  [COMPONENTS_TYPES.PRE_MATCH_SPORTS_MENU_SLIDER]: PreMatchSportsMenuSlider,
  [COMPONENTS_TYPES.SPORTS_MENU_BY_CONFIG]: SportsMenuByConfig,
  [COMPONENTS_TYPES.LIVE_SPORTS_MENU]: LiveSportsMenu,
  [COMPONENTS_TYPES.LIVE_SPORTS_MENU_SLIDER]: LiveSportsMenuSlider,
  [COMPONENTS_TYPES.SPORTS_BOOK_SEARCH]: SportsBookSearch,
  [COMPONENTS_TYPES.SPORTS_BOOK_TOP_EVENTS_MENU]: TopEventsMenu,
  [COMPONENTS_TYPES.SPORTS_BOOK_TOP_EVENTS_MENU_2]: TopEventsMenu2,
  [COMPONENTS_TYPES.SEARCH_BACK_HEADER]: SearchBackHeader,
  [COMPONENTS_TYPES.CYBER_SPORT_CATEGORIES_MENU]: CyberSportCategoriesMenu,
  [COMPONENTS_TYPES.CYBER_SPORT_MENU]: CyberSportMenu,
  [COMPONENTS_TYPES.SPORTS_BOOK_REDIRECT]: SportsRedirect,
  [COMPONENTS_TYPES.CYBER_SPORTS_MICRODATA]: CyberSportsMicrodata,
  [COMPONENTS_TYPES.SPORTS_MICRODATA]: SportsMicrodata,
  [COMPONENTS_TYPES.TOP_EVENTS_MICRODATA]: TopEventsMicrodata,
  [COMPONENTS_TYPES.CYBER_SPORT_SLIDER]: CyberSportSlider,

  [COMPONENTS_TYPES.LIVE_EVENTS_BUTTON]: LiveEventsButton,
  [COMPONENTS_TYPES.GO_TO_TOURNAMENT_BUTTON]: GoToTournamentButton,
  [COMPONENTS_TYPES.LIVE_FAVORITES_BUTTON]: LiveFavoritesButton,
  [COMPONENTS_TYPES.PRE_MATCH_FAVORITES_BUTTON]: PreMatchFavoritesButton,

  // OneEvent
  [COMPONENTS_TYPES.PRE_MATCH_ONE_EVENT]: PreMatchOneEvent,
  [COMPONENTS_TYPES.LIVE_ONE_EVENT]: LiveOneEvent,
  //

  // Lines
  [COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_EVENTS]: PreMatchEvents,
  [COMPONENTS_TYPES.SPORTS_BOOK_CUSTOM_FILTER_EVENTS]: CustomFilterEvents,
  [COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_FAVORITE_EVENTS]: PreMatchFavoriteEvents,
  [COMPONENTS_TYPES.SPORTS_BOOK_LIVE_FAVORITE_EVENTS]: LiveFavoriteEvents,
  [COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_FILTERED_EVENTS_BY_PARAMS]: PreMatchFilteredEventsByParams,
  [COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_FILTERED_EVENTS_BY_CONFIG]: PreMatchFilteredEventsByConfig,
  [COMPONENTS_TYPES.SPORTS_BOOK_LIVE_EVENTS]: LiveEvents,
  [COMPONENTS_TYPES.SPORTS_BOOK_POPULAR_BETS_EVENTS]: PreMatchPopularBetsEvents,
  [COMPONENTS_TYPES.SPORTS_BOOK_CARDS_POPULAR_BETS_EVENTS]: PreMatchPopularBetsEventsCards,
  [COMPONENTS_TYPES.CYBER_SPORT_CARDS_POPULAR_BETS_EVENTS]: CyberSportPopularBetsEventsCards,
  [COMPONENTS_TYPES.SPORTS_BOOK_TOP_EVENTS]: TopEvents,
  [COMPONENTS_TYPES.SPORTS_BOOK_TOP_EVENTS_2]: TopEvents,
  [COMPONENTS_TYPES.SPORTS_BOOK_TOP_LIVE_EVENTS]: TopLiveEvents,
  [COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_CATEGORY_EVENTS]: PreMatchCategoryEvents,
  [COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_TOURNAMENT_EVENTS]: PreMatchTournamentEvents,
  [COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_CATEGORY_EVENTS_EXTENDED]: PreMatchCategoryEventsExtended,
  [COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_TOURNAMENT_EVENTS_EXTENDED]: PreMatchTournamentEventsExtended,
  [COMPONENTS_TYPES.SPORTS_BOOK_LIVE_ALL_EVENTS]: LiveAllEvents,
  [COMPONENTS_TYPES.SPORTS_BOOK_LIVE_CALENDAR]: LiveCalendar,
  [COMPONENTS_TYPES.CYBER_SPORT_LIVE_CATEGORIES_EVENTS]: CyberSportLiveCategoryEvents,
  [COMPONENTS_TYPES.CYBER_SPORT_POPULAR_BETS_EVENTS]: CyberSportPopularBetsEvents,
  [COMPONENTS_TYPES.CYBER_SPORT_PRE_MATCH_CATEGORY_EVENTS]: CyberSportPreMatchCategoryEvents,
  [COMPONENTS_TYPES.CYBER_SPORT_PRE_MATCH_TOURNAMENT_EVENTS]: CyberSportPreMatchTournamentEvents,
  [COMPONENTS_TYPES.CYBER_SPORT_PRE_MATCH_TOURNAMENT_EVENTS_EXTENDED]: CyberSportPreMatchTournamentEventsExtended,
  [COMPONENTS_TYPES.CYBER_SPORT_PRE_MATCH_FAVORITE_EVENTS]: CyberSportFavoritePreMatchEvents,
  [COMPONENTS_TYPES.CYBER_SPORT_LIVE_FAVORITE_EVENTS]: CyberSportFavoriteLiveEvents,
  [COMPONENTS_TYPES.SPORTS_BOOK_TEASERS]: Teasers,
  [COMPONENTS_TYPES.TEASERS]: TeasersVersion2,
  [COMPONENTS_TYPES.EXPRESS_DAY_LINK]: ExpressDayLink,
  [COMPONENTS_TYPES.BACK_HEADER]: BackHeader,
  [COMPONENTS_TYPES.CYBER_SPORT_BACK_HEADER]: CyberSportBackHeader,
  [COMPONENTS_TYPES.SPORTS_LOBBY_HEADER]: SportsLobbyHeader,
  [COMPONENTS_TYPES.POPULAR_SPORTS]: PopularSports,

  [COMPONENTS_TYPES.SUB_MENU]: SubMenu,
  [COMPONENTS_TYPES.TABS_MENU]: TabsMenu,
  [COMPONENTS_TYPES.BET_SLIP]: BetSlip,
  [COMPONENTS_TYPES.BET_SLIP_GLOBAL]: BetSlipGlobal,
  [COMPONENTS_TYPES.BET_SLIP_LOCAL]: BetSlipLocal,
  [COMPONENTS_TYPES.MY_BETS]: MyBets,
  [COMPONENTS_TYPES.MULTIPLE_OF_THE_DAY]: MultipleOfTheDay,
  [COMPONENTS_TYPES.BETS_FROM_EXTERNAL]: AddBetsFromExternal,
  [COMPONENTS_TYPES.MULTI_BETS_FROM_EXTERNAL]: AddMultiBetsFromExternal,

  [COMPONENTS_TYPES.BETS_SPORT_HISTORY]: AllBets,
  [COMPONENTS_TYPES.BETS_SPORT_HISTORY_NEW]: BetsHistory,
  [COMPONENTS_TYPES.BETS_CASINO_HISTORY]: CasinoHistory,
  [COMPONENTS_TYPES.BETS_KENO_HISTORY]: KenoHistory,

  [COMPONENTS_TYPES.SPORTS_RESULTS]: SportsResults,
  [COMPONENTS_TYPES.VIRTUAL_SPORTS_RESULTS]: VirtualSportsResults,
  [COMPONENTS_TYPES.CHANGE_PASSWORD]: ChangePassword,
  [COMPONENTS_TYPES.CHANGE_PHONE_NUMBER]: ChangePhoneNumber,

  [COMPONENTS_TYPES.LOGIN_STEPPER]: LoginStepper,
  [COMPONENTS_TYPES.REGISTER]: Register,
  [COMPONENTS_TYPES.REGISTER_SUCCESS]: RegisterSuccess,
  [COMPONENTS_TYPES.PASSWORD_RECOVERY]: PasswordRecovery,
  [COMPONENTS_TYPES.EMAIL_UNSUBSCRIBE]: EmailUnsubscribe,
  [COMPONENTS_TYPES.EMAIL_VERIFICATION_SUCCESS]: EmailVerificationSuccess,
  [COMPONENTS_TYPES.EMAIL_VERIFICATION_ERROR]: EmailVerificationError,

  [COMPONENTS_TYPES.HTML_SNIPPET]: HtmlContent,
  [COMPONENTS_TYPES.HTML_SEGMENTED_SNIPPET]: HTMLSegmentedSnippet,
  [COMPONENTS_TYPES.CONTENT_PAGE_MENU]: ContentPageMenu,

  [COMPONENTS_TYPES.BANNER]: Banner,
  [COMPONENTS_TYPES.SCROLLER]: Scroller,

  [COMPONENTS_TYPES.CASINO_GAMES]: Games,
  [COMPONENTS_TYPES.GAME_PLAY]: GamePlay,
  [COMPONENTS_TYPES.PROVIDER_GAMES]: ProviderGames,
  [COMPONENTS_TYPES.CASINO_GAME_CONTAINER]: GameContainer,
  [COMPONENTS_TYPES.GAME_LAUNCHER]: GameLauncher,
  [COMPONENTS_TYPES.CASINO_LAUNCH_REDIRECT]: CasinoLaunchRedirect,
  [COMPONENTS_TYPES.INSTANT_GAME_LAUNCHER]: InstantGame,
  [COMPONENTS_TYPES.CASINO_BREAD_CRUMBS_MICRODATA]: CasinoBreadCrumbsMicrodata,
  [COMPONENTS_TYPES.GAMES_LIST_WIDGET]: GamesListWidget,
  [COMPONENTS_TYPES.CASINO_GAMES_SEARCH]: CasinoGamesSearch,

  [COMPONENTS_TYPES.VIRTUAL_SPORTS_LOBBY]: VirtualSportsLobby,
  [COMPONENTS_TYPES.VIRTUAL_SPORT_EVENT]: VirtualSportEvent,
  [COMPONENTS_TYPES.VIRTUAL_SPORT_LOBBY_MENU]: VirtualSportLobbyMenu,
  [COMPONENTS_TYPES.VIRTUAL_SPORTS_SUB_MENU]: VirtualSportsSubMenu,
  [COMPONENTS_TYPES.VIRTUAL_SPORT_LAUNCHER]: VirtualSportLauncher,
  [COMPONENTS_TYPES.VIRTUAL_SPORTS_GAMES_LIST_WIDGET]: VsGamesListWidget,

  [COMPONENTS_TYPES.PERSONAL_OFFICE_MENU]: PersonalOfficeMenu,

  [COMPONENTS_TYPES.PAGE_TITLE]: PageTitle,
  [COMPONENTS_TYPES.PAGE_SUBMENU]: PageSubmenu,
  [COMPONENTS_TYPES.PAGE_BACK]: PageBack,

  [COMPONENTS_TYPES.REDIRECT]: RedirectComponent,
  [COMPONENTS_TYPES.PROFILE_DOCUMENTS]: ProfileDocuments,
  [COMPONENTS_TYPES.PROFILE_REQUISITES]: ProfileRequisites,
  [COMPONENTS_TYPES.PROFILE_READONLY_DOCUMENTS]: ProfileReadonlyDocuments,
  [COMPONENTS_TYPES.VERIFICATION_DOCUMENTS]: VerificationDocuments,
  [COMPONENTS_TYPES.VERIFICATION_SUCCESS_UPLOAD]: VerificationSuccessUpload,
  [COMPONENTS_TYPES.PROFILE_BANK_ACCOUNTS]: ProfileBankAccounts,
  [COMPONENTS_TYPES.PROFILE_BANK_ACCOUNT_DETAILS]: ProfileBankAccountDetails,
  [COMPONENTS_TYPES.PROFILE_LOGIN_HISTORY]: ProfileLoginHistory,
  [COMPONENTS_TYPES.USER_NOTIFICATIONS]: UserNotifications,
  [COMPONENTS_TYPES.WALLETS]: Wallets,
  [COMPONENTS_TYPES.CREATE_WALLET]: CreateWallet,
  [COMPONENTS_TYPES.DEPOSIT_TRANSACTIONS]: DepositTransactions,
  [COMPONENTS_TYPES.WITHDRAWAL_TRANSACTIONS]: WithdrawalTransactions,
  [COMPONENTS_TYPES.DEPOSIT]: Deposit,
  [COMPONENTS_TYPES.WITHDRAW]: Withdraw,
  [COMPONENTS_TYPES.SUCCESSFUL_DEPOSIT]: SuccessfulDeposit,
  [COMPONENTS_TYPES.UNSUCCESSFUL_DEPOSIT]: UnsuccessfulDeposit,
  [COMPONENTS_TYPES.SW_DEPOSIT]: DepositSw,
  [COMPONENTS_TYPES.SW_WITHDRAW]: WithdrawSw,

  [COMPONENTS_TYPES.KENO_DETAILS]: KenoDetails,
  [COMPONENTS_TYPES.BETS_DETAILS]: BetDetails,
  [COMPONENTS_TYPES.BETS_DETAILS2]: BetDetails2,
  [COMPONENTS_TYPES.CASINO_DETAILS]: CasinoDetails,
  [COMPONENTS_TYPES.CLUB_PLAYER_DATA]: ClubPlayerData,
  [COMPONENTS_TYPES.CLUB_PURCHASE_HISTORY]: ClubPurchaseHistory,
  [COMPONENTS_TYPES.CLUB_SCORE_HISTORY]: ClubScoreHistory,
  [COMPONENTS_TYPES.FREE_SPIN_BONUS_DETAILS]: FreeSpinBonusDetails,
  [COMPONENTS_TYPES.FREE_SPIN_BONUSES_LIST]: FreeSpinBonusesList,
  [COMPONENTS_TYPES.FREE_SPIN_BONUSES_HISTORY_LIST]: FreeSpinBonusesHistoryList,
  [COMPONENTS_TYPES.RISK_FREE_BONUSES_LIST]: RiskFreeBonusesList,
  [COMPONENTS_TYPES.RISK_FREE_BONUSES_HISTORY_LIST]: RiskFreeBonusesHistoryList,
  [COMPONENTS_TYPES.RISK_FREE_BONUS_DETAILS]: RiskFreeBonusDetails,
  [COMPONENTS_TYPES.FUNDS_BONUSES_LIST]: FundsBonusesList,
  [COMPONENTS_TYPES.FUNDS_BONUSES_HISTORY_LIST]: FundsBonusesHistoryList,
  [COMPONENTS_TYPES.FUNDS_BONUS_DETAILS]: FundsBonusDetails,
  [COMPONENTS_TYPES.BONUS_PROMO_CODE]: PromoCode,
  [COMPONENTS_TYPES.JACKPOT_BONUSES_LIST]: JackpotBonusesList,
  [COMPONENTS_TYPES.LOG_ON_TIMER]: LogOnTimer,

  [COMPONENTS_TYPES.SHOP]: Shop,
  [COMPONENTS_TYPES.SHOP_ORDER]: ShopOrder,
  [COMPONENTS_TYPES.SHOP_ORDER_SUCCESS]: ShopOrderSuccess,

  [COMPONENTS_TYPES.RESPONSIBLE_GAMBLING]: ResponsibleGambling,

  [COMPONENTS_TYPES.SELF_EXCLUSION]: SelfExclusion,

  [COMPONENTS_TYPES.LIMIT]: Limit,

  [COMPONENTS_TYPES.REALITY_CHECK]: RealityCheck,

  [COMPONENTS_TYPES.MESSAGES_INBOX]: MailInbox,
  [COMPONENTS_TYPES.MESSAGES_SENT]: MailOutbox,
  [COMPONENTS_TYPES.SEND_MESSAGE]: MailNewMessage,
  [COMPONENTS_TYPES.MESSAGE_PREVIEW]: MailMessageDetails,
  [COMPONENTS_TYPES.INFO_PAGE]: Info,

  [COMPONENTS_TYPES.MOBILE_APP_BANNER]: MobileAppBanner,

  [COMPONENTS_TYPES.HEADER]: HeaderWrapper,
  [COMPONENTS_TYPES.FOOTER]: Footer,
  [COMPONENTS_TYPES.BOTTOM_NAVIGATION_MENU]: BottomNavigationMenu,
  [COMPONENTS_TYPES.FULL_SCREEN_POPUP]: FullScreenPopup,
  [COMPONENTS_TYPES.LOBBY_MENU]: LobbyMenu,

  [COMPONENTS_TYPES.PROMOTIONS]: Promotions,
  [COMPONENTS_TYPES.PROMOTION]: Promotion,
  [COMPONENTS_TYPES.RECENTLY_COMPLETED_PROMOTIONS]: RecentlyCompletedPromotions,

  [COMPONENTS_TYPES.BURGER]: BurgerMenu,
  [COMPONENTS_TYPES.PRE_MATCH_BREADCRUMBS_MICRODATA]: PreMatchBreadCrumbsMicrodata,
  [COMPONENTS_TYPES.LIVE_BREADCRUMBS_MICRODATA]: LiveBreadCrumbsMicrodata,
  [COMPONENTS_TYPES.CYBER_SPORT_BREADCRUMBS_MICRODATA]: CyberSportBreadCrumbsMicrodata,
  [COMPONENTS_TYPES.LOGO]: Logo,
  [COMPONENTS_TYPES.LANGUAGE]: Language,
  [COMPONENTS_TYPES.THEMES_SWITCHER]: ThemesSwitcher,
  [COMPONENTS_TYPES.CLOCK]: Clock,
  [COMPONENTS_TYPES.FOOTER_CLOCK]: FooterClock,
  [COMPONENTS_TYPES.LOGIN_BUTTON]: LoginButton,
  [COMPONENTS_TYPES.REGISTER_BUTTON]: RegisterButton,
  [COMPONENTS_TYPES.DEPOSIT_BUTTON]: DepositButton,
  [COMPONENTS_TYPES.QUICK_DEPOSIT_BUTTON]: QuickDepositButton,
  [COMPONENTS_TYPES.QUICK_DEPOSIT]: QuickDeposit,
  [COMPONENTS_TYPES.FAST_LOGIN]: FastLogin,
  [COMPONENTS_TYPES.USER_BLOCK]: User,
  [COMPONENTS_TYPES.NAVIGATION]: Navigation,
  [COMPONENTS_TYPES.NAVIGATION_OLD_VERSION]: NavigationOldVersion,
  [COMPONENTS_TYPES.NAVIGATION_GO_TO_OLD]: NavigationGoToOld,
  [COMPONENTS_TYPES.FOOTER_MENU]: FooterMenu,
  [COMPONENTS_TYPES.NATIVE_APPS_LINK]: NativeAppsLink,

  [COMPONENTS_TYPES.PREDICTIONS_BLOCK]: PredictionsBlock,
  [COMPONENTS_TYPES.PREDICTIONS_INFO_BLOCK]: PredictionsInfoBlock,
  [COMPONENTS_TYPES.PREDICTIONS_FOOTER]: PredictionsFooter,
  [COMPONENTS_TYPES.TOURNAMENT_BRACKET]: TournamentBracket,
  [COMPONENTS_TYPES.TOURNAMENT_RESULTS_BRACKET]: ResultsTournamentBracket,

  [COMPONENTS_TYPES.DEPOSIT_RESULT]: DepositResult,
  [COMPONENTS_TYPES.ASSISTANT]: AssistantPage,
  [COMPONENTS_TYPES.WITHDRAW_BONUS_CONFIRMATION]: WithdrawBonusConfirmation,
  [COMPONENTS_TYPES.COLOR_SCHEME_SWITCHER]: ColorSchemeSwitcher,
  [COMPONENTS_TYPES.BACK_TO_TOP]: BackToTop,

  [COMPONENTS_TYPES.SYSTEM_BET_CALCULATOR]: SystemBetCalculator,

  [COMPONENTS_TYPES.CASINO_IN_HOUSE_JACKPOT_IN_BANNER]: JackpotInHouseInBanner,
  [COMPONENTS_TYPES.COOKIE_MANAGEMENT]: CookieManagement,

  [COMPONENTS_TYPES.SERVICES_LIST_WIDGET]: ServicesListWidget,
  [COMPONENTS_TYPES.SETTINGS]: Settings
};
