import { useFormContext } from 'react-hook-form';
import { compareValidator } from '@alf/validators';
import InputField from '../../common/InputField';

const RepeatPasswordField = ({ name, rules = {}, ...props }) => {
  const { watch } = useFormContext();

  return (
    <InputField
      name={name}
      rules={{
        ...rules,
        ...compareValidator(
          // todo recheck why 'newPassword' is hardcoded
          () => watch('newPassword', null),
          'personalOffice.profile.changePassword.comparePasswords'
        )
      }}
      {...props}
    />
  );
};

export default RepeatPasswordField;
