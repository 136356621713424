import { get } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

const getStream = ({
  categoryId,
  partnerId,
  request,
  protocol,
  virtualClient
}) =>
  get(
    `/favtv/api?client=${virtualClient ||
      'virtual'}&virtual_id=${categoryId}&partner_id=${partnerId}&version=0.09s&request=${request}&protocol=${protocol}`
  ).then(camelizeKeys);

export default getStream;
