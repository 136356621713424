import { post } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

const addUserToBonusModel = ({ userId, bonusModelId }) =>
  post(
    '/service/crm_proxy/crm_api/gamegate/addbonusmodelparticipant',
    {
      user_id: String(userId),
      bonus_model_id: bonusModelId
    },
    { 'Content-Type': 'application/json' },
    'include',
    'same-origin'
  ).then(camelizeKeys);

export default addUserToBonusModel;
