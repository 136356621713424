import { postRPC } from '@namespace/helpers';

export const getHash = (serviceId = 0) =>
  postRPC('frontend/partner_config/get', {
    by: {
      service_id: {
        $in: [serviceId]
      }
    },
    fields: ['hash']
  });
