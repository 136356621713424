import { useContext } from 'react';
import { useI18n, useLocation } from '@namespace/i18n';
import { PageContext } from '@namespace/cms';

const usePageMetaData = (replacers) => {
  const { page, seoConfig = {} } = useContext(PageContext);
  const { pathname } = useLocation();
  const { t } = useI18n();
  const { pageConfig = {} } = page;
  const {
    metaDescription: seoDescription,
    pageTitle: seoPageTitle,
    robots: seoRobots,
    canonicalTag: seoCanonical
  } = seoConfig;
  const {
    canonicalTag: pageConfigCanonical = null,
    robots: pageConfigRobots = null,
    metaDescription: pageConfigDescription,
    pageTitleTranslation: pageConfigTitleTranslation,
    pageTitle: pageConfigTitle
  } = pageConfig;

  const title =
    t(seoPageTitle || pageConfigTitleTranslation, replacers, pageConfigTitle) ||
    pathname;
  const description = t(seoDescription || pageConfigDescription, replacers, '');
  const robots = seoRobots || pageConfigRobots;
  const canonical = seoCanonical || pageConfigCanonical;
  return { title, description, robots, canonical };
};

export default usePageMetaData;
