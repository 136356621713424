import { useContext, useMemo } from 'react';
import {
  SubMenu as CommonSubMenu,
  UserSettings,
  useUserSettings
} from '@namespace/common';
import { useUserSegmentsIncludes } from '@namespace/segments';
import { SiteSettingsContext, useConfig } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';

const SubMenu = ({ id }) => {
  const { t } = useI18n();
  const { config } = useConfig(id);
  const {
    mainMenu = [],
    secondMenu = [],
    disableUserSettings = false
  } = config;
  const [settings] = useContext(SiteSettingsContext);
  const { menuTags = {} } = settings;
  const {
    showTimeZoneSelector,
    showLineSettings,
    isShowLanguageUserSettings,
    showCoefSwitcher,
    showSettings
  } = useUserSettings();
  const filterSegments = useUserSegmentsIncludes();

  const mainSegmentedItems = useMemo(
    () =>
      mainMenu
        .map(({ title, tag, logoUrl, ...rest }) => ({
          title: t(`header.submenu.${title}`),
          tag: tag ? t(`header.submenu.${tag}`) : '',
          logoUrl,
          tagColor: menuTags[tag]?.tagBackground,
          ...rest
        }))
        .filter((item) => filterSegments(item.userSegments)),
    [t, mainMenu, filterSegments]
  );

  const secondarySegmentedItems = useMemo(
    () =>
      secondMenu
        .map(({ title, ...rest }) => ({
          title: t(`header.submenu.${title}`),
          ...rest
        }))
        .filter((item) => filterSegments(item.userSegments)),
    [secondMenu, filterSegments]
  );

  return (
    <CommonSubMenu
      mainMenuItems={mainSegmentedItems}
      SecondaryItems={
        disableUserSettings ? null : (
          <UserSettings
            items={secondarySegmentedItems}
            showTimeZoneSelector={showTimeZoneSelector}
            showCoefSwitcher={showCoefSwitcher}
            showLineSettings={showLineSettings}
            isShowLanguageUserSettings={isShowLanguageUserSettings}
            showSettings={showSettings}
          />
        )
      }
    />
  );
};

export default SubMenu;
