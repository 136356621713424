import { window } from '@namespace/helpers';

const callGtmEvent = (eventName, params = {}) => {
  if (window.gtag) {
    try {
      window.gtag('event', eventName, params);
    } catch {
      console.log('gtag error');
    }
  }
};

export default callGtmEvent;
