export const passValidationRules = /^(?=[A-Za-z0-9]*?[A-Z])(?=[A-Za-z0-9]*?[0-9])[A-Za-z0-9]{6,14}$/;

export const CARD_TYPE_PARAMETERS = [
  {
    name: 'Visa',
    prefixes: [4]
  },
  {
    name: 'MasterCard',
    prefixes: [51, 52, 53, 54, 55]
  },
  {
    name: 'DinersClub',
    prefixes: [36, 38, 54, 55]
  },
  {
    name: 'CarteBlanche',
    prefixes: [300, 301, 302, 303, 304, 305]
  },
  {
    name: 'AmEx',
    prefixes: [34, 37]
  },
  {
    name: 'Discover',
    prefixes: [6011, 622, 64, 65]
  },
  {
    name: 'JCB',
    prefixes: [35]
  },
  {
    name: 'enRoute',
    prefixes: [2014, 2149]
  },
  {
    name: 'Solo',
    prefixes: [6334, 6767]
  },
  {
    name: 'Switch',
    prefixes: [4903, 4905, 4911, 4936, 564182, 633110, 6333, 6759]
  },
  {
    name: 'LaserCard',
    prefixes: [6304, 6706, 6771, 6709]
  },
  {
    name: 'Maestro',
    prefixes: [5018, 5020, 5038, 6304, 6759, 6761, 6762, 6763]
  },
  {
    name: 'VisaElectron',
    prefixes: [4026, 417500, 4508, 4844, 4913, 4917]
  }
];
