import { forwardRef } from 'react';
import { LocalizedLink } from '@namespace/i18n';
import styles from './index.module.css';

const PromoTeaser = forwardRef(
  ({ className = '', picture, slug, handleClickOnMove, gap }, ref) => (
    <LocalizedLink
      gap={gap}
      ref={ref}
      to={slug}
      onClick={handleClickOnMove}
      className={`${styles.teaser} ${className}`}
      style={{ backgroundImage: `url(${picture})` }}
    />
  )
);

export default PromoTeaser;
