import styles from './index.module.css';

const WidgetWrapper = ({ children, className, dataRole }) => (
  <div
    className={`${styles.wrapper} ${className || ''}`}
    {...(dataRole != null ? { 'data-role': dataRole } : {})}
  >
    {children}
  </div>
);

export default WidgetWrapper;
