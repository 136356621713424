import { path } from 'ramda';
import { useConfig, useGeneralRoutes } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { Box } from '@alf/uikit';
import { MODE } from '../../../constants';
import { MenuHeader } from './components/MenuHeader';
import { useGetPathParams } from '../../../hooks/useGetPathParams';
import ListAllItem from './ListAllItem';
import { useSportsbookStore } from '../../../store';
import { BaseMenuList, FavoritesLink } from '../../../common';
import styles from './index.module.css';

const LiveList = ({ id }) => {
  const { t } = useI18n();
  const { liveSport, liveSportAll, liveFavorites } = useGeneralRoutes();
  const { config } = useConfig(id);
  const { isShowAll = false, showCategories } = config;
  const sports = useSportsbookStore(path(['sports', MODE.IN_PLAY]));
  const { activeSport, category, eventId } = useGetPathParams(MODE.IN_PLAY);
  const { sportId: activeSportId } = activeSport || {};
  const { sportId: categorySportId, categoryId } = category || {};

  return (
    <Box direction="column" className={styles.liveList}>
      <MenuHeader
        title="live.title"
        subTitle="live.subTitle"
        isLive={true}
        classNames={{
          headerClassName: styles.menuHeaderLive
        }}
        dataRole="live-sport"
      />
      {isShowAll && (
        <ListAllItem
          route={liveSportAll}
          title={t('sportsBook.menu.allSports')}
          classNames={{ itemClass: styles.itemAll }}
          withIcons={isShowAll}
          dataRole="live-list"
        />
      )}
      <FavoritesLink
        to={liveFavorites}
        title={t('sportsbook.favorites')}
        className={styles.favoriteItem}
      />
      <BaseMenuList
        sports={sports}
        serviceId={MODE.IN_PLAY}
        activeSportId={activeSportId}
        basePath={liveSport}
        showCategories={showCategories}
        eventId={eventId}
        categorySportId={categorySportId}
        categoryId={categoryId}
        keyProperty="sportId"
        showPinIcon={true}
      />
    </Box>
  );
};

export default LiveList;
