import { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  useComponentConfig,
  useOtherSettings,
  useGeneralRoutes
} from '@namespace/cms';
import { Search, SearchEmpty } from '@alf/uikit';
import { useI18n, useLocation } from '@namespace/i18n';
import { scrollTo } from '@namespace/helpers';
import { Image } from '@namespace/icons2';
import { useAddSearchParams } from '@namespace/router';
import useSearchParams from '@namespace/router/src/hooks/useSearchParams';
import { matchPath } from 'react-router-dom';

import { MIN_GAMES_SEARCH_QUERY_LENGTH } from '../../constants';
import {
  useGetFeedName,
  useLoadCasinoFeed,
  useGetLobbyPageUrl,
  useSearchGames,
  useLoadFavoritesList,
  useScrollToPrevGamesPosition
} from '../../hooks';
import { CasinoSearchBackHeader } from '../../common/CasinoSearchBackHeader';
import { GamesControlsContext } from '../../context';

import { SearchResultGames } from './SearchResultGames';

import styles from './index.module.css';

const CasinoGamesSearch = () => {
  const { casinoType, showTags = true, showGameNames } = useComponentConfig();
  const { t } = useI18n();
  const feedName = useGetFeedName(casinoType);
  const { query: searchParamsQuery } = useSearchParams();
  const lobbyPageUrl = useGetLobbyPageUrl(casinoType);

  const [query, setQuery] = useState(searchParamsQuery || '');
  const { pathname } = useLocation();
  const addSearchParams = useAddSearchParams();

  const otherSettings = useOtherSettings();
  const { isCasinoPaginationEnabled = false } = otherSettings;
  const { gamesSearch } = useGeneralRoutes();

  const { gamesResult, debouncedQuery } = useSearchGames(feedName, query);
  const gamesListId = debouncedQuery
    ? `search-games-list-${debouncedQuery.replace(/ /g, '-')}`
    : '';

  const controls = useMemo(
    () => ({
      serviceURL: lobbyPageUrl,
      hasFavoriteButton: true
    }),
    [lobbyPageUrl]
  );
  const gamesListConfig = useMemo(
    () => ({
      showTags,
      casinoType,
      showGameNames
    }),
    [showTags, casinoType, showGameNames]
  );
  const isCurrentSearchPath = !isEmpty(
    matchPath({ path: `/:service${gamesSearch}`, end: true }, pathname)
  );

  useEffect(() => {
    if (!isCurrentSearchPath) return;

    addSearchParams({ query: debouncedQuery });
  }, [debouncedQuery, isCurrentSearchPath, addSearchParams]);

  const { isFeedLoaded } = useLoadCasinoFeed({ feedNames: feedName });

  useLoadFavoritesList(casinoType);

  const isScrollToPrevPositionEnabled =
    isCasinoPaginationEnabled && isFeedLoaded;

  useEffect(() => {
    // need manually scroll to top, because "/casino/:category" route used in routesWithDisabledAutoScroll
    // and casino/search matches this route. maybe need to use another route in this case

    if (!searchParamsQuery) scrollTo({ timeout: 200 });
  }, []);

  useScrollToPrevGamesPosition(
    gamesListId,
    isScrollToPrevPositionEnabled,
    casinoType
  );

  return (
    <GamesControlsContext.Provider value={controls}>
      <CasinoSearchBackHeader lobbyUrl={lobbyPageUrl} />
      <Search
        setQuery={setQuery}
        query={query}
        minQueryLength={MIN_GAMES_SEARCH_QUERY_LENGTH}
        isAutofocus={!searchParamsQuery}
        result={<div />}
        haveToCloseOutsideClick={false}
        placeholder={t('web.games.search.placeholder')}
      />
      {query.length >= MIN_GAMES_SEARCH_QUERY_LENGTH ? (
        <SearchResultGames
          isFeedLoaded={isFeedLoaded}
          games={gamesResult}
          gamesListId={gamesListId}
          config={gamesListConfig}
          controls={controls}
          casinoType={casinoType}
        />
      ) : (
        <SearchEmpty
          image={
            <Image
              name="general/typing"
              className={styles.typingImage}
              alt=""
            />
          }
          className={styles.typingEmptyWrapper}
          classNames={{
            infoText: styles.searchEmptyInfoText
          }}
          text={t('casinoGames.searchplaceholder')}
          infoText={t('web.games.search.rule')}
        />
      )}
    </GamesControlsContext.Provider>
  );
};

export default CasinoGamesSearch;
