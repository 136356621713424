import { postAsUriParameters, get, asyncAttempt } from '@namespace/helpers';
import apiRoutes from '../apiRoutes';
import { NEW_BONUSES_FILTER } from '../../constants';

export const getAccountingBonusesCount = async ({
  userId,
  type,
  filter = NEW_BONUSES_FILTER
}) => {
  const body = {
    user_id: userId,
    type,
    filter
  };
  const isWithBraces = true;
  const [error, response] = await asyncAttempt(
    postAsUriParameters,
    apiRoutes.crmAnyBonusCount,
    body,
    isWithBraces
  );

  if (error) {
    console.error(error);

    return {};
  }

  const result = response?.response?.response;

  return result;
};

export const getJackpotsBonusesCount = () =>
  get('/service/jackpot/api/jackpot/count')
    .then((response) => response?.data?.count || 0)
    .catch((e) => {
      console.error(e);

      return 0;
    });
