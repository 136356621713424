import { useCallback, useMemo } from 'react';
import { LOG_ACTIONS, logAction } from '@namespace/analytics';
import { isEmpty } from 'lodash';
import { useComponentConfig, useGeneralRoutes } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { useGetPathParams } from '../../hooks/useGetPathParams';
import SectionButton from '../../common/SectionButton';
import { MODE } from '../../constants';
import {
  useSport,
  useSportsBookDataState,
  useSportsbookSelector,
  useSportsEventCount
} from '../../store';
import Skelet from './common/Skelet';
import { selectFirstSportSorted } from './selectors';
import styles from './index.module.css';

const LiveEventsButton = ({
  propLink = '',
  labelFirstPart = '',
  labelSecondPart = '',
  isDisplayCount = true
}) => {
  const { t } = useI18n();
  const config = useComponentConfig();
  const { isShowAll = false } = config;
  const { liveSport, liveSportAll } = useGeneralRoutes();
  const { activeSport } = useGetPathParams(MODE.PRE_MATCH);
  const { sportId, sportSlug: activeSportSlug } = activeSport || {};
  const { isSportsBookDataLoaded } = useSportsBookDataState();

  const sportDataActiveSport = useSport(sportId, MODE.IN_PLAY); // looking for active sport data
  // looking for mostWeightSport data (for lobby)
  const sportDataWeightFullSport = useSportsbookSelector(
    selectFirstSportSorted,
    [MODE.IN_PLAY]
  );

  const sportData = !isEmpty(activeSport)
    ? sportDataActiveSport
    : sportDataWeightFullSport;

  const { sportSlug = 0, eventCount = 0, sportId: sportid, sportName } =
    sportData || {};

  const handleClick = useCallback(
    () =>
      logAction(LOG_ACTIONS.LIVE_EVENTS, {
        sportid,
        sportName
      }),
    [sportid, sportName]
  );

  const link = () => {
    if (propLink) {
      return propLink;
    }
    if (isShowAll) {
      return `${liveSportAll}`;
    }
    return `${liveSport}/${activeSportSlug || sportSlug}`;
  };

  const allCount = useSportsEventCount([MODE.IN_PLAY], sportId);

  const eventsCount = useMemo(() => {
    if (isDisplayCount) {
      return isShowAll ? allCount : eventCount;
    }

    return '';
  }, [allCount, eventCount, isDisplayCount, isShowAll]);
  if (!isSportsBookDataLoaded) {
    return <Skelet />;
  }

  return eventCount > 0 ? (
    <SectionButton
      onClick={handleClick}
      count={eventsCount}
      labelFirstPart={labelFirstPart || t('sportsbook.events.goTo')}
      labelSecondPart={labelSecondPart || t('sportsbook.events.liveEvents')}
      link={link()}
      className={styles.sectionButton}
      dataRole="go-to-live-btn"
    />
  ) : (
    <div className={styles.emptyBanner} />
  );
};

export default LiveEventsButton;
