import { useCallback, useContext } from 'react';
import { AText, Box, Button } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { DeviceContext } from '@namespace/device';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { useLogout } from '@namespace/user';
import styles from './index.module.css';

const handleLogOutSuccess = (id) =>
  logAction(LOG_ACTIONS.REALITY_CHECK_LOGOUT, { userId: id });

const RealityCheckToast = ({ onClose, message }) => {
  const { isDesktop } = useContext(DeviceContext);
  const { t } = useI18n();
  const { logout } = useLogout(handleLogOutSuccess);
  const { title, body } = message;

  const handleContinue = useCallback(() => {
    logAction(LOG_ACTIONS.REALITY_CHECK_CONTINUE);
    return onClose();
  }, [onClose]);

  return (
    <Box
      padding="s"
      direction="column"
      align="center"
      className={styles.notification}
    >
      {title && (
        <Box bottom="s">
          <AText
            breed="B"
            size={isDesktop ? 'l' : 'm'}
            color="notification_txt_color_1"
            align="center"
          >
            {title}
          </AText>
        </Box>
      )}

      <AText
        breed="R"
        size={isDesktop ? 'l' : 'm'}
        color="notification_txt_color_1"
        align="center"
      >
        {body}
      </AText>
      <Box align="center" justify="center" top="s">
        <Button
          intent="primary"
          actionType="color4"
          size="s"
          onClick={handleContinue}
          className={styles.button}
        >
          {t('realityCheck.notice.continue')}
        </Button>
        <Button
          intent="secondary"
          actionType="color4"
          size="s"
          onClick={logout}
          className={styles.button}
        >
          {t('realityCheck.notice.logout')}
        </Button>
      </Box>
    </Box>
  );
};

export default RealityCheckToast;
