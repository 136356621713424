import { rePatterns } from '../../patterns';

const phoneOnlyNumberValidator = (
  message = 'validators.phoneOnlyNumberFormatValidator'
) => ({
  validate: ({ number }) =>
    number.match(rePatterns.onlyNumbers) ? true : message
});

export default phoneOnlyNumberValidator;
