import { identity } from 'lodash';
import { checkPhone } from '../../api';
import memoization from '../../utils/memoization';
import createAsyncValidator from '../../core/createAsyncValidator';

const REQUIRED_FIELD = 1501;

const phoneWithCodeValidation = (mapper = identity, cacheProps) => {
  const validator = async (value) => {
    const { code, number } = mapper(value);

    if (!number || !code) {
      return {
        error: 'yes',
        errorCode: REQUIRED_FIELD
      };
    }

    return await checkPhone(`${code}${number}`);
  };

  const passingValidator = cacheProps
    ? memoization(validator, cacheProps)
    : validator;

  return createAsyncValidator(passingValidator);
};

export default phoneWithCodeValidation;
