import { groupBy } from 'lodash';

const LIMITS_OF_EVENTS = [4, 2];
export const getEventsRequestParams = ({
  customFiltersData,
  sportsData: { advFilterSportsById, advFilterCategoriesById },
  lang,
  filterValue,
  lines = {}
}) => {
  const normalizedSportsByCategory = groupBy(
    customFiltersData
      .filter(
        ({ sportId, status }) =>
          advFilterSportsById[sportId] && status === 'active'
      )
      .map(({ sportId, name: filterName = [], categoryId, ...rest }) => {
        const [
          sportName = advFilterSportsById[sportId]?.name ?? sportId,
          categoryName = advFilterCategoriesById[categoryId]?.name ?? categoryId
        ] = filterName;
        const { weight } = advFilterSportsById[sportId];

        return {
          ...rest,
          sportId,
          categoryName,
          sportWeight: weight,
          sportName,
          categoryId
        };
      }),
    'categoryId'
  );
  return Object.entries(normalizedSportsByCategory)
    .map((item) => {
      const [categoryId, groupsList] = item;

      if (!Number(categoryId) && groupsList.length > 1) {
        return groupsList.map(({ sportId, sportName, sportWeight }) => {
          const lineKey = `custom-filter-sport-id-${sportId}`;
          const { dateFilterValue = {} } = lines[lineKey] || {};
          const { timeRange, tzDiff, date } =
            dateFilterValue || filterValue || {};

          return {
            requestData: {
              by: {
                lang,
                head_markets: true,
                service_id: 0,
                sport_id: sportId,
                ...((filterValue || dateFilterValue) &&
                  tzDiff && {
                    tz_diff: tzDiff
                  }),
                ...((filterValue || dateFilterValue) && date && { date }),
                ...((filterValue || dateFilterValue) &&
                  timeRange && {
                    time_filter: timeRange
                  })
              },
              offset: 0
            },
            additionalData: {
              sportId,
              sportName,
              lineKey,
              sportWeight
            }
          };
        });
      }

      const tournamentIds = groupsList
        .map(({ tournamentId }) => tournamentId)
        .filter(Boolean);

      const lineKey = `custom-filter${
        !Number(categoryId) && !tournamentIds.length
          ? `-sport-id-${groupsList[0].sportId}`
          : ''
      }${
        Number(categoryId) && !tournamentIds.length
          ? `-category-id-${Number(categoryId)}`
          : ''
      }${tournamentIds.length ? `-tournament-id-${tournamentIds[0]}` : ''}`;

      const { dateFilterValue = {} } = lines[lineKey] || {};
      const { timeRange, tzDiff, date } = dateFilterValue || filterValue || {};

      return {
        requestData: {
          by: {
            lang,
            head_markets: true,
            service_id: 0,
            ...(!Number(categoryId) &&
              !tournamentIds.length && { sport_id: groupsList[0].sportId }),
            ...(Number(categoryId) &&
              !tournamentIds.length && {
                category_id: {
                  $in: [Number(categoryId)]
                }
              }),
            ...(tournamentIds.length && {
              tournament_id: {
                $in: tournamentIds
              }
            }),
            ...((filterValue || dateFilterValue) &&
              tzDiff && { tz_diff: tzDiff }),
            ...((filterValue || dateFilterValue) && date && { date }),
            ...((filterValue || dateFilterValue) &&
              timeRange && { time_filter: timeRange })
          },
          offset: 0
        },
        additionalData: {
          sportId: groupsList[0].sportId,
          sportName: groupsList[0].sportName,
          categoryId: Number(categoryId),
          categoryName: groupsList[0].categoryName,
          tournamentIds,
          lineKey,
          sportWeight: groupsList[0].sportWeight
        }
      };
    })
    .flat()
    .sort(
      (
        { additionalData: { sportWeight: weightA } },
        { additionalData: { sportWeight: weightB } }
      ) => weightA - weightB
    )
    .map(({ requestData, ...rest }, index) => ({
      requestData: {
        ...requestData,
        limit: LIMITS_OF_EVENTS[index] || LIMITS_OF_EVENTS[1]
      },
      ...rest
    }));
};
