export const FONT_SIZE_INPUT = {
  xs: '14px',
  s: '14px',
  m: '16px'
};

export const HEIGHT_INPUT = {
  xs: '30px',
  s: '40px',
  m: '48px'
};
