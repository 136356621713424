import { CASINO_TYPE, useCasinoSettings } from '@namespace/games';
import { useGeneralRoutes } from '@namespace/cms';

export const useGetActualServiceUrl = (serviceUrl) => {
  const generalRoutes = useGeneralRoutes();
  const { lobbyRouteName: defaultLobbyRoute } = useCasinoSettings(
    CASINO_TYPE.CASINO
  );

  if (serviceUrl) return serviceUrl;

  return generalRoutes[defaultLobbyRoute];
};
