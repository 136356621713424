import { equals, lensProp, mergeDeepLeft, omit, over } from 'ramda';
import { flatten, groupBy, isEmpty, uniqBy } from 'lodash';
import { socketSend } from '@namespace/socket';
import { initialState, useSportsbookStore } from './store';
import {
  categoriesIdsByServiceId,
  getGroupedMessages,
  normalizeCategories,
  normalizeEvents,
  normalizeEventsToShow,
  normalizeHeadGroups,
  normalizeHeadGroupsBySportId,
  normalizePresets,
  normalizeSports,
  normalizeTournaments
} from './normalizers';
import { bulletReducersMap } from './reducers/bullet';
import { getHash } from '../api/getHash';
import { getSports } from '../api/getSports';
import {
  CANCELLED_EVENT_STATUS,
  FINISHED_EVENT_STATUS,
  MODE,
  PRESETS_SOURCE_TYPES
} from '../constants';
import { getHeadGroups } from '../api/getHeadGroups';
import { getCategories } from '../api/getCategories';
import { getTournaments } from '../api/getTournaments';
import { getMarketGroups } from '../api/getMarketGroups';
import { getEvents } from '../api/getEvents';
import {
  getFilteredByStateTopPreMatchEvents,
  groupByTournament
} from '../utils';
import { getPopularBetsEvents } from '../api/getPopularBetsEvents';
import { getTopEventPresets } from '../api/getTopEventPresets';
import { getCmsPresets } from '../api/getCmsPresets';
import { getEvent } from '../api/getEvent';
import { getEventMarkets } from '../api/getEventMarkets';
import { getTopLiveEvents } from '../api/getTopLiveEvents';
import { getTeasers, getTeasers2 } from '../api/getTeasers';
import { getTeaserEvents } from '../api/getTeaserEvents';
import { fetchVideoStream } from '../api/getVideoStream';

const allServiceIds = [MODE.IN_PLAY, MODE.PRE_MATCH];

// eslint-disable-next-line no-console
const logError = console.error;

export const actionsCreator = ({ setState, getState }) => ({
  // region Reducers

  // +
  REMOVE_LINE: ({ lineKey }) =>
    setState(over(lensProp('lines'), omit([lineKey]))),

  // +
  SET_TEASERS: ({
    teasers = [],
    teasersLoaded,
    events,
    lineKey,
    marketsKey
  }) => {
    const {
      eventsIds,
      eventsMarketsIds,
      events: normalizedEvents,
      markets,
      outcomes
    } = normalizeEvents(events, { marketsKey });

    setState((state) => ({
      teasers,
      teasersDataState: {
        ...state.teasersDataState,
        [lineKey]: teasersLoaded
      },
      events: mergeDeepLeft(normalizedEvents, state.events),
      markets: mergeDeepLeft(markets, state.markets),
      lines: {
        ...state.lines,
        [lineKey]: {
          eventsIds,
          eventsMarketsIds
        }
      },
      outcomes: {
        ...state.outcomes,
        ...outcomes
      }
    }));
  },

  // +
  SET_EVENTS: ({
    events: eventsList,
    lineKey,
    initialEventsLimit,
    dateFilterValue
  }) => {
    if (!eventsList) {
      return;
    }

    const {
      eventsIds,
      eventsMarketsIds,
      events,
      markets,
      outcomes
    } = normalizeEvents(eventsList);
    const state = getState();
    const lines = lineKey
      ? {
          ...state.lines,
          [lineKey]: {
            ...state.lines[lineKey],
            eventsIds,
            eventsMarketsIds,
            offset: eventsIds.length,
            ...(initialEventsLimit && { initialEventsLimit }),
            // It can be null from dateFilter when "all" option selected, but we need this value here
            ...((dateFilterValue || dateFilterValue === null) && {
              dateFilterValue
            })
          }
        }
      : state.lines;

    const currentEvents = {
      ...state.events,
      ...events
    };

    const eventsToShowByLineKey = normalizeEventsToShow({
      events: currentEvents,
      lines,
      sortByFilter: state.sortByFilter
    });

    setState({
      eventsToShowByLineKey,
      events: currentEvents,
      isEventsLoaded: true,
      markets: {
        ...state.markets,
        ...markets
      },
      lines,
      outcomes: {
        ...state.outcomes,
        ...outcomes
      }
    });
  },

  // +
  ADD_EVENTS: ({ events: eventsList, lineKey }) => {
    const {
      eventsIds,
      eventsMarketsIds,
      events,
      markets,
      outcomes
    } = normalizeEvents(eventsList);

    const state = getState();
    const currentEvents = {
      ...state.events,
      ...events
    };

    const lines = {
      ...state.lines,
      [lineKey]: {
        ...state.lines[lineKey],
        eventsIds: [
          ...new Set([...(state.lines[lineKey]?.eventsIds || []), ...eventsIds])
        ],
        eventsMarketsIds: {
          ...(state.lines[lineKey]?.eventsMarketsIds || {}),
          ...eventsMarketsIds
        },
        offset:
          state.lines[lineKey]?.offset + (Object.keys(events)?.length || 0)
      }
    };

    const eventsToShowByLineKey = normalizeEventsToShow({
      events: currentEvents,
      lines,
      sortByFilter: state.sortByFilter
    });

    setState({
      eventsToShowByLineKey,
      events: currentEvents,
      markets: {
        ...state.markets,
        ...markets
      },
      lines,
      outcomes: {
        ...state.outcomes,
        ...outcomes
      }
    });
  },

  // +
  SET_EVENT: ({ event, lineKey }) =>
    setState((state) => ({
      events: event
        ? {
            ...state.events,
            [event.eventId]: {
              ...event,
              isCachedEvent: [
                FINISHED_EVENT_STATUS,
                CANCELLED_EVENT_STATUS
              ].includes(event.eventStatusType)
            }
          }
        : state.events,
      lines: {
        ...state.lines,
        [lineKey]: {
          ...state.lines[lineKey],
          eventsIds: event?.eventId ? [event.eventId] : []
        }
      }
    })),

  // +
  SET_EVENT_MARKETS: ({ eventMarketsData = [], eventId, lineKey }) =>
    setState((state) => ({
      markets: {
        ...state.markets,
        ...eventMarketsData.reduce(
          (acc, { outcomes = [], ...market }) => ({
            ...acc,
            [market.marketId]: {
              ...market,
              outcomeIds: outcomes.map(({ outcomeId }) => outcomeId)
            }
          }),
          {}
        )
      },
      lines: {
        ...state.lines,
        [lineKey]: {
          ...state.lines[lineKey],
          eventsMarketsIds: {
            [eventId]: eventMarketsData.map(({ marketId }) => marketId)
          }
        }
      },
      outcomes: eventMarketsData.reduce((memo = {}, { outcomes }) => {
        const newOutcomes = outcomes.reduce(
          (acc, outcome) => ({
            ...acc,
            [outcome.outcomeId]: outcome
          }),
          {}
        );
        return {
          ...memo,
          ...newOutcomes
        };
      }, state.outcomes)
    })),

  // +
  SET_SORT_BY_FILTER: ({ lineKey, filter }) =>
    setState((state) => ({
      sortByFilter: {
        ...state.sortByFilter,
        [lineKey]: filter
      }
    })),

  // +
  UPDATE_STATE: (messages) => {
    const groupedMessages = getGroupedMessages(messages);

    setState((state) =>
      groupedMessages.reduce(
        (acc, array = []) =>
          bulletReducersMap[array[0]?.type]
            ? {
                ...acc,
                ...bulletReducersMap[array[0]?.type](
                  acc,
                  array.map(({ data }) => data)
                )
              }
            : acc,
        state
      )
    );
  },
  // endregion

  // region Actions
  FETCH_SPORTS_BOOK_DATA: async ({ lang, timeRange, ...rest }) => {
    const promises = [];

    promises.push(
      await Promise.all(
        allServiceIds.map(async (mode) => {
          const { result: { hash: resultHash } = {} } = await getHash(mode);

          return resultHash?.[0].operator_hash;
        })
      )
    );

    promises.push(
      Promise.all(
        allServiceIds.map(async (serviceId) => ({
          serviceId,
          sports: await getSports({
            lang,
            serviceId,
            timeRange:
              serviceId === MODE.PRE_MATCH && timeRange ? timeRange : undefined,
            ...rest
          })
        }))
      )
    );

    promises.push(
      await getHeadGroups({
        serviceIds: allServiceIds,
        lang
      })
    );

    const [hash, sports, headGroups] = await Promise.all(promises);
    const normalizedHeadGroups = normalizeHeadGroups(headGroups);

    setState((state) => ({
      sportsBookHash: hash,
      headGroups: { ...state.headGroups, ...normalizedHeadGroups },
      headGroupsBySportId: {
        ...state.headGroupsBySportId,
        ...normalizeHeadGroupsBySportId(normalizedHeadGroups)
      },
      isHeadGroupsLoaded: true,
      headGroupsDataLanguage: lang,
      sportDataState: {
        isLoaded: true,
        lang,
        dataCachingTime: Date.now()
      },
      sports: {
        ...state.sports,
        ...normalizeSports(sports)
      }
    }));
  },

  // todo refactor: remove categoriesBySportId as it's always taken from state
  //  Also, last `setState` is looking redundant as we re-set same state from state - investigate
  //  Update negative test accordingly
  FETCH_CATEGORIES_AND_TOURNAMENTS: async ({
    lang,
    sportId,
    categoriesBySportId,
    ...apiParams
  }) => {
    const {
      normalizedCategories: normalizedLoadedCategories,
      lang: normalizedLoadedCategoriesLand
    } = categoriesBySportId[sportId] || {};

    if (
      !normalizedLoadedCategories ||
      (normalizedLoadedCategories && lang !== normalizedLoadedCategoriesLand)
    ) {
      setState({ isTournamentCategoryReady: false });

      const categories = await Promise.all(
        allServiceIds.map(async (serviceId) => {
          const categoriesByServiceId = await getCategories({
            serviceId,
            lang,
            sportIds: [sportId],
            ...apiParams
          });

          return {
            serviceId,
            categories: categoriesByServiceId
          };
        })
      );

      const normalizedCategories = normalizeCategories(categories);

      const tournaments = await Promise.all(
        allServiceIds.map(async (serviceId) => {
          const tournamentsByServiceId = await getTournaments({
            serviceId,
            lang,
            sportId,
            ...apiParams
          });

          return {
            serviceId,
            tournaments: tournamentsByServiceId
          };
        })
      );
      const normalizedTournaments = normalizeTournaments(tournaments);
      const catIdsByServiceIds = categoriesIdsByServiceId(
        normalizedCategories,
        allServiceIds
      );

      setState((state) => ({
        isTournamentCategoryReady: true,
        categories: normalizedCategories,
        categoriesIdsBySportId: mergeDeepLeft(
          { [sportId]: catIdsByServiceIds },
          state.categoriesIdsBySportId
        ),
        categoriesBySportId: mergeDeepLeft(
          { [sportId]: { normalizedCategories, lang } },
          state.categoriesBySportId
        ),
        categoriesAndTournamentsDataState: {
          ...state.categoriesAndTournamentsDataState,
          [sportId]: {
            isLoaded: true,
            lang,
            dataCachingTime: Date.now()
          }
        },
        tournaments: mergeDeepLeft(normalizedTournaments, state.tournaments)
      }));
    } else {
      setState({ categories: normalizedLoadedCategories });
    }
  },

  FETCH_MARKET_GROUPS: async ({ serviceId, lang, sportId }) => {
    const { result: marketGroups = [] } = await getMarketGroups({
      serviceId,
      lang,
      sportId: Number(sportId)
    });

    // TODO: market groups for each sport?
    const nextMarketGroups = uniqBy(flatten(marketGroups), 'marketGroupId')
      .filter(({ marketGroupIsHead }) => marketGroupIsHead === 'no')
      .reduce((memo, currentValue) => {
        const marketGroupsByMarketTemplateId = groupBy(
          flatten(currentValue.rtMt),
          'marketTemplateId'
        );
        const marketGroupMarkets = Object.entries(
          marketGroupsByMarketTemplateId
        ).reduce(
          (acc, [marketTemplateId, markets]) => ({
            ...acc,
            [marketTemplateId]: markets.map(({ resultTypeId }) => resultTypeId)
          }),
          {}
        );

        return [
          ...memo,
          {
            marketGroupId: currentValue.marketGroupId,
            marketGroupName: currentValue.marketGroupName,
            marketGroupMarkets
          }
        ];
      }, []);

    setState({ marketGroups: nextMarketGroups });
  },

  FETCH_EVENTS: async ({
    serviceId,
    lang,
    sportId,
    tournamentsIds = [],
    filterValue = {},
    betType,
    marginGroupId,
    lineKey,
    categoryIds = [],
    limit
  }) => {
    let res;
    try {
      const { result = [] } = await getEvents({
        lang,
        serviceId,
        betType,
        limit: limit || initialState.limit,
        offset: 0,
        marginGroupId,
        ...(!tournamentsIds.length && { categoryIds }),
        ...(tournamentsIds.length
          ? { tournamentsIds }
          : { sportId: Number(sportId) }),
        ...filterValue
      });

      sportsbookActions.SET_EVENTS({
        events: result,
        lineKey
      });

      res = result;
    } catch (error) {
      logError(error);
    }
    return res;
  },

  FETCH_MICRODATA_TOP_EVENTS: async ({
    events = [],
    tournaments = [],
    serviceId,
    lang,
    lineKey
  }) => {
    const eventIds = events.map(({ eventId }) => eventId);
    const tournamentsIds = tournaments.map(({ tournamentId }) => tournamentId);
    const tournamentsEventsIds = tournaments.reduce(
      (acc, { events: tournamentEvents = [] }) => [
        ...acc,
        ...tournamentEvents.map(({ eventId }) => eventId)
      ],
      []
    );
    const allEventIds = [...eventIds, ...tournamentsEventsIds].filter(Boolean);

    let newEvents = [];

    if (allEventIds.length || tournamentsIds.length) {
      try {
        const { result = [] } = await getEvents({
          lang,
          serviceId,
          eventIds: allEventIds,
          tournamentsIds
        });

        newEvents = result;

        sportsbookActions.SET_EVENTS({
          lineKey,
          events: newEvents
        });
      } catch (e) {
        logError(e);
      }
    }
  },

  FETCH_TOP_PRE_MATCH_EVENTS: async ({ lang, filteredByDatePresets }) => {
    const {
      events: allEvents,
      tournaments: allTournaments
    } = filteredByDatePresets.reduce(
      (acc, { events: eventList = [], tournaments = [] }) => ({
        events: acc.events.concat(eventList),
        tournaments: acc.tournaments.concat(tournaments)
      }),
      { events: [], tournaments: [] }
    );
    const tournamentsIds = allTournaments.map((t) => t.tournamentId);
    const eventIds = allEvents.map(({ eventId }) => eventId);

    const promises = [];
    if (eventIds.length) {
      promises.push(
        getEvents({
          lang,
          serviceId: MODE.PRE_MATCH,
          eventIds,
          headMarkets: false
        })
      );
    }

    if (eventIds.length) {
      promises.push(
        getEvents({
          lang,
          serviceId: MODE.IN_PLAY,
          eventIds,
          headMarkets: false
        })
      );
    }

    if (tournamentsIds.length) {
      promises.push(
        getEvents({
          lang,
          serviceId: MODE.PRE_MATCH,
          tournamentsIds,
          headMarkets: false
        })
      );
    }

    let allPromises = [];
    try {
      allPromises = await Promise.all(promises);
    } catch (error) {
      logError('One of the promises rejected');
    }

    const [
      { result: preMatchEvents = [] } = {},
      { result: liveEvents = [] } = {},
      { result: eventsByTournamentId = [] } = {}
    ] = allPromises;

    const { eventsIds, events } = normalizeEvents([
      ...preMatchEvents,
      ...liveEvents,
      ...eventsByTournamentId
    ]);

    const lineEvents = eventsIds.map((id) => events[id]).filter(Boolean);

    const eventsByTournaments = groupByTournament(lineEvents);

    const filteredByState = getFilteredByStateTopPreMatchEvents({
      filteredByDatePresets,
      eventsStore: events,
      eventsByTournaments
    });

    setState({
      topPreMatchEvents: filteredByState,
      filteredByDatePresets,
      topPreMatchEventsDataState: {
        isLoaded: true,
        lang,
        dataCachingTime: Date.now()
      }
    });
  },

  // todo refactor: remove 'popularBetEvents' - take it from store directly
  FETCH_POPULAR_BETS_EVENTS: async ({
    sportId,
    lineKey,
    categoryId,
    lang,
    popularBetEvents
  }) => {
    const { sports: loadedSports, lang: loadedSportsLand } =
      popularBetEvents[sportId] || {};

    let sports = loadedSports;

    if (!sports || (sports && loadedSportsLand !== lang)) {
      const data = await getPopularBetsEvents({
        sportId: sportId || '',
        lang
      });
      sports = data.sports;
    }

    if (!isEmpty(sports)) {
      const { events = [] } = sports[0];
      let currentEvents = [...events];

      // TODO: need to discuss about refactoring the response from api
      if (events[0]?.sportId !== sportId) {
        currentEvents = [];
      }
      if (categoryId) {
        currentEvents = currentEvents.filter(
          ({ categoryId: eventCategoryId }) => eventCategoryId === categoryId
        );
      }

      sportsbookActions.SET_EVENTS({
        events: currentEvents,
        lineKey
      });

      const popularBetEvent = { [sportId]: { sports, lang } };
      const state = getState();

      // todo assume this check a hack that, with new stores, should be refactored away
      if (
        !state.isLoadedPopularEvents ||
        !equals(popularBetEvent, state.popularBetEvents)
      ) {
        setState({
          popularBetEvents: {
            ...state.popularBetEvents,
            ...popularBetEvent
          },
          isLoadedPopularEvents: true
        });
      }
    } else {
      setState({ isLoadedPopularEvents: true });
    }
  },

  FETCH_EVENTS_BY_LIMIT: async ({
    serviceId,
    lang,
    sportId,
    tournamentsIds = [],
    betType,
    categoryIds = [],
    offset,
    lineKey,
    filterValue = {},
    limit
  }) => {
    let res;
    try {
      const { result = [] } = await getEvents({
        lang,
        serviceId,
        betType,
        limit: limit || initialState.limit,
        offset,
        categoryIds,
        ...(tournamentsIds.length
          ? { tournamentsIds }
          : { sportId: Number(sportId) }),
        ...filterValue
      });

      sportsbookActions.ADD_EVENTS({
        events: result,
        lineKey
      });

      res = result;
    } catch (error) {
      logError(error);
    }

    return res;
  },

  FETCH_TOP_EVENT_PRESETS: async ({ lang, serviceId, tzDiff }) => {
    const topEvents = await getTopEventPresets({
      lang,
      serviceId,
      tzDiff
    });

    setState({
      topEvents: topEvents.presets,
      topEventsDataState: {
        isLoaded: true
      }
    });
  },

  FETCH_CMS_PRESETS: async (data) => {
    const presets = await getCmsPresets(data);

    setState({
      topEvents: normalizePresets(presets),
      topEventsDataState: {
        isLoaded: true,
        dataCachingTime: Date.now()
      }
    });
  },

  FETCH_ACTIVE_TOP_EVENTS: async ({
    source = PRESETS_SOURCE_TYPES.EVENTS,
    events = [],
    tournaments = [],
    serviceId,
    lang,
    lineKey,
    timeRange
  }) => {
    const eventIds = events.map(({ eventId }) => eventId);
    const tournamentsIds = tournaments.map(({ tournamentId }) => tournamentId);
    const tournamentsEventsIds = tournaments.reduce(
      (acc, { events: tournamentEvents = [] }) => [
        ...acc,
        ...tournamentEvents.map(({ eventId }) => eventId)
      ],
      []
    );
    const allEventIds = [...eventIds, ...tournamentsEventsIds].filter(Boolean);

    let newEvents = [];

    const validTimeRange =
      serviceId === MODE.PRE_MATCH && timeRange ? timeRange : undefined;

    if (allEventIds.length) {
      try {
        const { result = [] } = await getEvents({
          lang,
          serviceId,
          eventIds: allEventIds,
          timeRange: validTimeRange
        });

        newEvents = result;
      } catch (err) {
        logError(err);
      }
    } else if (
      tournamentsIds.length &&
      source === PRESETS_SOURCE_TYPES.TOURNAMENTS
    ) {
      try {
        const { result = [] } = await getEvents({
          lang,
          serviceId,
          tournamentsIds,
          timeRange: validTimeRange
        });

        newEvents = result;
      } catch (err) {
        logError(err);
      }
    }

    sportsbookActions.SET_EVENTS({
      lineKey,
      events: newEvents
    });
  },

  FETCH_FAVORITES: () =>
    socketSend({
      cmd: 'accounting/favorites/get'
    }).then(({ ok, data: { events = [], sports = [], categories = [] } }) => {
      if (ok) {
        setState((state) => ({
          favorites: {
            ...state.favorites,
            events: events.map(({ id }) => id),
            sports: sports.map(({ id }) => id),
            categories: categories.map(({ id }) => id)
          },
          isFavoritesLoaded: true
        }));
      }
    }),

  SAVE_FAVORITE_EVENT: ({ eventId, serviceId }) =>
    socketSend({
      cmd: 'accounting/favorites/save',
      data: {
        events: [{ id: eventId, serviceId }]
      }
    }),

  SAVE_FAVORITE_SPORT: ({ sportId }) =>
    socketSend({
      cmd: 'accounting/favorites/save',
      data: {
        sports: [{ id: sportId }]
      }
    }),

  SAVE_FAVORITE_CATEGORY: ({ categoryId }) =>
    socketSend({
      cmd: 'accounting/favorites/save',
      data: {
        categories: [{ id: categoryId }]
      }
    }),

  REMOVE_FAVORITE_EVENT: ({ eventId, serviceId }) =>
    socketSend({
      cmd: 'accounting/favorites/delete',
      data: {
        events: [{ id: eventId, serviceId }]
      }
    }),

  REMOVE_FAVORITE_SPORT: ({ sportId }) =>
    socketSend({
      cmd: 'accounting/favorites/delete',
      data: {
        sports: [{ id: sportId }]
      }
    }),

  REMOVE_FAVORITE_CATEGORY: ({ categoryId }) =>
    socketSend({
      cmd: 'accounting/favorites/delete',
      data: {
        categories: [{ id: categoryId }]
      }
    }),

  FETCH_LIST_OF_EVENTS: async ({
    eventIds = [],
    tournaments = [],
    serviceId,
    lang,
    lineKey
  }) => {
    const tournamentsIds = tournaments.reduce(
      (acc, { events: tournamentEvents }) => [
        ...acc,
        ...tournamentEvents.map(({ eventId }) => eventId)
      ],
      []
    );
    const allEventIds = [...eventIds, ...tournamentsIds].filter(Boolean);

    let newEvents = [];

    if (allEventIds.length) {
      try {
        const { result = [] } = await getEvents({
          lang,
          serviceId,
          eventIds: allEventIds
        });

        newEvents = result;
      } catch (e) {
        logError(e);
      }
    }

    sportsbookActions.SET_EVENTS({
      lineKey,
      events: newEvents
    });
  },

  FETCH_ACTIVE_EVENT: async ({ eventId, lang, lineKey, serviceId }) => {
    const { result: [event] = [] } = await getEvent({
      lang,
      eventId: Number(eventId),
      serviceId
    });

    sportsbookActions.SET_EVENT({
      event,
      lineKey
    });
  },

  FETCH_ACTIVE_EVENT_MARKETS: async ({ serviceId, eventId, lang, lineKey }) => {
    const { result } = await getEventMarkets({
      lang,
      serviceId,
      eventId: Number(eventId)
    });

    sportsbookActions.SET_EVENT_MARKETS({
      eventMarketsData: result,
      eventId,
      lineKey
    });
  },

  FETCH_TOP_LIVE_EVENTS: async ({ language, lineKey }) => {
    const { events } = await getTopLiveEvents({ language });

    sportsbookActions.SET_EVENTS({
      events,
      lineKey
    });
  },

  FETCH_TEASERS: async ({ lineKey, lang }) => {
    const { teasers = [] } = await getTeasers();
    const eventsByServiceId = groupBy(teasers, 'serviceId');
    const serviceIds = Object.keys(eventsByServiceId);
    const events = flatten(
      await Promise.all(
        serviceIds.map(async (serviceId) => {
          const eventIds = eventsByServiceId[serviceId]
            .map(({ eventId }) => eventId)
            .filter(Boolean);

          try {
            let newEvents = [];

            if (eventIds.length) {
              const { result = [] } = await getEvents({
                lang,
                serviceId: Number(serviceId),
                eventIds
              });

              newEvents = result;
            }

            return newEvents;
          } catch {
            return [];
          }
        })
      )
    );

    sportsbookActions.SET_TEASERS({
      teasers,
      teasersLoaded: true,
      events,
      lineKey,
      marketsKey: 'headMarkets'
    });
  },

  FETCH_TEASERS2: async ({ lineKey, teasersNames, lang }) => {
    const teasers = await getTeasers2({ teasersNames });
    const eventsByServiceId = groupBy(teasers, 'serviceId');
    const serviceIds = Object.keys(eventsByServiceId);
    const events = flatten(
      await Promise.all(
        serviceIds.map(async (serviceId) => {
          const eventsRequest = eventsByServiceId[serviceId].map(
            ({
              eventId,
              marketId,
              marketTemplateId,
              resultTypeId,
              availableInLive
            }) => {
              return {
                eventId,
                marketId,
                marketTemplateId,
                resultTypeId,
                availableInLive
              };
            }
          );

          try {
            const { teasers: result = [] } = await getTeaserEvents({
              lang,
              events: eventsRequest
            });

            return result;
          } catch {
            return [];
          }
        })
      )
    );

    sportsbookActions.SET_TEASERS({
      teasers,
      teasersLoaded: true,
      events,
      lineKey,
      marketsKey: 'markets'
    });
  },

  FETCH_ACTIVE_EVENT_STREAM: async ({ eventId, partnerId, lang }) => {
    const json = await fetchVideoStream(eventId, partnerId, lang);
    const eventStreamUrl = json?.stream ? json.stream : null;

    setState((state) => ({
      events: {
        ...state.events,
        [eventId]: {
          ...(state.events[eventId] || {}),
          eventStreamUrl,
          provider: json?.provider
        }
      }
    }));
  }

  // endregion
});

export const sportsbookActions = actionsCreator(useSportsbookStore);
