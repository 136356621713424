import { useContext } from 'react';
import { useOtherSettings, useCookieManagementEnabled } from '@namespace/cms';
import { CookieManagementContext } from '../../store/context';

const useActiveCookieManagementNotification = () => {
  const [{ customizeCookie, cookieManagementType }] = useContext(
    CookieManagementContext
  );
  const { cookieManagement = {} } = useOtherSettings();
  const cookieManagementEnabled = useCookieManagementEnabled();
  const { isNotificationCookieManagement = true } = cookieManagement;
  return (
    !customizeCookie &&
    !cookieManagementType &&
    cookieManagementEnabled &&
    isNotificationCookieManagement
  );
};

export default useActiveCookieManagementNotification;
