import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { Box } from '@alf/uikit';
import {
  CyberSportBurger,
  LiveSportsMenuBurger,
  PreMatchSportsBurger,
  VirtualSportBurger
} from '@namespace/sports';
import { CASINO_TYPE, CasinoCommonBurgerMenu } from '@namespace/games';
import { useUserSegmentsIncludes } from '@namespace/segments';
import { useConfig } from '@namespace/cms';
import { useLocation } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { isEmpty } from 'lodash';
import { getRootPath } from '@namespace/helpers';
import Slider from '../Slider';
import {
  CASINO,
  CASINO_LIVE,
  CYBERSPORT,
  INSTANT_GAMES,
  LIVE,
  SPORTS,
  VIRTUALS
} from '../constants';
import { filterMenuItems } from '../utils';
import { BurgerMenuContext } from '../context';
import styles from './index.module.css';

const Menu = ({ id, isOpen, container, setOpen, setIsOpen }) => {
  const containerRef = useRef(null);
  const { config = {} } = useConfig(id);
  const { mainMenuItems = {}, initialActiveTab = SPORTS } = config;
  const { pathname } = useLocation();
  const rootPath = getRootPath(pathname);
  const { push } = useHistory();
  const [{ activeTab = initialActiveTab }, { SET_ACTIVE_TAB }] = useContext(
    BurgerMenuContext
  );

  const filterSegments = useUserSegmentsIncludes();

  const segmentedItems = useMemo(
    () => filterMenuItems(mainMenuItems, filterSegments),
    [mainMenuItems, filterSegments]
  );

  const changeActiveTab = useCallback(
    (item) => {
      const { isLink } = segmentedItems[item] || {};

      if (!isLink || item === VIRTUALS) {
        SET_ACTIVE_TAB({ activeTab: item });
      } else {
        SET_ACTIVE_TAB({ activeTab: initialActiveTab });
      }
    },
    [segmentedItems, initialActiveTab, SET_ACTIVE_TAB]
  );

  const onSearchClicked = useCallback(() => {
    const searchPageUrl = segmentedItems[SPORTS].searchLink;

    if (searchPageUrl) {
      setIsOpen(false);
      push(searchPageUrl);
    }
  }, [push, segmentedItems, setIsOpen]);

  const setActiveMainMenuTab = useCallback(() => {
    if (!isEmpty(segmentedItems)) {
      const item = Object.keys(segmentedItems).find((i) => {
        const { link, isLink } = segmentedItems[i] || {};

        return !isLink && link && rootPath === link;
      });

      if (item) {
        SET_ACTIVE_TAB({ activeTab: item });
      } else {
        SET_ACTIVE_TAB({ activeTab: initialActiveTab });
      }
    }
  }, [rootPath, segmentedItems, SET_ACTIVE_TAB, initialActiveTab]);

  useEffect(() => {
    if (isOpen) {
      setActiveMainMenuTab();
    }
  }, [isOpen, setActiveMainMenuTab]);

  return (
    <Box
      ref={containerRef}
      direction="column"
      className={isOpen ? container : styles.hidden}
      data-role="burger-menu-inner"
    >
      <Slider
        id={id}
        activeTab={activeTab}
        changeActiveTab={changeActiveTab}
        segmentedItems={segmentedItems}
        isOpen={isOpen}
      />
      {activeTab === LIVE && (
        <LiveSportsMenuBurger
          config={segmentedItems[LIVE]}
          onSearchClicked={onSearchClicked}
          onClick={() => setOpen(false)}
        />
      )}
      {activeTab === SPORTS && (
        <PreMatchSportsBurger
          config={segmentedItems[SPORTS]}
          onSearchClicked={onSearchClicked}
          onClick={() => setOpen(false)}
        />
      )}
      {activeTab === CYBERSPORT && (
        <CyberSportBurger
          config={segmentedItems[CYBERSPORT]}
          onSearchClicked={onSearchClicked}
          onClick={() => setOpen(false)}
        />
      )}
      {activeTab === VIRTUALS && (
        <VirtualSportBurger
          config={segmentedItems[VIRTUALS]}
          onClick={() => setOpen(false)}
        />
      )}
      {activeTab === CASINO && (
        <CasinoCommonBurgerMenu
          onClick={() => setOpen(false)}
          config={segmentedItems[CASINO]}
          casinoType={CASINO_TYPE.CASINO}
        />
      )}
      {activeTab === CASINO_LIVE && (
        <CasinoCommonBurgerMenu
          onClick={() => setOpen(false)}
          config={segmentedItems[CASINO_LIVE]}
          casinoType={CASINO_TYPE.CASINO_LIVE}
        />
      )}
      {activeTab === INSTANT_GAMES && (
        <CasinoCommonBurgerMenu
          onClick={() => setOpen(false)}
          config={segmentedItems[INSTANT_GAMES]}
          casinoType={CASINO_TYPE.INSTANT_GAMES}
        />
      )}
    </Box>
  );
};

export default Menu;
