import {
  addressValidation,
  cityValidation,
  requiredValidator,
  requiredInputSelectValidator,
  zipCodeValidator,
  phoneValidation,
  passwordValidation,
  emailValidation,
  phoneWithCodeValidation,
  phoneWithIsoCodeValidation,
  maxLengthValidator,
  minLengthValidator,
  minValidator,
  maxValidator,
  phoneNumberValidator,
  phoneOnlyNumberValidator,
  phoneComplexValidation,
  minLengthAndCheckPhoneValidator,
  emailValidator,
  creditCardNumberValidator,
  creditCardHolderValidator,
  numberFormatValidator,
  multiple10Validator,
  regExpValidator,
  passwordAsyncValidation,
  usernameValidation,
  ibanValidation,
  latinValidator,
  compareValidator,
  ifcCodeValidator,
  innValidation,
  charsAndDigitsOnlyFormatValidator,
  charsAndAdditionalSymbolsValidator,
  notEmptyStringValidator,
  docRequestedValidator,
  passwordCompareAndCheckAsync,
  binCardNumberValidator,
  amountValidator,
  cpfNumberValidator,
  codeValidator
} from '@alf/validators';

import { FORM_VALIDATION_TYPES } from '../../../constants';

export const formValidationMap = {
  [FORM_VALIDATION_TYPES.REQUIRED]: requiredValidator,
  [FORM_VALIDATION_TYPES.REQUIRED_INPUT_SELECT]: requiredInputSelectValidator,
  [FORM_VALIDATION_TYPES.ZIP_CODE]: zipCodeValidator,
  [FORM_VALIDATION_TYPES.IFC_CODE]: ifcCodeValidator,
  [FORM_VALIDATION_TYPES.CITY]: cityValidation,
  [FORM_VALIDATION_TYPES.ADDRESS]: addressValidation,
  [FORM_VALIDATION_TYPES.PHONE_INPUT]: phoneValidation,
  [FORM_VALIDATION_TYPES.PHONE_WITH_CODE]: phoneWithCodeValidation,
  [FORM_VALIDATION_TYPES.PHONE_WITH_ISO_CODE]: phoneWithIsoCodeValidation,
  [FORM_VALIDATION_TYPES.PHONE_COMPLEX]: phoneComplexValidation,
  [FORM_VALIDATION_TYPES.PASSWORD_REGEX]: passwordValidation,
  [FORM_VALIDATION_TYPES.AMOUNT]: amountValidator,
  [FORM_VALIDATION_TYPES.PASSWORD_COMPARE_AND_CHECK]: passwordCompareAndCheckAsync,
  [FORM_VALIDATION_TYPES.CPF_NUMBER]: cpfNumberValidator,
  [FORM_VALIDATION_TYPES.EMAIL]: emailValidator,
  [FORM_VALIDATION_TYPES.EMAIL_ASYNC]: emailValidation,
  [FORM_VALIDATION_TYPES.INN_ASYNC]: innValidation,
  [FORM_VALIDATION_TYPES.PASSWORD_ASYNC]: passwordAsyncValidation,
  [FORM_VALIDATION_TYPES.USERNAME_ASYNC]: usernameValidation,
  [FORM_VALIDATION_TYPES.PHONE_NUMBER]: phoneNumberValidator,
  [FORM_VALIDATION_TYPES.PHONE_ONLY_NUMBER]: phoneOnlyNumberValidator,
  [FORM_VALIDATION_TYPES.MAX_LENGTH_VALIDATOR]: maxLengthValidator,
  [FORM_VALIDATION_TYPES.MIN_LENGTH_AND_CHECKPHONE_VALIDATOR]: minLengthAndCheckPhoneValidator,
  [FORM_VALIDATION_TYPES.MIN_LENGTH_VALIDATOR]: minLengthValidator,
  [FORM_VALIDATION_TYPES.CREDIT_CARD_NUMBER_VALIDATOR]: creditCardNumberValidator,
  [FORM_VALIDATION_TYPES.CREDIT_CARD_HOLDER_VALIDATOR]: creditCardHolderValidator,
  [FORM_VALIDATION_TYPES.BIN_CARD_NUMBER_VALIDATOR]: binCardNumberValidator,
  [FORM_VALIDATION_TYPES.NUMBER_FORMAT_VALIDATOR]: numberFormatValidator,
  [FORM_VALIDATION_TYPES.CHARS_AND_DIGITS_ONLY_FORMAT_VALIDATOR]: charsAndDigitsOnlyFormatValidator,
  [FORM_VALIDATION_TYPES.CHARS_AND_ADDITIONAL_SYMBOLS_VALIDATOR]: charsAndAdditionalSymbolsValidator,
  [FORM_VALIDATION_TYPES.MIN_VALIDATOR]: minValidator,
  [FORM_VALIDATION_TYPES.MAX_VALIDATOR]: maxValidator,
  [FORM_VALIDATION_TYPES.MULTIPLE_10_VALIDATOR]: multiple10Validator,
  [FORM_VALIDATION_TYPES.REGEXP_VALIDATOR]: regExpValidator,
  [FORM_VALIDATION_TYPES.IBAN_ASYNC]: ibanValidation,
  [FORM_VALIDATION_TYPES.LATIN]: latinValidator,
  [FORM_VALIDATION_TYPES.COMPARE_VALIDATOR]: compareValidator,
  [FORM_VALIDATION_TYPES.NOT_EMPTY_STRING_VALIDATOR]: notEmptyStringValidator,
  [FORM_VALIDATION_TYPES.DOC_REQUESTED]: docRequestedValidator,
  [FORM_VALIDATION_TYPES.CODE_VALIDATOR]: codeValidator
};
