import { useRef, useEffect } from 'react';

// This element is used to catch any clicks and potentially other DOM events from passing through onboarding to underlying site, potentially breaking scenarios. For example, click on overlay or tooltip may trigger closing opened UserMenu, MoreItems or similar popover.
export const DomEventWall = ({ children }) => {
  const ref = useRef();

  useEffect(() => {
    const handleBubble = (e) => {
      e.stopPropagation();
    };
    const el = ref.current; // this is required!

    el.addEventListener('mousedown', handleBubble);

    return () => {
      el.removeEventListener('mousedown', handleBubble);
    };
  }, []);

  return <div ref={ref}>{children}</div>;
};
