import { useState, useContext } from 'react';
import { IS_SERVER_RENDER } from '@namespace/helpers';
import { DeviceContext } from '@namespace/device';
import { useLocation } from '@namespace/i18n';
import { PageContext } from './index';
import getActualPageConfig from '../../../utils/getActualPageConfig';
import { SiteSettingsContext } from '../../siteSettings/context';

const getInitialPageState = ({
  initialPageConfig,
  pageType,
  pathname,
  pathsAliases
}) => {
  const initPageConfig = initialPageConfig || {};

  const pageConfig = IS_SERVER_RENDER
    ? initPageConfig
    : getActualPageConfig({
        initialPageConfig: initPageConfig,
        pageType,
        pathname: pathsAliases[pathname] || pathname
      });

  return {
    page: pageConfig || initialPageConfig || null,
    seoConfig: {}
  };
};

export const PageProvider = ({ children, initialPageConfig }) => {
  const { pageType } = useContext(DeviceContext);
  const { pathname } = useLocation();
  const [settings] = useContext(SiteSettingsContext);
  const { otherSettings = {} } = settings || {};
  const { pathsAliases = {} } = otherSettings;
  const [state, setState] = useState(
    getInitialPageState({ initialPageConfig, pageType, pathname, pathsAliases })
  );

  return (
    <PageContext.Provider value={{ ...state, setState }}>
      {children}
    </PageContext.Provider>
  );
};
