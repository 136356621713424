import { Fragment, useContext, useMemo, memo, useCallback } from 'react';
import {
  CoreList,
  SportList,
  UserSettingsBurger,
  useUserSettings
} from '@namespace/common';
import { useGeneralRoutes } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { AText, Box } from '@alf/uikit';
import {
  VirtualSportsContext,
  useLoadVirtualSportsData,
  useGetVirtualSportsFeedName
} from '@namespace/virtual-sports';
import { SPORT_ICONS } from '../../../constants';
import styles from './index.module.css';

const VirtualSportBurger = ({ config = {}, onClick }) => {
  const { subMenu = [], countable } = config;
  const { t } = useI18n();
  const { virtualSportsOneSport, virtualSportsFrame } = useGeneralRoutes();
  const [{ virtualSports, feed }] = useContext(VirtualSportsContext);
  const { games: feedGames, providers: feedProviders } = feed;
  const virtualSportsFeedName = useGetVirtualSportsFeedName();

  useLoadVirtualSportsData({ feedName: virtualSportsFeedName });

  const allSportsProvidersIds = [
    ...feedProviders.ids,
    ...Object.keys(virtualSports)
  ];

  const subMenuItems = useMemo(
    () =>
      subMenu.map(({ id, link, title }) => ({
        id,
        link,
        title: t(title)
      })),
    [subMenu, t]
  );

  const getMenuItem = useCallback(
    ({ categoryId, categoryName, tournaments, link }) => ({
      id: categoryId,
      icon: SPORT_ICONS[categoryId] || SPORT_ICONS.DEFAULT,
      link,
      title: categoryName,
      count: countable ? tournaments.length : undefined
    }),
    [countable]
  );

  const itemsByProvider = useMemo(() => {
    const outerSportsItems = Object.keys(virtualSports)
      .filter((provider) => Object.keys(virtualSports[provider]).length > 0)
      .reduce((acc, provider) => {
        const sports = Object.values(virtualSports[provider]);
        const items = sports.map(
          ({ categoryId, categoryName, tournaments, isFrame }) =>
            getMenuItem({
              categoryId,
              tournaments,
              link: `${
                isFrame ? virtualSportsFrame : virtualSportsOneSport
              }/${provider}/${categoryId}`,
              categoryName
            })
        );

        return { ...acc, [provider]: items };
      }, {});

    const feedSportsItems = feedProviders.ids.reduce((acc, providerId) => {
      const items = feedProviders.data?.[providerId].games.map((gameId) => {
        const game = feedGames.data?.[gameId];

        return getMenuItem({
          categoryId: game.gameId,
          tournaments: game.tournaments,
          link: `${game.isFrame ? virtualSportsFrame : virtualSportsOneSport}/${
            game.providerId
          }/${game.gameId}`,
          categoryName: game.gameName
        });
      });
      return { ...acc, [providerId]: items };
    }, {});

    return { ...feedSportsItems, ...outerSportsItems };
  }, [virtualSports, feedProviders, feedGames, getMenuItem]);

  const { showTimeZoneSelector, showSettings } = useUserSettings();

  return (
    <div className={styles.ItemsWrapper}>
      <Box bottom="s" direction="column">
        <UserSettingsBurger
          showTimeZoneSelector={showTimeZoneSelector}
          showLineSettings={false}
          showSettings={showSettings}
        />
      </Box>
      <CoreList
        items={subMenuItems}
        classNames={{
          listItemClass: styles.listItem
        }}
        onClick={onClick}
      />
      {allSportsProvidersIds.map((provider) => {
        return (
          <Fragment key={provider}>
            {itemsByProvider[provider] && !!itemsByProvider[provider].length && (
              <Box top="xs" bottom="xs">
                <AText
                  breed="BU"
                  size="xl"
                  color="sm_txt_color_3"
                  className={styles.sportItemTitle}
                >
                  {t(`burgerMenu.virtuals.submenu.${provider}`)}
                </AText>
              </Box>
            )}
            <SportList
              items={itemsByProvider[provider]}
              isPartialLinkMatch={true}
              onClick={onClick}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

export default memo(VirtualSportBurger);
