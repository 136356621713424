import { window } from '@namespace/helpers';
import { LINK_TYPE_RAW } from '../../constants';

export const getALinkFromTarget = (el, container) => {
  if (el.tagName === 'A') {
    return el;
  }

  const parent = el.parentElement;
  if (parent === container || !parent || !container.contains(el)) {
    return null;
  }

  return getALinkFromTarget(parent, container);
};

export const getInternalLinkHref = (a) => {
  // do not handle links with 'data-link-type=raw' attribute set
  if (!a || a.dataset.linkType === LINK_TYPE_RAW) {
    return null;
  }

  // Opt-out of handling for explicit target pointing to non-current document
  const target = a.getAttribute('target');
  if (target != null && ['_blank', '_parent', '_top'].includes(target)) {
    return null;
  }

  const href = a.getAttribute('href');
  const url = new URL(href, window.location);
  if (url.origin !== window.location.origin) {
    return null;
  }

  return href;
};
