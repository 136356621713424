import { useContext } from 'react';
import { AText, Box } from '@alf/uikit';
import { Image } from '@namespace/icons2';
import { DeviceContext } from '@namespace/device';
import { useI18n } from '@namespace/i18n';
import styles from './index.module.css';

const SelfExcludedBlock = ({ selfExclusion }) => {
  const { isMobile } = useContext(DeviceContext);
  const { t, f } = useI18n();
  const { activeTill } = selfExclusion[0];

  const endDate = activeTill
    ? f.getDateTime({ date: activeTill * 1000 }).toFormat('dd.MM.yyyy HH:mm')
    : null;

  return (
    <Box
      className={styles.wrapper}
      direction="column"
      align="center"
      justify="center"
    >
      <Image
        className={styles.image}
        data-role="self-excluded-image"
        name="general/bonus_hunter"
      />
      <AText
        className={styles.text}
        size={isMobile ? '2xl' : '3xl'}
        data-role="self-excluded-text"
        color="txt_color_7"
        breed="BU"
        align="center"
      >
        {t(
          `responsibleGambling.exclusionLimit.finishDate${
            !endDate ? 'indefinite' : ''
          }`
        )}
      </AText>
      {endDate && (
        <AText
          size={isMobile ? '2xl' : '3xl'}
          data-role="self-excluded-text"
          color="txt_color_7"
          breed="BU"
          align="center"
        >
          {endDate}
        </AText>
      )}
    </Box>
  );
};

export default SelfExcludedBlock;
