import { StepperProvider } from '../../store/stepper/context';
import Stepper from './components/Stepper';

const StepperWrapper = ({
  stepsMap = [],
  componentsMap = {},
  customHandlers = {},
  defaultActiveStep,
  defaultFormData = {},
  successMessage,
  header = '',
  onChangeStep = () => {},
  config = {},
  stepsClassNames
}) => (
  <StepperProvider customHandlers={customHandlers}>
    <Stepper
      stepsClassNames={stepsClassNames}
      onChangeStep={onChangeStep}
      header={header}
      stepsMap={stepsMap}
      config={config}
      successMessage={successMessage}
      defaultActiveStep={defaultActiveStep}
      defaultFormData={defaultFormData}
      componentsMap={componentsMap}
    />
  </StepperProvider>
);

export default StepperWrapper;
