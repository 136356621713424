import { camelizeKeys } from 'humps';
import { post, getAsJson } from '@namespace/helpers';

export const getAllPans = ({ userId, accountingId, ssid }) =>
  post(`/pankeeper/frontend_api/get_all_pans/`, {
    user_id: String(userId),
    accounting_id: accountingId,
    ssid
  }).then(camelizeKeys);

export const getAllCards = ({ userId }) =>
  getAsJson(`/accounting/api/v1/users/${userId}/payments/pans`).then(
    camelizeKeys
  );
