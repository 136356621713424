import { useMemo, forwardRef } from 'react';
import GameSkelet from '../GameSkelet';
import Game from '../Game';

// used outside of casino - tournament page games block / virtual popular games.
// doesn't depend on casino settings

export const GameWrapperExternal = forwardRef(
  ({ game, className, index, getGameUrl = () => {}, ...rest }, ref) => {
    const link = useMemo(() => getGameUrl(game), [game, getGameUrl]);

    return game.isPlaceholder ? (
      <GameSkelet ref={ref} className={className} />
    ) : (
      <Game
        ref={ref}
        className={className}
        game={game}
        index={`regular-${index + 1}`}
        {...(link ? { link } : {})}
        {...rest}
      />
    );
  }
);
