import { useContext, useEffect, useState } from 'react';
import { autoUpdate } from '@floating-ui/react-dom';
import { document } from '@namespace/helpers';
import { DeviceContext } from '@namespace/device';
import { useCurrentStep } from '../../../../../hooks';
import {
  getVisibleRects,
  makeOverlayPath,
  makeWindowPath,
  padRect
} from '../utils';
import { OnboardingContext } from '../../../../../store/context';
import { useTargetEls } from '../../../hooks';

export const useSpotlightSvgPath = () => {
  const [{ isInterrupted, padding: globalPadding, stepIndex }] = useContext(
    OnboardingContext
  );
  const { isDesktop } = useContext(DeviceContext);
  const {
    target,
    padding = globalPadding,
    scrollOffset,
    spotlightBorderRadius
  } = useCurrentStep();
  const [holes, setHoles] = useState([]);

  const [path, setPath] = useState(makeWindowPath());
  const els = useTargetEls();

  // prevent previous el highlight during step change
  useEffect(() => {
    setPath(makeWindowPath());
  }, [stepIndex]);

  useEffect(() => {
    let clearFn;
    let isCancelled = false;

    // do not clear svg overlay on exit, or it'll produce blinking
    const cleanEffect = () => {
      isCancelled = true;
      clearFn?.();
    };

    if (!target || isInterrupted) {
      const updateEmptyOverlay = () => setPath(makeWindowPath());
      // track window resize to make sure overlay always covers the entire screen
      clearFn = autoUpdate(document.body, document.body, updateEmptyOverlay);

      return cleanEffect;
    }

    if (!els) {
      return cleanEffect;
    }

    const updateOverlay = async () => {
      const rawHoles = await getVisibleRects(els, scrollOffset, isDesktop);

      // async nature of getVisibleRect leads to race condition
      if (isCancelled) {
        return;
      }

      const newHoles = rawHoles
        .filter(Boolean)
        .map((hole) => padRect(hole, padding));
      const newPath = makeOverlayPath(newHoles, spotlightBorderRadius);

      setHoles(newHoles);
      setPath(newPath);
    };

    clearFn = autoUpdate(document.body, els[0], updateOverlay);

    return cleanEffect;
  }, [
    els,
    isInterrupted,
    padding,
    scrollOffset,
    spotlightBorderRadius,
    target
  ]);

  return { holes, path };
};
