import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useI18n } from '@namespace/i18n';
import { Checkbox } from '@alf/uikit';
import getFormError from '../../utils/getFormError';
import Control from '../Control';
import useDynamicField from '../../hooks/useDynamicField';
import useCustomError from '../../hooks/useCustomError';
import useOnChangeValidate from '../../hooks/useOnChangeValidate';

const CheckboxField = ({
  name,
  label = '',
  LabelComponent,
  classNames = {},
  rules = {},
  isOnChangeValidation = false,
  hint,
  onChange = () => {},
  customError = '',
  formProps = {},
  ...props
}) => {
  const { t } = useI18n();
  const { wrapperClassName = '' } = classNames;
  const methods = useFormContext();
  const {
    control,
    formState: { errors },
    watch
  } = methods;
  const fieldWatched = watch(name);
  const { dynamicValues = {} } = formProps;
  useDynamicField({ name, dynamicFieldData: dynamicValues[name] });
  const { errorMessage, customErrorHandler } = useCustomError(customError);

  useOnChangeValidate({ name, isOnChangeValidation });

  useEffect(() => onChange(fieldWatched), [fieldWatched]);

  return (
    <Control
      error={getFormError(name, errors, t) || t(errorMessage)}
      onClick={customErrorHandler}
      hint={hint}
      classNames={{ wrapperClassName }}
    >
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { ref, ...field } }) => {
          const { value: controllerValue, ...controllerFields } = field;
          return (
            <Checkbox
              checked={controllerValue}
              name={name}
              label={LabelComponent || t(label)}
              classNames={classNames}
              {...controllerFields}
              {...props}
            />
          );
        }}
      />
    </Control>
  );
};

export default CheckboxField;
