// based on https://github.com/ivoilic/zustand-ards

import { prop } from 'ramda';
import { shallow } from 'zustand/shallow';

/**
 * Allows using smart selectors in created store.
 * Selector may accept one of as first arg:
 * 1. A string or a number: a property of the provided name is selected.
 * 2. A function: function is a selector itself, takes whole store as an argument which should produce selected data. Compare function is default - Object.is.
 * 3. An array of strings or numbers: selects props with provided names, result is an object and compare function is `shallow` by default.
 * 4. Empty (undefined): returns whole store. NOT RECOMMENDED.
 *
 * Second argument is an optional compare function which decides if result is actually changed or not. By default, `Object.is` is used. Most common option here is `shallow` (present in 'zustand/shallow' package), but you may provide any arbitrary function with matching interface. Be aware, however, unnecessary comparisons may take heavy tax on performance.
 */
export const withSmartSelectors = (storeHook) => {
  /**
   * @param {String|Number|Function|Array<String|Number>} [selector]
   * @param {(a:*, b:*) => Boolean} [equals]
   * @returns {*}
   */
  const smartStoreHook = (selector, equals) => {
    if (selector == null || typeof selector === 'function') {
      return storeHook(selector, equals);
    }

    if (typeof selector === 'string' || typeof selector === 'number') {
      return storeHook(prop(selector), equals);
    }

    // for array - shallow equality by default
    const selectorFn = (state) => {
      const selection = {};

      for (const key of selector) {
        selection[key] = state[key];
      }

      return selection;
    };

    return storeHook(selectorFn, equals ?? shallow);
  };

  smartStoreHook.getState = storeHook.getState;
  smartStoreHook.setState = storeHook.setState;
  smartStoreHook.subscribe = storeHook.subscribe;
  smartStoreHook.destroy = storeHook.destroy;

  return smartStoreHook;
};
