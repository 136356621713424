import { postRPC } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

export const getMarketGroups = ({ lang, serviceId = 0, sportId }) => {
  const request = {
    by: {
      lang,
      service_id: serviceId,
      sport_id: sportId
    }
  };

  return postRPC('frontend/market_group/get', request).then(camelizeKeys);
};
