import { createContext, useState, useEffect, useCallback } from 'react';
import { window } from '@namespace/helpers';
import useScreenSize from '../../hooks/useScreenSize';
import detectDevice from '../../utils/detectDevice';
import detectOrientation from '../../utils/detectOrientation';
import updatePageType from '../../utils/updatePageType';

export const DeviceContext = createContext();

export const DeviceProvider = ({ children, initialDeviceOptions }) => {
  const screenType = useScreenSize();
  const [device, setDevice] = useState(
    window.IS_SSR && initialDeviceOptions
      ? initialDeviceOptions
      : detectDevice(screenType)
  );

  const handleOrientation = useCallback(() =>
    setDevice(
      (prevDevice) => ({
        ...prevDevice,
        ...detectOrientation()
      }),
      []
    )
  );

  useEffect(() => {
    if (initialDeviceOptions) {
      handleOrientation();
    }
  }, [initialDeviceOptions]);

  useEffect(() => {
    window.addEventListener('orientationchange', handleOrientation);
    return () =>
      window.removeEventListener('orientationchange', handleOrientation);
  }, []);

  useEffect(() => {
    setDevice((prevDevice) => ({
      ...prevDevice,
      ...updatePageType(screenType)
    }));
  }, [screenType]);

  return (
    <DeviceContext.Provider value={device}>{children}</DeviceContext.Provider>
  );
};
