import { postRPC } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

export const getSports = async ({
  lang,
  serviceId = 0,
  tzDiff,
  date,
  timeRange,
  startDate,
  endDate
}) => {
  const request = {
    by: {
      lang,
      service_id: serviceId,
      in_play: true,
      event_count: 'sport_id',
      tz_diff: tzDiff,
      date,
      time_range: timeRange,
      start_dt: startDate,
      end_dt: endDate
    }
  };

  const results = await postRPC(
    'frontend/sport/get',
    request
  ).then(({ result }) => camelizeKeys(result));

  return results;
};
