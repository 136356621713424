export const types = {
  SET_SPEECH: 'SET_SPEECH',
  SPORTS_BOOK_SEARCH: 'sportsbook-search',
  CASINO_SEARCH: 'casino-search',
  REDIRECT: 'redirect',
  DISABLE_ASSISTANT: 'disable-assistant',
  GET_DICTIONARY: 'GET_DICTIONARY',
  SET_DICTIONARY: 'SET_DICTIONARY',
  SET_VISIBLE_MOBILE_BANNER: 'SET_VISIBLE_MOBILE_BANNER',
  SET_SUPPORT_SPEECH: 'SET_SUPPORT_SPEECH'
};
