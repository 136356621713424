export const PAYMENT_SYSTEMS = {
  FP: 1,
  SKRILL: 3,
  SKRILL_NEW: 610,
  CASHDESK: 4,
  W1: 200,
  VISA_MASTER_W1: 226,
  SAFECHARGE_NEW: 236,
  NETELLER: 300,
  EPAY: 307,
  EPAY_NEW: 336,
  YANDEX_MONEY: 310,
  ECOPAYZ: 312,
  SAFECHARGE_OLD: 314,
  ADVCASH: 318,
  IDRAM: 313,
  ARTPAY: 444,
  BPC: 500,
  BPC_NEW: 337,
  BPAY: 502,
  MUCH_BETTER: 503,
  RUNPAY: 506,
  PRAXIS: 509,
  HEXOPAY: 507,
  RAPID_TRANSFER: 601,
  AIR_CASH: 10096,
  PAY_CEK: 10167,
  POSLOVNICA: 221,
  ABON: 10097,
  CORVUS: 10000,
  DEPOSIT: 221,
  DCC: 609,
  BITCOIN: 608,
  COINSPAID: 612,
  EPAY_JS: 10119,
  INTER_KASSA: 614,
  UPI: 10170,
  PAY_TM: 10169,
  NET_BANKING: 10168
};

export const PAYMENT_SYSTEM_KEYS = {
  ABON: 'abon',
  PAY_CEK: 'payCek',
  POSLOVNICA: 'poslovnica',
  CORVUS_PAY: 'corvus',
  DEPOSIT: 'deposit',
  AIR_CASH: 'airCash',
  NETELLER: 'neteller',
  SKRILL: 'skrill'
};

export const MOBILE_MONEY_KYIVSTAR_SERVICE_ID = 10111;
export const INTER_KASSA_WALLET_ID = 'Interkassa_NodeJS';
export const INTER_KASSA_WALLET_ACCOUNT_ID = '34136_INR';
export const COINS_PAID_WALLET_ID = 'CoinsPaid';
export const COINS_PAID_WALLET_ACCOUNT_ID = '29411_UAH';
export const airCashKey = 'AIR_CASH';
export const corvusPayKey = 'CORVUS_PAY';
export const AIRCASH_PAY_RON_ID = 10274;
