import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { COLOR_SCHEME } from '@namespace/themes';
import { CoefTypeSwitcher, TimeZoneSwitcher } from '@namespace/common';
import { useConfig } from '@namespace/cms';
import { useSetLineView } from '@namespace/user';
import SettingsLineView from '../../common/SettingsLineView';
import LanguageSelect from '../../common/Language/LanguageSelect';
import SettingsSchemeSwitcher from '../../common/SettingsSchemeSwitcher';
import styles from './index.module.css';

const Settings = ({ id }) => {
  const { t } = useI18n();
  const { config = {} } = useConfig(id);
  const {
    isShowLanguage = true,
    isShowLineView = true,
    isShowOdds = true,
    isShowThemes = true,
    isShowTimeZone = true
  } = config;

  useSetLineView();

  return (
    <Box
      data-role="settings-wrapper"
      className={styles.wrapper}
      direction="column"
      padding="m"
    >
      {isShowLanguage && (
        <Box
          data-role="settings-language-container"
          className={styles.sectionWrapper}
          direction="column"
        >
          <AText
            data-role="settings-language-title"
            className={styles.title}
            breed="R"
            size="m"
            color="txt_color_2"
          >
            {t('web.settings.language.title')}
          </AText>
          <LanguageSelect
            title="web.settings.language"
            triggerType="combine"
            arrowSize="xs"
            classNames={{
              flagIconClass: styles.languageFlagIcon,
              containerClass: styles.languageSelect,
              additionalMobileSelectStyleClass: styles.additionalLanguageSelect
            }}
          />
        </Box>
      )}
      {isShowThemes && (
        <Box
          data-role="settings-color-scheme-container"
          className={styles.sectionWrapper}
          direction="column"
        >
          <AText
            data-role="settings-color-scheme-title"
            className={styles.title}
            breed="R"
            size="m"
            color="txt_color_2"
          >
            {t('web.settings.theme.title')}
          </AText>
          <SettingsSchemeSwitcher />
        </Box>
      )}
      {isShowTimeZone && (
        <Box
          data-role="settings-time-zone-container"
          className={styles.sectionWrapper}
          direction="column"
        >
          <AText
            data-role="settings-time-zone-title"
            className={styles.title}
            breed="R"
            size="m"
            color="txt_color_2"
          >
            {t('web.settings.timezone.title')}
          </AText>
          <TimeZoneSwitcher
            type="header"
            switcherClassName={styles.switcherContainer}
            customTriggerClassNames={{
              className: styles.customTriggerContainer
            }}
            intentType={COLOR_SCHEME.PRIMARY}
          />
        </Box>
      )}
      {isShowOdds && (
        <Box
          data-role="settings-coefficients-container"
          className={styles.sectionWrapper}
          direction="column"
        >
          <AText
            data-role="settings-coefficients-title"
            className={styles.title}
            breed="R"
            size="m"
            color="txt_color_2"
          >
            {t('web.settings.coefficients.title')}
          </AText>
          <CoefTypeSwitcher
            switcherClassName={styles.switcherContainer}
            customTriggerClassNames={{
              className: styles.customTriggerContainer
            }}
            intentType={COLOR_SCHEME.PRIMARY}
          />
        </Box>
      )}
      {isShowLineView && (
        <Box
          data-role="settings-line-view-container"
          className={styles.sectionWrapper}
          direction="column"
        >
          <AText
            data-role="settings-line-view-container"
            className={styles.title}
            breed="R"
            size="m"
            color="txt_color_2"
          >
            {t('web.settings.lineview.title.mobile')}
          </AText>
          <SettingsLineView iconOptions={{ size: 'm' }} />
        </Box>
      )}
    </Box>
  );
};

export default Settings;
