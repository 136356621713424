import { useContext } from 'react';
import { Box } from '@alf/uikit';
import { PageContext } from '@namespace/cms';
import { MultiColumnContent } from '../common/MultiColumnContent';
import { ColumnContent } from '../common/ColumnContent';
import { SCROLLABLE_COLUMN } from '../../../../constants';
import styles from '../index.module.css';

export const OneColumnThreeColumn = () => {
  const { page } = useContext(PageContext);
  const { layout = {} } = page;
  const { columns = [], columnsWithIndependentScroll } = layout;
  const isScrollable = columnsWithIndependentScroll?.[0];
  const oneColumnCls = [
    styles.oneColumnContainer,
    isScrollable && `${styles.scrollableColumn} ${SCROLLABLE_COLUMN}`
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <>
      <Box direction="column" className={oneColumnCls}>
        <ColumnContent column={columns[0]} isScrollable={isScrollable} />
      </Box>
      <Box className={styles.threeColumnsContainer}>
        <MultiColumnContent
          columns={columns.slice(1)}
          scrollableColumns={columnsWithIndependentScroll?.slice(1)}
          columnClassName={styles.column}
        />
      </Box>
    </>
  );
};
