import { useContext } from 'react';
import { useI18n } from '@namespace/i18n';
import { AText } from '@alf/uikit';
import { DeviceContext } from '@namespace/device';

import VerificationStatusNotification from '../VerificationStatusNotification';

const VerifiedUserToast = ({ onClose }) => {
  const { t } = useI18n();
  const { isDesktop } = useContext(DeviceContext);
  const text = t('verifiedUser.notification');
  const message = isDesktop ? (
    text
  ) : (
    <AText color="txt_color_3" size="l" align="center">
      {t('verifiedUser.notification')}
    </AText>
  );

  return <VerificationStatusNotification onClose={onClose} message={message} />;
};

export default VerifiedUserToast;
