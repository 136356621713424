import { types } from '../types';

export const reducer = {
  [types.SET_PRODUCT_LIST]: (state, payload) => ({
    ...state,
    products: payload
  }),
  [types.SET_FILTERED_PRODUCTS_LIST]: (state, payload) => ({
    ...state,
    filteredProducts: payload
  }),
  [types.SET_PRICE_RANGE]: (state, payload) => ({
    ...state,
    priceRange: payload
  }),
  [types.SET_CATEGORY_LIST]: (state, payload) => ({
    ...state,
    categories: payload
  }),
  [types.SET_USER_INFO]: (state, { deposit, ...payload }) => ({
    ...state,
    userBonusInfo: {
      ...payload,
      deposit: Math.floor(deposit)
    }
  }),
  [types.ADD_SHOP_ITEM]: (state, payload) => ({
    ...state,
    selectedProducts: {
      ...state.selectedProducts,
      [payload]: (state.selectedProducts[payload] || 0) + 1
    }
  }),
  [types.REMOVE_SHOP_ITEM]: (state, payload) => {
    const { [payload]: del, ...rest } = state.selectedProducts;
    const newValue = del - 1;
    return {
      ...state,
      selectedProducts: {
        ...(newValue === 0 ? { ...rest } : { [payload]: newValue, ...rest })
      }
    };
  },
  [types.REMOVE_ONE_STACK]: (state, payload) => {
    const { [payload]: remove, ...rest } = state.selectedProducts;
    return {
      ...state,
      selectedProducts: {
        ...rest
      }
    };
  },
  [types.SET_TOTAL_AMOUNT]: (state, payload) => ({
    ...state,
    totalAmount: payload
  }),
  [types.CLEAR_BASKET]: (state) => ({
    ...state,
    selectedProducts: {},
    totalAmount: 0
  })
};
