import { Box } from '@alf/uikit';
import StatusVerified from './StatusVerified';
import StatusPending from './StatusPending';
import StatusNotVerified from './StatusNotVerified';
import StatusAdditionalInformation from './StatusAdditionalInformation';
import { DOCUMENT_STATUSES } from '../../constants';

const keyMap = {
  [DOCUMENT_STATUSES.VERIFIED]: StatusVerified,
  [DOCUMENT_STATUSES.PENDING]: StatusPending,
  [DOCUMENT_STATUSES.NOT_VERIFIED]: StatusNotVerified,
  [DOCUMENT_STATUSES.ADDITIONAL_INFORMATION]: StatusAdditionalInformation
};

const StatusInformation = ({ document }) => {
  const { status = '' } = document;

  return <Box>{keyMap[status] ? keyMap[status]() : null}</Box>;
};

export default StatusInformation;
