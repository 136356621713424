import { useEffect, useMemo } from 'react';
import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { useCountdown } from '@namespace/helpers';
import { Icon } from '@namespace/icons2';
import { PROMO_TIMER_TYPES } from '../../../../constants';
import { promotionsActions } from '../../../../store';
import styles from './index.module.css';

const { SET_TIMER_IS_COMPLETED } = promotionsActions;

const Timer = ({
  periodStart = '',
  periodEnd = '',
  timerType = '',
  dateTextColor = '',
  isPromoItem = false,
  isPromoPage = false
}) => {
  const { t, f } = useI18n();

  const time = useMemo(() => {
    if (timerType === PROMO_TIMER_TYPES.END) {
      return f.getDateTime({ date: periodEnd }).ts - f.getDateTime().toMillis();
    }
    if (timerType === PROMO_TIMER_TYPES.BEGIN) {
      return (
        f.getDateTime({ date: periodStart }).ts - f.getDateTime().toMillis()
      );
    }
    return null;
  }, [f, periodEnd, periodStart, timerType]);

  const { days, hours, minutes, seconds, isCompleted } = useCountdown(time);

  useEffect(() => {
    if (isCompleted) {
      SET_TIMER_IS_COMPLETED(isCompleted);
    }
  }, [isCompleted]);

  return (
    <Box direction="column" align="center" className={styles.wrapper}>
      <AText
        breed="B"
        size={isPromoItem ? 'xs' : 's'}
        color={dateTextColor}
        data-role="promotions-timer-text"
      >
        {t(`web.promo.timer.${timerType}`)}
      </AText>
      <Box data-role="promotions-timer" align="center">
        <Icon
          name={`icons/general/state/${
            timerType === PROMO_TIMER_TYPES.END ? 'will-end-in' : 'will-begin'
          }`}
          color={isPromoPage ? 'txt_color_4' : 'txt_color_2'}
          className={styles.timerIcon}
          size={isPromoPage ? '4xl' : 'l'}
        />
        <Box direction="column" align="center">
          <AText
            breed="B"
            size={isPromoItem ? '2xl' : '4xl'}
            color={dateTextColor}
            className={styles.timerItem}
          >
            {days}
          </AText>
          <AText
            breed="R"
            size={isPromoItem ? 'xs' : 's'}
            color={dateTextColor}
            className={styles.timerItem}
          >
            {t('web.promo.timer.days')}
          </AText>
        </Box>
        <Box direction="column" align="center">
          <AText
            breed="B"
            size={isPromoItem ? '2xl' : '4xl'}
            color={dateTextColor}
            className={styles.timerItem}
          >
            {hours}
          </AText>
          <AText
            breed="R"
            size={isPromoItem ? 'xs' : 's'}
            color={dateTextColor}
            className={styles.timerItem}
          >
            {t('web.promo.timer.hours')}
          </AText>
        </Box>
        <Box direction="column" align="center">
          <AText
            breed="B"
            size={isPromoItem ? '2xl' : '4xl'}
            color={dateTextColor}
            className={styles.timerItem}
          >
            {minutes}
          </AText>
          <AText
            breed="R"
            size={isPromoItem ? 'xs' : 's'}
            color={dateTextColor}
            className={styles.timerItem}
          >
            {t('web.promo.timer.minutes')}
          </AText>
        </Box>
        <Box direction="column" align="center">
          <AText
            breed="B"
            size={isPromoItem ? '2xl' : '4xl'}
            color={dateTextColor}
            className={styles.timerItem}
          >
            {seconds}
          </AText>
          <AText
            breed="R"
            size={isPromoItem ? 'xs' : 's'}
            color={dateTextColor}
            className={styles.timerItem}
          >
            {t('web.promo.timer.seconds')}
          </AText>
        </Box>
      </Box>
    </Box>
  );
};

export default Timer;
