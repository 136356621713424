import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookieManagementEnabled } from '@namespace/cms/src/hooks/useCookieManagementEnabled';
import useOtherSettings from '@namespace/cms/src/hooks/useOtherSettings';
import { useI18n } from '../useI18n';
import { addLang, removeLang } from '../../utils/language';

const useLanguage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setLanguage } = useI18n();
  const otherSettings = useOtherSettings();
  const cookieManagementEnabled = useCookieManagementEnabled();

  const { hiddenLanguageInUrl } = otherSettings;

  const setNewLanguage = useCallback(
    async (lang) => {
      await setLanguage({ lang, cookieManagementEnabled });
      navigate(
        hiddenLanguageInUrl === lang
          ? removeLang(location)
          : addLang(location)(lang),
        {
          replace: true
        }
      );
    },
    [location, navigate, setLanguage, hiddenLanguageInUrl]
  );

  return [setNewLanguage];
};

export default useLanguage;
