import { passValidationRulesList, rePatterns } from '@alf/validators';

const prepareLabel = (label, process) =>
  Array.isArray(label) ? process(...label) : process(label);
const prepareRules = (rules) =>
  rules instanceof RegExp ? rules : rePatterns[rules];

export const rulesPassConverter = (
  process,
  rulesList = passValidationRulesList
) =>
  rulesList.map((validationRule) => {
    const label = prepareLabel(validationRule.label, process);
    const rules = prepareRules(validationRule.rules);

    return { label, rules };
  });

export const passwordRules = (process, rulesList, extRules = []) => [
  ...rulesPassConverter(process, rulesList),
  ...extRules
];
