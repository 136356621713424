import { useI18n } from '@namespace/i18n';
import { Box } from '@alf/uikit';
import useLiveEventState from '../../hooks/useLiveEventState';
import EventLabel from '../EventLabel';
import EventTime from '../EventTime';
import TimerCounter from '../TimerCounter';
import { useEventFields } from '../../store';
import { useEventTimerCounters } from '../../hooks/useTimerCounter';
import styles from './index.module.css';

const EventState = ({
  eventHeadContainerClass = '',
  lineView,
  eventId,
  canceled = false,
  finished = false,
  inprogress = false
}) => {
  const { t } = useI18n();
  const { eventStatusType, eventResultName, eventTimer } = useEventFields({
    eventId,
    fields: ['eventStatusType', 'eventResultName', 'eventTimer']
  });

  const { emptyStateText, labelText } = useLiveEventState(eventId);
  const timerCounters = useEventTimerCounters(eventTimer);

  return (
    <Box
      data-role={`event-${eventId}-date-time`}
      className={eventHeadContainerClass}
    >
      {eventTimer ? (
        <EventLabel
          className={`${styles.live} ${lineView ? styles[lineView] : ''}`}
          dataRole={`event-${eventId}-label`}
        >
          <TimerCounter {...timerCounters} />
        </EventLabel>
      ) : (
        (Boolean(emptyStateText) || Boolean(eventStatusType)) && (
          <EventLabel
            className={`${styles.live} ${lineView ? styles[lineView] : ''}`}
            dataRole={`event-${eventId}-label`}
            text={
              emptyStateText !== ''
                ? emptyStateText
                : t(`sportsBook.eventState.${eventStatusType}`)
            }
          />
        )
      )}
      {finished || inprogress || canceled ? (
        <EventTime
          dataRole={`event-${eventId}-time`}
          eventId={eventId}
          lineView={lineView}
        />
      ) : (
        <EventLabel
          text={labelText || eventResultName}
          dataRole={`event-${eventId}-label`}
          className={`${styles.time} ${lineView ? styles[lineView] : ''}`}
        />
      )}
    </Box>
  );
};

export default EventState;
