import { memo } from 'react';
import { Icon } from '@namespace/icons2';
import { AText, Box } from '@alf/uikit';
import { uniqueId } from 'lodash';
import { PENALTY_GOAL_SCOPE_ID, BULLETS_GOAL_SCOPE_ID } from '../../constants';
import {
  COLUMN_TYPES,
  TEXT_BREED_BY_COLUMN_TYPES
} from '../../../../constants';
import styles from './index.module.css';

const Score = memo(({ type, scores = [], lineView, isShortVersion }) => {
  switch (type) {
    case COLUMN_TYPES.ROUND_ICON:
      if (!scores.length && !isShortVersion) {
        return <div className={styles.roundTypeCellEmpty} />;
      }

      return scores.map((score) => (
        <Box
          className={`${styles.penaltyCell} ${
            lineView ? styles[lineView] : ''
          } ${!isShortVersion ? styles.fullVersionCell : ''}`}
          key={uniqueId('scoreCell_')}
        >
          <Icon
            size="xs"
            color={Number(score) ? 'state_success' : 'state_error'}
            name={`icons/general/state/${
              Number(score) ? 'success' : 'canceled'
            }`}
          />
        </Box>
      ));

    case COLUMN_TYPES.PENALTY:
    case COLUMN_TYPES.BULLETS:
      return scores.map(({ scopeId }) => (
        <Box
          className={`${styles.penaltyCell} ${
            lineView ? styles[lineView] : ''
          } ${!isShortVersion ? styles.fullVersionCell : ''}`}
          key={uniqueId('scoreCell_')}
        >
          {scopeId !== 0 && (
            <Icon
              size="xs"
              color={
                scopeId === PENALTY_GOAL_SCOPE_ID ||
                scopeId === BULLETS_GOAL_SCOPE_ID
                  ? 'state_success'
                  : 'state_error'
              }
              name={`icons/general/state/${
                scopeId === PENALTY_GOAL_SCOPE_ID ||
                scopeId === BULLETS_GOAL_SCOPE_ID
                  ? 'success'
                  : 'canceled'
              }`}
            />
          )}
        </Box>
      ));

    case COLUMN_TYPES.POINTS:
      return scores.map((score) => (
        <AText
          key={uniqueId('scoreCell_')}
          className={`${styles.scoreCell} ${styles.separated} ${
            lineView ? styles[lineView] : ''
          } ${!isShortVersion ? styles.fullVersionCell : ''}`}
          color="line_txt_1"
          size="m"
        >
          {score}
        </AText>
      ));

    default:
      return scores.map((score) => (
        <AText
          key={uniqueId('scoreCell_')}
          className={`${styles.scoreCell} ${lineView ? styles[lineView] : ''} ${
            !isShortVersion ? styles.fullVersionCell : ''
          }`}
          breed={type === COLUMN_TYPES.TOTAL ? 'B' : 'R'}
          color="line_txt_1"
          size="m"
        >
          {score}
        </AText>
      ));
  }
});

/**
 * Column component for score table
 * @param {string} type; for now can be ["default", "total", "penalty"]
 * @param {string} tag
 * @param {string} tagIcon
 * @param {string} tagIconColor
 * @param {(string | number)[]} scores
 * @param {boolean} isShortVersion
 * @param {string} lineView
 * @returns {*}
 * @constructor
 */
const Column = ({
  type,
  tag,
  tagIconColor,
  tagIcon,
  scores,
  isShortVersion,
  lineView
}) =>
  scores ? (
    <Box direction="column">
      {!isShortVersion && (
        <Box align="center" justify="center" className={styles.title}>
          {tagIcon ? (
            <Icon
              name={`icons/event/${tagIcon}`}
              size="xs"
              color={tagIconColor}
            />
          ) : (
            <AText
              color="line_txt_3"
              breed={TEXT_BREED_BY_COLUMN_TYPES[type]}
              size="m"
            >
              {tag}
            </AText>
          )}
        </Box>
      )}
      <Box direction="column">
        <Score
          type={type}
          scores={scores}
          lineView={lineView}
          isShortVersion={isShortVersion}
        />
      </Box>
    </Box>
  ) : null;

export default memo(Column);
