import { useContext } from 'react';
import {
  FORM_COMPONENTS_TYPES,
  FORM_VALIDATION_TYPES,
  FormBuilder
} from '@namespace/forms';
import { SiteSettingsContext } from '@namespace/cms';
import { Box, InfoMessage } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import {
  PAYMENT_SYSTEMS_COMPONENTS_MAP,
  paymentSystemsComponentsMap
} from '../constants';
import {
  BUILT_IN_TAX_RATE_TYPES,
  BUILT_IN_TAX_RATES
} from '../components/constants';
import useServiceLimits from '../../../../hooks/useServiceLimits';
import styles from '../index.module.css';

export default ({
  wallet: {
    currency,
    walletId,
    baseBalance,
    minWithdraw: minWithdAmount,
    maxWithdraw: maxWithdAmount,
    paymentInstrumentName,
    taxRate,
    serviceId
  },
  defaultAmount = '',
  onWithdraw
}) => {
  const [settings] = useContext(SiteSettingsContext);
  const { siteConfig = {} } = settings;
  const { taxType = null } = siteConfig;
  const { t } = useI18n();
  const { minWithdraw: minWithd, maxWithdraw: maxWithd } = useServiceLimits(
    serviceId
  );
  const minWithdraw = minWithd || minWithdAmount;
  const maxWithdraw = maxWithd || maxWithdAmount;
  const minAmount = 1;
  const defaultValues = {
    amount: defaultAmount,
    password: '',
    wallet: `${paymentInstrumentName} ${walletId}`
  };
  const componentsList = [
    {
      type: FORM_COMPONENTS_TYPES.INPUT_FIELD,
      name: 'wallet',
      label: 'balance.withdraw.form.cardDescription',
      disabled: true,
      classNames: {
        wrapperClassName: styles.fieldWrap
      }
    },
    {
      type: FORM_COMPONENTS_TYPES.AMOUNT_INPUT_FIELD,
      name: 'amount',
      currency,
      label: 'balance.withdraw.form.amount',
      placeholder: 'balance.deposit.creditCard.amountPlaceholder',
      min: minAmount,
      validationTypes: [
        FORM_VALIDATION_TYPES.REQUIRED,
        {
          type: FORM_VALIDATION_TYPES.MIN_VALIDATOR,
          parameters: [
            minWithdraw || minAmount,
            'balance.withdraw.form.minAmount',
            [currency]
          ]
        },
        {
          type: FORM_VALIDATION_TYPES.MAX_VALIDATOR,
          parameters: [
            maxWithdraw || baseBalance,
            'balance.withdraw.form.maxAmount',
            [currency]
          ]
        }
      ],
      classNames: {
        containerClassName: styles.fieldWrap,
        controlClassName: styles.controlWrap
      },
      isOnChangeValidation: true
    },
    {
      type: PAYMENT_SYSTEMS_COMPONENTS_MAP.TAX_RATE,
      name: 'taxRate',
      taxRate:
        taxType !== null && taxType === BUILT_IN_TAX_RATE_TYPES.UA
          ? BUILT_IN_TAX_RATES[BUILT_IN_TAX_RATE_TYPES.UA]
          : taxRate
    },
    {
      type: FORM_COMPONENTS_TYPES.PASSWORD_FIELD,
      name: 'password',
      classNames: {
        wrapperClassName: styles.fieldWrap
      },
      rulesList: [],
      label: 'balance.withdraw.form.password',
      placeholder: 'balance.withdraw.form.enterAccountPassword',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED]
    },
    {
      type: FORM_COMPONENTS_TYPES.SUBMIT_BUTTON,
      value: 'balance.withdraw.form.submit',
      classNames: {
        className: styles.withdrawButton
      }
    }
  ];

  return (
    <Box align="start" direction="column">
      <InfoMessage icon="info" className={styles.info}>
        {t('balance.withdraw.bc.info')}
      </InfoMessage>
      <FormBuilder
        wrapperClassName={styles.form}
        components={componentsList}
        componentsMap={paymentSystemsComponentsMap}
        onSubmit={onWithdraw}
        formConfig={{ mode: 'onBlur', defaultValues }}
      />
    </Box>
  );
};
