import { useCallback, useContext } from 'react';
import { UserContext } from '../../context';

const useShowQuickDeposit = () => {
  const [user, userActions] = useContext(UserContext);
  const { SHOW_QUICK_DEPOSIT } = userActions;
  const { isShowQuickDeposit } = user;
  const toggleQuickDeposit = useCallback(() => {
    SHOW_QUICK_DEPOSIT(!isShowQuickDeposit);
  }, [isShowQuickDeposit, SHOW_QUICK_DEPOSIT]);

  return {
    toggleQuickDeposit,
    isShowQuickDeposit
  };
};

export default useShowQuickDeposit;
