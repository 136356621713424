import luhnValidator from '../luhnValidator';
import cardTypeValidator from '../cardTypeValidator';

const creditCardNumberValidator = (
  message = 'validators.creditCardValidator',
  digitLimits = [16]
) => ({
  validate: (value) => {
    const cardNumber = value.replace(/\D/g, '');

    if (!digitLimits.includes(cardNumber.length)) {
      return message;
    }
    if (!luhnValidator(cardNumber) || !cardTypeValidator(cardNumber)) {
      return message;
    }

    return true;
  }
});

export default creditCardNumberValidator;
