import { useContext, useEffect } from 'react';
import { NOTIFICATION_TYPES, useNotifications } from '@namespace/notifications';
import { useGetJackpotWin } from '../../jackpotsContext/selectors';
import { JackpotsContext } from '../../jackpotsContext';
import useGetInHouseJackpotSettings from '../useGetInHouseJackpotSettings';

// show in case if user has closed the game but jackpot win was triggered

export const useShowInHouseJpWinNotificationInLobby = (showInhouseJackpot) => {
  const [, { SET_IN_HOUSE_JACKPOT_WIN }] = useContext(JackpotsContext);
  const jackpotWin = useGetJackpotWin();
  const { customNotification } = useNotifications();
  const { winNotification = {} } = useGetInHouseJackpotSettings();

  useEffect(() => {
    if (
      !jackpotWin ||
      !showInhouseJackpot ||
      !winNotification?.isShowOnCasino
    ) {
      return;
    }

    customNotification({
      notificationType: NOTIFICATION_TYPES.IN_HOUSE_JACKPOT_WIN,
      timeout: null,
      message: {
        amount: jackpotWin.userRealAmount,
        currency: jackpotWin.userCurrency,
        jackpotId: jackpotWin.jackpotId
      }
    });
    SET_IN_HOUSE_JACKPOT_WIN(null);
  }, [
    jackpotWin,
    showInhouseJackpot,
    customNotification,
    SET_IN_HOUSE_JACKPOT_WIN,
    winNotification?.isShowOnCasino
  ]);
};
