import { useState } from 'react';
import { slice } from 'ramda';
import { Box } from '@alf/uikit';
import EventCard from '../EventCard';
import Pagination from '../Pagination';
import styles from './index.module.css';

const EventsList = ({
  eventsIds = [],
  isShowShortName = false,
  isShowMore = true,
  pageSize = 4,
  type = 'horizontal',
  className = '',
  lineKey,
  isShowStatUrl,
  isLiveEvents
}) => {
  const [eventsToShow, setEventsToShow] = useState(pageSize);
  const onShowMore = () => setEventsToShow((prev) => prev + pageSize);
  const filteredEvents = isShowMore
    ? slice(0, eventsToShow, eventsIds)
    : eventsIds;

  return (
    <>
      <Box
        justify="between"
        align="center"
        className={`${styles[type]} ${className}`}
      >
        {filteredEvents.map((eventId) => (
          <EventCard
            key={`top-live-${eventId}`}
            eventId={eventId}
            lineKey={lineKey}
            className={styles.card}
            isShowShortName={isShowShortName}
            isShowFlag={true}
            isShowStatUrl={isShowStatUrl}
            isLiveEvents={isLiveEvents}
          />
        ))}
      </Box>
      <Pagination
        paginationType="showMore"
        onNextPage={onShowMore}
        isShow={isShowMore && eventsIds.length > eventsToShow}
        className={styles.pagination}
      />
    </>
  );
};

export default EventsList;
