import { useContext, useMemo } from 'react';
import { isEmpty } from 'lodash';
import {
  DOCUMENT_STATUSES,
  DOCUMENT_TYPES
} from '@namespace/account/src/constants';
import { UserContext } from '../../context';

const isIdCard = (i) => i.documentTypeId === DOCUMENT_TYPES.IDCARD;

export const useVerifyDocuments = () => {
  const [user] = useContext(UserContext);
  const {
    documents = [],
    accountStatuses = {},
    isDefaultRegistratedUser
  } = user;
  const { isDocumentsUpload } = accountStatuses;
  const [document = {}] = documents;
  const { verified = false, status = '' } = document;

  const isIdCardVerified = useMemo(
    () => documents.find(isIdCard)?.status === DOCUMENT_STATUSES.VERIFIED,
    [documents]
  );

  return {
    verified,
    status,
    isEmptyDocument: isEmpty(document),
    isIdCardVerified,
    isDocumentsUpload,
    document,
    isDefaultRegistratedUser
  };
};
