import { FILTER_ALL_VALUE } from '@namespace/common';

export const WITHDRAW_AMOUNT_FILTER = [
  {
    value: FILTER_ALL_VALUE,
    label: 'withdraw.all'
  },
  {
    value: '>=-10',
    label: '<10'
  },
  {
    value: '<=-10',
    label: '>10'
  },
  {
    value: '<=-50',
    label: '>50'
  },
  {
    value: '<=-100',
    label: '>100'
  },
  {
    value: '<=-1000',
    label: '>1000'
  },
  {
    value: '<=-10000',
    label: '>10000'
  }
];

export const DEPOSIT_AMOUNT_FILTER = [
  {
    value: FILTER_ALL_VALUE,
    label: 'deposit.all'
  },
  {
    value: '<=10',
    label: '<10'
  },
  {
    value: '>=10',
    label: '>10'
  },
  {
    value: '>=50',
    label: '>50'
  },
  {
    value: '>=100',
    label: '>100'
  },
  {
    value: '>=1000',
    label: '>1000'
  },
  {
    value: '>=10000',
    label: '>10000'
  }
];
