import { useComponentConfig, useGeneralRoutes } from '@namespace/cms';
import { AText, Box, Button, GridItem } from '@alf/uikit';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { getSportIcon, Icon } from '@namespace/icons2';
import { getPathToSports } from '@namespace/helpers';
import { usePopularSports } from '../../store';
import { useGetTileParams } from './hooks';
import styles from './index.module.css';

export const PopularSports = () => {
  const { t } = useI18n();
  const { viewAllUrl } = useComponentConfig();
  const popularSports = usePopularSports();
  const {
    preMatchSport: baseRoute,
    liveSportAll,
    cyberSport
  } = useGeneralRoutes();
  const { tilesNumber, tileSize } = useGetTileParams();

  if (popularSports.length === 0) {
    return null;
  }

  const actualSports = popularSports.slice(
    0,
    viewAllUrl ? tilesNumber - 1 : tilesNumber
  );

  return (
    <Box direction="column" padding="s" gap="s">
      <Box justify="between" align="center">
        <AText breed="BU" size="l" color="txt_color_1">
          {t('web.sportsbook.popularsports.title')}
        </AText>
        {viewAllUrl && (
          <LocalizedLink to={viewAllUrl}>
            <Button
              intent="quaternary"
              actionType="color2"
              size="m"
              iconRight={<Icon name="icons/general/nav/go-to" />}
            >
              {t('web.sportsbook.popularsports.viewall.link')}
            </Button>
          </LocalizedLink>
        )}
      </Box>
      <Box className={styles.popularSportsSlider} gap="s">
        <GridItem
          component={LocalizedLink}
          to={liveSportAll}
          iconName="icons/nav_menu/live"
          text={t('web.sportsbook.popularsports.liveitem')}
          size={tileSize}
          actionType="color3"
          isActive={true}
          className={styles.gridItem}
        />
        {actualSports.map(({ sportId, sportSlug, sportName }) => (
          <GridItem
            key={sportId}
            component={LocalizedLink}
            to={getPathToSports(
              `${baseRoute}/${sportSlug}`,
              cyberSport,
              sportId
            )}
            iconName={getSportIcon(sportId)}
            text={sportName}
            size={tileSize}
            className={styles.gridItem}
          />
        ))}
      </Box>
    </Box>
  );
};
