import { Image } from '@namespace/icons2';
import PropTypes from 'prop-types';
import AText from '../AText';
import Box from '../Box';
import styles from './index.module.css';

const EmptyState = ({
  dataRolePrefix,
  text,
  subText = '',
  linkPath,
  linkText = '',
  classNames: {
    containerClassName = '',
    textClassName = '',
    subTextClassName = ''
  } = {},
  image,
  Link
}) => {
  return (
    <Box
      data-role={
        dataRolePrefix
          ? `${dataRolePrefix}empty-state-wrapper`
          : 'empty-state-wrapper'
      }
      className={styles.wrapper}
    >
      <Box
        align="center"
        justify="center"
        direction="column"
        className={`${styles.empty} ${containerClassName}`}
      >
        {image || (
          <Image name="general/looking_for" width="64px" height="64px" alt="" />
        )}
        <AText
          breed="BU"
          size="m"
          color="line_txt_1"
          className={`${styles.text} ${textClassName}`}
        >
          {text}
        </AText>
        {subText && (
          <AText
            breed="R"
            size="m"
            color="line_txt_1"
            className={`${styles.subText} ${subTextClassName}`}
          >
            {subText}
          </AText>
        )}
        {linkText && (
          <Link
            to={linkPath}
            breed="RU"
            size="s"
            color="line_txt_1"
            className={`${styles.homeLink} ${styles.text} ${textClassName}`}
          >
            {linkText}
          </Link>
        )}
      </Box>
    </Box>
  );
};

EmptyState.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType,
    PropTypes.element
  ]).isRequired,
  subText: PropTypes.string,
  linkPath: PropTypes.string,
  linkText: PropTypes.string,
  classNames: PropTypes.shape({
    containerClassName: PropTypes.string,
    imgClassName: PropTypes.string,
    textClassName: PropTypes.string
  }),
  Link: PropTypes.elementType
};

export default EmptyState;
