import { useContext, useEffect, useMemo } from 'react';
import { DeviceContext } from '@namespace/device';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { formatAsUriParameters, window } from '@namespace/helpers';
import { SiteSettingsContext } from '@namespace/cms';
import { UserContext } from '@namespace/user/src/context';
import { useI18n } from '@namespace/i18n';
import loadSender from './utils/loadSender';
import useSenderAuthToken from './hooks/useSenderAuthToken';

const DEFAULT_SUPPORTED_LANGUAGES = [];

const useSender = () => {
  const [settings = {}] = useContext(SiteSettingsContext);
  const { supportSettings = {} } = settings || {};
  const { supportSender } = supportSettings;
  const auth = useSenderAuthToken();
  const [user] = useContext(UserContext);
  const { isVipUser = false } = user;

  const { isDesktopDevice } = useContext(DeviceContext);
  const { language } = useI18n();

  const {
    id,
    url,
    companyId,
    newWindowSrc,
    vipCompanyId,
    supportedLanguages = DEFAULT_SUPPORTED_LANGUAGES,
    defaultLanguage
  } = supportSender || {};

  const senderLanguage = useMemo(() => {
    if (supportedLanguages.includes(language)) return language;
    if (defaultLanguage) return defaultLanguage;

    return language;
  }, [language, supportedLanguages, defaultLanguage]);

  useEffect(() => {
    if (supportSender && senderLanguage) {
      window.ShowSupport = (info) => {
        if (isDesktopDevice) {
          if (window.SenderWidget?.isReady()) {
            window.SenderWidget.showWidget();
          } else {
            loadSender({
              src: url,
              id,
              companyId: vipCompanyId && isVipUser ? vipCompanyId : companyId,
              language: senderLanguage,
              auth
            })
              .then(() => {
                const intervalId = setInterval(() => {
                  if (window.SenderWidget?.isReady()) {
                    window.SenderWidget.showWidget();
                    clearInterval(intervalId);
                  }
                }, 200);
              })
              .catch(console.error);
          }
        }

        logAction(LOG_ACTIONS.SUPPORT, { info });

        if (!isDesktopDevice && newWindowSrc) {
          const params = formatAsUriParameters({
            auth,
            companyId: vipCompanyId && isVipUser ? vipCompanyId : companyId,
            lang: senderLanguage,
            location: global.location.href,
            showButton: true
          });

          global.open(`${newWindowSrc}?${params}`, '_blank');
        }

        return false;
      };
    }
  }, [
    auth,
    companyId,
    id,
    isDesktopDevice,
    isVipUser,
    newWindowSrc,
    senderLanguage,
    supportSender,
    url,
    vipCompanyId
  ]);
};

export default useSender;
