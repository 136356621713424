import { useEffect } from 'react';
import { useI18n } from '@namespace/i18n';
import { sportsbookActions } from '../../store';
import { MODE } from '../../constants';

const { FETCH_TOP_EVENT_PRESETS } = sportsbookActions;

const TopEvents = () => {
  const { f, language: lang } = useI18n();

  useEffect(() => {
    FETCH_TOP_EVENT_PRESETS({
      lang,
      tzDiff: f.getDateTime().offset * 60,
      serviceId: MODE.PRE_MATCH
    }).catch(console.error);
  }, [f, lang]);

  return null;
};

export default TopEvents;
