import { BrowserRouter } from 'react-router-dom';
import { SiteSettingsProvider } from '@namespace/layout';
import { DeviceProvider } from '@namespace/device';
import { I18nProvider } from '@namespace/i18n';
import { loadApp, window } from '@namespace/helpers';
import { OnboardingProvider } from '@namespace/onboarding';
import App from './components/App';
import DATE_FORMATS from './constants/dateFormats';
import CURRENCY_FORMATS from './constants/currencyFormats';

const AppWrapper = ({ initialPageConfig }) => (
  <BrowserRouter>
    <DeviceProvider initialDeviceOptions={window.initialDeviceOptions}>
      <I18nProvider
        DATE_FORMATS={DATE_FORMATS}
        CURRENCY_FORMATS={CURRENCY_FORMATS}
        translations={window.translations}
      >
        <OnboardingProvider>
          <SiteSettingsProvider SITE_SETTINGS={window.siteSettings}>
            <App
              colorScheme={window.colorScheme}
              snippets={window.snippets}
              initialPageConfig={initialPageConfig}
            />
          </SiteSettingsProvider>
        </OnboardingProvider>
      </I18nProvider>
    </DeviceProvider>
  </BrowserRouter>
);

loadApp(AppWrapper);
