import { SPORT_IDS } from '@namespace/sports';

export const providers = {
  betradar: '',
  vermantia: 'virtual-games',
  inspired: 'virtual'
};

export const BETRADAR_PROVIDERS = {
  VFL: 'vfl',
  NCUP: 'ncup',
  VTO: 'vto',
  VBL: 'vbl'
};

export const BETRADAR_PROVIDERS_LIST = [
  BETRADAR_PROVIDERS.VFL,
  BETRADAR_PROVIDERS.NCUP,
  BETRADAR_PROVIDERS.VTO,
  BETRADAR_PROVIDERS.VBL
];

export const PROVIDERS = {
  BETRADAR: 'vfl',
  VERMANTIA: 'vermantia',
  INSPIRED: 'inspired'
};

export const VIRTUAL_SPORT_IDS = [
  SPORT_IDS.VIRTUAL_SPORTS,
  SPORT_IDS.VERMANTIA,
  SPORT_IDS.INSPIRED
];

export const VIRTUAL_SPORTS_FEED_LOBBY_CATEGORY_ID = 'lobby';
