import {
  GTM_EVENT,
  LOG_ACTIONS,
  callGtmEvent,
  logAction,
  logPostConversionTracking
} from '@namespace/analytics';
import {
  RECAPTCHA_PLACE,
  REGISTER_COMPONENTS_TYPES,
  registerComponentsMap,
  useReCaptcha
} from '@namespace/auth';
import { useGeneralRoutes, useOtherSettings } from '@namespace/cms';
import { StepperContext } from '@namespace/common';
import {
  FORM_COMPONENTS_TYPES,
  FORM_VALIDATION_TYPES,
  FormBuilder
} from '@namespace/forms';
import { useI18n } from '@namespace/i18n';
import { useSearchParams } from '@namespace/router';
import { useLogin, useSaveUser } from '@namespace/user';
import { useCallback, useContext } from 'react';
import { COMPONENTS_TYPES, componentsMap } from './constants';

const SecretQuestions = ({
  onStepSuccess,
  onGoBack,
  classNames = {},
  ...props
}) => {
  const { formClassName = '', inputWithoutLabel = '' } = classNames;
  const { language } = useI18n();
  const { stepper } = useContext(StepperContext);
  const { formData } = stepper;

  const {
    secretQuestionAnswer = '',
    secretQuestion,
    confirmAge = true,
    promo = ''
  } = formData;
  const { registerSuccess } = useGeneralRoutes();
  const { login } = useLogin();
  const { postConversionType } = useOtherSettings();
  const saveUser = useSaveUser();

  const loadCaptchaToken = useReCaptcha(RECAPTCHA_PLACE.REGISTER);

  const defaultValues = {
    secretQuestionAnswer,
    secretQuestion,
    confirmAge,
    promo
  };

  const componentsList = [
    {
      type: FORM_COMPONENTS_TYPES.REG_SECRET_QUESTION_SELECT,
      name: 'secretQuestion',
      label: 'register.steps.secretQuestion.selectQuestion',
      placeholder: 'register.steps.secretQuestion.selectQuestionPlaceholder',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
      size: 'm'
    },
    {
      type: FORM_COMPONENTS_TYPES.REG_SECRET_ANSWER_INPUT,
      name: 'secretQuestionAnswer',
      placeholder: 'register.steps.secretQuestion.answer',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
      classNames: {
        wrapperClassName: inputWithoutLabel
      },
      size: 'm'
    },
    {
      type: FORM_COMPONENTS_TYPES.INPUT_FIELD,
      name: 'promo',
      label: 'register.steps.secretQuestion.promo',
      placeholder: 'register.steps.secretQuestion.promo',
      size: 'm'
    },
    {
      type: FORM_COMPONENTS_TYPES.SUBMIT_BUTTON,
      value: 'register.steps.secretQuestion.register',
      size: 'xl'
    },
    {
      type: COMPONENTS_TYPES.CONFIRM_AGE,
      name: 'confirmAge',
      validationTypes: [
        {
          type: FORM_VALIDATION_TYPES.REQUIRED,
          parameters: ['validators.requiredValidator.confirmAge']
        }
      ],
      size: 'm'
    },
    {
      type: REGISTER_COMPONENTS_TYPES.BACK_BUTTON,
      onGoBack
    }
  ];
  const { from: locationFrom } = useSearchParams();

  const submit = useCallback(
    async (fields) => {
      const reCaptchaData = await loadCaptchaToken();
      await saveUser({
        ...formData,
        ...fields,
        currency: 'TMT',
        reCaptchaData,
        language
      })
        .then(({ response }) => {
          login({
            username: formData.email,
            password: formData.password,
            nextPage: registerSuccess,
            withAutoLogin: false
          });
          callGtmEvent(GTM_EVENT.REGISTER, {
            event_category: 'Register',
            event_label: response.id
          });
          logAction(LOG_ACTIONS.REGISTRATION_SUCCESS, {
            country: fields.country,
            promoCode: fields.promo || 'no code',
            location: locationFrom || '',
            userId: response.id,
            language
          });
          logPostConversionTracking(
            LOG_ACTIONS.POST_REGISTRATION,
            postConversionType
          );
        })
        .catch((error) => {
          logAction(LOG_ACTIONS.REGISTRATION_FAILED, { error });
        });
    },
    [saveUser, formData, language, onStepSuccess, loadCaptchaToken]
  );

  return (
    <FormBuilder
      wrapperClassName={formClassName}
      componentsMap={{ ...registerComponentsMap, ...componentsMap }}
      components={componentsList}
      formConfig={{ defaultValues }}
      onSubmit={submit}
      formProps={{
        ...props
      }}
    />
  );
};

export default SecretQuestions;
