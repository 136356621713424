import { useContext, useCallback } from 'react';
import { AText, Box } from '@alf/uikit';
import { USER_ROLES, UserContext } from '@namespace/user';
import { useI18n, useLocation } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { Icon } from '@namespace/icons2';
import {
  useGeneralRoutes,
  PageComponentsContext,
  useMobileNavMenuSettings,
  COMPONENTS_TYPES
} from '@namespace/cms';
import {
  SESSION_STORAGE_KEYS,
  setSessionStorageValue
} from '@namespace/helpers';
import { TextCopy } from '@namespace/common';
import clsx from 'clsx';
// import Logo from '../../../common/Logo';
import styles from './index.module.css';

const ProfileMenuHeader = ({ classNames = {}, headerConfig = {} }) => {
  const { t } = useI18n();
  const { [COMPONENTS_TYPES.LOGO]: Logo } = useContext(PageComponentsContext);
  const [{ role, id, email, isVipUser }] = useContext(UserContext);
  const { isUserSiteLogo, textCopyColor, textCopyBreed } = headerConfig;
  const mobileNavigation = useMobileNavMenuSettings();
  const { showEmail } = mobileNavigation;
  const { profilePage } = useGeneralRoutes();
  const { push } = useHistory();
  const { pathname, search } = useLocation();

  const handleClick = useCallback(() => {
    setSessionStorageValue(
      SESSION_STORAGE_KEYS.PROFILE_MENU_PREV_PATH,
      pathname + search
    );
    return push(profilePage);
  }, [pathname, search, push, profilePage]);

  return (
    <Box
      className={clsx(styles.profileMenuHeader, classNames?.general)} // HERE PROPS
      direction="column"
      padding="s"
      justify="center"
    >
      {role === USER_ROLES.USER ? (
        <Box justify="between">
          {isUserSiteLogo && <Logo isProfileMenu={true} />}
          <Box align="center" flexShrink={0}>
            {isVipUser && (
              <Icon
                className={styles.icon}
                size="xl"
                name="icons/user_menu/profile_VIP_desktop"
                color="accent_color_3"
              />
            )}
            <Box direction="column">
              {showEmail && (
                <AText breed="B" size="m" color="header_txt_2">
                  {email}
                </AText>
              )}
              <Box
                direction="column"
                className={clsx(showEmail && styles.id)}
                margin="xs 0 0 0"
              >
                <TextCopy
                  breed={textCopyBreed || (showEmail ? 'R' : 'B')}
                  size="l"
                  color={textCopyColor || 'header_txt_2'}
                  textDisplay={`${t('web.personalaccountmenu.userid')} ${id}`}
                  textCopy={id}
                  textNotify="ID"
                />
              </Box>
            </Box>
          </Box>
          {!isUserSiteLogo && (
            <Box className={styles.profileItem} onClick={handleClick}>
              <Icon
                className={styles.icon}
                name="icons/user_menu/profile_desktop"
                color="btn_quaternary_color_4"
                size="m"
              />
              <AText color="btn_quaternary_color_4" breed="BU" size="m">
                {`${t('web.personalaccountmenu.myprofile')}`}
              </AText>
            </Box>
          )}
        </Box>
      ) : (
        <Logo isProfileMenu={true} />
      )}
    </Box>
  );
};

export default ProfileMenuHeader;
