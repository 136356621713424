import { useCallback, useContext, useState } from 'react';
import { UserContext } from '@namespace/user';
import { SiteSettingsContext } from '@namespace/cms';
import {
  checkHasActivePrewager,
  cancelPrewagerBonus
} from '../../api/withdraw';

export const usePrewagerBonusWalletWithdraw = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [isCancelFetching, setIsCancelFetching] = useState(false);
  const [settings] = useContext(SiteSettingsContext);
  const { bonuses = {} } = settings || {};
  const { isPrewagerEnabled = false } = bonuses;
  const [user] = useContext(UserContext);
  const { activeWallet } = user;
  const { walletHash } = activeWallet;
  const cancelPrewager = useCallback(async () => {
    try {
      setIsCancelFetching(true);
      await cancelPrewagerBonus(walletHash);
    } finally {
      setIsCancelFetching(false);
    }
  }, [walletHash]);
  const checkIsPrewagerActive = useCallback(async () => {
    if (!isPrewagerEnabled) {
      return { hasActivePreWager: false };
    }
    try {
      setIsFetching(true);
      return checkHasActivePrewager(walletHash);
    } catch {
      return { hasActivePreWager: false };
    } finally {
      setIsFetching(false);
    }
  }, [walletHash]);

  return {
    isFetching,
    isCancelFetching,
    cancelPrewager,
    checkIsPrewagerActive
  };
};
