import { useContext, useEffect } from 'react';

import { useOtherSettings } from '@namespace/cms';
import { USER_ROLES, UserContext } from '@namespace/user';
import { GamesContext } from '../../context';

export const useGetExcludedWagerGamesIdList = () => {
  const [
    { excludedActiveBonusGamesList },
    { FETCH_WAGER_EXCLUDED_GAME_LIST }
  ] = useContext(GamesContext);
  const { isGamesWagerInfoEnabled } = useOtherSettings();
  const [user] = useContext(UserContext);
  const { role } = user;
  const { isAlreadyBeenRequested } = excludedActiveBonusGamesList;

  const isLoggedInUser = role === USER_ROLES.USER;

  useEffect(() => {
    if (isLoggedInUser && !isAlreadyBeenRequested && isGamesWagerInfoEnabled) {
      FETCH_WAGER_EXCLUDED_GAME_LIST();
    }
  }, [isLoggedInUser, isGamesWagerInfoEnabled]);
};
