export const PERIODS = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  QUARTER: '3month',
  HALFYEAR: '6month',
  YEAR: 'year',
  INDEFINITE: 'indefinite'
};

export const periodsMap = {
  [PERIODS.DAY]: {
    label: 'day',
    delta: 86400
  },
  [PERIODS.WEEK]: {
    label: 'week',
    delta: 604800
  },
  [PERIODS.MONTH]: {
    label: 'month',
    delta: 2592000
  },
  [PERIODS.QUARTER]: {
    label: '3month',
    delta: 7776000
  },
  [PERIODS.HALFYEAR]: {
    label: '6month',
    delta: 15552000
  },
  [PERIODS.YEAR]: {
    label: 'year',
    delta: 31536000
  },
  [PERIODS.INDEFINITE]: {
    label: 'indefinite',
    delta: null
  }
};

export const LIMITS_PERIODS = [PERIODS.DAY, PERIODS.WEEK, PERIODS.MONTH];
export const ALL_EXCLUSION_PERIODS = [
  PERIODS.DAY,
  PERIODS.WEEK,
  PERIODS.MONTH,
  PERIODS.QUARTER,
  PERIODS.HALFYEAR,
  PERIODS.YEAR,
  PERIODS.INDEFINITE
];

export const DEFAULT_EXCLUSION_PERIODS = [
  PERIODS.DAY,
  PERIODS.WEEK,
  PERIODS.MONTH,
  PERIODS.HALFYEAR,
  PERIODS.INDEFINITE
];

export const LIMIT_TYPES = {
  DEPOSIT_LIMIT: 'depositLimit',
  BET_LIMIT: 'betLimit',
  LOSS_LIMIT: 'lossLimit',
  REALITY_CHECK: 'timeLimit',
  SELF_EXCLUSION: 'exclusionLimit'
};

export const commandsMap = {
  [LIMIT_TYPES.DEPOSIT_LIMIT]: 'deposit_limit',
  [LIMIT_TYPES.BET_LIMIT]: 'bet_limit',
  [LIMIT_TYPES.LOSS_LIMIT]: 'loss_limit',
  [LIMIT_TYPES.SELF_EXCLUSION]: 'exclusion_limit',
  [LIMIT_TYPES.REALITY_CHECK]: 'time_limit'
};

export const GAMBLING_BLOCKS = {
  limits: {
    title: 'responsibleGambling.limits.title',
    description: ['responsibleGambling.limits.infoMessage'],
    link: '/personal-office/responsible-gambling/limits/loss'
  },
  realityCheck: {
    title: 'responsibleGambling.realityCheck.title',
    description: [
      'responsibleGambling.realityCheck.infoMessage',
      'responsibleGambling.realityCheck.infoMessage2'
    ],
    link: '/personal-office/responsible-gambling/reality-check'
  },
  exclusionLimit: {
    title: 'responsibleGambling.exclusionLimit.title',
    description: [
      'responsibleGambling.exclusionLimit.infoMessage',
      'responsibleGambling.exclusionLimit.infoMessage2'
    ],
    link: '/personal-office/responsible-gambling/self-exclusion'
  }
};

export const API_SOCKET_ENDPOINT = 'do_proto_v1';
export const API_SET_PERIOD_TYPE = 'install_account_tracking';
export const API_SET_MAX_LOSS = 'install_max_loss_limit';
export const API_RESET_MAX_LOSS = 'cancel_max_loss_limit';
export const API_SET_MAX_DEPOSIT = 'install_max_deposit_limit';
export const API_RESET_MAX_DEPOSIT = 'cancel_max_deposit_limit';
export const API_SET_MAX_BET = 'install_max_bet_limit';
export const API_RESET_MAX_BET = 'cancel_max_bet_limit';

export const commandsActionsMap = {
  [LIMIT_TYPES.DEPOSIT_LIMIT]: {
    set: API_SET_MAX_DEPOSIT,
    reset: API_RESET_MAX_DEPOSIT
  },
  [LIMIT_TYPES.LOSS_LIMIT]: {
    set: API_SET_MAX_LOSS,
    reset: API_RESET_MAX_LOSS
  },
  [LIMIT_TYPES.BET_LIMIT]: {
    set: API_SET_MAX_BET,
    reset: API_RESET_MAX_BET
  }
};
