import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { DeviceContext } from '@namespace/device';
import {
  PageContext,
  SiteSettingsContext,
  useGeneralRoutes,
  useOtherSettings
} from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { AText, Box } from '@alf/uikit';
import { UserContext, useShowQuickDeposit } from '@namespace/user';
import { Icon as Icon2 } from '@namespace/icons2';
import {
  Amount,
  useUserSettings,
  useIsNavMenuVisible
} from '@namespace/common';
import { LOG_ACTIONS, logAction } from '@namespace/analytics';
import { USER_BLOCK_NAME_TYPES, HEADER_COLOR_SCHEME } from '../../constants';
import UserMenu from './components/UserMenu';
import styles from './index.module.css';

const AmountComponent = ({
  amount,
  className,
  convertedAmountColor,
  noFormattedAmount
}) => (
  <Amount
    amount={amount}
    noFormattedAmount={noFormattedAmount}
    currentAmountColor="header_txt_2"
    currentAmountBreed="B"
    currentCurrencyBreed="B"
    convertedAmountBreed="B"
    convertedCurrencyBreed="B"
    convertedAmountColor={convertedAmountColor}
    className={className}
    disabledSeparator={true}
    dataRole="user-balance-header"
    additionalFormatting={true}
  />
);

const User = ({ id, classNames = {} }) => {
  const {
    userWrapper = '',
    data = '',
    userInfoWrapper = '',
    loginStyle = '',
    depositStyle = '',
    userMenuStyles = '',
    arrowButtonStyles = ''
  } = classNames;
  const { c } = useI18n();
  const [settings] = useContext(SiteSettingsContext);
  const { components = {} } = settings;
  const { isShowFullNameInUserMenu = true } = useUserSettings();
  const { showLogOnTimer = false } = useOtherSettings();
  const navMenuVisible = useIsNavMenuVisible();
  const { config = {} } = components[id] || {};
  const {
    nameType = USER_BLOCK_NAME_TYPES.FULL_NAME,
    showBalance = false,
    showDepositButton = false,
    customDepositPath = '',
    showQuickDepositButton = false,
    showSupportLink = false,
    showUsername = false,
    showIcons = true,
    snippetIdPromotionLink = ''
  } = config;
  const [user] = useContext(UserContext);
  const {
    activeWallet = {},
    firstName = '',
    lastName = '',
    login = '',
    isVipUser = false
  } = user;
  const { deposit = 0, currency = null } = activeWallet;
  const [isOpen, setIsOpen] = useState(false);
  const userButtonRef = useRef(null);
  const { isDesktop } = useContext(DeviceContext);
  const isShowBurgerMenu = !navMenuVisible;
  const { page } = useContext(PageContext);
  const { pageConfig = {} } = page;
  const { headerColorScheme } = pageConfig;
  const { push } = useHistory();
  const { wallets } = useGeneralRoutes();
  const { isShowQuickDeposit, toggleQuickDeposit } = useShowQuickDeposit();
  const isShowVipIcon = isVipUser && isShowBurgerMenu;

  const onToggle = useCallback(() => {
    logAction(LOG_ACTIONS.MY_ACCOUNT_HEADER_CLICK);

    if (isShowBurgerMenu) {
      return setIsOpen(!isOpen);
    }

    if (isShowQuickDeposit) {
      return toggleQuickDeposit();
    }

    return push(wallets);
  }, [
    isOpen,
    isShowBurgerMenu,
    isShowQuickDeposit,
    push,
    toggleQuickDeposit,
    wallets
  ]);

  const name = useMemo(() => {
    switch (nameType) {
      case USER_BLOCK_NAME_TYPES.LOGIN:
        return login;
      case USER_BLOCK_NAME_TYPES.FULL_NAME:
        return `${firstName} ${lastName}`;
      default:
        return '';
    }
  }, [nameType, firstName, lastName, login]);

  const iconName = useMemo(() => {
    if (isShowVipIcon) {
      return 'icons/user_menu/profile_VIP_desktop';
    }

    return isDesktop
      ? 'icons/user_menu/profile_desktop'
      : 'icons/user_menu/profile_mobile';
  }, [isShowVipIcon, isDesktop]);

  const iconColor = useMemo(() => {
    if (isShowVipIcon) {
      return `header_accent_color_${headerColorScheme ? '2' : '1'}`;
    }

    return 'header_txt_2';
  }, [isShowVipIcon, headerColorScheme]);

  return (
    <Box
      data-role="c-user"
      align="center"
      left="s"
      className={`${styles.userWrapper} ${userWrapper}`}
      onClick={onToggle}
      ref={userButtonRef}
    >
      <Box align="center" className={`${styles.data} ${data}`}>
        <Box
          align="center"
          justify="start"
          className={`${styles.userInfoWrapper} ${userInfoWrapper}`}
        >
          <AText
            breed="BU"
            size="s"
            color="color_4"
            overflow="ellipsis"
            align="left"
          >
            {name && (
              <span className={`${styles.login} ${loginStyle}`}>{name}</span>
            )}
            <AmountComponent
              amount={`${c.formatCurrency(deposit)} ${currency || ''}`}
              noFormattedAmount={deposit}
              convertedAmountColor="btn_close_dark_color"
              className={`${styles.wrapperClassName} ${depositStyle} ${
                styles[HEADER_COLOR_SCHEME.PRIMARY]
              }`}
            />
            <AmountComponent
              amount={`${c.formatCurrency(deposit)} ${currency || ''}`}
              noFormattedAmount={deposit}
              convertedAmountColor="header_txt_2"
              className={`${styles.wrapperClassName} ${depositStyle} ${
                styles[HEADER_COLOR_SCHEME.SECONDARY]
              }`}
            />
          </AText>
        </Box>
        <Box left="s">
          <Icon2
            size="m"
            color={iconColor}
            className={isShowVipIcon ? '' : styles.userIcon}
            data-role={`${isShowVipIcon ? 'vip' : 'user'}-logo-header`}
            name={iconName}
          />
        </Box>
      </Box>
      {isShowBurgerMenu && (
        <UserMenu
          snippetIdPromotionLink={snippetIdPromotionLink}
          isOpen={isOpen}
          showDepositButton={showDepositButton}
          customDepositPath={customDepositPath}
          showQuickDepositButton={showQuickDepositButton}
          showIcons={showIcons}
          showUsername={showUsername}
          isShowFullNameInUserMenu={isShowFullNameInUserMenu}
          showSupportLink={showSupportLink}
          showLogOnTimer={showLogOnTimer}
          classNames={{
            popoverClassName: `${styles.popoverWrapper} ${userMenuStyles}`,
            arrowButtonClassName: arrowButtonStyles
          }}
          showBalance={showBalance}
        />
      )}
    </Box>
  );
};

export default User;
