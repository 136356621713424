export const EGT_DIGITAL_COMMANDS = {
  EXIT: 'exit'
};

export const egtDigitalLauncherControl = (event, callbacks = {}) => {
  const { onExitHandler = () => {} } = callbacks;
  if (event.data && event.data.command === EGT_DIGITAL_COMMANDS.EXIT) {
    onExitHandler();
  }
};
