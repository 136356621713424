import { useReducer, createContext, useMemo } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import { useGlobalMockedStore } from '@namespace/onboarding';
import actionCreators from '../actions';
import { reducers, initialState } from '../reducers';
import { types } from '../types';

export const BetsContext = createContext();

export const BetsProvider = ({ customReducers, children }) => {
  const memoReducers = useMemo(
    () => combineReducers(reducers, customReducers),
    [customReducers]
  );
  const [state, dispatch] = useReducer(memoReducers, initialState);

  const actions = useActions(types, dispatch, actionCreators);

  return (
    <BetsContext.Provider value={useGlobalMockedStore('bets', state, actions)}>
      {children}
    </BetsContext.Provider>
  );
};
