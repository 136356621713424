import { cloneElement, useCallback, useRef, useState } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import styles from './index.module.css';

const Popover = ({
  Trigger,
  trigger,
  Content,
  content,
  triggerButtonRef,
  setIsOpenExternal,
  isOpenExternal,
  isExpandable = false,
  classNames = {},
  haveToCloseOutsideClick = true,
  toggleCallback = noop,
  disabledToggleInWrapper = false,
  dataRole
}) => {
  const triggerWrapperRef = useRef(null);
  const contentWrapperRef = useRef(null);
  const [isOpenLocally, setIsOpenLocally] = useState(false);
  const {
    wrapperClass = '',
    contentClass = '',
    triggerClass = '',
    automationTestsClassName = ''
  } = classNames;

  const isOpen = isOpenExternal ?? isOpenLocally;
  const setIsOpen = setIsOpenExternal ?? setIsOpenLocally;

  const handleToggle = useCallback(() => {
    setIsOpen(setIsOpenExternal ? !isOpenExternal : !isOpenLocally);
  }, [isOpenExternal, isOpenLocally, setIsOpenExternal, setIsOpen]);

  const handleClose = useCallback(() => {
    toggleCallback(false);
    setIsOpen(false);
  }, [setIsOpen, toggleCallback]);

  const triggerForOutsideRef = triggerButtonRef || triggerWrapperRef;

  useOnClickOutside(
    () => {
      if (haveToCloseOutsideClick) {
        setIsOpen(false);
      }
    },
    contentWrapperRef,
    triggerForOutsideRef
  );

  return (
    <div className={clsx([styles.container, wrapperClass])}>
      <div
        className={triggerClass}
        onClick={disabledToggleInWrapper ? () => {} : handleToggle}
        ref={triggerWrapperRef}
      >
        {Trigger && (
          <Trigger isOpen={isOpen} classNames={{ automationTestsClassName }} />
        )}
        {trigger}
      </div>
      {isOpen && (
        <div
          data-role={dataRole && `popover-content-open-${dataRole}`}
          className={clsx([!isExpandable && styles.content, contentClass])}
          ref={contentWrapperRef}
        >
          {Content && <Content onClose={handleClose} />}
          {content && cloneElement(content, { onClose: handleClose })}
        </div>
      )}
    </div>
  );
};

Popover.propTypes = {
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  trigger: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  triggerButtonRef: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  setIsOpenExternal: PropTypes.func,
  isOpenExternal: PropTypes.bool,
  haveToCloseOutsideClick: PropTypes.bool,
  classNames: PropTypes.shape({
    wrapperClass: PropTypes.string,
    contentClass: PropTypes.string,
    triggerClass: PropTypes.string
  }),
  toggleCallback: PropTypes.func
};

export default Popover;
