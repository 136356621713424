import { useContext, useEffect } from 'react';
import { Loader } from '@namespace/common';
import { useRouteParams, useHistory } from '@namespace/router';
import { useGeneralRoutes } from '@namespace/cms';
import { Overlay } from '@alf/uikit';
import { BetSlipContext } from '@namespace/betslip';
import { SERVICE_IDS, SERVICE_IDS_TYPES_MAP } from '../../constants';
import styles from './index.module.css';

// todo: only for pre match for now
const AddBetsFromExternal = () => {
  const [{ isLoaded }, betSlipActions] = useContext(BetSlipContext);
  const { ADD_TO_STORE } = betSlipActions;
  const { replace } = useHistory();
  const {
    preMatchEvent,
    preMatchSport,
    preMatchRedirectBet
  } = useGeneralRoutes();

  const { eventId, marketId, outcomeId } = useRouteParams(
    `${preMatchRedirectBet}/:eventId/:marketId/:outcomeId`
  );

  useEffect(() => {
    if (eventId) {
      if (isLoaded) {
        if (marketId && outcomeId) {
          ADD_TO_STORE({
            bets: [
              {
                serviceId: SERVICE_IDS[SERVICE_IDS_TYPES_MAP.PRE_MATCH],
                eventId: Number(eventId),
                marketId: Number(marketId),
                outcomeId: Number(outcomeId)
              }
            ]
          });
        }
        replace(`${preMatchEvent}/none/${eventId}`);
      }
    } else {
      replace(preMatchSport);
    }
  }, [isLoaded]); // can't add eventId to dependency list because we got replace from else

  return (
    <div className={styles.wrapper}>
      <Overlay classNames={{ wrapperClass: styles.overlay }}>
        <Loader />
      </Overlay>
    </div>
  );
};

export default AddBetsFromExternal;
