import { useMatch } from 'react-router-dom';
import useHiddenLanguageInUrl from '@namespace/i18n/src/hooks/useHiddenLanguageInUrl';

const useLangMatch = ({ path = '/' }) => {
  const isHiddenLanguageInUrl = useHiddenLanguageInUrl();

  const matchData = useMatch({
    path: isHiddenLanguageInUrl ? path : `/:lang${path}`
  });

  return matchData || {};
};

export default useLangMatch;
