import clsx from 'clsx';
import { Box, AText } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';

import styles from './index.module.css';

export const LeaderboardGridRow = ({
  place,
  points,
  userId,
  prize,
  isPotentialPoints,
  isUserPlace
}) => {
  const { t } = useI18n();

  return (
    <>
      <Box
        className={clsx([
          styles.leaderboardItem,
          isUserPlace && styles.leaderboardItemYou
        ])}
      >
        <AText
          breed={isUserPlace ? 'B' : 'R'}
          className={styles.leaderboardItemText}
        >
          {place}
        </AText>
      </Box>
      <Box
        className={clsx([
          styles.leaderboardItem,
          isUserPlace && styles.leaderboardItemYou
        ])}
      >
        <AText className={styles.leaderboardItemText}>
          {isUserPlace ? t('web.tournaments.leaderboard.you') : userId}
        </AText>
      </Box>
      <Box
        className={clsx([
          styles.leaderboardItem,
          isUserPlace && styles.leaderboardItemYou
        ])}
      >
        <AText className={styles.leaderboardItemText}>
          <>
            {points}
            {isPotentialPoints && '*'}
          </>
        </AText>
      </Box>
      <Box
        className={clsx([
          styles.leaderboardItem,
          isUserPlace && styles.leaderboardItemYou,
          styles.leaderboardItemPrize
        ])}
      >
        <AText
          className={clsx([
            styles.leaderboardItemText,
            styles.leaderboardItemTextPrize
          ])}
        >
          {prize ? t(prize) : t('web.tournaments.leaderboard.noprize')}
        </AText>
      </Box>
    </>
  );
};
