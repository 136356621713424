const propsByBreed = {
  R: {
    '4xl': {
      size: '4xl',
      lsize: 'normal'
    },
    '3xl': {
      size: '3xl',
      lsize: 'normal'
    },
    '2xl': {
      size: '2xl',
      lsize: 'normal'
    },
    'xl': {
      size: 'xl',
      lsize: 'normal'
    },
    'l': {
      size: 'l',
      lsize: 'normal'
    },
    'm': {
      size: 'm',
      lsize: 'normal'
    },
    's': {
      size: 's',
      lsize: 'normal'
    },
    'xs': {
      size: 'xs',
      lsize: 'normal'
    },
    '2xs': {
      size: '2xs',
      lsize: 'normal'
    }
  },
  RU: {
    '4xl': {
      size: '4xl',
      lsize: 'normal',
      transform: 'uppercase'
    },
    '3xl': {
      size: '3xl',
      lsize: 'normal',
      transform: 'uppercase'
    },
    '2xl': {
      size: '2xl',
      lsize: 'normal',
      transform: 'uppercase'
    },
    'xl': {
      size: 'xl',
      lsize: 'normal',
      transform: 'uppercase'
    },
    'l': {
      size: 'l',
      lsize: 'normal',
      transform: 'uppercase'
    },
    'm': {
      size: 'm',
      lsize: 'normal',
      transform: 'uppercase'
    },
    's': {
      size: 's',
      lsize: 'normal',
      transform: 'uppercase'
    },
    'xs': {
      size: 'xs',
      lsize: 'normal',
      transform: 'uppercase'
    },
    '2xs': {
      size: '2xs',
      lsize: 'normal',
      transform: 'uppercase'
    }
  },
  BU: {
    '4xl': {
      size: '4xl',
      lsize: 'normal',
      bold: true,
      transform: 'uppercase'
    },
    '3xl': {
      size: '3xl',
      lsize: 'normal',
      bold: true,
      transform: 'uppercase'
    },
    '2xl': {
      size: '2xl',
      lsize: 'normal',
      bold: true,
      transform: 'uppercase'
    },
    'xl': {
      size: 'xl',
      lsize: 'normal',
      bold: true,
      transform: 'uppercase'
    },
    'l': {
      size: 'l',
      lsize: 'normal',
      bold: true,
      transform: 'uppercase'
    },
    'm': {
      size: 'm',
      lsize: 'normal',
      bold: true,
      transform: 'uppercase'
    },
    's': {
      size: 's',
      lsize: 'normal',
      bold: true,
      transform: 'uppercase'
    },
    'xs': {
      size: 'xs',
      lsize: 'normal',
      bold: true,
      transform: 'uppercase'
    },
    '2xs': {
      size: '2xs',
      lsize: 'normal',
      bold: true,
      transform: 'uppercase'
    }
  },
  B: {
    '4xl': {
      size: '4xl',
      lsize: 'normal',
      bold: true
    },
    '3xl': {
      size: '3xl',
      lsize: 'normal',
      bold: true
    },
    '2xl': {
      size: '2xl',
      lsize: 'normal',
      bold: true
    },
    'xl': {
      size: 'xl',
      lsize: 'normal',
      bold: true
    },
    'l': {
      size: 'l',
      lsize: 'normal',
      bold: true
    },
    'm': {
      size: 'm',
      lsize: 'normal',
      bold: true
    },
    's': {
      size: 's',
      lsize: 'normal',
      bold: true
    },
    'xs': {
      size: 'xs',
      lsize: 'normal',
      bold: true
    },
    '2xs': {
      size: '2xs',
      lsize: 'normal',
      bold: true
    }
  }
};

export const getPropsByBreed = (breed, size) => {
  try {
    const props = propsByBreed[breed][size];
    if (!props && process.env.NODE_ENV === 'development')
      throw new Error('No such combination');
    return props;
  } catch (e) {
    console.error(`wrong combination of breed: ${breed} and size: ${size}`, e);
  }
  return { size: 'm', lsize: 'm' };
};
