import { useRef } from 'react';
import { AText, Box, Button, Overlay } from '@alf/uikit';
import { Image } from '@namespace/icons2';
import { useI18n } from '@namespace/i18n';
import { useFixScroll } from '../../hooks/useFixScroll';
import styles from './index.module.css';

export const LeaveModal = ({ onConfirm, onClose }) => {
  const { t } = useI18n();
  const formPopup = useRef(null);

  useFixScroll('confirm-modal', formPopup, true);

  return (
    <Overlay
      classNames={{ wrapperClass: styles.overlay }}
      onClick={(e) => e.stopPropagation()}
    >
      <Box
        className={styles.formPopup}
        ref={formPopup}
        direction="column"
        align="center"
      >
        <Box
          className={styles.generalBlock}
          padding="ms ms l"
          direction="column"
          gap="ms"
        >
          <Image height="33px" width="auto" name="general/logo" />
          <AText breed="B" size="l" align="center" color="txt_color_1">
            {t('web.registration.close.title')}
          </AText>
          <Box direction="column" bottom="m">
            <AText breed="R" size="l" color="txt_color_1" align="center">
              {t('web.registration.close.description')}
            </AText>
            <AText breed="R" size="l" color="txt_color_1" align="center">
              {t('web.registration.close.bonusdescription')}
            </AText>
          </Box>
          <Box justify="between" align="center" gap="s">
            <Button
              fullWidth={true}
              onClick={onClose}
              intent="primary"
              actionType="color2"
              size="l"
              className={styles.closeBtn}
            >
              {t('web.registration.close.button.close')}
            </Button>
            <Button
              fullWidth={true}
              onClick={onConfirm}
              intent="primary"
              actionType="color1"
              size="l"
            >
              {t('web.registration.close.button.back')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Overlay>
  );
};
