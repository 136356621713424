import { memo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Icon } from '@namespace/icons2';
import Box from '../Box';
import AText from '../AText';
import styles from './index.module.css';

const OutcomeButton = ({
  isSelected,
  outcomeChange,
  isShowOutcome,
  name,
  onClick,
  coefficient,
  isDisabled,
  lineView,
  className,
  isDesktopDevice,
  outcomeTypeId = '',
  eventId = ''
}) => (
  <Box
    data-role={`outcome-btn-${lineView}${
      isSelected ? '-active' : ''
    }${eventId && `-${eventId}`}${outcomeTypeId && `-${outcomeTypeId}`}`}
    align="center"
    justify={name ? 'between' : 'center'}
    padding={name ? '0 s' : '0'}
    className={clsx([
      styles.outcome,
      styles[lineView],
      isSelected && styles.active,
      outcomeChange && isShowOutcome && styles[outcomeChange],
      !isShowOutcome && styles.locked,
      isDesktopDevice && styles.desktopStyles,
      className
    ])}
    onClick={onClick}
  >
    {name && (
      <Box right="xs">
        <AText breed="R" size="xs" className={styles.outcomeName}>
          {name}
        </AText>
      </Box>
    )}
    {/* eslint-disable-next-line no-nested-ternary */}
    {isShowOutcome ? (
      <Box flex="0 0 25px">
        <AText align="right" breed="B" size="m" className={styles.coef}>
          {coefficient}
        </AText>
      </Box>
    ) : isDisabled ? (
      <Box justify="center" align="center" className={styles.lineLogo}>
        <img
          src={`/images/sports/lineLogo/lineLogo${lineView}.svg`}
          alt="outcome icon"
          className={`${styles.itemLogo} ${styles[lineView]}`}
        />
      </Box>
    ) : (
      <Icon
        name="icons/odd/lock"
        size="m"
        className={`${styles.lockedIcon} ${styles[lineView]}`}
      />
    )}
  </Box>
);

OutcomeButton.propTypes = {
  isSelected: PropTypes.bool,
  outcomeChange: PropTypes.string,
  isShowOutcome: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  coefficient: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDisabled: PropTypes.bool,
  lineView: PropTypes.string,
  className: PropTypes.string
};

OutcomeButton.defaultProps = {
  isDisabled: false,
  lineView: 'button',
  className: ''
};

export default memo(OutcomeButton);
