import { useCallback } from 'react';
import { useNavigate as useReactRouterNavigate } from 'react-router-dom';
import { useI18n, useGetActualPrefixForPath } from '@namespace/i18n';
import { removeLangFromPathname } from '@namespace/helpers';

import { isString } from 'lodash';

const useNavigate = () => {
  const navigate = useReactRouterNavigate();
  const { language } = useI18n();
  const getActualPrefixForPath = useGetActualPrefixForPath();
  const actualPrefixForPath = getActualPrefixForPath(`/${language}`);
  return useCallback(
    (path, ...rest) =>
      navigate(
        isString(path)
          ? `${actualPrefixForPath}${removeLangFromPathname(path)}`
          : path,
        ...rest
      ),
    [navigate, actualPrefixForPath]
  );
};

export default useNavigate;
