import { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { Box, AText } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { useGetPaymentService } from '../../../store/selectors/useGetPaymentService';
import { useTaxApi } from '../../../hooks/useTaxApi';

export const UATax = ({ classNames = {} }) => {
  const { t } = useI18n();
  const { currency, limits = {} } = useGetPaymentService();
  const { min, max } = limits;

  const defaultTaxes = {
    noTaxable: { noTaxFee: 0, noTaxFeeRate: 0, noTaxReceived: 0, noTaxSum: 0 },
    taxable: {
      taxFee: 0,
      taxFeeRate: 0,
      taxMilitary: 0,
      taxMilitaryRate: 0,
      taxPersonal: 0,
      taxPersonalRate: 0,
      taxReceived: 0,
      taxSum: 0
    }
  };
  const [taxes, setTaxes] = useState(defaultTaxes);
  const { getValues } = useFormContext();
  const { amount } = getValues();

  const { loadTaxes } = useTaxApi(true);

  const onAmountChange = useRef(
    debounce((amountValue) => {
      if (amountValue >= min && amountValue <= max) {
        loadTaxes(amountValue).then((res) => {
          setTaxes(res || {});
        });
      } else {
        setTaxes(defaultTaxes);
      }
    }, 500)
  );
  useEffect(() => {
    if (onAmountChange.current) {
      onAmountChange.current(amount);
    }
  }, [amount, onAmountChange]);

  const { wrapperClassName } = classNames;

  return (
    <Box className={wrapperClassName} direction="column" gap="m">
      <Box direction="column">
        <AText color="po_txt_color_3">{t('web.newTaxInfo.title')}</AText>
        <AText color="po_txt_color_3">
          {t('web.newTaxInfo.part1.returnDeposit', [
            taxes.noTaxable?.noTaxSum || 0,
            currency
          ])}
        </AText>
        <AText color="po_txt_color_3">
          {t('web.newTaxInfo.part1.fee', [
            taxes.noTaxable?.noTaxFee || 0,
            currency
          ])}
        </AText>
        <Box direction="row" gap="xs">
          <AText color="po_txt_color_3">
            {t('web.newTaxInfo.part1.amountReceived')}
          </AText>
          <AText color="po_txt_color_3" breed="B">
            {`${taxes.noTaxable?.noTaxReceived || 0} ${currency}`}
          </AText>
        </Box>
      </Box>
      <Box direction="column">
        <AText color="po_txt_color_3">
          {t('web.newTaxInfo.part2.taxableWinning', [
            taxes.taxable?.taxSum || 0,
            currency
          ])}
        </AText>
        <AText color="po_txt_color_3">
          {t('web.newTaxInfo.part2.personalIncomeTax', [
            taxes.taxable?.taxPersonalRate || 0,
            taxes.taxable?.taxPersonal || 0,
            currency
          ])}
        </AText>
        <AText color="po_txt_color_3">
          {t('web.newTaxInfo.part2.militaryTax', [
            taxes.taxable?.taxMilitaryRate || 0,
            taxes.taxable?.taxMilitary || 0,
            currency
          ])}
        </AText>
        <AText color="po_txt_color_3">
          {t('web.newTaxInfo.part2.fee', [
            taxes.taxable?.taxFee || 0,
            currency
          ])}
        </AText>
        <Box direction="row" gap="xs">
          <AText color="po_txt_color_3">
            {t('web.newTaxInfo.part2.amountReceived')}
          </AText>
          <AText color="po_txt_color_3" breed="B">
            {`${taxes.taxable?.taxReceived || 0} ${currency}`}
          </AText>
        </Box>
      </Box>
    </Box>
  );
};
