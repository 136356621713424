import { useEffect } from 'react';
import { LOG_ACTIONS, logAction } from '@namespace/analytics';
import { useAdvFilterStore } from '../../storeAdvFilter';

export const useLogFilterLoad = () => {
  const advFilter = useAdvFilterStore('advFilter');
  const isLoaded = advFilter != null;

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    logAction(LOG_ACTIONS.ADV_FILTER_LOAD_RESULT, {
      filter: advFilter
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);
};
