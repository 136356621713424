import { LayoutProvider } from '@namespace/cms';
import { CoefTypeProvider } from '@namespace/user';
import {
  BottomNavigationMenuProvider,
  FixScrollProvider,
  ReferenceProvider
} from '@namespace/common';
import { NotificationProvider } from '@namespace/notifications';
import { VirtualSportsProvider } from '@namespace/virtual-sports';
import { BetSlipProvider } from '@namespace/betslip';
import { GamesProvider, JackpotsProvider } from '@namespace/games';
import { TournamentsLeaderboardsProvider } from '@namespace/promotions';
import { AssistantProvider } from '@namespace/assistant';
import { MailBoxProvider } from '@namespace/account';
import { BonusShopProvider, ClubProvider } from '@namespace/loyalty';
import { BetsProvider } from '@namespace/bets';
import { ResultsProvider } from '@namespace/results';
import { PredictionsProvider } from '@namespace/predictions';
import { ConfigProvider, PaymentsProvider } from '@namespace/payments';
import { SWPaymentsProvider } from '@namespace/payments-sw';
import { NativeAppProvider } from '@namespace/native-app';
import { ABTestProvider } from '@namespace/ab-test';
import { WidgetLayoutProvider } from '@namespace/widget';
import { BonusesProvider } from '@namespace/bonuses';
import { CookieManagementProvider } from '@namespace/cookie-management';
import DatePickerProvider from './DatePickerProvider';

export const appProvidersMap = {
  cmsLayout: LayoutProvider,
  betsType: CoefTypeProvider,
  fixScroll: FixScrollProvider,
  // TODO: move to page or remove at all
  config: ConfigProvider,
  reference: ReferenceProvider,
  bonuses: BonusesProvider,
  notification: NotificationProvider,
  betSlip: BetSlipProvider,
  virtualSports: VirtualSportsProvider,
  games: GamesProvider,
  assistant: AssistantProvider,
  club: ClubProvider,
  mailBox: MailBoxProvider,
  predictions: PredictionsProvider,
  bets: BetsProvider,
  results: ResultsProvider,
  shop: BonusShopProvider,
  datePickerProvider: DatePickerProvider,
  payments: PaymentsProvider,
  paymentSw: SWPaymentsProvider,
  nativeAppProvider: NativeAppProvider,
  abTestProvider: ABTestProvider,
  widgetLayoutProvider: WidgetLayoutProvider,
  jackpots: JackpotsProvider,
  cookieManagementProvider: CookieManagementProvider,
  tournamentLeaderboards: TournamentsLeaderboardsProvider,
  bottomNavigationMenu: BottomNavigationMenuProvider
};
