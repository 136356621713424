import { HTMLSnippet } from '@namespace/snippets';
import {
  BONUS_CONTENT_MACRO_FORMATS,
  BONUS_CONTENT_MACRO_TO_API_MAP
} from '../../constants';
import { formatBonusValue } from '../../utils/formatBonusValue';

export const BonusContent = ({ snippetId, bonusDetails }) => {
  const { macros } = bonusDetails;

  const transform = (src = '') =>
    src.replaceAll(/\{([a-z_]+)}/g, (match, macroName) => {
      if (!(macroName in BONUS_CONTENT_MACRO_TO_API_MAP)) {
        return match;
      }

      const rawValue = macros[macroName];
      const format = BONUS_CONTENT_MACRO_FORMATS[macroName];

      return rawValue != null && format != null
        ? formatBonusValue(rawValue, format)
        : rawValue;
    });

  return <HTMLSnippet snippetId={snippetId} transform={transform} />;
};
