import {
  useDisableScrollRestore,
  useCreateWindowChangeUrl
} from '@namespace/common';

const useWindowPurpose = () => {
  useCreateWindowChangeUrl();
  useDisableScrollRestore();
};

export default useWindowPurpose;
