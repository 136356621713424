import { useCallback, useContext, useState } from 'react';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { DeviceContext, FORM_FACTORS_MAP } from '@namespace/device';
import { PAYMENT_SYSTEMS } from '@namespace/payments';
import { useGeneralRoutes, useOtherSettings } from '@namespace/cms';
import { useNotifications } from '@namespace/notifications';
import { UserContext } from '@namespace/user';
import { LOCAL_STORAGE_KEYS, setLocalStorageValue } from '@namespace/helpers';
import prepareBonusDetailsList from '../../utils/prepareBonusDetailsList';
import getOutputBetType from '../../utils/getOutputBetType';
import { accept } from '../../api/acceptBonus';
import { activateBonusSystemBonus } from '../../api/activateBonusSystemBonus';
import { selectBonusSystemBonus } from '../../api/selectBonusSystemBonus';
import { acceptWager } from '../../api/fundsBonuses';
import {
  acceptModeType,
  bonusTab,
  fundsBonusAmountType,
  fundsBonusSystemStates,
  fundsStates,
  PREWAGER_BONUS_TYPE,
  progressBarStates
} from '../../constants';
import { unselectBonusSystemBonus } from '../../api/unselectBonusSystemBonus';
import { BonusesContext } from '../../store/context';

const useFundsBonus = (bonus, onAccept, onWagerAccept, opts = {}) => {
  const [isRequestProcessing, setIsRequestProcessing] = useState(false);
  const { t, f, language, timezone } = useI18n();
  const DATE_FORMATS_TYPES = f.getFormats();
  const [user] = useContext(UserContext);
  const { activeWallet = {}, wallets = [] } = user;
  const { deposit: userBalance = 0, paymentInstrumentId = 0 } = activeWallet;
  const { isShowServices = true } = opts;
  const { isBonusSliderCRMBonusClickable = true } = useOtherSettings();
  const [{ activeBonusSystemBonusId }] = useContext(BonusesContext);

  const { push } = useHistory();
  const {
    liveSportAll,
    casinoWager,
    deposit,
    games,
    createWallet: createWalletPage,
    fundsBonusDetails
  } = useGeneralRoutes();
  const {
    isBsBonus,
    state,
    acceptMode,
    bonusId,
    bonusModelId,
    outputBetTotal,
    outputMaxTotal,
    currency,
    bonusSum,
    maxActivationTime = {},
    isCashback,
    bonusMinSum,
    bonusMaxSum,
    incomeDepositMin,
    outputBetType,
    limitCalcPercent,
    bonusType,
    wagerMultiplier,
    services,
    updatedAt,
    triggerSettings
  } = bonus;
  const isCashbackBonus = isCashback ? 'cashback' : 'funds';
  const type = bonusType ? 'bonussystem' : isCashbackBonus;
  const casinoWagerLink = casinoWager || games;

  const { bonusAmountType, currency: triggerCurrency, percentOfDeposit } =
    triggerSettings || {};
  const { minDeposit, maxDeposit } = triggerCurrency || {};

  const { isDesktop } = useContext(DeviceContext);
  const platform = isDesktop
    ? FORM_FACTORS_MAP.DESKTOP
    : FORM_FACTORS_MAP.MOBILE;

  const { errorNotification, successNotification } = useNotifications();

  const isWagerAvailable = useCallback(
    () => acceptModeType.WAGER.includes(acceptMode),
    [acceptMode]
  );

  const isShowWagerButton = useCallback(
    () =>
      state === fundsBonusSystemStates.WAGERING ||
      (state === fundsStates.BONUS_WAGERING &&
        !isCashback &&
        outputMaxTotal > 0),
    [outputMaxTotal, isCashback, state]
  );

  const isCanAccept = useCallback(
    () => acceptModeType.ACCEPT.includes(acceptMode),
    [acceptMode]
  );

  const isNoWallet = useCallback(
    () =>
      ((wallets.length === 1 && paymentInstrumentId === PAYMENT_SYSTEMS.FP) ||
        wallets.length === 0) &&
      userBalance <= 0 &&
      !isWagerAvailable() &&
      !isCanAccept(),
    [wallets, userBalance, paymentInstrumentId, isWagerAvailable, isCanAccept]
  );

  const isPotential = useCallback(() => state === fundsStates.BONUS_POTENCIAL, [
    state
  ]);

  const isBonusSystemProposed = useCallback(
    () => state === fundsBonusSystemStates.PROPOSED,
    [state]
  );

  const isBonusSystemPotential = useCallback(
    () => state === fundsBonusSystemStates.POTENTIAL,
    [state]
  );

  const isShowButton = useCallback(
    () =>
      isCanAccept() ||
      isPotential() ||
      isWagerAvailable() ||
      isBonusSystemProposed() ||
      isBonusSystemPotential() ||
      isShowWagerButton(),
    [
      isCanAccept,
      isPotential,
      isWagerAvailable,
      isBonusSystemProposed,
      isBonusSystemPotential,
      isShowWagerButton
    ]
  );

  const handleAccept = useCallback(async () => {
    try {
      setIsRequestProcessing(true);
      const message = await accept(
        {
          bonus_id: bonusId,
          lang: language,
          time_zone: timezone,
          platform
        },
        bonusTab.bonus
      );
      setIsRequestProcessing(false);
      successNotification(t(message));
      if (onAccept) {
        onAccept();
      }
    } catch (e) {
      setIsRequestProcessing(false);
      errorNotification(
        t(
          e.message ||
            (e.errorCode && `accountingMessages.${e.errorCode}`) ||
            'accountingMessages.accounting_error_6',
          [currency]
        )
      );
    }
  }, [
    bonusId,
    language,
    timezone,
    platform,
    successNotification,
    t,
    onAccept,
    errorNotification,
    currency
  ]);

  const handleWagerAccept = useCallback(async () => {
    try {
      setIsRequestProcessing(true);
      const message = await acceptWager(bonusId, language, platform);
      setIsRequestProcessing(false);
      successNotification(t(message));
      if (onWagerAccept) {
        onWagerAccept();
      }
    } catch (e) {
      setIsRequestProcessing(false);
      errorNotification(
        e.message ? t(e.message) : t('accountingMessages.accounting_error_6')
      );
    }
  }, [
    bonusId,
    language,
    platform,
    successNotification,
    t,
    onWagerAccept,
    errorNotification
  ]);

  const handleStartPrewagerBonus = useCallback(async () => {
    try {
      setIsRequestProcessing(true);
      await activateBonusSystemBonus({ bonusId: bonusModelId });
      setIsRequestProcessing(false);
      successNotification(t('bonus.notification.startwagering'));
      if (onAccept) {
        onAccept();
      }
    } catch (e) {
      errorNotification(t(`prewagerMessages.${e}`));
    }
  }, [bonusModelId, errorNotification, onAccept, successNotification, t]);

  const wagerType = getOutputBetType(outputBetType) || services;
  const isWagerAcceptCasino = wagerType?.toLowerCase()?.includes('casino');

  const acceptBonus = useCallback(
    async (e) => {
      e.stopPropagation();
      if (isNoWallet()) {
        push(createWalletPage);
      } else if (isShowWagerButton()) {
        push(isWagerAcceptCasino ? casinoWagerLink : liveSportAll);
      } else if (isPotential()) {
        if (isBonusSliderCRMBonusClickable) {
          if (activeBonusSystemBonusId) {
            await unselectBonusSystemBonus({
              bonusId: activeBonusSystemBonusId
            });
          }
          setLocalStorageValue(
            LOCAL_STORAGE_KEYS.BONUS_SLIDER_ACTIVE_BONUS,
            bonusId
          );
        }
        push(deposit);
      } else if (isBonusSystemPotential()) {
        await selectBonusSystemBonus({ bonusId });
        if (isBonusSliderCRMBonusClickable) {
          setLocalStorageValue(
            LOCAL_STORAGE_KEYS.BONUS_SLIDER_ACTIVE_BONUS,
            bonusId
          );
        }
        push(deposit);
      } else if (isWagerAvailable()) {
        await handleWagerAccept();
      } else if (isCanAccept()) {
        await handleAccept();
      } else if (isBonusSystemProposed()) {
        await handleStartPrewagerBonus();
      }
    },
    [
      isNoWallet,
      isPotential,
      isWagerAvailable,
      isCanAccept,
      isBonusSystemProposed,
      isBonusSystemPotential,
      push,
      createWalletPage,
      deposit,
      handleWagerAccept,
      handleAccept,
      handleStartPrewagerBonus,
      activeBonusSystemBonusId
    ]
  );

  const formatButtonTitle = useCallback(() => {
    if (isPotential() || isNoWallet() || isBonusSystemPotential()) {
      return 'deposit';
    }
    if (isWagerAvailable()) {
      return outputBetTotal === 0 ? 'start' : 'resume';
    }
    if (isCanAccept()) {
      return 'accept';
    }
    if (isBonusSystemProposed()) {
      return 'start';
    }
    if (isShowWagerButton()) {
      return 'wager';
    }
    return '';
  }, [
    isPotential,
    isNoWallet,
    isBonusSystemPotential,
    isWagerAvailable,
    isCanAccept,
    isBonusSystemProposed,
    isShowWagerButton,
    outputBetTotal
  ]);

  const isWagerEnabled = useCallback(
    () =>
      bonus.outputMaxTotal > 0 &&
      [
        fundsStates.BONUS_WAGERING,
        fundsStates.BONUS_PROPOSED,
        fundsStates.BONUS_INCOME_NOT_ACHIEVED,
        fundsStates.BONUS_OUTPUT_NOT_ACHIEVED,
        fundsStates.BONUS_WASNOT_ACTIVATED,
        fundsBonusSystemStates.WAGERING,
        fundsBonusSystemStates.COMPLETED,
        fundsBonusSystemStates.EXPIRED_PROPOSED,
        fundsBonusSystemStates.CANCELED_BY_PLAYER,
        fundsBonusSystemStates.EXPIRED_WAGERING_PERIOD
      ].includes(state),
    [state, bonus]
  );

  const getProgressBarStatus = useCallback(() => {
    if (
      state === fundsStates.BONUS_WAGERING ||
      state === fundsBonusSystemStates.WAGERING
    ) {
      return progressBarStates.ACTIVE;
    }
    if (outputBetTotal === 0 && state === fundsStates.BONUS_PROPOSED) {
      return progressBarStates.NOT_ACTIVE;
    }
    if (
      outputBetTotal > 0 &&
      [fundsStates.BONUS_PROPOSED, fundsBonusSystemStates.COMPLETED].includes(
        state
      )
    ) {
      return progressBarStates.DISABLED;
    }
    if (
      [
        fundsStates.BONUS_INCOME_NOT_ACHIEVED,
        fundsStates.BONUS_OUTPUT_NOT_ACHIEVED,
        fundsStates.BONUS_WASNOT_ACTIVATED
      ].includes(state)
    ) {
      return progressBarStates.EXPIRED;
    }
    if (
      [
        fundsBonusSystemStates.CANCELED_BY_MANAGER,
        fundsBonusSystemStates.CANCELED_BY_PLAYER,
        fundsBonusSystemStates.EXPIRED_PROPOSED,
        fundsBonusSystemStates.EXPIRED_WAGERING_PERIOD
      ].includes(state)
    ) {
      return progressBarStates.COMPLETED;
    }

    return '';
  }, [state, outputBetTotal]);

  const isCountdownTimerEnabled = useCallback(
    () =>
      state === fundsStates.BONUS_POTENCIAL ||
      state === fundsStates.BONUS_WAGERING ||
      (state === fundsStates.BONUS_PROPOSED && outputMaxTotal > 0) ||
      (state === fundsStates.BONUS_PROPOSED && isCashback) ||
      (state === fundsStates.BONUS_WAGERING && isCashback) ||
      (state === fundsStates.BONUS_ACTVATED && isCashback) ||
      (bonusType === PREWAGER_BONUS_TYPE &&
        [
          fundsBonusSystemStates.POTENTIAL,
          fundsBonusSystemStates.PROPOSED,
          fundsBonusSystemStates.WAGERING
        ].includes(state)),
    [state, outputMaxTotal, isCashback, bonusType]
  );

  const getCountdownTimerTitle = useCallback(() => {
    if (state === fundsStates.BONUS_POTENCIAL) {
      return t('web.personaloffice.bonuses.timer.time.to.deposit');
    }

    if (state === fundsStates.BONUS_PROPOSED && isCashback) {
      return t('web.personaloffice.bonuses.timer.time.to.start');
    }

    if (
      outputMaxTotal > 0 &&
      [fundsStates.BONUS_WAGERING, fundsStates.BONUS_PROPOSED].includes(state)
    ) {
      return t('web.personaloffice.bonuses.timer.hurry.up.to.wager');
    }

    if (state === fundsStates.BONUS_WAGERING && isCashback) {
      return t('web.personaloffice.bonuses.cbb.timer.wagering');
    }

    if (state === fundsStates.BONUS_WAGERING) {
      return t('bonuses.countdownTimer.timeForActivity');
    }

    if (state === fundsStates.BONUS_ACTVATED && isCashback) {
      return t('web.personaloffice.bonuses.timer.time.to.accepts');
    }

    if (state === fundsBonusSystemStates.WAGERING) {
      return t('web.personaloffice.bonuses.timer.hurry.up.to.wager');
    }

    if (
      state === fundsBonusSystemStates.PROPOSED ||
      state === fundsBonusSystemStates.POTENTIAL
    ) {
      return t('web.personalOffice.bonuses.timeToActivate');
    }

    return '';
  }, [state, isCashback, outputMaxTotal, t]);

  const openDetails = useCallback(() => {
    if (!isRequestProcessing) {
      push(`${fundsBonusDetails}/${isBsBonus ? 'bs' : ''}${bonusId}`);
    }
  }, [isRequestProcessing, push, fundsBonusDetails, bonusId]);

  const maxActivationTimeInMilliseconds =
    maxActivationTime?.sec * 1000 || maxActivationTime?.sec;

  const getDetailsList = useCallback(
    () =>
      prepareBonusDetailsList([
        {
          name: t('web.personaloffice.bonuses.bonus.amount'),
          value: `${bonusSum}${currency ? ` ${currency}` : ''}`,
          isDisplay:
            bonusSum &&
            bonusSum !== null &&
            !isCashback &&
            state !== fundsBonusSystemStates.POTENTIAL &&
            state !== fundsBonusSystemStates.EXPIRED_POTENTIAL,
          isBonusAmount: true
        },
        {
          name: t('web.personaloffice.bonuses.bonus.amount'),
          value: t('web.personaloffice.bonuses.bonus.amount.percent', [
            percentOfDeposit
          ]),
          isDisplay:
            state === fundsBonusSystemStates.POTENTIAL &&
            bonusAmountType === fundsBonusAmountType.PERCENT
        },
        {
          name: t('web.personaloffice.bonuses.bonus.amount'),
          value: `${bonusSum}${currency ? ` ${currency}` : ''}`,
          isDisplay:
            bonusSum !== null && state === fundsBonusSystemStates.POTENTIAL,
          isBonusAmount: true
        },
        {
          name: t('web.personaloffice.bonuses.potential.min.deposit'),
          value: `${minDeposit} ${currency}`,
          isDisplay: state === fundsBonusSystemStates.POTENTIAL,
          isBonusAmount: true
        },
        {
          name: t('web.personaloffice.bonuses.potential.max.deposit'),
          value: `${maxDeposit} ${currency}`,
          isDisplay: state === fundsBonusSystemStates.POTENTIAL,
          isBonusAmount: true
        },
        {
          name: t('web.personalOffice.bonuses.wagerAmount'),
          value: `x${wagerMultiplier}`,
          isDisplay: wagerMultiplier > 0
        },
        {
          name: t('web.personaloffice.bonuses.required.wager'),
          value: `${outputMaxTotal}${currency ? ` ${currency}` : ''}`,
          isDisplay:
            outputMaxTotal > 0 &&
            state !== fundsBonusSystemStates.POTENTIAL &&
            state !== fundsBonusSystemStates.EXPIRED_POTENTIAL,
          isBonusAmount: true
        },
        {
          name: t('web.personaloffice.bonuses.min.deposit.activate'),
          value: `${incomeDepositMin}${currency ? ` ${currency}` : ''}`,
          isDisplay:
            isCashback &&
            (state === fundsStates.BONUS_POTENCIAL ||
              state === fundsStates.BONUS_INCOME_NOT_ACHIEVED ||
              state === fundsStates.BONUS_OUTPUT_NOT_ACHIEVED ||
              state === fundsStates.BONUS_WASNOT_ACTIVATED ||
              state === fundsBonusSystemStates.POTENTIAL),
          isBonusAmount: true
        },
        {
          name: t('web.personaloffice.bonuses.cashback.interest'),
          value: `${limitCalcPercent}%`,
          isDisplay:
            isCashback &&
            state !== fundsStates.BONUS_ACTVATED &&
            state !== fundsStates.BONUS_RELEASED
        },
        {
          name: t('web.personaloffice.bonuses.cbb.available.cashback'),
          value: `${bonusSum} ${currency}`,
          isDisplay: isCashback && state === fundsStates.BONUS_ACTVATED,
          isBonusAmount: true
        },
        {
          name: t('web.personaloffice.bonuses.cashback.amount'),
          value: `${bonusSum} ${currency}`,
          isDisplay: isCashback && state === fundsStates.BONUS_RELEASED,
          isBonusAmount: true
        },
        {
          name: t('web.personaloffice.bonuses.min.cashback.amount'),
          value: `${bonusMinSum}${currency ? ` ${currency}` : ''}`,
          isDisplay:
            isCashback &&
            state !== fundsStates.BONUS_ACTVATED &&
            state !== fundsStates.BONUS_RELEASED,
          isBonusAmount: true
        },
        {
          name: t('web.personaloffice.bonuses.max.cashback.amount'),
          value: `${bonusMaxSum}${currency ? ` ${currency}` : ''}`,
          isDisplay:
            isCashback &&
            state !== fundsStates.BONUS_ACTVATED &&
            state !== fundsStates.BONUS_RELEASED,
          isBonusAmount: true
        },
        {
          name: t('web.personaloffice.bonuses.servises'),
          value: t(
            `web.personaloffice.bonuses.bet.type.${getOutputBetType(
              outputBetType
            )}`
          ),
          isDisplay:
            isCashback &&
            isShowServices &&
            state !== fundsStates.BONUS_ACTVATED &&
            state !== fundsStates.BONUS_RELEASED
        },
        {
          name: t('web.personaloffice.bonuses.servises'),
          value: services,
          isDisplay: isShowServices && services
        },
        {
          name: t('web.personaloffice.bonuses.expired.date'),
          value: f
            .getDateTime({ date: maxActivationTimeInMilliseconds })
            ?.toFormat(DATE_FORMATS_TYPES.BONUSES_DATE_FORMAT),
          isDisplay:
            state === fundsStates.BONUS_INCOME_NOT_ACHIEVED ||
            state === fundsStates.BONUS_OUTPUT_NOT_ACHIEVED ||
            state === fundsStates.BONUS_WASNOT_ACTIVATED
        },
        {
          name: t('web.personaloffice.bonuses.expiry.date'),
          value: f
            .getDateTime({ date: maxActivationTimeInMilliseconds })
            ?.toFormat(DATE_FORMATS_TYPES.BONUSES_DATE_FORMAT),
          isDisplay: !isCashback && state === fundsStates.BONUS_ACTVATED
        },
        {
          name: t('web.personaloffice.bonuses.complited'),
          value: f
            .getDateTime({ date: maxActivationTimeInMilliseconds })
            ?.toFormat(DATE_FORMATS_TYPES.BONUSES_DATE_FORMAT),
          isDisplay: state === fundsStates.BONUS_RELEASED
        },
        {
          name: t('web.personalOffice.bonuses.expiredDate'),
          value: f
            .getDateTime({ date: updatedAt })
            ?.toFormat(DATE_FORMATS_TYPES.BONUSES_DATE_FORMAT),
          isDisplay: [
            fundsBonusSystemStates.EXPIRED_POTENTIAL,
            fundsBonusSystemStates.EXPIRED_PROPOSED,
            fundsBonusSystemStates.EXPIRED_WAGERING_PERIOD
          ].includes(state),
          isAlwaysWithoutOpacity: true
        },
        {
          name: t('bonuses.details.completed'),
          value: f
            .getDateTime({ date: updatedAt })
            ?.toFormat(DATE_FORMATS_TYPES.BONUSES_DATE_FORMAT),
          isDisplay: state === fundsBonusSystemStates.COMPLETED,
          isAlwaysWithoutOpacity: true
        },
        {
          name: t('bonuses.details.canceled'),
          value: f
            .getDateTime({ date: updatedAt })
            ?.toFormat(DATE_FORMATS_TYPES.BONUSES_DATE_FORMAT),
          isDisplay: state === fundsBonusSystemStates.CANCELED_BY_PLAYER,
          isAlwaysWithoutOpacity: true
        }
      ]),
    [
      t,
      bonusSum,
      currency,
      wagerMultiplier,
      outputMaxTotal,
      services,
      f,
      maxActivationTimeInMilliseconds,
      DATE_FORMATS_TYPES.BONUSES_DATE_FORMAT,
      state,
      incomeDepositMin,
      isCashback,
      limitCalcPercent,
      bonusMinSum,
      bonusMaxSum,
      outputBetType,
      updatedAt,
      isShowServices
    ]
  );

  return {
    isShowButton,
    formatButtonTitle,
    isWagerEnabled,
    isShowWagerButton,
    getProgressBarStatus,
    isCountdownTimerEnabled,
    getCountdownTimerTitle,
    openDetails,
    getDetailsList,
    acceptBonus,
    isRequestProcessing,
    type
  };
};

export default useFundsBonus;
