import PropTypes from 'prop-types';
import { Icon } from '@namespace/icons2';
import { useHistory } from '@namespace/router';
import { useGeneralRoutes } from '@namespace/cms';
import { Box } from '@alf/uikit';
import styles from './index.module.css';

const Close = ({ onClose, dataRole = '', className = '' }) => {
  const { push } = useHistory();
  const { home } = useGeneralRoutes();

  const onClick = () => {
    if (onClose) {
      onClose();
    } else {
      push(home);
    }
  };

  return (
    <Box
      align="center"
      justify="center"
      padding="s"
      className={`${styles.link} ${className}`}
      data-role={dataRole}
      onClick={onClick}
    >
      <Icon name="icons/general/action/close" size="xs" color="bg_color_4" />
    </Box>
  );
};

Close.propTypes = {
  onClose: PropTypes.func
};

export default Close;
