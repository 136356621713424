import { Fragment, memo } from 'react';
import { VIEWS_MAP } from './constants';
import TwoField from './components/TwoField';
import MarketOutcomeContainerWrapper from './components/MarketOutcomeContainerWrapper';

const EventMarketInner = memo(
  ({
    marketTemplateViewId,
    outcomeIds,
    eventId,
    market,
    isShowName,
    isShowShortName,
    lineView,
    classNames
  }) => {
    if (marketTemplateViewId) {
      return outcomeIds.map((outcomeId, i) => (
        <Fragment key={`${eventId}-${outcomeId}`}>
          <MarketOutcomeContainerWrapper
            outcomeId={outcomeId}
            lineView={lineView}
            classNames={classNames}
            market={market}
            eventId={eventId}
            isShowName={isShowName}
            isShowShortName={isShowShortName}
          />
          {marketTemplateViewId === VIEWS_MAP.TWO_FIELD && i === 0 && (
            <TwoField lineView={lineView} outcomeId={outcomeIds[0]} />
          )}
        </Fragment>
      ));
    }

    return null;
  }
);

const EventMarketContainer = memo(
  ({
    market = {},
    eventId,
    isShowName,
    isShowShortName,
    outcomeIds = [],
    lineView,
    classNames
  }) => {
    const { marketTemplateViewId } = market || {};

    return (
      <EventMarketInner
        marketTemplateViewId={marketTemplateViewId}
        outcomeIds={outcomeIds}
        eventId={eventId}
        market={market}
        isShowName={isShowName}
        isShowShortName={isShowShortName}
        lineView={lineView}
        classNames={classNames}
      />
    );
  }
);

export default EventMarketContainer;
