import { memo } from 'react';
import { useI18n } from '@namespace/i18n';
import { AText, Box } from '@alf/uikit';
import styles from './index.module.css';

const Title = () => {
  const { t } = useI18n();
  return (
    <Box margin="s" className={styles.title}>
      <AText breed="BU" size="xl" color="txt_color_1">
        {`${t('sportsBook.topLiveEvents.title1')} `}
      </AText>
      <AText
        breed="BU"
        size="xl"
        color="accent_color_3"
        className={styles.secondTitle}
      >
        {t('sportsBook.topLiveEvents.title2')}
      </AText>
    </Box>
  );
};

export default memo(Title);
