import { useContext, useEffect, useState } from 'react';
import { SiteSettingsContext } from '@namespace/cms';
import { DeviceContext } from '@namespace/device';
import { useGetUserSegments } from '@namespace/segments';
import { useUserInfoLoaded } from '@namespace/user';
import { GamesContext } from '../../context';
import { useGamesIsLoaded, useGamesFeed } from '../../context/selectors';
import { useGetPlatformForFeed } from '../useGetPlatformForFeed';

export const useLoadBurgerCasinoData = ({ feedNames = '' }) => {
  const [, { SET_IS_FEED_LOADING, GET_GAMES }] = useContext(GamesContext);
  const { isDesktop } = useContext(DeviceContext);
  const { isLoading: isFeedLoading } = useGamesFeed(feedNames) || {};
  const platform = useGetPlatformForFeed();
  const isGamesLoaded = useGamesIsLoaded(feedNames, platform);
  const [settings] = useContext(SiteSettingsContext);
  const { casino = {} } = settings;
  const { disabledProviders = [] } = casino;
  const { userSegmentsNames: userSegments } = useGetUserSegments();
  const { userInfoLoaded } = useUserInfoLoaded();

  const [isLoadedBurgerFeed, setIsLoadedBurgerFeed] = useState(false); // protector recursive feed loading

  useEffect(() => {
    if (
      !isGamesLoaded &&
      !isLoadedBurgerFeed &&
      feedNames &&
      !isFeedLoading &&
      !isDesktop &&
      userInfoLoaded
    ) {
      SET_IS_FEED_LOADING(true, feedNames);

      GET_GAMES({
        disabledProviders,
        feedNames,
        isDesktop,
        platform,
        userSegments
      }).finally(() => setIsLoadedBurgerFeed(true));
    }
  }, [
    isGamesLoaded,
    disabledProviders.join('/'),
    isDesktop,
    feedNames,
    isFeedLoading,
    userSegments,
    userInfoLoaded
  ]);
};
