import { useSearchParams, useHistory } from '@namespace/router';
import { useLocation } from '@namespace/i18n';

export const useCasinoGamesSectionPagination = (isPaginationEnabled) => {
  const { page } = useSearchParams();
  const { pathname } = useLocation();
  const { replace } = useHistory();
  const formattedPage = page ? Number(page) : 1;

  const handlePageChange = (newPage) => replace(`${pathname}?page=${newPage}`);

  if (!isPaginationEnabled) return {};

  return { page: formattedPage, handlePageChange };
};
