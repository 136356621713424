import { useState, useContext, useCallback } from 'react';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { structuringErrors } from '@namespace/forms';
import { useOtherSettings, useCookieManagementEnabled } from '@namespace/cms';
import {
  setIsShowAfterLoginNotifications,
  deleteCriticalMessageStorage,
  getLocalStorageValue,
  SESSION_STORAGE_KEYS,
  setSessionStorageValue,
  LOCAL_STORAGE_KEYS,
  window,
  COOKIES_KEYS,
  getCookie,
  setCookie
} from '@namespace/helpers';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { UserContext } from '../../context';

const useLogin = (
  onSuccess = () => {},
  onError = () => {},
  loadCaptchaToken = () => {}
) => {
  const { f } = useI18n();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [, userActions] = useContext(UserContext);
  const { FETCH_LOGIN, FETCH_USER_DATA } = userActions;
  const { push } = useHistory();
  const cookieManagementEnabled = useCookieManagementEnabled();
  const { cookieManagement, showLogOnTimer = false } = useOtherSettings();
  const { disableSendingAffiliateCookies = false } = cookieManagement || {};
  const affiliateInSessionStorage = getLocalStorageValue(
    LOCAL_STORAGE_KEYS.AFFILIATE_COOKIES
  );
  const affiliateParams =
    cookieManagementEnabled &&
    !disableSendingAffiliateCookies &&
    affiliateInSessionStorage
      ? affiliateInSessionStorage
      : null;

  const login = useCallback(
    (credentials) => {
      setError(null);
      onError(null);
      if (credentials !== null) {
        const {
          username,
          password,
          captcha,
          showReCaptchaSection,
          reCaptchaParams,
          nextPage,
          withAutoLogin = true
        } = credentials;

        (async () => {
          setIsLoading(true);
          try {
            setSessionStorageValue(
              SESSION_STORAGE_KEYS.SHOW_PRIVACY_POLICY_BANNER,
              false
            );

            let user = {};
            if (withAutoLogin) {
              const reCaptchaData = showReCaptchaSection
                ? reCaptchaParams
                : await loadCaptchaToken();
              user = await FETCH_LOGIN({
                username,
                password,
                captcha,
                affiliateParams,
                reCaptchaData
              });
            }

            await FETCH_USER_DATA();

            onSuccess(user);
            deleteCriticalMessageStorage();
            if (showLogOnTimer && !getCookie(COOKIES_KEYS.LOG_ON)) {
              setCookie(COOKIES_KEYS.LOG_ON, f.getDateTime().toMillis(), {
                expires: 30
              });
            }
            setIsShowAfterLoginNotifications(true);
            if (nextPage) {
              push(nextPage);
            }
            setSuccess(false);
          } catch (e) {
            console.error(e);

            if (!e.errorCode) {
              if (nextPage) {
                push(nextPage);
              }
              logAction(LOG_ACTIONS.LOGIN_RELOAD);
              // important! needed at least for bug with different sessions in socket
              window.location.reload();
            } else {
              onError(e.errorCode, structuringErrors(e, 'accountingMessages.'));
              setError(e.errorCode);
              setSuccess(false);
            }
          }
          setIsLoading(false);
        })();
      }
    },
    [FETCH_LOGIN, FETCH_USER_DATA, onError, onSuccess, push]
  );

  return {
    login,
    success,
    error,
    setError,
    setSuccess,
    isLoading
  };
};

export default useLogin;
