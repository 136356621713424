import { useI18n } from '@namespace/i18n';
import { isFunction, noop } from 'lodash';
import { Icon } from '@namespace/icons2';
import { useFormContext } from 'react-hook-form';
import SelectField from '../../common/SelectField';
import styles from './index.module.css';

const CardSelect = ({
  options: optionList = [],
  customSelectHandler: onSelectOption,
  onChange = noop,
  onAddCardHandler,
  dependedOnFieldName,
  resetFieldsOnSelect = [],
  ...props
}) => {
  const { t } = useI18n();

  const methods = useFormContext();
  const { watch, resetField } = methods;
  const fieldWatched = watch(dependedOnFieldName);

  const onAddCard = () =>
    isFunction(onAddCardHandler) &&
    onAddCardHandler({ [dependedOnFieldName]: fieldWatched });
  const customSelectHandler = () => (data) => {
    if (resetFieldsOnSelect.length) {
      for (const field of resetFieldsOnSelect) {
        resetField(field);
      }
    }
    return isFunction(onSelectOption) && onSelectOption(data, onAddCard);
  };
  const addCardOption = {
    title: t('personaloffice.balance.add.card'),
    iconElement: (
      <Icon
        className={styles.dropdownIcon}
        name="icons/general/action/create"
        size="s"
      />
    )
  };

  const options = isFunction(onAddCardHandler)
    ? [...optionList, addCardOption]
    : optionList;

  return (
    <SelectField
      options={options}
      customSelectHandler={onSelectOption && customSelectHandler()}
      enableInstantFieldValidation={true}
      onChange={onChange}
      {...props}
    />
  );
};

export default CardSelect;
