import { uniqueId } from 'lodash';
import formatAsUriParameters from '../formatAsUriParameters';
import { makeFetch } from './makeFetch';
import {
  bettorrentResponseHandler,
  ihubResponseHandler
} from './responseHandlers';
import { fetchInCorsMode } from './fetchInCorsMode';

export const getAsJson = (...args) => fetchInCorsMode('get', args);
export const postAsJson = (...args) => fetchInCorsMode('post', args);

export const get = (...args) => makeFetch('get', args);
export const post = (...args) => makeFetch('post', args);

export const postRPC = (...args) => {
  const [method, params, ...rest] = args;

  return makeFetch('post', [
    '/frontend_api2/',
    {
      jsonrpc: '2.0',
      id: Number(uniqueId()),
      method,
      params
    },
    ...rest
  ]);
};

export const ihubPost = (...args) =>
  makeFetch('post', args, ihubResponseHandler);

export const ihubGet = (...args) => makeFetch('get', args, ihubResponseHandler);

export const bettorrentPost = (...args) =>
  makeFetch('post', args, bettorrentResponseHandler);

// Adding extra argument isWithBraces
// ---stackoverflow---
// When the target server uses a weak typed language like PHP or RoR,
// then you need to suffix the parameter name with braces [] in order to trigger
// the language to return an array of values instead of a single value.
export const postAsUriParameters = (
  url,
  body = {},
  isWithBraces = false,
  isAccountStatuses,
  reCaptchaData = {}
) =>
  makeFetch('post', [
    url,
    formatAsUriParameters(body, isWithBraces, isAccountStatuses),
    {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      ...(reCaptchaData?.headers ?? {})
    },
    'include',
    reCaptchaData?.mode ?? 'no-cors'
  ]);
