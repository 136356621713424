import { memo, useContext, useEffect, useRef } from 'react';
import { scrollTo } from '@namespace/helpers';
import { DeviceContext } from '@namespace/device';
import { HEADER_HEIGHT, DESKTOP_HEADER_HEIGHT } from '@namespace/common';
import { PREVIOUS_STEPS_IDS } from '../../constants';
import { PredictionsContext } from '../../store/context';
import { useIsActiveStepDisabled } from '../../store/selectors';
import BracketStage from '../BracketStage';
import Winner from '../Winner';
import styles from './index.module.css';

const Bracket = ({ stages = [], isResults, className = '' }) => {
  const { isDesktop } = useContext(DeviceContext);
  const bracketRef = useRef(null);
  const topRef = useRef(0);
  const [state] = useContext(PredictionsContext);
  const { activeStep } = state;
  const isDisabledStep = useIsActiveStepDisabled();

  useEffect(() => {
    scrollTo();
    if (
      !isResults &&
      Boolean(PREVIOUS_STEPS_IDS[activeStep] || isDisabledStep)
    ) {
      scrollTo({ top: topRef.current });
    }
  }, [activeStep, isDisabledStep, isResults]);

  useEffect(() => {
    if (bracketRef?.current) {
      const headerHeight = isDesktop ? DESKTOP_HEADER_HEIGHT : HEADER_HEIGHT;
      topRef.current =
        bracketRef.current.getBoundingClientRect().top - headerHeight;
    }
  }, [isDesktop]);

  return (
    <div
      className={`${isResults ? styles.results : ''} ${className}`}
      ref={bracketRef}
    >
      {stages.map((type) => (
        <BracketStage
          key={type}
          type={type}
          isResults={isResults}
          classNames={{
            wrapperClassName: styles.stage,
            bracketClassName: `${styles.bracket} ${styles[`bracket-${type}`]}`,
            matchUpClassName: `${styles.matchup}  ${styles[`matchup-${type}`]}`,
            participantClassName: `${styles.participant} ${
              styles[`participant-${type}`]
            }`
          }}
        />
      ))}
      {isResults && (
        <Winner
          isResults={isResults}
          classNames={{
            wrapperClassName: styles.stage,
            bracketClassName: `${styles.bracket} ${styles['bracket-winner']}`,
            participantClassName: `${styles.participant} ${styles['participant-winner']}`
          }}
        />
      )}
    </div>
  );
};

export default memo(Bracket);
