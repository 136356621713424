import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { useGeneralRoutes } from '@namespace/cms';
import { Tournaments } from '../../common';
import { MODE } from '../../constants';
import { useGetPathParams } from '../../hooks/useGetPathParams';
import styles from './index.module.css';

export const CategoriesTournamentsMenu = () => {
  const { preMatchTournament } = useGeneralRoutes();
  const { t } = useI18n();

  const { category: activeCategory, activeSport } = useGetPathParams(
    MODE.PRE_MATCH
  );
  const { categoryId } = activeCategory || {};
  const { sportSlug } = activeSport || {};

  return (
    <Box direction="column" className={styles.wrapper}>
      <Box padding="0 s" align="center" className={styles.title}>
        <AText breed="BU" size="m" color="sm_txt_color_1">
          {t('sportsBook.sportsMenu.tournaments.title')}
        </AText>
      </Box>
      <Tournaments
        serviceId={MODE.PRE_MATCH}
        tournamentRoute={preMatchTournament}
        categoryId={categoryId}
        sportSlug={sportSlug}
      />
    </Box>
  );
};
