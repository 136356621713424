export const PAN_KEEPER_TYPES = {
  ADD: 'add',
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdraw'
};

export const CARD_INFO = {
  CARD_NUMBER: 'cardNumber',
  EXPIRY_DATE: 'expiryDate',
  CARD_HOLDER: 'cardHolder',
  CVV: 'cvv',
  DESCRIPTION: 'description'
};

export const PAYMENT_CARD = {
  VISA: 'Visa',
  MASTERCARD: 'Mastercard',
  OTHER: 'Other'
};

// https://wiki.betinvest.work/display/MNA/Bank+Card
// per-bit flags!
export const PAN_STATUS = {
  NEW: 1,
  FIRST_DEPOSIT: 2,
  VERIFIED_BY_RMT: 4,
  BLOCKED_BY_RMT: 8,
  BLOCKED_BY_CLIENT: 16,
  FIRST_PAYOUT: 32
};

export const PAN_STATUS_BLOCKED = [
  PAN_STATUS.BLOCKED_BY_RMT,
  PAN_STATUS.BLOCKED_BY_CLIENT
];

export const PAN_STATUS_BY_CARD_STATUS = {
  isNew: 'NEW',
  isFirstDeposit: 'FIRST_DEPOSIT',
  isVerified: 'VERIFIED_BY_RMT',
  isBlockedByRisk: 'BLOCKED_BY_RMT',
  isBlockedByClient: 'BLOCKED_BY_CLIENT',
  isFirstPayout: 'FIRST_PAYOUT'
};

export const PAN_TYPE = {
  PAN: 'pan',
  WALLET: 'wallet'
};
