import { rePatterns } from '../../patterns';

const charsAndAdditionalSymbolsValidator = (
  message = 'validators.charsAndAdditionalSymbols'
) => ({
  pattern: {
    value: rePatterns.charsAndAdditionalSymbols,
    message
  }
});

export default charsAndAdditionalSymbolsValidator;
