import { useContext } from 'react';
import { SiteSettingsContext } from '../../store/siteSettings/context';
import { PageContext } from '../../store/page/context';

const useConfig = (id) => {
  const { page } = useContext(PageContext);
  const { components: pageComponents = {} } = page;
  const [{ components = {} }] = useContext(SiteSettingsContext);

  if (pageComponents[id]) {
    const { config = {} } = pageComponents[id];

    return {
      config,
      configComponents: pageComponents
    };
  }

  if (components[id]) {
    const { config = {} } = components[id];

    return {
      config,
      configComponents: components
    };
  }

  return {
    config: {},
    configComponents: {}
  };
};

export default useConfig;
