const win =
  // eslint-disable-next-line no-restricted-globals
  typeof window !== 'undefined'
    ? // eslint-disable-next-line no-restricted-globals
      window
    : {
        IS_SSR: true,
        location: {
          origin: '',
          reload: () => {},
          href: '',
          replace: () => {},
          hostname: '',
          host: '',
          protocol: '',
          search: ''
        },
        navigator: { userAgent: '', language: '' },
        parent: { location: '', postMessage: () => {} },
        open: () => {},
        history: {}
      };

export default win;
