import { identity } from 'ramda';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import window from '../constants/window';

export const fingerprint = window.fingerprintjsEnabled
  ? FingerprintJS.load()
  : () => {};

export const getDeviceId = async () => {
  const fp = await fingerprint;
  const fpResult = await fp.get();

  return fpResult.visitorId;
};

/**
 * @returns {Function}
 */
export const getDeviceIdHeaderSetter = async () => {
  if (!window.fingerprintjsEnabled) {
    return identity;
  }

  const deviceId = await getDeviceId();

  return (headers = {}) => {
    const HEADER_NAME = 'x-device-id';

    if (headers instanceof Headers) {
      headers.set(HEADER_NAME, deviceId);
    } else {
      // eslint-disable-next-line no-param-reassign
      headers[HEADER_NAME] = deviceId;
    }

    return headers;
  };
};
