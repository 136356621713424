import { path } from 'ramda';
import { useConfig } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import clsx from 'clsx';
import { useActiveHeadGroups } from '../../../hooks/useActiveHeadGroups';
import { useEventsByIdsToShow, useSportsbookStore } from '../../../store';
import { MODE } from '../../../constants';
import {
  EventsLineHeader,
  EventsLineHeadGroups,
  EventsLineTitle,
  Line,
  WidgetWrapper
} from '../../../common';
import styles from './index.module.css';

const BasePopularBetsEvents = ({
  id,
  sportId,
  sportName,
  categoryId,
  lineKey,
  className
}) => {
  const { t } = useI18n();
  const { config } = useConfig(id);
  const {
    isShowEventGroup,
    isHeadGroupsSortingEnabled,
    activeHeadGroupsAmount,
    isDisplayHeadMarkets = true
  } = config;
  const eventsToShow = useEventsByIdsToShow(lineKey);

  const [activeHeadGroups, setActiveHeadGroups] = useActiveHeadGroups(
    isHeadGroupsSortingEnabled,
    activeHeadGroupsAmount,
    sportId,
    MODE.PRE_MATCH
  );

  const popularBetEvents = useSportsbookStore(
    path(['popularBetEvents', sportId])
  );

  return (
    eventsToShow.length > 0 && (
      <WidgetWrapper
        dataRole={`c-popularBetsEvents ${lineKey}`}
        className={clsx([styles.container, className])}
      >
        <EventsLineHeader borderBottom={true} isExpanded={false}>
          <EventsLineTitle
            title={sportName}
            sportId={sportId}
            categoryId={categoryId}
            subtitle={t('sportsbook.eventsTitle.popular')}
          />
          <EventsLineHeadGroups
            serviceId={MODE.PRE_MATCH}
            sport={{ sportId }}
            isHeadGroupsSortingEnabled={true}
            activeHeadGroups={activeHeadGroups}
            setActiveHeadGroups={setActiveHeadGroups}
            isDisplayHeadMarkets={isDisplayHeadMarkets}
          />
        </EventsLineHeader>
        <Line
          id={id}
          eventsToShow={eventsToShow}
          isShowEventGroup={isShowEventGroup}
          isEventsLoading={!popularBetEvents}
          activeHeadGroups={activeHeadGroups}
          isCollapsibleTournaments={true}
          lineKey={lineKey}
        />
      </WidgetWrapper>
    )
  );
};

export default BasePopularBetsEvents;
