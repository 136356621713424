import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useOtherSettings } from '@namespace/cms';
import {
  useGamesState,
  useGetIsLiveRtpGamesLoaded,
  useCategory,
  useGetLiveRtpMixedGamesIds,
  useGetIsEmptyLiveRtpCategory
} from './selectors';
import { SECTION_TYPES, LOBBY_LIVE_RTP_GAMES_AMOUNT } from '../../constants';

export const useLiveRtpGamesCategory = (feedName) => {
  const { games } = useGamesState();
  const { casinoDynamicCategoriesIds = {} } = useOtherSettings();
  const isLiveRtpGamesLoaded = useGetIsLiveRtpGamesLoaded();
  const feedLiveRtpCategory = useCategory(
    casinoDynamicCategoriesIds.liveRtp,
    feedName
  );
  const isEmptyLiveRtpCategory = useGetIsEmptyLiveRtpCategory(feedName);

  const lobbyLiveRtpGamesIds = useGetLiveRtpMixedGamesIds({
    feedName
  });

  const lobbyLiveRtpGames = useMemo(
    () => lobbyLiveRtpGamesIds.map((gameId) => games.data[gameId]),
    [games.data, lobbyLiveRtpGamesIds]
  );

  if (
    !casinoDynamicCategoriesIds?.liveRtp ||
    isEmptyLiveRtpCategory ||
    isEmpty(feedLiveRtpCategory)
  )
    return null;

  return {
    ...feedLiveRtpCategory,
    type: SECTION_TYPES.CATEGORY_LIVE_RTP,
    isViewAllEnabled: true,
    categoryGames: lobbyLiveRtpGames,
    games: lobbyLiveRtpGamesIds,
    maxGamesAmount: LOBBY_LIVE_RTP_GAMES_AMOUNT,
    isCategoryLoading: !isLiveRtpGamesLoaded,
    isWithLiveRtpInfo: true
  };
};
