import { post, get } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

const BETS_RESULTS_PATH = '/bets/results/';

export const getSportsEventResults = ({
  date,
  id,
  page = 1,
  categoryId,
  tournamentId,
  tzDiff
}) => {
  const request = {
    date,
    page,
    tz_diff: tzDiff,
    ...(id && { sport_id: id }),
    ...(categoryId && { category_id: categoryId }),
    ...(tournamentId && { tournament_id: tournamentId })
  };

  return post(`${BETS_RESULTS_PATH}filter/`, request).then(camelizeKeys);
};

export const getSportsNames = ({ date, tzDiff }) => {
  const request = {
    date,
    tz_diff: tzDiff
  };

  return post(`${BETS_RESULTS_PATH}filter/sports/`, request).then(camelizeKeys);
};

export const getCategoriesNames = ({ date, sportId, tzDiff }) => {
  return get(`${BETS_RESULTS_PATH}sport/${sportId}/${date}/${tzDiff}`).then(
    camelizeKeys
  );
};

export const getTournamentsNames = ({ date, categoryId, tzDiff }) => {
  return get(
    `${BETS_RESULTS_PATH}category/${categoryId}/${date}/${tzDiff}`
  ).then(camelizeKeys);
};
