import { useEffect, useState } from 'react';
import { addSlashToPathEnd } from '@namespace/helpers';
import { useNavigate, useLocation } from 'react-router-dom';

const useLinkSlash = (isSiteSettingsReady) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (isSiteSettingsReady && !isReady) {
      setIsReady(true);
      const { pathname, search, hash } = location;
      const lastSlash = pathname.slice(-1) === '/';
      if (!lastSlash) {
        navigate(addSlashToPathEnd(`${pathname}${search}${hash}`), {
          replace: true
        });
      }
    }
  }, [isSiteSettingsReady, isReady]);

  return isReady;
};

export default useLinkSlash;
