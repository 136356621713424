const parseSearchParams = (search) => {
  const entries = new URLSearchParams(search).entries();
  const searchParams = Array.from(entries);

  return searchParams.reduce((memo, [key, value]) => {
    let newValue = value;
    const isArray = key.includes('[]');
    const newKey = isArray ? key.slice(0, -2) : key;

    if (isArray) {
      const currentValue = memo[newKey] || [];
      newValue = [...currentValue, value];
    }
    return {
      ...memo,
      [newKey]: newValue
    };
  }, {});
};

export default parseSearchParams;
