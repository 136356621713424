export const EGT_COMMANDS = {
  EXIT: 'com.egt-bg.exit',
  RELOAD: 'com.egt-bg.reload',
  LAUNCH: 'com.egt-bg.launchGame'
};

export const egtLauncherControl = (event, callbacks = {}) => {
  const {
    onExitHandler = () => {},
    onReloadHandler = () => {},
    onLaunchHandler = () => {}
  } = callbacks;
  if (event.data) {
    if (event.data.command === EGT_COMMANDS.EXIT) {
      onExitHandler();
    } else if (event.data.command === EGT_COMMANDS.RELOAD) {
      onReloadHandler();
    } else if (event.data.command === EGT_COMMANDS.LAUNCH) {
      onLaunchHandler();
    }
  }
};
