import clsx from 'clsx';
import { useGeneralRoutes } from '@namespace/cms';
import { LocalizedLink } from '@namespace/i18n';
import { Button } from '@alf/uikit';
import styles from './index.module.css';

const ForgotPassword = ({
  linkText = '',
  className = '',
  isRememberMeEnable = false,
  actionType = 'color3'
}) => {
  const { forgotPassword } = useGeneralRoutes();

  return (
    <Button
      intent="quaternary"
      actionType={actionType}
      size="s"
      fullWidth={true}
      component={LocalizedLink}
      to={forgotPassword}
      className={clsx(
        className,
        isRememberMeEnable ? styles.forgotPasswordWithRememberMe : ''
      )}
      dataRole="login-page-forgot-password-btn"
    >
      {linkText}
    </Button>
  );
};

export default ForgotPassword;
