import { Notification } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';

const SessionDurationToast = ({
  onClose,
  message,
  onMouseEnter,
  onMouseLeave
}) => {
  const { t } = useI18n();

  return (
    <Notification
      onClose={onClose}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      message={message}
      onClick={onClose}
      buttonLabel={t('notifications.button.ok')}
    />
  );
};

export default SessionDurationToast;
