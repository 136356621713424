import { useContext } from 'react';
import { CoefTypeContext } from '../../store/coefType/context';

export const useCoefType = () => {
  const [, { SET_COEF_TYPE }] = useContext(CoefTypeContext);
  const setCoefType = ({ value: selectedCoefType }) => {
    SET_COEF_TYPE(selectedCoefType);
  };

  return {
    setCoefType
  };
};
