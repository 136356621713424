import { useI18n } from '@namespace/i18n';
import { useGeneralRoutes, useOtherSettings } from '@namespace/cms';

import VerificationStatusNotification, {
  MobileMessage
} from '../VerificationStatusNotification';
import { DesktopMessage } from './DesktopMessage/connector';

const NotVerfiedUserToast = ({ onClose }) => {
  const { t } = useI18n();
  const generalRoutes = useGeneralRoutes();
  const {
    verificationToasts: {
      notVerifiedUserToastRoute,
      isCloseAfterLink = false
    } = {}
  } = useOtherSettings();
  const currentRoute =
    generalRoutes[notVerifiedUserToastRoute ?? 'profileDocuments'];

  const primaryText = t('unverifiedUser.notification.info');
  const linkText = t('unverifiedUser.notification.linkTitle');

  const message = (
    <DesktopMessage
      {...(isCloseAfterLink ? { onClose } : {})}
      actionText={linkText}
      link={currentRoute}
      primaryText={primaryText}
      submitText={t('unverifiedUser.notification.button')}
    />
  );

  const mobileMessage = (
    <MobileMessage
      {...(isCloseAfterLink ? { onClose } : {})}
      linkText={linkText}
      submitText={t('unverifiedUser.notification.button')}
      link={currentRoute}
      primaryText={primaryText}
    />
  );

  return (
    <VerificationStatusNotification
      onClose={onClose}
      message={message}
      mobileMessage={mobileMessage}
    />
  );
};

export default NotVerfiedUserToast;
