import { isBoolean } from 'lodash';

const hasRuleError = (value, rules) => {
  let isValid = true;

  if (Array.isArray(rules)) {
    for (const rule of rules) {
      isValid *= new RegExp(rule).test(value);
    }
  } else if (isBoolean(rules)) {
    isValid = rules;
  } else {
    isValid = new RegExp(rules).test(value);
  }

  return !isValid;
};

export default hasRuleError;
