const luhnValidator = (value) => {
  let v = value;

  if (/[^0-9-\s]+/.test(v)) {
    return false;
  }

  let nCheck = 0;
  let nDigit = 0;
  let bEven = false;
  v = v.replace(/\D/g, '');

  for (let n = v.length - 1; n >= 0; --n) {
    const cDigit = v.charAt(n);
    nDigit = parseInt(cDigit, 10);

    if (bEven) {
      nDigit *= 2;
      if (nDigit > 9) {
        nDigit -= 9;
      }
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 === 0;
};

export default luhnValidator;
