import { get } from '@namespace/helpers';

export const getCurrencyRegistration = ({ country }) =>
  get(
    `/accounting/api/v1/lists/currencies-for-registration?country=${country}`
  ).then(({ response }) => response);

export const getServicesCurrencyRegistration = ({ country, currency }) =>
  get(
    `/accounting/api/v1/lists/services-for-registration?country=${country}&currency=${currency}`
  ).then(({ response }) => response);
