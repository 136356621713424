import { AText, Box } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import clsx from 'clsx';
import styles from '../../index.module.css';

const CasinoHeader = ({
  gameName,
  providerName,
  date,
  titleClass,
  iconClass,
  headerClass,
  casinoHistoryHeaderDate = ''
}) => {
  return (
    <Box
      justify="between"
      className={`${styles.containerHeader} ${headerClass}`}
      align="center"
    >
      <AText
        className={titleClass}
        data-role="casino-history-item-header-provider"
      >
        {`${providerName} | `}
        <b>{gameName}</b>
      </AText>
      <Box className={styles.dateWidth} gap="xs" align="center">
        <Icon
          className={iconClass}
          size="xs"
          name="icons/general/item/clock-bets"
          color="po_txt_color_1"
        />
        <AText
          className={clsx(titleClass, casinoHistoryHeaderDate)}
          data-role="casino-history-item-header-date"
          breed="R"
          size="s"
          color="po_txt_color_1"
        >
          {date}
        </AText>
      </Box>
    </Box>
  );
};

export default CasinoHeader;
