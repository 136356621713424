import { SCOPE_IDS, SPORT_IDS } from '../../constants';

export const TOTAL_SYMBOL_BY_SPORT = {
  [SPORT_IDS.BASEBALL]: 'scoreboard.totalRuns',
  [SPORT_IDS.TENNIS]: 'scoreboard.totalSets',
  [SPORT_IDS.CYBERSPORT]: '',
  [SPORT_IDS.E_FIGHTING]: 'scoreboard.totalRounds',
  default: 'scoreboard.total'
};

export const TOTAL_SCOPE_ID_BY_SPORT = {
  [SPORT_IDS.SOCCER]: SCOPE_IDS.POINTS,
  [SPORT_IDS.E_FOOTBALL]: SCOPE_IDS.POINTS,
  [SPORT_IDS.BASKETBALL]: SCOPE_IDS.POINTS,
  [SPORT_IDS.VOLLEYBALL]: SCOPE_IDS.SETS,
  [SPORT_IDS.TABLE_TENNIS]: SCOPE_IDS.SETS,
  [SPORT_IDS.BASEBALL]: SCOPE_IDS.POINTS,
  [SPORT_IDS.ICE_HOCKEY]: SCOPE_IDS.POINTS,
  [SPORT_IDS.KABADDI]: SCOPE_IDS.POINTS,
  [SPORT_IDS.TENNIS]: SCOPE_IDS.SETS,
  [SPORT_IDS.FLOORBALL]: SCOPE_IDS.POINTS,
  [SPORT_IDS.ICE_HOCKEY_STATISTICS]: SCOPE_IDS.POINTS,
  [SPORT_IDS.BALL_HOCKEY]: SCOPE_IDS.POINTS,
  [SPORT_IDS.SHORT_FOOTBALL]: SCOPE_IDS.POINTS,
  [SPORT_IDS.BASKETBALL3X3]: SCOPE_IDS.POINTS,
  [SPORT_IDS.HANDBALL]: SCOPE_IDS.POINTS
};

export const SCOPE_IDS_FOR_PERIODS = {
  [SPORT_IDS.TABLE_TENNIS]: SCOPE_IDS.POINTS,
  [SPORT_IDS.TENNIS]: SCOPE_IDS.GAME,
  [SPORT_IDS.VOLLEYBALL]: SCOPE_IDS.POINTS
};

export const SPORTS_WITH_ALL_PERIODS = [SPORT_IDS.BASEBALL];

export const PENALTY_ACTIONS_SCOPE_IDS = [9, 36, 37, 38];
export const PENALTY_GOAL_SCOPE_ID = 9;
export const EVENT_RESULT_PENALTY = 3;
export const STANDART_PENALTY_LIMIT = 5;
export const STANDART_BULLET_LIMIT = 3;
export const BULLET_ACTIONS_SCOPE_IDS = [10, 13];
export const BULLETS_GOAL_SCOPE_ID = 10;
export const BULLETS_TYPE_ID = 10;

export const E_FIGHTING_SPORTFORMS_NAMES = {
  MORTAL_COMBAT: 'mortalCombat',
  UFC: 'ufc'
};

export const E_FIGHTTING_SPORTFORMS = [
  {
    // MORTAL COMBAT
    sportformIds: [1051, 1052, 1053, 1054, 1055, 1056],
    totalKey: SCOPE_IDS.WINNER,
    defaultKey: SCOPE_IDS.WINNER,
    sportformName: E_FIGHTING_SPORTFORMS_NAMES.MORTAL_COMBAT
  },
  {
    // UFC
    sportformIds: [1132, 1133],
    totalKey: [
      SCOPE_IDS.KO,
      SCOPE_IDS.TKO,
      SCOPE_IDS.DECISION,
      SCOPE_IDS.SUBMISSION
    ],
    defaultKey: '',
    sportformName: E_FIGHTING_SPORTFORMS_NAMES.UFC
  }
];

export const CYBERSPORT_SPORTFORMS = [
  {
    // CS:GO
    sportformIds: [
      893,
      894,
      891,
      892,
      889,
      890,
      883,
      884,
      251,
      252,
      977,
      978,
      979,
      980,
      // Valorant's sportform_ids
      1121,
      1122,
      1123,
      1124,
      1125,
      1126,
      1127,
      1134,
      1135,
      1180,
      1181,
      1182,
      1183,
      1184,
      1185,
      1186,
      1187,
      1188,
      1189
    ],
    totalKey: SCOPE_IDS.SETS,
    defaultKey: SCOPE_IDS.ROUNDS
  },
  {
    // StarCraft 2
    sportformIds: [213, 214],
    totalKey: SCOPE_IDS.WINNER,
    defaultKey: SCOPE_IDS.WINNER
  }
];

export const RESULTS = {
  POINTS: 1,
  YELLOWCARD: 2,
  REDCARD: 3,
  CORNER: 4,
  PEN: 9,
  FOULS: 18,
  TRPEN: 29,
  REBOUNDS: 34
};

export const ORDERED_RESULT_TYPES = {
  [SPORT_IDS.SOCCER]: [7, 8, 510, 511, 512],
  [SPORT_IDS.KABADDI]: [7, 8, 510, 511],
  [SPORT_IDS.E_FOOTBALL]: [7, 8],
  [SPORT_IDS.BASKETBALL]: [60, 61, 62, 64, 63],
  [SPORT_IDS.VOLLEYBALL]: [80, 81, 82, 83, 84, 85, 86],
  [SPORT_IDS.TABLE_TENNIS]: [80, 81, 82, 83, 84, 85, 86],
  [SPORT_IDS.BASEBALL]: [515, 516, 600, 601, 602, 603, 604, 605, 606],
  [SPORT_IDS.ICE_HOCKEY]: [51, 52, 53, 512, 10],
  [SPORT_IDS.TENNIS]: [80, 81, 82, 83, 84],
  [SPORT_IDS.CYBERSPORT]: [
    1,
    100,
    101,
    102,
    103,
    104,
    105,
    106,
    118,
    119,
    120,
    609
  ],
  [SPORT_IDS.E_FIGHTING]: [31, 32, 33, 34, 35, 36, 644, 645, 646],
  [SPORT_IDS.BASKETBALL3X3]: [4, 512],
  [SPORT_IDS.SHORT_FOOTBALL]: [7, 8],
  [SPORT_IDS.HANDBALL]: [7, 8, 510, 511]
};
