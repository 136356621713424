import { post } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

export const getLiveCalendarEvents = ({
  lang,
  offset,
  startTime,
  endTime,
  sports,
  timezone,
  isOnlyWithVideo
}) => {
  return post('/live/calendar/events/', {
    lang,
    limit: 10,
    offset,
    start_time: startTime,
    end_time: endTime,
    sports,
    time_zone: timezone,
    video: isOnlyWithVideo ? 'yes' : 'no'
  }).then(camelizeKeys);
};
