import {
  NEXT_STEPS_IDS,
  PREDICTIONS_STATUS,
  PREDICTIONS_STEPS_IDS,
  PREDICTIONS_STEPS_ORDER
} from '../../constants';
import isNextStep from '../../utils/isNextStep';
import {
  normalizeReference,
  normalizeUserPrediction,
  normalizeIsValidPrediction
} from '../normalizer';
import { getEmptyStep } from '../normalizer/steps';
import { formatGroups } from '../normalizer/groups';

export const types = {
  FETCH_USER_PREDICTION: 'FETCH_USER_PREDICTION',
  SET_USER_PREDICTION: 'SET_USER_PREDICTION',
  FETCH_REFERENCE: 'FETCH_REFERENCE',
  SET_REFERENCE: 'SET_REFERENCE',
  SET_INDEX: 'SET_INDEX',
  SAVE_PREDICTION: 'SAVE_PREDICTION',
  CHANGE_ACTIVE_STEP: 'CHANGE_ACTIVE_STEP',
  CHANGE_TEAMS: 'CHANGE_TEAMS',
  SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',
  OFF_FILLED_FROM_STEP: 'OFF_FILLED_FROM_STEP',
  EDIT_STEP: 'EDIT_STEP',
  SET_STEPS: 'SET_STEPS',
  OFF_EDITING: 'OFF_EDITING',
  LOAD_FROM_LOCAL_STORAGE: 'LOAD_FROM_LOCAL_STORAGE',
  SET_LOCAL_STATUS: 'SET_LOCAL_STATUS'
};

export const initialState = {
  reference: {},
  activeStep: PREDICTIONS_STEPS_IDS.GROUPS,
  status: '',
  realWinAmount: 0,
  happyPathAmount: 0,
  endDateOfEditing: '',
  isReferenceLoaded: false,
  isPredictionsLoaded: false,
  isPredictionsLoadFromLocalStore: false,
  steps: {},
  defaultGroups: {},
  isAllowEditing: true,
  disabledSteps: {},
  editingInProgress: false,
  localStatus: PREDICTIONS_STATUS.DEFAULT
};

export const reducer = {
  [types.SET_REFERENCE]: (state, payload) => {
    const {
      reference,
      steps,
      endDateOfEditing,
      isReferenceLoaded,
      ...rest
    } = normalizeReference(payload, state.isPredictionsLoaded);

    let finalSteps = state.steps;

    if (!state.isPredictionsLoaded && !state.isPredictionsLoadFromLocalStore) {
      finalSteps = { ...state.steps, ...steps };
    }
    if (state.isPredictionsLoaded) {
      finalSteps = normalizeIsValidPrediction(state.steps, reference);
    }

    return {
      ...state,
      ...rest,
      reference,
      endDateOfEditing,
      isAllowEditing:
        Boolean(endDateOfEditing) &&
        new Date(endDateOfEditing).getTime() > new Date().getTime(),
      steps: finalSteps,
      isReferenceLoaded
    };
  },
  [types.SET_USER_PREDICTION]: (state, payload) => {
    const {
      steps: predSteps,
      isPredictionsLoaded,
      ...rest
    } = normalizeUserPrediction(payload);
    const steps = predSteps || state.steps;
    let disabledSteps = { ...state.disabledSteps };

    if (
      (rest.status === PREDICTIONS_STATUS.SAVED ||
        rest.status === PREDICTIONS_STATUS.COMPLETED) &&
      isPredictionsLoaded
    ) {
      disabledSteps = Object.keys(steps).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {});
    }

    return {
      ...state,
      ...rest,
      steps,
      disabledSteps,
      isPredictionsLoaded
    };
  },
  [types.SET_ACTIVE_STEP]: (state, nextActiveStep) => {
    let newState = {
      ...state,
      activeStep: nextActiveStep
    };

    if (isNextStep(nextActiveStep, state.activeStep)) {
      newState = {
        ...newState,
        disabledSteps: {
          ...state.disabledSteps,
          [state.activeStep]: true
        }
      };
    }

    return {
      ...newState
    };
  },
  [types.CHANGE_TEAMS]: (state, { step = state.activeStep, teamValues }) => {
    const newTeams = { ...state.steps[step]?.teams };
    for (const team of teamValues) {
      newTeams[team.id] = {
        ...newTeams[team.id],
        ...team.value
      };
    }
    const newSteps = { ...state.steps };
    newSteps[step] = {
      ...state.steps[step],
      teams: newTeams
    };

    const nextStep = NEXT_STEPS_IDS[step] && state.steps[NEXT_STEPS_IDS[step]];

    // if you fill activeStep, and your next step isFilled true, but you clear your activeStep(TO_OF_THIRD for example)
    // !nextIsFilled should work in useSetTeamsToFutureBracket
    if (nextStep) {
      newSteps[NEXT_STEPS_IDS[step]] = {
        ...state.steps[NEXT_STEPS_IDS[step]],
        isFilled: false
      };
    }

    return {
      ...state,
      steps: {
        ...state.steps,
        ...newSteps
      }
    };
  },
  [types.OFF_FILLED_FROM_STEP]: (
    state,
    { step = state.activeStep, isRefill }
  ) => {
    const newSteps = { ...state.steps };
    if (isRefill) {
      newSteps[PREDICTIONS_STEPS_IDS.GROUPS] = formatGroups(
        state.defaultGroups
      );
    }
    const stepIndex = PREDICTIONS_STEPS_ORDER.findIndex((s) => s === step);
    for (const stepName of PREDICTIONS_STEPS_ORDER.slice(
      // this needed for editing topOfTHird, if i edit top of third i can not clear next step - round of 16
      // because it contains teams from group stage, but when we refill our predictions we clear
      // all steps after groups
      isRefill ? 1 : stepIndex + (step === PREDICTIONS_STEPS_IDS.GROUPS ? 3 : 2)
    )) {
      newSteps[stepName] = getEmptyStep(stepName);
    }
    return {
      ...state,
      steps: newSteps,
      disabledSteps: {},
      editingInProgress: true
    };
  },
  [types.SET_STEPS]: (state, steps) => {
    return {
      ...state,
      steps: {
        ...state.steps,
        ...steps
      }
    };
  },
  [types.OFF_EDITING]: (state) => {
    return {
      ...state,
      editingInProgress: false
    };
  },
  [types.LOAD_FROM_LOCAL_STORAGE]: (state, localState = {}) => {
    return {
      ...state,
      ...localState,
      isPredictionsLoadFromLocalStore: Boolean(Object.keys(localState).length)
    };
  },
  [types.SET_LOCAL_STATUS]: (state, localStatus) => {
    return {
      ...state,
      localStatus
    };
  }
};
