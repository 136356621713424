import { useMemo, useReducer } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import { useGlobalMockedStore } from '@namespace/onboarding';
import {
  reducers,
  types,
  actions as actionCreators,
  SiteSettingsContext
} from '@namespace/cms';

export const SiteSettingsProvider = ({ SITE_SETTINGS, children }) => {
  const memoReducers = useMemo(() => combineReducers(reducers), []);

  let initialState = null;
  if (SITE_SETTINGS) {
    initialState = SITE_SETTINGS;
  }

  const [state, dispatch] = useReducer(memoReducers, initialState);
  const actions = useActions(types, dispatch, actionCreators);

  return (
    <SiteSettingsContext.Provider
      value={useGlobalMockedStore('siteSettings', state, actions)}
    >
      {children}
    </SiteSettingsContext.Provider>
  );
};
