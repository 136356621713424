import {
  COEF_TYPE_AMERICAN,
  COEF_TYPE_HONKONG,
  COEF_TYPE_FRACTION
} from '@namespace/helpers';

import Fraction from 'fraction.js';

// Доробные ауткамы
function fractionalConverter(coeff) {
  if (coeff === 2) return 'Evs (1/1)';
  const { n, d } = Fraction(coeff - 1);
  return [n, d].join('/');
}

// Американские ауткамы
function americanConvert(coeff) {
  return coeff < 2
    ? ['-', Math.round(100 / (coeff - 1).toFixed(2))].join('')
    : ['+', Math.round(100 * (coeff - 1))].join('');
}

export function calcCoef(coefType, coef) {
  switch (coefType) {
    case COEF_TYPE_AMERICAN:
      return americanConvert(coef);
    case COEF_TYPE_FRACTION:
      return fractionalConverter(coef);
    case COEF_TYPE_HONKONG:
      return (coef - 1).toFixed(2);
    default:
      return coef;
  }
}
