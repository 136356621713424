import { useCallback, useContext } from 'react';
import { isEmpty } from 'lodash';
import { UserContext, SET_ACTION_RAW_PAYLOAD } from '@namespace/user';
import { useOtherSettings } from '@namespace/cms';
import { LIMIT_TYPES } from '@namespace/responsible-gambling/src/constants';
import useLimits from '@namespace/responsible-gambling/src/hooks/useLimits';
import getBonusCounters from '../../api/getBonusCounters';

const useGetBonusesCounters = (types = []) => {
  const [user, userActions] = useContext(UserContext);
  const {
    skipBounsesCountRequest = false,
    isShowSelfExcludedBlock = false
  } = useOtherSettings();
  const [selfExclusion] = useLimits(LIMIT_TYPES.SELF_EXCLUSION);
  const { id, accountStatuses = {} } = user;
  const { isBonusHunter } = accountStatuses;
  const isSelfExcludedUser = isShowSelfExcludedBlock && !isEmpty(selfExclusion);

  return useCallback(async () => {
    if (
      !skipBounsesCountRequest &&
      id &&
      types.length &&
      !isBonusHunter &&
      !isSelfExcludedUser
    ) {
      const bonusCounters = await getBonusCounters({
        id,
        bonusTypes: types
      });

      userActions.SET_COUNTERS({
        ...bonusCounters,
        [SET_ACTION_RAW_PAYLOAD]: true
      });

      userActions.SET_IS_BONUS_COUNTERS_LOADED(true);
    }
  }, [
    id,
    isBonusHunter,
    isSelfExcludedUser,
    skipBounsesCountRequest,
    types,
    userActions
  ]);
};

export default useGetBonusesCounters;
