import { isString } from 'lodash';
import { window } from '../../constants';

export const getStorageValue = (storage, key, defaultValue) => {
  let json;
  if (window[storage]) {
    try {
      json = window[storage].getItem(key);
    } catch (e) {
      console.warn(e, `${storage} error`);
      return defaultValue;
    }
  }

  let result;
  try {
    result = JSON.parse(json);
  } catch {
    result = json;
  }

  if (result?.data && result?.expires) {
    const { data, expires } = result;
    if (new Date(expires) >= new Date()) {
      try {
        result = JSON.parse(data);
      } catch {
        result = data;
      }
    } else {
      result = null;
    }
  }

  return result || defaultValue;
};

export const setStorageValue = (storage, key, newValue, options = {}) => {
  if (key && window[storage]) {
    const { expires } = options;
    let data;
    if (expires) {
      data = JSON.stringify({
        data: isString(newValue) ? newValue : JSON.stringify(newValue),
        ...(expires ? { expires } : {})
      });
    } else {
      data = isString(newValue) ? newValue : JSON.stringify(newValue);
    }
    try {
      window[storage].setItem(key, data);
    } catch (e) {
      console.warn(e, `${storage} error`);
    }
  }
};

export const removeStorageValue = (storage, key) => {
  if (key && window[storage]) {
    try {
      window[storage].removeItem(key);
    } catch (e) {
      console.warn(e, `${storage} error`);
    }
  }
};

export const getLocalStorageValue = (key, defaultValue) =>
  getStorageValue('localStorage', key, defaultValue);
export const setLocalStorageValue = (key, newValue, options) =>
  setStorageValue('localStorage', key, newValue, options);
export const removeLocalStorageItem = (key) =>
  removeStorageValue('localStorage', key);
export const getSessionStorageValue = (key, defaultValue) =>
  getStorageValue('sessionStorage', key, defaultValue);
export const setSessionStorageValue = (key, newValue, options) =>
  setStorageValue('sessionStorage', key, newValue, options);
export const removeSessionStorageItem = (key) =>
  removeStorageValue('sessionStorage', key);
