import { useReducer, createContext } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import types from '../types';
import actionCreators from '../actions';
import { reducers, initialState } from '../reducers';

export const VirtualSportsContext = createContext();

export const VirtualSportsProvider = ({ customReducers, children }) => {
  const [state, dispatch] = useReducer(
    combineReducers(reducers, customReducers),
    initialState
  );

  const actions = useActions(types, dispatch, actionCreators);

  return (
    <VirtualSportsContext.Provider value={[state, actions]}>
      {children}
    </VirtualSportsContext.Provider>
  );
};
