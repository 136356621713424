import { OneColumn } from './OneColumn';
import { MultiColumns } from './MultiColumns';
import { OneColumnThreeColumn } from './OneColumnThreeColumn';
import { PersonalOfficeColumns } from './PersonalOfficeColumns';
import { ColumnsWithCustomFooter } from './ColumnsWithCustomFooter';
import { TwoColumnOneColumn } from './TwoColumnOneColumn';
import { TwoColumnOneColumnFullHd } from './TwoColumnOneColumnFullHd';

export const LAYOUT_TYPES = {
  ONE_COLUMN: 'oneColumn',
  ONE_COLUMN_FULL_WIDTH: 'oneColumnFullWidth',
  ONE_COLUMN_FULL_HD: 'oneColumnFullHd',
  TWO_COLUMN: 'twoColumn',
  TWO_COLUMN_70_30: 'twoColumn70_30',
  TWO_COLUMN_50_50: 'twoColumn50_50',
  TWO_COLUMN_50_50_FIXED: 'twoColumn50_50_fixed',
  TWO_COLUMN_80_20_FULL_HEIGHT: 'twoColumn80_20fullHeightTwoColumn20_60FullHd',
  TWO_COLUMN_20_FULL_HEIGHT_80: 'twoColumn20fullHeight_80TwoColumn60_20FullHd',
  THREE_COLUMN: 'threeColumn',
  THREE_COLUMN_FULL_HD: 'threeColumnFullHd',
  THREE_COLUMN_FULL_HD_SMALL_FOOTER: 'threeColumnFullHdSmallFooter',
  ONE_COLUMN_THREE_COLUMN: 'oneColumnThreeColumn',
  ONE_COLUMN_THREE_COLUMN_FULL_HD: 'oneColumnThreeColumnFullHd',
  TWO_COLUMN_70_30_FULL_HD: 'twoColumn70_30FullHd',
  PERSONAL_OFFICE: 'personalOffice',
  TWO_COLUMN_70_30_ONE_COLUMN: 'twoColumn70_30OneColumn',
  TWO_COLUMN_70_30_ONE_COLUMN_FULL_HD: 'twoColumn70_30OneColumnFullHd',
  TWO_COLUMN_30_70_FULL_HD: 'twoColumnFullHd'
};

export const layoutMap = {
  [LAYOUT_TYPES.ONE_COLUMN]: OneColumn,
  [LAYOUT_TYPES.ONE_COLUMN_FULL_WIDTH]: OneColumn,
  [LAYOUT_TYPES.ONE_COLUMN_FULL_HD]: OneColumn,
  [LAYOUT_TYPES.TWO_COLUMN_70_30]: MultiColumns,
  [LAYOUT_TYPES.TWO_COLUMN_50_50]: MultiColumns,
  [LAYOUT_TYPES.TWO_COLUMN_50_50_FIXED]: MultiColumns,
  [LAYOUT_TYPES.TWO_COLUMN_80_20_FULL_HEIGHT]: MultiColumns,
  [LAYOUT_TYPES.TWO_COLUMN_20_FULL_HEIGHT_80]: MultiColumns,
  [LAYOUT_TYPES.TWO_COLUMN]: MultiColumns,
  [LAYOUT_TYPES.THREE_COLUMN]: MultiColumns,
  [LAYOUT_TYPES.THREE_COLUMN_FULL_HD]: MultiColumns,
  [LAYOUT_TYPES.THREE_COLUMN_FULL_HD_SMALL_FOOTER]: ColumnsWithCustomFooter,
  [LAYOUT_TYPES.ONE_COLUMN_THREE_COLUMN]: OneColumnThreeColumn,
  [LAYOUT_TYPES.ONE_COLUMN_THREE_COLUMN_FULL_HD]: OneColumnThreeColumn,
  [LAYOUT_TYPES.TWO_COLUMN_70_30_FULL_HD]: PersonalOfficeColumns,
  [LAYOUT_TYPES.PERSONAL_OFFICE]: PersonalOfficeColumns,
  [LAYOUT_TYPES.TWO_COLUMN_70_30_ONE_COLUMN]: TwoColumnOneColumn,
  [LAYOUT_TYPES.TWO_COLUMN_70_30_ONE_COLUMN_FULL_HD]: TwoColumnOneColumnFullHd,
  [LAYOUT_TYPES.TWO_COLUMN_30_70_FULL_HD]: MultiColumns
};
