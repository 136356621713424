import { useContext } from 'react';
import { UserContext } from '@namespace/user/src/context';
import { useOtherSettings } from '@namespace/cms';
import { get } from 'lodash';

const useGetChatSocketUrl = () => {
  const [user] = useContext(UserContext);
  const { isVipUser = false } = user;
  const otherSettings = useOtherSettings();
  const { baseUrl = '', vipKey = '', generalKey = '' } = get(
    otherSettings,
    'customChat.chatSocketUrl',
    {}
  );

  const postfix = baseUrl[baseUrl.length - 1] === '/' ? '' : '/';

  return `${baseUrl}${postfix}${isVipUser && vipKey ? vipKey : generalKey}`;
};

export default useGetChatSocketUrl;
