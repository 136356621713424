import { useEffect, useContext, useState } from 'react';
import { identity } from 'ramda';
import root from 'react-shadow';
import { useOtherSettings } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { InnerHtmlContent } from '@namespace/common';
import { SnippetsContext } from '../../store/context';
import { getSnippetWithParams } from '../../utils/getSnippetWithParams';

const Snippet = ({
  snippetId,
  onLoaded = () => {},
  transform = identity,
  className = '',
  classNameForShadowRoot = '',
  replaceMap
}) => {
  const [isSnippetLoaded, setIsSnippetLoaded] = useState(false);
  const [{ snippets }, { GET_SNIPPET }] = useContext(SnippetsContext);
  const { language } = useI18n();
  // @TODO try remove [language]
  let html = snippets[snippetId]?.[language];
  const { isSnippetShadowRootEnabled = false } = useOtherSettings();

  useEffect(() => {
    if (!html) {
      GET_SNIPPET(snippetId);
    }
  }, [GET_SNIPPET, snippetId, html]);

  useEffect(() => {
    if (html && !isSnippetLoaded) {
      setIsSnippetLoaded(true);
      onLoaded(true);
    }
  }, [html, isSnippetLoaded]);

  if (replaceMap && html) {
    html = getSnippetWithParams({
      html,
      paramsMap: replaceMap
    });
  }
  if (html) {
    if (isSnippetShadowRootEnabled) {
      return (
        <root.div data-role={`snippet-${snippetId}`} className={className}>
          <InnerHtmlContent
            className={classNameForShadowRoot}
            html={transform(html)}
            snippetId={snippetId}
          />
        </root.div>
      );
    }

    return (
      <InnerHtmlContent
        className={className}
        html={transform(html)}
        snippetId={snippetId}
      />
    );
  }

  return null;
};

export default Snippet;
