import { useEffect } from 'react';
import { window } from '@namespace/helpers';

const useGA = (userId, isLoggedIn) => {
  useEffect(() => {
    if (userId) {
      // TODO: need return checking window.gtag after 16 march 2023 hr tournaments
      try {
        window.g_user_id = userId;
        window.g_user_auth = isLoggedIn;
      } catch (e) {
        console.warn(e);
      }
    }
  }, [userId, isLoggedIn]);
};

export default useGA;
