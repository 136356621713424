import { noop } from 'lodash';
import { parseEventData } from '../utils';

const commands = {
  handshakeReq: 'ucip.basic.g2wInitializationRequest',
  handshakeRes: 'ucip.basic.w2gInitializationResponse',

  closeGameReq: 'ucip.basic.g2wCloseGameFrameCommand'
};

const features = ['closegame'];
const protocolVersion = '1.0.7';

export const playtechLauncherControl = (event, callbacks = {}) => {
  const { onExitHandler = noop } = callbacks;
  const data = parseEventData(event.data);
  const { _type } = data;

  if (_type === commands.handshakeReq) {
    event.source.postMessage(
      JSON.stringify({
        _type: commands.handshakeRes,
        features,
        version: protocolVersion
      }),
      '*'
    );
  }

  if (_type === commands.closeGameReq) {
    onExitHandler();
  }
};
