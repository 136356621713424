import { useEffect, useRef } from 'react';
import { Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import Message from './Message';
import IsTyping from './IsTyping';

import styles from './index.module.css';

export default ({ messages = [], agentsTyping = [] }) => {
  const { t } = useI18n();
  const fallbackAgentName = t('support.chat.agent');
  const msgs = useRef(null);

  useEffect(() => {
    msgs.current.scrollTo(0, msgs.current.scrollHeight);
  }, [messages]);

  return (
    <Box className={styles.messages} direction="column" padding="s" ref={msgs}>
      {messages.map(
        ({
          isIncoming = false,
          text = '',
          agentName = '',
          dateTime = new Date(),
          status = '',
          statusText = '',
          fileName = '',
          fileSize = 0
        }) => (
          <Message
            key={text + dateTime}
            isIncoming={isIncoming}
            text={text}
            agentName={agentName || fallbackAgentName}
            dateTime={dateTime}
            status={status}
            statusText={statusText}
            fileName={fileName}
            fileSize={fileSize}
          />
        )
      )}
      {agentsTyping.map((agent, index) => (
        <IsTyping
          key={agent.name || index}
          agentName={agent.name || fallbackAgentName}
        />
      ))}
    </Box>
  );
};
