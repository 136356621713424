import { useContext } from 'react';
import { PageContext, useGeneralRoutes } from '@namespace/cms';
import { useMatchPath } from '@namespace/router';
import { isEmpty } from 'lodash';

const usePageShouldBeHydrate = () => {
  const { page } = useContext(PageContext);

  const { pageConfig = {} } = page;
  const { isSnippetPage = false } = pageConfig;
  const {
    register,
    registerSuccess,
    promotions,
    promotion,
    tournament,
    preMatchSport,
    preMatchCategory,
    preMatchTournament,
    preMatchFavorites,
    topEvents,
    preMatchEvent,
    sportSearch,
    multipleDay,
    preMatchLobby,
    preMatchFilter,
    login,
    forgotPassword
  } = useGeneralRoutes();

  const paths = [
    register && register,
    registerSuccess && registerSuccess,
    promotions && promotions,
    promotion && `${promotion}/:promoName`,
    tournament && `${tournament}/:promoName`,
    '/promos/promotions/:tag',
    '/promos/tournaments/:tag',
    '/promos/bonuses/:tag',
    preMatchLobby && preMatchLobby,
    preMatchSport && `${preMatchSport}/:id`,
    preMatchCategory && `${preMatchCategory}/:sport/:categoryId`,
    preMatchFilter && `${preMatchFilter}/:sport/`,
    preMatchTournament && `${preMatchTournament}/:sport/:tournamentId`,
    preMatchFavorites && preMatchFavorites,
    topEvents && `${topEvents}/:id`,
    preMatchEvent && `${preMatchEvent}/:sport/:id`, // loadable
    sportSearch && sportSearch,
    multipleDay && multipleDay, // not have on desktop   - on mobile loadable,
    login && login,
    forgotPassword && forgotPassword,
    '/rules'
  ].filter((i) => i);
  const pageShouldBeHydrate = !isEmpty(
    useMatchPath(paths, { isReturnFullMatch: true, catchAll: true })
  );

  return isSnippetPage || pageShouldBeHydrate;
};

export default usePageShouldBeHydrate;
