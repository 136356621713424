import { useI18n } from '@namespace/i18n';
import { AText, Box } from '@alf/uikit';
import TimerCounter from '../../../TimerCounter';
import useLiveEventState from '../../../../hooks/useLiveEventState';
import { useEventTimerCounters } from '../../../../hooks/useTimerCounter';
import { useEventFields } from '../../../../store';
import styles from './index.module.css';

const EventState = ({ eventId }) => {
  const { t } = useI18n();
  const {
    eventTimer,
    eventStatusType,
    eventResultName,
    canceled = false,
    finished = false,
    inprogress = false,
    isCachedEvent
  } = useEventFields({
    eventId,
    fields: [
      'eventTimer',
      'eventStatusType',
      'eventResultName',
      'canceled',
      'finished',
      'inprogress',
      'isCachedEvent'
    ]
  });
  const timerCounters = useEventTimerCounters(eventTimer);
  const { emptyStateText, labelText, eventTime } = useLiveEventState(
    eventId,
    isCachedEvent
  );

  return (
    <Box padding="xs 0 xs s" align="center" className={styles.container}>
      {eventTimer ? (
        <AText size="s" breed="B" color="line_txt_1">
          <TimerCounter {...timerCounters} />
        </AText>
      ) : (
        (Boolean(emptyStateText) || Boolean(eventStatusType)) && (
          <AText size="s" breed="B" color="line_txt_1">
            {emptyStateText !== ''
              ? emptyStateText
              : t(`sportsBook.eventState.${eventStatusType}`)}
          </AText>
        )
      )}
      <AText className={styles.separator} size="s" breed="B" color="line_txt_1">
        |
      </AText>
      {finished || inprogress || canceled || isCachedEvent ? (
        <AText size="s" breed="R" color="line_txt_1">
          {eventTime}
        </AText>
      ) : (
        <AText size="s" breed="R" color="line_txt_1">
          {labelText || eventResultName}
        </AText>
      )}
    </Box>
  );
};

export default EventState;
