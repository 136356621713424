import { createSelector } from 'reselect';
import { path, pluck, prop } from 'ramda';
import { selectEvents } from '../../../../normalizers';
import { groupByTournament } from '../../../../../utils';

export const selectEventsIdsBySportId = (lineKey) =>
  createSelector(
    prop('events'),
    path(['lines', lineKey]),
    path(['sortByFilter', lineKey]),
    (events, line, sortType) => {
      const eventsBySportId = selectEvents(events, line, sortType).reduce(
        (acc, event) => ({
          ...acc,
          [event.sportId]: [...(acc[event.sportId] || []), event]
        }),
        {}
      );

      return Object.entries(eventsBySportId).reduce(
        (acc, [sportId, eventsBySport]) => {
          const eventsByTournaments = groupByTournament(eventsBySport);

          return {
            ...acc,
            [sportId]: Array.from(eventsByTournaments.values()).map((items) =>
              pluck('eventId', items)
            )
          };
        },
        {}
      );
    }
  );
