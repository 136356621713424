import { types } from '../types';
import {
  normalizeSettings,
  mergeSettings,
  parseSettings,
  normalizeDefaultSettings
} from '../normalizer';

export const actions = {
  [types.SET_DEFAULT_SETTINGS]: (dispatch) => async (
    fallbackSettings,
    pageType
  ) => {
    const defaultSettings = normalizeDefaultSettings(
      fallbackSettings,
      pageType
    );

    dispatch({
      type: types.SET_SETTINGS,
      payload: normalizeSettings(defaultSettings)
    });
  },
  [types.SET_NEW_SETTINGS]: (dispatch) => async (
    fallbackSettings,
    pageType,
    settings
  ) => {
    const parsedDefaultSettings = parseSettings(fallbackSettings, pageType);
    const newSettings = parseSettings(settings, pageType);

    dispatch({
      type: types.SET_SETTINGS,
      payload: normalizeSettings(
        mergeSettings(parsedDefaultSettings, newSettings)
      )
    });
  }
};
