export const PREDICTIONS_STEPS_IDS = {
  GROUPS: 'groups',
  TOP_OF_THIRD: 'topOfThird',
  ROUND_OF_16: 'roundOf16',
  QUARTER_FINALS: 'quarterFinals',
  SEMI_FINALS: 'semiFinals',
  FINAL: 'final'
};

export const PREDICTIONS_STEPS_SELECTED_QUANTITY = {
  [PREDICTIONS_STEPS_IDS.GROUPS]: 18,
  [PREDICTIONS_STEPS_IDS.TOP_OF_THIRD]: 4,
  [PREDICTIONS_STEPS_IDS.ROUND_OF_16]: 8,
  [PREDICTIONS_STEPS_IDS.QUARTER_FINALS]: 4,
  [PREDICTIONS_STEPS_IDS.SEMI_FINALS]: 2,
  [PREDICTIONS_STEPS_IDS.FINAL]: 1
};

export const PREDICTIONS_GROUP_SELECTED_QUANTITY = {
  [PREDICTIONS_STEPS_IDS.GROUPS]: 3,
  [PREDICTIONS_STEPS_IDS.TOP_OF_THIRD]: 4,
  [PREDICTIONS_STEPS_IDS.ROUND_OF_16]: 1,
  [PREDICTIONS_STEPS_IDS.QUARTER_FINALS]: 1,
  [PREDICTIONS_STEPS_IDS.SEMI_FINALS]: 1,
  [PREDICTIONS_STEPS_IDS.FINAL]: 1
};

export const PREDICTION_STAGES = [
  PREDICTIONS_STEPS_IDS.ROUND_OF_16,
  PREDICTIONS_STEPS_IDS.QUARTER_FINALS,
  PREDICTIONS_STEPS_IDS.SEMI_FINALS,
  PREDICTIONS_STEPS_IDS.FINAL
];

export const PREVIOUS_STEPS_IDS = {
  [PREDICTIONS_STEPS_IDS.GROUPS]: '',
  [PREDICTIONS_STEPS_IDS.TOP_OF_THIRD]: PREDICTIONS_STEPS_IDS.GROUPS,
  [PREDICTIONS_STEPS_IDS.ROUND_OF_16]: PREDICTIONS_STEPS_IDS.TOP_OF_THIRD,
  [PREDICTIONS_STEPS_IDS.QUARTER_FINALS]: PREDICTIONS_STEPS_IDS.ROUND_OF_16,
  [PREDICTIONS_STEPS_IDS.SEMI_FINALS]: PREDICTIONS_STEPS_IDS.QUARTER_FINALS,
  [PREDICTIONS_STEPS_IDS.FINAL]: PREDICTIONS_STEPS_IDS.SEMI_FINALS
};

export const NEXT_STEPS_IDS = {
  [PREDICTIONS_STEPS_IDS.GROUPS]: PREDICTIONS_STEPS_IDS.TOP_OF_THIRD,
  [PREDICTIONS_STEPS_IDS.TOP_OF_THIRD]: PREDICTIONS_STEPS_IDS.ROUND_OF_16,
  [PREDICTIONS_STEPS_IDS.ROUND_OF_16]: PREDICTIONS_STEPS_IDS.QUARTER_FINALS,
  [PREDICTIONS_STEPS_IDS.QUARTER_FINALS]: PREDICTIONS_STEPS_IDS.SEMI_FINALS,
  [PREDICTIONS_STEPS_IDS.SEMI_FINALS]: PREDICTIONS_STEPS_IDS.FINAL,
  [PREDICTIONS_STEPS_IDS.FINAL]: ''
};

export const PREDICTIONS_STEPS_ORDER = [
  PREDICTIONS_STEPS_IDS.GROUPS,
  PREDICTIONS_STEPS_IDS.TOP_OF_THIRD,
  PREDICTIONS_STEPS_IDS.ROUND_OF_16,
  PREDICTIONS_STEPS_IDS.QUARTER_FINALS,
  PREDICTIONS_STEPS_IDS.SEMI_FINALS,
  PREDICTIONS_STEPS_IDS.FINAL
];

export const VERTICAL_GROUPS = [
  PREDICTIONS_STEPS_IDS.GROUPS,
  PREDICTIONS_STEPS_IDS.ROUND_OF_16
];

export const PREDICTIONS_INFO_STEPS_CONDITIONS = {
  EMPTY: 'Empty',
  ACTIVE: 'Active',
  CHECKED: 'Checked',
  FAILED: 'Failed'
};

export const PREDICTIONS_STATUS = {
  DEFAULT: 'default',
  FILLED: 'filled',
  SAVED: 'new',
  COMPLETED: 'confirmed',
  FAIL_SAVED: 'failSaved',
  FAIL_UNSAVED: 'failUnsaved'
};

export const PREDICTIONS_INFO_STEPS_ICONS_TYPES = {
  [PREDICTIONS_STATUS.DEFAULT]: [
    { stepId: 'step1', stepIcon: PREDICTIONS_INFO_STEPS_CONDITIONS.ACTIVE },
    { stepId: 'step2', stepIcon: PREDICTIONS_INFO_STEPS_CONDITIONS.EMPTY },
    { stepId: 'step3', stepIcon: PREDICTIONS_INFO_STEPS_CONDITIONS.EMPTY }
  ],
  [PREDICTIONS_STATUS.FILLED]: [
    { stepId: 'step1', stepIcon: PREDICTIONS_INFO_STEPS_CONDITIONS.CHECKED },
    { stepId: 'step2', stepIcon: PREDICTIONS_INFO_STEPS_CONDITIONS.ACTIVE },
    { stepId: 'step3', stepIcon: PREDICTIONS_INFO_STEPS_CONDITIONS.EMPTY }
  ],
  [PREDICTIONS_STATUS.SAVED]: [
    { stepId: 'step1', stepIcon: PREDICTIONS_INFO_STEPS_CONDITIONS.CHECKED },
    { stepId: 'step2', stepIcon: PREDICTIONS_INFO_STEPS_CONDITIONS.CHECKED },
    { stepId: 'step3', stepIcon: PREDICTIONS_INFO_STEPS_CONDITIONS.ACTIVE }
  ],
  [PREDICTIONS_STATUS.COMPLETED]: [
    { stepId: 'step1', stepIcon: PREDICTIONS_INFO_STEPS_CONDITIONS.CHECKED },
    { stepId: 'step2', stepIcon: PREDICTIONS_INFO_STEPS_CONDITIONS.CHECKED },
    { stepId: 'step3', stepIcon: PREDICTIONS_INFO_STEPS_CONDITIONS.CHECKED }
  ],
  [PREDICTIONS_STATUS.FAIL_SAVED]: [
    { stepId: 'step1', stepIcon: PREDICTIONS_INFO_STEPS_CONDITIONS.CHECKED },
    { stepId: 'step2', stepIcon: PREDICTIONS_INFO_STEPS_CONDITIONS.CHECKED },
    { stepId: 'step3', stepIcon: PREDICTIONS_INFO_STEPS_CONDITIONS.FAILED }
  ],
  [PREDICTIONS_STATUS.FAIL_UNSAVED]: []
};

export const ROUND_16_BRACKET_MAP = [
  [
    { thirdRound: true, index: 1 },
    { groupId: 'B', index: 1 }
  ],
  [
    { groupId: 'C', index: 2 },
    { groupId: 'A', index: 1 }
  ],
  [
    { thirdRound: true, index: 4 }, // index of group array from TOP_OF_THIRD_BRACKET
    { groupId: 'F', index: 1 }
  ],
  [
    { groupId: 'E', index: 2 },
    { groupId: 'D', index: 2 }
  ],
  [
    { thirdRound: true, index: 3 },
    { groupId: 'E', index: 1 }
  ],
  [
    { groupId: 'F', index: 2 },
    { groupId: 'D', index: 1 }
  ],
  [
    { thirdRound: true, index: 2 },
    { groupId: 'C', index: 1 }
  ],
  [
    { groupId: 'B', index: 2 },
    { groupId: 'A', index: 2 }
  ]
];

export const TOP_OF_THIRD_BRACKET = {
  ABCD: ['A', 'D', 'B', 'C'],
  ABCE: ['A', 'E', 'B', 'C'],
  ABCF: ['A', 'F', 'B', 'C'],
  ABDE: ['D', 'E', 'A', 'B'],
  ABDF: ['D', 'F', 'A', 'B'],
  ABEF: ['E', 'F', 'B', 'A'],
  ACDE: ['E', 'D', 'C', 'A'],
  ACDF: ['F', 'D', 'C', 'A'],
  ACEF: ['E', 'F', 'C', 'A'],
  ADEF: ['E', 'F', 'D', 'A'],
  BCDE: ['E', 'D', 'B', 'C'],
  BCDF: ['F', 'D', 'C', 'B'],
  BCEF: ['F', 'E', 'C', 'B'],
  BDEF: ['F', 'E', 'D', 'B'],
  CDEF: ['F', 'E', 'D', 'C']
};

export const VALID_PREDICTION_STATUS = {
  VALID: 'valid',
  INVALID: 'invalid',
  NOT_PLAYED: 'notPlayed'
};

// used for next step calculation in useSetTeamsToFutureBracket
export const BRACKET_MATCHES_EMPTY_MOCK = {
  [PREDICTIONS_STEPS_IDS.GROUPS]: [],
  [PREDICTIONS_STEPS_IDS.TOP_OF_THIRD]: [['', '', '', '', '', '']],
  [PREDICTIONS_STEPS_IDS.ROUND_OF_16]: [
    ['', ''],
    ['', ''],
    ['', ''],
    ['', ''],
    ['', ''],
    ['', ''],
    ['', ''],
    ['', '']
  ],
  [PREDICTIONS_STEPS_IDS.QUARTER_FINALS]: [
    ['', ''],
    ['', ''],
    ['', ''],
    ['', '']
  ],
  [PREDICTIONS_STEPS_IDS.SEMI_FINALS]: [
    ['', ''],
    ['', '']
  ],
  [PREDICTIONS_STEPS_IDS.FINAL]: [['', '']]
};
