import { useContext, useEffect } from 'react';
import { isEmpty } from 'lodash';
import useBetslipSubscriber from '../useBetSlipSubscriber';
import { BET_SLIP_STATUS_MAP } from '../../constants';
import { BetSlipContext } from '../../store/context';

const useLoadBetSlip = ({ serviceId = '' }) => {
  const [state, actions] = useContext(BetSlipContext);
  const { errors, preorder } = state;
  const { SET_BETSLIP_STATUS } = actions;

  useBetslipSubscriber(serviceId);

  useEffect(() => {
    if (!isEmpty(errors) || preorder) {
      SET_BETSLIP_STATUS({ status: BET_SLIP_STATUS_MAP.ACTIVE });
    }
  }, [errors, preorder]);
};

export default useLoadBetSlip;
