import { useContext } from 'react';
import {
  PageContext,
  SiteSettingsContext,
  useConfig,
  useGeneralRoutes
} from '@namespace/cms';
import { LocalizedLink } from '@namespace/i18n';
import { USER_ROLES, UserContext } from '@namespace/user';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { useIsNavMenuVisible } from '@namespace/common';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import { IS_SERVER_RENDER } from '@namespace/helpers';
import { HEADER_COLOR_SCHEME } from '../../constants';
import LogoComponent from './common/LogoComponent';
import styles from './index.module.css';

const Logo = ({
  id,
  link = '',
  imgClassName = '',
  className = '',
  isProfileMenu = false
}) => {
  const { config } = useConfig(id);
  const { home = '', lobby = '' } = useGeneralRoutes();
  const [user] = useContext(UserContext);
  const [settings] = useContext(SiteSettingsContext);
  const { page } = useContext(PageContext);
  const { pageConfig = {} } = page;
  const { headerColorScheme } = pageConfig;
  const navMenuVisible = useIsNavMenuVisible();

  const { role } = user;

  const isGuest = role === USER_ROLES.GUEST;

  const {
    siteLogo = '',
    siteLogoSecondary = '/images/logo/logoSecondary.svg',
    logoSmall,
    logoSmallSecondary = '/images/logo/logoSmallSecondary.svg',
    logoSmallScreenSize = 0,
    darkSiteLogo = '',
    darkSiteLogoSecondary = '',
    darkLogoSmall = '',
    darkLogoSmallSecondary = ''
  } = !isEmpty(config) ? config : settings?.siteConfig;

  const { basicLogo, secondaryLogo, basicLogoSmall, secondaryLogoSmall } = {
    basicLogo: siteLogo,
    secondaryLogo: siteLogoSecondary,
    basicLogoSmall: logoSmall,
    secondaryLogoSmall: logoSmallSecondary
  };

  const {
    basicLogoDark,
    secondaryLogoDark,
    basicLogoSmallDark,
    secondaryLogoSmallDark
  } = {
    basicLogoDark: darkSiteLogo || siteLogo,
    secondaryLogoDark: darkSiteLogoSecondary || siteLogo,
    basicLogoSmallDark: darkLogoSmall || logoSmall,
    secondaryLogoSmallDark: darkLogoSmallSecondary || logoSmall
  };

  const classes = clsx([
    isGuest && 'mobileGuestLogo',
    !isGuest && 'mobileLogo',
    !isGuest ? 'loginedUser' : '',
    (navMenuVisible || IS_SERVER_RENDER) && 'navMenuEnabled'
  ]);
  const children = `
  @media (min-width: ${logoSmallScreenSize + 1}px) {
    .logoSmall {
      display: none !important;
    }
  }

  @media (max-width: ${logoSmallScreenSize}px) {
    .mobileLogo.navMenuEnabled,
    .mobileGuestLogo.navMenuEnabled {
      max-width: 166px;
      height: 22px;
    }
    .mobileLogo.loginedUser {
      width: 166px;
    }
    .logoBasic {
      display: none !important;
    }
    .mobileGuestLogo {
      width: 100%;
      max-width: 106px;
      min-width: 80px;
      margin-right: 8px;
    }
    .mobileLogo {
      width: 100%;
      max-width: 130px;
      min-width: 80px;
      margin-right: 8px;
      height: 20px;
    }
  }
  @media (--mobileXS) {
    .mobileGuestLogo.navMenuEnabled {
      max-width: 135px;
    }
  }
`;

  const IS_SECONDARY_COLOR_SCHEME = !isProfileMenu
    ? headerColorScheme === HEADER_COLOR_SCHEME.SECONDARY
    : false;

  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: children }} />
      <LocalizedLink
        onClick={() => logAction(LOG_ACTIONS.LOGO_HEADER_CLICK)}
        to={`${isGuest ? lobby : link || home}`}
        className={`${styles.container} ${className}`}
      >
        <>
          <LogoComponent
            src={
              IS_SECONDARY_COLOR_SCHEME ? secondaryLogoSmall : basicLogoSmall
            }
            media={`(max-width: ${logoSmallScreenSize}px)`}
            className={clsx(
              styles.imgClassName,
              classes,
              imgClassName,
              headerColorScheme,
              styles[
                IS_SECONDARY_COLOR_SCHEME
                  ? HEADER_COLOR_SCHEME.SECONDARY
                  : HEADER_COLOR_SCHEME.PRIMARY
              ],
              'logoSmall'
            )}
          />
          <LogoComponent
            src={IS_SECONDARY_COLOR_SCHEME ? secondaryLogo : basicLogo}
            media={`(min-width: ${logoSmallScreenSize}px)`}
            className={clsx(
              styles.imgClassName,
              classes,
              imgClassName,
              headerColorScheme,
              styles[
                IS_SECONDARY_COLOR_SCHEME
                  ? HEADER_COLOR_SCHEME.SECONDARY
                  : HEADER_COLOR_SCHEME.PRIMARY
              ],
              'logoBasic'
            )}
          />
        </>
        <>
          <LogoComponent
            src={
              IS_SECONDARY_COLOR_SCHEME
                ? secondaryLogoSmallDark
                : basicLogoSmallDark
            }
            media={`(max-width: ${logoSmallScreenSize}px)`}
            className={clsx(
              styles.imgDarkClassName,
              classes,
              imgClassName,
              headerColorScheme,
              styles[
                IS_SECONDARY_COLOR_SCHEME
                  ? HEADER_COLOR_SCHEME.SECONDARY
                  : HEADER_COLOR_SCHEME.PRIMARY
              ],
              'logoSmall'
            )}
          />
          <LogoComponent
            src={IS_SECONDARY_COLOR_SCHEME ? secondaryLogoDark : basicLogoDark}
            media={`(min-width: ${logoSmallScreenSize}px)`}
            className={clsx(
              styles.imgDarkClassName,
              classes,
              imgClassName,
              headerColorScheme,
              styles[
                IS_SECONDARY_COLOR_SCHEME
                  ? HEADER_COLOR_SCHEME.SECONDARY
                  : HEADER_COLOR_SCHEME.PRIMARY
              ],
              'logoBasic'
            )}
          />
        </>
      </LocalizedLink>
    </>
  );
};

export default Logo;
