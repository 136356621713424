import { window } from '@namespace/helpers';

const logGaAction = (actionName, attributes) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: actionName,
      ...attributes
    });
  } else if (
    process.env.NODE_ENV !== 'development' ||
    process.env.TURN_ANALYTIC_LIBS_ON === 'true'
  ) {
    console.error('no dataLayer in window');
  }
};

export default logGaAction;
