import HeadMarket from '../HeadMarket';
import { LINE_VIEW } from '../../../../../../../constants';
import styles from './index.module.css';

const EventSubRowTable = ({
  headGroups,
  eventId,
  lineKey,
  isDisplayHeadMarkets
}) => {
  return (
    isDisplayHeadMarkets &&
    headGroups.map((headGroup) => {
      const { resultTypeId, marketTemplateId } = headGroup;

      return (
        <HeadMarket
          key={`${resultTypeId}-${marketTemplateId}`}
          eventId={eventId}
          lineKey={lineKey}
          resultTypeId={resultTypeId}
          marketTemplateId={marketTemplateId}
          lineView={LINE_VIEW.TABLE}
          dataRole="c-eventSubRowTable-headGroup"
          classNames={{
            marketClassName: styles.market
          }}
        />
      );
    })
  );
};

export default EventSubRowTable;
