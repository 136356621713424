import { Box } from '@alf/uikit';
import {
  PageContext,
  useCookieManagementEnabled,
  useMobileNavMenuSettings
} from '@namespace/cms';
import { CookieManagementContext } from '@namespace/cookie-management';
import { DeviceContext } from '@namespace/device';
import {
  MOBILE_MENU_TYPES,
  useIsMounted,
  usePrevious,
  document
} from '@namespace/helpers';
import { useLocation } from '@namespace/i18n';
import { Icon } from '@namespace/icons2';
import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Menu from './Menu';
import { ANIMATION_DELAY } from './constants';
import styles from './index.module.css';

const BurgerMenu = ({ id, isOpen, setIsOpen }) => {
  const { pathname } = useLocation();
  const { isMobile } = useContext(DeviceContext);
  const { page } = useContext(PageContext);
  const { pageConfig = {} } = page;
  const { headerColorScheme } = pageConfig;
  const headerSecondary = headerColorScheme === 'secondary';
  const prevIsOpen = usePrevious(isOpen);
  const [animationState, setAnimationState] = useState('');
  const cookieManagementEnabled = useCookieManagementEnabled();
  const [{ cookieManagementType }] = useContext(CookieManagementContext);
  const mobileNavigation = useMobileNavMenuSettings();
  const { mobileMenu = MOBILE_MENU_TYPES.BURGER } = mobileNavigation;
  const isShowBurgerMenu = mobileMenu === MOBILE_MENU_TYPES.BURGER;
  const isMounted = useIsMounted();

  useEffect(() => {
    if (cookieManagementEnabled ? cookieManagementType : true) {
      if (isOpen) {
        document.body.style.overflow = 'hidden';
      } else if (prevIsOpen) {
        document.body.style.overflow = 'unset';
      }
    }
  }, [isOpen, prevIsOpen]);

  const setOpen = useCallback(
    (openFlag) => {
      if (animationState !== '') {
        return;
      }

      if (openFlag) {
        setIsOpen(openFlag);
        setAnimationState('Start');
      } else {
        setAnimationState('Finish');
      }

      setTimeout(
        () =>
          openFlag ? setAnimationState('Finish') : setAnimationState('Start'),
        0
      );

      setTimeout(() => {
        setAnimationState('');
        if (!openFlag) {
          setIsOpen(openFlag);
        }
      }, ANIMATION_DELAY);
    },
    [animationState, setIsOpen]
  );

  const [isHandleOpen, setIsHandleOpen] = useState(isOpen);

  // A hack for onboarding. Handle is used to open burger programmatically without animation. Also, track programmatic open state and sync real open state with it to make sure burger is open from any initial state (as onboarding doesn't know what current state each trigger has).
  const onBurgerHandleClick = () => {
    setIsOpen(!isHandleOpen);
    setIsHandleOpen((o) => !o);
  };

  const bodyClass = styles[`body${animationState}`];
  const overlay = styles[`overlay${animationState}`];
  const container = styles[`container${animationState}`];

  const clearBodyClass = useCallback(() => {
    const rootEl = document.querySelector('#root');

    if (!rootEl) {
      return;
    }

    rootEl.classList.remove(
      styles.body,
      styles.bodyStart,
      styles.bodyFinish,
      styles.bodyMobile
    );
  }, []);

  const prevPathName = usePrevious(pathname);

  useEffect(() => {
    if (pathname !== prevPathName) {
      setOpen(false);
    }

    return clearBodyClass;
  }, [clearBodyClass, pathname, prevPathName, setOpen]);

  useEffect(() => {
    clearBodyClass();

    if (isOpen) {
      const rootEl = document.querySelector('#root');

      rootEl.classList.add(bodyClass);

      if (isMobile) {
        rootEl.classList.add(styles.bodyMobile);
      }
    }
  }, [isOpen, bodyClass, isMobile, clearBodyClass]);

  return (
    isShowBurgerMenu && (
      <>
        <Box
          data-role="burger-menu-icon"
          className={styles.burger}
          onClick={() => setOpen(true)}
        >
          <Icon
            size="xl"
            name="icons/nav_menu/menu"
            color={`header_accent_color_${headerSecondary ? '2' : '1'}`}
          />
        </Box>
        <span data-role="burger-menu-handle" onClick={onBurgerHandleClick} />
        <Box
          className={isOpen ? overlay : styles.hidden}
          onClick={() => setOpen(false)}
        />
        {isMounted &&
          createPortal(
            <Menu
              id={id}
              isOpen={isOpen}
              container={container}
              setOpen={setOpen}
              setIsOpen={setIsOpen}
            />,
            document.body
          )}
      </>
    )
  );
};

export default memo(BurgerMenu);
