import { SocketClient } from '../../classes/SocketClient';
import { SOCKET_URLS } from '../../constants';

let socketInstance;

export const getSocketInstance = () => {
  if (!socketInstance) {
    socketInstance = new SocketClient();
  }

  return socketInstance;
};

export const socketSend = (...args) => getSocketInstance().send(...args);

export const socketSubscribe = (...args) =>
  getSocketInstance().subscribe(...args);

export const socketUnsubscribe = (...args) =>
  getSocketInstance().unsubscribe(...args);

export const socketConnect = ({ ssid, connectionHandler }) =>
  getSocketInstance().connect({
    url: ssid ? `${SOCKET_URLS.SOCKET}?SSID=${ssid}` : SOCKET_URLS.SOCKET,
    pingDelay: process.env.REACT_APP_SOCKET_PING_DELAY,
    maxReconnectionAttempts:
      process.env.REACT_APP_MAX_SOCKET_RECONNECTION_ATTEMPTS,
    connectionHandler
  });
