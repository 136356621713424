export default {
  authUser: '/accounting/api/login',
  logout: '/accounting/api/logout',
  updatePhone: '/accounting/api/change_user',
  updateUser: '/accounting/api/update_user',
  changeUser: '/accounting/api/change_user',
  setUserNotifications: '/accounting/api/set_source_of_notifications',
  changeActiveWallet: '/accounting/api/on_off_deposit',
  checkVideo: '/accounting/api/video_check',
  getBankAccounts: '/accounting/api/get_bank_accounts',
  createBankAccount: '/accounting/api/create_bank_account',
  updateBankAccount: '/accounting/api/update_bank_account',
  deleteBankAccount: '/accounting/api/delete_bank_account',
  uploadDocuments: '/frontend_api/user/verification',
  getLoginHistory: '/user/loginhistory',
  updateAddressFields: '/accounting/api/update_billing_fields',
  getIbans: '/accounting/api/get_user_ibans_list',
  addIban: '/accounting/api/save_user_iban',
  getUserWallets: '/accounting/api/get_user_wallets',
  saveDocuments: '/accounting/api/save_document',
  confirmDocuments: '/accounting/api/jumio_get_url',
  changeUserPassword: '/accounting/api/change_password',
  verification: 'accounting/api/verification_get_available',
  getDocVerificationOptions: '/service/verification_get_available',
  getDocVerificationFormUrl: '/service/verification_get_form_url',
  getInboxMessages: '/accounting/api/in_box',
  getFreshChatUserData: '/service/cs/v1/fresh-chat/user-data/get',
  getZendeskChatUser: '/service/cs/v1/zendesk/user-data/get',
  updateFreshChatUserData: '/service/cs/v1/fresh-chat/user-data/update',
  acceptNewPolicy: '/service/pds/policy/accept'
};
