import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import InputField from '../../common/InputField';

const TokenDependedField = ({
  name,
  dependsOnName,
  targetProperty,
  userTokens,
  ...props
}) => {
  const methods = useFormContext();
  const { watch, setValue } = methods;
  const selectedToken = watch(dependsOnName);

  useEffect(() => {
    if (userTokens?.length && selectedToken) {
      const { paymentTokenObject = {} } =
        userTokens.find(({ paymentToken }) => paymentToken === selectedToken) ||
        {};
      const { [targetProperty]: prop } = paymentTokenObject;
      setValue(name, prop);
    }
  }, [userTokens, selectedToken, setValue, name, targetProperty]);

  return (
    <InputField name={name} enableInstantFieldValidation={true} {...props} />
  );
};

export default TokenDependedField;
