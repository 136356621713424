import { Notification, Box } from '@alf/uikit';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { useGeneralRoutes } from '@namespace/cms';
import styles from './index.module.css';

const SuspendedUserToast = ({ onClose }) => {
  const { t } = useI18n();
  const { wallets } = useGeneralRoutes();

  const message = (
    <Box direction="column" align="center" justify="center">
      {t('suspendedUser.notification.infoTop')}
      <br />
      <span>
        {t('suspendedUser.notification.infoBottom')}
        <LocalizedLink className={styles.link} to={wallets}>
          {t('suspendedUser.notification.link')}
        </LocalizedLink>
      </span>
    </Box>
  );

  return <Notification onClose={onClose} message={message} />;
};

export default SuspendedUserToast;
