import { useContext, useEffect, useRef } from 'react';
import { PageContext } from '@namespace/cms';
import { useColorScheme } from '@namespace/themes';
import { document } from '@namespace/helpers';

const usePageBackground = () => {
  const { page } = useContext(PageContext);
  const { pageConfig = {} } = page;
  const { backgroundColor, backgroundImage } = pageConfig;
  const { isDarkModeEnabled } = useColorScheme();

  // todo: change it
  const bodyRef = useRef(document.body);

  useEffect(() => {
    if (bodyRef.current && (backgroundColor || backgroundImage)) {
      bodyRef.current.style.cssText = `
        ${backgroundImage ? `background-image: url(${backgroundImage});` : ''}
        ${backgroundColor ? `background-color: ${backgroundColor};` : ''}
      `;
    } else if (bodyRef.current) {
      document.body.style.removeProperty('background');
    }
  }, [backgroundColor, backgroundImage, bodyRef, isDarkModeEnabled]);
};

export default usePageBackground;
