import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { AText, Box } from '@alf/uikit';
import { Icon } from '@namespace/icons2';

import styles from './index.module.css';

export const LiveRtpInfo = ({ liveRtpInfo = {} }) => {
  if (isEmpty(liveRtpInfo)) return null;

  const { rtp, arrowUp } = liveRtpInfo;

  return (
    <Box className={styles.rtpInfoWrapper}>
      <Box
        className={clsx([
          styles.rtpInfo,
          arrowUp ? styles.rtpInfoHot : styles.rtpInfoCold
        ])}
        align="center"
      >
        <AText breed="BU" size="xs" color={arrowUp ? 'tag_txt_4' : 'tag_txt_3'}>
          RTP:
          {` ${rtp}%`}
        </AText>
        <Box left="xxs">
          <Icon
            name={arrowUp ? 'icons/general/nav/up' : 'icons/general/nav/down'}
            color={arrowUp ? 'tag_txt_4' : 'tag_txt_3'}
            size="xs"
          />
        </Box>
      </Box>
    </Box>
  );
};
