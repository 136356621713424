import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { sortBy, isEmpty } from 'lodash';
import {
  COOKIES_KEYS,
  getLocalStorageValue,
  LOCAL_STORAGE_KEYS,
  COOKIE_MANAGEMENT_TYPES,
  setCookie,
  window
} from '@namespace/helpers';
import { socketSend } from '@namespace/socket';
import { i18nTypes } from './reducer';
import {
  getTranslations,
  setHtmlLang,
  setTimezone,
  convertTranslationsToLowerCase
} from './utils';
import {
  getStoredTranslations,
  setStoredTranslations
} from './utils/storedLanguageTranslation';
import { getTimeZones } from './api/timeZones';
import defaultTranslation from './en.json';

export default {
  [i18nTypes.SET_LANG_TRANSLATIONS]: (dispatch) => (
    lang,
    cookieManagementEnabled
  ) => {
    const cookieManagementType = getLocalStorageValue(
      LOCAL_STORAGE_KEYS.COOKIE_MANAGEMENT_TYPE,
      ''
    );

    const IS_FUNCTIONAL = cookieManagementType?.includes(
      COOKIE_MANAGEMENT_TYPES.FUNCTIONAL
    );

    if (!cookieManagementEnabled || IS_FUNCTIONAL) {
      setCookie(COOKIES_KEYS.LANGUAGE, lang, { expires: 365 });
    }
    const defaultFallbackLangEn =
      window.defaultTranslation || defaultTranslation || {};
    const stored = getStoredTranslations(lang) || {};
    const fallback = { ...defaultFallbackLangEn, ...stored };

    if (!window.IS_SSR) {
      dispatch({
        type: i18nTypes.SET_LANG_TRANSLATIONS,
        payload: {
          translations: convertTranslationsToLowerCase(fallback),
          lang
        }
      });
    }
    let loadedTranslations = window.translations?.[lang];
    let promises = [
      setHtmlLang(lang),
      new Promise((resolve, reject) => {
        socketSend({
          cmd: 'accounting/set_language',
          cid: 'accounting/set_language',
          data: {
            language: lang
          }
        })
          .then(resolve)
          .catch(reject);
      })
    ];
    if (isEmpty(loadedTranslations)) {
      promises = [getTranslations(lang), ...promises];
    }

    return Promise.all(promises)
      .then(([translations]) => {
        loadedTranslations = loadedTranslations || translations;
        dispatch({
          type: i18nTypes.SET_LANG_TRANSLATIONS,
          payload: {
            translations: convertTranslationsToLowerCase({
              ...fallback,
              ...loadedTranslations
            }),
            lang
          }
        });
        setStoredTranslations(
          lang,
          convertTranslationsToLowerCase(loadedTranslations)
        );
      })
      .catch((error) => {
        console.error(LOG_ACTIONS.TRANSLATIONS);
        logAction(LOG_ACTIONS.TRANSLATIONS, { error });
      });
  },
  [i18nTypes.CHANGE_TIMEZONE]: (dispatch) => async (timezone) => {
    try {
      await setTimezone(timezone);
      dispatch({
        type: i18nTypes.SET_TIMEZONE,
        payload: {
          timezone
        }
      });
    } catch (e) {
      console.warn(e);
    }
  },
  [i18nTypes.FETCH_TIMEZONES]: (dispatch) => async () => {
    const timeZones = await getTimeZones();
    dispatch({
      type: i18nTypes.SET_TIMEZONES,
      payload: { timeZones: sortBy(timeZones, 'timezoneDiff') }
    });
  }
};
