import { LocalizedLink, useI18n } from '@namespace/i18n';
import { AText } from '@alf/uikit';
import clsx from 'clsx';
import getOldVersionUrl from '../../../utils/getOldVersionUrl';
import styles from './components/NavMenuItem/index.module.css';

const NavigationOldVersion = () => {
  const { t } = useI18n();

  return (
    <LocalizedLink
      to={getOldVersionUrl()}
      className={clsx([styles.link, styles.linkContainer])}
      rel="noindex, nofollow"
    >
      <AText breed="BU" size="l" color="txt_color_4" className={styles.title}>
        {t('header.navigation.oldVersion')}
      </AText>
    </LocalizedLink>
  );
};

export default NavigationOldVersion;
