import { useCallback, useContext } from 'react';
import { AText, Box } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { useI18n } from '@namespace/i18n';
import { LOCAL_STORAGE_KEYS } from '@namespace/helpers';
import { UserContext } from '@namespace/user/src/context';
import { LINE_VIEW_TYPES } from '../../constants';
import styles from './index.module.css';

const LineView = ({ iconOptions = {} }) => {
  const [state = {}, userActions] = useContext(UserContext);
  const { settings = {} } = state;
  const { lineViewType } = settings;
  const { CHANGE_LINE_VIEW } = userActions;
  const { t } = useI18n();
  const lineView = [
    {
      name: t('web.settings.lineview.table'),
      icon: 'icons/general/state/line-tables',
      value: LINE_VIEW_TYPES.TABLE
    },
    {
      name: t('web.settings.lineview.button'),
      icon: 'icons/general/state/line-buttons',
      value: LINE_VIEW_TYPES.BUTTON
    }
  ];
  const handleChangeLineView = useCallback((value) => {
    CHANGE_LINE_VIEW(LOCAL_STORAGE_KEYS.LINE_VIEW, value);
  }, []);

  return (
    <Box direction="row">
      {lineView.map(({ name, icon, value }) => (
        <Box
          key={value}
          className={`${lineViewType === value ? '' : styles.isNotActive} ${
            styles.cursor
          } ${styles.margin}`}
          onClick={() => handleChangeLineView(value)}
          data-role={`line-view-${name.toLowerCase()}`}
        >
          <Box right="xs" align="center" justify="center">
            <Icon name={icon} color="txt_color_2" {...iconOptions} />
          </Box>
          <AText
            breed="R"
            size="s"
            color="txt_color_2"
            className={styles.iconsName}
          >
            {name}
          </AText>
        </Box>
      ))}
    </Box>
  );
};

export default LineView;
