import { useMemo } from 'react';
import { useGeneralRoutes } from '@namespace/cms';

// TODO:instant games route ?
const useGetNativeAppRouteMap = () => {
  const {
    liveEvent,
    liveSport,
    liveSportAll,
    preMatchSport,
    preMatchEvent,
    preMatchCategory,
    preMatchTournament,
    virtualSportsOneSport,
    games,
    gamePreview,
    casinoLive,
    promos,
    promo,
    register,
    login
  } = useGeneralRoutes();

  return useMemo(
    () => ({
      [`${liveEvent}/:sportName/:eventId`]: 'live/open/event?id=:eventId',
      [`${liveSport}/:sportName`]: 'live/open/sport?id=:sportName',
      [`${liveSportAll}`]: 'live/open',
      [`${preMatchSport}/:sportName`]: 'prematch/open/categories?sport_id=:sportName',
      [`${preMatchEvent}/:sportName/:eventId`]: 'prematch/open/event?id=:eventId',
      [`${preMatchCategory}/:sportName/:categoryId`]: 'prematch/open/tournaments?category_id=:categoryId&sport_id=:sportName',
      [`${preMatchTournament}/:sportName/:tournamentId`]: 'prematch/open/tournament/events/?sport_id=:sportName&tournament_id=:tournamentId',
      [`${games}`]: 'casino/open',
      [`${games}/:provider/:category`]: 'casino/open/provider?service=:provider',
      [`${games}/:category`]: 'casino/open/category?category=:category',
      [`${games}${gamePreview}/:service/:game`]: 'casino/open/game?game=:game&service=:service',
      [`${casinoLive}`]: 'live-casino/open',
      [`${casinoLive}/:provider/:category`]: 'live-casino/open/provider?service=:provider',
      [`${casinoLive}/:category`]: 'live-casino/open/category?category=:category',
      [`${casinoLive}${gamePreview}/:service/:game`]: 'live-casino/open/game?game=:game&service=:service',
      [`${promos}`]: 'promo/open',
      [`${promo}/:promo`]: 'promo/details?promo=:promo',
      '/tv-bet': 'tvbet/open',
      '/bet-games': 'betgames/open',
      '/lottery': 'loto/open',
      '/keno': 'keno/open',
      '/aviator': 'aviator/open',
      [`${register}`]: 'registration/open',
      [`${login}`]: 'quick-login/open',
      '/personal-office/balance': 'balance/open',
      '/personal-office/quick-deposit': 'quick-deposit/open',
      [`${virtualSportsOneSport}/:provider/:id/:categoryId/:gameId/`]: ':provider/open/game?game=:gameId'
    }),
    [
      liveEvent,
      liveSport,
      liveSportAll,
      preMatchSport,
      preMatchEvent,
      preMatchCategory,
      preMatchTournament,
      virtualSportsOneSport,
      games,
      gamePreview,
      casinoLive,
      promos,
      promo,
      register,
      login
    ]
  );
};

export default useGetNativeAppRouteMap;
