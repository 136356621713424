import { ContentRow } from '../ContentRow';

export const ContentRows = ({
  options,
  value,
  onRowSelect,
  size,
  intentType,
  className,
  dataRole
}) =>
  options.map((option = {}) => {
    const { value: val, title, iconElement, disabled, hidden } = option;

    if (hidden) {
      return null;
    }

    const isActive = val != null && value.includes(val);

    return (
      <ContentRow
        key={`${title}-${val}`}
        value={val}
        title={title}
        iconElement={iconElement}
        isActive={isActive}
        disabled={disabled}
        onChange={onRowSelect}
        size={size}
        intentType={intentType}
        className={className}
        dataRole={dataRole}
      />
    );
  });
