import useListener from '../useListener';

const useEnter = (callback = () => {}) => {
  useListener('keypress', (e) => {
    if (e.key === 'Enter') {
      callback(e);
    }
  });
};

export default useEnter;
