import { rePatterns } from '../../patterns';

const cpfNumberValidator = (message = 'balance.deposit.form.cpfn.error') => ({
  pattern: {
    value: rePatterns.cpfNumber,
    message
  }
});

export default cpfNumberValidator;
