import { filter } from 'ramda';

export const getFavoritesList = (
  favorites = [],
  gameCodes = [],
  isFavorite = true
) =>
  isFavorite
    ? [...gameCodes, ...favorites]
    : filter((code) => !gameCodes.includes(code), favorites);
