import { camelizeKeys, decamelizeKeys } from 'humps';
import { bettorrentPost } from '@namespace/helpers';

export const getLineElementNames = async ({
  lineElement = [],
  lang,
  serviceId
}) =>
  bettorrentPost({
    url: '/service/bettorrent/custom_filter/line_element_name/get',
    mode: 'same-origin',
    body: decamelizeKeys({
      lineElement: lineElement.map((el) => decamelizeKeys(el)),
      lang,
      serviceId
    })
  }).then(({ response = [] }) => response.map((item) => camelizeKeys(item)));
