import { memo } from 'react';

const MainTimer = ({ timer }) => timer || '';
const AdditionalTimer = ({ timer }) => {
  const additionalTimer = timer === null ? '' : ` (+${timer})`;

  return timer ? additionalTimer : '';
};

const TimerCounter = ({ timer2, eventTimerCounter, eventTimerCounter2 }) => {
  if (timer2) {
    return <AdditionalTimer timer={eventTimerCounter2} />;
  }
  return <MainTimer timer={eventTimerCounter} />;
};

export default memo(TimerCounter);
