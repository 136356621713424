import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useOtherSettings } from '@namespace/cms';
import {
  useGamesState,
  useGetIsWagerSlotGamesLoaded,
  useCategory,
  useGetWagerSlotsGamesIds
} from './selectors';
import { SECTION_TYPES } from '../../constants';

export const useWagerSlotsGamesCategory = (
  feedName,
  showWagerSlotsCategory
) => {
  const { games } = useGamesState();
  const { casinoDynamicCategoriesIds = {} } = useOtherSettings();
  const isWagerSlotGamesLoaded = useGetIsWagerSlotGamesLoaded();
  const feedWagerCategory = useCategory(
    casinoDynamicCategoriesIds?.wagerSlots,
    feedName
  );

  const wagerSlotGamesIds = useGetWagerSlotsGamesIds(feedName);

  const actualWagerSlotGames = useMemo(
    () => wagerSlotGamesIds.map((gameId) => games.data[gameId]),
    [games.data, wagerSlotGamesIds]
  );

  if (
    !casinoDynamicCategoriesIds?.wagerSlots ||
    !showWagerSlotsCategory ||
    isEmpty(feedWagerCategory) ||
    (isWagerSlotGamesLoaded && isEmpty(actualWagerSlotGames))
  )
    return null;

  return {
    ...feedWagerCategory,
    type: SECTION_TYPES.CATEGORY_GENERAL,
    categoryGames: actualWagerSlotGames,
    games: wagerSlotGamesIds
  };
};
