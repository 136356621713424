import { useContext } from 'react';
import { JackpotsContext } from '../context';

export const useGetInHouseJackpotWinnersGamesLaunchIds = () => {
  const [jackpotsState] = useContext(JackpotsContext);
  const { jackpots } = jackpotsState;

  const winnersGamesLaunchIds = [];

  for (const jackpot of Object.values(jackpots)) {
    for (const winner of [jackpot.lastWinner, jackpot.maxWinner]) {
      if (winner?.gameId) winnersGamesLaunchIds.push(winner.gameId);
    }
  }

  return winnersGamesLaunchIds;
};

export const useGetInHouseJackpotWinnerInfo = (launchGameId) => {
  const [{ games }] = useContext(JackpotsContext);

  return games[launchGameId] || {};
};

export const useGetJackpotWin = () => {
  const [jackpotsState] = useContext(JackpotsContext);

  return jackpotsState.jackpotWin;
};

export const useGetJackpotCurrency = () => {
  const [jackpotsState] = useContext(JackpotsContext);

  return Object.values(jackpotsState.jackpots || {})?.[0]?.convertedCurrency;
};
