import { useContext } from 'react';
import { AText, Box } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { useThemeComponents } from '@namespace/themes';
import { UserContext } from '@namespace/user';

import { useGetPragmaticData } from '../../../hooks/useGetPragmaticData';
import { splitNumber } from './utils';

import styles from './index.module.css';

export const PragmaticLiveInfo = ({ pragmaticLiveData = {} }) => {
  const {
    availableSeats = null,
    minBet = null,
    maxBet = null
  } = pragmaticLiveData;
  const [user] = useContext(UserContext);
  const { activeWallet = {} } = user;
  const { currency } = activeWallet;
  const { defaultCurrency } = useGetPragmaticData();
  const { PragmaticLiveInfo: PartnerPragmaticLiveInfo } = useThemeComponents();

  if (!availableSeats && !minBet && !maxBet) return null;

  if (PartnerPragmaticLiveInfo) {
    return (
      <PartnerPragmaticLiveInfo
        currency={currency}
        defaultCurrency={defaultCurrency}
        pragmaticLiveData={pragmaticLiveData}
        splitNumber={splitNumber}
      />
    );
  }

  return (
    <Box className={styles.liveData} justify="between">
      {(minBet || maxBet) && (
        <Box className={styles.liveDataItem}>
          <Box className={styles.liveDataIconWrapper}>
            <Icon
              size="s"
              name="icons/general/state/stake-sum1"
              color="txt_color_4"
            />
          </Box>
          <AText
            color="txt_color_4"
            breed="R"
            className={styles.liveDataItemText}
          >
            {`${
              minBet && maxBet
                ? `${splitNumber(minBet)} - ${splitNumber(maxBet)}`
                : splitNumber(minBet) || splitNumber(maxBet)
            } ${currency || defaultCurrency}`}
          </AText>
        </Box>
      )}
      {Boolean(availableSeats) && (
        <Box className={styles.liveDataItem}>
          <Box className={styles.liveDataIconWrapper}>
            <Icon
              size="s"
              color="txt_color_4"
              name="icons/general/state/seats4"
            />
          </Box>
          <AText
            color="txt_color_4"
            breed="R"
            className={styles.liveDataItemText}
          >
            {availableSeats}
          </AText>
        </Box>
      )}
    </Box>
  );
};
