import { useCallback } from 'react';
import { path } from 'ramda';
import { useI18n } from '@namespace/i18n';
import { useCacheLoad } from '@namespace/helpers';
import { sportsbookActions, useSportsbookStore } from '../../store';
import { useGetPathParams } from '../../hooks/useGetPathParams';
import useSportsSettings from '../../hooks/useSportsSettings';
import {
  useFetchFilter,
  useResetAdvFilterCache
} from '../../components/AdvancedFilter';

const { FETCH_CATEGORIES_AND_TOURNAMENTS } = sportsbookActions;

const SportsBook = ({ serviceId }) => {
  const { language } = useI18n();
  const categoriesBySportId = useSportsbookStore('categoriesBySportId');
  const { activeSport } = useGetPathParams(serviceId);
  const { sportId } = activeSport || {};
  const dataCachingTime = useSportsbookStore(
    path(['categoriesAndTournamentsDataState', sportId, 'dataCachingTime'])
  );
  const { timeRangeForAllPeriodByEvents } = useSportsSettings();

  const fetchCategoriesAndTournaments = useCallback(
    () =>
      FETCH_CATEGORIES_AND_TOURNAMENTS({
        lang: language,
        sportId,
        categoriesBySportId,
        timeRange: timeRangeForAllPeriodByEvents
      }),
    [language, sportId, categoriesBySportId, timeRangeForAllPeriodByEvents]
  );

  useCacheLoad({
    action: fetchCategoriesAndTournaments,
    dataMustBeLoaded: sportId && `${sportId} ${language}`,
    dataCachingTime
  });

  useFetchFilter();
  useResetAdvFilterCache();

  return null;
};

export default SportsBook;
