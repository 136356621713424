import { memo, useCallback } from 'react';
import { scrollTo } from '@namespace/helpers';
import { useGeneralRoutes } from '@namespace/cms';
import { LocalizedNavLink } from '@namespace/i18n';
import { Icon, SportIcon } from '@namespace/icons2';
import { AText, Box } from '@alf/uikit';
import PinnedIcon from '../PinnedIcon';
import { SPORT_IDS } from '../../constants';
import styles from './index.module.css';

const SportItem = ({
  sport = {},
  basePath,
  toggleMenu,
  isActive = false,
  isExpanded = false,
  withCounter,
  withExpandIcon,
  showPinIcon
}) => {
  const { isPinned = false, sportId, sportSlug, sportName, eventCount } = sport;
  const { cyberSport: cyberSportAllLink } = useGeneralRoutes();
  const path =
    sportId !== SPORT_IDS.CYBERSPORT
      ? `${basePath}/${sportSlug}`
      : `${cyberSportAllLink}/`;
  const handleClick = useCallback(() => {
    toggleMenu();
    if (!isActive) {
      scrollTo();
    }
  }, [isActive, toggleMenu]);

  return (
    <LocalizedNavLink
      to={path}
      className={`${styles.item} ${isExpanded ? styles.expanded : ''}`}
      activeClassName={styles.active}
      isActive={() => isActive}
      onClick={handleClick}
      dataRole={`sport-id-${sportId}`}
    >
      <div className={styles.iconWrap}>
        <SportIcon
          data-role={`sport-item-icon-${sportId}`}
          sportId={sportId}
          size="s"
        />
      </div>
      <Box flexGrow={1} className={styles.nameWrap}>
        <AText breed="BU" size="m" overflow="ellipsis">
          {sportName}
        </AText>
      </Box>
      {showPinIcon && (
        <PinnedIcon
          isPinned={isPinned}
          sportId={sportId}
          isActive={isActive}
          type="sport"
        />
      )}
      {withExpandIcon && (
        <Icon
          data-role={`sport-item-${isExpanded ? 'collapse' : 'expand'}-icon`}
          name={`icons/general/nav/${isExpanded ? 'collapse' : 'expand'}`}
          color="sm_txt_color_2"
          size="s"
          className={styles.expandIcon}
        />
      )}
      {withCounter && (
        <Box
          data-role="sport-item-events-counter"
          align="center"
          justify="center"
          flexShrink={0}
          className={styles.count}
        >
          <AText breed="R" size="xs" color="sm_txt_color_1">
            {eventCount}
          </AText>
        </Box>
      )}
    </LocalizedNavLink>
  );
};

export default memo(SportItem);
