import { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Box from '../Box';
import DefaultListComponent from './components/DefaultListComponent';

import styles from './index.module.css';

const Tabs = forwardRef(
  (
    {
      list = [],
      activeTabIndex = 0,
      initialTabIndex = 0,
      isDesktop = false,
      classNames = {},
      ListComponent = DefaultListComponent,
      LocalizedNavLink = (data) => <Link {...data} />,
      pathname = '/'
    },
    ref
  ) => {
    const filteredList = list.filter(({ disabled }) => !disabled);
    const [tabIndex, setTabIndex] = useState(initialTabIndex);
    const { component } = filteredList[tabIndex] || {};

    const {
      tabsClassName = '',
      tabClassName = '',
      componentClassName = '',
      counterClassName = '',
      tabActiveClassName = ''
    } = classNames;

    useEffect(() => {
      if (activeTabIndex !== null && tabIndex !== activeTabIndex) {
        setTabIndex(activeTabIndex);
      }
    }, [activeTabIndex]);

    const handleClick = (index) => {
      const { onActive = () => {} } = filteredList[index];
      onActive(index);
      setTabIndex(index);
    };

    return (
      <>
        <Box
          align="end"
          flexShrink={0}
          className={`${styles.tabs} ${tabsClassName}`}
        >
          <ListComponent
            counterClassName={counterClassName}
            handleClick={handleClick}
            list={filteredList}
            tabClassName={tabClassName}
            tabActiveClassName={tabActiveClassName}
            tabIndex={tabIndex}
            isDesktop={isDesktop}
            LocalizedNavLink={LocalizedNavLink}
            pathname={pathname}
          />
        </Box>
        {component && (
          <div className={componentClassName} ref={ref}>
            {component}
          </div>
        )}
      </>
    );
  }
);

Tabs.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.element,
      counter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      onActive: PropTypes.func
    })
  ),
  activeTabIndex: PropTypes.number,
  initialTabIndex: PropTypes.number,
  classNames: PropTypes.shape({
    tabsClassName: PropTypes.string,
    componentClassName: PropTypes.string,
    counterClassName: PropTypes.string,
    tabClassName: PropTypes.string,
    tabActiveClassName: PropTypes.string
  }),
  isDesktop: PropTypes.bool,
  ListComponent: PropTypes.func,
  LocalizedNavLink: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.node
  ]),
  pathname: PropTypes.string
};

export default Tabs;
