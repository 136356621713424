import { useCallback } from 'react';
import { useGeneralRoutes } from '@namespace/cms';
import { useNotifications } from '@namespace/notifications';
import { Box } from '@alf/uikit';
import { useLocation, useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { usePrewagerBonusWalletWithdraw } from '../../hooks/usePrewagerBonusWalletWithdraw';
import Confirmation from './Confirmation';
import Pending from './Pending';
import { PREWAGER_CANCELATION_IN_PROGRESS } from './contants';
import styles from './index.module.css';

const WithdrawBonusConfirmation = () => {
  const { t } = useI18n();
  const { withdraw } = useGeneralRoutes();
  const { successNotification, errorNotification } = useNotifications();
  const { isCancelFetching, cancelPrewager } = usePrewagerBonusWalletWithdraw();
  const { push } = useHistory();
  const { state = {} } = useLocation();
  const prewagerStatus = state?.prewagerStatus;
  const onCancel = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        await cancelPrewager();
        successNotification(
          t('withdrawBonusConfirmation.successfullyCanceled')
        );
        push(withdraw);
      } catch {
        errorNotification(t('withdrawBonusConfirmation.cancelError'));
      }
    },
    [cancelPrewager]
  );

  return (
    <Box align="center" justify="center" wrap={true}>
      <Box direction="column" align="center" className={styles.wrapper}>
        {prewagerStatus === PREWAGER_CANCELATION_IN_PROGRESS ? (
          <Pending />
        ) : (
          <Confirmation
            onCancel={onCancel}
            isCancelFetching={isCancelFetching}
          />
        )}
      </Box>
    </Box>
  );
};

export default WithdrawBonusConfirmation;
