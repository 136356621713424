import { rePatterns } from '../../patterns';

const amountValidator = (isOnlyDigits = true) => ({
  validate: (value = '') => {
    const pattern = isOnlyDigits
      ? rePatterns.onlyNumbers
      : rePatterns.numbersWithComma;
    const message = isOnlyDigits
      ? 'web.payment.paymentAmount.onlyDigitsError'
      : 'web.payment.paymentAmount.error';
    if (!value.toString().match(pattern)) {
      return message;
    }
    return true;
  }
});

export default amountValidator;
