import { pathOr } from 'ramda';
import { shallow } from 'zustand/shallow';
import { createSelector } from 'reselect';
import { MODE, SPORT_IDS } from '../../../constants';
import { useSportsbookSelector } from '../../store';

export const selectCyberSportCategoriesIds = () =>
  createSelector(
    pathOr({}, ['categoriesIdsBySportId', SPORT_IDS.CYBERSPORT]),
    (cyberSportCategoriesIds) => {
      const preMatchIds = cyberSportCategoriesIds[MODE.PRE_MATCH] || [];
      const liveIds = cyberSportCategoriesIds[MODE.IN_PLAY] || [];

      return [...new Set([...preMatchIds, ...liveIds])];
    }
  );

export const useCyberSportCategoriesIds = () =>
  useSportsbookSelector(selectCyberSportCategoriesIds, [], shallow);
