import { Box } from '@alf/uikit';
import { useIsParticipantServing } from '../../selectors/isParticipantServing';
import styles from './index.module.css';

const Serving = ({ eventId, participantIndex }) => {
  const isParticipantServing = useIsParticipantServing({
    eventId,
    participantIndex
  });

  if (isParticipantServing) {
    return <Box className={styles.serving} />;
  }
  return null;
};

export default Serving;
