import { AText } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';

const Pending = () => {
  const { t } = useI18n();
  return (
    <AText color="po_txt_color_1" size="m" breed="B" align="center">
      {t('withdrawBonusConfirmation.textPending')}
    </AText>
  );
};

export default Pending;
