import { AText, Box } from '@alf/uikit';
import { Image } from '@namespace/icons2';
import {
  FORM_VALIDATION_TYPES,
  formBuilderValidationMap,
  InputField,
  KeyboardDatePickerField
} from '@namespace/forms';
import { createValidationRules } from '@alf/validators';
import { CARD_INFO } from '../../../../constants';
import styles from '../index.module.css';

const CardFrontPreViewEdit = ({
  activeCard,
  minDate,
  maxDate,
  onBlur,
  customFieldName
}) => {
  const { cardMask } = activeCard || {};

  return (
    <>
      <Box align="center" justify="between" margin="0 0 m">
        <AText breed="BU" size="l" color="txt_color_1">
          {cardMask?.replace(/(.{4})/g, '$1 ')}
        </AText>
        <Box className={styles.cardIcons} justify="between">
          <Image name="payments/visa_mastercard_goriz" width="54px" alt="" />
        </Box>
      </Box>
      <KeyboardDatePickerField
        label="panKeeper.card.label.validUntil"
        onBlur={onBlur}
        customFieldName={customFieldName}
        labelSize="s"
        minDate={minDate}
        maxDate={maxDate}
        dateFormat="MM/yy"
        dateValueFormat="MM/yyyy"
        size="xs"
        placeholder="MM / YY"
        classNames={{
          datePickerWrapperClassName: styles.inputDateWrapper,
          labelClassName: styles.label,
          datePickerContainerClassName: styles.datePickerContainer
        }}
        withTooltip={true}
        name={CARD_INFO.EXPIRY_DATE}
        rules={createValidationRules(
          [FORM_VALIDATION_TYPES.REQUIRED],
          formBuilderValidationMap
        )}
      />
      <InputField
        classNames={{
          labelClassName: styles.label,
          wrapperClassName: styles.inputWrapperCardholder
        }}
        label="panKeeper.card.label.cardholder"
        placeholder="panKeeper.card.placeholder.cardholder"
        value=""
        withTooltip={true}
        size="xs"
        name={CARD_INFO.CARD_HOLDER}
        isOnChangeValidation={true}
        rules={createValidationRules(
          [
            {
              type: FORM_VALIDATION_TYPES.REGEXP_VALIDATOR,
              parameters: [
                /^[a-zA-Z\s'`\-]+$/,
                'panKeeper.card.regexp.validator.cardholder'
              ]
            },
            FORM_VALIDATION_TYPES.REQUIRED,
            {
              type: FORM_VALIDATION_TYPES.MAX_LENGTH_VALIDATOR,
              parameters: [32]
            }
          ],
          formBuilderValidationMap
        )}
      />
    </>
  );
};

export default CardFrontPreViewEdit;
