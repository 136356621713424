import { useOtherSettings } from '@namespace/cms';
import { getRootPath } from '@namespace/helpers';
import { useLocation } from '@namespace/i18n';

const useGetPaths = () => {
  const { pathname } = useLocation();
  const rootPath = getRootPath(pathname);
  const { pathsAliases = {} } = useOtherSettings();

  const finalPathname = pathsAliases[rootPath] || pathname;
  const finalRootPath = pathsAliases[rootPath] || rootPath;

  return [finalPathname, finalRootPath];
};

export default useGetPaths;
