import { useI18n } from '@namespace/i18n';
import { Box, AText } from '@alf/uikit';
import { DOCUMENT_STATUSES } from '../../constants';
import styles from './index.module.css';

const StatusBar = ({ document = {}, className = '', status: statusProp }) => {
  const { t } = useI18n();

  const status =
    statusProp || (document.status ?? DOCUMENT_STATUSES.NOTENTERED);

  const loweredCaseStatus = status.toLowerCase();
  const isVerified = status === DOCUMENT_STATUSES.VERIFIED;
  const labelTranslateKey = isVerified
    ? 'personalOffice.documents.documentsStatus'
    : 'personalOffice.documents.accountStatus';

  return (
    <Box className={`${styles.statusBar} ${className}`} direction="column">
      <Box>
        <AText size="m" color="po_txt_color_3">
          {` ${t(labelTranslateKey)}:`}
        </AText>
        <AText
          size="m"
          breed="B"
          className={`${styles.statusType} ${styles[loweredCaseStatus]}`}
          data-role="documents-status"
        >
          {t(`profileDocuments.status.${loweredCaseStatus}`)}
        </AText>
      </Box>
    </Box>
  );
};

export default StatusBar;
