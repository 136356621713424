import { useFormContext } from 'react-hook-form';
import Tax from '../../components/Tax';

const TaxRate = ({ taxRate }) => {
  const { watch } = useFormContext();
  const amountWatch = watch('amount') || 0;

  return taxRate ? (
    <Tax amount={parseFloat(amountWatch)} rate={taxRate} />
  ) : null;
};

export default TaxRate;
