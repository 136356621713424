import { useContext } from 'react';
import { PageContext } from '@namespace/cms';
import { isEmpty } from 'lodash';

const useIsComponentOnPage = (component = '') => {
  const context = useContext(PageContext);

  if (context) {
    const { page } = context;
    const { components = {} } = page;

    return !isEmpty(
      Object.values(components).filter((item) => item?.type === component)
    );
  }
  return false;
};

export default useIsComponentOnPage;
