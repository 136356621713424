import { NOTIFICATION_TYPES } from '@namespace/notifications/src/constants';

const getNotificationType = ({ accountStatuses, user = {} }) => {
  const { userOptions: { uploadAdditionalInfoDocTypes = [] } = {} } = user;

  if (accountStatuses.isPassportVerifiedByRisk) {
    return NOTIFICATION_TYPES.VERIFIED_USER;
  }

  if (accountStatuses.isAdditionalInformationRequired) {
    return uploadAdditionalInfoDocTypes.length
      ? NOTIFICATION_TYPES.ADDITIONAL_INFORMATION_UPLOAD_VERIFICATION
      : NOTIFICATION_TYPES.ADDITIONAL_INFORMATION_OTHER_VERIFICATION;
  }

  if (accountStatuses.isPassportInVerification) {
    return NOTIFICATION_TYPES.PASSPORT_VERIFICATION;
  }

  if (accountStatuses.isNotVerified) {
    return NOTIFICATION_TYPES.NOT_VERIFIED_USER;
  }

  return null;
};

export default getNotificationType;
