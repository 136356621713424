export const IS_WIDGET = process.env.REACT_APP_WIDGET;

export const POST_MESSAGE_TYPES = {
  LOGIN: 'LOGIN',
  SSID: 'SSID',
  REGISTER: 'REGISTER',
  CONTENT_HEIGHT: 'CONTENT_HEIGHT',
  ERROR: 'ERROR',
  MOUNT: 'MOUNT',
  URL_CHANGED: 'URL_CHANGED',
  SHOW_BETSLIP_INFO_BOX: 'SHOW_BETSLIP_INFO_BOX',
  HIDE_BETSLIP_INFO_BOX: 'HIDE_BETSLIP_INFO_BOX',
  PATHNAME_CHANGED: 'PATHNAME_CHANGED',
  DISABLE_SCROLL: 'DISABLE_SCROLL',
  ENABLE_SCROLL: 'ENABLE_SCROLL',
  UPDATE_DISTANCE: 'UPDATE_DISTANCE',
  CLOSE_BETSLIP: 'CLOSE_BETSLIP',
  WIDGET_BETS_COUNT_CHANGED: 'WIDGET_BETS_COUNT_CHANGED',
  ENABLE_BETSLIP_VISIBLE: 'ENABLE_BETSLIP_VISIBLE',
  DISABLE_BETSLIP_VISIBLE: 'DISABLE_BETSLIP_VISIBLE',
  BURGER_MENU_OPEN: 'BURGER_MENU_OPEN',
  BURGER_MENU_CLOSE: 'BURGER_MENU_CLOSE',
  SESSION_EXPIRED: 'SESSION_EXPIRED'
};

export const CLIENT_TYPES = {
  WIDGET: 'WIDGET',
  PARENT_WIDGET: 'PARENT_WIDGET',
  BET_HISTORY_WIDGET: 'BET_HISTORY_WIDGET'
};

export const LISTENER_EVENTS = {
  SET_LANG: 'SET_LANG',
  BETSLIP_WIDGET_CONFIG: 'BETSLIP_WIDGET_CONFIG',
  HIDE_BETSLIP: 'HIDE_BETSLIP',
  CLICK_ON_BETSLIP_INFO_BOX: 'CLICK_ON_BETSLIP_INFO_BOX'
};
