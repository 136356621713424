import { PanKeeperForm } from '@namespace/pankeeper';
import { Tax } from '../../../common/Tax';
import { City } from '../../../common/CashDeskFields/City';
import { CashDesk } from '../../../common/CashDeskFields/CashDesk';
import { PAYMENT_SYSTEMS_FORM_COMPONENTS_TYPES } from '../../../constants/formComponents';

export const PAYMENT_SYSTEMS_COMPONENTS_MAP = {
  CITY_FIELD: 'city-field',
  CASH_DESK: 'cash-desk',
  TAX: 'tax',
  PAN_KEEPER_FORM: 'pan-keeper'
};

export const paymentSystemsComponentsMap = {
  [PAYMENT_SYSTEMS_COMPONENTS_MAP.CITY_FIELD]: City,
  [PAYMENT_SYSTEMS_COMPONENTS_MAP.CASH_DESK]: CashDesk,
  [PAYMENT_SYSTEMS_COMPONENTS_MAP.TAX]: Tax,
  [PAYMENT_SYSTEMS_FORM_COMPONENTS_TYPES.PAN_KEEPER_FORM]: PanKeeperForm
};

export const paymentSystemsMethodsType = {
  EMAIL_PS: 'email',
  PHONE_PS: 'phone',
  BANK_CARD_PS: 'bank',
  SAFE_CHARGE_PS: 'safecharge',
  ECO_PAYZ: 'ecopayz',
  ADV_CASH: 'advcash',
  PIX: 'pix'
};
