import getRandomIndex from '../getRandomIndex';
import haveOptionInNewABTests from '../haveOptionInNewABTests';

const getABTests = (abTestsData, prevABTestsMap) => {
  const newTests = {};
  const abTestsMap = abTestsData.reduce((acc, testVariant) => {
    const haveOptions = haveOptionInNewABTests(
      prevABTestsMap[testVariant.name],
      testVariant.options
    );

    if (prevABTestsMap[testVariant.name] && haveOptions) {
      return {
        ...acc,
        [testVariant.name]: prevABTestsMap[testVariant.name]
      };
    }

    const optionsPercent = testVariant.options.reduce(
      (accumulator, option) => [...accumulator, option.percent],
      []
    );

    const optionPercentIndex = getRandomIndex(optionsPercent);

    newTests[testVariant.name] = testVariant.options[optionPercentIndex].name;

    return {
      ...acc,
      [testVariant.name]: testVariant.options[optionPercentIndex].name
    };
  }, {});

  return { newTests, abTestsMap };
};

export default getABTests;
