import { Route, Routes } from 'react-router-dom';
import { useGetActualPrefixForPath } from '@namespace/i18n/src/hooks/useGetActualPrefixForPath';

const LangRoutes = ({ path, ...rest }) => {
  const getActualPrefixForPath = useGetActualPrefixForPath();
  return (
    <Routes>
      <Route path={`${getActualPrefixForPath('/:lang')}${path}`} {...rest} />
    </Routes>
  );
};

export default LangRoutes;
