import { cloneElement, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Input from './components/Input';
import Popover from '../Popover';
import Box from '../Box';
import styles from './index.module.css';

const MIN_QUERY_LENGTH = 3;

const Search = ({
  query,
  setQuery,
  onSelect = () => {},
  result,
  isAutofocus = false,
  isExpandable = false,
  classNames = {},
  placeholder,
  haveToCloseOutsideClick,
  minQueryLength = MIN_QUERY_LENGTH,
  dataRole,
  disabled = false
}) => {
  const {
    wrapperClass = '',
    expandedWrapperClass = '',
    resultsClass = ''
  } = classNames;
  const mainRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(isAutofocus);
  const [isExpanded, setIsExpanded] = useState(!isExpandable);
  const className = `${wrapperClass} ${isExpanded ? expandedWrapperClass : ''}`;

  const handleChange = useCallback(
    (value) => {
      setQuery(value);
      setIsOpen(true);
    },
    [setQuery, setIsOpen]
  );
  const handleExpand = useCallback(
    (state) => {
      if (isExpandable) {
        setIsExpanded(state);
      }
    },
    [isExpandable, setIsExpanded]
  );
  const handleSelect = useCallback(
    (value) => {
      onSelect(value);
      setIsOpen(false);
      setIsActive(false);
    },
    [onSelect, setIsActive]
  );

  return (
    <Box
      data-role={dataRole}
      ref={mainRef}
      className={className}
      direction="column"
    >
      <Input
        value={query}
        onChange={handleChange}
        isAutofocus={isAutofocus}
        isExpanded={isExpanded}
        onExpand={handleExpand}
        setIsActive={setIsActive}
        placeholder={placeholder}
        disabled={disabled}
        dataRole="search-input"
      />
      <Popover
        isOpenExternal={isOpen}
        setIsOpenExternal={setIsOpen}
        triggerButtonRef={mainRef}
        haveToCloseOutsideClick={haveToCloseOutsideClick}
        trigger={<Box />}
        content={
          query.length >= minQueryLength ? (
            cloneElement(result, {
              isActive,
              query,
              onSelect: handleSelect
            })
          ) : (
            <Box />
          )
        }
        classNames={{
          contentClass: `${styles.results} ${resultsClass}`
        }}
      />
    </Box>
  );
};

Search.propTypes = {
  result: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  query: PropTypes.string,
  setQuery: PropTypes.func,
  onSelect: PropTypes.func,
  isAutofocus: PropTypes.bool,
  isExpandable: PropTypes.bool,
  haveToCloseOutsideClick: PropTypes.bool,
  placeholder: PropTypes.string,
  minQueryLength: PropTypes.number,
  classNames: PropTypes.shape({
    wrapperClass: PropTypes.string,
    expandedWrapperClass: PropTypes.string,
    resultsClass: PropTypes.string
  }),
  disabled: PropTypes.bool
};

export default Search;
