import { useContext, useEffect, useMemo } from 'react';
import { AlertNotification, AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { FormBuilder } from '@namespace/forms';
import { useFormContext } from 'react-hook-form';
import { SiteSettingsContext } from '@namespace/cms';
import CardFrontPreView from './CardFrontPreView';
import CardFrontEdit from './CardFrontEdit';
import CardBack from './CardBack';
import {
  CARD_INFO,
  PAN_KEEPER_TYPES,
  PAN_STATUS,
  PAN_STATUS_BLOCKED,
  PAN_TYPE
} from '../../../constants';
import CardDescription from '../CardDescription';
import CardFrontPreViewEdit from './CardFrontPreViewEdit';
import styles from './index.module.css';

const date = new Date();
let minDate = new Date(date.getFullYear(), date.getMonth(), 1);
const lowestMinDate = new Date(2021, 12, 1);
const maxDate = new Date(2099, 11, 31);

const DATE_CUSTOM_ERROR = 'dateFieldError';
const DATE_FIELD = 'dateField';

const Card = ({
  type,
  setIsCardInputReady,
  setEditCard,
  activeCard,
  setLocalError,
  saveDescription,
  isSingleWallet,
  shouldTriggerValidation
}) => {
  const [siteSettings] = useContext(SiteSettingsContext);
  const { otherSettings = {} } = siteSettings;
  const { isMinDateCardCheckDisabled } = otherSettings;
  minDate = isMinDateCardCheckDisabled ? lowestMinDate : minDate;
  const { t } = useI18n();
  const {
    getValues,
    formState,
    watch,
    trigger,
    clearErrors
  } = useFormContext();

  const updateFields = [
    watch(CARD_INFO.CARD_NUMBER),
    watch(CARD_INFO.EXPIRY_DATE),
    watch(CARD_INFO.CARD_HOLDER),
    watch(CARD_INFO.CVV),
    watch(CARD_INFO.DESCRIPTION)
  ].join('');

  const { isValid } = formState;

  useEffect(() => {
    const cb = async () => {
      if (shouldTriggerValidation) {
        await trigger();
      } else {
        clearErrors();
      }
    };
    cb();
  }, [clearErrors, shouldTriggerValidation, trigger]);

  useEffect(() => {
    setLocalError('');
    setIsCardInputReady(isValid);
    setEditCard(isValid ? getValues() : {});
  }, [
    setIsCardInputReady,
    isValid,
    getValues,
    setEditCard,
    updateFields,
    setLocalError,
    shouldTriggerValidation
  ]);

  const { cardStatus, type: panType } = activeCard || {};
  const isExtendedEditMode = panType === PAN_TYPE.WALLET;
  const isNewCard = cardStatus === PAN_STATUS.NEW;
  const isDescriptionFieldAvailable = isSingleWallet
    ? type === PAN_KEEPER_TYPES.ADD || isNewCard || isExtendedEditMode
    : type !== PAN_KEEPER_TYPES.WITHDRAW;

  const CardFrontView =
    isExtendedEditMode && isSingleWallet
      ? CardFrontPreViewEdit
      : CardFrontPreView;

  const handleDateFieldBlur = (setErrorType, currentError) =>
    setErrorType(DATE_FIELD, {
      type: DATE_CUSTOM_ERROR,
      message: currentError
    });

  return (
    <>
      <Box
        direction="column"
        align={!isSingleWallet ? 'center' : 'start'}
        className={styles.wrapper}
        margin="0 0 m"
      >
        {type === PAN_KEEPER_TYPES.ADD && (
          <AText bold={true} breed="BM" className={styles.title}>
            {t('panKeeper.addCard.topText')}
          </AText>
        )}
        <Box align="center" margin="0 0 s">
          <Box
            direction="column"
            justify="between"
            className={styles.cardForward}
          >
            {type === PAN_KEEPER_TYPES.ADD ? (
              <CardFrontEdit
                minDate={minDate}
                maxDate={maxDate}
                onBlur={handleDateFieldBlur}
                customFieldName={DATE_FIELD}
              />
            ) : (
              <CardFrontView
                minDate={minDate}
                maxDate={maxDate}
                activeCard={activeCard}
                onBlur={handleDateFieldBlur}
                customFieldName={DATE_FIELD}
              />
            )}
          </Box>
          {!activeCard?.cvv && type !== PAN_KEEPER_TYPES.WITHDRAW && (
            <CardBack />
          )}
        </Box>

        {type === PAN_KEEPER_TYPES.ADD && (
          <AText size="s">{t('panKeeper.addCard.bottomText')}</AText>
        )}
      </Box>

      {isDescriptionFieldAvailable && (
        <CardDescription
          activeCard={activeCard}
          saveDescription={saveDescription}
          isSingleWallet={isSingleWallet}
        />
      )}
    </>
  );
};

const CardForm = ({ activeCard, type, localError, ...props }) => {
  const { f } = useI18n();
  const {
    cardHash = '',
    cardStatus = '',
    description = '',
    expDate,
    cardHolderName = '',
    type: panType
  } = activeCard || {};
  const extraDefaultValues =
    panType === PAN_TYPE.WALLET
      ? {
          [CARD_INFO.CARD_HOLDER]: cardHolderName
        }
      : {};
  const defaultValues = useMemo(
    () => ({
      [CARD_INFO.DESCRIPTION]: description,
      ...(!PAN_STATUS_BLOCKED.includes(cardStatus) && {
        [CARD_INFO.EXPIRY_DATE]: expDate
          ? f.getDateTime({ date: expDate }).toFormat('MM/yyyy')
          : '',
        ...extraDefaultValues
      })
    }),
    [description, cardStatus, f, expDate, extraDefaultValues]
  );

  return (
    <FormBuilder
      key={type + cardHash}
      formConfig={{
        mode: 'onBlur',
        defaultValues
      }}
    >
      <Card activeCard={activeCard} type={type} {...props} />
      {localError && <AlertNotification message={localError} type="error" />}
    </FormBuilder>
  );
};

export default CardForm;
