import PropTypes from 'prop-types';
import clsx from 'clsx';
import Box from '../Box';
import styles from './index.module.css';

const ToggleCheckbox = ({
  className = '',
  checked = false,
  inputRef = null,
  disabled = false,
  onChange = () => {},
  ...props
}) => {
  const classes = clsx([
    styles.wrapper,
    styles.toggle,
    className,
    disabled && styles.disabled,
    checked && styles.checked
  ]);

  return (
    <Box data-role="toggle-checkbox" component="label" className={classes}>
      <input
        data-role="toggle-checkbox-input"
        type="checkbox"
        ref={inputRef}
        className={styles.checkbox}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        {...props}
      />
    </Box>
  );
};

ToggleCheckbox.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.element })
  ]),
  checked: PropTypes.bool,
  disabled: PropTypes.bool
};

export default ToggleCheckbox;
