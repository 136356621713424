import { FILTER_ALL_VALUE } from '@namespace/common';

export const MIN_ITEMS_COUNT = 10;

export const PURCHASE_WITHDRAW_AMOUNT_FILTER = [
  {
    value: FILTER_ALL_VALUE,
    label: 'purchaseHistory.withdraw.all'
  },
  {
    value: '>=-500000',
    label: '<500 000'
  },
  {
    value: '<=-500000',
    label: '>500 000'
  },
  {
    value: '<=-1000000',
    label: '>1 000 000'
  },
  {
    value: '<=-5000000',
    label: '>5 000 000'
  },
  {
    value: '<=-10000000',
    label: '>10 000 000'
  }
];

export const CLUB_TYPES = {
  SCORE_HISTORY: 'SCORE_HISTORY',
  PURCHASE_HISTORY: 'PURCHASE_HISTORY'
};

export const SHOP_STARS = {
  1: 'amateur',
  3: 'master',
  4: 'vip'
};
