import { HTMLSnippet } from '@namespace/snippets';
import styles from './index.module.css';

const SnippetContent = ({
  privacyPolicyId,
  privacyPolicyBannerTitle,
  isShowContent = true
}) => {
  return (
    <>
      {privacyPolicyBannerTitle && (
        <HTMLSnippet
          className={styles.snippetContent}
          snippetId={privacyPolicyBannerTitle}
        />
      )}

      {isShowContent && (
        <HTMLSnippet
          className={styles.snippetContent}
          snippetId={privacyPolicyId}
        />
      )}
    </>
  );
};

export default SnippetContent;
