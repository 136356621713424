import { useContext, useEffect, useReducer } from 'react';
import { waitForElements, document } from '@namespace/helpers';
import { OnboardingContext } from '../../../store/context';
import { checkEl } from '../../../utils';
import { useCurrentStep } from '../../../hooks';
import { ElContext } from './context';

export const ElWatcher = ({ children }) => {
  const [
    { isInterrupted, steps, stepIndex, tourIndex },
    { RUN, NEXT }
  ] = useContext(OnboardingContext);
  const { target } = useCurrentStep();
  const [els, setEls] = useContext(ElContext);
  const [rs, reset] = useReducer(() => Math.random(), 0);

  // find working element
  useEffect(() => {
    let isStillValid = true;

    const wait = async () => {
      try {
        const nextEls = await waitForElements(target, { checkEl });

        if (isStillValid) {
          setEls(nextEls);
        }
      } catch (ex) {
        if (!isStillValid) {
          console.info(ex);
          return;
        }

        console.error(ex);

        if (steps.length - 1 === stepIndex) {
          RUN(false);
          return;
        }

        NEXT();
      }
    };

    if (target && !isInterrupted) {
      wait();
    }

    return () => {
      isStillValid = false;
      setEls(null);
    };
  }, [
    NEXT,
    RUN,
    setEls,
    rs, // `rs` dependency is essential
    stepIndex,
    steps.length,
    target,
    isInterrupted
  ]);

  // todo 1. add timer to turn off setInterval after 3 seconds
  // todo 2. either pass `el` here to reset 3-sec timer or use `target` for that
  // reset by interval=300 for 3 seconds after each step change
  useEffect(() => {
    if (isInterrupted) {
      return () => {};
    }

    const interval = setInterval(() => reset(), 300);
    // const timeout = setTimeout(() => clearInterval(interval), 3000);

    return () => {
      clearInterval(interval);
      // clearTimeout(timeout);
    };
  }, [isInterrupted, stepIndex, tourIndex]);

  // reset by RAF - if element has left DOM
  useEffect(() => {
    let frame;
    const el = Array.isArray(els) ? els[0] : els;

    // todo use `checkEl` function from utils - if it's performant enough for RAF
    // We check that current found element is still in the DOM. If not - reset.
    // Also, we check if element is still visible in DOM (display != 'none').
    const checkIsElValid = () => {
      if (!document.contains(el) || getComputedStyle(el).display === 'none') {
        reset();
      } else {
        frame = requestAnimationFrame(checkIsElValid);
      }
    };

    if (el) {
      checkIsElValid();
    }

    return () => {
      cancelAnimationFrame(frame);
    };
  }, [els]);

  return children;
};
