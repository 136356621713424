import { useEffect, useState } from 'react';
import { Box } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { window, document } from '@namespace/helpers';
import { onBackToTopClick } from '../../common/BackToTop';
import styles from './index.module.css';

export const ScrollToTopButton = () => {
  const [isDisplayButton, setIsDisplayButton] = useState(false);

  let lastScrollTop = 0;
  const onScroll = () => {
    const backToTopButtonInFooter = document
      .querySelector('[data-role=footer-back-to-top-button]')
      ?.getBoundingClientRect();
    const scrollTop = window.scrollY || document.documentElement.scrollTop;

    if (
      scrollTop - lastScrollTop >= 2 ||
      scrollTop <= 20 ||
      (backToTopButtonInFooter &&
        backToTopButtonInFooter.top >= 0 &&
        backToTopButtonInFooter.left >= 0 &&
        backToTopButtonInFooter.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        backToTopButtonInFooter.right <=
          (window.innerWidth || document.documentElement.clientWidth))
    ) {
      setIsDisplayButton(false);
    } else if (lastScrollTop - scrollTop >= 2) {
      setIsDisplayButton(true);
    }
    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    isDisplayButton && (
      <Box
        onClick={onBackToTopClick}
        align="center"
        justify="center"
        className={styles.container}
      >
        <div className={styles.background} />
        <Icon
          className={styles.arrowIcon}
          color="footer_txt_1"
          size="m"
          name="icons/general/nav/arrow-up"
        />
      </Box>
    )
  );
};
