import { useReducer, useMemo } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import {
  getLocalStorageValue,
  LOCAL_STORAGE_KEYS,
  COEF_TYPE_DECIMAL
} from '@namespace/helpers';
import { actions as actionCreators } from '../actions';
import { reducers } from '../reducer';
import { types } from '../types';
import { CoefTypeContext } from '../context';

const initialState = {
  coefType: getLocalStorageValue(
    LOCAL_STORAGE_KEYS.COEF_TYPE,
    COEF_TYPE_DECIMAL
  )
};

export const CoefTypeProvider = ({ customReducers, children }) => {
  const memoReducers = useMemo(() => {
    return combineReducers(reducers, customReducers);
  }, [customReducers]);

  const [state, dispatch] = useReducer(memoReducers, initialState);

  const actions = useActions(types, dispatch, actionCreators);

  return (
    <CoefTypeContext.Provider value={[state, actions]}>
      {children}
    </CoefTypeContext.Provider>
  );
};
