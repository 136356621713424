import { memo } from 'react';
import { AText, Box } from '@alf/uikit';
import styles from './index.module.css';

const HeadGroup = ({
  marketTemplateName,
  resultTypeId,
  marketTemplateId,
  outcomeTypeNames
}) => {
  const formattedOutcomeTypeNames = outcomeTypeNames;
  if (outcomeTypeNames?.length === 2) {
    formattedOutcomeTypeNames.splice(1, 0, marketTemplateName);
  }

  return (
    <Box
      className={styles.marketParamHead}
      justify="around"
      key={`${resultTypeId}-${marketTemplateId}`}
    >
      {formattedOutcomeTypeNames?.map((typeName) => (
        <AText
          breed="R"
          size="s"
          color="line_txt_4"
          align="center"
          transform="capitalize"
          overflow="ellipsis"
          key={typeName}
          className={styles.typeName}
        >
          {typeName}
        </AText>
      ))}
    </Box>
  );
};

export default memo(HeadGroup);
