import { useI18n } from '@namespace/i18n';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { LocalizedUtils } from '@alf/uikit';

import en from 'date-fns/locale/en-US';
import cn from 'date-fns/locale/zh-CN';
import ch from 'date-fns/locale/fr-CH';
import ru from 'date-fns/locale/ru';
import uk from 'date-fns/locale/uk';
import ro from 'date-fns/locale/ro';
import tr from 'date-fns/locale/tr';
import pl from 'date-fns/locale/pl';
import fr from 'date-fns/locale/fr';
import de from 'date-fns/locale/de';
import sk from 'date-fns/locale/sk';
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';
import el from 'date-fns/locale/el';
import it from 'date-fns/locale/it';
import hr from 'date-fns/locale/hr';
import az from 'date-fns/locale/az';
import be from 'date-fns/locale/be';
import am from 'date-fns/locale/hy';

const DatePickerProvider = ({ children }) => {
  const { language } = useI18n();
  const localeMap = {
    en,
    ru,
    uk,
    ro,
    tr,
    pl,
    fr,
    de,
    sk,
    es,
    pt,
    el,
    it,
    hr,
    cn,
    ch,
    az,
    be,
    am
  };

  return (
    <MuiPickersUtilsProvider
      utils={LocalizedUtils}
      locale={localeMap[language] || localeMap.en}
    >
      {children}
    </MuiPickersUtilsProvider>
  );
};

export default DatePickerProvider;
