import { useContext } from 'react';
import { AText, Box, Button } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { DeviceContext } from '@namespace/device';
import { OnboardingContext } from '../../../../store/context';
import styles from './index.module.css';

export const TourSelectionContent = ({
  textBefore = '',
  textAfter = '',
  links = []
}) => {
  const { t } = useI18n();
  const [, { GO_TO_TOUR_BY_ID }] = useContext(OnboardingContext);
  const { pageType } = useContext(DeviceContext);

  return (
    <Box direction="column" className={styles.root} gap="m">
      <AText breed="R" size="m" color="txt_color_7">
        {t(textBefore)}
      </AText>
      <Box justify="around" align="start" className={styles.links}>
        {links.map((link) => (
          <Button
            dataRole={`onboarding-tooltip-btn-${link.tourId}`}
            key={link.tourId}
            intent="quaternary"
            actionType="color3"
            contentClass={styles.buttonText}
            onClick={() => GO_TO_TOUR_BY_ID({ tourId: link.tourId, pageType })}
          >
            {t(link.title)}
          </Button>
        ))}
      </Box>
      <AText breed="R" size="m" color="txt_color_7">
        {t(textAfter)}
      </AText>
    </Box>
  );
};
