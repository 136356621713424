import { useFormContext } from 'react-hook-form';
import { getFormError } from '@namespace/forms';
import { useI18n } from '@namespace/i18n';
import { CashDeskField } from '../CashDeskField';

export const CashDesk = ({
  name,
  rules = {},
  cashdesk,
  walletHash,
  amount,
  label,
  placeholder,
  ...props
}) => {
  const { t, language } = useI18n();
  const methods = useFormContext();
  const {
    watch,
    setValue,
    formState: { errors }
  } = methods;
  const cityWatch = watch('city');

  return (
    <CashDeskField
      walletHash={walletHash}
      language={language}
      amount={amount}
      setValue={(value) => setValue(name, value)}
      cityId={cityWatch || ''}
      name={name}
      rules={rules}
      error={getFormError(name, errors, t)}
      label={t(label)}
      placeholder={t(placeholder)}
      {...props}
    />
  );
};
