import { useCallback, useState, forwardRef, useContext } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { window } from '@namespace/helpers';
import { DeviceContext } from '@namespace/device';
import LazyLoadImage from '../../../LazyLoadImage';
import Box from '../../../Box';
import { SLIDER_ITEM_TYPES } from '../../constants';
import CarouselSkelet from '../CarouselSkelet';
import styles from '../../index.module.css';

const Slide = forwardRef(
  (
    {
      slide,
      handleClickOnMove,
      home,
      language,
      LocalizedLink,
      HTMLSnippet,
      currentCurrency,
      className,
      classNames = {},
      gap,
      getImgUrl
    },
    ref
  ) => {
    const {
      source,
      url,
      link,
      images = [],
      defaultImageLink,
      defaultImageLinkDark,
      snippetId,
      tablet = {}
    } = slide;
    const { isTablet } = useContext(DeviceContext);
    const [isLoaded, setIsLoaded] = useState(window.IS_SSR);
    const isLoadedImage = useCallback(() => setIsLoaded(true), [isLoaded]);

    if (source === SLIDER_ITEM_TYPES.HTML_SNIPPET) {
      return (
        <div
          className={styles.snippetBox}
          ref={ref}
          style={gap && { marginRight: gap }}
        >
          <HTMLSnippet snippetId={snippetId} />
        </div>
      );
    }

    if (source === SLIDER_ITEM_TYPES.IMAGE) {
      const {
        images: tabletImages,
        defaultImageLink: tabletDefaultImageLink,
        defaultImageLinkDark: tabletDefaultImageLinkDark
      } = tablet;
      const isTabletImages = isTablet && !isEmpty(tablet);
      const slideImages = isTabletImages ? tabletImages : images;
      const slideDefaultImageLink =
        (isTabletImages && tabletDefaultImageLink) || defaultImageLink;
      const slideDefaultImageLinkDark =
        (isTabletImages && tabletDefaultImageLinkDark) || defaultImageLinkDark;

      const { imagePath, imagePathDark, imagesPerCurrency } =
        slideImages?.find(({ lang }) => lang === language) || {};

      const { imagePath: currencyImage, imagePathDark: currencyImageDark } =
        imagesPerCurrency?.find(
          ({ currency: curr }) => curr === currentCurrency
        ) || {};

      const imageForLanguageDark = imagePathDark || slideDefaultImageLinkDark;
      const imageForLanguage = getImgUrl(imagePath, imageForLanguageDark);

      const imageForCurrencyDark = currencyImageDark || imageForLanguageDark;
      const imageForCurrency = getImgUrl(currencyImage, imageForCurrencyDark);

      const defaultImage = getImgUrl(
        slideDefaultImageLink,
        slideDefaultImageLinkDark
      );

      const imgPath = imageForCurrency || imageForLanguage || defaultImage;
      const imgLink = url || link || '';

      return (
        <LocalizedLink
          style={gap && { marginRight: gap }}
          ref={ref}
          rel="noopener noreferrer"
          to={imgLink}
          onClick={handleClickOnMove}
          className={clsx([styles.link, className])}
        >
          <Box className={styles.lazyLoadWrapper}>
            <CarouselSkelet
              className={clsx([
                !isLoaded ? styles.show : styles.hide,
                classNames?.slideSkeleton
              ])}
            />
            <LazyLoadImage
              alt="Promotion material"
              src={imgPath}
              key={imgLink || home}
              width="100%"
              height="100%"
              className={clsx(
                styles.lazyLoadImg,
                isLoaded ? styles.show : styles.hide,
                classNames?.slideImg
              )}
              onLoad={isLoadedImage}
            />
          </Box>
        </LocalizedLink>
      );
    }

    return null;
  }
);

export default Slide;
