import { window } from '@namespace/helpers';

const logNewRelicAction = (
  actionName,
  attributes = {},
  options = { sendAB: true }
) => {
  const { sendAB = true } = options;
  const abTestsMap = sendAB ? window.AB_TESTS || {} : {};

  if (window.newrelic) {
    try {
      window.newrelic.addPageAction(actionName, {
        ...attributes,
        ...abTestsMap
      });
    } catch {
      console.error('newrelic addPageAction exception');
    }
  } else if (
    process.env.NODE_ENV !== 'development' ||
    process.env.TURN_ANALYTIC_LIBS_ON === 'true'
  ) {
    console.error('no newrelic in window');
  }
};

export default logNewRelicAction;
