import { isString } from 'lodash';
import { TIMEOUT_FOR_REJECT_REQUEST } from '../../constants';
import { defaultResponseHandler } from './responseHandlers';
import { getDeviceIdHeaderSetter } from '../../services/fingerprint';

const prepareData = (data) => {
  if (isString(data)) {
    return data;
  }

  return data instanceof FormData ? data : JSON.stringify(data);
};

export const makeFetch = async (
  method,
  args,
  handler = defaultResponseHandler
) => {
  const controller = new AbortController();
  const { signal } = controller;

  const setDeviceIdHeader = await getDeviceIdHeaderSetter();

  return new Promise((resolve, reject) => {
    const argsIsObject = typeof args[0] === 'object';
    // An example of how to add a custom request rejection timeout is in the file: libs/i18n/src/utils/utils.js
    const [
      url,
      body,
      headers = {
        'Content-Type': 'application/json'
      },
      credentials = 'include',
      mode = 'cors',
      timeout = TIMEOUT_FOR_REJECT_REQUEST
    ] = argsIsObject
      ? [
          args[0].url,
          args[0].body,
          args[0].headers,
          args[0].credentials,
          args[0].mode,
          args[0].timeout
        ]
      : args;

    let fetchTimeout = null;

    const reqParams = {
      signal,
      method,
      body: prepareData(body),
      headers: setDeviceIdHeader(headers),
      credentials,
      mode
    };

    fetchTimeout = setTimeout(() => {
      controller.abort();
    }, timeout);

    return fetch(url, reqParams)
      .then(async (response) => {
        clearTimeout(fetchTimeout);
        try {
          return await response.json();
        } catch {
          return '';
        }
      })
      .then((response) => handler(response, resolve, reject))
      .catch(reject);
    // TODO: uncomment when need to collect some data from rests to use as mocks
    // add url for needed api url to utils/collectData if not presented yet
    // .then((response) => {
    //   collectRestData(url, response, body?.method ? body?.method : null);
    //   return handler(response, resolve, reject);
    // })
    // .catch(reject);
  });
};
