import { useCallback, useMemo } from 'react';
import { useI18n } from '@namespace/i18n';
import { Flag } from '@namespace/icons2';
import { Controller, useFormContext } from 'react-hook-form';
import { Phone, PhoneSearch } from '@alf/uikit';
import { logAmplitude } from '@namespace/analytics';
import getFormError from '../../utils/getFormError';
import Control from '../Control';
import useDynamicField from '../../hooks/useDynamicField';
import useCustomError from '../../hooks/useCustomError';
import useOnChangeValidate from '../../hooks/useOnChangeValidate';
import styles from './index.module.css';

export const isSubmit = (e) => /button|submit/.test(e.relatedTarget?.type);

const PhoneField = ({
  name,
  label = '',
  hint,
  classNames = {},
  rules = {},
  disabled = false,
  isOnChangeValidation = false,
  phoneCodes = [],
  defaultCountry,
  customError = '',
  formProps = {},
  size,
  dataRole,
  isUseSearch = false,
  value: valueProp,
  preventCodeChange = false,
  validateAsObject = false,
  analyticAction,
  ...rest
}) => {
  const { t } = useI18n();
  const {
    control,
    formState: { errors },
    watch,
    clearErrors,
    setValue,
    trigger,
    getFieldState
  } = useFormContext();
  const { errorMessage, customErrorHandler } = useCustomError(customError);
  const { dynamicValues = {} } = formProps;
  const {
    wrapperClassName = '',
    containerClassName = '',
    labelClassName = ''
  } = classNames;
  useDynamicField({ name, dynamicFieldData: dynamicValues[name] });
  useOnChangeValidate({ name, isOnChangeValidation, validateAsObject });

  const phoneDataSearch = useMemo(
    () =>
      phoneCodes.map((item) => {
        return {
          ...item,
          title: `+${item.phoneCode} ${item.countryName}`,
          value: item.iso1Code,
          iconElement: (
            <Flag
              key={item.iso1Code}
              iso={item.iso1Code}
              name={item.countryName}
              className={styles.flag}
            />
          )
        };
      }),
    [phoneCodes]
  );

  const { number: phoneNumber = '' } = watch(name) ?? { number: '' };
  const error = getFormError(name, errors, t) || t(errorMessage);
  const intent = useMemo(() => {
    if (error) {
      return 'error';
    }
    if (phoneNumber.length) {
      return 'success';
    }
    return 'default';
  }, [error, phoneNumber]);

  const validateMinMax = useCallback(
    (data) => {
      const number = data?.target?.value ?? data?.number;
      const minLength = rules?.minLength?.value;
      const maxLength = rules?.maxLength?.value;

      if (number) {
        if (minLength && number.length < minLength) {
          const [message, ...params] = rules?.minLength?.message ?? [];
          return t(message, params);
        }
        if (maxLength && number.length > maxLength) {
          const [message, ...params] = rules?.maxLength?.message ?? [];
          return t(message, params);
        }
      }
      return Boolean(number.length) || rules.required;
    },
    [rules, t]
  );

  const inputValidator = useCallback(
    (number) => {
      if (preventCodeChange) {
        const code = phoneCodes.find((i) => i.iso1Code === defaultCountry)
          ?.phoneCode;
        const isCodeNotChanged = number.startsWith(`+${code}`);

        return isCodeNotChanged;
      }

      return true;
    },
    [phoneCodes, preventCodeChange, defaultCountry]
  );

  const createInputValue = useCallback(
    (data) => {
      const { number = '', code = '', ...restData } = data;
      const matchedCountry = getCountryByPhoneNumber(phoneCodes, number);

      return {
        number,
        code: code || matchedCountry?.phoneCode || '',
        ...restData
      };
    },
    [phoneCodes]
  );

  const handleEventChange = useCallback(
    (onChange) => (data) => {
      const validData = createInputValue(data);

      onChange(validData);
      clearErrors(name);

      return validData;
    },
    [clearErrors, name, createInputValue]
  );

  const handleChange = useCallback(
    (onChange) => (data) => {
      const validData = createInputValue(data);

      onChange(validData);

      return validData;
    },
    [createInputValue]
  );

  const handleBlur = useCallback(
    (onBlur) => (e) => {
      if (isSubmit(e)) return;
      onBlur(e);

      if (analyticAction) {
        setTimeout(() => {
          const { invalid } = getFieldState(name);
          logAmplitude(analyticAction, {
            valid: !invalid
          });
        }, 1000);
      }
    },
    [analyticAction, getFieldState, name, trigger]
  );

  const handleDefaultValueChange = useCallback(
    (value) => {
      setValue(name, value, false);
    },
    [setValue, name]
  );

  const text = t('reg.phoneCodeSearch.notFound');
  const placeholderText = t('register.steps.personalInfo.mobilePlaceholder');

  const commonComponentProps = {
    ...rest,
    intent,
    disabled,
    dataRole
  };

  const renderComponent = (withSearch) => ({ field: { ref, ...field } }) => {
    const {
      value: { number = '', isoCode = '', code = '' } = {},
      onChange,
      onBlur,
      ...props
    } = field;

    return withSearch ? (
      <PhoneSearch
        {...commonComponentProps}
        onChange={handleEventChange(onChange)}
        onBlur={handleBlur(onBlur)}
        codesListItems={phoneDataSearch}
        codesListProps={{
          size,
          placeholderText,
          text
        }}
        onDefaultValueChange={handleDefaultValueChange}
        validator={inputValidator}
        value={{
          number,
          isoCode
        }}
        {...props}
      />
    ) : (
      <Phone
        {...commonComponentProps}
        onChange={handleChange(onChange)}
        phoneCodes={phoneCodes}
        defaultCountry={defaultCountry}
        value={{
          number,
          isoCode,
          code
        }}
        size={size}
        text={text}
        {...props}
      />
    );
  };

  return (
    <Control
      error={error}
      hint={hint}
      classNames={{
        className: containerClassName,
        wrapperClassName,
        labelClassName
      }}
      onClick={customErrorHandler}
      label={t(label)}
      dataRole={dataRole}
    >
      <Controller
        render={renderComponent(isUseSearch)}
        control={control}
        name={name}
        rules={{
          ...rules,
          ...((rules?.minLength?.value || rules?.maxLength?.value) && {
            validate: validateMinMax
          })
        }}
      />
    </Control>
  );
};

export default PhoneField;

function getCountryByPhoneNumber(countries = [], value = '') {
  return countries
    .sort((a, b) => (a.phoneCode.length < b.phoneCode.length ? 1 : -1))
    .find((i) => value.replace('+', '').startsWith(i.phoneCode));
}
