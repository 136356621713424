export const PROVIDERS_TYPES = {
  ORYX: 'oryx',
  CT_ORYX: 'ct-oryx',
  EGT: 'amusnet-interactive',
  AMUSNET_LIVE: 'amusnet-live',
  KALAMBA: 'kalamba',
  GAMOMAT: 'gamomat',
  TVBET: 'tvbet',
  KENO: 'keno',
  INTERNATIONAL_LOTTERIES: 'international-lotteries',
  BETGAMES: 'betgames',
  QUIZ: 'virtual-games-iksariquiz',
  GOLDENRACE: 'golden-race',
  BETRADAR: 'betradar',
  LEAP: 'leap-virtual-sports',
  BET1X2: '1x2-bet-virtual-sports',
  ENDORPHINA: 'endorphina',
  BETSOFT: 'betsoft',
  BOOMING: 'booming',
  EGT_DIGITAL: 'egt-digital',
  PATEPLAY: 'pateplay',
  BOOK_OF_SIX: 'book_of_six',
  STAKELOGIC_RLX: 'stakelogic-rlx',
  LUCKY_WHEEL: 'lucky_wheel',
  PINNACLE: 'PinnacleSolution',
  PLAYTECH: 'playtech'
};

export const DIRECT_URL_PROVIDERS = [PROVIDERS_TYPES.EGT];

export const LOBBY_GAMES_SECTION_COUNT = 24;

export const LOBBY_RECENTLY_PLAYED_GAMES_AMOUNT = 10;
export const LOBBY_RECOMMENDED_GAMES_AMOUNT = 20;
export const LOBBY_LIVE_RTP_GAMES_AMOUNT = 20;

export const LIVE_RTP_GAMES_SECTION_AMOUNT = 14;

export const REAL_MODE = 'real';
export const DEMO_MODE = 'demo';

export const FAVORITE_CATEGORY = {
  id: 'favorite',
  title: 'games.favorite'
};

export const CASINO_MIN_PROVIDERS = 2;

export const RECOMMENDED_CATEGORY = {
  id: 'recommended',
  title: 'content.games.category.recommended'
};
export const RECENTLY_PLAYED_CATEGORY = {
  id: 'recentlyPlayed',
  title: 'content.games.category.recentlyplayed'
};

export const LIVE_RTP_CATEGORY_SECTIONS = {
  hot: {
    title: 'content.games.daily.hot.games',
    tooltipTitle: 'content.games.category.tooltip.daily.hot.games'
  },
  cold: {
    title: 'content.games.daily.cold.games',
    tooltipTitle: 'content.games.category.tooltip.daily.cold.games'
  }
};

// TODO: can be removed after switch to BE recommended logic. for now used with GM
export const REC_VOLATILITY_HIGH = 'rec_volatility_high';
export const REC_VOLATILITY_MEDIUM = 'rec_volatility_medium';
export const REC_VOLATILITY_LOW = 'rec_volatility_low';
export const REC_MEGAWAYS = 'rec_megaways';
export const REC_BUY_BONUS = 'rec_buy_bonus';
export const REC_INSTANT_GAMES = 'rec_instant_games';
export const REC_TABLE_GAME = 'rec_table_game';
export const REC_ROULETTE_GAME = 'rec_roulette_game';

// TODO: can be removed with new Recommended games launch
export const GAME_GROUP = {
  SLOT_GAME: 'slotGame',
  INSTANT_GAME: 'instantGame',
  TABLE_GAME: 'tableGame',
  ROULETTE_GAME: 'rouletteGame'
};

// TODO: can be removed after switch to BE recommended logic. for now used with GM
export const GAME_GROUP_BY_TAGS = {
  [GAME_GROUP.SLOT_GAME]: [
    REC_VOLATILITY_HIGH,
    REC_VOLATILITY_MEDIUM,
    REC_VOLATILITY_LOW,
    REC_MEGAWAYS,
    REC_BUY_BONUS
  ],
  [GAME_GROUP.INSTANT_GAME]: [REC_INSTANT_GAMES],
  [GAME_GROUP.TABLE_GAME]: [REC_TABLE_GAME],
  [GAME_GROUP.ROULETTE_GAME]: [REC_ROULETTE_GAME]
};

export const ROWS_WITH_GAMES = {
  ALL: 'All',
  ALL_WITHOUT_PAGINATION: 'ALL_WITHOUT_PAGINATION',
  ONE: 'oneRow',
  TWO: 'twoRows',
  THREE: 'threeRows'
};

export const DEFAULT_ROWS_MOBILE = 3;
export const DEFAULT_ROWS_DESKTOP_TABLET = 2;

export const SECTION_TYPES = {
  CONTAINER: 'CONTAINER',
  CATEGORY_GENERAL: 'CATEGORY_GENERAL',
  CATEGORY_RECOMMENDED: 'CATEGORY_RECOMMENDED',
  JACKPOT_GENERAL: 'JACKPOT_GENERAL',
  JACKPOT_PROVIDER: 'JACKPOT_PROVIDER',
  JACKPOT_IN_HOUSE_MOBILE: 'JACKPOT_IN_HOUSE_MOBILE',
  CATEGORY_RECENTLY_PLAYED: 'CATEGORY_RECENTLY_PLAYED',
  CATEGORY_LIVE_RTP: 'CATEGORY_LIVE_RTP',
  BONUS_SLIDER: 'BONUS_SLIDER'
};

export const WIDGET_TYPES = {
  ANOTHER_CASINO_TYPE: 'anotherCasinoType',
  COLLECTIONS: 'collections',
  JACKPOT: 'jackpot',
  REAL_TIME_WINS: 'realTimeWins',
  TOP_GAMES: 'topGames',
  TOURNAMENTS: 'tournament'
};

// TODO: same as SERVICE_TYPE constant
export const CASINO_TYPE = {
  CASINO: 'casino',
  CASINO_LIVE: 'casinoLive',
  INSTANT_GAMES: 'instantGames'
};

export const IETF_LOCALES_FOR_LAUNCH = {
  en: 'en-US',
  ru: 'ru-RU',
  ro: 'ro-RO',
  hr: 'hr-HR',
  uk: 'uk-UA',
  pt: 'pt-PT',
  es: 'es-ES'
};

// by default jackpot code value is empty string in CMS settings
export const DEFAULT_JACKPOT_CODE = '';

export const DEFAULT_GAME_TAGS = [
  {
    _id: '1',
    tagId: 'new',
    tagIcon: null,
    tagBackground: 'var(--accent_color_5)'
  },
  {
    _id: '2',
    tagId: 'hot',
    tagIcon: null,
    tagBackground: 'var(--accent_color_3)'
  }
];

export const DEFAULT_PROVIDER_TAGS = [
  {
    _id: '1',
    tagId: 'new',
    tagIcon: null,
    tagBackground: 'var(--state_success)'
  },
  {
    _id: '2',
    tagId: 'hot',
    tagIcon: null,
    tagBackground: 'var(--accent_color_3)'
  }
];

export const DEFAULT_GAMES_TAG_BACKGROUND = 'var(--accent_color_3)';

export const IN_HOUSE_JACKPOT_ACCEPT_TYPES = {
  ACCEPT_REQUEST: 'acceptRequest',
  ACCEPT_REDIRECT: 'acceptRedirect',
  ACCEPT_CLOSE: 'acceptClose'
};

export const IN_HOUSE_JACKPOT_TYPES = {
  WIN_NOTIFICATION: 'winNotification',
  WIN_MESSAGES: 'winMessage'
};

export const TWO_GAMES_PER_ROW_BREAKPOINT = 491;

export const LARGE_SCREEN_WIDTH = 1366;

export const MIN_GAMES_SEARCH_QUERY_LENGTH = 3;

export const CONTROL_MODE_TYPES = {
  LAUNCH: 'launch',
  REDIRECT: 'redirect'
};

export const SLUG_PARAMETER = {
  prewagerExclusions: 'prewager-exclusions'
};

export const LAUNCHED_FROM_INHOUSE_JACKPOT = 'inhouseLaunched';
