import { useMemo } from 'react';
import { filter, isNil, map, pipe, prop, propEq, reject, uniq } from 'ramda';
import { useAdvFilterInnerCtxStore } from '../../store';

export const useSelectedCategoryIdsBySport = () => {
  const selectedFilter = useAdvFilterInnerCtxStore('filter');
  const selectedSportId = useAdvFilterInnerCtxStore('selectedSportId');

  return useMemo(
    () =>
      pipe(
        filter(propEq('sportId', selectedSportId)),
        map(prop('categoryId')),
        reject(isNil),
        uniq,
        map(String)
      )(selectedFilter),
    [selectedFilter, selectedSportId]
  );
};
