import { camelizeKeys } from 'humps';
import { isEmpty } from 'lodash';
import { post, postAsUriParameters, postAsJson } from '@namespace/helpers';
import apiRoutes from './apiRoutes';

const PREDEFINED_IP_WEB_API_ASK_US_TO_USE = '127.0.0.1';

const makeDataForApi = ({
  accountName,
  bankName,
  bankAccount,
  unpBank,
  personalUserBankAccount,
  bik,
  bankAccountId
}) => ({
  account_name: accountName,
  bank_name: bankName,
  bank_account: bankAccount,
  unp_bank: unpBank,
  personal_user_bank_account: personalUserBankAccount,
  bank_acc_id: bankAccountId,
  bik
});

export const getInboxCounter = (userId) =>
  postAsUriParameters(apiRoutes.getInboxMessages, {
    user_id: userId
  }).then(({ response = {} }) => camelizeKeys(response.new || 0));

export const checkIsVideoAvailable = (userId = 0) => {
  return postAsUriParameters(apiRoutes.checkVideo, {
    user_id: userId,
    service_id: 1
  }).then(({ response }) => camelizeKeys(response));
};

export const uploadDocuments = (files = []) => {
  const formData = new FormData();
  files.map((file) => formData.append('upload', file));

  return fetch(apiRoutes.uploadDocuments, {
    method: 'post',
    body: formData,
    headers: {
      'Content-Type': 'application/json'
    },
    mode: 'no-cors',
    credentials: 'include'
  }).then(async (response) => {
    try {
      if (response.ok && response.status === 200) {
        return await response;
      }

      return {
        type: 'error',
        status: response.status
      };
    } catch (error) {
      return error;
    }
  });
};

export const getBankAccounts = () => {
  return postAsUriParameters(
    apiRoutes.getBankAccounts,
    {}
  ).then(({ response }) => camelizeKeys(response));
};

export const createBankAccount = (data) => {
  return postAsUriParameters(
    apiRoutes.createBankAccount,
    makeDataForApi(data)
  ).then((response) => {
    const { error } = response;
    if (error !== 'no') {
      const firstValidationError = Object.values(response)[0]?.error_code;
      throw new Error(firstValidationError || 'accounting_error_6');
    }
    return response;
  });
};
export const deleteBankAccount = (accountId) => {
  return postAsUriParameters(apiRoutes.deleteBankAccount, {
    bank_acc_id: accountId
  });
};
export const updateBankAccount = (data) => {
  return postAsUriParameters(apiRoutes.updateBankAccount, makeDataForApi(data));
};

export const updateAddressFields = (data) => {
  return postAsUriParameters(apiRoutes.updateAddressFields, data);
};

export const loginUser = ({
  username,
  password,
  captcha,
  affiliateParams,
  reCaptchaData
}) =>
  postAsUriParameters(
    apiRoutes.authUser,
    {
      username,
      password,
      captcha,
      ...(affiliateParams ? { affiliateParams } : {})
    },
    undefined,
    undefined,
    reCaptchaData
  ).then((response) => {
    const [error] = Object.values(response);
    if (error?.error === 'yes') {
      return Promise.reject(camelizeKeys(error));
    }

    return response;
  });

export const logoutUser = (id) =>
  postAsUriParameters(apiRoutes.logout, { user_id: id });

export const updateUser = (userData) =>
  postAsUriParameters(apiRoutes.updateUser, userData, false, true).then(
    ({ error, error_code: errorCode }) =>
      error === 'no' && !errorCode.includes('error')
  );

export const setUserNotifications = (notifications) =>
  postAsUriParameters(apiRoutes.setUserNotifications, {
    source_of_notifications: JSON.stringify(notifications)
  });

export const changeActiveWallet = ({ walletHash }) =>
  postAsUriParameters(apiRoutes.changeActiveWallet, {
    wallet_hash: walletHash
  });

export const updatePhone = (phone, userId) =>
  postAsUriParameters(apiRoutes.changeUser, {
    phone,
    user_id: userId
  });

export const changeUser = ({ adress, city, phone, zip, id, ...rest }) =>
  postAsUriParameters(
    apiRoutes.changeUser,
    {
      user_id: id,
      adress,
      city,
      phone,
      zip,
      ...rest
    },
    false,
    true
  );

export const getIbanCards = (data) =>
  postAsUriParameters(apiRoutes.getIbans, data).then(
    ({ response }) => response
  );

export const saveIbanCard = (data) =>
  postAsUriParameters(apiRoutes.addIban, data);

export const changeUserPassword = ({
  id,
  oldPassword,
  newPassword,
  confirmPassword
}) =>
  postAsUriParameters('/accounting/api/change_password', {
    user_id: id,
    old_password: oldPassword,
    new_password: newPassword,
    password_repeat: confirmPassword
  });

export const saveDocuments = ({
  documentPlaceDate,
  documentExpireDate,
  documentNumber,
  documentPlace,
  documentTypeId,
  citizenship,
  pin
}) => {
  const pinData = pin ? { pin } : {};
  return postAsUriParameters(apiRoutes.saveDocuments, {
    doc_date: documentPlaceDate,
    ...(documentExpireDate && { date_of_expiration: documentExpireDate }),
    doc_number: documentNumber,
    doc_place: documentPlace,
    citizenship,
    document_type_id: documentTypeId,
    ...pinData
  });
};

export const confirmDocuments = ({
  citizenship,
  documentTypeId,
  documentNumber,
  language
}) =>
  postAsUriParameters(apiRoutes.confirmDocuments, {
    citizenship,
    document_type_id: documentTypeId,
    doc_number: documentNumber,
    lang: language
  }).then(camelizeKeys);

export const checkEmail = (email) =>
  postAsUriParameters(apiRoutes.checkEmail, { email })
    .then(camelizeKeys)
    .then((response) => {
      const { email: responseEmail = {} } = response || {};
      const { error = '', errorCode = '' } = responseEmail;
      return {
        error: error === 'yes' && !isEmpty(response.email),
        errorCode: errorCode || ''
      };
    });

export const checkInn = (inn) =>
  postAsUriParameters(apiRoutes.checkInn, { inn })
    .then(camelizeKeys)
    .then((response) => {
      const { inn: responseInn = {} } = response || {};
      const { error = '', errorCode = '' } = responseInn;
      return {
        error: error === 'yes' && !isEmpty(response.inn),
        errorCode: errorCode.includes('accounting_error')
          ? errorCode.replace('accounting_error_', '')
          : errorCode || ''
      };
    });

export const emailUnsubscribe = (types) =>
  Promise.all(
    types.map((type) =>
      post('/user/email/subscriptions', {
        action: 'unsubscribe',
        type
      }).then((res) =>
        res.status === 'ok' ? Promise.resolve(true) : Promise.reject()
      )
    )
  ).then((res) => res.every(Boolean));

export const getVerificationOptions = ({ countryCode }) =>
  post({
    mode: 'cors',
    url: apiRoutes.getDocVerificationOptions,
    body: { country_code: countryCode }
  });

export const getDocVerificationFormUrl = ({
  userId,
  partnerId,
  cashdeskId,
  country,
  documentNumber,
  documentTypeId,
  language,
  email,
  serviceId,
  urlSuccess,
  urlFailure
}) =>
  post({
    mode: 'cors',
    url: apiRoutes.getDocVerificationFormUrl,
    body: {
      user_id: userId,
      partner_id: partnerId,
      cashdesk: cashdeskId,
      country,
      doc_number: documentNumber,
      document_type_id: documentTypeId,
      ip: PREDEFINED_IP_WEB_API_ASK_US_TO_USE,
      lang: language,
      email,
      service_id: serviceId,
      url_success: urlSuccess,
      url_failure: urlFailure
    }
  });

export const acceptNewPolicy = (userId) =>
  postAsJson(apiRoutes.acceptNewPolicy, {
    user_id: userId
  });
