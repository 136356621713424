import { useCallback } from 'react';
import { window } from '@namespace/helpers';
import {
  FRESH_CHAT,
  WEBIM_CHAT,
  SENDER_CHAT,
  ZENDESK_CHAT,
  KWIZBOT
} from '../../constants';
import useSupport from '../useSupport';

const shouldShowSupport = (chat) =>
  [SENDER_CHAT, FRESH_CHAT, KWIZBOT, ZENDESK_CHAT].includes(chat);

const useOpenChat = (supportChatName) => {
  const support = useSupport();
  const chatName = supportChatName ?? support.chatName;

  const openChat = useCallback(() => {
    if (shouldShowSupport(chatName)) {
      window.ShowSupport();
    } else if (chatName === WEBIM_CHAT) {
      window.StartWebim();
    }
  }, [chatName]);

  return { openChat };
};

export default useOpenChat;
