import { AText, Box } from '@alf/uikit';
import usePreMatchEventState from '../../../../../../../../hooks/usePreMatchEventState';
import styles from './index.module.css';

const PreMatchEventState = ({ event }) => {
  const { eventDate, eventTime } = usePreMatchEventState(event.eventId);

  return (
    <Box direction="column">
      <AText size="xs" color="line_txt_3" className={styles.eventTime}>
        {eventDate}
      </AText>
      <AText size="xs" color="line_txt_4" className={styles.eventTime}>
        {eventTime}
      </AText>
    </Box>
  );
};

export default PreMatchEventState;
