import { useContext, useEffect, useCallback } from 'react';
import { bulletSubscribe, bulletUnsubscribe } from '@namespace/socket';
import { BONUS_PAYMENT_SERVICE_CURRENCY, UserContext } from '@namespace/user';
import { ConfigContext } from '../../store/config';
import { useUpdateActiveWallet } from '../useUpdateActiveWallet';

const useListenSwitchingWallet = () => {
  const [user, userActions] = useContext(UserContext);
  const { activeWallet = {}, wallets = [] } = user;
  const { walletHash: activeWalletHash, currency } = activeWallet;
  const { SET_ACTIVE_WALLET, FETCH_USER_DATA, CONFIGURE_WALLETS } = userActions;
  const { paymentSystems } = useContext(ConfigContext);

  const { changeActiveWalletHandler } = useUpdateActiveWallet();

  useEffect(() => {
    if (currency === BONUS_PAYMENT_SERVICE_CURRENCY && wallets.length) {
      changeActiveWalletHandler(wallets[0]);
    }
  }, [currency, wallets]);

  const handler = useCallback(
    async ({ wallet_hash: walletHash }) => {
      if (activeWalletHash !== walletHash) {
        if (
          wallets.findIndex(({ walletHash: wH }) => wH === walletHash) === -1
        ) {
          await FETCH_USER_DATA();
          CONFIGURE_WALLETS(paymentSystems);
        }

        SET_ACTIVE_WALLET(walletHash);
      }
    },
    [activeWalletHash, wallets]
  );

  useEffect(() => {
    bulletSubscribe({
      name: 'wallet-bullet',
      types: ['deposit'],
      callback: (data) => {
        if (data.action === 'switch') {
          handler(data);
        }
      }
    });

    return () => bulletUnsubscribe('wallet-bullet');
  }, [handler]);
};

export default useListenSwitchingWallet;
