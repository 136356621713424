import {
  getPageConfigWrapperTypes,
  PageContext,
  useGeneralRoutes,
  useRAMPageCache,
  useSeoBatchPageCache
} from '@namespace/cms';
import { DeviceContext } from '@namespace/device';
import {
  getCookie,
  removeCookie,
  setCookie,
  usePrevious
} from '@namespace/helpers';
import { useHistory } from '@namespace/router';
import {
  useGetUserSegments,
  useUserSegmentsIncludes
} from '@namespace/segments';
import { UserContext, USER_GROUP_TYPES } from '@namespace/user';
import {
  IS_WIDGET,
  POST_MESSAGE_TYPES,
  usePostMessage
} from '@namespace/widget';
import { useContext, useEffect, useRef } from 'react';
import useGetPaths from './useGetPaths';

const { LOGIN } = POST_MESSAGE_TYPES;

const GUEST_LAST_SEEN_TIME_LIMIT = 5 * 60 * 1000; // 5 min
const GUEST_COOKIE_TIME = 60 ** 2 * 1000; // 60 min

export const PageRouterWrapper = ({ FALLBACK_PAGES, children }) => {
  const [user] = useContext(UserContext);
  const { role: userRole, userInfoLoaded } = user;

  const { pageType } = useContext(DeviceContext);
  const { setState, ...state } = useContext(PageContext);
  const { home, lobby, login } = useGeneralRoutes();
  const { push, replace } = useHistory();
  const { postMessage } = usePostMessage();

  const [pathname, rootPath] = useGetPaths();

  const guestLastSeenTime = getCookie('guestLastSeenTime');

  const [getBatchPage] = useRAMPageCache(FALLBACK_PAGES);
  const [getSeoBatchPageConfig] = useSeoBatchPageCache();
  const previousPathname = usePrevious(pathname);
  const { allSegmentsNames } = useGetUserSegments();
  // This hack needed, because setPageState executes in effect before
  // it gets new version of filterSegments with updated segments
  // can be reproduced with redirect after login from guest page to page with user segments.
  const filterSegmentsRef = useRef();
  filterSegmentsRef.current = useUserSegmentsIncludes();

  const setPageState = (newState) => {
    const { userSegments = [] } = newState.page?.pageConfig || {};
    if (userInfoLoaded) {
      const isPageIncludedInSegments = filterSegmentsRef.current(userSegments);
      if (!isPageIncludedInSegments) {
        const shouldRedirectToLoginPage =
          userRole === USER_GROUP_TYPES.GUEST &&
          userSegments.includes(USER_GROUP_TYPES.USER);

        if (shouldRedirectToLoginPage) {
          if (IS_WIDGET) {
            postMessage(LOGIN);
          } else {
            replace(login);
          }
        } else {
          push(home);
        }

        return;
      }
      setState({ ...state, ...newState });
    } else if (!userSegments.length) {
      setState({ ...state, ...newState });
    }
  };

  useEffect(() => {
    (async () => {
      const [config, seoConfig] = await Promise.all([
        getBatchPage(
          rootPath,
          pathname,
          pageType,
          previousPathname && pathname !== previousPathname
        ),
        getSeoBatchPageConfig({ pathname, rootPath })
      ]);

      if (
        pathname.replace(/\/$/, '') === lobby &&
        lobby !== home &&
        userInfoLoaded
      ) {
        if (userRole === USER_GROUP_TYPES.GUEST) {
          if (
            guestLastSeenTime &&
            Date.now() - parseInt(guestLastSeenTime, 10) >
              GUEST_LAST_SEEN_TIME_LIMIT
          ) {
            removeCookie('guestLastSeenTime', null);

            if (pathname.replace(/\/$/, '') !== home) {
              return push(home);
            }
          }

          setCookie('guestLastSeenTime', Date.now(), {
            expires: new Date(new Date().getTime() + GUEST_COOKIE_TIME)
          });
        } else if (userRole === USER_GROUP_TYPES.USER) {
          return push(home);
        }
      }

      if (config) {
        // todo: temporary solution with contextTypes and wrapperTypes,
        //  should be done on CMS side

        const wrapperTypes = getPageConfigWrapperTypes(config);

        // DISABLED DUE TO UA-5073 task
        // const { pageConfig: { userGroup, userSegments } = {} } = config || {};
        // const existSegments = userSegments.filter((e) =>
        //   allSegmentsNames.includes(e)
        // );
        // if (userInfoLoaded) {
        //   if (!existSegments?.length && existSegments === undefined) {
        //     if (
        //       userRole === USER_GROUP_TYPES.USER &&
        //       userGroup === USER_GROUP_TYPES.GUEST
        //     ) {
        //       return push(home);
        //     }
        //
        //     if (
        //       userRole === USER_GROUP_TYPES.GUEST &&
        //       userGroup === USER_GROUP_TYPES.USER
        //     ) {
        //       if (IS_WIDGET) {
        //         return postMessage(LOGIN);
        //       }
        //       return push(login);
        //     }
        //   }
        // }
        setPageState({
          page: { ...config, wrapperTypes },
          seoConfig
        });
      } else if (rootPath.length === 1) {
        return push(lobby);
      } else {
        setPageState({
          page: {},
          seoConfig
        });
      }

      return null;
    })();
    // Don't use userRole for effect dependency
    // if someone what to change role than he must to redirect to properly page by himself
  }, [
    rootPath,
    pageType,
    pathname,
    home,
    login,
    lobby,
    userInfoLoaded,
    allSegmentsNames?.length
  ]);

  return children;
};
