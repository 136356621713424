import { useContext } from 'react';
import { DeviceContext } from '@namespace/device';
import { useGetPathParams } from '../../../hooks/useGetPathParams';
import BasePopularEventsCards from './BasePopularEventsCards';
import { MODE } from '../../../constants';

const PreMatchPopularBetsEventsCards = ({ id }) => {
  const { isMobile } = useContext(DeviceContext);
  const { activeSport } = useGetPathParams(MODE.PRE_MATCH);

  if (isMobile && !activeSport) {
    return null;
  }

  return <BasePopularEventsCards id={id} sport={activeSport} />;
};

export default PreMatchPopularBetsEventsCards;
