import { useCallback, useEffect, useState } from 'react';
import { AText, Box, Button } from '@alf/uikit';
import { Image } from '@namespace/icons2';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import useSearchParams from '@namespace/router/src/hooks/useSearchParams';
import { emailUnsubscribe } from '@namespace/user';
import { useGeneralRoutes } from '@namespace/cms';
import styles from './index.module.css';

const EmailUnsubscribe = () => {
  const { t } = useI18n();
  const { push, replace } = useHistory();
  const { lobby, unsubscribe } = useGeneralRoutes();
  const [unsubscriptionDone, setUnsubscriptionDone] = useState(false);
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const { types = '' } = useSearchParams();

  useEffect(() => {
    if (types.length) {
      setSubscriptionTypes(types.split(','));
      replace(unsubscribe);
    }
  }, [types, unsubscribe]);

  const handleUnsubscribe = useCallback(async () => {
    if (!unsubscriptionDone) {
      const result = await emailUnsubscribe(subscriptionTypes);
      return setUnsubscriptionDone(result);
    }
    return push(lobby);
  }, [subscriptionTypes, unsubscriptionDone, lobby]);

  return (
    <Box className={styles.container}>
      <Box direction="column" align="center">
        <Image
          name={unsubscriptionDone ? 'general/success' : 'general/email'}
          alt=""
          className={styles.icon}
        />
        <AText breed="BU" size="2xl" color="txt_color_3" align="center">
          {unsubscriptionDone ? t('unsubscribe.step2') : t('unsubscribe.step1')}
        </AText>
        <Button
          intent="secondary"
          actionType="color3"
          className={styles.submit}
          onClick={handleUnsubscribe}
        >
          <AText>
            {unsubscriptionDone
              ? t('unsubscribe.home')
              : t('unsubscribe.submit')}
          </AText>
        </Button>
      </Box>
    </Box>
  );
};

export default EmailUnsubscribe;
