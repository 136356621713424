import { useGetVirtualSportsFeedName } from '../../hooks/useGetVirtualSportsFeedName';
import { useLoadVirtualSportsData } from '../../hooks/useLoadVirtualSportsData';

// TODO: Remove wrapper, move loading to component
const VirtualSports = () => {
  const virtualSportsFeedName = useGetVirtualSportsFeedName();
  useLoadVirtualSportsData({ feedName: virtualSportsFeedName });

  return null;
};

export default VirtualSports;
