import { useReducer, createContext } from 'react';
import referenceReducer from '../reducer';

const initialState = {
  phoneCodes: [],
  countries: [],
  secretQuestions: {},
  registrationCountries: []
};

export const ReferenceContext = createContext(initialState);

export const ReferenceProvider = ({ children }) => {
  const [
    {
      phoneCodes = [],
      fullCountry: countries = [],
      regCountry: registrationCountries = [],
      questions: secretQuestions = {}
    },
    referenceDispatch
  ] = useReducer(referenceReducer, initialState);

  return (
    <ReferenceContext.Provider
      value={{
        phoneCodes,
        countries,
        secretQuestions,
        registrationCountries,
        referenceDispatch
      }}
    >
      {children}
    </ReferenceContext.Provider>
  );
};
