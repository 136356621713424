import { forwardRef } from 'react';
import { Icon } from '@namespace/icons2';
import Box from '../Box';
import styles from './index.module.css';

const NavigationDrawer = forwardRef(
  (
    {
      classNames = { wrapper: '', title: '', body: '', footer: '', close: '' },
      onClose,
      title = null,
      body = null,
      footer = null,
      icon
    },
    ref
  ) => (
    <Box
      className={`${styles.wrapper} ${classNames.wrapper}`}
      direction="column"
    >
      <Box
        className={`${styles.titleWrapper} ${classNames.title}`}
        align="center"
      >
        {title}
        <Box
          component="span"
          align="center"
          onClick={onClose}
          className={`${styles.closeIcon} ${classNames.close}`}
        >
          {icon || (
            <Icon
              name="icons/general/action/close"
              size="m"
              color="bg_color_7"
            />
          )}
        </Box>
      </Box>
      {body && (
        <Box ref={ref} className={`${styles.body} ${classNames.body}`}>
          {body}
        </Box>
      )}
      {footer && (
        <Box className={`${styles.footer} ${classNames.footer}`}>{footer}</Box>
      )}
    </Box>
  )
);

export default NavigationDrawer;
