import PropTypes from 'prop-types';
import clsx from 'clsx';
import AText from '../AText';
import styles from './index.module.css';

const intents = {
  primary: {
    breed: 'B',
    size: 'l'
  },
  secondary: {
    breed: 'R',
    size: 's'
  }
};

const Link = ({
  children = '',
  Component,
  to,
  intent = 'primary',
  className = '',
  target = '',
  ...props
}) => (
  <Component to={to} target={target}>
    <AText
      {...intents[intent]}
      className={clsx(styles.general, styles[intent], className)}
      {...props}
    >
      {children}
    </AText>
  </Component>
);

Link.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  to: PropTypes.string,
  Component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  intent: PropTypes.oneOf(['primary', 'secondary'])
};

export default Link;
