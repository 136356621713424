import { useCallback, useContext, useState } from 'react';
import { DeviceContext } from '@namespace/device';
import { Select } from '@alf/uikit';
import { COLOR_SCHEME } from '@namespace/themes';
import styles from './index.module.css';

const LineDropdown = ({ options, onChange }) => {
  const { isDesktop } = useContext(DeviceContext);
  const formattedOptions = options.map(({ value, name }) => ({
    value,
    title: name
  }));

  const [currentValue, setCurrentValue] = useState(formattedOptions[0].value);

  const onChangeSelect = useCallback(
    (e) => {
      setCurrentValue(e.value);
      onChange(e.value);
    },
    [onChange]
  );

  return (
    <Select
      value={currentValue}
      isFullWidth={true}
      options={formattedOptions}
      onChange={onChangeSelect}
      isDesktop={isDesktop}
      className={styles.lineSelect}
      intentType={COLOR_SCHEME.SECONDARY}
    />
  );
};

export default LineDropdown;
