import { useContext, useCallback } from 'react';
import { useGeneralRoutes } from '@namespace/cms';
import { USER_ROLES, UserContext } from '@namespace/user';
import { REDIRECT_PARAM } from '@namespace/common';

export const useGetVsGameUrl = () => {
  const { login, virtualSportsFrame, virtualSports } = useGeneralRoutes();
  const [user] = useContext(UserContext);
  const { role } = user;

  return useCallback(
    (game) => {
      const { gameCode, provider: gameProvider } = game;
      const launchLoginLink = `${login}?${REDIRECT_PARAM}${virtualSportsFrame}/${gameProvider}/${gameCode}`;
      const launchLink = `${virtualSportsFrame}/${game.provider}/${gameCode}`;

      if (role === USER_ROLES.USER && launchLink) {
        return launchLink;
      }

      if (role === USER_ROLES.GUEST && launchLoginLink) {
        return launchLoginLink;
      }

      return virtualSports;
    },
    [login, virtualSportsFrame, role, virtualSports]
  );
};
