import { useMemo } from 'react';
import { SportsSlider } from '@namespace/common';
import { useComponentConfig, useGeneralRoutes } from '@namespace/cms';
import { getSportIcon } from '@namespace/icons2';
import {
  useCyberSportCategories,
  useFavoriteEventsCounter,
  useFavorites
} from '../../store';
import { useCyberSportAllEventsCount } from '../../hooks/useCyberSportAllEventsCount';
import { MODE, SPORT_IDS } from '../../constants';
import { sortFavoriteSports } from '../../utils';

export const CyberSportSlider = () => {
  const { isShowAll = false, isWithPadding = false } = useComponentConfig();
  const {
    cyberSportCategory: routeBase,
    cyberSportFavorites,
    cyberSport: cyberSportAll
  } = useGeneralRoutes();
  const categories = useCyberSportCategories();
  const allCount = useCyberSportAllEventsCount();
  const favoritesCount = useFavoriteEventsCounter(
    [MODE.PRE_MATCH, MODE.IN_PLAY],
    SPORT_IDS.CYBERSPORT
  );
  const favoriteCategories = useFavorites('categories');
  const items = useMemo(
    () => sortFavoriteSports(categories, favoriteCategories),
    [categories, favoriteCategories]
  );

  return (
    <SportsSlider
      items={items}
      routeBase={routeBase}
      routeAll={cyberSportAll}
      routeFavorites={cyberSportFavorites}
      isShowAll={isShowAll}
      isWithPadding={isWithPadding}
      favoritesCount={favoritesCount}
      allCount={allCount}
      allSportIcon={getSportIcon(SPORT_IDS.CYBERSPORT)}
      allSportsTKey="sportsbook.cybersport.allSports"
      favoritesTKey="sportsbook.cybersport.favorites"
    />
  );
};
