import { useContext, useEffect } from 'react';
import { UserContext, USER_ROLES } from '@namespace/user';
import {
  getLocalStorageValue,
  setLocalStorageValue,
  LOCAL_STORAGE_KEYS
} from '@namespace/helpers';
import { JackpotsContext } from '../../jackpotsContext';
import useGetInHouseJackpotSettings from '../useGetInHouseJackpotSettings';

const getIsDelayTimePassed = (casinoShowDelayMinutes) => {
  const showDelayInMs = casinoShowDelayMinutes * 60 * 1000;

  const currentDateTime = new Date().getTime();
  const lastTimeChecked = getLocalStorageValue(
    LOCAL_STORAGE_KEYS.CASINO_JP_WIN_LAST_CHECKED,
    0
  );
  return currentDateTime - lastTimeChecked > showDelayInMs;
};

export const useCheckInHouseJpWinsWithDelay = (showInhouseJackpot) => {
  const [, { GET_IN_HOUSE_JACKPOT_WINS }] = useContext(JackpotsContext);
  const [user] = useContext(UserContext);
  const { winNotification = {} } = useGetInHouseJackpotSettings();
  const { casinoShowDelayMinutes = 30 } = winNotification;
  const isLoggedInUser = user.role === USER_ROLES.USER;

  useEffect(() => {
    const isLogicEnabled =
      showInhouseJackpot && winNotification.isShowOnCasino && isLoggedInUser;

    const isDelayTimePassed = isLogicEnabled
      ? getIsDelayTimePassed(casinoShowDelayMinutes)
      : false;

    if (isLogicEnabled && isDelayTimePassed) {
      GET_IN_HOUSE_JACKPOT_WINS();
      setLocalStorageValue(
        LOCAL_STORAGE_KEYS.CASINO_JP_WIN_LAST_CHECKED,
        new Date().getTime()
      );
    }
  }, [
    GET_IN_HOUSE_JACKPOT_WINS,
    casinoShowDelayMinutes,
    isLoggedInUser,
    showInhouseJackpot,
    winNotification.isShowOnCasino
  ]);
};
