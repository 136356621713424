export default `
        <style>
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 var(--b-spacing-m);
}

.footer-copyright {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: var(--b-font-size-xs);
  font-weight: bold;
  color: var(--txt_color_6);
  opacity: .8;
}

.contentWrapper {
  display: flex; 
  justify-content: center;
  width: 100%;
  font-weight: 700;
  font-size: var(--b-font-size-m);
  line-height: 22px;
  color: var(--footer_txt_2);
  margin-bottom: var(--b-spacing-m);
}

.emailWrapper { 
  display: flex; 
  align-items: center;
  margin-bottom: 4px;
}

.footerEmailWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: -10px;
}

.secondManagerWrapper {
  display: flex;
  flex-direction: column;
}

.emailIcon {
  margin-right: 8px;
}

@media screen and (max-width:1024px) {

  .footer {
    flex-direction: column;
    max-width: 540px;
    align-items: center;
  }

  .footer-copyright {
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .footer {
    padding-bottom: 0;
  }
}

    </style>
    <div class="footer">
       <div class='contentWrapper'>
          <div class='footerEmailWrapper'>
             <a href="mailto:altyntop2023@gmail.com" class='emailWrapper'>
              <img class="emailIcon" src="/snippets-media/footer/mail.svg" alt=""/>
              <span>altyntop2023@gmail.com</span>
             </a>
          </div>
      </div>
      <div class="footer-copyright">© 2023 Altyntop. Все права защищены.</div>
    </div>
  `;
