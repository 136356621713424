import { groupBy, pickBy, uniq } from 'lodash';
import { LINE_KEY } from '../../../../constants';
import { incorrectDataWorkAround } from '../../../normalizers';

export const outcomesUpdate = (state, data = []) => {
  const updatedData = data.reduce((memo, { outcomes = [] }) => {
    const updatedOutcomes = outcomes.reduce((acc, outcome) => {
      const stateOutcome = state.outcomes[outcome.outcomeId];

      if (stateOutcome) {
        return {
          ...acc,
          [outcome.outcomeId]: { ...stateOutcome, ...outcome }
        };
      }
      return acc;
    }, {});

    return { ...memo, ...updatedOutcomes };
  }, {});

  return {
    ...state,
    outcomes: { ...state.outcomes, ...updatedData }
  };
};

export const marketInsert = (state, markets = []) => {
  const marketsByEvent = groupBy(markets, 'eventId');

  const events = markets.reduce((acc, { eventId }) => {
    const eventToUpdate = acc[eventId];
    return eventToUpdate
      ? {
          ...acc,
          [eventId]: {
            ...eventToUpdate,
            marketCount: eventToUpdate.marketCount + 1
          }
        }
      : acc;
  }, {});

  const eventsMarkets = markets.reduce(
    (acc, { marketId, eventId, outcomes = [], ...rest }) => ({
      ...acc,
      [marketId]: {
        eventId,
        marketId,
        outcomeIds: outcomes.map(({ outcomeId }) => outcomeId),
        ...incorrectDataWorkAround(rest)
      }
    }),
    {}
  );

  const eventsOutcomes = markets.reduce((memo = {}, { outcomes }) => {
    const newOutcomes = outcomes.reduce(
      (acc, outcome) => ({
        ...acc,
        [outcome.outcomeId]: outcome
      }),
      {}
    );
    return {
      ...memo,
      ...newOutcomes
    };
  }, {});

  const lines = Object.entries(state.lines).reduce(
    (acc, [lineKey, { eventsIds, eventsMarketsIds, ...rest }]) => {
      const updatedEventsMarketsIds = Object.keys(marketsByEvent).reduce(
        (memo = {}, eventId) => {
          if (eventsIds?.includes(Number(eventId))) {
            const isDetailEvent = lineKey.includes(LINE_KEY.DETAIL_EVENT);
            const list = isDetailEvent
              ? marketsByEvent[eventId]
              : marketsByEvent[eventId].filter(
                  (m) =>
                    state.headGroupsBySportId[m.sportId][m.marketTemplateId]
                );
            const newIds = list.map(({ marketId }) => marketId);
            return {
              ...memo,
              [eventId]: uniq([...(eventsMarketsIds[eventId] || []), ...newIds])
            };
          }
          return memo;
        },
        {}
      );
      return {
        ...acc,
        [lineKey]: {
          eventsIds,
          eventsMarketsIds: {
            ...eventsMarketsIds,
            ...updatedEventsMarketsIds
          },
          ...rest
        }
      };
    },
    {}
  );

  return {
    ...state,
    ...(Object.keys(events).length
      ? { events: { ...state.events, ...events } }
      : {}),
    markets: { ...state.markets, ...eventsMarkets },
    lines,
    outcomes: { ...state.outcomes, ...eventsOutcomes }
  };
};

export const marketUpdate = (state, markets = []) => {
  const eventsMarkets = markets.reduce(
    (acc, { marketId, eventId, outcomes = [], ...rest }) => ({
      ...acc,
      [marketId]: {
        eventId,
        marketId,
        ...(state.markets[marketId] || {}),
        outcomeIds: outcomes.map(({ outcomeId }) => outcomeId),
        ...incorrectDataWorkAround(rest)
      }
    }),
    {}
  );

  const eventsOutcomes = markets.reduce((memo, { outcomes = [] }) => {
    const updatedOutcomes = outcomes.reduce((acc, outcome) => {
      const stateOutcome = state.outcomes[outcome.outcomeId];

      return {
        ...acc,
        [outcome.outcomeId]: { ...stateOutcome, ...outcome }
      };
    }, {});

    return { ...memo, ...updatedOutcomes };
  }, {});
  return {
    ...state,
    markets: { ...state.markets, ...eventsMarkets },
    outcomes: { ...state.outcomes, ...eventsOutcomes }
  };
};

export const marketDelete = (state, markets = []) => {
  const marketsIdsByEvent = markets.reduce(
    (memo, { eventId, marketId }) => ({
      ...memo,
      [eventId]: [...(memo[eventId] || []), marketId]
    }),
    {}
  );
  const marketsIds = markets.map(({ marketId }) => marketId);

  const outcomesIdsByMarkets = markets.reduce((acc, { marketId }) => {
    const { outcomeIds = [] } = state.markets[marketId] || {};
    return [...acc, ...outcomeIds];
  }, []);

  const updatedEvents = markets.reduce((acc, { eventId }) => {
    const eventToUpdate = acc[eventId];
    return eventToUpdate
      ? {
          ...acc,
          [eventId]: {
            ...eventToUpdate,
            marketCount: eventToUpdate.marketCount - 1
          }
        }
      : acc;
  }, {});

  const updatedLines = Object.entries(state.lines).reduce(
    (acc, [lineKey, { eventsMarketsIds, ...rest }]) => ({
      [lineKey]: {
        eventsMarketsIds: Object.keys(marketsIdsByEvent).reduce(
          (memo = {}, eventId) =>
            eventsMarketsIds[eventId]
              ? {
                  ...memo,
                  [eventId]: eventsMarketsIds[eventId].filter(
                    (id) => !marketsIdsByEvent[eventId].includes(Number(id))
                  )
                }
              : memo,
          eventsMarketsIds
        ),
        ...rest
      },
      ...acc
    }),
    {}
  );

  return {
    ...state,
    markets: pickBy(
      state.markets,
      (e, key) => !marketsIds.includes(Number(key))
    ),
    lines: updatedLines,
    outcomes: pickBy(
      state.outcomes,
      (e, key) => !outcomesIdsByMarkets.includes(Number(key))
    ),
    ...(Object.keys(updatedEvents).length
      ? { events: { ...state.events, ...updatedEvents } }
      : {})
  };
};

export const marketSuspend = (state, { data = [], isSuspended }) => {
  const groupedDataByMarkets = groupBy(data, 'marketId');
  const updatedMarkets = data.reduce((acc, { marketId }) => {
    const stateMarket = state.markets[marketId] || {};
    const marketSuspended = Object.keys(groupedDataByMarkets).includes(
      marketId.toString()
    )
      ? isSuspended
      : stateMarket.marketSuspend;

    return {
      ...acc,
      [marketId]: {
        marketId,
        ...stateMarket,
        marketSuspend: marketSuspended
      }
    };
  }, {});

  return {
    ...state,
    markets: { ...state.markets, ...updatedMarkets }
  };
};
