import { useContext, useEffect } from 'react';
import { Box } from '@alf/uikit';
import clsx from 'clsx';
import { useUserSegmentsIncludes } from '@namespace/segments';
import { useMobileNavMenuSettings, useOtherSettings } from '@namespace/cms';
import { USER_ROLES, UserContext } from '@namespace/user';
import {
  ACCOUNTING_BONUS_TYPES,
  OTHER_BONUS_TYPES,
  BONUS_SYSTEM_BONUS_TYPE,
  useGetBonusesCounters
} from '@namespace/bonuses';
import { useGetInHouseJackpotSettings } from '@namespace/games';

import ProfileMenuItem from '../../../common/ProfileMenuItem';

import { MOBILE_MENU_TILES_TYPE } from '../../../constants';

import styles from './index.module.css';

const ProfileMenuItems = ({
  onClick,
  CustomProfileMenuItem,
  className = ''
}) => {
  const mobileNavigation = useMobileNavMenuSettings();
  const { mobileUserMenu = [] } = mobileNavigation;
  const [{ role }] = useContext(UserContext);
  const isGuest = role === USER_ROLES.GUEST;
  const { isPreWagerBonusesEnabled = false } = useOtherSettings();
  const { isJackpotBonusTabEnabled } = useGetInHouseJackpotSettings();
  const fetchCounters = useGetBonusesCounters([
    ACCOUNTING_BONUS_TYPES.ALL,
    ACCOUNTING_BONUS_TYPES.RISK_FREE,
    ACCOUNTING_BONUS_TYPES.REAL_MONEY,
    ACCOUNTING_BONUS_TYPES.FREE_SPIN,
    ...(isPreWagerBonusesEnabled ? [BONUS_SYSTEM_BONUS_TYPE] : []),
    ...(isJackpotBonusTabEnabled ? [OTHER_BONUS_TYPES.JACKPOT_WINS] : [])
  ]);

  useEffect(() => {
    fetchCounters();
  }, []);

  const FinalProfileMenuItem = CustomProfileMenuItem || ProfileMenuItem;

  const filteredSegments = useUserSegmentsIncludes();

  const segmentedMobileUserMenu = mobileUserMenu.filter(({ userSegments }) =>
    filteredSegments(userSegments)
  );

  return (
    <Box
      className={clsx(
        styles.container,
        isGuest && styles.guest,
        className,
        segmentedMobileUserMenu.length === 1 && styles.soloItemContainer
      )}
    >
      {segmentedMobileUserMenu.map((item) => (
        <FinalProfileMenuItem
          item={item}
          key={item.title}
          menuType={MOBILE_MENU_TILES_TYPE.PROFILE_MENU}
          onClick={onClick}
          classNames={{ containerClassName: styles.soloItem }}
        />
      ))}
    </Box>
  );
};

export default ProfileMenuItems;
