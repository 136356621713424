import { rePatterns } from '../../patterns';

const ifcCodeValidator = (message = 'validators.ifcCodeFormatValidator') => ({
  pattern: {
    value: rePatterns.ifcCode,
    message
  }
});

export default ifcCodeValidator;
