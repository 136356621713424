import { useContext } from 'react';
import { Icon } from '@namespace/icons2';
import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { window } from '@namespace/helpers';
import clsx from 'clsx';
import { NativeAppContext } from '../../store';
import styles from './index.module.css';

const NativeAppsLink = ({
  className = '',
  isDisabled = false,
  isCustomFooter
}) => {
  const [{ androidLink, iosLink }] = useContext(NativeAppContext);
  const { t } = useI18n();

  return (
    <Box
      data-role="nativeAppsLink"
      className={clsx([styles.container, className])}
    >
      {!!iosLink && (
        <Box
          align="center"
          gap="s"
          className={clsx([
            styles.linkContainer,
            isCustomFooter && styles.tabletFooter
          ])}
          onClick={() => !isDisabled && window.open(iosLink, '_blank')}
        >
          <div>
            <Icon
              name="icons/user_menu/footer/app-store"
              color="footer_bg_color_1"
              size="l"
            />
          </div>
          <Box direction="column">
            <AText color="footer_bg_color_1" size="xs">
              {t('web.nativebadges.ios.download')}
            </AText>
            <AText color="footer_bg_color_1" size="m" breed="B">
              App Store
            </AText>
          </Box>
        </Box>
      )}
      {!!androidLink && (
        <Box
          align="center"
          gap="s"
          className={clsx([
            styles.linkContainer,
            isCustomFooter && styles.tabletFooter
          ])}
          onClick={() => !isDisabled && window.open(androidLink, '_self')}
        >
          <div>
            <Icon
              name="icons/user_menu/footer/android"
              color="footer_bg_color_1"
              size="l"
            />
          </div>
          <Box direction="column">
            <AText color="footer_bg_color_1" size="xs">
              {t('web.nativebadges.android.download')}
            </AText>
            <AText color="footer_bg_color_1" size="m" breed="B">
              Android
            </AText>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default NativeAppsLink;
