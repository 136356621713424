import { useMemo } from 'react';
import { AText, Box, Select } from '@alf/uikit';

const Selected = ({
  label,
  options,
  onChange,
  className,
  isDesktop,
  disabled,
  arrowConfig,
  value,
  dataRole
}) => {
  const newOptions = useMemo(
    () =>
      options.map(({ name: title, value: v }) => ({
        title,
        value: String(v)
      })),
    [options]
  );
  const {
    filterArrowName = 'icons/general/nav/dropdown_down',
    arrowSize
  } = arrowConfig;
  return (
    <Box bottom="s" direction="column">
      <AText
        data-role={dataRole && `${dataRole}-label`}
        className={className}
        color="po_txt_color_1"
      >
        {label}
      </AText>
      <Select
        options={newOptions}
        iconDropDownName={filterArrowName}
        arrowSize={arrowSize}
        dataRole={dataRole}
        size="s"
        value={String(value)}
        disabled={disabled}
        onChange={onChange}
        isDesktop={isDesktop}
      />
    </Box>
  );
};

export default Selected;
