export const TYPES = {
  PRE_BET: 0,
  LIVE: 1,
  ALL: [0, 1]
};

export const TITLES = {
  [TYPES.PRE_BET]: 'sportsbookSearch.prebetTitle',
  [TYPES.LIVE]: 'sportsbookSearch.liveTitle'
};
