import { Box, Radio } from '@alf/uikit';
import { useFormContext } from 'react-hook-form';
import { useI18n } from '@namespace/i18n';
import clsx from 'clsx';
import getFormError from '../../utils/getFormError';
import Control from '../Control';
import useDynamicField from '../../hooks/useDynamicField';
import useCustomError from '../../hooks/useCustomError';
import useOnChangeValidate from '../../hooks/useOnChangeValidate';
import styles from './index.module.css';

const RadioGroup = ({
  name,
  label = '',
  hint,
  elements = [],
  rules = {},
  classNames = {},
  isOnChangeValidation = false,
  customError = '',
  formProps = {},
  direction = 'row',
  disabled: isFieldDisabled,
  gap = 'm',
  size,
  wrap = false,
  dataRole
}) => {
  const {
    wrapperClassName = '',
    groupLabelClassName = '',
    groupClassName = '',
    labelClassName = '',
    activeLabelClassName = '',
    labelTextClassName = '',
    ...fieldClassNames
  } = classNames;
  const { t } = useI18n();
  const methods = useFormContext();
  const {
    register,
    formState: { errors },
    getValues
  } = methods;
  const { [name]: currentValue } = getValues();
  const { errorMessage, customErrorHandler } = useCustomError(customError);
  const { dynamicValues = {} } = formProps;

  useDynamicField({ name, dynamicFieldData: dynamicValues[name] });
  useOnChangeValidate({ name, isOnChangeValidation });

  return (
    <Control
      error={getFormError(name, errors, t) || t(errorMessage)}
      onClick={customErrorHandler}
      hint={hint}
      label={t(label)}
      classNames={{
        ...classNames,
        wrapperClassName,
        labelClassName: groupLabelClassName
      }}
    >
      <Box
        align={direction === 'row' ? 'center' : 'start'}
        direction={direction}
        wrap={wrap}
        className={`${styles[direction]} ${groupClassName}`}
      >
        {elements.map(({ label: radioLabel, value, disabled, ...rest }) => {
          const { ref, ...registerProps } = register(name, { ...rules });

          return (
            <Radio
              size={size}
              key={`radio-${value}`}
              inputRef={ref}
              name={name}
              label={typeof label === 'string' ? t(radioLabel) : radioLabel}
              value={value}
              disabled={disabled ?? isFieldDisabled}
              isActive={currentValue === value}
              classNames={{
                wrapperClassName: clsx([
                  styles.radio,
                  labelClassName,
                  styles[`gap_${gap}`]
                ]),
                activeLabelClassName,
                labelTextClassName
              }}
              dataRole={`${dataRole}-${value}`}
              {...registerProps}
              {...rest}
              {...fieldClassNames}
            />
          );
        })}
      </Box>
    </Control>
  );
};

export default RadioGroup;
