import { BET_TYPES } from '@namespace/bets';

const formatVariables = ({ betType, selectedSystems = [] }) => {
  if (betType === BET_TYPES.SYSTEM) {
    return { count_variants: selectedSystems[0] };
  }
  if (betType === BET_TYPES.MULTI_SYSTEM) {
    return { selected_variants: selectedSystems };
  }

  return {};
};

export default formatVariables;
