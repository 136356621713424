import CommonTopEventsLink from './CommonTopEventsLink';
import { useEventLink } from '../../hooks/useEventLink';

const EventLink = ({
  growsMap,
  growIndex,
  isShowImages,
  event = {},
  icon,
  presetId,
  translation
}) => {
  const { eventId } = event;
  const link = useEventLink({ eventId, isTopEvents: true });

  return (
    <CommonTopEventsLink
      growsMap={growsMap}
      growIndex={growIndex}
      isShowImages={isShowImages}
      icon={icon}
      presetId={presetId}
      translation={translation}
      link={link}
    />
  );
};

export default EventLink;
