import { useMemo } from 'react';
import { useComponentConfig, useGeneralRoutes } from '@namespace/cms';
import { SportsSlider } from '@namespace/common';
import { MODE } from '../../../constants';
import {
  useFavoriteEventsCounter,
  useFavorites,
  useSportsEventCount
} from '../../../store';
import { useFilteredSports } from '../../../hooks/useFilteredSports';
import { sortFavoriteSports } from '../../../utils';

export const LiveSlider = () => {
  const { isShowAll = false } = useComponentConfig();
  const {
    liveSport: routeBase,
    liveFavorites,
    liveSportAll
  } = useGeneralRoutes();
  const filteredSports = useFilteredSports(MODE.IN_PLAY);
  const allCount = useSportsEventCount([MODE.IN_PLAY]);
  const favoritesCount = useFavoriteEventsCounter([MODE.IN_PLAY]);

  const favoritesSports = useFavorites('sports');
  const items = useMemo(
    () => sortFavoriteSports(filteredSports, favoritesSports),
    [filteredSports, favoritesSports]
  );

  return (
    <SportsSlider
      items={items}
      routeBase={routeBase}
      routeAll={liveSportAll}
      routeFavorites={liveFavorites}
      isShowAll={isShowAll}
      favoritesCount={favoritesCount}
      allCount={allCount}
    />
  );
};
