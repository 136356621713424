import { useContext, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useScreenSize } from '@namespace/device';
import { SiteSettingsContext } from '@namespace/cms';
import { LOG_ACTIONS, logAction } from '@namespace/analytics';
import { useJackpot } from '../../../../context/selectors';
import { getJackpotIdentifier } from './utils';
import { DISPLAY_TYPES, JACKPOT_IDENTIFIERS } from './constants';
import { LARGE_SCREEN_WIDTH } from '../../../../constants';

import { JackpotSingleRow } from './JackpotSingleRow';
import { JackpotMultipleRows } from './JackpotMultipleRows';

import { PateplayJackpot } from './PateplayJackpot';

const JackpotByProvider = ({
  providerId: providedServiceId,
  jackpotAction,
  jackpotImage,
  jackpotCode,
  displayType,
  showJackpotWidget
}) => {
  const [settings] = useContext(SiteSettingsContext);
  const { siteConfig = {} } = settings;
  const { defaultJackpotCurrency } = siteConfig;
  const jackpot = useJackpot(providedServiceId, jackpotCode);
  const screenSize = useScreenSize(true);

  const isWidgetHidden = useMemo(
    () => isEmpty(jackpot) || !displayType || !showJackpotWidget,
    [displayType, jackpot, showJackpotWidget]
  );

  if (isWidgetHidden) return null;

  // force to use multiple rows display for small devices
  // started use own const value, take a look at it
  const actualDisplayType =
    screenSize < LARGE_SCREEN_WIDTH ? DISPLAY_TYPES.MULTIPLE : displayType;

  const jackpotIdentifier = getJackpotIdentifier(
    providedServiceId,
    jackpotCode
  );

  const jackpotBaseProps = {
    jackpot,
    jackpotIdentifier,
    jackpotAction,
    jackpotImage,
    providedServiceId,
    currency: defaultJackpotCurrency
  };

  const handleJackpotClick = (action) => {
    logAction(LOG_ACTIONS.BANNER_CLICK, {
      locationId: 'Casino || Razdel_banner',
      promotionPath: action
    });
  };

  // Pateplay jackpot
  if (jackpotIdentifier === JACKPOT_IDENTIFIERS.PATEPLAY.toString()) {
    return (
      <PateplayJackpot
        {...jackpotBaseProps}
        handleJackpotClick={handleJackpotClick}
      />
    );
  }

  // EGT / CT Gaming  jackpots (single row / multiple rows)
  if (actualDisplayType === DISPLAY_TYPES.MULTIPLE) {
    return (
      <JackpotMultipleRows
        {...jackpotBaseProps}
        handleJackpotClick={handleJackpotClick}
      />
    );
  }

  if (actualDisplayType === DISPLAY_TYPES.SINGLE)
    return (
      <JackpotSingleRow
        {...jackpotBaseProps}
        handleJackpotClick={handleJackpotClick}
      />
    );

  return null;
};

export default JackpotByProvider;
