import { useContext } from 'react';
import { Language as CoreLanguage } from '@namespace/layout';
import { Box } from '@alf/uikit';
import { DeviceContext } from '@namespace/device';
import styles from './index.module.css';

const Language = ({ id }) => {
  const { isDesktop } = useContext(DeviceContext);

  return isDesktop ? (
    <Box className={styles.languageWrapper}>
      <CoreLanguage id={id} />
    </Box>
  ) : (
    <CoreLanguage id={id} isFooter={!isDesktop} />
  );
};

export default Language;
