import { USER_STATUSES_REMAP } from '../../constants';

const normalizeAccountStatuses = (accountStatus = []) =>
  accountStatus.reduce(
    (memo, { alias, value }) =>
      USER_STATUSES_REMAP[alias]
        ? {
            ...memo,
            [USER_STATUSES_REMAP[alias]]: value
          }
        : memo,
    {}
  );

export default normalizeAccountStatuses;
