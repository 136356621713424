import { useMemo } from 'react';
import { useGeneralRoutes } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { MODE } from '../../../constants';
import { useFilteredSports } from '../../../hooks/useFilteredSports';
import { useFavorites } from '../../../store';
import { useGrid } from './useGrid';
import { SportsGrid } from '../../../common';
import { sortFavoriteSports } from '../../../utils';
import { AdvFilter, AdvFilterButton } from '../../AdvancedFilter';
import { useAdvFilterStore } from '../../../storeAdvFilter';

const PreMatchGrid = () => {
  const { t } = useI18n();
  const { preMatchSport } = useGeneralRoutes();
  const filteredSports = useFilteredSports(MODE.PRE_MATCH);
  const favoritesSports = useFavorites('sports');
  const isAdvFilterOpened = useAdvFilterStore('isAdvFilterOpened');
  const advFilter = useAdvFilterStore('advFilter');
  const { isShowAllSports, onToggle, list } = useGrid(
    sortFavoriteSports(filteredSports, favoritesSports),
    { showCustomFilter: advFilter?.length > 0 }
  );

  const sportsList = useMemo(
    () =>
      list.map(({ sportId, sportName, sportSlug, eventCount, isPinned }) => ({
        sportId,
        name: sportName,
        slug: sportSlug,
        count: eventCount,
        isPinned
      })),
    [list]
  );

  if (isAdvFilterOpened && advFilter != null) {
    return <AdvFilter />;
  }

  return (
    <SportsGrid
      title={t('sportsmenu.allSports')}
      titleContent={<AdvFilterButton showTitle={true} />}
      list={sportsList}
      baseRoute={preMatchSport}
      isShowAllSports={isShowAllSports}
      onToggle={onToggle}
    />
  );
};

export default PreMatchGrid;
