import { useCallback, useId } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import clsx from 'clsx';
import CheckedCheckbox from './img/checked-checkbox.svg';
import UnCheckedCheckbox from './img/un-checked-checkbox.svg';
import Box from '../Box';
import AText from '../AText';
import styles from './index.module.css';

const Checkbox = ({
  label,
  className,
  inputRef = null,
  onChange = noop,
  onBlur = noop,
  onClick = noop,
  checked = false,
  disabled = false,
  size = 's',
  name = '',
  iconElement,
  classNames = {},
  truncateText = false,
  dataRole = 'checkbox',
  ...props
}) => {
  const id = useId('checkbox-');
  const {
    checkboxInputClassName,
    checkboxClassName,
    checkedClassName,
    labelClassName
  } = classNames;
  const classes = clsx([
    styles.root,
    className,
    styles[size],
    disabled && styles.disabled,
    checked && clsx(styles.checked, checkedClassName)
  ]);

  const handleChange = useCallback(
    (value) => {
      onChange(value);
      onBlur(value);
    },
    [onChange, onBlur]
  );

  const CheckboxIcon = checked ? CheckedCheckbox : UnCheckedCheckbox;

  return (
    <label htmlFor={id} className={classes}>
      <input
        data-role={dataRole}
        ref={inputRef}
        type="checkbox"
        id={id}
        className={clsx(styles.input, checkboxInputClassName)}
        checked={checked}
        onChange={(e) => handleChange(e.target.checked)}
        onBlur={(e) => onBlur(e.target.checked)}
        onClick={onClick}
        name={name}
        disabled={disabled}
        {...props}
      />
      <Box
        data-role={`${dataRole}-element`}
        className={clsx(styles.checkbox, checkboxClassName)}
        align="center"
      >
        <CheckboxIcon className={styles.checkboxIcon} />
      </Box>
      {(iconElement || label) && (
        <Box gap="xs" className={styles.labelWrapper}>
          {iconElement}
          {label && (
            <AText
              data-role={`${dataRole}-label`}
              className={clsx([
                styles.label,
                labelClassName,
                truncateText && styles.labelTruncated
              ])}
              size={size}
              breed="R"
            >
              {label}
            </AText>
          )}
        </Box>
      )}
    </label>
  );
};

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.element })
  ]),
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  size: PropTypes.oneOf(['s', 'm']),
  classNames: PropTypes.shape({
    checkboxInputClassName: PropTypes.string,
    checkboxClassName: PropTypes.string,
    checkedClassName: PropTypes.string,
    labelClassName: PropTypes.string
  })
};

export default Checkbox;
