import { assoc, when } from 'ramda';
import { Flag } from '@namespace/icons2';
import { useSelectedCategoryIdsBySport } from '../../../../hooks/useSelectedCategoryIdsBySport';
import { useDistinctSelectedCategoryIds } from '../../../../hooks/useDistinctSelectedCategoryIds';
import { updateFilterWithNewCategories } from '../../../../utils';
import { useAdvFilterStore } from '../../../../../../../../storeAdvFilter';
import {
  useAdvFilterInnerCtxActions,
  useAdvFilterInnerCtxStore
} from '../../../../store';
import { useAdvFilterSettings } from '../../../../hooks/useAdvFilterSettings';

export const useCategoriesSelectData = () => {
  const advFilterCategoriesBySportId = useAdvFilterStore(
    'advFilterCategoriesBySportId'
  );
  const filter = useAdvFilterInnerCtxStore('filter');
  const selectedSportId = useAdvFilterInnerCtxStore('selectedSportId');
  const { SET_FILTER_SELECTION } = useAdvFilterInnerCtxActions();
  const { maxCategoriesCount } = useAdvFilterSettings();

  const selectedCategoryIds = useSelectedCategoryIdsBySport();
  const distinctCategoryIds = useDistinctSelectedCategoryIds();

  const categoriesOptionsRaw =
    advFilterCategoriesBySportId[selectedSportId]?.map(
      ({ id, name, countryId }) => ({
        title: name,
        value: String(id),
        iconElement: <Flag flagId={countryId} size="s" />
      })
    ) || [];
  const categoriesOptions =
    distinctCategoryIds.length >= maxCategoriesCount
      ? categoriesOptionsRaw.map(
          when(
            ({ value }) => !selectedCategoryIds.includes(value),
            assoc('disabled', true)
          )
        )
      : categoriesOptionsRaw;

  const onCategoryChange = (v) => {
    const newCategoryIds = (v.value ?? []).map(Number);
    const newFilter = updateFilterWithNewCategories(
      filter,
      newCategoryIds,
      selectedSportId
    );

    SET_FILTER_SELECTION({ filter: newFilter });
  };

  return {
    categoriesOptions,
    selectedCategoryIds,
    onCategoryChange
  };
};
