import { useEffect, useRef } from 'react';
import { useMatchPath } from '@namespace/router';
import { useGeneralRoutes } from '@namespace/cms';
import { useLocation } from '@namespace/i18n';
import { MODE } from '../../constants';
import {
  useCategory,
  useEvent,
  useSportBySportSlug,
  useTournament
} from '../../store';

export const useGetPathParams = (serviceIdProp, isMemoizedParams = true) => {
  const {
    liveSport,
    preMatchSport,
    topEvents,
    preMatchCategory,
    preMatchTournament,
    preMatchEvent,
    liveEvent,
    cyberSportCategory,
    preMatchFilter
  } = useGeneralRoutes();
  const { pathname } = useLocation();
  const serviceIdByPath = [
    preMatchSport,
    preMatchCategory,
    cyberSportCategory,
    preMatchEvent,
    topEvents,
    preMatchFilter,
    preMatchTournament
  ].some((item) => pathname.includes(item))
    ? MODE.PRE_MATCH
    : MODE.IN_PLAY;

  const serviceId = serviceIdProp ?? serviceIdByPath;

  const paths = [
    `${preMatchSport}/:sportName`,
    `${liveSport}/:sportName`,
    `${preMatchCategory}/:sportName/:categoryId`,
    `${cyberSportCategory}/:categoryId`,
    `${liveEvent}/:sportName/:eventId`,
    `${preMatchEvent}/:sportName/:eventId`,
    `${topEvents}/:topId`,
    `${preMatchFilter}/:sportName`,
    `${preMatchTournament}/:sportName/:tournamentId`
  ];
  const routeParams = useMatchPath(paths);
  const routeParamsRef = useRef(routeParams);

  let { sportName, categoryId, eventId, topId, tournamentId } = routeParams;

  if (isMemoizedParams) {
    sportName = routeParamsRef.current.sportName;
    categoryId = routeParamsRef.current.categoryId;
    eventId = routeParamsRef.current.eventId;
    topId = routeParamsRef.current.topId;
    tournamentId = routeParamsRef.current.tournamentId;
  }

  const event = useEvent(eventId);
  const { categoryId: eventCategoryId } = event || {};
  const activeSport = useSportBySportSlug(sportName, serviceId);

  const activeCategory = useCategory(categoryId || eventCategoryId, serviceId);
  const activeTournament = useTournament(tournamentId, serviceId);

  useEffect(() => {
    if (isMemoizedParams) {
      routeParamsRef.current = { ...routeParamsRef.current, ...routeParams };
    }
  }, [routeParams, isMemoizedParams]);

  return {
    sportSlug: sportName,
    eventId,
    event,
    topId,
    categoryId: (categoryId && Number(categoryId)) || eventCategoryId,
    activeSport,
    category: activeCategory,
    tournament: activeTournament,
    tournamentId: Number(tournamentId)
  };
};
