export default `
           <style>
 .aviator-banner {
  display: flex;
  justify-content: space-between;
  height: 160px;
  background: url('/images/snippetsMedia/aviator/clouds.gif') no-repeat #F2F3F4;
  background-size: contain;
}

.left {
  display: flex;
  flex-direction: column;
  color: #7085AE;
  margin-left: 42px;
  margin-top: 42px;
  font-family: Roboto Condensed;
  font-style: normal;
}

.title {
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
  text-transform: uppercase;
}

.subTitle {
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
}

    </style>
    <div class="aviator-banner">
      <div class="left">
        <span class="title">Innovative Casino Games</span>
        <span class="subTitle">New kind of social multiplayer</span>
      </div>
    </div>
  `;
