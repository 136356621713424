import apiRoutes from '../api/apiRoutes';

export const TAB_BONUSES = 'bonus';
export const TAB_CASINO = 'casino';
export const TAB_RISKFREE = 'riskfree';

export const bonusTab = {
  bonus: TAB_BONUSES,
  casino: TAB_CASINO,
  riskFree: TAB_RISKFREE
};

export const acceptRoutsMap = {
  [bonusTab.riskFree]: apiRoutes.crmAcceptRiskfree,
  [bonusTab.casino]: apiRoutes.crmAcceptFreespin,
  [bonusTab.bonus]: apiRoutes.crmAcceptBonus
};

export const detailsRoutsMap = {
  [bonusTab.riskFree]: apiRoutes.getRiskFreeBonusDetails,
  [bonusTab.casino]: apiRoutes.getFreespinBonusDetails,
  [bonusTab.bonus]: apiRoutes.getFundsBonusDetails
};

export const freeSpinStates = {
  FREESPIN_POTENCIAL: 5,
  FREESPIN_PROPOSED: 10,
  FREESPIN_WAGERING: 100,
  FREESPIN_ACTVATED: 0,
  FREESPIN_ACCEPT_REQUEST: -1,
  FREESPIN_RELEASED: 1,
  FREESPIN_INCOME_NOT_ACHIEVED: 6,
  FREESPIN_EXPIRED: 2,
  FREE_SPIN_USED: 7
};

export const progressBarStates = {
  ACTIVE: 'active',
  DISABLED: 'disabled',
  NOT_ACTIVE: 'notActive',
  EXPIRED: 'expired',
  COMPLETED: 'completed'
};

export const PROGRESS_BAR_STATES_COLORS = {
  [progressBarStates.ACTIVE]: 'accent_color_3',
  [progressBarStates.DISABLED]: 'accent_color_3',
  [progressBarStates.NOT_ACTIVE]: 'accent_color_3',
  [progressBarStates.EXPIRED]: 'bg_color_5',
  [progressBarStates.COMPLETED]: 'accent_color_1'
};

export const freeSpinStatesMap = {
  all: [
    freeSpinStates.FREESPIN_POTENCIAL,
    freeSpinStates.FREESPIN_PROPOSED,
    freeSpinStates.FREESPIN_WAGERING,
    freeSpinStates.FREESPIN_ACTVATED,
    freeSpinStates.FREESPIN_ACCEPT_REQUEST,
    freeSpinStates.FREESPIN_RELEASED
  ],
  history: [freeSpinStates.FREESPIN_EXPIRED, freeSpinStates.FREE_SPIN_USED],
  count: [freeSpinStates.FREESPIN_ACTVATED, freeSpinStates.FREESPIN_PROPOSED]
};

export const freeSpinAggregatedStatusesMap = {
  [freeSpinStates.FREESPIN_POTENCIAL]: 'new',
  [freeSpinStates.FREESPIN_PROPOSED]: 'inProgress',
  [freeSpinStates.FREESPIN_WAGERING]: 'inProgress',
  [freeSpinStates.FREESPIN_ACTVATED]: 'available',
  [freeSpinStates.FREESPIN_ACCEPT_REQUEST]: 'available',
  [freeSpinStates.FREESPIN_RELEASED]: 'available',
  [freeSpinStates.FREESPIN_INCOME_NOT_ACHIEVED]: 'expired',
  [freeSpinStates.FREESPIN_EXPIRED]: 'expired',
  [freeSpinStates.FREE_SPIN_USED]: 'completed'
};

export const riskFreeStates = {
  ERF_STATE_AVAILABLE: 1,
  ERF_STATE_ACTIVE: 4,
  ERF_STATE_COMPLETED: 5,
  ERF_STATE_EXPIRED_TIME_ACTIVATION: 8,
  ERF_STATE_EXPIRED_TIME_USAGE: 9,
  ERF_STATE_ACTIVATION_REQUEST: 3,
  ERF_STATE_COMPLETED_2: 10
};

export const riskFreeStatesMap = {
  all: [
    riskFreeStates.ERF_STATE_AVAILABLE,
    riskFreeStates.ERF_STATE_ACTIVE,
    riskFreeStates.ERF_STATE_ACTIVATION_REQUEST
  ],
  history: [
    riskFreeStates.ERF_STATE_COMPLETED,
    riskFreeStates.ERF_STATE_EXPIRED_TIME_ACTIVATION,
    riskFreeStates.ERF_STATE_EXPIRED_TIME_USAGE,
    riskFreeStates.ERF_STATE_COMPLETED_2
  ],
  count: [riskFreeStates.ERF_STATE_AVAILABLE, riskFreeStates.ERF_STATE_ACTIVE]
};

export const riskFreeAggregatedStatusesMap = {
  [riskFreeStates.ERF_STATE_AVAILABLE]: 'available',
  [riskFreeStates.ERF_STATE_ACTIVATION_REQUEST]: 'available',
  [riskFreeStates.ERF_STATE_ACTIVE]: 'available',
  [riskFreeStates.ERF_STATE_COMPLETED]: 'completed',
  [riskFreeStates.ERF_STATE_COMPLETED_2]: 'completed',
  [riskFreeStates.ERF_STATE_EXPIRED_TIME_ACTIVATION]: 'expired',
  [riskFreeStates.ERF_STATE_EXPIRED_TIME_USAGE]: 'expired'
};

export const acceptModeType = {
  WAGER: [10, 11],
  ACCEPT: [1, 2]
};

export const MAX_ACTIVATION_CHECK_ATTEMPT = 2;
export const ACTIVATION_CHECK_DELAY = 3000;

export const fundsStates = {
  BONUS_POTENCIAL: 0,
  BONUS_PROPOSED: 1,
  BONUS_WAGERING: 100,
  BONUS_ACTVATED: 2,
  BONUS_RELEASED: 3,
  BONUS_INCOME_NOT_ACHIEVED: 8,
  BONUS_OUTPUT_NOT_ACHIEVED: 9,
  BONUS_WASNOT_ACTIVATED: 10,
  PROPOSED_ACCEPT_REQUEST_BY_GAMER: -1 // unknown state (available only in bonus details)
};

export const fundsStatesMap = {
  all: [
    fundsStates.BONUS_POTENCIAL,
    fundsStates.BONUS_PROPOSED,
    fundsStates.BONUS_WAGERING,
    fundsStates.BONUS_ACTVATED
  ],
  history: [
    fundsStates.BONUS_RELEASED,
    fundsStates.BONUS_INCOME_NOT_ACHIEVED,
    fundsStates.BONUS_OUTPUT_NOT_ACHIEVED,
    fundsStates.BONUS_WASNOT_ACTIVATED
  ],
  count: [fundsStates.BONUS_PROPOSED, fundsStates.BONUS_ACTVATED]
};

export const fundsBonusSystemStates = {
  POTENTIAL: 'potential',
  PROPOSED: 'proposed',
  WAGERING: 'wagering',
  EXPIRED_PROPOSED: 'expired_proposed',
  EXPIRED_POTENTIAL: 'expired_potential',
  EXPIRED_WAGERING_PERIOD: 'expired_wagering_period',
  CANCELED_BY_PLAYER: 'cancelled_by_player',
  CANCELED_BY_MANAGER: 'cancelled_by_manager',
  COMPLETED: 'completed'
};

export const PREWAGER_BONUS_TYPE = 'prewager';

export const fundsBonusAmountType = {
  FIXED: 'fixed',
  PERCENT: 'percent'
};

export const fundsAggregatedStatusesMap = {
  [fundsStates.BONUS_POTENCIAL]: 'new',
  [fundsBonusSystemStates.PROPOSED]: 'offer',
  [fundsBonusSystemStates.POTENTIAL]: 'new',
  [fundsStates.BONUS_PROPOSED]: 'inProgress',
  [fundsStates.BONUS_WAGERING]: 'inProgress',
  [fundsBonusSystemStates.WAGERING]: 'inProgress',
  [fundsStates.BONUS_ACTVATED]: 'available',
  [fundsStates.BONUS_RELEASED]: 'completed',
  [fundsBonusSystemStates.COMPLETED]: 'completedBS',
  [fundsStates.BONUS_INCOME_NOT_ACHIEVED]: 'expired',
  [fundsStates.BONUS_OUTPUT_NOT_ACHIEVED]: 'expired',
  [fundsBonusSystemStates.EXPIRED_PROPOSED]: 'expired',
  [fundsBonusSystemStates.EXPIRED_POTENTIAL]: 'expired',
  [fundsBonusSystemStates.EXPIRED_WAGERING_PERIOD]: 'expired',
  [fundsStates.BONUS_WASNOT_ACTIVATED]: 'expired',
  [fundsBonusSystemStates.CANCELED_BY_PLAYER]: 'canceled'
};

export const jackpotWinsStates = {
  AVAILABLE: 'available',
  ACCEPTED: 'accepted',
  EXPIRED: 'expired'
};

export const jackpotWinsAggregatedStatusesMap = {
  [jackpotWinsStates.AVAILABLE]: 'available',
  [jackpotWinsStates.ACCEPTED]: 'accepted',
  [jackpotWinsStates.EXPIRED]: 'expired'
};

export const ACCOUNTING_BONUS_TYPES = {
  ALL: 'All',
  REAL_MONEY: 'RealMoney',
  FREE_SPIN: 'FreeSpin',
  RISK_FREE: 'RiskFree'
};
export const OTHER_BONUS_TYPES = {
  JACKPOT_WINS: 'JackpotWins',
  ACCOUNTING_AND_OTHER_BONUSES: 'AccountingAndOtherBonuses'
};

export const BONUS_SYSTEM_BONUS_TYPE = 'BonusSystem';

export const BONUS_STATE_TYPES = {
  [ACCOUNTING_BONUS_TYPES.REAL_MONEY]: 'bonusCount',
  [ACCOUNTING_BONUS_TYPES.FREE_SPIN]: 'freeSpinCount',
  [ACCOUNTING_BONUS_TYPES.RISK_FREE]: 'riskFreeCount',
  [ACCOUNTING_BONUS_TYPES.ALL]: 'bonusesCount',
  [BONUS_SYSTEM_BONUS_TYPE]: 'bonusSystemCount',
  [OTHER_BONUS_TYPES.JACKPOT_WINS]: 'jackpotWinsCount',
  [OTHER_BONUS_TYPES.ACCOUNTING_AND_OTHER_BONUSES]:
    'accountingAndOtherBonusesCount'
};

export const NEW_BONUSES_FILTER = 'UserActionRequired';

export const BETS_TYPE_CODES = {
  BET_TYPE_CODE_SINGLE: 1,
  BET_TYPE_CODE_MULTIPLE: 2,
  BET_TYPE_CODE_SYSTEM: 4,
  BET_TYPE_CODE_SPIN: 8,
  BET_TYPE_CODE_RETURN: 16,
  BET_TYPE_CODE_CASH_OUT: 32
};

export const BETS_TYPE_CODES_SPORTS = [1, 2, 4, 16, 32];

export const BONUS_CONTENT_MACRO_TO_API_MAP = {
  start_date: 'startedAt',
  end_date: 'endedAt',
  bonus_type: 'bonusType',
  multiplier_wager: 'wagerMultiplier',
  amount_for_wager: 'amountForWager',
  multiplier_max_bonus_profit: 'multiplierMaxBonusProfit',
  max_bonus_profit: 'maxBonusProfit',
  bonus_proposed_date: 'bonusProposedDate',
  wagering_start_date: 'activatedAt',
  bonus_amount: 'bonusAmount',
  currency: 'currency.currency',
  time_to_activate: 'timeToActivate',
  time_to_wager: 'timeToWager',
  service: 'type',
  minimum_casino_bet_amount: 'currency.casinoMinBetAmount',
  maximum_casino_bet_amount: 'currency.casinoMaxBetAmount',
  minimum_sport_bet_amount: 'currency.sportsbookMinBetAmount',
  maximum_sport_bet_amount: 'currency.sportsbookMaxBetAmount',
  sportsbook_bet_type: 'sportsbookType',
  bet_min_odd: 'sportsbookMinOdd',
  bonus_amount_type: 'triggerSettings.bonusAmountType',
  bonus_amount_fixed: 'bonusAmount',
  percentage_deposit_values: 'triggerSettings.percentOfDeposit',
  min_deposit: 'triggerSettings.currency.minDeposit',
  max_deposit: 'triggerSettings.currency.maxDeposit'
};

const BONUS_DATE_FORMAT = (src) => src.toFormat('dd.MM.yyyy HH:mm:ss');

const BONUS_DURATION_FORMAT = (src) => src.toFormat('dd:hh:mm:ss');

export const BONUS_CONTENT_MACRO_FORMATS = {
  start_date: BONUS_DATE_FORMAT,
  end_date: BONUS_DATE_FORMAT,
  bonus_proposed_date: BONUS_DATE_FORMAT,
  wagering_start_date: BONUS_DATE_FORMAT,
  time_to_activate: BONUS_DURATION_FORMAT,
  time_to_wager: BONUS_DURATION_FORMAT,
  service: (src = []) => src.join('+'),
  sportsbook_bet_type: (src = []) => src.join(', ')
};

export const BS_API_BONUS_PARTIAL_SCHEMA = {
  startedAt: 'datetime',
  endedAt: 'datetime',
  activatedAt: 'datetime',
  updatedAt: 'datetime',
  bonusProposedDate: 'datetime',

  timeToActivate: 'duration',
  timeToWager: 'duration'
};

export const PWB_NOTIFICATION_TYPES = {
  WAGERED: 'preWagerBonusWagered',
  PROPOSED: 'preWagerBonusProposed'
};

export const BONUS_SLIDER_BONUS_TYPES = {
  DEFAULT: 'default',
  FREE_SPIN: 'freespin',
  FUNDS: 'funds',
  BONUS_SYSTEM: 'bonussystem',
  CASHBACK: 'cashback'
};
