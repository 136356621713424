import { useContext, useMemo, useState } from 'react';
import { useI18n } from '@namespace/i18n';
import { AText, Box, Carousel, TRANSITION_CONVERT_TYPES } from '@alf/uikit';
import { useConfig } from '@namespace/cms';
import { useRouteParams } from '@namespace/router';
import { keyBy } from 'lodash';
import { useUserSegmentsIncludes } from '@namespace/segments';
import { UserContext } from '@namespace/user';
import { DeviceContext } from '@namespace/device';
import { useThemeComponents, useGetThemeConfig } from '@namespace/themes';
import {
  DEFAULT_PROMO_TYPE,
  PROMO_TYPES,
  RECENTLY_ITEM_WIDTH
} from '../../constants';
import { usePromotionsStore } from '../../store';
import { RecentlyPromoItem as RecentlyPromoItemDefault } from './RecentlyPromoItem';
import checkIsActivePromo from '../../utils/checkIsActivePromo';
import { parseRecentlyPromoConfig } from '../../store/normalize';
import { useGetActivePromoType } from '../../hooks';
import styles from './index.module.css';

const RecentlyCompletedPromotions = ({ id }) => {
  const { t } = useI18n();
  const [containerWidth, setContainerWidth] = useState(0);
  const { isDesktop } = useContext(DeviceContext);
  const [user] = useContext(UserContext);
  const { accountStatuses = {} } = user;
  const { isBonusHunter, isAddictedUser } = accountStatuses;
  const { config = {} } = useConfig(id);
  const filteredSegments = useUserSegmentsIncludes();
  const promotionsApi = usePromotionsStore('promotions');
  const { category: categoryPath, tag: pathTag } = useRouteParams(
    `/:promotions/:category/:tag`
  );
  const parsedConfig = useMemo(() => parseRecentlyPromoConfig(config), [
    config
  ]);
  const parsedPromoApi = useMemo(() => keyBy(promotionsApi, 'promoId'), [
    promotionsApi
  ]);

  const activePromoType = useGetActivePromoType();

  const { showRecentlyCompletedPromotions = false, list = {} } =
    parsedConfig[categoryPath || DEFAULT_PROMO_TYPE] || {};

  const filteredPromotions = Object.keys(list)
    .map((promoId) => parsedPromoApi[promoId] || false)
    .filter(Boolean);

  const activeRecentlyPromos = useMemo(
    () =>
      Object.values(filteredPromotions)?.filter(
        ({ promoId, enabled, showFrom, showTo, type }) => {
          const isEnabledPromo = enabled;
          const isActivePromo = checkIsActivePromo(showFrom, showTo, true);
          const isCorrectType = categoryPath === PROMO_TYPES[type];
          const promoItemSegments = list ? list[promoId]?.userSegments : [];
          const isPromoIncludedInSegments = filteredSegments(promoItemSegments);

          return (
            isEnabledPromo &&
            isPromoIncludedInSegments &&
            isActivePromo &&
            isCorrectType
          );
        }
      ),
    [filteredPromotions, categoryPath, list, filteredSegments]
  );

  const { RecentlyPromoItem = RecentlyPromoItemDefault } = useThemeComponents();
  const {
    customStyles,
    customWrapperStyles,
    sliderContainerStyle = { margin: 'var(--b-spacing-m) 0 0 0' }
  } = useGetThemeConfig('RecentlyCompletedPromotions');
  const { mobile, desktop } = customWrapperStyles || {};

  return (
    showRecentlyCompletedPromotions &&
    activeRecentlyPromos.length > 0 &&
    !isBonusHunter &&
    !isAddictedUser && (
      <Box
        direction="column"
        className={styles.wrapper}
        data-role="recently-promotions-wrapper"
        style={isDesktop ? desktop : mobile}
      >
        <AText
          data-role="recently-promotions-title"
          breed="BU"
          size="l"
          color="txt_color_1"
          style={customStyles}
        >
          {t(`web.${activePromoType}.recentlycompleted.title`)}
        </AText>
        <Box
          data-role="recently-promotions-list-of-promos"
          direction="row"
          className={styles.sliderContainer}
          style={sliderContainerStyle}
        >
          <Carousel
            className={styles.sliderContainer}
            horizontal={true}
            overflow="true"
            circularSlidesCount={1000}
            navigation={{
              arrows:
                activeRecentlyPromos.length >
                containerWidth / RECENTLY_ITEM_WIDTH
            }}
            autoPlay={false}
            moveType={[TRANSITION_CONVERT_TYPES.multiple, { count: 1 }]}
            animationDuration={500}
            setContainerWidth={setContainerWidth}
            arrow={
              isDesktop
                ? {
                    name: 'icons/general/nav/back',
                    leftClass: styles.sliderArrow,
                    rightClass: `${styles.sliderArrow} ${styles.sliderArrowRight}`,
                    background: `${styles.sliderArrowBackground}`,
                    isHideAtEdge: true
                  }
                : {}
            }
          >
            {activeRecentlyPromos.map((promo, index) => {
              const key = `recently-promo-${pathTag}-${index}`;
              return (
                <RecentlyPromoItem
                  gap="8px"
                  index={index + 1}
                  pathTag={pathTag}
                  promo={promo}
                  key={key}
                />
              );
            })}
          </Carousel>
        </Box>
      </Box>
    )
  );
};

export default RecentlyCompletedPromotions;
