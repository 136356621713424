import { useMemo } from 'react';
import { useConfig, useGeneralRoutes } from '@namespace/cms';
import { BreadCrumbsMicrodata } from '@namespace/common';
import { IS_PRERENDER } from '@namespace/helpers';
import { useGetPathParams } from '../../hooks/useGetPathParams';
import { MODE } from '../../constants';
import { generateBreadCrumbsItems } from '../../utils';

const PreMatchBreadCrumbsMicrodata = ({ id }) => {
  const { config } = useConfig(id);
  const { isPrerenderOnly = true } = config;
  const isRender = !isPrerenderOnly || (isPrerenderOnly && IS_PRERENDER);
  const params = useGetPathParams(MODE.PRE_MATCH);
  const {
    preMatchSport,
    preMatchCategory,
    preMatchEvent,
    preMatchTournament
  } = useGeneralRoutes();

  const items = useMemo(
    () =>
      isRender
        ? generateBreadCrumbsItems(
            params,
            preMatchSport,
            preMatchCategory,
            preMatchEvent,
            preMatchTournament
          )
        : null,
    [
      params,
      preMatchSport,
      preMatchCategory,
      preMatchEvent,
      preMatchTournament,
      isRender
    ]
  );

  if (!isRender) {
    return null;
  }

  return <BreadCrumbsMicrodata items={items} />;
};

export default PreMatchBreadCrumbsMicrodata;
