import { useEffect, useState } from 'react';
import { ConfirmModal } from '@namespace/common';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { useGeneralRoutes } from '@namespace/cms';
import { useLogout } from '@namespace/user';
import { document } from '@namespace/helpers';
import { useThemeComponents } from '@namespace/themes';

const SessionExpiredPopup = () => {
  const [isShowPopup, setIsShowPopup] = useState(true);
  const { t } = useI18n();
  const { push } = useHistory();
  const { login } = useGeneralRoutes();
  const { logout } = useLogout(() => push(login));
  const { SessionExpiredPopup: SessionExpired } = useThemeComponents();
  const Component = SessionExpired || ConfirmModal;

  useEffect(() => {
    if (isShowPopup) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isShowPopup]);

  return (
    <Component
      popupTitle={t('web.afterblockpopup.title')}
      title={t('web.afterblockpopup.description')}
      confirm={t('web.ofterblockpopup.ok')}
      onConfirm={() => {
        setIsShowPopup(false);
        logout();
      }}
      textAlign="center"
      isShowClose={false}
      isBottomFullWidth={true}
    />
  );
};

export default SessionExpiredPopup;
