import { useContext, useEffect, useMemo, useState } from 'react';
import { UserContext } from '@namespace/user';
import { Box } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { useOtherSettings } from '@namespace/cms';
import clsx from 'clsx';
import { BonusesContext } from '../../store/context';
import { WagerBonus } from './components/WagerBonus';
import styles from './index.module.css';

export const WagerBonuses = ({
  classNames = {},
  displayMoreIcon = 'icons/general/nav/collapse',
  displayLessIcon = 'icons/general/nav/expand',
  iconColor = 'btn_primary_txt_color_4',
  wagerBonusProps = {},
  wagerBonusClassNames = {},
  progressBarClassNames = {}
}) => {
  const { containerClassName = '' } = classNames;
  const [isDisplayMore, setIsDisplayMore] = useState(false);
  const [bonuses, bonusesActions] = useContext(BonusesContext);
  const { wagerBonuses } = bonuses;
  const { FETCH_WAGER_BONUSES } = bonusesActions;
  const [user] = useContext(UserContext);
  const { id: userId, activeWallet } = user;
  const { currency } = activeWallet;
  const { isPreWagerBonusesEnabled = false } = useOtherSettings();

  const bonusesToShow = useMemo(
    () => (isDisplayMore ? wagerBonuses : wagerBonuses.slice(0, 1)),
    [wagerBonuses, isDisplayMore]
  );

  useEffect(() => {
    if (userId) {
      FETCH_WAGER_BONUSES({
        isPreWagerBonusesEnabled
      });
    }
  }, [FETCH_WAGER_BONUSES, userId, isPreWagerBonusesEnabled]);

  return (
    bonusesToShow.length > 0 && (
      <div className={clsx([styles.container, containerClassName])}>
        {bonusesToShow.map((bonus) => (
          <WagerBonus
            classNames={wagerBonusClassNames}
            key={bonus?.name}
            bonus={bonus}
            currency={currency}
            wagerBonusProps={wagerBonusProps}
            progressBarClassNames={progressBarClassNames}
          />
        ))}
        {wagerBonuses.length > 1 && (
          <Box justify="center" top="s">
            <Icon
              className={styles.expandIcon}
              onClick={(e) => {
                e.stopPropagation();
                setIsDisplayMore(!isDisplayMore);
              }}
              name={isDisplayMore ? displayMoreIcon : displayLessIcon}
              color={iconColor}
              size="s"
            />
          </Box>
        )}
      </div>
    )
  );
};
