import { useContext } from 'react';
import { useIsComponentOnPage } from '@namespace/common';
import { COMPONENTS_TYPES } from '@namespace/cms';
import { DeviceContext } from '@namespace/device';
import { BetSlipContext } from '@namespace/betslip/src/store/context';

const useBetslipVisible = () => {
  const { isDesktop } = useContext(DeviceContext);
  const [betSlip = {}] = useContext(BetSlipContext);
  const { bets = [] } = betSlip;
  const isBetSlipEnabled = useIsComponentOnPage(
    COMPONENTS_TYPES.BET_SLIP_GLOBAL
  );
  const isBetSlipVisible = !isDesktop && isBetSlipEnabled && bets.length;

  return isBetSlipVisible;
};

export default useBetslipVisible;
