import { Box, AText } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { Icon } from '@namespace/icons2';
import { getIconName, formatBytes } from '../../../../utils';
import styles from './index.module.css';

export default ({
  isIncoming,
  text,
  agentName,
  dateTime,
  status,
  statusText,
  fileName,
  fileSize
}) => {
  const { t, f } = useI18n();

  return (
    <Box
      direction="row"
      justify={isIncoming ? 'start' : 'end'}
      margin={`0 0 ms ${isIncoming ? '0' : 'xl'}`}
    >
      {isIncoming ? (
        <Box direction="column" justify="end" padding="0 xs">
          <div />
        </Box>
      ) : (
        ''
      )}
      <Box direction="column">
        <AText breed="R" size="2xs" color="bg_color_1" padding="0 xxs">
          {isIncoming ? agentName : t('support.chat.you')}
          {`, `}
          {f.getDateTime({ date: dateTime }).toFormat('t')}
        </AText>
        <Box
          className={`${styles.message} ${
            isIncoming ? styles.messageIn : styles.messageOut
          }`}
          direction="column"
          justify="end"
        >
          <AText
            breed="R"
            size="s"
            color={isIncoming ? 'txt_color_1' : 'txt_color_4'}
          >
            {fileSize > 0 ? `${fileName} (${formatBytes(fileSize, 2)})` : ''}
            {fileSize === 0 && text}
          </AText>
        </Box>
        {!isIncoming && (Number(fileSize) > 0 || status !== 'ok') && (
          <Box
            className={styles.status}
            direction="row"
            padding="xxs 0"
            align="center"
          >
            <Icon
              name={`icons/general/state/${getIconName(status)}`}
              color={status === 'error' ? 'state_error' : 'state_success'}
              size="xs"
            />
            <Box margin="0 xs">
              <AText
                breed="R"
                size="xs"
                color={status === 'error' ? 'txt_error' : 'txt_color_7'}
              >
                {statusText}
              </AText>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
