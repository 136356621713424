import { useEffect } from 'react';
import { useShowInHouseJpWinNotificationAfterLogin } from '@namespace/games';
import {
  getLocalStorageValue,
  LOCAL_STORAGE_KEYS,
  setIsShowAfterLoginNotifications
} from '@namespace/helpers';

export const useShowAfterLoginNotifications = () => {
  const isShowAfterLoginNotifications = getLocalStorageValue(
    LOCAL_STORAGE_KEYS.IS_SHOW_AFTER_LOGIN_NOTIFICATIONS,
    false
  );

  const isJackpotWinNotificationLogicCompleted = useShowInHouseJpWinNotificationAfterLogin(
    isShowAfterLoginNotifications
  );

  useEffect(() => {
    if (isJackpotWinNotificationLogicCompleted) {
      setIsShowAfterLoginNotifications(false);
    }
  }, [isJackpotWinNotificationLogicCompleted]);
};
