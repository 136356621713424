import { cloneElement, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { isUndefined } from 'lodash';
import clsx from 'clsx';
import { Icon } from '@namespace/icons2';
import { useGetThemeConfig } from '@namespace/themes';
import useCombinedRefs from '../../hooks/useCombinedRefs';
import Box from '../Box';
import Label from '../Label';
import { propsMerge } from '../../utils/propsMerge';
import styles from './index.module.css';

const maybeEmptyString = (a) => a || '';

const InputMaskForward = InputMask;

const Input = (props) => {
  const inputConfig = useGetThemeConfig('Input');
  const {
    inputRef = null,
    type = 'text',
    className = '',
    labelClassName = '',
    placeholder = '',
    wrapperClassName = '',
    clearable = false,
    onClean,
    onChange,
    align = 'left',
    size = 's',
    iconSize,
    value = '',
    icon = null,
    iconLeft = null,
    intent = 'default',
    mask,
    onFocus,
    onBlur,
    dataRole,
    validator,
    bottomElement = null,
    postInputLabel,
    id,
    ...rest
  } = propsMerge(props, inputConfig);

  const isFocusedRef = useRef(false);
  const innerRef = useRef(null);
  const combinedRef = useCombinedRefs(inputRef, innerRef);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  // Due to "mask" prop updating, input component may loose focus on remounting.
  useEffect(() => {
    if (isFocusedRef.current) {
      if (mask) {
        innerRef.current.getInputDOMNode().focus();
      } else {
        innerRef.current.focus();
      }
    }
  }, [mask]);

  const togglePasswordVisibility = useCallback(
    () => setIsPasswordVisible((prevState) => !prevState),
    [setIsPasswordVisible]
  );

  const clean = useCallback(
    (e) => {
      e?.preventDefault();
      e?.stopPropagation();

      onChange({ target: { value: '' } });
      onClean?.('');

      if (innerRef.current) {
        innerRef.current.focus();
      }
    },
    [onClean, onChange]
  );

  const handleFocus = useCallback(
    (e) => {
      isFocusedRef.current = true;
      onFocus?.(e);
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    (e) => {
      isFocusedRef.current = false;
      onBlur?.(e);
    },
    [onBlur]
  );

  const handleChange = useCallback(
    (e) => {
      const isNotValid = !isUndefined(validator) && !validator(e.target.value);

      return isNotValid ? undefined : onChange(e);
    },
    [validator, onChange]
  );

  const wrapperClasses = clsx([
    styles.wrapper,
    wrapperClassName,
    styles[align],
    styles[size],
    styles[intent],
    clearable && styles.isClearable,
    icon && styles.withIcon,
    iconLeft && styles.iconLeft,
    type === 'password' && styles.password
  ]);

  const InputComponent = mask ? InputMaskForward : 'input';

  return (
    <Box data-role={dataRole} className={wrapperClasses} direction="column">
      {iconLeft && (
        <Box
          component="span"
          className={styles.iconLeftContainer}
          direction="column"
          align="center"
          justify="center"
        >
          {cloneElement(iconLeft, { size: iconSize || size })}
        </Box>
      )}
      <InputComponent
        ref={combinedRef}
        type={isPasswordVisible ? 'text' : type}
        className={`${styles.input} ${className}`}
        placeholder={placeholder}
        value={maybeEmptyString(value)}
        onChange={handleChange}
        mask={mask}
        onFocus={handleFocus}
        onBlur={handleBlur}
        id={id}
        {...rest}
      />
      {postInputLabel && (
        <Label htmlFor={id} className={clsx([styles.label, labelClassName])}>
          {postInputLabel}
        </Label>
      )}
      {clearable && value && (
        <Box
          data-role="input-clear-button"
          onClick={clean}
          className={styles.clear}
          direction="column"
        >
          <Icon size={size} name="icons/general/action/close" />
        </Box>
      )}
      {type === 'password' && (
        <Box
          data-role={`password-eye-${isPasswordVisible ? 'hide' : 'show'}`}
          component="span"
          onClick={togglePasswordVisibility}
          className={styles.showPassIcon}
          align="center"
          justify="center"
        >
          <Icon
            className={styles.passIcon}
            size={size}
            name={
              isPasswordVisible
                ? 'icons/general/state/hide'
                : 'icons/general/state/show'
            }
          />
        </Box>
      )}
      {icon && (
        <Box
          component="span"
          className={styles.iconContainer}
          direction="column"
        >
          {cloneElement(icon, { size: iconSize || size })}
        </Box>
      )}
      {bottomElement &&
        intent !== 'ghost' &&
        cloneElement(bottomElement, { className: styles.borderBottomCustom })}
    </Box>
  );
};

Input.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.shape({ current: PropTypes.element })
  ]),
  icon: PropTypes.object,
  iconLeft: PropTypes.object,
  onChange: PropTypes.func,
  onClean: PropTypes.func,
  clearable: PropTypes.bool,
  type: PropTypes.string,
  align: PropTypes.string,
  size: PropTypes.oneOf(['xs', 's', 'm']),
  value: PropTypes.any,
  className: PropTypes.string,
  mask: PropTypes.string,
  placeholder: PropTypes.string,
  intent: PropTypes.oneOf(['error', 'success', 'default', 'ghost']),
  wrapperClassName: PropTypes.string
};

export default Input;
