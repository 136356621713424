import { useSubscribeToInHouseJackpot } from '../useSubscribeToInHouseJackpot';
import { useLoadInHouseJackpotWinnersInfo } from '../useLoadInHouseJackpotWinnersInfo';
import { useGetInHouseJackpotWinnersGamesLaunchIds } from '../../jackpotsContext/selectors';

export const useInHouseJackpot = (showInhouseJackpot) => {
  useSubscribeToInHouseJackpot(showInhouseJackpot);

  const winnersGamesLaunchIds = useGetInHouseJackpotWinnersGamesLaunchIds();

  useLoadInHouseJackpotWinnersInfo(winnersGamesLaunchIds);
};
