import { useI18n } from '@namespace/i18n';
import { Box, AText } from '@alf/uikit';

export const WagerInfo = ({ className = '', isGameWagerExcluded }) => {
  const { t } = useI18n();

  return (
    <Box className={className} direction="column">
      <AText color="txt_color_4" size="s" align="center">
        {t(`web.games.${isGameWagerExcluded ? t('nowager') : t('iswager')}`)}
      </AText>
    </Box>
  );
};
