import { pathOr } from 'ramda';
import { shallow } from 'zustand/shallow';
import { useSportsbookStore } from '../../../../store';

export const selectEventFieldLength = (eventId, fieldPath) => (state = {}) =>
  pathOr(
    null,
    [
      'events',
      eventId,
      ...(Array.isArray(fieldPath) ? fieldPath : [fieldPath])
    ],
    state
  )?.length || 0;

export const useEventFieldLength = (eventId, fieldPath) =>
  useSportsbookStore(selectEventFieldLength(eventId, fieldPath), shallow);
