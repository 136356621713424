import {
  PAN_STATUS,
  PAN_STATUS_BY_CARD_STATUS,
  PAYMENT_CARD
} from '../constants';

export const cardRange = (number = '', range, fromToOrEquals = []) => {
  const currenNum = Number(number.slice(0, range));

  return fromToOrEquals.length > 1
    ? currenNum >= fromToOrEquals[0] && currenNum <= fromToOrEquals[1]
    : currenNum === fromToOrEquals[0];
};

export const getCardType = (cardNumber) => {
  if (cardRange(cardNumber, 1, [4])) {
    return PAYMENT_CARD.VISA;
  }

  if (
    cardRange(cardNumber, 2, [51, 55]) ||
    cardRange(cardNumber, 4, [2221, 2720])
  ) {
    return PAYMENT_CARD.MASTERCARD;
  }
  return PAYMENT_CARD.OTHER;
};

/* eslint-disable no-bitwise */
export const cardHasDeposit = (cardStatus) =>
  Boolean(cardStatus & PAN_STATUS.FIRST_DEPOSIT);

export const cardBlockedByRMT = (cardStatus) =>
  Boolean(cardStatus & PAN_STATUS.BLOCKED_BY_RMT);

export const cardBlockedByClient = (cardStatus) =>
  Boolean(cardStatus & PAN_STATUS.BLOCKED_BY_CLIENT);

export const withdrawAllowed = (status) =>
  cardHasDeposit(status) &&
  !cardBlockedByClient(status) &&
  !cardBlockedByRMT(status);

export const getExpMonthYear = (date) => {
  const [month, year] = date.split('/');
  const expYear = year.length === 2 ? `20${year}` : year;

  return {
    expMonth: month,
    expYear
  };
};

export const getExpDate = ({ expMonth, expYear }) => ({
  expDate: new Date(expYear, Number(expMonth) - 1)
});

export const findCardStatus = (statusList) => {
  const statusKey = Object.keys(statusList).find((key) => statusList[key]);
  return PAN_STATUS[PAN_STATUS_BY_CARD_STATUS[statusKey]];
};
