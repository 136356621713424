import { window } from '../../constants';

const getElementHeight = (el) => {
  if (!el) {
    return 0;
  }
  const elStyles = window.getComputedStyle(el);
  const margin =
    parseFloat(elStyles.marginTop) + parseFloat(elStyles.marginBottom);

  return Math.ceil(el.offsetHeight + margin);
};

export default getElementHeight;
