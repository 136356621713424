import styles from './index.module.css';

export const SportbookSkeletLine = () => (
  <>
    <div className={styles.subHeader}>
      <div className={styles.rectangleLeft} />
      <div className={styles.rectangleRight} />
    </div>
    <div className={styles.eventDataWrapper}>
      <div className={styles.eventDataLeft}>
        <div className={styles.eventDataRectangle} />
        <div className={styles.eventDataRectangle} />
        <div className={styles.eventDataRectangle} />
        <div
          className={`${styles.eventDataRectangle} ${styles.eventDataRectangleLeft}`}
        />
      </div>
      <div className={styles.eventDataCenter}>
        <div
          className={`${styles.eventDataRectangle} ${styles.eventDataRectangleCenter}`}
        />
        <div className={styles.eventDataRectangle} />
      </div>
      <div className={styles.eventDataRight}>
        <div className={styles.eventDataRectangle} />
        <div
          className={`${styles.eventDataRectangle} ${styles.eventDataRectangleClear}`}
        />
      </div>
    </div>
  </>
);
