import { useState, useRef, useEffect } from 'react';
import { useFixScroll } from '@namespace/common';
import { LocalizedNavLink, useI18n, useLocation } from '@namespace/i18n';
import { Box, AText, Menu, NavigationDrawer } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import styles from './index.module.css';

const MobileMenu = ({ items }) => {
  const { t } = useI18n();
  const { pathname, hash } = useLocation();
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useFixScroll('contentMobileMenu', containerRef, isOpen);

  useEffect(() => {
    setIsOpen(false);
  }, [pathname, hash]);

  if (!isOpen) {
    return (
      <Box direction="column" className={styles.menuButton} justify="center">
        <Box direction="row" onClick={() => setIsOpen(true)}>
          <Box direction="row" padding="0 s 0 m" align="center">
            <Icon
              name="icons/general/nav/arrow-down"
              color="accent_color_1"
              size="s"
            />
          </Box>
          <AText breed="B" size="xl" color="sm_txt_color_1">
            {t('contentPage.menu.services')}
          </AText>
        </Box>
      </Box>
    );
  }

  return (
    <div>
      <NavigationDrawer
        classNames={{
          wrapper: styles.wrapper,
          title: styles.titleOpen,
          body: styles.body
        }}
        title={t('contentPage.menu.services')}
        onClose={() => setIsOpen(false)}
        body={
          <Menu
            items={items}
            NavLink={LocalizedNavLink}
            isTop={true}
            className={styles.menu}
          />
        }
        icon={
          <Icon
            name="icons/general/action/close"
            color="txt_color_7"
            size="s"
            className={styles.icon}
          />
        }
        ref={containerRef}
      />
    </div>
  );
};

export default MobileMenu;
