import { memo, useMemo } from 'react';
import { Box } from '@alf/uikit';
import HeadGroup from '../HeadGroup';
import styles from './index.module.css';

const HeadGroups = ({ classNames = {}, items }) => {
  const { containerClassName = '', headGroupsClassName = '' } = classNames;

  const renderedItems = useMemo(
    () =>
      Array.isArray(items)
        ? items.map((item) => (
            <HeadGroup key={item.marketTemplateId} {...item} />
          ))
        : items,
    [items]
  );

  return (
    <Box
      data-role="c-headGroups"
      className={`${styles.container} ${containerClassName}`}
    >
      <Box className={headGroupsClassName}>{renderedItems}</Box>
    </Box>
  );
};

export default memo(HeadGroups);
