import { useState, useCallback, useContext, useMemo, useEffect } from 'react';
import { filter } from 'lodash';
import { useDebounce } from '@namespace/helpers';
import { GamesContext } from '../../context';
import { getGames } from '../../context/selectors';
import { MIN_GAMES_SEARCH_QUERY_LENGTH } from '../../constants';

export const useSearchGames = (feedName, query = '') => {
  const [gamesState] = useContext(GamesContext);
  const gamesFeed = gamesState.feeds[feedName];
  const lowerQuery = query.toLowerCase();
  const debouncedQuery = useDebounce(lowerQuery, 500);
  const isFeedLoaded = gamesFeed?.isLoaded;

  const [result, setResult] = useState({
    gamesResult: undefined,
    debouncedQuery: ''
  });

  const serviceGames = useMemo(
    () => getGames(gamesFeed?.gamesIds, gamesState.games.data),
    [gamesFeed?.gamesIds, gamesState.games]
  );

  const filterGames = useCallback(
    (searchQuery = '', games) => {
      if (!isFeedLoaded) return;

      const searchResult =
        searchQuery && searchQuery.length >= MIN_GAMES_SEARCH_QUERY_LENGTH
          ? filter(games, ({ name }) =>
              name.toLowerCase().includes(searchQuery)
            )
          : undefined;

      setResult({ gamesResult: searchResult, debouncedQuery: searchQuery });
    },
    [isFeedLoaded]
  );

  useEffect(() => {
    filterGames(debouncedQuery, serviceGames);
  }, [filterGames, debouncedQuery, serviceGames]);

  return { ...result };
};
