export const types = {
  FETCH_WAGER_BONUSES: 'FETCH_WAGER_BONUSES',
  FETCH_ACTIVE_WAGER_BONUS: 'FETCH_ACTIVE_WAGER_BONUS',
  FETCH_ACTIVE_BONUS_SYSTEM_BONUS: 'FETCH_ACTIVE_BONUS_SYSTEM_BONUS',
  SET_ACTIVE_WAGER_BONUS: 'SET_ACTIVE_WAGER_BONUS',
  SET_WAGER_BONUSES: 'SET_WAGER_BONUSES',
  SET_ACTIVE_BONUS_SYSTEM_BONUS: 'SET_ACTIVE_BONUS_SYSTEM_BONUS',

  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_BONUS: 'SET_BONUS',
  SET_BONUS_TMPL: 'SET_BONUS_TMPL',

  SET_PWB_NOTIFICATIONS: 'SET_PWB_NOTIFICATIONS',
  SET_IS_UPDATE_FREE_SPIN_BONUS_LIST: 'SET_IS_UPDATE_FREE_SPIN_BONUS_LIST',
  SET_IS_UPDATE_FUNDS_BONUS_LIST: 'SET_IS_UPDATE_FUNDS_BONUS_LIST'
};
