import { AText, Box } from '@alf/uikit';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { Icon } from '@namespace/icons2';
import { useGeneralRoutes } from '@namespace/cms';
import styles from './index.module.css';

const NeedHelpSection = ({ dataRole, linkText }) => {
  const { t } = useI18n();
  const { needHelpSection } = useGeneralRoutes();

  return (
    <Box
      className={styles.wrapper}
      data-role={dataRole}
      align="center"
      justify="center"
    >
      <Icon
        className={styles.icon}
        name="icons/general/state/info2"
        color="state_info"
        size="s"
      />
      <LocalizedLink to={needHelpSection}>
        <AText
          data-role="withdraw-bonus-notification-text"
          color="state_info"
          breed="R"
          size="m"
        >
          {t(linkText)}
        </AText>
      </LocalizedLink>
    </Box>
  );
};

export default NeedHelpSection;
