import { useEffect, useMemo, useContext } from 'react';
import { Box } from '@alf/uikit';
import { useSearchParams } from '@namespace/router';
import { COMPONENTS_TYPES, PageComponentsContext } from '@namespace/cms';
import {
  POPUP_TYPES,
  POPUP_TYPES_WITHOUT_MENU,
  document
} from '@namespace/helpers';
import ProfileMenu from '../ProfileMenu';
import styles from './index.module.css';

const FullScreenPopup = () => {
  const { popup } = useSearchParams();
  const isShowPopup = Object.values(POPUP_TYPES)?.includes(popup);
  const isShowMenu = !Object.values(POPUP_TYPES_WITHOUT_MENU)?.includes(popup);

  const {
    [COMPONENTS_TYPES.MOBILE_PROFILE_MENU]: CustomProfileMenu = ProfileMenu
  } = useContext(PageComponentsContext);

  const content = useMemo(() => {
    switch (popup) {
      case POPUP_TYPES.PROFILE_MENU:
        return <CustomProfileMenu />;
      default:
        return null;
    }
  }, [popup]);

  useEffect(() => {
    if (isShowPopup) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isShowPopup]);

  return isShowPopup && content ? (
    <Box
      className={`${styles.fullScreenPopup} ${
        isShowMenu ? styles.withMenu : ''
      }`}
    >
      {content}
    </Box>
  ) : null;
};

export default FullScreenPopup;
