import { socketSend } from '@namespace/socket';
import { isBetradar as checkIsBetradar } from '@namespace/virtual-sports';
import { VIRTUAL_SPORTS_EVENTS_LIMIT } from '../../constants';
import { types } from '../types/index.js';
import {
  mappedSportsNames,
  mappedTournamentsNames,
  sortDataCategories
} from '../normalize';
import {
  getSportsNames,
  getCategoriesNames,
  getTournamentsNames,
  getSportsEventResults
} from '../../api';

const actions = {
  [types.GET_SPORTS_NAME]: (dispatch) => async (date, tzDiff) => {
    try {
      const names = await getSportsNames({
        date,
        tzDiff
      });
      const mappedNames = mappedSportsNames(names.filterSports);
      dispatch({
        type: types.SET_SPORTS_NAME,
        payload: mappedNames
      });
    } catch (error) {
      console.error(error);
    }
  },
  [types.GET_CATEGORIES]: (dispatch) => async (date, sportId, tzDiff) => {
    try {
      const categories = await getCategoriesNames({
        date,
        sportId,
        tzDiff
      });
      const sortedCategories = [...sortDataCategories(categories)];
      dispatch({
        type: types.SET_CATEGORIES,
        payload: sortedCategories
      });
    } catch (error) {
      console.error(error);
    }
  },
  [types.GET_TOURNAMENTS]: (dispatch) => async (date, categoryId, tzDiff) => {
    try {
      const tournaments = await getTournamentsNames({
        date,
        categoryId,
        tzDiff
      });
      const mappedNames = mappedTournamentsNames(tournaments);
      dispatch({
        type: types.SET_TOURNAMENTS,
        payload: mappedNames
      });
    } catch (error) {
      console.error(error);
    }
  },
  [types.GET_SPORT_RESULTS]: (dispatch) => async ({
    id,
    date,
    page = 1,
    categoryId,
    tournamentId,
    tzDiff
  }) => {
    try {
      const { events, pages } = await getSportsEventResults({
        date,
        id,
        page,
        categoryId,
        tournamentId,
        tzDiff
      });
      dispatch({
        type: types.SET_SPORTS_EVENTS_RESULT,
        payload: { events, pages }
      });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({
        type: types.SET_PROCESSING,
        payload: false
      });
    }
  },
  [types.SET_CURRENT_PAGE]: (dispatch) => async (page) => {
    dispatch({
      type: types.SET_CURRENT_PAGE,
      payload: page
    });
  },
  [types.RESET_SPORT]: (dispatch) => async () => {
    dispatch({
      type: types.RESET_SPORT,
      payload: []
    });
  },
  [types.SET_OFFSET]: (dispatch) => async ({ offset }) => {
    dispatch({
      type: types.SET_OFFSET,
      payload: offset
    });
  },
  [types.GET_VIRTUAL_RESULT]: (dispatch) => async ({
    categoryId,
    date,
    limit = VIRTUAL_SPORTS_EVENTS_LIMIT,
    offset = 0,
    provider,
    language,
    tournamentId = null
  }) => {
    if (provider) {
      try {
        const isBetradar = checkIsBetradar(provider);
        const { data } = await socketSend({
          cmd: 'do_proto_v1',
          data: {
            query: {
              provider_results: {
                variables: {
                  category_id: isBetradar ? null : Number(categoryId),
                  date,
                  limit,
                  offset,
                  provider,
                  tournament_id: tournamentId || null,
                  language
                }
              }
            }
          }
        });
        dispatch({
          type: types.SET_VIRTUAL_EVENTS_RESULT,
          payload: { data }
        });
      } catch (e) {
        console.warn(e);
      } finally {
        dispatch({
          type: types.SET_PROCESSING,
          payload: false
        });
      }
    }
  },
  [types.GET_VIRTUAL_FILTER_OPTIONS]: (dispatch) => async ({
    provider,
    language
  }) => {
    if (provider) {
      try {
        const isBetradar = checkIsBetradar(provider);
        const { data } = await socketSend({
          cmd: 'do_proto_v1',
          data: {
            query: {
              provider_menu: {
                variables: {
                  language,
                  provider
                }
              }
            }
          }
        });
        dispatch({
          type: types.SET_VIRTUAL_FILTER_OPTIONS,
          payload: { data, isBetradar }
        });
      } catch (e) {
        console.warn(e);
      }
    }
  }
};

export default actions;
