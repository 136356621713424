import { post } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

export const getHeadGroups = ({ lang, serviceIds }) => {
  const body = {
    lang,
    service_id: {
      $in: serviceIds
    }
  };

  return post('/frontend_api/head_groups/', body).then(camelizeKeys);
};
