export const LANG_PATTERN = /^\/([a-zA-Zа-яА-ЯёЁіІїЇєЄґҐ]{2})(\/|$)/; // fixed bug when we go to '/en/укецу' before, we redirected to '/en/укецу'

// ToDo Don't use "DATE_FORMATS_TYPES" in component.
// Need to get values from "useI18n()" => f.getFormats();

export const DATE_FORMATS_TYPES = {
  BETS_HISTORY_DATE: 'dd/MM/yyyy',
  BETS_HISTORY_TIME: 'HH:mm:ss',
  EVENTS_LINE_DATE: 'dd.MM',
  EVENTS_LINE_TIME: 'HH:mm',
  EVENTS_DETAILS_DATE: 'dd.MM',
  EVENTS_DETAILS_TIME: 'HH:mm',
  BET_SLIP_DATE: 'dd/MM',
  BET_SLIP_TIME: 'HH:mm',
  MY_BETS_DATE: 'dd/MM/yyyy',
  MY_BETS_TIME: 'HH:mm:ss',
  MESSAGES: 'dd/MM/yyyy HH:mm',
  CLOCK: 'd M | HH:mm (z)',
  API: 'yyyy-MM-dd', // .toISODate()
  CLIENT_API: 'yyyy-MM-dd',
  dd_MM_yyyy: 'dd.MM.yyyy',
  API_WITH_TIME: 'yyyy-MM-dd HH:mm:ss',
  RESULTS_DATE: 'dd.MM - HH:mm',
  VIRTUAL_SPORTS_DATE_TIME: 'dd.MM - HH:mm',
  LOTTERY_HISTORY_DATE: 'yyyy-MM-dd',
  TEASER_TIME: 'HH:mm',
  DEPOSIT_HISTORY_DATE_TIME: 'dd/MM/yyyy HH:mm',
  BONUSES_DATE_FORMAT: 'dd.MM.yyyy | HH:mm',
  CASINO_ROUNDS_DATE_FORMAT: 'dd.MM.yyyy HH:mm',
  HOURS_MINUTES: 'HH:mm',
  DATE_WITH_TIME: 'yyyy-MM-dd HH:mm',
  dd_MM_yy: 'dd.MM.yy',
  HOURS_MINUTES_SECONDS: 'HH:mm:ss',
  FULL_TIME: 'hh:mm:ss',
  IN_HOUSE_JACKPOT_DATE_FORMAT: 'dd.MM.yy | HH:mm',
  PROMOTIONS_START_DATE: 'dd.MM.yyyy',
  PROMOTIONS_END_DATE: 'dd.MM.yyyy',
  PROMOTIONS_TIMER: 'dd hh mm ss',
  MESSAGES_NEW: 'dd/MM/yyyy | HH:mm',
  DEPOSIT_HISTORY_DATE_TIME_NEW: 'dd/MM/yyyy | HH:mm'
};

export const DATE_FORMATS = {
  yyyy_MM_dd: /^\d{4}[-/.](0[1-9]|1[0-2])[-/.](0[1-9]|[12][0-9]|3[01])$/,
  dd_MM_yyyy: /^(0[1-9]|[12][0-9]|3[01])[-/.](0[1-9]|1[0-2])[-/.]\d{4}$/,
  yyyy_MM: /^\d{4}[-/.](0[1-9]|1[0-2])$/,
  MM_yyyy: /^(0[1-9]|1[0-2])[-/.]\d{4}$/
};

export const TIME_FORMATS = {
  HH_mm_ss: /^([01][0-9]|2[0-3])[:]([0-5][0-9])[:]([0-5][0-9])$/,
  HH_mm: /^([01][0-9]|2[0-3])[:]([0-5][0-9])$/
};

export const DATE_SEPARATORS = ['-', '/', '.'];

export const TIME_SEPARATORS = [':'];

export const DATETIME_SEPARATORS = [' '];
