import { useCallback, useState } from 'react';
import { isFunction } from 'lodash';
import { IS_PRERENDER } from '@namespace/helpers';

const useOnSlideMove = () => {
  const [isMove, setIsMove] = useState(false);

  const handleClickOnMove = useCallback(
    (event, callback) => {
      if (isMove && !IS_PRERENDER) {
        event.preventDefault();
        event.stopPropagation();
      } else if (isFunction(callback)) {
        callback();
      }
    },
    [isMove]
  );
  return { isMove, setIsMove, handleClickOnMove };
};

export default useOnSlideMove;
