import EventLabel from '../EventLabel';
import styles from './index.module.css';
import usePreMatchEventState from '../../hooks/usePreMatchEventState';

const EventDate = ({ eventId, lineView, isWithTZ, dataRole }) => {
  const { eventDate } = usePreMatchEventState(eventId, isWithTZ);

  return (
    <EventLabel
      dataRole={dataRole}
      className={`${styles.eventDate} ${lineView ? styles[lineView] : ''}`}
      text={eventDate}
    />
  );
};

export default EventDate;
