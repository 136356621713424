import { AText, Box, Iframe } from '@alf/uikit';
import { Player } from '@namespace/common';
import { window } from '@namespace/helpers';
import { useI18n } from '@namespace/i18n';
import { Image } from '@namespace/icons2';
import { IS_WIDGET, WidgetContext } from '@namespace/widget';
import { memo, useContext } from 'react';
import { useTrackFavTv } from '../../../hooks/useTrackFavTv';
import styles from './index.module.css';

const Stream = memo(
  ({ streamUrl, eventBroadcastUrl, errorMessage = '', eventId }) => {
    const { t } = useI18n();
    const [{ parentHostname }] = useContext(WidgetContext);

    useTrackFavTv({
      eventId,
      isValidStream: !errorMessage && Boolean(streamUrl)
    });

    if (!errorMessage) {
      if (streamUrl) {
        return (
          <Box
            data-role="event-stream"
            flex="0 0 auto"
            justify="center"
            align="center"
            className={styles.playerContainer}
          >
            <Player
              className={styles.player}
              streamUrl={streamUrl}
              autoplay={true}
              width="100%"
            />
          </Box>
        );
      }

      if (eventBroadcastUrl) {
        return (
          <Box
            flex="0 0 auto"
            justify="center"
            align="center"
            className={styles.playerContainer}
          >
            <Iframe
              title="steam-iframe"
              src={`${eventBroadcastUrl}${
                eventBroadcastUrl.includes('?') ? '&' : '?'
              }parent=${window.location.hostname}${
                IS_WIDGET && parentHostname
                  ? `&parent=${parentHostname}
                    `
                  : ''
              }`}
              className={`${styles.player} ${styles.iframePlayer}`}
              allowFullScreen={true}
            />
          </Box>
        );
      }
    }

    if (streamUrl === null) {
      return (
        <Box
          flex="0 0 auto"
          justify="center"
          align="center"
          className={styles.playerContainer}
        >
          <Box
            flex="1 0 100%"
            direction="column"
            justify="center"
            align="center"
            className={styles.errorContainer}
          >
            <Image
              name="general/video-crash"
              className={styles.errorIcon}
              width="90px"
              height="90px"
              alt=""
            />
            <AText breed="BU" size="m" color="line_txt_2">
              {t('sportsbook.events.stream.crash')}
            </AText>
          </Box>
        </Box>
      );
    }

    if (
      errorMessage === 'sportsbook.events.stream.noCountry' ||
      errorMessage === 'sportsbook.events.stream.paidStream'
    ) {
      return (
        <Box
          data-role="event-stream-error"
          flex="0 0 auto"
          justify="center"
          align="center"
          className={styles.playerContainer}
        >
          <Box
            flex="1 0 100%"
            direction="column"
            justify="center"
            align="center"
            className={styles.errorContainer}
          >
            <AText breed="BU" size="m" color="line_txt_2" align="center">
              {t(errorMessage)}
            </AText>
          </Box>
        </Box>
      );
    }

    if (errorMessage === 'sportsbook.events.stream.notFound') {
      return (
        <Box
          data-role="event-stream-error-notFound"
          flex="0 0 auto"
          justify="center"
          align="center"
          className={styles.playerContainer}
        >
          <Box
            flex="1 0 100%"
            direction="column"
            justify="center"
            align="center"
            className={styles.errorContainer}
          >
            <Image
              name="general/looking_for"
              className={styles.errorIcon}
              width="90px"
              height="90px"
              alt=""
            />
            <AText breed="BU" size="m" color="line_txt_2">
              {t(errorMessage)}
            </AText>
          </Box>
        </Box>
      );
    }

    // otherwise, if error from backend or custom error
    return (
      <Box
        data-role="event-stream-error-custom"
        flex="0 0 auto"
        justify="center"
        align="center"
        className={styles.playerContainer}
      >
        <Box
          flex="1 0 100%"
          direction="column"
          justify="center"
          align="center"
          className={styles.errorContainer}
        >
          <AText breed="R" size="s" color="line_txt_2">
            {t(errorMessage)}
          </AText>
          <Box justify="center" align="center" top="m">
            <AText breed="BU" size="m" color="line_txt_2">
              {t('sportsbook.events.stream.action')}
            </AText>
          </Box>
        </Box>
      </Box>
    );
  }
);

export default Stream;
