import { useCallback, useEffect, useState } from 'react';
import { getIbanCards } from '@namespace/user';
import { PAYMENT_SYSTEMS } from '@namespace/payments';

export const useIbanCards = ({ currency }) => {
  const [ibanList, setIbanList] = useState([]);

  const fetchAndSetIbanList = useCallback(async () => {
    const ibanListResponse = await getIbanCards({
      payment_instrument_id: PAYMENT_SYSTEMS.CASHDESK,
      currency
    });

    setIbanList(ibanListResponse);
  }, [currency]);

  useEffect(() => {
    fetchAndSetIbanList();
  }, [fetchAndSetIbanList]);

  return ibanList;
};
