import { useContext } from 'react';
import { getCountryIdFromCookie, IS_PRERENDER } from '@namespace/helpers';
import useOtherSettings from '../useOtherSettings';
import { SiteSettingsContext } from '../../store/siteSettings/context';

export const useCookieManagementEnabled = () => {
  const { cookieManagement = {} } = useOtherSettings();
  const {
    isShowCookieManagement = false,
    availableCountries
  } = cookieManagement;
  const cookieCountryId = getCountryIdFromCookie();

  const [settings] = useContext(SiteSettingsContext);
  const { siteConfig = {} } = settings || {};
  const { defaultCountry = 'UA' } = siteConfig;

  const countryId = cookieCountryId || defaultCountry;

  return (
    isShowCookieManagement &&
    (!availableCountries?.length ||
      availableCountries?.some((country) => country === countryId)) &&
    !IS_PRERENDER
  );
};
