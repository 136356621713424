import { useCallback } from 'react';
import { matchPath } from 'react-router-dom';
import { useHistory } from '@namespace/router';
import { window, removeLangFromPathname } from '@namespace/helpers';
import { useCurrentTour } from '../useCurrentTour';

export const useAfterTourRedirect = (shouldRedirect) => {
  const { push } = useHistory();
  const { redirectToPage } = useCurrentTour();

  return useCallback(() => {
    if (!shouldRedirect) {
      return;
    }

    const locationMatch = matchPath(
      {
        path: redirectToPage,
        end: true
      },
      removeLangFromPathname(window.location.pathname)
    );

    if (locationMatch) {
      return;
    }

    push(redirectToPage);
  }, [push, redirectToPage, shouldRedirect]);
};
