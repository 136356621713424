import { createSelector } from 'reselect';
import {
  filter,
  flatten,
  map,
  pick,
  pipe,
  prop,
  propEq,
  sum,
  values,
  when
} from 'ramda';
import { useSportsbookSelector } from '../../store';

export const selectSportsEventCount = (serviceIds = [], sportId) =>
  createSelector(
    prop('sports'),
    pipe(
      pick(serviceIds),
      values,
      flatten,
      when(
        () => sportId != null, // prettier-ignore
        filter(propEq('sportId', sportId))
      ),
      map(prop('eventCount')),
      sum
    )
  );

export const useSportsEventCount = (serviceIds = [], sportId) =>
  useSportsbookSelector(selectSportsEventCount, [serviceIds, sportId]);
