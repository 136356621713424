import { useContext, useEffect } from 'react';
import {
  find,
  flip,
  groupBy,
  includes,
  map,
  pick,
  pipe,
  prop,
  uniq,
  whereEq
} from 'ramda';
import { useAdvFilterStore } from '../../../../../../storeAdvFilter';
import {
  AdvFilterInnerContext,
  useAdvFilterInnerCtxActions
} from '../../store';

const getKey = ({ status }) => (status === 'active' ? status : 'inactive');

export const useSetFetchedFilter = () => {
  const advFilter = useAdvFilterStore('advFilter');
  const advFilterSports = useAdvFilterStore('advFilterSports');
  const { useStore: useAdvFilterInnerStore } = useContext(
    AdvFilterInnerContext
  );
  const { SET_FILTER_SELECTION } = useAdvFilterInnerCtxActions();

  useEffect(() => {
    const { filter: existingFilter } = useAdvFilterInnerStore.getState();
    let filter;
    let inactiveFilter;
    let selectedSportId;

    if (advFilter?.length >= 0 && advFilterSports.length > 0) {
      ({ active: filter = [], inactive: inactiveFilter } = groupBy(getKey)(
        advFilter
      ));

      if (existingFilter?.length > 0) {
        // take existingFilter and replace same items with new filter items,
        // discard non-existing new items
        filter = existingFilter.map((existingFilterItem) => {
          const predicate = whereEq(
            pick(['sportId', 'categoryId', 'tournamentId'], existingFilterItem)
          );
          const newItem = filter.find(predicate);

          return newItem ?? existingFilterItem;
        });
      } else {
        // prettier-ignore
        const selectedSportIds = pipe(
          map(prop('sportId')),
          uniq
        )(filter);

        selectedSportId = pipe(
          map(prop('id')),
          find(flip(includes)(selectedSportIds))
        )(advFilterSports);
      }
    }

    SET_FILTER_SELECTION({
      filter,
      inactiveFilter,
      selectedSportId
    });
  }, [
    SET_FILTER_SELECTION,
    advFilter,
    advFilterSports,
    useAdvFilterInnerStore
  ]);
};
