export const types = {
  SET_SW_CONFIG: 'SET_SW_CONFIG',
  GET_SW_CONFIG: 'GET_SW_CONFIG',
  SET_ACTIVE_SERVICE: 'SET_ACTIVE_SERVICE',
  SET_ACTIVE_PAYMENT_TYPE: 'SET_ACTIVE_PAYMENT_TYPE',
  GET_USER_TOKENS: 'GET_USER_TOKENS',
  SET_USER_TOKENS: 'SET_USER_TOKENS',
  ADD_USER_TOKEN: 'ADD_USER_TOKEN',
  UPDATE_USER_TOKEN: 'UPDATE_USER_TOKEN',
  SET_DEPOSIT_RESULT: 'SET_DEPOSIT_RESULT',
  DEPOSIT: 'DEPOSIT',
  WITHDRAW: 'WITHDRAW'
};
