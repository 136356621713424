const getIsPageCorrect = (pathname, config) => {
  if (config) {
    const {
      path = '',
      general: { pageConfig: { routerParamsLength } = {} }
    } = config;

    if (!routerParamsLength?.length) {
      return true;
    }

    const differences = pathname
      .replace(path, '')
      .split('/')
      .filter((i) => i).length;

    const isPageExist =
      differences >= routerParamsLength[0] &&
      differences <= routerParamsLength[1];

    if (!isPageExist) {
      return false;
    }

    return true;
  }
  return true;
};

export default getIsPageCorrect;
