import { matchPath } from 'react-router-dom';
import {
  getSessionStorageValue,
  SESSION_STORAGE_KEYS
} from '@namespace/helpers';
import { useMatchPath, useSearchParams } from '@namespace/router';
import { useGeneralRoutes } from '@namespace/cms';
import { isEmpty } from 'lodash';

const useLoginSuccessRedirectLink = ({ isFastLogin = false } = {}) => {
  const { redirectUrl, highlightSeparately } = useSearchParams();
  const { forgotPassword, register, home = '' } = useGeneralRoutes();
  const redirectRoute = highlightSeparately
    ? `${redirectUrl}&highlightSeparately=${highlightSeparately}`
    : redirectUrl;
  const prevPathname = getSessionStorageValue(
    SESSION_STORAGE_KEYS.PREV_PATHNAME,
    ''
  );
  const sessionRedirectURL = getSessionStorageValue(
    SESSION_STORAGE_KEYS.REDIRECT_URL
  );

  const prevPathIsNotPathname = isEmpty(
    useMatchPath([prevPathname], {
      isReturnFullMatch: true
    })
  );
  const prevPathIsNotForgotPasswordPage = !matchPath(
    { path: prevPathname },
    forgotPassword
  );
  const prevPathIsNotRegisterPage = !matchPath(
    { path: prevPathname },
    register
  );

  return (
    sessionRedirectURL ||
    redirectRoute ||
    (!isFastLogin &&
      (prevPathIsNotPathname &&
      prevPathIsNotForgotPasswordPage &&
      prevPathIsNotRegisterPage
        ? prevPathname
        : home))
  );
};

export default useLoginSuccessRedirectLink;
