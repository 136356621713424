export const SUCCESS_REGISTER_ACTIONS = {
  AUTO_LOGIN: 'autoLogin',
  REDIRECT_TO_LOGIN: 'redirectToLogin'
};

export const RECAPTCHA_PLACE = {
  LOGIN: 'Login',
  REGISTER: 'Register'
};

export const RECAPTCHA_VERSION = {
  V3: 'v3',
  V2: 'v2'
};
