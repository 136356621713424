import { useContext } from 'react';
import { isEmpty } from 'lodash';
import { VirtualSportsContext } from '../../store/context';
import SportsGrid from './components/SportsGrid';
import { VIRTUAL_SPORTS_FEED_LOBBY_CATEGORY_ID } from '../../constants';

const SportsLobby = () => {
  const [{ virtualSports, feed, isLoaded }] = useContext(VirtualSportsContext);
  const { categories, games } = feed;

  const feedLobbyCategory =
    categories.data[VIRTUAL_SPORTS_FEED_LOBBY_CATEGORY_ID];
  const feedSports = isEmpty(feedLobbyCategory?.games)
    ? []
    : feedLobbyCategory.games.map((gameId) => games.data?.[gameId]);

  const sports = [...Object.values(virtualSports), ...feedSports].filter(
    (item) => !isEmpty(item)
  );

  return <SportsGrid sports={sports} isLoaded={isLoaded && games.isLoaded} />;
};

export default SportsLobby;
