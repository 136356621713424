import { Icon } from '@namespace/icons2';
import { AText, Box } from '@alf/uikit';
import styles from './index.module.css';

const Title = ({
  sportName,
  categoryName,
  onToggleMenu,
  isCategoriesOpened
}) => (
  <Box align="center" className={styles.wrapper}>
    <AText breed="BU" size="xl" color="line_header_txt" overflow="ellipsis">
      {[sportName, categoryName].filter(Boolean).join(' | ')}
    </AText>
    <Box align="center" margin="0 s" onClick={onToggleMenu}>
      <Icon
        name={`icons/general/nav/${isCategoriesOpened ? 'collapse' : 'expand'}`}
        color="line_header_txt"
        size="s"
      />
    </Box>
  </Box>
);

export default Title;
