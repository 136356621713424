export const VIP_BETSLIP_STATUS_TRADING = 1101;
export const VIP_BETSLIP_CARD_CHANGED = 1102;
export const VIP_BETSLIP_INFO_ERRORS = [1100, VIP_BETSLIP_STATUS_TRADING];
export const BETSLIP_INFO_ERRORS = [...VIP_BETSLIP_INFO_ERRORS];

export const BET_SLIP_STATUS_MAP = {
  ACTIVE: 'active',
  CONFIRMATION: 'confirmation',
  SUCCESS: 'success'
};

export const BET_TYPE_SINGLE = 1;
export const BET_TYPE_MULTIPLE = 2;
export const BET_TYPE_SYSTEM = 3;
export const BET_TYPE_COMPLEX_SYSTEM = 4; // aka multi-system
export const BET_TYPES_KEYS = {
  '-1': 'all',
  [BET_TYPE_SINGLE]: 'single',
  [BET_TYPE_MULTIPLE]: 'multiple',
  [BET_TYPE_SYSTEM]: 'system',
  [BET_TYPE_COMPLEX_SYSTEM]: 'complexSystem'
};

export const TYPE_CHANGES = {
  ALL_WARNINGS: 1,
  ACCEPT_BETTER_ODDS: 2,
  ACCEPT_ALL_ODDS: 3
};

export const PRESET_BET_AMOUNT_TYPE = {
  REPLACE: 'replace',
  REDUCE: 'reduce'
};

export const ODDS_WARNINGS_IDS = {
  UP: 1022,
  DOWN: 1023
};

export const MULTIBETSLIP_LABELS = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];

export const PREDEFINED_AMOUNTS_DEFAULT = ['50', '100', '200'];
export const PREDEFINED_STATES = {
  DONE: 'done',
  CLOSE: 'close',
  EDIT: 'edit'
};
