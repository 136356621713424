import { useEffect, useMemo } from 'react';
import { useLocation } from '@namespace/i18n';
import { useGeneralRoutes } from '@namespace/cms';
import { useMatchPath, useHistory } from '@namespace/router';
import { keyBy } from 'lodash';
import { DEFAULT_ACTIVE_TAG } from '../../constants';

export const usePromotionsRoutes = (
  enabledPromoTypes = [],
  enabledTags = {},
  validTags = []
) => {
  const { replace } = useHistory();
  const { pathname } = useLocation();
  const { promotions, promotion, tournament } = useGeneralRoutes();
  const { category, tag } = useMatchPath([
    `${promotions}/:category`,
    `${promotions}/:category/:tag`
  ]);

  const currentTags = useMemo(
    () =>
      validTags?.length > 0 ? keyBy(validTags, 'categoryName') : enabledTags,
    [enabledTags, validTags]
  );

  const defaultTag =
    Object.values(currentTags)[0]?.categoryName || DEFAULT_ACTIVE_TAG;
  const isCorrectTag = Object.keys(currentTags).some(
    (currentTag) => currentTag === tag
  );
  const isCorrectCategory = enabledPromoTypes.find((type) => type === category);
  const isIncorrectPromoRoute = !isCorrectTag || !isCorrectCategory;
  const isEmptyPromoPage = pathname === `${promotion}/`;
  const isPromoPageDetails =
    pathname.includes(`${promotion}/`) || pathname.includes(`${tournament}/`);
  const isPromoPage = pathname.includes(`${promotions}/`);

  useEffect(() => {
    if (isEmptyPromoPage) {
      replace(promotions);
    }
  }, [isEmptyPromoPage, promotions, replace]);

  useEffect(() => {
    if (
      enabledPromoTypes.length > 0 &&
      isPromoPage &&
      (isEmptyPromoPage || (isIncorrectPromoRoute && !isPromoPageDetails))
    ) {
      const categoryToRedirect = isCorrectCategory
        ? category
        : enabledPromoTypes[0];
      replace(`${promotions}/${categoryToRedirect}/${defaultTag}`);
    }
  }, [
    pathname,
    enabledPromoTypes,
    promotions,
    replace,
    isCorrectCategory,
    defaultTag,
    isPromoPage,
    isEmptyPromoPage,
    isIncorrectPromoRoute,
    isPromoPageDetails,
    category
  ]);
};
