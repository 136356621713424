import { Box } from '@alf/uikit';
import PromoSection from './components/PromoSection';
import styles from './index.module.css';

const SplitPromos = ({
  activePromotions = [],
  customClassNames,
  splitCategories = []
}) => {
  const categorizedPromos = splitCategories.reduce((acc, category) => {
    acc[category] = activePromotions
      .filter((item) => item?.tags?.includes(category))
      .map((item) => ({
        ...item,
        tags: item?.tags?.filter((value) => value !== category)
      }));
    return acc;
  }, {});

  return (
    <Box direction="column" flexGrow={1} className={styles.contentWrapper}>
      {splitCategories.map((name) => {
        if (categorizedPromos[name].length === 0) {
          return null;
        }

        return (
          <PromoSection
            name={name}
            key={name}
            promos={categorizedPromos[name]}
            customClassNames={customClassNames}
          />
        );
      })}
    </Box>
  );
};

export default SplitPromos;
