import { lensProp, map, over } from 'ramda';

const parseAndDivide = (divisor = 1) => (value) =>
  parseFloat(value) / divisor || 0;

export const parseGeneralJackpot = ({ globalJackpotDivisor = 1 }) =>
  over(lensProp('sum'), parseAndDivide(globalJackpotDivisor));

export const parseProviderJackpot = ({ providerJackpotDivisor = 1 }) =>
  map(({ currentWinAmount, largestWinAmount, winAmount }) => {
    const parse = parseAndDivide(providerJackpotDivisor);

    return {
      currentWinAmount: parse(currentWinAmount),
      largestWinAmount: parse(largestWinAmount),
      winAmount: parse(winAmount)
    };
  });
