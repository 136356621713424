import { useContext, useEffect } from 'react';
import { path } from 'ramda';
import { useI18n } from '@namespace/i18n';
import { USER_ROLES, UserContext } from '@namespace/user';
import { SiteSettingsContext, useOtherSettings } from '@namespace/cms';
import { MODE } from '../../../../../../constants';
import { advFilterActions } from '../../../../../../storeAdvFilter';

const { FETCH_ADV_FILTER } = advFilterActions;

export const useFetchFilter = () => {
  const { language } = useI18n();
  const [{ role }] = useContext(UserContext);
  const partnerId = path([0, 'siteConfig', 'partnerId'])(
    useContext(SiteSettingsContext)
  );
  const { isSportsBookAdvFilterEnabled } = useOtherSettings();

  useEffect(() => {
    if (!isSportsBookAdvFilterEnabled || role !== USER_ROLES.USER) {
      return;
    }

    FETCH_ADV_FILTER({
      lang: language,
      partnerId,
      serviceId: MODE.PRE_MATCH
    });
  }, [isSportsBookAdvFilterEnabled, language, partnerId, role]);
};
