import { SportIcon } from '@namespace/icons2';
import { AText, Box } from '@alf/uikit';
import { useEventFields } from '../../../../../../../../store';
import styles from './index.module.css';

export const EventTitle = ({ eventId }) => {
  const { sportId, sportName, categoryName, tournamentName } = useEventFields({
    eventId,
    fields: ['sportId', 'sportName', 'categoryName', 'tournamentName']
  });

  return (
    <Box className={styles.container} align="center" padding="xs s 0">
      <SportIcon
        color="line_infotxt_5"
        sportId={sportId}
        size="xs"
        className={styles.sportIcon}
      />
      <AText
        data-role="live-calendar-sport-name"
        size="s"
        className={styles.sportName}
        color="line_txt_4"
        transform="uppercase"
      >
        {sportName}
      </AText>
      <AText
        size="s"
        color="line_subheader_txt"
        transform="uppercase"
        className={styles.tournament}
      >
        {`${categoryName} | ${tournamentName}`}
      </AText>
    </Box>
  );
};
