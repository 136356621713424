import PropTypes from 'prop-types';
import AText from '../AText';
import Box from '../Box';
import styles from './index.module.css';

const Tooltip = ({ children, className = '' }) => (
  <Box padding="xs s" className={`${styles.inputTooltip} ${className}`}>
    {children && (
      <AText breed="R" size="s" color="txt_color_4">
        {children}
      </AText>
    )}
  </Box>
);

Tooltip.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};

export default Tooltip;
