import { Helmet } from '@namespace/common';
import { AText, Box, Link } from '@alf/uikit';
import { LocalizedLink } from '@namespace/i18n';
import Icon404 from './icons/icon404.svg';
import styles from './index.module.css';

const DefaultErrorPage = ({
  errorCode = '',
  text = '',
  link = '',
  buttonText = ''
}) => {
  return (
    <>
      <Helmet>
        <meta name="prerender-status-code" content={errorCode} />
      </Helmet>
      <Box
        className={styles.page404}
        direction="column"
        justify="center"
        align="center"
      >
        <Box
          className={styles.contentWrapper}
          direction="column"
          justify="end"
          align="center"
        >
          <Icon404 className={styles.icon404} />
          <Box
            className={styles.textContainer}
            direction="column"
            align="center"
          >
            <AText className={styles.text404} color="txt_color_7">
              {errorCode}
            </AText>
            <AText
              className={styles.text}
              breed="BU"
              size="xl"
              color="txt_color_7"
            >
              {text}
            </AText>
            {link && (
              <Link
                Component={LocalizedLink}
                to={link}
                className={styles.homeLink}
                intent="primary"
              >
                {buttonText}
              </Link>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DefaultErrorPage;
