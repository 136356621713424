import { useReducer, useMemo } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import { actions as actionCreators } from '../actions';
import { reducers, initialState } from '../reducer';
import { types } from '../types';
import { SWPaymentsContext } from '../context';

export const SWPaymentsProvider = ({ children }) => {
  const memoReducers = useMemo(() => combineReducers(reducers), []);

  const [state, dispatch] = useReducer(memoReducers, initialState);

  const actions = useActions(types, dispatch, actionCreators);

  return (
    <SWPaymentsContext.Provider value={[state, actions]}>
      {children}
    </SWPaymentsContext.Provider>
  );
};
