import { Box } from '@alf/uikit';
import styles from './index.module.css';

const CardSkelet = ({ className }) => {
  return (
    <Box className={styles.wrapperSkelet}>
      <div className={`${styles.skelet} ${styles.skeletBlock} ${className}`} />
      <div className={`${styles.skelet} ${styles.skeletBlock} ${className}`} />
      <div className={`${styles.skelet} ${styles.skeletBlock} ${className}`} />
      <div className={`${styles.skelet} ${styles.skeletBlock} ${className}`} />
      <div className={`${styles.skelet} ${styles.skeletBlock} ${className}`} />
    </Box>
  );
};

export default CardSkelet;
