import { types } from '../types';

export const initialState = {
  colorScheme: {}, // Example data: { selectedColorMode: dark | lite, activeTheme: theme,  activeColorScheme: dark | lite | auto }
  componentsConfig: {}
};

export const reducers = {
  [types.SET_COLOR_SCHEME]: (state, colorScheme) => {
    return {
      ...state,
      colorScheme: {
        ...state.colorScheme,
        ...colorScheme
      }
    };
  }
};
