import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import styles from './index.module.css';

const Price = ({ price, options = {} }) => {
  const { t } = useI18n();

  return (
    <Box align="center" {...options} data-role="shop-item-price">
      <AText breed="B" size="l" color="txt_color_1">
        {price}
      </AText>
      <AText
        breed="RU"
        size="2xs"
        color="txt_color_4"
        className={styles.points}
      >
        {t('bonusShop.productList.points')}
      </AText>
    </Box>
  );
};

export default Price;
