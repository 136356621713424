import { useContext, useCallback } from 'react';
import { StepperContext } from '@namespace/common';
import EnterSecretQuestion from '../EnterSecretQuestion';
import CancelButton from '../CancelButton';
import { checkSecretAnswer } from '../../api';
import styles from './index.module.css';

const CheckSecretQuestion = ({ onStepSuccess, onStepError, onGoFirst }) => {
  const {
    stepper: {
      formData: { question = '', answer = '', token = '' },
      errorCode = ''
    }
  } = useContext(StepperContext);

  const handleSubmit = useCallback(
    async ({ secretAnswer }) => {
      await checkSecretAnswer({
        question,
        answer: secretAnswer,
        token
      })
        .then(({ response }) => onStepSuccess({ ...response }))
        .catch(
          ({ errorCode: stepErrorCode }) =>
            onGoFirst() || onStepError({ errorCode: stepErrorCode })
        );
    },
    [question, token, onStepSuccess, onGoFirst, onStepError]
  );

  return (
    <div className={styles.step}>
      <EnterSecretQuestion
        secretAnswer={answer}
        secretQuestion={question}
        onSuccess={handleSubmit}
        errorCode={errorCode}
      />
      <CancelButton onCancel={onGoFirst} />
    </div>
  );
};

export default CheckSecretQuestion;
