import { useState, useContext, useCallback } from 'react';
import { Button, AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { DeviceContext } from '@namespace/device';
import { useGeneralRoutes } from '@namespace/cms';
import SkeletSportItem from '../SkeletSportItem';
import styles from './index.module.css';

const SportsGridItem = ({ category }) => {
  const { categoryId, categoryName, provider, isFrame, lobbyImage } = category;
  const { virtualSportsOneSport, virtualSportsFrame } = useGeneralRoutes();
  const [isShowButton, showButton] = useState(false);
  const { isDesktop } = useContext(DeviceContext);
  const { push } = useHistory();
  const link = `${
    isFrame ? virtualSportsFrame : virtualSportsOneSport
  }/${provider}/${categoryId}/`;
  const { t } = useI18n();
  const onSportLink = useCallback(() => push(link), [link]);
  const [isLoaded, setLoaded] = useState(false);
  const changeLoader = useCallback(() => setLoaded(true), [isLoaded]);

  return (
    <Box
      className={styles.imageWrapper}
      key={categoryId}
      onMouseOver={() => showButton(true)}
      onMouseLeave={() => showButton(false)}
      onFocus={() => showButton(true)}
      onBlur={() => showButton(false)}
      onClick={!isDesktop ? onSportLink : () => {}}
    >
      <img
        alt={`${categoryName}`}
        src={lobbyImage || `/images/virtualSports/${categoryId}.png`}
        key={categoryId}
        className={`${styles.image} ${isLoaded ? styles.show : styles.hide}`}
        onLoad={changeLoader}
      />
      <SkeletSportItem className={!isLoaded ? styles.show : styles.hide} />
      {isShowButton && isDesktop && (
        <Box className={styles.buttonWrapper} align="center" justify="center">
          <Box className={styles.buttonBackground} />
          <Button
            className={styles.button}
            onClick={onSportLink}
            intent="quinary"
            actionType="color2"
          >
            {t('virtualSports.playNow')}
          </Button>
        </Box>
      )}
      <Box className={styles.imageText} align="center">
        <AText breed="BU" size="m" color="txt_color_4">
          {categoryName}
        </AText>
      </Box>
    </Box>
  );
};

export default SportsGridItem;
