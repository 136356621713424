import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useI18n } from '@namespace/i18n';
import { getEventsBySports } from '../../api';
import { getEventsRequestParams } from './utils';
import { useFetchEventsFilter } from '../useFetchEventsFilter';
import { sportsbookActions, useSportsbookStore } from '../../store';
import { useAdvFilterStore } from '../../storeAdvFilter';

const { SET_EVENTS } = sportsbookActions;

export const useCustomFilterGroups = ({ filter }) => {
  const [groupsData, setGroupsData] = useState([]);
  const { language: lang } = useI18n();
  const [isGroupsLoaded, setIsGroupsLoaded] = useState(false);
  const advFilter = useAdvFilterStore('advFilter');
  const advFilterSportsById = useAdvFilterStore('advFilterSportsById');
  const advFilterCategoriesById = useAdvFilterStore('advFilterCategoriesById');
  const lines = useSportsbookStore('lines');
  const filterValue = useFetchEventsFilter(filter);

  useEffect(() => {
    if (!isEmpty(advFilter) && !isEmpty(advFilterSportsById)) {
      (async () => {
        try {
          const sportsData = { advFilterSportsById, advFilterCategoriesById };

          const requestParams = getEventsRequestParams({
            customFiltersData: advFilter,
            sportsData,
            lang,
            filterValue,
            lines
          });

          const { result: eventsGroups } = await getEventsBySports({
            params: requestParams.map(({ requestData }) => requestData)
          });

          setIsGroupsLoaded(true);

          eventsGroups.forEach((eventGroup, index) => {
            const { requestData, additionalData } = requestParams[index];

            SET_EVENTS({
              events: eventGroup,
              lineKey: additionalData.lineKey,
              initialEventsLimit: requestData.limit
            });
          });

          setGroupsData(
            eventsGroups.map((eventsGroup, index) => {
              const { additionalData } = requestParams[index];
              const {
                tournamentIds,
                categoryId,
                sportId,
                sportName,
                categoryName,
                lineKey
              } = additionalData;
              const isCategoryGroup = categoryId || tournamentIds?.length;

              return {
                lineKey,
                sportId,
                sportName,
                ...(isCategoryGroup && {
                  categoryId,
                  categoryName
                }),
                ...(isCategoryGroup &&
                  tournamentIds.length && { tournamentIds })
              };
            })
          );
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [SET_EVENTS, advFilter, advFilterSportsById, filterValue, lang]);

  return {
    isGroupsLoaded,
    groupsData
  };
};
