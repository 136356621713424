import { useEffect } from 'react';
import { useI18n } from '@namespace/i18n';
import { advFilterActions } from '../../../../../../storeAdvFilter';

const { RESET_ADV_FILTER_CACHE } = advFilterActions;

export const useResetAdvFilterCache = () => {
  const { language } = useI18n();

  useEffect(() => {
    RESET_ADV_FILTER_CACHE();
  }, [language]);
};
