import { useContext } from 'react';
import { ThemesContext } from '../../store/context';

const useGetThemeImages = () => {
  const [{ imagesSet = {} }] = useContext(ThemesContext);

  return imagesSet;
};

export default useGetThemeImages;
