import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Icon } from '@namespace/icons2';
import Box from '../Box';
import Button from '../Button';
import styles from './index.module.css';

const SliderItems = ({
  transformStep = 200,
  isDesktop,
  isButtonsEnabled,
  children,
  classNames = {},
  dataRole
}) => {
  const slider = useRef();
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [scrollLength, setScrollLength] = useState(0);
  const [transformLength, setTransformLength] = useState(0);

  const isWithButtons =
    isDesktop && (isButtonsEnabled || !isPrevDisabled || !isNextDisabled);

  useEffect(() => {
    if (slider.current) {
      const { scrollWidth = 0, clientWidth = 0 } = slider.current;
      if (scrollWidth > clientWidth + scrollLength) {
        setScrollLength(scrollWidth - clientWidth + 10);
        setIsNextDisabled(false);
      }
    }
  }, [scrollLength, slider, children]);

  useEffect(() => {
    setIsPrevDisabled(transformLength >= 0);
    setIsNextDisabled(Math.abs(transformLength) >= scrollLength);
  }, [transformLength, scrollLength]);

  const handleNext = () => {
    const transform = transformLength - transformStep;
    setTransformLength(
      Math.abs(transform) > scrollLength ? -scrollLength : transform
    );
  };

  const handlePrev = () => {
    const transform = transformLength + transformStep;
    setTransformLength(transform > 0 ? 0 : transform);
  };

  const { containerClassName = '', buttonClassName = '' } = classNames;

  return (
    <Box
      data-role={dataRole}
      align="center"
      className={`${styles.container} ${containerClassName}`}
    >
      {!isPrevDisabled && isWithButtons && (
        <Button
          disabled={isPrevDisabled}
          onClick={handlePrev}
          intent="quaternary"
          className={`${styles.arrow} ${buttonClassName}`}
        >
          <Icon
            className={styles.icon}
            size="m"
            name="icons/general/nav/back"
          />
        </Button>
      )}
      <Box
        padding="0 ms 0 0"
        className={clsx(styles.wrapper, !isWithButtons && styles.nextDisabled)}
      >
        <Box
          data-role="slider-items-container"
          align="center"
          className={styles.slider}
          style={{ transform: `translateX(${transformLength}px)` }}
          ref={slider}
        >
          {children}
        </Box>
      </Box>
      {!isNextDisabled && isWithButtons && (
        <Button
          disabled={isButtonsEnabled ? false : isNextDisabled}
          intent="quaternary"
          onClick={handleNext}
          className={`${styles.arrow} ${buttonClassName}`}
        >
          <Icon
            className={styles.icon}
            size="m"
            name="icons/general/nav/next"
          />
        </Button>
      )}
    </Box>
  );
};

export default SliderItems;
