import { useEffect, useCallback, useContext } from 'react';
import { socketSubscribe, socketUnsubscribe } from '@namespace/socket';
import useNotifications from '@namespace/notifications/src/hooks/useNotifications';
import { NOTIFICATION_TYPES } from '@namespace/notifications/src/constants';
import { SiteSettingsContext } from '@namespace/cms';

import useNotificationMessage from './hooks/makeNotificationMessage';

export const useRealityCheck = () => {
  const { customNotification } = useNotifications();
  const [settings] = useContext(SiteSettingsContext);
  const { responsibleGambling = {} } = settings || {};
  const {
    isRealityCheck,
    isShowReport,
    hideRealityCheckNotificationPeriod = false
  } = responsibleGambling;

  const notificationMessage = useNotificationMessage({
    hideRealityCheckNotificationPeriod,
    isShowReport
  });

  const callback = useCallback(
    ({ data }) => {
      customNotification({
        message: notificationMessage.create(data),
        notificationType: NOTIFICATION_TYPES.REALITYCHECK,
        timeout: null
      });
    },
    [customNotification, notificationMessage]
  );

  useEffect(() => {
    if (isRealityCheck) {
      socketSubscribe({
        name: 'responsible_gambling_report',
        types: ['responsible_gambling_report'],
        callback
      });
    }
    return () => socketUnsubscribe('responsible_gambling_report');
  }, [isRealityCheck, callback]);
};

export default useRealityCheck;
