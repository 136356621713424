import PropTypes from 'prop-types';
import StatusIcon from '../StatusIcon';
import Box from '../Box';
import AText from '../AText';
import styles from './index.module.css';

const StatusIconWithText = ({
  className = '',
  status = '',
  label = '',
  fontStatusConfig = {}
}) => {
  const { fontStatusSize = 's', fontStatusWeight = 'R' } = fontStatusConfig;
  return (
    <Box align="center" className={className}>
      <StatusIcon status={status} className={styles.icon} />
      <AText
        breed={fontStatusWeight}
        size={fontStatusSize}
        color="po_txt_color_1"
      >
        {label}
      </AText>
    </Box>
  );
};

StatusIconWithText.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
  label: PropTypes.string
};

export default StatusIconWithText;
