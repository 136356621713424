import { useI18n } from '@namespace/i18n';
import { AText, Box } from '@alf/uikit';
import { SCOPE_IDS, SUMMARIES_SCOPE_DATA } from '../../../../constants';
import { useEventFields } from '../../../../store';
import styles from './index.module.css';

const Winner = ({ eventId }) => {
  const {
    participants,
    scoreboard,
    eventResultId,
    eventResultName
  } = useEventFields({
    eventId,
    fields: ['participants', 'scoreboard', 'eventResultId', 'eventResultName']
  });
  const { winner = [] } = scoreboard;
  const { t } = useI18n();

  const { resultTypeData = [] } =
    winner.find(({ resultTypeId }) => resultTypeId === eventResultId) || {};
  const { scopeData } =
    resultTypeData.find(({ scopeId }) => scopeId === SCOPE_IDS.WINNER) || {};
  const [{ number: winnerParticipantNumber }] = scopeData?.filter(
    ({ value }) => value !== 0
  ) || [{}];

  const { participantName = '' } =
    participants.find(
      ({ participantNumber }) => participantNumber === winnerParticipantNumber
    ) || {};

  return (
    <Box justify="center" className={styles.summariesInfo}>
      <AText color="line_txt_1" breed="B" size="s">
        {`${eventResultName}${participantName &&
          `. ${t(
            SUMMARIES_SCOPE_DATA[SCOPE_IDS.WINNER]
          )}: ${participantName}`}`}
      </AText>
    </Box>
  );
};

export default Winner;
