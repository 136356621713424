import { useCallback, useEffect, useMemo, useState } from 'react';
import { FILTER_ALL_VALUE } from '@namespace/common';
import { useI18n } from '@namespace/i18n';
import { groupBy } from 'lodash';
import {
  DATE_FILTER_CMS_VALUES,
  NEXT_MORNING_FILTER
} from '../../../constants';
import { getTabs } from '../../../utils';
import FilterItem from '../FilterItem';
import styles from './index.module.css';

const Date = ({
  filterConfig = {},
  filterValue = {},
  className = '',
  onSetFilter,
  isShowAll,
  isShowStartingSoon,
  isShowNextMorning
}) => {
  const { days = [], hours = [] } = filterConfig;
  const { t, f, language } = useI18n();
  const [tabs, setTabs] = useState([]);
  const tabsByType = useMemo(() => groupBy(tabs, 'dateFilterType'), [tabs]);

  const handleSetFilter = useCallback(
    (value) => {
      if (value === FILTER_ALL_VALUE) {
        return onSetFilter({ [FILTER_ALL_VALUE]: FILTER_ALL_VALUE });
      }
      if (value === NEXT_MORNING_FILTER) {
        return onSetFilter({ [NEXT_MORNING_FILTER]: NEXT_MORNING_FILTER });
      }
      const { dateFilterType: type } = tabs.find(
        ({ value: tabValue }) =>
          tabValue === value || tabValue === Number(value)
      );
      if (
        type === DATE_FILTER_CMS_VALUES.DAYS &&
        value !== filterValue.filterByDate
      ) {
        return onSetFilter({ [type]: value });
      }
      if (
        type === DATE_FILTER_CMS_VALUES.HOURS &&
        Number(value) !== filterValue.filterByRange
      ) {
        return onSetFilter({ [type]: Number(value) });
      }
      return null;
    },
    [filterValue, onSetFilter, tabs]
  );
  const getIsTabActive = (type, value) =>
    (filterValue[type] && filterValue[type] === value) ||
    (isShowNextMorning && filterValue[NEXT_MORNING_FILTER] === value);

  useEffect(() => {
    const tabByDate = getTabs({
      dateFilterType: DATE_FILTER_CMS_VALUES.DAYS,
      t,
      f,
      dateFilters: days
    });
    const tabByHours = getTabs({
      dateFilterType: DATE_FILTER_CMS_VALUES.HOURS,
      t,
      f,
      dateFilters: hours,
      isShowStartingSoon,
      isShowNextMorning
    });

    let filterTabs = [...tabByHours, ...tabByDate];

    if (isShowAll) {
      filterTabs = [
        ...filterTabs,
        {
          name: t('sportsbook.filtering.by.all'),
          value: FILTER_ALL_VALUE,
          dateFilterType: FILTER_ALL_VALUE
        }
      ];
    }
    setTabs(filterTabs);
  }, [filterConfig, language]);

  return (
    <div className={`${styles.container} ${className}`}>
      {Object.entries(tabsByType).map((groupedTabs) => {
        const [type, currentTabs] = groupedTabs;
        return (
          <div
            className={`${styles.groupedTabs} ${styles[type]}`}
            key={`filter-${type}`}
          >
            {currentTabs.map(({ name, value, dateFilterType }) => (
              <FilterItem
                className={styles.tabItem}
                key={`filterItem-${value}`}
                isActive={getIsTabActive(dateFilterType, value)}
                value={value}
                name={name}
                onSetFilter={handleSetFilter}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default Date;
