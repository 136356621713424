import {
  getLocalStorageValue,
  setLocalStorageValue,
  LOCAL_STORAGE_KEYS
} from '@namespace/helpers';
import { sample } from 'lodash';

export const REPLACE = '$name';
export const LANGUAGES = {
  en: 'en-US',
  ru: 'ru-RU',
  uk: 'uk-UA'
};

export const setSpeechValueToLocalStorage = (value) =>
  setLocalStorageValue(LOCAL_STORAGE_KEYS.SPEECH, value);

export const getSpeechValueFromLocalStorage = () => {
  getLocalStorageValue(LOCAL_STORAGE_KEYS.SPEECH);
};

export const getRandomItem = (commands) => {
  if (Array.isArray(commands)) {
    return sample(commands);
  }
  return commands;
};

export const callingSuitableCallback = (callbacksObj) => {
  let arr = [];
  return (action) => {
    arr.push(action);
    if (arr.length === 1) {
      setTimeout(() => {
        const [props] = arr.sort((a, b) => b.threshold - a.threshold);
        callbacksObj.current.callback(props);
        arr = [];
      }, 500);
    }
  };
};

export const onSuccessSpeechEnd = (successResponse, voice, onEndPlayback) => {
  if (voice) {
    const utterance = new SpeechSynthesisUtterance(successResponse);
    utterance.voice = voice;
    speechSynthesis.speak(utterance);
  }
  onEndPlayback();
};

export const getFormattedCommands = ({ commandsParams = [], pushCallBack }) => {
  return commandsParams.reduce((acc, item) => {
    const {
      action,
      dictionary,
      responses: { success },
      url
    } = item;
    const successMassage = getRandomItem(success);
    const threshold = item.threshold
      ? {
          bestMatchOnly: true,
          isFuzzyMatch: true,
          fuzzyMatchingThreshold: item.threshold
        }
      : {};
    const commands = item.commands.reduce((commandAcc, command) => {
      let result = [];
      if (dictionary) {
        const dictionaryKeys = Object.keys(dictionary);
        const declinationsInsideCommand = command.includes(REPLACE);
        result = Object.values(dictionary).reduce(
          (declinationsAcc, declinations, index) => {
            const ultimateCommand = declinationsInsideCommand
              ? command.replace(REPLACE, dictionaryKeys[index])
              : `${command} ${dictionaryKeys[index]}`;
            const ultimateCommands =
              declinations.length > 0
                ? declinations.map((declination) =>
                    declinationsInsideCommand
                      ? command.replace(REPLACE, declination)
                      : `${command} ${declination}`
                  )
                : ultimateCommand;

            return [
              ...declinationsAcc,
              {
                command: ultimateCommands,
                callback: (
                  expectedCommand,
                  receivedCommand,
                  thresholdValue
                ) => {
                  pushCallBack({
                    action,
                    expectedCommand,
                    receivedCommand,
                    threshold: thresholdValue,
                    dictionaryKey: dictionaryKeys[index],
                    successMassage
                  });
                },
                ...threshold
              }
            ];
          },
          []
        );
      } else {
        result = [
          {
            command,
            callback: (expectedCommand, receivedCommand, thresholdValue) => {
              pushCallBack({
                action,
                expectedCommand,
                receivedCommand,
                threshold: thresholdValue,
                dictionaryKey: '',
                url,
                successMassage
              });
            },
            ...threshold
          }
        ];
      }
      return [...commandAcc, ...result];
    }, []);
    return [...acc, ...commands];
  }, []);
};

export const getVoice = (voices, language) => {
  const voiceMilena = voices.find((v) => v.name === 'Milena');
  const availableVoice = voices.find((v) =>
    v.lang.includes(LANGUAGES[language])
  );
  if (language === 'ru') {
    return voiceMilena || availableVoice || null;
  }
  return availableVoice || null;
};
