import { useCallback } from 'react';
import { useHistory } from '@namespace/router';
import { scrollTo } from '@namespace/helpers';
import { useCategoriesByIds, useTournamentsByIds } from '../../store';
import MenuItem from '../MenuItem';

export const Tournament = ({
  tournamentId,
  isActive,
  serviceId,
  basePath,
  className = ''
}) => {
  const [tournament = {}] = useTournamentsByIds(
    tournamentId ? [tournamentId] : [],
    serviceId
  );
  const { tournamentName, categoryId, eventCount } = tournament;
  const [category = {}] = useCategoriesByIds(
    categoryId ? [categoryId] : [],
    serviceId
  );
  const { countryId } = category;
  const { push } = useHistory();

  const handleClick = useCallback(() => {
    push(`${basePath}/${tournamentId}`);
    scrollTo();
  }, [basePath, push, tournamentId]);

  return (
    <MenuItem
      className={className}
      withCounter={true}
      name={tournamentName}
      countryId={countryId}
      onClick={handleClick}
      count={eventCount}
      isActive={isActive}
      withPinIcon={false}
      dataAttr={{ 'data-role': `tournament-id-${tournamentId}` }}
    />
  );
};
