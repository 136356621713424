import {
  formatAsUriParameters,
  getAsJson,
  postAsJson
} from '@namespace/helpers';
import { camelizeKeys } from 'humps';

export const getTokens = (params) => {
  const queryParams = formatAsUriParameters(params);
  return getAsJson(`/accounting/api/v1/payment_accounts?${queryParams}`).then(
    camelizeKeys
  );
};

export const addToken = (params) => {
  return postAsJson('/accounting/api/v1/payment_accounts', params).then(
    camelizeKeys
  );
};

export const updateToken = (params) => {
  return postAsJson('/accounting/api/v1/payment_accounts/update', params).then(
    camelizeKeys
  );
};
