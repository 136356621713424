import { useRef } from 'react';
import { Notification, Overlay } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { useFixScroll } from '@namespace/common';
import { useLogout } from '@namespace/user';
import { POST_MESSAGE_TYPES, usePostMessage } from '@namespace/widget';

const { SESSION_EXPIRED } = POST_MESSAGE_TYPES;

const SessionExpiredToast = ({ message, onMouseEnter, onMouseLeave }) => {
  const { t } = useI18n();
  const { logout } = useLogout();
  const contRef = useRef(null);
  const { postMessage } = usePostMessage();

  useFixScroll('sessionExpireCheck', contRef, true);

  const onClick = async () => {
    await logout();
    postMessage(SESSION_EXPIRED, '');
  };

  return (
    <div ref={contRef}>
      <Overlay />
      <Notification
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        message={message}
        onClick={onClick}
        onClose={logout}
        buttonLabel={t('sessionExpireCheck.notice.ok')}
      />
    </div>
  );
};

export default SessionExpiredToast;
