import clsx from 'clsx';
import { Box } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import styles from './index.module.css';

export const Loader = ({ isOverlay = false }) => (
  <Box
    align="center"
    justify="center"
    className={clsx([styles.root, isOverlay && styles.overlay])}
  >
    <Icon
      size="3xl"
      name="icons/general/state/loader"
      color="accent_color_6"
      className={styles.icon}
    />
  </Box>
);
