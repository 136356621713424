import { useGeneralRoutes } from '@namespace/cms';
import Routes from '../Routes';
import { MODE } from '../../constants';

const LiveRoutes = () => {
  const { liveSport } = useGeneralRoutes();

  return <Routes serviceId={MODE.IN_PLAY} servicePath={liveSport} />;
};

export default LiveRoutes;
