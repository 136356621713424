import { useContext, useMemo } from 'react';
import { Helmet, usePageMetaData } from '@namespace/common';
import { SiteSettingsContext } from '@namespace/cms';
import { useI18n, useLocation } from '@namespace/i18n';
import { window } from '@namespace/helpers';
import { useNativeAppBannerMetaTag } from '../../hooks/useNativeAppBannerMetaTag';

const HeaderLinks = () => {
  const { pathname } = useLocation();
  const { language, t } = useI18n();
  const defaultTitle = t('page.defaultTitle');
  const defaultDescription = t('page.defaultTitle');
  const [settings] = useContext(SiteSettingsContext);
  const { siteConfig = {}, otherSettings = {} } = settings || {};
  const { domainsByLocale = {} } = otherSettings;
  const {
    defaultLanguage,
    rootDomain = '',
    favicon,
    locales = [],
    siteLogo = ''
  } = siteConfig;
  const {
    title = defaultTitle,
    description = defaultDescription,
    robots,
    canonical
  } = usePageMetaData();
  const currentCanonical =
    Array.isArray(canonical) && canonical.find(({ lang }) => lang === language);
  const [location] = window.location.href.replace(/\/m\./, '/www.').split('?');
  const canonicalPageURL = currentCanonical ? currentCanonical.text : location;

  const altLinks = useMemo(() => {
    const links = [];
    for (const { lang, locales: locs } of locales) {
      for (const locale of locs) {
        const url = `${domainsByLocale[locale] || window.location.origin}${
          pathname === '/' &&
          (defaultLanguage === lang || domainsByLocale[locale] !== undefined)
            ? ''
            : `/${lang === 'uk' || lang === 'ru' ? 'uk' : lang}`
        }${pathname}`;

        links.push({
          lang,
          locale,
          url
        });
      }
    }

    return links;
  }, [defaultLanguage, domainsByLocale, locales, pathname]);

  const isNoIndex = !rootDomain.includes(window.location.hostname);
  const xDefaultUrl =
    pathname !== '/'
      ? `${window.location.origin}/${defaultLanguage}${pathname}`
      : `${window.location.origin}/`;

  const nativeAppBannerMetaTag = useNativeAppBannerMetaTag();

  return (
    <Helmet>
      <link rel="canonical" href={canonicalPageURL} />
      {isNoIndex && <meta name="robots" content="noindex, nofollow" />}
      {robots && <meta name="robots" content={robots} />}
      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
      {title && <title>{title}</title>}
      {title && <meta property="og:title" content={title} />}
      {altLinks.map((altLink) => (
        <link
          key={`${altLink.lang}_${altLink.locale}`}
          rel="alternate"
          hrefLang={altLink.locale}
          href={altLink.url}
        />
      ))}
      <link rel="alternate" hrefLang="x-default" href={xDefaultUrl} />
      <meta property="og:url" content={xDefaultUrl} />
      {favicon && <link rel="shortcut icon" href={favicon} />}
      {siteLogo && <meta property="og:image" content={siteLogo} />}
      {nativeAppBannerMetaTag}
    </Helmet>
  );
};

export default HeaderLinks;
