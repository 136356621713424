import { Button, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { Icon } from '@namespace/icons2';
import {
  FORM_VALIDATION_TYPES,
  formBuilderValidationMap,
  InputField
} from '@namespace/forms';
import { createValidationRules } from '@alf/validators';
import { useFormContext } from 'react-hook-form';
import { CARD_INFO } from '../../../constants';
import styles from './index.module.css';

const CardDescription = ({ activeCard, saveDescription, isSingleWallet }) => {
  const methods = useFormContext();
  const {
    getValues,
    formState: { errors }
  } = methods;
  const { t } = useI18n();
  const {
    cardHash: activeCardHash = '',
    description: activeCardDescription = ''
  } = activeCard || {};

  const description = getValues()[CARD_INFO.DESCRIPTION];
  const isValid = !errors[CARD_INFO.DESCRIPTION];

  return (
    <Box direction="row" margin="m 0" align="end">
      <InputField
        classNames={{
          wrapperClassName: ''
        }}
        label="panKeeper.card.label.description"
        icon={<Icon name="icons/general/action/edit" size="m" />}
        placeholder="panKeeper.card.placeholder.description"
        value=""
        hint={t('balance.pankeeper.card.description.hint')}
        name={CARD_INFO.DESCRIPTION}
        isOnChangeValidation={true}
        rules={createValidationRules(
          [
            {
              type: FORM_VALIDATION_TYPES.MAX_LENGTH_VALIDATOR,
              parameters: [32]
            }
          ],
          formBuilderValidationMap
        )}
      />
      {activeCardHash && !isSingleWallet && (
        <Button
          size="l"
          onClick={() => saveDescription(description)}
          className={styles.saveButton}
          disabled={description === activeCardDescription || !isValid}
        >
          {t('panKeeper.button.save.cardDescription')}
        </Button>
      )}
    </Box>
  );
};
export default CardDescription;
