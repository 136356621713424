import { useContext, useMemo } from 'react';
import { useI18n } from '@namespace/i18n';
import { isEmpty } from 'lodash';
import { USER_ROLES, UserContext } from '@namespace/user';
import {
  useGamesState,
  useGetIsRecentlyPlayedLoaded,
  useGetRecentlyPlayedGamesIds
} from './selectors';
import {
  RECENTLY_PLAYED_CATEGORY,
  SECTION_TYPES,
  LOBBY_RECENTLY_PLAYED_GAMES_AMOUNT
} from '../../constants';

export const useRecentlyPlayedCategory = ({
  feedName,
  suggesterServiceEntityName,
  showRecentlyPlayedCategory,
  maxGamesAmount = LOBBY_RECENTLY_PLAYED_GAMES_AMOUNT,
  isViewAllEnabled = false,
  categoryLogos = {}
}) => {
  const { t } = useI18n();
  const [user] = useContext(UserContext);
  const { role } = user;
  const { games } = useGamesState();
  const isRecentlyPlayedLoaded = useGetIsRecentlyPlayedLoaded(
    suggesterServiceEntityName
  );

  const recentlyPlayedGamesIds = useGetRecentlyPlayedGamesIds(
    suggesterServiceEntityName,
    feedName
  );
  const recentlyPlayedGames = useMemo(
    () => recentlyPlayedGamesIds.map((gameId) => games.data[gameId]),
    [games.data, recentlyPlayedGamesIds]
  );

  if (
    !showRecentlyPlayedCategory ||
    role !== USER_ROLES.USER ||
    (isRecentlyPlayedLoaded && isEmpty(recentlyPlayedGames))
  )
    return null;

  return {
    id: RECENTLY_PLAYED_CATEGORY.id,
    type: SECTION_TYPES.CATEGORY_RECENTLY_PLAYED,
    title: t(RECENTLY_PLAYED_CATEGORY.title),
    categoryGames: recentlyPlayedGames,
    isCategoryLoading: !isRecentlyPlayedLoaded,
    maxGamesAmount,
    isViewAllEnabled,
    logoActive: categoryLogos?.recentlyPlayedActive
  };
};
