import PropTypes from 'prop-types';
import Box from '../../../Box';
import styles from './index.module.css';

const SliderRail = ({ getRailProps }) => (
  <>
    <Box
      direction="column"
      className={styles.railOuterStyle}
      {...getRailProps()}
    />
    <Box className={styles.railInnerStyle} />
  </>
);

SliderRail.propTypes = {
  getRailProps: PropTypes.func.isRequired
};

export default SliderRail;
