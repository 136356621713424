import { useCallback } from 'react';
import { useGetNumberOfDecimalPlaces } from '@namespace/common';
import { getCurrencySign } from './getCurrencySign';
import { formatNumToAmount } from './formatNumToAmount';

export const useTotalJackpotFormatter = (currency, showCents = false) => {
  const numberOfDecimalPlaces = useGetNumberOfDecimalPlaces();
  return useCallback(
    (num) => {
      const formattedTotal = formatNumToAmount({
        num,
        showCents,
        numberOfDecimalPlaces
      });
      const currencySign = getCurrencySign(currency);
      return currencySign
        ? `${formattedTotal} ${currencySign}`
        : formattedTotal;
    },
    [currency, showCents, numberOfDecimalPlaces]
  );
};
