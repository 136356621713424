import { ROUND_16_BRACKET_MAP } from '../../constants';

const formatRound16Bracket = (groupTeams) =>
  ROUND_16_BRACKET_MAP.map((match) =>
    match.map(
      (team) =>
        (
          groupTeams.find(
            (groupTeam) =>
              groupTeam.groupId === team.groupId &&
              groupTeam.index === team.index
          ) || { id: '' }
        ).id
    )
  );

export default formatRound16Bracket;
