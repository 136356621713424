import { useContext, useState, memo, useMemo } from 'react';
import clsx from 'clsx';
import { isNumber, noop } from 'lodash';
import { DeviceContext, useScreenSize } from '@namespace/device';
import { useI18n } from '@namespace/i18n';
import { AText, Box, Button } from '@alf/uikit';
import { SiteSettingsContext } from '@namespace/cms';
import { USER_ROLES, UserContext } from '@namespace/user';
import {
  ROWS_WITH_GAMES,
  DEFAULT_GAME_TAGS,
  TWO_GAMES_PER_ROW_BREAKPOINT
} from '../../constants';
import { useGetLiveRtpCategory } from '../../context/selectors';
import { useCalcWideGamesAmount } from './useCalcWideGamesAmount';
import { GameWrapperInternal } from '../GameWrapperInternal';
import { GameWrapperExternal } from '../GameWrapperExternal';
import { WideGameWrapperInternal } from '../WideGameWrapperInternal';

import styles from './index.module.css';

const kindMap = {
  [ROWS_WITH_GAMES.ONE]: styles.oneRow,
  [ROWS_WITH_GAMES.TWO]: styles.twoRows,
  [ROWS_WITH_GAMES.THREE]: styles.threeRows
};

const GamesList = memo(
  ({
    games = [], // standard square game
    wideGames = [], // width of two standard games, height is the same
    firstGamePreview = false,
    kind,
    classNames = {},
    config = {},
    onGameClick,
    insertedGridTopComponent = null,
    showInstaGamesView = false,
    isPopularGamesList = false,
    isExternal = false,
    page: propPage,
    onChangePage = noop,
    id,
    pageSize = 24,
    onShowMoreClick = noop,
    isWithLiveRtpInfo,
    ...rest
  }) => {
    const { t } = useI18n();
    const { isDesktop } = useContext(DeviceContext);
    const [user] = useContext(UserContext);
    const { showTags = true, casinoType, showGameNames } = config;
    const [page, setPage] = useState(propPage || 1);
    const [settings] = useContext(SiteSettingsContext);
    const { gameTags = [] } = settings;
    const screenSize = useScreenSize(true);
    const isMobileGridView = screenSize <= TWO_GAMES_PER_ROW_BREAKPOINT;
    const { role } = user;
    const isLoggedInUser = role === USER_ROLES.USER;
    const liveRtpCategory = useGetLiveRtpCategory();

    const GameWrapperComponent = useMemo(
      () => (isExternal ? GameWrapperExternal : GameWrapperInternal),
      [isExternal]
    );

    const gameTagsConfig = useMemo(
      () =>
        [...DEFAULT_GAME_TAGS, ...gameTags].reduce(
          (acc, i) => ({ ...acc, [i.tagId]: i }),
          {}
        ),
      [gameTags]
    );

    const setListPage = (newPage) => {
      setPage(newPage);
      onChangePage(newPage);
    };

    const showMore = () => {
      setListPage(page + 1);
      onShowMoreClick();
    };

    const classNameShort = isNumber(kind)
      ? styles[`rows_${kind}`]
      : kindMap[kind];
    const classNamePreview =
      firstGamePreview &&
      isDesktop &&
      (kind === ROWS_WITH_GAMES.TWO || kind === 2)
        ? styles.firstGamePreview
        : '';

    const gamesList =
      kind === ROWS_WITH_GAMES.ALL ? games.slice(0, page * pageSize) : games;
    const wideGamesAmount = useCalcWideGamesAmount(
      wideGames?.length,
      gamesList?.length
    );
    const wideGamesList = showInstaGamesView
      ? wideGames.slice(0, page * wideGamesAmount)
      : [];
    const { cardItemClassName = '', gamesListClassName = '' } = classNames;

    const baseGameProps = {
      showTags,
      isDesktop,
      casinoType,
      gameTags: gameTagsConfig,
      showGameNames,
      onGameClick
    };

    return (
      <Box direction="column">
        <Box>
          <Box
            className={clsx([
              isPopularGamesList ? styles.popularGamesList : styles.gamesList,
              classNamePreview,
              classNameShort,
              gamesListClassName
            ])}
            {...(id ? { id } : {})}
          >
            {insertedGridTopComponent}
            {wideGamesList.map((game, index) => (
              <WideGameWrapperInternal
                game={game}
                className={clsx([
                  styles.cardItem,
                  styles.wideCardItem,
                  cardItemClassName
                ])}
                index={index}
                isLoggedInUser={isLoggedInUser}
                isMobileGridView={isMobileGridView}
                key={
                  game.isPlaceholder
                    ? index
                    : `${game.provider}/${game.gameCode}`
                }
                {...baseGameProps}
                {...rest}
              />
            ))}
            {gamesList.map((game, index) => (
              <GameWrapperComponent
                game={game}
                className={clsx([styles.cardItem, cardItemClassName])}
                index={index}
                isLoggedInUser={isLoggedInUser}
                key={
                  game.isPlaceholder
                    ? index
                    : `${game.provider}/${game.gameCode}`
                }
                isLaunchOnTap={isPopularGamesList}
                {...(isWithLiveRtpInfo && {
                  liveRtpInfo: liveRtpCategory?.data?.[game.launchGameId]
                })}
                {...baseGameProps}
                {...rest}
              />
            ))}
          </Box>
        </Box>
        {kind === ROWS_WITH_GAMES.ALL && games.length > gamesList.length && (
          <Box justify="center">
            <Button
              className={styles.showMore}
              intent="secondary"
              actionType="color2"
              size="l"
              onClick={showMore}
            >
              <AText breed="BU" size="m">
                {t('games.show_more')}
              </AText>
            </Button>
          </Box>
        )}
      </Box>
    );
  }
);

export default GamesList;
