import { isUndefined } from 'lodash';
import { types } from '../types';

import { getWagerBonuses } from '../../api/getWagerBonuses';
import { getBonusSystemActiveBonus } from '../../api/getBonusSystemActiveBonus';
import { getBonusSystemActiveBonusWithdraw } from '../../api/getBonusSystemActiveBonusWithdraw';
import { getBonusSystemActiveDepositBonuses } from '../../api/getBonusSystemActiveDepositBonuses';

export const actions = {
  [types.FETCH_WAGER_BONUSES]: (dispatch) => async ({
    isPreWagerBonusesEnabled
  }) => {
    const { response: crmResponse } = await getWagerBonuses();
    let bsResponse = {};
    if (isPreWagerBonusesEnabled) {
      const { data } = await getBonusSystemActiveBonus();
      bsResponse = data;
    }

    dispatch({
      type: types.SET_WAGER_BONUSES,
      payload: {
        crmWager: crmResponse,
        bsWager: bsResponse
      }
    });
  },
  [types.FETCH_ACTIVE_WAGER_BONUS]: (dispatch) => async () => {
    const { campaigns } = await getBonusSystemActiveBonusWithdraw();

    dispatch({
      type: types.SET_ACTIVE_WAGER_BONUS,
      payload: {
        activeWagerBonus: campaigns[0]
      }
    });
  },
  [types.SET_IS_UPDATE_FREE_SPIN_BONUS_LIST]: (dispatch) => (value) => {
    dispatch({
      type: types.SET_IS_UPDATE_FREE_SPIN_BONUS_LIST,
      payload: { isUpdate: value }
    });
  },
  [types.SET_IS_UPDATE_FUNDS_BONUS_LIST]: (dispatch) => (value) => {
    dispatch({
      type: types.SET_IS_UPDATE_FUNDS_BONUS_LIST,
      payload: { isUpdate: value }
    });
  },
  [types.FETCH_ACTIVE_BONUS_SYSTEM_BONUS]: (dispatch) => async () => {
    const result = await getBonusSystemActiveDepositBonuses();
    const activeBonusSystemBonusId = result?.data?.campaigns
      ?.filter(
        ({ isSelectedDeposit, bonusId }) =>
          isSelectedDeposit && !isUndefined(bonusId)
      )
      .map(({ bonusId }) => bonusId)[0];

    dispatch({
      type: types.SET_ACTIVE_BONUS_SYSTEM_BONUS,
      payload: {
        activeBonusSystemBonusId
      }
    });
  }
};
