/* eslint-disable react/no-array-index-key */
import { AText, Box } from '@alf/uikit';
import {
  SiteSettingsContext,
  useComponentConfig,
  useGeneralRoutes
} from '@namespace/cms';
import { DeviceContext } from '@namespace/device';
import { scrollToElement } from '@namespace/helpers';
import { LocalizedRedirect, useI18n, useLocation } from '@namespace/i18n';
import { Icon } from '@namespace/icons2';
import { NativeAppContext } from '@namespace/native-app';
import { useRouteParams } from '@namespace/router';
import { useUserSegmentsIncludes } from '@namespace/segments';
import { HTMLSnippet } from '@namespace/snippets';
import { useGetImage } from '@namespace/themes';
import { USER_ROLES, UserContext, useUserInfoLoaded } from '@namespace/user';
import { isEmpty } from 'lodash';
import { useCallback, useContext, useRef, useState } from 'react';
import DateTime from '../../common/DateTime';
import { TournamentTakePartButton } from '../../common/TournamentTakePartButton';
import { PROMO_TYPES } from '../../constants';
import {
  useGetActivePromoType,
  useGetPromotionGoBack,
  useGetPromotionPageRouteByPromoType
} from '../../hooks';
import {
  useGetTournamentServiceTournamentInfo,
  useHasPromotions,
  usePromoById,
  usePromotionsStore
} from '../../store';
import getPromoImage from '../../utils/getPromoImage';
import getPromoItemByCurrency from '../../utils/getPromoItemByCurrency';
import { PromotionGamesSection } from '../PromotionGamesSection';
import SimilarPromotions from '../SimilarPromotions';
import { TournamentLeaderboard } from '../TournamentLeaderboard';
import styles from './index.module.css';

const Promotion = ({ isFullMobileHeaderLink = false }) => {
  const [isError, setIsError] = useState(false);
  const [isLeaderboardVisible, setIsLeaderboardVisible] = useState(false);
  const [user] = useContext(UserContext);
  const { isMobile, realPageType } = useContext(DeviceContext);
  const [settings] = useContext(SiteSettingsContext);
  const [{ isNativeApp }] = useContext(NativeAppContext);
  const isTournamentsLoaded = usePromotionsStore('isTournamentsLoaded');
  const hasPromotions = useHasPromotions();
  const {
    promotion,
    promotions: promotionsPath,
    tournament
  } = useGeneralRoutes();
  const { t, language } = useI18n();
  const { pathname } = useLocation();
  const { promoId } = useRouteParams(
    `${promotionsPath}/:promoItemType/:promoId`
  );
  const { userInfoLoaded } = useUserInfoLoaded();
  const currentPromo = usePromoById(promoId) ?? {};
  const activePromoType = useGetActivePromoType();
  const { showYouMayBeInterestedIn } = useComponentConfig();
  const { activeWallet = {}, accountStatuses = {}, role } = user;
  const isTournamentPage = activePromoType === PROMO_TYPES.tournament;
  const promoPageRoute = useGetPromotionPageRouteByPromoType({
    activePromoType
  });
  const promotionGoBack = useGetPromotionGoBack(activePromoType);
  const { isBonusHunter, isAddictedUser } = accountStatuses;
  const { currency: userCurrency = null } = activeWallet;
  const { siteConfig } = settings;
  const { defaultCurrency } = siteConfig;
  const currentCurrency = userCurrency || defaultCurrency;
  const isPromoDetailsPage =
    pathname.includes(`${promotion}/`) || pathname.includes(`${tournament}/`);
  const getImg = useGetImage();

  const promoLink = `${promoPageRoute}/${promoId}`;
  const isLoggedInUser = role === USER_ROLES.USER;
  const filteredSegments = useUserSegmentsIncludes();

  const tournamentServiceTournamentInfo = useGetTournamentServiceTournamentInfo(
    currentPromo.tournamentId
  );

  const tournamentLeaderboardRef = useRef(null);

  const handleLeaderboardVisibleChange = useCallback(
    (isVisible) => setIsLeaderboardVisible(isVisible),
    []
  );

  if (
    isPromoDetailsPage &&
    ((hasPromotions && isEmpty(currentPromo)) ||
      isBonusHunter ||
      isAddictedUser ||
      (isTournamentPage && tournamentServiceTournamentInfo?.user?.blocked))
  ) {
    return <LocalizedRedirect to={`${promotionsPath}/${activePromoType}`} />;
  }

  const {
    defaultDescriptionTranslationKey,
    defaultDescriptionSnippetId,
    settingsPerCurrency = [],
    imagesPerLanguage = [],
    showTimerInsteadPeriod,
    titleTranslationKey,
    promotionPageItems = [],
    mayBeInterestedIn = [],
    defaultImageLink,
    defaultImageLinkDark,
    periodStart,
    periodEnd,
    userSegments
  } = currentPromo;

  let currentDefaultImageLink = defaultImageLink;
  let currentDefaultImageLinkDark = defaultImageLinkDark;
  let currentImagesPerLanguage = imagesPerLanguage;

  // temporary solution!! this condition will be deleted when per platform
  // functionality for promos images will be on prod envs
  if (currentPromo[realPageType]) {
    const {
      defaultImageLink: defaultImageLinkForPlatform,
      defaultImageLinkDark: defaultImageLinkDarkForPlatform,
      imagesPerLanguage: imagesPerLanguageForPlatform
    } = currentPromo[realPageType];

    currentDefaultImageLink = defaultImageLinkForPlatform;
    currentDefaultImageLinkDark = defaultImageLinkDarkForPlatform;
    currentImagesPerLanguage = imagesPerLanguageForPlatform;
  }

  const currentDate = new Date();
  const periodStartDate = new Date(periodStart);
  const periodEndDate = new Date(periodEnd);
  const isTournamentFinished = periodEndDate <= currentDate;
  const isTournamentStarted = currentDate >= periodStartDate;
  const isPromoIncludedInSegments = filteredSegments(userSegments);

  const isTournamentTakePartSectionShown =
    isTournamentPage && currentPromo?.showTakePart;
  const isTournamentTakePartSectionButtonShown = isLoggedInUser
    ? !tournamentServiceTournamentInfo?.user?.participant &&
      isTournamentsLoaded &&
      !isTournamentFinished
    : !isTournamentFinished;
  const isTournamentYouAreLabelInShown =
    isLoggedInUser &&
    tournamentServiceTournamentInfo?.user?.participant &&
    !tournamentServiceTournamentInfo?.user?.blocked &&
    isTournamentsLoaded;
  const defaultPromoImage = getImg(
    currentDefaultImageLink,
    currentDefaultImageLinkDark
  );

  const promoImage = getPromoImage(
    settingsPerCurrency,
    currentImagesPerLanguage,
    language,
    realPageType,
    currentCurrency,
    getImg
  );

  const promoShortDescription = getPromoItemByCurrency(
    settingsPerCurrency,
    currentCurrency,
    language,
    realPageType,
    getImg,
    'descriptionTranslationKey'
  );
  const promoLongDescription = getPromoItemByCurrency(
    settingsPerCurrency,
    currentCurrency,
    language,
    realPageType,
    getImg,
    'descriptionSnippetId'
  );

  if (!isPromoIncludedInSegments) {
    return <LocalizedRedirect to={`${promotionsPath}/${activePromoType}`} />;
  }

  return isBonusHunter || isAddictedUser ? null : (
    <Box className={styles.wrapper} direction="column">
      {(titleTranslationKey || promoId) && !isNativeApp && (
        <Box
          {...(isFullMobileHeaderLink && { onClick: promotionGoBack })}
          margin="m"
          align="center"
        >
          <Box>
            <Icon
              className={styles.backIcon}
              data-role="promotion-item-back-icon"
              name="icons/general/nav/back"
              color="txt_color_4"
              size="s"
              {...(!isFullMobileHeaderLink && { onClick: promotionGoBack })}
            />
          </Box>
          <AText
            data-role="promotion-item-back-title"
            breed="BU"
            size="xl"
            color="txt_color_4"
          >
            {t(`${titleTranslationKey}`, promoId)}
          </AText>
        </Box>
      )}
      {/*
        FYI: each promotionPageItem intentionally includes index in key for key's uniqueness, items can't be reordered as of now, so that's ok.
        another possible solution - add uniq id for each promo page item during promos normalize stage
      */}
      {promotionPageItems.map((item, index) => {
        if (item.item === 'bannerImage') {
          return (
            <Box
              className={styles.bannerImageWrapper}
              key={`${item.item}-${index}-banner-image`}
            >
              {isTournamentYouAreLabelInShown && (
                <Box
                  className={styles.bannerImageYouAreInLabelWrapper}
                  padding={isMobile ? 'xs s xs xs' : 's m s s'}
                  align="center"
                >
                  <Box margin={isMobile ? '0 s 0 0' : '0 m 0 0'}>
                    <Icon
                      name="icons/general/state/success"
                      size={isMobile ? 'xs' : 'l'}
                      color="txt_color_4"
                    />
                  </Box>
                  <AText
                    breed="BU"
                    size={isMobile ? 'l' : '2xl'}
                    color="txt_color_4"
                  >
                    {t('web.promo.tag.youAreIn')}
                  </AText>
                </Box>
              )}
              <img
                src={
                  (isError ? defaultPromoImage : promoImage) ||
                  defaultPromoImage
                }
                onError={() => setIsError(true)}
                alt="promoImage"
                data-role="promotion-item-banner"
                className={styles.image}
              />
            </Box>
          );
        }
        if (item.item === 'title') {
          return (
            <Box
              className={styles.title}
              align="center"
              data-role="promotion-item-title"
              key={`${item.item}-${index}-title`}
            >
              <AText
                size={isMobile ? '3xl' : '4xl'}
                breed="BU"
                color="txt_color_1"
              >
                {t(`${titleTranslationKey}`, promoId)}
              </AText>
            </Box>
          );
        }
        if (item.item === 'shortDescription') {
          return (
            <AText
              className={styles.shortDescription}
              breed="R"
              size={isMobile ? 'xl' : '2xl'}
              color="txt_color_4"
              data-role="promotion-item-short-description"
              key={`${item.item}-${index}-short-description`}
            >
              {t(
                `${promoShortDescription}`,
                t(`${defaultDescriptionTranslationKey}`)
              )}
            </AText>
          );
        }
        if (item.item === 'longDescription') {
          return (
            <HTMLSnippet
              snippetId={promoLongDescription || defaultDescriptionSnippetId}
              data-role="promotion-item-longDescription"
              key={`${item.item}-${index}-long-description`}
            />
          );
        }
        if (item.item === 'htmlSnippet') {
          const segmentedSnippetId = item?.segmentedSnippets?.find(
            ({ userSegments: snippetUserSegments }) =>
              filteredSegments(snippetUserSegments)
          )?.snippetId;

          return (
            <HTMLSnippet
              data-role={`promotion-item-snippet-${item.snippetId}`}
              key={`${item.snippetId}-${index}-item-snippet-promotion`}
              snippetId={segmentedSnippetId || item.snippetId}
            />
          );
        }
        if (item.item === 'timerOrPeriod') {
          return (
            <Box
              className={styles.dateTimeBlock}
              justify="center"
              data-role="promotion-item-time"
              key={`${item.item}-${index}-timer-or-period`}
            >
              <DateTime
                promoData={currentPromo}
                showTimerInsteadPeriod={showTimerInsteadPeriod}
                dateTextColor="txt_color_4"
                dateTextSize="3xl"
                isPromoPage={true}
                activePromoType={activePromoType}
              />
            </Box>
          );
        }
        if (item.item === 'image') {
          let imageSrc;
          let imageDarkSrc;

          // temporary solution!! this condition will be deleted when per platform
          // functionality for promos images will be on prod envs
          if (item[realPageType]) {
            const { image, imageDark } = item[realPageType];

            imageSrc = image;
            imageDarkSrc = imageDark;
          } else {
            const { image, imageDark } = item || {};

            imageSrc = image;
            imageDarkSrc = imageDark;
          }

          return (
            <img
              src={getImg(imageSrc, imageDarkSrc)}
              alt="promoImage"
              data-role="promotion-item-image"
              key={`${item.item}-${index}-image`}
              className={styles.image}
            />
          );
        }
        if (item.item === 'translationKey') {
          return (
            <AText
              className={styles.shortDescription}
              breed="R"
              size={isMobile ? 'xl' : '2xl'}
              color="txt_color_4"
              data-role="promotion-item-translation-key"
              key={`${item.item}-${index}-translation-key`}
            >
              {t(`${item.translationKey}`)}
            </AText>
          );
        }
        if (
          item.item === 'takePartButton' &&
          isTournamentTakePartSectionShown &&
          userInfoLoaded
        ) {
          if (isTournamentTakePartSectionButtonShown) {
            return (
              <Box
                className={styles.takePartButtonWrapper}
                justify="center"
                key={`${item.item}-${index}-take-part-btn`}
              >
                <TournamentTakePartButton
                  tournamentId={currentPromo?.tournamentId}
                  loginRedirectTo={promoLink}
                  className={styles.takePartButton}
                  isUpdateLeaderboard={true}
                  size="xl"
                />
              </Box>
            );
          }

          if (isTournamentYouAreLabelInShown) {
            return (
              <Box
                direction="column"
                margin="m"
                justify="center"
                align="center"
                key={`${item.item}-${index}-label`}
              >
                <Box align="center">
                  <Box margin="0 m 0 0">
                    <Icon
                      name="icons/general/state/success"
                      size={isMobile ? 'm' : 'l'}
                      color="state_success"
                    />
                  </Box>
                  <AText
                    breed="BU"
                    size={isMobile ? '2xl' : '3xl'}
                    color="state_success"
                  >
                    {t('web.promo.tag.youAreIn')}
                  </AText>
                </Box>
                {isLeaderboardVisible && (
                  <Box
                    margin="s 0 0 0"
                    padding="s"
                    className={styles.youAreInArrowWrapper}
                    onClick={() => {
                      scrollToElement(tournamentLeaderboardRef.current);
                    }}
                  >
                    <Icon
                      name="icons/general/nav/expand"
                      size="m"
                      color="state_success"
                    />
                  </Box>
                )}
              </Box>
            );
          }
        }
        if (item.item === 'leaderboard' && isTournamentStarted) {
          return (
            <Box key={`${item.item}-${index}`} justify="center">
              <TournamentLeaderboard
                promo={currentPromo}
                promoLink={promoLink}
                ref={tournamentLeaderboardRef}
                onLeaderboardVisibleChange={handleLeaderboardVisibleChange}
              />
            </Box>
          );
        }
        if (item.item === 'games') {
          return (
            <Box
              className={styles.gamesSectionWrapper}
              justify="center"
              key={`${item.item}-${index}-${item.actionLink}`}
            >
              <PromotionGamesSection
                defaultFeed={item.defaultFeed}
                segmentedFeeds={item.segmentedFeeds}
                actionLink={item.actionLink}
                serviceUrl={item.serviceUrl}
                promoLink={promoLink}
              />
            </Box>
          );
        }

        return null;
      })}
      {showYouMayBeInterestedIn && (
        <SimilarPromotions
          currentPromo={promoId}
          mayBeInterestedIn={mayBeInterestedIn}
        />
      )}
    </Box>
  );
};

export default Promotion;
