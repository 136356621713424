import { camelizeKeys } from 'humps';
import { post } from '@namespace/helpers';

export const updateDescription = ({
  userId,
  accountingId,
  cardHash,
  description,
  ssid
}) =>
  post('/pankeeper/frontend_api/update_description/', {
    user_id: String(userId),
    accounting_id: accountingId,
    ssid,
    card_hash: cardHash,
    description
  }).then(camelizeKeys);
