import { EmptyState } from '@alf/uikit';
import { useGeneralRoutes } from '@namespace/cms';
import { LocalizedLink, useI18n, useLocation } from '@namespace/i18n';
import { Image } from '@namespace/icons2';
import { useRouteOptionalParams } from '@namespace/router';
import { Meta } from '@namespace/common';
import { window } from '@namespace/helpers';
import { SPORT_NONE_PATH } from '../../../constants';
import { Header } from '../Header';
import Event from './Event';
import { EventMarketSkelet } from './EventMarketSkelet';
import { useEventFields, useLine } from '../../../store';

const EventContent = ({
  isLoading = true,
  isShowFavorite,
  isShowScoreboard,
  eventId,
  lineKey,
  sportSlug,
  isShowStatUrl,
  isCachedEvent,
  isShowSportRadarWidget,
  isIncorrectPath = false,
  slugPath
}) => {
  const { t, f } = useI18n();
  const DATE_FORMATS_TYPES = f.getFormats();
  const { preMatchLobby, preMatchEvent, liveEvent } = useGeneralRoutes();
  const line = useLine(lineKey);
  const { eventsIds = [], eventsMarketsIds } = line || {};
  const { pathname } = useLocation();
  const currentPathname = pathname.includes(liveEvent)
    ? liveEvent
    : preMatchEvent;

  const { optional = [] } = useRouteOptionalParams(`${currentPathname}/*`);

  const {
    eventName,
    eventStatusType,
    eventDt,
    eventResultTotal,
    sportName,
    categoryName,
    tournamentName
  } = useEventFields({
    eventId,
    fields: [
      'eventName',
      'eventStatusType',
      'eventDt',
      'eventResultTotal',
      'sportName',
      'categoryName',
      'tournamentName'
    ]
  });

  const startTime = f
    .getDateTime({ date: eventDt * 1000 }) // convert to milliseconds
    .toFormat(DATE_FORMATS_TYPES.DEPOSIT_HISTORY_DATE_TIME)
    .replace(/\//gi, '.');

  const metaParams = [
    sportName,
    categoryName,
    tournamentName,
    eventName,
    eventStatusType === 'inprogress' && eventResultTotal
      ? eventResultTotal.split(' ')[0]
      : startTime
  ];

  if (
    (!isLoading && line && !eventsIds.length) ||
    (isIncorrectPath && slugPath !== SPORT_NONE_PATH) ||
    (optional.length !== 2 && !window.IS_SSR)
  ) {
    return (
      <EmptyState
        image={<Image name="general/looking_for" />}
        Link={LocalizedLink}
        linkPath={preMatchLobby}
        linkText={t('home')}
        text={t('sportsbook.emptyState.noEvent')}
      />
    );
  }

  if (isLoading && !isCachedEvent && (!eventsIds.length || !eventsMarketsIds)) {
    return <EventMarketSkelet />;
  }

  if (eventsIds.length && (eventsMarketsIds || isCachedEvent)) {
    return (
      <>
        <Meta replacers={metaParams} />
        <Header
          eventId={eventId}
          isShowFavorite={isShowFavorite}
          isShowStatUrl={isShowStatUrl}
          isShowScoreboard={isShowScoreboard}
          isShowSportRadarWidget={isShowSportRadarWidget}
          dataRole={`event-header-${eventId}`}
        />
        {!isCachedEvent && (
          <Event eventId={eventId} lineKey={lineKey} sportSlug={sportSlug} />
        )}
      </>
    );
  }

  return null; // !isLoading && eventsIds.length && !eventsMarketsIds
};

export default EventContent;
