import clsx from 'clsx';
import Box from '../../../../../../../Box';
import AText from '../../../../../../../AText';
import styles from './index.module.css';

export const GroupHeader = ({ title, iconElement, size }) => {
  return (
    <Box
      className={clsx(styles.root, styles[size])}
      align="center"
      padding="s"
      gap="s"
    >
      {iconElement}
      {title && (
        <AText
          breed="BU"
          color="input_dropdown_txt_1"
          transform="uppercase"
          size={size}
        >
          {title}
        </AText>
      )}
    </Box>
  );
};
