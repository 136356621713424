import window from './window';

export const COOKIES_KEYS = {
  LANGUAGE: 'LANGUAGE',
  PHPSESSID: 'PHPSESSID',
  IS_HIDE_DOCUMENT_BANNER: 'isHideDocumentBanner',
  IS_BANNER_VIEWED: 'isBannerViewed',
  TZ: 'TZ',
  B_TAG: 'b_tag',
  AFFILIATE_TIMESTAMP: 'affiliate_timestamp',
  COLOR_THEME: 'COLOR_THEME',
  OLD_VERSION: 'old_version',
  IS_MOBILE_APP_BANNER_DISABLED: 'isMobileAppBannerDisabled',
  NATIVE_APP: 'native-app',
  COUNTRY_ID: 'COUNTRY-ID',
  TEST_COUNTRY_ID: 'TEST_COUNTRY-ID',
  LOG_ON: 'logOn',
  PHONE_VERIFICATION: 'phoneVerification',
  COLOR_SCHEME: 'COLOR_SCHEME',
  SELECTED_COLOR_MODE: 'SELECTED_COLOR_MODE'
};

export const LOCAL_STORAGE_KEYS = {
  MULTIBETSLIP_NUMBER_KEY: 'MULTIBETSLIP_CURRENT',
  MULTIBETSLIP_NONEMPTY_KEY: 'MULTIBETSLIP_NONEMPTY',
  TESTER: 'TESTER',
  REMEMBER_ME: 'rememberMe',
  IS_INSTANT_GAME_CLOSED: 'isInstantGameClosed',
  TRANSLATION: 'TRANSLATION',
  PREDICTIONS: 'predictions',
  ODDS_FORMAT: 'ODDS_FORMAT',
  ACTIVE_GROUPS: 'activeGroups',
  USER_SEGMENTS_FILTER: 'USER_SEGMENTS_FILTER',
  USER_SEGMENTS_ALL: 'USER_SEGMENTS_ALL',
  USER_SEGMENTS: 'USER_SEGMENTS',
  SPEECH: 'SPEECH',
  READ_CRITICAL_MESSAGE: 'READ_CRITICAL_MESSAGE',
  LINE_VIEW: 'LINE_VIEW',
  DOCUMENTS_PENDING: 'DOCUMENTS_PENDING',
  BETSLIP_PREDEFINED_VALUES: 'BETSLIP_PREDEFINED_VALUES',
  SW_PAYMENT_SERVICE: 'SW_PAYMENT_SERVICE',
  CSS_THEME: 'CSS_THEME',
  CARD_HASH_KEY: 'pan.cardHash',
  COEF_TYPE: 'COEF_TYPE',
  IS_SHOW_AFTER_LOGIN_NOTIFICATIONS: 'isShowAfterLoginNotifications',
  COOKIE_MANAGEMENT_TYPE: 'COOKIE_MANAGEMENT_TYPE',
  PREV_COOKIE_MANAGEMENT_TYPE: 'PREV_COOKIE_MANAGEMENT_TYPE',
  ONBOARDING_TOURS_COMPLETION: 'ONBOARDING_TOURS_COMPLETION',
  THEME_FOR_TESTING: 'THEME_FOR_TESTING',
  CASINO_JP_WIN_LAST_CHECKED: 'CASINO_JP_WIN_LAST_CHECKED',
  AFFILIATE_COOKIES: 'affiliate_cookies',
  JACKPOT_GENERAL: 'JACKPOT_GENERAL',
  HAS_BEEN_LOGGED_IN: 'HAS_BEEN_LOGGED_IN',
  BLUR_AMOUNT: 'BLUR_AMOUNT',
  QUICK_BET_ENABLED: 'QUICK_BET_ENABLED',
  BONUS_SLIDER_ACTIVE_BONUS: 'BONUS_SLIDER_ACTIVE_BONUS'
};

export const SESSION_STORAGE_KEYS = {
  REDIRECT_URL: 'redirectURL',
  FROM_DEPOSIT: 'fromDeposit',
  PREV_PATHNAME: 'prevPathname',
  TRANSLATION_KEYS_SHOW: 'TRANSLATION_KEYS_SHOW',
  HEADER_ANIMATION_BUTTON_SHOWED: 'HEADER_ANIMATION_BUTTON_SHOWED',
  SHOW_PRIVACY_POLICY_BANNER: 'SHOW_PRIVACY_POLICY_BANNER',
  PROFILE_MENU_PREV_PATH: 'PROFILE_MENU_PREV_PATH',
  CASINO_PREV_PAGE_SCROLL_TOP: 'CASINO_PREV_PAGE_SCROLL_TOP',
  ADV_FILTER_PREVENT_CLOSE: 'ADV_FILTER_PREVENT_CLOSE',
  PLAYED_GAME_PATH_BASE: 'PLAYED_GAME_PATH_BASE',
  PLAYED_GAME_PATH: 'PLAYED_GAME_PATH',
  PREV_PAGE_BEFORE_DEPOSIT: 'PREV_PAGE_BEFORE_DEPOSIT',
  DEPOSIT_DATA: 'DEPOSIT_DATA'
};

export const COLOR_SCHEME_MEDIA_LIGHT = '(prefers-color-scheme: light)';
export const COLOR_SCHEME_DARK = 'dark';
export const COLOR_SCHEME_LIGHT = 'light';
export const COLOR_SCHEME_AUTO = 'auto';

export const COEF_TYPE_DECIMAL = 'decimal';
export const COEF_TYPE_AMERICAN = 'american';
export const COEF_TYPE_HONKONG = 'honkong';
export const COEF_TYPE_FRACTION = 'fraction';

export const TIMEOUT_FOR_REJECT_REQUEST = 10000;

export const CASHING_PERIOD = 10 * 60 * 1000; // 10 min

export const CURRENCY_CODE_TO_SYMBOL = {
  EUR: '€',
  USD: '$',
  UAH: '₴',
  HRK: 'KN',
  RON: 'RON',
  BRL: 'R$',
  CLP: 'CLP',
  GBP: '£',
  INR: '₹',
  MDL: 'L',
  MXN: 'MXN',
  NOK: 'KR',
  PEN: 'S/',
  PLN: 'ZŁ',
  TMT: 'TMT'
};

export const POPUP_TYPES = {
  PROFILE_MENU: 'profile-menu',
  SPORT_SETTINGS: 'sport-settings'
};

export const POPUP_HALF_SCREEN = {
  MOBILE_POPUP_MENU: 'mobile-popup-menu'
};

export const POPUP_TYPES_WITHOUT_MENU = {
  SPORT_SETTINGS: 'sport-settings'
};

export const MOBILE_MENU_TYPES = {
  BURGER: 'burger',
  NAV_MENU: 'navmenu'
};

export const IS_SERVER_RENDER = process.env.REACT_APP_ENV === 'node';

export { default as window } from './window';
export { default as document } from './document';

export const LANG_PATTERN = /^\/([a-zA-Zа-яА-ЯёЁіІїЇєЄґҐ]{2})(\/|$)/; // fixed bug when we go to '/en/укецу' before, we redirected to '/en/укецу'

export const COOKIE_MANAGEMENT_TYPES = {
  NECESSARY: 'necessary',
  FUNCTIONAL: 'functional',
  ANALYTICAL: 'analytical'
};

export const PREWAGER_WALLET_ACCOUNT_ID = 'user_pre_wager_bonus_wallet';

export const SEGMENT_FIELDS = {
  BALANCE_FROM: 'balanceFrom',
  BALANCE_TO: 'balanceTo',
  USER_GROUP: 'userGroup',
  REGISTRATION: 'registration',
  VIP: 'vip',
  AFFILIATE_ID: 'affiliateID',
  CREATIVE_ID: 'creativeID',
  SITE_TRACKER_ID: 'siteTrackerID',
  ANID_ZONE_ID: 'anidZoneID',
  LANGUAGES: 'languages',
  COUNTRIES: 'countries',
  AGE_FROM: 'ageFrom',
  AGE_TO: 'ageTo',
  ACCOUNT_VERIFIED: 'accountVerified',
  USER_DEPOSITED: 'userDeposited'
};

export const ALL_VALUE = 'all'; // segment fields, or filter fields with this value, will be ignored by filtration

export * from './stores';

export const IS_PRERENDER = new RegExp('(Prerender)', 'i').test(
  window.navigator.userAgent
);
