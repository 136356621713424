import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import Price from '../Price';

const TotalPrice = ({ totalAmount, className = '', margin = {} }) => {
  const { t } = useI18n();

  return (
    <Box
      direction="row"
      padding="s 0"
      justify="between"
      className={className}
      {...margin}
    >
      <AText breed="BU" size="m" color="txt_color_1">
        {t('bonusShop.productList.amount')}
      </AText>
      <Price price={totalAmount} />
    </Box>
  );
};

export default TotalPrice;
