import { useContext } from 'react';
import { SiteSettingsContext } from '@namespace/cms';
import CustomNotificationBanner from '../../common/CustomNotificationBanner';

const CustomNotification = () => {
  const [settings] = useContext(SiteSettingsContext);
  const { otherSettings = {} } = settings;
  const { customNotification = {} } = otherSettings;
  const { snippetId, expires } = customNotification;

  return <CustomNotificationBanner snippetId={snippetId} expires={expires} />;
};

export default CustomNotification;
