import { useContext, useMemo } from 'react';
import { map } from 'ramda';
import { Box } from '@alf/uikit';
import { DeviceContext } from '@namespace/device';
import { useAdvFilterStore } from '../../../../../../storeAdvFilter';
import { formatFilterItems } from './utils';
import { ChipGroup } from '../ChipGroup';
import { useAdvFilterInnerCtxStore } from '../../store';

export const Chips = ({ className }) => {
  const { isDesktop } = useContext(DeviceContext);
  const filter = useAdvFilterInnerCtxStore('filter');
  const inactiveFilter = useAdvFilterInnerCtxStore('inactiveFilter');
  const advFilterSportsById = useAdvFilterStore('advFilterSportsById');
  const advFilterCategoriesById = useAdvFilterStore('advFilterCategoriesById');
  const advFilterTournamentsById = useAdvFilterStore(
    'advFilterTournamentsById'
  );

  const [activeChips, inactiveChips] = useMemo(
    () =>
      map(
        formatFilterItems([
          advFilterSportsById,
          advFilterCategoriesById,
          advFilterTournamentsById
        ])
      )([filter, inactiveFilter]),
    [
      advFilterCategoriesById,
      advFilterSportsById,
      advFilterTournamentsById,
      filter,
      inactiveFilter
    ]
  );

  return (
    <Box
      direction="column"
      align="start"
      gap={isDesktop ? 'm' : 's'}
      className={className}
    >
      <ChipGroup
        chips={activeChips}
        title="sportsBook.sportsMenu.advFilter.active"
        status="active"
      />
      <ChipGroup
        chips={inactiveChips}
        title="sportsBook.sportsMenu.advFilter.inactive"
        status="inactive"
      />
    </Box>
  );
};
