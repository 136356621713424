export const getIconName = (state) => {
  switch (state) {
    case 'ok':
      return 'success';
    case 'pending':
      return 'loader';
    case 'error':
      return 'canceled';
    default:
      return '-';
  }
};

export const formatBytes = (bytes, decimals) => {
  if (bytes === 0) return '0 B';
  const k = 1024;
  const dm = decimals || 2;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};
