export const SET_FILTER = 'SET_FILTER';
export const CHANGE_FILTER = 'CHANGE_FILTER';
export const COUNT_CHANGES = 'COUNT_CHANGES';
export const SET_INITIAL_FILTER = 'SET_INITIAL_FILTER';

export const setFilterAction = (data) => ({
  type: SET_FILTER,
  payload: data
});

export const changeFilterAction = (data) => ({
  type: CHANGE_FILTER,
  payload: data
});

export const setInitialFilterAction = (data) => ({
  type: SET_INITIAL_FILTER,
  payload: data
});

export const countChangesAction = (data) => ({
  type: COUNT_CHANGES,
  payload: data
});
