/* eslint-disable no-console */
import { useMemo, useState, useEffect } from 'react';
import { getLocalStorageValue, setLocalStorageValue } from '@namespace/helpers';
import { uniqueId } from 'lodash';
import { TOKEN_LS_KEY_PREFIX, HISTORY_LOAD_MESSAGES } from '../constants';
import socketInstance from './socket';
import { getSignature } from './api';

export const useChat = (socketUrl, userInfo, pingDelay = 50000) => {
  const lsTokenKey = `${TOKEN_LS_KEY_PREFIX}_${userInfo?.id}`;
  const [token, setToken] = useState(getLocalStorageValue(lsTokenKey, null));
  const [socket, setSocket] = useState(null);

  const setNewSocket = (newInstance) => {
    // old socket
    if (socket) {
      console.log({ oldSocket: socket });
      const [, , , , currentSocketInstance] = socket;
      currentSocketInstance.unsubscribe();
      currentSocketInstance.close();
    }

    setSocket(newInstance);
  };

  useEffect(() => {
    if (userInfo) {
      const { name } = userInfo;
      getSignature().then((sig) => {
        const { userId, email, nonce, signature, createdAt } = sig;
        const instance = socketInstance({
          socketUrl,
          token,
          initMessages: [
            {
              event: 'INFO',
              userId,
              name,
              email,
              nonce,
              signature,
              createdAt
            }
          ]
        });

        setNewSocket(instance);
      });
    } else {
      setNewSocket(socketInstance({ socketUrl, token, initMessages: [] }));
    }
  }, [userInfo, socketUrl, token]);

  const [send, subscribe, , rawSend, currentSocketInstance] = socket || [
    null,
    null,
    null,
    null,
    null
  ];
  const [messages, setMessages] = useState([]);
  const [typing, setTyping] = useState([]);
  const [historyRequested, setHistoryRequested] = useState(false);

  useEffect(() => {
    if (subscribe) {
      subscribe({
        // 3.1
        name: 'INFO',
        types: ['INFO'],
        callback: ({ token: newToken, online }) => {
          console.log('༼ つ ◕_◕ ༽つ Agents online: ', online ? 'yes' : 'no');
          setLocalStorageValue(lsTokenKey, newToken);
          setToken(newToken);
        }
      });

      subscribe({
        // 3.2
        name: 'STATUS',
        types: ['STATUS'],
        callback: ({ online }) => {
          console.log('༼ つ ◕_◕ ༽つ Agents online: ', online ? 'yes' : 'no');
        }
      });

      subscribe({
        // 3.3
        name: 'TYPING',
        types: ['TYPING'],
        callback: ({ agentId: id, agentName: name, typing: isTyping }) => {
          const allButCurrentAgent = typing.filter((agent) => agent.id !== id);
          if (isTyping) {
            setTyping([...allButCurrentAgent, { id, name }]);
          } else {
            setTyping(allButCurrentAgent);
          }
        }
      });

      subscribe({
        // 3.4
        name: 'MESSAGE',
        types: ['MESSAGE'],
        callback: ({
          /* messageId, agentId, */ agentName,
          text,
          createdAt
        }) => {
          setMessages([
            ...messages,
            {
              // messageId,
              // agentId,
              isIncoming: true,
              agentName,
              text,
              dateTime: createdAt,
              status: 'ok'
            }
          ]);
        }
      });

      subscribe({
        // 3.5
        name: 'MESSAGE_ACK',
        types: ['MESSAGE_ACK'],
        callback: ({ text, createdAt, messageId, reqId = null, ...etc }) => {
          console.log('༼ つ ◕_◕ ༽つ MESSAGE_ACK', reqId, etc);
          const newMessages = [...messages];
          newMessages.find(
            (msg) => Number(msg.reqId) === Number(reqId)
          ).status = 'ok';
          setMessages(newMessages);
        }
      });

      subscribe({
        // 3.6
        name: 'HISTORY',
        types: ['HISTORY'],
        callback: ({ messages: oldMessages }) => {
          setMessages([
            ...oldMessages
              .reverse()
              .map(
                ({
                  author,
                  agentName,
                  text,
                  filename,
                  filesize = 0,
                  createdAt
                }) => ({
                  isIncoming: author !== 'USER',
                  text,
                  agentName,
                  dateTime: createdAt,
                  status: 'ok',
                  fileName: filename,
                  fileSize: filename ? filesize || 1 : 0
                })
              ),
            ...messages
          ]);
        }
      });

      subscribe({
        // 3.7
        name: 'FILE_ACK',
        types: ['FILE_ACK'],
        callback: ({ filename, success, reqId = null, ...etc }) => {
          const newMessages = [...messages];
          console.log('༼ つ ◕_◕ ༽つ FILE_ACK', {
            filename,
            success,
            reqId,
            etc
          });
          newMessages.find(
            (msg) => msg.reqId === Number(reqId)
          ).status = success ? 'ok' : 'error';
          setMessages(newMessages);
        }
      });
    }
  }, [subscribe, typing, setTyping, messages, setMessages, lsTokenKey]);

  // 'PING' // 3.8
  // no reaction for incoming ping

  const actions = useMemo(() => {
    if (send) {
      return {
        // 4.1 Enduser info
        // see `initMessages` above, at the beginning of current hook

        // 4.2 Message
        message: (text) => {
          const reqId = Number(uniqueId());
          send({
            event: 'MESSAGE',
            text,
            reqId
          });
          setMessages([
            ...messages,
            {
              isIncoming: false,
              text,
              dateTime: new Date(),
              status: 'pending',
              reqId
            }
          ]);
        },

        // 4.3 Typing
        typing: (isTyping) =>
          send({
            event: 'TYPING',
            typing: isTyping
          }),

        // 4.4 File upload meta / init
        fileMeta: (fileName, fileType, fileSize) => {
          const reqId = Number(uniqueId());
          send({
            event: 'FILE',
            filename: fileName,
            filetype: fileType,
            size: fileSize,
            reqId
          });
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              isIncoming: false,
              dateTime: new Date(),
              status: 'pending',
              fileName,
              fileSize,
              reqId
            }
          ]);
        },

        // 4.5 History request
        history: (page, size) =>
          send({
            event: 'HISTORY',
            page,
            size
          }),

        // 4.6 Ping
        ping: () =>
          send({
            event: 'PING',
            timestamp: new Date().getTime()
          }),

        // 6 File upload
        file: (binaryData) => {
          rawSend(binaryData);
        },

        disconnect: () => {
          if (currentSocketInstance) {
            currentSocketInstance.unsubscribe();
            currentSocketInstance.close();
          }
        }
      };
    }
    return null;
  }, [send, rawSend, messages, setMessages]);

  useEffect(() => {
    if (socket && token && !historyRequested) {
      actions.history(1, HISTORY_LOAD_MESSAGES);
      setHistoryRequested(true);
    }
  }, [token, historyRequested, setHistoryRequested, socket]);

  // need to trigger request history again after token change
  useEffect(() => {
    if (token !== null) setHistoryRequested(false);
  }, [token]);

  const [pinger, setPinger] = useState(null);

  useEffect(() => {
    if (socket) {
      setPinger(
        setInterval(() => {
          if (!socket) {
            clearInterval(pinger);
          }
          actions.ping();
        }, pingDelay)
      );
    }
  }, [socket]);

  return {
    typing,
    messages,
    actions,
    isLoading: socket === null
  };
};
