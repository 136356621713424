import { path } from 'ramda';
import { useCacheLoad } from '@namespace/helpers';
import { sportsbookActions, useSportsbookStore } from '../../store';

const { FETCH_CMS_PRESETS } = sportsbookActions;

const TopEvents2 = () => {
  const isLoaded = useSportsbookStore(path(['topEventsDataState', 'isLoaded']));
  const dataCachingTime = useSportsbookStore(
    path(['topEventsDataState', 'dataCachingTime'])
  );

  useCacheLoad({
    dataMustBeLoaded: !isLoaded,
    action: FETCH_CMS_PRESETS,
    dataCachingTime
  });

  return null;
};

export default TopEvents2;
