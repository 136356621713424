import { useState, useContext } from 'react';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { useNotifications } from '@namespace/notifications';
import { acceptJackpotWin } from '../api';
import { JackpotsContext } from '../../jackpotsContext';
import {
  IN_HOUSE_JACKPOT_ACCEPT_TYPES,
  IN_HOUSE_JACKPOT_TYPES
} from '../../constants';

export const useGetInHouseJackpotNotificationControls = (
  jackpotId,
  notificationSettings,
  jackpotType
) => {
  const { t } = useI18n();
  const [, { SET_IN_HOUSE_JACKPOT_WIN }] = useContext(JackpotsContext);
  const [isRequestProcessing, setIsRequestProcessing] = useState(false);
  const { errorNotification } = useNotifications();
  const { push } = useHistory();

  const handleAccept = async () => {
    try {
      if (
        notificationSettings?.acceptType ===
        IN_HOUSE_JACKPOT_ACCEPT_TYPES.ACCEPT_CLOSE
      ) {
        if (jackpotType === IN_HOUSE_JACKPOT_TYPES.WIN_MESSAGES) {
          SET_IN_HOUSE_JACKPOT_WIN(null);
        }

        return;
      }

      if (
        notificationSettings?.acceptType ===
        IN_HOUSE_JACKPOT_ACCEPT_TYPES.ACCEPT_REDIRECT
      ) {
        push(notificationSettings?.redirectUrl);
        return;
      }

      setIsRequestProcessing(true);
      const { status } = await acceptJackpotWin(jackpotId);

      if (status !== 'success') {
        errorNotification(t('accountingMessages.accounting_error_18'));
      }

      SET_IN_HOUSE_JACKPOT_WIN(null);
      setIsRequestProcessing(false);
    } catch (e) {
      console.error(e);
      setIsRequestProcessing(false);
      errorNotification(t('accountingMessages.accounting_error_18'));
    }
  };

  const handleClose = () => {
    SET_IN_HOUSE_JACKPOT_WIN(null);
  };

  return { handleAccept, handleClose, isRequestProcessing };
};
