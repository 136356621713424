import { useContext, useMemo } from 'react';
import { ReferenceContext } from '@namespace/common/src/store/reference/context';
import { useI18n } from '@namespace/i18n';
import SelectField from '../../common/SelectField';

const RegSecretQuestionSelect = (props) => {
  const { t } = useI18n();
  const { secretQuestions } = useContext(ReferenceContext);

  const secretQuestionsOptions = useMemo(() => {
    const values = Object.values(secretQuestions);

    return values.map((question) => ({
      name: t(
        `register.secretQuestions.${question.toLowerCase().replace(/\W/g, '')}`
      ),
      value: question
    }));
  }, [secretQuestions, t]);

  return <SelectField options={secretQuestionsOptions} {...props} />;
};

export default RegSecretQuestionSelect;
