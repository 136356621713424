import { AText, Box } from '@alf/uikit';
import { useConfig, useGeneralRoutes } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { Icon } from '@namespace/icons2';
import { useHistory } from '@namespace/router';
import styles from './index.module.css';

const SportsLobbyHeader = ({ id }) => {
  const { t } = useI18n();
  const { push } = useHistory();
  const { config = {} } = useConfig(id);
  const {
    title = '',
    isShowSearch = true,
    isShowLiveCalendar = false
  } = config;
  const { sportSearch, liveCalendar } = useGeneralRoutes();

  return (
    <Box className={styles.wrapper} align="center" justify="between">
      <Box>
        {isShowSearch && (
          <Icon
            name="icons/general/action/search"
            color="line_header_txt"
            size="m"
            className={styles.icon}
            onClick={() => push(sportSearch)}
          />
        )}
        <AText breed="BU" size="xl" color="line_header_txt">
          {t(`web.sportsbook.lobby.title.${title}`)}
        </AText>
      </Box>
      <Box align="center">
        {isShowLiveCalendar && (
          <Icon
            className={styles.icon}
            onClick={() => push(liveCalendar)}
            name="icons/general/item/calendar"
            color="line_header_txt"
            size="m"
          />
        )}
      </Box>
    </Box>
  );
};

export default SportsLobbyHeader;
