import { EmptyState } from '@alf/uikit';
import { useGeneralRoutes } from '@namespace/cms';
import { REDIRECT_PARAM } from '@namespace/common';
import { DEMO_MODE, PureLauncher, REAL_MODE } from '@namespace/games';
import { LocalizedRedirect, useI18n, useLocation } from '@namespace/i18n';
import { useRouteOptionalParams } from '@namespace/router';
import { UserContext, USER_ROLES } from '@namespace/user';
import { isEmpty } from 'lodash';
import { useContext } from 'react';
import { window } from '@namespace/helpers';
import { VirtualSportsContext } from '../../store/context';
import styles from './index.module.css';

const classes = {
  '1x2-bet-virtual-sports': styles['x2-bet-virtual-sports']
};

const Launcher = () => {
  const { pathname } = useLocation();
  const { t } = useI18n();
  const [user] = useContext(UserContext);
  const { role, userInfoLoaded } = user;
  const { virtualSportsFrame, virtualSports, login } = useGeneralRoutes();
  const [{ feed }] = useContext(VirtualSportsContext);
  const { provider, optional = [] } = useRouteOptionalParams(
    `${virtualSportsFrame}/:provider/*`
  );

  const [gameCode] = optional;
  const { games, isLoaded = false } = feed;
  const game = games.data?.[gameCode];

  if (!isLoaded) return null;
  if (isLoaded && isEmpty(game)) {
    return <EmptyState text={t('virtualSports.notFound')} />;
  }

  const {
    launchGameId: gameId,
    hasDemoSupport,
    gameCode: gameCodeFeed,
    gameId: gameIdFeed,
    providerId
  } = game;

  const playMode =
    hasDemoSupport && role === USER_ROLES.GUEST ? DEMO_MODE : REAL_MODE;

  if (
    userInfoLoaded &&
    !isEmpty(game) &&
    !hasDemoSupport &&
    role === USER_ROLES.GUEST
  ) {
    return <LocalizedRedirect to={`${login}/?${REDIRECT_PARAM}${pathname}`} />;
  }

  if (
    !gameId ||
    (game && (gameCodeFeed !== gameIdFeed || providerId !== provider))
  ) {
    return <EmptyState text={t('virtualSports.notFound')} />;
  }

  return (
    <PureLauncher
      provider={provider}
      gameId={gameId}
      gameCode={gameCode}
      className={`${styles.iframe} ${styles[provider] || classes[provider]}`}
      playMode={playMode}
      scrolling="yes"
      isService={true}
      lobbyUrl={`${window.location.origin}${virtualSports}`}
    />
  );
};

export default Launcher;
