import { useContext } from 'react';
import { AText, Box } from '@alf/uikit';
import { UserContext } from '@namespace/user';
import { useI18n } from '@namespace/i18n';
import { isNull } from 'lodash';
import { Amount } from '@namespace/common';
import styles from './index.module.css';

const Balance = () => {
  const { t, c } = useI18n();
  const [user] = useContext(UserContext);
  const { activeWallet = {} } = user;
  const {
    deposit = 0,
    currency = '',
    baseBalance,
    bonusBalance
  } = activeWallet;

  return (
    <Box justify="between" className={styles.balanceBlock}>
      <Box direction="column">
        <AText
          breed="R"
          size="m"
          color="po_menutxt_color_5"
          className={styles.balanceText}
        >
          {t('web.personalaccountmenu.balance.title', 'Balance')}
        </AText>
        {!isNull(bonusBalance) && (
          <span className={styles.balance}>
            <Amount
              amount={`${c.formatCurrency(deposit)} ${currency}`}
              additionalFormatting={true}
              noFormattedAmount={deposit}
              currentAmountBreed="BU"
              currentCurrencyBreed="BU"
              currentAmountSize="m"
              currentAmountColor="po_menutxt_color_1"
            />
          </span>
        )}
      </Box>
      <Box align="end" direction="column">
        <Box>
          {!isNull(bonusBalance) && (
            <AText
              breed="R"
              size="m"
              color="po_txt_color_3"
              align="right"
              className={styles.balanceText}
            >
              {t('web.personalaccountmenu.realbalance.title', 'Payout}')}
            </AText>
          )}

          <span className={styles.balance}>
            <Amount
              amount={`${c.formatCurrency(baseBalance)} ${currency}`}
              additionalFormatting={true}
              noFormattedAmount={deposit}
              currentAmountBreed="BU"
              currentCurrencyBreed="BU"
              currentAmountSize="m"
              currentAmountColor="po_menutxt_color_1"
            />
          </span>
        </Box>
        {!isNull(bonusBalance) && (
          <Box>
            <AText
              breed="R"
              size="m"
              color="po_txt_color_3"
              align="right"
              className={styles.balanceText}
            >
              {t('web.personalaccountmenu.bonusbalance.title', 'Bonus')}
            </AText>
            <span className={styles.balance}>
              <Amount
                amount={`${c.formatCurrency(bonusBalance)} ${currency}`}
                additionalFormatting={true}
                noFormattedAmount={deposit}
                currentAmountBreed="BU"
                currentCurrencyBreed="BU"
                currentAmountSize="m"
                currentAmountColor="po_menutxt_color_1"
              />
            </span>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Balance;
