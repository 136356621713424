import { AText, Box } from '@alf/uikit';
import styles from './index.module.css';

export const ChipGroupTitle = ({ text, status }) => (
  <Box padding="xs s" className={styles[status]}>
    <AText breed="BU" size="xs" color="txt_color_4" transform="uppercase">
      {text}
    </AText>
  </Box>
);
