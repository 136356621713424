import { useContext, useEffect } from 'react';
import { UserContext } from '@namespace/user';
import { SiteSettingsContext } from '@namespace/cms';

const useGetBonusWallet = () => {
  const [user, userActions] = useContext(UserContext);
  const [settings] = useContext(SiteSettingsContext);
  const { bonuses = {} } = settings || {};
  const { isPrewagerEnabled = false } = bonuses;
  const { activeWallet = {} } = user;
  const { walletHash } = activeWallet;
  const { FETCH_BONUS_WALLET } = userActions;

  useEffect(() => {
    // listen for all activeWallet changing and summing bonus and base balance
    // if bonus wallet exists
    if (isPrewagerEnabled && walletHash) {
      FETCH_BONUS_WALLET({ walletHash });
    }
  }, [walletHash, isPrewagerEnabled]);
};

export default useGetBonusWallet;
