/**
 * @memberOf module:SetScore
 * @typedef {{
 *   scopeData: [
 *     { number: number, value: number }
 *   ],
 *   scopeId: number
 * }} ResultTypeDataItem
 */

/**
 * Generates new {ResultTypeDataItem}
 * @param {number} scopeId
 * @param {number} playerNumber
 * @param {number} newValue
 * @returns {ResultTypeDataItem}
 */
const generateResultTypeDataItem = (scopeId, playerNumber, newValue) => ({
  scopeData: [
    { number: playerNumber, value: newValue },
    { number: playerNumber === 1 ? 2 : 1, value: 0 }
  ],
  scopeId
});

/**
 * @memberOf module:SetScore
 * @typedef {[
 *   {
 *     resultTypeData: [ResultTypeDataItem],
 *     resultTypeId: number
 *   }
 * ]} ResultTypeData
 */

/**
 * Replaces value in scoreboard.byResultType by the given resultTypeId, scopeId and playerNumber
 * @param {ResultTypeData} initialData
 * @param {number} resultTypeId
 * @param {number} scopeId
 * @param {number} playerNumber
 * @param {number} newValue
 * @returns {ResultTypeData}
 */
export const modifyByResultTypeData = (
  initialData,
  resultTypeId,
  scopeId,
  playerNumber,
  newValue
) => {
  let modifiedData = initialData.map(
    ({ resultTypeData, resultTypeId: rId }) => ({
      resultTypeId: rId,
      resultTypeData: resultTypeData.map(
        ({ scopeData = [], scopeId: sId }) => ({
          scopeId: sId,
          scopeData: scopeData.map((s) => ({ ...s }))
        })
      )
    })
  );
  const byResultIdx = modifiedData.findIndex(
    (el) => el.resultTypeId === resultTypeId
  );
  if (byResultIdx !== -1) {
    const byScopeIdIdx = modifiedData[byResultIdx].resultTypeData.findIndex(
      (el) => el.scopeId === scopeId
    );
    if (byScopeIdIdx !== -1) {
      const { scopeData } = modifiedData[byResultIdx].resultTypeData[
        byScopeIdIdx
      ];
      modifiedData[byResultIdx].resultTypeData[
        byScopeIdIdx
      ].scopeData = scopeData.map(({ number, value }) => {
        if (number === playerNumber) {
          return {
            value: newValue + value,
            number
          };
        }
        return { number, value };
      });
    } else {
      modifiedData[byResultIdx].resultTypeData = [
        ...modifiedData[byResultIdx].resultTypeData,
        generateResultTypeDataItem(scopeId, playerNumber, newValue)
      ];
    }
  } else {
    modifiedData = [
      ...modifiedData,
      {
        resultTypeData: [
          generateResultTypeDataItem(scopeId, playerNumber, newValue)
        ],
        resultTypeId
      }
    ];
  }

  return modifiedData;
};

/**
 *
 * @param {[ResultTypeDataItem]} initialData
 * @param {number} scopeId
 * @param {number} playerNumber
 * @param {number} newValue
 */
export const modifyTotalData = (
  initialData,
  scopeId,
  playerNumber,
  newValue
) => {
  const modifiedData = initialData.map(({ scopeData, scopeId: sId }) => ({
    scopeData: scopeData.map(({ number, value }) => ({ number, value })),
    scopeId: sId
  }));

  const resultTypeDataItemIdx = modifiedData.findIndex(
    (i) => i.scopeId === scopeId
  );

  if (resultTypeDataItemIdx !== -1) {
    const scopeDataItemIdx = modifiedData[
      resultTypeDataItemIdx
    ].scopeData.findIndex(({ number }) => number === playerNumber);
    modifiedData[resultTypeDataItemIdx].scopeData[scopeDataItemIdx] = {
      number: playerNumber,
      value:
        newValue +
        modifiedData[resultTypeDataItemIdx].scopeData[scopeDataItemIdx].value
    };
  }

  return modifiedData;
};
