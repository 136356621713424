import PropTypes from 'prop-types';
import clsx from 'clsx';
import Box from '../Box';
import styles from './index.module.css';

const StatusIcon = ({ className = '', status = '' }) => (
  <Box
    className={clsx(styles.icon, className, styles[`statusColor_${status}`])}
  />
);

StatusIcon.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string
};

export default StatusIcon;
