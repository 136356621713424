import { get } from 'lodash';
import useSiteSettings from '../useSiteSettings';

const useMobileNavMenuSettings = () => {
  const settings = useSiteSettings();

  return get(settings, 'mobileNavigation', {});
};

export default useMobileNavMenuSettings;
