import { kebabCase } from 'lodash';
import Box from '../../../Box';
import AText from '../../../AText';
import styles from '../../index.module.css';

const Item = ({
  label,
  index,
  counter,
  tabIndex,
  isActive,
  handleClick,
  tabClassName,
  counterClassName,
  tabActiveClassName
}) => {
  return (
    <Box
      data-role={`tabs-item-${kebabCase(label)}`}
      key={label}
      onClick={() => handleClick(index)}
      className={`
      ${styles.tab}
      ${
        tabIndex === index || isActive
          ? `${styles.active} ${tabActiveClassName}`
          : ''
      }
      ${tabClassName}
    `}
      align="center"
      justify="center"
    >
      <AText breed="R" size="m" breakWord="keep-all">
        {label}
      </AText>
      {Boolean(counter) && (
        <Box
          align="center"
          justify="center"
          className={`${styles.counter} ${counterClassName}`}
        >
          <AText breed="R" size="xs" color="po_txt_color_2">
            {counter}
          </AText>
        </Box>
      )}
    </Box>
  );
};

const DefaultListComponent = ({
  list,
  tabIndex,
  handleClick,
  tabClassName,
  counterClassName,
  tabActiveClassName,
  LocalizedNavLink,
  pathname
}) => {
  return list
    .filter(({ disabled }) => !disabled)
    .map(({ label, link, counter, title }, index) => {
      const item = (
        <Item
          title={title}
          list={list}
          index={index}
          label={label}
          counter={counter}
          handleClick={handleClick}
          tabClassName={tabClassName}
          tabIndex={!link ? tabIndex : undefined}
          isActive={pathname.includes(link)}
          counterClassName={counterClassName}
          tabActiveClassName={tabActiveClassName}
        />
      );
      return link ? (
        <LocalizedNavLink
          data-role={`tabs-item-${label}`}
          key={label}
          className={styles.link}
          activeClassName={styles.active}
          to={link}
        >
          {item}
        </LocalizedNavLink>
      ) : (
        item
      );
    });
};

export default DefaultListComponent;
