import { useContext, useMemo, useState } from 'react';
import clsx from 'clsx';
import { DeviceContext } from '@namespace/device';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { UserContext } from '@namespace/user';
import { AText, Box } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { isEmpty } from 'lodash';
import { HeaderLayout } from './HeaderLayout';
import Scoreboards from '../Scoreboards';
import Stream from '../Stream';
import EventComments from '../../EventComments';
import { useVideoStream } from '../../../hooks/useVideoStream';
import { useGetPathParams } from '../../../hooks/useGetPathParams';
import { useEventFields } from '../../../store';
import {
  CANCELLED_EVENT_STATUS,
  ONE_EVENT_HEADER_TABS as TABS,
  ONE_EVENT_HEADER_TABS
} from '../../../constants';
import { EventHeaderProvider, useEventHeaderCtxStore } from './store';
import styles from './index.module.css';

export const HeaderBare = ({
  eventId,
  isShowStatUrl,
  isShowScoreboard,
  isShowSportRadarWidget,
  dataRole
}) => {
  const { t } = useI18n();
  const [state] = useContext(UserContext);
  const { settings } = state;
  const { lineViewType: lineView } = settings;
  const { goBack } = useHistory();
  const { isDesktop } = useContext(DeviceContext);
  const {
    categoryName = '',
    tournamentName = '',
    sportName = '',
    eventStatusType,
    isCachedEvent
  } = useEventFields({
    eventId,
    fields: [
      'categoryName',
      'tournamentName',
      'sportName',
      'eventStatusType',
      'isCachedEvent'
    ]
  });

  const [tabState, setTabState] = useState({
    type: ONE_EVENT_HEADER_TABS.SCORE,
    opened: true
  });
  const { activeSport } = useGetPathParams();
  const isPinned = useEventHeaderCtxStore('isPinned');

  const backTitle = useMemo(
    () =>
      (isDesktop
        ? [sportName, categoryName, tournamentName]
        : [categoryName, tournamentName]
      )
        .filter(Boolean)
        .join(' | '),
    [isDesktop, sportName, categoryName, tournamentName]
  );

  const {
    expandVideo,
    streamUrl,
    eventBroadcastUrl,
    errorMessage,
    isShowVideo,
    isVideoFree
  } = useVideoStream(eventId, tabState, setTabState);

  const isToolsEnabled = isShowStatUrl || isShowScoreboard || isShowVideo;
  const isShowBackHeader = Boolean(backTitle) && (isDesktop || isToolsEnabled);

  const cachedEventTitle = useMemo(() => {
    if (isEmpty(activeSport)) {
      return `web.sportsbook.eventpage.text.${
        eventStatusType === CANCELLED_EVENT_STATUS
          ? 'eventcancelled.short'
          : 'eventfinished.short'
      }`;
    }

    return `web.sportsbook.eventpage.text.${
      eventStatusType === CANCELLED_EVENT_STATUS
        ? 'eventcancelled'
        : 'eventfinished'
    }`;
  }, [activeSport, eventStatusType]);
  return (
    <Box direction="column" className={clsx(isPinned && styles.pinnedHeader)}>
      <HeaderLayout
        dataRole={dataRole}
        isShowStatUrl={isShowStatUrl}
        isShowScoreboard={isShowScoreboard}
        expandVideo={expandVideo}
        isShowVideo={isShowVideo && !isCachedEvent}
        isVideoFree={isVideoFree && !isCachedEvent}
        eventId={eventId}
        onGoBack={goBack}
        backTitle={backTitle}
        isShowBackHeader={isShowBackHeader}
        tabState={tabState}
        setTabState={setTabState}
        isDesktop={isDesktop}
        lineView={lineView}
      />
      {tabState.opened && (
        <>
          {tabState.type === TABS.SCORE && (
            <Scoreboards
              dataRole={dataRole}
              eventId={eventId}
              isShowSportRadarWidget={isShowSportRadarWidget}
            />
          )}
          {tabState.type === TABS.VIDEO && !isCachedEvent && (
            <Stream
              streamUrl={streamUrl}
              eventBroadcastUrl={eventBroadcastUrl}
              errorMessage={errorMessage}
              eventId={eventId}
            />
          )}
        </>
      )}
      <EventComments
        eventId={eventId}
        classNames={{
          commentsContainerClass: styles.commentsContainer,
          commentItemClass: styles.commentItemClass
        }}
      />
      {isCachedEvent && (
        <Box justify="center" align="center" className={styles.cachedEventInfo}>
          <Box align="center" right="s">
            <Icon
              size="s"
              name="icons/general/state/info"
              color="state_warning"
            />
          </Box>
          <AText size="s" breed="BU" color="state_warning">
            {t(cachedEventTitle)}
          </AText>
        </Box>
      )}
    </Box>
  );
};

export const Header = (props) => (
  <EventHeaderProvider>
    <HeaderBare {...props} />
  </EventHeaderProvider>
);
