import { memo, useContext } from 'react';
import { SearchEmpty } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import GamesList from '../../common/GamesList';
import { GamesContext } from '../../context';
import { ROWS_WITH_GAMES } from '../../constants';
import { gamesPlaceholder } from '../../utils';

import styles from './index.module.css';

export const SearchResultGames = memo(
  ({ games, isFeedLoaded, config, casinoType, controls, gamesListId }) => {
    const { t } = useI18n();
    const [{ excludedActiveBonusGamesList }] = useContext(GamesContext);
    const { data: excludedBonusGamesList } = excludedActiveBonusGamesList;

    if (games?.length || !isFeedLoaded) {
      return (
        <div className={styles.resultsWrapper}>
          <GamesList
            casinoType={casinoType}
            games={!isFeedLoaded ? gamesPlaceholder : games}
            config={config}
            excludedBonusGamesList={excludedBonusGamesList}
            controls={controls}
            kind={ROWS_WITH_GAMES.ALL_WITHOUT_PAGINATION}
            {...(gamesListId ? { id: gamesListId } : {})}
          />
        </div>
      );
    }

    return (
      !!games && (
        <SearchEmpty
          classNames={{
            text: styles.searchEmptyText,
            infoText: styles.searchEmptyInfoText
          }}
          text={t('casinoGames.notFound')}
          infoText={t('web.games.search.sorry')}
        />
      )
    );
  }
);
