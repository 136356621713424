import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { createAsyncValidator } from '@alf/validators';
import InputField from '../../common/InputField';
import { getPinType, isPinCode, pinCodeValidator } from './utils';

const PinCodeInput = ({ rules = {}, isForAllCountries = false, ...props }) => {
  const { watch, setValue, register } = useFormContext();
  const chosenCountry = watch('country');

  useEffect(() => {
    register('pinType');
  }, [register]);

  useEffect(() => {
    const pinType = getPinType(chosenCountry);
    setValue('pinType', pinType);
  }, [chosenCountry, setValue]);

  if (isForAllCountries || isPinCode(chosenCountry)) {
    return (
      <InputField
        rules={{
          ...rules,
          ...createAsyncValidator((value) =>
            pinCodeValidator(value, watch('country'))
          )
        }}
        {...props}
      />
    );
  }
  return null;
};

export default PinCodeInput;
