import { useMemo } from 'react';

import { isFunction } from 'lodash';

export const useActions = (types, dispatch, customActionCreators) =>
  useMemo(
    () =>
      Object.entries({ ...types, ...customActionCreators }).reduce(
        (memo, [type, action]) => ({
          ...memo,
          [type]: isFunction(action)
            ? // apply middleware
              action(dispatch)
            : // support implicit actions
              (payload) => dispatch({ type, payload })
        }),
        {}
      ),
    [customActionCreators, dispatch, types]
  );
