import { window, document } from '@namespace/helpers';

const createInitParams = ({ language, title, subtitle }) => ({
  language_code: language,
  bot_id: '1', // move to config
  params_from_site: {},
  title,
  subtitle,
  colors: {
    device: {
      bg: '#FCFCFC',
      dot: '#95CBFB',
      border: '#188BE4',
      container: '#FCFCFC'
    },
    keyboardButton: {
      border: '#000033',
      bg: '#000033',
      text: '#BFBFCC'
    },
    header: {
      bg: '#000033',
      text: '#BFBFCC'
    },
    poweredLink: {
      color: '#188BE4'
    },
    userInput: {
      bg: '#f4f7f9',
      border: '#f4f7f9',
      text: '#565867'
    },
    sentMessage: {
      bg: '#000033',
      text: '#BFBFCC'
    },
    receivedMessage: {
      bg: '#eaeaea',
      text: '#222222'
    },
    inlineKeyboardButton: {
      bg: '#4e8cff',
      text: '#4e8cff'
    }
  },
  titleImageUrl:
    'https://storage.static.kwizbot.io/casinoua.kwizbot.io/static/BlueLogo.png',
  botAvatarImageUrl:
    'https://storage.static.kwizbot.io/casinoua.kwizbot.io/static/GreenLogo.png',
  open_on_msg: false,
  btn_restart_enabled: false,
  recognize_user_meta: false
});

const injectCustomWidgetStyle = () => {
  if (document.querySelector('[data-name="js-kwizbot-favbet-custom-style"]'))
    return;

  const css = `
    #kwizbot_widget.showAfterCssLoaded {
      display: none!important;
    }

    #kwizbot_widget.showAfterCssLoaded.show {
      display: block!important;
    }
    
    #kwizbot_widget .sc-header-title-image {
      border-radius: 0;
    }
  `;
  const head = document.head || document.getElementsByTagName('head')[0];
  const style = document.createElement('style');
  style.dataset.name = 'js-kwizbot-favbet-custom-style';

  head.appendChild(style);
  style.appendChild(document.createTextNode(css));
};

const injectWidgetElement = () => {
  if (document.getElementById('kwizbot_widget')) return;
  const widgetElement = document.createElement('div');

  widgetElement.setAttribute('id', 'kwizbot_widget');

  document.body.append(widgetElement);
};

function waitUntil(name, fn, times = 10) {
  const time = 1000;
  if (!times) console.error('kwizbot not loaded');
  setTimeout(() => {
    if (!window[name]) {
      waitUntil(name, fn, times - 1);
      return;
    }

    fn();
  }, time);
}

const injectWidgetScript = ({ language = 'uk', title, subtitle, onReady }) => {
  const initParams = createInitParams({ language, title, subtitle });

  if (document.querySelector('[data-name="js-kwizbot-favbet-widget-script"]')) {
    // translations loaded twice
    waitUntil('kw_event', () => {
      window.kw_event('command', { title, subtitle });
    });
    return;
  }

  waitUntil('kw_event', onReady);

  const scriptElement = document.createElement('script');

  scriptElement.defer = true;

  scriptElement.innerHTML = `
    (function (k, w, i, z, b, o, t) {k['KwizbotWidget'] = b; k[b] = k[b] || function () { w.head.insertAdjacentHTML('beforeend', '<style type="text/css">.showAfterCssLoaded{display: none;}</style>'); (k[b].q = k[b].q || []).push(arguments) }, k[b].l = 1 * new Date(); o = w.createElement(i), t = w.getElementsByTagName(i)[0]; o.async = 1; o.src = z; t.parentNode.insertBefore(o, t) })(window, document, 'script', 'https://widget-casinoua.kwizbot.io/kwjs.js', 'kw');
    kw('init', ${JSON.stringify(initParams)})
  `;

  scriptElement.dataset.name = 'js-kwizbot-favbet-widget-script';

  document.body.append(scriptElement);
};

const kwizInjector = async ({ language, title } = {}) =>
  new Promise((res) => {
    injectCustomWidgetStyle();
    injectWidgetElement();
    injectWidgetScript({
      language,
      title,
      subtitle: '',
      onReady: res
    });
  });

export default kwizInjector;
