import { useCallback, useEffect, useState } from 'react';
import { useI18n } from '@namespace/i18n';
import { uniqBy } from 'lodash';
import { getLiveCalendarEvents } from '../../api/getLiveCalendarEvents';
import { TIME_FILTER_DATA } from '../../components/LiveCalendar/constants';
import { getStartEndTime } from '../../components/LiveCalendar/utils/getStartEndTime';
import { normalizeEvents } from '../../components/LiveCalendar/utils/normalizeEvents';
import { sportsbookActions } from '../../store';

const { SET_EVENTS } = sportsbookActions;

const emptyArr = [];
const emptyObj = {};

export const useLiveCalendarEvents = () => {
  const { language, f, timezone } = useI18n();
  const today = f.getDateTime().toISODate();
  // States
  const [isEventsLoading, setIsEventsLoading] = useState(false);
  const [eventsData, setEventsData] = useState(emptyObj);
  const [sportsList, setSportsList] = useState(emptyArr);
  const [selectedHours, setSelectedHours] = useState(TIME_FILTER_DATA[0]);
  const [selectedDate, setSelectedDate] = useState(today);
  const [eventsOffset, setEventsOffset] = useState(0);
  const [selectedSports, setSelectedSports] = useState(emptyArr);
  const [isOnlyWithVideo, setIsOnlyWithVideo] = useState(false);

  const resetFilters = useCallback(() => {
    setSelectedHours(TIME_FILTER_DATA[0]);
    setSelectedDate(today);
    setEventsOffset(0);
    setSelectedSports(emptyArr);
    setIsOnlyWithVideo(false);
  }, [today]);

  const selectDay = (date) => {
    setSelectedDate(f.getDateTime({ date, withTZ: false }).toISODate());
    setSelectedHours(TIME_FILTER_DATA[0]);
    setSelectedSports(emptyArr);
    setEventsOffset(0);
  };

  const selectHours = (hoursInterval) => {
    setSelectedHours(hoursInterval);
    setEventsOffset(0);
  };

  const selectSport = ({ value = [] }) => {
    setSelectedSports(value);
    setEventsOffset(0);
  };

  const showMore = () => {
    setEventsOffset((prevState) => prevState + 10);
  };

  const setWithVideo = (isWithVideo) => {
    setEventsOffset(0);
    setIsOnlyWithVideo(isWithVideo);
  };

  useEffect(() => {
    (async () => {
      const { startTime, endTime } = getStartEndTime(selectedHours.value);
      setIsEventsLoading(true);
      try {
        const { elements, sports, info } = await getLiveCalendarEvents({
          lang: language,
          timezone,
          offset: eventsOffset,
          startTime: `${selectedDate} ${startTime}`,
          endTime: `${selectedDate} ${endTime}`,
          sports: selectedSports.map(Number),
          isOnlyWithVideo
        });

        const normalizedEvents = normalizeEvents({
          events: elements,
          f
        });

        SET_EVENTS({
          events: normalizedEvents,
          lineKey: 'liveCalendar'
        });
        setEventsData((prevState) => ({
          isMoreEvents: info.moreEvents,
          events:
            eventsOffset >= 10 && normalizedEvents.length
              ? uniqBy([...prevState.events, ...normalizedEvents], 'eventId')
              : normalizedEvents
        }));
        setSportsList(sports);
      } catch (e) {
        console.error(e);
      }
      setIsEventsLoading(false);
    })();
  }, [
    f,
    selectedDate,
    selectedHours,
    selectedSports,
    isOnlyWithVideo,
    language,
    timezone,
    eventsOffset
  ]);

  return {
    isEventsLoading,
    eventsData,
    sportsList,
    date: selectedDate,
    selectedHours,
    selectedSports,
    resetFilters,
    selectDay,
    selectHours,
    selectSport,
    showMore,
    setIsOnlyWithVideo: setWithVideo,
    isOnlyWithVideo
  };
};
