import { useContext, useEffect, useState } from 'react';
import { equals, flatten, path } from 'ramda';
import { groupBy } from 'lodash';
import {
  getLocalStorageValue,
  LOCAL_STORAGE_KEYS,
  setLocalStorageValue
} from '@namespace/helpers';
import { useI18n } from '@namespace/i18n';
import { UserContext } from '@namespace/user';
import { useSportsbookStore } from '../../store';

export const useActiveHeadGroups = (
  isUserGroupsEnabled = false,
  groupsAmount = 3,
  sportId,
  serviceId
) => {
  const { language } = useI18n();
  const [activeHeadGroups, setActiveHeadGroups] = useState([]);
  const sportHeadGroups = useSportsbookStore(path(['headGroups', sportId]));
  const [user] = useContext(UserContext);
  const { id: userId } = user;
  const userHeadGroups = getLocalStorageValue(
    `${LOCAL_STORAGE_KEYS.ACTIVE_GROUPS}_${userId}_${language}`,
    null
  );

  useEffect(() => {
    if (sportId && sportHeadGroups) {
      const userSportHeadGroups = userHeadGroups
        ? userHeadGroups[sportId]
        : null;
      if (
        isUserGroupsEnabled &&
        userId &&
        sportHeadGroups &&
        userSportHeadGroups &&
        userSportHeadGroups.length
      ) {
        const activeHeadGroupsIds = activeHeadGroups.map(
          ({ marketTemplateId }) => marketTemplateId
        );
        if (!equals(activeHeadGroupsIds, userSportHeadGroups)) {
          const groupedHeadGroups = groupBy(
            sportHeadGroups,
            'marketTemplateId'
          );
          const activeGroups = flatten(
            userSportHeadGroups.map((item) => groupedHeadGroups[item] || [])
          );
          setActiveHeadGroups(activeGroups.slice(0, groupsAmount));
        }
      } else if (sportId && sportHeadGroups && sportHeadGroups.length) {
        setActiveHeadGroups(sportHeadGroups.slice(0, groupsAmount));
      }
    }
  }, [
    userId,
    sportId,
    serviceId,
    sportHeadGroups,
    isUserGroupsEnabled,
    groupsAmount
  ]);

  useEffect(() => {
    if (userId && activeHeadGroups.length) {
      const activeHeadGroupsIds = activeHeadGroups.map(
        ({ marketTemplateId }) => marketTemplateId
      );
      const newUserGroups = {
        ...userHeadGroups,
        [sportId]: activeHeadGroupsIds
      };
      setLocalStorageValue(
        `${LOCAL_STORAGE_KEYS.ACTIVE_GROUPS}_${userId}_${language}`,
        newUserGroups
      );
    }
  }, [userId, activeHeadGroups]);

  return [activeHeadGroups, setActiveHeadGroups];
};
