import { usePrevious } from '@namespace/helpers';
import { useI18n } from '@namespace/i18n';
import { memo } from 'react';
import {
  calculateBulletScore,
  getDetailedScoresForTable,
  getEventValues,
  getResultTypeScoreValues,
  stripeScore
} from '../../utils';
import {
  TOTAL_SCOPE_ID_BY_SPORT,
  TOTAL_SYMBOL_BY_SPORT,
  BULLETS_TYPE_ID,
  SCOPE_IDS_FOR_PERIODS
} from '../../constants';
import { COLUMN_TYPES } from '../../../../constants';
import ScoreDetails from '../../Details';

const IceHockey = ({
  sportId,
  eventResultId,
  byResultType,
  total,
  isShortVersion,
  lineView
}) => {
  const { t } = useI18n();

  const previousByResultType = usePrevious(byResultType);
  let bulletsScore = null;
  let totalBulletsScore = null;
  if (
    byResultType &&
    eventResultId === BULLETS_TYPE_ID &&
    byResultType.some(({ resultTypeData, resultTypeId }) =>
      resultTypeId === BULLETS_TYPE_ID
        ? resultTypeData.every(
            (data) => data.number && data.scopeId && data.value
          )
        : false
    )
  ) {
    [bulletsScore, totalBulletsScore] = calculateBulletScore(
      byResultType,
      eventResultId
    );
  } else if (
    byResultType &&
    eventResultId === BULLETS_TYPE_ID &&
    previousByResultType
  ) {
    [bulletsScore, totalBulletsScore] = calculateBulletScore(
      previousByResultType,
      eventResultId
    );
  }

  const commonScoreTotalData = {
    type: COLUMN_TYPES.TOTAL,
    tag: t(TOTAL_SYMBOL_BY_SPORT.default),
    scores: getEventValues(total, TOTAL_SCOPE_ID_BY_SPORT[sportId])
  };
  let fullFormattedScore;

  if (eventResultId === BULLETS_TYPE_ID) {
    if (isShortVersion) {
      fullFormattedScore = [{ ...commonScoreTotalData }];
    } else {
      fullFormattedScore = [
        ...bulletsScore,
        {
          ...commonScoreTotalData,
          scores: [
            totalBulletsScore?.firstTeamResult,
            totalBulletsScore?.secondTeamResult
          ]
        }
      ];
    }
  } else if (isShortVersion) {
    fullFormattedScore = [
      { ...commonScoreTotalData },
      {
        type: COLUMN_TYPES.DEFAULT,
        tag: '',
        scores: getResultTypeScoreValues(
          byResultType,
          eventResultId,
          SCOPE_IDS_FOR_PERIODS[sportId] || TOTAL_SCOPE_ID_BY_SPORT[sportId]
        )
      }
    ];
  } else {
    fullFormattedScore = [
      { ...commonScoreTotalData },
      ...stripeScore(
        getDetailedScoresForTable(byResultType),
        sportId,
        eventResultId,
        false
      )
    ];
  }

  return (
    <ScoreDetails
      isShortVersion={isShortVersion}
      scoreData={fullFormattedScore}
      lineView={lineView}
    />
  );
};

export default memo(IceHockey);
