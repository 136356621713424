import { Notification, Box } from '@alf/uikit';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import styles from './index.module.css';

const LudomanInVerificationToast = ({ onClose }) => {
  const { t } = useI18n();

  const message = (
    <Box direction="column" align="center" justify="center">
      <span>
        {t('ludoman.status.true.info1')}

        <LocalizedLink
          className={styles.link}
          to="/personal-office/messages/inbox"
        >
          {t('ludoman.status.true.linkTitle')}
        </LocalizedLink>

        {t('ludoman.status.true.info2')}
      </span>
    </Box>
  );

  return <Notification onClose={onClose} message={message} />;
};

export default LudomanInVerificationToast;
