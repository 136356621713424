import { useConfig } from '@namespace/cms';
import Wrapper from './Wrapper';
import DocumentInformation from './DocumentInformation';

const VerificationDocuments = ({ id }) => {
  const { config } = useConfig(id);
  return (
    <Wrapper>
      <DocumentInformation config={config} />
    </Wrapper>
  );
};

export default VerificationDocuments;
