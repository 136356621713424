import { useContext } from 'react';
import { Box } from '@alf/uikit';
import { StepperContext } from '@namespace/common';
import EnterEmail from '../EnterEmail';
import CancelButton from '../CancelButton';
import { sendChangePasswordLink } from '../../api';
import styles from './index.module.css';

const SendCodeToEmail = ({
  onStepSuccess,
  onStepError,
  onGoFirst,
  classNames = {},
  enterMailStyle = {}
}) => {
  const {
    stepper: {
      formData: { email = '' },
      errorCode = ''
    }
  } = useContext(StepperContext);

  const { cancelButtonClassNames = '', wrapperClassName } = classNames;

  const handleSubmit = ({ email: userEmail, captcha }) => {
    sendChangePasswordLink({
      email: userEmail,
      cap: captcha
    })
      .then(({ response }) => onStepSuccess({ ...response, email: userEmail }))
      .catch(({ errorCode: stepErrorCode }) =>
        onStepError({ errorCode: stepErrorCode })
      );
  };

  return (
    <Box direction="column" className={`${styles.step} ${wrapperClassName}`}>
      <EnterEmail
        enterMailStyle={enterMailStyle}
        email={email}
        withCaptcha={true}
        onSuccess={(data) => handleSubmit(data)}
        errorCode={errorCode}
      />
      <CancelButton
        className={`${styles.backButton} ${cancelButtonClassNames}`}
        onCancel={onGoFirst}
      />
    </Box>
  );
};

export default SendCodeToEmail;
