const tryParseJson = (data) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    console.log(e, data);
    return {};
  }
};

export const parseEventData = (data) => {
  return typeof data === 'string' ? tryParseJson(data) : data;
};
