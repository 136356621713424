import { window } from '@namespace/helpers';

const getRootDomain = () => {
  const { host } = window.location;

  if (host === 'localhost') {
    return 'localhost';
  }

  const parts = host.split('.');

  if (parts.length > 2) {
    return host.slice(parts[0].length);
  }
  return `.${host}`;
};

export default getRootDomain;
