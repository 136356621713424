import { useCallback, useContext } from 'react';
import { NotificationContext } from '../../store/context';
import {
  setErrorNotificationAction,
  setInfoNotificationAction,
  setNotificationAction,
  setSuccessNotificationAction,
  setWarningNotificationAction
} from '../../store/actions';

const useNotifications = () => {
  const [{ notificationDispatch = () => {} }] = useContext(NotificationContext);

  return {
    errorNotification: useCallback(
      (data) => notificationDispatch(setErrorNotificationAction(data)),
      []
    ),
    successNotification: useCallback(
      (data) => notificationDispatch(setSuccessNotificationAction(data)),
      []
    ),
    infoNotification: useCallback(
      (data) => notificationDispatch(setInfoNotificationAction(data)),
      []
    ),
    warningNotification: useCallback(
      (data) => notificationDispatch(setWarningNotificationAction(data)),
      []
    ),
    customNotification: useCallback(
      (data) => notificationDispatch(setNotificationAction(data)),
      []
    )
  };
};

export default useNotifications;
