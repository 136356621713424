export const initialState = {};

export const types = {
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB'
};

export const reducers = {
  [types.SET_ACTIVE_TAB]: (state, { activeTab }) => ({
    ...state,
    activeTab
  })
};
