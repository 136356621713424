import { useContext } from 'react';
import {
  AText,
  Box,
  Button,
  Dots,
  DOTS_TYPE,
  POSITION_TYPES
} from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { useI18n } from '@namespace/i18n';
import { OnboardingContext } from '../../store/context';
import { useCurrentStep, useCurrentTour, useEndTourAction } from '../../hooks';
import { useButtonsData } from './hooks';
import { parseLinks } from './utils';
import { onboardingComponentsMap } from '../Onboarding/componentsMap';
import styles from './index.module.css';

export const Tooltip = () => {
  const {
    title = '',
    content,
    links,
    contentProps = {},
    width = '378px',
    showDots = true
  } = useCurrentStep();
  const { hideFirstItem } = useCurrentTour();
  const [{ stepIndex, steps }, { GO_TO, RUN }] = useContext(OnboardingContext);
  const { t } = useI18n();

  let actualContent;
  switch (typeof content) {
    case 'object': {
      const { componentName } = content;

      const Component = onboardingComponentsMap[componentName];
      if (!Component) {
        console.error(`There is no such component: ${componentName}`);
        actualContent = '';
      } else {
        actualContent = <Component {...contentProps} />;
      }
      break;
    }

    case 'string': {
      actualContent = parseLinks(t(content), links, { RUN });
      break;
    }

    default:
      actualContent = content;
  }

  const {
    prevButtonAction,
    prevButtonTitle,
    nextButtonAction,
    nextButtonTitle
  } = useButtonsData();

  return (
    <Box
      data-role="onboarding-tooltip-wrapper"
      direction="column"
      gap="s"
      padding="m"
      className={styles.root}
      style={{ width }}
    >
      <Icon
        dataRole="onboarding-tooltip-close"
        className={styles.close}
        size="xs"
        name="icons/general/action/close"
        onClick={useEndTourAction()}
        color="btn_close_dark_color"
      />
      <Box
        data-role="onboarding-tooltip-title"
        align="center"
        justify="left"
        className={styles.title}
      >
        <AText breed="B" size="xl" color="txt_color_3">
          {t(title)}
        </AText>
      </Box>
      {actualContent != null && (
        <div
          data-role="onboarding-tooltip-content"
          className={styles.contentWrapper}
        >
          {actualContent}
        </div>
      )}
      <Box justify="between" className={styles.buttons}>
        <Box className={styles.button}>
          {prevButtonAction && (
            <Button
              dataRole="onboarding-tooltip-btn-prev"
              intent="secondary"
              actionType="color3"
              onClick={prevButtonAction}
            >
              {prevButtonTitle}
            </Button>
          )}
        </Box>
        {steps.length > 1 && showDots && (
          <Dots
            dataRole="onboarding-tooltip"
            activeIndex={stepIndex}
            dotsNumber={steps.length}
            setActiveIndex={GO_TO}
            hideFirstItem={hideFirstItem}
            config={{
              position: POSITION_TYPES.OUTSIDE,
              type: DOTS_TYPE.SMALL
            }}
          />
        )}
        <Button
          dataRole="onboarding-tooltip-btn-next"
          intent="secondary"
          onClick={nextButtonAction}
        >
          {nextButtonTitle}
        </Button>
      </Box>
    </Box>
  );
};
