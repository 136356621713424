import { SportbookSkeletLine } from './SportbookSkeletLine';
import { SportbookSkeletHeader } from './SportbookSkeletHeader';
import SportbookLineHeader from './SportbookLineHeader';
import styles from './index.module.css';

const SportbookSkelet = ({ showLineHeader = false }) => (
  <div className={styles.wrapper}>
    {showLineHeader && <SportbookLineHeader />}
    <SportbookSkeletHeader />
    <SportbookSkeletLine />
    <SportbookSkeletLine />
    <SportbookSkeletHeader />
    <SportbookSkeletLine />
    <SportbookSkeletLine />
    <SportbookSkeletLine />
    <SportbookSkeletLine />
    <SportbookSkeletLine />
    <SportbookSkeletHeader />
    <SportbookSkeletLine />
    <SportbookSkeletLine />
    <SportbookSkeletLine />
    <SportbookSkeletLine />
  </div>
);

export default SportbookSkelet;
