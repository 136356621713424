import { useState, useContext, useEffect } from 'react';
import { Box } from '@alf/uikit';

import { JackpotsContext } from '../../../../../jackpotsContext/context';
import { JackpotItemInGame } from './JackpotItemInGame';

import styles from './index.module.css';

export const JackpotInGame = () => {
  const [{ jackpots, ids }] = useContext(JackpotsContext);
  const [openedJackpotType, setOpenedJackpotType] = useState();
  const idsDep = ids.join('');

  useEffect(() => {
    if (ids.length && !openedJackpotType) {
      setOpenedJackpotType(ids[0]);
    }
  }, [idsDep]);

  const handleItemToggle = (isOpen, jackpotType) =>
    setOpenedJackpotType(isOpen ? jackpotType : null);

  return (
    <Box className={styles.wrapper} direction="column">
      {ids.map((jackpotType) => (
        <JackpotItemInGame
          jackpotType={jackpotType}
          jackpotInfo={jackpots[jackpotType]}
          key={jackpotType}
          isDetailsOpen={jackpotType === openedJackpotType}
          onToggle={(isOpen) => handleItemToggle(isOpen, jackpotType)}
        />
      ))}
    </Box>
  );
};
