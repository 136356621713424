import { isEmpty } from 'ramda';
import { window } from '@namespace/helpers';

export const generateBreadCrumbsItems = (
  category,
  provider,
  casinoRoute,
  lobbyLabel = ''
) => {
  const { origin } = window.location;
  let links = [
    {
      label: lobbyLabel,
      path: `${origin}${casinoRoute}`
    }
  ];

  if (!isEmpty(category) && isEmpty(provider)) {
    const { id: categoryId, title: categoryTitle } = category;
    links = [
      ...links,
      {
        label: categoryTitle,
        path: `${origin}${casinoRoute}/${categoryId}`
      }
    ];
  }

  if (!isEmpty(provider)) {
    const { id: providerId, label: providerTitle } = provider;
    const { id: categoryId, title: categoryTitle } = category;
    links = [
      ...links,
      {
        label: `${providerTitle}, ${categoryTitle ? `${categoryTitle}` : ''}`,
        path: `${origin}${casinoRoute}/${providerId}${
          categoryId ? `/${categoryId}` : ''
        }`
      }
    ];
  }
  return links;
};
