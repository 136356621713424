import { useState, useCallback, useEffect } from 'react';
import { clearRedirectAction, useReferenceState } from '@namespace/common';
import { logAction, LOG_ACTIONS, logAmplitude } from '@namespace/analytics';
import { useConfig, useGeneralRoutes } from '@namespace/cms';
import { useHistory } from '@namespace/router';
import { registerStepsMap } from './components/StepForm';
import GuestContent from '../../common/GuestContent';

const Register = ({ id, componentsMap = {}, classNames = {} }) => {
  const { config = {} } = useConfig(id);
  const { steps = [] } = config;
  const [activeStep, setActiveStep] = useState(1);
  const [popup, setPopup] = useState({
    isShowPopup: false,
    secondClick: false
  });
  const { home } = useGeneralRoutes();
  const { push } = useHistory();

  const onClose = useCallback(() => {
    const redirectURL = clearRedirectAction();
    logAmplitude(LOG_ACTIONS.REGISTRATION_CLICK_CLOSE);

    if (steps.length === activeStep) {
      push(redirectURL || home);
    } else {
      push(home);
    }
  }, [steps, activeStep, home]);

  const onChangeStep = (step) => {
    setActiveStep(step);
  };

  const registerComponentsMap = {
    ...registerStepsMap,
    ...componentsMap
  };

  useReferenceState();

  useEffect(() => logAction(LOG_ACTIONS.REGISTRATION_PAGE_OPEN), []);

  return (
    <GuestContent
      config={config}
      dataRole="register-page"
      classNames={classNames}
      onClose={onClose}
      popup={popup}
      setPopup={setPopup}
      onChangeStep={onChangeStep}
      componentsMap={registerComponentsMap}
    />
  );
};

export default Register;
