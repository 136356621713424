import { useMemo } from 'react';

export const useGetCardContentSettings = ({
  isLoggedInUser,
  tournamentUserInfo,
  isTournamentStarted,
  isTournamentFinished,
  showTakePart
}) => {
  return useMemo(() => {
    const initialSetting = {
      isDescriptionShown: false,
      isTakePartButtonShown: false,
      isQualificationProgressBarShown: false,
      isTimerShown: false,
      isUserBlockedShown: false
    };

    if (tournamentUserInfo.blocked) {
      return {
        ...initialSetting,
        isDescriptionShown: true,
        isUserBlockedShown: true
      };
    }

    if (
      !isLoggedInUser ||
      (isLoggedInUser && !tournamentUserInfo.participant)
    ) {
      return {
        ...initialSetting,
        isDescriptionShown: true,
        isTakePartButtonShown: showTakePart && !isTournamentFinished,
        isTimerShown: true
      };
    }

    if (
      tournamentUserInfo.participant &&
      (tournamentUserInfo.qualified || !isTournamentStarted)
    ) {
      return {
        ...initialSetting,
        isDescriptionShown: true,
        isTimerShown: true
      };
    }

    if (
      tournamentUserInfo.participant &&
      !tournamentUserInfo.qualified &&
      isTournamentStarted
    ) {
      return {
        ...initialSetting,
        isQualificationProgressBarShown: true,
        isTimerShown: true
      };
    }

    return initialSetting;
  }, [
    isLoggedInUser,
    isTournamentStarted,
    isTournamentFinished,
    tournamentUserInfo?.blocked,
    tournamentUserInfo?.participant,
    tournamentUserInfo?.qualified
  ]);
};
