import { Icon } from '@namespace/icons2';
import clsx from 'clsx';
import { Button, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { PAYMENT_FORM_VIEW_TYPES } from '../../constants';
import styles from './index.module.css';

export const PaymentFormTypeRadio = ({
  translations = {},
  formView,
  setFormView
}) => {
  const { t } = useI18n();
  const {
    listButtonLabel = 'personalOffice.balance.sw.forms.formTypeSelector.cards',
    addButtonLabel = 'personalOffice.balance.sw.forms.formTypeSelector.addCard'
  } = translations;
  const { ADD, LIST } = PAYMENT_FORM_VIEW_TYPES;
  return (
    <Box className={styles.formTypeSelector} margin="s 0 m">
      <Button
        className={clsx([
          styles.formTypeSelectorButton,
          styles.leftButton,
          formView === ADD && styles.buttonColor
        ])}
        intent={`${formView === ADD ? 'quaternary' : 'primary'}`}
        onClick={() => setFormView(LIST)}
        actionType={`color${formView === LIST ? '3' : '4'}`}
        size="l"
      >
        {t(listButtonLabel)}
      </Button>
      <Button
        className={clsx([
          styles.formTypeSelectorButton,
          styles.rightButton,
          formView === LIST && styles.buttonColor
        ])}
        onClick={() => setFormView(ADD)}
        intent={`${formView === LIST ? 'quaternary' : 'primary'}`}
        actionType={`color${formView === LIST ? '4' : '3'}`}
        size="l"
        iconLeft={<Icon size="m" name="icons/general/action/create" />}
      >
        {t(addButtonLabel)}
      </Button>
    </Box>
  );
};
