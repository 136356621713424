import { useCallback, useEffect, useContext } from 'react';
import { useLanguage } from '@namespace/i18n';
import { window, document } from '@namespace/helpers';
import { BetSlipContext } from '@namespace/betslip/src/store/context';
import useWidgetResizeObserver from '../useWidgetResizeObserver';
import useListenChangeUrl from '../useListenChangeUrl';
import { CLIENT_TYPES, LISTENER_EVENTS, IS_WIDGET } from '../../constants';
import { WidgetLayoutContext } from '../../store/widgetLayout/context';
import useSetCustomCss from '../useSetCustomCss';

const useWidget = () => {
  const [setNewLanguage] = useLanguage();
  useWidgetResizeObserver(document.getElementById('root'));
  useListenChangeUrl();
  useSetCustomCss();
  const { location } = window;

  const [, { SET_BETSLIP_WIDGET_CONFIG }] = useContext(WidgetLayoutContext);
  const [, { SET_BETSLIP_OPEN }] = useContext(BetSlipContext);

  const changeLanguage = useCallback(
    async (newLanguage) => {
      await setNewLanguage(newLanguage);
    },
    [setNewLanguage]
  );

  const receiveMessage = useCallback(
    (event) => {
      if (event.data.client === CLIENT_TYPES.PARENT_WIDGET) {
        const dataEvent = event.data.event;
        const { data } = event.data;

        if (dataEvent === LISTENER_EVENTS.SET_LANG) {
          changeLanguage(data);
        } else if (dataEvent === LISTENER_EVENTS.BETSLIP_WIDGET_CONFIG) {
          SET_BETSLIP_WIDGET_CONFIG(data || {});
        } else if (dataEvent === LISTENER_EVENTS.CLICK_ON_BETSLIP_INFO_BOX) {
          SET_BETSLIP_OPEN(true);
        } else {
          console.log(event.data);
        }
      }
      return null;
    },
    [changeLanguage, location]
  );

  useEffect(() => {
    if (IS_WIDGET) {
      window.addEventListener('message', receiveMessage);
      return () => {
        window.removeEventListener('message', receiveMessage);
      };
    }
    return undefined;
  }, [receiveMessage]);
};

export default useWidget;
