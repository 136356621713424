import { pathOr, prop } from 'ramda';
import { createSelector } from 'reselect';
import { MODE } from '../../../constants';
import { useSportsbookSelector } from '../../store';

export const selectFavoriteEventsCounter = (
  serviceIds = [MODE.PRE_MATCH],
  sportId
) =>
  createSelector(
    pathOr([], ['favorites', 'events']),
    prop('events'),
    (favoriteEvents, events) =>
      favoriteEvents.filter((eventId) => {
        const event = events[eventId];

        if (!serviceIds.includes(event?.serviceId)) {
          return false;
        }

        return sportId == null || event?.sportId === sportId;
      }).length
  );

export const useFavoriteEventsCounter = (serviceIds, sportId) =>
  useSportsbookSelector(selectFavoriteEventsCounter, [serviceIds, sportId]);
