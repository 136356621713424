/**
 * @memberOf module:SetScore
 * Build eventResultTotal string for volleyball
 * @param {Object.<string, number>} scores
 * @param {ResultTypeData} byResultType
 * @param {(number|string)} resultTypeId
 */
export const buildVolleyballTotal = (scores, byResultType, resultTypeId) => {
  const lastResultTypeIdx = byResultType.findIndex(
    ({ resultTypeId: id }) => id === resultTypeId
  );

  const detailedResult = [
    `${scores.firstParticipantSet1Points}:${scores.secondParticipantSet1Points}`,
    `${scores.firstParticipantSet2Points}:${scores.secondParticipantSet2Points}`,
    `${scores.firstParticipantSet3Points}:${scores.secondParticipantSet3Points}`,
    `${scores.firstParticipantSet4Points}:${scores.secondParticipantSet4Points}`,
    `${scores.firstParticipantSet5Points}:${scores.secondParticipantSet5Points}`,
    `${scores.firstParticipantSet6Points}:${scores.secondParticipantSet6Points}`,
    `${scores.firstParticipantSet7Points}:${scores.secondParticipantSet7Points}`
  ].slice(0, lastResultTypeIdx - 1);

  let eventResultTotal = `${scores.firstParticipantTotalPoints}:${scores.secondParticipantTotalPoints}`;
  eventResultTotal += ` (${detailedResult.join(',')}) `;

  return eventResultTotal;
};
