import { postRPC } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

export const getEvents = ({
  lang,
  sportId,
  serviceId = 0,
  tournamentsIds = [],
  eventIds = [],
  tzDiff,
  date,
  timeRange,
  startDate,
  endDate,
  betType,
  limit,
  offset,
  marginGroupId,
  headMarkets = true,
  categoryIds = []
}) => {
  const request = {
    by: {
      lang,
      sport_id: sportId,
      head_markets: headMarkets,
      service_id: serviceId,
      tz_diff: tzDiff,
      date,
      time_range: timeRange,
      start_dt: startDate,
      end_dt: endDate,
      bet_type: betType,
      margin_group_id: marginGroupId
    }
  };

  if (tournamentsIds.length) {
    request.by = {
      ...request.by,
      tournament_id: {
        $in: tournamentsIds
      }
    };
  }

  if (categoryIds.length) {
    request.by = {
      ...request.by,
      category_id: {
        $in: categoryIds
      }
    };
  }

  if (!serviceId && !categoryIds.length && !tournamentsIds.length) {
    request.by = {
      ...request.by,
      limit,
      offset
    };
  }

  if (eventIds.length) {
    request.by = {
      ...request.by,
      event_id: {
        $in: eventIds
      }
    };
  }
  return postRPC('frontend/event/get', request).then(camelizeKeys);
};
