import { Box, AText } from '@alf/uikit';
import { useGetProviderJackpotCoef, useSetJackpot } from '../../../../../hooks';
import { getJackpotTimeout } from '../utils';
import { formatAmount } from '../../../../../utils';
import { JACKPOT_LEVEL_IMAGE_INFO, LEVEL_IMAGE_POSITIONS } from '../constants';
import styles from './index.module.css';
import { OdometerWrapper } from '../../JackpotInHouse/common/OdometerWrapper';

const JackpotItem = ({
  type,
  jackpotAmounts = {},
  classNames = {},
  currency,
  jackpotIdentifier
}) => {
  const jackpotCoef = useGetProviderJackpotCoef(jackpotIdentifier);
  const delay = getJackpotTimeout(type, jackpotIdentifier);
  const { wrapperClassName = '' } = classNames;
  const textRef = useSetJackpot({
    initialValue: Number(jackpotAmounts.currentWinAmount),
    formatTotal: formatAmount,
    step: 0.03 * jackpotCoef,
    delay,
    isSkipImmediateSetOnUpdate: true,
    isAddSpacesToJackpotAmount: false
  });

  const {
    position: jackpotLevelImagePosition,
    levelsImages
  } = JACKPOT_LEVEL_IMAGE_INFO[jackpotIdentifier];
  const jackpotLevelImage = levelsImages[type];

  const JackpotCounter = (
    <Box className={styles.amount}>
      <OdometerWrapper
        initialValue={Number(jackpotAmounts.currentWinAmount)}
        textRef={textRef}
      />
    </Box>
  );

  return (
    <Box className={`${styles.wrapper} ${wrapperClassName}`} align="center">
      <Box className={styles.contentWrapper} align="center" justify="center">
        {jackpotLevelImagePosition === LEVEL_IMAGE_POSITIONS.INLINE && (
          <Box
            className={`${styles.content} ${styles.inlineContent}`}
            justify="between"
            align="center"
          >
            <img
              src={jackpotLevelImage}
              alt={type}
              className={styles.inlineLevelImage}
            />
            {JackpotCounter}
          </Box>
        )}
        {jackpotLevelImagePosition === LEVEL_IMAGE_POSITIONS.UNDERLINE && (
          <Box
            className={`${styles.content} ${styles.underlineContent}`}
            align="center"
            justify="end"
            direction="column"
          >
            {JackpotCounter}
            <img
              src={jackpotLevelImage}
              alt={type}
              className={styles.underlineLevelImage}
            />
          </Box>
        )}
        {!jackpotLevelImagePosition && (
          <Box className={styles.content} align="center" justify="end">
            {JackpotCounter}
          </Box>
        )}
      </Box>
      <Box className={styles.currencyWrapper} align="center">
        <AText breed="BU" className={styles.currency}>
          {currency}
        </AText>
      </Box>
    </Box>
  );
};

export default JackpotItem;
