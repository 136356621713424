import { App as CoreApp, Page } from '@namespace/layout';
import { pageComponentsMap, FALLBACK_PAGES } from 'components/Page';
import { COLOR_SCHEME_LIGHT } from '@namespace/helpers';
import FALLBACK_SITE_SETTINGS from './siteSettings.json';
import { FALLBACK_SNIPPETS } from './HTMLSnippet/FALLBACK_SNIPPETS';
import { appProvidersMap } from './providersMap';
import './css/index.css';

const App = ({ initialPageConfig, snippets, colorScheme }) => (
  <CoreApp
    FALLBACK_SITE_SETTINGS={FALLBACK_SITE_SETTINGS}
    FALLBACK_SNIPPETS={FALLBACK_SNIPPETS}
    providersMap={appProvidersMap}
    CSS_THEME="gyzyl_light"
    DEFAULT_COLOR_SCHEME={COLOR_SCHEME_LIGHT}
    snippets={snippets}
    colorScheme={colorScheme}
  >
    <Page
      componentsMap={pageComponentsMap}
      FALLBACK_PAGES={FALLBACK_PAGES}
      initialPageConfig={initialPageConfig}
    />
  </CoreApp>
);

export default App;
