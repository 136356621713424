import { useI18n } from '@namespace/i18n';
import { Stepper } from '@namespace/common';
import { useVerifyDocuments } from '@namespace/user';
import clsx from 'clsx';
import { DOCUMENT_STATUSES } from '../../../constants';
import ProfileDocumentsFormWrapper from '../ProfileDocumentsFormWrapper';
import UploadDocumentsForm from '../UploadDocumentsForm';
import styles from '../index.module.css';

const defaultSteps = [
  {
    type: 'documentForm',
    config: {
      title: 'documentData'
    }
  },
  {
    type: 'uploadForm',
    config: {
      title: 'uploadPhotos'
    }
  }
];

const defaultComponentsMap = {
  documentForm: ProfileDocumentsFormWrapper,
  uploadForm: UploadDocumentsForm
};

const Steps = ({
  config = {},
  steps = defaultSteps,
  componentsMap = {},
  classNames
}) => {
  const { t } = useI18n();
  const { verified, status } = useVerifyDocuments();
  const mergedComponentsMap = { ...defaultComponentsMap, ...componentsMap };
  const { showStepLine = true } = config;
  const preparedSteps = steps.map((item) => {
    const { config: stepConfig = {} } = item;
    const { title } = stepConfig;

    return {
      ...item,
      config: {
        ...stepConfig,
        ...config,
        title: title ? t(`verificationWizard.step.${title}`) : ''
      }
    };
  });

  const { stepperWrapper = '' } = classNames;

  return (
    <div className={styles.stepperContainer}>
      <Stepper
        config={{
          isShowStepLine:
            showStepLine &&
            !(
              status === DOCUMENT_STATUSES.PENDING ||
              status === DOCUMENT_STATUSES.ADDITIONAL_INFORMATION ||
              verified ||
              preparedSteps.length <= 1
            )
        }}
        stepsMap={preparedSteps}
        componentsMap={mergedComponentsMap}
        stepsClassNames={{
          stepsWrapperClassName: styles.stepsWrapper,
          stepperWrapperClassName: clsx(styles.stepperWrapper, stepperWrapper)
        }}
      />
    </div>
  );
};

export default Steps;
