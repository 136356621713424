import { useMemo, forwardRef } from 'react';
import { useIsFreeToPlayUser } from '@namespace/account';
import GameSkelet from '../GameSkelet';
import Game from '../Game';
import { useGetGameURL, useGetLobbyPageUrl } from '../../hooks';
import { REAL_MODE } from '../../constants';

// used on casino pages - casino / casino live / instant games. depends on casino settings for each type
export const GameWrapperInternal = forwardRef(
  (
    {
      game,
      className,
      index,
      isDesktop,
      casinoType,
      isLaunchOnTap = false,
      ...rest
    },
    ref
  ) => {
    const getGameURL = useGetGameURL();
    const { gameCode, provider } = game;
    const lobbyPageUrl = useGetLobbyPageUrl(casinoType);
    const isFreeToPlayUser = useIsFreeToPlayUser();

    const link = useMemo(() => {
      return getGameURL({
        gameCode,
        gameProvider: provider,
        playMode: REAL_MODE,
        serviceURL: lobbyPageUrl,
        type:
          (isDesktop && isFreeToPlayUser) || isLaunchOnTap
            ? 'launch'
            : 'preview'
      });
    }, [lobbyPageUrl, game, getGameURL]);

    return game.isPlaceholder ? (
      <GameSkelet ref={ref} className={className} />
    ) : (
      <Game
        ref={ref}
        className={className}
        game={game}
        index={`regular-${index + 1}`}
        link={link}
        isDesktop={isDesktop}
        casinoType={casinoType}
        isLaunchOnTap={isLaunchOnTap}
        {...rest}
      />
    );
  }
);
