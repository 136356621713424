import { AText, InfoMessage as Info } from '@alf/uikit';

const InfoMessage = ({
  icon = 'info',
  label = '',
  classNames = {
    wrapperClassName: '',
    labelClassName: ''
  }
}) => (
  <Info icon={icon} className={classNames.wrapperClassName}>
    <AText className={classNames.labelClassName}>{label}</AText>
  </Info>
);

export default InfoMessage;
