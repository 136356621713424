import {
  COLOR_SCHEME_DARK,
  COLOR_SCHEME_LIGHT,
  COLOR_SCHEME_MEDIA_LIGHT,
  window
} from '@namespace/helpers';

const getAutoColorScheme = () => {
  const { matches } = window.matchMedia(COLOR_SCHEME_MEDIA_LIGHT);
  return matches ? COLOR_SCHEME_LIGHT : COLOR_SCHEME_DARK;
};

export default getAutoColorScheme;
