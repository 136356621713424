import { get } from 'lodash';
import useOtherSettings from '../useOtherSettings';

export const useShowStatistics = (eventStatUrl) => {
  const otherSettings = useOtherSettings();
  const sportConfig = get(otherSettings, 'sports', {});
  const { notAllowedStatistics = [], isShowStatUrl = true } = sportConfig;
  if (isShowStatUrl && eventStatUrl) {
    if (notAllowedStatistics.length) {
      return notAllowedStatistics.every((item) => !eventStatUrl.includes(item));
    }
    return true;
  }

  return false;
};

export default useShowStatistics;
