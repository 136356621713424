import { DateTime } from 'luxon';
import getDateTime from '../getDateTime';

const getHours = (date) => {
  const end = DateTime.local();
  const dateTime = getDateTime(date);
  const { hours } = end.diff(dateTime, 'hours');
  return Math.floor(hours);
};

export default getHours;
