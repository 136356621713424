import { COLOR_SCHEME_DARK } from '@namespace/helpers';
import PropTypes from 'prop-types';
import { useColorScheme, useGetThemeImages } from '@namespace/themes';
import images from './images';

const { keys } = Object;

const buildSrcPropType = (mergedImages) => {
  return keys(mergedImages).map((key) => key.replace('images/', ''));
};

const buildPropTypes = (mergedImages) => {
  return {
    name: PropTypes.oneOf(buildSrcPropType(mergedImages)).isRequired,
    alt: PropTypes.string,
    className: PropTypes.string
  };
};

const Image = ({ name, alt = '', ...rest }) => {
  const {
    colorScheme: { selectedColorMode }
  } = useColorScheme();

  const themesImages = useGetThemeImages();
  const mergedImages = { ...images, ...themesImages };

  const fullName = `images/${name}`;
  const path =
    selectedColorMode === COLOR_SCHEME_DARK
      ? mergedImages[`${fullName}_dark`] || mergedImages[fullName]
      : mergedImages[fullName] || mergedImages[`${fullName}_dark`];

  if (!path) {
    return null;
  }

  Image.propTypes = buildPropTypes(mergedImages);

  return <img src={path} key={path} alt={alt} {...rest} />;
};

export default Image;
