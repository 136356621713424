import { useCallback, useEffect, useState } from 'react';

const useCustomError = (customError) => {
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (customError) {
      setErrorMessage(customError);
    }
  }, [setErrorMessage, customError]);

  const customErrorHandler = useCallback(() => {
    if (errorMessage) {
      setErrorMessage('');
    }
  }, [errorMessage, setErrorMessage]);

  if (!customError) {
    return { errorMessage: '', customErrorHandler: () => {} };
  }

  return { errorMessage, customErrorHandler };
};

export default useCustomError;
