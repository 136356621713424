import { AText, Box } from '@alf/uikit';
import { Flag } from '@namespace/icons2';
import HeadGroups from '../../../../HeadGroups';
import { useEventFields } from '../../../../../store';
import styles from './index.module.css';

const EventTitle = ({
  isShowFlag,
  activeHeadGroups,
  isDisplayHeadMarkets,
  index,
  eventId
}) => {
  const { categoryName, tournamentName, countryId } = useEventFields({
    eventId,
    fields: ['categoryName', 'tournamentName', 'countryId']
  });

  return (
    <Box className={styles.accordionHeader} align="center">
      {isShowFlag && (
        <Flag className={styles.flag} flagId={countryId} size="m" />
      )}
      <Box className={styles.sportName}>
        <AText
          data-role={`events-container-title-${index}`}
          overflow="ellipsis"
          transform="uppercase"
          color="line_subheader_txt"
          size="s"
          breed="RU"
          className={styles.sportTitle}
        >
          {`${categoryName} | ${tournamentName}`}
        </AText>
      </Box>
      {isDisplayHeadMarkets && <HeadGroups items={activeHeadGroups} />}
    </Box>
  );
};
export default EventTitle;
