export const constructMicrodata = (items) => ({
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  'itemListElement': items.map(({ path, label }, idx) => ({
    '@type': 'ListItem',
    'position': idx + 1,
    'name': label,
    'item': path
  }))
});
