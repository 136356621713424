import { FORM_COMPONENTS_TYPES } from '../../constants';

const addGlobalErrorComponent = (components) => {
  const newComponents = [...components];
  const submitButtonIndex = components.findIndex(
    (item) => item.type === FORM_COMPONENTS_TYPES.SUBMIT_BUTTON
  );
  if (submitButtonIndex !== -1) {
    newComponents.splice(submitButtonIndex, 0, {
      type: FORM_COMPONENTS_TYPES.GLOBAL_ERROR
    });
  }
  return newComponents;
};

export default addGlobalErrorComponent;
