const getFormError = (fieldName, errors, t, errorType = '') => {
  const fieldErrors = errors[fieldName];
  if (!fieldErrors) {
    return null;
  }

  let message = null;
  if (errorType) {
    const error = fieldErrors.find(({ type }) => type === errorType);
    if (error) {
      message = error.message;
    }
  } else if (Array.isArray(fieldErrors)) {
    message = fieldErrors.map((error) => error.message);
  } else if (isMessageTemplate(fieldErrors.message)) {
    message = parseMessageTemplate(fieldErrors.message);
  } else {
    message = fieldErrors.message;
  }

  if (Array.isArray(message)) {
    return t(message[0], message.slice(1));
  }

  return t(message);
};

export default getFormError;

export function isMessageTemplate(value) {
  return /\{.*\}/.test(value);
}

function parseMessageTemplate(value) {
  return value.split('{').map((i) => i.replace(/[\{\}\s]*/g, ''));
}
