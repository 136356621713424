import { path, prop } from 'ramda';
import { shallow } from 'zustand/shallow';
import { createSelector } from 'reselect';
import { useSportsbookSelector } from '../../store';
import { selectEvents } from '../../normalizers';

export const selectEventsByLineKey = (lineKey, onlyIds) =>
  createSelector(
    prop('events'),
    path(['lines', lineKey]),
    path(['sortByFilter', lineKey]),
    (events, line, sortType) => {
      const preparedEvents = selectEvents(events, line, sortType);
      if (onlyIds) {
        return preparedEvents.map(({ eventId }) => eventId);
      }
      return preparedEvents;
    }
  );

export const useEvents = ({ lineKey, onlyIds }) =>
  useSportsbookSelector(selectEventsByLineKey, [lineKey, onlyIds], shallow);
