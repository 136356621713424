import { AText, Box, Button } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { useEndTourAction, useTrackInterruptAction } from '../../hooks';
import styles from './index.module.css';

export const InterruptTooltip = () => {
  const { t } = useI18n();

  useTrackInterruptAction();

  return (
    <Box
      data-role="onboarding-interrupt-tooltip"
      direction="column"
      gap="s"
      padding="m"
      margin="m"
      className={styles.root}
    >
      <Box
        data-role="onboarding-interrupt-tooltip-title"
        align="center"
        justify="center"
        className={styles.title}
      >
        <AText breed="B" size="xl" color="txt_color_3">
          {t('onboarding.interrupt.title')}
        </AText>
      </Box>
      <div
        data-role="onboarding-interrupt-tooltip-content"
        className={styles.contentWrapper}
      >
        {t('onboarding.interrupt.content')}
      </div>
      <Box justify="center" className={styles.buttons}>
        <Button
          dataRole="onboarding-interrupt-tooltip-btn"
          intent="secondary"
          onClick={useEndTourAction()}
        >
          {t('onboarding.tooltip.button.interrupt')}
        </Button>
      </Box>
    </Box>
  );
};
