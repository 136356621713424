import { useMemo, useContext, useCallback } from 'react';
import { DeviceContext } from '@namespace/device';
import {
  SubMenu as CommonSubMenu,
  UserSettings,
  useUserSettings
} from '@namespace/common';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { useGeneralRoutes, useConfig } from '@namespace/cms';
import { useUserSegmentsIncludes } from '@namespace/segments';
import { getRootPath } from '@namespace/helpers';
import { useI18n, useLocation } from '@namespace/i18n';
import { VirtualSportsContext } from '../../store/context';
import getVSPath from '../../utils/getVSPath';

const VirtualSubMenu = ({ id }) => {
  const { t } = useI18n();
  const { config } = useConfig(id);
  const { mainMenu = [], secondMenu = [] } = config;
  const [{ virtualSports, feed }] = useContext(VirtualSportsContext);
  const { virtualSportsOneSport, virtualSportsFrame } = useGeneralRoutes();
  const { pathname } = useLocation();
  const { isDesktop } = useContext(DeviceContext);
  const {
    showTimeZoneSelector,
    isShowLanguageUserSettings,
    showSettings
  } = useUserSettings();

  const { providers } = feed;

  const getMenuItem = useCallback(
    ({ providerId, categoryId, link }) => ({
      title: t(`header.submenu.${providerId}`),
      id: categoryId,
      link,
      onClick: () => {
        logAction(LOG_ACTIONS.VSPORT_TOP_CATEGORY_CLICK, {
          CategoryName: t(`header.submenu.${providerId}`)
        });
      }
    }),
    [t]
  );

  const mainMenuItems = useMemo(() => {
    const feedMenuItems = providers.ids.map((providerId) => {
      const { categoryId, isFrame, games: provideGames } = providers.data?.[
        providerId
      ];
      const link = `${
        isFrame ? virtualSportsFrame : virtualSportsOneSport
      }/${providerId}/${provideGames[0]}/`;

      return getMenuItem({ providerId, link, categoryId });
    });
    const outerVirtualSportsMenuItems = Object.values(virtualSports).map(
      (item) => {
        const { categoryId, provider, isFrame } = Object.values(item)[0] || {};

        if (!provider) return null;

        const link = `${
          isFrame ? virtualSportsFrame : virtualSportsOneSport
        }/${provider}/${categoryId}/`;

        return getMenuItem({ providerId: provider, link, categoryId });
      }
    );

    const menuItemsProviders = [
      ...feedMenuItems,
      ...outerVirtualSportsMenuItems
    ].filter(Boolean);

    const menuItemsConfig = mainMenu.map(({ title, ...rest }) => ({
      title: t(`header.submenu.${title}`),
      ...rest
    }));

    return menuItemsConfig.concat(menuItemsProviders);
  }, [t, virtualSports, providers, getMenuItem]);

  const filterSegments = useUserSegmentsIncludes();

  const mainSegmentedItems = useMemo(
    () => mainMenuItems.filter((item) => filterSegments(item.userSegments)),
    [mainMenuItems, filterSegments]
  );

  const secondSegmentedItems = useMemo(
    () =>
      secondMenu
        .map(({ title, ...rest }) => ({
          title: t(`header.submenu.${title}`),
          ...rest
        }))
        .filter((item) => filterSegments(item.userSegments)),
    [t, secondMenu, filterSegments]
  );

  const isActive = useCallback(
    (link) => {
      const pathWithProvider = getVSPath(pathname, false);

      if (
        pathWithProvider === virtualSportsOneSport ||
        pathWithProvider === virtualSportsFrame
      ) {
        return getVSPath(pathname, true) === getVSPath(link, true);
      }

      return getRootPath(pathname) === link;
    },
    [pathname, virtualSportsOneSport, virtualSportsFrame]
  );

  return (
    <CommonSubMenu
      mainMenuItems={mainSegmentedItems}
      SecondaryItems={
        isDesktop && (
          <UserSettings
            items={secondSegmentedItems}
            showTimeZoneSelector={showTimeZoneSelector}
            showLineSettings={false}
            isShowLanguageUserSettings={isShowLanguageUserSettings}
            showSettings={showSettings}
          />
        )
      }
      isActive={isActive}
    />
  );
};

export default VirtualSubMenu;
