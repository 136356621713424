import { useContext } from 'react';
import { Box } from '@alf/uikit';
import { PageContext } from '@namespace/cms';
import { PrivateContentLoader } from '@namespace/user';
import { useGetThemeConfig } from '@namespace/themes';
import { MultiColumnContent } from '../common/MultiColumnContent';
import styles from './index.module.css';

export const PersonalOfficeColumns = () => {
  const { page } = useContext(PageContext);
  const { layout = {} } = page;
  const { columns = [] } = layout;
  const { gap = '0' } = useGetThemeConfig('PersonalOfficeColumns');

  return (
    <PrivateContentLoader>
      <Box gap={gap} className={styles.personalOffice}>
        <MultiColumnContent columns={columns} columnClassName={styles.column} />
      </Box>
    </PrivateContentLoader>
  );
};
