import { checkPhone } from '../../api';
import createAsyncValidator from '../../core/createAsyncValidator';

const phoneWithIsoCodeValidation = (mapper = (v) => v?.number) =>
  createAsyncValidator((value) => {
    const number = mapper(value);

    if (!number) {
      return {
        error: true,
        errorCode: 1501
      };
    }
    return checkPhone(number);
  });

export default phoneWithIsoCodeValidation;
