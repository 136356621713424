/* eslint-disable no-console */

import { omit } from 'ramda';
import ReconnectingWebSocket from 'reconnecting-websocket';

import { isFunction } from 'lodash';

const maybeArray = (a) => (Array.isArray(a) ? a : [a]);

class SocketClient {
  constructor({
    url,
    connectionHandler = () => {},
    messages = [],
    socketParams = {},
    maxReconnectionAttempts = 10
  }) {
    this.connection = new ReconnectingWebSocket(url, null, {
      reconnectInterval: 5000,
      maxRetries: maxReconnectionAttempts,
      ...socketParams
    });

    this.initMessages = messages;
    this.connectionHandler = connectionHandler;

    if (this.connection) {
      this.connection.onclose = this.onClose;
      this.connection.onerror = this.onError;
      this.connection.onopen = this.onOpen;
      this.connection.onmessage = this.onMessage;
    }

    this.messageSeq = 0;
  }

  subscribers = [];

  sentMessages = {};

  beforeConnectionOpenMessages = [];

  isOpen = false;

  isFirstOpen = true;

  setSubscribers = (subscribers) => {
    this.subscribers = subscribers;
  };

  setSentMessages(sentMessages) {
    this.sentMessages = sentMessages;
  }

  setBeforeConnectionOpenMessages(messages) {
    this.beforeConnectionOpenMessages = messages;
  }

  defaultConnectionHandler = (type, ...rest) => {
    if (this.connection && process.env.NODE_ENV !== 'test') {
      console.log(`connection ${type}`, this.connection.url);
      this.connectionHandler(this.connection.readyState, type, ...rest);
    }
  };

  onOpen = () => {
    this.isOpen = true;
    this.defaultConnectionHandler('open');
    [
      ...this.beforeConnectionOpenMessages,
      ...this.initMessages,
      ...this.subscribers.filter((s) => s.message).map((s) => s.message)
    ].forEach(this.send);
    if (this.isFirstOpen) {
      this.isFirstOpen = false;
    }
  };

  onClose = (event) => {
    this.isOpen = false;
    console.error('Chat socket disconnected:', event);
    this.defaultConnectionHandler('close', event);
  };

  onError = (error) => {
    console.error('Chat socket error:', error);
    this.defaultConnectionHandler('error', error);
  };

  onMessage = (message) => {
    try {
      const { data } = message;
      const parsedData = JSON.parse(data);
      const { reqId, status = true } = parsedData;
      const sentMessage = this.sentMessages[reqId];

      if (sentMessage) {
        if (status) {
          sentMessage.resolve(parsedData);
        } else {
          sentMessage.reject(parsedData);
        }
        this.setSentMessages(omit([reqId], this.sentMessages));
      }

      for (const mes of maybeArray(parsedData)) {
        for (const { types, callback } of this.subscribers) {
          if (
            types.includes(mes.reqId) ||
            types.includes(mes.event) ||
            types === 'all'
          ) {
            callback(mes);
          }
        }
        if (this.beforeConnectionOpenMessages.length) {
          this.setBeforeConnectionOpenMessages(
            this.beforeConnectionOpenMessages.filter(
              (m) => m.reqId !== mes.reqId
            )
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  close = () => {
    this.connection.close();
  };

  send = (params) =>
    new Promise((resolve, reject) => {
      try {
        let message = isFunction(params) ? params() : params;
        message = {
          event: message.event,
          seq: this.messageSeq,
          timestamp: new Date().getTime(),
          ...message
        };
        ++this.messageSeq;

        if (this.connection && this.isOpen) {
          this.connection.send(JSON.stringify(message));
        } else if (this.isFirstOpen) {
          this.setBeforeConnectionOpenMessages([
            ...this.beforeConnectionOpenMessages,
            message
          ]);
        }

        if (!this.sentMessages[message.reqId]) {
          this.setSentMessages({
            ...this.sentMessages,
            [message.reqId]: {
              resolve,
              reject
            }
          });
        }
      } catch (e) {
        console.warn(e);
      }
    });

  rawSend = (data) => {
    this.connection.send(data);
  };

  subscribe = (newSubscriber = {}) => {
    const { name, types, callback, message } = newSubscriber;
    if (name && types && isFunction(callback)) {
      const subscribersWithoutCurrent = this.subscribers.filter(
        (s) => s.name !== name
      );
      this.setSubscribers([
        ...subscribersWithoutCurrent,
        {
          name,
          types,
          callback,
          message
        }
      ]);
    }

    if (message) {
      this.send(message);
    }
  };

  unsubscribe = (name) => {
    this.setSubscribers(this.subscribers.filter((s) => s.name !== name));
  };
}

export default SocketClient;
