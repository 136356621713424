import { useMemo } from 'react';
import { uniq } from 'ramda';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { useGeneralRoutes } from '@namespace/cms';
import { isEmpty } from 'lodash';
import { EmptyState } from '@alf/uikit';
import { MODE } from '../../constants';
import { BasePreMatchEvents } from '../../common';
import {
  useCategory,
  useSportBySportSlug,
  useSportsBookDataState,
  useTournament,
  useTournamentsIdsByCategoriesIds
} from '../../store';

// Add this wrapper with key to force component unmount on sport change
// to restart all starting soon logic
// It's impossible to make another way
const PreMatchFilteredEvents = ({
  id,
  lineKey,
  sportName,
  categoryIds,
  tournamentIds = [],
  configTournamentIds = []
}) => {
  const { home } = useGeneralRoutes();
  const { t } = useI18n();

  const tournament = useTournament(tournamentIds[0], MODE.PRE_MATCH);
  const categoryId = useMemo(() => tournament?.categoryId, [tournament]);
  const backButtonSubTitle = useMemo(() => {
    if (tournamentIds.length === 1 && tournament) {
      const { tournamentName } = tournament;
      return tournamentName;
    }

    if (tournamentIds.length > 1) {
      return t('sportsBook.tournamentsMenu.all');
    }

    return '';
  }, [t, tournament, tournamentIds.length]);

  const { isSportsBookDataLoaded } = useSportsBookDataState();
  const activeSport = useSportBySportSlug(sportName, MODE.PRE_MATCH) || {};

  const categoriesTournamentsIds = useTournamentsIdsByCategoriesIds(
    categoryIds,
    MODE.PRE_MATCH
  );
  const shownTournaments = useMemo(
    () =>
      uniq([
        ...categoriesTournamentsIds,
        ...tournamentIds,
        ...configTournamentIds
      ]),
    [tournamentIds, categoriesTournamentsIds, configTournamentIds]
  );

  const category = useCategory(categoryId, MODE.PRE_MATCH);
  const categoryName = useMemo(() => category.categoryName || [], [category]);

  if (isSportsBookDataLoaded && !activeSport) {
    return (
      <EmptyState
        Link={LocalizedLink}
        linkPath={home}
        linkText={t('home')}
        text={t('web.sports.noSport')}
      />
    );
  }

  return (
    <BasePreMatchEvents
      id={id}
      lineKey={lineKey}
      activeSport={activeSport}
      tournamentsIds={!isEmpty(shownTournaments) ? shownTournaments : []}
      backButtonSubTitle={backButtonSubTitle}
      key={tournamentIds.join(' ')}
      categoryIdName={categoryName}
    />
  );
};

export default PreMatchFilteredEvents;
