import { window } from '../../constants';

const getRootHostName = () => {
  const SUBDOMAINS = ['prev', 'new'];

  let { hostname } = window.location;

  hostname = hostname.replace('www.', '');

  const hostParts = hostname.split('.');

  if (SUBDOMAINS.includes(hostParts[0])) {
    hostParts.shift();
    hostname = hostParts.join('.');
  }

  return hostname;
};

export default getRootHostName;
