import { document } from '@namespace/helpers';
import { LOG_ACTIONS } from '../../constants';

export const logPostConversionTracking = (
  cat = 'login0',
  postConversionType
) => {
  if (postConversionType) {
    const axel = Math.random();
    const a = axel * 10000000000000;

    const src = `https://12545469.fls.doubleclick.net/activityi;src=12545469;type=${postConversionType};cat=${cat};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=;gdpr_consent=;${
      cat === LOG_ACTIONS.POST_DEPOSIT ? `ord=${a}` : `ord=1;num=${a}`
    }?`;
    const iframe = document.createElement('iframe');

    iframe.setAttribute('src', src);
    iframe.setAttribute('width', 1);
    iframe.setAttribute('height', 1);
    iframe.setAttribute('frameborder', 0);
    iframe.style.display = 'none';

    document.body.appendChild(iframe);
  }
};
