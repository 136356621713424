import { SportIcon } from '@namespace/icons2';
import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import Place from './Place';
import Winner from './Winner';
import EventState from '../EventState';
import {
  SCOPE_IDS,
  SPORT_IDS,
  SUMMARIES_SCOPE_DATA
} from '../../../../constants';
import { useEventFields, useIsSummariesScoreboard } from '../../../../store';
import styles from './index.module.css';

const Summaries = ({ eventId }) => {
  const { t } = useI18n();
  const { eventName, eventResultName } = useEventFields({
    eventId,
    fields: ['eventName', 'eventResultName']
  });
  const {
    isWinnerScoreboard,
    isPlacesScoreboard,
    isSummariesScoreboard
  } = useIsSummariesScoreboard(eventId);

  return (
    <div className={styles.container}>
      <EventState eventId={eventId} />
      <Box justify="center" direction="column" className={styles.info}>
        <div className={styles.sportIcon}>
          <SportIcon
            sportId={SPORT_IDS.CYBERSPORT_SUMMARIES}
            size="3xl"
            color="line_bg_3"
          />
        </div>
        <AText
          size="m"
          breed="B"
          color="line_txt_1"
          className={styles.eventName}
        >
          {eventName}
        </AText>
        {isPlacesScoreboard && <Place eventId={eventId} />}
      </Box>
      {isWinnerScoreboard && <Winner eventId={eventId} />}
      {(!isSummariesScoreboard || isPlacesScoreboard) && (
        <Box justify="center" className={styles.summariesInfo}>
          <AText color="line_txt_1" breed="B" size="s">
            {`${eventResultName}${
              isPlacesScoreboard
                ? `. ${t(SUMMARIES_SCOPE_DATA[SCOPE_IDS.PLACE])}`
                : ''
            }`}
          </AText>
        </Box>
      )}
    </div>
  );
};

export default Summaries;
