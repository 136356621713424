import { useEffect, useContext, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { UserContext } from '@namespace/user/src/context';
import { createAsyncValidator, checkFIO } from '@alf/validators';
import InputField from '../../common/InputField';

const NameFieldInput = ({
  name,
  countryFieldName = 'country',
  rules = {},
  ...props
}) => {
  const { watch, trigger, getValues } = useFormContext();
  const country = watch(countryFieldName);
  const [user] = useContext(UserContext);
  const { countryId } = user;

  const validate = useCallback(async () => {
    await trigger([name]);
  }, [trigger, name]);

  useEffect(() => {
    const value = getValues()[name];
    if (value) {
      validate();
    }
  }, [country, getValues, name, validate]);

  return (
    <InputField
      name={name}
      rules={{
        ...rules,
        ...createAsyncValidator((value) => checkFIO(name, value, countryId))
      }}
      {...props}
    />
  );
};

export default NameFieldInput;
