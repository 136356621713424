import { get, isEmpty } from 'lodash';
import { FORM_FACTORS_MAP } from '@namespace/device';
import { getGameGroup } from '../../utils';

const normalizeCategoriesFromCms = (categories, content, normalizedGames) =>
  categories.reduce(
    (acc, categoryId) => {
      const item = content[categoryId];

      if (isEmpty(item) || item.games.length === 0) {
        return acc;
      }

      const filteredGames = item.games.filter(
        (gameId) => normalizedGames.data[gameId]
      );

      if (filteredGames.length === 0) {
        return acc;
      }

      const name = item.name.replace('translation.', '');

      return {
        ...acc,
        data: Object.assign(acc.data, {
          [name]: {
            id: name,
            title:
              item.settings?.nameTranslationKey ||
              `content.games.category.${name}`,
            ...item.settings,
            games: filteredGames,
            instantGames: item.instantGames
          }
        }),
        ids: [...acc.ids, name],
        isLoaded: true
      };
    },
    { data: {}, ids: [], isLoaded: true }
  );

export const normalizeGameFromCms = (game, provider) => {
  const desktopImage = game.desktop?.gameImage
    ? {
        [FORM_FACTORS_MAP.DESKTOP]: game.desktop?.gameImage,
        [`${FORM_FACTORS_MAP.DESKTOP}Placeholder`]: game.desktop
          ?.placeholderImage
      }
    : {};
  const tabletImage = game.mobile?.gameImage
    ? {
        [FORM_FACTORS_MAP.TABLET]: game.mobile?.gameImage,
        [`${FORM_FACTORS_MAP.TABLET}Placeholder`]: game.mobile?.placeholderImage
      }
    : {};
  const mobileImage = game.mobile?.gameImage
    ? {
        [FORM_FACTORS_MAP.MOBILE]: game.mobile?.gameImage,
        [`${FORM_FACTORS_MAP.MOBILE}Placeholder`]: game.mobile?.placeholderImage
      }
    : {};
  const cardImage = Object.assign(desktopImage, tabletImage, mobileImage);

  return {
    ...game,
    name: game.gameName,
    cardImage,
    hasDemoSupport: game.supportDemo,
    provider: game.providerId,
    gameCode: game.gameId,
    group: getGameGroup(game.gameTypes),
    providerName: provider?.providerName
  };
};

const normalizeGamesFromCms = ({ games, providers, isDesktop }) =>
  Object.values(games).reduce(
    (acc, game) => {
      if (
        (isDesktop && game.availableOn.includes('desktop')) ||
        (!isDesktop && game.availableOn.includes('mobile'))
      ) {
        return {
          data: Object.assign(acc.data, {
            [game.gameId]: normalizeGameFromCms(
              game,
              providers[game.providerId]
            )
          }),
          ids: [...acc.ids, game.gameId],
          launchIdToGameId: Object.assign(acc.launchIdToGameId, {
            [game.launchGameId]: game.gameId
          })
        };
      }
      return acc;
    },
    { data: {}, ids: [], launchIdToGameId: {} }
  );

export const formatProvider = (provider) => ({
  ...provider,
  id: provider.providerId, // TODO: just use providerId
  label: provider.providerName // TODO: just use providerName
});

const normalizeProvidersFromCms = (
  feedProvidersContentIds,
  content,
  providers,
  excludedProviders,
  normalizedGames
) => {
  const formattedProviders = Object.keys(providers).reduce((acc, key) => {
    acc[key] = formatProvider(providers[key]);

    return acc;
  }, {});

  const {
    feedProvidersIds,
    feedProvidersGames
  } = feedProvidersContentIds.reduce(
    (acc, key) => {
      const item = content[key];
      const provider = formattedProviders[item.name] || {};

      if (
        excludedProviders.includes(provider.providerId) ||
        isEmpty(provider) ||
        item.games.length === 0
      ) {
        return acc;
      }

      const filteredGames = item.games.filter(
        (gameId) => normalizedGames.data[gameId]
      );

      if (filteredGames.length === 0) {
        return acc;
      }

      return {
        feedProvidersGames: Object.assign(acc.feedProvidersGames, {
          [provider.id]: item.games
        }),
        feedProvidersIds: [...acc.feedProvidersIds, provider.id]
      };
    },
    { feedProvidersGames: {}, feedProvidersIds: [] }
  );

  return {
    data: formattedProviders,
    feedProvidersGames,
    feedProvidersIds,
    isLoaded: true
  };
};

export const parseGamesListFromCms = (
  data = [],
  excludedProviders = [],
  feedName,
  isDesktop
) => {
  const findedGamesFeed = data?.feeds.find(
    (feed) => feed.feedName === feedName
  );

  const gamesFeed = findedGamesFeed || {
    ...(get(data, 'feeds[0]') || {}),
    categories: get(data, 'feeds[0].categories') || [],
    providers: get(data, 'feeds[0].providers') || [],
    content: get(data, 'feeds[0].content') || {}
  };
  const normalizedGames = normalizeGamesFromCms({
    games: data.games,
    providers: data.providers,
    isDesktop
  });

  return {
    feedName: gamesFeed.feedName || feedName,
    categories: normalizeCategoriesFromCms(
      gamesFeed.categories,
      gamesFeed.content,
      normalizedGames
    ),
    providers: normalizeProvidersFromCms(
      gamesFeed.providers,
      gamesFeed.content,
      data.providers,
      excludedProviders,
      normalizedGames
    ),
    games: normalizedGames,
    isLoading: false
  };
};

export const parseFavorites = (gamesList) => (gamesList || []).map((i) => i.id);

export const mapServiceIdsToProviderIds = (providers) => {
  const serviceIdToProviderId = {};

  for (const provider of Object.values(providers)) {
    if (provider.serviceId) {
      serviceIdToProviderId[provider.serviceId] = provider.providerId;
    }
  }

  return serviceIdToProviderId;
};

const normalizeLiveRtpSection = (section) => {
  return section.reduce(
    (acc, game) => {
      const { gameId, rtp, arrowUp } = game;

      return {
        data: Object.assign(acc.data, {
          [gameId]: { rtp, arrowUp }
        }),
        ids: [...acc.ids, gameId]
      };
    },
    { ids: [], data: {} }
  );
};
export const normalizeLiveRtpGames = (games) => {
  const { mixed = [], hot = [], cold = [] } = games;

  const mixedSection = normalizeLiveRtpSection(mixed);
  const hotSection = normalizeLiveRtpSection(hot);
  const coldSection = normalizeLiveRtpSection(cold);

  return {
    hot: hotSection.ids,
    cold: coldSection.ids,
    mixed: mixedSection.ids,
    data: {
      ...mixedSection.data,
      ...hotSection.data,
      ...coldSection.data
    }
  };
};
