import { PAGINATION_TYPE } from '../../constants';
import InfiniteLoader from './InfiniteLoader';
import ButtonLoader from './ButtonLoader';

const componentsMap = {
  [PAGINATION_TYPE.INFINITE]: InfiniteLoader,
  [PAGINATION_TYPE.SHOW_MORE]: ButtonLoader
};

const Pagination = ({ paginationType, ...rest }) =>
  componentsMap[paginationType] ? componentsMap[paginationType](rest) : null;

export default Pagination;
