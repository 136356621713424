export const types = {
  ADD_EXPRESS: 'ADD_EXPRESS',
  BETS_LIMIT: 'BETS_LIMIT',
  FETCH_EXPRESS_DAY: 'FETCH_EXPRESS_DAY',
  SET_EXPRESS_DAY: 'SET_EXPRESS_DAY',
  FETCH_BETS: 'FETCH_BETS',
  FETCH_CASINO_BETS: 'FETCH_CASINO_BETS',
  FETCH_BETS_CASINO_HISTORY: 'FETCH_BETS_CASINO_HISTORY',
  FETCH_CASINO_SERVICES: 'FETCH_CASINO_SERVICES',
  FETCH_BET_DETAILS: 'FETCH_BET_DETAILS',
  SET_BETS: 'SET_BETS',
  SET_CASINO_BETS: 'SET_CASINO_BETS',
  SET_CASINO_BETS_HISTORY: 'SET_CASINO_BETS_HISTORY',
  SET_CASINO_SERVICES: 'SET_CASINO_SERVICES',
  SET_BET_DETAILS: 'SET_BET_DETAILS',
  SET_BET_DETAILS_MULTI_SYSTEM: 'SET_BET_DETAILS_MULTI_SYSTEM',
  SET_BETS_FETCHING: 'SET_BETS_FETCHING',
  SET_CASINO_GAME_ROUNDS: 'SET_CASINO_GAME_ROUNDS',
  GET_CASINO_GAME_ROUNDS: 'GET_CASINO_GAME_ROUNDS',
  SET_FILTER_DATA: 'SET_FILTER_DATA',
  FETCH_FILTER_DATA: 'FETCH_FILTER_DATA',
  UPDATE_BETS_ITEM: 'UPDATE_BETS_ITEM'
};
