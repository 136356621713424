import { ROUND_16_BRACKET_MAP, TOP_OF_THIRD_BRACKET } from '../../constants';

const formatRound16BracketFromThirdTeams = (bracket, teams, isTeamsReady) => {
  const thirdTeamsOrder =
    TOP_OF_THIRD_BRACKET[
      teams
        .map((team) => team.groupId)
        .sort()
        .join('')
    ];
  return ROUND_16_BRACKET_MAP.map((match, bracketIndex) =>
    match.map((team, teamIndex) => {
      if (team.thirdRound) {
        return isTeamsReady
          ? teams.find(
              (thirdTeam) =>
                thirdTeam.groupId === thirdTeamsOrder[team.index - 1]
            )?.id
          : '';
      }
      return bracket[bracketIndex].teams[teamIndex];
    })
  );
};

export default formatRound16BracketFromThirdTeams;
