import { camelizeKeys } from 'humps';
import { bettorrentPost } from '@namespace/helpers';

export const getCustomFilter = async () =>
  bettorrentPost({
    url: '/service/pds/custom_filter/get',
    mode: 'same-origin',
    body: {}
  }).then(({ response = [], status }) =>
    status === 'ok'
      ? Promise.resolve(response.map((item) => camelizeKeys(item)))
      : Promise.reject(status)
  );
