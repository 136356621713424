import { Controller } from 'react-hook-form';

import Control from '../Control';

const InputFieldControl = ({
  disableAutocompleteFieldsAbove,
  styles,
  name,
  control,
  rules,
  render,
  key,
  classNames,
  error,
  hint,
  withTooltip,
  onClick,
  id,
  size,
  label,
  dataRole,
  postInputLabel
}) => (
  <Control
    key={key}
    classNames={classNames}
    error={error}
    hint={hint}
    withTooltip={withTooltip}
    onClick={onClick}
    id={id}
    size={size}
    label={label}
    postInputLabel={postInputLabel}
    dataRole={dataRole}
  >
    {disableAutocompleteFieldsAbove && (
      <>
        <input type="text" className={styles.hideInput} />
      </>
    )}
    <Controller name={name} control={control} rules={rules} render={render} />
  </Control>
);

export default InputFieldControl;
