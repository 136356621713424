import { Accordion } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { useOtherSettings } from '@namespace/cms';
import { JackpotItemInGameShortInfo } from '../JackpotItemInGameShortInfo';
import { JackpotItemInGameDetails } from '../JackpotItemInGameDetails';
import { useGetInHouseJpGameLaunchHandler } from '../../useGetInHouseJpGameLaunchHandler';

import styles from './index.module.css';

export const JackpotItemInGame = ({
  jackpotType,
  jackpotInfo,
  isDetailsOpen,
  onToggle
}) => {
  const { isShowInHouseJackpotGamesNameAsLink = false } = useOtherSettings();
  const onLaunchGame = useGetInHouseJpGameLaunchHandler({
    isLaunchFromGamePage: true
  });

  return (
    <Accordion
      title={
        <JackpotItemInGameShortInfo
          jackpotType={jackpotType}
          jackpotInfo={jackpotInfo}
        />
      }
      icon={
        <Icon
          color="txt_color_4"
          size="s"
          name={`icons/general/nav/${isDetailsOpen ? 'collapse' : 'expand'}`}
        />
      }
      isOpen={isDetailsOpen}
      onToggle={onToggle}
      classNames={{
        headerClassName: styles.accordionHeader,
        containerClassName: `${styles.accordionContainer} ${
          styles[`accordionContainer-${jackpotType}`]
        }`,
        iconWrapperClassName: styles.arrowIcon
      }}
    >
      <JackpotItemInGameDetails
        jackpotType={jackpotType}
        jackpotInfo={jackpotInfo}
        isShowInHouseJackpotGamesNameAsLink={
          isShowInHouseJackpotGamesNameAsLink
        }
        onLaunchGame={onLaunchGame}
      />
    </Accordion>
  );
};
