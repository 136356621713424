import { memo, useState } from 'react';
import { Icon } from '@namespace/icons2';
import { HTMLSnippet } from '@namespace/snippets';
import { useI18n } from '@namespace/i18n';
import { AText, Box } from '@alf/uikit';
import styles from './index.module.css';

const PredictionsAccordion = ({
  snippetId = '',
  theme = 'gray',
  title = '',
  step = ''
}) => {
  const [isShowText, setIsShowText] = useState(false);
  const { t } = useI18n();

  return (
    <Box
      align="center"
      direction="column"
      className={`${styles.accordionWrapper} ${styles[theme]} ${styles[step]}`}
    >
      <Box
        align="center"
        className={`${styles.accordionTitle}`}
        onClick={() => setIsShowText(!isShowText)}
      >
        <AText
          breed="BU"
          size="xl"
          color={theme === 'blue' ? 'line_txt_2' : 'line_txt_1'}
        >
          {t(`predictionsBlock.accordion.text${title}`)}
        </AText>
        <Icon
          name={`icons/general/nav/arrow-${isShowText ? 'up' : 'down'}`}
          color={theme === 'blue' ? 'line_txt_2' : 'line_txt_1'}
          size="s"
        />
      </Box>
      {isShowText && (
        <Box align="center" justify="center" className={styles.accordionText}>
          <HTMLSnippet snippetId={snippetId} />
        </Box>
      )}
    </Box>
  );
};

export default memo(PredictionsAccordion);
