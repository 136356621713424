import { useEffect } from 'react';
import { window, document } from '@namespace/helpers';

const createFCStyle = () => {
  const style = document.createElement('style');

  // In order to have a widget element collapsed
  style.textContent = `
    #fc_frame {
      min-height: 0 !important;
      min-width: 0 !important;
    }  
  `;

  return style;
};

const createFCSourceScript = ({ onLoad, jsSDKSrc }) => {
  const script = document.createElement('script');

  script.defer = true;
  script.src = jsSDKSrc;
  script.onload = onLoad;

  return script;
};

window.fcWidgetMessengerConfig = {
  config: {
    headerProperty: {
      hideChatButton: true
    }
  }
};

const useFCLoader = ({ onComplete, token, host, jsSDKSrc, fullscreen }) => {
  useEffect(() => {
    window.fcSettings = {
      onInit() {
        window.fcWidgetMessengerConfig = {
          // TODO: put "restoreId" here
          host,
          token,
          config: {
            fullscreen,
            headerProperty: {
              hideChatButton: true
            }
          }
        };

        onComplete();
      }
    };

    const loadedSdk = createFCSourceScript({
      jsSDKSrc,
      onLoad: function init() {
        if (!window.fcWidget) {
          setTimeout(init);
        } else {
          window.fcWidget.init({ token, host });
        }
      }
    });

    document.head.appendChild(createFCStyle());
    document.body.append(loadedSdk);
    // execute only once on mount
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useFCLoader;
