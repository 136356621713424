import { camelizeKeys } from 'humps';

const getBetDetails = ({ betId, isMultiSystem = false, socketSend }) =>
  socketSend({
    cmd: 'bets_history/cardshistory_by_id',
    data: {
      card_id: betId,
      filters: {
        details: isMultiSystem
      }
    }
  }).then(({ data }) => camelizeKeys(data));

export default getBetDetails;
