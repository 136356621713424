import { cloneElement, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@namespace/icons2';
import { useGetThemeConfig } from '@namespace/themes';
import clsx from 'clsx';
import Box from '../Box';
import { propsMerge } from '../../utils/propsMerge';

import styles from './index.module.css';

const iconOnlySizesMap = {
  s: 'm',
  m: 'l',
  l: 'l',
  xl: 'xl'
};

const Button = forwardRef((props, ref) => {
  const buttonConfig = useGetThemeConfig('Button');
  const {
    className,
    contentClass,
    intent,
    size,
    component: Component,
    children,
    iconLeft,
    iconRight,
    elementLeft,
    elementRight,
    fullWidth,
    actionType,
    disabled,
    iconOnly,
    processing,
    dataRole,
    styles: customStyles = {},
    customIconSize,
    ...restProps
  } = propsMerge(props, buttonConfig);

  const type = Component === 'button' ? 'button' : null;

  const classes = clsx([
    styles.button,
    styles[intent],
    styles[size],
    styles[actionType],
    className,
    disabled && styles.disabled,
    fullWidth && styles.fullWidth,
    iconOnly && styles.iconOnly,
    processing && styles.processing
  ]);

  const commonIconSize = size === 'xl' ? 'm' : 's';
  const iconSize =
    customIconSize || (iconOnly ? iconOnlySizesMap[size] : commonIconSize);

  const dataRoleProp = {
    [typeof Component === 'string' ? 'data-role' : 'dataRole']: dataRole
  };

  return (
    <Component
      {...dataRoleProp}
      className={classes}
      type={type}
      disabled={disabled}
      ref={ref}
      style={customStyles}
      {...restProps}
    >
      {iconLeft && (
        <Box component="span" className={styles.iconLeft}>
          {cloneElement(iconLeft, { size: iconSize })}
        </Box>
      )}
      {elementLeft && !iconLeft && (
        <Box component="span" className={styles.iconLeft}>
          {elementLeft}
        </Box>
      )}
      {children && !iconOnly && (
        <Box component="span" className={clsx(styles.content, contentClass)}>
          {children}
        </Box>
      )}
      {iconRight && (
        <Box component="span" className={styles.iconRight}>
          {cloneElement(iconRight, { size: iconSize })}
        </Box>
      )}
      {elementRight && !iconRight && (
        <Box component="span" className={styles.iconRight}>
          {elementRight}
        </Box>
      )}
      {processing && (
        <Box className={styles.processingIconContainer}>
          <Icon
            size={iconSize}
            className={styles.processingIcon}
            name="icons/general/state/loader"
          />
        </Box>
      )}
    </Component>
  );
});

Button.propTypes = {
  className: PropTypes.string,
  contentClass: PropTypes.string,
  intent: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'quaternary',
    'quinary'
  ]),
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl']),
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.elementType,
    PropTypes.node
  ]),
  iconLeft: PropTypes.element,
  iconRight: PropTypes.element,
  fullWidth: PropTypes.bool,
  actionType: PropTypes.oneOf([
    'color1',
    'color2',
    'color3',
    'color4',
    'color5'
  ]),
  disabled: PropTypes.bool,
  iconOnly: PropTypes.bool,
  processing: PropTypes.bool,
  children: PropTypes.any
};

Button.defaultProps = {
  intent: 'primary',
  size: 'm',
  component: 'button',
  actionType: 'color1',
  contentClass: '',
  className: ''
};

Button.displayName = 'Button';

export default Button;
