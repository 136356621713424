import { useRef, useState } from 'react';
import { not } from 'ramda';
import { Box, Overlay } from '@alf/uikit';
import { useFixScroll } from '@namespace/common';
import { useComponentConfig, useGeneralRoutes } from '@namespace/cms';
import { Icon } from '@namespace/icons2';
import {
  BackHeaderCommon,
  CategoriesTitle,
  EventsLineHeader,
  EventsLineHeadGroups,
  EventsLineHeadGroupsFilterDropdown,
  TournamentsDrawer
} from '../../../../common';
import { useTournamentsByIds } from '../../../../store';
import styles from './index.module.css';

export const EventsLineExtendedHeader = ({
  activeSport,
  tournamentId,
  serviceId,
  filter,
  setFilter,
  activeHeadGroups,
  setActiveHeadGroups
}) => {
  const drawerRef = useRef(null);
  const { preMatchTournament } = useGeneralRoutes();
  const { sportId, sportSlug } = activeSport;
  const [isTournamentsOpened, setIsTournamentsOpened] = useState(false);
  const toggleTournaments = (e) => {
    e.stopPropagation();
    setIsTournamentsOpened(not);
  };
  const [tournament] = useTournamentsByIds(
    tournamentId ? [tournamentId] : [],
    serviceId
  );
  const { tournamentName, categoryId } = tournament || {};
  const {
    filters: {
      isShowAll,
      isShowStartingSoon,
      isShowNextMorning,
      default: defaultFilter,
      ...filterConfig
    },
    isDisplayHeadMarkets = true
  } = useComponentConfig();

  useFixScroll('TournamentsDrawer', drawerRef, isTournamentsOpened);

  return (
    <Box direction="column">
      <BackHeaderCommon
        Title={() => (
          <CategoriesTitle
            categoryName={tournamentName}
            onToggleMenu={toggleTournaments}
            isCategoriesOpened={isTournamentsOpened}
          />
        )}
        arrowBackIcon={
          <Icon
            size="s"
            name="icons/general/nav/back"
            color="line_header_txt"
          />
        }
        sportId={sportId}
        containerClassName={styles.headerContainer}
      />
      <Box direction="column">
        {isTournamentsOpened && (
          <Overlay
            onClick={toggleTournaments}
            classNames={{
              contentClass: styles.overlay,
              wrapperClass: styles.wrapper
            }}
          >
            <TournamentsDrawer
              ref={drawerRef}
              serviceId={serviceId}
              tournamentRoute={preMatchTournament}
              activeTournamentId={tournamentId}
              categoryId={categoryId}
              sportSlug={sportSlug}
            />
          </Overlay>
        )}

        <EventsLineHeader isCollapsible={false} key="dark-header">
          <EventsLineHeadGroupsFilterDropdown
            filterConfig={filterConfig}
            filterValue={filter}
            isShowAll={isShowAll}
            isShowStartingSoon={isShowStartingSoon}
            onSetFilter={setFilter}
            className={styles.timeFilterDropdown}
          />
          <EventsLineHeadGroups
            serviceId={serviceId}
            sport={activeSport}
            isHeadGroupsSortingEnabled={true}
            activeHeadGroups={activeHeadGroups}
            setActiveHeadGroups={setActiveHeadGroups}
            isDisplayHeadMarkets={isDisplayHeadMarkets}
          />
        </EventsLineHeader>
      </Box>
    </Box>
  );
};
