import { useMemo } from 'react';
import { useScreenSize } from '@namespace/device';
import { getGamesPerRowByScreenSize } from '../../utils';

export const useCalcWideGamesAmount = (wideGamesAmount, regularGamesAmount) => {
  const screenSize = useScreenSize(true);
  const gamesPerRow = getGamesPerRowByScreenSize(screenSize);

  return useMemo(() => {
    if (!wideGamesAmount || !regularGamesAmount) return null;

    // mobile: wide game displayed after each 2 rows of regular games, so 1 wide after 4 regular
    if (gamesPerRow === 2) return Math.ceil(regularGamesAmount / 4);

    // not mobile: wide game displayed on each row of regular games, pattern: 1st row - on left side, 2nd row - on right side
    const notRoundedAmount = regularGamesAmount / (gamesPerRow - 2); // wide game takes place of 2 regular games

    // possible to display a wide game in not a full row only if game's gonna be placed on left side of the row
    return Math.floor(notRoundedAmount) % 2
      ? Math.floor(notRoundedAmount)
      : Math.ceil(notRoundedAmount);
  }, [gamesPerRow, regularGamesAmount, wideGamesAmount]);
};
