import { createSelector } from 'reselect';
import { find, pathOr, propEq } from 'ramda';
import { useSportsbookSelector } from '../../store';

export const selectSportBySportSlug = (sportSlug, serviceId) =>
  createSelector(
    pathOr({}, ['sports', serviceId]),
    find(propEq('sportSlug', sportSlug))
  );

export const useSportBySportSlug = (sportSlug, serviceId) =>
  useSportsbookSelector(selectSportBySportSlug, [sportSlug, serviceId]);
