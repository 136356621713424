const maxLengthValidator = (
  value,
  message = 'validators.maxLengthValidator'
) => ({
  maxLength: {
    value,
    message: [message, value]
  }
});

export default maxLengthValidator;
