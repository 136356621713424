import types from '../types';
import { getLeaderboard } from '../../api';

export default {
  [types.GET_LEADERBOARD_INFO]: (dispatch) => async (tournamentId) => {
    const response = await getLeaderboard(tournamentId);

    dispatch({
      type: types.SET_LEADERBOARD_INFO,
      payload: { leaderboardInfo: response.data, tournamentId }
    });
  },
  [types.SET_LEADERBOARD_INFO]: (dispatch) => async (
    leaderboardInfo,
    tournamentId
  ) => {
    dispatch({
      type: types.SET_LEADERBOARD_INFO,
      payload: { leaderboardInfo, tournamentId }
    });
  }
};
