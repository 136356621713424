import types from '../types';

export const setStepAction = (step) => ({
  type: types.SET_STEP,
  payload: step
});

export const setFormDataAction = (data = {}) => ({
  type: types.SET_FORM_DATA,
  payload: data
});

export const setSuccessAction = () => ({
  type: types.SET_SUCCESS
});

export const setErrorAction = (data = {}) => ({
  type: types.SET_ERROR,
  payload: data
});

export const setErrorCodeAction = (data = {}) => ({
  type: types.SET_ERROR_CODE,
  payload: data
});

export const goNextStepAction = (data = {}) => ({
  type: types.GO_NEXT_STEP,
  payload: data
});

export const goPrevStepAction = (data = {}) => ({
  type: types.GO_PREV_STEP,
  payload: data
});

export const goFirstStepAction = (data = {}) => ({
  type: types.GO_FIRST_STEP,
  payload: data
});

export const goToStepAction = (formData = {}, step = 0, errorFields = {}) => ({
  type: types.GO_TO_STEP,
  payload: { formData, step, errorFields }
});
