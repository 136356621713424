export const DEFAULT_NOTIFICATION_TYPES = {
  INFO: 'info',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning'
};

export const NOTIFICATION_TYPES = {
  DEFAULT: 'default',
  SESSION_DURATION_NOTICE: 'session-duration-notice',
  REALITYCHECK: 'realityCheck',
  SESSION_EXPIRED: 'sessionExpired',
  SESSION_EXPIRED_POPUP: 'sessionExpiredPopup',
  SUSPENDED_USER: 'suspendedUser',
  ADDICTED_USER: 'addictedUser',
  UNVERIFIED_USER: 'unverifiedUser',
  IN_HOUSE_JACKPOT_WIN: 'inHouseJackpotWin',
  LUDOMAN_IN_VERIFICATION: 'ludomanInVerification',
  PASSPORT_VERIFICATION: 'passportVerification',
  ADDITIONAL_INFORMATION_UPLOAD_VERIFICATION:
    'additionalInformationUploadVerification',
  ADDITIONAL_INFORMATION_OTHER_VERIFICATION:
    'additionalInformationOtherVerification',
  VERIFIED_USER: 'verifiedUser',
  NOT_VERIFIED_USER: 'notVerifiedUser'
};
