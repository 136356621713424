export const SET_ACTION_RAW_PAYLOAD = Symbol('set-action-raw-payload');

export const USER_ROLES = {
  USER: 'user',
  GUEST: 'guest'
};

export const USER_GROUP_TYPES = {
  ALL: 'all',
  ...USER_ROLES
};

export const MIN_DATE_OFFSET = { years: 90 };
export const MAX_DATE_OFFSET = { years: 18 };

export const SHORT_REGISTER_USER_DATA = {
  firstName: 'Быстрая',
  lastName: 'Регистрация',
  birthdate: '1970-01-01T00:00:00Z',
  gender: 'mr',
  secretQuestion: 'What is your phone number?'
};

export const BONUS_PAYMENT_SERVICE_CURRENCY = 'BNS';

export const LINE_VIEW_TYPES = {
  TABLE: 'table',
  BUTTON: 'button'
};

export const PREWAGER_WALLET_PREFIX = '@bonus';
export const PREWAGER_PAYMENT_SYSTEM_ID = -1;

export const USER_VIP_STATUSES = {
  VIP_BETTING_CLIENT: 'isVipBettingClient',
  VIP_CASINO_CLIENT: 'isVipCasinoClient',
  VIP_SUPPORT_CLIENT: 'isVipSupportClient'
};

// key -> server value, value -> our key in context
export const USER_STATUSES_REMAP = {
  isSuspendedUser: 'isSuspendedUser',
  ludoman: 'isAddictedUser',
  IS_DOCUMENTS_UPLOAD: 'isDocumentsUpload',
  PEP: 'isPep',
  IS_BONUS_HUNTER: 'isBonusHunter',
  IS_EMAIL_VERIFIED: 'isEmailVerified',
  NEW_USER: 'isNewUser',
  IS_PHONE_VERIFIED: 'isPhoneVerified',
  VIP_BETTING_CLIENT: 'isVipBettingClient',
  VIP_CASINO_CLIENT: 'isVipCasinoClient',
  VIP_SUPPORT_CLIENT: 'isVipSupportClient',
  VIP_CASH: 'isVipCash',
  IS_SUBBED_FOR_EMAILS: 'isSubbedForEmails',
  LUDOMAN_IN_VERIFICATION: 'isLudomanInVerification',
  PASSPORT_VERIFIED_BY_RISK: 'isPassportVerifiedByRisk',
  ADDITIONAL_INFORMATION_REQUIRED: 'isAdditionalInformationRequired',
  PASSPORT_IN_VERIFICATION: 'isPassportInVerification',
  NOT_VERIFIED: 'isNotVerified',
  POLICY_BLOCK: 'policyBlock'

  // todo use in future, mb rename some of them
  // TRUSTED_USER: 'isTrustedUser',
  // SUREBETTOR: 'isSureBettor',
  // LATE_BETTOR: 'isLateBettor',
  // PALPABLE_ERROR_CHEATER: 'isPalpableErrorCheater',
  // POKER_CHEATER: 'isPokerCheater',
  // LOYALTY_LOCKED: 'isLoyaltyLocked',
  // ACTION_PARTICIPANT: 'isActionParticipant',
  // NOT_VERIFIED: 'isNotVerified',
  // VERIFIED_PASSPORT: 'isVerifiedPassport',
  // FULLY_VERIFIED: 'isFullyVerified',
  // A_FIXED_MATCHES: 'aFixedMatches',
  // FORK_CHEATER: 'isForkCheater',
  // A_HALF_FIXED_MATCHES: 'aHalfFixedMatches',
  // CHECK_ON_THE_INPUT_MEANS: 'checkOnTheInputMeans',
  // SHOW_USER_INFO: 'showUserInfo',
  // TERRORIST: 'isTerrorist',
  // LOST_PASSPORT: 'lostPassport',
  // AFFILIATE_PLAYER: 'affiliatePlayer',
  // NEED_TO_CHANGE_PASSWORD: 'needToChangePassword',
  // RESIDENT: 'isResident',
  // SELF_LIMITED: 'isSelfLimited',
  // PASSWORD_CHANGED: 'isPasswordChanged',
  // PEP: 'pep',
};
