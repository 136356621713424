import { useCallback } from 'react';
import { useI18n } from '@namespace/i18n';

import { roundTime, toMinutes, getTimeDuration } from '../../../../utils';

const useNotificationMessage = ({
  hideRealityCheckNotificationPeriod,
  isShowReport
}) => {
  const { t, f } = useI18n();

  const create = useCallback(
    (data) => {
      const { from_ts: loggedFrom, total, win, bets, currency, period } = data;
      const duration = getTimeDuration(loggedFrom, f);
      const body = t('responsibleGambling.realityCheckReport', [
        roundTime(duration.hours),
        roundTime(duration.minutes),
        ...(isShowReport ? [total, win, bets, currency] : [])
      ]);

      const notificationMessage = { body };

      if (!hideRealityCheckNotificationPeriod) {
        const title = t(
          'responsibleGambling.realityCheck.Report.sessionDuration',
          [[toMinutes(period)]]
        );

        notificationMessage.title = title;
      }

      return notificationMessage;
    },
    [f, isShowReport, hideRealityCheckNotificationPeriod, t]
  );

  return { create };
};

export default useNotificationMessage;
