import { AText, Box } from '@alf/uikit';
import { LocalizedLink } from '@namespace/i18n';
import { Icon } from '@namespace/icons2';
import styles from './index.module.css';

const SectionButton = ({
  onClick,
  link,
  count,
  labelFirstPart = '',
  labelSecondPart = '',
  className = '',
  dataRole
}) => (
  <Box
    justify="center"
    className={`${styles.container} ${className}`}
    data-role={dataRole}
  >
    <LocalizedLink
      to={link}
      className={`${styles.liveEventsButton} liveEventsButton`}
      onClick={onClick}
    >
      <Box className={styles.buttonContainer}>
        <Box
          flex="1"
          justify="center"
          align="center"
          className={styles.sportName}
        >
          <AText
            size="l"
            breed="BU"
            color="btn_crossection_txt_color_1"
            transform="uppercase"
            className={styles.sportNameFirstHalf}
          >
            {labelFirstPart}
          </AText>
          <Box
            align="center"
            className={styles.sportNameSecondHalf}
            padding="xs xs xs s"
          >
            <AText
              size="xl"
              breed="BU"
              color="btn_crossection_txt_color_2"
              transform="uppercase"
            >
              {labelSecondPart}
            </AText>
            <Icon
              className={styles.secondHalfIcon}
              name="icons/general/nav/go-to-circle"
              color="btn_crossection_txt_color_2"
              size="xs"
            />
          </Box>
          <Box align="center" className={styles.eventsCount}>
            <AText
              breed="B"
              size="l"
              color="btn_crossection_txt_color_1"
              data-role={`${dataRole}-counter`}
            >
              {count || ''}
            </AText>
            <Icon
              name="icons/general/nav/go-to"
              color="btn_crossection_txt_color_1"
              size="s"
            />
          </Box>
        </Box>
      </Box>
    </LocalizedLink>
  </Box>
);

export default SectionButton;
