import { useEffect, useContext } from 'react';
import { useComponentSettings } from '../useComponentSettings';
import { LayoutContext } from '../../storeLayout/context';

export const useExpandedComponent = () => {
  const { id } = useComponentSettings();
  const [, { SET_COMPONENT_EXPANDED, UNSET_COMPONENT_EXPANDED }] = useContext(
    LayoutContext
  );

  useEffect(() => {
    SET_COMPONENT_EXPANDED(id);

    return () => {
      UNSET_COMPONENT_EXPANDED(id);
    };
  }, [SET_COMPONENT_EXPANDED, UNSET_COMPONENT_EXPANDED, id]);
};
