import { useCallback, useContext } from 'react';
import { InfoMessage, Box, AText, Button, Link } from '@alf/uikit';
import { useConfig, useGeneralRoutes } from '@namespace/cms';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { UserContext } from '@namespace/user';
import { PersonalOfficeWrapper } from '../../../common';
import styles from './index.module.css';

const SuccessUpload = ({ id }) => {
  const { t } = useI18n();
  const { config = {} } = useConfig(id);
  const [user] = useContext(UserContext);
  const { push } = useHistory();
  const { email } = user;
  const { profileDocuments, wallets } = useGeneralRoutes();
  const { okLink = wallets } = config;
  const onSuccess = useCallback(() => push(okLink), []);

  return (
    <Box justify="center" component={PersonalOfficeWrapper}>
      <Box align="center" direction="column">
        <InfoMessage>
          <AText
            component="p"
            align="center"
            breed="B"
            color="po_txt_color_1"
            size="m"
          >
            {t('verificationWizard.step.success.successfullyUploaded')}
          </AText>
          <AText component="p" align="center" size="m" color="po_txt_color_1">
            {t('verificationWizard.step.success.info_1', [email])}
          </AText>
          <AText
            component="p"
            align="center"
            breed="B"
            color="po_txt_color_1"
            size="m"
          >
            {t('verificationWizard.step.success.info_2')}
          </AText>
          <AText component="p" align="center" size="m" color="po_txt_color_1">
            {t('verificationWizard.step.success.info_3')}
            <Link
              Component={LocalizedLink}
              to={profileDocuments}
              color="btn_quaternary_color_1"
              breed="B"
              className={styles.profile}
            >
              {t('verificationWizard.step.success.info_4')}
            </Link>
          </AText>
        </InfoMessage>
        <Button
          onClick={onSuccess}
          fullWidth={true}
          className={styles.button}
          size="l"
        >
          {t('verificationWizard.step.success.okButton')}
        </Button>
      </Box>
    </Box>
  );
};

export default SuccessUpload;
