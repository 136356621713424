import { Box } from '@alf/uikit';
import styles from './index.module.css';

export const TournamentItemInfoSkeleton = () => (
  <Box
    className={styles.wrapper}
    direction="column"
    align="center"
    justify="between"
  >
    <Box direction="column" align="center" style={{ width: '100%' }}>
      <div className={`${styles.oneLineSkelet} ${styles.shimer}`} />
      <div className={`${styles.titleBlock} ${styles.shimer}`} />
      <div className={`${styles.oneLineSkelet} ${styles.shimer}`} />
      <div className={`${styles.descriptionSkelet} ${styles.shimer}`} />
    </Box>
    <div className={`${styles.timerBlock} ${styles.shimer}`} />
  </Box>
);
