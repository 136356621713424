import { useCallback } from 'react';
import { updateUser } from '../../api';

const useUpdateUser = () =>
  useCallback(async (userData = {}) => {
    try {
      const response = await updateUser({
        first_name: userData.firstName,
        middle_name: userData.middleName,
        last_name: userData.lastName,
        title: userData.sex,
        country_id: userData.countryId,
        date_of_birth: userData.birthday,
        question: userData.secretQuestion,
        answer: userData.secretQuestionAnswer,
        statuses: userData.statuses,
        ...(userData.pepData || {})
      });

      if (response) {
        return {
          firstName: userData.firstName,
          middleName: userData.middleName,
          lastName: userData.lastName,
          sex: userData.sex,
          title: userData.sex,
          countryId: userData.countryId,
          birthday: userData.birthday,
          question: userData.secretQuestion
        };
      }

      throw new Error('accounting_error_134');
    } catch ({ errorCode }) {
      throw new Error(errorCode);
    }
  }, []);

export default useUpdateUser;
