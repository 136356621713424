import { useContext, useMemo } from 'react';
import { find } from 'lodash';
import {
  ReferenceContext,
  StepperContext,
  usePhoneCode
} from '@namespace/common';
import {
  FormBuilder,
  FORM_COMPONENTS_TYPES,
  FORM_VALIDATION_TYPES,
  useFormMethods
} from '@namespace/forms';
import {
  REGISTER_COMPONENTS_TYPES,
  registerComponentsMap
} from '../../../../constants';

const InfoForm = ({
  onStepSuccess,
  onGoBack,
  defaultCountryCode,
  classNames = {},
  autoSetPhoneIfCountryChanged = false,
  ...props
}) => {
  const defaultPhoneCode = usePhoneCode(defaultCountryCode);
  const { formClassName = '', inputWithoutLabel = '' } = classNames;
  const { registrationCountries } = useContext(ReferenceContext);
  const countryOptions = useMemo(
    () =>
      registrationCountries.map(({ countryName, iso1Code }) => ({
        name: countryName,
        value: iso1Code
      })),
    [registrationCountries]
  );
  const {
    stepper: {
      formData: {
        country = '',
        firstName = '',
        lastName = '',
        middleName = '',
        gender = 'mr',
        birthdate = '',
        phone = { isoCode: defaultCountryCode, number: defaultPhoneCode }
      }
    }
  } = useContext(StepperContext);

  const providedCountry = country || defaultCountryCode;
  const defaultFormCountryOption = providedCountry
    ? find(countryOptions, ['value', providedCountry])
    : countryOptions[0];
  const defaultValues = {
    country: defaultFormCountryOption?.value,
    firstName,
    lastName,
    middleName,
    birthdate,
    phone,
    gender
  };

  const formMethods = useFormMethods({ defaultValues });
  const { watch } = formMethods;
  const countryFieldValue = watch('country');

  const componentsList = [
    {
      type: FORM_COMPONENTS_TYPES.REG_COUNTRY_SELECT,
      name: 'country',
      label: 'register.steps.personalInfo.selectCountry',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
      size: 'm'
    },
    {
      type: FORM_COMPONENTS_TYPES.NAME_INPUT,
      name: 'lastName',
      label: 'register.steps.personalInfo.enterPersonals',
      placeholder: 'register.steps.personalInfo.lastName',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED]
    },
    {
      type: FORM_COMPONENTS_TYPES.NAME_INPUT,
      name: 'firstName',
      placeholder: 'register.steps.personalInfo.firstName',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
      classNames: {
        wrapperClassName: inputWithoutLabel
      }
    },
    {
      type: FORM_COMPONENTS_TYPES.MIDDLE_NAME_INPUT,
      name: 'middleName',
      placeholder: 'register.steps.personalInfo.middleName',
      classNames: {
        wrapperClassName: inputWithoutLabel
      }
    },
    {
      type: FORM_COMPONENTS_TYPES.GENDER_BUTTONS_GROUP,
      name: 'gender',
      label: 'register.steps.personalInfo.gender',
      elements: [
        {
          label: 'register.steps.personalInfo.man',
          value: 'mr',
          iconName: 'icons/general/item/men'
        },
        {
          label: 'register.steps.personalInfo.woman',
          value: 'ms',
          iconName: 'icons/general/item/woman'
        }
      ],
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED]
    },
    {
      type: FORM_COMPONENTS_TYPES.REG_DATE_PICKER,
      label: 'register.steps.personalInfo.birthdate',
      name: 'birthdate',
      placeholder: 'register.steps.personalInfo.birthdate',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
      size: 'm',
      classNames: {
        datePickerWrapperClassName: ''
      }
    },
    {
      type: FORM_COMPONENTS_TYPES.REG_PHONE,
      name: 'phone',
      label: 'register.steps.personalInfo.mobile',
      validationTypes: [
        FORM_VALIDATION_TYPES.REQUIRED,
        {
          type: FORM_VALIDATION_TYPES.MIN_LENGTH_AND_CHECKPHONE_VALIDATOR,
          parameters: [3, 'validators.requiredValidator', (v) => v.number]
        }
      ]
    },
    {
      type: FORM_COMPONENTS_TYPES.SUBMIT_BUTTON,
      value: 'register.steps.continue',
      size: 'xl'
    },
    {
      type: REGISTER_COMPONENTS_TYPES.BACK_BUTTON,
      onGoBack
    }
  ];

  return (
    <FormBuilder
      wrapperClassName={formClassName}
      components={componentsList}
      formMethods={formMethods}
      componentsMap={registerComponentsMap}
      onSubmit={onStepSuccess}
      formProps={{
        defaultCountryCode:
          (autoSetPhoneIfCountryChanged && countryFieldValue) ||
          providedCountry,
        isShowPhoneMask: true,
        ...props
      }}
    />
  );
};

export default InfoForm;
