import { get } from 'lodash';
import { window } from '@namespace/helpers';

const handleWrongStatusCode = (res) => {
  if (res.status !== 200) {
    throw new Error(
      `${res.url
        .split('/')
        .slice(3)
        .join('/')}>${res.status}:${res.statusText}`
    );
  }

  return res;
};

const fetchRealIp = () =>
  fetch('https://ip22.org/whatsapplay/api', { mode: 'cors' })
    .then(handleWrongStatusCode)
    .then((res) => res.json());

const fetchStream = async (eventId, partnerId, ip, lang) => {
  const streamURL = `/favtv/api?client=mobile&event_id=${eventId}&partner_id=${partnerId}&language=${lang}`;
  const streamData = await fetch(streamURL, {
    method: 'GET',
    headers: {
      'X-Real-IP-DM': ip
    }
  });

  return streamData.json();
};

const fetchUnasData = (url) =>
  fetch(url)
    .then(handleWrongStatusCode)
    .then((res) => res.text());

const getRealIp = () =>
  fetchRealIp()
    .then((json) => get(json, ['X-Real-IP']))
    .catch(console.error);

const getElementsByTagNameFromXML = (xmlDoc, name) => {
  return xmlDoc.getElementsByTagName('token')[0].getAttribute(name);
};

const fetchUnasStream = (data) => {
  const UNASurl = [window.location.protocol, data.stream.split(':')[1]].join(
    ''
  );
  const unasData = data;

  return fetchUnasData(UNASurl).then((text) => {
    let parser;
    let xmlDoc;
    if (window.DOMParser) {
      parser = new DOMParser();
      xmlDoc = parser.parseFromString(text, 'text/xml');
    } else {
      // Internet Explorer
      xmlDoc = new window.ActiveXObject('Microsoft.XMLDOM');
      xmlDoc.async = false;
      xmlDoc.loadXML(text);
    }
    const status = xmlDoc
      .getElementsByTagName('token')[0]
      .getAttribute('statustext');
    if (status === 'error') {
      return {};
    }
    const url = getElementsByTagNameFromXML(xmlDoc, 'url');
    const stream = getElementsByTagNameFromXML(xmlDoc, 'stream');
    const auth = getElementsByTagNameFromXML(xmlDoc, 'auth');
    const aifp = getElementsByTagNameFromXML(xmlDoc, 'aifp');

    unasData.stream = `rtmp://${url}/${stream}?auth=${auth}&aifp=${aifp}`;

    return unasData;
  });
};

const fetchStreamByEvent = (eventId, partnerId, IP, lang) =>
  fetchStream(eventId, partnerId, IP, lang)
    .then(async (json) => {
      const streamStatus = get(json, ['stream', 'status']);
      const error = get(json, ['error']);

      if (!error && json && streamStatus !== 'error') {
        let stream = get(json, ['stream', 'url']) || null;
        const promo = get(json, ['promo', 'file']);
        const logo = get(json, ['logo']);
        const provider = get(json, ['provider', 'name']);

        if (provider === 'img') {
          const imgData = await fetch({
            method: 'GET',
            url: stream
          });

          stream = imgData.data.hlsUrl;
        }

        return {
          stream,
          promo,
          logo,
          provider
        };
      }

      return {};
    })
    .catch((error) => {
      console.log('Stream error:', error);

      return {};
    });

let IP = null;

export const fetchVideoStream = async (eventId, partnerId, lang) => {
  if (!IP) {
    IP = await getRealIp();
  }

  return fetchStreamByEvent(eventId, partnerId, IP, lang).then((data) => {
    const newData = data;

    if (newData?.provider && newData.provider.match(/betradar/gi)) {
      // not supported yet
      newData.stream = null;
    }

    if (newData?.provider && newData.provider.match(/unas/gi)) {
      return fetchUnasStream(newData);
    }

    return newData;
  });
};
