import { post, window, getResourceFromCms, document } from '@namespace/helpers';
import { mapKeys } from 'lodash';
import {
  setStoredTranslationsKeysVisible,
  removeStoredTranslationsKeysVisible
} from './storedLanguageTranslation';
import {
  DATE_FORMATS,
  TIME_FORMATS,
  DATE_SEPARATORS,
  TIME_SEPARATORS,
  DATETIME_SEPARATORS
} from '../constants';

export const getBrowserLanguage = () =>
  window.navigator.language || window.navigator.browserLanguage;

export const getLocale = () => window.navigator.language || 'en-GB';

export const setTimezone = (tz) =>
  post({ url: '/bets/timezone/set/', body: `tz=${tz}`, timeout: 11000 });

export const setHtmlLang = (lang) =>
  new Promise((resolve) => {
    // set language to html for correct displaying some upper-case words in application

    // https://developer.mozilla.org/en-US/docs/Web/CSS/text-transform

    // In Turkic languages, like Turkish (tr), Azerbaijani (az), Crimean Tatar (crh),
    // Volga Tatar (tt), and Bashkir (ba),
    // there are two kinds of i, with and without the dot, and two case pairings: i/İ and ı/I.
    // should be set before application render
    resolve((document.documentElement.lang = lang));
  });

export const getTranslations = (lang, options) =>
  getResourceFromCms(`/translations/${lang}`, options);

export const checkAndSaveTranslationKeysVisible = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const keyFromContentManager = urlParams.get('translate');

  if (keyFromContentManager) {
    if (keyFromContentManager === 'disable') setStoredTranslationsKeysVisible();
    if (keyFromContentManager === 'enable')
      removeStoredTranslationsKeysVisible();
  }
};

export const convertTranslationsToLowerCase = (translations) =>
  mapKeys(translations, (_, key) => key.toLowerCase());

export const detectFormat = (strDateTime = '') => {
  console.warn('please, use "format" props for parse your specific format');
  if (/^[\d]+$/.test(strDateTime.toString())) {
    return '';
  }
  let dateTimeSeparator = '';
  let parsedDate = [strDateTime];
  for (const separator of DATETIME_SEPARATORS) {
    if (strDateTime.split(separator).length === 2) {
      dateTimeSeparator = separator;
      parsedDate = strDateTime.split(separator);
    }
  }
  const [date = '', time = ''] = parsedDate;
  let dateFormat = '';
  let timeFormat = '';
  for (const [format, regExp] of Object.entries(DATE_FORMATS)) {
    if (regExp.test(date)) {
      let separator = '';
      for (const dateSeparator of DATE_SEPARATORS) {
        if (date.split(dateSeparator).length > 1) {
          separator = dateSeparator;
        }
      }
      dateFormat = format.split('_').join(separator);
    }
  }
  for (const [format, regExp] of Object.entries(TIME_FORMATS)) {
    if (regExp.test(time)) {
      let separator = '';
      for (const timeSeparator of TIME_SEPARATORS) {
        if (time.split(timeSeparator).length > 1) {
          separator = timeSeparator;
        }
      }
      timeFormat = format.split('_').join(separator);
    }
  }

  return dateTimeSeparator && !timeFormat
    ? ''
    : `${dateFormat}${dateTimeSeparator}${timeFormat}`;
};
