import { pick, propEq, reject, whereEq } from 'ramda';
import { initialState } from './store';

export const actionsCreator = ({ setState, getState }) => ({
  SET_FILTER_SELECTION: ({
    filter = getState().filter,
    inactiveFilter = getState().inactiveFilter,
    selectedSportId = getState().selectedSportId
  }) =>
    setState({
      filter,
      inactiveFilter,
      selectedSportId
    }),

  RESET_FILTER_SELECTION: () => setState(initialState, true),

  REMOVE_FILTER_ITEM: (filterItem) => {
    if (filterItem == null) {
      return;
    }

    const sanitizedFilterItem = pick(
      ['sportId', 'categoryId', 'tournamentId'],
      filterItem
    );
    const withoutFilterItem = reject(whereEq(sanitizedFilterItem));

    const { filter, inactiveFilter, selectedSportId } = getState();
    const newFilter = withoutFilterItem(filter);
    const newInactiveFilter = withoutFilterItem(inactiveFilter);
    const newState = {
      filter: newFilter,
      inactiveFilter: newInactiveFilter
    };

    if (
      !newFilter.find(propEq('sportId', selectedSportId)) &&
      !newInactiveFilter.find(propEq('sportId', selectedSportId))
    ) {
      newState.selectedSportId = null;
    }

    setState(newState);
  }
});

// fake static actions
export const advFilterInnerActions = actionsCreator({});
