import { propOr } from 'ramda';
import { createSelector } from 'reselect';
import { selectEventById, useSportsbookSelector } from '../../../../../store';

export const selectEventsByEventIdInSameTournament = (eventId) =>
  createSelector(
    selectEventById(eventId),
    propOr({}, 'events'),
    ({ tournamentId, serviceId }, eventsObj) =>
      Object.values(eventsObj)
        .filter(
          ({ tournamentId: tId, eventId: eId, serviceId: sId }) =>
            tId === tournamentId && eId !== eventId && sId === serviceId
        )
        .sort(({ eventDt: eDate1 }, { eventDt: eDate2 }) => eDate1 - eDate2)
        .sort(
          ({ eventWeigh: eWeight1 }, { eventWeigh: eWeight2 }) =>
            eWeight1 - eWeight2
        )
  );

export const useEventsByEventIdInSameTournament = (eventId) =>
  useSportsbookSelector(selectEventsByEventIdInSameTournament, [eventId]);
