import { AText, Box } from '@alf/uikit';
import { SCOPE_IDS } from '../../../../constants';
import { useEventFields } from '../../../../store';
import styles from './index.module.css';

const Place = ({ eventId }) => {
  const { participants, scoreboard, eventResultId } = useEventFields({
    eventId,
    fields: ['participants', 'scoreboard', 'eventResultId']
  });
  const { places = [] } = scoreboard;

  const { resultTypeData = [] } =
    places.find(({ resultTypeId }) => resultTypeId === eventResultId) || {};
  const { scopeData = [] } =
    resultTypeData.find(({ scopeId }) => scopeId === SCOPE_IDS.PLACE) || {};
  const filteredParticipantValues = scopeData
    .filter(({ value }) => value !== 0)
    .sort((a, b) => a.value - b.value);

  const filteredParticipants = filteredParticipantValues.reduce(
    (memo, { number, value }) => {
      const participant = participants.find(
        ({ participantNumber }) => participantNumber === number
      );

      return [
        ...memo,
        {
          ...participant,
          position: value
        }
      ];
    },
    []
  );

  return (
    <Box align="center" wrap={true}>
      {filteredParticipants.map(({ position, participantName }) => (
        <Box
          key={participantName + position}
          align="center"
          className={styles.place}
        >
          <AText color="line_txt_1" size="m">
            {`${position} - ${participantName}`}
          </AText>
        </Box>
      ))}
    </Box>
  );
};

export default Place;
