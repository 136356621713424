import { useContext } from 'react';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import { AText, Box } from '@alf/uikit';
import { SiteSettingsContext, useOtherSettings } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { useGetImage } from '@namespace/themes';
import { UserContext } from '@namespace/user';
import { LIMIT_TYPES } from '@namespace/responsible-gambling';
import useLimits from '@namespace/responsible-gambling/src/hooks/useLimits';
import styles from './index.module.css';

const ItemContent = ({ item, menuType }) => {
  const [{ counters }] = useContext(UserContext);
  const [settings] = useContext(SiteSettingsContext);
  const { menuTags = {} } = settings;
  const { t } = useI18n();
  const getImg = useGetImage();
  const {
    image,
    imageDark,
    title,
    subTitle,
    countable,
    counterName,
    tagId
  } = item;
  const { tagBackground, tagId: tagTitle } = menuTags[tagId] || {};
  const { isShowSelfExcludedBlock = false } = useOtherSettings();
  const [selfExclusion, , , isLoaded] = useLimits(LIMIT_TYPES.SELF_EXCLUSION);
  const isSelfExcludedUser = isShowSelfExcludedBlock && !isEmpty(selfExclusion);
  // FYI: if need to show bonuses from BS use counterName: "accountingAndOtherBonusesCount" in siteSettings
  const counter = !isSelfExcludedUser && isLoaded && counters[counterName];

  return (
    <Box
      className={styles.item}
      direction="column"
      align="center"
      justify="center"
      gap={title && 's'}
    >
      {countable && counter > 0 && (
        <Box className={styles.counter}>
          <AText color="po_menutxt_color_2" breed="BU" size="xs">
            {counter}
          </AText>
        </Box>
      )}
      <Box>
        <img src={getImg(image, imageDark)} alt="" />
        {tagTitle && (
          <Box className={styles.tag} style={{ background: tagBackground }}>
            <AText breed="BU" size="2xs" color="txt_color_4">
              {tagTitle}
            </AText>
          </Box>
        )}
      </Box>
      <Box direction="row" justify="center" align="center">
        <AText
          className={clsx(styles.title, styles[`title-${menuType}`])}
          color="po_menutxt_color_1"
          breed="BU"
          align="center"
        >
          {t(title)}
        </AText>
        {subTitle && (
          <AText
            className={styles.subTitle}
            color="po_accent_color_1"
            breed="BU"
            size="xs"
          >
            {t(subTitle)}
          </AText>
        )}
      </Box>
    </Box>
  );
};

export default ItemContent;
