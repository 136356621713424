import { includes, pathOr, pipe } from 'ramda';
import { useConfig } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { MODE } from '../../constants';
import { useGetPathParams } from '../../hooks/useGetPathParams';
import BackHeaderCommon from '../../common/BackHeader';
import { useSportsbookStore } from '../../store';
import { AdvFilterButton } from '../AdvancedFilter';

const BackHeader = ({ id }) => {
  const {
    config: {
      title = '',
      forActiveSport = false,
      showPinIcon = false,
      mode = 0,
      isShowHeaderCustomFilter = false
    }
  } = useConfig(id);
  const { t } = useI18n();
  const { activeSport } = useGetPathParams(mode);
  const { sportName, sportId } = activeSport || {};
  const isPinned = useSportsbookStore(
    pipe(pathOr([], ['favorites', 'sports']), includes(sportId))
  );
  const isLiveSport = MODE.IN_PLAY === mode;

  return (
    <BackHeaderCommon
      title={forActiveSport ? sportName : t(title)}
      sportId={sportId}
      isPinned={isPinned}
      showPinIcon={showPinIcon}
      showSportIcon={false}
      isLiveSport={isLiveSport}
      isShowHeaderCustomFilter={isShowHeaderCustomFilter}
      contentRight={
        isShowHeaderCustomFilter && <AdvFilterButton color="line_header_txt" />
      }
    />
  );
};

export default BackHeader;
