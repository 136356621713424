import { Box } from '@alf/uikit';
import IconLink from '../IconLink';
import styles from './index.module.css';

const IconLinkList = ({ items = [], size = 'xs', padding = 's' }) => (
  <Box
    data-role="icon-link-list"
    className={styles[size]}
    align="center"
    justify={items.length > 2 ? 'between' : 'evenly'}
    padding={padding}
  >
    {items.map((item) => (
      <IconLink
        key={item.icon}
        className={
          items.length > 2 ? styles.icon : `${styles.icon} ${styles.iconMargin}`
        }
        {...item}
      />
    ))}
  </Box>
);

export default IconLinkList;
