import { RECAPTCHA_VERSION } from '../../../../constants';

export const reCaptchaHeaders = (
  token,
  place,
  version = RECAPTCHA_VERSION.V3
) => {
  return {
    headers: {
      'X-Captcha-Token': token,
      'X-Captcha-Type': version,
      'X-Captcha-Action': place
    },
    mode: 'cors'
  };
};
