import flagsMap from '@namespace/flags-icons/flagsMap.json';

const FALLBACK_NAME = 'World';

const getFlag = async (name) => {
  const allFlags = await import(`@namespace/flags-icons/flags`);
  return allFlags.flags[name];
};

const getFallback = (name) => (e) =>
  name !== FALLBACK_NAME ? getFlag(FALLBACK_NAME) : Promise.reject(e);

export const getFlagIconAsync = (id) => {
  const { name = FALLBACK_NAME } = flagsMap[id] || {};

  return getFlag(name).catch(getFallback(name));
};

export const getFlagId = ({ iso, name }) =>
  Object.keys(flagsMap).find(
    (key) => flagsMap[key].iso === iso || (name && flagsMap[key].name === name)
  );
