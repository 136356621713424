import { Box } from '@alf/uikit';
import clsx from 'clsx';
import { scrollTo, document } from '@namespace/helpers';
import { useConfig } from '@namespace/cms';
import { LOG_ACTIONS, logAction } from '@namespace/analytics';
import { SCROLLABLE_COLUMN } from '../../constants';
import styles from './index.module.css';

export const onBackToTopClick = () => {
  scrollTo({ top: 0, behavior: 'smooth' });

  for (const el of document.querySelectorAll(`.${SCROLLABLE_COLUMN}`)) {
    scrollTo({ el, top: 0, behavior: 'smooth' });
  }

  logAction(LOG_ACTIONS.BACK_TO_TOP);
};

const BackToTop = ({ id, customButtonIcon, className }) => {
  const { config = {} } = useConfig(id);
  const { margin = 'm', customButton = '', ...rest } = config;

  const customIcon = customButtonIcon || customButton;

  const customStyles = {
    backgroundImage: `url(${customIcon})`,
    ...rest
  };
  if (customIcon) {
    return (
      <Box
        style={customStyles}
        margin={margin}
        className={clsx([styles.backToTop, styles.custom, className])}
        onClick={onBackToTopClick}
        data-role="footer-back-to-top-button-new"
      />
    );
  }

  return (
    <Box
      margin={margin}
      className={styles.backToTop}
      onClick={onBackToTopClick}
      data-role="footer-back-to-top-button"
    />
  );
};

export default BackToTop;
