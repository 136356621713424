import { useCallback } from 'react';
import { matchPath } from 'react-router-dom';
import { AText, Box } from '@alf/uikit';
import { useI18n, useLocation } from '@namespace/i18n';
import { POPUP_TYPES, POPUP_HALF_SCREEN } from '@namespace/helpers';
import { useMobileNavMenuSettings } from '@namespace/cms';
import {
  useAddSearchParams,
  useSearchParams,
  useHistory
} from '@namespace/router';
import clsx from 'clsx';
import useDetectKeyboardOpen from '../../utils/useDetectKeyboardOpen';
import { MOBILE_MENU_MAX_LENGTH } from '../../constants';
import styles from './index.module.css';

const BottomNavigationMenu = ({ classNames = {} }) => {
  const {
    activeMenuItem = '',
    customWrapper = '',
    iconStyle = '',
    itemStyle = ''
  } = classNames;
  const { t } = useI18n();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const mobileNavigation = useMobileNavMenuSettings();
  const {
    navMenu = [],
    menuItemImage,
    menuItemImageOpen,
    menuItemImageActive,
    menuItemTitle,
    highlightOnPages: highlightMenuPages
  } = mobileNavigation;

  const navMenuFormatted = navMenu.slice(0, MOBILE_MENU_MAX_LENGTH);
  const { popup } = useSearchParams();

  const isProfileMenuPopupOpen = popup === POPUP_TYPES.PROFILE_MENU;
  const isMobilePopupMenuOpen = popup === POPUP_HALF_SCREEN.MOBILE_POPUP_MENU;
  const isPopupOpen = isProfileMenuPopupOpen || isMobilePopupMenuOpen;

  const addSearchParams = useAddSearchParams();
  const isKeyboardOpen = useDetectKeyboardOpen();
  const isHighlightMenu = highlightMenuPages?.some((path) =>
    matchPath({ path, end: true }, pathname)
  );
  const menuIconActive = isHighlightMenu ? menuItemImageActive : menuItemImage;

  const handleMenuClick = useCallback(
    (action) => {
      switch (action) {
        case POPUP_TYPES.PROFILE_MENU:
          if (isProfileMenuPopupOpen) {
            push(pathname);
          } else {
            addSearchParams({ popup: POPUP_TYPES.PROFILE_MENU }, null, true);
          }
          break;
        case POPUP_HALF_SCREEN.MOBILE_POPUP_MENU:
          if (isMobilePopupMenuOpen) {
            push(pathname);
          } else {
            addSearchParams(
              { popup: POPUP_HALF_SCREEN.MOBILE_POPUP_MENU },
              null,
              true
            );
          }
          break;
        default:
          push(action);
          break;
      }
      return null;
    },
    [
      addSearchParams,
      isProfileMenuPopupOpen,
      isMobilePopupMenuOpen,
      pathname,
      push
    ]
  );

  return !isKeyboardOpen ? (
    <Box
      className={clsx([styles.wrapper, customWrapper])}
      align="center"
      padding="s"
      data-role="bottom-navigation-menu"
    >
      {navMenuFormatted.map(
        ({ action, image, imageActive, title, highlightOnPages }) => {
          const isPathMatch = highlightOnPages.some((path) =>
            matchPath({ path, end: true }, pathname)
          );

          const isActiveItem = isPopupOpen ? action === popup : isPathMatch;

          return (
            <Box
              onClick={() => handleMenuClick(action)}
              className={clsx(styles.item, isActiveItem && activeMenuItem)}
              key={`bottom-menu-item-${title}`}
              justify="center"
              align="center"
            >
              <Box direction="column" justify="center" align="center">
                <img
                  src={isActiveItem ? imageActive : image}
                  className={clsx(styles.icon, iconStyle)}
                  alt=""
                />
                <AText
                  className={clsx(styles.itemText, itemStyle)}
                  color={
                    isActiveItem ? 'header_accent_color_1' : 'header_txt_2'
                  }
                  breed="R"
                >
                  {t(title)}
                </AText>
              </Box>
            </Box>
          );
        }
      )}
      <Box
        onClick={() => handleMenuClick(POPUP_TYPES.PROFILE_MENU)}
        className={clsx(styles.item, isProfileMenuPopupOpen && activeMenuItem)}
        direction="column"
        justify="center"
        align="center"
      >
        <img
          src={isProfileMenuPopupOpen ? menuItemImageOpen : menuIconActive}
          className={clsx(styles.icon, iconStyle)}
          alt=""
        />
        <AText
          className={clsx(styles.itemText, itemStyle)}
          color={
            isProfileMenuPopupOpen || isHighlightMenu
              ? 'header_accent_color_1'
              : 'header_txt_2'
          }
          breed="R"
        >
          {t(menuItemTitle, 'Menu')}
        </AText>
      </Box>
    </Box>
  ) : null;
};

export default BottomNavigationMenu;
