import { SPORT_IDS } from '@namespace/sports';
import { matchPath } from 'react-router-dom';
import { SportIcon } from '@namespace/icons2';
import { AText, Box, EmptyState } from '@alf/uikit';
import { useI18n, useLocation } from '@namespace/i18n';
import { useGeneralRoutes } from '@namespace/cms';
import ResultsDetails from './components/ResultsDetails';
import VirtualSportResultDetails from './components/VirtualSportResultDetails';
import styles from './index.module.css';

const List = ({
  events,
  shouldShowSport = false,
  isShowCategoryName = false,
  listClassNames = {}
}) => {
  const {
    column1Class = '',
    column2Class = '',
    eventDetailsClass = '',
    wrapperClass = ''
  } = listClassNames;
  const { t } = useI18n();
  const { results } = useGeneralRoutes();
  const { pathname } = useLocation();
  const matchedRoutes = matchPath({ path: `${results}/` }, pathname);
  if (!matchedRoutes) {
    return <EmptyState text={t('sportLine.emptyState')} />;
  }
  return (
    <Box
      data-role="results-event-list-of-results"
      direction="column"
      className={`${styles.scrollWrapper} ${wrapperClass}`}
    >
      {events.map((eventData) => {
        const {
          eventName,
          sportName,
          eventId,
          totalResult,
          sportId,
          tournamentTitle,
          totalResultJson,
          eventDt,
          categoryName,
          eventStatusDescName
        } = eventData;

        return (
          <Box
            data-role="results-event"
            className={styles.eventResult}
            key={`${events.length}${eventId}-${sportId}`}
          >
            <Box className={styles.eventInfo}>
              <Box
                align="center"
                className={`${styles.column} ${styles.column1} ${column1Class}`}
              >
                <AText
                  data-role="results-event-date"
                  className={styles.eventResultDateText}
                  breed="B"
                  color="line_txt_1"
                >
                  {eventDt}
                </AText>
              </Box>
              {shouldShowSport && (
                <Box
                  className={`${styles.column} ${styles.column2} ${column2Class}`}
                >
                  <Box align="center" className={styles.sport}>
                    <SportIcon
                      data-role={`results-sport-icon-${sportId}`}
                      sportId={sportId}
                      size="s"
                      className={styles.sportIcon}
                    />
                    <AText
                      data-role="results-sport-name"
                      breed="BU"
                      color="line_txt_1"
                      size="m"
                      className={styles.sportName}
                    >
                      {sportName}
                    </AText>
                  </Box>
                </Box>
              )}
            </Box>
            <Box
              padding="s"
              direction="column"
              justify="center"
              className={`${styles.eventDetails} ${eventDetailsClass}`}
            >
              <Box align="start">
                <AText
                  data-role="results-category-name"
                  breed="B"
                  size="s"
                  color="line_txt_1"
                >
                  {isShowCategoryName
                    ? `${categoryName} ${tournamentTitle}`
                    : tournamentTitle}
                </AText>
              </Box>
              <Box
                data-role="results-event-name"
                className={styles.eventName}
                direction="column"
              >
                <AText breed="R" size="s" color="line_txt_1">
                  {eventName}
                </AText>
                <AText breed="R" size="s" color="line_txt_1">
                  {eventStatusDescName}
                </AText>
              </Box>
              {sportId === SPORT_IDS.VIRTUAL_SPORTS ? (
                <VirtualSportResultDetails
                  totalResult={totalResult}
                  totalResultJson={totalResultJson}
                />
              ) : (
                <ResultsDetails eventData={eventData} />
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default List;
