import { prop } from 'ramda';
import { shallow } from 'zustand/shallow';
import { createSelector } from 'reselect';
import { useSportsbookSelector } from '../../../../../../../store';

export const selectIsSummariesEvents = (groupedEvents = []) =>
  createSelector(prop('events'), (events) =>
    groupedEvents
      .map((id) => events[id])
      .some(
        (event) =>
          Boolean(event.categoryIsSummaries) ||
          Boolean(event.tournamentIsSummaries)
      )
  );

export const useIsSummariesEvents = (groupedEvents) =>
  useSportsbookSelector(selectIsSummariesEvents, [groupedEvents], shallow);
