import { isEmpty } from 'lodash';
import { setCookie, window } from '@namespace/helpers';
import { logNewRelicAction, LOG_ACTIONS } from '@namespace/analytics';
import { types } from '../types';
import { AB_TEST_COOKIE } from '../../constants';
import getABTestsMapFromCookie from '../../utils/getABTestsMapFromCookie';
import getABTestsMap from '../../utils/getABTestsMap';
import { getSegmentsList } from '../../api/getSegmentsList';

const { SET_AB_TEST_MAP, FETCH_AB_SEGMENTS } = types;

export default {
  [SET_AB_TEST_MAP]: (dispatch) => (data) => {
    const prevABTestsMap = getABTestsMapFromCookie();
    const { abTestsMap, newTests } = getABTestsMap(data, prevABTestsMap);
    window.AB_TESTS = abTestsMap;
    try {
      //                                             (test name): (variant)
      // example cookie: AB_TEST_COOKIE: '{"test-betslip-button":"variant-b"}'
      setCookie(AB_TEST_COOKIE, JSON.stringify(abTestsMap), { expires: 365 });
    } catch (e) {
      console.warn(e);
    }
    dispatch({
      type: SET_AB_TEST_MAP,
      payload: abTestsMap
    });

    if (!isEmpty(newTests)) {
      logNewRelicAction(LOG_ACTIONS.INIT_AB_TEST, newTests, {
        sendAB: false
      });
    }
  },
  [FETCH_AB_SEGMENTS]: (dispatch) => async () => {
    const fetchedABSegments = await getSegmentsList();
    const abSegments = fetchedABSegments?.segments;

    dispatch({
      type: FETCH_AB_SEGMENTS,
      payload: { abSegments, isSegmentsLoaded: true }
    });

    return abSegments;
  }
};
