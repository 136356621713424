export default `
<div class='contentWrapper'>
   <div class='managerWrapper'>
      <div class='managerName'>LEBAP</div>
        <a href="tel:99363333078" class='phoneWrapper'>
           <img class="phoneIcon" src="/snippets-media/footer/call.svg" alt=""/>
           <span>+99363333078</span>
        </a>
        <a href="tel:99363748053" class='phoneWrapper'>
           <img class="phoneIcon" src="/snippets-media/footer/call.svg" alt=""/>
           <span>+99363748053</span>
        </a>
        <a href="tel:99365243441" class='phoneWrapper'>
           <img class="phoneIcon" src="/snippets-media/footer/call.svg" alt=""/>
           <span>+99365243441</span>
        </a>
   </div>
</div>
<style>
.contentWrapper{display: flex; justify-content: center; width: 100%;}
.phoneWrapper{display: flex; align-items: center; margin-bottom: 4px;}
.managerName {text-align: center;}
.managerWrapper{margin-left: 8px; line-height: 20px; display: flex; flex-direction: column; padding-top: 8px; margin-bottom: -12px;}
.secondManagerWrapper{line-height: 20px; display: flex; flex-direction: column;}
.phoneIcon{margin-right: 8px;}
</style>
`;
