import PropTypes from 'prop-types';

const TableRow = ({ children, className = '', ...props }) => (
  <tr className={className} {...props}>
    {children}
  </tr>
);

TableRow.propTypes = {
  children: PropTypes.node.isRequired
};

export default TableRow;
