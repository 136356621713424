import { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { COLOR_SCHEME } from '@namespace/themes';
import Checkbox from '../../../../../../../Checkbox';
import styles from './index.module.css';

export const ContentRow = ({
  value,
  title,
  iconElement,
  isActive,
  disabled,
  onChange,
  size,
  intentType,
  showCheckbox = true,
  className,
  dataRole
}) => {
  const onCheckboxChange = useCallback(
    (checked) => onChange?.(value, checked),
    [onChange, value]
  );
  const intentTypeSecondary = intentType === COLOR_SCHEME.SECONDARY;

  const classNames = {
    checkboxClassName: clsx(
      intentTypeSecondary
        ? styles.checkboxClassNameSecondary
        : styles.checkboxClassName,
      !showCheckbox && styles.hideCheckbox
    ),
    checkedClassName: intentTypeSecondary
      ? styles.checkedClassNameSecondary
      : styles.checkedClassName,
    labelClassName: intentTypeSecondary
      ? styles.labelClassNameSecondary
      : styles.labelClassName
  };
  return (
    <Checkbox
      data-role={dataRole && `${dataRole}-${value}`}
      key={`${title}-${value}`}
      label={title}
      checked={isActive}
      iconElement={iconElement}
      size={size === 'xs' ? 's' : size}
      disabled={disabled}
      onChange={onCheckboxChange}
      className={clsx(
        styles.option,
        styles[size],
        styles[intentType],
        className
      )}
      truncateText={true}
      classNames={classNames}
    />
  );
};

ContentRow.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string,
  isActive: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['xs', 's', 'm']),
  intentType: PropTypes.oneOf([COLOR_SCHEME.PRIMARY, COLOR_SCHEME.SECONDARY])
};
