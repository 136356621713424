import { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@namespace/icons2';
import Box from '../../../Box';
import styles from './index.module.css';

const Input = ({
  value,
  onChange,
  isAutofocus = false,
  isExpanded = true,
  onExpand,
  placeholder,
  setIsActive,
  disabled = false,
  dataRole
}) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(isAutofocus);

  const handleExpand = useCallback(() => {
    onExpand(!isExpanded);
    setIsFocused(!isExpanded);
    if (!isExpanded && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [isExpanded, onExpand, setIsFocused]);
  const handleDelete = useCallback(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
    onChange('');
  }, [inputRef, onChange]);
  const handleFocus = useCallback(() => {
    setIsActive(true);
    setIsFocused(true);
  }, [setIsFocused, setIsActive]);
  const handleBlur = useCallback(() => {
    setIsActive(false);
    if (!value) {
      return handleExpand();
    }
    return setIsFocused(false);
  }, [setIsFocused, handleExpand, value, setIsActive]);

  return (
    <Box
      align="center"
      padding="s m"
      className={`${styles.wrapper} ${isFocused ? styles.focus : ''}`}
    >
      <Box
        align="center"
        justify="center"
        className={styles.searchIconWrap}
        onClick={handleExpand}
      >
        <Icon
          name="icons/general/action/search"
          size="m"
          className={styles.icon}
        />
      </Box>
      {isExpanded && (
        <>
          <input
            ref={inputRef}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={isFocused}
            className={styles.input}
            placeholder={placeholder}
            disabled={disabled}
            data-role={dataRole}
          />
          {Boolean(value) && (
            <Box
              align="center"
              justify="center"
              onClick={handleDelete}
              className={styles.deleteIconWrap}
            >
              <Icon
                name="icons/general/action/close"
                size="s"
                className={styles.icon}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

Input.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  isAutofocus: PropTypes.bool,
  isExpanded: PropTypes.bool,
  onExpand: PropTypes.func,
  placeholder: PropTypes.string,
  setIsActive: PropTypes.func,
  disabled: PropTypes.bool
};

export default Input;
