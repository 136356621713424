import { AText, Button } from '@alf/uikit';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { useGeneralRoutes } from '@namespace/cms';
import styles from './index.module.css';

const Confirmation = ({ onCancel, isCancelFetching }) => {
  const { t } = useI18n();
  const { wallets } = useGeneralRoutes();

  return (
    <>
      <AText color="po_txt_color_1" size="m" breed="B" align="center">
        {t('withdrawBonusConfirmation.text_1')}
      </AText>
      <AText
        color="po_txt_color_1"
        size="m"
        breed="R"
        align="center"
        className={styles.text}
      >
        {t('withdrawBonusConfirmation.text_2')}
      </AText>

      <AText
        color="po_txt_color_1"
        size="m"
        breed="R"
        align="center"
        className={styles.text}
      >
        {t('withdrawBonusConfirmation.text_3')}
      </AText>

      <AText
        color="po_txt_color_1"
        size="m"
        breed="B"
        align="center"
        className={styles.text}
      >
        {t('withdrawBonusConfirmation.text_4')}
      </AText>
      <Button
        size="l"
        fullWidth={true}
        component={LocalizedLink}
        onClick={onCancel}
        className={styles.buttonConfirm}
        processing={isCancelFetching}
      >
        {t('withdrawBonusConfirmation.continue')}
      </Button>
      <Button
        size="l"
        fullWidth={true}
        component={LocalizedLink}
        to={wallets}
        intent="quaternary"
        disabled={isCancelFetching}
      >
        {t('withdrawBonusConfirmation.return')}
      </Button>
    </>
  );
};

export default Confirmation;
