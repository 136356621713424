import { PROMO_TYPES } from '../../constants';
import CommonDateTime from './components/CommonDateTime';
import ScheduleDateTime from './components/ScheduleDateTime';

const DateTime = ({
  promoData = {},
  showTimerInsteadPeriod,
  isRecentlyPromo = false,
  isPromoItem = false,
  isPromoPage = false,
  dateTextColor,
  dateTextSize,
  className,
  activePromoType = PROMO_TYPES.promotion
}) => {
  const { setSchedule } = promoData;

  return setSchedule ? (
    <ScheduleDateTime
      promoData={promoData}
      showTimerInsteadPeriod={showTimerInsteadPeriod}
      isRecentlyPromo={isRecentlyPromo}
      isPromoItem={isPromoItem}
      isPromoPage={isPromoPage}
      dateTextColor={dateTextColor}
      dateTextSize={dateTextSize}
      className={className}
      activePromoType={activePromoType}
    />
  ) : (
    <CommonDateTime
      promoData={promoData}
      showTimerInsteadPeriod={showTimerInsteadPeriod}
      isRecentlyPromo={isRecentlyPromo}
      isPromoItem={isPromoItem}
      isPromoPage={isPromoPage}
      dateTextColor={dateTextColor}
      dateTextSize={dateTextSize}
      className={className}
      activePromoType={activePromoType}
    />
  );
};

export default DateTime;
