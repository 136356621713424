import { isEqual } from 'lodash';

const compareValidator = (
  compareWith,
  message = 'validators.compareValidator',
  inverse = false
) => ({
  validate: (fieldValue) => {
    const equal = isEqual(fieldValue, compareWith());

    return (inverse ? !equal : equal) || message;
  }
});

export default compareValidator;
