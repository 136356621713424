import { useContext, useEffect, useState } from 'react';
import { UserContext } from '@namespace/user/src/context';
import { getCountryById } from '@namespace/helpers';
import { ReferenceContext } from '@namespace/common/src/store/reference/context';
import { useI18n } from '@namespace/i18n';
import { AText, Box, Input, Label } from '@alf/uikit';
import { Flag } from '@namespace/icons2';
import styles from './index.module.css';

const UserCountry = ({ name = '', classNames = {}, label, disabled }) => {
  const { className = '', labelClassName = '' } = classNames;
  const [user] = useContext(UserContext);
  const { countryId } = user;
  const { t } = useI18n();
  const { countries } = useContext(ReferenceContext);
  const [userCountry, setUserCountry] = useState('');

  useEffect(() => {
    if (countries?.length) {
      const { countryName = '' } = getCountryById(countries, countryId) || {};
      setUserCountry(countryName);
    }
  }, [countries, countryId]);

  return (
    <Box className={`${styles.wrapper} ${className}`} direction="column">
      {label && (
        <Label
          data-role="user-country-lable"
          htmlFor={name}
          className={`${styles.label} ${labelClassName}`}
        >
          <AText size="m" color="input_label_txt">
            {t(label)}
          </AText>
        </Label>
      )}
      <Input
        name={name}
        id={name}
        disabled={disabled}
        label={t(label)}
        value={userCountry}
        className={styles.input}
        data-role="user-country-input"
      />
      <Flag className={styles.icon} size="s" iso={countryId} />
    </Box>
  );
};

export default UserCountry;
