import clsx from 'clsx';
import styles from './index.module.css';

export const TopLiveEventsSkelet = ({ items, isShowMore }) =>
  Array(items)
    .fill(1)
    .map((_, i) => (
      <div
        key={/* eslint-disable-line react/no-array-index-key */ i}
        className={clsx(
          styles.skelet,
          styles.skeletImg,
          isShowMore ? styles.skeletDesktop : styles.skeletMobile
        )}
      />
    ));
