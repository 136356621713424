import { useContext } from 'react';
import { AText, Box } from '@alf/uikit';
import { Image } from '@namespace/icons2';
import { DeviceContext } from '@namespace/device';
import { useI18n } from '@namespace/i18n';
import styles from './index.module.css';

const BonusHunterPromotions = () => {
  const { isMobile } = useContext(DeviceContext);
  const { t } = useI18n();

  return (
    <Box
      className={styles.wrapper}
      direction="column"
      align="center"
      justify="center"
      data-role="promotions-bonus-hunter"
    >
      <Image
        data-role="promotions-bonus-hunter-image"
        name="general/bonus_hunter"
        className={styles.image}
      />
      <AText
        data-role="promotions-bonus-hunter-text"
        size={isMobile ? '2xl' : '3xl'}
        color="txt_color_7"
        breed="BU"
        align="center"
      >
        {t('bonusHunter.unavailableSection')}
      </AText>
    </Box>
  );
};

export default BonusHunterPromotions;
