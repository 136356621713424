import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import {
  emailValidator,
  cpfNumberValidator,
  regExpValidator
} from '@alf/validators';
import InputField from '../../common/InputField';
import EmailInputField from '../../common/EmailInputField';

const EMAIL_FIELD_TYPE = 'EMAIL';

const PixKeyField = ({
  name,
  dependsOnName,
  disabled,
  placeholder,
  rules = {},
  ...props
}) => {
  const fieldProps = {
    CPF: {
      placeholder: 'web_balance_enter_taxID',
      rules: { ...cpfNumberValidator() },
      mask: '999.999.999-99'
    },
    EMAIL: {
      placeholder: 'web_balance_enter_email',
      rules: { ...emailValidator() }
    },
    PHONE: {
      placeholder: 'web_balance_enter_cellPhone',
      rules: { ...regExpValidator('') },
      mask: '+5\\5999999999999999999',
      maskChar: null
    },
    EVP: {
      placeholder: 'web_balance_enter_aleatoryKey',
      rules: { ...regExpValidator('') }
    }
  };
  const [inputProps, setInputProps] = useState({});
  const fieldTypePlaceholder = inputProps?.placeholder || placeholder;
  const fieldTypeRules = inputProps?.rules || {};
  const fieldTypeMask = inputProps?.mask;
  const fieldTypeMaskChar = inputProps?.maskChar;
  const methods = useFormContext();
  const { watch, resetField } = methods;
  const selectedKeyType = watch(dependsOnName);
  useEffect(() => {
    if (selectedKeyType) {
      const currentFieldProps = fieldProps[selectedKeyType] || {};
      resetField('token');
      setInputProps({ ...currentFieldProps });
    }
  }, [resetField, selectedKeyType]);

  const Field =
    selectedKeyType === EMAIL_FIELD_TYPE ? EmailInputField : InputField;

  return (
    <Field
      rules={{ ...fieldTypeRules, ...rules }}
      mask={fieldTypeMask}
      maskChar={fieldTypeMaskChar}
      placeholder={fieldTypePlaceholder}
      disabled={!selectedKeyType}
      name={name}
      {...props}
    />
  );
};

export default PixKeyField;
