import { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDebounce } from '@namespace/helpers';
import Box from '../Box';
import Input from '../Input';
import Select from '../Select';
import styles from './index.module.css';

const CustomTrigger = ({ inputValue, onChange, isOpen, ...props }) => (
  <Input {...props} value={inputValue} onChange={(e) => onChange(e, isOpen)} />
);

const InputSelect = ({
  name,
  size,
  value,
  placeholder,
  onChange = () => {},
  loadAsyncOptions,
  disabled,
  ...props
}) => {
  const { title: valueTitle = '' } = value;
  const [inputValue, changeInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue, 500);

  const [asyncOptions, setAsyncOptions] = useState([]);

  const filteredOptions = useMemo(
    () =>
      asyncOptions.filter(({ title }) =>
        title.toLowerCase().includes(debouncedInputValue.toLowerCase())
      ),
    [debouncedInputValue, asyncOptions]
  );

  const onInputChange = useCallback((e, isOpen) => {
    changeInputValue(e.target.value);
    !isOpen && e.target.click?.();
  }, []);

  const onChangeSelect = useCallback(
    (item) => {
      const selected = filteredOptions.find((o) => o.value === item.value) || {
        value: '',
        title: ''
      };

      onChange(selected);
      changeInputValue(selected.title);
    },
    [filteredOptions]
  );

  const clean = useCallback(() => {
    onChange({ value: '', title: '' });
    changeInputValue('');
  }, []);

  useEffect(() => {
    loadAsyncOptions(debouncedInputValue).then((list = []) =>
      setAsyncOptions(list)
    );
  }, [loadAsyncOptions, debouncedInputValue]);

  useEffect(() => {
    if (valueTitle !== inputValue) {
      changeInputValue(valueTitle);
    }
  }, [inputValue, valueTitle]);

  useEffect(() => {
    if (!value.value && !value.title) {
      changeInputValue('');
      setAsyncOptions([]);
    }
  }, [value]);

  return (
    <Box direction="column" className={`${styles.wrapper} ${styles[size]}`}>
      <Select
        name={name}
        size={size}
        placeholder={placeholder}
        disabled={disabled}
        CustomTrigger={
          <CustomTrigger
            onChange={onInputChange}
            inputValue={inputValue}
            clearable={true}
            onClean={clean}
          />
        }
        {...props}
        onChange={onChangeSelect}
        options={filteredOptions}
        isDesktop={true}
      />
    </Box>
  );
};

InputSelect.propTypes = {
  size: PropTypes.oneOf(['s', 'm']),
  onChange: PropTypes.func,
  value: PropTypes.object,
  setInputValue: PropTypes.func,
  placeholder: PropTypes.string,
  onClean: PropTypes.func
};

InputSelect.defaultProps = {
  size: 's',
  value: { value: '', title: '' }
};

export default InputSelect;
