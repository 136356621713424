import { useRef } from 'react';
import { AText, Box, Button, Overlay } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { window } from '@namespace/helpers';
import { useFixScroll } from '@namespace/common';
import styles from './index.module.css';

export const DisconnectedPopup = ({ setIsDisconnected }) => {
  const { t } = useI18n();
  const rootRef = useRef();

  useFixScroll('DisconnectedPopup', rootRef, true);

  const onReloadClick = () => {
    window.location.reload();
  };

  const onCancelClick = () => {
    setIsDisconnected(false);
  };

  return (
    <Overlay classNames={{ wrapperClass: styles.overlay }}>
      <Box direction="column" className={styles.root} ref={rootRef}>
        <Box padding="0 ms" align="center" className={styles.header}>
          <AText breed="B" size="l" color="txt_color_4">
            {t('app.disconnectedPopup.title')}
          </AText>
        </Box>
        <Box
          direction="column"
          gap="ms"
          padding="ms"
          className={styles.content}
        >
          <AText
            breed="R"
            size="m"
            color="txt_color_2"
            className={styles.description}
          >
            {t('app.disconnectedPopup.description')}
          </AText>
          <Box direction="column" gap="s">
            <Button
              actionType="color1"
              intent="primary"
              size="l"
              onClick={onReloadClick}
            >
              {t('app.disconnectedPopup.reload')}
            </Button>
            <Button
              actionType="color1"
              intent="quaternary"
              size="l"
              onClick={onCancelClick}
            >
              {t('app.disconnectedPopup.cancel')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Overlay>
  );
};
