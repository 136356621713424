const mergeProperties = (prevProps, props) => {
  const newProps = { ...prevProps };

  for (const [key, item] of Object.entries(props)) {
    if (key === 'styles' && newProps.styles) {
      newProps.styles = { ...item, ...newProps.styles };
    } else if (!prevProps[key]) {
      newProps[key] = item;
    }
  }

  return newProps;
};

const merge = (prevProps, { dynamic, ...otherProps }) => {
  let newProps = { ...prevProps };

  if (typeof dynamic === 'function') {
    newProps = mergeProperties(newProps, dynamic(newProps));
  }

  newProps = mergeProperties(newProps, otherProps);

  return newProps;
};

export const propsMerge = (props, config) => {
  if (!config) {
    return props || {};
  }

  const { intents, ...rootProps } = config;

  let newProps = { ...props };

  const intent = intents?.[props.intent];
  if (intent) {
    newProps = merge(newProps, intent);
  }

  newProps = merge(newProps, rootProps);

  return newProps;
};
