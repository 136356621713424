import { useMemo } from 'react';
import { useMatchPath, useSearchParams } from '@namespace/router';
import { isString } from 'lodash';
import { useLocation } from '@namespace/i18n';

const parseParams = (param = []) =>
  isString(param) ? param.split(',') : param;

export const useGetFilterParams = (route) => {
  const paths = [`${route}/:sportNames`];

  const { sportNames } = useMatchPath(paths);
  const { pathname } = useLocation();

  const {
    categories: searchCategories = [],
    tournaments: searchTournaments = []
  } = useSearchParams();

  const tournamentIds = useMemo(() => searchTournaments.map(Number), [
    searchTournaments
  ]);
  const categoryIds = useMemo(() => searchCategories.map(Number), [
    searchCategories
  ]);
  const sportSlugs = useMemo(() => parseParams(sportNames), [sportNames]);

  return {
    sportSlugs,
    categoryIds,
    tournamentIds,
    isValidPath: pathname.includes(route)
  };
};
