import { useState, useContext, useCallback } from 'react';
import { DeviceContext } from '@namespace/device';
import { InfoMessage, Overlay, Box } from '@alf/uikit';
import { CommonLoader } from '@namespace/common';
import { useNotifications } from '@namespace/notifications';
import {
  FORM_COMPONENTS_TYPES,
  FORM_VALIDATION_TYPES,
  FormBuilder
} from '@namespace/forms';
import {
  UserContext,
  useUpdatePhoneNumber,
  useUpdateUser,
  MIN_DATE_OFFSET,
  MAX_DATE_OFFSET
} from '@namespace/user';
import { useI18n } from '@namespace/i18n';
import { ProfileDataInfo } from '@namespace/account';
import styles from './index.module.css';

const ProfileData = () => {
  const { t, f } = useI18n();
  const { successNotification, errorNotification } = useNotifications();
  const [isFetching, setIsFetching] = useState(false);
  const { isDesktop } = useContext(DeviceContext);
  const [user, userActions] = useContext(UserContext);
  const DATE_FORMATS_TYPES = f.getFormats();
  const {
    countryId,
    title,
    dateOfBirth,
    email,
    phoneNumber,
    isDefaultRegistratedUser,
    question = ''
  } = user;
  const { SET_PHONE, SET_USER } = userActions;

  const defaultValues = {
    userCountry: '',
    sex: title === 'mrs' ? 'ms' : title || '',
    birthday: isDefaultRegistratedUser
      ? ''
      : f.fromMillis(dateOfBirth, DATE_FORMATS_TYPES.CLIENT_API),
    email,
    phone: phoneNumber,
    firstName: '',
    lastName: '',
    middleName: '',
    secretQuestionAnswer: '',
    secretQuestion: isDefaultRegistratedUser ? '' : question
  };

  const minDateOffset = MIN_DATE_OFFSET;
  const maxDateOffset = MAX_DATE_OFFSET;

  const registeredComponentsList = [
    {
      type: FORM_COMPONENTS_TYPES.USER_COUNTRY_FIELD,
      name: 'userCountry',
      label: 'personalOffice.profile.data.country',
      classNames: {
        className: styles.fieldWrap
      },
      disabled: true
    },
    {
      type: FORM_COMPONENTS_TYPES.INPUT_FIELD,
      name: 'email',
      label: 'personalOffice.profile.data.email',
      classNames: {
        wrapperClassName: styles.fieldWrap
      },
      disabled: true
    },
    {
      type: FORM_COMPONENTS_TYPES.REG_DATE_PICKER,
      name: 'birthday',
      label: 'personalOffice.profile.data.birthday',
      classNames: {
        wrapperClassName: styles.fieldWrap
      },
      disabled: true
    },
    {
      type: FORM_COMPONENTS_TYPES.RADIO_GROUP,
      name: 'sex',
      disabled: true,
      elements: [
        {
          label: 'personalOffice.profile.data.male',
          value: 'mr'
        },
        {
          label: 'personalOffice.profile.data.female',
          value: 'ms'
        }
      ],
      classNames: {
        wrapperClassName: styles.radioGroup
      }
    },
    {
      type: FORM_COMPONENTS_TYPES.HR_LINE,
      key: 'hr-line-1',
      className: `${styles.fieldWrap} ${styles.hr}`
    },
    {
      type: FORM_COMPONENTS_TYPES.INPUT_FIELD,
      label: 'personalOffice.profile.data.phone',
      name: 'phone',
      placeholder: phoneNumber,
      validationTypes: [
        FORM_VALIDATION_TYPES.REQUIRED,
        FORM_VALIDATION_TYPES.PHONE_NUMBER
      ],
      classNames: {
        wrapperClassName: styles.fieldWrap,
        inputClassName: styles.phone
      },
      isOnChangeValidation: true
    },
    {
      type: FORM_COMPONENTS_TYPES.SUBMIT_BUTTON,
      value: 'personalOffice.profile.data.save',
      classNames: {
        className: styles.saveBtn
      },
      disabled: isFetching
    }
  ];

  const shortRegisterComponentsList = [
    {
      type: FORM_COMPONENTS_TYPES.USER_COUNTRY_FIELD,
      name: 'userCountry',
      label: 'personalOffice.profile.data.country',
      classNames: {
        className: styles.fieldWrap
      },
      disabled: true
    },
    {
      type: FORM_COMPONENTS_TYPES.INPUT_FIELD,
      name: 'email',
      label: 'personalOffice.profile.data.email',
      classNames: {
        wrapperClassName: styles.fieldWrap
      },
      disabled: true
    },
    {
      type: FORM_COMPONENTS_TYPES.INPUT_FIELD,
      name: 'phone',
      label: 'personalOffice.profile.data.phone',
      placeholder: phoneNumber,
      classNames: {
        wrapperClassName: styles.fieldWrap,
        inputClassName: styles.phone
      },
      disabled: true
    },
    {
      type: FORM_COMPONENTS_TYPES.HR_LINE,
      key: 'hr-line-2',
      className: `${styles.fieldWrap} ${styles.hr}`
    },
    {
      type: FORM_COMPONENTS_TYPES.NAME_INPUT,
      name: 'firstName',
      label: 'register.steps.personalInfo.enterPersonals',
      placeholder: 'register.steps.personalInfo.firstName',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
      classNames: {
        wrapperClassName: styles.fieldWrap
      },
      countryFieldName: 'userCountry'
    },
    {
      type: FORM_COMPONENTS_TYPES.NAME_INPUT,
      name: 'lastName',
      placeholder: 'register.steps.personalInfo.lastName',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
      classNames: {
        wrapperClassName: styles.fieldWrap
      },
      countryFieldName: 'userCountry'
    },
    {
      type: FORM_COMPONENTS_TYPES.NAME_INPUT,
      name: 'middleName',
      placeholder: 'register.steps.personalInfo.middleName',
      classNames: {
        wrapperClassName: styles.fieldWrap
      },
      countryFieldName: 'userCountry'
    },
    {
      type: FORM_COMPONENTS_TYPES.REG_DATE_PICKER,
      name: 'birthday',
      placeholder: 'personalOffice.profile.data.birthday',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
      classNames: {
        wrapperClassName: styles.fieldWrap
      }
    },
    {
      type: FORM_COMPONENTS_TYPES.RADIO_GROUP,
      name: 'sex',
      elements: [
        {
          label: 'personalOffice.profile.data.male',
          value: 'mr'
        },
        {
          label: 'personalOffice.profile.data.female',
          value: 'ms'
        }
      ],
      classNames: {
        wrapperClassName: styles.radioGroup
      }
    },
    {
      type: FORM_COMPONENTS_TYPES.REG_SECRET_QUESTION_SELECT,
      name: 'secretQuestion',
      label: 'register.steps.secretQuestion.selectQuestion',
      placeholder: 'register.steps.secretQuestion.selectQuestionPlaceholder',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
      classNames: {
        wrapperClassName: styles.select
      }
    },
    {
      type: FORM_COMPONENTS_TYPES.REG_SECRET_ANSWER_INPUT,
      name: 'secretQuestionAnswer',
      label: 'register.steps.secretQuestion.enterAnswer',
      placeholder: 'register.steps.secretQuestion.answer',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
      classNames: {
        wrapperClassName: styles.answerWrapper
      }
    }
  ];

  const componentsList = isDefaultRegistratedUser
    ? shortRegisterComponentsList
    : registeredComponentsList;

  if (!isDesktop) {
    shortRegisterComponentsList.push({
      type: FORM_COMPONENTS_TYPES.INFO_MESSAGE,
      label: <ProfileDataInfo />,
      classNames: {
        wrapperClassName: styles.info
      }
    });
  }
  shortRegisterComponentsList.push({
    type: FORM_COMPONENTS_TYPES.SUBMIT_BUTTON,
    value: 'personalOffice.profile.data.save',
    classNames: {
      className: styles.saveBtn
    },
    disabled: isFetching
  });

  const setUser = useUpdateUser();
  const onShortRegister = useCallback(
    async (data = {}) => {
      setIsFetching(true);

      try {
        const account = await setUser({ ...data, countryId });

        SET_USER({ account });
        successNotification(t('profile.data.profileSaved'));
      } catch ({ errorCode }) {
        errorNotification(t(`accountingMessages.${errorCode}`));
      }

      setIsFetching(false);
    },
    [
      countryId,
      setIsFetching,
      setUser,
      SET_USER,
      successNotification,
      errorNotification,
      t
    ]
  );

  const setPhoneNumber = useUpdatePhoneNumber();
  const onSubmit = useCallback(
    async ({ phone: newPhone }) => {
      setIsFetching(true);

      try {
        SET_PHONE(await setPhoneNumber(newPhone));
        successNotification(t('profile.data.profileSaved'));
      } catch ({ errorCode = 'accounting_error_6' }) {
        errorNotification(t(`accountingMessages.${errorCode}`));
      }

      setIsFetching(false);
    },
    [
      setIsFetching,
      SET_PHONE,
      setPhoneNumber,
      successNotification,
      errorNotification
    ]
  );

  const submit = isDefaultRegistratedUser ? onShortRegister : onSubmit;

  return (
    <Box className={styles.profileData} direction="column" padding="ms">
      {isFetching && (
        <Overlay>
          <CommonLoader />
        </Overlay>
      )}
      <div className={styles.form}>
        <FormBuilder
          components={componentsList}
          formConfig={{ defaultValues }}
          onSubmit={submit}
          formProps={{ minDateOffset, maxDateOffset }}
        />
      </div>
      {isDesktop && isDefaultRegistratedUser && (
        <InfoMessage icon="info" className={styles.info}>
          <ProfileDataInfo />
        </InfoMessage>
      )}
    </Box>
  );
};

export default ProfileData;
