import { rePatterns } from '../../patterns';

const codeValidator = (
  message = 'validators.numberFormatValidator',
  maskChar
) => ({
  validate: (value) => {
    const cleanValue = value.replaceAll(maskChar, '');

    if (cleanValue.length > 5) {
      return `validators.maxLengthValidator {${5}}`;
    }

    if (cleanValue.length < 5) {
      return `validators.minLengthValidator {${5}}`;
    }

    return cleanValue.match(rePatterns.onlyNumbers) ? true : message;
  }
});

export default codeValidator;
