import { useContext } from 'react';
import { isEmpty } from 'lodash';
import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { Image } from '@namespace/icons2';
import { BonusesContext } from '@namespace/bonuses/src/store/context';
import styles from './index.module.css';

const BonusNotification = () => {
  const [{ activeWagerBonus }] = useContext(BonusesContext);
  const { t } = useI18n();

  if (isEmpty(activeWagerBonus)) {
    return null;
  }

  return (
    <Box data-role="withdraw-bonus-notification" align="center" padding="m 0">
      <Image
        data-role="withdraw-bonus-notification-image"
        name="general/bonus_unavailable"
        className={styles.image}
      />
      <Box padding="0 s">
        <AText
          data-role="withdraw-bonus-notification-text"
          color="po_txt_color_3"
          breed="R"
          size="l"
        >
          {t('balance.withdraw.form.bonusNotification')}
        </AText>
      </Box>
    </Box>
  );
};

export default BonusNotification;
