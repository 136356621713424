import { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { AText, Box } from '@alf/uikit';
import { checkIsParamsNegative } from '../../../utils';
import OutcomeWithName from '../../OutcomeWithName';
import {
  SORTED_MARKET_TEMPLATE_IDS,
  SUMMARIES_RESULT_TYPE_IDS,
  TWO_COLUMNS_MARKET_TEMPLATE_VIEW_IDS
} from '../../../constants';
import styles from './index.module.css';

const MarketsTypeSection = ({
  title,
  listOfMarkets = [],
  marketsOutcomes = {},
  lineView,
  eventId = ''
}) => {
  const [markets, setMarkets] = useState([]);

  useEffect(() => {
    setMarkets(
      listOfMarkets[0]?.marketHasParam
        ? listOfMarkets.sort(
            (a, b) =>
              marketsOutcomes[a.outcomeIds[0]].outcomeParam -
              marketsOutcomes[b.outcomeIds[0]].outcomeParam
          )
        : listOfMarkets
    );
  }, [listOfMarkets, marketsOutcomes]);

  return (
    <Box direction="column">
      <Box
        className={`${styles.titleContainer} ${styles[lineView]}`}
        justify="center"
        align="center"
      >
        <AText breed="R" size="m" color="line_txt_4">
          {title}
        </AText>
      </Box>
      <Box wrap={true}>
        {markets.map((market) => {
          const {
            marketId,
            outcomeIds,
            resultTypeId,
            marketTemplateViewId,
            marketTemplateId,
            marketTemplateViewSchema
          } = market;
          const outcomes = outcomeIds
            .map((outcomeId) => marketsOutcomes[outcomeId])
            .filter(Boolean);

          if (marketTemplateViewSchema) {
            outcomes.sort((a, b) => a.outcomeTypeId - b.outcomeTypeId);
          }

          if (
            SUMMARIES_RESULT_TYPE_IDS.includes(resultTypeId) ||
            SORTED_MARKET_TEMPLATE_IDS.includes(marketTemplateId)
          ) {
            outcomes.sort((a, b) => a.outcomeCoef - b.outcomeCoef);
          }

          const outcomesCount = outcomes.length;
          let partialCount = outcomesCount % 3 === 0 ? '33.33333%' : '50%';

          if (
            TWO_COLUMNS_MARKET_TEMPLATE_VIEW_IDS.includes(marketTemplateViewId)
          ) {
            partialCount = '50%';
          }

          const outcomeFieldWidth = outcomesCount === 1 ? '100%' : partialCount;

          return (
            <Box
              key={marketId}
              wrap={true}
              className={`${styles.outcomeWithNameContainer} ${
                lineView ? styles[lineView] : ''
              }`}
            >
              {outcomes.map(({ outcomeId }) => (
                <OutcomeWithName
                  key={outcomeId}
                  market={market}
                  outcomeId={outcomeId}
                  isNegativeParams={checkIsParamsNegative(outcomes)}
                  isShowOutcomeChange={true}
                  lineView={lineView}
                  eventId={eventId}
                  styles={{
                    width: outcomeFieldWidth
                  }}
                />
              ))}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default memo(MarketsTypeSection);

MarketsTypeSection.propTypes = {
  title: PropTypes.string,
  listOfMarkets: PropTypes.array.isRequired,
  marketsOutcomes: PropTypes.object.isRequired,
  lineView: PropTypes.string
};
