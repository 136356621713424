import { forwardRef } from 'react';
import { Box } from '@alf/uikit';
import Categories from '../Categories';
import styles from './index.module.css';

const CategoriesDrawer = forwardRef(
  ({ categoryRoute, serviceId, activeCategoryId, sportSlug }, ref) => (
    <Box className={styles.wrapper}>
      <Categories
        ref={ref}
        categoryRoute={categoryRoute}
        classNames={{ containerClassName: styles.scrollContainer }}
        serviceId={serviceId}
        activeCategoryId={activeCategoryId}
        sportSlug={sportSlug}
      />
    </Box>
  )
);

export default CategoriesDrawer;
