export default {
  SET_STEP: 'SET_STEP',
  SET_FORM_DATA: 'SET_FORM_DATA',
  SET_SUCCESS: 'SET_SUCCESS',
  SET_ERROR: 'SET_ERROR',
  SET_ERROR_CODE: 'SET_ERROR_CODE',
  GO_NEXT_STEP: 'GO_NEXT_STEP',
  GO_PREV_STEP: 'GO_PREV_STEP',
  GO_FIRST_STEP: 'GO_FIRST_STEP',
  GO_TO_STEP: 'GO_TO_STEP'
};
