import { useContext } from 'react';
import { SiteSettingsContext } from '@namespace/cms';
import { useWithdrawTax } from '../../../../hooks/useWithdrawTax';
import CommonTax from './components/CommonTax';
import UATax from './components/UATax';

const Tax = (data) => {
  const [settings] = useContext(SiteSettingsContext);
  const { siteConfig = {} } = settings;
  const { taxType = null } = siteConfig;
  const { tax, taxes, returnAmount } = useWithdrawTax(data);
  return taxType === null ? (
    <CommonTax tax={tax} returnAmount={returnAmount} />
  ) : (
    <UATax taxes={taxes} returnAmount={returnAmount} />
  );
};

export default Tax;
