import { useState } from 'react';
import { Box } from '@alf/uikit';
import styles from './index.module.css';
import QRCode from './QRCode';
import TrxInfo from './TrxInfo';

const QRCodeDepositResult = ({ formData, operationId }) => {
  const [qrCodeView, setQRCodeView] = useState(true);
  const showTransactionInfo = () => setQRCodeView(false);

  return (
    <Box justify="center" top="l">
      <Box direction="column" align="center" className={styles.wrapper}>
        {qrCodeView ? (
          <QRCode
            formData={formData}
            showTransactionInfo={showTransactionInfo}
          />
        ) : (
          <TrxInfo operationId={operationId} />
        )}
      </Box>
    </Box>
  );
};

export default QRCodeDepositResult;
