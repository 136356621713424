import { window, document } from '@namespace/helpers';

const copyTextFallback = async (text) => {
  // Create text node. Don't use textarea since it makes keyboard to pop on mobile devices.
  const textNode = document.createElement('div');
  textNode.textContent = text;
  textNode.style.position = 'fixed';
  document.body.appendChild(textNode);

  const selection = window.getSelection();
  const range = document.createRange();
  range.selectNodeContents(textNode);
  selection.removeAllRanges();
  selection.addRange(range);

  try {
    if (document.execCommand('copy')) {
      return;
    }

    throw new Error('copy unsuccessful');
  } finally {
    document.body.removeChild(textNode);
  }
};

/**
 * Copy Text To Clipboard
 * @param {String} text
 * @returns {Promise<void>}
 */
export const copyTextToClipboard = async (text) => {
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text);
  }

  return copyTextFallback(text);
};
