import { useDidUpdateEffect } from '@namespace/common';

import {
  DOCUMENT_NUMBER_FILED_NAME,
  DOCUMENT_PLACE_DATE,
  DOCUMENT_EXPIRE_DATE,
  DOCUMENT_PLACE
} from '../../constants';

const useDocumentDataReset = ({ setValue, clearErrors, documentTypeId }) => {
  useDidUpdateEffect(() => {
    const documentTypeRelatedFieldNames = [
      DOCUMENT_NUMBER_FILED_NAME,
      DOCUMENT_PLACE_DATE,
      DOCUMENT_EXPIRE_DATE,
      DOCUMENT_PLACE
    ];

    for (const fieldName of documentTypeRelatedFieldNames) {
      setValue(fieldName, '');
    }

    clearErrors(documentTypeRelatedFieldNames);
  }, [documentTypeId, setValue, clearErrors]);
};

export default useDocumentDataReset;
