import { Accordion } from '@alf/uikit';
import EventBody from '../EventBody';
import ButtonLoader from '../../../../Pagination/ButtonLoader';
import EventTitle from '../EventTitle';
import { useIsSummariesEvents } from './selectors/IsSummariesEventsByGroup';
import styles from './index.module.css';

const EventsContainer = ({
  index,
  events = [],
  isShowEventGroup,
  isShowFlag = true,
  activeHeadGroups = [],
  isCollapsibleTournaments,
  lineKey,
  isShowStatUrl,
  isShowVideo,
  isDisplayHeadMarkets,
  eventsLimitByTournament,
  lineView,
  isLiveEvents,
  isDisplayAllEvents,
  setIsDisplayAllEvents
}) => {
  // if *IsSummaries is true - don't show headmarkets for this events
  const isSummaries = useIsSummariesEvents(events);
  let limitedEvents = [...events];
  if (eventsLimitByTournament && !isDisplayAllEvents) {
    limitedEvents = limitedEvents.slice(0, eventsLimitByTournament);
  }

  return (
    <Accordion
      title={
        <EventTitle
          index={index}
          isShowFlag={isShowFlag}
          eventId={events[0]}
          activeHeadGroups={activeHeadGroups}
          isDisplayHeadMarkets={isDisplayHeadMarkets && !isSummaries}
        />
      }
      isOpenDefault={true}
      isCollapsible={isCollapsibleTournaments}
      classNames={{
        headerClassName: styles.header,
        openAccordion: styles.open
      }}
    >
      {limitedEvents.map((eventId) => {
        return (
          <EventBody
            isShowVideo={isShowVideo}
            key={`event_body_${eventId}`}
            headGroups={activeHeadGroups}
            isShowEventGroup={isShowEventGroup}
            eventId={eventId}
            lineKey={lineKey}
            isShowStatUrl={isShowStatUrl}
            isDisplayHeadMarkets={isDisplayHeadMarkets && !isSummaries}
            lineView={lineView}
            isLiveEvents={isLiveEvents}
          />
        );
      })}
      <ButtonLoader
        onNextPage={() => setIsDisplayAllEvents(!isDisplayAllEvents)}
        isShow={eventsLimitByTournament < events.length}
        isLoading={false}
        title={
          isDisplayAllEvents
            ? 'sportsbook.events.collapse'
            : 'sportsbook.events.showAll'
        }
      />
    </Accordion>
  );
};

export default EventsContainer;
