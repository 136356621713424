import { useContext } from 'react';
import clsx from 'clsx';
import { Box } from '@alf/uikit';
import { PageContext } from '@namespace/cms';
import { ColumnContent } from './ColumnContent';
import { SCROLLABLE_COLUMN } from '../../../../constants';
import styles from '../index.module.css';

export const MultiColumnContent = ({
  columns,
  columnClassName,
  scrollableColumns
}) => {
  const { page } = useContext(PageContext);
  const { layout = {}, components = {} } = page;
  const { type = '', columnsWithIndependentScroll } = layout;
  const scrollColumns = scrollableColumns || columnsWithIndependentScroll;

  return columns.map((column, index) => {
    const { pageConfig = {} } = components[column] || {};
    const { backgroundColor } = pageConfig;
    const isScrollable = scrollColumns?.[index];
    const columnCls = clsx(
      columnClassName,
      isScrollable && `${styles.scrollableColumn} ${SCROLLABLE_COLUMN}`
    );
    const key = `${type}-${index}`;

    return (
      <Box
        key={key}
        className={columnCls}
        direction="column"
        style={{ backgroundColor }}
      >
        <ColumnContent column={column} isScrollable={isScrollable} />
      </Box>
    );
  });
};
