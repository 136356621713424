const compare = (currentValue) => ({ value }) =>
  String(currentValue) === String(value);

export const eventHandlerWrapper = (cb, options) => (e) => {
  const currentValue = e.target.value;
  // in case of selected option should be handled as a button then there is no value
  // defined for this option
  const selectedOption = options.find(compare(currentValue));
  const value = selectedOption ? currentValue : null;

  return cb({ value });
};
