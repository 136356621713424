import { useContext } from 'react';
import { Tooltip } from '../Tooltip';
import { InterruptTooltip } from '../InterruptTooltip';
import { useCurrentStep } from '../../hooks';
import { OnboardingContext } from '../../store/context';
import { Loader } from '../Loader';
import { ElProvider } from './store/ElProvider';
import { SpotlightOverlay } from './components/SpotlightOverlay';
import { Fixer } from './components/Fixer';
import { Floater } from './components/Floater';
import { DomEventWall } from './components/DomEventWall';
import { UserActionInterceptorDesktop } from './components/UserActionInterceptor/components/UserActionInterceptorDesktop';

export const Step = () => {
  const {
    target,
    allowedPlacements,
    preferredPlacements: stepPreferredPlacements,
    tooltipAlignment
  } = useCurrentStep();
  const [
    {
      isLoading,
      preferredPlacements: globalPreferredPlacements,
      tours,
      tourIndex,
      isInterrupted
    }
  ] = useContext(OnboardingContext);
  const { preferredPlacements: tourPreferredPlacements } = tours[tourIndex];
  const preferredPlacements =
    stepPreferredPlacements ??
    tourPreferredPlacements ??
    globalPreferredPlacements;

  const CurrentTooltip = isInterrupted ? InterruptTooltip : Tooltip;
  const Content = isLoading ? Loader : CurrentTooltip;
  const ContentPositioner =
    target && !isLoading && !isInterrupted ? Floater : Fixer;

  const stepInner = (
    <>
      <UserActionInterceptorDesktop />
      <SpotlightOverlay />
      <ContentPositioner
        allowedPlacements={allowedPlacements}
        preferredPlacements={preferredPlacements}
        tooltipAlignment={tooltipAlignment}
      >
        <Content />
      </ContentPositioner>
    </>
  );

  if (isLoading || !target) {
    return <DomEventWall>{stepInner}</DomEventWall>;
  }

  return (
    <DomEventWall>
      <ElProvider>{stepInner}</ElProvider>
    </DomEventWall>
  );
};
