import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        width: '100%'
      }
    },
    MuiInputBase: {
      root: {
        'border': 'var(--b-border-width-xs) solid var(--input_border_color_1)',
        'borderRadius':
          'var(--b-border-radius-custom, var(--b-border-radius-xs))',
        '&.Mui-focused': {
          borderBottom:
            'var(--b-border-width-s) solid var(--input_border_focus_1)'
        },
        '&.Mui-error': {
          borderBottom:
            'var(--b-border-width-s) solid var(--input_border_error_1)'
        }
      },
      input: {
        '&:-webkit-autofill': {
          WebkitBoxShadow:
            '0 0 0 50px var(--input_bg_color_1) inset !important',
          WebkitTextFillColor: 'var(--input_txt) !important'
        },
        '&:-webkit-autofill:hover': {
          WebkitBoxShadow:
            '0 0 0 50px var(--input_bg_color_1) inset !important',
          WebkitTextFillColor: 'var(--input_txt) !important'
        },
        '&:-webkit-autofill:focus': {
          WebkitBoxShadow:
            '0 0 0 50px var(--input_bg_color_1) inset !important',
          WebkitTextFillColor: 'var(--input_txt) !important'
        },
        '&:-webkit-autofill:active': {
          WebkitBoxShadow:
            '0 0 0 50px var(--input_bg_color_1) inset !important',
          WebkitTextFillColor: 'var(--input_txt) !important'
        }
      }
    },
    MuiInput: {
      underline: {
        '&:before': {
          display: 'none'
        },
        '&:after': {
          display: 'none'
        },
        'paddingLeft': '8px',
        'paddingTop': '0px',
        'paddingBottom': '0px',
        'fontFamily': 'var(--b-font-family-base)',
        'fontWeight': 'var(--b-font-weight-regular)',
        'lineHeight': 'var(--b-line-height-normal)'
      }
    },
    MuiInputAdornment: {
      positionEnd: {
        marginLeft: '0'
      },
      root: {
        display: 'none'
      }
    },
    MuiFormHelperText: {
      root: { display: 'none' }
    }
  }
});
