import { useCallback, useContext, useEffect, useState } from 'react';
import { Box } from '@alf/uikit';
import { PageContext } from '@namespace/cms';
import Header from './Header';
import Messages from './Messages';
import Input from './Input';
import { useChat } from '../../hooks';
import {
  CHAT_ERRORS,
  FILES_MAX_COUNT,
  TYPING_END_TIMEOUT
} from '../../constants';

import styles from './index.module.css';

const Chat = ({ toggleChat, socketUrl, userInfo }) => {
  const [value, setValue] = useState('');
  const [files, setFiles] = useState([]);
  const [userTyping, setUserTyping] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [error, setError] = useState(null);
  const { page } = useContext(PageContext);
  const { pageConfig = {} } = page;
  const { isHeaderVisible = true } = pageConfig;

  const { typing: agentsTyping, messages, actions, isLoading } = useChat(
    socketUrl,
    userInfo
  );

  const handleMaxCountExceed = () => {
    setError({ type: CHAT_ERRORS.FILE_MAX_COUNT_EXCEEDED });
  };

  const handleSend = useCallback(() => {
    if (value?.length) {
      actions.message(value);
      setValue('');
      clearTimeout(typingTimeout);
    }
    if (files?.length) {
      for (const file of files) {
        const reader = new FileReader();
        const { name, size, type } = file;

        reader.readAsArrayBuffer(file);
        reader.onload = () => {
          actions.fileMeta(name, type, size);
          actions.file(reader.result);
        };

        reader.onerror = () => {
          console.log(reader.error);
        };
      }
      setFiles([]);
      clearTimeout(typingTimeout);
    }
    setUserTyping(false);
    clearTimeout(typingTimeout);
  }, [files, value, isLoading]);

  const stopTyping = useCallback(() => {
    setUserTyping(false);
    actions.typing(false);
    clearTimeout(typingTimeout);
  }, [setUserTyping, actions, typingTimeout, isLoading]);

  const handleKeyDown = useCallback(() => {
    if (!userTyping) {
      setUserTyping(true);
      actions.typing(true);
      setTypingTimeout(setTimeout(stopTyping, TYPING_END_TIMEOUT));
    } else {
      clearTimeout(typingTimeout);
      setTypingTimeout(setTimeout(stopTyping, TYPING_END_TIMEOUT));
    }
  }, [
    userTyping,
    setUserTyping,
    actions,
    typingTimeout,
    setTypingTimeout,
    stopTyping,
    isLoading
  ]);

  useEffect(() => () => actions?.disconnect(), []);

  useEffect(() => {
    if (
      files.length < FILES_MAX_COUNT &&
      error?.type === CHAT_ERRORS.FILE_MAX_COUNT_EXCEEDED
    ) {
      setError(null);
    }
  }, [files, error]);

  return (
    <Box
      className={styles.chat}
      direction="column"
      style={{
        height: isHeaderVisible ? `calc(100% - var(--header-height))` : '100%'
      }}
    >
      <Header
        toggleChat={() => {
          toggleChat();
          actions.disconnect();
        }}
      />
      {isLoading ? (
        <div />
      ) : (
        <>
          <Messages messages={messages} agentsTyping={agentsTyping} />
          <Input
            files={files}
            error={error}
            setFiles={setFiles}
            value={value}
            onMaxCountExceed={handleMaxCountExceed}
            setValue={setValue}
            handleSend={handleSend}
            handleKeyDown={handleKeyDown}
          />
        </>
      )}
    </Box>
  );
};

export default Chat;
