export const GTM_EVENT = {
  DEPOSIT: 'Deposit',
  PLACE_BET: 'Bet Placed',
  REGISTER: 'Register',
  REGISTRATION_CR: 'Registration CR',
  DEPOSIT_SUCCESS_FIRST: 'first deposit',
  DEPOSIT_SUCCESS_REPEATED: 'repeated deposit'
};

export const LOG_ACTIONS = {
  BET_ATTEMPT: 'Bet Attempt',
  BET_PLACED: 'Bet Placed',
  PRE_ORDER: 'Bet pre order',
  REPEAT_BET: 'Repeat a bet click',
  SELECTED_OUTCOME: 'Selected outcome',
  UNSELECTED_OUTCOME: 'Unselected outcome',

  LOGIN: 'Login',
  LOGIN_FAILED: 'Login failed',
  LOGOUT: 'Log Out',

  REGISTRATION_SUCCESS: 'Registration success',
  REGISTRATION_FAILED: 'Registration failed',
  REGISTRATION_PAGE_OPEN: 'Registration page open',
  REGISTRATION_SUCCESS_SCREEN: 'Registration success screen open',

  DEPOSIT: 'Deposit',
  DEPOSIT_CLICK_HEADER: 'Deposit click Header',
  DEPOSIT_CLICK_OFFICE: 'Deposit click Office',
  DEPOSIT_CLICK_WALLET: 'Deposit click Wallet',
  DEPOSIT_REDIRECT: 'Deposit Redirect',

  EURO_PREDICTION_FORM: 'EuroPrediction Form',
  EURO_PREDICTION_SAVED: 'EuroPrediction Saved',

  CASINO_GAME_REAL: 'Casino Game Real',
  CASINO_GAME_DEMO: 'Casino Game Demo',
  CASINO_CATEGORIES: 'Casino categories',
  CASINO_PROVIDERS: 'Casino Providers',
  CASINO_PROVIDERS_SEARCH_ACTIVE: 'Casino Providers Search Active',
  CASINO_FAVORITES: 'Casino Favorites',
  CASINO_JACKPOT_BANNER: 'Casino Jackpot Banner',

  CASINO_CLICK_DEPOSIT_ON_MOBILE: 'Click Deposit button on Game page',

  FIRST_IMPRESSION: 'First impression',

  MAIN_MENU_CLICK: 'Main menu click',
  BURGER_MENU_CLICK: 'Burger menu click',

  MY_ACCOUNT_HEADER_CLICK: 'Header my account click',
  LOGO_HEADER_CLICK: 'Logo header click',

  SUPPORT: 'Support',

  PREMATCH_CLICK: 'Prematch Click',
  TOP_EVENTS: 'TOP Events',

  MULTIPLE_OF_THE_DAY_CLICK: 'Multiple of the day Click',
  MULTIPLE_OF_THE_DAY_BET: 'Multiple of the day Bet',

  MY_BETS_CLICK: 'My Bets Click',
  MY_BETS_ALL_BETS: 'My Bets All Bets',

  BET_SLIP_SEND_VIP_BET: 'Send a VIP bet to the Bookmaker click',

  VSPORT_TOP_CATEGORY_CLICK: 'VSport Top Category Click',
  VSPORT_INTERNAL_NAV: 'VSport internal nav',

  SPORTS_HM_CHANGE: 'Sports HM Change',

  PASS_RECOVERY: 'Pass Recovery',

  PROFILE_BET_HISTORY: 'Profile Bet history',
  PROFILE_BONUS: 'Profile Bonus',

  LIVE_EVENTS: 'Sportsbook LiveEvents',

  BACK_TO_TOP: 'Back To Top',

  FREESPIN_ACCEPT: 'FreeSpin accept',
  FREESPIN_ACCEPT_ERROR: 'FreeSpin accept error',
  FREESPIN_AFTER_ACCEPT: 'Freespin after accept',
  FREESPIN_INVALID_GAME_ID: 'FreeSpin invalid gameId',
  FREESPIN_LAUNCH_GAME: 'FreeSpin launch game',

  CASINO_PLAY_DESKTOP_FROM_MOBILE: 'Casino launch desktop game from mobile',
  CASINO_LAUNCH_LIVE_RTP_GAME_CATEGORY: 'Launch live RTP game from category',
  CASINO_LAUNCH_LIVE_RTP_GAME_LOBBY: 'Launch live RTP game from lobby',
  CASINO_LOAD_MORE_GAMES: 'Load more games',

  GO_OLD_VERSION: 'Old Version',

  PLAY_INSTANT_GAME: 'Play instant game',

  DIGITAL_PAY_METHOD_CLICK: 'Digital payment method click',

  COURIER_DEPOSIT: 'Courier deposit failed',

  TRANSLATIONS: 'Translations fetching failed in i18n using fallback',

  MOBILE_MONEY_DEPOSIT: 'Mobile money deposit failed',

  EVENT_BODY_MARKETS: 'Event body markets error',

  GO_TO_ASSISTANT_PAGE: 'GO_TO_ASSISTANT_PAGE',
  PRESS_ON_ASSISTANT_MICROPHONE: 'PRESS_ON_ASSISTANT_MICROPHONE',
  SUCCESS_ASSISTANT_EVENT: 'SUCCESS_ASSISTANT_EVENT',
  ERROR_ASSISTANT_EVENT: 'ERROR_ASSISTANT_EVENT',
  ENABLE_ASSISTANT: 'ENABLE_ASSISTANT',
  DISABLE_ASSISTANT: 'DISABLE_ASSISTANT',

  REALITY_CHECK_LOGOUT: 'Log out from reality check notification',
  REALITY_CHECK_CONTINUE: 'Click `continue` in reality check notification',

  CLICK_GAME_NAME_ON_INHOUSE_JACKPOT:
    'Click Game Name on in-House Jackpot section',
  SWITCH_SETTINGS: 'Switch settings',
  PREDEFINED_STATE: 'Predefined button in Betslip',
  PREDEFINED_AMOUNTS: 'Predefined amounts in Betslip',
  INIT_AB_TEST: 'INIT_NEW_AB_TEST',
  CASINO_LIVE_VIEW_ALL: 'CASINO_LIVE_VIEW_ALL',
  CASINO_LIVE_GAME_CLICK: 'CASINO_LIVE_GAME_CLICK',
  CASINO_VIEW_ALL: 'CASINO_VIEW_ALL',
  CASINO_GAME_CLICK: 'CASINO_GAME_CLICK',
  OPEN_CASINO_GAME: 'casino_games',
  PANKEEPER_SUCCESS: 'PANKEEPER_SUCCESS',
  PANKEEPER_ERROR: 'PANKEEPER_ERROR',
  PANKEEPER_DEPOSIT_SUCCESS: 'PANKEEPER_DEPOSIT_SUCCESS',
  PANKEEPER_DEPOSIT_FAILED: 'PANKEEPER_DEPOSIT_FAILED',
  PANKEEPER_DEPOSIT_NO_WALLET: 'PANKEEPER_DEPOSIT_NO_WALLET',
  PANKEEPER_WITHDRAW_SUCCESS: 'PANKEEPER_WITHDRAW_SUCCESS',
  PANKEEPER_WITHDRAW_FAILED: 'PANKEEPER_WITHDRAW_FAILED',
  PANKEEPER_WITHDRAW_NO_WALLET: 'PANKEEPER_WITHDRAW_NO_WALLET',
  PANKEEPER_GET_ALL_PANS_FAILED: 'PANKEEPER_GET_ALL_PANS_FAILED',
  PANKEEPER_SAVE_DESCRIPTION_FAILED: 'PANKEEPER_SAVE_DESCRIPTION_FAILED',
  PANKEEPER_DELETE_CARD_FAILED: 'PANKEEPER_DELETE_CARD_FAILED',
  PANKEEPER_ADD_CARD_SUCCESS: 'PANKEEPER_ADD_CARD_SUCCESS',
  PANKEEPER_ADD_CARD_FAILED: 'PANKEEPER_ADD_CARD_FAILED',
  PANKEEPER_GLOBAL_ERROR: 'PANKEEPER_GLOBAL_ERROR',
  PANKEEPER_LOCAL_ERROR: 'PANKEEPER_LOCAL_ERROR',
  VIEW_PROMO: 'View Promotion',
  BANNER_CLICK: 'Click on Banner',
  PROMO_CLICK: 'Click on promo',
  PROMO_RECENTLY_COMPLETED: 'Click on RECENTLY COMPLETED PROMOS',
  PROMO_SIMILAR_CLICK: 'Click on PROMO Interested',
  TO_PROMO_FROM_CASINO: 'Click on PROMO from Casino',
  AB_TEST_PRESET_DEPOSIT_UAH: 'AB_TEST_PRESET_DEPOSIT_UAH',
  AB_TEST_CLICK_BONUS_CARD_ON_CASINO_LOBBY: 'Click Bonus Card on Casino Lobby',
  POST_DEPOSIT: 'depos0',
  POST_LOGIN: 'login0',
  POST_PAYMENT_PAGE: 'payme0',
  POST_REGISTRATION: 'regis0',
  POST_SESSION: 'uniqu0',

  ONBOARDING_FIRST_STEP: 'ONBOARDING_FIRST_STEP',
  ONBOARDING_LAST_STEP: 'ONBOARDING_LAST_STEP',
  ONBOARDING_INTERRUPTION: 'ONBOARDING_INTERRUPTION',
  THEME_COLOR: 'THEME_COLOR',
  RECAPTCHA_SUCCESS: 'RECAPTCHA_SUCCESS',
  RECAPTCHA_WIDGET_SUCCESS: 'RECAPTCHA_WIDGET_SUCCESS',

  ADV_FILTER_APPLY_CLICK: 'ADV_FILTER_APPLY_CLICK',
  ADV_FILTER_RESET_CLICK: 'ADV_FILTER_RESET_CLICK',
  ADV_FILTER_MENU_CUSTOM_FILTER_CLICK: 'ADV_FILTER_MENU_CUSTOM_FILTER_CLICK',
  ADV_FILTER_LOAD_RESULT: 'ADV_FILTER_LOAD_RESULT',
  OPEN_SHARE_BET: 'OPEN_SHARE_BET',

  // AMPLITUDE
  REGISTRATION_PHONE_NUMBER_ENTERED: 'fe.registration.phone_number.enter',
  REGISTRATION_EMAIL_ENTERED: 'fe.registration.email_address.enter',
  REGISTRATION_PASSWORD_ENTERED: 'fe.registration.password.enter',
  REGISTRATION_CLICK_LOGIN: 'fe.registration.login_link.click',
  REGISTRATION_CLICK_REGISTER: 'fe.registration.register_btn.click',
  REGISTRATION_CLICK_CLOSE: 'fe.registration.close_btn.click',
  REGISTRATION_DOUBLE_EMAIL: 'fe.registration.double_email_page.open',
  REGISTRATION_SUCCESS_PAGE_OPEN: 'fe.registration.success_page.open',
  DEPOSIT_CHANGE_CARD: 'fe.deposit.card.change',
  DEPOSIT_AMOUNT_ENTERED: 'fe.deposit.deposit_amount.enter',
  DEPOSIT_CLICK_DEPOSIT_BTN: 'fe.deposit.deposit_btn.click',
  DEPOSIT_CLICK_PREDEFINED_AMOUNT: 'fe.deposit.preset_amount.click',
  DEPOSIT_SUCCESS_PAGE_OPEN: 'fe.deposit.success_page.open',
  DEPOSIT_PAYMENT_FAILED_PAGE_OPEN: 'fe.deposit.payment_failed_page.open',
  LOGIN_RELOAD: 'LOGIN_RELOAD'
};

export const NEW_RELIC_LOG_ERRORS = {
  RENDERING: 'Rendering error',
  REDUCER: 'Reducer error',
  RECAPTCHA_ERROR: 'RECAPTCHA_ERROR',
  RECAPTCHA_WIDGET_ERROR: 'RECAPTCHA_WIDGET_ERROR'
};

export const ACTION_LOCATIONS = {
  HEADER: 'header',
  HEADER_DESKTOP: 'header-desktop',
  BURGER: 'burger',
  FAVORITE_BUTTON: 'favorite-button',
  BANNER: 'banner',
  BANNER_NAME: 'banner-name',
  BETSPIL: 'betslip',
  BETSPIL_DESKTOP: 'betslip-desktop',
  HEADER_FAST_LOGIN: 'header/fast login'
};

export const FIRST_IMPRESSION_PERCENTAGE = 80;
