import clsx from 'clsx';
import { AText, Box } from '@alf/uikit';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { logAmplitude } from '@namespace/analytics';
import styles from './index.module.css';

const HaveAnAccount = ({
  text,
  linkText,
  to,
  linkColor = 'btn_quaternary_color_1',
  textColor = 'txt_color_7',
  classNames = {},
  analyticAction
}) => {
  const {
    registerClassName = '',
    haveAnAccountClassName = '',
    haveAnAccountTitleClassName
  } = classNames;
  const { t } = useI18n();

  return (
    <Box justify="center" className={registerClassName}>
      <AText
        data-role="register-have-account-text"
        breed="R"
        size="l"
        color={textColor}
        className={haveAnAccountTitleClassName}
      >
        {t(text)}
      </AText>
      <AText
        onClick={() => analyticAction && logAmplitude(analyticAction)}
        breed="BU"
        size="l"
        color={linkColor}
        component={LocalizedLink}
        to={to}
        dataRole="login-page-register-btn"
        className={clsx(styles.link, haveAnAccountClassName)}
      >
        {t(linkText)}
      </AText>
    </Box>
  );
};

export default HaveAnAccount;
