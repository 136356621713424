import { useMemo, memo } from 'react';
import { CoreList } from '@namespace/common';
import { useI18n } from '@namespace/i18n';
import { Box } from '@alf/uikit';
import {
  useGetCasinoCategoryFavorite,
  useLoadBurgerCasinoData,
  useGetFeedName,
  useGetLobbyPageUrl,
  useGetCasinoRecommendedCategory
} from '../../hooks';
import { useCategories } from '../../context/selectors';
import styles from './index.module.css';

const CasinoCommonBurgerMenu = ({ onClick, config = {}, casinoType }) => {
  const {
    showLobbyCategory = true,
    showFavoritesCategory,
    showRecommendedCategory
  } = config;
  const { t } = useI18n();
  const feedName = useGetFeedName(casinoType);
  const categories = useCategories(feedName);
  const lobbyPageUrl = useGetLobbyPageUrl(casinoType);
  const favoriteCategory = useGetCasinoCategoryFavorite({
    showFavoritesCategory,
    lobbyPageUrl
  });
  const recommendedCategory = useGetCasinoRecommendedCategory({
    showRecommendedCategory,
    lobbyPageUrl,
    casinoType,
    feedName
  });

  useLoadBurgerCasinoData({ feedNames: feedName });

  const lobbyCategory = showLobbyCategory
    ? [
        {
          id: 'lobby',
          title: 'games.lobby',
          link: `${lobbyPageUrl}/`
        }
      ]
    : [];

  const items = useMemo(
    () =>
      [
        ...lobbyCategory,
        ...favoriteCategory,
        ...recommendedCategory,
        ...categories
      ].map(({ id, title, link }) => ({
        title: t(title),
        id,
        link: link ?? `${lobbyPageUrl}/${id}`
      })),
    [favoriteCategory, recommendedCategory, lobbyPageUrl, categories, t]
  );

  const classNames = useMemo(() => ({
    listItemClass: styles.listItemClass
  }));

  return (
    <Box className={styles.itemsWrapper}>
      <CoreList items={items} classNames={classNames} onClick={onClick} />
    </Box>
  );
};

export default memo(CasinoCommonBurgerMenu);
