import { useEffect, useContext } from 'react';
import { useI18n } from '@namespace/i18n';
import { SiteSettingsContext } from '@namespace/cms';
import { window } from '@namespace/helpers';
import {
  MODE,
  SPORT_IDS,
  SPORTRADAR_IDS,
  SPORTRADAR_LANG
} from '../../../constants';
import { useEventField } from '../../../store';
import styles from './index.module.css';

const SportRadarWidget = ({ serviceId, matchId, eventId, setError }) => {
  const [settings] = useContext(SiteSettingsContext);
  const sportId = useEventField(eventId, 'sportId');
  const match =
    SPORT_IDS.FORMULA_1 === sportId ? { stageId: matchId } : { matchId };
  const { language } = useI18n();
  const isLive = serviceId === MODE.IN_PLAY;
  const { siteConfig } = settings;
  const { siteLogo = '' } = siteConfig;

  useEffect(() => {
    try {
      window.SIR('changeLanguage', SPORTRADAR_LANG[language]);
      window.SIR('addWidget', '.sr-widget', 'match.lmtPlus', {
        layout: 'double',
        scoreboard: isLive ? 'disable' : 'compact',
        detailedScoreboard: 'disable',
        sportId: SPORTRADAR_IDS[sportId],
        pitchLogo: siteLogo,
        goalBannerImage: siteLogo,
        logo: [siteLogo],
        ...match,
        onTrack: (name, { error }) => {
          if (error) {
            setError(true);
          }
        }
      });
    } catch {
      setError(true);
    }

    return () => {
      window.SIR('removeWidget', '.sr-widget', 'match.lmtPlus');
    };
  }, [language, matchId, setError, sportId, isLive]);

  return (
    <div
      data-role="sportradar-iframe"
      className={`sr-widget ${styles.widgetContainer}`}
    />
  );
};

export default SportRadarWidget;
