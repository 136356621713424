import { useLocation as useRouteHistory } from 'react-router-dom';
import { removeLangFromPathname } from '@namespace/helpers';

const useLocation = () => {
  const { pathname, ...rest } = useRouteHistory();

  return {
    ...rest,
    pathname: removeLangFromPathname(pathname)
  };
};

export default useLocation;
