import { createSelector } from 'reselect';
import { pathOr, prop } from 'ramda';
import { shallow } from 'zustand/shallow';
import { useSportsbookSelector } from '../../store';

export const selectSportSlugByEventId = (eventId, mode) =>
  createSelector(
    prop('sports'),
    pathOr(null, ['events', eventId]),
    (sports, event) => {
      const { sportId } = event || {};
      const sportByService = sports[mode] || [];
      const { sportSlug } =
        sportByService.find(({ sportId: id }) => id === sportId) || {};

      return sportSlug;
    }
  );

export const useSportSlugByEventId = (eventId, mode) =>
  useSportsbookSelector(selectSportSlugByEventId, [eventId, mode], shallow);
