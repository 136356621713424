import { AText, Box } from '@alf/uikit';
import { useHistory } from '@namespace/router';
import { Icon } from '@namespace/icons2';
import styles from './index.module.css';

const GoBackBar = ({ text }) => {
  const { goBack } = useHistory();

  return (
    <Box
      direction="row"
      align="center"
      flex="1"
      className={styles.goBackContainer}
    >
      <Box onClick={goBack} data-role="shop-back-btn-icon">
        <Icon
          className={styles.backArrow}
          name="icons/general/nav/back"
          size="s"
          color="line_header_txt"
        />
      </Box>
      <AText breed="BU" size="m" color="line_header_txt">
        {text}
      </AText>
    </Box>
  );
};

export default GoBackBar;
