import { forwardRef } from 'react';
import { isEmpty } from 'lodash';
import { getOutcomesForMarket } from '../../../utils';
import { SportTeaser } from '../../../common';
import styles from '../index.module.css';

const SportTeaserSlide = forwardRef(
  (
    {
      eventId,
      teaser,
      markets,
      teaserLine,
      stateOutcomes,
      handleClickOnMove,
      gap
    },
    ref
  ) => {
    const { id, picture } = teaser;
    const market =
      !isEmpty(teaserLine.eventsMarketsIds) &&
      teaserLine.eventsMarketsIds[eventId]
        ? markets[teaserLine.eventsMarketsIds[eventId][0]]
        : {};
    const { outcomeIds } = market || {};
    const outcomes = getOutcomesForMarket(outcomeIds, stateOutcomes);

    return (
      <SportTeaser
        ref={ref}
        gap={gap}
        key={id}
        className={styles.teaser}
        teaserPicture={picture}
        eventId={eventId}
        market={market}
        outcomes={outcomes}
        handleClickOnMove={handleClickOnMove}
      />
    );
  }
);

export default SportTeaserSlide;
