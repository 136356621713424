import { useContext, useEffect } from 'react';
import { window } from '@namespace/helpers';
import { UserContext } from '../../context';

const useIsVipFlag = () => {
  const [user] = useContext(UserContext);
  const { isVipUser = false } = user;

  useEffect(() => {
    window.isVip = isVipUser;
  }, [isVipUser]);
};

export default useIsVipFlag;
