import { useReducer, createContext, useMemo } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import { actions as actionCreators } from '../actions';
import { reducers } from '../reducers';
import { types } from '../types';
import { getWidgetInitParams } from '../../../utils/getWidgetInitParams';

export const WidgetContext = createContext();

export const WidgetProvider = ({ children }) => {
  const initialState = getWidgetInitParams();

  const memoReducers = useMemo(() => combineReducers(reducers), []);

  const [state, dispatch] = useReducer(memoReducers, initialState);

  const actions = useActions(types, dispatch, actionCreators);

  return (
    <WidgetContext.Provider value={[state, actions]}>
      {children}
    </WidgetContext.Provider>
  );
};
