import footerInfoEn from './snippets/footer-info-en';
import footerInfoRu from './snippets/footer-info-ru';
import footerInfoTr from './snippets/footer-info-tr';
import shortRegEn from './snippets/short-reg-en';
import shortRegRu from './snippets/short-reg-ru';
import shortRegTr from './snippets/short-reg-tr';
import shortRegBannerEn from './snippets/short-reg-banner-en';
import shortRegBannerRu from './snippets/short-reg-banner-ru';
import shortRegBannerTr from './snippets/short-reg-banner-tr';
import registerBannerEn from './snippets/reg-banner-en';
import registerBannerRu from './snippets/reg-banner-ru';
import registerBannerTr from './snippets/reg-banner-tr';
import rulesEn from './snippets/rules-en';
import rulesRu from './snippets/rules-ru';
import rulesTr from './snippets/rules-tr';
import passwordRecoveryBannerEn from './snippets/password-recovery-banner-en';
import passwordRecoveryBannerRu from './snippets/password-recovery-banner-ru';
import passwordRecoveryBannerTr from './snippets/password-recovery-banner-tr';
import loginBannerEn from './snippets/login-banner-en';
import loginBannerRu from './snippets/login-banner-ru';
import loginBannerTr from './snippets/login-banner-tr';
import registerFirstStepTitleEn from './snippets/reg-first-step-title-en';
import registerFirstStepTitleRu from './snippets/reg-first-step-title-ru';
import registerFirstStepTitleTr from './snippets/reg-first-step-title-tr';
import shortRegTitleEn from './snippets/short-reg-title-en';
import shortRegTitleRu from './snippets/short-reg-title-ru';
import shortRegTitleTr from './snippets/short-reg-title-tr';
import registerSuccessEn from './snippets/register-success-en';
import registerSuccessRu from './snippets/register-success-ru';
import registerSuccessTr from './snippets/register-success-tr';
import loginTitleEn from './snippets/login-title-en';
import loginTitleRu from './snippets/login-title-ru';
import loginTitleTr from './snippets/login-title-tr';
import aviatorCloudsBannerEn from './snippets/aviator-clouds-banner-en';
import aviatorDemoPlayEn from './snippets/aviator-demo-play-en';
import aviatorHowToPlayRu from './snippets/aviator-how-to-play-ru';
import phoneNumbersCookieNotificationEn from './snippets/phone-numbers-cookie-notification-en';

export const FALLBACK_SNIPPETS = {
  'footer-info-1': {
    en: footerInfoEn,
    ru: footerInfoRu,
    tr: footerInfoTr
  },
  'terms-conditions': {
    en: rulesEn,
    ru: rulesRu,
    tr: rulesTr
  },
  'short-reg-1': {
    en: shortRegEn,
    ru: shortRegRu,
    tr: shortRegTr
  },
  'short-reg-banner': {
    en: shortRegBannerEn,
    ru: shortRegBannerRu,
    tr: shortRegBannerTr
  },
  'register-banner': {
    en: registerBannerEn,
    ru: registerBannerRu,
    tr: registerBannerTr
  },
  'password-recovery-banner': {
    en: passwordRecoveryBannerEn,
    ru: passwordRecoveryBannerRu,
    tr: passwordRecoveryBannerTr
  },
  'login-banner': {
    en: loginBannerEn,
    ru: loginBannerRu,
    tr: loginBannerTr
  },
  'register-first-step-title': {
    en: registerFirstStepTitleEn,
    ru: registerFirstStepTitleRu,
    tr: registerFirstStepTitleTr
  },
  'short-reg-title': {
    en: shortRegTitleEn,
    ru: shortRegTitleRu,
    tr: shortRegTitleTr
  },
  'register-success': {
    en: registerSuccessEn,
    ru: registerSuccessRu,
    tr: registerSuccessTr
  },
  'login-title': {
    en: loginTitleEn,
    ru: loginTitleRu,
    tr: loginTitleTr
  },
  'aviator-clouds-banner': {
    en: aviatorCloudsBannerEn,
    ru: aviatorCloudsBannerEn,
    tr: aviatorCloudsBannerEn
  },
  'aviator-demo-play': {
    en: aviatorDemoPlayEn,
    ru: aviatorDemoPlayEn,
    tr: aviatorDemoPlayEn
  },
  'aviator-how-to-play': {
    en: aviatorHowToPlayRu,
    ru: aviatorHowToPlayRu,
    tr: aviatorHowToPlayRu
  },
  'phone-numbers-notification': {
    en: phoneNumbersCookieNotificationEn,
    ru: phoneNumbersCookieNotificationEn,
    tr: phoneNumbersCookieNotificationEn
  }
};
