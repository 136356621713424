import { useEffect } from 'react';
import { document } from '@namespace/helpers';

export const useOnClickOutside = (handler = null, mainRef, ...args) => {
  useEffect(() => {
    if (handler == null || mainRef == null) {
      return;
    }

    let opts = {};
    const refs = [];

    args.forEach((arg) => {
      if (arg?.current) {
        refs.push(arg);
      } else {
        opts = arg;
      }
    });

    const listener = (event) => {
      const { target } = event;

      if (
        !mainRef?.current ||
        mainRef.current.contains(target) ||
        refs?.some((ref) => ref?.current?.contains(target))
      ) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener, opts);
    document.addEventListener('touchstart', listener, opts);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handler, mainRef, ...args]);
};
