import { useMemo } from 'react';
import { useSiteSettings } from '@namespace/cms';
import { DEFAULT_PROVIDER_TAGS } from '../../constants';

export const useGetProviderTagsConfig = () => {
  const { gameTags = [] } = useSiteSettings();

  const tagsConfig = useMemo(
    () =>
      [...DEFAULT_PROVIDER_TAGS, ...gameTags].reduce(
        (acc, i) => ({ ...acc, [i.tagId]: i }),
        {}
      ),
    [gameTags]
  );

  return tagsConfig;
};
