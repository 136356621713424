import { useContext, useMemo } from 'react';
import { USER_ROLES, UserContext } from '@namespace/user';
import { useI18n } from '@namespace/i18n';
import { useGeneralRoutes } from '@namespace/cms';
import { REDIRECT_PARAM } from '@namespace/common';
import { FAVORITE_CATEGORY } from '../../constants';

export const useGetCasinoCategoryFavorite = ({
  showFavoritesCategory,
  lobbyPageUrl,
  logo,
  logoActive
}) => {
  const { t } = useI18n();
  const [{ role }] = useContext(UserContext);
  const { login } = useGeneralRoutes();
  const title = t(FAVORITE_CATEGORY.title);

  return useMemo(() => {
    if (showFavoritesCategory) {
      return role === USER_ROLES.USER
        ? [
            {
              id: FAVORITE_CATEGORY.id,
              title,
              logo,
              logoActive
            }
          ]
        : [
            {
              id: FAVORITE_CATEGORY.id,
              title,
              link: `${login}?${REDIRECT_PARAM}${lobbyPageUrl}/${FAVORITE_CATEGORY.id}`,
              logo,
              logoActive
            }
          ];
    }
    return [];
  }, [
    showFavoritesCategory,
    role,
    title,
    logo,
    logoActive,
    login,
    lobbyPageUrl
  ]);
};
