import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useI18n } from '@namespace/i18n';
import { Box, Button } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import getFormError from '../../utils/getFormError';
import Control from '../../common/Control';
import useOnChangeValidate from '../../hooks/useOnChangeValidate';
import styles from './index.module.css';

const GenderButtonGroup = ({
  name,
  label = '',
  hint,
  elements = [],
  rules = {},
  classNames = {},
  isOnChangeValidation = false,
  size = 'l',
  disabled: isFieldDisabled = false
}) => {
  const { wrapperClassName = '' } = classNames;
  const { t } = useI18n();
  const methods = useFormContext();
  const {
    register,
    formState: { errors },
    setValue,
    watch
  } = methods;
  const chosenValue = watch(name);

  useOnChangeValidate({ name, isOnChangeValidation });

  useEffect(() => {
    register(name, { ...rules });
  }, [register]);

  return (
    <Control
      error={getFormError(name, errors, t)}
      hint={hint}
      label={t(label)}
      classNames={{
        ...classNames,
        wrapperClassName
      }}
    >
      <Box align="center">
        {elements.map(
          ({
            label: buttonLabel,
            value,
            disabled = false,
            iconName,
            ...rest
          }) => {
            return (
              <Button
                intent="secondary"
                actionType="color3"
                size={size}
                key={`${name}-${value}`}
                disabled={disabled ?? isFieldDisabled}
                fullWidth={true}
                iconLeft={<Icon name={iconName} />}
                className={`${styles.button} ${
                  value !== chosenValue ? styles.active : ''
                }`}
                onClick={() => setValue(name, value)}
                {...rest}
              >
                {t(buttonLabel)}
              </Button>
            );
          }
        )}
      </Box>
    </Control>
  );
};

export default GenderButtonGroup;
