import { useContext } from 'react';
import { UserContext } from '@namespace/user';
import { PredictionsContext } from '../../store/context';

const useGetSavePrediction = () => {
  const [predState, predActions] = useContext(PredictionsContext);
  const { steps } = predState;
  const { SAVE_PREDICTION } = predActions;
  const [user] = useContext(UserContext);
  const { id } = user;
  return () => SAVE_PREDICTION(steps, id);
};

export default useGetSavePrediction;
