import { Box } from '@alf/uikit';
import { SkeletItem } from '../../../common/SkeletItem';
import styles from './index.module.css';

const PromotionsSkelet = ({ className }) => (
  <Box
    className={`${styles.skeletWrapper} ${className}`}
    data-role="promotions-skeleton"
  >
    <SkeletItem className={styles.skeletItem} />
    <SkeletItem className={styles.skeletItem} />
    <SkeletItem className={styles.skeletItem} />
    <SkeletItem className={styles.skeletItem} />
  </Box>
);

export default PromotionsSkelet;
