import { useContext, useEffect, useMemo } from 'react';
import { useI18n } from '@namespace/i18n';
import { FiltersSlider } from '@alf/uikit';
import { DeviceContext } from '@namespace/device';
import { useTournamentsByCategoryId, useTournamentsByIds } from '../../store';
import { useGetPathParams } from '../../hooks/useGetPathParams';
import styles from './index.module.css';

const TournamentsMenu = ({ serviceId, onSelect, activeTournament }) => {
  const { t } = useI18n();
  const { isDesktop } = useContext(DeviceContext);
  const { category } = useGetPathParams(serviceId);
  const { categoryId } = category || {};
  const tournamentsByCategoryId = useTournamentsByCategoryId(
    categoryId,
    serviceId
  );
  const tournaments = useTournamentsByIds(tournamentsByCategoryId, serviceId);

  const menuItems = useMemo(
    () =>
      tournaments
        ? [
            {
              label: t('sportsBook.tournamentsMenu.all'),
              id: null,
              active: Boolean(activeTournament)
            },
            ...tournaments.map(({ tournamentName, tournamentId }) => ({
              label: tournamentName,
              id: tournamentId,
              active: tournamentId === activeTournament
            }))
          ]
        : [],
    [tournaments]
  );

  useEffect(() => onSelect(null), [categoryId]);
  return (
    <FiltersSlider
      selectedItems={[activeTournament]}
      items={menuItems}
      isButtonsEnabled={true}
      isDesktop={isDesktop}
      onChange={onSelect}
      classNames={{
        containerClassName: styles.wrapper,
        itemClassName: styles.item,
        activeClassName: styles.active,
        activeTextClassName: styles.activeItemText
      }}
    />
  );
};

export default TournamentsMenu;
