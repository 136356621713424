import { useContext } from 'react';
import { USER_ROLES, UserContext } from '@namespace/user';
import { Box } from '@alf/uikit';
import { SiteSettingsContext, PageComponentsContext } from '@namespace/cms';
import { ErrorBoundary } from '@namespace/common';
import styles from './index.module.css';

// todo similar with ColumnContent in Layout
const MenuContent = ({ column = [], components, componentsMap }) =>
  column.map((componentId) => {
    const Content = components[componentId]
      ? componentsMap[components[componentId].type]
      : null;

    return Content ? (
      <ErrorBoundary componentId={componentId} key={componentId}>
        <Content id={componentId} />
      </ErrorBoundary>
    ) : null;
  });

const HeaderWrapper = ({
  className = '',
  leftMenuClassName = '',
  centerMenuClassName = '',
  rightMenuClassName = ''
}) => {
  const [user] = useContext(UserContext);
  const { role } = user;

  const [settings] = useContext(SiteSettingsContext);

  const componentsMap = useContext(PageComponentsContext);
  const { components, header = {} } = settings;
  const { guestMode = [], userMode = [] } = header;
  const [leftMenu = [], centerMenu = [], rightMenu = []] =
    role === USER_ROLES.GUEST ? guestMode : userMode;

  return (
    <Box
      justify="between"
      align="center"
      className={`${styles.wrapper} ${className}`}
    >
      <Box
        data-role="header-left-menu"
        align="center"
        className={`${styles.leftCol} ${leftMenuClassName}`}
      >
        <MenuContent
          column={leftMenu}
          components={components}
          componentsMap={componentsMap}
        />
      </Box>
      <Box
        align="center"
        flexGrow={1}
        data-role="header-center-menu"
        className={`${styles.centerCol} ${centerMenuClassName}`}
      >
        <MenuContent
          column={centerMenu}
          components={components}
          componentsMap={componentsMap}
        />
      </Box>
      <Box
        align="center"
        className={`${styles.rightCol} ${rightMenuClassName}`}
        justify="end"
      >
        <MenuContent
          column={rightMenu}
          components={components}
          componentsMap={componentsMap}
        />
      </Box>
    </Box>
  );
};

export default HeaderWrapper;
