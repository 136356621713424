import { useState, useEffect, useMemo } from 'react';
import { isEmpty, uniqBy, get } from 'lodash';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import { useOtherSettings } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { Accordion, AText, Box } from '@alf/uikit';
import { COLOR_SCHEME_DARK } from '@namespace/helpers';
import { useColorScheme } from '@namespace/themes';
import SelectField from '../../common/SelectField';
import SELECT_TYPES from '../../constants/selectTypes';
import {
  getCurrencyRegistration,
  getServicesCurrencyRegistration
} from '../../api';
import styles from './index.module.css';

const RegCurrencySelect = ({
  formProps = {},
  classNames: classNamesProp = {},
  noCurrencyListInfo = false,
  ...props
}) => {
  const [currencyList, setCurrencyList] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [isServicesOpened, setIsServicesOpened] = useState(false);
  const otherSettings = useOtherSettings();
  const paymentServicesDescription = get(
    otherSettings,
    'paymentServicesDescription',
    []
  );
  const { country = 'UA', currency = 'UAH' } = formProps;
  const { name } = props;
  const { t } = useI18n();
  const { setValue } = useFormContext();
  useEffect(() => {
    (async () => {
      if (country) {
        const currencyListResponse = await getCurrencyRegistration({
          country
        });
        setCurrencyList(currencyListResponse);
        setValue(name, currencyListResponse[0] || '');
      }
    })();
  }, [country]);

  useEffect(() => {
    (async () => {
      if (currency && country) {
        const servicesListResponse = await getServicesCurrencyRegistration({
          country,
          currency
        });
        setServicesList(servicesListResponse);
      }
    })();
  }, [country, currency]);

  const currencyListOptions = useMemo(
    () =>
      currencyList.map((value) => ({
        name: value,
        value
      })),
    [currencyList]
  );

  const clasNames = {
    headerClassName: styles.header,
    containerClassName: styles.containerClassName
  };

  const {
    colorScheme: { selectedColorMode }
  } = useColorScheme();

  const servicesInfo = useMemo(() => {
    if (servicesList.length) {
      return uniqBy(
        servicesList.reduce((result, value) => {
          if (paymentServicesDescription[value]?.deposit?.methods?.[country]) {
            return [
              ...result,
              ...paymentServicesDescription[value].deposit.methods[
                country
              ].slice(0, 3)
            ];
          }
          if (paymentServicesDescription[value]) {
            return [...result, paymentServicesDescription[value]];
          }
          return result;
        }, []),
        (object) => {
          if (object?.name) {
            return object.name.toLowerCase();
          }
          return {};
        }
      );
    }
    return [];
  }, [servicesList]);

  const isHasMore = servicesInfo.length > 6;

  const onServicesToggle = () => setIsServicesOpened((prev) => !prev);

  let servicesListMap = servicesInfo;

  if (!isServicesOpened && !isEmpty(servicesInfo)) {
    servicesListMap = servicesInfo.slice(0, 6);
  }

  const getPath = (value) => value['icon-dark'] || value?.icon || '';

  return (
    <>
      <SelectField
        options={currencyListOptions}
        selectType={SELECT_TYPES.SELECT}
        arrowSize="xs"
        {...props}
      />
      <AText
        className={clsx([styles.currencyInfo, classNamesProp.currencyInfo])}
        breed="R"
        size="s"
        color="input_help_txt"
        top="s"
      >
        {t('register.steps.welcome.currency.info')}
      </AText>
      {!isEmpty(currencyList) && (
        <Box
          onClick={isHasMore ? onServicesToggle : undefined}
          direction="column"
        >
          <Accordion
            title={t('register.steps.welcome.paymentServices')}
            withArrows={isHasMore}
            isCollapsible={false}
            isOpenDefault={true}
            arrowsSize="xs"
            arrowUpName={`icons/general/nav/arrow-${
              isServicesOpened ? 'up' : 'down'
            }`}
            classNames={clasNames}
            arrowsColor="input_txt"
            dataRole="payment-services"
          >
            <Box gap="m" wrap={true} bottom="m" padding="s s 0">
              {servicesListMap.map(
                (item, index) =>
                  !isEmpty(item) && (
                    <img
                      data-role={`register-currency-${index}`}
                      key={item?.name}
                      className={styles.img}
                      src={
                        selectedColorMode === COLOR_SCHEME_DARK
                          ? getPath(item)
                          : item?.icon || ''
                      }
                      alt={item?.name || ''}
                    />
                  )
              )}
            </Box>
          </Accordion>
          {!noCurrencyListInfo && (
            <AText
              className={styles.currencyInfo}
              breed="R"
              size="s"
              color="input_help_txt"
              top="s"
            >
              {t('register.steps.welcome.currencyList.info')}
            </AText>
          )}
        </Box>
      )}
    </>
  );
};

export default RegCurrencySelect;
