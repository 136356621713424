import {
  modifyByResultTypeData,
  modifyTotalData
} from './helpers/modifiers.js';
import { SPORT_IDS_SUPPORTED_BY_SET_SCORE } from '../../../../../constants';
import { getScoreForSport } from './helpers/getters.js';
import { buildEventTotalResult } from './total';
import { updateScore } from '../utils';

/**
 * @module SetScore
 */

const setEventScore = (
  state,
  {
    eventId,
    resultTypeId,
    scopeId,
    value,
    participantId
    // this params also come here
    // resultTypeName,
    // resultGameId,
    // resultGameDt,
    // scopeName,
  }
) => {
  const eventToUpdate = state.events[eventId];

  if (
    !eventToUpdate ||
    !SPORT_IDS_SUPPORTED_BY_SET_SCORE.includes(eventToUpdate.sportId)
  ) {
    return state;
  }

  const participantIdx = eventToUpdate.participants.findIndex(
    (participant) => participant.participantId === participantId
  );

  if (participantIdx === -1) {
    return state;
  }

  /**
   * @type {{
   * participants: *[],
   * sportId: number,
   * eventId: number,
   * scoreboard: {
   *   byResultType: ResultTypeData,
   *   total: ResultTypeDataItem[]
   * },
   * eventResultTotal: string
   * }}
   */
  const event = {
    ...eventToUpdate,
    scoreboard: eventToUpdate.scoreboard || {},
    participants: eventToUpdate.participants.map((p) => ({ ...p }))
  };

  const number = participantIdx + 1;

  const { scoreboard } = event;
  const { byResultType = [], total = [] } = scoreboard;

  event.scoreboard.byResultType = modifyByResultTypeData(
    byResultType,
    resultTypeId,
    scopeId,
    number,
    value
  );

  event.scoreboard.total = modifyTotalData(total, scopeId, number, value);

  const scores = getScoreForSport(
    event.scoreboard.byResultType,
    event.scoreboard.total,
    event.sportId,
    1,
    2
  );

  event.eventResultTotal = buildEventTotalResult(
    scores,
    event.sportId,
    event.scoreboard.byResultType,
    resultTypeId
  );

  const prevEventScore = {
    eventResultTotal: event.eventResultTotal,
    scoreboard: event.scoreboard
  };

  return {
    events: { ...state.events, [event.eventId]: event },
    prevLineScore: [
      ...state.prevLineScore.filter(({ eventId: id }) => eventId !== id),
      {
        eventId,
        ...prevEventScore
      }
    ]
  };
};

// TODO: cover sportsbook state with JSdoc and use here
/**
 * Set new event scores for all socket messages with type 'event.set_score'
 * @param state
 * @param {{
 *  eventId: number
 *  participantId: number
 *  resultGameDt: number
 *  resultGameId: number
 *  resultTypeId: number
 *  resultTypeName: string
 *  scopeId: number
 *  scopeName: string
 *  value: number
 * }[]} data
 * @returns {*}
 */
export const setEventsScore = (state, data = []) =>
  updateScore(state, data, setEventScore);
