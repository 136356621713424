import { useCallback, useContext } from 'react';
import { useGeneralRoutes } from '@namespace/cms';
import { StepperContext } from '@namespace/common';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { useLogin } from '@namespace/user';
import { AText, Box } from '@alf/uikit';
import { HTMLSnippet } from '@namespace/snippets';
import { Image } from '@namespace/icons2';
import styles from './index.module.css';

const SuccessBonus = ({ snippetId, replenishBalanceLink = '' }) => {
  const { t } = useI18n();
  const { createWallet } = useGeneralRoutes();
  const { login } = useLogin();
  const {
    stepper: {
      formData: { password, email }
    }
  } = useContext(StepperContext);

  const handleLinkClick = useCallback(() => {
    login({
      username: email,
      password,
      nextPage: replenishBalanceLink || createWallet
    });
  }, [replenishBalanceLink, createWallet]);

  return (
    <Box direction="column" className={styles.wrapper}>
      {snippetId && <HTMLSnippet snippetId={snippetId} />}
      <LocalizedLink
        onClick={handleLinkClick}
        to={replenishBalanceLink || createWallet}
        className={styles.createWallet}
        dataRole="success-page-deposit-btn"
      >
        <AText breed="BU" size="xl" className={styles.createWalletText}>
          {t('shortReg.createWallet')}
        </AText>
        <Image name="payments/visa_mastercard_goriz" alt="" />
      </LocalizedLink>
    </Box>
  );
};

export default SuccessBonus;
