import { useCallback, useContext } from 'react';
import { CoefTypeContext } from '@namespace/user';
import { calcCoef } from './utils';

const useOutcomeCalculation = () => {
  const [betsType] = useContext(CoefTypeContext);
  const { coefType } = betsType;

  return useCallback((coef) => calcCoef(coefType, Number(coef).toFixed(2)), [
    coefType
  ]);
};

export default useOutcomeCalculation;
