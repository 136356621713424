import { VERMANTIA_SPORT_ID } from '../../constants';

const formatTournamentTitle = ({
  isShowCategoryName,
  isShowTournamentName,
  categoryName,
  tournamentName,
  sportId,
  separator = ''
}) => `${isShowCategoryName ? `${categoryName} ` : ''}
${isShowCategoryName && isShowTournamentName ? `${separator} ` : ''}
${isShowTournamentName && sportId !== VERMANTIA_SPORT_ID ? tournamentName : ''}
`;

export default formatTournamentTitle;
