import { mergeDeepWith } from 'ramda';
import types from '../types';

export const reducers = {
  [types.SET_SNIPPETS]: (state, { snippets }) => {
    return {
      ...state,
      snippets: mergeDeepWith((s1, s2) => s2 ?? s1, state.snippets, snippets),
      newSnippetIds: []
    };
  },

  [types.SET_NEW_SNIPPETS_ID]: (state, { id }) => {
    return {
      ...state,
      newSnippetIds: [...state.newSnippetIds, id]
    };
  }
};
