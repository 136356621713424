import { getDetailedScoresForTable } from '../../../utils';
import {
  SCOPE_IDS,
  RESULT_TYPE_IDS,
  E_CRICKET_TOTAL_SPORTFORM_IDS
} from '../../../../../constants';

const getScoresDataForResultType = (data, resultTypeId) =>
  data.find(({ resultTypeId: rId }) => resultTypeId === rId)?.scores || [];

const getTotalRuns = (score = []) =>
  score.split(',').reduce((a, b) => Number(a) + Number(b), 0);

export const getECricketScore = ({
  byResultType,
  isShortVersion = false,
  eventServing = '',
  sportFormId,
  participants = [],
  total,
  isAlwaysDisplayScore = false
}) => {
  if (E_CRICKET_TOTAL_SPORTFORM_IDS.includes(sportFormId)) {
    const { scopeData = [] } = total.find(
      ({ scopeId }) => scopeId === SCOPE_IDS.RUNS
    );

    return scopeData
      .sort(({ number: numberA }, { number: numberB }) => numberA - numberB)
      .reduce((acc, { value }) => [...acc, value], []);
  }

  const { participantNumber: servingTeamNumber } =
    participants.find(
      ({ participantId }) => participantId === Number(eventServing)
    ) || {};

  const runsByPeriod = getDetailedScoresForTable(byResultType, SCOPE_IDS.RUNS);
  const wicketsByPeriod = getDetailedScoresForTable(
    byResultType,
    SCOPE_IDS.WICKET
  );
  const deliveriesByPeriod = getDetailedScoresForTable(
    byResultType,
    SCOPE_IDS.DELIVERY
  );
  const oversByPeriod = getDetailedScoresForTable(byResultType, SCOPE_IDS.OVER);

  const [team1RunsByOver, team2RunsByOver] = getScoresDataForResultType(
    runsByPeriod,
    RESULT_TYPE_IDS.E_CRICKET_INNING_1
  );
  const team1TotalRuns = getTotalRuns(team1RunsByOver);
  const team2TotalRuns = getTotalRuns(team2RunsByOver);

  const [team1Wickets, team2Wickets] = getScoresDataForResultType(
    wicketsByPeriod,
    RESULT_TYPE_IDS.E_CRICKET_INNING_1
  );

  const [team1Deliveries, team2Deliveries] = getScoresDataForResultType(
    deliveriesByPeriod,
    RESULT_TYPE_IDS.E_CRICKET_INNING_1
  );

  const [team1Overs, team2Overs] = getScoresDataForResultType(
    oversByPeriod,
    RESULT_TYPE_IDS.E_CRICKET_INNING_1
  );

  const isDisplayTeam1Score =
    isAlwaysDisplayScore ||
    servingTeamNumber === 1 ||
    team1TotalRuns ||
    team1Wickets ||
    team1Wickets ||
    team1Overs;

  const isDisplayTeam2Score =
    isAlwaysDisplayScore ||
    servingTeamNumber === 2 ||
    team2TotalRuns ||
    team2Wickets ||
    team2Deliveries ||
    team2Overs;

  return [
    isDisplayTeam1Score
      ? `${team1TotalRuns} ${
          isShortVersion ? '' : `(${team1RunsByOver})`
        } / ${team1Wickets} (${team1Overs}.${team1Deliveries})`
      : '',
    isDisplayTeam2Score
      ? `${team2TotalRuns} ${
          isShortVersion ? '' : `(${team2RunsByOver})`
        } / ${team2Wickets} (${team2Overs}.${team2Deliveries})`
      : ''
  ];
};
