import { useFormContext } from 'react-hook-form';

import { Button } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import useDirty from '../../hooks/useDirty';
import styles from './index.module.css';

const hasErrors = (formState) => formState.errors.length;

const SubmitButton = ({
  value,
  size = 'l',
  classNames = {},
  dataGmt = '',
  dataRole,
  disabled = false,
  enableIsValid = false,
  enableIsDirty = false,
  dirtyFields = [],
  defaultValues = {},
  onClick = () => {},
  formProps: { isLoading, loadingOnDisabled } = {},
  valueParams = [],
  secondaryElement,
  ...props
}) => {
  const { className = '', disabledClassName = '' } = classNames;
  const { t } = useI18n();
  const { formState } = useFormContext();

  const isFormChanged = useDirty(defaultValues, dirtyFields);
  const disabledFlag = isDisabled({
    disabled,
    enableIsDirty,
    isFormChanged,
    enableIsValid,
    formState
  });

  const processing = loadingOnDisabled ? disabledFlag || isLoading : isLoading;
  return [
    <Button
      key="button"
      onClick={onClick}
      type="submit"
      disabled={disabledFlag}
      data-gmt={dataGmt}
      data-role={dataRole}
      size={size}
      className={`${styles.button} ${className} ${
        disabledFlag ? disabledClassName : ''
      }`}
      processing={processing}
      {...props}
    >
      {t(value, valueParams)}
    </Button>,
    secondaryElement
  ];
};

export default SubmitButton;

export const isDisabled = ({
  disabled,
  enableIsDirty,
  isFormChanged,
  enableIsValid,
  formState
}) => {
  if (disabled) return true;
  // BE AWARE: just reading "isValid" ("formState.isValid") prop forces revalidation
  if (enableIsValid) return !formState.isValid;
  if (isFormChanged && !hasErrors(formState)) return false;
  if (enableIsDirty && !isFormChanged) return true;

  return false;
};
