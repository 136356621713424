import { useSiteSettings } from '@namespace/cms';
import RadioGroup from '../../common/RadioGroup';
import { WelcomePackItemLabel } from './components/WelcomePackItemLabel';
import styles from './index.module.css';

export const WelcomePackRadioSelect = ({
  name,
  dataRole,
  SnippetComponent,
  affiliateId
}) => {
  const { welcomePacks = [] } = useSiteSettings();

  if (!welcomePacks.length) {
    return null;
  }

  return (
    <RadioGroup
      name={name}
      elements={welcomePacks.map(({ welcomePackId, ...rest }) => ({
        value: String(welcomePackId),
        label: (
          <WelcomePackItemLabel
            data={rest}
            SnippetComponent={SnippetComponent}
            affiliateId={affiliateId}
          />
        )
      }))}
      size="m"
      gap="s"
      direction="column"
      classNames={{
        labelClassName: styles.label,
        labelTextClassName: styles.labelText,
        activeLabelClassName: styles.activeLabel
      }}
      dataRole={dataRole}
    />
  );
};
