import { useContext } from 'react';
import { get } from 'lodash';
import { useComponentConfig, useOtherSettings } from '@namespace/cms';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { Box, EmptyState } from '@alf/uikit';
import { Image } from '@namespace/icons2';
import { UserContext } from '@namespace/user';
import { useSportsbookStore } from '../../store';
import SportbookSkelet from '../SportbookSkelet';
import EventsContainer from './EventsContainer';
import { selectHasEventsIds, selectHasEventsMarketsIds } from './selectors';
import styles from './index.module.css';

const Line = ({
  isShowEventGroup,
  isShowFlag,
  fetchEvents,
  isEventsLoading = true,
  isLoadingMore,
  activeHeadGroups,
  isCollapsibleTournaments = true,
  isShowEmpty = false,
  lineKey,
  isEventsLoaded,
  showBorderIfEmpty = false,
  isLiveEvents = false,
  isReady = true,
  eventsToShow
}) => {
  const [state] = useContext(UserContext);
  const { settings } = state;
  const { lineViewType: lineView } = settings;
  const { t } = useI18n();
  const hasEventsIds = useSportsbookStore(selectHasEventsIds(lineKey));
  const hasEventsMarketsIds = useSportsbookStore(
    selectHasEventsMarketsIds(lineKey)
  );
  const otherSettings = useOtherSettings();
  const isShowStatUrl = get(otherSettings, 'sports.isShowStatUrl', true);
  const {
    paginationType,
    isShowVideo,
    isDisplayHeadMarkets = true,
    eventsLimitByTournament
  } = useComponentConfig();

  return (
    <Box>
      {isEventsLoading ? (
        <SportbookSkelet />
      ) : (
        <>
          {eventsToShow?.length > 0 &&
            hasEventsIds &&
            isReady &&
            hasEventsMarketsIds && (
              <EventsContainer
                isEventsLoaded={isEventsLoaded}
                isShowVideo={isShowVideo}
                isCollapsibleTournaments={isCollapsibleTournaments}
                eventsByGroups={eventsToShow}
                activeHeadGroups={activeHeadGroups}
                isShowEventGroup={isShowEventGroup}
                isShowFlag={isShowFlag}
                paginationType={paginationType}
                fetchEvents={fetchEvents}
                isLoadingMore={isLoadingMore}
                lineKey={lineKey}
                isShowStatUrl={isShowStatUrl}
                isDisplayHeadMarkets={isDisplayHeadMarkets}
                eventsLimitByTournament={eventsLimitByTournament}
                lineView={lineView}
                isLiveEvents={isLiveEvents}
              />
            )}
          {!eventsToShow?.length && isReady && isShowEmpty && (
            <Box
              direction="column"
              className={`${
                showBorderIfEmpty ? `${styles.emptyStateBorder}` : ''
              } ${styles.emptyStateWrapper}`}
            >
              <EmptyState
                dataRolePrefix="sport"
                image={<Image name="general/looking_for" />}
                Link={LocalizedLink}
                text={t('sportsbook.emptyState.noEvents')}
                classNames={{
                  textClassName: styles.emptyStateText
                }}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Line;
