import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useI18n } from '@namespace/i18n';
import { ToggleCheckbox, AText, Box } from '@alf/uikit';
import getFormError from '../../utils/getFormError';
import Control from '../../common/Control';
import useCustomError from '../../hooks/useCustomError';
import styles from './index.module.css';

export const ToggleCheckboxInfo = ({
  name,
  label = '',
  classNames = {},
  rules = {},
  hint,
  onChange = () => {},
  customError = '',
  ...props
}) => {
  const { t } = useI18n();
  const { wrapperClassName = '' } = classNames;
  const methods = useFormContext();
  const {
    control,
    formState: { errors },
    watch
  } = methods;
  const fieldWatched = watch(name);
  const { errorMessage, customErrorHandler } = useCustomError(customError);

  useEffect(() => onChange(fieldWatched), [fieldWatched, onChange]);

  return (
    <Control
      error={getFormError(name, errors, t) || t(errorMessage)}
      onClick={customErrorHandler}
      hint={hint}
      classNames={{
        ...classNames,
        wrapperClassName
      }}
    >
      <Box align="center">
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field: { ref, ...field } }) => {
            const { value: controllerValue, ...controllerFields } = field;

            return (
              <ToggleCheckbox
                name={name}
                checked={controllerValue}
                label={t(label)}
                {...props}
                {...field}
                {...controllerFields}
              />
            );
          }}
        />
        <AText className={styles.label} breed="R" size="m" color="txt_color_2">
          {t(label)}
        </AText>
      </Box>
    </Control>
  );
};
