import { useContext, useEffect } from 'react';
import { DeviceContext, getFormFactor } from '@namespace/device';
import { useGetUserSegments } from '@namespace/segments';
import { useUserInfoLoaded } from '@namespace/user';
import { VirtualSportsContext } from '../../store/context';

export const useLoadVirtualSportsData = ({ feedName = '' }) => {
  const [{ feed }, virtualSportsActions] = useContext(VirtualSportsContext);
  const {
    GET_VIRTUAL_SPORTS_FEED,
    SET_IS_VIRTUAL_SPORTS_FEED_LOADING
  } = virtualSportsActions;
  const { deviceFormFactor } = useContext(DeviceContext);
  const { isLoading: isFeedLoading, isLoaded: isFeedLoaded } = feed;
  const { userSegmentsNames: userSegments } = useGetUserSegments();
  const { userInfoLoaded } = useUserInfoLoaded();
  const formFactor = getFormFactor(deviceFormFactor);

  useEffect(() => {
    if (!isFeedLoaded && feedName && !isFeedLoading && userInfoLoaded) {
      SET_IS_VIRTUAL_SPORTS_FEED_LOADING(true);
      GET_VIRTUAL_SPORTS_FEED({
        platform: formFactor,
        feedName,
        userSegments
      });
    }
  }, [
    isFeedLoaded,
    isFeedLoading,
    formFactor,
    feedName,
    userSegments,
    GET_VIRTUAL_SPORTS_FEED,
    SET_IS_VIRTUAL_SPORTS_FEED_LOADING,
    userInfoLoaded
  ]);
};
