/**
 * @param {String|DateTime|Duration} src
 * @param {function} format
 * @returns {String}
 */
export const formatBonusValue = (src, format) => {
  try {
    return format(src);
  } catch (e) {
    console.error(
      `Invalid value/format pair. Value: ${src}, format: ${format}`
    );

    return src;
  }
};
