import { post } from '@namespace/helpers';

export const setTourAsFinished = ({ userId, tourName }) =>
  post(
    '/service/pds/user/onboarding/finish',
    {
      user_id: userId,
      onboarding_type: tourName
    },
    { 'Content-Type': 'application/json' },
    'include',
    'same-origin'
  );
