import { memo } from 'react';
import PropTypes from 'prop-types';
import AText from '../AText';
import Box from '../Box';
import styles from './index.module.css';

const EventGroupLabel = ({ eventGroup, className = '' }) => {
  const eventGroupNumber = Number(eventGroup) || 1;

  const getLabelColor = () => {
    if (eventGroupNumber === 1) {
      return styles.firstGroup;
    }
    return eventGroupNumber === 2 ? styles.secondGroup : styles.thirdGroup;
  };
  return (
    <Box
      align="center"
      justify="center"
      className={`${styles.label} ${getLabelColor()} ${className}`}
    >
      <AText align="center" breed="B" size="xs">
        {eventGroupNumber}
      </AText>
    </Box>
  );
};

EventGroupLabel.propTypes = {
  eventGroup: PropTypes.string,
  className: PropTypes.string
};

export default memo(EventGroupLabel);
