import { Box } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { advFilterActions } from '../../../../../../storeAdvFilter';

const { SET_ADV_FILTER_OPENED } = advFilterActions;

export const AdvFilterCloseButton = ({ className }) => (
  <Box className={className} onClick={() => SET_ADV_FILTER_OPENED(false)}>
    <Icon name="icons/general/action/close" size="s" color="state_info" />
  </Box>
);
