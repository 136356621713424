import { subscribeWithSelector } from 'zustand/middleware';

export const withMiddleware = (createStore, storeName) => {
  let appliedMw = subscribeWithSelector(createStore);

  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_ENV !== 'node'
  ) {
    // eslint-disable-next-line global-require
    const { devtools } = require('zustand/middleware');

    appliedMw = devtools(appliedMw, { name: storeName });
  }

  return appliedMw;
};
