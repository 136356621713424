import { AText, Box } from '@alf/uikit';
import { JackpotDetailsInfoColumn } from '../../common/JackpotDetailsInfoColumn';

import styles from './index.module.css';

export const JackpotItemDetails = ({ jackpotType, jackpotInfo }) => {
  return (
    <Box className={styles.wrapperForBorder}>
      <Box className={styles.contentWrapper} direction="column">
        <AText breed="BU" color="txt_color_4" size="2xl">
          {jackpotType}
        </AText>
        <JackpotDetailsInfoColumn jackpotInfo={jackpotInfo} />
      </Box>
    </Box>
  );
};
