export const BOOMING_EVENTS = {
  CLOSE: 'notifyCloseContainer'
};

export const boomingLauncherControl = (event, callbacks) => {
  const { onExitHandler = () => {} } = callbacks;
  const { data = {} } = event;

  if (data && data === BOOMING_EVENTS.CLOSE) {
    onExitHandler();
  }
};
