import { types } from '../types';

export const reducers = {
  [types.SET_COEF_TYPE]: (state, coefType) => {
    return {
      ...state,
      coefType
    };
  }
};
