import { useCallback, useContext, useEffect, useState } from 'react';
import { useNotifications } from '@namespace/notifications';
import { useI18n } from '@namespace/i18n';
import { SWPaymentsContext } from '../../store/context';
import { formatErrorMessage } from '../../utils';
import { PAYMENT_TYPES } from '../../constants';

export const useTokens = (activePaymentType, tokenType = 'card') => {
  const { t } = useI18n();
  const { errorNotification } = useNotifications();
  const [isFetchingCards, setIsFetching] = useState(true);

  const [
    { activeServiceId },
    { GET_USER_TOKENS, ADD_USER_TOKEN, UPDATE_USER_TOKEN }
  ] = useContext(SWPaymentsContext);

  const getTokens = useCallback(async () => {
    try {
      setIsFetching(true);
      const result = await GET_USER_TOKENS(
        activeServiceId,
        activePaymentType === PAYMENT_TYPES.WITHDRAW
      );
      return result;
    } catch (response) {
      const { errorCode } = response;
      errorNotification(t(formatErrorMessage(errorCode)));
    } finally {
      setIsFetching(false);
    }
    return {};
  }, [
    activeServiceId,
    activePaymentType,
    GET_USER_TOKENS,
    t,
    errorNotification
  ]);

  useEffect(() => {
    getTokens();
  }, [activeServiceId, getTokens]);

  const onAddToken = useCallback(
    async (token, onSuccess = () => {}) => {
      try {
        const result = await ADD_USER_TOKEN(activeServiceId, token, tokenType);
        await getTokens();
        onSuccess();
        return result;
      } catch (response) {
        const { errorCode } = response;
        errorNotification(t(formatErrorMessage(errorCode)));
      }
      return {};
    },
    [
      ADD_USER_TOKEN,
      activeServiceId,
      tokenType,
      getTokens,
      errorNotification,
      t
    ]
  );

  const onUpdateToken = useCallback(
    async (data) => {
      try {
        const result = await UPDATE_USER_TOKEN(
          activeServiceId,
          data.paymentToken,
          data.paymentTokenObject
        );
        return result;
      } catch (response) {
        const { errorCode } = response;
        errorNotification(t(formatErrorMessage(errorCode)));
      }
      return {};
    },
    [UPDATE_USER_TOKEN, activeServiceId, errorNotification, t]
  );

  return {
    onAddToken,
    onUpdateToken,
    isFetchingCards
  };
};
