import { useCallback, useContext, useState, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import {
  getCookie,
  setCookie,
  COOKIES_KEYS,
  document
} from '@namespace/helpers';
import { Icon } from '@namespace/icons2';
import { HTMLSnippet } from '@namespace/snippets';
import { NativeAppContext } from '@namespace/native-app';
import { Box } from '@alf/uikit';
import { BetSlipContext } from '@namespace/betslip';
import { DeviceContext } from '@namespace/device';
import { useIsComponentOnPage, useIsNavMenuVisible } from '@namespace/common';
import { COMPONENTS_TYPES } from '@namespace/cms';
import styles from './index.module.css';

const NotificationBanner = ({
  snippetId,
  expires = 365,
  customStyle = '',
  onClose,
  colorCloseBtn = '',
  closeIconClass = '',
  iconPositionClass = '',
  overlayClass = '',
  iconName = 'icons/general/action/close'
}) => {
  const { isDesktop } = useContext(DeviceContext);
  const [betSlip = {}] = useContext(BetSlipContext);
  const { bets = [] } = betSlip;
  const isBetSlipEnabled = useIsComponentOnPage(
    COMPONENTS_TYPES.BET_SLIP_GLOBAL
  );
  const isBetSlipVisible = !isDesktop && isBetSlipEnabled && bets.length;
  const navMenuVisible = useIsNavMenuVisible();

  const isBannerViewed = getCookie(
    `${COOKIES_KEYS.IS_BANNER_VIEWED}-${snippetId}`,
    false
  );
  const [isShowBanner, setIsShowBanner] = useState(!isBannerViewed);
  const [
    {
      nativeAppConfig: { popupVisibility }
    }
  ] = useContext(NativeAppContext);

  const setViewed = useCallback(() => {
    setCookie(`${COOKIES_KEYS.IS_BANNER_VIEWED}-${snippetId}`, true, {
      expires
    });
    setIsShowBanner(false);
  }, [snippetId, expires]);

  const notificationPosition = customStyle?.notification || styles.notification;

  useEffect(() => {
    if (overlayClass && isShowBanner) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [overlayClass, isShowBanner]);

  const Wrapper = overlayClass ? Box : Fragment;

  if (snippetId && isShowBanner && popupVisibility) {
    return (
      <Wrapper className={overlayClass}>
        <Box
          align="center"
          style={{ bottom: navMenuVisible ? '56px' : '0px' }}
          className={clsx([
            styles.notification,
            navMenuVisible && isBetSlipVisible && styles.withBetSlip,
            notificationPosition
          ])}
        >
          <Box align="center" className={styles.content}>
            <HTMLSnippet snippetId={snippetId} className={styles.htmlSnippet} />
          </Box>
          <Box
            onClick={onClose || setViewed}
            className={clsx([styles.closeButton, iconPositionClass])}
          >
            <Icon
              className={closeIconClass}
              color={colorCloseBtn || 'btn_close_dark_color'}
              size="s"
              name={iconName}
            />
          </Box>
        </Box>
      </Wrapper>
    );
  }

  return null;
};

export default NotificationBanner;
