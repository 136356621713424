import { camelizeKeys } from 'humps';
import { post, postAsJson } from '@namespace/helpers';

export const savePan = ({
  userId,
  accountingId,
  ssid,

  cardNumber,
  expMonth,
  expYear,
  cardHolder,
  cardDescription = '',

  isNewCard = true,
  isDeposit = true
}) =>
  post('/pankeeper/frontend_api/save_pan/', {
    user_id: String(userId),
    accounting_id: accountingId,
    ssid,
    card_number: cardNumber.replace(/[\s]/g, ''),
    exp_month: String(expMonth),
    exp_year: String(expYear),
    deposit: isDeposit,
    card_holder_name: cardHolder,
    save_to_db: isNewCard,
    description: cardDescription
  }).then(camelizeKeys);

export const saveCard = ({
  userId,
  serviceId,
  cardNumber,
  expMonth,
  expYear,
  cardHolder,
  cardDescription = ''
}) =>
  postAsJson(`/accounting/api/v1/users/${userId}/payments/pans`, {
    cardNumber: cardNumber.replace(/[\s]/g, ''),
    monthOfExpire: String(expMonth),
    yearOfExpire: String(expYear),
    cardHolderName: cardHolder,
    description: cardDescription,
    serviceId
  }).then(camelizeKeys);
