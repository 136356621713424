import { useEffect } from 'react';
import { useHistory } from '@namespace/router';
import { scrollTo } from '@namespace/helpers';
import {
  goNextStepAction,
  goPrevStepAction,
  goFirstStepAction,
  goToStepAction,
  setFormDataAction,
  setSuccessAction,
  setErrorAction,
  setErrorCodeAction
} from '../../../../store/stepper/actions';

const Steps = ({
  activeStep = 1,
  stepsMap = [],
  componentsMap = {},
  dispatch,
  stepsClassNames
}) => {
  const { goBack } = useHistory();
  const { type, config = {} } = stepsMap[activeStep - 1] || {};
  const Step = componentsMap[type] || null;

  const onSuccess = (data = {}) => {
    if (activeStep < stepsMap.length) {
      return dispatch(goNextStepAction({ ...data, activeStep }));
    }
    return dispatch(setSuccessAction());
  };

  const onError = (data = {}) => {
    if (activeStep === stepsMap.length) {
      return dispatch(setErrorAction(data));
    }
    return dispatch(setErrorCodeAction(data));
  };

  const onGoBack = (data = {}) => {
    if (activeStep === 1) {
      return goBack();
    }
    return dispatch(goPrevStepAction({ ...data, activeStep }));
  };

  const onGoToStep = (
    formData = {},
    toStep = activeStep - 1,
    errorFields = {}
  ) => dispatch(goToStepAction(formData, toStep, errorFields));

  useEffect(() => {
    scrollTo();
  }, [type]);

  return (
    Step && (
      <Step
        onStepSuccess={onSuccess}
        onStepError={onError}
        setFormData={(data) => dispatch(setFormDataAction(data))}
        onGoBack={onGoBack}
        onGoToStep={onGoToStep}
        onGoFirst={(data) => dispatch(goFirstStepAction(data))}
        componentsMap={componentsMap}
        classNames={stepsClassNames}
        {...config}
      />
    )
  );
};

export default Steps;
