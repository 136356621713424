import { post } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

export const getTopEventPresets = ({ lang, serviceId, tzDiff }) => {
  const body = {
    lang,
    service_id: serviceId,
    tz_diff: tzDiff
  };

  return post('/frontend_api/presets/v4_3/', body).then(camelizeKeys);
};
