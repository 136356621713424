import { ProgressBar, Box, AText } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { TENNIS_SERVES_TYPES } from '../../../constants';
import { useEventField } from '../../../../../../../store';
import styles from './index.module.css';

const Tennis = ({ eventId }) => {
  const { t } = useI18n();
  const eventRtsData = useEventField(eventId, 'eventRtsData') || [];

  const { value: firstParticipantPercents = 0 } =
    eventRtsData?.find(
      ({ type }) => type === TENNIS_SERVES_TYPES.PARTICIPANT_1
    ) || {};

  const { value: secondParticipantPercents = 0 } =
    eventRtsData?.find(
      ({ type }) => type === TENNIS_SERVES_TYPES.PARTICIPANT_2
    ) || {};

  return (
    <Box className={styles.container} direction="column" align="center">
      <AText size="xs" breed="BU" color="line_txt_1">
        {t('scoreboard.tennis.ownServesWon')}
      </AText>
      <Box className={styles.stats}>
        <Box className={styles.statsDetails} align="center">
          <AText className={styles.percent} size="s" color="line_txt_1">
            {`${firstParticipantPercents}%`}
          </AText>
          <ProgressBar
            totalValue={100}
            currentValue={Number(firstParticipantPercents)}
            progressColor="accent_color_1"
            containerColor="line_bg_7"
          />
        </Box>
        <Box className={styles.statsDetails} align="center">
          <ProgressBar
            totalValue={100}
            currentValue={Number(secondParticipantPercents)}
            progressColor="accent_color_1"
            containerColor="line_bg_7"
            isReversed={true}
          />
          <AText className={styles.percent} size="s" color="line_txt_1">
            {`${secondParticipantPercents}%`}
          </AText>
        </Box>
      </Box>
    </Box>
  );
};

export default Tennis;
