import { useCallback, useRef } from 'react';
import uuid from 'uuid/v4';
import useNativeMessageEventListener from '../useNativeMessageEventListener';
import useConfirmExit from './hooks/useConfirmExit';
import useNativePostMessage from '../useNativePostMessage';

const usePrompt = () => {
  const refPathInPending = useRef({});
  const nativePostMessage = useNativePostMessage();
  useNativeMessageEventListener({ refPathInPending });

  const confirmExit = useCallback(
    ({ callback, path }) => {
      const cid = uuid();
      refPathInPending.current = {
        ...refPathInPending.current,
        [cid]: callback
      };
      nativePostMessage({
        type: 'route',
        path,
        cid
      });
    },
    [nativePostMessage]
  );
  useConfirmExit(confirmExit);
};

export default usePrompt;
