import { Component } from 'react';
import { logNewRelicError, NEW_RELIC_LOG_ERRORS } from '@namespace/analytics';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false
    };
  }

  componentDidCatch(e) {
    const { componentId } = this.props;
    console.log('warn ERROR INSIDE', componentId);
    console.warn(e);
    this.setState({ isError: true });
    logNewRelicError(e, NEW_RELIC_LOG_ERRORS.RENDERING, { componentId });
  }

  render() {
    const { children, displayOnError = null } = this.props;
    const { isError } = this.state;
    if (isError) {
      return displayOnError;
    }

    return children;
  }
}

export default ErrorBoundary;
