import { get } from 'lodash';
import { useOtherSettings } from '@namespace/cms';
import { useUserSegmentsIncludes } from '@namespace/segments';

export const useUkrainePaymentRedirect = () => {
  const otherSettings = useOtherSettings();
  const {
    disabled = false,
    redirectUrl = '/welcome-to-ua',
    excludedSegments = ['vip-cash'],
    segments = ['ukraine-only']
  } = get(otherSettings, 'uaPaymentRedirect', {});

  const isUserSegmentsIncludes = useUserSegmentsIncludes();
  const isNotAllowedUser =
    isUserSegmentsIncludes(segments) &&
    !isUserSegmentsIncludes(excludedSegments);

  if (!disabled && redirectUrl && isNotAllowedUser) {
    return redirectUrl;
  }

  return null;
};
