import { useContext, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, AText } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { debounce } from 'lodash';
import { useGetPaymentService } from '../../../store/selectors/useGetPaymentService';
import { useTaxApi } from '../../../hooks/useTaxApi';
import { SWPaymentsContext } from '../../../store/context';
import { PAYMENT_TYPES } from '../../../constants';

export const OUGTax = ({ classNames = {} }) => {
  const { t } = useI18n();
  const [{ activePaymentType }] = useContext(SWPaymentsContext);
  const isDeposit = PAYMENT_TYPES.DEPOSIT === activePaymentType;
  const defaultTaxes = {
    currency: 'RON',
    fee: 0,
    fee2: 0,
    taxRate: 0,
    taxSum: 0,
    taxType: 0,
    total: 0
  };
  const [taxes, setTaxes] = useState(defaultTaxes);
  const { loadTaxes } = useTaxApi();
  const { limits = {} } = useGetPaymentService();
  const { min, max } = limits;
  const { getValues } = useFormContext();
  const { amount } = getValues();

  const onAmountChange = useRef(
    debounce((amountValue) => {
      if (amountValue >= min && amountValue <= max) {
        loadTaxes(amountValue).then((res) => {
          setTaxes(res || {});
        });
      } else {
        setTaxes(defaultTaxes);
      }
    }, 500)
  );

  useEffect(() => {
    if (onAmountChange.current) {
      onAmountChange.current(amount);
    }
  }, [amount, onAmountChange]);

  const { wrapperClassName } = classNames;

  const taxRateTrslKey = isDeposit
    ? 'web.personaloffice.balance.sw.deposit.taxratetype.oug'
    : 'personaloffice.balance.sw.withdraw.taxratetype.personalincome.romanian';
  const depositTaxRate = taxes.taxRate ? `${taxes.taxRate}%` : '';
  const taxRate = isDeposit ? depositTaxRate : '3%';
  const taxSum = isDeposit ? taxes.taxSum : taxes.fee2;

  return (
    <Box className={wrapperClassName} direction="column">
      <AText breed="R" color="po_txt_color_3">
        {t(taxRateTrslKey, [taxRate, taxSum, taxes.currency])}
      </AText>
      <AText breed="R" color="po_txt_color_3">
        {t(
          `web.personalOffice.balance.sw.${activePaymentType}.tax.totalAmount`,
          [taxes.total, taxes.currency]
        )}
      </AText>
    </Box>
  );
};
