export default {
  lessMore: (min, max) => new RegExp(`^.{${min},${max}}$`, 'g'),
  charAndNumber: [
    /\d+/g,
    /[a-zA-Z]+/g,
    /^[^\p{sc=Cyrillic}\s'"!@№#$;:,.%?&*\(\)\+=<>\{\}\[\]\\|\/`~\^-]+$/gu
  ],
  charAndNumberNoCyrillic: [/\d+/g, /[a-zA-Z]+/g, /^[^а-яёА-ЯЁыЫіІїЇ\s]+$/gu],
  noCyrillic: [/^[^а-яёА-ЯЁыЫіІїЇ\s]+$/gu],
  allChars: [/[a-z]+/g, /[A-Z]+/g],
  specialChars: [/['"!@№#$;:,.%?&*()+=<>{}\[\]\\|\/`~^_-]+/gu],
  additionalSymbols: [
    /[!@#$%?&*()+{}~_-]*/gu,
    /^[^\[\]\\|\/=:;"'<>,^№₽€£¥ƒ₣¢¤฿₠₡₢₤]+$/gu
  ],
  charAndNumberLowerUpperCase: [
    /^\S*$/,
    /\d+/g,
    /[a-zA-Z]+/g,
    /^[^а-яёА-ЯЁыЫіІїЇ\s]+$/gu,
    /[a-z]+/g,
    /[A-Z]+/g
  ],
  notNumber: /\D/,
  onlyNumbers: /^\d+$/,
  numbersWithComma: /^[0-9]+([\.,][0-9]+)?$/,
  multiple10: /^[1-9]+[0-9]*0$/,
  phoneNumber: /^\+\d+$/,
  email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9-]+)$/,
  latinSymbols: /^[^а-яёА-ЯЁыЫіІїЇ]+$/i,
  ifcCode: /^[A-Z]{4}0[A-Z0-9]{6}$/,
  cpfNumber: /^[\d]{3}\.[\d]{3}\.[\d]{3}-[\d]{2}$/,
  charsAndAdditionalSymbols: /^[A-Za-z -]+$/,
  charsAndDigitsOnly: /^[A-Za-z\d]+$/,
  latinCharsAndGap: /^[A-Za-z\s]+$/
};
