import BasePopularBetsEvents from './BasePopularBetsEvents';
import { useGetPathParams } from '../../../hooks/useGetPathParams';
import { MODE, LINE_KEY } from '../../../constants';

const PopularBetsEvents = ({ id }) => {
  const { activeSport } = useGetPathParams(MODE.PRE_MATCH);
  const { sportId, sportName = '' } = activeSport || {};
  const lineKey = `${LINE_KEY.POPULAR_BETS}-${sportId}`;

  return (
    <BasePopularBetsEvents
      id={id}
      sportId={sportId}
      sportName={sportName}
      lineKey={lineKey}
    />
  );
};

export default PopularBetsEvents;
