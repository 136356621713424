import { useContext, useEffect } from 'react';
import { LOG_ACTIONS, logAction } from '@namespace/analytics';
import { OnboardingContext } from '../../../store/context';

export const useTrackInterruptAction = () => {
  const [{ steps, stepIndex, tours, tourIndex }] = useContext(
    OnboardingContext
  );
  const tourId = tours[tourIndex]?.id;
  const stepId = steps[stepIndex]?.id;

  useEffect(() => {
    logAction(LOG_ACTIONS.ONBOARDING_INTERRUPTION, {
      tour: tourId,
      step: stepId
    });
  }, [stepId, tourId]);
};
