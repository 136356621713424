import { Box, Button } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import {
  SESSION_STORAGE_KEYS,
  setSessionStorageValue
} from '@namespace/helpers';
import { Icon } from '@namespace/icons2';
import { useGeneralRoutes } from '@namespace/cms';
import { LOG_ACTIONS, logAction } from '@namespace/analytics';
import { advFilterActions } from '../../../../../../storeAdvFilter';
import {
  useAdvFilterInnerCtxActions,
  useAdvFilterInnerCtxStore
} from '../../store';
import { useDistinctSelectedSportIds } from '../../hooks/useDistinctSelectedSportIds';
import { useAdvFilterSettings } from '../../hooks/useAdvFilterSettings';
import styles from './index.module.css';

const { SET_ADV_FILTER_OPENED, SAVE_ADV_FILTER } = advFilterActions;

export const BottomButtons = ({ className }) => {
  const { t } = useI18n();
  const filter = useAdvFilterInnerCtxStore('filter');
  const inactiveFilter = useAdvFilterInnerCtxStore('inactiveFilter');
  const { RESET_FILTER_SELECTION } = useAdvFilterInnerCtxActions();
  const { customFilter, preMatchLobby } = useGeneralRoutes();
  const { push } = useHistory();
  const distinctSportIds = useDistinctSelectedSportIds();
  const { maxSportsCount } = useAdvFilterSettings();
  const isDisableApplyButton = distinctSportIds.length > maxSportsCount;

  const onResetAll = async () => {
    logAction(LOG_ACTIONS.ADV_FILTER_RESET_CLICK, {
      filter: [...filter, ...inactiveFilter]
    });

    const newFilter = [];
    await SAVE_ADV_FILTER({ filter: newFilter });
    RESET_FILTER_SELECTION();
    // prevent closing filter on navigation after reset all click
    setSessionStorageValue(SESSION_STORAGE_KEYS.ADV_FILTER_PREVENT_CLOSE, true);
    push(preMatchLobby);
  };

  const onApply = async () => {
    logAction(LOG_ACTIONS.ADV_FILTER_APPLY_CLICK, {
      filter: [...filter, ...inactiveFilter]
    });

    await SAVE_ADV_FILTER({ filter: [...filter, ...inactiveFilter] });
    SET_ADV_FILTER_OPENED(false);
    push(customFilter);
  };

  return (
    <Box justify="between" gap="s" className={className}>
      <Button
        intent="secondary"
        actionType="color3"
        size="l"
        iconLeft={
          <Icon
            name="icons/general/action/close"
            size="s"
            color="btn_secondary_color_3"
          />
        }
        onClick={onResetAll}
        className={styles.button}
      >
        {t('sportsBook.sportsMenu.advFilter.resetAll')}
      </Button>
      <Button
        disabled={isDisableApplyButton}
        intent="primary"
        actionType="color1"
        size="l"
        iconLeft={
          <Icon
            name="icons/general/action/done"
            size="s"
            color="accent_color_1"
          />
        }
        onClick={onApply}
        className={styles.button}
      >
        {t('sportsBook.sportsMenu.advFilter.apply')}
      </Button>
    </Box>
  );
};
