import { post } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

const getPartnerConfig = () =>
  post('/eaccounting/api/partner_config').then(
    ({ response: { paymentSystems, pep_attributes: pepAttributes } }) =>
      camelizeKeys({
        paymentSystems,
        pepAttributes
      })
  );

export default getPartnerConfig;
