import { Box, Button } from '@alf/uikit';
import Amount from '@namespace/common/src/common/Amount';
import styles from './index.module.css';

const AutoAmount = ({
  name,
  amounts = [],
  initialAmount = 0,
  setValue,
  wrapperClassName = '',
  buttonClassName = '',
  isDisabled = false,
  currency = '',
  buttonSize = 's',
  intent = 'secondary',
  actionType = 'color3',
  amountBreed = 'B'
}) => {
  const setAmount = (amount) => {
    setValue((parseInt(initialAmount, 10) || 0) + amount);
  };

  return (
    <Box className={wrapperClassName}>
      {amounts.map((amount) => (
        <Button
          intent={intent}
          actionType={actionType}
          size={buttonSize}
          onClick={() => setAmount(amount)}
          key={`${name}-${amount}`}
          className={buttonClassName}
          disabled={isDisabled}
        >
          <Amount
            amount={`+${amount} ${currency}`}
            noFormattedAmount={amount}
            className={styles.amountWrapperClassName}
            currentAmountColor="btn_secondary_color_3"
            convertedAmountColor="input_help_txt"
            currentAmountSize="xs"
            convertedAmountSize="xs"
            currentAmountBreed={amountBreed}
            noAdditionalStyling={true}
          />
        </Button>
      ))}
    </Box>
  );
};

export default AutoAmount;
