import PropTypes from 'prop-types';
import clsx from 'clsx';
import { LocalizedNavLink, useLocation } from '@namespace/i18n';
import { FORM_FACTORS_MAP } from '@namespace/device';
import { Box, AText } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import getCurrentPath from '../../utils/getCurrentPath';
import IconLinkList from '../IconLinkList';
import styles from './index.module.css';

const uiProps = {
  desktop: {
    iconSize: 's',
    titleSize: 'm',
    countBreed: 'R',
    titleBreed: 'BU',
    iconColor: null,
    countSize: 'xs'
  },
  mobile: {
    iconSize: 'l',
    titleSize: 's',
    countBreed: 'BU',
    titleBreed: 'BU',
    iconColor: null,
    countSize: 'xs'
  }
};

const PersonalOfficeMenuItem = ({
  iconName,
  title,
  titleOrigin,
  count,
  items = [],
  component = LocalizedNavLink,
  link,
  // todo: mb rewrite
  intent = FORM_FACTORS_MAP.DESKTOP,
  classNames = {},
  handleExpand = () => {},
  isExpanded,
  place,
  uiMenuItemProps = uiProps,
  ...rest
}) => {
  const {
    iconSize,
    titleSize,
    countBreed,
    titleBreed,
    iconColor,
    countSize
  } = uiMenuItemProps[intent];
  const { pathname } = useLocation();
  const currentPagePath = getCurrentPath(pathname);
  const currentLinkPath = getCurrentPath(link);
  const {
    menuItemClassName = '',
    menuItemActiveClassName = '',
    itemMenuCount = '',
    menuTitleClassName = ''
  } = classNames;
  const itemLink = link
    ? { component, to: link, activeClassName: styles.active }
    : {};
  const toggleMenuItem = (e) => {
    if (!link) {
      e.stopPropagation();
      handleExpand();
    }
  };

  return (
    <Box
      direction="column"
      className={styles.wrapper}
      data-role={`${place}-${titleOrigin}`}
    >
      <Box
        data-role="user-menu-item-toggle"
        align="center"
        padding="s"
        className={clsx(
          styles.general,
          styles[intent],
          currentLinkPath === currentPagePath && styles.active,
          currentLinkPath === currentPagePath && menuItemActiveClassName,
          menuItemClassName
        )}
        onClick={toggleMenuItem}
        {...rest}
        {...itemLink}
      >
        {iconName && (
          <Icon
            size={iconSize}
            name={iconName}
            color={iconColor}
            className={styles.icon}
          />
        )}
        <AText
          breed={titleBreed}
          size={titleSize}
          className={clsx([styles.title, menuTitleClassName])}
        >
          {title}
        </AText>
        {Boolean(count) && (
          <Box
            align="center"
            justify="center"
            className={clsx([styles.count, itemMenuCount])}
          >
            <AText
              breed={countBreed}
              size={countSize}
              overflow="ellipsis"
              color="po_txt_color_2"
            >
              {count}
            </AText>
          </Box>
        )}
      </Box>
      {isExpanded && <IconLinkList items={items} />}
    </Box>
  );
};

PersonalOfficeMenuItem.propTypes = {
  iconName: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.number,
  items: PropTypes.array,
  component: PropTypes.func,
  link: PropTypes.string,
  classNames: PropTypes.shape({
    menuItemClassName: PropTypes.string,
    menuItemActiveClassName: PropTypes.string,
    itemMenuCount: PropTypes.string
  }),

  uiMenuItemProps: PropTypes.shape({
    desktop: PropTypes.shape({
      iconSize: PropTypes.string,
      titleSize: PropTypes.string,
      countBreed: PropTypes.string,
      titleBreed: PropTypes.string,
      iconColor: PropTypes.string,
      countSize: PropTypes.string
    }),
    mobile: PropTypes.shape({
      iconSize: PropTypes.string,
      titleSize: PropTypes.string,
      countBreed: PropTypes.string,
      titleBreed: PropTypes.string,
      iconColor: PropTypes.string,
      countSize: PropTypes.string
    })
  }),

  intent: PropTypes.string,
  place: PropTypes.string
};

export default PersonalOfficeMenuItem;
