import { useCallback, useState } from 'react';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { useGeneralRoutes } from '@namespace/cms';
import { useNotifications } from '@namespace/notifications';
import { accept } from '../../api/acceptBonus';
import { acceptModeType, bonusTab, riskFreeStates } from '../../constants';
import prepareBonusDetailsList from '../../utils/prepareBonusDetailsList';

const useRiskFreeBonus = (bonus, onAccept) => {
  const [isRequestProcessing, setIsRequestProcessing] = useState(false);
  const {
    bonusId,
    state,
    acceptMode,
    expireTime = {},
    betDateReceipt = {},
    currency
  } = bonus;
  const { t, language, f, timezone } = useI18n();
  const DATE_FORMATS_TYPES = f.getFormats();
  const { push } = useHistory();
  const { errorNotification, successNotification } = useNotifications();
  const { riskFreeBonusDetails, liveSportAll } = useGeneralRoutes();
  const isActivation = state === riskFreeStates.ERF_STATE_ACTIVATION_REQUEST;

  const isActive = state === riskFreeStates.ERF_STATE_ACTIVE;

  const isShowButton = useCallback(
    () =>
      isActivation || isActive || acceptModeType.ACCEPT.includes(acceptMode),
    [isActivation, acceptMode]
  );

  const handleClick = useCallback(
    async (event) => {
      event.stopPropagation();
      if (isActive) {
        push(liveSportAll);
      } else {
        setIsRequestProcessing(true);
        try {
          const message = await accept(
            {
              bonus_id: bonusId,
              lang: language,
              time_zone: timezone
            },
            bonusTab.riskFree
          );
          setIsRequestProcessing(false);
          successNotification(t(message));
          if (onAccept) {
            onAccept();
          }
        } catch (e) {
          setIsRequestProcessing(false);
          errorNotification(
            t(e.message || 'accountingMessages.accounting_error_6', [currency])
          );
        }
      }
    },
    [t, timezone, bonusId, currency, language, onAccept, isActive]
  );

  const openDetails = useCallback(
    () =>
      !isRequestProcessing || !isActivation
        ? push(`${riskFreeBonusDetails}/${bonusId}`)
        : null,
    [push, riskFreeBonusDetails, isRequestProcessing, isActivation]
  );

  const isCountdownTimerEnabled = () =>
    state === riskFreeStates.ERF_STATE_AVAILABLE ||
    state === riskFreeStates.ERF_STATE_ACTIVE;

  const getCountdownTimerTitle = () => {
    if (state === riskFreeStates.ERF_STATE_AVAILABLE) {
      return t('web.personaloffice.bonuses.timer.time.to.accepts');
    }
    if (state === riskFreeStates.ERF_STATE_ACTIVE) {
      return t('web.personaloffice.bonuses.timer.time.to.use');
    }

    return '';
  };

  const formatButtonTitle = useCallback(() => {
    if (isActive) {
      return 'placeBet';
    }

    return 'accept';
  }, [isActive]);
  const expireTimeInMilliseconds = expireTime?.sec * 1000 || expireTime?.sec;
  const completedTimeInMilliseconds = Date.parse(betDateReceipt);

  const getDetailsList = useCallback(
    () =>
      prepareBonusDetailsList([
        {
          name: t('web.personaloffice.bonuses.complited'),
          value: f
            .getDateTime({
              date: completedTimeInMilliseconds
            })
            ?.toFormat(DATE_FORMATS_TYPES.BONUSES_DATE_FORMAT),
          isDisplay:
            state === riskFreeStates.ERF_STATE_COMPLETED ||
            state === riskFreeStates.ERF_STATE_COMPLETED_2
        },
        {
          name: t('web.personaloffice.bonuses.expired.date'),
          value: f
            .getDateTime({ date: expireTimeInMilliseconds })
            ?.toFormat(DATE_FORMATS_TYPES.BONUSES_DATE_FORMAT),
          isDisplay:
            state === riskFreeStates.ERF_STATE_EXPIRED_TIME_ACTIVATION ||
            state === riskFreeStates.ERF_STATE_EXPIRED_TIME_USAGE
        }
      ]),
    [t, state, f, betDateReceipt]
  );

  return {
    isShowButton,
    handleClick,
    openDetails,
    isCountdownTimerEnabled,
    getCountdownTimerTitle,
    getDetailsList,
    isActivation,
    isRequestProcessing,
    formatButtonTitle
  };
};

export default useRiskFreeBonus;
