import isomorphicFetch from '../isomorphicFetch';
import { getDeviceIdHeaderSetter } from '../../services/fingerprint';

export const getResourceFromCms = async (
  param,
  { isSSR = false, headers, logger } = {}
) => {
  const path = `${isSSR ? '' : `/adm-gw01`}/public${param}`;
  const route = param.replace('/seoBatch', '');
  const isSeoBatchRequest = param.includes('/seoBatch');

  const setDeviceIdHeader = await getDeviceIdHeaderSetter();
  const newHeaders = setDeviceIdHeader(headers);

  return isomorphicFetch(path, { headers: newHeaders }, logger)
    .then((response) => {
      const { url, ok, status } = response;
      if (!ok) {
        if (isSSR && isSeoBatchRequest) {
          return {
            seoBatchMissingMap: {
              [route]: true
            }
          };
        }
        throw new Error(`http error - status: ${status}, url: ${url}`);
      }

      if (isSSR && isSeoBatchRequest) {
        return { seoBatch: response.json(), seoBatchMissingMap: {} };
      }

      return response.json();
    })
    .catch((error) => {
      if (!error.message?.includes('status: 404') || error.statusCode === 404) {
        logger?.error(`Error loading at url: "${path}"`);
      }

      if (isSSR && isSeoBatchRequest) {
        return {
          seoBatchMissingMap: {
            [route]: true
          }
        };
      }

      throw new Error(error);
    });
};
