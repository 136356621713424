import { document } from '@namespace/helpers';

const subscribe = (eventNames = [], el, handler) => {
  for (const evName of eventNames) {
    el.addEventListener(evName, handler, { capture: true, passive: false });
    document.body.addEventListener(evName, handler, {
      capture: true,
      passive: false
    });
  }

  // unsubscribe
  return () => {
    for (const evName of eventNames) {
      el.removeEventListener(evName, handler, {
        capture: true,
        passive: false
      });
      document.body.removeEventListener(evName, handler, {
        capture: true,
        passive: false
      });
    }
  };
};

const subscribeElInterceptActions = (targetEl, { action }) => {
  const handler = (e) => {
    if (
      !e.isTrusted || // pass-through programmatic clicks
      !targetEl.contains(e.target) // click outside target
    ) {
      return;
    }

    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();

    if (e.type === 'click') {
      action();
    }
  };

  return subscribe(['click', 'touchmove'], targetEl, handler);
};

export const subscribeInterceptActions = (targetEls, { action }) => {
  const cleanFns = targetEls.map((targetEl) =>
    subscribeElInterceptActions(targetEl, { action })
  );

  return () => {
    for (const unsubscribe of cleanFns) unsubscribe();
  };
};
