import { isNull, defaults } from 'lodash';

import getISOCodeByCode from '../getISOCodeByCode';
import getCodeByISOCode from '../getCodeByISOCode';
import extractCodeFromNumber from '../extractCodeFromNumber';
import { MAX_CODE_LENGTH } from '../../constants';

const createValue = ({ valueProp, codesListItems }) => (number, isoCode) => {
  const data = {};

  if (!isNull(number)) {
    data.number = number;

    const shouldCheckForCode = number.length <= MAX_CODE_LENGTH;

    if (shouldCheckForCode) {
      const extractedCode = extractCodeFromNumber(codesListItems, number);

      data.isoCode = extractedCode
        ? getISOCodeByCode(codesListItems, extractedCode)
        : undefined;
    }
  } else if (!isNull(isoCode)) {
    const foundCode = getCodeByISOCode(codesListItems, isoCode) ?? '';

    Object.assign(data, {
      isoCode,
      number: foundCode
    });
  }

  return defaults(data, valueProp);
};

export default createValue;
