import { InfoMessage } from '@alf/uikit';
import styles from './index.module.css';

const TextNotification = ({ children, className = '' }) => {
  return (
    <InfoMessage
      className={`${className} ${styles.textWrapper}`}
      align="center"
    >
      {children}
    </InfoMessage>
  );
};

export default TextNotification;
