import { forwardRef } from 'react';
import clsx from 'clsx';
import styles from './index.module.css';

const GameSkelet = forwardRef(({ className, style = {} }, ref) => (
  <div
    className={clsx([styles.skeletBlockWrapper, className])}
    style={style}
    ref={ref}
  >
    <img
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAM8AAADPCAQAAAD4iE3UAAABNElEQVR42u3RMQ0AAAzDsJU/6aGo1MOBEOc0XCzAIzx4hEd48AgPHuERHjzCg0d4hAeP8OARHuHBIzx4hEd48AiP8OARHjzCIzx4hAeP8AgPHuHBIzzCg0d48AiP8OARHuHBIzx4hEd48AgPHuERHjzCg0d4hAeP8OARHuHBIzzCg0d48AiP8OARHjzCIzx4hAeP8AgPHuHBIzzCg0d4hAeP8OARHuHBIzx4hEd48AgPHuERHjzCg0d4hAeP8AgPHuHBIzzCg0d48AiP8OARHjzCIzx4hAeP8AgPHuERHjzCg0d4hAeP8OARHuHBIzx4hEd48AgPHuERHjzCIzx4hAeP8AgPHuHBIzzCg0d48AiP8OARHjzCIzx4hEd48AgPHuERHjzCg0d4hAeP8OARHuHBo2IPqoAA0GWeeRAAAAAASUVORK5CYII="
      alt="clear rectangle"
      className={`${styles.clearRectangle}`}
    />
    <div className={styles.skeletBlock} />
  </div>
));

export default GameSkelet;
