import { useEffect } from 'react';
import { window } from '@namespace/helpers';

const useAmplitude = (userId) => {
  useEffect(() => {
    if (window.amplitude && userId) {
      window.amplitude.setUserId(userId);
    }
  }, [userId]);
};

export default useAmplitude;
