import PropTypes from 'prop-types';
import Box from '../Box';
import AText from '../AText';
import SliderItems from '../SliderItems';
import styles from './index.module.css';

const SubMenu = ({
  mainMenuItems = [],
  SecondaryItems = null,
  NavLink,
  isActive,
  isDesktop,
  dataRolePrefix
}) => (
  <Box justify="between" className={styles.container}>
    <Box className={styles.sliderItemsWrapper}>
      <SliderItems isButtonsEnabled={true} isDesktop={isDesktop}>
        {mainMenuItems.map(({ id, link, title, logoUrl, tag, tagColor }) => (
          <Box
            key={id}
            data-role={`${dataRolePrefix}-${id}`}
            className={styles.mainMenuLinkWrapper}
          >
            {tag && (
              <Box
                className={styles.mainMenuTag}
                padding="0 xs"
                style={{
                  backgroundColor: tagColor
                }}
              >
                <AText breed="BU" size="2xs" color="txt_color_4">
                  {tag}
                </AText>
              </Box>
            )}
            <Box align="center" className={styles.mainMenuItem}>
              {logoUrl ? (
                <Box className={styles.imgWrapper}>
                  <img className={styles.imgIcon} src={logoUrl} alt="logo" />
                </Box>
              ) : (
                <NavLink
                  isActive={() => isActive(link)}
                  className={styles.mainMenuLink}
                  activeClassName={styles.mainMenuLinkActive}
                  to={link}
                >
                  {title}
                </NavLink>
              )}
            </Box>
          </Box>
        ))}
      </SliderItems>
    </Box>
    {SecondaryItems}
  </Box>
);

SubMenu.propTypes = {
  mainMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      link: PropTypes.string,
      title: PropTypes.string
    })
  ),
  SecondaryItems: PropTypes.node,
  NavLink: PropTypes.elementType,
  isActive: PropTypes.func,
  isDesktop: PropTypes.bool
};

export default SubMenu;
