import { useEffect, useState, useContext, useMemo } from 'react';
import { useSearchParams, useHistory } from '@namespace/router';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { PageContext } from '@namespace/cms';
import { useI18n, useLocation } from '@namespace/i18n';
import { passwordRecoveryComponentsMap } from '../../constants';
import GuestContent from '../../../../common/GuestContent';
import styles from './index.module.css';

const PasswordRecovery = ({ id, componentsMap = {}, isHeader = true }) => {
  const { t } = useI18n();
  const { page } = useContext(PageContext);
  const { components } = page;
  const { config = {} } = components[id];
  const { steps = [] } = config;
  const [token, setToken] = useState(null);
  const { pathname } = useLocation();
  const { token: routeToken } = useSearchParams();
  const { replace } = useHistory();

  useEffect(() => {
    if (routeToken) {
      setToken(routeToken);
    }

    replace(pathname);
  }, [routeToken, pathname]);

  const mergedComponentsMap = useMemo(
    () => ({
      ...passwordRecoveryComponentsMap,
      ...componentsMap
    }),
    []
  );

  return (
    <GuestContent
      header={isHeader ? '' : t('passwordRecovery.header')}
      dataRole="password-recovery-page"
      config={config}
      successMessage={t('passwordRecovery.success')}
      defaultActiveStep={Boolean(token) && steps.length}
      defaultFormData={Boolean(token) && { token }}
      classNames={{
        wrapperClassName: styles.wrapper,
        cancelButtonClassNames: styles.cancelBtn
      }}
      componentsMap={mergedComponentsMap}
      onChangeStep={(stepNum) =>
        logAction(LOG_ACTIONS.PASS_RECOVERY, {
          StepN: steps[stepNum - 1].type
        })
      }
    />
  );
};

export default PasswordRecovery;
