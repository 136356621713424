import { useContext, useEffect } from 'react';
import { SiteSettingsContext } from '@namespace/cms';
import { UserContext } from '@namespace/user/src/context';
import { window, document } from '@namespace/helpers';

const useLiveChat = () => {
  const [settings = {}] = useContext(SiteSettingsContext);
  const { supportSettings = {} } = settings || {};
  const { supportLiveChat = {} } = supportSettings;
  const { license } = supportLiveChat;

  const [user] = useContext(UserContext);
  const { id, phoneNumber, email } = user;
  const { call = () => {} } = window.LiveChatWidget || {};

  useEffect(() => {
    if (id && license) {
      call('set_customer_email', email);
      call('set_session_variables', { phoneNumber, id });
    }
  }, [id, email, phoneNumber, license, call]);

  useEffect(() => {
    if (license) {
      const script = document.createElement('script');
      script.innerHTML = `window.__lc = window.__lc || {};
        window.__lc.license = ${license};
        ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))`;
      document.body.append(script);
    }
  }, []);
};

export default useLiveChat;
