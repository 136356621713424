import { Fragment, memo, useContext, useEffect } from 'react';
import clsx from 'clsx';
import { Box } from '@alf/uikit';
import { NotificationPopup } from '@namespace/account';
import {
  COMPONENTS_TYPES,
  PageComponentsContext,
  PageContext,
  useGeneralRoutes,
  useOtherSettings,
  useSiteSettings
} from '@namespace/cms';
import { InitAnalytics } from '@namespace/cookie-management';
import { DeviceContext } from '@namespace/device';
import { PWBBonusesNotification } from '@namespace/bonuses';
import {
  useIsMounted,
  IS_SERVER_RENDER,
  POPUP_TYPES,
  window,
  POPUP_HALF_SCREEN,
  document
} from '@namespace/helpers';
import {
  Loader,
  useNavMenuVisible,
  useIsNavMenuVisible
} from '@namespace/common';
import appInIframe from '@namespace/helpers/src/utils/appInIframe';
import { LocalizedRedirect, useI18n } from '@namespace/i18n';
import { NativeAppContext, useMobileAppBanner } from '@namespace/native-app';
import { Notification } from '@namespace/notifications';
import { OnboardingContext } from '@namespace/onboarding';
import { useMatchPath, useSearchParams } from '@namespace/router';
import { IS_WIDGET } from '@namespace/widget';
import { isEmpty } from 'lodash';
import { BetSlipContext } from '@namespace/betslip';
import { useThemeComponents } from '@namespace/themes';
import { SeoSnippet } from '../../common/SeoSnippet';
import DefaultUserNotification from '../DefaultUserNotification';
import HeaderLinks from '../HeaderLinks';
import HeaderScripts from '../HeaderScripts';
import DefaultErrorPage from './DefaultErrorPage';
import { LAYOUT_TYPES } from './Layout/layoutMap';
import usePageBackground from './usePageBackground';
import { pageWrappersMap } from './wrappersMap';
import useScrollToTop from '../../hooks/useScrollToTop';
import useDetectKeyboardOpen from '../../utils/useDetectKeyboardOpen';
import { ScrollToTopButton } from '../ScrollToTopButton';
import { HEADER_COLOR_SCHEME } from '../../constants';
import usePageShouldBeHydrate from './hooks/usePageShouldBeHydrate';
import styles from './index.module.css';

const headerColorSchemeClasses = {
  [HEADER_COLOR_SCHEME.SECONDARY]: 'headerColorSchemeSecondary'
};

const Container = ({
  children,
  pageWrapperClassName = '',
  pageContainerClassName = '',
  headerWrapperClassName = '',
  isOnboardingRunning
}) => {
  const { popup } = useSearchParams();
  const { isDesktop } = useContext(DeviceContext);
  const { t } = useI18n();
  const { page, seoConfig = {} } = useContext(PageContext);
  const [betSlip = {}] = useContext(BetSlipContext);
  const { isOpen: isBetSlipOpen = false, fastBet } = betSlip;
  useNavMenuVisible();
  const isMounted = useIsMounted();

  const [
    {
      isNativeApp,
      nativeAppConfig: { headerVisibility, footerVisibility, popupVisibility }
    }
  ] = useContext(NativeAppContext);
  const {
    pageConfig = {},
    wrapperTypes = [],
    components = {},
    layout = {}
  } = page;
  const {
    isHeaderVisible = true,
    isFooterVisible = true,
    seoSnippetId: pageConfigSnippetId = null,
    headerColorScheme
  } = pageConfig;
  const pageShouldBeHydrate = usePageShouldBeHydrate();

  const { type } = layout;
  const isCustomFooter =
    type === LAYOUT_TYPES.THREE_COLUMN_FULL_HD_SMALL_FOOTER;
  const headerVisible = isNativeApp ? headerVisibility : isHeaderVisible;
  const footerVisible = isNativeApp ? footerVisibility : isFooterVisible;
  const navMenuVisible = useIsNavMenuVisible();
  const { showBackToTopButton } = useOtherSettings();
  const isShowFloatingScrollToTopButton = !isEmpty(
    useMatchPath(showBackToTopButton, {
      isReturnFullMatch: true
    })
  );
  const isShowFullScreenPopup = Object.values(POPUP_TYPES)?.includes(popup);
  const isShowHalfScreenPopup = Object.values(POPUP_HALF_SCREEN)?.includes(
    popup
  );

  const { snippetId: seoSnippetId } = seoConfig;
  const snippetId = seoSnippetId || pageConfigSnippetId;

  const {
    [COMPONENTS_TYPES.MOBILE_APP_BANNER]: MobileAppBanner,
    [COMPONENTS_TYPES.HEADER]: Header,
    [COMPONENTS_TYPES.FOOTER]: Footer,
    [COMPONENTS_TYPES.BOTTOM_NAVIGATION_MENU]: BottomNavigationMenu,
    [COMPONENTS_TYPES.FULL_SCREEN_POPUP]: FullScreenPopup,
    [COMPONENTS_TYPES.MOBILE_POPUP_MENU]: MobilePopupMenu = Fragment
  } = useContext(PageComponentsContext);

  const { home } = useGeneralRoutes();

  const { isShowMobileAppBanner, slide } = useMobileAppBanner();
  const { siteConfig } = useSiteSettings();
  const { support404page = true } = siteConfig;

  const { ErrorPage = DefaultErrorPage } = useThemeComponents();

  const ifPageExist =
    Boolean(Object.keys(components).length) &&
    Boolean(Object.keys(page).length);

  usePageBackground();

  const inIframe = appInIframe() && IS_WIDGET;
  const isKeyboardOpen = useDetectKeyboardOpen();

  const IS_404_PAGE = !ifPageExist && support404page;

  return (
    <Box
      direction="column"
      align="center"
      className={clsx([
        styles.container,
        inIframe ? styles.widgetPageContainer : styles.pageContainer,
        isNativeApp && styles.nativeApp,
        isKeyboardOpen && styles.keyboardOpen,
        navMenuVisible &&
          (!isBetSlipOpen || (isBetSlipOpen && fastBet && !isKeyboardOpen)) &&
          styles.navMenu,
        pageContainerClassName
      ])}
    >
      <HeaderLinks />
      <HeaderScripts />
      <InitAnalytics />
      {popupVisibility && (
        <NotificationPopup isHeaderVisible={isHeaderVisible} />
      )}
      {popupVisibility && <Notification />}
      {!isDesktop &&
        isShowFullScreenPopup &&
        (!window.IS_SSR || (window.IS_SSR && isMounted)) && <FullScreenPopup />}
      {!isDesktop &&
        isShowHalfScreenPopup &&
        (!window.IS_SSR || (window.IS_SSR && isMounted)) && <MobilePopupMenu />}
      <Box
        data-role="c-headerWrapper"
        direction="column"
        className={clsx([
          styles.headerWrapper,
          headerColorSchemeClasses[headerColorScheme],
          !headerVisible && styles.hideComponent,
          headerWrapperClassName
        ])}
      >
        {isShowMobileAppBanner && <MobileAppBanner slide={slide} />}
        <Header />
      </Box>
      {isMounted ||
      (!isMounted && (pageShouldBeHydrate || IS_404_PAGE)) ||
      (IS_SERVER_RENDER && (pageShouldBeHydrate || IS_404_PAGE)) ? (
        <Box
          justify="center"
          flex="1 0 auto"
          flexBasis="auto"
          className={`${
            inIframe ? styles.widgetPageWrapper : styles.pageWrapper
          } ${pageWrapperClassName}`}
        >
          {ifPageExist && (
            <>
              {wrapperTypes.map((wrapperId) => {
                const Wrapper = pageWrappersMap[wrapperId];
                return <Wrapper key={wrapperId} />;
              })}
              {children}
            </>
          )}
          {!ifPageExist &&
            (support404page ? (
              <ErrorPage
                errorCode={404}
                text={t('404page.pageNotFound')}
                link={home}
                buttonText={t('404page.home')}
              />
            ) : (
              <LocalizedRedirect to="/" />
            ))}
        </Box>
      ) : (
        <Box align="center" justify="center" className={styles.pageWrapper}>
          <Loader />
        </Box>
      )}
      {snippetId && ifPageExist && !isCustomFooter && !isOnboardingRunning && (
        <SeoSnippet className={styles.seoSnippet} snippetId={snippetId} />
      )}
      {footerVisible && (
        <Box className={styles.footer}>{isMounted ? <Footer /> : null}</Box>
      )}
      {isShowFloatingScrollToTopButton && <ScrollToTopButton />}
      {navMenuVisible && (!isBetSlipOpen || (isBetSlipOpen && fastBet)) && (
        <BottomNavigationMenu />
      )}
      <PWBBonusesNotification />
    </Box>
  );
};

const Wrapper = (props) => {
  const [{ isRunning: isOnboardingRunning }] = useContext(OnboardingContext);
  const { pageType } = useContext(DeviceContext);
  const { page } = useContext(PageContext);
  const { pageConfig = {} } = page || {};
  const isValidPage = page !== null;

  // isDisablePageAutoScroll setting doesn't exist in CMS as of now so had to add another one in otherSettings
  const { routesWithDisabledAutoScroll = [] } = useOtherSettings();
  const { isDisablePageAutoScroll } = pageConfig;
  const isMatchWithDisabledAutoScrollRoutes = !isEmpty(
    useMatchPath(routesWithDisabledAutoScroll, { isReturnFullMatch: true })
  );

  const actualIsDisablePageAutoScroll =
    isOnboardingRunning ||
    isDisablePageAutoScroll ||
    isMatchWithDisabledAutoScrollRoutes;

  useEffect(() => {
    if (isValidPage) {
      try {
        document.getElementById('rootLoader').classList.add('loaderHidden');
      } catch (e) {
        console.warn(e);
      }
    }
  }, [isValidPage]);

  useScrollToTop(pageType, actualIsDisablePageAutoScroll);
  return isValidPage ? (
    <>
      <Container {...{ ...props, isOnboardingRunning }} />
      <DefaultUserNotification />
    </>
  ) : null;
};

export default memo(Wrapper, () => true);
