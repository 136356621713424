import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useComponentConfig } from '@namespace/cms';
import { useUserSegmentsIncludes } from '@namespace/segments';
import { useCasinoSettings } from '../useCasinoSettings';

/* 
  FYI: feed sources by priority (1 - highest | 6 - lowest)
  1 - segmented feed from "opts"
  2 - defaultFeed from "opts"
  3 - segmented feed from casinoSettings
  4 - defaultFeed from casinoSettings
  5 - segmented feed from component config
  6 - defaultFeed from component config
*/

export const useGetFeedName = (casinoType, opts = {}) => {
  const componentConfig = useComponentConfig();
  const {
    segmentedFeeds: casinoSegmentedFeeds,
    defaultFeed
  } = useCasinoSettings(casinoType);
  const isUserSegmentsIncludes = useUserSegmentsIncludes();

  const actualDefaultFeed = opts.defaultFeed || defaultFeed;
  const actualSegmentedFeeds = opts.segmentedFeeds || casinoSegmentedFeeds;

  const segmentedFeeds = [
    actualSegmentedFeeds,
    componentConfig?.segmentedFeeds
  ].find((segmentedFeedSettings) => !isEmpty(segmentedFeedSettings));

  const segmentedFeedsDep = !isEmpty(segmentedFeeds)
    ? segmentedFeeds.join('')
    : segmentedFeeds;

  const segmentedFeedName = useMemo(() => {
    if (isEmpty(segmentedFeeds)) return null;

    const matchSegmentedFeed = segmentedFeeds.find((segmentedFeed) =>
      isUserSegmentsIncludes(segmentedFeed.userSegments)
    );

    return matchSegmentedFeed?.feedName;
  }, [segmentedFeedsDep, isUserSegmentsIncludes]);

  return segmentedFeedName || actualDefaultFeed || componentConfig?.defaultFeed;
};
