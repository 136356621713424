import { useReducer, createContext, useMemo } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import { types } from '../types';
import { shopActions as actionCreators } from '../actions';
import { reducer } from '../reducer';

export const BonusShopContext = createContext();

const initialState = {
  products: [],
  filteredProducts: [],
  priceRange: {},
  categories: [],
  selectedProducts: {},
  userBonusInfo: {},
  totalAmount: 0
};

export const BonusShopProvider = ({ children }) => {
  const shopReducers = useMemo(() => combineReducers(reducer), []);
  const [shopState, shopDispatch] = useReducer(shopReducers, initialState);
  const shopActions = useActions(types, shopDispatch, actionCreators);

  return (
    <BonusShopContext.Provider value={[shopState, shopActions]}>
      {children}
    </BonusShopContext.Provider>
  );
};
