import { useEffect } from 'react';
import { path } from 'ramda';
import { useGeneralRoutes } from '@namespace/cms';
import { useLocation } from '@namespace/i18n';
import { useMatchPath, useHistory } from '@namespace/router';
import { useSportsbookStore } from '../../store';
import { ALL_FILTER_VALUE } from '../../constants';

const Routes = ({ serviceId, servicePath }) => {
  const { replace } = useHistory();
  const { pathname } = useLocation();
  const { liveSport, preMatchSport } = useGeneralRoutes();
  const sportPath = `${serviceId ? liveSport : preMatchSport}`;
  const { sportName } = useMatchPath([`${sportPath}/:sportName`]);
  const firstSport = useSportsbookStore(path(['sports', serviceId, 0]));

  useEffect(() => {
    if (firstSport && !sportName && pathname === `${sportPath}/`) {
      const { sportSlug } = firstSport;
      replace(`${servicePath}/${serviceId ? ALL_FILTER_VALUE : sportSlug}`);
    }
  }, [
    firstSport,
    pathname,
    replace,
    serviceId,
    servicePath,
    sportName,
    sportPath
  ]);

  return null;
};

export default Routes;
