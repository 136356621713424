import { useMemo, useCallback } from 'react';

/**
 * Least Recently Used cache implementation
 * @param initialState: Object|null - initial values for cache
 * @param itemsLimit: number - maximum amount of items in cache
 */
export const useLRUCache = (initialState, itemsLimit = 100) => {
  const cache = useMemo(() => {
    let initCache = {};

    if (initialState) {
      initCache = { ...initialState };
    }

    return initCache;
  }, []);

  const addItem = useCallback(
    (key, item) => {
      const entries = Object.entries(cache);
      if (entries.length >= itemsLimit) {
        const { itemKey: leastUsedItemKey } = entries.reduce(
          (memo, [itemKey, { usages }]) => ({
            itemKey: memo.usages > usages ? itemKey : memo.itemKey,
            usages: memo.usages < usages ? usages : memo.usages
          }),
          {
            itemKey: '',
            usages: 0
          }
        );

        delete cache[leastUsedItemKey];
      }

      cache[key] = { value: item, usages: 1 };
    },
    [itemsLimit]
  );

  const getItem = useCallback(
    ({ key, pathnameChanged }) => {
      const item = cache[key];
      const { value, usages = 0, isSSR = false } = item || {};

      if (!value || (value && isSSR && pathnameChanged)) {
        return null;
      }

      cache[key] = { value, usages: usages + 1, isSSR };

      return value;
    },
    [cache]
  );

  const removeItem = useCallback((key) => delete cache[key], [cache]);

  return [cache, addItem, getItem, removeItem];
};
