import { Controller, useFormContext } from 'react-hook-form';
import { Textarea } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import getFormError from '../../utils/getFormError';
import Control from '../Control';
import useDynamicField from '../../hooks/useDynamicField';
import useCustomError from '../../hooks/useCustomError';
import useOnChangeValidate from '../../hooks/useOnChangeValidate';

const TextareaField = ({
  name,
  label = '',
  dataRole,
  placeholder,
  hint,
  classNames = {},
  rules = {},
  disabled = false,
  autoComplete,
  isOnChangeValidation = false,
  customError = '',
  formProps = {},
  ...props
}) => {
  const { t } = useI18n();
  const methods = useFormContext();
  const {
    control,
    formState: { errors }
  } = methods;
  const { errorMessage, customErrorHandler } = useCustomError(customError);
  const { dynamicValues = {} } = formProps;
  const { wrapperClassName = '', className = '' } = classNames;
  const errorText = getFormError(name, errors, t) || t(errorMessage) || '';

  useDynamicField({ name, dynamicFieldData: dynamicValues[name] });
  useOnChangeValidate({ name, isOnChangeValidation });

  return (
    <Control
      onClick={customErrorHandler}
      error={errorText}
      dataRole={dataRole}
      classNames={{ wrapperClassName }}
      label={t(label)}
    >
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { ref, ...field } }) => (
          <Textarea
            placeholder={t(placeholder)}
            data-role={dataRole}
            hint={t(hint)}
            disabled={disabled}
            className={className}
            intent={errorText ? 'error' : null}
            {...field}
            {...props}
          />
        )}
      />
    </Control>
  );
};

export default TextareaField;
