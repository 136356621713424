import { useEffect, useState } from 'react';
import { usePrevious } from '@namespace/helpers';

export const useOutcomeCoefChange = (coef, timeout = 3000) => {
  const prevCoef = usePrevious(coef);
  const [move, setMove] = useState(null);

  useEffect(() => {
    let timeoutId;

    if (prevCoef && prevCoef !== coef) {
      clearTimeout(timeoutId);
      const diff = Number(coef) - Number(prevCoef);
      setMove(diff > 0 ? 'up' : 'down');
      timeoutId = setTimeout(() => setMove(null), timeout);
    }

    return () => clearTimeout(timeoutId);
  }, [coef, timeout]);

  return move;
};
