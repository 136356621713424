import { memo, useCallback, useContext } from 'react';
import { useI18n } from '@namespace/i18n';
import { AText, Box } from '@alf/uikit';
import { PREDICTIONS_STEPS_ORDER } from '../../constants';
import { PredictionsContext } from '../../store/context';
import MenuItem from './MenuItem';
import styles from './index.module.css';

const PredictionsMenu = ({ className = '' }) => {
  const { t } = useI18n();
  const [state, actions] = useContext(PredictionsContext);
  const { SET_ACTIVE_STEP } = actions;
  const { steps, activeStep, editingInProgress } = state;

  const handleClick = useCallback((step) => () => SET_ACTIVE_STEP(step), [
    SET_ACTIVE_STEP
  ]);

  const getItemClassName = useCallback(
    (round) => {
      const activeClassName = round === activeStep ? styles.activeItem : '';
      const filledClassName =
        steps[round]?.isFilled && round !== activeStep && !editingInProgress
          ? styles.filledStep
          : '';
      return `${styles.item} ${activeClassName} ${filledClassName}`;
    },
    [editingInProgress, activeStep, steps]
  );

  return (
    <Box justify="center" className={`${styles.wrapper} ${className}`}>
      {PREDICTIONS_STEPS_ORDER.map((round) => (
        <MenuItem
          isActive={round === activeStep}
          key={round}
          className={getItemClassName(round)}
          onClick={handleClick(round)}
        >
          <AText breed="BU" size="m" color="btn_primary_txt_color_3">
            {t(`predictionsBlock.menu.round.${round}`)}
          </AText>
        </MenuItem>
      ))}
    </Box>
  );
};

export default memo(PredictionsMenu);
