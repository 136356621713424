import { pathOr } from 'ramda';
import { isEmpty, uniq } from 'lodash';
import { shallow } from 'zustand/shallow';
import { createSelector } from 'reselect';
import { MODE } from '../../../constants';
import { useSportsbookSelector } from '../../store';

export const selectCyberSportCategories = () =>
  createSelector(
    pathOr({}, ['categories', MODE.PRE_MATCH, 'data']),
    pathOr([], ['categories', MODE.PRE_MATCH, 'ids']),
    pathOr({}, ['categories', MODE.IN_PLAY, 'data']),
    pathOr([], ['categories', MODE.IN_PLAY, 'ids']),
    (preMatchData, preMatchIds, liveData, liveIds) =>
      uniq([...preMatchIds, ...liveIds]).map((categoryId) => {
        const preMatchCategory = preMatchData[categoryId] || {};
        const liveCategory = liveData[categoryId] || {};
        const mixCategory = { ...preMatchCategory, ...liveCategory };

        return {
          ...mixCategory,
          sportSlug: mixCategory.categoryId,
          sportName: mixCategory.categoryName,
          sportId: mixCategory.categoryId,
          eventCount:
            !isEmpty(preMatchCategory) && !isEmpty(liveCategory)
              ? preMatchCategory.eventCount + liveCategory.eventCount
              : preMatchCategory.eventCount || liveCategory.eventCount
        };
      })
  );

export const useCyberSportCategories = () =>
  useSportsbookSelector(selectCyberSportCategories, [], shallow);
