import { types } from '../types';

export const initialState = {
  navMenuVisible: false
};

export const reducers = {
  [types.SET_NAV_MENU_VISIBLE]: (state, navMenuVisible) => ({
    ...state,
    navMenuVisible
  })
};
