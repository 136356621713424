import { get } from 'lodash';
import { useSiteSettings } from '@namespace/cms';

const useSportsSettings = () => {
  const { otherSettings } = useSiteSettings();

  return get(otherSettings, 'sports', {});
};

export default useSportsSettings;
