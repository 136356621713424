import { useGeneralRoutes } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { Box, Button } from '@alf/uikit';

const CancelButton = ({ className = '', onCancel = () => {}, processing }) => {
  const { t } = useI18n();
  const { login } = useGeneralRoutes();
  const { push } = useHistory();
  const handleClick = () => {
    onCancel();
    push(login);
  };
  return (
    <Box align="center" justify="center">
      <Button
        className={className}
        intent="quaternary"
        size="s"
        actionType="color3"
        onClick={handleClick}
        processing={processing}
      >
        {t('passwordRecovery.cancelButton')}
      </Button>
    </Box>
  );
};
export default CancelButton;
