import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { useAdvFilterStore } from '../../../../../../storeAdvFilter';
import { useDistinctSelectedSportIds } from '../../hooks/useDistinctSelectedSportIds';
import { useAdvFilterSettings } from '../../hooks/useAdvFilterSettings';

export const SportInfoMessage = () => {
  const { t } = useI18n();
  const advFilterSportsById = useAdvFilterStore('advFilterSportsById');
  const distinctSportIds = useDistinctSelectedSportIds();
  const { maxSportsCount } = useAdvFilterSettings();

  if (distinctSportIds.length < maxSportsCount) {
    return null;
  }

  let color;
  let message;

  if (distinctSportIds.length === maxSportsCount) {
    color = 'po_txt_color_3';
    const sportNames = distinctSportIds.map(
      (sportId) => advFilterSportsById[sportId]?.name
    );
    message = t('sportsBook.sportsMenu.advFilter.messageLimit', [
      sportNames.join(', ')
    ]);
  } else {
    color = 'state_warning';
    message = t('sportsBook.sportsMenu.advFilter.messageLimitExceeded', [
      maxSportsCount
    ]);
  }

  return (
    <Box>
      <AText breed="R" size="s" color={color}>
        {message}
      </AText>
    </Box>
  );
};
