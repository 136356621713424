import { useSiteSettings, useOtherSettings } from '@namespace/cms';
import { get, isEmpty } from 'lodash';

const useBetSlipConfig = () => {
  const otherSettings = useOtherSettings();
  const betSlipConfigFromOtherSettings = get(
    otherSettings,
    'betSlipConfig',
    {}
  );
  const siteSettings = useSiteSettings();
  const betSlipConfig = get(siteSettings, 'sports', {});
  const { additionalBetslipSettings } = betSlipConfig;
  const {
    tabs,
    defaultTabIndex,
    privateTabs,
    footerComponents
  } = additionalBetslipSettings;
  const tabsConfig = {
    components: tabs.map((type) => ({
      type,
      ...(betSlipConfig[type] && { config: betSlipConfig[type] })
    })),
    defaultTabIndex,
    footerComponents,
    privateTabs
  };

  return !isEmpty(betSlipConfig)
    ? {
        ...betSlipConfig,
        tabsConfig
      }
    : betSlipConfigFromOtherSettings;
};

export default useBetSlipConfig;
