import { isEmpty } from 'lodash';
import { getCookie, window } from '@namespace/helpers';
import { IS_WIDGET } from '../../constants';

export const getWidgetInitParams = () => {
  let params = {};

  if (!IS_WIDGET) {
    return params;
  }

  try {
    const urlParams = new URLSearchParams(window.location.search);
    /*
      ?widget={"ssid":"qwerty"}
      ?widget={"parentUrl":"url"}
      ?widget={"token":"c5a02ef10e7450717fee7410be67af94.1qD4ejXF99neBOWWTfEsLoIHafB"}
    */
    params = JSON.parse(urlParams.get('widget')) || {};
    if (isEmpty(params)) {
      params = { ssid: getCookie('PHPSESSID', null) };
    }
  } catch (e) {
    console.warn(e);
  }

  return params;
};
