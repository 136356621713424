import { JACKPOT_LEVEL_TO_TYPE, JACKPOT_TYPES_WEIGHT } from './constants';

export const getJackpotIdentifier = (providerId, jackpotCode) => {
  return jackpotCode ? `${providerId}_${jackpotCode}` : providerId;
};

export const formatJackpot = (jackpot = {}, jackpotIdentifier) => {
  const formattedJackpot = {};

  for (const [jackpotLevel, jackpotLevelValue] of Object.entries(jackpot)) {
    if (JACKPOT_LEVEL_TO_TYPE[jackpotIdentifier]?.[jackpotLevel]) {
      formattedJackpot[
        JACKPOT_LEVEL_TO_TYPE[jackpotIdentifier][jackpotLevel]
      ] = jackpotLevelValue;
    }
  }

  return formattedJackpot;
};

export const getSortedJackpotTypes = (
  formattedJackpot,
  jackpotIdentifier,
  order = 'asc'
) => {
  const isAsc = order === 'asc';

  return Object.keys(formattedJackpot).sort((aType, bType) =>
    // eslint-disable-next-line no-nested-ternary
    JACKPOT_TYPES_WEIGHT[jackpotIdentifier]?.[bType] >
    JACKPOT_TYPES_WEIGHT[jackpotIdentifier]?.[aType]
      ? isAsc
        ? -1
        : 1
      : isAsc
      ? 1
      : -1
  );
};

export const getJackpotTimeout = (type, jackpotIdentifier) => {
  if (JACKPOT_TYPES_WEIGHT[jackpotIdentifier]?.[type]) {
    const weight = JACKPOT_TYPES_WEIGHT[jackpotIdentifier][type] * 1500;
    return Math.floor(weight + Math.random() * 1500);
  }
  return null;
};
