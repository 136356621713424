import { useCallback, useEffect, useState } from 'react';
import { CommonLoader } from '@namespace/common';
import { useI18n } from '@namespace/i18n';
import { Overlay, Box, AText, Button } from '@alf/uikit';
import { window } from '@namespace/helpers';
import { useConfirmDocuments } from '../../../hooks';
import styles from '../index.module.css';

const Jumio = () => {
  const { t } = useI18n();
  const { isFetching, getUrl } = useConfirmDocuments();
  const [url, setUrl] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const urlData = await getUrl();
        setUrl(urlData);
      } catch (e) {
        console.warn(e);
      }
    })();
  }, [getUrl]);

  const handleMakePhoto = useCallback(() => {
    window.open(url, '_self');
  }, [url]);

  return (
    <Box className={styles.formWrapper} direction="column">
      {isFetching && (
        <Overlay>
          <CommonLoader />
        </Overlay>
      )}
      <Box direction="column" bottom="s">
        <AText
          component="p"
          className={styles.header}
          color="po_txt_color_1"
          breed="B"
          size="l"
          align="center"
        >
          {t('personalOffice.documents.takePhoto')}
        </AText>
        <AText
          component="p"
          className={styles.subHeaderJumio}
          color="po_txt_color_1"
          breed="R"
          size="l"
          align="center"
        >
          {t('personalOffice.documents.message')}
        </AText>
      </Box>
      <Button
        className={styles.submitButton}
        onClick={handleMakePhoto}
        disabled={!url}
        size="l"
      >
        {t('personalOffice.documents.makePhoto')}
      </Button>
    </Box>
  );
};

export default Jumio;
