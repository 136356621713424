import { useEffect, useContext, useCallback } from 'react';
import { UserContext } from '@namespace/user';
import { bulletSubscribe, bulletUnsubscribe } from '@namespace/socket';

const USER_STATUSES_CHANGED_EVENT = 'user_statuses_changed';

export const useStatusesUpdate = () => {
  const [, userActions] = useContext(UserContext);

  const handlUserStatusesChange = useCallback(
    async ({ statuses }) => {
      await userActions.FETCH_USER_DATA();

      userActions.SET_ACCOUNT_STATUSES(statuses);
    },
    [userActions]
  );

  useEffect(() => {
    bulletSubscribe({
      name: USER_STATUSES_CHANGED_EVENT,
      types: [USER_STATUSES_CHANGED_EVENT],
      callback: handlUserStatusesChange
    });

    return () => {
      bulletUnsubscribe(USER_STATUSES_CHANGED_EVENT);
    };
  }, [handlUserStatusesChange]);
};
