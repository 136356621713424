import { useFormContext } from 'react-hook-form';
import NameFieldInput from '../NameFieldInput';
import { isMiddleName } from './utils';

const MiddleNameInput = (props) => {
  const { watch } = useFormContext();
  const chosenCountry = watch('country');

  if (isMiddleName(chosenCountry)) {
    return <NameFieldInput {...props} />;
  }
  return null;
};

export default MiddleNameInput;
