import { useCallback, useMemo } from 'react';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { useGeneralRoutes } from '@namespace/cms';
import { SearchResultsList } from '@alf/uikit';
import { getSportIcon } from '@namespace/icons2';
import { selectSportById, useSportsbookStore } from '../../../store';
import { TITLES, TYPES } from '../constants';

const EventsGroup = ({ type, events, query, onSelect = () => {} }) => {
  const { t } = useI18n();
  const { preMatchEvent, liveEvent } = useGeneralRoutes();
  const { push } = useHistory();

  const handleClick = useCallback(
    ({ eventId, sportId }) => {
      const sport = selectSportById(
        sportId,
        type
      )(useSportsbookStore.getState());
      const slug = sport?.sportSlug;
      onSelect();
      push(
        `${
          type === TYPES.PRE_BET ? preMatchEvent : liveEvent
        }/${slug}/${eventId}`
      );
    },
    [type]
  );

  const memoEvents = useMemo(() => {
    return events.map((item) => ({
      ...item,
      title: item.eventName,
      subTitle: item.tournamentName,
      iconName: getSportIcon(item.sportId)
    }));
  }, [events]);

  return (
    <SearchResultsList
      title={t(TITLES[type])}
      items={memoEvents}
      onSelect={handleClick}
      query={query}
    />
  );
};

export default EventsGroup;
