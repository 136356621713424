import { CommonLoader } from '@namespace/common';
import { useI18n } from '@namespace/i18n';
import { Overlay, InfoMessage, Box } from '@alf/uikit';
import {
  FORM_COMPONENTS_TYPES,
  FORM_VALIDATION_TYPES,
  FormBuilder
} from '@namespace/forms';
import { PAN_KEEPER_TYPES } from '@namespace/pankeeper';
import {
  PAYMENT_SYSTEMS_COMPONENTS_MAP,
  paymentSystemsComponentsMap
} from '../constants';
import { usePanKeeperTypeChange } from '../../../../hooks/usePanKeeperTypeChange';
import useServiceLimits from '../../../../hooks/useServiceLimits';
import styles from '../index.module.css';

const BankCard = ({ wallet, onWithdraw }) => {
  const { t } = useI18n();
  const {
    currency,
    walletId,
    baseBalance,
    paymentInstrumentName,
    minWithdraw: minWithdAmount,
    maxWithdraw: maxWithdAmount,
    serviceId
  } = wallet;
  const { minWithdraw: minWithd, maxWithdraw: maxWithd } = useServiceLimits(
    serviceId
  );
  const minWithdraw = minWithd || minWithdAmount;
  const maxWithdraw = maxWithd || maxWithdAmount;
  const {
    isFetching,
    isCardInputReady,
    setPanKeeperInstance,
    handleSubmit,
    submitButtonValue
  } = usePanKeeperTypeChange({ onWithdraw });

  const minAmount = Number(minWithdraw || 0);

  const defaultValues = {
    amount: minAmount,
    password: '',
    wallet: `${paymentInstrumentName} ${walletId}`
  };

  const componentsList = [
    {
      type: FORM_COMPONENTS_TYPES.INPUT_FIELD,
      name: 'wallet',
      label: 'balance.withdraw.form.cardDescription',
      disabled: true,
      classNames: {
        wrapperClassName: styles.fieldWrap
      }
    },
    {
      type: PAYMENT_SYSTEMS_COMPONENTS_MAP.PAN_KEEPER_FORM,
      setInstance: setPanKeeperInstance,
      initialPanKeeperType: PAN_KEEPER_TYPES.WITHDRAW
    },
    {
      type: FORM_COMPONENTS_TYPES.AMOUNT_INPUT_FIELD,
      name: 'amount',
      currency,
      label: 'balance.withdraw.form.amount',
      placeholder: 'balance.withdraw.creditCard.amountPlaceholder',
      min: minAmount,
      validationTypes: [
        FORM_VALIDATION_TYPES.REQUIRED,
        {
          type: FORM_VALIDATION_TYPES.MIN_VALIDATOR,
          parameters: [
            minWithdraw || minAmount,
            'balance.withdraw.form.minAmount',
            [currency]
          ]
        },
        {
          type: FORM_VALIDATION_TYPES.MAX_VALIDATOR,
          parameters: [
            maxWithdraw || baseBalance,
            'balance.withdraw.form.maxAmount',
            [currency]
          ]
        }
      ],
      classNames: {
        containerClassName: styles.fieldWrap,
        controlClassName: styles.controlWrap
      },
      isOnChangeValidation: true
    },
    {
      type: FORM_COMPONENTS_TYPES.PASSWORD_FIELD,
      name: 'password',
      classNames: {
        wrapperClassName: styles.fieldWrap
      },
      rulesList: [],
      label: 'balance.withdraw.form.password',
      placeholder: 'balance.withdraw.form.enterAccountPassword',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED]
    },
    {
      type: FORM_COMPONENTS_TYPES.SUBMIT_BUTTON,
      value: submitButtonValue,
      classNames: {
        className: styles.withdrawButton
      },
      disabled: !isCardInputReady
    }
  ];

  if (isFetching) {
    return (
      <Overlay>
        <CommonLoader />
      </Overlay>
    );
  }

  return (
    <>
      <InfoMessage icon="info" className={styles.info}>
        {t('balance.withdraw.bc.info')}
      </InfoMessage>
      <Box align="start">
        <FormBuilder
          wrapperClassName={styles.form}
          components={componentsList}
          componentsMap={paymentSystemsComponentsMap}
          onSubmit={handleSubmit}
          formConfig={{ mode: 'onBlur', defaultValues }}
        />
      </Box>
    </>
  );
};

export default BankCard;
