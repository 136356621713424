import { Box, Button } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { useI18n } from '@namespace/i18n';
import { COLOR_SCHEME } from '@namespace/themes';
import { DayPickerFilter } from './components/DayPickerFilter';
import { TimeFilter } from './components/TimeFilter';
import { SportsFilter } from './components/SportsFilter';
import styles from './index.module.css';

export const Filters = ({
  sportsList,
  date,
  selectDay,
  selectHours,
  selectedHours,
  selectSport,
  selectedSports,
  // setIsOnlyWithVideo, // TODO: uncomment this after change api for live calendar
  // isOnlyWithVideo,
  resetFilters
}) => {
  const { t } = useI18n();

  // <Checkbox // TODO: uncomment and add this to rendered component after change api for live calendar
  //   className={styles.checkboxContainer}
  //   label={t('live.calendar.videoCheckbox')}
  //   classNames={{
  //     labelClassName: styles.checkboxLabel
  //   }}
  //   onChange={() => setIsOnlyWithVideo(!isOnlyWithVideo)}
  //   checked={isOnlyWithVideo}
  // />

  return (
    <Box padding="s" align="center" className={styles.container}>
      <Box
        className={styles.fieldContainer}
        data-role="live-calendar-filter-date"
      >
        <DayPickerFilter selectDay={selectDay} date={date} />
      </Box>
      <Box className={styles.fieldContainer}>
        <TimeFilter
          dataRole="live-calendar-filter-hours"
          selectHours={selectHours}
          selectedHours={selectedHours}
        />
      </Box>
      <Box align="center" justify="between" className={styles.buttonsContainer}>
        <Box
          className={`${styles.fieldContainer} ${styles.fieldContainerSports}`}
        >
          <SportsFilter
            dataRole="live-calendar-filter-sports"
            sportsList={sportsList}
            selectSport={selectSport}
            selectedSports={selectedSports}
            intentType={COLOR_SCHEME.SECONDARY}
          />
        </Box>
        <Button
          data-role="live-calendar-btn-reset"
          onClick={resetFilters}
          intent="quaternary"
          actionType="color3"
          size="s"
          className={styles.resetButton}
          iconLeft={<Icon size="m" name="icons/general/action/close" />}
        >
          {t('live.calendar.resetAllButton')}
        </Button>
      </Box>
    </Box>
  );
};
