import { useI18n } from '@namespace/i18n';
import {
  FORM_COMPONENTS_TYPES,
  FORM_VALIDATION_TYPES,
  FormBuilder
} from '@namespace/forms';
import styles from './index.module.css';

const EnterSecretQuestion = ({
  onSuccess = () => {},
  secretQuestion = '',
  errorCode = ''
}) => {
  const { t } = useI18n();
  const defaultValues = {
    secretAnswer: ''
  };
  const checkKey = secretQuestion.toLowerCase().replace(/\W/g, '');
  const question = checkKey
    ? `register.secretQuestions.${checkKey}`
    : secretQuestion;

  const componentsList = [
    {
      type: FORM_COMPONENTS_TYPES.INPUT_FIELD,
      name: 'secretAnswer',
      label: question,
      placeholder: 'passwordRecovery.question.placeholder',
      classNames: {
        wrapperClassName: styles.inputWrapper
      },
      allowClean: true,
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
      size: 'm'
    }
  ];

  if (errorCode) {
    componentsList.push({
      type: FORM_COMPONENTS_TYPES.INFO_MESSAGE,
      icon: 'error',
      label: t(`accountingMessages.${errorCode}`),
      classNames: {
        wrapperClassName: styles.errorWrap,
        labelClassName: styles.error
      }
    });
  }

  componentsList.push({
    type: FORM_COMPONENTS_TYPES.SUBMIT_BUTTON,
    size: 'xl',
    value: 'passwordRecovery.continueButton',
    classNames: {
      className: styles.submitButton
    }
  });

  return (
    <>
      <FormBuilder
        components={componentsList}
        formConfig={{ defaultValues }}
        onSubmit={onSuccess}
      />
    </>
  );
};

export default EnterSecretQuestion;
