import { useContext } from 'react';
import { SiteSettingsContext } from '../../store/siteSettings/context';

const useOtherSettings = () => {
  const [settings] = useContext(SiteSettingsContext);
  const { otherSettings = {} } = settings || {};
  return otherSettings;
};

export default useOtherSettings;
