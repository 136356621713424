import { useState, useCallback } from 'react';
import { scrollTo } from '@namespace/helpers';
import { DEFAULT_PAGE_SIZE } from '../../constants';

const defaults = {
  currentPage: 1,
  pageSize: DEFAULT_PAGE_SIZE
};

export const useFilter = (defaultFilter, isCallScrollTo = true) => {
  const [filter, setFilter] = useState({ ...defaults, ...defaultFilter });
  const setPage = useCallback(
    (num) => {
      setFilter((currentFilter) => ({
        ...currentFilter,
        currentPage: num
      }));
      if (isCallScrollTo) {
        scrollTo();
      }
    },
    [isCallScrollTo]
  );

  const onFilterChange = useCallback((newFilter) => {
    setFilter((currentFilter) => ({
      ...currentFilter,
      ...newFilter
    }));
  }, []);

  return {
    filter,
    setPage,
    onFilterChange
  };
};
