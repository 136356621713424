import { useI18n } from '@namespace/i18n';
import { Box, Label } from '@alf/uikit';

const FormRow = ({
  name,
  type,
  classNames = {},
  label = '',
  children = null,
  dataRole
}) => {
  const { t } = useI18n();
  const {
    containerClassName = '',
    wrapperClassName = '',
    labelClassName = ''
  } = classNames;
  const id = `wrapper-${name || type}`;

  return (
    <Box direction="column" key={id} className={wrapperClassName}>
      {label && (
        <Label
          data-role={dataRole && `${dataRole}-label`}
          htmlFor={id}
          className={labelClassName}
        >
          {t(label)}
        </Label>
      )}
      <Box className={containerClassName}>{children}</Box>
    </Box>
  );
};

export default FormRow;
