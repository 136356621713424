import { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@namespace/icons2';
import { document } from '@namespace/helpers';
import AText from '../AText';
import Input from '../Input';
import styles from './index.module.css';

const InputCopy = ({ tooltipText = '', ...props }) => {
  const [isDisplayTooltip, setIsDisplayTooltip] = useState(false);
  const inputRef = useRef(null);

  const displayTooltip = useCallback(() => {
    setIsDisplayTooltip(true);
    setTimeout(() => {
      setIsDisplayTooltip(false);
    }, 1000);
  }, [setIsDisplayTooltip]);

  const onCopy = useCallback(async () => {
    const inputValue = inputRef?.current?.value;
    if (inputValue) {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(inputValue);
        await displayTooltip();
      } else {
        inputRef.current.select();
        const successful = document.execCommand('copy');
        if (successful) {
          displayTooltip();
        }
      }
    }
  }, [inputRef, displayTooltip]);

  return (
    <div className={styles.container}>
      {isDisplayTooltip && (
        <div className={styles.tooltip}>
          <AText size="m" color="po_txt_color_2">
            {tooltipText}
          </AText>
        </div>
      )}
      <Input
        inputRef={inputRef}
        readOnly={true}
        icon={
          <Icon
            size="m"
            name="icons/general/action/copy"
            color="po_txt_color_1"
            onClick={onCopy}
            className={styles.copyIcon}
          />
        }
        {...props}
      />
    </div>
  );
};

InputCopy.propTypes = {
  tooltipText: PropTypes.string.isRequired
};

export default InputCopy;
