import { memo, useContext, useMemo } from 'react';
import { pathOr } from 'ramda';
import {
  CoreList,
  SportList,
  UserSettingsBurger,
  useUserSettings
} from '@namespace/common';
import { SiteSettingsContext, useGeneralRoutes } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { AText, Box } from '@alf/uikit';
import { useUserSegmentsIncludes } from '@namespace/segments';
import ListAllItem from '../List/ListAllItem';
import PinnedIcon from '../../../common/PinnedIcon';
import { useSportsbookStore } from '../../../store';
import SportsBookSearch from '../../SportsBookSearch';
import { MODE, SPORT_ICONS } from '../../../constants';
import FavoritesLink from '../../../common/FavoritesLink';
import styles from './index.module.css';

const LiveBurger = ({ config = {}, onSearchClicked = () => {}, onClick }) => {
  const {
    isShowAll = false,
    subMenu = [],
    isDisplayLiveCalendar = true
  } = config;
  const { liveSport, liveSportAll, liveFavorites } = useGeneralRoutes();
  const { t } = useI18n();
  const sports = useSportsbookStore(pathOr([], ['sports', MODE.IN_PLAY]));
  const favoritesSports = useSportsbookStore(
    pathOr([], ['favorites', 'sports'])
  );
  const [{ menuTags = {} }] = useContext(SiteSettingsContext);
  const filterSegments = useUserSegmentsIncludes();
  const subMenuItems = useMemo(
    () =>
      subMenu
        .map(({ id, link, title, tag, logoUrl, ...rest }) => ({
          id,
          link,
          title: t(title),
          tag: tag ? t(`burger.submenu.${tag}`) : '',
          tagColor: menuTags[tag]?.tagBackground,
          logoUrl,
          ...rest
        }))
        .filter((item) => filterSegments(item.userSegments)),
    [filterSegments, menuTags, subMenu, t]
  );

  const items = useMemo(
    () =>
      sports
        .map(({ sportId, sportName, sportSlug, eventCount }) => ({
          id: sportId,
          icon: SPORT_ICONS[sportId] || SPORT_ICONS.DEFAULT,
          link: `${liveSport}/${sportSlug}`,
          title: sportName,
          count: eventCount,
          isPinned: favoritesSports.includes(sportId)
        }))
        .sort((a, b) => b.isPinned - a.isPinned),
    [sports, liveSport, favoritesSports]
  );
  const allSportsClasses = useMemo(
    () => ({
      itemClass: styles.item,
      activeClass: styles.active,
      itemRow: styles.itemLiveAll
    }),
    []
  );
  const classNames = useMemo(
    () => ({
      iconClass: styles.icon
    }),
    []
  );

  const {
    showTimeZoneSelector,
    showCoefSwitcher,
    showLineSettings,
    showSettings
  } = useUserSettings();

  return (
    <>
      <SportsBookSearch onClick={onSearchClicked} />
      <div className={styles.ItemsWrapper}>
        <Box bottom="s" direction="column">
          <UserSettingsBurger
            showTimeZoneSelector={showTimeZoneSelector}
            showCoefSwitcher={showCoefSwitcher}
            showLineSettings={showLineSettings}
            showSettings={showSettings}
            isDisplayLiveCalendar={isDisplayLiveCalendar}
          />
        </Box>
        <Box>
          <CoreList items={subMenuItems} onClick={onClick} />
        </Box>
        <Box className={styles.sportItemTitle} align="center">
          <Box top="s" bottom="s">
            <AText breed="BU" size="l" color="sm_txt_color_3">
              {t('burgerMenu.live.menu.title')}
            </AText>
            <AText
              breed="RU"
              size="l"
              className={styles.sportItemSubTitle}
              color="sm_txt_color_3"
            >
              {t('burgerMenu.live.menu.subTitle')}
            </AText>
          </Box>
        </Box>
        {isShowAll && (
          <ListAllItem
            route={liveSportAll}
            title={t('sportsBook.menu.allSports')}
            classNames={allSportsClasses}
            withIcons={true}
            onClick={onClick}
            dataRole="live-burger"
          />
        )}
        <FavoritesLink
          to={liveFavorites}
          title={t('sportsbook.favorites')}
          className={styles.favoriteItem}
          onClick={onClick}
        />
        <SportList
          items={items}
          isShowPinned={true}
          withIcons={true}
          classNames={classNames}
          PinComponent={PinnedIcon}
          onClick={onClick}
        />
      </div>
    </>
  );
};

export default memo(LiveBurger);
