import { stores } from '@namespace/helpers';
import { createClientStore } from './createClientStore';
import { createServerStore } from './createServerStore';

export const createHookStore = (storeName, initialState) => {
  let useIsomorphicBoundStore;

  if (process.env.REACT_APP_ENV === 'node') {
    useIsomorphicBoundStore = createServerStore(storeName, initialState);
  } else {
    useIsomorphicBoundStore = createClientStore(storeName, initialState);
  }

  if (process.env.NODE_ENV === 'development') {
    // check if we accidentally create same store twice
    if (stores[storeName] != null) {
      console.warn(`Duplicate store initialization detected: ${storeName}.`);
    }
  }

  // todo for some reason 'stores' is imported as undefined when running jest tests. Need to find why and remove this condition
  //  This is in fact the result of circular dependencies: solve them -> problem should go.
  if (process.env.NODE_ENV !== 'test') {
    stores[storeName] = useIsomorphicBoundStore;
  }

  return useIsomorphicBoundStore;
};
