import { Box } from '@alf/uikit';
import EventParticipants from '../../../../../EventParticipants';
import LiveScore from '../../../../../LiveScore';
import PreMatchEventState from './components/PreMatchEventState';
import LiveEventState from './components/LiveEventState';
import { useEventLink } from '../../../../../../hooks/useEventLink';
import { MODE } from '../../../../../../constants';
import styles from './index.module.css';

const Event = ({ event }) => {
  const {
    serviceId,
    canceled = false,
    finished = false,
    inprogress = false,
    eventId
  } = event;
  const eventLink = useEventLink({ eventId });
  const isLive = serviceId === MODE.IN_PLAY;
  const isEmptyState = canceled || finished || inprogress;

  return (
    <Box align="center">
      <Box justify="center" className={styles.eventStateContainer}>
        {isLive || isEmptyState ? (
          <LiveEventState event={event} />
        ) : (
          <PreMatchEventState event={event} />
        )}
      </Box>
      <EventParticipants
        eventId={eventId}
        isActiveLink={true}
        linkToEventDetail={eventLink}
        isDisplayServing={false}
      />
      <LiveScore eventId={event.eventId} isShortVersion={true} />
    </Box>
  );
};

export default Event;
