import { Box } from '@alf/uikit';
import styles from './index.module.css';

const Skelet = ({ className }) => {
  return (
    <Box className={styles.wrapperSkelet} direction="column">
      {Array(20)
        .fill(1)
        .map((_, key) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={key}
            className={`${styles.skelet}  ${styles.skeletBlock} ${className}`}
          />
        ))}
    </Box>
  );
};

export default Skelet;
