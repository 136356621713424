import { COMPONENTS_TYPES } from '@namespace/cms';
import ProfileData from '../PersonalOffice/ProfileData';
import PasswordRecovery from '../PasswordRecovery';
import Register from '../Register';
import RegisterSuccess from '../RegisterSuccess';
import FooterMenu from '../FooterMenu';
import Footer from '../Footer';
import Logo from '../Header/components/Logo';
import Language from '../Header/components/Language';

export const pageComponentsMap = {
  [COMPONENTS_TYPES.PROFILE_DATA]: ProfileData,
  [COMPONENTS_TYPES.PASSWORD_RECOVERY]: PasswordRecovery,
  [COMPONENTS_TYPES.REGISTER]: Register,
  [COMPONENTS_TYPES.REGISTER_SUCCESS]: RegisterSuccess,
  [COMPONENTS_TYPES.LOGO]: Logo,
  [COMPONENTS_TYPES.FOOTER_MENU]: FooterMenu,
  [COMPONENTS_TYPES.FOOTER]: Footer,
  [COMPONENTS_TYPES.LANGUAGE]: Language
};
