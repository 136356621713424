import { uniq } from 'lodash';
import { types } from '../types';
import { normalize } from './utils';

export const initialState = {
  expressDay: {},
  isBetsFetching: false,
  bets: { ids: [], data: {} },
  casinoBetsHistory: [],
  details: {},
  multiSystemDetails: {},
  roundData: {},
  gameRounds: [],
  filterData: { gamesName: [], providersName: [] },
  gameRoundsName: { gameName: '', providerName: '' },
  providersName: [],
  isEmptyBets: false,
  isShowMoreRounds: false
};

export const reducers = {
  [types.SET_EXPRESS_DAY]: (state, expressDay) => ({
    ...state,
    expressDay
  }),
  [types.SET_BETS_FETCHING]: (state, isFetching) => ({
    ...state,
    isBetsFetching: isFetching
  }),
  [types.SET_BETS]: (state, { elements, totalCount, reset }) => {
    const normalizeElements = normalize(elements);
    const { ids, data } = normalizeElements;
    const newData = reset
      ? normalizeElements
      : {
          data: {
            ...state.bets.data,
            ...data
          },
          ids: uniq([...state.bets.ids, ...ids])
        };

    return {
      ...state,
      betsCount: totalCount,
      bets: newData
    };
  },
  [types.UPDATE_BETS_ITEM]: (state, elements) => {
    const normalizeElements = normalize(elements);
    const { ids, data } = normalizeElements;
    const newData = { ...state.bets.data };
    for (const id of ids) {
      newData[id] = {
        ...newData[id],
        ...data[id],
        ...(data[id].cardResult === 'cashout'
          ? { cashout: { code: -24, message: 'Forbidden' } }
          : {})
      };
    }

    return {
      ...state,
      bets: { ...state.bets, data: newData }
    };
  },

  [types.SET_BET_DETAILS]: (state, { betId, betDetails }) => ({
    ...state,
    details: {
      ...state.details,
      [betId]: betDetails
    }
  }),
  [types.SET_BET_DETAILS_MULTI_SYSTEM]: (state, { betId, multiSystem }) => ({
    ...state,
    multiSystemDetails: {
      ...state.multiSystemDetails,
      [betId]: multiSystem
    }
  }),
  [types.SET_CASINO_BETS_HISTORY]: (
    state,
    { newData, currentPage, limit, pageLimit, isClearData, indexedPageData }
  ) => {
    const limitedData =
      currentPage > pageLimit ? indexedPageData.slice(limit) : indexedPageData;
    const newDates = isClearData
      ? limitedData
      : [...state.casinoBetsHistory, ...indexedPageData];
    return {
      ...state,
      casinoBetsHistory: newDates,
      roundData: newData.roundData,
      isEmptyBets: newData.isEmptyBets,
      providersName: newData.providersName,
      isBetsFetching: false
    };
  },
  [types.SET_CASINO_GAME_ROUNDS]: (
    state,
    { gameRounds, names, isShowMoreRounds, isClearData }
  ) => ({
    ...state,
    gameRounds: isClearData ? gameRounds : [...state.gameRounds, ...gameRounds],
    gameRoundsName: names,
    isShowMoreRounds,
    isBetsFetching: false
  }),
  [types.SET_FILTER_DATA]: (state, { filterData }) => ({
    ...state,
    filterData
  })
};
