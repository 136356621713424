import { useReducer, createContext } from 'react';
import configReducer from '../reducer';

export const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
  const [
    { paymentSystems = [], servicesLimits = {} },
    configDispatch
  ] = useReducer(configReducer, {});

  return (
    <ConfigContext.Provider
      value={{ paymentSystems, servicesLimits, configDispatch }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
