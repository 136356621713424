/**
 * @typedef ButtonConfig
 * @type {Object}
 * @property {String} [type] button type
 * @property {Boolean} [noRedirect] prevent end-tour redirect on button click
 */

/**
 * @typedef TooltipPlacements
 * @type {('top'|'top-start'|'top-end'|'bottom'|'bottom-start'|'bottom-end'|'left'|'left-start'|'left-end'|'right'|'right-start'|'right-end'|null)}
 */

/**
 * @typedef TourStep
 * @type {Object}
 * @property {String} id Step id, unique within tour
 * @property {String} [navigate] URL to navigate before step
 * @property {Boolean} [navigateExact=false] check exact URL match when deciding whether to navigate or not
 * @property {String|Array<String>} [target] - target element(s) to attach popup(s) to.
 * @property {String|Array<String>} [trigger] - trigger element(s) which will be `click()`ed to open some menus/popups
 * @property {String|Array<String>} [trigger2] - secondary trigger. It's clicked right after the first one and in reverse order on unload.
 * @property {Number} [triggerDelay] delays entire step execution except navigation for a specified time(ms). Used to overcome page loading problems when we need to ensure page is actually ready for the step to process. This includes scroll to el problems, trigger problems, etc.
 * @property {String} [title] popup title
 * @property {String|React.ReactElement} [content] popup content
 * @property {Array<String>} [links] - array of links for string content with placeholders. Link index is specified in placeholder tag as a number. Example: `{LINK:2}link text{LINK}`.
 * @property {Object} [contentProps] - arbitrary object containing extra content props for Component content.
 * @property {Boolean|String|Array<String>} [waitFor] element to wait for before rendering step. If true - uses {@link TourStep.target}.
 * @property {Boolean|String} [scrollTo=true] Scroll to element, by default - to first {@link TourStep.target}
 * @property {Number} [scrollOffset] - scroll offset from page top to scroll to element, px.
 * @property {ButtonConfig} [leftButton] custom left button
 * @property {ButtonConfig} [rightButton] custom right button
 * @property {TooltipPlacements} [allowedPlacements] allowed tooltip placements
 * @property {TooltipPlacements} [preferredPlacements] preferred tooltip placements
 * @property {('start'|'end'|null)} [tooltipAlignment] tooltip alignment
 * @property {String} [width] - custom tooltip width. Can be any valid CSS length value.
 * @property {Number} [padding] tooltip padding, px. Defines a distance from element.
 * @property {Number} [spotlightBorderRadius] spotlight border radius, px.
 * @property {Boolean} [interceptActions=true] prevent clicks on targets from triggering their native actions
 * @property {Boolean} [showDots=true] tells whether should we show dots at the bottom of tooltip or not.
 * @property {Boolean|String|Object} [mocks] mocks for current step
 */

/**
 * @type {TourStep}
 */
export const step = {
  navigateExact: false,
  scrollTo: true,
  interceptActions: true
};
