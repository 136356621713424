import { useOtherSettings } from '@namespace/cms';
import { get } from 'lodash';
import NotificationBanner from '../../common/NotificationBanner';
import styles from './index.module.css';
import newStyles from './indexNew.module.css';

const MaintenanceNotification = () => {
  const otherSettings = useOtherSettings();
  const maintenanceConfig = get(otherSettings, 'maintenanceNotification', {});

  const {
    snippetId,
    expires,
    startDate,
    endDate,
    isNewPopup = false
  } = maintenanceConfig;

  const configNewPopup = {
    closeIconClass: newStyles.closeIcon,
    iconPositionClass: newStyles.iconPosition,
    overlayClass: newStyles.wrapper,
    colorCloseBtn: 'txt_color_1',
    iconName: 'icons/general/close',
    customStyle: newStyles.overlay
  };

  if (
    (startDate && startDate > Date.now()) ||
    (endDate && endDate < Date.now())
  ) {
    return null;
  }

  return (
    <NotificationBanner
      snippetId={snippetId}
      expires={expires}
      customStyle={styles.overlay}
      colorCloseBtn="btn_close_light_color"
      {...(isNewPopup && configNewPopup)}
    />
  );
};

export default MaintenanceNotification;
