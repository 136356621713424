export const TOP_EVENTS_GROWS_MAP = [
  [3],
  [2, 2],
  [1, 1, 2],
  [1, 1, 1, 1],
  [1, 2, 1, 2, 2],
  [1, 1, 2, 1, 2, 1],
  [1, 1, 1, 1, 1, 2, 1],
  [1, 1, 1, 1, 1, 1, 1, 1]
];
