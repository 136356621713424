import { useContext } from 'react';
import clsx from 'clsx';
import { DeviceContext } from '@namespace/device';
import { Box } from '@alf/uikit';
import { SkeletSplitItem } from '../../../common/SkeletSplitItem';
import styles from './index.module.css';

const PromotionsSplitSkelet = ({ className }) => {
  const { isMobile, isTablet } = useContext(DeviceContext);
  if (isMobile) {
    return (
      <Box direction="column" padding="ms 0" gap="ms">
        <Box
          className={`${styles.skeletWrapper} ${className}`}
          data-role="promotions-skeleton-1"
        >
          <SkeletSplitItem className={styles.skeletItemMobile} />
        </Box>
      </Box>
    );
  }

  if (isTablet) {
    return (
      <Box direction="column" padding="ms 0" gap="ms">
        <Box
          className={`${styles.skeletWrapper} ${className}`}
          data-role="promotions-skeleton-1"
        >
          <SkeletSplitItem className={styles.skeletItemTablet} />
          <SkeletSplitItem className={styles.skeletItemTablet} />
        </Box>
      </Box>
    );
  }

  return (
    <Box direction="column" padding="ms 0" gap="ms">
      <Box
        className={`${styles.skeletWrapper} ${className}`}
        data-role="promotions-skeleton-1"
      >
        <SkeletSplitItem className={styles.skeletItem} />
        <SkeletSplitItem className={styles.skeletItem} />
        <SkeletSplitItem className={styles.skeletItem} />
        <SkeletSplitItem
          className={clsx(styles.skeletItem, styles.lastBlock)}
        />
      </Box>
    </Box>
  );
};

export default PromotionsSplitSkelet;
