import { useEffect } from 'react';
import { useNotifications, NOTIFICATION_TYPES } from '@namespace/notifications';
import { useOtherSettings } from '@namespace/cms';
import { DOCUMENT_STATUSES } from '@namespace/account';
import { useVerifyDocuments } from '@namespace/user';

export const useUnverifiedUser = () => {
  const { customNotification } = useNotifications();
  const {
    isShowVerificationNotification = false,
    isFreeToPlayWithoutVerifiedDocs = true
  } = useOtherSettings();
  const { verified, status, isDefaultRegistratedUser } = useVerifyDocuments();

  useEffect(() => {
    if (
      !isShowVerificationNotification &&
      !(
        verified ||
        isFreeToPlayWithoutVerifiedDocs ||
        [DOCUMENT_STATUSES.PENDING, DOCUMENT_STATUSES.VERIFIED].includes(status)
      ) &&
      !(isFreeToPlayWithoutVerifiedDocs && !isDefaultRegistratedUser)
    ) {
      customNotification({
        notificationType: NOTIFICATION_TYPES.UNVERIFIED_USER,
        timeout: null
      });
    }
  }, [isFreeToPlayWithoutVerifiedDocs, verified, customNotification, status]);
};
