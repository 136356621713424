import { DEFAULT_NOTIFICATION_TYPES } from '../../constants';

export const SET_NOTIFICATION = 'SET_NOTIFICATION';

export const setNotificationAction = (data) => {
  return {
    type: SET_NOTIFICATION,
    payload: { isShown: true, timeout: 5000, ...data }
  };
};

export const closeNotificationAction = () =>
  setNotificationAction({
    isShown: false
  });

export const setErrorNotificationAction = (message) =>
  setNotificationAction({
    message,
    notificationType: DEFAULT_NOTIFICATION_TYPES.ERROR
  });

export const setSuccessNotificationAction = (message) =>
  setNotificationAction({
    message,
    notificationType: DEFAULT_NOTIFICATION_TYPES.SUCCESS
  });

export const setInfoNotificationAction = (message) =>
  setNotificationAction({
    message,
    notificationType: DEFAULT_NOTIFICATION_TYPES.INFO
  });

export const setWarningNotificationAction = (message) =>
  setNotificationAction({
    message,
    notificationType: DEFAULT_NOTIFICATION_TYPES.WARNING
  });
