import { useContext } from 'react';
import { useI18n } from '@namespace/i18n';
import { UserContext } from '@namespace/user';
import { AText } from '@alf/uikit';
import TextNotification from './TextNotification';

const StatusAdditionalInformation = () => {
  const { t } = useI18n();
  const [user] = useContext(UserContext);
  const { email } = user;

  return (
    <TextNotification>
      <AText size="m" color="po_txt_color_1" breed="B">
        {t(`profileDocuments.information.additionalInformation`, [email])}
      </AText>
    </TextNotification>
  );
};

export default StatusAdditionalInformation;
