import { useCallback, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Box } from '@alf/uikit';
import { useConfig, useGeneralRoutes } from '@namespace/cms';
import { Icon } from '@namespace/icons2';
import { useSearchParams, useHistory } from '@namespace/router';
import { HTMLSnippet } from '@namespace/snippets';
import {
  getSessionStorageValue,
  removeSessionStorageItem,
  SESSION_STORAGE_KEYS
} from '@namespace/helpers';
import { LOG_ACTIONS, logAmplitude } from '@namespace/analytics';
import UnsuccessfulDepositCode from './components/UnsuccessfulDepositCode';
import styles from './index.module.css';

const UnsuccessfulDeposit = ({ id }) => {
  const { home } = useGeneralRoutes();
  const { push } = useHistory();
  const { config } = useConfig(id);
  const { snippetId, snippetIdHelp, isNeedSupport = false } = config;
  const { code = '' } = useSearchParams();

  useEffect(() => {
    const depositData = getSessionStorageValue(
      SESSION_STORAGE_KEYS.DEPOSIT_DATA
    );

    if (!isEmpty(depositData)) {
      const { userId, serviceId } = depositData;

      logAmplitude(LOG_ACTIONS.DEPOSIT_PAYMENT_FAILED_PAGE_OPEN, {
        service_id: serviceId,
        user_id: userId
      });
      removeSessionStorageItem(SESSION_STORAGE_KEYS.DEPOSIT_DATA);
    }
  }, []);

  const onClose = useCallback(() => {
    push(home);
  }, [home, push]);

  if (code) {
    return (
      <UnsuccessfulDepositCode
        code={code}
        isNeedSupport={isNeedSupport}
        snippetIdHelp={snippetIdHelp}
      />
    );
  }

  return (
    <Box direction="column">
      {snippetId && <HTMLSnippet snippetId={snippetId} />}
      <Box onClick={onClose} className={styles.close}>
        <Icon size="m" color="bg_color_4" name="icons/general/action/close" />
      </Box>
    </Box>
  );
};

export default UnsuccessfulDeposit;
