import types from '../types';
import {
  deleteMessages,
  checkMessagesAsUnread,
  checkMessagesAsRead,
  sendMessage,
  getMessageById
} from '../../../api/mailBox';
import { MESSAGE_TYPES_API, MESSAGE_TYPES } from '../../../constants';

export const actions = {
  [types.SET_IS_FETCHING]: (dispatch) => (value) => {
    dispatch({
      type: types.SET_IS_FETCHING,
      payload: { isFetching: value }
    });
  },
  [types.SET_INBOX_MESSAGES]: (dispatch) => (data) => {
    dispatch({
      type: types.SET_INBOX_MESSAGES,
      payload: { messages: data }
    });
  },
  [types.FETCH_MESSAGES]: (dispatch) => async (fetcher, userId, type) => {
    dispatch({
      type: types.SET_IS_FETCHING,
      payload: { isFetching: true }
    });
    const messages = await fetcher(userId);

    dispatch({
      type:
        type === MESSAGE_TYPES.INBOX
          ? types.SET_INBOX_MESSAGES
          : types.SET_OUTBOX_MESSAGES,
      payload: { messages }
    });
  },
  [types.DELETE_MESSAGES]: (dispatch) => async (
    userId,
    messages = [],
    type
  ) => {
    if (messages.length > 0) {
      await deleteMessages(userId, messages);
      dispatch({
        type:
          type === MESSAGE_TYPES.INBOX
            ? types.UNSELECT_ALL_INBOX_MESSAGES
            : types.UNSELECT_ALL_OUTBOX_MESSAGES
      });
      dispatch({
        type:
          type === MESSAGE_TYPES.INBOX
            ? types.SET_DELETED_INBOX_MESSAGES
            : types.SET_DELETED_OUTBOX_MESSAGES,
        payload: { messages }
      });
    }
  },
  [types.CHECK_UNREAD]: (dispatch) => async (userId, messages) => {
    await checkMessagesAsUnread(userId, messages);
    dispatch({
      type: types.UNSELECT_ALL_INBOX_MESSAGES
    });
    dispatch({
      type: types.SET_UNREAD_MESSAGES,
      payload: { messages }
    });
  },
  [types.CHECK_READ]: () => async (userId, messages) => {
    await checkMessagesAsRead(userId, messages);
  },
  [types.CREATE_MESSAGE]: () => async (userId, message, subject) => {
    await sendMessage({
      userId,
      message,
      subject
    });
  },
  [types.FETCH_MESSAGE]: (dispatch) => async (userId, messageId) => {
    const message = await getMessageById(userId, messageId);
    dispatch({
      type: types.SET_MESSAGE,
      payload: {
        message: {
          messageDate: message.dtSesent,
          fromUser: message.fromuser,
          subject: message.subject,
          messageText: message.message,
          read: Boolean(message.read),
          type: MESSAGE_TYPES_API[message.move]
        }
      }
    });
  }
};
