import { useContext } from 'react';
import clsx from 'clsx';
import { Box } from '@alf/uikit';
import { useIsMounted } from '@namespace/helpers';
import { PageContext } from '@namespace/cms';
import { ColumnContent } from './ColumnContent';
import { SeoSnippet } from '../../../../common/SeoSnippet';
import Footer from '../../../Footer';
import { SCROLLABLE_COLUMN } from '../../../../constants';
import styles from '../index.module.css';

export const CustomFooterContent = ({
  columns,
  columnClassName,
  scrollableColumns
}) => {
  const { page, seoConfig = {} } = useContext(PageContext);
  const { snippetId: seoSnippetId } = seoConfig;
  const { layout = {}, components = {} } = page;
  const { type = '', columnsWithIndependentScroll } = layout;
  const scrollColumns = scrollableColumns || columnsWithIndependentScroll;

  const isMounted = useIsMounted();

  return columns.map((column, index) => {
    const { pageConfig = {} } = components[column] || {};
    const {
      backgroundColor,
      seoSnippetId: pageConfigSnippetId = null
    } = pageConfig;
    const snippetId = seoSnippetId || pageConfigSnippetId;
    const ifPageExist =
      Boolean(Object.keys(components).length) &&
      Boolean(Object.keys(page).length);
    const isScrollable = scrollColumns?.[index];
    const columnCls = clsx(
      columnClassName,
      isScrollable && `${styles.scrollableColumn} ${SCROLLABLE_COLUMN}`
    );
    const key = `${type}-${index}`;

    return (
      <Box
        key={key}
        className={columnCls}
        direction="column"
        style={{ backgroundColor }}
      >
        <ColumnContent column={column} isScrollable={isScrollable} />
        {index === 1 && snippetId && ifPageExist && (
          <SeoSnippet snippetId={snippetId} />
        )}
        {isMounted && index === 1 && <Footer isCustomFooter={true} />}
      </Box>
    );
  });
};
