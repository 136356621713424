import { useContext, useMemo, useRef, useEffect } from 'react';
import { GridItem, AText, Box } from '@alf/uikit';
import { DeviceContext } from '@namespace/device';
import { useRouteParams } from '@namespace/router';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { useGeneralRoutes, useConfig } from '@namespace/cms';
import { useI18n, LocalizedNavLink } from '@namespace/i18n';
import { getSportIcon, Icon, Image } from '@namespace/icons2';
import { isEmpty } from 'lodash';
import { VirtualSportsContext } from '../../store/context';
import styles from './index.module.css';

const imagesMap = {
  'vfb-mobile': 'virtual-sports/betradar-vfb-mobile'
};

const LobbyMenu = ({ id }) => {
  const { config } = useConfig(id);
  const { isFrameSport = false, hideForProviders = [] } = config;
  const { isDesktop, isMobile } = useContext(DeviceContext);
  const { virtualSportsOneSport, virtualSportsFrame } = useGeneralRoutes();
  const [{ virtualSports = {}, feed }] = useContext(VirtualSportsContext);
  const { t } = useI18n();
  const path = isFrameSport ? virtualSportsFrame : virtualSportsOneSport;
  const { provider } = useRouteParams(`${path}/:provider/:categoryId/*`);
  const { providers, games, isLoaded: isFeedLoaded } = feed;

  const wrapperRef = useRef();

  useEffect(() => {
    if (wrapperRef.current) {
      if (hideForProviders.includes(provider)) {
        // some shit for betradar
        wrapperRef.current.parentNode.style.display = 'none';
      } else {
        wrapperRef.current.parentNode.style.display = null;
      }
    }
  }, [wrapperRef, hideForProviders, provider]);

  const sports = useMemo(() => {
    const feedProviderGames = providers.data?.[provider]?.games;

    const outerVirtualSports = Object.values(virtualSports[provider] || {});
    const feedSports = isEmpty(feedProviderGames)
      ? []
      : feedProviderGames.map((gameId) => games.data?.[gameId]);

    return [...outerVirtualSports, ...feedSports].filter(
      (sport) => !isEmpty(sport)
    );
  }, [virtualSports, providers, games, provider]);

  const menuItemsDesktop = useMemo(() => {
    return sports.map(({ categoryId, categoryName }) => ({
      title: categoryName,
      icon: getSportIcon(categoryId),
      link: `${path}/${provider}/${categoryId}`,
      onClick: () => {
        logAction(LOG_ACTIONS.VSPORT_INTERNAL_NAV, {
          ProviderName: t(`virtualSports.${provider}`),
          GameName: categoryName
        });
      }
    }));
  }, [sports]);

  useEffect(() => {
    if (isFeedLoaded && !sports.length) {
      wrapperRef.current.parentNode.style.display = 'none';
    }
  }, [isFeedLoaded]);

  if (!isDesktop) {
    return (
      <Box flex="1 0 auto" className={styles.slider}>
        {sports.map(({ categoryId, categoryName }) => {
          if (imagesMap[categoryId]) {
            return (
              <GridItem
                key={categoryId}
                component={LocalizedNavLink}
                to={`${path}/${provider}/${categoryId}`}
                text={categoryName}
                Image={
                  <Image
                    name="virtual-sports/betradar-vfb-mobile"
                    alt={categoryName}
                    className={styles.image}
                  />
                }
                size={isMobile ? 'xs' : 'm'}
                className={styles.sliderItem}
              />
            );
          }

          return (
            <GridItem
              key={categoryId}
              component={LocalizedNavLink}
              to={`${path}/${provider}/${categoryId}`}
              text={categoryName}
              iconName={getSportIcon(categoryId)}
              size={isMobile ? 'xs' : 'm'}
              className={styles.sliderItem}
            />
          );
        })}
      </Box>
    );
  }

  return (
    <Box ref={wrapperRef} direction="column" className={styles.lobbyWrapper}>
      <Box align="center" className={styles.title}>
        <AText breed="BU" size="xl" color="sm_txt_color_3">
          {t(`virtualSports.${provider}`)}
        </AText>
      </Box>
      <ul className={styles.menu}>
        {menuItemsDesktop.map(({ link, title, icon, onClick }) => (
          <li key={link}>
            <LocalizedNavLink
              className={styles.link}
              activeClassName={styles.active}
              onClick={onClick}
              to={link}
            >
              <Box align="center" flex="1">
                <Box className={styles.iconWrap}>
                  <Icon name={icon} size="s" className={styles.icon} />
                </Box>
                <AText breed="R" className={styles.itemTitle}>
                  {title}
                </AText>
              </Box>
            </LocalizedNavLink>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default LobbyMenu;
