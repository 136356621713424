import { IS_PRERENDER } from '@namespace/helpers';
import logNewRelicAction from '../logNewRelicAction';
import logGaAction from '../logGaAction';

export const logAction = (actionName, attributes = {}) => {
  if (!IS_PRERENDER) {
    logGaAction(actionName, attributes);
    logNewRelicAction(actionName, attributes);
  }
};
