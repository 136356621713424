import { useContext, useEffect, useMemo, useRef } from 'react';
import { LocalizedNavLink, useI18n } from '@namespace/i18n';
import { AText, Box, GridItem } from '@alf/uikit';
import { DeviceContext, FORM_FACTORS_MAP } from '@namespace/device';
import { useGeneralRoutes } from '@namespace/cms';
import { USER_ROLES, UserContext } from '@namespace/user';
import { getSportIcon } from '@namespace/icons2';
import { getPathToSports, scrollTo, document } from '@namespace/helpers';
import { HEADER_HEIGHT } from '@namespace/common';
import Skelet from './common/Skelet';
import { useAdvFilterStore } from '../../storeAdvFilter';
import styles from './index.module.css';

const getSportGridSize = (
  formFactor = FORM_FACTORS_MAP.MOBILE,
  pageType = FORM_FACTORS_MAP.MOBILE,
  isTablet
) => {
  if (formFactor === FORM_FACTORS_MAP.MOBILE) {
    return 'xs';
  }
  if (formFactor === FORM_FACTORS_MAP.TABLET) {
    return 'm';
  }
  if (pageType !== FORM_FACTORS_MAP.DESKTOP) {
    return pageType === FORM_FACTORS_MAP.MOBILE && !isTablet ? 'xs' : 'm';
  }
  return 'l';
};

export const SportsGrid = ({
  title,
  titleContent,
  list = [],
  baseRoute,
  isShowAllSports,
  onToggle,
  isShowCounters
}) => {
  const { t } = useI18n();
  const [user] = useContext(UserContext);
  const { role } = user;
  const { deviceFormFactor, isTablet, pageType } = useContext(DeviceContext);
  const gridSize = useMemo(
    () => getSportGridSize(deviceFormFactor, pageType, isTablet),
    [deviceFormFactor, isTablet, pageType]
  );
  const { preMatchFavorites, cyberSport, customFilter } = useGeneralRoutes();
  const advFilter = useAdvFilterStore('advFilter');
  const rootRef = useRef();

  useEffect(() => {
    if (!isShowAllSports) {
      const getTop = (el) => {
        if (!el || el === document.body) {
          return 0;
        }

        return el.offsetTop + getTop(el.offsetParent);
      };

      const top = getTop(rootRef.current) - HEADER_HEIGHT;
      scrollTo({ top });
    }
  }, [isShowAllSports]);

  return (
    <Box direction="column" padding="s" ref={rootRef}>
      <Box
        data-role="sport-lobby-items-header"
        align="center"
        justify="between"
        padding="s"
        className={styles.header}
      >
        <AText breed="BU" size="xl" color="sm_txt_color_4">
          {title}
        </AText>
        {titleContent}
      </Box>
      <div
        className={`${styles.container} ${styles[gridSize]}`}
        data-role="sport-lobby-items-wrapper"
      >
        {!list.length ? (
          <Skelet />
        ) : (
          <>
            {role === USER_ROLES.USER && (
              <GridItem
                key="sportsbook.favorites"
                component={LocalizedNavLink}
                to={preMatchFavorites}
                iconName="icons/general/state/favorite-on"
                text={t('sportsbook.favorites')}
                size={gridSize}
                className={styles.favoritesLink}
                dataRole="sport-lobby-item-show-favorites"
              />
            )}
            {advFilter?.length > 0 && (
              <GridItem
                key="sports-custom-filter-link"
                component={LocalizedNavLink}
                to={customFilter}
                iconName="icons/nav_menu/sports"
                text={t('sportsBook.sportsMenu.CustomFilter')}
                size={gridSize}
              />
            )}
            {list.map(({ sportId, slug, count, name, isPinned }) => (
              <GridItem
                key={sportId}
                component={LocalizedNavLink}
                to={getPathToSports(
                  `${baseRoute}/${slug}`,
                  cyberSport,
                  sportId
                )}
                count={isShowCounters ? count : undefined}
                iconName={getSportIcon(sportId)}
                text={name}
                size={gridSize}
                isPinned={isPinned}
                dataRole={`sport-lobby-item-${sportId}`}
              />
            ))}
            <GridItem
              onClick={onToggle}
              text={t(`sportsmenu.${isShowAllSports ? 'hide' : 'show'}All`)}
              iconName="icons/general/item/list"
              size={gridSize}
              dataRole="sport-lobby-item-show-all"
            />
          </>
        )}
      </div>
    </Box>
  );
};
