import { useContext, useEffect } from 'react';
import { PageContext, useMobileNavMenuSettings } from '@namespace/cms';
import { DeviceContext } from '@namespace/device';
import { NativeAppContext } from '@namespace/native-app/src/store/context';
import { useSearchParams } from '@namespace/router';
import {
  MOBILE_MENU_TYPES,
  POPUP_TYPES_WITHOUT_MENU
} from '@namespace/helpers';
import { BottomNavigationMenuContext } from '../../store/bottomNavigationMenu/context';

const useNavMenuVisible = () => {
  const [, { SET_NAV_MENU_VISIBLE }] = useContext(BottomNavigationMenuContext);
  const { popup } = useSearchParams();
  const isShowBottomNavigationMenu = !Object.values(
    POPUP_TYPES_WITHOUT_MENU
  )?.includes(popup);
  const mobileNavigation = useMobileNavMenuSettings();
  const { mobileMenu = MOBILE_MENU_TYPES.BURGER } = mobileNavigation;
  const { isDesktop } = useContext(DeviceContext);
  const pageSettings = useContext(PageContext);
  const { page = {} } = pageSettings || {};
  const [{ isNativeApp }] = useContext(NativeAppContext);
  const { pageConfig = {} } = page;
  const { isNavMenuVisible = true } = pageConfig;

  useEffect(() => {
    SET_NAV_MENU_VISIBLE(
      mobileMenu === MOBILE_MENU_TYPES.NAV_MENU &&
        !isNativeApp &&
        !isDesktop &&
        isNavMenuVisible &&
        isShowBottomNavigationMenu
    );
  }, [
    SET_NAV_MENU_VISIBLE,
    mobileMenu,
    isNativeApp,
    isDesktop,
    isNavMenuVisible,
    isShowBottomNavigationMenu
  ]);
};

export default useNavMenuVisible;
