export const TIME_FILTER_DATA = [
  { title: '00:00 - 24:00', value: '00:00:00 - 23:59:00' },
  { title: '00:00 - 02:59', value: '00:00:00 - 02:59:00' },
  { title: '03:00 - 05:59', value: '03:00:00 - 05:59:00' },
  { title: '06:00 - 08:59', value: '06:00:00 - 08:59:00' },
  { title: '09:00 - 11:59', value: '09:00:00 - 11:59:00' },
  { title: '12:00 - 14:59', value: '12:00:00 - 14:59:00' },
  { title: '15:00 - 17:59', value: '15:00:00 - 17:59:00' },
  { title: '18:00 - 20:59', value: '18:00:00 - 20:59:00' },
  { title: '21:00 - 23:59', value: '21:00:00 - 23:59:00' }
];

export const STATUS_TYPE_UNKNOWN = 'unknown';
