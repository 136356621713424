import { Box } from '@alf/uikit';
import DocumentNumber from '../DocumentNumber';
import SelectField from '../../common/SelectField';
import styles from './index.module.css';

const DocumentNumberWithType = ({
  documentName,
  typeName,
  dependentName,
  labelType,
  placeholderType,
  options,
  labelDoc,
  placeholderDoc,
  disabled,
  classNames,
  rules,
  documentsNumberValidationMap,
  clearAfterChange,
  noResetFormData,
  documentNumberProps = {}
}) => {
  return (
    <Box
      top="m"
      className={`${classNames.wrapperClassName} ${styles.documentContainer}`}
    >
      <SelectField
        name={typeName}
        label={labelType}
        placeholder={placeholderType}
        options={options}
        classNames={{ containerClassName: styles.fieldWrap }}
        disabled={disabled}
        clearAfterChange={clearAfterChange}
        noResetFormData={noResetFormData}
      />
      <DocumentNumber
        {...documentNumberProps}
        name={documentName}
        dependentName={dependentName}
        label={labelDoc}
        placeholder={placeholderDoc}
        documentsNumberValidationMap={documentsNumberValidationMap}
        disabled={disabled}
        rules={rules}
        classNames={{ wrapperClassName: styles.marginTop }}
      />
    </Box>
  );
};

export default DocumentNumberWithType;
