import { useCallback, useEffect, useRef } from 'react';
import { window } from '@namespace/helpers';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { useI18n } from '@namespace/i18n';
import kwizInjector from './utils/script-injector';
import kwizBot from '../../utils/kwizBot';

const useKwizBot = () => {
  const isInitCalled = useRef(false);

  const { t } = useI18n();

  const title = t('support.kwizbot.title');
  const subtitle = t('support.kwizbot.subtitle');

  const handleOpenChat = useCallback(async () => {
    if (!isInitCalled.current) {
      isInitCalled.current = true;

      await kwizInjector({
        title,
        subtitle
      });
    }

    kwizBot.openChat();

    logAction(LOG_ACTIONS.SUPPORT);
  }, [subtitle, title]);

  useEffect(() => {
    window.ShowSupport = () => {
      handleOpenChat();
    };
  }, [handleOpenChat]);
};

export default useKwizBot;
