import { useEffect } from 'react';
import { document } from '../../constants';

const useListener = (on, callback = () => {}) => {
  useEffect(() => {
    if (on) {
      document.addEventListener(on, callback);
    }

    return () => {
      document.removeEventListener(on, callback);
    };
  }, [on, callback]);
};

export default useListener;
