import { Icon } from '@namespace/icons2';
import PropTypes from 'prop-types';
import Box from '../Box';
import AText from '../AText';
import styles from './index.module.css';

const SuccessTextComponent = ({ text }) => {
  return (
    <Box className={styles.wrapper}>
      <Icon
        name="icons/general/state/success"
        size="xl"
        className={styles.icon}
        color="accent_color_5"
      />
      <AText color="txt_color_7" breed="B" size="l">
        {text}
      </AText>
    </Box>
  );
};

SuccessTextComponent.propTypes = {
  text: PropTypes.string
};

export default SuccessTextComponent;
