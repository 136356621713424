import { useGeneralRoutes } from '@namespace/cms';
import { PROMO_TYPES } from '../../constants';

export const useGetPromotionPageRouteByPromoType = ({ activePromoType }) => {
  const {
    promotion: promoPageRoute,
    tournament: tournamentPageRoute
  } = useGeneralRoutes();

  const promoTypeToPageRoute = {
    [PROMO_TYPES.promotion]: promoPageRoute,
    [PROMO_TYPES.tournament]: tournamentPageRoute
  };

  return promoTypeToPageRoute[activePromoType];
};
