import {
  ORDERED_RESULT_TYPES,
  RESULTS,
  PENALTY_GOAL_SCOPE_ID,
  PENALTY_ACTIONS_SCOPE_IDS,
  EVENT_RESULT_PENALTY,
  BULLET_ACTIONS_SCOPE_IDS,
  BULLETS_GOAL_SCOPE_ID,
  BULLETS_TYPE_ID,
  STANDART_PENALTY_LIMIT,
  STANDART_BULLET_LIMIT
} from './constants';
import {
  SCOPE_IDS,
  RESULT_TYPE_IDS,
  ADAPTED_SPORT_IDS,
  COLUMN_TYPES
} from '../../constants';

export const getEventValues = (arr = [], id) => {
  const scope = arr.find(({ scopeId }) => scopeId === id);
  const { scopeData = [] } = scope || {};

  return scopeData
    .sort((a, b) => a.number - b.number)
    .map(({ value }) => String(value));
};

export const getResultTypeScoreValues = (
  byResultType = [],
  resultTypeId,
  scopeId
) => {
  const resultType = byResultType.find(
    ({ resultTypeId: id }) => id === resultTypeId
  );
  const { resultTypeData = [] } = resultType || {};
  const { scopeData = [] } =
    resultTypeData.find(({ scopeId: id }) => id === scopeId) || {};

  return scopeData
    .sort((a, b) => a.number - b.number)
    .map(({ value }) => String(value));
};

// TODO: add translations keys when investigation for other languages will be done
const tags = {
  // Soccer
  [RESULT_TYPE_IDS.HALF_1]: 1,
  [RESULT_TYPE_IDS.HALF_2]: 2,
  [RESULT_TYPE_IDS.HALF_2_WITH_OT]: 'H2OT',
  [RESULT_TYPE_IDS.EXTRATIME_1]: 'ET1',
  [RESULT_TYPE_IDS.EXTRATIME_2]: 'ET2',
  // Basketball
  [RESULT_TYPE_IDS.QUARTER1]: 1,
  [RESULT_TYPE_IDS.QUARTER2]: 2,
  [RESULT_TYPE_IDS.QUARTER3]: 3,
  [RESULT_TYPE_IDS.QUARTER4]: 4,
  [RESULT_TYPE_IDS.QUARTER4_WITH_ET]: 4,
  // Volleyball
  [RESULT_TYPE_IDS.VOLLEYBALL_SET_1]: 1,
  [RESULT_TYPE_IDS.VOLLEYBALL_SET_2]: 2,
  [RESULT_TYPE_IDS.VOLLEYBALL_SET_3]: 3,
  [RESULT_TYPE_IDS.VOLLEYBALL_SET_4]: 4,
  [RESULT_TYPE_IDS.VOLLEYBALL_SET_5]: 5,
  [RESULT_TYPE_IDS.VOLLEYBALL_SET_6]: 6,
  [RESULT_TYPE_IDS.VOLLEYBALL_SET_7]: 7,
  // Table tennis
  [RESULT_TYPE_IDS.TABLE_TENNIS_SET_1]: 1,
  [RESULT_TYPE_IDS.TABLE_TENNIS_SET_2]: 2,
  [RESULT_TYPE_IDS.TABLE_TENNIS_SET_3]: 3,
  [RESULT_TYPE_IDS.TABLE_TENNIS_SET_4]: 4,
  [RESULT_TYPE_IDS.TABLE_TENNIS_SET_5]: 5,
  [RESULT_TYPE_IDS.TABLE_TENNIS_SET_6]: 6,
  [RESULT_TYPE_IDS.TABLE_TENNIS_SET_7]: 7,
  // Baseball
  [RESULT_TYPE_IDS.BASEBALL_INNING_1]: 1,
  [RESULT_TYPE_IDS.BASEBALL_INNING_2]: 2,
  [RESULT_TYPE_IDS.BASEBALL_INNING_3]: 3,
  [RESULT_TYPE_IDS.BASEBALL_INNING_4]: 4,
  [RESULT_TYPE_IDS.BASEBALL_INNING_5]: 5,
  [RESULT_TYPE_IDS.BASEBALL_INNING_6]: 6,
  [RESULT_TYPE_IDS.BASEBALL_INNING_7]: 7,
  [RESULT_TYPE_IDS.BASEBALL_INNING_8]: 8,
  [RESULT_TYPE_IDS.BASEBALL_INNING_9]: 9,
  // Ice hockey
  [RESULT_TYPE_IDS.ICE_HOCKEY_PERIOD_1]: 1,
  [RESULT_TYPE_IDS.ICE_HOCKEY_PERIOD_2]: 2,
  [RESULT_TYPE_IDS.ICE_HOCKEY_PERIOD_3]: 3,
  [RESULT_TYPE_IDS.ICE_HOCKEY_BULLITT]: 'B',
  // Participants
  [RESULT_TYPE_IDS.TENNIS_SET_1]: 1,
  [RESULT_TYPE_IDS.TENNIS_SET_2]: 2,
  [RESULT_TYPE_IDS.TENNIS_SET_3]: 3,
  [RESULT_TYPE_IDS.TENNIS_SET_4]: 4,
  [RESULT_TYPE_IDS.TENNIS_SET_5]: 5,
  // Cybersport
  [RESULT_TYPE_IDS.CYBERSPORT_GAME_1]: 1,
  [RESULT_TYPE_IDS.CYBERSPORT_GAME_2]: 2,
  [RESULT_TYPE_IDS.CYBERSPORT_GAME_3]: 3,
  [RESULT_TYPE_IDS.CYBERSPORT_GAME_4]: 4,
  [RESULT_TYPE_IDS.CYBERSPORT_GAME_5]: 5,
  [RESULT_TYPE_IDS.CYBERSPORT_GAME_6]: 6,
  [RESULT_TYPE_IDS.CYBERSPORT_GAME_7]: 7,
  [RESULT_TYPE_IDS.CYBERSPORT_GAME_8]: 8,
  [RESULT_TYPE_IDS.CYBERSPORT_GAME_9]: 9,
  [RESULT_TYPE_IDS.CYBERSPORT_GAME_10]: 10,
  [RESULT_TYPE_IDS.CYBERSPORT_SUM]: '',
  [RESULT_TYPE_IDS.CYBERSPORT_FULL_TIME]: '',
  // E-Fighting
  [RESULT_TYPE_IDS.E_FIGHTING_ROUND_1]: 1,
  [RESULT_TYPE_IDS.E_FIGHTING_ROUND_2]: 2,
  [RESULT_TYPE_IDS.E_FIGHTING_ROUND_3]: 3,
  [RESULT_TYPE_IDS.E_FIGHTING_ROUND_4]: 4,
  [RESULT_TYPE_IDS.E_FIGHTING_ROUND_5]: 5,
  [RESULT_TYPE_IDS.E_FIGHTING_ROUND_6]: 6,
  [RESULT_TYPE_IDS.E_FIGHTING_ROUND_7]: 7,
  [RESULT_TYPE_IDS.E_FIGHTING_ROUND_8]: 8,
  [RESULT_TYPE_IDS.E_FIGHTING_ROUND_9]: 9,
  // common
  [RESULT_TYPE_IDS.OVERTIME]: 'OT'
};

/**
 * @typedef {{ scores: (number|string)[], tag: string|number, resultTypeId: number }[]} Score
 */

// keeps only resultTypeIds associated with halfs, quarters and overtimes
// sorts by ids order in constant
// maps data to array containing objects with tag for column and scores of both teams
/**
 * @param {
 *   {
 *     resultTypeId: number,
 *     resultTypeData: {
 *       scopeId: number,
 *       scopeData: {
 *         number: number,
 *         value: number
 *       }[]
 *     }[]
 *   }[]
 * } byResultType
 * @param {number} scopeId
 * @returns {Score}
 */
export const getDetailedScoresForTable = (
  byResultType,
  scopeId = SCOPE_IDS.POINTS
) => {
  const ids = Object.values(RESULT_TYPE_IDS);

  return byResultType
    .filter(({ resultTypeId }) => ids.includes(resultTypeId))
    .map(({ resultTypeId, resultTypeData }) => ({
      resultTypeId,
      resultTypeData: resultTypeData.filter(({ scopeId: id }) => id === scopeId)
    }))
    .sort(
      ({ resultTypeId: a }, { resultTypeId: b }) =>
        ids.indexOf(a) - ids.indexOf(b)
    )
    .map(({ resultTypeData, resultTypeId }) =>
      resultTypeData.length > 0
        ? {
            type: COLUMN_TYPES.DEFAULT,
            scores: resultTypeData[0].scopeData
              .sort(({ number: a }, { number: b }) => a - b)
              .map(({ value }) => value),
            tag: tags[resultTypeId],
            resultTypeId
          }
        : undefined
    )
    .filter(Boolean);
};

/**
 * Removes Q4 if Q4OT is presented and Q4 have 0 values
 * @param {Score} score
 * @returns {Score}
 */
const stripeBasketballQuarters = (score) => {
  const updatedScore = score;
  const q4Idx = updatedScore.findIndex(
    ({ resultTypeId }) => resultTypeId === RESULT_TYPE_IDS.QUARTER4
  );
  const q4OTIdx = updatedScore.findIndex(
    ({ resultTypeId }) => resultTypeId === RESULT_TYPE_IDS.QUARTER4_WITH_ET
  );
  if (q4Idx !== -1 && q4OTIdx !== -1) {
    const { scores: q4Scores } = updatedScore[q4Idx];
    const isScores = Boolean(q4Scores.reduce((acc, curr) => acc + curr, 0));
    if (!isScores) {
      delete updatedScore[q4Idx];
      return updatedScore.filter(Boolean);
    }
  }
  return updatedScore;
};

/**
 * @param {Score} score
 * @param {number} sportId
 * @param {number} eventResultId
 * @param {boolean} isFullScore
 * @returns {Score}
 */
export const stripeScore = (score, sportId, eventResultId, isFullScore) => {
  const currentOrderedResultTypeIdx = ADAPTED_SPORT_IDS.includes(sportId)
    ? ORDERED_RESULT_TYPES[sportId].findIndex((id) => id === eventResultId)
    : -1;

  if (currentOrderedResultTypeIdx !== -1) {
    const lastCorrectColumnIdx = score.findIndex(
      ({ resultTypeId }) =>
        resultTypeId ===
        ORDERED_RESULT_TYPES[sportId][currentOrderedResultTypeIdx]
    );

    if (isFullScore) {
      const fullScore = score.map(({ scores, ...rest }, index) => {
        if (index > lastCorrectColumnIdx) {
          return {
            ...rest,
            scores: scores.map(() => '')
          };
        }

        return {
          ...rest,
          scores
        };
      });

      return stripeBasketballQuarters(fullScore);
    }

    if (lastCorrectColumnIdx !== -1) {
      return stripeBasketballQuarters(
        score.splice(0, lastCorrectColumnIdx + 1)
      );
    }
  }

  return stripeBasketballQuarters(score);
};

const calculateTypeScore = (
  byResultType = {},
  eventResultId = 0,
  type = '',
  typeId = 0,
  goalScopeId = 0,
  actionsScopeIds = [],
  columnsLimit = 0
) => {
  if (eventResultId === typeId) {
    let typeScore = [];
    let totalScore = [];

    let { resultTypeData } = byResultType.find(
      ({ resultTypeId }) => resultTypeId === typeId
    );
    resultTypeData = resultTypeData.filter(({ scopeId }) =>
      actionsScopeIds.includes(scopeId)
    );
    const resultTypeDataFirst = [...resultTypeData]
      .reverse()
      .filter(({ number }) => number === 1);
    const resultTypeDataSecond = [...resultTypeData]
      .reverse()
      .filter(({ number }) => number === 2);

    const maxParticipationLength =
      resultTypeDataFirst.length > resultTypeDataSecond.length
        ? resultTypeDataFirst.length
        : resultTypeDataSecond.length;
    typeScore = Array(
      maxParticipationLength <= columnsLimit
        ? columnsLimit
        : maxParticipationLength
    ).fill({
      scores: [
        { scopeId: 0, value: 0 },
        { scopeId: 0, value: 0 }
      ]
    });
    typeScore = typeScore.map((item, index) => ({
      type,
      scores: item.scores.map((elem, idx) => {
        if (resultTypeDataFirst.length && idx === 0) {
          return resultTypeDataFirst[index]
            ? {
                scopeId: resultTypeDataFirst[index].scopeId,
                value: resultTypeDataFirst[index].value
              }
            : elem;
        }
        if (resultTypeDataSecond.length && idx === 1) {
          return resultTypeDataSecond[index]
            ? {
                scopeId: resultTypeDataSecond[index].scopeId,
                value: resultTypeDataSecond[index].value
              }
            : elem;
        }
        return elem;
      }),
      tag: index + 1
    }));

    if (typeScore.length > STANDART_PENALTY_LIMIT) {
      typeScore = typeScore.slice(typeScore.length - STANDART_PENALTY_LIMIT);
    }

    totalScore = resultTypeData.reduce(
      ({ firstTeamResult, secondTeamResult }, { scopeId, number }) => ({
        firstTeamResult:
          number === 1 && scopeId === goalScopeId
            ? firstTeamResult + 1
            : firstTeamResult,
        secondTeamResult:
          number === 2 && scopeId === goalScopeId
            ? secondTeamResult + 1
            : secondTeamResult
      }),
      { firstTeamResult: 0, secondTeamResult: 0 }
    );

    return [typeScore, totalScore];
  }
  return [null, null];
};

export const calculateBulletScore = (byResultType = {}, eventResultId = 0) =>
  calculateTypeScore(
    byResultType,
    eventResultId,
    COLUMN_TYPES.BULLETS,
    BULLETS_TYPE_ID,
    BULLETS_GOAL_SCOPE_ID,
    BULLET_ACTIONS_SCOPE_IDS,
    STANDART_BULLET_LIMIT
  );

export const calculatePenaltyScore = (byResultType = {}, eventResultId = 0) =>
  calculateTypeScore(
    byResultType,
    eventResultId,
    COLUMN_TYPES.PENALTY,
    EVENT_RESULT_PENALTY,
    PENALTY_GOAL_SCOPE_ID,
    PENALTY_ACTIONS_SCOPE_IDS,
    STANDART_PENALTY_LIMIT
  );

export const getScore = (scoreboard = {}) => {
  if (!Object.keys(scoreboard).length) {
    return false;
  }
  const { total = [], current = [] } = scoreboard || {};
  const scopeIdsList = [
    ...total.map(({ scopeId }) => scopeId),
    ...current.map(({ scopeId }) => scopeId)
  ];

  const currentPoints = scopeIdsList.includes(RESULTS.POINTS)
    ? getEventValues(current, RESULTS.POINTS)
    : null;
  const pointsTotal = scopeIdsList.includes(RESULTS.POINTS)
    ? getEventValues(total, RESULTS.POINTS)
    : null;
  const penTotal = scopeIdsList.includes(RESULTS.TRPEN)
    ? getEventValues(total, RESULTS.TRPEN)
    : null;
  const redCardTotal = scopeIdsList.includes(RESULTS.REDCARD)
    ? getEventValues(total, RESULTS.REDCARD)
    : null;
  const yellowCardTotal = scopeIdsList.includes(RESULTS.YELLOWCARD)
    ? getEventValues(total, RESULTS.YELLOWCARD)
    : null;
  const cornerTotal = scopeIdsList.includes(RESULTS.CORNER)
    ? getEventValues(total, RESULTS.CORNER)
    : null;
  const foulsTotal = scopeIdsList.includes(RESULTS.FOULS)
    ? getEventValues(total, RESULTS.FOULS)
    : null;
  const reboundsTotal = scopeIdsList.includes(RESULTS.REBOUNDS)
    ? getEventValues(total, RESULTS.REBOUNDS)
    : null;

  return {
    currentPoints,
    pointsTotal,
    penTotal,
    redCardTotal,
    yellowCardTotal,
    cornerTotal,
    foulsTotal,
    reboundsTotal
  };
};
