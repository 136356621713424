import { AText } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { Image } from '@namespace/icons2';
import { useGetThemeConfig } from '@namespace/themes';
import styles from './index.module.css';

const NoData = ({ className = '' }) => {
  const { t } = useI18n();

  const { config = {} } = useGetThemeConfig('NoData');

  const {
    imgName = 'general/looking_for',
    imageStyle = 'default',
    style = {}
  } = config;

  return (
    <div className={`${styles.wrap} ${className}`}>
      <Image
        name={imgName}
        className={styles[imageStyle]}
        alt="there is no data"
      />
      <AText
        breed="RU"
        size="m"
        color="po_txt_color_3"
        align="center"
        className={styles.text}
        style={style}
      >
        {t('personalOffice.noData.text')}
      </AText>
    </div>
  );
};

export default NoData;
