import {
  PageProvider,
  PageComponentsProvider,
  COMPONENTS_TYPES
} from '@namespace/cms';
import { IS_SERVER_RENDER } from '@namespace/helpers';
import CookieNotification from '../CookieNotification';
import { Layout } from './Layout';
import { pageComponentsMap } from './componentsMap';
import Wrapper from './Wrapper';
import CustomNotification from '../CustomNotification';
import MaintenanceNotification from '../MaintenanceNotification';
import { PageRouterWrapper } from '../PageRouterWrapper';
import PrivacyPolicyConfirm from '../PrivacyPolicyConfirm';

export const Page = ({
  componentsMap = {},
  FALLBACK_PAGES,
  pageWrapperClassName = '',
  pageContainerClassName = '',
  headerWrapperClassName = '',
  initialPageConfig
}) => {
  const pageComponentsContextMap = { ...pageComponentsMap, ...componentsMap };

  const {
    [COMPONENTS_TYPES.COOKIE_MANAGEMENT]: CookieManagementComponent
  } = pageComponentsContextMap;

  return (
    <>
      <PageComponentsProvider componentsMap={pageComponentsContextMap}>
        <PageProvider
          FALLBACK_PAGES={FALLBACK_PAGES}
          initialPageConfig={initialPageConfig}
        >
          <PageRouterWrapper FALLBACK_PAGES={FALLBACK_PAGES}>
            <Wrapper
              headerWrapperClassName={headerWrapperClassName}
              pageWrapperClassName={pageWrapperClassName}
              pageContainerClassName={pageContainerClassName}
            >
              <Layout />
            </Wrapper>
          </PageRouterWrapper>
        </PageProvider>
      </PageComponentsProvider>
      <CustomNotification />
      <CookieNotification />
      {CookieManagementComponent && <CookieManagementComponent />}
      {!IS_SERVER_RENDER && <MaintenanceNotification />}
      <PrivacyPolicyConfirm />
    </>
  );
};
