import asyncValidator from '../asyncValidator';
import getAccountingError from '../getAccountingError';

const createAsyncValidator = (promise) =>
  asyncValidator(
    promise,
    ({ error }) => !error,
    ({ errorCode }) => getAccountingError(errorCode)
  );

export default createAsyncValidator;
