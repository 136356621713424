import { identity } from 'lodash';

const memoization = (
  fn,
  { cache = {}, generateCacheKey = identity } = {}
) => async (value) => {
  const key = generateCacheKey(value);

  if (key in cache) return cache[key];

  const result = await fn(value);

  if (key) {
    cache[key] = result; // eslint-disable-line no-param-reassign
  }

  return result;
};

export default memoization;
