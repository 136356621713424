import { useCallback, useContext, useEffect } from 'react';
import { UserContext } from '@namespace/user';
import { normalizeSystemBonusDetails } from '../../utils/normalizeSystemBonusDetails';
import { getBonusDetailsBs } from '../../api/getBonusDetailsBs';
import { BonusesContext } from '../../store/context';
import { getBonusTmplCms } from '../../api/getBonusTmplCms';

export const useFetchBonusDetailsBs = (bonusId) => {
  const [, actions] = useContext(BonusesContext);
  const { SET_IS_LOADING, SET_BONUS } = actions;
  const [user] = useContext(UserContext);
  const { activeWallet } = user;
  const { currency } = activeWallet;

  const fetchBonusDetails = useCallback(async () => {
    let bonus = null;
    let bonusTmpl = null;
    try {
      SET_IS_LOADING(true);
      bonus = await getBonusDetailsBs(bonusId);
      const { contentTemplate } = bonus;
      [bonusTmpl] = await getBonusTmplCms(contentTemplate);
    } catch (e) {
      console.log(e);
    } finally {
      SET_BONUS({
        bonus: normalizeSystemBonusDetails(bonus, currency),
        bonusTmpl
      });
      SET_IS_LOADING(false);
    }
  }, [SET_BONUS, SET_IS_LOADING, bonusId, currency]);

  useEffect(() => {
    fetchBonusDetails();
  }, [fetchBonusDetails]);

  return fetchBonusDetails;
};
