const getCategory = ({
  isAllSports,
  isFavorites,
  category,
  prevCategory,
  onlyCategoryId
}) => {
  if (isAllSports || isFavorites || (!category && !prevCategory)) return [];

  if (onlyCategoryId) {
    return [category?.categoryId || prevCategory?.categoryId].filter(Boolean);
  }

  return [category || prevCategory].filter(Boolean);
};

export default getCategory;
