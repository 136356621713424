import { isEmpty } from 'lodash';
import { getPageFromBatch, preparePageConfig } from '../../api/getPageConfig';
import getPageConfigWrapperTypes from '../getPageConfigWrapperTypes';

const getActualPageConfig = ({
  initialPageConfig = {},
  pageType,
  pathname,
  isSSR = false
}) => {
  const page = getPageFromBatch(pathname, initialPageConfig, isSSR);
  const pageConfig = !isEmpty(page) ? preparePageConfig(page, pageType) : null;
  if (!pageConfig) {
    return pageConfig;
  }
  return {
    ...pageConfig,
    pageConfig: {
      ...(page?.general?.pageConfig || {}),
      ...pageConfig.pageConfig
    },
    wrapperTypes: getPageConfigWrapperTypes(pageConfig)
  };
};

export default getActualPageConfig;
