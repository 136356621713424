import { omit } from 'lodash';

const resetPageSeo = (matchedPath) => {
  if (!matchedPath) {
    return null;
  }
  const { general = {} } = matchedPath;
  const { pageConfig = {} } = general;
  const removedKeys = ['pageTitle', 'pageTitleTranslation', 'metaDescription'];
  return {
    ...matchedPath,
    general: {
      pageConfig: omit(pageConfig, removedKeys)
    }
  };
};

export default resetPageSeo;
