import { get, isEmpty } from 'lodash';
import committer from 'committer';
import { ODDS_WARNINGS_IDS } from '../../constants';

const excludedBacketErrors = [1024];

const excludedBetsErrors = [1022, 1023, 1064];

const vipErrors = [1013, 1019];

const bonusOutcomesTypes = [
  774,
  570,
  571,
  572,
  573,
  574,
  575,
  576,
  577,
  578,
  579
];

const suspendedErrors = [1064, 1017];

const preorderHideActions = ['push', 'pop', 'sync', 'check'];

const normalizeBasketErrors = (errors = []) =>
  errors.filter((e) => !excludedBacketErrors.includes(e.c));

const normalizeBetsErrors = (errors = []) =>
  errors.filter((e) => !excludedBetsErrors.includes(e.c));

export default (prevState, fields = {}) => {
  const defaultState = committer(prevState.defaultState, fields);
  const initialBets = get(defaultState, 'betslip.bets', []);
  const bets = initialBets.map((b) => {
    const errorCodes = (b.error || []).map((e) => e.c);
    return {
      ...b,
      event_id: b.event_id,
      event_line_position: b.event_line_position,
      event_name: b.event_name,
      isBonus: bonusOutcomesTypes.includes(b.outcome_type_id),
      fixed: b.fixed,
      isCoefDown: errorCodes.includes(ODDS_WARNINGS_IDS.DOWN),
      isCoefUp: errorCodes.includes(ODDS_WARNINGS_IDS.UP),
      isSuspended: !!suspendedErrors.filter((code) => errorCodes.includes(code))
        .length,
      choose: b.choose,
      outcome_id: b.id,
      outcomes: [
        {
          ...b,
          outcome_id: b.id
        }
      ]
    };
  });

  const basket = defaultState.betslip;
  const validBets = bets.filter((b) => !b.isBonus && !b.fixed && b.choose);
  const hasVipError =
    basket?.error?.length &&
    basket?.error.some((error) => vipErrors.includes(error.c));
  const state = {
    bets,
    choosenBets: bets.filter((b) => b.choose),
    basket,
    isCoefChanged: !!bets.filter((b) => b.isCoefDown || b.isCoefUp).length,
    validBets,
    isComplexSystem: basket.type === 4,
    isProcessing: basket.is_processing,
    isDone: basket.matched_bets,
    isPreOrderDone: Boolean(basket.preorder && basket.ticket_id),
    isOneClickBet: basket.one_click,
    vipTime: basket.action_time, // basket.time_stamp + 1000
    isVip:
      basket.vip &&
      basket.bet > basket.max_bet &&
      // basket.type === 1 &&
      !hasVipError,
    isChanceBet: basket.second_chance_candidate
  };

  const errors = initialBets.reduce((acc, b) => {
    const betErrors = normalizeBetsErrors(b.error);
    if (betErrors.length) {
      acc[b.id] = betErrors;
    }
    return acc;
  }, {});

  let basketErrors = {};

  if (get(defaultState, 'betslip.error.length', 0)) {
    const basketNormalizedErrors = normalizeBasketErrors(
      defaultState.betslip.error
    );
    basketErrors = {
      errors: basketNormalizedErrors,
      basketErrorCodes: basketNormalizedErrors.map((e) => e.c)
    };
  }

  const hasErrors = !isEmpty(errors) || !isEmpty(basketErrors.errors);

  const preorderWithoutId =
    defaultState.betslip.action === 'print' && !state.basket.ticket_id;

  return {
    ...state,
    defaultState,
    errors,
    basketErrors,
    hasErrors,
    showPreorder:
      preorderHideActions.includes(defaultState.betslip.action) ||
      hasErrors ||
      preorderWithoutId
        ? false
        : prevState.showPreorder
  };
};
