import { useContext, useEffect, useState } from 'react';
import { UserContext } from '@namespace/user';
import { getPayments } from '../../api/config';

export const useGetPayments = () => {
  const [{ id }] = useContext(UserContext);
  const [payments, setPayments] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await getPayments(id);

        setPayments(response || {});
      } catch (error) {
        console.error(error);
      }
    })();
  }, [id]);

  return payments;
};
