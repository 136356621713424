import {
  PreMatchRoutesWrapper,
  PreMatchSportsBookWrapper,
  LiveRoutesWrapper,
  LiveSportsBookWrapper,
  TopEventsRoutesWrapper,
  TopEventsWrapper,
  TopEventsWrapper2,
  CyberSportSportsBookWrapper,
  SportsBookCommonWrapper,
  PopularSportEventsWrapper,
  PopularCyberSportEventsWrapper
} from '@namespace/sports';
import { PromotionsWrapper } from '@namespace/promotions';
import {
  VirtualSportsWrapper,
  VirtualSportWrapper
} from '@namespace/virtual-sports';
import {
  BetSlipLoadWrapper,
  BetSlipLoadWrapperVirtual
} from '@namespace/betslip';
import { PredictionsLoadWrapper } from '@namespace/predictions';
import { ProfileDataLoadWrapper } from '@namespace/account';
import { BonusesWrapper, PreWagerBonusesWrapper } from '@namespace/bonuses';
import { WRAPPER_TYPES } from '@namespace/cms';

export const pageWrappersMap = {
  [WRAPPER_TYPES.PRE_MATCH_ROUTES_WRAPPER]: PreMatchRoutesWrapper,
  [WRAPPER_TYPES.PRE_MATCH_SPORTS_BOOK_WRAPPER]: PreMatchSportsBookWrapper,
  [WRAPPER_TYPES.LIVE_ROUTES_WRAPPER]: LiveRoutesWrapper,
  [WRAPPER_TYPES.LIVE_SPORTS_BOOK_WRAPPER]: LiveSportsBookWrapper,
  [WRAPPER_TYPES.TOP_EVENTS_ROUTES_WRAPPER]: TopEventsRoutesWrapper,
  [WRAPPER_TYPES.TOP_EVENTS_WRAPPER]: TopEventsWrapper,
  [WRAPPER_TYPES.TOP_EVENTS_WRAPPER_2]: TopEventsWrapper2,
  [WRAPPER_TYPES.CYBER_SPORTS_WRAPPER]: CyberSportSportsBookWrapper,

  [WRAPPER_TYPES.VIRTUAL_SPORTS_WRAPPER]: VirtualSportsWrapper,
  [WRAPPER_TYPES.VIRTUAL_SPORT_WRAPPER]: VirtualSportWrapper,

  [WRAPPER_TYPES.BETSLIP_LOAD]: BetSlipLoadWrapper,
  [WRAPPER_TYPES.BETSLIP_LOAD_VIRTUAL]: BetSlipLoadWrapperVirtual,

  [WRAPPER_TYPES.SPORTS_BOOK_COMMON_WRAPPER]: SportsBookCommonWrapper,

  [WRAPPER_TYPES.PREDICTIONS_LOAD_WRAPPER]: PredictionsLoadWrapper,

  [WRAPPER_TYPES.PROMOTIONS_WRAPPER]: PromotionsWrapper,

  [WRAPPER_TYPES.PROFILE_DATA_LOAD_WRAPPER]: ProfileDataLoadWrapper,

  [WRAPPER_TYPES.BONUSES_WRAPPER]: BonusesWrapper,

  [WRAPPER_TYPES.PRE_WAGER_BONUSES_WRAPPER]: PreWagerBonusesWrapper,

  [WRAPPER_TYPES.POPULAR_SPORT_EVENTS_WRAPPER]: PopularSportEventsWrapper,

  [WRAPPER_TYPES.POPULAR_CYBER_SPORT_EVENTS_WRAPPER]: PopularCyberSportEventsWrapper
};
