import { createContext, useMemo, useReducer } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import { initialState, reducers } from '../reducer';
import { types } from '../types';
import { actions } from '../actions';

export const BottomNavigationMenuContext = createContext();

export const BottomNavigationMenuProvider = ({ customReducers, children }) => {
  const memoReducers = useMemo(
    () => combineReducers(reducers, customReducers),
    [customReducers]
  );
  const [state, dispatch] = useReducer(memoReducers, initialState);

  const combinedActions = useActions(types, dispatch, actions);

  return (
    <BottomNavigationMenuContext.Provider value={[state, combinedActions]}>
      {children}
    </BottomNavigationMenuContext.Provider>
  );
};
