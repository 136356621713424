import { useCallback } from 'react';
import { useOtherSettings } from '@namespace/cms';
import { useHistory } from '@namespace/router';
import { useGetGameGoBackPath } from '../useGetGameGoBackPath';

export const useGetGameGoBack = (casinoLobbyUrl, providerServiceId) => {
  const { push, goBack } = useHistory();
  const { casinoProvidersWithBackToLobbyOnly = [] } = useOtherSettings();

  const { isPrevCasinoPathname, gameGoBackPath } = useGetGameGoBackPath(
    casinoLobbyUrl
  );

  return useCallback(() => {
    if (casinoProvidersWithBackToLobbyOnly.includes(providerServiceId)) {
      push(casinoLobbyUrl);
      return;
    }
    if (isPrevCasinoPathname) {
      goBack();

      return;
    }

    push(gameGoBackPath);
  }, [
    isPrevCasinoPathname,
    push,
    gameGoBackPath,
    goBack,
    casinoProvidersWithBackToLobbyOnly,
    providerServiceId
  ]);
};
