import { memo, useCallback, useContext, useMemo } from 'react';
import { DeviceContext } from '@namespace/device';
import { Box } from '@alf/uikit';
import { useGeneralRoutes } from '@namespace/cms';
import { USER_ROLES, UserContext } from '@namespace/user';
import { useLocation } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { LINE_VIEW, MODE } from '../../../../../constants';
import EventSubRowButton from './components/EventSubRowButton';
import EventHead from '../../../../EventHead';
import EventIcons from '../../../../EventIcons';
import EventFavoriteStar from '../../../../EventFavoriteStar';
import EventComments from '../../../../EventComments';
import { useEventLink } from '../../../../../hooks/useEventLink';
import EventParticipants from '../../../../EventParticipants';
import LiveScore from '../../../../LiveScore';
import EventSubRowTable from './components/EventSubRowTable';
import MarketCount from './components/MarketCount';
import { useSportSlugByEventId } from '../../../../../store/selectors/sportSlugByEventId';
import styles from './index.module.css';

const EventBody = ({
  headGroups = [],
  eventId,
  isShowEventGroup,
  lineKey,
  isShowStatUrl,
  isShowVideo,
  isDisplayHeadMarkets,
  lineView,
  isLiveEvents
}) => {
  const { isDesktop } = useContext(DeviceContext);
  const [{ role }] = useContext(UserContext);

  const sportSlug = useSportSlugByEventId(eventId, MODE.IN_PLAY);
  const linkToEventDetail = useEventLink({ eventId });
  const { push } = useHistory();
  const { liveSportAll } = useGeneralRoutes();
  const { pathname } = useLocation();

  const classNames = useMemo(
    () => ({
      marketsCountClass: `${styles.marketsCount} ${
        lineView ? styles[lineView] : ''
      }`
    }),
    [lineView]
  );

  const onEventOpen = useCallback(
    (e) => {
      e.stopPropagation();
      if (pathname === `${liveSportAll}/`) {
        push(`${liveSportAll}/#${sportSlug}`);
      }
      push(linkToEventDetail);
    },
    [pathname, liveSportAll, push, linkToEventDetail, sportSlug]
  );

  return (
    <div onClick={onEventOpen} data-role={`event-id-${eventId}`}>
      {lineView === LINE_VIEW.TABLE ? (
        <Box direction="column">
          <Box
            flex="1 1 100%"
            className={`${styles.container} ${
              lineView ? styles[lineView] : ''
            }`}
            data-role={`event-body-${eventId}`}
          >
            <Box direction="column" flex="1 1 100%">
              <Box className={styles.participantsContainer}>
                {isDesktop && (
                  <EventFavoriteStar
                    eventId={eventId}
                    className={styles.favorite}
                  />
                )}
                <EventParticipants
                  eventId={eventId}
                  isActiveLink={true}
                  isDisplayAdditionalData={true}
                />
                <LiveScore
                  eventId={eventId}
                  lineView={lineView}
                  isShortVersion={true}
                />
              </Box>
              <Box
                className={styles.iconsContainer}
                left={isDesktop && role === USER_ROLES.USER ? 'l' : 's'}
              >
                <EventHead
                  lineView={lineView}
                  isShowEventGroup={false}
                  eventId={eventId}
                />
                <EventIcons
                  eventId={eventId}
                  isShowFavorite={!isDesktop}
                  classNames={classNames}
                  isShowVideo={isShowVideo}
                  isShowStatUrl={isShowStatUrl}
                  isHideUnselectedFavorite={true}
                  isLiveEvents={isLiveEvents}
                />
                {isDesktop && (
                  <EventComments
                    eventId={eventId}
                    classNames={{
                      commentsContainerClass: styles.commentsContainer
                    }}
                  />
                )}
              </Box>
            </Box>
            <Box className={styles.eventSubRowContainer}>
              <EventSubRowTable
                eventId={eventId}
                lineKey={lineKey}
                headGroups={headGroups}
                isDisplayHeadMarkets={isDisplayHeadMarkets}
              />
            </Box>
            <MarketCount eventId={eventId} lineView={lineView} />
          </Box>
          {!isDesktop && (
            <EventComments
              eventId={eventId}
              classNames={{
                commentsContainerClass: styles.commentsContainerBottom
              }}
            />
          )}
        </Box>
      ) : (
        <Box direction="column" flex="1 1 100%" className={styles.container}>
          <Box flex="1 0 auto" direction="row" className={styles.headContainer}>
            <EventHead
              lineView={lineView}
              isShowEventGroup={isShowEventGroup}
              eventId={eventId}
            />

            <EventIcons
              eventId={eventId}
              isShowFavorite={!isDesktop}
              classNames={classNames}
              isShowVideo={isShowVideo}
              isShowStatUrl={isShowStatUrl}
              isIconsClickable={isDesktop}
              isHideUnselectedFavorite={true}
              isLiveEvents={isLiveEvents}
            />
            <EventComments
              eventId={eventId}
              classNames={{
                commentsContainerClass: styles.commentsContainer
              }}
              options={{ overflow: 'ellipsis' }}
            />
          </Box>
          <Box align="center" flex="1 1 100%">
            {isDesktop && (
              <EventFavoriteStar
                eventId={eventId}
                className={styles.favorite}
              />
            )}
            <Box flex="1 1 100%" className={styles.eventSubRowContainer}>
              <EventSubRowButton
                eventId={eventId}
                lineKey={lineKey}
                isActiveLink={true}
                headGroups={headGroups}
                isDisplayHeadMarkets={isDisplayHeadMarkets}
              />
            </Box>
            <MarketCount
              lineView={lineView}
              eventId={eventId}
              wrapperProps={{ flex: '0 0 55px' }}
            />
          </Box>
        </Box>
      )}
    </div>
  );
};

export default memo(EventBody);
