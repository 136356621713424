import { setLocalStorageValue, LOCAL_STORAGE_KEYS } from '@namespace/helpers';
import { types } from '../types';

export const actions = {
  [types.SET_COEF_TYPE]: (dispatch) => (coefType) => {
    setLocalStorageValue(LOCAL_STORAGE_KEYS.COEF_TYPE, coefType);
    dispatch({
      type: types.SET_COEF_TYPE,
      payload: coefType
    });
  }
};
