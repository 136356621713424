import { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Icon } from '@namespace/icons2';
import Box from '../Box';
import AText from '../AText';
import styles from './index.module.css';

const StepLine = ({ steps = [], activeStep = 1 }) => {
  return (
    <Box>
      {steps.map(({ type = '', config = {} }, index) => {
        const isFinishedStep = index + 1 < activeStep;
        const isActiveStep = index + 1 === activeStep;
        const { title = '' } = config;

        return (
          <Fragment key={type}>
            <Box
              direction="column"
              align="center"
              className={styles.stepContainer}
            >
              <Box
                align="center"
                justify="center"
                className={clsx([
                  styles.step,
                  isFinishedStep && styles.finishedStep,
                  isActiveStep && styles.activeStep
                ])}
              >
                {isFinishedStep ? (
                  <Icon
                    size="s"
                    name="icons/general/nav/arrow-checked"
                    color="bg_color_7"
                  />
                ) : (
                  <AText
                    breed="R"
                    size="xl"
                    color={isActiveStep ? 'txt_color_1' : 'txt_color_7'}
                  >
                    {index + 1}
                  </AText>
                )}
              </Box>
              {title && (
                <AText
                  breed="R"
                  size="m"
                  color="txt_color_3"
                  align="center"
                  className={styles.title}
                >
                  {title}
                </AText>
              )}
            </Box>
            {index !== steps.length - 1 && <div className={styles.connector} />}
          </Fragment>
        );
      })}
    </Box>
  );
};

StepLine.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      config: PropTypes.shape({ title: PropTypes.string })
    })
  ),
  activeStep: PropTypes.number
};

export default StepLine;
