import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './index.module.css';

const Table = ({ children, className, ...props }) => (
  <table className={clsx(styles.table, className)} {...props}>
    {children}
  </table>
);

Table.propTypes = {
  children: PropTypes.node.isRequired
};

export default Table;
