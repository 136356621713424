import { useI18n } from '@namespace/i18n';
import { pick } from 'lodash';
import { HTMLSnippet } from '@namespace/snippets';
import { MODE } from '../../constants';
import { useGetPathParams } from '../../hooks/useGetPathParams';
import { useEvent, useSportsbookStore } from '../../store';

export const PreMatchEventSeoSnippet = ({ snippetId, className }) => {
  const { f } = useI18n();
  const { eventId } = useGetPathParams(MODE.PRE_MATCH);
  const DATE_FORMATS_TYPES = f.getFormats();
  const { markets = {}, outcomes = {} } = useSportsbookStore([
    'markets',
    'outcomes'
  ]);
  const event = useEvent(eventId);

  const {
    sportName,
    eventName,
    tournamentName,
    categoryName,
    eventDt,
    participants = []
  } = event || {};

  const { outcomeIds = [] } =
    Object.values(markets).find(
      ({ eventId: eId, marketTemplateId }) =>
        eId === Number(eventId) && marketTemplateId === 1
    ) || {};

  const [
    drawOutcome,
    participant1WinOutcome,
    participant2WinOutcome
  ] = Object.values(pick(outcomes, outcomeIds)).sort(
    (
      { participantNumber: participantNumberA },
      { participantNumber: participantNumberB }
    ) => participantNumberA - participantNumberB
  );

  const eventStartTime = eventDt
    ? f
        .getDateTime({ date: eventDt * 1000 })
        .toFormat(DATE_FORMATS_TYPES.CASINO_ROUNDS_DATE_FORMAT)
    : '';

  const [participant1, participant2] = participants;

  return (
    <HTMLSnippet
      snippetId={snippetId}
      className={className}
      replaceMap={{
        0: sportName,
        1: categoryName,
        2: tournamentName,
        3: eventName,
        4: eventStartTime,
        5: participant1?.participantName,
        6: participant2?.participantName,
        7: participant1WinOutcome?.outcomeCoef,
        8: participant2WinOutcome?.outcomeCoef,
        9: drawOutcome?.outcomeCoef
      }}
    />
  );
};
