import { useMemo, useState, useRef, useEffect } from 'react';
import { throttle } from 'lodash';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { Box, AText, LazyLoadImage } from '@alf/uikit';
import {
  LOG_ACTIONS,
  FIRST_IMPRESSION_PERCENTAGE,
  logAction
} from '@namespace/analytics';
import { window } from '@namespace/helpers';
import { useProviderByServiceId } from '../../../../../context/selectors';
import JackpotItem from '../JackpotItem';
import { formatJackpot, getSortedJackpotTypes } from '../utils';
import ProviderImage from '../../../../../common/ProviderImage';

import styles from './index.module.css';

export const JackpotMultipleRows = ({
  jackpotIdentifier,
  jackpot,
  jackpotAction,
  jackpotImage,
  currency,
  providedServiceId,
  handleJackpotClick
}) => {
  const { t } = useI18n();
  const { providerLogo, providerColoredLogo } = useProviderByServiceId(
    providedServiceId
  );
  const [isLoadJackpotImageError, setIsLoadJackpotImageError] = useState(false);
  const ref = useRef(null);
  const isFirstImpressionRef = useRef(null);
  const formattedJackpot = useMemo(
    () => formatJackpot(jackpot, jackpotIdentifier),
    [jackpot, jackpotIdentifier]
  );
  const sortedJackpotTypes = useMemo(
    () => getSortedJackpotTypes(formattedJackpot, jackpotIdentifier, 'desc'),
    [formattedJackpot, jackpotIdentifier]
  );

  const handleJackpotImageLoadError = () => {
    setIsLoadJackpotImageError(true);
  };

  useEffect(() => {
    const handleScroll = throttle(
      () => {
        if (!isFirstImpressionRef.current) {
          const rect = ref.current.getBoundingClientRect();
          const windowHeight = window.innerHeight;
          const visibleHeight =
            Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);
          const visiblePercent = Math.round(
            (visibleHeight / rect.height) * 100
          );

          if (visiblePercent >= FIRST_IMPRESSION_PERCENTAGE) {
            isFirstImpressionRef.current = true;
            logAction(LOG_ACTIONS.FIRST_IMPRESSION, {
              promotionPath: jackpotAction
            });
          }
        }
      },
      200,
      { trailing: false }
    );

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [jackpotAction]);

  return (
    <LocalizedLink
      className={styles.jackpotByProviderMultiple}
      to={jackpotAction}
    >
      <Box
        direction="row"
        ref={ref}
        className={styles.wrapper}
        align="center"
        onClick={() => handleJackpotClick(jackpotAction)}
      >
        <Box justify="around" className={styles.providerInfoWrapper}>
          {isLoadJackpotImageError ? (
            <AText
              className={styles.title}
              color="accent_color_3"
              breed="BU"
              size="2xl"
            >
              {t(`games.jackpot.${providedServiceId}.title`)}
            </AText>
          ) : (
            <LazyLoadImage
              src={jackpotImage}
              onError={handleJackpotImageLoadError}
              className={styles.jackpotMultipleJackpotImage}
              alt={`${t(`games.jackpot.${providedServiceId}.title`)} jackpot`}
            />
          )}
          {providerLogo && providerColoredLogo && (
            <ProviderImage
              providerLogo={providerLogo}
              providerColoredLogo={providerColoredLogo}
              classNames={{
                providerLogoClassName: styles.jackpotMultipleProviderImage
              }}
            />
          )}
        </Box>
        <Box
          wrap={true}
          flex="1"
          justify="center"
          className={styles.jackpotMultipleList}
        >
          {sortedJackpotTypes.map((type) => (
            <JackpotItem
              classNames={{ wrapperClassName: styles.item }}
              key={`${jackpotIdentifier}_${type}`}
              type={type}
              jackpotAmounts={formattedJackpot[type]}
              jackpotIdentifier={jackpotIdentifier}
              currency={currency}
            />
          ))}
        </Box>
      </Box>
    </LocalizedLink>
  );
};
