import { useContext } from 'react';
import { useI18n } from '@namespace/i18n';
import { UserContext } from '@namespace/user';
import { AText } from '@alf/uikit';
import TextNotification from './TextNotification';

const StatusPending = () => {
  const { t } = useI18n();
  const [user] = useContext(UserContext);
  const { email } = user;
  return (
    <TextNotification>
      <AText size="m" color="po_txt_color_1">
        {t(`profileDocuments.information.pending_1`, [email])}
        &nbsp;
      </AText>
      <AText size="m" color="po_txt_color_1" breed="B">
        {t(`profileDocuments.information.pending_2`)}
      </AText>
    </TextNotification>
  );
};

export default StatusPending;
