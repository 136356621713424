import { useCallback, useMemo } from 'react';
import { path, pathOr } from 'ramda';
import clsx from 'clsx';
import { formatAsUriParameters } from '@namespace/helpers';
import { useConfig, useGeneralRoutes } from '@namespace/cms';
import { useI18n, useLocation } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { Box } from '@alf/uikit';
import { MODE } from '../../../constants';
import { MenuHeader } from './components/MenuHeader';
import {
  CustomFilterLink,
  FavoritesLink,
  FilterMenuList
} from '../../../common';
import { useGetFilterParams } from '../../../hooks/useGetFilterParams';
import { useGetPathParams } from '../../../hooks/useGetPathParams';
import {
  useCategoriesBySportId,
  useSportsbookStore,
  useTournamentsIds
} from '../../../store';
import { useAdvFilterStore } from '../../../storeAdvFilter';
import { AdvFilter } from '../../AdvancedFilter';
import styles from './index.module.css';

export const PreMatchFilterList = ({ id }) => {
  const { replace, push } = useHistory();
  const { pathname } = useLocation();
  const {
    preMatchFilter,
    preMatchSport,
    preMatchFavorites,
    preMatchCategory,
    preMatchTournament
  } = useGeneralRoutes();
  const { t } = useI18n();

  const { config } = useConfig(id);
  const { sportSlug, categoryId, tournamentId, activeSport } = useGetPathParams(
    MODE.PRE_MATCH,
    false
  );
  const { sportId } = activeSport || {};
  const { sportSlugs, tournamentIds = [] } = useGetFilterParams(preMatchFilter);

  const actualSportSlugs = useMemo(
    () => (sportSlug ? [sportSlug] : sportSlugs),
    [sportSlugs, sportSlug]
  );

  const actualTournamentIds = useMemo(
    () => (tournamentId ? [tournamentId] : tournamentIds),
    [tournamentId, tournamentIds]
  );

  const actualCategoryIds = useMemo(() => (categoryId ? [categoryId] : []), [
    categoryId
  ]);

  const sports = useSportsbookStore(path(['sports', MODE.PRE_MATCH]));
  const favoritesSports = useSportsbookStore(
    pathOr([], ['favorites', 'sports'])
  );
  const isAdvFilterOpened = useAdvFilterStore('isAdvFilterOpened');
  const advFilter = useAdvFilterStore('advFilter');

  const categoriesIdsBySportId = useCategoriesBySportId(
    sportId,
    MODE.PRE_MATCH
  );
  const tournamentsIds = useTournamentsIds(MODE.PRE_MATCH);

  const handleFilter = useCallback(
    ({ categories = [], tournaments = [] }) => {
      const sportsSlug = actualSportSlugs.join(',');
      if (categories.length === 1 && tournaments.length === 0) {
        const isSportPage = pathname.includes(preMatchSport);
        const newPath = `${preMatchCategory}/${sportsSlug}/${categories[0]}`;

        return isSportPage ? push(newPath) : replace(newPath);
      }

      const filter = formatAsUriParameters({ categories, tournaments }, true);

      return replace(
        tournaments.length === 1
          ? `${preMatchTournament}/${sportsSlug}/${tournaments[0]}`
          : `${preMatchFilter}/${sportsSlug}/?${filter}`
      );
    },
    [
      actualSportSlugs,
      pathname,
      preMatchCategory,
      preMatchFilter,
      preMatchSport,
      preMatchTournament,
      push,
      replace
    ]
  );

  const classes = clsx(
    styles.preMatchList,
    isAdvFilterOpened && styles.advFilterOpened
  );

  return (
    <Box
      data-role="preMatchFilterList preMathList"
      direction="column"
      className={classes}
    >
      <MenuHeader
        title="title"
        classNames={{
          headerClassName: styles.menuHeaderPreMatch
        }}
        dataRole="prematch-sport"
      />
      {isAdvFilterOpened && advFilter != null ? (
        <AdvFilter />
      ) : (
        <>
          <FavoritesLink
            to={preMatchFavorites}
            title={t('sportsbook.favorites')}
            className={styles.favoriteItem}
          />
          {advFilter?.length > 0 && (
            <CustomFilterLink className={styles.favoriteItem} />
          )}
          <FilterMenuList
            serviceId={MODE.PRE_MATCH}
            config={config}
            sports={sports}
            favoritesSports={favoritesSports}
            sportSlugs={actualSportSlugs}
            categories={categoriesIdsBySportId}
            categoryIds={actualCategoryIds}
            tournaments={tournamentsIds || []}
            tournamentIds={actualTournamentIds}
            basePath={preMatchSport}
            categoryRoute={preMatchFilter}
            setFilter={handleFilter}
          />
        </>
      )}
    </Box>
  );
};
