import { memo, useCallback, useMemo } from 'react';
import { pathOr } from 'ramda';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box } from '@alf/uikit';
import EventMarketContainer from '../EventMarketContainer';
import { useSportsbookStore } from '../../store';
import styles from './index.module.css';

const EventMarket = ({
  marketId,
  marketTemplateId,
  resultTypeId,
  isShowName = false,
  isShowShortName = false,
  lineView,
  className
}) => {
  const market = useSportsbookStore(pathOr({}, ['markets', marketId]));

  const {
    marketTemplateId: currentMarketTemplateId,
    resultTypeId: currentResultTypeId,
    outcomeIds
  } = market;

  const classNames = useMemo(
    () => ({
      outcomeContainerClassName: clsx(
        styles.eventMarket,
        lineView && styles[lineView]
      ),
      ...className
    }),
    [className, lineView]
  );

  const onMarketClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  if (
    resultTypeId !== Number(currentResultTypeId) ||
    marketTemplateId !== Number(currentMarketTemplateId)
  ) {
    return null;
  }

  return (
    <Box flex="1 0 100%" onClick={onMarketClick}>
      <EventMarketContainer
        market={market}
        outcomeIds={outcomeIds}
        isShowName={isShowName}
        isShowShortName={isShowShortName}
        lineView={lineView}
        classNames={classNames}
      />
    </Box>
  );
};

EventMarket.propTypes = {
  marketId: PropTypes.number.isRequired,
  marketTemplateId: PropTypes.number.isRequired,
  resultTypeId: PropTypes.number.isRequired,
  isShowName: PropTypes.bool,
  isShowShortName: PropTypes.bool
};

export default memo(EventMarket);
