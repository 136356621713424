export const SIZES = [
  'xxs',
  'xs',
  's',
  'm',
  'ms',
  'l',
  'xl',
  'xxl',
  'xxxl',
  '0'
];

// React automatically appends 'px' to certain numeric inline style properties: that happens only if the type of value is number. And it won't work for shorthand props with multiple values because it's a string, like this: margin: "8 16".
// That's why we have numbers here, not strings.
export const SIZES_VALUE = {
  xxs: 2,
  xs: 4,
  s: 8,
  m: 16,
  ms: 24,
  l: 32,
  xl: 48,
  xxl: 64,
  xxxl: 128
};
