import { useContext } from 'react';
import { DeviceContext } from '@namespace/device';
import { SiteSettingsContext, useOtherSettings } from '@namespace/cms';
import { Box } from '@alf/uikit';
import { LocalizedNavLink, useI18n } from '@namespace/i18n';
import CollapsibleMenu from '../../common/CollapsibleMenu';
import styles from './index.module.css';

const ItemLink = ({
  link = '',
  title,
  className = '',
  activeClassName = ''
}) => (
  <LocalizedNavLink
    to={link}
    className={className}
    activeClassName={activeClassName}
  >
    {title}
  </LocalizedNavLink>
);

const SubMenu = ({ link, isCollapsible, ...rest }) => {
  if (link) {
    return (
      <CollapsibleMenu
        isCollapsible={false}
        TitleComponent={(props) => <ItemLink {...props} />}
        {...rest}
      />
    );
  }
  return <CollapsibleMenu isCollapsible={isCollapsible} {...rest} />;
};

const FooterMenu = ({
  id,
  classNames = {},
  isCustomFooter = false,
  displayMoreIcon,
  displayLessIcon,
  collapsibleIconSize
}) => {
  const {
    containerClass = '',
    itemClass = '',
    linkClass = '',
    activeLinkClass = '',
    collapsibleMenuClassNames = {}
  } = classNames;
  const [{ components = {}, componentsMobile = {} }] = useContext(
    SiteSettingsContext
  );
  const {
    turnOffCollapsibleFooter = {},
    footer = { footerMenuConfig: {} }
  } = useOtherSettings();
  const { sectionForCollapsibleOff = [] } = turnOffCollapsibleFooter;
  const { isDesktop } = useContext(DeviceContext);
  const currentComponents = isCustomFooter ? componentsMobile : components;
  const { config = {} } = currentComponents[id] || footer?.footerMenuConfig;
  const { items = [] } = config;
  const { t } = useI18n();
  const collapMenuClassNames = isCustomFooter
    ? {
        containerClass: styles.customFooter,
        itemClass: styles.customFooterItem,
        titleClass: styles.customFooterTitle,
        linkClass: styles.customFooterLink,
        menuClass: styles.customFooterMenu
      }
    : collapsibleMenuClassNames;

  return (
    <Box
      justify="between"
      align="start"
      flex="1"
      className={`${styles.containerClass} ${containerClass} ${isCustomFooter &&
        styles.customFooter}`}
    >
      {items.map(({ title, link, items: subItems = [] }, idx) => {
        const isCustomCollapsibleOff = !!sectionForCollapsibleOff?.find(
          (item) => item.toLowerCase() === title.toLowerCase()
        );
        return subItems.length ? (
          <SubMenu
            dataRole={title}
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            title={title}
            link={link}
            items={subItems}
            isCollapsible={
              isCustomCollapsibleOff ? false : !isDesktop || isCustomFooter
            }
            classNames={collapMenuClassNames}
            displayMoreIcon={displayMoreIcon}
            displayLessIcon={displayLessIcon}
            collapsibleIconSize={collapsibleIconSize}
          />
        ) : (
          <Box
            align="center"
            margin="0 m 0 m"
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            className={itemClass}
          >
            <ItemLink
              link={link}
              title={t(title)}
              className={`${styles.link} ${linkClass}`}
              activeClassName={`${styles.active} ${activeLinkClass}`}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default FooterMenu;
