export default `
           <style>
.wrapper {
  padding: 72px 0 86px;
  background: #F2F3F4;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  margin-bottom: 24px;
}

.header span {
  font-family: Roboto Condensed;
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
  text-transform: uppercase;
  color: #140474;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  max-width: 924px;
}

.grid > div {
  display: flex; 
  flex-direction: column;
  flex: 1 0 45%;
  height: 328px;
  background: #0E0F0B;
  box-shadow: 0 1.32621px 2.65242px rgba(112, 133, 174, 0.5);
  border-radius: 2px;
  margin-bottom: 16px;
}

.grid > div:nth-child(2n) {
  margin-left: 16px;
}

.box:not(.box4) > div {
  margin: 28px 0 0 16px;
  display: flex;
}

.box .number {
  font-family: Roboto Condensed;
  font-weight: bold;
  font-size: 40px;
  line-height: 47px;
  color: #FFFFFF;
}

.box img:not(.backgroundImg) {
  width: 44px;
  height: 44px;
  background: #DA1A32;
  border-radius: 50%;
  margin: 0 8px;
  padding: 8px;
}

.box .text {
  font-family: Roboto Condensed;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
}

.box4 > div {
  display: flex;
  flex-direction: column;
}

.box4 span {
  display: inline-flex;
  margin: 28px 16px 16px;
  font-family: Roboto Condensed;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.bet {
  margin: 65px 0 22px 
}

.rate {
  margin: 20px 0 20px 
}

.curve {
  margin: 40px 0 20px 
}

.playBtnWrapper:hover .playBtn {
  background: #cd201f;
}

.playBtnWrapper {
  cursor: pointer;
  position: relative;
}

.playBtn {
  background: rgba(0,0,0,0.55);
  width: 50px;
  height: 24px;
  border-radius: 5px;
  padding-top: 10px;
  position: absolute;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-sizing: content-box;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 0 7px 14px;
  border-color: transparent transparent transparent #ffffff;
  margin: 0 auto;
}

.box4 iframe {
  align-self: center;
  width: 100%;
  height: 263px;
}

.grid > div.mobileBtn {
  display: none;
}

/* mobile & tablet */
@media (max-width: 1023px) {
  .wrapper {
    padding: 44px 0 48px;
  }
  
  .grid {
    flex-direction: column;
    align-items: center;
  }
  
  .grid > div {
    width: 343px;
    height: 84px;
    margin-bottom: 8px;
  }
  
  .grid > div:nth-child(2n) {
    margin-left: 0;
  }
  
  .box:not(.box4) > div {
    margin: 20px 30px 16px 12px;
  }
  
  .box .number {
    font-size: 32px;
    line-height: 32px;
  } 
  
  .box img:not(.backgroundImg) {
    width: 36px;
    height: 36px;
  } 
  
  .box .text {
    font-size: 16px;
    line-height: 16px;
  }
  
  .backgroundImg, .grid .box4 {
    display: none;
  }
  
  .grid > div.mobileBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    min-height: 40px;
    height: 100%;
    margin: 8px 0 0;
    background: #DA1A32;
    border-radius: 2px;
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: Roboto Condensed;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }
}

/* mobile */
@media (max-width: 479px) {
  .wrapper {
    padding: 72px 0 25px;
  }
  
  .header span {
    font-size: 16px;
    line-height: 18px;
  }
  
  div#popup {
    top: 5%;
    height: 85%;
  }
}

/* iframe in modal popup */
#popup, #desktopPopup { 
  display: none; 
  position: fixed; 
  top: 5%; 
  left: 5%; 
  width: 90%; 
  height: 90%; 
  background-color: white; 
  z-index: 15; 
}
#popup iframe, #desktopPopup iframe { 
  width: 100%; 
  height: 100%; 
  border: 0; 
}
#popupBlurredBg, #desktopPopupBlurredBg { 
  display: none; 
  position: fixed; 
  z-index: 12; 
  left: 0; 
  top: 0; 
  width: 100%; 
  height: 100%; 
  overflow: hidden; 
  background-color: rgba(255,255,255, 0.75); 
  backdrop-filter: blur(8px); 
}

    </style>
    <div class="wrapper">
      <div class="header">
        <span>How to play?</span>
      </div>
      <div class="grid">
        <div class="box box1">
          <div>
            <span class="number">01</span>
            <img src="/images/snippetsMedia/aviator/money.svg">
            <span class="text">Сделай ставку или даже две сразу и дождись начала раунда.</span>
          </div>
          <img src="/images/snippetsMedia/aviator/bet.svg" class="backgroundImg bet">
        </div>
        <div class="box box2">
          <div>
            <span class="number">02</span>
            <img src="/images/snippetsMedia/aviator/watermark.svg">
            <span class="text">Следи за самолётом, твой выигрыш это ставка умноженная на коэффициент.</span>
          </div>
          <img src="/images/snippetsMedia/aviator/rate.svg" class="backgroundImg rate">
        </div>
        <div class="box box3">
          <div>
            <span class="number">03</span>
            <img src="/images/snippetsMedia/aviator/banknotes.svg">
            <span class="text">Обналичь до того как самолет улетит и деньги твои!</span>
          </div>
          <img src="/images/snippetsMedia/aviator/curve.svg" class="backgroundImg curve">
        </div>
        <div class="box box4">
          <span>see video instruction</span>
          <div class="playBtnWrapper" id="desktopLink">
            <img src="/images/snippetsMedia/aviator/devices.svg" class="backgroundImg">
            <div class="playBtn">
              <div class="triangle"></div>
            </div>
          </div>
          <div id="desktopPopup"><iframe id="desktopPopupiFrame" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
          <div id="desktopPopupBlurredBg"></div>
        </div>
        <div class="mobileBtn">
          <a href="" id="link">see video instruction</a>
          <div id="popup"><iframe id="popupiFrame" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
          <div id="popupBlurredBg"></div>
        </div>

        <script>
          document.getElementById('desktopLink').onclick = function(e) {
              e.preventDefault();
              document.getElementById('desktopPopupBlurredBg').style.display = "block";
              document.getElementById('desktopPopup').style.display = "block";
              document.getElementById('desktopPopupiFrame').src = "https://www.youtube.com/embed/PZejs3XDCSY";
              document.getElementById('desktopPopupBlurredBg').onclick = function() {
                  document.getElementById('desktopPopup').style.display = "none";
                  document.getElementById('desktopPopupBlurredBg').style.display = "none";
                  document.getElementById('desktopPopupiFrame').src = "";
              };
              return false;
            }
          document.getElementById('link').onclick = function(e) {
            e.preventDefault();
            document.getElementById('popupBlurredBg').style.display = "block";
            document.getElementById('popup').style.display = "block";
            document.getElementById('popupiFrame').src = "https://www.youtube.com/embed/PZejs3XDCSY";
            document.getElementById('popupBlurredBg').onclick = function() {
                document.getElementById('popup').style.display = "none";
                document.getElementById('popupBlurredBg').style.display = "none";
                document.getElementById('popupiFrame').src = "";
            };
            return false;
          }
          window.onkeydown = function(e) {
            if (e.keyCode == 27) {
              document.getElementById('popup').style.display = "none";
              document.getElementById('desktopPopup').style.display = "none";
              document.getElementById('popupBlurredBg').style.display = "none";
              document.getElementById('desktopPopupBlurredBg').style.display = "none";
              e.preventDefault();
              return;
            }
          }
        </script>
      </div>
    </div>
  `;
