import { useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useOtherSettings } from '@namespace/cms';
import { UserContext, USER_GROUP_TYPES } from '@namespace/user';
import { document } from '@namespace/helpers';
import { TourManager } from '../TourManager';
import { DEFAULT_MOCKED_STORES } from '../../mocks';

/**
 * Onboarding entrypoint.
 */
export const Onboarding = () => {
  const [el, setEl] = useState(null);
  const { onboarding = {}, userSettings = {} } = useOtherSettings();
  const { tours, preferredCurrency } = onboarding;
  const { isOnboardingEnabled = false } = userSettings;
  const [user] = useContext(UserContext);
  const { role } = user;

  useEffect(() => {
    setEl(document.querySelector('#onboarding'));
  }, []);

  if (el == null || !isOnboardingEnabled || role === USER_GROUP_TYPES.GUEST) {
    return null;
  }

  return createPortal(
    <TourManager
      tours={tours}
      preferredCurrency={preferredCurrency}
      padding={0}
      mocks={DEFAULT_MOCKED_STORES}
    />,
    el
  );
};
