export const BETSOFT_EVENTS = {
  CLOSE: 'exi_onHomeUserAction'
};

export const betsoftLauncherControl = (event, callbacks) => {
  const { onExitHandler = () => {} } = callbacks;
  const { data = {} } = event;
  const {
    exi_fMessageType_str: exitMessageStr,
    exi_fMessageData_obj: exitMessageObj
  } = data;

  if (
    data &&
    (exitMessageStr === BETSOFT_EVENTS.CLOSE ||
      exitMessageObj === BETSOFT_EVENTS.CLOSE)
  ) {
    onExitHandler();
  }
};
