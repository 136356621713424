import { rePatterns } from '../../patterns';

const emailValidator = (message = 'validators.emailFormatValidator') => ({
  pattern: {
    value: rePatterns.email,
    message
  }
});

export default emailValidator;
