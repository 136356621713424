import { useCallback, useEffect, useState } from 'react';
import {
  FORM_COMPONENTS_TYPES,
  FORM_VALIDATION_TYPES,
  FormBuilder,
  useFormMethods
} from '@namespace/forms';
import { useI18n } from '@namespace/i18n';
import { Box, InfoMessage } from '@alf/uikit';
import { getDccCards } from '../../../../../api/withdraw';
import { paymentSystemsComponentsMap } from '../../constants';
import styles from '../../index.module.css';
import useServiceLimits from '../../../../../hooks/useServiceLimits';

const DCC = ({ wallet, onWithdraw }) => {
  const { t } = useI18n();
  const {
    currency,
    minWithdraw: minWithdAmount,
    maxWithdraw: maxWithdAmount,
    deposit,
    paymentInstrumentName,
    serviceId
  } = wallet;
  const { minWithdraw: minWithd, maxWithdraw: maxWithd } = useServiceLimits(
    serviceId
  );
  const minWithdraw = minWithd || minWithdAmount;
  const maxWithdraw = maxWithd || maxWithdAmount;
  const [cardListOptions, setCardListOptions] = useState([]);

  const defaultValues = {
    cardList: '',
    amount: '',
    password: '',
    wallet: `${paymentInstrumentName}`
  };

  const methods = useFormMethods({ defaultValues });
  const { watch, setValue, setError } = methods;
  const isNewCard = watch('isNewCard');

  useEffect(() => {
    (async () => {
      const cardListResponse = await getDccCards();
      if (cardListResponse.cardList?.length) {
        const newCardListOptions = cardListResponse
          ? Object.values(cardListResponse.cardList).map((number) => ({
              name: number.replace(/\D/g, ''),
              value: number.replace(/\D/g, '')
            }))
          : [];
        setCardListOptions(newCardListOptions);
      } else {
        setValue('isNewCard', true);
      }
    })();
  }, [setCardListOptions, setError, setValue, t]);

  const dynamicValues = {
    cardList: cardListOptions[0]?.value ?? ''
  };

  const onSubmit = useCallback(
    async ({ password, amount, card, cardList }) => {
      await onWithdraw(
        {
          card_number: isNewCard ? card.replace(/\D/g, '') : cardList,
          password,
          amount
        },
        true
      );
    },
    [cardListOptions, isNewCard, setValue]
  );

  const componentsList = [
    {
      type: FORM_COMPONENTS_TYPES.INPUT_FIELD,
      name: 'wallet',
      label: 'balance.withdraw.form.cardDescription',
      disabled: true,
      classNames: {
        wrapperClassName: styles.fieldWrap
      }
    },
    {
      type: FORM_COMPONENTS_TYPES.SELECT,
      name: 'cardList',
      label: 'balance.deposit.dcc.cardNumbers',
      placeholder: 'balance.deposit.dcc.cardNumbers',
      classNames: {
        containerClassName: styles.fieldWrap
      },
      options: cardListOptions,
      disabled: isNewCard
    },
    {
      type: FORM_COMPONENTS_TYPES.CHECKBOX_FIELD,
      name: 'isNewCard',
      label: 'balance.deposit.dcc.newCard',
      classNames: {
        wrapperClassName: `${styles.inputWrapper} ${styles.checkboxWrapper}`
      },
      disabled: !cardListOptions?.length
    },
    {
      type: FORM_COMPONENTS_TYPES.INPUT_FIELD,
      name: isNewCard ? 'card' : 'disabledCard',
      placeholder: 'balance.deposit.dcc.cardNumberPlaceholder',
      validationTypes: isNewCard
        ? [
            FORM_VALIDATION_TYPES.CREDIT_CARD_NUMBER_VALIDATOR,
            FORM_VALIDATION_TYPES.REQUIRED
          ]
        : [],
      classNames: {
        wrapperClassName: styles.fieldWrap
      },
      disabled: !isNewCard
    },
    {
      type: FORM_COMPONENTS_TYPES.AMOUNT_INPUT_FIELD,
      name: 'amount',
      currency,
      label: 'balance.withdraw.form.amount',
      placeholder: 'balance.withdraw.dcc.amountPlaceholder',
      min: 0,
      inputType: 'number',
      validationTypes: [
        FORM_VALIDATION_TYPES.REQUIRED,
        {
          type: FORM_VALIDATION_TYPES.MIN_VALIDATOR,
          parameters: [
            minWithdraw,
            'balance.withdraw.form.minAmount',
            [currency]
          ]
        },
        {
          type: FORM_VALIDATION_TYPES.MAX_VALIDATOR,
          parameters: [
            maxWithdraw || deposit,
            'balance.withdraw.form.maxAmount',
            [currency]
          ]
        }
      ],
      hint: minWithdraw
        ? t('balance.withdraw.form.minAmount', [minWithdraw, currency])
        : '',
      classNames: {
        containerClassName: styles.fieldWrap,
        controlClassName: styles.controlWrap
      },
      isOnChangeValidation: true
    },
    {
      type: FORM_COMPONENTS_TYPES.PASSWORD_FIELD,
      name: 'password',
      classNames: {
        wrapperClassName: styles.fieldWrap
      },
      rulesList: [],
      autoComplete: 'password',
      label: 'balance.withdraw.form.password',
      placeholder: 'balance.withdraw.dcc.passwordPlaceholder',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED]
    },
    {
      type: FORM_COMPONENTS_TYPES.SUBMIT_BUTTON,
      value: 'balance.withdraw.form.submit',
      classNames: {
        className: styles.withdrawButton
      }
    }
  ];

  return (
    <Box direction="column">
      <InfoMessage icon="info" className={styles.info}>
        {t('balance.withdraw.dcc.info')}
      </InfoMessage>
      <FormBuilder
        wrapperClassName={styles.form}
        components={componentsList}
        componentsMap={paymentSystemsComponentsMap}
        onSubmit={onSubmit}
        formConfig={{ defaultValues }}
        isNoValidate={true}
        formProps={{ dynamicValues }}
        formMethods={methods}
      />
    </Box>
  );
};

export default DCC;
