import { useContext, useEffect } from 'react';
import { UserContext } from '@namespace/user';
import { useCurrentTour } from '../useCurrentTour';
import { MAP_OF_TOUR_NAMES_FROM_BE } from '../../constants';
import { setTourAsFinished } from '../../api/setTourAsFinished';

export const useToursCompletion = () => {
  const { id: currentTourId } = useCurrentTour();
  const [user, userActions] = useContext(UserContext);
  const { SET_WAITING_ONBOARDING } = userActions;
  const { id: userId, userOptions = {} } = user;
  const { waitingForOnboarding } = userOptions;

  useEffect(() => {
    (async () => {
      if (
        waitingForOnboarding?.includes(MAP_OF_TOUR_NAMES_FROM_BE[currentTourId])
      ) {
        await setTourAsFinished({
          userId,
          tourName: MAP_OF_TOUR_NAMES_FROM_BE[currentTourId]
        });

        SET_WAITING_ONBOARDING(MAP_OF_TOUR_NAMES_FROM_BE[currentTourId]);
      }
    })();
  }, [currentTourId, waitingForOnboarding, userId, SET_WAITING_ONBOARDING]);
};
