import { useMemo } from 'react';
import { useConfig, useGeneralRoutes } from '@namespace/cms';
import { BreadCrumbsMicrodata } from '@namespace/common';
import { IS_PRERENDER } from '@namespace/helpers';
import { useCyberSportGetPathParams } from '../../hooks/useCyberSportGetPathParams';
import { generateCyberSportBreadCrumbsItems } from '../../utils';

const CyberSportBreadCrumbsMicrodata = ({ id }) => {
  const { config } = useConfig(id);
  const { isPrerenderOnly = true } = config;
  const isRender = !isPrerenderOnly || (isPrerenderOnly && IS_PRERENDER);
  const params = useCyberSportGetPathParams();
  const {
    cyberSport,
    cyberSportCategory,
    cyberSportTournament
  } = useGeneralRoutes();
  const items = useMemo(
    () =>
      isRender
        ? generateCyberSportBreadCrumbsItems(
            params,
            cyberSport,
            cyberSportCategory,
            cyberSportTournament
          )
        : null,
    [isRender, cyberSport, cyberSportTournament, cyberSportCategory, params]
  );

  if (!isRender) {
    return null;
  }
  return <BreadCrumbsMicrodata items={items} />;
};

export default CyberSportBreadCrumbsMicrodata;
