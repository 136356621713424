import { HEADER_HEIGHT, DESKTOP_HEADER_HEIGHT } from '@namespace/common';
import { window, document } from '@namespace/helpers';

export const getDocumentDimensions = () => {
  const el = document.scrollingElement;
  const w = Math.max(el.scrollWidth ?? 0, window.innerWidth);
  const h = Math.max(
    el.scrollHeight ?? 0,
    el.offsetHeight ?? 0,
    window.innerHeight
  );

  return { w, h };
};

/*
const isIntersected = (hole1, hole2) => {
  return false;
};

const getIntersectedHoles = (holes) => {
  const standaloneHoles = [];
  const intersectionGroups = [];
  let groupIndex = 0;

  for (let i = 0; i < holes.length; i += 1) {
    const hole1 = holes[i];

    for (let j = i; j < holes.length; j += 1) {
      const hole2 = holes[j];
      if (isIntersected(hole1, hole2)) {
      }
    }
  }

  return holes;
};
*/

// NB Remember, the perimeter travel direction matters! It affects how SVG will fill those gaps.
//  See https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/fill-rule for more.
export const makeWindowPath = () => {
  const { w: dW, h: dH } = getDocumentDimensions();

  // draw bigger rectangle to make sure it doesn't blink during page metamorphoses
  return `M0,0 H${dW + 2000} V${dH + 2000} H0 Z`;
};

const makeRectangleHolePath = ({ x = 0, y = 0, w = 0, h = 0 }) =>
  `M${x},${y} v${h} h${w} v${-h} Z`;

const makeRoundedRectangleHolePath = (br) => ({ x = 0, y = 0, w = 0, h = 0 }) =>
  [
    `M${x},${y + br}`,
    `v${h - 2 * br}`,
    `a${br},${br},90,0,0,${br},${br}`,
    `h${w - 2 * br}`,
    `a${br},${br},90,0,0,${br},${-br}`,
    `v${-h + 2 * br}`,
    `a${br},${br},90,0,0,${-br},${-br}`,
    `h${-w + 2 * br}`,
    `a${br},${br},90,0,0,${-br},${br}`,
    `Z`
  ].join(' ');

const makeHolePath = (br = 0) =>
  br === 0 ? makeRectangleHolePath : makeRoundedRectangleHolePath(br);

// todo There are 2 methods to handle multiple rects intersection.
/*
  Before each method (?):
  1. Find intersecting rects and split all rects into intersecting groups.

  Method A:
  1. find intersection points of each possible pair of initial rectangles
  2. for each pair: create new rectangle using 2 intersection points and 2 inner points
  3. set stroke-width to 0
  4. these extra overlapping rectangles should cover the defects of intersecting rectangles.
  This method is more simple but creates extra objects. Also, its unclear if this will work well on all platforms.

  Method B:
  1. write an algorithm to find resulting shape by intersecting of multiple rects. The points also must go in order of perimeter
  2. Build resulting shape using <path />
  This method is preferable as it creates fewer objects and thus should be more performant. But the complexity of algorithm may take its tall and counterweight resulting performance, so testing is required.
 */
export const makeOverlayPath = (holes = [], br) => {
  const outerRect = makeWindowPath();
  const holeRects = holes.map(makeHolePath(br));

  return `${outerRect} ${holeRects.join(' ')}`;
};

export const getVisibleRect = async (el, scrollOffset = 0, isDesktop) =>
  new Promise((resolve) => {
    const { scrollTop } = document.scrollingElement;
    const headerEl = document.querySelector('[data-role=c-headerWrapper]');
    const burgerMenuEl = document.querySelector(
      '[data-role=burger-menu-inner]'
    );
    const isHeaderEl = headerEl?.contains(el) || burgerMenuEl?.contains(el);

    const obs = new IntersectionObserver((entries) => {
      obs.unobserve(el);

      const { x, y, width: w, bottom } = entries[0].intersectionRect;
      const headerHeight = isDesktop ? DESKTOP_HEADER_HEIGHT : HEADER_HEIGHT;
      const clippedY = isHeaderEl
        ? y
        : Math.max(y, headerHeight + scrollOffset);
      const clippedH = bottom - clippedY;

      if (clippedH <= 0) {
        resolve(null);
      } else {
        resolve({ x, y: clippedY + scrollTop, w, h: clippedH });
      }
    });

    obs.observe(el);
  });

export const getVisibleRects = (els, scrollOffset, isDesktop) =>
  Promise.all(els.map((el) => getVisibleRect(el, scrollOffset, isDesktop)));

/**
 * Pad rect by provided amount
 * @param {Number} x
 * @param {Number} y
 * @param {Number} w
 * @param {Number} h
 * @param {Number} padding
 * @returns {{w: Number, x: Number, h: Number, y: Number}}
 */
export const padRect = ({ x, y, w, h }, padding = 0) => ({
  x: x - padding,
  y: y - padding,
  w: w + padding * 2,
  h: h + padding * 2
});
