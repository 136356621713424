import { min, keyBy, isEmpty } from 'lodash';
import { getIsJackpotsIncludeUserCurrency } from '../utils';

export const formatInHouseJackpotWinnersGames = ({ games, providers }) => {
  const formattedData = {};

  const providersMap = keyBy(providers, (provider) => provider.providerId);

  for (const game of games) {
    formattedData[game.launchGameId] = {
      gameId: game.gameId,
      gameName: game.gameName,
      providerId: game.providerId,
      hasDemoSupport: game.supportDemo,
      providerName: providersMap[game.providerId]?.providerName
    };
  }

  // games by launchGameId with all info required to show winner info in jackpot
  return formattedData;
};

export const calcTotalInHouseJackpot = (jackpots) => {
  const totalInHouseJackpot = {
    convertedAmount: 0,
    maxValue: 0
  };
  const jackpotsRefreshRates = [];

  for (const jackpot of Object.values(jackpots)) {
    totalInHouseJackpot.convertedAmount += jackpot.convertedAmount;
    totalInHouseJackpot.maxValue +=
      jackpot.convertedAmount * jackpot.amountCoef;

    if (jackpot.refreshRate) jackpotsRefreshRates.push(jackpot.refreshRate);
    if (!totalInHouseJackpot.currency)
      totalInHouseJackpot.convertedCurrency = jackpot.convertedCurrency;
  }

  return {
    ...totalInHouseJackpot,
    refreshRate: min(jackpotsRefreshRates)
  };
};

export const formatJackpots = (jackpots, userCurrency) => {
  const isJackpotsIncludeUserCurrency = getIsJackpotsIncludeUserCurrency(
    jackpots,
    userCurrency
  );
  const defaultCurrency = Object.values(jackpots)[0].currency;
  const convertedCurrency = isJackpotsIncludeUserCurrency
    ? userCurrency
    : defaultCurrency;
  const formattedJackpots = {};

  for (const [jackpotType, jackpot] of Object.entries(jackpots)) {
    formattedJackpots[jackpotType] = {
      ...jackpot,
      convertedCurrency,
      convertedAmount: isJackpotsIncludeUserCurrency
        ? jackpot.additionalCurrencies[userCurrency]
        : jackpot.amount,
      convertedActivationAmount: isJackpotsIncludeUserCurrency
        ? jackpot.activationAmountAdditionalCurrencies[userCurrency]
        : jackpot.activationAmount,
      ...(isEmpty(jackpot.lastWinner)
        ? {}
        : {
            lastWinner: {
              ...jackpot.lastWinner,
              convertedAmount: isJackpotsIncludeUserCurrency
                ? jackpot?.lastWinner?.additionalCurrencies?.[userCurrency]
                : jackpot?.lastWinner?.amount
            }
          }),
      ...(isEmpty(jackpot.maxWinner)
        ? {}
        : {
            maxWinner: {
              ...jackpot.maxWinner,
              convertedAmount: isJackpotsIncludeUserCurrency
                ? jackpot?.maxWinner?.additionalCurrencies?.[userCurrency]
                : jackpot?.maxWinner?.amount
            }
          })
    };
  }

  return formattedJackpots;
};
