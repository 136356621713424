import { AText, Box, ProgressBar } from '@alf/uikit';
import { useI18n, useLocation } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import {
  SiteSettingsContext,
  useGeneralRoutes,
  useOtherSettings
} from '@namespace/cms';
import { USER_ROLES, UserContext, useUserInfoLoaded } from '@namespace/user';
import { formatNumToAmount } from '@namespace/games';
import { getCurrencySymbol } from '@namespace/helpers';
import { useNotifications } from '@namespace/notifications';
import { useGetImage, useThemeComponents } from '@namespace/themes';
import { useContext, useState } from 'react';
import { DeviceContext } from '@namespace/device';
import { useGetNumberOfDecimalPlaces } from '@namespace/common';
import { PROMO_TYPES } from '../../constants';
import {
  useGetTournamentServiceTournamentInfo,
  usePromotionsStore
} from '../../store';
import { getPromoImage, getPromoItemByCurrency } from '../../utils';
import DateTime from '../DateTime';
import Tags from '../Tags';

import { TournamentTakePartButton } from '../TournamentTakePartButton';
import { TournamentItemInfoSkeleton } from './TournamentItemInfoSkeleton';
import { useGetCardContentSettings } from './useGetCardContentSettings';

import styles from './index.module.css';

export const PromotionsTournamentItem = ({
  promo = {},
  tournamentCardSize = ''
}) => {
  const [user] = useContext(UserContext);
  const [settings] = useContext(SiteSettingsContext);
  const { isPutCurrencyRightTournaments } = useOtherSettings();
  const { userInfoLoaded } = useUserInfoLoaded();
  const isTournamentsLoaded = usePromotionsStore('isTournamentsLoaded');
  const { t, language } = useI18n();
  const { realPageType } = useContext(DeviceContext);
  const { push } = useHistory();
  const {
    promoId,
    titleTranslationKey,
    tournamentId,
    tags,
    defaultImageLink,
    defaultImageLinkDark,
    showTimerInsteadPeriod,
    periodStart,
    periodEnd,
    settingsPerCurrency = [],
    defaultDescriptionTranslationKey,
    actionLink,
    showTakePart,
    imagesPerLanguage,
    showFrom
  } = promo;
  const { activeWallet = {}, role } = user;
  const { currency: userCurrency = null } = activeWallet;
  const { tournament } = useGeneralRoutes();
  const { pathname } = useLocation();
  const [isImageLoadError, setIsImageLoadError] = useState(false);
  const getImg = useGetImage();

  const {
    user: tournamentUserInfo = {}
  } = useGetTournamentServiceTournamentInfo(tournamentId);
  const { qualificationProgress } = tournamentUserInfo;
  const { promoTags, siteConfig } = settings;
  const { defaultCurrency } = siteConfig;
  const currentCurrency = userCurrency || defaultCurrency;
  const promoLink = actionLink || `${tournament}/${promoId}`;
  const isLoggedInUser = role === USER_ROLES.USER;
  const isYouAreInTagShown =
    tournamentUserInfo.participant && !tournamentUserInfo.blocked;
  const currentDate = new Date();
  const periodStartDate = new Date(periodStart);
  const periodEndDate = new Date(periodEnd);
  const isTournamentStarted = currentDate >= periodStartDate;
  const isTournamentFinished = periodEndDate <= currentDate;
  // tournament info is loading only for logged in user
  const isItemInfoSkeletonShown = isLoggedInUser ? !isTournamentsLoaded : false;
  const { errorNotification } = useNotifications();
  const numberOfDecimalPlaces = useGetNumberOfDecimalPlaces();
  const { TournamentItem: PartnerTournamentItem } = useThemeComponents();

  let currentDefaultImageLink = defaultImageLink;
  let currentDefaultImageLinkDark = defaultImageLinkDark;
  let currentImagesPerLanguage = imagesPerLanguage;

  // temporary solution!! this condition will be deleted when per platform
  // functionality for promos images will be on prod envs
  if (promo[realPageType]) {
    const {
      defaultImageLink: defaultImageLinkForPlatform,
      defaultImageLinkDark: defaultImageLinkDarkForPlatform,
      imagesPerLanguage: imagesPerLanguageForPlatform
    } = promo[realPageType];

    currentDefaultImageLink = defaultImageLinkForPlatform;
    currentDefaultImageLinkDark = defaultImageLinkDarkForPlatform;
    currentImagesPerLanguage = imagesPerLanguageForPlatform;
  }

  const defaultTournamentImage = getImg(
    currentDefaultImageLink,
    currentDefaultImageLinkDark
  );

  const promoImage = getPromoImage(
    settingsPerCurrency,
    currentImagesPerLanguage,
    language,
    realPageType,
    currentCurrency
  );
  const promoShortDescription =
    getPromoItemByCurrency(
      settingsPerCurrency,
      currentCurrency,
      language,
      realPageType,
      getImg,
      'descriptionTranslationKey'
    ) || defaultDescriptionTranslationKey;
  const tournamentPrize = getPromoItemByCurrency(
    settingsPerCurrency,
    currentCurrency,
    language,
    realPageType,
    getImg,
    'tournamentPrize'
  );

  const handleClickPromo = () => {
    if (tournamentUserInfo?.blocked) {
      errorNotification(t('web.tournaments.message.blocked'));

      return;
    }

    push(promoLink);
  };

  const formattedTournamentPrize = tournamentPrize
    ? formatNumToAmount({
        num: Number(tournamentPrize),
        showCents: false,
        numberOfDecimalPlaces
      })
    : null;

  const cardContentSettings = useGetCardContentSettings({
    isLoggedInUser,
    tournamentUserInfo,
    isTournamentFinished,
    isTournamentStarted,
    showTakePart
  });

  const {
    isDescriptionShown,
    isTakePartButtonShown,
    isQualificationProgressBarShown,
    isTimerShown,
    isUserBlockedShown
  } = cardContentSettings;

  if (PartnerTournamentItem) {
    return (
      <PartnerTournamentItem
        cardSize={tournamentCardSize}
        promo={promo}
        isYouAreInTagShown={isYouAreInTagShown}
        isImageLoadError={isImageLoadError}
        setIsImageLoadError={setIsImageLoadError}
        defaultTournamentImage={defaultTournamentImage}
        promoImage={promoImage}
        promoTags={promoTags}
        currentCurrency={currentCurrency}
        formattedTournamentPrize={formattedTournamentPrize}
        cardContentSettings={cardContentSettings}
        promoShortDescription={promoShortDescription}
        qualificationProgress={qualificationProgress}
        handleClickPromo={handleClickPromo}
      />
    );
  }

  return (
    <Box
      className={styles.promotionsItemWrapper}
      align="center"
      onClick={handleClickPromo}
      data-role="tournaments-item"
    >
      <Box className={styles.tournamentImageWrapper} justify="center">
        <Box className={styles.tournamentImageOverlay} />
        <img
          src={
            (isImageLoadError ? defaultTournamentImage : promoImage) ||
            defaultTournamentImage
          }
          className={styles.tournamentImage}
          onError={() => setIsImageLoadError(true)}
          alt="tournament"
          data-role="tournaments-item-image"
        />
      </Box>
      <Box
        className={styles.tournamentInfoWrapper}
        align="center"
        direction="column"
        justify="between"
      >
        {isItemInfoSkeletonShown || !userInfoLoaded ? (
          <TournamentItemInfoSkeleton />
        ) : (
          <>
            <Box direction="column" align="center">
              <Tags
                tags={[...tags, ...(isYouAreInTagShown ? ['youAreIn'] : [])]}
                promoTagsConfig={promoTags}
                className={styles.tagsWrapper}
                showFrom={showFrom}
                isTournamentParticipant={tournamentUserInfo?.participant}
              />
              <Box justify="center">
                <AText
                  className={styles.promoTitle}
                  breed="BU"
                  size="l"
                  color="txt_color_3"
                  data-role="tournaments-item-title"
                  align="center"
                >
                  {t(`${titleTranslationKey}`, promoId)}
                </AText>
              </Box>
              {formattedTournamentPrize && (
                <Box padding="m 0 0 0">
                  <Box padding="0 s 0 0">
                    <AText breed="BU" size="l" color="txt_color_7">
                      {t('web.tournaments.prize.label')}
                      <>:</>
                    </AText>
                  </Box>
                  <Box
                    direction={`${
                      !isPutCurrencyRightTournaments ? 'row' : 'row-reverse'
                    }`}
                  >
                    <AText
                      breed="BU"
                      size="l"
                      color="txt_color_3"
                      className={
                        isPutCurrencyRightTournaments && styles.currency
                      }
                    >
                      {getCurrencySymbol(currentCurrency)}
                    </AText>
                    <AText breed="BU" size="l" color="txt_color_3">
                      {formattedTournamentPrize}
                    </AText>
                  </Box>
                </Box>
              )}
              {isDescriptionShown && (
                <Box padding="s 0 0 0">
                  <AText
                    breed="R"
                    size="m"
                    color="txt_color_3"
                    align="center"
                    className={styles.shortDescription}
                  >
                    {t(promoShortDescription)}
                  </AText>
                </Box>
              )}
            </Box>
            {isTakePartButtonShown && (
              <Box padding="m 0 0 0" className={styles.takePartBtnWrapper}>
                <TournamentTakePartButton
                  loginRedirectTo={pathname}
                  tournamentId={tournamentId}
                />
              </Box>
            )}
            {isQualificationProgressBarShown && (
              <Box
                className={styles.qualificationProgressWrapper}
                direction="column"
                align="center"
              >
                <Box padding="0 0 s 0">
                  <Box padding="0 xs 0 0">
                    <AText breed="BU" size="l" color="po_txt_color_3">
                      {t('web.tournaments.lobby.qualification')}
                      <>:</>
                    </AText>
                  </Box>
                  <AText breed="BU" size="l" color="po_txt_color_1">
                    {qualificationProgress?.qualificationBets}
                    <> /</>
                    {qualificationProgress?.bets}
                  </AText>
                </Box>
                <ProgressBar
                  currentValue={qualificationProgress?.qualificationBets}
                  totalValue={qualificationProgress?.bets}
                  progressColor="accent_color_3"
                  containerColor="po_bg_color_2"
                />
              </Box>
            )}
            {isTimerShown && (
              <Box padding="m 0 0 0">
                <DateTime
                  promoData={promo}
                  showTimerInsteadPeriod={showTimerInsteadPeriod}
                  isPromoItem={true}
                  dateTextColor="txt_color_2"
                  dateTextSize="2xl"
                  className={styles.dateTimeWrapper}
                  activePromoType={PROMO_TYPES.tournament}
                />
              </Box>
            )}
            {isUserBlockedShown && (
              <Box align="center">
                <AText breed="BU" size="2xl" color="txt_error">
                  {t('web.tournaments.lobby.blocked')}
                </AText>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
