import { InfoMessage as DefaultInfoMessage } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';

export const InfoMessage = ({
  customMessageComp: CustomMessage,
  message,
  ...rest
}) => {
  const { t } = useI18n();
  return (
    <>
      {CustomMessage ? (
        <CustomMessage />
      ) : (
        <DefaultInfoMessage icon="info" {...rest}>
          {t(message)}
        </DefaultInfoMessage>
      )}
    </>
  );
};
