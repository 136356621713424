import { useComponentConfig, useOtherSettings } from '@namespace/cms';

// need this solution till showWagerSlotsCategory is implemented in CMS
export const useGetShowWagerSlotsCategory = () => {
  const componentConfig = useComponentConfig();
  const otherSettings = useOtherSettings();

  if (componentConfig.showWagerSlotsCategory !== undefined) {
    return componentConfig.showWagerSlotsCategory;
  }

  return otherSettings.showWagerSlotsCategory;
};
