import { pathOr } from 'ramda';
import { shallow } from 'zustand/shallow';
import { useSportsbookStore } from '../../../../store';

export const selectEventField = (eventId, fieldPath) =>
  pathOr(null, [
    'events',
    eventId,
    ...(Array.isArray(fieldPath) ? fieldPath : [fieldPath])
  ]);

export const useEventField = (eventId, fieldPath) =>
  useSportsbookStore(selectEventField(eventId, fieldPath), shallow);
