import { Iframe } from '@alf/uikit';
import PaymentSystemResultForm from '../PaymentSystemResultForm';
import styles from './index.module.css';

const PaymentSystemResultFormIframe = ({ formData, action }) => {
  return (
    <>
      <PaymentSystemResultForm
        formData={formData}
        action={action}
        target="depositIframe"
      />
      <Iframe src="" name="depositIframe" className={styles.iframe} />
    </>
  );
};

export default PaymentSystemResultFormIframe;
