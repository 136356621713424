import { useCallback } from 'react';
import { useConfig } from '@namespace/cms';
import { useActiveHeadGroups } from '../../hooks/useActiveHeadGroups';
import { LINE_KEY, MODE } from '../../constants';
import AllPreMatchSportButton from '../AllPreMatchSportButton';
import EventsLineHeader from '../EventsLineHeader';
import EventsLineTitle from '../EventsLineTitle';
import EventsLineHeadGroups from '../EventsLineHeadGroups';
import LineSport from '../LineSport';
import styles from './index.module.css';

const LiveLine = ({
  id,
  sport = {},
  isLiveEvents,
  isReady,
  isExpanded,
  setIsExpanded
}) => {
  const { sportId, sportName = '', eventCount, sportSlug } = sport;
  const { config } = useConfig(id);
  const {
    isHeadGroupsSortingEnabled,
    activeHeadGroupsAmount,
    isDisplayHeadMarkets = true
  } = config;
  const [activeHeadGroups, setActiveHeadGroups] = useActiveHeadGroups(
    isHeadGroupsSortingEnabled,
    activeHeadGroupsAmount,
    sportId,
    MODE.IN_PLAY
  );
  const toggleExpanded = useCallback(
    () =>
      setIsExpanded((prevState) => (prevState !== sportId ? sportId : null)),
    [setIsExpanded, sportId]
  );

  return (
    <div data-role={sportSlug}>
      <EventsLineHeader
        borderBottom={true}
        isExpanded={isExpanded}
        isCollapsible={true}
        toggleExpanded={toggleExpanded}
        className={styles.lineWrapper}
      >
        <EventsLineTitle
          title={`${sportName} (${eventCount})`}
          sportId={sportId}
          toggleExpanded={toggleExpanded}
        />
        {isExpanded && (
          <EventsLineHeadGroups
            serviceId={MODE.IN_PLAY}
            sport={{ sportId }}
            isHeadGroupsSortingEnabled={true}
            activeHeadGroups={activeHeadGroups}
            setActiveHeadGroups={setActiveHeadGroups}
            isDisplayHeadMarkets={isDisplayHeadMarkets}
            className={styles.headGroups}
          />
        )}
      </EventsLineHeader>
      {isExpanded && (
        <>
          <LineSport
            id={id}
            sport={sport}
            key={`live-sport-${sportId}`}
            serviceId={MODE.IN_PLAY}
            lineKey={`${LINE_KEY.LIVE}-${sportId}`}
            activeHeadGroups={activeHeadGroups}
            isShowEmpty={false}
            isCollapsibleTournaments={false}
            isLiveEvents={isLiveEvents}
            isReady={isReady}
          />
          <AllPreMatchSportButton sport={sport} />
        </>
      )}
    </div>
  );
};

export default LiveLine;
