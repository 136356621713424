import { getLengthFromAxis } from './getLengthFromAxis';
import { getMainAxisFromPlacement } from './getMainAxisFromPlacement';
import { getOppositePlacement } from './getOppositePlacement';
import { getAlignment } from './getAlignment';

export const getAlignmentSides = (placement, rects, rtl = false) => {
  const alignment = getAlignment(placement);
  const mainAxis = getMainAxisFromPlacement(placement);
  const length = getLengthFromAxis(mainAxis);
  let mainAlignmentSide =
    // eslint-disable-next-line no-nested-ternary
    mainAxis === 'x'
      ? alignment === (rtl ? 'end' : 'start')
        ? 'right'
        : 'left'
      : alignment === 'start'
      ? 'bottom'
      : 'top';
  if (rects.reference[length] > rects.floating[length]) {
    mainAlignmentSide = getOppositePlacement(mainAlignmentSide);
  }
  return {
    main: mainAlignmentSide,
    cross: getOppositePlacement(mainAlignmentSide)
  };
};
