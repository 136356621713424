import { useContext } from 'react';
import { ThemesContext } from '../../store/context';

const useGetThemeIcons = () => {
  const state = useContext(ThemesContext);
  const [{ iconsSet = {} }] = state || [{}];

  return iconsSet;
};

export default useGetThemeIcons;
