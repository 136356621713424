import {
  InputField,
  FORM_VALIDATION_TYPES,
  formBuilderValidationMap,
  KeyboardDatePickerField
} from '@namespace/forms';
import { createValidationRules } from '@alf/validators';
import { CARD_INFO } from '../../../constants';
import styles from './index.module.css';

const CardFrontEdit = ({ minDate, maxDate, onBlur, customFieldName }) => {
  return (
    <>
      <InputField
        className={styles.input}
        mask="9999 9999 9999 9999"
        maskChar=""
        placeholder="panKeeper.card.placeholder.cardNumber"
        size="xs"
        withTooltip={true}
        rules={createValidationRules(
          [
            {
              type: FORM_VALIDATION_TYPES.CREDIT_CARD_NUMBER_VALIDATOR,
              parameters: ['panKeeper.card.validator.cardNumber']
            },
            FORM_VALIDATION_TYPES.REQUIRED
          ],
          formBuilderValidationMap
        )}
        value=""
        name={CARD_INFO.CARD_NUMBER}
      />
      <KeyboardDatePickerField
        label="panKeeper.card.label.validUntil"
        onBlur={onBlur}
        customFieldName={customFieldName}
        labelSize="s"
        minDate={minDate}
        maxDate={maxDate}
        dateFormat="MM/yy"
        dateValueFormat="MM/yyyy"
        size="xs"
        placeholder="MM / YY"
        classNames={{
          datePickerWrapperClassName: styles.inputWrapperDate,
          labelClassName: styles.label,
          datePickerContainerClassName: styles.datePickerContainer,
          tooltipClassName: styles.tooltipClassName
        }}
        withTooltip={true}
        name={CARD_INFO.EXPIRY_DATE}
        rules={createValidationRules(
          [FORM_VALIDATION_TYPES.REQUIRED],
          formBuilderValidationMap
        )}
      />
      <InputField
        classNames={{
          labelClassName: styles.label,
          wrapperClassName: styles.inputWrapperCardholder
        }}
        label="panKeeper.card.label.cardholder"
        placeholder="panKeeper.card.placeholder.cardholder"
        value=""
        withTooltip={true}
        size="xs"
        name={CARD_INFO.CARD_HOLDER}
        rules={createValidationRules(
          [
            {
              type: FORM_VALIDATION_TYPES.REGEXP_VALIDATOR,
              parameters: [
                /^[a-zA-Z\s'`\-]+$/,
                'panKeeper.card.regexp.validator.cardholder'
              ]
            },
            FORM_VALIDATION_TYPES.REQUIRED,
            {
              type: FORM_VALIDATION_TYPES.MAX_LENGTH_VALIDATOR,
              parameters: [32]
            }
          ],
          formBuilderValidationMap
        )}
      />
    </>
  );
};

export default CardFrontEdit;
