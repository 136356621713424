import { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  SiteSettingsContext,
  useCookieManagementEnabled
} from '@namespace/cms';
import {
  getAvailableLanguageFromPath,
  getLanguageFromPath,
  addLang,
  removeLang,
  useI18n
} from '@namespace/i18n';
import { COOKIES_KEYS, IS_PRERENDER, getCookie } from '@namespace/helpers';

const useLanguage = (isSiteSettingsReady) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [settings] = useContext(SiteSettingsContext);
  const { siteConfig = {}, otherSettings = {} } = settings || {};
  const {
    isReplaceRuBrowserLanguage = false,
    hiddenLanguageInUrl
  } = otherSettings;
  const {
    defaultLanguage,
    languages = [],
    useBrowserLanguage = true,
    prerenderLang
  } = siteConfig;
  const { browserLanguage, setLanguage } = useI18n();
  const [isReady, setIsReady] = useState(false);
  const cookieManagementEnabled = useCookieManagementEnabled();

  useEffect(() => {
    (async () => {
      if (isSiteSettingsReady && !isReady) {
        const langInCookie = getCookie(COOKIES_KEYS.LANGUAGE);
        const availableBrowserLanguage = languages.find((lang) =>
          browserLanguage.includes(lang)
        );
        const finalBrowserLanguage =
          isReplaceRuBrowserLanguage && availableBrowserLanguage === 'ru'
            ? 'uk'
            : availableBrowserLanguage;
        const languageFromPath = getAvailableLanguageFromPath(
          location.pathname,
          languages
        );
        const detectedLanguage = useBrowserLanguage
          ? finalBrowserLanguage || defaultLanguage
          : defaultLanguage;
        const language = (IS_PRERENDER && prerenderLang) || detectedLanguage;
        const lang = languageFromPath || langInCookie || language;

        await setLanguage({
          lang,
          cookieManagementEnabled
        });
        setIsReady(true);
        const isSameAsPrerenderLang =
          location.pathname === '/' && prerenderLang && prerenderLang === lang;

        if (
          (languageFromPath && languageFromPath === hiddenLanguageInUrl) ||
          (lang === hiddenLanguageInUrl &&
            getLanguageFromPath(location.pathname))
        ) {
          navigate(removeLang(location), { replace: true });
        } else if (
          !isSameAsPrerenderLang &&
          languageFromPath !== lang &&
          ((hiddenLanguageInUrl &&
            lang !== hiddenLanguageInUrl &&
            langInCookie !== hiddenLanguageInUrl) ||
            !hiddenLanguageInUrl)
        ) {
          navigate(addLang(location)(lang), { replace: true });
        }
      }
    })();
  }, [isSiteSettingsReady, isReady]);
};

export default useLanguage;
