import { types } from '../types';

export const initialState = {
  resultData: null
};

export const reducers = {
  [types.SET_RESULT]: (state, { resultData }) => ({
    ...state,
    resultData
  })
};
