import { useInterceptorAction } from '../../hooks';
import styles from './index.module.css';

const Hole = ({ hole: { x, y, w, h }, interceptorAction }) => {
  const style = {
    left: `${x}px`,
    top: `${y}px`,
    width: `${w}px`,
    height: `${h}px`
  };

  return (
    <div className={styles.hole} style={style} onClick={interceptorAction} />
  );
};

export const UserActionInterceptorMobile = ({ holes }) => {
  const interceptorAction = useInterceptorAction();

  if (holes.length === 0) {
    return null;
  }

  return holes.map((hole, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Hole key={index} hole={hole} interceptorAction={interceptorAction} />
  ));
};
