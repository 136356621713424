import { PAYMENT_SYSTEMS } from '@namespace/payments/src/constants/paymentSystems';
import { CRYPTO_CURRENCIES } from '@namespace/payments/src/constants/cryptoCurrencies';

export const icons = {
  [PAYMENT_SYSTEMS.FP]: 'payments/icons/fp',
  [PAYMENT_SYSTEMS.SKRILL]: 'payments/icons/skrill',
  [`${PAYMENT_SYSTEMS.SKRILL}.BTC`]: 'payments/icons/btc',
  [`${PAYMENT_SYSTEMS.SKRILL}.AOB`]: 'payments/icons/astropay',
  [`${PAYMENT_SYSTEMS.SKRILL}.1tap`]: 'payments/icons/skrill1Tap',
  [`${PAYMENT_SYSTEMS.SKRILL}.PSC`]: 'payments/icons/paysafecard',
  [PAYMENT_SYSTEMS.SKRILL_NEW]: 'payments/icons/skrill',
  [`${PAYMENT_SYSTEMS.SKRILL_NEW}.BTC`]: 'payments/icons/btc',
  [`${PAYMENT_SYSTEMS.SKRILL_NEW}.AOB`]: 'payments/icons/astropay',
  [`${PAYMENT_SYSTEMS.SKRILL_NEW}.1tap`]: 'payments/icons/skrill1Tap',
  [`${PAYMENT_SYSTEMS.SKRILL_NEW}.PSC`]: 'payments/icons/paysafecard',
  [PAYMENT_SYSTEMS.CASHDESK]: 'payments/icons/terminal',
  [PAYMENT_SYSTEMS.W1]: 'payments/icons/wallet_one',
  [PAYMENT_SYSTEMS.VISA_MASTER_W1]: 'payments/icons/w1_visa.png',
  [PAYMENT_SYSTEMS.NETELLER]: 'payments/icons/neteller',
  [`${PAYMENT_SYSTEMS.NETELLER}.go`]: 'payments/icons/netellergo',
  [PAYMENT_SYSTEMS.EPAY]: 'payments/icons/visa_mastercard',
  [PAYMENT_SYSTEMS.EPAY_NEW]: 'payments/icons/visa_mastercard',
  [PAYMENT_SYSTEMS.PAY_CEK]: 'payments/PayCek',
  [PAYMENT_SYSTEMS.POSLOVNICA]: 'payments/Poslovnica',
  [PAYMENT_SYSTEMS.YANDEX_MONEY]: 'payments/icons/ya-money',
  [PAYMENT_SYSTEMS.ECOPAYZ]: 'payments/icons/ecopayz',
  313: 'payments/icons/idram',
  [PAYMENT_SYSTEMS.SAFECHARGE_OLD]: 'payments/icons/visa_mastercard', // Safecharge old
  [PAYMENT_SYSTEMS.SAFECHARGE_NEW]: 'payments/icons/visa_mastercard', // Safecharge new
  [PAYMENT_SYSTEMS.ADVCASH]: 'payments/icons/advcash',
  322: 'payments/icons/QiwiWallet',
  [PAYMENT_SYSTEMS.BPC]: 'payments/icons/visa_mastercard',
  [PAYMENT_SYSTEMS.BPC_NEW]: 'payments/icons/visa_mastercard',
  501: 'payOut',
  [PAYMENT_SYSTEMS.BPAY]: 'payments/icons/bpay',
  [PAYMENT_SYSTEMS.MUCH_BETTER]: 'payments/icons/muchBetter',
  [PAYMENT_SYSTEMS.RUNPAY]: 'payments/icons/runpay',
  [PAYMENT_SYSTEMS.HEXOPAY]: 'payments/icons/visa_mastercard',
  [PAYMENT_SYSTEMS.PRAXIS]: 'payments/icons/depositWithdraw',
  [PAYMENT_SYSTEMS.INTER_KASSA]: 'payments/icons/singleWallet',
  [PAYMENT_SYSTEMS.UPI]: 'payments/icons/upi',
  [PAYMENT_SYSTEMS.PAY_TM]: 'payments/icons/payTm',
  [PAYMENT_SYSTEMS.NET_BANKING]: 'payments/icons/netBanking',
  [PAYMENT_SYSTEMS.RAPID_TRANSFER]: 'payments/icons/rapidTransfer',
  [PAYMENT_SYSTEMS.ARTPAY]: 'payments/icons/visa_mastercard',
  [PAYMENT_SYSTEMS.DCC]: 'payments/icons/visa_mastercard',
  10096: 'payments/icons/aircash',
  824: 'payments/icons/aircash',
  821: 'payments/icons/skrill',
  10097: 'payments/icons/abon',
  825: 'payments/icons/abon',
  10000: 'payments/icons/corvus',
  10200: 'payments/icons/corvus',
  10213: 'payments/icons/neteller',
  221: 'payments/icons/deposit',
  208: 'payments/icons/deposit',
  608: 'payments/icons/Bitcoin',
  deposit: 'payments/icons/deposit',
  [PAYMENT_SYSTEMS.COINSPAID]: 'payments/icons/coinspaid',
  [CRYPTO_CURRENCIES.RIPPLE]: 'payments/icons/xrp',
  [CRYPTO_CURRENCIES.TETHER_ERC]: 'payments/icons/usdt',
  [CRYPTO_CURRENCIES.TETHER_TRX]: 'payments/icons/usdt',
  [CRYPTO_CURRENCIES.ETHERIUM]: 'payments/icons/eth',
  [CRYPTO_CURRENCIES.BITCOIN]: 'payments/icons/btc_logo',
  [CRYPTO_CURRENCIES.TRON]: 'payments/icons/tron',
  [CRYPTO_CURRENCIES.LITECOIN]: 'payments/icons/ltc',
  [CRYPTO_CURRENCIES.BITCOIN_CASH]: 'payments/icons/bch',
  [CRYPTO_CURRENCIES.BINANCECOIN]: 'payments/icons/bnb',
  [CRYPTO_CURRENCIES.CARDANO]: 'payments/icons/ada',
  [CRYPTO_CURRENCIES.DOGECOIN]: 'payments/icons/doge',
  [CRYPTO_CURRENCIES.USDCOIN]: 'payments/icons/usdc',
  [CRYPTO_CURRENCIES.BINANCEUSD]: 'payments/icons/busd'
};
