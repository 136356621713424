import { AText } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import clsx from 'clsx';
import styles from './index.module.css';

const ProfileDataInfo = ({ classNames = {} }) => {
  const { t } = useI18n();
  const { firstInfo = '', secondInfo = '' } = classNames;
  return (
    <>
      <AText
        className={clsx(styles.firstInfo, firstInfo)}
        color="po_txt_color_3"
        size="s"
      >
        {t('personalOffice.profile.data.info1')}
      </AText>
      <AText
        className={clsx(styles.secondInfo, secondInfo)}
        color="po_txt_color_3"
        size="s"
      >
        {t('personalOffice.profile.data.info2')}
      </AText>
    </>
  );
};

export default ProfileDataInfo;
