import { memo, useEffect, useState, useMemo } from 'react';
import { path, pathOr, pipe, propEq, find } from 'ramda';
import clsx from 'clsx';
import { Box, EmptyState } from '@alf/uikit';
import { useGeneralRoutes } from '@namespace/cms';
import { scrollTo, document } from '@namespace/helpers';
import appInIframe from '@namespace/helpers/src/utils/appInIframe';
import { LocalizedLink, useI18n, useLocation } from '@namespace/i18n';
import { Image } from '@namespace/icons2';
import { IS_WIDGET } from '@namespace/widget';
import {
  useSportBySportSlug,
  useSportsbookSelector,
  useSportsbookStore
} from '../../store';
import { WidgetWrapper } from '../../common';
import BreadCrumbs from '../../common/BreadCrumbs';
import LiveLine from '../../common/LiveLine';
import SportbookSkelet from '../../common/SportbookSkelet';
import { MODE } from '../../constants';
import { selectHasEvents } from './selectors';
import styles from './index.module.css';

const InnerLiveAllEvent = memo(
  ({ hashSportSlug, id, isLiveEvents, isEventsLoaded }) => {
    const sportByService = useSportsbookStore(
      pathOr([], ['sports', MODE.IN_PLAY])
    );
    const defaultExpandedLine = useMemo(() => {
      const expandedSport = find(
        propEq('sportSlug', hashSportSlug),
        sportByService
      );

      return (expandedSport ?? sportByService[0])?.sportId;
    }, [hashSportSlug, sportByService]);
    const [expandedLine, setExpandedLine] = useState();

    useEffect(() => {
      if (sportByService?.length > 0 && defaultExpandedLine != null) {
        setExpandedLine(defaultExpandedLine);
      }
    }, [defaultExpandedLine, sportByService?.length]);

    return (
      <WidgetWrapper>
        {sportByService.map((sport) => (
          <LiveLine
            id={id}
            sport={sport || {}}
            key={`liveline-${sport.sportId}`}
            isLiveEvents={isLiveEvents}
            isReady={isEventsLoaded}
            isExpanded={expandedLine === sport.sportId}
            setIsExpanded={setExpandedLine}
          />
        ))}
      </WidgetWrapper>
    );
  }
);

const LiveAllEvents = ({ id }) => {
  const isEventsLoaded = useSportsbookStore('isEventsLoaded');
  const hasSports = useSportsbookStore(
    pipe(path(['sports', MODE.IN_PLAY, 'length']), (length) => length > 0)
  );
  const { t } = useI18n();
  const { hash } = useLocation();
  const { liveSportAll } = useGeneralRoutes();
  const currentSportSlug = hash.replace('#', '');
  const { sportId } = useSportBySportSlug(currentSportSlug, MODE.IN_PLAY) || {};
  const hasEvents = useSportsbookSelector(selectHasEvents, [sportId]);
  const inIframe = appInIframe() && IS_WIDGET;

  useEffect(() => {
    if (hasEvents && hasSports && currentSportSlug) {
      let sportSection;
      try {
        sportSection = document.querySelector(
          `[data-role="${currentSportSlug}"]`
        );
      } catch {
        sportSection = '';
      }

      scrollTo({ left: 0, top: sportSection ? sportSection.offsetTop : 0 });
    }
  }, [currentSportSlug, hasEvents, hasSports, liveSportAll]);

  if (!isEventsLoaded) {
    return <SportbookSkelet />;
  }

  return (
    <div className={clsx([!inIframe && styles.container])}>
      <InnerLiveAllEvent
        id={id}
        hashSportSlug={currentSportSlug}
        isLiveEvents={true}
        isEventsLoaded={isEventsLoaded}
      />
      <BreadCrumbs type="inprogress" />
      {!hasSports && (
        <Box direction="column" className={styles.emptyStateWrapper}>
          <EmptyState
            image={<Image name="general/looking_for" />}
            Link={LocalizedLink}
            text={t('sportsbook.emptyState.noEvents')}
          />
        </Box>
      )}
    </div>
  );
};

export default LiveAllEvents;
