import { isFunction } from 'lodash';

export const formatErrorMessage = (errorCode) =>
  `accountingMessages.${
    typeof errorCode === 'object' ? errorCode.code : errorCode
  }`;

export const formatSuccessMessage = (data, successMessage) =>
  isFunction(successMessage) ? successMessage(data) : successMessage;

export const getFiltersConfig = ({
  t,
  paySystemsOptions,
  currencyOptions,
  prefix = ''
}) => [
  {
    label: '',
    key: 'paymentSystem',
    options: paySystemsOptions,
    defaultValue: {
      value: 'all',
      name: t(`${prefix}allWallets`)
    }
  },
  {
    label: '',
    key: 'currency',
    options: currencyOptions,
    defaultValue: {
      value: 'all',
      name: t(`${prefix}allCurrencies`)
    }
  }
];
