import { isString } from 'lodash';

const replacePlaceholders = (string = '', replacers = []) =>
  string.replace(/{(\d+)}/g, (_, i) => replacers[i] ?? '');

// t(‘key’, [replacers], ‘defaultValue’)
// t(‘key’, [replacers])
// t(‘key’, 'defaultValue')
const translate = (prefix, translations, showKeysForContentManager) => (
  tKey,
  value,
  defaultTranslation
) => {
  const nextKey = `${prefix}${tKey}`.toLowerCase();
  const isArrayValue = Array.isArray(value);

  if (showKeysForContentManager) {
    const keysCustomValue = isArrayValue
      ? value.map((_, id) => ` {${id}}`)
      : '';
    return nextKey + keysCustomValue;
  }

  let replacers;
  let backupTranslation = defaultTranslation;

  if (isArrayValue) {
    replacers = value;
  } else if (isString(value)) {
    backupTranslation = value;
  }

  const translation = translations[nextKey];

  if (!translation) {
    return backupTranslation || tKey;
  }

  return replacePlaceholders(translation, replacers);
};

export default translate;
