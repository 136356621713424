import { useContext } from 'react';
import {
  getLocalStorageValue,
  COOKIES_KEYS,
  LOCAL_STORAGE_KEYS,
  getCountryIdFromCookie
} from '@namespace/helpers';
import { SiteSettingsContext } from '@namespace/cms/src/store/siteSettings/context';
import { UserContext } from '../../context';

const { USER_SEGMENTS_FILTER } = LOCAL_STORAGE_KEYS;

const useGetCountryId = () => {
  const cookieCountryId = getCountryIdFromCookie();
  const { countries: storageCountryId } = getLocalStorageValue(
    USER_SEGMENTS_FILTER,
    {}
  );
  const [userState] = useContext(UserContext);
  const { countryId } = userState;

  const [settings] = useContext(SiteSettingsContext);
  const { siteConfig = {}, ssr } = settings || {};
  const { defaultCountry = 'UA' } = siteConfig;
  const ssrCountryId = ssr?.cookies[COOKIES_KEYS.COUNTRY_ID];

  return (
    countryId ||
    storageCountryId ||
    cookieCountryId ||
    ssrCountryId ||
    defaultCountry
  );
};

export default useGetCountryId;
