import { useContext } from 'react';
import { AText, Box } from '@alf/uikit';
import { DeviceContext } from '@namespace/device';
import { useI18n } from '@namespace/i18n';
import DateTime from '../../../DateTime';
import Tags from '../../../Tags';
import { PROMOTION_ITEM_TYPE_TO_PROMO_TYPE } from '../../../../constants';
import styles from './index.module.css';

export const PromoItem = ({ promo, ...rest }) => {
  const { isMobile } = useContext(DeviceContext);
  const { t } = useI18n();
  const { isSimilarPromo, promoTags } = rest;

  const {
    promoId,
    titleTranslationKey,
    tags,
    showTimerInsteadPeriod,
    showFrom,
    type
  } = promo;

  return (
    <>
      <Tags tags={tags} promoTagsConfig={promoTags} showFrom={showFrom} />
      <Box padding="0 s" justify="center" className={styles.promoTitle}>
        <AText
          breed="BU"
          size="l"
          color="txt_color_3"
          data-role="promotions-item-title"
          align="center"
          overflow={isMobile && !isSimilarPromo ? 'hidden' : 'ellipsis'}
        >
          {t(`${titleTranslationKey}`, promoId)}
        </AText>
      </Box>
      <DateTime
        showTimerInsteadPeriod={showTimerInsteadPeriod}
        promoData={promo}
        isPromoItem={true}
        dateTextColor="txt_color_2"
        dateTextSize="2xl"
        activePromoType={PROMOTION_ITEM_TYPE_TO_PROMO_TYPE[type]}
      />
    </>
  );
};
