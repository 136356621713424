import EventMarkets from '../EventMarkets';
import EmptyMarkets from '../EmptyMarkets';
import { useLine, useEventFields } from '../../../store';
import styles from './index.module.css';

const Event = ({ eventId, lineKey, sportSlug }) => {
  const { eventsMarketsIds } = useLine(lineKey) || {};

  const eventMarketsIds = eventsMarketsIds[eventId];

  const { finished, canceled, inprogress, serviceId } = useEventFields({
    eventId,
    fields: ['finished', 'canceled', 'inprogress', 'serviceId']
  });

  const isEmptyState =
    !eventMarketsIds?.length || finished || canceled || inprogress;

  return (
    <div
      className={`${styles.container} ${isEmptyState ? styles.emptyState : ''}`}
      data-role="event-content-container"
    >
      {isEmptyState ? (
        <EmptyMarkets
          finished={finished}
          canceled={canceled}
          inprogress={inprogress}
          isEventsMarketsExist={Boolean(eventsMarketsIds.length)}
          sportSlug={sportSlug}
          eventId={eventId}
        />
      ) : (
        <EventMarkets
          eventId={eventId}
          serviceId={serviceId}
          eventMarketsIds={eventMarketsIds}
        />
      )}
    </div>
  );
};

export default Event;
