import { useI18n } from '@namespace/i18n';
import { ModalDialog, Button } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { window } from '@namespace/helpers';
import SnippetContent from '../common/SnippetContent';
import styles from './index.module.css';

const PrivacyPolicyBanner = ({
  onAccept,
  onClose,
  currentPolicyContentId,
  policyBannerTitle
}) => {
  const { t } = useI18n();
  const handleSupport = () => {
    window.ShowSupport();
  };

  return (
    <ModalDialog
      acceptTitle={t('notifications.policychange.accept')}
      onCancel={onClose}
      onAccept={onAccept}
      intentAccept="primary"
      actionTypeAccept="color1"
      intentCancel="secondary"
      actionTypeCancel="color1"
      title={t('notifications.policychange.title')}
      classNames={{
        contentClass: styles.contentClassOverlay,
        wrapperClass: styles.wrapperClassOverlay
      }}
      customButton={
        <Button
          actionType="color3"
          intent="quaternary"
          onClick={handleSupport}
          iconLeft={
            <Icon
              name="icons/user_menu/support"
              color="btn_quaternary_color_3"
              className={styles.submitIcon}
            />
          }
        >
          {t('notifications.policychange.contactsupport')}
        </Button>
      }
    >
      <SnippetContent
        privacyPolicyBannerTitle={policyBannerTitle}
        privacyPolicyId={currentPolicyContentId}
      />
    </ModalDialog>
  );
};

export default PrivacyPolicyBanner;
