import {
  addIndex,
  ascend,
  assoc,
  curryN,
  identity,
  isNil,
  join,
  lens,
  lensProp,
  map,
  over,
  path,
  pipe,
  reject,
  sortWith
} from 'ramda';

const indexedMap = addIndex(map);

// ukrainian letters aren't sorted correctly without this
const localeAscend = curryN(3, (fn, a, b) => {
  const aa = fn(a);
  const bb = fn(b);

  if (typeof aa === 'string' && typeof bb === 'string') {
    return aa.localeCompare(bb);
  }

  return ascend(identity, aa, bb);
});

const txtLens = lens(identity, assoc('txt'));

const createChipText = (sportsData) => ({
  sportId,
  categoryId,
  tournamentId,
  name = []
}) =>
  pipe(
    reject(isNil),
    indexedMap((id, idx) => name[idx] ?? sportsData[idx][id]?.name ?? id)
  )([sportId, categoryId, tournamentId]);

export const formatFilterItems = (sportsData) => (filterItems = []) =>
  pipe(
    map(over(txtLens, createChipText(sportsData))),
    sortWith([
      localeAscend(path(['txt', 0])),
      localeAscend(path(['txt', 1])),
      localeAscend(path(['txt', 2]))
    ]),
    map(over(lensProp('txt'), join(' | ')))
  )(filterItems);
