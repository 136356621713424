import { useMemo } from 'react';
import {
  EventsLineHeader,
  EventsLineHeadGroups,
  LineSport
} from '../../common';
import { useActiveHeadGroups } from '../../hooks/useActiveHeadGroups';
import { useEvents } from '../../store';
import { groupByTournament } from '../../utils';
import { MODE } from '../../constants';
import EventsLineHeadGroupsFilterDropdown from '../../common/EventsLineHeadGroups/FilterDropdown';
import styles from './index.module.css';

const CyberSportLine = ({
  id,
  lineKey,
  isHeadGroupsSortingEnabled,
  activeHeadGroupsAmount,
  sport,
  tournament,
  category,
  isShowEmpty,
  serviceId,
  filter,
  isShowFlag,
  filterConfig,
  setFilter,
  isShowAll,
  isShowStartingSoon,
  defaultFilter,
  isTodayFilterApplied,
  onDefaultFilterChange
}) => {
  const { sportId } = sport || {};
  const { categoryId } = category || {};
  const { tournamentId } = tournament || {};
  const [activeHeadGroups, setActiveHeadGroups] = useActiveHeadGroups(
    isHeadGroupsSortingEnabled,
    activeHeadGroupsAmount,
    sportId,
    serviceId
  );
  const events = useEvents({ lineKey });
  const tournamentsIds = useMemo(() => (tournamentId ? [tournamentId] : []), [
    tournamentId
  ]);
  const eventsByTournaments = useMemo(() => groupByTournament(events), [
    events
  ]);
  // TODO: SPORTSBOOK NEED GET EVENTS TO SHOW from useEventsByIdsToShow()
  const eventsToShow = useMemo(
    () =>
      tournamentsIds.length && events.length
        ? tournamentsIds.map((tournamentsId) =>
            eventsByTournaments.get(tournamentsId)
          )
        : Array.from(eventsByTournaments.values()),
    [events.length, eventsByTournaments, tournamentsIds]
  );

  const isEventsToShowExist =
    Array.isArray(eventsToShow) &&
    eventsToShow.some((event) => Array.isArray(event));

  return (
    <>
      {((isShowEmpty && !isEventsToShowExist) || isEventsToShowExist) && (
        <EventsLineHeader borderBottom={true} isCollapsible={false}>
          {serviceId !== MODE.IN_PLAY && (
            <EventsLineHeadGroupsFilterDropdown
              filterConfig={filterConfig}
              filterValue={filter}
              isShowAll={isShowAll}
              isShowStartingSoon={isShowStartingSoon}
              onSetFilter={setFilter}
              additionSelectContentStyle={styles.listMaxHeight}
            />
          )}
          <EventsLineHeadGroups
            serviceId={serviceId}
            sport={{ sportId }}
            isHeadGroupsSortingEnabled={true}
            activeHeadGroups={activeHeadGroups}
            setActiveHeadGroups={setActiveHeadGroups}
          />
        </EventsLineHeader>
      )}
      <LineSport
        key={`line-${serviceId}`}
        id={id}
        tournamentsIds={tournamentsIds}
        activeHeadGroups={activeHeadGroups}
        sport={sport}
        categoryIds={categoryId ? [categoryId] : []}
        lineKey={lineKey}
        serviceId={serviceId}
        isShowFlag={isShowFlag}
        isExpanded={false}
        isShowEmpty={isShowEmpty}
        filter={filter}
        defaultFilter={defaultFilter}
        isTodayFilterApplied={isTodayFilterApplied}
        onDefaultFilterChange={onDefaultFilterChange}
      />
    </>
  );
};

export default CyberSportLine;
