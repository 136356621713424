import { useCallback, useContext, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import usePostMessage from '../usePostMessage';
import { POST_MESSAGE_TYPES, IS_WIDGET } from '../../constants';
import { WidgetLayoutContext } from '../../store/widgetLayout/context';
import { WidgetContext } from '../../store/widget/context';

const { CONTENT_HEIGHT } = POST_MESSAGE_TYPES;

const useWidgetResizeObserver = (element) => {
  const { postMessage } = usePostMessage();
  const [{ widgetInnerHeight }] = useContext(WidgetContext);
  const [, { SET_CONTENT_HEIGHT }] = useContext(WidgetLayoutContext);
  const resizeObserver = new ResizeObserver((entries) => {
    const rect = entries[0].contentRect;
    const height =
      rect.height < widgetInnerHeight ? widgetInnerHeight : rect.height;
    SET_CONTENT_HEIGHT(height);
    postMessage(CONTENT_HEIGHT, height);
  });

  const startObserving = useCallback((el) => {
    resizeObserver.observe(el);
  }, []);

  useEffect(() => {
    if (IS_WIDGET) {
      startObserving(element);
      return () => {
        resizeObserver.unobserve(element);
      };
    }

    return () => {};
  }, [element]);
};

export default useWidgetResizeObserver;
