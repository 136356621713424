import { useReducer } from 'react';
import { baseReducer, useActions } from '@namespace/helpers-store';
import { DateTime } from 'luxon';
import { window } from '@namespace/helpers';
import { I18nContext } from './context';
import { DATE_FORMATS_TYPES } from './constants';
import { i18nReducers, i18nTypes } from './reducer';
import actionCreators from './actions';
import {
  getBrowserLanguage,
  getLocale,
  checkAndSaveTranslationKeysVisible,
  convertTranslationsToLowerCase
} from './utils';
import {
  getStoredTranslations,
  getStoredTranslationsKeysVisible
} from './utils/storedLanguageTranslation';

export const I18nProvider = ({
  children,
  DATE_FORMATS = {},
  CURRENCY_FORMATS,
  translations = {}
}) => {
  const locale = getLocale();
  const browserLanguage = getBrowserLanguage();
  checkAndSaveTranslationKeysVisible();
  const showKeysForContentManager = getStoredTranslationsKeysVisible();
  const [lang = 'en', loadedTranslations = {}] =
    Object.entries(translations)[0] || [];

  const defaultFallbackLangEn = window.defaultTranslation || {};
  const stored = getStoredTranslations(lang) || {};
  const fallback = { ...defaultFallbackLangEn, ...stored };

  const translationsMap = {
    [lang]: convertTranslationsToLowerCase({
      ...fallback,
      ...loadedTranslations
    })
  };

  const [i18n, i18nDispatch] = useReducer(baseReducer(i18nReducers), {
    language: lang,
    timezone: DateTime.local().zoneName,
    browserLanguage,
    translationsMap,
    dateFormats: { ...DATE_FORMATS_TYPES, ...DATE_FORMATS },
    locale,
    currencyFormats: CURRENCY_FORMATS,
    showKeysForContentManager,
    timeZones: []
  });

  const i18nActions = useActions(i18nTypes, i18nDispatch, actionCreators);

  return (
    <I18nContext.Provider value={[i18n, i18nActions]}>
      {children}
    </I18nContext.Provider>
  );
};
