import { memo, useCallback, useContext } from 'react';
import { pathOr } from 'ramda';
import PropTypes from 'prop-types';
import { Icon } from '@namespace/icons2';
import { Box } from '@alf/uikit';
import { USER_ROLES, UserContext } from '@namespace/user';
import {
  sportsbookActions,
  useSportsbookStore,
  useEventFields
} from '../../store';

const {
  SAVE_FAVORITE_EVENT,
  REMOVE_FAVORITE_EVENT,
  FETCH_FAVORITES
} = sportsbookActions;

const EventFavoriteStar = ({
  eventId,
  size = 's',
  className = '',
  isClickable = true,
  isHideUnselected = false,
  color = 'line_color_3',
  activeColor = 'state_favorite'
}) => {
  const favoriteEvents = useSportsbookStore(
    pathOr([], ['favorites', 'events'])
  );
  const { serviceId, isCachedEvent } = useEventFields({
    eventId,
    fields: ['serviceId', 'isCachedEvent']
  });

  const [user] = useContext(UserContext);
  const { role } = user;

  const save = useCallback(() => {
    SAVE_FAVORITE_EVENT({ eventId, serviceId }).then(({ ok }) => {
      if (ok) {
        FETCH_FAVORITES();
      }
    });
  }, [eventId, serviceId]);

  const remove = useCallback(() => {
    REMOVE_FAVORITE_EVENT({ eventId, serviceId }).then(({ ok }) => {
      if (ok) {
        FETCH_FAVORITES();
      }
    });
  }, [eventId, serviceId]);

  const isFavorite = favoriteEvents.includes(eventId);

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      return isFavorite ? remove() : save();
    },
    [save, remove, isFavorite]
  );

  if (isHideUnselected && !isFavorite) {
    return null;
  }

  return (
    role === USER_ROLES.USER &&
    !isCachedEvent && (
      <Box
        data-role="event-favorite-star"
        className={className}
        align="center"
        onClick={isClickable ? handleClick : null}
      >
        <Icon
          data-role="event-favorite-star-icon"
          size={size}
          name={`icons/general/state/favorite-${isFavorite ? 'on' : 'off'}`}
          color={isFavorite ? activeColor : color}
        />
      </Box>
    )
  );
};

EventFavoriteStar.propTypes = {
  eventId: PropTypes.number.isRequired,
  className: PropTypes.string,
  size: PropTypes.string
};

export default memo(EventFavoriteStar);
