import { Box } from '@alf/uikit';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { useGeneralRoutes } from '@namespace/cms';

import VerificationStatusNotification, {
  MobileMessage
} from '../VerificationStatusNotification';

import styles from './index.module.css';

const AdditionalInformationUploadToast = ({ onClose }) => {
  const { t } = useI18n();
  const { profileDocuments } = useGeneralRoutes();

  const primaryText = t('additionalInformationUpload.notification.info1');
  const tailText = t('additionalInformationUpload.notification.info2');
  const linkText = t('additionalInformationUpload.notification.link');
  const message = (
    <Box direction="column" align="center" justify="center">
      <span>
        {primaryText}

        <LocalizedLink className={styles.link} to={profileDocuments}>
          {linkText}
        </LocalizedLink>

        {tailText}
      </span>
    </Box>
  );

  const mobileMessage = (
    <MobileMessage
      submitText={t('additionalInformationUpload.notification.button')}
      link={profileDocuments}
      primaryText={primaryText}
      tailText={tailText}
      linkText={linkText}
    />
  );

  return (
    <VerificationStatusNotification
      onClose={onClose}
      message={message}
      mobileMessage={mobileMessage}
    />
  );
};

export default AdditionalInformationUploadToast;
