import { useEffect } from 'react';
import { sportsbookActions } from '../../store';

const { REMOVE_LINE } = sportsbookActions;

// removes line from sportsbook state.lines on line component
// unmount to unsubscribe from rendered events
export const useRemoveLine = (lineKey) => {
  useEffect(
    () => () => {
      REMOVE_LINE({ lineKey });
    },
    [lineKey]
  );
};
