import bronzeLevelImage from './images/levels/bronze.png';
import silverLevelImage from './images/levels/silver.png';
import goldLevelImage from './images/levels/gold.png';
import platinumLevelImage from './images/levels/platinum.png';

import minorLevelImage from './images/levels/minor.png';
import majorLevelImage from './images/levels/major.png';
import megaLevelImage from './images/levels/mega.png';

import clubsLevelImage from './images/levels/clubs.png';
import diamondsLevelImage from './images/levels/diamonds.png';
import heartsLevelImage from './images/levels/hearts.png';
import spadesLevelImage from './images/levels/spades.png';

import pateplayPinkLvlImage from './images/levels/pateplay_pink@2x.png';
import pateplayGreenLvlImage from './images/levels/pateplay_green@2x.png';
import pateplayBlueLvlImage from './images/levels/pateplay_blue@2x.png';
import pateplayRedLvlImage from './images/levels/pateplay_red@2x.png';

export const JACKPOT_IDENTIFIERS = {
  EGT: 27,
  EGT_EQ: '27_EQ',
  CT_GAMES: 163,
  PATEPLAY: 10231
};

export const DISPLAY_TYPES = {
  SINGLE: 'singleRow',
  MULTIPLE: 'multipleRows'
};

export const LEVEL_IMAGE_POSITIONS = {
  INLINE: 'inline',
  UNDERLINE: 'underline'
};

export const JACKPOT_TYPES = {
  [JACKPOT_IDENTIFIERS.EGT_EQ]: {
    PLATINUM: 'platinum',
    GOLD: 'gold',
    SILVER: 'silver',
    BRONZE: 'bronze'
  },
  [JACKPOT_IDENTIFIERS.CT_GAMES]: {
    MINOR: 'minor',
    MAJOR: 'major',
    MEGA: 'mega'
  },
  [JACKPOT_IDENTIFIERS.EGT]: {
    CLUBS: 'clubs',
    DIAMONDS: 'diamonds',
    HEARTS: 'hearts',
    SPADES: 'spades'
  },
  [JACKPOT_IDENTIFIERS.PATEPLAY]: {
    RED: 'red',
    BLUE: 'blue',
    GREEN: 'green',
    PINK: 'pink'
  }
};

export const JACKPOT_LEVEL_TO_TYPE = {
  [JACKPOT_IDENTIFIERS.EGT_EQ]: {
    level1: JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT_EQ].BRONZE,
    level2: JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT_EQ].SILVER,
    level3: JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT_EQ].GOLD,
    level4: JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT_EQ].PLATINUM
  },
  [JACKPOT_IDENTIFIERS.CT_GAMES]: {
    level1: JACKPOT_TYPES[JACKPOT_IDENTIFIERS.CT_GAMES].MINOR,
    level2: JACKPOT_TYPES[JACKPOT_IDENTIFIERS.CT_GAMES].MAJOR,
    level3: JACKPOT_TYPES[JACKPOT_IDENTIFIERS.CT_GAMES].MEGA
  },
  [JACKPOT_IDENTIFIERS.EGT]: {
    level1: JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT].CLUBS,
    level2: JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT].DIAMONDS,
    level3: JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT].HEARTS,
    level4: JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT].SPADES
  },
  [JACKPOT_IDENTIFIERS.PATEPLAY]: {
    level1: JACKPOT_TYPES[JACKPOT_IDENTIFIERS.PATEPLAY].PINK,
    level2: JACKPOT_TYPES[JACKPOT_IDENTIFIERS.PATEPLAY].GREEN,
    level3: JACKPOT_TYPES[JACKPOT_IDENTIFIERS.PATEPLAY].BLUE,
    level4: JACKPOT_TYPES[JACKPOT_IDENTIFIERS.PATEPLAY].RED
  }
};

export const JACKPOT_TYPES_WEIGHT = {
  [JACKPOT_IDENTIFIERS.EGT_EQ]: {
    [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT_EQ].BRONZE]: 1,
    [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT_EQ].SILVER]: 2,
    [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT_EQ].GOLD]: 3,
    [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT_EQ].PLATINUM]: 4
  },
  [JACKPOT_IDENTIFIERS.CT_GAMES]: {
    [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.CT_GAMES].MINOR]: 1,
    [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.CT_GAMES].MAJOR]: 2,
    [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.CT_GAMES].MEGA]: 3
  },
  [JACKPOT_IDENTIFIERS.EGT]: {
    [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT].CLUBS]: 1,
    [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT].DIAMONDS]: 2,
    [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT].HEARTS]: 3,
    [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT].SPADES]: 4
  },
  [JACKPOT_IDENTIFIERS.PATEPLAY]: {
    [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.PATEPLAY].PINK]: 1,
    [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.PATEPLAY].GREEN]: 2,
    [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.PATEPLAY].BLUE]: 3,
    [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.PATEPLAY].RED]: 4
  }
};

export const JACKPOT_LEVEL_IMAGE_INFO = {
  [JACKPOT_IDENTIFIERS.EGT_EQ]: {
    position: LEVEL_IMAGE_POSITIONS.UNDERLINE,
    levelsImages: {
      [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT_EQ].BRONZE]: bronzeLevelImage,
      [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT_EQ].SILVER]: silverLevelImage,
      [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT_EQ].GOLD]: goldLevelImage,
      [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT_EQ].PLATINUM]: platinumLevelImage
    }
  },
  [JACKPOT_IDENTIFIERS.CT_GAMES]: {
    position: LEVEL_IMAGE_POSITIONS.UNDERLINE,
    levelsImages: {
      [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.CT_GAMES].MINOR]: minorLevelImage,
      [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.CT_GAMES].MAJOR]: majorLevelImage,
      [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.CT_GAMES].MEGA]: megaLevelImage
    }
  },
  [JACKPOT_IDENTIFIERS.EGT]: {
    position: LEVEL_IMAGE_POSITIONS.INLINE,
    levelsImages: {
      [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT].CLUBS]: clubsLevelImage,
      [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT].DIAMONDS]: diamondsLevelImage,
      [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT].HEARTS]: heartsLevelImage,
      [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.EGT].SPADES]: spadesLevelImage
    }
  },
  [JACKPOT_IDENTIFIERS.PATEPLAY]: {
    position: LEVEL_IMAGE_POSITIONS.INLINE,
    levelsImages: {
      [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.PATEPLAY].PINK]: pateplayPinkLvlImage,
      [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.PATEPLAY]
        .GREEN]: pateplayGreenLvlImage,
      [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.PATEPLAY].BLUE]: pateplayBlueLvlImage,
      [JACKPOT_TYPES[JACKPOT_IDENTIFIERS.PATEPLAY].RED]: pateplayRedLvlImage
    }
  }
};
