export const splitNumber = (x) => {
  const sX = String(x);
  if (sX.length < 5) {
    return sX;
  }
  const splitted = sX.split('').reverse();
  const groupped = [];
  for (let i = 0; i < splitted.length; i += 3) {
    groupped.push(
      `${splitted[i] || ''}${splitted[i + 1] || ''}${splitted[i + 2] || ''}`
        .split('')
        .reverse()
        .join('')
    );
  }
  return groupped.reverse().join(' ');
};
