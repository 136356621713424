import { TWO_GAMES_PER_ROW_BREAKPOINT } from '../constants';

export const getGamesPerRowByScreenSize = (screenSize) => {
  // values from breakpoints in css
  if (screenSize > 1751) return 8;
  if (screenSize <= 1751 && screenSize > 1384) return 7;
  if (screenSize <= 1384 && screenSize > 1125) return 6;
  if (screenSize <= 1125 && screenSize > 898) return 5;
  if (screenSize <= 898 && screenSize > 723) return 4;
  if (screenSize <= 723 && screenSize > 491) return 3;
  if (screenSize <= TWO_GAMES_PER_ROW_BREAKPOINT) return 2;

  return null;
};
