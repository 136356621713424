import { isEmpty } from 'lodash';
// eslint-disable-next-line @alf/loop-deps
import { useLimits } from '@namespace/responsible-gambling'; // hack FIT ME
import { LIMIT_TYPES } from '@namespace/responsible-gambling/src/constants';
import { useOtherSettings } from '@namespace/cms';
import SelfExcludedBlock from './SelfExcludedBlock';

const SelfExcludedWrapper = ({ children }) => {
  const [selfExclusion] = useLimits(LIMIT_TYPES.SELF_EXCLUSION);
  const { isShowSelfExcludedBlock = false } = useOtherSettings();

  if (!isShowSelfExcludedBlock || isEmpty(selfExclusion)) {
    return children;
  }

  return <SelfExcludedBlock selfExclusion={selfExclusion} />;
};

export default SelfExcludedWrapper;
