import { useMemo } from 'react';
import { map, pipe, prop, sum } from 'ramda';
import { useCyberSportCategories } from '../../store';

export const useCyberSportAllEventsCount = () => {
  const categories = useCyberSportCategories();

  return useMemo(
    () =>
      pipe(
        // prettier-ignore
        map(prop('eventCount')),
        sum
      )(categories),
    [categories]
  );
};
