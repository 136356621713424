import { useCallback, useContext } from 'react';
import { Icon } from '@namespace/icons2';
import { Box } from '@alf/uikit';
import { USER_ROLES, UserContext } from '@namespace/user';
import { PINNED_TYPE } from '../../constants';
import { sportsbookActions } from '../../store';
import styles from './index.module.css';

const {
  SAVE_FAVORITE_SPORT,
  SAVE_FAVORITE_CATEGORY,
  REMOVE_FAVORITE_SPORT,
  REMOVE_FAVORITE_CATEGORY,
  FETCH_FAVORITES
} = sportsbookActions;

const PinnedIcon = ({
  isPinned = false,
  isActive = false,
  sportId,
  categoryId,
  size = 's',
  type = 'sport',
  color = 'sm_txt_color_1'
}) => {
  const [user] = useContext(UserContext);
  const { role } = user;

  const save = useCallback(() => {
    const saveActionType = () => {
      switch (type) {
        case PINNED_TYPE.SPORT: {
          return SAVE_FAVORITE_SPORT({ sportId }).then(({ ok }) => {
            if (ok) {
              FETCH_FAVORITES();
            }
          });
        }
        case PINNED_TYPE.CATEGORY: {
          return SAVE_FAVORITE_CATEGORY({ categoryId }).then(({ ok }) => {
            if (ok) {
              FETCH_FAVORITES();
            }
          });
        }
        default:
          return null;
      }
    };
    return saveActionType();
  }, [type, sportId, categoryId]);

  const remove = useCallback(() => {
    const removeActionType = () => {
      switch (type) {
        case PINNED_TYPE.SPORT: {
          return REMOVE_FAVORITE_SPORT({ sportId }).then(({ ok }) => {
            if (ok) {
              FETCH_FAVORITES();
            }
          });
        }
        case PINNED_TYPE.CATEGORY: {
          return REMOVE_FAVORITE_CATEGORY({ categoryId }).then(({ ok }) => {
            if (ok) {
              FETCH_FAVORITES();
            }
          });
        }
        default:
          return null;
      }
    };
    return removeActionType();
  }, [type, sportId, categoryId]);

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      return isPinned ? remove() : save();
    },
    [save, remove, isPinned]
  );

  return (
    role === USER_ROLES.USER && (
      <Box data-role="sports-icon-pinnedIcon" right="s" onClick={handleClick}>
        <Icon
          name={`icons/general/state/pushpin-${isPinned ? 'on' : 'off'}`}
          size={size}
          color={isPinned ? 'state_favorite' : color}
          className={!isPinned && isActive ? styles.icon : ''}
        />
      </Box>
    )
  );
};

export default PinnedIcon;
