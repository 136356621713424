import { Icon } from '@namespace/icons2';
import { useI18n } from '@namespace/i18n';
import { AText, Box } from '@alf/uikit';
import { useGetGameGoBack } from '../../hooks';
import styles from './index.module.css';

export const CasinoSearchBackHeader = ({ lobbyUrl }) => {
  const { t } = useI18n();
  const goBack = useGetGameGoBack(lobbyUrl);

  return (
    <Box align="center" padding="0 s" bottom="xs" className={styles.container}>
      <Box padding="s" onClick={goBack} className={styles.back}>
        <Icon size="s" color="line_header_txt" name="icons/general/nav/back" />
      </Box>
      <AText
        breed="BU"
        size="xl"
        color="line_header_txt"
        overflow="ellipsis"
        className={styles.title}
      >
        {t('web.games.search.placeholder')}
      </AText>
    </Box>
  );
};
