import { useMemo } from 'react';
import { AText, Box } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { orderBy } from 'lodash';
import { useI18n } from '@namespace/i18n';
import {
  BASEBALL_GAME_POINTS_INFO,
  DIRECTION,
  DOWN,
  UP,
  BASE
} from '../../../constants';
import { useEventField } from '../../../../../../../store';
import styles from './index.module.css';

const Baseball = ({ eventId }) => {
  const { t } = useI18n();
  const eventRtsData = useEventField(eventId, 'eventRtsData') || [];

  const gamePointsData = eventRtsData.filter(({ type }) =>
    BASEBALL_GAME_POINTS_INFO.includes(type)
  );

  const { value: directionValue } =
    eventRtsData.find(({ type }) => type === DIRECTION) || {};

  const directionName = useMemo(() => {
    switch (Number(directionValue)) {
      case 1:
        return UP;

      case 2:
        return DOWN;

      default:
        return '';
    }
  }, [directionValue]);

  const bases = eventRtsData.filter(({ type }) => type.includes(BASE));
  const basesCombination = orderBy(bases, 'type', 'desc')
    .map(({ value }) => value)
    .join('');

  return (
    <Box justify="center">
      <Box className={styles.container} justify="between">
        <Box align="center">
          <AText breed="B" size="s" color="line_txt_3">
            {t('scoreboard.baseball.bases')}
          </AText>
          <Icon
            className={styles.basesIcon}
            name={`icons/baseball-bases/${basesCombination}`}
            size="m"
          />
        </Box>
        <Box align="center" justify="center" className={styles.directionName}>
          <AText breed="B" size="s" color="line_txt_3">
            {directionName && t(`scoreboard.baseball.${directionName}`)}
          </AText>
        </Box>
        {gamePointsData.map(({ type, value }) => (
          <Box key={type} align="center">
            <AText
              breed="B"
              size="s"
              color="line_txt_3"
              className={styles.statType}
            >
              {t(`scoreboard.baseball.${type}`)}
            </AText>
            <AText size="m" color="line_txt_1" className={styles.statValue}>
              {value}
            </AText>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Baseball;
