import { useContext, useEffect } from 'react';
import { DeviceContext } from '@namespace/device';
import { ThemesContext } from '@namespace/themes';
import {
  COLOR_SCHEME_AUTO,
  COLOR_SCHEME_DARK,
  COLOR_SCHEME_LIGHT,
  COOKIES_KEYS,
  getCookie,
  getLocalStorageValue,
  LOCAL_STORAGE_KEYS,
  setCookie,
  setRootStyle,
  useSetBodyClass
} from '@namespace/helpers';
import { useOtherSettings } from '@namespace/cms';
import { get, isEmpty } from 'lodash';
import { LOG_ACTIONS, logAction } from '@namespace/analytics';
import useSetThemeClassToBody from '../../hooks/useSetThemeClassToBody';
import styles from './index.module.css';

const CSSContainer = ({
  children,
  CSS_THEME,
  className,
  DEFAULT_COLOR_SCHEME = COLOR_SCHEME_LIGHT,
  AVAILABLE_COLOR_SCHEMES = [COLOR_SCHEME_LIGHT]
}) => {
  const { pageType } = useContext(DeviceContext);
  const [{ colorScheme }, { SET_COLOR_SCHEME }] = useContext(ThemesContext);

  const { selectedColorMode, activeColorScheme } = colorScheme || {};

  const otherSettings = useOtherSettings();
  const availableThemes = get(otherSettings, 'themes.availableThemes', []);

  const activeTheme = availableThemes.some(({ name }) => name === CSS_THEME)
    ? getLocalStorageValue(LOCAL_STORAGE_KEYS.CSS_THEME, CSS_THEME)
    : CSS_THEME;
  useSetThemeClassToBody(activeTheme);

  useEffect(() => {
    let initialColorScheme = activeColorScheme;

    if (isEmpty(colorScheme)) {
      const colorSchemeFromCookie = getCookie(
        COOKIES_KEYS.COLOR_SCHEME,
        DEFAULT_COLOR_SCHEME
      );
      initialColorScheme = AVAILABLE_COLOR_SCHEMES.some(
        (i) => i === colorSchemeFromCookie
      )
        ? colorSchemeFromCookie
        : DEFAULT_COLOR_SCHEME;
    }

    const newSelectedColorMode =
      initialColorScheme === COLOR_SCHEME_AUTO
        ? getCookie(COOKIES_KEYS.SELECTED_COLOR_MODE, initialColorScheme)
        : initialColorScheme;

    SET_COLOR_SCHEME({
      activeTheme,
      activeColorScheme: initialColorScheme,
      selectedColorMode: newSelectedColorMode
    });
    setCookie(COOKIES_KEYS.COLOR_SCHEME, initialColorScheme, { expires: 365 });
    setCookie(COOKIES_KEYS.SELECTED_COLOR_MODE, newSelectedColorMode, {
      expires: 365
    });
  }, []);

  const { setBodyClass, removeBodyClass, isClassExist } = useSetBodyClass();

  useEffect(() => {
    if (selectedColorMode) {
      if (isClassExist(COLOR_SCHEME_DARK)) {
        removeBodyClass(COLOR_SCHEME_DARK);
      }
      if (isClassExist(COLOR_SCHEME_LIGHT)) {
        removeBodyClass(COLOR_SCHEME_LIGHT);
      }

      setBodyClass(selectedColorMode);
      setRootStyle('color-scheme', selectedColorMode);
    }
  }, [isClassExist, removeBodyClass, selectedColorMode, setBodyClass]);

  useEffect(() => {
    if (selectedColorMode && activeColorScheme) {
      logAction(LOG_ACTIONS.THEME_COLOR, {
        themeColor: selectedColorMode,
        themeType: activeColorScheme
      });
    }
  }, [activeColorScheme, selectedColorMode]);

  return (
    <div className={`${pageType}`}>
      <div className={className ? `${className}` : `${styles.container}`}>
        {children}
      </div>
    </div>
  );
};

export default CSSContainer;
