import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { Icon } from '@namespace/icons2';

import { fileSizeFormat } from '../utils';

import styles from '../index.module.css';

const FilesList = ({ items, errors, onRemove }) => {
  const { t } = useI18n();

  return (
    <Box
      component="ul"
      className={styles.filesList}
      direction="column"
      padding="0 0"
      top="0"
      bottom="0"
    >
      {items.map(({ size, name: fileName, lastModified }, idx) => {
        const wrongFormatText = t(
          'personalOffice.documents.additionalInformation.error.wrongType'
        );

        const validationError = errors[fileName];
        const error = validationError?.format && wrongFormatText;
        const key = `${fileName}-${lastModified}-${idx}`;

        return (
          <Box
            component="li"
            justify="between"
            align="center"
            className={styles.filesListItem}
            key={key}
            bottom="s"
          >
            <Box direction="column">
              <AText size="m">{`${fileName} (${fileSizeFormat(size)})`}</AText>

              {error && (
                <AText size="s" breed="R" color="state_error">
                  {error}
                </AText>
              )}
            </Box>
            <Icon
              name="icons/general/action/close"
              size="s"
              className={styles.iconClose}
              color={
                error ? 'btn_quaternary_color_2' : 'btn_quaternary_color_4'
              }
              onClick={onRemove(idx)}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default FilesList;
