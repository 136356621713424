import { Box } from '@alf/uikit';
import clsx from 'clsx';
import styles from './index.module.css';

const Skelet = ({ className }) => {
  return (
    <Box className={styles.skeletWrapper} justify="center">
      <div className={clsx(styles.skelet, styles.skeletBlock, className)} />
    </Box>
  );
};

export default Skelet;
