import { useCallback, useContext } from 'react';
import { socketSend } from '@namespace/socket';
import { StepperContext } from '@namespace/common';
import NewPassword from '../NewPassword';
import CancelButton from '../CancelButton';
import styles from './index.module.css';

const SetNewPasswordRecovery = ({
  onStepSuccess,
  onStepError,
  onGoFirst,
  classNames = {}
}) => {
  const {
    stepper: {
      formData: { token = '' },
      errorCode = ''
    }
  } = useContext(StepperContext);

  const { cancelButtonClassNames = '' } = classNames;

  const handleSubmit = useCallback(
    async ({ newPassword }) => {
      await socketSend({
        cmd: 'accounting/password_recovery/set_new_password',
        data: {
          token,
          password: newPassword
        }
      })
        .then(() => onStepSuccess())
        .catch(({ code }) => onStepError({ errorCode: Math.abs(code) }));
    },
    [token, onStepSuccess]
  );

  return (
    <div className={styles.step}>
      <NewPassword onSuccess={handleSubmit} errorCode={errorCode} />
      <CancelButton
        className={`${styles.backButton} ${cancelButtonClassNames}`}
        onCancel={onGoFirst}
      />
    </div>
  );
};

export default SetNewPasswordRecovery;
