import { isEmpty } from 'lodash';

const extractSnippets = (snippetIds = [], data = {}, fallback = {}) => {
  return snippetIds.reduce((memo, id) => {
    return {
      ...memo,
      [id]: (!isEmpty(data?.[id]) && data?.[id]) || fallback?.[id]
    };
  }, data);
};

export default extractSnippets;
