import types from '../types';

export const initialState = {
  activeStep: 1,
  success: false,
  error: false,
  errorCode: '',
  formData: {}
};

export const handlers = {
  [types.SET_STEP]: (state, step) => ({
    ...state,
    activeStep: step
  }),
  [types.SET_FORM_DATA]: (state, data) => ({
    ...state,
    formData: { ...state.formData, ...data }
  }),
  [types.SET_SUCCESS]: (state) => ({
    ...state,
    success: true,
    error: false
  }),
  [types.SET_ERROR]: (state, { errorCode = '' }) => ({
    ...state,
    errorCode,
    success: false,
    error: true
  }),
  [types.SET_ERROR_CODE]: (state, { errorCode = '', error = false }) => ({
    ...state,
    error,
    errorCode
  }),
  [types.GO_NEXT_STEP]: (state, data) => ({
    ...state,
    activeStep: data.activeStep + 1,
    formData: { ...state.formData, ...data },
    errorCode: ''
  }),
  [types.GO_PREV_STEP]: (state, data) => ({
    ...state,
    activeStep: data.activeStep - 1,
    formData: { ...state.formData, ...data },
    errorCode: ''
  }),
  [types.GO_FIRST_STEP]: (state, data) => ({
    ...state,
    activeStep: 1,
    formData: { ...state.formData, ...data },
    errorCode: ''
  }),
  [types.GO_TO_STEP]: (state, { formData, step = 1, errorFields = {} }) => ({
    ...state,
    activeStep: step,
    formData: { ...state.formData, ...formData },
    errorFields
  })
};
