import { useContext, useCallback, useState } from 'react';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { USER_ROLES, UserContext } from '@namespace/user';
import { Box, AText, Button } from '@alf/uikit';
import { useGeneralRoutes, PageContext } from '@namespace/cms';
import PredictionsAccordion from '../../common/PredictionsAccordion';
import Step from '../../common/Step';
import {
  PREDICTIONS_STATUS,
  PREDICTIONS_STEPS_IDS,
  PREDICTIONS_INFO_STEPS_ICONS_TYPES
} from '../../constants/index';
import { PredictionsContext } from '../../store/context';
import Winnings from '../../common/Winnings';
import RefillConfirmation from './RefillConfirmation';
import styles from './index.module.css';

const PredictionsInfoBlock = ({ id: componentId }) => {
  const { page } = useContext(PageContext);
  const { components = {} } = page;
  const { config = {} } = components[componentId];
  const {
    currency = null,
    isResultsPage,
    howItWorksSnippetId,
    predictionsURL,
    mobileAppLoginURL,
    mobileAppBetURL
  } = config;
  const { t, f } = useI18n();
  const { push } = useHistory();
  const { login, preMatchSport, predictions } = useGeneralRoutes();
  const [user] = useContext(UserContext);
  const { role } = user;
  const [state, actions] = useContext(PredictionsContext);
  const {
    localStatus,
    realWinAmount,
    happyPathAmount,
    isAllowEditing,
    endDateOfEditing
  } = state;
  const { EDIT_STEP } = actions;
  const { SAVED, COMPLETED, FAIL_SAVED, FAIL_UNSAVED } = PREDICTIONS_STATUS;
  const steps = PREDICTIONS_INFO_STEPS_ICONS_TYPES[localStatus];
  const [isRefillConfirmation, setIsRefillConfirmation] = useState(false);

  const handleReFill = useCallback(() => {
    EDIT_STEP(PREDICTIONS_STEPS_IDS.GROUPS, true);
    if (isResultsPage) {
      push(predictionsURL || predictions);
    }
  }, [EDIT_STEP, isResultsPage, predictions, push, predictionsURL]);

  const handleGoToMatches = useCallback(
    () => push(role === USER_ROLES.GUEST ? login : preMatchSport),
    [push, role, login, preMatchSport]
  );

  if (isRefillConfirmation) {
    return (
      <RefillConfirmation
        onRefill={() => {
          handleReFill();
          setIsRefillConfirmation(false);
        }}
        onCancel={() => setIsRefillConfirmation(false)}
      />
    );
  }

  return (
    <Box
      align="center"
      justify="center"
      direction="column"
      className={styles.predictionsInfoBlock}
    >
      {(localStatus === SAVED ||
        localStatus === COMPLETED ||
        localStatus === FAIL_SAVED) && (
        <AText
          breed="BU"
          size="xl"
          color="txt_color_4"
          align="center"
          className={styles.predictionsInfoBlockTitle}
        >
          {t(`predictionsInfoBlock.title`)}
        </AText>
      )}
      {(isAllowEditing ||
        localStatus === FAIL_SAVED ||
        localStatus === COMPLETED) && (
        <Box align="center" className={styles.predictionsInfoBlockSteps}>
          {(steps || []).map((item) => (
            <Step key={item.stepId} status={localStatus} step={item} />
          ))}
        </Box>
      )}
      {localStatus === SAVED && (
        <Box align="center" className={styles.saveContainer}>
          <AText
            breed="R"
            size="l"
            color="txt_color_4"
            className={styles.saveMessage}
          >
            {!!endDateOfEditing &&
              t('predictionsInfoBlock.presaveMessage', [
                f
                  .getDateTime({ date: endDateOfEditing })
                  .toFormat('dd.MM.yyyy HH:mm')
              ])}
          </AText>
          <Box align="center" className={styles.predictionsInfoBlockButtons}>
            <Button
              className={styles.predictionsInfoBlockRefillButton}
              onClick={handleReFill}
              actionType="color1"
              intent="primary"
              size="l"
            >
              {t('predictionsInfoBlock.buttonRefill')}
            </Button>
            {mobileAppLoginURL && mobileAppBetURL ? (
              <Button
                className={styles.predictionsInfoBlockBetButton}
                component="a"
                actionType="color4"
                intent="secondary"
                size="l"
                href={
                  role === USER_ROLES.GUEST
                    ? mobileAppLoginURL
                    : mobileAppBetURL
                }
              >
                {t('predictionsInfoBlock.buttonBet')}
              </Button>
            ) : (
              <Button
                className={styles.predictionsInfoBlockBetButton}
                onClick={handleGoToMatches}
                actionType="color4"
                intent="secondary"
                size="l"
              >
                {t('predictionsInfoBlock.buttonBet')}
              </Button>
            )}
          </Box>
        </Box>
      )}
      {localStatus === COMPLETED && (
        <Box
          direction="column"
          className={styles.predictionsInfoBlockCompleted}
        >
          <Box className={styles.predictionsInfoBlockWinningsWrapper}>
            <Winnings
              amount={realWinAmount}
              text={t('predictionsInfoBlock.winningsCurrentAmount')}
              currency={currency}
            />
            <Winnings
              amount={happyPathAmount}
              text={t('predictionsInfoBlock.winningsPotentialAmount')}
              currency={currency}
            />
          </Box>
          {isAllowEditing && (
            <Button
              className={`${styles.predictionsInfoBlockRefillButton} ${styles.single}`}
              onClick={() => setIsRefillConfirmation(true)}
              actionType="color4"
              intent="secondary"
              size="l"
            >
              {t('predictionsInfoBlock.buttonRefill')}
            </Button>
          )}
        </Box>
      )}
      {(localStatus === FAIL_SAVED || localStatus === FAIL_UNSAVED) && (
        <Box align="center" className={styles.predictionsInfoBlockFailed}>
          <AText
            breed="R"
            size="l"
            color="txt_color_4"
            className={styles.predictionsInfoBlockErrorMessage}
          >
            {t(`predictionsInfoBlock.errorMessage.${localStatus}`)}
          </AText>
          <Button
            className={`${styles.predictionsInfoBlockGoToMatchesButton}`}
            onClick={handleGoToMatches}
            actionType="color1"
            intent="primary"
            size="l"
          >
            {t('predictionsInfoBlock.buttonGoToMatches')}
          </Button>
        </Box>
      )}
      <PredictionsAccordion
        snippetId={howItWorksSnippetId}
        title="HowItWorksTop"
        theme="blue"
        step={localStatus}
      />
    </Box>
  );
};

export default PredictionsInfoBlock;
