import { window } from '@namespace/helpers';

const logNewRelicError = (error, actionName, attributes = {}) => {
  if (window.newrelic) {
    const newAttributes = { actionName, ...attributes };
    try {
      window.newrelic.noticeError(error, newAttributes);
    } catch {
      console.error('newrelic noticeError exception');
    }
  } else if (
    process.env.NODE_ENV !== 'development' ||
    process.env.TURN_ANALYTIC_LIBS_ON === 'true'
  ) {
    console.error('no newrelic in window');
  }
};

export default logNewRelicError;
