import { useEffect, useState } from 'react';
import { window } from '@namespace/helpers';

const useDetectKeyboardOpen = (minKeyboardHeight = 390, defaultValue) => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(defaultValue);

  useEffect(() => {
    const listener = () => {
      const newState =
        window.screen.height - minKeyboardHeight > window.visualViewport.height;
      if (isKeyboardOpen !== newState) {
        setIsKeyboardOpen(newState);
      }
    };
    window.visualViewport.addEventListener('resize', listener);
    return () => {
      window.visualViewport.removeEventListener('resize', listener);
    };
  }, [isKeyboardOpen, minKeyboardHeight]);

  return isKeyboardOpen;
};

export default useDetectKeyboardOpen;
