import { Footer as CoreFooter } from '@namespace/layout';
import styles from './index.module.css';

export default ({ id }) => (
  <CoreFooter
    classNames={{
      row: styles.rowClassName
    }}
    id={id}
  />
);
