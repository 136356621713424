import { LINE_KEY, MODE } from '../../constants';
import { useGetPathParams } from '../../hooks/useGetPathParams';
import { useCategory, useTournament } from '../../store';
import BasePreMatchEvents from '../../common/BasePreMatchEvents';
import { EventsLineExtendedHeader } from './components/EventsLineExtendedHeader';

export const PreMatchTournamentEventsExtended = ({ id }) => {
  const { sportSlug, activeSport, tournamentId } = useGetPathParams(
    MODE.PRE_MATCH
  );

  const { categoryId, tournamentName } = useTournament(
    tournamentId,
    MODE.PRE_MATCH
  );

  const category = useCategory(categoryId, MODE.PRE_MATCH);

  return (
    <BasePreMatchEvents
      id={id}
      activeSport={activeSport}
      tournamentsIds={tournamentId ? [tournamentId] : []}
      tournamentName={tournamentName}
      categories={category ? [category] : []}
      key={sportSlug}
      lineKey={`${LINE_KEY.PRE_MATCH}-${tournamentId}`}
      EventsLineHeaderComponent={EventsLineExtendedHeader}
    />
  );
};
