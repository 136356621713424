import { Icon, Image } from '@namespace/icons2';
import { Button, Box, AText } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import styles from './index.module.css';

const images = {
  wallet: <Image name="general/wallet" />,
  book: <Image name="general/documents/book_photo_personal_data" />
};

export const InfoScreen = ({
  title,
  image,
  actionTitle,
  actionProps,
  icon
}) => {
  const { t } = useI18n();
  return (
    <Box
      className={styles.general}
      align="center"
      justify="center"
      direction="column"
    >
      {title && (
        <Box bottom="ms">
          <AText breed="R" size="l" color="po_txt_color_3" align="center">
            {t(title)}
          </AText>
        </Box>
      )}
      {image && <Box bottom="ms">{images[image]}</Box>}
      {actionProps && (
        <Button
          className={styles.action}
          actionType="color3"
          size="l"
          iconLeft={
            <Icon size="m" name={icon || 'icons/general/action/create'} />
          }
          {...actionProps}
        >
          {t(actionTitle)}
        </Button>
      )}
    </Box>
  );
};
