import { useCallback, useContext, useMemo } from 'react';
import { useI18n } from '@namespace/i18n';
import { FiltersSlider } from '@alf/uikit';
import { DeviceContext } from '@namespace/device';
import { getMarketsByGroup } from '../utils';
import styles from './index.module.css';

const MarketGroupsTabs = ({
  eventMarkets,
  allMarketGroups,
  allMarketsTemplateIds,
  activeMarket,
  setActiveMarket,
  dataRole
}) => {
  const { t } = useI18n();
  const { isDesktop } = useContext(DeviceContext);

  const currentMarketGroups = useMemo(
    () =>
      allMarketGroups?.filter(
        ({ marketGroupMarkets }) =>
          getMarketsByGroup(eventMarkets, marketGroupMarkets).length > 0 &&
          Object.keys(marketGroupMarkets).some((i) =>
            allMarketsTemplateIds.includes(Number(i))
          )
      ),
    [allMarketGroups, eventMarkets, allMarketsTemplateIds]
  );

  const items = useMemo(() => {
    const marketItems = currentMarketGroups
      ? Object.values(currentMarketGroups).map(
          ({ marketGroupId, marketGroupName }) => ({
            id: marketGroupId,
            label: marketGroupName
          })
        )
      : [];

    return [{ label: t('marketGroup.all'), id: null }, ...marketItems];
  }, [currentMarketGroups, t]);

  const onMarketChange = useCallback(
    (value) => {
      const market = currentMarketGroups.find(
        ({ marketGroupId }) => value === marketGroupId
      );

      if (market) {
        setActiveMarket(market);
      } else {
        setActiveMarket();
      }
    },
    [items, currentMarketGroups]
  );

  return (
    <FiltersSlider
      dataRole={dataRole}
      selectedItems={[activeMarket?.marketGroupId || null]}
      isButtonsEnabled={true}
      isDesktop={isDesktop}
      items={items}
      onChange={onMarketChange}
      classNames={{
        containerClassName: styles.wrapper,
        buttonClassName: styles.buttonClassName,
        itemClassName: styles.item,
        textClassName: styles.textClassName,
        activeClassName: styles.active,
        activeTextClassName: styles.activeItemText
      }}
    />
  );
};

export default MarketGroupsTabs;
