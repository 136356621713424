import { CheckboxField } from '@namespace/forms';
import { useI18n } from '@namespace/i18n';
import { useGeneralRoutes } from '@namespace/cms';
import { HTMLSnippet } from '@namespace/snippets';
import { AText, Box } from '@alf/uikit';
import styles from './index.module.css';

const ConfirmAgeField = (props) => {
  const { t } = useI18n();
  const { termsAndConditions } = useGeneralRoutes();
  const { size = 'm', formProps } = props;
  const { confirmAgeSnippetId } = formProps;

  return (
    <CheckboxField
      LabelComponent={
        confirmAgeSnippetId ? (
          <HTMLSnippet snippetId={confirmAgeSnippetId} />
        ) : (
          <Box direction="column">
            <AText color="txt_color_7">
              {t('register.steps.secretQuestion.confirmAge')}
            </AText>
            <AText color="txt_color_7">
              {t('shortReg.form.confirmTerms1')}
              <AText
                breed="B"
                color="txt_color_3"
                component="a"
                href={termsAndConditions}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                {t('shortReg.form.generalTermsLink')}
              </AText>
              {t('shortReg.form.confirmTerms2')}
            </AText>
          </Box>
        )
      }
      size={size}
      {...props}
    />
  );
};

export default ConfirmAgeField;
