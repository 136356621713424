import { useContext, useMemo } from 'react';
import { SiteSettingsContext } from '@namespace/cms';
import { PROVIDERS } from '../../constants';

const useVirtualSportsProviders = () => {
  const [settings = {}] = useContext(SiteSettingsContext);
  const { virtualSports = {} } = settings || {};
  const { providers } = virtualSports;

  return useMemo(
    () =>
      providers
        ? providers.map((item) => PROVIDERS[item])
        : Object.values(PROVIDERS),
    [providers]
  );
};

export default useVirtualSportsProviders;
