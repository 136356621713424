import { Box, AText } from '@alf/uikit';
// import { useI18n } from '@namespace/i18n';

const Error = () => {
  // const { t } = useI18n();
  return (
    <Box margin="m" padding="m">
      <AText align="center" breed="BU" size="s" color="txt_color_1">
        {/* {t('chunks.loadError')} */}
        Network error. Please, try reloading the page later.
      </AText>
    </Box>
  );
};

export default Error;
