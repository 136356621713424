import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useI18n } from '@namespace/i18n';
import KeyboardDatePicker from '../../common/KeyboardDatePicker';
import { KEYBOARD_PICKER_ERROR_TYPES } from './constants';

const KeyboardDatePickerField = ({
  name,
  label = '',
  placeholder,
  rules = {},
  classNames = {},
  withTooltip,
  disabled = false,
  dateFormat = '',
  dateValueFormat = '',
  onBlur = () => {},
  customFieldName,
  ...props
}) => {
  const { t, f } = useI18n();
  const DATE_FORMATS_TYPES = f.getFormats();
  const [currentKeyboardError, setCurrentKeyboardError] = useState('');
  const {
    control,
    watch,
    setError,
    formState: { errors }
  } = useFormContext();
  const formError = errors[name] || errors[customFieldName];
  const { message: formErrorMsg } = formError || {};
  const errorLabel = t(formErrorMsg);
  const dt = watch(name);

  const date = useMemo(
    () =>
      dt
        ? f
            .getDateTime({
              date: dt,
              format: dateValueFormat || DATE_FORMATS_TYPES.CLIENT_API,
              withTZ: false
            })
            .toJSDate()
        : f.getDateTime().toJSDate(),
    [dt, f, dateValueFormat, DATE_FORMATS_TYPES]
  );

  const onError = useCallback((error) => {
    const errorKey = error ? `panKeeper.error.${error}` : '';
    setCurrentKeyboardError(errorKey);
  }, []);

  const onChange = useCallback(
    (newDate, callback) => {
      const preparedDate = prepareDate({
        newDate,
        f,
        dateValueFormat: dateValueFormat || DATE_FORMATS_TYPES.CLIENT_API
      });

      return callback(preparedDate);
    },
    [DATE_FORMATS_TYPES.CLIENT_API, dateValueFormat, f]
  );
  const dateValue = dt ? date : null;

  const handleBlur = useCallback(() => onBlur(setError, currentKeyboardError), [
    currentKeyboardError,
    setError
  ]);

  return (
    <KeyboardDatePicker
      onBlur={handleBlur}
      name={name}
      label={t(label)}
      placeholder={t(placeholder)}
      onError={onError}
      invalidDateMessage={KEYBOARD_PICKER_ERROR_TYPES.INVALID_DATE}
      minDateMessage={KEYBOARD_PICKER_ERROR_TYPES.MIN_DATE}
      maxDateMessage={KEYBOARD_PICKER_ERROR_TYPES.MAX_DATE}
      date={dateValue}
      format={dateFormat || DATE_FORMATS_TYPES.CLIENT_API}
      onChange={onChange}
      control={control}
      withTooltip={withTooltip}
      rules={{ ...rules }}
      error={errorLabel}
      {...classNames}
      disabled={Boolean(disabled)}
      {...props}
    />
  );
};

export default KeyboardDatePickerField;

export const prepareDate = ({ newDate, dateValueFormat, f }) => {
  if (!newDate) {
    return newDate;
  }

  if (Number.isNaN(newDate.getTime())) {
    return newDate.toString();
  }

  const validDate = f
    .getDateTime({
      date: newDate,
      fromUTC: true
    })
    .toFormat(dateValueFormat);

  return validDate;
};
