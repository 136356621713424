import { useCallback, useEffect, useMemo } from 'react';
import { Box, AText } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import {
  CUSTOM_MSG_ERRORS_TYPES,
  FormBuilder,
  SelectField
} from '@namespace/forms';
import { Icon } from '@namespace/icons2';
import { docRequestedValidator } from '@alf/validators';
import { PAN_TYPE } from '../../../constants';
import styles from '../index.module.css';

const CardSelectForm = ({
  cardsList,
  activeCard,
  setActiveCard,
  supportMail,
  showNtf,
  setIsCardDropdownReady = () => {},
  isCardDropdownReady
}) => {
  const { t } = useI18n();
  const {
    cardHash: currentCardHash = '',
    cardNumber: currentCardNumber = '',
    type: currentType,
    hasNtf: hasNotification
  } = activeCard || {};

  const defaultValues = {
    card: currentType === PAN_TYPE.WALLET ? currentCardNumber : currentCardHash
  };

  useEffect(() => {
    if (isCardDropdownReady === hasNotification) {
      setIsCardDropdownReady(!hasNotification);
    }
  }, [hasNotification, isCardDropdownReady, setIsCardDropdownReady]);

  const docRequestErrorLink = `mailto: ${supportMail}`;
  const options = useMemo(
    () =>
      cardsList.map(
        ({
          cardNumber,
          cardHash,
          cardMask,
          description = '',
          type,
          hasNtf
        }) => {
          const params =
            hasNtf && showNtf
              ? {
                  hasNtf,
                  ntf: t(
                    'personaloffice.balance.sw.forms.selectcardnumber.additionalVerificationNeeded.info'
                  ),
                  iconElement: (
                    <Icon
                      className={styles.dropDownIcon}
                      name="icons/general/state/in-process"
                      color="input_error_txt"
                      size="s"
                    />
                  )
                }
              : {};
          return {
            ...params,
            value: type === PAN_TYPE.WALLET ? cardNumber : cardHash,
            title: `${cardMask} ${description}`
          };
        }
      ),
    [cardsList, showNtf, t]
  );

  const onChange = useCallback(
    (value) => {
      const currentCard = cardsList.find(({ cardHash, type, cardNumber }) =>
        type === PAN_TYPE.WALLET ? cardNumber === value : cardHash === value
      );
      setActiveCard(currentCard);
    },
    [cardsList, setActiveCard]
  );

  if (!activeCard) {
    return null;
  }

  return (
    <FormBuilder
      formConfig={{
        mode: 'onChange',
        defaultValues
      }}
    >
      <Box margin="0 0 m">
        <SelectField
          name="card"
          label="panKeeper.label.selectCard"
          placeholder={t('panKeeper.placeholder.selectCard')}
          onChange={onChange}
          options={options}
          enableInstantFieldValidation={true}
          validationTypesMsgs={{
            [CUSTOM_MSG_ERRORS_TYPES.DOC_REQUESTED]: (
              <>
                {t(
                  'personalOffice.balance.sw.forms.selectCardNumber.additionalVerificationNeeded.warning'
                )}
                <AText
                  breed="R"
                  size="s"
                  component="a"
                  color="input_error_txt"
                  href={docRequestErrorLink}
                  className={styles.link}
                >
                  {supportMail}
                </AText>
              </>
            )
          }}
          rules={{
            ...docRequestedValidator(
              options,
              CUSTOM_MSG_ERRORS_TYPES.DOC_REQUESTED
            )
          }}
        />
      </Box>
    </FormBuilder>
  );
};

export default CardSelectForm;
