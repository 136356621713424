import { pathOr } from 'ramda';
import { shallow } from 'zustand/shallow';
import { createSelector } from 'reselect';
import { useSportsbookSelector } from '../../../../store';

export const selectTopPreMatchEventFields = (eventId, fields) =>
  createSelector(pathOr([], ['topPreMatchEvents']), (topPreMatchEvents) => {
    const topEvent =
      topPreMatchEvents.reduce(
        (acc, { events }) => ({
          ...acc,
          ...events.reduce(
            (eventsAcc, event) => ({ ...eventsAcc, [event.eventId]: event }),
            {}
          )
        }),
        {}
      )[eventId] || {};
    const data = {};

    for (const fieldKey of fields) {
      data[fieldKey] = topEvent[fieldKey];
    }
    return data;
  });

export const useTopPreMatchEventFields = ({ eventId, fields }) =>
  useSportsbookSelector(
    selectTopPreMatchEventFields,
    [eventId, fields],
    shallow
  );
