import { rePatterns } from '../../patterns';

const phoneNumberValidator = (
  message = 'validators.phoneNumberFormatValidator'
) => ({
  pattern: {
    value: rePatterns.phoneNumber,
    message
  }
});

export default phoneNumberValidator;
