import { useContext } from 'react';
import { Box, Overlay } from '@alf/uikit';
import { useThemeComponents } from '@namespace/themes';
import { CommonLoader } from '@namespace/common';
import { isEmpty } from 'ramda';
import { SWPaymentsContext } from '../../store/context';
import { ServiceProperties } from '../ServiceProperties/connector';
import { InfoMessage } from '../InfoMessage';
import { FirstDepositInfoMessage as DefaultFirstDepositInfoMessage } from '../FirstDepositInfoMessage';
import styles from './index.module.css';

export const PaymentFormWrapper = ({
  className = '',
  customInfoComp: CustomInfoMsg,
  customInfoMessage,
  isFullWidthMessage,
  children,
  activePaymentName,
  haveServiceProperties = true,
  hasInfoMessage = true,
  isUserCardsLoaded,
  cardList = [],
  isNeedSuccessDeposit = true,
  withLimits,
  customFormWrapper = '',
  customServiceWrapper = '',
  isLoading = false
}) => {
  const [{ activePaymentType, userTokens }] = useContext(SWPaymentsContext);
  const isCardListEmpty = isUserCardsLoaded && isEmpty(cardList);
  const cards = isCardListEmpty ? userTokens : cardList;

  const {
    FirstDepositInfoMessage = DefaultFirstDepositInfoMessage
  } = useThemeComponents();

  if (isLoading) {
    return (
      <Overlay>
        <CommonLoader />
      </Overlay>
    );
  }

  if (isUserCardsLoaded && isEmpty(cards) && isNeedSuccessDeposit) {
    return <FirstDepositInfoMessage activePaymentName={activePaymentName} />;
  }

  return (
    <Box className={className} direction="column">
      {hasInfoMessage && (
        <Box top="m">
          <InfoMessage
            className={isFullWidthMessage && styles.infoMessage}
            customMessageComp={CustomInfoMsg}
            message={
              customInfoMessage ||
              `personalOffice.balance.sw.${activePaymentType}.forms.attentionMessage`
            }
          />
        </Box>
      )}
      {haveServiceProperties && (
        <ServiceProperties
          customServiceWrapper={customServiceWrapper}
          withLimits={withLimits}
        />
      )}
      <Box direction="column" top="m" className={customFormWrapper}>
        {children}
      </Box>
    </Box>
  );
};
