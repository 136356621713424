import { get, getResourceFromCms } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

export const getTeasers = () => get('/frontend_api/teaser/').then(camelizeKeys);

export const getTeasers2 = ({ teasersNames = [] }) =>
  getResourceFromCms(
    `/teasers?${teasersNames
      .map((teaser, i) => `${i > 0 ? '&' : ''}teasersNames[]=${teaser}`)
      .join('')}`
  ).catch((e) => console.log(e));
