import { isEmpty } from 'lodash';

export const getMarketsByGroup = (eventMarkets, marketGroupMarkets) => {
  return eventMarkets
    .filter(({ marketTemplateId }) =>
      Object.keys(marketGroupMarkets)
        .map(Number)
        .includes(marketTemplateId)
    )
    .filter(
      ({ resultTypeId, marketTemplateId }) =>
        marketGroupMarkets[marketTemplateId].includes(null) ||
        marketGroupMarkets[marketTemplateId].includes(resultTypeId)
    );
};

export function getSportRadarMatchId(url) {
  const regMatchId = /match\/(\d+)/;

  if (!isEmpty(url) && regMatchId.test(url)) {
    return parseInt(url.match(regMatchId)[1], 10);
  }
  return false;
}
