import { useI18n } from '@namespace/i18n';
import { useReferenceState } from '@namespace/common';
import { useConfig } from '@namespace/cms';
import { loginStepsMap } from './constants';
import GuestContent from '../../../../common/GuestContent';
import styles from './index.module.css';

const Login = ({ id, componentsMap = {}, classNames = {} }) => {
  const { t } = useI18n();
  const { config = {} } = useConfig(id);

  useReferenceState();

  return (
    <GuestContent
      header={t('login.title')}
      dataRole="login-page"
      config={config}
      classNames={{ ...classNames, headerClassNameDefault: styles.header }}
      componentsMap={{
        ...loginStepsMap,
        ...componentsMap
      }}
    />
  );
};

export default Login;
