import { AText, Box, Button } from '@alf/uikit';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { SiteSettingsContext } from '@namespace/cms';
import { DeviceContext } from '@namespace/device';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { useGetImage } from '@namespace/themes';
import { UserContext } from '@namespace/user';
import { forwardRef, useContext, useState } from 'react';
import DateTime from '../../../common/DateTime';
import { PROMOTION_ITEM_TYPES } from '../../../constants';
import { useGetPromotionPageRouteByPromoItemType } from '../../../hooks';
import getPromoImage from '../../../utils/getPromoImage';
import styles from './index.module.css';

const promoItemTypeToTermsTransKey = {
  [PROMOTION_ITEM_TYPES.PROMOTION]: 'web.promotions.button.terms',
  [PROMOTION_ITEM_TYPES.TOURNAMENT]: 'web.tournaments.button.terms'
};

export const RecentlyPromoItem = forwardRef(
  ({ promo, index, pathTag, gap }, ref) => {
    const [isError, setIsError] = useState(false);
    const [isShown, setIsShown] = useState(false);
    const { isDesktopDevice, realPageType } = useContext(DeviceContext);
    const [user] = useContext(UserContext);
    const [settings] = useContext(SiteSettingsContext);
    const { activeWallet = {} } = user;
    const { t, language } = useI18n();
    const { push } = useHistory();
    const {
      promoId,
      titleTranslationKey,
      defaultImageLink,
      defaultImageLinkDark,
      periodEnd,
      settingsPerCurrency = [],
      imagesPerLanguage = [],
      actionLink
    } = promo;
    const promoPageRoute = useGetPromotionPageRouteByPromoItemType({
      activePromoItemType: promo.type
    });
    const redirectLink = actionLink || `${promoPageRoute}/${promoId}`;
    const { currency: userCurrency = null } = activeWallet;
    const { siteConfig } = settings;
    const { defaultCurrency } = siteConfig;
    const currentCurrency = userCurrency || defaultCurrency;
    const getImg = useGetImage();

    let currentDefaultImageLink = defaultImageLink;
    let currentDefaultImageLinkDark = defaultImageLinkDark;
    let currentImagesPerLanguage = imagesPerLanguage;

    // temporary solution!! this condition will be deleted when per platform
    // functionality for promos images will be on prod envs
    if (promo[realPageType]) {
      const {
        defaultImageLink: defaultImageLinkForPlatform,
        defaultImageLinkDark: defaultImageLinkDarkForPlatform,
        imagesPerLanguage: imagesPerLanguageForPlatform
      } = promo[realPageType];

      currentDefaultImageLink = defaultImageLinkForPlatform;
      currentDefaultImageLinkDark = defaultImageLinkDarkForPlatform;
      currentImagesPerLanguage = imagesPerLanguageForPlatform;
    }

    const defaultPromoImage = getImg(
      currentDefaultImageLink,
      currentDefaultImageLinkDark
    );

    const recentlyPromoImage = getPromoImage(
      settingsPerCurrency,
      currentImagesPerLanguage,
      language,
      realPageType,
      currentCurrency,
      getImg
    );

    const handleClick = () => {
      logAction(LOG_ACTIONS.PROMO_RECENTLY_COMPLETED, {
        index,
        promoId,
        periodEnd,
        promoName: t(`${titleTranslationKey}`, promoId),
        promoCategory: pathTag
      });
      push(redirectLink);
    };

    return (
      <Box
        ref={ref}
        style={gap && { marginRight: gap }}
        direction="column"
        className={styles.recentlyPromoItem}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        onClick={!isDesktopDevice ? handleClick : undefined}
        data-role="promotions-item"
      >
        <Box>
          <img
            src={
              (isError ? defaultPromoImage : recentlyPromoImage) ||
              defaultPromoImage
            }
            className={styles.recentlyPromoImage}
            onError={() => setIsError(true)}
            alt="promoImage"
            data-role="promotions-item-image"
          />
          {isShown && isDesktopDevice && (
            <Box
              className={styles.layout}
              align="center"
              justify="center"
              data-role="promotions-item-layout"
            >
              <Button
                intent="secondary"
                size="m"
                actionType="color4"
                onClick={handleClick}
                data-role="promotions-item-layout-btn"
              >
                {t(promoItemTypeToTermsTransKey[promo.type])}
              </Button>
            </Box>
          )}
        </Box>
        <Box
          className={styles.tagsWrapper}
          justify="between"
          align="center"
          padding="xs"
        >
          <Box
            className={styles.promotionsItemTag}
            align="center"
            data-role="promotions-item-tag-isOver"
          >
            <AText breed="BU" size="xs" color="txt_color_3">
              {t(`web.promo.tag.isover`, 'Is over')}
            </AText>
          </Box>
          <DateTime
            isRecentlyPromo={true}
            showTimerInsteadPeriod={false}
            promoData={promo}
            dateTextColor="txt_color_3"
            dateTextSize="l"
          />
        </Box>
        <AText
          breed="B"
          size="l"
          color="txt_color_3"
          data-role="promotions-item-title"
          overflow="ellipsis"
          className={styles.recentlyPromoTitle}
        >
          {t(`${titleTranslationKey}`, promoId)}
        </AText>
      </Box>
    );
  }
);
