import { memo, useContext, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useLocation } from '@namespace/i18n';
import { useOtherSettings } from '@namespace/cms';
import { scrollToElement, document } from '@namespace/helpers';
import { ThemesContext } from '@namespace/themes';
import { DeviceContext, useScreenSize } from '@namespace/device';
import { useInternalLinkNavigation } from './useInternalLinkNavigation';

// from https://github.com/christo-pr/dangerously-set-html-content/blob/master/src/index.js

const InnerHtmlContent = ({ html, className, snippetId, ...rest }) => {
  const [{ colorScheme }] = useContext(ThemesContext);
  const { activeTheme, activeColorScheme } = colorScheme;
  const divRef = useRef(null);
  const { hash } = useLocation();
  const screenType = useScreenSize(false);
  const { pageType } = useContext(DeviceContext);
  const { isSnippetShadowRootEnabled = false } = useOtherSettings();

  useEffect(() => {
    if (html) {
      const slotHtml = document.createRange().createContextualFragment(html); // Create a 'tiny' document and parse the html string
      divRef.current.innerHTML = ''; // Clear the container
      divRef.current.appendChild(slotHtml); // Append the new content

      // scroll to the snippet after loading it
      const hashFromSnippet = divRef.current.querySelector(
        `[id='${hash.substring(1)}']` // hash without '#' we put in Id for compatibility with react-router-hash-link
      );
      if (hash && hashFromSnippet) {
        scrollToElement(hashFromSnippet, 'auto');
      }
    }
  }, [hash, html]);

  useInternalLinkNavigation(divRef);

  return (
    <div
      className={clsx(
        'snippet',
        className,
        isSnippetShadowRootEnabled &&
          clsx(activeTheme, activeColorScheme, pageType, screenType)
      )}
      {...rest}
      ref={divRef}
      dangerouslySetInnerHTML={{
        __html: html
      }}
    />
  );
};

export default memo(InnerHtmlContent);
