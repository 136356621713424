import { useState } from 'react';
import { formatAsUriParameters, getRootPath } from '@namespace/helpers';
import { useConfig } from '@namespace/cms';
import { Box, AText } from '@alf/uikit';
import { Icon, SportIcon } from '@namespace/icons2';
import { LocalizedNavLink, useI18n } from '@namespace/i18n';
import { useGetFilterParams } from '../../hooks/useGetFilterParams';
import { MODE } from '../../constants';
import ConfigTournament from './components/ConfigTournament';
import styles from './index.module.css';

const SportsMenuByConfig = ({ id: componentId }) => {
  const [isCategoryOpen, setIsCategoryOpen] = useState(true);
  const { config } = useConfig(componentId);
  const {
    mainTitle = '',
    categoryTitle = '',
    sportId,
    sportSlug = '',
    filterPathname = '',
    tournaments: configTournaments = []
  } = config;
  const { t } = useI18n();
  const { tournamentIds } = useGetFilterParams(filterPathname);
  const rootPath = getRootPath(filterPathname);

  return (
    <div className={styles.wrapper}>
      <div className={styles.menuHeader}>
        <AText size="xl" breed="BU" color="sm_txt_color_4">
          {t(mainTitle)}
        </AText>
      </div>
      <div className={styles.item}>
        <LocalizedNavLink to={`${rootPath}`}>
          <Box
            justify="between"
            align="center"
            className={`${styles.itemName} ${
              isCategoryOpen ? styles.activeItemName : ''
            }`}
          >
            <Box align="center">
              <div className={styles.sportIconWrap}>
                <SportIcon sportId={sportId} size="s" color="sm_txt_color_1" />
              </div>
              <AText breed="BU" size="m" color="sm_txt_color_1">
                {t(categoryTitle)}
              </AText>
            </Box>
            <Box
              onClick={(e) => {
                e.preventDefault();
                setIsCategoryOpen(!isCategoryOpen);
              }}
            >
              <Icon
                name={`icons/general/nav/${
                  isCategoryOpen ? 'collapse' : 'expand'
                }`}
                color="sm_txt_color_2"
                size="s"
                className={styles.expandIcon}
              />
            </Box>
          </Box>
        </LocalizedNavLink>
        <div className={styles.tournamentsWrapper}>
          {isCategoryOpen &&
            configTournaments.map(({ name, id, countryId }) => {
              const filter = formatAsUriParameters({ tournaments: [id] }, true);

              return (
                <ConfigTournament
                  id={id}
                  key={id}
                  filterPathname={filterPathname}
                  serviceId={MODE.PRE_MATCH}
                  sportSlug={sportSlug}
                  filter={filter}
                  tournamentIds={tournamentIds}
                  countryId={countryId}
                  name={name}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default SportsMenuByConfig;
