// eslint-disable-next-line no-restricted-imports
import { Helmet as ReactHelmet } from 'react-helmet';
import { IS_SERVER_RENDER } from '@namespace/helpers';

const Helmet = ({ children }) => {
  if (IS_SERVER_RENDER) {
    return null;
  }
  return <ReactHelmet>{children}</ReactHelmet>;
};

export default Helmet;
