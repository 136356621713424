import { CheckboxField } from '@namespace/forms';
import { useI18n } from '@namespace/i18n';
import { useGeneralRoutes } from '@namespace/cms';
import { HTMLSnippet } from '@namespace/snippets';
import FieldLabel from '../FieldLabel';

const ConfirmAgeField = (props) => {
  const { t } = useI18n();
  const { termsAndConditions } = useGeneralRoutes();
  const { size = 'm', formProps, dataRole = '' } = props;
  const { confirmAgeSnippetId } = formProps;

  return (
    <CheckboxField
      data-role={dataRole}
      LabelComponent={
        confirmAgeSnippetId ? (
          <HTMLSnippet snippetId={confirmAgeSnippetId} />
        ) : (
          <FieldLabel
            link={termsAndConditions}
            linkText={t('register.steps.secretQuestion.termsAndConditions')}
            firstText={t('register.steps.secretQuestion.confirmAge')}
          />
        )
      }
      size={size}
      {...props}
    />
  );
};

export default ConfirmAgeField;
