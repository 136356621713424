import { MODE } from '../../constants';
import CyberSportFavoriteEvents from './CyberSportFavoriteEvents';

export const CyberSportFavoriteLiveEvents = (props) => (
  <CyberSportFavoriteEvents
    serviceId={MODE.IN_PLAY}
    {...props}
    isLiveEvents={true}
  />
);
