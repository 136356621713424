import { xor } from 'lodash';
import types from '../types';

export const initialState = {
  inboxMessages: [],
  inboxMessagesList: [],
  outboxMessages: [],
  outboxMessagesList: [],
  selectedInboxMessages: [],
  selectedOutboxMessages: [],
  messageDetails: {},
  isSelectedAllInboxMessages: false,
  isSelectedAllOutboxMessages: false,
  totalPages: 1,
  isFetching: false,
  isImportantMessage: false
};

const handleUnreadMessages = (messages, ids = []) =>
  messages.map((message) => {
    const { id } = message;
    if (ids.includes(id)) {
      return {
        ...message,
        read: 0
      };
    }
    return { ...message };
  });

const handleDeleteMessages = (messages, ids = []) =>
  messages.filter(({ id }) => !ids.includes(id));

export const reducers = {
  [types.SET_INBOX_MESSAGES]: (state, { messages }) => ({
    ...state,
    inboxMessages: messages,
    isFetching: false
  }),
  [types.SET_OUTBOX_MESSAGES]: (state, { messages }) => ({
    ...state,
    outboxMessages: messages,
    isFetching: false
  }),
  [types.SET_INBOX_MESSAGES_LIST]: (state, { messages }) => ({
    ...state,
    inboxMessagesList: messages
  }),
  [types.SET_OUTBOX_MESSAGES_LIST]: (state, { messages }) => ({
    ...state,
    outboxMessagesList: messages
  }),
  [types.SELECT_INBOX_MESSAGE]: (state, { message }) => {
    const selected = xor(state.selectedInboxMessages, [message]);

    return {
      ...state,
      selectedInboxMessages: selected,
      isSelectedAllInboxMessages:
        state.inboxMessagesList.length > 0 &&
        state.inboxMessagesList.length === selected.length
    };
  },
  [types.SELECT_OUTBOX_MESSAGE]: (state, { message }) => {
    const selected = xor(state.selectedOutboxMessages, [message]);
    return {
      ...state,
      selectedOutboxMessages: selected,
      isSelectedAllOutboxMessages:
        state.outboxMessagesList.length > 0 &&
        state.outboxMessagesList.length === selected.length
    };
  },
  [types.SET_SELECTED_INBOX_MESSAGES]: (state, { messages = [] }) => ({
    ...state,
    isSelectedAllInboxMessages:
      state.inboxMessagesList.length > 0 &&
      state.inboxMessagesList.length === messages.length,
    selectedInboxMessages: messages
  }),
  [types.SET_SELECTED_OUTBOX_MESSAGES]: (state, { messages = [] }) => ({
    ...state,
    isSelectedAllOutboxMessages:
      state.outboxMessagesList.length > 0 &&
      state.outboxMessagesList.length === messages.length,
    selectedOutboxMessages: messages
  }),
  [types.SET_DELETED_INBOX_MESSAGES]: (state, { messages = [] }) => ({
    ...state,
    inboxMessages: handleDeleteMessages(state.inboxMessages, messages)
  }),
  [types.SET_DELETED_OUTBOX_MESSAGES]: (state, { messages = [] }) => ({
    ...state,
    outboxMessages: handleDeleteMessages(state.outboxMessages, messages)
  }),
  [types.UNSELECT_ALL_INBOX_MESSAGES]: (state) => ({
    ...state,
    selectedInboxMessages: [],
    isSelectedAllInboxMessages: false
  }),
  [types.UNSELECT_ALL_OUTBOX_MESSAGES]: (state) => ({
    ...state,
    selectedOutboxMessages: [],
    isSelectedAllOutboxMessages: false
  }),
  [types.SET_UNREAD_MESSAGES]: (state, { messages = [] }) => ({
    ...state,
    inboxMessages: handleUnreadMessages(state.inboxMessages, messages)
  }),
  [types.SET_TOTAL_PAGES]: (state, { totalPages }) => ({
    ...state,
    totalPages
  }),
  [types.SET_MESSAGE]: (state, { message }) => ({
    ...state,
    messageDetails: message
  }),
  [types.SET_IMPORTANT_MESSAGE]: (state, payload) => ({
    ...state,
    isImportantMessage: payload
  }),
  [types.SET_IS_FETCHING]: (state, { isFetching }) => ({
    ...state,
    isFetching
  })
};
