import { useContext } from 'react';
import { DeviceContext } from '@namespace/device';
import { useSpotlightSvgPath } from './hooks/useSpotlightSvgPath';
import { UserActionInterceptorMobile } from '../UserActionInterceptor/components/UserActionInterceptorMobile';
import { useCurrentStep } from '../../../../hooks';
import styles from './index.module.css';

export const SpotlightOverlay = () => {
  const { isDesktop } = useContext(DeviceContext);
  const { holes, path } = useSpotlightSvgPath();
  const { interceptActions } = useCurrentStep();

  return (
    <>
      {!isDesktop && interceptActions && (
        // It must be right near its desktop counterpart, but it's here only to minimize calculation load as `holes` is already calculated for SVG
        <UserActionInterceptorMobile holes={holes} />
      )}
      <svg className={styles.root}>
        <path d={path} className={styles.mask} fillRule="evenodd" />
      </svg>
    </>
  );
};
