import { LocalizedNavLink } from '@namespace/i18n';
import { AText, Box } from '@alf/uikit';
import styles from './index.module.css';

const Sidebar = ({ headerText, values, className = '', position }) => (
  <Box
    direction="column"
    className={`${styles.container} ${className} ${
      position ? styles[position] : ''
    }`}
  >
    <AText
      className={styles.headerText}
      breed="BU"
      size="m"
      color="txt_color_4"
    >
      {headerText}
    </AText>
    <Box className={styles.eventsWrapper}>
      <Box className={styles.buttonsWrapper}>
        {values.map(({ text, link, isActive }) => (
          <LocalizedNavLink
            key={`${text}-${link}`}
            to={link}
            className={styles.button}
            isActive={isActive ? () => true : undefined}
            activeClassName={styles.buttonActive}
          >
            {text}
          </LocalizedNavLink>
        ))}
      </Box>
    </Box>
  </Box>
);

export default Sidebar;
