import { useState, useEffect } from 'react';
import { CommonLoader } from '@namespace/common';
import { SelectField } from '@namespace/forms';
import { getCashdesks } from '../../../api/withdraw';

const CashdeskField = ({
  walletHash,
  amount,
  language,
  cityId,
  isLoading,
  setValue,
  placeholder,
  maxCashDesk,
  ...rest
}) => {
  const [cashdesks, setCashdesks] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const loadCashdesks = async () => {
      setIsFetching(true);
      try {
        const data = await getCashdesks({
          walletHash,
          language,
          cityId,
          amount
        });
        const cd = data.filter(
          ({ id }) => id > 0 && (maxCashDesk ? id < maxCashDesk : true)
        );
        setCashdesks(
          cd.map(({ id, address }) => ({
            value: String(id),
            name: address
          }))
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    };
    if (!cityId) {
      if (cashdesks.length > 0) {
        setCashdesks([]);
      }
      return;
    }
    loadCashdesks();
  }, [cityId, maxCashDesk]);

  if (isLoading || isFetching) {
    return <CommonLoader />;
  }

  return (
    <SelectField options={cashdesks} placeholder={placeholder} {...rest} />
  );
};

export default CashdeskField;
