import { useEffect, useMemo } from 'react';
import { Icon } from '@namespace/icons2';
import { Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { sportsbookActions, useSportsbookStore } from '../../store';
import LineDropdown from './LineDropdown';
import styles from './index.module.css';

const { SET_SORT_BY_FILTER } = sportsbookActions;

const SortingIcons = ({ sortingTypes }) => {
  const sortByFilter = useSportsbookStore('sortByFilter');

  return (
    <div className={styles.sorting}>
      {sortingTypes.map((sortOption) => (
        <Box
          key={sortOption}
          classNames={`${styles.sortOption} ${
            sortByFilter === sortOption ? styles.active : ''
          }`}
        >
          <Icon
            key={sortOption}
            name={sortOption}
            size="s"
            onClick={() => SET_SORT_BY_FILTER(sortOption)}
          />
        </Box>
      ))}
    </div>
  );
};

export const SortingDropdown = ({ lineKey, sortingTypes, ...props }) => {
  const { t } = useI18n();
  const sortByFilter = useSportsbookStore('sortByFilter');

  const options = useMemo(
    () =>
      sortingTypes.map((item) => ({
        value: item,
        name: t(`sportsbook.sorting.by.${item}`)
      })),
    [sortingTypes, t]
  );

  const handleOnChange = (value) =>
    SET_SORT_BY_FILTER({ lineKey, filter: value });

  useEffect(() => {
    if (sortingTypes.length && !sortByFilter[lineKey]) {
      SET_SORT_BY_FILTER({ lineKey, filter: sortingTypes[0] });
    }
  }, [sortingTypes, sortByFilter, lineKey]);

  return (
    <LineDropdown
      defaultOption={options.find(
        ({ value }) => value === sortByFilter[lineKey]
      )}
      options={options}
      onChange={handleOnChange}
      {...props}
    />
  );
};

const sortingViews = {
  icons: SortingIcons,
  dropdown: SortingDropdown
};

const Sorting = ({ sortingView = 'icons', ...props }) => {
  const SortingComponent = sortingViews[sortingView];

  return <SortingComponent {...props} />;
};

export default Sorting;
