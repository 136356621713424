import { useContext, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useI18n } from '@namespace/i18n';
import { UserContext } from '@namespace/user';
import {
  useOtherSettings,
  useSiteSettings,
  useSupportParamsSettings
} from '@namespace/cms';
import { useOpenChat, useSupport } from '@namespace/chats';
import { LIMIT_TYPES } from '@namespace/responsible-gambling';
import useLimits from '@namespace/responsible-gambling/src/hooks/useLimits';
import { MENU_ITEM_BONUSES } from '../constants';

export const useGetMenuItems = (showIcons, withSupportMenu) => {
  const { t, language } = useI18n();
  const [user] = useContext(UserContext);
  const { counters } = user;
  const { userMenu = [] } = useSiteSettings();
  const { supportMenu = [] } = useSupportParamsSettings();
  const { chatName } = useSupport();
  const { openChat } = useOpenChat(chatName);
  const { isShowSelfExcludedBlock = false } = useOtherSettings();
  const [selfExclusion] = useLimits(LIMIT_TYPES.SELF_EXCLUSION);
  const isSelfExcludedUser = isShowSelfExcludedBlock && !isEmpty(selfExclusion);

  const onChatOpen = (e) => {
    e.stopPropagation();
    openChat();
  };

  return useMemo(() => {
    let support = {
      title: 'support',
      icon: 'icons/user_menu/support'
    };
    const menuItems = [...userMenu];
    const enableSupportMenu = withSupportMenu && supportMenu.length;
    if (
      enableSupportMenu &&
      !menuItems.find(({ title }) => title === support.title)
    ) {
      support = {
        ...support,
        items: supportMenu.map((item) => {
          let el = { ...item, onClick: (e) => e.stopPropagation() };
          if (item.langLinks) {
            el = {
              ...item,
              link: item.langLinks[language] || item.langLinks?.en
            };
          } else if (!item.link) {
            el = {
              ...item,
              onClick: onChatOpen
            };
          }
          return el;
        })
      };
      menuItems.push(support);
    }

    return menuItems
      .map(({ icon, title, counterName, link, items } = {}) => {
        const isHideBonusCounter =
          title === MENU_ITEM_BONUSES && isSelfExcludedUser;

        return {
          iconName: showIcons ? icon : undefined,
          title: t(`header.userPopover.${title}`),
          titleOrigin: title,
          // FYI: if need to show bonuses from BS use counterName: "accountingAndOtherBonusesCount" in siteSettings
          count: !isHideBonusCounter && counters[counterName],
          link,
          items
        };
      })
      .filter(Boolean);
  }, [
    userMenu,
    withSupportMenu,
    supportMenu,
    language,
    onChatOpen,
    isSelfExcludedUser,
    showIcons,
    t,
    counters
  ]);
};
