import { types } from '../types';

export const actions = {
  [types.SET_NAV_MENU_VISIBLE]: (dispatch) => (payload) => {
    dispatch({
      type: types.SET_NAV_MENU_VISIBLE,
      payload
    });
  }
};
