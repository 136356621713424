import { forwardRef } from 'react';

export const Arrow = forwardRef((props, ref) => (
  <svg
    ref={ref}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon points="0 8, 8 16, 16 8, 8 0" fill="currentColor" />
  </svg>
));
