import { AText, Box, DatePicker } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { Icon } from '@namespace/icons2';
import { useSearchParams } from '@namespace/router';
import { IS_WIDGET } from '@namespace/widget/src/constants';
import { useEffect, useState } from 'react';
import { DATE_RANGE_FILTER_VALUE } from '../../../../constants';
import styles from './index.module.css';

export const DateRange = ({
  onChange,
  setIsDateRangeActive,
  handleSelectChange,
  changedFilters,
  options,
  maxMinDate = { years: 2 }
}) => {
  const { f } = useI18n();
  const DATE_FORMATS_TYPES = f.getFormats();
  const {
    startDate: searchStartDate,
    endDate: searchEndDate,
    dateOptionId: searchDateOptionId
  } = useSearchParams();

  const startDate = changedFilters?.startDate || searchStartDate;
  const endDate = changedFilters?.endDate || searchEndDate;
  const dateOptionId = changedFilters?.dateOptionId || searchDateOptionId;
  const [dateFrom, setDateFrom] = useState(
    dateOptionId === DATE_RANGE_FILTER_VALUE
      ? f
          .getDateTime({
            date: startDate,
            format: DATE_FORMATS_TYPES.API
          })
          .toISODate()
      : null
  );
  const [dateTo, setDateTo] = useState(
    dateOptionId === DATE_RANGE_FILTER_VALUE
      ? f
          .getDateTime({
            date: endDate,
            format: DATE_FORMATS_TYPES.API
          })
          .toISODate()
      : null
  );

  useEffect(() => {
    if (startDate) {
      onChange({
        dateOptionId,
        startDate,
        endDate
      });
    } else {
      onChange({
        dateOptionId: '2',
        startDate: f
          .getDateTime()
          .minus({ days: 1 })
          .toISODate(),
        endDate: f
          .getDateTime()
          .plus({ days: 1 })
          .toISODate()
      });
    }
  }, []);

  useEffect(() => {
    if (dateFrom && dateTo) {
      onChange({
        dateOptionId: DATE_RANGE_FILTER_VALUE,
        startDate: dateFrom,
        endDate: dateTo
      });
    }

    if ((!dateFrom || !dateTo) && startDate) {
      onChange({
        dateOptionId,
        startDate,
        endDate
      });
    }
  }, [dateFrom, dateTo, startDate]);

  return (
    <Box align="center">
      <DatePicker
        date={dateFrom}
        onChange={(date) => {
          setDateFrom(
            f
              .getDateTime({
                date
              })
              .toISODate()
          );
          setDateTo(null);
        }}
        disabledSuccess={true}
        variant={IS_WIDGET ? 'inline' : 'dialog'}
        className={styles.datePickerClassName}
        format={DATE_FORMATS_TYPES.dd_MM_yy}
        minDate={f
          .getDateTime()
          .minus(maxMinDate)
          .toJSDate()}
        maxDate={f.getDateTime().toJSDate()}
      />
      <AText
        size="m"
        color="input_txt_placeholder"
        className={styles.separator}
      >
        &#8211;
      </AText>
      <DatePicker
        date={
          dateTo
            ? f
                .getDateTime({
                  date: dateTo,
                  format: DATE_FORMATS_TYPES.API
                })
                .minus({ days: 1 })
            : null
        }
        onChange={(date) =>
          setDateTo(
            f
              .getDateTime({
                date
              })
              .plus({ days: 1 })
              .toISODate()
          )
        }
        disabledSuccess={true}
        variant={IS_WIDGET ? 'inline' : 'dialog'}
        className={styles.datePickerClassName}
        format={DATE_FORMATS_TYPES.dd_MM_yy}
        initialFocusedDate={dateFrom}
        minDate={dateFrom}
        disabled={!dateFrom}
        maxDate={
          Math.abs(
            f
              .getDateTime({
                date: dateFrom,
                format: DATE_FORMATS_TYPES.API
              })
              .diff(f.getDateTime(), 'days')
              .toObject().days
          ) > 90
            ? f
                .getDateTime({
                  date: dateFrom,
                  format: DATE_FORMATS_TYPES.API
                })
                .plus({ days: 90 })
                .toJSDate()
            : f.getDateTime().toJSDate()
        }
      />
      <Box className={styles.closeIconContainer}>
        <Icon
          dataRole="data-range-close"
          onClick={() => {
            handleSelectChange(options[0]);
            setIsDateRangeActive(false);
          }}
          name="icons/general/action/close"
          size="s"
          color="po_menutxt_color_5"
        />
      </Box>
    </Box>
  );
};
