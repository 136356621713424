import { useContext } from 'react';
import { Box, Button } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { useLogout, USER_ROLES, UserContext } from '@namespace/user';
import { LOG_ACTIONS, logNewRelicAction } from '@namespace/analytics';
import { TextCopy } from '@namespace/common';
import { useMobileNavMenuSettings, useGeneralRoutes } from '@namespace/cms';
import styles from './index.module.css';

const ProfileMenuFooter = ({ className = '', footerConfig }) => {
  const { t } = useI18n();
  const { push } = useHistory();
  const [{ role }] = useContext(UserContext);
  const mobileNavigation = useMobileNavMenuSettings();
  const { shareLink = '' } = mobileNavigation;
  const isLoggedIn = role === USER_ROLES.USER;
  const { home } = useGeneralRoutes();
  const { logoutButtonCentered = false } = footerConfig;

  const handleLogOutSuccess = (id) => {
    logNewRelicAction(LOG_ACTIONS.LOGOUT, { userId: id });
    push(home);
  };

  const { logout: handleLogOut } = useLogout(handleLogOutSuccess);

  const buttonsPosition = shareLink && isLoggedIn ? 'between' : 'center';
  return (
    <Box
      justify={logoutButtonCentered ? buttonsPosition : 'between'}
      align="center"
      padding="ms"
      className={`${styles.footerWrapper} ${
        !isLoggedIn ? styles.guest : ''
      } ${className}`}
    >
      {shareLink && (
        <TextCopy
          breed="BU"
          size="m"
          color="btn_quaternary_color_3"
          textDisplay={t('web.personalaccountmenu.share')}
          textCopy={shareLink}
          textNotify="Link"
          iconLeft={true}
          iconLink="icons/general/action/share"
        />
      )}
      {isLoggedIn && (
        <Button
          intent="quaternary"
          size="s"
          actionType="color3"
          onClick={handleLogOut}
          iconLeft={<Icon name="icons/user_menu/exit_ic" size="s" />}
          data-role="user-menu-logout"
        >
          {t('personalOffice.menu.footer.logOut')}
        </Button>
      )}
    </Box>
  );
};

export default ProfileMenuFooter;
