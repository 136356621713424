import { useMemo } from 'react';
import { useI18n } from '@namespace/i18n';
import { DateTime } from 'luxon';
import {
  ALL_FILTER_VALUE,
  DATE_FILTER_CMS_VALUES,
  NEXT_MORNING_FILTER
} from '../../constants';
import useSportsSettings from '../useSportsSettings';

export const useFetchEventsFilter = (filter) => {
  const { f } = useI18n();
  const { timeRangeForAllPeriodByEvents } = useSportsSettings();

  return useMemo(() => {
    if (filter) {
      const local = f.getDateTime();

      if (filter[ALL_FILTER_VALUE] && timeRangeForAllPeriodByEvents) {
        return {
          timeRange: timeRangeForAllPeriodByEvents,
          tzDiff: local.offset * 60
        };
      }

      if (filter[DATE_FILTER_CMS_VALUES.HOURS]) {
        return {
          timeRange: filter[DATE_FILTER_CMS_VALUES.HOURS],
          tzDiff: local.offset * 60
        };
      }
      if (filter[NEXT_MORNING_FILTER]) {
        const today = DateTime.local();
        let { year, month, day } = today;
        const { daysInMonth } = today;

        if (daysInMonth === day) {
          day = 1;
          if (month === 12) {
            month = 1;
            ++year;
          } else {
            ++month;
          }
        } else {
          ++day;
        }
        const timeToNextDate = Math.round(
          (DateTime.local(year, month, day, 9) - today) / 1000 / 60
        );

        return {
          timeRange: timeToNextDate,
          tzDiff: local.offset * 60
        };
      }
      if (filter[DATE_FILTER_CMS_VALUES.DAYS]) {
        return {
          date: filter[DATE_FILTER_CMS_VALUES.DAYS],
          tzDiff: local.offset * 60
        };
      }
    }
    return null;
  }, [filter, f, timeRangeForAllPeriodByEvents]);
};
