import { useContext, useEffect, useRef, useState } from 'react';
import { AText, Box, useOnClickOutside } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { Icon } from '@namespace/icons2';
import {
  useFixScroll,
  useIsNavMenuVisible,
  BOTTOM_NAV_MENU_HEIGHT
} from '@namespace/common';
import { getCurrencySign } from '../../../../../utils';
import { JackpotsContext } from '../../../../../jackpotsContext/context';
import { JackpotItemMobile } from './JackpotItemMobile';
import { JackpotBanner } from './JackpotBanner';
import { InHouseJackpotsTotalCounter } from './InHouseJackpotsTotalCounter';
import styles from './index.module.css';

export const JackpotMobile = ({ classNames }) => {
  const [{ jackpots, totalJackpot, ids }] = useContext(JackpotsContext);
  const { t } = useI18n();
  const [openedJackpotType, setOpenedJackpotType] = useState();
  const [isJackpotsInfoOpen, setIsJackpotsInfoOpen] = useState(false);
  const jackpotsInfoWindowRef = useRef(null);
  const idsDep = ids.join('');
  const isNavMenuVisible = useIsNavMenuVisible();

  useFixScroll(
    'inHouseMobileJackpotInfoWindow',
    jackpotsInfoWindowRef,
    isJackpotsInfoOpen
  );

  useEffect(() => {
    if (ids.length && !openedJackpotType) {
      setOpenedJackpotType(ids[0]);
    }
  }, [idsDep]);

  const handleOpen = () => {
    setOpenedJackpotType(ids[0]);
    setIsJackpotsInfoOpen(true);
  };

  useOnClickOutside(() => setIsJackpotsInfoOpen(false), jackpotsInfoWindowRef);

  const handleItemToggle = (isOpen, jackpotType) =>
    setOpenedJackpotType(isOpen ? jackpotType : null);

  return (
    <>
      <JackpotBanner
        classNames={classNames}
        totalJackpot={totalJackpot}
        onClick={handleOpen}
      />

      {isJackpotsInfoOpen && (
        <Box
          className={styles.jackpotsInfoWindowWrapper}
          direction="column"
          padding="m"
          style={{
            bottom: `${isNavMenuVisible ? BOTTOM_NAV_MENU_HEIGHT : 0}px`
          }}
          ref={jackpotsInfoWindowRef}
        >
          <Box
            className={styles.backBtn}
            padding="xxs"
            align="center"
            onClick={() => setIsJackpotsInfoOpen(false)}
          >
            <Icon
              name="icons/general/nav/back"
              color="txt_color_4"
              size="s"
              className={styles.backImage}
            />
            <AText breed="BU" color="txt_color_4">
              {t('games.jackpot.back')}
            </AText>
          </Box>

          <Box direction="column" align="center">
            <Box padding="xs 0 xs 0">
              <AText breed="BU" size="xl" color="accent_color_3" align="center">
                {t('games.jackpot.favbetJackpot')}
              </AText>
            </Box>
            <Box align="center">
              <AText breed="BU" align="center" size="3xl" color="txt_color_4">
                <InHouseJackpotsTotalCounter totalJackpot={totalJackpot} />
              </AText>
              <AText
                className={styles.currencySign}
                breed="BU"
                align="center"
                size="3xl"
                color="txt_color_4"
              >
                {getCurrencySign(totalJackpot.convertedCurrency)}
              </AText>
            </Box>

            <Box
              direction="column"
              padding="0 0 s 0"
              align="center"
              className={styles.jackpotItemsWrapper}
            >
              {ids.map((jackpotType) => (
                <JackpotItemMobile
                  jackpotType={jackpotType}
                  jackpotInfo={jackpots[jackpotType]}
                  key={jackpotType}
                  isDetailsOpen={jackpotType === openedJackpotType}
                  onToggle={(isOpen) => handleItemToggle(isOpen, jackpotType)}
                />
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
