import { Step } from '../Step';
import { useToursCompletion, useTrackNewRelicAction } from '../../hooks';
import { usePrepareStepRender } from './hooks';

export const TourRunner = () => {
  useToursCompletion();
  useTrackNewRelicAction();
  usePrepareStepRender();

  return <Step />;
};
