import { pathOr } from 'ramda';
import { useSportsbookStore } from '../../store';

export const selectFavoritesByName = (favoriteName) =>
  pathOr([], ['favorites', favoriteName]);

/**
 * @param {'categories'|'events'|'sports'} favoriteName
 * @returns {*}
 */
export const useFavorites = (favoriteName) =>
  useSportsbookStore(selectFavoritesByName(favoriteName));
