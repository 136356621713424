export const DOCUMENTS_VALIDATION_MAP = {
  1: {
    // eslint-disable-next-line
    regex: '^$',  // To convert a regex to a string, you can use the service https://www.freeformatter.com/json-escape.html
    translation: 'personalOffice.documents.error.passport.default'
  },
  3: {
    regex: '^$',
    translation: 'personalOffice.documents.error.cardId.default'
  }
};

export const getDocumentsValidation = (
  props = {},
  documentsNumberValidationMap = DOCUMENTS_VALIDATION_MAP
) => {
  const { documentTypeId } = props;
  const { regex, translation } =
    documentsNumberValidationMap[documentTypeId] || {};

  return {
    regex: regex ? new RegExp(regex.slice(1, -1), 'g') : undefined,
    translation
  };
};
