import { createContext, useContext, useMemo } from 'react';
import { uniqueId } from 'lodash';
import { createHookStore } from '../createHookStore';

export const createDynamicHookStore = ({
  storeName,
  initialState,
  actionsCreator
}) => {
  const Context = createContext();

  const Provider = ({ children }) => {
    const store = useMemo(() => {
      // todo SSR: make this name accessible for SSR
      const uniqueStoreName = uniqueId(storeName);
      const useBoundStore = createHookStore(uniqueStoreName, initialState);
      const boundActions = actionsCreator(useBoundStore);

      return { actions: boundActions, useStore: useBoundStore };
    }, []);

    return <Context.Provider value={store}>{children}</Context.Provider>;
  };

  const useCtxStore = (...args) => useContext(Context).useStore(...args);

  const useCtxActions = () => useContext(Context).actions;

  return {
    Context,
    Provider,
    useCtxStore,
    useCtxActions
  };
};
