import { useContext, useEffect } from 'react';
import {
  addIndex,
  allPass,
  assocPath,
  either,
  filter,
  map,
  pipe,
  propEq,
  reduce,
  reject
} from 'ramda';
import { SiteSettingsContext, useComponentConfig } from '@namespace/cms';
import { getJackpotByProvider, getJackpotGeneral } from '../api';
import { GamesContext } from '../../context';
import { DEFAULT_JACKPOT_CODE } from '../../constants';
import { useProvider } from '../../context/selectors';
import { parseGeneralJackpot, parseProviderJackpot } from './parsers';

const constEmptyArray = []; // for effect dependency

export const useLoadJackpots = ({ selectedProvider, selectedCategory }) => {
  const [, { SET_JACKPOTS }] = useContext(GamesContext);
  const {
    showGlobalJackpot,
    globalJackpotDivisor = 1,
    serviceId,
    jackpots: jackpotConfigs = constEmptyArray
  } = useComponentConfig();
  const provider = useProvider(selectedProvider);
  const [settings] = useContext(SiteSettingsContext);
  const { defaultJackpotCurrency } = settings.siteConfig;

  useEffect(() => {
    const isLobby = !selectedCategory && !selectedProvider;

    const filteredJpConfigs = filter(
      allPass([
        either(
          propEq('showJackpotTickers', true),
          propEq('showJackpotWidget', true)
        ),
        selectedProvider ? propEq('providerId', provider.serviceId) : () => true
      ]),
      jackpotConfigs
    );
    const shouldLoadProviderJp = filteredJpConfigs.length > 0;

    if (!isLobby && !shouldLoadProviderJp) {
      return;
    }

    const fetchJackpots = async () => {
      let generalJpPromise = null;
      if (showGlobalJackpot && isLobby) {
        generalJpPromise = getJackpotGeneral({ serviceId }).then(
          parseGeneralJackpot({ globalJackpotDivisor })
        );
      }

      const providerJpsPromises = filteredJpConfigs.map(
        ({ providerId, jackpotCode }) =>
          getJackpotByProvider({
            providerId,
            jackpotCode,
            currencyCode: defaultJackpotCurrency
            // for now, hardcoded divisor=100 for provider jackpots
          }).then(parseProviderJackpot({ providerJackpotDivisor: 100 }))
      );

      const allResults = await Promise.allSettled([
        generalJpPromise,
        ...providerJpsPromises
      ]);

      const allJpConfigs = [{ providerId: 'general' }, ...filteredJpConfigs];

      const result = pipe(
        addIndex(map)((res, i) => {
          const {
            providerId,
            jackpotCode = DEFAULT_JACKPOT_CODE
          } = allJpConfigs[i];

          return {
            ...res,
            key: [providerId, jackpotCode]
          };
        }),
        reject((res) => res.value == null || res.status === 'rejected'),
        reduce((acc, { key, value }) => assocPath(key, value)(acc), {})
      )(allResults);

      SET_JACKPOTS(result);
    };

    fetchJackpots();
  }, [
    selectedProvider,
    selectedCategory,
    jackpotConfigs,
    showGlobalJackpot,
    SET_JACKPOTS,
    serviceId,
    defaultJackpotCurrency,
    globalJackpotDivisor,
    provider.serviceId
  ]);
};
