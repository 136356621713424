import { useI18n, LocalizedLink } from '@namespace/i18n';
import { getGameError } from '../../../utils';
import styles from './index.module.css';

const ErrorLink = ({ to, errorCode }) => {
  const { t } = useI18n();

  return (
    <h3 className={`${styles.error} ${styles.errorLink}`}>
      <LocalizedLink to={to}>{t(getGameError(errorCode))}</LocalizedLink>
    </h3>
  );
};

export default ErrorLink;
