const getCountriesByPhoneCode = (phoneCodes, code) => {
  let updatedCodes = phoneCodes;
  if (code.length) {
    const defaultCode = phoneCodes.find(
      ({ phoneCode }) => phoneCode.indexOf(code) === 0
    );
    if (defaultCode) {
      const filteredCodes = phoneCodes.filter(
        ({ phoneCode }) => phoneCode !== defaultCode.phoneCode
      );
      updatedCodes = [defaultCode, ...filteredCodes];
    }
  }

  return updatedCodes;
};

export default getCountriesByPhoneCode;
