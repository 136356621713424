import clsx from 'clsx';
import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { Icon } from '@namespace/icons2';
import { DEFAULT_PROMO_TAGS, PROMO_TAGS } from '../../constants';
import styles from './index.module.css';

const DEFAULT_TAG_BACKGROUND = 'var(--accent_color_3)';
const DEFAULT_TAG_COLOR = 'txt_color_4';
const DEFAULT_TAG_FONT_SIZE = 'xs';

const Tags = ({
  tags = [],
  promoTagsConfig = [],
  className,
  showFrom = '',
  isTournamentParticipant = false
}) => {
  const { t } = useI18n();
  const actualPromoTagsConfig = promoTagsConfig.concat(DEFAULT_PROMO_TAGS);

  return (
    <Box className={clsx([styles.wrapper, className])}>
      {tags.map((tag) => {
        const {
          tagBackground = DEFAULT_TAG_BACKGROUND,
          tagColor = DEFAULT_TAG_COLOR,
          tagFontSize = DEFAULT_TAG_FONT_SIZE,
          tagIcon
        } = actualPromoTagsConfig.find(({ tagId }) => tagId === tag) || {};

        if (tag === PROMO_TAGS.NEW) {
          const currentDate = new Date();
          const showFromDatePlusFiveDays = new Date(
            new Date(showFrom).getTime() + 5 * 24 * 60 * 60 * 1000 // hide tag New after 5 days
          );

          if (
            showFromDatePlusFiveDays < currentDate ||
            isTournamentParticipant
          ) {
            return null;
          }
        }

        return (
          <Box
            className={styles.promotionsItemTag}
            style={{ background: tagBackground }}
            key={tag}
            align="center"
            data-role={`promotions-item-tag-${tag}`}
          >
            {tagIcon && (
              <Box margin="0 xs 0 0">
                <Icon name={tagIcon} size="xs" color={tagColor} />
              </Box>
            )}
            <AText breed="BU" size={tagFontSize} color={tagColor}>
              {t(`web.promo.tag.${tag}`, tag)}
            </AText>
          </Box>
        );
      })}
    </Box>
  );
};

export default Tags;
