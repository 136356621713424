import { useCallback, useContext, useMemo } from 'react';
import { StepperContext } from '@namespace/common';
import {
  logAction,
  LOG_ACTIONS,
  logPostConversionTracking
} from '@namespace/analytics';
import { useSearchParams } from '@namespace/router';
import { useGeneralRoutes, useOtherSettings } from '@namespace/cms';
import {
  FORM_COMPONENTS_TYPES,
  FORM_VALIDATION_TYPES,
  FormBuilder
} from '@namespace/forms';
import { useLogin, useSaveUser } from '@namespace/user';
import { useI18n } from '@namespace/i18n';
import {
  REGISTER_COMPONENTS_TYPES,
  registerComponentsMap,
  passwordRulesList,
  shortRegisterRestData
} from '../../../../constants';
import HaveAnAccount from '../../../../../../common/HaveAnAccount';
import RegisterAccordion from '../../../RegisterAccordion';
import numberWithoutCode from './utils/numberWithoutCode';

const Single = ({ classNames = {}, ...props }) => {
  const { defaultCountryCode = '' } = props;
  const { formClassName = '' } = classNames;
  const { language } = useI18n();
  const { stepper } = useContext(StepperContext);
  const { formData } = stepper;
  const {
    secretQuestionAnswer = '',
    secretQuestion = '',
    confirmAge = true,
    phone = { code: '', number: '' },
    promo = ''
  } = formData;
  const { login: loginPage, registerSuccess } = useGeneralRoutes();
  const { login } = useLogin();
  const { postConversionType } = useOtherSettings();
  const saveUser = useSaveUser();

  const defaultValues = {
    secretQuestionAnswer,
    country: defaultCountryCode,
    secretQuestion,
    confirmAge,
    phone,
    promo
  };

  const dynamicValues = useMemo(
    () => ({
      phone: {
        isoCode: defaultCountryCode,
        number: ''
      }
    }),
    [defaultCountryCode]
  );

  const componentsList = [
    {
      type: FORM_COMPONENTS_TYPES.REG_COUNTRY_SELECT,
      name: 'country',
      label: 'register.steps.personalInfo.selectCountry',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
      size: 'm'
    },
    {
      type: FORM_COMPONENTS_TYPES.REG_PHONE,
      name: 'phone',
      label: 'register.steps.personalInfo.mobile',
      validationTypes: [
        {
          type: FORM_VALIDATION_TYPES.PHONE_WITH_CODE,
          parameters: [
            (v) => ({
              ...v,
              number: numberWithoutCode(v)
            })
          ]
        }
      ],
      size: 'm',
      formProps: { dynamicValues }
    },
    {
      type: FORM_COMPONENTS_TYPES.EMAIL_INPUT_FIELD,
      name: 'email',
      label: 'register.steps.welcome.email',
      placeholder: 'register.steps.welcome.emailPlaceholder',
      validationTypes: [
        FORM_VALIDATION_TYPES.REQUIRED,
        FORM_VALIDATION_TYPES.EMAIL_ASYNC
      ],
      size: 'm'
    },
    {
      type: FORM_COMPONENTS_TYPES.PASSWORD_FIELD,
      inputType: 'password',
      name: 'password',
      label: 'register.steps.welcome.password',
      placeholder: 'register.steps.welcome.passwordPlaceholder',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
      rulesList: passwordRulesList,
      size: 'm'
    },
    {
      type: FORM_COMPONENTS_TYPES.INPUT_FIELD,
      name: 'promo',
      label: 'register.steps.welcome.promoCode',
      size: 'm'
    },
    {
      type: FORM_COMPONENTS_TYPES.SUBMIT_BUTTON,
      value: 'shortReg.register',
      size: 'xl'
    },
    {
      type: REGISTER_COMPONENTS_TYPES.CONFIRM_AGE_COMBINED_FIELD,
      name: 'confirmAge',
      size: 'm',
      validationTypes: [
        {
          type: FORM_VALIDATION_TYPES.REQUIRED,
          parameters: ['validators.requiredValidator.confirmAge']
        }
      ]
    }
  ];
  const { from: locationFrom } = useSearchParams();

  const submit = useCallback(
    async (fields) => {
      await saveUser({
        ...shortRegisterRestData,
        ...fields,
        language,
        phone: fields.phone,
        secretQuestionAnswer: fields.phone.number
      })
        .then(({ response }) => {
          login({
            username: fields.email,
            password: fields.password,
            nextPage: registerSuccess,
            withAutoLogin: false
          });
          logAction(LOG_ACTIONS.REGISTRATION_SUCCESS, {
            country: fields.country,
            promoCode: fields.promo || 'no code',
            userId: response.id,
            location: locationFrom || '',
            language
          });
          logPostConversionTracking(
            LOG_ACTIONS.POST_REGISTRATION,
            postConversionType
          );
        })
        .catch((error) => {
          logAction(LOG_ACTIONS.REGISTRATION_FAILED, { error });
        });
    },
    [saveUser, formData, language]
  );

  return (
    <FormBuilder
      wrapperClassName={formClassName}
      componentsMap={{ ...registerComponentsMap }}
      components={componentsList}
      formConfig={{ defaultValues }}
      onSubmit={submit}
      formProps={{
        isShowPhoneMask: true,
        defaultCountryCode,
        ...props
      }}
    >
      <HaveAnAccount
        to={`${loginPage}?from=registration-page`}
        text="register.steps.welcome.haveAnAccount"
        linkText="register.steps.welcome.login"
      />
      <div>
        <RegisterAccordion
          title="register.steps.welcome.messages.whyRegister"
          content="register.steps.welcome.messages.whyRegisterDetails"
        />
        <RegisterAccordion
          title="register.steps.welcome.messages.dataProtection"
          content="register.steps.welcome.messages.dataProtectionDetails"
        />
      </div>
    </FormBuilder>
  );
};

export default Single;
