import { useContext, useMemo } from 'react';
import { useI18n } from '@namespace/i18n';
import { DeviceContext } from '@namespace/device';
import { Select, Box, SELECT_TYPE } from '@alf/uikit';
import styles from './index.module.css';
import ThemeIcon from '../../common/ThemeIcon';

const ThemesSelect = ({
  activeTheme,
  classNames = {},
  onChange,
  availableThemes
}) => {
  const { t, language } = useI18n();

  const { isDesktop, isDesktopDevice } = useContext(DeviceContext);
  const options = useMemo(
    () =>
      availableThemes.map(({ name }) => ({
        iconElement: (
          <ThemeIcon themeName={name} availableThemes={availableThemes} />
        ),
        title: t(`themes.theme.name.${name}`),
        value: name
      })),
    [availableThemes, language]
  );

  const themesClassNames = {
    triggerClass: styles.trigger,
    ...classNames
  };

  return (
    <Box align="center">
      <Select
        value={activeTheme}
        options={options}
        onChange={onChange}
        additionalMobileSelectStyle={styles.mobileSelect}
        className={styles.container}
        additionSelectContentStyle={styles.contentStyle}
        additionSelectTitleStyle={styles.contentTitle}
        additionSelectOptionStyle={{
          active: styles.selectOptionActive,
          item: styles.selectOption
        }}
        additionSelectStyles={themesClassNames}
        selectType={SELECT_TYPE.ADDITIONAL}
        isDesktop={isDesktop || isDesktopDevice}
        hasIcon={true}
        hasCheck={false}
        hasDesktopSelect={true}
      />
    </Box>
  );
};

export default ThemesSelect;
