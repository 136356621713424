import { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { useSearchParams, useAddSearchParams } from '@namespace/router';
import { SiteSettingsContext } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import {
  NEXT_MORNING_FILTER,
  STARTINGSOON_FILTER,
  TODAY_FILTER
} from '../../constants';

const useEventsTimeFilter = ({
  initialFilter,
  filterConfig,
  isUrlTimeFilterByLine = true
}) => {
  const [settings] = useContext(SiteSettingsContext);
  const { sportsBook = {} } = settings;
  const { isUrlTimeFilter = true } = sportsBook;
  const { f } = useI18n();
  const DATE_FORMATS_TYPES = f.getFormats();
  const { timeFilter } = useSearchParams();
  const addSearchParams = useAddSearchParams();
  const { hours = [], days = [] } = filterConfig || {};

  const currentFilter = useMemo(() => {
    if (initialFilter[NEXT_MORNING_FILTER]) {
      return { [NEXT_MORNING_FILTER]: NEXT_MORNING_FILTER };
    }

    if (initialFilter[TODAY_FILTER]) {
      return {
        days: f
          .getDateTime()
          .plus({ days: days[0] })
          .toFormat(DATE_FORMATS_TYPES.API)
      };
    }

    return initialFilter[STARTINGSOON_FILTER]
      ? { hours: hours[0] }
      : initialFilter;
  }, [initialFilter, hours, days, DATE_FORMATS_TYPES, f]);

  const [filter, setFilter] = useState(
    timeFilter ? JSON.parse(timeFilter) : currentFilter
  );

  const handleSetFilter = useCallback(
    (newFilter) =>
      isUrlTimeFilterByLine && isUrlTimeFilter
        ? addSearchParams({
            timeFilter: JSON.stringify(newFilter)
          })
        : setFilter(newFilter),
    [addSearchParams, isUrlTimeFilter, isUrlTimeFilterByLine]
  );

  useEffect(() => {
    if (isUrlTimeFilter) {
      setFilter(timeFilter ? JSON.parse(timeFilter) : currentFilter);
    }
  }, [timeFilter]);

  return { filter, setFilter: handleSetFilter };
};

export default useEventsTimeFilter;
