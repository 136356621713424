import { forwardRef } from 'react';
import { Box } from '@alf/uikit';
import { Tournament } from './Tournament';
import { useTournamentsByCategoryId, useTournamentsByIds } from '../../store';
import styles from './index.module.css';

export const Tournaments = forwardRef((props, ref) => {
  const {
    serviceId,
    tournamentRoute,
    activeTournamentId,
    categoryId,
    sportSlug
  } = props;
  const tournamentIds = useTournamentsByCategoryId(categoryId, serviceId);
  const tournaments = useTournamentsByIds(tournamentIds, serviceId);

  return (
    <Box direction="column" className={styles.container} ref={ref}>
      {tournaments.map(({ tournamentId }) => (
        <Tournament
          key={tournamentId}
          tournamentId={tournamentId}
          isActive={tournamentId === activeTournamentId}
          serviceId={serviceId}
          basePath={`${tournamentRoute}/${sportSlug}`}
        />
      ))}
    </Box>
  );
});
