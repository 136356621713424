import { useContext, useMemo } from 'react';
import { get, isEmpty } from 'lodash';
import { useOtherSettings, SiteSettingsContext } from '@namespace/cms';

const formatOtherSettingsGamesService = (gamesService) => {
  const { pageGeneralRouteName, ...rest } = gamesService;

  return {
    ...(pageGeneralRouteName ? { lobbyRouteName: pageGeneralRouteName } : {}),
    ...rest
  };
};

export const useCasinoSettings = (casinoType) => {
  const [settings] = useContext(SiteSettingsContext);
  const otherSettings = useOtherSettings();
  const { gamesServices = [] } = settings;

  return useMemo(() => {
    const gamesServiceCasinoSettings = gamesServices.find(
      (gamesService) => gamesService.serviceName === casinoType
    );

    if (!isEmpty(gamesServiceCasinoSettings)) return gamesServiceCasinoSettings;

    const otherSettingsGameService = get(
      otherSettings,
      `casinoSettings.[${casinoType}]`,
      {}
    );

    return formatOtherSettingsGamesService(otherSettingsGameService);
  }, [casinoType, gamesServices, otherSettings]);
};
