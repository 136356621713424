import { useContext, useRef, useState } from 'react';
import clsx from 'clsx';
import { AText, Box, Overlay } from '@alf/uikit';
import { useHistory } from '@namespace/router';
import { Icon } from '@namespace/icons2';
import { DeviceContext } from '@namespace/device';
import { useFixScroll } from '@namespace/common';
import Event from './components/Event';
import BackHeaderCommon from '../../../BackHeader';
import { useEventsByEventIdInSameTournament } from './selectors/eventsByEventIdInSameTournament';
import styles from './index.module.css';

const BackHeader = ({
  sportId,
  eventId,
  children,
  title,
  isPrimary,
  dataRole
}) => {
  const containerRef = useRef(null);
  const [isEventsOpened, setIsEventsOpened] = useState(false);
  const { goBack } = useHistory();
  const { isDesktop } = useContext(DeviceContext);
  const eventsFromOneTournament = useEventsByEventIdInSameTournament(
    Number(eventId)
  );

  useFixScroll('events-list', containerRef, isEventsOpened && !isDesktop);

  return (
    <>
      {isEventsOpened && !isDesktop && (
        <Overlay
          onClick={() => setIsEventsOpened(false)}
          classNames={{ wrapperClass: styles.overlay }}
        />
      )}
      <div
        className={clsx(styles.wrapper, isEventsOpened && styles.eventOpened)}
        data-role={dataRole}
      >
        <BackHeaderCommon
          containerClassName={styles.container}
          Title={() => (
            <Box flex="1 1 100%" align="center">
              {title && (
                <AText
                  data-role={dataRole && `${dataRole}-title`}
                  className={styles.title}
                  breed="BU"
                  size="m"
                  color="line_header_txt"
                >
                  {title}
                </AText>
              )}
              {eventsFromOneTournament.length > 0 && (
                <Box
                  className={styles.expandIcon}
                  onClick={() => setIsEventsOpened((prevState) => !prevState)}
                >
                  <Icon
                    name={`icons/general/nav/${
                      isEventsOpened ? 'collapse' : 'expand'
                    }`}
                    color="line_header_txt"
                    size="xs"
                  />
                </Box>
              )}
            </Box>
          )}
          arrowBackIcon={
            <Icon
              dataRole={dataRole && `${dataRole}-back`}
              name="icons/general/nav/back"
              color="line_header_txt"
              size="s"
            />
          }
          onBackClick={goBack}
          isPrimary={isPrimary}
          sportId={sportId}
        >
          {children}
        </BackHeaderCommon>
        {isEventsOpened && (
          <div className={styles.eventsList} ref={containerRef}>
            {/* TODO: SPORTSBOOK REFACTOR need use only eventId */}
            {eventsFromOneTournament.map((eventData) => {
              const { eventId: eId } = eventData;

              return (
                <div key={eId} className={styles.eventContainer}>
                  <Event event={eventData} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default BackHeader;
