import { useContext, useMemo } from 'react';
import { useI18n } from '@namespace/i18n';
import { isEmpty } from 'lodash';
import { USER_ROLES, UserContext } from '@namespace/user';
import {
  useGetIsRecommendedGamesLoaded,
  useGetRecommendedGamesIds,
  useGamesState
} from './selectors';
import {
  RECOMMENDED_CATEGORY,
  SECTION_TYPES,
  LOBBY_RECOMMENDED_GAMES_AMOUNT
} from '../../constants';

export const useRecommendedGamesCategory = ({
  feedName,
  suggesterServiceEntityName,
  showRecommendedCategory,
  maxGamesAmount = LOBBY_RECOMMENDED_GAMES_AMOUNT,
  isViewAllEnabled = true
}) => {
  const { t } = useI18n();
  const [user] = useContext(UserContext);
  const { role } = user;
  const { games } = useGamesState();
  const isRecommendedGamesLoaded = useGetIsRecommendedGamesLoaded(
    suggesterServiceEntityName
  );

  const recommendedGamesIds = useGetRecommendedGamesIds(
    suggesterServiceEntityName,
    feedName
  );
  const actualRecommendedGames = useMemo(
    () => recommendedGamesIds.map((gameId) => games.data[gameId]),
    [games.data, recommendedGamesIds]
  );

  if (
    !showRecommendedCategory ||
    role !== USER_ROLES.USER ||
    (isRecommendedGamesLoaded && isEmpty(actualRecommendedGames))
  )
    return null;

  return {
    id: RECOMMENDED_CATEGORY.id,
    type: SECTION_TYPES.CATEGORY_RECOMMENDED,
    title: t(RECOMMENDED_CATEGORY.title),
    categoryGames: actualRecommendedGames,
    isCategoryLoading: !isRecommendedGamesLoaded,
    isViewAllEnabled,
    maxGamesAmount
  };
};
