import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AlertNotification, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import getFormError from '../../utils/getFormError';
import { FORM_ERRORS_TYPES } from '../../constants';

const GlobalError = () => {
  const { t } = useI18n();
  const [prevFormValues, setPrevFormValues] = useState(null);
  const methods = useFormContext();
  const {
    formState: { errors },
    clearErrors,
    getValues
  } = methods;

  const globalError =
    getFormError(FORM_ERRORS_TYPES.GLOBAL_ERROR, errors, t) || '';

  const formValues = JSON.stringify(getValues() || {});

  useEffect(() => {
    if (globalError) {
      if (prevFormValues && prevFormValues !== formValues) {
        clearErrors();
      }
      setPrevFormValues(formValues);
    } else {
      setPrevFormValues(null);
    }
  }, [globalError, formValues]);

  if (!globalError) {
    return null;
  }

  return (
    <Box margin="m 0">
      <AlertNotification message={globalError} type="error" />
    </Box>
  );
};

export default GlobalError;
