import { deposit } from '../../../api/balance';
import { types } from '../types';

export const actions = {
  [types.DEPOSIT]: (dispatch) => async (data) => {
    const resultData = await deposit(data);

    dispatch({
      type: types.SET_RESULT,
      payload: { resultData }
    });

    return resultData;
  }
};
