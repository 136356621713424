import { useContext, useEffect } from 'react';
import { socketSubscribe, socketUnsubscribe } from '@namespace/socket';
import { useOtherSettings } from '@namespace/cms';
import { PWB_NOTIFICATION_TYPES } from '../../constants';
import { BonusesContext } from '../../store/context';

export const useListenPWBNotifications = () => {
  const [, { SET_PWB_NOTIFICATIONS }] = useContext(BonusesContext);
  const { isPreWagerBonusesEnabled = false } = useOtherSettings();

  useEffect(() => {
    if (isPreWagerBonusesEnabled) {
      socketSubscribe({
        name: PWB_NOTIFICATION_TYPES.PROPOSED,
        types: [PWB_NOTIFICATION_TYPES.PROPOSED],
        callback: ({ data, event }) =>
          SET_PWB_NOTIFICATIONS({ type: event, data })
      });

      socketSubscribe({
        name: PWB_NOTIFICATION_TYPES.WAGERED,
        types: [PWB_NOTIFICATION_TYPES.WAGERED],
        callback: ({ data, event }) =>
          SET_PWB_NOTIFICATIONS({ type: event, data })
      });
    }

    return () => {
      socketUnsubscribe('preWagerBonusProposed');
      socketUnsubscribe('preWagerBonusWagered');
    };
  }, [SET_PWB_NOTIFICATIONS]);
};
