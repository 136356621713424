import { isEqual } from 'lodash';
import { checkPassword } from '../../api';
import createAsyncValidator from '../../core/createAsyncValidator';

const passwordCompareAndCheckAsync = ({ compareFn, email }) =>
  createAsyncValidator(async (value) => {
    const isSame = isEqual(value, compareFn());

    if (isSame) {
      const message =
        'personalOffice.profile.changePassword.newCantBeAsCurrent';
      const error = new Error(message);

      error.displayMessage = message;

      throw error;
    }

    const result = await checkPassword({ email, password: value });

    return result;
  });

export default passwordCompareAndCheckAsync;
