const formatWinnerBrackets = (bracket, teams) => {
  let newBracket = [];
  const winners = bracket.map((match) => {
    const team = match.teams.find((teamId) => teams[teamId]?.win);
    return team || '';
  });

  for (let i = 0; i < winners.length; i += 2) {
    newBracket = [...newBracket, [winners[i], winners[i + 1]]];
  }

  return newBracket;
};

export default formatWinnerBrackets;
