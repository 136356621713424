export const normalizeWagerBonuses = (bonuses) =>
  bonuses?.sort(
    (a, b) =>
      new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime()
  );

export const normalizeBonusSystemWager = ({
  wageredPercent,
  wageredAmount,
  amountForWager,
  campaignId,
  campaignName
}) => ({
  name: campaignName,
  campaignId,
  outputMaxTotal: amountForWager,
  outputBetTotal: wageredAmount,
  wageredPercent
});
