import { useEffect, useContext } from 'react';
import useNotifications from '@namespace/notifications/src/hooks/useNotifications';
import { NOTIFICATION_TYPES } from '@namespace/notifications/src/constants';
import { UserContext } from '../../context';

const useAddictedUser = () => {
  const { customNotification } = useNotifications();
  const [user] = useContext(UserContext);
  const { accountStatuses = {} } = user;
  const { isAddictedUser } = accountStatuses;

  useEffect(() => {
    if (isAddictedUser) {
      customNotification({
        notificationType: NOTIFICATION_TYPES.ADDICTED_USER,
        timeout: null
      });
    }
  }, [isAddictedUser]);
};

export default useAddictedUser;
