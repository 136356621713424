/* eslint-disable no-restricted-globals */

// This is actually a half-working polyfill, it's intended only to allow opening our site on ios12. DOL NOT rely on it!

// source: https://github.com/WICG/visual-viewport/blob/gh-pages/polyfill/visualViewport.js

// This is hacky but necessary in order to get the innerWidth/Height without
// page scale applied reliably.
function updateUnscaledDimensions() {
  if (!window.viewPolyfill.iframeDummy) {
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.left = '0px';
    iframe.style.top = '0px';
    iframe.style.border = '0';
    iframe.style.visibility = 'hidden';
    iframe.style.zIndex = '-1';
    iframe.srcdoc =
      '<!DOCTYPE html><html><body style="margin:0px; padding:0px"></body></html>';

    document.body.appendChild(iframe);
    window.viewPolyfill.iframeDummy = iframe;
  }

  const iframe = window.viewPolyfill.iframeDummy;

  const documentRect = document.documentElement.getBoundingClientRect();
  const iframeBody = iframe.contentDocument.body;
  iframeBody.style.width = `${documentRect.width}px`;
  iframeBody.style.height = `${documentRect.height}px`;

  // Hide overflow temporarily so that the iframe size isn't shrunk by
  // scrollbars.
  const prevDocumentOverflow = document.documentElement.style.overflow;
  document.documentElement.style.overflow = 'hidden';

  const iframeWindow = window.viewPolyfill.iframeDummy.contentWindow;
  window.viewPolyfill.unscaledInnerWidth = iframeWindow.innerWidth;
  window.viewPolyfill.unscaledInnerHeight = iframeWindow.innerHeight;

  document.documentElement.style.overflow = prevDocumentOverflow;
}

function fireScrollEvent() {
  const listeners = window.viewPolyfill.scrollEventListeners;
  for (let i = 0; i < listeners.length; i++) {
    listeners[i]();
  }
}

function fireResizeEvent() {
  const listeners = window.viewPolyfill.resizeEventListeners;
  for (let i = 0; i < listeners.length; i++) {
    listeners[i]();
  }
}

function updateViewportChanged() {
  const scrollChanged =
    window.viewPolyfill.offsetLeftSinceLastChange !==
      window.visualViewport.offsetLeft ||
    window.viewPolyfill.offsetTopSinceLastChange !==
      window.visualViewport.offsetTop;

  const sizeChanged =
    window.viewPolyfill.widthSinceLastChange !== window.visualViewport.width ||
    window.viewPolyfill.heightSinceLastChange !==
      window.visualViewport.height ||
    window.viewPolyfill.scaleSinceLastChange !== window.visualViewport.scale;

  window.viewPolyfill.offsetLeftSinceLastChange =
    window.visualViewport.offsetLeft;
  window.viewPolyfill.offsetTopSinceLastChange =
    window.visualViewport.offsetTop;
  window.viewPolyfill.widthSinceLastChange = window.visualViewport.width;
  window.viewPolyfill.heightSinceLastChange = window.visualViewport.height;
  window.viewPolyfill.scaleSinceLastChange = window.visualViewport.scale;

  if (scrollChanged) {
    fireScrollEvent();
  }

  if (sizeChanged) {
    fireResizeEvent();
  }

  setTimeout(updateViewportChanged, 500);
}

function registerChangeHandlers() {
  window.addEventListener('scroll', updateViewportChanged, { passive: true });
  window.addEventListener('resize', updateViewportChanged, { passive: true });
  window.addEventListener('resize', updateUnscaledDimensions, {
    passive: true
  });
}

const isChrome = navigator.userAgent.indexOf('Chrome') > -1;
let isSafari = navigator.userAgent.indexOf('Safari') > -1;
const isIEEdge = navigator.userAgent.indexOf('Edge') > -1;

if (isChrome && isSafari) {
  isSafari = false;
}

if (window.visualViewport) {
  console.log('Using real visual viewport API');
} else {
  console.log('Polyfilling Viewport API');
  const layoutDummy = document.createElement('div');
  layoutDummy.style.width = '100%';
  layoutDummy.style.height = '100%';
  if (isSafari) {
    layoutDummy.style.position = 'fixed';
  } else {
    layoutDummy.style.position = 'absolute';
  }
  layoutDummy.style.left = '0px';
  layoutDummy.style.top = '0px';
  layoutDummy.style.visibility = 'hidden';

  window.viewPolyfill = {
    offsetLeftSinceLastChange: null,
    offsetTopSinceLastChange: null,
    widthSinceLastChange: null,
    heightSinceLastChange: null,
    scaleSinceLastChange: null,
    scrollEventListeners: [],
    resizeEventListeners: [],
    layoutDummy,
    iframeDummy: null,
    unscaledInnerWidth: 0,
    unscaledInnerHeight: 0
  };

  registerChangeHandlers();

  // TODO: Need to wait for <body> to be loaded but this is probably
  // later than needed.
  window.addEventListener('load', function onload() {
    updateUnscaledDimensions();
    document.body.appendChild(layoutDummy);

    const viewport = {
      get offsetLeft() {
        if (isSafari) {
          // Note: Safari's getBoundingClientRect left/top is wrong when pinch-zoomed requiring this "unscaling".
          return (
            window.scrollX -
            (layoutDummy.getBoundingClientRect().left * this.scale +
              window.scrollX * this.scale)
          );
        }
        return window.scrollX + layoutDummy.getBoundingClientRect().left;
      },
      get offsetTop() {
        if (isSafari) {
          // Note: Safari's getBoundingClientRect left/top is wrong when pinch-zoomed requiring this "unscaling".
          return (
            window.scrollY -
            (layoutDummy.getBoundingClientRect().top * this.scale +
              window.scrollY * this.scale)
          );
        }
        return window.scrollY + layoutDummy.getBoundingClientRect().top;
      },
      get width() {
        let { clientWidth } = document.documentElement;
        if (isIEEdge) {
          // If there's no scrollbar before pinch-zooming, Edge will add
          // a non-layout-affecting overlay scrollbar. This won't be
          // reflected in documentElement.clientWidth, so we need to
          // manually subtract it out.
          if (
            document.documentElement.clientWidth ===
              window.viewPolyfill.unscaledInnerWidth &&
            this.scale > 1
          ) {
            const oldWidth = document.documentElement.clientWidth;
            const prevHeight = layoutDummy.style.height;
            // Lengthen the dummy to add a layout vertical scrollbar.
            layoutDummy.style.height = '200%';
            const scrollbarWidth =
              oldWidth - document.documentElement.clientWidth;
            layoutDummy.style.width = prevHeight;
            clientWidth -= scrollbarWidth;
          }
        }
        return clientWidth / this.scale;
      },
      get height() {
        let { clientHeight } = document.documentElement;
        if (isIEEdge) {
          // If there's no scrollbar before pinch-zooming, Edge will add
          // a non-layout-affecting overlay scrollbar. This won't be
          // reflected in documentElement.clientHeight so we need to
          // manually subtract it out.
          if (
            document.documentElement.clientHeight ===
              window.viewPolyfill.unscaledInnerHeight &&
            this.scale > 1
          ) {
            const oldHeight = document.documentElement.clientHeight;
            const prevWidth = layoutDummy.style.width;
            // Widen the dummy to add a layout horizontal scrollbar.
            layoutDummy.style.width = '200%';
            const scrollbarHeight =
              oldHeight - document.documentElement.clientHeight;
            layoutDummy.style.width = prevWidth;
            clientHeight -= scrollbarHeight;
          }
        }
        return clientHeight / this.scale;
      },
      get scale() {
        return window.viewPolyfill.unscaledInnerWidth / window.innerWidth;
      },
      get pageLeft() {
        return window.scrollX;
      },
      get pageTop() {
        return window.scrollY;
      },
      addEventListener(name, func) {
        // TODO: Match event listener semantics. i.e. can't add the same callback twice.
        if (name === 'scroll') {
          window.viewPolyfill.scrollEventListeners.push(func);
        } else if (name === 'resize') {
          window.viewPolyfill.resizeEventListeners.push(func);
        }
      },

      removeEventListener(name, func) {
        let eventListeners;
        if (name === 'scroll') {
          eventListeners = window.viewPolyfill.scrollEventListeners;
        } else if (name === 'resize') {
          eventListeners = window.viewPolyfill.resizeEventListeners;
        } else {
          return;
        }

        const index = eventListeners.indexOf(func);
        if (index !== -1) {
          eventListeners.splice(index, 1);
        }
      }
    };

    window.visualViewport = viewport;
  });
}
