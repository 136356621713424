import { types } from './reducer';

export default {
  [types.SET_IS_NATIVE_APP]: (dispatch) => (value) => {
    dispatch({
      type: types.SET_IS_NATIVE_APP,
      payload: value
    });
  },
  [types.SET_NATIVE_APP_CONFIG]: (dispatch) => (value) => {
    dispatch({
      type: types.SET_NATIVE_APP_CONFIG,
      payload: value
    });
  },
  [types.SET_ANDROID_LINK]: (dispatch) => (androidLink) => {
    dispatch({
      type: types.SET_ANDROID_LINK,
      payload: { androidLink }
    });
  },
  [types.SET_IOS_LINK]: (dispatch) => (iosLink) => {
    dispatch({
      type: types.SET_IOS_LINK,
      payload: { iosLink }
    });
  }
};
