import { useCallback, useMemo, memo } from 'react';
import { Icon } from '@namespace/icons2';
import { useI18n } from '@namespace/i18n';
import { AText, Box } from '@alf/uikit';
import {
  PREDICTIONS_STEPS_IDS,
  PREDICTIONS_GROUP_SELECTED_QUANTITY
} from '../../constants';
import styles from './index.module.css';
import ParticipantFlag from '../ParticipantFlag';

const { TOP_OF_THIRD, GROUPS } = PREDICTIONS_STEPS_IDS;

const Predictions = ({ group, type, onChange, teams, isDisabledStep }) => {
  const { t } = useI18n();
  const checked = useMemo(
    () =>
      group.teams
        .filter((teamId) => teams[teamId]?.index || teams[teamId]?.win)
        .sort((a, b) => teams[a]?.index - teams[b]?.index),
    [group, teams]
  );
  const groupQuantity = PREDICTIONS_GROUP_SELECTED_QUANTITY[type];

  const isGroup = useMemo(() => type === TOP_OF_THIRD || type === GROUPS, [
    type
  ]);

  const handleChange = useCallback(
    (e, item) => {
      const currentCheckedItem = checked.find((el) => el === item);
      let newChecked = [];
      if (
        currentCheckedItem ||
        (checked.length + 1 > groupQuantity && isGroup)
      ) {
        newChecked = [];
      } else if (isGroup) {
        newChecked = [...checked, item];
        if (newChecked.length === groupQuantity && type === GROUPS) {
          const lastTeam = group.teams.find(
            (team) => !newChecked.includes(team)
          );
          newChecked = [...newChecked, lastTeam];
        }
      } else {
        newChecked = [item];
      }

      onChange(
        !currentCheckedItem && !isGroup
          ? [...newChecked, checked[0]]
          : newChecked
      );
    },
    [checked, groupQuantity, isGroup, onChange, type, group.teams]
  );

  return (
    <Box direction="column" className={`${styles.predictions}`}>
      <Box direction="column" align="start" margin="s">
        <AText breed="BU" size="2xl" color="txt_color_1">
          {t(`${group.title}`)}
        </AText>
        <AText
          breed="BU"
          size="m"
          color="txt_color_7"
          className={styles.subtitle}
        >
          {t(`${group.subtitle}`)}
        </AText>
      </Box>
      {group.teams.map((item) => {
        const flagIcon =
          item === 'North Macedonia' ? 'Republic Of Macedonia' : item;

        return (
          <label
            htmlFor={item}
            className={`${styles.predictionsItem} ${
              isDisabledStep ? styles.predictionsItemDisabled : ''
            }`}
            key={item}
          >
            <Box align="center" margin="m 0">
              <Box className={styles.flagWrapper}>
                <ParticipantFlag
                  country={flagIcon}
                  className={`${styles.countryFlag}`}
                />
              </Box>
              <AText breed="BU" size="xl" color="txt_color_1">
                {t(
                  `predictions.countries.name.${item
                    .toLowerCase()
                    .replace(/\s/g, '')}`
                )}
              </AText>
            </Box>
            <Box align="center">
              <input
                type="checkbox"
                disabled={isDisabledStep}
                className={styles.predictionsCheckbox}
                id={item}
                checked={Boolean(checked.find((el) => el === item))}
                value={item}
                onChange={(event) => handleChange(event, item)}
              />
              <Box align="center" justify="center" className={styles.circle}>
                <AText
                  breed="R"
                  size="xl"
                  color="txt_color_4"
                  className={styles.icon}
                >
                  {type === PREDICTIONS_STEPS_IDS.GROUPS ? (
                    checked.findIndex((el) => el === item) + 1
                  ) : (
                    <Icon name="icons/general/nav/arrow-checked" size="xs" />
                  )}
                </AText>
              </Box>
            </Box>
          </label>
        );
      })}
    </Box>
  );
};

export default memo(Predictions);
