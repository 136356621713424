import '@namespace/common'; // !!!don't touch it fix loop deps
import { SnippetsProvider } from '@namespace/snippets';
import { WidgetProvider } from '@namespace/widget';
import { Onboarding } from '@namespace/onboarding';
import { UserProvider } from '@namespace/user';
import { ThemesProvider } from '@namespace/themes';
import CSSContainer from '../CSSContainer';
import Content from './Content';
import { ComponentProviders } from './ComponentProviders';

const App = ({
  children,
  providersMap = {},
  FALLBACK_SITE_SETTINGS,
  FALLBACK_SNIPPETS,
  CSS_THEME,
  DEFAULT_COLOR_SCHEME,
  AVAILABLE_COLOR_SCHEMES,
  className,
  snippets,
  colorScheme,
  themeConfig,
  themesImagesSet,
  themesIconsSet,
  componentsConfig
}) => (
  <WidgetProvider>
    <SnippetsProvider FALLBACK_SNIPPETS={FALLBACK_SNIPPETS} snippets={snippets}>
      <UserProvider>
        <ThemesProvider
          colorScheme={colorScheme}
          themeConfig={themeConfig}
          componentsConfig={componentsConfig}
          themesImagesSet={themesImagesSet}
          themesIconsSet={themesIconsSet}
        >
          <ComponentProviders providersMap={providersMap}>
            <Content FALLBACK_SITE_SETTINGS={FALLBACK_SITE_SETTINGS}>
              <CSSContainer
                CSS_THEME={CSS_THEME}
                DEFAULT_COLOR_SCHEME={DEFAULT_COLOR_SCHEME}
                AVAILABLE_COLOR_SCHEMES={AVAILABLE_COLOR_SCHEMES}
                className={className}
              >
                {children}
                <Onboarding />
              </CSSContainer>
            </Content>
          </ComponentProviders>
        </ThemesProvider>
      </UserProvider>
    </SnippetsProvider>
  </WidgetProvider>
);

export default App;
