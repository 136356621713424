import { PrivateContentLoader } from '@namespace/user';
import FavoriteEvents from './FavoriteEvents';
import { MODE } from '../../constants';
import styles from './index.module.css';

const LiveFavoriteEvents = ({ id }) => (
  <PrivateContentLoader classNames={styles.userLoader}>
    <FavoriteEvents id={id} serviceId={MODE.IN_PLAY} isLiveEvents={true} />
  </PrivateContentLoader>
);

export default LiveFavoriteEvents;
