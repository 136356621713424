import { always, filter, ifElse, isNil, map, pipe, prop, uniq } from 'ramda';
import { shallow } from 'zustand/shallow';
import { createSelector } from 'reselect';
import { PROMOTION_ITEM_TYPES } from '../../../constants';
import { checkIsActivePromo } from '../../../utils';
import { usePromotionsSelector } from '../../store';

export const selectActiveTournamentsIds = () =>
  createSelector(
    prop('promotions'),
    ifElse(
      isNil,
      always([]),
      pipe(
        filter(
          (promotion) =>
            promotion.type === PROMOTION_ITEM_TYPES.TOURNAMENT &&
            promotion.tournamentId &&
            promotion.enabled &&
            checkIsActivePromo(promotion.showFrom, promotion.showTo, false)
        ),
        map(prop('tournamentId')),
        uniq
      )
    )
  );

export const useGetActiveTournamentsIds = () =>
  usePromotionsSelector(selectActiveTournamentsIds, [], shallow);
