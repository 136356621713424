import { useMemo, memo } from 'react';
import { useI18n } from '@namespace/i18n';
import {
  getDetailedScoresForTable,
  getEventValues,
  stripeScore,
  getResultTypeScoreValues
} from '../../utils';
import {
  SPORTS_WITH_ALL_PERIODS,
  TOTAL_SCOPE_ID_BY_SPORT,
  TOTAL_SYMBOL_BY_SPORT,
  SCOPE_IDS_FOR_PERIODS
} from '../../constants';
import { COLUMN_TYPES, SCOPE_IDS } from '../../../../constants';
import ScoreDetails from '../../Details';

const Tennis = ({
  sportId,
  eventResultId,
  byResultType,
  total,
  current,
  isShortVersion,
  lineView
}) => {
  const { t } = useI18n();

  const formattedScoreByPeriod = useMemo(
    () =>
      stripeScore(
        getDetailedScoresForTable(byResultType, SCOPE_IDS.GAME),
        sportId,
        eventResultId,
        SPORTS_WITH_ALL_PERIODS.includes(sportId)
      ),
    [byResultType, eventResultId, sportId]
  );

  const scoreByPeriod = useMemo(
    () =>
      isShortVersion
        ? [
            {
              type: COLUMN_TYPES.DEFAULT,
              tag: '',
              scores: getResultTypeScoreValues(
                byResultType,
                eventResultId,
                SCOPE_IDS_FOR_PERIODS[sportId] ||
                  TOTAL_SCOPE_ID_BY_SPORT[sportId]
              )
            }
          ]
        : [...formattedScoreByPeriod],
    [
      byResultType,
      eventResultId,
      formattedScoreByPeriod,
      isShortVersion,
      sportId
    ]
  );

  const fullFormattedScore = useMemo(
    () => [
      {
        type: COLUMN_TYPES.POINTS,
        tag: '',
        scores: getEventValues(current, SCOPE_IDS.POINTS)
      },
      {
        type: COLUMN_TYPES.TOTAL,
        tag: TOTAL_SYMBOL_BY_SPORT[sportId]
          ? t(TOTAL_SYMBOL_BY_SPORT[sportId])
          : t(TOTAL_SYMBOL_BY_SPORT.default),
        scores: getEventValues(total, TOTAL_SCOPE_ID_BY_SPORT[sportId])
      },
      ...scoreByPeriod
    ],
    [current, scoreByPeriod, sportId, t, total]
  );

  return (
    <ScoreDetails
      scoreData={fullFormattedScore}
      isShortVersion={isShortVersion}
      lineView={lineView}
    />
  );
};

export default memo(Tennis);
