import { useMemo } from 'react';
import useOtherSettings from '@namespace/cms/src/hooks/useOtherSettings';
import { useI18n } from '../useI18n';

const useHiddenLanguageInUrl = () => {
  const otherSettings = useOtherSettings();

  const { hiddenLanguageInUrl } = otherSettings || {};
  const { language } = useI18n();

  return useMemo(() => language === hiddenLanguageInUrl, [
    language,
    hiddenLanguageInUrl
  ]);
};

export default useHiddenLanguageInUrl;
