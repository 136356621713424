import { path, pipe } from 'ramda';
import { useConfig } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { useActiveHeadGroups } from '../../hooks/useActiveHeadGroups';
import { useCyberSportGetPathParams } from '../../hooks/useCyberSportGetPathParams';
import useGetActualCyberSportCategory from '../../hooks/useGetActualCyberSportCategory';
import { LINE_KEY, MODE } from '../../constants';
import {
  EventsLineHeader,
  EventsLineHeadGroups,
  EventsLineTitle,
  LineSport,
  WidgetWrapper
} from '../../common';
import { useSportsbookStore } from '../../store';
import styles from './index.module.css';

const CyberSportLiveCategoryEvents = ({ id }) => {
  const { t } = useI18n();
  const { config } = useConfig(id);
  const { category, activeSport = {} } = useCyberSportGetPathParams();
  const { sportId } = activeSport;
  const { categoryId, categoryName } = category || {};
  const categoryIds = useGetActualCyberSportCategory({
    onlyCategoryId: true
  });
  const lineKey = `${LINE_KEY.CYBERSPORT_LIVE}-${sportId}`;

  const {
    isHeadGroupsSortingEnabled,
    activeHeadGroupsAmount,
    isDisplayHeadMarkets = true
  } = config;
  const [activeHeadGroups, setActiveHeadGroups] = useActiveHeadGroups(
    isHeadGroupsSortingEnabled,
    activeHeadGroupsAmount,
    sportId,
    MODE.IN_PLAY
  );
  const hasEvents = useSportsbookStore(
    pipe(
      path(['lines', lineKey, 'eventsIds', 'length']),
      (length) => length > 0
    )
  );

  if (!sportId) {
    return null;
  }

  return (
    <WidgetWrapper
      className={`${styles.wrapper} ${!hasEvents ? styles.empty : ''}`}
    >
      {hasEvents && (
        <EventsLineHeader borderBottom={true} isCollapsible={false}>
          <EventsLineTitle
            title={categoryName || t('sportsBook.menu.cyberSport')}
            sportId={sportId}
            categoryId={categoryId}
            subtitle="Live"
            classNames={{
              subtitleClassName: styles.label
            }}
          />
          <EventsLineHeadGroups
            sport={{ sportId }}
            serviceId={MODE.IN_PLAY}
            isHeadGroupsSortingEnabled={true}
            activeHeadGroups={activeHeadGroups}
            setActiveHeadGroups={setActiveHeadGroups}
            isDisplayHeadMarkets={isDisplayHeadMarkets}
          />
        </EventsLineHeader>
      )}
      <LineSport
        id={id}
        activeHeadGroups={activeHeadGroups}
        sport={activeSport}
        categoryIds={categoryIds}
        lineKey={lineKey}
        serviceId={MODE.IN_PLAY}
        isExpanded={false}
        isShowFlag={false}
        isShowEmpty={false}
        isLiveEvents={true}
        isReady={hasEvents}
      />
    </WidgetWrapper>
  );
};

export default CyberSportLiveCategoryEvents;
