import { forwardRef } from 'react';
import { Link, NavLink, useLocation, useMatch } from 'react-router-dom';
import { addSlashToPathEnd } from '@namespace/helpers';
import { isString, isFunction } from 'lodash';
import useHiddenLanguageInUrl from '../hooks/useHiddenLanguageInUrl';
import { useI18n } from '../hooks/useI18n';

const buildLink = (language, path = '', isHiddenLanguageInUrl) => {
  if (isHiddenLanguageInUrl) {
    return addSlashToPathEnd(path);
  }
  const langSeparator = path.indexOf('/') === 0;
  const checkedPath = `/${language}${!langSeparator ? '/' : ''}${path}`;
  return addSlashToPathEnd(checkedPath);
};

const LocalizedLink = forwardRef(
  (
    {
      to = '',
      isActive: isActiveProp,
      activeClassName = '',
      dataRole,
      ...rest
    },
    ref
  ) => {
    const { language } = useI18n();
    const location = useLocation();
    const isHiddenLanguageInUrl = useHiddenLanguageInUrl();
    const match = useMatch(to);

    const isActive = isActiveProp ? isActiveProp(match, location) : !!match;

    const modRest = { 'data-role': dataRole, ...rest, ref };

    let modifiedTo;
    if (isString(to)) {
      if (to.includes('*withoutLang')) {
        const [link] = to.split('*');
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        return <a href={link} {...modRest} />;
      }

      if (
        to.includes('https') ||
        to.includes('http') ||
        to.includes('mailto:')
      ) {
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        return <a href={to} {...modRest} />;
      }
      modifiedTo = buildLink(language, to, isHiddenLanguageInUrl);
    } else if (isFunction(to)) {
      modifiedTo = (l) =>
        to({
          ...l,
          pathname: buildLink(
            language,
            location.pathname,
            isHiddenLanguageInUrl
          )
        });
    } else {
      modifiedTo = {
        ...to,
        pathname: to
          ? buildLink(language, to.pathname, isHiddenLanguageInUrl)
          : '/'
      };
    }

    const validTo = isString(modifiedTo) ? modifiedTo : modifiedTo.pathname;

    if (isActive || activeClassName) {
      const { className, ...restProps } = modRest;

      return (
        <NavLink
          to={validTo}
          state={modifiedTo.state}
          className={(p) => {
            const styleIsActive = isActiveProp ? isActive : p.isActive;

            return `${className} ${styleIsActive ? activeClassName : ''}`;
          }}
          {...restProps}
        />
      );
    }

    return <Link to={validTo} state={modifiedTo.state} {...modRest} />;
  }
);

export default LocalizedLink;
