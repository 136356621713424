import { Box, AText } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';

import typingIcon from './icon.svg';
import styles from './index.module.css';

const IsTyping = ({ agentName }) => {
  const { t } = useI18n();

  return (
    <Box direction="row" className={styles.isTyping} margin="xs">
      <Box margin="0 xs 0 0">
        <img src={typingIcon} alt="" />
      </Box>
      <AText breed="R" size="xs" color="txt_color_7">
        {t('support.chat.is-typing', [agentName])}
      </AText>
    </Box>
  );
};

export default IsTyping;
