import {
  concat,
  equals,
  filter,
  flip,
  includes,
  isNil,
  map,
  pipe,
  prop,
  propEq,
  propSatisfies,
  reject,
  uniq,
  where,
  without
} from 'ramda';
import { setFilterItemStatusActive } from './setFilterItemStatusActive';

export const updateFilterWithNewTournaments = (
  selectedFilter,
  newTournamentIds,
  sportId,
  { advFilterTournamentsById }
) => {
  let result = selectedFilter;

  const oldTournamentIds = pipe(
    filter(propEq('sportId', sportId)),
    map(prop('tournamentId')),
    reject(isNil),
    uniq
  )(selectedFilter);
  const addedTournamentIds = without(oldTournamentIds, newTournamentIds);
  const removedTournamentIds = without(newTournamentIds, oldTournamentIds);

  if (addedTournamentIds.length > 0) {
    const addedTournamentCategoryIds = pipe(
      map((tId) => advFilterTournamentsById[tId]),
      map(prop('categoryId'))
    )(addedTournamentIds);

    result = pipe(
      // unlikely, but we remove current sport item since we add new tournaments
      reject(
        where({
          sportId: equals(sportId),
          categoryId: isNil,
          tournamentId: isNil
        })
      ),
      // remove all categories of added tournaments
      reject(
        where({
          categoryId: flip(includes)(addedTournamentCategoryIds),
          tournamentId: isNil
        })
      ),
      // remove all items with same tournamentIds
      // prettier-ignore
      reject(propSatisfies(
        flip(includes)(addedTournamentIds),
        'tournamentId'
      )),
      // add new tournaments to the end
      flip(concat)(
        map((tournamentId) => ({
          sportId,
          categoryId: advFilterTournamentsById[tournamentId].categoryId,
          tournamentId
        }))(addedTournamentIds)
      )
    )(result);
  }

  if (removedTournamentIds.length > 0) {
    const removedTournamentCategoryIds = pipe(
      map((tId) => advFilterTournamentsById[tId]),
      map(prop('categoryId')),
      uniq
    )(removedTournamentIds);

    // remove all items with same tournamentIds
    // prettier-ignore
    const filterWithoutRemovedTournaments = reject(propSatisfies(
      flip(includes)(removedTournamentIds),
      'tournamentId'
    ))(result);

    // add categories of removed tournaments if items with such categoryIds don't exist
    const currentCategoryIds = filterWithoutRemovedTournaments.map(
      prop('categoryId')
    );
    const categoryIdsToBeAdded = reject((cId) =>
      currentCategoryIds.includes(cId)
    )(removedTournamentCategoryIds);

    result = concat(
      filterWithoutRemovedTournaments,
      map((categoryId) => ({
        sportId,
        categoryId
      }))(categoryIdsToBeAdded)
    );
  }

  return result.map(setFilterItemStatusActive);
};
