const normalizeBets = (data) =>
  data.map((item) => {
    const { move, amount, currency } = item;
    const money = `${amount} ${currency}`;
    return {
      ...item,
      amount: move === 1 ? money : '',
      payout: move === 0 ? money : '\u2014'
    };
  });

export default normalizeBets;
