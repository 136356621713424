import { memo } from 'react';
import { Box, AText } from '@alf/uikit';
import styles from './index.module.css';

const Winnings = ({ amount, text, currency }) => {
  return (
    <Box
      align="center"
      justify="center"
      direction="column"
      className={styles.winningWrapper}
    >
      <Box className={styles.winningAmountBlock}>
        <AText breed="BU" size="4xl" color="txt_color_4">
          {Number(amount) >= 0 ? '+' : ''}
          {amount}
        </AText>
        <AText
          breed="R"
          size="xl"
          color="txt_color_4"
          className={styles.winningCurrency}
        >
          {currency}
        </AText>
      </Box>
      <AText breed="R" size="m" color="txt_color_7">
        {text}
      </AText>
    </Box>
  );
};

export default memo(Winnings);
