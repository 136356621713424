import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@namespace/icons2';
import clsx from 'clsx';
import { ThemeProvider } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';
import { COLOR_SCHEME, useGetThemeConfig } from '@namespace/themes';
import Box from '../Box';
import AText from '../AText';
import { propsMerge } from '../../utils/propsMerge';
import getDefaultMaterialTheme from './theme';
import { FONT_SIZE_INPUT, HEIGHT_INPUT } from './constants';
import styles from './index.module.css';

const MaterialDatePicker = (props) => {
  const dataPickerConfig = useGetThemeConfig('DataPicker');
  const {
    name = '',
    label = '',
    placeholder = '',
    date = null,
    onChange = () => {},
    onBlur = () => {},
    minDate = new Date('1900-01-01'),
    maxDate = new Date('2100-01-01'),
    error = false,
    format = 'yyyy-MM-dd',
    cancelLabel = 'cancel',
    okLabel = 'ok',
    inputClassName = '',
    className = '',
    clearErrors,
    disabled = false,
    iconClassName = '',
    labelClassName = '',
    withIcon = true,
    size = 's',
    focus = false,
    success = false,
    open = false,
    disabledSuccess = false,
    labelSize = 'm',
    variant = 'dialog',
    initialFocusedDate,
    labelFunc,
    shouldDisableDate = () => {},
    openTo = 'date',
    views = ['year', 'month', 'date'],
    intentType = COLOR_SCHEME.PRIMARY,
    setError,
    minDateMessage,
    maxDateMessage,
    dataRole,
    bottomElement = null,
    ...rest
  } = propsMerge(props, dataPickerConfig);
  const [pickerFocus, setFocus] = useState(focus);
  const [pickerSuccess, setSuccess] = useState(success);
  const [pickerTouched, setTouched] = useState(false);
  const [pickerOpen, setOpen] = useState(open);

  const borderStyles = () => {
    if (pickerFocus) {
      return 'focus';
    }

    if (error) {
      return 'error';
    }

    if (pickerSuccess) {
      return 'default';
    }

    return '';
  };

  useEffect(() => {
    if (!success) {
      if (date && !pickerFocus && !error && pickerTouched && !disabledSuccess) {
        setSuccess(true);
      } else {
        setSuccess(false);
      }
    }
  }, [pickerFocus, error, date, pickerTouched, disabledSuccess, success]);

  const isPlaceholder = placeholder && !date;
  const isDialog = variant === 'dialog';
  const dialogProps = isDialog ? { okLabel, cancelLabel } : {};
  const defaultMaterialTheme = getDefaultMaterialTheme({ intentType });
  const intentTypesSecondary = intentType === COLOR_SCHEME.SECONDARY;
  const inputStyles = intentTypesSecondary
    ? {
        color: isPlaceholder
          ? 'var(--input_02_txt_placeholder)'
          : 'var(--input_02_txt)',
        background: disabled
          ? 'var(--input_02_bg_color_2)'
          : 'var(--input_02_bg_color_1)',
        WebkitTextFillColor: isPlaceholder
          ? 'var(--input_02_txt_placeholder)'
          : 'var(--input_02_txt)'
      }
    : {
        color: isPlaceholder
          ? 'var(--input_txt_placeholder)'
          : 'var(--input_txt)',
        background: disabled
          ? 'var(--input_bg_color_2)'
          : 'var(--input_bg_color_1)',
        WebkitTextFillColor: isPlaceholder
          ? 'var(--input_txt_placeholder)'
          : 'var(--input_txt)'
      };
  const iconColor = intentTypesSecondary ? 'input_02_txt' : 'input_txt';
  const disabledIconColor = intentTypesSecondary
    ? 'input_02_txt_placeholder'
    : 'input_txt_placeholder';
  return (
    <Box className={className} direction="column">
      {label && (
        <Box
          direction="column"
          bottom="s"
          className={clsx(styles.label, styles[intentType], labelClassName)}
          data-role={dataRole && `${dataRole}-label`}
        >
          <AText size={labelSize} breed="R">
            {label}
          </AText>
        </Box>
      )}

      <Box
        className={`${styles.datePickerWrapper} ${styles[size]}`}
        data-role={dataRole && `${dataRole}-input`}
      >
        <ThemeProvider theme={defaultMaterialTheme}>
          <DatePicker
            {...rest}
            name={name}
            emptyLabel={placeholder}
            variant={variant}
            format={format}
            open={pickerOpen}
            onOpen={() => {
              setOpen(true);
              setFocus(true);
            }}
            openTo={openTo}
            views={views}
            minDate={minDate}
            className={inputClassName}
            maxDate={maxDate}
            value={date}
            disabled={disabled}
            onClose={() => {
              setOpen(false);
              setFocus(false);
            }}
            initialFocusedDate={initialFocusedDate}
            InputProps={{
              style: {
                fontSize: FONT_SIZE_INPUT[size] || FONT_SIZE_INPUT.s,
                height: HEIGHT_INPUT[size] || HEIGHT_INPUT.s,
                ...inputStyles
              }
            }}
            onChange={(d) => {
              onChange(d);
              setFocus(false);
              setTouched(true);
              setOpen(false);
              error && clearErrors && clearErrors(name);
              setFocus(false);
            }}
            shouldDisableDate={shouldDisableDate}
            onBlur={onBlur}
            labelFunc={labelFunc}
            onError={(errorMessage) =>
              errorMessage &&
              setError &&
              setError(name, 'required', errorMessage)
            }
            {...(minDateMessage ? { minDateMessage } : {})}
            {...(maxDateMessage ? { maxDateMessage } : {})}
            {...dialogProps}
          />
        </ThemeProvider>
        {withIcon && (
          <Icon
            onClick={() => !disabled && setOpen(true)}
            size={size}
            name="icons/general/item/calendar"
            color={isPlaceholder && disabled ? disabledIconColor : iconColor}
            className={clsx(
              styles.icon,
              iconClassName,
              disabled && styles.disabled,
              styles[`s_${size}`]
            )}
          />
        )}
        <Box className={clsx(styles[borderStyles()], styles[intentType])} />
      </Box>
      {bottomElement}
    </Box>
  );
};

MaterialDatePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  iconClassName: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  clearErrors: PropTypes.func,
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
    PropTypes.object
  ]), // it can be any parsable date, e.g. "string", Date, object (from API docs)
  onChange: PropTypes.func,
  setError: PropTypes.func,
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.object
  ]), // it can be any parsable date, e.g. "string", Date, "object" (from API docs)
  maxDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.object
  ]), // it can be any parsable date, e.g. "string", Date, "object" (from API docs)
  format: PropTypes.string,
  cancelLabel: PropTypes.string,
  okLabel: PropTypes.string,
  size: PropTypes.oneOf(['xs', 's', 'm']),
  labelSize: PropTypes.oneOf(['s', 'm']),
  variant: PropTypes.oneOf(['dialog', 'inline']),
  focus: PropTypes.bool,
  success: PropTypes.bool,
  open: PropTypes.bool,
  disabledSuccess: PropTypes.bool,
  intentType: PropTypes.oneOf([COLOR_SCHEME.PRIMARY, COLOR_SCHEME.SECONDARY]),
  minDateMessage: PropTypes.string,
  maxDateMessage: PropTypes.string
};

export default MaterialDatePicker;
