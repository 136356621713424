import { formatGroupTeams } from '../teams';

/*
 * Format groups in GROUP step
 */

export const formatGroups = (groups = {}, isSavedPrediction) => {
  return {
    teams: formatGroupTeams(groups, isSavedPrediction),
    bracket: Object.keys(groups).map((groupId) => {
      return {
        title: `predictions.group.title.${groupId}`,
        subtitle: `predictions.group.subtitle.${groupId}`,
        groupId,
        teams: groups[groupId]
      };
    }),
    isFilled: true
  };
};
