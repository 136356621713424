import { useOnSlideMove } from '@namespace/common';
import { AText } from '@alf/uikit';
import useSportsSettings from '../../../hooks/useSportsSettings';
import { useEvents, useSportsbookStore } from '../../../store';
import { LINE_KEY } from '../../../constants';
import { CardSkelet, EventsSlider } from '../../../common';
import Card from './Card';
import styles from './index.module.css';

const BasePopularEventsCards = ({ sport, wrapperClassName, title }) => {
  const { isShowStatUrl = true } = useSportsSettings();
  const { sportId } = sport || {};
  const lineKey = `${LINE_KEY.POPULAR_BETS}-${sportId}`;
  const eventsIds = useEvents({ lineKey, onlyIds: true });
  const { setIsMove } = useOnSlideMove();

  const popularEventsLoaded = useSportsbookStore('isLoadedPopularEvents');

  if (!popularEventsLoaded) {
    return <CardSkelet className={styles.skeletMargin} />;
  }

  if (!eventsIds.length) {
    return null;
  }

  return (
    <div className={wrapperClassName}>
      {title && (
        <AText
          breed="BU"
          size="xl"
          color="txt_color_1"
          className={styles.title}
        >
          {title}
        </AText>
      )}
      <div className={styles.wrapper}>
        <EventsSlider setIsMove={setIsMove} className={styles.slider}>
          {eventsIds.map((eventId) => (
            <Card
              gap="8px"
              isShowStatUrl={isShowStatUrl}
              key={`pop-bet-${eventId}`}
              eventId={eventId}
              lineKey={lineKey}
              isShowShortName={true}
              isShowFlag={true}
            />
          ))}
        </EventsSlider>
      </div>
    </div>
  );
};

export default BasePopularEventsCards;
