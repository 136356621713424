import { useCallback, useContext, useEffect, useState } from 'react';
import { SiteSettingsContext, useGeneralRoutes } from '@namespace/cms';
import { USER_ROLES, UserContext } from '@namespace/user';
import { useI18n, useLocation } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import {
  IS_WIDGET,
  POST_MESSAGE_TYPES,
  usePostMessage
} from '@namespace/widget';
import { REDIRECT_PARAM } from '@namespace/common';
import { sportsbookActions, useEvent } from '../../store';
import { MODE, ONE_EVENT_HEADER_TABS as TABS } from '../../constants';
import useSportsSettings from '../useSportsSettings';
import { useIsBroadcastEnabledForSport } from '../useIsBroadcastEnabledForSport';

const { FETCH_ACTIVE_EVENT_STREAM } = sportsbookActions;

export const useVideoStream = (eventId, tabState, setTabState) => {
  const event = useEvent(eventId);
  const {
    eventTv,
    eventBroadcastUrl,
    eventStreamUrl = '',
    serviceId,
    sportId
  } = event || {};
  const { pathname } = useLocation();
  const { login } = useGeneralRoutes();
  const [user, userActions] = useContext(UserContext);
  const { role, id: userId, countryId } = user;
  const { CHECK_IS_VIDEO_AVAILABLE } = userActions;
  const [settings] = useContext(SiteSettingsContext);
  const { siteConfig = {} } = settings;
  const { partnerId } = siteConfig;
  const { isShowVideo: isShowLiveBroadcast = true } = useSportsSettings();
  const { push } = useHistory();
  const isLive = serviceId === MODE.IN_PLAY;
  const { language: lang, timezone } = useI18n();
  const [errorMessage, setErrorMessage] = useState('');
  const { postMessage } = usePostMessage();
  const isAvailableForCountry =
    IS_WIDGET || Boolean(eventTv?.countries?.includes(countryId));
  const isVideoFree = Boolean(eventBroadcastUrl || eventTv?.isFta);
  const isBroadcastEnabled = useIsBroadcastEnabledForSport(sportId);
  const isShowVideo =
    isShowLiveBroadcast &&
    isLive &&
    ((isBroadcastEnabled && Boolean(eventBroadcastUrl)) ||
      eventTv?.countries?.length > 0);
  let encodedBroadCastUrl = null;
  if (eventBroadcastUrl) {
    try {
      const url = new URL(decodeURIComponent(eventBroadcastUrl));
      encodedBroadCastUrl = url?.href || null;
    } catch (e) {
      console.warn(e);
    }
  }

  const expandVideo = useCallback(async () => {
    if (tabState.type === TABS.VIDEO) {
      setTabState({ type: null, opened: false });
      return null;
    }

    if (role !== USER_ROLES.USER) {
      if (IS_WIDGET) {
        return postMessage(POST_MESSAGE_TYPES.LOGIN, '');
      }
      return push(`${login}?${REDIRECT_PARAM}${pathname}`);
    }

    if (!eventBroadcastUrl) {
      if (!isAvailableForCountry) {
        setErrorMessage('sportsbook.events.stream.noCountry');
      } else if (!isVideoFree && !IS_WIDGET) {
        try {
          const isPaidStreamAvailable = await CHECK_IS_VIDEO_AVAILABLE(userId);
          if (!isPaidStreamAvailable) {
            setErrorMessage('sportsbook.events.stream.paidStream');
          }
        } catch ({ errorCode }) {
          setErrorMessage(errorCode);
        }
      }
    }

    if (isVideoFree || !errorMessage) {
      setTabState({ type: TABS.VIDEO, opened: true });
    }

    return null;
  }, [
    tabState.type,
    setTabState,
    role,
    eventBroadcastUrl,
    userId,
    isAvailableForCountry,
    isVideoFree,
    errorMessage,
    push
  ]);

  useEffect(() => {
    if (isShowVideo && !eventStreamUrl && !errorMessage && !eventBroadcastUrl) {
      FETCH_ACTIVE_EVENT_STREAM({ eventId, partnerId, lang });
    }
  }, [
    isShowVideo,
    eventStreamUrl,
    errorMessage,
    eventBroadcastUrl,
    eventId,
    partnerId,
    lang,
    timezone
  ]);

  return {
    streamUrl: eventStreamUrl,
    eventBroadcastUrl: encodedBroadCastUrl,
    expandVideo,
    errorMessage,
    isShowVideo,
    isVideoFree
  };
};
