import { isFunction } from 'lodash';

const asyncValidator = (
  promise,
  predicate,
  message = 'validators.asyncValidator'
) => ({
  validate: async (value) => {
    if (!isFunction(promise) || !isFunction(predicate)) {
      return false;
    }
    if (!value) {
      return {
        error: 'no',
        error_code: ''
      };
    }
    try {
      const data = await promise(value);
      return predicate(data) || (isFunction(message) ? message(data) : message);
    } catch (e) {
      if (e.displayMessage) {
        return e.displayMessage;
      }

      return isFunction(message) ? message(e) : message;
    }
  }
});

export default asyncValidator;
