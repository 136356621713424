import { useContext, useEffect } from 'react';
import { disableBodyScroll } from 'body-scroll-lock';
import { POST_MESSAGE_TYPES, usePostMessage } from '@namespace/widget';
import { OnboardingContext } from '@namespace/onboarding/src/store/context';
import { COMPONENTS_TYPES } from '@namespace/cms';
import { FixScrollContext } from './store/context';

const { DISABLE_SCROLL, ENABLE_SCROLL } = POST_MESSAGE_TYPES;

export const useFixScroll = (key = '', targetRef, deps = false) => {
  const [{ isRunning }] = useContext(OnboardingContext);
  const [layers, addLayer, removeLayer] = useContext(FixScrollContext);
  const { postMessage } = usePostMessage();

  useEffect(() => () => removeLayer(key), []);

  useEffect(() => {
    // !isRunning for removing unnecessary body scroll blocking after closing Onboarding
    if (targetRef.current && layers.length > 0 && !isRunning) {
      disableBodyScroll(targetRef.current);
    }
  }, [isRunning, layers, targetRef]);

  useEffect(() => {
    if (deps) {
      addLayer(key);
    } else {
      removeLayer(key);
    }
  }, [deps]);

  useEffect(() => {
    if (key === COMPONENTS_TYPES.BET_SLIP) {
      if (deps) {
        postMessage(DISABLE_SCROLL, '');
      } else {
        postMessage(ENABLE_SCROLL, '');
      }
    }
  }, [deps, key]);
};
