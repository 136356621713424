import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@namespace/icons2';
import clsx from 'clsx';
import Box from '../../../Box';
import AText from '../../../AText';
import styles from './index.module.css';

const Highlight = ({ text = '', query, dataRole }) => {
  const formattedQuery = query.replace(/[\(\)\{\}\[\]']+/g, '');
  const regExp = new RegExp(`(${formattedQuery})`, 'i');

  return (
    <>
      {text.split(regExp).map((item, index) => {
        const key = `item-highlight-${index}`;
        return item.toLowerCase() === query.toLowerCase() ? (
          <span key={key} className={styles.highlight} data-role={dataRole}>
            {item}
          </span>
        ) : (
          <Fragment key={key}>{item}</Fragment>
        );
      })}
    </>
  );
};

const Item = ({
  title,
  subTitle,
  query,
  iconName,
  eventId,
  avatar,
  className = ''
}) => {
  return (
    <Box
      data-role={`search-item-${eventId}`}
      className={clsx([styles.wrapper, className])}
    >
      {Boolean(iconName) && (
        <Icon name={iconName} size="s" className={styles.icon} />
      )}
      {Boolean(avatar) && <Box>{avatar}</Box>}
      <Box direction="column">
        <AText
          breed="B"
          size="m"
          className={styles.title}
          data-role={`search-item-${eventId}-title`}
        >
          {query ? <Highlight text={title} query={query} /> : title}
        </AText>
        <AText breed="R" size="m" color="search_txt_result_2">
          {subTitle}
        </AText>
      </Box>
    </Box>
  );
};

Item.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  query: PropTypes.string,
  iconName: PropTypes.string
};

export default Item;
