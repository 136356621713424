import Stream from './componets/Stream';
import Markets from './componets/Markets';
import ResultsButton from './componets/ResultsButton';

const Event = () => (
  <>
    <Stream />
    <Markets />
    <ResultsButton />
  </>
);

export default Event;
