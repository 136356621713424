import { AText } from '@alf/uikit';
import { useConfig } from '@namespace/cms';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { useUserSegmentsIncludes } from '@namespace/segments';
import { useGetImage } from '@namespace/themes';
import styles from './index.module.css';

export const ServicesListWidget = ({ id }) => {
  const { config = {} } = useConfig(id);
  const filterSegments = useUserSegmentsIncludes();
  const getImg = useGetImage();
  const { items } = config;
  const filteredItems = items.filter(({ userSegments }) =>
    filterSegments(userSegments)
  );
  const { t } = useI18n();

  return (
    <div
      style={{ gridTemplateColumns: `repeat(${items.length}, 1fr)` }}
      className={styles.container}
    >
      {filteredItems.map(
        ({ imagePath, imagePathDark, translationKey, link }) => (
          <LocalizedLink to={link} className={styles.serviceItem} key={link}>
            <img
              className={styles.iconImage}
              src={getImg(imagePath, imagePathDark)}
              alt="icon"
            />
            <AText
              breed="BU"
              size="xs"
              color="txt_color_1"
              className={styles.serviceName}
            >
              {t(translationKey)}
            </AText>
          </LocalizedLink>
        )
      )}
    </div>
  );
};
