import { useCallback, useContext, useMemo, useState } from 'react';
import { DeviceContext } from '@namespace/device';
import { useConfig } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { useUserSegmentsIncludes } from '@namespace/segments';
import { useCacheLoad } from '@namespace/helpers';
import { parsePreset } from '../../store/normalizers';
import Menu from '../../common/TopEventsMenu';
import { sportsbookActions, useSportsbookStore } from '../../store';
import getFilteredByDatePresets from './utils/getFilteredByDatePresets';

const { FETCH_TOP_PRE_MATCH_EVENTS } = sportsbookActions;

const TopEventsMenu2 = ({ id }) => {
  const { t, language } = useI18n();
  const { pageType } = useContext(DeviceContext);
  const isUserSegmentsIncludes = useUserSegmentsIncludes();
  const { config = {} } = useConfig(id);
  const [topEventsLoading, setTopEventsLoading] = useState(false);
  const {
    topPreMatchEvents,
    topPreMatchEventsDataState: { isLoaded, dataCachingTime },
    topEventsDataState,
    topEvents,
    filteredByDatePresets: prevFilteredByDatePresets
  } = useSportsbookStore([
    'topPreMatchEvents',
    'topPreMatchEventsDataState',
    'topEventsDataState',
    'topEvents',
    'filteredByDatePresets'
  ]);

  const filteredByDatePresets = useMemo(
    () =>
      getFilteredByDatePresets({
        config,
        topEvents,
        isUserSegmentsIncludes
      }),
    [config, topEvents, isUserSegmentsIncludes]
  );

  const action = useCallback(async () => {
    setTopEventsLoading(true);
    await FETCH_TOP_PRE_MATCH_EVENTS({
      filteredByDatePresets,
      lang: language
    });
    setTopEventsLoading(false);
  }, [filteredByDatePresets, language]);

  useCacheLoad({
    dataCachingTime,
    dataMustBeLoaded:
      topEventsDataState.isLoaded &&
      !topEventsLoading &&
      (!isLoaded ||
        JSON.stringify(filteredByDatePresets) !==
          JSON.stringify(prevFilteredByDatePresets)),
    action
  });

  const parsedPresets = useMemo(
    () =>
      topPreMatchEvents.map((item) =>
        parsePreset(item, { t, pageType, language })
      ),
    [topPreMatchEvents, t, language, pageType]
  );

  return (
    <Menu
      isShowImages={true}
      presets={parsedPresets}
      topEventsLoading={topEventsLoading}
    />
  );
};

export default TopEventsMenu2;
