import { useContext } from 'react';
import { AText, Button } from '@alf/uikit';
import { useOtherSettings } from '@namespace/cms';
import { USER_ROLES, UserContext } from '@namespace/user';
import { useI18n } from '@namespace/i18n';
import { Icon } from '@namespace/icons2';
import { FilterIcon } from '../../common/FilterIcon';
import {
  advFilterActions,
  useAdvFilterStore
} from '../../../../storeAdvFilter';
import styles from './index.module.css';

const { SET_ADV_FILTER_OPENED } = advFilterActions;

export const AdvFilterButton = ({
  showTitle,
  color = 'btn_quaternary_color_4'
}) => {
  const { t } = useI18n();
  const [{ role }] = useContext(UserContext);
  const isAdvFilterOpened = useAdvFilterStore('isAdvFilterOpened');
  const { isSportsBookAdvFilterEnabled } = useOtherSettings();

  if (!isSportsBookAdvFilterEnabled || role !== USER_ROLES.USER) {
    return null;
  }

  if (isAdvFilterOpened) {
    return (
      <Button
        intent="quaternary"
        size="s"
        onClick={() => SET_ADV_FILTER_OPENED(false)}
        iconOnly={true}
        iconLeft={
          <Icon size="s" name="icons/general/action/close" color={color} />
        }
      />
    );
  }

  return (
    <Button
      intent="quaternary"
      size="s"
      onClick={() => SET_ADV_FILTER_OPENED(true)}
      contentClass={styles.buttonContent}
    >
      <FilterIcon color={color} />

      {showTitle && (
        <AText breed="BU" color={color}>
          {t('sportsBook.sportsMenu.advFilter.button')}
        </AText>
      )}
    </Button>
  );
};
