import EventsList from '../../../../common/EventsList';
import { TopLiveEventsSkelet } from '../TopLiveEventsSkelet';

const TopLiveEventsDesktop = ({
  isEventsLoaded,
  eventsIds,
  lineKey,
  type,
  isShowMore,
  isShowShortName,
  pageSize,
  isShowStatUrl,
  isLiveEvents
}) =>
  !eventsIds.length && !isEventsLoaded ? (
    <TopLiveEventsSkelet items={3} isShowMore={isShowMore} />
  ) : (
    <EventsList
      eventsIds={eventsIds}
      lineKey={lineKey}
      type={type}
      isShowMore={isShowMore}
      isShowShortName={isShowShortName}
      pageSize={pageSize}
      isShowStatUrl={isShowStatUrl}
      isLiveEvents={isLiveEvents}
    />
  );
export default TopLiveEventsDesktop;
