import { useMemo, useState } from 'react';
import clsx from 'clsx';
import { useScreenSize } from '@namespace/device';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { Box, AText } from '@alf/uikit';
import { useColorScheme } from '@namespace/themes';
import { formatJackpot, getSortedJackpotTypes } from '../utils';
import { useProviderByServiceId } from '../../../../../context/selectors';
import ProviderImage from '../../../../../common/ProviderImage';
import PateplayJackpotItem from './PateplayJackpotItem';

import styles from './index.module.css';

export const PateplayJackpot = ({
  jackpotIdentifier,
  jackpot,
  jackpotAction,
  jackpotImage,
  currency,
  providedServiceId,
  handleJackpotClick
}) => {
  const { t } = useI18n();
  const [isLoadJackpotImageError, setIsLoadJackpotImageError] = useState(false);
  const formattedJackpot = useMemo(
    () => formatJackpot(jackpot, jackpotIdentifier),
    [jackpot, jackpotIdentifier]
  );
  const sortedJackpotTypes = useMemo(
    () => getSortedJackpotTypes(formattedJackpot, jackpotIdentifier, 'desc'),
    [formattedJackpot, jackpotIdentifier]
  );
  const { providerLogo, providerColoredLogo } = useProviderByServiceId(
    providedServiceId
  );
  const { isDarkModeEnabled } = useColorScheme();
  const screenSize = useScreenSize(true);

  // TODO: I didn't understand how to make this with css. try again
  const isWrapJackpotsItems = screenSize < 768;

  const handleJackpotImageLoadError = () => {
    setIsLoadJackpotImageError(true);
  };

  return (
    <LocalizedLink
      className={styles.pateplayJackpotWrapper}
      onClick={() => handleJackpotClick(jackpotAction)}
      to={jackpotAction}
    >
      <Box
        align="center"
        justify="center"
        className={clsx([
          styles.pateplayJackpot,
          isDarkModeEnabled ? styles.darkJpBg : styles.lightJpBg
        ])}
      >
        <Box className={styles.providerInfoWrapper}>
          {isLoadJackpotImageError ? (
            <AText
              className={styles.title}
              color="accent_color_3"
              breed="BU"
              size="2xl"
            >
              {t(`games.jackpot.${providedServiceId}.title`)}
            </AText>
          ) : (
            <img
              src={jackpotImage}
              onError={handleJackpotImageLoadError}
              className={styles.jackpotImage}
              alt={`${t(`games.jackpot.${providedServiceId}.title`)} jackpot`}
            />
          )}
          {providerLogo && providerColoredLogo && (
            <ProviderImage
              providerLogo={providerLogo}
              providerColoredLogo={providerColoredLogo}
              classNames={{
                providerLogoClassName: styles.jackpotProviderImage
              }}
            />
          )}
        </Box>
        <Box className={styles.jackpotItems} align="start" justify="between">
          {isWrapJackpotsItems ? (
            <>
              {sortedJackpotTypes.slice(0, 1).map((type) => (
                <PateplayJackpotItem
                  key={`${jackpotIdentifier}_${type}`}
                  type={type}
                  jackpotAmounts={formattedJackpot[type]}
                  jackpotIdentifier={jackpotIdentifier}
                  currency={currency}
                />
              ))}
              <Box gap="xs">
                {sortedJackpotTypes.slice(1, 4).map((type) => (
                  <PateplayJackpotItem
                    key={`${jackpotIdentifier}_${type}`}
                    type={type}
                    jackpotAmounts={formattedJackpot[type]}
                    jackpotIdentifier={jackpotIdentifier}
                    currency={currency}
                  />
                ))}
              </Box>
            </>
          ) : (
            sortedJackpotTypes.map((type) => (
              <PateplayJackpotItem
                key={`${jackpotIdentifier}_${type}`}
                type={type}
                jackpotAmounts={formattedJackpot[type]}
                jackpotIdentifier={jackpotIdentifier}
                currency={currency}
              />
            ))
          )}
        </Box>
      </Box>
    </LocalizedLink>
  );
};
