import { LocalizedNavLink, useI18n } from '@namespace/i18n';
import { Flag } from '@namespace/icons2';
import { AText, Box } from '@alf/uikit';
import { useTournament } from '../../../../store';
import styles from '../../index.module.css';

const ConfigTournament = ({
  id,
  filterPathname,
  serviceId,
  sportSlug,
  filter,
  tournamentIds,
  countryId,
  name
}) => {
  const { t } = useI18n();
  const { eventCount } = useTournament(id, serviceId);

  return (
    <LocalizedNavLink
      to={`${filterPathname}/${sportSlug}/?${filter}`}
      isActive={() => tournamentIds.includes(id)}
      className={styles.tournament}
      activeClassName={styles.activeTournament}
    >
      <div className={styles.flagIconWrap}>
        {(countryId || countryId === 0) && (
          <Flag flagId={countryId} size="sm" className={styles.flag} />
        )}
      </div>
      <AText size="m" breed="BU" color="sm_txt_color_1">
        {t(name)}
      </AText>
      <Box align="center" padding="xs" className={styles.eventCount}>
        <AText size="xs" breed="R" color="sm_txt_color_1">
          {eventCount}
        </AText>
      </Box>
    </LocalizedNavLink>
  );
};

export default ConfigTournament;
