import WelcomeStep from '../Welcome';

export const LOGIN_STEPS_TYPES = {
  WELCOME: 'welcome-login',
  SETUP_USER_NAME: 'setup-user-name',
  SUCCESS_SCREEN: 'success-login-screen'
};

export const loginStepsMap = {
  [LOGIN_STEPS_TYPES.WELCOME]: WelcomeStep
};
