import { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './index.module.css';

const ProgressBar = ({
  currentValue,
  totalValue,
  containerColor = 'po_bg_color_2',
  progressColor = '',
  isReversed = false,
  classNames = {}
}) => {
  const {
    progressBarContainerClassName = '',
    containerClassName = ''
  } = classNames;
  const valuePercent = useMemo(
    () => Math.floor((currentValue / totalValue) * 100),
    [currentValue, totalValue]
  );

  return (
    <div className={clsx(styles.container, containerClassName)}>
      <div
        className={clsx(
          styles.progressBarContainer,
          progressBarContainerClassName,
          isReversed && styles.reversed
        )}
        style={{ backgroundColor: `var(--${containerColor})` }}
      >
        <div
          className={styles.progressBar}
          style={{
            width: `${valuePercent}%`,
            backgroundColor: `var(--${progressColor})`
          }}
        />
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  currentValue: PropTypes.number,
  totalValue: PropTypes.number,
  classNames: PropTypes.shape({
    progressBarContainerClassName: PropTypes.string,
    containerClassName: PropTypes.string
  }),
  containerColor: PropTypes.string,
  progressColor: PropTypes.string,
  isReversed: PropTypes.bool
};

export default ProgressBar;
