import { useContext, useEffect, useState } from 'react';
import { UserContext } from '@namespace/user/src/context';
import { USER_ROLES } from '@namespace/user/src/constants';
import { getZendeskChatUser } from '../../../../api/getZendeskChatUser';

const useSenderAuthToken = () => {
  const [auth, setAuth] = useState(null);
  const [{ role }] = useContext(UserContext);

  useEffect(() => {
    (async () => {
      if (role === USER_ROLES.USER) {
        try {
          const authData = await getZendeskChatUser();
          setAuth(authData?.data?.jwt || null);
        } catch (e) {
          console.warn(e);
        }
      }
      return false;
    })();
  }, [role]);

  return auth;
};

export default useSenderAuthToken;
