import { useEffect } from 'react';
import { useGeneralRoutes, useConfig } from '@namespace/cms';
import { useHistory } from '@namespace/router';

import { isEmpty } from 'lodash';
import { useGetFilterParams } from '../../hooks/useGetFilterParams';
import PreMatchFilteredEvents from '../PreMatchFilteredEvents';

const PreMatchFilteredEventsByParams = ({ id }) => {
  const { replace } = useHistory();
  const { config } = useConfig(id);
  const { filterPathname } = config;

  const { preMatchFilter, preMatchLobby } = useGeneralRoutes();
  const {
    sportSlugs,
    categoryIds,
    tournamentIds,
    isValidPath
  } = useGetFilterParams(filterPathname || preMatchFilter);

  const tournamentIdsLength = tournamentIds.length;

  useEffect(() => {
    if (!tournamentIdsLength && isValidPath) {
      replace(preMatchLobby);
    }
  }, [tournamentIdsLength, replace, preMatchLobby, isValidPath]);

  return (
    <PreMatchFilteredEvents
      id={id}
      sportName={sportSlugs[0]}
      categoryIds={!isEmpty(categoryIds) ? categoryIds : []}
      tournamentIds={!isEmpty(tournamentIds) ? tournamentIds : []}
    />
  );
};

export default PreMatchFilteredEventsByParams;
