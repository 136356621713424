import { BorderBottom, Box, Input } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import clsx from 'clsx';
import styles from './index.module.css';

const AmountInputWithButtons = ({
  handleAmountChange = () => {},
  inputType,
  min,
  step,
  placeholder,
  disabled,
  inputClassName,
  errorText,
  ...props
}) => {
  return (
    <Box className={styles.wrap}>
      <Box
        align="center"
        justify="center"
        onClick={() => handleAmountChange(false)}
        className={`${styles.button} ${styles.right}`}
      >
        <Icon
          color="input_border_focus_1"
          name="icons/general/minus"
          size="m"
        />
      </Box>
      <Input
        type={inputType}
        min={min}
        step={step}
        placeholder={placeholder}
        disabled={disabled}
        size="m"
        className={`${styles.amountInput} ${inputClassName}`}
        wrapperClassName={styles.inputWrapper}
        align="center"
        intent="ghost"
        {...props}
      />
      <Box
        align="center"
        justify="center"
        onClick={() => handleAmountChange(true)}
        className={`${styles.button} ${styles.left}`}
      >
        <Icon color="input_border_focus_1" name="icons/general/plus" size="m" />
      </Box>
      <BorderBottom
        className={clsx([styles.bottomBorder, errorText && styles.error])}
      />
    </Box>
  );
};

export default AmountInputWithButtons;
