import { useMatchPath } from '@namespace/router';
import { useGetLobbyPageUrl } from '../useGetLobbyPageUrl';

export const useGetURLFilters = (casinoType) => {
  const lobbyPageUrl = useGetLobbyPageUrl(casinoType);
  const paths = [
    `${lobbyPageUrl}/:provider`,
    `${lobbyPageUrl}/:provider/:category`
  ];
  const {
    provider: selectedProvider,
    category: selectedCategory
  } = useMatchPath(paths);
  let category = selectedCategory;
  let provider = !selectedProvider ? '' : selectedProvider;
  if (selectedProvider && !selectedCategory) {
    category = selectedProvider;
    provider = '';
  }
  if (selectedCategory === 'all') {
    category = '';
  }
  return [provider, category];
};
