import { post } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

export const getTaxes = (props) =>
  post('/accounting/api/v1/taxes/calculate', props).then(({ response }) =>
    camelizeKeys(response)
  );

export const getTaxesNew = (props) =>
  post(
    '/accounting/api/v2/taxes/withdraw/calculate',
    props
  ).then(({ response }) => camelizeKeys(response));
