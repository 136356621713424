import { useConfig } from '@namespace/cms';
import { isEmpty } from 'lodash';
import PreMatchFilteredEvents from '../PreMatchFilteredEvents';

const PreMatchFilteredEventsByConfig = ({ id }) => {
  const { config } = useConfig(id);
  const { sportSlug: sportName = '', categoryIds, tournamentsIds } = config;

  return (
    <PreMatchFilteredEvents
      id={id}
      lineKey={`${sportName}${categoryIds.join(',')}${tournamentsIds.join(
        ','
      )}`}
      sportName={sportName}
      categoryIds={!isEmpty(categoryIds) ? categoryIds : []}
      configTournamentIds={!isEmpty(tournamentsIds) ? tournamentsIds : []}
    />
  );
};

export default PreMatchFilteredEventsByConfig;
