import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';

const BackButton = ({ onGoBack }) => {
  const { t } = useI18n();
  const { getValues } = useFormContext();

  const back = useCallback(() => {
    onGoBack(getValues());
  }, [onGoBack, getValues]);

  return (
    <Button
      intent="quaternary"
      size="s"
      fullWidth={true}
      actionType="color3"
      onClick={back}
    >
      {t('register.steps.back')}
    </Button>
  );
};

export default BackButton;
