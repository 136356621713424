/**
 * @typedef TourManagerProps
 * @type {Object}
 * @property {Array<Tour>} tours
 * @property {Boolean|String} [returnUrl=false] Url to navigate on tour completion. true - to where user started, false - don't navigate, string - specific url.
 * @property {Number} [padding=0] default spotlight padding
 * @property {Object} [mocks] default mocks for onboarding
 * @property {Array<String>} [preferredPlacements] priority-sorted lists of preferred tooltip placements. If element cannot be fit inside any of preferred placements it will choose from all possible placements which has maximum available space. Allowed values: 'left', 'right', 'top', 'bottom' and derivatives with '-start' and '-end' suffixes, like 'left-start'.
 */

/**
 * @type {TourManagerProps}
 */
export const tourManagerProps = {
  padding: 0
};
