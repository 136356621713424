import { createContext, useMemo } from 'react';
import useConfig from '../../hooks/useConfig';

export const ComponentContext = createContext();

export const ComponentContextProvider = ({ id, children }) => {
  const { configComponents } = useConfig(id);

  const settings = useMemo(() => {
    const result = configComponents[id];

    // fix issue when component config doesn't include intrinsic 'id'
    if (result.id == null) {
      return {
        ...result,
        id
      };
    }

    return result;
  }, [configComponents, id]);

  return (
    <ComponentContext.Provider value={settings}>
      {children}
    </ComponentContext.Provider>
  );
};
