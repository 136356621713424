import { useReducer, createContext, useMemo } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import { actions as actionCreators } from '../actions';
import { reducers, initialState } from '../reducers';
import { types } from '../types';

export const ThemesContext = createContext();

export const ThemesProvider = ({
  customReducers,
  children,
  colorScheme,
  themeConfig,
  themesImagesSet,
  themesIconsSet,
  componentsConfig
}) => {
  const memoReducers = useMemo(
    () => combineReducers(reducers, customReducers),
    [customReducers]
  );

  const [state, dispatch] = useReducer(memoReducers, {
    ...initialState,
    colorScheme: colorScheme || initialState.colorScheme,
    ...themeConfig,
    imagesSet: themesImagesSet,
    iconsSet: themesIconsSet,
    componentsConfig: componentsConfig || initialState.componentsConfig
  });

  const actions = useActions(types, dispatch, actionCreators);

  return (
    <ThemesContext.Provider value={[state, actions]}>
      {children}
    </ThemesContext.Provider>
  );
};
