import { useCallback, useContext } from 'react';
import { UserContext } from '@namespace/user';
import { MailBoxContext } from '../store/mailBox/context';

export const useCreateMessage = () => {
  const [, actions] = useContext(MailBoxContext);
  const [user] = useContext(UserContext);
  const { id: userId } = user;
  const { CREATE_MESSAGE } = actions;
  return useCallback(
    async (message, subject) => {
      await CREATE_MESSAGE(userId, message, subject);
    },
    [userId]
  );
};
