import { useContext } from 'react';
import { logAction } from '@namespace/analytics';
import { ABTestContext } from '../../store/context';

const useABTestSegments = (prefix, logActionType) => {
  const [{ abSegments }] = useContext(ABTestContext);
  const includedVariant = abSegments?.find((segment) =>
    segment.startsWith(prefix)
  );

  return {
    includedVariant,
    logAction: (props) => {
      const { userId: playerID, ABSegments, additionalAttributes = {} } =
        props ?? {};
      const currentSegments = ABSegments || abSegments; // props segments are used after login

      const currentIncludedVariant = currentSegments?.find((segment) =>
        segment.startsWith(prefix)
      );
      if (currentIncludedVariant && playerID) {
        logAction(logActionType, {
          playerID,
          dateTime: new Date().getTime(),
          currentIncludedVariant,
          ...additionalAttributes
        });
      }
    }
  };
};

export default useABTestSegments;
