import { useCallback, useContext, useMemo, useState } from 'react';
import { AText, Box } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { get } from 'lodash';
import { useI18n } from '@namespace/i18n';
import {
  COMPONENTS_TYPES,
  PageComponentsContext,
  useSiteSettings
} from '@namespace/cms';
import ThemesSwitcher from '../ThemesSwitcher';
import TimeZoneSwitcher from '../TimeZoneSwitcher';
import CoefTypeSwitcher from '../CoefTypeSwitcher';
import LineView from '../LineView';
import SecondarySubMenuItems from './SecondarySubMenuItems';
import { SUB_MENU_ITEMS } from '../../constants';
import styles from './index.module.css';

const UserSettings = ({
  items = [],
  showTimeZoneSelector = true,
  showCoefSwitcher = false,
  showLineSettings = false,
  isShowLanguageUserSettings = false,
  showSettings = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClick = useCallback(() => setIsOpen((prevState) => !prevState), []);
  const { [COMPONENTS_TYPES.LANGUAGE]: LanguageComponent } = useContext(
    PageComponentsContext
  );
  const { otherSettings } = useSiteSettings();

  const { t } = useI18n();

  const isShowSeparator = useMemo(
    () =>
      showSettings &&
      Boolean(items.length) &&
      items.some(({ id }) => id === SUB_MENU_ITEMS.NEED_HELP),
    [showSettings, items]
  );

  const isShowThemesSwitcher = get(
    otherSettings,
    'themes.isShowThemesSwitcher',
    false
  );

  return (
    <>
      {isOpen ? (
        <Box
          justify="between"
          padding="s m"
          className={styles.container}
          align="center"
        >
          <Box className={styles.wrap}>
            <Box align="center">
              <Icon
                name="icons/general/state/settings"
                size="m"
                color="txt_color_2"
              />
            </Box>
            {showTimeZoneSelector && (
              <Box data-role="user-settings-time-zone-switcher">
                <TimeZoneSwitcher
                  type="header"
                  switcherClassName={styles.switcherContainer}
                  customTriggerClassNames={{
                    className: styles.customTriggerContainer
                  }}
                />
              </Box>
            )}
            {showCoefSwitcher && (
              <Box data-role="user-settings-coef-switcher">
                <AText
                  breed="B"
                  size="s"
                  color="txt_color_2"
                  className={styles.settingsTitle}
                >
                  {t('web.settings.coefficients.title')}
                </AText>
                <CoefTypeSwitcher
                  switcherClassName={styles.switcherContainer}
                  customTriggerClassNames={{
                    className: styles.switcherTriggerContainer
                  }}
                />
              </Box>
            )}
            {showLineSettings && (
              <Box>
                <AText
                  breed="B"
                  size="s"
                  color="txt_color_2"
                  className={styles.settingsTitle}
                >
                  {t('web.settings.lineview.title.desktop')}
                </AText>
                <LineView iconOptions={{ size: 'l' }} />
              </Box>
            )}
          </Box>
          <Box onClick={onClick} className={styles.cursor}>
            <Icon
              name="icons/general/action/close"
              size="s"
              color="txt_color_7"
              data-role="user-settings-close"
            />
          </Box>
        </Box>
      ) : (
        <Box padding="0 m" align="center" gap="m">
          {isShowThemesSwitcher && <ThemesSwitcher />}
          {isShowLanguageUserSettings && (
            <LanguageComponent
              classNames={{
                textClassName: styles.textClassName,
                dropdownClassName: styles.dropdownClassName
              }}
            />
          )}
          <SecondarySubMenuItems items={items} />
          {showSettings && (
            <>
              {isShowSeparator && (
                <Box
                  className={styles.separator}
                  data-role="user-settings-separator"
                />
              )}
              <Box
                align="center"
                onClick={onClick}
                className={styles.cursor}
                data-role="sports-settings-icon"
              >
                <Icon
                  name="icons/general/state/settings"
                  size="m"
                  color="txt_color_2"
                />
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default UserSettings;
