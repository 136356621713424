import {
  ascend,
  indexBy,
  map,
  mergeLeft,
  pick,
  pipe,
  prop,
  propOr,
  sort,
  zipObj,
  zipWith
} from 'ramda';
import { getCustomFilter } from '../api/getCustomFilter';
import { setCustomFilter } from '../api/setCustomFilter';
import { getCustomFilterSports } from '../api/getCustomFilterSports';
import { checkLineElements } from '../api/checkLineElements';
import { getLineElementNames } from '../api/getLineElementNames';
import { initialState, useAdvFilterStore } from './store';
import { getCustomFilterCategories } from '../api/getCustomFilterCategories';
import { getCustomFilterTournaments } from '../api/getCustomFilterTournaments';

export const actionsCreator = ({ setState }) => ({
  // region Actions

  SET_ADV_FILTER_OPENED: (isAdvFilterOpened) => {
    setState({ isAdvFilterOpened });
  },

  FETCH_ADV_FILTER: async ({ lang, serviceId, partnerId }) => {
    let advFilter;

    try {
      const customFilter = await getCustomFilter();
      if (customFilter?.length > 0) {
        const params = {
          lineElement: customFilter,
          lang,
          partnerId,
          serviceId
        };
        const [checkedFilter, filterNames] = await Promise.all([
          checkLineElements(params),
          getLineElementNames(params)
        ]);

        advFilter = zipWith(mergeLeft, checkedFilter, filterNames);
      }
    } catch (ex) {
      console.warn('Advanced Filter load error', ex);
    } finally {
      setState({
        advFilter: advFilter ?? []
      });
    }
  },

  SAVE_ADV_FILTER: async ({ filter }) => {
    try {
      await setCustomFilter({ filter });
      setState({ advFilter: filter });
    } catch (ex) {
      console.warn('Advanced Filter save error');
      throw ex;
    }
  },

  FETCH_ADV_FILTER_SPORTS: async ({ sportIds, lang, serviceId, partnerId }) => {
    const sports = await getCustomFilterSports({
      sportIds,
      lang,
      serviceId,
      partnerId
    });

    advFilterActions.SET_ADV_FILTER_SPORTS(sports);
  },

  FETCH_ADV_FILTER_CATEGORIES: async ({
    sportId,
    lang,
    serviceId,
    partnerId
  }) => {
    const categories = await getCustomFilterCategories({
      sportId,
      lang,
      serviceId,
      partnerId
    });

    advFilterActions.SET_ADV_FILTER_CATEGORIES({ categories, sportId });
  },

  FETCH_ADV_FILTER_TOURNAMENTS: async ({
    categoryIds = [],
    lang,
    serviceId,
    partnerId
  }) => {
    const tournaments = await Promise.all(
      categoryIds.map((categoryId) =>
        getCustomFilterTournaments({
          categoryId,
          lang,
          serviceId,
          partnerId
        })
      )
    );

    advFilterActions.SET_ADV_FILTER_TOURNAMENTS({ tournaments, categoryIds });
  },

  RESET_ADV_FILTER_CACHE: () => {
    setState(
      pick(
        [
          'advFilterSports',
          'advFilterSportsById',
          'isAdvFilterSportsLoaded',
          'advFilterCategoriesById',
          'advFilterCategoriesBySportId',
          'advFilterTournamentsById',
          'advFilterTournamentsByCategoryId'
        ],
        initialState
      )
    );
  },

  // endregion

  // region Reducers

  SET_ADV_FILTER_SPORTS: (sports = []) => {
    const sortedSports = sort(ascend(propOr(Infinity, 'weight')))(sports);

    setState({
      advFilterSports: sortedSports,
      advFilterSportsById: indexBy(prop('id'))(sortedSports),
      isAdvFilterSportsLoaded: true
    });
  },

  SET_ADV_FILTER_CATEGORIES: ({ categories = [], sportId }) => {
    const sortedCategories = sort(ascend(prop('weight')))(categories);

    setState((state) => ({
      advFilterCategoriesById: {
        ...state.advFilterCategoriesById,
        ...indexBy(prop('id'))(sortedCategories)
      },
      advFilterCategoriesBySportId: {
        ...state.advFilterCategoriesBySportId,
        [sportId]: sortedCategories
      }
    }));
  },

  SET_ADV_FILTER_TOURNAMENTS: ({ tournaments, categoryIds }) => {
    const tournamentsMapped = tournaments.map((categoryTournaments, idx) =>
      categoryTournaments.map((category) => ({
        ...category,
        categoryId: categoryIds[idx]
      }))
    );
    // always use unfiltered categoryIds, or you may break caching and cause infinite loop in case when category returns no tournaments
    const tournamentsByCategoryIdSorted = pipe(
      zipObj,
      map(sort(ascend(prop('weight'))))
    )(categoryIds, tournamentsMapped);

    setState((state) => ({
      advFilterTournamentsById: {
        ...state.advFilterTournamentsById,
        ...indexBy(prop('id'))(tournamentsMapped.flat())
      },
      advFilterTournamentsByCategoryId: {
        ...state.advFilterTournamentsByCategoryId,
        ...tournamentsByCategoryIdSorted
      }
    }));
  }

  // endregion
});

export const advFilterActions = actionsCreator(useAdvFilterStore);
