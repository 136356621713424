import { EmptyState } from '@alf/uikit';
import { useGeneralRoutes } from '@namespace/cms';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { Image } from '@namespace/icons2';

const EmptyMarkets = ({
  finished,
  canceled,
  inprogress,
  isEventsMarketsExist,
  sportSlug = 'none',
  eventId
}) => {
  const { home, liveEvent } = useGeneralRoutes();
  const { t } = useI18n();

  let emptyProps = {
    text: t('sportsbook.emptyState.marketsNotFound')
  };

  if (finished) {
    emptyProps = {
      text: t('sportsbook.emptyState.finished'),
      linkText: t('sportsbook.emptyState.home'),
      linkPath: home
    };
  } else if (canceled) {
    emptyProps = {
      text: t('sportsbook.emptyState.canceled'),
      linkText: t('sportsbook.emptyState.home'),
      linkPath: home
    };
  } else if (inprogress) {
    emptyProps = {
      text: t('sportsbook.emptyState.inprogress'),
      linkText: t('sportsbook.emptyState.live'),
      linkPath: `${liveEvent}/${sportSlug}/${eventId}`
    };
  } else if (isEventsMarketsExist) {
    emptyProps = {
      text: t('sportsbook.emptyState.noMarkets'),
      linkText: t('sportsbook.emptyState.home'),
      linkPath: home
    };
  }

  return (
    <EmptyState
      image={<Image name="general/looking_for" />}
      Link={LocalizedLink}
      {...emptyProps}
    />
  );
};

export default EmptyMarkets;
