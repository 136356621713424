import { HeaderDesktop } from './components/HeaderDesktop';
import { HeaderMobile } from './components/HeaderMobile';

export const HeaderLayout = ({
  isShowStatUrl,
  isShowScoreboard,
  expandVideo,
  isShowVideo,
  isVideoFree,
  isDesktop,
  tabState,
  setTabState,
  isShowBackHeader,
  backTitle,
  eventId,
  onGoBack = () => {},
  lineView,
  dataRole
}) =>
  isDesktop ? (
    <HeaderDesktop
      dataRole={dataRole}
      isShowStatUrl={isShowStatUrl}
      isShowScoreboard={isShowScoreboard}
      expandVideo={expandVideo}
      isShowVideo={isShowVideo}
      isVideoFree={isVideoFree}
      eventId={eventId}
      onGoBack={onGoBack}
      backTitle={backTitle}
      isShowBackHeader={isShowBackHeader}
      tabState={tabState}
      setTabState={setTabState}
      lineView={lineView}
    />
  ) : (
    <HeaderMobile
      dataRole={dataRole}
      isShowStatUrl={isShowStatUrl}
      isShowScoreboard={isShowScoreboard}
      expandVideo={expandVideo}
      isShowVideo={isShowVideo}
      isVideoFree={isVideoFree}
      eventId={eventId}
      onGoBack={onGoBack}
      backTitle={backTitle}
      isShowBackHeader={isShowBackHeader}
      tabState={tabState}
      setTabState={setTabState}
      lineView={lineView}
    />
  );
