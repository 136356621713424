import { Logo as CommonLogo } from '@namespace/layout';
import styles from './index.module.css';

const Logo = () => (
  <CommonLogo
    className={styles.loaderWrapperChange}
    imgClassName={styles.loaderImgChange}
  />
);

export default Logo;
