import { useCallback, useState } from 'react';
import { useConfig, useGeneralRoutes } from '@namespace/cms';
import { FILTER_ALL_VALUE } from '@namespace/common';
import clsx from 'clsx';
import { IS_WIDGET } from '@namespace/widget';
import CyberSportLine from './CyberSportLine';
import { EventsLineHeader, EventsLineTitle, Filters } from '../../common';
import { useCyberSportGetPathParams } from '../../hooks/useCyberSportGetPathParams';
import { LINE_KEY, MODE } from '../../constants';
import useEventsTimeFilter from '../../hooks/useEventsTimeFilter';
import useChangeDefaultFilter from '../../hooks/useChangeDefaultFilter';
import styles from './index.module.css';

const CyberSportPreMatchTournamentEvents = ({ id }) => {
  const { config } = useConfig(id);
  const {
    filters,
    oddFormats,
    isHeadGroupsSortingEnabled,
    activeHeadGroupsAmount,
    isShowStartingSoon
  } = config;
  const { isShowAll, default: defaultFilter, ...filterConfig } = filters;
  const { filter, setFilter } = useEventsTimeFilter({
    initialFilter: {
      [defaultFilter]: defaultFilter
    },
    filterConfig
  });
  const [isTodayFilterApplied, setIsTodayFilterApplied] = useState(false);
  const isChangeDefaultFilter = useChangeDefaultFilter();
  const { activeSport, category, tournament } = useCyberSportGetPathParams();
  const { sportId } = activeSport;
  const { tournamentId, tournamentName } = tournament || {};
  const { categoryId, categoryName } = category || {};
  const { cyberSportCategory } = useGeneralRoutes();
  const liveLineKey = `${LINE_KEY.CYBERSPORT_LIVE}-${sportId}-${tournamentId}`;
  const preMatchLineKey = `${LINE_KEY.CYBERSPORT_PRE_MATCH}-${sportId}-${tournamentId}`;

  const handleDefaultFilterChange = useCallback(() => {
    if (isChangeDefaultFilter && !isTodayFilterApplied) {
      setFilter({ [FILTER_ALL_VALUE]: FILTER_ALL_VALUE });
      setIsTodayFilterApplied(true);
    }
  }, [isChangeDefaultFilter, isTodayFilterApplied, setFilter]);

  return (
    <div className={clsx(!IS_WIDGET && styles.tournamentsContainer)}>
      <EventsLineHeader borderBottom={true}>
        <EventsLineTitle
          backLink={`${cyberSportCategory}/${categoryId}`}
          title={tournamentName}
          sportId={sportId}
          subtitle={categoryName}
          classNames={{
            iconClassName: styles.backIcon,
            subtitleClassName: styles.subtitle
          }}
        />
        <Filters
          filterConfig={filterConfig}
          filterValue={filter}
          isShowStartingSoon={isShowStartingSoon}
          isShowAll={isShowAll}
          oddFormats={oddFormats}
          onSetFilter={setFilter}
        />
      </EventsLineHeader>
      <CyberSportLine
        key={liveLineKey}
        id={id}
        sport={activeSport}
        category={category}
        serviceId={MODE.IN_PLAY}
        tournament={tournament}
        lineKey={liveLineKey}
        isHeadGroupsSortingEnabled={isHeadGroupsSortingEnabled}
        activeHeadGroupsAmount={activeHeadGroupsAmount}
        isDisplayFilter={false}
        isShowFlag={false}
        isShowEmpty={false}
        defaultFilter={defaultFilter}
        isTodayFilterApplied={isTodayFilterApplied}
        onDefaultFilterChange={handleDefaultFilterChange}
      />
      <CyberSportLine
        key={preMatchLineKey}
        id={id}
        sport={activeSport}
        category={category}
        serviceId={MODE.PRE_MATCH}
        tournament={tournament}
        lineKey={preMatchLineKey}
        isHeadGroupsSortingEnabled={isHeadGroupsSortingEnabled}
        isShowAll={isShowAll}
        filterConfig={filterConfig}
        activeHeadGroupsAmount={activeHeadGroupsAmount}
        filter={filter}
        setFilter={setFilter}
        isDisplayFilter={true}
        isShowFlag={false}
        isShowEmpty={true}
        isShowStartingSoon={isShowStartingSoon}
        defaultFilter={defaultFilter}
        isTodayFilterApplied={isTodayFilterApplied}
        onDefaultFilterChange={handleDefaultFilterChange}
      />
    </div>
  );
};

export default CyberSportPreMatchTournamentEvents;
