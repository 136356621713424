const types = {
  SET_INBOX_MESSAGES: 'SET_INBOX_MESSAGES',
  SET_OUTBOX_MESSAGES: 'SET_OUTBOX_MESSAGES',
  SET_INBOX_MESSAGES_LIST: 'SET_INBOX_MESSAGES_LIST',
  SET_OUTBOX_MESSAGES_LIST: 'SET_OUTBOX_MESSAGES_LIST',
  SELECT_INBOX_MESSAGE: 'SELECT_INBOX_MESSAGE',
  SELECT_OUTBOX_MESSAGE: 'SELECT_OUTBOX_MESSAGE',
  SET_SELECTED_INBOX_MESSAGES: 'SET_SELECTED_INBOX_MESSAGES',
  SET_SELECTED_OUTBOX_MESSAGES: 'SET_SELECTED_OUTBOX_MESSAGES',
  SET_DELETED_INBOX_MESSAGES: 'SET_DELETED_INBOX_MESSAGES',
  SET_DELETED_OUTBOX_MESSAGES: 'SET_DELETED_OUTBOX_MESSAGES',
  UNSELECT_ALL_INBOX_MESSAGES: 'UNSELECT_ALL_INBOX_MESSAGES',
  UNSELECT_ALL_OUTBOX_MESSAGES: 'UNSELECT_ALL_OUTBOX_MESSAGES',
  SET_UNREAD_MESSAGES: 'SET_UNREAD_MESSAGES',
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  CREATE_MESSAGE: 'CREATE_MESSAGE',
  FETCH_MESSAGE: 'FETCH_MESSAGE',
  SET_MESSAGE: 'SET_MESSAGE',
  DELETE_MESSAGES: 'DELETE_MESSAGES',
  SET_TOTAL_PAGES: 'SET_TOTAL_PAGES',
  CHECK_UNREAD: 'CHECK_UNREAD',
  CHECK_READ: 'CHECK_READ',
  SET_IMPORTANT_MESSAGE: 'SET_IMPORTANT_MESSAGE',
  FETCH_MESSAGES: 'FETCH_MESSAGES'
};

export default types;
