import { window } from '@namespace/helpers';
import useResizeObserver from '../useResizeObserver';
import { FORM_FACTORS_MAP } from '../../constants';

const selectSize = (size) => {
  if (size < 480) {
    return FORM_FACTORS_MAP.MOBILE;
  }
  if (size < 1024) {
    return FORM_FACTORS_MAP.TABLET;
  }

  return FORM_FACTORS_MAP.DESKTOP;
};

const useScreenSize = (isReturnWidth = false) => {
  const [screenWidth, screenHeight] = useResizeObserver();
  const { userAgent } = window.navigator;
  const isWindows = userAgent.toLocaleLowerCase().includes('windows');
  if (isWindows && screenHeight > window.outerHeight) {
    const width = window.innerWidth;
    return isReturnWidth ? width : selectSize(width);
  }

  return isReturnWidth ? screenWidth : selectSize(screenWidth);
};

export default useScreenSize;
