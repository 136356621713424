import { useEffect, useState } from 'react';
import { pathOr } from 'ramda';
import { useConfig, useGeneralRoutes } from '@namespace/cms';
import { LocalizedRedirect, useLocation } from '@namespace/i18n';
import { useSportsbookStore } from '../../store';
import { useGetPathParams } from '../../hooks/useGetPathParams';

export const SportsRedirect = ({ id }) => {
  const { config } = useConfig(id);
  const { serviceId } = config;

  const { sportSlug: activeSportSlug } = useGetPathParams(serviceId);

  const { pathname } = useLocation();
  const { liveSport, preMatchSport } = useGeneralRoutes();

  const sportPath = `${serviceId ? liveSport : preMatchSport}`;
  const sportsByService = useSportsbookStore(pathOr([], ['sports', serviceId]));
  const sportIds = sportsByService.map(({ sportId }) => sportId);
  const [redirectLink, setRedirectLink] = useState();

  useEffect(() => {
    if (sportsByService.length && !activeSportSlug) {
      const { sportSlug } = sportsByService[0];
      setRedirectLink(`${sportPath}/${sportSlug}`);
    }
  }, [sportsByService, sportIds.join(','), sportPath, pathname]);

  return redirectLink ? <LocalizedRedirect to={redirectLink} /> : null;
};

export default SportsRedirect;
