import { prop } from 'ramda';
import { socketSend } from '@namespace/socket';
import {
  get,
  post,
  urlWithParams,
  ihubGet,
  formatAsUriParameters,
  postAsJson,
  getResourceFromCms
} from '@namespace/helpers';
import { camelizeKeys } from 'humps';

export const getLaunchGameUrl = ({
  gameId,
  demo,
  locale,
  lobbyUrl,
  depositUrl,
  platform,
  userId
}) => {
  const request = {
    is_demo: Number(Boolean(demo)),
    locale,
    lobby_url: lobbyUrl,
    deposit_url: depositUrl,
    platform,
    ...(userId && { user_id: userId })
  };
  return ihubGet(
    urlWithParams(`/service/ihub-sw/api/launcher/${gameId}`, request)
  )
    .then(camelizeKeys)
    .then((response) => {
      const { url, directGameUrl } = response?.data || {};
      const launchUrl = directGameUrl || url;

      return { launchUrl, ...response.data };
    })
    .catch((e) => {
      const error = new Error(e?.errorCode);

      error.launchGameErrorCode = e.errorCode;

      throw error;
    });
};

export const getGamesList = ({ userSegments, platform, gamesIds }) =>
  get(
    `/adm-gw01/public/games?${formatAsUriParameters(
      {
        games: gamesIds,
        userSegments,
        platform
      },
      true
    )}`
  );

export const getGamesFeed = ({ feedNames, platform, userSegments = [] }) =>
  getResourceFromCms(
    `/gamesFeedsBatch?feedNames[]=${feedNames}&platform=${platform}&${userSegments
      .map((userSegment) => `userSegments[]=${userSegment}&`)
      .join('')
      .slice(0, -1)}`
  );

export const getFavoriteGames = (entityName) =>
  socketSend({
    cmd: `accounting/favorites/get_entities`,
    data: {
      entities: [entityName]
    }
  });

export const addGameToFavorites = ({ entityName, id }) => {
  return socketSend({
    cmd: 'accounting/favorites/save',
    data: {
      [entityName]: [{ id }]
    }
  });
};

export const removeGameFromFavorites = ({ entityName, id }) =>
  socketSend({
    cmd: 'accounting/favorites/delete',
    data: {
      [entityName]: [{ id }]
    }
  });

export const getJackpotGeneral = ({ serviceId }) =>
  post('/isw/api/v2/jackpot/get', {
    service_id: serviceId
  })
    .then(camelizeKeys)
    .then(prop('jackpot'));

export const getJackpotByProvider = ({
  providerId,
  currencyCode,
  jackpotCode
}) => {
  let url = `/ihub/jackpot/?service_id=${providerId}&currency=${currencyCode}`;
  if (jackpotCode != null) {
    url += `&code=${jackpotCode}`;
  }

  return get(url)
    .then(camelizeKeys)
    .then(prop('levels'));
};

export const getCMSProviderGames = ({ serviceId, limit = 300 }) =>
  get(`/service/GM/api/providers/${serviceId}/games?limit=${limit}`).then(
    camelizeKeys
  );

export const getGamesByLaunchIds = (launchIds, useStatus = false) =>
  get(
    `/adm-gw01/public/gamesByLaunchIds?${
      useStatus ? 'status=all&' : ''
    }${launchIds
      .map((launchId) => `launchGameIds[]=${launchId}&`)
      .join('')
      .slice(0, -1)}` // remove last extra "&" sign in the end
  ).then(camelizeKeys);

export const acceptJackpotWin = (jackpotId) =>
  post(`/service/jackpot/api/jackpot/${jackpotId}/accept`);

export const getJackpotWins = (status) =>
  postAsJson('/service/jackpot/api/jackpot-wins/get', {
    status: status ? [status] : []
  });

export const getProviders = () => get('/adm-gw01/public/gamesProviders');

export const getRecentlyPlayedGames = (type) =>
  get(`/service/suggester-games/recently-played/${type}`);

export const getRecommendedGames = (type) =>
  post(`/service/suggester-games/recommended-games`, {
    gameList: type
  });

export const getWagerSlotGames = () =>
  get(`/service/suggester-games/suggester/v1/wager-slot-list/get`);

export const getLiveRtpGames = () =>
  get(`/service/suggester-games/suggester/v1/cold-hot-list`).then(camelizeKeys);

export const excludedPrewagerGamesList = (slug) => {
  return post('/service/GM/public/game-list', {
    slug
  });
};
