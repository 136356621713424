import { useContext, useEffect, useMemo } from 'react';
import { DeviceContext } from '@namespace/device';
import {
  setLocalStorageValue,
  getLocalStorageValue,
  LOCAL_STORAGE_KEYS
} from '@namespace/helpers';
import { Select } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { BetSlipContext } from '@namespace/betslip';

const OddsFilter = ({ className = '', icon, oddFormats }) => {
  const { t, language } = useI18n();
  const [betSlip, betSlipActions] = useContext(BetSlipContext);
  const { oddsType, selectedOddsType } = betSlip;
  const { SET_ODDS_TYPE } = betSlipActions;
  const localOddsFormat = getLocalStorageValue(
    LOCAL_STORAGE_KEYS.ODDS_FORMAT,
    oddFormats[0]
  );
  const { isDesktop, isDesktopDevice } = useContext(DeviceContext);

  useEffect(() => {
    SET_ODDS_TYPE({
      oddsType: selectedOddsType || localOddsFormat,
      isSettingStatus: false
    });
  }, []);

  const actualConvertIndex = oddFormats.findIndex((i) => i === oddsType);
  const translatedList = useMemo(() => {
    return oddFormats.map((item) => ({
      value: item,
      title: t(`sportsbook.converting.${item}`)
    }));
  }, [oddFormats, t, language]);

  const handleChange = ({ value }) => {
    setLocalStorageValue(LOCAL_STORAGE_KEYS.ODDS_FORMAT, value);
    SET_ODDS_TYPE({ oddsType: value });
  };

  return oddFormats.length > 1 ? (
    <Select
      value={translatedList[actualConvertIndex]?.value}
      options={translatedList}
      onChange={handleChange}
      className={className}
      icon={icon}
      isDesktop={isDesktop || isDesktopDevice}
    />
  ) : null;
};

export default OddsFilter;
