import { PAYMENT_SYSTEMS } from '@namespace/payments/src/constants/paymentSystems';
import { isString, isNil, isNumber } from 'lodash';
import getPreWagerBalance from '../getPreWagerBalance';
import { PREWAGER_PAYMENT_SYSTEM_ID } from '../../constants';

const configureWallets = (rawWallets, paymentSystems) => {
  const psIds = [
    PREWAGER_PAYMENT_SYSTEM_ID,
    ...paymentSystems.map((ps) => ps.id)
  ];
  const wallets = rawWallets
    .filter(({ paymentInstrumentId }) => psIds.includes(paymentInstrumentId))
    .map(({ paymentInstrumentId, walletId = '', ...rest }) => {
      if (paymentInstrumentId === PREWAGER_PAYMENT_SYSTEM_ID) {
        return {
          ...rest,
          walletId,
          paymentInstrumentId
        };
      }

      // TODO make optimization
      const {
        payInstrName = '',
        comment = '',
        in: isCanDeposit,
        out: isCanWithdraw,
        order = 0,
        description,
        minAmount: minDeposit = null,
        maxAmount: maxDeposit = null,
        minWithdraw = null,
        maxWithdraw = null,
        autoAmount = [],
        payMethods = [],
        tax = undefined,
        depositTax = undefined
      } = paymentSystems.find((ps) => ps.id === paymentInstrumentId);

      // TODO temporary add this to keep compatibility with old front.
      // in future  - necessary to change min\max deposit option in config to number, not string with currency
      const clearMinDeposit = isString(minDeposit)
        ? minDeposit.replace(/\D/g, '')
        : minDeposit;
      const clearMaxDeposit = isString(maxDeposit)
        ? maxDeposit.replace(/\D/g, '')
        : maxDeposit;

      return {
        ...rest,
        paymentInstrumentId,
        paymentInstrumentName: payInstrName || comment,
        isCanDeposit,
        isCanWithdraw,
        order,
        description,
        minDeposit: clearMinDeposit,
        maxDeposit: clearMaxDeposit,
        minWithdraw,
        maxWithdraw,
        autoAmount,
        payMethods,
        taxRate: tax,
        depositTax,
        walletId
      };
    })
    .sort((a, b) => b.order - a.order);
  const activeWallet = wallets.find(({ isActive }) => isActive) || {};
  const { deposit: currentDeposit } = activeWallet;
  const preWagerWallet = getPreWagerBalance(wallets) || {};
  const { deposit: bonusBalance } = preWagerWallet;

  return {
    currencies: wallets.reduce(
      (acc, { currency, paymentInstrumentId, deposit }, idx, list) => {
        if (
          list.length === 1 &&
          paymentInstrumentId === PAYMENT_SYSTEMS.FP &&
          deposit <= 0
        ) {
          return acc;
        }
        return acc.includes(currency) ? acc : [...acc, currency];
      },
      []
    ),
    wallets,
    activeWallet: {
      ...activeWallet,
      baseBalance: currentDeposit || 0,
      bonusBalance: isNumber(bonusBalance) ? Number(bonusBalance) : null,
      deposit: !isNil(bonusBalance)
        ? Number(currentDeposit) + Number(bonusBalance)
        : currentDeposit
    }
  };
};

export default configureWallets;
