import { useContext } from 'react';
import clsx from 'clsx';
import { AText, Box, Button } from '@alf/uikit';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { COMPONENTS_TYPES, useGeneralRoutes } from '@namespace/cms';
import { Icon } from '@namespace/icons2';
import { BetSlipContext } from '@namespace/betslip';
import { DeviceContext } from '@namespace/device';
import { useIsComponentOnPage } from '@namespace/common';
import { useNotificationPopup } from '../../hooks';
import styles from './index.module.css';

const NotificationPopup = ({ isHeaderVisible }) => {
  const {
    importantMessages,
    onClose,
    isImportantMessage
  } = useNotificationPopup(isHeaderVisible);
  const { messagePreview, messages } = useGeneralRoutes();
  const { t } = useI18n();
  const { isDesktop } = useContext(DeviceContext);
  const [betSlip = {}] = useContext(BetSlipContext);
  const { bets = [] } = betSlip;
  const isBetSlipEnabled = useIsComponentOnPage(
    COMPONENTS_TYPES.BET_SLIP_GLOBAL
  );
  const isBetSlipVisible = !isDesktop && isBetSlipEnabled && bets.length > 0;

  if (!isImportantMessage) {
    return null;
  }

  const firstMessagePath =
    importantMessages.length === 1
      ? `${messagePreview}/${importantMessages[0]?.id}`
      : messages;

  return (
    <Box
      data-role="notification-popup-wrapper"
      justify="center"
      margin="s"
      color="bg_color_6"
      className={clsx(styles.wrapper, isBetSlipVisible && styles.withBetSlip)}
    >
      <Box
        data-role="notification-popup-close"
        onClick={onClose}
        className={styles.close}
      >
        <Icon
          size="xs"
          color="btn_close_dark_color"
          name="icons/general/action/close"
        />
      </Box>
      <Box
        direction="column"
        align="center"
        justify="center"
        className={styles.container}
      >
        <AText
          data-role="notification-popup-title"
          breed="BU"
          size="s"
          color="txt_color_1"
        >
          {`${t('popup.notification.msg.title')} ${
            importantMessages.length > 1 ? `(${importantMessages.length})` : ''
          }`}
        </AText>
        <Box top="s" padding="0 s">
          <AText
            data-role="notification-popup-message"
            breed="R"
            size="s"
            color="txt_color_1"
            align="center"
            className={styles.description}
          >
            {importantMessages[0]?.subject}
          </AText>
        </Box>
        <Button
          data-role="notification-popup-button-read"
          size="s"
          actionType="color3"
          intent="secondary"
          className={styles.readButton}
          component={LocalizedLink}
          to={firstMessagePath}
          onClick={onClose}
        >
          {t('popup.notification.button.read')}
        </Button>
      </Box>
    </Box>
  );
};

export default NotificationPopup;
