import { useGeneralRoutes } from '@namespace/cms';
import { useCacheLoad } from '@namespace/helpers';
import { LocalizedRedirect, useI18n, useLocation } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { useCallback, useEffect, useMemo } from 'react';
import { ALL_FILTER_VALUE, SPORT_IDS } from '../../constants';
import { useCyberSportGetPathParams } from '../../hooks/useCyberSportGetPathParams';
import useSportsSettings from '../../hooks/useSportsSettings';
import { sportsbookActions, useSportsbookStore } from '../../store';
import { getReadyCategories } from '../../utils';

const { FETCH_CATEGORIES_AND_TOURNAMENTS } = sportsbookActions;

const CyberSportSportsBook = () => {
  const { language } = useI18n();
  const { pathname } = useLocation();
  const {
    categories,
    categoriesAndTournamentsDataState,
    categoriesBySportId,
    isTournamentCategoryReady
  } = useSportsbookStore([
    'categories',
    'categoriesAndTournamentsDataState',
    'categoriesBySportId',
    'isTournamentCategoryReady'
  ]);
  const {
    category: activeCategory,
    activeSport,
    isAllSports
  } = useCyberSportGetPathParams();
  const {
    cyberSportCategory,
    cyberSport,
    cyberSportFavorites
  } = useGeneralRoutes();
  const { replace } = useHistory();
  const { sportId } = activeSport || {};

  const readyCategories = useMemo(
    () => getReadyCategories(categories, sportId),
    [sportId, categories]
  );
  const { timeRangeForAllPeriodByEvents } = useSportsSettings();
  const normalCyberSport = cyberSport?.includes('all')
    ? cyberSport.replace('/all', '')
    : cyberSport;

  const action = useCallback(
    () =>
      FETCH_CATEGORIES_AND_TOURNAMENTS({
        lang: language,
        sportId,
        categoriesBySportId,
        timeRange: timeRangeForAllPeriodByEvents
      }),
    [language, sportId, categoriesBySportId, timeRangeForAllPeriodByEvents]
  );

  const { dataCachingTime } =
    categoriesAndTournamentsDataState[SPORT_IDS.CYBERSPORT] || {};

  useCacheLoad({
    action,
    dataMustBeLoaded: sportId && `${sportId} ${language}`,
    dataCachingTime
  });

  useEffect(() => {
    if (
      ((pathname === `${normalCyberSport}/` &&
        !cyberSportCategory?.includes('cybersport')) ||
        (isTournamentCategoryReady && !isAllSports && !activeCategory)) &&
      cyberSport?.includes('/all')
    ) {
      replace(`${normalCyberSport}/${ALL_FILTER_VALUE}`);
    }
  }, [isTournamentCategoryReady]);

  if (
    readyCategories.length &&
    !activeCategory &&
    pathname.indexOf(cyberSportCategory) === 0 &&
    !pathname.includes(cyberSport) &&
    !pathname.includes(cyberSportFavorites)
  ) {
    const { sportSlug } = readyCategories[0];
    return <LocalizedRedirect to={`${cyberSportCategory}/${sportSlug}`} />;
  }

  return null;
};

export default CyberSportSportsBook;
