export const TENNIS_SERVES_TYPES = {
  PARTICIPANT_1: '2522',
  PARTICIPANT_2: '2523'
};

export const VOLLEYBALL_SERVES_WON = {
  PARTICIPANT_1: '2522',
  PARTICIPANT_2: '2523'
};

export const VOLLEYBALL_POINTS_WON_ON_SERVE = {
  PARTICIPANT_1: 'home_pwon',
  PARTICIPANT_2: 'away_pwon'
};

export const VOLLEYBALL_LONGEST_STRIKE = {
  PARTICIPANT_1: 'seria_h_match',
  PARTICIPANT_2: 'seria_a_match'
};

export const BALLS = 'balls';
export const STRIKES = 'strikes';
export const OUTS = 'outs';
export const BASE = 'base';
export const DIRECTION = 'direction';
export const UP = 'up';
export const DOWN = 'down';

export const BASEBALL_GAME_POINTS_INFO = [BALLS, STRIKES, OUTS];
