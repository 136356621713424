import { isNumber } from 'lodash';
import { LANG_CURRENCY_SIGNS } from './currencyFormats';

export const currencyFormatters = (language, currencyFormats) => ({
  formatCurrency: (
    num,
    grade = currencyFormats
      ? (currencyFormats[language] || currencyFormats.default)[0]
      : '',
    decimal = currencyFormats
      ? (currencyFormats[language] || currencyFormats.default)[1]
      : '',
    minimumFractionDigits = 2,
    maximumFractionDigits = 2
  ) => {
    const hasMoreThanTwoDigits = num?.toString().split('.')[1]?.length > 2;
    const currencyValue = hasMoreThanTwoDigits
      ? Math.floor(Number(num) * 100) / 100
      : Number(num);

    if (!isNumber(currencyValue)) {
      return '';
    }

    const signs =
      !grade && !decimal
        ? LANG_CURRENCY_SIGNS[language] || LANG_CURRENCY_SIGNS.default
        : [];
    const gradeSign = grade || signs[0] || '';
    const decimalSign = decimal || signs[1] || '';

    const baseFormat = currencyValue
      .toLocaleString('en-US', { minimumFractionDigits, maximumFractionDigits })
      .split(',')
      .join('G')
      .split('.')
      .join('D');

    return baseFormat
      .split('G')
      .join(gradeSign)
      .split('D')
      .join(decimalSign);
  },

  toNumber: (
    stringAmount,
    grade = currencyFormats
      ? (currencyFormats[language] || currencyFormats.default)[0]
      : '',
    decimal = currencyFormats
      ? (currencyFormats[language] || currencyFormats.default)[1]
      : ''
  ) => {
    const currencyValue = Number(stringAmount);

    if (isNumber(currencyValue) && !Number.isNaN(currencyValue)) {
      return currencyValue;
    }

    const signs =
      !grade && !decimal
        ? LANG_CURRENCY_SIGNS[language] || LANG_CURRENCY_SIGNS.default
        : [];
    const gradeSign = grade || signs[0] || ',';
    const decimalSign = decimal || signs[1] || '.';

    return Number(
      stringAmount
        .split(gradeSign)
        .join('')
        .split(decimalSign)
        .join('.')
    );
  }
});
