import { useScreenSize } from '@namespace/device';

export const useGetTileParams = () => {
  const screenWidth = useScreenSize(true);

  const tilesNumber = screenWidth < 760 ? 5 : 8;
  // prettier-ignore
  // eslint-disable-next-line no-nested-ternary
  const tileSize = screenWidth < 760
    ? 'xs'
    : screenWidth < 1023
      ? 's'
      : 'm';

  return { tilesNumber, tileSize };
};
