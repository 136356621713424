import { useState, useEffect } from 'react';
import { SelectField } from '@namespace/forms';
import { getCities } from '../../../api/withdraw';

const CityField = ({ walletHash, cashdesk, language, setValue, ...rest }) => {
  const [cities, setCities] = useState([]);

  const loadCities = () => {
    getCities({
      walletHash,
      cashdesk,
      language
    }).then((data) => {
      const cityList = data.map(({ id, name }) => ({
        value: String(id),
        name
      }));
      setCities(cityList);
      setValue(cityList[0]?.value ?? '');
    });
  };

  useEffect(() => {
    loadCities();

    return () => setCities([]);
  }, []);

  return <SelectField options={cities} {...rest} />;
};

export default CityField;
