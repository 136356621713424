import { useGeneralRoutes } from '@namespace/cms';
import { PROMOTION_ITEM_TYPES } from '../../constants';

export const useGetPromotionPageRouteByPromoItemType = ({
  activePromoItemType
}) => {
  const {
    promotion: promoPageRoute,
    tournament: tournamentPageRoute
  } = useGeneralRoutes();

  const promoItemTypeToPageRoute = {
    [PROMOTION_ITEM_TYPES.PROMOTION]: promoPageRoute,
    [PROMOTION_ITEM_TYPES.TOURNAMENT]: tournamentPageRoute
  };

  return promoItemTypeToPageRoute[activePromoItemType];
};
