import { useEffect, useCallback } from 'react';
import {
  useI18n,
  useLocation,
  useGetActualPrefixForPath
} from '@namespace/i18n';
import { useNavigate, useHistory as useI18nHistory } from '@namespace/router';
import { window } from '@namespace/helpers';

const normalizePath = (path = '') =>
  path
    .split('/')
    .filter((i) => i.length)
    .join('/');

const useCreateWindowChangeUrl = () => {
  const navigate = useNavigate();
  const history = useI18nHistory();
  const { pathname } = useLocation();
  const { language } = useI18n();
  const getActualPrefixForPath = useGetActualPrefixForPath();

  const changeUrl = useCallback(
    (path, { pathWithLang } = { pathWithLang: false }) => {
      const currentUrl = pathWithLang
        ? `${getActualPrefixForPath(`/${language}`)}${pathname}`
        : pathname;
      if (normalizePath(currentUrl) !== normalizePath(path)) {
        if (pathWithLang) {
          navigate(path);
        } else {
          history.push(path);
        }
      }
    },
    [navigate, history, language, pathname]
  );

  useEffect(() => {
    window.changeUrl = changeUrl;
  }, [changeUrl]);
};

export default useCreateWindowChangeUrl;
