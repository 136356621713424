import useThemeComponents from '../../hooks/useThemeComponents';

const getThemesComponent = (DefaultComponent, name) => (props) => {
  const ContextComponent = useThemeComponents();
  const Component = ContextComponent[name] || DefaultComponent;

  return <Component {...props} />;
};

export default getThemesComponent;
