import { useEffect, useState } from 'react';
import { Box } from '@alf/uikit';
import Pagination from '../../Pagination';
import EventsByGroup from './components/EventsByGroup';
import styles from './index.module.css';

const EventsContainer = ({
  eventsByGroups = [],
  isShowEventGroup,
  isShowFlag = true,
  activeHeadGroups = [],
  paginationType,
  fetchEvents,
  isLoadingMore,
  isCollapsibleTournaments,
  lineKey,
  isShowStatUrl,
  isShowVideo,
  isEventsLoaded,
  isDisplayHeadMarkets,
  eventsLimitByTournament,
  lineView,
  isLiveEvents
}) => {
  const [isDisplayAllEvents, setIsDisplayAllEvents] = useState(true);

  useEffect(() => {
    if (eventsLimitByTournament) {
      setIsDisplayAllEvents(false);
    }
  }, [eventsLimitByTournament]);

  return (
    <Box
      data-role="events-container"
      direction="column"
      className={styles.eventsContainer}
    >
      {eventsByGroups.map((events, index) => {
        if (!events) {
          return null;
        }

        return (
          <EventsByGroup
            key={`event_title_${events[0]}`}
            events={events}
            index={index}
            isShowEventGroup={isShowEventGroup}
            isShowFlag={isShowFlag}
            activeHeadGroups={activeHeadGroups}
            isCollapsibleTournaments={isCollapsibleTournaments}
            lineKey={lineKey}
            isShowStatUrl={isShowStatUrl}
            isShowVideo={isShowVideo}
            isDisplayHeadMarkets={isDisplayHeadMarkets}
            eventsLimitByTournament={eventsLimitByTournament}
            lineView={lineView}
            isLiveEvents={isLiveEvents}
            isDisplayAllEvents={isDisplayAllEvents}
            setIsDisplayAllEvents={setIsDisplayAllEvents}
          />
        );
      })}
      {!isEventsLoaded && (
        <Pagination
          isLoading={isLoadingMore}
          isShow={true}
          onNextPage={fetchEvents}
          paginationType={paginationType}
        />
      )}
    </Box>
  );
};

export default EventsContainer;
