import { useFormContext } from 'react-hook-form';
import { useI18n } from '@namespace/i18n';
import { getFormError } from '@namespace/forms';
import CityField from '../../components/CityField';

const City = ({
  name,
  rules = {},
  cashdesk,
  walletHash,
  label,
  placeholder,
  ...props
}) => {
  const { t, language } = useI18n();
  const methods = useFormContext();
  const {
    setValue,
    formState: { errors }
  } = methods;

  return (
    <CityField
      walletHash={walletHash}
      language={language}
      setValue={(value) => setValue(name, value, { shouldValidate: true })}
      cashdesk={cashdesk}
      name="city"
      rules={rules}
      error={getFormError(name, errors, t)}
      label={t(label)}
      placeholder={t(placeholder)}
      {...props}
    />
  );
};

export default City;
