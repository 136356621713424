const roundVal = (val) => Math.round(val * 100) / 100;

export const calcBaseTax = (amount, rate) => {
  const newTax = amount * (rate / 100);
  const returnAmount = amount - newTax;
  return {
    tax: roundVal(newTax).toFixed(2),
    returnAmount: roundVal(returnAmount).toFixed(2)
  };
};

export const calcUATax = (amount, rates) => {
  const taxes = rates.map(({ value }) => amount * (value / 100));
  const returnAmount = amount - taxes.reduce((memo, curr) => curr + memo, 0);
  return {
    taxes: taxes.map((val) => roundVal(val).toFixed(2)),
    returnAmount: roundVal(returnAmount).toFixed(2)
  };
};
