import clsx from 'clsx';
import { useI18n } from '@namespace/i18n';
import { Box, Checkbox } from '@alf/uikit';
import styles from './index.module.css';

const checkboxClassNames = {
  labelClassName: styles.rememberMeLabel
};

const RememberMe = ({ checked, onChange, className = '' }) => {
  const { t } = useI18n();

  return (
    <Box className={clsx(styles.rememberMe, className)} align="start">
      <Checkbox
        checked={checked}
        onChange={onChange}
        classNames={checkboxClassNames}
        label={t('login.helpers.rememberMe')}
        size="m"
      />
    </Box>
  );
};

export default RememberMe;
