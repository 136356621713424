import { useLRUCache } from '../useLRUCache';
import getMatchedPathFromBatch from '../../utils/getMatchedPathFromBatch';
import getSeoConfigFromCMS from '../../api/getSeoConfigFromCMS';

const getActualBatchConfig = ({ batchConfigs, path }) => {
  const matchedPath = getMatchedPathFromBatch(batchConfigs, path);
  const config =
    (matchedPath && batchConfigs[matchedPath]) || batchConfigs[path];
  return config?.seoConfig || {};
};

const useSeoBatchPageCache = () => {
  const [, addPage, getItem] = useLRUCache(null);

  const getSeoBatchPageConfig = async ({ pathname, rootPath }) => {
    const path = pathname.replace(/\/$/, '');
    let batchConfigs = getItem({
      key: rootPath
    });

    if (!batchConfigs) {
      if (
        !process.env.REACT_APP_IS_CMS_DISABLED ||
        process.env.REACT_APP_PRIORITY_CMS?.includes(rootPath)
      ) {
        try {
          const data = await getSeoConfigFromCMS(rootPath);
          addPage(rootPath, data);
          batchConfigs = data;
          return getActualBatchConfig({ batchConfigs, path });
        } catch {
          addPage(rootPath, {});
          batchConfigs = {};
        }
      }
      addPage(rootPath, {});
      batchConfigs = {};
    }

    return getActualBatchConfig({ batchConfigs, path });
  };
  return [getSeoBatchPageConfig];
};

export default useSeoBatchPageCache;
