import { useI18n } from '@namespace/i18n';
import { Box, AText } from '@alf/uikit';
import { Captcha } from '../../common/Captcha';
import InputField from '../../common/InputField';

const CaptchaField = ({
  name,
  label = '',
  inputLabel = '',
  rules = {},
  classNames = {},
  errorCode = '',
  ...props
}) => {
  const { t } = useI18n();

  return (
    <Box className={classNames.wrapperClassName} direction="column">
      {label && <AText className={classNames.labelClassName}>{t(label)}</AText>}
      <Captcha
        errorCode={errorCode}
        wrapperClassName={classNames.captchaClassName}
      />
      <InputField
        name={name}
        rules={rules}
        classNames={{ inputClassName: classNames.inputClassName }}
        label={inputLabel}
        {...props}
      />
    </Box>
  );
};

export default CaptchaField;
