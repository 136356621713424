import { useEffect } from 'react';
import {
  SESSION_STORAGE_KEYS,
  setSessionStorageValue,
  usePrevious
} from '@namespace/helpers';
import { useLocation } from '@namespace/i18n';

const useBackUp = () => {
  const { pathname } = useLocation();
  const prevPathname = usePrevious(pathname);

  useEffect(() => {
    if (pathname === prevPathname || !prevPathname) return;

    setSessionStorageValue(SESSION_STORAGE_KEYS.PREV_PATHNAME, prevPathname);
  }, [pathname, prevPathname]);

  return null;
};

export default useBackUp;
