import { useContext, useEffect, useState } from 'react';
import { useGetUserSegments } from '@namespace/segments';
import { isEmpty } from 'lodash';
import { useUserInfoLoaded } from '@namespace/user';
import { GamesContext } from '../../context';
import { useLoadFavoritesList } from '../useLoadFavoritesList';
import { useGetPlatformForFeed } from '../useGetPlatformForFeed';

export const useLoadCasinoSingleGameData = ({
  gameId,
  providerId,
  casinoType
}) => {
  const [gamesState, { GET_GAME, GET_PROVIDER }] = useContext(GamesContext);
  const isGameAlreadyLoaded = !isEmpty(gamesState.games.data[gameId]);
  const platform = useGetPlatformForFeed();
  const { userSegmentsNames: userSegments } = useGetUserSegments();
  const { userInfoLoaded } = useUserInfoLoaded();
  const [isGameDataLoading, setIsGameDataLoading] = useState(false);
  const [isGameDataLoaded, setIsGameDataLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (
          !isGameAlreadyLoaded &&
          gameId &&
          !isGameDataLoaded &&
          !isGameDataLoading &&
          userInfoLoaded
        ) {
          setIsGameDataLoading(true);

          const { success } = await GET_GAME({
            gameId,
            platform,
            userSegments
          });

          if (success) await GET_PROVIDER(providerId);

          setIsGameDataLoaded(true);
          setIsGameDataLoading(false);
        }
      } catch (e) {
        console.error(e);

        setIsGameDataLoaded(true);
        setIsGameDataLoading(false);
      }
    })();
  }, [
    gameId,
    providerId,
    isGameDataLoaded,
    isGameDataLoading,
    isGameAlreadyLoaded,
    platform,
    userSegments,
    GET_GAME,
    GET_PROVIDER,
    userInfoLoaded
  ]);

  useLoadFavoritesList(casinoType);

  return { isSingleGameDataLoaded: isGameDataLoaded, platform };
};
