import { useState, useEffect, useCallback, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { debounce } from 'lodash';
import { window, document } from '@namespace/helpers';

const useResizeObserver = (elementRef, wait = 1000) => {
  const [size, setSize] = useState({
    width: elementRef ? 0 : window.innerWidth || 0,
    height: 0
  });
  const htmlRef = useRef(document.documentElement);
  const ref = elementRef || htmlRef;
  const handleResize = useCallback((entries) => {
    if (Array.isArray(entries)) {
      const { contentRect } = entries[0];
      const { width, height } = contentRect;
      setSize({ width, height });
    }
  }, []);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    let RO = new ResizeObserver(debounce(handleResize, wait));
    // use debounce for observing not immediately
    RO.observe(ref.current);

    // eslint-disable-next-line consistent-return
    return () => {
      RO.disconnect();
      RO = null;
    };
  }, [ref.current]);

  return Object.values(size);
};

export default useResizeObserver;
