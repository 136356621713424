import { Icon } from '@namespace/icons2';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import AText from '../AText';
import Box from '../Box';
import styles from './index.module.css';

const InfoMessage = ({
  children,
  icon,
  className = '',
  breed = 'R',
  color = 'po_txt_color_3',
  align = 'left',
  dataRole,
  iconWrapClassName = '',
  ...rest
}) => (
  <Box className={`${styles.infoMessage} ${className}`}>
    {icon && (
      <Icon
        color={color}
        name={icon === 'info' ? 'icons/general/alert/info' : icon}
        size="s"
        className={clsx(styles.iconWrap, iconWrapClassName)}
        data-role={dataRole && `${dataRole}-icon`}
      />
    )}
    {children && (
      <AText
        data-role={dataRole && `${dataRole}-text`}
        breed={breed}
        color={color}
        size="s"
        align={align}
        {...rest}
      >
        {children}
      </AText>
    )}
  </Box>
);

InfoMessage.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  align: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};

export default InfoMessage;
