import { path } from 'ramda';
import clsx from 'clsx';
import { useConfig, useGeneralRoutes } from '@namespace/cms';
import { Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { MODE } from '../../../constants';
import { MenuHeader } from './components/MenuHeader';
import { BaseMenuList, CustomFilterLink, FavoritesLink } from '../../../common';
import { useGetPathParams } from '../../../hooks/useGetPathParams';
import { useSportsbookStore } from '../../../store';
import { useAdvFilterStore } from '../../../storeAdvFilter';
import { AdvFilter } from '../../AdvancedFilter';
import styles from './index.module.css';

export const PreMatchList = ({ id }) => {
  const {
    preMatchSport,
    preMatchCategory,
    preMatchFavorites
  } = useGeneralRoutes();

  const { config } = useConfig(id);
  const { showCategories = false } = config;
  const { t } = useI18n();

  const sports = useSportsbookStore(path(['sports', MODE.PRE_MATCH]));
  const isAdvFilterOpened = useAdvFilterStore('isAdvFilterOpened');
  const advFilter = useAdvFilterStore('advFilter');
  const { activeSport, category, eventId } = useGetPathParams(
    MODE.PRE_MATCH,
    false
  );
  const { sportId: activeSportId } = activeSport || {};
  const { sportId: categorySportId, categoryId } = category || {};

  const classes = clsx(
    styles.preMatchList,
    isAdvFilterOpened && styles.advFilterOpened
  );

  return (
    <Box data-role="preMathList" direction="column" className={classes}>
      <MenuHeader
        title="title"
        classNames={{
          headerClassName: styles.menuHeaderPreMatch
        }}
        dataRole="prematch-sport"
      />
      {isAdvFilterOpened && advFilter != null ? (
        <AdvFilter />
      ) : (
        <>
          <FavoritesLink
            to={preMatchFavorites}
            title={t('sportsbook.favorites')}
            className={styles.favoriteItem}
          />
          {advFilter?.length > 0 && (
            <CustomFilterLink className={styles.favoriteItem} />
          )}
          <BaseMenuList
            basePath={preMatchSport}
            categoryRoute={preMatchCategory}
            sports={sports}
            serviceId={MODE.PRE_MATCH}
            activeSportId={activeSportId}
            eventId={eventId}
            categorySportId={categorySportId}
            categoryId={categoryId}
            showCategories={showCategories}
            keyProperty="sportId"
            showPinIcon={true}
          />
        </>
      )}
    </Box>
  );
};
