import { useContext, useEffect, useReducer } from 'react';
import { useI18n } from '@namespace/i18n';
import { DeviceContext } from '@namespace/device';
import { reducer } from './store/reducer';
import { countChanges, formatConfig, hasChanges } from './utils';
import {
  changeFilterAction,
  countChangesAction,
  setFilterAction,
  setInitialFilterAction
} from './store/actions';
import DataFilter from './components/DataFilter';
import { FILTER_ALL_VALUE } from '../../constants';

const Filter = ({
  initialFilter = {},
  filtersConfig = [],
  onSet = () => {},
  arrowConfig = {},
  onFilterChange = () => {},
  className = '',
  dropdownFilterClassName = '',
  filterGroupClassName = '',
  filterClassName = '',
  triggerClassName = '',
  isInline = false,
  filterButtonClassName = '',
  tkPrefix,
  dataRole
}) => {
  const { isDesktop, isMobile } = useContext(DeviceContext);
  const { t } = useI18n();
  const config = formatConfig(filtersConfig, { t, tkPrefix });
  const resetFilter = config.reduce(
    (obj, { key, options = [], defaultValue }) => ({
      ...obj,
      [key]: defaultValue?.value || options[0]?.value
    }),
    {}
  );

  const initial = {
    appliedFilters: {},
    changedFilters: {},
    changesCount: 0,
    isChanged: false
  };

  const [
    { appliedFilters, changedFilters, isChanged, changesCount },
    dispatch
  ] = useReducer(reducer, initial);

  useEffect(() => {
    dispatch(setFilterAction(initialFilter));
    dispatch(setInitialFilterAction(initialFilter));
  }, [initialFilter]);

  useEffect(() => {
    dispatch(
      countChangesAction({
        changesCount: countChanges(changedFilters, resetFilter),
        isChanged: hasChanges(changedFilters, appliedFilters)
      })
    );
  }, [changedFilters]);

  // this method is a terrible hack and should be reimplemented
  const handleChanges = (data) => {
    let newData = data;
    if (data?.serviceId) {
      onFilterChange(data.serviceId);
    }

    if (data?.serviceId === FILTER_ALL_VALUE) {
      newData = {
        gameId: FILTER_ALL_VALUE,
        ...newData
      };
    }

    if (
      data.serviceId != null &&
      (appliedFilters.serviceId !== data?.serviceId ||
        changedFilters.serviceId !== data?.serviceId)
    ) {
      newData = {
        ...newData,
        gameId: FILTER_ALL_VALUE
      };
    }

    dispatch(changeFilterAction(newData));
  };

  const handleSet = (data = {}) => {
    const bufChanges = { ...changedFilters, ...data };
    if (onSet) {
      onSet(bufChanges);
    }
    return dispatch(setFilterAction(bufChanges));
  };

  const checkIsInline = !(
    isInline ||
    isMobile ||
    config.length > 5 ||
    (config.length > 2 && !isDesktop)
  );

  return (
    <DataFilter
      filterGroupClassName={filterGroupClassName}
      filterClassName={filterClassName}
      triggerClassName={triggerClassName}
      appliedFilters={appliedFilters}
      changedFilters={changedFilters}
      filtersConfig={config}
      isChanged={isChanged}
      changesCount={changesCount}
      onChange={handleChanges}
      onSet={handleSet}
      isInline={checkIsInline}
      className={className}
      arrowConfig={arrowConfig}
      dropdownFilterClassName={dropdownFilterClassName}
      filterButtonClassName={filterButtonClassName}
      dataRole={dataRole}
    />
  );
};

export default Filter;
