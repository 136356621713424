import { Image } from '@namespace/icons2';
import { icons } from './icons';
import styles from './index.module.css';

export const PaymentIcon = ({ id, className = '', alt = '' }) => {
  if (!icons[id]) {
    return null;
  }

  return (
    <Image
      name={icons[id]}
      alt={alt}
      className={`${styles.img} ${className}`}
    />
  );
};
