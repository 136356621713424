const parseBTagParam = (bTag = '') => {
  const [, trackerId = ''] = bTag.match(/a_(.*)b_/) || [];
  const [, creativeId = ''] = bTag.match(/b_(.*)c_/) || [];
  const [, zoneId = ''] = bTag.match(/c_(.*)AffiliateId/) || [];
  const [, affiliateId = ''] = bTag.match(/AffiliateId=(.*)/) || [];

  return {
    trackerId,
    creativeId,
    zoneId,
    affiliateId
  };
};

export default parseBTagParam;
