import { useCallback, useState } from 'react';
import { getCookie, setCookie, COOKIES_KEYS } from '@namespace/helpers';
import { HTMLSnippet } from '@namespace/snippets';
import { Notification } from '@alf/uikit';
import styles from './index.module.css';

const NotificationBanner = ({ snippetId, expires = 365 }) => {
  const isBannerViewed = getCookie(
    `${COOKIES_KEYS.IS_BANNER_VIEWED}-${snippetId}`,
    false
  );
  const [isShowBanner, setIsShowBanner] = useState(!isBannerViewed);

  const setViewed = useCallback(() => {
    setCookie(`${COOKIES_KEYS.IS_BANNER_VIEWED}-${snippetId}`, true, {
      expires
    });
    setIsShowBanner(false);
  }, [snippetId, expires]);

  if (snippetId && isShowBanner) {
    return (
      <Notification
        onClose={setViewed}
        message={
          <HTMLSnippet snippetId={snippetId} className={styles.htmlSnippet} />
        }
      />
    );
  }

  return null;
};

export default NotificationBanner;
