import { AText, Box } from '@alf/uikit';
import clsx from 'clsx';
import useSportsSettings from '../../hooks/useSportsSettings';
import { useEventFields } from '../../store';
import styles from './index.module.css';

const EventComments = ({ eventId, classNames = {}, options = {} }) => {
  const {
    eventLinePosition,
    eventCommentTemplateComment: eventComments = [],
    eventStatusDescName = ''
  } = useEventFields({
    eventId,
    fields: [
      'eventLinePosition',
      'eventCommentTemplateComment',
      'eventStatusDescName'
    ]
  });
  const { commentsContainerClass = '', commentItemClass = '' } = classNames;
  const comments = [...eventComments?.filter((comment) => comment.length > 0)];
  const isDisplayComments =
    comments.length > 0 || eventStatusDescName.trim().length > 0;
  const { isShowEventLinePosition = false } = useSportsSettings();

  return (
    (isDisplayComments || isShowEventLinePosition) && (
      <Box align="center" className={commentsContainerClass}>
        {isShowEventLinePosition && (
          <AText
            className={clsx([styles.comment, commentItemClass])}
            breed="B"
            color="line_infotxt_5"
          >
            {`#${eventLinePosition}`}
          </AText>
        )}
        {isDisplayComments && (
          <>
            {comments.length > 0 &&
              comments.map((comment, index) => {
                const key = `${eventId}-comments_${index}`;
                return (
                  <AText
                    breed="R"
                    className={clsx([styles.comment, commentItemClass])}
                    color="line_infotxt_5"
                    key={key}
                    {...options}
                  >
                    {comment}
                  </AText>
                );
              })}
            {eventStatusDescName.trim().length > 0 && (
              <AText
                breed="R"
                className={`${styles.comment} ${commentItemClass}`}
                color="line_infotxt_5"
                {...options}
              >
                {eventStatusDescName}
              </AText>
            )}
          </>
        )}
      </Box>
    )
  );
};

export default EventComments;
