import { useContext } from 'react';
import { AText, Box } from '@alf/uikit';
import { UserContext } from '@namespace/user';
import { DeviceContext } from '@namespace/device';
import { useHistory } from '@namespace/router';
import {
  EventHead,
  EventIcons,
  EventParticipants
} from '../../../../../../common';
import { LINE_VIEW } from '../../../../../../constants';
import { STATUS_TYPE_UNKNOWN } from '../../../../constants';
import { Outcomes } from './components/Outcomes';
import { EventTitle } from './components/EventTitle';
import { useEventLink } from '../../../../../../hooks/useEventLink';
import useSportsSettings from '../../../../../../hooks/useSportsSettings';
import styles from './index.module.css';

export const EventRow = ({ event, config, isLiveEvents, dataRole }) => {
  const { isDesktop } = useContext(DeviceContext);
  const { marketCount, statusType, eventId } = event;
  const [user] = useContext(UserContext);
  const { settings } = user;
  const { lineViewType: lineView } = settings;
  const linkToEventDetail = useEventLink({ eventId, event });
  const { push } = useHistory();
  const isUnknownEvent = statusType === STATUS_TYPE_UNKNOWN;
  const { isShowStatUrl = true, isShowVideo = true } = useSportsSettings();
  const { isHideUnselectedFavorite = true } = config;

  return (
    <div
      data-role={dataRole && `${dataRole}-${event.eventId}`}
      onClick={() =>
        isUnknownEvent || !linkToEventDetail ? null : push(linkToEventDetail)
      }
    >
      {lineView === LINE_VIEW.TABLE ? (
        <Box direction="column">
          <EventTitle eventId={eventId} />
          <Box
            flex="1 1 100%"
            className={`${styles.container} ${
              lineView ? styles[lineView] : ''
            }`}
          >
            <Box direction="column" flex="1 1 100%">
              <Box className={styles.participantsContainer}>
                <EventParticipants
                  eventId={eventId}
                  isActiveLink={!isUnknownEvent}
                  isDisplayAdditionalData={true}
                />
              </Box>
              <Box className={styles.iconsContainer} left="s">
                <EventHead
                  lineView={lineView}
                  isShowEventGroup={false}
                  eventId={event.eventId}
                  isWithTZ={false}
                  dataRole={dataRole}
                />
                <EventIcons
                  eventId={eventId}
                  isShowFavorite={!isDesktop}
                  isShowVideo={true}
                  isShowStatUrl={isShowStatUrl}
                  classNames={{ marketsCountClass: styles.marketsCount }}
                  isHideUnselectedFavorite={true}
                  isShowMarketsCount={!isUnknownEvent}
                  isLiveEvents={isLiveEvents}
                />
              </Box>
            </Box>
            {!isUnknownEvent && (
              <>
                <Box className={styles.eventSubRowContainer}>
                  <Outcomes
                    eventId={eventId}
                    lineView={lineView}
                    dataRole="live-calendar-outcome"
                  />
                </Box>
                <Box
                  flex="0 0 56px"
                  justify="center"
                  align="center"
                  right="s"
                  className={`${styles.more} ${
                    lineView ? styles[lineView] : ''
                  }`}
                >
                  <AText
                    size="m"
                    color="outcome_btn_txt"
                    className={styles.marketCount}
                  >
                    {`+${marketCount}`}
                  </AText>
                </Box>
              </>
            )}
          </Box>
        </Box>
      ) : (
        <Box direction="column" flex="1 1 100%" className={styles.container}>
          <Box flex="1 0 auto" direction="row" className={styles.headContainer}>
            <EventHead
              lineView={lineView}
              isShowEventGroup={false}
              eventId={eventId}
              isWithTZ={false}
            />
            <EventIcons
              eventId={eventId}
              isShowFavorite={!isDesktop}
              isShowVideo={isShowVideo}
              isShowStatUrl={isShowStatUrl}
              classNames={{ marketsCountClass: styles.marketsCount }}
              isIconsClickable={isDesktop}
              isHideUnselectedFavorite={isHideUnselectedFavorite}
              isShowMarketsCount={!isUnknownEvent}
              isLiveEvents={isLiveEvents}
            />
          </Box>
          <Box align="center" flex="1 1 100%">
            <Box
              flex="1 1 100%"
              align="center"
              className={styles.eventSubRowContainer}
            >
              <EventParticipants
                eventId={eventId}
                isActiveLink={!isUnknownEvent}
                isDisplayAdditionalData={true}
              />
              {!isUnknownEvent && (
                <Outcomes eventId={eventId} lineView={lineView} />
              )}
            </Box>
            {!isUnknownEvent && (
              <Box
                flex="1 0 55px"
                justify="center"
                align="center"
                right="s"
                className={`${styles.more} ${lineView ? styles[lineView] : ''}`}
              >
                <AText breed="B" size="m" color="outcome_btn_txt">
                  {`+${marketCount}`}
                </AText>
              </Box>
            )}
          </Box>
          <EventTitle eventId={eventId} />
        </Box>
      )}
    </div>
  );
};
