import { LINE_KEY } from '../../constants';
import { useFetchPopularEvents } from '../../hooks/useFetchPopularEvents';
import { useCyberSportGetPathParams } from '../../hooks/useCyberSportGetPathParams';
import { useRemoveLine } from '../../hooks/useRemoveLine';

const PopularSportEventsWrapper = () => {
  const {
    category: activeCategory,
    activeSport
  } = useCyberSportGetPathParams();
  const { categoryId } = activeCategory || {};
  const { sportId } = activeSport || {};
  const lineKey = `${LINE_KEY.POPULAR_BETS}-${sportId}`;

  useFetchPopularEvents({
    sportId,
    lineKey,
    categoryId
  });

  useRemoveLine(lineKey);

  return null;
};

export default PopularSportEventsWrapper;
