import { useI18n } from '@namespace/i18n';
import { getGameError } from '../../../utils';
import styles from './index.module.css';

const ErrorMessage = ({ errorCode = '' }) => {
  const { t } = useI18n();
  return <h3 className={styles.error}>{t(getGameError(errorCode))}</h3>;
};

export default ErrorMessage;
