export const AB_VARIANTS = {
  TEST_BETSLIP_BUTTON: 'test-betslip-button',
  PRESET_DEPOSIT_UAH: 'preset-deposit-uah'
};

export const AB_OPTIONS_VARIANTS = {
  VARIANT_A: 'variant-a',
  VARIANT_B: 'variant-b'
};

export const AB_TEST_COOKIE = 'AB_TEST_COOKIE';

export const AB_TEST_BONUSES_LOBBY_PREFIX = 'ab_test_bonuses_lobby';

export const AB_TEST_BONUSES_LOBBY = {
  COHORT1: 'ab_test_bonuses_lobby_Cohort1',
  COHORT2: 'ab_test_bonuses_lobby_Cohort2',
  COHORT3: 'ab_test_bonuses_lobby_Cohort3'
};
