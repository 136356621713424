import { createTheme } from '@material-ui/core/styles';
import { COLOR_SCHEME } from '@namespace/themes';
// eslint-disable-next-line import/no-unresolved
import edit from '../img/edit.svg?url';

export default ({ intentType }) =>
  createTheme({
    overrides: {
      MuiPaper: {
        rounded: {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: '2px',
          borderBottomRightRadius: '2px',
          borderTopRightRadius: 0
        },
        root: {
          color: 'var(--txt_color_3)'
        }
      },
      MuiPickersBasePicker: {
        pickerView: {
          backgroundColor: 'var(--bg_color_7)',
          maxWidth: '100%'
        },
        container: {
          fontFamily: `'Roboto Condensed', sans-serif`
        }
      },
      MuiTypography: {
        colorPrimary: {
          color: 'var(--accent_color_4)'
        },
        h4: {
          fontWeight: 700
        },
        body1: {
          fontWeight: 700
        }
      },
      MuiDialogActions: {
        root: {
          '&:first-child': {
            color: 'red'
          },
          'backgroundColor': 'var(--bg_color_7)'
        }
      },
      MuiButtonBase: {},
      MuiPickersDatePickerRoot: {
        toolbar: { backgroundColor: 'var(--bg_color_2)' }
      },
      MuiPickersYear: {
        root: {
          '&:focus': {
            color: 'inherit'
          }
        }
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          color: 'var(--bg_color_1)',
          textTransform: 'uppercase'
        },
        iconButton: {
          color: 'var(--accent_color_1)',
          backgroundColor: 'var(--bg_color_7)'
        },
        dayLabel: {
          textTransform: 'uppercase',
          color: 'var(--txt_color_7)',
          opacity: '0.5'
        },
        transitionContainer: {
          color: 'var(--txt_color_3)'
        }
      },
      MuiPickersDay: {
        day: {
          color: 'var(--txt_color_3)'
        },
        daySelected: {
          'color': 'var(--bg_color_7)',
          'backgroundColor': 'var(--accent_color_3)',
          '&:hover': {
            backgroundColor: 'var(--accent_color_3)'
          }
        },
        current: {
          color: 'var(--accent_color_3)'
        },
        dayDisabled: {
          color: 'var(--txt_color_3)',
          opacity: 0.4
        }
      },
      MuiFormControl: {
        root: {
          width: '100%'
        }
      },
      MuiInputBase: {
        root: {
          border:
            intentType === COLOR_SCHEME.SECONDARY
              ? 'var(--b-border-width-xs) solid var(--input_02_border_color_1)'
              : 'var(--b-border-width-xs) solid var(--input_border_color_1)',
          borderRadius:
            'var(--b-border-radius-custom, var(--b-border-radius-xs))'
        }
      },
      MuiInput: {
        underline: {
          '&:before': {
            display: 'none'
          },
          '&:after': {
            display: 'none'
          },
          'paddingLeft': '8px',
          'paddingTop': '0px',
          'paddingBottom': '0px',
          'fontFamily': 'var(--b-font-family-base)',
          'fontWeight': 'var(--b-font-weight-regular)',
          'lineHeight': 'var(--b-line-height-normal)'
        }
      },
      MuiIconButton: {
        root: {
          '&:hover': {
            backgroundColor: 'inherit'
          },
          '&.Mui-disabled': {
            color: 'var(--accent_color_1)',
            opacity: '0.5'
          },
          'paddingRight': '8px'
        }
      },
      MuiPickersToolbarText: {
        toolbarBtnSelected: {
          'textTransform': 'uppercase',
          'color': 'var(--txt_color_4)',
          'fontSize': '32px',
          '&:after': {
            display: 'none'
          }
        },
        toolbarTxt: {
          'position': 'relative',
          'fontWeight': 'bold',
          'color': 'var(--txt_color_6)',
          '&.MuiTypography-subtitle1': {
            '&:after': {
              position: 'absolute',
              color: 'var(--txt_color_6)',
              width: '12px',
              height: '12px',
              content: `url(${edit})`,
              right: '-16px',
              top: 0
            }
          }
        }
      },
      MuiButton: {
        textPrimary: {
          '&:first-child': {
            'backgroundColor': 'inherit',
            'color': 'var(--btn_quaternary_color_4)',
            '&:hover': {
              backgroundColor: 'inherit'
            }
          },
          'backgroundColor': 'var(--btn_primary_bg_color_3)',
          'color': 'var(--btn_primary_txt_color_3)',
          'minWidth': '50px',
          'height': '36px',
          'fontWeight': 'bold',
          'fontSize': '14px',
          '&:hover': {
            backgroundColor: 'var(--btn_primary_bg_color_3)',
            opacity: '0.7'
          }
        }
      },
      MuiFormHelperText: {
        root: {
          '&.Mui-error': {
            display: 'none'
          }
        }
      }
    }
  });
