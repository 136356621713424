import hasRuleError from '../hasRuleError';

const hasRulesError = (value, ruleList) => {
  let hasError = false;

  if (value && Boolean(ruleList?.length)) {
    hasError = Boolean(
      ruleList.find((rule) => hasRuleError(value, rule.rules))
    );
  }

  return hasError;
};

export default hasRulesError;
