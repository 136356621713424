import { SEGMENT_FIELDS, ALL_VALUE } from '../../constants';

const getVip = (segmentValue) =>
  segmentValue?.length ? segmentValue : ALL_VALUE;

const getAllArray = (segmentValue) =>
  segmentValue === ALL_VALUE ? ALL_VALUE : segmentValue;

const getBalance = (segmentValue) =>
  segmentValue !== null && segmentValue >= 0 ? segmentValue : ALL_VALUE;

const getBTAGArray = (segmentValue) =>
  segmentValue?.length ? segmentValue.split(',') : ALL_VALUE;

const getLanguages = (languages = [], segment = {}) =>
  languages?.length && segment.showForLanguages !== 'allLanguages'
    ? segment.languages
    : ALL_VALUE;

const getCountries = (countries = [], segment = {}) =>
  countries?.length && segment.showForCountries !== 'allCountries'
    ? segment.countries
    : ALL_VALUE;

const getAge = (segmentValue) =>
  segmentValue !== null && segmentValue >= 0 ? segmentValue : ALL_VALUE;

const getAccountVerified = (segmentValue) =>
  segmentValue === null || segmentValue === undefined
    ? ALL_VALUE
    : segmentValue;

const getDeposit = (segmentValue) =>
  segmentValue.length ? segmentValue : ALL_VALUE;

const parsersMap = {
  [SEGMENT_FIELDS.USER_DEPOSITED]: getDeposit,
  [SEGMENT_FIELDS.BALANCE_FROM]: getBalance,
  [SEGMENT_FIELDS.BALANCE_TO]: getBalance,
  [SEGMENT_FIELDS.USER_GROUP]: getAllArray,
  [SEGMENT_FIELDS.REGISTRATION]: getAllArray,
  [SEGMENT_FIELDS.VIP]: getVip,
  [SEGMENT_FIELDS.AFFILIATE_ID]: getBTAGArray,
  [SEGMENT_FIELDS.CREATIVE_ID]: getBTAGArray,
  [SEGMENT_FIELDS.SITE_TRACKER_ID]: getBTAGArray,
  [SEGMENT_FIELDS.ANID_ZONE_ID]: getBTAGArray,
  [SEGMENT_FIELDS.LANGUAGES]: getLanguages,
  [SEGMENT_FIELDS.COUNTRIES]: getCountries,
  [SEGMENT_FIELDS.AGE_FROM]: getAge,
  [SEGMENT_FIELDS.AGE_TO]: getAge,
  [SEGMENT_FIELDS.ACCOUNT_VERIFIED]: getAccountVerified
};

const normalizeSegments = (segments = []) =>
  segments.map((segment) => {
    const {
      id,
      segmentName,
      showForLanguages,
      showForCountries,
      ...rest
    } = segment;
    const parsedSegment = {
      // tech values, them not compare with filter
      techFields: {
        showForLanguages,
        showForCountries,
        segmentName,
        id
      }
    };
    for (const key of Object.keys(rest)) {
      const parser = parsersMap[key];
      if (parser) {
        const parsedValue = parser(segment[key], segment);
        if (parsedValue !== ALL_VALUE) {
          parsedSegment[key] = parsedValue;
        }
      }
    }

    return parsedSegment;
  });

export default normalizeSegments;
