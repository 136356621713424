import { useI18n } from '@namespace/i18n';
import { AText, Box } from '@alf/uikit';
import BracketItem from '../BracketItem';
import { useGetPredictedWinner } from '../../store/selectors';
import styles from './index.module.css';

const Winner = ({ isResults, classNames = {} }) => {
  const {
    wrapperClassName = '',
    bracketClassName = '',
    participantClassName = ''
  } = classNames;
  const { t } = useI18n();
  const { id, validPrediction } = useGetPredictedWinner();

  return (
    <Box align="center" justify="center" className={wrapperClassName}>
      <Box
        align="center"
        justify="center"
        direction="column"
        className={`${styles.bracket} ${bracketClassName}`}
      >
        <BracketItem
          country={id}
          validPrediction={validPrediction}
          isResults={isResults}
          className={`${styles.winner} ${participantClassName}`}
        />
        <AText breed="BU" size="l" color="txt_color_4" className={styles.title}>
          {t('predictions.stage.title.winner')}
        </AText>
      </Box>
    </Box>
  );
};

export default Winner;
