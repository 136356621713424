import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Icon } from '@namespace/icons2';
import { COLOR_SCHEME } from '@namespace/themes';
import { kebabCase } from 'lodash';
import { eventHandlerWrapper } from '../../../../utils/eventHandlerWrapper';
import styles from './index.module.css';

export const MobileSelect = ({
  options,
  onChange,
  onBlur,
  value,
  style,
  disabled,
  placeholder,
  size = 'xs',
  arrowSize,
  iconDropDownName = 'icons/general/nav/dropdown_down',
  intent = 'default',
  className,
  dataRole,
  mobileSelectStyle = '',
  intentType,
  showDropdownIconWhenOnlyOneOption,
  disableDropdownWhenOnlyOneOption,
  bottomElement = null
}) => (
  <div
    style={style}
    data-role={dataRole}
    className={clsx([
      styles.mobileWrapper,
      styles[size],
      styles[intent],
      (disabled ||
        (disableDropdownWhenOnlyOneOption && options.length === 1)) &&
        styles.disabled,
      placeholder && !value && styles.placeholder,
      className
    ])}
  >
    {options.find((item) => item.value === value)?.iconElement ?? null}
    <select
      value={value}
      className={clsx([styles.select, styles[intentType], mobileSelectStyle])}
      onChange={eventHandlerWrapper(onChange, options)}
      onBlur={eventHandlerWrapper(onBlur, options)}
    >
      {placeholder && !value && (
        <option value="" disabled={true} selected={true}>
          {placeholder}
        </option>
      )}
      {options.map((option) => (
        <option
          value={option.value}
          data-role={`option-${kebabCase(option.value)}`}
          key={`${option.value}-${option.title}`}
        >
          {option.title}
        </option>
      ))}
    </select>
    {((showDropdownIconWhenOnlyOneOption && options.length === 1) ||
      options.length > 1) && (
      <Icon
        name={iconDropDownName}
        className={clsx(
          styles.icon,
          styles[intentType],
          disabled && styles.disabled
        )}
        size={arrowSize || size}
      />
    )}
    {bottomElement}
  </div>
);

MobileSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  intent: PropTypes.oneOf(['error', 'success', 'default']),
  size: PropTypes.oneOf(['xs', 's', 'm']),
  arrowSize: PropTypes.string,
  style: PropTypes.object,
  intentType: PropTypes.oneOf([COLOR_SCHEME.PRIMARY, COLOR_SCHEME.SECONDARY]),
  showDropdownIconWhenOnlyOneOption: PropTypes.bool,
  disableDropdownWhenOnlyOneOption: PropTypes.bool
};
