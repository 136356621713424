import { useCallback } from 'react';
import { matchPath } from 'react-router-dom';
import { useNavigate } from '@namespace/router';
import { window, removeLangFromPathname } from '@namespace/helpers';
import { useCurrentStep } from '../../../hooks';

export const useStepUrlNavigate = () => {
  const { navigate: stepNavigate, navigateExact } = useCurrentStep();
  const navigate = useNavigate();

  return useCallback(() => {
    if (stepNavigate == null) {
      return;
    }

    const match = matchPath(
      {
        path: stepNavigate,
        end: navigateExact
      },
      removeLangFromPathname(window.location.pathname)
    );

    if (match) {
      return;
    }

    navigate(stepNavigate); // todo wait for navigate? HOW?
    // NB 'navigate' from react-router 6 is now changing constantly which breaks usePrepareStepRender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepNavigate, navigateExact]);
};
