import { postAsUriParameters } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

export const getList = (filter, platform, route) => {
  const { currentPage, pageSize, language, stateList } = filter;
  return postAsUriParameters(
    route,
    {
      state_list: stateList,
      lang: language,
      page: currentPage,
      page_size: pageSize,
      platform
    },
    true
  ).then(({ error, response }) =>
    error === 'no' ? camelizeKeys(response) : []
  );
};
