import { PanKeeperForm } from '@namespace/pankeeper';
import { TaxRate, CityField, CashDesk, PaymentIcons } from './components';

export const PAYMENT_SYSTEMS_COMPONENTS_MAP = {
  TAX_RATE: 'tax-rate',
  CITY_FIELD: 'city-field',
  CASH_DESK: 'cash-desk',
  PAYMENT_ICONS: 'payment-icons',
  PAN_KEEPER_FORM: 'pan-keeper'
};

export const paymentSystemsComponentsMap = {
  [PAYMENT_SYSTEMS_COMPONENTS_MAP.TAX_RATE]: TaxRate,
  [PAYMENT_SYSTEMS_COMPONENTS_MAP.CITY_FIELD]: CityField,
  [PAYMENT_SYSTEMS_COMPONENTS_MAP.CASH_DESK]: CashDesk,
  [PAYMENT_SYSTEMS_COMPONENTS_MAP.PAYMENT_ICONS]: PaymentIcons,
  [PAYMENT_SYSTEMS_COMPONENTS_MAP.PAN_KEEPER_FORM]: PanKeeperForm
};
