import PropTypes from 'prop-types';
import Item from './components/Item';
import styles from './index.module.css';

const Menu = ({
  items: menuItems = [],
  NavLink,
  isTop = false,
  className = ''
}) => (
  <ul className={`${styles.menu} ${className}`}>
    {menuItems.map((item) => {
      const { link, iKey = [], title, items } = item;
      return (
        <Item
          key={`${link}${title}${iKey}`}
          item={item}
          isTop={isTop}
          NavLink={NavLink}
          menu={<Menu items={items} NavLink={NavLink} />}
        />
      );
    })}
  </ul>
);

Menu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      link: PropTypes.string,
      title: PropTypes.string
    })
  ),
  NavLink: PropTypes.func,
  isTop: PropTypes.bool,
  className: PropTypes.string
};

export default Menu;
