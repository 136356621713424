import detectFormFactor from '../detectFormFactor';
import detectOrientation from '../detectOrientation';
import detectOs from '../detectOs';
import updatePageType from '../updatePageType';
import isIE from '../isIE';

const detectDevice = (screenType) => ({
  ...detectFormFactor(),
  ...detectOrientation(),
  ...detectOs(),
  ...updatePageType(screenType),
  isIE: isIE()
});

export default detectDevice;
