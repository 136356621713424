import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { noop, identity } from 'lodash';
import { Icon } from '@namespace/icons2';
import Box from '../Box';
import AText from '../AText';
import { copyTextToClipboard } from '../../utils/copyTextToClipboard';
import styles from './index.module.css';

export const TextCopy = ({
  textDisplay = '',
  textCopy = '',
  textNotify = '',
  customNotification = noop,
  t = identity,
  color,
  iconColor,
  iconSize,
  iconLink,
  iconLeft = false,
  gap,
  dataRoles = {},
  ...textProps
}) => {
  const notifyMessage = t('uikit.textCopy.notification', [textNotify]);
  const { dataRoleText, dataRoleIcon } = dataRoles;

  const onClick = useCallback(
    async (e) => {
      // prevent parent container from closing when trying to select/copy text
      e.stopPropagation();

      await copyTextToClipboard(textCopy);

      customNotification({
        notificationType: 'info',
        message: notifyMessage,
        timeout: 3000
      });
    },
    [notifyMessage, customNotification, textCopy]
  );

  return (
    <Box
      gap={gap || 's'}
      onClick={onClick}
      align="center"
      className={styles.root}
    >
      {iconLeft && (
        <Icon
          size={iconSize || 's'}
          name={iconLink || 'icons/general/action/copy'}
          color={iconColor || color}
          data-role={dataRoleIcon}
        />
      )}
      <AText {...textProps} color={color} data-role={dataRoleText}>
        {textDisplay}
      </AText>
      {!iconLeft && (
        <Icon
          size={iconSize || 's'}
          name={iconLink || 'icons/general/action/copy'}
          color={iconColor || color}
          data-role={dataRoleIcon}
        />
      )}
    </Box>
  );
};

TextCopy.propTypes = {
  ...AText.propTypes,
  textDisplay: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textCopy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textNotify: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customNotification: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  dataRoles: PropTypes.object,
  iconSize: PropTypes.string,
  iconLink: PropTypes.string,
  iconLeft: PropTypes.bool
};
