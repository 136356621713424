import { useCallback, useContext, useRef } from 'react';
import { DeviceContext } from '@namespace/device';
import { useFixScroll, CommonLoader } from '@namespace/common';
import { useI18n } from '@namespace/i18n';
import { Overlay, AText, Box } from '@alf/uikit';
import { UserContext, useShowQuickDeposit } from '@namespace/user';
import { QuickDepositButton } from '@namespace/payments-sw';
import Payments from '../../components/Deposit/Payments';
import { useDeposit } from '../../hooks/useDeposit';
import styles from './index.module.css';

const QuickDeposit = () => {
  const { t } = useI18n();
  const containerRef = useRef(null);
  const { isDesktop } = useContext(DeviceContext);
  const [user] = useContext(UserContext);
  const { activeWallet } = user;
  const { isShowQuickDeposit, toggleQuickDeposit } = useShowQuickDeposit();
  const { isFetching, onDeposit } = useDeposit();
  const isMobileQuickDeposit = isShowQuickDeposit && !isDesktop;
  useFixScroll('depositButton', containerRef, isMobileQuickDeposit);
  const ref = useRef(null);
  const contentRef = useRef(null);
  const scrollToElement = useCallback(
    (elHeight) =>
      ref.current.scrollTo(0, contentRef.current.offsetTop + elHeight),
    [ref, contentRef]
  );

  return isShowQuickDeposit ? (
    <Box className={styles.wrapper}>
      <Overlay
        onClick={toggleQuickDeposit}
        classNames={{ wrapperClass: `${styles.overlay}` }}
      />
      <Box
        ref={ref}
        direction="column"
        padding={isShowQuickDeposit ? 'm s' : 'm 0'}
        className={`${styles.container} ${
          isShowQuickDeposit ? styles.quickDeposit : ''
        }`}
      >
        <Box align="center" justify="between" className={styles.header}>
          <AText
            breed="BU"
            size="m"
            color="txt_color_1"
            transform="uppercase"
            className={isMobileQuickDeposit ? styles.mobileTitle : ''}
          >
            {t('personalOffice.paymentMethod')}
          </AText>
          {isMobileQuickDeposit && <QuickDepositButton size="l" />}
        </Box>
        <Box margin="s 0 0" className={styles.content} ref={contentRef}>
          {isFetching && (
            <Overlay>
              <CommonLoader />
            </Overlay>
          )}
          <Payments
            onDeposit={onDeposit}
            wallet={activeWallet}
            isQuickDeposit={true}
            onExpand={scrollToElement}
          />
        </Box>
      </Box>
    </Box>
  ) : null;
};

export default QuickDeposit;
