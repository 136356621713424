import { useGeneralRoutes } from '@namespace/cms';
import ErrorMessage from './ErrorMessage';
import ErrorLink from './ErrorLink';

const GameError = ({ errorCode }) => {
  const { wallets } = useGeneralRoutes();

  switch (errorCode) {
    case 'ihub_1003':
      return <ErrorLink to={wallets} errorCode={errorCode} />;
    default:
      return <ErrorMessage errorCode={errorCode} />;
  }
};

export default GameError;
