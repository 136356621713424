import { useCallback, useContext } from 'react';
import { updatePhone } from '../../api';
import { UserContext } from '../../context';

const useUpdatePhoneNumber = () => {
  const [user] = useContext(UserContext);
  const { id } = user;
  return useCallback(
    async (phoneNumber) => {
      const response = await updatePhone(phoneNumber, id);
      if (response) {
        return phoneNumber;
      }
      throw new Error('accounting_error_134');
    },
    [id]
  );
};

export default useUpdatePhoneNumber;
