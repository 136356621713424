import { useCallback, useState, useContext, useMemo, forwardRef } from 'react';
import { DeviceContext } from '@namespace/device';
import { UserContext } from '@namespace/user';
import { useI18n, LocalizedNavLink, useLocation } from '@namespace/i18n';
import { Tabs } from '@alf/uikit';
import { PageComponentsContext } from '@namespace/cms';
import styles from './index.module.css';

export const TabsMenu = forwardRef(
  ({ config, pageComponents, classNames = {} }, ref) => {
    const { t } = useI18n();
    const componentsMap = useContext(PageComponentsContext);

    const { isDesktop } = useContext(DeviceContext);
    const { pathname } = useLocation();

    const {
      components: childComponents = [],
      defaultTabIndex = 0,
      privateTabs = []
    } = config;

    const [user] = useContext(UserContext);
    const { id: userId } = user;
    const [activeTabIndex, setActiveTabIndex] = useState(defaultTabIndex);

    const handleSetIndex = useCallback(
      (index) => {
        setActiveTabIndex(index);
      },
      [setActiveTabIndex]
    );

    const tabs = useMemo(
      () =>
        childComponents
          .filter(
            (componentId) => !(privateTabs.includes(componentId) && !userId)
          )
          .map((componentId) => {
            const Content = componentsMap[pageComponents[componentId]?.type];

            return {
              label: t(`menu.navigation.${pageComponents[componentId]?.type}`),
              component: (
                <Content id={componentId} setActiveTabIndex={handleSetIndex} />
              ),
              onActive: handleSetIndex
            };
          }),
      [
        childComponents,
        privateTabs,
        userId,
        componentsMap,
        pageComponents,
        t,
        handleSetIndex
      ]
    );

    return (
      <Tabs
        list={tabs}
        activeTabIndex={activeTabIndex}
        isDesktop={isDesktop}
        ref={ref}
        LocalizedNavLink={LocalizedNavLink}
        pathname={pathname}
        classNames={{
          tabsClassName: `${styles.container}`,
          componentClassName: styles.overflow,
          ...classNames
        }}
      />
    );
  }
);
