import { useCallback, useContext, useEffect, useMemo } from 'react';
import {
  getLocalStorageValue,
  LOCAL_STORAGE_KEYS,
  setLocalStorageValue
} from '@namespace/helpers';
import { useInboxMessages } from './useInboxMessages';
import { MailBoxContext } from '../store/mailBox/context';
import { MESSAGE_IMPORTANCE } from '../constants';

export const useNotificationPopup = (isHeaderVisible) => {
  const { inboxMessages } = useInboxMessages({ skipFetching: true });
  const [state, actions] = useContext(MailBoxContext);
  const { isImportantMessage } = state;
  const { SET_IMPORTANT_MESSAGE } = actions;
  const lastMessage = getLocalStorageValue(
    LOCAL_STORAGE_KEYS.READ_CRITICAL_MESSAGE
  );

  const importantMessages = useMemo(
    () =>
      inboxMessages
        ?.filter(
          ({ importance, read }) =>
            importance === MESSAGE_IMPORTANCE.CRITICAL && !read
        )
        .sort((a, b) => b.id - a.id) ?? [],
    [inboxMessages]
  );
  const isNotify =
    isHeaderVisible && importantMessages.length > 0 && !lastMessage;

  useEffect(() => {
    SET_IMPORTANT_MESSAGE(isNotify);
  }, [lastMessage, importantMessages, isHeaderVisible]);

  const onClose = useCallback(() => {
    setLocalStorageValue(LOCAL_STORAGE_KEYS.READ_CRITICAL_MESSAGE, 1);
    SET_IMPORTANT_MESSAGE(false);
  }, [SET_IMPORTANT_MESSAGE, importantMessages]);

  return { importantMessages, onClose, isImportantMessage };
};
