import BasePreMatchEvents from '../../common/BasePreMatchEvents';
import { useCyberSportGetPathParams } from '../../hooks/useCyberSportGetPathParams';
import useGetActualCyberSportCategory from '../../hooks/useGetActualCyberSportCategory';

const CyberSportPreMatchCategoryEvents = ({ id }) => {
  const { activeSport } = useCyberSportGetPathParams();
  const categories = useGetActualCyberSportCategory();

  return (
    <BasePreMatchEvents
      activeSport={activeSport}
      categories={categories}
      id={id}
      isReady={!!activeSport}
    />
  );
};

// Add this wrapper with key to force component umnount on sport change
// to restart all starting soon logic
// It's impossible to make another way
const CyberSportPreMatchCategoryEventsWrapper = (props) => {
  const { activeSport } = useCyberSportGetPathParams();
  const categories = useGetActualCyberSportCategory();
  const { sportId } = activeSport || {};
  const { categoryId = '' } = categories[0] || {};

  return (
    <CyberSportPreMatchCategoryEvents
      {...props}
      key={`${sportId}${categoryId}`}
    />
  );
};
export default CyberSportPreMatchCategoryEventsWrapper;
