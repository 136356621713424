import extractNumber from '../extractNumber';

const filterByCountryOrCode = (value) => (item) => {
  const numberOnly = extractNumber(value);
  const loweredValue = value.toLowerCase();
  const names = item.countryName.toLowerCase().split(' ');

  for (const name of names) {
    if (name.startsWith(loweredValue)) {
      return true;
    }
  }

  if (numberOnly.length) {
    return item.phoneCode.includes(numberOnly);
  }

  return false;
};

export default filterByCountryOrCode;
