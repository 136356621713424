import { useContext, useEffect, useState } from 'react';
import { UserContext } from '@namespace/user/src/context';
import { USER_ROLES } from '@namespace/user/src/constants';
import { getWebimInfo } from '../../api/getWebimInfo';

export const useGetWebimInfo = (webimGuestMode) => {
  const [webimData, setWebimData] = useState();
  const [user] = useContext(UserContext);
  const { role } = user;

  useEffect(() => {
    if (!webimGuestMode) {
      (async () => {
        if (role === USER_ROLES.USER) {
          try {
            const { response: webimInfo } = await getWebimInfo();
            setWebimData(webimInfo);
          } catch (e) {
            console.log(e);
          }
        }
      })();
    }
  }, [role, webimGuestMode]);

  return { webimData };
};

export default useGetWebimInfo;
