import { useContext } from 'react';
import { useI18n } from '@namespace/i18n';
import { UserContext } from '@namespace/user/src/context';
import BonusHunterBlock from './BonusHunterBlock';

// TODO: discuss with @i.postol, maybe we need another category of wrappers like componentWrappers or move it to common components or something other
const BonusHunterWrapper = ({ children }) => {
  const { t } = useI18n();
  const [user] = useContext(UserContext);
  const { accountStatuses = {} } = user;
  const { isBonusHunter } = accountStatuses;

  if (!isBonusHunter) {
    return children;
  }

  return <BonusHunterBlock text={t('bonusHunter.unavailableSection')} />;
};

export default BonusHunterWrapper;
