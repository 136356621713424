import { useConfig } from '@namespace/cms';
import Bracket from '../../common/Bracket';
import { PREDICTION_STAGES } from '../../constants';
import styles from './index.module.css';

const TournamentBracket = ({ id }) => {
  const { config = {} } = useConfig(id);
  const { isMobilePage } = config;
  return (
    <Bracket
      stages={PREDICTION_STAGES}
      className={`${styles.bracket} ${
        isMobilePage ? styles.mobileBracket : ''
      }`}
    />
  );
};

export default TournamentBracket;
