import PropTypes from 'prop-types';
import { Handles, Rail, Slider, Tracks } from 'react-compound-slider';
import clsx from 'clsx';
import Handle from './components/Handle';
import Box from '../Box';
import SliderRail from './components/SliderRail';
import Track from './components/Track';
import styles from './index.module.css';

const CompoundSlider = ({
  domain,
  defaultValues,
  onUpdate,
  mode = 2,
  step = 1,
  className
}) => (
  <Box
    className={clsx(className, styles.container)}
    data-role="compound-slider"
  >
    <Slider
      mode={mode}
      step={step}
      domain={domain}
      className={styles.rootSlider}
      onUpdate={onUpdate}
      values={defaultValues}
    >
      <Rail>
        {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
      </Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <Box direction="column">
            {handles.map((handle) => (
              <Handle
                key={handle.id}
                handle={handle}
                domain={domain}
                getHandleProps={getHandleProps}
              />
            ))}
          </Box>
        )}
      </Handles>
      <Tracks left={false} right={false}>
        {({ tracks, getTrackProps }) => (
          <Box direction="column">
            {tracks.map(({ id, source, target }) => (
              <Track
                key={id}
                source={source}
                target={target}
                getTrackProps={getTrackProps}
              />
            ))}
          </Box>
        )}
      </Tracks>
    </Slider>
  </Box>
);

CompoundSlider.propTypes = {
  domain: PropTypes.array.isRequired,
  defaultValues: PropTypes.array,
  onUpdate: PropTypes.func,
  mode: PropTypes.number,
  step: PropTypes.number,
  className: PropTypes.string
};

export default CompoundSlider;
