import { useContext } from 'react';
import { SiteSettingsContext } from '@namespace/cms';
import { convertOutcome } from '@namespace/bets';
import { BET_SLIP_STATUS_MAP, VIP_BETSLIP_INFO_ERRORS } from '../../constants';
import { BetSlipContext } from '../../store/context';

const useBetSlip = (outcome = {}, market = {}) => {
  const { outcomeId, outcomeVisible, outcomeCoef } = outcome;
  const {
    marketSuspend,
    serviceId,
    eventId,
    marketId,
    marketTemplateId
  } = market;
  const [betSlip, betSlipActions] = useContext(BetSlipContext);
  const {
    bets = [],
    isProcessing,
    isDone,
    status,
    oddsType,
    basketErrors = []
  } = betSlip;
  const {
    INCREMENT_OUTCOME_CLICK_COUNTER,
    SET_BETSLIP_STATUS,
    ADD_TO_STORE,
    DELETE_FROM_STORE,
    CLEAN_STORE
  } = betSlipActions;
  const checkedOutcomesId = bets.map((item) => item.outcome_id);

  const [settings = {}] = useContext(SiteSettingsContext);
  const { siteConfig = {} } = settings;
  const { placeBetIsActive = true } = siteConfig;

  const addBet = () => {
    ADD_TO_STORE({
      bets: [
        {
          serviceId,
          eventId,
          marketId,
          outcomeId
        }
      ]
    }).catch(console.error);
  };

  const deleteBet = () => {
    DELETE_FROM_STORE({
      bets: [
        {
          serviceId,
          eventId,
          outcomeId
        }
      ]
    }).catch((error) => console.error(error));
  };

  const betHandler = () => {
    if (!placeBetIsActive) return false;
    const errorKey = basketErrors?.errors?.[0]?.c;
    const isVipBettingPropose = VIP_BETSLIP_INFO_ERRORS.includes(errorKey);
    if (
      !marketSuspend &&
      outcomeVisible &&
      !isProcessing &&
      !isVipBettingPropose
    ) {
      INCREMENT_OUTCOME_CLICK_COUNTER();
      if (checkedOutcomesId.includes(outcomeId)) {
        if (status !== BET_SLIP_STATUS_MAP.ACTIVE) {
          SET_BETSLIP_STATUS({ status: BET_SLIP_STATUS_MAP.ACTIVE });
        }
        deleteBet();
      } else if (!isProcessing && isDone) {
        CLEAN_STORE()
          .then(() => {
            addBet();
            SET_BETSLIP_STATUS({ status: BET_SLIP_STATUS_MAP.ACTIVE });
          })
          .catch(console.error);
      } else {
        addBet();
        SET_BETSLIP_STATUS({ status: BET_SLIP_STATUS_MAP.ACTIVE });
      }
    }

    return null;
  };

  return {
    placeBet: betHandler,
    isSelected: checkedOutcomesId.includes(outcomeId),
    coef: convertOutcome(outcomeCoef, marketTemplateId, oddsType)
  };
};

export default useBetSlip;
