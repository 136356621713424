import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { isNil } from 'lodash';
import { Icon } from '@namespace/icons2';
import Box from '../Box';
import { ICONS_LOCATIONS } from './constants';
import styles from './index.module.css';

const Accordion = ({
  children,
  dataRole,
  title = '',
  classNames: {
    containerClassName = '',
    headerClassName = '',
    openBodyClassName = '',
    bodyClassName = '',
    openAccordion = '',
    iconWrapperClassName = ''
  } = {},
  icon = null,
  withArrows = false,
  arrowDownName = null,
  arrowUpName = null,
  arrowsColor = null,
  arrowsSize = null,
  isOpenDefault = false,
  isCollapsible = true,
  arrowsLocation = ICONS_LOCATIONS.END,
  onToggle,
  isOpen
}) => {
  const arrowsLocationStart = arrowsLocation === ICONS_LOCATIONS.START;
  const arrowsLocationEnd = arrowsLocation === ICONS_LOCATIONS.END;
  const isControlled = !isNil(isOpen);
  const [isOpenLocally, setIsOpenLocally] = useState(isOpenDefault);
  const actualIsOpen = isControlled ? isOpen : isOpenLocally;

  const toggleAccordion = useCallback(() => {
    if (!isCollapsible) return;

    if (onToggle) onToggle(!actualIsOpen);
    if (!isControlled) setIsOpenLocally(!actualIsOpen);
  }, [isCollapsible, actualIsOpen, onToggle, isControlled]);

  const arrows = (
    <Icon
      color={arrowsColor || 'line_txt_2'}
      size={arrowsSize || 's'}
      name={
        actualIsOpen
          ? arrowUpName || 'icons/general/nav/arrow-up'
          : arrowDownName || 'icons/general/nav/arrow-down'
      }
    />
  );

  const arrowsWrapper = withArrows && (
    <Box className={arrowsLocationStart ? styles.iconStart : styles.iconEnd}>
      {arrows}
    </Box>
  );

  const iconWrapper = icon && (
    <Box className={clsx([styles.iconWrapper, iconWrapperClassName])}>
      {icon}
    </Box>
  );

  return (
    <Box
      direction="column"
      className={clsx([containerClassName, actualIsOpen && openBodyClassName])}
      data-role={dataRole && `${dataRole}-accordion-trigger`}
    >
      <Box
        data-role="accordion-handle"
        align="center"
        className={clsx([
          styles.header,
          headerClassName,
          isCollapsible && styles.collapsible,
          !actualIsOpen && openAccordion
        ])}
        onClick={toggleAccordion}
      >
        {arrowsLocationStart && arrowsWrapper}
        {iconWrapper}
        {title}
        {arrowsLocationEnd && arrowsWrapper}
      </Box>
      {actualIsOpen && (
        <Box
          data-role={dataRole && `${dataRole}-accordion-content`}
          className={clsx([
            styles.body,
            bodyClassName,
            actualIsOpen && styles.opened
          ])}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  isOpenDefault: PropTypes.bool,
  onToggle: PropTypes.func,
  icon: PropTypes.node,
  withArrows: PropTypes.bool,
  arrowDownName: PropTypes.string,
  arrowUpName: PropTypes.string,
  arrowsColor: PropTypes.string,
  arrowsSize: PropTypes.string,
  classNames: PropTypes.shape({
    headerClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    openBodyClassName: PropTypes.string,
    bodyClassName: PropTypes.string,
    openAccordion: PropTypes.string
  }),
  isCollapsible: PropTypes.bool,
  arrowsLocation: PropTypes.string,
  dataRole: PropTypes.string
};

export default Accordion;
