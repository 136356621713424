import { window } from '@namespace/helpers';
import { LANDSCAPE, PORTRAIT } from '../../constants';

const detectOrientation = () => {
  let orientation;

  if ('orientation' in window) {
    orientation = Math.abs(window.orientation) === 90 ? LANDSCAPE : PORTRAIT;
  } else {
    orientation =
      window.innerHeight / window.innerWidth < 1 ? LANDSCAPE : PORTRAIT;
  }

  return {
    isLandscape: orientation === LANDSCAPE,
    isPortrait: orientation === PORTRAIT
  };
};

export default detectOrientation;
