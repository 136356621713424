import styles from './index.module.css';

export const CasinoSkelet = ({ className }) => (
  <>
    <img
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAM8AAADPCAQAAAD4iE3UAAABNElEQVR42u3RMQ0AAAzDsJU/6aGo1MOBEOc0XCzAIzx4hEd48AgPHuERHjzCg0d4hAeP8OARHuHBIzx4hEd48AiP8OARHjzCIzx4hAeP8AgPHuHBIzzCg0d48AiP8OARHuHBIzx4hEd48AgPHuERHjzCg0d4hAeP8OARHuHBIzzCg0d48AiP8OARHjzCIzx4hAeP8AgPHuHBIzzCg0d4hAeP8OARHuHBIzx4hEd48AgPHuERHjzCg0d4hAeP8AgPHuHBIzzCg0d48AiP8OARHjzCIzx4hAeP8AgPHuERHjzCg0d4hAeP8OARHuHBIzx4hEd48AgPHuERHjzCIzx4hAeP8AgPHuHBIzzCg0d48AiP8OARHjzCIzx4hEd48AgPHuERHjzCg0d4hAeP8OARHuHBo2IPqoAA0GWeeRAAAAAASUVORK5CYII="
      alt="clear rectangle"
      className={`${styles.clearRectangle} ${className}`}
    />
    <div className={`${styles.skeletBlock} ${className}`} />
  </>
);
