import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import useDepositBonusCardContent from '@namespace/bonuses/src/hooks/useDepositBonusCardContent';
import styles from './index.module.css';

const BonusSliderItemDescription = ({ bonus, userCurrency }) => {
  const { t } = useI18n();

  const {
    bonusTitle,
    bonusDescriptionTitle1,
    bonusDescriptionTitle2,
    bonusDescriptionValue1,
    bonusDescriptionValue2
  } = useDepositBonusCardContent({ bonus, userCurrency });

  return (
    <Box className={styles.textWrapper} padding="s" direction="column">
      <AText className={styles.title} breed="BU" size="s" color="line_txt_1">
        {bonusTitle}
      </AText>
      <Box className={styles.text} padding="0 0 xs 0">
        <AText
          breed="R"
          size="s"
          color="po_txt_color_3"
          className={styles.textTitle}
        >
          {t(bonusDescriptionTitle1)}
        </AText>
        <Box className={styles.textValue}>
          <AText breed="B" size="s" color="po_txt_color_1">
            {bonusDescriptionValue1}
          </AText>
        </Box>
      </Box>
      <Box>
        <AText
          breed="R"
          size="s"
          color="po_txt_color_3"
          className={styles.textTitle}
        >
          {t(bonusDescriptionTitle2)}
        </AText>
        <AText breed="B" size="s" color="po_txt_color_1">
          {bonusDescriptionValue2}
        </AText>
      </Box>
    </Box>
  );
};

export default BonusSliderItemDescription;
