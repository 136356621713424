import { useCallback, useRef } from 'react';
import { document } from '../../constants';

const useSetBodyClass = () => {
  const bodyRef = useRef(document.body);

  const setBodyClass = useCallback((className) => {
    if (bodyRef.current) {
      bodyRef.current.classList.add(className);
    }
  }, []);

  const removeBodyClass = useCallback((className) => {
    if (bodyRef.current) {
      bodyRef.current.classList.remove(className);
    }
  }, []);

  const isClassExist = useCallback(
    (className) =>
      bodyRef.current ? bodyRef.current.classList.contains(className) : false,
    []
  );

  return {
    setBodyClass,
    removeBodyClass,
    isClassExist
  };
};

export default useSetBodyClass;
