import { useEffect, useCallback } from 'react';
import { useLocation } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import {
  SESSION_STORAGE_KEYS,
  getSessionStorageValue,
  removeSessionStorageItem,
  setSessionStorageValue
} from '@namespace/helpers';
import { REDIRECT_ACTIONS } from '../../constants';

const useRedirectAction = (type = REDIRECT_ACTIONS.SET) => {
  const { search, pathname } = useLocation();
  const { replace } = useHistory();

  const query = new URLSearchParams(search);
  const action = query.get('action');

  const applyRedirect = useCallback(() => {
    const isFromDeposit = getSessionStorageValue(
      SESSION_STORAGE_KEYS.FROM_DEPOSIT
    );
    const redirectURL = getSessionStorageValue(
      SESSION_STORAGE_KEYS.REDIRECT_URL
    );

    if (redirectURL) {
      if (isFromDeposit) {
        removeSessionStorageItem(SESSION_STORAGE_KEYS.FROM_DEPOSIT);
      }

      removeSessionStorageItem(SESSION_STORAGE_KEYS.REDIRECT_URL);
      replace(redirectURL);
    }
  }, [replace, action]);

  const setRedirect = useCallback(() => {
    if (action) {
      setSessionStorageValue(SESSION_STORAGE_KEYS.REDIRECT_URL, action);
      replace(`${pathname}${search}`);
    }
  }, [pathname, replace, action]);

  useEffect(() => {
    if (type === REDIRECT_ACTIONS.APPLY) {
      applyRedirect();
    } else {
      setRedirect();
    }
  }, [type, applyRedirect, setRedirect]);

  return null;
};

export default useRedirectAction;
