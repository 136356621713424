import { Box } from '@alf/uikit';
import {
  FORM_VALIDATION_TYPES,
  InputField,
  formBuilderValidationMap
} from '@namespace/forms';
import { createValidationRules } from '@alf/validators';
import { CARD_INFO } from '../../../constants';
import styles from './index.module.css';

const CardBack = () => (
  <Box direction="column" align="end" className={styles.cardBack}>
    <div className={styles.line} />
    <InputField
      classNames={{
        containerClassName: styles.inputWrapperCVV,
        inputClassName: styles.inputCVV,
        labelClassName: styles.labelCVV
      }}
      placeholder={CARD_INFO.CVV}
      mask="999"
      maskChar=""
      withTooltip={true}
      size="xs"
      label="panKeeper.card.label.cvv"
      value=""
      name={CARD_INFO.CVV}
      rules={createValidationRules(
        [
          FORM_VALIDATION_TYPES.REQUIRED,
          {
            type: FORM_VALIDATION_TYPES.MIN_LENGTH_VALIDATOR,
            parameters: [3]
          }
        ],
        formBuilderValidationMap
      )}
    />
  </Box>
);

export default CardBack;
