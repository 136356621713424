import { memo, useContext, useMemo } from 'react';
import { pathOr } from 'ramda';
import {
  CoreList,
  SportList,
  UserSettingsBurger,
  useUserSettings
} from '@namespace/common';
import { SiteSettingsContext, useGeneralRoutes } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { AText, Box } from '@alf/uikit';
import { useUserSegmentsIncludes } from '@namespace/segments';
import FavoritesLink from '../../../common/FavoritesLink';
import { useSportsbookStore } from '../../../store';
import { useAdvFilterStore } from '../../../storeAdvFilter';
import SportsBookSearch from '../../SportsBookSearch';
import { MODE, SPORT_ICONS } from '../../../constants';
import { CustomFilterLink, PinnedIcon } from '../../../common';
import styles from './index.module.css';

const PreMatchBurger = ({
  config = {},
  onSearchClicked = () => {},
  onClick
}) => {
  const { subMenu = [], isDisplayLiveCalendar = false } = config;
  const { preMatchSport, preMatchFavorites } = useGeneralRoutes();
  const [{ menuTags = {} }] = useContext(SiteSettingsContext);
  const { t } = useI18n();
  const sports = useSportsbookStore(pathOr([], ['sports', MODE.PRE_MATCH]));
  const favoritesSports = useSportsbookStore(
    pathOr([], ['favorites', 'sports'])
  );
  const advFilter = useAdvFilterStore('advFilter');
  const filterSegments = useUserSegmentsIncludes();
  const subMenuItems = useMemo(
    () =>
      subMenu
        .map(({ id, link, title, tag, logoUrl, ...rest }) => ({
          id,
          link,
          title: t(title),
          tag: tag ? t(`burger.submenu.${tag}`) : '',
          tagColor: menuTags[tag]?.tagBackground,
          logoUrl,
          ...rest
        }))
        .filter((item) => filterSegments(item.userSegments)),
    [filterSegments, menuTags, subMenu, t]
  );

  const items = useMemo(
    () =>
      sports
        .map(({ sportId, sportName, sportSlug, eventCount }) => ({
          id: sportId,
          icon: SPORT_ICONS[sportId] || SPORT_ICONS.DEFAULT,
          link: `${preMatchSport}/${sportSlug}`,
          title: sportName,
          count: eventCount,
          isPinned: favoritesSports.includes(sportId)
        }))
        .sort((a, b) => b.isPinned - a.isPinned),
    [sports, preMatchSport, favoritesSports]
  );

  const {
    showTimeZoneSelector,
    showCoefSwitcher,
    showLineSettings,
    showSettings
  } = useUserSettings();

  return (
    <>
      <SportsBookSearch onClick={onSearchClicked} />
      <div className={styles.ItemsWrapper}>
        <Box bottom="s" direction="column">
          <UserSettingsBurger
            showTimeZoneSelector={showTimeZoneSelector}
            showCoefSwitcher={showCoefSwitcher}
            showLineSettings={showLineSettings}
            showSettings={showSettings}
            isDisplayLiveCalendar={isDisplayLiveCalendar}
          />
        </Box>
        <Box>
          <CoreList items={subMenuItems} onClick={onClick} />
        </Box>
        <Box data-role="burger-sport-list" direction="column">
          <Box top="s" bottom="s">
            <AText
              className={styles.sportItemTitle}
              breed="BU"
              size="l"
              color="sm_txt_color_3"
            >
              {t('burgerMenu.sports.menu.title')}
            </AText>
          </Box>
          <FavoritesLink
            to={preMatchFavorites}
            title={t('sportsbook.favorites')}
            className={styles.favoriteItem}
            onClick={onClick}
          />
          {advFilter?.length > 0 && (
            <CustomFilterLink className={styles.favoriteItem} />
          )}
          <SportList
            items={items}
            isShowPinned={true}
            PinComponent={PinnedIcon}
            onClick={onClick}
          />
        </Box>
      </div>
    </>
  );
};

export default memo(PreMatchBurger);
