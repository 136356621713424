import { useCallback } from 'react';
import { AText, Box, Button, Input } from '@alf/uikit';
import { Close } from '@namespace/common';
import Price from '../../common/Price';
import styles from './index.module.css';

const OrderItems = ({
  id,
  title,
  price,
  inOrder = 0,
  images = {},
  buyerType,
  handleToBasket,
  handleRemoveItem,
  handleDropItemStack
}) => {
  const { standard } = images;
  const onRemoveItem = useCallback(() => handleRemoveItem(id, price), [
    id,
    price,
    handleRemoveItem
  ]);
  const onAddItem = useCallback(() => handleToBasket(id, price, buyerType), [
    id,
    price,
    buyerType,
    handleToBasket
  ]);

  return (
    <Box
      direction="row"
      justify="between"
      padding="s"
      className={styles.itemWrapper}
    >
      <Box direction="column" align="start">
        <AText breed="B" size="l" color="txt_color_2">
          {title}
        </AText>
        <Price
          price={price}
          options={{ top: 'm', bottom: 'm', justify: 'center' }}
        />
        <Box>
          <Button
            className={styles.actionBtn}
            onClick={onRemoveItem}
            intent="quaternary"
            actionType="color3"
            size="s"
          >
            -
          </Button>
          <Input
            align="center"
            value={inOrder}
            disabled={true}
            className={styles.input}
          />
          <Button
            className={styles.actionBtn}
            onClick={onAddItem}
            intent="quaternary"
            actionType="color3"
            size="s"
          >
            +
          </Button>
        </Box>
      </Box>
      <Box direction="row" left="m" justify="between">
        <Box justify="center" align="center" className={styles.imageDetails}>
          <img className={styles.image} src={standard} alt={title} />
        </Box>
        <Box left="xl" align="start">
          <Close
            onClose={() => handleDropItemStack(id, price)}
            className={styles.closeIcon}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default OrderItems;
