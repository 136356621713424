import { useEffect, useRef } from 'react';
import { document } from '../../constants';

const useScript = (
  { src = '', id = '', async = true, defer = false, charSet = 'utf-8' },
  callback
) => {
  const bodyRef = useRef(document.body);
  const scriptRef = useRef(document.createElement('script'));
  const scriptExisting = useRef(document.getElementById(id));

  useEffect(() => {
    if (src) {
      if (bodyRef.current.contains(scriptExisting.current)) {
        return callback();
      }
      scriptRef.current.src = src;
      scriptRef.current.id = id;
      scriptRef.current.async = async;
      scriptRef.current.defer = defer;
      scriptRef.current.charSet = charSet;
      bodyRef.current.appendChild(scriptRef.current);
    }

    return () =>
      bodyRef.current.contains(scriptRef.current) &&
      bodyRef.current.removeChild(scriptRef.current);
  }, [src]);
};

export default useScript;
