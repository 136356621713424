import { isEmpty } from 'lodash';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { useGeneralRoutes } from '@namespace/cms';
import { EmptyState } from '@alf/uikit';
import { MODE } from '../../constants';
import { useGetPathParams } from '../../hooks/useGetPathParams';
import BasePreMatchEvents from '../../common/BasePreMatchEvents';

// Add this wrapper with key to force component unmount on sport change
// to restart all starting soon logic
// It's impossible to make another way
const PreMatchEventsWrapper = () => {
  const { home } = useGeneralRoutes();
  const { t } = useI18n();
  const { sportSlug, activeSport, category } = useGetPathParams(MODE.PRE_MATCH);

  if (activeSport === null && sportSlug) {
    return (
      <EmptyState
        text={t('web.sports.noSport')}
        Link={LocalizedLink}
        linkPath={home}
        linkText={t('home')}
      />
    );
  }

  return (
    <BasePreMatchEvents
      activeSport={activeSport}
      categories={!isEmpty(category) ? [category] : []}
      key={`${sportSlug}`}
      isReady={!isEmpty(activeSport)}
    />
  );
};
export default PreMatchEventsWrapper;
