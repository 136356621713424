import {
  append,
  concat,
  equals,
  filter,
  flip,
  includes,
  isNil,
  map,
  none,
  pipe,
  prop,
  propEq,
  propSatisfies,
  reject,
  uniq,
  when,
  where,
  without
} from 'ramda';
import { setFilterItemStatusActive } from './setFilterItemStatusActive';

export const updateFilterWithNewCategories = (
  selectedFilter,
  newCategoryIds,
  sportId
) => {
  let result = selectedFilter;

  const oldCategoryIds = pipe(
    filter(propEq('sportId', sportId)),
    map(prop('categoryId')),
    reject(isNil),
    uniq
  )(selectedFilter);
  const addedCategoryIds = without(oldCategoryIds, newCategoryIds);
  const removedCategoryIds = without(newCategoryIds, oldCategoryIds);

  if (addedCategoryIds.length > 0) {
    result = pipe(
      // remove current sport item since we add new categories
      reject(
        where({
          sportId: equals(sportId),
          categoryId: isNil,
          tournamentId: isNil
        })
      ),
      // remove ALL items with same categoryIds
      // prettier-ignore
      reject(propSatisfies(
        flip(includes)(addedCategoryIds),
        'categoryId'
      )),
      // add new categories to the end
      flip(concat)(
        map((categoryId) => ({
          sportId,
          categoryId
        }))(addedCategoryIds)
      )
    )(result);
  }

  if (removedCategoryIds.length > 0) {
    result = pipe(
      // remove ALL items with same categoryId
      // prettier-ignore
      reject(propSatisfies(
        flip(includes)(removedCategoryIds),
        'categoryId'
      )),
      // add sport item if no items with this sportId exist
      // prettier-ignore
      when(
        none(propEq('sportId', sportId)),
        append({ sportId })
      )
    )(result);
  }

  return result.map(setFilterItemStatusActive);
};
