import { useContext } from 'react';
import { useGeneralRoutes } from '@namespace/cms';
import { UserContext } from '../../context';

const useDefineDepositPath = () => {
  const { wallets: walletsPage, createWallet } = useGeneralRoutes();
  const [user] = useContext(UserContext);
  const { wallets } = user;

  return wallets.length === 1 && wallets[0].paymentInstrumentId === 1
    ? createWallet
    : walletsPage;
};

export default useDefineDepositPath;
