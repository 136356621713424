import { useContext } from 'react';
import { useGeneralRoutes } from '@namespace/cms';
import { ACTION_LOCATIONS } from '@namespace/analytics';
import { DeviceContext } from '@namespace/device';
import { Button } from '@alf/uikit';
import { useI18n, LocalizedLink } from '@namespace/i18n';
import { useUserInfoLoaded } from '@namespace/user';
import styles from './index.module.css';

const LoginButton = ({ className = '', actionType = 'color4' }) => {
  const { t } = useI18n();
  const { login } = useGeneralRoutes();
  const { isDesktop } = useContext(DeviceContext);
  const { userInfoLoaded } = useUserInfoLoaded();

  if (!userInfoLoaded) {
    return null;
  }

  return (
    <Button
      component={LocalizedLink}
      to={`${login}?from=${
        isDesktop ? ACTION_LOCATIONS.HEADER_DESKTOP : ACTION_LOCATIONS.HEADER
      }`}
      size="s"
      actionType={actionType}
      className={`${styles.login} ${className}`}
      dataRole="header-login-button"
    >
      {t('login.header')}
    </Button>
  );
};

export default LoginButton;
