import {
  find,
  findIndex,
  lensPath,
  map,
  path as rPath,
  pathEq,
  pipe,
  propEq,
  set,
  toPairs,
  when
} from 'ramda';

/*
Usage examples:

"mocks": {
  "siteSettings": {
    "overrides": [
      {
        "type": "component",
        "componentType": "navigation",
        "path": ["config", "items", { "prop": "link", "value": "/live/all" }, "link"],
        "value": "/sports/sport"
      },
      {
        "type": "component",
        "componentType": "navigation",
        "path": ["config", "items", { "prop": "link", "value": "/live/all/" }, "link"],
        "value": "/sports/sport"
      }
    ]
  }
}

"mocks": {
  "siteSettings": {
    "overrides": [
      {
        "type": "component",
        "componentType": "burger",
        "path": ["config", "mainMenuItems", "live", "forceActive"],
        "value": true
      }
    ]
  }
}

 */

const unfoldPath = (path, obj) => {
  const nextPath = [];

  for (let i = 0; i < path.length; ++i) {
    const part = path[i];

    if (typeof part !== 'object') {
      nextPath.push(part);

      continue;
    }

    const { prop, value } = part;
    const deepObj = rPath(nextPath, obj);

    if (Array.isArray(deepObj)) {
      const index = findIndex(propEq(prop, value), deepObj);

      if (index === -1) {
        // invalidate whole path
        return null;
      }

      nextPath.push(index);
    } else {
      // prettier-ignore
      // cover both array indexes and object keys
      const match = pipe(
        toPairs,
        find(pathEq([1, prop], value))
      )(deepObj);

      if (!match) {
        // invalidate whole path
        return null;
      }

      nextPath.push(match[0]);
    }
  }

  return nextPath;
};

export const overrideMockedState = (state, mock = {}) => {
  const { overrides = [] } = mock;
  let { components } = state;

  for (const { type, componentType, path, value } of overrides) {
    if (type === 'component') {
      // prettier-ignore
      components = map(
        when(
          propEq('type', componentType),
          (component) => {
            const nextPath = unfoldPath(path, component);

            return nextPath
              ? set(lensPath(nextPath), value)(component)
              : component;
          }
        )
      )(components);
    }
  }

  if (state.components !== components) {
    return {
      ...state,
      components
    };
  }

  return state;
};
