import { useEffect } from 'react';
import { useConfig } from '@namespace/cms';
import { useHistory } from '@namespace/router';
import { Overlay } from '@alf/uikit';
import Loader from '../Loader';
import styles from './index.module.css';

const RedirectComponent = ({ id }) => {
  const { config } = useConfig(id);
  const { redirectLink: to } = config;
  const { push } = useHistory();

  useEffect(() => {
    push(to);
  }, [push, to]);

  return (
    <div className={styles.wrapper}>
      <Overlay classNames={{ wrapperClass: styles.overlay }}>
        <Loader />
      </Overlay>
    </div>
  );
};

export default RedirectComponent;
