import { useEffect, useState } from 'react';
import { useConfig } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { sportsbookActions, useEvents } from '../../store';
import { useRemoveLine } from '../../hooks/useRemoveLine';
import { LINE_KEY } from '../../constants';
import useSportsSettings from '../../hooks/useSportsSettings';
import InnerTopLiveEvents from './components/InnerTopLiveEvents';

const { FETCH_TOP_LIVE_EVENTS } = sportsbookActions;

const TopLiveEvents = ({ id }) => {
  const { language } = useI18n();
  const { config } = useConfig(id);
  const { pageSize = 3, type = 'vertical', isShowMore = true } = config;
  const { isShowStatUrl = true } = useSportsSettings();
  const [isEventsLoaded, setIsEventsLoaded] = useState(false);
  const lineKey = LINE_KEY.TOP_EVENTS;
  const eventsIds = useEvents({ lineKey, onlyIds: true });

  useEffect(() => {
    if (language) {
      FETCH_TOP_LIVE_EVENTS({
        language,
        lineKey
      }).finally(() => setIsEventsLoaded(true));
    }
  }, [lineKey, language]);

  useRemoveLine(lineKey);

  return (
    <InnerTopLiveEvents
      isEventsLoaded={isEventsLoaded}
      isShowMore={isShowMore}
      eventsIds={eventsIds}
      lineKey={lineKey}
      type={type}
      pageSize={pageSize}
      isShowStatUrl={isShowStatUrl}
      isLiveEvents={true}
    />
  );
};

export default TopLiveEvents;
