import { useEffect, useMemo, useState } from 'react';
import { isNumber, isString } from 'lodash';
import { usePrevious } from '@namespace/helpers';
import { secondsToTime } from '../../utils';
import { EVENT_TIMER_ACTION, EVENT_TIMER_DIRECTIONS } from '../../constants';

export const useTimerCounter = (timer, timerVector, action) => {
  const showTimer = useMemo(() => isNumber(timer) && secondsToTime(timer), [
    timer
  ]);

  const [timerCounter, setTimerCounter] = useState(null);
  const [counter, setCounter] = useState(0);
  const prevTimer = usePrevious(timer);

  useEffect(() => {
    if (isString(showTimer)) {
      setCounter(0);
      setTimerCounter(showTimer);
    }
  }, [showTimer]);

  useEffect(() => {
    let intervalId;

    if (isString(showTimer)) {
      if (action === EVENT_TIMER_ACTION.START) {
        intervalId = setInterval(() => {
          setCounter((currentCounter) => currentCounter + 1);
        }, 1000);
      }

      if (action === EVENT_TIMER_ACTION.PAUSE) {
        clearInterval(intervalId);
        setTimerCounter(secondsToTime(prevTimer));
      }
      if (timer - counter < 1 && timerVector === EVENT_TIMER_DIRECTIONS.DESC) {
        clearInterval(intervalId);
      }
    }

    return () => clearInterval(intervalId);
  }, [showTimer, counter, action, prevTimer, timerVector]);

  useEffect(() => {
    if (counter) {
      if (timerVector === EVENT_TIMER_DIRECTIONS.DESC) {
        if (timer - counter >= 0) {
          setTimerCounter(secondsToTime(prevTimer - counter));
        }
      } else {
        setTimerCounter(secondsToTime(prevTimer + counter));
      }
    }
  }, [counter, prevTimer, timerVector]);

  return timerCounter;
};

export const useEventTimerCounters = (eventTimer) => {
  const { timer, timer2, timerVector, action } = eventTimer || {};
  const eventTimerCounter = useTimerCounter(timer, timerVector, action);
  const eventTimerCounter2 = useTimerCounter(timer2, timerVector, action);
  return useMemo(() => ({ eventTimerCounter, timer2, eventTimerCounter2 }), [
    eventTimerCounter,
    timer2,
    eventTimerCounter2
  ]);
};
