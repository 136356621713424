import { useI18n } from '@namespace/i18n';
import { Accordion, AText } from '@alf/uikit';
import styles from './index.module.css';

const RegisterAccordion = ({
  title = '',
  content,
  arrowUpName = 'icons/general/nav/ic_next',
  arrowDownName = 'icons/general/nav/next'
}) => {
  const { t } = useI18n();

  return (
    <Accordion
      dataRole="register-accordion"
      title={
        <AText
          breed="R"
          size="m"
          color="txt_color_7"
          data-role="register-accordion-tittle"
        >
          {t(title)}
        </AText>
      }
      withArrows={true}
      arrowUpName={arrowUpName}
      arrowDownName={arrowDownName}
      arrowsColor="txt_color_7"
      arrowsSize="xs"
      classNames={{
        headerClassName: styles.accordion,
        bodyClassName: styles.accordionBody
      }}
    >
      <AText
        breed="R"
        size="s"
        color="txt_color_7"
        data-role="register-accordion-content"
      >
        {t(content)}
      </AText>
    </Accordion>
  );
};

export default RegisterAccordion;
