import { assoc, indexBy, prop, when } from 'ramda';
import { useSelectedTournamentIdsByCategories } from '../../../../hooks/useSelectedTournamentIdsByCategories';
import { useDistinctSelectedTournamentIds } from '../../../../hooks/useDistinctSelectedTournamentIds';
import { updateFilterWithNewTournaments } from '../../../../utils';
import { useAdvFilterStore } from '../../../../../../../../storeAdvFilter';
import {
  useAdvFilterInnerCtxActions,
  useAdvFilterInnerCtxStore
} from '../../../../store';
import { useAdvFilterSettings } from '../../../../hooks/useAdvFilterSettings';

export const useTournamentsSelectorData = ({
  categoriesOptions,
  selectedCategoryIds
}) => {
  const advFilterTournamentsByCategoryId = useAdvFilterStore(
    'advFilterTournamentsByCategoryId'
  );
  const advFilterTournamentsById = useAdvFilterStore(
    'advFilterTournamentsById'
  );
  const filter = useAdvFilterInnerCtxStore('filter');
  const selectedSportId = useAdvFilterInnerCtxStore('selectedSportId');
  const { SET_FILTER_SELECTION } = useAdvFilterInnerCtxActions();
  const { maxTournamentsCount } = useAdvFilterSettings();

  const selectedTournamentIds = useSelectedTournamentIdsByCategories();
  const distinctTournamentIds = useDistinctSelectedTournamentIds();
  const indexedCategories = indexBy(prop('value'))(categoriesOptions);

  const tournamentsOptionsRaw = selectedCategoryIds
    .map((cId) => advFilterTournamentsByCategoryId[cId])
    .filter(Boolean)
    .flat()
    .map(({ id, name, categoryId }) => ({
      title: name,
      value: String(id),
      categoryId
    }));
  const tournamentsOptions =
    distinctTournamentIds.length >= maxTournamentsCount
      ? tournamentsOptionsRaw.map(
          when(
            ({ value }) => !selectedTournamentIds.includes(value),
            assoc('disabled', true)
          )
        )
      : tournamentsOptionsRaw;

  const onTournamentChange = (v) => {
    const newTournamentIds = (v.value ?? []).map(Number);
    const newFilter = updateFilterWithNewTournaments(
      filter,
      newTournamentIds,
      selectedSportId,
      { advFilterTournamentsById }
    );

    SET_FILTER_SELECTION({ filter: newFilter });
  };

  return {
    tournamentsOptions,
    indexedCategories,
    selectedTournamentIds,
    onTournamentChange
  };
};
