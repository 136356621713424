import { SportIcon } from '@namespace/icons2';
import { parseSportId, updateFilterWithNewSport } from '../../../../utils';
import { useAdvFilterStore } from '../../../../../../../../storeAdvFilter';
import {
  useAdvFilterInnerCtxActions,
  useAdvFilterInnerCtxStore
} from '../../../../store';

export const useSportsSelectData = () => {
  const advFilterSports = useAdvFilterStore('advFilterSports');
  const filter = useAdvFilterInnerCtxStore('filter');
  const { SET_FILTER_SELECTION } = useAdvFilterInnerCtxActions();
  const sportOptions = advFilterSports.map(({ id, name }) => ({
    title: name,
    value: String(id),
    iconElement: <SportIcon sportId={id} size="s" />
  }));

  const onSportChange = (v) => {
    const sportId = parseSportId(v.value);
    const newFilter = updateFilterWithNewSport(filter, sportId);
    SET_FILTER_SELECTION({ filter: newFilter, selectedSportId: sportId });
  };

  return {
    sportOptions,
    onSportChange
  };
};
