import { useCallback } from 'react';
import { window } from '@namespace/helpers';
import { isEmpty } from 'lodash';
import { useLRUCache } from '../useLRUCache';
import {
  getConfigBatch,
  getPageFromBatch,
  preparePageConfig
} from '../../api/getPageConfig';

// TODO: Discussed with @i.postol to use only LRU cache in RAM for pages until CMS does not work
// TODO: after CMS begins to work correctly use usePageCache hook which uses RAM (LRU) + IndexedDB
const useRAMPageCache = (FALLBACK_PAGES) => {
  const [, addPage, getItem] = useLRUCache(
    window.pagesBatch && !isEmpty(window.pagesBatch)
      ? {
          [window.pagesBatch.rootPath]: {
            value: {
              ...window.pagesBatch.configs
            },
            isSSR: true
          }
        }
      : null
  );

  const loadBatch = async ({ rootPath }) => {
    const promise = getConfigBatch({
      rootPath,
      FALLBACK_PAGES
    });
    addPage(rootPath, promise);
    return promise
      .then((data) => {
        addPage(rootPath, data);
        return data;
      })
      .catch(() => {
        addPage(rootPath, {});
      });
  };

  const getPage = useCallback(
    async (rootPath, pathname, formFactor, pathnameChanged) => {
      let batchConfigs = getItem({ key: rootPath, pathnameChanged });

      if (!batchConfigs) {
        batchConfigs = await loadBatch({
          rootPath
        });
      }
      return Promise.resolve(batchConfigs).then((data) => {
        const pageConfigData = getPageFromBatch(pathname, data);
        return !isEmpty(pageConfigData)
          ? preparePageConfig(pageConfigData, formFactor)
          : null;
      });
    },
    []
  );

  return [getPage, loadBatch];
};

export default useRAMPageCache;
