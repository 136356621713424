import { Navigate } from 'react-router-dom';
import { addSlashToPathEnd } from '@namespace/helpers';
import { useGetActualPrefixForPath } from '../hooks/useGetActualPrefixForPath';
import { useI18n } from '../hooks/useI18n';

const LocalizedRedirect = ({ to = '', ...rest }) => {
  const { language } = useI18n();
  const getActualPrefixForPath = useGetActualPrefixForPath();
  const modifiedTo = addSlashToPathEnd(
    `${getActualPrefixForPath(`/${language}`)}${to}`
  );

  return <Navigate replace={true} to={modifiedTo} {...rest} />;
};

export default LocalizedRedirect;
