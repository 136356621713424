import { AText, Box } from '@alf/uikit';
import { LINE_VIEW } from '../../../../../../../constants';
import { useEventField } from '../../../../../../../store';
import styles from './index.module.css';

const MarketCount = ({ eventId, lineView, wrapperProps = {} }) => {
  const marketCount = useEventField(eventId, 'marketCount');

  return (
    <Box
      flex="0 0 56px"
      justify="center"
      align="center"
      right="s"
      className={`${styles.more} ${lineView ? styles[lineView] : ''}`}
      {...wrapperProps}
    >
      {lineView === LINE_VIEW.TABLE ? (
        <AText size="m" color="outcome_btn_txt" className={styles.marketCount}>
          {`+${marketCount}`}
        </AText>
      ) : (
        <AText breed="B" size="m" color="outcome_btn_txt">
          {`+${marketCount}`}
        </AText>
      )}
    </Box>
  );
};

export default MarketCount;
