import { useContext, useEffect } from 'react';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { Close } from '@namespace/common';
import { AText, Button, Box } from '@alf/uikit';
import { Support } from '@namespace/auth';
import { useGeneralRoutes } from '@namespace/cms';
import { UserContext } from '@namespace/user';
import styles from './index.module.css';

export const EmailConfirmation = ({ isSuccessPage, pageConfig }) => {
  const [, userActions] = useContext(UserContext);
  const { FETCH_USER_DATA } = userActions;
  const { t } = useI18n();
  const { push } = useHistory();
  const { home } = useGeneralRoutes();
  const { hideNeedHelpLabel = false } = pageConfig;

  useEffect(() => {
    FETCH_USER_DATA();
  }, []);

  return (
    <>
      <Box justify="center" align="center" className={styles.wrapper}>
        <Close dataRole="email-verification-page-close" />
        <Box direction="column" className={styles.container}>
          <AText breed="B" size="3xl" color="po_txt_color_1" align="center">
            {t(
              `email.verification.title.${
                isSuccessPage ? 'verified' : 'unverified'
              }`
            )}
          </AText>
          <AText
            size="xl"
            color="po_txt_color_1"
            align="center"
            className={styles.info}
          >
            {t(
              `email.verification.subtitle.${
                isSuccessPage ? 'verified' : 'unverified'
              }`
            )}
          </AText>
          <Button
            onClick={() => push(home)}
            fullWidth={true}
            intent="primary"
            actionType="color1"
            size="xl"
          >
            {t('email.verification.button')}
          </Button>
        </Box>
      </Box>
      <Support
        hideNeedHelpLabel={hideNeedHelpLabel}
        className={styles.chatContainer}
      />
    </>
  );
};
