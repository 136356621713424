import { useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'ramda';
import { useGeneralRoutes } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { IS_WIDGET } from '@namespace/widget';
import { Icon } from '@namespace/icons2';
import { DeviceContext } from '@namespace/device';
import {
  selectEventsIdsBySportId,
  sportsbookActions,
  useSportsbookStore
} from '../../store';
import { useGetPathParams } from '../../hooks/useGetPathParams';
import { useRemoveLine } from '../../hooks/useRemoveLine';
import { LINE_KEY, MODE, PRESETS_SOURCE_TYPES } from '../../constants';
import TopEventsLine from './TopEventsLine';
import SportbookSkelet from '../../common/SportbookSkelet';
import BackHeader from '../../common/BackHeader';
import useSportsSettings from '../../hooks/useSportsSettings';
import styles from './index.module.css';

const { FETCH_ACTIVE_TOP_EVENTS } = sportsbookActions;

const TopEventsWrapper = ({ id }) => {
  const [isLoading, setIsLoading] = useState(true);
  const topEvents = useSportsbookStore('topEvents');
  const { t, f, language: lang } = useI18n();
  const { topId } = useGetPathParams(MODE.PRE_MATCH);
  const { timeRangeForAllPeriodByEvents } = useSportsSettings();
  const { isDesktop, pageType } = useContext(DeviceContext);

  const eventsBySportId = useSportsbookStore(
    selectEventsIdsBySportId(LINE_KEY.TOP_EVENTS)
  );

  const { preMatchLobby } = useGeneralRoutes();
  const { push } = useHistory();
  const configByPlatform = useMemo(
    () =>
      topEvents?.length
        ? topEvents?.find(
            ({ presetId }) => presetId.toString() === topId.toString()
          )[pageType]
        : {},
    [pageType, topEvents, topId]
  );
  const { translationKey } = configByPlatform;

  useEffect(() => {
    if (topId && topEvents?.length) {
      setIsLoading(true);
      const {
        events: newEvents = [],
        tournaments: newTournaments = [],
        source = PRESETS_SOURCE_TYPES.EVENTS
      } =
        topEvents.find(
          ({ presetId }) => presetId.toString() === topId.toString()
        ) || {};

      FETCH_ACTIVE_TOP_EVENTS({
        source,
        events: newEvents,
        tournaments: newTournaments,
        lang,
        serviceId: MODE.PRE_MATCH,
        lineKey: LINE_KEY.TOP_EVENTS,
        timeRange: timeRangeForAllPeriodByEvents
      })
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [topId, lang, f, topEvents, timeRangeForAllPeriodByEvents]);

  useEffect(() => {
    if (!isLoading && isEmpty(eventsBySportId)) {
      push(preMatchLobby);
    }
  }, [eventsBySportId, isLoading, preMatchLobby, push]);

  useRemoveLine(LINE_KEY.TOP_EVENTS);

  // Add key to force component unmount on topId change
  return (
    <div key={topId} className={clsx(!IS_WIDGET && styles.container)}>
      {!isDesktop && (
        <BackHeader
          title={t(translationKey)}
          arrowBackIcon={
            <Icon
              name="icons/general/nav/back"
              color="line_header_txt"
              size="s"
            />
          }
          showArrowBack={true}
        />
      )}
      {isEmpty(eventsBySportId) && isLoading ? (
        <SportbookSkelet showLineHeader={true} />
      ) : (
        Object.entries(
          eventsBySportId
        ).map(([sportId, eventsIdsBySport], idx) => (
          <TopEventsLine
            key={sportId}
            id={id}
            lineKey={LINE_KEY.TOP_EVENTS}
            sportId={sportId}
            eventsIdsBySport={eventsIdsBySport}
            isLoading={isLoading}
            serviceId={MODE.PRE_MATCH}
            backLink={preMatchLobby}
            isExpandedDefault={idx < 1}
          />
        ))
      )}
    </div>
  );
};

export default TopEventsWrapper;
