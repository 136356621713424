import { useContext } from 'react';
import clsx from 'clsx';
import { Box } from '@alf/uikit';
import { DeviceContext } from '@namespace/device';
import { useOtherSettings } from '@namespace/cms';
import LanguageSelect from './LanguageSelect';
import styles from './index.module.css';

const Language = ({
  id,
  isFooter = true,
  isHeader = false,
  hasAdditionalHeaderColor = false,
  triggerType = 'combine',
  classNames,
  isCustomFooter,
  title
}) => {
  const { isDesktop } = useContext(DeviceContext);
  const { isLandBase = false } = useOtherSettings(); // should refactor Language select

  return (
    <Box
      justify="center"
      right={(isDesktop && !isCustomFooter) || isHeader ? 's' : '0'}
      className={clsx([
        styles.wrapper,
        !isLandBase && isFooter ? styles.footerLanguageSelect : '',
        isCustomFooter ? styles.customFooter : ''
      ])}
      data-role="language-switcher"
    >
      <LanguageSelect
        triggerType={triggerType}
        isFullHeight={false}
        isFullWidth={!isDesktop && !isLandBase && isFooter}
        isFooter={!isLandBase && isFooter}
        isHeader={isHeader}
        isCustomFooter={isCustomFooter}
        classNames={{
          ...classNames,
          containerClass: `${styles.select} languageDropdownContainer`,
          contentClass: styles.triggerClassName,
          triggerContentClass: styles.triggerContent,
          triggerContentTitleClass: clsx([
            styles.triggerContentTitle,
            hasAdditionalHeaderColor && isHeader && styles.header
          ]),
          arrowColor: clsx([
            styles.arrowColor,
            hasAdditionalHeaderColor && isHeader && styles.header
          ])
        }}
        id={id}
        title={title}
      />
    </Box>
  );
};

export default Language;
