import { useEffect, useState, useContext, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { FILTER_ALL_VALUE } from '@namespace/common';
import { DeviceContext } from '@namespace/device';
import { useI18n } from '@namespace/i18n';
import { Box, Select } from '@alf/uikit';
import { COLOR_SCHEME } from '@namespace/themes';
import { getTabs, formatSelectOptions } from '../../utils';
import { DATE_FILTER_CMS_VALUES, NEXT_MORNING_FILTER } from '../../constants';
import styles from './index.module.css';

const emptyArr = [];
const EventsLineHeadGroupsFilterDropdown = ({
  filterConfig = {},
  filterValue = {},
  onSetFilter,
  isShowAll,
  isShowStartingSoon,
  isShowNextMorning = false,
  className = '',
  additionSelectContentStyle = ''
}) => {
  const { days = emptyArr, hours = emptyArr } = filterConfig;
  const [options, setOptions] = useState([]);
  const { t, f, language } = useI18n();
  const { isDesktop } = useContext(DeviceContext);
  const formattedOptions = useMemo(() => formatSelectOptions(options), [
    options
  ]);
  const handleSetFilter = ({ value }) => {
    if (value === FILTER_ALL_VALUE) {
      return onSetFilter({ [FILTER_ALL_VALUE]: FILTER_ALL_VALUE });
    }
    if (value === NEXT_MORNING_FILTER) {
      return onSetFilter({ [NEXT_MORNING_FILTER]: NEXT_MORNING_FILTER });
    }
    const { dateFilterType: type } = options.find(
      ({ value: tabValue }) => String(tabValue) === String(value)
    );
    if (
      type === DATE_FILTER_CMS_VALUES.DAYS &&
      value !== filterValue.filterByDate
    ) {
      return onSetFilter({ [type]: value });
    }
    if (
      type === DATE_FILTER_CMS_VALUES.HOURS &&
      Number(value) !== filterValue.filterByRange
    ) {
      return onSetFilter({ [type]: Number(value) });
    }
    return null;
  };

  useEffect(() => {
    const optionsByDate = getTabs({
      dateFilterType: DATE_FILTER_CMS_VALUES.DAYS,
      t,
      f,
      dateFilters: days
    });
    const optionsByHours = getTabs({
      dateFilterType: DATE_FILTER_CMS_VALUES.HOURS,
      t,
      f,
      dateFilters: hours,
      isShowStartingSoon,
      isShowNextMorning
    });

    let filterOptions = [...optionsByHours, ...optionsByDate];
    if (isShowAll) {
      filterOptions = [
        ...filterOptions,
        {
          name: t('sportsbook.filtering.by.all'),
          value: FILTER_ALL_VALUE,
          dateFilterType: FILTER_ALL_VALUE
        }
      ];
    }

    setOptions(filterOptions);
  }, [
    days,
    f,
    filterConfig,
    hours,
    isShowAll,
    isShowNextMorning,
    isShowStartingSoon,
    language,
    t
  ]);
  const getActiveOption = (type, value) =>
    (filterValue[type] && filterValue[type] === value) ||
    (isShowNextMorning && filterValue[NEXT_MORNING_FILTER] === value);
  const selectedOption = options.find(({ value, dateFilterType }) =>
    getActiveOption(dateFilterType, value)
  );

  return (
    !isEmpty(formattedOptions) && (
      <Box
        onClick={(e) => e.stopPropagation()}
        className={`${styles.filterDropdownContainer} ${className}`}
      >
        <Select
          options={formattedOptions}
          value={selectedOption?.value}
          onChange={handleSetFilter}
          isDesktop={isDesktop}
          additionSelectContentStyle={additionSelectContentStyle}
          intentType={COLOR_SCHEME.SECONDARY}
          dataRole="events-line-header-filter"
        />
      </Box>
    )
  );
};

export default EventsLineHeadGroupsFilterDropdown;
