import { useContext, useEffect } from 'react';
import { PageContext, SiteSettingsContext } from '@namespace/cms';
import { USER_ROLES, UserContext } from '@namespace/user';
import { isEmpty } from 'lodash';
import { NOTIFICATION_TYPES, useNotifications } from '@namespace/notifications';
import { LAYOUT_TYPES } from '../Page/Layout/layoutMap';
import NotificationBanner from '../../common/NotificationBanner';

const DefaultUserNotification = () => {
  const { page } = useContext(PageContext);
  const { pageConfig = {}, layout = {} } = page;
  const { isHeaderVisible = true, isFooterVisible = true } = pageConfig;
  const { type } = layout;
  const isCustomFooter =
    type === LAYOUT_TYPES.THREE_COLUMN_FULL_HD_SMALL_FOOTER;
  const { customNotification } = useNotifications();

  const [settings = {}] = useContext(SiteSettingsContext);
  const { defaultUserNotification } = settings;
  const { snippetId, expires, showIfDocumentsNoEmpty = false } =
    defaultUserNotification || {};

  const [user] = useContext(UserContext);
  const { isDefaultRegistratedUser, documents = [] } = user;
  const [document = {}] = documents;
  const isEmptyDocument = isEmpty(document);

  const isDisplayDefaultUserNotification =
    defaultUserNotification &&
    (isDefaultRegistratedUser ||
      (showIfDocumentsNoEmpty &&
        isEmptyDocument &&
        user.role === USER_ROLES.USER)) &&
    isHeaderVisible &&
    (isFooterVisible || isCustomFooter);

  useEffect(() => {
    if (isDisplayDefaultUserNotification && !snippetId) {
      customNotification({
        notificationType: NOTIFICATION_TYPES.UNVERIFIED_USER,
        timeout: null
      });
    }
  }, [customNotification, isDisplayDefaultUserNotification, snippetId]);

  return (
    isDisplayDefaultUserNotification &&
    snippetId && <NotificationBanner snippetId={snippetId} expires={expires} />
  );
};

export default DefaultUserNotification;
