import { get } from '@namespace/helpers';

export const getBonusSystemBonusCount = () =>
  get('/service/prewager/campaigns/count')
    .then(({ data }) => data?.countBonuses || 0)
    .catch((e) => {
      console.error(e);

      return 0;
    });
