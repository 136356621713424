import { useContext } from 'react';
import { OnboardingContext } from '../../store/context';

// todo apply defaults and overrides automatically? Needs robust memoization to prevent performance hit.
export const useCurrentTour = () => {
  const [state] = useContext(OnboardingContext);
  const { tours, tourIndex } = state;

  return tours[tourIndex] ?? {};
};
