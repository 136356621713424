import { memo, useCallback, useContext, useState } from 'react';
import clsx from 'clsx';
import { AText, Button, Box } from '@alf/uikit';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { useNotifications } from '@namespace/notifications';
import { UserContext, USER_ROLES } from '@namespace/user';
import { useHistory } from '@namespace/router';
import { Icon } from '@namespace/icons2';
import { useGetGameURL, useCasinoSettings } from '../../hooks';
import { REAL_MODE } from '../../constants';
import { GamesContext } from '../../context';
import styles from './index.module.css';

const FavoriteButton = ({
  game = {},
  label,
  redirectToPlay = false,
  redirectToPreview = false,
  className = '',
  serviceURL,
  casinoType,
  iconSize = 'm',
  colorInactive = 'line_color_3'
}) => {
  const { gameCode, provider: gameProvider, gameId } = game;
  const [isFetching, setIsFetching] = useState(false);
  const [
    gamesState,
    { REMOVE_GAME_FAVORITES_LIST, ADD_GAME_FAVORITES_LIST }
  ] = useContext(GamesContext);
  const { favoritesRequestEntityName } = useCasinoSettings(casinoType);
  const favorites = gamesState.favorites[favoritesRequestEntityName]?.ids || [];
  const isFavorite = favorites.includes(gameCode);
  const [user] = useContext(UserContext);
  const { role } = user;
  const { errorNotification } = useNotifications();
  const { push } = useHistory();

  const getGameURL = useGetGameURL();

  const redirectGuest = useCallback(() => {
    let type = redirectToPlay ? 'launch' : 'preview';
    if (redirectToPreview) {
      type = 'previewLogin';
    }
    return push(
      getGameURL({
        gameCode,
        gameProvider,
        playMode: REAL_MODE,
        serviceURL,
        type
      })
    );
  }, [
    redirectToPlay,
    redirectToPreview,
    gameCode,
    gameProvider,
    serviceURL,
    push
  ]);

  const handleFavorites = useCallback(
    (callback) => {
      logAction(LOG_ACTIONS.CASINO_FAVORITES, {
        GameProvider: gameProvider,
        IDT: gameProvider
      });
      setIsFetching(true);
      callback({ id: gameId, entityName: favoritesRequestEntityName })
        .catch(({ message }) => errorNotification(message))
        .finally(() => setIsFetching(false));
    },
    [gameProvider, gameId, favoritesRequestEntityName]
  );

  const handleClick = useCallback(() => {
    if (role === USER_ROLES.GUEST) {
      return redirectGuest();
    }

    return isFavorite
      ? handleFavorites(REMOVE_GAME_FAVORITES_LIST)
      : handleFavorites(ADD_GAME_FAVORITES_LIST);
  }, [role, isFavorite, handleFavorites, redirectGuest]);

  return (
    <Button
      intent="quaternary"
      onClick={handleClick}
      actionType="color2"
      size="s"
      className={clsx([styles.button, className])}
      disabled={isFetching}
    >
      <Box align="center">
        <Icon
          size={iconSize}
          color={isFavorite ? 'state_favorite' : colorInactive}
          name={`icons/general/state/favorite-${isFavorite ? 'on' : 'off'}`}
        />
        {label && (
          <AText
            breed="BU"
            size="m"
            className={styles.label}
            color="state_info"
          >
            {label}
          </AText>
        )}
      </Box>
    </Button>
  );
};

export default memo(FavoriteButton);
