import { types } from '../types';

export const initialState = {
  betslipWidgetConfig: {
    distanceToBottomInIframe: 0,
    betslipMaxHeight: null
  },
  contentHeight: 0
};

export const reducers = {
  [types.SET_BETSLIP_WIDGET_CONFIG]: (state, betslipWidgetConfig) => ({
    ...state,
    betslipWidgetConfig
  }),
  [types.SET_CONTENT_HEIGHT]: (state, contentHeight) => ({
    ...state,
    contentHeight
  })
};
