import { Box, EmptyState } from '@alf/uikit';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { Icon, Image } from '@namespace/icons2';
import BackHeader from '../BackHeader';
import styles from './index.module.css';

const FavoritesEmptyState = ({
  isBackHeaderVisible = true,
  onGoBack,
  classNames = {},
  serviceId
}) => {
  const { t } = useI18n();
  const { containerClassName } = classNames;

  return (
    <>
      {isBackHeaderVisible && (
        <BackHeader
          containerClassName={styles.backHeaderContainer}
          title={t('sportsbook.favorites')}
          arrowBackIcon={
            <Icon
              name="icons/general/nav/back"
              color="line_header_txt"
              size="s"
            />
          }
          showArrowBack={true}
          onBackClick={onGoBack}
        />
      )}
      <Box className={styles.wrapper}>
        <EmptyState
          dataRolePrefix={
            serviceId ? 'live-cybersport-' : 'prematch-cybersport-'
          }
          image={<Image name="general/looking_for" />}
          Link={LocalizedLink}
          text={t('sportsbook.favorites.emptyState')}
          subText={t('sportsbook.favorites.emptyState.sub')}
          classNames={{
            subTextClassName: styles.subText,
            containerClassName
          }}
        />
      </Box>
    </>
  );
};

export default FavoritesEmptyState;
