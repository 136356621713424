import { useState, useRef, useEffect } from 'react';
import { isEmpty, noop } from 'lodash';
import { useOtherSettings } from '@namespace/cms';
import { getFileBaseAndExtension } from './utils';

import { useCalcSrcSets } from './useCalcSrcSets';

const ResponsiveImage = ({
  src = '',
  onError = noop,
  srcSetUnitsType = 'x', // 'x' | 'w'
  sizes, // used only with 'w' units
  sizesRange = '256-3840', // used only with 'w' units
  alt = '',
  ...props
}) => {
  const [srcFileBase, srcExtension] = getFileBaseAndExtension(src);
  const { responsiveImageConfig } = useOtherSettings();

  const [isPictureLoadError, setIsPictureLoadError] = useState(false);
  const pictureImgRef = useRef();
  const isGifImage = srcExtension === 'gif';
  const isFeatureEnabled = responsiveImageConfig?.isEnabled;

  const srcSets = useCalcSrcSets({
    srcSetUnitsType,
    srcFileBase,
    srcExtension,
    sizesRange,
    sizes,
    isFeatureEnabled
  });

  const isSafari = navigator.userAgent.match(/Version\/[\d.]+.*Safari/);

  const handlePictureLoadError = () => setIsPictureLoadError(true);

  const handleSecondLoadError = (e) => onError(e);

  useEffect(() => {
    // Safari loads both src img and source srcSet img. setting src after inital render fixes issue
    if (
      isSafari &&
      !isEmpty(pictureImgRef.current) &&
      !pictureImgRef.current.src &&
      src
    ) {
      pictureImgRef.current.src = src;
    }
  }, [src, isSafari]);

  return isPictureLoadError || isGifImage || !isFeatureEnabled ? (
    <img onError={handleSecondLoadError} src={src} alt={alt} {...props} />
  ) : (
    <picture>
      <>
        {srcSets.map(({ imgType, srcSet }) => (
          <source type={imgType} sizes={sizes} srcSet={srcSet} key={imgType} />
        ))}
      </>
      <img
        sizes={sizes}
        onError={handlePictureLoadError}
        alt={alt}
        ref={pictureImgRef}
        {...(isSafari ? {} : { src })}
        {...props}
      />
    </picture>
  );
};

export default ResponsiveImage;
