export const STAKE_LOGIC_EVENTS = {
  EXIT: 'GOTO_LOBBY'
};

export const stakelogicRlxLauncherControl = (event, callbacks = {}) => {
  const { onExitHandler = () => {} } = callbacks;

  if (event?.data?.type === STAKE_LOGIC_EVENTS.EXIT) {
    onExitHandler();
  }
};
