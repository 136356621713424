import { types } from '../types';
import { getHistory } from '../../../api/loginHistory';

export const actions = {
  [types.FETCH_HISTORY]: (dispatch) => async (filter) => {
    const { limit, pointer } = filter;
    const { userhistory = [], amount } = await getHistory(filter);
    const totalPages = Math.ceil(limit > 0 ? amount / limit : 1);
    dispatch({
      type: types.SET_DATA,
      payload: {
        history: userhistory,
        pagination: pointer,
        totalPages,
        totalCount: amount
      }
    });
  }
};
