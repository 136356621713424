export const IN_HOUSE_JACKPOT_LVL_TYPES = {
  MINI: 'mini',
  MINOR: 'minor',
  MAJOR: 'major',
  MEGA: 'mega'
};

export const JACKPOT_TYPE_TO_IMAGE = {
  [IN_HOUSE_JACKPOT_LVL_TYPES.MINI]: 'jackpotTypes/mini',
  [IN_HOUSE_JACKPOT_LVL_TYPES.MINOR]: 'jackpotTypes/minor',
  [IN_HOUSE_JACKPOT_LVL_TYPES.MAJOR]: 'jackpotTypes/major',
  [IN_HOUSE_JACKPOT_LVL_TYPES.MEGA]: 'jackpotTypes/mega'
};

// intervals are in seconds
export const JACKPOT_TYPE_TO_UPDATE_INTERVAL = {
  [IN_HOUSE_JACKPOT_LVL_TYPES.MEGA]: 3,
  [IN_HOUSE_JACKPOT_LVL_TYPES.MAJOR]: 2.5,
  [IN_HOUSE_JACKPOT_LVL_TYPES.MINOR]: 2,
  [IN_HOUSE_JACKPOT_LVL_TYPES.MINI]: 1.5
};
export const JACKPOT_BANNER_UPDATE_INTERVAL = 1.5;

export const WIN_MESSAGE_SHOW_TIME = 10000;
