import { socketSend } from '@namespace/socket';
import { getBetslipKey } from '../../utils/localStorage';
import formatVariables from '../../utils/formatVariables';
import { BET_TYPES_KEYS } from '../../constants';
import { types } from '../types';

const dataMutationBetslip = (query) => ({
  cmd: 'do_proto_v1',
  data: {
    mutation: {
      [getBetslipKey()]: query
    }
  }
});

export default {
  [types.ADD_TO_STORE]: () => ({ bets }) =>
    Promise.all(
      bets.map((bet) =>
        socketSend(
          dataMutationBetslip({
            push: {
              variables: {
                event_id: Number(bet.eventId),
                market_id: Number(bet.marketId),
                id: Number(bet.outcomeId),
                service_id: Number(bet.serviceId)
              }
            }
          })
        )
      )
    ),
  [types.DELETE_FROM_STORE]: () => ({ bets }) =>
    Promise.all(
      bets.map((bet) =>
        socketSend(
          dataMutationBetslip({
            pop: {
              variables: {
                id: bet.outcomeId
              }
            }
          })
        )
      )
    ),
  [types.CLEAN_STORE]: () => () =>
    socketSend(
      dataMutationBetslip({
        popAll: {
          variables: {}
        }
      })
    ),
  [types.SET_STORE_ACCEPT]: () => () =>
    socketSend(
      dataMutationBetslip({
        takePrices: {}
      })
    ),
  [types.TAKE_STAKE_INFO]: () => () =>
    socketSend(
      dataMutationBetslip({
        takeStakeInfo: {}
      })
    ),
  [types.SET_TYPE_CHANGES]: () => ({ typeChange }) =>
    socketSend(
      dataMutationBetslip({
        oddsEvent: {
          variables: {
            type_changes: parseInt(typeChange, 10)
          }
        }
      })
    ),
  [types.FETCH_STORE]: () => ({ serviceId, ...params } = {}) =>
    socketSend({
      cmd: 'do_proto_v1',
      data: {
        query: {
          [getBetslipKey()]: {
            variables: {
              service_id: serviceId,
              ...params
            }
          }
        }
      }
    }),
  [types.MAKE_BET]: () => () =>
    socketSend(
      dataMutationBetslip({
        pushStake: {}
      })
    ),
  [types.SET_STORE_TYPE]: () => ({ betType, selectedSystems, ...rest }) => {
    const variables = formatVariables({ betType, selectedSystems, ...rest });

    return socketSend(
      dataMutationBetslip({
        [BET_TYPES_KEYS[betType]]: {
          variables
        }
      })
    );
  },
  [types.MAKE_PREORDER]: () => () =>
    socketSend(
      dataMutationBetslip({
        print: {}
      })
    ),
  [types.FIX_SYSTEM]: () => (id) =>
    socketSend(
      dataMutationBetslip({
        fix: {
          variables: {
            id
          }
        }
      })
    ),
  [types.CHECK_OUTCOME]: () => (id) =>
    socketSend(
      dataMutationBetslip({
        check: {
          variables: {
            id
          }
        }
      })
    ),
  [types.SET_STORE_AMOUNT]: () => (sum) =>
    socketSend(
      dataMutationBetslip({
        stake: {
          variables: {
            bet: sum
          }
        }
      })
    ),
  [types.SWITCH_FREE_BET]: () => () =>
    socketSend(
      dataMutationBetslip({
        freeBet: {}
      })
    ),
  [types.ONE_CLICK_BET]: () => () =>
    socketSend(
      dataMutationBetslip({
        touchBet: {}
      })
    ),
  [types.ONE_CLICK_BET_PUSH_STAKE]: () => () =>
    socketSend(
      dataMutationBetslip({
        pushFastStake: {}
      })
    ),
  [types.PUSH_VIP_PRICES]: () => async (bet) =>
    fetch(`/bets/store/vip_pay_approve/${bet}`),
  [types.PUSH_VIP_MAX]: () => (bet) => fetch(`/bets/store/vip_pay_max/${bet}`),
  [types.PUSH_VIP_STAKE]: () => (bet) => fetch(`/bets/store/vip_pay/${bet}`)

  // This is action that's change oddFormats like malay, decimal and others.
  // This functional worked only in asia package but can be on in all partners.
  // Now in socket betslip realisation this method is not implemented.
  // So if we need on this functional again, we will need backend support.

  // [types.SET_COEF_TYPE]: (dispatch) => async ({ serviceIds, oddsType }) => {
  //   const promises = serviceIds.map((serviceId) => (
  //     setCoefType({ serviceId, coefType: oddsType })
  //   ));

  //   const betsData = await Promise.all(promises);
  //   const actualBetSlipData = last(betsData);

  //   dispatch({
  //     type: types.SET_BETSLIP_DATA,
  //     payload: { data: actualBetSlipData }
  //   });
  // },
};
