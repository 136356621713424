import { BETS_TYPE_CODES, BETS_TYPE_CODES_SPORTS } from '../../constants';

const getOutputBetType = (outputBetTypeCode) => {
  const result = [];
  const betsCodes = Object.entries(BETS_TYPE_CODES);

  // Encode outputBetTypeCode parameter into binary system and look at which constants
  // from BETS_TYPE_CODES it corresponds to. Should return array of numbers like [1,2,4,16]
  for (const [, value] of betsCodes) {
    // eslint-disable-next-line no-bitwise
    if ((outputBetTypeCode & value) === value) {
      result.push(value);
    }
  }

  // Checking for what types of bets it suits
  switch (true) {
    case result.length === 1 && result.includes(8):
      return 'casino';
    case result.includes(8) &&
      BETS_TYPE_CODES_SPORTS.some((code) => result.includes(code)):
      return 'sportsCasino';
    case BETS_TYPE_CODES_SPORTS.some((code) => result.includes(code)):
      return 'sports';
    default:
      return null;
  }
};

export default getOutputBetType;
