import { useReferenceState } from '@namespace/common';
import { useVerifyDocuments } from '@namespace/user';
import ProfileDocumentsForm from '../../../common/ProfileDocumentsForm';
import { DOCUMENT_STATUSES } from '../../../constants';

const ProfileDocumentsFormWrapper = ({
  onStepSuccess,
  documentDateValidationMap,
  defaultCitizenshipCountry = '',
  disabledCitizenship = false,
  ...rest
}) => {
  const { status, isEmptyDocument, document } = useVerifyDocuments();
  useReferenceState();

  return (
    <ProfileDocumentsForm
      config={rest}
      onSuccess={onStepSuccess}
      documentDateValidationMap={documentDateValidationMap}
      defaultCitizenshipCountry={defaultCitizenshipCountry}
      disabledCitizenship={disabledCitizenship}
      isDisabled={!isEmptyDocument}
      document={document}
      hideButton={
        status === DOCUMENT_STATUSES.PENDING ||
        status === DOCUMENT_STATUSES.ADDITIONAL_INFORMATION
      }
    />
  );
};

export default ProfileDocumentsFormWrapper;
