import { types } from './types';
import {
  formatInHouseJackpotWinnersGames,
  calcTotalInHouseJackpot,
  formatJackpots
} from './normalize';
import { getInHouseJackpotsOrder } from '../utils';

export const initialState = {
  jackpots: {},
  totalJackpot: {},
  games: {},
  ids: [],
  jackpotWin: null
};

export const reducers = {
  [types.SET_IN_HOUSE_JACKPOTS]: (state, { jackpots, userCurrency }) => {
    const formattedJackpots = formatJackpots(jackpots, userCurrency);

    return {
      ...state,
      jackpots: formattedJackpots,
      ids: getInHouseJackpotsOrder(jackpots),
      totalJackpot: calcTotalInHouseJackpot(formattedJackpots)
    };
  },
  [types.SET_IN_HOUSE_JACKPOT_WINNERS_GAMES]: (state, data) => {
    const formattedWinnerGameInfo = formatInHouseJackpotWinnersGames(data);

    return {
      ...state,
      games: {
        ...state.games,
        ...formattedWinnerGameInfo
      }
    };
  },
  [types.SET_IN_HOUSE_JACKPOT_WIN]: (state, jackpotWin) => {
    return {
      ...state,
      jackpotWin
    };
  }
};
