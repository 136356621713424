import { AlertNotification } from '@alf/uikit';
import styles from './index.module.css';

const DefaultToast = (props) => (
  <AlertNotification
    classNames={{ messageClassName: styles.fixed }}
    {...props}
  />
);

export default DefaultToast;
