import getDateTime from '../getDateTime';
import getIntervalCount from '../getIntervalCount';

const getNextTime = (date, duration = 60) => {
  const dateTime = getDateTime(date);
  const count = getIntervalCount(dateTime, duration);
  const minutes = count * duration;
  return dateTime.plus({ minutes });
};

export default getNextTime;
