import { useContext, useEffect, useState } from 'react';
import { isEmpty } from 'ramda';
import { DeviceContext } from '@namespace/device';
import { COOKIES_KEYS, getCookie, window } from '@namespace/helpers';
import { I18nContext } from '@namespace/i18n/src/context';
import { SiteSettingsContext } from '../../store/siteSettings/context';
import getSiteSettings from '../../api/getSiteSettings';

const useGetSiteSettings = (fallbackSettings) => {
  const { pageType } = useContext(DeviceContext);
  const [isSiteSettingsSet, setIsSiteSettingsSet] = useState(false);
  const [siteSettings, actions] = useContext(SiteSettingsContext);
  const { SET_DEFAULT_SETTINGS, SET_NEW_SETTINGS } = actions;
  const { siteConfig = {} } = siteSettings || {};
  const [{ timezone }, i18nActions] = useContext(I18nContext);
  const { CHANGE_TIMEZONE } = i18nActions;
  const timeZoneCookie = getCookie(COOKIES_KEYS.TZ);

  useEffect(() => {
    if (
      pageType &&
      (!window.initialDeviceOptions ||
        window.initialDeviceOptions?.pageType !== pageType ||
        !window.siteSettings)
    ) {
      if (window.initialDeviceOptions?.pageType) {
        window.initialDeviceOptions.pageType = null;
      }
      if (!process.env.REACT_APP_IS_CMS_DISABLED) {
        (async () => {
          try {
            const settings = await getSiteSettings();

            if (isEmpty(settings)) {
              SET_DEFAULT_SETTINGS(fallbackSettings, pageType);
            } else {
              SET_NEW_SETTINGS(fallbackSettings, pageType, settings);
            }
          } catch {
            SET_DEFAULT_SETTINGS(fallbackSettings, pageType);
          }
        })();
      } else {
        SET_DEFAULT_SETTINGS(fallbackSettings, pageType);
      }
    }
  }, [fallbackSettings, pageType, SET_NEW_SETTINGS, SET_DEFAULT_SETTINGS]);

  useEffect(() => {
    if (siteConfig && siteSettings) {
      const newTimeZone = timeZoneCookie || siteConfig.timeZone;
      if (newTimeZone && newTimeZone !== timezone) {
        CHANGE_TIMEZONE(timeZoneCookie || siteConfig.timeZone);
      }
      setIsSiteSettingsSet(true);
    }
  }, [CHANGE_TIMEZONE, siteConfig, siteSettings, timeZoneCookie, timezone]);

  return isSiteSettingsSet;
};

export default useGetSiteSettings;
