const setFormErrors = ({ errors, setError, inputsFieldsMap = {} }) => {
  if (errors) {
    for (const item of errors) {
      if (item.error) {
        setError(
          inputsFieldsMap[item.key] || item.key,
          'required',
          item.message
        );
      }
    }
  }
};

export default setFormErrors;
