import { useContext } from 'react';
import { PageContext } from '@namespace/cms';
import { CustomFooterContent } from '../common/CustomFooterContent';
import styles from '../index.module.css';

export const ColumnsWithCustomFooter = () => {
  const { page } = useContext(PageContext);
  const { layout = {} } = page;
  const { columns = [] } = layout;

  return (
    <CustomFooterContent columns={columns} columnClassName={styles.column} />
  );
};
