import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { SiteSettingsContext, useGeneralRoutes } from '@namespace/cms';
import { Box, Button } from '@alf/uikit';
import { DeviceContext } from '@namespace/device';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { useGetImage, useThemeComponents } from '@namespace/themes';
import { UserContext } from '@namespace/user';
import { forwardRef, useContext, useState } from 'react';
import clsx from 'clsx';
import { PromoItem as PromoItemDefault } from './component/PromoItem';
import {
  PROMOTION_ITEM_TYPES,
  PROMOTION_ITEM_TYPE_TO_PROMO_TYPE
} from '../../constants';
import { useGetPromotionPageRouteByPromoItemType } from '../../hooks';
import getPromoImage from '../../utils/getPromoImage';
import styles from './component/PromoItem/index.module.css';

const promoItemTypeToTermsTransKey = {
  [PROMOTION_ITEM_TYPES.PROMOTION]: 'web.promotions.button.terms',
  [PROMOTION_ITEM_TYPES.TOURNAMENT]: 'web.tournaments.button.terms'
};

export const PromotionsItemWrapper = forwardRef(
  (
    {
      promo = {},
      isSimilarPromo = false,
      currentPromo = '',
      tagPath = '',
      index,
      gap,
      customClassNames = {}
    },
    ref
  ) => {
    const [isError, setIsError] = useState(false);
    const [isShown, setIsShown] = useState(false);
    const { t, language } = useI18n();
    const { push } = useHistory();
    const {
      promoId,
      titleTranslationKey,
      defaultImageLink,
      defaultImageLinkDark,
      settingsPerCurrency = [],
      imagesPerLanguage = [],
      actionLink,
      type
    } = promo;
    const [user] = useContext(UserContext);
    const { isMobile, isDesktopDevice, realPageType } = useContext(
      DeviceContext
    );
    const { activeWallet = {} } = user;
    const { currency: userCurrency = null } = activeWallet;
    const { promotion } = useGeneralRoutes();
    const [settings] = useContext(SiteSettingsContext);
    const { promoTags, siteConfig } = settings;
    const { defaultCurrency } = siteConfig;
    const currentCurrency = userCurrency || defaultCurrency;
    const promoPageRoute = useGetPromotionPageRouteByPromoItemType({
      activePromoItemType: type
    });
    const promoLink = actionLink || `${promoPageRoute || promotion}/${promoId}`;

    let currentDefaultImageLink = defaultImageLink;
    let currentDefaultImageLinkDark = defaultImageLinkDark;
    let currentImagesPerLanguage = imagesPerLanguage;

    // temporary solution!! this condition will be deleted when per platform
    // functionality for promos images will be on prod envs
    if (promo[realPageType]) {
      const {
        defaultImageLink: defaultImageLinkForPlatform,
        defaultImageLinkDark: defaultImageLinkDarkForPlatform,
        imagesPerLanguage: imagesPerLanguageForPlatform
      } = promo[realPageType];

      currentDefaultImageLink = defaultImageLinkForPlatform;
      currentDefaultImageLinkDark = defaultImageLinkDarkForPlatform;
      currentImagesPerLanguage = imagesPerLanguageForPlatform;
    }

    const getImg = useGetImage();
    const defaultPromoImage = getImg(
      currentDefaultImageLink,
      currentDefaultImageLinkDark
    );

    const promoImage = getPromoImage(
      settingsPerCurrency,
      currentImagesPerLanguage,
      language,
      realPageType,
      currentCurrency,
      getImg
    );

    const {
      promotionsItemContainer = '',
      similarPromo = '',
      layoutBtn = '',
      layout = '',
      promoImage: customPromoImage = ''
    } = customClassNames;

    const handleClickPromo = () => {
      logAction(
        isSimilarPromo
          ? LOG_ACTIONS.PROMO_SIMILAR_CLICK
          : LOG_ACTIONS.PROMO_CLICK,
        {
          index,
          promoCategory: isSimilarPromo
            ? `From gameId: ${currentPromo}`
            : tagPath,
          promoId,
          promoName: t(`${titleTranslationKey}`, promoId)
        }
      );
      push(promoLink);
    };

    const additionalProps = {
      ref,
      gap,
      isSimilarPromo,
      setIsShown,
      isDesktopDevice,
      isMobile,
      handleClickPromo,
      isError,
      defaultPromoImage,
      promoImage,
      isShown,
      setIsError,
      promoItemTypeToTermsTransKey,
      promoTags,
      PROMOTION_ITEM_TYPE_TO_PROMO_TYPE,
      t
    };

    const { PromoItem = PromoItemDefault } = useThemeComponents();

    return (
      <Box
        ref={ref}
        style={gap && { marginRight: gap }}
        className={clsx([
          styles.promotionsItemContainer,
          promotionsItemContainer,
          isSimilarPromo && styles.similarPromo,
          isSimilarPromo && similarPromo
        ])}
        direction="column"
        justify="start"
        align="center"
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        onClick={
          isDesktopDevice && isSimilarPromo ? undefined : handleClickPromo
        }
        data-role="promotions-item"
      >
        <Box className={styles.promotionsItemWrapper} justify="center">
          <img
            src={
              (isError ? defaultPromoImage : promoImage) || defaultPromoImage
            }
            className={clsx([
              styles.promoImage,
              customPromoImage,
              isShown &&
                isDesktopDevice &&
                !isSimilarPromo &&
                styles.imageScaled
            ])}
            onError={() => setIsError(true)}
            alt="promoImage"
            data-role="promotions-item-image"
          />
          {isShown &&
            isDesktopDevice &&
            (isSimilarPromo ? (
              <Box
                className={clsx(styles.layoutBtn, layoutBtn)}
                align="center"
                justify="center"
                data-role="promotions-item-terms-layout"
              >
                <Button
                  intent="secondary"
                  size="m"
                  actionType="color4"
                  onClick={handleClickPromo}
                  data-role="promotions-item-layout-btn"
                >
                  {t(promoItemTypeToTermsTransKey[promo.type])}
                </Button>
              </Box>
            ) : (
              <div
                className={clsx(styles.layout, layout)}
                data-role="promotions-item-layout"
              />
            ))}
        </Box>
        <PromoItem promo={promo} {...additionalProps} />
      </Box>
    );
  }
);
