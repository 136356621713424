import { MARKET_TEMPLATE_IDS_LIST } from '@namespace/sports/src/constants';

const ODDS_TYPE_MAP = {
  HONG_KONG: 'hongkong',
  DECIMAL: 'decimal',
  MALAYSIAN: 'malay'
  // INDONESIAN: 'indonesian',
  // AMERICAN: 'american',
  // FRACTIONAL: 'fractional'
};

// TODO This part should be moved to shared etc.
const METRIC_CONVERTERS_MAP = {
  [ODDS_TYPE_MAP.DECIMAL]: (coef) =>
    coef < 100 ? (coef * 1).toFixed(2) : parseInt(coef, 10),
  [ODDS_TYPE_MAP.HONG_KONG]: (coef) =>
    coef - 1 < 100 ? (coef - 1).toFixed(2) : parseInt(coef - 1, 10),
  [ODDS_TYPE_MAP.MALAYSIAN]: (coef) =>
    coef <= 2 ? (coef - 1).toFixed(2) : (-100 / (coef - 1) / 100).toFixed(2)
  // [ODDS_TYPE_MAP.INDONESIAN]: coef => (coef >= 2
  //   ? (coef - 1).toFixed(2)
  //   : Math.round(-100 / (coef - 1)) / 100),
  // [ODDS_TYPE_MAP.AMERICAN]: coef => (coef < 2
  //   ? String(Math.round(-100 / (coef - 1)))
  //   : ['+', Math.round(100 * (coef - 1))].join('')),
  // [ODDS_TYPE_MAP.FRACTIONAL]: coef => (coef === 2
  //   ? 'Evs (1/1)'
  //   : `${Fraction(coef - 1).n}/${Fraction(coef - 1).d}`)
};

const convertOutcome = (
  coefficient,
  marketId,
  metric = ODDS_TYPE_MAP.DECIMAL
) => {
  if (coefficient) {
    if (!MARKET_TEMPLATE_IDS_LIST.find((id) => id === marketId)) {
      return METRIC_CONVERTERS_MAP[ODDS_TYPE_MAP.DECIMAL](coefficient);
    }

    return METRIC_CONVERTERS_MAP[metric]
      ? METRIC_CONVERTERS_MAP[metric](coefficient)
      : coefficient.toFixed(2);
  }

  return null;
};

export default convertOutcome;
