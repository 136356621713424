import { useContext, useState, useCallback } from 'react';
import { useI18n } from '@namespace/i18n';
import { UserContext, changeUserPassword } from '@namespace/user';
import { useNotifications } from '@namespace/notifications';

const useChangePassword = () => {
  const { t } = useI18n();
  const [isFetching, setIsFetching] = useState(false);
  const [user] = useContext(UserContext);
  const { id: userId } = user;
  const { errorNotification, successNotification } = useNotifications();

  const savePassword = useCallback(
    async (fields, methods = {}) => {
      const { currentPassword, newPassword, repeatNewPassword } = fields;
      const {
        reset = () => {},
        setValue = () => {},
        setError = () => {}
      } = methods;
      setIsFetching(true);
      await changeUserPassword({
        id: userId,
        oldPassword: currentPassword,
        newPassword,
        confirmPassword: repeatNewPassword
      })
        .then(() => {
          successNotification(
            t('personalOffice.profile.changePassword.successMessage')
          );
          reset();
        })
        .catch(({ errorCode }) => {
          errorNotification(t(`accountingMessages.${errorCode}`));
          setValue('currentPassword', '');
          setError(
            'currentPassword',
            'required',
            'validators.requiredValidator'
          );
        })
        .finally(() => setIsFetching(false));
    },
    [t, userId]
  );

  return {
    isFetching,
    savePassword
  };
};

export default useChangePassword;
