import { assoc, mergeLeft } from 'ramda';
import { types } from '../types';
import { applyDefaults, navigateStep, navigateTour } from './utils';

export const initialState = {
  isRunning: false,
  isLoading: true,
  stepIndex: 0,
  steps: [],
  tourIndex: 0,
  tours: [],
  padding: 0,
  mocks: {},
  preferredPlacements: undefined,
  isInterrupted: false,
  snapshots: {},
  isSnapshotsLoaded: false,
  preferredCurrency: ''
};

export const reducers = {
  [types.RUN]: (state, isRunning) => assoc('isRunning', isRunning, state),
  [types.SET_STEPS]: (state, steps = []) => assoc('steps', steps, state),
  [types.SET_ONBOARDING_CONFIG]: (state, { config, pageType }) => {
    const appliedCfg = applyDefaults(config);
    const nextState = mergeLeft(appliedCfg, state);

    return navigateTour(nextState, { index: state.tourIndex, pageType });
  },
  [types.NEXT]: (state) => navigateStep(state, { isForward: true }),
  [types.PREV]: (state) => navigateStep(state, { isForward: false }),
  [types.GO_TO]: (state, index) => navigateStep(state, { index }),
  [types.SET_IS_LOADING]: (state, isLoading) =>
    assoc('isLoading', isLoading, state),
  [types.NEXT_TOUR]: (state) => navigateTour(state, { isForward: true }),
  [types.PREV_TOUR]: (state) => navigateTour(state, { isForward: false }),
  [types.GO_TO_TOUR]: (state, { index, pageType }) =>
    navigateTour(state, { index, pageType }),
  [types.GO_TO_TOUR_BY_ID]: (state, { tourId, pageType }) =>
    navigateTour(state, { tourId, pageType }),
  [types.SET_INTERRUPTED]: (state, isInterrupted) =>
    assoc('isInterrupted', isInterrupted, state),
  [types.SET_LOADED_SNAPSHOTS]: (state, snapshots) => ({
    ...state,
    snapshots,
    isSnapshotsLoaded: true
  })
};
