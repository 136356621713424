import { Box, EmptyState } from '@alf/uikit';
import { useGeneralRoutes } from '@namespace/cms';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { Image } from '@namespace/icons2';
import { useRouteOptionalParams } from '@namespace/router';
import { MarketsGroup } from '@namespace/sports';
import { UserContext } from '@namespace/user';
import { get, groupBy } from 'lodash';
import { useContext } from 'react';
import { VirtualSportsContext } from '../../../../store/context';
import styles from './index.module.css';

const Markets = () => {
  const [state = {}] = useContext(UserContext);
  const { settings = {} } = state;
  const { lineViewType: lineView } = settings;
  const { t } = useI18n();
  const [virtualSports] = useContext(VirtualSportsContext);
  const { virtualMarkets, virtualOutcomes } = virtualSports;

  const { virtualSportsOneSport } = useGeneralRoutes();
  const { provider, optional = [] } = useRouteOptionalParams(
    `${virtualSportsOneSport}/:provider/:categoryId/*`
  );
  const [, eventId] = optional;

  const markets = Object.entries(
    groupBy(get(virtualMarkets, `${provider}.${eventId}`, []), 'marketName')
  );

  if (!markets.length) {
    return (
      <EmptyState
        image={<Image name="general/looking_for" />}
        Link={LocalizedLink}
        text={t('virtualSports.noMoreBets')}
      />
    );
  }

  return (
    <Box className={styles.marketsContainer} align="center" direction="column">
      {markets.map((marketByName) => (
        <MarketsGroup
          key={marketByName[0]}
          title={marketByName[0]}
          listOfMarkets={marketByName[1]}
          outcomes={virtualOutcomes}
          lineView={lineView}
        />
      ))}
    </Box>
  );
};

export default Markets;
