import { AText, Box } from '@alf/uikit';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { useGeneralRoutes } from '@namespace/cms';
import styles from './index.module.css';

const Tags = ({ validTags = {}, activeTag = '', activePromoType = '' }) => {
  const { t } = useI18n();
  const { promotions } = useGeneralRoutes();

  return (
    <Box
      className={styles.tagsContainer}
      align="center"
      data-role="promotions-tags"
    >
      {Object.values(validTags).map(
        ({ categoryName, categoryNameTranslationKey }) => {
          const isActive = categoryName === activeTag;

          return (
            <Box
              className={styles.tagsContainerItem}
              key={categoryNameTranslationKey}
              data-role={`promotions-tag-${categoryName}`}
            >
              <LocalizedLink
                to={`${promotions}/${activePromoType}/${categoryName}`}
              >
                <AText
                  className={styles.tagTitle}
                  breakWord="keep-all"
                  size="m"
                  breed={isActive ? 'BU' : 'RU'}
                  color={`txt_color_${isActive ? '1' : '2'}`}
                >
                  {t(`${categoryNameTranslationKey}`, categoryName)}
                </AText>
              </LocalizedLink>
            </Box>
          );
        }
      )}
    </Box>
  );
};

export default Tags;
