import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { COLOR_SCHEME } from '@namespace/themes';
import { getTrigger } from '../../triggers';
import Box from '../../../Box';
import styles from './index.module.css';

const wrapEventHandler = (cb) => (e) =>
  cb({ value: [...e.target.selectedOptions].map((x) => x.value) });

export const MultiSelectMobile = ({
  options,
  onChange,
  value,
  title,
  style,
  disabled,
  placeholder,
  kind,
  size = 'xs',
  intent = 'default',
  className,
  classNames,
  customTrigger,
  dataRole,
  intentType
}) => {
  const Trigger = getTrigger(kind);

  const triggerProps = {
    value,
    title,
    disabled,
    intent,
    placeholder,
    className: classNames?.trigger,
    size,
    intentType
  };

  return (
    <Box
      style={style}
      data-role={dataRole}
      className={`${styles.root} ${className}`}
    >
      {customTrigger ? (
        cloneElement(customTrigger, triggerProps)
      ) : (
        <Trigger {...triggerProps} />
      )}
      <select
        value={value}
        multiple={true}
        disabled={disabled}
        className={styles.select}
        onChange={wrapEventHandler(onChange)}
      >
        {options.map((option) => (
          <option value={option.value} key={`${option.value}-${option.title}`}>
            {option.title}
          </option>
        ))}
      </select>
    </Box>
  );
};

MultiSelectMobile.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      title: PropTypes.string
    })
  ),
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  classNames: PropTypes.shape({ trigger: PropTypes.string }),
  kind: PropTypes.oneOf(['count', 'chips']),
  intent: PropTypes.oneOf(['error', 'success', 'default']),
  size: PropTypes.oneOf(['xs', 's', 'm']),
  style: PropTypes.object,
  intentType: PropTypes.oneOf([COLOR_SCHEME.PRIMARY, COLOR_SCHEME.SECONDARY])
};
