import { useContext, useEffect } from 'react';
import { LOG_ACTIONS, logAction } from '@namespace/analytics';
import { OnboardingContext } from '../../../store/context';

export const useTrackNewRelicAction = () => {
  const [{ steps, stepIndex, tours, tourIndex }] = useContext(
    OnboardingContext
  );
  const tourId = tours[tourIndex]?.id;
  const isFirstStep = stepIndex === 0;
  const isLastStep = stepIndex === steps.length - 1;

  useEffect(() => {
    if (isFirstStep) {
      logAction(LOG_ACTIONS.ONBOARDING_FIRST_STEP, {
        tour: tourId
      });
    }
  }, [isFirstStep, tourId]);

  useEffect(() => {
    if (isLastStep) {
      logAction(LOG_ACTIONS.ONBOARDING_LAST_STEP, {
        tour: tourId
      });
    }
  }, [isLastStep, tourId]);
};
