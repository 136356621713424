import { useReducer, createContext, useMemo } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import { reducers, initialState } from './reducers';
import { types } from './types';
import actionCreators from './actions';

// need a separate context for in-house jackpots because of performance reasons.
// jackpots state updates every 10sec.
export const JackpotsContext = createContext();

export const JackpotsProvider = ({ children, customReducers }) => {
  const reducer = useMemo(() => combineReducers(reducers, customReducers), [
    customReducers
  ]);

  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = useActions(types, dispatch, actionCreators);

  return (
    <JackpotsContext.Provider value={[state, actions]}>
      {children}
    </JackpotsContext.Provider>
  );
};
