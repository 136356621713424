import { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useI18n } from '@namespace/i18n';
import { Icon } from '@namespace/icons2';
import { AText, Box } from '@alf/uikit';

import FilesList from './FilesList';

import { makeValidationErrors } from '../utils';

import styles from '../index.module.css';

const ALLOWED_FILE_FORMATS = ['.JPEG', '.PNG', '.JPG', '.PDF', '.DOC'];
const MAX_FILE_SIZE = 7_000_000;

const filterByIndex = (items, index) => items.filter((i, idx) => idx !== index);

const UploadFile = ({ name, filesList, onChange, onError }) => {
  const [validationErrors, setValidationErrors] = useState({});

  const formRef = useRef();
  const { t } = useI18n();

  const handleFilesChange = useCallback(
    (items) => {
      const newValidationErrors = makeValidationErrors(
        validationErrors,
        items,
        { formats: ALLOWED_FILE_FORMATS, maxSize: MAX_FILE_SIZE }
      );

      onError(newValidationErrors);
      onChange(items);
      setValidationErrors(newValidationErrors);
    },
    [onChange, onError, validationErrors]
  );

  const handleReset = useCallback(
    (index) => () => {
      const allItems = filterByIndex(filesList, index);

      handleFilesChange(allItems);
      formRef.current && formRef.current.reset();
    },
    [filesList, handleFilesChange]
  );

  const handleFilesSelect = useCallback(
    ({ target }) => {
      const items = Array.from(target.files);

      if (!items.length) return;

      const allItems = filesList.concat(items);

      handleFilesChange(allItems);
    },
    [filesList, handleFilesChange]
  );

  return (
    <Box direction="column" align="center" flexGrow="1">
      {filesList.length > 0 && (
        <FilesList
          items={filesList}
          errors={validationErrors}
          onRemove={handleReset}
        />
      )}

      <Box className={styles.uploadDocsButton}>
        <Box
          className={styles.uploadDocsLabel}
          justify="center"
          align="center"
          bottom="s"
        >
          <Icon
            name="icons/general/action/upload"
            color="accent_color_5"
            size="s"
            className={styles.uploadDocsIcon}
          />
          <AText size="m" breed="BU" color="accent_color_5">
            {` ${t('verificationWizard.step.uploadDocuments.selectFiles')}`}
          </AText>
        </Box>

        <form ref={formRef}>
          <input
            name={name}
            type="file"
            className={styles.uploadInput}
            onChange={handleFilesSelect}
            multiple={true}
          />
        </form>
      </Box>
    </Box>
  );
};

UploadFile.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  filesList: PropTypes.array.isRequired
};

export default UploadFile;
