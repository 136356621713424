import { useContext, useEffect } from 'react';
import { COOKIES_KEYS, getCookie } from '@namespace/helpers';
import { IS_WIDGET } from '@namespace/widget';
import { useColorScheme } from '@namespace/themes';
import usePrompt from '../usePrompt';
import { NativeAppContext } from '../../store';
import useNativeAppsLinks from '../useNativeAppsLinks';
import { NATIVE_DEVICE_TYPE } from '../../constants';

const useNativeApp = () => {
  const [, { SET_IS_NATIVE_APP, SET_NATIVE_APP_CONFIG }] = useContext(
    NativeAppContext
  );
  const { setColorScheme } = useColorScheme();

  useEffect(() => {
    if (!IS_WIDGET) {
      const nativeAppData = getCookie(COOKIES_KEYS.NATIVE_APP);
      SET_IS_NATIVE_APP(Boolean(nativeAppData));

      try {
        const data = nativeAppData ? JSON.parse(nativeAppData) : null;

        if (data) {
          const { header, footer, popup, ...rest } = data;
          SET_NATIVE_APP_CONFIG({
            headerVisibility: data.header,
            footerVisibility: data.footer,
            popupVisibility: data.popup,
            isIos: data.type === NATIVE_DEVICE_TYPE.IOS,
            isAndroid: data.type === NATIVE_DEVICE_TYPE.ANDROID,
            ...rest
          });

          if (data.theme) {
            setTimeout(() => setColorScheme(data.theme), 1);
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, []);

  useNativeAppsLinks();
  usePrompt();
};

export default useNativeApp;
