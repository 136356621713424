import { merge } from 'lodash';
import {
  BRACKET_MATCHES_EMPTY_MOCK,
  PREDICTIONS_STEPS_IDS
} from '../../constants';

const formatTopOfThirdBracket = (teams) => {
  const defaultBracket = [
    ...BRACKET_MATCHES_EMPTY_MOCK[PREDICTIONS_STEPS_IDS.TOP_OF_THIRD][0]
  ];
  return merge(
    // danger mutate variables
    defaultBracket,
    teams
      .filter((team) => team.index === 3)
      .sort((a, b) => (a.groupId > b.groupId ? 1 : -1))
      .map((team) => team.id)
  );
};

export default formatTopOfThirdBracket;
