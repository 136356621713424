export const types = {
  SET_USER: 'SET_USER',
  SET_USER_B_TAG: 'SET_USER_B_TAG',
  SET_DOCUMENTS: 'SET_DOCUMENTS',
  UPDATE_NOTIFICATIONS: 'UPDATE_NOTIFICATIONS',
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  UPDATE_PHONE: 'UPDATE_PHONE',
  UPDATE_USER: 'UPDATE_USER',
  SET_PHONE: 'SET_PHONE',
  UPDATE_ACTIVE_WALLET: 'UPDATE_ACTIVE_WALLET',
  SET_ACTIVE_WALLET: 'SET_ACTIVE_WALLET',
  UPDATE_WALLET_BALANCE: 'UPDATE_WALLET_BALANCE',
  CONFIGURE_WALLETS: 'CONFIGURE_WALLETS',
  SET_WALLETS: 'SET_WALLETS',
  ADD_WALLET: 'ADD_WALLET',
  SET_MESSAGES_COUNT: 'SET_MESSAGES_COUNT',
  FETCH_MESSAGES_COUNTERS: 'FETCH_MESSAGES_COUNTERS',
  SET_COUNTERS: 'SET_COUNTERS',
  CHECK_IS_VIDEO_AVAILABLE: 'CHECK_IS_VIDEO_AVAILABLE',
  SET_CHANGE_USER: 'SET_CHANGE_USER',
  FETCH_USER_DATA: 'FETCH_USER_DATA',
  FETCH_LOGIN: 'FETCH_LOGIN',
  FETCH_LOGOUT: 'FETCH_LOGOUT',
  LOGGING_OUT: 'LOGGING_OUT',
  GET_BANK_ACCOUNTS: 'GET_BANK_ACCOUNTS',
  CREATE_BANK_ACCOUNT: 'CREATE_BANK_ACCOUNT',
  UPDATE_BANK_ACCOUNT: 'UPDATE_BANK_ACCOUNT',
  DELETE_BANK_ACCOUNT: 'DELETE_BANK_ACCOUNT',
  SHOW_VERIFICATION_MESSAGE: 'SHOW_VERIFICATION_MESSAGE',
  SET_VERIFICATION_MESSAGE: 'SET_VERIFICATION_MESSAGE',
  UPLOAD_DOCUMENTS: 'UPLOAD_DOCUMENTS',
  SET_BANK_ACCOUNTS: 'SET_BANK_ACCOUNTS',
  UPDATE_ADDRESS_FIELDS: 'UPDATE_ADDRESS_FIELDS',
  SET_ADDRESS_FIELDS: 'SET_ADDRESS_FIELDS',
  SHOW_QUICK_DEPOSIT: 'SHOW_QUICK_DEPOSIT',
  SET_QUICK_DEPOSIT: 'SET_QUICK_DEPOSIT',
  CHANGE_USER: 'CHANGE_USER',
  SET_LINE_VIEW: 'SET_LINE_VIEW',
  CHANGE_LINE_VIEW: 'CHANGE_LINE_VIEW',
  SET_BONUS_WALLET: 'SET_BONUS_WALLET',
  FETCH_BONUS_WALLET: 'FETCH_BONUS_WALLET',
  FETCH_USER_DATA_FOR_WIDGET: 'FETCH_USER_DATA_FOR_WIDGET',
  PHONE_VERIFY: 'PHONE_VERIFY',
  PHONE_CONFIRM: 'PHONE_CONFIRM',
  SET_IS_PHONE_VERIFIED: 'SET_IS_PHONE_VERIFIED',
  SET_PEP_ATTRIBUTES: 'SET_PEP_ATTRIBUTES',
  SET_IS_BONUS_COUNTERS_LOADED: 'SET_IS_BONUS_COUNTERS_LOADED',
  SET_USER_INFO_LOADED: 'SET_USER_INFO_LOADED',
  SET_WAITING_ONBOARDING: 'SET_WAITING_ONBOARDING',
  SET_ACCOUNT_STATUSES: 'SET_ACCOUNT_STATUSES',
  ENABLE_FAST_BET: 'ENABLE_FAST_BET',
  SET_FAST_BET: 'SET_FAST_BET',
  UPDATE_POLICY: 'UPDATE_POLICY',
  SET_NEW_POLICY: 'SET_NEW_POLICY',
  UPDATE_ACCOUNT_STATUSES: 'UPDATE_ACCOUNT_STATUSES'
};
