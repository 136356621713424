import { useMemo, useReducer } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import { reducers, initialState } from './reducer';
import { types } from './types';
import { OnboardingContext } from './context';

export const OnboardingProvider = ({ customReducers, children }) => {
  const memoReducers = useMemo(() => {
    return combineReducers(reducers, customReducers);
  }, [customReducers]);

  const [state, dispatch] = useReducer(memoReducers, initialState);

  const actions = useActions(types, dispatch);

  return (
    <OnboardingContext.Provider value={[state, actions]}>
      {children}
    </OnboardingContext.Provider>
  );
};
