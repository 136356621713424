import { RESULT_TYPE_IDS } from '../../../../../../../constants';

/**
 * @memberOf module:SetScore
 * Build eventResultTotal string for basketball
 * @param {Object.<string, number>} scores
 * @param {ResultTypeData} byResultType
 */
export const buildBasketballTotal = (scores, byResultType) => {
  const firstQuarterIdx = byResultType.findIndex(
    ({ resultTypeId }) => resultTypeId === RESULT_TYPE_IDS.QUARTER1
  );
  const secondQuarterIdx = byResultType.findIndex(
    ({ resultTypeId }) => resultTypeId === RESULT_TYPE_IDS.QUARTER2
  );
  const thirdQuarterIdx = byResultType.findIndex(
    ({ resultTypeId }) => resultTypeId === RESULT_TYPE_IDS.QUARTER3
  );
  const fourthQuarterIdx = byResultType.findIndex(
    ({ resultTypeId }) => resultTypeId === RESULT_TYPE_IDS.QUARTER4
  );
  const fourthQuarterETIdx = byResultType.findIndex(
    ({ resultTypeId }) => resultTypeId === RESULT_TYPE_IDS.QUARTER4_WITH_ET
  );

  const resultIdxs = [
    firstQuarterIdx,
    secondQuarterIdx,
    thirdQuarterIdx,
    fourthQuarterIdx,
    fourthQuarterETIdx
  ];

  const detailedResult = [
    `${scores.firstParticipantQ1Points}:${scores.secondParticipantQ1Points}`,
    `${scores.firstParticipantQ2Points}:${scores.secondParticipantQ2Points}`,
    `${scores.firstParticipantQ3Points}:${scores.secondParticipantQ3Points}`,
    `${scores.firstParticipantQ4Points}:${scores.secondParticipantQ4Points}`,
    `${scores.firstParticipantQ4ETPoints}:${scores.secondParticipantQ4ETPoints}`
  ].filter((_, idx) => resultIdxs[idx] >= 0);

  let eventResultTotal = `${scores.firstParticipantTotalPoints}:${scores.secondParticipantTotalPoints}`;
  eventResultTotal += ` (${detailedResult.join(',')})`;

  return eventResultTotal;
};
