export const types = {
  SET_IS_NATIVE_APP: 'SET_IS_NATIVE_APP',
  SET_NATIVE_APP_CONFIG: 'SET_NATIVE_APP_CONFIG',
  SET_ANDROID_LINK: 'SET_ANDROID_LINK',
  SET_IOS_LINK: 'SET_IOS_LINK'
};

export const initialState = {
  isNativeApp: false,
  nativeAppConfig: {
    headerVisibility: false,
    footerVisibility: false,
    popupVisibility: true,
    delegateRouting: false,
    type: '' // "android" || "ios"
  },
  androidLink: '',
  iosLink: ''
};

export const reducer = {
  [types.SET_IS_NATIVE_APP]: (state, payload) => ({
    ...state,
    isNativeApp: payload
  }),
  [types.SET_NATIVE_APP_CONFIG]: (state, payload) => ({
    ...state,
    nativeAppConfig: { ...payload }
  }),
  [types.SET_ANDROID_LINK]: (state, { androidLink }) => ({
    ...state,
    androidLink
  }),
  [types.SET_IOS_LINK]: (state, { iosLink }) => ({
    ...state,
    iosLink
  })
};
