import { cloneElement, useContext, useEffect, useState } from 'react';
import { useI18n } from '@namespace/i18n';
import {
  SPORT_IDS,
  sportsbookActions,
  useSportsBookDataState,
  useSportsbookStore,
  useSportsSettings
} from '@namespace/sports';
import { useVirtualSportsProviders } from '@namespace/virtual-sports';
import { DeviceContext } from '@namespace/device';
import { POST_MESSAGE_TYPES, usePostMessage } from '@namespace/widget';
import { BurgerMenuContext } from './context';
import {
  CYBERSPORT,
  LIVE,
  LOTTERIES,
  PROMO,
  SPORTS,
  TOURNAMENT,
  VIRTUALS
} from './constants';

const {
  FETCH_SPORTS_BOOK_DATA,
  FETCH_CATEGORIES_AND_TOURNAMENTS
} = sportsbookActions;

const { BURGER_MENU_OPEN, BURGER_MENU_CLOSE } = POST_MESSAGE_TYPES;

const BurgerMenuWrapper = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { deviceFormFactor } = useContext(DeviceContext);
  const { language } = useI18n();
  const {
    categoriesAndTournamentsDataState,
    categoriesBySportId
  } = useSportsbookStore([
    'categoriesAndTournamentsDataState',
    'categoriesBySportId'
  ]);
  const [burgerMenu] = useContext(BurgerMenuContext);
  const { activeTab } = burgerMenu;
  const { isSportsBookDataLoaded } = useSportsBookDataState();
  const virtualProviders = useVirtualSportsProviders();
  const { postMessage } = usePostMessage();
  const { timeRangeForAllPeriodByEvents } = useSportsSettings();

  useEffect(() => {
    if (isOpen) {
      postMessage(BURGER_MENU_OPEN, '');
    } else {
      postMessage(BURGER_MENU_CLOSE, '');
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      switch (activeTab) {
        case SPORTS:
        case LIVE:
          if (!isSportsBookDataLoaded) {
            FETCH_SPORTS_BOOK_DATA({
              lang: language,
              timeRange: timeRangeForAllPeriodByEvents
            });
          }
          break;
        case CYBERSPORT:
          FETCH_CATEGORIES_AND_TOURNAMENTS({
            lang: language,
            sportId: SPORT_IDS.CYBERSPORT,
            categoriesBySportId,
            timeRange: timeRangeForAllPeriodByEvents
          });

          break;
        case VIRTUALS:
        case LOTTERIES:
        case PROMO:
        case TOURNAMENT:
          break;
        default:
      }
    }
  }, [
    language,
    activeTab,
    isOpen,
    categoriesAndTournamentsDataState,
    isSportsBookDataLoaded,
    deviceFormFactor,
    virtualProviders
  ]);

  return cloneElement(children, { isOpen, setIsOpen });
};

export default BurgerMenuWrapper;
