import { useContext } from 'react';
import { AText, Box } from '@alf/uikit';
import { Image } from '@namespace/icons2';
import { DeviceContext } from '@namespace/device';
import styles from './index.module.css';

const BonusHunterBlock = ({ text = '' }) => {
  const { isMobile } = useContext(DeviceContext);

  return (
    <Box
      className={styles.wrapper}
      direction="column"
      align="center"
      justify="center"
    >
      <Image
        className={styles.image}
        data-role="bonus-hunter-image"
        name="general/bonus_hunter"
      />
      <AText
        size={isMobile ? '2xl' : '3xl'}
        data-role="bonus-hunter-text"
        color="txt_color_7"
        breed="BU"
        align="center"
      >
        {text}
      </AText>
    </Box>
  );
};

export default BonusHunterBlock;
