import { memo, useMemo } from 'react';
import { useI18n } from '@namespace/i18n';
import ScoreDetails from '../../Details';
import { COLUMN_TYPES } from '../../../../constants';
import {
  SCOPE_IDS_FOR_PERIODS,
  TOTAL_SCOPE_ID_BY_SPORT,
  TOTAL_SYMBOL_BY_SPORT
} from '../../constants';
import {
  getDetailedScoresForTable,
  getEventValues,
  getResultTypeScoreValues,
  stripeScore
} from '../../utils';

const ShortFootball = ({
  byResultType,
  total,
  sportId,
  eventResultId,
  isShortVersion,
  lineView
}) => {
  const { t } = useI18n();

  const commonScoreTotalData = useMemo(
    () => ({
      type: COLUMN_TYPES.TOTAL,
      tag: t(TOTAL_SYMBOL_BY_SPORT.default),
      scores: getEventValues(total, TOTAL_SCOPE_ID_BY_SPORT[sportId])
    }),
    [sportId, t, total]
  );

  const calculatedFullFormattedScore = useMemo(() => {
    let fullFormattedScore = [];

    if (isShortVersion) {
      fullFormattedScore = [
        { ...commonScoreTotalData },
        {
          type: COLUMN_TYPES.DEFAULT,
          tag: '',
          scores: getResultTypeScoreValues(
            byResultType,
            eventResultId,
            SCOPE_IDS_FOR_PERIODS[sportId] || TOTAL_SCOPE_ID_BY_SPORT[sportId]
          )
        }
      ];
    } else {
      fullFormattedScore = [
        { ...commonScoreTotalData },
        ...stripeScore(
          getDetailedScoresForTable(byResultType),
          sportId,
          eventResultId,
          false
        )
      ];
    }

    return fullFormattedScore;
  }, [
    byResultType,
    commonScoreTotalData,
    eventResultId,
    isShortVersion,
    sportId
  ]);

  return (
    <ScoreDetails
      scoreData={calculatedFullFormattedScore}
      isShortVersion={isShortVersion}
      lineView={lineView}
    />
  );
};

export default memo(ShortFootball);
