import { useState, useEffect, useCallback } from 'react';
import { PREDEFINED_COUNTRY_CODES } from '../../constants';

const usePhoneMask = () => {
  const [mask, setMask] = useState(null);
  const [countryCode, setCountryCode] = useState(null);

  const onSetCountryCode = useCallback(
    (newIsoCode) => {
      if (PREDEFINED_COUNTRY_CODES[newIsoCode]) {
        setCountryCode(newIsoCode);
      } else {
        setCountryCode(null);
        setMask(null);
      }
    },
    [setCountryCode]
  );

  useEffect(() => {
    if (countryCode) {
      if (PREDEFINED_COUNTRY_CODES[countryCode]) {
        setMask(PREDEFINED_COUNTRY_CODES[countryCode]);
      } else {
        setMask(null);
      }
    }
  }, [countryCode]);

  return [mask, onSetCountryCode];
};

export default usePhoneMask;
