import { useCallback, useEffect } from 'react';
import { useI18n, LocalizedLink } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { clearRedirectAction } from '@namespace/common';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { Image } from '@namespace/icons2';
import { HTMLSnippet } from '@namespace/snippets';
import { AText, Box, Button } from '@alf/uikit';
import { useConfig, useGeneralRoutes, useOtherSettings } from '@namespace/cms';
import { OnboardingRegButton } from '@namespace/onboarding';
import useUserInfoLoaded from '@namespace/user/src/hooks/useUserInfoLoaded';
import PrivateContentLoader from '@namespace/user/src/common/PrivateContentLoader';
import { REGISTER_STEP_TYPES } from './constants';
import GuestContent from '../../common/GuestContent';
import styles from './index.module.css';

const Success = ({
  snippetId,
  replenishBalanceLink,
  snippetIdPaymentIcons = '',
  showTopUpButton = true,
  isNextButton = false,
  depositRedirectConfig
}) => {
  const { t } = useI18n();
  const { push } = useHistory();
  const { createWallet } = useGeneralRoutes();
  const { userSettings = {} } = useOtherSettings();
  const { isOnboardingEnabled = false } = userSettings;
  const { userInfoLoaded } = useUserInfoLoaded();

  useEffect(() => {
    if (userInfoLoaded && depositRedirectConfig?.isNeedDepRedirect) {
      const timeoutId = setTimeout(() => {
        push(replenishBalanceLink || createWallet);
      }, depositRedirectConfig?.redirectDelay || 3000);

      return () => clearTimeout(timeoutId);
    }
    return undefined;
  }, [depositRedirectConfig, userInfoLoaded]);

  useEffect(() => {
    let timeoutId;
    if (depositRedirectConfig?.isNeedDepRedirect) {
      timeoutId = setTimeout(() => {
        push(replenishBalanceLink || createWallet);
      }, depositRedirectConfig?.redirectDelay || 3000);
    }

    return () => clearTimeout(timeoutId);
  }, [depositRedirectConfig]);

  return (
    <PrivateContentLoader>
      <Box
        direction="column"
        gap="m"
        className={styles.wrapper}
        data-role="register-success-screen"
      >
        {snippetId && <HTMLSnippet snippetId={snippetId} />}
        {showTopUpButton && (
          <Button
            size="xl"
            isNextButton={isNextButton}
            contentClass={styles.fullWidth}
            component={LocalizedLink}
            to={replenishBalanceLink || createWallet}
          >
            <AText
              breed={!isNextButton && 'BU'}
              semibold={isNextButton}
              size="xl"
            >
              {t('shortReg.createWallet')}
            </AText>
            {!isNextButton && (
              <Image
                name="payments/visa_mastercard_goriz"
                className={styles.visaMasterCard}
                alt=""
              />
            )}
          </Button>
        )}
        {isOnboardingEnabled && <OnboardingRegButton />}
        {snippetIdPaymentIcons && (
          <HTMLSnippet snippetId={snippetIdPaymentIcons} />
        )}
      </Box>
    </PrivateContentLoader>
  );
};

const RegisterSuccess = ({ id }) => {
  const { push } = useHistory();
  const { config } = useConfig(id);
  const {
    needHelpText,
    isShowNeedHelp,
    isShowSupport = true,
    isWithCloseButton = true,
    hideNeedHelpLabel = false
  } = config;
  const { home } = useGeneralRoutes();
  const defaultSteps = [
    {
      type: REGISTER_STEP_TYPES.SUCCESS,
      config
    }
  ];

  const onClose = useCallback(() => {
    const redirectURL = clearRedirectAction();
    push(redirectURL || home);
  }, [home]);

  useEffect(() => logAction(LOG_ACTIONS.REGISTRATION_SUCCESS_SCREEN), []);

  return (
    <GuestContent
      config={{
        hideNeedHelpLabel,
        isShowSupport,
        isShowNeedHelp,
        isWithCloseButton,
        needHelpText,
        isShowStepLine: false,
        isShowStepLineOnSuccess: false,
        steps: defaultSteps
      }}
      componentsMap={{ [REGISTER_STEP_TYPES.SUCCESS]: Success }}
      onClose={onClose}
      dataRole="register-success-page"
      classNames={{ stepperWrapperClassName: styles.stepperWrapperClassName }}
    />
  );
};

export default RegisterSuccess;
