import { SearchEmpty } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { Image } from '@namespace/icons2';
import EventsGroup from './EventsGroup';

export const SearchResults = ({ events = [], query, onSelect }) => {
  const { t } = useI18n();
  const allEventsLength = events.reduce(
    (acc, { events: list = [] }) => acc + list.length,
    0
  );

  if (allEventsLength > 0) {
    return events.map(({ serviceId, events: list }) =>
      !list.length ? null : (
        <EventsGroup
          key={serviceId}
          query={query}
          onSelect={onSelect}
          type={serviceId}
          events={list}
        />
      )
    );
  }

  return (
    <SearchEmpty
      image={<Image name="general/looking_for" />}
      text={t('sportsbook.emptyState.noEvents')}
    />
  );
};
