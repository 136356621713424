import { postAsUriParameters } from '@namespace/helpers';
import { camelizeKeys } from 'humps';
import { isEmpty } from 'lodash';
import apiRoutes from './apiRoutes';

export const checkUsername = (username) =>
  postAsUriParameters(apiRoutes.checkUsername, { username })
    .then(camelizeKeys)
    .then((response) => {
      const {
        username: { error = '', errorCode = '' }
      } = response;
      return {
        error: error === 'yes' && !isEmpty(response.username),
        errorCode: errorCode || ''
      };
    });
