import { useEffect, useRef, useState } from 'react';
import { CommonLoader } from '@namespace/common';
import { Overlay, Input } from '@alf/uikit';

const PaymentSystemResultForm = ({
  formData,
  action,
  method = 'POST',
  target = ''
}) => {
  const formRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (formRef.current) {
      formRef.current.submit();
      setIsLoading(false);
    }
  }, []);

  return (
    <form
      ref={formRef}
      action={action}
      target={target}
      method={method}
      acceptCharset="utf-8"
    >
      {target !== '_blank' && isLoading && (
        <Overlay>
          <CommonLoader />
        </Overlay>
      )}
      {Object.keys(formData).map((item, index) => {
        const key = index;
        return (
          <Input
            type="hidden"
            name={item}
            value={`${formData[item]}`}
            key={`resultFormInput-${key}-${item}`}
          />
        );
      })}
    </form>
  );
};

export default PaymentSystemResultForm;
