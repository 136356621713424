import { Icon } from '@namespace/icons2';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { useConfig } from '@namespace/cms';
import { useLiveCalendarEvents } from '../../hooks/useLiveCalendarEvents';
import { WidgetWrapper } from '../../common';
import BackHeader from '../../common/BackHeader';
import { Filters } from './components/Filters';
import { EventsLine } from './components/EventsLine';
import styles from './index.module.css';

export const LiveCalendar = ({ id }) => {
  const {
    isEventsLoading,
    eventsData,
    sportsList,
    selectDay,
    date,
    selectHours,
    selectedHours,
    selectSport,
    selectedSports,
    setIsOnlyWithVideo,
    isOnlyWithVideo,
    resetFilters,
    showMore
  } = useLiveCalendarEvents();
  const { goBack } = useHistory();
  const { t, f } = useI18n();
  const { config = {} } = useConfig(id);
  return (
    <WidgetWrapper>
      <BackHeader
        containerClassName={styles.backHeader}
        title={t('live.calendar.liveCalendarTitle')}
        arrowBackIcon={
          <Icon
            name="icons/general/nav/back"
            color="line_header_txt"
            size="s"
          />
        }
        showArrowBack={true}
        onBackClick={goBack}
        dataRole="live-calendar-back-header"
      />
      <Filters
        sportsList={sportsList}
        selectDay={selectDay}
        date={f.getDateTime({ date, withTZ: false }).toJSDate()}
        selectHours={selectHours}
        selectedHours={selectedHours}
        selectSport={selectSport}
        selectedSports={selectedSports}
        setIsOnlyWithVideo={setIsOnlyWithVideo}
        isOnlyWithVideo={isOnlyWithVideo}
        resetFilters={resetFilters}
      />
      <EventsLine
        eventsData={eventsData}
        showMore={showMore}
        isEventsLoading={isEventsLoading}
        config={config}
        isLiveEvents={true}
        dataRole="live-calendar-events"
      />
    </WidgetWrapper>
  );
};
