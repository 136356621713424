import { useComponentConfig } from '@namespace/cms';
import { useEffect } from 'react';
import Line from '../../../Line';
import { useFetchEvents } from '../../../../hooks/useFetchEvents';
import { initialState, useEventsByIdsToShow } from '../../../../store';
import { NEXT_MORNING_FILTER, TODAY_FILTER } from '../../../../constants';

const emptyArr = [];
export const CommonSportLine = ({
  filter,
  defaultFilter,
  sport,
  categoryIds = emptyArr,
  tournamentsIds = emptyArr,
  lineKey,
  serviceId,
  activeHeadGroups,
  isShowFlag,
  isExpanded,
  isShowEmpty,
  isCollapsibleTournaments,
  isLiveEvents,
  isTodayFilterApplied,
  onDefaultFilterChange,
  isReady
}) => {
  const {
    isHeadGroupsSortingEnabled,
    isShowEventGroup,
    activeHeadGroupsAmount,
    initialEventsLimit,
    moreEventsLimit
  } = useComponentConfig();

  const { days = '' } = filter || {};

  const isActiveFilterToday =
    new Date(days).toLocaleDateString() === new Date().toLocaleDateString() ||
    (filter && filter[NEXT_MORNING_FILTER]);

  const eventsToShow = useEventsByIdsToShow(lineKey, tournamentsIds);

  const {
    isEventsLoading,
    loadMoreEvents,
    isLoadingMore,
    isEventsLoaded,
    isFilterEventsLoaded
  } = useFetchEvents({
    lineKey,
    serviceId,
    filter,
    sportId: sport?.sportId,
    categoryIds,
    tournamentsIds,
    initialEventsLimit: initialEventsLimit || initialState.limit || 0,
    moreEventsLimit: moreEventsLimit || initialState.limit,
    isTodayFilterApplied
  });

  useEffect(() => {
    if (
      isActiveFilterToday &&
      isFilterEventsLoaded &&
      eventsToShow.length === 0 &&
      (defaultFilter === TODAY_FILTER || defaultFilter === NEXT_MORNING_FILTER)
    ) {
      onDefaultFilterChange();
    }
  }, [
    defaultFilter,
    eventsToShow.length,
    isActiveFilterToday,
    isFilterEventsLoaded,
    onDefaultFilterChange
  ]);

  return (
    <>
      <Line
        eventsToShow={eventsToShow}
        tournamentIds={tournamentsIds}
        isExpanded={isExpanded}
        isShowEventGroup={isShowEventGroup}
        isShowFlag={isShowFlag}
        isHeadGroupsSortingEnabled={isHeadGroupsSortingEnabled}
        isEventsLoading={isEventsLoading}
        activeHeadGroupsAmount={activeHeadGroupsAmount}
        activeHeadGroups={activeHeadGroups}
        fetchEvents={loadMoreEvents}
        isLoadingMore={isLoadingMore}
        isShowEmpty={isShowEmpty}
        isCollapsibleTournaments={isCollapsibleTournaments}
        lineKey={lineKey}
        isEventsLoaded={isEventsLoaded}
        isLiveEvents={isLiveEvents}
        isReady={isReady}
      />
    </>
  );
};
