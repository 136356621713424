import {
  LOG_ACTIONS,
  NEW_RELIC_LOG_ERRORS,
  logNewRelicAction
} from '@namespace/analytics';
import { document } from '@namespace/helpers';

export const loadScript = (reCaptchaId, place) => {
  logNewRelicAction(LOG_ACTIONS.RECAPTCHA_SUCCESS, {
    message: 'ReCAPTCHA init script',
    place
  });
  return new Promise((resolve) => {
    const scriptElement = document.createElement('script');
    scriptElement.src = `https://www.google.com/recaptcha/enterprise.js?render=${reCaptchaId}`;
    scriptElement.async = true;
    scriptElement.type = 'text/javascript';

    scriptElement.addEventListener(
      'load',
      function() {
        logNewRelicAction(LOG_ACTIONS.RECAPTCHA_SUCCESS, {
          message: 'ReCAPTCHA load script success android',
          place
        });
        resolve(true);
      },
      false
    );

    scriptElement.addEventListener(
      'error',
      function() {
        logNewRelicAction(NEW_RELIC_LOG_ERRORS.RECAPTCHA_ERROR, {
          message: 'ReCaptcha load script error android',
          place
        });
        resolve(false);
      },
      false
    );

    scriptElement.onload = () => {
      logNewRelicAction(LOG_ACTIONS.RECAPTCHA_SUCCESS, {
        message: 'ReCAPTCHA load script success',
        place
      });
      resolve(true);
    };

    scriptElement.onerror = () => {
      logNewRelicAction(NEW_RELIC_LOG_ERRORS.RECAPTCHA_ERROR, {
        message: 'ReCaptcha load script error',
        place
      });
      resolve(false);
    };
    logNewRelicAction(LOG_ACTIONS.RECAPTCHA_SUCCESS, {
      message: 'ReCAPTCHA script append',
      place
    });

    document.head.appendChild(scriptElement);
  });
};
