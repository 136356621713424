import { useContext, useMemo, useEffect } from 'react';
import { get } from 'lodash';
import { useRouteOptionalParams, useHistory } from '@namespace/router';
import { useGeneralRoutes, SiteSettingsContext } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { VirtualSportsContext } from '../../store/context';
import isBetradarProvider from '../../utils/isBetradarProvider';

const MARKETS_UPDATE_TIME = 5000;

const VirtualSport = () => {
  const { language } = useI18n();
  const [virtual, virtualActions] = useContext(VirtualSportsContext);
  const [
    {
      siteConfig: { partnerId },
      virtualSports: { virtualClient }
    }
  ] = useContext(SiteSettingsContext);
  const {
    virtualSports,
    virtualEvents,
    virtualStreams,
    virtualMarkets,
    virtualTournaments
  } = virtual;
  const { FETCH_VIRTUAL_STREAM, FETCH_VIRTUAL_EVENT_MARKETS } = virtualActions;

  const { replace } = useHistory();

  const { virtualSportsOneSport } = useGeneralRoutes();
  const { provider, categoryId, optional = [] } = useRouteOptionalParams(
    `${virtualSportsOneSport}/:provider/:categoryId/*`
  );
  const [tournamentId, eventId] = optional;

  const isBetradar = isBetradarProvider(provider);

  // @todo use as selector
  const [event] = useMemo(
    () =>
      get(virtualSports, [provider, categoryId, 'tournaments'], [])
        .map((t) => virtualTournaments[provider][t])
        .reduce(
          (acc, tour) =>
            !isBetradar ? [...acc, ...tour.events] : [...acc, tour.events[0]],
          []
        )
        .map((e) => virtualEvents[provider][e])
        .sort((a, b) => a.eventDt - b.eventDt),
    [
      isBetradar,
      virtualSports,
      provider,
      categoryId,
      virtualTournaments,
      virtualEvents
    ]
  );

  useEffect(() => {
    if (!eventId && event) {
      replace(
        `${virtualSportsOneSport}/${provider}/${categoryId}/${event.tournamentId}/${event.eventId}`
      );
    }
  }, [event, eventId]);

  useEffect(() => {
    if (
      tournamentId &&
      eventId &&
      !get(
        virtualStreams,
        isBetradar ? `${provider}.${eventId}` : `${provider}.${categoryId}`
      )
    ) {
      FETCH_VIRTUAL_STREAM({
        partnerId,
        provider,
        tournamentId,
        categoryId,
        eventId,
        virtualClient
      });
    }
  }, [provider, categoryId, tournamentId, eventId, virtualStreams]);

  useEffect(() => {
    if (eventId && !virtualMarkets?.[provider]?.[eventId]) {
      FETCH_VIRTUAL_EVENT_MARKETS({
        eventId,
        provider,
        language
      });
    }
  }, [virtualMarkets, provider, eventId, language]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (eventId && provider) {
        if (virtualMarkets?.[provider]?.[eventId]?.length === 0) {
          clearInterval(interval);
        } else {
          FETCH_VIRTUAL_EVENT_MARKETS({
            eventId,
            provider,
            language
          });
        }
      }
    }, MARKETS_UPDATE_TIME);

    return () => clearInterval(interval);
  }, [language, eventId, partnerId, provider, virtualMarkets]);

  return null;
};

export default VirtualSport;
