import { useContext, useEffect, useCallback } from 'react';
import { scrollTo } from '@namespace/helpers';
import { useGeneralRoutes } from '@namespace/cms';
import { UserContext, USER_ROLES } from '@namespace/user';
import { useI18n, LocalizedLink } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { AText, Box, Button } from '@alf/uikit';
import { Image } from '@namespace/icons2';
import styles from './index.module.css';

const SuccessScreen = ({ message = '' }) => {
  const { t } = useI18n();
  const [user] = useContext(UserContext);
  const { role } = user;
  const { home, login } = useGeneralRoutes();
  const { push } = useHistory();
  const goLogin = useCallback(() => push(login), []);

  useEffect(() => {
    scrollTo();
  }, []);

  return (
    <Box className={styles.container} direction="column" align="center">
      <Image name="general/success" className={styles.icon} alt="" />
      <AText
        className={`${styles.title} ${styles.success}`}
        size="l"
        breed="B"
        color="txt_color_3"
      >
        {t('stepper.success')}
      </AText>
      {message && (
        <AText
          color="txt_color_3"
          size="m"
          align="center"
          className={styles.message}
        >
          {t(message)}
        </AText>
      )}
      <Box
        className={styles.buttonGroup}
        direction="column"
        align="center"
        justify="center"
      >
        {role === USER_ROLES.GUEST && (
          <Button
            onClick={goLogin}
            className={styles.submitButton}
            size="xl"
            type="submit"
          >
            {t('stepper.login')}
          </Button>
        )}
        <LocalizedLink to={home} className={styles.homeLink}>
          <AText color="btn_quaternary_color_3" size="m" breed="BU">
            {t('stepper.homeLink')}
          </AText>
        </LocalizedLink>
      </Box>
    </Box>
  );
};

export default SuccessScreen;
