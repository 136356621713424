import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { addSlashToPathEnd } from '@namespace/helpers';
import { useI18n } from '@namespace/i18n';
import useNavigate from '../useNavigate';

const useHistory = () => {
  const { language } = useI18n();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  return useMemo(() => {
    const wrappedPush = (path, state) => {
      const nextPath = addSlashToPathEnd(path);

      if (nextPath !== pathname + search) {
        navigate(nextPath, { state });
      }
    };

    const wrappedReplace = (path, state) =>
      navigate(addSlashToPathEnd(path), {
        state,
        replace: true
      });

    return {
      push: wrappedPush,
      replace: wrappedReplace,
      goBack: () => navigate(-1)
    };
  }, [language, pathname, search, navigate]);
};

export default useHistory;
