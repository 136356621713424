import { post } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

export const getTeaserEvents = ({ lang, events = [] }) => {
  const request = {
    lang,
    events: events.map(
      ({
        eventId,
        marketId,
        marketTemplateId,
        resultTypeId,
        availableInLive
      }) => ({
        event_id: eventId,
        market_id: marketId,
        market_template_id: marketTemplateId,
        result_type_id: resultTypeId,
        is_available_in_live: availableInLive
      })
    )
  };

  return post('/frontend_api/teaser_events/', request).then(camelizeKeys);
};
