import { memo } from 'react';
import { Box, AText } from '@alf/uikit';
import BracketItem from '../BracketItem';
import styles from './index.module.css';

const MatchUp = ({
  teams = [],
  groupId,
  orientation = 'horizontal',
  isResults,
  classNames = {}
}) => {
  const { wrapperClassName = '', itemClassName = '' } = classNames;
  return (
    <Box
      align="center"
      justify="center"
      className={`${styles[orientation]} ${wrapperClassName}`}
    >
      {teams.map(({ id, validPrediction }) => (
        <BracketItem
          key={`${id}-bracket-item-${groupId}`}
          country={id}
          validPrediction={validPrediction}
          isResults={isResults}
          className={`${styles.item} ${itemClassName}`}
        />
      ))}
      {Boolean(groupId) && (
        <AText breed="BU" color="txt_color_4" className={styles.title}>
          {groupId}
        </AText>
      )}
    </Box>
  );
};

export default memo(MatchUp);
