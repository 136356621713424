import { useMemo } from 'react';
import clsx from 'clsx';
import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { PROMO_TIMER_TYPES, PROMO_TYPES } from '../../../../constants';
import Timer from '../Timer';
import { usePromotionsStore } from '../../../../store';
import styles from '../../index.module.css';

const promoTypePromoDatesTranslationKey = {
  [PROMO_TYPES.promotion]: 'web.promotions.dates',
  [PROMO_TYPES.tournament]: 'web.tournaments.dates'
};

const CommonDateTime = ({
  promoData = {},
  showTimerInsteadPeriod,
  isRecentlyPromo = false,
  isPromoItem = false,
  isPromoPage = false,
  dateTextColor,
  dateTextSize,
  className,
  activePromoType = PROMO_TYPES.promotion
}) => {
  const isCompleted = usePromotionsStore('isCompleted');
  const { periodStart, periodEnd } = promoData;
  const currentDate = new Date();
  const periodStartDate = new Date(periodStart);
  const periodEndDate = new Date(periodEnd);
  const { t, f } = useI18n();
  const DATE_FORMATS_TYPES = f.getFormats();
  const startDate = f
    .getDateTime({ date: periodStart })
    .toFormat(DATE_FORMATS_TYPES.PROMOTIONS_START_DATE);
  const endDate = f
    .getDateTime({ date: periodEnd })
    .toFormat(DATE_FORMATS_TYPES.PROMOTIONS_END_DATE);

  const timerType = useMemo(() => {
    if (periodStartDate <= currentDate && currentDate < periodEndDate) {
      return PROMO_TIMER_TYPES.END;
    }
    if (currentDate < periodStartDate) {
      return PROMO_TIMER_TYPES.BEGIN;
    }
    if (periodEndDate <= currentDate) {
      return PROMO_TIMER_TYPES.FINISHED;
    }
    return null;
  }, [currentDate, periodEndDate, periodStartDate, isCompleted]); // isCompleted update timer type

  const dateWrapperClassName = useMemo(() => {
    if (showTimerInsteadPeriod) {
      return styles.dateWrapperTimer;
    }
    if (isRecentlyPromo) {
      return styles.dateWrapperRecentlyPromo;
    }

    return styles.dateWrapper;
  }, [showTimerInsteadPeriod, isRecentlyPromo]);

  return timerType === PROMO_TIMER_TYPES.FINISHED && !isRecentlyPromo ? (
    <Box
      data-testid="common-date-time"
      className={clsx([styles.finished, className])}
      align="center"
    >
      <AText breed="BU" size="2xl" color="txt_color_7">
        {t('web.promo.timer.finished')}
      </AText>
    </Box>
  ) : (
    <Box
      data-testid="common-date-time"
      direction="column"
      align="center"
      className={clsx([dateWrapperClassName, className])}
    >
      {showTimerInsteadPeriod && !isRecentlyPromo ? (
        <Timer
          periodStart={periodStart}
          periodEnd={periodEnd}
          timerType={timerType}
          dateTextColor={dateTextColor}
          isPromoItem={isPromoItem}
          isPromoPage={isPromoPage}
        />
      ) : (
        <Box direction="column" align="center">
          {!isRecentlyPromo && (
            <AText
              data-role="promotions-date-text"
              breed="B"
              size="xs"
              color={dateTextColor}
            >
              {t(promoTypePromoDatesTranslationKey[activePromoType])}
            </AText>
          )}
          <AText
            data-role="promotions-date"
            size={dateTextSize}
            color={dateTextColor}
            breed="B"
          >
            {`${startDate} - ${endDate}`}
          </AText>
        </Box>
      )}
    </Box>
  );
};

export default CommonDateTime;
