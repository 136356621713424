import { useConfig } from '@namespace/cms';
import { AText } from '@alf/uikit';

const Info = ({ id }) => {
  // TODO get info from api
  const { config } = useConfig(id);

  return (
    <AText color="po_subheader_txt_color_1" size="l">
      {config.type}
    </AText>
  );
};

export default Info;
