import { useContext, useEffect, useState } from 'react';
import { UserContext } from '@namespace/user';
import { getUsedSidList } from '../../api/balance';

export const useGetUsedSIDList = () => {
  const [{ id }] = useContext(UserContext);
  const [sidList, setSidList] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getUsedSidList(id);

        setSidList(response || []);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [id]);

  return sidList;
};
