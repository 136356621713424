import PropTypes from 'prop-types';
import QrCode from 'qrcode.react';

const QRCode = ({ value, size, imageSettings = {} }) => (
  <QrCode
    value={value}
    size={size}
    imageSettings={imageSettings}
    renderAs="svg"
  />
);

QRCode.propTypes = {
  value: PropTypes.string.isRequired,
  size: PropTypes.number,
  // Image in the qrcode
  imageSettings: PropTypes.shape({
    src: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    excavate: PropTypes.bool
  })
};

export default QRCode;
