export const DOCUMENT_STATUSES = {
  NOTENTERED: 'NOTENTERED',
  NOT_VERIFIED: 'NOT_VERIFIED',
  VERIFIED: 'VERIFIED',
  PENDING: 'PENDING',
  ADDITIONAL_INFORMATION: 'ADDITIONAL_INFORMATION',
  ISSUE_WITH_SKKS: 'ISSUE_WITH_SKKS'
};

export const DOCUMENT_TYPES = {
  PASSPORT: 1,
  IDCARD: 3
};

export const DOCUMENT_TYPES_MAP = {
  [DOCUMENT_TYPES.PASSPORT]: 'passport',
  [DOCUMENT_TYPES.IDCARD]: 'idCard'
};

export const MESSAGE_TYPES = {
  INBOX: 'inbox',
  OUTBOX: 'outbox'
};

export const MESSAGE_TYPES_API = {
  0: MESSAGE_TYPES.INBOX,
  1: MESSAGE_TYPES.OUTBOX
};

export const MESSAGE_IMPORTANCE = {
  CRITICAL: 3
};

export const ERROR_TYPES = {
  VERIFY: 'VERIFY',
  CONFIRM: 'CONFIRM'
};

export const PHONE_VERIFICATION_ERRORS = {
  USER_NOT_FOUND: 'user_not_found',
  PHONE_NUMBER_IS_ALREADY_CONFIRMED: 'phone_number_is_already_confirmed',
  DAILY_LIMIT_EXCEEDED: 'daily_limit_exceeded',
  VERIFICATION_TIMEOUT: 'verification_timeout',
  DB_ERROR: 'db_error',
  VERIFICATION_LOCKED: 'verification_locked'
};

export const BLOCK_PHONE_VERIFICATION = [
  PHONE_VERIFICATION_ERRORS.VERIFICATION_LOCKED,
  PHONE_VERIFICATION_ERRORS.DAILY_LIMIT_EXCEEDED
];

export const MENU_ITEM_BONUSES = 'bonuses';
