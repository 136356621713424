import { chunk, reverse } from 'lodash';

const formatBarCode = (barCode) => {
  const code = String(barCode);
  const symbols = code.split('');
  return reverse(
    chunk(reverse(symbols), 4).map((chunks) => reverse(chunks).join(''))
  ).join(' ');
};

export default formatBarCode;
