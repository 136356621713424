import { useGeneralRoutes } from '@namespace/cms';
import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import CyberSportCategories from '../../common/CyberSportCategories';
import { useCyberSportGetPathParams } from '../../hooks/useCyberSportGetPathParams';
import styles from './index.module.css';

const CyberSportCategoriesMenu = () => {
  const { t } = useI18n();
  const { cyberSportTournament } = useGeneralRoutes();
  const {
    category: activeCategory,
    tournament: tournamentId
  } = useCyberSportGetPathParams();
  const { categoryId } = activeCategory || {};

  return (
    <Box direction="column" className={styles.wrapper}>
      <Box padding="0 s" align="center" className={styles.title}>
        <AText breed="BU" size="m" color="sm_txt_color_1">
          {t('sportsBook.sportsMenu.tournaments.title')}
        </AText>
      </Box>
      <CyberSportCategories
        categoryRoute={cyberSportTournament}
        activeTournamentId={tournamentId}
        categoryId={categoryId}
        withIcon={false}
      />
    </Box>
  );
};

export default CyberSportCategoriesMenu;
