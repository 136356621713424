import PropTypes from 'prop-types';
import { COLOR_SCHEME } from '@namespace/themes';
import { SelectTrigger } from '../../../../../Select/components/DesktopSelect/components/SelectTrigger';

export const SelectTriggerCount = ({
  value,
  title: titlePrefix,
  placeholder,
  intentType,
  alwaysShowDropdownIcon = true,
  ...props
}) => {
  const count = value?.length ?? 0;

  let title;
  if (placeholder != null && count === 0) {
    title = placeholder;
  } else {
    title = titlePrefix ? `${titlePrefix} (${count})` : `(${count})`;
  }

  return (
    <SelectTrigger
      {...props}
      alwaysShowDropdownIcon={alwaysShowDropdownIcon}
      value={count}
      titleOption={{ title }}
      intentType={intentType}
    />
  );
};

SelectTriggerCount.propTypes = {
  className: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  placeholder: PropTypes.string,
  intent: PropTypes.oneOf(['error', 'success', 'default']),
  size: PropTypes.oneOf(['xs', 's', 'm']),
  intentType: PropTypes.oneOf([COLOR_SCHEME.PRIMARY, COLOR_SCHEME.SECONDARY])
};
