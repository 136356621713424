import { AText, Box, Popover } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import Item from '../Item';
import styles from './index.module.css';

const Content = ({ items, onClose, classNames, priorityCount }) => {
  const { t } = useI18n();

  return (
    <Box
      data-role="header-dropdown-menu-wrapper"
      direction="column"
      align="start"
      className={styles.moreMenu}
    >
      {items.map(({ title, link, highlightSeparately }, index) => (
        <Item
          key={title}
          title={t(title)}
          link={link}
          index={index + priorityCount}
          highlightSeparately={highlightSeparately}
          classNames={classNames}
          onClick={onClose}
        />
      ))}
    </Box>
  );
};

const Trigger = () => (
  <AText
    data-role="navigation-more"
    breed="BU"
    size="l"
    color="txt_color_6"
    className={styles.trigger}
  >
    ...
  </AText>
);

const MoreDropdown = ({ items, classNames, priorityCount = 0 }) => {
  const { dropdownContentClass = '' } = classNames;

  return (
    <Popover
      classNames={{
        contentClass: `${styles.content} ${dropdownContentClass}`,
        triggerClass: styles.triggerContainer
      }}
      trigger={<Trigger />}
      content={
        <Content
          items={items}
          priorityCount={priorityCount}
          classNames={classNames}
        />
      }
    />
  );
};

export default MoreDropdown;
