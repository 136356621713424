import { get, flatten } from 'lodash';
import {
  PREDICTIONS_STEPS_IDS,
  BRACKET_MATCHES_EMPTY_MOCK
} from '../../../constants';
import {
  formatTeams,
  isValidPredictionGroup,
  isValidPrediction
} from '../teams';

export const getEmptyStep = (stepName) => ({
  bracket:
    BRACKET_MATCHES_EMPTY_MOCK[stepName].map((teams) => ({ teams })) || [],
  teams: {},
  isFilled: false
});

/*
 * Initital get tournament steps, from API
 */

export const getStepsMap = (bracket = {}) =>
  Object.keys(bracket).reduce((acc, stepName) => {
    acc[stepName] = getEmptyStep(stepName);
    return acc;
  }, {});

/*
 * Format each step besides GROUP
 */

export const getStep = (stepName, matches, isSavedPrediction, groupTeams) => ({
  teams: formatTeams(matches, isSavedPrediction, stepName, groupTeams),
  bracket: matches.map((match, index) => ({
    title: `predictions.step.title.${stepName}.${index + 1}`,
    subtitle: `predictions.step.subtitle.${stepName}.${index + 1}`,
    teams: match
  })),
  isFilled: !flatten(matches).filter((teamId) => !teamId).length
});

/*
 * Compare reference prediction and user prediction
 */

export const getParser = (stepName, groupId) => {
  if (stepName === PREDICTIONS_STEPS_IDS.GROUPS) {
    return {
      referencePath: `${stepName}.${groupId}`,
      isValid: isValidPredictionGroup
    };
  }
  if (stepName === PREDICTIONS_STEPS_IDS.TOP_OF_THIRD) {
    return { referencePath: `${stepName}`, isValid: isValidPredictionGroup };
  }

  return { referencePath: `${stepName}`, isValid: isValidPrediction };
};

export const isValidPredictionStep = (prediction, reference, stepName) => {
  const { bracket, teams } = prediction;
  const newTeams = { ...teams };
  for (const group of bracket) {
    for (const teamId of group.teams) {
      if (!teamId) continue;
      const { isValid, referencePath } = getParser(stepName, group.groupId);
      newTeams[teamId] = {
        ...newTeams[teamId],
        validPrediction: isValid(
          newTeams[teamId],
          get(reference, referencePath)
        )
      };
    }
  }

  return {
    ...prediction,
    teams: newTeams
  };
};
