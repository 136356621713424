import { useCallback, useContext, useEffect } from 'react';
import { FILTER_ALL_VALUE, useFilter } from '@namespace/common';
import { DeviceContext } from '@namespace/device';
import { useI18n } from '@namespace/i18n';
import { UserContext, getInboxCounter } from '@namespace/user';
import { isEmpty } from 'lodash';
import { MailBoxContext } from '../store/mailBox/context';
import { MESSAGE_TYPES } from '../constants';
import { useGetMessages } from './useGetMessages';

export const useInboxMessages = ({ skipFetching } = {}) => {
  const { isMobile } = useContext(DeviceContext);
  const { f } = useI18n();
  const DATE_FORMATS_TYPES = f.getFormats();
  const defaultFilter = {
    pageSize: 10,
    activePage: 1,
    startDate: f
      .getDateTime({ isUTC0: true })
      .startOf('day')
      .minus({ days: 1000 })
      .toFormat(DATE_FORMATS_TYPES.API),
    endDate: f
      .getDateTime({ isUTC0: true })
      .plus({ days: 1 })
      .toFormat(DATE_FORMATS_TYPES.API),
    dateOptionId: FILTER_ALL_VALUE
  };

  const { filter, setPage, onFilterChange } = useFilter(defaultFilter, false);
  const [user, userActions] = useContext(UserContext);
  const { id: userId } = user;
  const { SET_MESSAGES_COUNT } = userActions;
  const [state, actions] = useContext(MailBoxContext);
  const {
    SET_SELECTED_INBOX_MESSAGES,
    SET_INBOX_MESSAGES_LIST,
    DELETE_MESSAGES,
    CHECK_UNREAD,
    SET_TOTAL_PAGES
  } = actions;
  const {
    isSelectedAllInboxMessages,
    selectedInboxMessages,
    inboxMessages = [],
    inboxMessagesList
  } = state;
  const { pageSize, currentPage } = filter;

  useGetMessages({ skip: skipFetching });

  const getFormatedMessageDate = (time) =>
    f.getDateTime({
      date: time.split('.')[0],
      format: DATE_FORMATS_TYPES.API_WITH_TIME
    });

  useEffect(() => {
    return () => {
      SET_INBOX_MESSAGES_LIST({ messages: [] });
      SET_TOTAL_PAGES({ totalPages: 1 });
    };
  }, [userId, SET_INBOX_MESSAGES_LIST, SET_TOTAL_PAGES]);

  useEffect(() => {
    let bufList = inboxMessages ?? [];
    const start = 0;
    const stop = pageSize * currentPage;
    const sortByDate = (a, b) =>
      (getFormatedMessageDate(b.dtSesent)?.ts || 0) -
      (getFormatedMessageDate(a.dtSesent)?.ts || 0);

    bufList?.sort(sortByDate);

    if (!isEmpty(filter) && filter.dateOptionId !== FILTER_ALL_VALUE) {
      const startTime = new Date(filter.startDate).getTime();
      const endTime = new Date(filter.endDate).getTime();

      bufList = bufList.filter(
        ({ dtSesent }) =>
          getFormatedMessageDate(dtSesent).toJSDate() >= startTime &&
          getFormatedMessageDate(dtSesent).toJSDate() <= endTime
      );
    }
    SET_INBOX_MESSAGES_LIST({ messages: bufList.slice(start, stop) });
    SET_TOTAL_PAGES({ totalPages: Math.ceil(bufList.length / pageSize) });
  }, [currentPage, f, isMobile, pageSize, filter, inboxMessages]);

  const toggleSelectAll = useCallback(() => {
    const choosedMessages = isSelectedAllInboxMessages
      ? []
      : inboxMessagesList.map(({ id }) => id);

    SET_SELECTED_INBOX_MESSAGES({ messages: choosedMessages });
  }, [isSelectedAllInboxMessages, selectedInboxMessages, inboxMessagesList]);

  const updateMessagesCount = useCallback(async () => {
    const unreadMessages = (await getInboxCounter(userId)) || 0;

    SET_MESSAGES_COUNT(unreadMessages);
  }, []);

  const onDelete = useCallback(async () => {
    await DELETE_MESSAGES(userId, selectedInboxMessages, MESSAGE_TYPES.INBOX);
    await updateMessagesCount();
  }, [userId, selectedInboxMessages]);

  const onSetUnread = useCallback(async () => {
    await CHECK_UNREAD(userId, selectedInboxMessages);
    await updateMessagesCount();
  }, [userId, selectedInboxMessages]);

  return {
    ...state,
    filter,
    onFilterChange,
    setPage,
    toggleSelectAll,
    onDelete,
    onSetUnread
  };
};
