import { useState, useEffect, useCallback } from 'react';
import { useI18n } from '@namespace/i18n';
import { useDebounce } from '@namespace/helpers';
import { searchApi } from './api/search';

export const useSearch = (serviceIds) => {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const [isSearched, setSearched] = useState(false);
  const [searchResults, setResults] = useState(null);
  const { language } = useI18n();

  const setResultsCallback = useCallback((events, isSearchedVal) => {
    setResults(events);
    setSearched(isSearchedVal);
  }, []);

  useEffect(() => {
    if (debouncedQuery.length > 2) {
      searchApi(debouncedQuery, serviceIds, language)
        .then((response) => setResultsCallback(response.events, true))
        .catch(() => setResultsCallback([]));
    } else {
      setResultsCallback([]);
    }
  }, [debouncedQuery, language, serviceIds]);

  return {
    setQuery,
    query,
    searchResults,
    isSearched
  };
};
