import styles from './index.module.css';

export const EventSkelet = () => {
  return (
    <>
      <div
        className={`${styles.skeletInfoBlockTitle} ${styles.shimer} ${styles.shimerMini}`}
      />
      <div className={styles.skeletEventMarketWrapper}>
        <div className={`${styles.skeletEventMarket} ${styles.shimer}`} />
        <div className={`${styles.skeletEventMarket} ${styles.shimer}`} />
        <div className={`${styles.skeletEventMarket} ${styles.shimer}`} />
      </div>
    </>
  );
};
