import {
  createHookStore,
  createUseSelector,
  STORE_NAMES
} from '@namespace/helpers-store';

export const initialState = {
  isAdvFilterOpened: false,
  advFilter: null,
  advFilterSports: [],
  advFilterSportsById: {},
  isAdvFilterSportsLoaded: false,
  advFilterCategoriesById: {},
  advFilterCategoriesBySportId: {},
  advFilterTournamentsById: {},
  advFilterTournamentsByCategoryId: {}
};

export const useAdvFilterStore = createHookStore(
  STORE_NAMES.ADV_FILTER,
  initialState
);

export const useAdvFilterSelector = createUseSelector(useAdvFilterStore);
