import { memo } from 'react';
import { AText, Box } from '@alf/uikit';
import ParticipantFlag from '../ParticipantFlag';
import styles from './index.module.css';
import { VALID_PREDICTION_STATUS } from '../../constants';

const BracketItem = ({
  country,
  validPrediction,
  isResults = false,
  className
}) => {
  const statusClassName = `${styles.status} ${
    isResults
      ? styles[validPrediction || VALID_PREDICTION_STATUS.NOT_PLAYED]
      : ''
  }`;

  if (country) {
    const flagIcon =
      country === 'North Macedonia' ? 'Republic Of Macedonia' : country;

    return (
      <Box className={`${styles.wrapper} ${className}`}>
        <Box align="center" justify="center" className={statusClassName}>
          <ParticipantFlag
            country={flagIcon}
            className={`${styles.item} ${styles.flag}`}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box className={`${styles.wrapper} ${className}`}>
      <AText
        breed="BU"
        color={isResults ? 'txt_color_4' : 'txt_color_7'}
        className={styles.item}
      >
        ?
      </AText>
    </Box>
  );
};

export default memo(BracketItem);
