import { includes, pathOr, pipe } from 'ramda';
import { useConfig } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { useCyberSportGetPathParams } from '../../hooks/useCyberSportGetPathParams';
import BackHeaderCommon from '../../common/BackHeader';
import { useSportsbookStore } from '../../store';

const CyberSportBackHeader = ({ id }) => {
  const {
    config: {
      title,
      forActiveSport = false,
      isShowCategoryName = false,
      showPinIcon = false
    }
  } = useConfig(id);
  const { t } = useI18n();
  const { activeSport, category = {} } = useCyberSportGetPathParams();
  const { sportName } = activeSport;
  const { categoryName, categoryId } = category || {};
  const headerTitle = forActiveSport ? activeSport && sportName : t(title);
  const isPinned = useSportsbookStore(
    pipe(pathOr([], ['favorites', 'categories']), includes(categoryId))
  );

  return (
    <BackHeaderCommon
      title={isShowCategoryName && categoryName ? categoryName : headerTitle}
      // backLink={cyberSport}
      showPinIcon={showPinIcon}
      categoryId={categoryId}
      isPinned={isPinned}
      pinnedType="category"
    />
  );
};

export default CyberSportBackHeader;
