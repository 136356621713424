import { useCallback, useContext, useEffect } from 'react';
import { FILTER_ALL_VALUE, useFilter } from '@namespace/common';
import { DeviceContext } from '@namespace/device';
import { useI18n } from '@namespace/i18n';
import { UserContext } from '@namespace/user';
import { isEmpty } from 'lodash';
import { MailBoxContext } from '../store/mailBox/context';
import { getOutboxMessages } from '../api/mailBox';
import { MESSAGE_TYPES } from '../constants';

export const useOutboxMessages = () => {
  const { isMobile } = useContext(DeviceContext);
  const { f } = useI18n();

  const DATE_FORMATS_TYPES = f.getFormats();
  const defaultFilter = {
    pageSize: 10,
    activePage: 1,
    startDate: f
      .getDateTime({ isUTC0: true })
      .startOf('day')
      .minus({ days: 1000 })
      .toFormat(DATE_FORMATS_TYPES.API),
    endDate: f
      .getDateTime({ isUTC0: true })
      .plus({ days: 1 })
      .toFormat(DATE_FORMATS_TYPES.API),
    dateOptionId: FILTER_ALL_VALUE
  };

  const { filter, setPage, onFilterChange } = useFilter(defaultFilter, false);
  const [user] = useContext(UserContext);
  const { id: userId } = user;

  const [state, actions] = useContext(MailBoxContext);
  const {
    FETCH_MESSAGES,
    SET_SELECTED_OUTBOX_MESSAGES,
    SET_OUTBOX_MESSAGES_LIST,
    DELETE_MESSAGES,
    SET_TOTAL_PAGES
  } = actions;
  const {
    isSelectedAllOutboxMessages,
    selectedOutboxMessages,
    outboxMessages = [],
    outboxMessagesList
  } = state;
  const { pageSize, currentPage } = filter;

  const getFormatedMessageDate = (time) =>
    f.getDateTime({
      date: time.split('.')[0],
      format: DATE_FORMATS_TYPES.API_WITH_TIME
    });

  useEffect(() => {
    if (userId) {
      FETCH_MESSAGES(getOutboxMessages, userId, MESSAGE_TYPES.OUTBOX);
    }

    return () => {
      SET_OUTBOX_MESSAGES_LIST({ messages: [] });
      SET_TOTAL_PAGES({ totalPages: 1 });
    };
  }, [userId]);

  useEffect(() => {
    let bufList = outboxMessages ?? [];
    const start = 0;
    const stop = pageSize * currentPage;

    const sortByDate = (a, b) =>
      (getFormatedMessageDate(b.dtSesent)?.ts || 0) -
      (getFormatedMessageDate(a.dtSesent)?.ts || 0);
    bufList?.sort(sortByDate);

    if (!isEmpty(filter) && filter.dateOptionId !== FILTER_ALL_VALUE) {
      const startTime = new Date(filter.startDate).getTime();
      const endTime = new Date(filter.endDate).getTime();

      bufList = bufList.filter(
        ({ dtSesent }) =>
          getFormatedMessageDate(dtSesent).toJSDate() >= startTime &&
          getFormatedMessageDate(dtSesent).toJSDate() <= endTime
      );
    }
    SET_OUTBOX_MESSAGES_LIST({ messages: bufList.slice(start, stop) });
    SET_TOTAL_PAGES({ totalPages: Math.ceil(bufList.length / pageSize) });
  }, [currentPage, f, isMobile, pageSize, filter, outboxMessages]);

  const toggleSelectAll = useCallback(() => {
    const choosedMessages = isSelectedAllOutboxMessages
      ? []
      : outboxMessagesList.map(({ id }) => id);

    SET_SELECTED_OUTBOX_MESSAGES({ messages: choosedMessages });
  }, [isSelectedAllOutboxMessages, selectedOutboxMessages, outboxMessagesList]);

  const onDelete = useCallback(() => {
    DELETE_MESSAGES(userId, selectedOutboxMessages, MESSAGE_TYPES.OUTBOX);
  }, [userId, selectedOutboxMessages]);

  return {
    ...state,
    filter,
    onFilterChange,
    setPage,
    toggleSelectAll,
    onDelete
  };
};
