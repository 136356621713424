import { isString } from 'lodash';

const formatSum = (type, value) => {
  if (isString(value)) {
    if (value.includes('>')) {
      return { [`sum_${type}_up`]: Number(value.replace(/\D/g, '')) };
    }
    if (value.includes('<')) {
      return { [`sum_${type}_lower`]: Number(value.replace(/\D/g, '')) };
    }
  }
  return { [`sum_${type}_up`]: 0 };
};

export default formatSum;
