import { Box, AText } from '@alf/uikit';
import { isNil } from 'lodash';
import styles from './index.module.css';

const GameName = ({ game = {} }) => {
  const { gameName, pragmaticLiveData } = game;
  const tableOpen = pragmaticLiveData?.tableOpen;

  return (
    <Box className={styles.wrapper}>
      <Box align="center" className={styles.nameAndIndicatorWrapper}>
        {!isNil(tableOpen) && ( // pragmatic live info as of now
          <Box
            className={`${styles.tableOpenIndicator} ${
              tableOpen ? styles.open : styles.closed
            }`}
          />
        )}
        <AText breed="RU" size="m" color="txt_color_4">
          {gameName}
        </AText>
      </Box>
    </Box>
  );
};

export default GameName;
