import { useContext } from 'react';
import { useI18n } from '@namespace/i18n';
import { MultiSelect } from '@alf/uikit';
import { DeviceContext } from '@namespace/device';

export const SportsFilter = ({
  sportsList,
  selectSport,
  selectedSports,
  intentType,
  dataRole
}) => {
  const { t } = useI18n();
  const { isDesktopDevice } = useContext(DeviceContext);

  const options = sportsList.map(({ sportId, sportName }) => ({
    title: sportName,
    value: String(sportId)
  }));

  return (
    <MultiSelect
      dataRole={dataRole}
      options={options}
      isDesktop={isDesktopDevice}
      onChange={selectSport}
      value={selectedSports}
      disabled={!options.length}
      intentType={intentType}
      title={
        !selectedSports.length
          ? t('live.calendar.selectSports')
          : t('live.calendar.selectedSports')
      }
      t={t}
      showSelectAll={true}
    />
  );
};
