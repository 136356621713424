import { FooterMenu as CoreFooterMenu } from '@namespace/layout';
import styles from './index.module.css';

export default ({ id }) => (
  <CoreFooterMenu
    classNames={{
      containerClass: styles.containerClass,
      linkClass: styles.link
    }}
    id={id}
  />
);
