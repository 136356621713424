import { useContext, useRef, useCallback } from 'react';
import { useI18n } from '@namespace/i18n';
import { SiteSettingsContext } from '@namespace/cms';
import { Box } from '@alf/uikit';
import { useUserSegmentsIncludes } from '@namespace/segments';
import { usePriorityNavigation } from './usePriorityNavigation';
import MoreDropdown from './MoreDropdown';
import Item from './Item';
import useGetBackgroundMenuItemsSetting from './hooks/useGetBackgroundMenuItemsSetting';
import styles from './index.module.css';

const emptyObj = {};

const Navigation = ({
  id,
  classNames = emptyObj,
  componentsMap = emptyObj
}) => {
  const [{ components = {}, menuTags = {} }] = useContext(SiteSettingsContext);
  const { config = {} } = components[id];
  const { items = [] } = config;

  const backgroundMenuItems = useGetBackgroundMenuItemsSetting();

  const filterSegments = useUserSegmentsIncludes();

  const filterItemsNav = useCallback(
    (filterItems) => {
      const filtered = filterItems.filter((item) => {
        return filterSegments(item.userSegments);
      });

      return filtered.map((item) => {
        const { link, highlightSeparately } = item;
        let itemLink = link;

        if (highlightSeparately) {
          const search = link.split('?')[1];
          const pathname = search?.length ? `${link}&` : `${link}?`;
          itemLink = `${pathname}highlightSeparately=${highlightSeparately}`;
        }

        return {
          ...item,
          link: itemLink
        };
      });
    },
    [items, backgroundMenuItems, filterSegments]
  );
  const segmentedItems = filterItemsNav(items);
  const segmentedItemsBackground = filterItemsNav(backgroundMenuItems);

  const { wrapperClass = '' } = classNames;
  const { t } = useI18n();
  const outerRef = useRef(null);
  const navRef = useRef(null);
  const moreRef = useRef(null);
  const additionalRef = useRef(null);
  const { priorityCount } = usePriorityNavigation({
    outerRef,
    navRef,
    moreRef,
    additionalRef,
    itemCount: segmentedItems.length
  });

  const mappedItems = (menuItems) =>
    menuItems.map(
      (
        {
          title,
          link,
          tag,
          logoUrl,
          iconName,
          useAnimation,
          useBackground,
          animationDuration,
          highlightSeparately
        },
        i
      ) => {
        const tagColor = menuTags[tag]?.tagBackground;

        return (
          <Item
            componentsMap={componentsMap}
            key={`${title}-${link}`}
            title={t(title)}
            tag={tag ? t(`header.navigation.${tag}`) : ''}
            logoUrl={logoUrl}
            link={link}
            index={i}
            iconName={iconName}
            useAnimation={useAnimation}
            useBackground={useBackground}
            animationDuration={animationDuration}
            highlightSeparately={highlightSeparately}
            tagColor={tagColor}
            {...(tag ? { tagId: tag } : {})}
            className={i >= priorityCount ? styles.hidden : ''}
            classNames={classNames}
          />
        );
      }
    );

  return (
    <Box
      align="center"
      right="s"
      flexGrow={1}
      ref={outerRef}
      className={`${styles.root} ${wrapperClass}`}
    >
      <Box align="center" ref={navRef}>
        {mappedItems(segmentedItems)}
      </Box>
      <Box
        align="end"
        left="s"
        ref={moreRef}
        className={`${styles.dropdownWrapper} ${
          priorityCount < segmentedItems.length ? '' : styles.hidden
        }`}
      >
        <MoreDropdown
          items={segmentedItems.slice(priorityCount)}
          classNames={classNames}
        />
      </Box>
      {/* last elemets */}
      <Box ref={additionalRef} align="center">
        {mappedItems(segmentedItemsBackground)}
      </Box>
    </Box>
  );
};

export default Navigation;
