import { passValidationRules } from '../../validators/constants';
import regExpValidator from '../../validators/regExpValidator';

const passwordValidation = () =>
  regExpValidator(passValidationRules, [
    'accountingMessages.accounting_error_1511',
    6,
    14
  ]);

export default passwordValidation;
