const MARKET_TEMPLATE_IDS_FOR = {
  HANDICAP: 190,
  MATCH_RESULT: 1,
  OVER_UNDER: 50
};

export const VIEWS_MAP = {
  THREE_FIELD: 2,
  TWO_FIELD: 1
};

export const MARKET_TEMPLATE_OUTCOMES_MAP = {
  [MARKET_TEMPLATE_IDS_FOR.HANDICAP]: 3,
  [MARKET_TEMPLATE_IDS_FOR.MATCH_RESULT]: 3,
  [MARKET_TEMPLATE_IDS_FOR.OVER_UNDER]: 3
};
