import { useContext, memo, useMemo, useState } from 'react';
import clsx from 'clsx';
import { DeviceContext, useScreenSize } from '@namespace/device';
import { USER_ROLES, UserContext } from '@namespace/user';
import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { NativeAppContext } from '@namespace/native-app';

import { GameWrapperExternal } from '../GameWrapperExternal';
import { GamesContext, GamesControlsContext } from '../../context';
import { getGamesPerRowByScreenSize } from '../../utils';
import styles from './index.module.css';

const SimpleGamesList = memo(
  ({
    games = [],
    showMoreUrl,
    serviceUrl,
    classNames = {},
    rowsStep = 3,
    rowsPaginationLimit = 9, // redirect to showMoreUrl or show all games after thins rows amount
    gameBackTo
  }) => {
    const { t } = useI18n();
    const { isDesktop } = useContext(DeviceContext);
    const [user] = useContext(UserContext);
    const [{ excludedActiveBonusGamesList }] = useContext(GamesContext);
    const [rowsAmount, setRowsAmount] = useState(rowsStep);
    const rowsClassName = rowsAmount && styles[`rows_${rowsAmount}`];
    const { gameItemClassName = '', gamesListClassName = '' } = classNames;
    const { push } = useHistory();
    const { role } = user;
    const { data: excludedBonusGamesList } = excludedActiveBonusGamesList;
    const screenSize = useScreenSize(true);
    const isLoggedInUser = role === USER_ROLES.USER;
    const [
      {
        isNativeApp,
        nativeAppConfig: { delegateRouting }
      }
    ] = useContext(NativeAppContext);
    // TODO: remove isNativeApp / isMobileAppRedirect logic after new app redirects logic

    const controls = useMemo(
      () => ({
        serviceURL: serviceUrl,
        hasFavoriteButton: false,
        backTo: gameBackTo,
        isMobileAppRedirect: isNativeApp && !delegateRouting,
        isNativeApp
      }),
      [serviceUrl, gameBackTo, isNativeApp]
    );

    const gamesPerRow = getGamesPerRowByScreenSize(screenSize);
    const currentShowGamesAmount = rowsAmount
      ? gamesPerRow * rowsAmount
      : games.length;

    const isReachedPaginationLimit =
      rowsAmount >= rowsPaginationLimit ||
      (rowsAmount < rowsPaginationLimit &&
        games.length - currentShowGamesAmount < gamesPerRow * rowsStep);

    const showMoreTranslationKey = isReachedPaginationLimit
      ? 'web.tournaments.games.showall'
      : 'web.tournaments.games.showmore';

    const handleShowMoreClick = () => {
      if (isReachedPaginationLimit) {
        if (showMoreUrl && !isNativeApp) {
          push(showMoreUrl);
        } else {
          setRowsAmount(undefined); // show all games
        }

        return;
      }

      setRowsAmount((currentRowsAmount) => currentRowsAmount + rowsStep);
    };

    const isShowAllBtnVisible =
      rowsAmount && games.length > gamesPerRow * rowsAmount;

    return (
      <GamesControlsContext.Provider value={controls}>
        <Box
          className={clsx([
            styles.gamesList,
            rowsClassName,
            gamesListClassName
          ])}
        >
          {games.map((game, index) => (
            <GameWrapperExternal
              game={game}
              className={clsx([styles.cardItem, gameItemClassName])}
              index={index}
              isDesktop={isDesktop}
              isLoggedInUser={isLoggedInUser}
              excludedBonusGamesList={excludedBonusGamesList}
              key={
                game.isPlaceholder ? index : `${game.provider}/${game.gameCode}`
              }
            />
          ))}

          {isShowAllBtnVisible && (
            <Box
              className={styles.showMoreBlock}
              style={{ gridRow: rowsAmount }}
              onClick={handleShowMoreClick}
              align="center"
              justify="center"
              {...(isNativeApp && showMoreUrl && isReachedPaginationLimit
                ? { component: 'a', href: showMoreUrl }
                : {})}
            >
              <AText breed="BU" size="l" color="btn_primary_txt_color_3">
                {t(showMoreTranslationKey)}
              </AText>
            </Box>
          )}
        </Box>
      </GamesControlsContext.Provider>
    );
  }
);

export default SimpleGamesList;
