export const types = {
  FETCH_PRODUCT_LIST: 'FETCH_PRODUCT_LIST',
  SET_PRODUCT_LIST: 'SET_PRODUCT_LIST',
  FETCH_CATEGORY_LIST: 'FETCH_CATEGORY_LIST',
  SET_CATEGORY_LIST: 'SET_CATEGORY_LIST',
  FETCH_USER_INFO: 'FETCH_USER_INFO',
  SET_USER_INFO: 'SET_USER_INFO',
  SET_TOTAL_AMOUNT: 'SET_TOTAL_AMOUNT',
  ADD_SHOP_ITEM: 'ADD_SHOP_ITEM',
  REMOVE_ONE_STACK: 'REMOVE_ONE_STACK',
  REMOVE_SHOP_ITEM: 'REMOVE_SHOP_ITEM',
  CLEAR_BASKET: 'CLEAR_BASKET',
  SET_FILTERED_PRODUCTS_LIST: 'SET_FILTERED_PRODUCTS_LIST',
  SET_PRICE_RANGE: 'SET_PRICE_RANGE'
};
