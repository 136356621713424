import { isEmpty } from 'lodash';
import { useMatchPath } from '@namespace/router';
import { useOtherSettings } from '@namespace/cms';
import { useGetLobbyPageUrl } from '../useGetLobbyPageUrl';

export const useGetIsLobbyPage = (casinoType) => {
  const lobbyPageUrl = useGetLobbyPageUrl(casinoType);
  const { pathsAliases = {} } = useOtherSettings();

  const paths = [lobbyPageUrl];
  if (pathsAliases['/'] === lobbyPageUrl) {
    paths.push('/');
  }

  const isLobby = !isEmpty(
    useMatchPath(paths, {
      isReturnFullMatch: true
    })
  );

  return isLobby;
};
