import { memo } from 'react';
import { useI18n } from '@namespace/i18n';
import { Box, AText } from '@alf/uikit';
import { VERTICAL_GROUPS } from '../../constants';
import { useGetStep } from '../../store/selectors';
import getTeams from '../../utils/getTeams';
import MatchUp from '../MatchUp';
import styles from './index.module.css';

const BracketStage = ({ type, isResults, classNames = {} }) => {
  const {
    wrapperClassName = '',
    titleClassName = '',
    bracketClassName = '',
    matchUpClassName = '',
    participantClassName = ''
  } = classNames;
  const { t } = useI18n();
  const { bracket = [], teams = {} } = useGetStep(type);

  return (
    <Box direction="column" className={wrapperClassName}>
      <AText
        breed="BU"
        color="txt_color_4"
        className={`${styles.title} ${titleClassName}`}
      >
        {t(`predictions.stage.title.${type}`)}
      </AText>
      <Box
        align="center"
        justify="center"
        className={`${styles.bracket} ${bracketClassName}`}
      >
        {bracket.map(({ groupId, teams: bracketTeams }) => (
          <MatchUp
            key={`matchUp-${groupId}-${bracketTeams}`}
            groupId={groupId}
            teams={getTeams(bracketTeams, teams, type)}
            orientation={
              VERTICAL_GROUPS.includes(type) ? 'vertical' : 'horizontal'
            }
            isResults={isResults}
            classNames={{
              wrapperClassName: matchUpClassName,
              itemClassName: participantClassName
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default memo(BracketStage);
