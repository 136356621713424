import { useContext, useEffect, useMemo } from 'react';
import { LOCAL_STORAGE_KEYS, setLocalStorageValue } from '@namespace/helpers';
import { PredictionsContext } from '../../store/context';
import { useGetActiveStep, useGetStep } from '../../store/selectors';
import { getStep } from '../../store/normalizer/steps';
import formatRound16Bracket from '../../utils/formatRound16Bracket';
import formatWinnerBrackets from '../../utils/formatWinnerBrackets';
import formatRound16BracketFromThirdTeams from '../../utils/formatRound16BracketFromThirdTeams';
import formatTopOfThirdBracket from '../../utils/formatTopOfThirdBracket';
import {
  PREDICTIONS_STATUS,
  PREDICTIONS_STEPS_IDS,
  PREDICTIONS_STEPS_SELECTED_QUANTITY,
  NEXT_STEPS_IDS
} from '../../constants';

const { GROUPS, TOP_OF_THIRD, ROUND_OF_16 } = PREDICTIONS_STEPS_IDS;
const TOP_OF_THIRD_QUANTITY = PREDICTIONS_STEPS_SELECTED_QUANTITY[TOP_OF_THIRD];

const useSetTeamsToFutureBracket = () => {
  const [predState, predActions] = useContext(PredictionsContext);
  const {
    activeStep,
    isReferenceLoaded,
    editingInProgress,
    disabledSteps,
    steps,
    isAllowEditing,
    localStatus = PREDICTIONS_STATUS.DEFAULT,
    isPredictionsLoaded
  } = predState;
  const { SET_STEPS } = predActions;

  const { teams, bracket } = useGetActiveStep();
  const { bracket: roundOf16Bracket } = useGetStep(ROUND_OF_16);
  const { isFilled: nextIsFilled } = useGetStep(NEXT_STEPS_IDS[activeStep]);

  const teamsArray = useMemo(() => Object.values(teams || []), [teams]);

  useEffect(() => {
    const newSteps = {};
    if (isReferenceLoaded && !nextIsFilled && NEXT_STEPS_IDS[activeStep]) {
      if (activeStep === GROUPS) {
        const topOfThirdNewBracket = formatTopOfThirdBracket(teamsArray);
        newSteps[TOP_OF_THIRD] = getStep(
          TOP_OF_THIRD,
          [topOfThirdNewBracket],
          false,
          teams
        );

        const roundOf16BracketNewBracket = formatRound16Bracket(teamsArray);
        newSteps[ROUND_OF_16] = getStep(
          ROUND_OF_16,
          roundOf16BracketNewBracket
        );
      } else if (activeStep === TOP_OF_THIRD) {
        const teamsWithIndex = teamsArray.filter((team) => team.index);
        const roundOf16BracketNewBracket = formatRound16BracketFromThirdTeams(
          roundOf16Bracket,
          teamsWithIndex,
          teamsWithIndex.length === TOP_OF_THIRD_QUANTITY
        );
        newSteps[ROUND_OF_16] = getStep(
          ROUND_OF_16,
          roundOf16BracketNewBracket,
          false,
          steps[GROUPS].teams
        );
      } else {
        const winnerBracket = formatWinnerBrackets(bracket, teams);

        newSteps[NEXT_STEPS_IDS[activeStep]] = getStep(
          NEXT_STEPS_IDS[activeStep],
          winnerBracket
        );
      }
      SET_STEPS(newSteps);
    }

    if (
      !editingInProgress &&
      isReferenceLoaded &&
      !nextIsFilled &&
      !isPredictionsLoaded
    ) {
      setLocalStorageValue(LOCAL_STORAGE_KEYS.PREDICTIONS, {
        activeStep,
        steps: { ...steps, ...newSteps },
        disabledSteps,
        isAllowEditing,
        localStatus
      });
    }
  }, [teamsArray, bracket, isReferenceLoaded]);
};

export default useSetTeamsToFutureBracket;
