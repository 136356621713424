export const MARKET_TEMPLATE_IDS_LIST = [
  50,
  190,
  201,
  202,
  310,
  320,
  560,
  690,
  710,
  730,
  740,
  770,
  772,
  773,
  774,
  780,
  781,
  806,
  807,
  809,
  812,
  855,
  857,
  858,
  859,
  867,
  899,
  914,
  915,
  917,
  922,
  923,
  924,
  925,
  954,
  973,
  974,
  975,
  978,
  982,
  1052,
  1071,
  1104,
  1109,
  1137,
  1138,
  1139,
  1140,
  1141,
  1142,
  1151,
  1154,
  1155,
  1156,
  1157,
  1158,
  1159,
  1182,
  1183,
  1184,
  1185,
  1186,
  1190,
  1200,
  1201,
  1203,
  1206,
  1211,
  1212,
  1214,
  1221,
  1224,
  1226,
  1227,
  1241,
  1247,
  1248,
  1249,
  1257,
  1278,
  1326,
  1327,
  1328,
  1329,
  1330,
  1331,
  1332,
  1333,
  1334,
  1335,
  1336,
  1339,
  1340,
  1341,
  1351,
  1352,
  1353,
  1354,
  1355,
  1360,
  1365,
  1366,
  1425,
  1436,
  1438,
  1454,
  1455,
  1456,
  1463,
  1464,
  1466,
  1484,
  1485,
  1514,
  1543,
  1544,
  1558,
  1712,
  1742,
  1744,
  1745,
  1782,
  1785,
  1786,
  1787,
  1800,
  1812
];
