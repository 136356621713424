import { useContext } from 'react';
import { useI18n } from '@namespace/i18n';
import { UserContext } from '@namespace/user/src/context';
import { AText, Box } from '@alf/uikit';

import VerificationStatusNotification from '../VerificationStatusNotification';

const PassportVerificationToast = ({ onClose }) => {
  const { t } = useI18n();
  const [user] = useContext(UserContext);
  const { email } = user;
  const messageText = t('pendingPassportUser.notification', [email]);
  const mobileMessage = (
    <Box align="center">
      <AText color="txt_color_3" size="l" align="center">
        {messageText}
      </AText>
    </Box>
  );

  return (
    <VerificationStatusNotification
      onClose={onClose}
      message={messageText}
      mobileMessage={mobileMessage}
    />
  );
};

export default PassportVerificationToast;
