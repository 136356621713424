import {
  SiteSettingsContext,
  useCookieManagementEnabled
} from '@namespace/cms';
import { CookieManagementContext } from '@namespace/cookie-management';
import {
  COOKIES_KEYS,
  COOKIE_MANAGEMENT_TYPES,
  LOCAL_STORAGE_KEYS,
  getCookie,
  getLocalStorageValue,
  removeLocalStorageItem,
  setCookie,
  setLocalStorageValue,
  window
} from '@namespace/helpers';
import { useLocation } from '@namespace/i18n';
import { UserContext } from '@namespace/user';
import { isEmpty } from 'lodash';
import { useContext, useEffect } from 'react';

const useAffiliateLink = () => {
  const [settings] = useContext(SiteSettingsContext);
  const { siteConfig = {}, otherSettings = {} } = settings || {};
  const { cookieManagement = {} } = otherSettings;
  const [{ cookieManagementType }] = useContext(CookieManagementContext);
  const cookieManagementEnabled = useCookieManagementEnabled();
  const { disableSendingAffiliateCookies = false } = cookieManagement;
  const {
    affiliateLink: parametersList,
    affiliateCookieLifeTime = 365
  } = siteConfig;
  const { search } = useLocation();

  const [, { SET_USER_B_TAG }] = useContext(UserContext);
  const IS_ANALYTICAL = cookieManagementType?.includes(
    COOKIE_MANAGEMENT_TYPES.ANALYTICAL
  );

  // ?type_r=ia
  // &b_tag=a_2997b_1858c_brand_AffiliateId=141
  // &link=https://www.favbet.com/ru/euro-predictions/
  useEffect(() => {
    const bTag =
      getCookie(COOKIES_KEYS.B_TAG, '') ||
      getLocalStorageValue(LOCAL_STORAGE_KEYS.AFFILIATE_COOKIES)?.b_tag;
    SET_USER_B_TAG(bTag);

    if (search && parametersList && parametersList.length) {
      const query = new URLSearchParams(search);
      const link = query.get('link');

      const parsedParametersValuesList = parametersList.reduce(
        (acc, { parameter: item, cookieName }) => {
          const linkAffiliateParameter = query.get(item);
          if (linkAffiliateParameter) {
            query.delete(item);
            return [
              ...acc,
              {
                key: cookieName || item,
                value: linkAffiliateParameter
              }
            ];
          }
          return acc;
        },
        []
      );

      if (parsedParametersValuesList.length) {
        const localStorageValues = {};
        for (const { key, value } of parsedParametersValuesList) {
          if (key === COOKIES_KEYS.B_TAG) {
            const affiliateTimestamp = getCookie(
              COOKIES_KEYS.AFFILIATE_TIMESTAMP,
              ''
            );

            if (bTag !== value || !affiliateTimestamp) {
              if (
                !cookieManagementEnabled ||
                (IS_ANALYTICAL && disableSendingAffiliateCookies)
              ) {
                setCookie(
                  COOKIES_KEYS.AFFILIATE_TIMESTAMP,
                  new Date().toISOString(),
                  { expires: affiliateCookieLifeTime }
                );
                SET_USER_B_TAG(value);
              } else {
                localStorageValues[
                  COOKIES_KEYS.AFFILIATE_TIMESTAMP
                ] = new Date().toISOString();
              }
            }
          }
          if (
            !cookieManagementEnabled ||
            (IS_ANALYTICAL && disableSendingAffiliateCookies)
          ) {
            setCookie(key, value, { expires: affiliateCookieLifeTime });
          } else {
            localStorageValues[key] = value;
          }
          if (!isEmpty(localStorageValues)) {
            setLocalStorageValue(
              LOCAL_STORAGE_KEYS.AFFILIATE_COOKIES,
              localStorageValues
            );
          }
        }

        if (link) {
          window.location.replace(link);
        }
      }
    }
  }, [
    parametersList,
    cookieManagementEnabled,
    IS_ANALYTICAL,
    disableSendingAffiliateCookies
  ]);

  useEffect(() => {
    if (
      cookieManagementEnabled &&
      cookieManagementType &&
      disableSendingAffiliateCookies
    ) {
      const affiliateInLocalStorage = getLocalStorageValue(
        LOCAL_STORAGE_KEYS.AFFILIATE_COOKIES
      );

      if (IS_ANALYTICAL && !isEmpty(affiliateInLocalStorage)) {
        for (const [key, value] of Object.entries(affiliateInLocalStorage)) {
          if (key === COOKIES_KEYS.B_TAG) {
            SET_USER_B_TAG(value);
          }
          setCookie(key, value, { expires: affiliateCookieLifeTime });
        }
      }

      if (affiliateInLocalStorage) {
        removeLocalStorageItem(LOCAL_STORAGE_KEYS.AFFILIATE_COOKIES);
      }
    }
  }, [
    IS_ANALYTICAL,
    cookieManagementType,
    cookieManagementEnabled,
    disableSendingAffiliateCookies
  ]);

  return null;
};

export default useAffiliateLink;
