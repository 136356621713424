import { useCallback, useEffect, useState, useContext } from 'react';
import {
  confirmDocuments,
  getVerificationOptions,
  getDocVerificationFormUrl,
  useVerifyDocuments,
  UserContext
} from '@namespace/user';
import { useI18n } from '@namespace/i18n';
import { useNotifications } from '@namespace/notifications';
import { useGeneralRoutes, useCashdeskId } from '@namespace/cms';
import { window, asyncAttempt } from '@namespace/helpers';

import {
  parseOptionsResponse as parseOptionsResponseUtil,
  getJumioModeMethods,
  hasJumioMethod
} from './utils';

const KYCAID_SERVICE_ID = 2;
const BANK_ID_DOC_TYPE_ID = '-1';
const DIIA_DOC_TYPE_ID = '-2';

export * from './constants';

export const useConfirmDocuments = ({
  shouldFetchOptions,
  parseOptionsResponse = parseOptionsResponseUtil
} = {}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [options, setOptions] = useState([]);

  const { errorNotification } = useNotifications();

  const cashdeskId = useCashdeskId();
  const { t, language } = useI18n();
  const { document } = useVerifyDocuments();
  const {
    documentNumber,
    documentTypeId,
    citizenship,
    documentCountry
  } = document;
  const {
    verificationDocumentsSuccess,
    verificationDocumentsFailure
  } = useGeneralRoutes();

  const [user] = useContext(UserContext);
  const { id: userId, partnerId } = user;

  const handleError = useCallback(() => {
    setIsFetching(false);
  }, []);

  const fetchOptions = useCallback(
    async (code) => {
      const [error, result] = await asyncAttempt(getVerificationOptions, {
        countryCode: code
      });

      if (error) {
        errorNotification(error.message);
        setOptions([]);

        return;
      }

      if (result.status === 'error') {
        setOptions([]);
        errorNotification(result.error.message ?? 'Something went wrong');

        return;
      }

      setOptions(result.response || []);
    },
    [errorNotification]
  );

  useEffect(() => {
    if (shouldFetchOptions && citizenship) {
      fetchOptions(citizenship);
    }
  }, [shouldFetchOptions, fetchOptions, citizenship]);

  const fetchJumioFormURL = useCallback(async () => {
    setIsFetching(true);

    const [error, result] = await asyncAttempt(confirmDocuments, {
      citizenship,
      documentTypeId,
      documentNumber,
      language
    });

    if (error) {
      errorNotification(t(`accountingMessages.${error.errorCode}`));
      handleError(error);
      return '';
    }

    setIsFetching(false);

    return result.apiRedirectUrl;
  }, [
    handleError,
    language,
    documentNumber,
    documentTypeId,
    errorNotification,
    t,
    citizenship
  ]);

  const fetchFormUrl = useCallback(async () => {
    setIsFetching(true);

    const { origin } = window.location;
    const [err, result] = await asyncAttempt(getDocVerificationFormUrl, {
      userId,
      partnerId,
      cashdeskId,
      language,
      documentNumber,
      documentTypeId,
      country: documentCountry,
      serviceId: KYCAID_SERVICE_ID,
      email: user.email,
      urlSuccess: `${origin}${verificationDocumentsSuccess}`,
      urlFailure: `${origin}${verificationDocumentsFailure}`
    });

    setIsFetching(false);

    if (err) {
      errorNotification('Something went wrong');
      return '';
    }

    const { status, response, error } = result;

    if (status === 'ok') {
      return response.redirect_link;
    }

    const errorMessage = error?.message ?? response?.message;

    if (errorMessage) {
      errorNotification(errorMessage);
    }

    return '';
  }, [
    cashdeskId,
    documentNumber,
    documentTypeId,
    documentCountry,
    errorNotification,
    language,
    partnerId,
    user.email,
    userId,
    verificationDocumentsFailure,
    verificationDocumentsSuccess
  ]);

  const makeFormURLFetcher = useCallback(
    async (opts = {}) => {
      setIsFetching(true);

      const { origin } = window.location;
      const [err, result] = await asyncAttempt(getDocVerificationFormUrl, {
        userId,
        partnerId,
        cashdeskId,
        language,
        documentTypeId: opts.type,
        serviceId: KYCAID_SERVICE_ID,
        email: user.email,
        urlSuccess: `${origin}${verificationDocumentsSuccess}`,
        urlFailure: `${origin}${verificationDocumentsFailure}`
      });

      setIsFetching(false);

      if (err) {
        errorNotification('Something went wrong');
        return '';
      }
      const { status, response, error } = result;

      if (status === 'ok') {
        return response.redirect_link;
      }

      const errorMessage = error?.message ?? response?.message;

      if (errorMessage) {
        errorNotification(errorMessage);
      }

      return '';
    },
    [
      cashdeskId,
      errorNotification,
      language,
      partnerId,
      user.email,
      userId,
      verificationDocumentsFailure,
      verificationDocumentsSuccess
    ]
  );

  const fetchBankIDFormUrl = useCallback(
    () => makeFormURLFetcher({ type: BANK_ID_DOC_TYPE_ID }),
    [makeFormURLFetcher]
  );

  const fetchDiiaFormUrl = useCallback(
    () => makeFormURLFetcher({ type: DIIA_DOC_TYPE_ID }),
    [makeFormURLFetcher]
  );

  // TEMPORARY solution for smooth toggling from Jumio to KYCAID
  const isJumioModeActive = hasJumioMethod(options);

  if (shouldFetchOptions && isJumioModeActive) {
    return {
      isFetching,
      fetchFormUrl: fetchJumioFormURL,
      verificationOption: getJumioModeMethods(documentTypeId, citizenship)
    };
  }

  return {
    isFetching,
    fetchFormUrl,
    fetchBankIDFormUrl,
    fetchDiiaFormUrl,
    getUrl: fetchJumioFormURL,
    verificationOption: parseOptionsResponse(options, documentTypeId)
  };
};
