import Tennis from './Sports/components/Tennis';
import Basketball from './Sports/components/Basketball';
import Volleyball from './Sports/components/Volleyball';
import Baseball from './Sports/components/Baseball';
import ShortFootball from './Sports/components/ShortFootball';
import { SPORT_IDS } from '../../../../constants';
import { useEventField } from '../../../../store';

const AdditionalStatistics = ({ eventId }) => {
  const sportId = useEventField(eventId, 'sportId');

  switch (sportId) {
    case SPORT_IDS.TENNIS:
      return <Tennis eventId={eventId} />;

    case SPORT_IDS.BASKETBALL:
      return <Basketball eventId={eventId} />;

    case SPORT_IDS.VOLLEYBALL:
      return <Volleyball eventId={eventId} />;

    case SPORT_IDS.BASEBALL:
      return <Baseball eventId={eventId} />;

    case SPORT_IDS.SHORT_FOOTBALL:
      return <ShortFootball eventId={eventId} />;

    default:
      return null;
  }
};

export default AdditionalStatistics;
