import { useContext, useEffect } from 'react';
import { SiteSettingsContext } from '@namespace/cms';
import { DeviceContext } from '@namespace/device';
import { useGetUserSegments } from '@namespace/segments';
import { useUserInfoLoaded } from '@namespace/user';
import { GamesContext } from '../../context';
import { useGamesIsLoaded, useGamesFeed } from '../../context/selectors';
import { useGetPlatformForFeed } from '../useGetPlatformForFeed';

export const useLoadCasinoFeed = ({ feedNames = '' }) => {
  const [, { SET_IS_FEED_LOADING, GET_GAMES }] = useContext(GamesContext);
  const { isDesktop } = useContext(DeviceContext);
  const { isLoading: isFeedLoading, isLoaded: isFeedLoaded } =
    useGamesFeed(feedNames) || {};
  const platform = useGetPlatformForFeed();
  const isGamesLoaded = useGamesIsLoaded(feedNames, platform);
  const [settings] = useContext(SiteSettingsContext);
  const { casino = {} } = settings;
  const { disabledProviders = [] } = casino;
  const { userSegmentsNames: userSegments } = useGetUserSegments();
  const { userInfoLoaded } = useUserInfoLoaded();

  useEffect(() => {
    if (!isGamesLoaded && feedNames && !isFeedLoading && userInfoLoaded) {
      SET_IS_FEED_LOADING(true, feedNames);
      GET_GAMES({
        disabledProviders,
        feedNames,
        isDesktop,
        platform,
        userSegments
      });
    }
  }, [
    isGamesLoaded,
    disabledProviders.join('/'),
    feedNames,
    isDesktop,
    isFeedLoading,
    userSegments,
    userInfoLoaded
  ]);

  return { isFeedLoading, isFeedLoaded };
};
