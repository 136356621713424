import { Box, DatePicker } from '@alf/uikit';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { IS_WIDGET } from '@namespace/widget/src/constants';
import { useCallback } from 'react';
import FormFieldError from '../FormFieldError';
import styles from './index.module.css';

const DatePickerFormWrapper = ({
  name,
  control,
  rules,
  error,
  hint,
  withTooltip,
  onChange,
  onClick = () => {},
  date,
  datePickerWrapperClassName = styles.datePickerWrapperClassName,
  datePickerContainerClassName = '',
  dataRole,
  ...datePickerProps
}) => {
  const handleChange = useCallback(
    (fieldOnChange) => (v) => {
      fieldOnChange(onChange(v));
    },
    [onChange]
  );

  return (
    <Box
      direction="column"
      className={datePickerContainerClassName}
      onClick={onClick}
    >
      <Controller
        render={({ field: { ref, ...field } }) => {
          const { onChange: controllerOnChange, ...controllerFields } = field;
          return (
            <DatePicker
              {...datePickerProps}
              variant={IS_WIDGET ? 'inline' : 'dialog'}
              className={datePickerWrapperClassName}
              error={Boolean(error)}
              onChange={handleChange(controllerOnChange)}
              date={date}
              dataRole={dataRole}
              {...controllerFields}
            />
          );
        }}
        rules={rules}
        control={control}
        name={name}
      />
      {!error && hint && <span className={styles.inputHint}>{hint}</span>}
      {error && (
        <FormFieldError
          withTooltip={withTooltip}
          className={
            withTooltip ? styles.inputTooltipError : styles.inputHintError
          }
        >
          {error}
        </FormFieldError>
      )}
    </Box>
  );
};

DatePickerFormWrapper.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hint: PropTypes.string,
  withTooltip: PropTypes.bool,
  onChange: PropTypes.func,
  date: PropTypes.instanceOf(Date)
};

export default DatePickerFormWrapper;
