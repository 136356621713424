import { Box } from '@alf/uikit';
import OutcomeButtonContainer from '../../../../../../../../common/OutcomeButtonContainer';
import EmptyMarket from '../../../../../../../../common/EventMarketContainer/EmptyMarket';
import { MATCH_RESULT_MARKET_TEMPLATE_ID } from '../../../../../../../../constants';
import styles from './index.module.css';
import MarketOutcomeContainer from '../../../../../../../../common/EventMarketContainer/MarketOutcomeContainer';
import { useEventField } from '../../../../../../../../store';

export const Outcomes = ({ eventId, lineView, dataRole }) => {
  const outcomes = useEventField(eventId, 'outcomes');
  return (
    <Box
      onClick={(e) => e.stopPropagation()}
      className={`${styles.marketContainer} ${styles[lineView] || ''}`}
    >
      {outcomes?.length > 0 ? (
        <Box className={styles.outcomes}>
          {outcomes.map(({ outcomeId, serviceId, marketId }) => (
            <MarketOutcomeContainer
              dataRole={`${dataRole}-${outcomeId}`}
              key={outcomeId}
              outcomeId={outcomeId}
              lineView={lineView}
              classNames={{
                outcomeContainerClassName: `${
                  styles.outcomeContainerClassName
                } ${styles[lineView] || ''}`
              }}
              value={
                <OutcomeButtonContainer
                  eventId={eventId}
                  outcomeId={outcomeId}
                  market={{ serviceId, marketId, eventId }}
                  lineView={lineView}
                />
              }
            />
          ))}
        </Box>
      ) : (
        <EmptyMarket
          marketTemplateId={MATCH_RESULT_MARKET_TEMPLATE_ID}
          lineView={lineView}
        />
      )}
    </Box>
  );
};
