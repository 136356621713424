import { COLOR_SCHEME_DARK } from '@namespace/helpers';
import useColorScheme from '../useColorScheme';

const useGetImage = () => {
  const {
    colorScheme: { selectedColorMode }
  } = useColorScheme();

  const isDark = selectedColorMode === COLOR_SCHEME_DARK;

  const getImgUrl = (light, dark) => (isDark && dark ? dark : light);

  return getImgUrl;
};

export default useGetImage;
