import { useCallback, useContext, useEffect, useState } from 'react';
import { Box, MultiSelect, Select } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { DeviceContext } from '@namespace/device';
import { useAdvFilterInnerCtxStore } from '../../store';
import { formatSportId } from '../../utils';
import { SportInfoMessage } from '../SportInfoMessage';
import { useAdvFilterSettings } from '../../hooks/useAdvFilterSettings';
import { useSportsSelectData } from './hooks/useSportsSelectorData';
import { useCategoriesSelectData } from './hooks/useCategoriesSelectorData';
import { useTournamentsSelectorData } from './hooks/useTournamentsSelectorData';
import styles from './index.module.css';

export const SportItemsSelector = ({ className }) => {
  const { t } = useI18n();
  const selectedSportId = useAdvFilterInnerCtxStore('selectedSportId');
  const { maxCategoriesCount, maxTournamentsCount } = useAdvFilterSettings();
  const { isDesktop } = useContext(DeviceContext);
  const [openedControls, setOpenedControls] = useState(0);

  const { sportOptions, onSportChange } = useSportsSelectData();

  const {
    categoriesOptions,
    selectedCategoryIds,
    onCategoryChange
  } = useCategoriesSelectData();

  const {
    tournamentsOptions,
    indexedCategories,
    selectedTournamentIds,
    onTournamentChange
  } = useTournamentsSelectorData({ categoriesOptions, selectedCategoryIds });

  // close multi-select when options are empty
  useEffect(() => {
    if (
      (categoriesOptions.length === 0 && openedControls === 2) ||
      (tournamentsOptions.length === 0 && openedControls === 3)
    ) {
      setOpenedControls(0);
    }
  }, [categoriesOptions.length, openedControls, tournamentsOptions.length]);

  const setIsOpenedSports = useCallback((opened) => {
    setOpenedControls(opened ? 1 : 0);
  }, []);

  const setIsOpenedCategories = useCallback((opened) => {
    setOpenedControls(opened ? 2 : 0);
  }, []);

  const setIsOpenedTournaments = useCallback((opened) => {
    setOpenedControls(opened ? 3 : 0);
  }, []);

  const categoryTitleAndPlaceholder = t(
    'sportsBook.sportsMenu.advFilter.category',
    [maxCategoriesCount]
  );
  const tournamentsTitleAndPlaceholder = t(
    'sportsBook.sportsMenu.advFilter.tournaments',
    [maxTournamentsCount]
  );

  return (
    <Box direction="column" gap={isDesktop ? 's' : 'm'} className={className}>
      <Box direction="column" gap="s">
        <SportInfoMessage />
        <Select
          options={sportOptions}
          isDesktop={true}
          value={formatSportId(selectedSportId)}
          onChange={onSportChange}
          size="s"
          placeholder={t('sportsBook.sportsMenu.advFilter.sport')}
          noPlaceholderColor={true}
          additionSelectOptionStyle={{
            item: styles.selectRow
          }}
          additionSelectStyles={{
            triggerContentClass: styles.selectRow
          }}
          isExpandable={true}
          haveToCloseOutsideClick={false}
          isOpenExternal={openedControls === 1}
          setIsOpenExternal={setIsOpenedSports}
        />
      </Box>
      <MultiSelect
        t={t}
        options={categoriesOptions}
        isDesktop={true}
        value={selectedCategoryIds}
        onChange={onCategoryChange}
        size="s"
        hasSearch={true}
        isInlineSearch={false}
        disabled={categoriesOptions.length === 0}
        title={categoryTitleAndPlaceholder}
        placeholder={categoryTitleAndPlaceholder}
        isExpandable={true}
        haveToCloseOutsideClick={false}
        isOpenExternal={openedControls === 2}
        setIsOpenExternal={setIsOpenedCategories}
      />
      <MultiSelect
        t={t}
        options={tournamentsOptions}
        groupBy="categoryId"
        groupsData={indexedCategories}
        isDesktop={true}
        value={selectedTournamentIds}
        onChange={onTournamentChange}
        size="s"
        hasSearch={true}
        isInlineSearch={false}
        disabled={tournamentsOptions.length === 0}
        title={tournamentsTitleAndPlaceholder}
        placeholder={tournamentsTitleAndPlaceholder}
        isExpandable={true}
        haveToCloseOutsideClick={false}
        isOpenExternal={openedControls === 3}
        setIsOpenExternal={setIsOpenedTournaments}
      />
    </Box>
  );
};
