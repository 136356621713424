import OutcomeButtonContainer from '../../../OutcomeButtonContainer';
import { useOutcomeField } from '../../../../store/selectors/outcome';
import MarketOutcomeContainer from '../../MarketOutcomeContainer';

const OutcomeButtonContainerWrapper = ({
  outcomeId,
  lineView,
  classNames,
  market,
  eventId,
  isShowName,
  isShowShortName
}) => {
  const outcomeTypeId = useOutcomeField(outcomeId, 'outcomeTypeId');
  return (
    <MarketOutcomeContainer
      dataRole={`market-outcome-${outcomeTypeId}`}
      lineView={lineView}
      classNames={classNames}
      value={
        <OutcomeButtonContainer
          outcomeId={outcomeId}
          market={market}
          eventId={eventId}
          outcomeTypeId={outcomeTypeId}
          isShowName={isShowName}
          lineView={lineView}
          isShowShortName={isShowShortName}
          classNames={classNames}
        />
      }
    />
  );
};

export default OutcomeButtonContainerWrapper;
