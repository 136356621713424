import { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { isEmpty } from 'lodash';

const HookedForm = ({
  children,
  formMethods = {},
  onSubmit = () => {},
  className = '',
  isNoValidate = false,
  autoComplete,
  rest
}) => {
  const { handleSubmit = () => {}, errors } = formMethods;
  const submit = useCallback(
    (fields) => {
      if (isEmpty(errors)) {
        onSubmit(fields, formMethods);
      }
    },
    [onSubmit, formMethods, errors]
  );

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(submit)}
        className={className}
        noValidate={isNoValidate}
        autoComplete={autoComplete}
        {...rest}
      >
        {children}
      </form>
    </FormProvider>
  );
};

export default HookedForm;
