import createAsyncValidator from '../../core/createAsyncValidator';
import minLengthAndCheckPhone from './minLengthAndCheckPhone';

const minLengthAndCheckPhoneValidator = (
  length,
  message = 'validators.minLengthValidator',
  mapper
) =>
  createAsyncValidator((value) =>
    minLengthAndCheckPhone({ length, message, value: mapper?.(value) ?? value })
  );

export default minLengthAndCheckPhoneValidator;
