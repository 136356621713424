import { useMemo } from 'react';
import vanillaLoadable from '@loadable/component';
import ErrorBoundary from '../../common/ErrorBoundary';
import Error from './components/Error';

/**
 * Replacer for "vanilla" @loadable/component, with default & custom message for case of network error
 * @param {function} componentFn The component to load, in form of "() => import('./some/component')"
 * @param {React.Component} FallbackMessage The message component to show in case of network error
 * @returns {React.Component} Loadable network-safe component
 * @example
 * // With default error
 * export default loadable(() => import('./some/component1'));
 * @example
 * // With no output on error
 * export default loadable(() => import('./some/component2'), null);
 * @example
 * // With custom error
 * export default loadable(
 *   () => import('./some/component3'),
 *   <div>Network error. Please, reload the page.</div>
 * );
 */
const loadable = (componentFn, FallbackMessage = <Error />) => (props) => {
  const ComponentLoadable = useMemo(() => vanillaLoadable(componentFn), []);

  return (
    <ErrorBoundary displayOnError={FallbackMessage}>
      <ComponentLoadable {...props} />
    </ErrorBoundary>
  );
};

export default loadable;
