import { useGeneralRoutes } from '@namespace/cms';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { EmptyState } from '@alf/uikit';
import { window } from '@namespace/helpers';
import { isEmpty } from 'lodash';
import { LINE_KEY, MODE } from '../../constants';
import { useGetPathParams } from '../../hooks/useGetPathParams';
import BasePreMatchEvents from '../../common/BasePreMatchEvents';
import { useSportsbookStore } from '../../store';

const PreMatchCategoryEventsWrapper = () => {
  const { home } = useGeneralRoutes();
  const { t } = useI18n();
  const { activeSport, sportSlug, category, categoryId } = useGetPathParams(
    MODE.PRE_MATCH
  );

  const isReady = useSportsbookStore('isTournamentCategoryReady');

  if (
    (isReady && isEmpty(category)) ||
    (activeSport === null && sportSlug) ||
    (!categoryId && !window.IS_SSR)
  ) {
    return (
      <EmptyState
        text={t('web.sports.noCategory')}
        Link={LocalizedLink}
        linkPath={home}
        linkText={t('home')}
      />
    );
  }

  return (
    category && (
      <BasePreMatchEvents
        activeSport={activeSport}
        categories={category ? [category] : []}
        key={`${categoryId}`}
        lineKey={`${LINE_KEY.PRE_MATCH}-${categoryId}`}
        isReady={isReady && !isEmpty(category)}
      />
    )
  );
};
export default PreMatchCategoryEventsWrapper;
