import { PrivateContentLoader } from '@namespace/user';
import FavoriteEvents from './FavoriteEvents';
import { MODE } from '../../constants';
import styles from './index.module.css';

const PreMatchFavoriteEvents = ({ id }) => (
  <PrivateContentLoader classNames={styles.userLoader}>
    <FavoriteEvents id={id} serviceId={MODE.PRE_MATCH} />
  </PrivateContentLoader>
);

export default PreMatchFavoriteEvents;
