import { useContext } from 'react';
import { useI18n, LocalizedLink } from '@namespace/i18n';
import { InfoMessage, AText, Box, Link } from '@alf/uikit';
import { UserContext } from '@namespace/user';
import { HTMLSnippet } from '@namespace/snippets';
import Jumio from '../Jumio';
import UploadFiles from '../UploadFiles';
import { DOCUMENT_TYPES, DOCUMENT_TYPES_MAP } from '../../../constants';
import styles from '../index.module.css';

const UploadDocumentsForm = ({
  maxFilesCount,
  minFilesCount,
  maxFilesSize,
  allowedFilesFormats,
  isJumio,
  isUploadDocuments,
  allowedJumioUserIds = [],
  hideInfoMessage = false,
  infoMessageSnippetId
}) => {
  const { t } = useI18n();
  const [user] = useContext(UserContext);
  const {
    firstName,
    lastName,
    documents: [document],
    id: userId
  } = user;
  const { documentTypeId, citizenship } = document;

  return (
    <Box
      direction="column"
      align="center"
      className={styles.uploadDocumentsWrapper}
    >
      {infoMessageSnippetId && (
        <InfoMessage icon="info" className={styles.message}>
          <HTMLSnippet snippetId={infoMessageSnippetId} />
        </InfoMessage>
      )}
      {!hideInfoMessage && (
        <InfoMessage icon="info" className={styles.message}>
          <AText component="p" breed="R" size="s" color="po_txt_color_3">
            {t('verificationWizard.step.uploadDocuments.info_1', [
              `${firstName} ${lastName}`
            ])}
          </AText>
          <AText component="p" breed="R" size="s" color="po_txt_color_3">
            {DOCUMENT_TYPES_MAP[documentTypeId]
              ? t(
                  `verificationWizard.step.uploadDocuments.info_2_${DOCUMENT_TYPES_MAP[documentTypeId]}`
                )
              : t('verificationWizard.step.uploadDocuments.info_2')}
          </AText>
          <AText component="p" breed="R" size="s" color="po_txt_color_3">
            {t('verificationWizard.step.uploadDocuments.info_3')}
            <Link
              to={`mailto:${t(
                'verificationWizard.step.uploadDocuments.info_4'
              )}`}
              Component={LocalizedLink}
            >
              &nbsp;
              <AText component="u" breed="B" size="s" color="po_txt_color_3">
                {t('verificationWizard.step.uploadDocuments.info_4')}
              </AText>
              &nbsp;
            </Link>
            {t('verificationWizard.step.uploadDocuments.info_5')}
          </AText>
        </InfoMessage>
      )}
      <Box className={styles.wrapper}>
        {(isJumio || allowedJumioUserIds.includes(userId)) &&
          documentTypeId === DOCUMENT_TYPES.IDCARD &&
          citizenship.toLowerCase() === 'ua' && <Jumio />}
        {(isUploadDocuments || allowedJumioUserIds.includes(userId)) && (
          <UploadFiles
            maxSize={maxFilesSize}
            maxCount={maxFilesCount}
            minCount={minFilesCount}
            allowedFilesFormats={allowedFilesFormats}
          />
        )}
      </Box>
    </Box>
  );
};

export default UploadDocumentsForm;
