import { useContext } from 'react';
import { UserContext } from '@namespace/user';
import { PageContext } from '@namespace/cms';
import { DeviceContext } from '@namespace/device';
import clsx from 'clsx';
import { Box } from '@alf/uikit';
import { useGetThemeConfig } from '@namespace/themes';
import styles from './index.module.css';

import PersonalOfficeUserName from './PersonalOfficeUserName';

export { PersonalOfficeUserName };

const Wrapper = ({
  children,
  className,
  wrapperClassName,
  hideUserName,
  ...rest
}) => {
  const [user] = useContext(UserContext);
  const { isDesktop } = useContext(DeviceContext);
  const { firstName, lastName, middleName, isDefaultRegistratedUser } = user;
  const { page } = useContext(PageContext);
  const { pageConfig = {} } = page;
  const { isDisplayFullNameOnPage = false } = pageConfig;
  const { styles: poStyles = {} } = useGetThemeConfig('PersonalOfficeWrapper');
  const { desktopStyles = {}, mobileStyles = {} } = poStyles;

  return (
    <Box
      style={isDesktop ? desktopStyles : mobileStyles}
      className={clsx(styles.wrapper, wrapperClassName)}
      direction="column"
    >
      {!hideUserName &&
        isDisplayFullNameOnPage &&
        !isDefaultRegistratedUser && (
          <PersonalOfficeUserName
            firstName={firstName}
            lastName={lastName}
            middleName={middleName}
          />
        )}
      <div className={clsx([className, styles.content])} {...rest}>
        {children}
      </div>
    </Box>
  );
};

export default Wrapper;
