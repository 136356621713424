import { useMemo } from 'react';
import clsx from 'clsx';
import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { makeDatesByDaysInterval, makeDatesByWeeksInterval } from './utils';
import {
  PROMO_TIMER_TYPES,
  PROMO_TYPES,
  SCHEDULING_TYPE
} from '../../../../constants';
import Timer from '../Timer';
import { usePromotionsStore } from '../../../../store';
import styles from '../../index.module.css';

const promoTypePromoDatesTranslationKey = {
  [PROMO_TYPES.promotion]: 'web.promotions.dates',
  [PROMO_TYPES.tournament]: 'web.tournaments.dates'
};

const ScheduleDateTime = ({
  promoData = {},
  showTimerInsteadPeriod,
  isRecentlyPromo = false,
  isPromoItem = false,
  isPromoPage = false,
  dateTextColor,
  dateTextSize,
  className,
  activePromoType = PROMO_TYPES.promotion
}) => {
  const isCompleted = usePromotionsStore('isCompleted');
  const {
    periodStart,
    periodEnd,
    repeatEvery,
    period,
    startTime,
    endTime,
    daysOfWeek,
    showDateWhenActive
  } = promoData;
  const { t, f } = useI18n();
  const periodEndDate = f.getDateTime({ date: periodEnd });
  const currentDate = f.getDateTime();
  const DATE_FORMATS_TYPES = f.getFormats();
  const startDate = f
    .getDateTime({ date: periodStart })
    .toFormat(DATE_FORMATS_TYPES.PROMOTIONS_START_DATE);
  const endDate = f
    .getDateTime({ date: periodEnd })
    .toFormat(DATE_FORMATS_TYPES.PROMOTIONS_END_DATE);
  const startTimeInMinutes = startTime
    .split(':')
    .reduce((acc, time) => 60 * acc + +time);
  const endTimeInMinutes = endTime
    .split(':')
    .reduce((acc, time) => 60 * acc + +time);

  const possibleDates =
    period === SCHEDULING_TYPE.DAY
      ? makeDatesByDaysInterval({
          f,
          startDate: periodStart,
          endDate: periodEnd,
          startTimeInMinutes,
          endTimeInMinutes,
          daysInterval: repeatEvery
        })
      : makeDatesByWeeksInterval({
          f,
          startDate: periodStart,
          endDate: periodEnd,
          startTimeInMinutes,
          endTimeInMinutes,
          weeksInterval: repeatEvery,
          daysOfWeek
        });

  const [firstPossibleDate] = possibleDates || [{}];
  const { from: nearestDateFrom, to: nearestDateTo, isPeriod } =
    firstPossibleDate || {};

  const timerType = useMemo(() => {
    if (
      possibleDates.length &&
      currentDate > nearestDateFrom &&
      currentDate < nearestDateTo
    ) {
      return PROMO_TIMER_TYPES.END;
    }

    if (possibleDates.length && currentDate < nearestDateFrom) {
      return PROMO_TIMER_TYPES.BEGIN;
    }
    if (currentDate > periodEndDate || !possibleDates.length) {
      return PROMO_TIMER_TYPES.FINISHED;
    }
    return null;
  }, [
    possibleDates,
    isCompleted,
    currentDate,
    nearestDateFrom,
    nearestDateTo,
    periodEndDate
  ]); // isCompleted update timer type

  const dateWrapperClassName = useMemo(() => {
    if (showTimerInsteadPeriod) {
      return styles.dateWrapperTimer;
    }
    if (isRecentlyPromo) {
      return styles.dateWrapperRecentlyPromo;
    }

    return styles.dateWrapper;
  }, [showTimerInsteadPeriod, isRecentlyPromo]);

  const isDisplayTimer =
    (showTimerInsteadPeriod && !isRecentlyPromo) ||
    (!showTimerInsteadPeriod &&
      !showDateWhenActive &&
      !isRecentlyPromo &&
      currentDate > nearestDateFrom);

  const dateToDisplay = useMemo(() => {
    if (
      (showDateWhenActive || currentDate < nearestDateFrom) &&
      (period === SCHEDULING_TYPE.DAY ||
        (period === SCHEDULING_TYPE.WEEK && possibleDates.length === 1))
    ) {
      return isPeriod
        ? `${f
            .getDateTime({ date: nearestDateFrom })
            .toFormat(
              DATE_FORMATS_TYPES.PROMOTIONS_START_DATE
            )} - ${f
            .getDateTime({ date: nearestDateTo })
            .toFormat(DATE_FORMATS_TYPES.PROMOTIONS_END_DATE)}`
        : f
            .getDateTime({ date: nearestDateFrom })
            .toFormat(DATE_FORMATS_TYPES.PROMOTIONS_END_DATE);
    }

    return `${startDate} - ${endDate}`;
  }, [
    DATE_FORMATS_TYPES,
    endDate,
    f,
    isPeriod,
    nearestDateFrom,
    nearestDateTo,
    period,
    possibleDates,
    showDateWhenActive,
    startDate
  ]);

  if (timerType === PROMO_TIMER_TYPES.FINISHED && !isRecentlyPromo) {
    return (
      <Box
        data-testid="schedule-date-time"
        className={clsx([styles.finished, className])}
        align="center"
      >
        <AText breed="BU" size="2xl" color="txt_color_7">
          {t('web.promo.timer.finished')}
        </AText>
      </Box>
    );
  }

  return (
    <Box
      direction="column"
      align="center"
      data-testid="schedule-date-time"
      className={clsx([dateWrapperClassName, className])}
    >
      {isDisplayTimer ? (
        <Timer
          periodStart={nearestDateFrom}
          periodEnd={nearestDateTo}
          timerType={timerType}
          dateTextColor={dateTextColor}
          isPromoItem={isPromoItem}
          isPromoPage={isPromoPage}
        />
      ) : (
        <Box direction="column" align="center">
          {!isRecentlyPromo && (
            <AText
              data-role="promotions-date-text"
              breed="B"
              size="xs"
              color={dateTextColor}
            >
              {t(promoTypePromoDatesTranslationKey[activePromoType])}
            </AText>
          )}
          <AText
            data-role="promotions-date"
            size={dateTextSize}
            color={dateTextColor}
            breed="B"
          >
            {dateToDisplay}
          </AText>
        </Box>
      )}
    </Box>
  );
};

export default ScheduleDateTime;
