import { useRouteParams } from '@namespace/router';
import { getRootPath } from '@namespace/helpers';
import { useConfig } from '@namespace/cms';
import { useLocation } from '@namespace/i18n';
import { HTMLSnippet } from '@namespace/snippets';
import PageScroll from './PageScroll';

const Scroller = ({ id }) => {
  const { config } = useConfig(id);
  const { snippets = [], activeSlideClass, animationDuration } = config;
  const snippetsUrls = snippets.map(({ url }) => url);
  const { pathname } = useLocation();
  const rootPath = getRootPath(pathname);

  const { currentSlide = snippetsUrls[0] } = useRouteParams(
    `${rootPath}/:currentSlide`
  );

  return (
    <PageScroll
      slidesUrls={snippetsUrls}
      defaultSlide={snippetsUrls.indexOf(currentSlide)}
      activeSlideClass={activeSlideClass}
      animationDuration={animationDuration}
      slides={snippets.map(({ snippetId }) => (
        <HTMLSnippet key={snippetId} snippetId={snippetId} />
      ))}
    />
  );
};

export default Scroller;
