import { useContext, useEffect, useMemo } from 'react';
import { isEmpty } from 'ramda';
import { UserContext } from '@namespace/user';
import { SWPaymentsContext } from '../../store/context';
import { normalizePaymentServices } from '../../utils';
import { useGetPayments } from '../useGetPayments';
import { usePSDescriptions } from '../usePSDescriptions';

export const useGetPaymentsConfigSW = () => {
  const [, { GET_SW_CONFIG }] = useContext(SWPaymentsContext);
  const [{ activeWallet = {} }] = useContext(UserContext);
  const { currency } = activeWallet;
  const paymentSystems = useGetPayments();

  const paymentServicesDescription = usePSDescriptions();
  const userPaymentServices = useMemo(
    () =>
      normalizePaymentServices(
        paymentSystems || {},
        paymentServicesDescription,
        currency
      ),
    [paymentSystems, paymentServicesDescription, currency]
  );

  useEffect(() => {
    if (!isEmpty(paymentServicesDescription)) {
      GET_SW_CONFIG(userPaymentServices);
    }
  }, [GET_SW_CONFIG, paymentServicesDescription, userPaymentServices]);

  return null;
};
