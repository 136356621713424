import { useCallback, useEffect, useMemo, useState, cloneElement } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Flag, Icon } from '@namespace/icons2';
import { useGetThemeConfig } from '@namespace/themes';

import { propsMerge } from '../../utils/propsMerge';
import Input from '../Input';
import Popover from '../Popover';
import Box from '../Box';
import { SelectContent } from '../Select/components/DesktopSelect/components/SelectContent';
import {
  createValue as createValueUtil,
  extractNumber,
  filterByCountryOrCode,
  getCountryByPhoneNumber,
  getMaskByIsoCode,
  getPhoneLength,
  withPlusPrefix
} from './utils';
import styles from './index.module.css';

const MINIMAL_FILTERED_ITEMS_COUNT = 3;

const PhoneSearch = (props) => {
  const phoneSearchConfig = useGetThemeConfig('PhoneSearch');
  const {
    value: valueProp = {
      number: '',
      // In order to handle specific cases of selection countries with similar
      // ISO code (ex. "+1": Puerto-Rico & Canada), we decided to store ISO-code separately
      // (instead of not extracting it from phone-number).
      isoCode: ''
    },
    onChange,
    onDefaultValueChange,
    disabled = false,
    codesListItems = [],
    codesListProps = {
      size: '',
      placeholderText: '',
      text: ''
    },
    minNumberLength = 0,
    dataRole = '',
    intent = 'default',
    inputSize = 'm',
    bottomElement = null,
    ...rest
  } = propsMerge(props, phoneSearchConfig);

  const [showFilter, setShowFilter] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [currentValue, setCurrentValue] = useState('');
  const [selectedItem, setSelectedItem] = useState(-1);
  const [isDirty, setIsDirty] = useState(false);

  const code = valueProp.number.slice(0, 3);
  const codesListItemsExists = Boolean(codesListItems.length);

  const countryData = useMemo(
    () =>
      getCountryByPhoneNumber(codesListItems, code) || {
        phoneCode: '',
        iso1Code: ''
      },
    [code, codesListItems]
  );

  const mask = useMemo(() => getMaskByIsoCode(valueProp.isoCode) ?? null, [
    valueProp.isoCode
  ]);

  const filteredCodes = useMemo(() => {
    const filteredItems = codesListItems.filter(
      filterByCountryOrCode(filterValue)
    );

    filteredItems.sort((a, b) => a.countryName.localeCompare(b.countryName));

    return filteredItems;
  }, [filterValue, codesListItems]);

  const createValue = useMemo(
    () =>
      createValueUtil({
        valueProp,
        codesListItems
      }),
    [valueProp, codesListItems]
  );

  useEffect(() => {
    /* Handles "isoCode" update (ex. default-country-code change). */
    if (valueProp.isoCode && !valueProp.number && codesListItemsExists) {
      onDefaultValueChange?.(createValue(null, valueProp.isoCode));
    }

    /* It should watch for "isoCode" update ONLY. */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [valueProp.isoCode, codesListItemsExists]);

  const handleKeyDown = useCallback(
    (e) => {
      setIsDirty(true);

      showFilter && e.key === 'Tab' && setShowFilter(false);
    },
    [showFilter, setShowFilter]
  );

  const handleFlagClick = useCallback(() => {
    setShowFilter((prev) => !prev);
  }, [setShowFilter]);

  const handleNumberChange = useCallback(
    (e) => {
      const { value } = e.target;

      if (value.length < minNumberLength) return;

      onChange?.(createValue(extractNumber(value)));
    },
    [onChange, createValue, minNumberLength]
  );

  const closePopover = useCallback(() => {
    setFilterValue('');
    setShowFilter(false);
  }, []);

  const handlePopoverClose = useCallback(() => {
    closePopover();
  }, [closePopover]);

  const handleISOCodeChange = useCallback(
    ({ iso1Code }) => {
      onChange?.(createValue(null, iso1Code));
    },
    [onChange, createValue]
  );

  const handleNumberInputClick = useCallback(() => {
    setIsDirty(true);

    showFilter && closePopover();
  }, [closePopover, showFilter]);

  const maxLength = useMemo(
    () =>
      getMaskByIsoCode(valueProp.isoCode)
        ? undefined
        : getPhoneLength(valueProp.isoCode, countryData.phoneCode),
    [valueProp.isoCode, countryData.phoneCode]
  );

  const wrapperClasses = [styles.general];

  if (isDirty) {
    wrapperClasses.push(styles[intent]);
  }

  const displaySearchInput =
    filteredCodes.length >= MINIMAL_FILTERED_ITEMS_COUNT ||
    Boolean(currentValue);

  const handleFilter = useCallback(
    (event) => {
      setCurrentValue(event.target.value);

      // In seek of smooth user-interaction (typing) work we're decreasing side-effect's priority
      setTimeout(() => {
        setFilterValue(event.target.value);
      });
    },
    [setCurrentValue, setFilterValue]
  );

  return (
    <Box direction="column" onKeyDown={handleKeyDown} data-role={dataRole}>
      <Popover
        trigger={
          <Box
            className={clsx(wrapperClasses)}
            data-role={dataRole && `${dataRole}-phone-input`}
          >
            <Box
              disabled={true}
              align="center"
              onClick={handleFlagClick}
              className={clsx([
                styles.flagWrapper,
                disabled && styles.disabled
              ])}
              data-role={dataRole && `${dataRole}-flag`}
            >
              <Box className={styles.flagContainer}>
                {valueProp.isoCode && (
                  <Flag
                    data-testid="flag"
                    className={styles.icon}
                    size="s"
                    iso={valueProp.isoCode}
                  />
                )}
              </Box>

              <Icon
                size="xs"
                name="icons/general/nav/dropdown_down"
                className={clsx(styles.arrowDown, showFilter && styles.arrowUp)}
              />
            </Box>
            <Input
              data-role={`${dataRole}-phone-number`}
              type="tel"
              name="number"
              className={styles.inputNumber}
              onChange={handleNumberChange}
              onClick={handleNumberInputClick}
              value={withPlusPrefix(valueProp.number)}
              min={0}
              maxLength={maxLength}
              mask={mask}
              size={inputSize}
              autoComplete="nope"
              disabled={disabled}
              isDisabledBottomElement={true}
              {...rest}
            />
          </Box>
        }
        content={
          <>
            {displaySearchInput && (
              <Box align="center" className={styles.countryCodeInput}>
                <Icon
                  className={styles.searchIcon}
                  size="s"
                  name="icons/general/action/search"
                />
                <Input
                  type="text"
                  intent="ghost"
                  name="codeSearchInput"
                  className={styles.inputPlaceholder}
                  onChange={handleFilter}
                  placeholder={codesListProps?.placeholderText}
                  value={currentValue}
                  maxLength={50}
                  min={0}
                  size="m"
                  autoComplete="nope"
                />
              </Box>
            )}
            <SelectContent
              dataRole={dataRole}
              options={filteredCodes}
              onClick={handleISOCodeChange}
              value={valueProp.isoCode}
              setIsOpen={setShowFilter}
              setCurrentValue={setFilterValue}
              hasCheck={true}
              emptyText={codesListProps.text}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              size="s"
            />
          </>
        }
        classNames={{
          contentClass: styles.contentClass
        }}
        setIsOpenExternal={handlePopoverClose}
        isOpenExternal={showFilter}
        disabledToggleInWrapper={true}
      />
      {bottomElement &&
        !showFilter &&
        cloneElement(bottomElement, { className: styles.borderBottomCustom })}
    </Box>
  );
};

PhoneSearch.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.shape({
    number: PropTypes.string.isRequired,
    isoCode: PropTypes.string.isRequired
  }),
  codesListItems: PropTypes.array,
  codesListProps: PropTypes.shape({
    size: PropTypes.oneOf(['s', 'm']),
    placeholderText: PropTypes.string,
    text: PropTypes.string
  }),
  disabled: PropTypes.bool,
  dataRole: PropTypes.string,
  intent: PropTypes.oneOf(['error', 'success', 'default'])
};

export default PhoneSearch;
