import { useContext } from 'react';
import { isEmpty } from 'lodash';
import { useOtherSettings } from '@namespace/cms';
import { useMatchPath } from '@namespace/router';
import { DeviceContext } from '@namespace/device';

export const useNativeAppBannerMetaTag = () => {
  const { isIOS } = useContext(DeviceContext);
  const { appIdCode, nativeAppBannerPages } = useOtherSettings();
  const hasMatch = !isEmpty(
    useMatchPath(nativeAppBannerPages, { isReturnFullMatch: true })
  );

  if (isIOS && appIdCode && hasMatch) {
    return <meta name="apple-itunes-app" content={`app-id=${appIdCode}`} />;
  }

  return null;
};
