import { useContext } from 'react';
import { isEmpty } from 'lodash';
import { Box, AText } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { useComponentConfig } from '@namespace/cms';
import { DeviceContext } from '@namespace/device';
import { getCurrencySign } from '../../../../../../utils';
import { InHouseJackpotsTotalCounter } from '../InHouseJackpotsTotalCounter';
import { useGetInHouseJackpotSettings } from '../../../../../../hooks';

import styles from './index.module.css';

export const JackpotBanner = ({
  totalJackpot,
  onClick = () => {},
  classNames = {}
}) => {
  const { t } = useI18n();
  const { inhouseJackpotImage } = useComponentConfig();
  const { isTablet } = useContext(DeviceContext);
  const { bannerWrapper = '' } = classNames;
  const { tabletJackpotImage } = useGetInHouseJackpotSettings();

  const actualInhouseJackpotImage =
    isTablet && tabletJackpotImage ? tabletJackpotImage : inhouseJackpotImage;

  return (
    <Box
      className={`${styles.wrapper} ${bannerWrapper}`}
      justify="center"
      onClick={onClick}
    >
      <img
        className={styles.jackpotBackgroundImage}
        src={actualInhouseJackpotImage}
        alt="in-house jackpot"
      />
      <Box direction="column" className={styles.jackpotBannerWrapper}>
        <Box padding="0 0 xs 0">
          <AText
            className={styles.jackpotBannerLabel}
            breed="BU"
            color="accent_color_3"
            align="center"
          >
            {t('games.jackpot.favbetJackpot')}
          </AText>
        </Box>
        <Box align="baseline" padding="xxs 0 0 0">
          <AText
            className={styles.jackpotBannerAmount}
            breed="BU"
            align="center"
            color="txt_color_4"
          >
            {!isEmpty(totalJackpot) && (
              <InHouseJackpotsTotalCounter totalJackpot={totalJackpot} />
            )}
          </AText>
          <AText
            className={styles.jackpotBannerCurrency}
            breed="BU"
            color="txt_color_4"
          >
            {getCurrencySign(totalJackpot.convertedCurrency)}
          </AText>
        </Box>
      </Box>
    </Box>
  );
};
