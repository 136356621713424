import { AText, Box } from '@alf/uikit';
import { LocalizedLink, useI18n, useLocation } from '@namespace/i18n';
import { Icon } from '@namespace/icons2';
import { useGeneralRoutes } from '@namespace/cms';
import { DEFAULT_ACTIVE_TAG } from '../../constants';
import styles from './index.module.css';

const Categories = ({ enabledTypes = [], validTags = {} }) => {
  const { t } = useI18n();
  const { promotions } = useGeneralRoutes();
  const { pathname } = useLocation();

  return (
    <Box className={styles.categoriesWrapper}>
      {enabledTypes?.map((category) => {
        const isActive = pathname.includes(category);
        const tagPath = validTags?.categoryName || DEFAULT_ACTIVE_TAG;

        return (
          <LocalizedLink
            to={`${promotions}/${category}/${tagPath}`}
            className={styles.filtersContainerItem}
            data-role={`promotions-category-${category}`}
            key={category}
          >
            <Box align="center">
              <Icon
                className={styles.filterItemIcon}
                size="s"
                name={`icons/nav_menu/${category}`}
                color={`accent_color_${isActive ? '3' : '2'}`}
              />
              <AText
                breed="BU"
                size="m"
                color={`accent_color_${isActive ? '3' : '2'}`}
              >
                {t(`web.${category}.lobby.title`)}
              </AText>
            </Box>
          </LocalizedLink>
        );
      })}
    </Box>
  );
};

export default Categories;
