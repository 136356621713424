import { useGeneralRoutes } from '@namespace/cms';
import CommonTopEventsLink from './CommonTopEventsLink';

const TournamentLink = ({
  growsMap,
  growIndex,
  isShowImages,
  icon,
  presetId,
  translation
}) => {
  const { topEvents: topEventsRoute } = useGeneralRoutes();

  return (
    <CommonTopEventsLink
      growsMap={growsMap}
      growIndex={growIndex}
      isShowImages={isShowImages}
      icon={icon}
      presetId={presetId}
      translation={translation}
      link={`${topEventsRoute}/${presetId}`}
    />
  );
};

export default TournamentLink;
