import { useCallback, useContext, useState } from 'react';
import { UserContext } from '@namespace/user';
import { useReferenceState } from '@namespace/common';
import { isEmpty } from 'lodash';
import { Box } from '@alf/uikit';
import { SiteSettingsContext, useGeneralRoutes } from '@namespace/cms';
import { useHistory } from '@namespace/router';
import DocumentInformationForm from '../../../common/ProfileDocumentsForm';
import EmailVerificationForm from '../EmailVerificationForm';

const DocumentInformation = ({ config = {} }) => {
  useReferenceState();
  const [user] = useContext(UserContext);
  const [showEmailVerificationForm, setShowEmailVerificationForm] = useState(
    true
  );
  const { documents = [] } = user;
  const [document = {}] = documents;
  const isEmptyDocument = isEmpty(document);
  const { verified } = document;

  const [settings = {}] = useContext(SiteSettingsContext);
  const { payments = {} } = settings;
  const { isWithdrawWithoutDocuments = false } = payments;

  const { push } = useHistory();
  const { withdraw } = useGeneralRoutes();

  const onSuccess = useCallback(() => {
    if (isWithdrawWithoutDocuments) {
      push(withdraw);
    }
  }, [push, withdraw, isWithdrawWithoutDocuments]);

  if (!verified && !isEmptyDocument && showEmailVerificationForm) {
    return (
      <EmailVerificationForm
        onClick={() => setShowEmailVerificationForm(false)}
        document={document}
      />
    );
  }

  return (
    <Box direction="column" flex="1">
      <DocumentInformationForm
        isDisabled={!isEmptyDocument}
        document={document}
        config={config}
        onSuccess={onSuccess}
        hideInfoMessage={verified}
        hideButton={!isEmptyDocument}
      />
    </Box>
  );
};

export default DocumentInformation;
