import { useContext } from 'react';
import { isEmpty, isNil } from 'lodash';
import { useI18n } from '@namespace/i18n';
import { DeviceContext } from '@namespace/device';
import { AText, Box } from '@alf/uikit';
import { useGetNumberOfDecimalPlaces } from '@namespace/common';
import { getCurrencySign, formatNumToAmount } from '../../../../../../utils';
import { useGetInHouseJackpotDateFormatter } from '../../../../../../hooks/useGetInHouseJackpotDateFormatter';
import { useGetInHouseJackpotWinnerInfo } from '../../../../../../jackpotsContext/selectors';
import { JackpotItemWinnerGameInfo } from './JackpotItemWinnerGameInfo';

import styles from './index.module.css';

export const JackpotItemInGameDetails = ({
  jackpotInfo,
  onLaunchGame,
  isShowInHouseJackpotGamesNameAsLink
}) => {
  const { t } = useI18n();
  const { maxWinner, lastWinner } = jackpotInfo;
  const { isDesktop } = useContext(DeviceContext);

  const {
    gameName: maxWinnerGameName,
    providerName: maxWinnerProviderName,
    gameId: maxWinnerGameId,
    providerId: maxWinnerProviderId
  } = useGetInHouseJackpotWinnerInfo(maxWinner?.gameId);
  const {
    gameName: lastWinnerGameName,
    providerName: lastWinnerProviderName,
    gameId: lastWinnerGameId,
    providerId: lastWinnerProviderId
  } = useGetInHouseJackpotWinnerInfo(lastWinner?.gameId);

  const formatDate = useGetInHouseJackpotDateFormatter();
  const numberOfDecimalPlaces = useGetNumberOfDecimalPlaces();

  return (
    <Box className={styles.contentWrapper} direction="column">
      {!isNil(jackpotInfo.total) && (
        <Box className={styles.infoRow} justify="center" align="center">
          <AText size="s" breed="B" color="accent_color_3" align="center">
            {t('games.jackpot.totalWinners')}
          </AText>
          <AText size="s" breed="R" color="txt_color_4">
            &nbsp;
            {jackpotInfo.total}
          </AText>
        </Box>
      )}
      {jackpotInfo.activationAmount && (
        <>
          <Box className={styles.hr} />
          <Box className={styles.infoRow} justify="center" align="center">
            <AText size="s" breed="B" color="accent_color_3" align="center">
              {t('games.jackpot.activationAmount')}
            </AText>
            <Box align="baseline" className={styles.amountCurrencyWrapper}>
              <AText size="s" breed="R" color="txt_color_4">
                &nbsp;
                {formatNumToAmount({
                  num: jackpotInfo.convertedActivationAmount,
                  numberOfDecimalPlaces
                })}
              </AText>
              <AText size="s" breed="R" color="txt_color_4">
                &nbsp;
                {getCurrencySign(jackpotInfo.convertedCurrency)}
              </AText>
            </Box>
          </Box>
        </>
      )}
      {!isEmpty(jackpotInfo.maxWinner) && (
        <>
          <Box className={styles.hr} />
          <Box className={styles.infoRow} align="center" direction="column">
            <AText size="s" breed="B" color="accent_color_3">
              {t('games.jackpot.maxWinner')}
            </AText>
            <AText size="s" breed="RU" color="txt_color_4">
              {t('games.jackpot.id')}
              <span>: </span>
              <span>{jackpotInfo.maxWinner.userId}</span>
            </AText>
            <Box align="baseline">
              <AText size="s" breed="B" color="txt_color_4">
                {formatNumToAmount({
                  num: jackpotInfo.maxWinner.convertedAmount,
                  numberOfDecimalPlaces
                })}
              </AText>
              <AText size="s" breed="R" color="txt_color_4">
                &nbsp;
                {getCurrencySign(jackpotInfo.convertedCurrency)}
              </AText>
            </Box>
            <AText size="s" breed="R" color="txt_color_4">
              {formatDate(jackpotInfo.maxWinner.reportedAt)}
            </AText>
            {maxWinnerProviderName && maxWinnerGameName && (
              <JackpotItemWinnerGameInfo
                isShowAsLink={isShowInHouseJackpotGamesNameAsLink && isDesktop}
                onLaunchGame={() =>
                  onLaunchGame({
                    gameId: maxWinnerGameId,
                    providerId: maxWinnerProviderId,
                    launchGameId: maxWinner?.gameId
                  })
                }
                gameName={maxWinnerGameName}
                providerName={maxWinnerProviderName}
              />
            )}
          </Box>
        </>
      )}
      {!isEmpty(jackpotInfo.lastWinner) && (
        <>
          <Box className={styles.hr} />
          <Box className={styles.infoRow} align="center" direction="column">
            <AText size="s" breed="B" color="accent_color_3">
              {t('games.jackpot.lastWinner')}
            </AText>
            <AText size="s" breed="RU" color="txt_color_4">
              {t('games.jackpot.id')}
              <span>: </span>
              <span>{jackpotInfo.lastWinner.userId}</span>
            </AText>
            <Box align="baseline">
              <AText size="s" breed="B" color="txt_color_4">
                {formatNumToAmount({
                  num: jackpotInfo.lastWinner.convertedAmount,
                  numberOfDecimalPlaces
                })}
              </AText>
              <AText size="s" breed="R" color="txt_color_4">
                &nbsp;
                {getCurrencySign(jackpotInfo.convertedCurrency)}
              </AText>
            </Box>
            <AText size="s" breed="R" color="txt_color_4">
              {formatDate(jackpotInfo.lastWinner.reportedAt)}
            </AText>
            {lastWinnerProviderName && lastWinnerGameName && (
              <JackpotItemWinnerGameInfo
                isShowAsLink={isShowInHouseJackpotGamesNameAsLink && isDesktop}
                onLaunchGame={() =>
                  onLaunchGame({
                    gameId: lastWinnerGameId,
                    providerId: lastWinnerProviderId,
                    launchGameId: lastWinner?.gameId
                  })
                }
                gameName={lastWinnerGameName}
                providerName={lastWinnerProviderName}
              />
            )}
          </Box>
        </>
      )}
      {isEmpty(jackpotInfo.maxWinner) && isEmpty(jackpotInfo.lastWinner) && (
        <>
          <Box className={styles.hr} />
          <Box className={styles.infoRow} direction="column">
            <AText size="m" align="center" breed="R" color="txt_color_4">
              {t('games.jackpot.noWinnerYet')}
            </AText>
          </Box>
        </>
      )}
    </Box>
  );
};
