import { useContext, useCallback } from 'react';
import { StepperContext } from '@namespace/common';
import usePasswordRecovery from '../../../../hooks/usePasswordRecovery';
import NewPassword from '../NewPassword';
import CancelButton from '../CancelButton';
import styles from './index.module.css';

const SetNewPassword = ({ onStepSuccess, onGoFirst, onStepError }) => {
  const {
    stepper: {
      formData: { question = '', answer = '', token = '' },
      errorCode = ''
    }
  } = useContext(StepperContext);

  const { cancel, savePassword } = usePasswordRecovery(
    onStepSuccess,
    onGoFirst
  );

  const onSubmit = useCallback(
    (data) => {
      savePassword({
        ...data,
        answer,
        question,
        token
      }).catch(({ errorCode: stepErrorCode }) =>
        onStepError({ errorCode: stepErrorCode })
      );
    },
    [savePassword, question, answer, token, onStepError]
  );

  return (
    <div className={styles.step}>
      <NewPassword onSuccess={onSubmit} errorCode={errorCode} />
      <CancelButton onCancel={cancel} />
    </div>
  );
};

export default SetNewPassword;
