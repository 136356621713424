import { memo } from 'react';
import { AText, Box } from '@alf/uikit';
import { SportIcon } from '@namespace/icons2';
import { SERVING_SPORTS } from '../../../../../constants';
import { useEventFields } from '../../../../../store';
import styles from './index.module.css';

const EventParticipants = ({ eventId }) => {
  const { participants, eventName, sportId, eventServing } = useEventFields({
    eventId,
    fields: ['participants', 'eventName', 'sportId', 'eventServing']
  });

  return (
    <Box className={`${styles.container}`}>
      <Box className={styles.participants} justify="center" direction="column">
        {participants?.length === 2 ? (
          participants?.map(
            ({ eventParticipantId, participantName, participantId }) => {
              const isParticipantServing =
                SERVING_SPORTS.includes(sportId) &&
                participantId === Number(eventServing);

              return (
                <Box
                  className={styles.participantMain}
                  key={eventParticipantId}
                  data-role={`event-participant-${eventParticipantId}`}
                >
                  <AText
                    className={`
                      ${styles.participantName}
                      ${isParticipantServing ? styles.serving : ''}
                    `}
                    title={participantName}
                    size="m"
                    overflow="ellipsis"
                    color="line_txt_1"
                  >
                    {participantName}
                  </AText>
                </Box>
              );
            }
          )
        ) : (
          <Box direction="column" className={styles.participantMain}>
            <AText
              size="m"
              breed="B"
              overflow="ellipsis"
              color="line_txt_1"
              className={`${styles.participantName}`}
              title={eventName}
            >
              {eventName}
            </AText>
          </Box>
        )}
      </Box>
      <div className={styles.sportIconWrapper}>
        <SportIcon
          sportId={sportId}
          size="3xl"
          color="line_bg_3"
          className={styles.sportIcon}
        />
      </div>
    </Box>
  );
};

export default memo(EventParticipants);
