import { useMemo } from 'react';
import { AText, Box } from '@alf/uikit';
import { SPORT_IDS_WITH_DETAILS, SCOPE_IDS } from '../../../../../../constants';
import { useEventFields, useEventField } from '../../../../../../store';
import styles from './index.module.css';

const RedCard = ({ eventId, participantIndex }) => {
  const { scoreboard, sportId } = useEventFields({
    eventId,
    fields: ['scoreboard', 'sportId']
  });

  const participantNumber = useEventField(eventId, [
    'participants',
    participantIndex,
    'participantNumber'
  ]);

  const { total = [] } = scoreboard || {};
  const redCards = useMemo(() => {
    if (SPORT_IDS_WITH_DETAILS.includes(sportId)) {
      return total.find(({ scopeId }) => scopeId === SCOPE_IDS.RED_CARD)
        ?.scopeData;
    }

    return [];
  }, [total, sportId]);

  const { value: redCard } =
    redCards?.find(({ number }) => number === participantNumber) || {};

  if (redCard > 0) {
    return (
      <Box align="center" justify="center" className={styles.redCard}>
        <AText size="xs" breed="R" color="line_txt_2">
          {redCard}
        </AText>
      </Box>
    );
  }
  return null;
};

export default RedCard;
