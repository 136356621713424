import {
  setLocalStorageValue,
  LOCAL_STORAGE_KEYS,
  setCookie,
  COOKIES_KEYS,
  removeCookie,
  getCookie
} from '@namespace/helpers';
import { types } from '../types';
import removeOptionalCookies from '../../utils/removeOptionalCookies';
import getRootDomain from '../../utils/getRootDomain';

const {
  SET_COOKIE_MANAGEMENT_TYPE,
  UPDATE_COOKIE_MANAGEMENT_TYPE,
  SET_SHOW_COOKIE_MANAGEMENT_ON_COOKIE_DECLARATION_PAGE,
  SET_CUSTOMIZE_COOKIE
} = types;

export default {
  [SET_COOKIE_MANAGEMENT_TYPE]: (dispatch) => (cookieManagementType, lang) => {
    if (lang) setCookie(COOKIES_KEYS.LANGUAGE, lang, { expires: 365 });

    const fbpCookieName = '_fbp';
    if (getCookie(fbpCookieName)) {
      removeCookie(fbpCookieName);
      removeCookie(fbpCookieName, { path: '/', domain: getRootDomain() });
    }

    setLocalStorageValue(
      LOCAL_STORAGE_KEYS.COOKIE_MANAGEMENT_TYPE,
      cookieManagementType
    );

    dispatch({
      type: SET_COOKIE_MANAGEMENT_TYPE,
      payload: cookieManagementType
    });

    dispatch({
      type: SET_CUSTOMIZE_COOKIE,
      payload: false
    });
  },
  [UPDATE_COOKIE_MANAGEMENT_TYPE]: (dispatch) => (
    cookieManagementType,
    cookieTypes
  ) => {
    dispatch({
      type: SET_SHOW_COOKIE_MANAGEMENT_ON_COOKIE_DECLARATION_PAGE,
      payload: true
    });

    if (cookieManagementType) {
      removeOptionalCookies(cookieManagementType, cookieTypes);
      setLocalStorageValue(LOCAL_STORAGE_KEYS.COOKIE_MANAGEMENT_TYPE, null);

      setLocalStorageValue(
        LOCAL_STORAGE_KEYS.PREV_COOKIE_MANAGEMENT_TYPE,
        cookieManagementType
      );

      dispatch({
        type: SET_COOKIE_MANAGEMENT_TYPE,
        payload: null
      });

      dispatch({
        type: UPDATE_COOKIE_MANAGEMENT_TYPE,
        payload: cookieManagementType
      });
    }
  }
};
