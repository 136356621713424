import { useConfig, useGeneralRoutes } from '@namespace/cms';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import appInIframe from '@namespace/helpers/src/utils/appInIframe';
import { IS_WIDGET } from '@namespace/widget';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { EmptyState } from '@alf/uikit';
import { EventsLineHeadGroups, EventsLineTitle, Line } from '../../common';
import { useActiveHeadGroups } from '../../hooks/useActiveHeadGroups';
import { useFetchEvents } from '../../hooks/useFetchEvents';
import { useGetPathParams } from '../../hooks/useGetPathParams';
import { useRemoveLine } from '../../hooks/useRemoveLine';
import { useEventsByIdsToShow } from '../../store';
import { LINE_KEY, MODE } from '../../constants';
import EventsLineHeader from '../../common/EventsLineHeader';
import AllPreMatchSportButton from '../../common/AllPreMatchSportButton';
import BreadCrumbs from '../../common/BreadCrumbs';
import styles from './index.module.css';

const LiveEvents = ({ id }) => {
  const { config } = useConfig(id);
  const {
    isHeadGroupsSortingEnabled,
    isShowEventGroup,
    activeHeadGroupsAmount,
    isDisplayHeadMarkets = true
  } = config;
  const { activeSport } = useGetPathParams(MODE.IN_PLAY);
  const { sportId, sportName = '', sportSlug, eventCount } = activeSport || {};
  const lineKey = `${LINE_KEY.LIVE}-${sportId}`;
  const { isEventsLoading, isLoadingMore, loadMoreEvents } = useFetchEvents({
    lineKey,
    serviceId: MODE.IN_PLAY,
    sportId
  });
  const { t } = useI18n();
  const { home } = useGeneralRoutes();
  const inIframe = appInIframe() && IS_WIDGET;

  const eventsToShow = useEventsByIdsToShow(lineKey);
  const [activeHeadGroups, setActiveHeadGroups] = useActiveHeadGroups(
    isHeadGroupsSortingEnabled,
    activeHeadGroupsAmount,
    sportId,
    MODE.IN_PLAY
  );
  useRemoveLine(lineKey);

  if (!activeSport || isEmpty(activeSport)) {
    return (
      <EmptyState
        text={t('web.sports.noSport')}
        Link={LocalizedLink}
        linkPath={home}
        linkText={t('home')}
      />
    );
  }

  // Add key to force component unmount on sport change
  return (
    <div key={sportSlug} className={clsx([!inIframe && styles.container])}>
      <EventsLineHeader
        borderBottom={true}
        isCollapsible={false}
        className={styles.wrapper}
      >
        <EventsLineTitle
          title={`${sportName} (${eventCount})`}
          sportId={sportId}
        />
        <EventsLineHeadGroups
          serviceId={MODE.IN_PLAY}
          sport={{ sportId }}
          isHeadGroupsSortingEnabled={true}
          activeHeadGroups={activeHeadGroups}
          setActiveHeadGroups={setActiveHeadGroups}
          isDisplayHeadMarkets={isDisplayHeadMarkets}
        />
      </EventsLineHeader>
      <div className={styles.lineWrapper}>
        <Line
          id={id}
          isShowEventGroup={isShowEventGroup}
          isEventsLoading={isEventsLoading}
          fetchEvents={loadMoreEvents}
          isLoadingMore={isLoadingMore}
          sportSlug={sportSlug}
          activeHeadGroups={activeHeadGroups}
          isCollapsibleTournaments={true}
          lineKey={lineKey}
          isShowEmpty={true}
          showBorderIfEmpty={true}
          isLiveEvents={true}
          eventsToShow={eventsToShow}
        />
        <AllPreMatchSportButton sport={activeSport} />
      </div>
      <BreadCrumbs
        type="inprogress"
        sportName={sportName}
        sportSlug={sportSlug}
      />
    </div>
  );
};

export default LiveEvents;
