export const types = {
  SET_BETSLIP_DATA: 'SET_BETSLIP_DATA',
  SET_ODDS_TYPE: 'SET_ODDS_TYPE',
  SET_BETSLIP_STATUS: 'SET_BETSLIP_STATUS',
  SET_ERRORS: 'SET_ERRORS',
  INCREMENT_OUTCOME_CLICK_COUNTER: 'INCREMENT_OUTCOME_CLICK_COUNTER',
  SHOW_PREORDER: 'SHOW_PREORDER', // show preorder only after click on button
  SET_FAST_BET: 'SET_FAST_BET',
  ONE_CLICK_BET: 'ONE_CLICK_BET',
  ONE_CLICK_BET_PUSH_STAKE: 'ONE_CLICK_BET_PUSH_STAKE',
  OPEN_BETSLIP_SETTINGS: 'OPEN_BETSLIP_SETTINGS',
  OPEN_BETSLIP_TAB: 'OPEN_BETSLIP_TAB',

  ACCEPT_BETSLIP: 'ACCEPT_BETSLIP',

  ADD_TO_STORE: 'ADD_TO_STORE',
  DELETE_FROM_STORE: 'DELETE_FROM_STORE',
  CLEAN_STORE: 'CLEAN_STORE',
  SET_STORE_ACCEPT: 'SET_STORE_ACCEPT',
  TAKE_STAKE_INFO: 'TAKE_STAKE_INFO',
  SET_TYPE_CHANGES: 'SET_TYPE_CHANGES',
  FETCH_STORE: 'FETCH_STORE',
  MAKE_BET: 'MAKE_BET',
  SET_STORE_TYPE: 'SET_STORE_TYPE',
  MAKE_PREORDER: 'MAKE_PREORDER',
  FIX_SYSTEM: 'FIX_SYSTEM',
  CHECK_OUTCOME: 'CHECK_OUTCOME',
  SET_STORE_AMOUNT: 'SET_STORE_AMOUNT',
  SWITCH_FREE_BET: 'SWITCH_FREE_BET',
  SET_BETSLIP_OPEN: 'SET_BETSLIP_OPEN',
  PUSH_VIP_PRICES: 'PUSH_VIP_PRICES',
  PUSH_VIP_MAX: 'PUSH_VIP_MAX',
  PUSH_VIP_STAKE: 'PUSH_VIP_STAKE'
};
