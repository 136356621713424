import { pathOr } from 'ramda';
import { shallow } from 'zustand/shallow';
import { createSelector } from 'reselect';
import { useSportsbookSelector } from '../../../../../../store';
import { SERVING_SPORTS } from '../../../../../../constants';

export const selectIsParticipantServing = (eventId, participantIndex) =>
  createSelector(pathOr(null, ['events', eventId]), (event) => {
    const { eventServing, participants, sportId } = event;
    const { participantId } = participants[participantIndex];

    return (
      SERVING_SPORTS.includes(sportId) && participantId === Number(eventServing)
    );
  });

export const useIsParticipantServing = ({ eventId, participantIndex }) =>
  useSportsbookSelector(
    selectIsParticipantServing,
    [eventId, participantIndex],
    shallow
  );
