import { pathOr } from 'ramda';
import { shallow } from 'zustand/shallow';
import { createSelector } from 'reselect';
import { useSportsbookSelector } from '../../../../store';
import { getIsSummariesScoreboard } from '../../../../../utils';

export const selectIsSummariesScoreboard = (eventId) =>
  createSelector(pathOr(null, ['events', eventId]), ({ scoreboard }) => {
    return getIsSummariesScoreboard(scoreboard);
  });

export const useIsSummariesScoreboard = (eventId) =>
  useSportsbookSelector(selectIsSummariesScoreboard, [eventId], shallow);
