/* eslint-disable camelcase */
import { camelizeKeys } from 'humps';
import { uniqBy, isEmpty } from 'lodash';
import { socketSend } from '@namespace/socket';
import { getGamesByLaunchIds } from '@namespace/games/src/hooks/api';
import { types } from '../types';
import getExpressDay from '../../api/getExpressDay';
import getBetsHistory from '../../api/getBetsHistory';
import getBetDetails from '../../api/getBetDetails';

import getCasinoGameIds from '../../api/getCasinoGamesGroup';
import { normalizeBets } from '../normalize';
import getCasinoRounds from '../../api/getCasinoRounds';
import getCasinoGameRounds from '../../api/getCasinoGameRounds';

export default {
  [types.ADD_EXPRESS]: () => ({ id, betslipKey }) => {
    socketSend({
      cmd: 'do_proto_v1',
      data: {
        mutation: {
          [betslipKey]: {
            pushMultiple: {
              variables: {
                id
              }
            }
          }
        }
      }
    });
  },
  [types.FETCH_EXPRESS_DAY]: (dispatch) => async () => {
    const { express = [] } = await getExpressDay();

    const { ids, data, coefs } = express.reduce(
      (acc, { express_day_id, events }) => ({
        ids: [...acc.ids, express_day_id],
        data: {
          ...acc.data,
          [express_day_id]: {
            express_day_id,
            events
          }
        },
        coefs: {
          ...acc.coefs,
          [express_day_id]: Number(
            events
              .reduce((memo, { outcome_coef }) => memo * outcome_coef, 1)
              .toFixed(2)
          )
        }
      }),
      {
        ids: [],
        data: {},
        coefs: {}
      }
    );
    const [id] = ids;

    dispatch({
      type: types.SET_EXPRESS_DAY,
      payload: {
        ids,
        data,
        coefs,
        id
      }
    });
  },
  [types.FETCH_BETS]: (dispatch) => async (filters) => {
    dispatch({
      type: types.SET_BETS_FETCHING,
      payload: true
    });
    try {
      const { elements = [], totalCount = 0 } = await getBetsHistory(
        filters,
        socketSend
      );
      if (filters.page > 1) {
        dispatch({
          type: types.SET_BETS,
          payload: { elements, totalCount, reset: false }
        });
      } else {
        dispatch({
          type: types.SET_BETS,
          payload: { elements, totalCount, reset: true }
        });
      }
    } finally {
      dispatch({
        type: types.SET_BETS_FETCHING,
        payload: false
      });
    }
  },
  [types.FETCH_BET_DETAILS]: (dispatch) => async (
    betId,
    isMultiSystem = false
  ) => {
    dispatch({
      type: types.SET_BETS_FETCHING,
      payload: true
    });
    const details = await getBetDetails({
      betId,
      isMultiSystem,
      socketSend
    });

    if (!isMultiSystem) {
      dispatch({
        type: types.SET_BET_DETAILS,
        payload: {
          betId,
          betDetails: camelizeKeys(details && details?.list[0])
        }
      });
    } else {
      dispatch({
        type: types.SET_BET_DETAILS_MULTI_SYSTEM,
        payload: {
          betId,
          multiSystem: camelizeKeys((details && details.list[0]) || {})
        }
      });
    }
    dispatch({
      type: types.SET_BETS_FETCHING,
      payload: false
    });
  },
  [types.UPDATE_BETS_ITEM]: (dispatch) => async (betId) => {
    const details = await getBetDetails({
      betId,
      socketSend
    });
    dispatch({
      type: types.UPDATE_BETS_ITEM,
      payload: details.list || []
    });
  },
  [types.FETCH_BETS_CASINO_HISTORY]: (dispatch) => async (
    filter,
    pageLimit = 10
  ) => {
    dispatch({
      type: types.SET_BETS_FETCHING,
      payload: true
    });

    const gameIds = await getCasinoGameIds({
      ...filter
    });

    let launchResult = { games: [], providers: [] };

    if (!isEmpty(gameIds)) {
      launchResult = await getGamesByLaunchIds(gameIds, true);
    }

    const { games, providers } = launchResult;

    const gamesProvidersData = games?.map((game) => {
      const provider = providers.find(
        (item) => item.providerId === game.providerId
      );
      return {
        ...game,
        gameId: Number(game.launchGameId),
        ...provider
      };
    });

    const gameRound = await getCasinoRounds({
      ...filter
    });

    const roundData = gameRound.map((round) => {
      const result = gamesProvidersData.find(
        (item) => item.gameId === round.gameId
      );

      return {
        roundDt: new Date(round.date).getTime(),
        ...round,
        ...result
      };
    });

    const newData = {
      casinoBetsHistory: roundData,
      roundData,
      providersName: uniqBy(
        gamesProvidersData.map(({ providerName, serviceId: providerId }) => {
          return { label: providerName, value: providerId };
        }),
        'value'
      ),
      isEmptyBets: roundData.length < filter.limit
    };

    const indexedPageData = await normalizeBets(
      newData.casinoBetsHistory.map((pageItem, index) => ({
        ...pageItem,
        id: `card-${index}-${pageItem.id}-${pageItem.roundDt}`
      }))
    );

    const page = filter?.page;
    const limit = filter?.limit;
    const isClearData = filter?.page === 1;

    dispatch({
      type: types.SET_CASINO_BETS_HISTORY,
      payload: {
        newData,
        page,
        limit,
        pageLimit,
        isClearData,
        indexedPageData
      }
    });
  },
  [types.GET_CASINO_GAME_ROUNDS]: (dispatch) => async (
    filterDate,
    gameId,
    page = 1,
    pageLimit = 20
  ) => {
    dispatch({
      type: types.SET_BETS_FETCHING,
      payload: true
    });

    const data = await getCasinoGameRounds({
      ...filterDate,
      gameId,
      limit: pageLimit,
      page
    });

    const {
      games: [{ gameName }],
      providers: [{ providerName }]
    } = await getGamesByLaunchIds([gameId], true);

    const isClearData = page === 1;

    dispatch({
      type: types.SET_CASINO_GAME_ROUNDS,
      payload: {
        isClearData,
        gameRounds: data,
        isShowMoreRounds: data.length < pageLimit,
        names: { gameName, providerName }
      }
    });
  },
  [types.FETCH_FILTER_DATA]: (dispatch) => async ({
    startDate,
    categoryId
  }) => {
    const gameIds = await getCasinoGameIds({
      startDate,
      categoryId
    });

    let launchResult = { games: [], providers: [] };

    if (!isEmpty(gameIds)) {
      launchResult = await getGamesByLaunchIds(gameIds, true);
    }

    const { games, providers } = launchResult;

    const providersName = providers.map(({ providerName, serviceId }) => {
      return {
        label: providerName,
        value: serviceId
      };
    });
    const gamesName = games.map(
      ({ gameName, launchGameId, providerId: searchId }) => {
        const provider = providers.find((item) => item.providerId === searchId);
        return {
          label: gameName,
          value: launchGameId,
          providerId: provider.serviceId
        };
      }
    );

    dispatch({
      type: types.SET_FILTER_DATA,
      payload: {
        filterData: {
          providersName,
          gamesName
        }
      }
    });
  }
};
