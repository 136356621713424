import { FORM_FACTORS_MAP } from '../../constants';

const updatePageType = (screenType) => {
  const defaultPageType = screenType;
  const pageType =
    defaultPageType === FORM_FACTORS_MAP.TABLET
      ? FORM_FACTORS_MAP.MOBILE
      : defaultPageType;
  const isMobile = defaultPageType === FORM_FACTORS_MAP.MOBILE;
  const isTablet = defaultPageType === FORM_FACTORS_MAP.TABLET;
  const isDesktop = defaultPageType === FORM_FACTORS_MAP.DESKTOP;

  return {
    pageType,
    // used for cases where we need to get some content,
    // like in CMS on tablet, with mobile page config,
    // we need to get tablet images in promotions or banner
    realPageType: defaultPageType,
    isMobile,
    isTablet,
    isDesktop
  };
};

export default updatePageType;
