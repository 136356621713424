import { postAsJson, post } from '@namespace/helpers';
import { isString } from 'lodash';
import { camelizeKeys } from 'humps';

export const withdraw = ({ userId, ...rest }) => {
  return postAsJson(
    `/accounting/api/v1/users/${userId}/payments/withdraw`,
    rest
  ).then((data) => {
    const camelized = camelizeKeys(data);
    // We shouldn't camelize response
    // because we should send it to the payment system as we got it from the backend
    camelized.response = isString(data.response)
      ? data.response
      : { ...data.response };

    return {
      ...camelized,
      redirectMethod:
        camelized.redirectMethodNew ||
        camelized.redirectMethod ||
        camelized.redirect_method
    };
  });
};

export const deposit = ({ userId, ...rest }) => {
  return post({
    url: `/accounting/api/v1/users/${userId}/payments/deposit`,
    body: rest,
    mode: 'cors',
    timeout: 15000
  }).then((data) => {
    const camelized = camelizeKeys(data);
    // We shouldn't camelize response
    // because we should send it to the payment system as we got it from the backend
    camelized.response = isString(data.response)
      ? data.response
      : { ...data.response };

    return {
      ...camelized,
      redirectMethod:
        camelized.redirectMethodNew ||
        camelized.redirectMethod ||
        camelized.redirect_method
    };
  });
};
