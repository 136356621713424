// eslint-disable-next-line @alf/loop-deps
import {
  ACCOUNTING_BONUS_TYPES,
  BONUS_STATE_TYPES,
  BONUS_SYSTEM_BONUS_TYPE,
  OTHER_BONUS_TYPES
} from '@namespace/bonuses'; // FIX ME
import {
  BONUS_PAYMENT_SERVICE_CURRENCY,
  LINE_VIEW_TYPES,
  SHORT_REGISTER_USER_DATA,
  USER_ROLES
} from '../constants';
import configureWallets from '../utils/configureWallets';
import normalizeAccountStatuses from '../utils/normalizeAccountStatuses';
import parseBTagParam from '../utils/parseBTagParam';
import useVipUser from '../utils/useVipUser';
import { types } from './types';

export { types };

export const initialState = {
  role: USER_ROLES.GUEST,
  settings: {
    bTag: {},
    lineViewType: LINE_VIEW_TYPES.BUTTON
  },
  counters: {
    messagesCount: 0,
    [BONUS_STATE_TYPES[[ACCOUNTING_BONUS_TYPES.REAL_MONEY]]]: 0,
    [BONUS_STATE_TYPES[[ACCOUNTING_BONUS_TYPES.FREE_SPIN]]]: 0,
    [BONUS_STATE_TYPES[[ACCOUNTING_BONUS_TYPES.RISK_FREE]]]: 0,
    [BONUS_STATE_TYPES[[ACCOUNTING_BONUS_TYPES.ALL]]]: 0,
    [BONUS_STATE_TYPES[[OTHER_BONUS_TYPES.JACKPOT_WINS]]]: 0,
    [BONUS_STATE_TYPES[[OTHER_BONUS_TYPES.ACCOUNTING_AND_OTHER_BONUSES]]]: 0,
    [BONUS_STATE_TYPES[BONUS_SYSTEM_BONUS_TYPE]]: 0
  },
  isBonusCountersLoaded: false,
  wallets: [],
  currencies: [],
  activeWallet: {
    baseBalance: 0, // actual balance of active wallet as we got from backend
    bonusBalance: null, // prewager bonus balance we got from bonus system. null - means bonus wallet does not exist
    deposit: null // Initially, the deposit = null, in order to understand when the active wallet was loaded. sum of baseBalance and bonusBalance. If bonusBalance === null -> deposit === baseBalance
  },
  userOptions: {
    sourceOfNotifications: [],
    waitingForOnboarding: [],
    quickbet: true
  },
  isDefaultRegistratedUser: false,
  isLoggingOut: false,
  paymentServices: [],
  bankAccounts: [],
  verificationMessageType: '',
  isShowQuickDeposit: false,
  pepAttributes: [],
  isBonusHunter: false,
  accountStatuses: {},
  userInfoLoaded: false
};

export const reducers = {
  [types.SET_USER_INFO_LOADED]: (state, userInfoLoaded) => ({
    ...state,
    userInfoLoaded
  }),
  [types.SET_USER]: (state, payload) => {
    const {
      account = state.account || {},
      wallets = state.wallets || [],
      services = state.services || [],
      userOptions = state.userOptions || {},
      statuses = state.statuses || {},
      isLoggedIn = true,
      ...user
    } = payload;

    if (!isLoggedIn) {
      return { ...initialState, userInfoLoaded: true };
    }

    const { accountStatus = [], ...restAccount } = account;

    return {
      ...state,
      ...user,
      ...restAccount,

      accountStatuses: normalizeAccountStatuses(accountStatus),

      ...statuses,
      userOptions: { ...state.userOptions, ...userOptions },
      // remove because we set active wallet again after payment systems config loading
      // and this cause a blinking of user user balance
      // activeWallet,
      wallets: wallets.filter(
        ({ currency }) => currency !== BONUS_PAYMENT_SERVICE_CURRENCY
      ),
      paymentServices: services
        .filter(
          ({ public: isPublic, currency }) =>
            !isPublic && currency !== BONUS_PAYMENT_SERVICE_CURRENCY
        )
        .map((item) =>
          item.serviceId === 9020
            ? {
                ...item,
                serviceName: 'Visa/Mastercard'
              }
            : item
        ), // hardcoded for safecharge in deposit history

      role: USER_ROLES.USER,
      userInfoLoaded: true,
      error: null,
      isDefaultRegistratedUser:
        account?.dateOfBirth === SHORT_REGISTER_USER_DATA.birthdate &&
        account?.question === SHORT_REGISTER_USER_DATA.secretQuestion &&
        account?.firstName === SHORT_REGISTER_USER_DATA.firstName &&
        account?.lastName === SHORT_REGISTER_USER_DATA.lastName &&
        account?.title === SHORT_REGISTER_USER_DATA.gender,
      isVipUser: useVipUser(normalizeAccountStatuses(accountStatus))
    };
  },
  [types.UPDATE_ACCOUNT_STATUSES]: (state, payload) => ({
    ...state,
    accountStatuses: {
      ...state.accountStatuses,
      ...normalizeAccountStatuses(payload)
    }
  }),
  [types.LOGGING_OUT]: (state) => ({
    ...state,
    isLoggingOut: true
  }),
  [types.SET_USER_B_TAG]: (state, payload) => ({
    ...state,
    settings: {
      ...state.settings,
      bTag: parseBTagParam(payload) || {}
    }
  }),
  [types.SET_DOCUMENTS]: (state, documents) => ({
    ...state,
    documents
  }),
  [types.SET_PHONE]: (state, phoneNumber) => ({
    ...state,
    phoneNumber
  }),
  [types.SET_CHANGE_USER]: (state, data) => ({ ...state, ...data }),
  [types.SET_NOTIFICATIONS]: (state, sourceOfNotifications) => ({
    ...state,
    userOptions: {
      ...state.userOptions,
      sourceOfNotifications
    }
  }),
  [types.SET_ACTIVE_WALLET]: (state, newWalletHash) => {
    const { wallets, activeWallet } = state;
    const { bonusBalance, walletHash: activeWalletHash } = activeWallet;

    const newActiveWalletIdx = wallets.findIndex(
      ({ walletHash }) => walletHash === newWalletHash
    );

    const oldActiveWalletIdx = activeWallet
      ? wallets.findIndex(({ walletHash }) => {
          return walletHash === activeWalletHash;
        })
      : -1;

    const newWallets = [...wallets];

    if (newActiveWalletIdx !== -1) {
      // list of wallets could be not full
      newWallets[newActiveWalletIdx].isActive = 1;
    }

    if (
      oldActiveWalletIdx !== -1 &&
      oldActiveWalletIdx !== newActiveWalletIdx
    ) {
      newWallets[oldActiveWalletIdx] = activeWallet;
      newWallets[oldActiveWalletIdx].isActive = 0;
      newWallets[oldActiveWalletIdx].deposit =
        newWallets[oldActiveWalletIdx].baseBalance;
    }

    const newActiveWallet = newWallets[newActiveWalletIdx] || {};
    const activeWalletDeposit = newActiveWallet.deposit || 0;

    return {
      ...state,
      activeWallet: {
        ...newActiveWallet,
        baseBalance: newActiveWallet.deposit || 0,
        deposit:
          bonusBalance !== null
            ? Number(activeWalletDeposit) + Number(bonusBalance)
            : activeWalletDeposit,
        bonusBalance
      },
      wallets: newWallets
    };
  },
  [types.UPDATE_WALLET_BALANCE]: (state, { walletHash, deposit = 0 }) => {
    const { wallets, activeWallet } = state;
    const { bonusBalance } = activeWallet;
    const walletIdxForUpdate = wallets.findIndex(
      ({ walletHash: wHash }) => wHash === walletHash
    );
    const newWallets = [...wallets];
    let newActiveWallet = activeWallet;

    if (walletIdxForUpdate !== -1) {
      newWallets[walletIdxForUpdate] = {
        ...newWallets[walletIdxForUpdate],
        baseBalance: deposit,
        deposit:
          bonusBalance !== null
            ? Number(deposit) + Number(bonusBalance)
            : deposit,
        bonusBalance
      };
    }

    if (activeWallet.walletHash === walletHash) {
      newActiveWallet = {
        ...newActiveWallet,
        baseBalance: deposit,
        deposit:
          bonusBalance !== null
            ? Number(deposit) + Number(bonusBalance)
            : deposit,
        bonusBalance
      };
    }

    return {
      ...state,
      wallets: newWallets,
      activeWallet: newActiveWallet
    };
  },
  [types.SET_MESSAGES_COUNT]: (state, messagesCount) => ({
    ...state,
    counters: {
      ...state.counters,
      messagesCount
    }
  }),
  [types.CONFIGURE_WALLETS]: (state, paymentSystems) => ({
    ...state,
    ...configureWallets(state.wallets, paymentSystems)
  }),
  [types.SET_WALLETS]: (state, payload) => {
    const { wallets = [], paymentSystems } = payload;
    return {
      ...state,
      ...configureWallets(wallets, paymentSystems)
    };
  },
  [types.SET_COUNTERS]: (state, counters) => {
    return {
      ...state,
      counters: { ...state.counters, ...counters }
    };
  },
  [types.SET_VERIFICATION_MESSAGE]: (state, { verificationMessageType }) => ({
    ...state,
    verificationMessageType
  }),
  [types.SET_BANK_ACCOUNTS]: (state, { accounts }) => ({
    ...state,
    bankAccounts: accounts
  }),
  [types.SET_ADDRESS_FIELDS]: (
    state,
    { address = '', city = '', zip = '' }
  ) => ({
    ...state,
    adress: address,
    city,
    zip
  }),
  [types.SET_QUICK_DEPOSIT]: (state, isShowQuickDeposit) => ({
    ...state,
    isShowQuickDeposit
  }),
  [types.SET_LINE_VIEW]: (state, localStorage) => ({
    ...state,
    settings: {
      ...state.settings,
      lineViewType: localStorage
    }
  }),
  [types.SET_BONUS_WALLET]: (state, { deposit }) => {
    const { activeWallet } = state;
    return {
      ...state,
      activeWallet: {
        ...activeWallet,
        deposit:
          deposit !== null
            ? Number(activeWallet.baseBalance || 0) + Number(deposit)
            : Number(activeWallet.baseBalance || 0),
        bonusBalance: deposit
      }
    };
  },
  [types.SET_PEP_ATTRIBUTES]: (state, pepAttributes) => {
    return {
      ...state,
      pepAttributes
    };
  },
  [types.SET_IS_PHONE_VERIFIED]: (state, payload) => ({
    ...state,
    accountStatuses: {
      ...state.accountStatuses,
      isPhoneVerified: payload
    }
  }),
  [types.SET_ACCOUNT_STATUSES]: (state, payload) => ({
    ...state,
    accountStatuses: {
      ...state.accountStatuses,
      ...payload
    }
  }),
  [types.SET_IS_BONUS_COUNTERS_LOADED]: (state, isBonusCountersLoaded) => ({
    ...state,
    isBonusCountersLoaded
  }),
  [types.SET_WAITING_ONBOARDING]: (state, onboardingName) => {
    const { userOptions } = state;
    const { waitingForOnboarding = [] } = userOptions;

    return {
      ...state,
      userOptions: {
        ...userOptions,
        waitingForOnboarding: waitingForOnboarding.filter(
          (type) => type !== onboardingName
        )
      }
    };
  },
  [types.SET_FAST_BET]: (state, quickbet) => ({
    ...state,
    userOptions: {
      ...state.userOptions,
      quickbet
    }
  }),
  [types.SET_NEW_POLICY]: (state) => ({
    ...state,
    accountStatuses: { ...state.accountStatuses, policyBlock: false }
  })
};
