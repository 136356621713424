import { useContext, useEffect, useCallback } from 'react';
import { UserContext } from '@namespace/user';
import { MailBoxContext } from '../store/mailBox/context';
import { getInboxMessages } from '../api/mailBox';

export const useGetMessages = ({ skip } = {}) => {
  const [, { SET_INBOX_MESSAGES }] = useContext(MailBoxContext);
  const [{ id: userId }, { SET_COUNTERS }] = useContext(UserContext);

  const fetchAndUpdateInboxMessages = useCallback(async () => {
    try {
      const { list: messages, new: messagesCount } = await getInboxMessages(
        userId
      );

      SET_INBOX_MESSAGES(messages);
      SET_COUNTERS(messagesCount);
    } catch (error) {
      console.error(error);
    }
  }, [SET_INBOX_MESSAGES, SET_COUNTERS, userId]);

  useEffect(() => {
    if (userId && !skip) {
      fetchAndUpdateInboxMessages(userId);
    }
  }, [userId, fetchAndUpdateInboxMessages, skip]);
};
