const LogoComponent = ({ src, media, className = '' }) => (
  <picture>
    <source srcSet={src} media={media} />
    <img
      className={className}
      src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
      alt="site-logo"
    />
  </picture>
);

export default LogoComponent;
