import { useContext } from 'react';
import { ThemesContext } from '../../store/context';

const useGetThemeConfig = (componentName) => {
  const state = useContext(ThemesContext);
  const [{ elementsConfig = {} }] = state || [{}];

  return elementsConfig?.[componentName] || {};
};

export default useGetThemeConfig;
