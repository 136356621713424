import {
  outcomesUpdate,
  marketInsert,
  marketUpdate,
  marketDelete,
  marketSuspend
} from './markets';
import {
  eventInsert,
  eventUpdate,
  eventUpdateRts,
  eventSuspend,
  eventTimerSet,
  eventServing,
  eventInProgress,
  eventFinish,
  eventCancel,
  eventDelete
} from './event';
import { setEventsScore } from './score/setScore';
import { undoEventsScore } from './score/undoScore';

export const bulletReducersMap = {
  'outcome.update_list': outcomesUpdate,
  'market.update_coefs': outcomesUpdate,

  'market.insert': marketInsert,
  'market.update': marketUpdate,
  'market.delete': marketDelete,

  'market.suspend': (state, data) =>
    marketSuspend(state, { data, isSuspended: true }),
  'market.suspend_list': (state, data) =>
    marketSuspend(state, { data, isSuspended: true }),
  'market.unsuspend': (state, data) =>
    marketSuspend(state, { data, isSuspended: false }),
  'market.unsuspend_list': (state, data) =>
    marketSuspend(state, { data, isSuspended: false }),

  'event.insert': eventInsert,
  'event.result': eventUpdate,
  'event.update': eventUpdate,
  'event.update_result': eventUpdate,
  'event.rts_update': eventUpdateRts,

  'event.suspend': (state, data) =>
    eventSuspend(state, { data, isSuspended: true }),
  'event.unsuspend': (state, data) =>
    eventSuspend(state, { data, isSuspended: false }),

  'event.timer_set': eventTimerSet,
  'event.serving': eventServing,
  'event.set_inprogress': eventInProgress,
  'event.set_finished': eventFinish,
  'event.set_canceled': eventCancel,
  'event.delete': eventDelete,

  // TODO: maybe can be removed, need to check if this messages is needed now BLD-101 by @y.zaiats
  'event.set_score': setEventsScore,
  'event.undo_score': undoEventsScore
};
