import { useGeneralRoutes } from '@namespace/cms';
import { AText, Box } from '@alf/uikit';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { Icon } from '@namespace/icons2';
import styles from './index.module.css';

const ExpressDayLink = () => {
  const { multipleDay } = useGeneralRoutes();
  const { t } = useI18n();

  return (
    <Box className={styles.container} data-role="express-day-wrapper">
      <LocalizedLink to={multipleDay} className={styles.wrapper}>
        <Box
          className={styles.header}
          align="center"
          justify="between"
          bottom="xs"
        >
          <Box
            className={styles.titleWrapper}
            direction="column"
            justify="center"
          >
            <AText
              className={styles.title}
              size="l"
              breed="B"
              color="txt_color_4"
            >
              {t('expressDay.teaser.title')}
            </AText>
          </Box>
          <Box
            data-role="express-day-btn"
            className={styles.link}
            align="center"
            justify="center"
            padding="s m"
          >
            <AText color="btn_primary_txt_color_4" size="m" breed="BU">
              {t('expressDay.teaser.link')}
            </AText>
            <Icon
              name="icons/general/nav/go-to"
              color="btn_primary_txt_color_4"
              size="s"
              className={styles.icon}
            />
          </Box>
        </Box>
        <AText
          className={styles.content}
          size="m"
          breed="R"
          color="txt_color_4"
        >
          {t('expressDay.teaser.content')}
        </AText>
      </LocalizedLink>
    </Box>
  );
};

export default ExpressDayLink;
