import { forwardRef } from 'react';
import { HTMLSnippet } from '@namespace/snippets';
import styles from './index.module.css';

const PromoTeaser = forwardRef(
  ({ className = '', snippetId, handleClickOnMove, gap }, ref) => (
    <div
      data-role={`promo-teaser-${snippetId}`}
      ref={ref}
      onClick={handleClickOnMove}
      className={`${styles.teaser} ${className}`}
      style={gap && { marginRight: gap }}
    >
      <HTMLSnippet snippetId={snippetId} />
    </div>
  )
);

export default PromoTeaser;
