import { isEmpty } from 'lodash';
import { AText, Box } from '@alf/uikit';
import { LOCAL_STORAGE_KEYS } from '@namespace/helpers';
import { LocalizedLink } from '@namespace/i18n';
import { useComponentConfig } from '@namespace/cms';
import { useSetJackpot } from '../../../../hooks';
import { useJackpot } from '../../../../context/selectors';
import { useTotalJackpotFormatter } from '../../../../utils';
import styles from './index.module.css';

const JackpotGeneral = ({ className = '' }) => {
  const {
    showCents = false,
    globalJackpotImage,
    globalJackpotAction
  } = useComponentConfig();

  const jackpot = useJackpot();
  const { sum = '', currency = '' } = jackpot || {};

  const formatTotal = useTotalJackpotFormatter(currency, showCents);
  const stepMultiplier = showCents ? 0.01 : 1;

  const textRef = useSetJackpot({
    initialValue: Number(sum) || 0,
    step: Math.round(Math.random() * 5) * stepMultiplier,
    formatTotal,
    delay: 150,
    jackpotStorageKey: LOCAL_STORAGE_KEYS.JACKPOT_GENERAL
  });

  if (isEmpty(jackpot)) {
    return null;
  }

  return (
    <Box
      className={`${styles.wrapper} ${className}`}
      direction="column"
      align="center"
    >
      <LocalizedLink to={globalJackpotAction} className={styles.link}>
        <img className={styles.img} src={globalJackpotImage} alt="jackpot" />
        <AText
          align="center"
          breed="BU"
          className={styles.total}
          color="txt_color_4"
        >
          <span ref={textRef} />
        </AText>
      </LocalizedLink>
    </Box>
  );
};

export default JackpotGeneral;
