import { useContext } from 'react';
import { UserContext, USER_ROLES, useUserInfoLoaded } from '@namespace/user';

const PrivateContent = ({ children, fallback = null }) => {
  const [user] = useContext(UserContext);
  const { userInfoLoaded } = useUserInfoLoaded();
  const { role } = user;

  if (userInfoLoaded && role !== USER_ROLES.USER) {
    return fallback;
  }

  return children;
};

export default PrivateContent;
