import { useContext, useState, useCallback } from 'react';
import { SuccessTextComponent } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { StepperContext } from '@namespace/common';
import EnterSecretQuestion from '../EnterSecretQuestion';
import CancelButton from '../CancelButton';
import { forgotPasswordSendEmail } from '../../api';
import styles from './index.module.css';

const CheckSecretQuestionSendEmail = ({ onGoFirst, onStepError }) => {
  const {
    stepper: {
      formData: { question = '', answer = '', token = '' }
    }
  } = useContext(StepperContext);

  const { t } = useI18n();

  const [successText, setSuccessText] = useState(null);

  const handleSubmit = useCallback(
    async ({ secretAnswer }) => {
      onStepError({ errorCode: '' });
      await forgotPasswordSendEmail({
        question,
        answer: secretAnswer,
        token
      })
        .then(
          ({ response }) =>
            response && setSuccessText('passwordRecovery.sendEmail.success')
        )
        .catch(({ errorCode: stepErrorCode }) =>
          onStepError({ error: true, errorCode: stepErrorCode })
        );
    },
    [question, token, setSuccessText, onStepError]
  );

  return (
    <div className={styles.step}>
      {successText ? (
        <SuccessTextComponent text={t(successText)} />
      ) : (
        <>
          <EnterSecretQuestion
            secretAnswer={answer}
            secretQuestion={question}
            onSuccess={handleSubmit}
          />
          <CancelButton onCancel={onGoFirst} />
        </>
      )}
    </div>
  );
};

export default CheckSecretQuestionSendEmail;
