import { useCallback, useContext } from 'react';
import { appInIframe, window } from '@namespace/helpers';
import { WidgetContext } from '../../store/widget/context';
import { CLIENT_TYPES, IS_WIDGET } from '../../constants';

const usePostMessage = () => {
  const [{ parentUrl, isBetHistoryPage }] = useContext(WidgetContext);

  const postMessage = useCallback(
    (event, data) => {
      if (IS_WIDGET && appInIframe()) {
        if (window.parent) {
          window.parent.postMessage(
            {
              event,
              data,
              client: isBetHistoryPage
                ? CLIENT_TYPES.BET_HISTORY_WIDGET
                : CLIENT_TYPES.WIDGET
            },
            parentUrl
          );
        } else {
          console.error('no parent in window');
        }
      }
    },
    [parentUrl, IS_WIDGET]
  );

  return { postMessage };
};

export default usePostMessage;
