import { useContext, useEffect } from 'react';
import { scrollTo } from '@namespace/helpers';
import { AText, Box } from '@alf/uikit';
import { Image } from '@namespace/icons2';
import { useGeneralRoutes } from '@namespace/cms';
import { StepperContext, Header } from '@namespace/common';
import { useI18n, LocalizedLink } from '@namespace/i18n';
import styles from './index.module.css';

const ErrorScreen = ({ header, itemClassNames = {} }) => {
  const { t } = useI18n();
  const { home } = useGeneralRoutes();
  const { stepper } = useContext(StepperContext);
  const { errorCode = '' } = stepper;
  const { stepperWrapperClassName, headerClassName } = itemClassNames;

  useEffect(() => {
    scrollTo();
  }, []);

  return (
    <Box
      direction="column"
      align="center"
      className={`${styles.wrapper} ${stepperWrapperClassName}`}
    >
      <Header
        header={header}
        className={`${styles.header} ${headerClassName}`}
      />
      <Box className={styles.container} direction="column" align="center">
        <Image name="general/error" className={styles.icon} />
        <AText
          className={`${styles.title} ${styles.error}`}
          size="l"
          breed="B"
          color="po_txt_color_1"
        >
          {t('stepper.error')}
        </AText>
        <AText
          color="po_txt_color_1"
          size="m"
          align="center"
          className={styles.message}
        >
          <AText component="span">{`${t('stepper.errorText')}: `}</AText>
          {t(`accountingMessages.${errorCode}`)}
        </AText>
        <LocalizedLink to={home} className={styles.homeLink}>
          <AText color="btn_quaternary_color_3" size="m" breed="BU">
            {t('stepper.homeLink')}
          </AText>
        </LocalizedLink>
      </Box>
    </Box>
  );
};

export default ErrorScreen;
