export class EventEmitter {
  events = {};

  trigger(eventName, ...args) {
    if (eventName in this.events) {
      for (const callback of this.events[eventName]) callback(...args);
    }
  }

  on(eventName, callback) {
    if (!(eventName in this.events)) {
      this.events[eventName] = [];
    }

    this.events[eventName].push(callback);
  }

  once(eventName, callback) {
    const onceWrapped = (...args) => {
      this.off(eventName, onceWrapped);

      callback(...args);
    };

    this.on(eventName, onceWrapped);
  }

  off(eventName, callback) {
    if (arguments.length > 1) {
      this.removeListener(eventName, callback);
    } else {
      this.removeAllListeners(eventName);
    }
  }

  removeListener(eventName, callback) {
    if (!(eventName in this.events)) {
      return;
    }

    this.events[eventName] = this.events[eventName].filter(
      (currentEvent) => currentEvent !== callback
    );

    if (this.events[eventName].length === 0) {
      delete this.events[eventName];
    }
  }

  removeAllListeners(eventName) {
    if (eventName in this.events) {
      delete this.events[eventName];
    }
  }
}
