import { Box } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { LocalizedLink } from '@namespace/i18n';
import { SUB_MENU_ICON_ITEMS } from '../../../constants';
import styles from './index.module.css';

const SecondarySubMenuItems = ({ items = [] }) =>
  items.map(({ id, link, title }) =>
    SUB_MENU_ICON_ITEMS.includes(id) ? (
      <Box data-role={id} key={id} align="center">
        <LocalizedLink to={link} className={styles.subIcons}>
          <Icon name={`icons/general/nav/${id}`} size="m" color="txt_color_2" />
        </LocalizedLink>
      </Box>
    ) : (
      <Box data-role={id} key={id}>
        <Box
          align="center"
          justify="between"
          left="m"
          right="m"
          className={styles.secondMenuItem}
        >
          <LocalizedLink className={styles.secondMenuLink} to={link}>
            {title}
          </LocalizedLink>
        </Box>
      </Box>
    )
  );

export default SecondarySubMenuItems;
