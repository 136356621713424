import { useContext, useCallback } from 'react';
import {
  setLocalStorageValue,
  removeLocalStorageItem,
  LOCAL_STORAGE_KEYS,
  window,
  document
} from '@namespace/helpers';
import useSupport from '@namespace/chats/src/hooks/useSupport';
import { UserContext } from '../../context';

const useLogout = (onSuccess) => {
  const [user, userActions] = useContext(UserContext);
  const { id } = user;
  const { FETCH_LOGOUT } = userActions;
  const { logout: supportChatLogout } = useSupport();

  const logout = useCallback(async () => {
    try {
      await supportChatLogout();
      if (window.zE) {
        await window.zE('messenger', 'logoutUser');
      }
      await FETCH_LOGOUT(id);
      onSuccess?.(id);
      removeLocalStorageItem(LOCAL_STORAGE_KEYS.MULTIBETSLIP_NUMBER_KEY);
      removeLocalStorageItem(LOCAL_STORAGE_KEYS.MULTIBETSLIP_NONEMPTY_KEY);
    } catch {
      // suppress error
    } finally {
      setLocalStorageValue(LOCAL_STORAGE_KEYS.HAS_BEEN_LOGGED_IN, true, {
        expires: 365
      });
      // necessary to get new PHPSESSID
      document.location.reload(true);
    }
  }, [FETCH_LOGOUT, id, onSuccess, supportChatLogout]);

  return { logout };
};

export default useLogout;
