import { useI18n } from '@namespace/i18n';
import { Icon } from '@namespace/icons2';
import { AText, Box } from '@alf/uikit';
import { AdvFilterButton } from '../../../../AdvancedFilter';
import styles from './index.module.css';

export const MenuHeader = ({
  title = '',
  subTitle = '',
  isLive = false,
  hideAdvFilter = false,
  classNames = {},
  dataRole
}) => {
  const { t } = useI18n();
  const {
    headerClassName = '',
    titleClassName = '',
    subTitleClassName = ''
  } = classNames;

  return (
    <Box
      data-role={dataRole && `${dataRole}-menu-header`}
      padding={isLive ? 's' : 'xs 0 xs s'}
      className={`${styles.header} ${headerClassName}`}
      justify="between"
    >
      <Box align="center">
        <AText breed="BU" size="xl" className={titleClassName}>
          {`${t(`sportsBook.sportsMenu.${title}`)}`}
        </AText>
        {subTitle && (
          <>
            &nbsp;
            <AText breed="RU" size="xl" className={subTitleClassName}>
              {t(`sportsBook.sportsMenu.${subTitle}`)}
            </AText>
          </>
        )}
      </Box>
      {isLive && (
        <Box
          align="center"
          className={styles.sportNameSecondHalf}
          padding="xs xs xs s"
        >
          <AText
            size="m"
            breed="BU"
            color="btn_crossection_txt_color_2"
            transform="uppercase"
          >
            {t('sports.sportsMenu.header.liveTitle')}
          </AText>
          <Icon
            className={styles.secondHalfIcon}
            name="icons/general/nav/go-to-circle"
            color="btn_crossection_txt_color_2"
            size="xs"
          />
        </Box>
      )}
      {!isLive && !hideAdvFilter && <AdvFilterButton showTitle={true} />}
    </Box>
  );
};
