import { useI18n } from '@namespace/i18n';
import { AText, Box, Button } from '@alf/uikit';
import DocumentStatusBar from '../../../common/DocumentStatusBar';
import styles from './index.module.css';

const EmailVerificationForm = ({ onClick, document }) => {
  const { t } = useI18n();

  return (
    <Box className={styles.formWrapper} direction="column">
      <DocumentStatusBar className={styles.formWrap} document={document} />
      <Box className={styles.message} direction="column" top="s">
        <AText size="m" color="po_txt_color_3">
          {t('personalOffice.documents.forms.emailVerification.message.text_0')}
        </AText>
        <AText size="m" color="po_txt_color_3">
          {t('personalOffice.documents.forms.emailVerification.message.text_1')}
        </AText>
        <Box component="ul" className={styles.list} direction="column">
          <AText component="li" size="m" color="po_txt_color_3">
            {`${t(
              'personalOffice.documents.forms.emailVerification.message.listItem_0'
            )}`}
          </AText>
          <AText component="li" size="m" color="po_txt_color_3">
            {`${t(
              'personalOffice.documents.forms.emailVerification.message.listItem_1'
            )}`}
          </AText>
        </Box>
        <AText
          size="m"
          color="po_txt_color_3"
          component="p"
          dangerouslySetInnerHTML={{
            __html: t(
              'personalOffice.documents.forms.emailVerification.message.text_2'
            )
          }}
        />
      </Box>
      <Button
        onClick={() => onClick()}
        type="submit"
        size="l"
        className={styles.button}
      >
        {t('personalOffice.documents.forms.emailVerification.ok')}
      </Button>
    </Box>
  );
};

export default EmailVerificationForm;
