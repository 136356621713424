import { createValidationRules } from '@alf/validators';
import { formComponentsMap as defaultComponentsMap } from '../componentsMap';
import { formValidationMap as defaultValidationMap } from '../validationMap';
import { FORM_COMPONENTS_TYPES } from '../../../constants';

const buildComponents = (componentsData = {}) => {
  const {
    components = [],
    componentsMap = {},
    validationMap = {},
    formProps = {}
  } = componentsData;
  const formComponentsMap = { ...defaultComponentsMap, ...componentsMap };
  const formValidationMap = { ...defaultValidationMap, ...validationMap };

  return components
    .filter(({ isActive = true }) => isActive)
    .map(({ type, name, validationTypes, disabled, fields = [], ...rest }) => {
      const { isDisabled: isAllFieldsDisabled } = formProps;
      const isDisabledField = disabled ?? isAllFieldsDisabled;
      const Field = formComponentsMap[type];

      if (Field) {
        if (type === FORM_COMPONENTS_TYPES.FORM_ROW && fields.length > 0) {
          return (
            <Field
              key={`field-${name || type}`}
              name={name}
              disabled={isDisabledField}
              formProps={formProps}
              {...rest}
            >
              {buildComponents({ ...componentsData, components: fields })}
            </Field>
          );
        }

        return (
          <Field
            key={`field-${name || type}`}
            name={name}
            disabled={isDisabledField}
            formProps={formProps}
            rules={createValidationRules(validationTypes, formValidationMap)}
            fields={fields}
            {...rest}
          />
        );
      }

      return null;
    });
};

export default buildComponents;
