import { useCallback } from 'react';
import { scrollTo } from '@namespace/helpers';
import { useHistory } from '@namespace/router';
import MenuItem from '../MenuItem';
import { useCyberSportTournament } from '../../store';

export const Category = ({
  categoryId,
  isActive,
  basePath,
  className = '',
  withIcon
}) => {
  const { categoryName, countryId, eventCount } = useCyberSportTournament(
    categoryId
  );
  const { push } = useHistory();

  const handleClick = useCallback(() => {
    push(`${basePath}/${categoryId}`);
    scrollTo();
  }, [basePath, categoryId]);

  return (
    <MenuItem
      key={categoryId}
      className={className}
      withCounter={true}
      name={categoryName}
      countryId={countryId}
      onClick={handleClick}
      count={eventCount}
      isActive={isActive}
      isCyberSport={true}
      withIcon={withIcon}
      dataAttr={{ 'data-role': `category-id-${categoryId}` }}
    />
  );
};
