import { Box, AText } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { window } from '@namespace/helpers';
import { Image } from '@namespace/icons2';
import styles from './index.module.css';

const CheckChangePasswordLink = () => {
  const { t } = useI18n();

  return (
    <Box direction="column" align="center" padding="0">
      <Box bottom="ms" justify="center">
        <Image name="general/success" className={styles.icon} alt="" />
      </Box>

      <AText breed="R" size="m" color="txt_color_3" className={styles.text}>
        {t(`passwordRecovery.sendChangePasswordLinkToEmailFirst`)}
      </AText>

      <AText breed="R" size="m" color="txt_color_3" className={styles.text}>
        {t(`passwordRecovery.sendChangePasswordLinkToEmailSecond`)}
      </AText>

      <AText
        transform="uppercase"
        breed="B"
        size="m"
        color="txt_color_3"
        className={`${styles.text} ${styles.supportLink}`}
        onClick={window.ShowSupport}
      >
        {t('passwordRecovery.forgotPassword.supportLink')}
      </AText>
    </Box>
  );
};

export default CheckChangePasswordLink;
