import { Fragment } from 'react';
import { path } from 'ramda';
import { useI18n } from '@namespace/i18n';
import { AText, Box } from '@alf/uikit';
import EventLink from './EventLink';
import TournamentLink from './TournamentLink';
import { TOP_EVENTS_GROWS_MAP } from './constants';
import Skelet from './common/Skelet';
import { useSportsbookStore } from '../../store';
import styles from './index.module.css';

const TopEventsMenu = ({
  presets = [],
  isShowImages = false,
  topEventsLoading = false
}) => {
  const { t } = useI18n();
  const isTopPreMatchLoaded = useSportsbookStore(
    path(['topPreMatchEventsDataState', 'isLoaded'])
  );
  const isTopLoaded = useSportsbookStore(
    path(['topEventsDataState', 'isLoaded'])
  );
  const isLoaded = isTopPreMatchLoaded || isTopLoaded;

  const topEventsCount = (presets?.length || 0) - 1;
  const growCount = TOP_EVENTS_GROWS_MAP.length - 1;
  let growNumber =
    topEventsCount > growCount - 1 ? growCount - 1 : topEventsCount;
  let growsMap = TOP_EVENTS_GROWS_MAP[growNumber];

  if (isLoaded && !topEventsLoading && !presets?.length) {
    return null;
  }

  return (
    <Box className={styles.container}>
      <Box
        align="center"
        padding="s"
        className={styles.header}
        data-role="top-events-header"
      >
        <AText
          color="sm_txt_color_4"
          breed="BU"
          size="xl"
          className={styles.headerText}
        >
          {t('sportsbook.topEvents.header')}
        </AText>
      </Box>
      <Box
        data-role="top-events-container"
        flexGrow={1}
        className={styles.eventsWrapper}
      >
        {topEventsLoading || !isLoaded ? (
          <Skelet />
        ) : (
          presets?.length > 0 &&
          presets.map((presetData, i) => {
            const {
              events = [],
              tournaments,
              icon,
              presetId,
              translation,
              _id
            } = presetData;

            const growIndex = i < growCount - 1 ? i : i % growCount;

            growNumber =
              i - Math.trunc(topEventsCount / growCount) * growCount < 0
                ? growCount - 1
                : topEventsCount % growCount;

            growsMap = TOP_EVENTS_GROWS_MAP[growNumber];

            return (
              <Fragment key={`${presetId}_${_id}`}>
                {events.length === 1 && !tournaments.length ? (
                  <EventLink
                    growsMap={growsMap}
                    growIndex={growIndex}
                    isShowImages={isShowImages}
                    event={events[0]}
                    icon={icon}
                    presetId={presetId}
                    translation={translation}
                  />
                ) : (
                  <TournamentLink
                    growsMap={growsMap}
                    growIndex={growIndex}
                    isShowImages={isShowImages}
                    icon={icon}
                    presetId={presetId}
                    translation={translation}
                  />
                )}
              </Fragment>
            );
          })
        )}
      </Box>
    </Box>
  );
};

export default TopEventsMenu;
