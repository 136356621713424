import { Box } from '@alf/uikit';
import { Loader } from '@namespace/common';
import styles from './index.module.css';
import useUserInfoLoaded from '../../hooks/useUserInfoLoaded';

const PrivateContentLoader = ({ children, classNames = styles.wrapper }) => {
  const { userInfoLoaded, isGuest } = useUserInfoLoaded();
  return userInfoLoaded && !isGuest ? (
    children
  ) : (
    <Box align="center" justify="center" className={classNames}>
      <Loader />
    </Box>
  );
};

export default PrivateContentLoader;
