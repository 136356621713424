import { getCookie } from '@namespace/helpers';
import { AB_TEST_COOKIE } from '../../constants';

const getABTestsMapFromCookie = () => {
  try {
    const abTestMap = getCookie(AB_TEST_COOKIE);
    if (abTestMap) {
      return JSON.parse(abTestMap);
    }
    return {};
  } catch {
    return {};
  }
};

export default getABTestsMapFromCookie;
