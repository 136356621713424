import RememberMe from './components/RememberMe';
import ForgotPassword from './components/ForgotPassword';
import HaveAnAccount from '../../../../common/HaveAnAccount';

export const LOGIN_DATA_COMPONENTS_TYPE = {
  REMEMBER_ME: 'remember-me-field',
  FORGOT_PASSWORD: 'forgot-password-field',
  REGISTER: 'register-field'
};

export const loginDataComponentsMap = {
  [LOGIN_DATA_COMPONENTS_TYPE.REMEMBER_ME]: RememberMe,
  [LOGIN_DATA_COMPONENTS_TYPE.FORGOT_PASSWORD]: ForgotPassword,
  [LOGIN_DATA_COMPONENTS_TYPE.REGISTER]: HaveAnAccount
};
