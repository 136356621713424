import { DOCUMENT_TYPES } from '../../../../constants';
import { INTERNAL, EXTERNAL } from '../../constants';

const getJumioModeMethods = (documentTypeId, country) => {
  if (country === 'UA') {
    const methodsByTypeId = {
      [DOCUMENT_TYPES.IDCARD]: [EXTERNAL, INTERNAL],
      [DOCUMENT_TYPES.PASSPORT]: [INTERNAL]
    };

    return methodsByTypeId[documentTypeId];
  }

  return [INTERNAL];
};

export default getJumioModeMethods;
