import clsx from 'clsx';
import { BaseFilter } from './BaseFilter';
import styles from './index.module.css';

export const SingleFilter = ({
  filtersConfig,
  changedFilters,
  appliedFilters,
  onSet,
  onChange,
  arrowConfig,
  isInline,
  className,
  filterGroupClassName,
  dropdownFilterClassName,
  triggerClassName,
  filterClassName
}) => {
  const filterOnChangeHandler = (key, value) => {
    const newValue = !value.value ? { ...value } : { [key]: value.value };
    onChange(newValue);
    onSet(newValue);
  };

  return (
    <div className={className}>
      <div
        className={clsx(
          styles.singleFilter,
          isInline ? styles.filterInline : styles.filter,
          filterClassName
        )}
      >
        <BaseFilter
          {...{
            filtersConfig,
            changedFilters,
            appliedFilters,
            isInline,
            arrowConfig,
            filterOnChangeHandler,
            filterGroupClassName,
            dropdownFilterClassName,
            triggerClassName
          }}
        />
      </div>
    </div>
  );
};
