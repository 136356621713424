import PropTypes from 'prop-types';
import clsx from 'clsx';
import Label from '../Label';
import AText from '../AText';
import Box from '../Box';
import styles from './index.module.css';

const Radio = ({
  label = '',
  inputRef = null,
  onChange = () => {},
  value = '',
  disabled = false,
  size = 's',
  name = '',
  className,
  classNames = {},
  dataRole,
  isActive,
  ...props
}) => {
  const {
    wrapperClassName = '',
    labelTextClassName = '',
    inputClassName = '',
    radioClassName = '',
    activeLabelClassName = ''
  } = classNames;
  const classes = clsx([
    styles.wrapper,
    className,
    wrapperClassName,
    styles[size],
    disabled && styles.disabled,
    isActive && activeLabelClassName
  ]);

  return (
    <Label className={classes}>
      <Box data-role={dataRole && `${dataRole}-input`}>
        <input
          ref={inputRef}
          type="radio"
          className={`${styles.input} ${inputClassName}`}
          value={value}
          onChange={onChange}
          name={name}
          disabled={disabled}
          {...props}
        />
        <Box
          className={`${styles.radio} ${radioClassName}`}
          align="center"
          justify="center"
        />
      </Box>
      {label && (
        <AText
          data-role={dataRole && `${dataRole}-label`}
          className={`${styles.label} ${labelTextClassName}`}
          color="txt_color_7"
          size={size}
        >
          {label}
        </AText>
      )}
    </Label>
  );
};

Radio.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  classNames: PropTypes.shape({
    wrapperClassName: PropTypes.string,
    labelTextClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    radioClassName: PropTypes.string
  }),
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.element })
  ]),
  onChange: PropTypes.func,
  value: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  size: PropTypes.oneOf(['s', 'm'])
};

export default Radio;
