import { useContext, useEffect } from 'react';
import { getLocalStorageValue, LOCAL_STORAGE_KEYS } from '@namespace/helpers';
import { useGeneralRoutes } from '@namespace/cms';
import { useLocation } from '@namespace/i18n';
import { UserContext } from '@namespace/user';
import useSetLocalStatus from '../../hooks/useSetLocalStatus';
import useSetTeamsToFutureBracket from '../../hooks/useSetTeamsToFutureBracket';
import { PredictionsContext } from '../../store/context';

const PredictionsLoadWrapper = () => {
  const [, predActions] = useContext(PredictionsContext);
  const {
    FETCH_REFERENCE,
    FETCH_USER_PREDICTION,
    LOAD_FROM_LOCAL_STORAGE,
    OFF_EDITING
  } = predActions;
  const [user] = useContext(UserContext);
  const { id } = user;
  const { pathname } = useLocation();
  const { predictionsSuccess } = useGeneralRoutes();
  useEffect(() => {
    LOAD_FROM_LOCAL_STORAGE(
      getLocalStorageValue(LOCAL_STORAGE_KEYS.PREDICTIONS)
    );
    FETCH_USER_PREDICTION(id)
      .catch((e) => console.error(e))
      .then(() => FETCH_REFERENCE());
  }, [FETCH_REFERENCE, FETCH_USER_PREDICTION, LOAD_FROM_LOCAL_STORAGE, id]);

  useEffect(() => {
    if (pathname.includes(predictionsSuccess)) {
      OFF_EDITING();
      FETCH_USER_PREDICTION(id)
        .catch((e) => console.error(e))
        .then(() => FETCH_REFERENCE());
    }
  }, [pathname, predictionsSuccess, OFF_EDITING]);

  useSetTeamsToFutureBracket();
  useSetLocalStatus();

  return null;
};

export default PredictionsLoadWrapper;
