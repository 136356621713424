import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useComponentConfig } from '@namespace/cms';
import { useSportsbookStore } from '../../store';

// todo refactor: 1) move to selectors 2) useComputedSelector (when it's done)
export const useFilteredSports = (serviceId) => {
  const sports = useSportsbookStore('sports');
  const { sportIds = [] } = useComponentConfig();
  const sportsByService = useMemo(() => sports[serviceId] || [], [
    serviceId,
    sports
  ]);

  const filteredSports = useMemo(() => {
    if (!isEmpty(sportsByService) && !isEmpty(sportIds)) {
      return sportsByService.filter(({ sportId }) =>
        sportIds.includes(sportId)
      );
    }
    return sportsByService;
  }, [sportIds, sportsByService]);

  return filteredSports;
};
