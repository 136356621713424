import { postAsUriParameters } from '@namespace/helpers';
import { handleAcceptResponse } from '../acceptBonus';
import { getList } from '../getList';
import { getTotalPages } from '../getTotalPages';
import apiRoutes from '../apiRoutes';

export const getFundsBonusesList = (filter, platform) =>
  getList(filter, platform, apiRoutes.getBonusList);

export const getFundsBonusesTotalPages = (filter, platform) =>
  getTotalPages(filter, platform, apiRoutes.getBonusCount);

export const acceptWager = async (bonusId, language, platform = '') => {
  const params = {
    bonus_id: bonusId,
    lang: language,
    platform
  };
  const response = await postAsUriParameters(apiRoutes.crmAcceptWager, params);
  return handleAcceptResponse(params, 'updwinbackstate', response);
};
