import {
  createHookStore,
  createUseSelector,
  STORE_NAMES
} from '@namespace/helpers-store';

export const initialState = {
  promotions: [],
  tournaments: {},
  enabledPromoTypes: [],
  enabledTag: [],
  activeTag: 'all',
  isPromosLoaded: false,
  isTournamentsLoaded: false,
  isTournamentsLoading: false
};

export const usePromotionsStore = createHookStore(
  STORE_NAMES.PROMOTIONS,
  initialState
);

export const usePromotionsSelector = createUseSelector(usePromotionsStore);
