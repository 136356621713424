import { useMemo } from 'react';
import {
  getSessionStorageValue,
  SESSION_STORAGE_KEYS,
  formatAsUriParameters
} from '@namespace/helpers';
import { matchPath } from 'react-router-dom';
import { useSearchParams } from '@namespace/router';
import { useOtherSettings } from '@namespace/cms';

export const useGetGameGoBackPath = (casinoLobbyUrl) => {
  const prevPathname = getSessionStorageValue(
    SESSION_STORAGE_KEYS.PREV_PATHNAME,
    ''
  );
  const { backTo, prevPage, prevQuery } = useSearchParams();
  const { pathsAliases } = useOtherSettings();

  const isPrevCasinoPathname = Boolean(
    matchPath({ path: `${casinoLobbyUrl}/*`, end: true }, prevPathname) ||
      pathsAliases?.[prevPathname] === casinoLobbyUrl
  );

  const prevPathnameWithParams = `${prevPathname}?${formatAsUriParameters({
    page: prevPage,
    query: prevQuery
  })}`.replace(/\?$/, '');

  const gameGoBackPath = useMemo(() => {
    if (backTo) return backTo;

    return isPrevCasinoPathname ? prevPathnameWithParams : casinoLobbyUrl;
  }, [casinoLobbyUrl, isPrevCasinoPathname, prevPathnameWithParams, backTo]);

  return {
    gameGoBackPath,
    isPrevCasinoPathname
  };
};
