import { useEffect, useRef } from 'react';

const useTimeout = (callback = () => {}, delay = 1000) => {
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    if (delay !== null) {
      const id = setTimeout(tick, delay);
      return () => clearTimeout(id);
    }

    return undefined;
  }, [delay]);
};

export default useTimeout;
