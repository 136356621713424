import { useEffect } from 'react';
import { AText, Box, Button } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { Icon } from '@namespace/icons2';
import {
  useGetInHouseJackpotSettings,
  useGetInHouseJackpotNotificationControls
} from '../../../../../../hooks';
import { IN_HOUSE_JACKPOT_TYPES } from '../../../../../../constants';
import { WIN_MESSAGE_SHOW_TIME } from '../../constants';

import styles from './index.module.css';

export const InHouseJackpotWinMessage = ({ jackpotWin, className }) => {
  const { t } = useI18n();
  const { winMessage = {} } = useGetInHouseJackpotSettings();
  const { acceptType } = winMessage;

  const {
    handleAccept,
    handleClose,
    isRequestProcessing
  } = useGetInHouseJackpotNotificationControls(
    jackpotWin.jackpotId,
    winMessage,
    IN_HOUSE_JACKPOT_TYPES.WIN_MESSAGES
  );

  useEffect(() => {
    const timeoutId = setTimeout(handleClose, WIN_MESSAGE_SHOW_TIME);

    return () => {
      clearTimeout(timeoutId);
      handleClose();
    };
  }, []);

  return (
    <Box
      className={`${styles.wrapper} ${className}`}
      align="center"
      justify="center"
    >
      <AText
        size="2xl"
        className={styles.winMessageText}
        breed="BU"
        color="txt_color_4"
        align="center"
      >
        {t('web.games.jackpot.winMessage', [
          jackpotWin.userRealAmount,
          jackpotWin.userCurrency
        ])}
      </AText>
      <Button
        size="s"
        actionType="color2"
        className={styles.acceptButton}
        onClick={handleAccept}
        disabled={isRequestProcessing}
      >
        {t(`games.jackpot.${acceptType}`)}
      </Button>
      <Icon
        onClick={handleClose}
        size="s"
        fill="btn_close_light_color"
        name="icons/general/action/close"
        className={styles.closeIcon}
      />
    </Box>
  );
};
