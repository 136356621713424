import { useContext, useEffect } from 'react';
import { map, path, pipe, reject } from 'ramda';
import { USER_ROLES, UserContext } from '@namespace/user';
import { useI18n } from '@namespace/i18n';
import { SiteSettingsContext } from '@namespace/cms';
import {
  advFilterActions,
  useAdvFilterStore
} from '../../../../../../storeAdvFilter';
import { MODE } from '../../../../../../constants';

const { FETCH_ADV_FILTER_CATEGORIES } = advFilterActions;

export const useFetchCategories = (sportIds) => {
  const { language } = useI18n();
  const [{ role }] = useContext(UserContext);
  const partnerId = path([0, 'siteConfig', 'partnerId'])(
    useContext(SiteSettingsContext)
  );
  const advFilterCategoriesBySportId = useAdvFilterStore(
    'advFilterCategoriesBySportId'
  );

  useEffect(() => {
    if (role !== USER_ROLES.USER) {
      return;
    }

    if (sportIds == null || sportIds.length === 0) {
      return;
    }

    const newSportIds = pipe(
      map(Number),
      reject((spId) => spId in advFilterCategoriesBySportId)
    )(sportIds);

    if (newSportIds.length === 0) {
      return;
    }

    for (const spId of newSportIds) {
      FETCH_ADV_FILTER_CATEGORIES({
        sportId: spId,
        lang: language,
        serviceId: MODE.PRE_MATCH,
        partnerId
      });
    }
  }, [advFilterCategoriesBySportId, language, partnerId, role, sportIds]);
};
