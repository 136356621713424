import { isEmpty } from 'lodash';
import { getBonusSystemActiveBonus, getWagerBonuses } from '@namespace/bonuses';
import { SLUG_PARAMETER } from '../constants';
import { types } from './types';
import {
  getFavoriteGames,
  addGameToFavorites,
  removeGameFromFavorites,
  getGamesFeed,
  getCMSProviderGames,
  getGamesList,
  getProviders,
  getRecentlyPlayedGames,
  getRecommendedGames,
  excludedPrewagerGamesList,
  getWagerSlotGames,
  getLiveRtpGames
} from '../hooks/api';

const actions = {
  [types.GET_GAMES]: (dispatch) => async ({
    disabledProviders,
    feedNames,
    isDesktop,
    platform,
    userSegments
  }) => {
    try {
      const data = await getGamesFeed({ feedNames, platform, userSegments });
      dispatch({
        type: types.SET_GAMES,
        payload: {
          data,
          disabledProviders,
          feedName: feedNames,
          isDesktop,
          platform
        }
      });
    } catch {
      console.log('On load casino gamesFeed error');
    }
  },

  [types.SET_IS_FEED_LOADING]: (dispatch) => (isLoading, feedName) =>
    dispatch({
      type: types.SET_IS_FEED_LOADING,
      payload: { isLoading, feedName }
    }),

  [types.GET_FAVORITES_LIST]: (dispatch) => async (entityName) => {
    const { data } = await getFavoriteGames(entityName);

    dispatch({
      type: types.SET_FAVORITES_LIST,
      payload: { gamesList: data[entityName], entityName }
    });
  },

  [types.ADD_GAME_FAVORITES_LIST]: (dispatch) => async ({ id, entityName }) => {
    const { ok } = await addGameToFavorites({ id, entityName });

    if (ok) {
      dispatch({
        type: types.SET_FAVORITES_LIST,
        payload: { gamesList: [{ id }], entityName }
      });
    }
  },

  [types.REMOVE_GAME_FAVORITES_LIST]: (dispatch) => async ({
    id,
    entityName
  }) => {
    const { ok } = await removeGameFromFavorites({
      id,
      entityName
    });

    if (ok) {
      dispatch({
        type: types.SET_FAVORITES_LIST,
        payload: {
          gamesList: [{ id }],
          isFavorite: false,
          entityName
        }
      });
    }
  },

  [types.SET_JACKPOTS]: (dispatch) => (payload) =>
    dispatch({
      type: types.SET_JACKPOTS,
      payload
    }),

  [types.GET_CMS_PRAGMATIC_LIVE_INFO]: (dispatch) => async (serviceId) => {
    const { data } = await getCMSProviderGames({ serviceId });

    dispatch({
      type: types.SET_CMS_PRAGMATIC_LIVE_INFO,
      payload: { gmGames: data.games }
    });
  },
  [types.SET_RECOMMENDED_GAMES]: (dispatch) => (
    recommendedGamesList,
    feedName
  ) => {
    dispatch({
      type: types.SET_RECOMMENDED_GAMES,
      payload: { recommendedGamesList, feedName }
    });
  },
  [types.GET_GAME]: (dispatch) => async ({
    gameId,
    userSegments,
    platform
  }) => {
    const gamesData = await getGamesList({
      gamesIds: [gameId],
      userSegments,
      platform
    });

    if (!isEmpty(gamesData)) {
      dispatch({
        type: types.SET_GAME,
        payload: gamesData[0]
      });
      return { success: true };
    }

    return { success: false };
  },
  [types.GET_PROVIDER]: (dispatch) => async (providerId) => {
    const providersData = await getProviders();
    const gameProvider = providersData.find(
      (provider) => provider.providerId === providerId
    );

    dispatch({
      type: types.SET_PROVIDER,
      payload: gameProvider
    });
  },
  [types.GET_RECENTLY_PLAYED_LIST]: (dispatch) => async (entityName) => {
    try {
      const { data } = await getRecentlyPlayedGames(entityName);

      dispatch({
        type: types.SET_RECENTLY_PLAYED_LIST,
        payload: { gamesList: data?.gameIds, entityName, isLoaded: true }
      });
    } catch (e) {
      console.error(e);

      dispatch({
        type: types.SET_RECENTLY_PLAYED_LIST,
        payload: { gamesList: [], entityName, isLoaded: true }
      });
    }
  },
  [types.RESET_RECENTLY_PLAYED_LIST]: (dispatch) => async (entityName) => {
    dispatch({
      type: types.SET_RECENTLY_PLAYED_LIST,
      payload: { gamesList: [], entityName, isLoaded: false }
    });
  },
  [types.GET_RECOMMENDED_GAMES_LIST]: (dispatch) => async (entityName) => {
    try {
      const { data } = await getRecommendedGames(entityName);

      dispatch({
        type: types.SET_RECOMMENDED_GAMES_LIST,
        payload: { gamesList: data?.gameIds, entityName, isLoaded: true }
      });
    } catch (e) {
      console.error(e);

      dispatch({
        type: types.SET_RECOMMENDED_GAMES_LIST,
        payload: { gamesList: [], entityName, isLoaded: true }
      });
    }
  },
  [types.GET_WAGER_SLOT_GAMES]: (dispatch) => async () => {
    try {
      const { data } = await getWagerSlotGames();

      dispatch({
        type: types.SET_WAGER_SLOT_GAMES,
        payload: { gamesList: data?.gameIds, isLoaded: true }
      });
    } catch (e) {
      console.error(e);

      dispatch({
        type: types.SET_WAGER_SLOT_GAMES,
        payload: { gamesList: [], isLoaded: true }
      });
    }
  },
  [types.GET_LIVE_RTP_GAMES]: (dispatch) => async () => {
    try {
      const { data } = await getLiveRtpGames();

      dispatch({
        type: types.SET_LIVE_RTP_GAMES,
        payload: { games: data, isLoaded: true }
      });
    } catch (e) {
      console.error(e);

      dispatch({
        type: types.SET_LIVE_RTP_GAMES,
        payload: { games: {}, isLoaded: true }
      });
    }
  },
  [types.FETCH_WAGER_EXCLUDED_GAME_LIST]: (dispatch) => async () => {
    let isBonusExists = false;

    const { data: activeBonusData } = await getBonusSystemActiveBonus();
    if (isEmpty(activeBonusData)) {
      const { response: wagerBonusesData } = await getWagerBonuses();
      if (!isEmpty(wagerBonusesData)) {
        isBonusExists = true;
      }
    } else {
      isBonusExists = true;
    }

    if (isBonusExists) {
      const { data } = await excludedPrewagerGamesList(
        SLUG_PARAMETER.prewagerExclusions
      );
      const { gameIds } = data;
      dispatch({
        type: types.SET_EXCLUDED_GAMES_LIST,
        payload: { gameIds }
      });
    }
  }
};

export default actions;
