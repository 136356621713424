import { useContext } from 'react';
import { ErrorBoundary } from '@namespace/common';
import { IS_WIDGET } from '@namespace/widget';
import {
  ComponentContextProvider,
  LayoutContext,
  PageComponentsContext,
  PageContext,
  STATIC_COMPONENT_TYPES,
  UNIQUE_COMPONENT_TYPES
} from '@namespace/cms';
import { StickyContainer } from './StickyContainer';

export const ColumnContent = ({ column = [], isScrollable }) => {
  const [{ expandedComponentIds }] = useContext(LayoutContext);
  const componentsMap = useContext(PageComponentsContext);
  const { page } = useContext(PageContext);
  const { components = {}, layout = {} } = page;
  const { stickyComponents = [] } = layout;
  const isExpandedContainer = expandedComponentIds.some((cmp) =>
    column.includes(cmp)
  );
  let filteredComponents = components;
  const componentsArr = Object.entries(components);
  // should be only one component type in Column if component type is in UNIQUE_COMPONENT_TYPES constant
  if (
    componentsArr.some(([, { type }]) => UNIQUE_COMPONENT_TYPES.includes(type))
  ) {
    filteredComponents = componentsArr.reduce((acc, [key, item]) => {
      if (
        UNIQUE_COMPONENT_TYPES.includes(item.type) &&
        Object.values(acc).some(({ type }) => item.type === type)
      ) {
        return acc;
      }

      return {
        ...acc,
        [key]: item
      };
    }, {});
  }

  return column.map((componentId) => {
    const type = filteredComponents[componentId]?.type?.trim();
    const Content = filteredComponents[componentId]
      ? componentsMap[type]
      : () => <div />;
    const key = STATIC_COMPONENT_TYPES.includes(type) ? type : componentId;

    let RenderedContent;
    if (stickyComponents.includes(componentId)) {
      // NB this is now calculated automatically and property in JSON is deprecated
      const stickyContainerToTop = IS_WIDGET || isScrollable;

      RenderedContent = (
        <StickyContainer stickyContainerToTop={stickyContainerToTop}>
          <Content id={componentId} />
        </StickyContainer>
      );
    } else if (isExpandedContainer) {
      RenderedContent = expandedComponentIds.includes(componentId) ? (
        <Content id={componentId} />
      ) : (
        <div />
      );
    } else {
      RenderedContent = <Content id={componentId} />;
    }

    return (
      RenderedContent && (
        <ErrorBoundary componentId={componentId} key={`content-${key}`}>
          <ComponentContextProvider id={componentId}>
            {RenderedContent}
          </ComponentContextProvider>
        </ErrorBoundary>
      )
    );
  });
};
