import clsx from 'clsx';
import { Button } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { FilterButton } from './components/FilterButton';
import { BaseFilter } from './BaseFilter';
import styles from './index.module.css';

export const MultiFilter = ({
  filtersConfig,
  changedFilters,
  appliedFilters,
  onSet,
  onChange,
  isChanged,
  changesCount,
  isOpen,
  setOpen,
  isInline,
  className,
  filterGroupClassName,
  dropdownFilterClassName,
  triggerClassName,
  filterClassName,
  filterButtonClassName,
  arrowConfig,
  dataRole
}) => {
  const { t } = useI18n();
  const filterDataRole = dataRole && `${dataRole}-filter`;
  const filterOnChangeHandler = (key, value) => {
    const newValue = !value.value ? { ...value } : { [key]: value.value };
    onChange(newValue);
  };

  if (isOpen || isInline) {
    return (
      <div className={className}>
        {!isInline && (
          <FilterButton
            label={t('personalOffice.filter.hideFilters')}
            onClick={() => setOpen(false)}
          />
        )}
        <div
          className={clsx(
            isInline ? styles.filterInline : styles.filter,
            filterClassName
          )}
        >
          <BaseFilter
            {...{
              filtersConfig,
              changedFilters,
              appliedFilters,
              isInline,
              arrowConfig,
              filterOnChangeHandler,
              filterGroupClassName,
              dropdownFilterClassName,
              triggerClassName,
              dataRole: filterDataRole
            }}
          />
          <div className={isInline ? styles.filterGroupInline : ''}>
            <Button
              dataRole={dataRole && `${dataRole}-btn-apply`}
              size="l"
              actionType="color1"
              intent="primary"
              className={styles.applyFiltersButton}
              onClick={() => {
                setOpen(false);
                return onSet();
              }}
              disabled={!isChanged}
            >
              {t('personalOffice.filter.applyFilters')}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <FilterButton
      label={
        changesCount
          ? t('personalOffice.filter.showFiltersCount', [changesCount])
          : t('personalOffice.filter.showFilters')
      }
      onClick={() => setOpen(true)}
      className={filterButtonClassName}
    />
  );
};
