import { useCallback, useEffect, useState } from 'react';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { camelizeKeys } from 'humps';
import { useNotifications } from '@namespace/notifications';
import { getBonusDetails } from '../../api/getBonusDetails';

const useBonusDetails = (bonusId, bonusType, bonusListUrl) => {
  const [details, setDetails] = useState({});
  const [isFetchingDetails, setIsFetchingDetails] = useState(false);
  const { language, timezone, t } = useI18n();
  const { errorNotification } = useNotifications();
  const { push } = useHistory();

  const loadBonusDetails = useCallback(() => {
    setIsFetchingDetails(true);
    getBonusDetails(
      {
        bonus_id: bonusId,
        lang: language,
        time_zone: timezone
      },
      bonusType
    )
      .then((data) => {
        if (data) {
          setDetails(camelizeKeys(data));
        } else {
          push(bonusListUrl);
        }
      })
      .catch(() =>
        errorNotification(t(`accountingMessages.accounting_error_6`))
      )
      .finally(() => setIsFetchingDetails(false));
  }, [bonusId, bonusType, bonusListUrl, timezone, push, language]);

  useEffect(() => {
    if (bonusId) loadBonusDetails();
  }, [loadBonusDetails, bonusId]);

  return { details, isFetchingDetails, loadBonusDetails };
};

export default useBonusDetails;
