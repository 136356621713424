import {
  removeCookie,
  COOKIE_MANAGEMENT_TYPES,
  document
} from '@namespace/helpers';
import getRootDomain from '../getRootDomain';

const removeOptionalCookies = (cookieManagementType = '', cookieTypes = {}) => {
  const cookieManagementTypes = cookieManagementType
    ?.split(',')
    .filter((i) => i !== COOKIE_MANAGEMENT_TYPES.NECESSARY);

  const {
    optionalCookiesList,
    optionalMatchCookiesList
  } = cookieManagementTypes.reduce(
    (acc, i) => {
      return {
        optionalCookiesList: [
          ...acc.optionalCookiesList,
          ...(cookieTypes[i]?.optionalCookiesList || [])
        ],
        optionalMatchCookiesList: [
          ...acc.optionalMatchCookiesList,
          ...(cookieTypes[i]?.optionalMatchCookiesList || [])
        ]
      };
    },
    { optionalCookiesList: [], optionalMatchCookiesList: [] }
  );

  const matchCookiesList = optionalMatchCookiesList
    .reduce((acc, i) => {
      const regexp = new RegExp(i, 'g');
      const item = document.cookie?.match(regexp) || [];
      return [...acc, ...(Array.isArray(item) ? item : [item])];
    }, [])
    .filter(Boolean);

  for (const i of [...optionalCookiesList, ...matchCookiesList]) {
    removeCookie(i);
    removeCookie(i, { path: '/', domain: getRootDomain() });
  }
};

export default removeOptionalCookies;
