import { useContext } from 'react';
import clsx from 'clsx';
import { AText, Box, Button, Overlay } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { COMPONENTS_TYPES, PageComponentsContext } from '@namespace/cms';
import styles from './index.module.css';

const ConfirmModal = ({
  onClose,
  onConfirm,
  popupTitle,
  title,
  subtitle,
  confirm,
  close,
  textAlign = 'left',
  isShowClose = true,
  isBottomFullWidth = false,
  ...rest
}) => {
  const { t } = useI18n();

  const { [COMPONENTS_TYPES.CONFIRM_MODAL]: PartnerConfirmModal } = useContext(
    PageComponentsContext
  );

  if (PartnerConfirmModal) {
    return (
      <PartnerConfirmModal
        onClose={onClose}
        onConfirm={onConfirm}
        title={title}
        subtitle={subtitle}
        confirm={confirm}
        close={close}
        {...rest}
      />
    );
  }

  return (
    <Overlay classNames={{ wrapperClass: styles.overlay }} onClick={onClose}>
      <Box
        className={clsx([
          styles.formPopup,
          isBottomFullWidth && styles.bottomFullWidth
        ])}
        direction="column"
        align="center"
      >
        <Box className={styles.title} align="center" padding="s ms">
          <AText breed="B" size="l" color="txt_color_4">
            {popupTitle || t('web.personaloffice.bonuses.confirmdialog.title')}
          </AText>
        </Box>
        <Box className={styles.generalBlock} padding="l ms" direction="column">
          {title && (
            <AText breed="B" size="l" color="txt_color_3" align={textAlign}>
              {title}
            </AText>
          )}
          {subtitle && (
            <AText
              className={styles.secondaryText}
              breed="R"
              size="m"
              color="txt_color_7"
              align={textAlign}
            >
              {subtitle}
            </AText>
          )}
          <Box padding="m 0 0 0" direction="column">
            <Button
              onClick={onConfirm}
              intent="primary"
              actionType="color1"
              size="l"
              className={styles.button}
            >
              {confirm || t('formPopup.button.confirm')}
            </Button>
            {isShowClose && (
              <Button
                onClick={onClose}
                intent="quaternary"
                actionType="color1"
                size="l"
              >
                {close || t('formPopup.button.close')}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Overlay>
  );
};

export default ConfirmModal;
