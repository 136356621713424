import { matchPath } from 'react-router-dom';
import { useMemo } from 'react';
import { useLocation } from '@namespace/i18n';
import useGetNativeAppRouteMap from '@namespace/router/src/hooks/useGetNativeAppRouteMap';
import { MODE } from '../../constants';
import { useSportBySportSlug } from '../../store';

const getNativeAppUrl = (nativeAppPath, params) => {
  if (!nativeAppPath) {
    return '';
  }
  if (!params || Object.keys(params).length === 1) {
    return nativeAppPath;
  }
  return Object.keys(params).reduce(
    (acc, key) => acc.replace(`:${key}`, params[key]),
    nativeAppPath
  );
};

export const useGetNativeAppPath = () => {
  const { pathname } = useLocation();
  const nativeAppRouteMap = useGetNativeAppRouteMap();
  const paths = Object.keys(nativeAppRouteMap);
  const [matchedPath = {}] = useMemo(
    () =>
      paths
        .map((currentPath) =>
          matchPath(
            {
              path: currentPath,
              end: true
            },
            pathname
          )
        )
        .filter(Boolean),
    [pathname, paths]
  );
  const { path, params = {} } = matchedPath;
  const { sportName: slug } = params;
  const preMatchSport = useSportBySportSlug(slug, MODE.PRE_MATCH);
  const liveSport = useSportBySportSlug(slug, MODE.IN_PLAY);
  const sportName = preMatchSport?.sportId ?? liveSport?.sportId;
  const preparedParams = sportName
    ? {
        ...params,
        sportName
      }
    : params;

  return getNativeAppUrl(nativeAppRouteMap[path], preparedParams);
};
