import { camelize } from 'humps';
import { invert } from 'lodash';
import { DOCUMENT_TYPES_MAP } from '../../../../constants';

import { METHODS_MAP } from '../../constants';

const createCompareTypes = (value, types) => (item) =>
  Number(types[camelize(item.type)]) === value;

const toMethod = (item) => METHODS_MAP[item];

const parseOptionsResponse = (items, type) => {
  const invertedDocumentTypes = invert(DOCUMENT_TYPES_MAP);
  const item = items.find(createCompareTypes(type, invertedDocumentTypes));

  return item?.verification_methods.map(toMethod);
};

export default parseOptionsResponse;
