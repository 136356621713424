import { types } from '../types';

export const initialState = {
  abTestsMap: {},
  abSegments: [],
  isSegmentsLoaded: false
};

export const reducer = {
  [types.SET_AB_TEST_MAP]: (state, abTestsMap) => ({
    ...state,
    abTestsMap
  }),
  [types.FETCH_AB_SEGMENTS]: (state, { abSegments, isSegmentsLoaded }) => ({
    ...state,
    abSegments,
    isSegmentsLoaded
  })
};
