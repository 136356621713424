import { useCallback, useContext, useMemo, useState } from 'react';
import {
  AText,
  Carousel,
  DOTS_TYPE,
  ModalDialog,
  Slide,
  propsMerge
} from '@alf/uikit';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import {
  PageContext,
  SiteSettingsContext,
  useGeneralRoutes,
  useOtherSettings
} from '@namespace/cms';
import { useOnSlideMove } from '@namespace/common';
import { LocalizedLink, useI18n, useLocation } from '@namespace/i18n';
import { useUserSegmentsIncludes } from '@namespace/segments';
import { HTMLSnippet } from '@namespace/snippets';
import { useGetImage, useGetThemeConfig } from '@namespace/themes';
import { UserContext } from '@namespace/user';
import clsx from 'clsx';
import { window } from '@namespace/helpers';
import { ROTATION_TYPES } from './constants';

import styles from './index.module.css';

const Banner = ({ id }) => {
  const [modalInfoMessage, setModalInfoMessage] = useState(null);
  const { page } = useContext(PageContext);
  const { components = {} } = page;
  const { config = {} } = components[id] || {};
  const { home = '' } = useGeneralRoutes();
  const bannerConfig = useGetThemeConfig('Banner');
  const {
    slides = [],
    height = '',
    showOnlyFirstSlide = false,
    navigation = {},
    rotation = ROTATION_TYPES.PAGES,
    isUA13Look,
    ...otherConfig
  } = propsMerge(config, bannerConfig);
  const { externalUrlConfirmMessages = [] } = useOtherSettings();
  const { dots = {} } = navigation;
  const { t, f, language } = useI18n();
  const { pathname } = useLocation();
  const filterSegments = useUserSegmentsIncludes();
  const { setIsMove, isMove, handleClickOnMove } = useOnSlideMove();
  const [user] = useContext(UserContext);
  const { activeWallet = {} } = user;
  const { currency: userCurrency = null } = activeWallet;
  const [settings] = useContext(SiteSettingsContext);
  const { siteConfig } = settings;
  const { defaultCurrency } = siteConfig;
  const currentCurrency = userCurrency || defaultCurrency;
  const isSlidesRotationType = rotation === ROTATION_TYPES.SLIDES;

  const getExternalUrlMessage = (url) => {
    if (externalUrlConfirmMessages.length) {
      for (const item of externalUrlConfirmMessages) {
        if (url.includes(item.url)) {
          return item.messageKey;
        }
      }
    }

    return null;
  };

  const getImgUrl = useGetImage();

  const clickOnSlide = ({ event, slideId, slideName, index, url }) => {
    logAction(LOG_ACTIONS.BANNER_CLICK, {
      index: index + 1,
      promotionId: slideId,
      promotionName: slideName,
      locationId: pathname.replace(/\//g, '')
    });
    const externalMessage = getExternalUrlMessage(url);

    if (externalMessage) {
      event.preventDefault();
      setModalInfoMessage({ url, message: externalMessage });
    }
  };

  const options = {
    ...otherConfig,
    navigation: {
      ...navigation,
      dots:
        (dots && slides.length > 1 && !showOnlyFirstSlide && !isUA13Look) ||
        (dots && isUA13Look && slides.length > 2 && !showOnlyFirstSlide)
          ? {
              ...dots,
              type: DOTS_TYPE.SMALL,
              color: 'accent_color_6',
              classNames: isUA13Look
                ? {
                    general: styles.dotsWrapper,
                    dot: styles.dot,
                    activeDot: styles.activeDot,
                    dotWrapper: styles.dotWrapper
                  }
                : undefined
            }
          : {}
    },
    classNames: {
      container: clsx(
        isSlidesRotationType && styles.slidesRotationCarouselWrapper,
        isUA13Look && styles.slideIsNotFullScreenImgContainer,
        isUA13Look &&
          slides.length <= 2 &&
          styles.slideIsNotFullScreenImgContainerWithoutThird
      )
    }
  };

  const filterByShowTime = useCallback(
    ({ from, to }) => {
      if (from || to) {
        return f.localTimeIsActual(from, to, f.getDateTime);
      }
      return true;
    },
    [f]
  );

  const handleCancel = () => {
    setModalInfoMessage(null);
  };

  const handleAccept = () => {
    window.location.href = modalInfoMessage.url;
  };

  const filteredSlides = useMemo(() => {
    const filtered = slides
      .filter(
        (slide) => filterByShowTime(slide) && filterSegments(slide.userSegments)
      )
      .map((slide, index) => (
        <Slide
          handleClickOnMove={(event) =>
            handleClickOnMove(event, () =>
              clickOnSlide({
                event,
                slideId: slide.id,
                slideName: slide.name,
                index,
                url: slide.url || slide.link
              })
            )
          }
          slide={slide}
          key={slide.id}
          home={home}
          isMove={isMove}
          language={language}
          LocalizedLink={LocalizedLink}
          HTMLSnippet={HTMLSnippet}
          currentCurrency={currentCurrency}
          className={clsx([
            isUA13Look && styles.slideIsNotFullScreenSlide,
            isSlidesRotationType && styles.slidesRotationSlideWrapper
          ])}
          classNames={{
            ...(isSlidesRotationType && {
              slideSkeleton: styles.sliderSlideSkeleton
            }),
            ...(isUA13Look && {
              slideImg: styles.slideIsNotFullScreenImg,
              slideSkeleton: styles.slideIsNotFullScreenSkelet
            })
          }}
          getImgUrl={getImgUrl}
        />
      ));
    return showOnlyFirstSlide ? [filtered[0]] : filtered;
  }, [
    slides,
    showOnlyFirstSlide,
    filterByShowTime,
    filterSegments,
    home,
    language,
    currentCurrency,
    clickOnSlide,
    getImgUrl
  ]);

  if (filteredSlides.length) {
    return (
      <>
        <Carousel setIsMove={setIsMove} {...options} style={{ height }}>
          {filteredSlides}
        </Carousel>
        {modalInfoMessage && (
          <ModalDialog
            acceptTitle={t('web.confirmDialog.confirmButton')}
            closeTitle={t('web.confirmDialog.declineButton')}
            onCancel={handleCancel}
            onAccept={handleAccept}
            onBlur={handleCancel}
            intentAccept="primary"
            actionTypeAccept="color1"
            intentCancel="secondary"
            actionTypeCancel="color1"
            title={t('web.confirmDialog.title')}
            classNames={{
              contentClass: styles.contentClassOverlay,
              wrapperClass: styles.wrapperClassOverlay
            }}
          >
            <AText color="po_txt_color_1" breed="B" size="l">
              {t(modalInfoMessage.message)}
            </AText>
          </ModalDialog>
        )}
      </>
    );
  }

  return null;
};

export default Banner;
