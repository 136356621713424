import { useCallback, useContext } from 'react';
import clsx from 'clsx';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import {
  USER_ROLES,
  UserContext,
  useLudomanInVerification
} from '@namespace/user';
import { AText, Box, Button } from '@alf/uikit';
import { useIsFreeToPlayUser, useIsAddictedUser } from '@namespace/account';
import { DEMO_MODE, REAL_MODE, CONTROL_MODE_TYPES } from '../../constants';
import { useGetGameURL, useGetLobbyPageUrl } from '../../hooks';
import styles from './index.module.css';

const Controls = ({
  serviceName = '',
  serviceURL,
  game,
  className = '',
  classNames: { wrapper = '', control = '', active = '' } = {},
  isDemoActive = false,
  isRealActive = false,
  onUnAuthorizedPlay,
  onCancelUnAuthorizedPlay,
  setIsDisplayUnavailableScreen,
  controlsMode = CONTROL_MODE_TYPES.REDIRECT,
  onGameClick = () => {},
  gameIndex = 0,
  casinoType,
  backTo,
  isMobileAppRedirect = false,
  isNativeApp = false
}) => {
  const { gameCode, provider, hasDemoSupport, labels = [], tags = [] } = game;
  const { push, replace } = useHistory();
  const { t } = useI18n();
  const [user] = useContext(UserContext);
  const { role } = user;
  const isFreeToPlayUser = useIsFreeToPlayUser();
  const getGameURL = useGetGameURL();
  const isAddictedUser = useIsAddictedUser();
  const lobbyPageUrl = useGetLobbyPageUrl(casinoType);
  const actualServiceURL = serviceURL || lobbyPageUrl; // TODO: take only from serviceURL ?
  const {
    showNotification: showLudomanNotification
  } = useLudomanInVerification();

  const launchGame = useCallback(
    (mode) => {
      onGameClick({
        isDemo: mode === DEMO_MODE,
        gameName: game.gameCode,
        launchGameId: game.launchGameId,
        casinoProvider: game.provider,
        gameOrder: gameIndex || 'preview',
        casinoType
      });
      const launchURL = getGameURL({
        gameCode,
        gameProvider: provider,
        playMode: mode,
        serviceURL: actualServiceURL,
        type: 'launch',
        backTo
      });

      if (controlsMode === CONTROL_MODE_TYPES.LAUNCH) {
        replace(launchURL);

        return;
      }

      push(launchURL);
    },
    [
      replace,
      push,
      actualServiceURL,
      gameCode,
      provider,
      controlsMode,
      getGameURL,
      backTo
    ]
  );

  const launchDemo = useCallback(() => launchGame(DEMO_MODE), [launchGame]);

  const launchReal = useCallback(() => launchGame(REAL_MODE), [launchGame]);

  const isRecommendedGame = tags.some((tag) => tag.includes('rec_'));

  const handleRealPlayClick = useCallback(() => {
    if (!isFreeToPlayUser && setIsDisplayUnavailableScreen) {
      setIsDisplayUnavailableScreen(true);
    } else if (role === USER_ROLES.GUEST && onUnAuthorizedPlay) {
      onUnAuthorizedPlay();
    } else if (onCancelUnAuthorizedPlay) {
      onCancelUnAuthorizedPlay();
      showLudomanNotification();
      launchReal();
    } else {
      showLudomanNotification();
      launchReal();
    }
  }, [
    isFreeToPlayUser,
    onUnAuthorizedPlay,
    launchReal,
    role,
    showLudomanNotification,
    setIsDisplayUnavailableScreen,
    onCancelUnAuthorizedPlay
  ]);

  const onHandleRealPlayClick = useCallback(
    () =>
      isNativeApp
        ? push(`${actualServiceURL}/open/game?gameId=${gameCode}`)
        : handleRealPlayClick(),
    [handleRealPlayClick, isNativeApp, push, actualServiceURL, gameCode]
  );

  const onDemoPlayClick = useCallback(() => {
    if (isAddictedUser && setIsDisplayUnavailableScreen) {
      setIsDisplayUnavailableScreen(true);
    } else if (onCancelUnAuthorizedPlay) {
      onCancelUnAuthorizedPlay();
      launchDemo();
    } else {
      launchDemo();
    }
  }, [isAddictedUser, launchDemo, onCancelUnAuthorizedPlay]);

  // TODO: won't need isMobileAppRedirect logic after release of new app redirects logic
  // for now need this so native app could track redirect and apply its own game launch logic
  // used only in promo games list for now

  return (
    <Box align="center" className={clsx([styles.controls, wrapper, className])}>
      <Button
        actionType="color2"
        size="l"
        className={`
          ${styles.control}
          ${control}
          ${isRealActive ? active : ''} 
          ${isRecommendedGame ? 'recommendedGame' : ''}`}
        data-gtm={`Game-launch ${serviceName.toLowerCase()} ${REAL_MODE} ${gameCode} ${labels.join(
          ' '
        )}`}
        {...(isMobileAppRedirect
          ? {
              component: 'a',
              href: `${actualServiceURL}/open/game?gameId=${gameCode}`
            }
          : { onClick: onHandleRealPlayClick })}
      >
        <AText breed="BU" size="m">
          {t('games.playForReal')}
        </AText>
      </Button>
      {hasDemoSupport && !isNativeApp && (
        <Button
          intent="secondary"
          actionType="color4"
          size="l"
          className={`
            ${styles.control}
            ${control}
            ${isDemoActive ? active : ''}
            ${isRecommendedGame ? 'recommendedGame' : ''}
          `}
          onClick={onDemoPlayClick}
          data-gtm={`Game-launch ${serviceName.toLowerCase()} ${DEMO_MODE} ${gameCode} ${labels.join(
            ' '
          )}`}
        >
          <AText breed="BU" size="m">
            {t('games.demo')}
          </AText>
        </Button>
      )}
    </Box>
  );
};

export default Controls;
