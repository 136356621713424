import { memo, useContext } from 'react';
import {
  COOKIES_KEYS,
  getCookie,
  getLocalStorageValue,
  LOCAL_STORAGE_KEYS,
  setCookie,
  window
} from '@namespace/helpers';
import { ErrorBoundary } from '@namespace/common';
import { PageComponentsContext, SiteSettingsContext } from '@namespace/cms';
import { Box, Select } from '@alf/uikit';
import styles from './index.module.css';

// todo similar with ColumnContent in Layout
const MenuContent = ({
  column = [],
  components,
  componentsMap,
  isCustomFooter
}) =>
  column.map((componentId) => {
    const Content = components[componentId]
      ? componentsMap[components[componentId].type]
      : null;

    return Content ? (
      <ErrorBoundary componentId={componentId} key={componentId}>
        <Content
          id={componentId}
          config={components[componentId]?.config}
          isFooter={true}
          isCustomFooter={isCustomFooter}
        />
      </ErrorBoundary>
    ) : null;
  });

const Footer = memo(({ classNames = {}, isCustomFooter = false }) => {
  const [settings] = useContext(SiteSettingsContext);
  const componentsMap = useContext(PageComponentsContext);
  const {
    components = {},
    componentsMobile = {},
    footer = {},
    footerMobile = {}
  } = settings;
  const currentComponents = isCustomFooter ? componentsMobile : components;
  const { components: footerRows = [] } = isCustomFooter
    ? footerMobile
    : footer;
  const {
    components: componentsClassName = '',
    row: rowClassName = ''
  } = classNames;

  const isTester = getLocalStorageValue(LOCAL_STORAGE_KEYS.TESTER);

  if (!footerRows.length) {
    return null;
  }

  return (
    <Box
      justify="center"
      direction="column"
      className={`${styles.container}  ${isCustomFooter &&
        styles.customFooter} ${isCustomFooter && 'customFooter'}`}
      flexShrink={0}
    >
      {isTester && (
        <Select
          options={[
            { value: 'favbet', title: 'favbet' },
            { value: 'goodwin_light', title: 'goodwin_light' }
          ]}
          size="s"
          onChange={({ value }) => {
            setCookie(COOKIES_KEYS.COLOR_THEME, value, { expires: 365 });
            window.location.reload();
          }}
          value={getCookie(COOKIES_KEYS.COLOR_THEME, 'Choose Theme')}
          style={{ margin: '0 auto', width: 300 }}
        />
      )}
      <Box
        align="center"
        direction="column"
        className={`${styles.components} ${componentsClassName}`}
      >
        {footerRows.map((column = [], idx) => (
          <Box
            className={`${styles.row} ${rowClassName}`}
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
          >
            <MenuContent
              column={column}
              components={currentComponents}
              componentsMap={componentsMap}
              isCustomFooter={isCustomFooter}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
});

export default Footer;
