import { useContext, useMemo } from 'react';
import { DateTime } from 'luxon';
import { UserContext } from '@namespace/user/src/context';
import useGetCountryId from '@namespace/user/src/hooks/useGetCountryId';
import { USER_ROLES } from '@namespace/user/src/constants';
import { useI18n } from '@namespace/i18n';
import {
  setLocalStorageValue,
  getLocalStorageValue,
  LOCAL_STORAGE_KEYS,
  SEGMENT_FIELDS
} from '@namespace/helpers';
import { SiteSettingsContext } from '@namespace/cms/src/store/siteSettings/context';
import getNewUserSegments from '../../utils/getNewUserSegments';
import { REGISTRATION_TYPES, ALL_VALUE, LOGGED_OUT } from '../../constants';

const {
  USER_SEGMENTS_FILTER,
  USER_SEGMENTS_ALL,
  USER_SEGMENTS,
  HAS_BEEN_LOGGED_IN
} = LOCAL_STORAGE_KEYS;

const useGetUserSegments = () => {
  const { language, f } = useI18n();
  const [userState] = useContext(UserContext);
  const {
    role,
    activeWallet = {},
    isDefaultRegistratedUser,
    vipStatus,
    settings: userSettings,
    dateOfBirth,
    documents = [],
    countOfSuccessfulDeposits
  } = userState;
  const { verified = false, status = '' } = documents[0] || {};
  const { bTag } = userSettings;
  const { affiliateId, creativeId, trackerId, zoneId } = bTag;

  const [settings] = useContext(SiteSettingsContext);
  const { userSegments: segments } = settings || {};

  // start of segments user filter settings
  const verify = !!(verified && status === 'VERIFIED');
  const deposit = activeWallet?.deposit;
  const countryId = useGetCountryId();
  const age = useMemo(
    () =>
      role === USER_ROLES.USER
        ? Math.floor(
            DateTime.local().diff(
              f.getDateTime({
                date: dateOfBirth
              }),
              'years'
            ).years
          )
        : ALL_VALUE,
    [role, dateOfBirth, f]
  );

  const segmentationUserRole = useMemo(() => {
    let userRole = role;
    if (role === USER_ROLES.GUEST) {
      const hasBeenLoggedIn = getLocalStorageValue(HAS_BEEN_LOGGED_IN);
      if (hasBeenLoggedIn) {
        userRole = LOGGED_OUT;
      }
    }
    return userRole;
  }, [role]);

  const registration = isDefaultRegistratedUser
    ? REGISTRATION_TYPES.SHORT
    : REGISTRATION_TYPES.FULL;

  const balance = role !== USER_ROLES.USER ? ALL_VALUE : Number(deposit);

  const typesOfVip = useMemo(
    () => vipStatus?.map((item) => (item === 'notVip' ? 'noneVip' : item)),
    [vipStatus]
  );

  const typeOfDeposits = useMemo(() => {
    const countOfDeposits = Number(countOfSuccessfulDeposits);
    if (countOfDeposits === 0) {
      return 'noDeposits';
    }
    if (countOfDeposits === 1) {
      return 'firstDeposit';
    }
    return 'moreThanOneDeposit';
  }, [countOfSuccessfulDeposits]);

  const response = useMemo(() => {
    if (!segments) {
      return {
        allSegments: [],
        userSegments: [],
        allSegmentsNames: [],
        userSegmentsNames: []
      };
    }
    const userFilter = {
      [SEGMENT_FIELDS.USER_GROUP]: segmentationUserRole,
      [SEGMENT_FIELDS.AGE_FROM]: age,
      [SEGMENT_FIELDS.AGE_TO]: age,
      [SEGMENT_FIELDS.ACCOUNT_VERIFIED]:
        role === USER_ROLES.USER ? verify : ALL_VALUE,
      [SEGMENT_FIELDS.REGISTRATION]:
        role !== USER_ROLES.USER ? ALL_VALUE : registration,
      [SEGMENT_FIELDS.BALANCE_FROM]: balance, // 2 same values for consistency, all filter values named like segment values
      [SEGMENT_FIELDS.BALANCE_TO]: balance,
      [SEGMENT_FIELDS.VIP]: role !== USER_ROLES.USER ? ALL_VALUE : typesOfVip,
      [SEGMENT_FIELDS.AFFILIATE_ID]: affiliateId,
      [SEGMENT_FIELDS.CREATIVE_ID]: creativeId,
      [SEGMENT_FIELDS.SITE_TRACKER_ID]: trackerId,
      [SEGMENT_FIELDS.ANID_ZONE_ID]: zoneId,
      [SEGMENT_FIELDS.LANGUAGES]: language,
      [SEGMENT_FIELDS.COUNTRIES]: countryId,
      [SEGMENT_FIELDS.USER_DEPOSITED]:
        role !== USER_ROLES.USER ? ALL_VALUE : typeOfDeposits
    };

    const {
      newUserSegments,
      allSegmentsNames,
      userSegmentsNames
    } = getNewUserSegments(segments, userFilter);

    // needed for qa debug, and for storageCountryId feature
    setLocalStorageValue(USER_SEGMENTS_FILTER, userFilter);
    setLocalStorageValue(USER_SEGMENTS_ALL, segments);
    setLocalStorageValue(USER_SEGMENTS, newUserSegments);

    return {
      allSegments: segments,
      userSegments: newUserSegments,
      allSegmentsNames,
      userSegmentsNames
    };
  }, [ // eslint-disable-line
    // disabled because we have 2 array deps, segments and typesOfVip, their length added
    role,
    age,
    verify,
    isDefaultRegistratedUser,
    deposit,
    affiliateId,
    creativeId,
    trackerId,
    zoneId,
    language,
    countryId,
    countOfSuccessfulDeposits,
    segments?.length,
    typesOfVip?.length,
    registration,
    balance,
    segmentationUserRole,
    typeOfDeposits
  ]);

  return response;
};

export default useGetUserSegments;
