import { flatten, isEmpty, mergeDeepLeft, mergeDeepRight, omit } from 'ramda';
import { FORM_FACTORS_MAP } from '@namespace/device';
import { normalizeSegments } from '@namespace/helpers';
import { REQUIRED_SITE_CONFIG_SETTINGS } from '../../constants';

export const normalizeSettings = (siteSettings = {}) => {
  if (siteSettings.menuTags) {
    const menuTags = siteSettings.menuTags.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.tagId]: curr
      }),
      {}
    );
    return { ...siteSettings, menuTags };
  }
  return siteSettings;
};

export const mergeSettings = (defaultSettings, newSettings) => {
  const {
    footer = {},
    footerMobile = {},
    componentsMobile = {},
    header = {},
    userMenu = [],
    userSegments,
    siteConfig = {},
    otherSettings = {},
    supportSettings = {},
    mobileAppBanner,
    mobileNavigation,
    sports = {},
    gameTypes,
    menuTags,
    promoTags
  } = newSettings;
  const {
    siteConfig: defaultSiteConfig,
    otherSettings: defaultOtherSettings,
    supportSettings: defaultSupportSettings
  } = defaultSettings;
  const { components: footerComponents = [] } = footer;
  const { guestMode = [], userMode = [] } = header;

  return {
    ...mergeDeepLeft(defaultSettings, newSettings),
    siteConfig: mergeDeepRight(
      { ...REQUIRED_SITE_CONFIG_SETTINGS, ...defaultSiteConfig },
      siteConfig
    ),
    otherSettings: mergeDeepRight(defaultOtherSettings, otherSettings),
    sports: mergeDeepRight(defaultSettings.sports, sports),
    generalRoutes: {
      ...defaultSettings.generalRoutes,
      ...newSettings.generalRoutes
    },
    footer: isEmpty(flatten(footerComponents))
      ? { ...defaultSettings.footer }
      : { ...footer },
    footerMobile: isEmpty(flatten(footerComponents))
      ? { ...defaultSettings.footerMobile }
      : { ...footerMobile },
    componentsMobile: isEmpty(flatten(footerComponents))
      ? { ...defaultSettings.componentsMobile }
      : { ...componentsMobile },
    header:
      isEmpty(flatten(guestMode)) && isEmpty(flatten(userMode))
        ? { ...defaultSettings.header }
        : { ...header },
    userMenu: isEmpty(userMenu) ? [...defaultSettings.userMenu] : [...userMenu],
    userSegments: isEmpty(userSegments)
      ? defaultSettings.userSegments
      : userSegments,
    supportSettings: mergeDeepRight(defaultSupportSettings, supportSettings),
    mobileAppBanner: isEmpty(mobileAppBanner)
      ? defaultSettings.mobileAppBanner
      : mobileAppBanner,
    mobileNavigation: isEmpty(mobileNavigation)
      ? defaultSettings.mobileNavigation
      : mobileNavigation,
    gameTypes: isEmpty(gameTypes) ? defaultSettings.gameTypes : gameTypes,
    menuTags: isEmpty(menuTags) ? defaultSettings.menuTags : menuTags,
    promoTags: isEmpty(promoTags) ? defaultSettings.promoTags : promoTags
  };
};

export const parseSettings = (settings, pageType) => {
  const deviceConfig = settings[pageType];
  const parsedSettings = omit(
    [
      FORM_FACTORS_MAP.DESKTOP,
      FORM_FACTORS_MAP.TABLET,
      FORM_FACTORS_MAP.MOBILE
    ],
    settings
  );

  return {
    ...parsedSettings,
    ...deviceConfig,
    footerMobile: settings?.mobile?.footer,
    componentsMobile: settings?.mobile?.components,
    userSegments: normalizeSegments(settings.userSegments)
  };
};

export const normalizeDefaultSettings = (settings, pageType) => {
  const parsedDefaultSettings = parseSettings(settings, pageType);

  return {
    ...parsedDefaultSettings,
    siteConfig: {
      ...REQUIRED_SITE_CONFIG_SETTINGS,
      ...(parsedDefaultSettings?.siteConfig || {})
    }
  };
};
