import { useEffect, useContext } from 'react';
import { useNotifications, NOTIFICATION_TYPES } from '@namespace/notifications';
import { UserContext } from '@namespace/user';

export const useSuspendedUser = () => {
  const { customNotification } = useNotifications();
  const [user] = useContext(UserContext);
  const { accountStatuses = {} } = user;
  const { isSuspendedUser } = accountStatuses;

  useEffect(() => {
    if (isSuspendedUser) {
      customNotification({
        notificationType: NOTIFICATION_TYPES.SUSPENDED_USER,
        timeout: null
      });
    }
  }, [isSuspendedUser]);
};
