import { forwardRef } from 'react';
import { Box } from '@alf/uikit';
import EventHead from '../../../EventHead';
import Title from '../Title';
import EventIcons from '../../../EventIcons';
import EventParticipants from '../../../EventParticipants';
import EventFavoriteStar from '../../../EventFavoriteStar';
import EventMarket from '../../../EventMarket';
import LiveScore from '../../../LiveScore';
import EmptyMarket from '../../../EventMarketContainer/EmptyMarket';
import { LINE_VIEW } from '../../../../constants';
import styles from './index.module.css';

const InnerItem = forwardRef(
  (
    {
      className,
      eventLink,
      title,
      isShowFlag,
      shouldRenderEventMarket,
      eventId,
      marketId,
      outcomeIds,
      marketTemplateId,
      resultTypeId,
      isShowShortName,
      isShowStatUrl,
      isDesktop,
      isLiveEvents,
      gap
    },
    ref
  ) => (
    <Box
      data-role={`event-card-${eventId}-wrapper`}
      ref={ref}
      direction="column"
      className={`${styles.event} ${className}`}
      style={gap && { marginRight: gap }}
    >
      <Title
        dataRole={`event-card-${eventId}-title`}
        eventId={eventId}
        link={eventLink}
        title={title}
        isShowFlag={isShowFlag}
      />
      <Box>
        <EventHead
          lineView={LINE_VIEW.BUTTON}
          isShowEventGroup={false}
          eventId={eventId}
        />
        <EventIcons
          isShowStatUrl={isShowStatUrl}
          eventId={eventId}
          isShowFavorite={false}
          isIconsClickable={isDesktop}
          isHideUnselectedFavorite={true}
          isLiveEvents={isLiveEvents}
        />
      </Box>
      <Box>
        <EventParticipants
          eventId={eventId}
          isDisplayAdditionalData={true}
          isActiveLink={true}
          linkToEventDetail={eventLink}
        />
        <LiveScore isShortVersion={true} eventId={eventId} />
      </Box>
      <Box align="center" justify="between" padding="s">
        <EventFavoriteStar
          size="l"
          eventId={eventId}
          className={styles.favorite}
        />
        {shouldRenderEventMarket ? (
          <Box flexGrow="1">
            <EventMarket
              marketId={marketId}
              outcomeIds={outcomeIds}
              marketTemplateId={marketTemplateId}
              resultTypeId={resultTypeId}
              isShowName={!isShowShortName}
              isShowShortName={isShowShortName}
              lineView={LINE_VIEW.BUTTON}
            />
          </Box>
        ) : (
          <Box flex="1 1 100%">
            <EmptyMarket marketTemplateId={marketTemplateId} />
          </Box>
        )}
      </Box>
    </Box>
  )
);

export default InnerItem;
