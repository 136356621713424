import { DateTime, Duration } from 'luxon';
import { detectFormat } from '../utils';

const WEEK_DAYS_MAP = {
  MONDAY: 'weekdays.monday',
  TUESDAY: 'weekdays.tuesday',
  WEDNESDAY: 'weekdays.wednesday',
  THURSDAY: 'weekdays.thursday',
  FRIDAY: 'weekdays.friday',
  SATURDAY: 'weekdays.saturday',
  SUNDAY: 'weekdays.sunday'
};

const WEEK_DAYS_SHORT_MAP = {
  MONDAY: 'weekdays.mon',
  TUESDAY: 'weekdays.tue',
  WEDNESDAY: 'weekdays.wed',
  THURSDAY: 'weekdays.thu',
  FRIDAY: 'weekdays.fri',
  SATURDAY: 'weekdays.sat',
  SUNDAY: 'weekdays.sun'
};

const MONTHS = [
  'month.january',
  'month.february',
  'month.march',
  'month.april',
  'month.may',
  'month.june',
  'month.july',
  'month.august',
  'month.september',
  'month.october',
  'month.november',
  'month.december'
];

export const formatters = (timezone, formats = {}, t) => ({
  getDateTime: (props = {}) => {
    const {
      date: customDate, // not set, string, timestamp, Date, DateTime
      format = '',
      customTimezone = timezone,
      withTZ = true,
      fromUTC = false
    } = props;
    let newDate = null;
    const date = customDate || DateTime.now();

    if (date instanceof DateTime) {
      // if DateTime instance
      newDate = date;
    } else if (format) {
      newDate = DateTime.fromFormat(date, format);
    } else if (
      date.toString().length === 10 &&
      /^[\d]+$/.test(date.toString())
    ) {
      // if fromSeconds
      console.warn(
        'date from seconds will deprecate soon. Please, multiple it for 1000 if need or use other data format'
      );
      newDate = DateTime.fromSeconds(Number.parseInt(date, 10));
    } else if (date.toString().length > 10) {
      // if fromMiliSeconds or ISO format
      const jsDate = /^[\d]+$/.test(date.toString())
        ? Number.parseInt(date, 10)
        : Date.parse(date);
      if (!Number.isNaN(jsDate)) {
        newDate = DateTime.fromMillis(jsDate);
      }
    }

    if (!newDate) {
      // if format is not set - try to detect
      // and get dateTime instance by format
      console.warn('There is no custom format detected');
      newDate = DateTime.fromFormat(date, detectFormat(date));
    }

    // set additional conversations
    if (newDate) {
      if (fromUTC) {
        newDate = newDate.plus({ minutes: newDate.offset });
      }

      if (withTZ) {
        newDate = newDate.setZone(customTimezone);
      }
    }

    return newDate;
  },

  localizeWeekDay: (weekDay, short = false) =>
    t((short ? WEEK_DAYS_SHORT_MAP : WEEK_DAYS_MAP)[weekDay.toUpperCase()]),

  localizeMonth: (month) => t(MONTHS[month]),

  getFormat: (format) => formats[format],

  getFormats: () => formats,

  msToHoursAndMinutes: (milliseconds) =>
    Duration.fromMillis(milliseconds).toFormat('hh:mm'),

  localTimeIsActual: (from, to, getDateTime) => {
    const today = getDateTime();
    if (from && to) {
      return (
        today > getDateTime({ date: from }) && today < getDateTime({ date: to })
      );
    }

    if (from) {
      return today > getDateTime({ date: from });
    }

    if (to) {
      return today < getDateTime({ date: to });
    }

    return false;
  },

  fromMillis: (date, format) =>
    DateTime.fromMillis(new Date(date).getTime()).toFormat(format) || ''
});
