import { useContext, useState } from 'react';
import { UserContext } from '@namespace/user';
import { scrollTo } from '@namespace/helpers';

export const useUpdateActiveWallet = () => {
  const [user, userActions] = useContext(UserContext);
  const { activeWallet } = user;
  const [isLoading, setIsLoading] = useState(false);
  const { UPDATE_ACTIVE_WALLET } = userActions;

  const changeActiveWalletHandler = async (wallet) => {
    if (isLoading || wallet === activeWallet) {
      return;
    }
    setIsLoading(true);
    try {
      await UPDATE_ACTIVE_WALLET(wallet);
      scrollTo();
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  return { isLoading, changeActiveWalletHandler };
};

export default useUpdateActiveWallet;
