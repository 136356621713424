export const PAYMENT_SERVICE_IDS = {
  TERMINALS: [101],
  EPAY: [6000],
  AIR_CASH: [824, 10096],
  ABON: [825, 10097],
  CORVUS_PAY: [10200, 10000],
  POSLOVNICA: [221, 208],
  PAY_CEK: 10167,
  GOOGLE_PAY: [10122, 10132],
  APPLE_PAY: [10124, 10117],
  APPLE_PAY_DIRECT: [10214],
  BANK_CARD: [10040],
  EPAY_BANK_CARD: [10119, 10120],
  NETELLER: [
    800,
    801,
    802,
    803,
    804,
    805,
    806,
    807,
    808,
    809,
    810,
    811,
    812,
    813,
    814,
    815,
    817,
    818,
    819,
    820,
    10213,
    10268
  ],
  ECO_PAYZ: [9007, 9008, 9009, 10068, 10069, 10070, 10090, 10091],
  HEXO_PAY: [10063, 10065, 10066],
  SKRILL: [106, 107, 109, 110, 111, 821, 823],
  ADV_CASH: [10003, 10004, 10005, 10007],
  MUCH_BETTER: [10050, 10051, 10052],
  SAFE_CHARGE: [9014, 9017, 9020],
  PRAXIS: [10093, 10094, 10095, 10113, 10114, 10115, 10154, 10155, 10199],
  RAPID_TRANSFER: [10080, 10081, 10082, 10083, 10084, 10085, 10086, 10087],
  COINSPAID: [10134, 10137, 10138, 10156, 10157, 10158, 10159, 10160, 10181],
  PAYTM: [10184],
  UPI: [10189],
  UPI_P2P: [10185],
  PHONE_PE_P2P: [10188],
  IMPS: [10190],
  E_EX_WALLET: [10201],
  E_EX_WALLET_CRYPTO: [10237, 10238, 10239],
  BOLETO: [10196],
  PIX: [10195],
  INTER_KASSA: [10194],
  SPORT_PAY: [10235],
  PAYMENT_IQ: [10240],
  AVAZA: [226]
};

export const CRYPTO_SIDS = [
  ...PAYMENT_SERVICE_IDS.COINSPAID,
  ...PAYMENT_SERVICE_IDS.E_EX_WALLET_CRYPTO
];

export const PAYMENT_TYPES = {
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdraw'
};

export const TAX_BY_PAYMENT_TYPE = {
  [PAYMENT_TYPES.DEPOSIT]: 'deposit',
  [PAYMENT_TYPES.WITHDRAW]: 'withdrawal'
};

export const PAYMENT_FORM_VIEW_TYPES = {
  ADD: 'addToken',
  LIST: 'tokenList'
};

export const icons = {
  SUCCESS: 'general/success'
};
export const eExWalletBotLink = 'https://t.me/eexwalletbot?start=favuah-';
export const eExWalletLink =
  'https://gdelambo.notion.site/FAVBET-EEXWALLET-eebb72ab8bbd4c9386b0a984b21c0ac4';

export const PAYMENT_TOKEN_TYPES = {
  TOKEN_OBJECT: 'paymentTokenObject'
};

export const pixKeyTypes = [`CPF`, `EMAIL`, `PHONE`, `EVP`];
export const CPF_NUMBER = `CPF`;

export const CARD_HASH_SERVICE_KEYS = ['BANK_CARD', 'EPAY_BANK_CARD'];

export const EXCLUDED_PAYMENTS = ['AVAZA', 'POSLOVNICA'];

export const MODAL_FORM_VIEW_TYPES = {
  ADD: 'add',
  INFO: 'info',
  EDIT: 'edit'
};

export const PAN_TYPE = {
  PAN: 'pan',
  WALLET: 'wallet'
};

// https://wiki.betinvest.work/display/MNA/Bank+Card
// per-bit flags!
export const PAN_STATUS = {
  NEW: 1,
  FIRST_DEPOSIT: 2,
  VERIFIED_BY_RMT: 4,
  BLOCKED_BY_RMT: 8,
  BLOCKED_BY_CLIENT: 16,
  FIRST_PAYOUT: 32
};

export const PAN_STATUS_BY_CARD_STATUS = {
  isNew: 'NEW',
  isFirstDeposit: 'FIRST_DEPOSIT',
  isVerified: 'VERIFIED_BY_RMT',
  isBlockedByRisk: 'BLOCKED_BY_RMT',
  isBlockedByClient: 'BLOCKED_BY_CLIENT',
  isFirstPayout: 'FIRST_PAYOUT'
};
