import { memo, useMemo } from 'react';
import { useI18n } from '@namespace/i18n';
import ScoreDetails from '../../Details';
import { COLUMN_TYPES, RESULT_TYPE_IDS } from '../../../../constants';
import {
  TOTAL_SCOPE_ID_BY_SPORT,
  TOTAL_SYMBOL_BY_SPORT
} from '../../constants';
import {
  getDetailedScoresForTable,
  getEventValues,
  stripeScore
} from '../../utils';

const Basketball3x3 = ({
  byResultType,
  total,
  sportId,
  eventResultId,
  isShortVersion,
  lineView
}) => {
  const { t } = useI18n();

  const calculatedFullFormattedScore = useMemo(() => {
    let fullFormattedScore = [
      {
        type: COLUMN_TYPES.TOTAL,
        tag: t(TOTAL_SYMBOL_BY_SPORT.default),
        scores: getEventValues(total, TOTAL_SCOPE_ID_BY_SPORT[sportId])
      }
    ];

    if (eventResultId === RESULT_TYPE_IDS.OVERTIME) {
      fullFormattedScore = [
        ...fullFormattedScore,
        ...stripeScore(
          getDetailedScoresForTable(byResultType),
          sportId,
          eventResultId,
          false
        )
      ];
    }

    return fullFormattedScore;
  }, [byResultType, eventResultId, sportId, t, total]);

  return (
    <ScoreDetails
      scoreData={calculatedFullFormattedScore}
      isShortVersion={isShortVersion}
      lineView={lineView}
    />
  );
};

export default memo(Basketball3x3);
