import { useEffect, useState } from 'react';
import { camelizeKeys } from 'humps';
import { uniq } from 'lodash';
import {
  bulletSend,
  bulletSubscribe,
  bulletUnsubscribe,
  throttleEvents
} from '@namespace/socket';
import { sportsbookActions, useSportsbookStore } from '../../store';
import { bulletReducersMap } from '../../store/reducers/bullet';
import { LINE_KEY } from '../../constants';
import { buildLineRequest } from './utils';

const { UPDATE_STATE } = sportsbookActions;

export const useLineBullet = () => {
  const { lines, events, sportsBookHash } = useSportsbookStore([
    'lines',
    'events',
    'sportsBookHash'
  ]);
  const [request, setRequest] = useState(undefined);
  const [messageString, setMessageString] = useState('');

  const [liveHash, hash] = sportsBookHash;

  useEffect(() => {
    if (hash && liveHash) {
      const [detailLineKey] =
        Object.entries(lines).find(([key]) =>
          key.includes(LINE_KEY.DETAIL_EVENT)
        ) || [];
      const { [detailLineKey]: detailLine, ...otherLines } = lines;
      const sortedLines = detailLine
        ? [...Object.entries(otherLines), [detailLineKey, detailLine]]
        : Object.entries(otherLines);

      const allMarketsByEventIds = sortedLines.reduce(
        (memo, [key, { eventsIds = [], eventsMarketsIds = {} }]) => ({
          ...memo,
          ...eventsIds.reduce(
            (acc, eventId) => ({
              ...acc,
              [eventId]:
                key === detailLineKey
                  ? null
                  : uniq([
                      ...(eventsMarketsIds[eventId] || []),
                      ...(memo[eventId] || [])
                    ])
            }),
            {}
          )
        }),
        {}
      );

      const newMessageString = JSON.stringify(allMarketsByEventIds);
      const isChangedEventMarkets = messageString !== newMessageString;

      const eventsRequest = isChangedEventMarkets
        ? buildLineRequest({
            hash,
            liveHash,
            events,
            allMarketsByEventIds
          })
        : null;
      if (eventsRequest) {
        setRequest(eventsRequest);
      }

      setMessageString(newMessageString);
    }
  }, [events, lines, messageString, hash, liveHash]);

  useEffect(() => {
    if (request) {
      bulletSubscribe({
        name: 'line-bullet-subscribe',
        types: [],
        message: request,
        callback: () => {}
      });

      return () => bulletUnsubscribe('line-bullet-subscribe');
    }

    return undefined;
  }, [request]);

  useEffect(() => {
    bulletSubscribe({
      name: 'line-bullet',
      types: Object.keys(bulletReducersMap),
      callback: throttleEvents((messages) => {
        if (Array.isArray(messages) && messages.length) {
          UPDATE_STATE(camelizeKeys(messages));
        }
      })
    });

    return () => {
      bulletSend({ subscribe_multiline: [] });
      bulletUnsubscribe('line-bullet');
    };
  }, []);
};
