import { useContext } from 'react';
import { PageContext } from '@namespace/cms';
import { Box } from '@alf/uikit';
import PredictionsAccordion from '../../common/PredictionsAccordion';
import styles from './index.module.css';

const PredictionsFooter = ({ id }) => {
  const { page } = useContext(PageContext);
  const { components = {} } = page;
  const { config = {} } = components[id];
  const { snippetId = 'test' } = config;

  return (
    <Box
      direction="column"
      align="center"
      justify="center"
      className={styles.wrapper}
    >
      <PredictionsAccordion
        snippetId={snippetId}
        title="HowItWorksBottom"
        theme="gray"
      />
    </Box>
  );
};

export default PredictionsFooter;
