import { isString } from 'lodash';

export const normalizeMock = (storeMock) => {
  if (!storeMock) {
    return storeMock;
  }

  const snapshotName = isString(storeMock) ? storeMock : storeMock.snapshotName;

  return {
    ...storeMock,
    snapshotName
  };
};
