import { useContext } from 'react';
import { UserContext } from '../../context';
import { USER_ROLES } from '../../constants';

const useUserInfoLoaded = () => {
  const [user] = useContext(UserContext);
  const { role: userRole, userInfoLoaded } = user;
  return { userInfoLoaded, isGuest: userRole === USER_ROLES.GUEST };
};

export default useUserInfoLoaded;
