import { useState } from 'react';
import { Box, AText } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { useGeneralRoutes } from '@namespace/cms';
import TimeZoneSwitcher from '../TimeZoneSwitcher';
import CoefTypeSwitcher from '../CoefTypeSwitcher';
import LineView from '../LineView';
import styles from './index.module.css';

const UserSettingsBurger = ({
  isDisplayLiveCalendar = false,
  showTimeZoneSelector = true,
  showCoefSwitcher = false,
  showLineSettings = false,
  showSettings = false
}) => {
  const { push } = useHistory();
  const { t } = useI18n();
  const { liveCalendar } = useGeneralRoutes();

  const [isOpen, setIsOpen] = useState(false);

  if (!showSettings) {
    return null;
  }

  return (
    <>
      <Box justify={isDisplayLiveCalendar ? 'between' : 'end'}>
        {isDisplayLiveCalendar && (
          <Box align="center" onClick={() => push(liveCalendar)}>
            <Icon
              name="icons/general/item/calendar"
              size="s"
              color="txt_color_7"
              className={styles.liveCalendarIcon}
            />
            <AText breed="BU" size="m" color="txt_color_7">
              {t('live.calendar.liveCalendarTitle')}
            </AText>
          </Box>
        )}
        <Box
          data-role="sports-settings-button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <Box right="s">
            <Icon
              size="s"
              name={
                isOpen
                  ? 'icons/general/action/close'
                  : 'icons/general/state/settings'
              }
              color="txt_color_7"
            />
          </Box>
          <AText breed="BU" size="m" color="txt_color_7">
            {t('header.submenu.settings.title')}
          </AText>
        </Box>
      </Box>

      {isOpen && (
        <Box flex="" direction="column" top="s" bottom="m">
          <Box flex="1 0 auto" direction="column">
            {showTimeZoneSelector && (
              <>
                <Box bottom="s">
                  <AText breed="R" size="m" color="txt_color_2">
                    {t('header.submenu.settings.timeZoneTitle')}
                  </AText>
                </Box>
                <Box data-role="user-settings-time-zone-switcher">
                  <TimeZoneSwitcher
                    type="header"
                    switcherClassName={styles.switcherContainer}
                    customTriggerClassNames={{
                      className: styles.customTriggerContainer
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
          <Box flex="1 0 auto" direction="column">
            {showCoefSwitcher && (
              <>
                <Box bottom="s" top="m">
                  <AText breed="R" size="m" color="txt_color_2">
                    {t('web.settings.coefficients.title')}
                  </AText>
                </Box>
                <CoefTypeSwitcher
                  switcherClassName={styles.switcherContainer}
                  customTriggerClassNames={{
                    className: styles.customTriggerContainer
                  }}
                />
              </>
            )}
          </Box>
          {showLineSettings && (
            <Box top="s" direction="column">
              <Box bottom="s">
                <AText breed="R" size="m" color="txt_color_2">
                  {t('web.settings.lineview.title.desktop')}
                </AText>
              </Box>
              <LineView iconOptions={{ size: 'm' }} />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default UserSettingsBurger;
