import { formatAsUriParameters } from '@namespace/helpers';
import { isEmpty } from 'lodash';

const getIosLink = (link, params) => {
  if (!isEmpty(params) && link) {
    let iosUrl = '';
    try {
      iosUrl = new URL(link);
    } catch {
      iosUrl = link;
    }
    const iosLink = iosUrl?.href || iosUrl;
    return `${iosLink}${iosUrl?.search ? '&' : '?'}${formatAsUriParameters(
      params
    )}`;
  }

  return link;
};

export default getIosLink;
