import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ThemeProvider } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Box from '../Box';
import AText from '../AText';
import defaultMaterialTheme from './theme';
import { FONT_SIZE_INPUT, HEIGHT_INPUT } from './constants';
import styles from './index.module.css';

const MaterialKeyboardDatePicker = ({
  name = '',
  label = '',
  minDateMessage = '',
  maxDateMessage = '',
  invalidDateMessage = '',
  placeholder = '',
  date = null,
  onChange = () => {},
  minDate = new Date('1900-01-01'),
  maxDate = new Date('2100-01-01'),
  format = 'yyyy-MM-dd',
  inputClassName = '',
  className = '',
  disabled = false,
  labelClassName = '',
  size = 's',
  labelSize = 'm',
  shouldDisableDate = () => {},
  onError = () => {},
  ...props
}) => {
  const isPlaceholder = placeholder && date === null;
  return (
    <Box className={className} direction="column">
      {label && (
        <Box
          direction="column"
          bottom="s"
          className={clsx(styles.label, labelClassName)}
        >
          <AText size={labelSize} breed="R">
            {label}
          </AText>
        </Box>
      )}

      <Box className={`${styles.datePickerWrapper} ${styles[size]}`}>
        <ThemeProvider theme={defaultMaterialTheme}>
          <KeyboardDatePicker
            name={name}
            value={date}
            onChange={onChange}
            format={format}
            minDate={minDate}
            maxDate={maxDate}
            onError={onError}
            invalidDateMessage={invalidDateMessage}
            minDateMessage={minDateMessage}
            maxDateMessage={maxDateMessage}
            emptyLabel={placeholder}
            className={inputClassName}
            disabled={disabled}
            InputProps={{
              style: {
                color: isPlaceholder
                  ? 'var(--input_txt_placeholder)'
                  : 'var(--input_txt)',
                fontSize: FONT_SIZE_INPUT[size] || FONT_SIZE_INPUT.s,
                height: HEIGHT_INPUT[size] || HEIGHT_INPUT.s,
                background: disabled
                  ? 'var(--input_bg_color_2)'
                  : 'var(--input_bg_color_1)',
                WebkitTextFillColor: isPlaceholder
                  ? 'var(--input_txt_placeholder)'
                  : 'var(--input_txt)'
              }
            }}
            shouldDisableDate={shouldDisableDate}
            {...props}
          />
        </ThemeProvider>
      </Box>
    </Box>
  );
};

MaterialKeyboardDatePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
    PropTypes.object
  ]), // it can be any parsable date, e.g. "string", Date, object (from API docs)
  onChange: PropTypes.func,
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.object
  ]), // it can be any parsable date, e.g. "string", Date, "object" (from API docs)
  maxDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.object
  ]), // it can be any parsable date, e.g. "string", Date, "object" (from API docs)
  format: PropTypes.string,
  size: PropTypes.oneOf(['xs', 's', 'm']),
  labelSize: PropTypes.oneOf(['s', 'm'])
};

export default MaterialKeyboardDatePicker;
