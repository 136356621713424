import { useReducer, createContext, useMemo } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import { actions as actionCreators } from '../actions';
import { reducers, initialState } from '../reducer';
import { types } from '../types';

export const PaymentsContext = createContext();

export const PaymentsProvider = ({ customReducers, children }) => {
  const memoReducers = useMemo(() => {
    return combineReducers(reducers, customReducers);
  }, [customReducers]);

  const [state, dispatch] = useReducer(memoReducers, initialState);

  const actions = useActions(types, dispatch, actionCreators);

  return (
    <PaymentsContext.Provider value={[state, actions]}>
      {children}
    </PaymentsContext.Provider>
  );
};
