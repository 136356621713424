import { useContext } from 'react';
import { AText, Box } from '@alf/uikit';
import { UserContext } from '@namespace/user';
import { useEventField } from '../../../../store';
import RedCard from './components/RedCard';
import Serving from './components/Serving';
import styles from './index.module.css';

const Participant = ({
  eventId,
  isDisplayAdditionalData = false,
  isDisplayServing = true,
  participantIndex
}) => {
  const finished = useEventField(eventId, 'finished');

  const participantName = useEventField(eventId, [
    'participants',
    participantIndex,
    'participantName'
  ]);

  const [state] = useContext(UserContext);
  const { settings } = state;
  const { lineViewType: lineView } = settings;

  return (
    <Box className={styles.participantMain} key={participantName}>
      <AText
        className={`${styles.participantName} ${
          lineView ? styles[lineView] : ''
        }`}
        title={participantName}
        size="m"
        overflow="ellipsis"
        color="line_txt_1"
      >
        {participantName}
        {isDisplayAdditionalData && (
          <RedCard eventId={eventId} participantIndex={participantIndex} />
        )}
        {!finished && isDisplayServing && (
          <Serving eventId={eventId} participantIndex={participantIndex} />
        )}
      </AText>
    </Box>
  );
};

export default Participant;
