import { useCallback, useContext } from 'react';
import { UserContext } from '@namespace/user';
import { MailBoxContext } from '../store/mailBox/context';
import { MESSAGE_TYPES } from '../constants';

export const useMessage = (messageId, messageType) => {
  const [mailBox, mailBoxActions] = useContext(MailBoxContext);
  const [user, userActions] = useContext(UserContext);
  const {
    selectedInboxMessages,
    selectedOutboxMessages,
    inboxMessages,
    messageDetails = {}
  } = mailBox;
  const {
    FETCH_MESSAGE,
    SELECT_INBOX_MESSAGE,
    SELECT_OUTBOX_MESSAGE,
    DELETE_MESSAGES,
    CHECK_UNREAD,
    CHECK_READ
  } = mailBoxActions;
  const {
    id: userId,
    counters: { messagesCount }
  } = user;
  const { SET_MESSAGES_COUNT } = userActions;

  const { type = messageType } = messageDetails;

  const selectMessage = useCallback(() => {
    type === MESSAGE_TYPES.INBOX
      ? SELECT_INBOX_MESSAGE({ message: messageId })
      : SELECT_OUTBOX_MESSAGE({ message: messageId });
  }, [messageId]);

  const isSelected = (type === MESSAGE_TYPES.INBOX
    ? selectedInboxMessages
    : selectedOutboxMessages
  ).includes(messageId);

  const deleteMessage = useCallback(() => {
    DELETE_MESSAGES(userId, [messageId], type);
    if (type === MESSAGE_TYPES.INBOX) {
      const { read } = inboxMessages.find(({ id }) => id === Number(messageId));
      if (!read) {
        SET_MESSAGES_COUNT(messagesCount - 1);
      }
    }
  }, [userId, messageId, type, inboxMessages, messagesCount]);

  const checkAsUnread = useCallback(async () => {
    await CHECK_UNREAD(userId, [messageId]);
    SET_MESSAGES_COUNT(messagesCount + 1);
  }, [userId, messageId, messagesCount]);

  const checkAsRead = useCallback(async () => {
    await CHECK_READ(userId, [messageId]);
    if (type === MESSAGE_TYPES.INBOX) {
      SET_MESSAGES_COUNT(messagesCount - 1);
    }
  }, [userId, messageId, type, messagesCount]);

  const getMessage = useCallback(async () => FETCH_MESSAGE(userId, messageId), [
    userId,
    messageId
  ]);

  return {
    selectMessage,
    deleteMessage,
    checkAsUnread,
    checkAsRead,
    getMessage,
    isSelected,
    message: messageDetails
  };
};
