import { useContext, useEffect } from 'react';
import { BetSlipContext } from '../../store/context';

export const CLEAR_COUPON_TIMEOUT = 10000;

const useClearBetslipTimeout = ({ shouldClearSuccessCoupon, isSuccess }) => {
  const [, actions] = useContext(BetSlipContext);

  const { SET_STORE_AMOUNT, CLEAN_STORE } = actions;

  useEffect(() => {
    let timeoutId;
    if (shouldClearSuccessCoupon && isSuccess) {
      timeoutId = setTimeout(() => {
        (async () => {
          try {
            await SET_STORE_AMOUNT(0);
            await CLEAN_STORE();
          } catch (e) {
            console.error(e);
          }
        })();
      }, CLEAR_COUPON_TIMEOUT);
    }
    return () => clearTimeout(timeoutId);
  }, [shouldClearSuccessCoupon, isSuccess]);
};

export default useClearBetslipTimeout;
