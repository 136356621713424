import { MATCH_RESULT_MARKET_TEMPLATE_ID } from '../../../../constants';

export const normalizeEvents = ({ events, f }) => {
  const DATE_FORMATS_TYPES = f.getFormats();

  return events
    .map(
      ({
        outcomes,
        participants,
        eventDtDate: date,
        eventId,
        ...restEventData
      }) => ({
        ...restEventData,
        eventId,
        participants: participants.sort(
          (a, b) => a.participantNumber - b.participantNumber
        ),
        ...(outcomes.length && {
          market: {
            eventId,
            serviceId: 0,
            marketId: outcomes[0].marketId,
            marketTemplateId: MATCH_RESULT_MARKET_TEMPLATE_ID
          }
        }),
        outcomes: outcomes.map(({ outcomeId, outcomeCoef }) => ({
          eventId: outcomes[0].eventId,
          marketId: outcomes[0].marketId,
          serviceId: outcomes[0].serviceId,
          outcomeId,
          outcomeCoef,
          outcomeVisible: true
        })),
        serviceId: 0,
        eventDt: f
          .getDateTime({
            date,
            format: DATE_FORMATS_TYPES.API_WITH_TIME,
            withTZ: false
          })
          .toSeconds(),
        headMarkets: outcomes.length
          ? [
              {
                eventId: outcomes[0].eventId,
                marketId: outcomes[0].marketId,
                serviceId: outcomes[0].serviceId,
                outcomes: outcomes.map(({ outcomeCoef, outcomeId }) => ({
                  outcomeCoef,
                  outcomeId,
                  outcomeVisible: true
                }))
              }
            ]
          : []
      })
    )
    .sort((a, b) => a.eventDt - b.eventDt);
};
