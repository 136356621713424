import { useI18n } from '@namespace/i18n';
import { AText } from '@alf/uikit';
import TextNotification from './TextNotification';

const StatusNotVerified = () => {
  const { t } = useI18n();
  return (
    <TextNotification>
      <AText size="m" color="po_txt_color_1" breed="B">
        {t(`profileDocuments.information.notVerified`)}
      </AText>
    </TextNotification>
  );
};

export default StatusNotVerified;
