import { matchPath } from 'react-router-dom';

export const matchUrlCondition = ({ pathname }) => ({
  url,
  matchExact = true
}) => {
  if (url == null) {
    return false;
  }

  return matchPath(
    {
      path: url,
      end: Boolean(matchExact)
    },
    pathname
  );
};
