import { get } from '@namespace/helpers';

export const getBonusSystemList = () =>
  get('/service/prewager/campaigns').then(({ data, status, errorCode }) => {
    const { campaigns } = data;

    return status === 'success'
      ? Promise.resolve(campaigns)
      : Promise.reject(errorCode);
  });
