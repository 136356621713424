import { last } from 'lodash';
import isExtensionValid from '../isExtensionValid';
import isOverSize from '../isOverSize';

const isSizeValid = (item, limit) => !isOverSize([item], limit);

const makeValidationErrors = (base, items, opts) => {
  const newValidationErrors = {};
  const isTotalOverSized = isOverSize(items, opts.maxSize);

  if (isTotalOverSized) {
    newValidationErrors[last(items).name] = { size: true };
  }

  for (const item of items) {
    if (!newValidationErrors[item.name]) {
      newValidationErrors[item.name] = {};
    }

    if (!isExtensionValid(item, opts.formats)) {
      newValidationErrors[item.name].format = true;
    } else if (!isSizeValid(item, opts.maxSize)) {
      newValidationErrors[item.name].size = true;
    }
  }

  return newValidationErrors;
};

export default makeValidationErrors;
