import { useEffect } from 'react';
import { bulletSubscribe, bulletUnsubscribe } from '@namespace/socket';
import { window } from '@namespace/helpers';

const useDepositAnalytic = (enabled) => {
  // important, subscription is needed here for reconnection case
  useEffect(() => {
    if (enabled) {
      bulletSubscribe({
        name: 'on-deposit-google-analytics',
        types: ['deposit'],
        callback: ({
          action,
          deposit_counter: depositCounter = false,
          deposit_amount: depositAmount,
          currency
          // #region "socket response example"
          /*
            {
              "type":"deposit",
              "action":"update",
              "service_id":9020,
              "summ":"6236.70",
              "currency":"RON",
              "user_id":28554,
              "payment_instrument_id":236,
              "wallet_id":"285541626976522",
              "wallet_account_id":"285541626976522",
              "wallet_hash":"YTo0OntzOjIxOiJwYXltZW50X2luc3RydW1lbnRfaWQiO2k6MjM2O3M6OToid2FsbGV0X2lkIjtzOjE1OiIyODU1NDE2MjY5NzY1MjIiO3M6MTc6IndhbGxldF9hY2NvdW50X2lkIjtzOjE1OiIyODU1NDE2MjY5NzY1MjIiO3M6ODoiY3VycmVuY3kiO3M6MzoiUk9OIjt9",
              "deposit_counter":129,
              "deposit_amount": 34
            }
          */
          // #endregion
        }) => {
          if (
            action === 'update' &&
            depositCounter &&
            depositCounter <= 1 &&
            window.fbq
          ) {
            window.fbq('track', 'Purchase', {
              value: depositAmount || 0,
              currency
            });
          }
        }
      });
    }
    return () => bulletUnsubscribe('on-deposit-google-analytics');
  }, [enabled]);
};

export default useDepositAnalytic;
