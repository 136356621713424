// @TODO refactor file
import { useCallback, useContext, useMemo } from 'react';
import { ReferenceContext, useReferenceState } from '@namespace/common';
import { useNotifications } from '@namespace/notifications';
import { UserContext, useSaveDocuments } from '@namespace/user';
import { useI18n } from '@namespace/i18n';
import { Box } from '@alf/uikit';
import {
  FORM_COMPONENTS_TYPES,
  FORM_VALIDATION_TYPES,
  useFormMethods,
  FormBuilder,
  DOC_DATE_PICKER_TYPES
} from '@namespace/forms';
import DocumentStatusBar from '../DocumentStatusBar';
import DocumentStatusInformation from '../DocumentStatusInformation';
import { DOCUMENT_STATUSES, DOCUMENT_TYPES } from '../../constants';
import {
  DOCUMENT_NUMBER_FILED_NAME,
  DOCUMENT_PLACE_DATE,
  DOCUMENT_EXPIRE_DATE,
  DOCUMENT_PLACE,
  DOCUMENT_TYPE_ID
} from './constants';
import makeDataForApi from '../../utils/makeDataForApi';
import ProfileDocumentsFormInfoMessage from '../ProfileDocumentsFormInfoMessage';
import styles from './index.module.css';
import useDocumentDataReset from './hooks/useDocumentDataReset';

const DocumentForm = ({
  isDisabled,
  document = {},
  config = {},
  onSuccess,
  defaultCitizenshipCountry = '',
  hideInfoMessage,
  hideButton,
  showDocumentsInfo = false,
  disabledCitizenship,
  classNames = {}
}) => {
  useReferenceState();
  const { t, f } = useI18n();
  const {
    minDateOffset,
    maxDateOffset,
    minDateOffsetExpiry,
    maxDateOffsetExpiry,
    documentDateValidationMap,
    noResetFormData,
    documentsNumberValidationMap,
    defaultDocType,
    isNeedExpiryDocField = false,
    showInn = false,
    inlineDocumentNumberWithDocumentType = false
  } = config;

  const { datePickerIconClassName = '' } = classNames;

  const documentTypeOptions = useMemo(
    () => [
      {
        name: t('personalOffice.documents.documentTypePassport'),
        value: DOCUMENT_TYPES.PASSPORT
      },
      {
        name: t('personalOffice.documents.documentTypeIDCard'),
        value: DOCUMENT_TYPES.IDCARD
      }
    ],
    [t]
  );
  const { countries } = useContext(ReferenceContext);
  const [user, userActions] = useContext(UserContext);
  const { countryId } = user;
  const { SET_DOCUMENTS, FETCH_USER_DATA } = userActions;
  const {
    status,
    documentNumber = '',
    documentPlace = '',
    documentPlaceDate = '',
    documentExpireDate = '',
    documentTypeId = defaultDocType || 1,
    citizenship
  } = document;

  const citizenshipCountries = useMemo(
    () =>
      countries.map(({ countryName, iso1Code }) => ({
        name: countryName,
        value: iso1Code
      })),
    [countries]
  );

  const getCitizenship = () => {
    return citizenship || countryId || defaultCitizenshipCountry;
  };

  const defaultValues = {
    documentNumber,
    documentPlace,
    documentPlaceDate,
    documentExpireDate,
    documentTypeId,
    citizenship: getCitizenship(),
    status: DOCUMENT_STATUSES.NOT_VERIFIED,
    verified: false,
    ...(showInn && { pin: user.pin })
  };

  const formMethods = useFormMethods({ defaultValues });
  const { watch, setValue, clearErrors } = formMethods;
  const countryField = watch('citizenship');
  const docType = watch(DOCUMENT_TYPE_ID);

  useDocumentDataReset({
    setValue,
    clearErrors,
    documentTypeId: docType
  });

  const { errorNotification, successNotification } = useNotifications();
  const { setExec, setDocumentsData, isFetching } = useSaveDocuments(
    undefined,
    (data) => {
      SET_DOCUMENTS([{ ...defaultValues, ...data }]);
      FETCH_USER_DATA();
      successNotification(t('personalOffice.documents.successMessage'));
      if (onSuccess) {
        onSuccess();
      }
    },
    ({ errorCode = 'accounting_error_6' }) => {
      errorNotification(t(`accountingMessages.${errorCode}`));
    }
  );
  const handleWeekDayFormat = useCallback(
    (weekDay) => f.localizeWeekDay(weekDay, true),
    [f]
  );
  const submit = useCallback(
    async (fieldsData) => {
      if (isDisabled) {
        if (onSuccess) {
          await onSuccess();
        }
      } else {
        setDocumentsData(makeDataForApi({ f, withTZ: false, ...fieldsData }));
        setExec(true);
      }
    },
    [f, setExec, onSuccess, isDisabled, setDocumentsData]
  );

  const componentsList = [
    {
      type: FORM_COMPONENTS_TYPES.SELECT,
      name: 'citizenship',
      label: 'personalOffice.documents.citizenship',
      options: citizenshipCountries,
      disabled: disabledCitizenship || isDisabled,
      classNames: {
        containerClassName: styles.fieldWrap
      }
    },
    ...(inlineDocumentNumberWithDocumentType
      ? [
          {
            type: FORM_COMPONENTS_TYPES.DOCUMENT_NUMBER_WITH_TYPE,
            documentName: DOCUMENT_NUMBER_FILED_NAME,
            typeName: DOCUMENT_TYPE_ID,
            dependentName: DOCUMENT_TYPE_ID,
            labelDoc: 'personalOffice.documents.documentNumber',
            placeholderDoc:
              'personalOffice.documents.documentNumberPlaceholder',
            labelType: 'personalOffice.documents.documentType',
            placeholderType: 'personalOffice.documents.selectDocumentType',
            options: documentTypeOptions,
            disabled: isDisabled,
            noResetFormData,
            documentsNumberValidationMap,
            classNames: { wrapperClassName: styles.fieldWrap },
            validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
            clearAfterChange: {
              documentNumber: '',
              documentPlaceDate: '',
              documentPlace: ''
            },
            documentNumberProps: {
              controlProps: {
                // In order to reflect "documentsNumberValidationMap" update we're updating Control's "key" field
                key: countryField
              }
            }
          }
        ]
      : [
          {
            type: FORM_COMPONENTS_TYPES.SELECT,
            name: DOCUMENT_TYPE_ID,
            classNames: {
              containerClassName: styles.fieldWrap
            },
            label: 'personalOffice.documents.documentType',
            placeholder: 'personalOffice.documents.selectDocumentType',
            options: documentTypeOptions,
            clearAfterChange: {
              documentNumber: '',
              documentPlaceDate: '',
              documentPlace: ''
            },
            noResetFormData
          },
          {
            type: FORM_COMPONENTS_TYPES.DOCUMENT_NUMBER_FIELD,
            name: DOCUMENT_NUMBER_FILED_NAME,
            key: `${
              docType === 1 ? 'passport' : 'id-card'
            }-number-field-${countryField}`,
            dependentName: DOCUMENT_TYPE_ID,
            classNames: {
              wrapperClassName: styles.fieldWrap
            },
            label: 'personalOffice.documents.documentNumber',
            placeholder: 'personalOffice.documents.documentNumberPlaceholder',
            validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
            documentsNumberValidationMap:
              countryField === 'UA' ? documentsNumberValidationMap : undefined
          }
        ]),
    {
      type: FORM_COMPONENTS_TYPES.DOCUMENT_DATE_PICKER,
      name: DOCUMENT_PLACE_DATE,
      label: 'personalOffice.documents.documentDate',
      placeholder: 'personalOffice.documents.documentDatePlaceholder',
      formatWeekDay: handleWeekDayFormat,
      classNames: {
        datePickerWrapperClassName: styles.fieldWrap,
        iconClassName: datePickerIconClassName
      },
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
      dependentName: DOCUMENT_TYPE_ID
    },
    ...(isNeedExpiryDocField
      ? [
          {
            type: FORM_COMPONENTS_TYPES.DOCUMENT_DATE_PICKER,
            name: DOCUMENT_EXPIRE_DATE,
            key: 'expires-document',
            label: 'personalOffice.documents.documentExpiryDate',
            placeholder: 'personalOffice.documents.documentDatePlaceholder',
            dataPickerType: DOC_DATE_PICKER_TYPES.EXPIRY,
            ...(minDateOffsetExpiry && { minOffsetProps: minDateOffsetExpiry }),
            ...(maxDateOffsetExpiry && { maxOffsetProps: maxDateOffsetExpiry }),
            formatWeekDay: handleWeekDayFormat,
            classNames: {
              datePickerWrapperClassName: styles.fieldWrap
            },
            validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
            dependentName: 'documentTypeId'
          }
        ]
      : []),
    {
      type: FORM_COMPONENTS_TYPES.TEXTAREA,
      name: DOCUMENT_PLACE,
      classNames: {
        wrapperClassName: styles.fieldWrap
      },
      label: 'personalOffice.documents.documentPlace',
      placeholder: 'personalOffice.documents.documentPlacePlaceholder',
      resize: 'none',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED]
    },
    {
      type: FORM_COMPONENTS_TYPES.SUBMIT_BUTTON,
      value: 'personalOffice.documents.confirm',
      classNames: {
        className: `${styles.saveBtn}`
      },
      disabled: false
    }
  ];

  if (showInn) {
    componentsList.splice(1, 0, {
      type: FORM_COMPONENTS_TYPES.INPUT_FIELD,
      name: 'pin',
      label: 'personalOffice.documents.inn.label',
      placeholder: 'personalOffice.documents.inn.placeholder',
      validationTypes: [
        FORM_VALIDATION_TYPES.REQUIRED,
        {
          type: FORM_VALIDATION_TYPES.REGEXP_VALIDATOR,
          parameters: [
            /^[\d]{10}$/,
            'personalOffice.documents.inn.validation.error'
          ]
        }
      ],
      classNames: {
        wrapperClassName: styles.fieldWrap
      }
    });
  }

  if (citizenshipCountries.length) {
    return (
      <>
        {!hideInfoMessage && <ProfileDocumentsFormInfoMessage />}
        <Box direction="column" className={styles.formWrap}>
          <DocumentStatusBar className={styles.formWrap} document={document} />
          <Box className={styles.formWrap} direction="column">
            <FormBuilder
              components={componentsList}
              formMethods={formMethods}
              onSubmit={submit}
              hideButton={hideButton}
              formProps={{
                minDateOffset,
                maxDateOffset,
                documentDateValidationMap,
                isLoading: isFetching,
                isDisabled
              }}
            />
          </Box>
          {showDocumentsInfo && status !== DOCUMENT_STATUSES.VERIFIED && (
            <DocumentStatusInformation document={document} />
          )}
        </Box>
      </>
    );
  }
  return null;
};

export default DocumentForm;
