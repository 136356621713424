import { CARD_TYPE_PARAMETERS } from '../constants';

const cardTypeValidator = (cardNumber = '') => {
  for (const { prefixes } of CARD_TYPE_PARAMETERS) {
    for (const prefix of prefixes) {
      const exp = new RegExp(`^${prefix}`);
      if (exp.test(cardNumber)) {
        return true;
      }
    }
  }
  return false;
};

export default cardTypeValidator;
