import { hide } from '@floating-ui/react-dom';

export const keepInView = () => ({
  name: 'keepInView',
  async fn(mwArgs) {
    const hideMwData = await hide().fn(mwArgs);
    const { referenceHidden, referenceHiddenOffsets } = hideMwData.data;

    if (!referenceHidden) {
      return {};
    }

    let { y } = mwArgs;
    const { top, bottom } = referenceHiddenOffsets;

    if (top > 0) {
      y += top;
    } else if (bottom > 0) {
      y -= bottom;
    }

    return { y };
  }
});
