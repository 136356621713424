import { isNil, isEmpty } from 'lodash';

export const getIsJackpotsIncludeUserCurrency = (jackpots, userCurrency) => {
  if (!userCurrency) return false; // guest

  // check that every jackpot amount field of every jackpot has amount in user currency
  return Object.values(jackpots)
    .map((jackpot) => {
      const {
        additionalCurrencies,
        maxWinner,
        lastWinner,
        activationAmountAdditionalCurrencies
      } = jackpot;

      if (isNil(additionalCurrencies?.[userCurrency])) return false;
      if (
        !isEmpty(maxWinner) &&
        isNil(maxWinner.additionalCurrencies?.[userCurrency])
      )
        return false;
      if (
        !isEmpty(lastWinner) &&
        isNil(lastWinner.additionalCurrencies?.[userCurrency])
      )
        return false;
      return !isNil(activationAmountAdditionalCurrencies?.[userCurrency]);
    })
    .every(Boolean);
};
