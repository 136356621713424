import { useContext, useEffect } from 'react';
import { DeviceContext } from '@namespace/device';
import { SiteSettingsContext } from '@namespace/cms';
// todo rewrite
import { useI18n } from '@namespace/i18n';
import { UserContext } from '@namespace/user';
import { IS_WIDGET } from '@namespace/widget';
import { NativeAppContext } from '../../store';
import getIosLink from '../../utils/getIosLink';

const useNativeAppsLinks = () => {
  const [, { SET_ANDROID_LINK, SET_IOS_LINK }] = useContext(NativeAppContext);

  const [settings] = useContext(SiteSettingsContext);
  const {
    siteConfig: { iosLink: iosSettingsLink, androidLink: androidSettingsLink }
  } = settings;

  const { language } = useI18n();

  const { deviceFormFactor } = useContext(DeviceContext);

  const [user = {}] = useContext(UserContext);
  const { countryId, settings: userSettings = {} } = user;
  const { bTag = {} } = userSettings;
  const {
    affiliateId = '141',
    creativeId = '835',
    trackerId = '524',
    zoneId = 'ios_organic'
  } = bTag;

  useEffect(() => {
    if (!IS_WIDGET) {
      if (iosSettingsLink) {
        const affQueryParams = {
          af_sub1: trackerId,
          af_sub2: creativeId,
          af_sub3: zoneId,
          af_sub4: affiliateId,
          af_click_lookback: '7d'
        };
        const iosLink = getIosLink(iosSettingsLink, affQueryParams);
        SET_IOS_LINK(iosLink);
      }

      if (androidSettingsLink) {
        SET_ANDROID_LINK(androidSettingsLink);
      }
    }
  }, [
    affiliateId,
    creativeId,
    trackerId,
    zoneId,
    countryId,
    language,
    deviceFormFactor
  ]);
};

export default useNativeAppsLinks;
