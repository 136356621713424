import { createContext, useReducer } from 'react';
import { baseReducer, useActions } from '@namespace/helpers-store';
import { useGlobalMockedStore } from '@namespace/onboarding';
import { initialState, reducer as handlers } from '../reducer';
import { types } from '../types';
import actionCreators from '../actions';

export const AssistantContext = createContext();

export const AssistantProvider = ({ children, customHandlers = {} }) => {
  const reducer = baseReducer(handlers, customHandlers);

  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useActions(types, dispatch, actionCreators);

  return (
    <AssistantContext.Provider
      value={useGlobalMockedStore('assistant', state, actions)}
    >
      {children}
    </AssistantContext.Provider>
  );
};
