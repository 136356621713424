import { useContext, useEffect } from 'react';
import { SiteSettingsContext } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { UserContext } from '@namespace/user/src/context';
import { window, document } from '@namespace/helpers';
import { useGetWebimInfo } from '../useGetWebimInfo';
import loadWebim from './utils/loadWebim';

const useWebim = () => {
  const [settings = {}] = useContext(SiteSettingsContext);
  const { supportSettings = {}, otherSettings = {} } = settings || {};
  const { supportWebim = {} } = supportSettings;
  const { webimGuestMode = false } = otherSettings;
  const {
    accountName = '',
    chatLocations = {},
    vipChatLocations = {}
  } = supportWebim;
  const { language } = useI18n();
  const { webimData } = useGetWebimInfo(webimGuestMode);
  const [user] = useContext(UserContext);
  const { isVipUser = false } = user;

  useEffect(() => {
    if (accountName && language) {
      window.StartWebim = () => {
        if (window.webim?.api?.chat) {
          window.webim.api.chat.start();
        } else {
          window.webim = {
            accountName: `${accountName}`,
            domain: `${accountName}.webim.ru`,
            location: `${
              isVipUser ? vipChatLocations[language] : chatLocations[language]
            }`
          };
          loadWebim({ accountName }).then(() => {
            const intervalId = setInterval(() => {
              if (window.webim?.api?.chat) {
                window.webim.api.chat.start();
                clearInterval(intervalId);
              }
            }, 1000);
          });
        }

        return false;
      };
    }
  }, [accountName, chatLocations, language, isVipUser, vipChatLocations]);

  useEffect(() => {
    if (webimData && accountName && !webimGuestMode) {
      const script = document.createElement('script');
      const scriptId = 'webimInfo';
      script.id = scriptId;
      script.innerHTML = `webim_visitor = ${JSON.stringify(webimData)};`;
      const staleScript = document.getElementById(scriptId);
      if (staleScript) {
        document.body.replaceChild(script, staleScript);
      } else {
        document.body.append(script);
      }
    }
  }, [webimData, accountName, webimGuestMode]);
};

export default useWebim;
