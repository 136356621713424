import { useMemo } from 'react';
import { Iframe } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { PANDASCORE_LANGUAGES_MAP } from '../../../constants';
import styles from './index.module.css';

const Pandascore = ({ url, pandascoreUserName }) => {
  const { language } = useI18n();

  const widgetLanguage = useMemo(
    () =>
      PANDASCORE_LANGUAGES_MAP[language]
        ? `&language=${PANDASCORE_LANGUAGES_MAP[language]}`
        : '',
    [language]
  );

  return (
    <Iframe
      data-role="pandascore-iframe"
      title="pandascore-iframe"
      src={`${url}${
        url.includes('?') ? '&' : '?'
      }user=${pandascoreUserName}${widgetLanguage}`} // TODO: add dark_mode param after dark mode implementation
      className={styles.container}
    />
  );
};

export default Pandascore;
