import { postAsUriParameters } from '@namespace/helpers';
import { camelizeKeys } from 'humps';
import apiRoutes from './apiRoutes';

export const sendMessage = ({ userId, subject, message }) =>
  postAsUriParameters(apiRoutes.sendMessage, {
    user_id: userId,
    importance: '',
    message,
    subject
  });

export const getOutboxMessages = (userId) =>
  postAsUriParameters(apiRoutes.getSentMessages, {
    user_id: userId
  }).then(({ response = {} }) => camelizeKeys(response.list || []));

export const getInboxMessages = (userId) =>
  postAsUriParameters(apiRoutes.getInboxMessages, {
    user_id: userId
  }).then(({ response = {} }) => camelizeKeys(response));

export const deleteMessages = (userId, messagesIds) =>
  postAsUriParameters(
    apiRoutes.deleteMessages,
    {
      user_id: userId,
      ids: messagesIds
    },
    true
  );

export const checkMessagesAsUnread = (userId, messagesIds) =>
  postAsUriParameters(
    apiRoutes.checkMessagesAsUnread,
    {
      user_id: userId,
      ids: messagesIds
    },
    true
  );

export const checkMessagesAsRead = (userId, messagesIds = []) =>
  postAsUriParameters(
    apiRoutes.checkMessagesAsRead,
    {
      user_id: userId,
      ids: messagesIds
    },
    true
  );

export const getMessageById = (userId, messageId) =>
  postAsUriParameters(
    apiRoutes.getMessageById,
    {
      msg_id: messageId
    },
    false
  ).then(({ response }) => camelizeKeys(response));
