import { getResourceFromCms, formatAsUriParameters } from '@namespace/helpers';

const getSnippets = async (ids, lang, options) => {
  const params = formatAsUriParameters(
    {
      snippetIds: ids,
      lang
    },
    true
  );
  const pathPortion = `/snippets${params ? `?${params}` : ''}`;
  return getResourceFromCms(pathPortion, options);
};

export default getSnippets;
