import { useContext, useEffect } from 'react';
import { PredictionsContext } from '../../store/context';
import { useIsFinalStepFilled } from '../../store/selectors';
import { PREDICTIONS_STATUS } from '../../constants';

const useSetLocalStatus = () => {
  const [predState, predActions] = useContext(PredictionsContext);
  const {
    status,
    localStatus,
    isAllowEditing,
    editingInProgress,
    isReferenceLoaded
  } = predState;
  const { SET_LOCAL_STATUS } = predActions;
  const isFinalFilled = useIsFinalStepFilled();
  const currentStatus = status || localStatus;

  useEffect(() => {
    const {
      FILLED,
      SAVED,
      FAIL_SAVED,
      FAIL_UNSAVED,
      DEFAULT
    } = PREDICTIONS_STATUS;

    if (!isAllowEditing && status === SAVED) {
      SET_LOCAL_STATUS(FAIL_SAVED);
      return FAIL_SAVED;
    }
    if (!isAllowEditing && !status && isReferenceLoaded) {
      SET_LOCAL_STATUS(FAIL_UNSAVED);
      return FAIL_UNSAVED;
    }
    if (isFinalFilled && !status) {
      SET_LOCAL_STATUS(FILLED);
      return FILLED;
    }
    if (editingInProgress || (!isFinalFilled && !status)) {
      SET_LOCAL_STATUS(DEFAULT);
      return DEFAULT;
    }

    SET_LOCAL_STATUS(currentStatus);
    return currentStatus;
  }, [
    SET_LOCAL_STATUS,
    currentStatus,
    isAllowEditing,
    isFinalFilled,
    isReferenceLoaded,
    status,
    editingInProgress
  ]);
};

export default useSetLocalStatus;
