import { camelizeKeys } from 'humps';
import { get } from 'lodash';

const normalizeUserData = (userData) => {
  const newUserData = camelizeKeys(userData);
  const phoneNumber = get(newUserData, 'account.phoneNumber');

  if (phoneNumber) {
    newUserData.account.phoneNumber = `+${Number(phoneNumber)}`;
  }

  return newUserData;
};

export default normalizeUserData;
