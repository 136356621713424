const formatNumberGroups = (numStr) => numStr.split(/(?=(?:...)*$)/).join(' ');

export const formatNumToAmount = ({
  num,
  showCents = true,
  numberOfDecimalPlaces
}) => {
  if (showCents || numberOfDecimalPlaces !== 2) {
    const parts = num.toFixed(numberOfDecimalPlaces).split('.');
    const delimitedIntPart = formatNumberGroups(parts[0]);

    return [delimitedIntPart, parts[1]].join('.');
  }

  return formatNumberGroups(num.toFixed(0));
};
