import { useContext } from 'react';
import { SiteSettingsContext } from '../../store/siteSettings/context';

const useSiteSettings = () => {
  const [settings] = useContext(SiteSettingsContext);

  return settings;
};

export default useSiteSettings;
