import {
  PREDICTIONS_STEPS_IDS,
  VALID_PREDICTION_STATUS
} from '../../constants';
import orderTeams from '../orderTeams';

const getTeams = (bracket = [], teams = {}, type) => {
  const bracketTeams = bracket.map((team) => teams[team] || {});
  const orderedTeams =
    type === PREDICTIONS_STEPS_IDS.GROUPS ||
    type === PREDICTIONS_STEPS_IDS.TOP_OF_THIRD
      ? orderTeams(bracketTeams, type).slice(0, 4)
      : bracketTeams;
  if (type === PREDICTIONS_STEPS_IDS.TOP_OF_THIRD) {
    return orderedTeams.map((team) => ({
      ...team,
      validPrediction: VALID_PREDICTION_STATUS.NOT_PLAYED
    }));
  }
  if (type === PREDICTIONS_STEPS_IDS.GROUPS) {
    return orderedTeams;
  }
  return orderedTeams.map((team, i) => ({
    ...team,
    validPrediction:
      i > 0 ? VALID_PREDICTION_STATUS.NOT_PLAYED : team.validPrediction
  }));
};

export default getTeams;
