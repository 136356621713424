const minLengthValidator = (
  value,
  message = 'validators.minLengthValidator'
) => ({
  minLength: {
    value,
    message: [message, value]
  }
});

export default minLengthValidator;
