import { useOnSlideMove } from '@namespace/common';
import { Box } from '@alf/uikit';
import { TopLiveEventsSkelet } from '../TopLiveEventsSkelet';
import { EventCard, EventsSlider } from '../../../../common';
import styles from './index.module.css';

const TopLiveEventsMobile = ({
  isEventsLoaded,
  eventsIds,
  lineKey,
  isShowShortName,
  isShowFlag,
  isShowStatUrl,
  isLiveEvents
}) => {
  const { setIsMove } = useOnSlideMove();

  return !eventsIds.length && !isEventsLoaded ? (
    <Box className={styles.skeletonContainer}>
      <TopLiveEventsSkelet items={5} />
    </Box>
  ) : (
    eventsIds.length > 0 && (
      <EventsSlider setIsMove={setIsMove} className={styles.sliderContainer}>
        {eventsIds.map((eventId) => (
          <EventCard
            gap="8px"
            key={`top-live-${eventId}`}
            eventId={eventId}
            lineKey={lineKey}
            isShowShortName={isShowShortName}
            isShowFlag={isShowFlag}
            isShowStatUrl={isShowStatUrl}
            isLiveEvents={isLiveEvents}
          />
        ))}
      </EventsSlider>
    )
  );
};

export default TopLiveEventsMobile;
