import { memo, useMemo } from 'react';
import { usePrevious } from '@namespace/helpers';
import { useI18n } from '@namespace/i18n';
import {
  calculatePenaltyScore,
  getDetailedScoresForTable,
  getEventValues,
  stripeScore,
  getResultTypeScoreValues,
  getScore
} from '../../utils';
import {
  TOTAL_SCOPE_ID_BY_SPORT,
  TOTAL_SYMBOL_BY_SPORT,
  EVENT_RESULT_PENALTY,
  SCOPE_IDS_FOR_PERIODS
} from '../../constants';
import { COLUMN_TYPES } from '../../../../constants';
import ScoreDetails from '../../Details';

const Soccer = ({
  sportId,
  eventResultId,
  byResultType,
  current,
  total,
  isShortVersion,
  lineView
}) => {
  const { t } = useI18n();
  const { penTotal, redCardTotal, yellowCardTotal, cornerTotal } = getScore({
    total,
    byResultType,
    current
  });

  const previousByResultType = usePrevious(byResultType);
  let penaltyScore = null;
  let totalPenaltyScore = null;
  if (
    byResultType &&
    eventResultId === EVENT_RESULT_PENALTY &&
    byResultType.some(({ resultTypeData, resultTypeId }) =>
      resultTypeId === EVENT_RESULT_PENALTY
        ? resultTypeData.every(
            (data) => data.number && data.scopeId && data.value
          )
        : false
    )
  ) {
    [penaltyScore, totalPenaltyScore] = calculatePenaltyScore(
      byResultType,
      eventResultId
    );
  } else if (
    byResultType &&
    eventResultId === EVENT_RESULT_PENALTY &&
    previousByResultType
  ) {
    [penaltyScore, totalPenaltyScore] = calculatePenaltyScore(
      previousByResultType,
      eventResultId
    );
  }

  const commonScoreTotalData = useMemo(
    () => ({
      type: COLUMN_TYPES.TOTAL,
      tag: t(TOTAL_SYMBOL_BY_SPORT.default),
      scores: getEventValues(total, TOTAL_SCOPE_ID_BY_SPORT[sportId])
    }),
    [sportId, t, total]
  );

  const calculatedFullFormattedScore = useMemo(() => {
    let fullFormattedScore = [];

    if (eventResultId === EVENT_RESULT_PENALTY) {
      if (isShortVersion) {
        fullFormattedScore = [{ ...commonScoreTotalData }];
      } else {
        fullFormattedScore = [
          {
            ...commonScoreTotalData,
            scores: [
              totalPenaltyScore?.firstTeamResult,
              totalPenaltyScore?.secondTeamResult
            ]
          },
          ...penaltyScore
        ];
      }
    } else if (isShortVersion) {
      fullFormattedScore = [
        { ...commonScoreTotalData },
        {
          type: COLUMN_TYPES.DEFAULT,
          tag: '',
          scores: getResultTypeScoreValues(
            byResultType,
            eventResultId,
            SCOPE_IDS_FOR_PERIODS[sportId] || TOTAL_SCOPE_ID_BY_SPORT[sportId]
          )
        }
      ];
    } else {
      fullFormattedScore = [
        { ...commonScoreTotalData },
        ...stripeScore(
          getDetailedScoresForTable(byResultType),
          sportId,
          eventResultId,
          false
        ),
        {
          type: COLUMN_TYPES.DEFAULT,
          tagIcon: 'corner',
          tagIconColor: 'line_txt_3',
          scores: cornerTotal
        },
        {
          type: COLUMN_TYPES.DEFAULT,
          tagIcon: 'red-card',
          tagIconColor: 'state_error',
          scores: redCardTotal
        },
        {
          type: COLUMN_TYPES.DEFAULT,
          tagIcon: 'yellow-card',
          tagIconColor: 'state_warning',
          scores: yellowCardTotal
        },
        {
          type: COLUMN_TYPES.DEFAULT,
          tagIcon: 'penalty',
          tagIconColor: 'line_txt_3',
          scores: penTotal
        }
      ];
    }
    return fullFormattedScore;
  }, [
    byResultType,
    commonScoreTotalData,
    eventResultId,
    isShortVersion,
    penaltyScore,
    sportId,
    totalPenaltyScore?.firstTeamResult,
    totalPenaltyScore?.secondTeamResult,
    cornerTotal,
    penTotal,
    yellowCardTotal,
    redCardTotal
  ]);

  return (
    <ScoreDetails
      scoreData={calculatedFullFormattedScore}
      isShortVersion={isShortVersion}
      lineView={lineView}
    />
  );
};

export default memo(Soccer);
