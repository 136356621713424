import { get, urlWithParams } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

const getCasinoGameRounds = ({
  startDate,
  endDate,
  gameId,
  categoryId,
  page = 1,
  limit = 20
}) => {
  const params = {
    game_id: gameId,
    date_to: endDate,
    per_page: limit,
    page,
    date_from: startDate,
    category_id: categoryId
  };

  return get(
    urlWithParams(`/service/transaction-history/history/game-rounds`, params)
  ).then(({ data }) => camelizeKeys(data.bets));
};

export default getCasinoGameRounds;
