export const MAX_RECONNECT_ATTEMPTS = 10;

export const TOKEN_LS_KEY_PREFIX = 'HRCHAT_TOKEN';

// export const NONCE_LS_KEY = 'HRCHAT_NONCE';

export const FILE_ACCEPTABLE_EXTENSIONS = [
  '.jpeg',
  '.png',
  '.webp',
  '.jpg',
  '.tiff',
  '.heic',
  '.heif',
  '.zip',
  '.arj',
  '.lzh',
  '.rar',
  '.uc2',
  '.tar',
  '.gz',
  '.cab',
  '.ace',
  '.7z',
  '.pdf',
  '.doc',
  '.docx',
  '.docm',
  '.epub',
  '.rtf',
  '.pages',
  '.odt'
];

export const FILE_MAX_SIZE = 8 * 1024 * 1024;

export const TYPING_END_TIMEOUT = 5000; // 5s

export const HISTORY_LOAD_MESSAGES = 10;

export const FILES_MAX_COUNT = 5;

export const CHAT_ERRORS = {
  FILE_MAX_COUNT_EXCEEDED: 'FILE_MAX_COUNT_EXCEEDED'
};
