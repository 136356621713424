import { createDynamicHookStore, STORE_NAMES } from '@namespace/helpers-store';
import { initialState } from './store';
import { actionsCreator, advFilterInnerActions } from './actions';

const {
  Context,
  Provider,
  useCtxStore,
  // NB trick IDE with default value for intellisense to work
  useCtxActions = () => advFilterInnerActions
} = createDynamicHookStore({
  storeName: STORE_NAMES.ADV_FILTER_INNER,
  initialState,
  actionsCreator
});

export const AdvFilterInnerContext = Context;

export const AdvFilterInnerProvider = Provider;

export const useAdvFilterInnerCtxStore = useCtxStore;

export const useAdvFilterInnerCtxActions = useCtxActions;
