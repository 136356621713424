import { useRef } from 'react';
import { defaultMemoize } from 'reselect';

/**
 * Create selector hook that supports memoized RESELECT selectors
 */
export const createUseSelector = (useStore) => (
  selectorCreator,
  args = [],
  options
) => {
  // Create memoized version of selector creator and store it as permanent ref, so its cache would live while component lives. This is needed for parametrized selectors.
  const selCreator = useRef(defaultMemoize(selectorCreator));

  // Create actual selector by calling creator with variable args. If args don't change during update - this function won't be recreated due to memoize and won't recompute anything as a result.
  const selector = selCreator.current(...args);

  // Select actual data
  return useStore(selector, options);
};
