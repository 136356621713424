import { isEmpty } from 'lodash';
import { Box } from '@alf/uikit';
import Date from './Date';
import OddsFilter from './OddsFilter';
import styles from './index.module.css';

const Filters = ({
  filterConfig = {},
  filterValue = {},
  onSetFilter,
  oddFormats = ['decimal'],
  isShowStartingSoon = false,
  isShowNextMorning = false,
  isShowAll = false
}) => (
  <Box align="center" justify="between">
    {!isEmpty(filterConfig) && (
      <Date
        filterConfig={filterConfig}
        isShowStartingSoon={isShowStartingSoon}
        isShowNextMorning={isShowNextMorning}
        filterValue={filterValue}
        className={styles.dayFilter}
        onSetFilter={onSetFilter}
        isShowAll={isShowAll}
      />
    )}
    <OddsFilter oddFormats={oddFormats} />
  </Box>
);

export default Filters;
