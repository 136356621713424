import { useCallback, useContext } from 'react';
import { SiteSettingsContext, useSupportParamsSettings } from '@namespace/cms';
import { window } from '@namespace/helpers';
import { SENDER_CHAT, FRESH_CHAT, ZENDESK_CHAT } from '../../constants';

const useSupport = () => {
  const [settings] = useContext(SiteSettingsContext);
  const { siteConfig = {} } = settings;
  const { supportChatName = SENDER_CHAT } = siteConfig;
  const otherSettingsSupport = useSupportParamsSettings();
  const { chat = supportChatName } = otherSettingsSupport;

  const handleFreshChatLogout = useCallback(async () => {
    const { data: isExist } = await window.fcWidget.user.isExists();

    if (isExist) {
      window.fcWidget.user.clear();
    }

    window.fcWidget.close();
  }, []);

  const handleLogout = useCallback(() => {
    if (chat === FRESH_CHAT) {
      handleFreshChatLogout();
    }
  }, [handleFreshChatLogout, chat]);

  return {
    logout: handleLogout,
    chatName: chat,
    isSender: chat === SENDER_CHAT,
    isFreshChat: chat === FRESH_CHAT,
    isZendesk: chat === ZENDESK_CHAT
  };
};

export default useSupport;
