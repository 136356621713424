import { useReducer, createContext, useMemo } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import { initialState, reducers } from './reducers';
import { types } from './types';
import { actions as actionCreators } from './actions';

export const LayoutContext = createContext();

export const LayoutProvider = ({ children }) => {
  const memoReducers = useMemo(() => combineReducers(reducers), []);
  const [state, dispatch] = useReducer(memoReducers, initialState);
  const actions = useActions(types, dispatch, actionCreators);

  return (
    <LayoutContext.Provider value={[state, actions]}>
      {children}
    </LayoutContext.Provider>
  );
};
