export const DATA_SET = 'DATA_SET';
export const CARDS_SET = 'CARDS_SET';
export const DATA_FETCHING = 'DATA_FETCHING';
export const FILTER_SET = 'FILTER_SET';
export const SHOW_MORE = 'SHOW_MORE';

export const setIsFetchingAction = (state = true) => ({
  type: DATA_FETCHING,
  payload: {
    isFetching: state
  }
});

export const setDataAction = ({ data, totalPages }) => ({
  type: DATA_SET,
  payload: {
    data,
    totalPages,
    isFetching: false
  }
});

export const setShowMoreAction = ({ data, totalPages }) => ({
  type: SHOW_MORE,
  payload: {
    data,
    totalPages,
    isFetching: false
  }
});
