import { isEmpty, get } from 'lodash';
import { PROVIDERS } from '../../constants';

const maybeEmptyArray = (a) => (Array.isArray(a) ? a : []);

const formatVermantiaAndInspired = ({ providerMenu }, provider) => {
  const virtualSports = {};
  const virtualTournaments = {};
  const virtualEvents = {};
  for (const { categoryId, categoryName, tournaments } of maybeEmptyArray(
    providerMenu.categories
  )) {
    const tournamentIds = [];
    for (const { tournamentId, tournamentName, events } of tournaments) {
      tournamentIds.push(tournamentId);
      const eventIds = [];
      for (const {
        eventId,
        eventLinePosition,
        eventDt,
        eventName,
        eventStatusType
      } of events) {
        eventIds.push(eventId);
        virtualEvents[eventId] = {
          eventId,
          eventLinePosition,
          eventDt,
          eventName,
          eventStatusType,
          tournamentId,
          provider
        };
      }
      virtualTournaments[tournamentId] = {
        tournamentId,
        tournamentName,
        events: eventIds,
        provider
      };
    }
    virtualSports[categoryId] = {
      categoryId,
      provider,
      categoryName,
      tournaments: tournamentIds
    };
  }
  return {
    virtualSports,
    virtualTournaments,
    virtualEvents
  };
};

const formatBetradar = ({ providerMenu = [] }, provider) => {
  const virtualSports = {};
  const virtualTournaments = {};
  const virtualEvents = {};
  for (const { sportId, sportName, tournaments } of providerMenu) {
    const tournamentIds = [];
    for (const { tournamentId, tournamentName, events } of tournaments) {
      tournamentIds.push(tournamentId);
      const eventIds = [];
      events
        .sort((a, b) => a.eventEnetId - b.eventEnetId)
        .forEach(
          (
            {
              eventId,
              eventLinePosition,
              eventDt,
              eventName,
              eventEnetId,
              eventStatusType,
              headMarket
            },
            index
          ) => {
            eventIds.push(eventId);
            virtualEvents[eventId] = {
              eventId,
              eventLinePosition,
              eventDt,
              provider,
              eventEnetId,
              tournamentId,
              eventName,
              eventStatusType,
              headMarket,
              index
            };
          }
        );
      virtualTournaments[tournamentId] = {
        tournamentId,
        tournamentName,
        events: eventIds,
        provider
      };
    }
    virtualSports[sportId] = {
      categoryId: sportId,
      provider,
      categoryName: sportName,
      tournaments: tournamentIds
    };
  }

  return {
    virtualSports,
    virtualTournaments,
    virtualEvents
  };
};

export const formatProviderData = {
  [PROVIDERS.VERMANTIA]: formatVermantiaAndInspired,
  [PROVIDERS.INSPIRED]: formatVermantiaAndInspired,
  [PROVIDERS.BETRADAR]: formatBetradar
};

export const normaliseBetradarVirtualMarkets = (eventMarkets, eventId) =>
  (eventMarkets?.providerEvent?.resultTypes || []).reduce(
    (acc1, { marketGroups, ...a }) => ({
      updatedMarkets: [
        ...acc1.updatedMarkets,
        ...marketGroups.reduce(
          (acc2, { markets, ...b }) => [
            ...acc2,
            ...markets.reduce(
              (acc3, { outcomes, ...c }) => [
                ...acc3,
                {
                  ...a,
                  ...b,
                  ...c,
                  marketSuspend: c.marketSuspend === 'yes',
                  serviceId: 20,
                  eventId,
                  outcomeIds: outcomes.map(({ outcomeId }) => outcomeId)
                }
              ],
              []
            )
          ],
          []
        )
      ],
      updatedOutcomes: Object.assign(acc1.updatedOutcomes, {
        ...acc1.updatedOutcomes,
        ...marketGroups.reduce(
          (acc2, { markets }) => ({
            ...acc2,
            ...markets.reduce(
              (acc3, { outcomes }) => ({
                ...acc3,
                ...outcomes.reduce(
                  (outcomesAcc, outcome) => ({
                    ...outcomesAcc,
                    [outcome.outcomeId]: outcome
                  }),
                  {}
                )
              }),
              {}
            )
          }),
          {}
        )
      })
    }),
    {
      updatedMarkets: [],
      updatedOutcomes: {}
    }
  );

export const normaliseVirtualMarkets = (markets, data) =>
  markets.reduce(
    (acc, { outcomes = [], ...m }) => ({
      updatedMarkets: [
        ...acc.updatedMarkets,
        {
          ...data,
          ...m,
          outcomeIds: outcomes.map(({ outcomeId }) => outcomeId)
        }
      ],
      updatedOutcomes: {
        ...acc.updatedOutcomes,
        ...outcomes.reduce(
          (acc2, outcome) => ({ ...acc2, [outcome.outcomeId]: outcome }),
          {}
        )
      }
    }),
    {
      updatedMarkets: [],
      updatedOutcomes: {}
    }
  );

const normalizeVsGamesFromCms = (games) => {
  const normalizedVsGames = {};
  const ids = [];

  for (const [key, game] of Object.entries(games)) {
    const {
      gameId,
      supportDemo: hasDemoSupport,
      providerId,
      launchGameId
    } = game;

    const lobbyImage =
      game.desktop?.gameImage ||
      game.tablet?.gameImage ||
      game.mobile?.gameImage;

    normalizedVsGames[key] = {
      ...game,
      gameCode: gameId,
      launchGameId,
      hasDemoSupport,
      provider: providerId,
      categoryName: game.gameName,
      categoryId: gameId,
      isFrame: true,
      lobbyImage
    };

    ids.push(key);
  }

  return { data: normalizedVsGames, ids, isLoaded: true };
};

const normalizeVsCategoriesFromCms = (categories, content, normalizedGames) =>
  categories.reduce(
    (acc, categoryId) => {
      const item = content[categoryId];

      if (isEmpty(item) || item.games.length === 0) return acc;

      const filteredGames = item.games.filter(
        (gameId) => normalizedGames.data[gameId]
      );

      if (filteredGames.length === 0) return acc;

      const { name } = item;

      return {
        ...acc,
        data: Object.assign(acc.data, {
          [name]: {
            id: name,
            ...item.settings,
            games: filteredGames
          }
        }),
        ids: [...acc.ids, name],
        isLoaded: true
      };
    },
    { data: {}, ids: [], isLoaded: true }
  );

export const normalizeVsProvidersFromCms = (
  providersKeys,
  content,
  providers,
  normalizedGames
) => {
  const normalizedContentProviders = providersKeys.reduce(
    (acc, key) => {
      const item = content[key];
      const provider = providers[item.name] || {};

      if (isEmpty(provider) || item.games.length === 0) {
        return acc;
      }

      const filteredGames = item.games.filter(
        (gameId) => normalizedGames.data[gameId]
      );

      if (filteredGames.length === 0) return acc;

      return {
        data: Object.assign(acc.data, {
          [provider.providerId]: Object.assign(item, provider, {
            games: filteredGames,
            name: item.name,
            categoryName: provider.providerName,
            categoryId: provider.providerId,
            isFrame: true
          })
        }),
        ids: [...acc.ids, provider.providerId]
      };
    },
    { data: {}, ids: [] }
  );

  return {
    ...normalizedContentProviders,
    data: {
      ...providers,
      ...normalizedContentProviders.data
    },
    isLoaded: true
  };
};

export const parseVsGamesFeed = ({ data, feedName }) => {
  const gamesFeed = data?.feeds.find((feed) => feed.feedName === feedName);
  const actualGamesFeed = gamesFeed || {
    ...(get(data, 'feeds[0]') || {}),
    categories: get(data, 'feeds[0].categories') || [],
    providers: get(data, 'feeds[0].providers') || [],
    content: get(data, 'feeds[0].content') || {}
  };
  const normalizedGames = normalizeVsGamesFromCms(data.games);

  return {
    feedName: actualGamesFeed.feedName,
    categories: normalizeVsCategoriesFromCms(
      actualGamesFeed.categories,
      actualGamesFeed.content,
      normalizedGames
    ),
    providers: normalizeVsProvidersFromCms(
      actualGamesFeed.providers,
      actualGamesFeed.content,
      data.providers,
      normalizedGames
    ),
    games: normalizedGames
  };
};
