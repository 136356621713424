import { intersection } from 'lodash';
import { WRAPPER_TYPES_MAP } from '../../constants';

const getPageConfigWrapperTypes = (config) => {
  const existedPageComponents = config.layout?.columns.reduce(
    (memo, column) => memo.concat(column),
    []
  );

  const pageComponentTypes = Object.entries(config.components || {})
    .filter(([pageComponent]) => existedPageComponents.includes(pageComponent))
    .reduce((memo, [, { type }]) => [...memo, type], []);

  return Object.entries(WRAPPER_TYPES_MAP).reduce(
    (memo, [context, componentTypes]) =>
      intersection(pageComponentTypes, componentTypes).length
        ? [...memo, context]
        : memo,
    []
  );
};

export default getPageConfigWrapperTypes;
