import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { Image } from '@namespace/icons2';
import {
  FORM_VALIDATION_TYPES,
  formBuilderValidationMap,
  KeyboardDatePickerField
} from '@namespace/forms';
import { createValidationRules } from '@alf/validators';
import { CARD_INFO, PAN_STATUS_BLOCKED } from '../../../constants';
import styles from './index.module.css';

const CardFrontPreView = ({
  activeCard,
  minDate,
  maxDate,
  onBlur,
  customFieldName
}) => {
  const { cardMask, cardHolderName, cardStatus, expDate } = activeCard || {};
  const { t, f } = useI18n();

  return (
    <>
      <Box align="center" justify="between" margin="0 0 m">
        <AText breed="BU" size="l" color="txt_color_1">
          {cardMask?.replace(/(.{4})/g, '$1 ')}
        </AText>
        <Box className={styles.cardIcons} justify="between">
          <Image name="payments/visa_mastercard_goriz" width="54px" alt="" />
        </Box>
      </Box>
      <Box align="center" justify="between" className={styles.bottom}>
        <AText breed="BU" size="l" color="txt_color_1">
          {cardHolderName}
        </AText>

        <Box direction="column">
          {!PAN_STATUS_BLOCKED.includes(cardStatus) ? (
            <KeyboardDatePickerField
              label="panKeeper.card.label.validUntil"
              onBlur={onBlur}
              customFieldName={customFieldName}
              labelSize="s"
              minDate={minDate}
              maxDate={maxDate}
              dateFormat="MM/yy"
              dateValueFormat="MM/yyyy"
              size="xs"
              placeholder="MM / YY"
              classNames={{
                datePickerWrapperClassName: styles.inputWrapperDate,
                labelClassName: styles.label,
                datePickerContainerClassName: styles.datePickerContainer
              }}
              withTooltip={true}
              name={CARD_INFO.EXPIRY_DATE}
              rules={createValidationRules(
                [FORM_VALIDATION_TYPES.REQUIRED],
                formBuilderValidationMap
              )}
            />
          ) : (
            <>
              <AText
                breed="RU"
                className={styles.label}
                size="s"
                align="right"
                color="txt_color_7"
              >
                {t('panKeeper.card.label.validUntil')}
              </AText>
              <AText
                className={styles.validUntil}
                breed="BU"
                size="l"
                color="txt_color_1"
                align="right"
              >
                {f.getDateTime({ date: expDate }).toFormat('MM/yy')}
              </AText>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CardFrontPreView;
