import { useEffect, useCallback, useContext, useRef, useState } from 'react';
import { UserContext } from '@namespace/user';
import { window, document } from '@namespace/helpers';
import { throttle } from 'lodash';
import { useGetPragmaticData } from '../useGetPragmaticData';
import { GamesContext } from '../../context';
import {
  useUnsortedGamesByProvider,
  useProvider,
  useGamesState,
  useIsFeedLoaded
} from '../../context/selectors';
import { CASINO_TYPE } from '../../constants';

export const usePragmaticData = (casinoType, defaultFeedName) => {
  const [user] = useContext(UserContext);
  const refPragmaticData = useRef([]);
  const { activeWallet = {} } = user;
  const { currency } = activeWallet;
  const {
    defaultCurrency,
    pragmaticScriptSrc,
    pragmaticWSUrl,
    casinoId,
    pragmaticProviderId,
    isPragmaticEnabled
  } = useGetPragmaticData();

  const [, gamesActions] = useContext(GamesContext);
  const pragmaticProvider = useProvider(pragmaticProviderId);
  const { isCmsPragmaticLiveDataLoaded } = useGamesState();
  const isFeedLoaded = useIsFeedLoaded(defaultFeedName);
  const { SET_PRAGMATIC_LIVE_DATA, GET_CMS_PRAGMATIC_LIVE_INFO } = gamesActions;

  const [isPragmaticScriptLoaded, setIsPragmaticScriptLoaded] = useState(false);
  const [isConnectedToWS, setIsConnectedToWS] = useState(false);

  const pragmaticGames = Object.values(
    useUnsortedGamesByProvider(pragmaticProviderId)
  );

  const onSetPragmaticData = useCallback(
    throttle((data, callback) => {
      callback();
      SET_PRAGMATIC_LIVE_DATA(data);
    }, 3000),
    [SET_PRAGMATIC_LIVE_DATA]
  );

  const onMessage = useCallback(
    (data) => {
      if (data?.tableKey) {
        window.dga.subscribe(
          casinoId,
          data.tableKey,
          currency || defaultCurrency
        );
      }

      refPragmaticData.current.push(data);
      onSetPragmaticData(refPragmaticData.current, () => {
        refPragmaticData.current = [];
      });
    },
    [onSetPragmaticData, refPragmaticData, casinoId, defaultCurrency, currency]
  );

  const subscribeToWS = useCallback(() => {
    if (!window.dga || !casinoId || !pragmaticWSUrl) return;

    window.dga.connect(pragmaticWSUrl);
    window.dga.onConnect = () => {
      window.dga.available(casinoId);

      setIsConnectedToWS(true);
      window.dga.onMessage = onMessage;
    };
  }, [currency, defaultCurrency, pragmaticGames, casinoId, pragmaticWSUrl]);

  useEffect(() => {
    if (
      isPragmaticEnabled &&
      !isCmsPragmaticLiveDataLoaded &&
      isFeedLoaded &&
      pragmaticProvider?.serviceId &&
      pragmaticGames.length &&
      casinoType === CASINO_TYPE.CASINO_LIVE
    ) {
      GET_CMS_PRAGMATIC_LIVE_INFO(pragmaticProvider.serviceId);
    }
  }, [
    isPragmaticEnabled,
    isCmsPragmaticLiveDataLoaded,
    isFeedLoaded,
    pragmaticProvider.serviceId,
    pragmaticGames.length,
    casinoType
  ]);

  useEffect(() => {
    // after unmount of component (e.g. switching to regular casino)
    // dga still will be in window
    if (window.dga && !isPragmaticScriptLoaded) {
      setIsPragmaticScriptLoaded(true);
    }

    if (isPragmaticEnabled && pragmaticScriptSrc && !window.dga) {
      const script = document.createElement('script');

      script.onload = () => setIsPragmaticScriptLoaded(true);
      script.src = pragmaticScriptSrc;
      document.querySelector('#root').appendChild(script);
    }
  }, [isPragmaticEnabled, isPragmaticScriptLoaded, pragmaticScriptSrc]);

  useEffect(() => {
    (async () => {
      try {
        if (!isPragmaticEnabled) return;
        if (casinoType !== CASINO_TYPE.CASINO_LIVE) return;
        if (!isCmsPragmaticLiveDataLoaded) return;
        if (!isPragmaticScriptLoaded) return;
        if (!pragmaticGames.length) return;
        if (isConnectedToWS) return;
        if (!isFeedLoaded) return;

        await subscribeToWS();
      } catch (e) {
        console.warn(e);
      }
    })();
  }, [
    isPragmaticEnabled,
    pragmaticGames.length,
    isCmsPragmaticLiveDataLoaded,
    isPragmaticScriptLoaded,
    isConnectedToWS,
    casinoType,
    isFeedLoaded
  ]);

  useEffect(() => {
    return () => {
      if (window.dga?.websocket) {
        window.dga.disconnect();
      }
    };
  }, []);
};
