import { Icon } from '@namespace/icons2';
import { AText, Box } from '@alf/uikit';

import styles from './index.module.css';

const PersonalOfficeUserName = ({
  firstName,
  lastName,
  middleName,
  userNameClassName
}) => {
  return (
    <Box
      data-role="profile-data-username"
      align="center"
      className={`${styles.userName} ${userNameClassName}`}
    >
      <Icon
        size="s"
        name="icons/user_menu/profile_desktop"
        className={styles.userIcon}
      />
      <AText breed="B" size="m" color="po_txt_color_1">
        {`${firstName} ${middleName || ''} ${lastName}`}
      </AText>
    </Box>
  );
};

export default PersonalOfficeUserName;
