import { postAsUriParameters } from '@namespace/helpers';
import { detailsRoutsMap } from '../../constants';

export const getBonusDetails = async (bonusData, bonusType) => {
  const url = detailsRoutsMap[bonusType] || '';

  if (url) {
    const responseData = await postAsUriParameters(url, bonusData);
    return responseData?.response?.response;
  }
  return null;
};
