import { PREDICTIONS_GROUP_SELECTED_QUANTITY } from '../../constants';

const orderTeams = (teams, type) =>
  teams
    .map((item) =>
      item.index === 0
        ? { ...item, index: PREDICTIONS_GROUP_SELECTED_QUANTITY[type] + 1 }
        : item
    )
    .sort((a, b) => a.index - b.index);

export default orderTeams;
