import { useContext } from 'react';
import clsx from 'clsx';
import { isEmpty, keyBy } from 'lodash';
import { AText, Box } from '@alf/uikit';
import { SENDER_CHAT, useOpenChat, SupportChat } from '@namespace/chats';
import { SiteSettingsContext } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { useUserSegmentsIncludes } from '@namespace/segments';
import { useGetImage } from '@namespace/themes';
import CustomChatButton from './CustomChatButton';
import styles from './index.module.css';

const BottomPopup = ({ bottomPopupItems, onClosePopup, classNames = {} }) => {
  const { t, language } = useI18n();
  const getImg = useGetImage();
  const [siteSettings] = useContext(SiteSettingsContext);
  const { siteConfig = [] } = siteSettings;
  const { supportChatName = SENDER_CHAT } = siteConfig;
  const { openChat } = useOpenChat(supportChatName);
  const filteredSegments = useUserSegmentsIncludes();

  const onChatOpen = (e) => {
    e.stopPropagation();
    openChat();
  };

  const normalizedPopupItems = bottomPopupItems.map((item) => {
    let el = { ...item, onClick: (e) => e.stopPropagation() };
    if (!isEmpty(item.langLinks)) {
      const langLinks = keyBy(item.langLinks, 'lang');
      el = {
        ...item,
        action:
          langLinks[language]?.action || langLinks?.en?.action || item?.action
      };
    } else if (item.action === 'openChat') {
      el = {
        ...item,
        action: null,
        onClick: onChatOpen
      };
    }
    return el;
  });

  return (
    <Box className={styles.bottomPopupWrapper} direction="column">
      <Box
        className={clsx(
          styles.bottomPopupOverlay,
          classNames?.bottomPopupOverlay
        )}
        onClick={onClosePopup}
      />
      <Box className={styles.bottomPopupMenu} direction="column">
        {normalizedPopupItems.map(
          ({ title, image, imageDark, action, userSegments, onClick }) => {
            const isItemIncludedInSegments = filteredSegments(userSegments);
            const isCustomChat = action === 'openCustomChat';

            return (
              isItemIncludedInSegments &&
              (isCustomChat ? (
                <SupportChat
                  ChatButton={CustomChatButton}
                  title={title}
                  image={image}
                  imageDark={imageDark}
                  key={title}
                />
              ) : (
                <a
                  className={styles.bottomPopupItem}
                  target="_blank"
                  onClick={onClick}
                  href={action}
                  rel="noreferrer"
                  key={title}
                >
                  <Box align="center">
                    <img
                      src={getImg(image, imageDark)}
                      className={styles.bottomPopupIcon}
                      alt=""
                    />
                    <AText breed="R" size="m" color="po_menutxt_color_1">
                      {t(title)}
                    </AText>
                  </Box>
                </a>
              ))
            );
          }
        )}
      </Box>
    </Box>
  );
};

export default BottomPopup;
