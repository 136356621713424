import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@namespace/icons2';
import { document } from '@namespace/helpers';
import Button from '../Button';
import Overlay from '../Overlay';
import Box from '../Box';
import AText from '../AText';
import styles from './index.module.css';

const ModalDialog = ({
  title = '',
  children,
  onAccept,
  onBlur = () => {},
  intentAccept = 'primary',
  actionTypeAccept = 'color1',
  intentCancel = 'quaternary',
  actionTypeCancel = 'color4',
  acceptTitle = '',
  onCancel,
  closeTitle = '',
  classNames = '',
  customButton = null
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  return (
    <Overlay onClick={onBlur} classNames={classNames}>
      <Box
        onClick={(e) => e.stopPropagation()}
        direction="column"
        className={styles.modalDialog}
      >
        <Box align="center" justify="between" className={styles.titleWrapper}>
          <AText
            breed="B"
            size="l"
            color="po_txt_color_2"
            className={styles.title}
          >
            {title}
          </AText>
          <Icon
            onClick={onCancel}
            name="icons/general/action/close"
            size="s"
            color="po_txt_color_2"
            className={styles.close}
          />
        </Box>
        <Box direction="column" className={styles.content}>
          <Box direction="column" align="center">
            {children}
          </Box>
          {acceptTitle && (
            <Button
              actionType={actionTypeAccept}
              intent={intentAccept}
              type="submit"
              size="l"
              className={styles.acceptBtn}
              onClick={onAccept}
            >
              {acceptTitle}
            </Button>
          )}
          {closeTitle && (
            <Button
              onClick={onCancel}
              actionType={actionTypeCancel}
              intent={intentCancel}
              size="l"
            >
              {closeTitle}
            </Button>
          )}
          {customButton}
        </Box>
      </Box>
    </Overlay>
  );
};

ModalDialog.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  onAccept: PropTypes.func,
  acceptTitle: PropTypes.string,
  onCancel: PropTypes.func,
  closeTitle: PropTypes.string
};

export default ModalDialog;
