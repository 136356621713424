import { useEffect } from 'react';
import { useI18n } from '@namespace/i18n';
import { AlertNotification, Box, Button, Overlay } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { CommonLoader } from '@namespace/common';
import { usePanKeeper } from '../../hooks/usePanKeeper';
import CardSelect from './CardSelect';
import Card from './Card';
import { PAN_KEEPER_TYPES } from '../../constants';
import styles from './index.module.css';

const PanKeeperForm = ({
  // remove after full payments migration to SW
  isSingleWallet = false,
  setInstance,
  initialPanKeeperType = PAN_KEEPER_TYPES.DEPOSIT,
  className = '',
  shouldTriggerValidation = false,
  docRequestSupportEmail,
  showNtf
}) => {
  const { t } = useI18n();
  const {
    getCardHash,
    isLoading,

    isCardInputReady,
    setIsCardInputReady,

    isCardDropdownReady,
    setIsCardDropdownReady,

    panKeeperType,
    setPanKeeperType,

    cardsList,
    activeCard,
    setActiveCard,

    setEditCard,

    saveDescription,

    // deleteCard,

    globalError,
    localError,
    setLocalError,
    hideGlobalError
  } = usePanKeeper({
    initialPanKeeperType,
    isNewApi: isSingleWallet
  });

  const isCardListEmpty = !cardsList.length;
  useEffect(() => {
    setInstance({
      panKeeperType,
      getCardHash,
      isCardInputReady,
      isCardDropdownReady,
      setPanKeeperType,
      isCardListEmpty,
      cardsList,
      isLoading,
      activeCard,
      hideGlobalError
    });
  }, [
    setInstance,
    panKeeperType,
    getCardHash,
    isCardInputReady,
    isCardDropdownReady,
    setPanKeeperType,
    isCardListEmpty,
    cardsList,
    isLoading,
    activeCard,
    hideGlobalError
  ]);

  if (isLoading) {
    return (
      <div className={`${styles.panKeeper} ${className}`}>
        <Overlay>
          <CommonLoader />
        </Overlay>
      </div>
    );
  }

  if (globalError) {
    return (
      <Box margin="m 0">
        <AlertNotification
          onClose={hideGlobalError}
          message={globalError}
          type="error"
        />
      </Box>
    );
  }

  return (
    <div className={`${styles.panKeeper} ${className}`}>
      {panKeeperType !== PAN_KEEPER_TYPES.WITHDRAW && !isSingleWallet && (
        <Box>
          <Button
            intent="secondary"
            size="l"
            actionType="color3"
            disabled={panKeeperType === PAN_KEEPER_TYPES.ADD}
            iconLeft={<Icon name="icons/general/action/create" size="s" />}
            fullWidth={true}
            onClick={() => setPanKeeperType(PAN_KEEPER_TYPES.ADD)}
          >
            {t('panKeeper.button.addCard')}
          </Button>
        </Box>
      )}

      {panKeeperType !== PAN_KEEPER_TYPES.ADD && !isCardListEmpty && (
        <>
          <CardSelect
            cardsList={cardsList}
            activeCard={activeCard}
            setActiveCard={setActiveCard}
            supportMail={docRequestSupportEmail}
            showNtf={showNtf}
            setIsCardDropdownReady={setIsCardDropdownReady}
            isCardDropdownReady={isCardDropdownReady}
          />
          {/*  todo ux flow is needed to delete active pan */}
          {/* <Button intent="primary" actionType="color2" onClick={deleteCard}> */}
          {/*  DELETE CURRENT PAN */}
          {/* </Button> */}
        </>
      )}

      <Card
        type={panKeeperType}
        setIsCardInputReady={setIsCardInputReady}
        activeCard={activeCard}
        setEditCard={setEditCard}
        localError={localError}
        setLocalError={setLocalError}
        saveDescription={saveDescription}
        isSingleWallet={isSingleWallet}
        shouldTriggerValidation={shouldTriggerValidation}
      />

      {panKeeperType === PAN_KEEPER_TYPES.ADD && !isSingleWallet && (
        <Box>
          <Button
            intent="quaternary"
            size="l"
            actionType="color3"
            fullWidth={true}
            onClick={() => setPanKeeperType(PAN_KEEPER_TYPES.DEPOSIT)}
          >
            {t('panKeeper.button.cancel')}
          </Button>
        </Box>
      )}
    </div>
  );
};

export default PanKeeperForm;
