import { useEffect, useState } from 'react';
import { useConfig, useGeneralRoutes, useOtherSettings } from '@namespace/cms';
import { useI18n, useLocation } from '@namespace/i18n';
import { Box } from '@alf/uikit';
import clsx from 'clsx';
import appInIframe from '@namespace/helpers/src/utils/appInIframe';
import { IS_WIDGET } from '@namespace/widget';
import { isEmpty } from 'lodash';
import EventContent from '../EventContent';
import { getEvents } from '../../../api/getEvents';
import { useGetPathParams } from '../../../hooks/useGetPathParams';
import { useRemoveLine } from '../../../hooks/useRemoveLine';
import useSportsSettings from '../../../hooks/useSportsSettings';
import OneEventHelmet from '../../OneEventHelmet';
import BreadCrumbs from '../../BreadCrumbs';
import { LINE_KEY, MODE } from '../../../constants';
import {
  sportsbookActions,
  useCategory,
  useEvent,
  useEventFields
} from '../../../store';
import PreMatchCategoryEvents from '../../../components/PreMatchCategoryEvents';
import { PreMatchEventsForEventDetails } from '../../../components/PreMatchEventsForEventDetails';
import { LiveButton } from '../LiveButton';
import styles from './index.module.css';

const {
  FETCH_ACTIVE_EVENT,
  FETCH_ACTIVE_EVENT_MARKETS,
  FETCH_MARKET_GROUPS,
  SET_EVENTS
} = sportsbookActions;

const Event = ({ id, serviceId: serviceIdProp }) => {
  const { config } = useConfig(id);
  const { isShowStatUrl = true } = useSportsSettings();
  const {
    isShowEventGroup = false,
    isShowScoreboard = true,
    isShowSportRadarWidget = true
  } = config;
  const {
    isRenderEventsLineInsideEventDetails = true,
    isCachedEventsEnabled = false
  } = useOtherSettings();
  const { eventId, activeSport, sportSlug: slugPath } = useGetPathParams();
  const { sportSlug = 'none' } = activeSport || {};
  const { liveEvent } = useGeneralRoutes();
  const { pathname } = useLocation();
  const { language: lang } = useI18n();
  const event = useEvent(eventId);

  const {
    eventGroup,
    sportId,
    sportName,
    eventName,
    tournamentId,
    tournamentName,
    categoryName,
    categoryId,
    eventStatusType,
    serviceId,
    isCachedEvent
  } = useEventFields({
    eventId,
    fields: [
      'eventGroup',
      'sportId',
      'sportName',
      'eventName',
      'tournamentId',
      'tournamentName',
      'categoryName',
      'categoryId',
      'eventStatusType',
      'serviceId',
      'isCachedEvent'
    ]
  });

  const [isLoading, setIsLoading] = useState(true);
  const lineKey = `${LINE_KEY.DETAIL_EVENT}-${eventId}`;
  const inIframe = appInIframe() && IS_WIDGET;
  const isServiceIdReady = [MODE.IN_PLAY, MODE.PRE_MATCH].includes(serviceId);
  const category = useCategory(categoryId, MODE.PRE_MATCH);

  useEffect(() => {
    (async () => {
      if (eventId) {
        setIsLoading(true);

        await FETCH_ACTIVE_EVENT({
          lang,
          eventId,
          lineKey,
          ...(!isCachedEventsEnabled && { serviceId: serviceIdProp })
        });
        if (isServiceIdReady) {
          await FETCH_ACTIVE_EVENT_MARKETS({
            serviceId,
            lang,
            eventId,
            lineKey
          });
        }
      }
      setIsLoading(false);
    })();
  }, [
    isServiceIdReady,
    serviceId,
    lang,
    eventId,
    lineKey,
    isCachedEventsEnabled
  ]);

  useEffect(() => {
    if (isCachedEvent) {
      setIsLoading(false);
    }
  }, [isCachedEvent]);

  useEffect(() => {
    if (eventId && sportId && isServiceIdReady) {
      FETCH_MARKET_GROUPS({ serviceId, lang, sportId });
    }
  }, [isServiceIdReady, serviceId, lang, eventId, sportId, lineKey]);

  useEffect(() => {
    if (tournamentId && isServiceIdReady) {
      getEvents({
        lang,
        serviceId,
        tournamentsIds: [tournamentId]
      }).then(({ result = [] }) =>
        // Update events in state without current event, so we don't need lineKey
        SET_EVENTS({
          events: result.filter(({ eventId: eId }) => String(eId) !== eventId)
        })
      );
    }
  }, [isServiceIdReady, tournamentId, lang, serviceId]);

  useRemoveLine(lineKey);

  // Add key to force component unmount on event change
  return (
    <Box
      direction="column"
      key={eventId}
      className={clsx([!inIframe && styles.container])}
    >
      <OneEventHelmet />
      <EventContent
        eventId={eventId}
        lineKey={lineKey}
        eventGroup={isShowEventGroup ? eventGroup : ''}
        sportId={sportId}
        isCachedEvent={isCachedEvent}
        sportSlug={sportSlug}
        isLoading={isLoading}
        isShowStatUrl={isShowStatUrl}
        isShowScoreboard={isShowScoreboard}
        isShowSportRadarWidget={isShowSportRadarWidget}
        isIncorrectPath={!isLoading && (event === null || !eventId)}
        slugPath={slugPath}
      />
      {isRenderEventsLineInsideEventDetails && (
        <>
          {isCachedEvent &&
            !isEmpty(activeSport) &&
            (!isEmpty(category) ? (
              <PreMatchCategoryEvents />
            ) : (
              <PreMatchEventsForEventDetails />
            ))}
          {serviceId !== MODE.PRE_MATCH &&
            !pathname.includes(liveEvent) &&
            event !== null && (
              <LiveButton
                eventId={eventId}
                serviceId={serviceId}
                sportName={sportName}
              />
            )}
        </>
      )}
      <BreadCrumbs
        type={eventStatusType}
        categoryId={!isCachedEvent && categoryId}
        categoryName={!isCachedEvent && categoryName}
        sportName={!isCachedEvent && sportName}
        sportSlug={!isCachedEvent && sportSlug}
        eventId={eventId}
        eventName={eventName}
        tournamentName={!isCachedEvent && tournamentName}
        tournamentsIds={!isCachedEvent && [tournamentId]}
      />
    </Box>
  );
};

export default Event;
