import { convertParam } from '@namespace/sports/src/utils';

/**
 *
 * @param {string} outcomeName
 * @param {boolean} isAlwaysShowParam
 * @returns {string} like 'some string (parameter)'
 *                        |   name   | |outcomeParam|
 */
const convertOutcomeName = (outcomeName = '', isAlwaysShowParam = false) => {
  const [name] = outcomeName.split(/\(([^)]+)\)$/);
  // get parameter of the outcome ex. 0.25, 0.5
  let [, outcomeParam = ''] = outcomeName.trim().match(/\(([^)]+)\)$/) || [];

  if (outcomeParam) {
    const numericParam = Number(outcomeParam);

    if (numericParam <= 0 || isAlwaysShowParam) {
      const [symbol = ''] = outcomeParam.match(/^[-+]/) || [];
      const param = numericParam ? convertParam(outcomeParam) : outcomeParam;
      outcomeParam = `(${isAlwaysShowParam ? symbol : ''}${param})`;
    } else if (numericParam > 0) {
      outcomeParam = '';
    } else {
      outcomeParam = `(${outcomeParam})`;
    }
  }

  return `${name}${outcomeParam}`;
};

export default convertOutcomeName;
