import { useI18n } from '@namespace/i18n';
import styles from '../../../index.module.css';

const CommonTax = ({ tax, returnAmount }) => {
  const { t } = useI18n();

  return (
    <ul className={styles.taxes}>
      <li className={styles.taxesItem}>
        {`${t('personalOffice.balance.withdraw.tax.incomeTax')}: ${tax}`}
      </li>
      <li className={styles.taxesItem}>
        {`${t(
          'personalOffice.balance.withdraw.tax.totalAmount'
        )}: ${returnAmount}`}
      </li>
    </ul>
  );
};

export default CommonTax;
