import { useContext } from 'react';
import { Box, AText } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { DeviceContext } from '@namespace/device';

import styles from './index.module.css';

export const PromotionsEmptyState = ({ activePromoType, parsedConfig }) => {
  const { isDesktop } = useContext(DeviceContext);
  const { t } = useI18n();

  return (
    <Box
      className={styles.wrapper}
      justify="center"
      align="center"
      direction="column"
    >
      <img
        className={styles.placeholderImage}
        src={parsedConfig[activePromoType]?.placeholderImage}
        alt="placeholderImage"
      />
      <AText
        className={styles.placeholderText}
        breed="BU"
        size={isDesktop ? '3xl' : '2xl'}
        color="txt_color_7"
        align="center"
      >
        {t(`web.${activePromoType}.placeholder`)}
      </AText>
    </Box>
  );
};
