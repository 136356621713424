import { forwardRef, Fragment, useContext, useEffect, useMemo } from 'react';
import { isEmpty, noop, range } from 'lodash';
import clsx from 'clsx';
import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { USER_ROLES, UserContext } from '@namespace/user';
import { TournamentsLeaderboardsContext } from '../../tournamentsLeaderboardsStore/context';
import { useSubscribeToTournamentLeaderboard } from './useSubscribeToTournamentLeaderboard';
import { TournamentTakePartButton } from '../../common/TournamentTakePartButton';
import { TOURNAMENT_LEADERBOARD_TOP_PLAYERS_LIMIT } from '../../constants';
import {
  useGetTournamentServiceTournamentInfo,
  usePromotionsStore
} from '../../store';

import { LeaderboardGridRow } from './LeaderboardGridRow';
import styles from './index.module.css';

// TODO: possible to move on formatters lvl
// from [{ prizeTranslationKey: 'qwe.qwe', placeFrom: 1, placeTo: 1 }] to -> { 1: 'qwe.qwe' }
const getLeaderboardPlaceToPrizeMap = (leaderboardPrizes) => {
  const leaderboardPlaceToPrizeMap = {};

  for (const leaderboardPrize of leaderboardPrizes) {
    for (const leaderBoardPlace of range(
      leaderboardPrize.placeFrom,
      leaderboardPrize.placeTo + 1
    )) {
      leaderboardPlaceToPrizeMap[leaderBoardPlace] =
        leaderboardPrize.prizeTranslationKey || 0;
    }
  }

  return leaderboardPlaceToPrizeMap;
};

export const TournamentLeaderboard = forwardRef(
  (
    { promo = {}, promoLink, className, onLeaderboardVisibleChange = noop },
    ref
  ) => {
    const [{ tournamentsLeaderboards }] = useContext(
      TournamentsLeaderboardsContext
    );
    const isTournamentsLoaded = usePromotionsStore('isTournamentsLoaded');
    const [user] = useContext(UserContext);
    const { t } = useI18n();
    const isLoggedInUser = user.role === USER_ROLES.USER;
    const {
      tournamentId,
      leaderboardPrizes = [],
      periodEnd,
      showTakePart
    } = promo;
    const currentDate = new Date();
    const periodEndDate = new Date(periodEnd);
    const isTournamentFinished = periodEndDate <= currentDate;
    const tournamentServiceTournamentInfo = useGetTournamentServiceTournamentInfo(
      tournamentId
    );
    const { participant } = tournamentServiceTournamentInfo?.user || {};
    const { leaderboard = [], qualificationProgress, qualified } =
      tournamentsLeaderboards[tournamentId] || {};

    const placeToPrizeMap = useMemo(
      () => getLeaderboardPlaceToPrizeMap(leaderboardPrizes),
      [leaderboardPrizes]
    );
    // TODO: somehow reuse isTakePartButtonShown / isQualificationProgressShown logic from tournament card
    const isTakePartButtonShown =
      showTakePart &&
      !isTournamentFinished &&
      (!isLoggedInUser ||
        (isLoggedInUser && !participant && isTournamentsLoaded));
    const isQualificationProgressShown = participant && !qualified;

    const leaderboardRows = useMemo(
      () =>
        leaderboard.map((leaderboardItem) => ({
          ...leaderboardItem,
          prize: placeToPrizeMap[leaderboardItem.place]
        })),
      [leaderboard, placeToPrizeMap]
    );
    const isLeaderboardHidden =
      isEmpty(leaderboardRows) && !isQualificationProgressShown;

    useEffect(() => {
      onLeaderboardVisibleChange(!isLeaderboardHidden);
    }, [isLeaderboardHidden, onLeaderboardVisibleChange]);

    useSubscribeToTournamentLeaderboard(tournamentId, isTournamentFinished);

    if (isLeaderboardHidden) {
      return null;
    }

    return (
      <Box
        className={clsx([styles.wrapper, className])}
        direction="column"
        ref={ref}
      >
        <Box
          className={styles.sectionTitleWrapper}
          align="center"
          data-role="promotion-item-title"
        >
          <AText className={styles.sectionTitle} breed="BU" color="txt_color_1">
            {t(`web.tournaments.leaderboard.title`)}
          </AText>
        </Box>
        <Box
          className={styles.sectionContent}
          justify="center"
          align="center"
          direction="column"
        >
          <Box
            className={styles.leaderboard}
            direction="column"
            justify="center"
          >
            <Box className={styles.leaderboardHeader}>
              <Box align="center" justify="center">
                <AText className={styles.leaderboardHeaderItemText} breed="BU">
                  {t('web.tournaments.leaderboard.place')}
                </AText>
              </Box>
              <Box align="center" justify="center">
                <AText className={styles.leaderboardHeaderItemText} breed="BU">
                  {t('web.tournaments.leaderboard.user')}
                </AText>
              </Box>
              <Box align="center" justify="center">
                <AText className={styles.leaderboardHeaderItemText} breed="BU">
                  {t('web.tournaments.leaderboard.points')}
                </AText>
              </Box>
              <Box align="center" justify="center">
                <AText className={styles.leaderboardHeaderItemText} breed="BU">
                  {t('web.tournaments.leaderboard.prize')}
                </AText>
              </Box>
            </Box>
            <Box className={styles.leaderboardGrid}>
              {leaderboardRows.map((leaderboardRow, index) => {
                const isUserPlace = leaderboardRow.userId === user.id;
                return (
                  <Fragment key={`user-${leaderboardRow.userId}-info`}>
                    {index === TOURNAMENT_LEADERBOARD_TOP_PLAYERS_LIMIT && (
                      <Box className={styles.leaderboardHr} />
                    )}

                    <LeaderboardGridRow
                      {...leaderboardRow}
                      isUserPlace={isUserPlace}
                    />
                  </Fragment>
                );
              })}
              {isQualificationProgressShown && (
                <>
                  {!isEmpty(leaderboardRows) && (
                    <Box className={styles.leaderboardHr} />
                  )}
                  <Box
                    padding="s 0 s 0"
                    direction="column"
                    align="center"
                    className={styles.qualificationProgressWrapper}
                  >
                    <Box align="center">
                      <Box padding="0 s 0 0">
                        <AText breed="BU" size="xl" color="txt_color_7">
                          {t('web.tournaments.leaderboard.qualification')}
                          <>:</>
                        </AText>
                      </Box>
                      <AText breed="BU" size="2xl" color="po_txt_color_1">
                        {qualificationProgress?.qualificationBets}
                        <> /</>
                        {qualificationProgress?.bets}
                      </AText>
                    </Box>
                  </Box>
                  <LeaderboardGridRow
                    place="-"
                    points={qualificationProgress?.potentialPoints}
                    isUserPlace={true}
                    isPotentialPoints={true}
                  />
                </>
              )}
            </Box>

            {isQualificationProgressShown && (
              <Box padding="ms 0 0 0">
                <AText breed="R" size="2xl" color="txt_color_7">
                  {`* ${t('web.tournaments.leaderboard.potentialpoints')}`}
                </AText>
              </Box>
            )}
          </Box>

          {isTakePartButtonShown && (
            <Box className={styles.takePartButtonWrapper} justify="center">
              <TournamentTakePartButton
                tournamentId={tournamentId}
                loginRedirectTo={promoLink}
                className={styles.takePartButton}
                isUpdateLeaderboard={true}
                size="xl"
              />
            </Box>
          )}
        </Box>
      </Box>
    );
  }
);
