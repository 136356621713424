export const types = {
  GET_GAMES: 'GET_GAMES',
  SET_GAMES: 'SET_GAMES',
  GET_GAME: 'GET_GAME',
  SET_GAME: 'SET_GAME',
  GET_FAVORITES_LIST: 'GET_FAVORITES_LIST',
  SET_FAVORITES_LIST: 'SET_FAVORITES_LIST',
  ADD_GAME_FAVORITES_LIST: 'ADD_GAME_FAVORITES_LIST',
  REMOVE_GAME_FAVORITES_LIST: 'REMOVE_GAME_FAVORITES_LIST',
  SET_IS_FEED_LOADING: 'SET_IS_FEED_LOADING',
  SET_JACKPOTS: 'SET_JACKPOTS',
  SET_PRAGMATIC_LIVE_DATA: 'SET_PRAGMATIC_LIVE_DATA',
  GET_CMS_PRAGMATIC_LIVE_INFO: 'GET_CMS_PRAGMATIC_LIVE_INFO',
  SET_CMS_PRAGMATIC_LIVE_INFO: 'SET_CMS_PRAGMATIC_LIVE_INFO',
  GET_PROVIDER: 'GET_PROVIDER',
  SET_PROVIDER: 'SET_PROVIDER',
  GET_RECENTLY_PLAYED_LIST: 'GET_RECENTLY_PLAYED_LIST',
  SET_RECENTLY_PLAYED_LIST: 'SET_RECENTLY_PLAYED_LIST',
  RESET_RECENTLY_PLAYED_LIST: 'RESET_RECENTLY_PLAYED_LIST',
  GET_RECOMMENDED_GAMES_LIST: 'GET_RECOMMENDED_GAMES_LIST',
  SET_RECOMMENDED_GAMES_LIST: 'SET_RECOMMENDED_GAMES_LIST',
  SET_EXCLUDED_GAMES_LIST: 'SET_EXCLUDED_GAMES_LIST',
  FETCH_WAGER_EXCLUDED_GAME_LIST: 'FETCH_WAGER_EXCLUDED_GAME_LIST',
  GET_WAGER_SLOT_GAMES: 'GET_WAGER_SLOT_GAMES',
  SET_WAGER_SLOT_GAMES: 'SET_WAGER_SLOT_GAMES',
  GET_LIVE_RTP_GAMES: 'GET_LIVE_RTP_GAMES',
  SET_LIVE_RTP_GAMES: 'SET_LIVE_RTP_GAMES'
};
