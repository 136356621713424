import { forwardRef } from 'react';
import { Box } from '@alf/uikit';
import { Category } from './Category';
import { useCyberSportTournamentsIdsByCategoryId } from '../../store';
import styles from './index.module.css';

const Categories = forwardRef(
  (
    {
      categoryRoute,
      categoryId,
      activeTournamentId,
      withIcon,
      classNames: { containerClassName = '', itemClassName = '' } = {}
    },
    ref
  ) => {
    const ids = useCyberSportTournamentsIdsByCategoryId(categoryId);

    return ids ? (
      <Box
        ref={ref}
        direction="column"
        className={`${styles.container} ${containerClassName}`}
      >
        {ids.map((tournamentId) => (
          <Category
            className={itemClassName}
            key={`cyberSportMenuTournament-${tournamentId}`}
            categoryId={tournamentId}
            basePath={`${categoryRoute}/${categoryId}`}
            isActive={tournamentId === activeTournamentId}
            withIcon={withIcon}
          />
        ))}
      </Box>
    ) : null;
  }
);

export default Categories;
