import { useContext, useEffect } from 'react';
import { I18nContext } from '../context';

export const useFetchTimeZones = () => {
  const [i18n, i18nActions] = useContext(I18nContext);
  const { language } = i18n;
  const { FETCH_TIMEZONES } = i18nActions;

  useEffect(() => {
    FETCH_TIMEZONES();
  }, [language]);
};
