export { SPORTS_CONTEXT_TYPES } from './contextTypes';
export { MARKET_TEMPLATE_IDS_LIST } from './marketTemplateIds';
export const MODE = {
  IN_PLAY: 1, // [1]
  PRE_MATCH: 0, // [0]
  ALL: 'all' // [1, 0]
};

export const SPORTS_LINE_TYPES = {
  COMMON_SPORT: 'commonSport',
  CUSTOM_FILTER: 'customFilter'
};

export const SPORT_IDS = {
  SOCCER: 1,
  TENNIS: 2,
  GOLF: 3,
  ATHLETICS: 4,
  ICE_HOCKEY_OLD: 5,
  ALPINE_SKIING: 6,
  BIATHLON: 7,
  BOBSLEIGH: 8,
  CROSS_COUNTRY_SKIING: 9,
  CURLING: 10,
  FIGURE_SKATING: 11,
  FREESTYLE_SKIING: 12,
  LUGE: 13,
  NORDIC_COMBINED: 14,
  SHORT_TRACK_SKATING: 15,
  SKELETON: 16,
  JUMPING_WITH_SKI: 17,
  SNOWBOARDING: 18,
  SPEED_SKATING: 19,
  HANDBALL: 20,
  OUTRIGHTS: 21,
  MOTORSPORTS: 22,
  BASKETBALL: 23,
  AMERICAN_FOOTBALL: 24,
  BANDY: 25,
  BASEBALL: 26,
  BEACH_VOLLEYBALL: 27,
  FLOORBALL: 28,
  RUGBY_UNION: 29,
  CYCLING: 30,
  HORSE_RACING: 31,
  ARCHERY: 32,
  BADMINTON: 33,
  BOXING: 34,
  CANOEING: 35,
  DIVING: 36,
  EQUESTRIAN: 37,
  FENCING: 38,
  ICE_HOCKEY: 39,
  GYMNASTICS: 40,
  JUDO: 41,
  MODERN_PENTATHLON: 42,
  ROWING: 43,
  SAILING: 44,
  SHOOTING: 45,
  SWIMMING: 46,
  SYNCHRONISED_SWIMMING: 47,
  TABLE_TENNIS: 48,
  TAEKWONDO: 49,
  TRIATHLON: 50,
  VOLLEYBALL: 51,
  WATER_POLO_GAME: 52,
  WEIGHT_LIFTING: 53,
  WRESTLING: 54,
  TRACK_CYCLING: 55,
  MOUNTBIKE: 56,
  SOFTBALL: 57,
  BMX: 58,
  REFERENCE_SPORT: 59,
  SNOOKER: 60,
  CRICKET: 61,
  AUSSIE_RULES: 62,
  SURFING: 63,
  AUTOSPORT: 64,
  DARTS: 65,
  VIRTUAL_SPORTS: 66,
  BEACH_FOOTBALL: 68,
  FUTSAL: 71,
  FIELD_HOCKEY: 72,
  FORMULA_1: 73,
  LOTTERY: 74,
  OLYMPICS: 75,
  CHESS: 76,
  POLITICS: 77,
  INDOOR_SOCCER: 78,
  RUGBY_1: 79,
  BOWLS: 80,
  BONUS: 81,
  SPECIALS: 84,
  CYBERSPORT: 85,
  INSPIRED: 86,
  NETBALL: 87,
  MMA: 89,
  SEPAKTAKRAW: 90,
  SQUASH: 91,
  HURLING: 92,
  LACROSSE: 93,
  KORFBALL: 95,
  RALLY: 96,
  BILLIARD: 99,
  VIRTUAL_BOX: 100,
  POKER: 132,
  INTERNATIONAL_LOTTERY: 165,
  SOCCER_STATISTICS: 167,
  GRID_GOAL: 168,
  SNOW_VOLLEYBALL: 169,
  VIRTUAL_TENNIS_OPEN: 171,
  VIRTUAL_BASKETBALL_LEAGUE: 172,
  BASKETBALL_STATISTICS: 173,
  NASCAR: 175,
  SUMO: 176,
  BETRADAR_NATIONS_CUP: 177,
  TENNIS_STATISTICS: 178,
  VERMANTIA: 179,
  CROSSFIT: 180,
  BALL_HOCKEY: 181,
  MINI_FOOTBALL: 182,
  GAELIC_FOOTBALL: 183,
  E_FOOTBALL: 184,
  E_BASKETBALL: 185,
  E_TENNIS: 186,
  PING_PONG: 187,
  GREYHOUND_RACING: 188,
  E_FIGHTING: 191,
  E_ICE_HOCKEY: 192,
  E_CRICKET: 194,
  E_FUTSAL: 196,
  HEADIS: 197,
  KABADDI: 231,
  BASKETBALL3X3: 232,
  ICE_HOCKEY_STATISTICS: 230,
  SHORT_FOOTBALL: 233,
  INSPIRED_FOOTBALL: 1559,
  INSPIRED_TENNIS: 35936,
  INSPIRED_CYCLING: 35938,
  INSPIRED_HORSE_RACING: 35939,
  INSPIRED_DOG_RACING: 35941,
  INSPIRED_FORMULA_1: 36097,
  VERMANTIA_HORSE_RACING: 36334,
  VERMANTIA_FOOTBALL: 36335,
  VERMANTIA_HORSE_ROULETTE: 36338,
  VERMANTIA_TABLE_TENNIS: 36339,
  VERMANTIA_FOOTBALL_DEV: 37286, // todo temporary for devel environment
  // CYBERSPORT GAMES
  DOTA2: 1537,
  COUNTER_STRIKE: 1800,
  STARCRAFT2: 1655,
  LEAGUE_OF_LEGENDS: 1536,
  ARENA_OF_VALOR: 37014,
  RAINBOW_SIX: 36765,
  KING_OF_GLORY: 36459,
  ROCKET_LEAGUE: 36267,
  OVERWATCH: 33870,
  VALORANT: 37387,
  FORTNITE: 37405,
  WARCRAFT3: 36288,
  PUBG: 36616,
  CYBERSPORT_SPECIALS: 36698,
  CYBERSPORT_SUMMARIES: 36318
};

export const SPORT_IDS_SUPPORTED_BY_SET_SCORE = [
  SPORT_IDS.BASKETBALL,
  SPORT_IDS.SOCCER,
  SPORT_IDS.VOLLEYBALL,
  SPORT_IDS.BASEBALL
];

export const SPORT_IDS_FOR_EVENT_NAME_CHANGES = [
  SPORT_IDS.NASCAR,
  SPORT_IDS.GOLF,
  SPORT_IDS.MOTORSPORTS
];

export const SORT_MAP = {
  TOURNAMENT: 'tournament',
  TIME: 'time',
  MBN: 'mbn'
};

export const EVENT_TIMER_ACTION = {
  START: 'start',
  PAUSE: 'pause'
};

export const EVENT_TIMER_DIRECTIONS = {
  ASC: 'asc',
  DESC: 'desc'
};

export const STARTINGSOON_FILTER = 'startingSoon';
export const NEXT_MORNING_FILTER = 'nextMorning';
export const ALL_FILTER_VALUE = 'all';
export const TODAY_FILTER = 'today';

export const DATE_FILTER_CMS_VALUES = {
  HOURS: 'hours',
  DAYS: 'days'
};

export const EVENT_TYPES = {
  INPROGRESS: 'inprogress',
  NOT_STARTED: 'notstarted'
};

export const SPORT_IDS_WITH_DETAILS = [
  SPORT_IDS.E_FOOTBALL,
  SPORT_IDS.FUTSAL,
  SPORT_IDS.INDOOR_SOCCER,
  SPORT_IDS.MINI_FOOTBALL,
  SPORT_IDS.SOCCER,
  SPORT_IDS.BASKETBALL
];

export const SPORT_BACKGROUNDS = {
  [SPORT_IDS.AMERICAN_FOOTBALL]: 'american-football',
  [SPORT_IDS.AUSSIE_RULES]: 'aussie-rules',
  [SPORT_IDS.BASKETBALL]: 'basketball',
  [SPORT_IDS.FIELD_HOCKEY]: 'field-hockey',
  [SPORT_IDS.HANDBALL]: 'handball',
  [SPORT_IDS.HURLING]: 'soccer',
  [SPORT_IDS.ICE_HOCKEY]: 'ice-hockey',
  [SPORT_IDS.ICE_HOCKEY_STATISTICS]: 'ice-hockey',
  [SPORT_IDS.SOCCER]: 'soccer',
  [SPORT_IDS.TABLE_TENNIS]: 'ping-pong',
  [SPORT_IDS.TENNIS]: 'tennis',
  [SPORT_IDS.VOLLEYBALL]: 'volleyball',
  [SPORT_IDS.WATER_POLO_GAME]: 'water-polo',
  [SPORT_IDS.KABADDI]: 'kabaddi',
  [SPORT_IDS.SHORT_FOOTBALL]: 'short-football',
  [SPORT_IDS.BASKETBALL3X3]: 'basketball',
  DEFAULT: 'default'
};

export const SPORT_ICONS = {
  [SPORT_IDS.SOCCER]: 'soccer',
  [SPORT_IDS.TENNIS]: 'tennis',
  [SPORT_IDS.GOLF]: 'golf',
  [SPORT_IDS.ATHLETICS]: 'athletics',
  [SPORT_IDS.ICE_HOCKEY_OLD]: 'iceHockey',
  [SPORT_IDS.ALPINE_SKIING]: 'alpineSkiing',
  [SPORT_IDS.BIATHLON]: 'biathlon',
  [SPORT_IDS.BOBSLEIGH]: 'bobsleigh',
  [SPORT_IDS.CROSS_COUNTRY_SKIING]: 'crossCountrySkiing',
  [SPORT_IDS.CURLING]: 'curling',
  [SPORT_IDS.FIGURE_SKATING]: 'figureSkating',
  [SPORT_IDS.FREESTYLE_SKIING]: 'freestyleSkiing',
  [SPORT_IDS.LUGE]: 'luge',
  [SPORT_IDS.NORDIC_COMBINED]: 'nordicCombined',
  [SPORT_IDS.SHORT_TRACK_SKATING]: 'shortTrackSkating',
  [SPORT_IDS.SKELETON]: 'skeleton',
  [SPORT_IDS.JUMPING_WITH_SKI]: 'jumpingWithSki',
  [SPORT_IDS.SNOWBOARDING]: 'snowboarding',
  [SPORT_IDS.SPEED_SKATING]: 'speedSkating',
  [SPORT_IDS.HANDBALL]: 'handball',
  [SPORT_IDS.OUTRIGHTS]: 'outrights',
  [SPORT_IDS.MOTORSPORTS]: 'motorsports',
  [SPORT_IDS.BASKETBALL]: 'basketball',
  [SPORT_IDS.AMERICAN_FOOTBALL]: 'americanFootball',
  [SPORT_IDS.BANDY]: 'bandy',
  [SPORT_IDS.BASEBALL]: 'baseball',
  [SPORT_IDS.BEACH_VOLLEYBALL]: 'beachVolleyball',
  [SPORT_IDS.FLOORBALL]: 'floorball',
  [SPORT_IDS.RUGBY_UNION]: 'rugby',
  [SPORT_IDS.CYCLING]: 'cycling',
  [SPORT_IDS.INSPIRED_CYCLING]: 'cycling',
  [SPORT_IDS.HORSE_RACING]: 'horseRacing',
  [SPORT_IDS.INSPIRED_HORSE_RACING]: 'horseRacing',
  [SPORT_IDS.VERMANTIA_HORSE_RACING]: 'horseRacing',
  [SPORT_IDS.VERMANTIA_HORSE_ROULETTE]: 'horseRacing',
  [SPORT_IDS.ARCHERY]: 'archery',
  [SPORT_IDS.BADMINTON]: 'badminton',
  [SPORT_IDS.BOXING]: 'boxing',
  [SPORT_IDS.CANOEING]: 'canoeing',
  [SPORT_IDS.DIVING]: 'diving',
  [SPORT_IDS.EQUESTRIAN]: 'equestrian',
  [SPORT_IDS.FENCING]: 'fencing',
  [SPORT_IDS.ICE_HOCKEY]: 'iceHockey',
  [SPORT_IDS.ICE_HOCKEY_STATISTICS]: 'iceHockeyStatistics',
  [SPORT_IDS.GYMNASTICS]: 'gymnastics',
  [SPORT_IDS.JUDO]: 'judo',
  [SPORT_IDS.MODERN_PENTATHLON]: 'modernPentathlon',
  [SPORT_IDS.ROWING]: 'rowing',
  [SPORT_IDS.SAILING]: 'sailing',
  [SPORT_IDS.SHOOTING]: 'shooting',
  [SPORT_IDS.SWIMMING]: 'swimming',
  [SPORT_IDS.SYNCHRONISED_SWIMMING]: 'synchronisedSwimming',
  [SPORT_IDS.TABLE_TENNIS]: 'tableTennis',
  [SPORT_IDS.VERMANTIA_TABLE_TENNIS]: 'tableTennis',
  [SPORT_IDS.TAEKWONDO]: 'taekwondo',
  [SPORT_IDS.TRIATHLON]: 'triathlon',
  [SPORT_IDS.VOLLEYBALL]: 'volleyball',
  [SPORT_IDS.WATER_POLO_GAME]: 'waterPoloGame',
  [SPORT_IDS.WEIGHT_LIFTING]: 'weightLifting',
  [SPORT_IDS.WRESTLING]: 'wrestling',
  [SPORT_IDS.TRACK_CYCLING]: 'trackCycling',
  [SPORT_IDS.MOUNTBIKE]: 'mountbike',
  [SPORT_IDS.SOFTBALL]: 'softball',
  [SPORT_IDS.BMX]: 'bmx',
  [SPORT_IDS.REFERENCE_SPORT]: 'referenceSport',
  [SPORT_IDS.SNOOKER]: 'snooker',
  [SPORT_IDS.CRICKET]: 'cricket',
  [SPORT_IDS.AUSSIE_RULES]: 'aussieRules',
  [SPORT_IDS.SURFING]: 'surfing',
  [SPORT_IDS.AUTOSPORT]: 'autosport',
  [SPORT_IDS.DARTS]: 'darts',
  [SPORT_IDS.VIRTUAL_SPORTS]: 'virtualSports',
  [SPORT_IDS.BEACH_FOOTBALL]: 'beachFootball',
  [SPORT_IDS.FUTSAL]: 'futsal',
  [SPORT_IDS.FIELD_HOCKEY]: 'fieldHockey',
  [SPORT_IDS.FORMULA_1]: 'formula1',
  [SPORT_IDS.INSPIRED_FORMULA_1]: 'formula1',
  [SPORT_IDS.LOTTERY]: 'lottery',
  [SPORT_IDS.OLYMPICS]: 'olympics',
  [SPORT_IDS.CHESS]: 'chess',
  [SPORT_IDS.POLITICS]: 'politics',
  [SPORT_IDS.INDOOR_SOCCER]: 'indoorSoccer',
  [SPORT_IDS.RUGBY_1]: 'rugby',
  [SPORT_IDS.BOWLS]: 'bowls',
  [SPORT_IDS.BONUS]: 'bonus',
  [SPORT_IDS.SPECIALS]: 'specials',
  [SPORT_IDS.CYBERSPORT]: 'cybersport',
  [SPORT_IDS.INSPIRED]: 'inspired',
  [SPORT_IDS.NETBALL]: 'netball',
  [SPORT_IDS.MMA]: 'mma',
  [SPORT_IDS.SEPAKTAKRAW]: 'sepaktakraw',
  [SPORT_IDS.SQUASH]: 'squash',
  [SPORT_IDS.HURLING]: 'hurling',
  [SPORT_IDS.LACROSSE]: 'lacrosse',
  [SPORT_IDS.KORFBALL]: 'korfball',
  [SPORT_IDS.RALLY]: 'rally',
  [SPORT_IDS.BILLIARD]: 'billiard',
  [SPORT_IDS.VIRTUAL_BOX]: 'virtualBox',
  [SPORT_IDS.POKER]: 'poker',
  [SPORT_IDS.INTERNATIONAL_LOTTERY]: 'internationalLottery',
  [SPORT_IDS.SOCCER_STATISTICS]: 'soccerStatistics',
  [SPORT_IDS.GRID_GOAL]: 'gridGoal',
  [SPORT_IDS.SNOW_VOLLEYBALL]: 'volleyball',
  [SPORT_IDS.VIRTUAL_TENNIS_OPEN]: 'tennis',
  [SPORT_IDS.INSPIRED_TENNIS]: 'tennis',
  [SPORT_IDS.VIRTUAL_BASKETBALL_LEAGUE]: 'basketball',
  [SPORT_IDS.BASKETBALL_STATISTICS]: 'basketballStatistics',
  [SPORT_IDS.NASCAR]: 'nascar',
  [SPORT_IDS.SUMO]: 'sumo',
  [SPORT_IDS.BETRADAR_NATIONS_CUP]: 'betradarNationsCup',
  [SPORT_IDS.TENNIS_STATISTICS]: 'tennisStatistics',
  [SPORT_IDS.VERMANTIA]: 'vermantia',
  [SPORT_IDS.CROSSFIT]: 'crossfit',
  [SPORT_IDS.BALL_HOCKEY]: 'ballHockey',
  [SPORT_IDS.MINI_FOOTBALL]: 'miniFootball',
  [SPORT_IDS.GAELIC_FOOTBALL]: 'gaelicFootball',
  [SPORT_IDS.E_FOOTBALL]: 'eFootball',
  [SPORT_IDS.E_BASKETBALL]: 'basketball',
  [SPORT_IDS.E_TENNIS]: 'tennis',
  [SPORT_IDS.PING_PONG]: 'pingPong',
  [SPORT_IDS.GREYHOUND_RACING]: 'greyhoundRacing',
  [SPORT_IDS.E_FIGHTING]: 'eFighting',
  [SPORT_IDS.E_ICE_HOCKEY]: 'iceHockey',
  [SPORT_IDS.E_CRICKET]: 'cricket',
  [SPORT_IDS.E_FUTSAL]: 'futsal',
  [SPORT_IDS.HEADIS]: 'headis',
  [SPORT_IDS.INSPIRED_FOOTBALL]: 'soccer',
  [SPORT_IDS.VERMANTIA_FOOTBALL]: 'soccer',
  [SPORT_IDS.VERMANTIA_FOOTBALL_DEV]: 'soccer',
  [SPORT_IDS.DOTA2]: 'dota2',
  [SPORT_IDS.COUNTER_STRIKE]: 'counterStrike',
  [SPORT_IDS.STARCRAFT2]: 'starCraft',
  [SPORT_IDS.LEAGUE_OF_LEGENDS]: 'leagueOfLegends',
  [SPORT_IDS.ARENA_OF_VALOR]: 'arenaOfValor',
  [SPORT_IDS.RAINBOW_SIX]: 'rainbow6',
  [SPORT_IDS.KING_OF_GLORY]: 'kingsOfGlory',
  [SPORT_IDS.ROCKET_LEAGUE]: 'rocketLeague',
  [SPORT_IDS.OVERWATCH]: 'overwatch',
  [SPORT_IDS.VALORANT]: 'valorant',
  [SPORT_IDS.FORTNITE]: 'fortnite',
  [SPORT_IDS.WARCRAFT3]: 'warcraft3',
  [SPORT_IDS.PUBG]: 'pubg',
  [SPORT_IDS.CYBERSPORT_SPECIALS]: 'cybersportSpecials',
  [SPORT_IDS.CYBERSPORT_SUMMARIES]: 'cybersportSummaries',
  all: 'all',
  topEvents: 'topEvents',
  DEFAULT: 'defaultIcon'
};

export const PAGINATION_TYPE = {
  INFINITE: 'infinite',
  SHOW_MORE: 'showMore'
};

export const LINE_KEY = {
  PRE_MATCH: 'preMatch',
  TOP_PRE_MATCH: 'topPreMatch',
  LIVE: 'live',
  POPULAR_BETS: 'popularBets',
  TOP_EVENTS: 'topEvents',
  TOP_LIVE_EVENTS: 'topLiveEvents',
  TEASERS: 'teasers',
  TEASERS2: 'teasers2',
  FAVORITES: 'favorites',
  CYBERSPORT_LIVE: 'cybersportLiveEvents',
  CYBERSPORT_PRE_MATCH: 'cybersportPreMatchEvents',
  CYBERSPORT_FAVORITES: 'cybersportFavorites',
  DETAIL_EVENT: 'detailEvent'
};

export const COLUMN_TYPES = {
  DEFAULT: 'default',
  TOTAL: 'total',
  PENALTY: 'penalty',
  BULLETS: 'bullets',
  POINTS: 'points',
  ROUND_ICON: 'roundIcon'
};

export const TEXT_BREED_BY_COLUMN_TYPES = {
  [COLUMN_TYPES.DEFAULT]: 'R',
  [COLUMN_TYPES.TOTAL]: 'B',
  [COLUMN_TYPES.PENALTY]: 'R',
  [COLUMN_TYPES.BULLETS]: 'R',
  [COLUMN_TYPES.POINTS]: 'R',
  [COLUMN_TYPES.ROUND_ICON]: 'B'
};

// TODO: temp, delete after all sports will be supported by getDetailedScoresForTable
export const ADAPTED_SPORT_IDS = [
  SPORT_IDS.SOCCER,
  SPORT_IDS.E_FOOTBALL,
  SPORT_IDS.BASKETBALL,
  SPORT_IDS.VOLLEYBALL,
  SPORT_IDS.TABLE_TENNIS,
  SPORT_IDS.BASEBALL,
  SPORT_IDS.ICE_HOCKEY,
  SPORT_IDS.TENNIS,
  SPORT_IDS.CYBERSPORT,
  SPORT_IDS.E_FIGHTING,
  SPORT_IDS.KABADDI,
  SPORT_IDS.BASKETBALL3X3,
  SPORT_IDS.SHORT_FOOTBALL,
  SPORT_IDS.HANDBALL
];

export const SCOPE_IDS = {
  POINTS: 1,
  YELLOW_CARD: 2,
  RED_CARD: 3,
  CORNER: 4,
  TIE_BREAK: 5,
  GAME: 6,
  SETS: 7,
  CHAMPION_TIE_BREAK: 8,
  PEN: 9,
  PLACE: 11,
  WINNER: 12,
  SETS_WINNER: 16,
  FOUL: 18,
  KILLS: 19,
  DRAGON: 20,
  NASHOR: 21,
  TOWER: 22,
  INHIBITOR: 23,
  FLAG: 25,
  BARRACK: 26,
  ROSHAN: 27,
  RUNS: 28,
  TR_PEN: 29,
  ACE: 30,
  TENNIS_POINTS: 31,
  REBOUNDS: 34,
  ROUNDS: 35,
  MISS_OR_BLOCK_BY_KEEPER: 36,
  MISS_OUT_OF_BOUNDS: 37,
  MISS_GOALPOST: 38,
  MEGACREEP: 40,
  RAMPAGE: 41,
  GODLIKE: 42,
  COURIER: 43,
  KO: 74,
  TKO: 75,
  DECISION: 76,
  SUBMISSION: 77,
  WICKET: 80,
  OVER: 81,
  DELIVERY: 82
};

export const RESULT_TYPE_IDS = {
  // soccer
  HALF_1: 7,
  HALF_2: 8,
  HALF_2_WITH_OT: 9,
  EXTRATIME_1: 510,
  EXTRATIME_2: 511,
  PENALTIES: 3,

  // basketball
  QUARTER1: 60,
  QUARTER2: 61,
  QUARTER3: 62,
  QUARTER4: 64,
  QUARTER4_WITH_ET: 64,

  // volleyball
  VOLLEYBALL_SET_1: 80,
  VOLLEYBALL_SET_2: 81,
  VOLLEYBALL_SET_3: 82,
  VOLLEYBALL_SET_4: 83,
  VOLLEYBALL_SET_5: 84,
  VOLLEYBALL_SET_6: 85,
  VOLLEYBALL_SET_7: 86,

  // table tennis
  TABLE_TENNIS_SET_1: 80,
  TABLE_TENNIS_SET_2: 81,
  TABLE_TENNIS_SET_3: 82,
  TABLE_TENNIS_SET_4: 83,
  TABLE_TENNIS_SET_5: 84,
  TABLE_TENNIS_SET_6: 85,
  TABLE_TENNIS_SET_7: 86,

  // baseball
  BASEBALL_INNING_1: 515,
  BASEBALL_INNING_2: 516,
  BASEBALL_INNING_3: 600,
  BASEBALL_INNING_4: 601,
  BASEBALL_INNING_5: 602,
  BASEBALL_INNING_6: 603,
  BASEBALL_INNING_7: 604,
  BASEBALL_INNING_8: 605,
  BASEBALL_INNING_9: 606,

  // ice hockey
  ICE_HOCKEY_PERIOD_1: 51,
  ICE_HOCKEY_PERIOD_2: 52,
  ICE_HOCKEY_PERIOD_3: 53,
  ICE_HOCKEY_BULLITT: 10,

  // tennis
  TENNIS_SET_1: 80,
  TENNIS_SET_2: 81,
  TENNIS_SET_3: 82,
  TENNIS_SET_4: 83,
  TENNIS_SET_5: 84,

  // cybersport
  CYBERSPORT_GAME_1: 100,
  CYBERSPORT_GAME_2: 101,
  CYBERSPORT_GAME_3: 102,
  CYBERSPORT_GAME_4: 103,
  CYBERSPORT_GAME_5: 104,
  CYBERSPORT_GAME_6: 105,
  CYBERSPORT_GAME_7: 106,
  CYBERSPORT_GAME_8: 118,
  CYBERSPORT_GAME_9: 119,
  CYBERSPORT_GAME_10: 120,
  CYBERSPORT_FULL_TIME: 1,
  CYBERSPORT_SUM: 609,

  // E-Fighting
  E_FIGHTING_ROUND_1: 31,
  E_FIGHTING_ROUND_2: 32,
  E_FIGHTING_ROUND_3: 33,
  E_FIGHTING_ROUND_4: 34,
  E_FIGHTING_ROUND_5: 35,
  E_FIGHTING_ROUND_6: 36,
  E_FIGHTING_ROUND_7: 644,
  E_FIGHTING_ROUND_8: 645,
  E_FIGHTING_ROUND_9: 646,

  // Cricket
  CRICKET_INNING_1: 660,
  CRICKET_INNING_2: 661,
  CRICKET_SUPER_OVER: 662,

  // E-Cricket
  E_CRICKET_INNING_1: 660,
  E_CRICKET_SUPER_OVER: 662,

  // Short football
  SHORT_FOOTBALL_FULL_TIME: 1,
  SHORT_FOOTBALL_HALF_1: 7,
  SHORT_FOOTBALL_HALF_2: 8,

  // Hadnball
  HANDBALL_HALF_1: 7,
  HANDBALL_HALF_2: 8,
  HANDBALL_HALF_ET1: 510,
  HANDBALL_HALF_ET2: 511,
  HANDBALL_HALF_PENALTIES: 3,

  // common
  OVERTIME: 512
};

export const SERVING_SPORTS = [
  SPORT_IDS.TABLE_TENNIS,
  SPORT_IDS.VOLLEYBALL,
  SPORT_IDS.TENNIS,
  SPORT_IDS.ICE_HOCKEY,
  SPORT_IDS.E_CRICKET
];

export const SUMMARIES_RESULT_TYPE_IDS = [11, 513, 609];
export const SORTED_MARKET_TEMPLATE_IDS = [1075, 1648, 1649, 1650];
export const TWO_COLUMNS_MARKET_TEMPLATE_VIEW_IDS = [5, 8];

export const ONE_EVENT_HEADER_TABS = {
  SCORE: 'score',
  VIDEO: 'video'
};

export const TEASER_TYPES = {
  TEASER: 'Teaser',
  HTML_SNIPPET: 'HTML snippet',
  IMAGE: 'Image'
};

export const SPORTRADAR_IDS = {
  [SPORT_IDS.SOCCER]: 1,
  [SPORT_IDS.BASKETBALL]: 2,
  [SPORT_IDS.ICE_HOCKEY]: 4,
  [SPORT_IDS.TENNIS]: 5,
  [SPORT_IDS.AMERICAN_FOOTBALL]: 16,
  [SPORT_IDS.HANDBALL]: 6,
  [SPORT_IDS.VOLLEYBALL]: 23,
  [SPORT_IDS.BEACH_VOLLEYBALL]: 34,
  [SPORT_IDS.TABLE_TENNIS]: 20,
  [SPORT_IDS.BADMINTON]: 31,
  [SPORT_IDS.RUGBY_UNION]: 12,
  [SPORT_IDS.RUGBY_1]: 12,
  [SPORT_IDS.BASEBALL]: 3,
  [SPORT_IDS.CRICKET]: 21,
  [SPORT_IDS.DARTS]: 22,
  [SPORT_IDS.FUTSAL]: 29,
  [SPORT_IDS.SNOOKER]: 19,
  [SPORT_IDS.FORMULA_1]: 40,
  [SPORT_IDS.E_FOOTBALL]: 137,
  [SPORT_IDS.E_BASKETBALL]: 153,
  [SPORT_IDS.E_ICE_HOCKEY]: 195
};

export const PANDASCORE_LANGUAGES_MAP = {
  uk: 'ru',
  ru: 'ru'
};

export const UFC_WIN_TYPE = {
  [SCOPE_IDS.KO]: 'ko',
  [SCOPE_IDS.TKO]: 'tko',
  [SCOPE_IDS.DECISION]: 'decision',
  [SCOPE_IDS.SUBMISSION]: 'submission'
};

export const E_FIGHTING_ROUNDS_MAP = {
  [RESULT_TYPE_IDS.E_FIGHTING_ROUND_1]: '1',
  [RESULT_TYPE_IDS.E_FIGHTING_ROUND_2]: '2',
  [RESULT_TYPE_IDS.E_FIGHTING_ROUND_3]: '3',
  [RESULT_TYPE_IDS.E_FIGHTING_ROUND_4]: '4',
  [RESULT_TYPE_IDS.E_FIGHTING_ROUND_5]: '5',
  [RESULT_TYPE_IDS.E_FIGHTING_ROUND_6]: '6',
  [RESULT_TYPE_IDS.E_FIGHTING_ROUND_7]: '7',
  [RESULT_TYPE_IDS.E_FIGHTING_ROUND_8]: '8',
  [RESULT_TYPE_IDS.E_FIGHTING_ROUND_9]: '9'
};

export const SPORTRADAR_LANG = {
  en: 'en',
  ru: 'ru',
  uk: 'ukr',
  ro: 'ro',
  hr: 'hr',
  pt: 'pt',
  es: 'es'
};

export const PRESETS_SOURCE_TYPES = {
  EVENTS: 'events',
  TOURNAMENTS: 'tournaments'
};

export const LINE_VIEW = {
  BUTTON: 'button',
  TABLE: 'table'
};

export const SUMMARIES_SCOPE_DATA = {
  [SCOPE_IDS.PLACE]: 'scoreboard.place',
  [SCOPE_IDS.WINNER]: 'scoreboard.winner'
};

export const SUMMARIES_PLACE_TYPE = 'place';
export const SUMMARIES_WINNER_TYPE = 'winner';

export const CRICKET_1_INNING_SPORTFORM_IDS = [1143, 1144, 1171, 1172];
export const CRICKET_2_INNINGS_SPORTFORM_IDS = [1145, 1146];
export const CRICKET_TOTAL_SPORTFORM_IDS = [194, 195, 1049, 1050];
export const E_CRICKET_SPORTFORM_IDS = [1153, 1154];
export const E_CRICKET_TOTAL_SPORTFORM_IDS = [1082, 1083];

export const PINNED_TYPE = {
  SPORT: 'sport',
  CATEGORY: 'category'
};

export const SPORT_NONE_PATH = 'none';
export const CANCELLED_EVENT_STATUS = 'cancelled';
export const FINISHED_EVENT_STATUS = 'finished';

export const MATCH_RESULT_MARKET_TEMPLATE_ID = 1;
export const TEASERS_NAVIGATION_TYPES = {
  BULLETS: 'bullets',
  BULLETS_AND_ARROWS: 'bulletsAndArrows',
  NONE: 'none'
};
