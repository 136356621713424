import { useEffect, useContext } from 'react';
import { bulletSubscribe, bulletUnsubscribe } from '@namespace/socket';
import { UserContext, PREWAGER_WALLET_PREFIX } from '@namespace/user';

export const useBalanceBullet = () => {
  const [, userActions] = useContext(UserContext);
  const { UPDATE_WALLET_BALANCE, SET_BONUS_WALLET } = userActions;

  const handler = (data) => {
    const {
      summ: deposit,
      wallet_hash: walletHash,
      wallet_id: walletId = ''
    } = data;
    if (walletId.includes(PREWAGER_WALLET_PREFIX)) {
      SET_BONUS_WALLET({ deposit });
    } else {
      UPDATE_WALLET_BALANCE({ walletHash, deposit });
    }
  };

  const handleExtPaySys = () => {};

  useEffect(() => {
    bulletSubscribe({
      name: 'balance-bullet',
      types: ['deposit'],
      callback: (data) => {
        if (data.action === 'update') {
          handler(data);
        }
      }
    });

    bulletSubscribe({
      name: 'ext_pay_sys',
      types: ['ext_pay_sys'],
      callback: handleExtPaySys
    });

    return () => {
      bulletUnsubscribe('balance-bullet');
      bulletUnsubscribe('ext_pay_sys');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
