import { useMemo } from 'react';
import { find, pipe, propEq, propOr } from 'ramda';
import { useGeneralRoutes } from '@namespace/cms';
import { MODE } from '../../constants';
import {
  useSportsbookStore,
  useEventFields,
  useTopPreMatchEventFields
} from '../../store';

export const useEventLink = ({ eventId, isTopEvents, event }) => {
  const { preMatchEvent, liveEvent } = useGeneralRoutes();
  const eventFields = useEventFields({
    eventId,
    fields: ['inprogress', 'serviceId', 'sportId']
  });

  const topPreMatchEventFields = useTopPreMatchEventFields({
    eventId,
    fields: ['inprogress', 'serviceId', 'sportId']
  });

  const { inprogress, serviceId, sportId } =
    event || (isTopEvents ? topPreMatchEventFields : eventFields);

  const sports = useSportsbookStore('sports');

  const sport = useMemo(() => {
    if (sportId == null) {
      return null;
    }

    return pipe(
      propOr([], serviceId),
      find(propEq('sportId', sportId))
    )(sports);
  }, [serviceId, sportId, sports]);

  if (!sportId || !sport) {
    return '';
  }

  const { sportSlug } = sport;
  const serviceLink = serviceId === MODE.IN_PLAY ? liveEvent : preMatchEvent;

  return inprogress
    ? `${liveEvent}/${sportSlug}/${eventId}`
    : `${serviceLink}/${sportSlug}/${eventId}`;
};
