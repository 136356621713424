import { useContext, useState } from 'react';
import { Button, Overlay } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { UserContext } from '@namespace/user';
import styles from './index.module.css';

const Preview = ({ gameCode, provider }) => {
  const [isOverlayShown, setIsOverlayShown] = useState(true);
  const { t } = useI18n();
  const [user] = useContext(UserContext);
  const { id: userId } = user;

  const handleClick = () => {
    logAction(LOG_ACTIONS.PLAY_INSTANT_GAME, {
      gameCode,
      gameProvider: provider,
      userId
    });

    setIsOverlayShown(false);
  };

  if (!isOverlayShown) {
    return null;
  }

  return (
    <Overlay
      classNames={{ wrapperClass: styles.overlay }}
      className={styles.overlay}
    >
      <Button
        intent="primary"
        actionType="color2"
        size="s"
        className={styles.button}
        onClick={handleClick}
      >
        {t('games.playForReal')}
      </Button>
    </Overlay>
  );
};

export default Preview;
