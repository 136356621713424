import { Box, AText } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import styles from './index.module.css';

const FormHelpText = ({
  children,
  icon,
  iconColor = 'po_txt_color_3',
  iconSize = 'xs',
  className = ''
}) => {
  return (
    <Box align="start" padding="0 s" className={className}>
      {Boolean(icon) && (
        <Icon
          name={icon}
          size={iconSize}
          color={iconColor}
          className={styles.icon}
        />
      )}
      <AText breed="R" size="s" color="po_txt_color_3">
        {children}
      </AText>
    </Box>
  );
};

export default FormHelpText;
