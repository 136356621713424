import { matchPath } from 'react-router-dom';

const getMatchedPathFromBatch = (batch, path) => {
  const pathArray = Object.keys(batch).sort((a, b) => {
    const aArr = a.split('/');
    const bArr = b.split('/');

    return aArr.length > bArr.length || aArr.at(-1).indexOf(':') === 0 ? 1 : -1;
  });

  const matchedPath = pathArray.reduce((acc, i) => {
    if (acc) return acc;

    return matchPath(
      {
        path: i,
        end: true
      },
      path === '' ? '/' : path
    );
  }, null);

  return matchedPath?.pattern.path;
};

export default getMatchedPathFromBatch;
