import { useCallback, useContext } from 'react';
import { OnboardingContext } from '../../../../../store/context';

export const useInterceptorAction = () => {
  const [{ steps, stepIndex }, { NEXT }] = useContext(OnboardingContext);
  const isLastStep = stepIndex === steps.length - 1;

  return useCallback(() => {
    if (!isLastStep) {
      NEXT();
    }
  }, [NEXT, isLastStep]);
};
