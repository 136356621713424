import { Box } from '@alf/uikit';
import { useComponentConfig } from '@namespace/cms';
import { LazyResponsiveImage } from '@namespace/common';
import { useGetImage } from '@namespace/themes';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { DEFAULT_JACKPOT_CODE } from '../../../constants';
import { useProvider } from '../../../context/selectors';
import { useGetGameJackpot, useGetPlatformForFeed } from '../../../hooks';
import { CasinoSkelet } from './CasinoSkelet';
import GameJackpot from './GameJackpot';
import styles from './index.module.css';

const getPlaceholderImgName = (isWideGame, validPath) => {
  if (isWideGame) return validPath('wideGamePic', 'wideGamePicDark');

  return validPath('gamePic', 'gamePicDark');
};

const GameImage = ({ game, onImageLoad, isWideGame, className }) => {
  const {
    name,
    cardImage = {},
    providerId,
    jackpotCode = DEFAULT_JACKPOT_CODE
  } = game;
  const platform = useGetPlatformForFeed();
  const { jackpots, showJackpotTickers } = useComponentConfig();
  const provider = useProvider(providerId);
  const { serviceId } = provider;

  const image = isWideGame
    ? game[platform]?.instaGameImage
    : cardImage[platform];

  const getImg = useGetImage();
  const [isImageLoadingEnded, setIsImageLoadingEnded] = useState(false);
  const [isImageSuccessfullyLoaded, setIsImageSuccessfullyLoaded] = useState(
    false
  );

  const gameJackpot = useGetGameJackpot(serviceId, jackpotCode);

  const isShowJackpotTickers = !showJackpotTickers
    ? false
    : jackpots.find((jackpot) => jackpot.providerId === serviceId)
        ?.showJackpotTickers;

  const handleLoad = useCallback(
    (e) => {
      setIsImageLoadingEnded(true);
      const width = e.target.naturalWidth;
      const height = e.target.naturalHeight;

      if (width > 100 && height > 100) {
        onImageLoad(true);
        setIsImageSuccessfullyLoaded(true);
      }
    },
    [onImageLoad]
  );

  const placeholderImgName = getPlaceholderImgName(isWideGame, getImg);

  return (
    <div
      className={clsx([
        styles.wrapper,
        isImageSuccessfullyLoaded && styles.loaded,
        className
      ])}
    >
      <Box className={styles.lazyLoadWrapper}>
        <LazyResponsiveImage
          onLoad={handleLoad}
          className={`${styles.lazyLoadImg} ${
            isImageLoadingEnded ? styles.show : styles.hide
          }`}
          src={image}
          srcSetUnitsType="x"
          alt={name}
          width="100%"
          height="100%"
        />
        <CasinoSkelet
          className={!isImageLoadingEnded ? styles.show : styles.hide}
        />
      </Box>
      {isShowJackpotTickers && gameJackpot != null && (
        <GameJackpot gameJackpot={gameJackpot} />
      )}
      <Box
        className={isWideGame ? styles.wideGamePlaceholder : styles.placeholder}
      >
        <img
          className={styles.placeholderImg}
          src={`/images/casino/common/${placeholderImgName}.svg`}
          alt={`${name} placeholder`}
          width="100%"
          height="100%"
        />
      </Box>
    </div>
  );
};

export default GameImage;
