import { useI18n } from '@namespace/i18n';
import { AText, Box } from '@alf/uikit';
import { getScore } from '../../../../../../Score/utils';
import { useEventField } from '../../../../../../../store';
import styles from './index.module.css';

const ShortFootball = ({ eventId }) => {
  const { t } = useI18n();
  const scoreboard = useEventField(eventId, 'scoreboard');

  const { foulsTotal } = getScore(scoreboard);

  if (!foulsTotal) {
    return null;
  }

  const [firstParticipantScore, secondParticipantScore] = foulsTotal;

  return (
    <Box className={styles.container} align="center" justify="center">
      <Box className={styles.stat} align="center">
        <AText color="line_txt_1" size="m">
          {firstParticipantScore}
        </AText>
        <AText
          color="line_txt_3"
          size="s"
          breed="BU"
          className={styles.statTitle}
        >
          {t('scoreboard.fouls')}
        </AText>
        <AText color="line_txt_1" size="m">
          {secondParticipantScore}
        </AText>
      </Box>
    </Box>
  );
};

export default ShortFootball;
