import { useContext } from 'react';
import { Box } from '@alf/uikit';
import { PageContext } from '@namespace/cms';
import { MultiColumnContent } from '../common/MultiColumnContent';
import { ColumnContent } from '../common/ColumnContent';
import styles from '../index.module.css';

export const TwoColumnOneColumnFullHd = () => {
  const { page } = useContext(PageContext);
  const { layout = {} } = page;
  const { columns = [] } = layout;

  return (
    <Box direction="column" className={styles.twoColumnOneColumnFullHd}>
      <Box className={styles.twoColumn70_30FullHd}>
        <MultiColumnContent
          columns={columns.slice(0, 2)}
          columnClassName={styles.column}
        />
      </Box>
      <Box direction="column" className={styles.oneColumnFullHd}>
        <ColumnContent column={columns[2]} />
      </Box>
    </Box>
  );
};
