import clsx from 'clsx';
import { Box } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import styles from './index.module.css';

const EventsLineHeader = ({
  isExpanded = true,
  children,
  isCollapsible = false,
  toggleExpanded,
  className = '',
  borderBottom = false,
  dataRole
}) => (
  <Box
    className={clsx(
      styles.wrapper,
      isExpanded && styles.expanded,
      className,
      borderBottom && styles.borderBottom
    )}
    onClick={isCollapsible ? toggleExpanded : null}
    padding="0 s"
    align="center"
    justify="between"
    data-role={dataRole}
  >
    {children}
    {isCollapsible && (
      <Box className={styles.controls}>
        <Icon
          size="s"
          color="line_header_txt"
          name={`icons/general/nav/${isExpanded ? 'collapse' : 'expand'}`}
        />
      </Box>
    )}
  </Box>
);

export default EventsLineHeader;
