import { camelizeKeys } from 'humps';
import { post } from '@namespace/helpers';

export const putCVVToCache = ({ cardHash, userId, accountingId, cvv, ssid }) =>
  post('/pankeeper/frontend_api/put_cvv_to_cache/', {
    user_id: String(userId),
    accounting_id: accountingId,
    ssid,
    card_hash: cardHash,
    cvv
  }).then(camelizeKeys);
