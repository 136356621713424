import { useConfig } from '@namespace/cms';
import Wrapper from './Wrapper';
import DocumentInformation from './DocumentInformation';

const ProfileDocuments = ({ id, classNames = {} }) => {
  const { config } = useConfig(id);
  return (
    <Wrapper>
      <DocumentInformation
        showDocumentsInfo={true}
        config={config}
        classNames={classNames}
      />
    </Wrapper>
  );
};

export default ProfileDocuments;
