import { camelizeKeys, decamelizeKeys } from 'humps';
import { bettorrentPost } from '@namespace/helpers';

export const checkLineElements = async ({
  lineElement = [],
  lang,
  serviceId,
  partnerId
}) =>
  bettorrentPost({
    url: '/service/bettorrent/custom_filter/line_element/check',
    mode: 'same-origin',
    body: decamelizeKeys({
      lineElement: lineElement.map((el) => decamelizeKeys(el)),
      lang,
      serviceId,
      partnerId
    })
  }).then(({ response = [] }) => response.map((item) => camelizeKeys(item)));
