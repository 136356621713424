import { useCallback, useRef, useState } from 'react';
import { includes, pathOr, pipe } from 'ramda';
import { useConfig, useGeneralRoutes } from '@namespace/cms';
import { FILTER_ALL_VALUE, useFixScroll } from '@namespace/common';
import { Box, EmptyState, Overlay } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { usePrevious, window } from '@namespace/helpers';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { isEmpty } from 'lodash';
import { LINE_KEY, MODE } from '../../constants';
import {
  BackHeaderCommon,
  CategoriesDrawer,
  CategoriesTitle,
  EventsLineHeader,
  EventsLineHeadGroups,
  EventsLineHeadGroupsFilterDropdown,
  LineSport,
  TournamentsMenu
} from '../../common';
import BreadCrumbs from '../../common/BreadCrumbs';
import { useActiveHeadGroups } from '../../hooks/useActiveHeadGroups';
import { useGetPathParams } from '../../hooks/useGetPathParams';
import useEventsTimeFilter from '../../hooks/useEventsTimeFilter';
import useChangeDefaultFilter from '../../hooks/useChangeDefaultFilter';
import { useSportsbookStore } from '../../store';
import styles from './index.module.css';

const PreMatchCategoryEventsExtended = ({ id }) => {
  const containerRef = useRef(null);
  const [tournament, setTournament] = useState(null);
  const [isCategoriesOpened, setIsCategoriesOpened] = useState(false);
  const [isTodayFilterApplied, setIsTodayFilterApplied] = useState(false);
  const isChangeDefaultFilter = useChangeDefaultFilter();
  const isReady = useSportsbookStore('isTournamentCategoryReady');
  const { t } = useI18n();
  const toggleCategories = (e) => {
    e.stopPropagation();
    return setIsCategoriesOpened((prev) => !prev);
  };
  const { preMatchCategory, home } = useGeneralRoutes();
  const { config } = useConfig(id);
  const {
    filters,
    isHeadGroupsSortingEnabled,
    activeHeadGroupsAmount,
    isDisplayHeadMarkets = true
  } = config;
  const {
    isShowAll,
    isShowStartingSoon,
    default: defaultFilter,
    ...filterConfig
  } = filters;
  const { filter, setFilter } = useEventsTimeFilter({
    initialFilter: {
      [defaultFilter]: defaultFilter
    },
    filterConfig
  });
  const { activeSport, category, categoryId } = useGetPathParams(
    MODE.PRE_MATCH
  );
  const { categoryName = '' } = category || {};
  const prevCategoryId = usePrevious(categoryId);
  const { sportId, sportName = '', sportSlug } = activeSport || {};
  const [activeHeadGroups, setActiveHeadGroups] = useActiveHeadGroups(
    isHeadGroupsSortingEnabled,
    activeHeadGroupsAmount,
    sportId,
    MODE.PRE_MATCH
  );
  const lineKey = `${LINE_KEY.PRE_MATCH}-${sportId}`;
  const isPinned = useSportsbookStore(
    pipe(pathOr([], ['favorites', 'categories']), includes(categoryId))
  );

  useFixScroll('CategoriesDrawer', containerRef, isCategoriesOpened);

  const handleDefaultFilterChange = useCallback(() => {
    if (isChangeDefaultFilter && !isTodayFilterApplied) {
      setFilter({ [FILTER_ALL_VALUE]: FILTER_ALL_VALUE });
      setIsTodayFilterApplied(true);
    }
  }, [isChangeDefaultFilter, isTodayFilterApplied, setFilter]);

  if (
    (isReady && isEmpty(category)) ||
    (activeSport === null && sportSlug) ||
    (!categoryId && !window.IS_SSR)
  ) {
    return (
      <EmptyState
        text={t('web.sports.noCategory')}
        Link={LocalizedLink}
        linkPath={home}
        linkText={t('home')}
      />
    );
  }

  // Add key to force component unmount on category change
  return (
    <Box key={categoryId} direction="column">
      <BackHeaderCommon
        Title={() => (
          <CategoriesTitle
            categoryName={categoryName}
            sportName={sportName}
            onToggleMenu={toggleCategories}
            isCategoriesOpened={isCategoriesOpened}
          />
        )}
        arrowBackIcon={
          <Icon
            size="s"
            name="icons/general/nav/back"
            color="line_header_txt"
          />
        }
        sportId={sportId}
        categoryId={categoryId}
        pinnedType="category"
        isPinned={isPinned}
        containerClassName={styles.headerContainer}
        showPinIcon={true}
      />
      <Box direction="column">
        {isCategoriesOpened && (
          <Overlay
            onClick={toggleCategories}
            classNames={{
              contentClass: styles.overlay,
              wrapperClass: styles.wrapper
            }}
          >
            <CategoriesDrawer
              ref={containerRef}
              serviceId={MODE.PRE_MATCH}
              categoryRoute={preMatchCategory}
              activeCategoryId={categoryId}
              sportSlug={sportSlug}
            />
          </Overlay>
        )}
        <TournamentsMenu
          serviceId={MODE.PRE_MATCH}
          onSelect={setTournament}
          activeTournament={tournament}
        />
        <EventsLineHeader
          isCollapsible={false}
          borderBottom={true}
          key="dark-header"
        >
          <EventsLineHeadGroupsFilterDropdown
            filterConfig={filterConfig}
            filterValue={filter}
            isShowAll={isShowAll}
            isShowStartingSoon={isShowStartingSoon}
            onSetFilter={setFilter}
            className={styles.timeFilterDropdown}
          />
          <EventsLineHeadGroups
            serviceId={MODE.PRE_MATCH}
            sport={activeSport}
            isHeadGroupsSortingEnabled={true}
            activeHeadGroups={activeHeadGroups}
            setActiveHeadGroups={setActiveHeadGroups}
            isDisplayHeadMarkets={isDisplayHeadMarkets}
          />
        </EventsLineHeader>
        <LineSport
          id={id}
          filter={filter}
          defaultFilter={defaultFilter}
          activeHeadGroups={activeHeadGroups}
          tournamentsIds={
            tournament && prevCategoryId === categoryId ? [tournament] : []
          }
          sport={activeSport}
          categoryIds={categoryId ? [categoryId] : []}
          lineKey={lineKey}
          serviceId={MODE.PRE_MATCH}
          isExpanded={false}
          isShowEmpty={true}
          isTodayFilterApplied={isTodayFilterApplied}
          onDefaultFilterChange={handleDefaultFilterChange}
          isReady={isReady}
        />
      </Box>
      <BreadCrumbs
        categoryId={categoryId}
        categoryName={categoryName}
        sportName={sportName}
        sportSlug={sportSlug}
      />
    </Box>
  );
};

export default PreMatchCategoryEventsExtended;
