import { get } from '@namespace/helpers';

export const getBonusSystemHistory = () =>
  get('/service/prewager/campaigns/history').then(
    ({ data, status, errorCode }) => {
      const { campaigns } = data;

      return status === 'success'
        ? Promise.resolve(campaigns)
        : Promise.reject(errorCode);
    }
  );
