import { useEffect } from 'react';
import { useIntersect } from '@namespace/helpers';

const ListLoader = ({
  currentPage = 1,
  totalPages = 1,
  setPage = () => {},
  className = '',
  isBackendPagination = false,
  isLastPage = false,
  children
}) => {
  const [bottomRef, { isIntersecting }] = useIntersect({});

  useEffect(() => {
    if (
      ((totalPages !== currentPage && totalPages > 1) ||
        (isBackendPagination && !isLastPage)) &&
      isIntersecting
    ) {
      setPage(currentPage + 1);
    }
  }, [isIntersecting]);

  return (
    <div className={className}>
      {children}
      <div ref={bottomRef} />
    </div>
  );
};

export default ListLoader;
