import { forwardRef } from 'react';
import { EventCard } from '../../../common';
import { useEventFields } from '../../../store';

const Card = forwardRef((props, ref) => {
  const { eventId } = props;
  const { categoryName, tournamentName } = useEventFields({
    eventId,
    fields: ['categoryName', 'tournamentName']
  });

  return (
    <EventCard
      {...props}
      ref={ref}
      title={`${categoryName} | ${tournamentName}`}
    />
  );
});

export default Card;
