import { CheckboxField } from '@namespace/forms';
import { useI18n } from '@namespace/i18n';
import { useGeneralRoutes } from '@namespace/cms';
import FieldLabel from '../FieldLabel';

const ReceiveNotificationField = (props) => {
  const { t } = useI18n();
  const { privacyPolicy } = useGeneralRoutes();
  return (
    <CheckboxField
      LabelComponent={
        <FieldLabel
          link={privacyPolicy}
          linkText={t('register.steps.secretQuestion.privacyPolicy')}
          firstText={t('register.steps.secretQuestion.acceptNotifications')}
          secondText={t('register.steps.secretQuestion.acceptNotifications2')}
        />
      }
      {...props}
    />
  );
};

export default ReceiveNotificationField;
