import { postAsUriParameters } from '@namespace/helpers';
import { camelizeKeys } from 'humps';
import { FORM_ERRORS_TYPES, structuringErrors } from '@namespace/forms';

export const saveUser = ({
  email,
  password,
  country,
  firstName,
  lastName,
  middleName,
  birthdate,
  gender,
  phone,
  secretQuestion,
  secretQuestionAnswer,
  confirmAge,
  promo,
  pin,
  pinType,
  city,
  zip,
  address,
  language,
  clubUser,
  iban,
  username,
  citizenship,
  documentCountry,
  documentDate,
  documentNumber,
  documentPlace,
  document,
  politicalStatus = false,
  sourceOfTheAssets = '',
  politicalDuty = '',
  politicallyExposed = '',
  currency,
  receiveNotifications = false,
  pepData = {},
  affiliateParams,
  welcomePackId,
  reCaptchaData = {}
}) => {
  const body = {
    email,
    password,
    country_id: country,
    first_name: firstName,
    last_name: lastName,
    date_of_birth: birthdate,
    title: gender,
    phone_number: phone?.number,
    question: secretQuestion,
    answer: secretQuestionAnswer,
    spam_ok: confirmAge ? 1 : 0,
    agreement_receive_notifications: receiveNotifications ? 1 : 0,
    PoliticalStatus: politicalStatus ? 1 : 0,
    coupon: promo,
    lang: language,

    ...(welcomePackId && { welcome_pack_id: Number(welcomePackId) }),
    ...(middleName && { middle_name: middleName }),
    ...(username && { username }),
    ...(pinType && { pin_type: pinType }),
    ...(pin && { pin }),
    ...(iban && { iban }),
    ...(city && { city }),
    ...(currency && { currency }),
    ...(citizenship && { citizenship }),
    ...(zip && { zip }),
    ...(address && { adress: address }),
    ...(document && { document_type_id: document }),
    ...(documentDate && { document_date: documentDate }),
    ...(documentNumber && { document_number: documentNumber }),
    ...(documentPlace && { document_place: documentPlace }),
    ...(documentCountry && { document_country: documentCountry }),
    ...(politicalStatus && {
      PoliticalExposed: politicallyExposed,
      PoliticalDuty: politicalDuty,
      SourceOfTheAssets: sourceOfTheAssets
    }),
    ...(clubUser && { fav_bet_club_user: 1 }),

    ...pepData,
    ...(affiliateParams && { affiliateParams })
  };

  return postAsUriParameters(
    '/accounting/api/saveuser',
    body,
    undefined,
    undefined,
    reCaptchaData
  )
    .then((data) => {
      const camelizeKeysData = camelizeKeys(data);
      if (data.response) {
        return camelizeKeysData;
      }
      return Promise.reject(
        structuringErrors(camelizeKeysData.elements || camelizeKeysData)
      );
    })
    .catch((error) => {
      if (error instanceof Error) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject([
          {
            key: FORM_ERRORS_TYPES.GLOBAL_ERROR,
            error: 'yes',
            message: 'accountingMessages.undefined'
          }
        ]);
      }

      return Promise.reject(error);
    });
};
