import { useContext, useState } from 'react';
import { AText, Box } from '@alf/uikit';
import {
  COLOR_SCHEME_AUTO,
  COLOR_SCHEME_DARK,
  COLOR_SCHEME_LIGHT
} from '@namespace/helpers';
import { Icon } from '@namespace/icons2';
import { useColorScheme } from '@namespace/themes';
import { useI18n } from '@namespace/i18n';
import { DeviceContext } from '@namespace/device';
import styles from './index.module.css';

const SettingsSchemeSwitcher = () => {
  const {
    colorScheme: { activeColorScheme },
    setColorScheme
  } = useColorScheme();
  const { t } = useI18n();
  const [active, setActive] = useState(activeColorScheme);
  const { pageType } = useContext(DeviceContext);
  const isActive = (value = '') => {
    return value === active;
  };

  const onClick = (scheme) => () => {
    setColorScheme(scheme);
    setActive(scheme);
  };

  const themeIconsData = [
    {
      type: COLOR_SCHEME_LIGHT,
      icon: 'icons/general/color_scheme/light'
    },
    {
      type: COLOR_SCHEME_AUTO,
      icon: 'icons/general/color_scheme/auto'
    },
    {
      type: COLOR_SCHEME_DARK,
      icon: 'icons/general/color_scheme/dark'
    }
  ];

  return (
    <Box
      data-role="settings-color-scheme-switcher"
      align="center"
      direction="column"
      className={styles.wrapper}
    >
      <Box align="center" justify="between" className={styles.container}>
        {themeIconsData.map(({ type, icon }) => (
          <Box
            data-role={`settings-color-scheme-switcher-${type}`}
            key={type}
            align="center"
            className={`${styles.theme} ${
              isActive(type) ? `${styles.active}` : ''
            }`}
            onClick={onClick(type)}
          >
            <Icon
              className={styles.themeIcon}
              size="m"
              color={
                isActive(type)
                  ? 'btn_primary_txt_color_5'
                  : 'btn_secondary_color_3'
              }
              name={icon}
            />
            <AText
              breed="BU"
              size="m"
              color={
                isActive(type)
                  ? 'btn_primary_txt_color_5'
                  : 'btn_secondary_color_3'
              }
            >
              {t(`web.settings.theme.${type}.${pageType}`)}
            </AText>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SettingsSchemeSwitcher;
