import { useMemo } from 'react';
import { useOtherSettings } from '@namespace/cms';
import {
  ensureCorrectImgMimeType,
  calcImageWSizes,
  calcWUnitSrcSet,
  calcXUnitSrcSet,
  ORDERED_SUPPORTED_IMAGE_FORMATS
} from './utils';

export const useCalcSrcSets = ({
  srcSetUnitsType,
  srcFileBase,
  srcExtension,
  sizesRange,
  sizes,
  isFeatureEnabled
}) => {
  const { responsiveImageConfig } = useOtherSettings();

  return useMemo(() => {
    if (srcExtension === 'gif' || !isFeatureEnabled) return [];

    const { deviceSizes, imageSizes, formats, xSizes } = responsiveImageConfig;
    const isWSrcSetSizeUnits = srcSetUnitsType === 'w';
    const totalFormats = [srcExtension, ...formats];

    let wImageSizesImageSizes;

    if (isWSrcSetSizeUnits) {
      wImageSizesImageSizes = calcImageWSizes({
        deviceSizes,
        imageSizes,
        sizesRange,
        sizes
      });
    }

    return totalFormats
      .sort(
        (a, b) =>
          ORDERED_SUPPORTED_IMAGE_FORMATS.indexOf(a) -
          ORDERED_SUPPORTED_IMAGE_FORMATS.indexOf(b)
      )
      .map((format) => ({
        imgType: ensureCorrectImgMimeType(format),
        srcSet: isWSrcSetSizeUnits
          ? calcWUnitSrcSet({
              fileBase: srcFileBase,
              format,
              imageSizes: wImageSizesImageSizes
            })
          : calcXUnitSrcSet({
              fileBase: srcFileBase,
              format,
              xSizes
            })
      }));
  }, [
    srcExtension,
    responsiveImageConfig,
    sizes,
    sizesRange,
    srcFileBase,
    srcSetUnitsType,
    isFeatureEnabled
  ]);
};
