import { useCallback } from 'react';
import clsx from 'clsx';
import { Box } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { BONUS_SLIDER_BONUS_TYPES } from '@namespace/bonuses/src/constants';
import BonusSliderItemDescription from './BonusSliderItemDescription';
import styles from './index.module.css';

const BonusSliderItem = ({ bonus, activeBonusId, onClick, userCurrency }) => {
  const { type, bonusId: fundsId, freespinId, isSelectedDeposit } = bonus;
  const bonusId = fundsId || freespinId;
  const isAvailableHover = bonusId !== activeBonusId;
  const isShowOutline =
    (type === BONUS_SLIDER_BONUS_TYPES.DEFAULT && !activeBonusId) ||
    isSelectedDeposit ||
    bonusId === activeBonusId;

  const onBonusClick = useCallback(() => {
    onClick({ bonusId, type, isSelectedDeposit });
  }, [bonusId, isSelectedDeposit, onClick, type]);

  return (
    <Box
      className={clsx(
        styles.itemContainer,
        isShowOutline && styles[type],
        isAvailableHover && styles.hover
      )}
      justify="start"
      onClick={onBonusClick}
      direction="column"
    >
      <Box className={styles.imageWrapper} direction="column" align="center">
        <Icon
          name={`icons/bonuses/${type}-background`}
          className={styles.background}
          fill=""
        />
        <img
          src={`/minio/bonuses/${type}.png`}
          alt="bonus_slider_item_background"
          className={styles.image}
        />
      </Box>
      <BonusSliderItemDescription bonus={bonus} userCurrency={userCurrency} />
    </Box>
  );
};

export default BonusSliderItem;
