import PropTypes from 'prop-types';
import clsx from 'clsx';
import { kebabCase } from 'lodash';
import SliderItems from '../SliderItems';
import Box from '../Box';
import AText from '../AText';
import styles from './index.module.css';

const SliderItem = ({
  label,
  id,
  selected,
  onChange,
  classNames = {},
  logo,
  dataRole
}) => {
  const {
    itemClassName = '',
    activeClassName = '',
    textClassName = '',
    activeTextClassName = ''
  } = classNames;

  return (
    <Box
      data-role={dataRole && `${dataRole}-tab-${kebabCase(label)}`}
      align="center"
      onClick={() => onChange(id)}
      className={clsx([
        styles.item,
        itemClassName,
        selected && `${styles.active} ${activeClassName}`
      ])}
    >
      <AText
        className={clsx([
          styles.itemText,
          textClassName,
          selected && `${styles.textActive} ${activeTextClassName}`
        ])}
        size="m"
      >
        {label}
      </AText>
      {logo && (
        <Box
          component="img"
          alt=""
          left="xxs"
          src={logo}
          width="14px"
          height="14px"
        />
      )}
    </Box>
  );
};

const FiltersSlider = ({
  items = [],
  selectedItems = [],
  onChange = () => {},
  transformStep,
  isButtonsEnabled = false,
  isDesktop,
  classNames = {},
  dataRole
}) => {
  const {
    containerClassName = '',
    buttonClassName = '',
    ...itemClassNames
  } = classNames;

  const sliderClassNames = { containerClassName, buttonClassName };

  return (
    <SliderItems
      dataRole={dataRole}
      isButtonsEnabled={isButtonsEnabled}
      isDesktop={isDesktop}
      transformStep={transformStep}
      classNames={sliderClassNames}
    >
      {items.map(({ label, id, isActive, logo }) => (
        <SliderItem
          dataRole={dataRole}
          key={`filter-${id}`}
          selected={isActive || selectedItems.includes(id)}
          label={label}
          id={id}
          onChange={onChange}
          classNames={itemClassNames}
          logo={logo}
        />
      ))}
    </SliderItems>
  );
};

FiltersSlider.propTypes = {
  items: PropTypes.instanceOf(Array),
  selectedItems: PropTypes.instanceOf(Array),
  onChange: PropTypes.func,
  isButtonsEnabled: PropTypes.bool,
  isDesktop: PropTypes.bool,
  transformStep: PropTypes.string,
  classNames: PropTypes.object
};

export default FiltersSlider;
