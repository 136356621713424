import { useContext, useEffect } from 'react';
import { ABTestContext } from '../../store/context';

const useInitABTest = (abTests) => {
  const [, { SET_AB_TEST_MAP }] = useContext(ABTestContext);

  useEffect(() => {
    if (abTests?.length) {
      SET_AB_TEST_MAP(abTests);
    }
  }, [abTests]);
};

export default useInitABTest;
