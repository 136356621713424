import { useContext, useEffect } from 'react';
import { SiteSettingsContext } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { window, useScript } from '@namespace/helpers';

const useSupportChat = (callback = () => {}) => {
  const { language: currentLanguage } = useI18n();
  const [settings] = useContext(SiteSettingsContext);
  const { supportSettings = {} } = settings || {};
  const { supportChat = {} } = supportSettings;
  const { zE } = window;

  useEffect(() => {
    zE?.setLocale && zE?.setLocale(currentLanguage);
  }, [zE, currentLanguage]);

  useScript(supportChat, callback);
};

export default useSupportChat;
