import { LIST_SEGMENT_FIELDS_MAP, SEGMENT_TECH_FIELDS } from '../../constants';

const isInList = (segmentValue, filterValue, key, segment) => {
  const { showMap, showKey } = LIST_SEGMENT_FIELDS_MAP[key];
  const incVal = segmentValue.includes(filterValue);
  const techFields = segment[SEGMENT_TECH_FIELDS.TECH_FIELDS] || {};

  // return true - filter segment, false - skip segment
  if (techFields[showKey] === showMap.LIST) {
    return !incVal; // if value includes, and LIST, do not filter segment
  }
  if (techFields[showKey] === showMap.EXCEPTION) {
    return incVal; // if value includes, and EXCEPTION, we filter this segment
  }

  return false;
};

export default isInList;
