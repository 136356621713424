import { Register as CoreRegister } from '@namespace/auth';
import { registerStepsMap } from './steps/registerStepsMap';
import styles from './index.module.css';

const Register = ({ id }) => (
  <CoreRegister
    id={id}
    componentsMap={registerStepsMap}
    classNames={{ wrapperClassName: styles.wrapper }}
  />
);

export default Register;
