export const SPORTS = 'sports';
export const LIVE = 'live';
export const CASINO = 'casino';
export const CASINO_LIVE = 'casinoLive';
export const VIRTUALS = 'virtuals';
export const CYBERSPORT = 'cybersport';
export const LOTTERIES = 'lotteries';
export const PROMO = 'promo';
export const TOURNAMENT = 'tournament';
export const OLD_VERSION = 'oldVersion';
export const GO_TO_OLD = 'goToOld';
export const ANIMATION_DELAY = 150;
export const INSTANT_GAMES = 'instantGames';
