import { useContext } from 'react';
import { LocalizedNavLink, useLocation } from '@namespace/i18n';
import { SubMenu as SubMenuUiKit } from '@alf/uikit';
import { getRootPath } from '@namespace/helpers';
import { DeviceContext } from '@namespace/device';

const SubMenu = ({ mainMenuItems = [], SecondaryItems = null, isActive }) => {
  const { isDesktop } = useContext(DeviceContext);
  const { pathname } = useLocation();

  const isActiveDefault = (link) => {
    const rootPath = getRootPath(pathname);
    const urlRoot = getRootPath(link);

    return urlRoot === rootPath;
  };

  return (
    <SubMenuUiKit
      mainMenuItems={mainMenuItems}
      SecondaryItems={SecondaryItems}
      isActive={isActive || isActiveDefault}
      NavLink={LocalizedNavLink}
      isDesktop={isDesktop}
      dataRolePrefix="sub-menu-item"
    />
  );
};

export default SubMenu;
