import {
  SESSION_STORAGE_KEYS,
  getSessionStorageValue,
  removeSessionStorageItem
} from '@namespace/helpers';

const clearRedirectAction = () => {
  const redirectURL = getSessionStorageValue(
    SESSION_STORAGE_KEYS.REDIRECT_URL,
    ''
  );

  if (redirectURL) {
    removeSessionStorageItem(SESSION_STORAGE_KEYS.REDIRECT_URL);
  }

  return redirectURL;
};

export default clearRedirectAction;
