import clsx from 'clsx';
import styles from './index.module.css';

const Skelet = () => {
  return (
    <>
      {Array(12)
        .fill(1)
        .map((_, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={clsx(styles.skelet, styles.skeletBlock)}
          />
        ))}
    </>
  );
};

export default Skelet;
