import { useCallback, useContext, useEffect } from 'react';
import { UserContext } from '@namespace/user';

export const useBankAccounts = (accountId) => {
  const [user, userActions] = useContext(UserContext);
  const { bankAccounts = [] } = user;
  const currentAccount = accountId
    ? bankAccounts.find(({ id }) => id === Number(accountId))
    : undefined;
  const {
    GET_BANK_ACCOUNTS,
    CREATE_BANK_ACCOUNT,
    DELETE_BANK_ACCOUNT,
    UPDATE_BANK_ACCOUNT
  } = userActions;

  useEffect(() => {
    GET_BANK_ACCOUNTS();
  }, []);

  const onDelete = useCallback(() => {
    if (accountId) {
      DELETE_BANK_ACCOUNT(accountId);
    }
  }, [accountId]);

  const onCreate = useCallback(async (data) => CREATE_BANK_ACCOUNT(data), []);

  const onUpdate = useCallback(
    (data) => {
      if (currentAccount) {
        return UPDATE_BANK_ACCOUNT({
          ...currentAccount,
          ...data,
          bankAccountId: accountId
        });
      }
      return false;
    },
    [currentAccount]
  );

  return {
    bankAccounts,
    onDelete,
    onCreate,
    onUpdate,
    currentAccount
  };
};
