export const COMPONENTS_TYPES = {
  HEADER: 'header',
  FOOTER: 'footer',
  BOTTOM_NAVIGATION_MENU: 'bottom-navigation-menu',
  FULL_SCREEN_POPUP: 'full-screen-popup',

  PRE_MATCH_SPORTS_MENU: 'preMatch-sports-menu',
  PRE_MATCH_FILTER_MENU: 'preMatch-filter-menu',
  SPORTS_MENU_BY_CONFIG: 'sports-menu-by-config',
  PRE_MATCH_SPORTS_CATEGORIES_MENU: 'preMatch-sports-categories-menu',
  PRE_MATCH_CATEGORIES_TOURNAMENTS_MENU: 'preMatch-categories-tournaments-menu',
  CYBER_SPORT_CATEGORIES_MENU: 'sportsBook-cybersport-tournaments-menu',
  LIVE_SPORTS_MENU: 'live-sports-menu',
  LIVE_SPORTS_MENU_SLIDER: 'live-sports-menu-slider',
  PRE_MATCH_SPORTS_MENU_SLIDER: 'preMatch-sports-menu-slider',
  CYBER_SPORT_MENU: 'cybersport-menu',
  CYBER_SPORT_SLIDER: 'cyber-sport-slider',

  SPORTS_BOOK_EVENTS: 'sportsbookevents',
  SPORTS_BOOK_SEARCH: 'sports-book-search',
  SPORTS_BOOK_TOP_EVENTS_MENU: 'sports-book-top-events-menu',
  SPORTS_BOOK_TOP_EVENTS_MENU_2: 'presets',
  SPORTS_BOOK_TOP_LIVE_EVENTS: 'sports-book-top-live-events',
  SEARCH_BACK_HEADER: 'search-back-header',
  SPORTS_BOOK_REDIRECT: 'sports-book-redirect',
  SPORTS_LOBBY_HEADER: 'sports-lobby-header',

  LIVE_EVENTS_BUTTON: 'live-events-button',
  LIVE_FAVORITES_BUTTON: 'live-favorites-button',
  GO_TO_TOURNAMENT_BUTTON: 'go-to-tournament-button',
  PRE_MATCH_FAVORITES_BUTTON: 'pre-match-favorites-button',

  BACK_HEADER: 'back-header',
  CYBER_SPORT_BACK_HEADER: 'cybersport-back-header',

  // OneEvent
  PRE_MATCH_ONE_EVENT: 'preMatch-one-event',
  LIVE_ONE_EVENT: 'live-one-event',
  //

  // Lines
  SPORTS_BOOK_PRE_MATCH_EVENTS: 'sports-book-preMatch-events',
  SPORTS_BOOK_CUSTOM_FILTER_EVENTS: 'sports-book-custom-filter-events',
  SPORTS_BOOK_PRE_MATCH_FILTERED_EVENTS_BY_PARAMS:
    'sports-book-preMatch-filtered-events-by-params',
  SPORTS_BOOK_PRE_MATCH_FILTERED_EVENTS_BY_CONFIG:
    'sports-book-preMatch-filtered-events-by-config',
  SPORTS_BOOK_PRE_MATCH_FAVORITE_EVENTS: 'sports-book-preMatch-favorite-events',
  SPORTS_BOOK_LIVE_FAVORITE_EVENTS: 'sports-book-live-favorite-events',
  SPORTS_BOOK_LIVE_EVENTS: 'sports-book-live-events',
  SPORTS_BOOK_LIVE_CALENDAR: 'sports-book-live-calendar',
  SPORTS_BOOK_POPULAR_BETS_EVENTS: 'sports-book-popular-bets-events',
  SPORTS_BOOK_CARDS_POPULAR_BETS_EVENTS:
    'sports-book-cards-popular-bets-events',
  CYBER_SPORT_CARDS_POPULAR_BETS_EVENTS:
    'sports-book-cards-cybersport-popular-bets-events',
  SPORTS_BOOK_TOP_EVENTS: 'sports-book-top-events',
  SPORTS_BOOK_TOP_EVENTS_2: 'sports-book-top-events-2',
  SPORTS_BOOK_PRE_MATCH_CATEGORY_EVENTS: 'sports-book-preMatch-category-events',
  SPORTS_BOOK_PRE_MATCH_TOURNAMENT_EVENTS:
    'sports-book-preMatch-tournament-events',
  SPORTS_BOOK_PRE_MATCH_CATEGORY_EVENTS_EXTENDED:
    'sports-book-preMatch-category-events-extended',
  SPORTS_BOOK_PRE_MATCH_TOURNAMENT_EVENTS_EXTENDED:
    'sports-book-preMatch-tournament-events-extended',
  SPORTS_BOOK_LIVE_ALL_EVENTS: 'sports-book-live-all-events',
  CYBER_SPORT_POPULAR_BETS_EVENTS: 'sports-book-cybersport-popular-events',
  CYBER_SPORT_LIVE_CATEGORIES_EVENTS: 'sports-book-cybersport-live-events',
  CYBER_SPORT_PRE_MATCH_CATEGORY_EVENTS:
    'sports-book-cybersport-preMatch-events',
  CYBER_SPORT_PRE_MATCH_TOURNAMENT_EVENTS:
    'sports-book-cybersport-preMatch-tournament-events',
  CYBER_SPORT_PRE_MATCH_TOURNAMENT_EVENTS_EXTENDED:
    'sports-book-cybersport-preMatch-tournament-events-extended',
  CYBER_SPORT_PRE_MATCH_FAVORITE_EVENTS:
    'sports-book-cybersport-preMatch-favorite-events',
  CYBER_SPORT_LIVE_FAVORITE_EVENTS:
    'sports-book-cybersport-live-favorite-events',
  // Teasers
  SPORTS_BOOK_TEASERS: 'sports-book-teasers',
  TEASERS: 'teasers',
  EXPRESS_DAY_LINK: 'express-day-link',
  POPULAR_SPORTS: 'popular-sports',

  SUB_MENU: 'submenu',
  TABS_MENU: 'tabsmenu',
  BET_SLIP: 'betslip',
  BET_SLIP_GLOBAL: 'betslip-global',
  BET_SLIP_LOCAL: 'betslip-local',
  MY_BETS: 'mybets',
  MULTIPLE_OF_THE_DAY: 'multiple-of-the-day',

  BETS_SPORT_HISTORY: 'bets-sport-history',
  BETS_SPORT_HISTORY_NEW: 'bets-sport-history-new',
  BETS_CASINO_HISTORY: 'bets-casino-history',
  BETS_KENO_HISTORY: 'bets-keno-history',
  BETS_FROM_EXTERNAL: 'bets-from-external',
  MULTI_BETS_FROM_EXTERNAL: 'multi-bets-from-external',

  SPORTS_RESULTS: 'sports-results',
  VIRTUAL_SPORTS_RESULTS: 'virtual-sports-results',
  CHANGE_PASSWORD: 'change-password',
  CHANGE_PHONE_NUMBER: 'change-phone-number',

  LOGIN_STEPPER: 'login-stepper',
  REGISTER: 'register',
  REGISTER_SUCCESS: 'register-success',
  PASSWORD_RECOVERY: 'password-recovery',
  EMAIL_UNSUBSCRIBE: 'email-unsubscribe',
  EMAIL_VERIFICATION_SUCCESS: 'email-verification-success',
  EMAIL_VERIFICATION_ERROR: 'email-verification-error',

  HTML_SNIPPET: 'html-snippet',
  HTML_SEGMENTED_SNIPPET: 'segmented-html-snippet',
  CONTENT_PAGE_MENU: 'content-page-menu',

  BANNER: 'banner',
  SCROLLER: 'vertical-full-scroller',

  CASINO_GAMES: 'casino-games',
  GAME_PLAY: 'game-play',
  GAME_LAUNCHER: 'game-launcher',
  PROVIDER_GAMES: 'provider-games',
  CASINO_GAME_CONTAINER: 'casino-game-container',
  CASINO_LAUNCH_REDIRECT: 'casino-launch-redirect',
  CASINO_BREAD_CRUMBS_MICRODATA: 'casino-bread-crumbs-microdata',
  INSTANT_GAME_LAUNCHER: 'instant-game-launcher',
  CASINO_IN_HOUSE_JACKPOT_IN_BANNER: 'casino-in-house-jackpot-in-banner',
  GAMES_LIST_WIDGET: 'games-list-widget',
  CASINO_GAMES_SEARCH: 'casino-games-search',
  CASINO_CATEGORIES_SIDEBAR: 'casino-categories-sidebar',

  VIRTUAL_SPORTS_LOBBY: 'virtual-sports-lobby',
  VIRTUAL_SPORT_EVENT: 'virtual-sport-event',
  VIRTUAL_SPORT_LOBBY_MENU: 'virtual-sport-lobby-menu',
  VIRTUAL_SPORTS_SUB_MENU: 'virtual-sports-sub-menu',
  VIRTUAL_SPORT_LAUNCHER: 'virtual-sport-launcher',
  VIRTUAL_SPORTS_GAMES_LIST_WIDGET: 'virtual-sports-games-list-widget',

  PERSONAL_OFFICE_MENU: 'personal-office-menu',

  PREDICTIONS: 'predictions',

  PAGE_TITLE: 'page-title',
  PAGE_SUBMENU: 'page-submenu', // personal office usage
  PAGE_BACK: 'page-back',

  REDIRECT: 'redirect',
  PROFILE_DATA: 'profile-data',
  PROFILE_DOCUMENTS: 'profile-documents',
  PROFILE_REQUISITES: 'profile-requisites',
  PROFILE_READONLY_DOCUMENTS: 'profile-readonly-documents',
  VERIFICATION_DOCUMENTS: 'verification-documents',
  VERIFICATION_SUCCESS_UPLOAD: 'verification-success-upload',
  PROFILE_DOCUMENTS_VERIFICATION_SUCCESS:
    'profile-documents-verification-success',
  PROFILE_DOCUMENTS_VERIFICATION_FAILURE:
    'profile-documents-verification-failure',
  PROFILE_BANK_ACCOUNTS: 'profile-bank-details',
  PROFILE_BANK_ACCOUNT_DETAILS: 'profile-bank-details-form',
  PROFILE_LOGIN_HISTORY: 'profile-login-history',
  USER_NOTIFICATIONS: 'user-notifications',
  WALLETS: 'wallets',
  CREATE_WALLET: 'create-wallet',
  DEPOSIT_TRANSACTIONS: 'deposit-transactions',
  WITHDRAWAL_TRANSACTIONS: 'withdrawal-transactions',
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdraw',
  SW_DEPOSIT: 'sw-deposit',
  SW_WITHDRAW: 'sw-withdraw',
  BETS_DETAILS: 'bets-details',
  BETS_DETAILS2: 'bets-details2',
  CASINO_DETAILS: 'casino-details',
  KENO_DETAILS: 'keno-details',
  CLUB_PLAYER_DATA: 'club-player-data',
  CLUB_PURCHASE_HISTORY: 'club-purchase-history',
  CLUB_SCORE_HISTORY: 'club-score-history',
  BONUS: 'bonus',
  BONUS_PROMO_CODE: 'bonus-promo-code',
  LOG_ON_TIMER: 'log-on-timer',
  FREE_SPIN_BONUS_DETAILS: 'free-spin-bonus-details',
  RISK_FREE_BONUS_DETAILS: 'risk-free-bonus-details',
  FUNDS_BONUS_DETAILS: 'funds-bonus-details',
  FREE_SPIN_BONUSES_LIST: 'free-spin-bonuses-list',
  RISK_FREE_BONUSES_LIST: 'risk-free-bonuses-list',
  FUNDS_BONUSES_LIST: 'funds-bonuses-list',
  FREE_SPIN_BONUSES_HISTORY_LIST: 'free-spin-bonuses-history-list',
  RISK_FREE_BONUSES_HISTORY_LIST: 'risk-free-bonuses-history-list',
  FUNDS_BONUSES_HISTORY_LIST: 'funds-bonuses-history-list',
  JACKPOT_BONUSES_LIST: 'jackpot-bonuses-list',
  BONUSES_SECTION: 'bonuses-section',
  BONUSES_HISTORY_SECTION: 'bonuses-history-section',
  SELF_EXCLUSION: 'self-exclusion',
  RESPONSIBLE_GAMBLING: 'responsible-gambling',
  LIMIT: 'limit',
  REALITY_CHECK: 'reality-check',
  MESSAGES_INBOX: 'messages-inbox',
  MESSAGES_SENT: 'messages-sent',
  SEND_MESSAGE: 'send-message',
  MESSAGE_PREVIEW: 'message-preview',
  INFO_PAGE: 'info-page',
  MOBILE_APP_BANNER: 'mobileAppBanner',
  PROMOTIONS: 'promotions',
  PROMOTION: 'promotion',
  RECENTLY_COMPLETED_PROMOTIONS: 'recently-completed-promotions',
  SIMPLE_GAMES_LIST: 'simple-games-list',
  MOBILE_POPUP_MENU: 'mobile-popup-menu',
  VIRTUAL_SPORTS: 'virtual-sports',
  SHOP: 'shop',
  SHOP_ORDER: 'shop-order',
  SHOP_ORDER_SUCCESS: 'shop-order-success',
  PREDICTIONS_BLOCK: 'predictions-block',
  PREDICTIONS_INFO_BLOCK: 'predictions-info-block',
  PREDICTIONS_FOOTER: 'predictions-footer',
  TOURNAMENT_BRACKET: 'tournament-bracket',
  TOURNAMENT_RESULTS_BRACKET: 'tournament-results-bracket',

  // components from footer and header
  BURGER: 'burger',
  LOGO: 'logo',
  LANGUAGE: 'language',
  THEMES_SWITCHER: 'themes-switcher',
  FOOTER_CLOCK: 'footer-clock',
  MESSAGES: 'messages',
  LOGIN_BUTTON: 'loginButton',
  REGISTER_BUTTON: 'registerButton',
  DEPOSIT_BUTTON: 'depositButton',
  QUICK_DEPOSIT_BUTTON: 'quickDepositButton',
  QUICK_DEPOSIT: 'quickDeposit',
  FAST_LOGIN: 'fastLogin',
  USER_BLOCK: 'userBlock',
  NAVIGATION: 'navigation',
  NAVIGATION_OLD_VERSION: 'navigation-old-version',
  NAVIGATION_GO_TO_OLD: 'navigation-go-to-old',
  FOOTER_MENU: 'footer-menu',
  NATIVE_APPS_LINK: 'native-apps-link',
  DEPOSIT_RESULT: 'deposit-result',
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
  ASSISTANT: 'assistant',
  PRE_MATCH_BREADCRUMBS_MICRODATA: 'prematch-breadcrumbs-microdata',
  LIVE_BREADCRUMBS_MICRODATA: 'live-breadcrumbs-microdata',
  CYBER_SPORT_BREADCRUMBS_MICRODATA: 'cybersport-breadcrumbs-microdata',
  SPORTS_MICRODATA: 'sports-microdata',
  CYBER_SPORTS_MICRODATA: 'cyber-sports-microdata',
  TOP_EVENTS_MICRODATA: 'top-events-microdata',
  WITHDRAW_BONUS_CONFIRMATION: 'bonus-withdraw-confirm',
  SUCCESSFUL_DEPOSIT: 'successful-deposit',
  COLOR_SCHEME_SWITCHER: 'color-scheme-switcher',
  BACK_TO_TOP: 'back-to-top',
  UNSUCCESSFUL_DEPOSIT: 'unsuccessful-deposit',
  CUSTOM_SUPPORT_CHAT: 'custom-support-chat',
  FOOTER_CONTACT_INFO_WITH_CUSTOM_CHAT: 'footer-contact-info-with-custom-chat',
  HEADER_CONTACT_INFO_WITH_CUSTOM_CHAT: 'header-contact-info-with-custom-chat',
  HEADER_CUSTOMER_SUPPORT: 'header-customer-support',
  CLOCK: 'clock',
  SYSTEM_BET_CALCULATOR: 'system-bet-calculator',
  COOKIE_MANAGEMENT: 'cookie-management',
  LOBBY_MENU: 'lobby-menu',
  SERVICES_LIST_WIDGET: 'services-list-widget',
  MOBILE_PROFILE_MENU: 'mobile-profile-menu',
  PWB_TRIGGERS_BONUS_SLIDER: 'pwb-triggers-bonus-slider',
  PWB_TRIGGERS_POPUP: 'pwb-triggers-popup',
  CONFIRM_MODAL: 'confirm-modal',
  SETTINGS: 'settings'
};

export const STATIC_COMPONENT_TYPES = [
  COMPONENTS_TYPES.BET_SLIP_LOCAL,
  COMPONENTS_TYPES.SPORTS_BOOK_SEARCH,
  COMPONENTS_TYPES.SPORTS_BOOK_TOP_EVENTS_MENU,
  COMPONENTS_TYPES.PRE_MATCH_SPORTS_MENU,
  COMPONENTS_TYPES.LIVE_SPORTS_MENU,
  COMPONENTS_TYPES.SPORTS_BOOK_TOP_LIVE_EVENTS,
  COMPONENTS_TYPES.CYBER_SPORT_MENU,
  COMPONENTS_TYPES.PRE_MATCH_FILTER_MENU,
  COMPONENTS_TYPES.LIVE_ONE_EVENT,
  COMPONENTS_TYPES.PRE_MATCH_ONE_EVENT
];

// List of components that should be only one on one column
export const UNIQUE_COMPONENT_TYPES = [
  COMPONENTS_TYPES.SPORTS_BOOK_TOP_EVENTS_MENU,
  COMPONENTS_TYPES.SPORTS_BOOK_TOP_EVENTS_MENU_2
];

export const WRAPPER_TYPES = {
  PRE_MATCH_SPORTS_BOOK_WRAPPER: 'preMatch-sportsBook-wrapper',
  PRE_MATCH_ROUTES_WRAPPER: 'preMatch-routes-wrapper',
  LIVE_SPORTS_BOOK_WRAPPER: 'live-sportsBook-wrapper',
  LIVE_ROUTES_WRAPPER: 'live-routes-wrapper',
  TOP_EVENTS_ROUTES_WRAPPER: 'top-events-routes-wrapper',
  TOP_EVENTS_WRAPPER: 'top-events-wrapper',
  TOP_EVENTS_WRAPPER_2: 'top-events-wrapper2',

  CYBER_SPORTS_WRAPPER: 'cybersport-sportsBook-wrapper',
  VIRTUAL_SPORTS_WRAPPER: 'virtual-sports-wrapper',
  VIRTUAL_SPORT_WRAPPER: 'virtual-sport-wrapper',

  BETSLIP_LOAD: 'betslip-load-wrapper',
  BETSLIP_LOAD_VIRTUAL: 'betslip-load-virtual-wrapper',

  SPORTS_BOOK_COMMON_WRAPPER: 'sportsBook-common-wrapper',

  PREDICTIONS_LOAD_WRAPPER: 'predictions-load-wrapper',

  PROMOTIONS_WRAPPER: 'promotions-wrapper',

  PROFILE_DATA_LOAD_WRAPPER: 'profile-data-load-wrapper',

  BONUSES_WRAPPER: 'bonuses-wrapper',

  PRE_WAGER_BONUSES_WRAPPER: 'pre-wager-bonuses-wrapper',

  POPULAR_SPORT_EVENTS_WRAPPER: 'popular-sport-events-wrapper',

  POPULAR_CYBER_SPORT_EVENTS_WRAPPER: 'popular-cyber-sport-events-wrapper'
};

export const WRAPPER_TYPES_MAP = {
  [WRAPPER_TYPES.PRE_MATCH_SPORTS_BOOK_WRAPPER]: [
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_CATEGORY_EVENTS_EXTENDED,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_TOURNAMENT_EVENTS_EXTENDED,
    COMPONENTS_TYPES.PRE_MATCH_FILTER_MENU,
    COMPONENTS_TYPES.PRE_MATCH_SPORTS_MENU,
    COMPONENTS_TYPES.PRE_MATCH_SPORTS_MENU_SLIDER,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_FILTERED_EVENTS_BY_PARAMS,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_FILTERED_EVENTS_BY_CONFIG,
    COMPONENTS_TYPES.SPORTS_BOOK_CUSTOM_FILTER_EVENTS,
    COMPONENTS_TYPES.PRE_MATCH_ONE_EVENT,
    COMPONENTS_TYPES.LIVE_ONE_EVENT
  ],
  [WRAPPER_TYPES.PRE_MATCH_ROUTES_WRAPPER]: [
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_FILTERED_EVENTS_BY_PARAMS,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_FILTERED_EVENTS_BY_CONFIG,
    COMPONENTS_TYPES.SPORTS_BOOK_POPULAR_BETS_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_CARDS_POPULAR_BETS_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_CATEGORY_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_TOURNAMENT_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_CATEGORY_EVENTS_EXTENDED,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_TOURNAMENT_EVENTS_EXTENDED,
    COMPONENTS_TYPES.BETS_FROM_EXTERNAL
  ],
  [WRAPPER_TYPES.LIVE_SPORTS_BOOK_WRAPPER]: [
    COMPONENTS_TYPES.SPORTS_BOOK_LIVE_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_LIVE_FAVORITE_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_LIVE_ALL_EVENTS,
    COMPONENTS_TYPES.LIVE_ONE_EVENT
  ],
  [WRAPPER_TYPES.LIVE_ROUTES_WRAPPER]: [
    COMPONENTS_TYPES.SPORTS_BOOK_LIVE_EVENTS
  ],
  [WRAPPER_TYPES.TOP_EVENTS_ROUTES_WRAPPER]: [
    COMPONENTS_TYPES.SPORTS_BOOK_TOP_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_TOP_EVENTS_2
  ],
  [WRAPPER_TYPES.TOP_EVENTS_WRAPPER]: [
    COMPONENTS_TYPES.SPORTS_BOOK_TOP_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_TOP_EVENTS_MENU
  ],
  [WRAPPER_TYPES.TOP_EVENTS_WRAPPER_2]: [
    COMPONENTS_TYPES.SPORTS_BOOK_TOP_EVENTS_2,
    COMPONENTS_TYPES.SPORTS_BOOK_TOP_EVENTS_MENU_2
  ],
  [WRAPPER_TYPES.CYBER_SPORTS_WRAPPER]: [
    COMPONENTS_TYPES.CYBER_SPORT_MENU,
    COMPONENTS_TYPES.CYBER_SPORT_LIVE_CATEGORIES_EVENTS,
    COMPONENTS_TYPES.CYBER_SPORT_POPULAR_BETS_EVENTS,
    COMPONENTS_TYPES.CYBER_SPORT_PRE_MATCH_FAVORITE_EVENTS,
    COMPONENTS_TYPES.CYBER_SPORT_LIVE_FAVORITE_EVENTS,
    COMPONENTS_TYPES.CYBER_SPORT_PRE_MATCH_CATEGORY_EVENTS,
    COMPONENTS_TYPES.CYBER_SPORT_CARDS_POPULAR_BETS_EVENTS,
    COMPONENTS_TYPES.CYBER_SPORT_PRE_MATCH_TOURNAMENT_EVENTS,
    COMPONENTS_TYPES.CYBER_SPORT_PRE_MATCH_TOURNAMENT_EVENTS_EXTENDED,
    COMPONENTS_TYPES.CYBER_SPORT_CATEGORIES_MENU,
    COMPONENTS_TYPES.CYBER_SPORTS_MICRODATA
    // todo added for rendering CYBER_SPORTS_WRAPPER in mobile,tablet
    //  sports lobby to display cybersport menulist
    // todo need to discuss whether we need CYBER_SPORTS_WRAPPER at all
  ],
  [WRAPPER_TYPES.VIRTUAL_SPORTS_WRAPPER]: [
    COMPONENTS_TYPES.VIRTUAL_SPORTS_LOBBY,
    COMPONENTS_TYPES.VIRTUAL_SPORT_EVENT,
    COMPONENTS_TYPES.VIRTUAL_SPORT_LOBBY_MENU,
    COMPONENTS_TYPES.VIRTUAL_SPORTS_SUB_MENU,
    COMPONENTS_TYPES.VIRTUAL_SPORT_LAUNCHER
  ],
  [WRAPPER_TYPES.VIRTUAL_SPORT_WRAPPER]: [
    COMPONENTS_TYPES.VIRTUAL_SPORTS_LOBBY,
    COMPONENTS_TYPES.VIRTUAL_SPORT_EVENT,
    COMPONENTS_TYPES.VIRTUAL_SPORT_LOBBY_MENU,
    COMPONENTS_TYPES.VIRTUAL_SPORTS_SUB_MENU
  ],
  [WRAPPER_TYPES.BETSLIP_LOAD]: [
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_FILTERED_EVENTS_BY_PARAMS,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_FILTERED_EVENTS_BY_CONFIG,
    COMPONENTS_TYPES.SPORTS_BOOK_TOP_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_TOP_EVENTS_2,
    COMPONENTS_TYPES.PRE_MATCH_ONE_EVENT,
    COMPONENTS_TYPES.SPORTS_BOOK_POPULAR_BETS_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_CARDS_POPULAR_BETS_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_CATEGORY_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_CATEGORY_EVENTS_EXTENDED,
    COMPONENTS_TYPES.SPORTS_BOOK_TEASERS,
    COMPONENTS_TYPES.TEASERS,
    COMPONENTS_TYPES.MULTIPLE_OF_THE_DAY,
    COMPONENTS_TYPES.SPORTS_BOOK_LIVE_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_LIVE_ALL_EVENTS,
    COMPONENTS_TYPES.LIVE_ONE_EVENT,
    COMPONENTS_TYPES.CYBER_SPORT_LIVE_CATEGORIES_EVENTS,
    COMPONENTS_TYPES.CYBER_SPORT_POPULAR_BETS_EVENTS,
    COMPONENTS_TYPES.CYBER_SPORT_PRE_MATCH_CATEGORY_EVENTS,
    COMPONENTS_TYPES.CYBER_SPORT_CARDS_POPULAR_BETS_EVENTS,
    COMPONENTS_TYPES.CYBER_SPORT_PRE_MATCH_TOURNAMENT_EVENTS,
    COMPONENTS_TYPES.CYBER_SPORT_PRE_MATCH_TOURNAMENT_EVENTS_EXTENDED,
    COMPONENTS_TYPES.BETS_FROM_EXTERNAL,
    COMPONENTS_TYPES.SPORTS_BOOK_LIVE_FAVORITE_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_FAVORITE_EVENTS,
    COMPONENTS_TYPES.CYBER_SPORT_LIVE_FAVORITE_EVENTS,
    COMPONENTS_TYPES.CYBER_SPORT_PRE_MATCH_FAVORITE_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_TOURNAMENT_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_TOURNAMENT_EVENTS_EXTENDED,
    COMPONENTS_TYPES.SPORTS_BOOK_LIVE_CALENDAR,
    COMPONENTS_TYPES.SPORTS_BOOK_CUSTOM_FILTER_EVENTS
  ],
  [WRAPPER_TYPES.BETSLIP_LOAD_VIRTUAL]: [COMPONENTS_TYPES.VIRTUAL_SPORT_EVENT],
  [WRAPPER_TYPES.SPORTS_BOOK_COMMON_WRAPPER]: [
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_FILTERED_EVENTS_BY_PARAMS,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_FILTERED_EVENTS_BY_CONFIG,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_FAVORITE_EVENTS,
    COMPONENTS_TYPES.CYBER_SPORT_PRE_MATCH_FAVORITE_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_TOP_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_TOP_EVENTS_2,
    COMPONENTS_TYPES.SPORTS_BOOK_TOP_EVENTS_MENU_2,
    COMPONENTS_TYPES.PRE_MATCH_ONE_EVENT,
    COMPONENTS_TYPES.SPORTS_BOOK_POPULAR_BETS_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_CARDS_POPULAR_BETS_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_CATEGORY_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_TOURNAMENT_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_CATEGORY_EVENTS_EXTENDED,
    COMPONENTS_TYPES.SPORTS_BOOK_PRE_MATCH_TOURNAMENT_EVENTS_EXTENDED,
    COMPONENTS_TYPES.SPORTS_BOOK_TEASERS,
    COMPONENTS_TYPES.TEASERS,
    COMPONENTS_TYPES.MULTIPLE_OF_THE_DAY,
    COMPONENTS_TYPES.BETS_FROM_EXTERNAL,
    COMPONENTS_TYPES.SPORTS_BOOK_REDIRECT,
    COMPONENTS_TYPES.PRE_MATCH_FILTER_MENU,
    COMPONENTS_TYPES.PRE_MATCH_SPORTS_MENU,
    COMPONENTS_TYPES.PRE_MATCH_SPORTS_MENU_SLIDER,
    COMPONENTS_TYPES.SPORTS_BOOK_SEARCH,
    COMPONENTS_TYPES.SPORTS_MICRODATA,
    COMPONENTS_TYPES.TOP_EVENTS_MICRODATA,
    COMPONENTS_TYPES.SPORTS_BOOK_LIVE_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_LIVE_FAVORITE_EVENTS,
    COMPONENTS_TYPES.CYBER_SPORT_LIVE_FAVORITE_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_LIVE_ALL_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_LIVE_CALENDAR,
    COMPONENTS_TYPES.CYBER_SPORT_PRE_MATCH_CATEGORY_EVENTS,
    COMPONENTS_TYPES.CYBER_SPORT_PRE_MATCH_TOURNAMENT_EVENTS,
    COMPONENTS_TYPES.CYBER_SPORT_PRE_MATCH_TOURNAMENT_EVENTS_EXTENDED,
    COMPONENTS_TYPES.LIVE_ONE_EVENT,
    COMPONENTS_TYPES.VIRTUAL_SPORTS_LOBBY,
    COMPONENTS_TYPES.VIRTUAL_SPORT_EVENT,
    COMPONENTS_TYPES.VIRTUAL_SPORT_LOBBY_MENU,
    COMPONENTS_TYPES.LIVE_EVENTS_BUTTON,
    COMPONENTS_TYPES.SPORTS_BOOK_LIVE_CALENDAR,
    COMPONENTS_TYPES.BETS_DETAILS2,
    COMPONENTS_TYPES.CASINO_DETAILS,
    COMPONENTS_TYPES.POPULAR_SPORTS,
    COMPONENTS_TYPES.SPORTS_BOOK_CUSTOM_FILTER_EVENTS
  ],
  [WRAPPER_TYPES.PREDICTIONS_LOAD_WRAPPER]: [
    COMPONENTS_TYPES.PREDICTIONS_BLOCK,
    COMPONENTS_TYPES.PREDICTIONS_INFO_BLOCK,
    COMPONENTS_TYPES.TOURNAMENT_RESULTS_BRACKET,
    COMPONENTS_TYPES.TOURNAMENT_BRACKET
  ],
  [WRAPPER_TYPES.PROMOTIONS_WRAPPER]: [
    COMPONENTS_TYPES.PROMOTIONS,
    COMPONENTS_TYPES.PROMOTION,
    COMPONENTS_TYPES.RECENTLY_COMPLETED_PROMOTIONS
  ],
  [WRAPPER_TYPES.PROFILE_DATA_LOAD_WRAPPER]: [COMPONENTS_TYPES.PROFILE_DATA],
  [WRAPPER_TYPES.BONUSES_WRAPPER]: [
    COMPONENTS_TYPES.FREE_SPIN_BONUSES_LIST,
    COMPONENTS_TYPES.RISK_FREE_BONUSES_LIST,
    COMPONENTS_TYPES.FUNDS_BONUSES_LIST,
    COMPONENTS_TYPES.BONUS_PROMO_CODE,
    COMPONENTS_TYPES.JACKPOT_BONUSES_LIST
  ],
  [WRAPPER_TYPES.PRE_WAGER_BONUSES_WRAPPER]: [COMPONENTS_TYPES.SW_WITHDRAW],
  [WRAPPER_TYPES.POPULAR_SPORT_EVENTS_WRAPPER]: [
    COMPONENTS_TYPES.SPORTS_BOOK_POPULAR_BETS_EVENTS,
    COMPONENTS_TYPES.SPORTS_BOOK_CARDS_POPULAR_BETS_EVENTS
  ],
  [WRAPPER_TYPES.POPULAR_CYBER_SPORT_EVENTS_WRAPPER]: [
    COMPONENTS_TYPES.CYBER_SPORT_LIVE_CATEGORIES_EVENTS,
    COMPONENTS_TYPES.CYBER_SPORT_POPULAR_BETS_EVENTS
  ]
};

export const REQUIRED_SITE_CONFIG_SETTINGS = {
  languages: ['en'],
  defaultLanguage: 'en'
};
