import { useMemo } from 'react';
import { useI18n } from '@namespace/i18n';
import {
  getDetailedScoresForTable,
  getEventValues,
  stripeScore
} from '../../utils';
import {
  SPORTS_WITH_ALL_PERIODS,
  E_FIGHTTING_SPORTFORMS,
  E_FIGHTING_SPORTFORMS_NAMES,
  TOTAL_SYMBOL_BY_SPORT
} from '../../constants';
import {
  COLUMN_TYPES,
  UFC_WIN_TYPE,
  E_FIGHTING_ROUNDS_MAP
} from '../../../../constants';
import ScoreDetails from '../../Details';
import { useEventField } from '../../../../store';

const EFighting = ({
  sportId,
  eventId,
  byResultType,
  eventResultId,
  total,
  sportformId,
  isShortVersion,
  lineView,
  setEventResultText = () => {}
}) => {
  const { t } = useI18n();
  const participants = useEventField(eventId, 'participants');
  const { totalKey, defaultKey, sportformName } =
    E_FIGHTTING_SPORTFORMS.find(({ sportformIds }) =>
      sportformIds.includes(sportformId)
    ) || {};

  const isUfc = sportformName === E_FIGHTING_SPORTFORMS_NAMES.UFC;

  let actualTotalKey = totalKey;

  if (Array.isArray(totalKey)) {
    const { scopeId = '', scopeData } =
      total?.find(({ scopeData: sd }) =>
        sd.some(({ value }) => Boolean(value))
      ) || {};

    if (scopeId) {
      actualTotalKey = scopeId;
      const { number: winningParticipantNumber } =
        scopeData.find(({ value }) => Boolean(value)) || {};

      const { participantName } =
        participants.find(
          ({ participantNumber }) =>
            participantNumber === winningParticipantNumber
        ) || {};

      const eventResultText = `${participantName} ${t('scoreboard.winBy')} ${t(
        `scoreboard.winType.${UFC_WIN_TYPE[scopeId]}`
      )}, ${t('scoreboard.round')} ${E_FIGHTING_ROUNDS_MAP[eventResultId]}`;

      setEventResultText(eventResultText);
    } else {
      actualTotalKey = '';
    }
  }

  const formattedScoreByPeriod = useMemo(
    () =>
      stripeScore(
        getDetailedScoresForTable(byResultType, defaultKey),
        sportId,
        eventResultId,
        SPORTS_WITH_ALL_PERIODS.includes(sportId)
      ),
    [byResultType, defaultKey, eventResultId, sportId]
  );

  const scoreByPeriod = useMemo(
    () =>
      isShortVersion
        ? [
            isUfc
              ? {}
              : {
                  type: COLUMN_TYPES.DEFAULT,
                  tag: '',
                  scores:
                    formattedScoreByPeriod.find(
                      ({ resultTypeId }) => resultTypeId === eventResultId
                    )?.scores || []
                }
          ]
        : formattedScoreByPeriod,
    [eventResultId, formattedScoreByPeriod, isShortVersion]
  );

  const fullFormattedScore = useMemo(
    () => [
      {
        type:
          isUfc || (!byResultType.length && !total.length)
            ? COLUMN_TYPES.ROUND_ICON
            : COLUMN_TYPES.TOTAL,
        tag: isUfc ? 'R' : t(TOTAL_SYMBOL_BY_SPORT.default),
        scores: getEventValues(total, actualTotalKey)
      },
      ...scoreByPeriod
    ],
    [scoreByPeriod, total, actualTotalKey, isUfc]
  );

  return (
    <ScoreDetails
      scoreData={isUfc && isShortVersion ? [] : fullFormattedScore}
      isShortVersion={isShortVersion}
      lineView={lineView}
    />
  );
};

export default EFighting;
