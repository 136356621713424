import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useI18n } from '@namespace/i18n';
import DatePickerFormWrapper from '../../common/DatePickerFormWrapper';
import getFormError from '../../utils/getFormError';
import useCustomError from '../../hooks/useCustomError';

const DatePickerField = ({
  name,
  label = '',
  placeholder,
  timeRange = {},
  rules = {},
  classNames = {},
  withTooltip,
  disabled = false,
  dateFormat = '',
  dateValueFormat = '',
  customError = '',
  ...rest
}) => {
  const { t, f } = useI18n();
  const DATE_FORMATS_TYPES = f.getFormats();
  const {
    control,
    watch,
    formState: { errors },
    clearErrors,
    setError
  } = useFormContext();
  const dt = watch(name);
  const { errorMessage, customErrorHandler } = useCustomError(customError);

  const {
    from: availableFrom = f.getDateTime().minus({ year: 10 }),
    to: availableTo = f.getDateTime().plus({ year: 10 })
  } = timeRange;

  const date = useMemo(
    () =>
      dt
        ? f
            .getDateTime({
              date: dt,
              format: dateValueFormat || DATE_FORMATS_TYPES.CLIENT_API,
              withTZ: false
            })
            .toJSDate()
        : f.getDateTime().toJSDate(),
    [dt, f, dateValueFormat, DATE_FORMATS_TYPES]
  );

  const onChange = useCallback(
    (newDate) => {
      if (typeof newDate !== 'object') {
        return newDate || '';
      }

      return f
        .getDateTime({ date: newDate })
        .toFormat(dateValueFormat || DATE_FORMATS_TYPES.CLIENT_API);
    },
    [DATE_FORMATS_TYPES, dateValueFormat, f]
  );

  return (
    <DatePickerFormWrapper
      name={name}
      label={t(label)}
      placeholder={t(placeholder)}
      clearErrors={clearErrors}
      date={watch(name) ? date : null}
      format={dateFormat || DATE_FORMATS_TYPES.CLIENT_API}
      onChange={onChange}
      minDate={availableFrom.toJSDate()}
      maxDate={availableTo.toJSDate()}
      control={control}
      withTooltip={withTooltip}
      rules={{ ...rules }}
      error={getFormError(name, errors, t) || t(errorMessage)}
      onClick={customErrorHandler}
      setError={setError}
      minDateMessage={t('datePicker.error.dateBeforeMinimalDate')}
      maxDateMessage={t('datePicker.error.dateAfterMaximalDate')}
      {...classNames}
      {...rest}
      disabled={Boolean(disabled)}
    />
  );
};

export default DatePickerField;
