import { types } from '../types';
import { getUserBonusInfo } from '../../../api/getUserBonusInfo';
import { getShopCategories } from '../../../api/getShopCategories';
import { getShopItems } from '../../../api/getShopItems';

export const shopActions = {
  [types.FETCH_PRODUCT_LIST]: (dispatch) => async (data) => {
    const { price_from: priceFrom, price_to: priceTo } = data;
    const productList = await getShopItems(data);

    dispatch({
      type:
        priceFrom || priceTo
          ? types.SET_FILTERED_PRODUCTS_LIST
          : types.SET_PRODUCT_LIST,
      payload: productList
    });
  },
  [types.FETCH_CATEGORY_LIST]: (dispatch) => async (payload) => {
    const tabs = await getShopCategories(payload);

    dispatch({
      type: types.SET_CATEGORY_LIST,
      payload: tabs
    });
  },
  [types.FETCH_USER_INFO]: (dispatch) => async () => {
    const { bonusWallet = {}, loyalty = {} } = await getUserBonusInfo();

    dispatch({
      type: types.SET_USER_INFO,
      payload: { ...bonusWallet, ...loyalty }
    });
  }
};
