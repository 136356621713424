import { useCallback, useContext, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DeviceContext } from '@namespace/device';
import { useI18n } from '@namespace/i18n';
import { InputSelect, Select } from '@alf/uikit';
import { logAmplitude } from '@namespace/analytics';
import { isEmpty, isFunction } from 'lodash';
import getFormError from '../../utils/getFormError';
import Control from '../Control';
import useDynamicField from '../../hooks/useDynamicField';
import useCustomError from '../../hooks/useCustomError';
import useOnChangeValidate from '../../hooks/useOnChangeValidate';
import SELECT_TYPES from '../../constants/selectTypes';
import useInstantValidation from '../../hooks/useInstantValidation';

// InputSelect with async loaded options uses object as value,
// while all other forms of Select use plain value.
const SelectField = ({
  name,
  label = '',
  labelParams = [],
  placeholder,
  classNames = {},
  disabled = false,
  options = [],
  rules = {},
  isOnChangeValidation = false,
  customSelectHandler,
  onChange = () => {},
  onBlur = () => {},
  onOpen = () => {},
  clearAfterChange = {},
  formProps = {},
  noResetFormData = false,
  customError = '',
  size = 's',
  arrowSize = 's',
  dataRole = '',
  selectType = '',
  onCleanValue,
  hasSearch = false,
  validationTypesMsgs = {},
  enableInstantFieldValidation = false,
  analyticAction,
  ...props
}) => {
  const { isDesktop } = useContext(DeviceContext);
  const { t } = useI18n();
  const {
    containerClassName = '',
    wrapperClassName = '',
    labelClassName = '',
    hintClassName = ''
  } = classNames;
  const methods = useFormContext();
  const {
    control,
    formState: { errors },
    watch,
    setValue
  } = methods;
  const fieldWatched = watch(name);
  useInstantValidation(name, enableInstantFieldValidation);
  const { errorMessage, customErrorHandler } = useCustomError(customError);
  const { dynamicValues = {} } = formProps;
  const selectOptions = useMemo(
    () =>
      options.map((option) => ({
        ...option,
        title: option.title || option.name
      })),
    [options]
  );
  const errorText = getFormError(name, errors, t) || t(errorMessage) || '';
  const customErrorMsg = validationTypesMsgs[errorText];

  const handleSelect = useCallback(
    (data) => {
      if (!data || isEmpty(data)) {
        return null;
      }

      if (isFunction(customSelectHandler)) {
        return customSelectHandler(data);
      }

      let selectedOption;

      if (data.target) {
        // unit testing
        selectedOption = data.target.value;
      } else {
        // actual use
        selectedOption = data;
      }

      const { value, title } = selectedOption;
      // for InputSelect only: return object
      if (value != null && title != null) {
        return selectedOption;
      }

      if (analyticAction) {
        logAmplitude(analyticAction);
      }

      // convert to Number if initial option was of Number type.
      const numValue = Number(value);
      const isNumber = value !== '' && Number.isFinite(numValue);
      const hasNumOption =
        isNumber && selectOptions.find(({ value: v }) => numValue === v);

      return hasNumOption ? numValue : value;
    },
    [analyticAction, selectOptions]
  );

  useDynamicField({ name, dynamicFieldData: dynamicValues[name] });

  const clearFields = useCallback(() => {
    for (const [fieldName, fieldValue] of Object.entries(clearAfterChange))
      setValue(fieldName, fieldValue);
  }, [clearAfterChange, setValue]);

  const handleChange = useCallback(
    (fieldOnChange) => (v) => {
      fieldOnChange(handleSelect(v));
    },
    [handleSelect]
  );

  const handleBlur = useCallback(
    (fieldOnBlur) => (v) => {
      fieldOnBlur(handleSelect(onBlur(v)));
    },
    [handleSelect, onBlur]
  );

  useOnChangeValidate({ name, isOnChangeValidation });

  useEffect(() => {
    onChange(fieldWatched);
    !noResetFormData && clearFields();
  }, [fieldWatched]);

  let Component;

  if (selectType === SELECT_TYPES.WITH_INPUT) {
    Component = InputSelect;
  } else {
    Component = Select;
  }

  return (
    <Control
      onClick={customErrorHandler}
      id={name}
      label={t(label, [...labelParams])}
      error={customErrorMsg || errorText}
      classNames={{
        wrapperClassName: containerClassName,
        labelClassName,
        hintClassName
      }}
      dataRole={dataRole}
    >
      <Controller
        name={name}
        id={name}
        control={control}
        render={({ field: { ref, ...field } }) => {
          const {
            onChange: controllerOnChange,
            onBlur: controllerOnBlur,
            ...controllerFields
          } = field;
          return (
            <Component
              onChange={handleChange(controllerOnChange)}
              onBlur={handleBlur(controllerOnBlur)}
              onOpen={onOpen}
              placeholder={t(placeholder)}
              options={selectOptions}
              disabled={disabled}
              className={wrapperClassName}
              dataRole={dataRole}
              isDesktop={isDesktop}
              size={size}
              arrowSize={arrowSize}
              hasSearch={hasSearch}
              intent={errorText ? 'error' : null}
              emptyText={t('reg.countrySearch.notFound')}
              {...controllerFields}
              {...props}
            />
          );
        }}
        rules={rules}
      />
    </Control>
  );
};

export default SelectField;
