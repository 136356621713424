import { AText } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { get, isNumber } from 'lodash';
import { CURRENCY_CODE_TO_SYMBOL } from '@namespace/helpers';
import { useSiteSettings } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import CurrencyConvertation from './components/CurrencyConvertation';
import useGetNumberOfDecimalPlaces from '../../hooks/useGetNumberOfDecimalPlaces';
import styles from './index.module.css';

const convertFormattedAmountToNumber = (formattedAmount) => {
  const numberWithoutFormat = formattedAmount
    .replace(/\s/g, '')
    .replace(/[.,](?=[^.,]*[.,])/g, '')
    .replace(',', '.');
  return Number(numberWithoutFormat);
};

const Amount = ({
  amount,
  currentAmountColor = 'po_txt_color_1',
  currentAmountSize = 's',
  currentAmountBreed = 'R',
  currentCurrencyBreed = 'R',
  convertedAmountColor = 'po_txt_color_3',
  convertedAmountSize = currentAmountSize,
  convertedAmountBreed = 'R',
  convertedCurrencyBreed = 'R',
  additionalFormatting = false,
  displayOnlyConvertedAmount = false,
  disabledSeparator = false,
  noAdditionalStyling = false,
  walletType = 1,
  iconStyle = '',
  noFormattedAmount,
  currencyToSymbol = false,
  dataRole,
  currentAmountProps = {},
  currentCurrencyProps = {},
  ...rest
}) => {
  const { otherSettings } = useSiteSettings();
  const convertationEnabled = get(
    otherSettings,
    'currencyConvertationInfo.enabled',
    false
  );
  const numberOfDecimalPlaces = useGetNumberOfDecimalPlaces();
  const { c } = useI18n();

  if (
    amount === '—' ||
    isNumber(amount) ||
    (noAdditionalStyling && !convertationEnabled)
  ) {
    return amount;
  }

  const currentCurrency = amount.match(' [A-Z]{3}');
  const currentAmount = amount.replace(currentCurrency, '');

  const currencyConvertationProps = {
    convertedAmountColor,
    convertedAmountSize,
    convertedAmountBreed,
    convertedCurrencyBreed,
    additionalFormatting,
    noFormattedAmount,
    currentAmount,
    currentCurrency
  };

  const formatedCurrentCurrency = currencyToSymbol
    ? CURRENCY_CODE_TO_SYMBOL[
        (currentCurrency?.[0] || '')
          .split(' ')
          .filter((i) => i)
          .join('')
      ]
    : currentCurrency;

  return (
    <AText className={styles.wrapper} data-role={dataRole} {...rest}>
      {!displayOnlyConvertedAmount && (
        <AText color={currentAmountColor} size={currentAmountSize}>
          {!walletType && (
            <Icon
              className={iconStyle}
              size="xs"
              name="icons/user_menu/bonuses"
            />
          )}
          <AText
            {...(currentAmountBreed ? { breed: currentAmountBreed } : {})}
            size={currentAmountSize}
            {...currentAmountProps}
          >
            {additionalFormatting
              ? c.formatCurrency(
                  convertFormattedAmountToNumber(currentAmount),
                  undefined,
                  undefined,
                  numberOfDecimalPlaces,
                  numberOfDecimalPlaces
                )
              : convertFormattedAmountToNumber(currentAmount).toFixed(
                  numberOfDecimalPlaces
                )}
          </AText>
          {currentCurrency && (
            <AText
              {...(currentCurrencyBreed ? { breed: currentCurrencyBreed } : {})}
              size={currentAmountSize}
              {...currentCurrencyProps}
            >
              {` ${formatedCurrentCurrency} ${
                disabledSeparator || !convertationEnabled ? '' : `| `
              }`}
            </AText>
          )}
        </AText>
      )}
      {convertationEnabled && (
        <CurrencyConvertation {...currencyConvertationProps} />
      )}
    </AText>
  );
};

export default Amount;
