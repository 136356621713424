import { useI18n } from '@namespace/i18n';
import { AText, Box } from '@alf/uikit';
import TimerCounter from '../../../../../../../TimerCounter';
import { useEventTimerCounters } from '../../../../../../../../hooks/useTimerCounter';
import useLiveEventState from '../../../../../../../../hooks/useLiveEventState';

const LiveEventState = ({ event }) => {
  const { t } = useI18n();
  const { emptyStateText, labelText, eventTime } = useLiveEventState(
    event.eventId
  );

  const {
    eventTimer,
    eventStatusType,
    eventResultName,
    canceled = false,
    finished = false,
    inprogress = false
  } = event;

  const timerCounters = useEventTimerCounters(eventTimer);

  return (
    <Box direction="column" align="center">
      <Box margin="xxs 0">
        {eventTimer ? (
          <AText size="xs" color="line_txt_3">
            <TimerCounter {...timerCounters} />
          </AText>
        ) : (
          (Boolean(emptyStateText) || Boolean(eventStatusType)) && (
            <AText size="xs" color="line_txt_3">
              {emptyStateText !== ''
                ? emptyStateText
                : t(`sportsBook.eventState.${eventStatusType}`)}
            </AText>
          )
        )}
      </Box>
      <Box margin="xxs 0">
        {finished || inprogress || canceled ? (
          <AText size="xs" color="line_txt_4">
            {eventTime}
          </AText>
        ) : (
          <AText size="xs" color="line_txt_4">
            {labelText || eventResultName}
          </AText>
        )}
      </Box>
    </Box>
  );
};
export default LiveEventState;
