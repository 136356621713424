import { post, getAsJson } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

export const getConfig = () =>
  post('/eaccounting/api/partner_config').then(
    ({ response: { paymentSystems } }) =>
      camelizeKeys({
        paymentSystems
      })
  );

export const getPayments = (userId) =>
  getAsJson(
    `/accounting/api/v1/users/${userId}/payments`
  ).then(({ response }) => camelizeKeys(response));

// checks either pankeeper enabled for current user or not
export const getBCConfig = () =>
  post({
    url: '/service/ext_pay_sys/v1/bank_card/if_user_switched',
    body: {},
    mode: 'cors'
  }).then(({ response }) => camelizeKeys(response));
