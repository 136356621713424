import { useContext, useEffect } from 'react';
import { OnboardingContext } from '../../store/context';

export const useLoadMocks = () => {
  const [, { SET_LOADED_SNAPSHOTS }] = useContext(OnboardingContext);

  useEffect(() => {
    import(
      '../../mocks/storesSnapshots/index.js'
    ).then(({ MOCKED_STORES_SNAPSHOTS }) =>
      SET_LOADED_SNAPSHOTS(MOCKED_STORES_SNAPSHOTS)
    );
  }, [SET_LOADED_SNAPSHOTS]);
};
