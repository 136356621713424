import { useContext, useMemo } from 'react';
import { LocalizedNavLink, useI18n, useLocation } from '@namespace/i18n';
import { DeviceContext } from '@namespace/device';
import { Box, AText, Menu } from '@alf/uikit';
import { useConfig } from '@namespace/cms';
import MobileMenu from '../MobileMenu';
import formatItems from '../utils/formatItems';
import styles from './index.module.css';

const ContentPageMenu = ({ id }) => {
  const location = useLocation();
  const { t } = useI18n();
  const { isDesktop } = useContext(DeviceContext);
  const { config } = useConfig(id);
  const { items = [], isExpandedByDefault = true } = config;

  const menuItems = useMemo(
    () => formatItems(items, location, t, 'i', isExpandedByDefault),
    [isExpandedByDefault, items, location, t]
  );

  if (!isDesktop) {
    return <MobileMenu items={menuItems} />;
  }

  return (
    <Box direction="column" className={styles.wrapper} padding="0 m m s">
      <Box padding="m m m s">
        <AText breed="R" size="xl" color="sm_txt_color_5">
          {t('contentPage.menu.services')}
        </AText>
      </Box>
      <Menu items={menuItems} NavLink={LocalizedNavLink} isTop="true" />
    </Box>
  );
};

export default ContentPageMenu;
