import { useContext, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { USER_ROLES, UserContext } from '@namespace/user';
import { useI18n } from '@namespace/i18n';
import { useGeneralRoutes } from '@namespace/cms';
import { REDIRECT_PARAM } from '@namespace/common';
import { RECOMMENDED_CATEGORY } from '../../constants';
import { useCasinoSettings } from '../useCasinoSettings';
import { useGetRecommendedGamesIds } from '../../context/selectors';

export const useGetCasinoRecommendedCategory = ({
  showRecommendedCategory,
  lobbyPageUrl,
  logo,
  logoActive,
  casinoType,
  feedName
}) => {
  const { t } = useI18n();
  const [{ role }] = useContext(UserContext);
  const { login } = useGeneralRoutes();
  const title = t(RECOMMENDED_CATEGORY.title);
  const { suggesterServiceEntityName } = useCasinoSettings(casinoType);
  const recommendedGamesIds = useGetRecommendedGamesIds(
    suggesterServiceEntityName,
    feedName
  );
  const isNoRecommendedGames = isEmpty(recommendedGamesIds);

  return useMemo(() => {
    if (isNoRecommendedGames) return [];

    if (showRecommendedCategory) {
      return role === USER_ROLES.USER
        ? [
            {
              id: RECOMMENDED_CATEGORY.id,
              title,
              logo,
              logoActive
            }
          ]
        : [
            {
              id: RECOMMENDED_CATEGORY.id,
              title,
              link: `${login}?${REDIRECT_PARAM}${lobbyPageUrl}/${RECOMMENDED_CATEGORY.id}`,
              logo,
              logoActive
            }
          ];
    }
    return [];
  }, [
    showRecommendedCategory,
    role,
    title,
    logo,
    logoActive,
    login,
    lobbyPageUrl,
    isNoRecommendedGames
  ]);
};
