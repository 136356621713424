import {
  formatAsUriParameters,
  get,
  getAsJson,
  post,
  postAsUriParameters
} from '@namespace/helpers';
import { isString } from 'lodash';
import { camelizeKeys } from 'humps';
import { FILTER_ALL_VALUE } from '@namespace/common';

const WITHDRAW_PARAMS_MAP = {
  walletHash: 'wallet_hash',
  cashdesk: 'cashdesk',
  cashdeskId: 'cash_desk_id',
  password: 'password',
  amount: 'amount',
  type: 'fp_type_id',
  tokenId: 'token_id',
  firstName: 'first_name',
  lastName: 'last_name',
  middleName: 'middle_name',
  inn: 'inn',
  cardNumber: 'card_num',
  card_number: 'card_number',
  bankName: 'bank',
  cardHash: 'card_hash',
  cityId: 'city_id',
  address: 'address',
  orderDate: 'order_date',
  orderTime: 'order_time',
  phoneNumber: 'phone_number',
  comments: 'comments',
  region: 'region',
  cardMask: 'card_mask',
  paySystem: 'pay_system',
  ipayCardId: 'ipay_byn_card_id',
  ipayCardMask: 'ipay_byn_card_mask',
  lang: 'lang',
  paymentInstrumentId: 'payment_instrument_id',
  iban: 'iban',
  serviceId: 'service_id',
  walletAccountId: 'wallet_account_id',
  walletId: 'wallet_id',
  currency: 'currency',
  bitcoinWithdrawalAddress: 'bitcoin_withdrawal_address',
  cryptoAddress: 'cryptoAddress',
  convertToCryptoCurrency: 'convertToCryptoCurrency',
  bank_name: 'bname',
  bankAccountName: 'bnameacc',
  bankCityName: 'bcity',
  bankZipCode: 'bzip',
  bankCountryName: 'bcountry',
  bankPhone: 'bphone',
  bankAccountNumber: 'baccount',
  bankAddress: 'baddress',
  bankAccount: 'bank_account',
  phone: 'phone',
  ifsc: 'ifsc',
  vpa: 'vpa',
  email: 'email',
  fullName: 'full_name'
};

const DEPOSIT_PARAMS_MAP = {
  walletHash: 'wallet_hash',
  walletAccountId: 'wallet_account_id',
  cashdesk: 'cashdesk',
  password: 'password',
  amount: 'amount',
  serialNumber: 'serial',
  secretCode: 'code',
  captcha: 'cap',
  lang: 'lang',
  userId: 'user_id',
  WMI: 'WMI_PTENABLED',
  okUrl: 'ok_url',
  badUrl: 'bad_url',
  code: 'code',
  cardHash: 'card_hash',
  verificationCode: 'verificationCode',
  phoneNumber: 'phone_number',
  email: 'email',
  paymentMethods: 'payment_methods',
  walletId: 'wallet_id',
  paymentInstrumentId: 'payment_instrument_id',
  currency: 'currency',
  address: 'address',
  orderDate: 'order_date',
  orderTime: 'order_time',
  comments: 'comments',
  region: 'region',
  couponCode: 'coupon_code',
  netellergo: 'netellergo',
  cardOwner: 'link_card_by',
  serviceId: 'service_id',
  screenWidth: 'screen_width',
  screenHeight: 'screen_height',
  screenColorDepth: 'screen_color_depth',
  language: 'language',
  javaEnabled: 'java_enabled',
  userAgent: 'user_agent',
  timeZone: 'time_zone',
  firstName: 'first_name',
  lastName: 'last_name',
  phone: 'phone',
  paymentWalletId: 'payment_wallet_id',
  upiAccountNumber: 'upi_account_number'
};

const CREATE_WALLET_PARAMS_MAP = {
  userId: 'user_id',
  WMI: 'WMI_PTENABLED',
  walletId: 'wallet_id',
  walletAccountId: 'wallet_account_id',
  cashdesk: 'cashdesk',
  currency: 'currency',
  amount: 'amount',
  paymentInstrumentId: 'payment_instrument_id'
};

/**
 *
 * @param {Object} params
 * @param {Object} defaultValues
 * @param {Object} paramsMap
 * @returns {Object}
 */
const fillData = (params, defaultValues, paramsMap) =>
  Object.keys({ ...defaultValues, ...params }).reduce((acc, key) => {
    if (!paramsMap[key]) {
      return { ...acc };
    }

    return {
      ...acc,
      [paramsMap[key]]:
        params[key] !== undefined ? params[key] : defaultValues[key]
    };
  }, {});

const getHistory = ({
  amount,
  startDate,
  endDate,
  wallet,
  page,
  state,
  paymentServicesFilter = [],
  move
}) => {
  let body = {
    move,
    page,
    amount: amount === FILTER_ALL_VALUE ? '' : amount,
    status: state === FILTER_ALL_VALUE ? '' : state,
    dt_start: startDate,
    dt_end: endDate,
    wallet_hash: ''
  };

  if (wallet && wallet !== FILTER_ALL_VALUE) {
    body = {
      ...body,
      service_id: wallet
    };
  } else {
    body = {
      ...body,
      service_id: paymentServicesFilter.map(({ value }) => value)
    };
  }

  return postAsUriParameters('/accounting/api/history', body, true).then(
    ({ response: balanceHistory, page: currentPage, total: totalPages }) =>
      camelizeKeys({
        data: balanceHistory,
        currentPage,
        totalPages
      })
  );
};

export const getDepositHistory = (data) =>
  getHistory({
    ...data,
    move: 0
  });

export const getWithdrawHistory = (data) =>
  getHistory({
    ...data,
    move: 1
  });

export const withdraw = (params) =>
  postAsUriParameters(
    '/accounting/api/withdraw',
    fillData(params, {}, WITHDRAW_PARAMS_MAP)
  ).then((data) => {
    const camelized = camelizeKeys(data);
    // We shouldn't camelize response
    // because we should send it to the payment system as we got it from the backend
    camelized.response = isString(data.response)
      ? data.response
      : { ...data.response };

    return {
      ...camelized,
      redirectMethod:
        camelized.redirectMethodNew ||
        camelized.redirectMethod ||
        camelized.redirect_method
    };
  });

export const deposit = (params) => {
  const defaultValues = {
    amount: 1, // ? why default like this one
    WMI: '',
    okUrl: '',
    badUrl: '',
    secretCode: '',
    serialNumber: '',
    captcha: ''
  };

  return postAsUriParameters(
    '/accounting/api/deposit',
    fillData(params, defaultValues, DEPOSIT_PARAMS_MAP)
  ).then((data) => {
    const camelized = camelizeKeys(data);
    // We shouldn't camelize response
    // because we should send it to the payment system as we got it from the backend
    camelized.response = isString(data.response)
      ? data.response
      : { ...data.response };

    return {
      ...camelized,
      redirectMethod:
        camelized.redirectMethodNew ||
        camelized.redirectMethod ||
        camelized.redirect_method
    };
  });
};

export const checkMobMoneyCode = (params) =>
  postAsUriParameters(
    '/accounting/api/mob_mon_confirm',
    fillData(params, {}, { code: 'code' })
  ).then((data) => camelizeKeys(data));

export const addWallet = (params) => {
  const defaultValues = {
    WMI: '1'
    // walletId: '',
    // walletAccountId: '1',
    // currency: '',
    // amount: ''
  };

  return postAsUriParameters(
    '/accounting/api/add_wallet',
    fillData(params, defaultValues, CREATE_WALLET_PARAMS_MAP)
  ).then(camelizeKeys);
};

export const getWallets = () =>
  post('/accounting/api/get_user_wallets').then(({ response }) =>
    camelizeKeys(response)
  );

export const getIKAvailableMethods = () =>
  get(
    '/accounting/api/v1/payments-info/interkassa/available_wallets_and_banks'
  ).then(({ response }) => response);

export const getIKUser = (userId, serviceId, paymentType) =>
  get(
    `/accounting/api/v1/users/${userId}/payments/${serviceId}/info/${paymentType}`
  ).then(({ response }) => response);

export const getUsedSidList = (userId) =>
  getAsJson(`/accounting/api/v1/users/${userId}/last-used-services`).then(
    ({ response }) => response
  );

export const getAvailableCryptoCurrencies = () =>
  get('/accounting/api/coins-paid/get_available_crypto_currencies').then(
    ({ response }) => response
  );

export const getCryptoCurrencyRate = (queryParams) =>
  get(
    `/accounting/api/coins-paid/get_currency_rate?${formatAsUriParameters(
      queryParams
    )}`
  ).then(({ response }) => response);

export const getDepositCryptoAddress = (queryParams) =>
  get(
    `/accounting/api/coins-paid/get_deposit_crypto_address?${formatAsUriParameters(
      queryParams
    )}`
  ).then(({ response }) => response);

export const getWithdrawalCryptoAddressHistory = (queryParams) =>
  get(
    `/accounting/api/coins-paid/get_withdrawal_crypto_address_history?${formatAsUriParameters(
      queryParams
    )}`
  ).then(({ response }) => response);

export const getWmiPt = ({ currency, paymentInstrumentId }) =>
  postAsUriParameters('/accounting/api/list_wmi_ptenabled', {
    currency,
    payment_instrument_id: paymentInstrumentId
  })
    .then(camelizeKeys)
    .then(({ response }) => response);

// tmp double it here and in payments package before deposit will be moved to the payments
export const getAirCashPhones = () =>
  postAsUriParameters('/accounting/api/get_aircash_user_phones').then(
    ({ response }) => response
  );
