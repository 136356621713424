import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { useGetImage } from '@namespace/themes';
import styles from './index.module.css';

const CustomChatButton = ({ toggleChat, image, imageDark, title }) => {
  const { t } = useI18n();
  const getImg = useGetImage();

  return (
    <Box align="center" onClick={toggleChat} className={styles.bottomPopupItem}>
      <img
        src={getImg(image, imageDark)}
        className={styles.bottomPopupIcon}
        alt=""
      />
      <AText breed="R" size="m" color="po_menutxt_color_1">
        {t(title)}
      </AText>
    </Box>
  );
};

export default CustomChatButton;
