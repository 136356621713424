import { useContext, useEffect } from 'react';
import { DeviceContext } from '@namespace/device';
import { window, document } from '@namespace/helpers';
import { OnboardingContext } from '../../store/context';
import { Loader } from '../Loader';
import { TourRunner } from '../TourRunner';
import { useActionLinks, useAutomaticTourLaunch } from '../../hooks';
import styles from './index.module.css';

/**
 * @param {TourManagerProps} p
 */
export const TourManager = ({ tours, preferredCurrency, padding, mocks }) => {
  const { pageType } = useContext(DeviceContext);
  const [
    { isRunning, isSnapshotsLoaded },
    { RUN, SET_ONBOARDING_CONFIG }
  ] = useContext(OnboardingContext);

  useEffect(() => {
    SET_ONBOARDING_CONFIG({
      config: { tours, padding, mocks, preferredCurrency },
      pageType
    });
  }, [
    SET_ONBOARDING_CONFIG,
    mocks,
    padding,
    pageType,
    tours,
    preferredCurrency
  ]);

  useAutomaticTourLaunch();
  useActionLinks();

  useEffect(() => {
    if (isRunning) {
      document.body.classList.add(styles.onboardingOpen);
    } else {
      // todo this should be done on useEffect exit to prevent memory leaks
      document.body.classList.remove(styles.onboardingOpen);
    }
    // todo add pathname, but run only on tour start
  }, [isRunning]);

  useEffect(() => {
    let onPopState;
    let promise;

    if (isRunning) {
      promise = new Promise((resolve) => {
        onPopState = () => {
          RUN(false);
        };
        // setTimeout for cases when startOnboarding callback bind on <a> tag
        setTimeout(() => {
          window.addEventListener('popstate', onPopState);
          resolve();
        }, 200);
      });
    }

    return () => {
      Promise.resolve(promise).then(() =>
        window.removeEventListener('popstate', onPopState)
      );
    };
  }, [RUN, isRunning]);

  if (!isRunning) {
    return null;
  }

  if (isRunning && !isSnapshotsLoaded) {
    return <Loader isOverlay={true} />;
  }

  return (
    <div className={styles.root}>
      <TourRunner />
    </div>
  );
};
