import { useContext } from 'react';
import { useI18n } from '@namespace/i18n';
import { useCurrentStep, useEndTourAction } from '../../../hooks';
import { OnboardingContext } from '../../../store/context';
import { BUTTON_TYPE } from '../constants';

export const useButtonsData = () => {
  const [{ stepIndex, steps }, { NEXT, PREV }] = useContext(OnboardingContext);
  const { leftButton = {}, rightButton = {} } = useCurrentStep();
  const endTourAction = useEndTourAction();
  const endTourActionNoRedirect = useEndTourAction({ shouldRedirect: false });
  const { t } = useI18n();

  let prevButtonAction;
  let prevButtonTitle;

  switch (leftButton.type) {
    case BUTTON_TYPE.LATER:
      prevButtonAction = leftButton.noRedirect
        ? endTourActionNoRedirect
        : endTourAction;
      prevButtonTitle = t('onboarding.tooltip.button.later');
      break;
    case BUTTON_TYPE.NONE:
      break;
    default:
      if (stepIndex > 0) {
        prevButtonAction = PREV;
        prevButtonTitle = t('onboarding.tooltip.button.prev');
      }
  }

  let nextButtonAction;
  let nextButtonTitle;

  switch (rightButton.type) {
    case BUTTON_TYPE.LATER:
      nextButtonAction = rightButton.noRedirect
        ? endTourActionNoRedirect
        : endTourAction;
      nextButtonTitle = t('onboarding.tooltip.button.later');
      break;
    case BUTTON_TYPE.VIEW:
      nextButtonAction = NEXT;
      nextButtonTitle = t('onboarding.tooltip.button.view');
      break;
    case BUTTON_TYPE.NONE:
      break;
    default:
      if (stepIndex < steps.length - 1) {
        nextButtonAction = NEXT;
        nextButtonTitle = t('onboarding.tooltip.button.next');
      } else {
        nextButtonAction = endTourAction;
        nextButtonTitle = t('onboarding.tooltip.button.finish');
      }
  }

  return {
    prevButtonAction,
    prevButtonTitle,
    nextButtonAction,
    nextButtonTitle
  };
};
