import { memo, useCallback, useContext, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { DeviceContext } from '@namespace/device';
import { getRootHostName } from '@namespace/helpers';
import { LOG_ACTIONS, logAction } from '@namespace/analytics';
import { SiteSettingsContext, useConfig } from '@namespace/cms';
import { Icon } from '@namespace/icons2';
import { LocalizedNavLink, useI18n } from '@namespace/i18n';
import { OnboardingContext } from '@namespace/onboarding';
import { AText, Box } from '@alf/uikit';
import getGoToOldUrl from '../../../utils/getGoToOldUrl';
import getOldVersionUrl from '../../../utils/getOldVersionUrl';
import { useOldVersion } from '../../Header/useOldVersion';
import { GO_TO_OLD, OLD_VERSION } from '../constants';
import styles from './index.module.css';

const Slider = ({ activeTab, changeActiveTab, id, segmentedItems, isOpen }) => {
  const myRef = useRef(null);
  const { t } = useI18n();
  const saveCookie = useOldVersion();
  const { config = {} } = useConfig(id);
  const { mainMenuItems = {} } = config;
  const menuItems = segmentedItems || mainMenuItems;
  const { isDesktop } = useContext(DeviceContext);
  const [{ siteConfig, menuTags = {} }] = useContext(SiteSettingsContext);
  const [{ isRunning: isOnboardingRunning }] = useContext(OnboardingContext);
  const { availableMirrors = [] } = siteConfig;
  const hostname = getRootHostName();

  const onChangeActiveTab = useCallback(
    (item, index) => () => {
      const { link, title, isLink } = menuItems[item] || {};
      logAction(LOG_ACTIONS.BURGER_MENU_CLICK, {
        BurgerItemName: title,
        indexItem: index + 1,
        link
      });
      changeActiveTab(item);
      if (item === GO_TO_OLD || item === OLD_VERSION) {
        saveCookie();
      }
      if (isLink) {
        myRef.current.scrollTo(0, 0);
      }
    },
    [myRef, saveCookie]
  );

  // a hack for onboarding
  useEffect(() => {
    if (isOnboardingRunning) {
      myRef.current.scrollTo(0, 0);
    }
  }, [isOnboardingRunning, isOpen]);

  return (
    Object.keys(menuItems).length > 0 && (
      <Box ref={myRef} className={styles.container}>
        {Object.keys(menuItems)
          .filter((tab) =>
            availableMirrors.length > 0 && tab === GO_TO_OLD
              ? availableMirrors.includes(hostname)
              : tab
          )
          .map((tab, index) => {
            const {
              link,
              isLink,
              tag,
              logoUrl,
              title,
              forceActive
            } = menuItems[tab];
            const titleConfig = title ? t(title) : '';
            const tagColor = menuTags[tag]?.tagBackground;
            const isActive = forceActive || tab === activeTab;

            let currentLink = link;

            if (tab === GO_TO_OLD) {
              currentLink = getGoToOldUrl(isDesktop);
            } else if (tab === OLD_VERSION) {
              currentLink = getOldVersionUrl();
            }
            return isLink ? (
              <LocalizedNavLink
                data-role={`burger-item-${tab}`}
                className={styles.tabLink}
                key={tab}
                to={currentLink}
                onClick={onChangeActiveTab(tab, index)}
                rel={`${
                  tab === GO_TO_OLD || OLD_VERSION ? 'noindex, nofollow' : null
                }`}
              >
                <Box
                  align="center"
                  justify="around"
                  direction="column"
                  padding="xs"
                  className={`${styles.tab} ${
                    tab === GO_TO_OLD ? styles.oldVersionTab : ''
                  }`}
                >
                  {tag && (
                    <Box
                      className={styles.burgerTag}
                      padding="0 xs"
                      style={{
                        backgroundColor: tagColor
                      }}
                    >
                      <AText breed="BU" size="2xs" color="txt_color_4">
                        {t(`burger.slider.${tag}`)}
                      </AText>
                    </Box>
                  )}
                  {logoUrl ? (
                    <Box className={styles.imgWrapper}>
                      <img
                        className={`${
                          titleConfig
                            ? styles.imgWrapperTitle
                            : styles.imgWrapperNoTitle
                        }`}
                        src={logoUrl}
                        alt="logo burger"
                      />
                    </Box>
                  ) : (
                    <Icon
                      className={styles.icon}
                      name={`icons/nav_menu/${tab}`}
                      color={`header_txt_${isActive ? '2' : '1'}`}
                    />
                  )}
                  {titleConfig && (
                    <AText
                      className={`${styles.tabText} ${
                        tab === GO_TO_OLD ? styles.oldVersionTabText : ''
                      }`}
                      align="center"
                      breed="BU"
                      size="s"
                      color={`header_txt_${
                        tab === GO_TO_OLD || isActive ? '2' : '1'
                      }`}
                    >
                      {titleConfig}
                    </AText>
                  )}
                </Box>
              </LocalizedNavLink>
            ) : (
              <Box
                data-role={`burger-item-${tab}`}
                align="center"
                justify="around"
                direction="column"
                padding="xs"
                key={tab}
                className={clsx(styles.tab, isActive && styles.active)}
                onClick={onChangeActiveTab(tab, index)}
              >
                {tag && (
                  <Box
                    className={styles.burgerTag}
                    padding="0 xs"
                    style={{
                      backgroundColor: tagColor
                    }}
                  >
                    <AText breed="BU" size="2xs">
                      {t(`burger.slider.${tag}`)}
                    </AText>
                  </Box>
                )}
                {logoUrl ? (
                  <Box className={styles.imgWrapper}>
                    <img
                      className={`${
                        titleConfig
                          ? styles.imgWrapperTitle
                          : styles.imgWrapperNoTitle
                      }`}
                      src={logoUrl}
                      alt="logo burger"
                    />
                  </Box>
                ) : (
                  <Icon
                    className={styles.icon}
                    name={`icons/nav_menu/${tab}`}
                    color={`header_txt_${isActive ? '2' : '1'}`}
                  />
                )}
                {titleConfig && (
                  <AText
                    className={`${styles.tabText} ${
                      tab === GO_TO_OLD ? styles.oldVersionTabText : ''
                    }`}
                    color={`header_txt_${isActive ? '2' : '1'}`}
                    align="center"
                    breed="BU"
                    size="s"
                  >
                    {titleConfig}
                  </AText>
                )}
              </Box>
            );
          })}
      </Box>
    )
  );
};

export default memo(Slider);
