import clsx from 'clsx';
import styles from './index.module.css';

const SkeletItem = ({ className }) => (
  <div className={clsx(styles.skelet, styles.skeletBlock, className)} />
);

const Skelet = () => {
  return (
    <>
      <SkeletItem />
      <SkeletItem />
      <SkeletItem />
      <SkeletItem />
      <SkeletItem />
      <SkeletItem />
      <SkeletItem className={styles.onlyMobile} />
      <SkeletItem className={styles.onlyMobile} />
    </>
  );
};

export default Skelet;
