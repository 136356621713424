import { useReducer, useMemo } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import { useGlobalMockedStore } from '@namespace/onboarding';
import { types } from '../types';
import actionCreators from '../actions';
import { reducers, initialState } from '../reducer';
import { BetSlipContext } from '../context';

export const BetSlipProvider = ({ customReducers, children }) => {
  const memoReducers = useMemo(
    () => combineReducers(reducers, customReducers),
    [customReducers]
  );

  const [state, dispatch] = useReducer(memoReducers, initialState);

  const actions = useActions(types, dispatch, actionCreators);

  // This is effect that's change oddFormats like malay, decimal and others.
  // This functional worked only in asia package but can be on in all partners.
  // Now in socket betslip realisation this method is not implemented.
  // So if we need on this functional again, we will need backend support.

  // useEffect(() => {
  //   // TODO: show loader only for malay odds
  //   // betSlipDispatch(setLoaderAction(true));
  //   if (oddsType && oddsType !== prevOddsType) {
  //     SET_COEF_TYPE({
  //       serviceIds: [0, 1],
  //       oddsType
  //     })
  //       .then(() => SET_LOADER({ isLoader: false }))
  //       .catch((error) => {
  //         if (prevOddsType !== null) {
  //           SET_ODDS_TYPE({ oddsType: prevOddsType });
  //         }
  //         console.error(error);
  //       });
  //   }
  // }, [oddsType, prevOddsType]);

  return (
    <BetSlipContext.Provider
      value={useGlobalMockedStore('betslip', state, actions)}
    >
      {children}
    </BetSlipContext.Provider>
  );
};
