import {
  getLocalStorageValue,
  getSessionStorageValue,
  removeLocalStorageItem,
  removeSessionStorageItem,
  setLocalStorageValue,
  setSessionStorageValue,
  SESSION_STORAGE_KEYS,
  LOCAL_STORAGE_KEYS
} from '@namespace/helpers';

export const setStoredTranslations = (lang, translations) =>
  setLocalStorageValue(
    `${LOCAL_STORAGE_KEYS.TRANSLATION}_${lang}`,
    translations
  );

export const setStoredTranslationsKeysVisible = () => {
  try {
    setSessionStorageValue(SESSION_STORAGE_KEYS.TRANSLATION_KEYS_SHOW, 'true');
  } catch {
    console.error('Error show translation keys');
  }
};

export const getStoredTranslations = (lang) =>
  getLocalStorageValue(`${LOCAL_STORAGE_KEYS.TRANSLATION}_${lang}`);

export const getStoredTranslationsKeysVisible = () =>
  getSessionStorageValue(SESSION_STORAGE_KEYS.TRANSLATION_KEYS_SHOW);

export const removeStoredTranslationsKeysVisible = () =>
  removeSessionStorageItem(SESSION_STORAGE_KEYS.TRANSLATION_KEYS_SHOW);

export const removeStoredTranslation = (lang) =>
  removeLocalStorageItem(`${LOCAL_STORAGE_KEYS.TRANSLATION}_${lang}`);
