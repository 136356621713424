import { usePromotionsStore } from './store';
import { getPromotions, getTournaments } from '../api';
import { parseTournaments } from './normalize';

export const actionsCreator = ({ setState }) => ({
  FETCH_PROMOTIONS: async () => {
    const promotions = await getPromotions();

    setState({
      promotions,
      isPromosLoaded: true
    });
  },

  SET_ACTIVE_TAG: (activeTag) => {
    setState({ activeTag });
  },

  SET_TIMER_IS_COMPLETED: (isCompleted) => {
    setState({ isCompleted });
  },

  FETCH_TOURNAMENTS_SERVICE_INFO: async (ids) => {
    let data;
    try {
      setState({ isTournamentsLoading: true });
      ({ data } = await getTournaments(ids));
    } catch (e) {
      console.error(e);
    } finally {
      setState((state) => ({
        tournaments: {
          ...state.tournaments,
          ...parseTournaments(data?.tournaments)
        },
        isTournamentsLoaded: true,
        isTournamentsLoading: false
      }));
    }
  },

  RESET_TOURNAMENTS_SERVICE_INFO: () => {
    setState({
      tournaments: {},
      isTournamentsLoaded: false,
      isTournamentsLoading: false
    });
  }
});

export const promotionsActions = actionsCreator(usePromotionsStore);
