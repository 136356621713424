import { Button } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { useI18n } from '@namespace/i18n';

import styles from './index.module.css';

const ChatButton = ({ toggleChat, className = '' }) => {
  const { t } = useI18n();

  return (
    <Button
      actionType="color1"
      size="xl"
      intent="primary"
      iconLeft={
        <Icon
          name="icons/user_menu/support"
          size="m"
          color="btn_primary_txt_color_1"
        />
      }
      className={`${className} ${styles.button}`}
      onClick={toggleChat}
    >
      {t('support24')}
    </Button>
  );
};

export default ChatButton;
