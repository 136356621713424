import { useContext, useEffect } from 'react';
import { UserContext } from '@namespace/user';
import { ConfigContext } from '../../store/config';

const useConfigurePaymentSystems = () => {
  const { paymentSystems } = useContext(ConfigContext);
  const [user, { CONFIGURE_WALLETS }] = useContext(UserContext);
  const { id } = user;

  useEffect(() => {
    if (paymentSystems.length && id) {
      CONFIGURE_WALLETS(paymentSystems);
    }
  }, [id, paymentSystems]);
};

export default useConfigurePaymentSystems;
