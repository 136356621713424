import { useCallback, useContext } from 'react';
import { InfoMessage, Box } from '@alf/uikit';
import { useNotifications } from '@namespace/notifications';
import { UserContext } from '@namespace/user';
import {
  FORM_COMPONENTS_TYPES,
  FORM_VALIDATION_TYPES,
  FormBuilder
} from '@namespace/forms';
import { BonusHunterWrapper, SelfExcludedWrapper } from '@namespace/common';
import { useI18n } from '@namespace/i18n';
import { setUserPromoCode } from '../../api/setUserPromoCode';
import { PersonalOfficeWrapper } from '../../../../account';
import styles from './index.module.css';

const PromoCode = () => {
  const { t } = useI18n();
  const [user] = useContext(UserContext);
  const { id } = user;
  const { errorNotification, successNotification } = useNotifications();

  const defaultValues = {
    promoCode: ''
  };

  const sendPromoCode = useCallback(
    async ({ promoCode }) => {
      if (promoCode && promoCode.trim().length > 0) {
        const response = await setUserPromoCode({
          promocode: promoCode,
          user_id: id
        });
        response !== 0
          ? errorNotification(t('accountingMessages.bunuses.promoCode.error'))
          : successNotification(
              t('accountingMessages.bunuses.promoCode.success')
            );
      } else {
        errorNotification(t('accountingMessages.accounting_error_fatal_1502'));
      }
    },
    [setUserPromoCode]
  );
  const componentsList = [
    {
      type: FORM_COMPONENTS_TYPES.INPUT_FIELD,
      name: 'promoCode',
      label: 'personalOffice.bonuses.promoCode',
      placeholder: 'personalOffice.bonuses.enterPromoCode',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
      classNames: {
        wrapperClassName: styles.promoCodeInput
      }
    },
    {
      type: FORM_COMPONENTS_TYPES.SUBMIT_BUTTON,
      value: 'personalOffice.bonuses.usePromoCodes'
    }
  ];
  return (
    <Box component={PersonalOfficeWrapper} direction="column">
      <Box direction="column" className={styles.promocodeWrapper}>
        <InfoMessage icon="info">
          {t('personalOffice.promoCode.info')}
        </InfoMessage>
        <FormBuilder
          components={componentsList}
          onSubmit={sendPromoCode}
          formConfig={{ defaultValues }}
        />
      </Box>
    </Box>
  );
};

const WrappedPromoCode = () => (
  <SelfExcludedWrapper>
    <BonusHunterWrapper>
      <PromoCode />
    </BonusHunterWrapper>
  </SelfExcludedWrapper>
);

export default WrappedPromoCode;
