import { memo } from 'react';
import { Box, OutcomeButton } from '@alf/uikit';
import { MARKET_TEMPLATE_OUTCOMES_MAP } from '../constants';
import styles from './index.module.css';

const EmptyMarket = ({ marketTemplateId, lineView, className }) => {
  const count = MARKET_TEMPLATE_OUTCOMES_MAP[marketTemplateId] || 2;

  return [...Array(count).keys()].map((idx) => (
    <Box className={styles.emptyMarketContainer} key={`placeholder-${idx}`}>
      <OutcomeButton
        className={`${styles.outcomeContainer} ${
          lineView ? styles[lineView] : ''
        } ${className}`}
        isDisabled={true}
        lineView={lineView}
      />
    </Box>
  ));
};

export default memo(EmptyMarket);
