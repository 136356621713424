import { useCallback, useContext, useState } from 'react';
import { useNotifications } from '@namespace/notifications';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { Button } from '@alf/uikit';
import { useGeneralRoutes } from '@namespace/cms';
import { REDIRECT_PARAM } from '@namespace/common';
import { USER_ROLES, UserContext } from '@namespace/user';
import { DEFAULT_ACTIVE_TAG } from '../../constants';

import { joinTournament } from '../../api';
import { promotionsActions } from '../../store';
import { TournamentsLeaderboardsContext } from '../../tournamentsLeaderboardsStore/context';

const { FETCH_TOURNAMENTS_SERVICE_INFO } = promotionsActions;

export const TournamentTakePartButton = ({
  loginRedirectTo,
  tournamentId,
  className,
  isUpdateLeaderboard = false,
  size = 'm',
  ...btnProps
}) => {
  const [user] = useContext(UserContext);
  const [, { GET_LEADERBOARD_INFO }] = useContext(
    TournamentsLeaderboardsContext
  );
  const { push } = useHistory();
  const { t } = useI18n();
  const { login, promotions } = useGeneralRoutes();
  const redirectLink =
    loginRedirectTo || `${promotions}/tournaments/${DEFAULT_ACTIVE_TAG}`;
  const promoLoginLink = `${login}?${REDIRECT_PARAM}${redirectLink}`;
  const isLoggedInUser = user?.role === USER_ROLES.USER;
  const { errorNotification } = useNotifications();

  const [isJoinTournamentInProcess, setIsJoinTournamentInProcess] = useState(
    false
  );

  const handleTakePartClick = useCallback(
    async (event) => {
      try {
        event.stopPropagation();

        if (!isLoggedInUser) {
          push(promoLoginLink);

          return;
        }

        setIsJoinTournamentInProcess(true);

        const joinResponse = await joinTournament(tournamentId);

        if (joinResponse?.status !== 'success') {
          throw new Error(joinResponse.errorCode);
        }

        if (isUpdateLeaderboard) {
          await GET_LEADERBOARD_INFO(tournamentId);
        }

        await FETCH_TOURNAMENTS_SERVICE_INFO([tournamentId]);

        setIsJoinTournamentInProcess(false);
      } catch (e) {
        console.error(e);
        setIsJoinTournamentInProcess(false);

        const errorCode = e?.message || 'default';

        errorNotification(t(`web.tournaments.error.${errorCode}`));
      }
    },
    [
      isLoggedInUser,
      tournamentId,
      FETCH_TOURNAMENTS_SERVICE_INFO,
      isUpdateLeaderboard,
      push,
      promoLoginLink,
      GET_LEADERBOARD_INFO,
      errorNotification,
      t
    ]
  );

  return (
    <Button
      className={className}
      intent="primary"
      size={size}
      actionType="color1"
      fullWidth={true}
      onClick={handleTakePartClick}
      processing={isJoinTournamentInProcess}
      data-role="tournaments-item-take-part-btn"
      {...btnProps}
    >
      {t('web.tournaments.lobby.takepart')}
    </Button>
  );
};
