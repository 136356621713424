import { useState } from 'react';
import { Box, Popover } from '@alf/uikit';
import { JackpotItemShortInfo } from '../JackpotItemShortInfo';
import { JackpotItemDetails } from '../JackpotItemDetails';

import styles from './index.module.css';

export const JackpotItemInBanner = ({
  jackpotType,
  jackpotInfo,
  className = ''
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Box
      onMouseEnter={() => setIsPopoverOpen(true)}
      onMouseLeave={() => setIsPopoverOpen(false)}
    >
      <Popover
        classNames={{
          wrapperClass: styles.popoverWrapper,
          contentClass: styles.contentClass
        }}
        isOpenExternal={isPopoverOpen}
        disabledToggleInWrapper={true}
        trigger={
          <JackpotItemShortInfo
            jackpotType={jackpotType}
            jackpotInfo={jackpotInfo}
            className={className}
          />
        }
        content={
          <JackpotItemDetails
            jackpotType={jackpotType}
            jackpotInfo={jackpotInfo}
          />
        }
      />
    </Box>
  );
};
