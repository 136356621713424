import PropTypes from 'prop-types';
import clsx from 'clsx';
import { isFunction, isObject } from 'lodash';
import Table from './components/Table';
import TableCell from './components/TableCell';
import TableRow from './components/TableRow';
import TableHeader from './components/TableHeader';
import TableBody from './components/TableBody';
import styles from './index.module.css';

/* eslint react/no-array-index-key: "off" */

const DataTable = ({
  data,
  columns,
  withHeader = true,
  className = '',
  customCellClass,
  tableRowStyle,
  isClearAfter,
  size = 's'
}) => {
  return (
    <Table className={className}>
      {withHeader && (
        <TableHeader>
          <TableRow className={clsx([styles.row, styles[size]])}>
            {columns.map(({ Header }, index) => (
              <TableCell
                scope="col"
                key={index}
                className={clsx([
                  styles.head,
                  isClearAfter && styles.clearAfter
                ])}
                style={customCellClass}
              >
                {isFunction(Header) || isObject(Header) ? <Header /> : Header}
              </TableCell>
            ))}
          </TableRow>
        </TableHeader>
      )}
      <TableBody>
        {data.map((row, index) => (
          <TableRow
            key={index}
            className={clsx([styles.row, styles[size]])}
            style={tableRowStyle}
          >
            {columns.map(
              ({ accessor, DataComponent, cellClass }, indexColumn) => {
                const key = `row-${index}-column-${indexColumn}`;
                return (
                  <TableCell
                    key={key}
                    className={clsx([
                      cellClass,
                      styles.cell,
                      isClearAfter && styles.clearAfter
                    ])}
                    style={customCellClass}
                  >
                    {DataComponent ? (
                      <DataComponent row={row} index={index} />
                    ) : (
                      row[accessor]
                    )}
                  </TableCell>
                );
              }
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

DataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.node,
      accessor: PropTypes.string,
      DataComponent: PropTypes.func
    })
  ),
  withHeader: PropTypes.bool,
  className: PropTypes.string
};

export default DataTable;
