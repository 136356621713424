import { useCallback, useContext } from 'react';
import { Icon } from '@namespace/icons2';
import { AText, Box } from '@alf/uikit';
import { useShowStatistics } from '@namespace/cms';
import { window } from '@namespace/helpers';
import { UserContext } from '@namespace/user';
import clsx from 'clsx';
import EventFavoriteStar from '../EventFavoriteStar';
import { useIsBroadcastEnabledForSport } from '../../hooks/useIsBroadcastEnabledForSport';
import useSportsSettings from '../../hooks/useSportsSettings';
import { LINE_VIEW } from '../../constants';
import {
  useEventField,
  useEventFieldLength,
  useEventFields
} from '../../store';
import styles from './index.module.css';

const EventIcons = ({
  eventId,
  isShowMarketsCount = true,
  isShowFavorite = true,
  classNames = {},
  isIconsClickable = true,
  isHideUnselectedFavorite = false,
  isLiveEvents = false
}) => {
  const [state = {}] = useContext(UserContext);
  const { settings = {} } = state;
  const { lineViewType } = settings;
  const { showPaidVideos = true, isShowVideo = true } = useSportsSettings();

  const { marketsCountClass } = classNames;
  const {
    serviceId,
    eventBroadcastUrl,
    eventEnetStatUrl,
    marketCount,
    sportId
  } = useEventFields({
    eventId,
    fields: [
      'serviceId',
      'eventBroadcastUrl',
      'eventEnetStatUrl',
      'marketCount',
      'sportId'
    ]
  });
  const isFta = useEventField(eventId, ['eventTv', 'isFta']);
  const countriesLength = useEventFieldLength(eventId, [
    'eventTv',
    'countries'
  ]);

  const isVideoFree = Boolean(eventBroadcastUrl || isFta);
  const isBroadcastEnabled = useIsBroadcastEnabledForSport(sportId);

  const isShowPaidVideo = showPaidVideos || isVideoFree;

  const isShowVideoIcon =
    isLiveEvents &&
    isShowPaidVideo &&
    isShowVideo &&
    ((eventBroadcastUrl && isBroadcastEnabled) || countriesLength > 0);

  const onStatsOpen = useCallback(
    (e) => {
      e.preventDefault();
      window.open(eventEnetStatUrl, '_blank');
    },
    [eventEnetStatUrl]
  );

  const isShowStatIcon = useShowStatistics(eventEnetStatUrl);
  return (
    <Box align="center" className={styles.icons}>
      {isShowVideoIcon && (
        <Icon
          data-role="sportsBook-event-videoIcon"
          className={styles.iconItem}
          size="xs"
          name={`icons/general/state/video${isVideoFree ? '-free' : ''}`}
          color={isVideoFree ? 'accent_color_5' : 'accent_color_3'}
        />
      )}
      {isShowStatIcon && (
        <Box
          align="center"
          className={clsx([
            lineViewType === LINE_VIEW.TABLE && styles.iconItemTable
          ])}
          onClick={isIconsClickable ? onStatsOpen : null}
        >
          <Icon
            className={styles.iconItem}
            size="xs"
            name="icons/event/statistics"
            color="line_infotxt_5"
          />
        </Box>
      )}
      {isShowMarketsCount && (
        <AText
          size="xs"
          breed="R"
          color="line_infotxt_5"
          data-role="sport-event-markets-counter"
          className={`
              ${styles.iconItem}
              ${styles.marketsCount}
              ${marketsCountClass}
            `}
        >
          {`+${marketCount}`}
        </AText>
      )}
      {isShowFavorite && (
        <EventFavoriteStar
          size="xs"
          eventId={eventId}
          serviceId={serviceId}
          isClickable={isIconsClickable}
          isHideUnselected={isHideUnselectedFavorite}
        />
      )}
    </Box>
  );
};

export default EventIcons;
