export const passValidationRules = /^(?=[A-Za-z0-9]*?[A-Z])(?=[A-Za-z0-9]*?[0-9])[A-Za-z0-9]{6,14}$/;

export const passValidationRulesList = [
  {
    label: ['validation.lessMore', ['8', '14']],
    rules: /^.{8,14}$/
  },
  {
    label: 'validation.charAndNumber',
    rules: 'charAndNumber'
  },
  {
    label: 'validation.allChars',
    rules: 'allChars'
  }
];
