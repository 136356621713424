export const types = {
  GET_SPORTS_NAME: 'GET_SPORTS_NAME',
  SET_SPORTS_NAME: 'SET_SPORTS_NAME',
  GET_CATEGORIES: 'GET_CATEGORIES',
  SET_CATEGORIES: 'SET_CATEGORIES',
  GET_TOURNAMENTS: 'GET_TOURNAMENTS',
  SET_TOURNAMENTS: 'SET_TOURNAMENTS',
  GET_SPORT_RESULTS: 'GET_SPORT_RESULTS',
  SET_SPORTS_EVENTS_RESULT: 'SET_SPORTS_EVENTS_RESULT',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_VIRTUAL_EVENTS_RESULT: 'SET_VIRTUAL_EVENTS_RESULT',
  GET_VIRTUAL_RESULT: 'GET_VIRTUAL_RESULT',
  SET_VIRTUAL_TOURNAMENT: 'SET_VIRTUAL_TOURNAMENT',
  SET_VIRTUAL_CATEGORIES: 'SET_VIRTUAL_CATEGORIES',
  GET_VIRTUAL_FILTER_OPTIONS: 'GET_VIRTUAL_FILTER_OPTIONS',
  SET_OFFSET: 'SET_OFFSET',
  SET_PROCESSING: 'SET_PROCESSING',
  RESET_SPORT: 'RESET_SPORT'
};
