const getPromoItemByCurrency = (
  settingsPerCurrency,
  currentCurrency,
  language,
  platform,
  getImg = () => {},
  item
) => {
  const currentCurrencyConfig =
    settingsPerCurrency?.find(({ currency }) => currency === currentCurrency) ||
    {};
  const currencyConfigForPlatform = currentCurrencyConfig[platform];
  const { imageLink: currencyImage, imageLinkDark: currencyImageDark } =
    currencyConfigForPlatform || {};

  if (item === 'images') {
    const imagePerLanguage =
      currencyConfigForPlatform?.imagesPerLanguage?.find(
        (image) => image.language === language
      ) || {};
    const {
      imageLink: languageImage,
      imageLinkDark: languageImageDark
    } = imagePerLanguage;
    const promoLanguageImage =
      getImg(languageImage, languageImageDark) || languageImage;
    const promoCurrencyImage =
      getImg(currencyImage, currencyImageDark) || currencyImage;

    return promoLanguageImage || promoCurrencyImage;
  }

  return currentCurrencyConfig ? currentCurrencyConfig[item] : null;
};

export default getPromoItemByCurrency;
