import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './index.module.css';

const Text = (props) => {
  const {
    component: Component,
    intent,
    size,
    lsize,
    className,
    align,
    transform,
    decoration,
    bold,
    semibold,
    typeface,
    overflow,
    inject,
    breakWord,
    danger,
    color = '',
    style = {},
    ...restProps
  } = props;

  const classes = clsx([
    className,
    styles[typeface],
    styles.general,
    size && styles[`f_${size}`],
    align && styles[align],
    bold && styles.bold,
    breakWord && styles[breakWord],
    semibold && styles.semibold,
    transform && styles[transform],
    decoration && styles[decoration],
    overflow && styles[overflow],
    lsize && styles[`l_${lsize}`]
  ]);

  let finalColor = '';
  if (color.startsWith('--')) {
    finalColor = color;
    if (process.env.NODE_ENV === 'development') {
      console.warn(
        `Please replace AText color from ${color} to ${color.slice(2)}`
      );
    }
  } else if (color) {
    finalColor = `--${color}`;
  }

  if (intent && !color) {
    finalColor = `--txt_${intent}`;
    if (process.env.NODE_ENV === 'development') {
      console.warn(`Please replace AText intent ${intent} to color`);
    }
  }

  const styleWithColor = {
    color: finalColor ? `var(${finalColor})` : undefined,
    ...style
  };

  if (inject) {
    return cloneElement(restProps.children, {
      className: classes,
      style: styleWithColor
    });
  }

  if (danger) {
    const { children, ...rest } = restProps;
    return (
      <Component
        {...rest}
        dangerouslySetInnerHTML={{ __html: children }}
        style={styleWithColor}
        className={classes}
      />
    );
  }

  return (
    <Component {...restProps} style={styleWithColor} className={classes} />
  );
};

Text.propTypes = {
  intent: PropTypes.oneOf([
    'color_1',
    'color_2',
    'color_3',
    'color_4',
    'color_5',
    'color_6',
    'color_7',
    'color_error'
  ]),
  color: PropTypes.string,
  size: PropTypes.oneOf([
    '2xs',
    'xs',
    's',
    'm',
    'l',
    'xl',
    '2xl',
    '3xl',
    '4xl'
  ]),
  transform: PropTypes.oneOf([
    'capitalize',
    'lowercase',
    'uppercase',
    'initial'
  ]),
  decoration: PropTypes.oneOf([
    'none',
    'underline',
    'overline',
    'line-through'
  ]),
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.elementType,
    PropTypes.node
  ]),
  align: PropTypes.oneOf(['left', 'right', 'center']),
  breakWord: PropTypes.oneOf(['break-all', 'break-word', 'keep-all', 'normal']),
  children: PropTypes.node,
  overflow: PropTypes.oneOf(['ellipsis', 'hidden']),
  inject: PropTypes.bool,
  className: PropTypes.string,
  bold: PropTypes.bool,
  semibold: PropTypes.bool,
  typeface: PropTypes.oneOf(['base']),
  lsize: PropTypes.oneOf(['normal']),
  danger: PropTypes.bool
};

Text.defaultProps = {
  component: 'span',
  size: 'm',
  align: 'left',
  typeface: 'base',
  className: ''
};

export default Text;
