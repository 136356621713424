import { useContext, useEffect } from 'react';
import { USER_ROLES, UserContext } from '@namespace/user';
import { useComponentConfig } from '@namespace/cms';
import { GamesContext } from '../../context';
import { useCasinoSettings } from '../useCasinoSettings';

export const useLoadRecentlyPlayed = (casinoType) => {
  const [
    gamesState,
    { GET_RECENTLY_PLAYED_LIST, RESET_RECENTLY_PLAYED_LIST }
  ] = useContext(GamesContext);
  const { suggesterServiceEntityName } = useCasinoSettings(casinoType);
  const recentlyGamesIsLoaded =
    gamesState.recentlyPlayed[suggesterServiceEntityName]?.isLoaded;
  const [user] = useContext(UserContext);
  const { role } = user;
  const { showRecentlyPlayedCategory } = useComponentConfig();

  useEffect(() => {
    if (
      role === USER_ROLES.USER &&
      !recentlyGamesIsLoaded &&
      showRecentlyPlayedCategory
    ) {
      GET_RECENTLY_PLAYED_LIST(suggesterServiceEntityName);
    }
  }, [
    role,
    recentlyGamesIsLoaded,
    suggesterServiceEntityName,
    GET_RECENTLY_PLAYED_LIST,
    RESET_RECENTLY_PLAYED_LIST,
    showRecentlyPlayedCategory
  ]);

  useEffect(() => {
    // reset recently played after leaving casino section to refetch each time user visits casino
    return () => {
      RESET_RECENTLY_PLAYED_LIST(suggesterServiceEntityName);
    };
  }, [RESET_RECENTLY_PLAYED_LIST, suggesterServiceEntityName]);
};
