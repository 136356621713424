import { useComponentConfig } from '@namespace/cms';
import Line from '../../../Line';
import { useCustomFilterEvents } from '../../../../hooks/useCustomFilterEvents';
import { useEventsByIdsToShow } from '../../../../store';

const emptyArr = [];
export const CustomFilterLine = ({
  id,
  filter,
  sport,
  categoryIds = emptyArr,
  tournamentsIds = emptyArr,
  lineKey,
  activeHeadGroups,
  isShowFlag,
  isExpanded,
  isShowEmpty,
  isCollapsibleTournaments,
  isLiveEvents,
  isReady
}) => {
  const {
    isHeadGroupsSortingEnabled,
    isShowEventGroup,
    activeHeadGroupsAmount
  } = useComponentConfig();

  const {
    loadMoreEvents,
    isLoadingMore,
    isEventsLoaded,
    isEventsLoading
  } = useCustomFilterEvents({
    lineKey,
    sportId: sport.sportId,
    filter,
    categoryId: categoryIds[0],
    tournamentsIds
  });
  const eventsToShow = useEventsByIdsToShow(lineKey, tournamentsIds);

  return (
    <>
      <Line
        id={id}
        eventsToShow={eventsToShow}
        tournamentIds={tournamentsIds}
        isExpanded={isExpanded}
        isShowEventGroup={isShowEventGroup}
        isShowFlag={isShowFlag}
        isHeadGroupsSortingEnabled={isHeadGroupsSortingEnabled}
        isEventsLoading={isEventsLoading}
        activeHeadGroupsAmount={activeHeadGroupsAmount}
        activeHeadGroups={activeHeadGroups}
        fetchEvents={loadMoreEvents}
        isLoadingMore={isLoadingMore}
        isShowEmpty={isShowEmpty}
        isCollapsibleTournaments={isCollapsibleTournaments}
        lineKey={lineKey}
        isEventsLoaded={isEventsLoaded}
        isLiveEvents={isLiveEvents}
        isReady={isReady}
      />
    </>
  );
};
