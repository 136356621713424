import { AText, Box } from '@alf/uikit';
import clsx from 'clsx';
import styles from './index.module.css';

export const CricketTemplate = ({
  isShortVersion,
  fullScore,
  lineView,
  isTotalScore
}) => (
  <Box direction="column" className={styles.container}>
    {!isShortVersion && <div className={styles.emptyHeader} />}
    <Box
      direction="column"
      className={clsx([
        styles.scoreContainer,
        styles[lineView],
        isTotalScore && !isShortVersion && styles.totalScore
      ])}
    >
      {fullScore.map((score, index) => {
        const key = `score-${index}`;
        return (
          <Box
            key={key}
            align="center"
            justify="center"
            className={clsx([
              styles.scoreCell,
              isShortVersion && styles.shortVersionScoreCell
            ])}
          >
            <AText
              size="m"
              color="line_txt_1"
              breed={isTotalScore ? 'BU' : 'R'}
              className={clsx([styles.scoreCellText, styles[lineView]])}
            >
              {score}
            </AText>
          </Box>
        );
      })}
    </Box>
  </Box>
);
