import { AText } from '@alf/uikit';
import useCurrencyConvertation from '../../../../hooks/useCurrencyConvertation';

const CurrencyConvertation = ({
  convertedAmountColor,
  convertedAmountSize,
  convertedAmountBreed,
  convertedCurrencyBreed,
  additionalFormatting,
  noFormattedAmount,
  currentAmount,
  currentCurrency
}) => {
  const { getConvertedAmount } = useCurrencyConvertation();
  const {
    amounts: convertedAmounts,
    currency: convertedCurrency
  } = getConvertedAmount({
    amount: currentAmount,
    additionalFormatting,
    currency: currentCurrency,
    noFormattedAmount
  });

  return (
    <AText color={convertedAmountColor} size={convertedAmountSize}>
      <AText breed={convertedAmountBreed} size={convertedAmountSize}>
        {convertedAmounts.join('/')}
      </AText>
      <AText size={convertedAmountSize} breed={convertedCurrencyBreed}>
        {` ${convertedCurrency}`}
      </AText>
    </AText>
  );
};

export default CurrencyConvertation;
