import { forwardRef } from 'react';
import styles from './index.module.css';

const GameIframe = forwardRef(
  ({ className = '', content = '', title, ...props }, ref) => (
    <iframe
      {...props}
      srcDoc={content}
      className={`${styles.iframe} ${className}`}
      title={title}
      ref={ref}
    />
  )
);

export default GameIframe;
