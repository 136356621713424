import { AText, Box } from '@alf/uikit';
import clsx from 'clsx';
import { Icon } from '@namespace/icons2';
import { TextCopy } from '@namespace/common';
import { isEmpty } from 'lodash';
import styles from '../../index.module.css';
import {
  CASINO_BET_DETAILS_RESULT_COLOR,
  CASINO_BET_RESULT_STATUS_ICON_FILL,
  CASINO_BET_TYPES
} from '../../../../../../constants';

const CasinoHistoryItem = ({
  gameName,
  providerName,
  roundId,
  date,
  betSum = 0,
  betWin = [],
  freeSpinWins = [],
  jackpotWins = [],
  tournamentWins = [],
  refunds = [],
  canceled = [],
  walletType = 1,
  status,
  translate,
  isNeedCopy = false,
  customLabel,
  customFont,
  customContainerItem,
  customLabelWrapper,
  iconConfig
}) => {
  const winResult = [
    ...betWin,
    ...freeSpinWins,
    ...jackpotWins,
    ...tournamentWins
  ];

  const { iconPosition, iconSize = 'xs' } = iconConfig || {};

  const copyText = `Round id: ${roundId}
Game name: ${gameName}
Provider name: ${providerName}
Bet time: ${date}
Bet: ${betSum}
Result: ${winResult.join(', ')}`;

  return (
    <Box className={clsx(styles.containerItem, customContainerItem)}>
      <Box
        className={clsx(iconPosition && styles[iconPosition])}
        margin="0 s 0 0"
      >
        <Icon
          dataRole="casino-history-item-status"
          size={iconSize}
          color={CASINO_BET_DETAILS_RESULT_COLOR[CASINO_BET_TYPES[status]]}
          name={`icons/general/state/${
            CASINO_BET_RESULT_STATUS_ICON_FILL[CASINO_BET_TYPES[status]]
          }`}
        />
      </Box>
      <Box direction="column" gap="xs" flexGrow={1}>
        <Box className={clsx(styles.labelWrapper, customLabelWrapper)}>
          <Box>
            <AText
              size="s"
              breed="B"
              color="po_txt_color_1"
              className={customFont}
            >
              {`ID ${roundId}`}
            </AText>
            <TextCopy
              gap="xs"
              breed="R"
              iconSize="xs"
              iconLink="icons/general/action/ic_copy"
              color="po_txt_color_1"
              textCopy={roundId}
              textNotify="ID"
              dataRoles={{
                dataRoleText: 'casino-history-copyId-value',
                dataRoleIcon: 'casino-history-copyId-icon'
              }}
            />
          </Box>
          {!isEmpty(freeSpinWins) && (
            <AText
              size="2xs"
              breed="BU"
              transform="uppercase"
              className={clsx(styles.label, customLabel)}
            >
              {translate('bets.betsCasino.history.freeSpin')}
            </AText>
          )}
          {!isEmpty(jackpotWins) && (
            <AText
              size="2xs"
              breed="BU"
              transform="uppercase"
              className={clsx(styles.label, customLabel)}
            >
              {translate('bets.betsCasino.history.jackpot')}
            </AText>
          )}
          {!isEmpty(tournamentWins) && (
            <AText
              size="2xs"
              breed="BU"
              transform="uppercase"
              className={clsx(styles.label, customLabel)}
            >
              {translate('bets.betsCasino.history.tournament')}
            </AText>
          )}
        </Box>
        <Box justify="between">
          <AText
            data-role="casino-history-item-date"
            size="s"
            breed="R"
            color="po_txt_color_3"
            className={customFont}
          >
            {date}
          </AText>
          <Box align="start">
            <Box align="center" className={styles.minWidth}>
              <AText
                className={styles.paddingText}
                size="s"
                breed="R"
                color="po_txt_color_3"
              >
                {translate('bets.betsCasino.history.bet')}
              </AText>
              {!walletType && (
                <Icon
                  className={styles.margin}
                  size="xs"
                  name="icons/user_menu/bonuses"
                />
              )}
              <AText
                size="s"
                breed="B"
                color="po_txt_color_1"
                data-role="casino-history-item-betSum"
              >
                {betSum || 0}
              </AText>
            </Box>
            <Box
              direction="column"
              className={clsx(styles.wrapperResult, styles.minWidth)}
            >
              {winResult?.length < 4 ? (
                winResult.map((item, index) => {
                  const key = `${roundId}-win-${index}`;
                  return (
                    <Box key={key}>
                      <AText
                        className={styles.paddingText}
                        size="s"
                        breed="R"
                        color="po_txt_color_3"
                      >
                        {translate('bets.betsCasino.history.win')}
                      </AText>
                      <AText
                        data-role="casino-history-item-win"
                        size="s"
                        breed="B"
                        color="po_txt_color_1"
                      >
                        {item}
                      </AText>
                    </Box>
                  );
                })
              ) : (
                <>
                  <Box>
                    <AText
                      className={styles.paddingText}
                      size="s"
                      breed="R"
                      color="po_txt_color_3"
                    >
                      {translate('bets.betsCasino.history.win')}
                    </AText>
                    <AText
                      className={styles.multiWin}
                      size="s"
                      breed="B"
                      color="po_txt_color_1"
                    >
                      {winResult
                        .reduce((total, item) => total + item, 0)
                        .toFixed(2)}
                    </AText>
                  </Box>
                  <AText
                    className={styles.multiWin}
                    size="s"
                    breed="B"
                    color="po_txt_color_1"
                  >
                    {translate('bets.betsCasino.history.roundsWin', [
                      winResult.length
                    ])}
                  </AText>
                </>
              )}
              {refunds?.map((item, index) => {
                const key = `${roundId}-refund-${index}`;
                return (
                  <Box key={key}>
                    <AText
                      className={styles.paddingText}
                      size="s"
                      breed="R"
                      color="po_txt_color_3"
                    >
                      {translate('bets.betsCasino.history.refund')}
                    </AText>
                    <AText size="s" breed="B" color="po_txt_color_1">
                      {item}
                    </AText>
                  </Box>
                );
              })}
              {canceled?.map((item, index) => {
                const key = `${roundId}-cancel-${index}`;
                return (
                  <Box key={key}>
                    <AText
                      className={styles.paddingText}
                      size="s"
                      breed="R"
                      color="po_txt_color_3"
                    >
                      {translate('bets.betsCasino.history.cancel')}
                    </AText>
                    <AText size="s" breed="B" color="po_txt_color_1">
                      {item}
                    </AText>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
      {isNeedCopy && (
        <TextCopy
          breed="R"
          size="s"
          color="po_txt_color_3"
          textCopy={copyText}
          gap="m"
        />
      )}
    </Box>
  );
};

export default CasinoHistoryItem;
