import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useConfig } from '@namespace/cms';
import { useUserSegmentsIncludes } from '@namespace/segments';
import { useI18n } from '@namespace/i18n';
import Snippet from '../Snippet';
import styles from './index.module.css';

const SegmentedSnippet = ({ id, config: externalConfig = {} }) => {
  const { t } = useI18n();
  const { config } = useConfig(id);
  const { slides } = isEmpty(config) ? externalConfig : config; // used for customFooter in 3column layout
  const isUserSegmentsIncludes = useUserSegmentsIncludes();

  const { snippetId, title } = useMemo(() => {
    const [snippet = {}] = (slides || []).filter((slide) =>
      isUserSegmentsIncludes(slide.userSegments)
    );
    return snippet;
  }, [slides, isUserSegmentsIncludes]);
  if (!snippetId) {
    return null;
  }

  if (title) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.page}>
          <div className={styles.header}>{t(title)}</div>
          <div className={styles.content}>
            <Snippet snippetId={snippetId} />
          </div>
        </div>
      </div>
    );
  }

  return <Snippet snippetId={snippetId} />;
};

export default SegmentedSnippet;
