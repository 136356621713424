import { useCallback } from 'react';
import { AText } from '@alf/uikit';
import styles from './index.module.css';

const FilterItem = ({
  isActive,
  value,
  className = '',
  onSetFilter,
  name = ''
}) => {
  const selectFilter = useCallback(
    () => (isActive ? undefined : onSetFilter(value)),
    [isActive, onSetFilter, value]
  );

  return (
    <AText
      breed="RU"
      className={`
        ${className}
        ${styles.item}
        ${isActive ? styles.active : ''}
      `}
      onClick={selectFilter}
    >
      {name}
    </AText>
  );
};

export default FilterItem;
