import { useI18n } from '@namespace/i18n';
import { useCallback, useContext, useEffect, useState } from 'react';
import { DeviceContext, FORM_FACTORS_MAP } from '@namespace/device';
import { useNotifications } from '@namespace/notifications';
import { useGlobalMockedStore, OnboardingContext } from '@namespace/onboarding';
import { uniqBy, difference } from 'lodash';
import { UserContext } from '@namespace/user';
import { useOtherSettings } from '@namespace/cms';
import { fundsStatesMap } from '../../constants';
import {
  getFundsBonusesList,
  getFundsBonusesTotalPages
} from '../../api/fundsBonuses';
import { prepareMockedBonuses } from '../../utils/prepareMockedBonuses';
import { normalizeSystemBonuses } from '../../utils/normalizeSystemBonuses';
import { getBonusSystemHistory } from '../../api/getBonusSystemHistory';
import { getBonusSystemList } from '../../api/getBonusSystemList';
import { BonusesContext } from '../../store/context';

const useFundsBonuses = (filter) => {
  const { t } = useI18n();
  const { pageSize, currentPage, stateList } = filter;
  const [bonuses, setBonuses] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [isInitialLoaded, setIsInitialLoaded] = useState(false);
  const { errorNotification } = useNotifications();
  const { isPreWagerBonusesEnabled = false } = useOtherSettings();
  const { isDesktop } = useContext(DeviceContext);
  const [, { FETCH_WAGER_BONUSES }] = useContext(BonusesContext);
  const [user] = useContext(UserContext);
  const { activeWallet, id: userId } = user;
  const { currency } = activeWallet;

  const platform = isDesktop
    ? FORM_FACTORS_MAP.DESKTOP
    : FORM_FACTORS_MAP.MOBILE;
  // TODO: TEMPORARY SOLUTION! rewrite bonuses to context structure and remove this from here
  const [bonusesMockedStore] = useGlobalMockedStore('bonuses', {}, {});
  const { fundsBonuses: mockedFundsBonuses } = bonusesMockedStore;
  const [{ isRunning }] = useContext(OnboardingContext);

  const loadCrmBonuses = useCallback(async () => {
    try {
      const bonusesResponse = await getFundsBonusesList(filter, platform);

      const { errorCode, response } = bonusesResponse;

      if (errorCode === 0) {
        return response;
      }

      return [];
    } catch (e) {
      errorNotification(t(`accountingMessages.accounting_error_6`));
      return [];
    }
  }, [errorNotification, filter, platform, t]);

  const loadBsBonuses = useCallback(async () => {
    try {
      let systemBonuses;

      if (!difference(stateList, fundsStatesMap.history).length) {
        const systemBonusesResponse = await getBonusSystemHistory();
        systemBonuses = normalizeSystemBonuses(systemBonusesResponse, currency);
      } else {
        FETCH_WAGER_BONUSES({
          isPreWagerBonusesEnabled: true
        });
        const systemBonusesResponse = await getBonusSystemList();
        systemBonuses = normalizeSystemBonuses(systemBonusesResponse, currency);
      }

      return systemBonuses;
    } catch (e) {
      errorNotification(t(`accountingMessages.accounting_error_6`));
      return [];
    }
  }, [FETCH_WAGER_BONUSES, currency, errorNotification, stateList, t, userId]);

  const loadBonusesList = useCallback(async () => {
    setIsFetching(true);

    const crmBonuses = await loadCrmBonuses();
    let bsBonuses = [];
    if (isPreWagerBonusesEnabled) {
      bsBonuses = await loadBsBonuses();
    }

    setBonuses((prevState) => {
      const bonusList =
        currentPage === 1
          ? [...bsBonuses, ...crmBonuses]
          : [...prevState, ...crmBonuses];
      return uniqBy(bonusList, 'bonusId');
    });

    setIsFetching(false);
    setIsInitialLoaded(true);
  }, [currentPage, isPreWagerBonusesEnabled, loadBsBonuses, loadCrmBonuses]);

  useEffect(() => {
    loadBonusesList();
  }, [loadBonusesList, platform]);

  useEffect(() => {
    (async () => {
      const totalBonuses = await getFundsBonusesTotalPages(filter, platform);
      setTotalPages(
        totalBonuses <= pageSize ? 1 : Math.ceil(totalBonuses / pageSize)
      );
    })();
  }, [filter, t, pageSize, platform]);

  return {
    bonuses: isRunning ? prepareMockedBonuses(mockedFundsBonuses) : bonuses,
    totalPages: isRunning ? 0 : totalPages,
    loadBonusesList,
    isFetching,
    isInitialLoaded
  };
};

export default useFundsBonuses;
