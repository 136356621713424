import { useContext, useMemo } from 'react';
import { ReferenceContext } from '@namespace/common/src/store/reference/context';
import { Flag } from '@namespace/icons2';
import SelectField from '../../common/SelectField';
import SELECT_TYPES from '../../constants/selectTypes';
import styles from './index.module.css';

const RegCountrySelect = ({ formProps = {}, ...props }) => {
  const { disableCountrySelect = false } = formProps;
  const { registrationCountries } = useContext(ReferenceContext);
  const countriesOptions = useMemo(
    () =>
      registrationCountries.map(({ countryName, iso1Code }) => ({
        iconElement: (
          <Flag name={countryName} iso={iso1Code} className={styles.flag} />
        ),
        name: countryName,
        value: iso1Code
      })),
    [registrationCountries]
  );

  return (
    <SelectField
      options={countriesOptions}
      selectType={SELECT_TYPES.SEARCH_SELECT}
      arrowSize="xs"
      hasSearch={true}
      {...props}
      disabled={disableCountrySelect}
    />
  );
};

export default RegCountrySelect;
