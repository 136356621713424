import { memo, useContext, useEffect, useRef } from 'react';
import { DeviceContext } from '@namespace/device';
import { Box } from '@alf/uikit';

const MenuItem = ({
  isActive = false,
  className = '',
  onClick = () => {},
  children
}) => {
  const { isDesktop, isIOS } = useContext(DeviceContext);
  const itemRef = useRef(null);

  useEffect(() => {
    if (!isDesktop && !isIOS && isActive && itemRef?.current) {
      itemRef.current.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }
  }, [isActive, isDesktop, isIOS]);

  return (
    <Box ref={itemRef} className={`menuItem ${className}`} onClick={onClick}>
      {children}
    </Box>
  );
};

export default memo(MenuItem);
