import { useContext } from 'react';
import { getRootHostName } from '@namespace/helpers';
import { SiteSettingsContext } from '@namespace/cms';
import { AText } from '@alf/uikit';
import clsx from 'clsx';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import getGoToOldUrl from '../../../utils/getGoToOldUrl';
import { useOldVersion } from '../../Header/useOldVersion';
import styles from './components/NavMenuItem/index.module.css';

const NavigationGoToOld = () => {
  const { t } = useI18n();
  const saveCookie = useOldVersion();
  const [{ siteConfig }] = useContext(SiteSettingsContext);
  const { availableMirrors = [] } = siteConfig;
  const hostname = getRootHostName();

  if (availableMirrors.length > 0 && !availableMirrors.includes(hostname)) {
    return null;
  }

  return (
    <LocalizedLink
      to={getGoToOldUrl()}
      onClick={saveCookie}
      className={clsx([styles.link, styles.linkContainer])}
      rel="noindex, nofollow"
    >
      <AText breed="BU" size="l" color="header_txt_1" className={styles.title}>
        {t('header.navigation.oldVersion')}
      </AText>
    </LocalizedLink>
  );
};

export default NavigationGoToOld;
