import { useMemo } from 'react';
import { List } from '@alf/uikit';
import { SportIcon } from '@namespace/icons2';
import { LocalizedNavLink, useLocation } from '@namespace/i18n';
import { addSlashToPathEnd } from '@namespace/helpers';

const SportList = ({
  items = [],
  isPartialLinkMatch = false,
  isShowPinned,
  classNames,
  isActive,
  onClick,
  ...props
}) => {
  const { pathname } = useLocation();

  const isActiveDefault = (link) => {
    return isPartialLinkMatch
      ? pathname.includes(link)
      : pathname === addSlashToPathEnd(link);
  };

  const itemsWithIcons = useMemo(() => {
    return items.map((item) => ({
      ...item,
      Icon: <SportIcon sportId={item.id} size="s" />
    }));
  }, [items]);

  return (
    <List
      classNames={classNames}
      items={itemsWithIcons}
      isShowPinned={isShowPinned}
      LocalizedNavLink={LocalizedNavLink}
      isActive={isActive || isActiveDefault}
      onClick={onClick}
      dataRole="sports-menu-list"
      {...props}
    />
  );
};

export default SportList;
