import PropTypes from 'prop-types';
import clsx from 'clsx';
import Label from '../Label';
import AText from '../AText';
import Box from '../Box';
import styles from './index.module.css';

const htIntents = {
  error: 'color_error',
  success: 'color_3',
  default: 'color_3'
};

const Field = ({
  labelPosition = 'left',
  label = '',
  labelProps = {},
  helpText = '',
  intent = 'default',
  control: Control,
  ...props
}) => {
  const labelComponent = (
    <Label
      {...labelProps}
      className={clsx([
        styles.label,
        styles[`label-${labelPosition}`],
        labelProps.className
      ])}
    >
      {label}
    </Label>
  );

  const hT = helpText && (
    <Box left="s" top="xs" align="center">
      <AText breed="R" size="s" intent={htIntents[intent]}>
        {helpText}
      </AText>
    </Box>
  );

  const control = Control ? <Control {...props} intent={intent} /> : null;

  if (labelPosition === 'top') {
    return (
      <Box align="start" direction="column">
        {labelComponent}
        <Box direction="column">
          {control}
          {hT}
        </Box>
      </Box>
    );
  }

  return (
    <Box direction="column">
      <Box align="center" direction="row">
        {labelComponent}
        {control}
      </Box>
      <Box>
        <Box style={{ visibility: 'hidden' }}>{labelComponent}</Box>
        {hT}
      </Box>
    </Box>
  );
};

Field.propTypes = {
  labelPosition: PropTypes.oneOf(['left', 'top']),
  label: PropTypes.string,
  helpText: PropTypes.string,
  labelProps: PropTypes.object,
  control: PropTypes.object,
  intent: PropTypes.oneOf(['error', 'success', 'default'])
};

export default Field;
