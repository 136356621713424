import { useEffect, useRef } from 'react';
import { useI18n } from '@namespace/i18n';
import { Box, Button } from '@alf/uikit';
import clsx from 'clsx';
import SnippetContent from '../common/SnippetContent';
import styles from './index.module.css';

const PrivacyPolicyNotification = ({
  currentPolicyContentId,
  onAccept,
  showMore,
  onShowMoreToggle,
  currentPolicyTitleId
}) => {
  const { t } = useI18n();
  const scrollTopTarget = useRef();

  useEffect(() => {
    if (showMore) {
      scrollTopTarget.current.scrollIntoView(true);
    }
  }, [showMore]);

  return (
    <Box className={styles.overlay}>
      <Box
        className={clsx([
          styles.contentWrapper,
          showMore ? styles.full : styles.short
        ])}
      >
        <div ref={scrollTopTarget} className={styles.scrollTop} />

        {currentPolicyContentId && (
          <SnippetContent
            isShowContent={showMore}
            privacyPolicyId={currentPolicyContentId}
            privacyPolicyBannerTitle={currentPolicyTitleId}
          />
        )}
      </Box>
      <Box gap="s" className={styles.buttonWrapper}>
        <Button
          actionType="color1"
          intent="secondary"
          onClick={onShowMoreToggle}
          className={styles.button}
        >
          {t(
            `notifications.policychange.${showMore ? 'readless' : 'readmore'}`
          )}
        </Button>
        <Button onClick={onAccept} className={styles.button}>
          {t('notifications.policychange.accept')}
        </Button>
      </Box>
    </Box>
  );
};

export default PrivacyPolicyNotification;
