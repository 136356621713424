import { createContext, useMemo, useReducer } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import { types } from '../types';
import { actions as actionCreators } from '../actions';
import { reducers, initialState } from '../reducers';

export const ClubContext = createContext();

export const ClubProvider = ({ children }) => {
  const memoReducers = useMemo(() => combineReducers(reducers), []);
  const [clubState, dispatch] = useReducer(memoReducers, initialState);
  const clubActions = useActions(types, dispatch, actionCreators);

  return (
    <ClubContext.Provider value={[clubState, clubActions]}>
      {children}
    </ClubContext.Provider>
  );
};
