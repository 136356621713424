import { useContext } from 'react';
import { UserContext } from '@namespace/user';
import { useReferenceState } from '@namespace/common';
import { isEmpty } from 'lodash';
import { Box } from '@alf/uikit';
import EmptyDocuments from '../EmptyDocuments';
import ProfileDocumentsForm from '../../../common/ProfileDocumentsForm';
import EmptyProfile from '../EmptyProfile';
import styles from './index.module.css';

const DocumentInformation = ({
  showDocumentsInfo,
  config,
  classNames = {}
}) => {
  useReferenceState();
  const [user] = useContext(UserContext);
  const { documents = [], isDefaultRegistratedUser } = user;
  const [document = {}] = documents;
  const isEmptyDocument = isEmpty(document);

  if (isDefaultRegistratedUser) {
    return <EmptyProfile />;
  }

  if (isEmptyDocument) {
    return <EmptyDocuments />;
  }

  return (
    <Box className={styles.information} direction="column">
      <ProfileDocumentsForm
        classNames={classNames}
        config={config}
        showDocumentsInfo={showDocumentsInfo}
        isDisabled={true}
        document={document}
        hideInfoMessage={!isEmptyDocument}
        hideButton={true}
      />
    </Box>
  );
};

export default DocumentInformation;
