import { useCallback, useContext } from 'react';
import { DeviceContext } from '@namespace/device';
import { Button } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { OnboardingContext } from '../../store/context';

export const OnboardingRegButton = () => {
  const { pageType } = useContext(DeviceContext);
  const [, { GO_TO_TOUR_BY_ID, RUN }] = useContext(OnboardingContext);
  const { t } = useI18n();
  const onClick = useCallback(() => {
    GO_TO_TOUR_BY_ID({ tourId: 'afterRegistration', pageType });
    RUN(true);
  }, [GO_TO_TOUR_BY_ID, RUN, pageType]);

  return (
    <Button intent="secondary" actionType="color3" size="xl" onClick={onClick}>
      {t('onboarding.register.button')}
    </Button>
  );
};
