import { bettorrentPost } from '@namespace/helpers';
import { camelizeKeys, decamelizeKeys } from 'humps';

export const getCustomFilterTournaments = async ({
  categoryId,
  lang,
  serviceId,
  partnerId
}) =>
  bettorrentPost({
    url: '/service/bettorrent/custom_filter/tournament/get',
    mode: 'same-origin',
    body: decamelizeKeys({
      categoryId,
      lang,
      serviceId,
      partnerId
    })
  }).then(({ response = [] }) => response.map((item) => camelizeKeys(item)));
