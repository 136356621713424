import { getSpeechValueFromLocalStorage } from '../../utils';
import { types } from '../types';

export const initialState = {
  speech: getSpeechValueFromLocalStorage() || false,
  dictionaries: {},
  visibleMobileBanner: false,
  supportSpeech: true
};

export const reducer = {
  [types.SET_SPEECH]: (state, speech) => ({
    ...state,
    speech
  }),
  [types.SET_DICTIONARY]: (state, { dictionary, language }) => ({
    ...state,
    dictionaries: { ...state.dictionaries, [language]: dictionary }
  }),
  [types.SET_VISIBLE_MOBILE_BANNER]: (state, visibleMobileBanner) => ({
    ...state,
    visibleMobileBanner
  }),
  [types.SET_SUPPORT_SPEECH]: (state, supportSpeech) => ({
    ...state,
    supportSpeech
  })
};
