const SOCCER = 1;
const BASKETBALL = 23;

export const SCOPE_IDS = {
  POINTS: 1,
  YELLOW_CARD: 2,
  RED_CARD: 3,
  CORNER: 4,
  PEN: 9,
  FOUL: 18
};

export const RESULT_TYPE_IDS = {
  HALF_1: 7, //            \
  HALF_2: 8, //             \
  HALF_2_WITH_OT: 9, //      \
  EXTRATIME_1: 510, //        -> For soccer
  EXTRATIME_2: 511, //       /
  PENALTIES: 3, //          /
  QUARTER_1: 60, //         \
  QUARTER_2: 61, //          \
  QUARTER_3: 62, //           -> For basketball
  QUARTER_4: 64, //          /
  QUARTER_4_WITH_OT: 63, // /
  OVERTIME: 512 //         |  common
};

export const RESULT_DETAIL_ICONS = {
  [SCOPE_IDS.YELLOW_CARD]: {
    icon: 'icons/event/yellow-card',
    color: 'state_warning'
  },
  [SCOPE_IDS.RED_CARD]: { icon: 'icons/event/red-card', color: 'state_error' },
  [SCOPE_IDS.CORNER]: { icon: 'icons/event/corner', color: 'accent_color_1' }
};

export const SCOPE_IDS_BY_SPORT_MAP = {
  [SOCCER]: [SCOPE_IDS.YELLOW_CARD, SCOPE_IDS.RED_CARD, SCOPE_IDS.CORNER],
  [BASKETBALL]: [] // SCOPE_IDS.POINTS
};

export const RESULT_TYPE_IDS_BY_SPORT_MAP = {
  [SOCCER]: [RESULT_TYPE_IDS.HALF_1, RESULT_TYPE_IDS.HALF_2],
  [BASKETBALL]: [
    RESULT_TYPE_IDS.QUARTER_1,
    RESULT_TYPE_IDS.QUARTER_2,
    RESULT_TYPE_IDS.QUARTER_3,
    RESULT_TYPE_IDS.QUARTER_4
  ]
};

export const VIRTUAL_SPORTS_EVENTS_LIMIT = 10;
