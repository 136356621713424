import { useContext } from 'react';
import { DeviceContext } from '@namespace/device';
import Selected from '../Selected';
import styles from '../../index.module.css';

const DropdownFilter = ({
  isInline,
  label,
  options = [],
  value,
  filterKey,
  onChange,
  Component,
  changedFilters,
  filterGroupClassName,
  arrowConfig,
  className = '',
  disabled,
  dataRole
}) => {
  const Control = Component || Selected;
  const defaultValue = options.find(
    // Made here == comparison because native select always convert int values to string
    // eslint-disable-next-line
    ({ value: optionValue }) => value == optionValue
  );
  const { isDesktop } = useContext(DeviceContext);
  return (
    <div
      data-role={dataRole && `${dataRole}-${filterKey}-wrapper`}
      key={`filterGroup-${filterKey}`}
      className={`
        ${filterGroupClassName}
        ${isInline ? styles.filterGroupInline : styles.filterGroup}
        ${filterKey === 'dateOptionId' && isInline ? styles.dateFilter : ''}
        ${className}
        filterGroup_${filterKey}
      `}
    >
      <Control
        label={label}
        defaultValue={defaultValue}
        changedFilters={changedFilters}
        arrowConfig={arrowConfig}
        options={options}
        onChange={onChange}
        disabled={disabled}
        className={styles.text}
        value={value || defaultValue}
        isDesktop={isDesktop}
        dataRole={dataRole && `${dataRole}-${filterKey}`}
      />
    </div>
  );
};

export default DropdownFilter;
