import { DateTime } from 'luxon';
import { AText, Box } from '@alf/uikit';
import { DATE_FORMATS_TYPES } from '@namespace/i18n';
import styles from './index.module.css';

export const BetEventTime = ({ eventDt }) => {
  if (!Number.isFinite(eventDt)) {
    return null;
  }

  const dateTime = DateTime.fromSeconds(eventDt);

  return (
    <Box flex="0 0 auto">
      <Box
        align="center"
        justify="center"
        padding="0 s"
        className={styles.eventDate}
      >
        <AText breed="R" size="xs" color="line_txt_3">
          {dateTime.toFormat(DATE_FORMATS_TYPES.EVENTS_LINE_DATE)}
        </AText>
      </Box>
      <Box
        align="center"
        justify="center"
        padding="0 s"
        className={styles.eventTime}
      >
        <AText breed="R" size="xs" color="line_txt_3">
          {dateTime.toFormat(DATE_FORMATS_TYPES.EVENTS_LINE_TIME)}
        </AText>
      </Box>
    </Box>
  );
};
