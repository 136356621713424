const resource = '/accounting/api/crm_roxy/';
const accountingApi = '/accounting/api/';

export default {
  resource,
  getBonusList: `${resource}getbonuslist`,
  getFreespinList: `${resource}getfreespinlist`,
  getRiskfreeList: `${resource}getriskfreelist`,
  getBonusCount: `${resource}getbonuscount`,
  getFreespinCount: `${resource}getfreespincount`,
  getRiskfreeCount: `${resource}getriskfreecount`,
  getFundsBonusDetails: `${resource}getuserbonusbyid`,
  getFreespinBonusDetails: `${resource}getfreespinbyid`,
  getRiskFreeBonusDetails: `${resource}getriskfreebyid`,
  getFreespinGames: `${resource}getfreespingames`,
  accountingApi,
  crmAcceptBonus: `${accountingApi}crm_accept_bonus`,
  crmAcceptFreespin: `${resource}acceptfreespin`,
  crmAcceptRiskfree: `${resource}acceptriskfree`,
  crmAcceptWager: `${resource}updwinbackstate`,
  crmAcceptAWS: `${resource}updwinbackstatefs`,
  crmAnyBonusCount: `${resource}getanybonuscount`,
  setUserPromoCode: `${resource}usepromocode`
};
