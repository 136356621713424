import { useState, useEffect } from 'react';
import { SelectField } from '@namespace/forms';
import { getCities } from '../../../api/getCities';

export const CityField = ({
  walletHash,
  cashdesk,
  language,
  setValue,
  ...rest
}) => {
  const [cities, setCities] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const loadCities = () => {
    setIsFetching(true);
    getCities({
      walletHash,
      cashdesk,
      language
    }).then((data) => {
      if (data.length > 0) {
        const cityList = data.map(({ id, name }) => ({
          value: String(id),
          name
        }));
        setCities(cityList);
        setValue(cityList[0].value);
      }
      setIsFetching(false);
    });
  };

  useEffect(() => {
    if (!cities.length && !isFetching) {
      loadCities();
    }
  }, []);

  return <SelectField options={cities} {...rest} />;
};
