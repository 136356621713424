import { useState, useEffect } from 'react';

const useDocuments = (documentsFunc, data, successCallback, errorCallback) => {
  const [exec, setExec] = useState(false);
  const [documentsData, setDocumentsData] = useState(data);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    (async () => {
      if (exec && documentsData) {
        setIsFetching(true);
        await documentsFunc(documentsData)
          .then(() => successCallback(documentsData))
          .catch(errorCallback);
        setIsFetching(false);
      }
    })();
  }, [exec, documentsData]);
  return {
    setExec,
    setDocumentsData,
    isFetching
  };
};

export default useDocuments;
