import { getGamesFeed } from '@namespace/games';
import { socketSend } from '@namespace/socket';
import { camelizeKeys } from 'humps';
import types from '../types';
import getStream from '../../api/getSream';
import isBetradarProvider from '../../utils/isBetradarProvider';

export default {
  [types.FETCH_VIRTUAL_STREAM]: (dispatch) => async ({
    partnerId,
    request = 1,
    protocol = 'hls',
    provider,
    eventId,
    tournamentId,
    categoryId,
    virtualClient
  }) => {
    if (isBetradarProvider(provider)) {
      dispatch({
        type: types.SET_VIRTUAL_STREAM,
        payload: {
          eventId,
          categoryId,
          provider,
          tournamentId
        }
      });
    } else {
      const streamData = await getStream({
        categoryId,
        partnerId,
        request,
        protocol,
        virtualClient
      });
      if (streamData.stream) {
        dispatch({
          type: types.SET_VIRTUAL_STREAM,
          payload: {
            url: streamData.stream.url,
            status: streamData.stream.status,
            provider,
            categoryId,
            tournamentId
          }
        });
      }
    }
  },

  [types.FETCH_VIRTUAL_EVENT_MARKETS]: (dispatch) => async ({
    eventId,
    provider,
    language
  }) => {
    const markets = await socketSend({
      cmd: 'do_proto_v1',
      data: {
        query: {
          provider_event: {
            variables: {
              language,
              provider,
              event_id: Number(eventId)
            }
          }
        }
      }
    });

    dispatch({
      type: types.SET_VIRTUAL_EVENT_MARKETS,
      payload: {
        markets: camelizeKeys(markets.data),
        provider,
        eventId
      }
    });
  },

  [types.GET_VIRTUAL_SPORTS_FEED]: (dispatch) => async ({
    feedName,
    platform,
    userSegments
  }) => {
    const data = await getGamesFeed({
      feedNames: feedName,
      platform,
      userSegments
    });

    dispatch({
      type: types.SET_VIRTUAL_SPORTS_FEED,
      payload: { data, platform, feedName }
    });
  },
  [types.SET_IS_VIRTUAL_SPORTS_FEED_LOADING]: (dispatch) => (isLoading) =>
    dispatch({
      type: types.SET_IS_VIRTUAL_SPORTS_FEED_LOADING,
      payload: { isLoading }
    })
};
