import { compositeValidator } from '../../validators';

const getValidator = (validator) => {
  let type = validator;
  let params = [];
  if (typeof validator === 'object') {
    type = validator.type;
    params = [...validator.parameters];
  }
  return [type, params];
};

const createValidationRules = (types = [], formValidationMap = {}) =>
  types.reduce((acc, type) => {
    if (Array.isArray(type)) {
      const validators = type.reduce((memo, validator) => {
        const [validatorType, params] = getValidator(validator);
        if (formValidationMap[validatorType]) {
          return [...memo, formValidationMap[validatorType](params)];
        }
        return memo;
      }, []);

      return {
        ...acc,
        ...compositeValidator(validators)
      };
    }

    const [validatorType, params] = getValidator(type);

    if (formValidationMap[validatorType]) {
      return {
        ...acc,
        ...formValidationMap[validatorType](...params)
      };
    }
    return acc;
  }, {});

export default createValidationRules;
