import { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useComponentConfig, useGeneralRoutes } from '@namespace/cms';
import { FILTER_ALL_VALUE } from '@namespace/common';
import { useLocation } from '@namespace/i18n';
import { IS_WIDGET } from '@namespace/widget';
import appInIframe from '@namespace/helpers/src/utils/appInIframe';
import { useSearchParams } from '@namespace/router';
import LineSport from '../LineSport';
import WidgetWrapper from '../WidgetWrapper';
import { useActiveHeadGroups } from '../../hooks/useActiveHeadGroups';
import useEventsTimeFilter from '../../hooks/useEventsTimeFilter';
import BreadCrumbs from '../BreadCrumbs';
import { LINE_KEY, MODE, SPORTS_LINE_TYPES } from '../../constants';
import { mapCategoriesToCategoryIds } from './utils';
import { EventsLineBaseHeader } from '../EventsLineBaseHeader';
import useChangeDefaultFilter from '../../hooks/useChangeDefaultFilter';
import useSportsSettings from '../../hooks/useSportsSettings';
import styles from './index.module.css';

const emptyObj = {};
const emptyArr = [];

const BasePreMatchEvents = ({
  lineKey,
  activeSport = emptyObj,
  backButtonSubTitle = '',
  categories = emptyArr,
  tournamentsIds = emptyArr,
  tournamentName = '',
  categoryIdName = emptyArr,
  isReady,
  EventsLineHeaderComponent = EventsLineBaseHeader,
  lineType,
  className = ''
}) => {
  const [isTodayFilterApplied, setIsTodayFilterApplied] = useState(false);
  const { categoryId: titleCategoryId, categoryName: titleCategoryName = '' } =
    categories.length === 1 ? categories[0] : {};
  const categoryIds = useMemo(() => mapCategoriesToCategoryIds(categories), [
    categories
  ]);
  const config = useComponentConfig();
  const isCustomFilterEvents = lineType === SPORTS_LINE_TYPES.CUSTOM_FILTER;
  const isChangeDefaultFilter = useChangeDefaultFilter();
  const { sportId, sportName, sportSlug } = activeSport || {};
  const { timeFilter } = useSearchParams();
  const { showBreadCrumbs } = useSportsSettings();
  const { pathname } = useLocation();
  const { preMatchEvent, liveEvent } = useGeneralRoutes();
  const isOneEventPage =
    pathname.includes(preMatchEvent) || pathname.includes(liveEvent);
  const {
    filters,
    isHeadGroupsSortingEnabled,
    activeHeadGroupsAmount
  } = config;
  const {
    isShowAll,
    isShowStartingSoon,
    isShowNextMorning,
    default: defaultFilter,
    ...filterConfig
  } = filters || {};
  const [activeHeadGroups, setActiveHeadGroups] = useActiveHeadGroups(
    isHeadGroupsSortingEnabled,
    activeHeadGroupsAmount,
    sportId,
    MODE.PRE_MATCH
  );

  const { filter, setFilter } = useEventsTimeFilter({
    initialFilter: {
      [defaultFilter]: defaultFilter
    },
    filterConfig,
    isUrlTimeFilterByLine: !isCustomFilterEvents
  });

  const handleDefaultFilterChange = useCallback(() => {
    if (isChangeDefaultFilter && !isTodayFilterApplied && !timeFilter) {
      setFilter({ [FILTER_ALL_VALUE]: FILTER_ALL_VALUE });
      setIsTodayFilterApplied(true);
    }
  }, [isChangeDefaultFilter, isTodayFilterApplied, setFilter, timeFilter]);

  const actualLineKey = lineKey || `${LINE_KEY.PRE_MATCH}-${sportId}`;
  const inIframe = appInIframe() && IS_WIDGET;

  return (
    <div
      className={clsx([
        !inIframe &&
          !isCustomFilterEvents &&
          !isOneEventPage &&
          styles.container,
        className
      ])}
    >
      <WidgetWrapper dataRole={`c-preMatchEvents ${actualLineKey}`}>
        <EventsLineHeaderComponent
          lineKey={actualLineKey}
          lineType={lineType}
          activeSport={activeSport}
          backButtonSubTitle={backButtonSubTitle}
          titleCategoryName={titleCategoryName}
          titleCategoryId={titleCategoryId}
          filter={filter}
          setFilter={setFilter}
          activeHeadGroups={activeHeadGroups}
          setActiveHeadGroups={setActiveHeadGroups}
          categories={categories}
          tournamentId={tournamentsIds[0]}
          serviceId={MODE.PRE_MATCH}
          dataRole="pre-match-events-line-header"
        />
        <LineSport
          lineType={lineType}
          filter={filter}
          defaultFilter={defaultFilter}
          activeHeadGroups={activeHeadGroups}
          isShowFlag={true}
          sport={activeSport}
          categoryIds={categoryIds}
          tournamentsIds={tournamentsIds}
          lineKey={actualLineKey}
          serviceId={MODE.PRE_MATCH}
          isExpanded={false}
          isShowEmpty={true}
          isTodayFilterApplied={isTodayFilterApplied}
          onDefaultFilterChange={handleDefaultFilterChange}
          isReady={isReady}
        />
      </WidgetWrapper>
      {sportName &&
        showBreadCrumbs &&
        !isCustomFilterEvents &&
        !isOneEventPage && (
          <BreadCrumbs
            categoryId={titleCategoryId}
            categoryName={titleCategoryName}
            categoryIdName={categoryIdName}
            sportName={sportName}
            sportSlug={sportSlug}
            tournamentsIds={tournamentsIds}
            tournamentName={tournamentName}
          />
        )}
    </div>
  );
};

export default BasePreMatchEvents;
