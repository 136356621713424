import { Icon } from '@namespace/icons2';
import { Box } from '@alf/uikit';
import styles from '../../index.module.css';

export const ExpandIcon = ({ setIsTabOpen, isTabOpen }) => (
  <Box align="center" onClick={setIsTabOpen} className={styles.filterIcon}>
    <Icon
      name={`icons/general/nav/${isTabOpen ? 'arrow-up' : 'arrow-down'}`}
      size="s"
      color="line_header_txt"
    />
  </Box>
);
