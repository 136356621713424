import { useCallback, useEffect } from 'react';
import { useI18n, LocalizedLink } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { clearRedirectAction } from '@namespace/common';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { HTMLSnippet } from '@namespace/snippets';
import { AText, Box } from '@alf/uikit';
import { useConfig, useGeneralRoutes } from '@namespace/cms';
import { GuestContent } from '@namespace/auth';
import PrivateContentLoader from '@namespace/user/src/common/PrivateContentLoader';
import { REGISTER_STEP_TYPES } from './constants';
import styles from './index.module.css';

const Success = ({ snippetId, snippetIdPaymentIcons = '' }) => {
  const { t } = useI18n();
  const { home } = useGeneralRoutes();

  return (
    <PrivateContentLoader>
      <Box direction="column" className={styles.wrapper}>
        {snippetId && <HTMLSnippet snippetId={snippetId} />}
        <LocalizedLink to={home} className={styles.continueButton}>
          <AText breed="BU" size="xl">
            {t('passwordRecovery.continueButton')}
          </AText>
        </LocalizedLink>
        {snippetIdPaymentIcons && (
          <HTMLSnippet snippetId={snippetIdPaymentIcons} />
        )}
      </Box>
    </PrivateContentLoader>
  );
};

const RegisterSuccess = ({ id }) => {
  const { push } = useHistory();
  const { config } = useConfig(id);
  const { isShowSupport = true, isWithCloseButton = true } = config;
  const { home } = useGeneralRoutes();
  const defaultSteps = [
    {
      type: REGISTER_STEP_TYPES.SUCCESS,
      config
    }
  ];

  const onClose = useCallback(() => {
    const redirectURL = clearRedirectAction();
    push(redirectURL || home);
  }, [home]);

  useEffect(() => logAction(LOG_ACTIONS.REGISTRATION_SUCCESS_SCREEN), []);

  return (
    <GuestContent
      config={{
        isShowSupport,
        isWithCloseButton,
        isShowStepLine: false,
        isShowStepLineOnSuccess: false,
        steps: defaultSteps
      }}
      componentsMap={{
        [REGISTER_STEP_TYPES.SUCCESS]: Success
      }}
      onClose={onClose}
      classNames={{ stepperWrapperClassName: styles.stepperWrapperClassName }}
    />
  );
};

export default RegisterSuccess;
