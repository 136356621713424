import Helmet from '../../common/Helmet';
import usePageMetaData from '../../hooks/usePageMetaData';

/* 
  need isWithOgElements in cases when needed replacers in "og:" like elements
  which HeaderLinks element can't provide
*/

const Meta = ({ replacers, isWithOgElements = false }) => {
  const { title, description } = usePageMetaData(replacers);

  return (
    <Helmet>
      {description && <meta name="description" content={description} />}
      {title && <title>{title}</title>}

      {isWithOgElements && description && (
        <meta property="og:description" content={description} />
      )}
      {isWithOgElements && title && (
        <meta property="og:title" content={title} />
      )}
    </Helmet>
  );
};

export default Meta;
