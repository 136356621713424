import { forwardRef } from 'react';
import { pathOr } from 'ramda';
import clsx from 'clsx';
import { Box } from '@alf/uikit';
import { Category } from './Category';
import { useCategories, useSportsbookStore } from '../../store';
import styles from './index.module.css';

const Categories = forwardRef(
  (
    {
      categoryRoute,
      serviceId,
      sportSlug,
      activeCategoryId,
      withIcon,
      classNames: { containerClassName = '', itemClassName = '' } = {}
    },
    ref
  ) => {
    const favoritesCategories = useSportsbookStore(
      pathOr([], ['favorites', 'categories'])
    );
    const categoriesData = useCategories(serviceId);

    const sortedCategories = Object.values(categoriesData)
      .map((category) => ({
        ...category,
        isPinned: favoritesCategories.includes(category.categoryId)
      }))
      .sort((a, b) => {
        if (a.isPinned || b.isPinned) {
          if (b.isPinned - a.isPinned !== 0) {
            return b.isPinned - a.isPinned;
          }
          return a.categoryWeigh - b.categoryWeigh;
        }

        return a.categoryWeigh - b.categoryWeigh;
      });

    return (
      <Box
        ref={ref}
        direction="column"
        className={clsx(styles.container, containerClassName)}
      >
        {sortedCategories.map(({ categoryId, isPinned }) => (
          <Category
            serviceId={serviceId}
            className={itemClassName}
            key={`sportMenuCategory-${categoryId}`}
            categoryId={categoryId}
            basePath={`${categoryRoute}/${sportSlug}`}
            isActive={categoryId === activeCategoryId}
            withIcon={withIcon}
            isPinned={isPinned}
          />
        ))}
      </Box>
    );
  }
);

export default Categories;
