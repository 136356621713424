import EventLabel from '../EventLabel';
import usePreMatchEventState from '../../hooks/usePreMatchEventState';
import styles from './index.module.css';

const EventTime = ({ eventId, lineView = '', isWithTZ, dataRole }) => {
  const { eventTime } = usePreMatchEventState(eventId, isWithTZ);

  return (
    <EventLabel
      dataRole={dataRole}
      text={eventTime}
      className={`${styles.time} ${lineView ? styles[lineView] : ''}`}
    />
  );
};

export default EventTime;
