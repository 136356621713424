export const ORYX_EVENTS = {
  LOAD_START: 'loadStart',
  LOAD_END: 'loadEnd',
  BALANCE_UPDATE: 'balance',
  SPIN_START: 'gameSpinStart',
  SPIN_END: 'ameSpinEnd',
  CASHIER: 'cashier',
  PLAYER_OUT_OF_MONEY: 'errorOutOfMoney',
  CLOSE: 'close'
};

export const oryxLauncherControl = (event, callbacks) => {
  const { onLaunchHandler = () => {}, onExitHandler = () => {} } = callbacks;

  if (event.data) {
    if (event.data.wpgaction === ORYX_EVENTS.LOAD_START) {
      onLaunchHandler();
    } else if (event.data.wpgaction === ORYX_EVENTS.CLOSE) {
      onExitHandler();
    }
  }
};
