import { useState, useContext, useEffect } from 'react';
import { UserContext } from '@namespace/user';
import {
  useAnimationFrame,
  COOKIES_KEYS,
  setCookie,
  getCookie,
  removeCookie
} from '@namespace/helpers';
import { useI18n } from '@namespace/i18n';

export const useLogOnTimer = () => {
  const { f } = useI18n();
  const [user] = useContext(UserContext);
  const { id = null } = user;
  const [time, setTime] = useState('');
  const DATE_FORMATS_TYPES = f.getFormats();

  useAnimationFrame(() => {
    const sessionStartTime = f.getDateTime({
      date: Number(getCookie(COOKIES_KEYS.LOG_ON))
    });
    if (!sessionStartTime) {
      setTime('');
      return;
    }
    const timeDiff = f
      .getDateTime()
      .diff(sessionStartTime)
      .toFormat(DATE_FORMATS_TYPES.FULL_TIME);

    if (timeDiff) {
      setTime(timeDiff);
    }
  }, 1000);

  useEffect(() => {
    if (id) {
      if (!getCookie(COOKIES_KEYS.LOG_ON)) {
        setCookie(COOKIES_KEYS.LOG_ON, f.getDateTime().toMillis(), {
          expires: 30
        });
      }
    } else {
      removeCookie(COOKIES_KEYS.LOG_ON);
    }
  }, [f, id]);

  return time;
};
