import Helmet from '../../../common/Helmet';
import { constructMicrodata } from './utils';

const BreadCrumbsMicrodata = ({ items = [] }) => {
  const breadCrumbs = constructMicrodata(items);
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(breadCrumbs)}</script>
    </Helmet>
  );
};

export default BreadCrumbsMicrodata;
