/* eslint-disable no-underscore-dangle */
import uuid from 'uuid/v4';
import { omit } from 'ramda';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { isFunction } from 'lodash';
import { EventEmitter } from '../EventEmitter';

// import { collectSocketData } from '@namespace/helpers/src/utils/collectData';

const maybeArray = (a) => (Array.isArray(a) ? a : [a]);

export class SocketClient {
  constructor() {
    this._ev = new EventEmitter();
  }

  connect({
    url,
    connectionHandler = () => {},
    isOkPing = false,
    withCid = true,
    messages = [],
    socketParams = {},
    pingDelay = 50000,
    maxReconnectionAttempts = 10
  }) {
    this.url = url;
    this.isOkPing = isOkPing;
    this.withCid = withCid;
    this.pingDelay = pingDelay;
    this.initMessages = messages;
    this.maxRetries = maxReconnectionAttempts;
    this.connectionHandler = connectionHandler;

    this.connection = new ReconnectingWebSocket(() => this.url, null, {
      minReconnectionDelay: 3000,
      maxReconnectionDelay: 15000,
      connectionTimeout: 10000,
      maxRetries: maxReconnectionAttempts,
      ...socketParams
    });

    this.connection.onclose = this.onClose;
    this.connection.onerror = this.onError;
    this.connection.onopen = this.onOpen;
    this.connection.onmessage = this.onMessage;

    const pingMessage = this.isOkPing
      ? { ping: 'ok' }
      : { cid: 'ping', cmd: 'ping' };
    setInterval(() => this.send(pingMessage), this.pingDelay);

    return this;
  }

  subscribers = [];

  sentMessages = {};

  beforeConnectionOpenMessages = [];

  isOpen = false;

  isFirstOpen = true;

  setSubscribers(subscribers) {
    this.subscribers = subscribers;
  }

  setUrl(newUrl) {
    this.url = newUrl;
  }

  setSentMessages(sentMessages) {
    this.sentMessages = sentMessages;
  }

  setBeforeConnectionOpenMessages(messages) {
    this.beforeConnectionOpenMessages = messages;
  }

  defaultConnectionHandler(type, ...rest) {
    this._ev.trigger(type);

    if (this.connection) {
      console.log(
        `connection ${type}: ready state ${this.connection.readyState}, url ${this.connection.url}`
      );
      this.connectionHandler(this.connection.readyState, type, ...rest);
    }
  }

  onOpen = () => {
    this.isOpen = true;
    this.defaultConnectionHandler('open');
    // if (this.withCid) {
    //   this.send({ async: true });
    // }
    for (const msg of [
      ...this.beforeConnectionOpenMessages,
      ...this.initMessages,
      ...this.subscribers.filter((s) => s.message).map((s) => s.message)
    ]) {
      this.send(msg);
    }

    if (this.isFirstOpen) {
      this.isFirstOpen = false;
      if (!this.withCid) {
        this.setBeforeConnectionOpenMessages([]);
      }
    }
  };

  onClose = (event) => {
    this.isOpen = false;
    this.defaultConnectionHandler('close', event);
  };

  onError = (error) => {
    this.defaultConnectionHandler('error', error);
  };

  onMessage = (message) => {
    try {
      const { data } = message;
      const parsedData = JSON.parse(data);
      const { cid, ok, error } = parsedData;
      const sentMessage = this.sentMessages[cid]; // * sentMessages

      if (sentMessage) {
        if (ok) {
          sentMessage.resolve(parsedData);
        } else {
          sentMessage.reject(error);
        }
        this.setSentMessages(omit([cid], this.sentMessages));
      }

      // todo if it can be array - why `this.sentMessages` is not aware of it?
      for (const mes of maybeArray(parsedData)) {
        for (const { types, callback } of this.subscribers) {
          if (
            types.includes(mes.cid) ||
            types.includes(mes.type) ||
            types.includes(mes.event) ||
            (mes.data && types.includes(Object.keys(mes.data)[0])) ||
            types === 'all'
          ) {
            // TODO: uncomment when need to collect some data from sockets to use as mocks
            // collectSocketData(this.url, mes);
            callback(mes);
          }
        }
        if (this.withCid && this.beforeConnectionOpenMessages.length) {
          this.setBeforeConnectionOpenMessages(
            this.beforeConnectionOpenMessages.filter((m) => m.cid !== mes.cid)
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  send(params) {
    if (
      !this.connection ||
      this.connection.readyState !== ReconnectingWebSocket.OPEN
    ) {
      return new Promise((resolve) => {
        this._ev.once('open', resolve);
        // todo It's unclear right now if we should reject unsent messages at all. It seems, there's only one case when it needs to be rejected - when socket has finished all reconnection attempts and presumed finally dead. This, however, could interfere with site logic and prevent showing correct error page. Yet if we leave unsent messages waiting for connection we'll still have a chance to restore site in working condition later by calling this.connection.reconnect(). But at the cost of potential memory leaks due to hanging unsent messages.
        // this._ev.once('error', reject);
      }).then(() => this.send(params));
    }

    return new Promise((resolve, reject) => {
      try {
        let message = isFunction(params) ? params() : params;
        const cid = message.cid || uuid();
        message = {
          cmd: this.withCid ? message.cmd : undefined,
          cid: this.withCid ? cid : undefined,
          ...message
        };

        if (this.connection && this.isOpen) {
          this.connection.send(JSON.stringify(message));
        } else if (this.isFirstOpen) {
          this.setBeforeConnectionOpenMessages([
            ...this.beforeConnectionOpenMessages,
            message
          ]);
        }

        if (this.withCid && !this.sentMessages[message.cid]) {
          this.setSentMessages({
            ...this.sentMessages,
            [message.cid]: {
              resolve,
              reject
            }
          });
        }
      } catch (e) {
        console.warn(e);
      }
    });
  }

  subscribe(newSubscriber = {}) {
    const { name, types, callback, message } = newSubscriber;
    if (name && types && isFunction(callback)) {
      const subscribersWithoutCurrent = this.subscribers.filter(
        (s) => s.name !== name
      );
      this.setSubscribers([
        ...subscribersWithoutCurrent,
        {
          name,
          types,
          callback,
          message
        }
      ]);
    }

    if (message) {
      this.send(message);
    }
  }

  unsubscribe(name) {
    this.setSubscribers(this.subscribers.filter((s) => s.name !== name));
  }
}
