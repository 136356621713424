import useLangMatch from '../useLangMatch';

const useRouteParams = (path = '/') => {
  const { params = {} } = useLangMatch({
    path
  });

  return params;
};

export default useRouteParams;
