import CyberSportSportItem from '../CyberSportSportItem';
import CyberSportCategories from '../CyberSportCategories';
import { useCyberSportCategory } from '../../store';
import styles from './index.module.css';

export const CyberSportBaseMenuListItem = ({
  categoryId,
  tournamentId,
  activeSportId,
  isVisibleCurrentCategories,
  setIsVisibleCurrentCategories,
  showCategories,
  basePath,
  countable,
  showPinIcon,
  categoryRoute,
  categoriesWithIcon
}) => {
  const getIsSameSport = (sportId) => sportId === activeSportId;
  const item = useCyberSportCategory(categoryId);
  const { sportId, sportSlug } = item;
  const isSameSport = getIsSameSport(sportId);

  return (
    <>
      <CyberSportSportItem
        sport={item}
        isExpanded={isVisibleCurrentCategories}
        toggleMenu={() =>
          setIsVisibleCurrentCategories(
            isSameSport ? !isVisibleCurrentCategories : true
          )
        }
        basePath={basePath}
        isActive={isSameSport}
        withCounter={countable && (!isSameSport || !showCategories)}
        withExpandIcon={isSameSport && showCategories}
        showPinIcon={showPinIcon}
      />
      {showCategories && isSameSport && isVisibleCurrentCategories && (
        <div
          data-role={`categories-list-${sportId}`}
          className={styles.wrapper}
        >
          <CyberSportCategories
            activeTournamentId={tournamentId}
            categoryRoute={categoryRoute}
            categoryId={sportSlug}
            withIcon={categoriesWithIcon}
          />
        </div>
      )}
    </>
  );
};
