import { lazy, Suspense } from 'react';
import Loader from '../Loader';

const Player = lazy(() => import('./Player'));

const VideoPlayer = ({
  streamUrl,
  autoplay = true,
  width = '',
  height = '',
  className = ''
}) => (
  <Suspense fallback={<Loader />}>
    <Player
      streamUrl={streamUrl}
      autoplay={autoplay}
      width={width}
      height={height}
      className={className}
    />
  </Suspense>
);

export default VideoPlayer;
