import { useCallback } from 'react';
import {
  useDefineDepositPath,
  useLudomanInVerification
} from '@namespace/user';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { useOtherSettings } from '@namespace/cms';
import { Box, Button } from '@alf/uikit';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import styles from './index.module.css';

export const DepositButton = ({
  id,
  className = '',
  customDepositPath,
  place = 'header-deposit',
  ...rest
}) => {
  const { t } = useI18n();
  const depositPath = useDefineDepositPath();
  const otherSettings = useOtherSettings();
  const { wideDepositButton = false } = otherSettings;
  const {
    showNotification: showLudomanNotification
  } = useLudomanInVerification();
  const handleBoxClick = useCallback(() => {
    showLudomanNotification();
    logAction(LOG_ACTIONS.DEPOSIT_CLICK_HEADER);
  }, [showLudomanNotification]);

  const buttonProps = {
    component: LocalizedLink,
    size: 's',
    to: customDepositPath || depositPath,
    actionType: 'color1',
    intent: 'primary',
    className: `${styles.depositButton} ${
      wideDepositButton ? styles.wideDepositButton : ''
    } ${className}`,
    ...rest
  };

  return (
    <Box
      data-role={`${place}-depositButton`}
      className={`${styles.buttonWrapper}`}
      onClick={handleBoxClick}
    >
      <Button {...buttonProps}>{t('personalOffice.deposit')}</Button>
    </Box>
  );
};
