import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { isEmpty, kebabCase } from 'lodash';
import { Icon } from '@namespace/icons2';
import { COLOR_SCHEME } from '@namespace/themes';
import { document } from '@namespace/helpers';
import EmptyState from '../../../../../EmptyState';
import Box from '../../../../../Box';
import AText from '../../../../../AText';
import styles from './index.module.css';

export const SelectContent = ({
  value,
  options = [],
  size = 'xs',
  onClick,
  onClose,
  additionSelectContentStyle = '',
  additionSelectTitleStyle = '',
  additionSelectOptionStyle = { item: '', active: '' },
  intentType,
  selectType,
  hasCheck,
  emptyText,
  setIsOpen,
  setSearchValue,
  inputRef,
  selectedItem,
  setSelectedItem,
  dataRole,
  customOptionColor
}) => {
  const optionColor = customOptionColor || 'input_dropdown_txt_2';
  const handleBlur = useCallback(() => {
    setIsOpen(false);
    setSelectedItem(-1);
    setSearchValue('');
    if (inputRef.current) {
      inputRef.current.blur();
    }
  }, [inputRef, setIsOpen, setSearchValue, setSelectedItem]);

  const scroll = () => {
    const section = document.querySelector('div[class *= selectedItem]');

    return section?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  };

  useEffect(() => {
    if (options.length === 1) {
      setSelectedItem(0);
    }
  }, [options, setSelectedItem]);

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === 'ArrowDown' && selectedItem < options.length - 1) {
        event.preventDefault();
        setSelectedItem((prev) => prev + 1);
        scroll();
      }

      if (event.key === 'ArrowUp' && selectedItem > 0) {
        event.preventDefault();
        setSelectedItem((prev) => prev - 1);
        scroll();
      }

      if ((event.key === 'Enter' || event.key === 'Tab') && selectedItem >= 0) {
        event.preventDefault();
        onClick(options[selectedItem]);
        handleBlur();
      }

      if (event.key === 'Escape') {
        handleBlur();
      }
    },
    [setSelectedItem, selectedItem, options, handleBlur, onClick]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  if (isEmpty(options)) {
    return (
      <EmptyState
        text={emptyText}
        classNames={{ containerClassName: styles.containerClassName }}
      />
    );
  }

  return (
    <Box
      data-role={dataRole && `select-content-${dataRole}`}
      direction="column"
      onKeyDown={handleKeyPress}
      className={clsx([
        styles.root,
        styles[intentType],
        additionSelectContentStyle,
        styles[size]
      ])}
    >
      {options.map((option, index) => {
        const isActive = option?.value === value;
        const handleClick = () => {
          onClick(option);
          onClose && onClose();
          setIsOpen(false);
        };

        return (
          <Box
            data-role={`option-${kebabCase(option.value)}`}
            key={`${option.title}-${option.value}`}
            align="center"
            onClick={handleClick}
            className={clsx([
              styles.option,
              styles[intentType],
              styles[selectType],
              additionSelectOptionStyle.item,
              isActive && hasCheck && styles.active,
              isActive && !hasCheck && additionSelectOptionStyle.active,
              index === selectedItem &&
                (additionSelectOptionStyle.active || styles.selectedItem)
            ])}
          >
            {option?.iconElement && option.iconElement}
            <AText
              breed={isActive ? 'B' : 'R'}
              color={option.hasNtf ? 'input_error_txt' : optionColor}
              className={clsx([
                additionSelectTitleStyle,
                isActive && additionSelectOptionStyle.activeTitle,
                styles.title,
                styles[intentType]
              ])}
            >
              {option.ntf ? `${option.title} ${option.ntf}` : option.title}
            </AText>
            {isActive && hasCheck && (
              <Icon
                name="icons/general/action/done"
                color="state_success"
                size={size === 'xs' ? 's' : size}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

SelectContent.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  size: PropTypes.oneOf(['xs', 's', 'm']),
  intentType: PropTypes.oneOf([COLOR_SCHEME.PRIMARY, COLOR_SCHEME.SECONDARY])
};
