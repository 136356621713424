import { LazyLoadImage } from '@alf/uikit';
import { getSlideImage } from '@namespace/cms';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { useGetImage } from '@namespace/themes';
import { forwardRef, useState } from 'react';
import styles from './index.module.css';

const ImageTeaser = forwardRef(
  ({ slide = {}, className = '', handleClickOnMove, currency, gap }, ref) => {
    const { url } = slide;
    const { language } = useI18n();
    const [isLoaded, setIsLoaded] = useState(false);
    const getImg = useGetImage();

    return (
      <LocalizedLink
        to={url}
        ref={ref}
        onClick={handleClickOnMove}
        className={className}
        style={gap && { marginRight: gap }}
      >
        <LazyLoadImage
          src={getSlideImage(slide, language, currency, getImg)}
          className={`${isLoaded ? styles.show : styles.hide}`}
          onLoad={() => setIsLoaded(true)}
        />
        <div
          className={`${!isLoaded ? styles.show : styles.hide} ${
            styles.skeleton
          }`}
        />
      </LocalizedLink>
    );
  }
);

export default ImageTeaser;
