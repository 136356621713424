import WelcomeStep from './components/Welcome';
import PersonalInfo from './components/PersonalInfo';
import SecretQuestion from './components/SecretQuestion';
import SuccessBonus from './components/SuccessBonus';
import Single from './components/Single';

export const REGISTER_STEPS_TYPES = {
  WELCOME: 'welcome',
  PERSONAL_INFO: 'personal-info',
  SECRET_QUESTION: 'secret-question',
  SUCCESS_BONUS_SCREEN: 'success-bonus-screen',
  SINGLE: 'single'
};

export const registerStepsMap = {
  [REGISTER_STEPS_TYPES.WELCOME]: WelcomeStep,
  [REGISTER_STEPS_TYPES.PERSONAL_INFO]: PersonalInfo,
  [REGISTER_STEPS_TYPES.SECRET_QUESTION]: SecretQuestion,
  [REGISTER_STEPS_TYPES.SUCCESS_BONUS_SCREEN]: SuccessBonus,
  [REGISTER_STEPS_TYPES.SINGLE]: Single
};
