import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Icon } from '@namespace/icons2';
import AText from '../AText';
import Box from '../Box';
import styles from './index.module.css';

export const iconSizeMap = {
  xs: 'm',
  s: 'm',
  m: '2xl',
  l: '3xl'
};

export const pinIconSizeMap = {
  xs: 'xs',
  s: 'xs',
  m: 's',
  l: 'm'
};

const GridItem = ({
  className,
  component: Component,
  text = '',
  iconName,
  size,
  actionType,
  count,
  isPinned,
  isActive,
  isFavorite,
  to,
  Image,
  dataRole,
  ...props
}) => {
  const componentProps = to
    ? { activeClassName: styles.active, to, ...props }
    : { ...props };
  const classes = clsx([
    styles.root,
    styles[size],
    styles[actionType],
    isActive && styles.active,
    isFavorite && styles.favorite,
    className
  ]);

  return (
    <Component className={classes} {...componentProps}>
      <Box
        data-role={dataRole}
        direction="column"
        align="center"
        justify="center"
        className={styles.container}
      >
        {Boolean(count) && (
          <AText breed="B" className={styles.count}>
            {count}
          </AText>
        )}
        {isPinned && (
          <Icon
            name="icons/general/state/pushpin-on"
            size={pinIconSizeMap[size]}
            className={styles.pin}
          />
        )}
        {Image ||
          (Boolean(iconName) && (
            <Icon
              name={iconName}
              size={iconSizeMap[size]}
              className={styles.icon}
            />
          ))}
        {text && (
          <Box align="center" justify="center" className={styles.textWrap}>
            <AText
              breed="BU"
              align="center"
              breakWord="normal"
              className={styles.text}
            >
              {text}
            </AText>
          </Box>
        )}
      </Box>
    </Component>
  );
};

GridItem.defaultProps = {
  component: 'div',
  size: 'xs',
  actionType: 'color1',
  isPinned: false,
  isActive: false,
  className: ''
};

GridItem.propTypes = {
  className: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  size: PropTypes.oneOf(['xs', 's', 'm', 'l']),
  actionType: PropTypes.oneOf(['color1', 'color2', 'color3']),
  text: PropTypes.string,
  to: PropTypes.string,
  iconName: PropTypes.string,
  count: PropTypes.number,
  isPinned: PropTypes.bool,
  isActive: PropTypes.bool,
  isFavorite: PropTypes.bool
};

export default GridItem;
