import { useCallback } from 'react';
import { useOtherSettings, useCookieManagementEnabled } from '@namespace/cms';
import { getLocalStorageValue, LOCAL_STORAGE_KEYS } from '@namespace/helpers';
import { saveUser } from '../../api';

const useSaveUser = () => {
  const cookieManagementEnabled = useCookieManagementEnabled();
  const { cookieManagement } = useOtherSettings();
  const { disableSendingAffiliateCookies = false } = cookieManagement || {};
  const affiliateInSessionStorage = getLocalStorageValue(
    LOCAL_STORAGE_KEYS.AFFILIATE_COOKIES
  );
  const affiliateParams =
    cookieManagementEnabled &&
    !disableSendingAffiliateCookies &&
    affiliateInSessionStorage
      ? affiliateInSessionStorage
      : null;

  return useCallback(
    async (params) =>
      saveUser({
        ...params,
        affiliateParams
      }),
    [affiliateParams]
  );
};

export default useSaveUser;
