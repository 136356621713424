import { useCallback, useEffect, useRef } from 'react';

const useAnimationFrame = (callback, delay = 0) => {
  const requestRef = useRef(null);
  const previousTimeRef = useRef(null);
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const animate = useCallback(
    (time) => {
      if (previousTimeRef.current) {
        const deltaTime = time - previousTimeRef.current;
        if (deltaTime >= delay) {
          savedCallback.current(deltaTime);
          previousTimeRef.current = time;
        }
      } else {
        previousTimeRef.current = time;
      }
      requestRef.current = requestAnimationFrame(animate);
    },
    [callback, delay]
  );

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [animate]);
};

export default useAnimationFrame;
