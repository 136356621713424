import { Box, Carousel, TRANSITION_CONVERT_TYPES } from '@alf/uikit';
import { PromotionsItemWrapper } from '../../../../../common/PromotionsItemWrapper';
import styles from './index.module.css';

const PromoCarousel = ({ customClassNames, promosList = [] }) => (
  <Carousel
    horizontal={true}
    circular={false}
    overflow="false"
    navigation={{
      arrows: false
    }}
    slider={{
      slider: `${styles.slider}`
    }}
    autoPlay={false}
    animationDuration={200}
    circularSlidesCount={1000}
    moveType={[TRANSITION_CONVERT_TYPES.strict, { count: 1 }]}
  >
    {promosList.map((promo, index) => (
      <Box className={styles.sliderWrapper} key={promo.promoId}>
        <PromotionsItemWrapper
          promo={promo}
          index={index + 1}
          tagPath="all"
          gap="8px"
          customClassNames={customClassNames}
        />
      </Box>
    ))}
  </Carousel>
);

export default PromoCarousel;
