import { types } from '../types';
import { getClubHistory } from '../../../api/getClubHistory';
import { getUserBonusInfo } from '../../../api/getUserBonusInfo';

export const actions = {
  [types.FETCH_PLAYER_DATA]: (dispatch) => async () => {
    const {
      bonusMonthHistory,
      bonusWallet,
      loyalty
    } = await getUserBonusInfo();
    const { response: bonusMonthHistoryData } = bonusMonthHistory;

    dispatch({
      type: types.SET_PLAYER_DATA,
      payload: {
        bonusMonthHistory: bonusMonthHistoryData,
        bonusWallet,
        loyalty,
        isFetching: false
      }
    });
  },
  [types.FETCH_PURCHASE_HISTORY]: (dispatch) => async (filter) => {
    const data = await getClubHistory(filter);
    dispatch({
      type: types.SET_PURCHASE_HISTORY,
      payload: { data, isFetching: false }
    });
  },
  [types.FETCH_SCORE_HISTORY]: (dispatch) => async (filter) => {
    const data = await getClubHistory(filter);
    dispatch({
      type: types.SET_SCORE_HISTORY,
      payload: { data, isFetching: false }
    });
  }
};
