import { BONUS_STATE_TYPES } from '../../../../constants';

const bonustTypesToCountersData = (types, counterValues) =>
  types.reduce((acc, i, idx) => {
    const key = i.types ?? i;
    const value = counterValues[idx];

    if (Array.isArray(key)) {
      return {
        ...acc,
        ...bonustTypesToCountersData(key, value)
      };
    }

    return {
      ...acc,
      [BONUS_STATE_TYPES[key]]: value
    };
  }, {});

export default bonustTypesToCountersData;
