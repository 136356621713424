import { useEffect, useRef } from 'react';
import {
  arrow,
  autoUpdate,
  offset,
  shift,
  useFloating
} from '@floating-ui/react-dom';
import { useTargetEls } from '../../hooks';
import { Arrow } from './Arrow';
import { autoPlacement, keepInView } from './middleware';
import { useCurrentStep } from '../../../../hooks';
import styles from './index.module.css';

export const Floater = ({
  children,
  allowedPlacements,
  preferredPlacements,
  tooltipAlignment
}) => {
  const arrowRef = useRef();
  const { padding = 0 } = useCurrentStep();
  const {
    x,
    y,
    reference,
    floating,
    strategy,
    placement,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} }
  } = useFloating({
    placement: 'bottom',
    middleware: [
      offset(12 + padding),
      shift({ padding: 8 }),
      autoPlacement({
        allowedPlacements,
        preferredPlacements,
        alignment: tooltipAlignment
      }),
      keepInView(),
      arrow({ element: arrowRef, padding: 12 })
    ],
    whileElementsMounted: autoUpdate
  });
  const els = useTargetEls();

  useEffect(() => {
    if (els?.[0]) {
      reference(els[0]);
    }
  }, [els, reference]);

  const style = {
    position: strategy,
    top: y ?? 0,
    left: x ?? 0
  };

  const staticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right'
  }[placement.split('-')[0]];

  const arrowStyle = {
    left: arrowX != null ? `${arrowX}px` : '',
    top: arrowY != null ? `${arrowY}px` : '',
    right: '',
    bottom: '',
    [staticSide]: 'calc(var(--arrowSize) / -2)'
  };

  // this fixes popup blinking to top left corner between heavy updates such as page navigation
  if (
    (style.left === 0 && style.top === 0) ||
    style.left < 0 ||
    style.top < 0
  ) {
    style.visibility = 'hidden';
  }

  return (
    <div ref={floating} style={style}>
      <Arrow ref={arrowRef} className={styles.arrow} style={arrowStyle} />
      {children}
    </div>
  );
};
