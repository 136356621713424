import { useContext, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { USER_ROLES, UserContext } from '@namespace/user';
import { useGetExcludedWagerGamesIdList } from '@namespace/games';
import { window } from '@namespace/helpers';
import { STORE_NAMES } from '@namespace/helpers-store';
import { useGetActivePromoType } from '../../hooks';
import { PROMO_TYPES } from '../../constants';
import {
  promotionsActions,
  useGetActiveTournamentsIds,
  useHasTournaments,
  usePromotionsStore
} from '../../store';

const {
  FETCH_PROMOTIONS,
  FETCH_TOURNAMENTS_SERVICE_INFO,
  RESET_TOURNAMENTS_SERVICE_INFO
} = promotionsActions;

const PromotionsWrapper = () => {
  const [user] = useContext(UserContext);
  const isTournamentsLoaded = usePromotionsStore('isTournamentsLoaded');
  const isTournamentsLoading = usePromotionsStore('isTournamentsLoading');
  const hasTournaments = useHasTournaments();
  const activeTournamentsIds = useGetActiveTournamentsIds();
  const activePromoType = useGetActivePromoType();
  const isTournamentsPage = activePromoType === PROMO_TYPES.tournament;
  const isLoggedInUser = user.role === USER_ROLES.USER;

  useGetExcludedWagerGamesIdList();

  useEffect(() => {
    // fetch all promo types (promo/tournaments/bonuses) from CMS
    if (!window.initialStoresState?.[STORE_NAMES.PROMOTIONS]) {
      FETCH_PROMOTIONS();
    }

    return () => {
      RESET_TOURNAMENTS_SERVICE_INFO();
    };
  }, []);

  useEffect(() => {
    if (
      isTournamentsPage &&
      !isEmpty(activeTournamentsIds) &&
      !isTournamentsLoaded &&
      !isTournamentsLoading &&
      isLoggedInUser
    ) {
      FETCH_TOURNAMENTS_SERVICE_INFO(activeTournamentsIds);
    }
  }, [
    isTournamentsPage,
    isTournamentsLoading,
    isTournamentsLoaded,
    activeTournamentsIds,
    isLoggedInUser
  ]);

  useEffect(() => {
    if (!isLoggedInUser && hasTournaments) {
      RESET_TOURNAMENTS_SERVICE_INFO();
    }
  }, [hasTournaments, isLoggedInUser]);

  return null;
};

export default PromotionsWrapper;
