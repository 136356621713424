import { useContext } from 'react';
import { Box } from '@alf/uikit';
import { ThemesContext } from '@namespace/themes';
import {
  LOCAL_STORAGE_KEYS,
  setLocalStorageValue,
  useSetBodyClass
} from '@namespace/helpers';
import { get } from 'lodash';
import { useOtherSettings } from '@namespace/cms';
import ThemesSelect from './components/ThemesSelect';
import styles from './index.module.css';

const ThemesSwitcher = ({ id }) => {
  const [{ colorScheme }, { SET_COLOR_SCHEME }] = useContext(ThemesContext);
  const otherSettings = useOtherSettings();
  const availableThemes = get(otherSettings, 'themes.availableThemes', []);
  const { setBodyClass, removeBodyClass } = useSetBodyClass();

  const setCssTheme = ({ value: theme }) => {
    removeBodyClass(colorScheme.activeTheme);

    setBodyClass(theme);
    setLocalStorageValue(LOCAL_STORAGE_KEYS.CSS_THEME, theme);
    SET_COLOR_SCHEME({ activeTheme: theme });
  };

  if (availableThemes.length === 0) {
    return null;
  }

  return (
    <Box justify="center" className={`${styles.wrapper}`}>
      <ThemesSelect
        data-role="theme-switcher"
        activeTheme={colorScheme.activeTheme}
        onChange={setCssTheme}
        availableThemes={availableThemes}
        classNames={{
          containerClass: styles.select,
          triggerContentClass: styles.triggerContent,
          triggerContentTitleClass: styles.triggerContentTitle,
          arrowColor: styles.arrowColor
        }}
        id={id}
      />
    </Box>
  );
};

export default ThemesSwitcher;
