import { getJackpotsBonusesCount } from '../../../bonusesCount';
import { getBonusSystemBonusCount } from '../../../getBonusSystemBonusCount';
import {
  OTHER_BONUS_TYPES,
  BONUS_SYSTEM_BONUS_TYPE
} from '../../../../constants';

import accountingBonusesCountResponse from '../accountingBonusesCountResponse';

const bonusTypeToServiceMap = {
  ACCOUNTING_BONUS_TYPES: (props) => accountingBonusesCountResponse(props),
  [OTHER_BONUS_TYPES.JACKPOT_WINS]: () => getJackpotsBonusesCount(),
  [BONUS_SYSTEM_BONUS_TYPE]: () => getBonusSystemBonusCount()
};

const collectBonusCountersRequests = (types, { userId } = {}) => {
  const result = types.reduce((acc, i) => {
    const key = i.value ?? i;

    let props;

    if (i.types) {
      props = {
        types: i.types,
        userId
      };
    }

    const makeServiceFn = bonusTypeToServiceMap[key];
    const serviceFn = makeServiceFn(props);

    return acc.concat(serviceFn);
  }, []);

  return result;
};

export default collectBonusCountersRequests;
