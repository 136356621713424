import { useContext, useEffect } from 'react';
import { path } from 'ramda';
import { SiteSettingsContext } from '@namespace/cms';
import { USER_ROLES, UserContext } from '@namespace/user';
import { useI18n } from '@namespace/i18n';
import { MODE } from '../../../../../../constants';
import {
  advFilterActions,
  useAdvFilterStore
} from '../../../../../../storeAdvFilter';

const { FETCH_ADV_FILTER_SPORTS } = advFilterActions;

export const useFetchSports = () => {
  const { language } = useI18n();
  const [{ role }] = useContext(UserContext);
  const partnerId = path([0, 'siteConfig', 'partnerId'])(
    useContext(SiteSettingsContext)
  );
  const isAdvFilterSportsLoaded = useAdvFilterStore('isAdvFilterSportsLoaded');

  useEffect(() => {
    if (role !== USER_ROLES.USER) {
      return;
    }

    if (isAdvFilterSportsLoaded) {
      return;
    }

    FETCH_ADV_FILTER_SPORTS({
      lang: language,
      serviceId: MODE.PRE_MATCH,
      partnerId
    });
  }, [isAdvFilterSportsLoaded, language, partnerId, role]);
};
