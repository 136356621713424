import { window } from '@namespace/helpers';
import {
  FORM_FACTORS_MAP,
  MOBILE_DEVICES,
  TABLET_DEVICES
} from '../../constants';

const detectFormFactor = () => {
  const { userAgent } = window.navigator;

  const isMobileDevice = MOBILE_DEVICES.test(userAgent);
  const isTabletDevice = TABLET_DEVICES.test(userAgent);
  const isDesktopDevice = !(isMobileDevice || isTabletDevice);

  let deviceFormFactor = FORM_FACTORS_MAP.DESKTOP;

  if (isMobileDevice) {
    deviceFormFactor = FORM_FACTORS_MAP.MOBILE;
  } else if (isTabletDevice) {
    deviceFormFactor = FORM_FACTORS_MAP.TABLET;
  }

  return {
    deviceFormFactor,
    isMobileDevice,
    isTabletDevice,
    isDesktopDevice
  };
};

export default detectFormFactor;
