import {
  ACCOUNTING_BONUS_TYPES,
  OTHER_BONUS_TYPES,
  BONUS_SYSTEM_BONUS_TYPE
} from '../../../../constants';

const groupTypes = (items) => {
  const accountingBonusesTypesValues = Object.values(ACCOUNTING_BONUS_TYPES);
  const accountingBonusesTypes = items.filter((bonusType) =>
    accountingBonusesTypesValues.includes(bonusType)
  );
  const hasJackpot = items.includes(OTHER_BONUS_TYPES.JACKPOT_WINS);
  const hasBonusSystem = items.includes(BONUS_SYSTEM_BONUS_TYPE);
  const result = [
    accountingBonusesTypes.length && {
      value: 'ACCOUNTING_BONUS_TYPES',
      types: accountingBonusesTypes
    },
    hasJackpot && OTHER_BONUS_TYPES.JACKPOT_WINS,
    hasBonusSystem && BONUS_SYSTEM_BONUS_TYPE
  ].filter(Boolean);

  return result;
};

export default groupTypes;
