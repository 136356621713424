import { useContext } from 'react';
import { UserContext } from '@namespace/user/src/context';
import { USER_ROLES } from '@namespace/user/src/constants';
import { DeviceContext } from '@namespace/device';
import { LocalizedNavLink, useI18n } from '@namespace/i18n';
import { useGeneralRoutes } from '@namespace/cms';
import { Box, GridItem } from '@alf/uikit';
import { getSportIcon } from '@namespace/icons2';
import { getPathToSports } from '@namespace/helpers';
import { SportSliderSkelet } from './components/SportsSliderSkelet';
import styles from './index.module.css';

export const SportsSlider = ({
  items,
  routeBase,
  routeAll,
  routeFavorites,
  isShowAll,
  isWithPadding,
  favoritesCount,
  allCount,
  allSportIcon,
  allSportsTKey = 'sportsBook.menu.allSports',
  favoritesTKey = 'sportsbook.favorites'
}) => {
  const [{ role }] = useContext(UserContext);
  const { isMobile } = useContext(DeviceContext);
  const { t } = useI18n();
  const { cyberSport } = useGeneralRoutes();

  if (!items.length) {
    return <SportSliderSkelet items={12} />;
  }

  return (
    <Box
      padding={isWithPadding ? 'm s' : 's'}
      className={styles.container}
      gap="xs"
    >
      {isShowAll && (
        <GridItem
          component={LocalizedNavLink}
          to={routeAll}
          count={allCount}
          iconName={allSportIcon ?? getSportIcon(null)}
          text={t(allSportsTKey)}
          actionType="color2"
          size={isMobile ? 'xs' : 'm'}
          dataRole="live-burger-all-item"
        />
      )}
      {role === USER_ROLES.USER && (
        <GridItem
          component={LocalizedNavLink}
          to={routeFavorites}
          count={favoritesCount}
          iconName="icons/general/state/favorite-on"
          text={t(favoritesTKey)}
          actionType="color2"
          size={isMobile ? 'xs' : 'm'}
          className={styles.favoritesLink}
          isFavorite={true}
        />
      )}

      {items.map(({ sportId, sportName, sportSlug, eventCount, isPinned }) => (
        <GridItem
          key={sportId}
          component={LocalizedNavLink}
          to={getPathToSports(`${routeBase}/${sportSlug}`, cyberSport, sportId)}
          count={eventCount}
          iconName={getSportIcon(sportId)}
          text={sportName}
          actionType="color2"
          size={isMobile ? 'xs' : 'm'}
          isPinned={isPinned}
        />
      ))}
    </Box>
  );
};
