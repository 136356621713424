import { memo } from 'react';
import { SPORT_IDS, E_CRICKET_SPORTFORM_IDS } from '../../constants';
import {
  Baseball,
  Sport,
  Soccer,
  Tennis,
  Cybersport,
  IceHockey,
  EFighting,
  Kabaddi,
  Cricket,
  ECricket,
  Basketball3x3,
  ShortFootball,
  Handball
} from './Sports';
import { useEventFields } from '../../store';

const Score = ({
  eventId,
  isShortVersion = false,
  lineView,
  setEventResultText
}) => {
  const { sportId, eventResultId, sportformId } = useEventFields({
    eventId,
    fields: ['sportId', 'eventResultId', 'sportformId']
  });

  const { byResultType = [], total = [], current = [] } = useEventFields({
    eventId,
    fieldPath: 'scoreboard',
    fields: ['byResultType', 'total', 'current']
  });

  switch (sportId) {
    case SPORT_IDS.KABADDI:
      return (
        <Kabaddi
          byResultType={byResultType}
          eventResultId={eventResultId}
          total={total}
          eventId={eventId}
          isShortVersion={isShortVersion}
          lineView={lineView}
        />
      );

    case SPORT_IDS.E_FIGHTING:
      return (
        <EFighting
          sportId={sportId}
          eventId={eventId}
          byResultType={byResultType}
          eventResultId={eventResultId}
          total={total}
          sportformId={sportformId}
          isShortVersion={isShortVersion}
          lineView={lineView}
          setEventResultText={setEventResultText}
        />
      );

    case SPORT_IDS.BASEBALL:
      return (
        <Baseball
          sportId={sportId}
          eventResultId={eventResultId}
          byResultType={byResultType}
          total={total}
          isShortVersion={isShortVersion}
          lineView={lineView}
        />
      );

    case SPORT_IDS.SOCCER:
    case SPORT_IDS.E_FOOTBALL:
      return (
        <Soccer
          byResultType={byResultType}
          total={total}
          current={current}
          isShortVersion={isShortVersion}
          lineView={lineView}
          sportId={sportId}
          eventResultId={eventResultId}
        />
      );

    case SPORT_IDS.TENNIS:
      return (
        <Tennis
          sportId={sportId}
          eventResultId={eventResultId}
          byResultType={byResultType}
          total={total}
          current={current}
          isShortVersion={isShortVersion}
          lineView={lineView}
        />
      );

    case SPORT_IDS.CYBERSPORT:
      return (
        <Cybersport
          byResultType={byResultType}
          total={total}
          sportId={sportId}
          eventResultId={eventResultId}
          sportformId={sportformId}
          isShortVersion={isShortVersion}
          lineView={lineView}
        />
      );

    case SPORT_IDS.ICE_HOCKEY:
    case SPORT_IDS.ICE_HOCKEY_STATISTICS:
    case SPORT_IDS.FLOORBALL:
    case SPORT_IDS.BALL_HOCKEY:
      return (
        <IceHockey
          sportId={sportId}
          eventResultId={eventResultId}
          byResultType={byResultType}
          total={total}
          isShortVersion={isShortVersion}
          lineView={lineView}
        />
      );

    case SPORT_IDS.FORMULA_1:
      return null;

    case SPORT_IDS.CRICKET:
      return (
        <Cricket
          eventResultId={eventResultId}
          sportformId={sportformId}
          byResultType={byResultType}
          total={total}
          eventId={eventId}
          isShortVersion={isShortVersion}
          lineView={lineView}
        />
      );

    case SPORT_IDS.E_CRICKET:
      if (
        E_CRICKET_SPORTFORM_IDS.includes(sportformId) &&
        (byResultType.length || total.length)
      ) {
        return (
          <ECricket
            byResultType={byResultType}
            eventId={eventId}
            isShortVersion={isShortVersion}
            lineView={lineView}
          />
        );
      }

      return (
        <Sport
          byResultType={byResultType}
          total={total}
          sportId={sportId}
          eventId={eventId}
          isShortVersion={isShortVersion}
          lineView={lineView}
        />
      );

    case SPORT_IDS.SHORT_FOOTBALL:
      return (
        <ShortFootball
          byResultType={byResultType}
          total={total}
          sportId={sportId}
          eventResultId={eventResultId}
          isShortVersion={isShortVersion}
          lineView={lineView}
        />
      );

    case SPORT_IDS.BASKETBALL3X3:
      return (
        <Basketball3x3
          byResultType={byResultType}
          total={total}
          sportId={sportId}
          eventResultId={eventResultId}
          isShortVersion={isShortVersion}
          lineView={lineView}
        />
      );

    case SPORT_IDS.HANDBALL:
      return (
        <Handball
          sportId={sportId}
          eventResultId={eventResultId}
          byResultType={byResultType}
          total={total}
          lineView={lineView}
          isShortVersion={isShortVersion}
        />
      );

    default:
      return (
        <>
          <Sport
            isShortVersion={isShortVersion}
            byResultType={byResultType}
            total={total}
            sportId={sportId}
            eventId={eventId}
            lineView={lineView}
          />
        </>
      );
  }
};

export default memo(Score);
