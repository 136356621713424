import { useContext, useEffect, useMemo, useRef } from 'react';
import { Box } from '@alf/uikit';
import { useExpandedComponent } from '@namespace/cms';
import { DeviceContext } from '@namespace/device';
import { useFixScroll } from '@namespace/common';
import {
  getSessionStorageValue,
  removeSessionStorageItem,
  SESSION_STORAGE_KEYS
} from '@namespace/helpers';
import {
  advFilterActions,
  useAdvFilterStore
} from '../../../../storeAdvFilter';
import { AdvFilterTitle } from './components/AdvFilterTitle';
import { Chips } from './components/Chips';
import { SportItemsSelector } from './components/SportItemsSelector';
import { AdvFilterInnerProvider, useAdvFilterInnerCtxStore } from './store';
import { useFetchCategories } from './hooks/useFetchCategories';
import { useFetchTournaments } from './hooks/useFetchTournaments';
import { useFetchSports } from './hooks/useFetchSports';
import { useSetFetchedFilter } from './hooks/useSetFetchedFilter';
import { useSelectedCategoryIdsBySport } from './hooks/useSelectedCategoryIdsBySport';
import { BottomButtons } from './components/BottomButtons';
import styles from './index.module.css';

const { SET_ADV_FILTER_OPENED } = advFilterActions;

export const AdvFilterBare = () => {
  const advFilter = useAdvFilterStore('advFilter');
  const selectedSportId = useAdvFilterInnerCtxStore('selectedSportId');
  const filter = useAdvFilterInnerCtxStore('filter');
  const { isDesktop } = useContext(DeviceContext);
  const ref = useRef();

  useSetFetchedFilter();

  useFetchSports();
  useFetchCategories(
    useMemo(
      () =>
        selectedSportId != null ? [Number(selectedSportId)] : selectedSportId,
      [selectedSportId]
    )
  );
  useFetchTournaments(useSelectedCategoryIdsBySport());

  useExpandedComponent();
  useFixScroll('AdvancedFilter', ref, !isDesktop);

  useEffect(
    () => () => {
      const advFilterPreventClose = getSessionStorageValue(
        SESSION_STORAGE_KEYS.ADV_FILTER_PREVENT_CLOSE
      );
      removeSessionStorageItem(SESSION_STORAGE_KEYS.ADV_FILTER_PREVENT_CLOSE);

      if (advFilterPreventClose) {
        return;
      }

      SET_ADV_FILTER_OPENED(false);
    },
    []
  );

  const isShowBottomButtons = filter.length > 0 || advFilter?.length > 0;

  return (
    <Box
      direction="column"
      padding={isDesktop ? 's 0 0 0' : 'ms m m m'}
      className={styles.root}
      ref={ref}
    >
      <AdvFilterTitle />
      <SportItemsSelector className={styles.sportItemSelector} />
      <Chips className={styles.chips} />
      {isShowBottomButtons && (
        <BottomButtons className={styles.bottomButtons} />
      )}
    </Box>
  );
};

export const AdvFilter = (props) => (
  <AdvFilterInnerProvider>
    <AdvFilterBare {...props} />
  </AdvFilterInnerProvider>
);
