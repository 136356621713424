import { useMemo } from 'react';
import { complement, isNil, prop, where } from 'ramda';
import { useAdvFilterInnerCtxStore } from '../../store';

export const useDistinctSelectedSportIds = () => {
  const filter = useAdvFilterInnerCtxStore('filter');

  return useMemo(
    () =>
      filter
        .filter(
          where({
            sportId: complement(isNil),
            categoryId: isNil,
            tournamentId: isNil
          })
        )
        .map(prop('sportId')),
    [filter]
  );
};
