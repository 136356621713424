import { get } from 'lodash';
import { useSiteSettings } from '@namespace/cms';

const useUserSettings = () => {
  const { otherSettings } = useSiteSettings();

  return get(otherSettings, 'userSettings', {});
};

export default useUserSettings;
