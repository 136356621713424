import { camelizeKeys } from 'humps';
import { postAsUriParameters } from '@namespace/helpers';
import { isEmpty } from 'lodash';
import apiRoutes from './apiRoutes';

export const checkPassword = ({ password, email }) =>
  postAsUriParameters(apiRoutes.checkPassword, {
    password,
    email
  })
    .then(camelizeKeys)
    .then((response) => {
      const passwordResponse = response?.password || {};
      const { error, errorCode } = passwordResponse;
      const minMax = response?.minMax || [];
      return error === 'no'
        ? { error: false }
        : {
            error: true,
            errorCode,
            data: minMax
          };
    });

export const checkPhone = async (phone) => {
  const value = await postAsUriParameters(apiRoutes.checkPhone, {
    phone_number: phone
  });

  const response = camelizeKeys(value);
  const { phoneNumber = {} } = response || {};
  const { error = '', errorCode = '' } = phoneNumber;
  return {
    error: error === 'yes' && !isEmpty(phoneNumber),
    errorCode: errorCode || ''
  };
};

export const checkFIO = (key, value, country) => {
  const keyMap = {
    firstName: 'first_name',
    lastName: 'last_name',
    middleName: 'middle_name'
  };

  return postAsUriParameters(apiRoutes.checkFio, {
    [keyMap[key]]: value,
    country_id: country
  })
    .then(camelizeKeys)
    .then((response = {}) => {
      if (!response[key]) {
        return { error: false };
      }
      const {
        [key]: { error = '', errorCode = '' }
      } = response;
      return {
        error: error === 'yes' && !isEmpty(response[key]),
        errorCode: errorCode || ''
      };
    });
};

export const checkDocument = (documentTypeId, documentNumber) =>
  postAsUriParameters(apiRoutes.checkDoc, {
    document_type_id: documentTypeId,
    document_number: documentNumber
  }).then((response) => {
    const {
      document_number: { error = '', error_code: errorCode = '' }
    } = response;
    return {
      error: error === 'yes' && !isEmpty(response.document_number),
      errorCode: errorCode || ''
    };
  });

export const checkDate = (date) =>
  postAsUriParameters(apiRoutes.checkDate, { dt: date })
    .then(camelizeKeys)
    .then((response) => {
      const { dt = {} } = response || {};
      const { error = '', errorCode = '' } = dt;
      return {
        error: error === 'yes' && !isEmpty(response.dt),
        errorCode: errorCode || ''
      };
    });

export const checkPersonalCode = ({ pin, nationality, pinType }) =>
  postAsUriParameters(apiRoutes.checkPersonalCode, {
    pin,
    nationality,
    pin_type: pinType
  })
    .then(camelizeKeys)
    .then(({ pin: pinData }) => {
      if (!pinData) {
        return { error: false };
      }
      const { error = '', errorCode = '' } = pinData;
      return {
        error: error === 'yes' && !isEmpty(pinData),
        errorCode
      };
    });

export const checkCity = (city) =>
  postAsUriParameters(apiRoutes.checkCity, { city })
    .then(camelizeKeys)
    .then(({ city: cityData }) => {
      if (!cityData) {
        return { error: false };
      }
      const { error = '', errorCode = '', value = '' } = cityData;
      return {
        error: error === 'yes' && !isEmpty(cityData),
        errorCode,
        value
      };
    });

export const checkAddress = (adress) =>
  postAsUriParameters(apiRoutes.checkAddress, { adress })
    .then(camelizeKeys)
    .then((response) => {
      const { adress: address } = response || {};
      if (!address) {
        return { error: false };
      }
      const { error = '', errorCode = '', value = '' } = address;
      return {
        error: error === 'yes' && !isEmpty(address),
        errorCode,
        value
      };
    });

export const checkZip = (zip) =>
  postAsUriParameters(apiRoutes.checkZip, { zip })
    .then(camelizeKeys)
    .then((response) => {
      const { zip: zipData } = response || {};
      if (!zipData) {
        return { error: false };
      }
      const { error = '', errorCode = '', value = '' } = zipData;
      return {
        error: error === 'yes' && !isEmpty(zipData),
        errorCode,
        value
      };
    });

export const checkEmail = (email) =>
  postAsUriParameters(apiRoutes.checkEmail, { email })
    .then(camelizeKeys)
    .then((response) => {
      const { email: responseEmail = {} } = response || {};
      const { error = '', errorCode = '' } = responseEmail;
      return {
        error: error === 'yes' && !isEmpty(response.email),
        errorCode: errorCode || ''
      };
    });

export const checkInn = (inn) =>
  postAsUriParameters(apiRoutes.checkInn, { inn })
    .then(camelizeKeys)
    .then((response) => {
      const { inn: responseInn = {} } = response || {};
      const { error = '', errorCode = '' } = responseInn;
      return {
        error: error === 'yes' && !isEmpty(response.inn),
        errorCode: errorCode.includes('accounting_error')
          ? errorCode.replace('accounting_error_', '')
          : errorCode || ''
      };
    });
