import { useContext, useEffect } from 'react';
import { getCookie, COOKIES_KEYS } from '@namespace/helpers';
import { bulletSubscribe, bulletUnsubscribe } from '@namespace/socket';
import { useI18n } from '@namespace/i18n';
import { UserContext } from '@namespace/user';
import { IS_WIDGET, WidgetContext } from '@namespace/widget';

const useSSID = () => {
  const [{ ssid: widgetSSID }] = useContext(WidgetContext);
  const { language } = useI18n();
  const [user] = useContext(UserContext);
  const { role } = user;

  // important, subscription is needed here for reconnection case
  useEffect(() => {
    const ssid = IS_WIDGET ? widgetSSID : getCookie(COOKIES_KEYS.PHPSESSID);

    if (ssid) {
      bulletSubscribe({
        name: 'user-ssid',
        types: [],
        message: { user_ssid: ssid },
        callback: () => {}
      });
    }

    return () => bulletUnsubscribe('user-ssid');
  }, [language, role, widgetSSID]);
};

export default useSSID;
