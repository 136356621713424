import { useContext } from 'react';
import { UserContext, USER_ROLES } from '@namespace/user';
import { Icon } from '@namespace/icons2';
import { Box } from '@alf/uikit';
import styles from './index.module.css';

const BetSlipTab = ({
  handleClick,
  tabIndex,
  tabClassName,
  list,
  counterClassName,
  isDesktop
}) => {
  const [user] = useContext(UserContext);
  const { role } = user;
  return list.map(({ label, counter, isBetslip = false, type }, index, arr) => (
    <Box
      key={label}
      align="center"
      justify="center"
      data-role={`betslip-tabs-header-${type}`}
      onClick={() => handleClick(index)}
      className={`
        ${styles.menuTab}
        ${role === USER_ROLES.GUEST ? styles.guestTab : ''}
        ${isBetslip ? styles.specialTab : ''}
        ${
          arr.some(({ isBetslip: isBs }) => isBs) &&
          arr.length <= 2 &&
          index !== 0 &&
          isDesktop
            ? styles.fullWidth
            : ''
        }
        ${tabIndex === index ? styles.active : ''} 
        ${tabClassName}
      `}
    >
      {isBetslip ? (
        <Box align="center">
          <Icon
            name="icons/nav_menu/betslip_default"
            color="betslip_txt_color_5"
          />
          {counter && (
            <span data-role="betslip-counter" className={styles.betSlipCounter}>
              {counter}
            </span>
          )}
          {!isDesktop && <div className={styles.betSlipText}>{label}</div>}
        </Box>
      ) : (
        <>
          <div>{label}</div>
          {counter && (
            <div
              data-role="betslip-counter"
              className={`${styles.counter} ${counterClassName}`}
            >
              {counter}
            </div>
          )}
        </>
      )}
    </Box>
  ));
};
export default BetSlipTab;
