import { memo } from 'react';
import { Box } from '@alf/uikit';
import { LINE_VIEW } from '../../../../../../../constants';
import EventParticipants from '../../../../../../EventParticipants';
import LiveScore from '../../../../../../LiveScore';
import HeadMarket from '../HeadMarket';
import styles from './index.module.css';

const EventSubRowButton = ({
  headGroups,
  isActiveLink,
  isDisplayHeadMarkets,
  eventId,
  lineKey
}) => {
  return (
    <Box flex="1 0 100%" justify="between" align="center">
      <EventParticipants
        eventId={eventId}
        isActiveLink={isActiveLink}
        isDisplayAdditionalData={true}
      />
      <LiveScore
        eventId={eventId}
        isShortVersion={true}
        lineView={LINE_VIEW.BUTTON}
      />
      <Box>
        {isDisplayHeadMarkets &&
          headGroups.map((headGroup) => {
            const { resultTypeId, marketTemplateId } = headGroup;

            return (
              <HeadMarket
                key={`${resultTypeId}-${marketTemplateId}`}
                eventId={eventId}
                lineKey={lineKey}
                resultTypeId={resultTypeId}
                marketTemplateId={marketTemplateId}
                lineView={LINE_VIEW.BUTTON}
                dataRole="c-eventSubRowTable-headGroup"
                classNames={{
                  marketClassName: styles.market,
                  emptyMarketContainerClassName: styles.eventSubRowContainer
                }}
              />
            );
          })}
      </Box>
    </Box>
  );
};

export default memo(EventSubRowButton);
