const makeDataForApi = ({
  documentPlaceDate,
  documentNumber,
  citizenship,
  documentTypeId,
  f,
  pin: inn,
  withTZ = true,
  ...rest
}) => {
  const DATE_FORMATS_TYPES = f.getFormats();
  return {
    documentPlaceDate: f
      .getDateTime({ date: documentPlaceDate, withTZ })
      .toFormat(DATE_FORMATS_TYPES.API),
    documentNumber: documentNumber.toUpperCase(),
    documentTypeId,
    citizenship,
    ...(inn && { pin: inn }),
    ...rest
  };
};

export default makeDataForApi;
