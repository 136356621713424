import { useContext, useEffect } from 'react';
import { useGetInHouseJackpotSettings } from '@namespace/games';
import { UserContext } from '@namespace/user';
import { useOtherSettings } from '@namespace/cms';
import useGetBonusesCounters from '../../hooks/useGetBonusesCounters';
import {
  ACCOUNTING_BONUS_TYPES,
  BONUS_SYSTEM_BONUS_TYPE,
  OTHER_BONUS_TYPES
} from '../../constants';
import { BonusesContext } from '../../store/context';

const BonusesWrapper = () => {
  const [user] = useContext(UserContext);
  const { userInfoLoaded = false } = user;
  const { isJackpotBonusTabEnabled } = useGetInHouseJackpotSettings();
  const { isPreWagerBonusesEnabled = false } = useOtherSettings();
  const [
    ,
    { FETCH_ACTIVE_WAGER_BONUS, FETCH_ACTIVE_BONUS_SYSTEM_BONUS }
  ] = useContext(BonusesContext);

  const fetchCounters = useGetBonusesCounters([
    ACCOUNTING_BONUS_TYPES.ALL,
    ACCOUNTING_BONUS_TYPES.RISK_FREE,
    ACCOUNTING_BONUS_TYPES.REAL_MONEY,
    ACCOUNTING_BONUS_TYPES.FREE_SPIN,
    ...(isPreWagerBonusesEnabled ? [BONUS_SYSTEM_BONUS_TYPE] : []),
    ...(isJackpotBonusTabEnabled ? [OTHER_BONUS_TYPES.JACKPOT_WINS] : [])
  ]);

  useEffect(() => {
    if (isPreWagerBonusesEnabled) {
      FETCH_ACTIVE_WAGER_BONUS();
      FETCH_ACTIVE_BONUS_SYSTEM_BONUS();
    }
  }, [
    FETCH_ACTIVE_WAGER_BONUS,
    FETCH_ACTIVE_BONUS_SYSTEM_BONUS,
    isPreWagerBonusesEnabled
  ]);

  useEffect(() => {
    fetchCounters();
  }, [userInfoLoaded]);

  return null;
};

export default BonusesWrapper;
