import { createContext, useMemo, useReducer } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import { types } from '../types';
import actionCreators from '../actions';
import { reducer, initialState } from '../reducer';

export const ABTestContext = createContext();

export const ABTestProvider = ({ children }) => {
  const memoReducers = useMemo(() => combineReducers(reducer), []);
  const [state, dispatch] = useReducer(memoReducers, initialState);
  const actions = useActions(types, dispatch, actionCreators);

  return (
    <ABTestContext.Provider value={[state, actions]}>
      {children}
    </ABTestContext.Provider>
  );
};
