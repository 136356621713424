import { useMemo } from 'react';
import { useSearchParams } from '@namespace/router';

const useBetsFilter = (page = 1, defaultFilter) => {
  const params = useSearchParams();
  return useMemo(() => {
    return {
      ...defaultFilter,
      ...params,
      page
    };
  }, [defaultFilter, params, page]);
};

export default useBetsFilter;
