import { useMemo, useContext } from 'react';
import { SiteSettingsContext } from '@namespace/cms';
import { CheckboxField } from '@namespace/forms';
import { useI18n, useGetActualPrefixForPath } from '@namespace/i18n';
import { HTMLSnippet } from '@namespace/snippets';
import FieldLabel from '../FieldLabel';

const ConfirmAgeCombined = ({
  termsAndConditionsLink = '',
  privacyPolicyLink = '',
  ...props
}) => {
  const { t, language } = useI18n();
  const [{ siteConfig }] = useContext(SiteSettingsContext);
  const { rootDomain } = siteConfig;
  const { confirmAgeSnippetId } = props.formProps;
  const getActualPrefixForPath = useGetActualPrefixForPath();

  const linkToTermAndConditions = useMemo(() => {
    return `${rootDomain}${getActualPrefixForPath(
      `/${language}`
    )}${termsAndConditionsLink}`;
  }, [rootDomain, language, termsAndConditionsLink]);

  const linkToPrivacyPolicy = useMemo(() => {
    return `${rootDomain}${getActualPrefixForPath(
      `/${language}`
    )}${privacyPolicyLink}`;
  }, [rootDomain, language, privacyPolicyLink]);

  return (
    <CheckboxField
      LabelComponent={
        confirmAgeSnippetId ? (
          <HTMLSnippet snippetId={confirmAgeSnippetId} />
        ) : (
          <>
            <FieldLabel
              dataRole="general-terms-link"
              link={linkToTermAndConditions}
              linkText={t('shortReg.form.generalTermsLink')}
              firstText={t('shortReg.form.confirmTerms1')}
            />
            <FieldLabel
              dataRole="privacy-policy-link"
              link={linkToPrivacyPolicy}
              linkText={t('shortReg.form.privacyPolicyLink')}
              firstText={t('shortReg.form.confirmTerms2')}
              secondText={t('shortReg.form.confirmTerms3')}
            />
          </>
        )
      }
      {...props}
    />
  );
};

export default ConfirmAgeCombined;
