import { pathOr } from 'ramda';
import { shallow } from 'zustand/shallow';
import { createSelector } from 'reselect';
import { useSportsbookSelector } from '../../store';

export const selectTournamentsByCategoryId = (categoryId, serviceId) =>
  createSelector(
    pathOr({}, ['tournaments', serviceId, 'data']),
    (tournamentsData) =>
      Object.entries(tournamentsData)
        .reduce(
          (
            memo,
            [
              tournamentId,
              { categoryId: tournamentCategoryId, tournamentWeigh }
            ]
          ) =>
            categoryId === tournamentCategoryId
              ? [...memo, { tournamentId, tournamentWeigh }]
              : memo,
          []
        )
        .sort(({ tournamentWeigh: a }, { tournamentWeigh: b }) => a - b)
        .map(({ tournamentId }) => tournamentId)
  );

export const useTournamentsByCategoryId = (categoryId, serviceId) =>
  useSportsbookSelector(
    selectTournamentsByCategoryId,
    [categoryId, serviceId],
    shallow
  );
