import { useContext } from 'react';
import { DeviceContext } from '@namespace/device';
import { Box, AText } from '@alf/uikit';
import { Icon } from '@namespace/icons2';

import styles from '../index.module.css';

export const JackpotGameInfoBlock = ({
  onLaunchGame,
  isShowInHouseJackpotGamesNameAsLink,
  winnerProviderName,
  winnerGameName
}) => {
  const { isDesktop } = useContext(DeviceContext);

  return (
    <>
      {winnerProviderName &&
        winnerGameName &&
        (isShowInHouseJackpotGamesNameAsLink && isDesktop ? (
          <Box>
            <Box
              gap="xxs"
              align="center"
              onClick={onLaunchGame}
              className={styles.gameLink}
            >
              <AText size="m" breed="B" color="txt_link">
                {winnerProviderName}
                <span>:</span>
              </AText>
              <AText size="m" breed="B" color="txt_link">
                {winnerGameName}
              </AText>
              <Icon color="txt_link" size="xs" name="icons/general/nav/go-to" />
            </Box>
          </Box>
        ) : (
          <AText size="m" breed="R" color="txt_color_4">
            {winnerProviderName}
            <span>: </span>
            {winnerGameName}
          </AText>
        ))}
    </>
  );
};
