import { useState } from 'react';
import { AText, Box } from '@alf/uikit';
import { SERVICE_IDS, SERVICE_IDS_TYPES_MAP } from '@namespace/bets';
import { useShowStatistics } from '@namespace/cms';
import BackHeader from '../../../BackHeader';
import EventFavoriteStar from '../../../../../EventFavoriteStar';
import Summaries from '../../../Summaries';
import ScoreTable from '../../../ScoreTable';
import AdditionalStatistics from '../../../AdditionalStatistics';
import {
  PinIcon,
  ScoreboardIcon,
  StatisticsIcon,
  VideoIcon
} from '../FilterIcons';
import {
  FINISHED_EVENT_STATUS,
  SUMMARIES_RESULT_TYPE_IDS
} from '../../../../../../constants';
import {
  useEventFields,
  useIsSummariesScoreboard
} from '../../../../../../store';
import styles from './index.module.css';

export const HeaderDesktop = ({
  isShowScoreboard,
  expandVideo,
  isShowVideo,
  isVideoFree,
  tabState,
  setTabState,
  isShowBackHeader,
  backTitle,
  eventId,
  lineView,
  dataRole
}) => {
  const [eventResultText, setEventResultText] = useState('');
  const { isSummariesScoreboard } = useIsSummariesScoreboard(eventId);
  const {
    eventEnetStatUrl = '',
    eventStatusType,
    scoreboard,
    serviceId,
    eventResultId
  } = useEventFields({
    eventId,
    fields: [
      'eventEnetStatUrl',
      'eventStatusType',
      'scoreboard',
      'serviceId',
      'eventResultId'
    ]
  });

  const isShowStatIcon = useShowStatistics(eventEnetStatUrl);
  return (
    <>
      {isShowBackHeader && (
        <BackHeader dataRole={dataRole} title={backTitle} eventId={eventId}>
          <Box gap="m" margin="0 s">
            {isShowScoreboard && eventEnetStatUrl && (
              <ScoreboardIcon tabState={tabState} setTabState={setTabState} />
            )}
            {isShowVideo && (
              <VideoIcon
                tabState={tabState}
                expandVideo={expandVideo}
                isVideoFree={isVideoFree}
              />
            )}
            {isShowStatIcon && (
              <StatisticsIcon eventEnetStatUrl={eventEnetStatUrl} />
            )}
            <EventFavoriteStar
              eventId={Number(eventId)}
              size="m"
              serviceId={serviceId}
            />
            <PinIcon />
          </Box>
        </BackHeader>
      )}
      {(serviceId === SERVICE_IDS[SERVICE_IDS_TYPES_MAP.LIVE] ||
        (eventStatusType === FINISHED_EVENT_STATUS && scoreboard)) &&
        (isSummariesScoreboard ||
        SUMMARIES_RESULT_TYPE_IDS.includes(eventResultId) ? (
          <Summaries eventId={eventId} />
        ) : (
          <ScoreTable
            lineView={lineView}
            eventId={eventId}
            setEventResultText={setEventResultText}
          />
        ))}
      {eventResultText && (
        <Box
          justify="center"
          padding="xs 0"
          className={styles.eventResultTextContainer}
        >
          <AText breed="B" size="s" color="line_txt_1">
            {eventResultText}
          </AText>
        </Box>
      )}
      {serviceId === SERVICE_IDS[SERVICE_IDS_TYPES_MAP.LIVE] && (
        <div className={styles.additionalStats}>
          <AdditionalStatistics eventId={eventId} />
        </div>
      )}
    </>
  );
};
