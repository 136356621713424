import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '@namespace/user';
import { useI18n } from '@namespace/i18n';
import { isEmpty } from 'lodash';
import { sportsbookActions, useSportsbookStore } from '../../store';
import { MODE } from '../../constants';
import { useFetchEventsFilter } from '../useFetchEventsFilter';
import filterByOnlyParsedNumbers from './filterByNumbers';
import { useGetLineOffset } from '../../store/selectors/lineOffset';

const { FETCH_EVENTS, FETCH_EVENTS_BY_LIMIT } = sportsbookActions;

const emptyArr = [];

export const useFetchEvents = ({
  lineKey,
  serviceId,
  filter = null,
  sportId,
  categoryIds = emptyArr,
  tournamentsIds = emptyArr,
  initialEventsLimit,
  moreEventsLimit,
  isTodayFilterApplied
}) => {
  const isMounted = useRef(false);
  const [isEventsLoading, setIsEventsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isEventsLoaded, setIsEventsLoaded] = useState(false);
  const [isFilterEventsLoaded, setIsFilterEventsLoaded] = useState(false);
  const [user] = useContext(UserContext);
  const { marginGroupId } = user;
  const { language: lang, timezone } = useI18n();
  const { categories, tournaments } = useSportsbookStore([
    'categories',
    'tournaments'
  ]);
  const lineOffset = useGetLineOffset(lineKey);

  const filterValue = useFetchEventsFilter(filter);
  const categoriesDep = categoryIds.join(',');
  const tournamentsDep = tournamentsIds.join(',');
  const isCategoriesLoaded = !isEmpty(categories);
  const isTournamentsLoaded = !isEmpty(tournaments);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchEvents = useCallback(async () => {
    setIsEventsLoading(true);
    const data = await FETCH_EVENTS({
      lang,
      sportId,
      serviceId,
      categoryIds,
      filterValue,
      marginGroupId,
      lineKey,
      tournamentsIds: filterByOnlyParsedNumbers(tournamentsIds),
      limit: initialEventsLimit
    });
    // prevent error on unmounted component update
    if (!isMounted.current) {
      return;
    }

    setIsEventsLoading(false);

    if (data?.length < initialEventsLimit) {
      setIsEventsLoaded(true);
    } else {
      setIsEventsLoaded(false);
    }
    if (!isTodayFilterApplied) {
      setIsFilterEventsLoaded(true);
    }
  }, [
    lang,
    sportId,
    serviceId,
    categoryIds,
    filterValue,
    marginGroupId,
    lineKey,
    tournamentsIds,
    initialEventsLimit,
    isTodayFilterApplied
  ]);

  const loadMoreEvents = useCallback(async () => {
    try {
      setIsLoadingMore(true);
      const data = await FETCH_EVENTS_BY_LIMIT({
        lang,
        sportId,
        serviceId,
        categoryIds,
        filterValue,
        lineKey,
        tournamentsIds,
        offset: lineOffset,
        limit: moreEventsLimit
      });
      // prevent error on unmounted component update
      if (!isMounted.current) {
        return;
      }

      if (data.length < moreEventsLimit) {
        setIsEventsLoaded(true);
      }
      setIsLoadingMore(false);
    } catch {
      setIsLoadingMore(false);
    }
  }, [
    lang,
    sportId,
    serviceId,
    categoriesDep,
    filterValue,
    lineKey,
    tournamentsDep,
    lineOffset,
    moreEventsLimit
  ]);

  useEffect(() => {
    setIsEventsLoaded(false);
    setIsFilterEventsLoaded(false);
  }, [filterValue]);

  useEffect(() => {
    // need to wait when categories and tournaments are loaded because only on
    // this stage we already have all data for correct requests without extra requests
    // to display relevant events for user without store overwrite
    if (
      sportId &&
      ((!isEmpty(categories) && !isEmpty(tournaments)) ||
        serviceId === MODE.IN_PLAY)
    ) {
      fetchEvents().catch(console.error);
    }
  }, [
    timezone,
    sportId,
    categoriesDep,
    tournamentsDep,
    filterValue,
    isCategoriesLoaded,
    isTournamentsLoaded,
    lang
  ]);

  return {
    fetchEvents,
    isEventsLoading,
    isLoadingMore,
    loadMoreEvents,
    isEventsLoaded,
    isFilterEventsLoaded
  };
};
