import { useMemo } from 'react';
import { AText, Box, ProgressBar } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import clsx from 'clsx';
import { CURRENCY_CODE_TO_SYMBOL } from '@namespace/helpers';
import styles from './index.module.css';

export const WagerBonus = ({
  bonus,
  currency,
  classNames = {},
  wagerBonusProps = {},
  progressBarClassNames = {}
}) => {
  const {
    outputBetTotal,
    outputMaxTotal,
    name,
    wageredPercent: wageredPercentFromResponse,
    campaignId,
    bonusModelId
  } = bonus;
  const {
    bonusName = '',
    wagerPercentage = '',
    wagerOptions = '',
    wagerDescriptionWrapper = ''
  } = classNames;
  const {
    progressBarProgressColor = 'accent_color_3',
    progressBarContainerColor = 'po_bg_color_5',
    currencyToSymbol = false
  } = wagerBonusProps;
  const wageredPercent = useMemo(() => {
    const value = (outputBetTotal / outputMaxTotal) * 100;
    return value >= 100 ? 100 : value.toFixed(2);
  }, [outputBetTotal, outputMaxTotal]);

  const { t } = useI18n();

  return (
    <div className={styles.container}>
      <Box justify="center" direction="column">
        <AText
          className={clsx([styles.bonusName, bonusName])}
          align="center"
          breed="B"
          size="s"
          color="po_txt_color_1"
        >
          {t(
            `content.bonus.template.name.${
              campaignId ? `bs.${campaignId}` : bonusModelId
            }`,
            name
          )}
        </AText>
        <Box
          bottom="xs"
          top="xs"
          justify="center"
          className={clsx([wagerDescriptionWrapper])}
        >
          <AText
            breed="B"
            size="s"
            color="po_txt_color_1"
            className={clsx([styles.wagerPercentage, wagerPercentage])}
          >
            {`${Math.floor(wageredPercentFromResponse || wageredPercent)}%`}
          </AText>
          <AText
            breed="B"
            size="s"
            color="po_txt_color_3"
            className={clsx([wagerOptions])}
          >
            {`(${outputBetTotal} / ${outputMaxTotal} ${
              currencyToSymbol ? CURRENCY_CODE_TO_SYMBOL[currency] : currency
            })`}
          </AText>
        </Box>
      </Box>
      <ProgressBar
        currentValue={outputBetTotal}
        totalValue={outputMaxTotal}
        progressColor={progressBarProgressColor}
        containerColor={progressBarContainerColor}
        classNames={progressBarClassNames}
      />
    </div>
  );
};
