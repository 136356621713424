export const CRYPTO_CURRENCIES = {
  RIPPLE: 'XRP',
  ETHERIUM: 'ETH',
  TETHER_ERC: 'USDTE',
  TETHER_TRX: 'USDTT',
  BITCOIN: 'BTC',
  BITCOIN_CASH: 'BCH',
  LITECOIN: 'LTC',
  BINANCECOIN: 'BNB',
  TRON: 'TRX',
  CARDANO: 'ADA',
  DOGECOIN: 'DOGE',
  BINANCEUSD: 'BUSD',
  USDCOIN: 'USDC'
};
