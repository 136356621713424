import styles from './index.module.css';

export const SportSliderSkelet = ({ items = 1 }) => {
  const skeletItems = new Array(items).fill(1);

  return (
    <div className={styles.skeletWrapper}>
      {skeletItems.map((_, index) => (
        <div key={index} className={styles.skelet} /> // eslint-disable-line react/no-array-index-key
      ))}
    </div>
  );
};
