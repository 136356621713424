import PropTypes from 'prop-types';
import Box from '../Box';
import styles from './index.module.css';

const Overlay = ({ children, classNames = {}, onClick = () => {} }) => {
  const { wrapperClass = '', contentClass = '' } = classNames;

  return (
    <Box className={`${styles.overlay} ${wrapperClass}`} onClick={onClick}>
      {children && (
        <Box
          align="center"
          justify="center"
          className={`${styles.content} ${contentClass}`}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

Overlay.propTypes = {
  children: PropTypes.node,
  classNames: PropTypes.shape({
    wrapperClass: PropTypes.string,
    contentClass: PropTypes.string
  }),
  onClick: PropTypes.func
};

export default Overlay;
