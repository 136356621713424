import { useContext, useEffect } from 'react';
import { pathOr } from 'ramda';
import { DeviceContext } from '@namespace/device';
import { useGeneralRoutes } from '@namespace/cms';
import { useRouteOptionalParams, useHistory } from '@namespace/router';
import { useI18n } from '@namespace/i18n';
import { Icon } from '@namespace/icons2';
import {
  sportsbookActions,
  useSportsbookStore,
  selectEventsIdsBySportId
} from '../../store';
import { FavoritesEmptyState, PreMatchLine, WidgetWrapper } from '../../common';
import { useRemoveLine } from '../../hooks/useRemoveLine';
import { LINE_KEY, MODE } from '../../constants';
import BackHeader from '../../common/BackHeader';
import styles from './index.module.css';

const { FETCH_LIST_OF_EVENTS, SET_EVENTS } = sportsbookActions;

const FavoriteEventsWrapper = ({ id, serviceId, isLiveEvents }) => {
  const favoriteEvents = useSportsbookStore(
    pathOr([], ['favorites', 'events'])
  );
  const { language: lang, t } = useI18n();
  const {
    preMatchLobby,
    preMatchFavorites,
    liveFavorites
  } = useGeneralRoutes();
  const { push, replace } = useHistory();
  const { isDesktop } = useContext(DeviceContext);

  const lineKey = `${LINE_KEY.FAVORITES}-${serviceId}`;
  const eventsBySportId = useSportsbookStore(selectEventsIdsBySportId(lineKey));
  const isBackHeaderVisible = !isDesktop && serviceId === MODE.PRE_MATCH;
  const { optional = [] } = useRouteOptionalParams(
    `${isLiveEvents ? liveFavorites : preMatchFavorites}/*`
  );
  // todo check when to show loader
  useEffect(() => {
    if (favoriteEvents?.length) {
      FETCH_LIST_OF_EVENTS({
        eventIds: favoriteEvents,
        lang,
        serviceId,
        lineKey
      });
    } else {
      SET_EVENTS({ events: [], lineKey });
    }
  }, [lang, favoriteEvents.join(''), serviceId]);

  const onGoBack = () => push(preMatchLobby);

  useRemoveLine(lineKey);

  useEffect(() => {
    if (optional.length > 0) {
      replace(isLiveEvents ? liveFavorites : preMatchFavorites);
    }
  }, []);

  if (Object.keys(eventsBySportId).length === 0) {
    return (
      <FavoritesEmptyState
        isBackHeaderVisible={isBackHeaderVisible}
        onGoBack={onGoBack}
        classNames={{ containerClassName: styles.emptySpaceContainer }}
      />
    );
  }

  return (
    <WidgetWrapper>
      {isBackHeaderVisible && (
        <BackHeader
          containerClassName={styles.backHeaderContainer}
          title={t('sportsbook.favorites')}
          arrowBackIcon={
            <Icon
              name="icons/general/nav/back"
              color="line_header_txt"
              size="s"
            />
          }
          showArrowBack={true}
          onBackClick={onGoBack}
        />
      )}
      {Object.entries(eventsBySportId).map(([sportId, eventsIdsBySport]) => (
        <PreMatchLine
          key={sportId}
          id={id}
          lineKey={lineKey}
          sportId={sportId}
          eventsIdsBySport={eventsIdsBySport}
          isLoading={false}
          serviceId={serviceId}
          backLink={preMatchLobby}
          isExpandedDefault={true}
          isLiveEvents={isLiveEvents}
        />
      ))}
    </WidgetWrapper>
  );
};

export default FavoriteEventsWrapper;
