import { Icon } from '@namespace/icons2';
import { Box } from '@alf/uikit';
import styles from '../../index.module.css';

export const StatisticsIcon = ({ eventEnetStatUrl }) => (
  <Box align="center" className={styles.filterIcon}>
    <a href={eventEnetStatUrl} rel="noopener noreferrer" target="_blank">
      <Icon name="icons/event/statistics" size="l" className={styles.icon} />
    </a>
  </Box>
);
