import { keyBy, isEmpty } from 'lodash';
import checkIsActivePromo from '../../utils/checkIsActivePromo';
import { PROMO_TYPES } from '../../constants';

export const parsePromoConfig = (config) => {
  const normalizedConfig = {};

  for (const [key, value] of Object.entries(config)) {
    const normalizedList = value.categories.map((category) => ({
      ...category,
      list: keyBy(category.list, 'itemId')
    }));

    const normalizedCategories = keyBy(normalizedList, 'categoryName');

    normalizedConfig[key] = {
      ...value,
      categories: normalizedCategories
    };
  }

  return normalizedConfig;
};

export const parseRecentlyPromoConfig = (config) => {
  const normalizedConfig = {};

  for (const [key, value] of Object.entries(config)) {
    normalizedConfig[key] = {
      ...value,
      list: keyBy(value.list, 'itemId')
    };
  }

  return normalizedConfig;
};

export const parsePromotionsApi = (
  promotionsApi,
  activePromoType,
  filteredSegments,
  listOfSelectedPromo
) =>
  Object.values(promotionsApi).filter((promo) => {
    const { enabled, showFrom, showTo, type, promoId } = promo;
    const isEnabledPromo = enabled;
    const currentPromoSegments = listOfSelectedPromo[promoId]?.userSegments;
    const isActivePromo = checkIsActivePromo(showFrom, showTo, false);
    const isPromoIncludedInSegments = filteredSegments(currentPromoSegments);
    const isCorrectType = PROMO_TYPES[type] === activePromoType;

    if (
      isEnabledPromo &&
      isActivePromo &&
      isCorrectType &&
      isPromoIncludedInSegments
    ) {
      return promo;
    }

    return null;
  });

export const parseTournaments = (tournaments) => {
  const parsedTournaments = {};

  if (isEmpty(tournaments)) return parsedTournaments;

  for (const tournament of tournaments) {
    parsedTournaments[tournament.id] = tournament;
  }

  return parsedTournaments;
};
