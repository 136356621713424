const getRandomIndex = (p) => {
  let sump = 0;

  for (let i = 0; i < p.length; i++) {
    sump += p[i];
  }
  let r = Math.random() * sump;
  let index = p.length;

  for (let i = 0; i < p.length; i++) {
    if (r > p[i]) {
      r -= p[i];
    } else {
      index = i;
      break;
    }
  }
  return index;
};

export default getRandomIndex;
