import { Box } from '@alf/uikit';
import EventMarket from '../../../../../../EventMarket';
import EmptyMarket from '../../../../../../EventMarketContainer/EmptyMarket';
import { useEventMarketId, useEventFields } from '../../../../../../../store';

const HeadMarket = ({
  eventId,
  lineKey,
  resultTypeId,
  marketTemplateId,
  lineView,
  dataRole,
  classNames = {}
}) => {
  const {
    emptyMarketContainerClassName = '',
    marketClassName = ''
  } = classNames;
  const { canceled, finished, inprogress } = useEventFields({
    eventId,
    fields: ['canceled', 'finished', 'inprogress']
  });

  const marketId = useEventMarketId({
    resultTypeId,
    marketTemplateId,
    eventId,
    lineKey
  });
  const isEmptyState = canceled || finished || inprogress;

  return (
    <Box data-role={dataRole} className={marketClassName}>
      {!isEmptyState && marketId ? (
        <EventMarket
          marketId={marketId}
          marketTemplateId={marketTemplateId}
          resultTypeId={resultTypeId}
          lineView={lineView}
        />
      ) : (
        <Box flex="1 1 100%">
          <EmptyMarket
            marketTemplateId={marketTemplateId}
            lineView={lineView}
            className={emptyMarketContainerClassName}
          />
        </Box>
      )}
    </Box>
  );
};

export default HeadMarket;
