import { Box } from '@alf/uikit';
import BonusSliderItem from './BonusSliderItem';
import styles from './index.module.css';

export const BonusSliderContainer = ({
  bonuses,
  handleBonusClick,
  activeBonusId,
  userCurrency
}) => {
  return (
    <Box className={styles.bonusSlider}>
      {bonuses.map((bonus) => (
        <BonusSliderItem
          bonus={bonus}
          key={bonus.bonusId || bonus.freespinId || bonus.bonusName}
          onClick={handleBonusClick}
          activeBonusId={activeBonusId}
          userCurrency={userCurrency}
        />
      ))}
    </Box>
  );
};
