import { useCallback, useContext } from 'react';
import { AText, Box } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { useI18n } from '@namespace/i18n';
import { LOCAL_STORAGE_KEYS } from '@namespace/helpers';
import { UserContext } from '@namespace/user';
import { LINE_VIEW_TYPES } from '@namespace/common';
import clsx from 'clsx';
import styles from './index.module.css';

const SettingsLineView = ({ iconOptions = {} }) => {
  const [state = {}, userActions] = useContext(UserContext);
  const { settings = {} } = state;
  const { lineViewType } = settings;
  const { CHANGE_LINE_VIEW } = userActions;
  const { t } = useI18n();
  const lineView = [
    {
      name: t('web.settings.lineview.button'),
      icon: 'icons/general/state/line-buttons',
      value: LINE_VIEW_TYPES.BUTTON
    },
    {
      name: t('web.settings.lineview.table'),
      icon: 'icons/general/state/line-tables',
      value: LINE_VIEW_TYPES.TABLE
    }
  ];
  const handleChangeLineView = useCallback((value) => {
    CHANGE_LINE_VIEW(LOCAL_STORAGE_KEYS.LINE_VIEW, value);
  }, []);

  return (
    <Box data-role="settings-line-view">
      {lineView.map(({ name, icon, value }) => {
        const isActive = lineViewType === value;
        return (
          <Box
            key={value}
            className={clsx([styles.button, isActive && styles.active])}
            onClick={() => handleChangeLineView(value)}
            data-role={`settings-line-view-${name.toLowerCase()}`}
            align="center"
          >
            <Box right="xs">
              <Icon
                name={icon}
                color={`txt_color_${isActive ? '5' : '2'}`}
                {...iconOptions}
              />
            </Box>
            <AText
              breed="BU"
              size="m"
              color={`txt_color_${isActive ? '5' : '2'}`}
            >
              {name}
            </AText>
          </Box>
        );
      })}
    </Box>
  );
};

export default SettingsLineView;
