import { rePatterns } from '../../patterns';

const creditCardHolderValidator = ({
  message = 'panKeeper.card.regexp.validator.cardholder',
  min = 3,
  max = 100,
  allowedGaps = 5
} = {}) => ({
  validate: (value) => {
    if (!rePatterns.latinCharsAndGap.test(value)) {
      return message;
    }

    const whitespaces = value.match(/\s/g) || [];
    if (whitespaces.length > allowedGaps) {
      return 'pankeeper.card.validator.cardholder.error2';
    }

    if (value.length < min || value.length > max) {
      return `pankeeper.card.validator.cardholder.error1 {${min}} {${max}}`;
    }

    return true;
  }
});

export default creditCardHolderValidator;
