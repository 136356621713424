import { useContext, useMemo } from 'react';
import { useI18n } from '@namespace/i18n';
import clsx from 'clsx';
import { Box, Select, SELECT_TYPE } from '@alf/uikit';
import { CoefTypeContext } from '@namespace/user/src/store/coefType/context';
import { useCoefType } from '@namespace/user/src/hooks/useCoefType';
import { COLOR_SCHEME } from '@namespace/themes';
import useUserSettings from '../../hooks/useUserSettings';
import styles from './index.module.css';

const CoefTypeSwitcher = ({ switcherClassName = '' }) => {
  const [betsType] = useContext(CoefTypeContext);
  const { t } = useI18n();
  const { coefType } = betsType;
  const { setCoefType } = useCoefType();
  const { availableCoefType = [] } = useUserSettings();

  const options = useMemo(
    () =>
      availableCoefType.map((item) => ({
        value: item,
        title: t(`web.settings.coefficients.type.${item}`)
      })),
    [availableCoefType, t]
  );

  return (
    <Box align="center" className={`${switcherClassName} ${styles.switcher}`}>
      <Select
        options={options}
        onChange={setCoefType}
        value={coefType}
        isDesktop={true}
        size="xs"
        additionSelectTitleStyle={styles.margin}
        additionSelectStyles={{
          triggerContentClass: styles.triggerContent,
          triggerContentTitleClass: styles.triggerContentTitle
        }}
        additionSelectOptionStyle={{ item: styles.item }}
        additionalPopoverStyle={clsx(styles.width)}
        selectType={SELECT_TYPE.ADDITIONAL}
        intentType={COLOR_SCHEME.SECONDARY}
      />
    </Box>
  );
};

export default CoefTypeSwitcher;
