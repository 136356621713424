import { useConfig, useGeneralRoutes } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { Box } from '@alf/uikit';
import { MODE } from '../../../constants';
import { MenuHeader } from './components/MenuHeader';
import { CyberSportBaseMenuList } from '../../../common/BaseMenuList/CyberSportBaseMenuList';
import ListAllItem from './ListAllItem';
import { useCyberSportGetPathParams } from '../../../hooks/useCyberSportGetPathParams';
import { FavoritesLink } from '../../../common';
import styles from './index.module.css';

const CyberSportMenuList = ({ id }) => {
  const { t } = useI18n();
  const {
    cyberSport,
    cyberSportCategory,
    cyberSportTournament,
    cyberSportFavorites
  } = useGeneralRoutes();

  const { config } = useConfig(id);
  const { showCategories = false } = config;

  const { category, activeSport, tournament } = useCyberSportGetPathParams();
  const { categoryId: activeCategoryId } = category || {};
  const { sportId } = activeSport || {};
  const { tournamentId, categoryId: tournamentCategoryId } = tournament || {};

  return (
    <Box
      data-role="cyber-sport-menu-list"
      direction="column"
      className={styles.cyberSportMenuWrapper}
    >
      <MenuHeader
        title="cybersport"
        hideAdvFilter={true}
        classNames={{
          headerClassName: styles.menuHeaderPreMatch
        }}
        dataRole="cyber-sport"
      />
      <ListAllItem
        isActive={!activeCategoryId}
        route={cyberSport}
        serviceIds={[MODE.PRE_MATCH, MODE.IN_PLAY]}
        sportId={sportId}
        title={t('sportsBook.menu.allCyberSport')}
        classNames={{ itemClass: styles.itemAll }}
        withIcons={true}
        dataRole="cybersport-list"
      />
      <FavoritesLink
        to={cyberSportFavorites}
        title={t('sportsbook.menu.cyberSportFavorites')}
        className={styles.favoriteItem}
      />
      <CyberSportBaseMenuList
        tournamentId={tournamentId}
        activeSportId={activeCategoryId}
        basePath={cyberSportCategory}
        categoryRoute={cyberSportTournament}
        showCategories={showCategories}
        categorySportId={tournamentCategoryId}
        categoriesWithIcon={false}
      />
    </Box>
  );
};

export default CyberSportMenuList;
