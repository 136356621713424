import { AB_TEST_BONUSES_LOBBY } from '@namespace/ab-test/src/constants';
import { selectBonusSystemBonus } from '@namespace/bonuses';
import { useGeneralRoutes } from '@namespace/cms';
import { UserContext } from '@namespace/user';
import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const insertAfterId = (array, id, newElement) => {
  const index = array.findIndex((obj) => obj.id === id);

  if (index > -1) {
    return [
      ...array.slice(0, index + 1),
      newElement,
      ...array.slice(index + 1)
    ];
  }
  return array;
};

export const useLobbySectionsWithBonuses = (
  sections,
  casinoLobbyBonusesData = {}
) => {
  const {
    bonusSystemBonuses,
    activeBonusSystemBonusId,
    abTestCohort,
    logAction,
    isDepositBonusesOnLobby
  } = casinoLobbyBonusesData;

  const [user] = useContext(UserContext);
  const navigate = useNavigate();
  const { deposit } = useGeneralRoutes();

  const { id: userId } = user;

  const handleBonusClick = async ({ bonusId, isSelectedDeposit }) => {
    if (!bonusId) return;
    if (!isSelectedDeposit) await selectBonusSystemBonus({ bonusId });
    logAction({
      userId,
      additionalAttributes: { bonusId }
    });
    navigate(deposit);
  };

  const sectionsWithBonuses = useMemo(() => {
    if (!isDepositBonusesOnLobby) return sections;

    const bonusSliderSection = {
      type: 'BONUS_SLIDER',
      id: 'BONUS_SLIDER',
      handleBonusClick,
      bonusSystemBonuses,
      activeBonusSystemBonusId
    };

    if (abTestCohort === AB_TEST_BONUSES_LOBBY.COHORT1) {
      return insertAfterId(sections, 'recommended', bonusSliderSection);
    }
    if (abTestCohort === AB_TEST_BONUSES_LOBBY.COHORT2) {
      return insertAfterId(sections, 'hot', bonusSliderSection);
    }
    return sections;
  }, [
    sections,
    abTestCohort,
    bonusSystemBonuses,
    activeBonusSystemBonusId,
    isDepositBonusesOnLobby
  ]);
  return { sectionsWithBonuses };
};
