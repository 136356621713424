import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useUserSegmentsIncludes } from '@namespace/segments';
import useGetVirtualSportsSettings from './hooks/useGetVirtualSportsSettings';

export const useGetVirtualSportsFeedName = () => {
  const { defaultFeed, segmentedFeeds = [] } = useGetVirtualSportsSettings();
  const isUserSegmentsIncludes = useUserSegmentsIncludes();

  const segmentedFeedsDep = !isEmpty(segmentedFeeds)
    ? segmentedFeeds.join('')
    : segmentedFeeds;

  const segmentedFeedName = useMemo(() => {
    if (isEmpty(segmentedFeeds)) return null;

    const matchSegmentedFeed = segmentedFeeds.find((segmentedFeed) =>
      isUserSegmentsIncludes(segmentedFeed.userSegments)
    );

    return matchSegmentedFeed?.feedName;
  }, [segmentedFeedsDep, isUserSegmentsIncludes]);

  return segmentedFeedName || defaultFeed;
};
