import { useContext } from 'react';
import { useOtherSettings, useCookieManagementEnabled } from '@namespace/cms';
import { Helmet } from '@namespace/common';
import { window, COOKIE_MANAGEMENT_TYPES } from '@namespace/helpers';
import { CookieManagementContext } from '../../store/context';

const InitAnalytics = () => {
  const { analytics = {} } = useOtherSettings();
  const { googleTag: gTagConfig, facebookPixel, hotjar } = analytics;
  const [{ cookieManagementType }] = useContext(CookieManagementContext);
  const cookieManagementEnabled = useCookieManagementEnabled();

  const IS_ANALYTICAL = cookieManagementType?.includes(
    COOKIE_MANAGEMENT_TYPES.ANALYTICAL
  );
  const turnAnalyticLibsOn =
    process.env.NODE_ENV !== 'development' ||
    process.env.TURN_ANALYTIC_LIBS_ON === 'true' ||
    process.env.AMPLITUDE_ANALYTIC_ON === 'true';

  if (
    (gTagConfig || facebookPixel || hotjar) &&
    ((cookieManagementType?.length && turnAnalyticLibsOn && IS_ANALYTICAL) ||
      (!cookieManagementEnabled &&
        turnAnalyticLibsOn &&
        window.analyticsInTemplateDisabled))
  ) {
    return (
      <Helmet>
        {gTagConfig && (
          <script>
            {`
        var googleTagConfig = ${JSON.stringify(gTagConfig)};
        var googleKeys = googleTagConfig[window.location.hostname] || googleTagConfig.default;
        `}
          </script>
        )}
        {/* Google Tag Manager */}
        {gTagConfig && (
          <script>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': 
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], 
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); 
         })(window,document,'script','dataLayer',googleKeys.GTM);`}
          </script>
        )}
        {/* End Google Tag Manager */}
        {/* Global site tag (gtag.js) - Google Analytics */}
        {gTagConfig && (
          <script>
            {`if (googleKeys.GA) { 
          var googleTag = document.createElement('script'); 
          googleTag.setAttribute('async', ''); 
          googleTag.src = 'https://www.googletagmanager.com/gtag/js?id=' + googleKeys.GA; 
          var firstScriptTag = document.getElementsByTagName('script')[0]; 
          firstScriptTag.parentNode.insertBefore(googleTag, firstScriptTag); 
         }`}
          </script>
        )}
        {gTagConfig && (
          <script>
            {`if (googleKeys.GA) { 
          window.dataLayer = window.dataLayer || []; 
          function gtag(){dataLayer.push(arguments);} 
          gtag('js', new Date()); 
          gtag('config', googleKeys.GA); 
         }`}
          </script>
        )}
        {gTagConfig && (
          <script>
            {`if (googleKeys.GA) { 
          window.dataLayer = window.dataLayer || []; 
          function gtag(){dataLayer.push(arguments);} 
          gtag('js', new Date()); 
          gtag('config', googleKeys.GA); 
         }`}
          </script>
        )}
        {/* End global site tag - Google Analytics */}
        {hotjar && (
          <script>
            {`var hotjarConfig = ${JSON.stringify(hotjar)};
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function()

              {(h.hj.q=h.hj.q||[]).push(arguments)}
              ;
              h._hjSettings={hjid:hotjarConfig.hjid,hjsv:hotjarConfig.hjsv};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
          </script>
        )}
        {facebookPixel?.id &&
          `<!-- Facebook Pixel Code -->\n\n<script>\n\n!function (f, b, e, v, n, t, s)\n\n{if (f.fbq) return; n = f.fbq = function () {n.callMethod?\n\nn.callMethod.apply (n, arguments): n.queue.push (arguments)};\n\nif (! f._fbq) f._fbq = n; n.push = n; n.loaded =! 0; n.version = '2.0';\n\nn.queue = []; t = b.createElement (e); t.async =! 0;\n\nt.src = v; s = b.getElementsByTagName (e) [0];\n\ns.parentNode.insertBefore (t, s)} (window, document, 'script',\n\n'https://connect.facebook.net/en_US/fbevents.js');\n\nfbq ('init', '${facebookPixel.id}');\n\nfbq ('track', 'PageView');\n\n<\/script>\n\n<noscript> <img height = \"1\" width = \"1\" style = \"display: none\"\n\nsrc = \"https://www.facebook.com/tr?id=${facebookPixel.id}&ev=PageView&noscript=1\"\n\n/> </noscript>\n\n<!-- End Facebook Pixel Code -->`}
      </Helmet>
    );
  }
  return null;
};

export default InitAnalytics;
