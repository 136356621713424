import { useMemo, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useI18n } from '@namespace/i18n';
import { Input } from '@alf/uikit';
import { passwordValidator, hasRulesError } from '@alf/validators';
import { logAmplitude } from '@namespace/analytics';
import getFormError from '../../utils/getFormError';
import useDynamicField from '../../hooks/useDynamicField';
import useCustomError from '../../hooks/useCustomError';
import useOnChangeValidate from '../../hooks/useOnChangeValidate';
import Control from './components/Control';
import useRulesList from './utils/useRulesListState';

const PasswordField = ({
  name,
  label = '',
  hint = '',
  placeholder,
  rulesList,
  classNames = {},
  rules = {},
  isOnChangeValidation = false,
  customError = '',
  dataRole = '',
  formProps = {},
  typeValidation,
  showRulesOnChange,
  analyticAction,
  ...props
}) => {
  const { t } = useI18n();
  const methods = useFormContext();
  const {
    control,
    formState: { errors },
    watch
  } = methods;
  const password = watch(name, null);

  const rulesListState = useRulesList({
    isValueSensitive: showRulesOnChange,
    rules: rulesList,
    value: password
  });
  const { value: textRules } = rulesListState;

  const pasRules = useMemo(
    () => passwordValidator(textRules, '', rules, typeValidation),
    [rules, textRules, typeValidation]
  );
  const { inputClassName = '', containerClassName = '' } = classNames;
  const { errorMessage, customErrorHandler } = useCustomError(customError);
  const errorText = getFormError(name, errors, t) || t(errorMessage) || '';
  const hasError = errorText || hasRulesError(password, textRules);
  const { dynamicValues = {} } = formProps;

  useDynamicField({ name, dynamicFieldData: dynamicValues[name] });
  useOnChangeValidate({ name, isOnChangeValidation });

  const handleFocus = useCallback(
    (cb) => (e) => {
      rulesListState.focus();

      props.onFocus?.(e);
      cb?.(e);
    },
    [rulesListState, props]
  );

  const handleBlur = useCallback(
    (onBlur) => (e) => {
      rulesListState.unfocus();

      if (analyticAction) {
        logAmplitude(analyticAction, {
          valid: !hasError
        });
      }
      props.onBlur?.(e);
      onBlur?.(e);
    },
    [analyticAction, hasError, props, rulesListState]
  );

  return (
    <Control
      dataRole={dataRole}
      classNames={classNames}
      error={errorText}
      onClick={customErrorHandler}
      value={password}
      ruleList={textRules}
      hint={hint}
      id={name}
      label={typeof label === 'object' ? label : t(label)}
    >
      <Controller
        name={name}
        control={control}
        rules={pasRules}
        render={({ field: { ref, ...field } }) => {
          return (
            <Input
              {...props}
              id={name}
              intent={hasError ? 'error' : null}
              type="password"
              data-role={dataRole}
              className={inputClassName}
              wrapperClassName={containerClassName}
              placeholder={t(placeholder)}
              {...field}
              onFocus={handleFocus(field.onFocus)}
              onBlur={handleBlur(field.onBlur)}
            />
          );
        }}
      />
    </Control>
  );
};

export default PasswordField;
