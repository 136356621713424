import clsx from 'clsx';
import { SKELET_ITEMS_COUNT } from '../../constants';
import styles from './index.module.css';

const SkeletTable = ({ classNames = {}, skeletItems = SKELET_ITEMS_COUNT }) => {
  const itemList = new Array(skeletItems).fill(1);
  const { classNameWrapper, classNameItem } = classNames;

  return (
    <div className={`${styles.skeletTableWrapper} ${classNameWrapper}`}>
      {itemList.map((_, index) => (
        <div
          key={index} // eslint-disable-line react/no-array-index-key
          className={clsx(
            styles.skeletAnimation,
            styles.skeletTableItem,
            classNameItem
          )}
        />
      ))}
    </div>
  );
};

export default SkeletTable;
