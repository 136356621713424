import { useState } from 'react';
import { SingleFilter } from './SingleFilter';
import { MultiFilter } from './MultiFilter';

const DataFilter = ({
  filtersConfig,
  changedFilters,
  appliedFilters,
  onSet,
  onChange,
  isChanged,
  changesCount,
  isInline,
  className = '',
  filterGroupClassName = '',
  dropdownFilterClassName = '',
  triggerClassName = '',
  filterClassName = '',
  filterButtonClassName = '',
  arrowConfig,
  dataRole
}) => {
  const [isOpen, setOpen] = useState(false);
  const filterOptions = {
    filtersConfig,
    changedFilters,
    appliedFilters,
    onSet,
    onChange,
    isChanged,
    changesCount,
    isOpen,
    setOpen,
    isInline,
    className,
    filterGroupClassName,
    dropdownFilterClassName,
    triggerClassName,
    filterClassName,
    filterButtonClassName,
    arrowConfig,
    dataRole
  };
  const Component = filtersConfig.length === 1 ? SingleFilter : MultiFilter;

  return <Component {...filterOptions} />;
};

export default DataFilter;
