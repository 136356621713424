import { Box } from '@alf/uikit';
import {
  getSlidePlatformImage,
  SiteSettingsContext,
  useConfig
} from '@namespace/cms';
import { useOnSlideMove } from '@namespace/common';
import { DeviceContext } from '@namespace/device';
import { useI18n } from '@namespace/i18n';
import { useUserSegmentsIncludes } from '@namespace/segments';
import { useGetImage } from '@namespace/themes';
import { UserContext } from '@namespace/user';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  CardSkelet,
  EventsSlider,
  SportTeaser,
  WidgetWrapper
} from '../../common';
import {
  LINE_KEY,
  TEASERS_NAVIGATION_TYPES,
  TEASER_TYPES
} from '../../constants';
import { useRemoveLine } from '../../hooks/useRemoveLine';
import { sportsbookActions, useSportsbookStore } from '../../store';
import { getOutcomesForMarket } from '../../utils';
import ImageTeaser from './ImageTeaser';
import PromoTeaser from './PromoTeaser';
import styles from './index.module.css';

const { FETCH_TEASERS2 } = sportsbookActions;

const DURATION = 5;
const SLIDE_SPEED = 700;

const SportsBookTeasers = ({ id }) => {
  const { config } = useConfig(id);
  const { language: lang, f } = useI18n();
  const { pageType } = useContext(DeviceContext);
  const {
    slides: configSlides = [],
    rotationDelay = DURATION,
    height,
    pauseRotationOnFocus = false,
    navigation
  } = config;
  const isBulletsEnabled =
    navigation === TEASERS_NAVIGATION_TYPES.BULLETS ||
    navigation === TEASERS_NAVIGATION_TYPES.BULLETS_AND_ARROWS;
  const isArrowsEnabled =
    navigation === TEASERS_NAVIGATION_TYPES.BULLETS_AND_ARROWS;
  const [user] = useContext(UserContext);
  const { activeWallet } = user;
  const { currency } = activeWallet;
  const [settings] = useContext(SiteSettingsContext);
  const { siteConfig } = settings;
  const { defaultCurrency } = siteConfig;
  const {
    teasers,
    markets,
    lines: { [LINE_KEY.TEASERS2]: teaserLine = {} },
    outcomes: stateOutcomes
  } = useSportsbookStore(['teasers', 'markets', 'lines', 'outcomes']);
  const filterSegments = useUserSegmentsIncludes();
  const { handleClickOnMove, setIsMove } = useOnSlideMove();
  const getImg = useGetImage();

  const { eventsIds = [] } = teaserLine;
  const slides = useMemo(
    () => configSlides.map((slide, i) => ({ ...slide, order: i })),
    [configSlides]
  );

  const teasersNames = useMemo(
    () => slides.map(({ teaserName }) => teaserName).filter(Boolean),
    [slides]
  );
  const promoTeasers = useMemo(
    () =>
      slides.filter((slide) => {
        const { snippetId, from, to, source } = slide;

        if (
          (source === TEASER_TYPES.HTML_SNIPPET && snippetId) ||
          source === TEASER_TYPES.IMAGE
        ) {
          if (from || to) {
            return f.localTimeIsActual(from, to, f.getDateTime);
          }

          return true;
        }

        return false;
      }),
    [f, slides]
  );

  // const promoTeasers = useMemo(() => filterPromoTeasers(slides), [f, slides]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (teasersNames.length) {
      FETCH_TEASERS2({
        lineKey: LINE_KEY.TEASERS2,
        teasersNames,
        lang
      }).finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [lang, teasersNames]);

  const allSlides = useMemo(
    () =>
      [
        ...teasers
          .map(({ teaserName, ...teaser }) => ({
            ...slides.find((slide) => teaserName === slide.teaserName),
            teaserName,
            ...teaser
          }))
          .filter(
            (slide) =>
              eventsIds.includes(slide.eventId) &&
              teasersNames.includes(slide.teaserName)
          ),
        ...promoTeasers
      ]
        .filter((slide) => filterSegments(slide.userSegments))
        .sort((a, b) => a.order - b.order),
    [teasers, promoTeasers, slides, eventsIds]
  );

  useRemoveLine(LINE_KEY.TEASERS2);

  if (!isLoading && (!allSlides.length || !teaserLine)) {
    return null;
  }

  return (
    <WidgetWrapper>
      {isLoading ? (
        <Box className={styles.skeletWrapper}>
          <CardSkelet />
        </Box>
      ) : (
        <EventsSlider
          key={allSlides.length}
          sliderOptions={{
            className: styles.general,
            duration: SLIDE_SPEED,
            rotationInterval: rotationDelay,
            height,
            circular: true
          }}
          pauseRotationOnFocus={pauseRotationOnFocus}
          isBulletsEnabled={isBulletsEnabled}
          isArrowsEnabled={isArrowsEnabled}
          setIsMove={setIsMove}
        >
          {allSlides.map((teaser) => {
            const { eventId, snippetId, source } = teaser;
            if (snippetId) {
              return (
                <PromoTeaser
                  gap="10px"
                  key={teaser.id}
                  snippetId={snippetId}
                  className={styles.teaser}
                  handleClickOnMove={handleClickOnMove}
                />
              );
            }

            if (source === TEASER_TYPES.IMAGE) {
              return (
                <ImageTeaser
                  gap="10px"
                  key={teaser.id}
                  slide={teaser}
                  currency={currency || defaultCurrency}
                  className={styles.teaser}
                  handleClickOnMove={handleClickOnMove}
                />
              );
            }

            const { eventsMarketsIds } = teaserLine;

            if (eventId && eventsMarketsIds[eventId]) {
              const [marketId] = eventsMarketsIds[eventId];
              const market = markets[marketId];
              const imageLink = getSlidePlatformImage(
                teaser,
                pageType,
                lang,
                currency || defaultCurrency,
                getImg
              );
              const { outcomeIds } = market || {};
              const outcomes = getOutcomesForMarket(outcomeIds, stateOutcomes);

              return (
                <SportTeaser
                  gap="10px"
                  key={teaser.id}
                  className={styles.teaser}
                  teaserPicture={imageLink}
                  eventId={eventId}
                  market={market}
                  outcomes={outcomes}
                  lineKey={teaserLine}
                  handleClickOnMove={handleClickOnMove}
                />
              );
            }
            return null;
          })}
        </EventsSlider>
      )}
    </WidgetWrapper>
  );
};

export default SportsBookTeasers;
