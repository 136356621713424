import { SocketClient } from '../../classes/SocketClient';
import { SOCKET_URLS } from '../../constants';

let bulletInstance;

export const getBulletInstance = () => {
  if (!bulletInstance) {
    bulletInstance = new SocketClient();
  }

  return bulletInstance;
};

export const bulletSend = (...args) => getBulletInstance().send(...args);

export const bulletSubscribe = (...args) =>
  getBulletInstance().subscribe(...args);

export const bulletUnsubscribe = (...args) =>
  getBulletInstance().unsubscribe(...args);

export const bulletConnect = () =>
  getBulletInstance().connect({
    url: SOCKET_URLS.BULLET,
    withCid: false,
    isOkPing: true,
    pingDelay: process.env.REACT_APP_SOCKET_PING_DELAY,
    maxReconnectionAttempts:
      process.env.REACT_APP_MAX_SOCKET_RECONNECTION_ATTEMPTS
  });
