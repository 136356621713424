import { useEffect } from 'react';
import { CASHING_PERIOD } from '../../constants';

const isInvalidCachedData = (dataCachingTime) =>
  dataCachingTime ? dataCachingTime + CASHING_PERIOD < Date.now() : false;

const useCacheLoad = ({ dataMustBeLoaded, action, dataCachingTime }) => {
  useEffect(() => {
    if (dataMustBeLoaded || isInvalidCachedData(dataCachingTime)) {
      action();
    }
  }, [dataMustBeLoaded, dataCachingTime]);
};

export default useCacheLoad;
