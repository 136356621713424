import { useFormContext } from 'react-hook-form';
import { useI18n } from '@namespace/i18n';
import InputField from '../../common/InputField';
import { getDocumentsValidation } from './documentsVerificationMap';
import { DOC_TYPE_IDS } from '../../constants';

const DocumentNumber = ({
  name,
  rules = {},
  label = '',
  placeholder,
  dependentName,
  documentsNumberValidationMap,
  controlProps = {},
  dataRole,
  ...props
}) => {
  const { t } = useI18n();
  const { getValues, watch } = useFormContext();
  const { documentTypeId } = getValues();
  const { value: dependentValue } =
    (dependentName && watch(dependentName)) || {};

  return (
    <InputField
      dataRole={`${dataRole}-document-number`}
      name={name}
      key={dependentValue || watch(dependentName)}
      controlProps={controlProps}
      rules={
        DOC_TYPE_IDS.includes(documentTypeId)
          ? {
              ...rules,
              validate: (value) => {
                const { regex, translation } = getDocumentsValidation(
                  getValues(),
                  documentsNumberValidationMap
                );
                return !regex || regex.test(value) ? true : translation;
              }
            }
          : undefined
      }
      {...props}
      label={t(`${label}_${dependentValue}`, t(label))}
      placeholder={t(`${placeholder}_${dependentValue}`, t(placeholder))}
    />
  );
};

export default DocumentNumber;
