import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const useDirty = (defaultValues, fieldsList = []) => {
  const { watch } = useFormContext();
  const [isDirty, setIsDirty] = useState(false);
  const formFields = {};
  useMemo(() => {
    for (const fieldName of Object.keys(defaultValues)) {
      if (fieldsList.includes(fieldName)) {
        formFields[fieldName] = watch(fieldName);
      }
    }
  }, [formFields, watch]);

  useEffect(() => {
    setIsDirty(false);
    const isChanged = Object.keys(formFields).find(
      (fieldName) => formFields[fieldName] !== defaultValues[fieldName]
    );
    if (isChanged) {
      setIsDirty(true);
    }
  }, [formFields, setIsDirty]);

  return isDirty;
};

export default useDirty;
