import sportsLobby from './pages/sports-lobby.json';
import sportsSport from './pages/sports-sport.json';
import sportsCategory from './pages/sports-category.json';
import sportsTournament from './pages/sports-tournament.json';
import sportsFilter from './pages/sports-filter.json';
import sportsEvent from './pages/sports-event.json';
import sportsFavorites from './pages/sports-favorites.json';
import sportsTop from './pages/sports-top.json';
import liveAll from './pages/live-all.json';
import liveSport from './pages/live-sport.json';
import liveEvent from './pages/live-event.json';
import liveFavorites from './pages/live-favorites.json';
import personalOffice from './pages/personal-office.json';
import personalOfficeMessages from './pages/personal-office-messages.json';
import personalOfficeMessagesInbox from './pages/personal-office-messages-inbox.json';
import personalOfficeMessagesSent from './pages/personal-office-messages-sent.json';
import personalOfficeMessagesNew from './pages/personal-office-messages-new.json';
import personalOfficeMessagesPreview from './pages/personal-office-messages-preview.json';
import personalOfficeProfile from './pages/personal-office-profile.json';
import personalOfficeProfileData from './pages/personal-office-profile-data.json';
import personalOfficeProfileDocuments from './pages/personal-office-profile-documents.json';
import personalOfficeProfileChangepass from './pages/personal-office-profile-changepass.json';
import personalOfficeProfileNotifications from './pages/personal-office-profile-notifications.json';
import personalOfficeBets from './pages/personal-office-bets.json';
import personalOfficeBalance from './pages/personal-office-balance.json';
// import personalOfficeDeposit from './pages/personal-office-balance-deposit.json';
// import personalOfficeCreateWallet from './pages/personal-office-balance-create-wallet.json';
import personalOfficeDocuments from './pages/personal-office-balance-documents.json';
import personalOfficeDocumentsSuccess from './pages/personal-office-balance-documents-success.json';
// import personalOfficeWallets from './pages/personal-office-balance-wallets.json';
// import personalOfficeWithdraw from './pages/personal-office-balance-withdraw.json';
import termsConditions from './pages/rules.json';
import login from './pages/login.json';
import register from './pages/register.json';
import registerSuccess from './pages/register-success.json';
import casino from './pages/casino.json';
import casinoShowGame from './pages/casino-show-game.json';
import casinoLauncher from './pages/casino-launch.json';
import casinoLaunchRedirect from './pages/casino-launch-redirect.json';
import forgotPassword from './pages/forgot-password.json';
import casinoSearch from './pages/casino-search.json';
import results from './pages/results.json';
import lottery from './pages/lottery.json';
import betGames from './pages/betgames.json';
import cyberSport from './pages/cybersport.json';
import cyberSportCategory from './pages/cybersport-category.json';
import cyberSportTournament from './pages/cybersport-tournament.json';
import cyberSportFavorites from './pages/cybersport-favorites.json';
import sportsSearch from './pages/sports-search.json';
import sportsMultipleDay from './pages/sports-multiple-day.json';
import betsRedirect from './pages/bets-redirect.json';
import betsFromExternal from './pages/bets-add-external.json';
import referPhp from './pages/refer-php.json';
import depositResult from './pages/personal-office-deposit-result.json';
import personalOfficeBetsCasino from './pages/personal-office-bets-casino.json';
import personalOfficeBetsSport from './pages/personal-office-bets-sport.json';
import personalOfficeBetsDetails from './pages/personal-office-bets-details.json';
import personalOfficeDepositHistory from './pages/personal-office-deposits-history.json';
import personalOfficeWithdrawalHistory from './pages/personal-office-withdrawal-history.json';

import personalOfficeSingleWalletDeposit from './pages/personal-office-balance-sw-deposit.json';
import personalOfficeSingleWalletWithdraw from './pages/personal-office-balance-sw-withdraw.json';

const PROD_PAGES = {
  '/sports': {
    '/sports': sportsLobby,
    '/sports/sport': sportsSport,
    '/sports/category': sportsCategory,
    '/sports/tournament': sportsTournament,
    '/sports/event': sportsEvent,
    '/sports/top': sportsTop,
    '/sports/search': sportsSearch,
    '/sports/redirect/bet': betsFromExternal,
    '/sports/favorites': sportsFavorites,
    '/sports/multiple-day': sportsMultipleDay,
    '/sports/filter': sportsFilter
  },
  '/results': results,
  '/live': {
    '/live': liveSport,
    '/live/all': liveAll,
    '/live/event': liveEvent,
    '/live/favorites': liveFavorites
  },
  '/e-sports': {
    '/e-sports': cyberSport,
    '/e-sports/category': cyberSportCategory,
    '/e-sports/tournament': cyberSportTournament,
    '/e-sports/favorites': cyberSportFavorites
  },
  '/personal-office': {
    '/personal-office': personalOffice,
    '/personal-office/balance/sw-deposit': personalOfficeSingleWalletDeposit,
    '/personal-office/balance/sw-withdraw': personalOfficeSingleWalletWithdraw,
    '/personal-office/balance': personalOfficeBalance,
    '/personal-office/bets': personalOfficeBets,
    // '/personal-office/balance/deposit': personalOfficeDeposit,
    // '/personal-office/balance/withdraw': personalOfficeWithdraw,
    // '/personal-office/balance/create-wallet': personalOfficeCreateWallet,
    '/personal-office/balance/documents': personalOfficeDocuments,
    '/personal-office/balance/documents/success': personalOfficeDocumentsSuccess,
    // '/personal-office/balance/wallets': personalOfficeWallets,
    '/personal-office/messages': personalOfficeMessages,
    '/personal-office/messages/inbox': personalOfficeMessagesInbox,
    '/personal-office/messages/sent': personalOfficeMessagesSent,
    '/personal-office/messages/newMessage': personalOfficeMessagesNew,
    '/personal-office/messages/view': personalOfficeMessagesPreview,
    '/personal-office/profile': personalOfficeProfile,
    '/personal-office/bets/casino': personalOfficeBetsCasino,
    '/personal-office/bets/sport': personalOfficeBetsSport,
    '/personal-office/bets/details/:id': personalOfficeBetsDetails,
    '/personal-office/profile/data': personalOfficeProfileData,
    '/personal-office/profile/documents': personalOfficeProfileDocuments,
    '/personal-office/profile/changepass': personalOfficeProfileChangepass,
    '/personal-office/profile/notifications': personalOfficeProfileNotifications,
    '/personal-office/balance/deposit/result': depositResult,
    '/personal-office/balance/history-deposits': personalOfficeDepositHistory,
    '/personal-office/balance/history-withdrawals': personalOfficeWithdrawalHistory
  },
  '/login': login,
  '/register': {
    '/register': register,
    '/register/success': registerSuccess
  },
  '/forgot-password': forgotPassword,
  '/casino': {
    '/casino': casino,
    '/casino/launch': casinoLauncher,
    '/casino/launch-redirect': casinoLaunchRedirect,
    '/casino/show-game': casinoShowGame,
    '/casino/search': casinoSearch
  },
  '/lottery': lottery,
  '/bet-games': betGames,
  '/bets': betsRedirect,
  '/refer.php': referPhp,
  '/rules': termsConditions
};

const DEV_PAGES = {};

export const FALLBACK_PAGES =
  process.env.NODE_ENV === 'development'
    ? { ...PROD_PAGES, ...DEV_PAGES }
    : PROD_PAGES;
