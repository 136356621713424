import { useContext, useEffect } from 'react';
import {
  COOKIES_KEYS,
  document,
  getCookie,
  IS_SERVER_RENDER,
  IS_PRERENDER,
  removeCookie,
  window
} from '@namespace/helpers';
import { useListenPWBNotifications } from '@namespace/bonuses';
import { useGetSiteSettings, useOtherSettings } from '@namespace/cms';
import {
  LOG_ACTIONS,
  logAction,
  logPostConversionTracking,
  useGA,
  useAmplitude
} from '@namespace/analytics';
import { useWidget } from '@namespace/widget';
import {
  useAddictedUser,
  useGetPartnerConfig,
  useIsVipFlag,
  useLudomanInVerification,
  USER_ROLES,
  UserContext,
  useRealityCheck,
  useSessionExpireCheck,
  useUserBonusModel,
  useVerificationStatusNotification
} from '@namespace/user';
import { useNativeApp } from '@namespace/native-app';
import {
  useGetMessages,
  useShowAfterLoginNotifications,
  useStatusesUpdate
} from '@namespace/account';
import { useInitABTest, useInitABSegments } from '@namespace/ab-test';
import {
  useConfigurePaymentSystems,
  useGetServicesLimits,
  useListenSwitchingWallet
} from '@namespace/payments';
import {
  useGetPaymentsConfigSW,
  useBalanceBullet
} from '@namespace/payments-sw';
import { useLoadMocks } from '@namespace/onboarding';
import { REDIRECT_ACTIONS, useRedirectAction } from '@namespace/common';
import { SupportChatPicker, useLiveChat, useWebim } from '@namespace/chats';
import { isEmpty } from 'lodash';
import usePostResizeListener from '../../hooks/usePostResizeListener';
import useAffiliateLink from '../../hooks/useAffiliateLink';
import useSessionDurationNotice from '../../hooks/useSessionDurationNotice';
import useSupportChat from '../../../../chats/src/hooks/useSupportChat';
import useWindowPurpose from '../../hooks/useWindowPurpose';
import useBackUp from '../../hooks/useBackUp';
import useGetUserData from './useGetUserData';
import useLanguage from './useLanguage';
import useLinkSlash from './useLinkSlash';
import useAddUserIdToWindow from './useAddUserIdToWindow';
import useSSID from './useSSID';
import useDepositAnalytics from './useDepositAnalytics';
import DefaultErrorPage from '../Page/DefaultErrorPage';
import useGetBonusWallet from './useGetBonusWallet';
import useSetBodyTag from '../../hooks/useSetBodyTag';
import { useSocketConnect } from '../../hooks/useSocketConnect';
import { useAbTestsSettings } from '../../hooks/useAbTestsSettings';
import { useSuspendedUser } from '../../hooks/useSuspendedUser';
import { useUnverifiedUser } from '../../hooks/useUnverifiedUser';
import { DisconnectedPopup } from '../DisconnectedPopup';

const ContentForUser = ({ FALLBACK_SITE_SETTINGS }) => {
  const [user] = useContext(UserContext);
  const { id: userId, role, title: sex } = user;

  useSetBodyTag(sex);
  useBalanceBullet();
  useListenSwitchingWallet();
  useGetServicesLimits();
  useGetPartnerConfig();
  useGetPaymentsConfigSW();
  useConfigurePaymentSystems();
  useLoadMocks();

  useSessionDurationNotice();
  useIsVipFlag();
  useRealityCheck();
  useSessionExpireCheck();
  useSuspendedUser();
  useAddictedUser();
  useUnverifiedUser();
  useVerificationStatusNotification();
  useStatusesUpdate();
  useLudomanInVerification(true);
  useGA(userId, Boolean(role === USER_ROLES.USER));
  useAmplitude(userId);
  useAddUserIdToWindow(userId, Boolean(role === USER_ROLES.USER));

  const bulletDepositAnalyticsEnabled =
    FALLBACK_SITE_SETTINGS?.siteConfig?.bulletDepositAnalytics;
  useDepositAnalytics(bulletDepositAnalyticsEnabled);

  useGetBonusWallet();

  useGetMessages();

  useRedirectAction(REDIRECT_ACTIONS.APPLY);

  useShowAfterLoginNotifications();

  return null;
};

const ContentForAll = () => {
  const { postConversionType } = useOtherSettings();
  const abTests = useAbTestsSettings();
  useWindowPurpose();

  useSupportChat();
  useLiveChat();
  useWebim();

  useUserBonusModel();
  useSSID();
  useWidget();

  usePostResizeListener(document.getElementById('root'));
  useNativeApp();

  useInitABTest(abTests);

  useAffiliateLink();

  useRedirectAction(REDIRECT_ACTIONS.SET);

  useBackUp();

  useInitABSegments();

  // moved from ContentForUser because of after deposit notification
  useListenPWBNotifications();

  useEffect(() => {
    if (!IS_SERVER_RENDER) {
      const div = document.createElement('div');
      div.classList.add('SUCCESS_RENDERED');
      div.classList.add(`IS_PRERENDER_${IS_PRERENDER}`);
      document.body.appendChild(div);
    }
  }, []);

  useEffect(() => {
    if (!IS_SERVER_RENDER) {
      window.logAction = logAction;
      logPostConversionTracking(LOG_ACTIONS.POST_SESSION, postConversionType);
    }
  }, []);

  return <SupportChatPicker />;
};

const Content = ({ children, FALLBACK_SITE_SETTINGS }) => {
  const [user] = useContext(UserContext);
  const { role, userInfoLoaded } = user;
  const {
    isFatalError,
    isDisconnected,
    setIsDisconnected
  } = useSocketConnect();

  let isSiteSettingsReady = useGetSiteSettings(FALLBACK_SITE_SETTINGS);
  if (!isSiteSettingsReady) {
    isSiteSettingsReady =
      window.IS_SSR && (!isEmpty(window.siteSettings) || IS_SERVER_RENDER);
  }

  useGetUserData();
  useLanguage(isSiteSettingsReady);
  useLinkSlash(isSiteSettingsReady);

  if (isFatalError) {
    return (
      <DefaultErrorPage
        errorCode={500}
        text="Technical issues... we'll be back soon!"
      />
    );
  }

  if (!isSiteSettingsReady) {
    return null;
  }

  if (
    userInfoLoaded &&
    role === USER_ROLES.GUEST &&
    getCookie(COOKIES_KEYS.LOG_ON)
  ) {
    removeCookie(COOKIES_KEYS.LOG_ON);
  }

  return (
    <>
      <ContentForAll />
      {role === USER_ROLES.USER && (
        <ContentForUser FALLBACK_SITE_SETTINGS={FALLBACK_SITE_SETTINGS} />
      )}
      {children}
      {isDisconnected && (
        <DisconnectedPopup setIsDisconnected={setIsDisconnected} />
      )}
    </>
  );
};

export default Content;
