import { useMemo, useState } from 'react';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { Box, AText, LazyLoadImage } from '@alf/uikit';
import JackpotItem from '../JackpotItem';
import { formatJackpot, getSortedJackpotTypes } from '../utils';

import styles from './index.module.css';

const JACKPOT_IDENTIFICATOR_TYPES = {
  JACKPOT_IMAGE: 'JACKPOT_IMAGE',
  PROVIDER_TITLE: 'PROVIDER_TITLE'
};

function getPreferedJackpotIdentificator({
  jackpotImage,
  isLoadJackpotImageError
}) {
  if (jackpotImage && !isLoadJackpotImageError)
    return JACKPOT_IDENTIFICATOR_TYPES.JACKPOT_IMAGE;

  return JACKPOT_IDENTIFICATOR_TYPES.PROVIDER_TITLE;
}

export const JackpotSingleRow = ({
  jackpotIdentifier,
  jackpot,
  jackpotAction,
  jackpotImage,
  currency,
  providedServiceId,
  handleJackpotClick
}) => {
  const { t } = useI18n();
  const [isLoadJackpotImageError, setIsLoadJackpotImageError] = useState(false);
  const formattedJackpot = useMemo(
    () => formatJackpot(jackpot, jackpotIdentifier),
    [jackpot, jackpotIdentifier]
  );
  const sortedJackpotTypes = useMemo(
    () => getSortedJackpotTypes(formattedJackpot, jackpotIdentifier),
    [formattedJackpot, jackpotIdentifier]
  );
  const jackpotIdentificatorType = getPreferedJackpotIdentificator({
    jackpotImage,
    isLoadJackpotImageError
  });

  const handleJackpotImageLoadError = () => {
    setIsLoadJackpotImageError(true);
  };

  return (
    <LocalizedLink
      className={styles.jackpotByProviderSingle}
      onClick={() => handleJackpotClick(jackpotAction)}
      to={jackpotAction}
    >
      <Box direction="row" align="center" className={styles.wrapper}>
        {sortedJackpotTypes.length === 4 ? (
          <Box
            justify="around"
            align="center"
            flex="1"
            className={styles.itemsList}
          >
            {sortedJackpotTypes.slice(0, 2).map((type) => (
              <JackpotItem
                key={`${jackpotIdentifier}_${type}`}
                type={type}
                jackpotAmounts={formattedJackpot[type]}
                jackpotIdentifier={jackpotIdentifier}
                currency={currency}
              />
            ))}
            <Box>
              {jackpotIdentificatorType ===
                JACKPOT_IDENTIFICATOR_TYPES.JACKPOT_IMAGE && (
                <LazyLoadImage
                  alt={`${t(
                    `games.jackpot.${providedServiceId}.title`
                  )} jackpot`}
                  src={jackpotImage}
                  onError={handleJackpotImageLoadError}
                  className={styles.jackpotSingleJackpotImage}
                />
              )}
              {jackpotIdentificatorType ===
                JACKPOT_IDENTIFICATOR_TYPES.PROVIDER_TITLE && (
                <AText
                  className={styles.title}
                  color="accent_color_3"
                  breed="BU"
                  align="center"
                  size="2xl"
                >
                  {t(`games.jackpot.${providedServiceId}.title`)}
                </AText>
              )}
            </Box>
            {sortedJackpotTypes.slice(-2).map((type) => (
              <JackpotItem
                key={`${jackpotIdentifier}_${type}`}
                type={type}
                jackpotAmounts={formattedJackpot[type]}
                jackpotIdentifier={jackpotIdentifier}
                currency={currency}
              />
            ))}
          </Box>
        ) : (
          <Box
            justify="center"
            align="center"
            flex="1"
            className={
              sortedJackpotTypes.length > 4
                ? styles.itemsListSmallGap
                : styles.itemsList
            }
          >
            {jackpotIdentificatorType ===
              JACKPOT_IDENTIFICATOR_TYPES.JACKPOT_IMAGE && (
              <LazyLoadImage
                src={jackpotImage}
                onError={handleJackpotImageLoadError}
                className={styles.jackpotSingleJackpotImage}
                alt={`${t(`games.jackpot.${providedServiceId}.title`)} jackpot`}
              />
            )}
            {jackpotIdentificatorType ===
              JACKPOT_IDENTIFICATOR_TYPES.PROVIDER_TITLE && (
              <AText
                className={styles.title}
                color="accent_color_3"
                breed="BU"
                size="2xl"
              >
                {t(`games.jackpot.${providedServiceId}.title`)}
              </AText>
            )}
            {sortedJackpotTypes.map((type) => (
              <JackpotItem
                classNames={{ wrapperClassName: styles.jackpotItemWrapper }}
                key={`${jackpotIdentifier}_${type}`}
                type={type}
                jackpotAmounts={formattedJackpot[type]}
                jackpotIdentifier={jackpotIdentifier}
                currency={currency}
              />
            ))}
          </Box>
        )}
      </Box>
    </LocalizedLink>
  );
};
