import { useContext, useEffect } from 'react';
import clsx from 'clsx';
import { StepLine, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import Steps from '../Steps';
import { StepperContext } from '../../../../store/stepper/context';
import { STEP_TYPES } from '../../../../constants';
import {
  setFormDataAction,
  setStepAction
} from '../../../../store/stepper/actions';
import Header from '../../../../common/Header';
import styles from './index.module.css';

const Stepper = ({
  stepsMap,
  componentsMap,
  successMessage,
  defaultActiveStep,
  defaultFormData,
  header,
  onChangeStep = () => {},
  config = {},
  stepsClassNames = {}
}) => {
  const {
    headerClassName = '',
    headerClassNameDefault = '',
    stepsWrapperClassName = '',
    stepperWrapperClassName = ''
  } = stepsClassNames;
  const {
    dispatch,
    stepper: { activeStep, success, error }
  } = useContext(StepperContext);
  const {
    isShowStepLine = true,
    isShowStepLineOnSuccess = true,
    isNewFinalTitle = false
  } = config;

  const { t } = useI18n();

  const SuccessComponent = componentsMap[STEP_TYPES.SUCCESS];
  const ErrorComponent = componentsMap[STEP_TYPES.ERROR];

  useEffect(() => {
    if (defaultActiveStep) {
      dispatch(setStepAction(defaultActiveStep));
    }
  }, [defaultActiveStep]);

  useEffect(() => onChangeStep(activeStep), [activeStep]);

  useEffect(() => {
    if (defaultFormData) {
      dispatch(setFormDataAction(defaultFormData));
    }
  }, [defaultFormData]);

  if (success && SuccessComponent) {
    return (
      <Box
        align="center"
        direction="column"
        className={clsx([styles.stepperWrapper, stepperWrapperClassName])}
      >
        {isShowStepLineOnSuccess && (
          <Box
            className={clsx([styles.stepLineWrapper, stepsWrapperClassName])}
          >
            <StepLine
              itemClassNames={stepsClassNames}
              steps={stepsMap}
              activeStep={activeStep + 1}
            />
          </Box>
        )}
        <Header
          header={isNewFinalTitle ? t('passwordRecovery.newHeader') : header}
          className={clsx([
            styles.header,
            headerClassNameDefault,
            headerClassName
          ])}
        />
        <SuccessComponent message={successMessage} />
      </Box>
    );
  }

  if (error && ErrorComponent) {
    return (
      <ErrorComponent
        header={isNewFinalTitle ? t('passwordRecovery.newHeader') : header}
        itemClassNames={stepsClassNames}
      />
    );
  }

  return (
    <Box
      direction="column"
      align="center"
      className={clsx([styles.stepperWrapper, stepperWrapperClassName])}
    >
      {isShowStepLine && (
        <Box className={clsx([styles.stepLineWrapper, stepsWrapperClassName])}>
          <StepLine
            steps={stepsMap}
            itemClassNames={stepsClassNames}
            activeStep={activeStep}
          />
        </Box>
      )}
      <Header
        header={header}
        className={clsx([
          styles.header,
          headerClassNameDefault,
          headerClassName
        ])}
      />
      <Steps
        activeStep={activeStep}
        stepsMap={stepsMap}
        componentsMap={componentsMap}
        dispatch={dispatch}
        stepsClassNames={stepsClassNames}
      />
    </Box>
  );
};

export default Stepper;
