import clsx from 'clsx';
import { debounce } from 'lodash';
import { useGeneralRoutes, useOtherSettings } from '@namespace/cms';
import { Icon } from '@namespace/icons2';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { AText, Box } from '@alf/uikit';
import { useOpenChat, useSupport } from '@namespace/chats';
import { useEffect, useState } from 'react';
import { window } from '@namespace/helpers';
import styles from './index.module.css';

export const Support = ({
  className = '',
  hideNeedHelpLabel = false,
  dataRole = ''
}) => {
  const { t } = useI18n();
  const { helpCenter } = useGeneralRoutes();
  const { chatName } = useSupport();
  const { openChat } = useOpenChat(chatName);
  const { isHideNeedHelpLabel, customSupportIconPath } = useOtherSettings();
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = debounce(() => {
      setIsScrolling(false);
    }, 200);

    window.addEventListener('scroll', () => {
      setIsScrolling(true);
      handleScroll();
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box
      align="center"
      className={clsx(styles.wrapper, className)}
      data-role={`${dataRole}-support-wrapper`}
    >
      {!hideNeedHelpLabel && !isHideNeedHelpLabel && (
        <AText
          breed="R"
          size="m"
          color="txt_color_7"
          component={LocalizedLink}
          to={helpCenter}
          className={styles.needHelp}
          data-role={`${dataRole}-support-text`}
        >
          {t('header.submenu.needHelp')}
        </AText>
      )}
      <Box
        className={clsx(styles.button, isScrolling && styles.hidden)}
        onClick={openChat}
        padding="s"
        data-role={dataRole && `${dataRole}-support-button`}
      >
        <Icon
          name={customSupportIconPath || 'icons/user_menu/support'}
          color="txt_color_6"
          size="l"
        />
      </Box>
    </Box>
  );
};
