import { useState, useEffect } from 'react';
import { useI18n } from '@namespace/i18n';
import styles from './index.module.css';

const useTimer = (currentDate = new Date(), interval = 1000) => {
  const [date, setDate] = useState(currentDate);

  useEffect(() => {
    const timerID = setInterval(() => setDate(new Date()), interval);
    return () => clearInterval(timerID);
  }, [interval]);

  return date;
};

const Clock = ({ className = '' }) => {
  const timer = useTimer();
  const { f, language } = useI18n();
  const DATE_FORMATS_TYPES = f.getFormats();
  const datetime = f.getDateTime({ date: timer });

  const formattedDate = datetime
    .toFormat(DATE_FORMATS_TYPES.CLOCK, { locale: language })
    .replace(` ${datetime.month} `, ` ${f.localizeMonth(datetime.month - 1)} `);

  return <div className={`${styles.time} ${className}`}>{formattedDate}</div>;
};

export default Clock;
