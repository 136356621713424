import {
  createHookStore,
  createUseSelector,
  STORE_NAMES
} from '@namespace/helpers-store';

export const initialState = {
  isTournamentCategoryReady: false,
  sports: {},
  categories: {},
  categoriesIdsBySportId: {},
  categoriesBySportId: {},
  tournaments: {},
  isHeadGroupsLoaded: false,
  headGroupsDataLanguage: null,
  sportDataState: {
    isLoaded: false,
    lang: ''
  },
  headGroups: {},
  marketGroups: [],

  topEvents: [],
  topEventsDataState: {
    isLoaded: false
  },
  teasers: [],
  teasersDataState: {},

  favorites: {},
  favoritesSports: {},
  isFavoritesLoaded: false,
  isEventsLoaded: false,
  events: {},
  markets: {},
  outcomes: {},

  lines: {},
  // TODO check is necessary
  limit: 30,
  offset: 30,

  // bullet
  prevLineScore: [],
  sortByFilter: {},

  sportsBookHash: [],
  categoriesAndTournamentsDataState: false,
  filteredByDatePresets: [],

  topPreMatchEventsDataState: {
    isLoaded: false,
    lang: null
  },
  topPreMatchEvents: [],
  popularBetEvents: {},
  isLoadedPopularEvents: false,
  headGroupsBySportId: {},
  eventsToShowByLineKey: {}
};

export const useSportsbookStore = createHookStore(
  STORE_NAMES.SPORTSBOOK,
  initialState
);

export const useSportsbookSelector = createUseSelector(useSportsbookStore);
