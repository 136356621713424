import CheckSecretQuestion from './components/CheckSecretQuestion';
import SetNewPassword from './components/SetNewPassword';
import GetSecretQuestion from './components/GetSecretQuestion';
import CheckSecretQuestionSendEmail from './components/CheckSecretQuestionSendEmail';
import SetNewPasswordRecovery from './components/SetNewPasswordRecovery';
import SendChangePasswordLink from './components/SendChangePasswordLink';
import CheckChangePasswordLink from './components/CheckChangePasswordLink';
import ErrorScreen from '../../common/ErrorScreen';
import SuccessScreen from '../../common/SuccessScreen';

export const PASSWORD_RECOVERY_STEP_TYPES = {
  GET_SECRET_QUESTION: 'get-secret-question',
  CHECK_SECRET_QUESTION: 'check-secret-question',
  SET_NEW_PASSWORD: 'set-new-password',
  SEND_CHANGE_PASSWORD_LINK: 'send-change-password-link',
  CHECK_CHANGE_PASSWORD_LINK: 'check-change-password-link',
  CHECK_SECRET_QUESTION_SEND_EMAIL: 'check-secret-question-send-email',
  SET_NEW_PASSWORD_RECOVERY: 'set-new-password-recovery',
  ERROR_SCREEN: 'error',
  SUCCESS_SCREEN: 'success'
};

export const passwordRecoveryComponentsMap = {
  [PASSWORD_RECOVERY_STEP_TYPES.GET_SECRET_QUESTION]: GetSecretQuestion,
  [PASSWORD_RECOVERY_STEP_TYPES.CHECK_SECRET_QUESTION]: CheckSecretQuestion,
  [PASSWORD_RECOVERY_STEP_TYPES.CHECK_SECRET_QUESTION_SEND_EMAIL]: CheckSecretQuestionSendEmail,
  [PASSWORD_RECOVERY_STEP_TYPES.SET_NEW_PASSWORD]: SetNewPassword,
  [PASSWORD_RECOVERY_STEP_TYPES.SET_NEW_PASSWORD_RECOVERY]: SetNewPasswordRecovery,
  [PASSWORD_RECOVERY_STEP_TYPES.SEND_CHANGE_PASSWORD_LINK]: SendChangePasswordLink,
  [PASSWORD_RECOVERY_STEP_TYPES.CHECK_CHANGE_PASSWORD_LINK]: CheckChangePasswordLink,
  [PASSWORD_RECOVERY_STEP_TYPES.ERROR_SCREEN]: ErrorScreen,
  [PASSWORD_RECOVERY_STEP_TYPES.SUCCESS_SCREEN]: SuccessScreen
};
