import { useI18n } from '@namespace/i18n';
import { AText } from '@alf/uikit';
import TextNotification from '../../../common/DocumentStatusInformation/TextNotification';
import styles from './index.module.css';

const EmptyDocuments = () => {
  const { t } = useI18n();

  return (
    <TextNotification className={styles.notification}>
      <AText color="po_txt_color_1" size="m">
        {t('profileDocuments.emptyDocuments')}
      </AText>
    </TextNotification>
  );
};

export default EmptyDocuments;
