import { window } from '@namespace/helpers';
import { parseEventData } from '../utils';

export const resizeControl = (event, callbacks, frame) => {
  const { setHeightHandler = () => {} } = callbacks;
  const {
    tvbet_height: tvbetHeight,
    data: messageData,
    game_field_height: gameFieldHeight,
    value,
    action
  } = parseEventData(event.data);
  if (gameFieldHeight) {
    setHeightHandler(gameFieldHeight);
  } else if (tvbetHeight) {
    // === for tvbet integration ===
    setHeightHandler(tvbetHeight);
  } else if (messageData?.height) {
    setHeightHandler(messageData.height);
  } else if (action === 'game.resize.height' && value) {
    setHeightHandler(value);
  } else if (action === 'game.get.clientrect' && frame?.current) {
    // todo add tests
    event.source.postMessage(
      {
        action: 'game.clientrect',
        value: frame.current.getBoundingClientRect()
      },
      '*'
    );
  } else if (action === 'game.get.clientheight' && frame?.current) {
    event.source.postMessage(
      {
        action: 'game.clientheight',
        value: frame.current.offsetHeight
      },
      '*'
    );
  } else if (action === 'game.get.innerheight') {
    event.source.postMessage(
      {
        action: 'game.innerheight',
        value: window.innerHeight
      },
      '*'
    );
  }
};
