import { useContext, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useOtherSettings } from '@namespace/cms';
import { GamesContext } from '../../context';
import {
  useGetIsLiveRtpGamesLoaded,
  useCategory
} from '../../context/selectors';

export const useLoadLiveRtpGames = (feedName) => {
  const [, { GET_LIVE_RTP_GAMES }] = useContext(GamesContext);
  const { casinoDynamicCategoriesIds = {} } = useOtherSettings();
  const isLiveRtpGamesLoaded = useGetIsLiveRtpGamesLoaded();
  const feedLiveRtpCategory = useCategory(
    casinoDynamicCategoriesIds.liveRtp,
    feedName
  );

  const showLiveRtpCategory = !isEmpty(feedLiveRtpCategory);

  useEffect(() => {
    if (!isLiveRtpGamesLoaded && showLiveRtpCategory) {
      GET_LIVE_RTP_GAMES();
    }
  }, [isLiveRtpGamesLoaded, GET_LIVE_RTP_GAMES, showLiveRtpCategory]);
};
