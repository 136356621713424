import { useContext, useEffect, useState } from 'react';
import { SiteSettingsContext } from '@namespace/cms';
import { UserContext } from '@namespace/user/src/context';
import { window, document } from '@namespace/helpers';
import useZendeskAuthToken from './hooks/useZendeskAuthToken';

// dummy callback for snippets which doesn't know when fresh-chat get initialized
window.ShowSupport = () => {};

const useZendesk = () => {
  const [settings = {}] = useContext(SiteSettingsContext);
  const { supportSettings = {} } = settings || {};
  const { zendeskChat = {} } = supportSettings;
  const { key } = zendeskChat;
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const jwt = useZendeskAuthToken();
  const [{ role }] = useContext(UserContext);

  useEffect(() => {
    if (key) {
      const script = document.createElement('script');
      script.id = 'ze-snippet';
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
      document.body.appendChild(script);
      script.addEventListener('load', function() {
        setScriptLoaded(true);
        window.zE('messenger', 'hide');
        window.zE('messenger:on', 'close', function() {
          setTimeout(() => window.zE('messenger', 'hide'), 1);
        });
        window.ShowSupport = function ShowSupport() {
          window.zE('messenger', 'show');
          window.zE('messenger', 'open');
        };
      });
    }
  }, [key]);

  useEffect(() => {
    if (window.zE) {
      window.zE('messenger', 'logoutUser');
    }
  }, [scriptLoaded, role]);

  useEffect(() => {
    if (scriptLoaded && window.zE) {
      if (jwt) {
        window.zE('messenger', 'loginUser', function(callback) {
          callback(jwt);
        });
      }
    }
  }, [scriptLoaded, jwt]);
};

export default useZendesk;
