import { useMemo, useContext } from 'react';
import { is } from 'ramda';
import { SiteSettingsContext } from '@namespace/cms';
import { calcBaseTax, calcUATax } from './utils';
import { BUILT_IN_TAX_RATE_TYPES } from '../../components/Withdraw/paymentSystem/components/constants';

/**
 *
 * @param float amount
 * @param float rate
 * @returns {tax, returnAmount, taxes}
 */
export const useWithdrawTax = ({ amount, rate }) => {
  const [settings] = useContext(SiteSettingsContext);
  const { siteConfig = {} } = settings;
  const { taxType = null } = siteConfig;
  return useMemo(() => {
    if (taxType === BUILT_IN_TAX_RATE_TYPES.UA) {
      return calcUATax(amount, rate);
    }
    if (is(Number, amount) && is(Number, rate)) {
      return calcBaseTax(amount, rate);
    }
    return null;
  }, [amount, rate, taxType]);
};
