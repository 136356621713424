import { set, isUndefined } from 'lodash';

const aggregateNestedPages = (data) => {
  const result = {};

  for (const [key, value] of Object.entries(data)) {
    const shouldTransform = isUndefined(value[key]);

    if (shouldTransform) {
      set(result, [key, key], value);

      continue;
    }

    result[key] = value;
  }

  return result;
};

export default aggregateNestedPages;
