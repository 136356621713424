import { useCallback } from 'react';
import { window } from '@namespace/helpers';

const useNativePostMessage = () => {
  return useCallback((message) => {
    console.log(message, 'NAVTIVE_SEND try');
    if (window.nativeApp?.postMessage) {
      console.log(message, 'NAVTIVE_SEND postMessage');
      window.nativeApp.postMessage(JSON.stringify(message), '*');
    } else {
      console.error('postMessage to native app no sent');
    }
  }, []);
};

export default useNativePostMessage;
