import { useGeneralRoutes } from '@namespace/cms';
import Routes from '../Routes';
import { MODE } from '../../constants';

const PreMatchRoutes = () => {
  const { preMatchSport } = useGeneralRoutes();

  return <Routes serviceId={MODE.PRE_MATCH} servicePath={preMatchSport} />;
};

export default PreMatchRoutes;
