import { window } from '@namespace/helpers';

const pinnacleProdOrigin = '.tender88.com';

export const pinnacleLauncherControl = (event) => {
  if (event.origin && event.origin.toLowerCase().endsWith(pinnacleProdOrigin)) {
    const postData = event?.data;

    if (postData?.action === 'OPEN_WINDOW' && postData?.url) {
      window.open(postData.url);
    }
  }
};
