import { types } from './types';

export const initialState = {
  expandedComponentIds: []
};

export const reducers = {
  [types.SET_COMPONENT_EXPANDED]: (state, expandedComponentId) => {
    return {
      ...state,
      // uniqueness is not necessary
      expandedComponentIds: [...state.expandedComponentIds, expandedComponentId]
    };
  },
  [types.UNSET_COMPONENT_EXPANDED]: (state, expandedComponentId) => {
    return {
      ...state,
      expandedComponentIds: state.expandedComponentIds.filter(
        (componentId) => componentId !== expandedComponentId
      )
    };
  }
};
