import { useGetPathParams } from '../../hooks/useGetPathParams';
import { LINE_KEY, MODE } from '../../constants';
import { useFetchPopularEvents } from '../../hooks/useFetchPopularEvents';
import { useRemoveLine } from '../../hooks/useRemoveLine';

const PopularSportEventsWrapper = () => {
  const { category, activeSport } = useGetPathParams(MODE.PRE_MATCH);
  const { sportId } = activeSport || {};
  const lineKey = `${LINE_KEY.POPULAR_BETS}-${sportId}`;

  const { categoryId } = category || {};

  useFetchPopularEvents({
    sportId,
    lineKey,
    categoryId
  });

  useRemoveLine(lineKey);

  return null;
};

export default PopularSportEventsWrapper;
