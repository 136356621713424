import { path } from 'ramda';
import { shallow } from 'zustand/shallow';
import { createSelector } from 'reselect';
import { useSportsbookSelector } from '../../store';

export const selectTournamentsIdsByCategoriesIds = (categoriesIds, serviceId) =>
  createSelector(path(['tournaments', serviceId, 'data']), (tournamentsData) =>
    tournamentsData
      ? Object.entries(tournamentsData).reduce(
          (memo, [tournamentId, { categoryId }]) =>
            categoriesIds.includes(categoryId) ? [...memo, tournamentId] : memo,
          []
        )
      : []
  );

export const useTournamentsIdsByCategoriesIds = (categoriesIds, serviceId) =>
  useSportsbookSelector(
    selectTournamentsIdsByCategoriesIds,
    [categoriesIds, serviceId],
    shallow
  );
