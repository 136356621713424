import { useContext } from 'react';
import { ThemesContext } from '../../store/context';

const useThemeComponents = () => {
  const [{ componentsConfig }] = useContext(ThemesContext);

  return componentsConfig || {};
};

export default useThemeComponents;
