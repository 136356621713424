import { Icon } from '@namespace/icons2';
import { useAdvFilterStore } from '../../../storeAdvFilter';
import styles from './index.module.css';

export const FilterIcon = ({ color = 'sm_txt_color_1' }) => {
  const advFilter = useAdvFilterStore('advFilter');

  return (
    <>
      <Icon size="s" name="icons/general/item/filter" color={color} />
      {advFilter?.length > 0 && (
        <Icon
          size="s"
          name="icons/index/counter-general"
          color="accent_color_3"
          className={styles.iconActive}
        />
      )}
    </>
  );
};
