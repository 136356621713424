import { isEmpty } from 'lodash';
import { jackpotWinsStates } from '@namespace/bonuses';
import { types } from './types';
import { getGamesByLaunchIds, getJackpotWins } from '../hooks/api';

const actions = {
  [types.SET_IN_HOUSE_JACKPOTS]: (dispatch) => (jackpots, userCurrency) => {
    dispatch({
      type: types.SET_IN_HOUSE_JACKPOTS,
      payload: { jackpots, userCurrency }
    });
  },
  [types.GET_IN_HOUSE_JACKPOT_WINNERS_GAMES]: (dispatch) => async (
    launchIds
  ) => {
    const data = await getGamesByLaunchIds(launchIds);

    dispatch({
      type: types.SET_IN_HOUSE_JACKPOT_WINNERS_GAMES,
      payload: data
    });
  },
  [types.SET_IN_HOUSE_JACKPOT_WIN]: (dispatch) => (jackpotWin) => {
    dispatch({
      type: types.SET_IN_HOUSE_JACKPOT_WIN,
      payload: jackpotWin
    });
  },
  [types.GET_IN_HOUSE_JACKPOT_WINS]: (dispatch) => async () => {
    const { status, data } = await getJackpotWins(jackpotWinsStates.AVAILABLE);

    if (status !== 'success' || isEmpty(data)) {
      return;
    }

    const [lastWonJackpot] = data;

    dispatch({
      type: types.SET_IN_HOUSE_JACKPOT_WIN,
      payload: { ...lastWonJackpot, jackpotId: lastWonJackpot.id }
    });
  }
};

export default actions;
