import { useReducer, createContext, useMemo } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import { actions as actionCreators } from '../actions';
import { reducers, initialState } from '../reducers';
import { types } from '../types';

export const WidgetLayoutContext = createContext();

export const WidgetLayoutProvider = ({ children }) => {
  const memoReducers = useMemo(() => combineReducers(reducers), []);

  const [state, dispatch] = useReducer(memoReducers, initialState);

  const actions = useActions(types, dispatch, actionCreators);

  return (
    <WidgetLayoutContext.Provider value={[state, actions]}>
      {children}
    </WidgetLayoutContext.Provider>
  );
};
