import { useMemo, useContext } from 'react';
import { DeviceContext } from '@namespace/device';
import { Box, Link } from '@alf/uikit';
import { isEmpty } from 'lodash';
import { useGeneralRoutes } from '@namespace/cms';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import useSportsSettings from '../../hooks/useSportsSettings';
import styles from './index.module.css';

const BreadCrumbs = ({
  type = '',
  sportName = '',
  eventId = '',
  eventName = '',
  sportSlug = '',
  tournamentsIds = [],
  tournamentName = '',
  categoryId = '',
  categoryName = '',
  categoryIdName = ''
}) => {
  const {
    preMatchSport,
    preMatchCategory,
    preMatchEvent,
    preMatchTournament,
    preMatchLobby,
    liveSport,
    liveSportAll,
    liveEvent
  } = useGeneralRoutes();
  const { isDesktop } = useContext(DeviceContext);
  const { t } = useI18n();
  const { showBreadCrumbs } = useSportsSettings();
  const breadCrumbItems = useMemo(() => {
    if (!showBreadCrumbs) {
      return [];
    }

    const items = [
      { label: t(`Home`), path: '/' },
      {
        label: t(`header.betsSport`),
        path: type === 'inprogress' ? liveSportAll : preMatchLobby
      }
    ];
    if (sportSlug && sportName) {
      items.push({
        label: sportName,
        path: `${
          type === 'inprogress' ? liveSport : preMatchSport
        }/${sportSlug}`
      });
    }
    if ((categoryId || !isEmpty(categoryIdName)) && type !== 'inprogress') {
      items.push({
        label: categoryName || categoryIdName,
        path: `${preMatchCategory}/${sportSlug}/${categoryId}`
      });
    }
    if (
      !isEmpty(tournamentsIds) &&
      tournamentsIds.length === 1 &&
      type !== 'inprogress' &&
      isDesktop
    ) {
      items.push({
        label: tournamentName,
        path: `${preMatchTournament}/${sportSlug}/${tournamentsIds}`
      });
    }
    if (eventId && eventName) {
      items.push({
        label: eventName,
        path: `${
          type === 'inprogress' ? liveEvent : preMatchEvent
        }/${sportSlug}/${eventId}`
      });
    }

    return items;
  }, [
    sportSlug,
    isDesktop,
    sportName,
    eventId,
    categoryId,
    type,
    tournamentsIds
  ]);

  const breadCrumbs = breadCrumbItems.map(({ label, path }, index) => (
    <Link
      Component={LocalizedLink}
      to={path}
      key={label + path}
      breed="R"
      size="m"
      color={
        breadCrumbItems.length - 1 !== index ? 'line_txt_3' : 'line_infotxt_5'
      }
    >
      {label}
    </Link>
  ));

  return <Box className={styles.link}>{breadCrumbs}</Box>;
};

export default BreadCrumbs;
