import { types } from '../types';

export const reducers = {
  [types.SET_SSID]: (state, ssid) => {
    return {
      ...state,
      ssid
    };
  }
};
