import { AText, Tooltip } from '@alf/uikit';

const FormFieldError = ({
  children,
  withTooltip,
  dataRole,
  className = ''
}) => {
  return (
    <>
      {withTooltip ? (
        <Tooltip className={className}>{children}</Tooltip>
      ) : (
        <AText
          data-role={dataRole}
          size="s"
          color="input_error_txt"
          className={className}
        >
          {children}
        </AText>
      )}
    </>
  );
};

export default FormFieldError;
