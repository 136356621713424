import { useState, useEffect } from 'react';
import { useI18n } from '@namespace/i18n';
import { Button } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import styles from './index.module.css';

const CorePagination = ({
  activePage = 1,
  pagesCount = 1,
  showDropdown = false,
  isBackendPagination = false,
  isLastPage = false,
  onChange = () => {},
  className = '',
  children
}) => {
  const [page, setPage] = useState(activePage);
  const { t } = useI18n();

  useEffect(() => {
    setPage(activePage);
  }, [activePage]);

  const pagesList = [];
  for (let i = 1; i <= pagesCount; ++i) {
    pagesList.push({
      name: String(i),
      value: String(i)
    });
  }

  const handlePageChange = (num) => {
    setPage(num);
    if (onChange) {
      onChange(Number(num));
    }
  };

  return (
    <>
      {children && children(page)}
      <div className={`${styles.pagination} ${className}`}>
        <div className={styles.pageControls}>
          <Button
            className={styles.pageBtn}
            intent="quaternary"
            actionType="color3"
            size="s"
            iconOnly={true}
            iconLeft={<Icon name="icons/general/nav/back" size="s" />}
            onClick={() => handlePageChange(page - 1)}
            disabled={page <= 1}
          />
          {isBackendPagination ? (
            <div className={styles.pageCount}>{page}</div>
          ) : (
            <div className={`${styles.pageCount} ${styles.pageCountExt}`}>
              {showDropdown
                ? `${t('pagination.of')} ${pagesCount}`
                : `${page} ${t('pagination.of')} ${pagesCount}`}
            </div>
          )}
          <Button
            intent="quaternary"
            actionType="color3"
            size="s"
            iconOnly={true}
            iconRight={<Icon name="icons/general/nav/next" size="s" />}
            onClick={() => handlePageChange(parseInt(page, 10) + 1)}
            disabled={isBackendPagination ? isLastPage : page >= pagesCount}
          />
        </div>
      </div>
    </>
  );
};

export default CorePagination;
