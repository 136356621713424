import { pathOr } from 'ramda';
import { shallow } from 'zustand/shallow';
import { createSelector } from 'reselect';
import { useSportsbookSelector } from '../../../../store';

const emptyObj = {};

export const selectEventFields = (eventId, fieldPath, fields) =>
  createSelector(
    pathOr(emptyObj, [
      'events',
      eventId,
      // eslint-disable-next-line no-nested-ternary
      ...(fieldPath ? (Array.isArray(fieldPath) ? fieldPath : [fieldPath]) : [])
    ]),
    (item) => {
      const data = {};
      for (const fieldKey of fields) {
        data[fieldKey] = item[fieldKey];
      }
      return data;
    }
  );

export const useEventFields = ({ eventId, fieldPath, fields }) =>
  useSportsbookSelector(
    selectEventFields,
    [eventId, fieldPath, fields],
    shallow
  );
