export const normalize = (bets) =>
  bets.reduce(
    (acc, curr) => ({
      data: {
        ...acc.data,
        [curr.cardId]: curr
      },
      ids: [...acc.ids, curr.cardId]
    }),
    { data: {}, ids: [] }
  );
