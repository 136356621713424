import { TextCopy as TextCopyBase } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import useNotifications from '@namespace/notifications/src/hooks/useNotifications'; // FIT IT

export const TextCopy = (props) => {
  const { t } = useI18n();
  const { customNotification } = useNotifications();

  return (
    <TextCopyBase t={t} customNotification={customNotification} {...props} />
  );
};
