import { useCallback, useContext, useEffect } from 'react';
import { USER_ROLES, UserContext, useSetLineView } from '@namespace/user';
import { useCacheLoad } from '@namespace/helpers';
import { useI18n } from '@namespace/i18n';
import {
  sportsbookActions,
  useSportsBookDataState,
  useSportsbookStore
} from '../../store';
import { useLineBullet } from '../../hooks/useLineBullet';
import useSportsSettings from '../../hooks/useSportsSettings';

const { FETCH_FAVORITES, FETCH_SPORTS_BOOK_DATA } = sportsbookActions;

const SportsBookCommon = () => {
  const isFavoritesLoaded = useSportsbookStore('isFavoritesLoaded');
  const [user] = useContext(UserContext);
  const { role } = user;
  const { isSportsBookDataLoaded, dataCachingTime } = useSportsBookDataState();
  const { language: lang } = useI18n();

  useLineBullet();
  useSetLineView();
  const { timeRangeForAllPeriodByEvents } = useSportsSettings();

  const action = useCallback(
    () =>
      FETCH_SPORTS_BOOK_DATA({
        lang,
        timeRange: timeRangeForAllPeriodByEvents
      }),
    [lang, timeRangeForAllPeriodByEvents]
  );
  useCacheLoad({
    dataMustBeLoaded: !isSportsBookDataLoaded,
    dataCachingTime,
    action
  });
  useEffect(() => {
    if (role === USER_ROLES.USER && !isFavoritesLoaded) {
      FETCH_FAVORITES();
    }
  }, [role, isFavoritesLoaded]);

  return null;
};

export default SportsBookCommon;
