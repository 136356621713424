import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useI18n } from '@namespace/i18n';
import { Input } from '@alf/uikit';
import { logAmplitude } from '@namespace/analytics';
import getFormError from '../../utils/getFormError';
import useDynamicField from '../../hooks/useDynamicField';
import useCustomError from '../../hooks/useCustomError';
import useOnChangeValidate from '../../hooks/useOnChangeValidate';
import useInstantValidation from '../../hooks/useInstantValidation';
import InputFieldControl from '../InputFieldControl';
import styles from './index.module.css';

const EmailInputField = ({
  name,
  label = '',
  labelParams = [],
  hint = '',
  withTooltip,
  classNames = {},
  rules = {},
  allowClean = false,
  isOnChangeValidation = false,
  isClearErrorsOnChange = false,
  customError = '',
  formProps = {},
  inputType = 'text',
  dataRole,
  placeholder = '',
  size = 's',
  disableAutocompleteFieldsAbove = false,
  controlProps = {},
  enableInstantFieldValidation = false,
  analyticAction,
  iconSize,
  ...props
}) => {
  const { t } = useI18n();
  useInstantValidation(name, enableInstantFieldValidation);
  const { inputClassName = '', containerClassName = '' } = classNames;
  const methods = useFormContext();
  const {
    control,
    clearErrors,
    formState: { errors },
    setValue,
    watch,
    getFieldState
  } = methods;
  const { dynamicValues = {} } = formProps;

  const fieldValue = watch(name);

  useDynamicField({ name, dynamicFieldData: dynamicValues[name] });

  const { errorMessage, customErrorHandler } = useCustomError(customError);
  const onClean = useCallback((value = '') => setValue(name, value), [
    setValue,
    name
  ]);

  const handleBlur = useCallback(
    (onBlur) => (e) => {
      const validValue = e.target.value.trim();

      e.target.value = validValue;

      if (analyticAction) {
        // setTimeout for waiting input validation for Amplitude event analytic
        setTimeout(() => {
          const { invalid } = getFieldState(name);
          logAmplitude(analyticAction, {
            valid: !invalid
          });
        }, 0);
      }
      setValue(name, validValue);
      onBlur?.(e);
    },
    [analyticAction, getFieldState, name, setValue]
  );

  useEffect(() => {
    if (isClearErrorsOnChange) {
      clearErrors();
    }
  }, [clearErrors, isClearErrorsOnChange, fieldValue]);

  const inputLabel =
    typeof label === 'object' ? label : t(label, [...labelParams]);
  const errorText = getFormError(name, errors, t) || t(errorMessage) || '';

  useOnChangeValidate({ name, isOnChangeValidation });

  const controllerRender = ({ field: { ref, onBlur, ...field } }) => (
    <Input
      {...props}
      id={name}
      size={iconSize || size}
      clearable={allowClean}
      onClean={onClean}
      data-role={dataRole}
      type={inputType}
      placeholder={t(placeholder)}
      className={inputClassName}
      wrapperClassName={containerClassName}
      intent={errorText ? 'error' : 'default'}
      onBlur={handleBlur(onBlur)}
      {...field}
    />
  );

  return (
    <InputFieldControl
      disableAutocompleteFieldsAbove={disableAutocompleteFieldsAbove}
      name={name}
      control={control}
      rules={rules}
      render={controllerRender}
      key={controlProps.key}
      classNames={classNames}
      error={errorText}
      hint={hint}
      withTooltip={withTooltip}
      onClick={customErrorHandler}
      styles={styles}
      id={name}
      size={size}
      label={inputType !== 'hidden' ? inputLabel : ''}
      dataRole={dataRole}
    />
  );
};

export default EmailInputField;
