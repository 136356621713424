import { Button as UIKitButton } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import styles from './index.module.css';

const Button = ({
  value,
  size = 'l',
  classNames = {},
  dataGmt = '',
  disabled = false,
  onClick = () => {},
  intent,
  actionType,
  processing,
  dataRole
}) => {
  const { className = '' } = classNames;
  const { t } = useI18n();

  return (
    <UIKitButton
      onClick={onClick}
      type="button"
      disabled={disabled}
      data-gmt={dataGmt}
      size={size}
      className={`${styles.button} ${className}`}
      intent={intent}
      actionType={actionType}
      processing={processing}
      dataRole={dataRole}
    >
      {t(value)}
    </UIKitButton>
  );
};

export default Button;
