import { Box, AText } from '@alf/uikit';
import clsx from 'clsx';
import {
  useGetProviderJackpotCoef,
  useSetJackpot
} from '../../../../../../hooks';
// import { getJackpotTimeout } from '../utils';
import { formatAmount, getCurrencySign } from '../../../../../../utils';
import { JACKPOT_LEVEL_IMAGE_INFO, JACKPOT_TYPES } from '../../constants';
import styles from './index.module.css';

const PateplayJackpotItem = ({
  type,
  jackpotAmounts = {},
  classNames = {},
  currency,
  jackpotIdentifier
}) => {
  const jackpotCoef = useGetProviderJackpotCoef(jackpotIdentifier);

  const { wrapperClassName = '' } = classNames;
  const textRef = useSetJackpot({
    initialValue: Number(jackpotAmounts.currentWinAmount),
    formatTotal: formatAmount,
    step: 0.01 * jackpotCoef,
    delay: 1
  });

  const { levelsImages } = JACKPOT_LEVEL_IMAGE_INFO[jackpotIdentifier];
  const jackpotLevelImage = levelsImages[type];

  const isRedJp = type === JACKPOT_TYPES[jackpotIdentifier].RED;

  return (
    <Box className={clsx([styles.wrapper, wrapperClassName])} align="center">
      <img
        src={jackpotLevelImage}
        className={clsx([styles.lvlBgImage, isRedJp && styles.lvlBgImageRedJp])}
        alt="lvl-background"
      />
      <Box className={clsx([styles.content, isRedJp && styles.contentRedJp])}>
        <Box>
          <Box className={styles.amount} ref={textRef} />
          <AText className={styles.currency}>{getCurrencySign(currency)}</AText>
        </Box>
      </Box>
    </Box>
  );
};

export default PateplayJackpotItem;
