import { memo } from 'react';
import { useSetJackpot } from '../../../../../../hooks/useSetJackpot';
import { JACKPOT_BANNER_UPDATE_INTERVAL } from '../../constants';
import { OdometerWrapper } from '../../common/OdometerWrapper';
import { formatTotal } from '../../useSetInHouseJackpot';
import { useGetIsJackpotCentsHidden } from '../../useGetIsJackpotCentsHidden';

export const InHouseJackpotsTotalCounter = memo(({ totalJackpot }) => {
  const isJackpotCentsHidden = useGetIsJackpotCentsHidden();
  const totalAmountTextRef = useSetJackpot({
    initialValue:
      totalJackpot.maxValue < totalJackpot.convertedAmount
        ? totalJackpot.maxValue
        : totalJackpot.convertedAmount,
    step:
      totalJackpot.maxValue < totalJackpot.convertedAmount
        ? 0
        : (totalJackpot.maxValue - totalJackpot.convertedAmount) /
          (totalJackpot.refreshRate / JACKPOT_BANNER_UPDATE_INTERVAL),
    delay: JACKPOT_BANNER_UPDATE_INTERVAL * 1000,
    maxValue: totalJackpot.maxValue,
    formatTotal,
    isSkipImmediateSetOnUpdate: true,
    isAddSpacesToJackpotAmount: false
  });

  return (
    <OdometerWrapper
      initialValue={totalJackpot.convertedAmount}
      textRef={totalAmountTextRef}
      isCentsHidden={isJackpotCentsHidden}
      // need key for instant remount (without animation) in case with currency change
      key={`inHouseJackpotsTotalCounter-${totalJackpot.convertedCurrency}`}
    />
  );
});
