import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import styles from './index.module.css';
import { getFlagIconAsync, getFlagId } from './utils';

const Flag = ({ flagId, iso, name, size, className = '', ...rest }) => {
  const [renderFlag, setRenderFlag] = useState(null);

  useEffect(() => {
    let isCancelled = false;
    const countryName = name ? name.replace(/\s/g, '') : null;
    const byId = flagId || flagId === 0;
    const flag = byId ? flagId : getFlagId({ iso, name: countryName });
    if (flag || flag === 0) {
      getFlagIconAsync(flag)
        .then((module) => {
          /*
            omg func component need set in state already render func,
            or this component in setState will do render by self
          */
          if (!isCancelled && module) {
            const RenderFlag = module;
            setRenderFlag(<RenderFlag />);
          }
          return module;
        })
        .catch((e) => {
          console.error(`Failed to load flag ${flag}:`, e);
        });
    }
    return () => {
      isCancelled = true;
    };
  }, [flagId, iso, name]);

  return (
    <div
      {...rest}
      className={`${styles.flag} ${styles[size] || ''} ${className}`}
      data-role={flagId && `flag-${flagId}`}
    >
      {renderFlag || <div className={styles.flag} />}
    </div>
  );
};

Flag.propTypes = {
  /**
   * some BE id
   */
  flagId: PropTypes.number,
  /**
   * string like UA RU EN
   */
  iso: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf(['s', 'sm', 'm', 'l']),
  className: PropTypes.string
};

export default Flag;
