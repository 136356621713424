import { cloneDeep, groupBy } from 'lodash';

/**
 * Function for event score update with returning new state
 * @param state
 * @param data
 * @param updateScoreHandler
 * @returns {*}
 */
export const updateScore = (state, data, updateScoreHandler) => {
  const dataByEvent = groupBy(data, 'eventId');

  let eventsState = {
    events: state.events,
    activeEventData: cloneDeep(state.activeEventData),
    prevLineScore: cloneDeep(state.prevLineScore),
    prevActiveEventScore: cloneDeep(state.prevActiveEventScore)
  };

  for (const eventUpdates of Object.values(dataByEvent)) {
    for (const eventData of eventUpdates) {
      eventsState = updateScoreHandler(eventsState, eventData);
    }
  }

  return {
    ...state,
    ...eventsState
  };
};
