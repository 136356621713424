import { useMemo, memo } from 'react';
import {
  SportList,
  UserSettingsBurger,
  useUserSettings
} from '@namespace/common';
import { useGeneralRoutes } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { AText, Box } from '@alf/uikit';
import { SPORT_ICONS } from '../../../constants';
import SportsBookSearch from '../../SportsBookSearch';
import { FavoritesLink } from '../../../common';
import { useCyberSportCategories } from '../../../store';
import styles from './index.module.css';

const CyberSportBurger = ({
  config = {},
  onSearchClicked = () => {},
  onClick
}) => {
  const { subMenu = [] } = config;
  const { t } = useI18n();
  const subMenuItems = useMemo(
    () =>
      subMenu.map(({ id, link, title }) => ({
        id,
        link,
        icon: SPORT_ICONS[id] || SPORT_ICONS.DEFAULT,
        title: t(title)
      })),
    [subMenu, t]
  );
  const { cyberSportCategory, cyberSportFavorites } = useGeneralRoutes();

  const categories = useCyberSportCategories();

  const items = useMemo(
    () =>
      categories.map(({ sportId: id, sportName, sportSlug, eventCount }) => ({
        id,
        icon: SPORT_ICONS[id] || SPORT_ICONS.DEFAULT,
        link: `${cyberSportCategory}/${sportSlug}`,
        title: sportName,
        count: eventCount
      })),
    [categories, cyberSportCategory]
  );

  const classNames = useMemo(
    () => ({
      iconClass: styles.icon
    }),
    []
  );

  const {
    showTimeZoneSelector,
    showLineSettings,
    showCoefSwitcher,
    showSettings
  } = useUserSettings();

  return (
    <>
      <SportsBookSearch onClick={onSearchClicked} />
      <div className={styles.ItemsWrapper}>
        <Box direction="column" bottom="s">
          <UserSettingsBurger
            showTimeZoneSelector={showTimeZoneSelector}
            showCoefSwitcher={showCoefSwitcher}
            showLineSettings={showLineSettings}
            showSettings={showSettings}
          />
        </Box>
        <Box bottom="s">
          <AText
            breed="BU"
            size="l"
            color="sm_txt_color_3"
            className={styles.sportItemTitle}
          >
            {t('burgerMenu.cybersports.title')}
          </AText>
        </Box>
        <Box bottom="xs">
          <SportList items={subMenuItems} onClick={onClick} />
        </Box>
        <FavoritesLink
          to={cyberSportFavorites}
          title={t('sportsbook.menu.cyberSportFavorites')}
          className={styles.favoriteItem}
          onClick={onClick}
        />
        <SportList
          items={items}
          isPartialLinkMatch={true}
          classNames={classNames}
          onClick={onClick}
        />
      </div>
    </>
  );
};

export default memo(CyberSportBurger);
