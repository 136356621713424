import { shallow } from 'zustand/shallow';
import { createSelector } from 'reselect';
import { useSportsbookSelector } from '../../store';

export const selectCategoriesByIds = (categoryIds, serviceId) =>
  createSelector(
    (state) => state.categories ?? {},
    (categories) =>
      categoryIds
        .map((categoryId) => categories[serviceId]?.data[categoryId])
        .filter(Boolean)
  );

export const useCategoriesByIds = (activeCategoriesIds, serviceId) =>
  useSportsbookSelector(
    selectCategoriesByIds,
    [activeCategoriesIds, serviceId],
    shallow
  );
