import { useMemo } from 'react';
import { useI18n } from '@namespace/i18n';
import { BONUS_SLIDER_BONUS_TYPES } from '../../constants';

const useDepositBonusCardContent = ({ bonus, userCurrency }) => {
  const { t } = useI18n();
  const {
    type,
    bonusSum,
    bonusName,
    bonusModelId,
    freespinName,
    campaignId,
    campaignName,
    wagerMultiplier,
    triggerSettings = {},
    incomeDepositMin,
    incomeDepositMax,
    outputMaxTotal,
    bonusMaxSum
  } = bonus;

  const { currencies } = triggerSettings;
  const { minDeposit, maxDeposit } =
    currencies?.find(
      ({ currency: itemCurrency }) => itemCurrency === userCurrency
    ) || {};

  const bonusTitle = useMemo(() => {
    switch (type) {
      case BONUS_SLIDER_BONUS_TYPES.BONUS_SYSTEM:
        return t(`content.bonus.template.name.bs.${campaignId}`, campaignName);
      case BONUS_SLIDER_BONUS_TYPES.CASHBACK:
      case BONUS_SLIDER_BONUS_TYPES.FUNDS: {
        return t(`content.bonus.template.name.${bonusModelId}`, bonusName);
      }
      case BONUS_SLIDER_BONUS_TYPES.FREE_SPIN: {
        return t(`content.bonus.template.name.${bonusModelId}`, freespinName);
      }
      case BONUS_SLIDER_BONUS_TYPES.DEFAULT:
        return t(bonusName);
      default:
        return '';
    }
  }, [
    bonusModelId,
    bonusName,
    campaignId,
    campaignName,
    freespinName,
    t,
    type
  ]);

  const bonusDescriptionTitle1 = useMemo(() => {
    switch (type) {
      case BONUS_SLIDER_BONUS_TYPES.BONUS_SYSTEM:
        return `web.personaloffice.deposit.bonus.deposit.range`;
      case BONUS_SLIDER_BONUS_TYPES.CASHBACK:
        return 'web.personaloffice.bonuses.min.deposit';
      case BONUS_SLIDER_BONUS_TYPES.FUNDS: {
        return 'web.personaloffice.bonuses.bonus.amount';
      }
      case BONUS_SLIDER_BONUS_TYPES.FREE_SPIN: {
        return 'web.personaloffice.deposit.bonus.deposit.range';
      }
      default:
        return '';
    }
  }, [type]);

  const bonusDescriptionTitle2 = useMemo(() => {
    switch (type) {
      case BONUS_SLIDER_BONUS_TYPES.BONUS_SYSTEM:
        return 'web.personaloffice.deposit.bonuses.wager';
      case BONUS_SLIDER_BONUS_TYPES.CASHBACK:
        return 'web.personaloffice.deposit.bonus.maxcashback';
      case BONUS_SLIDER_BONUS_TYPES.FREE_SPIN: {
        return (
          outputMaxTotal && 'web.personaloffice.bonuses.bonus.wagering.amount'
        );
      }
      default:
        return '';
    }
  }, [outputMaxTotal, type]);

  const bonusDescriptionValue1 = useMemo(() => {
    switch (type) {
      case BONUS_SLIDER_BONUS_TYPES.BONUS_SYSTEM:
        return `${minDeposit} - ${maxDeposit}`;
      case BONUS_SLIDER_BONUS_TYPES.CASHBACK:
        return incomeDepositMin;
      case BONUS_SLIDER_BONUS_TYPES.FUNDS: {
        return bonusSum;
      }
      case BONUS_SLIDER_BONUS_TYPES.FREE_SPIN: {
        return `${incomeDepositMin} - ${incomeDepositMax}`;
      }
      default:
        return '';
    }
  }, [
    bonusSum,
    incomeDepositMax,
    incomeDepositMin,
    maxDeposit,
    minDeposit,
    type
  ]);

  const bonusDescriptionValue2 = useMemo(() => {
    switch (type) {
      case BONUS_SLIDER_BONUS_TYPES.BONUS_SYSTEM:
        return `x${wagerMultiplier}`;
      case BONUS_SLIDER_BONUS_TYPES.CASHBACK:
        return `${bonusMaxSum}`;
      case BONUS_SLIDER_BONUS_TYPES.FREE_SPIN: {
        return outputMaxTotal && `${outputMaxTotal}`;
      }
      default:
        return '';
    }
  }, [bonusMaxSum, outputMaxTotal, type, wagerMultiplier]);

  return {
    bonusTitle,
    bonusDescriptionTitle1,
    bonusDescriptionTitle2,
    bonusDescriptionValue1,
    bonusDescriptionValue2
  };
};

export default useDepositBonusCardContent;
