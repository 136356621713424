import { useState, useEffect } from 'react';
import { matchPath } from 'react-router-dom';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import {
  getRootPath,
  setSessionStorageValue,
  getSessionStorageValue
} from '@namespace/helpers';
import { useOtherSettings } from '@namespace/cms';
import { HEADER_COMPONENTS } from '../../../constants';
import NavMenuItem from './components/NavMenuItem';

const Item = ({
  title = '',
  link = '',
  highlightSeparately = false,
  useAnimation = false,
  classNames,
  index = 0,
  animationDuration = 10000,
  onClick = () => {},
  componentsMap = {},
  ...rest
}) => {
  const NavMenuItemComponent =
    componentsMap[HEADER_COMPONENTS.NAV_MENU_ITEM] || NavMenuItem;

  const { linkClass = '', activeLinkClass = '' } = classNames;
  const handleClick = (...args) => {
    logAction(LOG_ACTIONS.MAIN_MENU_CLICK, {
      MenuItemName: title,
      indexItem: index + 1,
      link
    });
    onClick(...args);
  };
  const [showAnimation, setShowAnimation] = useState(
    useAnimation &&
      !getSessionStorageValue(
        `HEADER_BUTTON_ANIMATED_${link.replace(/\//g, '')}`,
        false
      )
  );

  useEffect(() => {
    let handler;
    if (showAnimation) {
      handler = setTimeout(() => {
        setShowAnimation(false);
      }, animationDuration);
      setSessionStorageValue(
        `HEADER_BUTTON_ANIMATED_${link.replace(/\//g, '')}`,
        true
      );
    }
    return () => clearTimeout(handler);
  }, []);

  // kostil for display casino on root page
  const {
    navMenuPathsAliases = {},
    desktopNavMenuHighlightOnPages = {}
  } = useOtherSettings();

  const isActive = (match, location) => {
    const { pathname, search } = location;
    const query = new URLSearchParams(search);
    const isHighlightSeparately = query.get('highlightSeparately');
    const [linkPathname] = link.split('?');

    if (isHighlightSeparately) {
      return pathname === linkPathname || pathname === `${linkPathname}/`;
    }

    // analogy of mobile nav menu highlightOnPages. will need to implement on CMS side
    if (desktopNavMenuHighlightOnPages[linkPathname]) {
      return desktopNavMenuHighlightOnPages[linkPathname]?.some((path) =>
        matchPath({ path, end: true }, pathname)
      );
    }

    const rootPath = getRootPath(pathname);
    const urlRoot = getRootPath(link);

    return (
      (!highlightSeparately && urlRoot === rootPath) ||
      urlRoot === navMenuPathsAliases[rootPath]
    );
  };

  return (
    <NavMenuItemComponent
      title={title}
      link={link}
      useAnimation={useAnimation}
      isActive={isActive}
      linkClass={linkClass}
      activeLinkClass={activeLinkClass}
      handleClick={handleClick}
      showAnimation={showAnimation}
      {...rest}
    />
  );
};

export default Item;
