import { Box } from '@alf/uikit';
import Title from '../Title';
import TopLiveEventsDesktop from '../TopLiveEventsDesktop';
import TopLiveEventsMobile from '../TopLiveEventsMobile';
import styles from './index.module.css';

const InnerTopLiveEvents = ({
  isEventsLoaded,
  isShowMore,
  eventsIds,
  lineKey,
  type,
  pageSize,
  isShowStatUrl
}) => (
  <Box
    data-role="top-live-events-wrapper"
    direction="column"
    padding="0 s"
    className={eventsIds.length || !isEventsLoaded ? styles.wrapper : ''}
  >
    {eventsIds.length > 0 && <Title />}
    {isShowMore ? (
      <TopLiveEventsDesktop
        isEventsLoaded={isEventsLoaded}
        eventsIds={eventsIds}
        lineKey={lineKey}
        type={type}
        isShowMore={isShowMore}
        isShowShortName={true}
        pageSize={pageSize}
        isShowStatUrl={isShowStatUrl}
        isLiveEvents={true}
      />
    ) : (
      <TopLiveEventsMobile
        isEventsLoaded={isEventsLoaded}
        eventsIds={eventsIds}
        lineKey={lineKey}
        isShowShortName={true}
        isShowFlag={true}
        isShowStatUrl={isShowStatUrl}
        isLiveEvents={true}
      />
    )}
  </Box>
);

export default InnerTopLiveEvents;
