import { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { identity } from 'ramda';
import { COLOR_SCHEME } from '@namespace/themes';
import Box from '../../../../../Box';
import { ContentRow } from './components/ContentRow';
import { ContentRows } from './components/ContentRows';
import { ContentGroups } from './components/ContentGroups';
import { SearchRow } from './components/SearchRow';
import styles from './index.module.css';

const SELECT_ALL = '$$SELECT_ALL$$';

export const MultiSelectContent = ({
  t = identity,
  options,
  groupBy,
  groupsData,
  value,
  onChange,
  size = 'xs',
  className = '',
  classNames,
  intentType,
  hasSearch = false,
  isInlineSearch = true,
  searchValue,
  onSearchValueChange,
  searchPlaceholder,
  showSelectAll = false,
  inputRef,
  dataRole
}) => {
  const contentRef = useRef();
  const selectAllTitle = t('uikit.multiSelect.content.selectAll');
  const values = useMemo(() => options.map((o) => o.value), [options]);

  const onRowSelect = useCallback(
    (rowValue, isSelected) => {
      let newSortedValue;

      if (rowValue === SELECT_ALL) {
        newSortedValue = isSelected ? values : [];
      } else {
        const newValue = isSelected
          ? [...value, rowValue]
          : value.filter((v) => v !== rowValue);

        // sort according to initial `options` order
        newSortedValue = values.filter((v) => newValue.includes(v));
      }

      return onChange({ value: newSortedValue });
    },
    [onChange, value, values]
  );

  const contentProps = {
    options,
    groupBy,
    groupsData,
    value,
    onRowSelect,
    size,
    classNames,
    intentType,
    dataRole
  };

  return (
    <Box
      direction="column"
      className={clsx([
        styles.general,
        styles[intentType],
        className,
        styles[size]
      ])}
      ref={contentRef}
    >
      {hasSearch && !isInlineSearch && (
        <SearchRow
          searchValue={searchValue}
          onSearchValueChange={onSearchValueChange}
          placeholder={searchPlaceholder}
          size={size}
          inputRef={inputRef}
          contentRef={contentRef}
        />
      )}
      {showSelectAll && (
        <ContentRow
          key={SELECT_ALL}
          value={SELECT_ALL}
          title={selectAllTitle}
          // dangerous but effective assumption
          isActive={value.length === values.length}
          onChange={onRowSelect}
          size={size}
          intentType={intentType}
          dataRole={dataRole}
        />
      )}
      {groupBy != null ? (
        <ContentGroups {...contentProps} />
      ) : (
        <ContentRows {...contentProps} />
      )}
    </Box>
  );
};

MultiSelectContent.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      title: PropTypes.string
    })
  ),
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['xs', 's', 'm']),
  className: PropTypes.string,
  t: PropTypes.func,
  intentType: PropTypes.oneOf([COLOR_SCHEME.PRIMARY, COLOR_SCHEME.SECONDARY])
};
