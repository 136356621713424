import { isEmpty } from 'lodash';
import { types } from '../types';
import {
  normalizeBonusSystemWager,
  normalizeWagerBonuses
} from '../normalizers';

export const initialState = {
  wagerBonuses: [],
  currentBonus: null,
  currentBonusTmpl: null,
  isLoading: false,
  isUpdateFreeSpinBonusList: false,
  isUpdateFundsBonusList: false,
  bsWager: {
    data: {},
    isLoaded: false
  },
  activeWagerBonus: {},
  activeBonusSystemBonusId: null,
  pwbNotification: null
};

export const reducers = {
  [types.SET_WAGER_BONUSES]: (state, { crmWager, bsWager }) => ({
    ...state,
    wagerBonuses: [
      ...(!isEmpty(bsWager) ? [normalizeBonusSystemWager(bsWager)] : []),
      ...(crmWager ? [...normalizeWagerBonuses(crmWager)] : [])
    ],
    bsWager: {
      data: bsWager,
      isLoaded: true
    }
  }),

  [types.SET_IS_UPDATE_FREE_SPIN_BONUS_LIST]: (state, { isUpdate }) => ({
    ...state,
    isUpdateFreeSpinBonusList: isUpdate
  }),

  [types.SET_IS_UPDATE_FUNDS_BONUS_LIST]: (state, { isUpdate }) => ({
    ...state,
    isUpdateFundsBonusList: isUpdate
  }),

  [types.SET_IS_LOADING]: (state, isLoading) => ({
    ...state,
    isLoading
  }),
  [types.SET_BONUS]: (state, { bonus, bonusTmpl }) => ({
    ...state,
    bonus,
    bonusTmpl
  }),
  [types.SET_PWB_NOTIFICATIONS]: (state, data) => ({
    ...state,
    pwbNotification: data
  }),
  [types.SET_ACTIVE_WAGER_BONUS]: (state, { activeWagerBonus }) => ({
    ...state,
    activeWagerBonus
  }),
  [types.SET_ACTIVE_BONUS_SYSTEM_BONUS]: (
    state,
    { activeBonusSystemBonusId }
  ) => ({
    ...state,
    activeBonusSystemBonusId
  })
};
