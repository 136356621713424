import { logNewRelicError, NEW_RELIC_LOG_ERRORS } from '@namespace/analytics';

const baseReducer = (handlers = {}, customHandlers = {}) => {
  const mergedHandlers = { ...handlers, ...customHandlers };
  return (state = {}, { type, payload }) => {
    try {
      return mergedHandlers[type]
        ? mergedHandlers[type](state, payload)
        : state;
    } catch (e) {
      logNewRelicError(e, NEW_RELIC_LOG_ERRORS.REDUCER, { type });
      return state;
    }
  };
};

export default baseReducer;
