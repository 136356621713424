import { rePatterns } from '../../patterns';

const latinValidator = (message = 'validators.latinValidator') => ({
  pattern: {
    value: rePatterns.latinSymbols,
    message
  }
});

export default latinValidator;
