import { window, document } from '@namespace/helpers';

const loadSender = ({ src, id, companyId, language: lang, auth }) =>
  new Promise((resolve, reject) => {
    if (!document.getElementById('senderCallback')) {
      const script = document.createElement('script');
      script.text = `window.senderCallback = () => {
        SenderWidget.init({ companyId:'${companyId}', lang:'${lang}', auth: '${auth}' });
      };`;
      script.id = 'senderCallback';
      script.defer = true;
      document.body.append(script);
    } else {
      window.SenderWidget?.restart({ companyId, lang, auth });
    }

    if (!document.getElementById(id)) {
      const script = document.createElement('script');
      script.src = src;
      script.id = id;
      script.onload = resolve;
      script.onerror = reject;
      script.defer = true;
      document.body.append(script);
    } else {
      resolve();
    }
  });

export default loadSender;
