import { useContext, useEffect } from 'react';
import { DeviceContext } from '@namespace/device';
import { useTargetEls } from '../../../../hooks';
import { useCurrentStep } from '../../../../../../hooks';
import { subscribeInterceptActions } from './utils';
import { useInterceptorAction } from '../../hooks';

export const UserActionInterceptorDesktop = () => {
  const { isDesktop } = useContext(DeviceContext);
  const { interceptActions } = useCurrentStep();
  const els = useTargetEls();
  const action = useInterceptorAction();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isDesktop && interceptActions && els) {
      return subscribeInterceptActions(els, { action });
    }
  }, [action, els, interceptActions, isDesktop]);

  return null;
};
