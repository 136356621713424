import { postAsUriParameters } from '@namespace/helpers';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { acceptRoutsMap } from '../../constants';

export const handleAcceptResponse = (inputParams, endpoint, serverResponse) => {
  const { response, error } = serverResponse;
  const logParams = {
    inputParams,
    serverResponse,
    endpoint
  };
  if (response?.errorCode) {
    const { errorCode } = response;
    if (errorCode === 0) {
      return 'accountingMessages.bonuses_crm_success_1';
    }
    logAction(LOG_ACTIONS.FREESPIN_ACCEPT_ERROR, logParams);
    throw new Error(`accountingMessages.bonuses_crm_error_${errorCode}`);
  }
  if (error === 'no') {
    return 'accountingMessages.bonuses_crm_success_1';
  }
  logAction(LOG_ACTIONS.FREESPIN_ACCEPT_ERROR, logParams);
  throw new Error('accountingMessages.bonuses_crm_error_1');
};

export const accept = async (bonusData, bonusType) => {
  const url = acceptRoutsMap[bonusType] || '';
  const response = await postAsUriParameters(url, bonusData);
  return handleAcceptResponse(bonusData, url, response);
};
