import { parsePromotionsApi } from '../../store/normalize';

const getValidTags = (
  enabledTags,
  parsedConfig,
  promotionsApi,
  activePromoType,
  filteredSegments
) =>
  Object.values(enabledTags)?.filter(({ list, categoryName }) => {
    const selectedPromoTypeSegments =
      parsedConfig[activePromoType]?.categories[categoryName]?.userSegments;
    const isTagIncludedInSegments = filteredSegments(selectedPromoTypeSegments);
    const promoList =
      parsedConfig[activePromoType]?.categories[categoryName]?.list || [];
    const promosForCurrentTag = parsePromotionsApi(
      promotionsApi,
      activePromoType,
      filteredSegments,
      promoList
    );
    const isCorrectTag = Object.values(promosForCurrentTag).some(
      (item) => list[item.promoId]
    );
    const currentCategory =
      parsedConfig[activePromoType]?.categories[categoryName]?.list;
    const listOfValidPromos = Object.values(currentCategory)
      .map((item) => filteredSegments(item.userSegments))
      .filter(Boolean);
    const isTagIncludeActivePromos = promosForCurrentTag.some(
      ({ promoId }) => promoList[promoId]
    );

    return (
      isTagIncludeActivePromos &&
      isTagIncludedInSegments &&
      isCorrectTag &&
      listOfValidPromos.length > 0
    );
  });

export default getValidTags;
