const getFilteredByDatePresets = ({
  config,
  topEvents,
  isUserSegmentsIncludes
}) => {
  const { slides = [] } = config;
  const filteredSlides = slides.filter((slide) =>
    isUserSegmentsIncludes(slide.userSegments)
  );

  return filteredSlides
    .map(({ presetName }) =>
      topEvents.find(({ presetName: name }) => name === presetName)
    )
    .filter((slide) => {
      if (slide) {
        const { from, to } = slide;
        const dateNow = new Date();
        let result = true;
        if (from) {
          result = new Date(from) <= dateNow;
        }

        if (result && to) {
          result = new Date(to) >= dateNow;
        }
        return result;
      }
      return false;
    });
};

export default getFilteredByDatePresets;
