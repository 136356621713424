import BasePopularBetsEvents from './BasePopularBetsEvents';
import { useCyberSportGetPathParams } from '../../../hooks/useCyberSportGetPathParams';
import { LINE_KEY } from '../../../constants';
import styles from './index.module.css';

const CyberSportPopularBetsEvents = ({ id }) => {
  const {
    category: activeCategory,
    activeSport
  } = useCyberSportGetPathParams();
  const { categoryId, categoryName } = activeCategory || {};
  const { sportId, sportName } = activeSport || {};
  const lineKey = `${LINE_KEY.POPULAR_BETS}-${sportId}`;
  return (
    <BasePopularBetsEvents
      id={id}
      sportId={sportId}
      sportName={categoryName || sportName}
      categoryId={categoryId}
      lineKey={lineKey}
      className={styles.cybersportContainer}
    />
  );
};

export default CyberSportPopularBetsEvents;
