import { useI18n } from '@namespace/i18n';
import { AText, Box } from '@alf/uikit';
import {
  SPORT_BACKGROUNDS,
  CANCELLED_EVENT_STATUS
} from '../../../../constants';
import { useEventFields } from '../../../../store';
import styles from './index.module.css';
import bgStyle from '../index.module.css';

const Participants = ({ participants = [], eventName, dataRole }) => {
  const { t } = useI18n();
  const [first = {}, second = {}] = participants;

  return participants.length === 2 ? (
    <Box
      data-role={dataRole && `${dataRole}-participants`}
      justify="between"
      align="center"
    >
      <AText breed="B" size="m" color="line_txt_1" className={styles.pName}>
        {first.participantName}
      </AText>
      <AText breed="B" size="m" color="line_txt_4">
        {t('scoreboards.prematch.vs')}
      </AText>
      <AText
        breed="B"
        size="m"
        color="line_txt_1"
        align="right"
        className={styles.pName}
      >
        {second.participantName}
      </AText>
    </Box>
  ) : (
    <Box justify="center" align="start" bottom="s">
      <AText breed="B" size="m" color="line_txt_1">
        {eventName}
      </AText>
    </Box>
  );
};

const PreMatchScoreboard = ({ eventId, eventStatusType, dataRole }) => {
  const { eventDt, participants, eventName, sportId } = useEventFields({
    eventId,
    fields: ['eventDt', 'participants', 'eventName', 'sportId']
  });
  const bg = SPORT_BACKGROUNDS[sportId] || SPORT_BACKGROUNDS.DEFAULT;
  const { f, t } = useI18n();
  const DATE_FORMATS_TYPES = f.getFormats();
  const datetime = f.getDateTime({ date: eventDt * 1000 }); // convert to milliseconds
  const date = datetime.toFormat(DATE_FORMATS_TYPES.EVENTS_DETAILS_DATE);
  const time = datetime.toFormat(DATE_FORMATS_TYPES.EVENTS_DETAILS_TIME);

  return (
    <Box
      data-role={dataRole && `${dataRole}-scoreboard`}
      direction="column"
      flex="0 0 90px"
      className={`${styles.container} ${bgStyle[bg]}`}
    >
      <Box
        flex="0 0 50px"
        direction="column"
        padding="0 s"
        justify="center"
        className={styles.participantContainer}
      >
        {eventStatusType === CANCELLED_EVENT_STATUS && (
          <Box align="center" className={styles.cancelledEventLabel}>
            <AText color="txt_color_4" breed="BU" size="xs">
              {t('web.sportsbook.eventpage.status.cancelled')}
            </AText>
          </Box>
        )}
        <Participants
          participants={participants}
          eventName={eventName}
          sportId={sportId}
          dataRole={dataRole}
        />

        <Box flex="0 0 30px" align="center" className={styles.dateTime}>
          <Box className={styles.event}>
            <Box
              data-role={dataRole && `${dataRole}-date`}
              flex="1 0 30px"
              align="center"
              justify="center"
              padding="s m"
              className={styles.date}
            >
              <AText breed="R" size="m" color="line_txt_3">
                {date}
              </AText>
            </Box>
            <Box
              data-role={dataRole && `${dataRole}-time`}
              flex="1 0 30px"
              align="center"
              justify="center"
              padding="s m"
              className={styles.time}
            >
              <AText breed="R" size="m" color="line_txt_3">
                {time}
              </AText>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PreMatchScoreboard;
