import { types } from '../types';

export const initialState = {
  isFetching: false,

  bonusWallet: {},
  loyalty: {},
  bonusMonthHistory: [],

  historyData: {}
};

export const reducers = {
  [types.SET_PLAYER_DATA]: (
    state,
    { bonusMonthHistory, bonusWallet, loyalty, isFetching }
  ) => ({
    ...state,
    bonusWallet,
    loyalty,
    bonusMonthHistory,
    isFetching
  }),
  [types.SET_SCORE_HISTORY]: (state, { data, isFetching }) => ({
    ...state,
    historyData: data,
    isFetching
  }),
  [types.SET_PURCHASE_HISTORY]: (state, { data, isFetching }) => ({
    ...state,
    historyData: data,
    isFetching
  }),
  [types.SET_IS_FETCHING]: (state, { isFetching = true }) => ({
    ...state,
    isFetching
  })
};
