import { postAsUriParameters } from '@namespace/helpers';
import { handleAcceptResponse } from '../acceptBonus';
import { getList } from '../getList';
import { getTotalPages } from '../getTotalPages';
import apiRoutes from '../apiRoutes';

export const getFreeSpinList = (filter, platform) =>
  getList(filter, platform, apiRoutes.getFreespinList);

export const getFreeSpinTotalPages = (filter = {}, platform) =>
  getTotalPages(filter, platform, apiRoutes.getFreespinCount);

export const acceptWager = async (bonus, language, platform = '') => {
  const { freespinId } = bonus;
  const params = {
    bonus_id: freespinId,
    lang: language,
    platform
  };
  const response = await postAsUriParameters(apiRoutes.crmAcceptAWS, params);
  return handleAcceptResponse(params, 'updwinbackstatefs', response);
};
