import { useContext, useEffect, useMemo, useState } from 'react';
import { groupBy, uniq } from 'lodash';
import { useResizeObserver } from '@namespace/device';
import { isOdd } from '@namespace/common';
import { UserContext } from '@namespace/user';
import { getMarketsByGroup } from '../utils';
import { useSportsbookStore } from '../../../store';
import MarketGroupsTabs from '../MarketGroupsTabs';
import MarketsGroup from '../../MarketsGroup';
import styles from './index.module.css';

const EventMarkets = ({ eventMarketsIds = [], serviceId, eventId = '' }) => {
  const [state] = useContext(UserContext);
  const { settings } = state;
  const { lineViewType: lineView } = settings;
  const [activeMarket, setActiveMarket] = useState(null);
  const [screenWidth] = useResizeObserver();
  const { markets: allMarkets, marketGroups, outcomes } = useSportsbookStore([
    'markets',
    'marketGroups',
    'outcomes'
  ]);

  const eventMarkets = useMemo(
    () =>
      eventMarketsIds.reduce(
        (acc, marketId) =>
          allMarkets[marketId] ? [...acc, allMarkets[marketId]] : acc,
        []
      ),
    [eventMarketsIds, allMarkets]
  );

  const allMarketsTemplateIds = useMemo(
    () => uniq(eventMarkets.map(({ marketTemplateId: id }) => id)),
    [eventMarkets]
  );

  const marketsByGroup = useMemo(() => {
    if (activeMarket) {
      const { marketGroupMarkets } = activeMarket;

      return getMarketsByGroup(eventMarkets, marketGroupMarkets);
    }

    return eventMarkets;
  }, [activeMarket, eventMarkets]);

  const marketsByName = Object.entries(groupBy(marketsByGroup, 'marketName'));

  marketsByName.sort(
    ([, marketsA], [, marketsB]) =>
      marketsA[0].marketOrder - marketsB[0].marketOrder
  );
  const firstColumnMarkets = useMemo(
    () => marketsByName.filter((_, i) => !isOdd(i)),
    [marketsByName]
  );
  const secondColumnMarkets = useMemo(
    () => marketsByName.filter((_, i) => isOdd(i)),
    [marketsByName]
  );

  useEffect(() => {
    if (!marketsByGroup.length) {
      setActiveMarket(null);
    }
  }, [marketsByGroup.length]);

  return (
    <div
      className={`${styles.marketsWrapper} ${lineView ? styles[lineView] : ''}`}
    >
      {allMarketsTemplateIds.length > 0 && (
        <MarketGroupsTabs
          dataRole="event-markets-filter"
          eventMarkets={eventMarkets}
          serviceId={serviceId}
          activeMarket={activeMarket}
          setActiveMarket={setActiveMarket}
          allMarketGroups={marketGroups}
          allMarketsTemplateIds={allMarketsTemplateIds}
        />
      )}
      <div
        data-role="event-markets-container"
        className={`${styles.markets} ${lineView ? styles[lineView] : ''}`}
      >
        {screenWidth <= 1671 || marketsByName.length <= 1 ? (
          marketsByName.map((marketByName) => (
            <MarketsGroup
              key={marketByName[0]}
              title={marketByName[0]}
              listOfMarkets={marketByName[1]}
              outcomes={outcomes}
              lineView={lineView}
              eventId={eventId}
            />
          ))
        ) : (
          <div className={styles.twoColumns}>
            <div className={`${styles.column} ${styles[lineView]}`}>
              {firstColumnMarkets.map((marketByName) => (
                <MarketsGroup
                  key={marketByName[0]}
                  title={marketByName[0]}
                  listOfMarkets={marketByName[1]}
                  outcomes={outcomes}
                  lineView={lineView}
                  eventId={eventId}
                />
              ))}
            </div>
            <div className={`${styles.column} ${styles[lineView]}`}>
              {secondColumnMarkets.map((marketByName) => (
                <MarketsGroup
                  key={marketByName[0]}
                  title={marketByName[0]}
                  listOfMarkets={marketByName[1]}
                  outcomes={outcomes}
                  lineView={lineView}
                  eventId={eventId}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventMarkets;
