import { useContext, useEffect } from 'react';
import { UserContext } from '@namespace/user';
import {
  WidgetContext,
  IS_WIDGET,
  POST_MESSAGE_TYPES,
  usePostMessage
} from '@namespace/widget';
import { socketSubscribe, socketSend } from '@namespace/socket';

const { SSID, MOUNT } = POST_MESSAGE_TYPES;

const useGetUserData = () => {
  const [, userActions] = useContext(UserContext);
  const [{ token: widgetToken }, { SET_SSID }] = useContext(WidgetContext);
  const { postMessage } = usePostMessage();
  const { FETCH_USER_DATA_FOR_WIDGET, FETCH_USER_DATA } = userActions;

  useEffect(() => {
    if (IS_WIDGET) {
      socketSubscribe({
        name: 'ssid_update',
        types: ['ssid_update'],
        callback: ({ data: { ssid } }) => {
          SET_SSID(ssid);
          postMessage(SSID, ssid);

          if (widgetToken) {
            socketSend({
              cmd: 'do_proto_v1', // todo 'accounting/login'
              data: {
                query: {
                  login: {
                    variables: {
                      token: widgetToken
                    }
                  }
                }
              }
            });
          }
          FETCH_USER_DATA_FOR_WIDGET().finally(() => {
            postMessage(MOUNT, '');
          });
        }
      });
    } else {
      FETCH_USER_DATA().catch(console.error);
    }
  }, [widgetToken]);
};

export default useGetUserData;
