import { get, isString, isNumber } from 'lodash';
import { useSiteSettings } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';

const useCurrencyConvertation = () => {
  const { otherSettings } = useSiteSettings();
  const { c } = useI18n();

  const convertationEnabled = get(
    otherSettings,
    'currencyConvertationInfo.enabled',
    false
  );
  const rate = get(otherSettings, 'currencyConvertationInfo.rate', 1);
  const convertationCurrency = get(
    otherSettings,
    'currencyConvertationInfo.name',
    ''
  );

  const conversions = get(
    otherSettings,
    'currencyConvertationInfo.convertations',
    {}
  );

  const getConvertedAmount = ({
    amount,
    additionalFormatting,
    currency,
    noFormattedAmount
  }) => {
    const cur = String(currency).trim();
    const conversionRate = conversions[cur]?.rate || rate;
    const conversionCurrency = conversions[cur]?.to || convertationCurrency;
    if (isString(amount)) {
      const amounts = amount.split('/');
      return {
        amounts: amounts.map((i) => {
          const formattedAmount =
            amounts.length === 1
              ? Number(noFormattedAmount) || c.toNumber(i)
              : c.toNumber(i);
          if (isNumber(formattedAmount)) {
            const convertedAmount =
              Math.round((formattedAmount / conversionRate) * 100) / 100;
            return `${
              additionalFormatting
                ? c.formatCurrency(convertedAmount)
                : convertedAmount
            }`;
          }
          return i;
        }),
        currency: conversionCurrency
      };
    }
    return { amounts: [`0 `], currency: convertationCurrency };
  };

  return {
    convertationEnabled,
    getConvertedAmount,
    currency: convertationCurrency
  };
};

export default useCurrencyConvertation;
