import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { Icon } from '@namespace/icons2';
import styles from './index.module.css';

export const JackpotInGameToggleBlock = ({ isJackpotOpened, onToggle }) => {
  const { t } = useI18n();

  return (
    <Box
      className={`${styles.jackpotToggleBlockWrapper}
          ${
            isJackpotOpened
              ? styles.jackpotToggleBlockWrapperOpen
              : styles.jackpotToggleBlockWrapperClosed
          }`}
      onClick={onToggle}
      justify="center"
    >
      <Box
        className={styles.jackpotToggleTextIconWrapper}
        direction="column"
        margin="m 0 0 0"
      >
        <Icon
          name="icons/general/nav/dropdown-up"
          color="btn_primary_txt_color_3"
          size="xs"
          className={
            isJackpotOpened
              ? styles.jackpotToggleIconOpen
              : styles.jackpotToggleIconClosed
          }
        />
        <AText
          breed="BU"
          size="xs"
          color="btn_primary_txt_color_3"
          className={styles.jackpotToggleText}
        >
          {isJackpotOpened
            ? t('games.jackpot.hideJackpot')
            : t('games.jackpot.showJackpot')}
        </AText>
      </Box>
    </Box>
  );
};
