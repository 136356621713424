import { useContext, memo, useMemo, useState, useCallback } from 'react';
import { isEmpty } from 'lodash';
import {
  getLocalStorageValue,
  setLocalStorageValue,
  LOCAL_STORAGE_KEYS
} from '@namespace/helpers';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { AText, Box } from '@alf/uikit';
import { USER_ROLES, UserContext } from '@namespace/user';
import { Icon } from '@namespace/icons2';
import { useI18n } from '@namespace/i18n';
import GameLauncher from '../../common/GameLauncher';
import Preview from './Preview';
import styles from './index.module.css';

// TODO: move to some util
const getRandomInteger = (min = 0, max = Number.MAX_SAFE_INTEGER) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const InstantGame = ({ config = {} }) => {
  const { t } = useI18n();
  const { games = [], providersWithGameScrollingEnabled = [] } = config;
  const game = useMemo(() => {
    const randomIndex = getRandomInteger(0, games.length - 1);
    if (games.length) {
      return games[randomIndex];
    }
    return {};
  }, [games.length]);
  const { launchGameId, gameCode, provider } = game;
  const isInstantGameClosed = getLocalStorageValue(
    LOCAL_STORAGE_KEYS.IS_INSTANT_GAME_CLOSED
  );
  const isScrollingEnabled = useMemo(() => {
    return providersWithGameScrollingEnabled.includes(game.provider)
      ? 'yes'
      : 'no';
  }, [game]);
  const [isOpen, setIsOpen] = useState(!isInstantGameClosed);
  const [user] = useContext(UserContext);
  const { role } = user;

  const handleToggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
    setLocalStorageValue(LOCAL_STORAGE_KEYS.IS_INSTANT_GAME_CLOSED, isOpen);
  }, [isOpen, setIsOpen]);

  return (
    role === USER_ROLES.USER &&
    !isEmpty(game) && (
      <div className={styles.wrapper}>
        <Box
          padding="s"
          onClick={handleToggleOpen}
          className={styles.trigger}
          justify="between"
          align="center"
        >
          <AText size="m" breed="BU" color="txt_color_1">
            {t(`instantGame.open`)}
          </AText>
          <Icon
            name={`icons/general/nav/${isOpen ? 'collapse' : 'expand'}`}
            size="m"
            color="txt_color_6"
          />
        </Box>
        {isOpen && (
          <div
            className={styles.container}
            onClick={() => logAction(LOG_ACTIONS.DEPOSIT_CLICK_OFFICE)}
          >
            <Preview gameCode={gameCode} provider={provider} />
            <GameLauncher
              gameCode={gameCode}
              gameId={launchGameId}
              provider={provider}
              playMode="real"
              isWithRedirect={false}
              isFullScreenAllowed={false}
              className={styles.game}
              scrolling={isScrollingEnabled}
            />
          </div>
        )}
      </div>
    )
  );
};

export default memo(InstantGame);
