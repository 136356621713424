import { Box } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import {
  useEventHeaderCtxActions,
  useEventHeaderCtxStore
} from '../../../../../store';
import styles from '../../index.module.css';

export const PinIcon = () => {
  const isPinned = useEventHeaderCtxStore('isPinned');
  const { SET_IS_PINNED } = useEventHeaderCtxActions();

  return (
    <Box className={styles.filterIcon} onClick={() => SET_IS_PINNED(!isPinned)}>
      <Icon
        name={
          isPinned
            ? 'icons/general/state/pushpin-on'
            : 'icons/general/state/pushpin-off'
        }
        size="l"
        color="line_header_txt"
      />
    </Box>
  );
};
