import { memo } from 'react';
import MenuItem from '../../MenuItem';
import { useTournament } from '../../../store';

const TournamentItem = ({
  tournamentId,
  serviceId,
  isActive,
  onClick = () => {},
  className = '',
  multiple,
  withCounter = false
}) => {
  const { tournamentName, eventCount } = useTournament(tournamentId, serviceId);

  return (
    <MenuItem
      className={className}
      name={tournamentName}
      onClick={onClick}
      isActive={isActive}
      count={eventCount}
      withCounter={withCounter}
      withCheckbox={multiple}
      fontSize="s"
      dataAttr={{ 'data-role': `tournament-id-${tournamentId}` }}
    />
  );
};

export default memo(TournamentItem);
