import { memo } from 'react';
import { AText, Box } from '@alf/uikit';
import { Image } from '@namespace/icons2';
import { getCurrencySign } from '../../../../../../utils';
import { JACKPOT_TYPE_TO_IMAGE } from '../../constants';
import { OdometerWrapper } from '../../common/OdometerWrapper';
import { useSetInHouseJackpot } from '../../useSetInHouseJackpot';
import { useGetIsJackpotCentsHidden } from '../../useGetIsJackpotCentsHidden';

import styles from './index.module.css';

export const JackpotItemShortInfo = memo(
  ({ jackpotType, jackpotInfo, className }) => {
    const isJackpotCentsHidden = useGetIsJackpotCentsHidden();
    const { initialValue, amountTextRef } = useSetInHouseJackpot({
      jackpotType,
      amount: jackpotInfo.convertedAmount,
      amountCoef: jackpotInfo.amountCoef,
      refreshRate: jackpotInfo.refreshRate
    });

    return (
      <Box
        justify="between"
        align="center"
        className={`${styles.itemWrapper} ${styles[jackpotType]} ${className}`}
      >
        <Image
          className={styles.jackpotTypeImage}
          name={JACKPOT_TYPE_TO_IMAGE[jackpotType]}
          alt={jackpotType}
        />
        <Box direction="column" align="end">
          <AText
            size="s"
            breed="B"
            transform="capitalize"
            className={`${styles.typeLabel} ${
              styles[`typeLabel-${jackpotType}`]
            } `}
          >
            {jackpotType}
          </AText>
          <Box align="baseline">
            <AText size="3xl" breed="B" color="txt_color_4">
              <OdometerWrapper
                initialValue={initialValue}
                textRef={amountTextRef}
                isCentsHidden={isJackpotCentsHidden}
                // need key for instant remount (without animation) in case with currency change
                key={`${jackpotType}-${jackpotInfo.convertedCurrency}`}
              />
            </AText>
            <AText
              size="l"
              breed="B"
              color="txt_color_4"
              className={styles.currencySign}
            >
              {getCurrencySign(jackpotInfo.convertedCurrency)}
            </AText>
          </Box>
        </Box>
      </Box>
    );
  }
);
