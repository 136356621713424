const docRequestedValidator = (
  options = [],
  message = 'validators.docRequestedValidator'
) => ({
  validate: (value) => {
    const option = options.find(
      ({ value: optionValue }) => value === optionValue
    );
    return !option?.hasNtf || message;
  }
});

export default docRequestedValidator;
