import { useState } from 'react';
import { SERVICE_IDS, SERVICE_IDS_TYPES_MAP } from '@namespace/bets';
import { FINISHED_EVENT_STATUS } from '../../../constants';
import PreMatchScoreboard from './PreMatch';
import SportRadarWidget from '../SportRadarWidget';
import { getSportRadarMatchId } from '../utils';
import Pandascore from '../Pandascore';
import useSportsSettings from '../../../hooks/useSportsSettings';
import { useEventFields } from '../../../store/selectors/event/selectors/eventFields';

const isIncludUrlStat = (url, params) =>
  params?.some((string) => url?.includes(string));

const Scoreboards = ({ eventId, isShowSportRadarWidget, dataRole }) => {
  const [isError, setError] = useState(false);
  const {
    eventEnetStatUrl,
    serviceId,
    eventStatusType,
    scoreboard
  } = useEventFields({
    eventId,
    fields: ['eventEnetStatUrl', 'serviceId', 'eventStatusType', 'scoreboard']
  });
  const {
    pandascoreUserName,
    sportRadarUrls,
    pandascoreUrls
  } = useSportsSettings();

  const isSportRadarUrl = isIncludUrlStat(eventEnetStatUrl, sportRadarUrls);

  const matchId = isSportRadarUrl
    ? getSportRadarMatchId(eventEnetStatUrl)
    : false;

  if (matchId && !isError && isShowSportRadarWidget && isSportRadarUrl) {
    return (
      <SportRadarWidget
        serviceId={serviceId}
        matchId={matchId}
        eventId={eventId}
        setError={setError}
      />
    );
  }

  if (isIncludUrlStat(eventEnetStatUrl, pandascoreUrls) && pandascoreUserName) {
    return (
      <Pandascore
        url={eventEnetStatUrl}
        pandascoreUserName={pandascoreUserName}
      />
    );
  }

  if (
    serviceId === SERVICE_IDS[SERVICE_IDS_TYPES_MAP.LIVE] ||
    (eventStatusType === FINISHED_EVENT_STATUS && scoreboard)
  ) {
    return null;
  }

  return (
    <PreMatchScoreboard
      eventId={eventId}
      dataRole={dataRole}
      eventStatusType={eventStatusType}
    />
  );
};

export default Scoreboards;
