import { useI18n } from '@namespace/i18n';
import { AText, Box } from '@alf/uikit';
import styles from './index.module.css';

const SearchLabel = () => {
  const { t } = useI18n();

  return (
    <Box align="center" justify="center" className={styles.wrapper}>
      <AText breed="BU" size="m" align="center" color="txt_color_7">
        {t('sports.search.begin')}
      </AText>
    </Box>
  );
};

export default SearchLabel;
