import { useMemo, useReducer } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import { useGlobalMockedStore } from '@namespace/onboarding';
import { initialState, reducers } from '../../store/mailBox/reducers';
import types from '../../store/mailBox/types';
import { actions as actionCreators } from '../../store/mailBox/actions';
import { MailBoxContext } from '../../store/mailBox/context';

const MailBoxProvider = ({ children }) => {
  const memoReducers = useMemo(() => combineReducers(reducers), []);
  const [messagesState, dispatch] = useReducer(memoReducers, initialState);
  const messagesActions = useActions(types, dispatch, actionCreators);

  return (
    <MailBoxContext.Provider
      value={useGlobalMockedStore('mailBox', messagesState, messagesActions)}
    >
      {children}
    </MailBoxContext.Provider>
  );
};

export default MailBoxProvider;
