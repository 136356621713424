import { postRPC } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

export const getCategories = async ({
  lang,
  serviceId = 0,
  sportIds = [],
  tzDiff,
  date,
  timeRange,
  startDate,
  endDate
}) => {
  const request = {
    by: {
      lang,
      service_id: serviceId,
      event_count: 'category_id',
      sport_id: {
        $in: sportIds
      },
      tz_diff: tzDiff,
      date,
      time_range: timeRange,
      start_dt: startDate,
      end_dt: endDate
    }
  };

  const results = await postRPC(
    'frontend/category/get',
    request
  ).then(({ result }) => camelizeKeys(result));

  return results;
};
