import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { uniq } from 'ramda';
import { useGeneralRoutes } from '@namespace/cms';
import { useLocation } from '@namespace/i18n';
import SportItem from '../SportItem';
import ExpandedSport from './ExpandedSport';
import MenuListSkelet from '../MenuListSkelet';
import SubMenuListSkelet from '../SubMenuListSkelet';
import { useCategoriesByIds, useTournamentsByIds } from '../../store';
import styles from './index.module.css';

const FilterMenuList = ({
  config = {},
  sports = [],
  favoritesSports = [],
  sportSlugs = [],
  categories = [],
  categoryIds = [],
  tournaments = [],
  tournamentIds = [],
  basePath,
  setFilter = () => {},
  serviceId
}) => {
  const [selectedTournamentsIds, setSelectedTournamentsIds] = useState(
    tournamentIds
  );
  const { pathname } = useLocation();
  const { preMatchSport } = useGeneralRoutes();

  const sportIds = useMemo(
    () =>
      sports
        .filter(({ sportSlug }) => sportSlugs.includes(sportSlug))
        .map(({ sportId }) => sportId),
    [sportSlugs, sports]
  );
  const activeTournamentsIds = useMemo(
    () =>
      tournaments.filter((tournamentId) =>
        selectedTournamentsIds.includes(tournamentId)
      ),
    [selectedTournamentsIds, tournaments]
  );
  const activeCategoriesIds = useMemo(
    () =>
      categories.filter(({ categoryId }) => categoryIds.includes(categoryId)),
    [categories, categoryIds]
  );
  const activeTournaments = useTournamentsByIds(
    activeTournamentsIds,
    serviceId
  );
  const activeCategories = useCategoriesByIds(activeCategoriesIds, serviceId);
  const {
    showCategories = true,
    showTournaments = true,
    isSingleTournament = false,
    isShowEventsCount = false
  } = config;
  const isSportExpandable = showCategories || showTournaments;
  const isCategoryExpandable = showTournaments;
  const [expandedSports, setExpandedSports] = useState([]);
  const [expandedCategories, setExpandedCategories] = useState([]);

  const openedSports = useMemo(() => {
    const tournamentSports = activeTournaments.map(({ sportId }) => sportId);
    const categorySports = activeCategories.map(({ sportId }) => sportId);
    return uniq([...tournamentSports, ...categorySports, ...sportIds]);
  }, [activeCategories, activeTournaments, sportIds]);

  const openedCategories = useMemo(() => {
    const tourCategories = activeTournaments.map(
      ({ categoryId }) => categoryId
    );

    return tourCategories.length ? uniq(tourCategories) : categoryIds;
  }, [activeTournaments, categoryIds]);

  const handleToggleSport = useCallback(
    (sportId) => {
      if (expandedSports.includes(sportId)) {
        const sportCategories = categories
          .filter(({ sportId: id }) => id === sportId)
          .map(({ categoryId }) => categoryId);
        setExpandedSports(expandedSports.filter((id) => id !== sportId));
        setExpandedCategories(
          expandedCategories.filter((id) => !sportCategories.includes(id))
        );
        setFilter({ categories: [], tournaments: [] });
      } else {
        setExpandedSports([sportId]);
      }
    },
    [
      expandedSports,
      setFilter,
      categories,
      expandedCategories,
      setExpandedCategories,
      setExpandedSports
    ]
  );

  const handleToggleCategory = useCallback(
    (categoryId) => {
      if (expandedCategories.includes(categoryId)) {
        setExpandedCategories(
          expandedCategories.filter((id) => id !== categoryId)
        );
        const categoryTournaments = activeTournaments
          .filter(
            ({ categoryId: tourCategoryId }) => tourCategoryId === categoryId
          )
          .map(({ tournamentId }) => tournamentId);
        setSelectedTournamentsIds([]);
        setFilter({
          categories: [categoryId],
          tournaments: selectedTournamentsIds.filter(
            (id) => !categoryTournaments.includes(id)
          )
        });
      } else {
        setExpandedCategories([categoryId]);
      }
    },
    [expandedCategories, setFilter, selectedTournamentsIds, activeTournaments]
  );

  const handleCategorySelect = useCallback(
    (categoryId) => {
      setFilter({
        tournaments: [],
        categories: [categoryId]
      });
      setExpandedCategories([categoryId]);
    },
    [setFilter, setExpandedCategories]
  );

  const handleTournamentSelect = useCallback(
    (newTournamentId, newCategoryId) => {
      if (selectedTournamentsIds.includes(newTournamentId)) {
        const catsIds = activeTournaments.find(
          ({ tournamentId, categoryId }) =>
            tournamentId !== newTournamentId && categoryId === newCategoryId
        )
          ? categoryIds
          : uniq([...categoryIds, newCategoryId]);
        const filteredTournaments = selectedTournamentsIds.filter(
          (id) => id !== newTournamentId
        );

        setSelectedTournamentsIds(filteredTournaments);

        setFilter({
          categories: catsIds,
          tournaments: filteredTournaments
        });
      } else {
        const ids = isSingleTournament
          ? [newTournamentId]
          : uniq([...selectedTournamentsIds, newTournamentId]);

        setSelectedTournamentsIds(ids);

        setFilter({
          categories: categoryIds.filter((id) => id !== newCategoryId),
          tournaments: ids
        });
      }
    },
    [
      activeTournaments,
      categoryIds,
      isSingleTournament,
      selectedTournamentsIds,
      setFilter
    ]
  );

  useEffect(() => {
    if (openedSports.length) {
      setExpandedSports([openedSports[0]]);
    }
  }, [openedSports.join(',')]);

  useEffect(() => {
    if (openedCategories.length) {
      setExpandedCategories([openedCategories[0]]);
    }
  }, [openedCategories.join(',')]);

  useEffect(() => {
    if (categoryIds.length) {
      setSelectedTournamentsIds([]);
    }
  }, [categoryIds.join(',')]);

  useEffect(() => {
    if (pathname.includes(preMatchSport)) {
      setSelectedTournamentsIds([]);
      setExpandedCategories([]);
    }
  }, [pathname, preMatchSport]);

  const items = useMemo(
    () =>
      favoritesSports.length
        ? sports
            .map((sport) => ({
              ...sport,
              isPinned: favoritesSports.includes(sport.sportId)
            }))
            .sort((a, b) => b.isPinned - a.isPinned)
        : sports,
    [sports, favoritesSports]
  );
  if (!items.length) {
    return <MenuListSkelet />;
  }
  return (
    items.length > 0 &&
    items.map((sport) => {
      const { sportId } = sport;
      const isActiveSport = sportIds.includes(sportId);
      const isExpandedSport = expandedSports.includes(sportId);

      return (
        <Fragment key={`sportItem-${sportId}`}>
          <SportItem
            sport={sport}
            basePath={basePath}
            isActive={isActiveSport}
            isExpanded={isExpandedSport}
            toggleMenu={() => handleToggleSport(sportId)}
            withCounter={!isActiveSport || !isSportExpandable}
            withExpandIcon={isActiveSport && isSportExpandable}
            showPinIcon={true}
          />
          {isSportExpandable && isActiveSport && isExpandedSport && (
            <div
              className={styles.wrapper}
              data-role={`categories-list-${sportId}`}
            >
              {categories.length > 0 ? (
                categories.map(({ categoryId }) => {
                  const isExpandedCategory =
                    expandedCategories.includes(categoryId) || !showCategories;
                  const isActiveCategory =
                    categoryIds.includes(categoryId) || isExpandedCategory;

                  return (
                    <ExpandedSport
                      key={categoryId}
                      categoryId={categoryId}
                      showCategories={showCategories}
                      isActiveCategory={isActiveCategory}
                      isCategoryExpandable={isCategoryExpandable}
                      isExpandedCategory={isExpandedCategory}
                      handleCategorySelect={handleCategorySelect}
                      handleToggleCategory={handleToggleCategory}
                      selectedTournamentsIds={selectedTournamentsIds}
                      isSingleTournament={isSingleTournament}
                      isShowEventsCount={isShowEventsCount}
                      handleTournamentSelect={handleTournamentSelect}
                      serviceId={serviceId}
                    />
                  );
                })
              ) : (
                <SubMenuListSkelet />
              )}
            </div>
          )}
        </Fragment>
      );
    })
  );
};

export default memo(FilterMenuList);
