import { useEffect } from 'react';
import { useI18n } from '@namespace/i18n';
import { useConfig } from '@namespace/cms';
import { IS_PRERENDER } from '@namespace/helpers';
import { sportsbookActions, useSportsbookStore } from '../../store';
import { LINE_KEY, MODE } from '../../constants';
import EventsMicrodata from './EventsMicrodata';

const { FETCH_MICRODATA_TOP_EVENTS } = sportsbookActions;

const TopEventsMicrodata = ({ id }) => {
  const { config } = useConfig(id);
  const { isPrerenderOnly = true } = config;
  const topEvents = useSportsbookStore('topEvents');
  const { f, language: lang } = useI18n();
  const allData = topEvents.reduce(
    (acc, { events = [], tournaments = [] }) => ({
      events: acc.events.concat(events),
      tournaments: acc.tournaments.concat(tournaments)
    }),
    { events: [], tournaments: [] }
  );
  const isRender = !isPrerenderOnly || (isPrerenderOnly && IS_PRERENDER);

  useEffect(() => {
    if (isRender) {
      FETCH_MICRODATA_TOP_EVENTS({
        events: allData.events,
        tournaments: allData.tournaments,
        lang,
        serviceId: MODE.PRE_MATCH,
        lineKey: LINE_KEY.TOP_EVENTS
      });
    }
  }, [lang, f, topEvents, isRender]);

  return isRender && <EventsMicrodata lineKey={LINE_KEY.TOP_EVENTS} />;
};

export default TopEventsMicrodata;
