import { useReducer, createContext, useMemo } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import { useGlobalMockedStore } from '@namespace/onboarding';
import { reducers, initialState } from './reducers';
import { types } from './types';
import actionCreators from './actions';

export const GamesContext = createContext();

export const GamesProvider = ({ children, customReducers }) => {
  const reducer = useMemo(() => combineReducers(reducers, customReducers), [
    customReducers
  ]);

  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = useActions(types, dispatch, actionCreators);

  return (
    <GamesContext.Provider
      value={useGlobalMockedStore('games', state, actions)}
    >
      {children}
    </GamesContext.Provider>
  );
};
