import { useCallback, useContext } from 'react';
import { Icon } from '@namespace/icons2';
import { USER_ROLES, UserContext } from '@namespace/user';
import { useGeneralRoutes, useOtherSettings } from '@namespace/cms';
import { AText, Box } from '@alf/uikit';
import { LocalizedNavLink, useI18n } from '@namespace/i18n';
import { LOG_ACTIONS, logAction } from '@namespace/analytics';
import styles from './index.module.css';

export const CustomFilterLink = ({ className = '', onClick }) => {
  const [user] = useContext(UserContext);
  const { role } = user;
  const { customFilter } = useGeneralRoutes();
  const { t } = useI18n();
  const { isSportsBookAdvFilterEnabled } = useOtherSettings();

  const wrappedOnClick = useCallback(
    (...args) => {
      logAction(LOG_ACTIONS.ADV_FILTER_MENU_CUSTOM_FILTER_CLICK);

      onClick?.(...args);
    },
    [onClick]
  );

  if (!isSportsBookAdvFilterEnabled) {
    return null;
  }

  return (
    role === USER_ROLES.USER && (
      <LocalizedNavLink
        dataRole="sports-custom-filter-link"
        to={customFilter}
        className={`${styles.item} ${className}`}
        activeClassName={styles.active}
        onClick={wrappedOnClick}
      >
        <Box className={styles.container}>
          <Icon
            size="s"
            name="icons/nav_menu/sports"
            color="sm_txt_color_2"
            className={styles.icon}
          />
          <AText breed="BU" color="sm_txt_color_1" className={styles.title}>
            {t('sportsBook.sportsMenu.CustomFilter')}
          </AText>
        </Box>
      </LocalizedNavLink>
    )
  );
};
