import { isSymbol } from 'lodash';

const simpleDataProxy = new Proxy(
  {},
  {
    get(target, prop) {
      if (prop === 'length') {
        return 0;
      }

      return simpleDataProxy;
    }
  }
);

const promiseDataProxy = new Proxy(
  {},
  {
    get(target, prop) {
      switch (prop) {
        case 'length':
          return 0;

        case 'then':
        case 'catch':
        case 'finally':
          return (f) => f?.(simpleDataProxy);

        default:
          return promiseDataProxy;
      }
    }
  }
);

const actionFn = () => promiseDataProxy;

export const actionsProxy = () =>
  new Proxy(
    {},
    {
      get(target, prop) {
        // fix for browser react-devtools
        if (isSymbol(prop)) {
          return null;
        }

        return actionFn;
      }
    }
  );
