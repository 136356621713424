import styles from './index.module.css';

const ThemeIcon = ({ themeName, availableThemes }) => {
  const icon =
    availableThemes.find(({ name }) => themeName === name)?.icon || '';
  return (
    <img
      src={icon}
      alt=""
      className={styles.themeIcon}
      width="19"
      height="12"
    />
  );
};

export default ThemeIcon;
