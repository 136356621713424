import { useMemo } from 'react';
import { useI18n } from '@namespace/i18n';
import { useGetUsedSIDList } from '../useGetUsedSIDList';
import {
  MOBILE_MONEY_KYIVSTAR_SERVICE_ID,
  AIRCASH_PAY_RON_ID
} from '../../constants/paymentSystems';

export const useUsedSIDList = () => {
  const { t } = useI18n();
  const list = useGetUsedSIDList();

  return useMemo(
    () =>
      list
        .filter(notKyivstar)
        // TODO: Remove this filter once AirCash 10274 sid will be implemented
        .filter(notAirCash)
        .map(createService(t)),
    [list, t]
  );
};

const notKyivstar = (serviceId) =>
  serviceId !== MOBILE_MONEY_KYIVSTAR_SERVICE_ID;

const notAirCash = (serviceId) => serviceId !== AIRCASH_PAY_RON_ID;

const createService = (t) => (serviceId) => ({
  value: String(serviceId),
  label: t(`personalOffice.filter.options.depositHistory.wallet.${serviceId}`)
});
