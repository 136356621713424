import { useMemo } from 'react';
import { useConfig, useGeneralRoutes } from '@namespace/cms';
import { BreadCrumbsMicrodata } from '@namespace/common';
import { IS_PRERENDER } from '@namespace/helpers';
import { useI18n } from '@namespace/i18n';
import { useGetURLFilters, useGetFeedName } from '../../hooks';
import { useCategory, useProvider } from '../../context/selectors';
import { generateBreadCrumbsItems } from '../../utils';
import { CASINO_TYPE } from '../../constants';

const CasinoBreadCrumbsMicrodata = ({ id }) => {
  const { config } = useConfig(id);
  const feedName = useGetFeedName(CASINO_TYPE.CASINO);
  const { isPrerenderOnly = true } = config;
  const { t } = useI18n();
  const { games: casinoRoute } = useGeneralRoutes();
  const [selectedProviders, selectedCategory] = useGetURLFilters(
    CASINO_TYPE.CASINO
  );
  const category = useCategory(selectedCategory, feedName);
  const provider = useProvider(selectedProviders[0]);
  const isRender = !isPrerenderOnly || (isPrerenderOnly && IS_PRERENDER);

  const items = useMemo(
    () =>
      isRender
        ? generateBreadCrumbsItems(
            category,
            provider,
            casinoRoute,
            t('header.navigation.casino')
          )
        : null,
    [category, provider, isRender]
  );

  if (!isRender) {
    return null;
  }

  return <BreadCrumbsMicrodata items={items} />;
};

export default CasinoBreadCrumbsMicrodata;
