import { useContext } from 'react';
import { ReferenceContext } from '@namespace/common/src/store/reference/context';
import PhoneField from '../../common/PhoneField';

const RegPhoneField = ({ formProps = {}, size = 'm', ...props }) => {
  const {
    defaultCountryCode = '',
    phoneCodesFallback = [],
    ...restFormProps
  } = formProps;
  const { phoneCodes = [] } = useContext(ReferenceContext);

  return (
    <PhoneField
      phoneCodes={phoneCodes.length ? phoneCodes : phoneCodesFallback}
      defaultCountry={defaultCountryCode}
      size={size}
      isUseSearch={true}
      formProps={restFormProps}
      {...props}
    />
  );
};

export default RegPhoneField;
