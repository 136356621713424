import useSender from '../../hooks/useSender';
import useFreshChat from '../../hooks/useFreshChat';
import useSupport from '../../hooks/useSupport';
import useKwizBot from '../../hooks/useKwizBot';
import useZendesk from '../../hooks/useZendesk';
import { FRESH_CHAT, KWIZBOT, ZENDESK_CHAT } from '../../constants';

const SenderChat = () => {
  useSender();
  return null;
};

const FreshChat = () => {
  useFreshChat();
  return null;
};

const ZendeskChat = () => {
  useZendesk();
  return null;
};

const KwizBot = () => {
  useKwizBot();
  return null;
};

const SupportChatPicker = () => {
  const { chatName } = useSupport();

  switch (chatName) {
    case FRESH_CHAT:
      return <FreshChat />;

    case KWIZBOT:
      return <KwizBot />;

    case ZENDESK_CHAT:
      return <ZendeskChat />;

    default:
      return <SenderChat />;
  }
};

export default SupportChatPicker;
