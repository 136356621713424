import { Helmet } from '@namespace/common';
import { useI18n } from '@namespace/i18n';
import { window } from '@namespace/helpers';
import { useEventLink } from '../../hooks/useEventLink';
import { useEvents } from '../../store';

const EventsMicrodata = ({ lineKey }) => {
  const { f } = useI18n();
  const events = useEvents({ lineKey });
  const linkToEventDetail = useEventLink({ eventId: events[0].eventId });
  const url = `${window.location?.origin}${linkToEventDetail}`;
  return (
    <Helmet>
      {events.map(({ eventId, eventName, sportName, eventDt }) => (
        <script key={eventId} type="application/ld+json">
          {`{
            "@context":"https://schema.org",
            "@type":"SportsEvent",
            "name":"${eventName}",
            "url":"${url}",
            "startDate":"${f.getDateTime({ date: eventDt }).toISO()}",
            "location":{
              "@type":"Place",
              "name":"${sportName}",
              "address":{
                "@type":"PostalAddress",
                "name":""
              }
            }
          }`}
        </script>
      ))}
    </Helmet>
  );
};

export default EventsMicrodata;
