import { useContext } from 'react';
import { useHistory } from '@namespace/router';
import { AText, Box } from '@alf/uikit';
import { UserContext } from '@namespace/user';
import { useEventField, useEventFieldLength } from '../../store';
import Participant from './components/Participant';
import styles from './index.module.css';

const EventParticipants = ({
  isActiveLink,
  eventId,
  linkToEventDetail,
  isDisplayAdditionalData = false,
  isDisplayServing = true
}) => {
  const participantsLength = useEventFieldLength(eventId, 'participants');

  const eventName = useEventField(eventId, 'eventName');

  const [state] = useContext(UserContext);
  const { settings } = state;
  const { lineViewType: lineView } = settings;
  const { push } = useHistory();

  const handleClick = () => {
    if (isActiveLink && linkToEventDetail) {
      push(linkToEventDetail);
    }
  };

  return (
    <Box className={`${styles.event} ${lineView ? styles[lineView] : ''}`}>
      <Box
        data-role={`event-participants-name-${eventId}`}
        className={`${isActiveLink ? styles.activeLink : styles.link} ${
          styles.participants
        } ${lineView ? styles[lineView] : ''}`}
        onClick={handleClick}
        justify="center"
        direction="column"
      >
        {participantsLength === 2 ? (
          <>
            <Participant
              eventId={eventId}
              isDisplayAdditionalData={isDisplayAdditionalData}
              isDisplayServing={isDisplayServing}
              participantIndex={[0]}
            />
            <Participant
              eventId={eventId}
              isDisplayAdditionalData={isDisplayAdditionalData}
              isDisplayServing={isDisplayServing}
              participantIndex={[1]}
            />
          </>
        ) : (
          <Box direction="column" className={styles.participantMain}>
            <AText
              size="m"
              breed="B"
              overflow="ellipsis"
              color="line_txt_1"
              className={`${styles.participantName} ${
                lineView ? styles[lineView] : ''
              }`}
              title={eventName}
            >
              {eventName}
            </AText>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EventParticipants;
