import { Icon } from '@namespace/icons2';

const IconLink = ({ link, alt, icon, className, onClick }) => (
  <a
    target="_blank"
    onClick={onClick}
    href={link}
    rel="noreferrer"
    data-role={`user-menu-${alt.toLowerCase()}`}
  >
    <Icon className={className} name={icon} color="po_menutxt_color_5" />
  </a>
);

export default IconLink;
