import { FORM_COMPONENTS_TYPES } from '../../../constants';
import SelectField from '../../../common/SelectField';
import InputSelectField from '../../../common/InputSelectField';
import InputField from '../../../common/InputField';
import EmailInputField from '../../../common/EmailInputField';
import RadioGroup from '../../../common/RadioGroup';
import SubmitButton from '../../../common/SubmitButton';
import CheckboxField from '../../../common/CheckboxField';
import TextareaField from '../../../common/TextareaField';
import PhoneField from '../../../common/PhoneField';
import InfoMessage from '../../../common/InfoMessage';
import FormRow from '../../../common/FormRow';
import Button from '../../../common/Button';
import HrLine from '../../../common/HrLine';
import GlobalError from '../../../common/GlobalError';
import FormHelpText from '../../../common/FormHelpText';
import NameFieldInput from '../../NameFieldInput';
import RegCountrySelect from '../../RegCountrySelect';
import RegSecretQuestionSelect from '../../RegSecretQuestionSelect';
import RegDatePickerField from '../../RegDatePickerField';
import DocumentDatePickerField from '../../DocumentDatePickerField';
import RegPhoneField from '../../RegPhoneField';
import MiddleNameInput from '../../MiddleNameInput';
import PinCodeInput from '../../PinCodeInput';
import RegSecretAnswerFieldInput from '../../RegSecretAnswerFieldInput';
import PasswordField from '../../PasswordField';
import CaptchaField from '../../CaptchaField';
import AmountInputField from '../../AmountInputField';
import DatePickerField from '../../DatePickerField';
import DocumentNumber from '../../DocumentNumber';
import DocumentNumberWithType from '../../DocumentNumberWithType';
import RepeatPasswordField from '../../RepeatPasswordField';
import GenderButtonGroup from '../../GenderButtonGroup';
import LinkGroup from '../../../common/LinkGroup';
import { VerificationField } from '../../VerificationField';
import KeyboardDatePickerField from '../../KeyboardDatePickerField';
import { ToggleCheckboxInfo } from '../../ToggleCheckboxInfo';
import RegCurrencySelect from '../../RegCurrencySelect';
import UserCountry from '../../UserCountry';
import TokenDependedField from '../../TokenDependedField';
import PixKeyField from '../../PixKeyField';
import BonusSlider from '../../../common/BonusSlider';
import BonusNotification from '../../../common/BonusNotification';
import { WelcomePackRadioSelect } from '../../WelcomePackRadioSelect';
import CardSelect from '../../CardSelect';
import CardExpirationDate from '../../CardExpirationDate';
import NeedHelp from '../../../common/NeedHelp';
import { ReCaptchaField } from '../../ReCaptchaField';

export const formComponentsMap = {
  [FORM_COMPONENTS_TYPES.BUTTON]: Button,
  [FORM_COMPONENTS_TYPES.SELECT]: SelectField,
  [FORM_COMPONENTS_TYPES.INPUT_FIELD]: InputField,
  [FORM_COMPONENTS_TYPES.EMAIL_INPUT_FIELD]: EmailInputField,
  [FORM_COMPONENTS_TYPES.INPUT_SELECT]: InputSelectField,
  [FORM_COMPONENTS_TYPES.TOKEN_DEPENDED_FIELD]: TokenDependedField,
  [FORM_COMPONENTS_TYPES.PIX_KEY_FIELD]: PixKeyField,
  [FORM_COMPONENTS_TYPES.REG_COUNTRY_SELECT]: RegCountrySelect,
  [FORM_COMPONENTS_TYPES.RE_CAPTCHA]: ReCaptchaField,
  [FORM_COMPONENTS_TYPES.REG_CURRENCY_SELECT]: RegCurrencySelect,
  [FORM_COMPONENTS_TYPES.REG_SECRET_QUESTION_SELECT]: RegSecretQuestionSelect,
  [FORM_COMPONENTS_TYPES.REG_SECRET_ANSWER_INPUT]: RegSecretAnswerFieldInput,
  [FORM_COMPONENTS_TYPES.REG_DATE_PICKER]: RegDatePickerField,
  [FORM_COMPONENTS_TYPES.DOCUMENT_DATE_PICKER]: DocumentDatePickerField,
  [FORM_COMPONENTS_TYPES.DATE_PICKER]: DatePickerField,
  [FORM_COMPONENTS_TYPES.KEYBOARD_DATE_PICKER]: KeyboardDatePickerField,
  [FORM_COMPONENTS_TYPES.RADIO_GROUP]: RadioGroup,
  [FORM_COMPONENTS_TYPES.LINK_GROUP]: LinkGroup,
  [FORM_COMPONENTS_TYPES.NAME_INPUT]: NameFieldInput,
  [FORM_COMPONENTS_TYPES.REG_PHONE]: RegPhoneField,
  [FORM_COMPONENTS_TYPES.SUBMIT_BUTTON]: SubmitButton,
  [FORM_COMPONENTS_TYPES.MIDDLE_NAME_INPUT]: MiddleNameInput,
  [FORM_COMPONENTS_TYPES.PIN_CODE_INPUT]: PinCodeInput,
  [FORM_COMPONENTS_TYPES.CHECKBOX_FIELD]: CheckboxField,
  [FORM_COMPONENTS_TYPES.TEXTAREA]: TextareaField,
  [FORM_COMPONENTS_TYPES.PHONE_FIELD]: PhoneField,
  [FORM_COMPONENTS_TYPES.PASSWORD_FIELD]: PasswordField,
  [FORM_COMPONENTS_TYPES.INFO_MESSAGE]: InfoMessage,
  [FORM_COMPONENTS_TYPES.CAPTCHA_FIELD]: CaptchaField,
  [FORM_COMPONENTS_TYPES.AMOUNT_INPUT_FIELD]: AmountInputField,
  [FORM_COMPONENTS_TYPES.CARD_SELECT]: CardSelect,
  [FORM_COMPONENTS_TYPES.CARD_EXPIRATION_DATE]: CardExpirationDate,
  [FORM_COMPONENTS_TYPES.DOCUMENT_NUMBER_FIELD]: DocumentNumber,
  [FORM_COMPONENTS_TYPES.DOCUMENT_NUMBER_WITH_TYPE]: DocumentNumberWithType,
  [FORM_COMPONENTS_TYPES.REPEAT_PASSWORD]: RepeatPasswordField,
  [FORM_COMPONENTS_TYPES.FORM_ROW]: FormRow,
  [FORM_COMPONENTS_TYPES.GENDER_BUTTONS_GROUP]: GenderButtonGroup,
  [FORM_COMPONENTS_TYPES.BUTTON]: Button,
  [FORM_COMPONENTS_TYPES.VERIFICATION_FIELD]: VerificationField,
  [FORM_COMPONENTS_TYPES.GLOBAL_ERROR]: GlobalError,
  [FORM_COMPONENTS_TYPES.FORM_HELP_TEXT]: FormHelpText,
  [FORM_COMPONENTS_TYPES.TOGGLE_CHECKBOX_INFO]: ToggleCheckboxInfo,
  [FORM_COMPONENTS_TYPES.HR_LINE]: HrLine,
  [FORM_COMPONENTS_TYPES.USER_COUNTRY_FIELD]: UserCountry,
  [FORM_COMPONENTS_TYPES.BONUS_SLIDER]: BonusSlider,
  [FORM_COMPONENTS_TYPES.WELCOME_PACK_RADIO_SELECT]: WelcomePackRadioSelect,
  [FORM_COMPONENTS_TYPES.BONUS_NOTIFICATION]: BonusNotification,
  [FORM_COMPONENTS_TYPES.NEED_HELP]: NeedHelp
};
