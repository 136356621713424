import { Icon } from '@namespace/icons2';
import { AText, Box } from '@alf/uikit';
import { hasRuleError } from '@alf/validators';
import clsx from 'clsx';
import styles from '../../index.module.css';

const RulesList = ({
  value,
  ruleList,
  classNames = {
    wrapper: ''
  }
}) => (
  <Box
    className={clsx([styles.labelWrapper, classNames.wrapper])}
    direction="column"
  >
    {ruleList.map((rule, index) => {
      const isRuleError = hasRuleError(value, rule.rules);

      return (
        <Box
          key={rule.label}
          align="center"
          left="s"
          data-role={`reg-rules-list-${index}`}
        >
          <Box
            padding={isRuleError ? '0 xs' : '0'}
            right="xs"
            data-role={`reg-rules-icon-${index}`}
          >
            <Icon
              data-role={`rules-list-${isRuleError ? 'invalid' : 'valid'}`}
              style={isRuleError ? { width: 4 } : { width: 12 }}
              name={`icons/general/state/${isRuleError ? 'not' : ''}verified`}
              color={isRuleError ? 'state_info' : 'state_success'}
            />
          </Box>
          <AText color="txt_color_7" breed="R" size="s">
            {rule.label}
          </AText>
        </Box>
      );
    })}
  </Box>
);

export default RulesList;
