import {
  LOG_ACTIONS,
  NEW_RELIC_LOG_ERRORS,
  logNewRelicAction
} from '@namespace/analytics';
import { useOtherSettings } from '@namespace/cms';
import { window } from '@namespace/helpers';
import { useCallback, useEffect, useRef } from 'react';
import { reCaptchaHeaders } from './utils/reCaptchaHeaders';

import { loadScript } from './utils/loadScript';

export const useReCaptcha = (place = '') => {
  const { reCaptchaConfig = {} } = useOtherSettings();
  const { reCaptchaId = '', captchaTimeout = 5000 } = reCaptchaConfig;

  const ref = useRef();

  useEffect(() => {
    ref.current = new Promise((resolve) => {
      if (reCaptchaId && !window.recaptcha) {
        loadScript(reCaptchaId, place).then((result) => {
          resolve(result);
        });
      } else {
        resolve(true);
      }
    });
  }, []);

  const loadCaptchaToken = useCallback(() => {
    return new Promise((resolve) => {
      if (reCaptchaId) {
        const initialTime = Date.now();
        const timeoutId = setTimeout(() => {
          logNewRelicAction(NEW_RELIC_LOG_ERRORS.RECAPTCHA_ERROR, {
            message: 'ReCaptcha timeout',
            place
          });
          resolve({});
        }, captchaTimeout);
        logNewRelicAction(LOG_ACTIONS.RECAPTCHA_SUCCESS, {
          message: 'ReCAPTCHA ref.current.then start',
          place
        });
        ref.current.then((isScriptLoaded) => {
          if (isScriptLoaded) {
            logNewRelicAction(LOG_ACTIONS.RECAPTCHA_SUCCESS, {
              message: 'ReCAPTCHA beforeReady'
            });
            window.grecaptcha.enterprise.ready(() => {
              logNewRelicAction(LOG_ACTIONS.RECAPTCHA_SUCCESS, {
                message: 'ReCAPTCHA ready'
              });
              window.grecaptcha.enterprise
                .execute(reCaptchaId, { action: place })
                .then((token) => {
                  clearTimeout(timeoutId);
                  logNewRelicAction(LOG_ACTIONS.RECAPTCHA_SUCCESS, {
                    message: 'ReCAPTCHA token received',
                    place,
                    time: ((Date.now() - initialTime) / 1000).toFixed()
                  });
                  resolve(reCaptchaHeaders(token, place));
                })
                .catch((error) => {
                  logNewRelicAction(NEW_RELIC_LOG_ERRORS.RECAPTCHA_ERROR, {
                    message: 'ReCaptcha load token error',
                    place,
                    error
                  });
                  clearTimeout(timeoutId);
                  resolve({});
                });
            });
          } else {
            clearTimeout(timeoutId);
            resolve({});
          }
        });
      } else {
        resolve({});
      }
    });
  }, [captchaTimeout, place, reCaptchaId]);

  return loadCaptchaToken;
};
