import {
  useContext,
  useState,
  useEffect,
  useCallback,
  useReducer
} from 'react';
import { isEmpty } from 'lodash';
import { useConfig } from '@namespace/cms';
import { DeviceContext } from '@namespace/device';
import { useI18n } from '@namespace/i18n';
import { Box, SkeletTable } from '@alf/uikit';
import { FILTER_ALL_VALUE, NoData, Filter } from '@namespace/common';
import { PersonalOfficeWrapper } from '@namespace/account';
import { getKeyByServiceId, PaymentIcon } from '@namespace/payments-sw';
import { useGetThemeConfig, useThemeComponents } from '@namespace/themes';
import {
  formatDateColumn,
  formatStatusColumn,
  getFiltersConfig
} from './utils';
import { getDepositHistory, getWithdrawHistory } from '../../api/balance';
import {
  setDataAction,
  setShowMoreAction,
  setIsFetchingAction,
  balanceReducer
} from '../../store/balance';
import {
  airCashKey,
  corvusPayKey,
  PAYMENT_SYSTEM_KEYS,
  PAYMENT_SYSTEMS
} from '../../constants/paymentSystems';
import TransactionsHistoryTable from './TransactionsHistoryTable';
import DefaultTransactionsHistoryCardList from './TransactionsHistoryCardList';
import { useUsedSIDList } from '../../hooks/useUsedSIDList';
import styles from './index.module.css';

const MethodColumn = ({ serviceId }) => {
  const { t } = useI18n();
  const key = getKeyByServiceId(serviceId);

  return PAYMENT_SYSTEM_KEYS[key] ? (
    <Box align="center">
      <Box align="center" justify="center" className={styles.paymentIcon}>
        <PaymentIcon
          id={serviceId}
          alt={t(
            `personalOffice.payments.name.${PAYMENT_SYSTEM_KEYS[key]}`,
            ''
          )}
        />
      </Box>
      <div className={styles.paymentName}>
        {t(`personalOffice.payments.name.${PAYMENT_SYSTEM_KEYS[key]}`, '')}
      </div>
    </Box>
  ) : (
    t('personalOffice.payments.name.unknown')
  );
};

const WalletColumn = ({ enableSecretCode, objectId, phone, serviceId }) => {
  const { t } = useI18n();
  const { isMobile } = useContext(DeviceContext);
  const serviceName = t(
    `personalOffice.filter.options.depositHistory.wallet.${serviceId}`
  );
  const key = getKeyByServiceId(serviceId);
  let secretCodeName;
  if (key === airCashKey) {
    secretCodeName = t('balance.depositHistory.table.phone');
  } else if (key === corvusPayKey) {
    secretCodeName = t('balance.depositHistory.table.iban');
  } else {
    secretCodeName = isMobile
      ? t('balance.depositHistory.table.secretCodeMobile')
      : t('balance.depositHistory.table.secretCode');
  }
  let data;

  if ([airCashKey, corvusPayKey].includes(key) && phone) {
    data = phone;
  } else if (objectId) {
    data = objectId;
  }

  return (
    <div>
      <div>{serviceName}</div>
      {enableSecretCode && data && (
        <div className={styles.secretCode}>
          {secretCodeName}
          &nbsp;
          {data}
        </div>
      )}
    </div>
  );
};

const RenderTransactionsHistory = (props) => {
  const { isMobile } = useContext(DeviceContext);

  const {
    TransactionsHistoryCardList = DefaultTransactionsHistoryCardList
  } = useThemeComponents();

  if (isMobile) {
    return <TransactionsHistoryCardList {...props} />;
  }

  return <TransactionsHistoryTable {...props} />;
};

const TransactionsHistory = ({ id: configId, type }) => {
  const [currentDataLength, setCurrentDataLength] = useState(10);
  const { config } = useConfig(configId);
  const {
    filterFields,
    dataFields = [],
    enableSecretCode = false,
    isNeedDoneTime = true,
    isDateWithLine = false,
    fontStatusConfig = {},
    dateFilterOptions,
    dateFilterDefaultValue = {},
    transactionHistory = {},
    amountFilterOptions,
    copyIconOptions = {}
  } = config;
  const isTypeDeposit = type === 'deposit';
  const { t, f } = useI18n();
  const DATE_FORMATS_TYPES = f.getFormats();
  const formatDate = useCallback(
    (date, format) => f.getDateTime({ date, fromUTC: true }).toFormat(format),
    [f]
  );

  const {
    styles: customCellClass,
    tableRowStyle,
    isClearAfter = false,
    size = 's',
    arrowConfig = {}
  } = useGetThemeConfig('TransactionsHistory');

  const usedSidList = useUsedSIDList();

  const {
    startDate = { days: 1 },
    endDate = { days: 1 }
  } = dateFilterDefaultValue;

  const [filter, setFilter] = useState({
    page: 1,
    dateOptionId: startDate.days === 1 ? '2' : String(startDate.days),
    startDate: f
      .getDateTime()
      .startOf('day')
      .minus(startDate)
      .toFormat(DATE_FORMATS_TYPES.API),
    endDate: f
      .getDateTime()
      .plus(endDate)
      .toFormat(DATE_FORMATS_TYPES.API),
    amount: FILTER_ALL_VALUE,
    state: FILTER_ALL_VALUE,
    wallet: FILTER_ALL_VALUE,
    paymentServicesFilter: []
  });

  const { page, paymentServicesFilter = [] } = filter;
  const [{ data, isFetching }, balanceDispatch] = useReducer(balanceReducer, {
    data: [],
    cards: [],
    totalPages: 1,
    isFetching: false
  });

  const formatData = (source) =>
    source.map(
      ({
        amount,
        walletId,
        serviceId,
        currency,
        cardMask,
        objectId,
        details,
        isSplittedChild,
        paymentInstrumentName,
        id,
        data: phone,
        ...rest
      }) => ({
        id,
        objectId,
        walletId,
        cardMask,
        details,
        wallet: (
          <WalletColumn
            enableSecretCode={enableSecretCode}
            objectId={objectId}
            phone={phone}
            serviceId={serviceId}
          />
        ),
        isSplittedChild,
        hideContent: serviceId === PAYMENT_SYSTEMS.EPAY_JS,
        amount: `${amount} ${currency}`,
        state: formatStatusColumn(rest, fontStatusConfig, t),
        date: formatDateColumn(
          rest,
          formatDate,
          isNeedDoneTime,
          isDateWithLine
        ),
        method: <MethodColumn serviceId={serviceId} />
      })
    );

  const condition = (!isEmpty(data) && !isFetching) || page > 1;

  const getDataRequest = useCallback(() => {
    if (!isEmpty(filter)) {
      balanceDispatch(setIsFetchingAction());

      return isTypeDeposit
        ? getDepositHistory(filter)
        : getWithdrawHistory(filter);
    }
    return Promise.resolve([]);
  }, [filter, isTypeDeposit]);

  const addData = useCallback(() => {
    getDataRequest()
      .then((response) => {
        balanceDispatch(setDataAction(response));
      })
      .catch(() => balanceDispatch(setIsFetchingAction(false)));
  }, [getDataRequest, balanceDispatch]);

  const updateData = useCallback(() => {
    getDataRequest()
      .then((response) => {
        balanceDispatch(setShowMoreAction(response));
      })
      .catch(() => balanceDispatch(setIsFetchingAction(false)));
  }, [balanceDispatch, getDataRequest]);

  const onFilterChange = useCallback((newFilter) => {
    setFilter((currentFilter) => ({
      ...currentFilter,
      ...newFilter,
      page: 1
    }));
    setCurrentDataLength(1);
  }, []);

  const onPageChange = useCallback(() => {
    setFilter((currentFilter) => ({
      ...currentFilter,
      page: page + 1
    }));
    setCurrentDataLength(data.length);
  }, [data.length, page]);

  const isShowMore =
    isFetching ||
    (currentDataLength !== 0 &&
      data.length >= currentDataLength &&
      data.length % 10 === 0);

  useEffect(() => {
    if (page === 1) {
      addData();
    } else {
      updateData();
    }
  }, [
    paymentServicesFilter.length,
    isTypeDeposit,
    filter,
    page,
    addData,
    updateData
  ]);

  return (
    <Box
      component={PersonalOfficeWrapper}
      direction="column"
      className={styles.wrapper}
    >
      <Filter
        initialFilter={filter}
        onSet={onFilterChange}
        arrowConfig={arrowConfig}
        filtersConfig={getFiltersConfig({
          t,
          usedSidList,
          isTypeDeposit,
          filterFields,
          dateFilterOptions,
          amountFilterOptions
        })}
      />
      {isEmpty(data) && !isFetching && <NoData />}
      {isFetching && page <= 1 && (
        <SkeletTable
          classNames={{
            classNameWrapper: styles.skeletMargin,
            classNameItem: styles.skeletHeight
          }}
        />
      )}
      {condition && (
        <RenderTransactionsHistory
          transactionHistory={transactionHistory}
          historyData={formatData(data)}
          enableSecretCode={enableSecretCode}
          dataFields={dataFields}
          setPage={onPageChange}
          isFetching={isFetching}
          isShowMore={isShowMore}
          customCellClass={customCellClass}
          tableRowStyle={tableRowStyle}
          isClearAfter={isClearAfter}
          copyIconOptions={copyIconOptions}
          size={size}
        />
      )}
    </Box>
  );
};

export default TransactionsHistory;
