import { Box, AText } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { useMemo } from 'react';
import styles from './index.module.css';

export const WelcomePackItemLabel = ({
  data,
  SnippetComponent,
  affiliateId
}) => {
  const { t } = useI18n();
  const {
    type,
    image,
    webNameKey,
    snippetByAffiliateId,
    defaultSnippet
  } = data;

  const currentSnippet = useMemo(
    () =>
      snippetByAffiliateId.find(({ affid }) => affid.includes(affiliateId))
        ?.snippet || defaultSnippet,
    [affiliateId, defaultSnippet, snippetByAffiliateId]
  );

  return (
    <Box justify="between" align="center" className={styles.container}>
      <Box direction="column">
        <AText
          className={styles.labelTitle}
          color="po_txt_color_1"
          size="s"
          breed="BU"
        >
          {t(webNameKey)}
        </AText>
        {SnippetComponent && <SnippetComponent snippetId={currentSnippet} />}
      </Box>
      <Box className={styles.imageContainer}>
        <img src={image} alt={type} />
      </Box>
    </Box>
  );
};
