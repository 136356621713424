import { useContext } from 'react';
import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';

import { JackpotsContext } from '../../../../../jackpotsContext/context';

import { JackpotItemInBanner } from './JackpotItemInBanner';

import styles from './index.module.css';

export const JackpotInBanner = () => {
  const [{ jackpots, ids }] = useContext(JackpotsContext);
  const { t } = useI18n();

  return (
    <Box className={styles.wrapper} direction="column" align="center">
      <AText breed="BU" align="center" color="accent_color_3" size="3xl">
        {t('games.jackpot.favbetJackpot')}
      </AText>
      <Box direction="column" className={styles.jackpotsItemsWrapper}>
        {ids.map((jackpotType) => (
          <JackpotItemInBanner
            jackpotType={jackpotType}
            jackpotInfo={jackpots[jackpotType]}
            className={styles.jackpotItem}
            key={jackpotType}
          />
        ))}
      </Box>
    </Box>
  );
};
