import { isObject } from 'lodash';

const formatAsUriParameters = (
  data,
  isWithBraces = false,
  isAccountStatuses = false
) => {
  const obj = Object.keys(data)
    .map((key) => {
      if (Array.isArray(data[key]) && isAccountStatuses) {
        return data[key]
          .map(
            (subData) =>
              `${encodeURIComponent(
                `${key}[${encodeURIComponent(subData)}]`
              )}=true`
          )
          .join('&');
      }
      if (Array.isArray(data[key])) {
        const paramKey = isWithBraces ? encodeURIComponent(`${key}[]`) : key;
        return data[key]
          .map((subData) => `${paramKey}=${encodeURIComponent(subData)}`)
          .join('&');
      }
      if (isObject(data[key])) {
        return Object.entries(data[key])
          .map(
            ([subKey, subValue]) =>
              `${encodeURIComponent(
                `${key}[${encodeURIComponent(subKey)}]`
              )}=${subValue}`
          )
          .join('&');
      }

      if (data[key] === undefined) {
        return '';
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
    })
    .filter(Boolean)
    .join('&');
  return obj;
};

export default formatAsUriParameters;
