import { getECricketScore } from './utils';
import { CricketTemplate } from '../../common/CricketTemplate';
import { useEventFields } from '../../../../store';

const ECricket = ({ eventId, byResultType, isShortVersion, lineView }) => {
  const { participants, eventServing } = useEventFields({
    eventId,
    fields: ['participants', 'eventServing']
  });

  const fullScore = getECricketScore({
    byResultType,
    isShortVersion,
    eventServing,
    participants
  });

  return (
    <CricketTemplate
      lineView={lineView}
      fullScore={fullScore}
      isShortVersion={isShortVersion}
    />
  );
};

export default ECricket;
