import { rePatterns } from '../../patterns';

const multiple10Validator = (message = 'validators.multiple10Validator') => ({
  pattern: {
    value: rePatterns.multiple10,
    message
  }
});

export default multiple10Validator;
