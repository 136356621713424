import styles from './index.module.css';

const Loader = ({
  className = '',
  width = '48px',
  height = '12px',
  color = '#00d991',
  label = ''
}) => (
  <svg
    className={`${styles.loader} ${className}`}
    width={width}
    height={height}
    fill={color}
    aria-label={label}
    viewBox="0 0 48 12"
  >
    <circle cx="6" cy="6" r="6">
      <animate
        attributeName="cx"
        begin="0s"
        dur="1s"
        values="6; 24; 42"
        keyTimes="0;  0.75; 1"
        keySplines=".42 0 1 1;
                  0 0 .59 1;
                  .42 0 1 1;"
        repeatCount="indefinite"
      />
      <animate
        attributeName="r"
        begin="0s"
        dur="1s"
        values="6; 2; 6"
        keyTimes="0;  0.75; 1"
        keySplines=".42 0 1 1;
                  0 0 .59 1;
                  .42 0 1 1;"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="24" cy="6" r="6">
      <animate
        attributeName="cx"
        begin="0s"
        dur="1s"
        values="24; 24; 8; 6"
        keyTimes="0; 0.5; 0.75; 1"
        keySplines=".42 0 1 1;
                  0 0 .59 1;
                  .42 0 1 1;
                  0 0 .59 1;"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="42" cy="6" r="6">
      <animate
        attributeName="cx"
        begin="0s"
        dur="1s"
        values="42; 42; 26; 24"
        keyTimes="0; 0.5; 0.75; 1"
        keySplines=".42 0 1 1;
                  0 0 .59 1;
                  .42 0 1 1;
                  0 0 .59 1;"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
);

export default Loader;
