import { post } from '@namespace/helpers';

export const trackFavTv = ({
  eventId,
  provider,
  timeStart, // event start date
  duration = 0,
  language,
  domain,
  userId,
  countryId
}) => {
  post('/favtv/api/statistics', {
    provider,
    event_id: eventId,
    time_start: timeStart,
    duration,
    language,
    domain,
    user_id: userId,
    user_country: countryId
  });
};
