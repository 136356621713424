export const SEGMENT_TECH_FIELDS = {
  TECH_FIELDS: 'techFields',
  ID: 'id',
  NAME: 'segmentName',
  SHOW_FOR_LANGUAGES: 'showForLanguages',
  SHOW_FOR_COUNTRIES: 'showForCountries'
};

export const ALL_VALUE = 'all'; // segment fields, or filter fields with this value, will be ignored by filtration

export const LOGGED_OUT = 'logged-out'; // 'logged-out' user role

const showForLanguagesMap = {
  LIST: 'listOfLanguages',
  EXCEPTION: 'allLanguagesExcept'
};

const showForCountriesMap = {
  LIST: 'listOfCountries',
  EXCEPTION: 'allCountriesExcept'
};

export const LIST_SEGMENT_FIELDS_MAP = {
  languages: {
    showKey: SEGMENT_TECH_FIELDS.SHOW_FOR_LANGUAGES,
    showMap: showForLanguagesMap
  },
  countries: {
    showKey: SEGMENT_TECH_FIELDS.SHOW_FOR_COUNTRIES,
    showMap: showForCountriesMap
  }
};

export const REGISTRATION_TYPES = {
  SHORT: 'short',
  FULL: 'full'
};
