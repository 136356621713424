/* eslint-disable */
import { Helmet } from '@namespace/common';
import { window, document } from '@namespace/helpers';
import { useI18n } from '@namespace/i18n';
import { SPORTRADAR_LANG } from '../../constants';
import useSportsSettings from '../../hooks/useSportsSettings';

const OneEventHelmet = () => {
  const { language } = useI18n();
  const { sportRadarWidgetUrl } = useSportsSettings();

  if (!sportRadarWidgetUrl || window.IS_SSR) {
    return null;
  }

  return (
    <Helmet>
      <script type="text/javascript">
        {(function(a,b,c,d,e,f,g,h,i){a[e]||(i=a[e]=function(){(a[e].q=a[e].q||[]).push(arguments)},i.l=1*new Date,i.o=f,
            g=b.createElement(c),[h] = b.getElementsByTagName(c),g.async=1,g.src=d,g.setAttribute("n",e),h.parentNode.insertBefore(g,h)
        )})(window,document,"script", sportRadarWidgetUrl, "SIR", {
          theme: false,
          ...(SPORTRADAR_LANG[language] && {
            language: SPORTRADAR_LANG[language]
          }),
        })}
      </script>
    </Helmet>
  );
};

export default OneEventHelmet;
