import { useConfig } from '@namespace/cms';
import { Support } from '@namespace/auth';

import Steps from './Steps';
import styles from './index.module.css';

const VerificationWizard = ({
  id,
  steps,
  componentsMap,
  isShowNeedHelpLink,
  classNames = {}
}) => {
  const { config } = useConfig(id);

  return (
    <>
      <Steps
        config={config}
        steps={steps}
        componentsMap={componentsMap}
        classNames={classNames}
      />

      {isShowNeedHelpLink && (
        <Support
          dataRole="profile-documents"
          className={styles.supportLink}
          alwaysShowWidget={true}
        />
      )}
    </>
  );
};

export default VerificationWizard;
