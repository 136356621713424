import { isEmpty } from 'lodash';
import { useMatchPath } from '@namespace/router';
import { useUserSettings } from '@namespace/common';
import { useOtherSettings } from '@namespace/cms';
import { NOTIFICATION_TYPES } from '../../constants';

const VERIFICATION_NOTIFICATION_TYPES = [
  NOTIFICATION_TYPES.UNVERIFIED_USER,
  NOTIFICATION_TYPES.NOT_VERIFIED_USER,
  NOTIFICATION_TYPES.VERIFIED_USER,
  NOTIFICATION_TYPES.ADDITIONAL_INFORMATION_OTHER_VERIFICATION,
  NOTIFICATION_TYPES.ADDITIONAL_INFORMATION_UPLOAD_VERIFICATION,
  NOTIFICATION_TYPES.PASSPORT_VERIFICATION
];

const useShowVerificationToast = ({ notificationType } = {}) => {
  const isVerificationToast = VERIFICATION_NOTIFICATION_TYPES.includes(
    notificationType
  );

  const { isShowVerificationNotification = false } = useOtherSettings();
  const { routesWithHiddenVerificationToast } = useUserSettings();
  const matchPath = useMatchPath(routesWithHiddenVerificationToast, {
    isReturnFullMatch: true
  });

  if (!isVerificationToast) return true;

  if (isShowVerificationNotification === false) return false;

  return isEmpty(matchPath);
};

export default useShowVerificationToast;
