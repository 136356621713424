import { camelizeKeys } from 'humps';
import {
  processEvents,
  normalizeEvents,
  normalizePages,
  normalizeTournaments,
  formatVirtualSportsEvents
} from '../normalize';
import { types } from '../types/index.js';

export const initialState = {
  sportsName: [],
  categoriesName: [],
  tournamentsName: [],
  filtersChanged: false,
  didCancel: false,
  currentPage: 1,
  events: [],
  pages: [],
  sportEventResult: [],
  hasNextPage: false,
  virtualEventResult: [],
  virtualTournament: [],
  offset: 0,
  virtualCategories: [],
  processing: false
};

export const reducers = {
  [types.SET_SPORTS_NAME]: (state, sportsName) => ({
    ...state,
    sportsName
  }),
  [types.SET_CATEGORIES]: (state, categoriesName) => ({
    ...state,
    categoriesName
  }),
  [types.SET_TOURNAMENTS]: (state, tournamentsName) => ({
    ...state,
    tournamentsName
  }),
  [types.SET_SPORTS_EVENTS_RESULT]: (state, payload) => {
    const { events, pages } = payload;
    const { hasNextPage } = normalizePages(pages);
    return {
      ...state,
      sportEventResult:
        state.currentPage > 1
          ? [...state.sportEventResult, ...processEvents(events)]
          : processEvents(events),
      hasNextPage
    };
  },
  [types.SET_CURRENT_PAGE]: (state, currentPage) => ({
    ...state,
    currentPage: currentPage + 1
  }),
  [types.SET_OFFSET]: (state, offset) => ({
    ...state,
    offset
  }),
  [types.SET_PROCESSING]: (state, processing) => ({
    ...state,
    processing
  }),
  [types.SET_OFFSET]: (state, offset) => ({
    ...state,
    offset
  }),
  [types.RESET_SPORT]: (state) => ({
    ...state,
    currentPage: 1,
    events: [],
    sportEventResult: []
  }),
  [types.SET_VIRTUAL_EVENTS_RESULT]: (state, payload) => {
    const { data } = payload;
    const { providerResults } = camelizeKeys(data);
    const { categories = [] } = providerResults;
    const { tournaments, categoryName } = categories[0] || {};
    const normalizedEvents = normalizeEvents(tournaments, categoryName);
    const formatedEvents = formatVirtualSportsEvents({
      events: normalizedEvents
    });
    const events = state.offset
      ? [...state.virtualEventResult, ...formatedEvents]
      : formatedEvents;
    return {
      ...state,
      virtualEventResult: events
    };
  },
  [types.SET_VIRTUAL_FILTER_OPTIONS]: (state, payload) => {
    const { data, isBetradar } = payload;
    const { providerMenu } = camelizeKeys(data);
    let responseCategories;
    let responseTournaments;
    if (isBetradar) {
      const { sportName, sportId } = providerMenu[0] || {};

      responseCategories = [
        {
          name: sportName,
          id: sportId
        }
      ];
    } else {
      const { categories: currentCategories } = providerMenu;
      responseCategories = currentCategories.reduce(
        (memo, { categoryName, categoryId }) => [
          ...memo,
          {
            name: categoryName,
            id: categoryId
          }
        ],
        []
      );
      responseTournaments = currentCategories.reduce(
        (memo, { categoryId, tournaments: currentTournaments }) => {
          return {
            ...memo,
            [categoryId]: [...normalizeTournaments(currentTournaments)]
          };
        },
        {}
      );
    }
    return {
      ...state,
      virtualTournament: responseTournaments || [],
      virtualCategories: responseCategories || []
    };
  }
};
