import { useCallback } from 'react';
import { Icon } from '@namespace/icons2';
import { AText, Box, Button } from '@alf/uikit';
import { LOG_ACTIONS, logAction } from '@namespace/analytics';
import { useGeneralRoutes } from '@namespace/cms';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { MODE } from '../../constants';
import { useSportBySportSlug } from '../../store';
import styles from './index.module.css';

const AllPreMatchSportButton = ({ sport }) => {
  const { sportSlug, sportName, sportId } = sport;
  const preMatchCurrentSport = useSportBySportSlug(sportSlug, MODE.PRE_MATCH);
  const { eventCount = 0 } = preMatchCurrentSport || {};
  const redirectSlug = eventCount > 0 ? sportSlug : '';
  const { t } = useI18n();
  const { preMatchSport } = useGeneralRoutes();

  const handleClick = useCallback(
    () =>
      logAction(LOG_ACTIONS.PREMATCH_CLICK, {
        sportId,
        sportName
      }),
    [sportId, sportName]
  );

  return (
    <Box className={styles.buttonWrapper} data-role="pre-match-sport-button">
      <Button
        size="l"
        actionType="color3"
        intent="quaternary"
        className={styles.button}
        onClick={handleClick}
        data-role="pre-match-all-btn"
      >
        <LocalizedLink to={`${preMatchSport}/${redirectSlug}`}>
          <AText breed="BU" size="m" className={styles.gotoAll}>
            {t('sportsBook.live.gotosport', [sportName])}
            <Icon
              size="s"
              name="icons/general/nav/go-to"
              className={styles.gotoAllIcon}
            />
          </AText>
        </LocalizedLink>
      </Button>
    </Box>
  );
};

export default AllPreMatchSportButton;
