import {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
  cloneElement
} from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import { Icon } from '@namespace/icons2';
import { IS_PRERENDER } from '@namespace/helpers';
import Flicking, { FlickingError } from '@egjs/react-flicking';
import { AutoPlay } from '@egjs/flicking-plugins';
import { useScreenSize } from '@namespace/device';
import useIsomorphicLayoutEffect from './hooks/useIsomorphicLayoutEffect';
import Box from '../Box';
import Dots from '../Dots';
import { TRANSITION_CONVERT_TYPES, TRANSITION_TYPES } from './constants';
import styles from './index.module.css';
import '@egjs/flicking-plugins/dist/flicking-plugins.css';
import '@egjs/react-flicking/dist/flicking.css';

const Carousel = ({
  children,
  headerComponent,
  rotationInterval = 5,
  pauseRotationOnFocus = false,
  customStyle = {},
  navigation = {},
  transitionType = TRANSITION_TYPES.MULTIPLE,
  animationDuration = 750,
  circular = false,
  circularSlidesCount = 1,
  autoPlay = true,
  moveType = [TRANSITION_CONVERT_TYPES[transitionType], { count: 1 }],
  arrow = {
    name: 'icons/general/nav/back',
    leftClass: `${styles.controllerItem} ${styles.controllerArrow} ${styles.arrowPrevious}`,
    rightClass: `${styles.controllerItem} ${styles.controllerArrow} ${styles.arrowNext}`,
    background: `${styles.background}`,
    size: 'l',
    isHideAtEdge: false,
    moveCount: 1
  },
  slider = {
    slider: `${styles.slider}`
  },
  setIsMove = () => {},
  setVisiblePanels = () => {},
  setContainerWidth = () => {},
  classNames = {},
  position = 'prev',
  isAutoFocusOnSelectedPanel = false,
  selectedPanelIndex,
  ...props
}) => {
  const plugins = useMemo(
    () =>
      autoPlay && !IS_PRERENDER
        ? [
            new AutoPlay({
              duration: rotationInterval * 1000,
              direction: 'NEXT',
              stopOnHover: pauseRotationOnFocus
            })
          ]
        : [],
    [autoPlay, rotationInterval, pauseRotationOnFocus]
  );
  const flicking = useRef();

  const flickingMoveTo = useCallback(
    (...options) =>
      flicking.current?.moveTo(...options).catch((err) => {
        if (FlickingError && err instanceof FlickingError) return;
        throw err;
      }),
    []
  );

  const onClickLeft = useCallback(() => {
    if (!arrow.moveCount || arrow.moveCount === 1) {
      flicking.current?.prev(animationDuration).catch((err) => {
        if (FlickingError && err instanceof FlickingError) return;
        throw err;
      });
    } else {
      const currentPanel = flicking.current?.currentPanel;

      flickingMoveTo(
        Math.max(0, currentPanel?.index - arrow.moveCount),
        animationDuration
      );
    }
  }, [animationDuration, arrow, flicking, flickingMoveTo]);

  const onClickRight = useCallback(() => {
    if (!arrow.moveCount || arrow.moveCount === 1) {
      flicking.current?.next(animationDuration).catch((err) => {
        if (FlickingError && err instanceof FlickingError) return;
        throw err;
      });
    } else {
      const currentPanel = flicking.current?.currentPanel;
      const lastPanelIndex = flicking.current?.panelCount - 1;
      flickingMoveTo(
        Math.min(currentPanel?.index + arrow.moveCount, lastPanelIndex),
        animationDuration
      );
    }
  }, [animationDuration, arrow, flicking, flickingMoveTo]);

  const [activeIndex, setActiveIndex] = useState(selectedPanelIndex || 0);
  const [internalIsMove, setInternalIsMove] = useState(false);
  const [isLeftArrowHidden, setIsLeftArrowHidden] = useState(
    arrow.isHideAtEdge
  );
  const [isRightArrowHidden, setIsRightArrowHidden] = useState(false);
  const { arrows = true, dots = {} } = navigation;
  const slidesNumber = children?.length || 0;
  const isCircular = circular || slidesNumber > circularSlidesCount;
  const arrowSize = arrow.size || 'l';
  const sreenSize = useScreenSize(true);
  const onChangeIndex = useCallback(({ index }) => setActiveIndex(index), []);
  const onSetActiveIndex = useCallback(
    (index) => {
      flickingMoveTo(index, animationDuration);
      setActiveIndex(index);
    },
    [flicking, flickingMoveTo]
  );

  const animationStart = useCallback(() => {
    setIsMove(true);
    setInternalIsMove(true);
  }, [setIsMove]);

  const animationStop = useCallback(() => {
    setIsMove(false);
    setInternalIsMove(false);
  }, [setIsMove]);

  useIsomorphicLayoutEffect(() => {
    const panelCount = flicking.current?.panelCount;
    const onePanelWidth =
      (flicking.current?.getPanel(0)?.element?.offsetWidth * panelCount + // all panels width
        (panelCount - 1) * 8) / // panels spase
      panelCount;
    const visiblePanels = flicking.current?.size / onePanelWidth;
    const visiblePanelsNumber = Math.floor(visiblePanels);

    if (visiblePanelsNumber) {
      setVisiblePanels(visiblePanelsNumber);
      setContainerWidth(flicking.current.containerElement.offsetWidth);
    }
  }, [setContainerWidth, setVisiblePanels, sreenSize]);

  useEffect(() => {
    if (isAutoFocusOnSelectedPanel) {
      setActiveIndex(selectedPanelIndex);
    }
  }, [isAutoFocusOnSelectedPanel, selectedPanelIndex]);

  useEffect(() => {
    if (isAutoFocusOnSelectedPanel) {
      flickingMoveTo(activeIndex);
    }
  }, [isAutoFocusOnSelectedPanel, activeIndex, flickingMoveTo]);

  // hide left arrow at left edge
  useEffect(() => {
    if (!arrow.isHideAtEdge || internalIsMove || !navigation.arrows) return;
    const firstPanelVisibleRatio = flicking?.current?.getPanel(0)?.visibleRatio;

    if (firstPanelVisibleRatio === 1 && !isLeftArrowHidden) {
      setIsLeftArrowHidden(true);
    }
    if (firstPanelVisibleRatio < 1 && isLeftArrowHidden) {
      setIsLeftArrowHidden(false);
    }
  }, [
    arrow.isHideAtEdge,
    isLeftArrowHidden,
    internalIsMove,
    navigation.arrows
  ]);

  // hide right arrow at right edge
  useEffect(() => {
    if (!arrow.isHideAtEdge || internalIsMove || !navigation.arrows) return;

    const panelCount = flicking.current?.panelCount;
    const lastPanelVisibleRatio = flicking.current?.getPanel(panelCount - 1)
      ?.visibleRatio;

    if (lastPanelVisibleRatio === 1 && !isRightArrowHidden) {
      setIsRightArrowHidden(true);
    }
    if (lastPanelVisibleRatio < 1 && isRightArrowHidden) {
      setIsRightArrowHidden(false);
    }
  }, [
    arrow.isHideAtEdge,
    isRightArrowHidden,
    internalIsMove,
    navigation.arrows
  ]);

  return (
    <Box
      style={customStyle?.position}
      className={clsx([styles.sliderContainer, classNames.container])}
    >
      {headerComponent &&
        cloneElement(headerComponent, {
          onClickArrowRight: onClickRight,
          onClickArrowLeft: onClickLeft,
          arrowActiveStatus: {
            isRightArrowHidden,
            isLeftArrowHidden
          }
        })}
      {arrows && !isLeftArrowHidden && (
        <Box onClick={onClickLeft} className={arrow.leftClass}>
          <Box className={arrow.background} />
          <Icon className={styles.icon} name={arrow.name} size={arrowSize} />
        </Box>
      )}
      <Flicking
        align={position}
        ref={flicking}
        className={slider.slider}
        circular={isCircular}
        moveType={moveType}
        bound={!isCircular}
        anchor="0"
        hanger="0"
        duration={animationDuration}
        plugins={plugins}
        onMoveStart={animationStart}
        onMoveEnd={animationStop}
        onWillChange={onChangeIndex}
        autoResize={true}
        circularFallback="bound"
        {...props}
      >
        {children}
      </Flicking>
      {dots && !isEmpty(dots) && (
        <Dots
          dotsNumber={slidesNumber}
          activeIndex={activeIndex}
          setActiveIndex={onSetActiveIndex}
          config={dots}
        />
      )}
      {arrows && !isRightArrowHidden && (
        <Box onClick={onClickRight} className={arrow.rightClass}>
          <Box className={arrow.background} />
          <Icon className={styles.icon} name={arrow.name} size={arrowSize} />
        </Box>
      )}
    </Box>
  );
};

Carousel.propTypes = {
  children: PropTypes.node,
  rotationInterval: PropTypes.number,
  pauseRotationOnFocus: PropTypes.bool,
  navigation: PropTypes.object,
  transitionType: PropTypes.string,
  animationDuration: PropTypes.number,
  circular: PropTypes.bool,
  circularSlidesCount: PropTypes.number,
  autoPlay: PropTypes.bool,
  moveType: PropTypes.oneOfType([PropTypes.string, PropTypes.array]), // shape with array just doesn't work
  arrow: PropTypes.shape({
    name: PropTypes.string,
    leftClass: PropTypes.string,
    rightClass: PropTypes.string,
    background: PropTypes.string
  }),
  slider: PropTypes.shape({
    slider: PropTypes.string
  }),
  setIsMove: PropTypes.func,
  setVisiblePanels: PropTypes.func
};

export default Carousel;
