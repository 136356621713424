import { useReducer } from 'react';
import { useGlobalMockedStore } from '@namespace/onboarding'; // hack FIX ME
import notificationReducer from '../reducer';
import { NotificationContext } from '../context';

export const NotificationProvider = ({ children }) => {
  const [
    { isShown, notificationType, NotificationComponent, message, timeout },
    notificationDispatch
  ] = useReducer(notificationReducer, {
    isShown: false,
    notificationType: undefined,
    NotificationComponent: undefined,
    message: '',
    timeout: 5000
  });

  return (
    <NotificationContext.Provider
      value={useGlobalMockedStore(
        'notifications',
        {
          isShown,
          notificationType,
          NotificationComponent,
          message,
          timeout,
          notificationDispatch
        },
        {}
      )}
    >
      {children}
    </NotificationContext.Provider>
  );
};
