import { FILTER_ALL_VALUE } from '@namespace/common';
import { postAsUriParameters } from '@namespace/helpers';
import { camelizeKeys } from 'humps';
import { CLUB_TYPES } from '../../constants';

export const getClubHistory = ({
  amount,
  endDate,
  startDate,
  currentPage,
  status,
  tabId
}) => {
  let bufFilter = {
    // wallet_hash: walletHash,
    amount: amount === FILTER_ALL_VALUE ? '' : amount,
    dt_end: endDate,
    dt_start: startDate,
    page: currentPage
  };
  // TODO move hardcoded IDS to config
  switch (tabId) {
    case CLUB_TYPES.SCORE_HISTORY:
      bufFilter = {
        ...bufFilter,
        service_id: 301,
        move: 0
      };
      break;

    case CLUB_TYPES.PURCHASE_HISTORY:
      bufFilter = {
        ...bufFilter,
        service_id: 11,
        move: 1,
        status: status === FILTER_ALL_VALUE ? '' : status
      };
      break;

    default:
      bufFilter = { ...bufFilter };
  }

  return (
    postAsUriParameters('/accounting/api/history', bufFilter)
      .then(camelizeKeys)
      // eslint-disable-next-line no-console
      .catch((error) => console.error('Error when querying login api:', error))
  );
};
