import { useMemo, useState } from 'react';
import { AText, Box } from '@alf/uikit';
import { SERVICE_IDS, SERVICE_IDS_TYPES_MAP } from '@namespace/bets';
import { useShowStatistics } from '@namespace/cms';
import BackHeader from '../../../BackHeader';
import EventFavoriteStar from '../../../../../EventFavoriteStar';
import Summaries from '../../../Summaries';
import ScoreTable from '../../../ScoreTable';
import {
  ExpandIcon,
  PinIcon,
  ScoreboardIcon,
  StatisticsIcon,
  VideoIcon
} from '../FilterIcons';
import {
  FINISHED_EVENT_STATUS,
  ONE_EVENT_HEADER_TABS as TABS,
  ONE_EVENT_HEADER_TABS,
  SUMMARIES_RESULT_TYPE_IDS
} from '../../../../../../constants';
import {
  useEventFields,
  useIsSummariesScoreboard
} from '../../../../../../store';
import AdditionalStatistics from '../../../AdditionalStatistics';
import { getSportRadarMatchId } from '../../../../utils';
import styles from './index.module.css';

export const HeaderMobile = ({
  isShowScoreboard,
  expandVideo,
  isShowVideo,
  isVideoFree,
  tabState,
  setTabState,
  isShowBackHeader,
  backTitle,
  eventId,
  lineView
}) => {
  const [eventResultText, setEventResultText] = useState('');
  const { isSummariesScoreboard } = useIsSummariesScoreboard(eventId);
  const {
    serviceId,
    eventEnetStatUrl,
    eventStatusType,
    scoreboard,
    sportId,
    eventResultId
  } = useEventFields({
    eventId,
    fields: [
      'serviceId',
      'eventEnetStatUrl',
      'eventStatusType',
      'scoreboard',
      'sportId',
      'eventResultId'
    ]
  });
  const matchId = getSportRadarMatchId(eventEnetStatUrl);

  const isDisplayExpandIcon = useMemo(
    () =>
      serviceId === SERVICE_IDS[SERVICE_IDS_TYPES_MAP.PRE_MATCH] ||
      /pandascore.co/.test(eventEnetStatUrl) ||
      matchId ||
      tabState.type === ONE_EVENT_HEADER_TABS.VIDEO,
    [eventEnetStatUrl, matchId, serviceId, tabState.type]
  );

  const isShowStatIcon = useShowStatistics(eventEnetStatUrl);
  return (
    <>
      {backTitle && (
        <BackHeader
          title={backTitle}
          sportId={sportId}
          eventId={eventId}
          dataRole="back-header"
        >
          <EventFavoriteStar
            eventId={Number(eventId)}
            serviceId={serviceId}
            className={styles.favoriteStar}
            size="m"
            color="line_header_txt"
          />
        </BackHeader>
      )}
      {(serviceId === SERVICE_IDS[SERVICE_IDS_TYPES_MAP.LIVE] ||
        (eventStatusType === FINISHED_EVENT_STATUS && scoreboard)) &&
        (isSummariesScoreboard ||
        SUMMARIES_RESULT_TYPE_IDS.includes(eventResultId) ? (
          <Summaries eventId={eventId} />
        ) : (
          <ScoreTable
            lineView={lineView}
            eventId={eventId}
            setEventResultText={setEventResultText}
          />
        ))}
      {eventResultText && (
        <Box
          justify="center"
          padding="xs 0"
          className={styles.eventResultTextContainer}
        >
          <AText breed="B" size="s" color="line_txt_1">
            {eventResultText}
          </AText>
        </Box>
      )}
      {serviceId === SERVICE_IDS[SERVICE_IDS_TYPES_MAP.LIVE] && (
        <div className={styles.additionalStats}>
          <AdditionalStatistics eventId={eventId} />
        </div>
      )}
      {isShowBackHeader && (isShowStatIcon || isShowVideo) && (
        <Box
          padding="0 s"
          justify="between"
          align="center"
          className={styles.tabsPanel}
        >
          <Box gap="m">
            {isShowScoreboard && eventEnetStatUrl && (
              <ScoreboardIcon tabState={tabState} setTabState={setTabState} />
            )}
            {isShowVideo && (
              <VideoIcon
                tabState={tabState}
                expandVideo={expandVideo}
                isVideoFree={isVideoFree}
              />
            )}
            {isShowStatIcon && (
              <StatisticsIcon eventEnetStatUrl={eventEnetStatUrl} />
            )}
          </Box>
          <Box gap="m">
            <PinIcon />
            {isDisplayExpandIcon && (
              <ExpandIcon
                setIsTabOpen={() =>
                  setTabState((prevState) => ({
                    type: prevState.type || TABS.SCORE,
                    opened: !prevState.opened
                  }))
                }
                isTabOpen={tabState.opened}
              />
            )}
          </Box>
        </Box>
      )}
    </>
  );
};
