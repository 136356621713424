import getPromoItemByCurrency from '../getPromoItemByCurrency';

const getPromoImage = (
  settingsPerCurrency,
  imagesPerLanguage,
  language,
  platform,
  currentCurrency,
  getImg = () => {}
) => {
  const promoImagePerCurrency = getPromoItemByCurrency(
    settingsPerCurrency,
    currentCurrency,
    language,
    platform,
    getImg,
    'images'
  );

  const imagePerLanguage =
    imagesPerLanguage?.find(
      ({ language: imageLang }) => imageLang === language
    ) || {};
  const { imageLink, imageLinkDark } = imagePerLanguage;
  const promoImagePerLanguage = getImg(imageLink, imageLinkDark) || imageLink;

  return promoImagePerCurrency || promoImagePerLanguage;
};

export default getPromoImage;
