import { intersection } from 'ramda';
import { GAME_GROUP, GAME_GROUP_BY_TAGS } from '../constants';

// TODO: can be removed after switch to BE recommended logic. for now used with GM
export const getGameGroup = (tagsIdt) => {
  if (intersection(tagsIdt, GAME_GROUP_BY_TAGS[GAME_GROUP.SLOT_GAME]).length) {
    return GAME_GROUP.SLOT_GAME;
  }

  if (
    intersection(tagsIdt, GAME_GROUP_BY_TAGS[GAME_GROUP.INSTANT_GAME]).length
  ) {
    return GAME_GROUP.INSTANT_GAME;
  }

  if (intersection(tagsIdt, GAME_GROUP_BY_TAGS[GAME_GROUP.TABLE_GAME]).length) {
    return GAME_GROUP.TABLE_GAME;
  }

  if (
    intersection(tagsIdt, GAME_GROUP_BY_TAGS[GAME_GROUP.ROULETTE_GAME]).length
  ) {
    return GAME_GROUP.ROULETTE_GAME;
  }

  return 'default';
};
