import { useEffect } from 'react';
import { debounce, isNumber } from 'lodash';
import { useIntersect, usePrevious } from '@namespace/helpers';
import { Loader } from '@namespace/common';
import styles from './index.module.css';

const InfiniteLoader = ({ isLoading, onNextPage }) => {
  const debouncedFetch = debounce(onNextPage, 500);
  const [bottomRef, { isIntersecting, intersectionRatio }] = useIntersect({});
  const prevIntersectionRatio = usePrevious(intersectionRatio);

  useEffect(() => {
    if (
      isIntersecting &&
      isNumber(prevIntersectionRatio) &&
      prevIntersectionRatio < intersectionRatio
    ) {
      // Fetch new events only while scrolling from top to bottom
      debouncedFetch();
    }
  }, [isIntersecting]);

  return (
    <>
      {isLoading && <Loader className={styles.loader} />}
      <div className={styles.ref} ref={bottomRef} />
    </>
  );
};

export default InfiniteLoader;
