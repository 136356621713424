import { useCallback } from 'react';
import { IS_WIDGET } from '@namespace/widget';
import { useI18n } from '@namespace/i18n';
import { DatePicker } from '@alf/uikit';
import { COLOR_SCHEME } from '@namespace/themes';
import styles from '../../index.module.css';

export const DayPickerFilter = ({ date, selectDay }) => {
  const { f } = useI18n();
  const DATE_FORMATS_TYPES = f.getFormats();

  const getLabelFormat = useCallback(
    (dt) => {
      const dayMonth = f
        .getDateTime({ date: dt, withTZ: false })
        .toFormat(DATE_FORMATS_TYPES.EVENTS_LINE_DATE);
      const weekday = f
        .getDateTime({ date: dt, withTZ: false })
        .setLocale('en-GB').weekdayLong;
      const formattedWeekday = f.localizeWeekDay(weekday);

      return `${dayMonth} ${formattedWeekday}`;
    },
    [DATE_FORMATS_TYPES, f]
  );

  return (
    <DatePicker
      date={date}
      onChange={selectDay}
      disabledSuccess={true}
      minDate={f.getDateTime().toISODate()}
      maxDate={f
        .getDateTime()
        .plus({ days: 7 })
        .toISODate()}
      size="xs"
      className={styles.datePickerContainer}
      variant={IS_WIDGET ? 'inline' : 'dialog'}
      labelFunc={(dt) => getLabelFormat(dt)}
      intentType={COLOR_SCHEME.SECONDARY}
    />
  );
};
