const types = {
  FETCH_VIRTUAL_SPORTS: 'FETCH_VIRTUAL_SPORTS',
  FETCH_VIRTUAL_STREAM: 'FETCH_VIRTUAL_STREAM',
  FETCH_VIRTUAL_EVENT_MARKETS: 'FETCH_VIRTUAL_EVENT_MARKETS',

  SET_VIRTUAL_DATA: 'SET_VIRTUAL_DATA',
  SET_VIRTUAL_STREAM: 'SET_VIRTUAL_STREAMS',
  SET_VIRTUAL_EVENT_MARKETS: 'SET_VIRTUAL_EVENT_MARKETS',

  GET_VIRTUAL_SPORTS_FEED: 'GET_VIRTUAL_SPORTS_FEED',
  SET_VIRTUAL_SPORTS_FEED: 'SET_VIRTUAL_SPORTS_FEED',

  SET_IS_VIRTUAL_SPORTS_FEED_LOADING: 'SET_IS_VIRTUAL_SPORTS_FEED_LOADING'
};

export default types;
