import { useGeneralRoutes, useComponentConfig } from '@namespace/cms';
import { useCasinoSettings } from '../useCasinoSettings';

export const useGetLobbyPageUrl = (casinoType, opts = {}) => {
  const generalRoutes = useGeneralRoutes();
  const config = useComponentConfig();
  const { lobbyRouteName } = useCasinoSettings(casinoType);

  // FYI: lobbyRouteName is a new field name for a pageGeneralRouteName. leaving pageGeneralRouteName options here for a backward compatibility
  const actualPageGeneralRouteName =
    opts?.pageGeneralRouteName ||
    opts?.lobbyRouteName ||
    config?.pageGeneralRouteName ||
    config?.lobbyRouteName ||
    lobbyRouteName;

  const lobbyPageUrl = generalRoutes[actualPageGeneralRouteName];

  return lobbyPageUrl;
};
