import { useEffect } from 'react';
import { useSetBodyClass } from '@namespace/helpers';
import { useOtherSettings } from '@namespace/cms';

const useSetBodyTag = (sex) => {
  const { hasGenderBodyClass = false } = useOtherSettings();
  const { setBodyClass } = useSetBodyClass();

  useEffect(() => {
    if (hasGenderBodyClass) {
      setBodyClass(sex);
    }
  }, []);
};

export default useSetBodyTag;
