import { window, document } from '../../constants';
// https://gist.github.com/diachedelic/0d60233dab3dcae3215da8a4dfdcd434

// For improove this logic, can reserch
// https://ionicframework.com/docs/native/app-availability
// https://github.com/adjust/ios_sdk#deeplinking-deferred
// https://developer.apple.com/library/archive/documentation/General/Conceptual/AppSearch/UniversalLinks.html
// https://support.appsflyer.com/hc/en-us/articles/208874366-OneLink-2-4-Sending-existing-users-to-open-your-app

function DeepLinker(options) {
  if (!options) {
    throw new Error('no options');
  }

  let hasFocus = true;
  let didHide = false;

  // window is blurred when dialogs are shown
  function onBlur() {
    hasFocus = false;
  }

  // document is hidden when native app is shown or browser is backgrounded
  function onVisibilityChange(e) {
    if (e.target.visibilityState === 'hidden') {
      didHide = true;
    }
  }

  // window is focused when dialogs are hidden, or browser comes into view
  function onFocus() {
    if (didHide) {
      if (options.onReturn) {
        options.onReturn();
      }

      didHide = false; // reset
    } else {
      // ignore duplicate focus event when returning from native app on
      // iOS Safari 13.3+
      if (!hasFocus && options.onFallback) { // eslint-disable-line
        // wait for app switch transition to fully complete - only then is
        // 'visibilitychange' fired
        setTimeout(() => {
          // if browser was not hidden, the deep link failed
          if (!didHide) {
            options.onFallback();
          }
        }, 2000);
      }
    }

    hasFocus = true;
  }

  // add/remove event listeners
  // `mode` can be "add" or "remove"
  function bindEvents(mode) {
    for (const conf of [
      [window, 'blur', onBlur],
      [document, 'visibilitychange', onVisibilityChange],
      [window, 'focus', onFocus]
    ]) {
      conf[0][`${mode}EventListener`](conf[1], conf[2]);
    }
  }

  // add event listeners
  bindEvents('add');

  // expose public API
  this.destroy = bindEvents.bind(null, 'remove');
  this.openURL = (url) => {
    // it can take a while for the dialog to appear
    const dialogTimeout = 500;

    setTimeout(() => {
      if (hasFocus && options.onIgnored) {
        options.onIgnored();
      }
    }, dialogTimeout);

    window.location = url;
  };
}

export default DeepLinker;
