import { scrollTo } from '../scrollTo';
import { window } from '../../constants';

export const scrollToElement = (
  el,
  behavior = 'smooth',
  padding = 10,
  timeout
) => {
  if (el && el instanceof Element) {
    const yOffset = -48 - padding; // 48px = Header height
    const top = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

    scrollTo({ top, behavior, timeout });
  }
};
