import { pathOr } from 'ramda';
import { shallow } from 'zustand/shallow';
import { createSelector } from 'reselect';
import { useSportsbookSelector } from '../../store';

export const selectCategoriesBySportId = (sportId, serviceId) =>
  createSelector(
    pathOr([], ['categoriesIdsBySportId', sportId, serviceId]),
    (state) => state.categories ?? {},
    (categoryIds, categories) =>
      categoryIds
        .map((categoryId) => categories[serviceId]?.data[categoryId])
        .filter(Boolean)
  );

export const useCategoriesBySportId = (sportId, serviceId) =>
  useSportsbookSelector(
    selectCategoriesBySportId,
    [sportId, serviceId],
    shallow
  );
