import { memo } from 'react';
import PropTypes from 'prop-types';
import Box from '../../../Box';
import AText from '../../../AText';
import Item from '../Item';
import styles from './index.module.css';

const List = ({ title, items, onSelect, query, className = '' }) => {
  return (
    <Box direction="column" className={`${styles.general} ${className}`}>
      {Boolean(title) && (
        <Box padding="0 m" align="center" className={styles.title}>
          <AText breed="B" size="l" color="search_txt_title">
            {title}
          </AText>
        </Box>
      )}
      {items.map((item) => (
        <div key={item.eventId} onClick={() => onSelect(item)}>
          <Item query={query} {...item} />
        </div>
      ))}
    </Box>
  );
};

List.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  onSelect: PropTypes.func,
  query: PropTypes.string,
  className: PropTypes.string
};

export default memo(List);
