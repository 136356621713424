import { setSpeechValueToLocalStorage } from '../../utils';
import { types } from '../types';
import { getDictionary } from '../../api';

const {
  SET_SPEECH,
  GET_DICTIONARY,
  SET_VISIBLE_MOBILE_BANNER,
  SET_SUPPORT_SPEECH
} = types;

export default {
  [SET_SPEECH]: (dispatch) => (speech) => {
    setSpeechValueToLocalStorage(speech);
    dispatch({
      type: types.SET_SPEECH,
      payload: speech
    });
  },

  [GET_DICTIONARY]: (dispatch) => async (language) => {
    try {
      const dictionary = await getDictionary(language);
      dispatch({
        type: types.SET_DICTIONARY,
        payload: { language, dictionary }
      });
    } catch (error) {
      console.error(error);
    }
  },
  [SET_VISIBLE_MOBILE_BANNER]: (dispatch) => async (value) => {
    try {
      dispatch({
        type: types.SET_VISIBLE_MOBILE_BANNER,
        payload: value
      });
    } catch (error) {
      console.error(error);
    }
  },
  [SET_SUPPORT_SPEECH]: (dispatch) => async (value) => {
    try {
      dispatch({
        type: SET_SUPPORT_SPEECH,
        payload: value
      });
    } catch (error) {
      console.error(error);
    }
  }
};
