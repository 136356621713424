import { useContext, useEffect, useRef } from 'react';
import { get } from 'lodash';
import { useI18n } from '@namespace/i18n';
import { SiteSettingsContext } from '@namespace/cms';
import { UserContext } from '@namespace/user';
import { window } from '@namespace/helpers';
import { trackFavTv } from '../../api/trackFavTv';
import { useEvent } from '../../store';

export const useTrackFavTv = ({ eventId, isValidStream }) => {
  const timer = useRef();
  const trackFn = useRef();

  const { language } = useI18n();
  const [settings] = useContext(SiteSettingsContext);
  const defaultCountry = get(settings, 'siteConfig.defaultCountry', 'UA');
  const [{ id: userId = 0, countryId = defaultCountry }] = useContext(
    UserContext
  );

  const { provider, eventDt: timeStart } = useEvent(eventId) || {};

  useEffect(() => {
    // a hack to remove dependencies
    trackFn.current = () => {
      if (!isValidStream) {
        return;
      }

      if (!timer.current || new Date() - timer.current < 30 * 1000) {
        return;
      }

      trackFavTv({
        eventId,
        provider,
        timeStart,
        language,
        domain: window.location.origin,
        userId,
        countryId
      });
    };
  });

  useEffect(() => {
    timer.current = new Date();

    const onBeforeUnload = () => {
      trackFn.current?.();
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      trackFn.current?.();

      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);
};
