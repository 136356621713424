import { getFavoritesList } from '../utils';
import { types } from './types';
import {
  parseFavorites,
  parseGamesListFromCms,
  mapServiceIdsToProviderIds,
  normalizeGameFromCms,
  formatProvider,
  normalizeLiveRtpGames
} from './normalize';

export const initialState = {
  feeds: {},
  games: {
    data: {},
    launchIdToGameId: {}
  },
  providers: {
    data: {},
    serviceIdToProviderId: {}
  },
  excludedActiveBonusGamesList: {
    data: null,
    isAlreadyBeenRequested: false
  },
  favorites: {},
  recentlyPlayed: {},
  recommendedGames: {},
  dynamicCategories: {
    wagerSlotGames: {},
    liveRtpGames: {}
  },
  isCmsPragmaticLiveDataLoaded: false,
  jackpots: {}
};

export const reducers = {
  [types.SET_GAMES]: (
    state,
    { data, disabledProviders, feedName, platform, isDesktop }
  ) => {
    const parsedFeed = parseGamesListFromCms(
      data,
      disabledProviders,
      feedName,
      isDesktop
    );
    const serviceIdToProviderId = mapServiceIdsToProviderIds(
      parsedFeed.providers?.data
    );

    const stateToSet = {
      ...state,
      feeds: {
        ...state.feeds,
        [feedName]: {
          categories: parsedFeed.categories,
          platform,
          providersGames: parsedFeed.providers.feedProvidersGames,
          providersIds: parsedFeed.providers.feedProvidersIds,
          gamesIds: parsedFeed.games.ids,
          isLoading: false,
          isLoaded: true
        }
      },
      games: {
        ...state.games,
        data: {
          ...state.games.data,
          ...parsedFeed.games.data
        },
        launchIdToGameId: {
          ...state.games.launchIdToGameId,
          ...parsedFeed.games.launchIdToGameId
        }
      },
      providers: {
        ...state.provides,
        data: {
          ...state.providers.data,
          ...parsedFeed.providers.data
        },
        serviceIdToProviderId: {
          ...state.providers.serviceIdToProviderId,
          ...serviceIdToProviderId
        }
      }
    };

    return stateToSet;
  },
  [types.SET_GAME]: (state, game) => ({
    ...state,
    games: {
      ...state.games,
      data: {
        ...state.games.data,
        [game.gameId]: normalizeGameFromCms(game)
      }
    }
  }),
  [types.SET_FAVORITES_LIST]: (
    state,
    { gamesList, entityName, isFavorite }
  ) => {
    const parsedFavorites = parseFavorites(gamesList);
    const newFavoritesList = getFavoritesList(
      state.favorites[entityName]?.ids || [],
      parsedFavorites,
      isFavorite
    );

    return {
      ...state,
      favorites: {
        ...state.favorites,
        [entityName]: {
          ids: newFavoritesList,
          isLoaded: true
        }
      }
    };
  },
  [types.SET_RECENTLY_PLAYED_LIST]: (
    state,
    { gamesList, entityName, isLoaded }
  ) => {
    return {
      ...state,
      recentlyPlayed: {
        ...state.recentlyPlayed,
        [entityName]: {
          ids: gamesList,
          isLoaded
        }
      }
    };
  },
  [types.SET_RECOMMENDED_GAMES_LIST]: (
    state,
    { gamesList, entityName, isLoaded }
  ) => {
    return {
      ...state,
      recommendedGames: {
        ...state.recommendedGames,
        [entityName]: {
          ids: gamesList,
          isLoaded
        }
      }
    };
  },
  [types.SET_IS_FEED_LOADING]: (state, { isLoading, feedName }) => ({
    ...state,
    feeds: {
      ...state.feeds,
      [feedName]: {
        ...state.feeds[feedName],
        isLoading
      }
    }
  }),
  [types.SET_JACKPOTS]: (state, payload) => ({
    ...state,
    jackpots: payload
  }),
  [types.SET_PRAGMATIC_LIVE_DATA]: (state, data = {}) => {
    return {
      ...state,
      games: {
        ...state.games,
        data: data.reduce((acc, item = {}) => {
          if (!item.tableId) return acc;

          const game = Object.values(state.games.data).find(
            ({ partnerGameId }) => partnerGameId === item.tableId
          );

          if (!game) return acc;

          const { tableLimits = {}, tableOpen = null, availableSeats } = item;
          const { minBet, maxBet } = tableLimits;

          return {
            ...acc,
            [game.gameCode]: {
              ...game,
              pragmaticLiveData: {
                minBet,
                maxBet,
                tableOpen,
                availableSeats
              }
            }
          };
        }, state.games.data)
      }
    };
  },
  [types.SET_CMS_PRAGMATIC_LIVE_INFO]: (state, { gmGames }) => {
    const updatedGames = {};
    const stateGamesArray = Object.values(state.games.data);

    for (const cmsGame of gmGames) {
      const stateGameToUpdate = stateGamesArray.find(
        // eslint-disable-next-line eqeqeq
        (stateGame) => stateGame.launchGameId == cmsGame.gameId // yep -> string == number
      );

      if (!stateGameToUpdate) continue;
      updatedGames[stateGameToUpdate.gameCode] = {
        ...stateGameToUpdate,
        // both desktop / mobile platforms have the same providerGameId
        partnerGameId: cmsGame.platform?.desktop?.providerGameId
      };
    }

    return {
      ...state,
      games: {
        ...state.games,
        data: {
          ...state.games.data,
          ...updatedGames
        }
      },
      isCmsPragmaticLiveDataLoaded: true
    };
  },
  [types.SET_PROVIDER]: (state, provider) => ({
    ...state,
    providers: {
      ...state.providers,
      data: {
        ...state.providers.data,
        [provider.providerId]: formatProvider(provider)
      }
    }
  }),
  [types.SET_EXCLUDED_GAMES_LIST]: (state, { gameIds }) => ({
    ...state,
    excludedActiveBonusGamesList: {
      data: gameIds,
      isAlreadyBeenRequested: true
    }
  }),
  [types.SET_WAGER_SLOT_GAMES]: (state, { gamesList, isLoaded }) => {
    return {
      ...state,
      dynamicCategories: {
        ...state.dynamicCategories,
        wagerSlotGames: {
          ids: gamesList,
          isLoaded
        }
      }
    };
  },
  [types.SET_LIVE_RTP_GAMES]: (state, { games, isLoaded }) => {
    const normalizedLiveRtpGames = normalizeLiveRtpGames(games);

    return {
      ...state,
      dynamicCategories: {
        ...state.dynamicCategories,
        liveRtpGames: {
          ...normalizedLiveRtpGames,
          isLoaded
        }
      }
    };
  }
};
