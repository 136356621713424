import PropTypes from 'prop-types';
import { IS_PRERENDER } from '@namespace/helpers';
import styles from './index.module.css';

const Iframe = ({
  src,
  className = '',
  title = '',
  content = null,
  frameRef,
  ...rest
}) => {
  return (
    !IS_PRERENDER && (
      <iframe
        src={src}
        className={`${styles.iframe} ${className}`}
        title={title}
        srcDoc={content}
        {...rest}
        ref={frameRef}
      />
    )
  );
};

Iframe.propTypes = {
  src: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
  frameRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ])
};

export default Iframe;
