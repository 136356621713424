import { memo } from 'react';
import { AText, Box } from '@alf/uikit';
import { Image } from '@namespace/icons2';
import { getCurrencySign } from '../../../../../../utils';
import { JACKPOT_TYPE_TO_IMAGE } from '../../constants';
import { OdometerWrapper } from '../../common/OdometerWrapper';
import { useSetInHouseJackpot } from '../../useSetInHouseJackpot';
import { useGetIsJackpotCentsHidden } from '../../useGetIsJackpotCentsHidden';

import styles from './index.module.css';

export const JackpotItemInGameShortInfo = memo(
  ({ jackpotType, jackpotInfo }) => {
    const isJackpotCentsHidden = useGetIsJackpotCentsHidden();
    const { initialValue, amountTextRef } = useSetInHouseJackpot({
      jackpotType,
      amount: jackpotInfo.convertedAmount,
      amountCoef: jackpotInfo.amountCoef,
      refreshRate: jackpotInfo.refreshRate
    });

    return (
      <Box align="center" justify="start" className={styles.itemWrapper}>
        <Box direction="column" align="center">
          <Image
            className={styles.jackpotTypeImage}
            name={JACKPOT_TYPE_TO_IMAGE[jackpotType]}
            alt={jackpotType}
          />
          <AText
            size="s"
            breed="B"
            transform="capitalize"
            className={styles[`typeLabel-${jackpotType}`]}
          >
            {jackpotType}
          </AText>
        </Box>
        <Box align="baseline" className={styles.amountWrapper}>
          <AText size="xl" breed="B" color="txt_color_4">
            <OdometerWrapper
              initialValue={initialValue}
              textRef={amountTextRef}
              isCentsHidden={isJackpotCentsHidden}
              // need key for instant remount (without animation) in case with currency change
              key={`${jackpotType}-${jackpotInfo.convertedCurrency}`}
            />
          </AText>
          <AText
            size="m"
            breed="B"
            color="txt_color_4"
            className={styles.currencySign}
          >
            {getCurrencySign(jackpotInfo.convertedCurrency)}
          </AText>
        </Box>
      </Box>
    );
  }
);
