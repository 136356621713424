import { Box, Label, AText } from '@alf/uikit';
import RulesList from '../RulesList';
import styles from '../../index.module.css';

const Control = ({
  dataRole,
  children,
  error,
  value,
  ruleList,
  hint,
  label = '',
  id = '',
  onClick = () => {},
  classNames: {
    className = '',
    hintClassName = '',
    labelClassName = '',
    wrapperClassName = '',
    rulesListWrapper = ''
  } = {}
}) => (
  <Box className={`${styles.wrapper} ${wrapperClassName}`} onClick={onClick}>
    <Box
      direction="column"
      onClick={onClick}
      className={`${styles.controlContainer} ${className}`}
    >
      {label && (
        <Label
          data-role={dataRole && `${dataRole}-label`}
          htmlFor={id}
          className={`${styles.fieldLabel} ${labelClassName}`}
        >
          {label}
        </Label>
      )}
      {children}
      {error && (
        <AText
          data-role="control-error-message"
          size="s"
          color="input_error_txt"
          className={styles.inputHintError}
        >
          {error}
        </AText>
      )}
      {Boolean(ruleList?.length) && (
        <RulesList
          value={value}
          ruleList={ruleList}
          classNames={{ wrapper: rulesListWrapper }}
        />
      )}
      {!ruleList?.length && (
        <>
          {!error && hint && (
            <AText
              data-role="control-hint-message"
              size="xs"
              color="input_label_txt"
              className={`${styles.inputHint} ${hintClassName}`}
            >
              {hint}
            </AText>
          )}
        </>
      )}
    </Box>
  </Box>
);

export default Control;
