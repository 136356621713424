export const normalizeSystemBonusDetails = (bonus, currency) => {
  const { triggerSettings } = bonus;
  const { currencies = [] } = triggerSettings || {};
  const triggerCurrency = currencies.find(({ currency: c }) => c === currency);

  return {
    ...bonus,
    triggerSettings: {
      currency: triggerCurrency,
      ...triggerSettings
    }
  };
};
