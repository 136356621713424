import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Icon } from '@namespace/icons2';
import Box from '../Box';
import AText from '../AText';
import styles from './index.module.css';

const iconNameByType = {
  info: 'info',
  warning: 'info',
  error: 'error',
  success: 'success'
};

const AlertNotification = ({
  classNames = {},
  message = '',
  type = 'info',
  onClose,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  isWithIcon = true,
  dataRole = 'alert-notification'
}) => (
  <Box
    data-role={dataRole}
    justify="between"
    align="center"
    className={clsx([
      styles.wrapper,
      classNames.messageClassName,
      styles[type]
    ])}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <Box align="center">
      {isWithIcon && (
        <Icon
          className={styles.typeIcon}
          color="txt_color_4"
          size="s"
          name={`icons/general/alert/${iconNameByType[type]}`}
        />
      )}
      <AText breed="R" size="s" color="txt_color_4">
        {message}
      </AText>
    </Box>
    {onClose && (
      <Icon
        onClick={onClose}
        size="xs"
        fill="txt_color_4"
        name="icons/general/action/close"
        className={styles.closeIcon}
      />
    )}
  </Box>
);

AlertNotification.propTypes = {
  message: PropTypes.node,
  type: PropTypes.oneOf(['info', 'error', 'success', 'warning']),
  classNames: PropTypes.shape({
    wrapperClassName: PropTypes.string
  }),
  onClose: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
};

export default AlertNotification;
