import { cloneElement, useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import clsx from 'clsx';
import { COLOR_SCHEME } from '@namespace/themes';
import Popover from '../../../Popover';
import { SelectTrigger } from './components/SelectTrigger';
import { SelectContent } from './components/SelectContent';
import styles from './index.module.css';

export const DesktopSelect = ({
  options,
  onChange = noop,
  onBlur = noop,
  value,
  disabled,
  placeholder,
  size,
  arrowSize,
  intent = 'default',
  className,
  style,
  CustomTrigger,
  CustomContent,
  dataRole,
  haveToCloseOutsideClick,
  additionSelectContentStyle = '',
  additionalPopoverStyle = '',
  additionSelectTitleStyle = '',
  additionSelectOptionStyle = {},
  additionSelectStyles = {},
  intentType,
  selectType,
  noPlaceholderColor,
  hasIcon,
  hasCheck,
  hasSearch,
  emptyText,
  showDropdownIconWhenOnlyOneOption,
  disableDropdownWhenOnlyOneOption,
  isExpandable,
  isOpenExternal,
  setIsOpenExternal,
  bottomElement = null,
  iconDropDownName,
  contentSize,
  customOptionColor
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedItem, setSelectedItem] = useState(-1);
  const inputRef = useRef(null);

  const filteredList = useMemo(
    () =>
      options.filter((item) =>
        item.title.toLowerCase().startsWith(searchValue.toLowerCase())
      ),
    [options, searchValue]
  );

  const list = searchValue ? filteredList : options;

  const titleOption = useMemo(
    () => options.find((o) => o?.value === value) || '',
    [value, options]
  );
  const isDisabled =
    (disableDropdownWhenOnlyOneOption && list.length === 1) || disabled;
  const [isOpenLocal, setIsOpenLocal] = useState(false);
  const isOpen = isOpenExternal ?? isOpenLocal;
  const setIsOpen = setIsOpenExternal ?? setIsOpenLocal;
  const handleOpen = useCallback(
    (open) => {
      if (!open) {
        setSelectedItem(-1);
      }

      setSearchValue('');

      if (!isDisabled) {
        setIsOpen(open);
      }
    },
    [isDisabled, setIsOpen]
  );

  const onSearchValueChange = useCallback((e) => {
    setSearchValue(e.target.value);
    setSelectedItem(-1);
  }, []);

  const handleClick = useCallback(
    (e) => {
      onChange({ value: e.value });
      onBlur();
      setSearchValue('');
      setSelectedItem(-1);
    },
    [onChange, onBlur]
  );
  const props = {
    value,
    titleOption,
    disabled: isDisabled,
    intent,
    placeholder,
    isOpen,
    setIsOpen,
    size,
    arrowSize,
    selectType,
    intentType,
    noPlaceholderColor,
    additionSelectStyles,
    hasIcon,
    hasSearch,
    searchValue,
    onSearchValueChange,
    inputRef,
    showDropdownIconWhenOnlyOneOption,
    options: list,
    iconDropDownName
  };

  return (
    <div
      className={clsx([className, styles.general])}
      style={style}
      data-role={dataRole}
    >
      <Popover
        trigger={
          CustomTrigger ? (
            cloneElement(CustomTrigger, props)
          ) : (
            <SelectTrigger {...props} />
          )
        }
        content={
          CustomContent ? (
            cloneElement(CustomContent, props)
          ) : (
            <SelectContent
              options={list}
              onClick={handleClick}
              size={contentSize || size}
              value={value}
              additionSelectContentStyle={additionSelectContentStyle}
              additionSelectTitleStyle={additionSelectTitleStyle}
              additionSelectOptionStyle={additionSelectOptionStyle}
              intentType={intentType}
              selectType={selectType}
              hasIcon={hasIcon}
              hasCheck={hasCheck}
              emptyText={emptyText}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              inputRef={inputRef}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              customOptionColor={customOptionColor}
            />
          )
        }
        classNames={{
          contentClass: clsx([styles.contentClass, additionalPopoverStyle])
        }}
        haveToCloseOutsideClick={haveToCloseOutsideClick}
        setIsOpenExternal={handleOpen}
        isOpenExternal={isOpen}
        isExpandable={isExpandable}
        dataRole={dataRole}
      />
      {bottomElement}
    </div>
  );
};

DesktopSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  arrowSize: PropTypes.string,
  className: PropTypes.string,
  intent: PropTypes.oneOf(['error', 'success', 'default']),
  intentType: PropTypes.oneOf([COLOR_SCHEME.PRIMARY, COLOR_SCHEME.SECONDARY]),
  size: PropTypes.oneOf(['xs', 's', 'm']),
  style: PropTypes.object,
  CustomTrigger: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,

    PropTypes.elementType
  ]),
  CustomContent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType
  ]),
  showDropdownIconWhenOnlyOneOption: PropTypes.bool,
  disableDropdownWhenOnlyOneOption: PropTypes.bool
};
