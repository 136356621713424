import { useMemo } from 'react';
import { useLocation } from '@namespace/i18n';
import { parseSearchParams } from '@namespace/helpers';

const useSearchParams = () => {
  const { search } = useLocation();

  const params = useMemo(() => parseSearchParams(search), [search]);

  return params;
};

export default useSearchParams;
