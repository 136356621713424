import { useGeneralRoutes } from '@namespace/cms';
import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { useContext } from 'react';
import { DeviceContext } from '@namespace/device';
import { MODE } from '../../constants';
import { useGetPathParams } from '../../hooks/useGetPathParams';
import { Categories } from '../../common';
import styles from './index.module.css';

const PreMatchCategoriesMenu = () => {
  const { isMobile } = useContext(DeviceContext);
  const { preMatchCategory } = useGeneralRoutes();
  const { t } = useI18n();

  const { activeSport } = useGetPathParams(MODE.PRE_MATCH);
  const { sportSlug } = activeSport || {};

  if (isMobile && !sportSlug) {
    return null;
  }

  return (
    <Box direction="column" className={styles.wrapper}>
      <Box padding="0 s" align="center" className={styles.title}>
        <AText breed="BU" size="m" color="sm_txt_color_1">
          {t('sportsBook.sportsMenu.categories.title')}
        </AText>
      </Box>
      <Categories
        serviceId={MODE.PRE_MATCH}
        categoryRoute={preMatchCategory}
        sportSlug={sportSlug}
      />
    </Box>
  );
};

export default PreMatchCategoriesMenu;
