import { types } from '../types';

export const initialState = {
  paymentServices: [],
  activeServiceId: null,
  activePaymentType: null,
  isPaymentServicesLoaded: false,
  userTokens: [],
  resultDepositData: null
};

export const reducers = {
  [types.SET_SW_CONFIG]: (state, paymentServices) => ({
    ...state,
    paymentServices,
    isPaymentServicesLoaded: true
  }),
  [types.SET_ACTIVE_SERVICE]: (state, activeServiceId) => ({
    ...state,
    activeServiceId
  }),
  [types.SET_ACTIVE_PAYMENT_TYPE]: (state, activePaymentType) => ({
    ...state,
    activePaymentType
  }),
  [types.SET_USER_TOKENS]: (state, userTokens) => ({
    ...state,
    userTokens
  }),
  [types.SET_DEPOSIT_RESULT]: (state, resultDepositData) => ({
    ...state,
    resultDepositData
  })
};
