import { useI18n } from '@namespace/i18n';
import { AText, InfoMessage } from '@alf/uikit';
import styles from './index.module.css';

const ProfileDocumentsFormInfoMessage = () => {
  const { t } = useI18n();
  return (
    <InfoMessage icon="info" className={styles.info}>
      <AText className={styles.infoText} size="s" color="po_txt_color_3">
        {t('personalOffice.documents.info.text_1')}
      </AText>
      <AText className={styles.infoText} size="s" color="po_txt_color_3">
        {t('personalOffice.documents.info.text_2')}
      </AText>
    </InfoMessage>
  );
};

export default ProfileDocumentsFormInfoMessage;
