import { useConfig } from '@namespace/cms';
import { IS_PRERENDER } from '@namespace/helpers';
import { LINE_KEY } from '../../constants';
import EventsMicrodata from './EventsMicrodata';
import { useCyberSportGetPathParams } from '../../hooks/useCyberSportGetPathParams';

const CyberSportsMicrodata = ({ id }) => {
  const { config } = useConfig(id);
  const { isPrerenderOnly = true } = config;
  const { activeSport, tournament } = useCyberSportGetPathParams();
  const { sportId } = activeSport || {};
  const { tournamentId } = tournament || {};
  const isRender = !isPrerenderOnly || (isPrerenderOnly && IS_PRERENDER);
  return (
    isRender && (
      <EventsMicrodata
        lineKey={`${LINE_KEY.CYBERSPORT_PRE_MATCH}-${sportId}-${tournamentId}`}
      />
    )
  );
};

export default CyberSportsMicrodata;
