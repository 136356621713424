import { useState, useEffect, useContext, useRef, useCallback } from 'react';
import {
  DeviceContext,
  FORM_FACTORS_MAP,
  getFormFactor
} from '@namespace/device';
import { useGeneralRoutes } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { Overlay, Box } from '@alf/uikit';
import { Loader } from '@namespace/common';
import { useIsFreeToPlayUser } from '@namespace/account';
import { UserContext, useUserInfoLoaded } from '@namespace/user';
import { window, IS_PRERENDER } from '@namespace/helpers';
import { useLauncherMessages, getLaunchGameUrl } from '../../hooks';
import {
  DEMO_MODE,
  DIRECT_URL_PROVIDERS,
  IETF_LOCALES_FOR_LAUNCH
} from '../../constants';
import GameError from './GameError';
import GameIframe from './GameIframe';
import styles from './index.module.css';

const GameLauncher = ({
  playMode,
  className = '',
  provider,
  gameId,
  lobbyUrl = window.location.origin,
  isService = false,
  isFullScreenAllowed = true,
  isWithRedirect = true,
  scrolling = 'no',
  classNames = {
    loaderWrapper: '',
    errorWrapper: ''
  }
}) => {
  const { deposit } = useGeneralRoutes();
  const isFreeToPlayUser = useIsFreeToPlayUser();
  const { deviceFormFactor } = useContext(DeviceContext);
  const [user] = useContext(UserContext);
  // todo do we really need this one at all?
  const formFactor = getFormFactor(deviceFormFactor);
  const { language } = useI18n();
  const frame = useRef(null);
  const [gameUrl, setGameUrl] = useState(null);
  const [gameContent, setGameContent] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [iframeKey, setIframeKey] = useState(0);
  const { id: userId } = user;
  const { userInfoLoaded } = useUserInfoLoaded();

  const reloadIframe = useCallback(() => setIframeKey((prev) => prev + 1), []);

  useLauncherMessages({ gameUrl, provider, frame, reloadIframe });

  useEffect(() => {
    if (gameId && provider && userInfoLoaded) {
      const launchParams = {
        demo: playMode === DEMO_MODE || !isFreeToPlayUser,
        locale: IETF_LOCALES_FOR_LAUNCH[language] || 'en-US',
        lobbyUrl,
        platform: formFactor,
        gameId,
        depositUrl: `${window.location.origin}${deposit}`,
        userId
      };
      getLaunchGameUrl(launchParams)
        .then(({ launchUrl, url, content }) => {
          const gameLaunchUrl = DIRECT_URL_PROVIDERS.includes(provider)
            ? launchUrl
            : url;

          // @todo replace this logic only for casino, mb send params isCasino only
          if (!isService && url && formFactor !== FORM_FACTORS_MAP.DESKTOP) {
            const isRedirect = url.slice(0, 4) === 'http';
            if (isRedirect && isWithRedirect) {
              window.location.replace(url);
            } else {
              setGameUrl(url);
            }
          } else {
            setGameUrl(gameLaunchUrl);
          }

          if (content) {
            setGameContent(content);
          }
        })
        .catch((error) => {
          setErrorCode(error?.launchGameErrorCode || 'noErrorCode');
        });
    }
    return () => {
      setErrorCode(null);
    };
  }, [
    provider,
    playMode,
    language,
    isFreeToPlayUser,
    userId,
    gameId,
    userInfoLoaded
  ]);

  if ((!gameUrl && !gameContent && !errorCode) || IS_PRERENDER) {
    return (
      <div className={`${styles.loaderContainer} ${classNames.loaderWrapper}`}>
        <Overlay>
          <Loader />
        </Overlay>
      </div>
    );
  }

  if (errorCode) {
    return (
      <Box className={classNames.errorWrapper}>
        <GameError errorCode={errorCode} />
      </Box>
    );
  }

  return (
    <GameIframe
      key={iframeKey}
      src={gameUrl}
      content={gameContent}
      className={className}
      ref={frame}
      scrolling={scrolling}
      allowFullScreen={isFullScreenAllowed}
    />
  );
};

export default GameLauncher;
