import { useState } from 'react';
import { Box, Textarea, Button, UploadFile, AText } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import {
  FILE_ACCEPTABLE_EXTENSIONS,
  FILE_MAX_SIZE,
  FILES_MAX_COUNT,
  CHAT_ERRORS
} from '../../../constants';

import styles from './index.module.css';

const Input = ({
  value,
  setValue,
  files,
  setFiles,
  handleSend,
  handleKeyDown,
  onMaxCountExceed,
  error
}) => {
  const { t } = useI18n();
  const [overSizeFile, setOverSizeFile] = useState(false);
  const [wrongFormatFiles, setWrongFormatFiles] = useState([]);
  const onMaxSize = (params) => setOverSizeFile(params);

  return (
    <Box direction="column" padding="m" className={styles.input}>
      <Textarea
        className={styles.textarea}
        size="s"
        rows="5"
        placeholder={t('support.chat.message-placeholder')}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <Box direction="row" justify="between" padding="s 0 0 0">
        <Box direction="column">
          {error?.type === CHAT_ERRORS.FILE_MAX_COUNT_EXCEEDED && (
            <AText
              component="span"
              color="state_error"
              className={styles.overSizeError}
            >
              {t('support.chat.maxFileAmountExceeded', [FILES_MAX_COUNT])}
            </AText>
          )}
          <Box>
            <UploadFile
              label={t('support.chat.attach-file')}
              accept={FILE_ACCEPTABLE_EXTENSIONS}
              name="attach"
              overSizeLabel={t('support.chat.oversize')}
              onChange={setFiles}
              filesList={files}
              onMaxCountExceed={onMaxCountExceed}
              maxCount={FILES_MAX_COUNT}
              maxSize={
                files.length > 1 ? files.length * FILE_MAX_SIZE : FILE_MAX_SIZE
              }
              onMaxSize={onMaxSize}
              setWrongFiles={setWrongFormatFiles}
              wrongFormatText={t('support.chat.wrongFileFormat')}
              showFilesList={files.length > 0}
              showUploadButton={files.length === 0}
              classNames={{
                containerClass: styles.filesContainer,
                filesListClass: styles.fileList,
                filesListItemClass: styles.fileListItem,
                uploadDocsButtonClass: styles.uploadDocsButton
              }}
              uploadIcon="icons/general/action/attach_file"
              uploadButtonColor="btn_quaternary_color_3"
            />
          </Box>
        </Box>
        <Button
          actionType="color1"
          size="s"
          type="primary"
          className={styles.send}
          disabled={
            ((!value || value.length === 0) && !files.length) ||
            overSizeFile ||
            Boolean(wrongFormatFiles.length)
          }
          onClick={handleSend}
        >
          {t('support.chat.send')}
        </Button>
      </Box>
    </Box>
  );
};

export default Input;
