import { hasIn } from 'lodash';
import { mergeDeepRight } from 'ramda';
import aggregateNestedPages from '../../utils/aggregateNestedPages';
import getIsPageCorrect from '../../utils/getIsPageCorrect';
import getMatchedPathFromBatch from '../../utils/getMatchedPathFromBatch';
import resetPageSeo from '../../utils/resetPageSeo';
import getBatchConfigFromCMS from '../getBatchConfigFromCMS';

export const preparePageConfig = (config = {}, formFactor) => {
  const { general = {} } = config || {};
  const pageConfig = mergeDeepRight(general, config[formFactor]);
  const isSnippetPage = Object.values(pageConfig?.components || {}).every(
    ({ type }) => type === 'segmented-html-snippet' || type === 'html-snippet'
  );

  return {
    ...pageConfig,
    pageConfig: { ...pageConfig.pageConfig, isSnippetPage }
  };
};

export const getPathParts = (pathname) => {
  const parts = pathname.split('/').filter((part) => part.length);
  return parts.reduce(
    (acc, currentPart, idx, array) =>
      array.length > 1 && idx > 0
        ? [...acc, `${acc.at(-1)}/${currentPart}`]
        : [`/${currentPart}`],
    []
  );
};

/**
 * @param path
 * @param batch
 * @returns Object || null
 */
export const getPageFromBatch = (path = '', batch = {}, isSSR = false) => {
  const matchedPath = getMatchedPathFromBatch(batch, path.replace(/\/$/, ''));
  const oldMatchedPath = getPathParts(path).reduce(
    (memo, curr) => batch[curr] || batch[`${curr}/`] || memo,
    null
  );

  const configData =
    (matchedPath && batch[matchedPath]) || resetPageSeo(oldMatchedPath);

  const isPageCorrect = getIsPageCorrect(path, configData);

  if (!isPageCorrect && !isSSR) {
    return {};
  }

  return configData || null;
};

const getFallbacks = ({ rootPath, fallbackPages }) =>
  fallbackPages?.[rootPath] || {};

const processPriorityConfigs = (cmsConfigs, fallbacks) => {
  const priorityPaths = process.env.REACT_APP_CMS_PRIORITY_FALLBACKS.split(',');
  return Object.keys(cmsConfigs).reduce((configs, path) => {
    if (priorityPaths.includes(path)) {
      return {
        ...configs,
        [path]: fallbacks[path]
      };
    }
    return {
      ...configs,
      [path]: cmsConfigs[path]
    };
  }, {});
};

export const mergeConfigs = ({ rootPath, FALLBACK_PAGES, configs }) => {
  const fallbacks = getFallbacks({
    rootPath,
    fallbackPages: FALLBACK_PAGES
  });
  if (
    !process.env.REACT_APP_IS_CMS_DISABLED ||
    process.env.REACT_APP_PRIORITY_CMS?.includes(rootPath)
  ) {
    const processedConfigs = process.env.REACT_APP_CMS_PRIORITY_FALLBACKS
      ? processPriorityConfigs(configs, fallbacks)
      : configs;

    return { ...fallbacks, ...processedConfigs };
  }
  return fallbacks;
};

export const getConfigBatch = async ({
  rootPath,
  FALLBACK_PAGES: fallbackPages,
  options
}) => {
  const fallbacks = getFallbacks({
    rootPath,
    fallbackPages
  });
  const originalFallbacks = aggregateNestedPages(fallbackPages);
  const { batch } = await getConfigBatchOriginal({
    rootPath,
    FALLBACK_PAGES: originalFallbacks,
    options
  });

  const newBatch = Object.entries(batch)?.reduce((acc, [path, config]) => {
    const optionalPath = 'general.pageConfig.routerParamsLength';
    const fallbackOptionalsParam = fallbacks[path];
    if (
      hasIn(fallbackOptionalsParam, optionalPath) &&
      !hasIn(config, optionalPath)
    ) {
      acc[path] = {
        path,
        ...config,
        general: {
          ...config.general,
          pageConfig: {
            ...config.general.pageConfig,
            routerParamsLength:
              fallbackOptionalsParam.general.pageConfig.routerParamsLength
          }
        }
      };
    } else {
      acc[path] = {
        path,
        ...config
      };
    }

    return acc;
  }, {});

  if (options?.isSSR) {
    return { batch: newBatch };
  }

  return newBatch;
};

async function getConfigBatchOriginal({ rootPath, FALLBACK_PAGES, options }) {
  const fallbacks = getFallbacks({
    rootPath,
    fallbackPages: FALLBACK_PAGES
  });
  if (
    !process.env.REACT_APP_IS_CMS_DISABLED ||
    process.env.REACT_APP_PRIORITY_CMS?.includes(rootPath)
  ) {
    try {
      const cmsConfigBatch = await getBatchConfigFromCMS({
        route: rootPath,
        options
      });

      return {
        batch: mergeConfigs({
          rootPath,
          FALLBACK_PAGES,
          configs: cmsConfigBatch
        })
      };
    } catch {
      return { batch: fallbacks };
    }
  } else {
    return { batch: fallbacks };
  }
}
