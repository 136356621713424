import { useCallback, useEffect, useState } from 'react';
import { useI18n } from '@namespace/i18n';
import { sportsbookActions, useSportsbookStore } from '../../store';

const { FETCH_POPULAR_BETS_EVENTS } = sportsbookActions;

// todo refactor: it has effects with suboptimal dependencies together with FETCH_POPULAR_BETS_EVENTS which has hack and causes endless loop.
export const useFetchPopularEvents = ({ sportId, lineKey, categoryId }) => {
  const { language: lang, timezone } = useI18n();
  const popularBetEvents = useSportsbookStore('popularBetEvents');
  const [isLoading, setIsLoading] = useState(false);

  const fetchEvents = useCallback(async () => {
    setIsLoading(true);

    await FETCH_POPULAR_BETS_EVENTS({
      sportId,
      lineKey,
      lang,
      categoryId,
      popularBetEvents
    });

    setIsLoading(false);
  }, [sportId, lineKey, lang, categoryId, popularBetEvents]);

  useEffect(() => {
    if (sportId) {
      fetchEvents();
    }
  }, [lang, sportId, categoryId, lineKey, timezone]);

  return {
    fetchEvents,
    isLoading
  };
};
