import { useEffect, useLayoutEffect } from 'react';
import { useLocation } from '@namespace/i18n';
import {
  waitForElement,
  scrollTo,
  SESSION_STORAGE_KEYS,
  setSessionStorageValue,
  getSessionStorageValue,
  removeLangFromPathname,
  window,
  document
} from '@namespace/helpers';
import { matchPath } from 'react-router-dom';
import { useGeneralRoutes, useOtherSettings } from '@namespace/cms';
import { useGetLobbyPageUrl } from '../useGetLobbyPageUrl';

// save prev casino page position before leaving the page and scroll to this position after coming back to casino page.
// used on pages with games lists: lobby / category page / provider games page.

export const useScrollToPrevGamesPosition = (
  gamesListId,
  isLogicEnabled,
  casinoType
) => {
  const { pathname } = useLocation();
  const { gamePreview, gameLauncher } = useGeneralRoutes();
  const casinoLobbyUrl = useGetLobbyPageUrl(casinoType);
  const { pathsAliases } = useOtherSettings();

  useEffect(() => {
    if (!isLogicEnabled) return;
    const prevPageScrollObj =
      getSessionStorageValue(
        SESSION_STORAGE_KEYS.CASINO_PREV_PAGE_SCROLL_TOP
      ) ?? {};

    const prevPathname = getSessionStorageValue(
      SESSION_STORAGE_KEYS.PREV_PATHNAME,
      ''
    );
    const prevPageTop =
      (prevPageScrollObj.path === pathname && prevPageScrollObj.position) || 0;
    const isPrevGamePath = [
      `/:service${gamePreview}/:provider/:gameCode/`,
      `:service${gameLauncher}/:provider/:gameCode`
    ].some((path) => matchPath({ path, end: true }, prevPathname)); // games launched on our side

    if (
      gamesListId &&
      prevPageTop &&
      (prevPathname === pathname || isPrevGamePath)
    ) {
      waitForElement(`#${gamesListId}`)
        .then(() => {
          scrollTo({
            top: prevPageTop,
            behavior: 'auto',
            timeout: 200,
            isSkipSmoothForLongDistance: true
          });
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setSessionStorageValue(
            SESSION_STORAGE_KEYS.CASINO_PREV_PAGE_SCROLL_TOP,
            {
              path: pathname,
              position: 0
            }
          );
        });
    }
  }, [gamesListId, isLogicEnabled, pathname, gameLauncher, gamePreview]);

  useLayoutEffect(() => {
    return () => {
      const top = window.pageYOffset || document.documentElement.scrollTop;

      if (top && isLogicEnabled && gamesListId) {
        setSessionStorageValue(
          SESSION_STORAGE_KEYS.CASINO_PREV_PAGE_SCROLL_TOP,
          {
            path: pathname,
            position: top
          }
        );
      }
    };
  }, [isLogicEnabled, gamesListId]);

  // reset prev scroll position if leaving casino
  useEffect(() => {
    return () => {
      const formattedPathname = removeLangFromPathname(
        window.location.pathname
      );
      const isNextCasinoPage =
        formattedPathname.includes(casinoLobbyUrl) ||
        pathsAliases?.[formattedPathname] === casinoLobbyUrl;

      if (!isNextCasinoPage)
        setSessionStorageValue(
          SESSION_STORAGE_KEYS.CASINO_PREV_PAGE_SCROLL_TOP,
          {
            path: pathname,
            position: 0
          }
        );
    };
  }, []);
};
