import { forwardRef } from 'react';
import { Box } from '@alf/uikit';
import { Tournaments } from '../Tournaments';
import styles from './index.module.css';

export const TournamentsDrawer = forwardRef((props, ref) => {
  const {
    serviceId,
    tournamentRoute,
    activeTournamentId,
    categoryId,
    sportSlug
  } = props;

  return (
    <Box className={styles.wrapper}>
      <Tournaments
        ref={ref}
        serviceId={serviceId}
        tournamentRoute={tournamentRoute}
        activeTournamentId={activeTournamentId}
        categoryId={categoryId}
        sportSlug={sportSlug}
      />
    </Box>
  );
});
