import { useState, useEffect, useCallback } from 'react';
import { CommonLoader } from '@namespace/common';
import { SelectField } from '@namespace/forms';
import { getCashdesks } from '../../../api/getCashdesks';

export const CashDeskField = ({
  walletHash,
  amount,
  language,
  cityId,
  isLoading,
  setValue,
  placeholder,
  maxCashDesk,
  ...rest
}) => {
  const [cashdesks, setCashdesks] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const loadCashdesks = useCallback(() => {
    setIsFetching(true);
    getCashdesks({
      walletHash,
      language,
      cityId,
      amount
    })
      .then((data) => {
        const cd = data.filter(
          ({ id }) => id > 0 && (maxCashDesk ? id < maxCashDesk : true)
        );

        setCashdesks(
          cd.map(({ id, address }) => ({
            value: String(id),
            name: address
          }))
        );
      })
      .finally(() => setIsFetching(false));
  }, [amount, cityId, language, maxCashDesk, walletHash]);

  useEffect(() => {
    if (!cityId && cashdesks.length) {
      setCashdesks([]);
    }
  }, [cashdesks.length, cityId]);

  useEffect(() => {
    loadCashdesks();
  }, [loadCashdesks]);

  if (isLoading || isFetching) {
    return <CommonLoader />;
  }

  return (
    <SelectField options={cashdesks} placeholder={placeholder} {...rest} />
  );
};
