import { createContext, useReducer } from 'react';
import { baseReducer, useActions } from '@namespace/helpers-store';
import { types, initialState, reducer as handlers } from '../reducer';
import actionCreators from '../actions';

export const PredictionsContext = createContext();

export const PredictionsProvider = ({ children, customHandlers = {} }) => {
  const reducer = baseReducer(handlers, customHandlers);

  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useActions(types, dispatch, actionCreators);

  return (
    <PredictionsContext.Provider value={[state, actions]}>
      {children}
    </PredictionsContext.Provider>
  );
};
