import clsx from 'clsx';
import { Box } from '@alf/uikit';
import styles from './index.module.css';

export const WideGameDots = ({ dotsNumber, activeIndex }) => {
  const indexesArray = [...Array(dotsNumber).keys()];

  return (
    <Box className={styles.dotsMenu}>
      {indexesArray.map((index) => (
        <Box key={index} className={styles.dotWrapper}>
          <Box
            className={clsx([
              styles.dot,
              index === activeIndex && styles.dotActive
            ])}
          />
        </Box>
      ))}
    </Box>
  );
};
