export const SET_CONFIG = 'SET_CONFIG';
export const SET_SERVICES_LIMITS = 'SET_SERVICES_LIMITS';

export const setConfig = (paymentSystems, countryId, userId) => ({
  type: SET_CONFIG,
  payload: { paymentSystems, countryId, userId }
});

export const setServicesLimits = (limits) => ({
  type: SET_SERVICES_LIMITS,
  payload: { limits }
});
