import { useCallback, useContext, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { DeviceContext } from '@namespace/device';
import { document } from '@namespace/helpers';
import { Icon } from '@namespace/icons2';
import Box from '../../../../../../../Box';
import Input from '../../../../../../../Input';
import styles from './index.module.css';

export const SearchRow = ({
  searchValue,
  onSearchValueChange,
  placeholder,
  size,
  inputRef: externalRef,
  contentRef,
  disabled,
  className
}) => {
  const internalRef = useRef();
  const inputRef = externalRef ?? internalRef;
  const classes = clsx(styles.root, styles[size], className);
  const { isDesktopDevice } = useContext(DeviceContext);
  const focusInput = useCallback(() => {
    if (isDesktopDevice) {
      inputRef.current?.focus();
    }
  }, [inputRef, isDesktopDevice]);

  useEffect(() => {
    focusInput();
  }, [focusInput]);

  useEffect(() => {
    if (contentRef == null) {
      return;
    }

    const listener = (e) => {
      if (!contentRef.current) {
        return;
      }

      if (contentRef.current.contains(e.target)) {
        focusInput();
      }
    };

    document.addEventListener('click', listener);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('click', listener);
    };
  }, [contentRef, focusInput]);

  return (
    <Box padding="s" gap="s" align="center" className={classes}>
      <Icon
        name="icons/general/action/search"
        size={size === 'xs' ? 's' : size}
        color="accent_color_1"
        className={styles.icon}
      />
      <Input
        type="text"
        inputRef={inputRef}
        value={searchValue}
        onChange={onSearchValueChange}
        placeholder={placeholder}
        maxLength={50}
        min={0}
        size={size}
        disabled={disabled}
        className={styles.input}
      />
    </Box>
  );
};
