import { throttle } from 'lodash';

/*
  Util for merge few events in one array, 
  and process r them together in reducer
*/

const throttleEvents = (callback, delay = 2000) => {
  let events = [];

  const batch = throttle(
    (action) => {
      callback(action);
      events = [];
    },
    delay,
    { leading: false }
  );

  return (event) => {
    events.push(event);
    batch(events);
  };
};

export default throttleEvents;
