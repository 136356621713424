import { Box, Button, AText } from '@alf/uikit';
import { LocalizedLink } from '@namespace/i18n';

import styles from './index.module.css';

export const MobileMessage = ({
  linkText,
  link,
  primaryText: primaryTextProp,
  tailText,
  submitText,
  onClose = () => {}
}) => {
  const primary = `${primaryTextProp} ${linkText}`;
  const secondary = tailText ? ` ${tailText}` : '';
  const fullText = `${primary}${secondary}`;

  return (
    <Box align="center" justify="center">
      <Box
        className={styles.container}
        direction="column"
        align="center"
        justify="center"
      >
        <AText
          color="txt_color_3"
          size="l"
          align="center"
          className={styles.message}
        >
          {fullText}
        </AText>

        <Box direction="column" align="center" justify="center">
          <Button
            onClick={onClose}
            component={LocalizedLink}
            to={link}
            size="s"
            intent="secondary"
            actionType="color3"
            className={styles.button}
          >
            {submitText}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MobileMessage;
