import { useState, useEffect, useContext } from 'react';
import { useInterval } from '@namespace/helpers';
import { SiteSettingsContext } from '@namespace/cms';
import { NOTIFICATION_TYPES, useNotifications } from '@namespace/notifications';
import { UserContext } from '@namespace/user';
import { useI18n } from '@namespace/i18n';
import getNextTime from './utils/getNextTime';
import isAfterNextTime from './utils/isAfterNextTime';
import getHours from './utils/getHours';
import plusMinutes from './utils/plusMinutes';

const useSessionDurationNotice = () => {
  const { t } = useI18n();
  const { customNotification } = useNotifications();

  const [user = {}] = useContext(UserContext);
  const { authTs } = user;
  const [settings] = useContext(SiteSettingsContext);
  const { responsibleGambling = {} } = settings || {};
  const { sessionNoticeDuration = null } = responsibleGambling;
  const [nextTime, setNextTime] = useState(null);

  useEffect(() => {
    if (authTs && sessionNoticeDuration) {
      const dateTime = getNextTime(authTs, sessionNoticeDuration);
      setNextTime(dateTime);
    }
  }, [authTs, sessionNoticeDuration]);

  useInterval(
    () => {
      if (nextTime && isAfterNextTime(nextTime)) {
        const hours = getHours(authTs);
        const message = t('responsibleGambling.sessionDurationNotice', [hours]);

        customNotification({
          notificationType: NOTIFICATION_TYPES.SESSION_DURATION_NOTICE,
          timeout: 10000,
          message
        });
        setNextTime(plusMinutes(nextTime, sessionNoticeDuration));
      }
    },
    nextTime ? 1000 : null
  );
};

export default useSessionDurationNotice;
