import { useFormContext } from 'react-hook-form';
import { getFormError } from '@namespace/forms';
import { useI18n } from '@namespace/i18n';
import CashdeskField from '../../components/CashdeskField';

const CashDesk = ({
  name,
  rules = {},
  cashdesk,
  walletHash,
  amount,
  label,
  placeholder,
  ...props
}) => {
  const { t, language } = useI18n();
  const methods = useFormContext();
  const {
    watch,
    setValue,
    formState: { errors }
  } = methods;
  const city = watch('city');

  return (
    <CashdeskField
      walletHash={walletHash}
      language={language}
      amount={amount}
      setValue={(value) => setValue(name, value)}
      cityId={city ?? ''}
      name={name}
      rules={rules}
      error={getFormError(name, errors, t)}
      label={t(label)}
      placeholder={t(placeholder)}
      {...props}
    />
  );
};

export default CashDesk;
