import { useContext, useEffect } from 'react';
import { useNavigate } from '@namespace/router';
import { NativeAppContext } from '@namespace/native-app/src/store/context';
import { getALinkFromTarget, getInternalLinkHref } from './utils';

// We strongly assume containerRef.current is never changed during re-render.
export const useInternalLinkNavigation = (containerRef) => {
  const [
    {
      isNativeApp,
      nativeAppConfig: { delegateRouting }
    }
  ] = useContext(NativeAppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isNativeApp && !delegateRouting) {
      return;
    }

    const container = containerRef?.current;

    if (!container) {
      return;
    }

    const onLinkClick = (e) => {
      const a = getALinkFromTarget(e.target, container);
      const href = getInternalLinkHref(a);
      if (href == null) {
        return;
      }

      e.preventDefault();
      navigate(href);
    };

    container.addEventListener('click', onLinkClick);

    // eslint-disable-next-line consistent-return
    return () => {
      container.removeEventListener('click', onLinkClick);
    };
  }, [containerRef, isNativeApp, navigate]);
};
