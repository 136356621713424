import { useI18n } from '@namespace/i18n';
import { useCallback, useContext, useEffect, useState } from 'react';
import { DeviceContext, FORM_FACTORS_MAP } from '@namespace/device';
import { useNotifications } from '@namespace/notifications';
import { uniqBy } from 'lodash';
import { getRiskFreeList, getRiskFreeTotalPages } from '../../api/riskFree';

const useRiskFreeList = (filter) => {
  const { t } = useI18n();
  const { pageSize, currentPage } = filter;
  const [bonuses, setBonuses] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const { errorNotification } = useNotifications();
  const { isMobile, isTablet } = useContext(DeviceContext);
  const platform =
    isMobile || isTablet ? FORM_FACTORS_MAP.MOBILE : FORM_FACTORS_MAP.DESKTOP;
  const loadBonusesList = useCallback(async () => {
    setIsFetching(true);
    try {
      const { errorCode, response } = await getRiskFreeList(filter, platform);
      if (errorCode === 0) {
        setBonuses((prevState) => {
          const bonusList =
            currentPage === 1 ? response : [...prevState, ...response];
          return uniqBy(bonusList, 'bonusId');
        });
      }
    } catch {
      errorNotification(t(`accountingMessages.accounting_error_6`));
    }
    setIsFetching(false);
  }, [filter, platform]);

  useEffect(() => {
    loadBonusesList(filter);
  }, [loadBonusesList, filter]);

  useEffect(() => {
    (async () => {
      const totalBonuses = await getRiskFreeTotalPages(filter, platform);
      setTotalPages(
        totalBonuses <= pageSize ? 1 : Math.ceil(totalBonuses / pageSize)
      );
    })();
  }, [filter, t, pageSize, platform]);

  return {
    bonuses,
    totalPages,
    isFetching,
    loadBonusesList
  };
};

export default useRiskFreeList;
