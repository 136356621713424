import { useContext } from 'react';
import { PageContext } from '@namespace/cms';
import { TaxWithCurrency } from './TaxWithCurrency';
import { UATax } from './UATax';
import { OUGTax } from './OUGTax';
import { Wrapper } from './Wrapper';

const taxComponentsMap = {
  OUGTax,
  UATax,
  // TODO: make sure we still need keep this kind of tax component
  CurrencyTax: TaxWithCurrency
};

export const Tax = ({ withAccordion = false, ...props }) => {
  const { page = {} } = useContext(PageContext);
  const { pageConfig = {} } = page;
  const { taxComponent = '' } = pageConfig;
  const TaxComponent = taxComponentsMap[taxComponent];

  return TaxComponent ? (
    <Wrapper withAccordion={withAccordion}>
      <TaxComponent {...props} />
    </Wrapper>
  ) : null;
};
