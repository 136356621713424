import { useContext, useCallback, useEffect } from 'react';
import { AText, Box, Button } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { USER_ROLES, UserContext } from '@namespace/user';
import { DeviceContext } from '@namespace/device';
import { removeLocalStorageItem } from '@namespace/helpers';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { useGeneralRoutes, PageContext } from '@namespace/cms';
import Predictions from '../../common/Predictions';
import PredictionsAccordion from '../../common/PredictionsAccordion';
import PredictionsMenu from '../../common/PredictionsMenu';
import useGetSavePrediction from '../../hooks/useGetSavePrediction';
import {
  PREDICTIONS_STEPS_IDS,
  NEXT_STEPS_IDS,
  PREDICTIONS_STATUS
} from '../../constants';
import { PredictionsContext } from '../../store/context';
import {
  useGetActiveStep,
  useIsActiveStepDisabled,
  useIsFinalStepFilled
} from '../../store/selectors';
import styles from './index.module.css';

const { TOP_OF_THIRD, GROUPS, FINAL } = PREDICTIONS_STEPS_IDS;
const { COMPLETED, DEFAULT, FILLED, FAIL_UNSAVED } = PREDICTIONS_STATUS;

const PredictionsBlock = ({ id: componentId }) => {
  const { page } = useContext(PageContext);
  const { components = {} } = page;
  const { config = {} } = components[componentId];
  const {
    howItWorksSnippetId,
    predictionsResultsURL,
    mobileAppLoginURL
  } = config;

  const { t, f } = useI18n();
  const { isDesktop } = useContext(DeviceContext);
  const { push, replace } = useHistory();
  const [state, actions] = useContext(PredictionsContext);
  const {
    CHANGE_TEAMS,
    SET_ACTIVE_STEP,
    EDIT_STEP,
    FETCH_USER_PREDICTION
  } = actions;
  const {
    steps,
    activeStep,
    editingInProgress,
    isAllowEditing,
    endDateOfEditing,
    isPredictionsLoaded,
    localStatus
  } = state;
  const [user] = useContext(UserContext);
  const { role, id: userId } = user;
  const { predictionsSuccess, login } = useGeneralRoutes();
  const { bracket: groups = [], teams } = useGetActiveStep();
  const isDisabledStep = useIsActiveStepDisabled();
  const isFinalFilled = useIsFinalStepFilled();
  const SAVE_PREDICTION = useGetSavePrediction();

  useEffect(() => {
    if (
      !editingInProgress &&
      localStatus !== DEFAULT &&
      localStatus !== FILLED &&
      localStatus !== FAIL_UNSAVED
    ) {
      replace(predictionsResultsURL || predictionsSuccess);
    }
  }, [
    editingInProgress,
    localStatus,
    predictionsSuccess,
    replace,
    predictionsResultsURL
  ]);

  const handleChange = (params, bracketTeams) => {
    const teamsType = params.length ? params : bracketTeams;

    CHANGE_TEAMS({
      teamValues: teamsType.map((id, index) => {
        const value =
          activeStep === TOP_OF_THIRD || activeStep === GROUPS
            ? { index: params.length ? index + 1 : 0 }
            : { win: params[0] === id };

        return { id, value };
      })
    });
  };

  const handleNextStep = useCallback(() => {
    if (NEXT_STEPS_IDS[activeStep]) {
      logAction(LOG_ACTIONS.EURO_PREDICTION_FORM, {
        StepName: activeStep
      });
      SET_ACTIVE_STEP(NEXT_STEPS_IDS[activeStep]);
    }
  }, [SET_ACTIVE_STEP, activeStep]);

  const handleEdit = useCallback(() => EDIT_STEP(activeStep), [
    activeStep,
    EDIT_STEP
  ]);

  const handleReFill = useCallback(() => EDIT_STEP(GROUPS, true), [EDIT_STEP]);

  const handleToResults = useCallback(async () => {
    if (role === USER_ROLES.GUEST) {
      push(login);
      return;
    }
    const res = await SAVE_PREDICTION();
    if (res?.ok === 'true') {
      FETCH_USER_PREDICTION(userId);
      push(predictionsResultsURL || predictionsSuccess);
      removeLocalStorageItem('predictions');
    }
  }, [
    push,
    predictionsSuccess,
    SAVE_PREDICTION,
    role,
    login,
    FETCH_USER_PREDICTION,
    userId,
    predictionsResultsURL
  ]);

  if (!isAllowEditing) {
    return (
      <Box className={styles.predictionsBlock}>
        <Box
          align="center"
          justify="center"
          direction="column"
          className={`${styles.predictionsBlockCentralize} ${styles.accordionSingle}`}
        >
          <PredictionsAccordion
            snippetId={howItWorksSnippetId}
            title="HowItWorksTop"
            theme="gray"
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box wrap={true} className={styles.predictionsBlock}>
      {!isPredictionsLoaded && isDisabledStep && !editingInProgress && (
        <Box
          align="center"
          justify="center"
          className={styles.predictionsBlockEditWrapper}
        >
          <Box
            align="center"
            justify="between"
            className={styles.predictionsBlockEdit}
          >
            <AText
              breed="R"
              size="l"
              color="txt_color_4"
              className={styles.predictionsBlockEditText}
            >
              {t('predictionsBlock.edit.text')}
            </AText>
            <Button
              className={styles.predictionsBlockNextButton}
              onClick={handleEdit}
              actionType="color1"
              intent="primary"
              size="l"
            >
              {t('predictionsBlock.buttonEdit')}
            </Button>
          </Box>
        </Box>
      )}
      <Box
        align="center"
        justify="center"
        className={styles.predictionsBlockMenu}
      >
        <PredictionsMenu className={styles.predictionsBlockList} />
      </Box>
      {groups.map((value) => (
        <Predictions
          key={value.title}
          type={activeStep}
          group={value}
          teams={teams}
          isDisabledStep={isDisabledStep}
          onChange={(params) => handleChange(params, value.teams)}
        />
      ))}
      <Box
        align="center"
        justify="center"
        direction="column"
        className={`${styles.predictionsBlockCentralize} ${styles.doubleButtons}`}
      >
        {activeStep === FINAL ? (
          <Box className={styles.buttonsWrapper}>
            <Box
              data-role="predictions-save-wrapper"
              onClick={() => logAction(LOG_ACTIONS.EURO_PREDICTION_SAVED)}
            >
              {role === USER_ROLES.GUEST && mobileAppLoginURL ? (
                <Button
                  className={styles.predictionsBlockNextButton}
                  disabled={!isFinalFilled || isDisabledStep}
                  component="a"
                  href={mobileAppLoginURL}
                  actionType="color1"
                  intent="primary"
                  size="l"
                >
                  {t('predictionsBlock.buttonSave')}
                </Button>
              ) : (
                <Button
                  data-role="predictions-result-button"
                  className={styles.predictionsBlockNextButton}
                  disabled={!isFinalFilled || isDisabledStep}
                  onClick={handleToResults}
                  actionType="color1"
                  intent="primary"
                  size="l"
                >
                  {t('predictionsBlock.buttonSave')}
                </Button>
              )}
            </Box>
            <Button
              data-role="predictions-refill-button"
              className={styles.predictionsBlockRefillButton}
              onClick={handleReFill}
              actionType="color3"
              intent="secondary"
              size="l"
            >
              {t('predictionsBlock.buttonRefill')}
            </Button>
          </Box>
        ) : (
          <Button
            className={styles.predictionsBlockNextButton}
            disabled={
              !steps[NEXT_STEPS_IDS[activeStep]]?.isFilled || isDisabledStep
            }
            onClick={handleNextStep}
            actionType="color1"
            intent="primary"
            size="l"
          >
            {t('predictionsBlock.buttonNext')}
          </Button>
        )}
      </Box>
      {activeStep === FINAL &&
        !isDesktop &&
        endDateOfEditing &&
        localStatus !== COMPLETED && (
          <Box
            align="center"
            justify="center"
            direction="column"
            className={styles.predictionsBlockCentralize}
          >
            <AText
              breed="BU"
              size="m"
              color="txt_color_7"
              align="center"
              className={styles.expireDate}
            >
              {t('predictionsBlock.message.expireDate', [
                f
                  .getDateTime({ date: endDateOfEditing })
                  .toFormat('dd.MM.yyyy HH:mm')
              ])}
            </AText>
            <AText
              breed="BU"
              size="m"
              color="txt_color_7"
              align="center"
              className={styles.betCondition}
            >
              {t('predictionsBlock.message.betCondition')}
            </AText>
          </Box>
        )}
      <Box
        align="center"
        justify="center"
        direction="column"
        className={`${styles.predictionsBlockCentralize} ${styles.accordionLast}`}
      >
        <PredictionsAccordion
          snippetId={howItWorksSnippetId}
          title="HowItWorksBottom"
          theme="gray"
        />
      </Box>
    </Box>
  );
};

export default PredictionsBlock;
