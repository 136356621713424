import { AText, EmptyState } from '@alf/uikit';
import { useConfig, useGeneralRoutes } from '@namespace/cms';
import { DeviceContext } from '@namespace/device';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { Image } from '@namespace/icons2';
import { isEmpty } from 'lodash';
import { useContext, useEffect } from 'react';
import BasePreMatchEvents from '../../common/BasePreMatchEvents';
import SportbookSkelet from '../../common/SportbookSkelet';
import { SPORTS_LINE_TYPES } from '../../constants';
import { useCustomFilterGroups } from '../../hooks/useCustomFilterGroups';
import useEventsTimeFilter from '../../hooks/useEventsTimeFilter';
import { useAdvFilterStore } from '../../storeAdvFilter';
import { AdvFilter, useFetchSports } from '../AdvancedFilter';
import { useLogFilterLoad } from './useLogFilterLoad';
import styles from './index.module.css';

export const CustomFilterEvents = ({ id }) => {
  useFetchSports();
  useLogFilterLoad();
  const { t } = useI18n();
  const { preMatchSport } = useGeneralRoutes();
  const { isDesktop } = useContext(DeviceContext);
  const advFilter = useAdvFilterStore('advFilter');
  const advFilterSportsById = useAdvFilterStore('advFilterSportsById');
  const isAdvFilterOpened = useAdvFilterStore('isAdvFilterOpened');
  const { push } = useHistory();
  const { config } = useConfig(id);
  const { filters } = config;
  const {
    isShowAll,
    isShowStartingSoon,
    isShowNextMorning,
    default: defaultFilter,
    ...filterConfig
  } = filters;
  const { filter } = useEventsTimeFilter({
    initialFilter: {
      [defaultFilter]: defaultFilter
    },
    filterConfig
  });
  const {
    groupsData: customFiltersGroupsData,
    isGroupsLoaded
  } = useCustomFilterGroups({ filter });

  const content =
    !isEmpty(advFilter) && !customFiltersGroupsData?.length ? (
      <EmptyState
        image={<Image name="general/looking_for" />}
        text={
          <div className={styles.emptyStateText}>
            <AText color="line_txt_1" size="m" breed="BU">
              {t('sportsBook.sportsMenu.advFilter.messageNoEvents')}
            </AText>
            <LocalizedLink to={preMatchSport}>
              <AText
                color="txt_link"
                size="m"
                breed="BU"
                className={styles.link}
              >
                {t('stepper.homeLink')}
              </AText>
            </LocalizedLink>
          </div>
        }
      />
    ) : (
      customFiltersGroupsData.map(
        ({
          lineKey,
          sportId,
          sportName,
          categoryName,
          categoryId,
          tournamentIds
        }) => (
          <BasePreMatchEvents
            key={lineKey}
            lineType={SPORTS_LINE_TYPES.CUSTOM_FILTER}
            id={id}
            lineKey={lineKey}
            activeSport={{ sportId, sportName }}
            categories={categoryId ? [{ categoryId, categoryName }] : []}
            tournamentsIds={tournamentIds}
            isCustomFilterEvents={true}
            className={styles.lineContainer}
          />
        )
      )
    );

  useEffect(() => {
    if (
      advFilter != null &&
      isEmpty(advFilter) &&
      !isEmpty(advFilterSportsById) &&
      !isAdvFilterOpened
    ) {
      push(preMatchSport);
    }
  }, [advFilter, advFilterSportsById, isAdvFilterOpened, preMatchSport, push]);

  return (
    <div className={styles.container}>
      {!isDesktop &&
        isGroupsLoaded &&
        isAdvFilterOpened &&
        advFilter != null && <AdvFilter />}
      {isGroupsLoaded ? (
        content
      ) : (
        <>
          <div className={styles.skelet}>
            <SportbookSkelet />
          </div>
          <div className={styles.skelet}>
            <SportbookSkelet />
          </div>
        </>
      )}
    </div>
  );
};
