export const types = {
  RUN: 'RUN',
  SET_STEPS: 'SET_STEPS',
  SET_ONBOARDING_CONFIG: 'SET_ONBOARDING_CONFIG',
  NEXT: 'NEXT',
  PREV: 'PREV',
  GO_TO: 'GO_TO',
  SET_IS_LOADING: 'SET_IS_LOADING',
  NEXT_TOUR: 'NEXT_TOUR',
  PREV_TOUR: 'PREV_TOUR',
  GO_TO_TOUR: 'GO_TO_TOUR',
  GO_TO_TOUR_BY_ID: 'GO_TO_TOUR_BY_ID',

  SET_COMPLETED_TOURS: 'SET_COMPLETED_TOURS',
  SET_INTERRUPTED: 'SET_INTERRUPTED',

  SET_LOADED_SNAPSHOTS: 'SET_LOADED_SNAPSHOTS'
};
