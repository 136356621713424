import { createRoot, hydrateRoot } from 'react-dom/client';
import { document, stores, window } from '../../constants';

// init stores with data loaded from ssr since these stores are created before data is loaded
const initZustandStoresFromSsr = () => {
  for (const [storeName, useBoundStore] of Object.entries(stores)) {
    const state = window.initialStoresState?.[storeName];
    if (state) {
      useBoundStore.setState(state, true);
    }
  }
};

const container = document.getElementById('root');

const loadApp = (AppWrapper) => {
  const startReact = () => {
    const root = createRoot(container);
    root.render(<AppWrapper initialPageConfig={window.pagesBatch?.configs} />);
  };

  const startHydrateReact = () => {
    initZustandStoresFromSsr();

    hydrateRoot(
      container,
      <AppWrapper initialPageConfig={window.pagesBatch?.configs || {}} />
    );
  };

  if (
    window.IS_SSR &&
    process.env.REACT_APP_SSR_HYDRATION_DISABLED !== 'true'
  ) {
    const scriptElement = document.getElementById('ssr-page-data');

    if (window.ssrDataLoaded && window.siteSettings) {
      startHydrateReact();
    } else if (!window.ssrDataLoaded && scriptElement) {
      scriptElement.onload = () => {
        startHydrateReact();
      };
      scriptElement.onerror = () => {
        startReact();
      };
    } else {
      startReact();
    }
  } else {
    startReact();
  }
};

export default loadApp;
