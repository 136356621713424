import { useContext, useEffect, useCallback, useState, useMemo } from 'react';
import { isUndefined } from 'lodash';
// eslint-disable-next-line @alf/loop-deps
import {
  getBonusSystemActiveDepositBonuses,
  getFundsBonusesList,
  getFreeSpinList,
  freeSpinStates,
  freeSpinStatesMap,
  fundsStates,
  fundsStatesMap,
  BONUS_SLIDER_BONUS_TYPES
} from '@namespace/bonuses'; // FIX ME
import { DeviceContext, FORM_FACTORS_MAP } from '@namespace/device';

const useDepositBonuses = (
  isPreWagerBonusesEnabled,
  isPreWagerDepositFundsEnabled
) => {
  const [bonusSystemBonuses, setBonusSystemBonuses] = useState([]);
  const [freeSpinsBonuses, setFreeSpinsBonuses] = useState([]);
  const [fundsBonuses, setFundsBonuses] = useState([]);
  const { isDesktop } = useContext(DeviceContext);
  const platform = isDesktop
    ? FORM_FACTORS_MAP.DESKTOP
    : FORM_FACTORS_MAP.MOBILE;

  const fetchBonuses = useCallback(
    async ({ disableCRMBonusesLoad = false } = {}) => {
      const fetchBonusSystemBonuses = async () => {
        const results = await getBonusSystemActiveDepositBonuses();
        const BSBonuses = results?.data?.campaigns.map((bonus) => ({
          ...bonus,
          type: BONUS_SLIDER_BONUS_TYPES.BONUS_SYSTEM
        }));
        if (BSBonuses) {
          setBonusSystemBonuses(BSBonuses);
        }
        return BSBonuses;
      };
      const fetchFreeSpinBonuses = async () => {
        const { response } = await getFreeSpinList(
          {
            stateList: freeSpinStatesMap.all,
            pageSize: 10,
            currentPage: 1
          },
          platform
        );
        setFreeSpinsBonuses(
          response
            .filter(({ state }) => state === freeSpinStates.FREESPIN_POTENCIAL)
            .map((bonus) => ({
              ...bonus,
              type: BONUS_SLIDER_BONUS_TYPES.FREE_SPIN
            }))
        );
      };
      const fetchFundsBonuses = async () => {
        const { response } = await getFundsBonusesList(
          {
            stateList: fundsStatesMap.all,
            pageSize: 10,
            currentPage: 1
          },
          platform
        );
        setFundsBonuses(
          response
            .filter(({ state }) => state === fundsStates.BONUS_POTENCIAL)
            .map((bonus) => ({
              ...bonus,
              type: bonus?.isCashback
                ? BONUS_SLIDER_BONUS_TYPES.CASHBACK
                : BONUS_SLIDER_BONUS_TYPES.FUNDS
            }))
        );
      };

      if (isPreWagerBonusesEnabled) {
        const bonuses = await fetchBonusSystemBonuses();

        if (bonuses?.length && !disableCRMBonusesLoad) {
          if (isPreWagerDepositFundsEnabled) {
            fetchFundsBonuses();
          }
          fetchFreeSpinBonuses();
        }
      }
    },
    [isPreWagerBonusesEnabled, platform]
  );

  useEffect(() => {
    fetchBonuses();
  }, []);

  const activeBonusSystemBonusId = useMemo(() => {
    const filteredBonuses = bonusSystemBonuses
      ?.filter(
        ({ isSelectedDeposit, bonusId }) =>
          isSelectedDeposit && !isUndefined(bonusId)
      )
      .map(({ bonusId }) => bonusId);

    return filteredBonuses[0];
  }, [bonusSystemBonuses]);

  return {
    // all possible bonuses list, used for almost all partners (except for casinoua)
    bonuses: [
      {
        bonusName: 'web.personaloffice.deposit.nobonus',
        type: BONUS_SLIDER_BONUS_TYPES.DEFAULT
      },
      ...bonusSystemBonuses,
      ...fundsBonuses,
      ...freeSpinsBonuses
    ],
    // selectable / auto selectable bonuses. needed for casinoua partner for now
    bonusesByType: {
      selectableBonuses: [
        {
          bonusName: 'web.personaloffice.deposit.nobonus',
          type: BONUS_SLIDER_BONUS_TYPES.DEFAULT
        },
        ...bonusSystemBonuses
      ],
      autoSelectableBonuses: { freeSpinsBonuses }
    },
    isHasBonusSystemBonuses: bonusSystemBonuses?.length > 0,
    activeBonusSystemBonusId,
    fetchBonuses
  };
};

export default useDepositBonuses;
