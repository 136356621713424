import { memo } from 'react';
import { useGetThemeIcons } from '@namespace/themes';
import icons from '../icons';

const IconSVGComponent = ({ name, dataRole, ...props }) => {
  const iconsSet = useGetThemeIcons();
  const mergedIcons = { ...icons, ...iconsSet };
  const Component = mergedIcons[name];

  if (!Component) {
    return null;
  }

  return <Component data-role={dataRole && `icon-${dataRole}`} {...props} />;
};

export default memo(IconSVGComponent);
