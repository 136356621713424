import { SERVICE_IDS, SERVICE_IDS_TYPES_MAP } from '@namespace/bets';
import useLoadBetSlip from '../../hooks/useLoadBetSlip';

const BetSlipLoadWrapper = () => {
  useLoadBetSlip({ serviceId: SERVICE_IDS[SERVICE_IDS_TYPES_MAP.PRE_MATCH] });

  return null;
};

export default BetSlipLoadWrapper;
