/* eslint-disable import/no-unresolved  */
import Bank from '../../../images/general/bank.svg?url';
import BankDark from '../../../images/general/bank_dark.svg?url';
import BanksLogoList from '../../../images/general/banks_logo_list.svg?url';
import Bill from '../../../images/general/bill.svg?url';
import BillDark from '../../../images/general/billl_dark.svg?url';
import Bonus from '../../../images/general/bonus.svg?url';
import BonusDark from '../../../images/general/bonus_dark.svg?url';
import BonusHunter from '../../../images/general/bonus_hunter.svg?url';
import BonusHunterDark from '../../../images/general/bonus_hunter_dark.svg?url';
import BonusUnavailable from '../../../images/general/bonus_unavailable.svg?url';
import BonusUnavailableDark from '../../../images/general/bonus_unavailable_dark.svg?url';
import Camera from '../../../images/general/camera.svg?url';
import CameraDark from '../../../images/general/camera_dark.svg?url';
import Card2SidesForFilling from '../../../images/general/card_2sides_for_fillling.svg?url';
import CardFront from '../../../images/general/card_front.svg?url';
import CardImage from '../../../images/general/card_image.svg?url';
import CardImageDark from '../../../images/general/card_imagel_dark.svg?url';
import Coin from '../../../images/general/coin.svg?url';
import Confirm from '../../../images/general/confirm.svg?url';
import ConfirmDark from '../../../images/general/confirm_dark.svg?url';
import DefaultBonusImage from '../../../images/general/default_bonus_image.png';
import DiiaLogo from '../../../images/general/diia_logo.svg?url';
import Email from '../../../images/general/email.svg?url';
import EmailDark from '../../../images/general/email_dark.svg?url';
import EmailIl from '../../../images/general/email_il.svg?url';
import Error from '../../../images/general/error.svg?url';
import ErrorDark from '../../../images/general/error_dark.svg?url';
import FixingDark from '../../../images/general/fixing_dark.svg?url';
import IllBonus from '../../../images/general/ill_bonus.svg?url';
import InClub from '../../../images/general/in_Club.svg?url';
import IPhone from '../../../images/general/iphone.svg?url';
import IPhoneDark from '../../../images/general/iphone_dark.svg?url';
import LookingFor from '../../../images/general/looking-for.svg?url';
import LookingForDark from '../../../images/general/looking-for_dark.svg?url';
import Money from '../../../images/general/money.svg?url';
import MoneyDark from '../../../images/general/moneyl_dark.svg?url';
import NoData from '../../../images/general/no_data.svg?url';
import NoDataDark from '../../../images/general/no_data_dark.svg?url';
import NoWallet from '../../../images/general/no_wallet.svg?url';
import Notification from '../../../images/general/notification.svg?url';
import NotificationDark from '../../../images/general/notification_dark.svg?url';
import OnlyPC from '../../../images/general/onlyPC.svg?url';
import OnlyPCDark from '../../../images/general/onlyPC_dark.svg?url';
import Password from '../../../images/general/password.svg?url';
import PasswordDark from '../../../images/general/password_dark.svg?url';
import PBDiiaLogo from '../../../images/general/pb-diia-logo.svg?url';
import Pending from '../../../images/general/pending.svg?url';
import PendingDark from '../../../images/general/pending_dark.svg?url';
import PersonalData from '../../../images/general/personal-data.svg?url';
import PersonalDataDark from '../../../images/general/personal-data_dark.svg?url';
import Responsibity from '../../../images/general/responsibity.svg?url';
import ResponsibityDark from '../../../images/general/responsibity_dark.svg?url';
import SadSmile from '../../../images/general/sad_smile.svg?url';
import Shop from '../../../images/general/shop.svg?url';
import ShopDark from '../../../images/general/shop_dark.svg?url';
import Success from '../../../images/general/success.svg?url';
import SuccessDark from '../../../images/general/success_dark.svg?url';
import TournamentsDark from '../../../images/general/tournaments_dark.svg?url';
import Typing from '../../../images/general/typing.svg?url';
import TypingDark from '../../../images/general/typing_dark.svg?url';
import UpdateDark from '../../../images/general/update_dark.svg?url';
import VideoCrash from '../../../images/general/video-crash.svg?url';
import VideoCrashDark from '../../../images/general/video-crash_dark.svg?url';
import Wallet from '../../../images/general/wallet.svg?url';
import WalletDark from '../../../images/general/wallet_dark.svg?url';

import BookAddressSelfie from '../../../images/general/documents/book-address-selfie.svg?url';
import BookAddressSelfieDark from '../../../images/general/documents/book-address-selfie_dark.svg?url';
import BookAddress from '../../../images/general/documents/book-address.svg?url';
import BookAddressDark from '../../../images/general/documents/book-address_dark.svg?url';
import BookFirstPage from '../../../images/general/documents/book-first-page.svg?url';
import BookFirstPageDark from '../../../images/general/documents/book-first-page_dark.svg?url';
import BookPhotoPersonalDataSelfie from '../../../images/general/documents/book-photo-personal-data-selfie.svg?url';
import BookPhotoPersonalDataSelfieDark from '../../../images/general/documents/book-photo-personal-data-selfie_dark.svg?url';
import BookPhotoPersonalData from '../../../images/general/documents/book-photo-personal-data.svg?url';
import BookPhotoPersonalDataDark from '../../../images/general/documents/book-photo-personal-data_dark.svg?url';
import CardBackSide from '../../../images/general/documents/card-back-side.svg?url';
import CardBackSideDark from '../../../images/general/documents/card-back-side_dark.svg?url';
import CardFrontSideSelfie from '../../../images/general/documents/card-front-side-selfie.svg?url';
import CardFrontSideSelfieDark from '../../../images/general/documents/card-front-side-selfie_dark.svg?url';
import CardFrontSide from '../../../images/general/documents/card-front-side.svg?url';
import CardFrontSideDark from '../../../images/general/documents/card-front-side_dark.svg?url';
import IdFront from '../../../images/general/documents/id_front.svg?url';
import IdFrontDark from '../../../images/general/documents/id_front_dark.svg?url';
import Passport from '../../../images/general/documents/passport.svg?url';
import PassportDark from '../../../images/general/documents/passport_dark.svg?url';

import AndroidIcon from '../../../images/footer/android.svg?url';
import IosIcon from '../../../images/footer/ios.svg?url';

import Major from '../../../images/jackpotTypes/major.svg?url';
import MajorDark from '../../../images/jackpotTypes/major_dark.svg?url';
import Mega from '../../../images/jackpotTypes/mega.svg?url';
import MegaDark from '../../../images/jackpotTypes/mega_dark.svg?url';
import Mini from '../../../images/jackpotTypes/mini.svg?url';
import MiniDark from '../../../images/jackpotTypes/mini_dark.svg?url';
import Minor from '../../../images/jackpotTypes/minor.svg?url';
import MinorDark from '../../../images/jackpotTypes/minor_dark.svg?url';

import VirtualBetRadar from '../../../images/virtual-sports/betradar-vfb-mobile.svg?url';

import Abon from '../../../images/payments/Abon.svg?url';
import Advcash from '../../../images/payments/Advcash.svg?url';
import Aircash from '../../../images/payments/Aircash.svg?url';
import ApplePayGoriz from '../../../images/payments/ApplePay_goriz.svg?url';
import CorvusPay from '../../../images/payments/CorvusPay.svg?url';
import CorvusPayDark from '../../../images/payments/CorvusPay_dark.svg?url';
import DinerClub from '../../../images/payments/DinerClub.svg?url';
import DinerClubDark from '../../../images/payments/DinerClub_dark.svg?url';
import DiscoverNetwork from '../../../images/payments/DiscoverNetwork.svg?url';
import DiscoverNetworkDark from '../../../images/payments/DiscoverNetwork_dark.svg?url';
import GooglePayGoriz from '../../../images/payments/Google_Pay_goriz.svg?url';
import Maestro from '../../../images/payments/Maestro.svg?url';
import MaestroDark from '../../../images/payments/Maestro_dark.svg?url';
import MasterCard from '../../../images/payments/MasterCard.svg?url';
import MasterCardDark from '../../../images/payments/MasterCard_dark.svg?url';
import MastercardSecurecode from '../../../images/payments/Mastercard-securecode.svg?url';
import MastercardSecurecodeDark from '../../../images/payments/Mastercard-securecode_dark.svg?url';
import PayCek from '../../../images/payments/PayCek.svg?url';
import Poslovnica from '../../../images/payments/Poslovnica.svg?url';
import VerifiedByVisa from '../../../images/payments/Verified-by-visa.svg?url';
import VerifiedByVisaDark from '../../../images/payments/Verified-by-visa_dark.svg?url';
import VisaMastercardVert from '../../../images/payments/Visa-Mastercard_vert.svg?url';
import VisaMastercardVertDark from '../../../images/payments/Visa-Mastercard_vert_dark.svg?url';
import Visa from '../../../images/payments/Visa.svg?url';
import VisaVar from '../../../images/payments/Visa_var.svg?url';
import VisaVarDark from '../../../images/payments/Visa_var_dark.svg?url';
import ApplePay from '../../../images/payments/applepay.svg?url';
import ApplePayDark from '../../../images/payments/applepay_dark.svg?url';
import City24 from '../../../images/payments/city24.svg?url';
import City24Dark from '../../../images/payments/city24_dark.svg?url';
import DevelopmentIcon from '../../../images/payments/developmentIcon.svg?url';
import EasyPay from '../../../images/payments/easy_pay.svg?url';
import EasyPayDark from '../../../images/payments/easy_pay_dark.svg?url';
import GPay from '../../../images/payments/gpay.svg?url';
import GPayDark from '../../../images/payments/gpay_dark.svg?url';
import Masterpass from '../../../images/payments/masterpass.svg?url';
import MasterpassDark from '../../../images/payments/masterpass_dark.svg?url';
import VisaMastercardGoriz from '../../../images/payments/visa_mastercard_goriz.svg?url';
import VisaMastercardGorizDark from '../../../images/payments/visa_mastercard_goriz_dark.svg?url';

import Bitcoin from '../../../images/payments/icons/Bitcoin.svg?url';
import BitcoinDark from '../../../images/payments/icons/Bitcoin_dark.svg?url';
import NetellerDark from '../../../images/payments/icons/Neteller_dark.svg?url';
import QiwiWallet from '../../../images/payments/icons/QiwiWallet.svg?url';
import QiwiWalletDark from '../../../images/payments/icons/QiwiWallet_dark.svg?url';
import AbonIcons from '../../../images/payments/icons/abon.svg?url';
import AbonIconsDark from '../../../images/payments/icons/abon_dark.svg?url';
import Ada from '../../../images/payments/icons/ada.svg?url';
import AdvcashIcons from '../../../images/payments/icons/advcash.svg?url';
import AdvcashIconsDark from '../../../images/payments/icons/advcash_dark.svg?url';
import AircashIcons from '../../../images/payments/icons/aircash.svg?url';
import Astropay from '../../../images/payments/icons/astropay.svg?url';
import AstropayDark from '../../../images/payments/icons/astropay_dark.svg?url';
import BankCard from '../../../images/payments/icons/bank_card.svg?url';
import BankCardDark from '../../../images/payments/icons/bank_card_dark.svg?url';
import BCH from '../../../images/payments/icons/bch.svg?url';
import BNB from '../../../images/payments/icons/bnb.svg?url';
import BPay from '../../../images/payments/icons/bpay.svg?url';
import BPayDark from '../../../images/payments/icons/bpay_dark.svg?url';
import BTC from '../../../images/payments/icons/btc.svg?url';
import BTCDark from '../../../images/payments/icons/btc_dark.svg?url';
import BTCLogo from '../../../images/payments/icons/btc_logo.svg?url';
import BUSD from '../../../images/payments/icons/busd.svg?url';
import CoinsPaid from '../../../images/payments/icons/coinspaid.svg?url';
import Corvus from '../../../images/payments/icons/corvus.svg?url';
import Deposit from '../../../images/payments/icons/deposit.svg?url';
import DepositWithdraw from '../../../images/payments/icons/depositWithdraw.svg?url';
import DepositWithdrawalDark from '../../../images/payments/icons/depositWithdrawal_dark.svg?url';
import DepositDark from '../../../images/payments/icons/deposit_dark.svg?url';
import DepositWaiting from '../../../images/payments/icons/deposit_waiting.svg?url';
import DepositWaitingDark from '../../../images/payments/icons/deposit_waiting_dark.svg?url';
import Doge from '../../../images/payments/icons/doge.svg?url';
import EcoPayz from '../../../images/payments/icons/ecopayz.svg?url';
import EcoPayzDark from '../../../images/payments/icons/ecopayz_dark.svg?url';
import ETH from '../../../images/payments/icons/eth.svg?url';
import FavoritPay from '../../../images/payments/icons/favorit_pay.svg?url';
import FavoritPayDark from '../../../images/payments/icons/favorit_pay_dark.svg?url';
import FP from '../../../images/payments/icons/fp.svg?url';
import FPDark from '../../../images/payments/icons/fp_dark.svg?url';
import ID from '../../../images/payments/icons/id.svg?url';
import IDDark from '../../../images/payments/icons/id_dark.svg?url';
import IDram from '../../../images/payments/icons/idram.svg?url';
import LTC from '../../../images/payments/icons/ltc.svg?url';
import Mastercard from '../../../images/payments/icons/mastercard.svg?url';
import MuchBetter from '../../../images/payments/icons/muchBetter.svg?url';
import MuchBetterDark from '../../../images/payments/icons/muchBetter_dark.svg?url';
import NetBanking from '../../../images/payments/icons/netBanking.svg?url';
import Neteller from '../../../images/payments/icons/neteller.svg?url';
import NetellerGo from '../../../images/payments/icons/netellergo.svg?url';
import NetellerGoDark from '../../../images/payments/icons/netellergo_dark.svg?url';
import PayTm from '../../../images/payments/icons/payTm.svg?url';
import Paysafecard from '../../../images/payments/icons/paysafecard.svg?url';
import Praxis from '../../../images/payments/icons/praxis.svg?url';
import PraxisDark from '../../../images/payments/icons/praxis_dark.svg?url';
import RapidTransfer from '../../../images/payments/icons/rapidTransfer.svg?url';
import RapidTransferDark from '../../../images/payments/icons/rapidTransfer_dark.svg?url';
import RunPay from '../../../images/payments/icons/runpay.svg?url';
import RunPayDark from '../../../images/payments/icons/runpay_dark.svg?url';
import SingleWallet from '../../../images/payments/icons/singleWallet.svg?url';
import Skrill from '../../../images/payments/icons/skrill.svg?url';
import Skrill1Tap from '../../../images/payments/icons/skrill1Tap.svg?url';
import Terminal from '../../../images/payments/icons/terminal.svg?url';
import TRON from '../../../images/payments/icons/tron.svg?url';
import UPI from '../../../images/payments/icons/upi.svg?url';
import USDC from '../../../images/payments/icons/usdc.svg?url';
import USDT from '../../../images/payments/icons/usdt.svg?url';
import VisaIcons from '../../../images/payments/icons/visa.svg?url';
import VisaIconsDark from '../../../images/payments/icons/visa_dark.svg?url';
import VisaMastercard from '../../../images/payments/icons/visa_mastercard.svg?url';
import VisaMastercardDark from '../../../images/payments/icons/visa_mastercard_dark.svg?url';
import W1Visa from '../../../images/payments/icons/w1_visa.png';
import WalletOne from '../../../images/payments/icons/wallet_one.svg?url';
import WalletOneDark from '../../../images/payments/icons/wallet_one_dark.svg?url';
import WebMoney from '../../../images/payments/icons/webmoney.svg?url';
import XRP from '../../../images/payments/icons/xrp.svg?url';
import YAMoney from '../../../images/payments/icons/ya-money.svg?url';
import YAMoneyDark from '../../../images/payments/icons/ya-money_dark.svg?url';
import Yigim from '../../../images/payments/icons/yigim.svg?url';
import BitcoinVert from '../../../images/payments/Bitcoin_vert.svg?url';
import Litecoin from '../../../images/payments/Litecoin.svg?url';
import BitcoinCash from '../../../images/payments/Bitcoin_Cash.svg?url';
import ADA from '../../../images/payments/ADA.svg?url';
import ADADark from '../../../images/payments/ADA_dark.svg?url';
import Ethereum from '../../../images/payments/ethereum.svg?url';
import Dogecoin from '../../../images/payments/dogecoin.svg?url';
import Ripple from '../../../images/payments/ripple.svg?url';
import CasinoCoin from '../../../images/payments/CasinoCoin.svg?url';
import TetherErc20 from '../../../images/payments/Tether_Erc20.svg?url';
import TetherTrc20 from '../../../images/payments/Tether_Trc20.svg?url';
import BinanceCoin from '../../../images/payments/binance_coin.svg?url';
import Binance20Coin from '../../../images/payments/Binance_coin_20.svg?url';
import BinanceUsdToken from '../../../images/payments/BUSD.svg?url';
import Sonm from '../../../images/payments/sonm.svg?url';
import StasisEurs from '../../../images/payments/stasis-euro-eurs.svg?url';
import Usdc from '../../../images/payments/USDC.svg?url';
import Tron from '../../../images/payments/tron.svg?url';
import ExeedmeToken from '../../../images/payments/XED.svg?url';
import DaiStablecoin from '../../../images/payments/dai-dai.svg?url';
import MetrixCoin from '../../../images/payments/MRX.svg?url';
import WrappedBitcoin from '../../../images/payments/wbtc.svg?url';
import WrappedBitcoinDark from '../../../images/payments/wbtc_dark.svg?url';
import CoinspaidToken from '../../../images/payments/CPD.svg?url';
import BrazilianDigitalToken from '../../../images/payments/BRZ.svg?url';
import VerseToken from '../../../images/payments/VERSE.svg?url';

export default {
  'images/general/bank_dark': BankDark,
  'images/general/bank': Bank,
  'images/general/banks_logo_list': BanksLogoList,
  'images/general/bill': Bill,
  'images/general/bill_dark': BillDark,
  'images/general/bonus': Bonus,
  'images/general/bonus_dark': BonusDark,
  'images/general/bonus_hunter': BonusHunter,
  'images/general/bonus_hunter_dark': BonusHunterDark,
  'images/general/bonus_unavailable': BonusUnavailable,
  'images/general/bonus_unavailable_dark': BonusUnavailableDark,
  'images/general/camera': Camera,
  'images/general/camera_dark': CameraDark,
  'images/general/card_2sides_for_filling': Card2SidesForFilling,
  'images/general/card_front': CardFront,
  'images/general/card_image': CardImage,
  'images/general/card_image_dark': CardImageDark,
  'images/general/coin': Coin,
  'images/general/confirm': Confirm,
  'images/general/confirm_dark': ConfirmDark,
  'images/general/default_bonus_image': DefaultBonusImage,
  'images/general/diia_logo': DiiaLogo,
  'images/general/email': Email,
  'images/general/email_dark': EmailDark,
  'images/general/email_il': EmailIl,
  'images/general/error': Error,
  'images/general/error_dark': ErrorDark,
  'images/general/fixing_dark': FixingDark,
  'images/general/ill_bonus': IllBonus,
  'images/general/in_club': InClub,
  'images/general/iphone': IPhone,
  'images/general/iphone_dark': IPhoneDark,
  'images/general/looking_for': LookingFor,
  'images/general/looking_for_dark': LookingForDark,
  'images/general/money': Money,
  'images/general/money_dark': MoneyDark,
  'images/general/no_data': NoData,
  'images/general/no_data_dark': NoDataDark,
  'images/general/no_wallet': NoWallet,
  'images/general/notification': Notification,
  'images/general/notification_dark': NotificationDark,
  'images/general/only_pc': OnlyPC,
  'images/general/only_pc_dark': OnlyPCDark,
  'images/general/password': Password,
  'images/general/password_dark': PasswordDark,
  'images/general/pb-diia-logo': PBDiiaLogo,
  'images/general/pending': Pending,
  'images/general/pending_dark': PendingDark,
  'images/general/personal_data': PersonalData,
  'images/general/personal_data_dark': PersonalDataDark,
  'images/general/responsibity': Responsibity,
  'images/general/responsibity_dark': ResponsibityDark,
  'images/general/sad_smile': SadSmile,
  'images/general/shop': Shop,
  'images/general/shop_dark': ShopDark,
  'images/general/success': Success,
  'images/general/success_dark': SuccessDark,
  'images/general/tournaments_dark': TournamentsDark,
  'images/general/typing': Typing,
  'images/general/typing_dark': TypingDark,
  'images/general/update_dark': UpdateDark,
  'images/general/video_crash': VideoCrash,
  'images/general/video_crash_dark': VideoCrashDark,
  'images/general/wallet': Wallet,
  'images/general/wallet_dark': WalletDark,

  'images/general/documents/book_address_selfie': BookAddressSelfie,
  'images/general/documents/book_address_selfie_dark': BookAddressSelfieDark,
  'images/general/documents/book_address': BookAddress,
  'images/general/documents/book_address_dark': BookAddressDark,
  'images/general/documents/book_first_page': BookFirstPage,
  'images/general/documents/book_first_page_dark': BookFirstPageDark,
  'images/general/documents/book_photo_personal_data_selfie': BookPhotoPersonalDataSelfie,
  'images/general/documents/book_photo_personal_data_selfie_dark': BookPhotoPersonalDataSelfieDark,
  'images/general/documents/book_photo_personal_data': BookPhotoPersonalData,
  'images/general/documents/book_photo_personal_data_dark': BookPhotoPersonalDataDark,
  'images/general/documents/card_back_side': CardBackSide,
  'images/general/documents/card_back_side_dark': CardBackSideDark,
  'images/general/documents/card_front_side_selfie': CardFrontSideSelfie,
  'images/general/documents/card_front_side_selfie_dark': CardFrontSideSelfieDark,
  'images/general/documents/card_front_side': CardFrontSide,
  'images/general/documents/card_front_side_dark': CardFrontSideDark,
  'images/general/documents/id_front': IdFront,
  'images/general/documents/id_front_dark': IdFrontDark,
  'images/general/documents/passport': Passport,
  'images/general/documents/passport_dark': PassportDark,

  'images/footer/android': AndroidIcon,
  'images/footer/ios': IosIcon,

  'images/jackpotTypes/major': Major,
  'images/jackpotTypes/major_dark': MajorDark,
  'images/jackpotTypes/mega': Mega,
  'images/jackpotTypes/mega_dark': MegaDark,
  'images/jackpotTypes/mini': Mini,
  'images/jackpotTypes/mini_dark': MiniDark,
  'images/jackpotTypes/minor': Minor,
  'images/jackpotTypes/minor_dark': MinorDark,

  'images/virtual-sports/betradar-vfb-mobile': VirtualBetRadar,

  'images/payments/Abon': Abon,
  'images/payments/Advcash': Advcash,
  'images/payments/Aircash': Aircash,
  'images/payments/ApplePay_goriz': ApplePayGoriz,
  'images/payments/CorvusPay': CorvusPay,
  'images/payments/CorvusPay_dark': CorvusPayDark,
  'images/payments/DinerClub': DinerClub,
  'images/payments/DinerClub_dark': DinerClubDark,
  'images/payments/DiscoverNetwork': DiscoverNetwork,
  'images/payments/DiscoverNetwork_dark': DiscoverNetworkDark,
  'images/payments/Google_Pay_goriz': GooglePayGoriz,
  'images/payments/Maestro': Maestro,
  'images/payments/Maestro_dark': MaestroDark,
  'images/payments/MasterCard': MasterCard,
  'images/payments/MasterCard_dark': MasterCardDark,
  'images/payments/Mastercard-securecode': MastercardSecurecode,
  'images/payments/Mastercard-securecode_dark': MastercardSecurecodeDark,
  'images/payments/PayCek': PayCek,
  'images/payments/Poslovnica': Poslovnica,
  'images/payments/Verified-by-visa': VerifiedByVisa,
  'images/payments/Verified-by-visa_dark': VerifiedByVisaDark,
  'images/payments/Visa-Mastercard_vert': VisaMastercardVert,
  'images/payments/Visa-Mastercard_vert_dark': VisaMastercardVertDark,
  'images/payments/Visa': Visa,
  'images/payments/Visa_var': VisaVar,
  'images/payments/Visa_var_dark': VisaVarDark,
  'images/payments/applepay': ApplePay,
  'images/payments/applepay_dark': ApplePayDark,
  'images/payments/city24': City24,
  'images/payments/city24_dark': City24Dark,
  'images/payments/developmentIcon': DevelopmentIcon,
  'images/payments/easy_pay': EasyPay,
  'images/payments/easy_pay_dark': EasyPayDark,
  'images/payments/gpay': GPay,
  'images/payments/gpay_dark': GPayDark,
  'images/payments/masterpass': Masterpass,
  'images/payments/masterpass_dark': MasterpassDark,
  'images/payments/visa_mastercard_goriz': VisaMastercardGoriz,
  'images/payments/visa_mastercard_goriz_dark': VisaMastercardGorizDark,

  'images/payments/icons/Bitcoin': Bitcoin,
  'images/payments/icons/Bitcoin_dark': BitcoinDark,
  'images/payments/icons/Neteller_dark': NetellerDark,
  'images/payments/icons/QiwiWallet': QiwiWallet,
  'images/payments/icons/QiwiWallet_dark': QiwiWalletDark,
  'images/payments/icons/abon': AbonIcons,
  'images/payments/icons/abon_dark': AbonIconsDark,
  'images/payments/icons/ada': Ada,
  'images/payments/icons/advcash': AdvcashIcons,
  'images/payments/icons/advcash_dark': AdvcashIconsDark,
  'images/payments/icons/aircash': AircashIcons,
  'images/payments/icons/astropay': Astropay,
  'images/payments/icons/astropay_dark': AstropayDark,
  'images/payments/icons/bank_card': BankCard,
  'images/payments/icons/bank_card_dark': BankCardDark,
  'images/payments/icons/bch': BCH,
  'images/payments/icons/bnb': BNB,
  'images/payments/icons/bpay': BPay,
  'images/payments/icons/bpay_dark': BPayDark,
  'images/payments/icons/btc': BTC,
  'images/payments/icons/btc_dark': BTCDark,
  'images/payments/icons/btc_logo': BTCLogo,
  'images/payments/icons/busd': BUSD,
  'images/payments/icons/coinspaid': CoinsPaid,
  'images/payments/icons/corvus': Corvus,
  'images/payments/icons/deposit': Deposit,
  'images/payments/icons/depositWithdraw': DepositWithdraw,
  'images/payments/icons/depositWithdrawal_dark': DepositWithdrawalDark,
  'images/payments/icons/deposit_dark': DepositDark,
  'images/payments/icons/deposit_waiting': DepositWaiting,
  'images/payments/icons/deposit_waiting_dark': DepositWaitingDark,
  'images/payments/icons/doge': Doge,
  'images/payments/icons/ecopayz': EcoPayz,
  'images/payments/icons/ecopayz_dark': EcoPayzDark,
  'images/payments/icons/eth': ETH,
  'images/payments/icons/favorit_pay': FavoritPay,
  'images/payments/icons/favorit_pay_dark': FavoritPayDark,
  'images/payments/icons/fp': FP,
  'images/payments/icons/fp_dark': FPDark,
  'images/payments/icons/id': ID,
  'images/payments/icons/id_dark': IDDark,
  'images/payments/icons/idram': IDram,
  'images/payments/icons/ltc': LTC,
  'images/payments/icons/mastercard': Mastercard,
  'images/payments/icons/muchBetter': MuchBetter,
  'images/payments/icons/muchBetter_dark': MuchBetterDark,
  'images/payments/icons/netBanking': NetBanking,
  'images/payments/icons/neteller': Neteller,
  'images/payments/icons/netellergo': NetellerGo,
  'images/payments/icons/netellergo_dark': NetellerGoDark,
  'images/payments/icons/payTm': PayTm,
  'images/payments/icons/paysafecard': Paysafecard,
  'images/payments/icons/praxis': Praxis,
  'images/payments/icons/praxis_dark': PraxisDark,
  'images/payments/icons/rapidTransfer': RapidTransfer,
  'images/payments/icons/rapidTransfer_dark': RapidTransferDark,
  'images/payments/icons/runpay': RunPay,
  'images/payments/icons/runpay_dark': RunPayDark,
  'images/payments/icons/singleWallet': SingleWallet,
  'images/payments/icons/skrill': Skrill,
  'images/payments/icons/skrill1Tap': Skrill1Tap,
  'images/payments/icons/terminal': Terminal,
  'images/payments/icons/tron': TRON,
  'images/payments/icons/upi': UPI,
  'images/payments/icons/usdc': USDC,
  'images/payments/icons/usdt': USDT,
  'images/payments/icons/visa': VisaIcons,
  'images/payments/icons/visa_dark': VisaIconsDark,
  'images/payments/icons/visa_mastercard': VisaMastercard,
  'images/payments/icons/visa_mastercard_dark': VisaMastercardDark,
  'images/payments/icons/w1_visa': W1Visa,
  'images/payments/icons/wallet_one': WalletOne,
  'images/payments/icons/wallet_one_dark': WalletOneDark,
  'images/payments/icons/webmoney': WebMoney,
  'images/payments/icons/xrp': XRP,
  'images/payments/icons/ya-money': YAMoney,
  'images/payments/icons/ya-money_dark': YAMoneyDark,
  'images/payments/icons/yigim': Yigim,
  'images/payments/Bitcoin_vert': BitcoinVert,
  'images/payments/Litecoin': Litecoin,
  'images/payments/Bitcoin_Cash': BitcoinCash,
  'images/payments/ADA': ADA,
  'images/payments/ADA_dark': ADADark,
  'images/payments/ethereum': Ethereum,
  'images/payments/dogecoin': Dogecoin,
  'images/payments/ripple': Ripple,
  'images/payments/CasinoCoin': CasinoCoin,
  'images/payments/Tether_Erc20': TetherErc20,
  'images/payments/Tether_Trc20': TetherTrc20,
  'images/payments/binance_coin': BinanceCoin,
  'images/payments/Binance_coin_20': Binance20Coin,
  'images/payments/BUSD': BinanceUsdToken,
  'images/payments/sonm': Sonm,
  'images/payments/stasis-euro-eurs': StasisEurs,
  'images/payments/USDC': Usdc,
  'images/payments/tron': Tron,
  'images/payments/XED': ExeedmeToken,
  'images/payments/dai-dai': DaiStablecoin,
  'images/payments/MRX': MetrixCoin,
  'images/payments/wbtc': WrappedBitcoin,
  'images/payments/wbtc_dark': WrappedBitcoinDark,
  'images/payments/CPD': CoinspaidToken,
  'images/payments/BRZ': BrazilianDigitalToken,
  'images/payments/VERSE': VerseToken
};
