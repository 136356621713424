import { useLocation } from '@namespace/i18n';
import { matchPath } from 'react-router-dom';
import { isEmpty } from 'lodash';

const useMatchPath = (
  paths = [],
  { isReturnFullMatch, catchAll = false } = {}
) => {
  const { pathname } = useLocation();

  return paths.reduce((acc, currentPath) => {
    const match = matchPath(
      { path: catchAll ? `${currentPath}/*` : currentPath },
      pathname
    );
    if (!isEmpty(match)) return isReturnFullMatch ? match : match.params || {};

    return acc;
  }, {});
};

export default useMatchPath;
