import { useContext, useEffect } from 'react';
import useNotifications from '@namespace/notifications/src/hooks/useNotifications';
import { useI18n, useLocation } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { REDIRECT_PARAM } from '@namespace/common';
import { useGeneralRoutes } from '@namespace/cms';
import { window } from '@namespace/helpers';
import { UserContext } from '../../context';
import addUserToBonusModel from '../../api/addUserToBonusModel';
import { USER_ROLES } from '../../constants';

const useUserBonusModel = () => {
  const [user] = useContext(UserContext);
  const { id: userId, role } = user;
  const { errorNotification, successNotification } = useNotifications();
  const { t } = useI18n();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { login } = useGeneralRoutes();

  useEffect(() => {
    window.AddUserBonusModel = (bonusModelId) => {
      if (role === USER_ROLES.USER) {
        addUserToBonusModel({ userId, bonusModelId })
          .then(({ response }) => {
            if (response) {
              successNotification(t('user.bonusModel.success'));
            } else {
              errorNotification(t('user.bonusModel.error'));
            }
          })
          .catch(() => errorNotification(t('user.bonusModel.error')));
      } else {
        push(`${login}?${REDIRECT_PARAM}${pathname}`);
      }
    };
  }, [userId]);
};

export default useUserBonusModel;
