import { useI18n } from '@namespace/i18n';
import { Button, AText, Box } from '@alf/uikit';
import buttonStyles from '../index.module.css';
import styles from './index.module.css';

const RefillConfirmation = ({ onRefill, onCancel }) => {
  const { t } = useI18n();
  return (
    <Box justify="center" align="center" padding="l" className={styles.wrapper}>
      <Box
        direction="column"
        justify="center"
        align="center"
        className={styles.innerWrapper}
      >
        <Box bottom="s">
          <AText breed="BU" size="3xl" color="txt_color_4" align="center">
            {t('predictionsInfoBlock.refillConfirmation.header')}
          </AText>
        </Box>
        <Box bottom="ms">
          <AText breed="R" size="l" color="txt_color_4" align="center">
            {t('predictionsInfoBlock.refillConfirmation.message')}
          </AText>
        </Box>
        <Box bottom="l">
          <img
            className={styles.image}
            src="/images/predictions/emptyState.svg"
            alt="empty bracket"
          />
        </Box>
        <Box align="center" justify="center" className={styles.buttonsWrap}>
          <Button
            className={`${buttonStyles.predictionsInfoBlockBetButton} ${styles.button}`}
            onClick={onRefill}
            actionType="color1"
            intent="primary"
            size="l"
          >
            {t('predictionsInfoBlock.refillConfirmation.buttonRefill')}
          </Button>
          <Button
            className={`${buttonStyles.predictionsInfoBlockRefillButton} ${styles.button}`}
            onClick={onCancel}
            actionType="color4"
            intent="secondary"
            size="l"
          >
            {t('predictionsInfoBlock.refillConfirmation.buttonCancel')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default RefillConfirmation;
