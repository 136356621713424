import PropTypes from 'prop-types';
import { Icon } from '@namespace/icons2';
import Box from '../Box';
import Button from '../Button';
import AText from '../AText';
import styles from './index.module.css';

const Notification = ({
  message,
  buttonLabel = '',
  onClick,
  onClose,
  className = '',
  isMobileView = false
}) => (
  <Box
    align="center"
    justify="center"
    className={`${styles.notification} ${className}`}
  >
    <Box align="center">
      <AText
        breed="R"
        size="l"
        color="notification_txt_color_1"
        className={styles.message}
      >
        {message}
      </AText>
      {buttonLabel && (
        <Button
          size="s"
          intent="secondary"
          actionType="color4"
          onClick={onClick}
          className={styles.button}
        >
          {buttonLabel}
        </Button>
      )}
    </Box>
    {onClose && (
      <Icon
        onClick={onClose}
        size="s"
        color={isMobileView ? 'btn_close_dark_color' : 'txt_color_4'}
        name="icons/general/action/close"
        className={styles.closeIcon}
      />
    )}
  </Box>
);

Notification.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  buttonLabel: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  isMobileView: PropTypes.bool
};

export default Notification;
