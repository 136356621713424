import { useCallback } from 'react';
import { Button } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import styles from './index.module.css';

const icon = <Icon name="icons/general/state/settings-default" />;

export const FilterButton = ({ className = '', label = '', onClick }) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      onClick?.();
    },
    [onClick]
  );

  return (
    <Button
      data-role="filters-btn"
      className={`${styles.button} ${className}`}
      onClick={handleClick}
      intent="quaternary"
      actionType="color3"
      size="s"
      iconLeft={icon}
    >
      {label}
    </Button>
  );
};
