import { useCallback, useContext, useMemo } from 'react';
import { I18nContext } from '../context';
import { formatters } from '../utils/formatters';
import { currencyFormatters } from '../utils/currencyFormatters';
import translate from '../utils/translate';

export const useI18n = (prefix = '') => {
  const [i18n, { SET_LANG_TRANSLATIONS }] = useContext(I18nContext);
  const {
    translationsMap,
    timezone,
    language,
    locale,
    browserLanguage,
    dateFormats,
    showKeysForContentManager,
    currencyFormats
  } = i18n;

  /**
   * @type {function(tKey:String, replacers:(String|Array<String>)=, defaultTranslation: String=): (String)}
   */
  const t = useMemo(
    () =>
      translate(prefix, translationsMap[language], showKeysForContentManager),
    [translationsMap, language, prefix, showKeysForContentManager]
  );

  const setLanguage = useCallback(
    ({ lang, cookieManagementEnabled }) =>
      SET_LANG_TRANSLATIONS(lang, cookieManagementEnabled),
    [SET_LANG_TRANSLATIONS]
  );

  const f = useMemo(() => formatters(timezone, dateFormats, t), [
    timezone,
    dateFormats,
    t
  ]);

  const c = useMemo(() => currencyFormatters(language, currencyFormats), [
    language,
    currencyFormats
  ]);

  return {
    t,
    f,
    c,
    language,
    timezone,
    locale,
    browserLanguage,
    setLanguage
  };
};
