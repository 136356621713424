import { ProgressBar, Box, AText } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import {
  VOLLEYBALL_LONGEST_STRIKE,
  VOLLEYBALL_POINTS_WON_ON_SERVE,
  VOLLEYBALL_SERVES_WON
} from '../../../constants';
import { useEventField } from '../../../../../../../store';
import styles from './index.module.css';

const Volleyball = ({ eventId }) => {
  const { t } = useI18n();
  const eventRtsData = useEventField(eventId, 'eventRtsData') || [];

  const { value: firstParticipantPercents = 0 } =
    eventRtsData?.find(
      ({ type }) => type === VOLLEYBALL_SERVES_WON.PARTICIPANT_1
    ) || {};

  const { value: secondParticipantPercents = 0 } =
    eventRtsData?.find(
      ({ type }) => type === VOLLEYBALL_SERVES_WON.PARTICIPANT_2
    ) || {};

  const { value: firstParticipantPoints = 0 } =
    eventRtsData?.find(
      ({ type }) => type === VOLLEYBALL_POINTS_WON_ON_SERVE.PARTICIPANT_1
    ) || {};

  const { value: secondParticipantPoints = 0 } =
    eventRtsData?.find(
      ({ type }) => type === VOLLEYBALL_POINTS_WON_ON_SERVE.PARTICIPANT_2
    ) || {};

  const { value: firstParticipantLongestStrike = 0 } =
    eventRtsData?.find(
      ({ type }) => type === VOLLEYBALL_LONGEST_STRIKE.PARTICIPANT_1
    ) || {};

  const { value: secondParticipantLongestStrike = 0 } =
    eventRtsData?.find(
      ({ type }) => type === VOLLEYBALL_LONGEST_STRIKE.PARTICIPANT_2
    ) || {};

  return (
    <Box className={styles.container} direction="column" align="center">
      <AText size="xs" breed="BU" color="line_txt_1">
        {t('scoreboard.volleyball.ownServesWon')}
      </AText>
      <Box className={styles.stats}>
        <Box className={styles.statsDetails} align="center">
          <AText className={styles.percent} size="s" color="line_txt_1">
            {`${firstParticipantPercents}%`}
          </AText>
          <ProgressBar
            totalValue={100}
            currentValue={Number(firstParticipantPercents)}
            progressColor="accent_color_1"
            containerColor="line_bg_7"
          />
        </Box>
        <Box className={styles.statsDetails} align="center">
          <ProgressBar
            totalValue={100}
            currentValue={Number(secondParticipantPercents)}
            progressColor="accent_color_1"
            containerColor="line_bg_7"
            isReversed={true}
          />
          <AText className={styles.percent} size="s" color="line_txt_1">
            {`${secondParticipantPercents}%`}
          </AText>
        </Box>
      </Box>
      <Box className={styles.pointsDetails} direction="column">
        <AText
          className={styles.points}
          breed="B"
          size="s"
          color="line_txt_1"
          align="center"
        >
          {t('scoreboard.volleyball.pointsWonOnServe')}
          <AText
            className={styles.points}
            breed="R"
            size="m"
            color="line_txt_1"
          >
            {` (${firstParticipantPoints}:${secondParticipantPoints})`}
          </AText>
        </AText>
        <AText
          className={styles.points}
          breed="B"
          size="s"
          color="line_txt_1"
          align="center"
        >
          {t('scoreboard.volleyball.longestStrikeOfPointsTaken')}
          <AText
            className={styles.points}
            breed="R"
            size="m"
            color="line_txt_1"
          >
            {` (${firstParticipantLongestStrike}:${secondParticipantLongestStrike})`}
          </AText>
        </AText>
      </Box>
    </Box>
  );
};

export default Volleyball;
