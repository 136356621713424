import { useCallback } from 'react';
import { AText, Box, Button } from '@alf/uikit';
import { Image } from '@namespace/icons2';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { useGeneralRoutes } from '@namespace/cms';
import styles from './index.module.css';

const TrxInfo = ({ operationId = '' }) => {
  const { t } = useI18n();
  const { push } = useHistory();
  const { swDeposit } = useGeneralRoutes();
  const handleDepositRedirect = useCallback(() => push(swDeposit), [
    push,
    swDeposit
  ]);

  return (
    <>
      <AText breed="BU" size="l" color="po_txt_color_1" align="center">
        {t('balance.deposit.qrcode.trx.status')}
      </AText>
      <Box top="s">
        <AText breed="R" size="l" color="po_txt_color_3" align="center">
          {t('balance.deposit.qrcode.trx.status.info')}
          <AText
            className={styles.info}
            breed="B"
            size="l"
            color="po_txt_color_3"
            align="center"
          >
            {operationId}
          </AText>
        </AText>
      </Box>
      <Box top="m">
        <Image name="general/pending" alt="#" />
      </Box>
      <Box top="ms" className={styles.btn}>
        <Button
          fullWidth={true}
          size="m"
          intent="primary"
          actionType="color3"
          onClick={handleDepositRedirect}
        >
          {t('balance.deposit.qrcode.trx.status.btn')}
        </Button>
      </Box>
    </>
  );
};

export default TrxInfo;
