import {
  VALID_PREDICTION_STATUS,
  PREDICTIONS_STEPS_IDS
} from '../../../constants';

/*
 * Format team instance
 */

export const getTeamWithIndex = (
  teamName,
  index,
  isSavedPrediction
  // stepName
) => ({
  title: teamName,
  id: teamName,
  index: isSavedPrediction ? index + 1 : 0,
  validPrediction: VALID_PREDICTION_STATUS.NOT_PLAYED
});

export const getTeamWithWin = (teamName, index, isSavedPrediction) => ({
  title: teamName,
  id: teamName,
  win: isSavedPrediction ? index === 0 : false,
  validPrediction: false
});

/*
 * Format teams in each step besides GROUP
 */

export const formatTeams = (
  teams = [],
  isSavedPrediction,
  stepName,
  groupTeams
) => {
  const getTeam =
    stepName === PREDICTIONS_STEPS_IDS.TOP_OF_THIRD
      ? getTeamWithIndex
      : getTeamWithWin;

  return teams.reduce((acc, match) => {
    for (const [index, team] of match.entries()) {
      if (team) {
        acc[team] = getTeam(team, index, isSavedPrediction);
        if (groupTeams) {
          acc[team] = {
            ...acc[team],
            groupId: groupTeams[team]?.groupId
          };
        }
      }
    }
    return acc;
  }, {});
};

/*
 * Format teams in GROUP step
 */

export const formatGroupTeams = (groups = {}, isSavedPrediction) => {
  return Object.keys(groups).reduce((acc, groupId) => {
    for (const [index, team] of groups[groupId].entries()) {
      acc[team] = {
        ...getTeamWithIndex(team, index, isSavedPrediction),
        groupId
      };
    }
    return acc;
  }, {});
};

/*
 * Return is team a valid prediction in steps where teams have indexes (GROUP, TOP_OF_THIRD)
 */

export const isValidPredictionGroup = (team, referenceGroup) => {
  const referenceIndex = referenceGroup.findIndex(
    (teamId) => teamId === team.id
  );

  if (referenceGroup.length === 0) {
    return VALID_PREDICTION_STATUS.NOT_PLAYED;
  }

  return team.index === referenceIndex + 1
    ? VALID_PREDICTION_STATUS.VALID
    : VALID_PREDICTION_STATUS.INVALID;
};

/*
 * Return is team a valid prediction in steps where teams have win/lose
 */

export const isValidPrediction = (team, referenceStep) => {
  if (!Object.keys(referenceStep).length) {
    return VALID_PREDICTION_STATUS.NOT_PLAYED;
  }

  return team.win === referenceStep[team.id]
    ? VALID_PREDICTION_STATUS.VALID
    : VALID_PREDICTION_STATUS.INVALID;
};
