import { useCallback, useContext, useEffect, useState } from 'react';
import { FORM_COMPONENTS_TYPES, FormBuilder } from '@namespace/forms';
import { ACTION_LOCATIONS, LOG_ACTIONS, logAction } from '@namespace/analytics';
import {
  useLogin,
  useLoginSuccessRedirectLink,
  useUserInfoLoaded
} from '@namespace/user';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { setSessionStorageValue } from '@namespace/helpers';
import { PageContext, useGeneralRoutes } from '@namespace/cms';
import { Link } from '@alf/uikit';
import styles from './index.module.css';

const LinkTo = ({ text, link, color }) => (
  <Link
    to={link}
    Component={LocalizedLink}
    color={color}
    className={styles.link}
    decoration="underline"
    size="s"
  >
    {text}
  </Link>
);

const FastLogin = () => {
  const { page } = useContext(PageContext);
  const { pageConfig = {} } = page;
  const { headerColorScheme } = pageConfig;
  const headerSecondary = headerColorScheme === 'secondary';
  const { t } = useI18n();
  const {
    forgotPassword,
    register,
    login: loginRedirectLink
  } = useGeneralRoutes();
  const { push } = useHistory();
  const [credentials, setCredentials] = useState(null);
  const successRedirectLink = useLoginSuccessRedirectLink({
    isFastLogin: true
  });
  const { userInfoLoaded } = useUserInfoLoaded();
  const onSuccess = useCallback(
    (attributes) => {
      logAction(LOG_ACTIONS.LOGIN, {
        userId: attributes.user.id,
        location: ACTION_LOCATIONS.HEADER_FAST_LOGIN
      });
      if (successRedirectLink) {
        return push(successRedirectLink);
      }
      return null;
    },
    [push, successRedirectLink]
  );

  const onError = useCallback(
    (loginError, loginFormData) => {
      if (loginFormData) {
        setSessionStorageValue('loginFormData', loginFormData);
        setSessionStorageValue(
          'fastLoginError',
          t(`login.error.${loginError}`)
        );
        push(loginRedirectLink);
      }
    },
    [push, loginRedirectLink, setSessionStorageValue, t]
  );

  const { login, success, error, setError } = useLogin(onSuccess, onError);

  useEffect(() => {
    if (!success && error) {
      setError(null);
      onError(error, credentials);
    }
  }, [success, credentials, error, setError]);

  const submit = useCallback(
    ({ email: username, password }) => {
      if (!username || !password) {
        setSessionStorageValue('loginFormData', { username, password });
        push(loginRedirectLink);
        return;
      }
      const newCredentials = {
        username,
        password,
        nextPage: successRedirectLink
      };
      setCredentials({ ...newCredentials });
      login(newCredentials);
    },
    [
      login,
      successRedirectLink,
      loginRedirectLink,
      setCredentials,
      push,
      setSessionStorageValue
    ]
  );

  if (!userInfoLoaded) {
    return null;
  }

  const componentsList = [
    {
      type: FORM_COMPONENTS_TYPES.FORM_ROW,
      name: 'fastLogin',
      fields: [
        {
          type: FORM_COMPONENTS_TYPES.EMAIL_INPUT_FIELD,
          name: 'email',
          label: (
            <LinkTo
              link={`${register}?from=${ACTION_LOCATIONS.HEADER_DESKTOP}`}
              color={headerSecondary ? 'header_txt_1' : 'accent_color_5'}
              text={t('login.helpers.register')}
            />
          ),
          placeholder: 'login.input.emailPlaceholder',
          classNames: {
            inputClassName: styles.input,
            labelClassName: styles.emailLabel,
            wrapperClassName: styles.inputWrapper
          },
          size: 's'
        },
        {
          type: FORM_COMPONENTS_TYPES.PASSWORD_FIELD,
          name: 'password',
          label: (
            <LinkTo
              link={forgotPassword}
              text={t('login.helpers.forgotPassword')}
              color="header_txt_1"
              align="right"
            />
          ),
          placeholder: 'login.input.passwordPlaceholder',
          classNames: {
            inputClassName: styles.input,
            labelClassName: styles.passwordLabel,
            wrapperClassName: styles.inputWrapper
          },
          rulesList: [],
          size: 's'
        },
        {
          type: FORM_COMPONENTS_TYPES.SUBMIT_BUTTON,
          value: 'login.btn.submit',
          classNames: {
            className: styles.submit
          },
          size: 's'
        }
      ]
    }
  ];

  return (
    <FormBuilder
      components={componentsList}
      formConfig={{
        defaultValues: {
          email: '',
          password: ''
        }
      }}
      onSubmit={submit}
      wrapperClassName={styles.wrapper}
    />
  );
};

export default FastLogin;
