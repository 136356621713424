import { useI18n } from '@namespace/i18n';
import { map, path, pipe, reject } from 'ramda';
import { useContext, useEffect } from 'react';
import { SiteSettingsContext } from '@namespace/cms';
import {
  advFilterActions,
  useAdvFilterStore
} from '../../../../../../storeAdvFilter';
import { MODE } from '../../../../../../constants';

const { FETCH_ADV_FILTER_TOURNAMENTS } = advFilterActions;

export const useFetchTournaments = (categoryIds) => {
  const { language } = useI18n();
  const partnerId = path([0, 'siteConfig', 'partnerId'])(
    useContext(SiteSettingsContext)
  );
  const advFilterTournamentsByCategoryId = useAdvFilterStore(
    'advFilterTournamentsByCategoryId'
  );

  useEffect(() => {
    if (categoryIds == null || categoryIds.length === 0) {
      return;
    }

    const newCategoryIds = pipe(
      map(Number),
      reject((cId) => cId in advFilterTournamentsByCategoryId)
    )(categoryIds);

    if (newCategoryIds.length === 0) {
      return;
    }

    FETCH_ADV_FILTER_TOURNAMENTS({
      categoryIds: newCategoryIds,
      lang: language,
      serviceId: MODE.PRE_MATCH,
      partnerId
    });
  }, [advFilterTournamentsByCategoryId, categoryIds, language, partnerId]);
};
