export const DEFAULT_MOCKED_STORES = {
  sports: {
    snapshotName: 'sports-sport-soccer'
  },
  betslip: {
    snapshotName: 'betslip-empty-state'
  },
  user: {
    snapshotName: 'onboarding-user-with-balance',
    syncFields: [
      'id',
      'colorScheme',
      'timezone',
      'userOptions',
      'countryId',
      'tzoffset',
      'lang'
    ]
  },
  games: {
    snapshotName: '150-games-default-state'
  },
  bonuses: {
    snapshotName: 'funds-income-bonus'
  },
  assistant: {
    snapshotName: 'hide-mob-app-banner'
  },
  notifications: {
    snapshotName: 'hide-notifications'
  },
  mailBox: {
    snapshotName: 'hide-messages'
  }
};

export const MOCK_LANG_BY_APP_LANG = {
  uk: 'uk',
  ru: 'uk',
  default: 'en'
};
