import { AText } from '@alf/uikit';
import { getDurationFromMillis } from '@namespace/helpers';

const TextWithDuration = ({
  text,
  type = 'info',
  time = 0,
  timeFormat = 'mm:ss'
}) => {
  const color = {
    info: 'po_txt_color_3',
    error: 'input_error_txt'
  };
  const counterColor = {
    info: 'po_txt_color_1',
    error: 'input_error_txt'
  };

  return (
    <>
      <AText breed="B" size="s" color={counterColor[type]}>
        {getDurationFromMillis(time, timeFormat)}
      </AText>
      &nbsp;
      <AText breed="R" size="s" color={color[type]}>
        {text}
      </AText>
    </>
  );
};

export default TextWithDuration;
