import { useEffect, useMemo, useState } from 'react';
import { useConfig } from '@namespace/cms';
import { useOnSlideMove } from '@namespace/common';
import { useI18n } from '@namespace/i18n';
import { Box } from '@alf/uikit';
import { CardSkelet, EventsSlider, WidgetWrapper } from '../../common';
import { sportsbookActions, useSportsbookStore } from '../../store';
import { useRemoveLine } from '../../hooks/useRemoveLine';
import { LINE_KEY } from '../../constants';
import PromoTeaser from './PromoTeaser';
import SportTeaserSlide from './SportTeaserSlide';
import styles from './index.module.css';

const { FETCH_TEASERS } = sportsbookActions;

const DURATION = 5;
const SLIDE_SPEED = 700;

const Teasers = ({ id }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { config } = useConfig(id);
  const { language: lang } = useI18n();
  const { sportIds = [] } = config;
  const {
    teasers,
    markets,
    lines: { [LINE_KEY.TEASERS]: teaserLine },
    outcomes: stateOutcomes
  } = useSportsbookStore(['teasers', 'markets', 'lines', 'outcomes']);
  const { handleClickOnMove, setIsMove } = useOnSlideMove();

  const filteredTeasers = useMemo(() => {
    const sortedTeasers = teasers.sort((a, b) => a.order - b.order);
    return sportIds.length
      ? sortedTeasers.filter(
          ({ sportId, eventId }) => sportIds.includes(sportId) && eventId
        )
      : sortedTeasers;
  }, [teasers, sportIds]);

  useEffect(() => {
    FETCH_TEASERS({ lineKey: LINE_KEY.TEASERS, lang }).finally(() => {
      setIsLoading(false);
    });
  }, [lang]);

  useRemoveLine(LINE_KEY.TEASERS);

  if (!isLoading && (!filteredTeasers.length || !teaserLine)) {
    return null;
  }

  return (
    <WidgetWrapper className={styles.wrapper}>
      {isLoading ? (
        <Box className={styles.skeletWrapper}>
          <CardSkelet />
        </Box>
      ) : (
        <EventsSlider
          setIsMove={setIsMove}
          sliderOptions={{
            className: styles.general,
            animationDuration: SLIDE_SPEED,
            autoPlay: true,
            rotationInterval: DURATION
          }}
        >
          {filteredTeasers.map(({ eventId, ...teaser }) => {
            return eventId ? (
              <SportTeaserSlide
                gap="8px"
                key={teaser.id}
                eventId={eventId}
                markets={markets}
                stateOutcomes={stateOutcomes}
                teaserLine={teaserLine}
                teaser={teaser}
                handleClickOnMove={handleClickOnMove}
              />
            ) : (
              <PromoTeaser
                gap="8px"
                key={teaser.id}
                className={styles.teaser}
                handleClickOnMove={handleClickOnMove}
                {...teaser}
              />
            );
          })}
        </EventsSlider>
      )}
    </WidgetWrapper>
  );
};

export default Teasers;
