import { useCallback, useEffect, useMemo, useState } from 'react';
import { useInterval, usePrevious } from '@namespace/helpers';
import { useI18n } from '@namespace/i18n';

const useCountdown = (startTimeStamp = 0, step = 1000) => {
  const { f } = useI18n();
  const [time, setTime] = useState(0);
  const initialTime = useMemo(
    () =>
      startTimeStamp - f.getDateTime().ts > 0
        ? startTimeStamp - f.getDateTime().ts
        : 0,
    [startTimeStamp, f]
  );
  const prevTime = usePrevious(time);
  const isCompleted = useMemo(() => Boolean(prevTime && !time), [
    prevTime,
    time
  ]);

  const ticker = useCallback(() => {
    setTime((prev) => (prev - step > 0 ? prev - step : 0));
  }, [step, setTime]);

  useInterval(ticker, time > 0 ? step : null);

  useEffect(() => {
    setTime(initialTime);
  }, [initialTime]);

  return { time, isCompleted };
};

export default useCountdown;
