import { isEmpty } from 'lodash';

export const findChanges = (data, compareData) =>
  Object.keys(compareData).reduce((acc, key) => {
    if (Object.hasOwn(data, key) && data[key] !== compareData[key]) {
      return { ...acc, [key]: data[key] };
    }
    return acc;
  }, {});

export const countChanges = (data, compareData) =>
  Object.keys(compareData).reduce((acc, key) => {
    if (Object.hasOwn(data, key) && data[key] !== compareData[key]) {
      return acc + 1;
    }
    return acc;
  }, 0);

export const hasChanges = (data, compareData) =>
  !isEmpty(findChanges(data, compareData));

export const formatConfig = (config, { t, tkPrefix }) =>
  config.map((item) => {
    const { options, key } = item;

    // TODO translation filters:
    const selectivePrefix = (value) => {
      if (tkPrefix != null) {
        return tkPrefix;
      }

      if (
        key === 'wallet' ||
        key === 'paymentSystem' ||
        key === 'currency' ||
        key === 'serviceId' ||
        key === 'gameId'
      ) {
        return '';
      }

      if (
        (key === 'cardIn' || key === 'cardOut' || key === 'amount') &&
        value !== 'all'
      ) {
        return '';
      }

      return 'personalOffice.filter.options.';
    };

    return {
      ...item,
      options: options.map(({ label, title, value }) => ({
        name: title ?? t(`${selectivePrefix(value)}${label}`),
        value
      }))
    };
  });
