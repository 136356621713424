import useLangMatch from '../useLangMatch';

const optionalMap = (params) => params?.slice(0, -1).split('/');

const useRouteOptionalParams = (path = '/') => {
  const { params = {} } = useLangMatch({
    path
  });
  const { '*': other = [], ...rest } = params;

  return {
    ...rest,
    ...(other.length > 0 && { optional: optionalMap(other) })
  };
};

export default useRouteOptionalParams;
