import { memo } from 'react';
import { Icon } from '@namespace/icons2';
import { useI18n } from '@namespace/i18n';
import { Box, AText } from '@alf/uikit';
import styles from './index.module.css';

const Step = ({ step }) => {
  const { t } = useI18n();

  return (
    <Box align="center" direction="column" className={styles.step}>
      <Box align="center">
        <Icon
          name={`icons/predictions/predictionsInfoBlockIcon${step.stepIcon}`}
          className={styles.stepIcon}
        />
      </Box>
      <AText
        breed="R"
        size="m"
        color="txt_color_4"
        className={styles.stepTitle}
      >
        {t(`predictions.step.title.${step.stepId}`)}
      </AText>
    </Box>
  );
};

export default memo(Step);
