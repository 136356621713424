import { useState, useEffect, useCallback, useContext } from 'react';
import {
  getLocalStorageValue,
  setLocalStorageValue,
  LOCAL_STORAGE_KEYS,
  getCookie,
  COOKIES_KEYS
} from '@namespace/helpers';
import { useI18n } from '@namespace/i18n';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { UserContext } from '@namespace/user';
// TODO: refactor: it is forbidden to use imports from higher level modules, remove payments-sw dependency
import { SWPaymentsContext } from '@namespace/payments-sw/src/store/context';
import {
  withdrawAllowed,
  getExpMonthYear,
  getExpDate,
  findCardStatus
} from '../../utils';
import { deletePan } from '../../api/deletePan';
import { updateDescription } from '../../api/updateDescription';
import { getAllCards, getAllPans } from '../../api/getAllPans';
import { saveCard, savePan } from '../../api/savePan';
import { putCVVToCache } from '../../api/putCVVToCache';
import { updateExpDate } from '../../api/updateExpDate';
import { PAN_KEEPER_TYPES, PAN_TYPE } from '../../constants';

export const usePanKeeper = ({ initialPanKeeperType, isNewApi }) => {
  const [{ activeServiceId }] = useContext(SWPaymentsContext);
  const { t } = useI18n();
  const [user] = useContext(UserContext);
  const { id: userId, partnerId: accountingId } = user;
  const [ssid] = useState(getCookie(COOKIES_KEYS.PHPSESSID, null));

  const [isLoading, setIsLoading] = useState(true);
  const [isCardInputReady, setIsCardInputReady] = useState(false);

  const [globalError, setGlobalError] = useState('');
  const [localError, setLocalError] = useState('');

  const setError = useCallback(
    ({ code }) => {
      if ([101, -3].includes(code)) {
        logAction(LOG_ACTIONS.PANKEEPER_LOCAL_ERROR);
        setLocalError(t(`panKeeper.error.${code}`));
      } else {
        logAction(LOG_ACTIONS.PANKEEPER_GLOBAL_ERROR);
        setGlobalError(t(`panKeeper.error.${code || 'internal'}`));
      }
    },
    [t]
  );
  const hideGlobalError = useCallback(() => {
    setGlobalError('');
    setIsCardInputReady(true);
  }, []);

  const [panKeeperType, setPanKeeperType] = useState(initialPanKeeperType);

  const [activeCard, setActCard] = useState(null);
  const setActiveCard = useCallback((card) => {
    const { cardHash = '', expMonth } = card || {};
    const date = expMonth ? getExpDate(card) : {};
    setActCard(card ? { ...card, ...date } : null);
    setLocalStorageValue(LOCAL_STORAGE_KEYS.CARD_HASH_KEY, cardHash);
  }, []);

  const [cardsList, setCardsList] = useState([]);
  const isCardDropdownReadyInitState =
    panKeeperType === PAN_KEEPER_TYPES.ADD || !cardsList;
  const [isCardDropdownReady, setIsCardDropdownReady] = useState(
    isCardDropdownReadyInitState
  );

  const [editCard, setEditCard] = useState({});

  const fetchAllPans = useCallback(
    async (type) => {
      try {
        const getCardList = isNewApi ? getAllCards : getAllPans;
        const { response = [] } = await getCardList({
          accountingId,
          ssid,
          userId
        });
        const processedResponse = response.map((item) => {
          const { type: cardType, status, cardStatus } = item;
          let processedItem = {
            ...item,
            hasNtf: status.docRequested
          };
          if (cardType === PAN_TYPE.WALLET && !cardStatus) {
            processedItem = {
              ...processedItem,
              cardStatus: findCardStatus(status)
            };
          }
          return processedItem;
        });

        let cardList = [];
        setCardsList(cardList);

        if (!response.length) {
          setPanKeeperType(PAN_KEEPER_TYPES.ADD);
        } else if (type === PAN_KEEPER_TYPES.WITHDRAW) {
          cardList = processedResponse.filter(({ cardStatus }) =>
            withdrawAllowed(cardStatus)
          );
        } else {
          cardList = processedResponse;
          setPanKeeperType(PAN_KEEPER_TYPES.DEPOSIT);
        }

        setCardsList(cardList);

        if (activeCard) {
          setActiveCard(
            cardList.find(({ cardHash, cardNumber }) =>
              cardHash
                ? cardHash === activeCard?.cardHash
                : cardNumber === activeCard?.cardNumber
            )
          );
        }
      } catch ({ error = {} }) {
        logAction(LOG_ACTIONS.PANKEEPER_GET_ALL_PANS_FAILED, {
          error
        });
        setError(error);
      }
    },
    [isNewApi, accountingId, ssid, userId, activeCard, setActiveCard, setError]
  );

  const saveDescription = useCallback(
    async (description) => {
      if (activeCard) {
        try {
          await updateDescription({
            userId,
            accountingId,
            ssid,
            cardHash: activeCard?.cardHash,
            description
          });
          await fetchAllPans(panKeeperType);
        } catch ({ error = {} }) {
          setError(error);
          logAction(LOG_ACTIONS.PANKEEPER_SAVE_DESCRIPTION_FAILED, { error });
          console.error(error);
        }
      }
    },
    [
      userId,
      accountingId,
      ssid,
      activeCard,
      fetchAllPans,
      panKeeperType,
      setError
    ]
  );

  const deleteCard = useCallback(async () => {
    if (activeCard) {
      try {
        await deletePan({
          userId,
          accountingId,
          ssid,
          cardHash: activeCard?.cardHash
        });
        setLocalStorageValue(LOCAL_STORAGE_KEYS.CARD_HASH_KEY, '');
        await fetchAllPans(panKeeperType);
      } catch ({ error = {} }) {
        setError(error);
        logAction(LOG_ACTIONS.PANKEEPER_DELETE_CARD_FAILED, {
          error
        });
        console.error(error);
      }
    }
  }, [
    userId,
    accountingId,
    ssid,
    activeCard,
    fetchAllPans,
    panKeeperType,
    setError
  ]);

  const getCardHash = useCallback(async () => {
    try {
      const { cardNumber, expiryDate, cardHolder, cvv, description } = editCard;

      if (panKeeperType === PAN_KEEPER_TYPES.ADD) {
        const save = isNewApi ? saveCard : savePan;
        const { response = {} } = await save({
          userId: `${userId}`,
          accountingId,
          ssid,
          cardNumber,
          ...getExpMonthYear(expiryDate),
          cardHolder,
          cardDescription: description,
          isNewCard: true,
          isDeposit: true,
          serviceId: activeServiceId
        });
        const { cardHash } = response;
        await putCVVToCache({ cardHash, userId, accountingId, cvv, ssid });

        await fetchAllPans(panKeeperType);
        logAction(LOG_ACTIONS.PANKEEPER_ADD_CARD_SUCCESS);
        return { cardHash };
      }

      const { cardHash, type, cardNumber: panNumber } = activeCard || {};

      if (!cardHash && type === PAN_TYPE.WALLET) {
        const { response = {} } = await saveCard({
          userId: `${userId}`,
          cardNumber: panNumber,
          ...getExpMonthYear(expiryDate),
          cardHolder,
          cardDescription: description,
          serviceId: activeServiceId
        });
        const { cardHash: panHash } = response;

        if (panKeeperType !== PAN_KEEPER_TYPES.WITHDRAW) {
          await putCVVToCache({
            cardHash: panHash,
            userId,
            accountingId,
            cvv,
            ssid
          });
        }

        await fetchAllPans(panKeeperType);
        logAction(LOG_ACTIONS.PANKEEPER_ADD_CARD_SUCCESS);
        return { cardHash: panHash };
      }

      if (panKeeperType === PAN_KEEPER_TYPES.DEPOSIT) {
        await updateExpDate({
          cardHash,
          userId,
          accountingId,
          ssid,
          ...getExpMonthYear(expiryDate)
        });
        await fetchAllPans(panKeeperType);
        await putCVVToCache({ cardHash, userId, accountingId, cvv, ssid });

        return { cardHash };
      }

      if (panKeeperType === PAN_KEEPER_TYPES.WITHDRAW) {
        return { cardHash };
      }
    } catch ({ error = {} }) {
      setError(error);
      logAction(LOG_ACTIONS.PANKEEPER_ADD_CARD_FAILED, {
        error
      });
      throw new Error(error);
    }

    return new Error(`Error: invalid type / subType.`);
  }, [
    editCard,
    panKeeperType,
    activeCard,
    isNewApi,
    userId,
    accountingId,
    ssid,
    fetchAllPans,
    setError
  ]);

  useEffect(() => {
    (async () => {
      if (isLoading) {
        await fetchAllPans(initialPanKeeperType);
        setIsLoading(false);
      }
    })();
  }, [initialPanKeeperType, isLoading, fetchAllPans]);

  useEffect(() => {
    if (globalError) {
      setIsCardInputReady(false);
    }
  }, [globalError]);

  useEffect(() => {
    if (!activeCard) {
      setEditCard({});
    }
  }, [activeCard]);

  useEffect(() => {
    if (panKeeperType === PAN_KEEPER_TYPES.ADD) {
      setActiveCard(null);
    } else if (cardsList.length && !activeCard) {
      const lastCardHash = getLocalStorageValue(
        LOCAL_STORAGE_KEYS.CARD_HASH_KEY,
        ''
      );
      const lastCard = cardsList.find(
        ({ cardHash }) => cardHash === lastCardHash
      );
      const card = lastCard || cardsList[0] || null;

      setActiveCard(card);
    }
  }, [panKeeperType, activeCard, cardsList, setActiveCard]);

  return {
    getCardHash,
    isLoading,

    isCardInputReady,
    setIsCardInputReady,

    isCardDropdownReady,
    setIsCardDropdownReady,

    panKeeperType,
    setPanKeeperType,
    activeCard,
    setActiveCard,
    cardsList,
    setCardsList,

    editCard,
    setEditCard,

    saveDescription,

    deleteCard,

    globalError,
    localError,
    setLocalError,
    setGlobalError,
    hideGlobalError
  };
};
