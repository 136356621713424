import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const useDynamicField = ({ name, dynamicFieldData }) => {
  const { setValue } = useFormContext();

  useEffect(() => {
    if (dynamicFieldData !== undefined) {
      setValue(name, dynamicFieldData);
    }
  }, [setValue, dynamicFieldData, name]);
};

export default useDynamicField;
