export const DEFAULT_ACTIVE_TAG = 'all';
export const RECENTLY_ITEM_WIDTH = 300;
export const SIMILAR_ITEM_WIDTH = 400;
export const PROMO_TIMER_TYPES = {
  BEGIN: 'begin',
  END: 'end',
  FINISHED: 'finished'
};

export const PROMOTION_ITEM_TYPES = {
  PROMOTION: 'promotion',
  TOURNAMENT: 'tournament'
};

export const PROMO_TYPES = {
  promotion: 'promotions',
  tournament: 'tournaments'
};

export const SCHEDULING_TYPE = {
  DAY: 'day',
  WEEK: 'week'
};

// TODO: use only one type on constant everywhere - PROMOTION_ITEM_TYPES
// then this might be removed
export const PROMOTION_ITEM_TYPE_TO_PROMO_TYPE = {
  [PROMOTION_ITEM_TYPES.PROMOTION]: PROMO_TYPES.promotion,
  [PROMOTION_ITEM_TYPES.TOURNAMENT]: PROMO_TYPES.tournament
};

export const PROMO_TAGS = {
  CASINO: 'casino',
  SPORT: 'sport',
  NEW: 'new'
};

export const DEFAULT_PROMO_TYPE = PROMO_TYPES.promotion;

export const DEFAULT_PROMO_TAGS = [
  {
    tagBackground: 'var(--accent_color_3)',
    tagId: PROMO_TAGS.CASINO
  },
  {
    tagBackground: 'var(--accent_color_1)',
    tagId: PROMO_TAGS.SPORT
  },
  {
    tagId: 'youAreIn',
    tagBackground: 'transparent',
    tagColor: 'state_success',
    tagFontSize: 's',
    tagIcon: 'icons/general/state/success'
  }
];

export const TOURNAMENT_LEADERBOARD_TOP_PLAYERS_LIMIT = 10;
