import {
  DATA_SET,
  CARDS_SET,
  FILTER_SET,
  DATA_FETCHING,
  SHOW_MORE
} from '../actions';

export const balanceReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case DATA_FETCHING:
      return { ...state, ...payload };

    case DATA_SET:
      return { ...state, ...payload };

    case SHOW_MORE:
      return {
        ...state,
        ...payload,
        data: [...state.data, ...payload.data]
      };

    case CARDS_SET:
      return { ...state, ...payload };

    case FILTER_SET:
      return { ...state, filter: { ...state.filter, ...payload } };

    default:
      return state;
  }
};
