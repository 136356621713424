import { useSetJackpot } from '../../../../hooks/useSetJackpot';
import { JACKPOT_TYPE_TO_UPDATE_INTERVAL } from './constants';

// odometer lib cuts zeros in cents in the end, so should always have 1 as last character
// TODO: need to think about better solution
export const formatTotal = (num) => (num || 0).toFixed(2).replace(/0$/, '1');

export const useSetInHouseJackpot = ({
  jackpotType,
  amount,
  amountCoef,
  refreshRate
}) => {
  const maxAmountValue = amount * amountCoef;
  const inHouseJackpotDelayS = JACKPOT_TYPE_TO_UPDATE_INTERVAL[jackpotType];
  const initialValue = amountCoef < 1 ? maxAmountValue : amount;

  const amountTextRef = useSetJackpot({
    initialValue,
    step:
      amountCoef < 1
        ? 0
        : (maxAmountValue - amount) / (refreshRate / inHouseJackpotDelayS),
    delay: inHouseJackpotDelayS * 1000,
    maxValue: maxAmountValue,
    formatTotal,
    isSkipImmediateSetOnUpdate: true,
    isAddSpacesToJackpotAmount: false
  });

  return { amountTextRef, initialValue: formatTotal(initialValue) };
};
