import { useCallback, useContext } from 'react';
import { useI18n } from '@namespace/i18n';
import { Box } from '@alf/uikit';
import { StepperContext } from '@namespace/common';
import EnterEmail from '../EnterEmail';
import CancelButton from '../CancelButton';
import { getSecretQuestion } from '../../api';
import styles from './index.module.css';

const SendCodeToEmail = ({ onStepSuccess, onStepError, onGoFirst }) => {
  const { language } = useI18n();
  const {
    stepper: {
      formData: { email = '' },
      errorCode = ''
    }
  } = useContext(StepperContext);

  const handleSubmit = useCallback(
    async ({ email: userEmail, captcha }) => {
      await getSecretQuestion({
        email: userEmail,
        cap: captcha,
        lang: language
      })
        .then(({ response }) =>
          onStepSuccess({ ...response, email: userEmail })
        )
        .catch(({ errorCode: stepErrorCode }) =>
          onStepError({ errorCode: stepErrorCode })
        );
    },
    [language, onStepSuccess, onStepError]
  );

  return (
    <Box direction="column" className={styles.step}>
      <EnterEmail
        email={email}
        withCaptcha={true}
        onSuccess={handleSubmit}
        errorCode={errorCode}
      />
      <CancelButton className={styles.backButton} onCancel={onGoFirst} />
    </Box>
  );
};

export default SendCodeToEmail;
