import { useContext } from 'react';
import { SiteSettingsContext } from '@namespace/cms';
import NotificationBanner from '../../common/NotificationBanner';

const CookieNotification = () => {
  const [settings = {}] = useContext(SiteSettingsContext);

  const { cookieNotification = {} } = settings;
  const { snippetId, expires } = cookieNotification;

  return <NotificationBanner snippetId={snippetId} expires={expires} />;
};

export default CookieNotification;
