import { MODE } from '../../constants';

const buildRequest = (eventsIds, eventsMarketsIds, hash) =>
  eventsIds.reduce(
    (memo, eventId) => ({
      [hash]: {
        ...memo[hash],
        [eventId]: {
          result: {},
          markets:
            eventsMarketsIds[eventId] !== null
              ? { id: eventsMarketsIds[eventId]?.map(String) || [] }
              : {}
        }
      }
    }),
    {}
  );

export const buildLineRequest = ({
  events,
  liveHash,
  hash,
  allMarketsByEventIds
}) => {
  const { preMatchEventsIds, liveEventsIds } = Object.keys(
    allMarketsByEventIds
  ).reduce(
    (memo, id) => {
      const eventId = Number(id);
      return {
        preMatchEventsIds:
          events[eventId]?.serviceId === MODE.PRE_MATCH
            ? [...memo.preMatchEventsIds, eventId]
            : memo.preMatchEventsIds,
        liveEventsIds:
          events[eventId]?.serviceId === MODE.IN_PLAY
            ? [...memo.liveEventsIds, eventId]
            : memo.liveEventsIds
      };
    },
    {
      liveEventsIds: [],
      preMatchEventsIds: []
    }
  );

  return {
    subscribe_multiline: [
      preMatchEventsIds.length
        ? buildRequest(preMatchEventsIds, allMarketsByEventIds, hash)
        : null,
      liveEventsIds.length
        ? buildRequest(liveEventsIds, allMarketsByEventIds, liveHash)
        : null
    ].filter(Boolean)
  };
};
