import { useMemo, memo } from 'react';
import {
  getDetailedScoresForTable,
  getEventValues,
  stripeScore
} from '../../utils';
import {
  SPORTS_WITH_ALL_PERIODS,
  CYBERSPORT_SPORTFORMS
} from '../../constants';
import { COLUMN_TYPES, SCOPE_IDS } from '../../../../constants';
import ScoreDetails from '../../Details';

const Cybersport = ({
  byResultType,
  total,
  sportId,
  eventResultId,
  sportformId,
  isShortVersion,
  lineView
}) => {
  const { totalKey = SCOPE_IDS.WINNER, defaultKey = SCOPE_IDS.KILLS } =
    CYBERSPORT_SPORTFORMS.find(({ sportformIds }) =>
      sportformIds.includes(sportformId)
    ) || {};

  const formattedScoreByPeriod = useMemo(
    () =>
      stripeScore(
        getDetailedScoresForTable(byResultType, defaultKey),
        sportId,
        eventResultId,
        SPORTS_WITH_ALL_PERIODS.includes(sportId)
      ),
    [byResultType, defaultKey, eventResultId, sportId]
  );

  const scoreByPeriod = useMemo(
    () =>
      isShortVersion
        ? [
            {
              type: COLUMN_TYPES.DEFAULT,
              tag: '',
              scores: formattedScoreByPeriod.find(
                ({ resultTypeId }) => resultTypeId === eventResultId
              )?.scores
            }
          ]
        : formattedScoreByPeriod,
    [eventResultId, formattedScoreByPeriod, isShortVersion]
  );

  const fullFormattedScore = useMemo(
    () => [
      {
        type: COLUMN_TYPES.TOTAL,
        tag: '',
        scores: getEventValues(total, totalKey)
      },
      ...scoreByPeriod
    ],
    [scoreByPeriod, total, totalKey]
  );

  return (
    <ScoreDetails
      scoreData={fullFormattedScore}
      isShortVersion={isShortVersion}
      lineView={lineView}
    />
  );
};

export default memo(Cybersport);
