import { useCallback, useContext, useMemo } from 'react';
import { Box, DatePicker, Select } from '@alf/uikit';
import { isBetradar as checkIsBetradar } from '@namespace/virtual-sports';
import { DeviceContext } from '@namespace/device';
import { IS_WIDGET } from '@namespace/widget';
import { useI18n } from '@namespace/i18n';
import { COLOR_SCHEME } from '@namespace/themes';
import styles from './index.module.css';

const formatOptions = (options = []) =>
  options.map(({ id, name }) => ({ value: id, title: name }));

const Filters = ({
  date,
  sport = {},
  sportsNames = [],
  category = {},
  categories = [],
  tournament = {},
  tournaments = [],
  onDateChange,
  onSportChange,
  onCategoryChange,
  onTournamentChange,
  provider,
  filterClassNames = {}
}) => {
  const {
    filtersContainer = '',
    datePickerWrapper = '',
    datePickerClassName = ''
  } = filterClassNames;
  const { f, timezone } = useI18n();
  const DATE_FORMATS_TYPES = f.getFormats();

  const { isDesktop, isDesktopDevice } = useContext(DeviceContext);

  const sportsOptions = formatOptions(sportsNames);
  const categoriesOptions = formatOptions(categories);
  const tournamentsOptions = formatOptions(tournaments);
  const isBetradar = checkIsBetradar(provider);

  const handleDateChange = useCallback(
    (newDate) =>
      onDateChange(
        f
          .getDateTime({ date: newDate, withTZ: false })
          .toFormat(
            provider ? DATE_FORMATS_TYPES.API_WITH_TIME : DATE_FORMATS_TYPES.API
          )
      ),
    [timezone, onDateChange, DATE_FORMATS_TYPES.API_WITH_TIME]
  );

  const handleSelectChange = (onChange, options) => ({ value: optionsId }) => {
    const { value: id, title: name } = options.find(
      ({ value: optionsValue }) =>
        optionsValue.toString() === optionsId.toString()
    );
    onChange({ id, name });
  };

  const sportsValue = useMemo(
    () => sportsOptions.find((option) => option.value === sport.id),
    [sport, sportsOptions]
  );

  const categoriesValue = useMemo(
    () => categoriesOptions.find((option) => option.value === category.id),
    [category, categoriesOptions]
  );

  return (
    <Box className={`${styles.filtersContainer} ${filtersContainer}`}>
      <Box
        className={`${styles.datePickerWrapper} ${datePickerWrapper}`}
        data-role="results-filters-date"
      >
        <DatePicker
          date={f
            .getDateTime({
              date,
              format: provider
                ? DATE_FORMATS_TYPES.API_WITH_TIME
                : DATE_FORMATS_TYPES.API,
              withTZ: false
            })
            .toJSDate()}
          onChange={handleDateChange}
          disabledSuccess={true}
          variant={IS_WIDGET ? 'inline' : 'dialog'}
          inputClassName={`${styles.datePickerClassName} ${datePickerClassName}`}
          format={DATE_FORMATS_TYPES.dd_MM_yyyy}
          maxDate={f.getDateTime().toJSDate()}
          intentType={COLOR_SCHEME.SECONDARY}
        />
      </Box>
      {sportsValue?.title && (
        <Box className={styles.filterSelectWrapperS}>
          <Select
            options={sportsOptions}
            size="s"
            className={styles.select}
            onChange={handleSelectChange(onSportChange, sportsOptions)}
            value={sport.id}
            isDesktop={isDesktop}
            isDesktopDevice={isDesktopDevice}
            intentType={COLOR_SCHEME.SECONDARY}
            dataRole="results-filters-sports"
          />
        </Box>
      )}
      {categoriesValue?.title && (
        <Box
          className={
            sportsValue?.title
              ? styles.filterSelectWrapperM
              : styles.filterSelectWrapperS
          }
        >
          <Select
            view="select"
            size="s"
            options={categoriesOptions}
            onChange={handleSelectChange(onCategoryChange, categoriesOptions)}
            value={category.id}
            disabled={sport.id === 0 || categoriesOptions.length <= 1}
            isDesktop={isDesktop}
            isDesktopDevice={isDesktopDevice}
            intentType={COLOR_SCHEME.SECONDARY}
            dataRole="results-filters-categories"
          />
        </Box>
      )}

      {!isBetradar && (
        <Box className={styles.filterSelectWrapperM}>
          <Select
            options={tournamentsOptions}
            size="s"
            className={styles.select}
            onChange={handleSelectChange(
              onTournamentChange,
              tournamentsOptions
            )}
            value={tournament.id}
            disabled={category.id === 0 || tournamentsOptions.length <= 1}
            isDesktop={isDesktop}
            isDesktopDevice={isDesktopDevice}
            intentType={COLOR_SCHEME.SECONDARY}
            dataRole="results-filters-tournaments"
          />
        </Box>
      )}
    </Box>
  );
};

export default Filters;
