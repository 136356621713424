import { useCallback, useEffect } from 'react';
import { useGeneralRoutes, useOtherSettings } from '@namespace/cms';
import {
  SESSION_STORAGE_KEYS,
  getSessionStorageValue,
  window
} from '@namespace/helpers';
import { useHistory } from '@namespace/router';

export const useGoPrevPageBeforeDep = () => {
  const { login, deposit, home, registerSuccess } = useGeneralRoutes();
  const { push } = useHistory();
  const { isEnableDepositSourceURL = false } = useOtherSettings();

  const defaultOnClose = useCallback(() => {
    push(home);
  }, [push, home]);

  const prevDepPath = getSessionStorageValue(
    SESSION_STORAGE_KEYS.PREV_PAGE_BEFORE_DEPOSIT,
    home
  );

  const onBackButtonEvent = useCallback(() => {
    const isDepOrAuthPage = [login, registerSuccess, deposit].some((page) =>
      prevDepPath?.includes(page)
    );

    push(isDepOrAuthPage ? home : prevDepPath);
  }, [push, login, registerSuccess, deposit, home, prevDepPath]);

  useEffect(() => {
    window.getReturnPath = onBackButtonEvent;
  }, [onBackButtonEvent]);

  return isEnableDepositSourceURL ? onBackButtonEvent : defaultOnClose;
};
