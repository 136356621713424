import { useState, useEffect } from 'react';
import useInterval from '../useInterval';

const useCountdown = (time) => {
  const [currentTime, setCurrentTime] = useState(time);
  const [isCompleted, setIsCompleted] = useState(false);
  const [currentDays, setCurrentDays] = useState('');
  const [currentHours, setCurrentHours] = useState('');
  const [currentMinutes, setCurrentMinutes] = useState('');
  const [currentSeconds, setCurrentSeconds] = useState('');

  useInterval(
    () => {
      setCurrentTime((prevState) => prevState - 1000);
    },
    !isCompleted ? 1000 : null
  );

  useEffect(() => {
    if (time) {
      setCurrentTime(time);
    }
  }, [time]);

  useEffect(() => {
    if (currentTime > 0) {
      const days = Math.floor(currentTime / (1000 * 60 * 60 * 24));
      const hours = Math.floor((currentTime / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((currentTime / (1000 * 60)) % 60);
      const seconds = Math.floor((currentTime / 1000) % 60);

      setCurrentDays(days < 10 ? `0${days}` : days);
      setCurrentHours(hours < 10 ? `0${hours}` : hours);
      setCurrentMinutes(minutes < 10 ? `0${minutes}` : minutes);
      setCurrentSeconds(seconds < 10 ? `0${seconds}` : seconds);
    } else {
      setCurrentDays('00');
      setCurrentHours('00');
      setCurrentMinutes('00');
      setCurrentSeconds('00');
      setIsCompleted(true);
    }
  }, [currentTime]);

  return {
    isCompleted,
    days: currentDays,
    hours: currentHours,
    minutes: currentMinutes,
    seconds: currentSeconds
  };
};

export default useCountdown;
