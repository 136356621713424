import { post } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

export const getSegmentsList = () =>
  post('/service/segmentation/v1/user/segment/get-list')
    .then(camelizeKeys)
    .then(({ data, status }) => (status === 'success' ? data : {}))
    .catch((error) => {
      console.error('Failed to fetch AB segments:', error);
    });
