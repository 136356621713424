import { useEffect } from 'react';
import { pathOr } from 'ramda';
import { useI18n } from '@namespace/i18n';
import { useConfig, useGeneralRoutes } from '@namespace/cms';
import { PrivateContentLoader } from '@namespace/user';
import { useRouteOptionalParams, useHistory } from '@namespace/router';
import {
  selectEventsIdsBySportId,
  sportsbookActions,
  useSportsbookStore
} from '../../store';
import { LINE_KEY, MODE, SPORT_IDS } from '../../constants';
import { useActiveHeadGroups } from '../../hooks/useActiveHeadGroups';
import { useRemoveLine } from '../../hooks/useRemoveLine';
import {
  EventsLineHeader,
  EventsLineHeadGroups,
  EventsLineTitle,
  FavoritesEmptyState,
  Line,
  WidgetWrapper
} from '../../common';
import styles from './index.module.css';

const { FETCH_LIST_OF_EVENTS, SET_EVENTS } = sportsbookActions;

const CyberSportFavoriteEvents = ({ id, serviceId, isLiveEvents }) => {
  const sportId = SPORT_IDS.CYBERSPORT;
  const isFavoritesLoaded = useSportsbookStore('isFavoritesLoaded');
  const favoriteEvents = useSportsbookStore(
    pathOr([], ['favorites', 'events'])
  );
  const { t, language: lang } = useI18n();
  const { replace } = useHistory();
  const { cyberSportFavorites } = useGeneralRoutes();

  const { config } = useConfig(id);
  const {
    isUserGroupsEnabled,
    isShowEventGroup,
    groupsAmount,
    isDisplayHeadMarkets = true
  } = config;

  const lineKey = `${LINE_KEY.CYBERSPORT_FAVORITES}-${serviceId}`;

  const { optional = [] } = useRouteOptionalParams(`${cyberSportFavorites}/*`);

  const eventsBySportId = useSportsbookStore(selectEventsIdsBySportId(lineKey));
  const cyberSportEventsIds = eventsBySportId[sportId] || {};

  const [activeHeadGroups, setActiveHeadGroups] = useActiveHeadGroups(
    isUserGroupsEnabled,
    groupsAmount,
    sportId,
    serviceId
  );

  useEffect(() => {
    if (favoriteEvents.length) {
      FETCH_LIST_OF_EVENTS({
        eventIds: favoriteEvents,
        lang,
        serviceId,
        lineKey
      });
    } else {
      SET_EVENTS({ events: [], lineKey });
    }
  }, [lang, favoriteEvents.join(''), serviceId, lineKey]);

  const sportName = t('sportsbook.favorites.cybersport');

  useRemoveLine(lineKey);

  const isEmptyVisible = !cyberSportEventsIds.length;

  useEffect(() => {
    if (optional.length > 0) {
      replace(cyberSportFavorites);
    }
  }, []);

  return (
    <WidgetWrapper className={styles.widget}>
      <EventsLineHeader borderBottom={true}>
        <EventsLineTitle
          title={sportName}
          sportId={sportId}
          subtitle={serviceId === MODE.IN_PLAY ? 'Live' : null}
          classNames={{
            subtitleClassName: styles.live
          }}
        />
        {!isEmptyVisible && (
          <EventsLineHeadGroups
            sport={{ sportId }}
            isHeadGroupsSortingEnabled={true}
            activeHeadGroups={activeHeadGroups}
            setActiveHeadGroups={setActiveHeadGroups}
            isDisplayHeadMarkets={isDisplayHeadMarkets}
          />
        )}
      </EventsLineHeader>
      <PrivateContentLoader classNames={styles.userLoader}>
        {isEmptyVisible ? (
          <FavoritesEmptyState
            serviceId={serviceId}
            isBackHeaderVisible={false}
          />
        ) : (
          <Line
            id={id}
            eventsToShow={cyberSportEventsIds}
            isShowEventGroup={isShowEventGroup}
            sportSlug={sportName}
            activeHeadGroups={activeHeadGroups}
            isCategoriesExpanded={true}
            lineKey={lineKey}
            isEventsLoading={!isFavoritesLoaded}
            isLiveEvents={isLiveEvents}
          />
        )}
      </PrivateContentLoader>
    </WidgetWrapper>
  );
};

export default CyberSportFavoriteEvents;
