import { useCallback, useContext, useEffect, memo } from 'react';
import { DeviceContext } from '@namespace/device';
import { useSearchParams } from '@namespace/router';
import { useConfig } from '@namespace/cms';
import { Search } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { useColorScheme } from '@namespace/themes';
import { OnboardingContext } from '@namespace/onboarding';
import { SearchResults } from './SearchResults';
import { useSearch } from './hooks/useSearch';
import { TYPES } from './constants';
import SearchLabel from './SearchLabel';
import styles from './index.module.css';

const SportsBookSearch = ({ id, onClick = null }) => {
  const { config = {} } = useConfig(id);
  const { t } = useI18n();
  const { isAutofocus = false, isPopup = true } = config;
  const { isDesktop } = useContext(DeviceContext);
  const [{ isRunning: isOnboardingRunning }] = useContext(OnboardingContext);
  const { setQuery, query: searchQuery, searchResults, isSearched } = useSearch(
    TYPES.ALL
  );

  const { sportsSearch } = useSearchParams();
  const { colorScheme } = useColorScheme();
  const { selectedColorMode } = colorScheme;

  useEffect(() => {
    if (sportsSearch) {
      setQuery(sportsSearch);
    }
  }, [sportsSearch, selectedColorMode]);

  const onSearchClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  const Results = (props) => {
    return (
      <>
        {searchResults && isSearched && (
          <SearchResults events={searchResults} {...props} />
        )}
        {(!searchQuery || !isSearched) && !isDesktop && isAutofocus && (
          <SearchLabel />
        )}
      </>
    );
  };

  return (
    <div
      data-role="c-sportsBookSearch"
      className={styles.container}
      onClick={onSearchClick}
    >
      <Search
        setQuery={setQuery}
        query={searchQuery || ''}
        minQueryLength={0}
        isAutofocus={isAutofocus}
        result={isPopup ? <Results /> : <div />}
        haveToCloseOutsideClick={false}
        placeholder={t('sportsbooksearch.searchplaceholder')}
        classNames={{
          resultsClass: styles.results
        }}
        disabled={isOnboardingRunning}
      />
      {!isPopup && <Results query={searchQuery || ''} />}
    </div>
  );
};

export default memo(SportsBookSearch);
