import {
  SET_FILTER,
  CHANGE_FILTER,
  COUNT_CHANGES,
  SET_INITIAL_FILTER
} from '../actions';

export const reducer = (state, action) => {
  switch (action.type) {
    case SET_FILTER:
      return {
        ...state,
        appliedFilters: { ...state.appliedFilters, ...action.payload }
      };
    case CHANGE_FILTER:
      return {
        ...state,
        changedFilters: { ...state.changedFilters, ...action.payload }
      };
    case SET_INITIAL_FILTER:
      return {
        ...state,
        changedFilters: { ...action.payload },
        appliedFilters: { ...action.payload }
      };
    case COUNT_CHANGES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
