import { FILTER_ALL_VALUE } from '@namespace/common';
import { camelizeKeys } from 'humps';
import { isUndefined } from 'lodash';

import formatSum from '../../utils/formatSum';

const { assign } = Object;

const getBetsHistory = async (
  {
    startDate,
    endDate,
    startDateDone,
    endDateDone,
    page = 1,
    resultStatus,
    cardType,
    cardIn,
    cardOut,
    services = []
  },
  socketSend
) => {
  const params = { page };

  if (startDate && endDate) {
    assign(params, {
      dt_start: startDate,
      dt_end: endDate
    });
  }

  if (startDateDone && endDateDone) {
    assign(params, {
      dt_done_start: startDateDone,
      dt_done_end: endDateDone
    });
  }

  if (!isUndefined(resultStatus) && resultStatus !== FILTER_ALL_VALUE) {
    params.result_status = resultStatus;
  }

  if (!isUndefined(cardType) && cardType !== FILTER_ALL_VALUE) {
    params.cardcontainer_cc_type =
      cardType.toString() === '3' ? [3, 4] : Number(cardType); // for systems & multisystems
  }

  if (!isUndefined(cardIn) && cardIn !== FILTER_ALL_VALUE) {
    assign(params, formatSum('in', cardIn));
  }

  if (!isUndefined(cardOut) && cardOut !== FILTER_ALL_VALUE) {
    assign(params, formatSum('out', cardOut));
  }

  params.service_id = services;

  const result = await socketSend({
    cmd: 'bets_history/cardshistory',
    data: {
      params,
      filters: {
        extra: true
      }
    }
  });

  return camelizeKeys(result.data);
};

export default getBetsHistory;
