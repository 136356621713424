import { useContext, useEffect } from 'react';
import { USER_ROLES, UserContext } from '@namespace/user';
import { GamesContext } from '../../context';
import { useCasinoSettings } from '../useCasinoSettings';

export const useLoadFavoritesList = (casinoType) => {
  const [gamesState, { GET_FAVORITES_LIST }] = useContext(GamesContext);
  const { favoritesRequestEntityName } = useCasinoSettings(casinoType);
  const favoritesIsLoaded =
    gamesState.favorites[favoritesRequestEntityName]?.isLoaded;
  const [user] = useContext(UserContext);
  const { role } = user;

  useEffect(() => {
    if (role === USER_ROLES.USER && !favoritesIsLoaded) {
      GET_FAVORITES_LIST(favoritesRequestEntityName);
    }
  }, [role, favoritesIsLoaded, favoritesRequestEntityName]);
};
