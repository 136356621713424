import { getCricketScore } from './utils';
import { CricketTemplate } from '../../common/CricketTemplate';
import { CRICKET_TOTAL_SPORTFORM_IDS } from '../../../../constants';
import { useEventFields } from '../../../../store';

const Cricket = ({
  eventResultId,
  sportformId,
  byResultType,
  total,
  eventId,
  isShortVersion,
  lineView
}) => {
  const { eventServing, participants } = useEventFields({
    eventId,
    fields: ['eventServing', 'participants']
  });

  const fullScore = getCricketScore({
    byResultType,
    eventResultId,
    eventServing,
    participants,
    sportFormId: sportformId,
    total
  });

  return (
    <CricketTemplate
      fullScore={fullScore}
      isShortVersion={isShortVersion}
      lineView={lineView}
      isTotalScore={CRICKET_TOTAL_SPORTFORM_IDS.includes(sportformId)}
    />
  );
};

export default Cricket;
