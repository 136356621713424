import { checkPhone } from '../../../api';

function minLengthAndCheckPhone({ length, value, message }) {
  const isLengthValid = value.length >= length;

  if (isLengthValid) {
    return checkPhone(value);
  }

  const error = new Error(message);

  error.displayMessage = message;

  throw error;
}

export default minLengthAndCheckPhone;
