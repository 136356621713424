import { AText } from '@alf/uikit';
import styles from './index.module.css';

const DocRequestedMsg = ({ link, email, msg }) => (
  <>
    {msg}
    <AText
      breed="R"
      size="s"
      component="a"
      color="txt_link"
      href={link}
      className={styles.link}
    >
      {email}
    </AText>
  </>
);

export default DocRequestedMsg;
