import { useContext, useEffect, useRef } from 'react';
import { uniq } from 'lodash';

import { JackpotsContext } from '../../jackpotsContext';

export const useLoadInHouseJackpotWinnersInfo = (winnersGamesLaunchIds) => {
  const alreadyBeenRequestedIdSet = useRef(new Set());
  const { current: ref } = alreadyBeenRequestedIdSet;
  const [{ games }, { GET_IN_HOUSE_JACKPOT_WINNERS_GAMES }] = useContext(
    JackpotsContext
  );
  const winnersGamesLaunchIdsDependency = winnersGamesLaunchIds.join('');

  const getIsGameLoaded = (winnerGameLaunchId) => {
    return Boolean(games[winnerGameLaunchId]);
  };

  useEffect(() => {
    const gamesToLoad = [];

    for (const winnerGameLaunchId of winnersGamesLaunchIds) {
      if (
        !getIsGameLoaded(winnerGameLaunchId) &&
        !ref.has(winnerGameLaunchId)
      ) {
        ref.add(winnerGameLaunchId);
        gamesToLoad.push(winnerGameLaunchId);
      }
    }

    if (gamesToLoad.length)
      GET_IN_HOUSE_JACKPOT_WINNERS_GAMES(uniq(gamesToLoad));
  }, [winnersGamesLaunchIdsDependency]);
};
