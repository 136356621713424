import { useContext } from 'react';
import { I18nContext } from '@namespace/i18n/src/context';
import { MOCK_LANG_BY_APP_LANG } from '../../mocks';
import { actionsProxy } from '../../utils/actionsProxy';
import { OnboardingContext } from '../../store/context';
import { useCurrentStep } from '..';
import { normalizeMock } from './normalizeMock';
import { overrideMockedState } from './overrideMockedState';

// Turn off default mock via explicit `false` or `null` value in step config.
// `undefined` means "not configured" so we use default mock.
export const isDefaultMockTurnedOffByStep = (stepStoreMock) =>
  stepStoreMock === null || stepStoreMock === false;

export const useGlobalMockedStore = (
  storeName,
  originalState,
  originalActions
) => {
  const [{ language }] = useContext(I18nContext);
  const [
    {
      isRunning,
      mocks: defaultMocks,
      snapshots = {},
      isSnapshotsLoaded,
      preferredCurrency
    }
  ] = useContext(OnboardingContext);
  const { mocks: stepMocks = {} } = useCurrentStep();
  const stepStoreMock = normalizeMock(stepMocks[storeName]);
  const defaultStoreMock = normalizeMock(defaultMocks[storeName]);
  const storeSnapshots = snapshots[storeName];

  if (
    !isRunning ||
    !isSnapshotsLoaded ||
    isDefaultMockTurnedOffByStep(stepStoreMock)
  ) {
    return [originalState, originalActions];
  }

  const mockLang =
    MOCK_LANG_BY_APP_LANG[language] || MOCK_LANG_BY_APP_LANG.default;
  const currentMock = {
    ...defaultStoreMock,
    ...stepStoreMock
  };
  const currentSnapshot =
    currentMock.snapshotName && storeSnapshots
      ? storeSnapshots[currentMock.snapshotName]?.[mockLang]
      : {};
  const currentFields = currentMock.syncFields || [];

  // First, apply snapshot from mock config
  const currentState = {
    ...originalState,
    ...(preferredCurrency
      ? JSON.parse(
          // now we have only EUR and UAH currency in mocks, so that's why only these two currencies in regex, but we can change it to any currency by partner
          JSON.stringify(currentSnapshot).replace(/UAH|EUR/g, preferredCurrency)
        )
      : currentSnapshot),
    ...currentFields.reduce(
      (acc, fieldName) => ({
        ...acc,
        [fieldName]: originalState[fieldName]
      }),
      {}
    )
  };

  // Second, apply manual overrides to state from mock config
  const nextState = currentMock.overrides
    ? overrideMockedState(currentState, currentMock)
    : currentState;

  return [
    nextState,
    // I admit this is dangerous, but as long as no problems arise - it's OK
    currentFields.length === 0 ? actionsProxy() : originalActions
  ];
};
