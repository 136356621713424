import { isString } from 'lodash';
import { PREDICTIONS_STEPS_IDS } from '../../constants';
import orderTeams from '../../utils/orderTeams';
import { formatGroups } from './groups';
import { getStepsMap, getStep, isValidPredictionStep } from './steps';

const { GROUPS, TOP_OF_THIRD } = PREDICTIONS_STEPS_IDS;

/*
 * Marketing reference normalizer
 */

export const normalizeReference = (reference = {}, isHavePrediction) => {
  let steps = {};

  const {
    bracket = {},
    default: defaultTeams = {},
    endDateOfEditing
  } = reference;

  if (!isHavePrediction) {
    steps = getStepsMap(bracket);
    steps[GROUPS] = formatGroups(defaultTeams);
  }

  return {
    steps: {
      ...steps
    },
    reference: reference?.bracket,
    defaultGroups: reference.default,
    isReferenceLoaded: Boolean(Object.keys(reference)),
    endDateOfEditing
  };
};

/*
 * User prediction normalizer
 */

export const normalizeUserPrediction = (prediction = {}) => {
  if (!Object.keys(prediction).length) return { isPredictionsLoaded: false };
  const { bracket = {}, status, realWinAmount, happyPathAmount } = prediction;
  const { groups: groupsDefault } = bracket;
  const steps = getStepsMap(bracket);
  const groups = formatGroups(groupsDefault, true);

  Object.keys(bracket)
    .filter((step) => step !== GROUPS)
    .forEach((step) => {
      const finalStep = isString(bracket[step][0])
        ? [bracket[step]]
        : bracket[step]; // Cast to one type for FINAL and TOP_OF_THIRD

      steps[step] = getStep(step, finalStep, true);
    });

  return {
    steps: {
      ...steps,
      [GROUPS]: groups
    },
    status,
    realWinAmount,
    happyPathAmount,
    isPredictionsLoaded: true
  };
};

/*
 * Find which user prediction is valid
 */

export const normalizeIsValidPrediction = (userPrediction, reference) => {
  const newPredictions = { ...userPrediction };
  for (const step of Object.keys(reference)) {
    newPredictions[step] = isValidPredictionStep(
      newPredictions[step],
      reference,
      step
    );
  }
  return newPredictions;
};

/*
 * Formating our state, to save action format
 */

const formatWithWinners = (teamsIds, teamsMap) => [
  teamsIds.find((team) => teamsMap[team].win),
  teamsIds.find((team) => !teamsMap[team].win)
];

const formatWithIndexes = (teamsIds, teamsMap, type) =>
  orderTeams(
    teamsIds.map((teamId) => teamsMap[teamId]),
    type
  ).map((team) => team.id);

export const normalizeStateForSaveRequest = (steps) => {
  const newSteps = {};
  for (const stepName of Object.keys(steps)) {
    const step = steps[stepName];
    if (stepName === GROUPS) {
      newSteps[stepName] = step.bracket.reduce((acc, group) => {
        acc[group.groupId] = formatWithIndexes(group.teams, step.teams, GROUPS);
        return acc;
      }, {});
    } else if (stepName === TOP_OF_THIRD) {
      newSteps[stepName] = formatWithIndexes(
        step.bracket[0].teams,
        step.teams,
        TOP_OF_THIRD
      ).slice(0, 4);
    } else {
      for (const match of step.bracket) {
        const newMatch = formatWithWinners(match.teams, step.teams);
        if (step.bracket.length === 1) {
          newSteps[stepName] = newMatch;
        } else {
          newSteps[stepName] = newSteps[stepName]
            ? [...newSteps[stepName], newMatch]
            : [newMatch];
        }
      }
    }
  }

  return newSteps;
};
