import { formatAsUriParameters, getResourceFromCms } from '@namespace/helpers';

export const getCmsPresets = async (data = {}) => {
  const { presets = [] } = data;
  const presetNames = presets.length
    ? `?${formatAsUriParameters({ presetsNames: presets }, true)}`
    : '';

  return getResourceFromCms(`/presets${presetNames}`).catch((e) =>
    console.log(e)
  );
};
