import { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { DeviceContext } from '@namespace/device';
import { scrollTo, window } from '@namespace/helpers';
import { useHistory } from '@namespace/router';
import { isString } from 'lodash';
import { useOtherSettings } from '@namespace/cms';
import { PROVIDERS_TYPES } from '../../constants';
import {
  egtLauncherControl,
  endorphinaLauncherControl,
  oryxLauncherControl,
  betsoftLauncherControl,
  boomingLauncherControl,
  resizeControl,
  egtDigitalLauncherControl,
  pateplayLauncherControl,
  useBetGamesControl,
  stakelogicRlxLauncherControl,
  pinnacleLauncherControl,
  playtechLauncherControl
} from './controls';

const ctOnExitHandler = () => {
  window.history.go(-2);
};

export const handleMessage = (
  provider,
  callbacks,
  frame,
  controls,
  disabledProviders
) => (event) => {
  if (disabledProviders?.includes(provider)) {
    return null;
  }

  switch (provider) {
    case PROVIDERS_TYPES.EGT:
    case PROVIDERS_TYPES.AMUSNET_LIVE:
      return egtLauncherControl(event, callbacks);
    case PROVIDERS_TYPES.BETSOFT:
      return betsoftLauncherControl(event, callbacks);
    case PROVIDERS_TYPES.BOOMING:
      return boomingLauncherControl(event, callbacks);
    case PROVIDERS_TYPES.ORYX:
    case PROVIDERS_TYPES.KALAMBA:
    case PROVIDERS_TYPES.GAMOMAT:
      return oryxLauncherControl(event, callbacks);
    case PROVIDERS_TYPES.CT_ORYX:
      return oryxLauncherControl(event, {
        ...callbacks,
        onExitHandler: ctOnExitHandler
      });
    case PROVIDERS_TYPES.ENDORPHINA:
      return endorphinaLauncherControl(event, callbacks);
    case PROVIDERS_TYPES.BETGAMES:
      return controls?.betGamesControl(event, callbacks);
    case PROVIDERS_TYPES.TVBET:
    case PROVIDERS_TYPES.KENO:
    case PROVIDERS_TYPES.INTERNATIONAL_LOTTERIES:
    case PROVIDERS_TYPES.QUIZ:
    case PROVIDERS_TYPES.GOLDENRACE:
    case PROVIDERS_TYPES.BOOK_OF_SIX:
    case PROVIDERS_TYPES.LUCKY_WHEEL:
      return resizeControl(event, callbacks, frame);
    case PROVIDERS_TYPES.EGT_DIGITAL:
      return egtDigitalLauncherControl(event, callbacks);
    case PROVIDERS_TYPES.PATEPLAY:
      return pateplayLauncherControl(event, callbacks);
    case PROVIDERS_TYPES.STAKELOGIC_RLX:
      return stakelogicRlxLauncherControl(event, callbacks);
    case PROVIDERS_TYPES.PINNACLE:
      return pinnacleLauncherControl(event, callbacks);
    case PROVIDERS_TYPES.PLAYTECH:
      return playtechLauncherControl(event, callbacks);
    default:
      return null;
  }
};

export const useLauncherMessages = (data) => {
  const { gameUrl, provider, frame, reloadIframe } = data;
  const { isMobileDevice, isTabletDevice, isIOS } = useContext(DeviceContext);
  const { goBack } = useHistory();
  const [height, setHeight] = useState(null);
  const betGamesControl = useBetGamesControl(frame);
  const { isPinnacleIntegrationEnabled = false } = useOtherSettings();

  const callbacks = useMemo(
    () => ({
      onExitHandler: () => goBack(),
      setHeightHandler: (value) =>
        setHeight(isString(value) ? value.replace(/\D/g, '') : value),
      onLaunchHandler: () => {
        const isMobileView = isMobileDevice || isTabletDevice;
        if (isMobileView) {
          scrollTo({ top: isIOS ? 1 : 0 });
        }
      },
      onReloadHandler: () => reloadIframe()
    }),
    [goBack, isMobileDevice, isTabletDevice, isIOS, reloadIframe]
  );
  const controls = useMemo(
    () => ({
      betGamesControl
    }),
    [betGamesControl]
  );

  const disabledProviders = useMemo(() => {
    const list = [];

    if (!isPinnacleIntegrationEnabled) {
      list.push(PROVIDERS_TYPES.PINNACLE);
    }

    return list;
  }, [isPinnacleIntegrationEnabled]);

  const eventHandler = useCallback(
    (e) =>
      handleMessage(provider, callbacks, frame, controls, disabledProviders)(e),
    [provider, callbacks, frame, controls, disabledProviders]
  );

  useEffect(() => {
    if (frame.current && height) {
      frame.current.style.height = `${height}px`;
    }
  }, [frame, height]);

  useEffect(() => {
    window.addEventListener('message', eventHandler, false);
    return () => {
      window.removeEventListener('message', eventHandler);
    };
  }, [gameUrl, eventHandler]);
};
