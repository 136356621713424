import { useEffect, useState } from 'react';
import { kebabCase } from 'lodash';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { AText, Box, Link } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import styles from './index.module.css';

const GenericLink = ({
  itemTitle,
  link,
  linkClass,
  linkCustomClass,
  isTargetBlank
}) => {
  const { t } = useI18n();

  return (link || '').includes('https') || isTargetBlank ? (
    <a
      className={`${styles.link} ${linkClass}`}
      target="_blank"
      rel="noopener noreferrer"
      href={link}
    >
      {t(itemTitle)}
    </a>
  ) : (
    <Link
      to={`${link}` || ''}
      Component={LocalizedLink}
      className={`${styles.link} ${linkCustomClass}`}
      intent="secondary"
      size="s"
      breed="R"
    >
      {t(itemTitle)}
    </Link>
  );
};

const CollapsibleMenu = ({
  dataRole,
  title = '',
  items = [],
  isCollapsible = true,
  isCollapsedDefault = true,
  classNames = {},
  TitleComponent = null,
  displayMoreIcon = 'icons/general/nav/dropdown-down',
  displayLessIcon = 'icons/general/nav/dropdown-up',
  collapsibleIconSize = 'xs'
}) => {
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedDefault);
  const { t } = useI18n();
  const {
    containerClass = '',
    itemClass = '',
    titleClass = '',
    menuItemClass = '',
    menuClass = '',
    linkClass = '',
    linkCustomClass = ''
  } = classNames;

  useEffect(() => {
    if (!isCollapsible) {
      setIsCollapsed(false);
    }
  }, [isCollapsible]);

  const collapseClickHandler = () => {
    if (isCollapsible) {
      return setIsCollapsed(!isCollapsed);
    }

    return () => {};
  };

  return (
    <Box
      data-role={dataRole}
      direction="column"
      className={`${styles.container} ${containerClass}`}
    >
      <Box
        className={`${itemClass} ${isCollapsible ? styles.cursorPointer : ''}`}
        flexGrow="1"
        onClick={collapseClickHandler}
        justify="between"
        align="center"
      >
        {TitleComponent ? (
          <TitleComponent
            className={`${styles.title} ${titleClass}`}
            title={t(title)}
          />
        ) : (
          <AText
            className={`${styles.title} ${titleClass}`}
            breed="BU"
            size="l"
            color="footer_txt_2"
          >
            {t(title)}
          </AText>
        )}
        {isCollapsible && (
          <Icon
            name={`${isCollapsed ? displayMoreIcon : displayLessIcon}`}
            color="footer_txt_2"
            size={collapsibleIconSize}
          />
        )}
      </Box>
      {!isCollapsed && (
        <ul className={`${styles.menu} ${menuClass}`}>
          {items.map(({ title: itemTitle, link, isTargetBlank = false }) => (
            <li
              data-role={itemTitle && kebabCase(itemTitle)}
              key={itemTitle}
              className={`${styles.menuItem} ${menuItemClass}`}
            >
              <GenericLink
                itemTitle={itemTitle}
                link={link}
                linkClass={linkClass}
                linkCustomClass={linkCustomClass}
                isTargetBlank={isTargetBlank}
              />
            </li>
          ))}
        </ul>
      )}
    </Box>
  );
};

export default CollapsibleMenu;
