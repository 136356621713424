import { memo } from 'react';
import { Flag, Icon } from '@namespace/icons2';
import { AText, Box, Checkbox } from '@alf/uikit';
import PinnedIcon from '../PinnedIcon';
import styles from './index.module.css';

const MenuItem = ({
  name,
  count,
  countryId,
  fontSize = 'm',
  onClick = () => {},
  toggleMenu = () => {},
  isActive,
  isExpanded,
  withCounter,
  withExpandIcon,
  withIcon = true,
  withCheckbox,
  withPinIcon,
  isPinned,
  isCyberSport,
  categoryId,
  className = '',
  dataAttr = {}
}) => {
  const itemClassName = `${styles.item} ${isActive ? styles.active : ''} ${
    isExpanded ? styles.expanded : ''
  } ${className}`;
  const isIconExist = countryId || countryId === 0;

  return (
    <Box
      justify="between"
      align="center"
      padding={isIconExist || isCyberSport ? 's' : 's s s l'}
      flex="1 0 auto"
      className={itemClassName}
      onClick={onClick}
      {...dataAttr}
    >
      {isIconExist && (
        <div className={withIcon ? styles.iconWrap : ''}>
          <Flag
            data-role={`menu-item-flag-${countryId}`}
            flagId={countryId}
            size="sm"
            className={styles.flag}
          />
        </div>
      )}
      <Box flexGrow={1} className={styles.nameWrap}>
        <AText
          breed="BU"
          size={fontSize}
          data-role="menu-item-name"
          color="sm_txt_color_1"
          overflow="ellipsis"
        >
          {name}
        </AText>
      </Box>
      {withPinIcon && (
        <PinnedIcon
          isPinned={isPinned}
          categoryId={categoryId}
          size="s"
          type="category"
        />
      )}
      {withCounter && (
        <Box
          justify="center"
          align="center"
          flexShrink={0}
          className={styles.count}
        >
          <AText breed="R" size="xs" color="sm_txt_color_1">
            {count}
          </AText>
        </Box>
      )}
      {withCheckbox && (
        <Checkbox
          className={styles.checkboxWrapper}
          checked={isActive}
          classNames={{
            checkboxInputClassName: styles.checkboxInput,
            checkboxClassName: styles.checkboxClassName,
            checkedClassName: styles.checkedClassName,
            labelClassName: styles.labelClassName
          }}
        />
      )}
      {withExpandIcon && (
        <Icon
          name={`icons/general/nav/${isExpanded ? 'collapse' : 'expand'}`}
          data-role={`menu-item-${isExpanded ? 'collapse' : 'expand'}-icon`}
          color="sm_txt_color_1"
          size="s"
          onClick={(e) => {
            e.stopPropagation();
            toggleMenu();
          }}
        />
      )}
    </Box>
  );
};

export default memo(MenuItem);
