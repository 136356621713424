import { useFormContext } from 'react-hook-form';
import InputField from '../../common/InputField';

const RegSecretAnswerFieldInput = ({ disabled, ...props }) => {
  const { watch } = useFormContext();
  const secretQuestion = watch('secretQuestion');

  return <InputField disabled={disabled ?? !secretQuestion} {...props} />;
};

export default RegSecretAnswerFieldInput;
