import luhnValidator from '../luhnValidator';
import cardTypeValidator from '../cardTypeValidator';

const binCardNumberValidator = (digitLimits = [16], cardBIN) => ({
  validate: (value) => {
    const cardNumber = value.replace(/\D/g, '');
    const matchFound = cardBIN.some((num) => {
      const numAsString = num.toString();
      const partToMatch = cardNumber
        .toString()
        .substring(0, numAsString.length);
      return partToMatch === numAsString;
    });
    if (
      !luhnValidator(cardNumber) ||
      !cardTypeValidator(cardNumber) ||
      !digitLimits.includes(cardNumber.length)
    ) {
      return 'validators.creditCardValidator';
    }
    if (!matchFound) {
      return 'web.balance.sw.forms.cardBINsportBank';
    }

    return true;
  }
});

export default binCardNumberValidator;
