import { LANG_PATTERN } from '../../constants';

export const addLang = (location) => {
  const match = location.pathname.match(LANG_PATTERN);
  return (lang) => {
    let { pathname } = location;
    if (match) {
      if (match[1] === lang) {
        return location;
      }
      pathname = pathname.replace(LANG_PATTERN, `/${lang}/$\``);
    } else {
      pathname = pathname.replace(/^\/?(.*)/, `/${lang}/$1`);
    }

    return {
      ...location,
      pathname
    };
  };
};

export const removeLang = (location) => {
  const match = location.pathname.match(LANG_PATTERN);

  let { pathname } = location;
  if (match) {
    pathname = pathname.replace(LANG_PATTERN, '');
  }

  return {
    ...location,
    pathname
  };
};

export const getAvailableLanguageFromPath = (pathname, languages) => {
  const match = pathname.match(LANG_PATTERN);
  if (match) {
    const pathnameFirstPart = match[1];
    if (languages.includes(pathnameFirstPart)) {
      return pathnameFirstPart;
    }
  }

  return null;
};

export const getLanguageFromPath = (pathname) => {
  const match = pathname.match(LANG_PATTERN);
  if (match) {
    return match[1];
  }

  return null;
};
