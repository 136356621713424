import CategoryItem from '../CategoryItem';
import TournamentItem from '../TournamentItem';
import { useTournamentsByCategoryId } from '../../../store';

const ExpandedSport = ({
  categoryId,
  showCategories,
  isActiveCategory,
  isCategoryExpandable,
  isExpandedCategory,
  handleCategorySelect,
  handleToggleCategory,
  selectedTournamentsIds,
  isSingleTournament,
  isShowEventsCount,
  handleTournamentSelect,
  serviceId
}) => {
  const categoryTournaments = useTournamentsByCategoryId(categoryId, serviceId);
  return (
    <div key={`categoryItem-${categoryId}`}>
      {showCategories && (
        <CategoryItem
          categoryId={categoryId}
          serviceId={serviceId}
          isActive={isActiveCategory}
          expandable={isCategoryExpandable}
          isExpanded={isExpandedCategory}
          onClick={() => handleCategorySelect(categoryId)}
          toggleMenu={() => handleToggleCategory(categoryId)}
        />
      )}
      {isCategoryExpandable && isActiveCategory && isExpandedCategory && (
        <div>
          {categoryTournaments.map((tournamentId) => {
            const isActiveTournament = selectedTournamentsIds.includes(
              Number(tournamentId)
            );

            return (
              <TournamentItem
                key={tournamentId}
                serviceId={serviceId}
                tournamentId={tournamentId}
                isActive={isActiveTournament}
                multiple={!isSingleTournament}
                withCounter={isShowEventsCount}
                onClick={() =>
                  handleTournamentSelect(Number(tournamentId), categoryId)
                }
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ExpandedSport;
