import { useEffect, useContext } from 'react';
import {
  socketSubscribe,
  socketUnsubscribe,
  socketSend
} from '@namespace/socket';
import { useInterval } from '@namespace/helpers';
import { UserContext, USER_ROLES } from '@namespace/user';
import { TournamentsLeaderboardsContext } from '../../tournamentsLeaderboardsStore/context';
import {
  getLeaderboard,
  leaderboardSubscribe,
  leaderboardUnsubscribe
} from '../../api';

const LEADERBOARD_SUBSCRIBE_PING_INTERVAL = 5 * 60 * 1000; // 5 min

/* 
Leaderboard subscribe logic

guest flow:
1 - GET /leaderboard (initial state)
2 - send socket subscribe
3 - listen for updates

user flow
1 - start POST /subscribe polling each 5 min (initial state)
2 - send socket subscribe
3 - listen for updates
*/

export const useSubscribeToTournamentLeaderboard = (
  tournamentId,
  isTournamentFinished
) => {
  const [, { SET_LEADERBOARD_INFO }] = useContext(
    TournamentsLeaderboardsContext
  );
  const [user] = useContext(UserContext);
  const isLoggedInUser = user.role === USER_ROLES.USER;
  const isPollingSubscribeEnabled =
    tournamentId && isLoggedInUser && !isTournamentFinished;

  useEffect(() => {
    (async () => {
      try {
        if (!tournamentId) return;

        const initialLeaderboardInfo = isLoggedInUser
          ? await leaderboardSubscribe(tournamentId)
          : await getLeaderboard(tournamentId);

        SET_LEADERBOARD_INFO(initialLeaderboardInfo.data, tournamentId);

        if (!isTournamentFinished) {
          socketSubscribe({
            name: 'tournament_leaderboards',
            types: ['tournament_leaderboards'],
            callback: ({ data }) => {
              SET_LEADERBOARD_INFO(data, data?.tournamentId);
            }
          });

          await socketSend({
            cmd: 'subscribe_tournament_leaderboards_updates',
            data: { tournament_id: Number(tournamentId) }
          });
        }
      } catch (e) {
        console.warn(e);
      }
    })();

    return () => {
      if (isLoggedInUser) leaderboardUnsubscribe(tournamentId);
      if (!isTournamentFinished) {
        socketUnsubscribe('tournament_leaderboards');
        socketSend({
          cmd: 'unsubscribe_tournament_leaderboards_updates',
          data: { tournament_id: Number(tournamentId) }
        });
      }
    };
  }, [
    SET_LEADERBOARD_INFO,
    tournamentId,
    isLoggedInUser,
    isTournamentFinished
  ]);

  useInterval(
    () => leaderboardSubscribe(tournamentId),
    isPollingSubscribeEnabled ? LEADERBOARD_SUBSCRIBE_PING_INTERVAL : null
  );
};
