import { useEffect } from 'react';
import { window } from '@namespace/helpers';

const useDisableScrollRestore = () => {
  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);
};

export default useDisableScrollRestore;
