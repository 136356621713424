import clsx from 'clsx';
import { AText, Box, Label } from '@alf/uikit';
import Amount from '@namespace/common/src/common/Amount';
import FormFieldError from '../FormFieldError';
import styles from './index.module.css';

const Control = ({
  children,
  hint,
  error,
  onClick = () => {},
  label = '',
  postInputLabel,
  id = '',
  withTooltip,
  noErrorMsg,
  classNames: {
    className = '',
    hintClassName = '',
    labelClassName = '',
    wrapperClassName = '',
    hintErrorClassName = ''
  } = {},
  displayCurrencyConvertation,
  formAmount,
  currency,
  dataRole
}) => (
  <Box className={clsx([styles.wrapper, wrapperClassName])} onClick={onClick}>
    <Box
      direction="column"
      className={`
      ${styles.controlContainer} 
      ${className}
    `}
    >
      {label && !postInputLabel && (
        <Label
          htmlFor={id}
          className={clsx([styles.label, labelClassName])}
          data-role={dataRole && `${dataRole}-label`}
        >
          {label}
        </Label>
      )}
      {children}
      {displayCurrencyConvertation && formAmount > 0 && (
        <Box top="s" bottom="0">
          <Amount
            additionalFormatting={true}
            amount={`${formAmount} ${currency}`}
            noFormattedAmount={formAmount}
            displayOnlyConvertedAmount={true}
            convertedAmountColor="input_label_txt"
          />
        </Box>
      )}
      {!error && hint && (
        <AText
          data-role="control-hint-message"
          size="s"
          color="input_help_txt"
          className={clsx([styles.inputHint, hintClassName])}
        >
          {hint}
        </AText>
      )}
      {error && !noErrorMsg && (
        <FormFieldError
          dataRole="control-error-message"
          withTooltip={withTooltip}
          className={
            withTooltip
              ? clsx([styles.inputTooltipError, hintErrorClassName])
              : clsx([styles.inputHintError, hintErrorClassName])
          }
        >
          {error}
        </FormFieldError>
      )}
    </Box>
  </Box>
);

export default Control;
