import { isEmpty } from 'lodash';
import { useMatchPath } from '@namespace/router';
import { useGeneralRoutes } from '@namespace/cms';
import { PROMO_TYPES } from '../../constants';

export const useGetActivePromoType = () => {
  const { promotions, promotion, tournament } = useGeneralRoutes();

  const isPromotionPage = !isEmpty(
    useMatchPath([
      `${promotions}/promotions/:categoryId`,
      `${promotion}/:promoId`
    ])
  );
  const isTournamentsPage = !isEmpty(
    useMatchPath([
      `${promotions}/tournaments/:categoryId`,
      `${tournament}/:promoId`
    ])
  );

  if (isPromotionPage) return PROMO_TYPES.promotion;
  if (isTournamentsPage) return PROMO_TYPES.tournament;

  return null;
};
