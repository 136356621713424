import clsx from 'clsx';
import { Icon } from '@namespace/icons2';
import { AText, Box } from '@alf/uikit';
import { LocalizedNavLink } from '@namespace/i18n';
import styles from './index.module.css';

const Item = ({
  title = '',
  link = '',
  tag,
  logoUrl,
  iconName = '',
  useBackground = false,
  useAnimation = false,
  className = '',
  tagColor,
  isActive,
  linkClass,
  activeLinkClass,
  handleClick,
  showAnimation
}) => {
  return (
    <Box
      className={clsx([className, styles.linkContainer])}
      data-role={`nav-item ${link}`}
    >
      <LocalizedNavLink
        isActive={isActive}
        to={link}
        className={clsx([
          styles.link,
          linkClass,
          useAnimation && styles.animationButtonBorder,
          useBackground && styles.backgroundButton
        ])}
        activeClassName={clsx([
          useBackground ? styles.activeNewButton : styles.active,
          activeLinkClass
        ])}
        onClick={handleClick}
      >
        {showAnimation && useAnimation && (
          <>
            <span className={styles.topLine} />
            <span className={styles.rightLine} />
            <span className={styles.bottomLine} />
            <span className={styles.leftLine} />
          </>
        )}
        {tag && (
          <Box
            className={styles.headerTag}
            padding="0 xs"
            style={{
              backgroundColor: tagColor
            }}
          >
            <AText breed="BU" size="2xs" color="txt_color_4">
              {tag}
            </AText>
          </Box>
        )}
        {logoUrl ? (
          <Box className={styles.imgWrapper}>
            <img className={styles.imgIcon} src={logoUrl} alt="logo" />
          </Box>
        ) : (
          <Box align="center">
            {iconName && (
              <Icon className={styles.titleIcon} size="s" name={iconName} />
            )}
            <AText breed="BU" size="l" className={styles.title}>
              {title}
            </AText>
          </Box>
        )}
      </LocalizedNavLink>
    </Box>
  );
};

export default Item;
