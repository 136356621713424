import { useReducer, createContext, useMemo } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import actionCreators from '../actions';
import { reducers, initialState } from '../reducers';
import { types } from '../types/index.js';

export const ResultsContext = createContext();

export const ResultsProvider = ({ customReducers, children }) => {
  const memoReducers = useMemo(
    () => combineReducers(reducers, customReducers),
    [customReducers]
  );

  const [state, dispatch] = useReducer(memoReducers, initialState);

  const actions = useActions(types, dispatch, actionCreators);

  return (
    <ResultsContext.Provider value={[state, actions]}>
      {children}
    </ResultsContext.Provider>
  );
};
