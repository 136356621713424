import collectBonusCountersRequests from './utils/collectBonusCountersRequests';
import createSummedCounters from './utils/createSummedCounters';
import bonustTypesToCountersData from './utils/bonustTypesToCountersData';
import groupTypes from './utils/groupTypes';

const getBonusCounters = async ({ id: userId, bonusTypes = [] }) => {
  const groupedBonusTypes = groupTypes(bonusTypes);

  const bonusesCountsResponse = await Promise.all(
    collectBonusCountersRequests(groupedBonusTypes, { userId })
  );

  const newCounters = bonustTypesToCountersData(
    groupedBonusTypes,
    bonusesCountsResponse
  );

  const result = createSummedCounters(newCounters, bonusTypes);

  return result;
};

export default getBonusCounters;
