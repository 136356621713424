import { window } from '@namespace/helpers';

const getGoToOldUrl = (isDesktop = true) => {
  const { origin } = window.location;
  const replaceTo = isDesktop ? 'old.' : 'old-m.';

  return origin.replace(
    /(http[s]?:\/\/)(www\.|prev\.|new\.|m\.)?/,
    `$1${replaceTo}`
  );
};

export default getGoToOldUrl;
