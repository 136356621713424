import { types } from '../types';

export const initialState = {
  history: [],
  historyCards: [],
  pagination: {},
  isFetching: false,
  totalPages: 1,
  totalCount: 0
};

export const reducers = {
  [types.SET_DATA]: (
    state,
    { history, pagination, totalPages, totalCount }
  ) => ({
    ...state,
    history,
    pagination,
    totalPages,
    totalCount,
    isFetching: false
  }),
  [types.SET_CARDS]: (state, { cards }) => ({
    ...state,
    historyCards: cards
  })
};
