import { useContext } from 'react';
import clsx from 'clsx';
import { Box, AText } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { COMPONENTS_TYPES, PageComponentsContext } from '@namespace/cms';
import {
  useLoadCasinoFeed,
  useGetFeedName,
  useGamesFeed,
  useGetCategoryGames,
  gamesPlaceholder,
  SimpleGamesList
} from '@namespace/games';
import { useGetActualServiceUrl } from './useGetActualServiceUrl';
import styles from './index.module.css';

export const PromotionGamesSection = ({
  className,
  defaultFeed,
  segmentedFeeds = [],
  actionLink,
  serviceUrl,
  promoLink
}) => {
  const { t } = useI18n();

  const feedName = useGetFeedName(null, { defaultFeed, segmentedFeeds });
  const gamesFeed = useGamesFeed(feedName);
  const gamesCategory = gamesFeed?.categories?.ids?.[0];
  const games = useGetCategoryGames(gamesCategory, feedName);
  const { isFeedLoaded } = useLoadCasinoFeed({
    feedNames: feedName
  });
  const actualServiceUrl = useGetActualServiceUrl(serviceUrl);

  const {
    [COMPONENTS_TYPES.SIMPLE_GAMES_LIST]: SimpleGamesListNew
  } = useContext(PageComponentsContext);

  const SimpleGamesListComponent = SimpleGamesListNew || SimpleGamesList;

  return (
    <Box className={clsx([styles.wrapper, className])} direction="column">
      <Box
        className={styles.sectionTitleWrapper}
        align="center"
        data-role="promotion-item-title"
      >
        <AText className={styles.sectionTitle} breed="BU" color="txt_color_1">
          {t(`web.tournaments.games.title`)}
        </AText>
      </Box>
      <Box
        className={styles.sectionContent}
        justify="center"
        align="center"
        direction="column"
      >
        <Box className={styles.gamesListWrapper}>
          <SimpleGamesListComponent
            games={!isFeedLoaded ? gamesPlaceholder : games}
            showMoreUrl={actionLink}
            serviceUrl={actualServiceUrl}
            gameBackTo={actionLink || promoLink}
          />
        </Box>
      </Box>
    </Box>
  );
};
