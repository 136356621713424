import { postRPC } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

export const getEvent = ({ lang, eventId, serviceId }) => {
  const request = {
    by: {
      lang,
      head_markets: false,
      event_id: eventId,
      service_id: serviceId
    }
  };
  return postRPC('frontend/event/get', request).then(camelizeKeys);
};
