import { useEffect, useContext } from 'react';
import { useI18n } from '@namespace/i18n';
import { memoize } from 'lodash';
import { getReferences } from '../../api';
import { ReferenceContext } from '../../store/reference/context';
import setReferencesAction from '../../store/reference/actions';

const memoGetReferences = memoize(getReferences);

const useReferenceState = () => {
  const { referenceDispatch } = useContext(ReferenceContext);
  const { language } = useI18n();

  useEffect(() => {
    run();
    async function run() {
      const referencesData = await memoGetReferences(language);
      referenceDispatch(setReferencesAction(referencesData));
    }
  }, [language, referenceDispatch]);
};

export default useReferenceState;
