import PropTypes from 'prop-types';
import { Box, EventGroupLabel } from '@alf/uikit';
import EventState from '../EventState';
import EventDate from '../EventDate';
import EventTime from '../EventTime';
import { LINE_VIEW, MODE } from '../../constants';
import { useEventFields } from '../../store';

const EventHead = ({
  isShowEventGroup = true,
  eventId,
  lineView = LINE_VIEW.BUTTON,
  classNames = {},
  isWithTZ,
  dataRole
}) => {
  const {
    eventGroup,
    serviceId,
    canceled = false,
    finished = false,
    inprogress = false
  } = useEventFields({
    eventId,
    fields: ['eventGroup', 'serviceId', 'canceled', 'finished', 'inprogress']
  });

  const isLive = serviceId === MODE.IN_PLAY;
  const { boxClass = '', eventHeadContainerClass = '' } = classNames;
  const isEmptyState = canceled || finished || inprogress;
  return (
    <Box className={isLive ? boxClass : ''}>
      {isShowEventGroup && eventGroup !== false && (
        <EventGroupLabel eventGroup={eventGroup} />
      )}
      {isLive || isEmptyState ? (
        <EventState
          eventHeadContainerClass={eventHeadContainerClass}
          eventId={eventId}
          canceled={canceled}
          finished={finished}
          inprogress={inprogress}
          lineView={lineView}
        />
      ) : (
        <Box className={eventHeadContainerClass}>
          <EventDate
            eventId={eventId}
            lineView={lineView}
            isWithTZ={isWithTZ}
            dataRole={dataRole && `${dataRole}-date`}
          />
          <EventTime
            eventId={eventId}
            lineView={lineView}
            isWithTZ={isWithTZ}
            dataRole={dataRole && `${dataRole}-time`}
          />
        </Box>
      )}
    </Box>
  );
};

EventHead.propTypes = {
  isShowEventGroup: PropTypes.bool,
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default EventHead;
