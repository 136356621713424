import { useI18n } from '@namespace/i18n';
import { useEventField } from '../../store';

const usePreMatchEventState = (eventId = {}, isWithTZ = true) => {
  const { f } = useI18n();
  const DATE_FORMATS_TYPES = f.getFormats();
  const eventDt = useEventField(eventId, 'eventDt');

  const eventDate = eventDt
    ? f
        .getDateTime({ date: eventDt * 1000, withTZ: isWithTZ }) // convert to milliseconds
        .toFormat(DATE_FORMATS_TYPES.EVENTS_LINE_DATE)
    : '';

  const eventTime = eventDt
    ? f
        .getDateTime({ date: eventDt * 1000, withTZ: isWithTZ }) // convert to milliseconds
        .toFormat(DATE_FORMATS_TYPES.EVENTS_LINE_TIME)
    : '';

  return { eventDate, eventTime };
};

export default usePreMatchEventState;
