import {
  useCallback,
  useContext,
  useRef,
  useState,
  forwardRef,
  useMemo
} from 'react';
import clsx from 'clsx';
import { Box, useOnClickOutside } from '@alf/uikit';
import { useHistory } from '@namespace/router';
import { isEmpty, isNil } from 'lodash';
import FavoriteButton from '../FavoriteButton';
import GameContent from './GameContent';
import GameImage from './GameImage';
import GameTags from './GameTags';
import GameName from './GameName';
import { WideGameDots } from './WideGameDots';
import { PragmaticLiveInfo } from './PragmaticLiveInfo';
import { LiveRtpInfo } from './LiveRtpInfo';
import { useGetPragmaticData } from '../../hooks/useGetPragmaticData';
import { GamesControlsContext } from '../../context';
import styles from './index.module.css';

const getGameWrapperClass = ({ isWideGame, isLaunchOnTap }) => {
  if (isWideGame) {
    return styles.wideGameWrapper;
  }

  if (isLaunchOnTap) {
    return styles.launchOnTapGameWrapper;
  }

  return styles.wrapper;
};

const Game = forwardRef(
  (
    {
      game,
      className,
      casinoType,
      isDesktop,
      showTags,
      gameTags,
      showGameNames,
      onGameClick = () => {},
      index,
      isWideGame,
      style = {},
      isLaunchOnTap = false, // don't show GameContent on tap, immediately redirect to real play mode
      link,
      isLoggedInUser,
      excludedBonusGamesList,
      liveRtpInfo
    },
    ref
  ) => {
    const controls = useContext(GamesControlsContext) || {};
    const [showGameContent, setShowGameContent] = useState(false);
    const { isPragmaticEnabled } = useGetPragmaticData();
    const refer = useRef(null);
    const { gameCode, launchGameId } = game;
    const { push } = useHistory();
    const { hasFavoriteButton, serviceURL, serviceName = '' } = controls;
    const [isGameImageLoaded, setIsGameImageLoaded] = useState(false);
    const gameWrapperClass = getGameWrapperClass({ isWideGame, isLaunchOnTap });

    useOnClickOutside(() => {
      setShowGameContent(false);
    }, refer);

    const dataGTM = !isDesktop
      ? `Game-launch ${serviceName.toLowerCase()} ${gameCode} ${game.tags?.join(
          ' '
        )}`
      : '';

    const onClick = useCallback(() => {
      if (isLaunchOnTap && link) {
        push(link);

        return;
      }

      setShowGameContent(!showGameContent);
    }, [setShowGameContent, showGameContent, isLaunchOnTap, link, push]);

    const isWagerInfoShow = useMemo(
      () => isLoggedInUser && !!excludedBonusGamesList,
      [isLoggedInUser, excludedBonusGamesList]
    );

    const isGameWagerExcluded = useMemo(
      () => isWagerInfoShow && excludedBonusGamesList?.includes(+launchGameId),
      [isWagerInfoShow, excludedBonusGamesList, launchGameId]
    );

    const isFavoriteButtonShown =
      (hasFavoriteButton && isDesktop && !isLaunchOnTap) ||
      (hasFavoriteButton && !showGameContent && !isDesktop && !isLaunchOnTap);
    const isGameNameShown =
      showGameNames ||
      !isGameImageLoaded ||
      !isNil(game?.pragmaticLiveData?.tableOpen);
    const setRef = (element) => {
      if (typeof ref === 'function') {
        ref(element);
      } else if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = element;
      }
      refer.current = element;
    };

    return (
      <Box className={className} style={style} ref={setRef}>
        <Box
          direction="column"
          className={clsx([
            gameWrapperClass,
            showGameContent && !isLaunchOnTap && styles.wrapperShowedGameContent
          ])}
          onClick={onClick}
        >
          <GameImage
            game={game}
            onImageLoad={setIsGameImageLoaded}
            isWideGame={isWideGame}
            className={isWideGame ? styles.wideGameImageWrapper : ''}
          />
          {showTags && !liveRtpInfo && (
            <GameTags game={game} gameTags={gameTags} />
          )}
          {liveRtpInfo && <LiveRtpInfo liveRtpInfo={liveRtpInfo} />}
          {isGameNameShown && (
            <Box
              className={styles.gameBottomInfoWrapper}
              direction="column"
              justify="end"
            >
              {isGameNameShown && <GameName game={game} />}
              {!isEmpty(game.pragmaticLiveData) && isPragmaticEnabled && (
                <PragmaticLiveInfo pragmaticLiveData={game.pragmaticLiveData} />
              )}
            </Box>
          )}
          {!isLaunchOnTap && (
            <GameContent
              controls={controls}
              game={game}
              className={styles.content}
              isDesktop={isDesktop}
              showGameContent={showGameContent}
              dataGTM={dataGTM}
              infoBtnLink={link}
              casinoType={casinoType}
              onGameClick={onGameClick}
              gameIndex={index}
              isWideGame={isWideGame}
              isWagerInfoShow={isWagerInfoShow}
              isGameWagerExcluded={isGameWagerExcluded}
              classNames={{
                wideGameInfoDesktopWrapper: styles.wideGameInfoDesktopWrapper,
                controlsDesktopWrapper: styles.controlsDesktopWrapper,
                wideWagerDesktopWrapper: styles.wideWagerDesktopWrapper
              }}
            />
          )}
          {isFavoriteButtonShown && (
            <Box
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
              className={styles.favoriteWrap}
            >
              <FavoriteButton
                casinoType={casinoType}
                game={game}
                redirectToPlay={isDesktop}
                serviceURL={serviceURL}
                redirectToPreview={!isDesktop}
              />
            </Box>
          )}
          {isWideGame && !isDesktop && (
            <WideGameDots
              dotsNumber={2}
              activeIndex={showGameContent ? 1 : 0}
            />
          )}
        </Box>
      </Box>
    );
  }
);

export default Game;
