import types from '../types';

const referenceReducer = (state, action) => {
  switch (action.type) {
    case types.SET_REFERENCE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default referenceReducer;
