import { useContext } from 'react';
import { AText, Box } from '@alf/uikit';
import { isNull } from 'lodash';
import { UserContext } from '@namespace/user';
import { useI18n } from '@namespace/i18n';
import { Amount } from '@namespace/common';
import clsx from 'clsx';
import styles from './index.module.css';

const AmountComponent = ({
  amount,
  isUserMenu,
  dataRole,
  noFormattedAmount,
  balanceAmountProps
}) => (
  <Amount
    amount={amount}
    noFormattedAmount={noFormattedAmount}
    currentAmountSize={isUserMenu ? 'm' : 'l'}
    additionalFormatting={true}
    currentCurrencyBreed="B"
    currentAmountBreed="B"
    convertedAmountBreed="B"
    convertedCurrencyBreed="B"
    data-role={dataRole}
    {...balanceAmountProps}
  />
);

const UserMenuBalance = ({
  isUserMenu = false,
  showPayout = true,
  classNames = {},
  balanceAmountProps = {},
  alwaysShowBonusBalance = false
}) => {
  const { t, c } = useI18n();
  const [user] = useContext(UserContext);
  const { activeWallet = {} } = user;
  const {
    deposit = 0,
    currency = '',
    baseBalance,
    bonusBalance
  } = activeWallet;

  const {
    wrapperClassName = '',
    balanceTitleClassName = '',
    bonusBalanceTitleClassName = '',
    balanceBlockWrapperClassName = '',
    bonusBalanceBlockWrapperClassName = '',
    balanceBlockWrapperClassNameWithBonusBalance = ''
  } = classNames;

  return (
    <Box
      direction={isUserMenu ? 'row' : 'column'}
      justify={isUserMenu ? 'between' : 'center'}
      align="center"
      flex="1"
      className={wrapperClassName}
    >
      <Box
        margin="xs"
        direction="column"
        align={isUserMenu ? 'start' : 'center'}
        className={clsx(
          balanceBlockWrapperClassName,
          (!isNull(bonusBalance) || alwaysShowBonusBalance) &&
            balanceBlockWrapperClassNameWithBonusBalance
        )}
      >
        <AText
          breed="B"
          size="m"
          color="po_txt_color_1"
          style={{ marginBottom: isUserMenu ? '6px' : '4px' }}
          data-role="personal-office-menu-balance-title"
          className={balanceTitleClassName}
        >
          {t('personalOffice.userInfo.balance')}
        </AText>
        <AmountComponent
          amount={`${c.formatCurrency(Number(deposit))} ${currency}`}
          noFormattedAmount={Number(deposit)}
          isUserMenu={isUserMenu}
          dataRole="personal-office-menu-balance-amount"
          balanceAmountProps={balanceAmountProps}
        />
      </Box>
      {(!isNull(bonusBalance) || alwaysShowBonusBalance) && (
        <Box direction="column" align={isUserMenu ? 'end' : 'center'}>
          {showPayout && (
            <Box
              margin="xs"
              direction={isUserMenu ? 'row' : 'column'}
              align="center"
            >
              <AText
                breed="R"
                size={isUserMenu ? 's' : 'm'}
                color="po_txt_color_3"
                className={clsx([
                  styles.textTitle,
                  isUserMenu && styles.userMenuTitle
                ])}
              >
                {t('personalOffice.userInfo.balance.payout')}
              </AText>
              <AmountComponent
                amount={`${c.formatCurrency(Number(baseBalance))} ${currency}`}
                isUserMenu={isUserMenu}
              />
            </Box>
          )}
          <Box
            margin="xs"
            direction={isUserMenu ? 'row' : 'column'}
            align="center"
            className={bonusBalanceBlockWrapperClassName}
          >
            <AText
              breed="R"
              size={isUserMenu ? 's' : 'm'}
              color="po_txt_color_3"
              className={clsx([
                styles.textTitle,
                bonusBalanceTitleClassName,
                isUserMenu && styles.userMenuTitle
              ])}
            >
              {t('personalOffice.userInfo.balance.bonus')}
            </AText>
            <AmountComponent
              amount={`${c.formatCurrency(Number(bonusBalance))} ${currency}`}
              isUserMenu={isUserMenu}
              balanceAmountProps={balanceAmountProps}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default UserMenuBalance;
