import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Flag, Icon } from '@namespace/icons2';
import { isEqual } from 'lodash';
import clsx from 'clsx';
import Input from '../Input';
import Popover from '../Popover';
import Box from '../Box';
import CodesList from './components/CodesList';
import getCountriesByPhoneCode from './utils/getCountriesByPhoneCode';
import usePhoneMask from './hooks/usePhoneMask';
import styles from './index.module.css';

const Phone = ({
  onChange: setValue,
  onBlur = () => {},
  value: phoneValue,
  phoneCodes = [],
  disabled = false,
  size,
  dataRole = '',
  intent = 'default',
  defaultCountry = '',
  maskChar = '_',
  ...props
}) => {
  const [filteredCodes, setFilteredCodes] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [value, onChange] = useState(
    {
      ...phoneValue,
      isoCode:
        (phoneCodes.find((item) => item.phoneCode === phoneValue.code) || {})
          .iso1Code || ''
    } || {
      code: '',
      number: '',
      isoCode: ''
    }
  );
  const [mask, setCountryCode] = usePhoneMask();

  useEffect(() => {
    if (!isEqual(phoneValue, value)) {
      setValue && setValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (value.code !== phoneValue.code && !value.number) {
      onChange(phoneValue);
    }
  }, [phoneValue]);

  const handleBlur = useCallback(() => onBlur(value), [onBlur, value]);

  const handleChangeNumber = useCallback(
    (number) => onChange({ ...value, number: number.replace(/[^0-9]/g, '') }),
    [onChange, value]
  );

  const handleChangeCode = useCallback(
    (initCode, isoCode) => {
      const code = initCode.replace(/^\+\s?/, '');
      const newIsoCode =
        isoCode ||
        (phoneCodes.find((item) => item.phoneCode === code) || {}).iso1Code;
      onChange({
        ...value,
        code,
        isoCode: newIsoCode || value.isoCode
      });
      if (newIsoCode) {
        setCountryCode(newIsoCode);
      }
    },
    [phoneCodes, onChange, value, setCountryCode]
  );

  useEffect(() => {
    if (defaultCountry && phoneCodes.length && !value.number) {
      const phoneCode =
        phoneCodes.find((item) => item.iso1Code === defaultCountry) || {};
      const initialCode = phoneCode.phoneCode || phoneCodes[0].phoneCode;

      handleChangeCode(initialCode);
    }
  }, [defaultCountry, phoneCodes]);

  useEffect(() => {
    // prevent to pass field data to form context before code and number will be filled to avoid validation issue
    if (!isEqual(phoneValue, value) && value.number && value.code) {
      setValue(value);
    }
  }, [phoneValue, setValue, value]);

  const filterCodes = useCallback(
    (code = '') =>
      setFilteredCodes(
        getCountriesByPhoneCode(phoneCodes, code.replace(/^\+\s?/, ''))
      ),
    [setFilteredCodes, phoneCodes]
  );

  const onKeyDown = useCallback(
    (e) => showFilter && e.key === 'Tab' && setShowFilter(false),
    [showFilter, setShowFilter]
  );

  const onInputClick = useCallback(
    (val) => {
      setShowFilter(val);
      filterCodes();
    },
    [setShowFilter, filterCodes]
  );

  const onCountryInputChange = useCallback(
    (e) => {
      filterCodes(e.target.value);
      handleChangeCode(e.target.value);
    },
    [handleChangeCode, filterCodes]
  );

  const onNumberInputChange = useCallback(
    (e) => handleChangeNumber(e.target.value),
    [handleChangeNumber]
  );

  const wrapperClasses = clsx([
    styles.general,
    styles[intent],
    disabled && styles.disabled
  ]);

  return (
    <Box
      direction="column"
      onKeyDown={onKeyDown}
      className={wrapperClasses}
      data-role={dataRole}
    >
      <Popover
        trigger={
          <Box className={styles.triggerWrapper}>
            <Box
              align="center"
              onClick={() => onInputClick(true)}
              className={styles.code}
              data-role={`${dataRole}-phone-code`}
            >
              {value.isoCode && (
                <Flag className={styles.icon} size="s" iso={value.isoCode} />
              )}
              <Input
                type="tel"
                name="code"
                className={styles.inputCode}
                onChange={onCountryInputChange}
                onBlur={handleBlur}
                value={`+ ${value.code || ''}`}
                maxLength={6}
                min={0}
                size={size}
                disabled={disabled}
                autoComplete="nope"
                {...props}
              />
              <Icon
                name="icons/general/nav/dropdown_down"
                className={clsx(styles.arrowDown, showFilter && styles.arrowUp)}
                onClick={(e) => {
                  if (showFilter) {
                    e.stopPropagation();
                    onInputClick(false);
                  }
                }}
              />
            </Box>
            <Box
              flexBasis="80%"
              onClick={() => onInputClick(false)}
              data-role={`${dataRole}-phone-number`}
            >
              <Input
                type="tel"
                className={styles.inputNumber}
                name="number"
                onBlur={handleBlur}
                onChange={onNumberInputChange}
                value={value.number}
                disabled={disabled}
                mask={mask}
                size={size}
                maskChar={maskChar}
                autoComplete="nope"
                {...props}
              />
            </Box>
          </Box>
        }
        content={
          filteredCodes.length > 0 && (
            <CodesList
              defaultValue={defaultCountry}
              codes={filteredCodes}
              handleChangeCode={handleChangeCode}
              setShow={setShowFilter}
              value={value}
              size={size}
            />
          )
        }
        classNames={{
          contentClass: styles.contentClass
        }}
        setIsOpenExternal={setShowFilter}
        isOpenExternal={showFilter}
        disabledToggleInWrapper={true}
      />
    </Box>
  );
};

Phone.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.shape({
    code: PropTypes.string,
    number: PropTypes.string
  }),
  phoneCodes: PropTypes.array,
  disabled: PropTypes.bool,
  defaultCountry: PropTypes.string,
  dataRole: PropTypes.string,
  size: PropTypes.oneOf(['s', 'm']),
  intent: PropTypes.oneOf(['error', 'success', 'default']),
  maskChar: PropTypes.string
};

export default Phone;
