import { useCallback, useContext, useState } from 'react';
import { UserContext } from '@namespace/user';
import { withdraw } from '../../api/balance';

export const useWithdraw = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [resultData, setResultData] = useState(null);
  const [user] = useContext(UserContext);
  const { activeWallet = {} } = user;

  const onWithdraw = useCallback(
    async (data) => {
      try {
        setIsFetching(true);
        const responseData = await withdraw({
          walletHash: activeWallet.walletHash,
          ...data
        });
        setResultData({ ...responseData, ...data });
      } finally {
        setIsFetching(false);
      }
    },
    [activeWallet]
  );

  return {
    onWithdraw,
    resultData,
    setResultData,
    isFetching
  };
};
