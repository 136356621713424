import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';
import { Accordion, AText, Box, ICONS_LOCATIONS } from '@alf/uikit';
import MarketsTypeSection from './MarketsTypeSection';
import styles from './index.module.css';
import { LINE_VIEW } from '../../constants';

const MarketsGroup = ({
  title: titleText,
  listOfMarkets = [],
  outcomes,
  lineView,
  eventId = ''
}) => {
  const marketsByResultType = Object.entries(
    groupBy(listOfMarkets, 'resultTypeName')
  ).sort((a, b) => a[1][0].resultTypeWeigh - b[1][0].resultTypeWeigh);

  const marketsOutcomes = useMemo(
    () =>
      listOfMarkets.reduce(
        (acc, { marketId, outcomeIds, resultTypeId }) => ({
          ...acc,
          ...Object.values(outcomes).reduce(
            (outcomeAcc, outcome) =>
              outcomeIds.includes(outcome.outcomeId)
                ? {
                    ...outcomeAcc,
                    [outcome.outcomeId]: { marketId, resultTypeId, ...outcome }
                  }
                : outcomeAcc,
            {}
          )
        }),
        {}
      ),
    [listOfMarkets, outcomes]
  );

  const title = (
    <AText
      breed={lineView === LINE_VIEW.TABLE ? 'R' : 'B'}
      size="l"
      color="line_header_txt"
    >
      {titleText}
    </AText>
  );

  return (
    listOfMarkets.length > 0 && (
      <Accordion
        dataRole="markets-group"
        arrowsLocation={ICONS_LOCATIONS.END}
        arrowsColor="line_header_txt"
        title={title}
        withArrows={true}
        isOpenDefault={true}
        classNames={{
          headerClassName: `${styles.accordion} ${
            lineView ? styles[lineView] : ''
          }`,
          containerClassName: `${styles.accordionContainer} ${
            lineView ? styles[lineView] : ''
          }`,
          bodyClassName: styles.accordionBodyClassName
        }}
      >
        <Box
          direction="column"
          className={`${styles.container} ${lineView ? styles[lineView] : ''}`}
        >
          {marketsByResultType.map(([marketTitle, list]) => (
            <MarketsTypeSection
              key={marketTitle}
              title={marketTitle}
              listOfMarkets={list}
              marketsOutcomes={marketsOutcomes}
              lineView={lineView}
              eventId={eventId}
            />
          ))}
        </Box>
      </Accordion>
    )
  );
};

export default MarketsGroup;

MarketsGroup.propTypes = {
  title: PropTypes.string,
  listOfMarkets: PropTypes.array.isRequired,
  outcomes: PropTypes.object.isRequired
};
