import { Icon } from '@namespace/icons2';
import { useConfig } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { AText, Box } from '@alf/uikit';
import styles from './index.module.css';

const SearchBackHeader = ({ id }) => {
  const { t } = useI18n();
  const { config } = useConfig(id);
  const { title } = config;
  const { goBack } = useHistory();

  return (
    <Box align="center" padding="0 s" bottom="s" className={styles.container}>
      <Box padding="s" onClick={goBack} className={styles.back}>
        <Icon size="s" color="line_header_txt" name="icons/general/nav/back" />
      </Box>
      <AText
        breed="BU"
        size="xl"
        color="line_header_txt"
        overflow="ellipsis"
        className={styles.title}
      >
        {t(title)}
      </AText>
    </Box>
  );
};

export default SearchBackHeader;
