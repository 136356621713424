export const MOBILE_DEVICES = /(iP(hone|od)|Android(?!.*SM-T720)|webOS|Opera M(obi|ini))/i;

export const TABLET_DEVICES = /(SM-T720|tablet|ipad)|(android(?!.*mobile))/i;

export const FORM_FACTORS_MAP = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop'
};

export const LANDSCAPE = 'landscape';
export const PORTRAIT = 'portrait';
