import { SERVICE_IDS, SERVICE_IDS_TYPES_MAP } from '@namespace/bets';
import Score from '../Score';
import { useEventFields } from '../../store';
import { FINISHED_EVENT_STATUS } from '../../constants';

const LiveScore = ({ eventId, ...rest }) => {
  const { eventStatusType, scoreboard, serviceId } = useEventFields({
    eventId,
    fields: ['eventStatusType', 'scoreboard', 'serviceId']
  });

  return (
    (serviceId === SERVICE_IDS[SERVICE_IDS_TYPES_MAP.LIVE] ||
      (eventStatusType === FINISHED_EVENT_STATUS && scoreboard)) && (
      <Score eventId={eventId} {...rest} />
    )
  );
};

export default LiveScore;
