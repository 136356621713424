import { createValidationRules } from '@alf/validators';
import { useFormContext } from 'react-hook-form';
import KeyboardDatePickerField from '../KeyboardDatePickerField';
import { formValidationMap } from '../FormBuilder/validationMap';

const date = new Date();
let minDate = new Date(date.getFullYear(), date.getMonth(), 1);
const lowestMinDate = new Date(2021, 12, 1);
const maxDate = new Date(date.setFullYear(date.getFullYear() + 10));

const DATE_CUSTOM_ERROR = 'dateFieldError';
const DATE_FIELD = 'dateField';

const CardExpirationDate = ({
  isMinDateCardCheckDisabled = false,
  validationTypes,
  ...props
}) => {
  minDate = isMinDateCardCheckDisabled ? lowestMinDate : minDate;

  const { clearErrors } = useFormContext();

  const handleDateFieldBlur = (setErrorType, currentError) => {
    if (!currentError) {
      return clearErrors();
    }
    return setErrorType(DATE_FIELD, {
      type: DATE_CUSTOM_ERROR,
      message: currentError
    });
  };

  return (
    <KeyboardDatePickerField
      minDate={minDate}
      maxDate={maxDate}
      dateFormat="MM/yy"
      dateValueFormat="MM/yy"
      onBlur={handleDateFieldBlur}
      customFieldName={DATE_FIELD}
      rules={createValidationRules(validationTypes, formValidationMap)}
      {...props}
    />
  );
};

export default CardExpirationDate;
