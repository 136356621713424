import { useCallback, useState } from 'react';
import { useConfig } from '@namespace/cms';
import EventsLineHeadGroups from '../EventsLineHeadGroups';
import EventsLineTitle from '../EventsLineTitle';
import Line from '../Line';
import { useActiveHeadGroups } from '../../hooks/useActiveHeadGroups';
import EventsLineHeader from '../EventsLineHeader';
import { MODE } from '../../constants';
import { useEventField } from '../../store';
import styles from './index.module.css';

const PreMatchLine = ({
  id,
  sportId,
  eventsIdsBySport = [],
  isLoading,
  lineKey,
  serviceId,
  isExpandedDefault = false,
  isLiveEvents
}) => {
  const { config } = useConfig(id);
  const {
    isHeadGroupsSortingEnabled,
    isShowEventGroup,
    activeHeadGroupsAmount,
    isDisplayHeadMarkets = true
  } = config;

  const sportName = useEventField(eventsIdsBySport?.[0]?.[0], 'sportName');

  const [activeHeadGroups, setActiveHeadGroups] = useActiveHeadGroups(
    isHeadGroupsSortingEnabled,
    activeHeadGroupsAmount,
    sportId,
    serviceId
  );

  const [isExpanded, setIsExpanded] = useState(isExpandedDefault);
  const toggleExpanded = useCallback(
    () => setIsExpanded((prevState) => !prevState),
    []
  );

  return (
    <>
      <EventsLineHeader
        isExpanded={isExpanded}
        isCollapsible={true}
        toggleExpanded={toggleExpanded}
        className={styles.lineWrapper}
        borderBottom={true}
      >
        <EventsLineTitle
          title={sportName}
          sportId={sportId}
          toggleExpanded={toggleExpanded}
        />
        {isExpanded && (
          <EventsLineHeadGroups
            sport={{ sportId }}
            serviceId={MODE.PRE_MATCH}
            isHeadGroupsSortingEnabled={isHeadGroupsSortingEnabled}
            activeHeadGroups={activeHeadGroups}
            setActiveHeadGroups={setActiveHeadGroups}
            className={styles.headGroups}
            isDisplayHeadMarkets={isDisplayHeadMarkets}
          />
        )}
      </EventsLineHeader>
      {isExpanded && (
        <Line
          id={id}
          eventsToShow={eventsIdsBySport}
          isShowEventGroup={isShowEventGroup}
          isEventsLoading={isLoading}
          sportSlug={sportName}
          activeHeadGroups={activeHeadGroups}
          isCategoriesExpanded={true}
          lineKey={lineKey}
          isLiveEvents={isLiveEvents}
        />
      )}
    </>
  );
};

export default PreMatchLine;
