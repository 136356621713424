import PropTypes from 'prop-types';
import clsx from 'clsx';
import Box from '../Box';
import styles from './index.module.css';

const DataCard = ({
  className = '',
  commonStyle = '',
  headerClass = '',
  header,
  content,
  footer,
  children,
  onClick,
  dataRole
}) => (
  <Box
    className={clsx(styles.card, commonStyle, className)}
    direction="column"
    onClick={onClick}
    data-role={dataRole}
  >
    {children}
    {header && (
      <Box
        justify="between"
        align="center"
        padding="s"
        className={clsx(styles.header, commonStyle, headerClass)}
      >
        {header}
      </Box>
    )}
    {content && (
      <Box
        justify="between"
        align="center"
        padding="s"
        className={clsx(styles.content, commonStyle)}
      >
        {content}
      </Box>
    )}
    {footer && (
      <Box
        justify="between"
        align="center"
        padding="s"
        className={clsx(styles.footer, commonStyle)}
      >
        {footer}
      </Box>
    )}
  </Box>
);

DataCard.propTypes = {
  className: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  content: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  footer: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onClick: PropTypes.func
};

export default DataCard;
