import { useContext, useEffect } from 'react';
import { GamesContext } from '../../context';
import { useGetIsWagerSlotGamesLoaded } from '../../context/selectors';
import { useGetShowWagerSlotsCategory } from '../useGetShowWagerSlotsCategory';

export const useLoadWagerSlotsGames = () => {
  const [, { GET_WAGER_SLOT_GAMES }] = useContext(GamesContext);
  const isWagerSlotGamesLoaded = useGetIsWagerSlotGamesLoaded();
  const showWagerSlotsCategory = useGetShowWagerSlotsCategory();

  useEffect(() => {
    if (!isWagerSlotGamesLoaded && showWagerSlotsCategory) {
      GET_WAGER_SLOT_GAMES();
    }
  }, [isWagerSlotGamesLoaded, GET_WAGER_SLOT_GAMES, showWagerSlotsCategory]);
};
