import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';

const HookedUseForm = ({
  children,
  config = {},
  onSubmit = () => {},
  className = '',
  isNoValidate = false,
  autoComplete,
  rest
}) => {
  const methods = useForm(config);
  const {
    handleSubmit,
    formState: { errors }
  } = methods;
  const errorsNotPersist = isEmpty(errors);
  const submit = useCallback(
    (fields) => errorsNotPersist && onSubmit(fields, methods),
    [onSubmit, methods, errorsNotPersist]
  );

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(submit)}
        className={className}
        noValidate={isNoValidate}
        autoComplete={autoComplete}
        {...rest}
      >
        {children}
      </form>
    </FormProvider>
  );
};

export default HookedUseForm;
