import { useContext, useEffect } from 'react';
import clsx from 'clsx';

import { Box } from '@alf/uikit';
import { PageContext } from '@namespace/cms';
import { IS_WIDGET } from '@namespace/widget';
import appInIframe from '@namespace/helpers/src/utils/appInIframe';
import { useSetBodyClass } from '@namespace/helpers';
import { layoutMap } from './layoutMap';
import styles from './index.module.css';

export const Layout = () => {
  const { page } = useContext(PageContext);
  const { layout = {} } = page;
  const { type = '' } = layout;
  const LayoutComponent = layoutMap[type] || null;
  const inIframe = appInIframe() && IS_WIDGET;
  const { setBodyClass } = useSetBodyClass();

  useEffect(() => {
    if (inIframe) {
      setBodyClass(styles.isWidgetBodyClass);
    }
  }, []);

  return (
    LayoutComponent && (
      <Box
        flex="1 1 auto"
        className={clsx(
          styles.container,
          inIframe && styles.isWidget,
          styles[type]
        )}
      >
        <LayoutComponent />
      </Box>
    )
  );
};
