import { useState, useCallback, useEffect, createContext } from 'react';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';

export const FixScrollContext = createContext();

export const FixScrollProvider = ({ children }) => {
  const [layers, setLayers] = useState([]);
  const addLayer = useCallback(
    (layer) => setLayers((prevLayers) => [...prevLayers, layer]),
    []
  );
  const removeLayer = useCallback(
    (layer) => setLayers((prevLayers) => prevLayers.filter((l) => l !== layer)),
    []
  );

  useEffect(() => {
    if (!layers.length) {
      clearAllBodyScrollLocks();
    }
  }, [layers]);

  return (
    <FixScrollContext.Provider value={[layers, addLayer, removeLayer]}>
      {children}
    </FixScrollContext.Provider>
  );
};
