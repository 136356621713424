import { formatAsUriParameters } from '@namespace/helpers';
import { useConfig } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { SectionButton } from '../../common';

const GoToTournamentButton = ({ id }) => {
  const { config } = useConfig(id);
  const {
    filterPathname = '',
    tournamentId = '',
    title = '',
    sportSlug = ''
  } = config;
  const { t } = useI18n();
  const filter = formatAsUriParameters({ tournaments: [tournamentId] }, true);

  return (
    <SectionButton
      link={`${filterPathname}/${sportSlug}/?${filter}`}
      labelSecondPart={t(title)}
      dataRole="go-to-tournament-btn"
    />
  );
};

export default GoToTournamentButton;
