import { memo, useCallback } from 'react';
import { scrollTo } from '@namespace/helpers';
import MenuItem from '../../MenuItem';
import { useCategory } from '../../../store';

const CategoryItem = ({
  categoryId = null,
  serviceId,
  onClick = () => {},
  toggleMenu = () => {},
  isActive,
  expandable,
  isExpanded,
  className = ''
}) => {
  const { categoryName, countryId, eventCount } = useCategory(
    categoryId,
    serviceId
  );

  const handleClick = useCallback(() => {
    if (!isActive) {
      scrollTo();
    }
    onClick();
    if (isActive) {
      toggleMenu();
    }
  }, [isActive, onClick]);

  return (
    <MenuItem
      name={categoryName}
      count={eventCount}
      countryId={countryId}
      onClick={handleClick}
      toggleMenu={toggleMenu}
      className={className}
      isActive={isActive}
      expandable={expandable}
      isExpanded={isExpanded}
      withCounter={!isActive || !expandable}
      withExpandIcon={isActive && expandable}
      dataAttr={{ 'data-role': `category-id-${categoryId}` }}
    />
  );
};

export default memo(CategoryItem);
