import { SiteSettingsContext } from '@namespace/cms';
import { useContext } from 'react';

export const useGetProviderJackpotCoef = (jackpotIdentifier) => {
  const [settings] = useContext(SiteSettingsContext);
  const {
    otherSettings: { casinoProvidersJackpotsCoefs = {} }
  } = settings ?? {};
  return casinoProvidersJackpotsCoefs[jackpotIdentifier] ?? 1;
};
