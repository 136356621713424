export const LINE_VIEW_TYPES = {
  TABLE: 'table',
  BUTTON: 'button'
};

export const REDIRECT_ACTIONS = {
  SET: 'setRedirectAction',
  GET: 'getRedirectAction',
  APPLY: 'applyRedirectAction'
};

export const REDIRECT_PARAM = 'redirectUrl=';

// TODO use values from sports package from SPORT_IDS instead of VERMANTIA_SPORT_ID and BONUS_SPORT_ID
export const VERMANTIA_SPORT_ID = 179;

export const SUB_MENU_ITEMS = {
  NEED_HELP: 'needHelp'
};

export const SUB_MENU_ICON_ITEMS = ['needHelp', 'liveCalendar'];

export const DEFAULT_PAGE_SIZE = 10;

export const FILTER_ALL_VALUE = 'all';

export const DATE_FILTER = [
  {
    value: '2',
    label: 'date.todayYesterday'
  },
  {
    value: '7',
    label: 'date.for7days'
  },
  {
    value: '30',
    label: 'date.for30days'
  },
  {
    value: '90',
    label: 'date.for90days'
  }
];

export const DATE_RANGE_FILTER_VALUE = 'dtRange';

export const PURCHASE_STATUS_FILTER = [
  {
    value: FILTER_ALL_VALUE,
    label: 'purchaseHistory.status.all'
  },
  {
    value: 'pending',
    label: 'purchaseHistory.status.pending'
  },
  {
    value: 'canceled',
    label: 'purchaseHistory.status.canceled'
  },
  {
    value: 'completed',
    label: 'purchaseHistory.status.completed'
  }
];

export const STEP_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error'
};

export const LINK_TYPE_RAW = 'raw';

export const HEADER_HEIGHT = 48;
export const DESKTOP_HEADER_HEIGHT = 60;

export const BOTTOM_NAV_MENU_HEIGHT = 56;
