import PropTypes from 'prop-types';
import AText from '../AText';
import styles from './index.module.css';

const Label = ({ className = '', htmlFor, children, ...props }) => (
  <AText
    component="label"
    breed="R"
    size="m"
    htmlFor={htmlFor}
    className={`${styles.label} ${className}`}
    {...props}
  >
    {children}
  </AText>
);

Label.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.string
  ]),
  htmlFor: PropTypes.string,
  className: PropTypes.string
};

export default Label;
