import { camelizeKeys } from 'humps';
import { isEmpty } from 'lodash';

const isErrorInResponse = (response) =>
  (response.error !== undefined && response.error !== 'no') ||
  !isEmpty(response.errorCode) ||
  (response.status === 'error' && response.status === 'fail') ||
  (response?.status >= 400 && response?.status < 600);

export const defaultResponseHandler = (response, resolve, reject) =>
  !isErrorInResponse(response)
    ? resolve(response)
    : reject(camelizeKeys(response));

export const ihubResponseHandler = (response, resolve, reject) =>
  !isErrorInResponse(response)
    ? resolve(response)
    : reject(camelizeKeys(response));

// TODO this is hardly exactly "bettorrent" format, but we get it with new API. I think this should not exist in the first place.
export const bettorrentResponseHandler = (response, resolve, reject) =>
  !isErrorInResponse(response)
    ? resolve(response)
    : reject(camelizeKeys(response));
