import { useContext } from 'react';
import { UserContext } from '../../context';

export const useStatuses = () => {
  const [user] = useContext(UserContext);
  const { accountStatuses = {} } = user;
  const {
    isPassportInVerification,
    isAdditionalInformationRequired,
    isPassportVerifiedByRisk
  } = accountStatuses;
  const isNotVerified =
    !isPassportInVerification &&
    !isAdditionalInformationRequired &&
    !isPassportVerifiedByRisk;

  return {
    ...accountStatuses,
    isNotVerified
  };
};
