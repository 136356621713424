import { useContext } from 'react';
import { PageContext } from '@namespace/cms';
import { MultiColumnContent } from '../common/MultiColumnContent';
import styles from '../index.module.css';

export const MultiColumns = () => {
  const { page } = useContext(PageContext);
  const { layout = {} } = page;
  const { columns = [] } = layout;

  return (
    <MultiColumnContent columns={columns} columnClassName={styles.column} />
  );
};
