import { AText, Accordion, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import styles from './index.module.css';

export const Wrapper = ({ withAccordion, children }) => {
  const { t } = useI18n();

  return withAccordion ? (
    <Accordion
      dataRole="tax-accordion"
      title={
        <AText
          breed="R"
          size="m"
          color="input_label_txt"
          data-role="tax-accordion-title"
        >
          {t('web.TaxInfo.label')}
        </AText>
      }
      withArrows={true}
      arrowsColor="input_label_txt"
      arrowsSize="xs"
      classNames={{
        headerClassName: styles.header,
        bodyClassName: styles.body,
        containerClassName: styles.container
      }}
    >
      <Box data-role="tax-accordion-content">{children}</Box>
    </Accordion>
  ) : (
    children
  );
};
