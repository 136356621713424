import { List } from '@alf/uikit';
import { LocalizedNavLink, useLocation } from '@namespace/i18n';
import { addSlashToPathEnd } from '@namespace/helpers';

const CoreList = ({
  items = [],
  isPartialLinkMatch = false,
  isShowPinned,
  classNames,
  isActive,
  onClick,
  ...props
}) => {
  const { pathname } = useLocation();

  const isActiveDefault = (link) => {
    return isPartialLinkMatch
      ? pathname.includes(link)
      : pathname === addSlashToPathEnd(link);
  };

  return (
    <List
      classNames={classNames}
      items={items}
      isShowPinned={isShowPinned}
      LocalizedNavLink={LocalizedNavLink}
      isActive={isActive || isActiveDefault}
      onClick={onClick}
      dataRole="burger-menu-list"
      {...props}
    />
  );
};

export default CoreList;
