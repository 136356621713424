import { useContext, useMemo } from 'react';
import { ReferenceContext } from '../../store/reference/context';
import getCodeByISOCode from '../../utils/getCodeByISOCode';

const usePhoneCode = (isoCode) => {
  const { phoneCodes = [] } = useContext(ReferenceContext);

  const phoneCode = useMemo(() => getCodeByISOCode(phoneCodes, isoCode), [
    phoneCodes,
    isoCode
  ]);

  return phoneCode;
};

export default usePhoneCode;
