/**
 * @typedef TourPrecondition
 * @type {Object}
 * @property {String} [url] - start if url matches. Supports react-router matchPath format.
 * @property {Boolean} [matchExact=true] - should match url exactly
 * @property {Boolean} [isAfterFirstBet] - start tour after first bet was made
 */

/**
 * @typedef TourPlatformOverride
 * @type {Object}
 * @property {Object<TourStep>} steps An object whose keys are step ids and values are step overrides.
 */

/**
 * @typedef Tour
 * @type {Object}
 * @property {String} id tour Id
 * @property {Array<TourStep>} steps tour steps
 * @property {Array<TourPrecondition>} preConditions tour automatic launch preconditions
 * @property {String} [redirectToPage] page to redirect user to after tour completion
 * @property {Boolean} [hideFirstItem] (a hack) Don't show first dot on tooltip. Used with step prop `showDots: false` to make some kind of "intro" step out of general first step.
 * @property {TourPlatformOverride} [mobile] platform override for mobile and tablet
 */

/**
 * @type {Tour}
 */
export const tour = {};
