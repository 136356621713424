import { Box } from '@alf/uikit';
import { LocalizedLink } from '@namespace/i18n';

import styles from './index.module.css';

export const DesktopMessage = ({
  link,
  actionText,
  primaryText,
  onClose = () => {}
}) => {
  return (
    <Box direction="column" align="center" justify="center">
      <span>
        {primaryText}

        <LocalizedLink onClick={onClose} className={styles.link} to={link}>
          {actionText}
        </LocalizedLink>
      </span>
    </Box>
  );
};
