export const i18nTypes = {
  SET_LOCALE: 'SET_LOCALE',
  SET_TIMEZONE: 'SET_TIMEZONE',
  SET_LANG_TRANSLATIONS: 'SET_LANG_TRANSLATIONS',
  SET_TIMEZONES: 'SET_TIMEZONES',
  FETCH_TIMEZONES: 'FETCH_TIMEZONES',
  CHANGE_TIMEZONE: 'CHANGE_TIMEZONE'
};

export const i18nReducers = {
  [i18nTypes.SET_LANG_TRANSLATIONS]: (state, { translations, lang }) => ({
    ...state,
    translationsMap: { ...state.translationsMap, [lang]: translations },
    language: lang
  }),
  [i18nTypes.SET_LOCALE]: (state, locale) => ({
    ...state,
    locale
  }),
  [i18nTypes.SET_TIMEZONE]: (state, { timezone }) => ({
    ...state,
    timezone
  }),
  [i18nTypes.SET_TIMEZONES]: (state, { timeZones }) => ({
    ...state,
    timeZones
  })
};
