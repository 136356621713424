import { get } from 'lodash';
import isBetradarProvider from '../../utils/isBetradarProvider';
import {
  formatProviderData,
  normaliseBetradarVirtualMarkets,
  normaliseVirtualMarkets,
  parseVsGamesFeed
} from '../normalize';
import types from '../types';

export const initialState = {
  virtualSports: {},
  virtualTournaments: {},
  virtualEvents: {},
  virtualStreams: {},
  virtualMarkets: {},
  virtualOutcomes: {},
  feed: {
    games: { data: {}, ids: [], isLoaded: false },
    categories: { data: {}, ids: [], isLoaded: false },
    providers: { data: {}, ids: [], isLoaded: false },
    isLoading: false,
    isLoaded: false,
    platform: null
  },
  isLoaded: false
};

export const reducers = {
  [types.SET_VIRTUAL_DATA]: (state, data = []) => {
    const { virtualSports, virtualTournaments, virtualEvents } = data.reduce(
      // TODO get rid of extra cycles inside format functions
      (memo, { provider, providerSports }) => {
        const providerData = formatProviderData[provider](
          providerSports,
          provider
        );
        return {
          virtualSports: {
            ...memo.virtualSports,
            [provider]: providerData.virtualSports
          },
          virtualTournaments: {
            ...memo.virtualTournaments,
            [provider]: providerData.virtualTournaments
          },
          virtualEvents: {
            ...memo.virtualEvents,
            [provider]: providerData.virtualEvents
          }
        };
      },
      {}
    );

    return {
      ...state,
      virtualSports,
      virtualTournaments,
      virtualEvents,
      isLoaded: true
    };
  },

  [types.SET_VIRTUAL_STREAM]: (
    state,
    { provider, eventId, tournamentId, url, categoryId }
  ) => {
    let streamUrl = url;
    if (isBetradarProvider(provider)) {
      const eventIndex = Object.values(state.virtualEvents[provider] || {})
        .filter((e) => +tournamentId === +e.tournamentId)
        .sort((a, b) => a.eventEnetId - b.eventEnetId)
        .findIndex((e) => +e.eventId === +eventId);
      const streamIndex = eventIndex < 0 ? 0 : eventIndex;

      streamUrl = `https://vflvideo.aitcloud.de/live/vfl3_favbet-320x240-vbr30-mobile-v2_channel${streamIndex}/playlist.m3u8`;
    }

    return {
      ...state,
      virtualStreams: {
        ...state.virtualStreams,
        [provider]: {
          ...state.virtualStreams[provider],
          [isBetradarProvider(provider) ? eventId : categoryId]: streamUrl
        }
      }
    };
  },

  [types.SET_VIRTUAL_EVENT_MARKETS]: (
    state,
    { eventId, provider, markets: eventMarkets }
  ) => {
    let newMarkets;
    let newOutcomes;

    if (isBetradarProvider(provider)) {
      const marketName = get(
        state,
        `virtualEvents[${provider}][${eventId}].headMarket.marketName`
      );

      const {
        updatedMarkets,
        updatedOutcomes
      } = normaliseBetradarVirtualMarkets(eventMarkets, eventId);

      const { outcomes = [], ...headMarket } = marketName
        ? state.virtualEvents[provider][eventId].headMarket
        : {};

      newMarkets = [
        ...(marketName
          ? [
              {
                ...headMarket,
                outcomeIds: outcomes.map(({ outcomeId }) => outcomeId),
                marketSuspend: headMarket.marketSuspend === 'yes',
                serviceId: 20,
                eventId
              }
            ]
          : []),
        ...updatedMarkets
      ];
      newOutcomes = {
        ...updatedOutcomes,
        ...outcomes.reduce(
          (outcomesAcc, outcome) => ({
            ...outcomesAcc,
            [outcome.outcomeId]: outcome
          }),
          {}
        )
      };
    } else {
      const { markets = [], ...rest } = eventMarkets?.providerEvent || {};
      const { updatedMarkets, updatedOutcomes } = normaliseVirtualMarkets(
        markets,
        rest
      );
      newMarkets = updatedMarkets;
      newOutcomes = updatedOutcomes;
    }

    return {
      ...state,
      virtualMarkets: {
        ...state.virtualMarkets,
        [provider]: {
          ...state.virtualMarkets[provider],
          [eventId]: newMarkets
        }
      },
      virtualOutcomes: newOutcomes
    };
  },

  [types.SET_VIRTUAL_SPORTS_FEED]: (state, { data, platform, feedName }) => {
    const parsedFeed = parseVsGamesFeed({ data, feedName });

    return {
      ...state,
      feed: {
        ...state.feed,
        ...parsedFeed,
        feedName,
        platform,
        isLoaded: true,
        isLoading: false
      }
    };
  },
  [types.SET_IS_VIRTUAL_SPORTS_FEED_LOADING]: (state, { isLoading }) => ({
    ...state,
    feed: {
      ...state.feed,
      isLoading
    }
  })
};
