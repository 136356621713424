import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { LocalizedNavLink } from '@namespace/i18n';
import { AText, Box } from '@alf/uikit';
import styles from './index.module.css';

const CommonTopEventsLink = ({
  growsMap,
  growIndex,
  isShowImages,
  icon,
  presetId,
  translation,
  link
}) => (
  <LocalizedNavLink
    onClick={() =>
      logAction(LOG_ACTIONS.TOP_EVENTS, {
        ItemId: presetId,
        ItemName: translation
      })
    }
    key={presetId}
    className={`${styles.eventContainer} ${
      styles[`grow${growsMap[growIndex]}`]
    }`}
    activeClassName={styles.active}
    to={link}
  >
    <Box
      align="center"
      className={styles.event}
      data-role={`top-event-${presetId}`}
    >
      {isShowImages && (
        <i className={styles.icon}>
          <img className={styles.iconImage} src={icon} alt="icon" />
        </i>
      )}
      <AText breed="B" transform="uppercase" className={styles.title}>
        {translation}
      </AText>
    </Box>
  </LocalizedNavLink>
);

export default CommonTopEventsLink;
