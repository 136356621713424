import { useMemo } from 'react';
import { flatten, isEmpty } from 'lodash';
import { useMatchPath } from '@namespace/router';
import { useGeneralRoutes } from '@namespace/cms';
import { useLocation } from '@namespace/i18n';
import { normalizePath } from '@namespace/helpers';
import { MODE, SPORT_IDS } from '../../constants';
import { useSportsbookStore } from '../../store';

export const useCyberSportGetPathParams = () => {
  const { pathname } = useLocation();
  const {
    cyberSportCategory,
    cyberSportTournament,
    cyberSportFavorites,
    cyberSport
  } = useGeneralRoutes();
  const paths = [
    `${cyberSportCategory}/:categoryId`,
    `${cyberSportTournament}/:categoryId/:tournamentId`
  ];

  const normalizedPath = normalizePath(pathname);
  const IS_FAVORITES = normalizedPath === normalizePath(cyberSportFavorites);
  const IS_ALL_SPORTS = normalizePath(pathname) === normalizePath(cyberSport);
  const routeParams = useMatchPath(paths);
  const { categoryId, tournamentId } = routeParams;

  // todo refactor
  const { sports, categories, tournaments } = useSportsbookStore([
    'sports',
    'categories',
    'tournaments'
  ]);

  const getActiveSport = useMemo(
    () =>
      flatten(Object.values(sports)).find(
        ({ sportId }) => sportId === SPORT_IDS.CYBERSPORT
      ) || {},
    [sports]
  );
  const getActiveCategory = useMemo(() => {
    if (isEmpty(categories)) {
      return null;
    }
    return (
      categories[MODE.PRE_MATCH]?.data[categoryId] ||
      categories[MODE.IN_PLAY]?.data[categoryId] ||
      null
    );
  }, [categories, categoryId]);

  const getActiveTournament = useMemo(() => {
    if (isEmpty(tournaments)) {
      return null;
    }
    return (
      tournaments[MODE.PRE_MATCH]?.data[tournamentId] ||
      tournaments[MODE.IN_PLAY]?.data[tournamentId] ||
      null
    );
  }, [tournaments, tournamentId]);

  return {
    activeSport: getActiveSport,
    category: IS_FAVORITES ? { categoryId: 'favorites' } : getActiveCategory,
    tournament: getActiveTournament,
    isAllSports: IS_ALL_SPORTS,
    isFavorites: IS_FAVORITES,
    isPathExist: !isEmpty(routeParams)
  };
};
