import { useEffect, useContext, useCallback } from 'react';
import useNotifications from '@namespace/notifications/src/hooks/useNotifications';
import { NOTIFICATION_TYPES } from '@namespace/notifications/src/constants';
import { usePrevious } from '@namespace/helpers';

import { UserContext } from '../../context';

const useLudomanInVerification = (isInit = false) => {
  const { customNotification } = useNotifications();
  const [user] = useContext(UserContext);
  const { accountStatuses = {} } = user;
  const { isLudomanInVerification } = accountStatuses;
  const shouldShowStatusBasedNotification = isInit && isLudomanInVerification;
  const isLudomanInVerificationPrev = usePrevious(isLudomanInVerification);

  useEffect(() => {
    if (shouldShowStatusBasedNotification) {
      customNotification({
        notificationType: NOTIFICATION_TYPES.LUDOMAN_IN_VERIFICATION,
        timeout: null
      });
    }
  }, [shouldShowStatusBasedNotification, customNotification]);

  useEffect(() => {
    if (isLudomanInVerificationPrev && !isLudomanInVerification) {
      customNotification({
        notificationType: NOTIFICATION_TYPES.LUDOMAN_IN_VERIFICATION,
        isShown: false
      });
    }
  }, [
    isLudomanInVerification,
    isLudomanInVerificationPrev,
    customNotification
  ]);

  const handleShowNotification = useCallback(() => {
    if (isLudomanInVerification) {
      customNotification({
        notificationType: NOTIFICATION_TYPES.LUDOMAN_IN_VERIFICATION,
        timeout: null
      });
    }
  }, [customNotification, isLudomanInVerification]);

  return {
    showNotification: handleShowNotification
  };
};

export default useLudomanInVerification;
