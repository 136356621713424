import { useContext, useEffect, useCallback, useState } from 'react';
import { UserContext } from '@namespace/user/src/context';
import { DeviceContext } from '@namespace/device';
import { window, asyncAttempt } from '@namespace/helpers';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { SiteSettingsContext, useSupportParamsSettings } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { updateFreshChatUser } from '../../api/updateFreshChatUserData';
import useFreshChatUserData from './useFreshChatUserData';
import useFCLoader from './utils/script-injector';

const DEFAULT_SUPPORTED_LANGUAGES = [];

const FRESH_CHAT_LOADED_EVENT = 'widget:loaded';
const FRESH_CHAT_USER_CREATED_EVENT = 'user:created';

// dummy callback for snippets which doesn't know when fresh-chat get initialized
window.ShowSupport = () => {};

const useFreshChat = () => {
  const [isChatInitialized, setIsChatInitialized] = useState(false);
  const [isFCSettingsInit, setIsFCSettingsInit] = useState(false);
  const [isFreshChatLoaded, setIsFreshChatLoaded] = useState(false);

  const [user] = useContext(UserContext);
  const [settings = {}] = useContext(SiteSettingsContext);
  const { supportSettings = {} } = settings || {};
  const { isDesktopDevice } = useContext(DeviceContext);
  const { supportFreshChat } = supportSettings;
  const otherSettingsSupport = useSupportParamsSettings();
  const { token, host, jsSDKSrc } = otherSettingsSupport;

  const { language } = useI18n();
  const userFreshData = useFreshChatUserData();

  const handleSettingsInit = useCallback(() => {
    setIsFCSettingsInit(true);

    window.fcWidget.setConfig({
      fullscreen: !isDesktopDevice,
      headerProperty: {
        hideChatButton: true
      }
    });
  }, [isDesktopDevice]);

  useFCLoader({
    token,
    host,
    jsSDKSrc,
    fullscreen: !isDesktopDevice,
    onComplete: handleSettingsInit
  });

  window.ShowSupport = () => {
    window.fcWidget.open();
    logAction(LOG_ACTIONS.SUPPORT);
  };

  const { supportedLanguages = DEFAULT_SUPPORTED_LANGUAGES, defaultLanguage } =
    supportFreshChat || {};

  const setChatLanguage = useCallback(
    (value) => {
      const currentLanguage = supportedLanguages.includes(value)
        ? value
        : defaultLanguage;

      window.fcWidget.user.setLocale(currentLanguage);
    },
    [defaultLanguage, supportedLanguages]
  );

  useEffect(() => {
    if (isChatInitialized) {
      setChatLanguage(language);
    }
  }, [setChatLanguage, language, isChatInitialized]);

  const initialize = useCallback(
    (opts) => {
      const { fcWidget } = window;

      setIsChatInitialized(true);

      if (opts.userFreshData?.data) {
        const {
          externalId,
          firstName,
          email,
          isVip,
          phoneCode,
          phoneNumber
          // restoreId // TODO: apply restoreId
        } = opts.userFreshData.data;

        // window.fcWidgetMessengerConfig.restoreId = restoreId;

        const phone = `+${phoneCode}${phoneNumber}`;

        fcWidget.setExternalId(externalId);
        fcWidget.user.setFirstName(firstName);
        fcWidget.user.setEmail(email);
        fcWidget.user.setProperties({
          cf_vip: isVip,
          cf_account_id: user.id,
          phone
        });
        window.fcWidget.setConfig({
          headerProperty: {
            hideChatButton: true
          }
        });
      }
    },
    [user.id]
  );

  useEffect(() => {
    if (userFreshData && isFreshChatLoaded) {
      initialize({ language, userFreshData });
    }
  }, [initialize, language, userFreshData, isFreshChatLoaded]);

  useEffect(() => {
    if (!isFCSettingsInit) return undefined;

    window.fcWidget.on(FRESH_CHAT_USER_CREATED_EVENT, async (resp) => {
      const status = resp?.status;
      const { restoreId } = resp?.data ?? {};
      const isUserExists = userFreshData?.data?.externalId;
      const isSucceeded = status === 200;

      if (isSucceeded && restoreId && isUserExists) {
        await asyncAttempt(updateFreshChatUser, { restoreId });
      }
    });

    window.fcWidget.on(FRESH_CHAT_LOADED_EVENT, () => {
      setIsFreshChatLoaded(true);
    });

    return () => {
      window.fcWidget.off(FRESH_CHAT_LOADED_EVENT);
      window.fcWidget.off(FRESH_CHAT_USER_CREATED_EVENT);
    };
  }, [isFCSettingsInit, userFreshData]);
};

export default useFreshChat;
