import { document } from '../../constants';

export const waitForElement = (selector, { timeout = 2_000, checkEl } = {}) =>
  new Promise((resolve, reject) => {
    if (!selector) {
      reject(Error('Empty selector'));
    }

    let frame;

    const timer = setTimeout(() => {
      cancelAnimationFrame(frame);
      reject(Error(['Not found', selector].join()));
    }, timeout);

    const getEl = () => {
      const el = document.querySelector(selector);

      if (el && (!checkEl || checkEl(el))) {
        clearTimeout(timer);
        resolve(el);
      } else {
        frame = requestAnimationFrame(getEl);
      }
    };

    getEl();
  });
