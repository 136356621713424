import { useCallback, useState, useMemo } from 'react';
import { isEmpty } from 'lodash';
import HookedForm from '../../common/HookedForm';
import HookedUseForm from '../../common/HookedUseForm';
import Component from './buildComponents';
import addGlobalErrorComponent from '../../utils/addGlobalErrorComponent';
import { DEFAULT_FORM_CONFIG } from '../../constants';
import styles from './index.module.css';

const FormBuilder = ({
  components = [],
  componentsMap = {},
  validationMap = {}, // todo check if this prop need
  formConfig = {},
  onSubmit = () => {},
  formMethods = {},
  dataRole = '',
  formProps = {},
  children,
  wrapperClassName = '',
  isNoValidate = false,
  hideButton,
  autoComplete = 'off',
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitButton = useCallback(
    async (fields, methods) => {
      setIsLoading(true);
      await onSubmit(fields, methods);
      setIsLoading(false);
    },
    [onSubmit]
  );

  const newComponents = useMemo(() => addGlobalErrorComponent(components), [
    components
  ]);

  if (!isEmpty(formMethods)) {
    return (
      <HookedForm
        formMethods={formMethods}
        onSubmit={onSubmitButton}
        data-role={dataRole}
        className={`${wrapperClassName} ${hideButton ? styles.hideButton : ''}`}
        isNoValidate={isNoValidate}
        autoComplete={autoComplete}
        rest={rest}
      >
        {Component({
          components: newComponents,
          componentsMap,
          validationMap,
          formProps: { isLoading, ...formProps }
        })}
        {children}
      </HookedForm>
    );
  }

  return (
    <HookedUseForm
      config={{ ...DEFAULT_FORM_CONFIG, ...formConfig }}
      onSubmit={onSubmitButton}
      className={`${wrapperClassName} ${hideButton ? styles.hideButton : ''}`}
      data-role={dataRole}
      isNoValidate={isNoValidate}
      autoComplete={autoComplete}
      rest={rest}
    >
      {Component({
        components: newComponents,
        componentsMap,
        validationMap,
        formProps: { ...formProps, isLoading: formProps.isLoading || isLoading }
      })}
      {children}
    </HookedUseForm>
  );
};

export default FormBuilder;
