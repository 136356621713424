import { useCallback, useRef, useState } from 'react';
import { useConfig, useGeneralRoutes } from '@namespace/cms';
import { FILTER_ALL_VALUE, useFixScroll } from '@namespace/common';
import { Box, Overlay } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { BackHeaderCommon, CategoriesTitle } from '../../common';
import { useCyberSportGetPathParams } from '../../hooks/useCyberSportGetPathParams';
import useEventsTimeFilter from '../../hooks/useEventsTimeFilter';
import useChangeDefaultFilter from '../../hooks/useChangeDefaultFilter';
import { LINE_KEY, MODE } from '../../constants';
import CyberSportLine from './CyberSportLine';
import CyberSportCategories from '../../common/CyberSportCategories';
import styles from './index.module.css';

// TODO make one hook to reuse all this stuff
const CyberSportPreMatchTournamentEventsExtended = ({ id }) => {
  const containerRef = useRef(null);
  const [isCategoriesOpened, setIsCategoriesOpened] = useState(false);
  const toggleCategories = () => setIsCategoriesOpened((prev) => !prev);
  const { activeSport, category, tournament } = useCyberSportGetPathParams();
  const { cyberSportTournament } = useGeneralRoutes();
  const { categoryId, categoryName } = category || {};
  const { sportId } = activeSport;
  const { tournamentName = '', tournamentId } = tournament || {};
  const { config } = useConfig(id);

  const {
    filters,
    isHeadGroupsSortingEnabled,
    activeHeadGroupsAmount,
    isShowStartingSoon
  } = config;
  const { isShowAll, default: defaultFilter, ...filterConfig } = filters;
  const { filter, setFilter } = useEventsTimeFilter({
    initialFilter: {
      [defaultFilter]: defaultFilter
    },
    filterConfig
  });
  const isChangeDefaultFilter = useChangeDefaultFilter();
  const [isTodayFilterApplied, setIsTodayFilterApplied] = useState(false);

  useFixScroll('CategoriesDrawer', containerRef, isCategoriesOpened);
  const liveLineKey = `${LINE_KEY.CYBERSPORT_LIVE}-${sportId}-${tournamentId}`;
  const preMatchLineKey = `${LINE_KEY.CYBERSPORT_PRE_MATCH}-${sportId}-${tournamentId}`;

  const handleDefaultFilterChange = useCallback(() => {
    if (isChangeDefaultFilter && !isTodayFilterApplied) {
      setFilter({ [FILTER_ALL_VALUE]: FILTER_ALL_VALUE });
      setIsTodayFilterApplied(true);
    }
  }, [isChangeDefaultFilter, isTodayFilterApplied, setFilter]);

  return (
    <Box direction="column" className={styles.container}>
      <BackHeaderCommon
        arrowBackIcon={
          <Icon
            size="s"
            name="icons/general/nav/back"
            color="line_header_txt"
          />
        }
        Title={() => (
          <CategoriesTitle
            categoryName={categoryName}
            sportName={tournamentName}
            onToggleMenu={toggleCategories}
            isCategoriesOpened={isCategoriesOpened}
          />
        )}
        sportId={categoryId || sportId}
      />
      <Box direction="column" className={styles.container}>
        {isCategoriesOpened && (
          <Overlay
            onClick={toggleCategories}
            classNames={{
              contentClass: styles.overlay
            }}
          >
            <CyberSportCategories
              categoryRoute={cyberSportTournament}
              activeTournamentId={tournamentId}
              categoryId={categoryId}
              ref={containerRef}
            />
          </Overlay>
        )}
        <CyberSportLine
          key={liveLineKey}
          id={id}
          sport={activeSport}
          category={category}
          serviceId={MODE.IN_PLAY}
          tournament={tournament}
          lineKey={liveLineKey}
          isHeadGroupsSortingEnabled={isHeadGroupsSortingEnabled}
          activeHeadGroupsAmount={activeHeadGroupsAmount}
          isDisplayFilter={false}
          isShowEmpty={false}
          isShowFlag={false}
          isTodayFilterApplied={isTodayFilterApplied}
          onDefaultFilterChange={handleDefaultFilterChange}
        />
        <CyberSportLine
          key={preMatchLineKey}
          id={id}
          sport={activeSport}
          category={category}
          serviceId={MODE.PRE_MATCH}
          tournament={tournament}
          lineKey={preMatchLineKey}
          isHeadGroupsSortingEnabled={isHeadGroupsSortingEnabled}
          isShowAll={isShowAll}
          defaultFilter={defaultFilter}
          isShowStartingSoon={isShowStartingSoon}
          filterConfig={filterConfig}
          activeHeadGroupsAmount={activeHeadGroupsAmount}
          filter={filter}
          setFilter={setFilter}
          isDisplayFilter={true}
          isShowFlag={false}
          isShowEmpty={true}
          isTodayFilterApplied={isTodayFilterApplied}
          onDefaultFilterChange={handleDefaultFilterChange}
        />
      </Box>
    </Box>
  );
};

export default CyberSportPreMatchTournamentEventsExtended;
