import { Box, EmptyState } from '@alf/uikit';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { Image } from '@namespace/icons2';
import { SportbookSkelet } from '../../../../common';
import ButtonLoader from '../../../../common/Pagination/ButtonLoader';
import styles from '../../index.module.css';
import { EventRow } from './components/EventRow';

export const EventsLine = ({
  eventsData,
  showMore,
  isEventsLoading,
  config,
  isLiveEvents,
  dataRole
}) => {
  const { t } = useI18n();
  const { events = [], isMoreEvents } = eventsData;

  return (
    <div>
      {isEventsLoading && !events.length ? (
        <SportbookSkelet />
      ) : (
        <>
          {events.length > 0 && (
            <Box data-role={dataRole} direction="column">
              {events.map((event) => (
                <EventRow
                  dataRole="live-calendar-event"
                  key={event.eventId}
                  event={event}
                  config={config}
                  isLiveEvents={isLiveEvents}
                />
              ))}
              <ButtonLoader
                dataRole="live-calendar-btn-show-more"
                isShow={isMoreEvents}
                onNextPage={showMore}
                isLoading={isEventsLoading}
              />
            </Box>
          )}
          {!events.length && (
            <Box
              data-role={dataRole && `${dataRole}-empty-state`}
              direction="column"
            >
              <EmptyState
                image={<Image name="general/looking_for" />}
                Link={LocalizedLink}
                classNames={{ textClassName: styles.emptyStateText }}
                text={t('sportsbook.emptyState.noEvents')}
              />
            </Box>
          )}
        </>
      )}
    </div>
  );
};
