import { useCallback, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { UserContext } from '@namespace/user/src/context';
import { window, document } from '@namespace/helpers';
import Chat from './components/Chat';
import Button from './components/ChatButton';
import useGetChatSocketUrl from './hooks/useGetChatSocketUrl';

const SupportChat = ({
  buttonClassName = '',
  ChatButton = Button,
  ...customChatButtonProps
}) => {
  const [chatShown, setChatShown] = useState(false);
  const chatSocketUrl = useGetChatSocketUrl();
  const [user] = useContext(UserContext);
  const { role, firstName, lastName, id: userId } = user;
  const userInfo =
    role === 'user' ? { name: `${firstName} ${lastName}`, id: userId } : null;

  useEffect(() => {
    window.chatShown = false;
  }, []);

  const toggleChat = useCallback(() => {
    window.chatShown = !chatShown;
    setChatShown(!chatShown);
  }, [chatShown, setChatShown]);

  const onToggleChat = useCallback(() => {
    if (!window.chatShown) {
      toggleChat();
    }
  }, [toggleChat]);

  return (
    <>
      {chatShown &&
        createPortal(
          <Chat
            toggleChat={toggleChat}
            socketUrl={chatSocketUrl}
            userInfo={userInfo}
          />,
          document.body
        )}

      <ChatButton
        className={buttonClassName}
        toggleChat={onToggleChat}
        {...customChatButtonProps}
      />
    </>
  );
};
export default SupportChat;
