const prepareBonusDetailsList = (details) =>
  details.reduce(
    (memo, { isDisplay, name, value, ...rest }) =>
      isDisplay
        ? [
            ...memo,
            {
              ...rest,
              name,
              value
            }
          ]
        : memo,
    []
  );

export default prepareBonusDetailsList;
