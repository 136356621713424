import { useConfig } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { Box, AText } from '@alf/uikit';
import Snippet from '../Snippet';
import styles from './index.module.css';

const SnippetWrapper = ({ id, isFooter, isCustomFooter, config = {} }) => {
  const { config: pageConfig } = useConfig(id);
  const { id: snippetId, title } = { ...config, ...pageConfig };
  const { t } = useI18n();

  if (title) {
    return (
      <Box className={styles.wrapper}>
        <Box direction="column" className={styles.page}>
          <AText
            breed="BU"
            size="xl"
            color="po_txt_color_2"
            className={styles.header}
          >
            {t(title)}
          </AText>
          <Box className={styles.content}>
            <Snippet snippetId={snippetId} />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Snippet
      className={`${isFooter ? styles.footerSnippet : ''}`}
      classNameForShadowRoot={isCustomFooter ? 'customFooter' : ''}
      snippetId={snippetId}
    />
  );
};

export default SnippetWrapper;
