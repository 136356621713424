import { useContext, useEffect } from 'react';
import { getLocalStorageValue, LOCAL_STORAGE_KEYS } from '@namespace/helpers';
import { LINE_VIEW_TYPES } from '@namespace/common';
import useUserSettings from '@namespace/common/src/hooks/useUserSettings';
import { UserContext } from '../../context';

const useSetLineView = () => {
  const [, { SET_LINE_VIEW }] = useContext(UserContext);
  const { defaultLineViewType } = useUserSettings();
  let configLineViewType = defaultLineViewType;

  if (
    configLineViewType == null ||
    // validate settings
    !Object.values(LINE_VIEW_TYPES).includes(configLineViewType)
  ) {
    configLineViewType = LINE_VIEW_TYPES.BUTTON;
  }

  useEffect(() => {
    SET_LINE_VIEW(
      getLocalStorageValue(LOCAL_STORAGE_KEYS.LINE_VIEW) || configLineViewType
    );
  }, [SET_LINE_VIEW, configLineViewType]);
};

export default useSetLineView;
