import { createDynamicHookStore, STORE_NAMES } from '@namespace/helpers-store';
import { actionsCreator, eventHeaderActions } from './actions';
import { initialState } from './store';

export const {
  Context: EventHeaderContext,
  Provider: EventHeaderProvider,
  useCtxStore: useEventHeaderCtxStore,
  useCtxActions: useEventHeaderCtxActions = () => eventHeaderActions
} = createDynamicHookStore({
  storeName: STORE_NAMES.EVENT_HEADER,
  initialState,
  actionsCreator
});
