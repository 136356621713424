import { Box } from '@alf/uikit';
import clsx from 'clsx';
import styles from './index.module.css';

export const StickyContainer = ({
  children,
  stickyContainerToTop,
  className
}) => (
  <Box
    direction="column"
    className={clsx(
      stickyContainerToTop ? styles.stickToTop : styles.stickToHeader,
      className
    )}
  >
    {children}
  </Box>
);
