import { Button, DataCard, AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { Amount, TextCopy } from '@namespace/common';
import { METHOD_FIELD } from '../../../constants';
import styles from './index.module.css';

const TransactionsHistoryCardList = ({
  historyData,
  dataFields = [],
  setPage,
  isFetching,
  isShowMore
}) => {
  const isShowMethod = dataFields.includes(METHOD_FIELD);
  const { t } = useI18n();

  return (
    <>
      {historyData.map(
        ({ id, state, wallet, amount, date, method, hideContent = false }) => (
          <DataCard
            key={`card-${id}`}
            header={
              <>
                <div>{state}</div>
                <AText size="s" color="po_txt_color_1">
                  {date}
                </AText>
              </>
            }
            content={
              hideContent ? null : (
                <AText size="s" color="po_txt_color_1">
                  {isShowMethod ? method : wallet}
                </AText>
              )
            }
            footer={
              <>
                <Amount
                  amount={amount}
                  currentAmountSize="m"
                  convertedAmountSize="m"
                  convertedAmountBreed="B"
                  convertedCurrencyBreed="B"
                />
                <TextCopy
                  gap="xs"
                  breed="R"
                  iconSize="s"
                  iconLink="icons/general/action/ic_copy"
                  color="po_txt_color_1"
                  textDisplay={id}
                  textCopy={id}
                  textNotify="ID"
                  dataRoles={{
                    dataRoleText: 'casino-history-copyId-value',
                    dataRoleIcon: 'casino-history-copyId-icon'
                  }}
                />
              </>
            }
          />
        )
      )}
      <Box className={styles.showMoreButtonWrapper}>
        {isShowMore && (
          <Button
            size="m"
            actionType="color3"
            intent="quaternary"
            onClick={setPage}
            processing={isFetching}
          >
            {t('balance.depositHistory.table.showMore')}
          </Button>
        )}
      </Box>
    </>
  );
};

export default TransactionsHistoryCardList;
