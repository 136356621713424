import { useCallback, useEffect, useContext } from 'react';
import { window } from '@namespace/helpers';
import { omit } from 'lodash';
import { NativeAppContext } from '../../store';

const useNativeMessageEventListener = ({ refPathInPending = {} }) => {
  const [
    {
      isNativeApp,
      nativeAppConfig: { delegateRouting }
    }
  ] = useContext(NativeAppContext);

  const receiveMessage = useCallback(
    (event) => {
      console.log(event, event.data, 'NATIVE try receive');
      const { cid, canRoute } = event.data;
      if (refPathInPending.current[cid] && canRoute) {
        refPathInPending.current[cid]();
        Object.assign(refPathInPending, {
          current: omit(refPathInPending.current, [cid])
        });
      }
    },
    [refPathInPending]
  );

  useEffect(() => {
    if (isNativeApp && delegateRouting) {
      window.addEventListener('message', receiveMessage);
      return () => {
        window.removeEventListener('message', receiveMessage);
      };
    }
    return () => {};
  }, [receiveMessage, isNativeApp, delegateRouting]);
};

export default useNativeMessageEventListener;
