const removeParamsFromQuery = (searchParams, paramsToDelete) => {
  if (typeof URLSearchParams !== 'undefined') {
    const params = new URLSearchParams(searchParams);
    for (const param of paramsToDelete) params.delete(param);
    const newParams = params.toString();
    return newParams ? `?${newParams}` : '';
  }
  return searchParams;
};

export default removeParamsFromQuery;
