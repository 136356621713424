import { groupBy, pickBy, uniq, last, isEqual } from 'lodash';
import { isEmpty } from 'ramda';
import { normalizeEventsToShow } from '../../../normalizers';

export const getUpdatedEventTimer = (eventUpdates = []) => {
  const { action, timer, timer2, timerFactor, timerVector } =
    last(eventUpdates) || {};

  return {
    action,
    timer,
    timer2: timer2 === 0 ? null : timer2, // TODO: REMOVE THIS AFTER WEB API FIX FOR TIMER
    timerFactor,
    timerVector
  };
};

export const getEventUpdate = (eventUpdates = []) =>
  eventUpdates.reduce((memo, update) => ({ ...memo, ...update }), {});

export const getUpdatedEventStatus = (eventUpdates = [], status = {}) => ({
  ...status,
  ...getEventUpdate(eventUpdates)
});

export const eventInsert = (state, data = []) => {
  // temporary this handler just for updating existing events
  const eventIds = Object.keys(state.events) || [];
  const filteredData = data.filter((event) =>
    eventIds.includes(String(event.eventId))
  );

  if (isEmpty(filteredData)) {
    return state;
  }

  const eventUpdatesById = groupBy(filteredData, 'eventId');
  const events = Object.keys(eventUpdatesById).reduce(
    (memo, eventId) => ({
      ...memo,
      [Number(eventId)]: {
        ...(state.events[Number(eventId)] || {}),
        ...getEventUpdate(eventUpdatesById[Number(eventId)])
      }
    }),
    {}
  );
  return {
    ...state,
    events: { ...state.events, ...events }
  };
};

export const eventUpdate = (state, data = []) => {
  const eventUpdatesById = groupBy(data, 'eventId');
  const events = Object.keys(eventUpdatesById).reduce(
    (memo, eventId) => ({
      ...memo,
      [Number(eventId)]: {
        ...(state.events[Number(eventId)] || {}),
        ...getEventUpdate(eventUpdatesById[Number(eventId)]) // todo check if headMarkets could be here
      }
    }),
    {}
  );
  return {
    ...state,
    events: { ...state.events, ...events }
  };
};

export const eventUpdateRts = (state, data = []) => {
  const eventUpdatesById = groupBy(data, 'eventId');
  const events = Object.keys(eventUpdatesById).reduce((memo, eventId) => {
    const rtsData = last(eventUpdatesById[Number(eventId)])?.type;
    let updatedData;

    if (!Array.isArray(rtsData)) {
      const { type } = rtsData;
      updatedData = [...state.events[Number(eventId)]?.eventRtsData];

      const statIndex = state.events[Number(eventId)]?.eventRtsData?.findIndex(
        ({ type: currentType }) => currentType === type
      );

      if (statIndex !== -1) {
        updatedData[statIndex] = rtsData;
      } else {
        updatedData = [...updatedData, rtsData];
      }
    } else {
      updatedData = rtsData;
    }

    return {
      ...memo,
      [Number(eventId)]: {
        ...(state.events[Number(eventId)] || {}),
        eventRtsData: updatedData
      }
    };
  }, {});
  return {
    ...state,
    events: { ...state.events, ...events }
  };
};

export const eventSuspend = (state, { data = [], isSuspended }) => {
  const eventsIds = uniq(data.map(({ eventId }) => eventId)) || [];

  const eventsIdsMarkets = Object.values(state.markets).reduce(
    (acc, market) => {
      if (eventsIds.includes(market.eventId)) {
        return {
          ...acc,
          [market.marketId]: { ...market, marketSuspend: isSuspended }
        };
      }
      return acc;
    },
    {}
  );

  return {
    ...state,
    markets: { ...state.markets, ...eventsIdsMarkets }
  };
};

export const eventTimerSet = (state, data = []) => {
  const timersByEvent = groupBy(data, 'eventId');

  const updatedEvents = Object.keys(timersByEvent).reduce(
    (memo, eventId) => ({
      ...memo,
      [Number(eventId)]: {
        ...state.events[Number(eventId)],
        eventTimer: getUpdatedEventTimer(timersByEvent[Number(eventId)])
      }
    }),
    {}
  );

  return {
    ...state,
    events: { ...state.events, ...updatedEvents }
  };
};

export const eventServing = (state, data = []) => {
  const eventsById = groupBy(data, 'eventId');
  const events = Object.keys(eventsById).reduce(
    (memo, eventId) => ({
      ...memo,
      [Number(eventId)]: {
        ...state.events[Number(eventId)],
        eventServing: eventsById[Number(eventId)][0].eventServing
      }
    }),
    {}
  );
  return {
    ...state,
    events: { ...state.events, ...events }
  };
};

export const eventInProgress = (state, data = []) => {
  const eventsById = groupBy(data, 'eventId');
  const eventsIdsMarkets = Object.values(state.markets).reduce(
    (acc, market) => {
      if (Object.keys(eventsById).includes(market.eventId?.toString())) {
        return [...acc, market];
      }
      return acc;
    },
    []
  );

  const events = Object.keys(eventsById).reduce(
    (memo, eventId) =>
      state.events[Number(eventId)]
        ? {
            ...memo,
            [Number(eventId)]: {
              ...state.events[Number(eventId)],
              ...getUpdatedEventStatus(eventsById[Number(eventId)], {
                inprogress: true
              })
            }
          }
        : memo,
    {}
  );

  if (isEmpty(events)) {
    return state;
  }

  const markets = eventsIdsMarkets.reduce(
    (acc, market) => ({
      ...acc,
      [market.marketId]: { ...market, marketSuspend: true }
    }),
    {}
  );

  return {
    ...state,
    events: { ...state.events, ...events },
    markets: { ...state.markets, ...markets }
  };
};

export const eventFinish = (state, data = []) => {
  const eventsById = groupBy(data, 'eventId');
  const eventsIds = Object.keys(eventsById);
  const events = eventsIds.reduce(
    (memo, eventId) =>
      state.events[Number(eventId)]
        ? {
            ...memo,
            [Number(eventId)]: {
              ...(state.events[Number(eventId)] || {}),
              ...getUpdatedEventStatus(eventsById[Number(eventId)], {
                finished: true
              }),
              eventTimer: null
            }
          }
        : memo,
    {}
  );

  if (isEmpty(events)) {
    return state;
  }

  const markets = eventsIds.reduce(
    (memo, eventId) => ({
      ...memo,
      ...Object.values(state.markets).reduce((acc, market) => {
        if (market.eventId === eventId) {
          return {
            ...acc,
            [market.marketId]: { ...market, marketSuspend: true }
          };
        }
        return acc;
      }, {})
    }),
    {}
  );

  return {
    ...state,
    events: { ...state.events, ...events },
    markets: { ...state.markets, ...markets }
  };
};

export const eventCancel = (state, data = []) => {
  const eventsById = groupBy(data, 'eventId');
  const events = Object.keys(eventsById).reduce(
    (memo, eventId) =>
      state.events[Number(eventId)]
        ? {
            ...memo,
            [Number(eventId)]: {
              ...state.events[Number(eventId)],
              ...getUpdatedEventStatus(eventsById[Number(eventId)], {
                canceled: true
              }),
              eventTimer: null
            }
          }
        : memo,
    {}
  );
  if (isEmpty(events)) {
    return state;
  }

  return {
    ...state,
    events: { ...state.events, ...events },
    markets: pickBy(
      state.markets,
      (e) => !Object.keys(eventsById).includes(String(e.eventId))
    )
  };
};

export const eventDelete = (state, data = []) => {
  const deletedEventIds = uniq(data.map(({ eventId }) => eventId));

  const events = pickBy(
    state.events,
    (e) => !deletedEventIds.includes(e.eventId)
  );

  if (isEqual(state.events, events)) {
    return state;
  }

  const lines = Object.entries(state.lines).reduce(
    (acc, [lineKey, { eventsIds, offset, ...rest }]) => {
      const filteredLineEvents = eventsIds.filter(
        (id) => !deletedEventIds.includes(id)
      );
      const offsetDiff = eventsIds.length - filteredLineEvents.length;

      return {
        ...acc,
        [lineKey]: {
          ...rest,
          // marketsIdsByEvent todo cleanup
          eventsIds: filteredLineEvents,
          offset: offset - offsetDiff
        }
      };
    },
    {}
  );

  const eventsToShowByLineKey = normalizeEventsToShow({
    events,
    lines,
    sortByFilter: state.sortByFilter
  });

  return {
    ...state,
    events,
    markets: pickBy(state.markets, (e) => !deletedEventIds.includes(e.eventId)),
    lines,
    eventsToShowByLineKey,
    outcomes: pickBy(
      state.outcomes,
      (e) => !deletedEventIds.includes(e.eventId)
    )
  };
};
