// src: https://github.com/floating-ui/floating-ui/blob/master/packages/core/src/middleware/autoPlacement.ts

import { detectOverflow } from '@floating-ui/core';
import {
  getSide,
  getAlignment,
  getAlignmentSides,
  getOppositeAlignmentPlacement
} from './utils';
import { allPlacements } from './enums';

const getPlacementList = (alignment, autoAlignment, allowedPlacements) => {
  const allowedPlacementsSortedByAlignment = alignment
    ? [
        ...allowedPlacements.filter(
          (placement) => getAlignment(placement) === alignment
        ),
        ...allowedPlacements.filter(
          (placement) => getAlignment(placement) !== alignment
        )
      ]
    : allowedPlacements.filter((placement) => getSide(placement) === placement);

  return allowedPlacementsSortedByAlignment.filter((placement) => {
    if (alignment) {
      return (
        getAlignment(placement) === alignment ||
        (autoAlignment
          ? getOppositeAlignmentPlacement(placement) !== placement
          : false)
      );
    }

    return true;
  });
};

/**
 * Automatically chooses the `placement` by preferred placements OR which has the most space available.
 * @see https://floating-ui.com/docs/autoPlacement
 */
export const autoPlacement = (options = {}) => ({
  name: 'autoPlacement',
  options,

  async fn(middlewareArguments) {
    const {
      x,
      y,
      rects,
      middlewareData,
      placement,
      platform,
      elements
    } = middlewareArguments;
    const {
      alignment = null,
      allowedPlacements = allPlacements,
      autoAlignment = true,
      preferredPlacements = null,
      ...detectOverflowOptions
    } = options;
    const placements = getPlacementList(
      alignment,
      autoAlignment,
      allowedPlacements
    );
    const overflow = await detectOverflow(
      middlewareArguments,
      detectOverflowOptions
    );
    const currentIndex = middlewareData.autoPlacement?.index ?? 0;
    const currentPlacement = placements[currentIndex];

    if (currentPlacement == null) {
      return {};
    }

    const { main, cross } = getAlignmentSides(
      currentPlacement,
      rects,
      await platform.isRTL?.(elements.floating)
    );

    // Make `computeCoords` start from the right place
    if (placement !== currentPlacement) {
      return {
        x,
        y,
        reset: {
          placement: placements[0]
        }
      };
    }

    const currentOverflows = [
      overflow[getSide(currentPlacement)],
      overflow[main],
      overflow[cross]
    ];
    const allOverflows = [
      ...(middlewareData.autoPlacement?.overflows ?? []),
      {
        placement: currentPlacement,
        overflows: currentOverflows
      }
    ];
    const nextPlacement = placements[currentIndex + 1];

    // There are more placements to check
    if (nextPlacement) {
      return {
        data: {
          index: currentIndex + 1,
          overflows: allOverflows
        },
        reset: {
          placement: nextPlacement
        }
      };
    }

    const predicateByOverflow = (a, b) => a.overflows[0] - b.overflows[0];
    const predicateByPreferred = (a, b) => {
      const indexA = preferredPlacements.indexOf(a.placement);
      const indexB = preferredPlacements.indexOf(b.placement);

      if ((indexA === -1 && indexB === -1) || indexA === indexB) {
        return predicateByOverflow(a, b);
      }

      if (indexA === -1) {
        return 1;
      }

      if (indexB === -1) {
        return -1;
      }

      return indexA - indexB;
    };

    const sortedPlacements = allOverflows
      .slice()
      .sort(
        preferredPlacements != null ? predicateByPreferred : predicateByOverflow
      );
    const placementThatFitsOnAllSides = sortedPlacements.find(({ overflows }) =>
      overflows.every((ovf) => ovf <= 0)
    )?.placement;
    const resetPlacement =
      placementThatFitsOnAllSides ?? sortedPlacements[0].placement;

    if (resetPlacement !== placement) {
      return {
        data: {
          index: currentIndex + 1,
          overflows: allOverflows
        },
        reset: {
          placement: resetPlacement
        }
      };
    }

    return {};
  }
});
