import { useCallback } from 'react';
import { InfoMessage } from '@alf/uikit';
import {
  FORM_COMPONENTS_TYPES,
  FORM_VALIDATION_TYPES,
  FormBuilder
} from '@namespace/forms';
import { useI18n } from '@namespace/i18n';
import {
  PAYMENT_SYSTEMS_COMPONENTS_MAP,
  paymentSystemsComponentsMap
} from '../constants';
import styles from '../index.module.css';

export default ({
  wallet: {
    currency,
    baseBalance,
    walletHash,
    cashdesk,
    taxRate,
    paymentInstrumentName,
    walletId
  },
  amount = '',
  onWithdraw,
  maxCashDesk,
  isMultiWallet = true
}) => {
  const { t } = useI18n();
  const defaultValues = {
    amount,
    password: '',
    city: '',
    cashdesk: '',
    wallet: paymentInstrumentName || walletId
  };

  const onSubmit = useCallback(
    ({ cashdesk: cashDesk, city, amount: quantity, password }) =>
      onWithdraw({
        cashdeskId: cashDesk,
        cityId: city,
        amount: quantity,
        password
      }),
    []
  );

  const minAmount = 2;
  const componentsList = [
    {
      type: FORM_COMPONENTS_TYPES.INPUT_FIELD,
      inputType: isMultiWallet ? 'text' : 'hidden',
      name: 'wallet',
      classNames: { wrapperClassName: styles.fieldWrap },
      label: 'balance.withdraw.form.wallet',
      placeholder: 'balance.withdraw.form.wallet',
      disabled: true
    },
    {
      type: PAYMENT_SYSTEMS_COMPONENTS_MAP.CITY_FIELD,
      name: 'city',
      walletHash,
      cashdesk,
      classNames: { containerClassName: styles.fieldWrap },
      label: 'balance.withdraw.form.city',
      placeholder: 'balance.withdraw.form.city',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED]
    },
    {
      type: PAYMENT_SYSTEMS_COMPONENTS_MAP.CASH_DESK,
      name: 'cashdesk',
      walletHash,
      amount,
      maxCashDesk,
      classNames: { containerClassName: styles.fieldWrap },
      label: 'balance.withdraw.form.cashdesk',
      placeholder: 'balance.withdraw.form.cashdesk',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
      additionSelectContentStyle: styles.cashDeskOptions
    },
    {
      type: FORM_COMPONENTS_TYPES.AMOUNT_INPUT_FIELD,
      name: 'amount',
      currency,
      label: 'balance.withdraw.form.amount',
      placeholder: 'balance.deposit.creditCard.amountPlaceholder',
      min: minAmount,
      validationTypes: [
        FORM_VALIDATION_TYPES.REQUIRED,
        {
          type: FORM_VALIDATION_TYPES.MIN_VALIDATOR,
          parameters: [minAmount, 'balance.withdraw.form.minAmount', [currency]]
        },
        {
          type: FORM_VALIDATION_TYPES.MAX_VALIDATOR,
          parameters: [baseBalance]
        }
      ],
      classNames: {
        containerClassName: styles.fieldWrap,
        controlClassName: styles.controlWrap
      },
      isOnChangeValidation: true
    },
    {
      type: PAYMENT_SYSTEMS_COMPONENTS_MAP.TAX_RATE,
      name: 'taxRate',
      taxRate
    },
    {
      type: FORM_COMPONENTS_TYPES.PASSWORD_FIELD,
      name: 'password',
      classNames: {
        wrapperClassName: styles.fieldWrap
      },
      rulesList: [],
      label: 'balance.withdraw.form.password',
      placeholder: 'balance.withdraw.form.enterAccountPassword',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED]
    },
    {
      type: FORM_COMPONENTS_TYPES.SUBMIT_BUTTON,
      value: 'balance.withdraw.form.submit',
      classNames: {
        className: styles.withdrawButton
      }
    }
  ];

  return (
    <>
      <InfoMessage icon="info" className={isMultiWallet ? styles.info : ''}>
        {t('balance.withdraw.cashdesk.info')}
      </InfoMessage>
      <div className={styles.wrapper}>
        <FormBuilder
          wrapperClassName={styles.form}
          components={componentsList}
          componentsMap={paymentSystemsComponentsMap}
          onSubmit={onSubmit}
          formConfig={{ mode: 'onBlur', defaultValues }}
        />
      </div>
    </>
  );
};
