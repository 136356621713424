import { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import AText from '../AText';
import Box from '../Box';
import styles from './index.module.css';

const List = ({
  items = [],
  isShowPinned = false,
  LocalizedNavLink,
  isActive,
  classNames = {
    iconClass: '',
    listItemClass: ''
  },
  PinComponent = null,
  onClick,
  dataRole
}) =>
  items.length > 0 && (
    <Box direction="column" className={styles.container}>
      {items.map(
        ({
          id,
          link,
          title,
          Icon,
          count,
          isPinned,
          tag,
          tagColor,
          logoUrl
        }) => (
          <LocalizedNavLink
            key={link}
            to={link}
            onClick={onClick}
            className={styles.link}
          >
            <Box
              align="center"
              justify="between"
              className={clsx(styles.item, isActive?.(link) && styles.active)}
              data-role={dataRole ? `${dataRole}-item-${id}` : ''}
            >
              {logoUrl ? (
                <Box className={styles.imgWrapper}>
                  <img
                    className={styles.imgIcon}
                    src={logoUrl}
                    alt="logo burger"
                  />
                </Box>
              ) : (
                <>
                  {Icon}
                  <AText
                    breed="BU"
                    size="m"
                    className={`${styles.title} ${classNames.listItemClass}`}
                    data-role={dataRole ? `${dataRole}-item-${id}-text` : ''}
                  >
                    {title}
                  </AText>
                </>
              )}

              {tag && (
                <Box
                  className={styles.burgerSubMenuTag}
                  padding="0 xs"
                  style={{
                    backgroundColor: tagColor
                  }}
                >
                  <AText breed="BU" size="xs" color="txt_color_4">
                    {tag}
                  </AText>
                </Box>
              )}
              <Box>
                {isShowPinned && (
                  <PinComponent isPinned={isPinned} sportId={id} />
                )}
                {count !== undefined && (
                  <Box className={styles.count} align="center" justify="center">
                    <AText
                      breed="R"
                      size="xs"
                      color="sm_txt_color_1"
                      data-role={
                        dataRole ? `${dataRole}-item-${id}-counter` : ''
                      }
                    >
                      {count}
                    </AText>
                  </Box>
                )}
              </Box>
            </Box>
          </LocalizedNavLink>
        )
      )}
    </Box>
  );

List.propTypes = {
  items: PropTypes.array,
  isShowPinned: PropTypes.bool,
  LocalizedNavLink: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.object })
  ]),
  isActive: PropTypes.func,
  classNames: PropTypes.shape({
    iconClass: PropTypes.string,
    listItemClass: PropTypes.string
  }),
  PinComponent: PropTypes.func,
  onClick: PropTypes.func
};

export default memo(List);
