import { postAsUriParameters } from '@namespace/helpers';
import apiRoutes from '../apiRoutes';

export const setUserPromoCode = (data) =>
  postAsUriParameters(apiRoutes.setUserPromoCode, data, true).then(
    ({ error, response }) => {
      const { errorCode } = response;
      return error === 'no' && errorCode;
    }
  );
