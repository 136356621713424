import { window } from '../../constants';
import { getScrollTop } from '../getScrollTop';

const LONG_DISTANCE = 5000;
const scroll = ({ el = window, top, left, behavior }) => {
  try {
    el.scrollTo({ top, left, behavior });
  } catch {
    el.scrollTo(left, top);
  }
};

export const scrollTo = (options = {}) => {
  const {
    el,
    top = 0,
    left = 0,
    behavior = 'auto',
    timeout = 0,
    isSkipSmoothForLongDistance = false
  } = options;
  const currentScrollTop = getScrollTop();
  const scrollDistance = top - currentScrollTop;

  if (
    Math.abs(scrollDistance) > LONG_DISTANCE &&
    !isSkipSmoothForLongDistance
  ) {
    setTimeout(() => {
      scroll({
        el,
        top: top > currentScrollTop ? top - 1500 : top + 1500,
        left,
        behavior: 'auto'
      });

      scroll({
        el,
        top,
        left,
        behavior: 'smooth'
      });
    }, timeout);
  } else {
    setTimeout(() => scroll({ el, top, left, behavior }), timeout);
  }
};
