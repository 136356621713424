import { useMemo } from 'react';
import { useI18n } from '@namespace/i18n';
import { last } from 'lodash';
import {
  getDetailedScoresForTable,
  stripeScore,
  getEventValues,
  getResultTypeScoreValues
} from '../../utils';
import {
  SPORTS_WITH_ALL_PERIODS,
  TOTAL_SCOPE_ID_BY_SPORT,
  TOTAL_SYMBOL_BY_SPORT,
  SCOPE_IDS_FOR_PERIODS
} from '../../constants';
import {
  COLUMN_TYPES,
  ADAPTED_SPORT_IDS,
  SUMMARIES_RESULT_TYPE_IDS
} from '../../../../constants';
import ScoreDetails from '../../Details';
import { useEventFields } from '../../../../store';

const Sport = ({
  byResultType,
  total,
  sportId,
  eventId,
  isShortVersion,
  lineView
}) => {
  const { t } = useI18n();
  const { eventResultTotal, eventResultId } = useEventFields({
    eventId,
    fields: ['eventResultTotal', 'eventResultId']
  });

  const totalScore = useMemo(() => {
    if (SUMMARIES_RESULT_TYPE_IDS.includes(eventResultId)) {
      return [];
    }

    if (TOTAL_SCOPE_ID_BY_SPORT[sportId]) {
      return getEventValues(total, TOTAL_SCOPE_ID_BY_SPORT[sportId]);
    }

    return eventResultTotal?.split(' ')[0].split(':');
  }, [eventResultId, sportId, eventResultTotal, total]);

  if (!eventResultTotal) {
    return null;
  }

  // can be null from server

  const [, resultsByPeriod] =
    eventResultTotal.replace(/\s/g, '').match(/\(([^)]+)\)/) || [];

  let formattedScoreByPeriod = [];

  if (resultsByPeriod?.length && !ADAPTED_SPORT_IDS.includes(sportId)) {
    const scoreHalftimes =
      eventResultTotal &&
      resultsByPeriod
        .split(',')
        .reduce((memo, key) => [...memo, key.split(':')], []);

    if (isShortVersion) {
      formattedScoreByPeriod = [
        {
          type: COLUMN_TYPES.DEFAULT,
          tag: '',
          scores: SUMMARIES_RESULT_TYPE_IDS.includes(eventResultId)
            ? []
            : last(scoreHalftimes)
        }
      ];
    } else {
      formattedScoreByPeriod = scoreHalftimes.map((scores, index) => ({
        type: COLUMN_TYPES.DEFAULT,
        tag: index + 1,
        scores
      }));
    }
  }

  if (ADAPTED_SPORT_IDS.includes(sportId)) {
    if (isShortVersion) {
      formattedScoreByPeriod = [
        {
          type: COLUMN_TYPES.DEFAULT,
          tag: '',
          scores: getResultTypeScoreValues(
            byResultType,
            eventResultId,
            SCOPE_IDS_FOR_PERIODS[sportId] || TOTAL_SCOPE_ID_BY_SPORT[sportId]
          )
        }
      ];
    } else {
      formattedScoreByPeriod = stripeScore(
        getDetailedScoresForTable(byResultType),
        sportId,
        eventResultId,
        SPORTS_WITH_ALL_PERIODS.includes(sportId)
      );
    }
  }

  const fullFormattedScore = [
    {
      type: COLUMN_TYPES.TOTAL,
      tag: TOTAL_SYMBOL_BY_SPORT[sportId]
        ? t(TOTAL_SYMBOL_BY_SPORT[sportId])
        : t(TOTAL_SYMBOL_BY_SPORT.default),
      scores: totalScore
    },
    ...formattedScoreByPeriod
  ];

  return (
    <ScoreDetails
      scoreData={fullFormattedScore}
      isShortVersion={isShortVersion}
      lineView={lineView}
    />
  );
};

export default Sport;
