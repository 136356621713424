import { useEffect, useContext } from 'react';
import { range } from 'ramda';
import { socketSubscribe, socketUnsubscribe } from '@namespace/socket';
import { useI18n } from '@namespace/i18n';
import { BetSlipContext } from '../../store/context';
import { getBetslipKey } from '../../utils/localStorage';

const useBetslipSubscriber = (serviceId) => {
  const [, actions] = useContext(BetSlipContext);
  const { SET_BETSLIP_DATA } = actions;
  const { language: currentLanguage, timezone } = useI18n();

  useEffect(() => {
    socketSubscribe({
      name: 'betslip',
      // up to 7 theoretical multi-betslips; 5 used as default
      types: range(0, 7).map((idx) => (idx ? `betslip_${idx}` : 'betslip')),
      callback: ({ data }) => {
        const key = getBetslipKey();
        // maps `betslip_X` back to `betslip`
        const { [key]: betslip, ...fields } = data;
        SET_BETSLIP_DATA({
          data: { betslip, ...fields }
        });
      },
      message: {
        cmd: 'do_proto_v1',
        data: {
          query: {
            [getBetslipKey()]: {
              variables: {
                service_id: serviceId
              }
            }
          }
        }
      }
    });

    return () => socketUnsubscribe('betslip');
  }, [SET_BETSLIP_DATA, currentLanguage, serviceId, timezone]);
};

export default useBetslipSubscriber;
