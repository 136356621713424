import { useContext, useEffect } from 'react';
import { Overlay } from '@alf/uikit';
import { Loader } from '@namespace/common';
import { useLocation } from '@namespace/i18n';
import { useSearchParams, useHistory } from '@namespace/router';
import { useGeneralRoutes } from '@namespace/cms';
import { BetSlipContext } from '@namespace/betslip';
import { LOG_ACTIONS, logAction } from '@namespace/analytics';
import { setSessionStorageValue } from '@namespace/helpers';
import styles from './index.module.css';

const parseOutcomes = (outcomes) => {
  return outcomes?.map((outcome) => {
    const params = outcome.split(',');
    return {
      eventId: Number(params[0]),
      marketId: Number(params[1]),
      outcomeId: Number(params[2]),
      serviceId: Number(params[3])
    };
  });
};

const AddMultiBetsFromExternal = () => {
  const [, { ADD_TO_STORE }] = useContext(BetSlipContext);
  const { replace } = useHistory();
  const { preMatchSport } = useGeneralRoutes();
  const { outcomes } = useSearchParams();
  const { pathname } = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (pathname !== preMatchSport) {
          replace(preMatchSport);
        }
        if (outcomes) {
          const parsedBets = parseOutcomes(outcomes);
          await ADD_TO_STORE({ bets: parsedBets });
          logAction(LOG_ACTIONS.OPEN_SHARE_BET);
          setSessionStorageValue('ShareBetOutcomesCount', parsedBets?.length);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [ADD_TO_STORE, outcomes, pathname, preMatchSport, replace]);

  return (
    <div className={styles.wrapper}>
      <Overlay classNames={{ wrapperClass: styles.overlay }}>
        <Loader />
      </Overlay>
    </div>
  );
};

export default AddMultiBetsFromExternal;
