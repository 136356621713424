import { useContext } from 'react';
import { DeviceContext } from '@namespace/device';
import { useI18n } from '@namespace/i18n';
import { Box, AText } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { useLogOnTimer } from '../../hooks';
import styles from './index.module.css';

const LogOnTimer = () => {
  const { isMobile } = useContext(DeviceContext);
  const { t } = useI18n();
  const time = useLogOnTimer();

  return (
    <Box className={styles.wrapper} justify="center">
      <Icon
        size={isMobile ? 's' : 'l'}
        name="icons/general/item/timer"
        className={styles.icon}
      />
      <Box className={styles.inner}>
        <AText breed={isMobile ? 'R' : 'RU'} className={styles.info}>
          {t('header.endOfSession')}
        </AText>
        {time}
      </Box>
    </Box>
  );
};

export default LogOnTimer;
