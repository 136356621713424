import { useConfig } from '@namespace/cms';
import { MODE } from '../../constants';
import { useActiveHeadGroups } from '../../hooks/useActiveHeadGroups';
import { EventsLineTitle, Line } from '../../common';
import EventsLineHeader from '../../common/EventsLineHeader';
import { useEventField } from '../../store';

const TopEventsLine = ({
  id,
  sportId,
  isLoading,
  lineKey,
  eventsIdsBySport = []
}) => {
  const { config } = useConfig(id);
  const {
    isHeadGroupsSortingEnabled,
    isShowEventGroup,
    activeHeadGroupsAmount
  } = config;

  const sportName = useEventField(eventsIdsBySport?.[0]?.[0], 'sportName');

  const [activeHeadGroups, setActiveHeadGroups] = useActiveHeadGroups(
    isHeadGroupsSortingEnabled,
    activeHeadGroupsAmount,
    sportId,
    MODE.PRE_MATCH
  );

  return (
    <div>
      <EventsLineHeader
        serviceId={MODE.PRE_MATCH}
        activeHeadGroups={activeHeadGroups}
        isHeadGroupsSortingEnabled={isHeadGroupsSortingEnabled}
        setActiveHeadGroups={setActiveHeadGroups}
        isCollapsible={false}
        borderBottom={true}
      >
        <EventsLineTitle title={sportName} sportId={sportId} />
      </EventsLineHeader>
      <Line
        id={id}
        eventsToShow={eventsIdsBySport}
        isShowEventGroup={isShowEventGroup}
        isEventsLoading={isLoading}
        sportSlug={sportName}
        activeHeadGroups={activeHeadGroups}
        isCategoriesExpanded={true}
        lineKey={lineKey}
      />
    </div>
  );
};

export default TopEventsLine;
