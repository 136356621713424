import { useContext, useCallback } from 'react';
import { DeviceContext } from '@namespace/device';
import { useGeneralRoutes } from '@namespace/cms';
import { USER_ROLES, UserContext } from '@namespace/user';
import { formatAsUriParameters } from '@namespace/helpers';
import { REDIRECT_PARAM } from '@namespace/common';
import { useSearchParams } from '@namespace/router';
import { DEMO_MODE, REAL_MODE } from '../../constants';

const getLaunchLink = (link, isHighlightSeparately) =>
  isHighlightSeparately
    ? `${link}&highlightSeparately=${isHighlightSeparately}`
    : link;

export const useGetGameURL = () => {
  const { isDesktop } = useContext(DeviceContext);
  const { gameLauncher, login, gamePreview } = useGeneralRoutes();
  const [user] = useContext(UserContext);
  const { role } = user;
  const {
    highlightSeparately: isHighlightSeparately,
    page,
    query,
    prevPage
  } = useSearchParams();

  return useCallback(
    ({
      gameCode,
      gameProvider,
      playMode,
      serviceURL,
      type,
      isUnavailableToPlay,
      backTo
    }) => {
      const launcherLink = isDesktop ? gamePreview : gameLauncher;
      const prevPageParam =
        page || prevPage ? `&prevPage=${page || prevPage}` : '';
      const prevQueryParam = query ? `&prevQuery=${query}` : '';

      const launchLoginLink = `${login}?${REDIRECT_PARAM}${serviceURL}${launcherLink}/${gameProvider}/${gameCode}?playMode=${REAL_MODE}`;
      const launchLink = `${serviceURL}${launcherLink}/${gameProvider}/${gameCode}?playMode=${playMode}${
        backTo ? `&backTo=${backTo}` : ''
      }${prevPageParam}${prevQueryParam}`;
      const previewLink = `${serviceURL}${gamePreview}/${gameProvider}/${gameCode}?${formatAsUriParameters(
        {
          isUnavailableToPlay: isUnavailableToPlay ? 1 : undefined,
          prevPage: page,
          prevQuery: query
        }
      )}`.replace(/\?$/, ''); // remove "?" from the end of the string if no params given
      const previewLoginLink = `${login}?${REDIRECT_PARAM}${previewLink}`;

      if (playMode && launcherLink && type === 'launch') {
        if (
          (playMode === REAL_MODE && role === USER_ROLES.USER) ||
          playMode === DEMO_MODE
        ) {
          return getLaunchLink(launchLink, isHighlightSeparately);
        }
        return getLaunchLink(launchLoginLink, isHighlightSeparately);
      }

      if (gamePreview && gameCode && type === 'preview') {
        return previewLink;
      }

      if (gamePreview && gameCode && type === 'previewLogin') {
        return previewLoginLink;
      }

      return serviceURL;
    },
    [
      isDesktop,
      gameLauncher,
      gamePreview,
      role,
      login,
      isHighlightSeparately,
      page
    ]
  );
};
