import { Icon } from '@namespace/icons2';
import { Box } from '@alf/uikit';
import { ONE_EVENT_HEADER_TABS as TABS } from '../../../../../../../../constants';
import styles from '../../index.module.css';

export const ScoreboardIcon = ({ tabState, setTabState }) => (
  <Box align="center" className={styles.filterIcon}>
    <Icon
      name="icons/event/scoreboard"
      size="l"
      className={`
        ${styles.icon}
        ${
          tabState.type === TABS.SCORE && tabState.opened ? styles.expanded : ''
        }
      `}
      onClick={() => {
        if (tabState.type === TABS.SCORE) {
          setTabState({ type: null, opened: false });
        } else {
          setTabState({ type: TABS.SCORE, opened: true });
        }
      }}
    />
  </Box>
);
