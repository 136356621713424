const DAYS_NUMBERS_MAP = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7
};

export const makeDatesByDaysInterval = ({
  f,
  startDate,
  endDate,
  startTimeInMinutes,
  endTimeInMinutes,
  daysInterval
}) => {
  const endDateTimeForInterval = f
    .getDateTime({ date: endDate })
    .plus({ minutes: endTimeInMinutes });

  let endDateTime = f
    .getDateTime({ date: startDate })
    .startOf('day')
    .plus({
      minutes: f.getDateTime().o + endTimeInMinutes
    });
  const dates = [];

  for (
    let startDateTime = f
      .getDateTime({ date: startDate })
      .startOf('day')
      .plus({ minutes: f.getDateTime().o + startTimeInMinutes });
    startDateTime <= endDateTimeForInterval;
    startDateTime = startDateTime.plus({
      days: daysInterval
    })
  ) {
    dates.push({ from: startDateTime, to: endDateTime });
    endDateTime = endDateTime.plus({
      days: daysInterval
    });
  }

  return dates.filter(({ to }) => to > f.getDateTime());
};

export const makeDatesByWeeksInterval = ({
  f,
  startDate,
  endDate,
  startTimeInMinutes,
  endTimeInMinutes,
  weeksInterval,
  daysOfWeek
}) => {
  const possibleDaysNumbers = daysOfWeek.map((day) => DAYS_NUMBERS_MAP[day]);

  const endDateTimeForInterval = f.getDateTime({ date: endDate }).endOf('week');
  const dates = [];

  for (
    let startDateTime = f.getDateTime({ date: startDate }).startOf('week');
    startDateTime <= endDateTimeForInterval;
    startDateTime = startDateTime.plus({
      days: 1
    })
  ) {
    dates.push({
      from: startDateTime.plus({
        minutes: f.getDateTime().o + startTimeInMinutes
      }),
      to: startDateTime.plus({ minutes: f.getDateTime().o + endTimeInMinutes }),
      dayOfWeek: startDateTime.weekday
    });
  }

  const datesGroupedByWeeks = dates
    .filter(({ dayOfWeek }) => possibleDaysNumbers.includes(dayOfWeek))
    .reduce((acc, item, index) => {
      const chunkIndex = Math.floor(index / possibleDaysNumbers.length);

      if (!acc[chunkIndex]) {
        acc[chunkIndex] = [];
      }

      acc[chunkIndex].push(item);

      return acc;
    }, [])
    .filter((datesArray) =>
      datesArray.some(({ from }) => from > f.getDateTime({ date: startDate }))
    );

  const filteredWeeksByInterval = getEveryNthElementOfArray(
    datesGroupedByWeeks,
    weeksInterval
  );

  return makeFromToInterval({
    dates: filteredWeeksByInterval.flat().filter(
      ({ from, to }) =>
        from >= f.getDateTime({ date: startDate }).startOf('day') &&
        to <=
          f.getDateTime({ date: endDate }).plus({
            minutes: endTimeInMinutes
          })
    ),
    f,
    startDate: f.getDateTime({ date: startDate }).plus({
      minutes: startTimeInMinutes
    }),
    endDate: f.getDateTime({ date: endDate }).plus({
      minutes: endTimeInMinutes
    })
  });
};

const makeFromToInterval = ({ dates, f, startDate, endDate }) => {
  const now = f.getDateTime();
  let periodStart = now;
  let periodEnd = now;
  const original = [...dates];
  const reversed = dates.reverse();

  if (
    dates.some(
      ({ from }) =>
        from.weekday === now.weekday && from.weekNumber === now.weekNumber
    )
  ) {
    if (
      dates.some(
        ({ from, to }) =>
          from.weekday === now.weekday &&
          from.weekNumber === now.weekNumber &&
          now > to
      )
    ) {
      const filteredDates = original.filter(
        ({ from, to }) => from > f.getDateTime().startOf('day') && to > now
      );
      const [{ from, to }] = filteredDates;
      periodStart = from;
      periodEnd = to;
    }

    // Define start of dates interval, so that's why need reversed array of dates
    for (const date of reversed) {
      const { from } = date;

      if (
        from &&
        Math.abs(periodStart.diff(from, 'hours').toObject().hours) <= 24
      ) {
        periodStart = from;
      }
    }
    // Define end of dates interval
    for (const date of original) {
      const { to } = date;

      if (to && Math.abs(periodEnd.diff(to, 'hours').toObject().hours) <= 24) {
        periodEnd = to;
      }
    }
  } else {
    const filteredDates = original.filter(
      ({ from, to }) => from > f.getDateTime().startOf('day') && to > now
    );

    if (!filteredDates.length) {
      return [];
    }

    const [{ from, to }] = filteredDates;
    periodStart = from;
    periodEnd = to;

    for (const date of original) {
      const { to: currentEndDate } = date;

      if (
        to &&
        Math.abs(periodEnd.diff(currentEndDate, 'hours').toObject().hours) <= 24
      ) {
        periodEnd = currentEndDate;
      }
    }
  }

  return [
    {
      from: periodStart < startDate ? startDate : periodStart,
      to: periodEnd > endDate ? endDate : periodEnd,
      isPeriod:
        Math.abs(periodStart.diff(periodEnd, 'hours').toObject().hours) > 24 ||
        periodEnd.day !== periodStart.day
    }
  ].filter(({ to }) => to > f.getDateTime());
};
const getEveryNthElementOfArray = (array, nth) => {
  const result = [];

  for (let i = 0; i < array.length; i += nth) {
    result.push(array[i]);
  }

  return result;
};
