import { useI18n } from '@namespace/i18n';

export const useGetInHouseJackpotDateFormatter = () => {
  const { f } = useI18n();
  const DATE_FORMATS_TYPES = f.getFormats();

  // example -> date: "2022-08-05 08:27:07", timezone: "+00:00"
  return ({ date, timezone }) => {
    return f
      .getDateTime({
        date: `${date.replace(' ', 'T')}${timezone}` // format to ISO
      })
      .toFormat(DATE_FORMATS_TYPES.IN_HOUSE_JACKPOT_DATE_FORMAT);
  };
};
