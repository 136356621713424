import { Notification, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';

const AddictedUserToast = ({ onClose }) => {
  const { t } = useI18n();

  const message = (
    <Box direction="column" align="center" justify="center">
      {t('addictedUser.notification.infoTop')}
      <br />
      <span>{t('addictedUser.notification.infoBottom')}</span>
    </Box>
  );

  return <Notification onClose={onClose} message={message} />;
};

export default AddictedUserToast;
