import { Fragment, memo, useEffect, useMemo, useState } from 'react';
import Categories from '../Categories';
import SportItem from '../SportItem';
import { sortFavoriteSports } from '../../utils';
import { useFavorites, useSportsBookDataState } from '../../store';
import MenuListSkelet from '../MenuListSkelet';
import styles from './index.module.css';

const BaseMenuList = ({
  sports = [],
  activeSportId,
  basePath,
  categoryRoute,
  showCategories,
  serviceId,
  eventId,
  categorySportId,
  categoryId,
  countable = true,
  keyProperty,
  showPinIcon = false,
  categoriesWithIcon
}) => {
  const [isVisibleCurrentCategories, setIsVisibleCurrentCategories] = useState(
    true
  );
  const getIsSameSport = (sportId) => sportId === activeSportId;
  const favoritesSports = useFavorites('sports');
  const { isSportsBookDataLoaded } = useSportsBookDataState();

  useEffect(() => {
    if (
      categoryId &&
      activeSportId === categorySportId &&
      !isVisibleCurrentCategories
    ) {
      setIsVisibleCurrentCategories(true);
    }
  }, [categoryId]);

  useEffect(() => {
    if (eventId) {
      setIsVisibleCurrentCategories(true);
    }
  }, [eventId]);

  const items = useMemo(() => sortFavoriteSports(sports, favoritesSports), [
    sports,
    favoritesSports
  ]);

  if (!isSportsBookDataLoaded) {
    return <MenuListSkelet />;
  }

  return (
    items.length > 0 &&
    items.map((item) => {
      const { sportId, sportSlug } = item;
      const isSameSport = getIsSameSport(sportId);

      return (
        <Fragment key={`sportItem-${item[keyProperty]}`}>
          <SportItem
            sport={item}
            isExpanded={isVisibleCurrentCategories}
            toggleMenu={() =>
              setIsVisibleCurrentCategories(
                isSameSport ? !isVisibleCurrentCategories : true
              )
            }
            basePath={basePath}
            isActive={isSameSport}
            withCounter={countable && (!isSameSport || !showCategories)}
            withExpandIcon={isSameSport && showCategories}
            showPinIcon={showPinIcon}
          />
          {showCategories && isSameSport && isVisibleCurrentCategories && (
            <div className={styles.wrapper}>
              <Categories
                serviceId={serviceId}
                activeCategoryId={categoryId}
                categoryRoute={categoryRoute}
                sportSlug={sportSlug}
                withIcon={categoriesWithIcon}
              />
            </div>
          )}
        </Fragment>
      );
    })
  );
};

export default memo(BaseMenuList);
