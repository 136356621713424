import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const useInstantValidation = (name, enable) => {
  const { watch, trigger } = useFormContext();
  const fieldWatched = watch(name);
  useEffect(() => {
    if (fieldWatched && enable) {
      trigger(name);
    }
  }, [enable, fieldWatched, name, trigger]);
};

export default useInstantValidation;
