import { useCallback } from 'react';
import clsx from 'clsx';
import { Icon } from '@namespace/icons2';
import Box from '../../../Box';
import AText from '../../../AText';
import styles from './index.module.css';

const CodesList = ({
  setShow,
  handleChangeCode,
  codes,
  filterRef,
  value,
  size = 's'
}) => {
  const handleClickFactory = useCallback(
    (phoneCode, iso1Code) => (e) => {
      e.preventDefault();
      handleChangeCode(phoneCode, iso1Code);
      setShow(false);
    },
    [handleChangeCode, setShow]
  );
  return (
    <Box
      className={`${styles.general} ${styles[size]}`}
      ref={filterRef}
      direction="column"
    >
      {codes.map(({ countryName, phoneCode, iso1Code }) => {
        const isActive =
          value && phoneCode === value.code && iso1Code === value.isoCode;
        return (
          <Box
            key={countryName}
            align="center"
            onClick={handleClickFactory(phoneCode, iso1Code)}
            className={clsx(styles.option, isActive && styles.active)}
          >
            <AText className={`${styles.title}`} color="txt_color_3">
              {`+${phoneCode} ${countryName}`}
            </AText>
            {isActive && (
              <Icon
                name="icons/general/action/done"
                color="state_success"
                size="m"
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default CodesList;
