import { useEffect, useState, useCallback } from 'react';
import clsx from 'clsx';
import { Box, AText } from '@alf/uikit';
import { DateTime } from 'luxon';
import { Icon } from '@namespace/icons2';
import styles from './index.module.css';

const FooterClock = ({ classNames = {} }) => {
  const dateFormatType = `dd'.'LL'.'yyyy '|' HH':'mm':'ss`;
  const localTimeZone = DateTime.local().toFormat('Z');
  const [clock, setClock] = useState(DateTime.local().toFormat(dateFormatType));
  const { customClockWrapper = '' } = classNames;
  const getDateTime = useCallback(() => {
    setClock(DateTime.local().toFormat(dateFormatType));
  }, [DateTime]);

  useEffect(() => {
    setInterval(getDateTime, 1000);
    return () => {
      clearInterval(getDateTime);
    };
  }, []);

  return (
    <Box
      align="center"
      className={clsx([styles.clockWrapper, customClockWrapper])}
    >
      <Icon
        size="s"
        color="footer_txt_1"
        name="icons/general/item/clock"
        className={styles.icon}
      />
      <AText color="footer_txt_1" size="s" className={styles.clock}>
        {clock}
      </AText>
      <AText color="footer_txt_1" size="s">
        {` (UTC ${localTimeZone})`}
      </AText>
    </Box>
  );
};

export default FooterClock;
