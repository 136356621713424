import { useMemo } from 'react';
import { useConfig, useGeneralRoutes } from '@namespace/cms';
import { BreadCrumbsMicrodata } from '@namespace/common';
import { IS_PRERENDER } from '@namespace/helpers';
import { useGetPathParams } from '../../hooks/useGetPathParams';
import { MODE } from '../../constants';
import { generateBreadCrumbsItems } from '../../utils';

const LiveBreadCrumbsMicrodata = ({ id }) => {
  const { config } = useConfig(id);
  const { isPrerenderOnly = true } = config;
  const isRender = !isPrerenderOnly || (isPrerenderOnly && IS_PRERENDER);
  const params = useGetPathParams(MODE.IN_PLAY);
  const { liveSport, liveEvent } = useGeneralRoutes();
  const items = useMemo(
    () =>
      isRender
        ? generateBreadCrumbsItems(params, liveSport, '', liveEvent)
        : null,
    [params, liveSport, liveEvent, isRender]
  );

  if (!isRender) {
    return null;
  }

  return <BreadCrumbsMicrodata items={items} />;
};

export default LiveBreadCrumbsMicrodata;
