import { useCallback } from 'react';
import { useHistory } from '@namespace/router';
import { useGeneralRoutes } from '@namespace/cms';
import { saveNewPassword } from '../../components/PasswordRecovery/api';

const usePasswordRecovery = (onSuccess, onCancel) => {
  const { login } = useGeneralRoutes();
  const { push } = useHistory();

  const savePassword = useCallback(
    async ({ newPassword, repeatNewPassword, token, question, answer }) => {
      await saveNewPassword({
        token,
        question,
        answer,
        new_password: newPassword,
        new_password_compare: repeatNewPassword
      }).then(({ response }) => onSuccess({ ...response }));
    },
    [saveNewPassword, onSuccess]
  );

  const cancel = useCallback(() => {
    onCancel();
    push(login);
  }, [onCancel, push]);

  return {
    cancel,
    savePassword
  };
};

export default usePasswordRecovery;
