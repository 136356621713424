import { getTokens, addToken, updateToken } from '../../api/tokens';
import { deposit, withdraw } from '../../api/balance';
import { types } from '../types';

export const actions = {
  [types.GET_SW_CONFIG]: (dispatch) => async (paymentSystems) => {
    dispatch({
      type: types.SET_SW_CONFIG,
      payload: paymentSystems
    });
  },
  [types.GET_USER_TOKENS]: (dispatch) => async (serviceId, isForWithdraw) => {
    const requestParams = { service_id: serviceId };
    const withdrawRequestParams = {
      ...requestParams,
      only_for_withdraw: isForWithdraw,
      statuses: isForWithdraw
    };
    const tokens = await getTokens(
      (isForWithdraw && withdrawRequestParams) || requestParams
    );
    const response = tokens?.response || [];
    const payload = response.map(({ statuses = {}, ...itemProps }) => ({
      hasNtf: statuses.docRequested,
      ...itemProps
    }));

    dispatch({
      type: types.SET_USER_TOKENS,
      payload
    });
  },
  [types.UPDATE_USER_TOKEN]: () => async (
    serviceId,
    paymentToken,
    tokenObj
  ) => {
    const res = await updateToken({
      serviceId,
      data: { paymentToken, paymentTokenObject: tokenObj }
    });

    return res?.response;
  },
  [types.ADD_USER_TOKEN]: () => async (serviceId, token, tokenType) => {
    const res = await addToken({
      serviceId,
      data: { [tokenType]: token }
    });

    return res?.response;
  },
  [types.DEPOSIT]: (dispatch) => async (params) => {
    const resultDepositData = await deposit(params);

    dispatch({
      type: types.SET_DEPOSIT_RESULT,
      payload: resultDepositData
    });

    return resultDepositData;
  },
  [types.WITHDRAW]: (dispatch) => async (params) => {
    const resultWithdrawData = await withdraw(params);

    dispatch({
      type: types.SET_DEPOSIT_RESULT,
      payload: resultWithdrawData
    });

    return resultWithdrawData;
  }
};
