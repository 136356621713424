export const BET_RESULT_STATUS = {
  ALL: 'all',
  WIN: 'win',
  HALF_WIN: 'halfwin',
  RETURN: 'return',
  HALF_RETURN: 'halfreturn',
  LOSE: 'lose',
  UNDEFINED: 'undefined',
  CASHOUT: 'cashout',
  RISK_FREE: 'riskfree'
};

export const CASINO_DATE_FILTER = [
  {
    value: '2',
    label: 'date.todayYesterday'
  },
  {
    value: '7',
    label: 'date.for7days'
  },
  {
    value: '14',
    label: 'date.for14days'
  },
  {
    value: '30',
    label: 'date.for30days'
  }
];

export const BET_RESULT_STATUS_ICON_NAME = {
  LOSE: 'canceled',
  RETURN: 'return',
  WIN: 'success',
  UNDEFINED: 'in-process',
  CASHOUT: 'cashout'
};

export const BET_RESULT_STATUS_ICON_FILL = {
  [BET_RESULT_STATUS_ICON_NAME.LOSE]: 'state_error',
  [BET_RESULT_STATUS_ICON_NAME.WIN]: 'state_success',
  [BET_RESULT_STATUS_ICON_NAME.RETURN]: 'state_warning',
  [BET_RESULT_STATUS_ICON_NAME.UNDEFINED]: 'state_info',
  [BET_RESULT_STATUS_ICON_NAME.CASHOUT]: 'accent_color_1'
};

export const BET_DETAILS_RESULT_COLOR = {
  [BET_RESULT_STATUS.LOSE]: 'state_error',
  [BET_RESULT_STATUS.WIN]: 'state_success',
  [BET_RESULT_STATUS.UNDEFINED]: 'state_info',
  [BET_RESULT_STATUS.RETURN]: 'state_warning'
};

export const BET_DETAILS_RESULT_ICON = {
  [BET_RESULT_STATUS.LOSE]: 'canceled',
  [BET_RESULT_STATUS.WIN]: 'success',
  [BET_RESULT_STATUS.UNDEFINED]: 'in-progress',
  [BET_RESULT_STATUS.CASHOUT]: 'cashout',
  [BET_RESULT_STATUS.RETURN]: 'return'
};

export const BET_TYPES = {
  ALL: -1, // TODO FILTERS?
  SINGLE: 1,
  MULTIPLE: 2,
  SYSTEM: 3,
  MULTI_SYSTEM: 4
};

export const BETS_TYPE_FILTER = [
  {
    value: String(BET_TYPES.ALL),
    label: 'betsSport.bidType.all'
  },
  {
    value: String(BET_TYPES.SINGLE),
    label: 'betsSport.bidType.single'
  },
  {
    value: String(BET_TYPES.MULTIPLE),
    label: 'betsSport.bidType.multiple'
  },
  {
    value: String(BET_TYPES.SYSTEM),
    label: 'betsSport.bidType.system'
  }
];

export const CHANCE_BET = 'chanceBet';

export const BITMASK = {
  [BET_RESULT_STATUS.CASHOUT]: 32768,
  [BET_RESULT_STATUS.RISK_FREE]: 768
};

export const KENO_STATUS_MAP = {
  ALL: 'All',
  WIN: 'Win',
  LOST: 'Lost',
  CANCEL: 'Cancel',
  RETURN: 'Return',
  UNDEFINED: 'Undefined'
};

export const TRANSACTION_TYPE = {
  WIN: 'win',
  BET: 'bet',
  LOSE: 'lose',
  REFUND: 'refund',
  BONUS_WIN: 'bonusWin',
  FREE_SPIN: 'freespin'
};

export const PRESET_COEFFICIENTS = [50, 100, 500];

// FEP-1053 - only for AZ, change logic for quick amount in betslip
export const NEW_PRESET_COEFFICIENTS = [1, 5, 10, 20, 50, 100];

export const BETS_STATUS_FILTER = [
  { label: 'betsSport.status.all', value: BET_RESULT_STATUS.ALL },
  { label: 'betsSport.status.win', value: BET_RESULT_STATUS.WIN },
  { label: 'betsSport.status.lose', value: BET_RESULT_STATUS.LOSE },
  { label: 'betsSport.status.return', value: BET_RESULT_STATUS.RETURN },
  { label: 'betsSport.status.undefined', value: BET_RESULT_STATUS.UNDEFINED }
];

export const HISTORY_BETS_STATUS_FILTER = [
  { label: 'kenoFilter.All', value: null },
  { label: 'kenoFilter.Win', value: KENO_STATUS_MAP.WIN },
  { label: 'kenoFilter.Lost', value: KENO_STATUS_MAP.LOST },
  { label: 'kenoFilter.Cancel', value: KENO_STATUS_MAP.CANCEL },
  { label: 'kenoFilter.Return', value: KENO_STATUS_MAP.RETURN },
  { label: 'kenoFilter.Undefined', value: KENO_STATUS_MAP.UNDEFINED }
];

export const SERVICE_IDS_TYPES_MAP = {
  PRE_MATCH: 'preMatch',
  LIVE: 'live',
  MIXED: 'mixed',

  INSPIRED: 'inspired',
  VFL: 'vfl',
  VERMANTIA: 'vermantia',

  KENO: 'keno'
};

export const SERVICE_IDS = {
  [SERVICE_IDS_TYPES_MAP.PRE_MATCH]: 0,
  [SERVICE_IDS_TYPES_MAP.LIVE]: 1,
  [SERVICE_IDS_TYPES_MAP.MIXED]: 31,

  [SERVICE_IDS_TYPES_MAP.INSPIRED]: 2,
  [SERVICE_IDS_TYPES_MAP.VFL]: 20,
  [SERVICE_IDS_TYPES_MAP.VERMANTIA]: 57,

  [SERVICE_IDS_TYPES_MAP.KENO]: 68
};

export const LOTTERY_PRODUCT_ID = {
  LUCKY_WHEEL: 5,
  BOOK_OF_SIX: 9,
  KENO: 7
};

export const ODDS_TYPE_MAP = {
  DECIMAL: 'decimal',
  HONG_KONG: 'hongkong',
  MALAYSIAN: 'malay'
};

export const WIN_AMOUNT_CALC_TYPE = {
  CLEAN: 'clean',
  FULL: 'full'
};

export const RESULT_VIEW_TYPE = {
  DEFAULT: 'default',
  FULL: 'full'
};

export const BONUS_SPORT_ID = 81;

export const BET_TYPES_BETS_LENGTH = {
  1: 1,
  2: 2,
  3: 3,
  4: 3
};

export const MATCH_TYPES = {
  [SERVICE_IDS[SERVICE_IDS_TYPES_MAP.LIVE]]: 'betslip.title.live',
  [SERVICE_IDS[SERVICE_IDS_TYPES_MAP.PRE_MATCH]]: 'betslip.title.preMatch'
};

export const BARCODE_FORMAT = 'CODE128B';
export const BARCODE_FONT = 'Roboto Condensed, sans-serif';

export const AMOUNT_MULTI_SYSTEM_FIELDS = ['amount', 'min', 'max'];

export const CASINO_BET_RESULT_STATUS = {
  WIN: 'win',
  REFUND: 'refund',
  CANCEL: 'cancel',
  LOSE: 'lose',
  INDEFINITE: 'indefinite'
};

export const CASINO_BET_TYPES = {
  1: 'win',
  2: 'lose',
  3: 'refund',
  4: 'canceled',
  5: 'indefinite'
};

export const CASINO_BET_RESULT_STATUS_ICON_FILL = {
  [CASINO_BET_TYPES[1]]: 'success',
  [CASINO_BET_TYPES[2]]: 'canceled',
  [CASINO_BET_TYPES[3]]: 'cashout',
  [CASINO_BET_TYPES[4]]: 'cashout',
  [CASINO_BET_TYPES[5]]: 'in-process'
};

export const CASINO_BET_DETAILS_RESULT_COLOR = {
  [CASINO_BET_RESULT_STATUS.LOSE]: 'state_error',
  [CASINO_BET_RESULT_STATUS.WIN]: 'state_success',
  [CASINO_BET_RESULT_STATUS.INDEFINITE]: 'state_info',
  [CASINO_BET_RESULT_STATUS.CANCEL]: 'state_warning',
  [CASINO_BET_RESULT_STATUS.REFUND]: 'state_warning'
};

export const BET_ITEM_PLACE = {
  BET_SLIP: 'betslip',
  PO_BETS_HISTORY: 'po-bets-history'
};
