import { types } from '../types';

export const actions = {
  [types.SET_CONTENT_HEIGHT]: (dispatch) => (contentHeight) => {
    dispatch({
      type: types.SET_CONTENT_HEIGHT,
      payload: contentHeight
    });
  }
};
