import PropTypes from 'prop-types';
import clsx from 'clsx';
import Text from './components/Text';
import { getPropsByBreed } from './a-text.config';

const AText = (props) => {
  const {
    // eslint-disable-next-line
    className: propClassName = '',
    breed,
    'data-role': dataRole,
    ...rest
  } = props;

  if (!breed) {
    return <Text {...props} />;
  }

  const { className: breedClassName = '', ...breedProps } = getPropsByBreed(
    breed,
    rest.size
  );

  const className = clsx(propClassName, breedClassName);

  return (
    <Text
      {...breedProps}
      {...rest}
      data-role={dataRole}
      className={className}
    />
  );
};

AText.propTypes = {
  intent: PropTypes.oneOf([
    'color_1',
    'color_2',
    'color_3',
    'color_4',
    'color_5',
    'color_6',
    'color_7',
    'color_error'
  ]),
  color: PropTypes.string,
  size: PropTypes.oneOf([
    '2xs',
    'xs',
    's',
    'm',
    'l',
    'xl',
    '2xl',
    '3xl',
    '4xl'
  ]),
  breed: PropTypes.oneOf(['R', 'RU', 'B', 'BU']),
  transform: PropTypes.oneOf([
    'capitalize',
    'lowercase',
    'uppercase',
    'initial'
  ]),
  decoration: PropTypes.oneOf([
    'none',
    'underline',
    'overline',
    'line-through'
  ]),
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.elementType,
    PropTypes.node
  ]),
  align: PropTypes.oneOf(['left', 'right', 'center']),
  breakWord: PropTypes.oneOf(['break-all', 'break-word', 'keep-all', 'normal']),
  children: PropTypes.node,
  overflow: PropTypes.oneOf(['ellipsis', 'hidden']),
  typeface: PropTypes.oneOf(['base']),
  inject: PropTypes.bool,
  className: PropTypes.string,
  danger: PropTypes.bool
};

AText.defaultProps = {
  size: 'm'
};

export default AText;
