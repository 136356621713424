export const PATEPLAY_EVENTS = {
  EXIT: 'exit',
  RELOAD: 'reload'
};

export const pateplayLauncherControl = (event, callbacks = {}) => {
  const { onExitHandler = () => {}, onReloadHandler = () => {} } = callbacks;

  if (event.data) {
    if (event.data.event === PATEPLAY_EVENTS.EXIT) {
      onExitHandler();
    } else if (event.data.event === PATEPLAY_EVENTS.RELOAD) {
      onReloadHandler();
    }
  }
};
