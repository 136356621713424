import { Box } from '@alf/uikit';
import AppGallery from './assets/AppGallery.jpg';
import AppStore from './assets/AppStore.jpg';
import GooglePlay from './assets/GooglePlay.jpg';
import {
  APP_GALLERY_LINK,
  APP_STORE_LINK,
  GOOGLE_PLAY_LINK
} from './constants';
import styles from './index.module.css';

const PaymentIcons = ({ classNames = {} }) => {
  const { wrapperClassName = '' } = classNames;

  return (
    <Box justify="between" className={wrapperClassName}>
      <a
        className={styles.link}
        href={APP_GALLERY_LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={AppGallery} alt="" className={styles.icon} />
      </a>
      <a
        className={styles.link}
        href={APP_STORE_LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={AppStore} alt="" className={styles.icon} />
      </a>
      <a
        className={styles.link}
        href={GOOGLE_PLAY_LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={GooglePlay} alt="" className={styles.icon} />
      </a>
    </Box>
  );
};

export default PaymentIcons;
