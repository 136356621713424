import { useRouteParams } from '@namespace/router';
import { useGeneralRoutes } from '@namespace/cms';
import { SERVICE_IDS } from '@namespace/bets';
import useLoadBetSlip from '../../hooks/useLoadBetSlip';

const BetSlipLoadWrapperVirtual = () => {
  const { virtualSportsOneSport } = useGeneralRoutes();
  const { provider = '' } = useRouteParams(
    `${virtualSportsOneSport}/:provider/:categoryId/*`
  );

  useLoadBetSlip({ serviceId: SERVICE_IDS[provider] || '' });

  return null;
};

export default BetSlipLoadWrapperVirtual;
