import { AText, Box, LazyLoadImage } from '@alf/uikit';
import { useGetImage } from '@namespace/themes';
import { useCallback, useState } from 'react';
import styles from './index.module.css';

const ProviderImage = ({
  label,
  providerLogo,
  providerColoredLogo,
  classNames = {}
}) => {
  const [isText, setIsText] = useState(false);
  const { providerLogoClassName = '', labelClassName = '' } = classNames;

  const getImg = useGetImage();

  const actualProviderImgUrl = getImg(providerColoredLogo, providerLogo);

  const onLoadError = useCallback(
    (e) => {
      e.target.onerror = null;
      setIsText(true);
    },
    [setIsText, isText]
  );

  const handleLoad = useCallback(
    (e) => {
      const width = e.target.naturalWidth;
      const height = e.target.naturalHeight;
      if (width < 10 && height < 10) {
        setIsText(true);
      }
    },
    [setIsText]
  );

  return (
    <Box justify="center" align="center">
      {isText ? (
        <AText
          className={`${styles.label} ${labelClassName}`}
          align="center"
          breed="BU"
        >
          {label}
        </AText>
      ) : (
        <>
          <LazyLoadImage
            alt={`color-${label}`}
            src={actualProviderImgUrl}
            onError={onLoadError}
            onLoad={handleLoad}
            className={`${styles.providerLogo} ${providerLogoClassName}`}
          />
        </>
      )}
    </Box>
  );
};

export default ProviderImage;
