import { useContext } from 'react';
import clsx from 'clsx';
import { Notification, Box, Button, AText } from '@alf/uikit';
import { useIsNavMenuVisible } from '@namespace/common';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import {
  useGeneralRoutes,
  SiteSettingsContext,
  useOtherSettings
} from '@namespace/cms';
import { UserContext } from '@namespace/user/src/context';
import { DeviceContext } from '@namespace/device';

import useBetSlipVisible from '../../hooks/useBetSlipVisible';
import styles from './index.module.css';

const UnverifiedUserToast = ({ onClose }) => {
  const { t } = useI18n();
  const { profilePage, verificationDocuments } = useGeneralRoutes();
  const { isDesktop } = useContext(DeviceContext);
  const isBetSlipVisible = useBetSlipVisible();
  const {
    verificationToasts: { isCloseAfterLink = false } = {}
  } = useOtherSettings();
  const [{ isDefaultRegistratedUser }] = useContext(UserContext);
  const [settings] = useContext(SiteSettingsContext);
  const openVerificationAfterFillingPersonalData =
    settings?.otherSettings?.openVerificationAfterFillingPersonalData;
  const navMenuVisible = useIsNavMenuVisible();

  let pageLink;
  if (openVerificationAfterFillingPersonalData) {
    pageLink = isDefaultRegistratedUser ? profilePage : verificationDocuments;
  } else {
    pageLink = profilePage;
  }

  const message = (
    <Box align="center" justify="center">
      <Box
        className={styles.container}
        direction={isDesktop ? 'row' : 'column'}
        align="center"
        justify="center"
      >
        <AText
          color="notification_txt_color_1"
          size="l"
          align="center"
          className={styles.message}
        >
          {t('unverifiedUser.notification.info')}
        </AText>
        <Button
          {...(isCloseAfterLink ? { onClick: onClose } : {})}
          component={LocalizedLink}
          to={pageLink}
          size="s"
          intent="secondary"
          actionType="color4"
          className={styles.button}
        >
          {t('unverifiedUser.notification.button')}
        </Button>
      </Box>
    </Box>
  );

  return (
    <Notification
      onClose={onClose}
      message={message}
      className={clsx([
        styles.general,
        !isDesktop && styles.mobileNotification,
        isBetSlipVisible && styles.withBetslip,
        navMenuVisible && styles.navMenuEnabled
      ])}
      isMobileView={!isDesktop}
    />
  );
};

export default UnverifiedUserToast;
