import { useCallback, useContext } from 'react';
import { useGeneralRoutes } from '@namespace/cms';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { Icon, SportIcon } from '@namespace/icons2';
import { DeviceContext } from '@namespace/device';
import { AText, Box } from '@alf/uikit';
import { window } from '@namespace/helpers';
import PinnedIcon from '../PinnedIcon';
import styles from './index.module.css';

const BackHeader = ({
  title = '',
  Title,
  filtersClassName = '',
  containerClassName = '',
  backBtnClassName = '',
  titleClassName = '',
  arrowBackIcon = (
    <Icon size="s" name="icons/general/nav/back" color="bg_color_5" />
  ),
  showArrowBack = true,
  sportIcon = null,
  sportId = null,
  categoryId = null,
  onBackClick,
  isPrimary = true,
  children,
  backLink,
  dataRole,
  showSportIcon = true,
  showPinIcon = false,
  isPinned = false,
  isLiveSport = false,
  pinnedType = 'sport',
  contentRight
}) => {
  const { t } = useI18n();
  const { isMobile } = useContext(DeviceContext);
  const { home } = useGeneralRoutes();
  const { goBack, push } = useHistory();
  const sportIconClass = isPrimary ? styles.sportContainer : '';

  const handleClick = useCallback(() => {
    if (!window.history.state && !backLink) {
      return push(home);
    }
    if (backLink) {
      return push(backLink);
    }
    return onBackClick ? onBackClick() : goBack();
  }, [backLink, onBackClick, goBack, push, home]);

  if (isMobile && !title && !Title && sportId) {
    return null;
  }

  return (
    <Box
      flex="0 0 40px"
      className={`${styles.container} ${sportIconClass} ${containerClassName}`}
      align="center"
      data-role={dataRole}
    >
      {showArrowBack && (
        <Box
          align="center"
          left="s"
          right="m"
          onClick={handleClick}
          className={`${backBtnClassName} ${styles.back}`}
          data-role={dataRole && `${dataRole}-arrow-back`}
        >
          {arrowBackIcon}
        </Box>
      )}
      {Boolean(sportIcon) && <Box>{sportIcon}</Box>}
      {showSportIcon && Boolean(sportId) && (
        <Box align="center" right="s" className={styles.sportIcon}>
          <SportIcon sportId={sportId} size="s" />
        </Box>
      )}
      {title && (
        <Box flex="1 1 100%">
          <AText
            breed="BU"
            size="xl"
            color="line_header_txt"
            className={titleClassName}
          >
            {title}
          </AText>
          {isLiveSport && (
            <AText
              breed="BU"
              size="xl"
              color="line_accent_color_1"
              className={styles.liveSportTitle}
            >
              {t('sportsbook.back.header.live')}
            </AText>
          )}
        </Box>
      )}
      {Title && <Title />}
      {children && (
        <Box flex="1 0 auto" className={filtersClassName}>
          {children}
        </Box>
      )}
      {showPinIcon && (
        <PinnedIcon
          isPinned={isPinned}
          sportId={sportId}
          size="m"
          type={pinnedType}
          categoryId={categoryId}
          color="line_header_txt"
        />
      )}
      {contentRight}
    </Box>
  );
};

export default BackHeader;
