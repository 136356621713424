import { flatten, orderBy, sumBy, isEmpty } from 'lodash';
import { LOG_ACTIONS, logAction } from '@namespace/analytics';
import { isOdd } from '@namespace/common';
import { window } from '@namespace/helpers';

import {
  DATE_FILTER_CMS_VALUES,
  EVENT_TYPES,
  SPORT_IDS_FOR_EVENT_NAME_CHANGES,
  SUMMARIES_PLACE_TYPE,
  SUMMARIES_WINNER_TYPE
} from '../constants';

export const getHeadMarketsByTemplateId = ({
  headMarkets = [],
  headGroups = []
}) => {
  const headMarketsByTemplateId = new Map();
  const marketTemplateIds = headGroups.map(
    ({ marketTemplateId }) => marketTemplateId
  );
  for (const id of marketTemplateIds) {
    headMarketsByTemplateId.set(id, undefined);
  }
  for (const id of marketTemplateIds) {
    for (const item of headMarkets) {
      if (item && item[0]?.marketTemplateId === id) {
        headMarketsByTemplateId.set(id, item);
      }
    }
  }

  return headMarketsByTemplateId;
};

export const getOutcomesForMarket = (outcomeIds = [], outcomes = {}) =>
  outcomeIds.map((outcomeId) => outcomes[outcomeId]).filter(Boolean);

const getOutcomesDiff = (outcomes = []) => {
  const sortedOutcomes = orderBy(outcomes, ['outcomeCoef'], ['desc']);

  return sortedOutcomes[0].outcomeCoef - sortedOutcomes[1].outcomeCoef;
};

export const getMinMarketIds = (markets, outcomes) =>
  markets
    .map(({ marketId, marketHasParam: hasParam, outcomeIds }) => ({
      marketId,
      outcomeDiff: hasParam
        ? getOutcomesDiff(getOutcomesForMarket(outcomeIds, outcomes))
        : -Infinity
    }))
    .sort((a, b) => a.outcomeDiff - b.outcomeDiff)
    .map(({ marketId }) => marketId);

export const getHeadGroupsList = (headGroups, sportId) =>
  !SPORT_IDS_FOR_EVENT_NAME_CHANGES.includes(sportId) && headGroups[sportId]
    ? headGroups[sportId]
    : [];

// todo refactor: algorithm is suboptimal (can be done in one iteration), Map is not needed
export const groupByTournament = (events = []) =>
  events.reduce((acc, item, key, array) => {
    const { tournamentId } = item;
    if (!acc.has(tournamentId)) {
      const allEventsByT = array.filter(
        (event) => event.tournamentId === tournamentId
      );
      acc.set(tournamentId, allEventsByT);
    }
    return acc;
  }, new Map());

export const secondsToTime = (seconds, insertZero = false) => {
  if (seconds <= 0) {
    return '0:00';
  }
  const padFirst = (input) => (input < 10 && insertZero ? `0${input}` : input);
  const padSecond = (input) => (input < 10 ? `0${input}` : input);
  return [
    padFirst(Math.floor(seconds / 60)),
    padSecond(Math.floor(seconds % 60))
  ].join(':');
};

export const checkIsParamsNegative = (outcomes) => {
  const outcomesWithNegativeParams = outcomes.filter(
    ({ outcomeParam }) => Number(outcomeParam) < 0
  );

  return outcomesWithNegativeParams.length !== 0;
};

const getTransposesMatrix = (allMarkets = []) => {
  const arrayOfMarketsLength = allMarkets.map((markets) => markets.length);
  const maxLengthOfMarkets = Math.max(...arrayOfMarketsLength);
  const indexOfMaxLengthMarkets = arrayOfMarketsLength.indexOf(
    maxLengthOfMarkets
  );

  return allMarkets[indexOfMaxLengthMarkets]
    ? allMarkets[indexOfMaxLengthMarkets].map((_, i) =>
        allMarkets.map((row) => row[i])
      )
    : [];
};

const getLabel = {
  hours: ({ value, t, isShowStartingSoon, isShowNextMorning }) => {
    if (isShowStartingSoon) {
      return t('sportsbook.filtering.startingSoon');
    }
    if (isShowNextMorning) {
      return t('sportsbook.filtering.by.today');
    }
    const number = Number(value);
    const key = number / 60 < 1 ? 'minutes' : 'hours';
    return t(`sportsbook.filtering.by.${key}`, [
      number === 30 ? number : Math.floor(number / 60)
    ]);
  },
  days: ({ value, datetime, t, f }) => {
    const DATE_FORMATS_TYPES = f.getFormats();
    switch (value) {
      case 0:
        return t('sportsbook.filtering.by.today');
      case 1:
        return t('sportsbook.filtering.by.tomorrow');
      default:
        return datetime.toFormat(DATE_FORMATS_TYPES.EVENTS_LINE_DATE);
    }
  }
};

export const getTabs = ({
  dateFilterType,
  t,
  f,
  dateFilters,
  isShowStartingSoon,
  isShowNextMorning
}) => {
  const DATE_FORMATS_TYPES = f.getFormats();
  return dateFilters.map((day, index) => {
    let value = day;
    let datetime;

    if (dateFilterType === DATE_FILTER_CMS_VALUES.DAYS) {
      datetime = f.getDateTime().plus({ day });
      value = datetime.toFormat(DATE_FORMATS_TYPES.API);
    }

    const name = getLabel[dateFilterType]({
      value: day,
      datetime,
      t,
      f,
      isShowStartingSoon:
        dateFilterType === DATE_FILTER_CMS_VALUES.HOURS &&
        isShowStartingSoon &&
        index === 0,
      isShowNextMorning:
        dateFilterType === DATE_FILTER_CMS_VALUES.HOURS &&
        isShowNextMorning &&
        index === 1
    });

    return {
      name,
      value,
      dateFilterType
    };
  });
};

export const convertParam = (param) => {
  const numericParam = Math.abs(Number(param));
  if (isOdd(numericParam / 0.25)) {
    const firstParam = numericParam - 0.25;
    const secondParam = numericParam + 0.25;

    return `${firstParam}/${secondParam}`;
  }

  return String(numericParam);
};

export const getReadyCategories = (categories, sportId) =>
  flatten(Object.values(categories).map(({ data }) => data)).reduce(
    (acc, item, i, arr) => {
      const sameCategory = arr.filter(
        ({ categoryId }) => categoryId === item.categoryId
      );

      if (
        item.sportId === sportId &&
        !acc.some(({ categoryId }) => categoryId === item.categoryId)
      ) {
        return [
          ...acc,
          {
            ...item,
            sportId: item.categoryId,
            eventCount:
              sameCategory.length > 1
                ? sumBy(sameCategory, ({ eventCount }) => eventCount)
                : item.eventCount,
            sportSlug: item.categoryId,
            sportName: item.categoryName
          }
        ];
      }
      return acc;
    },
    []
  );

export const formatSelectOptions = (options = []) =>
  options.map(({ value, name }) => ({
    value,
    title: name
  }));

export const sortFavoriteSports = (sports, favoritesSports) =>
  favoritesSports.length
    ? sports
        .map((sport) => ({
          ...sport,
          isPinned: favoritesSports.includes(sport.sportId)
        }))
        .sort((a, b) => b.isPinned - a.isPinned)
    : sports;

export const getEventBodyMatrix = ({
  eventId,
  eventMarketsIds,
  headGroups,
  markets,
  outcomes
}) => {
  if (eventId && !isEmpty(eventMarketsIds)) {
    try {
      const headMarkets =
        eventMarketsIds.map((marketId) => markets[marketId]).filter(Boolean) ||
        [];

      // TODO: REWRITE AFTER BACKEND FOR ASIAN MARKETS WILL BE READY
      const marketsByType = headGroups
        .map((headGroup) =>
          headMarkets.filter(
            ({ marketTemplateId, resultTypeId }) =>
              marketTemplateId === headGroup.marketTemplateId &&
              resultTypeId === headGroup.resultTypeId
          )
        )
        .map((mrkts) => {
          const sortedMarketIds = getMinMarketIds(mrkts, outcomes);

          return sortedMarketIds
            .map((id) => mrkts.find(({ marketId }) => marketId === id))
            .map((market) => [market]);
        });
      const countOfRows = 1;
      return getTransposesMatrix(marketsByType).slice(0, countOfRows);
    } catch (e) {
      logAction(e, LOG_ACTIONS.EVENT_BODY_MARKETS);
    }
  }
  return [];
};

/**
 *
 * @param data
 * @param {string} sportPath
 * @param {string} categoryPath
 * @param {string} eventPath
 * @param {string} tournamentPath
 * @returns {*[]|[{path: string, label}]}
 */
export const generateBreadCrumbsItems = (
  data,
  sportPath = '',
  categoryPath = '',
  eventPath = '',
  tournamentPath = ''
) => {
  const { activeSport, category, event, tournament } = data;
  const { origin } = window.location;
  if (!activeSport) {
    return [];
  }
  const { sportSlug, sportName } = activeSport;

  let links = [
    {
      label: sportName,
      path: `${origin}${sportPath}/${sportSlug}`
    }
  ];

  if (category && categoryPath) {
    const { categoryId } = category || {};
    links = [
      ...links,
      {
        label: category.categoryName,
        path: `${origin}${categoryPath}/${sportSlug}/${categoryId}`
      }
    ];
  }

  if (tournament && tournamentPath) {
    const { tournamentName, tournamentId } = tournament || {};
    links = [
      ...links,
      {
        label: tournamentName,
        path: `${origin}${tournamentPath}/${sportSlug}/${tournamentId}`
      }
    ];
  }

  if (event && eventPath) {
    const { eventName, eventId } = event || {};
    links = [
      ...links,
      {
        label: eventName,
        path: `${origin}${eventPath}/${sportSlug}/${eventId}`
      }
    ];
  }

  return links;
};

export const generateCyberSportBreadCrumbsItems = (
  data,
  sportPath = '',
  categoryPath = '',
  tournamentPath = ''
) => {
  const { category, tournament } = data;
  const { origin } = window.location;

  let links = [
    {
      label: 'e-sport',
      path: `${origin}${sportPath}`
    }
  ];

  if (category && categoryPath) {
    const { categoryId } = category || {};
    links = [
      ...links,
      {
        label: category.categoryName,
        path: `${origin}${categoryPath}/${categoryId}`
      }
    ];
  }

  if (tournament && tournamentPath) {
    const { tournamentName, tournamentId, categoryId } = tournament || {};
    links = [
      ...links,
      {
        label: tournamentName,
        path: `${origin}${tournamentPath}/${categoryId}/${tournamentId}`
      }
    ];
  }

  return links;
};

export const getIsSummariesScoreboard = (scoreboard) => {
  const { type = '', places = [], winner = [] } = scoreboard || {};
  const isPlacesScoreboard =
    Boolean(places.length) || type === SUMMARIES_PLACE_TYPE;
  const isWinnerScoreboard =
    Boolean(winner.length) || type === SUMMARIES_WINNER_TYPE;
  const isSummariesScoreboard = [isWinnerScoreboard, isPlacesScoreboard].some(
    Boolean
  );

  return {
    isSummariesScoreboard,
    isWinnerScoreboard,
    isPlacesScoreboard
  };
};

export const getFilteredByStateTopPreMatchEvents = ({
  filteredByDatePresets,
  eventsStore,
  eventsByTournaments
}) => {
  const filterByEventStatusType = (eventList) => ({ eventId }) => {
    const { [eventId]: event = {} } = eventList;
    return EVENT_TYPES.NOT_STARTED === event.eventStatusType;
  };

  return filteredByDatePresets
    .map((preset) => {
      const { events = [], tournaments = [] } = preset;
      let filteredPreset = { ...preset };
      if (events.length) {
        const isSingleEvent = events.length === 1;
        const singleEventId = events[0].eventId;
        const { [singleEventId]: event = {} } = eventsStore;
        const isInProgress =
          event.eventStatusType === EVENT_TYPES.INPROGRESS || event.inprogress;
        const filteredEvents = events.filter(
          filterByEventStatusType(eventsStore)
        );
        filteredPreset = {
          ...filteredPreset,
          events:
            isSingleEvent && isInProgress
              ? [{ ...events[0], inprogress: isInProgress }]
              : filteredEvents
        };
      }
      if (tournaments.length) {
        const filteredTournaments = tournaments.filter(({ tournamentId }) => {
          const eventsByTournament = eventsByTournaments.get(tournamentId);
          if (eventsByTournament?.length) {
            return eventsByTournament.filter(
              filterByEventStatusType(eventsStore)
            ).length;
          }
          return false;
        });
        filteredPreset = {
          ...filteredPreset,
          tournaments: filteredTournaments
        };
      }
      if (!filteredPreset.events.length && !filteredPreset.tournaments.length) {
        return null;
      }
      return filteredPreset;
    })
    .filter(Boolean);
};
