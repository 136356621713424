import { useEffect, useRef, useState } from 'react';
import { useI18n } from '@namespace/i18n';
import { isEmpty } from 'lodash';
import { getEventsBySports } from '../../api';
import { useFetchEventsFilter } from '../useFetchEventsFilter';
import { sportsbookActions, useSportsbookStore } from '../../store';
import { useAdvFilterStore } from '../../storeAdvFilter';

const { ADD_EVENTS, SET_EVENTS } = sportsbookActions;

export const useCustomFilterEvents = ({
  lineKey,
  sportId,
  categoryId,
  tournamentsIds,
  filter
}) => {
  const lines = useSportsbookStore('lines');
  const advFilter = useAdvFilterStore('advFilter');
  const {
    [lineKey]: { eventsIds, initialEventsLimit, offset: generalOffset } = {}
  } = lines;
  const isMounted = useRef(false);
  const [isDisplayLoader, setIsDisplayLoader] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isEventsLoaded, setIsEventsLoaded] = useState(
    eventsIds?.length < initialEventsLimit
  );
  const { language: lang } = useI18n();
  const filterValue = useFetchEventsFilter(filter);
  const { timeRange, tzDiff, date } = filterValue || {};

  const fetchEvents = async ({ limit, offset }) => {
    const { result: eventGroups } = await getEventsBySports({
      params: [
        {
          by: {
            lang,
            service_id: 0,
            head_markets: true,
            ...(!Number(categoryId) &&
              !tournamentsIds.length && { sport_id: sportId }),
            ...(Number(categoryId) &&
              !tournamentsIds.length && {
                category_id: {
                  $in: [Number(categoryId)]
                }
              }),
            ...(tournamentsIds.length && {
              tournament_id: {
                $in: tournamentsIds
              }
            }),
            tz_diff: tzDiff,
            ...(filterValue && date && { date }),
            ...(filterValue && timeRange && { time_filter: timeRange })
          },
          limit,
          offset
        }
      ]
    });

    return eventGroups;
  };

  const loadMoreEvents = async () => {
    try {
      setIsLoadingMore(true);
      const eventGroups = await fetchEvents({
        limit: 10,
        offset: lines[lineKey] ? generalOffset : 0
      });

      await ADD_EVENTS({ events: eventGroups[0], lineKey });

      if (eventGroups[0].length < 10) {
        setIsEventsLoaded(true);
      }
      setIsLoadingMore(false);
    } catch (e) {
      setIsLoadingMore(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(advFilter) && generalOffset > initialEventsLimit) {
      setIsEventsLoaded(false);
    }
  }, [advFilter]);

  useEffect(() => {
    // This is loading events by date
    (async () => {
      // This condition must be here because we don't need to load events in first render,
      // because we already have events on this stage
      if (isMounted.current) {
        try {
          setIsEventsLoaded(false);
          setIsDisplayLoader(true);

          const eventGroups = await fetchEvents({
            limit: initialEventsLimit,
            offset: 0
          });
          if (eventGroups[0].length < initialEventsLimit) {
            setIsEventsLoaded(true);
          }

          await SET_EVENTS({
            events: eventGroups[0],
            lineKey,
            dateFilterValue: filterValue
          });

          setIsDisplayLoader(false);
        } catch (e) {
          setIsDisplayLoader(false);
        }
      }
    })();
  }, [filterValue]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return {
    loadMoreEvents,
    isLoadingMore,
    isEventsLoaded,
    isEventsLoading: isDisplayLoader
  };
};
