import { Box } from '@alf/uikit';
import LiveScore from '../../../LiveScore';
import EventParticipants from './EventParticipants';
import EventState from '../EventState';
import styles from './index.module.css';

const ScoreTable = ({ eventId, setEventResultText }) => {
  return (
    <Box
      justify="between"
      align="start"
      className={styles.scoreTable}
      data-role="score-table"
    >
      <Box direction="column" flex="1 1 100%">
        <EventState eventId={eventId} />
        <EventParticipants eventId={eventId} />
      </Box>
      <LiveScore eventId={eventId} setEventResultText={setEventResultText} />
    </Box>
  );
};

export default ScoreTable;
