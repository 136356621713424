import { getDetailedScoresForTable } from '../../../utils';
import {
  CRICKET_2_INNINGS_SPORTFORM_IDS,
  SCOPE_IDS,
  RESULT_TYPE_IDS,
  CRICKET_TOTAL_SPORTFORM_IDS
} from '../../../../../constants';

const getScoresDataForResultType = (data, resultTypeId) =>
  data.find(({ resultTypeId: rId }) => resultTypeId === rId)?.scores || [];

const getScoreFor1InningGame = ({
  scoreData,
  eventServing,
  participants,
  isAlwaysDisplayScore
}) => {
  const {
    runsByPeriod,
    wicketsByPeriod,
    deliveriesByPeriod,
    oversByPeriod
  } = scoreData;
  const { participantNumber: servingTeamNumber } =
    participants.find(
      ({ participantId }) => participantId === Number(eventServing)
    ) || {};

  const [team1Runs, team2Runs] = getScoresDataForResultType(
    runsByPeriod,
    RESULT_TYPE_IDS.CRICKET_INNING_1
  );

  const [team1Wickets, team2Wickets] = getScoresDataForResultType(
    wicketsByPeriod,
    RESULT_TYPE_IDS.CRICKET_INNING_1
  );

  const [team1Deliveries, team2Deliveries] = getScoresDataForResultType(
    deliveriesByPeriod,
    RESULT_TYPE_IDS.CRICKET_INNING_1
  );

  const [team1Overs, team2Overs] = getScoresDataForResultType(
    oversByPeriod,
    RESULT_TYPE_IDS.CRICKET_INNING_1
  );
  // if there are some kind of points or team is serving then we show team 1 score
  const isDisplayTeam1Score =
    isAlwaysDisplayScore ||
    servingTeamNumber === 1 ||
    team1Runs ||
    team1Wickets ||
    team1Wickets ||
    team1Overs;
  // if there are some kind of points or team is serving then we show team 2 score
  const isDisplayTeam2Score =
    isAlwaysDisplayScore ||
    servingTeamNumber === 2 ||
    team2Runs ||
    team2Wickets ||
    team2Deliveries ||
    team2Overs;

  return [
    isDisplayTeam1Score
      ? `${team1Runs}${
          team1Wickets >= 10 ? ' All out' : `/${team1Wickets}`
        } (${team1Overs}.${team1Deliveries})`
      : '',
    isDisplayTeam2Score
      ? `${team2Runs}${
          team2Wickets >= 10 ? ' All out' : `/${team2Wickets}`
        } (${team2Overs}.${team2Deliveries})`
      : ''
  ];
};

const getScoreFor2InningsGame = ({
  scoreData,
  participants,
  eventResultId,
  eventServing,
  isAlwaysDisplayScore
}) => {
  const {
    runsByPeriod,
    wicketsByPeriod,
    oversByPeriod,
    deliveriesByPeriod
  } = scoreData;
  const { participantNumber: servingTeamNumber } =
    participants.find(
      ({ participantId }) => participantId === Number(eventServing)
    ) || {};

  const [inning1Team1Runs, inning1Team2Runs] = getScoresDataForResultType(
    runsByPeriod,
    RESULT_TYPE_IDS.CRICKET_INNING_1
  );
  const [inning1Team1Wickets, inning1Team2Wickets] = getScoresDataForResultType(
    wicketsByPeriod,
    RESULT_TYPE_IDS.CRICKET_INNING_1
  );

  const [
    inning1Team1Deliveries,
    inning1Team2Deliveries
  ] = getScoresDataForResultType(
    deliveriesByPeriod,
    RESULT_TYPE_IDS.CRICKET_INNING_1
  );

  const [inning1Team1Overs, inning1Team2Overs] = getScoresDataForResultType(
    oversByPeriod,
    RESULT_TYPE_IDS.CRICKET_INNING_1
  );

  const isDisplayTeam1Score1Inning =
    isAlwaysDisplayScore ||
    servingTeamNumber === 1 ||
    inning1Team1Runs ||
    inning1Team1Deliveries ||
    inning1Team1Overs;

  const isDisplayTeam2Score1Inning =
    isAlwaysDisplayScore ||
    servingTeamNumber === 2 ||
    inning1Team2Runs ||
    inning1Team2Deliveries ||
    inning1Team2Overs;

  let totalScore = [
    isDisplayTeam1Score1Inning
      ? `${inning1Team1Runs}${
          (inning1Team1Wickets !== 10 && inning1Team1Wickets > 0) ||
          (servingTeamNumber === 1 && inning1Team1Wickets !== 10)
            ? `/${inning1Team1Wickets}`
            : ''
        }`
      : '',
    isDisplayTeam2Score1Inning
      ? `${inning1Team2Runs}${
          (inning1Team2Wickets !== 10 && inning1Team2Wickets > 0) ||
          (servingTeamNumber === 2 && inning1Team2Wickets !== 10)
            ? `/${inning1Team2Wickets}`
            : ''
        }`
      : ''
  ];

  if (eventResultId === RESULT_TYPE_IDS.CRICKET_INNING_2) {
    const [inning2Team1Runs, inning2Team2Runs] = getScoresDataForResultType(
      runsByPeriod,
      RESULT_TYPE_IDS.CRICKET_INNING_2
    );
    const [
      inning2Team1Wickets,
      inning2Team2Wickets
    ] = getScoresDataForResultType(
      wicketsByPeriod,
      RESULT_TYPE_IDS.CRICKET_INNING_2
    );

    const [
      inning2Team1Deliveries,
      inning2Team2Deliveries
    ] = getScoresDataForResultType(
      deliveriesByPeriod,
      RESULT_TYPE_IDS.CRICKET_INNING_2
    );

    const [inning2Team1Overs, inning2Team2Overs] = getScoresDataForResultType(
      oversByPeriod,
      RESULT_TYPE_IDS.CRICKET_INNING_2
    );

    const isDisplayTeam1Score2Inning =
      isAlwaysDisplayScore ||
      servingTeamNumber === 1 ||
      inning2Team1Runs ||
      inning2Team1Deliveries ||
      inning2Team1Overs;
    const isDisplayTeam2Score2Inning =
      isAlwaysDisplayScore ||
      servingTeamNumber === 2 ||
      inning2Team2Runs ||
      inning2Team2Deliveries ||
      inning2Team2Overs;

    totalScore = [
      `${inning1Team1Runs} ${
        isDisplayTeam1Score2Inning
          ? `& ${inning2Team1Runs}${
              (inning2Team1Wickets !== 10 &&
                (inning2Team1Wickets !== 0 || isAlwaysDisplayScore)) ||
              (servingTeamNumber === 1 && inning2Team1Wickets !== 10)
                ? `/${inning2Team1Wickets}`
                : ''
            }`
          : ''
      }`,
      `${inning1Team2Runs} ${
        isDisplayTeam2Score2Inning
          ? `& ${inning2Team2Runs}${
              (inning2Team2Wickets !== 10 &&
                (inning2Team2Wickets !== 0 || isAlwaysDisplayScore)) ||
              (servingTeamNumber === 2 && inning2Team2Wickets !== 10)
                ? `/${inning2Team2Wickets}`
                : ''
            }`
          : ''
      }`
    ];
  }

  return totalScore;
};

export const getCricketScore = ({
  byResultType = [],
  eventServing = '',
  participants = [],
  sportFormId,
  eventResultId,
  total = [],
  isAlwaysDisplayScore = false
}) => {
  if (CRICKET_TOTAL_SPORTFORM_IDS.includes(sportFormId)) {
    const { scopeData = [] } = total.find(
      ({ scopeId }) => scopeId === SCOPE_IDS.RUNS
    );

    return scopeData
      .sort(({ number: numberA }, { number: numberB }) => numberA - numberB)
      .reduce((acc, { value }) => [...acc, value], []);
  }

  const runsByPeriod = getDetailedScoresForTable(byResultType, SCOPE_IDS.RUNS);
  const wicketsByPeriod = getDetailedScoresForTable(
    byResultType,
    SCOPE_IDS.WICKET
  );
  const deliveriesByPeriod = getDetailedScoresForTable(
    byResultType,
    SCOPE_IDS.DELIVERY
  );
  const oversByPeriod = getDetailedScoresForTable(byResultType, SCOPE_IDS.OVER);

  const scoreData = {
    runsByPeriod,
    wicketsByPeriod,
    deliveriesByPeriod,
    oversByPeriod
  };

  return CRICKET_2_INNINGS_SPORTFORM_IDS.includes(sportFormId)
    ? getScoreFor2InningsGame({
        scoreData,
        eventServing,
        eventResultId,
        participants,
        isAlwaysDisplayScore
      })
    : getScoreFor1InningGame({
        scoreData,
        eventServing,
        participants,
        isAlwaysDisplayScore
      });
};
