import { useReducer, createContext } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import { reducers, types, initialState } from './reducers';

export const BurgerMenuContext = createContext();

export const BurgerMenuProvider = ({ customReducers, children }) => {
  const [state, dispatch] = useReducer(
    combineReducers(reducers, customReducers),
    initialState
  );

  const actions = useActions(types, dispatch);

  return (
    <BurgerMenuContext.Provider value={[state, actions]}>
      {children}
    </BurgerMenuContext.Provider>
  );
};
