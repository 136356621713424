import { useContext, useMemo } from 'react';
import { matchPath } from 'react-router-dom';
import { any, map, pipe, prop } from 'ramda';
import { useLocation } from '@namespace/i18n';
import { SiteSettingsContext } from '@namespace/cms';
import { COOKIES_KEYS, getCookie } from '@namespace/helpers';
import { useUserSegmentsIncludes } from '@namespace/segments';
import { DeviceContext } from '@namespace/device';

const useMobileAppBanner = () => {
  const { pathname } = useLocation();
  const isMobileAppBannerDisabled = getCookie(
    COOKIES_KEYS.IS_MOBILE_APP_BANNER_DISABLED,
    false
  );
  const { isIOS } = useContext(DeviceContext);
  const [settings] = useContext(SiteSettingsContext);
  const { mobileAppBanner: mobileAppBannerConfig } = settings;
  const { showMobileAppBanner, showOn: showOnPlatforms, slides = [] } =
    mobileAppBannerConfig || {};

  const filterSegments = useUserSegmentsIncludes();
  const segmentedSlide = useMemo(() => {
    const filtered = slides.filter((slide) =>
      filterSegments(slide.userSegments)
    );
    return filtered[0] || {};
  }, [slides, filterSegments]);

  const { showOn: showOnPages, pageUrls = [] } = segmentedSlide;
  let isPathPassed;
  if (showOnPages === 'allPages') {
    isPathPassed = true;
  } else {
    const isPathMatched = pipe(
      map(prop('url')),
      any((url) =>
        matchPath(
          {
            path: url,
            end: true
          },
          pathname
        )
      )
    )(pageUrls);

    isPathPassed =
      showOnPages === 'allPagesExcept' ? !isPathMatched : isPathMatched;
  }

  const isPlatformPassed =
    showOnPlatforms == null ||
    showOnPlatforms === 'all' ||
    (showOnPlatforms === 'iOS' && isIOS) ||
    (showOnPlatforms === 'notIOS' && !isIOS);

  return {
    slide: segmentedSlide,
    isShowMobileAppBanner:
      showMobileAppBanner &&
      isPathPassed &&
      isPlatformPassed &&
      !isMobileAppBannerDisabled
  };
};

export default useMobileAppBanner;
