import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Box } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { useConfig } from '@namespace/cms';
import { HTMLSnippet } from '@namespace/snippets';
import { LOG_ACTIONS, logAmplitude } from '@namespace/analytics';
import {
  getSessionStorageValue,
  removeSessionStorageItem,
  SESSION_STORAGE_KEYS
} from '@namespace/helpers';
import { useGoPrevPageBeforeDep } from '../../hooks/useGoPrevPageBeforeDep';
import styles from './index.module.css';

const SuccessfulDeposit = ({ id }) => {
  const { config } = useConfig(id);
  const { snippetId } = config;

  useEffect(() => {
    const depositData = getSessionStorageValue(
      SESSION_STORAGE_KEYS.DEPOSIT_DATA
    );

    if (!isEmpty(depositData)) {
      const { userId, serviceId, amount } = depositData;

      logAmplitude(LOG_ACTIONS.DEPOSIT_SUCCESS_PAGE_OPEN, {
        service_id: serviceId,
        user_id: userId,
        amount
      });
      removeSessionStorageItem(SESSION_STORAGE_KEYS.DEPOSIT_DATA);
    }
  }, []);

  const onClose = useGoPrevPageBeforeDep();

  return (
    <Box direction="column">
      {snippetId && <HTMLSnippet snippetId={snippetId} />}
      <Box onClick={onClose} className={styles.close}>
        <Icon size="m" color="bg_color_4" name="icons/general/action/close" />
      </Box>
    </Box>
  );
};

export default SuccessfulDeposit;
