import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, AText } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { useGetPaymentService } from '../../../store/selectors/useGetPaymentService';
import { SWPaymentsContext } from '../../../store/context';
import { TAX_BY_PAYMENT_TYPE } from '../../../constants';

export const TaxWithCurrency = ({ classNames = {} }) => {
  const { t } = useI18n();
  const [{ activePaymentType }] = useContext(SWPaymentsContext);
  const {
    [TAX_BY_PAYMENT_TYPE[activePaymentType]]: taxByTypeConfig = {},
    tax: commonTax = {},
    currency,
    limits = {}
  } = useGetPaymentService();
  const { min, max } = limits;
  const { tax: taxByType } = taxByTypeConfig;
  const { amount: taxAmount = '' } = taxByType || commonTax;
  const { getValues } = useFormContext();
  const { amount } = getValues();

  const taxAmountValue = (
    (taxAmount.replace(/[%]/g, '') / 100) *
    amount
  ).toFixed(2);
  const feeAmount = amount >= min && amount <= max ? taxAmountValue : '0.00';
  const totalAmount =
    amount >= min && amount <= max
      ? Number((1 - taxAmount.replace(/[%]/g, '') / 100) * amount)?.toFixed(2)
      : '0.00';

  const { wrapperClassName } = classNames;

  if (!amount) {
    return null;
  }

  return (
    <Box className={wrapperClassName} direction="column">
      <AText breed="R" size="s" color="po_txt_color_3">
        {t(
          'personalOffice.balance.sw.forms.feeSW',
          taxAmount === '0%' ? ['0.00', currency] : [feeAmount, currency]
        )}
      </AText>
      <AText breed="R" size="s" color="po_txt_color_3">
        {t('personalOffice.balance.sw.withdraw.tax.totalAmount', [
          totalAmount,
          currency
        ])}
      </AText>
    </Box>
  );
};
