import { useShowQuickDeposit } from '@namespace/user';
import { Button, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import styles from './index.module.css';

export const QuickDepositButton = ({ className = '', ...rest }) => {
  const { t } = useI18n();
  const { isShowQuickDeposit, toggleQuickDeposit } = useShowQuickDeposit();

  const buttonProps = {
    onClick: toggleQuickDeposit,
    component: 'button',
    size: 's',
    actionType: isShowQuickDeposit ? 'color4' : 'color1',
    intent: 'primary',
    className: `${styles.depositButton} ${className}`,
    ...rest
  };

  return (
    <Box data-role="c-quickDepositButton" className={`${styles.buttonWrapper}`}>
      <Button {...buttonProps}>
        {isShowQuickDeposit
          ? t('personalOffice.close')
          : t('personalOffice.deposit')}
      </Button>
    </Box>
  );
};
