import SocketClient from './SocketClient';
import { MAX_RECONNECT_ATTEMPTS } from '../constants';

const socketInstance = ({ socketUrl, token = null, initMessages = [] }) => {
  console.log(
    '༼ つ ◕_◕ ༽つ creating socket instance with init-messages:',
    initMessages
  );
  const socket = new SocketClient({
    url: token ? `${socketUrl}?token=${token}` : socketUrl,
    maxReconnectionAttempts: MAX_RECONNECT_ATTEMPTS,
    messages: initMessages
  });

  return [
    socket.send,
    socket.subscribe,
    socket.unsubscribe,
    socket.rawSend,
    socket
  ];
};

export default socketInstance;
