import { useEffect, useRef } from 'react';
import { isFunction } from 'lodash';

function useCombinedRefs(...refs) {
  const targetRef = useRef();

  useEffect(() => {
    for (const ref of refs) {
      if (!ref) continue;

      if (isFunction(ref)) {
        ref(targetRef.current);
      } else {
        Object.assign(ref, {
          current: targetRef.current
        });
      }
    }
  }, [refs]);

  return targetRef;
}

export default useCombinedRefs;
