import { useEffect, useContext, useState } from 'react';
import { isEmpty } from 'lodash';
import { DeviceContext } from '@namespace/device';
import { useI18n, useLocation } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { Box } from '@alf/uikit';
import { useGeneralRoutes } from '@namespace/cms';
import { PrivateContentLoader } from '@namespace/user';
import useShopItems from '../../hooks/useShopItems';
import OrderItems from './OrderItems';
import ShopOrderForm from './ShopOrderForm';
import GoBackBar from '../../common/GoBackBar';
import TotalPrice from '../../common/TotalPrice';
import styles from './index.module.css';

const ShopOrder = () => {
  const {
    products,
    selectedProducts = {},
    totalAmount,
    createOrder,
    handleToBasket,
    handleRemoveItem,
    handleDropItemStack,
    clearBasket,
    handleGetCities,
    handleGetWarehouse
  } = useShopItems();

  const { shopOrder } = useGeneralRoutes();
  const { t } = useI18n();
  const { goBack } = useHistory();
  const { pathname } = useLocation();
  const [preOrderItems, setPreOrderItems] = useState([]);
  const { isDesktop } = useContext(DeviceContext);
  const top = isDesktop && { top: 'l' };
  const direction = isDesktop ? 'row-reverse' : 'column';
  const align = isDesktop ? 'start' : 'center';

  useEffect(() => {
    if (!isEmpty(selectedProducts)) {
      const toOrder = products.filter(({ id }) => selectedProducts[id]) || [];
      setPreOrderItems(toOrder);
    } else {
      setPreOrderItems([]);
      if (pathname === `${shopOrder}/`) {
        goBack();
      }
    }
  }, [selectedProducts, products, pathname, shopOrder, goBack]);

  return (
    <div className={styles.preOrderContainer}>
      <GoBackBar text={t('bonusShop.goBack.titlePlaceAnOrder')} />
      <Box direction={direction} justify="center" align={align}>
        <Box direction="column" flex="1" className={styles.orderWrap}>
          <Box direction="column" {...top}>
            {preOrderItems.map((item) => {
              const { id, title } = item;

              return (
                <OrderItems
                  handleToBasket={handleToBasket}
                  handleRemoveItem={handleRemoveItem}
                  handleDropItemStack={handleDropItemStack}
                  inOrder={selectedProducts[id]}
                  key={`${id}_${title}`}
                  t={t}
                  {...item}
                />
              );
            })}
          </Box>
          <TotalPrice
            totalAmount={totalAmount}
            className={styles.textWrapper}
          />
        </Box>
        <Box
          className={styles.formWrap}
          justify="center"
          flex="0.5"
          top="ms"
          bottom="ms"
        >
          <ShopOrderForm
            createOrder={createOrder}
            clearBasket={clearBasket}
            totalAmount={totalAmount}
            handleGetCities={handleGetCities}
            handleGetWarehouse={handleGetWarehouse}
          />
        </Box>
      </Box>
    </div>
  );
};

const ShopOrderWrapper = () => {
  return (
    <PrivateContentLoader>
      <ShopOrder />
    </PrivateContentLoader>
  );
};

export default ShopOrderWrapper;
