import { useCallback, useContext } from 'react';
import { LOG_ACTIONS, logAction } from '@namespace/analytics';
import { useLocation } from '@namespace/i18n';
import { useStatuses } from '@namespace/account';
import { useGeneralRoutes } from '@namespace/cms';
import { useRouteParams, useSearchParams, useHistory } from '@namespace/router';
import {
  DEMO_MODE,
  REAL_MODE,
  LAUNCHED_FROM_INHOUSE_JACKPOT
} from '../../../../constants';
import { useGetGameURL } from '../../../../hooks';
import { JackpotsContext } from '../../../../jackpotsContext';

export const useGetInHouseJpGameLaunchHandler = ({
  isLaunchFromGamePage = false
} = {}) => {
  const getGameURL = useGetGameURL();
  const { games: gamesRoute } = useGeneralRoutes();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { isPassportVerifiedByRisk } = useStatuses();
  const { service } = useRouteParams(`/:service/*`);
  const [{ games }] = useContext(JackpotsContext);
  const { playMode } = useSearchParams();

  const launchGame = useCallback(
    (mode, gameId, providerId) => {
      const launchURL = getGameURL({
        gameCode: gameId,
        gameProvider: providerId,
        playMode: mode,
        serviceURL: gamesRoute,
        type: 'launch',
        backTo: `${pathname}${playMode ? `?playMode=${playMode}` : ''}`
      });

      const querySymbol = launchURL.split('?')[1] ? '&' : '?';
      const launchURLWithParameter = `${launchURL}${querySymbol}launchInfo=${LAUNCHED_FROM_INHOUSE_JACKPOT}`;

      push(launchURLWithParameter);
    },
    [getGameURL, gamesRoute, pathname, playMode, push]
  );

  const onLaunchGame = ({ gameId, providerId, launchGameId }) => {
    logAction(LOG_ACTIONS.CLICK_GAME_NAME_ON_INHOUSE_JACKPOT, {
      pageRoot: service,
      pageType: isLaunchFromGamePage ? 'gamePage' : 'lobby'
    });
    const isGameDemoAvailable = games?.[launchGameId]?.hasDemoSupport;

    if (isPassportVerifiedByRisk || !isGameDemoAvailable) {
      launchGame(REAL_MODE, gameId, providerId);
    } else {
      launchGame(DEMO_MODE, gameId, providerId);
    }
  };

  return onLaunchGame;
};
