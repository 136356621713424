import { useEffect } from 'react';
import { useOtherSettings } from '@namespace/cms';
import { window } from '@namespace/helpers';

const useAddUserIdToWindow = (userId, isLoggedIn) => {
  const { isWindowUserIdDisabled = false } = useOtherSettings();

  useEffect(() => {
    if (userId && !isWindowUserIdDisabled) {
      window.user_id = userId;
      window.user_auth = isLoggedIn;
    }
  }, [userId, isLoggedIn, isWindowUserIdDisabled]);
};

export default useAddUserIdToWindow;
