import { useCallback, useEffect, useState } from 'react';
import { intersection, isEmpty } from 'lodash';
import { useOtherSettings } from '@namespace/cms';
import {
  useGamesState,
  getGames,
  useIsFeedLoaded,
  useGamesFeed,
  useGetRecommendedGamesIds,
  useGetRecentlyPlayedGamesIds,
  useGetWagerSlotsGamesIds,
  useGetLiveRtpGamesSectionsIds
} from '../../context/selectors';
import {
  FAVORITE_CATEGORY,
  RECOMMENDED_CATEGORY,
  RECENTLY_PLAYED_CATEGORY
} from '../../constants';
import { useCasinoSettings } from '../useCasinoSettings';
import { useGetFeedName } from '../useGetFeedName';

// TODO: Add test
export const useGetGamesByFilter = ({
  selectedCategory,
  selectedProvider,
  casinoType
}) => {
  const [filteredGames, setFilteredGames] = useState([]);
  const [filteredMultiSectionGames, setFilteredMultiSectionGames] = useState(
    []
  );
  const [isFiltered, setIsFiltered] = useState(false);
  const feedName = useGetFeedName(casinoType);
  const gamesFeed = useGamesFeed(feedName);
  const {
    favoritesRequestEntityName,
    suggesterServiceEntityName
  } = useCasinoSettings(casinoType);
  const {
    categories,
    gamesIds: feedGamesIds,
    providersGames: feedProvidersGames
  } = gamesFeed || {};
  const isFeedLoaded = useIsFeedLoaded(feedName);
  const { casinoDynamicCategoriesIds = {} } = useOtherSettings();
  const { games, providers, favorites } = useGamesState();

  const recommendedGamesIds = useGetRecommendedGamesIds(
    suggesterServiceEntityName,
    feedName
  );
  const recentlyPlayedGamesIds = useGetRecentlyPlayedGamesIds(
    suggesterServiceEntityName,
    feedName
  );
  const wagerSlotGamesIds = useGetWagerSlotsGamesIds(feedName);
  const liveRtpGamesSectionsIds = useGetLiveRtpGamesSectionsIds({
    feedName
  });

  const {
    wagerSlots: wagerSlotsCategoryId,
    liveRtp: liveRtpCategoryId
  } = casinoDynamicCategoriesIds;

  const isDynamicCategory =
    (selectedCategory === wagerSlotsCategoryId &&
      !isEmpty(wagerSlotGamesIds)) ||
    selectedCategory === liveRtpCategoryId;

  const isNonFeedCategory =
    selectedCategory === FAVORITE_CATEGORY.id ||
    selectedCategory === RECOMMENDED_CATEGORY.id ||
    selectedCategory === RECENTLY_PLAYED_CATEGORY.id ||
    isDynamicCategory;

  const isMultiSectionCategory = selectedCategory === liveRtpCategoryId;
  const isMultiSectionCategoryLoaded =
    selectedCategory === liveRtpCategoryId && !isEmpty(liveRtpGamesSectionsIds);

  const handleNonFeedCategory = useCallback(
    (category) => {
      const dynamicCategoriesMap = {
        ...(wagerSlotsCategoryId && {
          [wagerSlotsCategoryId]: wagerSlotGamesIds
        })
      };
      const customCategoriesMap = {
        recommended: recommendedGamesIds,
        favorite:
          intersection(
            favorites[favoritesRequestEntityName]?.ids,
            feedGamesIds
          ) || [],
        recentlyPlayed: recentlyPlayedGamesIds,
        ...dynamicCategoriesMap
      };
      const dynamicMultiSectionCategoriesMap = {
        ...(liveRtpCategoryId && {
          [liveRtpCategoryId]: liveRtpGamesSectionsIds
        })
      };

      return isMultiSectionCategory
        ? dynamicMultiSectionCategoriesMap[category].map((categorySection) => ({
            ...categorySection,
            games: getGames(categorySection.gamesIds, games.data)
          }))
        : getGames(customCategoriesMap[category], games.data);
    },
    [
      wagerSlotsCategoryId,
      wagerSlotGamesIds,
      recommendedGamesIds,
      favorites,
      favoritesRequestEntityName,
      feedGamesIds,
      recentlyPlayedGamesIds,
      liveRtpCategoryId,
      liveRtpGamesSectionsIds,
      isMultiSectionCategory,
      games.data
    ]
  );

  const getAllGames = useCallback(
    (gameIds) => {
      return getGames(gameIds || [], games.data);
    },
    [games.data]
  );

  // filter by category
  useEffect(() => {
    if (!selectedCategory || !isFeedLoaded) return;
    if (isMultiSectionCategory && !isMultiSectionCategoryLoaded) return;

    if (isNonFeedCategory) {
      const filteredNonFeedGames = handleNonFeedCategory(selectedCategory);

      if (isMultiSectionCategory) {
        setFilteredGames([]);
        setFilteredMultiSectionGames(filteredNonFeedGames);
      } else {
        setFilteredMultiSectionGames([]);
        setFilteredGames(filteredNonFeedGames);
      }

      setIsFiltered(true);

      return;
    }

    setFilteredMultiSectionGames([]);
    setFilteredGames(getAllGames(categories?.data?.[selectedCategory]?.games));
    setIsFiltered(true);
  }, [
    selectedCategory,
    handleNonFeedCategory,
    categories,
    isNonFeedCategory,
    isFeedLoaded,
    getAllGames,
    isMultiSectionCategory,
    isMultiSectionCategoryLoaded
  ]);

  // filter by provider
  useEffect(() => {
    if (selectedProvider && isFeedLoaded) {
      setFilteredGames(getAllGames(feedProvidersGames?.[selectedProvider]));
      setIsFiltered(true);
    }
  }, [
    selectedProvider,
    getAllGames,
    providers,
    isFeedLoaded,
    feedProvidersGames
  ]);

  return { filteredGames, filteredMultiSectionGames, isFiltered };
};
