if (process.env.REACT_APP_ENV === 'web') {
  // eslint-disable-next-line no-restricted-globals
  module.exports = window.fetch;
} else {
  // eslint-disable-next-line global-require
  const nodeFetch = require('node-fetch').default;
  module.exports = (url, options = {}, logger) => {
    const controller = new AbortController();
    const rejectTimeout = process.env.TIMEOUT_FOR_REJECT_REQUEST || 10000;
    const timeout = setTimeout(() => {
      logger?.error(
        `Request ${url} rejected, timed out in ${rejectTimeout} ms`
      );
      controller.abort();
    }, rejectTimeout);

    const path = `${process.env.SSR_PROXY_TARGET}${url}`;
    logger?.debug(`Loading information by url: "${path}"`);

    return nodeFetch(path, { ...options, signal: controller.signal }).then(
      (response) => {
        clearTimeout(timeout);
        return response;
      }
    );
  };
}
