import { useMemo, useContext, memo } from 'react';
import { DeviceContext } from '@namespace/device';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { Box, Select } from '@alf/uikit';
import { COLOR_SCHEME } from '@namespace/themes';
import { formatSelectOptions } from '../../utils';
import styles from './index.module.css';

export const Sorter = memo(
  ({ activeHeadGroups, onSelect, selectedGroups, categoryName, sportName }) => {
    const swap = (idx1, idx2, list) => {
      const newList = [...list];
      newList[idx1] = list[idx2];
      newList[idx2] = list[idx1];
      return newList;
    };

    const onHeadGroupSelect = (idx) => (item) => {
      const newItem = Number(item.value);
      const newIndex = activeHeadGroups.findIndex(
        ({ marketTemplateId }) => marketTemplateId === newItem
      );
      if (newIndex === -1) {
        const selectedGroupIdx = selectedGroups.findIndex(
          ({ marketTemplateId }) => marketTemplateId === newItem
        );
        const newActiveGroups = [
          ...activeHeadGroups,
          selectedGroups[selectedGroupIdx]
        ];
        const swappedGroups = swap(
          idx,
          newActiveGroups.length - 1,
          newActiveGroups
        );
        onSelect(
          swappedGroups.filter(
            ({ marketTemplateId }) =>
              marketTemplateId !== activeHeadGroups[idx].marketTemplateId
          )
        );
      } else if (newIndex !== idx) {
        logAction(LOG_ACTIONS.SPORTS_HM_CHANGE, {
          SportName: sportName,
          CategoryName: categoryName,
          MarketName: selectedGroups[idx]?.marketTemplateName
        });
        onSelect(swap(idx, newIndex, activeHeadGroups));
      }
    };
    const options = useMemo(
      () =>
        selectedGroups.length
          ? selectedGroups.map(({ marketTemplateId, marketTemplateName }) => ({
              value: marketTemplateId,
              name: marketTemplateName
            }))
          : [],
      [selectedGroups]
    );
    const { isDesktop } = useContext(DeviceContext);
    const formattedOptions = useMemo(() => formatSelectOptions(options), [
      options
    ]);
    const getCurrentValue = (currentId) => currentId || options[0];

    return (
      <Box onClick={(e) => e.stopPropagation()}>
        {activeHeadGroups.map((item, idx) => (
          <Select
            key={`headGroupSorter-${item.marketTemplateId}`}
            dataRole={`headGroupSorter-${item.marketTemplateId}`}
            options={formattedOptions}
            value={getCurrentValue(item.marketTemplateId)}
            onChange={onHeadGroupSelect(idx)}
            isDesktop={isDesktop}
            className={styles.customSelect}
            intentType={COLOR_SCHEME.SECONDARY}
          />
        ))}
      </Box>
    );
  }
);
