import { Image } from '@namespace/icons2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import AText from '../../../AText';
import Box from '../../../Box';
import styles from './index.module.css';

const Empty = ({ image, text, infoText, className = '', classNames = {} }) => (
  <Box
    direction="column"
    align="center"
    justify="center"
    className={clsx([styles.wrapper, className])}
  >
    {image || (
      <Image name="general/looking_for" className={styles.image} alt="" />
    )}
    <AText
      className={clsx([styles.text, classNames.text])}
      breed="BU"
      align="center"
    >
      {text}
    </AText>
    {infoText && (
      <Box margin="s 0 0 0">
        <AText
          className={clsx([styles.infoText, classNames.infoText])}
          breed="R"
          align="center"
        >
          {infoText}
        </AText>
      </Box>
    )}
  </Box>
);

Empty.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string
};

export default Empty;
