import { useI18n } from '@namespace/i18n';
import {
  BUILT_IN_TAX_RATE_TYPES,
  BUILT_IN_TAX_RATES
} from '../../../../paymentSystem/components/constants';
import styles from '../../../index.module.css';

const UATax = ({ taxes, returnAmount }) => {
  const { t } = useI18n();

  return (
    <ul className={styles.taxes}>
      {taxes &&
        BUILT_IN_TAX_RATES[BUILT_IN_TAX_RATE_TYPES.UA].map(({ key }, i) => (
          <li key={key} className={styles.taxesItem}>
            {`${t(key)}: ${taxes[i]}`}
          </li>
        ))}
      <li className={styles.taxesItem}>
        {`${t(
          'personalOffice.balance.withdraw.tax.withdrawAmount'
        )}: ${returnAmount}`}
      </li>
    </ul>
  );
};

export default UATax;
