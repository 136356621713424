/* eslint-disable camelcase */
import IndexCounterGeneral from '../../../icons/index/counter-general.svg';
import UserMenu_profile_desktop from '../../../icons/user_menu/profile_desktop.svg';
import UserMenu_profile_mobile from '../../../icons/user_menu/profile_mobile.svg';
import UserMenu_profile_VIP_desktop from '../../../icons/user_menu/profile_VIP_desktop.svg';
import UserMenu_Balance from '../../../icons/user_menu/balance.svg';
import UserMenu_Bonuses from '../../../icons/user_menu/bonuses.svg';
import UserMenu_Bets from '../../../icons/user_menu/bets.svg';
import UserMenu_Club from '../../../icons/user_menu/club.svg';
import UserMenu_ExitIc from '../../../icons/user_menu/exit_ic.svg';
import UserMenu_Messages from '../../../icons/user_menu/messages.svg';
import UserMenu_Responsible from '../../../icons/user_menu/responsible.svg';
import UserMenu_Support from '../../../icons/user_menu/support.svg';
import UserMenu_Support_New from '../../../icons/user_menu/support_new.svg';
import UserMenu_Support_24 from '../../../icons/user_menu/support_24.svg';
import UserMenu_Information from '../../../icons/user_menu/information.svg';
import UserMenu_Footer_Mail from '../../../icons/user_menu/footer/mail.svg';
import UserMenu_Footer_Chat from '../../../icons/user_menu/footer/chat.svg';
import UserMenu_Footer_Support from '../../../icons/user_menu/footer/support.svg';
import UserMenu_Footer_Viber from '../../../icons/user_menu/footer/viber.svg';
import UserMenu_Footer_Telegram from '../../../icons/user_menu/footer/telegram.svg';
import GeneralStateLoader from '../../../icons/general/state/loader.svg';
import GeneralStateFavoriteOn from '../../../icons/general/state/favorite-on.svg';
import GeneralStateFavoriteOff from '../../../icons/general/state/favorite-off.svg';
import GeneralStateVideo from '../../../icons/general/state/video.svg';
import GeneralStateVideoFree from '../../../icons/general/state/video-free.svg';
import GeneralStateHide from '../../../icons/general/state/hide.svg';
import GeneralStateInProgress from '../../../icons/general/state/in-progress.svg';
import GeneralStateCashout from '../../../icons/general/state/cashout.svg';
import GeneralStateShow from '../../../icons/general/state/show.svg';
import GeneralStateCheckmark from '../../../icons/general/state/checkmark.svg';
import GeneralStatePushpinOff from '../../../icons/general/state/pushpin-off.svg';
import GeneralStatePushpinOn from '../../../icons/general/state/pushpin-on.svg';
import GeneralStateWillEndIn from '../../../icons/general/state/will-end-in.svg';
import GeneralStateWillBegin from '../../../icons/general/state/will-begin.svg';
import NotVerified from '../../../icons/general/state/notverified.svg';
import Verified from '../../../icons/general/state/verified.svg';
import Success from '../../../icons/general/state/success.svg';
import Canceled from '../../../icons/general/state/canceled.svg';
import Return from '../../../icons/general/state/return.svg';
import GeneralStateBeginner from '../../../icons/general/state/beginner.svg';
import GeneralStateAmateur from '../../../icons/general/state/amateur.svg';
import GeneralStateMaster from '../../../icons/general/state/master.svg';
import GeneralStateVip from '../../../icons/general/state/vip.svg';
import Settings from '../../../icons/general/state/settings.svg';
import lineButtons from '../../../icons/general/state/line-buttons.svg';
import lineTables from '../../../icons/general/state/line-tables.svg';
import StakeSum from '../../../icons/general/state/stake-sum.svg';
import StakeSum1 from '../../../icons/general/state/stake-sum1.svg';
import InProcess from '../../../icons/general/state/in-process.svg';
import Seats4 from '../../../icons/general/state/seats4.svg';
import CasinoHold from '../../../icons/general/state/casino-hold.svg';
import QuantityOfPlayers from '../../../icons/general/state/quantity-of-players.svg';
import InfoTwo from '../../../icons/general/state/info2.svg';
import Calendar from '../../../icons/general/item/calendar.svg';
import Corona from '../../../icons/general/item/corona.svg';
import Timer from '../../../icons/general/item/timer.svg';
import Clock from '../../../icons/general/item/clock.svg';
import ClockBets from '../../../icons/general/item/clock-bets.svg';
import Android from '../../../icons/user_menu/footer/android.svg';
import AppStore from '../../../icons/user_menu/footer/app-store.svg';
import Filter from '../../../icons/general/item/filter.svg';
import GeneralNavArrowUp from '../../../icons/general/nav/arrow-up.svg';
import GeneralNavArrowDown from '../../../icons/general/nav/arrow-down.svg';
import GeneralNavBack from '../../../icons/general/nav/back.svg';
import GeneralNavGoTo from '../../../icons/general/nav/go-to.svg';
import GeneralNavDropDownDown from '../../../icons/general/nav/dropdown-down.svg';
import GeneralNavDropDownUp from '../../../icons/general/nav/dropdown-up.svg';
import GeneralNavGoToCircle from '../../../icons/general/nav/go-to-circle.svg';
import GeneralNavExpand from '../../../icons/general/nav/expand.svg';
import GeneralNavCollapse from '../../../icons/general/nav/collapse.svg';
import GeneralNavNext from '../../../icons/general/nav/next.svg';
import GeneralNavDropdownDown from '../../../icons/general/nav/dropdown_down.svg';
import GeneralNavArrowChecked from '../../../icons/general/nav/arrow-checked.svg';
import GeneralNavNeedHelp from '../../../icons/general/nav/need_help.svg';
import GeneralNavLiveCalendar from '../../../icons/general/nav/calendar.svg';
import GeneralNavLocation from '../../../icons/general/nav/location.svg';
import GeneralNavIcNext from '../../../icons/general/nav/ic_next.svg';
import GeneralNavUp from '../../../icons/general/nav/up.svg';
import GeneralNavDown from '../../../icons/general/nav/down.svg';
import GeneralActionDelete from '../../../icons/general/action/delete.svg';
import GeneralActionMoveTo from '../../../icons/general/action/move_to.svg';
import GeneralActionClose from '../../../icons/general/action/close.svg';
import GeneralActionRefresh from '../../../icons/general/action/refresh.svg';
import GeneralActionSearch from '../../../icons/general/action/search.svg';
import GeneralActionEdit from '../../../icons/general/action/edit.svg';
import GeneralActionCreate from '../../../icons/general/action/create.svg';
import GeneralActionMinus from '../../../icons/general/action/minus.svg';
import GeneralActionMakePhoto from '../../../icons/general/action/photo.svg';
import GeneralActionPlus from '../../../icons/general/action/plus.svg';
import GeneralActionAttachFile from '../../../icons/general/action/attach_file.svg';
import GeneralActionShare from '../../../icons/general/action/share.svg';
import GeneralActionLiveChat from '../../../icons/general/action/live_chat.svg';
import GeneralAlertError from '../../../icons/general/alert/error.svg';
import GeneralAlertInfo from '../../../icons/general/alert/info.svg';
import GeneralAlertSuccess from '../../../icons/general/alert/success.svg';
import EventStatistics from '../../../icons/event/statistics.svg';
import EventNeutral from '../../../icons/event/neutral.svg';
import EventCorner from '../../../icons/event/corner.svg';
import EventYellowCard from '../../../icons/event/yellow-card.svg';
import EventRedCard from '../../../icons/event/red-card.svg';
import EventScoreboard from '../../../icons/event/scoreboard.svg';
import SportDefault from '../../../icons/sport/default.svg';
import SportSoccer from '../../../icons/sport/id-1.svg';
import SportTennis from '../../../icons/sport/id-2.svg';
import SportGolf from '../../../icons/sport/id-3.svg';
import SportAthletics from '../../../icons/sport/id-4.svg';
import SportHockeyOld from '../../../icons/sport/id-5.svg';
import SportAlpineSkiing from '../../../icons/sport/id-6.svg';
import SportBiathlon from '../../../icons/sport/id-7.svg';
import SportBobsleigh from '../../../icons/sport/id-8.svg';
import SportCrossCountrySkiing from '../../../icons/sport/id-9.svg';
import SportCurling from '../../../icons/sport/id-10.svg';
import SportFigureSkating from '../../../icons/sport/id-11.svg';
import SportFreestyleSkiing from '../../../icons/sport/id-12.svg';
import SportLuge from '../../../icons/sport/id-13.svg';
import SportNordicCombined from '../../../icons/sport/id-14.svg';
import SportShortTrackSpeedSkating from '../../../icons/sport/id-15.svg';
import SportSkeleton from '../../../icons/sport/id-16.svg';
import SportSkiJumping from '../../../icons/sport/id-17.svg';
import SportSnowboarding from '../../../icons/sport/id-18.svg';
import SportSpeedSkating from '../../../icons/sport/id-19.svg';
import SportHandball from '../../../icons/sport/id-20.svg';
import SportOutRights from '../../../icons/sport/id-21.svg';
import SportMotoSports from '../../../icons/sport/id-22.svg';
import SportBasketball from '../../../icons/sport/id-23.svg';
import SportAmericanFootball from '../../../icons/sport/id-24.svg';
import SportBandy from '../../../icons/sport/id-25.svg';
import SportBaseball from '../../../icons/sport/id-26.svg';
import SportBeachVolleyball from '../../../icons/sport/id-27.svg';
import SportFloorBall from '../../../icons/sport/id-28.svg';
import SportRugbyUnion from '../../../icons/sport/id-29.svg';
import SportCycling from '../../../icons/sport/id-30.svg';
import SportHorseRacing from '../../../icons/sport/id-31.svg';
import SportArchery from '../../../icons/sport/id-32.svg';
import SportBadminton from '../../../icons/sport/id-33.svg';
import SportBoxing from '../../../icons/sport/id-34.svg';
import SportCanoeing from '../../../icons/sport/id-35.svg';
import SportDiving from '../../../icons/sport/id-36.svg';
import SportEquestrian from '../../../icons/sport/id-37.svg';
import SportFencing from '../../../icons/sport/id-38.svg';
import SportHockey from '../../../icons/sport/id-39.svg';
import SportHockeyStatistics from '../../../icons/sport/id-230.svg';
import SportGymnastics from '../../../icons/sport/id-40.svg';
import SportJudo from '../../../icons/sport/id-41.svg';
import SportModernPentathlon from '../../../icons/sport/id-42.svg';
import SportRowing from '../../../icons/sport/id-43.svg';
import SportSailing from '../../../icons/sport/id-44.svg';
import SportShooting from '../../../icons/sport/id-45.svg';
import SportSwimming from '../../../icons/sport/id-46.svg';
import SportSynchronisedSwimming from '../../../icons/sport/id-47.svg';
import SportTableTennis from '../../../icons/sport/id-48.svg';
import SportTaekwondo from '../../../icons/sport/id-49.svg';
import SportTriathlon from '../../../icons/sport/id-50.svg';
import SportVolleyball from '../../../icons/sport/id-51.svg';
import SportWaterPolo from '../../../icons/sport/id-52.svg';
import SportWeightLifting from '../../../icons/sport/id-53.svg';
import SportWrestling from '../../../icons/sport/id-54.svg';
import SportTrackCycling from '../../../icons/sport/id-55.svg';
import SportMountainBike from '../../../icons/sport/id-56.svg';
import SportSoftball from '../../../icons/sport/id-57.svg';
import SportBMX from '../../../icons/sport/id-58.svg';
import SportReferenceSport from '../../../icons/sport/id-59.svg';
import SportSnooker from '../../../icons/sport/id-60.svg';
import SportCricket from '../../../icons/sport/id-61.svg';
import SportAussieRules from '../../../icons/sport/id-62.svg';
import SportSurfing from '../../../icons/sport/id-63.svg';
import SportAutoSport from '../../../icons/sport/id-64.svg';
import SportDart from '../../../icons/sport/id-65.svg';
import SportVirtualSports from '../../../icons/sport/id-66.svg';
import SportBeachFootball from '../../../icons/sport/id-68.svg';
import SportFutsal from '../../../icons/sport/id-71.svg';
import SportFieldHockey from '../../../icons/sport/id-72.svg';
import SportFormula1 from '../../../icons/sport/id-73.svg';
import SportLottery from '../../../icons/sport/id-74.svg';
import SportOlympic from '../../../icons/sport/id-75.svg';
import SportChess from '../../../icons/sport/id-76.svg';
import SportPolitics from '../../../icons/sport/id-77.svg';
import SportIndoorSoccer from '../../../icons/sport/id-78.svg';
import SportRugbyLeague from '../../../icons/sport/id-79.svg';
import SportBowling from '../../../icons/sport/id-80.svg';
import SportBonus from '../../../icons/sport/id-81.svg';
import SportSpecials from '../../../icons/sport/id-84.svg';
import SportCyberSports from '../../../icons/sport/id-85.svg';
import SportInspired from '../../../icons/sport/id-86.svg';
import SportNetball from '../../../icons/sport/id-87.svg';
import SportMMA from '../../../icons/sport/id-89.svg';
import SportSepaktakraw from '../../../icons/sport/id-90.svg';
import SportSquash from '../../../icons/sport/id-91.svg';
import SportHurling from '../../../icons/sport/id-92.svg';
import SportLacrosse from '../../../icons/sport/id-93.svg';
import SportKorfball from '../../../icons/sport/id-95.svg';
import SportRally from '../../../icons/sport/id-96.svg';
import SportBilliard from '../../../icons/sport/id-99.svg';
import SportVirtualBox from '../../../icons/sport/id-100.svg';
import SportPoker from '../../../icons/sport/id-132.svg';
import SportInternationalLottery from '../../../icons/sport/id-165.svg';
import SportSoccerStatistics from '../../../icons/sport/id-167.svg';
import SportGridGoal from '../../../icons/sport/id-168.svg';
import SportSnowVolleyball from '../../../icons/sport/id-169.svg';
import SportVirtualTennisOpen from '../../../icons/sport/id-171.svg';
import SportVirtualBasketballLeague from '../../../icons/sport/id-172.svg';
import SportBasketballStatistics from '../../../icons/sport/id-173.svg';
import SportNascar from '../../../icons/sport/id-175.svg';
import SportSumo from '../../../icons/sport/id-176.svg';
import SportBetradarNationsCup from '../../../icons/sport/id-177.svg';
import SportTennisStatistics from '../../../icons/sport/id-178.svg';
import SportVermantia from '../../../icons/sport/id-179.svg';
import SportCrossfit from '../../../icons/sport/id-180.svg';
import SportBallHockey from '../../../icons/sport/id-181.svg';
import SportMiniFootball from '../../../icons/sport/id-182.svg';
import SportGaelicFootball from '../../../icons/sport/id-183.svg';
import SportEFootball from '../../../icons/sport/id-184.svg';
import SportEBasketball from '../../../icons/sport/id-185.svg';
import SportETennis from '../../../icons/sport/id-186.svg';
import SportPingPong from '../../../icons/sport/id-187.svg';
import SportDogRacing from '../../../icons/sport/id-188.svg';
import SportEFighting from '../../../icons/sport/id-191.svg';
import SportEHockey from '../../../icons/sport/id-192.svg';
import SportWorldOfTanks from '../../../icons/sport/id-193.svg';
import SportECricket from '../../../icons/sport/id-194.svg';
import SportFortnite from '../../../icons/sport/id-195.svg';
import SportEFutsal from '../../../icons/sport/id-196.svg';
import SportHeadis from '../../../icons/sport/id-197.svg';
import SportKabaddi from '../../../icons/sport/id-231.svg';
import SportShortFootball from '../../../icons/sport/id-233.svg';
import SportBasketball3x3 from '../../../icons/sport/id-232.svg';
import SportFavorites from '../../../icons/sport/id-999.svg';
import SportLeagueOfLegends from '../../../icons/sport/id-1536.svg';
import SportDota2 from '../../../icons/sport/id-1537.svg';
import SportStarCraft2 from '../../../icons/sport/id-1655.svg';
import SportCounterStrike from '../../../icons/sport/id-1800.svg';
import SportOverwatch from '../../../icons/sport/id-33870.svg';
import SportRocketLeague from '../../../icons/sport/id-36267.svg';
import SportWarcraft3 from '../../../icons/sport/id-36288.svg';
import SportCallOfDuty from '../../../icons/sport/id-36293.svg';
import SportCyberSportSummaries from '../../../icons/sport/id-36318.svg';
import SportVermantiaFootball from '../../../icons/sport/id-36335.svg';
import SportHorseRoulette from '../../../icons/sport/id-36338.svg';
import SportKingOfGlory from '../../../icons/sport/id-36459.svg';
import SportPUBG from '../../../icons/sport/id-36616.svg';
import SportCyberSportSpecials from '../../../icons/sport/id-36698.svg';
import SportRainbow6 from '../../../icons/sport/id-36765.svg';
import SportArenaOfValor from '../../../icons/sport/id-37014.svg';
import SportValorant from '../../../icons/sport/id-37387.svg';
import EventPenalty from '../../../icons/event/penalty.svg';
import GeneralItemList from '../../../icons/general/item/list.svg';
import OddLock from '../../../icons/odd/lock.svg';
import NavMenuGoToOld from '../../../icons/nav_menu/goToOld.svg';
import NavMenuResults from '../../../icons/nav_menu/results.svg';
import NavMenuBetGames from '../../../icons/nav_menu/betGames.svg';
import NavMenuCasino from '../../../icons/nav_menu/casino.svg';
import NavMenuCasinoLive from '../../../icons/nav_menu/casinoLive.svg';
import NavMenuInstantGames from '../../../icons/nav_menu/instantGames.svg';
import NavMenuCyberSport from '../../../icons/nav_menu/cybersport.svg';
import NavMenuLive from '../../../icons/nav_menu/live.svg';
import NavMenuLotteries from '../../../icons/nav_menu/lotteries.svg';
import NavMenuPromo from '../../../icons/nav_menu/promo.svg';
import NavMenuSport from '../../../icons/nav_menu/sports.svg';
import NavMenuEuro from '../../../icons/nav_menu/euro2020.svg';
import NavMenuTvBet from '../../../icons/nav_menu/tvBet.svg';
import NavMenuVirtuals from '../../../icons/nav_menu/virtuals.svg';
import NavMenuAviator from '../../../icons/nav_menu/aviator.svg';
import NavMenuKeno from '../../../icons/nav_menu/keno.svg';
import NavMenuMenu from '../../../icons/nav_menu/menu.svg';
import NavMenuGoldenRace from '../../../icons/nav_menu/goldenRace.svg';
import NavMenuEuro2 from '../../../icons/nav_menu/euro.svg';
import NavMenuPromos from '../../../icons/nav_menu/promos.svg';
import NavMenuNavMenu from '../../../icons/nav_menu/navMenu.svg';
import NavMenuMenuClose from '../../../icons/nav_menu/menuClose.svg';
import NavMenuQuiz from '../../../icons/nav_menu/quiz.svg';
import NavMenuPromotions from '../../../icons/nav_menu/promotions.svg';
import NavMenuBonuses from '../../../icons/nav_menu/bonuses.svg';
import NavMenuTournaments from '../../../icons/nav_menu/tournaments.svg';
import NavMenuTwainSport from '../../../icons/nav_menu/twainSport.svg';
import NavMenuBookOfSix from '../../../icons/nav_menu/book_of_six.svg';
import SettingsDefault from '../../../icons/general/state/settings-default.svg';
import OddDown from '../../../icons/odd/odd-down.svg';
import OddUp from '../../../icons/odd/odd-up.svg';
import BeslipDefault from '../../../icons/nav_menu/betslip_default.svg';
import UploadAction from '../../../icons/general/action/upload.svg';
import PredictionInfoBlockIconActive from '../../../icons/predictions/predictionsInfoBlockIconActive.svg';
import PredictionInfoBlockIconChecked from '../../../icons/predictions/predictionsInfoBlockIconChecked.svg';
import PredictionInfoBlockIconEmpty from '../../../icons/predictions/predictionsInfoBlockIconEmpty.svg';
import PredictionInfoBlockIconFailed from '../../../icons/predictions/predictionsInfoBlockIconFailed.svg';
import Basket from '../../../icons/general/action/basket.svg';
import Warning from '../../../icons/general/state/warning.svg';
import GeneralItemMen from '../../../icons/general/item/men.svg';
import GeneralItemWoman from '../../../icons/general/item/woman.svg';
import Microphone from '../../../icons/general/nav/microphone.svg';
import MicrophoneLoader from '../../../icons/general/nav/microphone_loader.svg';
import AssistantMicrophoneEnabled from '../../../icons/assistant/assistant_microphone_enabled.svg';
import AssistantMicrophoneDisabled from '../../../icons/assistant/assistant_microphone_disabled.svg';
import AssistantNav from '../../../icons/nav_menu/assistant.svg';
import GeneralActionDone from '../../../icons/general/action/done.svg';
import BaseballBases000 from '../../../icons/baseball-bases/000.svg';
import BaseballBases001 from '../../../icons/baseball-bases/001.svg';
import BaseballBases010 from '../../../icons/baseball-bases/010.svg';
import BaseballBases011 from '../../../icons/baseball-bases/011.svg';
import BaseballBases100 from '../../../icons/baseball-bases/100.svg';
import BaseballBases101 from '../../../icons/baseball-bases/101.svg';
import BaseballBases110 from '../../../icons/baseball-bases/110.svg';
import BaseballBases111 from '../../../icons/baseball-bases/111.svg';
import ColorSchemeDark from '../../../icons/general/color_scheme/dark.svg';
import ColorSchemeLight from '../../../icons/general/color_scheme/light.svg';
import ColorSchemeAuto from '../../../icons/general/color_scheme/auto.svg';
import CasinoItemJackpot from '../../../icons/casino/item/jackpot.svg';
import GeneralActionCopy from '../../../icons/general/action/copy.svg';
import GeneralActionCopyIcon from '../../../icons/general/action/ic_copy.svg';
import HelpSupportCircle from '../../../icons/help/supportCircle.svg';
import BonusSystemBackground from '../../../icons/bonuses/bonussystem-background.svg';
import BonusFundsBackground from '../../../icons/bonuses/funds-background.svg';
import BonusDefaultBackground from '../../../icons/bonuses/default-background.svg';
import BonusCashbackBackground from '../../../icons/bonuses/cashback-background.svg';
import BonusFreeSpinBackground from '../../../icons/bonuses/freespin-background.svg';
import Info from '../../../icons/general/state/info.svg';

export default {
  /* Index */
  'icons/index/counter-general': IndexCounterGeneral,
  /* User menu */
  'icons/user_menu/profile_desktop': UserMenu_profile_desktop,
  'icons/user_menu/profile_mobile': UserMenu_profile_mobile,
  'icons/user_menu/profile_VIP_desktop': UserMenu_profile_VIP_desktop,
  'icons/user_menu/balance': UserMenu_Balance,
  'icons/user_menu/bonuses': UserMenu_Bonuses,
  'icons/user_menu/bets': UserMenu_Bets,
  'icons/user_menu/club': UserMenu_Club,
  'icons/user_menu/exit_ic': UserMenu_ExitIc,
  'icons/user_menu/messages': UserMenu_Messages,
  'icons/user_menu/responsible': UserMenu_Responsible,
  'icons/user_menu/support': UserMenu_Support,
  'icons/user_menu/support_new': UserMenu_Support_New,
  'icons/user_menu/support_24': UserMenu_Support_24,
  'icons/user_menu/information': UserMenu_Information,
  'icons/user_menu/footer/mail': UserMenu_Footer_Mail,
  'icons/user_menu/footer/chart': UserMenu_Footer_Chat,
  'icons/user_menu/footer/support': UserMenu_Footer_Support,
  'icons/user_menu/footer/viber': UserMenu_Footer_Viber,
  'icons/user_menu/footer/telegram': UserMenu_Footer_Telegram,
  'icons/user_menu/footer/android': Android,
  'icons/user_menu/footer/app-store': AppStore,
  /* State */
  'icons/general/state/loader': GeneralStateLoader,
  'icons/general/state/favorite-on': GeneralStateFavoriteOn,
  'icons/general/state/favorite-off': GeneralStateFavoriteOff,
  'icons/general/state/video': GeneralStateVideo,
  'icons/general/state/video-free': GeneralStateVideoFree,
  'icons/general/state/settings-default': SettingsDefault,
  'icons/general/state/notverified': NotVerified,
  'icons/general/state/verified': Verified,
  'icons/general/state/in-process': InProcess,
  'icons/general/state/success': Success,
  'icons/general/state/canceled': Canceled,
  'icons/general/state/return': Return,
  'icons/general/state/checkmark': GeneralStateCheckmark,
  'icons/general/state/pushpin-on': GeneralStatePushpinOn,
  'icons/general/state/pushpin-off': GeneralStatePushpinOff,
  'icons/general/state/show': GeneralStateShow,
  'icons/general/state/hide': GeneralStateHide,
  'icons/general/state/in-progress': GeneralStateInProgress,
  'icons/general/state/cashout': GeneralStateCashout,
  'icons/general/state/beginner': GeneralStateBeginner,
  'icons/general/state/amateur': GeneralStateAmateur,
  'icons/general/state/master': GeneralStateMaster,
  'icons/general/state/vip': GeneralStateVip,
  'icons/general/state/warning': Warning,
  'icons/general/state/settings': Settings,
  'icons/general/state/line-buttons': lineButtons,
  'icons/general/state/line-tables': lineTables,
  'icons/general/state/stake-sum': StakeSum,
  'icons/general/state/stake-sum1': StakeSum1,
  'icons/general/state/seats4': Seats4,
  'icons/general/state/casino-hold': CasinoHold,
  'icons/general/state/quantity-of-players': QuantityOfPlayers,
  'icons/general/state/info': Info,
  'icons/general/state/info2': InfoTwo,
  'icons/general/state/will-end-in': GeneralStateWillEndIn,
  'icons/general/state/will-begin': GeneralStateWillBegin,
  'icons/general/color_scheme/dark': ColorSchemeDark,
  'icons/general/color_scheme/light': ColorSchemeLight,
  'icons/general/color_scheme/auto': ColorSchemeAuto,
  /* Nav */
  'icons/general/nav/arrow-up': GeneralNavArrowUp,
  'icons/general/nav/arrow-down': GeneralNavArrowDown,
  'icons/general/nav/dropdown-down': GeneralNavDropDownDown,
  'icons/general/nav/dropdown-up': GeneralNavDropDownUp,
  'icons/general/nav/expand': GeneralNavExpand,
  'icons/general/nav/collapse': GeneralNavCollapse,
  'icons/general/nav/back': GeneralNavBack,
  'icons/general/nav/next': GeneralNavNext,
  'icons/general/nav/ic_next': GeneralNavIcNext,
  'icons/general/nav/go-to': GeneralNavGoTo,
  'icons/general/nav/go-to-circle': GeneralNavGoToCircle,
  'icons/general/nav/dropdown_down': GeneralNavDropdownDown,
  'icons/general/nav/arrow-checked': GeneralNavArrowChecked,
  'icons/general/nav/needHelp': GeneralNavNeedHelp,
  'icons/general/nav/liveCalendar': GeneralNavLiveCalendar,
  'icons/general/nav/location': GeneralNavLocation,
  'icons/general/nav/up': GeneralNavUp,
  'icons/general/nav/down': GeneralNavDown,
  /* Action */
  'icons/general/action/close': GeneralActionClose,
  'icons/general/action/refresh': GeneralActionRefresh,
  'icons/general/action/search': GeneralActionSearch,
  'icons/general/action/delete': GeneralActionDelete,
  'icons/general/action/move_to': GeneralActionMoveTo,
  'icons/general/action/edit': GeneralActionEdit,
  'icons/general/action/create': GeneralActionCreate,
  'icons/general/action/basket': Basket,
  'icons/general/action/plus': GeneralActionPlus,
  'icons/general/action/minus': GeneralActionMinus,
  'icons/general/action/done': GeneralActionDone,
  'icons/general/action/copy': GeneralActionCopy,
  'icons/general/action/ic_copy': GeneralActionCopyIcon,
  'icons/general/action/attach_file': GeneralActionAttachFile,
  'icons/general/action/photo': GeneralActionMakePhoto,
  'icons/general/action/share': GeneralActionShare,
  'icons/general/action/live_chat': GeneralActionLiveChat,
  /* Item */
  'icons/general/item/calendar': Calendar,
  'icons/general/item/list': GeneralItemList,
  'icons/general/item/corona': Corona,
  'icons/general/item/men': GeneralItemMen,
  'icons/general/item/woman': GeneralItemWoman,
  'icons/general/item/timer': Timer,
  'icons/general/item/clock': Clock,
  'icons/general/item/clock-bets': ClockBets,
  'icons/general/item/filter': Filter,
  /* Alert */
  'icons/general/alert/error': GeneralAlertError,
  'icons/general/alert/success': GeneralAlertSuccess,
  'icons/general/alert/info': GeneralAlertInfo,
  /* Event */
  'icons/event/statistics': EventStatistics,
  'icons/event/neutral': EventNeutral,
  'icons/event/penalty': EventPenalty,
  'icons/event/corner': EventCorner,
  'icons/event/yellow-card': EventYellowCard,
  'icons/event/red-card': EventRedCard,
  'icons/event/scoreboard': EventScoreboard,
  /* Sport */
  'icons/sport/default': SportDefault,
  'icons/sport/id-1': SportSoccer,
  'icons/sport/id-2': SportTennis,
  'icons/sport/id-3': SportGolf,
  'icons/sport/id-4': SportAthletics,
  'icons/sport/id-5': SportHockeyOld,
  'icons/sport/id-6': SportAlpineSkiing,
  'icons/sport/id-7': SportBiathlon,
  'icons/sport/id-8': SportBobsleigh,
  'icons/sport/id-9': SportCrossCountrySkiing,
  'icons/sport/id-10': SportCurling,
  'icons/sport/id-11': SportFigureSkating,
  'icons/sport/id-12': SportFreestyleSkiing,
  'icons/sport/id-13': SportLuge,
  'icons/sport/id-14': SportNordicCombined,
  'icons/sport/id-15': SportShortTrackSpeedSkating,
  'icons/sport/id-16': SportSkeleton,
  'icons/sport/id-17': SportSkiJumping,
  'icons/sport/id-18': SportSnowboarding,
  'icons/sport/id-19': SportSpeedSkating,
  'icons/sport/id-20': SportHandball,
  'icons/sport/id-21': SportOutRights,
  'icons/sport/id-22': SportMotoSports,
  'icons/sport/id-23': SportBasketball,
  'icons/sport/id-24': SportAmericanFootball,
  'icons/sport/id-25': SportBandy,
  'icons/sport/id-26': SportBaseball,
  'icons/sport/id-27': SportBeachVolleyball,
  'icons/sport/id-28': SportFloorBall,
  'icons/sport/id-29': SportRugbyUnion,
  'icons/sport/id-30': SportCycling,
  'icons/sport/id-31': SportHorseRacing,
  'icons/sport/id-32': SportArchery,
  'icons/sport/id-33': SportBadminton,
  'icons/sport/id-34': SportBoxing,
  'icons/sport/id-35': SportCanoeing,
  'icons/sport/id-36': SportDiving,
  'icons/sport/id-37': SportEquestrian,
  'icons/sport/id-38': SportFencing,
  'icons/sport/id-39': SportHockey,
  'icons/sport/id-40': SportGymnastics,
  'icons/sport/id-41': SportJudo,
  'icons/sport/id-42': SportModernPentathlon,
  'icons/sport/id-43': SportRowing,
  'icons/sport/id-44': SportSailing,
  'icons/sport/id-45': SportShooting,
  'icons/sport/id-46': SportSwimming,
  'icons/sport/id-47': SportSynchronisedSwimming,
  'icons/sport/id-48': SportTableTennis,
  'icons/sport/id-49': SportTaekwondo,
  'icons/sport/id-50': SportTriathlon,
  'icons/sport/id-51': SportVolleyball,
  'icons/sport/id-52': SportWaterPolo,
  'icons/sport/id-53': SportWeightLifting,
  'icons/sport/id-54': SportWrestling,
  'icons/sport/id-55': SportTrackCycling,
  'icons/sport/id-56': SportMountainBike,
  'icons/sport/id-57': SportSoftball,
  'icons/sport/id-58': SportBMX,
  'icons/sport/id-59': SportReferenceSport,
  'icons/sport/id-60': SportSnooker,
  'icons/sport/id-61': SportCricket,
  'icons/sport/id-62': SportAussieRules,
  'icons/sport/id-63': SportSurfing,
  'icons/sport/id-64': SportAutoSport,
  'icons/sport/id-65': SportDart,
  'icons/sport/id-66': SportVirtualSports,
  'icons/sport/id-68': SportBeachFootball,
  'icons/sport/id-71': SportFutsal,
  'icons/sport/id-72': SportFieldHockey,
  'icons/sport/id-73': SportFormula1,
  'icons/sport/id-74': SportLottery,
  'icons/sport/id-75': SportOlympic,
  'icons/sport/id-76': SportChess,
  'icons/sport/id-77': SportPolitics,
  'icons/sport/id-78': SportIndoorSoccer,
  'icons/sport/id-79': SportRugbyLeague,
  'icons/sport/id-80': SportBowling,
  'icons/sport/id-81': SportBonus,
  'icons/sport/id-84': SportSpecials,
  'icons/sport/id-85': SportCyberSports,
  'icons/sport/id-86': SportInspired,
  'icons/sport/id-87': SportNetball,
  'icons/sport/id-89': SportMMA,
  'icons/sport/id-90': SportSepaktakraw,
  'icons/sport/id-91': SportSquash,
  'icons/sport/id-92': SportHurling,
  'icons/sport/id-93': SportLacrosse,
  'icons/sport/id-95': SportKorfball,
  'icons/sport/id-96': SportRally,
  'icons/sport/id-99': SportBilliard,
  'icons/sport/id-100': SportVirtualBox,
  'icons/sport/id-132': SportPoker,
  'icons/sport/id-165': SportInternationalLottery,
  'icons/sport/id-167': SportSoccerStatistics,
  'icons/sport/id-168': SportGridGoal,
  'icons/sport/id-169': SportSnowVolleyball,
  'icons/sport/id-171': SportVirtualTennisOpen,
  'icons/sport/id-172': SportVirtualBasketballLeague,
  'icons/sport/id-173': SportBasketballStatistics,
  'icons/sport/id-175': SportNascar,
  'icons/sport/id-176': SportSumo,
  'icons/sport/id-177': SportBetradarNationsCup,
  'icons/sport/id-178': SportTennisStatistics,
  'icons/sport/id-179': SportVermantia,
  'icons/sport/id-180': SportCrossfit,
  'icons/sport/id-181': SportBallHockey,
  'icons/sport/id-182': SportMiniFootball,
  'icons/sport/id-183': SportGaelicFootball,
  'icons/sport/id-184': SportEFootball,
  'icons/sport/id-185': SportEBasketball,
  'icons/sport/id-186': SportETennis,
  'icons/sport/id-187': SportPingPong,
  'icons/sport/id-188': SportDogRacing,
  'icons/sport/id-191': SportEFighting,
  'icons/sport/id-192': SportEHockey,
  'icons/sport/id-193': SportWorldOfTanks,
  'icons/sport/id-194': SportECricket,
  'icons/sport/id-195': SportFortnite,
  'icons/sport/id-196': SportEFutsal,
  'icons/sport/id-197': SportHeadis,
  'icons/sport/id-230': SportHockeyStatistics,
  'icons/sport/id-231': SportKabaddi,
  'icons/sport/id-232': SportBasketball3x3,
  'icons/sport/id-233': SportShortFootball,
  'icons/sport/id-999': SportFavorites,
  'icons/sport/id-1536': SportLeagueOfLegends,
  'icons/sport/id-1537': SportDota2,
  'icons/sport/id-1655': SportStarCraft2,
  'icons/sport/id-1800': SportCounterStrike,
  'icons/sport/id-33870': SportOverwatch,
  'icons/sport/id-36267': SportRocketLeague,
  'icons/sport/id-36288': SportWarcraft3,
  'icons/sport/id-36293': SportCallOfDuty,
  'icons/sport/id-36318': SportCyberSportSummaries,
  'icons/sport/id-36335': SportVermantiaFootball,
  'icons/sport/id-36338': SportHorseRoulette,
  'icons/sport/id-36459': SportKingOfGlory,
  'icons/sport/id-36616': SportPUBG,
  'icons/sport/id-36698': SportCyberSportSpecials,
  'icons/sport/id-36765': SportRainbow6,
  'icons/sport/id-37014': SportArenaOfValor,
  'icons/sport/id-37387': SportValorant,
  /* Odd */
  'icons/odd/lock': OddLock,
  'icons/odd/odd-up': OddUp,
  'icons/odd/odd-down': OddDown,
  /* Burger menu */
  'icons/nav_menu/betslip_default': BeslipDefault,
  'icons/nav_menu/goToOld': NavMenuGoToOld,
  'icons/nav_menu/results': NavMenuResults,
  'icons/nav_menu/betGames': NavMenuBetGames,
  'icons/nav_menu/casino': NavMenuCasino,
  'icons/nav_menu/casinoLive': NavMenuCasinoLive,
  'icons/nav_menu/cybersport': NavMenuCyberSport,
  'icons/nav_menu/live': NavMenuLive,
  'icons/nav_menu/lotteries': NavMenuLotteries,
  'icons/nav_menu/promo': NavMenuPromo,
  'icons/nav_menu/sports': NavMenuSport,
  'icons/nav_menu/tvBet': NavMenuTvBet,
  'icons/nav_menu/virtuals': NavMenuVirtuals,
  'icons/nav_menu/aviator': NavMenuAviator,
  'icons/nav_menu/keno': NavMenuKeno,
  'icons/nav_menu/menu': NavMenuMenu,
  'icons/nav_menu/euro': NavMenuEuro2,
  'icons/nav_menu/goldenRace': NavMenuGoldenRace,
  'icons/nav_menu/quiz': NavMenuQuiz,
  'icons/nav_menu/instantGames': NavMenuInstantGames,
  'icons/nav_menu/euro2020': NavMenuEuro,
  'icons/nav_menu/promos': NavMenuPromos,
  'icons/nav_menu/navMenu': NavMenuNavMenu,
  'icons/nav_menu/twainSport': NavMenuTwainSport,
  'icons/nav_menu/menuClose': NavMenuMenuClose,
  'icons/nav_menu/promotions': NavMenuPromotions,
  'icons/nav_menu/bonuses': NavMenuBonuses,
  'icons/nav_menu/tournaments': NavMenuTournaments,
  'icons/nav_menu/bookOfSix': NavMenuBookOfSix,
  'icons/general/action/upload': UploadAction,
  /* Predictions */
  'icons/predictions/predictionsInfoBlockIconActive': PredictionInfoBlockIconActive,
  'icons/predictions/predictionsInfoBlockIconChecked': PredictionInfoBlockIconChecked,
  'icons/predictions/predictionsInfoBlockIconEmpty': PredictionInfoBlockIconEmpty,
  'icons/predictions/predictionsInfoBlockIconFailed': PredictionInfoBlockIconFailed,
  'icons/general/nav/microphone': Microphone,
  'icons/general/nav/microphone_loader': MicrophoneLoader,
  'icons/assistant/assistant_microphone_disabled': AssistantMicrophoneDisabled,
  'icons/assistant/assistant_microphone_enabled': AssistantMicrophoneEnabled,
  'icons/nav_menu/assistant': AssistantNav,
  /* Baseball bases */
  'icons/baseball-bases/000': BaseballBases000,
  'icons/baseball-bases/001': BaseballBases001,
  'icons/baseball-bases/010': BaseballBases010,
  'icons/baseball-bases/011': BaseballBases011,
  'icons/baseball-bases/100': BaseballBases100,
  'icons/baseball-bases/101': BaseballBases101,
  'icons/baseball-bases/110': BaseballBases110,
  'icons/baseball-bases/111': BaseballBases111,
  /* Casino */
  'icons/casino/item/jackpot': CasinoItemJackpot,

  'icons/help/supportCircle': HelpSupportCircle,

  /* Bonuses */
  'icons/bonuses/bonussystem-background': BonusSystemBackground,
  'icons/bonuses/funds-background': BonusFundsBackground,
  'icons/bonuses/default-background': BonusDefaultBackground,
  'icons/bonuses/freespin-background': BonusFreeSpinBackground,
  'icons/bonuses/cashback-background': BonusCashbackBackground
};
