import { forwardRef } from 'react';
import { useI18n } from '../hooks/useI18n';
import LocalizedLink from './LocalizedLink';

const LocalizedNavLink = forwardRef(({ isActive, ...rest }, ref) => {
  const { language } = useI18n();

  const modifiedIsActive = isActive
    ? (match, location) =>
        isActive(match, {
          ...location,
          pathname:
            location.pathname.indexOf('/') === 0
              ? location.pathname.replace(`/${language}`, '')
              : location.pathname.replace(`${language}`, '')
        })
    : undefined;

  return <LocalizedLink isActive={modifiedIsActive} ref={ref} {...rest} />;
});

export default LocalizedNavLink;
