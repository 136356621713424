import { camelizeKeys } from 'humps';
import { post, postAsUriParameters } from '@namespace/helpers';
import { orderBy } from 'lodash';

export const checkCashdeskMaxAmount = ({ cashdesk, amount, walletHash }) =>
  postAsUriParameters('/accounting/api/check_cashdesk_max_order_amount', {
    cashdesk,
    amount,
    wallet_hash: walletHash
  });

/**
 *
 * @param walletHash
 * @returns {Promise<T>}
 */
export const favoritPayPrepare = ({ walletHash }) =>
  postAsUriParameters('/accounting/api/fp_prepare', {
    wallet_hash: walletHash
  }).then(({ response }) => camelizeKeys(response));

/**
 *
 * @returns {Promise<T>}
 */
export const favoritPayConfirm = () =>
  post('/accounting/api/fp_confirm').then(({ response }) =>
    camelizeKeys(response)
  );

/**
 *
 * @param walletHash
 * @param cashdesk
 * @param language
 * @returns {Promise<T>}
 */
export const getCities = ({ walletHash, cashdesk, language }) =>
  postAsUriParameters('/accounting/api/get_city_list', {
    wallet_hash: walletHash,
    cashdesk,
    lang: language
  }).then(({ response }) => orderBy(response, 'name'));

/**
 *
 * @param walletHash
 * @param cashdesk
 * @param language
 * @param cityId
 * @returns {Promise<T>}
 */
export const getCashdesks = ({ walletHash, language, cityId, amount }) =>
  postAsUriParameters('/accounting/api/get_cash_desk', {
    wallet_hash: walletHash,
    lang: language,
    city_id: cityId,
    amount
  }).then(({ response }) => response);

/**
 *
 * @returns {Promise<T>}
 */
export const getIpayCards = () =>
  post('/accounting/api/get_ipay_user_cards').then(({ response }) => response);

/**
 *
 * @returns {Promise<T>}
 */
export const getHexopayCards = () =>
  post('/accounting/api/get_hexopay_user_cards').then(
    ({ response }) => response
  );

/**
 *
 * @returns {Promise<T>}
 */
export const getArtPayCards = () =>
  post('/accounting/api/get_artpay_user_cards').then(
    ({ response }) => response
  );

/**
 *
 * @param accountId
 * @param amount
 * @param password
 * @returns {Promise<T>}
 */
export const prepareBankAccountWithdraw = ({
  accountId,
  amount = 0,
  password
}) =>
  postAsUriParameters('/accounting/api/bank_account_payout/prepare', {
    bank_account_id: accountId,
    amount: parseFloat(amount).toFixed(2),
    password
  }).then(({ response }) => response);

export const withdrawBankAccount = (isAgree = 0) =>
  postAsUriParameters('/accounting/api/bank_account_payout/withdrawal', {
    i_agree: isAgree
  }).then(camelizeKeys);

export const getAirCashPhones = () =>
  postAsUriParameters('/accounting/api/get_aircash_user_phones').then(
    ({ response }) => response
  );

export const checkHasActivePrewager = (walletHash) =>
  postAsUriParameters('/accounting/api/crm_roxy/hasactivepwbonus', {
    wallet_hash: walletHash
  }).then(({ response: crmData }) => {
    const { response: crmResponse } = crmData;
    return crmResponse;
  });

export const cancelPrewagerBonus = (walletHash) =>
  postAsUriParameters('/accounting/api/crm_roxy/cancelpwbonus', {
    wallet_hash: walletHash
  }).then(({ response: crmData }) => {
    const { errorCode } = crmData;
    if (errorCode === 1) {
      throw new Error(crmData);
    }
    return crmData;
  });

export const getDccCards = () =>
  postAsUriParameters('/accounting/api/get_dcc_user_cards').then(
    ({ response }) => response
  );
