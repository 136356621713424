import { useMemo, useCallback, useContext, useState, forwardRef } from 'react';
import { useI18n, LocalizedNavLink } from '@namespace/i18n';
import { AText, Box, LazyLoadImage } from '@alf/uikit';
import { DeviceContext } from '@namespace/device';
import EventMarketContainer from '../EventMarketContainer';
import TimerCounter from '../TimerCounter';
import EventFavoriteStar from '../EventFavoriteStar';
import { useEventLink } from '../../hooks/useEventLink';
import { MODE } from '../../constants';
import { useEvent, useEventFields } from '../../store';
import styles from './index.module.css';

const TEASER_MONTHS = [
  'teaser.month.jan',
  'teaser.month.feb',
  'teaser.month.mar',
  'teaser.month.apr',
  'teaser.month.may',
  'teaser.month.jun',
  'teaser.month.jul',
  'teaser.month.aug',
  'teaser.month.sep',
  'teaser.month.oct',
  'teaser.month.nov',
  'teaser.month.dec'
];

const SportTeaser = forwardRef(
  (
    {
      className = '',
      teaserPicture,
      eventId,
      market = {},
      handleClickOnMove,
      gap
    },
    ref
  ) => {
    const event = useEvent(eventId) || {};

    const {
      participants,
      eventDt,
      eventResultShortName,
      eventTimer,
      serviceId,
      eventResultTotal
    } = useEventFields({
      eventId,
      fields: [
        'participants',
        'eventDt',
        'eventResultShortName',
        'eventTimer',
        'serviceId',
        'eventResultTotal'
      ]
    });

    const { isDesktop } = useContext(DeviceContext);
    const { t, f, language } = useI18n();
    const DATE_FORMATS_TYPES = f.getFormats();
    // @TODO check as doit in line components
    const firstParticipant = participants?.[0] || {};
    const secondParticipant = participants?.[1] || {};

    const isLive = serviceId === MODE.IN_PLAY;

    const { eventDay, eventTime, eventMonth } = useMemo(() => {
      const eventDate = eventDt ? f.getDateTime({ date: eventDt * 1000 }) : ''; // convert to milliseconds

      return eventDate
        ? {
            eventDay: eventDate.day,
            eventMonth: t(TEASER_MONTHS[eventDate.month - 1]),
            eventTime: eventDate.toFormat(DATE_FORMATS_TYPES.TEASER_TIME)
          }
        : {};
    }, [eventDt, language]);

    const getScore = useCallback(() => {
      try {
        return eventResultTotal.match(/^(\d+:\d+)/)[1].replace(':', ' | ');
      } catch (e) {
        console.warn(e);
      }

      return null;
    }, [eventResultTotal]);

    const eventLink = useEventLink({ eventId });
    const marketsClickPreventDefault = useCallback(
      (e) => e.preventDefault(),
      []
    );
    const [isLoaded, setLoaded] = useState(false);
    const changeLoader = useCallback(() => setLoaded(true), [isLoaded]);

    if (!event) {
      return null;
    }

    return (
      <LocalizedNavLink
        ref={ref}
        style={gap && { marginRight: gap }}
        to={eventLink}
        onClick={handleClickOnMove}
        className={`${styles.teaser} ${className}`}
      >
        <div className={styles.imageWrapper}>
          <LazyLoadImage
            className={isLoaded ? styles.show : styles.hide}
            src={teaserPicture}
            onLoad={changeLoader}
          />
          <div
            className={`${!isLoaded ? styles.show : styles.hide} ${
              styles.skelet
            } ${styles.skeletImg}`}
          />
        </div>
        {isLive && (
          <Box align="center" className={styles.isLiveTeaser}>
            <AText breed="BU" size="xs" color="line_txt_2">
              {t('sportsbook.teasers.live.title')}
            </AText>
          </Box>
        )}
        <Box direction="column" className={`${styles.content}`}>
          <Box align="center" className={styles.info}>
            <Box flexGrow="1" className={styles.participant}>
              <AText
                breed="B"
                size="s"
                color="teaser_txt_1"
                overflow="ellipsis"
              >
                {firstParticipant.participantName}
              </AText>
            </Box>
            <Box
              flexGrow="1"
              flexShrink={0}
              direction="column"
              align="center"
              className={styles.eventTimeContainer}
            >
              <AText breed="RU" size="m" color="teaser_txt_2">
                {isLive ? getScore() : `${eventDay} ${eventMonth}`}
                {/* TODO remove hardcode */}
              </AText>
              <AText breed="RU" size="s" color="teaser_txt_2">
                {isLive ? (
                  <AText breed="RU" size="xs" color="teaser_txt_2">
                    {eventResultShortName &&
                      `${eventResultShortName}${eventTimer ? ' | ' : ''}`}
                    {eventTimer && (
                      <TimerCounter
                        timer2={eventTimer.timer2}
                        timerVector={eventTimer.timerVector}
                        action={eventTimer.action}
                        timer={eventTimer.timer}
                      />
                    )}
                  </AText>
                ) : (
                  eventTime
                )}
              </AText>
            </Box>
            <Box flexGrow="1" justify="end" className={styles.participant}>
              <AText
                breed="B"
                size="s"
                color="teaser_txt_1"
                overflow="ellipsis"
              >
                {secondParticipant.participantName}
              </AText>
            </Box>
          </Box>
          {Boolean(market) && (
            <Box
              className={styles.marketContainer}
              onClick={marketsClickPreventDefault}
            >
              {isDesktop && (
                <EventFavoriteStar
                  size="l"
                  eventId={eventId}
                  className={styles.favorite}
                />
              )}
              <EventMarketContainer
                market={market}
                isShowShortName={true}
                outcomeIds={market?.outcomeIds}
                eventId={eventId}
                classNames={{
                  outcomeContainerClassName: styles.outcomeContainerClassName,
                  outcomeMainClassName: styles.outcomeMainClassName
                }}
              />
            </Box>
          )}
        </Box>
      </LocalizedNavLink>
    );
  }
);

export default SportTeaser;
