export const SCROLLABLE_COLUMN = 'scrollableColumn';

export const COLOR_SCHEME = {
  SECONDARY: 'secondary',
  PRIMARY: 'primary'
};

export const MOBILE_MENU_MAX_LENGTH = 4;

export const HEADER_COLOR_SCHEME = {
  SECONDARY: 'secondary',
  PRIMARY: 'primary'
};

export const HEADER_COMPONENTS = {
  NAV_MENU_ITEM: 'NAV_MENU_ITEM'
};

export const USER_BLOCK_NAME_TYPES = {
  LOGIN: 'login',
  FULL_NAME: 'fullName',
  NONE: 'none'
};

export const MOBILE_MENU_TILES_TYPE = {
  LOBBY: 'lobby',
  PROFILE_MENU: 'profile-menu'
};
