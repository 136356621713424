import { useMemo } from 'react';
import { useI18n } from '@namespace/i18n';
import { useNotifications } from '@namespace/notifications';
import { DataTable, Button, Box } from '@alf/uikit';
import { Amount, TextCopy } from '@namespace/common';
import {
  TABLE_COLUMNS,
  WITHDRAWAL_WALLET_FIELD,
  WALLET_FIELD,
  AMOUNT_COLUMN,
  ID_COLUMN
} from '../../../constants';

import styles from './index.module.css';

const TransactionsHistoryTable = ({
  historyData,
  transactionHistory,
  dataFields = [],
  setPage,
  isFetching,
  isShowMore,
  customCellClass,
  tableRowStyle,
  size,
  copyIconOptions,
  isClearAfter
}) => {
  const { t } = useI18n();
  const { infoNotification, customNotification } = useNotifications();
  const {
    copyIconColor = 'po_txt_color_1',
    copyIconLink = 'icons/general/action/ic_copy',
    copyTextNotification
  } = copyIconOptions;

  const createAdditionalData = useMemo(
    () => (column) => {
      switch (column) {
        case AMOUNT_COLUMN:
          return {
            DataComponent: ({ row: { amount } }) => <Amount amount={amount} />
          };
        case ID_COLUMN: {
          return {
            DataComponent: ({ row: { id } }) => (
              <TextCopy
                gap="xs"
                breed="R"
                iconSize="s"
                iconLink={copyIconLink}
                iconColor={copyIconColor}
                textDisplay={id}
                textCopy={id}
                customNotification={
                  copyTextNotification
                    ? () => {
                        infoNotification(t(copyTextNotification));
                      }
                    : customNotification
                }
                textNotify="ID"
                dataRoles={{
                  dataRoleText: 'casino-history-copyId-value',
                  dataRoleIcon: 'casino-history-copyId-icon'
                }}
              />
            )
          };
        }
        default:
          return {};
      }
    },
    [copyIconColor, copyIconLink, copyTextNotification]
  );

  const columns = (transactionHistory.columns || TABLE_COLUMNS).map(
    (column) => {
      const additionalData = createAdditionalData(column);

      return column === WITHDRAWAL_WALLET_FIELD
        ? {
            key: column,
            Header: t(`balance.depositHistory.table.${column}`),
            accessor: WALLET_FIELD,
            cellClass: styles[WALLET_FIELD],
            ...additionalData
          }
        : {
            key: column,
            Header: t(`balance.depositHistory.table.${column}`),
            accessor: column,
            cellClass: styles[column],
            ...additionalData
          };
    }
  );

  const sortedColumns = dataFields.length
    ? dataFields.map((field) => columns.find((column) => column.key === field))
    : columns;

  return (
    <>
      <DataTable
        columns={sortedColumns}
        data={historyData}
        className={styles.table}
        customCellClass={customCellClass}
        tableRowStyle={tableRowStyle}
        size={size}
        isClearAfter={isClearAfter}
      />
      <Box className={styles.showMoreButtonWrapper}>
        {isShowMore && (
          <Button
            size="m"
            actionType="color3"
            intent="quaternary"
            onClick={setPage}
            className={styles.showMoreButton}
            processing={isFetching}
          >
            {t('balance.depositHistory.table.showMore')}
          </Button>
        )}
      </Box>
    </>
  );
};

export default TransactionsHistoryTable;
