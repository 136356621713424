const addSlashToPathEnd = (pathname = '') => {
  const slashOrHash = pathname.match(/(\?|#)(.*)/)?.[0];
  if (slashOrHash) {
    const pathWithoutSlashOrHash = pathname.match(/[^\(?|#)]*/)?.[0] || '';
    const lastSlash = pathWithoutSlashOrHash.slice(-1) === '/';
    if (lastSlash) return pathname;
    return `${pathWithoutSlashOrHash}/${slashOrHash}`;
  }

  const lastSlash = pathname.slice(-1) === '/';
  if (lastSlash) return pathname;
  return `${pathname}/`;
};

export default addSlashToPathEnd;
