import { createContext, useReducer } from 'react';
import { baseReducer, useActions } from '@namespace/helpers-store';
import types from '../types';
import { initialState, reducer as handlers } from '../reducer';
import actionCreators from '../actions';

export const TournamentsLeaderboardsContext = createContext();

export const TournamentsLeaderboardsProvider = ({
  children,
  customHandlers = {}
}) => {
  const reducer = baseReducer(handlers, customHandlers);
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useActions(types, dispatch, actionCreators);

  return (
    <TournamentsLeaderboardsContext.Provider value={[state, actions]}>
      {children}
    </TournamentsLeaderboardsContext.Provider>
  );
};
