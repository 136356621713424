import { hasRulesError } from '../../patterns';

const passwordValidator = (
  textRules = [],
  errorMessage = 'validators.validationError',
  rules,
  typeValidation
) => {
  const validationType = typeValidation || 'validate';

  return {
    ...rules,
    ...(textRules?.length && {
      [validationType]: (value) => {
        const isRulesError = hasRulesError(value, textRules);
        if (rules?.validate && !isRulesError) {
          return rules.validate(value);
        }

        return isRulesError ? errorMessage : true;
      }
    })
  };
};

export default passwordValidator;
