import { Fragment } from 'react';
import { Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { DEFAULT_GAMES_TAG_BACKGROUND } from '../../../constants';

import styles from './index.module.css';

const GameTags = ({ game = {}, className = '', gameTags = {} }) => {
  const { tags } = game;
  const { t } = useI18n();

  if (tags.length) {
    return (
      <div className={`${styles.tags} ${className}`}>
        {tags.map((tag) => {
          const { tagBackground, tagIcon } = gameTags[tag] || {};

          return (
            <Fragment key={tag}>
              {tagIcon && (
                <Box className={styles.iconWrapper} justify="center">
                  <img src={tagIcon} width="26px" height="32px" alt="" />
                </Box>
              )}
              {!tagIcon && (
                <div
                  className={styles.tag}
                  style={{
                    background: tagBackground || DEFAULT_GAMES_TAG_BACKGROUND
                  }}
                >
                  {t(`web.games.tag.${tag}`)}
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
    );
  }
  return null;
};

export default GameTags;
