import { flatten, uniq, sortBy, groupBy, isEmpty } from 'lodash';
import { getSlidePlatformImage } from '@namespace/cms';
import { pluck } from 'ramda';
import { SORT_MAP, SPORT_IDS } from '../../constants';
import { groupByTournament } from '../../utils';

export const normalizeSports = (allSports = []) =>
  allSports.reduce(
    (memo, { serviceId, sports }) => ({
      ...memo,
      [serviceId]: sports
    }),
    {}
  );

export const normalizeEvents = (
  events = [],
  { marketsKey = 'headMarkets' } = {}
) =>
  events.reduce(
    (acc, event) => {
      const { eventId } = event;
      const { [marketsKey]: markets = [], ...eventWithoutMarkets } = event;
      const { marketsData, marketsIds, outcomes } = markets.reduce(
        (memo, { outcomes: marketOutcomes = [], ...market }) => ({
          marketsData: Object.assign(memo.marketsData, {
            [market.marketId]: {
              eventId,
              ...market,
              outcomeIds: marketOutcomes.map(({ outcomeId }) => outcomeId)
            }
          }),
          marketsIds: [...memo.marketsIds, market.marketId],
          outcomes: Object.assign(
            memo.outcomes,
            marketOutcomes.reduce(
              (accOutcome, outcome) => ({
                ...accOutcome,
                [outcome.outcomeId]: outcome
              }),
              {}
            )
          )
        }),
        {
          marketsData: {},
          marketsIds: [],
          outcomes: {}
        }
      );

      return {
        eventsIds: [...acc.eventsIds, eventId],
        events: Object.assign(acc.events, { [eventId]: eventWithoutMarkets }),
        markets: Object.assign(acc.markets, marketsData),
        eventsMarketsIds: Object.assign(acc.eventsMarketsIds, {
          [eventId]: marketsIds
        }),
        outcomes: Object.assign(acc.outcomes, outcomes)
      };
    },
    {
      eventsIds: [],
      events: {},
      markets: {},
      eventsMarketsIds: {},
      outcomes: {}
    }
  );

const normalizeList = (list = [], idKey) =>
  list.reduce(
    (memo, { [idKey]: idVal, ...item }) => ({
      data: {
        ...memo.data,
        [idVal]: { [idKey]: idVal, ...item }
      },
      ids: [...memo.ids, idVal]
    }),
    { data: {}, ids: [] }
  );

const normalizeCategoriesList = (categories) =>
  normalizeList(categories, 'categoryId');
const normalizeTournamentsList = (tournaments) =>
  normalizeList(tournaments, 'tournamentId');

const sortList = (list = [], sortPropKey) =>
  [...list].sort(({ [sortPropKey]: a }, { [sortPropKey]: b }) => a - b);

const sortCategories = (categories) => sortList(categories, 'categoryWeigh');
const sortTournaments = (tournaments) =>
  sortList(tournaments, 'tournamentWeigh');

const listByServiceId = (list = [], listKey, normalizeMethod, sortMethod) =>
  list.reduce(
    (memo, { serviceId, [listKey]: listVal }) => ({
      ...memo,
      [serviceId]: normalizeMethod(sortMethod(uniq(flatten(listVal))))
    }),
    {}
  );

const categoriesByServiceId = (categories) =>
  listByServiceId(
    categories,
    'categories',
    normalizeCategoriesList,
    sortCategories
  );

const tournamentsByServiceId = (tournaments) =>
  listByServiceId(
    tournaments,
    'tournaments',
    normalizeTournamentsList,
    sortTournaments
  );

export const normalizeCategories = (categories = []) =>
  categoriesByServiceId(categories);

export const normalizeTournaments = (tournaments = []) =>
  tournamentsByServiceId(tournaments);

export const categoriesIdsByServiceId = (normalizedCategories, serviceIds) =>
  serviceIds.reduce(
    (memo, serviceId) => ({
      ...memo,
      [serviceId]: normalizedCategories[serviceId].ids
    }),
    {}
  );

export const normalizeHeadGroups = (headGroups = []) => {
  const groupedHeadGroups = groupBy(headGroups, 'sportId');
  const cyberSportHeadGroups = groupedHeadGroups[SPORT_IDS.CYBERSPORT] || [];

  return {
    ...groupedHeadGroups,
    [SPORT_IDS.CYBERSPORT]: cyberSportHeadGroups.sort(
      (a, b) => a.marketTemplateId - b.marketTemplateId
    )
  };
};

export const normalizeHeadGroupsBySportId = (headGroups = []) =>
  Object.keys(headGroups).reduce((acc, key) => {
    return {
      ...acc,
      [key]: headGroups[key].reduce(
        (marketTemplateIds, market) => ({
          ...marketTemplateIds,
          [market.marketTemplateId]: true
        }),
        {}
      )
    };
  }, {});

export const incorrectDataWorkAround = (market) => {
  const updatedMarket = { ...market };

  if (market.marketSuspend) {
    updatedMarket.marketSuspend = market.marketSuspend !== 'no';
  }

  return updatedMarket;
};

export const getGroupedMessages = (messages) =>
  messages.reduce((memo, item) => {
    const lastIndex = memo.length - 1;
    const subMemo = memo[lastIndex];

    return subMemo && subMemo.at(-1).type === item.type
      ? [...memo.slice(0, lastIndex), [...subMemo, item]]
      : [...memo, [item]];
  }, []);

export const sortEventsByMethod = (events, method) => {
  switch (method) {
    case SORT_MAP.TOURNAMENT: {
      const nextEvents = events.map((event) => ({
        ...event,
        weigh: event.categoryWeigh + event.tournamentWeigh
      }));

      return sortBy(nextEvents, 'tournamentId');
    }

    case SORT_MAP.TIME:
      return sortBy(
        sortBy(Object.values(events), 'eventLinePosition'),
        'eventDt'
      );

    case SORT_MAP.MBN:
      return sortBy(
        sortBy(sortBy(Object.values(events), 'eventLinePosition'), 'eventDt'),
        'eventGroup'
      );

    default:
      return events;
  }
};

const parsePresetEvent = (events) =>
  events.reduce((acc, item) => {
    const { eventIds = [], ...rest } = item;
    return [...acc, ...eventIds.map((eventId) => ({ eventId, ...rest }))];
  }, []);

const parsePresetTournaments = (tournaments) =>
  tournaments.reduce((acc, item) => {
    const { tournamentIds = [], ...rest } = item;
    return [
      ...acc,
      ...tournamentIds.map((tournamentId) => ({ tournamentId, ...rest }))
    ];
  }, []);

export const normalizePresets = (presets = []) =>
  presets.map((preset) => {
    const { presetName, events, tournaments } = preset;
    return {
      ...preset,
      presetId: presetName,
      events: events ? parsePresetEvent(events) : [],
      tournaments: tournaments ? parsePresetTournaments(tournaments) : []
    };
  });

export const parsePreset = (data, { t, pageType, language }) => {
  const { translationKey = '' } = data[pageType] || {};

  return {
    ...data,
    icon: getSlidePlatformImage(data, pageType, language),
    translation: t(translationKey)
  };
};

export const selectEvents = (events, line, sortType) => {
  if (isEmpty(line)) {
    return [];
  }

  const filteredEvents = line?.eventsIds
    ?.map((id) => events[id])
    .filter(Boolean);

  return sortType
    ? sortEventsByMethod(filteredEvents, sortType)
    : filteredEvents;
};

export const normalizeEventsToShow = ({ events, lines, sortByFilter }) =>
  Object.keys(lines).reduce((acc, lineKey) => {
    const line = lines[lineKey];
    const sortType = sortByFilter[lineKey];
    const preparedEvents = selectEvents(events, line, sortType);

    const eventsByTournaments = groupByTournament(preparedEvents);
    const tournamentsIds = [...eventsByTournaments.keys()];
    return {
      ...acc,
      [lineKey]: {
        eventsByTournaments: tournamentsIds.reduce(
          (eventsByTournamentsAcc, tournamentId) => ({
            ...eventsByTournamentsAcc,
            [tournamentId]: eventsByTournaments
              .get(tournamentId)
              .map((item) => item?.eventId)
          }),
          {}
        ),
        groupedEvents: Array.from(eventsByTournaments.values()).map((items) =>
          pluck('eventId', items)
        )
      }
    };
  }, {});
