import { useI18n } from '@namespace/i18n';
import { Box, AText } from '@alf/uikit';

export const WideGameInfo = ({ game, className = '' }) => {
  const { t } = useI18n();
  const { gameName, providerName, instaType, maxMultiplier, gameRTP } = game;

  return (
    <Box className={className} direction="column">
      <AText breed="BU" size="xl" color="txt_color_4">
        {gameName}
      </AText>
      <Box padding="xxs 0 0 0">
        <AText breed="R" size="m" color="txt_color_4">
          {providerName}
        </AText>
      </Box>
      <Box padding="s 0 0 0" direction="column">
        {instaType && (
          <Box padding="0 0 xs 0">
            <Box padding="0 xxs 0 0">
              <AText breed="R" size="m" color="txt_color_4">
                {`${t('web.casino.instant.parameter.type')}:`}
              </AText>
            </Box>
            <AText breed="B" size="m" color="txt_color_4">
              {instaType}
            </AText>
          </Box>
        )}
        {maxMultiplier && (
          <Box padding="0 0 xs 0">
            <Box padding="0 xxs 0 0">
              <AText breed="R" size="m" color="txt_color_4">
                {`${t('web.casino.instant.parameter.max_multiplier')}:`}
              </AText>
            </Box>
            <AText breed="B" size="m" color="txt_color_4">
              {maxMultiplier}
            </AText>
          </Box>
        )}
        {gameRTP && (
          <Box>
            <Box padding="0 xxs 0 0">
              <AText breed="R" size="m" color="txt_color_4">
                {`${t('web.casino.instant.parameter.rtp')}:`}
              </AText>
            </Box>
            <AText breed="B" size="m" color="txt_color_4">
              {gameRTP}
            </AText>
          </Box>
        )}
      </Box>
    </Box>
  );
};
