import { useContext, useMemo } from 'react';
import { find, mapObjIndexed, pipe, prop, propEq, propOr } from 'ramda';
import { BonusesContext } from '../../store/context';
import {
  BS_API_BONUS_PARTIAL_SCHEMA,
  fundsBonusSystemStates
} from '../../constants';
import { parseBsBonusDate } from '../../utils/parseBsBonusDate';
import { getMacrosData } from './utils';
import { parseBonusDuration } from '../../utils/parseBonusDuration';

export const useBonusDetailsBs = () => {
  const [{ bonus, bonusTmpl }] = useContext(BonusesContext);

  return useMemo(() => {
    if (bonus == null) {
      return {};
    }

    // prettier-ignore
    const parsedBonus = mapObjIndexed(
      (value, key) => {
        const type = BS_API_BONUS_PARTIAL_SCHEMA[key];

        switch (type) {
          case 'datetime':
            return parseBsBonusDate(value);
          case 'duration':
            return parseBonusDuration(value);
          default:
            return value;
        }
      }
    )(bonus);

    const {
      status,
      activationExpiredAtTimestamp,
      wageringExpiredAtTimestamp
    } = parsedBonus;

    const maxActivationTime =
      status === fundsBonusSystemStates.PROPOSED ||
      status === fundsBonusSystemStates.POTENTIAL
        ? activationExpiredAtTimestamp
        : null;
    const maxWageringTime =
      status === fundsBonusSystemStates.WAGERING
        ? wageringExpiredAtTimestamp
        : null;
    const countdownTimerTime =
      status === fundsBonusSystemStates.PROPOSED ||
      status === fundsBonusSystemStates.POTENTIAL
        ? maxActivationTime
        : maxWageringTime;

    const bonusContentId = pipe(
      propOr([], 'bonusStatesDescriptions'),
      find(propEq('state', status)),
      prop('snippetId')
    )(bonusTmpl);

    const macros = getMacrosData(parsedBonus);

    return {
      bonus,
      bonusTmpl,

      ...parsedBonus,

      maxActivationTime,
      maxWageringTime,
      countdownTimerTime,
      bonusContentId,

      macros
    };
  }, [bonus, bonusTmpl]);
};
