import { useEffect, useContext } from 'react';
import { camelizeKeys } from 'humps';
import { UserContext } from '@namespace/user';
import {
  socketSubscribe,
  socketSend,
  socketUnsubscribe
} from '@namespace/socket';
import { JackpotsContext } from '../../jackpotsContext';

export const useSubscribeToInHouseJackpot = (showInhouseJackpot) => {
  const [, { SET_IN_HOUSE_JACKPOTS, SET_IN_HOUSE_JACKPOT_WIN }] = useContext(
    JackpotsContext
  );
  const [user] = useContext(UserContext);
  const { activeWallet = {}, defaultCurrency = '' } = user;
  const { currency = '' } = activeWallet;
  const userCurrency = (currency || defaultCurrency).toUpperCase();

  useEffect(() => {
    (async () => {
      try {
        if (!showInhouseJackpot) return;

        socketSubscribe({
          name: 'jackpots_update',
          types: ['jackpots_update'],
          callback: ({ data }) => {
            SET_IN_HOUSE_JACKPOTS(data, userCurrency);
          }
        });
        socketSubscribe({
          name: 'jackpot_wins',
          types: ['jackpot_wins'],
          callback: ({ data }) => {
            SET_IN_HOUSE_JACKPOT_WIN(camelizeKeys(data));
          }
        });

        // TODO: resend this message in case of reconnect
        const initialJackpotsInfo = await socketSend({
          cmd: 'subscribe_jackpot_updates'
        });

        SET_IN_HOUSE_JACKPOTS(initialJackpotsInfo.data, userCurrency);
      } catch (e) {
        console.warn(e);
      }
    })();

    return () => {
      if (!showInhouseJackpot) return;

      socketUnsubscribe('jackpots_update');
      socketUnsubscribe('jackpot_wins');
      socketSend({ cmd: 'unsubscribe_jackpot_updates' });
    };
  }, [
    showInhouseJackpot,
    userCurrency,
    SET_IN_HOUSE_JACKPOTS,
    SET_IN_HOUSE_JACKPOT_WIN
  ]);
};
