import { useCallback, useContext, useMemo } from 'react';
import { I18nContext, useI18n, useFetchTimeZones } from '@namespace/i18n';
import { Box, Select, SELECT_TYPE } from '@alf/uikit';
import { COLOR_SCHEME } from '@namespace/themes';
import styles from './index.module.css';

const TimeZoneSwitcher = ({ switcherClassName = '' }) => {
  useFetchTimeZones();
  const [i18nState, i18nActions] = useContext(I18nContext);
  const { timeZones, timezone } = i18nState;
  const { CHANGE_TIMEZONE } = i18nActions;
  const { f } = useI18n();
  const DATE_FORMATS_TYPES = f.getFormats();

  const validTimezoneCode = timeZones.find((i) => i.timezoneCode === timezone)
    ? timezone
    : timeZones.find((i) => i.timezoneDiff === f.getDateTime().offset * 60)
        ?.timezoneCode;

  const timeZonesOptions = useMemo(
    () =>
      timeZones.map(({ timezoneDiff, timezoneCode, timezoneName }) => {
        const offset = timezoneDiff / 3600;
        const value =
          offset > 0 ? `UTC+${Math.abs(offset)}` : `UTC-${Math.abs(offset)}`;
        return {
          value: timezoneCode,
          title: `${f
            .getDateTime()
            .setZone(timezoneCode)
            .toFormat(
              DATE_FORMATS_TYPES.HOURS_MINUTES
            )} (${value}) ${timezoneName}`
        };
      }),
    [timeZones, f, DATE_FORMATS_TYPES.HOURS_MINUTES]
  );

  const onTimeZoneChange = useCallback(({ value }) => CHANGE_TIMEZONE(value), [
    CHANGE_TIMEZONE
  ]);

  return (
    <Box align="center" className={`${switcherClassName} ${styles.switcher}`}>
      <Select
        options={timeZonesOptions}
        onChange={onTimeZoneChange}
        value={validTimezoneCode}
        isDesktop={true}
        size="xs"
        additionalPopoverStyle={styles.contentWrap}
        additionSelectStyles={{
          triggerContentClass: styles.triggerContent,
          triggerContentTitleClass: styles.triggerContentTitle
        }}
        additionSelectOptionStyle={{ item: styles.item }}
        additionSelectContentStyle={styles.contentStyle}
        selectType={SELECT_TYPE.ADDITIONAL}
        intentType={COLOR_SCHEME.SECONDARY}
      />
    </Box>
  );
};

export default TimeZoneSwitcher;
