import { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useNotifications } from '@namespace/notifications';
import { jackpotWinsStates } from '@namespace/bonuses';
import { InHouseJackpotWinNotification } from '../../common/InHouseJackpotWinNotification';
import useGetInHouseJackpotSettings from '../useGetInHouseJackpotSettings';
import { getJackpotWins } from '../api';

export const useShowInHouseJpWinNotificationAfterLogin = (
  isShowAfterLoginNotifications
) => {
  const { winNotification = {} } = useGetInHouseJackpotSettings();

  const isShowAfterLogin =
    isShowAfterLoginNotifications && winNotification.isShowAfterLogin;

  const { customNotification } = useNotifications();
  const [isLogicCompleted, setIsLogicCompleted] = useState(false);

  useEffect(() => {
    if (!isShowAfterLogin || isLogicCompleted) return;

    (async () => {
      try {
        const { status, data } = await getJackpotWins(
          jackpotWinsStates.AVAILABLE
        );

        if (status !== 'success' || isEmpty(data)) {
          setIsLogicCompleted(true);
          return;
        }

        const [lastWonJackpot] = data;

        customNotification({
          NotificationComponent: InHouseJackpotWinNotification,
          timeout: null,
          message: {
            amount: lastWonJackpot.userRealAmount,
            currency: lastWonJackpot.userCurrency,
            jackpotId: lastWonJackpot.id
          }
        });

        setIsLogicCompleted(true);
      } catch (e) {
        console.error(e);
        setIsLogicCompleted(true);
      }
    })();
  }, [customNotification, isLogicCompleted, isShowAfterLogin]);

  return isLogicCompleted;
};
