import { useContext } from 'react';
import clsx from 'clsx';
import { PageContext } from '@namespace/cms';
import { Box } from '@alf/uikit';
import { ColumnContent } from '../common/ColumnContent';
import { SCROLLABLE_COLUMN } from '../../../../constants';
import styles from '../index.module.css';

export const OneColumn = () => {
  const { page } = useContext(PageContext);
  const { layout = {} } = page;
  const { columns = [], columnsWithIndependentScroll } = layout;
  const isScrollable = columnsWithIndependentScroll?.[0];

  return (
    <Box
      className={clsx([
        styles.column,
        styles.oneColumnWrapper,
        isScrollable && `${styles.scrollableColumn} ${SCROLLABLE_COLUMN}`
      ])}
      direction="column"
    >
      <ColumnContent column={columns[0]} isScrollable={isScrollable} />
    </Box>
  );
};
