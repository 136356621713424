import { SPORT_IDS } from '@namespace/sports';

export const formatVirtualSportsEvents = ({ events }) =>
  events
    .sort(({ eventDt: eventDt1 }, { eventDt: eventDt2 }) => eventDt2 - eventDt1)
    .map((event) => {
      const {
        categoryName,
        tournamentName,
        eventDt: eventDate,
        resultGameTotalResultTotal: totalResult,
        resultGameTotalResultTotalJson: totalResultJson,
        eventName,
        eventId
      } = event;
      return {
        tournamentTitle: tournamentName === eventName ? '' : tournamentName,
        eventName,
        totalResult,
        totalResultJson,
        sportId: SPORT_IDS.VIRTUAL_SPORTS,
        sportName: categoryName,
        eventDate,
        categoryName,
        eventId
      };
    });

export const mappedSportsNames = (names) =>
  [...names].map(({ sportName, sportId }) => ({
    name: sportName,
    id: sportId
  }));

export const mappedTournamentsNames = (tournaments = []) =>
  (tournaments || []).map(({ tournamentName, tournamentId }) => ({
    name: tournamentName,
    id: tournamentId
  }));

export const processEvents = (events = []) =>
  events.map(
    ({
      eventName,
      tournamentName: tournamentTitle,
      eventDtRaw: eventDate,
      eventDt,
      eventId,
      sportName,
      categoryName,
      sportId,
      resultGameTotalResultTotal: totalResult,
      eventResultTotalJson: eventResultTotal,
      eventStatusDescName,
      scoreboard,
      eventResultId,
      participants,
      sportformId
    }) => ({
      eventName,
      categoryName,
      tournamentTitle,
      eventDt,
      eventDate,
      sportName,
      eventId,
      totalResult,
      sportId,
      eventResultTotal,
      eventStatusDescName,
      scoreboard,
      eventResultId,
      participants,
      sportformId
    })
  );

export const normalizePages = (pages) => {
  return {
    hasNextPage: Boolean(pages?.paginatorNext?.length)
  };
};

export const normalizeEvents = (tournaments, categoryName) =>
  tournaments.reduce(
    (memo, { events, tournamentName }) => [
      ...memo,
      ...events.map((event) => ({
        ...event,
        tournamentName,
        categoryName
      }))
    ],
    []
  );

export const normalizeTournaments = (tournaments) =>
  tournaments.reduce(
    (memo, { tournamentId, tournamentName }) => [
      ...memo,
      {
        name: tournamentName,
        id: tournamentId
      }
    ],
    []
  );

export const sortDataCategories = (data) => {
  return [...data]
    .sort((a, b) => (a.categoryName < b.categoryName ? -1 : 1))
    .map(({ categoryName, categoryId }) => ({
      name: categoryName,
      id: categoryId
    }));
};
