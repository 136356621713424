import { useContext, useMemo } from 'react';
import { SPORT_IDS } from '@namespace/sports';
import { BetSlipContext } from '../../store/context';

const useBetsCounter = () => {
  const [{ bets = [] }] = useContext(BetSlipContext);
  return useMemo(
    () => bets.filter((bet) => bet.sport_id !== SPORT_IDS.BONUS).length,
    [bets]
  );
};

export default useBetsCounter;
