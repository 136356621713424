import { useCallback, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useI18n } from '@namespace/i18n';
import { Input } from '@alf/uikit';
import { logAmplitude } from '@namespace/analytics';
import getFormError from '../../utils/getFormError';
import Control from '../Control';
import useDynamicField from '../../hooks/useDynamicField';
import useCustomError from '../../hooks/useCustomError';
import useOnChangeValidate from '../../hooks/useOnChangeValidate';
import useInstantValidation from '../../hooks/useInstantValidation';
import styles from './index.module.css';

const InputField = ({
  name,
  label = '',
  labelParams = [],
  hint = '',
  withTooltip,
  noErrorMsg,
  classNames = {},
  rules = {},
  allowClean = false,
  isOnChangeValidation = false,
  isClearErrorsOnChange = false,
  customError = '',
  formProps = {},
  inputType = 'text',
  dataRole,
  placeholder = '',
  size = 's',
  iconSize,
  disableAutocompleteFieldsAbove = false,
  controlProps = {},
  enableInstantFieldValidation = false,
  analyticAction,
  enableTrimSpaces = false,
  postInputLabel = false,
  ...props
}) => {
  const { t } = useI18n();
  useInstantValidation(name, enableInstantFieldValidation);
  const {
    inputClassName = '',
    containerClassName = '',
    labelClassName = ''
  } = classNames;
  const methods = useFormContext();
  const {
    control,
    clearErrors,
    formState: { errors },
    setValue,
    watch,
    getFieldState
  } = methods;
  const { dynamicValues = {} } = formProps;

  const fieldValue = watch(name);

  useDynamicField({ name, dynamicFieldData: dynamicValues[name] });

  const { errorMessage, customErrorHandler } = useCustomError(customError);
  const onClean = useCallback((value = '') => setValue(name, value), [
    setValue,
    name
  ]);

  useEffect(() => {
    if (isClearErrorsOnChange) {
      clearErrors();
    }
  }, [clearErrors, isClearErrorsOnChange, fieldValue]);

  const handleAnalyticAction = useCallback(
    (e) => {
      if (analyticAction) {
        // setTimeout for waiting input validation for Amplitude event analytic
        setTimeout(() => {
          const { invalid } = getFieldState(name);
          logAmplitude(analyticAction, {
            [name]: e?.target?.value,
            valid: !invalid
          });
        }, 0);
      }
    },
    [analyticAction, getFieldState, name]
  );

  const handleBlur = useCallback(
    (fn) => (e) => {
      if (enableTrimSpaces) {
        const trimmedValue = e.target.value.trim();
        e.target.value = trimmedValue;
        setValue(name, trimmedValue);
      }
      fn?.(e);
      handleAnalyticAction(e);
    },
    [enableTrimSpaces, handleAnalyticAction, name, setValue]
  );

  const inputLabel =
    typeof label === 'object' ? label : t(label, [...labelParams]);
  const errorText = getFormError(name, errors, t) || t(errorMessage) || '';

  useOnChangeValidate({ name, isOnChangeValidation });

  return (
    <Control
      key={controlProps.key}
      classNames={{
        ...classNames,
        wrapperClassName: `${classNames.wrapperClassName} ${
          disableAutocompleteFieldsAbove ? styles.wrapperClassName : ''
        } `
      }}
      error={errorText}
      hint={hint}
      withTooltip={withTooltip}
      noErrorMsg={noErrorMsg}
      onClick={customErrorHandler}
      id={name}
      size={size}
      label={inputType !== 'hidden' ? inputLabel : ''}
      postInputLabel={postInputLabel}
      dataRole={dataRole}
    >
      {disableAutocompleteFieldsAbove && (
        <>
          <input type="text" className={styles.hideInput} />
        </>
      )}
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { ref, onBlur, ...field } }) => {
          return (
            <Input
              {...props}
              id={name}
              size={size}
              iconSize={iconSize || size}
              clearable={allowClean}
              onClean={onClean}
              onBlur={handleBlur(onBlur)}
              data-role={dataRole}
              type={inputType}
              placeholder={t(placeholder)}
              className={inputClassName}
              wrapperClassName={containerClassName}
              intent={errorText ? 'error' : 'default'}
              postInputLabel={postInputLabel && inputLabel}
              labelClassName={labelClassName}
              {...field}
            />
          );
        }}
      />
    </Control>
  );
};

export default InputField;
