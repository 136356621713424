import { useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { NativeAppContext } from '../../../../store';

const useConfirmExit = (confirmExit) => {
  const { navigator } = useContext(NavigationContext);
  const [
    {
      isNativeApp,
      nativeAppConfig: { delegateRouting }
    }
  ] = useContext(NativeAppContext);
  useEffect(() => {
    if (isNativeApp && delegateRouting) {
      const { push, replace } = navigator;

      navigator.push = (...args) => {
        const { pathname, search } = args[0] || {};
        confirmExit({
          callback: () => push(...args),
          path: pathname ? `${pathname}${search}` : '/'
        });
      };

      navigator.replace = (...args) => {
        const { pathname, search } = args[0] || {};
        confirmExit({
          callback: () => replace(...args),
          path: pathname ? `${pathname}${search}` : '/'
        });
      };

      navigator.replace = (...args) => {
        confirmExit({
          callback: () => replace(...args),
          path: args[0]?.pathname || '/'
        });
      };

      return () => {
        navigator.push = push;
        navigator.replace = replace;
      };
    }
    return () => {};
  }, [navigator, confirmExit, isNativeApp, delegateRouting]);
};

export default useConfirmExit;
