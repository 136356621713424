export const TRANSITION_TYPES = {
  SINGLE: 'single',
  MULTIPLE: 'multiple'
};

export const TRANSITION_CONVERT_TYPES = {
  multiple: 'snap',
  single: 'freeScroll',
  strict: 'strict'
};

export const SLIDER_ITEM_TYPES = {
  HTML_SNIPPET: 'HTML snippet',
  IMAGE: 'Image'
};
