const compositeValidator = (validators = []) => {
  const grouped = validators.reduce(
    (acc, current, idx) => ({
      ...acc,
      [`validator_${idx}`]: current.validate
    }),
    {}
  );
  return { validate: grouped };
};

export default compositeValidator;
