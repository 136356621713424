import { useCallback, useContext, useState } from 'react';
import { FormBuilder, SubmitButton } from '@namespace/forms';
import { AText, Box, UploadFile } from '@alf/uikit';
import { fileSizeFormat, window } from '@namespace/helpers';
import { SiteSettingsContext, useGeneralRoutes } from '@namespace/cms';
import { useNotifications } from '@namespace/notifications';
import { LocalizedRedirect, useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { UserContext, useVerifyDocuments } from '@namespace/user';
import styles from '../index.module.css';

const UploadFiles = ({ maxSize, maxCount, allowedFilesFormats, minCount }) => {
  const { t } = useI18n();
  const [files, setFiles] = useState([]);
  const [wrongFormatFiles, setWrongFormatFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [user = {}, userActions] = useContext(UserContext);
  const { verified, isEmptyDocument } = useVerifyDocuments();
  const [settings] = useContext(SiteSettingsContext);
  const { siteConfig = {} } = settings;
  const { accountStatuses = {} } = user;
  const { isDocumentsUpload } = accountStatuses;
  const { oneTimeVerificationDocuments = false } = siteConfig;
  const { UPLOAD_DOCUMENTS, FETCH_USER_DATA } = userActions;
  const { push } = useHistory();
  const { loadDocumentsSuccess } = useGeneralRoutes();
  const onSuccess = useCallback(() => push(loadDocumentsSuccess), []);
  const { errorNotification } = useNotifications();

  const formSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        setIsUploading(true);

        const res = await UPLOAD_DOCUMENTS(files);

        if (res.type === 'error') {
          throw t(`error_${res.status}`);
        }

        setIsUploading(false);
        onSuccess();
        window.scrollTo(0, 0);
        FETCH_USER_DATA();
      } catch (err) {
        setIsUploading(false);
        errorNotification(err);
      }
    },
    [files]
  );
  const onOverSize = useCallback((state) => {
    setIsDisabled(state);
  }, []);

  if (
    oneTimeVerificationDocuments &&
    isDocumentsUpload &&
    !verified &&
    !isEmptyDocument
  ) {
    return <LocalizedRedirect to={loadDocumentsSuccess} />;
  }

  const isMinFilesCount = minCount ? files?.length >= minCount : true;

  return (
    <Box className={styles.formWrapper} direction="column">
      <Box direction="column">
        <AText
          component="p"
          className={styles.header}
          color="po_txt_color_1"
          breed="B"
          size="l"
          align="center"
        >
          {t('verificationWizard.step.uploadDocuments.uploadForm')}
        </AText>
        <AText
          component="p"
          className={styles.subHeader}
          color="po_txt_color_1"
          breed="R"
          size="l"
          align="center"
        >
          {t('verificationWizard.step.uploadDocuments.fileRequirements', [
            fileSizeFormat(maxSize),
            maxCount
          ])}
        </AText>
        {!isMinFilesCount && (
          <AText
            component="p"
            className={styles.minFilesCount}
            color="po_txt_color_1"
            breed="R"
            size="l"
            align="center"
          >
            {t('verificationWizard.step.uploadDocuments.minFilesCount', [
              minCount
            ])}
          </AText>
        )}
      </Box>
      <FormBuilder encType="multipart/form-data">
        <UploadFile
          label={t('verificationWizard.step.uploadDocuments.selectFiles')}
          accept={allowedFilesFormats}
          name="upload"
          onChange={setFiles}
          filesList={files}
          maxCount={maxCount}
          maxSize={maxSize}
          setWrongFiles={setWrongFormatFiles}
          overSizeLabel={t('verificationWizard.step.uploadDocuments.overSize')}
          onMaxSize={onOverSize}
          wrongFormatText={t(
            'verificationWizard.step.uploadDocuments.wrongFormat'
          )}
        />
        <SubmitButton
          type="submit"
          classNames={{ className: styles.submitButton }}
          disabled={
            !isMinFilesCount ||
            !files.length ||
            isDisabled ||
            isUploading ||
            Boolean(wrongFormatFiles.length)
          }
          onClick={formSubmit}
          size="l"
          value={t('verificationWizard.step.uploadDocuments.sendDocument')}
        />
      </FormBuilder>
    </Box>
  );
};

export default UploadFiles;
