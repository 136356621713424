import { Carousel, Box, TRANSITION_CONVERT_TYPES, DOTS_TYPE } from '@alf/uikit';
import styles from './index.module.css';

const EventsSlider = ({
  children = null,
  sliderOptions = {},
  className = '',
  isBulletsEnabled = false,
  isArrowsEnabled = false,
  ...props
}) => (
  <Box className={styles.sliderContainer}>
    <Carousel
      className={`${className}`}
      arrow={{
        name: 'icons/general/nav/back',
        leftClass: `${styles.prevNext} ${styles.leftBtn}`,
        rightClass: `${styles.prevNext} ${styles.rightBtn}`,
        background: `${styles.background}`
      }}
      circularSlidesCount={4}
      transitionType="multiple"
      moveType={[TRANSITION_CONVERT_TYPES.multiple, { count: 1 }]}
      anchor="0"
      hanger="0"
      navigation={{
        arrows: isArrowsEnabled,
        ...(isBulletsEnabled && { dots: { type: DOTS_TYPE.SMALL } })
      }}
      {...sliderOptions}
      {...props}
    >
      {children}
    </Carousel>
  </Box>
);

export default EventsSlider;
