import { useRef } from 'react';
import { AText, Box, Button } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { useFixScroll } from '@namespace/common';
import { Icon } from '@namespace/icons2';
import useGetInHouseJackpotSettings from '../../hooks/useGetInHouseJackpotSettings';
import { useGetInHouseJackpotNotificationControls } from '../../hooks/useGetInHouseJackpotNotificationControls';
import { IN_HOUSE_JACKPOT_TYPES } from '../../constants';
import styles from './index.module.css';

export const InHouseJackpotWinNotification = ({ message, onClose }) => {
  const { t } = useI18n();
  const { winNotification = {} } = useGetInHouseJackpotSettings();
  const { acceptType } = winNotification;

  const {
    handleAccept,
    isRequestProcessing
  } = useGetInHouseJackpotNotificationControls(
    message.jackpotId,
    winNotification,
    IN_HOUSE_JACKPOT_TYPES.WIN_NOTIFICATION
  );

  const onClick = () => {
    handleAccept();
    onClose();
  };

  const notificationRef = useRef(null);

  useFixScroll(
    'notification-InHouseJackpotWinNotification',
    notificationRef,
    true
  );

  return (
    <>
      <Box className={styles.overlay} />
      <Box className={styles.notification} ref={notificationRef}>
        <Icon
          className={styles.closeIcon}
          onClick={onClose}
          size="s"
          fill="btn_close_light_color"
          name="icons/general/action/close"
        />
        <Box flex="1" direction="column" align="center" padding="l">
          <AText breed="BU" size="2xl" color="state_favorite">
            {t(`web.games.jackpot.winMessageCongratulations`)}
          </AText>
          <Box padding="xs 0 0 0">
            <AText breed="R" size="2xl" color="txt_color_4" align="center">
              {t(`web.games.jackpot.winMessageYouWon`)}
            </AText>
          </Box>
          <Box>
            <AText breed="B" size="2xl" color="txt_color_4">
              {message?.amount}
            </AText>
            <Box padding="0 0 0 xs">
              <AText breed="R" size="2xl" color="txt_color_4">
                {message?.currency}
              </AText>
            </Box>
          </Box>
          <Box padding="ms 0 0 0">
            <Button
              size="s"
              intent="primary"
              actionType="color2"
              onClick={onClick}
              className={styles.acceptBtn}
              disabled={isRequestProcessing}
            >
              {t(`games.jackpot.${acceptType}`)}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
