import { useEffect } from 'react';
import { scrollTo, window } from '@namespace/helpers';
import { useLocation } from '@namespace/i18n';

const useScrollToTop = (pageType, isDisablePageAutoScroll = false) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isDisablePageAutoScroll && !window.location.hash) {
      // need timeout here for correct scroll behaviour on IOS devices
      scrollTo({ timeout: 200 });
    }
  }, [pathname, pageType]);
};

export default useScrollToTop;
