import { useEffect, useState } from 'react';
import { useResizeObserver } from '@namespace/device';
import { window } from '@namespace/helpers';

/* eslint-disable guard-for-in, no-param-reassign, no-restricted-syntax */
const styleSwap = (el, props, cb) => {
  const old = {};

  for (const name in props) {
    old[name] = el.style[name];
    el.style[name] = props[name];
  }

  const result = cb(el);

  for (const name in props) {
    el.style[name] = old[name];
  }

  return result;
};
/* eslint-enable guard-for-in, no-param-reassign, no-restricted-syntax */

const cssShow = {
  position: 'absolute',
  visibility: 'hidden',
  display: 'block'
};

const getElWidthUnsafe = (el) =>
  el.getBoundingClientRect().width +
  parseInt(window.getComputedStyle(el)?.marginLeft, 10);

const getElWidth = (el) => {
  const { display } = getComputedStyle(el);

  // calculate width of hidden item(s)
  if (display === 'none') {
    return styleSwap(el, cssShow, getElWidthUnsafe);
  }

  return getElWidthUnsafe(el);
};

const getPriorityItemCount = (
  navRef,
  moreRef,
  outerWidth,
  additionalRef,
  minimumNumberInNav = 2
) => {
  const itemNavWidths = Array.from(navRef.current.children).map(getElWidth);
  const itemAddWidths = Array.from(additionalRef.current.children).map(
    getElWidth
  );
  const itemWidths = [...itemNavWidths, ...itemAddWidths];
  const sum = itemWidths.reduce((acc, item) => acc + item, 0);

  // all elements fit without 'more' button
  if (outerWidth >= sum || outerWidth === 0) {
    return Math.max(itemWidths.length, minimumNumberInNav);
  }

  // find how many elements fit with 'more' button
  let width = getElWidth(moreRef.current) + getElWidth(additionalRef.current);
  let i = 0;

  while (width < outerWidth && i < itemNavWidths.length) {
    width += itemNavWidths[i];
    ++i;
  }

  return Math.max(i - 1, minimumNumberInNav);
};

export const usePriorityNavigation = ({
  navRef,
  outerRef,
  moreRef,
  additionalRef,
  itemCount = 0
}) => {
  const [priorityCount, setPriorityCount] = useState(itemCount);
  const [outerWidth] = useResizeObserver(outerRef, false);

  useEffect(() => {
    const newItemCount = getPriorityItemCount(
      navRef,
      moreRef,
      outerWidth,
      additionalRef
    );

    if (newItemCount !== priorityCount) {
      setPriorityCount(newItemCount);
    }
  }, [moreRef, additionalRef, navRef, outerWidth, priorityCount]);

  return { priorityCount };
};
