import { memo } from 'react';
import Icon from '../Icon';
import getSportIcon from '../../utils/getSportIcon';

const SportIcon = ({ sportId, ...props }) => {
  const iconName = getSportIcon(sportId);

  return <Icon name={iconName} {...props} />;
};

export default memo(SportIcon);
