import { useOtherSettings } from '@namespace/cms';
import { get } from 'lodash';

const useMyAccountSettings = () => {
  const otherSettings = useOtherSettings();

  return get(otherSettings, 'myAccount', {});
};

export default useMyAccountSettings;
