import { createContext, useMemo, useReducer } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import { types } from '../types';
import { actions as actionCreators } from '../actions';
import { reducers, initialState } from '../reducers';

export const LoginHistoryContext = createContext();

export const LoginHistoryProvider = ({ children }) => {
  const memoReducers = useMemo(() => combineReducers(reducers), []);
  const [state, dispatch] = useReducer(memoReducers, initialState);
  const actions = useActions(types, dispatch, actionCreators);

  return (
    <LoginHistoryContext.Provider value={[state, actions]}>
      {children}
    </LoginHistoryContext.Provider>
  );
};
