import { camelizeKeys } from 'humps';
import { post } from '@namespace/helpers';

export const deletePan = ({ userId, accountingId, ssid, cardHash }) =>
  post('/pankeeper/frontend_api/delete_pan/', {
    user_id: String(userId),
    accounting_id: accountingId,
    ssid,
    card_hash: cardHash
  }).then(camelizeKeys);
