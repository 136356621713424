import { useContext, useMemo } from 'react';
import { BetsContext } from '../../context';

const useBets = () => {
  const [{ bets }] = useContext(BetsContext);
  const { data, ids } = bets;
  return useMemo(() => ids.map((id) => data[id]), [ids, data]);
};

export default useBets;
