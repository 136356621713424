import { assoc, propEq } from 'ramda';
import { FORM_FACTORS_MAP } from '@namespace/device';
import * as defaults from '../../defaults';

const navigateItem = (indexPropName, itemsPropName) => (
  state,
  { isForward, index }
) => {
  const itemsLength = state[itemsPropName].length;

  if (itemsLength === 0) {
    return state;
  }

  const prevIndex = state[indexPropName];
  let nextIndex;

  if (isForward != null) {
    nextIndex = isForward ? prevIndex + 1 : prevIndex - 1;
  } else if (index != null) {
    nextIndex = index;
  } else {
    throw new Error('Internal Error: invalid tour navigation call');
  }

  if (nextIndex >= itemsLength) {
    nextIndex = itemsLength - 1;
  } else if (nextIndex < 0) {
    nextIndex = 0;
  }

  if (nextIndex === prevIndex) {
    return state;
  }

  return assoc(indexPropName, nextIndex, state);
};

/**
 * @type {Function}
 */
export const navigateStep = (state, ...args) => {
  const nextState = navigateItem('stepIndex', 'steps')(state, ...args);

  return {
    ...nextState,
    isLoading: true
  };
};

/**
 * Apply platform overrides to each step.
 * To skip step - set override to 'skip' string. To add step - add null step in base configuration in the same place.
 * @param state
 * @param pageType
 * @returns {Array<Object>}
 */
export const applyPlatformOverrides = (state, pageType) => {
  const tour = state.tours[state.tourIndex];
  let steps = tour?.steps || [];

  if (pageType !== FORM_FACTORS_MAP.DESKTOP) {
    const platformOverride = tour[FORM_FACTORS_MAP.MOBILE];

    if (platformOverride != null) {
      steps = steps
        .flatMap((step) => {
          const { id: stepId } = step;
          const stepOverride = platformOverride.steps?.[stepId];

          // pass 'skip' string to remove step
          if (stepOverride === 'skip') {
            return [];
          }

          // actual step override
          if (typeof stepOverride === 'object') {
            const newStep = {
              ...step,
              ...stepOverride
            };

            return [newStep];
          }

          // by default, ignore empty and incorrect overrides
          return [step];
        })
        .filter(Boolean);
    }
  }

  return steps;
};

export const navigateTour = (state, { isForward, index, tourId, pageType }) => {
  const tourIndex =
    tourId != null ? state.tours.findIndex(propEq('id', tourId)) : index;

  const nextState = navigateItem('tourIndex', 'tours')(state, {
    isForward,
    index: tourIndex
  });

  const steps = applyPlatformOverrides(nextState, pageType);

  return {
    ...nextState,
    steps,
    stepIndex: 0
  };
};

export const applyDefaults = ({
  tours = [],
  padding = defaults.tourManagerProps.padding,
  mocks,
  preferredCurrency
}) => ({
  tours: tours.map((tour) => ({
    ...defaults.tour,
    ...tour,
    steps:
      tour.steps?.map((step) => ({
        ...defaults.step,
        ...step
      })) ?? []
  })),
  padding,
  mocks,
  preferredCurrency
});
