import { useReducer, useMemo } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import { useGlobalMockedStore } from '@namespace/onboarding/src/hooks/useGlobalMockedStore';
import { actions as actionCreators } from './actions';
import { reducers, initialState } from './reducers';
import { types } from './reducers/types';
import { UserContext } from './context';

export const UserProvider = ({ customReducers, children }) => {
  const memoReducers = useMemo(() => {
    return combineReducers(reducers, customReducers);
  }, [customReducers]);
  const [state, dispatch] = useReducer(memoReducers, initialState);

  const actions = useActions(types, dispatch, actionCreators);

  return (
    <UserContext.Provider value={useGlobalMockedStore('user', state, actions)}>
      {children}
    </UserContext.Provider>
  );
};
