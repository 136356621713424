export default {
  checkIban: '/accounting/api/check_iban',
  checkPassword: '/accounting/api/checkpassowrd',
  checkPhone: '/accounting/api/checkphone',
  checkFio: '/accounting/api/checkfio',
  checkDoc: '/accounting/api/check_document',
  checkDate: '/accounting/api/checkyears',
  checkPersonalCode: '/accounting/api/check_personal_code',
  checkCity: '/accounting/api/check_city',
  checkAddress: '/accounting/api/check_address',
  checkZip: '/accounting/api/check_zip',
  checkEmail: '/accounting/api/checkemail',
  checkInn: '/accounting/api/validate_inn',
  checkUsername: '/accounting/api/checkusername'
};
