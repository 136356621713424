import { useMemo } from 'react';
import { useGeneralRoutes } from '@namespace/cms';
import { useLocation, useI18n } from '@namespace/i18n';
import { MODE } from '../../../constants';
import LiveEventsButton from '../../../components/LiveEventsButton';

export const LiveButton = ({ eventId, serviceId, sportSlug }) => {
  const { liveEvent, preMatchEvent } = useGeneralRoutes();
  const { pathname } = useLocation();
  const { t } = useI18n();
  const isLiveEventUnderPreMatchUrl =
    serviceId === MODE.IN_PLAY && pathname.includes(preMatchEvent);

  const link = useMemo(() => {
    if (isLiveEventUnderPreMatchUrl) {
      return `${liveEvent}/${sportSlug}/${eventId}`;
    }

    return '';
  }, [eventId, isLiveEventUnderPreMatchUrl, liveEvent, sportSlug]);

  return (
    <LiveEventsButton
      propLink={link}
      labelFirstPart={
        isLiveEventUnderPreMatchUrl
          ? t('web.sportsbook.eventpage.button.gotoevent')
          : t('web.sportsbook.eventpage.button.goto')
      }
      labelSecondPart={t('web.sportsbook.eventpage.button.live')}
      isDisplayCount={!isLiveEventUnderPreMatchUrl}
    />
  );
};
