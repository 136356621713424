import { prop } from 'ramda';
import { shallow } from 'zustand/shallow';
import { createSelector } from 'reselect';
import { useSportsbookSelector } from '../../../../store';

export const selectEventsIdsByLineKey = (lineKey, tournamentsIds = []) =>
  createSelector(prop('eventsToShowByLineKey'), (eventsToShowByLineKey) => {
    const { eventsByTournaments = {}, groupedEvents = [] } =
      eventsToShowByLineKey?.[lineKey] || {};
    return tournamentsIds.length
      ? tournamentsIds
          .map((tournamentId) => eventsByTournaments[tournamentId])
          .filter(Boolean)
      : groupedEvents;
  });

export const useEventsByIdsToShow = (lineKey, tournamentsIds) =>
  useSportsbookSelector(
    selectEventsIdsByLineKey,
    [lineKey, tournamentsIds],
    shallow
  );
