import GameSkelet from '../GameSkelet';
import Game from '../Game';

// used on casino pages for wide games - casino / casino live / instant games. depends on casino settings for each type
// FYI: no external usage for now
export const WideGameWrapperInternal = ({
  game,
  className,
  isMobileGridView,
  index,
  ...rest
}) => {
  return game.isPlaceholder ? (
    <GameSkelet
      className={className}
      style={{
        gridRow: (isMobileGridView ? index * 3 : index) + 1
      }}
    />
  ) : (
    <Game
      style={{
        gridRow: (isMobileGridView ? index * 3 : index) + 1
      }}
      isWideGame={true}
      className={className}
      game={game}
      index={`wide-${index + 1}`}
      {...rest}
    />
  );
};
