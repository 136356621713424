import { cloneElement, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  assoc,
  map,
  pipe,
  propSatisfies,
  startsWith,
  toLower,
  unless
} from 'ramda';
import clsx from 'clsx';
import { noop } from 'lodash';
import { COLOR_SCHEME } from '@namespace/themes';
import Popover from '../../../Popover';
import { MultiSelectContent } from './components/MultiSelectContent';
import { getTrigger } from '../../triggers';
import styles from './index.module.css';

export const MultiSelectDesktop = ({
  t,
  options,
  groupBy,
  groupsData,
  onChange = noop,
  value,
  title,
  disabled,
  placeholder,
  searchPlaceholder = t('uikit.multiSelect.search.placeholder'),
  kind,
  size,
  intent = 'default',
  hasSearch,
  isInlineSearch,
  className,
  classNames,
  style,
  customTrigger,
  showSelectAll,
  dataRole,
  haveToCloseOutsideClick,
  intentType,
  isExpandable,
  isOpenExternal,
  setIsOpenExternal
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [isOpenLocal, setIsOpenLocal] = useState(false);

  const isOpen = isOpenExternal ?? isOpenLocal;
  const setIsOpen = setIsOpenExternal ?? setIsOpenLocal;

  const handleOpen = useCallback(
    (open) => {
      if (disabled) {
        return;
      }

      setSearchValue('');
      setIsOpen(open);
    },
    [disabled, setIsOpen]
  );

  const markedOptions = useMemo(() => {
    if (!hasSearch) {
      return options;
    }

    const lowerSearchValue = searchValue.toLowerCase();
    const startsWithSearchValue = pipe(toLower, startsWith(lowerSearchValue));

    return map(
      unless(
        propSatisfies(startsWithSearchValue, 'title'),
        assoc('hidden', true)
      )
    )(options);
  }, [hasSearch, options, searchValue]);

  const onSearchValueChange = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);

  const triggerProps = {
    value,
    title,
    disabled,
    intent,
    hasSearch,
    isInlineSearch,
    searchValue,
    onSearchValueChange,
    placeholder,
    className: classNames?.trigger,
    isOpen,
    size,
    intentType,
    options
  };

  const Trigger = getTrigger(kind);

  return (
    <div
      className={clsx(styles.root, className)}
      style={style}
      data-role={dataRole}
    >
      <Popover
        trigger={
          customTrigger ? (
            cloneElement(customTrigger, triggerProps)
          ) : (
            <Trigger {...triggerProps} />
          )
        }
        content={
          <MultiSelectContent
            t={t}
            options={markedOptions}
            groupBy={groupBy}
            groupsData={groupsData}
            onChange={onChange}
            size={size}
            value={value}
            hasSearch={hasSearch}
            isInlineSearch={isInlineSearch}
            searchValue={searchValue}
            onSearchValueChange={onSearchValueChange}
            placeholder={placeholder}
            searchPlaceholder={searchPlaceholder}
            className={classNames?.content}
            classNames={classNames}
            intentType={intentType}
            showSelectAll={showSelectAll}
            dataRole={dataRole}
          />
        }
        classNames={{
          contentClass: styles.contentClass
        }}
        haveToCloseOutsideClick={haveToCloseOutsideClick}
        setIsOpenExternal={handleOpen}
        isOpenExternal={isOpen}
        isExpandable={isExpandable}
      />
    </div>
  );
};

MultiSelectDesktop.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      title: PropTypes.string
    })
  ),
  value: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  classNames: PropTypes.shape({
    content: PropTypes.string,
    trigger: PropTypes.string
  }),
  kind: PropTypes.oneOf(['count', 'chips']),
  intent: PropTypes.oneOf(['error', 'success', 'default']),
  size: PropTypes.oneOf(['xs', 's', 'm']),
  style: PropTypes.object,
  customTrigger: PropTypes.func,
  t: PropTypes.func,
  intentType: PropTypes.oneOf([COLOR_SCHEME.PRIMARY, COLOR_SCHEME.SECONDARY])
};
