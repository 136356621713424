import { LazyLoadComponent } from 'react-lazy-load-image-component';
import ResponsiveImage from '../../common/ResponsiveImage';

const LazyResponsiveImage = (props) => {
  const {
    beforeLoad,
    className,
    delayMethod,
    delayTime,
    height,
    placeholder,
    scrollPosition,
    style,
    threshold,
    useIntersectionObserver,
    visibleByDefault,
    width,
    ...imgProps
  } = props;

  return (
    <LazyLoadComponent
      beforeLoad={beforeLoad}
      className={className}
      delayMethod={delayMethod}
      delayTime={delayTime}
      height={height}
      placeholder={placeholder}
      scrollPosition={scrollPosition}
      style={style}
      threshold={threshold}
      useIntersectionObserver={useIntersectionObserver}
      visibleByDefault={visibleByDefault}
      width={width}
    >
      <ResponsiveImage
        className={className}
        style={style}
        height={height}
        width={width}
        {...imgProps}
      />
    </LazyLoadComponent>
  );
};

export default LazyResponsiveImage;
