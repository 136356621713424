import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';

class LocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, 'LLLL yyyy', { locale: this.locale });
  }

  getDatePickerHeaderText(date) {
    return format(date, 'EEEEEE, dd MMMM', { locale: this.locale });
  }
}

export default LocalizedUtils;
