import { useContext, useEffect } from 'react';
import { isEmpty } from 'lodash';
// FIX ME LOOPS DEPS
import useOtherSettings from '@namespace/cms/src/hooks/useOtherSettings';
import { USER_ROLES } from '@namespace/user/src/constants';
import { UserContext } from '@namespace/user/src/context';
import { ABTestContext } from '../../store/context';

const useInitABSegments = () => {
  const [{ abSegments, isSegmentsLoaded }, { FETCH_AB_SEGMENTS }] = useContext(
    ABTestContext
  );
  const { isABSegmentsEnabled } = useOtherSettings();
  const [user] = useContext(UserContext);
  const { role } = user;

  useEffect(() => {
    if (
      !isSegmentsLoaded &&
      isEmpty(abSegments) &&
      isABSegmentsEnabled &&
      role === USER_ROLES.USER
    ) {
      FETCH_AB_SEGMENTS();
    }
  }, [
    FETCH_AB_SEGMENTS,
    abSegments,
    isABSegmentsEnabled,
    isSegmentsLoaded,
    role
  ]);
};

export default useInitABSegments;
