import ConfirmAgeField from './components/ConfirmAgeField';
import ConfirmAgeCombined from './components/ConfirmAgeCombined';
import BackButton from './components/BackButton';
import ReceiveNotificationField from './components/ReceiveNotificationField';

export const REGISTER_COMPONENTS_TYPES = {
  CONFIRM_AGE_FIELD: 'confirm-age-field',
  CONFIRM_AGE_COMBINED_FIELD: 'confirm-age-combined-field',
  RECEIVE_NOTIFICATIONS_FIELD: 'receive-notification-field',
  BACK_BUTTON: 'back-button'
};

export const registerComponentsMap = {
  [REGISTER_COMPONENTS_TYPES.CONFIRM_AGE_FIELD]: ConfirmAgeField,
  [REGISTER_COMPONENTS_TYPES.CONFIRM_AGE_COMBINED_FIELD]: ConfirmAgeCombined,
  [REGISTER_COMPONENTS_TYPES.RECEIVE_NOTIFICATIONS_FIELD]: ReceiveNotificationField,
  [REGISTER_COMPONENTS_TYPES.BACK_BUTTON]: BackButton
};

export const passwordRulesList = [
  {
    label: ['validation.lessMore', ['8', '30']],
    rules: /^.{8,30}$/
  },
  {
    label: 'validation.charAndNumberLowerUpperCase',
    rules: 'charAndNumberLowerUpperCase'
  },
  {
    label: 'validation.additionalSymbols',
    rules: 'additionalSymbols'
  }
];

export const shortRegisterRestData = {
  firstName: 'Быстрая',
  lastName: 'Регистрация',
  birthdate: '1970-01-01',
  gender: 'mr',
  secretQuestion: 'What is your phone number?'
};
