import { useReducer } from 'react';
import { baseReducer, useActions } from '@namespace/helpers-store';
import { types, initialState, reducer as handlers } from './reducer';
import { NativeAppContext } from './context';
import actionCreators from './actions';

export const NativeAppProvider = ({ children, customHandlers = {} }) => {
  const reducer = baseReducer(handlers, customHandlers);

  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useActions(types, dispatch, actionCreators);

  return (
    <NativeAppContext.Provider value={[state, actions]}>
      {children}
    </NativeAppContext.Provider>
  );
};
