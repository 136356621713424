import { createSelector } from 'reselect';
import { find, pathOr, propEq } from 'ramda';
import { useSportsbookSelector } from '../../store';

export const selectSportById = (sportId, serviceId) =>
  createSelector(
    pathOr([], ['sports', serviceId]),
    find(propEq('sportId', sportId))
  );

export const useSport = (sportId, serviceId) =>
  useSportsbookSelector(selectSportById, [sportId, serviceId]);
