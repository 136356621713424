import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { DeviceContext } from '@namespace/device';
import { UserContext } from '@namespace/user';
import { COMPONENTS_TYPES, PageComponentsContext } from '@namespace/cms';
import { useI18n, useLocation } from '@namespace/i18n';
import { Tabs } from '@alf/uikit';
import { BetSlipContext } from '../../store/context';
import useBetsCounter from '../../hooks/useBetsCounter';
import BetSlipTab from './components/BetSlipTab';
import styles from './index.module.css';

export const BetSlipTabs = forwardRef(({ config, classNames = {} }, ref) => {
  const { t } = useI18n();

  const componentsMap = useContext(PageComponentsContext);
  const { isDesktop } = useContext(DeviceContext);

  const {
    components: childComponents = [],
    defaultTabIndex = 0,
    privateTabs = [],
    footerComponents = []
  } = config;

  const [betSlip, { SET_FAST_BET }] = useContext(BetSlipContext);
  const { bets = [], outcomeClickCounter, isSettingsOpen } = betSlip;
  const { pathname } = useLocation();
  const [user] = useContext(UserContext);
  const { id: userId } = user;
  const [activeTabIndex, setActiveTabIndex] = useState(defaultTabIndex);

  const handleSetIndex = useCallback(
    (index) => {
      setActiveTabIndex(index);
      SET_FAST_BET(false);
    },
    [setActiveTabIndex, SET_FAST_BET]
  );

  const [betSlipTabIndex] = useState(
    childComponents.findIndex(
      (component) => component.type === COMPONENTS_TYPES.BET_SLIP
    )
  );

  const betsCounter = useBetsCounter();

  const tabs = useMemo(
    () =>
      childComponents
        .filter(
          (component) =>
            !(privateTabs.includes(component?.type) && !userId) &&
            !(
              !isDesktop &&
              component?.type === COMPONENTS_TYPES.MULTIPLE_OF_THE_DAY
            )
        )
        .map((component, index) => {
          const Content = componentsMap[component?.type];

          return {
            label: t(`menu.navigation.${component?.type}`),
            type: component?.type,
            isBetslip: component?.type === 'betslip',
            component: (
              <>
                <Content
                  id={component.type}
                  config={component.config}
                  setActiveTabIndex={handleSetIndex}
                  isBetslip={true}
                />
                {isDesktop &&
                  footerComponents.map((footerComponent) => {
                    const Component = componentsMap[footerComponent?.type];

                    return (
                      Component && (
                        <Component
                          key={`${footerComponent?.type}-${JSON.stringify(
                            footerComponent?.config
                          )}`}
                          config={footerComponent?.config}
                        />
                      )
                    );
                  })}
              </>
            ),
            onActive: handleSetIndex,
            counter: index === betSlipTabIndex ? String(betsCounter) : ''
          };
        }),
    [
      childComponents,
      privateTabs,
      userId,
      isDesktop,
      componentsMap,
      t,
      handleSetIndex,
      footerComponents,
      betSlipTabIndex,
      betsCounter
    ]
  );

  useEffect(() => {
    if (outcomeClickCounter && betSlipTabIndex !== -1) {
      setActiveTabIndex(betSlipTabIndex);
    }
  }, [outcomeClickCounter, betSlipTabIndex]);

  useEffect(() => {
    if (activeTabIndex !== null) {
      setActiveTabIndex(null);
    }
  }, [activeTabIndex]);

  useEffect(() => {
    if (bets.length === 0) {
      setActiveTabIndex(defaultTabIndex);
    }
  }, [bets.length, defaultTabIndex, pathname]);

  return (
    <Tabs
      list={tabs}
      activeTabIndex={activeTabIndex}
      isDesktop={isDesktop}
      ref={ref}
      classNames={{
        tabsClassName: `${styles.container}`,
        componentClassName: !isSettingsOpen && styles.overflow,
        ...classNames
      }}
      ListComponent={BetSlipTab}
    />
  );
});
