export const BUILT_IN_TAX_RATE_TYPES = {
  UA: 'ua'
};

export const BUILT_IN_TAX_RATE_NAMES = {
  [BUILT_IN_TAX_RATE_TYPES.UA]: {
    PERSONAL_INCOME:
      'personalOffice.balance.withdraw.taxRateType.personalIncome',
    MIL_FEE: 'personalOffice.balance.withdraw.taxRateType.milFee'
  }
};

export const BUILT_IN_TAX_RATES = {
  [BUILT_IN_TAX_RATE_TYPES.UA]: [
    {
      key: BUILT_IN_TAX_RATE_NAMES[BUILT_IN_TAX_RATE_TYPES.UA].PERSONAL_INCOME,
      value: 18
    },
    {
      key: BUILT_IN_TAX_RATE_NAMES[BUILT_IN_TAX_RATE_TYPES.UA].MIL_FEE,
      value: 1.5
    }
  ]
};
