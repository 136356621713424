import { useContext } from 'react';
import { useI18n } from '@namespace/i18n';
import { AText } from '@alf/uikit';
import { UserContext } from '@namespace/user/src/context';

import VerificationStatusNotification from '../VerificationStatusNotification';

const AdditionalInfoOtherVerificationToast = ({ onClose }) => {
  const { t } = useI18n();
  const [user] = useContext(UserContext);
  const { email } = user;

  const messageText = t('additionalInfoOtherVerification.notification', [
    email
  ]);
  const mobileMessage = (
    <AText color="txt_color_3" size="l" align="center">
      {messageText}
    </AText>
  );

  return (
    <VerificationStatusNotification
      onClose={onClose}
      message={messageText}
      mobileMessage={mobileMessage}
    />
  );
};

export default AdditionalInfoOtherVerificationToast;
