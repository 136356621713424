import { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useGeneralRoutes } from '@namespace/cms';
import { useNotifications } from '@namespace/notifications';
import { useI18n } from '@namespace/i18n';
import { UserContext } from '@namespace/user';
import { BonusShopContext } from '../../store/bonusShop/context';
import { setShopOrder } from '../../api/setShopOrder';
import { getNovaPoshtaParams } from '../../api/getNovaPoshtaParams';
import useMyAccountSettings from './hooks/useMyAccountSettings';

const useShopItems = () => {
  const [
    {
      products,
      priceRange,
      filteredProducts,
      categories,
      selectedProducts,
      userBonusInfo,
      totalAmount
    },
    {
      FETCH_PRODUCT_LIST,
      FETCH_CATEGORY_LIST,
      FETCH_USER_INFO,
      ADD_SHOP_ITEM,
      REMOVE_SHOP_ITEM,
      REMOVE_ONE_STACK,
      SET_TOTAL_AMOUNT,
      CLEAR_BASKET,
      SET_PRICE_RANGE
    }
  ] = useContext(BonusShopContext);
  const { novaPoshtaApiKey } = useMyAccountSettings();
  const { deposit, loyaltyRatingLevel } = userBonusInfo;
  const { language, t } = useI18n();
  const {
    infoNotification,
    successNotification,
    errorNotification
  } = useNotifications();
  const [user] = useContext(UserContext);
  const { firstName, middleName, lastName, phoneNumber, id: userId } = user;
  const [rangeValue, setRangeValue] = useState({
    minDefault: 0,
    maxDefault: 0,
    minValue: 0,
    maxValue: 0
  });
  const { shop: shopRoute } = useGeneralRoutes();
  // load shop item and categories list, calculate min/max range
  useEffect(() => {
    if (!products.length || !categories.length) {
      FETCH_PRODUCT_LIST({ language });
      FETCH_CATEGORY_LIST({
        language,
        route: shopRoute,
        allTab: {
          id: 'all',
          link: `${shopRoute}/`,
          title: t('bonusShop.categories.All')
        }
      });
    }
  }, [language, t, shopRoute]);

  // set range if products list isset
  useEffect(() => {
    if (products.length > 0) {
      const itemPriceList = products.map(({ price }) => price);
      const minDefault = Math.min(...itemPriceList) || 0;
      const maxDefault = Math.max(...itemPriceList) || 0;

      setRangeValue((prev) => ({
        minDefault: prev.minDefault || minDefault,
        maxDefault: prev.maxDefault || maxDefault,
        minValue: priceRange.minValue || prev.minValue || minDefault,
        maxValue: priceRange.maxValue || prev.maxValue || maxDefault
      }));
    }
  }, [products]);

  // load user bonus wallet and loyalty status
  useEffect(() => {
    if (isEmpty(userBonusInfo)) {
      FETCH_USER_INFO();
    }
  }, []);

  const actualProducts = useMemo(
    () => (!isEmpty(priceRange) ? filteredProducts : products),
    [filteredProducts, products, priceRange]
  );

  // range filter
  const handleFilterItems = useCallback(
    async (maxFilter = false) => {
      const { minValue, maxValue, minDefault, maxDefault } = rangeValue;

      SET_PRICE_RANGE({ minValue, maxValue });
      FETCH_PRODUCT_LIST({
        language,
        price_from: maxFilter ? minDefault : minValue,
        price_to: maxFilter ? maxDefault : maxValue
      });
    },
    [language, rangeValue]
  );

  // add item to basket
  const handleToBasket = useCallback(
    (id, price, buyerType) => {
      const totalPrice = totalAmount + price;

      if (!buyerType.includes(loyaltyRatingLevel)) {
        infoNotification(t('bonusShop.productList.lowLevel'));
      } else if (deposit < totalPrice) {
        infoNotification(t('bonusShop.productList.enoughPoints'));
      } else {
        SET_TOTAL_AMOUNT(totalPrice);
        ADD_SHOP_ITEM(id);
        successNotification(t('bonusShop.productList.addedToBasket'));
      }
    },
    [
      totalAmount,
      loyaltyRatingLevel,
      deposit,
      infoNotification,
      t,
      SET_TOTAL_AMOUNT,
      ADD_SHOP_ITEM,
      successNotification
    ]
  );

  // remove item from basket
  const handleRemoveItem = useCallback(
    (id, price) => {
      SET_TOTAL_AMOUNT(totalAmount - price);
      REMOVE_SHOP_ITEM(id);
    },
    [totalAmount]
  );

  // remove all item stack from basket
  const handleDropItemStack = useCallback(
    (id, price) => {
      const { [id]: count } = selectedProducts;
      const totalPrice = totalAmount - price * count;

      SET_TOTAL_AMOUNT(totalPrice);
      REMOVE_ONE_STACK(id);
    },
    [totalAmount, selectedProducts]
  );

  // load cities list on typing
  const handleGetCities = useCallback(
    async (city) => {
      if (city && city.length > 2) {
        const list = await getNovaPoshtaParams({
          modelName: 'Address',
          calledMethod: 'getCities',
          methodProperties: {
            FindByString: city
          },
          apiKey: novaPoshtaApiKey
        });

        return list.map(({ description, descriptionRu, ref }) => ({
          value: ref,
          title: language === 'ru' ? descriptionRu : description
        }));
      }

      return [];
    },
    [language, novaPoshtaApiKey]
  );

  // load warehouse list on typing
  const handleGetWarehouse = useCallback(
    async (inputValue, city) => {
      if (String(inputValue).length && city) {
        const list = await getNovaPoshtaParams({
          modelName: 'AddressGeneral',
          calledMethod: 'getWarehouses',
          methodProperties: {
            CityRef: city,
            FindByString: inputValue
          },
          apiKey: novaPoshtaApiKey
        });

        return list.map(({ description, descriptionRu, ref }) => ({
          value: ref,
          title: language === 'ru' ? descriptionRu : description
        }));
      }

      return [];
    },
    [language, novaPoshtaApiKey]
  );

  // send order form
  const createOrder = (props) => {
    const selectedItems = props.selectedProduct
      ? [props.selectedProduct]
      : Object.keys(selectedProducts);
    const itemsList = selectedItems.map((productId) => ({
      id: Number(productId),
      qty: String(props.selectedProduct ? 1 : selectedProducts[productId])
    }));
    const params = {
      user_id: props.user_id,
      full_name: props.fullname,
      phone: props.phoneNumber,
      city_name: props.city,
      delivery_address: props.mailAddress,
      comment: props.orderInfo,
      items: itemsList
    };

    return setShopOrder(params);
  };

  // create single order
  const handleToBuy = useCallback(
    async (id, price, buyerType, isReal) => {
      const totalPrice = totalAmount + price;
      const body = {
        city: 'Київ', // hardcode for single order
        mailAddress: 'Відділення №1: вул. Пирогівський шлях, 135', // hardcode for single order
        user_id: userId,
        fullname: `${firstName} ${middleName} ${lastName}`,
        phoneNumber,
        orderInfo: '',
        selectedProduct: !isReal && id
      };

      try {
        if (!buyerType.includes(loyaltyRatingLevel)) {
          infoNotification(t('bonusShop.productList.lowLevel'));
        } else if (deposit < totalPrice) {
          infoNotification(t('bonusShop.productList.enoughPoints'));
        } else {
          await createOrder(body);
          FETCH_USER_INFO();
          successNotification(t('bonusShop.productList.success'));
        }
      } catch {
        errorNotification(t('bonusShop.productList.form.error'));
      }
    },
    [
      totalAmount,
      userId,
      firstName,
      middleName,
      lastName,
      phoneNumber,
      loyaltyRatingLevel,
      deposit,
      infoNotification,
      t,
      createOrder,
      successNotification,
      errorNotification
    ]
  );

  return {
    products: actualProducts,
    categories,
    selectedProducts,
    userBonusInfo,
    rangeValue,
    totalAmount,
    setRangeValue,
    handleFilterItems,
    handleToBuy,
    createOrder,
    handleToBasket,
    handleRemoveItem,
    handleDropItemStack,
    clearBasket: CLEAR_BASKET,
    handleGetCities,
    handleGetWarehouse
  };
};

export default useShopItems;
