import { Box } from '@alf/uikit';
import { PersonalOfficeWrapper } from '../../../common';

const Index = ({ children }) => {
  return (
    <Box component={PersonalOfficeWrapper} align="start">
      {children}
    </Box>
  );
};

export default Index;
