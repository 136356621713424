import { useCallback, useContext, useState } from 'react';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { DeviceContext, FORM_FACTORS_MAP } from '@namespace/device';
import { LOG_ACTIONS, logAction } from '@namespace/analytics';
import { PAYMENT_SYSTEMS } from '@namespace/payments';
import { useGeneralRoutes, useOtherSettings } from '@namespace/cms';
import { useNotifications } from '@namespace/notifications';
import {
  REAL_MODE,
  useGetGameURL,
  getGamesByLaunchIds
} from '@namespace/games';
import { UserContext } from '@namespace/user';
import { LOCAL_STORAGE_KEYS, setLocalStorageValue } from '@namespace/helpers';
import { acceptWager } from '../../api/freeSpin';
import { accept } from '../../api/acceptBonus';
import {
  acceptModeType,
  bonusTab,
  freeSpinStates,
  progressBarStates
} from '../../constants';
import prepareBonusDetailsList from '../../utils/prepareBonusDetailsList';
import getBonusExpireTime from '../../utils/getBonusExpireTime';
import { BonusesContext } from '../../store/context';
import { unselectBonusSystemBonus } from '../../api/unselectBonusSystemBonus';

const useFreeSpin = (bonus, onAccept, onWagerAccept) => {
  const [isRequestProcessing, setIsRequestProcessing] = useState(false);
  const {
    state,
    outputBetTotal,
    currency,
    freespinCount: freeSpinCount,
    outputMaxTotal,
    gameName,
    acceptMode,
    maxActivationTime = {},
    freespinEndTime = {},
    gameId,
    serviceId,
    freespinId,
    gameCode,
    isGameManagement
  } = bonus;
  const { t, language, f } = useI18n();
  const DATE_FORMATS_TYPES = f.getFormats();
  const [user] = useContext(UserContext);
  const { activeWallet = {}, wallets = [] } = user;
  const { deposit: userBalance = 0, paymentInstrumentId = 0 } = activeWallet;
  const { push } = useHistory();
  const { errorNotification, successNotification } = useNotifications();
  const { isMobile, isTablet } = useContext(DeviceContext);
  const platform =
    isMobile || isTablet ? FORM_FACTORS_MAP.MOBILE : FORM_FACTORS_MAP.DESKTOP;
  const getGameURL = useGetGameURL();
  const {
    deposit,
    casinoWager,
    createWallet: createWalletPage,
    games: casinoPage,
    casinoBonusDetails
  } = useGeneralRoutes();
  const bonusExpireTime = getBonusExpireTime(bonus);
  const { isBonusSliderCRMBonusClickable = true } = useOtherSettings();
  const [{ activeBonusSystemBonusId }] = useContext(BonusesContext);

  const isWagerAvailable = useCallback(
    () => acceptModeType.WAGER.includes(acceptMode),
    [acceptMode]
  );

  const isShowWagerButton = useCallback(
    () => state === freeSpinStates.FREESPIN_WAGERING && outputMaxTotal > 0,
    [outputMaxTotal, state]
  );

  const isCanAccept = useCallback(
    () => acceptModeType.ACCEPT.includes(acceptMode),
    [acceptMode]
  );

  const isReleased = useCallback(
    () => state === freeSpinStates.FREESPIN_RELEASED,
    [state]
  );

  const isNoWallet = useCallback(
    () =>
      ((wallets.length === 1 && paymentInstrumentId === PAYMENT_SYSTEMS.FP) ||
        wallets.length === 0) &&
      userBalance <= 0 &&
      !isWagerAvailable() &&
      !isCanAccept() &&
      !isReleased(),
    [
      wallets,
      userBalance,
      paymentInstrumentId,
      isWagerAvailable,
      isCanAccept,
      isReleased
    ]
  );

  const isPotential = useCallback(
    () => state === freeSpinStates.FREESPIN_POTENCIAL,
    [state]
  );

  const isActivation = useCallback(
    () => state === freeSpinStates.FREESPIN_ACCEPT_REQUEST,
    [state]
  );

  const isShowButton = useCallback(
    () =>
      isActivation() ||
      isCanAccept() ||
      isReleased() ||
      isPotential() ||
      isWagerAvailable() ||
      isShowWagerButton(),
    [
      isActivation,
      isCanAccept,
      isReleased,
      isPotential,
      isWagerAvailable,
      isShowWagerButton
    ]
  );

  const handleAccept = useCallback(async () => {
    try {
      setIsRequestProcessing(true);
      const message = await accept(
        {
          bonus_id: freespinId,
          lang: language,
          platform
        },
        bonusTab.casino
      );
      setIsRequestProcessing(false);
      successNotification(t(message));
      if (onAccept) {
        onAccept(bonus, platform);
      }
    } catch (e) {
      setIsRequestProcessing(false);
      errorNotification(
        t(e.message || 'accountingMessages.accounting_error_6', [currency])
      );
    }
  }, [bonus, freespinId, currency, language, t, platform, onAccept]);

  const handleWagerAccept = useCallback(async () => {
    try {
      setIsRequestProcessing(true);
      const message = await acceptWager(bonus, language, platform);
      setIsRequestProcessing(false);
      successNotification(t(message));
      if (onWagerAccept) {
        onWagerAccept(bonus, platform);
      }
    } catch (e) {
      setIsRequestProcessing(false);
      errorNotification(
        e.message ? t(e.message) : t('accountingMessages.accounting_error_6')
      );
    }
  }, [onWagerAccept, platform, t, bonus, language]);

  const acceptFreeSpin = useCallback(
    async (e) => {
      e.stopPropagation();
      if (isNoWallet()) {
        push(createWalletPage);
      } else if (isPotential()) {
        if (isBonusSliderCRMBonusClickable) {
          if (activeBonusSystemBonusId) {
            await unselectBonusSystemBonus({
              bonusId: activeBonusSystemBonusId
            });
          }
          setLocalStorageValue(
            LOCAL_STORAGE_KEYS.BONUS_SLIDER_ACTIVE_BONUS,
            freespinId
          );
        }
        push(deposit);
      } else if (isShowWagerButton()) {
        push(casinoWager);
      } else if (isWagerAvailable()) {
        await handleWagerAccept();
      } else if (isCanAccept()) {
        await handleAccept();
      } else if (isReleased()) {
        let gameIdGM;
        let providerIdGM;

        if (isGameManagement) {
          const {
            games: [{ gameId: launchId }],
            providers: [{ providerId }]
          } = await getGamesByLaunchIds([gameCode], true);

          gameIdGM = launchId;
          providerIdGM = providerId;
        }

        const gameUrl = getGameURL({
          gameCode: gameIdGM || gameId,
          gameProvider: providerIdGM || serviceId,
          playMode: REAL_MODE,
          serviceURL: casinoPage,
          type: 'launch'
        });
        logAction(LOG_ACTIONS.FREESPIN_LAUNCH_GAME, {
          gameUrl,
          freespinId,
          platform
        });
        push(gameUrl);
      }
    },
    [
      isNoWallet,
      isPotential,
      isWagerAvailable,
      isCanAccept,
      isReleased,
      push,
      createWalletPage,
      deposit,
      handleWagerAccept,
      handleAccept,
      getGameURL,
      gameId,
      serviceId,
      casinoPage,
      freespinId,
      platform
    ]
  );

  const formatButtonTitle = useCallback(() => {
    if (isPotential() || isNoWallet()) {
      return 'deposit';
    }
    if (isWagerAvailable()) {
      return outputBetTotal === 0 ? 'start' : 'resume';
    }
    if (isActivation()) {
      return 'activation';
    }
    if (isCanAccept()) {
      return 'accept';
    }
    if (isReleased()) {
      return 'play';
    }
    if (isShowWagerButton()) {
      return 'wager';
    }
    return '';
  }, [
    isPotential,
    isNoWallet,
    isWagerAvailable,
    isActivation,
    isCanAccept,
    isReleased,
    isShowWagerButton,
    outputBetTotal
  ]);
  const bonusExpireTimeInMilliseconds =
    bonusExpireTime?.sec * 1000 || bonusExpireTime?.sec;
  const freespinEndTimeInMilliseconds =
    freespinEndTime?.sec * 1000 || freespinEndTime?.sec;
  const getDetailsList = useCallback(
    () =>
      prepareBonusDetailsList([
        {
          name: t('web.personaloffice.bonuses.game'),
          value: gameName,
          isDisplay: true
        },
        {
          name: t('web.personaloffice.bonuses.free.spins'),
          value: `${freeSpinCount} ${t('bonuses.details.freeSpins')}${
            currency ? `, ${currency}` : ''
          }`,
          isDisplay: true
        },
        {
          name: t('web.personaloffice.bonuses.required.wager'),
          value: `${outputMaxTotal}${currency ? ` ${currency}` : ''}`,
          isDisplay: outputMaxTotal > 0,
          isBonusAmount: true
        },
        {
          name: t('web.personaloffice.bonuses.expiry.date'),
          value: f
            .getDateTime({ date: bonusExpireTimeInMilliseconds })
            ?.toFormat(DATE_FORMATS_TYPES.BONUSES_DATE_FORMAT),
          isDisplay:
            state === freeSpinStates.FREESPIN_ACTVATED ||
            state === freeSpinStates.FREESPIN_ACCEPT_REQUEST ||
            state === freeSpinStates.FREESPIN_RELEASED ||
            state === freeSpinStates.FREESPIN_POTENCIAL
        },
        {
          name: t('web.personaloffice.bonuses.complited'),
          value: f
            .getDateTime({ date: freespinEndTimeInMilliseconds })
            ?.toFormat(DATE_FORMATS_TYPES.BONUSES_DATE_FORMAT),
          isDisplay: state === freeSpinStates.FREE_SPIN_USED
        },
        {
          name: t('web.personaloffice.bonuses.expired.date'),
          value: f
            .getDateTime({ date: bonusExpireTimeInMilliseconds })
            ?.toFormat(DATE_FORMATS_TYPES.BONUSES_DATE_FORMAT),
          isDisplay:
            state === freeSpinStates.FREESPIN_INCOME_NOT_ACHIEVED ||
            state === freeSpinStates.FREESPIN_EXPIRED
        }
      ]),
    [
      f,
      t,
      gameName,
      state,
      maxActivationTime,
      outputMaxTotal,
      currency,
      freeSpinCount,
      bonusExpireTimeInMilliseconds
    ]
  );

  const isWagerEnabled = () =>
    bonus.outputMaxTotal > 0 &&
    (state === freeSpinStates.FREESPIN_WAGERING ||
      state === freeSpinStates.FREESPIN_PROPOSED ||
      state === freeSpinStates.FREESPIN_INCOME_NOT_ACHIEVED ||
      state === freeSpinStates.FREESPIN_EXPIRED);

  const getProgressBarStatus = useCallback(() => {
    if (state === freeSpinStates.FREESPIN_WAGERING) {
      return progressBarStates.ACTIVE;
    }
    if (outputBetTotal === 0 && state === freeSpinStates.FREESPIN_PROPOSED) {
      return progressBarStates.NOT_ACTIVE;
    }
    if (outputBetTotal > 0 && state === freeSpinStates.FREESPIN_PROPOSED) {
      return progressBarStates.DISABLED;
    }
    if (
      state === freeSpinStates.FREESPIN_INCOME_NOT_ACHIEVED ||
      state === freeSpinStates.FREE_SPIN_USED ||
      state === freeSpinStates.FREESPIN_EXPIRED
    ) {
      return progressBarStates.EXPIRED;
    }

    return '';
  }, [state, outputBetTotal]);

  const isCountdownTimerEnabled = () =>
    state === freeSpinStates.FREESPIN_POTENCIAL ||
    state === freeSpinStates.FREESPIN_WAGERING ||
    (state === freeSpinStates.FREESPIN_PROPOSED && outputMaxTotal > 0);

  const getCountdownTimerTitle = () => {
    if (state === freeSpinStates.FREESPIN_POTENCIAL) {
      return t('web.personaloffice.bonuses.timer.time.to.deposit');
    }
    if (
      outputMaxTotal > 0 &&
      (state === freeSpinStates.FREESPIN_WAGERING ||
        state === freeSpinStates.FREESPIN_PROPOSED)
    ) {
      return t('web.personaloffice.bonuses.timer.hurry.up.to.wager');
    }
    if (state === freeSpinStates.FREESPIN_WAGERING) {
      return t('bonuses.countdownTimer.timeForActivity');
    }

    return '';
  };

  const openDetails = useCallback(
    () =>
      !isRequestProcessing || !isActivation()
        ? push(`${casinoBonusDetails}/${freespinId}`)
        : null,
    [push, casinoBonusDetails, isRequestProcessing]
  );

  return {
    acceptFreeSpin,
    isCanAccept,
    isReleased,
    isShowButton,
    isActivation,
    formatButtonTitle,
    getDetailsList,
    isWagerEnabled,
    getProgressBarStatus,
    isCountdownTimerEnabled,
    getCountdownTimerTitle,
    openDetails,
    isRequestProcessing,
    isShowWagerButton
  };
};

export default useFreeSpin;
