import { AText, Box } from '@alf/uikit';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { useGeneralRoutes } from '@namespace/cms';
import { Icon } from '@namespace/icons2';
import styles from './index.module.css';

const NeedHelp = ({ dataRole, needHelpText }) => {
  const { t } = useI18n();
  const { needHelpSection } = useGeneralRoutes();

  return (
    <Box data-role={dataRole} align="center" justify="center" margin="0">
      <Icon
        className={styles.icon}
        name="icons/general/state/info2"
        color="state_info"
        size="s"
      />
      <LocalizedLink to={needHelpSection}>
        <AText
          data-role="withdraw-bonus-notification-text"
          color="state_info"
          breed="R"
          size="m"
        >
          {t(needHelpText)}
        </AText>
      </LocalizedLink>
    </Box>
  );
};

export default NeedHelp;
