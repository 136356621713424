import { useContext, useEffect } from 'react';
import { USER_ROLES, UserContext } from '@namespace/user';
import { useComponentConfig } from '@namespace/cms';
import { GamesContext } from '../../context';
import { useCasinoSettings } from '../useCasinoSettings';

export const useLoadRecommendedGames = (casinoType) => {
  const [gamesState, { GET_RECOMMENDED_GAMES_LIST }] = useContext(GamesContext);
  const { suggesterServiceEntityName } = useCasinoSettings(casinoType);
  const recommendedGamesIsLoaded =
    gamesState.recommendedGames[suggesterServiceEntityName]?.isLoaded;
  const [user] = useContext(UserContext);
  const { role } = user;
  const { showRecommendedCategory } = useComponentConfig();

  useEffect(() => {
    if (
      role === USER_ROLES.USER &&
      !recommendedGamesIsLoaded &&
      showRecommendedCategory
    ) {
      GET_RECOMMENDED_GAMES_LIST(suggesterServiceEntityName);
    }
  }, [
    role,
    recommendedGamesIsLoaded,
    suggesterServiceEntityName,
    GET_RECOMMENDED_GAMES_LIST,
    showRecommendedCategory
  ]);
};
