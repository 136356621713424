import { useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { isUndefined } from 'lodash';

import { DEFAULT_FORM_CONFIG } from '../../constants';

const useFormMethods = (config = {}) => {
  const { setError, setValue, ...methods } = useForm({
    ...DEFAULT_FORM_CONFIG,
    ...config
  });

  const handleSetError = useCallback(
    (field, type, message) => {
      const isOptionsPassed = !isUndefined(type) || !isUndefined(message);
      const options = isOptionsPassed ? { type, message } : undefined;

      setError(field, options);
    },
    [setError]
  );

  const handleSetValue = useCallback(
    (field, value, params = {}) => {
      const options = { ...params };

      options.shouldDirty ??= true;

      setValue(field, value, options);
    },
    [setValue]
  );

  return useMemo(
    () => ({
      ...methods,
      setError: handleSetError,
      setValue: handleSetValue
    }),
    [methods, handleSetError, handleSetValue]
  );
};

export default useFormMethods;
