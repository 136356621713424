import { AText, Box } from '@alf/uikit';
import styles from './index.module.css';
import { useOutcome } from '../../../../store/selectors/outcome';

const TwoField = ({ outcomeId, lineView }) => {
  const outcome = useOutcome(outcomeId);

  if (!outcome?.outcomeParam) {
    return null;
  }

  return (
    <Box
      align="center"
      justify="center"
      className={`${styles.param} ${lineView ? styles[lineView] : ''}`}
    >
      <AText size="s" breed="R">
        {outcome.outcomeParam}
      </AText>
    </Box>
  );
};

export default TwoField;
