import { useCallback } from 'react';
import { isEmpty } from 'lodash';
import useGetUserSegments from '../useGetUserSegments';

const useUserSegmentsIncludes = () => {
  const { allSegmentsNames, userSegmentsNames } = useGetUserSegments();

  const callback = useCallback(
    (contentSegments = []) => {
      const existContentSegments = contentSegments.filter((e) =>
        allSegmentsNames.includes(e)
      );
      if (isEmpty(existContentSegments)) return true;
      return (
        userSegmentsNames.filter((segmentName) =>
          existContentSegments.some((el) => segmentName === el)
        ).length > 0
      );
    },
    [userSegmentsNames, allSegmentsNames]
  );

  return callback;
};

export default useUserSegmentsIncludes;
