import { useContext } from 'react';
import {
  COLOR_SCHEME_DARK,
  COLOR_SCHEME_AUTO,
  COOKIES_KEYS,
  setCookie
} from '@namespace/helpers';
import { ThemesContext } from '../../store/context';
import getAutoColorScheme from '../../utils/getAutoColorScheme';

const useColorScheme = () => {
  const [{ colorScheme }, { SET_COLOR_SCHEME }] = useContext(ThemesContext);

  const setColorScheme = (activeColorScheme) => {
    setCookie(COOKIES_KEYS.COLOR_SCHEME, activeColorScheme, { expires: 365 });

    const selectedColorMode =
      activeColorScheme === COLOR_SCHEME_AUTO
        ? getAutoColorScheme()
        : activeColorScheme;

    setCookie(COOKIES_KEYS.SELECTED_COLOR_MODE, selectedColorMode, {
      expires: 365
    });

    SET_COLOR_SCHEME({
      selectedColorMode, // dark | lite
      activeColorScheme // dark | lite | auto
    });
  };

  const isDarkModeEnabled =
    colorScheme?.selectedColorMode === COLOR_SCHEME_DARK;

  return {
    colorScheme,
    setColorScheme,
    isDarkModeEnabled
  };
};

export default useColorScheme;
