import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useI18n } from '@namespace/i18n';
import DatePickerFormWrapper from '../../common/DatePickerFormWrapper';
import getFormError from '../../utils/getFormError';
import useCustomError from '../../hooks/useCustomError';

const RegDatePickerField = ({
  name,
  label = '',
  format = '',
  placeholder,
  formProps = {},
  rules = {},
  classNames = {},
  disabled = false,
  customError = '',
  dataRole,
  size
}) => {
  const { minDateOffset, maxDateOffset } = formProps;
  const { t, f } = useI18n();
  const DATE_FORMATS_TYPES = f.getFormats();
  const methods = useFormContext();
  const {
    control,
    watch,
    formState: { errors },
    clearErrors,
    setError
  } = methods;
  const { errorMessage, customErrorHandler } = useCustomError(customError);
  const minDate = useMemo(
    () =>
      f
        .getDateTime()
        .minus(minDateOffset)
        .toJSDate(),
    [f, minDateOffset]
  );
  const maxDate = useMemo(
    () =>
      f
        .getDateTime()
        .minus(maxDateOffset)
        .toJSDate(),
    [f, maxDateOffset]
  );

  const dt = watch(name);
  const date = useMemo(() => {
    if (dt) {
      return f
        .getDateTime({
          date: dt,
          format: DATE_FORMATS_TYPES.CLIENT_API,
          fromUTC: false // UA-4963
        })
        .toJSDate();
    }
    return f
      .getDateTime()
      .minus(maxDateOffset)
      .toJSDate();
  }, [dt, f, DATE_FORMATS_TYPES, maxDateOffset]);

  const handleEvent = useCallback(
    (newDate) => {
      if (typeof newDate !== 'object') {
        return newDate || '';
      }

      return f
        .getDateTime({ date: newDate, withTZ: false })
        .toFormat(DATE_FORMATS_TYPES.CLIENT_API);
    },
    [DATE_FORMATS_TYPES, f]
  );

  return (
    <DatePickerFormWrapper
      dataRole={dataRole}
      cancelLabel={t('datePicker.cancel') || 'cancel'}
      okLabel={t('datePicker.ok') || 'ok'}
      name={name}
      label={t(label)}
      placeholder={t(placeholder)}
      date={watch(name) ? date : null}
      format={format || DATE_FORMATS_TYPES.CLIENT_API}
      onChange={handleEvent}
      onClick={customErrorHandler}
      clearErrors={clearErrors}
      minDate={minDate}
      maxDate={maxDate}
      control={control}
      rules={{ ...rules }}
      error={getFormError(name, errors, t) || t(errorMessage)}
      size={size}
      disabled={Boolean(disabled)}
      setError={setError}
      minDateMessage={t('datePicker.error.dateBeforeMinimalDate')}
      maxDateMessage={t('datePicker.error.dateAfterMaximalDate')}
      {...classNames}
    />
  );
};

export default RegDatePickerField;
