import { isFunction } from 'lodash';
import { flatten } from 'ramda';
import { COLOR_SCHEME_DARK } from '@namespace/helpers';
import {
  CARD_HASH_SERVICE_KEYS,
  PAYMENT_SERVICE_IDS,
  PAN_STATUS,
  PAN_STATUS_BY_CARD_STATUS
} from '../constants';

export const formatErrorMessage = (errorCode) =>
  `accountingMessages.${
    typeof errorCode === 'object' ? errorCode.code : errorCode
  }`;

export const getErrorMsg = ({ errorCode, error = {} } = {}) =>
  formatErrorMessage(errorCode || error);

export const formatSuccessMessage = (data, successMessage) =>
  isFunction(successMessage) ? successMessage(data) : successMessage;

const parseServices = (
  type,
  paymentSystems,
  paymentServicesDescription,
  currency
) => {
  const availableServiceIds = flatten(Object.values(PAYMENT_SERVICE_IDS));
  const availableServiceDescriptionList = Object.keys(
    paymentServicesDescription
  );
  const filteredSystems = paymentSystems.filter(
    ({ serviceId }) =>
      availableServiceIds.includes(serviceId) &&
      availableServiceDescriptionList.includes(`${serviceId}`)
  );

  return {
    ids: filteredSystems.map(({ serviceId }) => serviceId),
    data: filteredSystems.reduce((acc, system) => {
      const { limits: { max: maxLimit = 0, min: minLimit = 0 } = {} } = system;
      const limits = {
        max: Math.floor(Number(maxLimit)),
        min: Math.ceil(Number(minLimit))
      };
      const { [type]: configByType = {}, ...rest } =
        paymentServicesDescription[system.serviceId] || {};
      acc[system.serviceId] = {
        ...rest,
        ...configByType,
        ...system,
        limits,
        serviceId: system.serviceId,
        currency
      };
      return acc;
    }, {})
  };
};

export const normalizePaymentServices = (
  { withdraw: withdrawServiceList = [], deposit: depositServiceList = [] },
  paymentServicesDescription,
  currency
) => ({
  deposit: parseServices(
    'deposit',
    depositServiceList,
    paymentServicesDescription,
    currency
  ),
  withdraw: parseServices(
    'withdraw',
    withdrawServiceList,
    paymentServicesDescription,
    currency
  )
});

export const getKeyByServiceId = (activeServiceId) => {
  const [key] = flatten(
    Object.entries(PAYMENT_SERVICE_IDS).filter(([, ids]) => {
      return (
        activeServiceId === ids ||
        (Array.isArray(ids) && ids.includes(activeServiceId))
      );
    })
  );
  return key;
};

export const iconPath = (item, selectedColorMode) => {
  const path =
    selectedColorMode === COLOR_SCHEME_DARK ? item['icon-dark'] : item?.icon;
  return path || item?.icon || '';
};

export const isCardHashService = (sid) =>
  CARD_HASH_SERVICE_KEYS.includes(getKeyByServiceId(sid));

export const findCardStatus = (statusList) => {
  const statusKey = Object.keys(statusList).find((key) => statusList[key]);
  return PAN_STATUS[PAN_STATUS_BY_CARD_STATUS[statusKey]];
};

export const getExpMonthYear = (date) => {
  const [month, year] = date.split('/');
  const expYear = year.length === 2 ? `20${year}` : year;

  return {
    expMonth: month,
    expYear
  };
};

/* eslint-disable no-bitwise */
const cardHasDeposit = (cardStatus) =>
  Boolean(cardStatus & PAN_STATUS.FIRST_DEPOSIT);

const cardBlockedByRMT = (cardStatus) =>
  Boolean(cardStatus & PAN_STATUS.BLOCKED_BY_RMT);

const cardBlockedByClient = (cardStatus) =>
  Boolean(cardStatus & PAN_STATUS.BLOCKED_BY_CLIENT);

export const withdrawAllowed = (status) =>
  cardHasDeposit(status) &&
  !cardBlockedByClient(status) &&
  !cardBlockedByRMT(status);
