import { rePatterns } from '../../patterns';

const numberFormatValidator = (
  message = 'validators.numberFormatValidator'
) => ({
  pattern: {
    value: rePatterns.onlyNumbers,
    message
  }
});

export default numberFormatValidator;
