import { useContext, useMemo } from 'react';
import { DeviceContext, getFormFactor } from '@namespace/device';
import { SiteSettingsContext } from '../../store/siteSettings/context';

export const useCashdeskId = () => {
  const { deviceFormFactor } = useContext(DeviceContext);
  const [settings] = useContext(SiteSettingsContext);
  const { siteConfig = {} } = settings;
  const { cashdesks = {} } = siteConfig;

  return useMemo(() => cashdesks[getFormFactor(deviceFormFactor)], [
    deviceFormFactor,
    cashdesks
  ]);
};
