import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@namespace/icons2';
import { uniq } from 'lodash';
import isOverSize from './utils/isOverSize';
import fileSizeFormat from './utils/fileSizeFormat';
import Box from '../Box';
import AText from '../AText';
import styles from './index.module.css';

// todo: recheck mb separate into form field and ui part components ?
const UploadFile = ({
  label = '',
  overSizeLabel = '',
  accept = [],
  name,
  isMultiple = true,
  filesList = [],
  onChange = () => {},
  setWrongFiles = () => {},
  onMaxSize = () => {},
  maxCount = 1,
  onMaxCountExceed = () => {},
  maxSize = 7000000,
  classNames: {
    containerClass = '',
    filesListClass = '',
    filesListItemClass = '',
    uploadDocsButtonClass = ''
  } = {},
  wrongFormatText = '',
  showFilesList = true,
  showUploadButton = true,
  uploadIcon = 'icons/general/action/upload',
  uploadButtonColor = 'btn_quaternary_color_1'
}) => {
  const [overSize, setOverSize] = useState(false);
  const [wrongFormatFiles, setWrongFormatFiles] = useState([]);
  const formRef = useRef();

  const checkSize = (files) => {
    const oversize = isOverSize(files, maxSize);
    setOverSize(oversize);
    onMaxSize(oversize);
  };

  const onFileSelect = ({ target }) => {
    if (filesList.length === maxCount) {
      return;
    }
    if (filesList.length + target.files.length > maxCount) {
      onMaxCountExceed();
    }

    const filesArray = Array.from(target.files).reduce(
      (acc, item) => {
        const path = item.name.split('.');
        const extension = `.${path.at(-1)}`;
        const isWrongFormat = !accept.includes(extension.toLowerCase());
        setWrongFormatFiles((prevState) =>
          isWrongFormat ? uniq([...prevState, item.name]) : prevState
        );

        return filesList.findIndex(
          ({ name: fileName, lastModified }) =>
            lastModified === item.lastModified && fileName === item.name
        ) === -1
          ? [...acc, item]
          : acc;
      },
      [...filesList]
    );

    const newFiles = filesArray.splice(0, maxCount);
    onChange(newFiles);
    formRef.current.reset();
  };

  const onFileRemove = (fileName, lastModified) => {
    const newFiles = filesList.filter(
      (file) => !(fileName === file.name && lastModified === file.lastModified)
    );
    setWrongFormatFiles((prevState) =>
      prevState.filter((file) => fileName !== file)
    );
    onChange(newFiles);
    formRef.current.reset();
  };

  useEffect(() => {
    setWrongFiles(wrongFormatFiles);
  }, [wrongFormatFiles]);

  useEffect(() => {
    checkSize(filesList);
  }, [filesList]);

  return (
    <Box className={containerClass} direction="column" align="center">
      {filesList.length > 0 && showFilesList && (
        <Box
          component="ul"
          className={`${styles.filesList} ${filesListClass}`}
          direction="column"
          padding="0 ms"
          top="l"
        >
          {filesList.map(({ size, name: fileName, lastModified }) => {
            const isWrongFormat = wrongFormatFiles.includes(fileName);

            return (
              <Box
                component="li"
                justify="between"
                align="center"
                className={`${styles.filesListItem} ${filesListItemClass}`}
                key={`${fileName}-${lastModified}`}
              >
                <Box direction="column">
                  <AText size="m">
                    {`${fileName} (${fileSizeFormat(size)})`}
                  </AText>
                  {isWrongFormat && (
                    <AText size="m" breed="R" color="state_error">
                      {wrongFormatText}
                    </AText>
                  )}
                </Box>
                <Icon
                  name="icons/general/action/close"
                  size="m"
                  className={styles.iconClose}
                  color={
                    isWrongFormat
                      ? 'btn_quaternary_color_2'
                      : 'btn_quaternary_color_4'
                  }
                  onClick={() => onFileRemove(fileName, lastModified)}
                />
              </Box>
            );
          })}
        </Box>
      )}
      {overSize && (
        <AText
          breed="R"
          component="span"
          color="state_error"
          className={styles.overSizeError}
        >
          {overSizeLabel}
        </AText>
      )}
      <Box className={`${styles.uploadDocsButton} ${uploadDocsButtonClass}`}>
        {showUploadButton && (
          <Box
            className={styles.uploadDocsLabel}
            justify="center"
            align="center"
          >
            <Icon
              name={uploadIcon}
              color={uploadButtonColor}
              size="s"
              className={styles.uploadDocsIcon}
            />
            <AText size="m" breed="BU" color={uploadButtonColor}>
              {` ${label}`}
            </AText>
          </Box>
        )}
        <form ref={formRef}>
          <input
            name={name}
            type="file"
            multiple={isMultiple}
            className={styles.uploadInput}
            onChange={onFileSelect}
            accept={accept}
          />
        </form>
      </Box>
    </Box>
  );
};

UploadFile.propTypes = {
  label: PropTypes.string.isRequired,
  overSizeLabel: PropTypes.string.isRequired,
  accept: PropTypes.array,
  name: PropTypes.string,
  isMultiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  setWrongFiles: PropTypes.func,
  onMaxSize: PropTypes.func,
  maxCount: PropTypes.number,
  maxSize: PropTypes.number,
  classNames: PropTypes.shape({
    containerClass: PropTypes.string,
    filesListClass: PropTypes.string,
    filesListItemClass: PropTypes.string
  }),
  wrongFormatText: PropTypes.string
};

export default UploadFile;
