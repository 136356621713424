import { useCallback, useState, useContext } from 'react';
import { useNotifications } from '@namespace/notifications';
import { useI18n } from '@namespace/i18n';
import { SWPaymentsContext } from '../../store/context';
import { getTaxes, getTaxesNew } from '../../api/taxes';
import { formatErrorMessage } from '../../utils';
import { useGetPaymentService } from '../../store/selectors/useGetPaymentService';

export const useTaxApi = (isNewTaxAPI = false) => {
  const [isFetching, setIsFetching] = useState(false);
  const [{ activeServiceId, activePaymentType }] = useContext(
    SWPaymentsContext
  );
  const { currency } = useGetPaymentService();
  const { errorNotification } = useNotifications();
  const { t } = useI18n();

  const apiRequest = isNewTaxAPI ? getTaxesNew : getTaxes;

  const loadTaxes = useCallback(
    async (amount) => {
      try {
        setIsFetching(true);
        const responseData = await apiRequest({
          amount,
          currency,
          serviceId: activeServiceId,
          paymentType: activePaymentType
        });
        return responseData;
      } catch (response) {
        const { errorCode } = response;
        errorNotification(t(formatErrorMessage(errorCode)));
      } finally {
        setIsFetching(false);
      }
      return null;
    },
    [
      activePaymentType,
      activeServiceId,
      apiRequest,
      currency,
      errorNotification,
      t
    ]
  );

  return {
    loadTaxes,
    isFetching
  };
};
