import { toFinite, toNumber } from 'lodash';

const fileSizeFormat = (value) => {
  const numericValue = toNumber(value);

  if (!toFinite(numericValue)) throw new Error('Invalid value passed');

  const UNITS = ['B', 'kB', 'MB'];
  const PORTION = 1024;
  const ratio = Math.floor(Math.log(value) / Math.log(PORTION));
  const unit = UNITS[ratio];
  const convertedSize = (value / PORTION ** ratio).toFixed(2) * 1;

  return `${convertedSize} ${unit}`;
};

export default fileSizeFormat;
