import { post } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

export const getNovaPoshtaParams = (props = {}) =>
  post(
    'https://api.novaposhta.ua/v2.0/json/',
    { ...props, apiKey: props.apiKey },
    { 'Content-Type': 'application/json' },
    'same-origin',
    'cors'
  )
    .then(camelizeKeys)
    .then((response) => (response?.data ? response.data : []));
