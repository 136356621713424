import { useState, useEffect, useCallback } from 'react';
import { camelizeKeys } from 'humps';
import { isEmpty } from 'ramda';
import { useNotifications } from '@namespace/notifications';
import { socketSend } from '@namespace/socket';
import { useI18n } from '@namespace/i18n';
import { commandsMap, API_SOCKET_ENDPOINT } from '../../constants';

const useLimits = (type, defaultState = {}) => {
  const { successNotification, errorNotification } = useNotifications();
  const [limitData, setLimitData] = useState(defaultState);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { t } = useI18n();

  const setData = useCallback(
    (data) => {
      if (!isEmpty(data[type])) {
        setLimitData(data[type]);
      }
    },
    [setLimitData, type]
  );

  const saveLimit = useCallback(
    async (data) => {
      setIsLoading(true);

      try {
        const response = await socketSend({
          cmd: API_SOCKET_ENDPOINT,
          data: {
            mutation: {
              [commandsMap[type]]: {
                variables: data
              }
            }
          }
        });

        setData(camelizeKeys(response.data));
        successNotification(t('responsibleGambling.limits.saveSuccess'));
      } catch {
        errorNotification(t('responsibleGambling.limits.saveError'));
      } finally {
        setIsLoading(false);
      }
    },
    [type, setData, t, successNotification, errorNotification]
  );

  useEffect(() => {
    setIsLoaded(false);
    socketSend({
      cmd: 'do_proto_v1',
      data: {
        query: {
          [commandsMap[type]]: {}
        }
      }
    }).then((data) => {
      setData(camelizeKeys(data.data));
      setIsLoaded(true);
    });
  }, [type, setData]);
  return [limitData, saveLimit, { isLoading }, isLoaded];
};

export default useLimits;
