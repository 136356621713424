import { useContext, useEffect, useState } from 'react';
import {
  getWidgetInitParams,
  IS_WIDGET,
  WidgetContext
} from '@namespace/widget';
import { post } from '@namespace/helpers';
import { getSocketInstance, socketConnect } from '@namespace/socket';
import {
  MAX_SOCKET_RECONNECTION_ATTEMPTS_FIRST_TIME,
  SOCKET_URLS
} from '@namespace/socket/src/constants';
import { bulletConnect } from '@namespace/socket/src/instances/bullet';

const createConnectionHandler = ({ setIsFatalError, setIsDisconnected }) => {
  // can't rely on internal rws `retriesCount` since it reports incorrect number on close event (it actually has a bug in `_handleClose` method where it first calls async `_connect` and then calls `onclose` event handlers, where handlers are called with updated state from `_connect`)
  let failedRetriesCount = 0;

  // don't use arrow fn or `this.isFirstOpen` will break
  return function connectionHandler(readyState, type) {
    switch (type) {
      case 'open': {
        failedRetriesCount = 0;
        setIsFatalError(false);
        setIsDisconnected(false);
        break;
      }

      case 'close': {
        if (
          failedRetriesCount === MAX_SOCKET_RECONNECTION_ATTEMPTS_FIRST_TIME &&
          this.isFirstOpen
        ) {
          // todo Error page on initial connection failure.
          //  Need to rename parameter to "MAX_SOCKET_INITIAL_RECONNECTION_ATTEMPTS = 3" and move its declaration to wherever 'REACT_APP_MAX_SOCKET_RECONNECTION_ATTEMPTS' is declared. Or, maybe, it could be better to remove this extra parameter at all and follow 'REACT_APP_MAX_SOCKET_RECONNECTION_ATTEMPTS' in all cases.
          //  Also, we can make reconnection attempts infinite but with significant interval between series of attempts using 'setTimeout', so, for example, after 10 unsuccessful attempts we wait 5 minutes and try again 10 times. Or not.
          // this.connection.close();
          console.log('isFatalError = true (initial connection failure)');
        }

        if (failedRetriesCount >= this.maxRetries) {
          setIsDisconnected(true);
        }

        failedRetriesCount++;
        break;
      }

      default:
        break;
    }
  };
};

const isCookieNeeded =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const useSocketConnect = () => {
  const [isFatalError, setIsFatalError] = useState(false);
  const [isDisconnected, setIsDisconnected] = useState(false);

  const [isCookieSet, setIsCookieSet] = useState(!isCookieNeeded);

  useEffect(() => {
    if (isCookieNeeded) {
      post('/live/count/')
        .catch(console.error)
        .finally(() => setIsCookieSet(true));
    }
  }, []);

  useEffect(() => {
    if (isCookieSet) {
      const { ssid } = getWidgetInitParams();
      const connectionHandler = createConnectionHandler({
        setIsFatalError,
        setIsDisconnected
      });
      socketConnect({ ssid, connectionHandler });
    }
  }, [isCookieSet]);

  useEffect(() => {
    if (isCookieSet) {
      bulletConnect();
    }
  }, [isCookieSet]);

  const [{ ssid: widgetSSID }] = useContext(WidgetContext);
  const ssid = IS_WIDGET ? widgetSSID || 'null' : undefined;

  useEffect(() => {
    if (IS_WIDGET) {
      getSocketInstance().setUrl(`${SOCKET_URLS.SOCKET}?SSID=${ssid}`);
    }
  }, [ssid]);

  return {
    isFatalError,
    isDisconnected,
    setIsDisconnected
  };
};
