import { useCallback, useEffect } from 'react';
import { scrollTo, window } from '@namespace/helpers';
import { parseEventData } from '../utils';

export const useBetGamesControl = (frame) => {
  const scrollSubscribe = useCallback(() => {
    frame?.current?.contentWindow?.postMessage(
      JSON.stringify({
        type: 'onScroll',
        payload: {
          height: window.innerHeight,
          scrollY: window.scrollY,
          iFrameTopOffset: frame?.current?.offsetTop
        }
      }),
      '*'
    );
  }, [frame]);

  useEffect(() => {
    return () => {
      window.removeEventListener('scroll', scrollSubscribe);
    };
  }, []);

  return useCallback(
    (event, callbacks) => {
      const { setHeightHandler = () => {} } = callbacks;
      const eventData = parseEventData(event.data);
      const { payload, type, data } = eventData;

      // === betgames basic integration ===
      if (data?.height) {
        setHeightHandler(data.height);

        return;
      }

      // === betgames twain-sports integration ===
      if (type === 'newHeight' && payload?.height) {
        setHeightHandler(payload.height);
      } else if (type === 'getViewPort' && frame?.current) {
        event.source.postMessage(
          JSON.stringify({
            type: 'getViewPort',
            payload: {
              width: window.innerWidth,
              height: window.innerHeight,
              iFrameTopOffset: frame?.current?.offsetTop,
              scrollY: window.scrollY
            }
          })
        );
      } else if (type === 'scrollTo') {
        scrollTo({ top: payload?.scrollTo });
      } else if (type === 'iframeTop' && frame?.current) {
        scrollTo({
          top: Number(payload?.topOffset) + frame?.current?.offsetTop
        });
      } else if (type === 'subscribeScroll' && frame?.current) {
        window.addEventListener('scroll', scrollSubscribe);
      } else if (type === 'unsubscribeScroll') {
        window.removeEventListener('scroll', scrollSubscribe);
      }
    },
    [frame, scrollSubscribe]
  );
};
