const getCodeByISOCode = (items = [], value) =>
  items.find((i) => i.iso1Code === value)?.phoneCode;

const phoneComplexValidation = (
  { countries },
  { required, minLength, maxLength }
) => ({
  validate: ({ isoCode, number }) => {
    const countryCode = getCodeByISOCode(countries, isoCode);
    const value = number.replace(countryCode, '');

    if (required && !value) {
      return 'validators.requiredValidator';
    }

    if (minLength && value.length < minLength) {
      return `validators.minLengthValidator {${minLength}}`;
    }

    if (maxLength && value.length > maxLength) {
      return `validators.maxLengthValidator {${maxLength}}`;
    }

    return true;
  }
});

export default phoneComplexValidation;
