import { get, urlWithParams } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

const getCasinoRounds = ({
  startDate,
  endDate,
  gameId,
  serviceId,
  categoryId,
  page = 1,
  limit = 10
}) => {
  const params = {
    ...(gameId && gameId !== 'all' && { game_id: gameId }),
    ...(serviceId && serviceId !== 'all' && { provider_id: serviceId }),
    date_to: `${endDate} 00:00:00`,
    per_page: limit,
    page,
    date_from: `${startDate} 00:00:00`,
    category_id: categoryId
  };

  return get(
    urlWithParams(`/service/transaction-history/history/rounds`, params)
  ).then(({ data }) => camelizeKeys(data.groups));
};

export default getCasinoRounds;
