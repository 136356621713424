import { useJackpot } from '../../context/selectors';

export const useGetGameJackpot = (providerServiceId, jackpotCode) => {
  const jackpot = useJackpot(providerServiceId, jackpotCode);

  if (!providerServiceId || !jackpot) {
    return null;
  }

  const [lastKey] = Object.keys(jackpot)
    .map((key) => Number(key.replace('level', '')))
    .sort((a, b) => b - a);

  return jackpot[`level${lastKey}`]?.currentWinAmount;
};
