import { document } from '@namespace/helpers';

const loadWebim = ({ accountName }) => {
  return new Promise((resolve, reject) => {
    if (!document.getElementById('webimScript')) {
      const script = document.createElement('script');
      script.src = `https://${accountName}.webim.ru/js/button.js`;
      script.id = 'webimScript';
      script.onload = resolve;
      script.onerror = reject;
      script.defer = true;
      document.head.append(script);
    } else {
      resolve();
    }
  });
};

export default loadWebim;
