import { all, collectBy, pipe, prop, reject } from 'ramda';
import clsx from 'clsx';
import Box from '../../../../../../../Box';
import { ContentRows } from '../ContentRows';
import { GroupHeader } from '../GroupHeader';
import styles from './index.module.css';

export const ContentGroups = ({
  options,
  groupBy,
  groupsData = {},
  value,
  onRowSelect,
  size,
  intentType,
  className,
  classNames = {},
  dataRole
}) => {
  const groups = pipe(
    collectBy(prop(groupBy)),
    reject(all(prop('hidden')))
  )(options);

  return groups.map((group) => {
    const { [groupBy]: groupKey } = group[0];
    const { title, iconElement } = groupsData[groupKey];

    return (
      <Box
        key={groupKey}
        direction="column"
        className={clsx(className, styles.groupContainer)}
      >
        <GroupHeader
          title={title}
          iconElement={iconElement}
          size={size}
          intentType={intentType}
        />
        <ContentRows
          options={group}
          value={value}
          onRowSelect={onRowSelect}
          size={size}
          intentType={intentType}
          className={clsx(styles.groupContent, classNames.groupContentRow)}
          dataRole={dataRole}
        />
      </Box>
    );
  });
};
