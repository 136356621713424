import { useCallback, useContext, useEffect, useState } from 'react';
import {
  getLocalStorageValue,
  getSessionStorageValue,
  LOCAL_STORAGE_KEYS,
  removeSessionStorageItem,
  setLocalStorageValue
} from '@namespace/helpers';
import {
  LOG_ACTIONS,
  logAction,
  logPostConversionTracking
} from '@namespace/analytics';

import { useGeneralRoutes, useOtherSettings } from '@namespace/cms';
import { StepperContext } from '@namespace/common';
import { useSearchParams } from '@namespace/router';
import { useLogin, useLoginSuccessRedirectLink } from '@namespace/user';
import {
  FORM_COMPONENTS_TYPES,
  FORM_ERRORS_TYPES,
  FORM_VALIDATION_TYPES,
  FormBuilder,
  setFormErrors,
  useFormMethods
} from '@namespace/forms';
import { useI18n } from '@namespace/i18n';
import { HTMLSnippet } from '@namespace/snippets';
import {
  LOGIN_DATA_COMPONENTS_TYPE,
  loginDataComponentsMap
} from './constants';
import { useReCaptcha } from '../../../../hooks/useReCaptcha';
import { reCaptchaHeaders } from '../../../../hooks/useReCaptcha/utils/reCaptchaHeaders';
import { RECAPTCHA_PLACE, RECAPTCHA_VERSION } from '../../../../constants';
import styles from './index.module.css';

const Welcome = ({
  usernameType = 'email',
  snippetId = '', // todo move param in fallbacks
  labels = {},
  placeholders = {},
  requiredFields = {},
  showSections = {},
  textFields = {},
  classNames = {},
  onStepSuccess,
  onExternalError,
  size = 'm',
  isOpenFromPopup,
  forgotPasswordProps = {}
}) => {
  const { t } = useI18n();
  const [rememberMe, setRememberMe] = useState(false);
  const { register } = useGeneralRoutes();
  const {
    stepper: {
      formData: { email: username, password, captcha }
    }
  } = useContext(StepperContext);
  const [captchaRetryCount, setCaptchaRetryCount] = useState(0);
  const [showReCaptchaSection, setShowReCaptchaSection] = useState(false);
  const fastLoginGlobalErrorMessage = getSessionStorageValue(
    'fastLoginError',
    ''
  );

  const loadCaptchaToken = useReCaptcha(RECAPTCHA_PLACE.LOGIN);

  const {
    username: fastLoginUserName = '',
    password: fastLoginPassword = ''
  } = getSessionStorageValue('loginFormData', {});

  useEffect(() => {
    setRememberMe(getLocalStorageValue(LOCAL_STORAGE_KEYS.REMEMBER_ME, false));
  }, [rememberMe]);

  const formMethods = useFormMethods({
    defaultValues: {
      email: username || fastLoginUserName,
      password: password || fastLoginPassword,
      captcha: captcha || ''
    }
  });

  const { postConversionType, reCaptchaConfig = {} } = useOtherSettings();

  const { reCaptchaRegexErrors = [] } = reCaptchaConfig;

  const { setError } = formMethods;

  const {
    usernameLabel = t('login.input.email'),
    passwordLabel = t('login.input.password')
  } = labels;
  const {
    usernamePlaceholder = t('login.input.emailPlaceholder'),
    passwordPlaceholder = t('login.input.passwordPlaceholder')
  } = placeholders;
  const {
    usernameRequired = true,
    passwordRequired = true,
    usernameNativeRequired = false,
    passwordNativeRequired = false
  } = requiredFields;
  const {
    showRememberMeSection = false,
    showForgotPasswordSection = false,
    showRegisterSection = false,
    showCaptchaSection = false
  } = showSections;
  const {
    formClassName = '',
    usernameClassName = '',
    usernameWrapperClassName = '',
    passwordClassName = '',
    passwordWrapperClassName = '',
    buttonClassName = '',
    inputLabelClassName = '',
    inputClassName = '',
    inputWrapperClassName = '',
    forgotPassClassName = '',
    registerClassName = '',
    rememberMeClassName = '',
    snippetClassName = '',
    haveAnAccountClassName = '',
    haveAnAccountTitleClassName = ''
  } = classNames;
  const { buttonText = t('login.btn.submit') } = textFields;

  useEffect(
    () => () => {
      removeSessionStorageItem('fastLoginError');
      removeSessionStorageItem('loginFormData');
    },
    []
  );

  useEffect(() => {
    if (fastLoginGlobalErrorMessage) {
      setFormErrors({
        errors: [
          {
            key: FORM_ERRORS_TYPES.GLOBAL_ERROR,
            error: true,
            message: fastLoginGlobalErrorMessage
          }
        ],
        setError
      });
    }
  }, [fastLoginGlobalErrorMessage, setError]);

  const handleError = useCallback(
    (errorCode, errors) => {
      if (errors) {
        if (showCaptchaSection) {
          setCaptchaRetryCount((state) => state + 1);
        }
        if (reCaptchaRegexErrors?.includes(errorCode)) {
          setShowReCaptchaSection(true);
        }
        if (!onExternalError || onExternalError(errorCode) !== false) {
          setFormErrors({
            errors,
            setError
          });
          logAction(LOG_ACTIONS.LOGIN_FAILED, { errorCode });
        }
      }
    },
    [onExternalError, setError, showCaptchaSection]
  );

  const { from: locationFrom } = useSearchParams();

  const handleSuccess = (attributes) => {
    onStepSuccess?.();
    logPostConversionTracking(LOG_ACTIONS.POST_LOGIN, postConversionType);
    logAction(LOG_ACTIONS.LOGIN, {
      userId: attributes.user.id,
      location: locationFrom || ''
    });
  };

  const { login, isLoading } = useLogin(
    handleSuccess,
    handleError,
    loadCaptchaToken
  );
  const successRedirectLink = useLoginSuccessRedirectLink();

  const submit = useCallback(
    (obj) => {
      // eslint-disable-next-line no-shadow
      const { email: username, password, captcha, reCaptchaToken } = obj;
      const reCaptchaParams = reCaptchaHeaders(
        reCaptchaToken,
        RECAPTCHA_PLACE.LOGIN,
        RECAPTCHA_VERSION.V2
      );
      const newCredentials = {
        username,
        password,
        captcha,
        reCaptchaParams,
        showReCaptchaSection,
        nextPage: !isOpenFromPopup && successRedirectLink
      };
      login(newCredentials);
    },
    [isOpenFromPopup, login, successRedirectLink]
  );

  const checkRememberMe = useCallback(() => {
    setLocalStorageValue(LOCAL_STORAGE_KEYS.REMEMBER_ME, !rememberMe);
    setRememberMe(!rememberMe);
  }, [rememberMe]);

  const componentsList = [
    {
      type: FORM_COMPONENTS_TYPES.EMAIL_INPUT_FIELD,
      inputType: usernameType === 'email' ? 'email' : 'text',
      dataRole: 'login-page-login-input',
      name: 'email',
      label: usernameLabel,
      placeholder: usernamePlaceholder,
      required: usernameNativeRequired,
      classNames: {
        inputClassName: `${inputClassName} ${usernameClassName}`,
        labelClassName: inputLabelClassName,
        wrapperClassName: `${inputWrapperClassName} ${usernameWrapperClassName}`
      },
      size,
      validationTypes: usernameRequired ? [FORM_VALIDATION_TYPES.REQUIRED] : [],
      autoComplete: rememberMe ? 'on' : 'off'
    },
    {
      type: FORM_COMPONENTS_TYPES.PASSWORD_FIELD,
      dataRole: 'login-page-password-input',
      name: 'password',
      label: passwordLabel,
      placeholder: passwordPlaceholder,
      required: passwordNativeRequired,
      classNames: {
        inputClassName: `${passwordClassName} ${inputClassName}`,
        labelClassName: inputLabelClassName,
        wrapperClassName: `${passwordWrapperClassName} ${inputWrapperClassName} `
      },
      rulesList: [],
      size,
      validationTypes: passwordRequired ? [FORM_VALIDATION_TYPES.REQUIRED] : [],
      isOnChangeValidation: true,
      autoComplete: rememberMe ? 'on' : 'off'
    }
  ];

  if (showCaptchaSection) {
    componentsList.push({
      type: FORM_COMPONENTS_TYPES.CAPTCHA_FIELD,
      name: 'captcha',
      placeholder: 'login.captcha.placeholder',
      allowClean: true,
      autoComplete: 'off',
      classNames: {
        captchaClassName: styles.captchaWrapper
      },
      size: 'm',
      errorCode: captchaRetryCount, // needed to refresh value of captcha
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED]
    });
  }

  if (showRememberMeSection) {
    componentsList.push({
      type: LOGIN_DATA_COMPONENTS_TYPE.REMEMBER_ME,
      checked: rememberMe,
      onChange: checkRememberMe,
      className: rememberMeClassName
    });
  }

  if (showForgotPasswordSection) {
    componentsList.push({
      type: LOGIN_DATA_COMPONENTS_TYPE.FORGOT_PASSWORD,
      linkText: t('login.helpers.forgotPassword'),
      className: forgotPassClassName,
      isRememberMeEnable: showRememberMeSection,
      ...forgotPasswordProps
    });
  }

  if (showReCaptchaSection) {
    componentsList.push({
      type: FORM_COMPONENTS_TYPES.RE_CAPTCHA,
      isOnChangeValidation: true,
      name: 'reCaptchaToken'
    });
  }

  componentsList.push({
    type: FORM_COMPONENTS_TYPES.SUBMIT_BUTTON,
    dataRole: 'login-page-submit-btn',
    value: buttonText,
    classNames: {
      className: buttonClassName
    },
    size: 'xl',
    isNextButton: true
  });

  if (showRegisterSection) {
    componentsList.push({
      type: LOGIN_DATA_COMPONENTS_TYPE.REGISTER,
      linkText: t('login.helpers.register'),
      text: t('login.helpers.noAccount'),
      to: `${register}?from=login-page`,
      classNames: {
        registerClassName,
        haveAnAccountClassName,
        haveAnAccountTitleClassName
      }
    });
  }

  return (
    <>
      {Boolean(snippetId) && (
        <HTMLSnippet className={`${snippetClassName}`} snippetId={snippetId} />
      )}
      <FormBuilder
        components={componentsList}
        formMethods={formMethods}
        formProps={{ isLoading }}
        componentsMap={loginDataComponentsMap}
        onSubmit={submit}
        wrapperClassName={formClassName}
      />
    </>
  );
};

export default Welcome;
