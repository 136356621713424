import { AText, Box } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { useAdvFilterInnerCtxActions } from '../../store';
import styles from './index.module.css';

export const Chip = ({ chip }) => {
  const { REMOVE_FILTER_ITEM } = useAdvFilterInnerCtxActions();

  const onDelClick = () => {
    REMOVE_FILTER_ITEM(chip);
  };

  return (
    <Box align="center" padding="xs" gap="s" className={styles.root}>
      <AText breed="B" size="s" color="sm_txt_color_1">
        {chip?.txt}
      </AText>
      <Box onClick={onDelClick} className={styles.delIcon}>
        <Icon
          name="icons/general/action/close"
          size="xs"
          color="sm_txt_color_5"
        />
      </Box>
    </Box>
  );
};
