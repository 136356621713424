import { useRef, useEffect } from 'react';

const useDidUpdateEffect = (effect, dependencies) => {
  const hasMounted = useRef(false);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }
    effect();
    // we want to omit "effect" here, otherwise "useEffect" would be called each tick
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useDidUpdateEffect;
