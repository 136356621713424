import { orderBy, isString } from 'lodash';
import { SET_CONFIG, SET_SERVICES_LIMITS } from '../actions';

const stripMinAmount = ({ minAmount, ...countryConfig }) => {
  if (minAmount) {
    return {
      ...countryConfig,
      minAmount: String(minAmount)
        ? String(minAmount).replace(/\D/g, '')
        : minAmount
    };
  }
  return countryConfig;
};

const parsePayMethodAmount = (amount) =>
  parseInt(isString(amount) ? amount.replace(/\D/g, '') : amount, 10);

const filterPaymentSystems = (paymentSystems, userCountry = '', userId) => {
  const systems = paymentSystems
    .map((item = {}) => {
      const { availableCountries = {}, ...rest } = item;
      const globalConfig = availableCountries?.['*'] || {};
      const countryConfig = availableCountries?.[userCountry] || {};
      const payMethods =
        countryConfig.payMethods || globalConfig.payMethods || [];
      const filterPayMethods = payMethods.filter((pm) =>
        Boolean(!pm?.utester?.length || pm?.utester?.includes(userId))
      );

      return {
        ...stripMinAmount(globalConfig),
        ...stripMinAmount(countryConfig),
        payMethods: filterPayMethods.map((payMethod) => {
          const { minWithdraw, maxWithdraw, minAmount, maxAmount } = payMethod;
          const minWithdrawNum = parsePayMethodAmount(minWithdraw);
          const maxWithdrawNum = parsePayMethodAmount(maxWithdraw);
          const minAmountNum = parsePayMethodAmount(minAmount);
          const maxAmountNum = parsePayMethodAmount(maxAmount);

          return {
            ...payMethod,
            minWithdrawNum,
            maxWithdrawNum,
            minAmountNum,
            maxAmountNum
          };
        }),
        ...rest
      };
    })
    .filter(
      ({ utester = [] }) =>
        !utester.length || (utester.length > 0 && utester.includes(userId))
    );

  return orderBy(systems, 'order');
};

export default (state, action) => {
  const { paymentSystems, countryId, userId, limits = {} } = action.payload;

  switch (action.type) {
    case SET_CONFIG:
      return {
        ...state,
        paymentSystems: filterPaymentSystems(
          paymentSystems || [],
          countryId ? countryId.toLowerCase() : '',
          userId
        )
      };

    case SET_SERVICES_LIMITS:
      return {
        ...state,
        servicesLimits: { ...limits }
      };

    default:
      return state;
  }
};
