import { useCallback, useContext } from 'react';
import { OnboardingContext } from '../../store/context';
import { useAfterTourRedirect } from '../useAfterTourRedirect';

export const useEndTourAction = ({ shouldRedirect = true } = {}) => {
  const [
    { isInterrupted, steps, stepIndex },
    { RUN, SET_INTERRUPTED }
  ] = useContext(OnboardingContext);
  const isLastStep = steps.length === stepIndex + 1;
  const afterTourRedirect = useAfterTourRedirect(shouldRedirect);

  return useCallback(() => {
    if (isInterrupted) {
      RUN(false);
      SET_INTERRUPTED(false);
      afterTourRedirect();
      return;
    }

    if (isLastStep) {
      afterTourRedirect();
      RUN(false);
    } else {
      SET_INTERRUPTED(true);
    }
  }, [RUN, SET_INTERRUPTED, afterTourRedirect, isInterrupted, isLastStep]);
};
