import { useI18n, LocalizedLink } from '@namespace/i18n';
import { AText, Link } from '@alf/uikit';
import { useGeneralRoutes } from '@namespace/cms';
import clsx from 'clsx';
import TextNotification from '../../../common/DocumentStatusInformation/TextNotification';
import styles from './index.module.css';

const EmptyProfile = ({ classNames = {} }) => {
  const { t } = useI18n();
  const { profilePage } = useGeneralRoutes();
  const { notificationWrapper = '', link = '' } = classNames;
  return (
    <TextNotification
      className={clsx(notificationWrapper, styles.notification)}
    >
      <AText color="po_txt_color_1" size="m">
        {t('personalOffice.documents.shortRegistration.infoPart1')}
      </AText>
      <Link
        Component={LocalizedLink}
        to={profilePage}
        className={clsx(styles.link, link)}
      >
        {t('personalOffice.documents.shortRegistration.link')}
      </Link>
    </TextNotification>
  );
};

export default EmptyProfile;
