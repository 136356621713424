import { pathOr } from 'ramda';
import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import { MODE } from '../../../constants';
import { useSportsbookSelector } from '../../store';

export const selectCyberSportTournament = (tournamentId) =>
  createSelector(
    pathOr({}, ['tournaments', MODE.PRE_MATCH, 'data', tournamentId]),
    pathOr({}, ['tournaments', MODE.IN_PLAY, 'data', tournamentId]),
    (preMatchTournament, liveTournament) => ({
      ...preMatchTournament,
      ...liveTournament,
      categoryId: tournamentId,
      categoryName:
        preMatchTournament.tournamentName || liveTournament.tournamentName,
      eventCount:
        !isEmpty(preMatchTournament) && !isEmpty(liveTournament)
          ? preMatchTournament.eventCount + liveTournament.eventCount
          : preMatchTournament.eventCount || liveTournament.eventCount
    })
  );

export const useCyberSportTournament = (tournamentId) =>
  useSportsbookSelector(selectCyberSportTournament, [tournamentId]);
