import types from '../types';

export const initialState = {
  tournamentsLeaderboards: {}
};

export const reducer = {
  [types.SET_LEADERBOARD_INFO]: (state, { leaderboardInfo, tournamentId }) => ({
    ...state,
    tournamentsLeaderboards: {
      ...state.tournamentsLeaderboards,
      [tournamentId]: leaderboardInfo
    }
  })
};
