export const PAYMENT_METHODS = {
  DEPOSIT: 'deposit',
  ABON: 'abon',
  CORVUS: 'corvus',
  TR_XPRS: 'transexpress',
  AIR_CASH: 'aircash'
};

export const PAY_METHODS = {
  APPLE_PAY: 10117,
  APPLE_PAY_NEW: 10124,
  MASTERPASS_NEW: 10125,
  MASTERPASS: 10118,
  GOOGLE_PAY_NEW: 10122,
  GOOGLE_PAY: 10132
};

export const PAY_METHODS_ICONS = {
  [PAY_METHODS.APPLE_PAY]: 'payments/applepay',
  [PAY_METHODS.APPLE_PAY_NEW]: 'payments/applepay',
  [PAY_METHODS.MASTERPASS]: 'payments/masterpass',
  [PAY_METHODS.MASTERPASS_NEW]: 'payments/masterpass',
  [PAY_METHODS.GOOGLE_PAY]: 'payments/gpay',
  [PAY_METHODS.GOOGLE_PAY_NEW]: 'payments/gpay'
};
