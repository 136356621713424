import { Box, Button } from '@alf/uikit';
import { CommonLoader } from '@namespace/common';
import { useI18n } from '@namespace/i18n';
import styles from './index.module.css';

const ButtonLoader = ({
  onNextPage,
  isShow,
  className = '',
  isLoading,
  title = 'sportsbook.events.showMore',
  dataRole
}) => {
  const { t } = useI18n();

  if (!isShow || !onNextPage) {
    return null;
  }

  return (
    <Box justify="center" className={`${styles.showMoreWrapper} ${className}`}>
      {isLoading ? (
        <Box align="center" justify="center" className={styles.loaderWrap}>
          <CommonLoader />
        </Box>
      ) : (
        <Button
          data-role={dataRole}
          onClick={onNextPage}
          actionType="color3"
          size="l"
          intent="quaternary"
        >
          {t(title)}
        </Button>
      )}
    </Box>
  );
};

export default ButtonLoader;
