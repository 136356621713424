import { useContext, useEffect } from 'react';
import { keyBy } from 'lodash';
import { getServicesLimits } from '../../api/getServicesLimits';
import { ConfigContext } from '../../store/config';
import { setServicesLimits } from '../../store/config/actions';

const useGetServicesLimits = () => {
  const { configDispatch } = useContext(ConfigContext);

  useEffect(() => {
    (async () => {
      try {
        const { response } = await getServicesLimits();
        if (response) {
          configDispatch(setServicesLimits(keyBy(response, 'serviceId')));
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);
};

export default useGetServicesLimits;
