import { Box } from '@alf/uikit';
import { useI18n, useLocation } from '@namespace/i18n';
import { useComponentConfig, useGeneralRoutes } from '@namespace/cms';
import EventsLineHeader from '../EventsLineHeader';
import EventsLineTitle from '../EventsLineTitle';
import Sorting from '../Sorting';
import Filters from '../Filters';
import EventsLineHeadGroupsFilterDropdown from '../EventsLineHeadGroups/FilterDropdown';
import EventsLineHeadGroups from '../EventsLineHeadGroups';
import { MODE, SPORT_IDS, SPORTS_LINE_TYPES } from '../../constants';
import styles from './index.module.css';

const classNames = {
  containerClass: styles.containerClass,
  triggerTextClass: styles.sortTriggerText
};

export const EventsLineBaseHeader = ({
  lineKey,
  lineType,
  activeSport,
  backButtonSubTitle = '',
  titleCategoryName = '',
  titleCategoryId,
  filter,
  setFilter,
  activeHeadGroups,
  setActiveHeadGroups,
  categories
}) => {
  const { t } = useI18n();
  const { preMatchSport, preMatchEvent, liveEvent } = useGeneralRoutes();
  const { pathname } = useLocation();

  const {
    oddFormats,
    filters,
    sortingView,
    sortingTypes = [],
    lineTitle,
    lineSubTitle,
    isDisplayHeadMarkets = true
  } = useComponentConfig();
  const {
    isShowAll,
    isShowStartingSoon,
    isShowNextMorning,
    default: defaultFilter,
    ...filterConfig
  } = filters || {};

  const { sportId, sportName = '', sportSlug } = activeSport;
  const isCustomFilterEvents = lineType === SPORTS_LINE_TYPES.CUSTOM_FILTER;
  const isCustomFilterCategory = isCustomFilterEvents && categories?.length > 0;
  const isOneEventPage =
    pathname.includes(preMatchEvent) || pathname.includes(liveEvent);

  const showBackBtn =
    (titleCategoryName &&
      sportId !== SPORT_IDS.CYBERSPORT &&
      !isOneEventPage &&
      !isCustomFilterEvents) ||
    backButtonSubTitle;
  const name =
    titleCategoryName && sportId === SPORT_IDS.CYBERSPORT
      ? titleCategoryName
      : sportName;
  const title =
    t(lineTitle) ||
    (showBackBtn ||
    isCustomFilterCategory ||
    (isOneEventPage && titleCategoryId)
      ? `${sportName && `${sportName} | `}${titleCategoryName}`
      : name);
  const subTitle =
    t(lineSubTitle) ||
    backButtonSubTitle || // TODO: NEED TO REFACTOR TITLE LOGIC WITH BACK BUTTON!
    (showBackBtn ||
    isCustomFilterCategory ||
    (isOneEventPage && titleCategoryId)
      ? ''
      : t('sportsbook.eventsTitle.all'));
  const backLink = showBackBtn ? `${preMatchSport}/${sportSlug}` : '';
  const isSortingVisible = Boolean(sortingTypes.length) && sportId;

  return (
    <Box direction="column">
      <EventsLineHeader
        borderBottom={true}
        isExpanded={false}
        className={styles.filterDropdownWrapper}
      >
        <EventsLineTitle
          title={title}
          sportId={sportId}
          categoryId={
            !isCustomFilterCategory && !isOneEventPage && titleCategoryId
          }
          subtitle={subTitle}
          backLink={backLink}
        />
        {isSortingVisible && (
          <Box className={styles.filterDropdownContainer}>
            <Sorting
              lineKey={lineKey}
              sortingTypes={sortingTypes}
              classNames={classNames}
              sortingView={sortingView}
            />
          </Box>
        )}
        <Filters
          filterConfig={filterConfig}
          filterValue={filter}
          isShowStartingSoon={isShowStartingSoon}
          isShowNextMorning={isShowNextMorning}
          isShowAll={isShowAll}
          oddFormats={oddFormats}
          onSetFilter={setFilter}
        />
      </EventsLineHeader>
      <EventsLineHeader isCollapsible={false} key="dark-header">
        <EventsLineHeadGroupsFilterDropdown
          filterConfig={filterConfig}
          filterValue={filter}
          isShowAll={isShowAll}
          isShowStartingSoon={isShowStartingSoon}
          isShowNextMorning={isShowNextMorning}
          onSetFilter={setFilter}
          additionSelectContentStyle={styles.listMaxHeight}
        />
        <EventsLineHeadGroups
          serviceId={MODE.PRE_MATCH}
          sport={activeSport}
          isHeadGroupsSortingEnabled={true}
          activeHeadGroups={activeHeadGroups}
          setActiveHeadGroups={setActiveHeadGroups}
          isDisplayHeadMarkets={isDisplayHeadMarkets}
        />
      </EventsLineHeader>
    </Box>
  );
};
