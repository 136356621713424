import { getCountryByPhoneNumber } from '@namespace/common';

export const withPlusPrefix = (value) =>
  /^\+\d+$/.test(value) ? value : `+${value}`;

function phoneFormField(countries, phoneNumber) {
  if (!countries.length || !phoneNumber) return undefined;

  const matchedCountry = getCountryByPhoneNumber(countries, phoneNumber);
  const code = matchedCountry?.phoneCode ?? '';

  return {
    code,
    isoCode: matchedCountry?.iso1Code ?? '',
    number: phoneNumber.replace('+', '')
  };
}

export default phoneFormField;
