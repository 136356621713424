import { useCallback, useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Box, AText } from '@alf/uikit';
import { USER_ROLES, UserContext, useUserInfoLoaded } from '@namespace/user';
import { Welcome } from '@namespace/auth';
import { useI18n } from '@namespace/i18n';
import { LogOnTimer } from '@namespace/account';
import { StepperProvider } from '@namespace/common';
import { document } from '@namespace/helpers';
import { useMobileNavMenuSettings, useOtherSettings } from '@namespace/cms';
import { DepositButton } from '@namespace/payments-sw';
import { WagerBonuses } from '@namespace/bonuses';
import ProfileMenuHeader from './ProfileMenuHeader';
import ProfileMenuFooter from './ProfileMenuFooter';
import ProfileMenuItems from './ProfileMenuItems';
import Balance from './Balance';
import BottomPopup from './BottomPopup';
import styles from './index.module.css';

const ProfileMenu = ({
  classNames = {},
  BalanceComponent,
  CustomProfileMenuItem,
  wagerBonusesConfig = {},
  footerConfig = {},
  headerConfig,
  profileMenuConfig = {},
  welcomeComponentProps
}) => {
  const { t } = useI18n();
  const [bottomPopupItems, setBottomPopupItems] = useState([]);
  const [{ role }] = useContext(UserContext);
  const isLoggedIn = role === USER_ROLES.USER;
  const mobileNavigation = useMobileNavMenuSettings();
  const { showLogOnTimer = false } = useOtherSettings();
  const { showBonusWagering = false } = mobileNavigation;
  const {
    showFormTitle = false,
    showRememberMeSection = true,
    showDepositButton = true
  } = profileMenuConfig;

  const {
    formClassName,
    formContainerClassName,
    registerBlock,
    bottomPopupOverlay,
    profileMenuItemsContainer,
    profileMenuFooterWrapper,
    profileMenuHeaderWrapper,
    profileMenuUserBlock,
    forgotPassword,
    haveAnAccount,
    buttonClassName,
    haveAnAccountTitleClassName
  } = classNames;
  const { userInfoLoaded } = useUserInfoLoaded();

  const handleShowBottomMenuItems = useCallback((items) => {
    document.body.classList.add('supportPopupOpen');
    setBottomPopupItems(items);
  }, []);

  const handleCloseBottomMenuItems = useCallback(() => {
    document.body.classList.remove('supportPopupOpen');
    setBottomPopupItems([]);
  }, []);

  useEffect(() => {
    return () => {
      document.body.classList.remove('supportPopupOpen');
    };
  }, []);

  return (
    userInfoLoaded && (
      <Box className={styles.profileMenu} direction="column" align="center">
        <ProfileMenuHeader
          classNames={{ general: profileMenuHeaderWrapper }}
          headerConfig={headerConfig}
        />
        {isLoggedIn ? (
          <Box
            className={clsx(styles.userBlock, profileMenuUserBlock)}
            direction="column"
          >
            {(BalanceComponent && <BalanceComponent />) || <Balance />}
            {showBonusWagering && <WagerBonuses {...wagerBonusesConfig} />}
            {showDepositButton && (
              <DepositButton
                className={styles.depositButton}
                actionType="color1"
                size="l"
                data-role="profile-menu-deposit"
              />
            )}
          </Box>
        ) : (
          <Box
            align="center"
            direction="column"
            className={clsx(styles.guestMenuWrapper, formContainerClassName)}
          >
            {showFormTitle && (
              <AText
                breed="RU"
                size="m"
                color="po_subheader_txt_color_1"
                align="center"
              >
                {t('web.personalaccountmenu.logIn.title')}
              </AText>
            )}
            <StepperProvider>
              <Welcome
                showSections={{
                  showRememberMeSection,
                  showForgotPasswordSection: true,
                  showRegisterSection: true
                }}
                classNames={{
                  formClassName: clsx(styles.form, formClassName),
                  inputLabelClassName: styles.label,
                  inputWrapperClassName: styles.input,
                  registerClassName: clsx(styles.registerBlock, registerBlock),
                  forgotPassClassName: clsx(
                    styles.forgotPassword,
                    forgotPassword
                  ),
                  haveAnAccountClassName: clsx(
                    styles.haveAnAccount,
                    haveAnAccount
                  ),
                  haveAnAccountTitleClassName,
                  buttonClassName
                }}
                isOpenFromPopup={true}
                {...welcomeComponentProps}
              />
            </StepperProvider>
          </Box>
        )}
        <ProfileMenuItems
          onClick={handleShowBottomMenuItems}
          className={profileMenuItemsContainer}
          CustomProfileMenuItem={CustomProfileMenuItem}
        />
        <ProfileMenuFooter
          className={profileMenuFooterWrapper}
          footerConfig={footerConfig}
        />
        {bottomPopupItems.length > 0 && (
          <BottomPopup
            bottomPopupItems={bottomPopupItems}
            classNames={{ bottomPopupOverlay }}
            onClosePopup={handleCloseBottomMenuItems}
          />
        )}
        {showLogOnTimer && (
          <Box className={styles.logInOn} justify="center">
            <LogOnTimer />
          </Box>
        )}
      </Box>
    )
  );
};

export default ProfileMenu;
