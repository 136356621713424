import { useCallback, useEffect, useRef } from 'react';
import {
  setLocalStorageValue,
  getLocalStorageValue,
  useAnimationFrame
} from '@namespace/helpers';
import { addSpacesToJackpotAmount } from '../../utils';

export const useSetJackpot = ({
  initialValue = 0,
  maxValue = 0,
  step = 1,
  formatTotal = (val) => val,
  delay,
  jackpotStorageKey = '',
  isSkipImmediateSetOnUpdate = false,
  isAddSpacesToJackpotAmount = true
}) => {
  const diffRef = useRef(0);
  const textRef = useRef(null);
  const isInitialSetJackpotCalled = useRef(false);
  const localGeneralJackpot =
    jackpotStorageKey && getLocalStorageValue(jackpotStorageKey);

  const setJackpot = useCallback(() => {
    if (
      textRef?.current &&
      (!maxValue || initialValue + diffRef.current <= maxValue)
    ) {
      const currentValue = localGeneralJackpot
        ? Number(localGeneralJackpot) + diffRef.current
        : initialValue + diffRef.current;

      textRef.current.innerText = isAddSpacesToJackpotAmount
        ? addSpacesToJackpotAmount(formatTotal(currentValue))
        : formatTotal(currentValue);

      if (jackpotStorageKey) {
        setLocalStorageValue(jackpotStorageKey, currentValue, {
          expires: new Date(new Date().getTime() + 600000) // 10 min
        });
      }

      diffRef.current += step;
    }
  }, [
    maxValue,
    initialValue,
    localGeneralJackpot,
    formatTotal,
    step,
    jackpotStorageKey
  ]);

  useAnimationFrame(setJackpot, delay);

  useEffect(() => {
    diffRef.current = 0;

    if (isSkipImmediateSetOnUpdate && isInitialSetJackpotCalled.current) return;
    if (!isInitialSetJackpotCalled.current) {
      isInitialSetJackpotCalled.current = true;
    }

    setJackpot();
  }, [initialValue, setJackpot, isSkipImmediateSetOnUpdate]);

  return textRef;
};
