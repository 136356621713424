import { postAsUriParameters } from '@namespace/helpers';
import { camelizeKeys } from 'humps';
import { isEmpty } from 'lodash';
import apiRoutes from './apiRoutes';

export const checkIban = (iban) =>
  postAsUriParameters(apiRoutes.checkIban, { iban })
    .then(camelizeKeys)
    .then((response) => {
      const {
        iban: { error = '', errorCode = '' }
      } = response;
      return {
        error: error === 'yes' && !isEmpty(response.iban),
        errorCode: errorCode || ''
      };
    });
