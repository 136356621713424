import { post } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

export const getTopLiveEvents = ({ language }) => {
  const body = {
    lang: language,
    tags: ['top_live']
  };

  return post('/frontend_api/top_live/', body).then(camelizeKeys);
};
