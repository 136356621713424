import { useEffect, useState, useMemo } from 'react';
import { hasRuleError } from '@alf/validators';
import { useI18n } from '@namespace/i18n';

import { passwordRules } from '../../../../utils/password';

const useRulesList = ({ isValueSensitive, rules, value }) => {
  const { t } = useI18n();
  const [isVisible, setIsVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const textRules = useMemo(() => passwordRules(t, rules), [t, rules]);

  const getValue = () => {
    if (isValueSensitive) {
      return isVisible ? textRules : [];
    }

    return textRules;
  };

  useEffect(() => {
    const hasError = textRules.some((i) => hasRuleError(value, i.rules));

    if (isFocused) {
      const isPassed = value && !hasError;

      setIsVisible(!isPassed);
    } else if (!hasError) {
      setIsVisible(false);
    }
  }, [isFocused, textRules, value]);

  return {
    value: getValue(),
    focus() {
      setIsFocused(true);
    },
    unfocus() {
      setIsFocused(false);
    }
  };
};

export default useRulesList;
