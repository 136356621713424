import { memo } from 'react';
import { useHistory } from '@namespace/router';
import { SportIcon, Icon } from '@namespace/icons2';
import { Box, AText } from '@alf/uikit';
import styles from './index.module.css';

const EventsLineTitle = ({
  sportId,
  categoryId,
  title = '',
  titleComponent = null,
  isDisplayIcon = true,
  subtitle = '',
  classNames = {},
  backLink = '',
  toggleExpanded
}) => {
  const {
    containerClassName = '',
    iconClassName = '',
    titleClassName = '',
    subtitleClassName = ''
  } = classNames;
  const { goBack } = useHistory();
  const titleText = titleComponent ? (
    <>{titleComponent}</>
  ) : (
    <AText breed="BU" size="m" color="line_header_txt" overflow="ellipsis">
      {title}
    </AText>
  );

  return (
    <Box align="center" className={containerClassName}>
      {isDisplayIcon &&
        (backLink ? (
          <div onClick={goBack} className={styles.link}>
            <Icon
              className={`${styles.backIcon} ${iconClassName}`}
              name="icons/general/nav/back"
              size="s"
              color="line_header_txt"
            />
          </div>
        ) : (
          <SportIcon
            color="line_header_txt"
            sportId={categoryId || sportId}
            className={`${styles.icon} ${iconClassName}`}
            size="s"
          />
        ))}
      <Box
        className={`${styles.title} ${titleClassName} ${
          toggleExpanded ? styles.activeTitle : ''
        }`}
        onClick={(e) => {
          e.stopPropagation();
          if (toggleExpanded) {
            toggleExpanded();
          }
        }}
      >
        {titleText}
      </Box>
      {subtitle && (
        <Box margin="0 xs">
          <AText
            breed="RU"
            size="m"
            className={`${styles.subtitle} ${subtitleClassName}`}
          >
            {subtitle}
          </AText>
        </Box>
      )}
    </Box>
  );
};

export default memo(EventsLineTitle);
