import { fundsBonusSystemStates } from '../../constants';
import { parseBsBonusDate } from '../parseBsBonusDate';

export const normalizeSystemBonuses = (bonuses, currency) => {
  return bonuses
    .filter(
      ({ status, currency: responseCurrency }) =>
        status !== fundsBonusSystemStates.CANCELED_BY_MANAGER &&
        responseCurrency.currency === currency
    )
    .map((bonus) => {
      const {
        bonusId,
        campaignId: bonusTemplateId,
        campaignName,
        status,
        updatedAt,
        type: types,
        wagerMultiplier,
        wageredAmount,
        bonusType,
        amountForWager,
        bonusAmount,
        wageredPercent,
        activationExpiredAtTimestamp,
        wageringExpiredAtTimestamp,
        triggerSettings
      } = bonus;

      const { currencies = [] } = triggerSettings || {};
      const triggerCurrency = currencies.find(
        ({ currency: c }) => c === currency
      );

      return {
        bonusId,
        bonusModelId: bonusTemplateId,
        bonusName: campaignName,
        bonusSum: bonusAmount,
        currency,
        outputBetTotal: wageredAmount,
        outputMaxTotal: amountForWager,
        state: status,
        bonusType,
        services: types
          .map((type) => type.charAt(0).toUpperCase() + type.slice(1))
          .join(' + '),
        wagerMultiplier,
        updatedAt: parseBsBonusDate(updatedAt),
        wageredPercent,
        ...([
          fundsBonusSystemStates.POTENTIAL,
          fundsBonusSystemStates.PROPOSED,
          fundsBonusSystemStates.WAGERING
        ].includes(status) && {
          maxActivationTime: {
            sec:
              status === fundsBonusSystemStates.PROPOSED ||
              status === fundsBonusSystemStates.POTENTIAL
                ? activationExpiredAtTimestamp
                : wageringExpiredAtTimestamp
          }
        }),
        isBsBonus: true, // identify as bonus from Bonus System,
        triggerSettings: {
          currency: triggerCurrency,
          ...triggerSettings
        }
      };
    });
};
