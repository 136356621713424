import { AText, Box } from '@alf/uikit';
import { Icon } from '@namespace/icons2';

import styles from './index.module.css';

export const JackpotItemWinnerGameInfo = ({
  isShowAsLink,
  onLaunchGame,
  gameName,
  providerName
}) => {
  return (
    <>
      {isShowAsLink ? (
        <Box align="center">
          <AText
            size="s"
            breed="B"
            color="txt_link"
            align="center"
            onClick={onLaunchGame}
            className={styles.gameLink}
          >
            {providerName}
            <span>: </span>
            {gameName}
            <span className={styles.gameLinkIconWrapper}>
              {' '}
              <Icon color="txt_link" size="xs" name="icons/general/nav/go-to" />
            </span>
          </AText>
        </Box>
      ) : (
        <AText size="s" breed="R" color="txt_color_4" align="center">
          {providerName}
          <span>: </span>
          {gameName}
        </AText>
      )}
    </>
  );
};
