import { useContext, useEffect, useRef } from 'react';
import useNotifications from '@namespace/notifications/src/hooks/useNotifications';
import { NOTIFICATION_TYPES } from '@namespace/notifications/src/constants';
import { useStatuses } from '../useStatuses';
import { UserContext } from '../../context';

import getNotificationType from './utils/getNotificationType';

export { getNotificationType };

const useVerificationStatusNotification = () => {
  const isMounted = useRef(false);

  const { customNotification } = useNotifications();
  const accountStatuses = useStatuses();
  const [user] = useContext(UserContext);
  const notificationType = getNotificationType({ accountStatuses, user });

  useEffect(() => {
    if (notificationType) {
      const shouldShowVerificationNotification = isMounted.current
        ? true
        : notificationType !== NOTIFICATION_TYPES.VERIFIED_USER;

      if (shouldShowVerificationNotification) {
        customNotification({
          notificationType,
          timeout: null
        });
      }
    }

    if (!isMounted.current) {
      isMounted.current = true;
    }
  }, [notificationType, customNotification]);
};

export default useVerificationStatusNotification;
