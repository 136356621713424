import { useContext } from 'react';
import { PredictionsContext } from '../context';
import { PREDICTIONS_STEPS_IDS } from '../../constants';

export const useGetActiveStep = () => {
  const [predState] = useContext(PredictionsContext);
  const { steps, activeStep } = predState;

  return steps[activeStep] || {};
};

export const useGetActiveStepBracket = () => {
  const activeStep = useGetActiveStep();

  return activeStep.bracket || [];
};

export const useGetActiveStepTeams = () => {
  const activeStep = useGetActiveStep();

  return activeStep.teams || [];
};

export const useGetStep = (step) => {
  const [predState] = useContext(PredictionsContext);
  const { steps } = predState;

  return steps[step] || {};
};

export const useGetStepBracket = (stepName) => {
  const step = useGetStep(stepName);

  return step.bracket || [];
};

export const useIsActiveStepDisabled = () => {
  const [predState] = useContext(PredictionsContext);
  const { disabledSteps, activeStep } = predState;

  return disabledSteps[activeStep];
};

export const useIsFinalStepFilled = () => {
  const [predState] = useContext(PredictionsContext);
  const { steps } = predState;
  const finalStep = steps[PREDICTIONS_STEPS_IDS.FINAL];
  const { teams = {} } = finalStep || {};

  return Boolean(Object.values(teams).find((team) => team?.win));
};

export const useGetPredictedWinner = () => {
  const { teams = {} } = useGetStep(PREDICTIONS_STEPS_IDS.FINAL);
  return Object.values(teams).find(({ win }) => Boolean(win)) || {};
};
