import { Box, KeyboardDatePicker as Picker } from '@alf/uikit';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';
import FormFieldError from '../FormFieldError';
import styles from './index.module.css';

const KeyboardDatePicker = ({
  name,
  control,
  rules,
  error,
  withTooltip,
  noErrorMsg,
  date,
  datePickerWrapperClassName = styles.datePickerWrapperClassName,
  datePickerContainerClassName = '',
  datePickerInputClassName = '',
  tooltipClassName = '',
  onBlur = () => {},
  disabled,
  ...datePickerProps
}) => {
  return (
    <Box
      direction="column"
      className={datePickerContainerClassName}
      onBlur={onBlur}
    >
      <Controller
        name={name}
        control={control}
        render={({
          field: { name: fieldName, onChange, onBlur: nativeOnBlur }
        }) => {
          const { onChange: pickerOnChange, ...rest } = datePickerProps;
          return (
            <Picker
              name={fieldName}
              onChange={(e) => pickerOnChange(e, onChange)}
              date={date}
              className={datePickerWrapperClassName}
              inputClassName={datePickerInputClassName}
              error={Boolean(error)}
              disabled={disabled}
              onBlur={nativeOnBlur}
              {...rest}
            />
          );
        }}
        rules={rules}
        disabled={disabled}
      />
      {error && !noErrorMsg && (
        <FormFieldError
          withTooltip={withTooltip}
          className={clsx(
            tooltipClassName,
            withTooltip ? styles.inputTooltipError : styles.inputHintError
          )}
        >
          {error}
        </FormFieldError>
      )}
    </Box>
  );
};

KeyboardDatePicker.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  withTooltip: PropTypes.bool,
  date: PropTypes.instanceOf(Date)
};

export default KeyboardDatePicker;
