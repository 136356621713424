import { useGeneralRoutes } from '@namespace/cms';
import { useLocation } from '@namespace/i18n';
import { PreMatchEventSeoSnippet } from '@namespace/sports';
import { HTMLSnippet } from '@namespace/snippets';

export const SeoSnippet = ({ snippetId, className, onLoaded }) => {
  const { preMatchEvent } = useGeneralRoutes();
  const { pathname } = useLocation();

  if (pathname.includes(preMatchEvent)) {
    return (
      <PreMatchEventSeoSnippet snippetId={snippetId} className={className} />
    );
  }

  return (
    <HTMLSnippet
      snippetId={snippetId}
      className={className}
      onLoaded={onLoaded}
    />
  );
};
