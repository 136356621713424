import { createContext, useReducer } from 'react';
import { baseReducer } from '@namespace/helpers-store';
import { initialState, handlers } from '../reducer';

export const StepperContext = createContext();

export const StepperProvider = ({ children, customHandlers = {} }) => {
  const reducer = baseReducer(handlers, customHandlers);
  const [stepper, dispatch] = useReducer(reducer, initialState);

  return (
    <StepperContext.Provider value={{ stepper, dispatch }}>
      {children}
    </StepperContext.Provider>
  );
};
