import { useContext, useMemo } from 'react';
import { SiteSettingsContext } from '@namespace/cms';
import { USER_ROLES, UserContext, useVerifyDocuments } from '@namespace/user';

export const useIsFreeToPlayUser = () => {
  const [settings = {}] = useContext(SiteSettingsContext);
  const { otherSettings = {} } = settings;
  const { isFreeToPlayWithoutVerifiedDocs = true } = otherSettings;
  const { verified } = useVerifyDocuments();
  const [user] = useContext(UserContext);
  const { accountStatuses = {}, role } = user;
  const { isSuspendedUser, isAddictedUser } = accountStatuses;

  return useMemo(
    () =>
      role !== USER_ROLES.GUEST
        ? !(isSuspendedUser || isAddictedUser) &&
          (verified || isFreeToPlayWithoutVerifiedDocs)
        : true,
    [verified, isFreeToPlayWithoutVerifiedDocs, isSuspendedUser, isAddictedUser]
  );
};
