import { socketSend } from '@namespace/socket';
import { types } from '../reducer';
import { normalizeStateForSaveRequest } from '../normalizer';

export default {
  [types.FETCH_REFERENCE]: (dispatch) => async () => {
    const res = await socketSend({
      cmd: `predictions/v1/tournament/euro2020`,
      data: {
        method: 'GET'
      }
    });
    dispatch({
      type: types.SET_REFERENCE,
      payload: res?.ok === 'true' ? res.data.result : {}
    });
  },
  [types.FETCH_USER_PREDICTION]: (dispatch) => async (userId) => {
    if (userId) {
      const res = await socketSend({
        cmd: `predictions/v1/tournament/euro2020/player-prediction/${userId}`,
        data: {
          method: 'GET'
        }
      });
      dispatch({
        type: types.SET_USER_PREDICTION,
        payload: res?.ok === 'true' ? res.data.result : {}
      });
    }
  },
  [types.SAVE_PREDICTION]: (dispatch) => async (steps, userId) => {
    const requestData = normalizeStateForSaveRequest(steps);
    const res = await socketSend({
      cmd: `predictions/v1/tournament/euro2020/player-prediction/${userId}`,
      data: {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: requestData
      }
    });
    if (res?.ok === 'true') {
      dispatch({
        type: types.OFF_EDITING
      });
    }
    return res;
  },
  [types.EDIT_STEP]: (dispatch) => (step, isRefill) => {
    dispatch({
      type: types.OFF_FILLED_FROM_STEP,
      payload: { step, isRefill }
    });
    dispatch({
      type: types.SET_ACTIVE_STEP,
      payload: step
    });
  }
};
