import { DateTime } from 'luxon';

const BS_API_DATE_FORMAT = 'dd.MM.yyyy HH:mm:ss';

export const parseBsBonusDate = (srcDate) =>
  srcDate != null
    ? DateTime.fromFormat(srcDate, BS_API_DATE_FORMAT, {
        zone: 'UTC'
      }).toLocal()
    : srcDate;
