import { AText, Box, Button } from '@alf/uikit';
import { useGeneralRoutes } from '@namespace/cms';
import { Image } from '@namespace/icons2';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import styles from './index.module.css';

const ShopSuccess = () => {
  const { shop: shopRoute } = useGeneralRoutes();
  const { push } = useHistory();
  const { t } = useI18n();

  return (
    <Box direction="column" align="center">
      <Box direction="column" align="center" top="xxl" bottom="m">
        <Image name="general/success" className={styles.image} alt="" />
        <AText
          breed="BU"
          size="m"
          color="line_txt_3"
          className={styles.marginBottom}
        >
          {t('bonusShop.success.text.info1')}
        </AText>
        <AText breed="R" size="m" color="line_txt_3">
          {t('bonusShop.success.text.info2')}
        </AText>
      </Box>
      <Box flex="1" padding="ms" bottom="m">
        <Button onClick={() => push(shopRoute)}>
          {t('bonusShop.success.text.button')}
        </Button>
      </Box>
    </Box>
  );
};

export default ShopSuccess;
