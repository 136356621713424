import { Box } from '@alf/uikit';

import styles from './index.module.css';

const LinkGroup = ({
  links = [],
  direction = 'row',
  justify = 'start',
  className
}) => (
  <Box
    className={className}
    direction={direction}
    padding="m 0 0"
    justify={justify}
  >
    {links.map(({ icon, url, alt }) => (
      <a
        key={icon}
        className={styles.link}
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        <img className={styles.icon} src={icon} alt={alt} />
      </a>
    ))}
  </Box>
);

export default LinkGroup;
