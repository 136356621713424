import BurgerMenu from './BurgerMenu';
import { BurgerMenuProvider } from './context';
import BurgerMenuWrapper from './wrapper';

const BurgerMenuContainer = ({ id, classNames }) => (
  <BurgerMenuProvider>
    <BurgerMenuWrapper>
      <BurgerMenu id={id} classNames={classNames} />
    </BurgerMenuWrapper>
  </BurgerMenuProvider>
);

export default BurgerMenuContainer;
