import PropTypes from 'prop-types';

const TableCell = ({ children, className = '', scope, ...props }) => {
  if (scope) {
    return (
      <th className={className} scope={scope} {...props}>
        {children}
      </th>
    );
  }
  return (
    <td className={className} {...props}>
      {children}
    </td>
  );
};

TableCell.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  /**
   * Specifies whether a header cell is a header for a column, row, or group of columns or rows.
   * Only for header cell
   */
  scope: PropTypes.oneOf(['col', 'colgroup', 'row', 'rowgroup'])
};

export default TableCell;
