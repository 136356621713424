import { useContext, useMemo } from 'react';
import { ConfigContext } from '../../store/config';

const useServiceLimits = (serviceId) => {
  const { servicesLimits = {} } = useContext(ConfigContext);

  return useMemo(() => {
    const { deposit = {}, withdraw = {} } = servicesLimits[serviceId] || {};

    return {
      minDeposit: Number(deposit.min) || 0,
      maxDeposit: Number(deposit.max) || 0,
      minWithdraw: Number(withdraw.min) || 0,
      maxWithdraw: Number(withdraw.max) || 0
    };
  }, [servicesLimits, serviceId]);
};

export default useServiceLimits;
