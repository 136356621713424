import PropTypes from 'prop-types';
import { useRemoveLine } from '../../hooks/useRemoveLine';
import { CommonSportLine } from './components/CommonSportLine';
import { CustomFilterLine } from './components/CustomFilterLine';
import { SPORTS_LINE_TYPES } from '../../constants';

const emptyArr = [];

const componentsMap = {
  [SPORTS_LINE_TYPES.COMMON_SPORT]: CommonSportLine,
  [SPORTS_LINE_TYPES.CUSTOM_FILTER]: CustomFilterLine
};

const LineSport = ({
  lineType = SPORTS_LINE_TYPES.COMMON_SPORT,
  filter,
  defaultFilter,
  sport,
  categoryIds = emptyArr,
  tournamentsIds = emptyArr,
  lineKey,
  serviceId,
  activeHeadGroups,
  isShowFlag,
  isExpanded,
  isShowEmpty,
  isCollapsibleTournaments,
  isLiveEvents,
  isTodayFilterApplied,
  onDefaultFilterChange,
  isReady
}) => {
  const Component = componentsMap[lineType];

  useRemoveLine(lineKey);
  return (
    <Component
      lineKey={lineKey}
      filter={filter}
      defaultFilter={defaultFilter}
      sport={sport}
      categoryIds={categoryIds}
      tournamentsIds={tournamentsIds}
      serviceId={serviceId}
      activeHeadGroups={activeHeadGroups}
      isShowFlag={isShowFlag}
      isExpanded={isExpanded}
      isShowEmpty={isShowEmpty}
      isCollapsibleTournaments={isCollapsibleTournaments}
      isLiveEvents={isLiveEvents}
      isTodayFilterApplied={isTodayFilterApplied}
      onDefaultFilterChange={onDefaultFilterChange}
      isReady={isReady}
    />
  );
};

LineSport.propTypes = {
  lineKey: PropTypes.string.isRequired,
  serviceId: PropTypes.number.isRequired,
  sport: PropTypes.object,
  filter: PropTypes.object,
  categoryIds: PropTypes.array,
  isExpanded: PropTypes.bool,
  isShowEmpty: PropTypes.bool
};

export default LineSport;
