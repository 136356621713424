import { useContext, useEffect } from 'react';
// eslint-disable-next-line @alf/loop-deps
import { ConfigContext, setConfig } from '@namespace/payments'; // Refactor with migration payments to payments-sw
import { UserContext } from '../../context';
import getPartnerConfig from '../../api/getPartnerConfig';

const useGetPartnerConfig = () => {
  const [user, { SET_PEP_ATTRIBUTES }] = useContext(UserContext);
  const { countryId, id } = user;
  const { configDispatch } = useContext(ConfigContext);

  useEffect(() => {
    if (countryId && id) {
      (async () => {
        try {
          const {
            paymentSystems = [],
            pepAttributes = []
          } = await getPartnerConfig();
          configDispatch(setConfig(paymentSystems, countryId, id));
          SET_PEP_ATTRIBUTES(pepAttributes);
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [SET_PEP_ATTRIBUTES, configDispatch, countryId, id]);

  return null;
};

export default useGetPartnerConfig;
