import { AText, Box } from '@alf/uikit';
import { Image } from '@namespace/icons2';
import { LocalizedLink } from '@namespace/i18n';
import styles from '../index.module.css';

// due to webkit doesn't support lookbehind
const webkitFallbackSplit = (str, tags) => {
  const splitRe = new RegExp(`(?={(?:${tags}):\\d.+?{(?:${tags})})`);
  const splitPartRe = new RegExp(`(?:{(?:${tags}):\\d.+?{(?:${tags})})`);

  return str.split(splitRe).flatMap((part) => {
    const match = part.match(splitPartRe);
    const splits = part.split(splitPartRe);

    if (splits.length > 1) {
      // eslint-disable-next-line prefer-destructuring
      splits[0] = match[0];
    }

    return splits;
  });
};

const split = (str, tags) => {
  let result;

  try {
    const splitRe = new RegExp(
      `(?={(?:${tags}):\\d.+{(?:${tags})})|(?<={(?:${tags}):\\d.+{(?:${tags})})`
    );

    result = str.split(splitRe);
  } catch {
    // todo remove after this ships to all Safari https://bugs.webkit.org/show_bug.cgi?id=174931
    console.debug('Safari...lookbehind');

    result = webkitFallbackSplit(str, tags);
  }

  return result;
};

export const parseLinks = (content, links = [], { RUN }) => {
  const tags = ['IMG', 'LINK'].join('|');
  const matchRe = new RegExp(`{(${tags}):(\\d+)}(.+){(?:${tags})}`);

  return split(content, tags).map((string, idx) => {
    const [, tagName, linkIndex, linkText] = matchRe.exec(string) || [];

    switch (tagName) {
      case 'IMG':
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={idx} className={styles.contentImage}>
            <Image name={links[linkIndex]} />
          </Box>
        );

      case 'LINK':
        return (
          <LocalizedLink
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            to={links[linkIndex]}
            className={styles.link}
            onClick={() => RUN(false)}
          >
            {linkText}
          </LocalizedLink>
        );

      default:
        return (
          // eslint-disable-next-line react/no-array-index-key
          <AText key={idx} breed="R" size="m" color="txt_color_7">
            {string}
          </AText>
        );
    }
  });
};
