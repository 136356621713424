import { useEffect, useContext } from 'react';
import { document } from '@namespace/helpers';
import { WidgetContext } from '../../store/widget/context';

const useWidgetListener = () => {
  const [{ linkToCustomStyles }] = useContext(WidgetContext);

  useEffect(() => {
    if (linkToCustomStyles) {
      const link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = linkToCustomStyles;
      document.head.appendChild(link);
    }
  }, [linkToCustomStyles]);
};

export default useWidgetListener;
