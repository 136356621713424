import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useGetThemeConfig } from '@namespace/themes';
import Box from '../Box';
import { propsMerge } from '../../utils/propsMerge';
import styles from './index.module.css';

const getResizeCls = (resize) =>
  resize && styles[`resize${resize[0].toUpperCase() + resize.slice(1)}`];

const TextArea = (props) => {
  const textAreaConfig = useGetThemeConfig('TextArea');
  const {
    className = '',
    resize = 'none',
    size = 's',
    wrapperClassName,
    intent = 'default',
    bottomElement = null,
    ...rest
  } = propsMerge(props, textAreaConfig);
  return (
    <Box className={clsx([wrapperClassName, styles[size], styles[intent]])}>
      <textarea
        className={clsx([styles.textarea, className, getResizeCls(resize)])}
        {...rest}
      />
      {bottomElement &&
        cloneElement(bottomElement, { className: styles.borderBottomCustom })}
    </Box>
  );
};

TextArea.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.shape({ current: PropTypes.element })
  ]),
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['s', 'm']),
  value: PropTypes.any,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  intent: PropTypes.oneOf(['error', 'success']),
  wrapperClassName: PropTypes.string,
  resize: PropTypes.oneOf(['none', 'vertical', 'horizontal'])
};

export default TextArea;
