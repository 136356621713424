import { camelizeKeys } from 'humps';
import { post } from '@namespace/helpers';

export const updateExpDate = ({
  userId,
  accountingId,
  cardHash,
  ssid,
  expMonth,
  expYear
}) =>
  post('/pankeeper/frontend_api/update_exp_date/', {
    user_id: String(userId),
    accounting_id: accountingId,
    ssid,
    card_hash: cardHash,
    exp_month: String(expMonth),
    exp_year: String(expYear)
  }).then(camelizeKeys);
