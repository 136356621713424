import { BET_SLIP_STATUS_MAP } from '../../constants';
import { types } from '../types';
import normalizeBetslip from '../normalizer';

export const initialState = {
  oddsType: null,
  selectedOddsType: null,
  status: BET_SLIP_STATUS_MAP.ACTIVE,
  bets: [],
  basket: {},
  isLoaded: false,
  errors: {},
  basketErrors: {},
  // todo: this prop used to catch user click on outcome, fix
  outcomeClickCounter: 0,
  showPreorder: false,
  fastBet: false,
  isSettingsOpen: false,
  isOneClickBet: false,
  isOpen: false,
  serviceId: 0, // SERVICE_IDS[SERVICE_IDS_TYPES_MAP.PRE_MATCH]
  // serviceId: 31, // SERVICE_IDS[SERVICE_IDS_TYPES_MAP.MIXED]
  needsAccept: true,
  isBetSlipTabOpen: true
};

export const reducers = {
  [types.SET_BETSLIP_DATA]: (state, { data }) => {
    const normalizedBetslip = normalizeBetslip(state, data);
    const { basket } = normalizedBetslip;
    const { is_processing: isProcessing = true } = basket;

    return {
      ...state,
      ...normalizedBetslip,
      isLoaded: true,
      needsAccept: !isProcessing
    };
  },
  [types.ACCEPT_BETSLIP]: (state) => ({
    ...state,
    needsAccept: false
  }),
  [types.SET_ODDS_TYPE]: (state, { oddsType, isSettingStatus = true }) => {
    return {
      ...state,
      oddsType,
      selectedOddsType: oddsType,
      status: isSettingStatus ? BET_SLIP_STATUS_MAP.ACTIVE : state.status
    };
  },
  [types.SET_BETSLIP_STATUS]: (state, { status }) => ({
    ...state,
    status
  }),
  [types.SHOW_PREORDER]: (state, showPreorder) => ({
    ...state,
    showPreorder: state.hasErrors ? false : showPreorder
  }),
  [types.SET_FAST_BET]: (state, fastBet) => ({
    ...state,
    fastBet
  }),
  [types.SET_ERRORS]: (state, { errors }) => ({
    ...state,
    status: BET_SLIP_STATUS_MAP.ACTIVE,
    errors: {
      ...state.errors,
      ...errors
    }
  }),
  // todo: this prop used to catch user click on outcome, fix
  [types.INCREMENT_OUTCOME_CLICK_COUNTER]: (state) => ({
    ...state,
    outcomeClickCounter: state.outcomeClickCounter + 1
  }),
  [types.SET_BETSLIP_OPEN]: (state, isOpen) => ({
    ...state,
    isOpen: Boolean(isOpen)
  }),
  [types.OPEN_BETSLIP_SETTINGS]: (state, isOpen) => ({
    ...state,
    isSettingsOpen: isOpen,
    showPreorder: false
  }),
  [types.OPEN_BETSLIP_TAB]: (state, isBetslip) => ({
    ...state,
    isBetSlipTabOpen: isBetslip
  })
};
