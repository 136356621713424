import { ihubPost } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

export const getShopCategories = async ({ language, route, allTab }) => {
  const list = await ihubPost('/ihub/api/v1/shop/category/get', { language })
    .then(camelizeKeys)
    .then(({ data, status }) => (status ? data : []));

  // todo refactor, move logic into reducer
  return [
    allTab,
    ...list.map(({ idt, title }) => ({
      id: idt,
      link: `${route}/${idt}/`,
      title
    }))
  ];
};
