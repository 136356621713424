import { useCallback, useContext, useEffect } from 'react';
import { isString } from 'lodash';
import { useHistory } from '@namespace/router';
import { CommonLoader } from '@namespace/common';
import {
  logAction,
  logPostConversionTracking,
  LOG_ACTIONS
} from '@namespace/analytics';
import { useGeneralRoutes, useOtherSettings } from '@namespace/cms';
import { UserContext } from '@namespace/user';
import { Iframe, Overlay, Box } from '@alf/uikit';
import { SWPaymentsContext } from '@namespace/payments-sw/src/store/context';
import { window } from '@namespace/helpers';
import PaymentSystemResultForm from '../../common/PaymentSystemResultForm';
import PaymentSystemResultFormIframe from '../../common/PaymentSystemResultFormIframe';
import QRCodeDepositResult from '../../common/QRCodeDepositResult';
import { PaymentsContext } from '../../store/main/context';
import styles from './index.module.css';

const DepositResult = () => {
  const { push } = useHistory();
  const { wallets } = useGeneralRoutes();
  const [
    { resultData: oldResultData },
    { SET_RESULT: OLD_SET_RESULT }
  ] = useContext(PaymentsContext);
  const [
    { resultDepositData },
    { SET_DEPOSIT_RESULT: SWSET_RESULT }
  ] = useContext(SWPaymentsContext);
  const { postConversionType } = useOtherSettings();

  const resultData = resultDepositData || oldResultData;
  const SET_RESULT = resultDepositData ? SWSET_RESULT : OLD_SET_RESULT;

  const [user] = useContext(UserContext);
  const {
    activeWallet: { name, id }
  } = user;

  const {
    redirectMethod,
    apiRedirectUrl,
    operationId,
    response: formData,
    responseQuery: queryParams
  } = resultData || {};
  const onBackButtonEvent = useCallback(() => {
    push(wallets);
  }, [push, wallets]);
  useEffect(() => {
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [onBackButtonEvent]);
  const redirectFrom = () => {
    logAction(LOG_ACTIONS.DEPOSIT_REDIRECT, {
      status: 'Redirect received',
      name,
      id
    });
    logPostConversionTracking(LOG_ACTIONS.POST_DEPOSIT, postConversionType);

    return (
      <Box flex="1 0 100%" justify="center" align="center">
        <Overlay>
          <CommonLoader />
        </Overlay>
        <PaymentSystemResultForm formData={formData} action={apiRedirectUrl} />
      </Box>
    );
  };

  switch (redirectMethod) {
    case 'redirect_iframe':
      logAction(LOG_ACTIONS.DEPOSIT_REDIRECT, {
        status: 'Redirect received',
        name,
        id
      });
      logPostConversionTracking(LOG_ACTIONS.POST_DEPOSIT, postConversionType);

      return <Iframe src={apiRedirectUrl} className={styles.iframe} />;
    case 'form_iframe':
      logAction(LOG_ACTIONS.DEPOSIT_REDIRECT, {
        status: 'Redirect received',
        name,
        id
      });
      logPostConversionTracking(LOG_ACTIONS.POST_DEPOSIT, postConversionType);

      return (
        <PaymentSystemResultFormIframe
          formData={formData}
          action={apiRedirectUrl}
        />
      );
    case 'redirect_url':
      logAction(LOG_ACTIONS.DEPOSIT_REDIRECT, {
        status: 'Redirect received',
        name,
        id
      });
      logPostConversionTracking(LOG_ACTIONS.POST_DEPOSIT, postConversionType);

      // eslint-disable-next-line no-case-declarations
      let url = apiRedirectUrl;
      if (queryParams) {
        url = `${apiRedirectUrl}?${queryParams}`;
      } else if (isString(formData) && formData.length) {
        url = `${apiRedirectUrl}?${formData}`;
      }
      window.location.href = url;
      return null;
    case 'redirect_3ds':
      logPostConversionTracking(LOG_ACTIONS.POST_DEPOSIT, postConversionType);
      if (Object.values(formData).every((i) => i === 'null' || i === null)) {
        logAction(LOG_ACTIONS.DEPOSIT_REDIRECT, {
          status: 'Redirect received',
          name,
          id
        });
        window.location.href = apiRedirectUrl;
        return null;
      }
      return redirectFrom();

    case 'redirect_form':
      return redirectFrom();

    case 'redirect_qr_code':
      return (
        <QRCodeDepositResult formData={formData} operationId={operationId} />
      );

    case 'redirect_none':
      SET_RESULT({ resultData: null });
      logAction(LOG_ACTIONS.DEPOSIT_REDIRECT, {
        status: 'Redirect none',
        name,
        id
      });
      logPostConversionTracking(LOG_ACTIONS.POST_DEPOSIT, postConversionType);
      return null;

    default:
      logAction(LOG_ACTIONS.DEPOSIT_REDIRECT, {
        status: 'No redirect',
        name,
        id
      });
      logPostConversionTracking(LOG_ACTIONS.POST_DEPOSIT, postConversionType);

      push(wallets);
      return null;
  }
};

export default DepositResult;
