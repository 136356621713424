import { get, urlWithParams } from '@namespace/helpers';
import { camelizeKeys } from 'humps';

const getCasinoGameIds = async ({ startDate, endDate, categoryId }) => {
  const params = {
    ...(startDate && { date_from: `${startDate} 00:00:00` }),
    ...(endDate && { date_to: `${endDate} 00:00:00` }),
    category_id: categoryId
  };

  const providerWithGames = await get(
    urlWithParams(`/service/transaction-history/history/played-games`, params)
  ).then(({ data }) => camelizeKeys(data));

  const result = providerWithGames.providers.reduce(
    (acc, item) => [...acc, ...item.gameIds],
    []
  );
  return result;
};

export default getCasinoGameIds;
