import PropTypes from 'prop-types';
import clsx from 'clsx';
import Box from '../Box';
import { DOTS_TYPE, POSITION_TYPES } from './constants';
import styles from './index.module.css';

const Dots = ({
  dotsNumber,
  activeIndex,
  setActiveIndex,
  hideFirstItem,
  config = {},
  className,
  dataRole
}) => {
  const indexesArray = [...Array(dotsNumber).keys()];
  if (hideFirstItem) {
    indexesArray.shift(); // preserve original index numbers
  }
  const {
    position = POSITION_TYPES.OUTSIDE,
    background = false,
    type = DOTS_TYPE.DEFAULT,
    classNames
  } = config;

  return (
    <Box
      data-role={`${dataRole}-dots`}
      className={clsx([
        styles.dotsMenu,
        position === POSITION_TYPES.INSIDE
          ? styles.dotsInside
          : styles.dotsOutside,
        background && styles.withBackground,
        className,
        classNames?.general
      ])}
    >
      {indexesArray.map((index) => (
        <Box
          key={index}
          className={clsx(styles[`${type}_wrapper`], classNames?.dotWrapper)}
          onClick={() => setActiveIndex(index)}
        >
          <Box
            data-role={index === activeIndex && `${dataRole}-dots-active`}
            className={clsx([
              styles[`${type}_dot`],
              index === activeIndex && styles[`${type}_dotActive`],
              classNames?.dot,
              index === activeIndex && classNames?.activeDot
            ])}
          />
        </Box>
      ))}
    </Box>
  );
};

Dots.propTypes = {
  dotsNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  activeIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setActiveIndex: PropTypes.func,
  config: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      position: PropTypes.string,
      background: PropTypes.bool
    })
  ])
};

export default Dots;
