import { useCallback, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { COOKIES_KEYS, getCookie, window } from '@namespace/helpers';

const CONTENT_HEIGHT = 'CONTENT_HEIGHT';

const usePostResizeListener = (element) => {
  const resizeObserver = new ResizeObserver((entries) => {
    const rect = entries[0].contentRect;
    window.parent.postMessage(
      { event: CONTENT_HEIGHT, height: rect.height },
      '*'
    );
  });

  const startObserving = useCallback((el) => {
    resizeObserver.observe(el);
  }, []);

  const nativeApp = getCookie(COOKIES_KEYS.NATIVE_APP);

  useEffect(() => {
    if (nativeApp) {
      startObserving(element);
      return () => {
        resizeObserver.unobserve(element);
      };
    }

    return undefined;
  }, [element]);
};

export default usePostResizeListener;
