import { Icon } from '@namespace/icons2';
import AText from '../AText';
import Box from '../Box';
import Button from '../Button';
import Overlay from '../Overlay';
import styles from './index.module.css';

export const FullScreenNotification = ({
  title = '',
  subtitle = '',
  acceptButtonText = '',
  onAccept = () => {},
  onClose = () => {}
}) => {
  return (
    <Overlay
      classNames={{
        wrapperClass: styles.container
      }}
      onClick={onClose}
    >
      <Box
        className={styles.notificationContainer}
        direction="column"
        align="center"
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.closeIcon}>
          <Icon
            size="s"
            name="icons/general/action/close"
            color="txt_color_4"
            onClick={onClose}
          />
        </div>
        {title && (
          <AText
            size="2xl"
            breed="BU"
            color="state_favorite"
            align="center"
            className={styles.title}
          >
            {title}
          </AText>
        )}
        {subtitle && (
          <AText
            size="l"
            breed="R"
            align="center"
            color="txt_color_4"
            className={styles.subtitle}
          >
            {subtitle}
          </AText>
        )}
        <Box justify="center" align="center" className={styles.buttonContainer}>
          <Button
            className={styles.button}
            onClick={onAccept}
            actionType="color2"
            size="s"
            intent="primary"
          >
            {acceptButtonText}
          </Button>
        </Box>
      </Box>
    </Overlay>
  );
};
