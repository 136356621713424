import { useCallback, useContext, useMemo } from 'react';
import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
// eslint-disable-next-line @alf/loop-deps
import {
  selectBonusSystemBonus,
  unselectBonusSystemBonus,
  BONUS_SLIDER_BONUS_TYPES
} from '@namespace/bonuses'; // FEX ME
import {
  LOCAL_STORAGE_KEYS,
  getLocalStorageValue,
  setLocalStorageValue
} from '@namespace/helpers';
import { UserContext } from '@namespace/user/src/context';
import useOtherSettings from '@namespace/cms/src/hooks/useOtherSettings';
import { COMPONENTS_TYPES } from '@namespace/cms/src/constants';
import { PageComponentsContext } from '@namespace/cms/src/store/pageComponents/context';
import useDepositBonuses from '../../hooks/useDepositBonuses';
import { BonusSliderContainer } from './BonusSliderContainer';

const BonusSlider = ({
  title = 'web.personaloffice.deposit.bonusavailable',
  hasBonus,
  depositAmount
}) => {
  const { t } = useI18n();
  const {
    isPreWagerBonusesEnabled = false,
    isPreWagerDepositFundsEnabled = true
  } = useOtherSettings();
  const {
    bonuses,
    bonusesByType,
    fetchBonuses,
    isHasBonusSystemBonuses,
    activeBonusSystemBonusId
  } = useDepositBonuses(
    isPreWagerBonusesEnabled,
    isPreWagerDepositFundsEnabled
  );
  const [user] = useContext(UserContext);
  const { activeWallet = {}, defaultCurrency = '' } = user;
  const { currency = '' } = activeWallet;
  const userCurrency = (currency || defaultCurrency).toUpperCase();
  const activeBonusSliderBonusIdLocal = getLocalStorageValue(
    LOCAL_STORAGE_KEYS.BONUS_SLIDER_ACTIVE_BONUS
  );
  const isActiveBonusAvailable = useMemo(
    () =>
      bonuses?.find(
        (bonus) =>
          bonus?.bonusId === activeBonusSliderBonusIdLocal ||
          bonus?.freespinId === activeBonusSliderBonusIdLocal
      ),
    [bonuses, activeBonusSliderBonusIdLocal]
  );

  const {
    [COMPONENTS_TYPES.PWB_TRIGGERS_BONUS_SLIDER]: PartnerBonusSlider
  } = useContext(PageComponentsContext);

  const handleBonusClick = useCallback(
    async ({ bonusId, type }) => {
      if (type === BONUS_SLIDER_BONUS_TYPES.BONUS_SYSTEM) {
        await selectBonusSystemBonus({ bonusId });
      }
      if (activeBonusSystemBonusId) {
        await unselectBonusSystemBonus({ bonusId: activeBonusSystemBonusId });
      }

      setLocalStorageValue(
        LOCAL_STORAGE_KEYS.BONUS_SLIDER_ACTIVE_BONUS,
        bonusId || null
      );
      await fetchBonuses({ disableCRMBonusesLoad: true });
    },
    [activeBonusSystemBonusId, fetchBonuses]
  );

  if (!hasBonus || !isHasBonusSystemBonuses) {
    return null;
  }

  if (PartnerBonusSlider) {
    return (
      <PartnerBonusSlider
        bonuses={bonuses}
        bonusesByType={bonusesByType}
        handleBonusClick={handleBonusClick}
        activeBonusSystemBonusId={activeBonusSystemBonusId}
        userCurrency={userCurrency}
        depositAmount={depositAmount}
      />
    );
  }

  return (
    <Box direction="column" padding="m 0 0 0">
      <Box padding="0 0 s 0">
        <AText>{t(title)}</AText>
      </Box>
      <BonusSliderContainer
        bonuses={bonuses}
        handleBonusClick={handleBonusClick}
        activeBonusId={
          activeBonusSystemBonusId ||
          (isActiveBonusAvailable && activeBonusSliderBonusIdLocal)
        }
        userCurrency={userCurrency}
      />
    </Box>
  );
};

export default BonusSlider;
