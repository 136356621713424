import { memo } from 'react';
import { Box } from '@alf/uikit';
import styles from './index.module.css';

const MarketOutcomeContainer = ({
  dataRole,
  classNames = {},
  styles: inlineStyles,
  value,
  lineView
}) => {
  const {
    outcomeContainerClassName = '',
    outcomeMainClassName = ''
  } = classNames;

  return (
    <Box
      data-role={dataRole}
      padding="0"
      className={`${styles.outcomeContainer} ${outcomeContainerClassName}`}
      style={inlineStyles}
    >
      <Box
        className={`
        ${styles.outcomeMain} 
        ${lineView ? styles[lineView] : ''}
        ${outcomeMainClassName}
      `}
      >
        {value}
      </Box>
    </Box>
  );
};

export default memo(MarketOutcomeContainer);
