import getSlideImage from '../getSlideImage';

const getSlidePlatformImage = (
  slide = {},
  pageType = 'mobile',
  language = 'en',
  currency,
  getImg
) => getSlideImage(slide[pageType], language, currency, getImg);

export default getSlidePlatformImage;
