import { useContext, useEffect, useMemo } from 'react';
import { useLocation } from '@namespace/i18n';
import { DeviceContext } from '@namespace/device';
import { UserContext } from '@namespace/user';
import { MAP_OF_TOUR_NAMES_FROM_BE } from '../../constants';
import { OnboardingContext } from '../../store/context';
import { matchUrlCondition } from './conditionMatchers';

export const useAutomaticTourLaunch = () => {
  const { pageType } = useContext(DeviceContext);
  const [{ tours, isRunning }, { RUN, GO_TO_TOUR_BY_ID }] = useContext(
    OnboardingContext
  );
  const [user] = useContext(UserContext);
  const { userOptions = {} } = user;
  const { waitingForOnboarding } = userOptions;
  const { pathname } = useLocation();

  const matchingTour = useMemo(
    () =>
      waitingForOnboarding?.length > 0 &&
      tours.find(({ preConditions, id }) => {
        if (!waitingForOnboarding.includes(MAP_OF_TOUR_NAMES_FROM_BE[id])) {
          return false;
        }

        return preConditions?.some(matchUrlCondition({ pathname }));
      }),
    [pathname, tours, waitingForOnboarding]
  );

  useEffect(() => {
    if (matchingTour && !isRunning) {
      // a hack with timeout is because some tour auto-triggers, which lead to page navigation, may fail to update page config.
      setTimeout(() => {
        GO_TO_TOUR_BY_ID({ tourId: matchingTour.id, pageType });
        RUN(true);
      }, 500);
    }
  }, [GO_TO_TOUR_BY_ID, RUN, matchingTour, isRunning, pageType]);
};
