import clsx from 'clsx';
import { LocalizedLink } from '@namespace/i18n';
import { Icon } from '@namespace/icons2';
import { Box } from '@alf/uikit';
import Controls from '../../Controls';
import styles from './index.module.css';
import FavoriteButton from '../../FavoriteButton';
import { WideGameInfo } from './WideGameInfo';
import { WagerInfo } from './WagerInfo';

const GameContent = ({
  game,
  controls,
  className = '',
  isDesktop,
  showGameContent,
  dataGTM,
  infoBtnLink,
  casinoType,
  onGameClick,
  gameIndex,
  isWideGame,
  isGameWagerExcluded,
  isWagerInfoShow,
  classNames = {}
}) => {
  const {
    serviceName,
    serviceURL,
    hasFavoriteButton,
    backTo,
    isMobileAppRedirect,
    isNativeApp
  } = controls;
  const {
    wideGameInfoDesktopWrapper,
    controlsDesktopWrapper,
    wideWagerDesktopWrapper
  } = classNames;

  if (isDesktop) {
    return (
      <Box
        className={clsx([
          styles.content,
          isWideGame && styles.wideGameContent,
          className
        ])}
      >
        {isWideGame && (
          <WideGameInfo
            className={clsx([
              styles.wideGameInfoWrapper,
              wideGameInfoDesktopWrapper
            ])}
            game={game}
          />
        )}
        <Controls
          casinoType={casinoType}
          serviceName={serviceName}
          serviceURL={serviceURL}
          backTo={backTo}
          isMobileAppRedirect={isMobileAppRedirect}
          isNativeApp={isNativeApp}
          game={game}
          onGameClick={onGameClick}
          gameIndex={gameIndex}
          isWideGame={isWideGame}
          className={clsx([
            isWideGame ? styles.wideGameControls : styles.controls,
            controlsDesktopWrapper
          ])}
          classNames={{
            control: styles.control
          }}
        />
        {isWagerInfoShow && (
          <WagerInfo
            isGameWagerExcluded={isGameWagerExcluded}
            className={clsx([
              isWideGame ? styles.wideWager : styles.wager,
              wideWagerDesktopWrapper
            ])}
          />
        )}
      </Box>
    );
  }

  if (!isDesktop && showGameContent) {
    return (
      <Box
        className={clsx([
          styles.content,
          isWideGame && styles.wideGameContent,
          className
        ])}
      >
        <Box className={styles.mobileInfoWrapper}>
          {!isWideGame && infoBtnLink && (
            <LocalizedLink
              to={infoBtnLink}
              className={styles.infoIcon}
              data-gtm={dataGTM}
            >
              <Icon
                name="icons/general/state/info2"
                size="m"
                color="state_info"
              />
            </LocalizedLink>
          )}
          {hasFavoriteButton && (
            <Box
              className={styles.favoriteBtnWrapper}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <FavoriteButton
                casinoType={casinoType}
                game={game}
                redirectToPlay={isDesktop}
                serviceURL={serviceURL}
                redirectToPreview={!isDesktop}
              />
            </Box>
          )}
        </Box>
        {isWideGame && (
          <WideGameInfo className={styles.wideGameInfoWrapper} game={game} />
        )}
        <Controls
          casinoType={casinoType}
          serviceName={serviceName}
          serviceURL={serviceURL}
          onGameClick={onGameClick}
          backTo={backTo}
          isMobileAppRedirect={isMobileAppRedirect}
          isNativeApp={isNativeApp}
          game={game}
          gameIndex={gameIndex}
          isWideGame={isWideGame}
          classNames={{
            wrapper: isWideGame ? styles.wideGameControls : styles.controls,
            control: styles.control
          }}
        />
        {isWagerInfoShow && (
          <WagerInfo
            isGameWagerExcluded={isGameWagerExcluded}
            className={isWideGame ? styles.wideWagerWrapper : styles.wager}
          />
        )}
      </Box>
    );
  }
  return null;
};

export default GameContent;
