import PropTypes from 'prop-types';

const TableHeader = ({ children, className = '', ...props }) => (
  <thead className={className} {...props}>
    {children}
  </thead>
);

TableHeader.propTypes = {
  children: PropTypes.node.isRequired
};

export default TableHeader;
