import { useMemo } from 'react';
import { prop } from 'ramda';
import { shallow } from 'zustand/shallow';
import { useI18n } from '@namespace/i18n';
import { useSportsbookStore } from '../../store';

export const selectSportsBookDataState = () => prop('sportDataState');

// TODO SHARED STATE
// TODO LEGACY CONTEXT
export const useSportsBookDataState = () => {
  const { language } = useI18n();
  const { isLoaded, lang, dataCachingTime } = useSportsbookStore(
    selectSportsBookDataState(),
    shallow
  );

  return useMemo(
    () => ({
      isSportsBookDataLoaded: isLoaded && lang === language,
      dataCachingTime
    }),
    [isLoaded, lang, language, dataCachingTime]
  );
};
