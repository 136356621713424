import { useLoadFavoritesList } from '../useLoadFavoritesList';
import { useLoadCasinoFeed } from '../useLoadCasinoFeed';
import { useLoadRecentlyPlayed } from '../useLoadRecentlyPlayed';
import { useLoadRecommendedGames } from '../useLoadRecommendedGames';
import { useLoadWagerSlotsGames } from '../useLoadWagerSlotsGames';
import { useLoadLiveRtpGames } from '../useLoadLiveRtpGames';

export const useLoadCasinoData = ({ feedNames = '', casinoType }) => {
  useLoadCasinoFeed({ feedNames });

  useLoadFavoritesList(casinoType);
  useLoadRecentlyPlayed(casinoType);
  useLoadRecommendedGames(casinoType);
  useLoadWagerSlotsGames();
  useLoadLiveRtpGames(feedNames);
};
