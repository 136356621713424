import MarketOutcomeContainer from '../EventMarketContainer/MarketOutcomeContainer';
import OutcomeButtonContainer from '../OutcomeButtonContainer';
import { useOutcomeField } from '../../store/selectors/outcome';
import styles from './index.module.css';

const OutcomeWithName = ({
  outcomeId,
  market,
  lineView,
  styles: inlineStyles = {},
  className = '',
  eventId = ''
}) => {
  const outcomeTypeId = useOutcomeField(outcomeId, 'outcomeTypeId');
  return (
    <MarketOutcomeContainer
      styles={inlineStyles}
      classNames={{
        outcomeContainerClassName: `${styles.outcome} ${
          lineView ? styles[lineView] : ''
        } ${className}`,
        outcomeMainClassName: styles[lineView]
      }}
      value={
        <OutcomeButtonContainer
          market={market}
          outcomeId={outcomeId}
          outcomeTypeId={outcomeTypeId}
          eventId={eventId}
          isShowName={true}
          lineView={lineView}
        />
      }
    />
  );
};

export default OutcomeWithName;
