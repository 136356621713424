const transformLangToISO = (lang, enableRuFlag = false) => {
  switch (lang) {
    case 'en':
      return 'GB';
    case 'uk':
      return 'UA';
    case 'ru':
      return enableRuFlag ? 'RU' : 'UA';
    case 'ch':
      return 'CN';
    case 'cn':
      return 'CN-T';
    case 'hi':
      return 'IN';
    default:
      return lang.toUpperCase();
  }
};

export default transformLangToISO;
