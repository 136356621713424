import types from '../types';

export default {
  [types.GET_SNIPPET]: (dispatch) => (id) => {
    dispatch({
      type: types.SET_NEW_SNIPPETS_ID,
      payload: {
        id
      }
    });
  }
};
