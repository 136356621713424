import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { useContext } from 'react';
import { DeviceContext } from '@namespace/device';
import { AdvFilterCloseButton } from './AdvFilterCloseButton';
import { FilterIcon } from '../../../../common/FilterIcon';
import styles from './index.module.css';

export const AdvFilterTitle = ({ className }) => {
  const { t } = useI18n();
  const { isDesktop } = useContext(DeviceContext);

  return (
    <Box gap="s" align="center" className={className}>
      <FilterIcon />
      <AText breed="BU" color="sm_txt_color_1" size="xl">
        {t('sportsBook.sportsMenu.advFilter.title')}
      </AText>
      {!isDesktop && (
        <AdvFilterCloseButton className={styles.closeIconWrapper} />
      )}
    </Box>
  );
};
