import { useEffect } from 'react';
import { useSetBodyClass } from '@namespace/helpers';

const useSetThemeClassToBody = (themeName) => {
  const { setBodyClass } = useSetBodyClass();

  useEffect(() => {
    setBodyClass(themeName);
  }, [themeName]);
};

export default useSetThemeClassToBody;
