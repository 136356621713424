import { Accordion } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { JackpotMobileShortInfo } from '../JackpotMobileShortInfo';
import { JackpotDetailsInfoColumn } from '../../common/JackpotDetailsInfoColumn';

import styles from './index.module.css';

export const JackpotItemMobile = ({
  jackpotType,
  jackpotInfo,
  isDetailsOpen,
  onToggle
}) => {
  return (
    <Accordion
      title={
        <JackpotMobileShortInfo
          jackpotType={jackpotType}
          jackpotInfo={jackpotInfo}
        />
      }
      icon={
        <Icon
          color="txt_color_4"
          size="s"
          name={`icons/general/nav/${isDetailsOpen ? 'collapse' : 'expand'}`}
        />
      }
      isOpen={isDetailsOpen}
      onToggle={onToggle}
      classNames={{
        headerClassName: styles.accordionHeader,
        containerClassName: `${styles.accordionContainer} ${
          styles[`accordionContainer-${jackpotType}`]
        }`,
        iconWrapperClassName: styles.arrowIcon
      }}
    >
      <JackpotDetailsInfoColumn
        className={styles.jackpotDetailsWrapper}
        jackpotInfo={jackpotInfo}
      />
    </Accordion>
  );
};
