import { getLocalStorageValue, LOCAL_STORAGE_KEYS } from '@namespace/helpers';
import { types } from '../types';

export const initialState = {
  cookieManagementType: getLocalStorageValue(
    LOCAL_STORAGE_KEYS.COOKIE_MANAGEMENT_TYPE
  ),
  prevCookieManagementType: getLocalStorageValue(
    LOCAL_STORAGE_KEYS.PREV_COOKIE_MANAGEMENT_TYPE
  ),
  showCookieManagementOnCookieDeclarationPage: false,
  customizeCookie: false
};

export const reducer = {
  [types.SET_COOKIE_MANAGEMENT_TYPE]: (state, cookieManagementType) => ({
    ...state,
    cookieManagementType
  }),
  [types.UPDATE_COOKIE_MANAGEMENT_TYPE]: (state, prevCookieManagementType) => ({
    ...state,
    prevCookieManagementType
  }),
  [types.SET_CUSTOMIZE_COOKIE]: (state, customizeCookie) => ({
    ...state,
    customizeCookie
  }),
  [types.SET_SHOW_COOKIE_MANAGEMENT_ON_COOKIE_DECLARATION_PAGE]: (
    state,
    showCookieManagementOnCookieDeclarationPage
  ) => ({
    ...state,
    showCookieManagementOnCookieDeclarationPage
  })
};
