import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { COLOR_SCHEME } from '@namespace/themes/src/constants';
import { useGetThemeConfig } from '@namespace/themes';
import { DesktopSelect } from './components/DesktopSelect';
import { MobileSelect } from './components/MobileSelect';
import { SELECT_TYPE } from '../../constants';
import { propsMerge } from '../../utils/propsMerge';

const emptyArr = [];

const Select = (props) => {
  const selectConfig = useGetThemeConfig('Select');
  const {
    options = emptyArr,
    value,
    onChange = noop,
    onBlur = noop,
    disabled,
    placeholder,
    isDesktop,
    size,
    arrowSize,
    intent = 'default',
    style,
    className,
    CustomTrigger,
    CustomContent,
    dataRole,
    additionSelectContentStyle = '',
    additionalPopoverStyle = '',
    additionalMobileSelectStyle = '',
    additionSelectTitleStyle = '',
    additionSelectOptionStyle = {},
    additionSelectStyles = {},
    haveToCloseOutsideClick = true,
    hasIcon = false,
    hasCheck = true,
    selectType = SELECT_TYPE.GENERAL,
    noPlaceholderColor = false,
    hasSearch = false,
    hasDesktopSelect = false,
    emptyText = '',
    intentType = COLOR_SCHEME.PRIMARY,
    showDropdownIconWhenOnlyOneOption = true,
    disableDropdownWhenOnlyOneOption = false,
    isExpandable = false,
    isOpenExternal,
    setIsOpenExternal,
    iconDropDownName,
    contentSize,
    ...rest
  } = propsMerge(props, selectConfig);

  const commonProps = {
    options,
    value,
    onChange,
    onBlur,
    disabled,
    placeholder,
    size,
    arrowSize,
    intent,
    className,
    style,
    dataRole,
    selectType,
    noPlaceholderColor,
    hasIcon,
    hasCheck,
    hasSearch,
    emptyText,
    showDropdownIconWhenOnlyOneOption,
    disableDropdownWhenOnlyOneOption,
    iconDropDownName,
    contentSize,
    isExpandable,
    isOpenExternal,
    setIsOpenExternal,
    ...rest
  };

  return isDesktop || hasSearch || hasDesktopSelect ? (
    <DesktopSelect
      {...commonProps}
      haveToCloseOutsideClick={haveToCloseOutsideClick}
      CustomTrigger={CustomTrigger}
      CustomContent={CustomContent}
      additionSelectContentStyle={additionSelectContentStyle}
      additionalPopoverStyle={additionalPopoverStyle}
      additionSelectTitleStyle={additionSelectTitleStyle}
      additionSelectStyles={additionSelectStyles}
      additionSelectOptionStyle={additionSelectOptionStyle}
      intentType={intentType}
    />
  ) : (
    <MobileSelect
      mobileSelectStyle={additionalMobileSelectStyle}
      intentType={intentType}
      {...commonProps}
    />
  );
};

Select.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  hasSearch: PropTypes.bool,
  hasDesktopSelect: PropTypes.bool,
  placeholder: PropTypes.string,
  emptyText: PropTypes.string,
  arrowSize: PropTypes.string,
  isDesktop: PropTypes.bool,
  hasIcon: PropTypes.bool,
  intent: PropTypes.oneOf(['error', 'success', 'default']),
  intentType: PropTypes.oneOf([COLOR_SCHEME.PRIMARY, COLOR_SCHEME.SECONDARY]),
  selectType: PropTypes.oneOf([SELECT_TYPE.GENERAL, SELECT_TYPE.ADDITIONAL]),
  noPlaceholderColor: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 's', 'm']),
  style: PropTypes.object,
  CustomTrigger: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType
  ]),
  showDropdownIconWhenOnlyOneOption: PropTypes.bool,
  disableDropdownWhenOnlyOneOption: PropTypes.bool
};

export default Select;
