import { useContext, useEffect } from 'react';
import { DeviceContext } from '@namespace/device';
import { NativeAppContext } from '@namespace/native-app';
import { SiteSettingsContext } from '@namespace/cms';
import { window } from '@namespace/helpers';
import { OnboardingContext } from '../../store/context';
import { MAP_OF_TOUR_NAMES_FROM_BE } from '../../constants';

export const useActionLinks = () => {
  const [onboarding, onboardingActions] = useContext(OnboardingContext);
  const { pageType } = useContext(DeviceContext);
  const { RUN, GO_TO_TOUR_BY_ID } = onboardingActions;
  const { tours } = onboarding;
  const tourIds = tours.map(({ id }) => id);
  const [{ isNativeApp }] = useContext(NativeAppContext);
  const [settings] = useContext(SiteSettingsContext);
  const { siteConfig = {} } = settings;
  const { mobileAppScheme = '' } = siteConfig;

  useEffect(() => {
    window.startOnboardingTour = (tourId) => {
      if (tourIds.includes(tourId)) {
        if (isNativeApp) {
          window.location = `${mobileAppScheme}onboarding/open?name=${MAP_OF_TOUR_NAMES_FROM_BE[tourId]}`;
        } else {
          GO_TO_TOUR_BY_ID({ tourId, pageType });
          RUN(true);
        }
      } else {
        console.error(
          `Incorrect tour id, there are only these tourIds: ${tourIds.join(
            ', '
          )}`
        );
      }
    };
  }, [GO_TO_TOUR_BY_ID, RUN, isNativeApp, mobileAppScheme, pageType, tourIds]);
};
