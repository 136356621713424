import { PasswordRecovery as CorePasswordRecovery } from '@namespace/auth';
import styles from './index.module.css';

const PasswordRecovery = ({ id }) => (
  <CorePasswordRecovery
    id={id}
    isHeader={false}
    className={styles.wrapper}
    showSupportSection={false}
  />
);

export default PasswordRecovery;
