import { useReducer, createContext, useEffect } from 'react';
import { debounce } from 'lodash';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import { useI18n } from '@namespace/i18n';
import actionCreators from '../actions';
import getSnippets from '../../api/getSnippets';
import types from '../types';
import { reducers } from '../reducers';
import extractSnippets from '../../utils/extractSnippets';

const REQUEST_TIME = 200;

export const SnippetsContext = createContext();

const getSnippetsDebounced = debounce(
  async (ids, lang, FALLBACK_SNIPPETS, SET_SNIPPETS) => {
    try {
      const response = await getSnippets(ids, lang);
      SET_SNIPPETS({
        snippets: extractSnippets(ids, response, FALLBACK_SNIPPETS)
      });
    } catch {
      SET_SNIPPETS({ snippets: extractSnippets(ids, {}, FALLBACK_SNIPPETS) });
    }
  },
  REQUEST_TIME
);

export const SnippetsProvider = ({
  FALLBACK_SNIPPETS = {},
  customReducers,
  snippets = {},
  children
}) => {
  const [state, dispatch] = useReducer(
    combineReducers(reducers, customReducers),
    {
      snippets,
      newSnippetIds: []
    }
  );

  const actions = useActions(types, dispatch, actionCreators);

  const { newSnippetIds } = state;
  const { SET_SNIPPETS } = actions;
  const { language } = useI18n();

  useEffect(() => {
    if (newSnippetIds.length) {
      getSnippetsDebounced(
        newSnippetIds,
        language,
        FALLBACK_SNIPPETS,
        SET_SNIPPETS
      );
    }
  }, [newSnippetIds, language, FALLBACK_SNIPPETS, SET_SNIPPETS]);

  return (
    <SnippetsContext.Provider value={[state, actions]}>
      {children}
    </SnippetsContext.Provider>
  );
};
