import { useContext } from 'react';
import { DeviceContext } from '@namespace/device';
import { AText } from '@alf/uikit';

const Header = ({ header, className = '' }) => {
  const { isDesktop } = useContext(DeviceContext);

  if (header) {
    return (
      <AText
        data-role="header-title"
        className={className}
        color="txt_color_3"
        align="center"
        breed="BU"
        size={isDesktop ? '4xl' : '3xl'}
      >
        {header}
      </AText>
    );
  }

  return null;
};

export default Header;
