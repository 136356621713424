import PropTypes from 'prop-types';
import Box from '../../../Box';
import styles from './index.module.css';

const Handle = ({
  domain: [min, max],
  handle: { id, value, percent },
  getHandleProps
}) => (
  <>
    <Box
      className={styles.handleComponent}
      style={{
        left: `${percent}%`
      }}
      {...getHandleProps(id)}
    />
    {}
    <Box
      className={styles.handleMainComponent}
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      style={{
        left: `${percent}%`
      }}
    />
  </>
);

Handle.propTypes = {
  domain: PropTypes.array.isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

Handle.defaultProps = {
  disabled: false
};

export default Handle;
