import { useOtherSettings } from '@namespace/cms';
import { useMemo } from 'react';

export const useAdvFilterSettings = () => {
  const { sportsBookAdvFilter } = useOtherSettings();

  const result = useMemo(() => {
    const {
      maxSportsCount = 2,
      maxCategoriesCount = 10,
      maxTournamentsCount = 20,
      ...other
    } = sportsBookAdvFilter ?? {};

    return {
      maxSportsCount,
      maxCategoriesCount,
      maxTournamentsCount,
      ...other
    };
  }, [sportsBookAdvFilter]);

  return result;
};
