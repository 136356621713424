import { omitBy } from 'lodash';
import { updateScore } from '../utils';

// TODO: cover sportsbook state with JSdoc and use here
/**
 * Make undo events scores for all socket messages with type 'event.undo_score'
 * @param state
 * @param {{
 *   event_id: number
 *   result_game_id: number
 *   result_type_id: number
 * }[]} data
 * @returns {*}
 */
const undoEventScore = (state, { eventId }) => {
  const event = state.events[eventId];

  if (!event) {
    return state;
  }

  const prevScore = state.prevLineScore.find(
    ({ eventId: id }) => id === eventId
  );

  return {
    events: {
      ...omitBy(state.events, ({ eventId: id }) => id === eventId),
      [event.eventId]: { ...event, ...prevScore }
    },
    prevLineScore: state.prevLineScore.filter(
      ({ eventId: id }) => id !== eventId
    )
  };
};

export const undoEventsScore = (state, data) =>
  updateScore(state, data, undoEventScore);
