import { useI18n } from '@namespace/i18n';
import { SUMMARIES_RESULT_TYPE_IDS } from '../../constants';
import { useEventFields, useIsSummariesScoreboard } from '../../store';

const useLiveEventState = (eventId = {}, isFullDateTime = false) => {
  const { t, f } = useI18n();
  const DATE_FORMATS_TYPES = f.getFormats();
  const {
    eventResultShortName,
    eventResultName,
    canceled = false,
    finished = false,
    inprogress = false,
    eventResultId,
    eventDt
  } = useEventFields({
    eventId,
    fields: [
      'eventResultShortName',
      'eventResultName',
      'canceled',
      'finished',
      'inprogress',
      'eventResultId',
      'eventDt'
    ]
  });
  const { isSummariesScoreboard } = useIsSummariesScoreboard(eventId);

  let emptyStateText = '';
  if (canceled) {
    emptyStateText = t('sportsbook.emptyState.canceled');
  } else if (finished) {
    emptyStateText = t('sportsbook.emptyState.finished');
  } else if (inprogress) {
    emptyStateText = t('sportsbook.emptyState.inprogress.startingNow');
  }

  const labelText =
    isSummariesScoreboard || SUMMARIES_RESULT_TYPE_IDS.includes(eventResultId)
      ? eventResultName
      : eventResultShortName;

  const eventTime = eventDt
    ? f
        .getDateTime({ date: eventDt * 1000 })
        .toFormat(
          isFullDateTime
            ? DATE_FORMATS_TYPES.RESULTS_DATE
            : DATE_FORMATS_TYPES.EVENTS_LINE_TIME
        )
    : '';

  return {
    emptyStateText,
    labelText,
    eventTime
  };
};

export default useLiveEventState;
