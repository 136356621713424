import { getAccountingError } from '@alf/validators';
import { FORM_ERRORS_TYPES } from '../../constants';

const structuringErrors = (response, textPrefix) => {
  if (response.error && response.errorCode) {
    return [
      {
        key: FORM_ERRORS_TYPES.GLOBAL_ERROR,
        error: response.error === 'yes',
        errorCode: response.errorCode,
        message: `${textPrefix}${response.errorCode}`
      }
    ];
  }
  return Object.keys(response).reduce((acc, key) => {
    const { error = '', errorCode = '' } = response[key];
    return [
      ...acc,
      {
        key,
        errorCode,
        error: error === 'yes',
        message: getAccountingError(errorCode || '')
      }
    ];
  }, []);
};

export default structuringErrors;
