import { camelizeKeys } from 'humps';
import { postAsUriParameters } from '@namespace/helpers';

const BASE_API_URL = '/accounting/api/';
const GET_SECRET_QUESTION_URL = 'forgot_password_check_email';
const CHECK_SECRET_ANSWER_URL = 'forgot_password_check_answer';
const FORGOT_PASSWORD_SEND_EMAIL = 'forgot_password_send_email';
const SAVE_NEW_PASSWORD_URL = 'forgot_password_save_password';
const SEND_CHANGE_PASSWORD_LINK = 'send_change_password_link';

const makePost = (data = {}, url = '') => {
  const postUrl = `${BASE_API_URL}${url}`;
  return postAsUriParameters(postUrl, data).then(camelizeKeys);
};

export const getSecretQuestion = (data) =>
  makePost(data, GET_SECRET_QUESTION_URL);
export const checkSecretAnswer = (data) =>
  makePost(data, CHECK_SECRET_ANSWER_URL);
export const saveNewPassword = (data) => makePost(data, SAVE_NEW_PASSWORD_URL);
export const forgotPasswordSendEmail = (data) =>
  makePost(data, FORGOT_PASSWORD_SEND_EMAIL);
export const sendChangePasswordLink = (data) =>
  makePost(data, SEND_CHANGE_PASSWORD_LINK);
