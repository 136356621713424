import { checkPersonalCode } from '@alf/validators';

export const PIN_CODE_COUNTRY_CODES = ['RO', 'HR'];

// const roPinValidator = async (params, isTooYoung) => {
//   // HOTFIX FIP-2069, must be fixed on back-end someday
//   let res = await checkPersonalCode(params);

//   const days = params.pin.slice(5, 7);
//   const month = params.pin.slice(3, 5);
//   const year = params.pin.slice(1, 3);

//   const currentYearInCentrury = parseInt(
//     `${new Date().getFullYear()}`.slice(2),
//     10
//   );

//   const dateFromPin = `${days}.${month}.${year > currentYearInCentrury ? '19' : '20'}${year}`; // eslint-disable-line

//   if (isTooYoung(dateFromPin)) {
//     res = { error: true, errorCode: 1529 };
//   }

//   return res;
// };

const pins = {
  RO: {
    type: 'cnp',
    request: (value) =>
      checkPersonalCode({
        pin: value,
        nationality: 'RO',
        pinType: 'cnp'
      })
  },
  HR: {
    type: 'oib',
    request: (value) =>
      checkPersonalCode({
        pin: value,
        pinType: 'oib'
      })
  }
};

export const isPinCode = (
  selectedCountry,
  countriesList = PIN_CODE_COUNTRY_CODES
) => countriesList.includes(selectedCountry);

const checkRegex = (value) => {
  const regex = /^\d+$/;
  return regex.test(value) ? { error: false } : { error: true, errorCode: 180 };
};

export const pinCodeValidator = (value, country) =>
  pins[country]?.request(value) || checkRegex(value);

export const getPinType = (country) => pins[country]?.type || '';
