import { filter, pathOr } from 'ramda';
import { shallow } from 'zustand/shallow';
import { createSelector } from 'reselect';
import { MODE } from '../../../constants';
import { useSportsbookSelector } from '../../store';

export const selectPopularSports = (serviceId = MODE.PRE_MATCH) =>
  createSelector(
    pathOr([], ['sports', serviceId]),
    filter(({ eventCount }) => eventCount > 0)
  );

export const usePopularSports = (serviceId) =>
  useSportsbookSelector(selectPopularSports, [serviceId], shallow);
