import {
  ACCOUNTING_BONUS_TYPES,
  OTHER_BONUS_TYPES,
  BONUS_STATE_TYPES,
  BONUS_SYSTEM_BONUS_TYPE
} from '../../../../constants';

const digitsSum = (values) => values.reduce((acc, i) => acc + (i || 0), 0);

const createSummedCounters = (counters, types) => {
  const accountingAndOtherBonusesCount = digitsSum([
    counters[BONUS_STATE_TYPES[ACCOUNTING_BONUS_TYPES.ALL]],
    counters[BONUS_STATE_TYPES[OTHER_BONUS_TYPES.JACKPOT_WINS]],
    counters[BONUS_STATE_TYPES[BONUS_SYSTEM_BONUS_TYPE]]
  ]);

  const fundsBonusesCount = digitsSum([
    counters[BONUS_STATE_TYPES[ACCOUNTING_BONUS_TYPES.REAL_MONEY]],
    counters[BONUS_STATE_TYPES[BONUS_SYSTEM_BONUS_TYPE]]
  ]);

  return {
    ...counters,
    ...(types.includes(BONUS_SYSTEM_BONUS_TYPE) && {
      [BONUS_STATE_TYPES[ACCOUNTING_BONUS_TYPES.REAL_MONEY]]: fundsBonusesCount
    }),
    [BONUS_STATE_TYPES[
      OTHER_BONUS_TYPES.ACCOUNTING_AND_OTHER_BONUSES
    ]]: accountingAndOtherBonusesCount
  };
};

export default createSummedCounters;
