import { useContext, useEffect } from 'react';
import { useOtherSettings } from '@namespace/cms';
import { BonusesContext } from '../../store/context';

const PreWagerBonusesWrapper = () => {
  const { isPreWagerBonusesEnabled = false } = useOtherSettings();
  const [, { FETCH_ACTIVE_WAGER_BONUS }] = useContext(BonusesContext);

  useEffect(() => {
    if (isPreWagerBonusesEnabled) {
      FETCH_ACTIVE_WAGER_BONUS();
    }
  }, [FETCH_ACTIVE_WAGER_BONUS, isPreWagerBonusesEnabled]);

  return null;
};

export default PreWagerBonusesWrapper;
