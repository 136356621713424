export const DEFAULT_FORM_CONFIG = {
  mode: 'onBlur',
  reValidateMode: 'onBlur'
};

export const FORM_COMPONENTS_TYPES = {
  SELECT: 'SELECT',
  RADIO_GROUP: 'RADIO_GROUP',
  LINK_GROUP: 'LINK_GROUP',
  INPUT_FIELD: 'INPUT_FIELD',
  EMAIL_INPUT_FIELD: 'EMAIL_INPUT_FIELD',
  INPUT_SELECT: 'INPUT_SELECT',
  CHECKBOX_FIELD: 'CHECKBOX_FIELD',
  TEXTAREA: 'TEXTAREA',
  PHONE_FIELD: 'PHONE_FIELD',
  TOKEN_DEPENDED_FIELD: 'TOKEN_DEPENDED_FIELD',
  PIX_KEY_FIELD: 'PIX_KEY_FIELD',
  PASSWORD_FIELD: 'PASSWORD_FIELD',
  FORM_ROW: 'form-row',
  BUTTON: 'button',
  TOGGLE_CHECKBOX_INFO: 'TOGGLE_CHECKBOX_INFO',
  BONUS_SLIDER: 'BONUS_SLIDER',
  BONUS_NOTIFICATION: 'BONUS_NOTIFICATION',
  // Registration
  REG_COUNTRY_SELECT: 'reg-country-select',
  REG_CURRENCY_SELECT: 'reg-currency-select',
  REG_SECRET_QUESTION_SELECT: 'reg-secret-question-select',
  REG_SECRET_ANSWER_INPUT: 'reg-secret-answer-input',
  NAME_INPUT: 'name-input',
  REG_DATE_PICKER: 'reg-date-picker',
  DOCUMENT_DATE_PICKER: 'document-date-picker',
  DATE_PICKER: 'date-picker',
  KEYBOARD_DATE_PICKER: 'keyboard-date-picker',
  REG_PHONE: 'reg-phone',
  SUBMIT_BUTTON: 'submit-button',
  MIDDLE_NAME_INPUT: 'middle-name-input',
  PIN_CODE_INPUT: 'pin-code-input',
  INFO_MESSAGE: 'info-message',
  CAPTCHA_FIELD: 'captcha-field',
  AMOUNT_INPUT_FIELD: 'amount-input-field',
  CARD_SELECT: 'card-select',
  CARD_EXPIRATION_DATE: 'card-expiration-date',
  REPEAT_PASSWORD: 'repeat-password',
  DOCUMENT_NUMBER_FIELD: 'document-number-field',
  GENDER_BUTTONS_GROUP: 'gender-buttons-group',
  GLOBAL_ERROR: 'global-error',
  VERIFICATION_FIELD: 'verification-field',
  FORM_HELP_TEXT: 'form-help-text',
  HR_LINE: 'hr-line',
  USER_COUNTRY_FIELD: 'user-country-field',
  DOCUMENT_NUMBER_WITH_TYPE: 'document-number-with-type',
  WELCOME_PACK_RADIO_SELECT: 'welcome-pack-radio-select',
  NEED_HELP: 'need-help',
  RE_CAPTCHA: 're-captcha'
};

export const FORM_VALIDATION_TYPES = {
  REQUIRED: 'required',
  REQUIRED_INPUT_SELECT: 'required-input-select',
  ZIP_CODE: 'zip-code',
  IFC_CODE: 'ifc-code',
  CITY: 'city',
  ADDRESS: 'address',
  PHONE_INPUT: 'phone-input',
  PHONE_NUMBER: 'phone-number',
  PHONE_ONLY_NUMBER: 'phone-only-number',
  PHONE_WITH_CODE: 'phone-with-code',
  PHONE_WITH_ISO_CODE: 'phone-with-iso-code',
  PHONE_COMPLEX: 'phone-complex',
  PASSWORD_REGEX: 'password_regex',
  AMOUNT: 'amount-input',
  PASSWORD_COMPARE_AND_CHECK: 'password-compare-and-check',
  CPF_NUMBER: 'cpf-number',
  EMAIL: 'email',
  EMAIL_ASYNC: 'email-async',
  PASSWORD_ASYNC: 'password-async',
  USERNAME_ASYNC: 'username-async',
  MAX_LENGTH_VALIDATOR: 'max-length-validator',
  MIN_LENGTH_VALIDATOR: 'min-length-validator',
  MIN_LENGTH_AND_CHECKPHONE_VALIDATOR: 'min-length-and-checkphone-validator',
  CREDIT_CARD_NUMBER_VALIDATOR: 'credit-card-number-validator',
  CREDIT_CARD_HOLDER_VALIDATOR: 'credit-card-holder-validator',
  BIN_CARD_NUMBER_VALIDATOR: 'bin-card-number-validator',
  NUMBER_FORMAT_VALIDATOR: 'number-format-validator',
  CHARS_AND_DIGITS_ONLY_FORMAT_VALIDATOR:
    'chars-and-digits-only-format-validator',
  CHARS_AND_ADDITIONAL_SYMBOLS_VALIDATOR:
    'chars-and-additional-symbols-validator',
  MIN_VALIDATOR: 'min-validator',
  MAX_VALIDATOR: 'max-validator',
  MULTIPLE_10_VALIDATOR: 'multiple-10-validator',
  REGEXP_VALIDATOR: 'regexp-validator',
  COMPARE_VALIDATOR: 'compare-validator',
  IBAN_ASYNC: 'iban-async',
  LATIN: 'latin',
  INN_ASYNC: 'inn-async',
  NOT_EMPTY_STRING_VALIDATOR: 'not-empty-string-validator',
  DOC_REQUESTED: 'doc-requested-validator',
  CODE_VALIDATOR: 'code-validator'
};

export const FORM_ERRORS_TYPES = {
  GLOBAL_ERROR: 'globalError'
};

export const CUSTOM_MSG_ERRORS_TYPES = {
  DOC_REQUESTED: 'docRequested'
};

export const DOC_TYPE_IDS = [1, 3];

export const DOC_DATE_PICKER_TYPES = {
  PLACED: 'placed',
  EXPIRY: 'expiry'
};
