import { useContext, useEffect, useState } from 'react';
import { useTimeout } from '@namespace/helpers';
import { NotificationContext } from '../../store/context';
import { closeNotificationAction } from '../../store/actions';
import SessionDurationToast from '../../common/SessionDurationToast';
import RealityCheckToast from '../../common/RealityCheckToast';
import SuspendedUserToast from '../../common/SuspendedUserToast';
import AddictedUserToast from '../../common/AddictedUserToast';
import SessionExpiredToast from '../../common/SessionExpiredToast';
import SessionExpiredPopup from '../../common/SessionExpiredPopup';
import UnverifiedUserToast from '../../common/UnverifiedUserToast';
import LudomanInVerificationToast from '../../common/LudomanInVerificationToast';
import DefaultToast from '../../common/DefaultToast';
import PassportVerificationToast from '../../common/PassportVerificationToast';
import AdditionalInfoUploadVerificationToast from '../../common/AdditionalInformationUploadToast';
import AdditionalInfoOtherVerificationToast from '../../common/AdditionalInfoOtherVerificationToast';
import VerifiedUserToast from '../../common/VerifiedUserToast';
import NotVerifiedUserToast from '../../common/NotVerifiedUserToast';
import useShowVerificationToast from '../../hooks/useShowVerificationToast';

import {
  DEFAULT_NOTIFICATION_TYPES,
  NOTIFICATION_TYPES
} from '../../constants';

const componentsMap = {
  [NOTIFICATION_TYPES.DEFAULT]: DefaultToast,
  [NOTIFICATION_TYPES.SESSION_DURATION_NOTICE]: SessionDurationToast,
  [NOTIFICATION_TYPES.REALITYCHECK]: RealityCheckToast,
  [NOTIFICATION_TYPES.SUSPENDED_USER]: SuspendedUserToast,
  [NOTIFICATION_TYPES.LUDOMAN_IN_VERIFICATION]: LudomanInVerificationToast,
  [NOTIFICATION_TYPES.ADDICTED_USER]: AddictedUserToast,
  [NOTIFICATION_TYPES.SESSION_EXPIRED]: SessionExpiredToast,
  [NOTIFICATION_TYPES.SESSION_EXPIRED_POPUP]: SessionExpiredPopup,
  [NOTIFICATION_TYPES.UNVERIFIED_USER]: UnverifiedUserToast,
  [NOTIFICATION_TYPES.PASSPORT_VERIFICATION]: PassportVerificationToast,
  [NOTIFICATION_TYPES.ADDITIONAL_INFORMATION_UPLOAD_VERIFICATION]: AdditionalInfoUploadVerificationToast,
  [NOTIFICATION_TYPES.ADDITIONAL_INFORMATION_OTHER_VERIFICATION]: AdditionalInfoOtherVerificationToast,
  [NOTIFICATION_TYPES.VERIFIED_USER]: VerifiedUserToast,
  [NOTIFICATION_TYPES.NOT_VERIFIED_USER]: NotVerifiedUserToast
};

const Notification = () => {
  const [
    {
      isShown,
      message,
      notificationType,
      NotificationComponent,
      timeout,
      notificationDispatch
    }
  ] = useContext(NotificationContext);

  const [timer, setTimer] = useState(timeout);

  const isErrorType = notificationType === DEFAULT_NOTIFICATION_TYPES.ERROR;

  const allowedToShow = useShowVerificationToast({ notificationType });
  const shouldShow = (isShown && allowedToShow) || isErrorType;

  const mouseEnterHandler = () => setTimer(null);
  const mouseLeaveHandler = () => setTimer(timeout);

  const closeCallback = () => {
    notificationDispatch(closeNotificationAction());
    mouseLeaveHandler();
  };

  useTimeout(closeCallback, shouldShow ? timer : null);

  useEffect(() => {
    if (shouldShow) {
      setTimer(timeout);
    }
  }, [timeout, shouldShow]);

  if (!isShown || (!allowedToShow && !isErrorType)) {
    return null;
  }

  const Component =
    NotificationComponent ||
    componentsMap[notificationType] ||
    componentsMap[NOTIFICATION_TYPES.DEFAULT];

  return (
    <Component
      message={message}
      type={notificationType}
      onClose={closeCallback}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    />
  );
};

export default Notification;
