// todo use from f.getFormats()
import { DATE_FORMATS_TYPES } from '@namespace/i18n';
import {
  DateSelect,
  FILTER_ALL_VALUE,
  PURCHASE_STATUS_FILTER,
  DATE_FILTER
} from '@namespace/common';
import { StatusIconWithText } from '@alf/uikit';
import { FILTER_FIELDS } from '../../constants';
import {
  DEPOSIT_AMOUNT_FILTER,
  WITHDRAW_AMOUNT_FILTER
} from '../../constants/filters';
import styles from './index.module.css';

export const formatStatusColumn = (
  { status, reason },
  fontStatusConfig,
  translate
) => (
  <>
    <StatusIconWithText
      label={translate(`balance.depositHistory.status.${status}`)}
      status={status}
      fontStatusConfig={fontStatusConfig}
    />
    {status === 'canceled' && reason && (
      <span className={styles.errorMessage}>{reason}</span>
    )}
  </>
);

export const formatDateColumn = (
  { dt, dtDone },
  formatDate,
  isNeedDoneTime = true,
  isDateWithLine = false
) => {
  let date = '';
  const dateFormat = isDateWithLine
    ? DATE_FORMATS_TYPES.DEPOSIT_HISTORY_DATE_TIME_NEW
    : DATE_FORMATS_TYPES.DEPOSIT_HISTORY_DATE_TIME;
  if (dt) {
    date += formatDate(dt, dateFormat);
  }
  if (dtDone && isNeedDoneTime) {
    date += ' - ';
    date += formatDate(dtDone, dateFormat);
  }
  return date;
};

export const getFiltersConfig = ({
  t,
  usedSidList,
  isTypeDeposit,
  filterFields = FILTER_FIELDS,
  dateFilterOptions = DATE_FILTER,
  amountFilterOptions
}) => {
  const fieldsList = {
    state: {
      key: 'state',
      dataRole: 'payments-state-filter',
      label: t('balance.depositHistory.filter.state'),
      options: PURCHASE_STATUS_FILTER,
      automationTestsClassName: isTypeDeposit
        ? 'depositStatusDropdown'
        : 'withdrawStatusDropdown'
    },
    wallet: {
      key: 'wallet',
      dataRole: 'payments-wallet-filter',
      label: t('balance.depositHistory.filter.wallet'),
      options: [
        {
          value: FILTER_ALL_VALUE,
          label: t('personalOffice.filter.options.depositHistory.wallet.all')
        },
        ...usedSidList
      ],
      automationTestsClassName: isTypeDeposit
        ? 'depositWalletsDropdown'
        : 'withdrawWalletsDropdown'
    },
    amount: {
      key: 'amount',
      dataRole: 'payments-amount-filter',
      label: t('balance.depositHistory.filter.amount'),
      options:
        amountFilterOptions ||
        (isTypeDeposit ? DEPOSIT_AMOUNT_FILTER : WITHDRAW_AMOUNT_FILTER),
      automationTestsClassName: isTypeDeposit
        ? 'depositAmountDropdown'
        : 'withdrawAmountDropdown'
    },
    dateOptionId: {
      key: 'dateOptionId',
      dataRole: 'payments-dateOptionId-filter',
      label: t('balance.depositHistory.filter.dateRange'),
      options: dateFilterOptions,
      automationTestsClassName: isTypeDeposit
        ? 'depositDateRangeDropdown'
        : 'withdrawDateRangeDropdown',
      Component: ({ defaultValue, ...rest }) => (
        <DateSelect value={defaultValue} {...rest} />
      )
    }
  };

  return filterFields.map((fieldKey) => {
    fieldsList.key = fieldKey;

    return fieldsList[fieldKey];
  });
};
