import { checkPhone } from '../../api';
import createAsyncValidator from '../../core/createAsyncValidator';

const phoneValidation = () =>
  createAsyncValidator(async (value) => {
    if (value.code === 'not valid')
      return {
        error: 'yes',
        error_code: 1509
      };
    return await checkPhone(value);
  });

export default phoneValidation;
