import { memo, useEffect, useMemo, useState } from 'react';
import { sortFavoriteSports } from '../../utils';
import { CyberSportBaseMenuListItem } from './CyberSportBaseMenuListItem';
import { useCyberSportCategoriesIds, useFavorites } from '../../store';

const CyberSportBaseMenuListBare = ({
  activeSportId,
  basePath,
  categoryRoute,
  showCategories,
  serviceId,
  eventId,
  categorySportId,
  tournamentId,
  countable = true,
  showPinIcon = false,
  categoriesWithIcon
}) => {
  const [isVisibleCurrentCategories, setIsVisibleCurrentCategories] = useState(
    true
  );
  const favoritesSports = useFavorites('sports');

  useEffect(() => {
    if (
      tournamentId &&
      activeSportId === categorySportId &&
      !isVisibleCurrentCategories
    ) {
      setIsVisibleCurrentCategories(true);
    }
  }, [tournamentId]);

  useEffect(() => {
    if (eventId) {
      setIsVisibleCurrentCategories(true);
    }
  }, [eventId]);

  const categories = useCyberSportCategoriesIds();
  const items = useMemo(
    () =>
      sortFavoriteSports(
        categories.map((id) => ({ sportId: id })),
        favoritesSports
      ),
    [categories, favoritesSports]
  );

  return items.map(({ sportId }) => (
    <CyberSportBaseMenuListItem
      key={sportId}
      categoryId={sportId}
      tournamentId={tournamentId}
      activeSportId={activeSportId}
      isVisibleCurrentCategories={isVisibleCurrentCategories}
      setIsVisibleCurrentCategories={setIsVisibleCurrentCategories}
      showCategories={showCategories}
      basePath={basePath}
      countable={countable}
      showPinIcon={showPinIcon}
      serviceId={serviceId}
      categoryRoute={categoryRoute}
      categoriesWithIcon={categoriesWithIcon}
    />
  ));
};

export const CyberSportBaseMenuList = memo(CyberSportBaseMenuListBare);
