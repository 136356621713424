import { useCallback } from 'react';
import { formatAsUriParameters } from '@namespace/helpers';
import { useLocation } from '@namespace/i18n';
import useHistory from '../useHistory';
import useSearchParams from '../useSearchParams';

const useAddSearchParams = () => {
  const params = useSearchParams();
  const { replace, push } = useHistory();
  const { pathname } = useLocation();

  return useCallback(
    (newParams, newPath, isShouldPush = false) => {
      const search = formatAsUriParameters({ ...params, ...newParams }, true);
      const url = `${newPath || pathname}?${search}`;
      return isShouldPush ? push(url) : replace(url);
    },
    [params, pathname, push, replace]
  );
};

export default useAddSearchParams;
