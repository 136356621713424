import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useI18n } from '@namespace/i18n';
import { UserContext } from '@namespace/user';
import { isString } from 'lodash';
import {
  BLOCK_PHONE_VERIFICATION,
  ERROR_TYPES,
  PHONE_VERIFICATION_ERRORS
} from '../../constants';
import useCountdown from '../useCountdown';

const formatError = ({ code, message }) => {
  const errorData = isString(code) ? { reason: code } : code;
  return errorData.reason ? errorData : { reason: message };
};

const usePhoneVerification = () => {
  const { f } = useI18n();
  const [, userActions] = useContext(UserContext);
  const { PHONE_VERIFY, PHONE_CONFIRM } = userActions;
  const [verificationData, setVerificationData] = useState({});
  const { verificationTime = 0, error = {}, locked = {} } = verificationData;
  const [isVerifying, setIsVerifying] = useState(false);
  const { time: verifyTime, isCompleted: isVerifyTimeout } = useCountdown(
    verificationTime
  );
  const { time: lockedTime } = useCountdown(locked?.restTime);

  const isLocked = useMemo(
    () =>
      Boolean(lockedTime) || BLOCK_PHONE_VERIFICATION.includes(error?.reason),
    [error, lockedTime]
  );
  const isVerificationTimeout = useMemo(
    () => error?.reason === PHONE_VERIFICATION_ERRORS.VERIFICATION_TIMEOUT,
    [error]
  );

  const cancel = useCallback(() => {
    setVerificationData({});
    setIsVerifying(false);
  }, [setVerificationData, setIsVerifying]);

  const handleError = useCallback(
    (e, type) => {
      const parsedError = formatError(e);
      if (parsedError.reason?.rest_time) {
        const restTime =
          f.getDateTime().ts + parsedError.reason.rest_time * 1000;

        setVerificationData((prevState) => ({
          ...prevState,
          locked: { ...parsedError, restTime }
        }));
      } else {
        setVerificationData((prevState) => ({
          ...prevState,
          error: { ...parsedError, type }
        }));
      }
    },
    [f, setVerificationData]
  );

  const verify = useCallback(
    ({ phoneNumber }) =>
      PHONE_VERIFY({ phoneNumber })
        .then((response) => {
          const { data } = response;
          const { phoneVerificationTtl } = data;
          setVerificationData({
            verificationTime: f.getDateTime().ts + phoneVerificationTtl * 1000,
            ...data
          });
          setIsVerifying(true);
          return response;
        })
        .catch((e = {}) => {
          handleError(e, ERROR_TYPES.VERIFY);
          throw e;
        }),
    [f, PHONE_VERIFY, setVerificationData, handleError, setIsVerifying]
  );

  const confirm = useCallback(
    ({ code }, afterConfirmCallback) =>
      PHONE_CONFIRM({
        confirmCode: code,
        sessionId: verificationData.sessionId
      })
        .then(() => {
          cancel();
          if (afterConfirmCallback) {
            afterConfirmCallback();
          }
        })
        .catch((e = {}) => {
          handleError(e, ERROR_TYPES.CONFIRM);
          throw e;
        }),
    [verificationData, PHONE_CONFIRM, handleError, cancel]
  );

  useEffect(() => {
    if (verifyTime) {
      setIsVerifying(true);
    }
  }, [verifyTime]);

  useEffect(() => {
    if (isVerifyTimeout) {
      handleError(
        { code: PHONE_VERIFICATION_ERRORS.VERIFICATION_TIMEOUT },
        ERROR_TYPES.CONFIRM
      );
      setVerificationData((prevState) => ({
        ...prevState,
        verificationTime: 0
      }));
    }
  }, [isVerifyTimeout]);

  return {
    verify,
    confirm,
    cancel,
    verificationData,
    isVerificationTimeout,
    isVerifying,
    isLocked,
    verifyTime,
    lockedTime
  };
};

export default usePhoneVerification;
