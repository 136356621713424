import { useReducer, useMemo } from 'react';
import {
  baseReducer as combineReducers,
  useActions
} from '@namespace/helpers-store';
import { BonusesContext } from '../context';
import { actions as actionCreators } from '../actions';
import { reducers, initialState } from '../reducers';
import { types } from '../types';

export const BonusesProvider = ({ customReducers, children }) => {
  const memoReducers = useMemo(
    () => combineReducers(reducers, customReducers),
    [customReducers]
  );
  const [state, dispatch] = useReducer(memoReducers, initialState);

  const actions = useActions(types, dispatch, actionCreators);

  return (
    <BonusesContext.Provider value={[state, actions]}>
      {children}
    </BonusesContext.Provider>
  );
};
