import { SEGMENT_FIELDS } from '@namespace/helpers';
import { ALL_VALUE, SEGMENT_TECH_FIELDS } from '../../constants';
import isInList from '../isInList';

const handlersMap = {
  [SEGMENT_FIELDS.BALANCE_FROM]: (segmentValue, filterValue) =>
    filterValue < segmentValue,
  [SEGMENT_FIELDS.BALANCE_TO]: (segmentValue, filterValue) =>
    filterValue > segmentValue,
  [SEGMENT_FIELDS.USER_GROUP]: (segmentValue, filterValue) =>
    filterValue !== segmentValue,
  [SEGMENT_FIELDS.REGISTRATION]: (segmentValue, filterValue) =>
    filterValue !== segmentValue,
  [SEGMENT_FIELDS.VIP]: (segmentValue, filterValue) =>
    !segmentValue.some((item) => filterValue?.includes(item)),
  [SEGMENT_FIELDS.AFFILIATE_ID]: (segmentValue, filterValue) =>
    !segmentValue.includes(filterValue),
  [SEGMENT_FIELDS.CREATIVE_ID]: (segmentValue, filterValue) =>
    !segmentValue.includes(filterValue),
  [SEGMENT_FIELDS.SITE_TRACKER_ID]: (segmentValue, filterValue) =>
    !segmentValue.includes(filterValue),
  [SEGMENT_FIELDS.ANID_ZONE_ID]: (segmentValue, filterValue) =>
    !segmentValue.includes(filterValue),
  [SEGMENT_FIELDS.LANGUAGES]: (segmentValue, filterValue, key, segment) =>
    isInList(segmentValue, filterValue, key, segment),
  [SEGMENT_FIELDS.COUNTRIES]: (segmentValue, filterValue, key, segment) =>
    isInList(segmentValue, filterValue, key, segment),
  [SEGMENT_FIELDS.AGE_FROM]: (segmentValue, filterValue) =>
    filterValue < segmentValue,
  [SEGMENT_FIELDS.AGE_TO]: (segmentValue, filterValue) =>
    filterValue > segmentValue,
  [SEGMENT_FIELDS.ACCOUNT_VERIFIED]: (segmentValue, filterValue) =>
    filterValue !== segmentValue,
  [SEGMENT_FIELDS.USER_DEPOSITED]: (segmentValue, filterValue) =>
    filterValue !== segmentValue
};

const getNewUserSegments = (segments = [], filter) => {
  const allSegmentsNames = [];
  const userSegmentsNames = [];
  const newUserSegments = segments.filter((segment) => {
    allSegmentsNames.push(segment?.techFields?.segmentName);
    let isEqual = true;
    for (const [key, value] of Object.entries(segment)) {
      if (key === SEGMENT_TECH_FIELDS.TECH_FIELDS) continue;
      const isNotEqual = handlersMap[key];

      if (
        filter[key] !== ALL_VALUE && // it needed for special marketing logic, registration, vip fields must be ignored for authorised user
        isNotEqual &&
        isNotEqual(value, filter[key], key, segment)
      ) {
        isEqual = false;
        break;
      }
    }
    if (isEqual) {
      userSegmentsNames.push(segment?.techFields?.segmentName);
    }
    return isEqual;
  });

  return { newUserSegments, userSegmentsNames, allSegmentsNames };
};

export default getNewUserSegments;
