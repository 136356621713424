import { useState, useEffect, useRef } from 'react';
import { throttle } from 'lodash';
import Flicking, { FlickingError } from '@egjs/react-flicking';
import { scrollTo, window, document } from '@namespace/helpers';
import styles from './index.module.css';
import '@egjs/react-flicking/dist/flicking.css';

const DIRECTIONS = {
  NEXT: 'NEXT',
  PREV: 'PREV'
};

const PageScroll = ({
  slides = [],
  slidesUrls = [],
  defaultSlide = 0,
  activeSlideClass = 'active',
  animationDuration = 750
}) => {
  const [currentPanel, setCurrentPanel] = useState(defaultSlide);
  const [overflowHidden, setOverflowHidden] = useState(true);
  const flickingRef = useRef();
  const wrapperRef = useRef();
  const isLastSlide = currentPanel === slides.length - 1;
  const calculatedSliderHeight = 'calc(100vh - var(--header-height))';

  const nextSlide = () => {
    flickingRef.current?.next(animationDuration).catch((err) => {
      if (err instanceof FlickingError) return;
      throw err;
    });
  };

  const prevSlide = () => {
    flickingRef.current?.prev(animationDuration).catch((err) => {
      if (err instanceof FlickingError) return;
      throw err;
    });
  };

  useEffect(() => {
    const handleWheel = throttle(
      (e) => {
        const delta = e.wheelDeltaY || e.deltaY * -1;
        if (delta < 0) {
          nextSlide();
        } else if (delta > 0) {
          prevSlide();
        }
      },
      300,
      { trailing: false }
    );

    window.addEventListener('wheel', handleWheel, { passive: false });
    return () => window.removeEventListener('wheel', handleWheel);
  }, []);

  useEffect(() => {
    if (isLastSlide) {
      setTimeout(() => setOverflowHidden(false), 500);
    } else {
      setOverflowHidden(true);
    }
  }, [isLastSlide]);

  useEffect(() => {
    scrollTo();
  }, [currentPanel]);

  useEffect(() => {
    if (overflowHidden) {
      document.body.classList.add(styles.overflow);
    } else {
      document.body.classList.remove(styles.overflow);
    }
  }, [overflowHidden]);

  const getSliderClientRect = () => {
    if (wrapperRef.current) {
      return wrapperRef.current.getBoundingClientRect();
    }
    return null;
  };

  const handleHoldEnd = ({ index, direction }) => {
    if (index === slides.length - 1) {
      if (direction === DIRECTIONS.NEXT) {
        scrollTo({ top: getSliderClientRect().bottom, behavior: 'smooth' });
      } else if (direction === DIRECTIONS.PREV) {
        scrollTo({ behavior: 'smooth' });
      }
    }
  };

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      <Flicking
        style={{ height: calculatedSliderHeight, width: '100%' }}
        ref={flickingRef}
        horizontal={false}
        autoResize={true}
        adaptive={true}
        duration={animationDuration}
        defaultIndex={defaultSlide}
        onWillChange={({ index }) => {
          setCurrentPanel(index);
        }}
        onHoldEnd={handleHoldEnd}
      >
        {slides.map((slide, index) => (
          <div
            style={{
              height: '100%',
              width: '100%'
            }}
            key={slidesUrls[index] || index}
            className={currentPanel === index ? activeSlideClass : ''}
          >
            {slide}
          </div>
        ))}
      </Flicking>
      {currentPanel !== slides.length - 1 && (
        <div className={styles.nextButton} onClick={nextSlide} />
      )}
    </div>
  );
};

export default PageScroll;
