import { useContext } from 'react';
import { DeviceContext } from '@namespace/device';
import { Box, AText } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import PromoCarousel from './PromoCarousel';
import { PromotionsItemWrapper } from '../../../../../common/PromotionsItemWrapper';

const PromoSection = ({ name, customClassNames, promos = [] }) => {
  const { t } = useI18n();
  const { isDesktop, isTablet } = useContext(DeviceContext);

  return (
    <Box gap="s" direction="column">
      <AText size="m" breed="B">
        {t(`web.promos.sections.${name}`)}
      </AText>
      <Box wrap={isDesktop} {...(isDesktop ? { gap: 's' } : {})}>
        {isDesktop ? (
          promos.map((promo, index) => (
            <PromotionsItemWrapper
              key={promo.promoId}
              promo={promo}
              index={index + 1}
              tagPath="all"
              customClassNames={customClassNames}
            />
          ))
        ) : (
          <PromoCarousel
            isTablet={isTablet}
            promosList={promos}
            customClassNames={customClassNames}
          />
        )}
      </Box>
    </Box>
  );
};

export default PromoSection;
