import { useCallback } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useOtherSettings } from '@namespace/cms';
import {
  LOG_ACTIONS,
  NEW_RELIC_LOG_ERRORS,
  logNewRelicAction
} from '@namespace/analytics';
import { useColorScheme } from '@namespace/themes';
import { Controller, useFormContext } from 'react-hook-form';
import { window } from '@namespace/helpers';
import { useI18n } from '@namespace/i18n';
import { Box } from '@alf/uikit';
import Control from '../../common/Control';
import useOnChangeValidate from '../../hooks/useOnChangeValidate';
import styles from './index.module.css';

export const ReCaptchaField = ({ name, isOnChangeValidation = false }) => {
  const { reCaptchaConfig = {} } = useOtherSettings();
  const { reCaptchaWidgetId = '', reCaptchaSize = 'normal' } = reCaptchaConfig;

  const { language } = useI18n();

  const { colorScheme } = useColorScheme();
  const { activeColorScheme } = colorScheme;

  const methods = useFormContext();
  const { control } = methods;

  useOnChangeValidate({ name, isOnChangeValidation });

  const handleChange = useCallback(
    (fieldOnChange) => (e) => {
      logNewRelicAction(LOG_ACTIONS.RECAPTCHA_WIDGET_SUCCESS, {
        message: 'ReCAPTCHA widget was successfully resolved'
      });
      fieldOnChange(e);
    },
    []
  );

  if (!reCaptchaWidgetId) {
    return null;
  }

  return (
    <Control id={name} classNames={{ wrapperClassName: styles.wrapper }}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const { onChange: controllerOnChange, ...controllerFields } = field;
          return (
            <Box justify="center" flexGrow={1}>
              <ReCAPTCHA
                sitekey={reCaptchaWidgetId}
                grecaptcha={window.grecaptcha}
                theme={activeColorScheme}
                onChange={handleChange(controllerOnChange)}
                onErrored={() =>
                  logNewRelicAction(
                    NEW_RELIC_LOG_ERRORS.RECAPTCHA_WIDGET_ERROR,
                    {
                      message: 'ReCAPTCHA widget error'
                    }
                  )
                }
                hl={language}
                asyncScriptOnLoad={() =>
                  logNewRelicAction(LOG_ACTIONS.RECAPTCHA_WIDGET_SUCCESS, {
                    message: 'ReCAPTCHA widget was successfully load'
                  })
                }
                size={reCaptchaSize}
                {...controllerFields}
              />
            </Box>
          );
        }}
      />
    </Control>
  );
};
