import { useI18n, LocalizedLink } from '@namespace/i18n';
import { Icon } from '@namespace/icons2';
import { useGeneralRoutes } from '@namespace/cms';
import { Box } from '@alf/uikit';
import { useUserInfoLoaded } from '@namespace/user';
import styles from './index.module.css';

const LiveEventsButton = () => {
  const { t } = useI18n();
  const { liveFavorites } = useGeneralRoutes();
  const { userInfoLoaded, isGuest } = useUserInfoLoaded();

  if (userInfoLoaded && !isGuest) {
    return (
      <Box className={styles.buttonWrapper} justify="center">
        <Box className={styles.button} align="center">
          <LocalizedLink to={liveFavorites} className={styles.buttonText}>
            {t('sportsbook.goToLiveFavoritesPart1')}
            <span className={styles.marked}>
              {t('sportsbook.goToLiveFavoritesPart2')}
            </span>
          </LocalizedLink>
          <Icon
            name="icons/general/nav/go-to"
            color="btn_quaternary_color_3"
            size="s"
          />
        </Box>
      </Box>
    );
  }

  return null;
};

export default LiveEventsButton;
