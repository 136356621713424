const checkIsActivePromo = (showFrom, showTo, isRecentlyPromo) => {
  const currentDate = new Date();
  const showFromDate = new Date(showFrom);
  const showToDate = new Date(showTo);

  if (isRecentlyPromo) {
    return showToDate < currentDate && currentDate >= showFromDate;
  }

  return showFromDate <= currentDate && currentDate <= showToDate;
};

export default checkIsActivePromo;
