import { useContext } from 'react';
import { useGetPaymentService } from '../../store/selectors/useGetPaymentService';
import { SWPaymentsContext } from '../../store/context';
import { PAYMENT_TYPES } from '../../constants';

export const useGetServiceProperties = (withLimits = false) => {
  const [{ activePaymentType }] = useContext(SWPaymentsContext);
  const isDeposit = activePaymentType === PAYMENT_TYPES.DEPOSIT;
  const {
    tax: { amount, type } = {},
    withdrawal = {},
    limits = {},
    currency
  } = useGetPaymentService();
  const { tax = {} } = withdrawal;
  const { amount: withdrawalAmount } = tax;
  const withdrawalFeeAmount = withdrawalAmount || amount;
  const feeAmount = isDeposit ? amount : withdrawalFeeAmount;
  const { min, max } = limits;
  const limitsWithCurrency = {
    min: min && currency && `${min} ${currency}`,
    max: max && currency && `${max} ${currency}`
  };

  return {
    amount: feeAmount,
    type: isDeposit && type,
    min,
    max,
    limits: withLimits ? limitsWithCurrency : {}
  };
};
