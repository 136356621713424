import { AText, Box } from '@alf/uikit';
import styles from './index.module.css';

const EventLabel = ({ className = '', text = '', children, dataRole }) => (
  <Box className={`${className} ${styles.label}`} data-role={dataRole}>
    <AText size="xs" align="center">
      {children || text}
    </AText>
  </Box>
);

export default EventLabel;
