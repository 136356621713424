import PropTypes from 'prop-types';

const TableBody = ({ children, className = '', ...props }) => (
  <tbody className={className} {...props}>
    {children}
  </tbody>
);

TableBody.propTypes = {
  children: PropTypes.node.isRequired
};

export default TableBody;
