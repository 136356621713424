import { useCallback, useContext, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { useResizeObserver } from '@namespace/device';
import { USER_ROLES, UserContext } from '@namespace/user';

const ROW_SIZES = [
  { maxWidth: 375, count: 3 },
  { maxWidth: 580, count: 4 }
];
const ROWS_COUNT = 3;

export const useGrid = (items, { showCustomFilter } = {}) => {
  const [screenWidth] = useResizeObserver();
  const [user] = useContext(UserContext);
  const { role } = user;
  const [list, setList] = useState(items);
  const [cachedList, setCachedList] = useState(items);
  const [isShowAllSports, setIsShowAllSports] = useState(false);

  if (!isEqual(cachedList, items)) {
    setCachedList(items);
  }
  // TODO: figure out if there is a way to do it using css
  // what does this effect does:
  // calculates amount of items which can be rendered in a row properly depending on screen width
  useEffect(() => {
    const { count: rowSize } = ROW_SIZES.find(
      (row) => screenWidth < row.maxWidth
    ) || { count: 5 };

    let sportsCount = rowSize * ROWS_COUNT - 1;
    if (role === USER_ROLES.USER) {
      sportsCount--;
    }
    if (showCustomFilter) {
      sportsCount--;
    }

    if (
      !isShowAllSports &&
      cachedList.length > 0 &&
      cachedList.length <= sportsCount + 1
    ) {
      setIsShowAllSports(true);
    }

    if (!isShowAllSports) {
      const trimmedSportsList = cachedList.filter(
        (sport, i) => i < sportsCount
      );
      setList(trimmedSportsList);
    } else {
      setList(cachedList);
    }
  }, [cachedList, screenWidth, isShowAllSports, role, showCustomFilter]);

  const onToggle = useCallback(() => {
    setIsShowAllSports((prevState) => !prevState);
    setList(items);
  }, [items]);

  return {
    isShowAllSports,
    onToggle,
    list
  };
};
