import { useOnSlideMove } from '@namespace/common';
import { LocalizedLink } from '@namespace/i18n';
import { AText, Box } from '@alf/uikit';
import { SportIcon } from '@namespace/icons2';
import { useEventFields } from '../../../../store';
import styles from './index.module.css';

const Title = ({ eventId, link, title, isShowFlag, dataRole }) => {
  const { sportId, sportName, categoryName, tournamentName } = useEventFields({
    eventId,
    fields: ['sportId', 'sportName', 'categoryName', 'tournamentName']
  });
  const { handleClickOnMove } = useOnSlideMove();

  return (
    <LocalizedLink onClick={handleClickOnMove} to={link}>
      <Box
        data-role={dataRole}
        align="center"
        padding="0 s"
        className={styles.eventTitleContainer}
      >
        {isShowFlag && (
          <Box className={styles.sportIcon} margin="0 s 0 0">
            <SportIcon color="line_subheader_txt" sportId={sportId} size="xs" />
          </Box>
        )}
        <AText
          breed="RU"
          size="s"
          color="line_subheader_txt"
          className={styles.eventTitle}
        >
          {title || `${sportName} | ${categoryName} | ${tournamentName}`}
        </AText>
      </Box>
    </LocalizedLink>
  );
};

export default Title;
