import { useContext, useEffect, useState, useRef } from 'react';
import {
  getSessionStorageValue,
  SESSION_STORAGE_KEYS,
  setSessionStorageValue,
  window
} from '@namespace/helpers';
import { useOtherSettings } from '@namespace/cms';
import { UserContext } from '@namespace/user';
import PrivacyPolicyBanner from './PrivacyPolicyBanner';
import PrivacyPolicyNotification from './PrivacyPolicyNotification';

const PrivacyPolicyConfirm = () => {
  const showPrivacyPolicyRef = useRef(
    getSessionStorageValue(
      SESSION_STORAGE_KEYS.SHOW_PRIVACY_POLICY_BANNER,
      false
    )
  );

  const [showPolicyAcceptance, setShowPolicyAcceptance] = useState(
    showPrivacyPolicyRef.current
  );
  const [notificationShowMore, setNotificationShowMore] = useState(false);

  const { policyBannerTitle = '' } = useOtherSettings();
  const [user, userActions] = useContext(UserContext);
  const { accountStatuses, currentPolicyContentId, id: userId } = user;
  const { policyBlock } = accountStatuses;
  const { UPDATE_POLICY } = userActions;

  useEffect(() => {
    if (user.role === 'user') {
      const currentShowPrivacyPolicy = getSessionStorageValue(
        SESSION_STORAGE_KEYS.SHOW_PRIVACY_POLICY_BANNER,
        false
      );

      if (showPrivacyPolicyRef.current !== currentShowPrivacyPolicy) {
        setShowPolicyAcceptance(currentShowPrivacyPolicy);
      }
    }
  }, [user.role]);

  const handleAccept = async () => {
    try {
      await UPDATE_POLICY(userId);
    } catch (error) {
      console.error('Error accepting policy or fetching user data: ', error);
    }
  };

  const handleCancel = () => {
    setSessionStorageValue(
      SESSION_STORAGE_KEYS.SHOW_PRIVACY_POLICY_BANNER,
      true
    );
    setShowPolicyAcceptance(true);
    setNotificationShowMore(true);
  };

  const handleShowMoreToggle = () => {
    setNotificationShowMore((state) => !state);
  };

  window.closeBanner = handleCancel;

  if (!policyBlock) return null;

  return showPolicyAcceptance ? (
    <PrivacyPolicyNotification
      isNotification={true}
      onAccept={handleAccept}
      showMore={notificationShowMore}
      onShowMoreToggle={handleShowMoreToggle}
      currentPolicyContentId={currentPolicyContentId} // change to policy_id_1 for test on de1.me
      currentPolicyTitleId={policyBannerTitle}
    />
  ) : (
    <PrivacyPolicyBanner
      onAccept={handleAccept}
      onClose={handleCancel}
      currentPolicyContentId={currentPolicyContentId} // change to policy_id_1 for test on de1.me
      policyBannerTitle={policyBannerTitle}
    />
  );
};

export default PrivacyPolicyConfirm;
