import { useMemo } from 'react';
import { isNull } from 'lodash';
import { LocalizedNavLink } from '@namespace/i18n';
import { AText, Box } from '@alf/uikit';
import { SportIcon } from '@namespace/icons2';
import { useSportsEventCount } from '../../../store';
import { useGetPathParams } from '../../../hooks/useGetPathParams';
import { MODE } from '../../../constants';
import styles from './index.module.css';

const ListAllItem = ({
  title,
  route,
  isActive = null,
  serviceIds = [],
  sportId,
  classNames = {},
  withIcons = false,
  onClick,
  dataRole
}) => {
  const {
    itemClass = '',
    activeClass = '',
    itemRow = '',
    text = '',
    counter = '',
    sportIcon = ''
  } = classNames;
  const textStyle = text || styles.left;
  const counterStyle = counter || styles.count;
  const { activeSport } = useGetPathParams(MODE.IN_PLAY);
  const isActiveSport = useMemo(() => {
    if (!isNull(isActive)) {
      return isActive;
    }
    return activeSport || {};
  }, [isActive, activeSport]);
  const allServiceIds = useMemo(() => [MODE.IN_PLAY, ...serviceIds], [
    serviceIds
  ]);

  const allCount = useSportsEventCount(allServiceIds, sportId);

  return (
    <LocalizedNavLink
      to={route}
      className={`${styles.item} ${itemClass} ${itemRow}`}
      activeClassName={isActiveSport ? `${styles.active} ${activeClass}` : ''}
      onClick={onClick}
      dataRole={dataRole && `${dataRole}-all-item`}
    >
      {withIcons && (
        <SportIcon
          data-role={dataRole && `${dataRole}-all-icon`}
          sportId={sportId}
          className={sportIcon}
          size="s"
        />
      )}
      <AText breed="BU" size="m" className={textStyle}>
        {title}
      </AText>
      <Box
        data-role={dataRole && `${dataRole}-all-counter`}
        align="center"
        justify="center"
        flexShrink={0}
        className={counterStyle}
      >
        <AText breed="R" size="xs" color="sm_txt_color_1">
          {allCount}
        </AText>
      </Box>
    </LocalizedNavLink>
  );
};

export default ListAllItem;
