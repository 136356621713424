import { useState } from 'react';
import { useNotifications } from '@namespace/notifications';
import { useI18n } from '@namespace/i18n';
import { logAction, LOG_ACTIONS } from '@namespace/analytics';
import { PAN_KEEPER_TYPES } from '@namespace/pankeeper';
import { useDeposit } from '../useDeposit';

const submitButtonValue = {
  [PAN_KEEPER_TYPES.WITHDRAW]: 'balance.withdraw.form.submit',
  [PAN_KEEPER_TYPES.DEPOSIT]: 'balance.withdraw.form.addAndDeposit',
  [PAN_KEEPER_TYPES.ADD]: 'balance.withdraw.form.addAndDeposit'
};

// for usage only in withdrawal flow
export const usePanKeeperTypeChange = ({ onWithdraw }) => {
  const { t } = useI18n();
  const [
    { getCardHash, panKeeperType, isCardInputReady } = {},
    setPanKeeperInstance
  ] = useState({});
  const { onDeposit, isFetching } = useDeposit();
  const { errorNotification } = useNotifications();

  const withdraw = async (data) => {
    try {
      const { cardHash } = await getCardHash();

      if (!cardHash) {
        errorNotification(t('balance.withdraw.bankCard.noWallet'));
        logAction(LOG_ACTIONS.PANKEEPER_WITHDRAW_NO_WALLET);
        return;
      }

      await onWithdraw({
        ...data,
        cardHash
      });
      logAction(LOG_ACTIONS.PANKEEPER_WITHDRAW_SUCCESS);
    } catch ({ errorCode }) {
      if (errorCode) {
        logAction(LOG_ACTIONS.PANKEEPER_WITHDRAW_FAILED);
        errorNotification(errorCode);
      }
    }
  };

  const deposit = async (data) => {
    try {
      const { cardHash } = await getCardHash();

      if (!cardHash) {
        errorNotification(t('balance.deposit.bankCard.noWallet'));
        logAction(LOG_ACTIONS.PANKEEPER_DEPOSIT_NO_WALLET);
        return;
      }

      await onDeposit({
        ...data,
        cardHash
      });
      logAction(LOG_ACTIONS.PANKEEPER_DEPOSIT_SUCCESS);
    } catch ({ errorCode }) {
      if (errorCode) {
        errorNotification(errorCode);
        logAction(LOG_ACTIONS.PANKEEPER_DEPOSIT_FAILED);
      }
    }
  };

  return {
    setPanKeeperInstance,
    isFetching,
    isCardInputReady,
    isDeposit: panKeeperType !== PAN_KEEPER_TYPES.WITHDRAW,
    handleSubmit:
      panKeeperType === PAN_KEEPER_TYPES.WITHDRAW ? withdraw : deposit,
    submitButtonValue: submitButtonValue[panKeeperType]
  };
};
