import { useMemo } from 'react';
import { useCategory, useSportsbookStore } from '../../store';
import HeadGroups from '../HeadGroups';
import { Sorter } from './Sorter';
import { getHeadGroupsList } from '../../utils';
import { useGetPathParams } from '../../hooks/useGetPathParams';

const EventsLineHeadGroups = ({
  serviceId,
  sport,
  activeHeadGroups = [],
  isHeadGroupsSortingEnabled = false,
  setActiveHeadGroups,
  isDisplayHeadMarkets = true,
  className = ''
}) => {
  const { sportSlug, categoryId } = useGetPathParams();
  const headGroups = useSportsbookStore('headGroups');
  const { categoryName } = useCategory(categoryId, serviceId);

  const { sportId } = sport || {};
  const selectedGroups = useMemo(() => getHeadGroupsList(headGroups, sportId), [
    headGroups,
    sportId
  ]);

  const classNames = useMemo(
    () => ({
      containerClassName: className
    }),
    [className]
  );

  const sorter = useMemo(
    () => (
      <Sorter
        categoryName={categoryName}
        sportName={sportSlug}
        activeHeadGroups={activeHeadGroups}
        selectedGroups={selectedGroups}
        onSelect={setActiveHeadGroups}
      />
    ),
    [activeHeadGroups, selectedGroups, setActiveHeadGroups, categoryName]
  );

  return (
    isHeadGroupsSortingEnabled &&
    isDisplayHeadMarkets &&
    selectedGroups.length > 0 && (
      <HeadGroups classNames={classNames} items={sorter} />
    )
  );
};

export default EventsLineHeadGroups;
