import { window } from '@namespace/helpers';

const protocol = window.location.protocol.includes('https') ? 'wss:' : 'ws:';

export const SOCKET_URLS = {
  SOCKET: `${protocol}//${window.location.host}/socket`,
  BULLET: `${protocol}//${window.location.host}/bullet`
};

export const MAX_SOCKET_RECONNECTION_ATTEMPTS_FIRST_TIME = 3;
