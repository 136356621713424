import {
  getLocalStorageValue,
  setLocalStorageValue,
  LOCAL_STORAGE_KEYS
} from '@namespace/helpers';
import { uniq } from 'ramda';

export const setCurrentBetlsipNumber = (betslipNumber) => {
  return setLocalStorageValue(
    LOCAL_STORAGE_KEYS.MULTIBETSLIP_NUMBER_KEY,
    betslipNumber
  );
};

export const getCurrentBetslipNumber = () => {
  return getLocalStorageValue(LOCAL_STORAGE_KEYS.MULTIBETSLIP_NUMBER_KEY, 0);
};

export const getBetslipKey = () => {
  const currentBetslip = getCurrentBetslipNumber();
  if (currentBetslip > 0) {
    return `betslip_${currentBetslip}`;
  }
  return 'betslip';
};

export const getNonEmptyBetslips = () => {
  return getLocalStorageValue(LOCAL_STORAGE_KEYS.MULTIBETSLIP_NONEMPTY_KEY, []);
};

export const updateCurrentBetslipNonEmpty = (nonEmpty) => {
  const currentNonEmpty = getNonEmptyBetslips();
  const currentBetslip = Number(getCurrentBetslipNumber());

  if (nonEmpty) {
    setLocalStorageValue(
      LOCAL_STORAGE_KEYS.MULTIBETSLIP_NONEMPTY_KEY,
      uniq([...currentNonEmpty, currentBetslip])
    );
  } else {
    setLocalStorageValue(
      LOCAL_STORAGE_KEYS.MULTIBETSLIP_NONEMPTY_KEY,
      currentNonEmpty.filter((value) => value !== currentBetslip)
    );
  }
};
