import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { COLOR_SCHEME } from '@namespace/themes';
import { MultiSelectDesktop } from './components/MultiSelectDesktop';
import { MultiSelectMobile } from './components/MultiSelectMobile';

// just constant reference
const emptyArr = [];

/**
 * MultiSelect
 * @param t
 * @param options
 * @param groupBy
 * @param groupsData
 * @param value
 * @param onChange
 * @param disabled
 * @param placeholder when nothing is selected & for inline search field
 * @param searchPlaceholder placeholder for search field below trigger
 * @param isDesktop
 * @param title when something is selected
 * @param kind
 * @param size
 * @param intent
 * @param hasSearch
 * @param isInlineSearch
 * @param style
 * @param className
 * @param classNames
 * @param customTrigger
 * @param showSelectAll
 * @param dataRole
 * @param haveToCloseOutsideClick
 * @param intentType
 * @param isExpandable
 * @param isOpenExternal
 * @param setIsOpenExternal
 * @returns {JSX.Element}
 * @constructor
 */
export const MultiSelect = ({
  t,
  options = emptyArr,
  groupBy,
  groupsData,
  value = emptyArr,
  onChange = noop,
  disabled,
  placeholder,
  searchPlaceholder,
  isDesktop, // use `isDesktopDevice` instead of `isDesktop` from DeviceContext
  title,
  kind = 'count', // count|chips
  size = 'xs',
  intent = 'default',
  hasSearch = false,
  isInlineSearch = true,
  style,
  className,
  classNames,
  customTrigger,
  showSelectAll,
  dataRole,
  haveToCloseOutsideClick = true,
  intentType = COLOR_SCHEME.PRIMARY,
  isExpandable = false,
  isOpenExternal,
  setIsOpenExternal
}) => {
  const commonProps = {
    t,
    options,
    groupBy,
    groupsData,
    value,
    title,
    onChange,
    disabled,
    placeholder,
    searchPlaceholder,
    kind,
    size,
    intent,
    hasSearch,
    isInlineSearch,
    className,
    classNames,
    customTrigger,
    style,
    showSelectAll,
    dataRole,
    intentType,
    isExpandable,
    isOpenExternal,
    setIsOpenExternal
  };

  return isDesktop || hasSearch || groupBy ? (
    <MultiSelectDesktop
      {...commonProps}
      haveToCloseOutsideClick={haveToCloseOutsideClick}
      dataRole={dataRole}
    />
  ) : (
    <MultiSelectMobile {...commonProps} dataRole={dataRole} />
  );
};

MultiSelect.propTypes = {
  t: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      title: PropTypes.string
    })
  ),
  value: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  classNames: PropTypes.shape({
    content: PropTypes.string,
    trigger: PropTypes.string
  }),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  isDesktop: PropTypes.bool,
  kind: PropTypes.oneOf(['count', 'chips']),
  intent: PropTypes.oneOf(['error', 'success', 'default']),
  size: PropTypes.oneOf(['xs', 's', 'm']),
  style: PropTypes.object,
  hasSearch: PropTypes.bool,
  isInlineSearch: PropTypes.bool,
  customTrigger: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType
  ]),
  intentType: PropTypes.oneOf([COLOR_SCHEME.PRIMARY, COLOR_SCHEME.SECONDARY])
};
