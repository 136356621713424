const getSlideImage = (slide, language = 'en', currency, getImg = () => {}) => {
  const { defaultImageLink = '', defaultImageLinkDark, images = [] } =
    slide || {};

  const { imagePath, link, linkDark, imagesPerCurrency, imagePathDark } =
    images.find(({ lang }) => lang === language) || {};

  const { imagePath: currencyImage, imagePathDark: currencyImageDark } =
    imagesPerCurrency?.find(({ currency: curr }) => curr === currency) || {};

  const imageForCurrency =
    getImg(currencyImage, currencyImageDark) || currencyImage;

  const imageForLanguage =
    getImg(imagePath, imagePathDark, imagePathDark) || imagePath;

  const imageForLink = getImg(link, linkDark) || link;

  const defaultImage =
    getImg(defaultImageLink, defaultImageLinkDark) || defaultImageLink;

  return imageForCurrency || imageForLanguage || imageForLink || defaultImage;
};

export default getSlideImage;
