import { useContext } from 'react';
import { Icon } from '@namespace/icons2';
import { USER_ROLES, UserContext } from '@namespace/user';
import { AText, Box } from '@alf/uikit';
import { LocalizedNavLink } from '@namespace/i18n';
import styles from './index.module.css';

const FavoritesLink = ({
  to,
  title,
  count,
  size = 's',
  className = '',
  onClick
}) => {
  const [user] = useContext(UserContext);
  const { role } = user;

  return (
    role === USER_ROLES.USER && (
      <LocalizedNavLink
        dataRole="sports-favorites-link"
        to={to}
        className={`${styles.item} ${className}`}
        activeClassName={styles.active}
        onClick={onClick}
      >
        <Box className={styles.container}>
          <Icon
            size={size}
            name="icons/general/state/favorite-on"
            color="state_favorite"
            className={styles.icon}
          />
          <AText
            breed="BU"
            color="sm_txt_color_1"
            className={styles.title}
            data-role="sports-favorites-link-text"
          >
            {title}
          </AText>
          {Number(count) > -1 && (
            <Box
              align="center"
              justify="center"
              className={styles.count}
              data-role="sports-favorites-link-counter"
            >
              {count}
            </Box>
          )}
        </Box>
      </LocalizedNavLink>
    )
  );
};

export default FavoritesLink;
