import {
  filter,
  map,
  mergeLeft,
  pathOr,
  pipe,
  prop,
  propEq,
  uniq,
  values
} from 'ramda';
import { createSelector } from 'reselect';
import { MODE } from '../../../constants';
import { useSportsbookSelector } from '../../store';

export const selectCyberSportTournamentsIdsByCategoryId = (categoryId) =>
  createSelector(
    pathOr({}, ['tournaments', MODE.PRE_MATCH, 'data']),
    pathOr({}, ['tournaments', MODE.IN_PLAY, 'data']),
    (preMatchData, liveData) =>
      pipe(
        mergeLeft(liveData),
        values,
        filter(propEq('categoryId', categoryId)),
        map(prop('tournamentId')),
        uniq
      )(preMatchData)
  );

export const useCyberSportTournamentsIdsByCategoryId = (categoryId) =>
  useSportsbookSelector(selectCyberSportTournamentsIdsByCategoryId, [
    categoryId
  ]);
