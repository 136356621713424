const minValidator = (
  value,
  message = 'validators.minValidator',
  params = []
) => ({
  min: {
    value,
    message: [message, value, ...params]
  }
});

export default minValidator;
