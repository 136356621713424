import DropdownFilter from './components/DropdownFilter';

export const BaseFilter = ({
  filtersConfig,
  changedFilters,
  appliedFilters,
  isInline,
  filterOnChangeHandler,
  filterGroupClassName,
  dropdownFilterClassName,
  triggerClassName,
  arrowConfig,
  dataRole
}) =>
  filtersConfig.map(
    ({
      label,
      key,
      options,
      defaultValue = null,
      disabled = false,
      Component,
      automationTestsClassName
    }) => (
      <DropdownFilter
        isInline={isInline}
        arrowConfig={arrowConfig}
        filterKey={key}
        label={label}
        value={changedFilters[key] ? changedFilters[key] : appliedFilters[key]}
        changedFilters={changedFilters}
        className={dropdownFilterClassName}
        key={`filter-${key}`}
        disabled={disabled}
        options={defaultValue ? [defaultValue, ...options] : options}
        filterGroupClassName={filterGroupClassName}
        triggerClassName={triggerClassName}
        onChange={(value) => filterOnChangeHandler(key, value)}
        automationTestsClassName={automationTestsClassName}
        dataRole={dataRole || automationTestsClassName}
        Component={Component}
      />
    )
  );
