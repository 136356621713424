import { window, document } from '@namespace/helpers';

const kwizBot = () => ({
  openChat() {
    const widgetElement = document.getElementById('kwizbot_widget');
    const handleClick = () => {
      const isClosed = !document
        .querySelector('.sc-launcher')
        .classList.contains('sc-launcher--opened');

      if (isClosed) {
        widgetElement.removeEventListener('click', handleClick);
        widgetElement.classList.remove('show');
      }
    };

    widgetElement.classList.add('show');
    widgetElement.addEventListener('click', handleClick);

    window.kw_event('openchat', 1);
  },

  changeTitle(title) {
    window.kw_event('command', { title });
  },

  changeSubtitle(subtitle) {
    window.kw_event('command', { subtitle });
  }
});

export default kwizBot();
