import { useCallback } from 'react';
import clsx from 'clsx';
import { useLocation } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { useUserSegmentsIncludes } from '@namespace/segments';
import { Box } from '@alf/uikit';
import {
  SESSION_STORAGE_KEYS,
  setSessionStorageValue
} from '@namespace/helpers';
import ItemContent from './ItemContent/ItemContent';
import { MOBILE_MENU_TILES_TYPE } from '../../constants';
import styles from './index.module.css';

const ProfileMenuItem = ({ item, onClick, classNames = {}, menuType }) => {
  const { containerClassName = '' } = classNames;
  const filteredSegments = useUserSegmentsIncludes();
  const { action, wide, userSegments, bottomPopupItems } = item;
  const isItemIncludedInSegments = filteredSegments(userSegments);
  const isBottomPopupAvailable = bottomPopupItems?.length > 0;
  const { pathname, search } = useLocation();
  const { push } = useHistory();

  const handleClick = useCallback(() => {
    setSessionStorageValue(
      SESSION_STORAGE_KEYS.PROFILE_MENU_PREV_PATH,
      pathname + search
    );

    return push(action);
  }, [action, pathname, push, search]);

  const isLobbyMenuItem = menuType === MOBILE_MENU_TILES_TYPE.LOBBY;

  return (
    isItemIncludedInSegments &&
    (isBottomPopupAvailable ? (
      <Box
        className={clsx(
          styles.itemContainer,
          wide && styles.wideItem,
          isLobbyMenuItem && styles.itemContainerHigher,
          containerClassName
        )}
        onClick={() => onClick(bottomPopupItems)}
        align="center"
        justify="center"
      >
        <ItemContent item={item} menuType={menuType} />
      </Box>
    ) : (
      <Box
        className={clsx(
          styles.itemContainer,
          wide && styles.wideItem,
          isLobbyMenuItem && styles.itemContainerHigher
        )}
        onClick={handleClick}
        align="center"
        justify="center"
      >
        <ItemContent item={item} menuType={menuType} />
      </Box>
    ))
  );
};

export default ProfileMenuItem;
