import { useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import { AText, Box, Button, Input } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { document } from '@namespace/helpers';
import styles from './index.module.css';

const QRCode = ({ formData = {}, showTransactionInfo = () => {} }) => {
  const { t } = useI18n();
  const [isDisplayTooltip, setIsDisplayTooltip] = useState(false);
  const { qrCode, qrCodeImage } = formData;
  const src = `data:image/png;base64, ${qrCodeImage}`;
  const inputRef = useRef(null);

  const displayTooltip = useCallback(() => {
    setIsDisplayTooltip(true);
    setTimeout(() => {
      setIsDisplayTooltip(false);
    }, 5 * 1000);
  }, [setIsDisplayTooltip]);

  const onCopy = useCallback(async () => {
    const inputValue = inputRef?.current?.value;
    if (inputValue) {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(inputValue);
        await displayTooltip();
      } else {
        inputRef.current.select();
        const successful = document.execCommand('copy');
        if (successful) {
          displayTooltip();
        }
      }
    }
  }, [displayTooltip]);

  const handleClick = () => showTransactionInfo();
  return (
    <>
      <AText breed="BU" size="l" color="po_txt_color_1" align="center">
        {t('balance.deposit.qrcode.title.info')}
      </AText>
      <Box margin="ms 0 0">
        <img src={src} alt="" className={styles.img} />
      </Box>
      <Box margin="ms 0">
        <AText breed="B" size="l" color="po_txt_color_3">
          {t('balance.deposit.qrcode.additional.info')}
        </AText>
      </Box>
      <Input type="hidden" inputRef={inputRef} readOnly={true} value={qrCode} />
      <Box className={styles.btn}>
        {isDisplayTooltip && (
          <Box padding="xs s" className={styles.tooltip}>
            <AText size="m" color="po_txt_color_2">
              {t('personalOffice_balance_pix_copied')}
            </AText>
          </Box>
        )}
        <Button
          fullWidth={true}
          size="m"
          intent="primary"
          actionType="color3"
          onClick={onCopy}
        >
          {t('balance.deposit.qrcode.btn.title')}
        </Button>
      </Box>
      <Box top="ms">
        <AText breed="B" size="l" color="po_txt_color_1" align="center">
          {t('balance.deposit.qrcode.info.text')}
          <AText
            className={clsx(styles.info, styles.infoLink)}
            breed="B"
            size="l"
            color="po_txt_color_1"
            onClick={handleClick}
          >
            {t('balance.deposit.qrcode.info.text1')}
          </AText>
          {t('balance.deposit.qrcode.info.text2')}
        </AText>
      </Box>
      <Box top="ms">
        <AText breed="B" size="l" color="po_txt_color_3" align="center">
          {t('balance.deposit.qrcode.deposit.info')}
        </AText>
      </Box>
    </>
  );
};

export default QRCode;
