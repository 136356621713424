import { useContext } from 'react';
import { UserContext } from '@namespace/user';

const useIsAddictedUser = () => {
  const [user] = useContext(UserContext);
  const { accountStatuses = {} } = user;
  const { isAddictedUser } = accountStatuses;

  return isAddictedUser;
};

export default useIsAddictedUser;
