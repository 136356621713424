import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { DeviceContext, FORM_FACTORS_MAP } from '@namespace/device';
import { DepositButton, QuickDepositButton } from '@namespace/payments-sw';
import { LOG_ACTIONS, logAction } from '@namespace/analytics';
import { TextCopy, useFixScroll } from '@namespace/common';
import { useI18n, useLocation } from '@namespace/i18n';
import { useLogout, UserContext } from '@namespace/user';
import { Icon } from '@namespace/icons2';
import { AText, Box, Button, Overlay } from '@alf/uikit';
import {
  LogOnTimer,
  PersonalOfficeMenuItem,
  useGetMenuItems,
  UserMenuBalance
} from '@namespace/account';
import {
  ACCOUNTING_BONUS_TYPES,
  OTHER_BONUS_TYPES,
  useGetBonusesCounters,
  WagerBonuses,
  BonusesContext,
  BONUS_SYSTEM_BONUS_TYPE
} from '@namespace/bonuses';
import { useGeneralRoutes, useOtherSettings } from '@namespace/cms';
import { HTMLSnippet } from '@namespace/snippets';
import IconLinkList from '@namespace/account/src/common/IconLinkList';
import { useGetInHouseJackpotSettings } from '@namespace/games';
import { isNull } from 'lodash';
import styles from './index.module.css';

const handleLogOutSuccess = (id) =>
  logAction(LOG_ACTIONS.LOGOUT, { userId: id });

const UserMenu = ({
  isOpen,
  showDepositButton = false,
  customDepositPath = '',
  showQuickDepositButton = false,
  showIcons = true,
  showUsername = false,
  snippetIdPromotionLink = '',
  showLogOnTimer = false,
  isShowFullNameInUserMenu = true,
  showBalance = true,
  showDropdownIcon = true
}) => {
  const { t } = useI18n();
  const [, { FETCH_WAGER_BONUSES }] = useContext(BonusesContext);
  const containerRef = useRef(null);
  const { isDesktop } = useContext(DeviceContext);
  const { pathname } = useLocation();
  const { isJackpotBonusTabEnabled } = useGetInHouseJackpotSettings();
  const {
    casinoBonusList,
    riskFreeBonusList,
    fundsBonusList
  } = useGeneralRoutes();
  const { userSettings, isPreWagerBonusesEnabled = false } = useOtherSettings();
  const { isDisplayWagerBonuses = false } = userSettings;

  const [user] = useContext(UserContext);

  const {
    id: userId,
    firstName,
    middleName,
    lastName,
    login,
    isVipUser = false,
    isDefaultRegistratedUser,
    activeWallet
  } = user;
  const { bonusBalance } = activeWallet;

  const fetchCounters = useGetBonusesCounters([
    ACCOUNTING_BONUS_TYPES.ALL,
    ...(isPreWagerBonusesEnabled ? [BONUS_SYSTEM_BONUS_TYPE] : []),
    ...(isJackpotBonusTabEnabled ? [OTHER_BONUS_TYPES.JACKPOT_WINS] : [])
  ]);

  const bonusRoutesMap = useMemo(
    () =>
      [casinoBonusList, riskFreeBonusList, fundsBonusList].map(
        (route) => `${route}/`
      ),
    [casinoBonusList, riskFreeBonusList, fundsBonusList]
  );

  const [isExpanded, setExpanded] = useState(false);
  const expandMenuItem = () => setExpanded(!isExpanded);

  useEffect(() => {
    if (isDisplayWagerBonuses) {
      FETCH_WAGER_BONUSES({
        isPreWagerBonusesEnabled
      });
    }
    if (!bonusRoutesMap.includes(pathname)) {
      fetchCounters();
    }
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setExpanded(isOpen);
    }
  }, [isOpen, setExpanded]);

  useFixScroll('burger', containerRef, isOpen && !isDesktop);

  const withSupportMenu = true;
  const filteredUserMenu = useGetMenuItems(showIcons, withSupportMenu);
  const { items: supportMenu = [] } =
    filteredUserMenu.find(({ items = [] }) => items.length) || {};

  const { logout: handleLogOut } = useLogout(handleLogOutSuccess);

  return (
    <Box direction="column">
      {isDesktop && showDropdownIcon && (
        <Box align="center" className={styles.icon}>
          <Icon
            size="xs"
            name="icons/general/nav/dropdown_down"
            color="header_txt_2"
            data-role="header-dropdown-icon"
          />
        </Box>
      )}
      {isOpen && (
        <div>
          <Overlay classNames={{ wrapperClass: `${styles.overlay}` }} />
          <Box
            data-role="user-menu-wrapper"
            ref={containerRef}
            className={styles.general}
            direction="column"
            gap="m"
          >
            <Box
              align="center"
              justify="center"
              direction={isShowFullNameInUserMenu ? 'column' : 'row'}
              gap="xs"
              className={
                !isDesktop && (showDepositButton || showQuickDepositButton)
                  ? ''
                  : styles.userBlock
              }
            >
              {isVipUser && (
                <Icon
                  size="s"
                  name="icons/user_menu/profile_VIP_desktop"
                  color="accent_color_3"
                />
              )}
              {!isDefaultRegistratedUser && isShowFullNameInUserMenu && (
                <AText
                  breed="B"
                  size="m"
                  color="po_txt_color_1"
                  align="center"
                  data-role="user-menu-username"
                >
                  {`${firstName} ${middleName || ''} ${lastName}`}
                </AText>
              )}
              {showUsername ? (
                login
              ) : (
                <TextCopy
                  breed="R"
                  size="l"
                  color="po_txt_color_1"
                  textDisplay={`ID: ${userId}`}
                  textCopy={userId}
                  textNotify="ID"
                  dataRoles={{
                    dataRoleText: 'user-menu-text-copy-text',
                    dataRoleIcon: 'user-menu-text-copy-icon'
                  }}
                />
              )}
              {snippetIdPromotionLink && (
                <HTMLSnippet
                  snippetId={snippetIdPromotionLink}
                  className={styles.promotionLink}
                />
              )}
            </Box>
            {showBalance && !isNull(bonusBalance) && (
              <Box>
                <UserMenuBalance isUserMenu={true} />
              </Box>
            )}
            {(showDepositButton || showQuickDepositButton) && (
              <Box direction="column" align="center">
                {showDepositButton && (
                  <DepositButton
                    actionType="color1"
                    size="l"
                    customDepositPath={customDepositPath}
                    data-role="user-menu-deposit"
                  />
                )}
                {showQuickDepositButton && (
                  <QuickDepositButton
                    actionType="color1"
                    size="l"
                    data-role="user-menu-quick-deposit"
                  />
                )}
              </Box>
            )}
            {isDisplayWagerBonuses && <WagerBonuses />}
            <Box className={styles.items}>
              {filteredUserMenu.map((menuItem) => (
                <Box key={menuItem.title} bottom="xs" className={styles.item}>
                  <PersonalOfficeMenuItem
                    handleExpand={expandMenuItem}
                    isExpanded={!menuItem.link && isExpanded && isDesktop}
                    place="user-menu"
                    classNames={{
                      menuItemClassName:
                        isExpanded && !menuItem.link ? styles.expanded : ''
                    }}
                    intent={
                      isDesktop
                        ? FORM_FACTORS_MAP.DESKTOP
                        : FORM_FACTORS_MAP.MOBILE
                    }
                    {...menuItem}
                  />
                </Box>
              ))}
            </Box>
            {isExpanded && !isDesktop && (
              <IconLinkList
                padding="s 0"
                size={isDesktop ? 'xs' : 'm'}
                items={supportMenu}
              />
            )}
            <Box margin="s 0" justify="center">
              <Button
                intent="quaternary"
                size="s"
                actionType="color3"
                onClick={handleLogOut}
                iconLeft={<Icon name="icons/user_menu/exit_ic" size="s" />}
                data-role="user-menu-logout"
              >
                {t('personalOffice.menu.footer.logOut')}
              </Button>
            </Box>
            {showLogOnTimer && !isDesktop && <LogOnTimer />}
          </Box>
        </div>
      )}
    </Box>
  );
};

export default UserMenu;
