import { IS_PRERENDER } from '@namespace/helpers';
import { useConfig } from '@namespace/cms';
import { LINE_KEY, MODE } from '../../constants';
import EventsMicrodata from './EventsMicrodata';
import { useGetPathParams } from '../../hooks/useGetPathParams';

const SportsMicrodata = ({ id }) => {
  const { config } = useConfig(id);
  const { isPrerenderOnly = true } = config;
  const { activeSport } = useGetPathParams(MODE.PRE_MATCH);
  const { sportId } = activeSport || {};
  const isRender = !isPrerenderOnly || (isPrerenderOnly && IS_PRERENDER);
  return (
    isRender && <EventsMicrodata lineKey={`${LINE_KEY.PRE_MATCH}-${sportId}`} />
  );
};

export default SportsMicrodata;
