import { Box } from '@alf/uikit';
import { isEmpty } from 'lodash';
import SportsGridItem from '../SportsGridItem';
import styles from './index.module.css';

const SportsGrid = ({ sports = [], isLoaded }) => {
  if (isLoaded && isEmpty(sports)) {
    return null;
  }

  return (
    <Box className={styles.sportsGrid} justify="center">
      {!isEmpty(sports)
        ? sports.map((item) => (
            <SportsGridItem key={item.categoryId} category={item} />
          ))
        : Array(12)
            .fill(1)
            // eslint-disable-next-line react/no-array-index-key
            .map((_, index) => <SportsGridItem key={index} category={index} />)}
    </Box>
  );
};

export default SportsGrid;
