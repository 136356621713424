import { useGeneralRoutes } from '@namespace/cms';
import { LocalizedRedirect } from '@namespace/i18n';
import { useSportsbookStore } from '../../store';
import { useGetPathParams } from '../../hooks/useGetPathParams';
import { MODE } from '../../constants';

const TopEventsRoutes = () => {
  const topEvents = useSportsbookStore('topEvents');
  const { preMatchSport } = useGeneralRoutes();
  const { topId } = useGetPathParams(MODE.PRE_MATCH);
  const topEventsIds = topEvents?.map(({ presetId }) => presetId.toString());

  if ((!topId || !topEventsIds.includes(topId)) && topEventsIds?.length) {
    return <LocalizedRedirect to={`${preMatchSport}`} />;
  }

  return null;
};

export default TopEventsRoutes;
