import { useI18n } from '@namespace/i18n';
import { ChipGroupTitle } from '../ChipGroupTitle';
import { Chip } from '../Chip';

export const ChipGroup = ({ chips, title, status }) => {
  const { t } = useI18n();

  if (chips.length === 0) {
    return null;
  }

  return (
    <>
      <ChipGroupTitle text={t(title)} status={status} />
      {chips.map((chip) => (
        <Chip chip={chip} key={chip.txt} />
      ))}
    </>
  );
};
