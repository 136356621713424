import { useEffect, useCallback, useContext } from 'react';
import { useI18n } from '@namespace/i18n';
import useNotifications from '@namespace/notifications/src/hooks/useNotifications';
import { NOTIFICATION_TYPES } from '@namespace/notifications/src/constants';
import { socketSubscribe, socketUnsubscribe } from '@namespace/socket';
import { window } from '@namespace/helpers';
import { UserContext } from '../../context';

const useSessionExpireCheck = () => {
  const { t } = useI18n();
  const { customNotification } = useNotifications();
  const [user] = useContext(UserContext);

  const callback = useCallback(() => {
    if (user.isLoggingOut) return;

    customNotification({
      message: t('sessionExpireCheck.sessionClosed'),
      notificationType: NOTIFICATION_TYPES.SESSION_EXPIRED_POPUP,
      timeout: null
    });
  }, [customNotification, t, user.isLoggingOut]);

  useEffect(() => {
    window.sessionExpire = callback;
    socketSubscribe({
      name: 'session_closed',
      types: ['session_closed'],
      callback
    });
    return () => socketUnsubscribe('session_closed');
  }, [callback]);
};

export default useSessionExpireCheck;
