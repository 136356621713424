import { useGeneralRoutes } from '@namespace/cms';
import { LocalizedLink, useI18n } from '@namespace/i18n';
import { isEmpty } from 'lodash';
import { EmptyState } from '@alf/uikit';
import { window } from '@namespace/helpers';
import { LINE_KEY, MODE } from '../../constants';
import { useGetPathParams } from '../../hooks/useGetPathParams';
import BasePreMatchEvents from '../../common/BasePreMatchEvents';
import { useCategory, useSportsbookStore, useTournament } from '../../store';

export const PreMatchTournamentEvents = ({ id }) => {
  const { home } = useGeneralRoutes();
  const { t } = useI18n();
  const { sportSlug, activeSport, tournamentId, tournament } = useGetPathParams(
    MODE.PRE_MATCH
  );
  const isReady = useSportsbookStore('isTournamentCategoryReady');
  const { categoryId, tournamentName } = useTournament(
    tournamentId,
    MODE.PRE_MATCH
  );

  const category = useCategory(categoryId, MODE.PRE_MATCH);

  if (
    (isReady && isEmpty(tournament)) ||
    (isReady && isEmpty(category)) ||
    (activeSport === null && sportSlug) ||
    (!tournamentId && !window.IS_SSR)
  ) {
    return (
      <EmptyState
        text={t('web.sports.noTournament')}
        Link={LocalizedLink}
        linkPath={home}
        linkText={t('home')}
      />
    );
  }

  return (
    <BasePreMatchEvents
      id={id}
      activeSport={activeSport}
      tournamentsIds={tournamentId ? [tournamentId] : []}
      tournamentName={tournamentName}
      categories={category ? [category] : []}
      key={sportSlug}
      lineKey={`${LINE_KEY.PRE_MATCH}-${tournamentId}`}
      isReady={isReady}
    />
  );
};
