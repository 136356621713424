export const TRANSACTIONS_TYPES = {
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdraw',
  CREATE_WALLET: 'createWallet'
};

export const WITHDRAWAL_WALLET_FIELD = 'withdrawalWallet';
export const WALLET_FIELD = 'wallet';
export const METHOD_FIELD = 'method';
export const TABLE_COLUMNS = [
  'state',
  'date',
  'wallet',
  'withdrawalWallet',
  'amount',
  'method',
  'id'
];
export const FILTER_FIELDS = ['state', 'wallet', 'amount', 'dateOptionId'];

export const WITHDRAWAL_FORM_VIEW_TYPE = {
  ADD_ADDRESS: 'addAddress',
  ADDRESS_LIST: 'addressList'
};

export const AMOUNT_COLUMN = 'amount';
export const ID_COLUMN = 'id';

export const COUNTRY_ID_LIST = {
  INDIA: 'IN'
};
