import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useI18n } from '@namespace/i18n';
import getFormError from '../../utils/getFormError';
import DatePickerFormWrapper from '../../common/DatePickerFormWrapper';
import { DOC_DATE_PICKER_TYPES } from '../../constants';

const DocumentDatePickerField = ({
  name,
  label = '',
  placeholder,
  formProps = {},
  rules = {},
  classNames = {},
  disabled = false,
  dependentName,
  dataPickerType = DOC_DATE_PICKER_TYPES.PLACED,
  minOffsetProps,
  maxOffsetProps
}) => {
  const {
    minDateOffset = { years: 90 },
    maxDateOffset = 0,
    documentDateValidationMap = {}
  } = formProps;
  const { t, f } = useI18n();
  const DATE_FORMATS_TYPES = f.getFormats();
  const methods = useFormContext();
  const {
    control,
    watch,
    formState: { errors },
    clearErrors,
    setError
  } = methods;
  // TODO: transfer logic to form after improving forms
  const { value: dependentValue } =
    (dependentName && watch(dependentName)) || {};
  const { minOffset: minDocumentOffset = 0, maxOffset: maxDocumentOffset = 0 } =
    documentDateValidationMap[dependentValue] || {};
  const isDependentMode = documentDateValidationMap[dependentValue];
  const minOffset = isDependentMode ? minDocumentOffset : minDateOffset;
  const maxOffset = isDependentMode ? maxDocumentOffset : maxDateOffset;

  const minDate = useMemo(
    () =>
      dataPickerType === DOC_DATE_PICKER_TYPES.PLACED
        ? f
            .getDateTime()
            .minus(minOffset)
            .toJSDate()
        : f
            .getDateTime()
            .plus(minOffsetProps)
            .toJSDate(),
    [f, minOffset]
  );
  const maxDate = useMemo(
    () =>
      dataPickerType === DOC_DATE_PICKER_TYPES.PLACED
        ? f
            .getDateTime()
            .minus(maxOffset)
            .toJSDate()
        : f
            .getDateTime()
            .plus(maxOffsetProps)
            .toJSDate(),
    [f, maxOffset]
  );

  const dt = watch(name);
  const date = useMemo(
    () =>
      // eslint-disable-next-line no-nested-ternary
      dt
        ? f
            .getDateTime({
              date: dt,
              format: DATE_FORMATS_TYPES.CLIENT_API,
              fromUTC: false
            })
            .toJSDate()
        : isDependentMode
        ? f.getDateTime().toJSDate()
        : f
            .getDateTime()
            .minus(maxOffset)
            .toJSDate(),
    [dt, f, DATE_FORMATS_TYPES.CLIENT_API, maxOffset, isDependentMode]
  );

  const handleEvent = useCallback(
    (newDate) =>
      newDate
        ? f
            .getDateTime({ date: newDate, withTZ: false })
            .toFormat(DATE_FORMATS_TYPES.CLIENT_API)
        : '',
    [DATE_FORMATS_TYPES.CLIENT_API, f]
  );

  return (
    <DatePickerFormWrapper
      cancelLabel={t('datePicker.cancel') || 'cancel'}
      okLabel={t('datePicker.ok') || 'ok'}
      name={name}
      label={t(`${label}_${dependentValue}`, t(label))}
      placeholder={t(`${placeholder}_${dependentValue}`, t(placeholder))}
      dropdownMode="select"
      date={watch(name) ? date : null}
      format={DATE_FORMATS_TYPES.CLIENT_API}
      onChange={handleEvent}
      clearErrors={clearErrors}
      minDate={minDate}
      maxDate={maxDate}
      control={control}
      rules={{ ...rules }}
      error={getFormError(name, errors, t)}
      setError={setError}
      minDateMessage={t('datePicker.error.dateBeforeMinimalDate')}
      maxDateMessage={t('datePicker.error.dateAfterMaximalDate')}
      {...classNames}
      disabled={Boolean(disabled)}
    />
  );
};

export default DocumentDatePickerField;
