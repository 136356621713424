import { useOtherSettings } from '@namespace/cms';

import styles from './index.module.css';

const Loader = ({ imgSrc = '/images/loader.svg', className = '' }) => {
  const { loader: { isRotate = false } = {} } = useOtherSettings();

  return (
    <div
      className={`${styles.container} ${className} ${
        isRotate ? styles.spin : ''
      }`}
    >
      <img src={imgSrc} alt="load" className={styles.animation} />
    </div>
  );
};

export default Loader;
