import { AText, Box } from '@alf/uikit';
import clsx from 'clsx';
import { isEmpty, keyBy, pickBy } from 'lodash';
import { useConfig, useGeneralRoutes, useOtherSettings } from '@namespace/cms';
import { useI18n, useLocation } from '@namespace/i18n';
import { useRouteParams } from '@namespace/router';
import { useUserSegmentsIncludes } from '@namespace/segments';
import { useThemeComponents } from '@namespace/themes';
import { UserContext } from '@namespace/user';
import { useContext, useMemo } from 'react';
import DefaultBonusHunterPromotions from '../../common/BonusHunterPromotions';
import { PromotionsItemWrapper } from '../../common/PromotionsItemWrapper';
import { PromotionsTournamentItem } from '../../common/PromotionsTournamentItem';
import {
  DEFAULT_ACTIVE_TAG,
  DEFAULT_PROMO_TYPE,
  PROMO_TYPES
} from '../../constants';
import { usePromotionsRoutes } from '../../hooks';
import { usePromotionsStore } from '../../store';
import { parsePromoConfig, parsePromotionsApi } from '../../store/normalize';
import getValidTags from '../../utils/getValidTags';
import Categories from '../Categories';
import Tags from '../Tags';
import { PromotionsEmptyState as DefaultPromotionsEmptyState } from './PromotionsEmptyState';
import PromotionsSplitSkelet from './PromotionsSplitSkelet';
import PromotionsSkelet from './PromotionsSkelet';
import TournamentsSkelet from './TournamentsSkelet';
import SplitPromos from './SplitPromos';
import styles from './index.module.css';

const promoTypeToItemComponent = {
  [PROMO_TYPES.promotion]: PromotionsItemWrapper,
  [PROMO_TYPES.tournament]: PromotionsTournamentItem
};
const promoTypeToSkeletonComponent = {
  [PROMO_TYPES.promotion]: PromotionsSkelet,
  [PROMO_TYPES.tournament]: TournamentsSkelet
};

const promoTypeToContainerClass = {
  [PROMO_TYPES.promotion]: styles.promotionsContainer,
  [PROMO_TYPES.tournament]: styles.tournamentsContainer
};

const Promotions = ({
  id,
  classNames = {},
  isNeedFilterTag = true,
  isNeedCustomTitle = false,
  isNeedFullCoverEmpty = false,
  isNeedSplittedPromos = false,
  customClassNames = {}
}) => {
  const { t } = useI18n();
  const [user] = useContext(UserContext);
  const { accountStatuses = {} } = user;
  const { isBonusHunter, isAddictedUser } = accountStatuses;
  const {
    PromotionsEmptyState = DefaultPromotionsEmptyState,
    BonusHunterPromotions = DefaultBonusHunterPromotions,
    PromotionsTournamentsLobby: PartnerPromotionsTournamentsLobby
  } = useThemeComponents();
  const { splitCategories = [] } = useOtherSettings();
  const promotionsApi = usePromotionsStore('promotions');
  const isPromosLoaded = usePromotionsStore('isPromosLoaded');
  const { promotions: promotionsPath } = useGeneralRoutes();
  const { tag: tagPath } = useRouteParams(`${promotionsPath}/:category/:tag`);
  const { pathname } = useLocation();
  const { config = {} } = useConfig(id);
  const filteredSegments = useUserSegmentsIncludes();
  const parsedConfig = useMemo(() => parsePromoConfig(config), [config]);

  const enabledTypes = useMemo(
    () =>
      Object.keys(
        pickBy(parsedConfig, ({ enabled }) =>
          Object.keys(parsedConfig).reduce(() => enabled)
        )
      ),
    [parsedConfig]
  );

  // TODO: can use useGetActivePromoType ?
  // TODO: purpose of default type. can just redirect in usePromotionsRoutes in case of wrong type ?
  const activePromoType = useMemo(
    () =>
      enabledTypes.find((item) => pathname.includes(item) && item) || [
        DEFAULT_PROMO_TYPE
      ],
    [enabledTypes, pathname]
  );
  const isTournamentPage = activePromoType === PROMO_TYPES.tournament;

  const enabledTags = parsedConfig[activePromoType]?.categories;
  const activeTag = enabledTags
    ? enabledTags[tagPath]?.categoryName
    : DEFAULT_ACTIVE_TAG;

  const listOfSelectedPromo =
    parsedConfig[activePromoType]?.categories[activeTag]?.list || [];

  const parsedPromoApi = useMemo(
    () =>
      parsePromotionsApi(
        promotionsApi,
        activePromoType,
        filteredSegments,
        listOfSelectedPromo
      ),
    [activePromoType, filteredSegments, listOfSelectedPromo, promotionsApi]
  );

  const activePromotions = Object.keys(listOfSelectedPromo)
    .map((promoId) => keyBy(parsedPromoApi, 'promoId')[promoId] || false)
    .filter(Boolean);

  const validTags = enabledTags
    ? getValidTags(
        enabledTags,
        parsedConfig,
        promotionsApi,
        activePromoType,
        filteredSegments
      )
    : [];
  const isShowTags = Object.keys(validTags).length > 1;

  const PromotionItemComponent = promoTypeToItemComponent[activePromoType];
  const PromotionSkeletonComponent =
    promoTypeToSkeletonComponent[activePromoType];

  usePromotionsRoutes(enabledTypes, enabledTags, validTags);

  if (isBonusHunter || isAddictedUser) {
    return <BonusHunterPromotions activePromoType={activePromoType} />;
  }

  if (!isPromosLoaded) {
    return isNeedSplittedPromos &&
      activePromoType === PROMO_TYPES.promotion &&
      splitCategories.length > 0 ? (
      <PromotionsSplitSkelet />
    ) : (
      <PromotionSkeletonComponent />
    );
  }

  if (
    enabledTypes.length === 0 ||
    !pathname.includes(promotionsPath) ||
    !PromotionItemComponent
  ) {
    return null;
  }

  // empty state
  if (
    !activePromoType ||
    (isNeedFullCoverEmpty && (isEmpty(activePromotions) || isEmpty(validTags)))
  ) {
    return (
      <PromotionsEmptyState
        activePromoType={activePromoType}
        parsedConfig={parsedConfig}
      />
    );
  }

  if (isTournamentPage && PartnerPromotionsTournamentsLobby) {
    return (
      <PartnerPromotionsTournamentsLobby
        activePromotions={activePromotions}
        classNames={classNames}
      />
    );
  }

  const {
    promoWrapper = '',
    titleClass = '',
    promosContentWrapper = ''
  } = classNames;

  return (
    <Box
      direction="column"
      className={clsx([styles.wrapper, promoWrapper])}
      data-role="promotions-wrapper"
    >
      {isNeedCustomTitle && (
        <AText breed="B" color="txt_color_1" size="2xl" className={titleClass}>
          {t('web.promotions.customTitle')}
        </AText>
      )}
      {isNeedFilterTag && (
        <Box className={styles.filtersContainer}>
          {enabledTypes.length >= 2 && (
            <Categories enabledTypes={enabledTypes} validTags={validTags} />
          )}
          {isShowTags && (
            <Tags
              validTags={validTags}
              activeTag={activeTag}
              activePromoType={activePromoType}
            />
          )}
        </Box>
      )}
      {isEmpty(activePromotions) || isEmpty(validTags) ? (
        <PromotionsEmptyState
          activePromoType={activePromoType}
          parsedConfig={parsedConfig}
        />
      ) : (
        <Box
          data-role="promotions-list-of-promos"
          align="center"
          className={clsx(
            promoTypeToContainerClass[activePromoType],
            promosContentWrapper
          )}
        >
          {isNeedSplittedPromos && splitCategories.length > 0 ? (
            <SplitPromos
              splitCategories={splitCategories}
              activePromotions={activePromotions}
              customClassNames={customClassNames}
            />
          ) : (
            activePromotions.map((promo, index) => (
              <PromotionItemComponent
                promo={promo}
                key={promo.promoId}
                tagPath={tagPath}
                index={index + 1}
                customClassNames={customClassNames}
              />
            ))
          )}
        </Box>
      )}
    </Box>
  );
};

export default Promotions;
