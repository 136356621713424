import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { isObject, isUndefined } from 'lodash';

const useOnChangeValidate = ({
  name,
  isOnChangeValidation,
  validateAsObject = false
}) => {
  const { watch, trigger } = useFormContext();
  const fieldValue = watch(name);

  const validate = useCallback(async () => {
    await trigger([name]);
  }, [trigger, name]);

  useEffect(() => {
    if (isOnChangeValidation) {
      const emptyItem =
        isObject(fieldValue) && Object.values(fieldValue).find((item) => !item);
      if (validateAsObject && isUndefined(emptyItem)) {
        validate();
      } else if (fieldValue && !validateAsObject) {
        validate();
      }
    }
  }, [fieldValue, isOnChangeValidation]);
};

export default useOnChangeValidate;
