import { useContext } from 'react';
import { AText, Box } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import { SiteSettingsContext } from '@namespace/cms';
import { addSpacesToJackpotAmount } from '../../../../utils';
import styles from './index.module.css';

const GameJackpot = ({ gameJackpot }) => {
  const [settings] = useContext(SiteSettingsContext);
  const { siteConfig = {} } = settings;
  const { defaultJackpotCurrency = '' } = siteConfig;
  const formattedJackpot = addSpacesToJackpotAmount(gameJackpot.toFixed(0));

  return (
    <Box className={styles.jackpotWrapper}>
      <Box className={styles.jackpotIcon} align="center" justify="center">
        <Icon name="icons/casino/item/jackpot" color="txt_color_4" size="m" />
      </Box>
      <Box
        className={styles.jackpotTextWrapper}
        align="center"
        justify="center"
      >
        <Box className={styles.jackpotTextBg} />
        <AText
          color="txt_color_4"
          breed="RU"
          size="m"
          className={styles.jackpotText}
        >
          {`${formattedJackpot} ${defaultJackpotCurrency}`}
        </AText>
      </Box>
    </Box>
  );
};

export default GameJackpot;
