import { LocalizedLink } from '@namespace/i18n';
import { useGeneralRoutes } from '@namespace/cms';
import { InfoScreen } from '../InfoScreen';

export const FirstDepositInfoMessage = ({ activePaymentName = 'default' }) => {
  const { deposit } = useGeneralRoutes();
  return (
    <InfoScreen
      title={`personalOffice.balance.sw.forms.withdraw.${activePaymentName}.emptyCardsText`}
      image="wallet"
      actionTitle={`personalOffice.balance.sw.forms.withdraw.${activePaymentName}.emptyCards.action.title`}
      actionProps={{
        component: LocalizedLink,
        to: deposit
      }}
    />
  );
};
