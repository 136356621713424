import { useEffect, useState } from 'react';
import { useCyberSportGetPathParams } from '../useCyberSportGetPathParams';
import { getCategory } from './utils';

const useGetActualCyberSportCategory = ({ onlyCategoryId = false } = {}) => {
  const {
    category,
    isAllSports: allSports,
    isFavorites
  } = useCyberSportGetPathParams();
  const [isAllSports] = useState(allSports);
  const [prevCategory, setPrevCategoryId] = useState(
    !isAllSports && !isFavorites ? category : null
  );
  const categoryId = category?.categoryId;
  useEffect(() => {
    if (!isAllSports && !isFavorites && category) {
      setPrevCategoryId(category);
    }
  }, [isAllSports, isFavorites, categoryId, setPrevCategoryId]);

  return getCategory({
    onlyCategoryId,
    isAllSports,
    isFavorites,
    category,
    prevCategory
  });
};

export default useGetActualCyberSportCategory;
