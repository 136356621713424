import { useContext, useEffect } from 'react';
import { DeviceContext } from '@namespace/device';
import {
  scrollToElement,
  waitForElement,
  waitForElements
} from '@namespace/helpers';
import { OnboardingContext } from '../../../../store/context';
import { useCurrentStep } from '../../../../hooks';
import { useStepUrlNavigate } from '../useStepUrlNavigate';
import { checkEl } from '../../../../utils';

// NB This hook is extremely fragile and is required to run exactly once on step change or it breaks triggers (at least)
export const usePrepareStepRender = () => {
  const { isDesktop } = useContext(DeviceContext);
  const [
    { steps, stepIndex, isInterrupted },
    { SET_IS_LOADING, NEXT, RUN }
  ] = useContext(OnboardingContext);
  const {
    target,
    trigger,
    trigger2,
    triggerDelay,
    navigate,
    waitFor,
    scrollTo,
    scrollOffset = 0,
    interceptActions
  } = useCurrentStep();

  const doNavigate = useStepUrlNavigate();

  // prepare step for rendering
  useEffect(() => {
    if (
      (!navigate && !trigger && !target && !waitFor && !scrollTo) ||
      isInterrupted
    ) {
      SET_IS_LOADING(false);

      return;
    }

    const wait = async () => {
      SET_IS_LOADING(true);

      let triggerEl;
      let triggerEl2;

      try {
        // process navigate
        doNavigate();

        // process triggerDelay
        if (triggerDelay) {
          await new Promise((resolve) => setTimeout(resolve, triggerDelay));
        }

        // process trigger
        if (trigger) {
          [triggerEl] = await waitForElements(trigger);
          triggerEl?.click();
        }

        // process trigger2
        if (trigger2) {
          [triggerEl2] = await waitForElements(trigger2);
          triggerEl2?.click();
        }

        // process target
        if (target) {
          await waitForElements(target, { checkEl });
        }

        // process waitFor
        if (waitFor) {
          await waitForElements(waitFor, { checkEl });
        }

        // process scrollTo
        const scrollToTargets = scrollTo === true ? target : scrollTo;
        if (scrollToTargets) {
          const scrollTargetSelector = Array.isArray(scrollToTargets)
            ? scrollToTargets[0]
            : scrollToTargets;
          const scrollTargetEl = await waitForElement(scrollTargetSelector, {
            checkEl
          });
          scrollToElement(scrollTargetEl, undefined, 10 + scrollOffset);
        }

        SET_IS_LOADING(false);
      } catch (ex) {
        console.error(ex);

        if (steps.length - 1 === stepIndex) {
          RUN(false);
        } else {
          NEXT();
        }
      }

      return [triggerEl, triggerEl2];
    };

    const waitPromise = wait();

    // eslint-disable-next-line consistent-return
    return () => {
      SET_IS_LOADING(true);

      waitPromise.then(([triggerEl, trigger2El]) => {
        trigger2El?.click();
        triggerEl?.click();
      });
    };
  }, [
    NEXT,
    RUN,
    SET_IS_LOADING,
    doNavigate,
    interceptActions,
    isDesktop,
    isInterrupted,
    navigate,
    scrollOffset,
    scrollTo,
    stepIndex,
    steps.length,
    target,
    trigger,
    trigger2,
    triggerDelay,
    waitFor
  ]);
};
