import { AText } from '@alf/uikit';
import styles from './index.module.css';

const FieldLabel = ({
  firstText,
  secondText,
  linkText,
  link,
  dataRole = null
}) => (
  <>
    {firstText}
    <AText
      data-role={dataRole}
      breed="B"
      color="txt_color_1"
      component="a"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.link}
    >
      {linkText}
    </AText>
    {secondText}
  </>
);

export default FieldLabel;
