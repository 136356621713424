import { useCallback, useContext, useMemo, useState } from 'react';
import { useI18n } from '@namespace/i18n';
import { AText, Box, Select } from '@alf/uikit';
import { DeviceContext } from '@namespace/device';
import { useSearchParams } from '@namespace/router';
import { DATE_RANGE_FILTER_VALUE, FILTER_ALL_VALUE } from '../../constants';
import { DateRange } from './components/DateRange';

const DateSelect = ({
  label,
  options = [],
  onChange,
  value,
  className,
  changedFilters,
  dataRole,
  size = 's',
  isWithDateRange = false,
  arrowConfig,
  maxMinDate = { years: 2 }
}) => {
  const { t, f } = useI18n();
  const { isDesktop } = useContext(DeviceContext);
  const { dateOptionId = '' } = useSearchParams();
  const [isDateRangeActive, setIsDateRangeActive] = useState(
    dateOptionId === DATE_RANGE_FILTER_VALUE ||
      value === DATE_RANGE_FILTER_VALUE
  );

  const {
    filterArrowName = 'icons/general/nav/dropdown_down',
    arrowSize
  } = arrowConfig;

  const handleChange = useCallback(({ value: newValue = 1 }) => {
    if (newValue === DATE_RANGE_FILTER_VALUE) {
      setIsDateRangeActive(true);
    } else {
      const plusDay = 1;
      const today = f.getDateTime();
      const range =
        newValue === FILTER_ALL_VALUE ? 10000 : parseInt(newValue, 10);
      const dateEnd = today.plus({ days: plusDay }).toISODate();
      const dateStart = today.minus({ days: range - plusDay }).toISODate();

      onChange({
        dateOptionId: newValue,
        startDate: dateStart,
        endDate: dateEnd
      });
    }
  }, []);

  const newOptions = useMemo(() => {
    const currentOptions = options.map(({ name: title, value: v }) => ({
      title,
      value: v
    }));

    if (isWithDateRange) {
      currentOptions.push({
        title: t('personalOffice.filter.options.date.dateRange'),
        value: DATE_RANGE_FILTER_VALUE
      });
    }

    return currentOptions;
  }, [options, isWithDateRange]);

  return (
    <Box bottom="s" direction="column">
      <AText
        data-role={dataRole && `${dataRole}-label`}
        className={className}
        color="po_txt_color_1"
      >
        {label}
      </AText>
      {isDateRangeActive ? (
        <DateRange
          onChange={onChange}
          changedFilters={changedFilters}
          options={newOptions}
          setIsDateRangeActive={setIsDateRangeActive}
          handleSelectChange={handleChange}
          maxMinDate={maxMinDate}
        />
      ) : (
        <Select
          options={newOptions}
          onChange={handleChange}
          iconDropDownName={filterArrowName}
          arrowSize={arrowSize}
          value={value}
          size={size}
          isDesktop={isDesktop}
          dataRole={dataRole}
        />
      )}
    </Box>
  );
};

export default DateSelect;
