import { path, prop } from 'ramda';
import { shallow } from 'zustand/shallow';
import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import { LOG_ACTIONS, logAction } from '@namespace/analytics';
import { useSportsbookSelector } from '../../../../store';
import { getMinMarketIds } from '../../../../../utils';

export const selectEventMarketId = (
  lineKey,
  eventId,
  headGroupResultTypeId,
  headGroupMarketTemplateId
) =>
  createSelector(
    prop('lines'),
    path(['lines', lineKey]),
    prop('outcomes'),
    prop('markets'),
    (lines, line, outcomes, markets) => {
      const eventMarketsIds = line?.eventsMarketsIds?.[eventId] || [];

      if (eventId && !isEmpty(eventMarketsIds)) {
        try {
          const headMarkets =
            eventMarketsIds
              .map((marketId) => markets[marketId])
              .filter(Boolean) || [];

          const mrkts = headMarkets.filter(
            ({ marketTemplateId, resultTypeId }) =>
              marketTemplateId === headGroupMarketTemplateId &&
              resultTypeId === headGroupResultTypeId
          );
          const sortedMarketIds = getMinMarketIds(mrkts, outcomes);

          return sortedMarketIds[0];
        } catch (e) {
          logAction(e, LOG_ACTIONS.EVENT_BODY_MARKETS);
        }
      }
      return null;
    }
  );

export const useEventMarketId = ({
  resultTypeId,
  marketTemplateId,
  eventId,
  lineKey
}) =>
  useSportsbookSelector(
    selectEventMarketId,
    [lineKey, eventId, resultTypeId, marketTemplateId],
    shallow
  );
