import { memo } from 'react';
import { LazyLoadImage as LazyImageLoad } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import PropTypes from 'prop-types';
import { window } from '@namespace/helpers';

const visibleByDefault = window.IS_SSR
  ? true
  : window.navigator.userAgent.includes('Version/15.1 Safari');

const LazyLoadImage = ({
  className,
  src,
  alt,
  width = '100%',
  height = '100%',
  effect = null,
  onLoad,
  onError,
  placeholderSrc
}) => {
  return (
    <LazyImageLoad
      className={className}
      src={src}
      alt={alt}
      width={width}
      height={height}
      effect={effect}
      onLoad={onLoad}
      onError={onError}
      threshold="200"
      placeholderSrc={placeholderSrc}
      visibleByDefault={visibleByDefault}
    />
  );
};

LazyLoadImage.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  effect: PropTypes.string,
  onLoad: PropTypes.func,
  onError: PropTypes.func
};

export default memo(LazyLoadImage);
