import { useContext } from 'react';
import { Select } from '@alf/uikit';
import { DeviceContext } from '@namespace/device';
import { COLOR_SCHEME } from '@namespace/themes';
import { TIME_FILTER_DATA } from '../../../../constants';
import styles from '../../index.module.css';

export const TimeFilter = ({ selectHours, selectedHours, dataRole }) => {
  const { isDesktopDevice, isDesktop } = useContext(DeviceContext);

  return (
    <Select
      value={selectedHours.value}
      options={TIME_FILTER_DATA}
      onChange={selectHours}
      isDesktop={isDesktop}
      isDesktopDevice={isDesktopDevice}
      className={styles.timeFilter}
      intentType={COLOR_SCHEME.SECONDARY}
      dataRole={dataRole}
    />
  );
};
