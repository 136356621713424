/**
 * @memberOf module:SetScore
 * Returns value for participant by scopeId, resultTypeId and playerNumber
 * @param {ResultTypeData} resultTypeData
 * @param {number[]} resultTypeIds
 * @param {number} scopeId
 * @param {number} playerNumber
 */
import {
  RESULT_TYPE_IDS,
  SCOPE_IDS,
  SPORT_IDS
} from '../../../../../../constants';

const getValueByResultTypeIdAndScopeIdForParticipant = (
  resultTypeData,
  resultTypeIds,
  scopeId,
  playerNumber
) => {
  const filteredByResultId = resultTypeData.filter(({ resultTypeId: rId }) =>
    resultTypeIds.includes(rId)
  );

  const filteredByScopeIdAndPlayerNumber = filteredByResultId
    .map(({ resultTypeData: rTD }) =>
      rTD
        .filter(({ scopeId: sId }) => sId === scopeId)
        .map(({ scopeData }) =>
          scopeData
            .filter(({ number }) => number === playerNumber)
            .map(({ value }) => value)
        )
    )
    .map((value) => {
      if (value.length > 0) {
        const [[v]] = value;
        return v;
      }
      return undefined;
    })
    .filter((v) => v !== undefined);

  return filteredByScopeIdAndPlayerNumber.reduce(
    (acc, value) => acc + value,
    0
  );
};

const getParticipantScoreTotalByScopeId = (
  resultTotalData,
  scopeId,
  playerNumber
) => {
  const totalForScopeId = resultTotalData.find(
    ({ scopeId: id }) => id === scopeId
  );
  const { scopeData } = totalForScopeId || {};

  return scopeData?.find(({ number }) => number === playerNumber).value || '';
};

const getSoccerScores = (
  resultByTypeData,
  resultTotalData,
  firstPlayerNumber,
  secondPlayerNumber
) => ({
  firstParticipantTotalPoints: getParticipantScoreTotalByScopeId(
    resultTotalData,
    SCOPE_IDS.POINTS,
    firstPlayerNumber
  ),
  secondParticipantTotalPoints: getParticipantScoreTotalByScopeId(
    resultTotalData,
    SCOPE_IDS.POINTS,
    secondPlayerNumber
  ),
  firstParticipantTotalPointsInFT: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.HALF_1],
    SCOPE_IDS.POINTS,
    firstPlayerNumber
  ),
  secondParticipantTotalPointsInFT: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.HALF_1],
    SCOPE_IDS.POINTS,
    secondPlayerNumber
  ),
  firstParticipantTotalPointsInST: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.HALF_2],
    SCOPE_IDS.POINTS,
    firstPlayerNumber
  ),
  secondParticipantTotalPointsInST: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.HALF_2],
    SCOPE_IDS.POINTS,
    secondPlayerNumber
  ),
  firstParticipantTotalPointsInET: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.EXTRATIME_1],
    SCOPE_IDS.POINTS,
    firstPlayerNumber
  ),
  secondParticipantTotalPointsInET: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.EXTRATIME_1],
    SCOPE_IDS.POINTS,
    secondPlayerNumber
  ),
  firstParticipantTotalPointsInET2: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.EXTRATIME_2],
    SCOPE_IDS.POINTS,
    firstPlayerNumber
  ),
  secondParticipantTotalPointsInET2: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.EXTRATIME_2],
    SCOPE_IDS.POINTS,
    secondPlayerNumber
  ),
  firstParticipantTotalPenaltyPoints: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.PENALTIES],
    SCOPE_IDS.PEN,
    firstPlayerNumber
  ),
  secondParticipantTotalPenaltyPoints: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.PENALTIES],
    SCOPE_IDS.PEN,
    secondPlayerNumber
  )
});

const getBasketballScores = (
  resultByTypeData,
  resultTotalData,
  firstPlayerNumber,
  secondPlayerNumber
) => ({
  firstParticipantTotalPoints: getParticipantScoreTotalByScopeId(
    resultTotalData,
    SCOPE_IDS.POINTS,
    firstPlayerNumber
  ),
  secondParticipantTotalPoints: getParticipantScoreTotalByScopeId(
    resultTotalData,
    SCOPE_IDS.POINTS,
    secondPlayerNumber
  ),
  firstParticipantQ1Points: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.QUARTER1],
    SCOPE_IDS.POINTS,
    firstPlayerNumber
  ),
  secondParticipantQ1Points: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.QUARTER1],
    SCOPE_IDS.POINTS,
    secondPlayerNumber
  ),
  firstParticipantQ2Points: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.QUARTER2],
    SCOPE_IDS.POINTS,
    firstPlayerNumber
  ),
  secondParticipantQ2Points: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.QUARTER2],
    SCOPE_IDS.POINTS,
    secondPlayerNumber
  ),
  firstParticipantQ3Points: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.QUARTER3],
    SCOPE_IDS.POINTS,
    firstPlayerNumber
  ),
  secondParticipantQ3Points: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.QUARTER3],
    SCOPE_IDS.POINTS,
    secondPlayerNumber
  ),
  firstParticipantQ4Points: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.QUARTER4],
    SCOPE_IDS.POINTS,
    firstPlayerNumber
  ),
  secondParticipantQ4Points: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.QUARTER4],
    SCOPE_IDS.POINTS,
    secondPlayerNumber
  ),
  firstParticipantQ4ETPoints: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.QUARTER4_WITH_ET],
    SCOPE_IDS.POINTS,
    firstPlayerNumber
  ),
  secondParticipantQ4ETPoints: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.QUARTER4_WITH_ET],
    SCOPE_IDS.POINTS,
    secondPlayerNumber
  )
});

const getVolleyballScores = (
  resultByTypeData,
  resultTotalData,
  firstPlayerNumber,
  secondPlayerNumber
) => ({
  firstParticipantTotalPoints: getParticipantScoreTotalByScopeId(
    resultTotalData,
    SCOPE_IDS.SETS,
    firstPlayerNumber
  ),
  secondParticipantTotalPoints: getParticipantScoreTotalByScopeId(
    resultTotalData,
    SCOPE_IDS.SETS,
    secondPlayerNumber
  ),
  firstParticipantSet1Points: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.VOLLEYBALL_SET_1],
    SCOPE_IDS.POINTS,
    firstPlayerNumber
  ),
  secondParticipantSet1Points: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.VOLLEYBALL_SET_1],
    SCOPE_IDS.POINTS,
    secondPlayerNumber
  ),
  firstParticipantSet2Points: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.VOLLEYBALL_SET_2],
    SCOPE_IDS.POINTS,
    firstPlayerNumber
  ),
  secondParticipantSet2Points: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.VOLLEYBALL_SET_2],
    SCOPE_IDS.POINTS,
    secondPlayerNumber
  ),
  firstParticipantSet3Points: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.VOLLEYBALL_SET_3],
    SCOPE_IDS.POINTS,
    firstPlayerNumber
  ),
  secondParticipantSet3Points: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.VOLLEYBALL_SET_3],
    SCOPE_IDS.POINTS,
    secondPlayerNumber
  ),
  firstParticipantSet4Points: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.VOLLEYBALL_SET_4],
    SCOPE_IDS.POINTS,
    firstPlayerNumber
  ),
  secondParticipantSet4Points: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.VOLLEYBALL_SET_4],
    SCOPE_IDS.POINTS,
    secondPlayerNumber
  ),
  firstParticipantSet5Points: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.VOLLEYBALL_SET_5],
    SCOPE_IDS.POINTS,
    firstPlayerNumber
  ),
  secondParticipantSet5Points: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.VOLLEYBALL_SET_5],
    SCOPE_IDS.POINTS,
    secondPlayerNumber
  ),
  firstParticipantSet6Points: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.VOLLEYBALL_SET_6],
    SCOPE_IDS.POINTS,
    firstPlayerNumber
  ),
  secondParticipantSet6Points: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.VOLLEYBALL_SET_6],
    SCOPE_IDS.POINTS,
    secondPlayerNumber
  ),
  firstParticipantSet7Points: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.VOLLEYBALL_SET_7],
    SCOPE_IDS.POINTS,
    firstPlayerNumber
  ),
  secondParticipantSet7Points: getValueByResultTypeIdAndScopeIdForParticipant(
    resultByTypeData,
    [RESULT_TYPE_IDS.VOLLEYBALL_SET_7],
    SCOPE_IDS.POINTS,
    secondPlayerNumber
  )
});

/**
 * @callback scoreGetter
 * @memberOf module:SetScore
 * Gets participants values for scopes by sportId and result types
 * @param {ResultTypeData} resultTypeData
 * @param {number} firstPlayerNumber
 * @param {number} secondPlayerNumber
 * @returns {(Object.<string, number>|null)} values
 */

/**
 * {Object.<(string|number), {scoreGetter}>}
 */
const SCORE_GETTERS_MAP = {
  [SPORT_IDS.SOCCER]: getSoccerScores,
  [SPORT_IDS.BASKETBALL]: getBasketballScores,
  [SPORT_IDS.VOLLEYBALL]: getVolleyballScores
};

/**
 * @memberOf module:SetScore
 * Gets participants values for scopes by sportId and result types
 * @param {ResultTypeData} resultByTypeData
 * @param {ResultTypeData} resultTotalData
 * @param {number} sportId
 * @param {number} firstPlayerNumber
 * @param {number} secondPlayerNumber
 * @returns {(Object.<string, number>|null)} values
 */
export const getScoreForSport = (
  resultByTypeData,
  resultTotalData,
  sportId,
  firstPlayerNumber,
  secondPlayerNumber
) =>
  SCORE_GETTERS_MAP[sportId]
    ? SCORE_GETTERS_MAP[sportId](
        resultByTypeData,
        resultTotalData,
        firstPlayerNumber,
        secondPlayerNumber
      )
    : null;
