import { useMemo, useContext } from 'react';
import { get } from 'lodash';
import { Player } from '@namespace/common';
import { useRouteOptionalParams } from '@namespace/router';
import { useGeneralRoutes } from '@namespace/cms';
import { useI18n, useLocation } from '@namespace/i18n';
import { Box } from '@alf/uikit';
import isBetradarProvider from '../../../../utils/isBetradarProvider';
import { VirtualSportsContext } from '../../../../store/context';
import Sidebar from '../Sidebar';
import styles from './index.module.css';

const Stream = () => {
  const [
    { virtualStreams, virtualTournaments, virtualEvents, virtualSports: sports }
  ] = useContext(VirtualSportsContext);
  const { f, t } = useI18n();
  const { pathname } = useLocation();
  const { virtualSportsOneSport } = useGeneralRoutes();
  const { provider, categoryId, optional = [] } = useRouteOptionalParams(
    `${virtualSportsOneSport}/:provider/:categoryId/*`
  );

  const [tournamentId, eventId] = optional;

  const isBetradar = isBetradarProvider(provider);

  // @todo create normal optimized selector
  const eventsList = useMemo(() => {
    if (isBetradar) {
      return get(sports, [provider, categoryId, 'tournaments'], [])
        .map((tId) => virtualTournaments[provider][tId])
        .reduce(
          (acc, tour) => [...acc, virtualEvents[provider][tour.events[0]]],
          []
        )
        .slice(0, 10)
        .map((event) => ({
          text: f.getDateTime({ date: event.eventDt }).toFormat('HH:mm'),
          link: `${virtualSportsOneSport}/${provider}/${categoryId}/${event.tournamentId}/${event.eventId}`,
          isActive: tournamentId === String(event.tournamentId)
        }));
    }

    return get(sports, [provider, categoryId, 'tournaments'], [])
      .map((tId) => virtualTournaments[provider][tId])
      .reduce((acc, tour) => [...acc, ...tour.events], [])
      .map((e) => virtualEvents[provider][e])
      .sort((a, b) => a.eventDt - b.eventDt)
      .map((id) => ({
        text: f.getDateTime({ date: id.eventDt }).toFormat('HH:mm'),
        link: pathname.replace(eventId, id.eventId)
      }));
  }, [
    isBetradar,
    sports,
    provider,
    categoryId,
    tournamentId,
    virtualTournaments,
    virtualEvents,
    pathname
  ]);

  if (!eventsList.length) {
    return null;
  }

  const streamUrl = get(
    virtualStreams,
    isBetradar ? `${provider}.${eventId}` : `${provider}.${categoryId}`,
    ''
  );

  let streamsList = [];
  let tournamentName = '';

  if (isBetradar) {
    const currentTournament = get(
      virtualTournaments,
      [provider, tournamentId],
      []
    );

    tournamentName = get(currentTournament, 'tournamentName', '');
    streamsList = get(currentTournament, 'events', []).map((id) => ({
      text: virtualEvents[provider][id].eventName,
      link: pathname.replace(eventId, virtualEvents[provider][id].eventId)
    }));
  }

  const isShowLeftSidebar = streamsList.length > 1;

  return (
    <Box className={styles.streamWrapper}>
      <Box
        className={`
            ${styles.playerContainer}
            ${isShowLeftSidebar ? styles.showLeft : ''}
          `}
      >
        {isShowLeftSidebar && (
          <Sidebar
            position="left"
            className={styles.left}
            values={streamsList}
            headerText={tournamentName}
          />
        )}
        <Player
          className={styles.player}
          streamUrl={streamUrl}
          autoplay={true}
        />
        <Sidebar
          position="right"
          values={eventsList}
          headerText={t('virtualSports.events')}
        />
      </Box>
    </Box>
  );
};

export default Stream;
