import { pathOr } from 'ramda';
import { shallow } from 'zustand/shallow';
import { useSportsbookStore } from '../../../../store';

export const selectOutcomeField = (outcomeId, fieldPath) =>
  pathOr({}, [
    'outcomes',
    outcomeId,
    ...(Array.isArray(fieldPath) ? fieldPath : [fieldPath])
  ]);

export const useOutcomeField = (outcomeId, fieldPath) =>
  useSportsbookStore(selectOutcomeField(outcomeId, fieldPath), shallow);
