import { memo, useMemo } from 'react';
import { useI18n } from '@namespace/i18n';
import {
  getDetailedScoresForTable,
  getEventValues,
  stripeScore,
  getResultTypeScoreValues
} from '../../utils';
import {
  SCOPE_IDS_FOR_PERIODS,
  SPORTS_WITH_ALL_PERIODS,
  TOTAL_SCOPE_ID_BY_SPORT,
  TOTAL_SYMBOL_BY_SPORT
} from '../../constants';
import { COLUMN_TYPES } from '../../../../constants';
import ScoreDetails from '../../Details';
import styles from './index.module.css';

// TODO: need to improve this sport for new scoreboard
const Baseball = ({
  sportId,
  eventResultId,
  byResultType,
  total,
  isShortVersion,
  lineView
}) => {
  const { t } = useI18n();

  const scoreByPeriod = useMemo(
    () =>
      isShortVersion
        ? [
            {
              type: COLUMN_TYPES.DEFAULT,
              tag: '',
              scores: getResultTypeScoreValues(
                byResultType,
                eventResultId,
                SCOPE_IDS_FOR_PERIODS[sportId] ||
                  TOTAL_SCOPE_ID_BY_SPORT[sportId]
              )
            }
          ]
        : [
            ...stripeScore(
              getDetailedScoresForTable(byResultType),
              sportId,
              eventResultId,
              SPORTS_WITH_ALL_PERIODS.includes(sportId)
            )
          ],
    [byResultType, eventResultId, isShortVersion, sportId]
  );

  const fullFormattedScore = useMemo(
    () => [
      {
        type: COLUMN_TYPES.TOTAL,
        tag: TOTAL_SYMBOL_BY_SPORT[sportId]
          ? t(TOTAL_SYMBOL_BY_SPORT[sportId])
          : t(TOTAL_SYMBOL_BY_SPORT.default),
        scores: getEventValues(total, TOTAL_SCOPE_ID_BY_SPORT[sportId])
      },
      ...scoreByPeriod
    ],
    [scoreByPeriod, sportId, t, total]
  );

  return (
    <div className={styles.container}>
      {/* { */}
      {/*  eventRtsData?.length > 0 && ( */}
      {/*    <> */}
      {/*      { */}
      {/*        !isMobile && ( */}
      {/*          <div className={styles.basesContainer}> */}
      {/*            { */}
      {/*              bases.map(({ value, type }, index) => ( */}
      {/*                Number(value) !== 0 && ( */}
      {/*                  <div key={type} className={styles[`${BASE}${index + 1}`]}> */}
      {/*                    <Icon name="baseballBase" size={ICON_SIZES.EXTRA_LARGE} /> */}
      {/*                  </div> */}
      {/*                ) */}
      {/*              )) */}
      {/*            } */}
      {/*          </div> */}
      {/*        ) */}
      {/*      } */}
      {/*      <div className={styles.additionalInfo}> */}
      {/*        <div className={styles.direction}> */}
      {/*          {directionName && t(`scoreboard.baseball.${directionName}`)} */}
      {/*        </div> */}
      {/*        { */}
      {/*          gamePointsData.map(({ type, value }) => ( */}
      {/*            <div key={type} className={styles.gamePoints}> */}
      {/*              <div className={styles.gamePointsType}> */}
      {/*                {t(`scoreboard.baseball.${type}`)} */}
      {/*              </div> */}
      {/*              <div className={styles.gamePointsValue}>{value}</div> */}
      {/*            </div> */}
      {/*          )) */}
      {/*        } */}
      {/*      </div> */}
      {/*    </> */}
      {/*  ) */}
      {/* } */}
      {/* <BetDetails */}
      {/*  eventData={eventData} */}
      {/*  scoreData={fullFormattedScore} */}
      {/*  isDisplayClockIcon={true} */}
      {/*  infoClassNames={{ */}
      {/*    cellClass: styles.cell, */}
      {/*    participantClass: styles.participant, */}
      {/*    firstColumnClass: styles.firstColumn */}
      {/*  }} */}
      {/*  scoreClassNames={{ */}
      {/*    cellClass: styles.cell, */}
      {/*    tagTitleClass: styles.tagTitle */}
      {/*  }} */}
      {/* /> */}
      <ScoreDetails
        scoreData={fullFormattedScore}
        isShortVersion={isShortVersion}
        lineView={lineView}
      />
    </div>
  );
};

export default memo(Baseball);
