import { memo } from 'react';
import { Box } from '@alf/uikit';
import Column from './Column';

/**
 * Component for score building
 * @param {{
 *   type: string
 *   tag: string
 *   scores: (string | number)[]
 * }[]} scoreData
 * @param {boolean} isShortVersion
 * @param {string} lineView
 * @returns {*}
 * @constructor
 */
const ScoreDetails = ({ scoreData, isShortVersion, lineView }) => (
  <Box align="center" justify="center">
    {scoreData.map((scoreColumn, index) => {
      const key = `scoreColumn-${index}-${scoreColumn?.type}--${scoreColumn?.tag}`;
      return (
        <Column
          key={key}
          {...scoreColumn}
          isShortVersion={isShortVersion}
          lineView={lineView}
        />
      );
    })}
  </Box>
);

export default memo(ScoreDetails);
