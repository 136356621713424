export const STORE_NAMES = {
  PROMOTIONS: 'PromotionsStore',
  SPORTSBOOK: 'SportsbookStore',
  ADV_FILTER: 'AdvFilterStore',
  ADV_FILTER_INNER: 'AdvFilterInnerStore',
  EVENT_HEADER: 'EventHeaderStore'
};

// NB it belongs to SSR package but moved here because of circular deps
export const HTTP_CONTEXT_KEYS = {
  INITIAL_STATE: 'INITIAL_STATE',
  STORES: 'STORES'
};
