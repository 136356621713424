import clsx from 'clsx';
import { Close, LeaveModal, Stepper } from '@namespace/common';
import { useThemeComponents } from '@namespace/themes';
import { Support as DefaultSupport } from '../Support';
import NeedHelpSection from '../NeedHelpSection';
import styles from './index.module.css';

const GuestContent = ({
  config = {},
  onClose,
  popup,
  setPopup,
  componentsMap = {},
  classNames = {},
  onChangeStep,
  dataRole = '',
  header,
  successMessage,
  defaultActiveStep,
  defaultFormData
}) => {
  const {
    steps = [],
    hideNeedHelpLabel = false,
    needHelpText = '',
    isShowSupport = false,
    isWithCloseButton = false,
    isNeedHandleLeave = false,
    isShowNeedHelp = false
  } = config;
  const {
    container = '',
    formClassName = '',
    wrapperClassName = '',
    closeIconClassName = '',
    ...stepsClassNames
  } = classNames;

  const { Support = DefaultSupport } = useThemeComponents();

  return (
    <div className={clsx([styles.container, container])}>
      {popup?.isShowPopup && isNeedHandleLeave && (
        <LeaveModal
          onConfirm={() => {
            setPopup({
              isShowPopup: false,
              secondClick: true
            });
          }}
          onClose={() => {
            onClose();
            setPopup({
              isShowPopup: false,
              secondClick: false
            });
          }}
        />
      )}
      <div
        className={clsx([
          styles.wrapper,
          isShowSupport && styles.withSupport,
          wrapperClassName
        ])}
      >
        {isWithCloseButton && (
          <Close
            dataRole={`${dataRole}-close-btn`}
            onClose={
              isNeedHandleLeave && !popup?.secondClick
                ? () =>
                    setPopup((prevState) => ({
                      ...prevState,
                      isShowPopup: true
                    }))
                : onClose
            }
            className={closeIconClassName}
          />
        )}
        <Stepper
          config={config}
          stepsMap={steps}
          componentsMap={componentsMap}
          header={header}
          onChangeStep={onChangeStep}
          stepsClassNames={{
            ...stepsClassNames,
            formClassName: clsx([styles.form, formClassName]),
            inputWithoutLabel: styles.inputWithoutLabel
          }}
          successMessage={successMessage}
          defaultActiveStep={defaultActiveStep}
          defaultFormData={defaultFormData}
        />
        {isShowNeedHelp && (
          <NeedHelpSection
            dataRole={`${dataRole}-need-help`}
            linkText={needHelpText}
          />
        )}
        {isShowSupport && (
          <Support dataRole={dataRole} hideNeedHelpLabel={hideNeedHelpLabel} />
        )}
      </div>
    </div>
  );
};

export default GuestContent;
