import { useCallback, useContext, useEffect } from 'react';
import clsx from 'clsx';
import { isString } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import { AText, Box, Input } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import useOtherSettings from '@namespace/cms/src/hooks/useOtherSettings';
import { SWPaymentsContext } from '@namespace/payments-sw/src/store/context';
import { PAYMENT_TYPES } from '@namespace/payments-sw/src/constants';
import { LOG_ACTIONS, logAmplitude } from '@namespace/analytics';
import getFormError from '../../utils/getFormError';
import useDynamicField from '../../hooks/useDynamicField';
import useCustomError from '../../hooks/useCustomError';
import useOnChangeValidate from '../../hooks/useOnChangeValidate';
import Control from '../../common/Control';
import AutoAmountComponent from './components/AutoAmount';
import AmountInputWithButtons from './components/AmountInputWithButtons';
import styles from './index.module.css';

const AmountInputField = ({
  name,
  label = '',
  placeholder,
  hint,
  classNames = {},
  rules = {},
  disabled = false,
  inputType = 'number',
  allowClean,
  step = 0.01,
  min = 0,
  currency = '',
  autoAmount = [],
  isOnChangeValidation = false,
  customError = '',
  formProps = {},
  disableChangeValueByScroll = false,
  taxComponent = null,
  displayCurrencyConvertation = false,
  withButtons = false,
  changeAmountStep = 100,
  setAmountValue = () => {},
  analyticAction,
  ...props
}) => {
  const { t } = useI18n();
  const {
    containerClassName = '',
    autoAmountClassName = '',
    wrapperClassName = '',
    inputClassName = '',
    controlClassName = '',
    buttonClassName = '',
    hintClassName = '',
    currencyClassName = '',
    labelClassName = ''
  } = classNames;
  const methods = useFormContext();
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    trigger
  } = methods;
  const { errorMessage, customErrorHandler } = useCustomError(customError);
  const errorText = getFormError(name, errors, t) || t(errorMessage) || '';
  const [{ activePaymentType }] = useContext(SWPaymentsContext);
  const { payments = {}, hasCurrencyConvertation = false } = useOtherSettings();
  const { isWithdrawalWithCentsAllowed = false } = payments;
  const isWithdrawal = activePaymentType === PAYMENT_TYPES.WITHDRAW;
  const inputPattern =
    isWithdrawal && isWithdrawalWithCentsAllowed
      ? '[0-9]+([\\.,][0-9]+)?'
      : '[0-9]*';
  const disabledScrollProps = disableChangeValueByScroll
    ? {
        inputMode: 'numeric',
        pattern: inputPattern
      }
    : {};

  const autoAmountProps = withButtons
    ? {
        buttonSize: 'm',
        intent: 'primary',
        actionType: 'color2',
        amountBreed: 'R'
      }
    : {};

  const { dynamicValues = {} } = formProps;
  useDynamicField({ name, dynamicFieldData: dynamicValues[name] });
  const formAmount = Number(watch(name));
  const isNegativeAmount = Math.sign(formAmount) < 0;
  const isOnchangeValidationEnabled = isOnChangeValidation && !isNegativeAmount;

  useOnChangeValidate({
    name,
    isOnChangeValidation: isOnchangeValidationEnabled
  });

  const setAmount = async (value) => {
    if (analyticAction) {
      logAmplitude(LOG_ACTIONS.DEPOSIT_CLICK_PREDEFINED_AMOUNT, {
        amount: value - Math.abs(formAmount)
      });
    }
    setValue(name, value);
    await trigger(name);
  };

  useEffect(() => {
    setAmountValue(formAmount);
  }, [setAmountValue, formAmount]);

  useEffect(() => {
    if (isNegativeAmount) {
      setValue(name, Math.abs(formAmount));
    }
  }, [formAmount, setValue, name, isNegativeAmount]);

  const handleAmountChange = useCallback(
    (increment) => {
      setAmount(
        increment
          ? formAmount + changeAmountStep
          : Math.max(0, formAmount - changeAmountStep)
      );
    },
    [changeAmountStep, formAmount, setAmount]
  );

  return (
    <Box className={containerClassName} direction="column">
      <Box className={wrapperClassName} direction="row">
        <Control
          error={errorText}
          onClick={customErrorHandler}
          hint={hint}
          label={t(label)}
          classNames={{
            className: controlClassName,
            hintClassName,
            labelClassName
          }}
          displayCurrencyConvertation={
            displayCurrencyConvertation && hasCurrencyConvertation
          }
          formAmount={`${formAmount}`}
          currency={currency}
        >
          <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field: { ref, onChange, onBlur, ...field } }) => {
              const handleChange = (e) => {
                const { target } = e;
                if (isString(target.value)) {
                  target.value = target.value.trim();
                }
                onChange(e);
              };
              return withButtons ? (
                <AmountInputWithButtons
                  handleAmountChange={handleAmountChange}
                  onChange={handleChange}
                  min={min}
                  step={step}
                  placeholder={t(placeholder)}
                  disabled={disabled}
                  inputClassName={inputClassName}
                  errorText={errorText}
                  {...disabledScrollProps}
                  {...props}
                  {...field}
                  onBlur={(e) => {
                    if (analyticAction && formAmount > 0) {
                      logAmplitude(analyticAction, {
                        amount: formAmount
                      });
                    }
                    onBlur(e);
                  }}
                />
              ) : (
                <Input
                  onChange={handleChange}
                  type={inputType}
                  min={min}
                  step={step}
                  placeholder={t(placeholder)}
                  disabled={disabled}
                  className={`${styles.amountInput} ${inputClassName}`}
                  intent={errorText ? 'error' : null}
                  {...disabledScrollProps}
                  {...props}
                  {...field}
                  onBlur={(e) => {
                    if (analyticAction && formAmount > 0) {
                      logAmplitude(analyticAction, {
                        amount: formAmount
                      });
                    }
                    onBlur(e);
                  }}
                />
              );
            }}
          />
        </Control>
        {currency && (
          <Box
            component="span"
            direction="column"
            justify="start"
            padding={label ? 'l 0 0 0' : 's 0 0 0'}
            margin={label ? '0' : 'xs 0 0 0'}
          >
            <AText
              color="po_txt_color_1"
              breed="B"
              className={clsx([styles.currency, currencyClassName])}
              size="m"
            >
              {currency}
            </AText>
          </Box>
        )}
      </Box>
      {taxComponent}
      {autoAmount?.length > 0 && (
        <AutoAmountComponent
          name={name}
          wrapperClassName={autoAmountClassName}
          buttonClassName={buttonClassName}
          amounts={autoAmount}
          setValue={setAmount}
          initialAmount={formAmount}
          currency={currency}
          isDisabled={disabled}
          {...autoAmountProps}
        />
      )}
    </Box>
  );
};

export default AmountInputField;
