import { useI18n } from '@namespace/i18n';
import { useCyberSportGetPathParams } from '../../../hooks/useCyberSportGetPathParams';
import BasePopularEventsCards from './BasePopularEventsCards';

const CyberSportPopularBetsEventsCards = ({ id }) => {
  const { activeSport } = useCyberSportGetPathParams();
  const { t } = useI18n();

  return (
    <BasePopularEventsCards
      id={id}
      sport={activeSport}
      title={t('sportsbook.eventsTitle.popular')}
    />
  );
};

export default CyberSportPopularBetsEventsCards;
