import { forwardRef, useContext } from 'react';
import { DeviceContext } from '@namespace/device';
import PropTypes from 'prop-types';
import {
  useEventField,
  useEventMarketId,
  useSportsbookStore
} from '../../store';
import { useEventLink } from '../../hooks/useEventLink';
import InnerItem from './components/InnerItem';

const EventCard = forwardRef(
  (
    {
      isShowShortName,
      className = '',
      title = '',
      lineKey,
      isShowFlag,
      eventId,
      isShowStatUrl,
      isLiveEvents,
      gap
    },
    ref
  ) => {
    const { isDesktop } = useContext(DeviceContext);
    const sportId = useEventField(eventId, 'sportId');
    const { headGroups } = useSportsbookStore(['headGroups']);

    const { marketTemplateId, resultTypeId } = headGroups[sportId]
      ? headGroups[sportId][0]
      : {};
    const eventLink = useEventLink({ eventId });
    const marketId = useEventMarketId({
      resultTypeId,
      marketTemplateId,
      eventId,
      lineKey
    });

    if (!sportId) {
      return null;
    }
    return (
      <InnerItem
        ref={ref}
        gap={gap}
        className={className}
        eventLink={eventLink}
        title={title}
        isShowFlag={isShowFlag}
        shouldRenderEventMarket={marketId}
        eventId={eventId}
        marketId={marketId}
        marketTemplateId={marketTemplateId}
        resultTypeId={resultTypeId}
        isShowShortName={isShowShortName}
        isShowStatUrl={isShowStatUrl}
        isDesktop={isDesktop}
        isLiveEvents={isLiveEvents}
      />
    );
  }
);

EventCard.propTypes = {
  gap: PropTypes.string,
  eventId: PropTypes.string,
  className: PropTypes.string,
  isShowShortName: PropTypes.bool,
  title: PropTypes.string,
  lineKey: PropTypes.string,
  isShowFlag: PropTypes.bool,
  isShowStatUrl: PropTypes.bool
};

export default EventCard;
