const getPhoneLength = (isoCode = '', code = '') => {
  if (isoCode === 'UA' || isoCode === 'RO' || isoCode === 'HR') {
    return code.length + 10;
  }
  if (isoCode === 'BR') {
    return code.length + 12;
  }

  return code.length + 13;
};

export default getPhoneLength;
