import { useRouteParams, useHistory } from '@namespace/router';
import { useGeneralRoutes } from '@namespace/cms';
import { Button, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';

const ResultsButton = () => {
  const { t } = useI18n();
  const { virtualSportsOneSport, virtualSportsResults } = useGeneralRoutes();
  const { provider, categoryId } = useRouteParams(
    `${virtualSportsOneSport}/:provider/:categoryId`
  );
  const { push } = useHistory();

  if (!provider || !categoryId) {
    return null;
  }

  return (
    <Box align="center" justify="center" padding="m">
      <Button
        intent="secondary"
        actionType="color3"
        size="m"
        onClick={() =>
          push(`${virtualSportsResults}/${provider}/${categoryId}/`)
        }
      >
        {t('virtualSports.results')}
      </Button>
    </Box>
  );
};

export default ResultsButton;
