import { useContext, useEffect, useState } from 'react';
import { UserContext } from '@namespace/user/src/context';
import { USER_ROLES } from '@namespace/user/src/constants';
import { asyncAttempt } from '@namespace/helpers';
import { getFreshChatUser } from '../../api/getFreshChatUser';

const useFreshChatUserData = () => {
  const [data, setData] = useState(null);

  const [{ role }] = useContext(UserContext);

  useEffect(() => {
    (async () => {
      if (role === USER_ROLES.USER) {
        const [error, result] = await asyncAttempt(getFreshChatUser);

        setData(result);

        if (error) {
          console.warn(error);
        }

        return;
      }

      setData({});
    })();
  }, [role]);

  return data;
};

export default useFreshChatUserData;
