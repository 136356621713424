const maxValidator = (
  value,
  message = 'validators.maxValidator',
  params = []
) => ({
  max: {
    value,
    message: [message, value, ...params]
  }
});

export default maxValidator;
