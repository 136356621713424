import { memo } from 'react';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';
import IconSVGComponent from './IconSVGComponent';
import styles from './index.module.css';

// TODO some properties could be deprecated and/or we can provide css api for that
const Icon = ({
  name,
  size = 'l',
  className = '',
  color,
  style,
  fill,
  dataRole,
  ...props
}) => {
  const iconStyle = isUndefined(fill) ? styles.icon : '';
  const extStyles = fill ? { fill } : {};

  return (
    <IconSVGComponent
      className={`${iconStyle} ${styles[`s_${size}`]} ${className}`}
      style={
        color
          ? { ...style, color: `var(--${color})` }
          : { ...style, ...extStyles }
      }
      name={name}
      dataRole={dataRole}
      {...props}
    />
  );
};

Icon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf([
    'xs',
    's',
    'm',
    'l',
    'xl',
    '2xl',
    '3xl',
    '4xl',
    'auto'
  ]),
  dataRole: PropTypes.string
};

export default memo(Icon);
