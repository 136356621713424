import { document } from '@namespace/helpers';

/**
 * Check if element is valid - it's in DOM and in layout (display !== 'none')
 * @param {Element} el
 * @returns {boolean}
 */
export const checkEl = (el) => {
  if (!document.contains(el)) {
    return false;
  }

  const cs = getComputedStyle(el);
  if (cs.display === 'none') {
    return false;
  }

  const rect = el.getBoundingClientRect();

  // noinspection RedundantIfStatementJS
  if (rect.width === 0 && rect.height === 0) {
    return false;
  }

  return true;
};
