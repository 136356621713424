import styles from './index.module.css';

const SkeletSportItem = ({ className }) => (
  <>
    <img
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUoAAAHMCAQAAAAZySlLAAADR0lEQVR42u3SAQ0AAAzCsOPf9G2Q0EpYloMykQBTgikxJZgSU4IpMSWYEkyJKcGUmBJMiSnBlJgSTAmmxJRgSkwJpsSUYEowJaYEU2JKMCWmBFNiSjAlmBJTgikxJZgSU4IpwZSYEkyJKcGUmBJMiSnBlGBKTAmmxJRgSkwJpgRTYkowJaYEU2JKMCWmBFOCKTElmBJTgikxJZgSTIkpwZSYEkyJKcGUmBJMCabElGBKTAmmxJRgSjAlpgRTYkowJaYEU2JKMCWYElOCKTElmBJTginBlJgSTIkpwZSYEkyJKcGUYEpMCabElGBKTAmmBFNiSjAlpgRTYkowJaYEU4IpMSWYElOCKTElmBJTginBlJgSTIkpwZSYEkwJpsSUYEpMCabElGBKTAmmBFNiSjAlpgRTYkowJZgSU4IpMSWYElOCKTElmBJMiSnBlJgSTIkpwZRgSkwJpsSUYEpMCabElGBKMCWmBFNiSjAlpgRTgikxJZgSU4IpMSWYElOCKcGUmBJMiSnBlJgSTAmmxJRgSkwJpsSUYEpMCaYEU2JKMCWmBFNiSjAlmBJTgikxJZgSU4IpMSWYEkyJKcGUmBJMiSnBlGBKTAmmxJRgSkwJpsSUYEowJaYEU2JKMCWmBFNiSgkwJZgSU4IpMSWYElOCKcGUmBJMiSnBlJgSTIkpwZRgSkwJpsSUYEpMCaYEU2JKMCWmBFNiSjAlpgRTgikxJZgSU4IpMSWYEkyJKcGUmBJMiSnBlJgSTAmmxJRgSkwJpsSUYEowJaYEU2JKMCWmBFNiSjAlmBJTgikxJZgSU4IpwZSYEkyJKcGUmBJMiSnBlGBKTAmmxJRgSkwJpgRTYkowJaYEU2JKMCWmBFOCKTElmBJTgikxJZgSTIkpwZSYEkyJKcGUmBJMCabElGBKTAmmxJRgSjAlpgRTYkowJaYEU2JKMCWYElOCKTElmBJTgikxJZgSTIkpwZSYEkyJKcGUYEpMCabElGBKTAmmxJRgSjAlpgRTYkowJaYEU4IpMSWYElOCKTElmBJTginBlJgSTIkpwZSYEkwJpsSUYEpMCabElGBKTAmmBFNiSjAlpgRTYkowJZgSU4IpMSWYElOCKVn1T3ABzac09loAAAAASUVORK5CYII="
      alt="clear rectangle"
      className={`${styles.clearRectangle} ${className}`}
    />
    <div className={`${styles.skelet} ${className}`} />
  </>
);

export default SkeletSportItem;
