import { useCallback, useContext } from 'react';
import { useGeneralRoutes } from '@namespace/cms';
import { StepperContext } from '@namespace/common';
import {
  FORM_COMPONENTS_TYPES,
  FORM_VALIDATION_TYPES,
  FormBuilder
} from '@namespace/forms';
import { HTMLSnippet } from '@namespace/snippets';
import { Box } from '@alf/uikit';
import HaveAnAccount from '../../../../../../common/HaveAnAccount';
import RegisterAccordion from '../../../RegisterAccordion';
import styles from './index.module.css';

const Welcome = ({
  onStepSuccess,
  snippetId,
  classNames = {},
  isShowNeedHelp
}) => {
  const { formClassName = '', snippetClassName = '' } = classNames;
  const { login } = useGeneralRoutes();
  const {
    stepper: {
      formData: { email = '', password = '' }
    }
  } = useContext(StepperContext);

  const componentsList = [
    {
      type: FORM_COMPONENTS_TYPES.EMAIL_INPUT_FIELD,
      name: 'email',
      label: 'register.steps.welcome.email',
      placeholder: 'register.steps.welcome.emailPlaceholder',
      validationTypes: [
        FORM_VALIDATION_TYPES.REQUIRED,
        FORM_VALIDATION_TYPES.EMAIL_ASYNC
      ],
      size: 'm'
    },
    {
      type: FORM_COMPONENTS_TYPES.PASSWORD_FIELD,
      name: 'new-password',
      label: 'register.steps.welcome.password',
      placeholder: 'register.steps.welcome.passwordPlaceholder',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
      isOnChangeValidation: true,
      size: 'm'
    },
    {
      type: FORM_COMPONENTS_TYPES.SUBMIT_BUTTON,
      value: 'register.steps.welcome.button',
      dataGmt: 'Registration-step-welcome',
      size: 'xl',
      secondaryElement: (
        <Box direction="column">
          <HaveAnAccount
            to={`${login}?from=registration-page`}
            text="register.steps.welcome.haveAnAccount"
            linkText="register.steps.welcome.login"
          />
          <Box direction="column" margin="ms 0 0 0">
            <RegisterAccordion
              title="register.steps.welcome.messages.whyRegister"
              content="register.steps.welcome.messages.whyRegisterDetails"
            />
            <RegisterAccordion
              title="register.steps.welcome.messages.dataProtection"
              content="register.steps.welcome.messages.dataProtectionDetails"
            />
          </Box>
        </Box>
      )
    }
  ];

  if (isShowNeedHelp) {
    componentsList.push({
      type: FORM_COMPONENTS_TYPES.NEED_HELP,
      needHelpText: 'web.register.needhelp.link',
      dataRole: 'register-need-help'
    });
  }

  const submit = useCallback(
    (fields) => onStepSuccess({ ...fields, password: fields['new-password'] }),
    [onStepSuccess]
  );

  return (
    <>
      {Boolean(snippetId) && (
        <HTMLSnippet
          className={`${styles.snippet} ${snippetClassName}`}
          snippetId={snippetId}
        />
      )}
      <FormBuilder
        wrapperClassName={formClassName}
        components={componentsList}
        formConfig={{
          defaultValues: {
            email,
            'new-password': password
          }
        }}
        onSubmit={submit}
      />
    </>
  );
};

export default Welcome;
