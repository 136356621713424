import { SPORT_IDS } from '../../../../../../constants';
import { buildSoccerTotal } from './buildTotal/buildSoccerTotal';
import { buildBasketballTotal } from './buildTotal/buildBasketballTotal';
import { buildVolleyballTotal } from './buildTotal/buildVolleyballTotal';

const TOTAL_BUILDERS_BY_SPORT_ID = {
  [SPORT_IDS.SOCCER]: buildSoccerTotal,
  [SPORT_IDS.BASKETBALL]: buildBasketballTotal,
  [SPORT_IDS.VOLLEYBALL]: buildVolleyballTotal
};

/**
 * @memberOf module:SetScore
 * @param {Object.<string, (number|string)>} scores
 * @param {(number|string)} sportId
 * @param {ResultTypeData} byResultType
 * @param {(number|string)} resultTypeId
 * @returns {string}
 */
export const buildEventTotalResult = (
  scores,
  sportId,
  byResultType,
  resultTypeId
) =>
  TOTAL_BUILDERS_BY_SPORT_ID[sportId]
    ? TOTAL_BUILDERS_BY_SPORT_ID[sportId](scores, byResultType, resultTypeId)
    : null;
