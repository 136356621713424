import { useI18n } from '@namespace/i18n';
import { AText, Box } from '@alf/uikit';
import { getScore } from '../../../../../../Score/utils';
import { useEventField } from '../../../../../../../store';
import styles from './index.module.css';

const Basketball = ({ eventId }) => {
  const { t } = useI18n();
  const scoreboard = useEventField(eventId, 'scoreboard');
  const { foulsTotal, reboundsTotal } = getScore(scoreboard);

  if (!(foulsTotal && reboundsTotal)) {
    return null;
  }

  const stats = [
    {
      title: t('scoreboard.fouls'),
      data: foulsTotal
    },
    {
      title: t('scoreboard.rebounds'),
      data: reboundsTotal
    }
  ];

  return (
    <Box className={styles.container} align="center" justify="center">
      {stats.map(({ title, data }) => {
        const [firstParticipantScore, secondParticipantScore] = data;

        return (
          <Box key={title} className={styles.stat} align="center">
            <AText color="line_txt_1" size="m">
              {firstParticipantScore}
            </AText>
            <AText
              color="line_txt_3"
              size="s"
              breed="BU"
              className={styles.statTitle}
            >
              {title}
            </AText>
            <AText color="line_txt_1" size="m">
              {secondParticipantScore}
            </AText>
          </Box>
        );
      })}
    </Box>
  );
};

export default Basketball;
