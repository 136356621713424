import { useCallback } from 'react';
import { matchPath } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  getSessionStorageValue,
  SESSION_STORAGE_KEYS
} from '@namespace/helpers';
import { useLocation } from '@namespace/i18n';
import { useGeneralRoutes } from '@namespace/cms';
import { useSearchParams, useHistory } from '@namespace/router';

export const useGetPromotionGoBack = (activePromoType) => {
  const prevPathname = getSessionStorageValue(
    SESSION_STORAGE_KEYS.PREV_PATHNAME,
    ''
  );
  const { backTo } = useSearchParams();
  const { push } = useHistory();
  const { promotions, login } = useGeneralRoutes();
  const { pathname } = useLocation();

  const isPrevLoginPage = !isEmpty(matchPath({ path: login }, prevPathname));

  const promotionsCategory = `${promotions}/${activePromoType}`;

  return useCallback(() => {
    if (backTo) {
      push(backTo);

      return;
    }

    if (prevPathname && !isPrevLoginPage && prevPathname !== pathname) {
      push(prevPathname);

      return;
    }

    push(promotionsCategory);
  }, [
    backTo,
    prevPathname,
    isPrevLoginPage,
    pathname,
    push,
    promotionsCategory
  ]);
};
