import { useI18n } from '@namespace/i18n';
import {
  FORM_COMPONENTS_TYPES,
  FORM_VALIDATION_TYPES,
  FormBuilder
} from '@namespace/forms';
import styles from './index.module.css';

const NewPassword = ({
  onSuccess = () => {},
  errorCode = '',
  passwordFieldClassNames = {},
  isOnChangeValidation = false,
  rulesList,
  repeatPasswordFieldClassNames = {}
}) => {
  const { t } = useI18n();
  const defaultValues = {
    newPassword: '',
    repeatNewPassword: ''
  };

  const componentsList = [
    {
      type: FORM_COMPONENTS_TYPES.PASSWORD_FIELD,
      name: 'newPassword',
      label: 'passwordRecovery.newPassword.label',
      placeholder: 'passwordRecovery.newPassword.placeholder',
      classNames: {
        wrapperClassName: styles.inputWrapper,
        ...passwordFieldClassNames
      },
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
      size: 'm',
      ...(rulesList && { rulesList }),
      isOnChangeValidation
    },
    {
      type: FORM_COMPONENTS_TYPES.REPEAT_PASSWORD,
      inputType: 'password',
      name: 'repeatNewPassword',
      label: 'passwordRecovery.repeatNewPassword.label',
      placeholder: 'passwordRecovery.repeatNewPassword.placeholder',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
      classNames: {
        wrapperClassName: styles.newPasswordWrapper,
        ...repeatPasswordFieldClassNames
      },
      size: 'm'
    }
  ];

  if (errorCode) {
    componentsList.push({
      type: FORM_COMPONENTS_TYPES.INFO_MESSAGE,
      icon: 'error',
      label: t(`accountingMessages.${errorCode}`),
      classNames: {
        wrapperClassName: styles.errorWrap,
        labelClassName: styles.error
      }
    });
  }

  componentsList.push({
    type: FORM_COMPONENTS_TYPES.SUBMIT_BUTTON,
    value: 'passwordRecovery.continueButton',
    size: 'xl',
    classNames: {
      className: styles.submitButton
    }
  });

  return (
    <>
      <FormBuilder
        components={componentsList}
        formConfig={{ defaultValues }}
        onSubmit={onSuccess}
      />
    </>
  );
};

export default NewPassword;
