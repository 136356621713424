import { useMemo } from 'react';
import { useOtherSettings } from '@namespace/cms';

export const useIsBroadcastEnabledForSport = (sportId) => {
  const { liveBroadcast = {} } = useOtherSettings();

  return useMemo(() => {
    const { includeSportId, excludeSportId } = liveBroadcast;
    if (includeSportId?.length) {
      return includeSportId.includes(sportId);
    }
    if (excludeSportId?.length) {
      return !excludeSportId.includes(sportId);
    }
    return true;
  }, [sportId, liveBroadcast]);
};
