import { EventSkelet } from './EventsSkelet';
import styles from './index.module.css';

export const EventMarketSkelet = () => {
  return (
    <div className={styles.wrapper}>
      <div
        className={`${styles.skeletScoreboard} ${styles.shimer} ${styles.shimerBig}`}
      />
      <div
        className={`${styles.skeletHeader} ${styles.shimer}  ${styles.shimerBig}`}
      />
      <div className={styles.skeletInfoBlockWrapper}>
        <div className={styles.column}>
          <div
            className={`${styles.skeletHeaderTitle} ${styles.shimer} ${styles.shimerMini}`}
          />
          <div className={`${styles.skeletHeaderLine} ${styles.shimer}`} />
          <EventSkelet />
          <EventSkelet />
          <EventSkelet />
        </div>
        <div className={`${styles.column} ${styles.show}`}>
          <div
            className={`${styles.skeletHeaderTitle} ${styles.shimer} ${styles.shimerMini}`}
          />
          <div className={`${styles.skeletHeaderLine} ${styles.shimer}`} />
          <EventSkelet />
          <EventSkelet />
          <EventSkelet />
        </div>
      </div>
      <div className={styles.skeletInfoBlockWrapper}>
        <div className={styles.column}>
          <div
            className={`${styles.skeletHeaderTitle} ${styles.shimer} ${styles.shimerMini}`}
          />
          <div className={`${styles.skeletHeaderLine} ${styles.shimer}`} />
          <EventSkelet />
          <EventSkelet />
          <EventSkelet />
        </div>
        <div className={`${styles.column} ${styles.show}`}>
          <div
            className={`${styles.skeletHeaderTitle} ${styles.shimer} ${styles.shimerMini}`}
          />
          <div className={`${styles.skeletHeaderLine} ${styles.shimer}`} />
          <EventSkelet />
          <EventSkelet />
          <EventSkelet />
        </div>
      </div>
    </div>
  );
};
