import { useCallback, useContext, useEffect, useState } from 'react';
import {
  SESSION_STORAGE_KEYS,
  setSessionStorageValue
} from '@namespace/helpers';
import {
  logAction,
  LOG_ACTIONS,
  callGtmEvent,
  GTM_EVENT
} from '@namespace/analytics';
import { useGeneralRoutes } from '@namespace/cms';
import { useNotifications } from '@namespace/notifications';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { UserContext } from '@namespace/user';
import { PaymentsContext } from '../../store/main/context';
import { formatErrorMessage, formatSuccessMessage } from '../../utils';

export const useDeposit = () => {
  const {
    depositResult,
    successfulDeposit,
    unsuccessfulDeposit
  } = useGeneralRoutes();
  const { t, language: lang } = useI18n();
  const { errorNotification, successNotification } = useNotifications();
  const [user] = useContext(UserContext);
  const { activeWallet = {} } = user;
  const { paymentInstrumentId } = activeWallet;
  const [isFetching, setIsFetching] = useState(false);
  const { replace } = useHistory();
  const [{ resultData }, { DEPOSIT }] = useContext(PaymentsContext);

  // todo recheck mb move to layout
  useEffect(() => {
    if (resultData) {
      replace(depositResult);
    }
  }, [resultData]);

  const onDeposit = useCallback(
    async (data, successMessage = '') => {
      try {
        setIsFetching(true);

        const result = await DEPOSIT({
          walletHash: activeWallet.walletHash,
          okUrl: successfulDeposit,
          badUrl: unsuccessfulDeposit,
          lang,
          ...data
        });

        callGtmEvent(GTM_EVENT.DEPOSIT, {
          event_category: 'Account',
          event_label: `Deposit_${paymentInstrumentId}`
        });
        logAction(LOG_ACTIONS.DEPOSIT, {});
        setSessionStorageValue(SESSION_STORAGE_KEYS.FROM_DEPOSIT, 1);

        if (successMessage) {
          successNotification(t(formatSuccessMessage(result, successMessage)));
        }

        return result;
      } catch (response) {
        const { errorCode } = response;
        errorNotification(t(formatErrorMessage(errorCode)));
      } finally {
        setIsFetching(false);
      }
      return null;
    },
    [activeWallet, paymentInstrumentId]
  );

  return {
    onDeposit,
    isFetching
  };
};
