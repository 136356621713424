import { useState, useContext, useCallback, useEffect } from 'react';
import { CommonLoader } from '@namespace/common';
import { useGeneralRoutes } from '@namespace/cms';
import { useNotifications } from '@namespace/notifications';
import { UserContext } from '@namespace/user';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import { Overlay, Box } from '@alf/uikit';
import {
  FORM_COMPONENTS_TYPES,
  FORM_VALIDATION_TYPES,
  FormBuilder,
  useFormMethods
} from '@namespace/forms';
import { BONUS_SHOP_COMPONENTS_MAP, bonusShopComponentsMap } from './constants';
import styles from './index.module.css';

const ShopOrderForm = ({
  createOrder,
  clearBasket,
  totalAmount,
  handleGetCities,
  handleGetWarehouse
}) => {
  const { t } = useI18n();
  const { push } = useHistory();
  const { errorNotification } = useNotifications();
  const [isFetching, setIsFetching] = useState(false);

  const [mailAddressIsDisabled, setMailAddressIsDisabled] = useState(true);

  const [user] = useContext(UserContext);
  const { shopOrderSuccess: shopOrderSuccessRoute } = useGeneralRoutes();
  const {
    firstName = '',
    lastName = '',
    middleName = '',
    phoneNumber = '',
    id,
    isDefaultRegistratedUser
  } = user;

  const defaultValues = {
    fullname: isDefaultRegistratedUser
      ? ''
      : `${firstName} ${middleName} ${lastName}`,
    phoneNumber,
    city: { title: '', value: '' },
    mailAddress: { title: '', value: '' },
    orderInfo: ''
  };

  const formMethods = useFormMethods({
    mode: 'onChange',
    defaultValues
  });
  const { watch } = formMethods;
  const watchedCity = watch('city');
  const onGetCities = useCallback((city) => handleGetCities(city), [
    handleGetCities
  ]);

  const onGetWarehouse = useCallback(
    (address) => handleGetWarehouse(address, watchedCity?.value),
    [handleGetWarehouse, watchedCity]
  );

  const onSubmit = useCallback(
    async ({ city, mailAddress, ...params }) => {
      const body = {
        ...params,
        city: city.title,
        mailAddress: mailAddress.title,
        user_id: id
      };
      try {
        setIsFetching(true);
        await createOrder(body);
        push(shopOrderSuccessRoute);
        clearBasket();
      } catch {
        errorNotification(t('bonusShop.productList.form.error'));
        setIsFetching(false);
      }
    },
    [createOrder]
  );

  useEffect(() => {
    setMailAddressIsDisabled(!watchedCity?.value);
  }, [watchedCity]);

  const componentsList = [
    {
      type: FORM_COMPONENTS_TYPES.INPUT_FIELD,
      name: 'fullname',
      classNames: {
        wrapperClassName: styles.marginBottom
      },
      label: 'bonusShop.form.fullname',
      placeholder: 'bonusShop.form.fullname',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED]
    },
    {
      type: FORM_COMPONENTS_TYPES.INPUT_FIELD,
      name: 'phoneNumber',
      classNames: {
        wrapperClassName: styles.marginBottom
      },
      label: 'bonusShop.form.phoneNumber',
      placeholder: 'bonusShop.form.phoneNumber',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED]
    },
    {
      type: FORM_COMPONENTS_TYPES.INPUT_SELECT,
      name: 'city',
      classNames: {
        containerClassName: styles.marginBottom
      },
      label: 'bonusShop.form.city',
      placeholder: 'bonusShop.form.cityLabel',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED_INPUT_SELECT],
      clearAfterChange: { mailAddress: {} },
      loadAsyncOptions: onGetCities
    },
    {
      type: FORM_COMPONENTS_TYPES.INPUT_SELECT,
      name: 'mailAddress',
      classNames: {
        containerClassName: styles.marginBottom
      },
      label: 'bonusShop.form.mailAddress',
      placeholder: 'bonusShop.form.mailAddressLabel',
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED_INPUT_SELECT],
      loadAsyncOptions: onGetWarehouse,
      disabled: mailAddressIsDisabled
    },
    {
      type: FORM_COMPONENTS_TYPES.TEXTAREA,
      name: 'orderInfo',
      classNames: {
        wrapperClassName: styles.marginBottom
      },
      label: 'bonusShop.form.orderInfo',
      placeholder: 'bonusShop.form.oderInfoLabel',
      resize: 'none'
    },
    {
      type: BONUS_SHOP_COMPONENTS_MAP.TOTAL_PRICE,
      totalAmount,
      margin: { bottom: 'ms' }
    },
    {
      type: FORM_COMPONENTS_TYPES.SUBMIT_BUTTON,
      value: 'bonusShop.order.makeOrder'
    }
  ];

  return (
    <>
      {isFetching && (
        <Overlay>
          <CommonLoader />
        </Overlay>
      )}
      <Box direction="column" justify="center" padding="s 0" flex="1">
        <FormBuilder
          components={componentsList}
          componentsMap={bonusShopComponentsMap}
          onSubmit={onSubmit}
          formConfig={{ defaultValues }}
          formMethods={formMethods}
        />
      </Box>
    </>
  );
};

export default ShopOrderForm;
