import { Box } from '@alf/uikit';
import useBetSlipConfig from '../../hooks/useBetSlipConfig';
import { BetSlipTabs } from '../../common/BetSlipTabs';
import styles from './index.module.css';

const BetSlipLocal = () => {
  const { tabsConfig = {} } = useBetSlipConfig();

  return (
    <Box
      direction="column"
      className={styles.betslipContainer}
      data-role="betslip-container"
    >
      <BetSlipTabs config={tabsConfig} />
    </Box>
  );
};

export default BetSlipLocal;
