import clsx from 'clsx';
import { Icon } from '@namespace/icons2';
import { Box } from '@alf/uikit';
import { ONE_EVENT_HEADER_TABS as TABS } from '../../../../../../../../constants';
import styles from '../../index.module.css';

export const VideoIcon = ({ tabState, expandVideo, isVideoFree }) => (
  <Box
    align="center"
    className={styles.filterIcon}
    onClick={() => expandVideo()}
  >
    <Icon
      dataRole={`video-stream${isVideoFree ? '-free' : ''}`}
      name={`icons/general/state/video${isVideoFree ? '-free' : ''}`}
      className={clsx(
        styles.icon,
        tabState.type === TABS.VIDEO && tabState.opened && styles.videoOn,
        isVideoFree ? styles.videoFree : styles.video
      )}
      size="l"
    />
  </Box>
);
