import { rePatterns } from '../../patterns';

const charsAndDigitsOnlyFormatValidator = (
  message = 'validators.charAndNumberNoCyrillic'
) => ({
  pattern: {
    value: rePatterns.charsAndDigitsOnly,
    message
  }
});

export default charsAndDigitsOnlyFormatValidator;
