import { Component as ReactComponent, cloneElement, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import clsx from 'clsx';
import { SIZES, SIZES_VALUE } from './constants';
import { expandSizeValues } from './utils';
import styles from './index.module.css';

const Box = forwardRef((props, ref) => {
  const {
    component: Component,
    className = '',
    margin,
    top,
    bottom,
    left,
    right,
    justify,
    align,
    gap,
    padding,
    direction,
    inject,
    flex,
    wrap,
    flexGrow,
    flexBasis,
    flexShrink,
    order,
    style: propStyles,
    children,
    danger,
    ...rest
  } = props;

  const classes = clsx([
    styles.box,
    className,
    justify && styles[`justify_${justify}`],
    align && styles[`align_${align}`],
    wrap && styles.wrap
  ]);

  const inlineStyles = {
    marginTop: get(SIZES_VALUE, top) || top,
    marginBottom: get(SIZES_VALUE, bottom) || bottom,
    marginLeft: get(SIZES_VALUE, left) || left,
    marginRight: get(SIZES_VALUE, right) || right,
    gap: get(SIZES_VALUE, gap),
    flexDirection: direction,
    order,
    flex,
    ...propStyles
  };

  if (flexGrow !== undefined) {
    inlineStyles.flexGrow = flexGrow;
  }

  if (flexBasis !== undefined) {
    inlineStyles.flexBasis = flexBasis;
  }

  if (flexShrink !== undefined) {
    inlineStyles.flexShrink = flexShrink;
  }

  if (padding) {
    inlineStyles.padding = expandSizeValues(padding);
  }

  if (margin) {
    inlineStyles.margin = expandSizeValues(margin);
  }

  if (inject && !Array.isArray(children)) {
    return cloneElement(children, {
      className: classes,
      style: inlineStyles
    });
  }

  if (danger) {
    return (
      <Component
        {...rest}
        ref={ref}
        dangerouslySetInnerHTML={{ __html: children }}
        className={classes}
        style={inlineStyles}
      />
    );
  }

  return (
    <Component className={classes} style={inlineStyles} ref={ref} {...rest}>
      {children}
    </Component>
  );
});

Box.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(ReactComponent) })
  ]),
  className: PropTypes.string,
  top: PropTypes.oneOf(SIZES),
  bottom: PropTypes.oneOf(SIZES),
  left: PropTypes.oneOf(SIZES),
  right: PropTypes.oneOf(SIZES),
  justify: PropTypes.oneOf([
    'center',
    'left',
    'right',
    'around',
    'between',
    'start',
    'end'
  ]),
  align: PropTypes.oneOf(['center', 'start', 'end', 'baseline']),
  gap: PropTypes.oneOf(SIZES),
  padding: PropTypes.string,
  margin: PropTypes.string,
  direction: PropTypes.oneOf([
    'row',
    'column',
    'row-reverse',
    'column-reverse'
  ]),
  flex: PropTypes.string,
  flexGrow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flexBasis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flexShrink: PropTypes.number,
  inject: PropTypes.bool,
  danger: PropTypes.bool,
  wrap: PropTypes.bool,
  order: PropTypes.number
};

Box.defaultProps = {
  component: 'div'
};

Box.displayName = 'Box';

export default Box;
