export const calcWUnitSrcSet = ({ fileBase, format, imageSizes }) => {
  return imageSizes
    .map(
      (imgSize) => `${encodeURI(fileBase)}_w${imgSize}.${format} ${imgSize}w`
    )
    .join(', ');
};

export const calcXUnitSrcSet = ({ fileBase, format, xSizes }) => {
  return xSizes
    .map((xSize) => `${encodeURI(fileBase)}@${xSize}x.${format} ${xSize}x`)
    .join(', ');
};

export const getFileBaseAndExtension = (path) => {
  const extension = path.substring(path.lastIndexOf('.') + 1);
  const fileBase = path.replace(`.${extension}`, '');

  return [fileBase, extension];
};

export const calcImageWSizes = ({
  deviceSizes,
  imageSizes,
  sizesRange,
  sizes
}) => {
  if (!sizes) return deviceSizes;

  const allImageSizesArray = [...imageSizes, ...deviceSizes];
  const [minImageSize, maxImageSize] = sizesRange.split('-');

  return allImageSizesArray.slice(
    allImageSizesArray.indexOf(Number(minImageSize)),
    allImageSizesArray.indexOf(Number(maxImageSize)) + 1
  );
};

export const ensureCorrectImgMimeType = (format) => {
  if (format === 'jpg') return 'image/jpeg';

  return `image/${format}`;
};

export const ORDERED_SUPPORTED_IMAGE_FORMATS = [
  'avif',
  'webp',
  'png',
  'jpeg',
  'jpg'
];
