import { pathOr } from 'ramda';
import { createSelector } from 'reselect';
import { MODE } from '../../../constants';
import { useSportsbookSelector } from '../../store';

export const selectCyberSportCategory = (categoryId) =>
  createSelector(
    pathOr({}, ['categories', MODE.PRE_MATCH, 'data', categoryId]),
    pathOr({}, ['categories', MODE.IN_PLAY, 'data', categoryId]),
    (preMatchCategory, liveCategory) => {
      const mixCategory = { ...preMatchCategory, ...liveCategory };

      return {
        ...mixCategory,
        sportSlug: mixCategory.categoryId,
        sportName: mixCategory.categoryName,
        sportId: mixCategory.categoryId,
        eventCount:
          (preMatchCategory.eventCount || 0) + (liveCategory.eventCount || 0)
      };
    }
  );

export const useCyberSportCategory = (categoryId) =>
  useSportsbookSelector(selectCyberSportCategory, [categoryId]);
