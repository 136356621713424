import { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import clsx from 'clsx';
import { useRouteParams } from '@namespace/router';
import { useGeneralRoutes } from '@namespace/cms';
import { DATE_FORMATS_TYPES, useI18n } from '@namespace/i18n';
import { DeviceContext } from '@namespace/device';
import { Box, Button, Overlay } from '@alf/uikit';
import { CommonLoader, NoData } from '@namespace/common';
import { isEmpty } from 'lodash';
import { BetsContext } from '../../store/context';
import {
  useCasinoGameRounds,
  useCasinoGameRoundsName,
  useIsFetching,
  useIsShowMoreRounds
} from '../../store/selectors';
import CasinoHeader from '../CasinoHistory/components/GameSectionResult/components/CasinoHeader';
import CasinoHistoryItem from '../CasinoHistory/components/GameSectionResult/components/CasinoHistoryItem';
import styles from '../CasinoHistory/components/GameSectionResult/index.module.css';

const CasinoDetails = ({
  classNames = {},
  iconConfig = {},
  isNeedCopy = false
}) => {
  const { t, f } = useI18n();
  const { isDesktop } = useContext(DeviceContext);
  const { casinoDetails } = useGeneralRoutes();
  const isFetching = useIsFetching();
  const { date, categoryId, gameId } = useRouteParams(
    `${casinoDetails}/:date/:categoryId/:gameId`
  );
  const dateDt = useMemo(() => new Date(date).getTime(), [date]);
  const [page, setPage] = useState(1);
  const pageLimit = 20;
  const filterData = useMemo(() => {
    return {
      startDate: f
        .getDateTime({ date: dateDt, fromUTC: true })
        .startOf('day')
        .toFormat(DATE_FORMATS_TYPES.API_WITH_TIME),
      endDate: f
        .getDateTime({ date: dateDt, fromUTC: true })
        .startOf('day')
        .plus({ days: 1 })
        .toFormat(DATE_FORMATS_TYPES.API_WITH_TIME),
      categoryId
    };
  }, [f, dateDt]);

  const { iconPosition = '' } = iconConfig || {};

  const [, actions] = useContext(BetsContext);
  const { GET_CASINO_GAME_ROUNDS } = actions;

  useEffect(() => {
    GET_CASINO_GAME_ROUNDS(filterData, gameId, page, pageLimit);
  }, [GET_CASINO_GAME_ROUNDS, filterData, gameId, page, pageLimit]);

  const showMore = useCallback(() => {
    setPage(page + 1);
  }, [page, setPage]);

  const roundsData = useCasinoGameRounds();
  const gameTitle = useCasinoGameRoundsName();
  const isShowMoreRounds = useIsShowMoreRounds();

  const {
    titleClass = '',
    iconClass = '',
    labelClass = '',
    headerClass = '',
    customFont = '',
    casinoHistoryHeaderDate = '',
    customContainerItem = '',
    customGameResultWrapper = ''
  } = classNames;

  if (isEmpty(roundsData)) {
    return (
      <>
        {isFetching && (
          <Overlay>
            <CommonLoader />
          </Overlay>
        )}
        <NoData />
      </>
    );
  }

  return (
    <Box direction="column">
      {isFetching && (
        <Overlay>
          <CommonLoader />
        </Overlay>
      )}
      <Box
        direction="column"
        margin={isDesktop ? 'm' : 's 0'}
        className={clsx(
          styles.CasinoGameResultWrapper,
          customGameResultWrapper
        )}
      >
        <CasinoHeader
          titleClass={titleClass}
          iconClass={iconClass}
          headerClass={headerClass}
          casinoHistoryHeaderDate={casinoHistoryHeaderDate}
          gameName={gameTitle.gameName}
          providerName={gameTitle.providerName}
          date={f
            .getDateTime({ date: dateDt })
            .toFormat(DATE_FORMATS_TYPES.dd_MM_yyyy)}
        />
        <Box className={styles.CasinoItemsWrapper}>
          {roundsData?.map((roundItem) => (
            <CasinoHistoryItem
              key={roundItem.stringRoundId}
              roundId={roundItem.stringRoundId}
              date={f
                .getDateTime({ date: roundItem.firstAction, fromUTC: true })
                .toFormat(DATE_FORMATS_TYPES.CASINO_ROUNDS_DATE_FORMAT)}
              betSum={roundItem.betSum}
              freeSpinWins={roundItem.freeSpinWins}
              jackpotWins={roundItem.jackpotWins}
              tournamentWins={roundItem.tournamentWins}
              betWin={roundItem.wins}
              translate={t}
              refunds={roundItem.refunds}
              canceled={roundItem.canceled}
              status={roundItem.status}
              walletType={roundItem.walletType}
              customLabel={labelClass}
              customFont={customFont}
              customContainerItem={customContainerItem}
              iconPosition={iconPosition}
              iconConfig={iconConfig}
              isNeedCopy={isNeedCopy}
              gameName={gameTitle.gameName}
              providerName={gameTitle.providerName}
            />
          ))}
        </Box>
      </Box>
      {!isShowMoreRounds && (
        <Button
          className={styles.btn}
          size="m"
          actionType="color3"
          intent="quaternary"
          onClick={showMore}
          processing={isFetching}
        >
          {t('bets.betsCasino.table.showMore')}
        </Button>
      )}
    </Box>
  );
};

export default CasinoDetails;
