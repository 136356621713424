import { useState, useEffect } from 'react';
import { useI18n } from '@namespace/i18n';
import { Button } from '@alf/uikit';
import { Icon } from '@namespace/icons2';
import styles from './index.module.css';

export const Captcha = ({ errorCode = '', wrapperClassName = '' }) => {
  const { t } = useI18n();
  const [captcha, setCaptcha] = useState(Math.random());
  const onClick = () => setCaptcha(Math.random());

  useEffect(() => {
    if (errorCode) {
      setCaptcha(Math.random());
    }
  }, [errorCode]);

  return (
    <div className={`${styles.wrapper} ${wrapperClassName}`}>
      <img
        src={`/accounting/images/captcha.php?v${captcha}`}
        title={t('captcha.refresh')}
        alt={t('captcha.refresh')}
      />
      <Button intent="quaternary" onClick={onClick} className={styles.button}>
        <Icon name="icons/general/action/refresh" fill="color4" />
      </Button>
    </div>
  );
};
