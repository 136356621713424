import { useContext, useCallback } from 'react';
import { useGeneralRoutes } from '@namespace/cms';
import { UserContext } from '@namespace/user';
import { DOCUMENT_STATUSES, useVerifyDocuments } from '@namespace/account';

export const useVerifyUrl = () => {
  const [user] = useContext(UserContext);
  const { isDefaultRegistratedUser } = user;
  const {
    verificationDocuments,
    profileDocuments,
    profilePage,
    loadDocumentsSuccess
  } = useGeneralRoutes();
  const {
    verified = false,
    status = '',
    isEmptyDocument,
    isDocumentsUpload
  } = useVerifyDocuments();

  return useCallback(
    (successUrl = '') => {
      if (verified) {
        return successUrl;
      }

      if (isDefaultRegistratedUser) {
        return profilePage;
      }

      if (
        !isEmptyDocument &&
        (status === DOCUMENT_STATUSES.PENDING ||
          status === DOCUMENT_STATUSES.ADDITIONAL_INFORMATION)
      ) {
        return profileDocuments;
      }

      if (isDocumentsUpload) {
        return loadDocumentsSuccess;
      }

      return verificationDocuments;
    },
    [
      verified,
      isDefaultRegistratedUser,
      isEmptyDocument,
      status,
      isDocumentsUpload,
      verificationDocuments,
      profilePage,
      profileDocuments,
      loadDocumentsSuccess
    ]
  );
};
