import { useCallback, useContext, useEffect, useMemo } from 'react';
import { FullScreenNotification } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { useHistory } from '@namespace/router';
import {
  useGeneralRoutes,
  COMPONENTS_TYPES,
  PageComponentsContext,
  useOtherSettings
} from '@namespace/cms';
import { isEmpty } from 'lodash';
import { BonusesContext } from '../../store/context';
import { PWB_NOTIFICATION_TYPES } from '../../constants';

export const PWBBonusesNotification = () => {
  const [bonuses, bonusesActions] = useContext(BonusesContext);
  const { SET_PWB_NOTIFICATIONS } = bonusesActions;
  const { pwbNotification } = bonuses;
  const { type = '', data = {} } = pwbNotification || {};
  const {
    amount = '',
    currency = '',
    availableBonusCount,
    campaignName,
    bonusId,
    campaignId
  } = data;
  const { t } = useI18n();
  const { push } = useHistory();
  const { fundsBonusDetails, fundsBonusList } = useGeneralRoutes();
  const { [COMPONENTS_TYPES.PWB_TRIGGERS_POPUP]: PartnerPWBPopup } =
    useContext(PageComponentsContext) || {};

  const { isAutoClose = true } = useOtherSettings();

  const notificationTitle = useMemo(
    () =>
      type === PWB_NOTIFICATION_TYPES.WAGERED
        ? t('web.personaloffice.bonuses.notification.completed.label')
        : '',
    [t, type]
  );

  const notificationSubtitle = useMemo(
    () =>
      type === PWB_NOTIFICATION_TYPES.WAGERED
        ? t('web.personaloffice.bonuses.notification.completed', [
            amount,
            currency
          ])
        : t('web.personaloffice.bonuses.notification.start.wagering', [
            t(`content.bonus.template.name.bs.${campaignId}`, campaignName)
          ]),
    [amount, campaignId, campaignName, currency, t, type]
  );

  const acceptButtonText = useMemo(
    () =>
      type === PWB_NOTIFICATION_TYPES.WAGERED
        ? t('web.personaloffice.bonuses.button.completed.continue')
        : t('web.personaloffice.bonuses.button.view.bonus'),
    [t, type]
  );

  const onClose = useCallback(() => {
    SET_PWB_NOTIFICATIONS(null);
  }, [SET_PWB_NOTIFICATIONS]);

  const onAccept = useCallback(() => {
    if (availableBonusCount > 0) {
      push(fundsBonusList);
    } else if (type === PWB_NOTIFICATION_TYPES.PROPOSED) {
      push(`${fundsBonusDetails}/bs${bonusId}`);
    }
    onClose();
  }, [
    onClose,
    availableBonusCount,
    bonusId,
    fundsBonusDetails,
    fundsBonusList,
    push,
    type
  ]);

  useEffect(() => {
    if (!isEmpty(pwbNotification) && isAutoClose) {
      const timeoutId = setTimeout(() => {
        onClose();
      }, 10000);

      return () => {
        clearTimeout(timeoutId);
      };
    }

    return undefined;
  }, [onClose, isAutoClose, pwbNotification]);

  const Popup = PartnerPWBPopup || FullScreenNotification;

  return (
    !isEmpty(pwbNotification) && (
      <Popup
        onClose={onClose}
        onAccept={onAccept}
        title={notificationTitle}
        subtitle={notificationSubtitle}
        acceptButtonText={acceptButtonText}
      />
    )
  );
};
