import { pathOr } from 'ramda';
import { shallow } from 'zustand/shallow';
import { createSelector } from 'reselect';
import { useSportsbookStore } from '../../store';

export const selectTournamentsByIds = (tournamentsIds, serviceId) =>
  createSelector(
    pathOr({}, ['tournaments', serviceId, 'data']),
    (tournamentsData) =>
      tournamentsIds
        .map((tournamentId) => tournamentsData[tournamentId])
        .filter(Boolean)
  );

export const useTournamentsByIds = (tournamentsIds, serviceId) =>
  useSportsbookStore(
    selectTournamentsByIds(tournamentsIds, serviceId),
    shallow
  );
