import { isString } from 'lodash';

export const ENDORPHINA_COMANDS = {
  EXIT: 'casinoGameClosed',
  DEMO_EXIT: 'closeInnerFrame',
  CLOSE_FRAME: 'closeFrame'
};

export const endorphinaLauncherControl = (event, callbacks = {}) => {
  const { onExitHandler = () => {} } = callbacks;
  const { data } = event;
  if (
    data &&
    ((isString(data) &&
      (data === ENDORPHINA_COMANDS.EXIT ||
        data === ENDORPHINA_COMANDS.DEMO_EXIT ||
        data === ENDORPHINA_COMANDS.CLOSE_FRAME)) ||
      (Array.isArray(data) &&
        (data[0] === ENDORPHINA_COMANDS.EXIT ||
          data[0] === ENDORPHINA_COMANDS.DEMO_EXIT)))
  ) {
    onExitHandler();
  }
};
