import { useMemo } from 'react';
import { useConfig } from '@namespace/cms';
import { useSportsbookStore } from '../../store';
import Menu from '../../common/TopEventsMenu';

const TopEventsMenu = ({ id }) => {
  const topEvents = useSportsbookStore('topEvents');
  const { config = {} } = useConfig(id);
  const { isShowImages = false, sportIds = [] } = config;

  const filteredTopEvents = useMemo(() => {
    if (sportIds.length) {
      return topEvents.filter(
        ({ events = [], tournaments = [] }) =>
          tournaments.some(({ sportId }) => sportIds.includes(sportId)) ||
          events.some(({ sportId }) => sportIds.includes(sportId))
      );
    }
    return topEvents;
  }, [topEvents, sportIds]);

  return <Menu isShowImages={isShowImages} presets={filteredTopEvents} />;
};

export default TopEventsMenu;
