import clsx from 'clsx';
import {
  FORM_COMPONENTS_TYPES,
  FORM_VALIDATION_TYPES,
  FormBuilder
} from '@namespace/forms';
import { useI18n } from '@namespace/i18n';
import styles from './index.module.css';

const EnterEmail = ({
  onSuccess = () => {},
  email = '',
  withCaptcha = false,
  errorCode = '',
  enterMailStyle = {}
}) => {
  const { t } = useI18n();
  const defaultValues = {
    email,
    captcha: ''
  };

  const { captchaClassName = '', labelClassName = '' } = enterMailStyle;

  const componentsList = [
    {
      type: FORM_COMPONENTS_TYPES.EMAIL_INPUT_FIELD,
      name: 'email',
      label: 'passwordRecovery.email.label',
      placeholder: 'passwordRecovery.email.placeholder',
      allowClean: true,
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
      size: 'm'
    }
  ];

  if (withCaptcha) {
    componentsList.push({
      type: FORM_COMPONENTS_TYPES.CAPTCHA_FIELD,
      name: 'captcha',
      label: 'passwordRecovery.captcha.label',
      classNames: {
        labelClassName: clsx([styles.captchaLabel, labelClassName]),
        captchaClassName: clsx([styles.captcha, captchaClassName])
      },
      placeholder: 'passwordRecovery.captcha.placeholder',
      allowClean: true,
      autoComplete: 'off',
      errorCode,
      validationTypes: [FORM_VALIDATION_TYPES.REQUIRED],
      size: 'm'
    });
  }

  if (errorCode) {
    componentsList.push({
      type: FORM_COMPONENTS_TYPES.INFO_MESSAGE,
      icon: 'error',
      label: t(`accountingMessages.${errorCode}`),
      classNames: {
        wrapperClassName: styles.errorWrap,
        labelClassName: styles.error
      }
    });
  }

  componentsList.push({
    type: FORM_COMPONENTS_TYPES.SUBMIT_BUTTON,
    value: 'passwordRecovery.continueButton',
    size: 'xl',
    classNames: {
      className: styles.submitButton
    }
  });

  return (
    <FormBuilder
      components={componentsList}
      formConfig={{ defaultValues }}
      onSubmit={onSuccess}
    />
  );
};

export default EnterEmail;
