import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  removeLangFromPathname,
  removeParamsFromQuery
} from '@namespace/helpers';
import usePostMessage from '../usePostMessage';
import { IS_WIDGET, POST_MESSAGE_TYPES } from '../../constants';
import { WidgetContext } from '../../store/widget/context';

const useListenChangeUrl = () => {
  const { postMessage } = usePostMessage();
  const [{ isBetHistoryPage }] = useContext(WidgetContext);
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (IS_WIDGET && !isBetHistoryPage) {
      postMessage(
        POST_MESSAGE_TYPES.URL_CHANGED,
        `${removeLangFromPathname(pathname)}${removeParamsFromQuery(search, [
          'widget'
        ])}`
      );
    }
  }, [pathname, search, IS_WIDGET, isBetHistoryPage]);

  useEffect(() => {
    if (IS_WIDGET && !isBetHistoryPage) {
      postMessage(POST_MESSAGE_TYPES.PATHNAME_CHANGED, '');
    }
  }, [pathname, IS_WIDGET, isBetHistoryPage]);
};

export default useListenChangeUrl;
