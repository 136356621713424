import { AText, Box } from '@alf/uikit';
import { useI18n } from '@namespace/i18n';
import { useGetServiceProperties } from '../../hooks/useGetServiceProperties';

export const ServiceProperties = ({
  withLimits,
  customServiceWrapper = ''
}) => {
  const { t } = useI18n();
  const { amount, type, limits } = useGetServiceProperties(withLimits);
  const { min, max } = limits;

  if (!(amount || type || min || max)) {
    return null;
  }

  return (
    <Box top="m" className={customServiceWrapper} wrap={true}>
      <Box>
        {amount && (
          <Box align="center" right="m">
            <AText breed="R" size="s" color="po_txt_color_3">
              {t('personalOffice.balance.sw.forms.fee')}
            </AText>
            <Box left="xs">
              <AText breed="R" size="s" color="po_txt_color_1">
                {amount}
              </AText>
            </Box>
          </Box>
        )}
        {type && (
          <Box align="center" right="m">
            <AText breed="R" size="s" color="po_txt_color_3">
              {t('personalOffice.balance.sw.forms.makeDeposit')}
            </AText>
            <Box left="xs">
              <AText breed="R" size="s" color="po_txt_color_1">
                {t(`personalOffice.balance.sw.forms.makeDeposit.${type}`)}
              </AText>
            </Box>
          </Box>
        )}
      </Box>
      <Box>
        {min && (
          <Box align="center" right="m">
            <AText breed="R" size="s" color="po_txt_color_3">
              {t('web.personalOffice.balance.sw.minAmount')}
            </AText>
            <Box left="xs">
              <AText breed="R" size="s" color="po_txt_color_1">
                {min}
              </AText>
            </Box>
          </Box>
        )}
        {max && (
          <Box align="center" right="m">
            <AText breed="R" size="s" color="po_txt_color_3">
              {t('web.personalOffice.balance.sw.maxAmount')}
            </AText>
            <Box left="xs">
              <AText breed="R" size="s" color="po_txt_color_1">
                {max}
              </AText>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
